import { Component, OnInit } from '@angular/core';
import { DatabaseConfigService } from 'src/app/services/api/database-config.service'
import { toast } from 'materialize-css';
import { Router } from '@angular/router';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/firebase/auth.service';

declare var $: any;

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})

export class SettingsComponent implements OnInit {

  defaultThemeColor = environment.appTheme.themeColor;
  themeColor: any;
  buttonType: any;
  user: any;
  selectedMenu = {
    name: 'Menu Setting',
    icon: 'phonelink_setup',
  }

  constructor(public appSettingsService: AppSettingsService,
    private router: Router,
    private databaseconfig: DatabaseConfigService,
    private authService: AuthService) {
    this.themeColor = this.appSettingsService.getAppThemeColor() ? this.appSettingsService.getAppThemeColor() : this.defaultThemeColor;
    this.buttonType = this.appSettingsService.getButtonType() ? this.appSettingsService.getButtonType() : 'rectangularButton';

    this.user = this.authService.getGlobalUser();
  }

  ngOnInit(): void {
    setTimeout(() => {
      $('.dropdown-trigger').dropdown({
        constrainWidth: false,
        isScrollable: true,
      });
      $('.tooltipped').tooltip();
    }, 25);
  }

  select(menu, icon) {
    this.selectedMenu.name = menu;
    this.selectedMenu.icon = icon;
  }

  updateDatabaseClick() {
    const id = 2;
    this.databaseconfig.updateDatabaseTables(id).subscribe(x => {
      if (x) {
        toast({ html: 'Database Tables Updated !', classes: 'green' });
      }
    });
  }

  backClicked() {
    this.router.navigate(['/admin/dashboard']);
  }
}