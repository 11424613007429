import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { toast } from 'materialize-css';

@Component({
  selector: 'app-create-event',
  templateUrl: './create-event.component.html',
  styleUrls: ['./create-event.component.css']
})

export class CreateEventComponent implements OnInit {

  myModel = {
    id: '',
    type: 'Event',
    title: '',
    eventDate: '',
    eventPlace: '',
    eventDuration : 1,
    body: '',
    imgLink: '',
    videoLink: '',
    visibility: '',
    expiryDate: '',
    author: '',
    published: true,
    unPublished: false,
    datePublished: new Date(),
    index: Math.floor(Math.random() * (10000 - 5000 + 1)) + 5000,
    genderFilter: '',
    communityFilter: '',
    minAgeFilter: '',
    maxAgeFilter: '',
    residencyFilter: '',
  };
  valueEmittedFromChildComponent: any;
  parentEventHandlerFunction(valueEmitted) {
    this.valueEmittedFromChildComponent = valueEmitted;
    if (this.valueEmittedFromChildComponent === true) {
      toast({ html: 'Event Successfully Created!', classes: 'green' });
    }
  }

  constructor(private location: Location) {}

  ngOnInit() {
  }

  backClicked() {
    this.location.back();
  }

}
