import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AddBandMemberModel, MemberMatchModel } from 'src/app/models/AddMemberModel';
import { EmailService } from 'src/app/services/api/email.service';
import { MemberService } from 'src/app/services/api/member.service';
import { RegistrationRequestService } from 'src/app/services/api/registration-request.service';
import { EmployeeRegRequestService } from 'src/app/services/firebase/employee-reg-request.service';
import { environment } from 'src/environments/environment';
import { toast } from 'materialize-css';
import { Location } from '@angular/common';
import { EmailModel } from 'src/app/models/emailModel';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { FireMembersService } from 'src/app/services/firebase/fire-members.service';
import { DataService } from 'src/app/services/data.service';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-employees-registration-request',
  templateUrl: './employees-registration-request.component.html',
  styleUrls: ['./employees-registration-request.component.css']
})
export class EmployeesRegistrationRequestComponent implements OnInit {

  provinces: any[];
  Status = ['Pending', 'Invited', 'Authenticated', 'Declined', 'Archived'];

  public dateformat = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
  themeColor = environment.appTheme.adminThemeColor;
  fontColor = environment.appTheme.adminFontColor;
  url = environment.firstNation.website;
  user: any;
  registrationRequests: any[];
  loading = false;
  departmentID: any;
  step1 = true;
  step2 = false;
  step3 = false;
  request: any;
  model: AddBandMemberModel;
  hasMatch = false;
  hasISNMatch = false;
  hasNoMatch = false;
  matches: any[];
  selected = false;
  selectedIndex: number;
  selectedMember: any;
  memberAdded = false;
  addedMemberId: any;
  declineReason = '';
  firebaseMember: any;

  constructor( private authService: AuthService,
    private empRegReq: EmployeeRegRequestService,
    private location: Location,
    private memberService: MemberService,
    private fireMemberService: FireMembersService,
    private emailService: EmailService,
    private dataService: DataService) { 
      this.provinces = this.dataService.getProvinceWithCode();
    }

  ngOnInit(): void {
    this.user = this.authService.getGlobalUser();
    if(this.user){
      this.departmentID = this.user.departmentId
    }
  
    this.loading = true;
    if (this.departmentID) {
      this.empRegReq.getDepartmentRequests(this.departmentID).valueChanges().subscribe(x => {
        if (x && x.length > 0) {
          this.registrationRequests = x;
          this.registrationRequests = this.registrationRequests.sort((d1,d2) => new Date(d2.datetime.toDate()).getTime()- new Date(d1.datetime.toDate()).getTime());
        }
        this.loading = false;
      });
    }
  }

  changeDates(dateValue) {
    if (dateValue) {
      if (dateValue.match(this.dateformat)) {
        let dateArray = dateValue.split('/');
        let year = dateArray[2];
        let month = dateArray[1] - 1;
        let day = dateArray[0];
        const date = new Date(year, month, day);
        return date;
      } else {
        const date = new Date(dateValue);
        return date;
      }
    } else {
      return null
    }
  }

  backClicked() {
    this.location.back();
  }

  backToList() {
    this.step1 = true;
    this.step2 = false;
    this.step3 = false;
  }

  showReqDetails(request) {
    this.request = request;
    this.step1 = false;
    this.step2 = true;
  }

  onApprove() {
    this.selected = false;
    this.selectedMember = null;
    this.selectedIndex = null;
    this.hasMatch = false;
    this.hasNoMatch = false;
    this.hasISNMatch = false;
    this.checkForMatch();
  }

  checkForMatch() {
    let model = new MemberMatchModel();
    model.GivenName = this.request.Fname;
    model.LastName = this.request.Lname;
    model.IndianStatusNo = this.request.isn;
    this.memberService.chekForPossibleMatch(model).subscribe(result => {
      if (result && result.length > 0) {
        if (model.IndianStatusNo && model.IndianStatusNo != "") {
          let isnMatch = result.filter(member => member.IndianStatusNo == model.IndianStatusNo);
          if (isnMatch.length > 0) this.hasISNMatch = true;
        }
        this.hasMatch = true;
        this.matches = result;
        $('.modal').modal();
        $('#similarMembersModal').modal('open');
      } else {
        this.hasMatch = false;
        this.hasNoMatch = true;
        $('.modal').modal();
        $('#noMatchFound').modal('open');
      }
    });
  }

  selectMatch(index) {
    this.selected = !this.selected;
    if (this.selected) {
      this.loading = true;
      this.selectedIndex = index;
      this.selectedMember = this.matches[index];
      this.fireMemberService.getMemberByAppliciantId(this.selectedMember.applicantID).valueChanges().subscribe(x => {
        if (x && x.length > 0) {
          this.firebaseMember = x[0];
          if (this.firebaseMember.role == 'Member') {
            $('#similarMembersModal').modal('close');
            $('.modal').modal({ dismissible: false });
            $('#inviteMember').modal('open');
          }
        }
        this.loading = false;
      })
    } else {
      this.selectedIndex = null;
      this.selectedMember = null;
    }
  }

  addMember() {
    this.model = new AddBandMemberModel();
    this.model.GivenName = this.request.Fname;
    this.model.LastName = this.request.Lname;
    this.model.middle_name = this.request.Mname;
    if (this.request.dob && (typeof this.request.dob) == 'string') {
      this.request.dob = this.changeDates(this.request.dob);
    }
    this.model.DOB = this.request.dob;
    //this.model.DOB = new Date();
    if (this.request.gender == 'Female') this.request.gender = 'F';
    else if (this.request.gender == 'Male') this.request.gender = 'M';
    this.model.Sex = this.request.gender;
    this.model.IndianStatusNo = this.request.isn;
    this.model.Email = this.request.email;
    this.model.cell_phone_num = this.request.cellPhone;
    this.model.HealthCareNo = this.request.healthCareNumber;
    this.model.MailAddress = this.request.address;
    this.model.city = this.request.city;
    const province = this.provinces.filter(prov => prov.name == this.request.province);
    this.model.province = province[0].code;;
    this.model.PostalCode = this.request.postalCode;
    this.model.SIN = this.request.sin;
    this.model.Community = this.request.community;
    this.model.Officer = this.request.officer;
    this.model.date_added = new Date();
    this.model.is_employee = true;

    this.memberService.AddMember(this.model).subscribe(resp => {
      if (resp){
        this.addedMemberId = resp;
        $('#similarMembersModal').modal('close');
        $('#noMatchFound').modal('close');
        $('.modal').modal({ 'dismissible': false });
        $('#memberAdded').modal('open');
      }
    });
  }

  SendRegistrationLink(applicantID) {
    this.url = window.location.hostname !== 'localhost' ? this.url : 'http://localhost:4200/#';
    const invitationModel = {
      ApplicantID: applicantID,
      verifySIN: false,
      verifyISN: false,
      secretQuestion: '',
      secretAnswer: '',
      email: this.request.email,
    };
    const msg = new EmailModel();
    msg.emailList = [];
    msg.emailList.push(this.request.email);
    msg.subject = 'Registration Request Approved';
    msg.body = '<h2>Your Request has been Approved </h2> <p>Please click the link below to continue with the registration. </p> <p> <strong> Registration Link : </strong> </p> <p> ' + this.url +
      '/auth/employee-register/' + applicantID +
      '</p> <br/> <p><strong> Thank you for using Our App.  </strong> </p>';

    ////this.changeRequestStatus(2, applicantID);
    this.loading = true;
    this.emailService.sendEmail(msg).subscribe(x => {
      if (x.Status && x.Status == "OK") {
        toast({ html: 'Registration Link sent successfully!', classes: 'green' });
        this.memberService.InviteMember(invitationModel).subscribe(res => {
          if (res == 'Success') {
            $('.modal').modal('close');
            this.changeRequestStatus(2, applicantID);
            this.step1 = false;
            this.step2 = false;
            this.step3 = true;
            this.loading = false;
          }
        });
      }
      else {
        toast({ html: 'Unable to send Registration Link!', classes: 'red' });
        this.loading = false;
        $('.modal').modal('close');
        this.backToList();
      }
    });
  }

  invite() {
    const mail = new EmailModel();
    mail.emailList = [this.request.email];
    mail.subject = 'Account Update ';
    mail.body = '<h2>Hello, </h2> <p>Your account has been upgraded to an employee. </p> <p> <strong>Please Use your member account credentials to log into the app. </strong> </p> </p> <br/> <p>Thank you for using Our App. </p>';

    this.firebaseMember.role = 'Employee';
    this.firebaseMember.isMember = true;

    this.loading = true;

    this.fireMemberService.updateAccountRole(this.firebaseMember).then(x => {
      this.emailService.sendEmail(mail).subscribe(x => {
        if (x.Status && x.Status == "OK") {
          this.changeRequestStatus(3, this.firebaseMember.applicantID);
          $('.modal').modal('close');
          toast({ html: 'Employee Invitaion sent successfully!', classes: 'green' });
          this.backToList();
        }
        else {
          toast({ html: 'Unable to send Invitation Link!', classes: 'red' });
          $('.modal').modal('close');
          this.backToList();
        }
      });
    });
  }

  onDecline() {
    this.declineReason = '';
    $('.modal').modal();
    $('#declinModal').modal('open');
    M.textareaAutoResize($('#reason'));
  }

  Decline() {
    const msg = new EmailModel();
    msg.emailList = [];
    msg.emailList.push(this.request.email);
    msg.subject = 'Registration Request Declined';
    msg.body = '<h2>Your Request has been Approved. </h2> <p> Your request to register at as an employee on myNation was Declined Because of </p> <p>' + this.declineReason + '<p/> <br/> <p><strong> Thank you for using Our App.  </strong> </p>';

    this.loading = true;
    this.emailService.sendEmail(msg).subscribe(x => {
      if (x.Status && x.Status == "OK") {
        this.changeRequestStatus(4);
        toast({ html: 'The request has been declined!', classes: 'blue' });
        this.backToList();
        this.loading = false;
        $('#declinModal').modal('close');
      }
      else {
        toast({ html: 'Unable to decline the reqeust!', classes: 'red' });
        this.loading = false;
        this.backToList();
        $('#declinModal').modal('close');
      }
    });
  }

  changeRequestStatus(status, applicantID?) {
    if(status == 4){
      this.empRegReq.updateRequestStatus(this.request.id, status, this.declineReason).then(x => {
      });
    }else{
      this.empRegReq.updateRequestStatus(this.request.id, status).then(x => {
      });
    }
    if(applicantID){
      this.empRegReq.linkRequestToMember(this.request.id,applicantID).then(x => {
      });
    }
  }

  OnDelete() {
    $('.modal').modal();
    $('#deleteConfirmation').modal('open');
  }

  DeleteRequest() {
    this.empRegReq.deleteRequest(this.request.id).then(x => {
      toast({ html: 'Request has been Deleted!', classes: 'green' });
      this.backClicked();
    });
  }

  cancel() {
    $('.modal').modal('close');
    $('select').formSelect();
    M.updateTextFields();
  }

  closeModal(){
    $('.modal').modal('close');
  }
}