import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';

declare var M: any;
declare var $: any;

@Component({
  selector: 'app-messaging',
  templateUrl: './messaging.component.html',
  styleUrls: ['./messaging.component.css'],
})

export class MessagingComponent implements OnInit {
 
  constructor( private location: Location, ) {}

  ngOnInit() {    
  }
  backClicked() {
    this.location.back();
  }
}
