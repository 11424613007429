export class EmploymentModel {
  id = 0;
  client_id = 0;
  Name = '';
  employer = '';
  City = '';
  Prov = '';
  description = '';
  start_date: Date;
  end_date: Date;
  iscurrent = false;
  reason = '';
}
