export class PrivateConversationsModel {
    id : '';
    dateCreated : Date;
    user_1: {};
    user_2: {};
    lastMessage: {};
    // unreadCount: 0;
  }
export class GroupConversationsModel {
    id : '';
    dateCreated : Date;
    name : '';
    users: [];
    isPrivate: false;
    profilePicture: '';
    lastMessage: {};
    admin: '';
    previousMembers:[];
    // unreadCount: 0;
}