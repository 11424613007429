
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
//import { PSSSPApplicationModel } from 'src/app/models/PSSPApplicationModel';
import { ApiKeyManagerService } from './apiKeyManager.Service';
import { PSEApplicationModel } from 'src/app/models/PSEApplicationModel';
import { IncomeSupportFamilyMemberModel } from 'src/app/models/IncomeSupportFamilyMemberModel';
import { PseHighSchoolModel } from 'src/app/models/PseHighSchoolModel';
import { PseCollegeModel } from 'src/app/models/PseCollegeModel';
import { PseEduPlanModel } from 'src/app/models/PseEduPlanModel';
import { PseFindCourseModel } from 'src/app/models/PseFindCourseModel';
import { PseCoursePlanModel } from 'src/app/models/PseCoursePlanModel';
import { PseSupportHistoryModel } from 'src/app/models/PseSupportHistoryModel';
import { PseLoadDocumentModel } from 'src/app/models/PseLoadDocumentModel';
import { PseFundingModel } from 'src/app/models/PseFunding.Model';

@Injectable({
  providedIn: 'root'
})
export class PseApplicationService {

    constructor(private httpClient: HttpClient, private keyManager: ApiKeyManagerService) { }

    /*
    getMember(id: number): Observable<any> {
      const url = environment.firstNation.apiUrl + 'api/members/Get/' + id;
      //console.log(url);
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
      return this.httpClient.get<any>(url, { headers });
    }
    */
    
    getPSEApplication(id: number): Observable<any> {
      const url = environment.firstNation.apiUrl + 'api/PSEApplication/GetPSEApplication/' + id;
      //console.log(url);
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
      return this.httpClient.get<any>(url, { headers });
    }
      

    saveApplication(model: PSEApplicationModel): Observable<any>{
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
      return this.httpClient.post<any>(
        environment.firstNation.apiUrl + 'api/PSEApplication/SaveApplication', JSON.stringify(model), { headers });
    } 

  
    SaveFamilyComposition(model: PSEApplicationModel): Observable<any>{
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
      return this.httpClient.post<any>(
        environment.firstNation.apiUrl + 'api/PSEApplication/SaveFamilyComposition', JSON.stringify(model), { headers });
    } 

    saveFamilyMembers(model: IncomeSupportFamilyMemberModel[]): Observable<any> {
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
      return this.httpClient.post<any>(
        environment.firstNation.apiUrl + 'api/PSEApplication/SaveFamilyMembers', JSON.stringify(model), { headers });
    }


    getFamilyMembers(id: number): Observable<any> {
      const url = environment.firstNation.apiUrl + 'api/PSEApplication/GetFamilyMembers/' + id;
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
      return this.httpClient.get<any>(url, { headers });
    }

    saveHighSchool(model: PseHighSchoolModel): Observable<any> {
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
      return this.httpClient.post<any>(
        environment.firstNation.apiUrl + 'api/PSEApplication/SaveHighSchool', JSON.stringify(model), { headers });
    }
    

    getHighSchool(id: number): Observable<any> {
      const url = environment.firstNation.apiUrl + 'api/PSEApplication/GetHighSchool/' + id;
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
      return this.httpClient.get<any>(url, { headers });
    }

    
    getCollege(id: number): Observable<any> {
      const url = environment.firstNation.apiUrl + 'api/PSEApplication/GetCollege/' + id;
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
      return this.httpClient.get<any>(url, { headers });
    }

    
    saveCollege(model: PseCollegeModel[]): Observable<any> {
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
      return this.httpClient.post<any>(
        environment.firstNation.apiUrl + 'api/PSEApplication/SaveCollege', JSON.stringify(model), { headers });
    }


    
    getUniversity(id: number): Observable<any> {
      const url = environment.firstNation.apiUrl + 'api/PSEApplication/GetUniversity/' + id;
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
      return this.httpClient.get<any>(url, { headers });
    }


    saveUniversity(model: PseCollegeModel[]): Observable<any> {
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
      return this.httpClient.post<any>(
        environment.firstNation.apiUrl + 'api/PSEApplication/SaveUniversity', JSON.stringify(model), { headers });
    }


    
    getTraining(id: number): Observable<any> {
      const url = environment.firstNation.apiUrl + 'api/PSEApplication/GetTraining/' + id;
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
      return this.httpClient.get<any>(url, { headers });
    }


    saveTraining(model: PseCollegeModel[]): Observable<any> {
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
      return this.httpClient.post<any>(
        environment.firstNation.apiUrl + 'api/PSEApplication/SaveTraining', JSON.stringify(model), { headers });
    }

    
    saveEducationPlan(model: PseEduPlanModel): Observable<any>{
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
      return this.httpClient.post<any>(
        environment.firstNation.apiUrl + 'api/PSEApplication/SaveEducationPlan', JSON.stringify(model), { headers });
    } 

    getEducationPlan(id: number): Observable<any> {
      const url = environment.firstNation.apiUrl + 'api/PSEApplication/GetEducationPlan/' + id;
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
      return this.httpClient.get<any>(url, { headers });
    }

  
    getCourseList(): Observable<any[]> {
      const url = environment.firstNation.apiUrl + 'api/PSEApplication/GetCourses/' ;
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
      return this.httpClient.get<any[]>( url, { headers });
    }

    
    searchCourse(model: PseFindCourseModel): Observable<any> {
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
      return this.httpClient.post<any>(environment.firstNation.apiUrl + 'api/PSEApplication/SearchCourse', JSON.stringify(model), { headers });
    }


    
    
    getCoursesPlan(id: number): Observable<any> {
      const url = environment.firstNation.apiUrl + 'api/PSEApplication/GetCoursesPlan/' + id;
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
      return this.httpClient.get<any>(url, { headers });
    }


    saveCoursesPlan(model: PseCoursePlanModel[]): Observable<any> {
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
      return this.httpClient.post<any>(
        environment.firstNation.apiUrl + 'api/PSEApplication/SaveCoursesPlan', JSON.stringify(model), { headers });
    }

    
    saveSupportHistory(model:  PseSupportHistoryModel[]): Observable<any> {
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
      return this.httpClient.post<any>(
        environment.firstNation.apiUrl + 'api/PSEApplication/SaveSupportHistory', JSON.stringify(model), { headers });
    }

    getSupportHistory(id: number): Observable<any> {
      const url = environment.firstNation.apiUrl + 'api/PSEApplication/GetSupportHistory/' + id;
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
      return this.httpClient.get<any>(url, { headers });
    }

    
    saveDocuments(model:  PseLoadDocumentModel[]): Observable<any> {
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
      return this.httpClient.post<any>(
        environment.firstNation.apiUrl + 'api/PSEApplication/SaveDocuments', JSON.stringify(model), { headers });
    }

    getDocuments(id: number): Observable<any> {
      const url = environment.firstNation.apiUrl + 'api/PSEApplication/GetDocuments/' + id;
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
      return this.httpClient.get<any>(url, { headers });
    }


    getBankInfo(id: number): Observable<any> {
      const url = environment.firstNation.apiUrl + 'api/PSEApplication/GetBankInfo/' + id;
      //console.log(url);
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
      return this.httpClient.get<any>(url, { headers });
    }


   
    getInstitutions(): Observable<any[]> {
      const url = environment.firstNation.apiUrl + 'api/PSEApplication/GetInstitutions/' ;
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
      return this.httpClient.get<any[]>( url, { headers });
    }

/*
    getIfApplicationHasCurrentBD(id: number): Observable<any> {
      const url = environment.firstNation.apiUrl + 'api/PSEApplication/GetIfApplicationHasFundingBD/' + id;
      const headers = new HttpHeaders().set('SOCIALAPIKEY', this.keyManager.getKey());
      return this.httpClient.get<any>(url, { headers });
    }
  */

    getIfApplicationHasCurrentBD(id: number): Observable<any[]> {
      const url = environment.firstNation.apiUrl + 'api/PSEApplication/GetIfApplicationHasFundingBD/' + id;
      const headers = new HttpHeaders().set('SOCIALAPIKEY', this.keyManager.getKey());
      return this.httpClient.get<any[]>(url, { headers });
    }


//==================== new update =========================

    getStudent(id: number): Observable<any> {
      const url = environment.firstNation.apiUrl + 'api/PSEApplication/GetStudent/' + id;
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
      return this.httpClient.get<any>(url, { headers });
    }


    getPSEApp(id: number): Observable<any> {
      const url = environment.firstNation.apiUrl + 'api/PSEApplication/GetPSEApp/' + id;
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
      return this.httpClient.get<any>(url, { headers });
    }
      

    
    getFundingRate(): Observable<any> {
      const url = environment.firstNation.apiUrl + 'api/PSEApplication/GetFundingRate/' ;
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
      return this.httpClient.get<any>(url, { headers });
    }


    /*
    get_SBD_Suffix(id: number): Observable<any> {
      const url = environment.firstNation.apiUrl + 'api/PSEApplication/Get_SBD_Suffix/' + id;
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
      return this.httpClient.get<any>(url, { headers });
    }
    */

    saveFundingRequest(model: PseFundingModel): Observable<any>{
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
      return this.httpClient.post<any>(
        environment.firstNation.apiUrl + 'api/PSEApplication/SaveFundingRequest', JSON.stringify(model), { headers });
    } 








    getAppShoolYears(id: number): Observable<any> {
      const url = environment.firstNation.apiUrl + 'api/PSEApplication/GetAppShoolYears/' + id;
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
      return this.httpClient.get<any>(url, { headers });
    }
      


    getPseStudentInfo(id: number): Observable<any> {
      const url = environment.firstNation.apiUrl + 'api/PSEApplication/GetPseStudentInfo/' + id;
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
      return this.httpClient.get<any>(url, { headers });
    }



}
