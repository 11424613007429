import { transition, trigger, useAnimation } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { bounceIn } from 'ng-animate';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { DepartmentService } from 'src/app/services/firebase/department.service';

@Component({
  selector: 'app-employee-list',
  templateUrl: './employee-list.component.html',
  styleUrls: ['./employee-list.component.css'],
  animations: [
    trigger('swing', [transition('* => *', useAnimation(bounceIn))])
  ],
})

export class EmployeeListComponent implements OnInit {

  departmentId: any;
  departmentMembers: any;
  isManager = false;
  canManageEmployees = false;

  constructor( private authService: AuthService,
    private router: Router,
    private departmentService: DepartmentService) { }

  ngOnInit() {
    this.departmentId = this.authService.getGlobalUser().departmentId;
    this.isManager = this.authService.getGlobalUser().isManager;
    this.canManageEmployees = this.authService.getGlobalUser().manageDepartmentEmployees;

    this.departmentService.getDepartmentEmployeeList(this.departmentId).valueChanges().subscribe(x => {
      if (x) {
        this.departmentMembers = x;
      }
    });
  }

  addDepartmentMember(event: Event) {
    event.preventDefault();
    localStorage.setItem('isManager', 'No');
    this.router.navigate(['/employee/invite-employee']);
  }

  editDepartmentMember(empID: string, event: Event ) {
    event.preventDefault();
    this.router.navigate(['/employee/account', empID]);
  }
}