import { Injectable } from '@angular/core';
import { ApiKeyManagerService } from './apiKeyManager.Service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EmailModel } from 'src/app/models/emailModel';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { FireMembersService } from '../firebase/fire-members.service';
import { toast } from 'materialize-css';
import { SMSModel } from 'src/app/models/SMSModel';
import { MemberAccountSettingsService } from '../firebase/memberAccountSettings.service';
import { M } from 'angular-mydatepicker';
import { DatePipe } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class EmailService {

  constructor(private httpClient: HttpClient, private keyManager: ApiKeyManagerService,
              private fireMembersService: FireMembersService, private accountSetings : MemberAccountSettingsService) { }

  url = environment.firstNation.apiUrl + 'api/Email/';
  website = environment.firstNation.website;

  sendEmail(model: EmailModel): Observable<any> {
    //console.log(model);
    const headers = new HttpHeaders({SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8'});
    return this.httpClient.post<any>(this.url + 'sendEmail/', JSON.stringify(model), {headers});
  }


  sendBDApprovalNotification(clientName: string) {
    let admins: any[];
    const mail = new EmailModel();
    this.fireMembersService.getMembersList().valueChanges().subscribe(x => {
        if (x) {
        admins  = x.filter(e => e.role === 'Admin');
        mail.emailList = [];
        admins.forEach(o => {
          mail.emailList.push(o.Email);
        });
        mail.subject = 'New B&D Signed And Approved';
        // tslint:disable-next-line: max-line-length
        mail.body = clientName + ' has signed and approved his B&D, please login to your administrator account to manage the payments';
        const headers = new HttpHeaders({SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8'});
        this.httpClient.post<any>(this.url + 'sendEmail/', JSON.stringify(mail), {headers});
      }
    });
  }

  sendIncomeDeclarationNotification(clientName: string, month: string) {
    let admins: any[];
    const mail = new EmailModel();
    this.fireMembersService.getMembersList().valueChanges().subscribe(x => {
        if (x) {
        admins  = x.filter(e => e.role === 'Admin');
        mail.emailList = [];
        admins.forEach(o => {
          mail.emailList.push(o.Email);
        });
        mail.subject = 'New Income Declaration';
        // tslint:disable-next-line: max-line-length
        mail.body = clientName + ' has submited his income statement of ' + month + ' .';
        const headers = new HttpHeaders({SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8'});
        this.httpClient.post<any>(this.url + 'sendEmail/', JSON.stringify(mail), {headers}).subscribe(o => {
          console.log(o);

        });
      }
    });
  }

  sendContactAdminEmail(clientUID: string, email: EmailModel){
    this.fireMembersService.getMembersList().valueChanges().subscribe(x => {
        if (x) {
        let admins: any[];
        let member: any;
        admins  = x.filter(e => e.role === 'Admin');
        member = x.filter(e => e.uid === clientUID);
        email.emailList = [];
        admins.forEach(o => {
          email.emailList.push(o.Email);
        });
        const subject = email.subject;
        const body = email.body;
        email.subject = 'You have a new message from ' + member[0].GivenName + ' ' + member[0].LastName;
        email.body = 'From : ' + member[0].Email + ' <br/> Subject : <strong> ' + subject + ' </strong> <br/> <p>' + body + '</p>';
        const headers = new HttpHeaders({SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8'});
         this.httpClient.post<any>(this.url + 'sendEmail/', JSON.stringify(email), { headers });

      }
    });

  }



  sendNewsNotification(model: any) {
    model.title = model.title.replace(/<[^>]*>/g, '');
    model.body = model.body.replace(/<[^>]*>/g, '');
    const nation = environment.firstNation.displayName;
    let clients: any[];
    const mail = new EmailModel();
    this.fireMembersService.getMembersList().valueChanges().subscribe(x => {
        if (x) {
        clients  = x.filter(e => e.role === 'Member');
        let settings:any;
        mail.emailList = [];
        clients.forEach(o => {

         this.accountSetings.getAccoutSetting(o.uid).valueChanges().subscribe(m => {
           if(m) {
             if(m.length > 0) {
              settings = m[0];
              settings.permissions.forEach(element => {
                if(Object.keys(element)[0] == 'allowEmail'){
                  if(element.allowEmail) {
                    if (o.Email) {mail.emailList.push(o.Email); }
                  }
                }});
             }
           }
         });

        });
        mail.subject =  'Your Nation Shared a News';
        mail.body = '<span style="color:blue; margin-right: 15px;">News Title : </span> ' + model.title + ' <br/> ';
        mail.body += '<span style="color:blue; margin-right: 15px;">Details : </span> ' + model.body + ' <br/> ';

        // tslint:disable-next-line: max-line-length
        mail.body += '<br/><br/> For More Details Please Check The News Page at : <a href="' + environment.firstNation.website + '/news" target="_blank">mynation.app</a>';
        mail.body += '<br/> Thank You';
        console.log(mail);

        const headers = new HttpHeaders({SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8'});
        this.httpClient.post<any>(this.url + 'sendEmail/', JSON.stringify(mail), {headers}).subscribe(o => {
        });
      }
    });
  }

  sendEventNotification(model: any) {
    model.title = model.title.replace(/<[^>]*>/g, '');
    model.body = model.body.replace(/<[^>]*>/g, '');
    const nation = environment.firstNation.displayName;
    let clients: any[];
    const mail = new EmailModel();
    this.fireMembersService.getMembersList().valueChanges().subscribe(x => {
        if (x) {
        clients  = x.filter(e => e.role === 'Member');
        let settings:any;
        mail.emailList = [];
       clients.forEach(o => {

         this.accountSetings.getAccoutSetting(o.uid).valueChanges().subscribe(m => {
           if(m) {
             if(m.length > 0) {
              settings = m[0];
              settings.permissions.forEach(element => {
                if(Object.keys(element)[0] == 'allowEmail'){
                  if(element.allowEmail) {
                    if (o.Email) {mail.emailList.push(o.Email); }
                  }
                }});
             }
           }
         });

        });
        mail.subject = 'New Event On ' + model.eventDate;
        mail.body = '<span style="color:blue; margin-right: 15px;">Event Title : </span> ' + model.title + ' <br/> ';
        mail.body += '<span style="color:blue; margin-right: 15px;">Event Date : </span> ' + model.eventDate + ' <br/> ';
        mail.body += '<span style="color:blue; margin-right: 15px;">Event Place : </span> ' + model.eventPlace + ' <br/> ';
        mail.body += '<span style="color:blue; margin-right: 15px;">Description : </span> ' + model.body + ' <br/> ';

        // tslint:disable-next-line: max-line-length
        mail.body += '<br/><br/> For More Details Please Check The Event Calendar at : <a href="' + environment.firstNation.website + '/member/events-calendar" target="_blank">mynation.app</a>';
        mail.body += '<br/> Thank You';
        const headers = new HttpHeaders({SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8'});
        this.httpClient.post<any>(this.url + 'sendEmail/', JSON.stringify(mail), {headers}).subscribe(o => {
        });
      }
    });
  }

  sendCongratulationEmail(model: EmailModel): Observable<any> {
    const headers = new HttpHeaders({SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8'});
    return this.httpClient.post<any>(this.url + 'sendCongratulationEmail/', JSON.stringify(model), {headers});
  }


  sendFilledApplicationNotification(application: any, employees: any) {
    const mail = new EmailModel();
    mail.emailList = [];
    if (employees && application) {
      mail.emailList = employees;
      mail.subject = `${application} Application filled`;
      mail.body = `The ${application} application has been filled please review.`;
      //console.log("mail     ", mail);
      const headers = new HttpHeaders({SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8'});
      this.httpClient.post<any>(this.url + 'sendEmail/', JSON.stringify(mail), {headers});

    }

  }


  
  sendFundingApprovalNotification(clientName: string) {
    let admins: any[];
    const mail = new EmailModel();
    this.fireMembersService.getMembersList().valueChanges().subscribe(x => {
        if (x) {
        admins  = x.filter(e => e.role === 'Admin');
        mail.emailList = [];
        admins.forEach(o => {
         mail.emailList.push(o.Email);
        });
       // mail.emailList.push('michael@hl360.ca');

        mail.subject = 'New Funding Signed And Approved';
        // tslint:disable-next-line: max-line-length
        mail.body = clientName + ' has signed and approved his funding, please login to your administrator account to manage the payments';
       
       //console.log("Mail 1: ", mail);
        const headers = new HttpHeaders({SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8'});
         this.httpClient.post<any>(this.url + 'sendEmail/', JSON.stringify(mail), {headers});
        //console.log("Mail 2: ", mail);
      }
    });
  }



  
  sendSubmitApplicationNotification(email: string, firstname: string) {
    const mail = new EmailModel();
    mail.emailList = [];
    mail.emailList.push(email);
   
    mail.subject = 'Application Submitted';
    mail.body = 'Thank you for sending your application ' + firstname + ', Our business hours are Monday - Friday, 8:00 AM to 5:00 PM,  we will notify when your application is processed';
    //console.log("mail     ", mail);
    const headers = new HttpHeaders({SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8'});
    return this.httpClient.post<any>(this.url + 'sendEmail/', JSON.stringify(mail), {headers});
  }


  sendApplicationNotificationToAdmin(student: string, emaillist: string[]) {
    
    var mail = new EmailModel();
    mail.emailList = emaillist;
    const datePipe = new DatePipe('en-US');
    var dd =   datePipe.transform(new Date(), 'dd/MM/yyyy') ;
    mail.subject = 'New Application Submitted';
    mail.body =  'A PSE Application was submitted by ' + student + ' on ' + dd + ', Please review.';  

    const headers = new HttpHeaders({SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8'});
    return this.httpClient.post<any>(this.url + 'sendEmail/', JSON.stringify(mail), {headers});
    
  }




  
  sendSubmitFundingRequestNotification(email: string, firstname: string) {
    const mail = new EmailModel();
    mail.emailList = [];
    mail.emailList.push(email);
   
    mail.subject = 'Funding Request Submitted';
    mail.body = 'Thank you for sending your funding request application ' + firstname + ', Our business hours are Monday - Friday, 8:00 AM to 5:00 PM,  The administrator will review your reqeust and get back to you shortly';
    //console.log("mail     ", mail);
    const headers = new HttpHeaders({SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8'});
    return this.httpClient.post<any>(this.url + 'sendEmail/', JSON.stringify(mail), {headers});
  }


  
  sendFundingRequestNotificationToAdmin(student: string, emaillist: string[], request_amount: string) {
    console.log("emails: ", emaillist);
    var mail = new EmailModel();
    mail.emailList = emaillist;
    const datePipe = new DatePipe('en-US');
    var dd =   datePipe.transform(new Date(), 'dd/MM/yyyy') ;
    mail.subject = 'New Funding Request Submitted';
    mail.body =  'A PSE Funding Request Application was submitted by ' + student + ' on ' + dd + ', Please review.' + ' <br/> ' + request_amount;  

    const headers = new HttpHeaders({SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8'});
    return this.httpClient.post<any>(this.url + 'sendEmail/', JSON.stringify(mail), {headers});
    
  }



}
