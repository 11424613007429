<div class="row center-align">
  <h5><strong>Election Result </strong></h5>
</div>
<div class="row col s12">
  <div class="col m1 l2"></div>
  <div class="col s12 m10 l8">
      <div>
          <app-elections-result></app-elections-result>
      </div>
      <div class="row col s12">
          <button [ngClass]="'col s12 btn waves-effect ' + themeColor" (click)="backClicked()">
              <i class="material-icons left">arrow_back_ios</i> Back To Previous Page
          </button>
      </div>
  </div>
  <div class="col m1 l2"></div>
</div>
