import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DepartmentService } from 'src/app/services/firebase/department.service';
import { toast } from 'materialize-css';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { DataService } from 'src/app/services/data.service';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-department-info',
  templateUrl: './department-info.component.html',
  styleUrls: ['./department-info.component.css']
})

export class DepartmentInfoComponent implements OnInit {
  themeColor = environment.appTheme.themeColor;
  model: any;
  departmentForm: UntypedFormGroup;
  postalCode = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
  mobilePattern = /^\s*([\(])[[0-9]{2}\d{1}[\)]\s*[\-]?[\.]?\s*\d{3}\s*[\-]?[\.]?\s*\d{4}$/;
  public postalCodeMask = [/[A-Za-z]/, /[0-9]/, /[A-Za-z]/, ' ', /[0-9]/, /[A-Za-z]/, /[0-9]/];
  public phoneNumberMask = ['(', /[0-9]/, /[0-9]/, /[0-9]/, ')', ' ',
    /[0-9]/, /[0-9]/, /[0-9]/, ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  departmentId: any;
  isManager = false;
  canUpdateDepInfo = false;

  selectedColor = 'black-text $';
  colorPicker: any[];

  constructor(private authService: AuthService,
    private location: Location,
    private departmentService: DepartmentService,
    private formBuilder: UntypedFormBuilder,
    private dataService: DataService) {
    this.colorPicker = this.dataService.getColors();
  }

  ngOnInit() {

    this.departmentId = this.authService.getGlobalUser().departmentId;
    this.isManager = this.authService.getGlobalUser().isManager;
    this.canUpdateDepInfo = this.authService.getGlobalUser().manageDepartmentInfo;

    this.departmentForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      shortName: [''],
      postal_code: ['', [Validators.pattern(this.postalCode)]],
      phone: ['', [Validators.pattern(this.mobilePattern)]],
      fax: ['', [Validators.pattern(this.mobilePattern)]],
      address: [''],
      mandate: ['']
    });

    this.departmentService.getDepartmentByID(this.departmentId).valueChanges().subscribe(x => {
      if (x) {
        this.model = x;
        this.selectedColor = this.model.name.substring(
          this.model.name.lastIndexOf('=') + 2,
          this.model.name.lastIndexOf('$') - 1,
        );
        this.model.name = this.model.name.replace(/<[^>]*>/g, '');

        this.departmentForm.setValue({
          name: this.model.name,
          shortName: (x.shortName) ? x.shortName : '',
          postal_code: x.postal_code,
          phone: x.phone,
          fax: x.fax,
          address: x.address,
          mandate: x.mandate
        });

        setTimeout(() => {
          M.updateTextFields();
          M.textareaAutoResize($('#mandate'));
          $('.dropdown-trigger').dropdown();
        }, 25);
      }
    });
  }

  ChangeColor(color: string) {
    this.selectedColor = color;
  }

  get name() { return this.departmentForm.get('name'); }
  get postal_code() { return this.departmentForm.get('postal_code'); }
  get phone() { return this.departmentForm.get('phone'); }
  get address() { return this.departmentForm.get('address'); }
  get mandate() { return this.departmentForm.get('mandate'); }

  PostDepartment(value: any) {
    this.model.name = '<span class="' + this.selectedColor + '"><strong>' + value.value.name + '</strong></span>';
    this.model.shortName = value.shortName;
    this.model.phone = value.value.phone;
    this.model.fax = value.value.fax;
    this.model.postal_code = value.value.postal_code;
    this.model.address = value.value.address;
    this.model.mandate = value.value.mandate;

    this.departmentService.UpdateDepartment(this.model).then(x => {
      toast({ html: 'Department Successfully Updated!', classes: 'green' });
    });
  }

  backClicked() {
    this.location.back();
  }

}