import { Component, OnInit } from '@angular/core';
import { Form, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Options } from 'sortablejs';
import { toast } from 'materialize-css';
import { CreateCustomApplicationService } from 'src/app/services/firebase/create-custom-application.service';
import { Router } from '@angular/router';
import { runInThisContext } from 'vm';

declare var $: any;
declare var M: any;
@Component({
  selector: 'app-create-application',
  templateUrl: './create-application.component.html',
  styleUrls: ['./create-application.component.css']
})
export class CreateApplicationComponent implements OnInit {

  application = {
    appName: '',
    appDescription: '',
    logo: '',
    sections: [],
  }
  // colorPicker = [
  //   { name: 'Black', value: 'black-text $' },
  //   { name: 'Light Blue 1', value: 'blue-text text-lighten-2 $' },
  //   { name: 'Light Blue 2', value: 'blue-text text-lighten-1 $' },
  //   { name: 'Blue', value: 'blue-text $' },
  //   { name: 'Dark Blue 1', value: 'blue-text text-darken-1 $' },
  //   { name: 'Dark Blue 2', value: 'blue-text text-darken-2 $' },
  //   { name: 'Dark Blue 3', value: 'blue-text text-darken-3 $' },
  //   { name: 'Light Teal 1', value: 'teal-text text-lighten-2 $' },
  //   { name: 'Light Teal 2', value: 'teal-text text-lighten-1 $' },
  //   { name: 'Teal', value: 'teal-text $' },
  //   { name: 'Dark Teal 1', value: 'teal-text text-darken-1 $' },
  //   { name: 'Dark Teal 2', value: 'teal-text text-darken-2 $' },
  //   { name: 'Dark Teal 3', value: 'teal-text text-darken-3 $' },
  //   { name: 'Light Purple 1', value: 'purple-text text-lighten-2 $' },
  //   { name: 'Light Purple 2', value: 'purple-text text-lighten-1 $' },
  //   { name: 'Purple', value: 'purple-text $' },
  //   { name: 'Dark Purple 1', value: 'purple-text text-darken-1 $' },
  //   { name: 'Dark Purple 2', value: 'purple-text text-darken-2 $' },
  //   { name: 'Dark Purple 3', value: 'purple-text text-darken-3 $' },
  //   { name: 'Light Green 1', value: 'green-text text-lighten-2 $' },
  //   { name: 'Light Green 2', value: 'green-text text-lighten-1 $' },
  //   { name: 'Green', value: 'green-text $' },
  //   { name: 'Dark Green 1', value: 'green-text text-darken-1 $' },
  //   { name: 'Dark Green 2', value: 'green-text text-darken-2 $' },
  //   { name: 'Dark Green 3', value: 'green-text text-darken-3 $' },
  //   { name: 'Light Orange 1', value: 'orange-text text-lighten-2 $' },
  //   { name: 'Light Orange 2', value: 'orange-text text-lighten-1 $' },
  //   { name: 'Orange', value: 'orange-text $' },
  //   { name: 'Dark Orange 1', value: 'orange-text text-darken-1 $' },
  //   { name: 'Dark Orange 2', value: 'orange-text text-darken-2 $' },
  //   { name: 'Dark Orange 3', value: 'orange-text text-darken-3 $' },
  //   { name: 'Light Red 1', value: 'red-text text-lighten-2 $' },
  //   { name: 'Light Red 2', value: 'red-text text-lighten-1 $' },
  //   { name: 'Red', value: 'red-text $' },
  //   { name: 'Dark Red 1', value: 'red-text text-darken-1 $' },
  //   { name: 'Dark Red 2', value: 'red-text text-darken-2 $' },
  //   { name: 'Dark Red 3', value: 'red-text text-darken-3 $' },
  //   { name: 'Light Brown 1', value: 'brown-text text-lighten-2 $' },
  //   { name: 'Light Brown 2', value: 'brown-text text-lighten-1 $' },
  //   { name: 'Brown', value: 'brown-text $' },
  //   { name: 'Dark Brown 1', value: 'brown-text text-darken-1 $' },
  //   { name: 'Dark Brown 2', value: 'brown-text text-darken-2 $' },
  //   { name: 'Dark Brown 3', value: 'brown-text text-darken-3 $' },
  //   { name: 'Light Cyan 1', value: 'cyan-text text-lighten-2 $' },
  //   { name: 'Light Cyan 2', value: 'cyan-text text-lighten-1 $' },
  //   { name: 'Cyan', value: 'cyan-text $' },
  //   { name: 'Dark Cyan 1', value: 'cyan-text text-darken-1 $' },
  //   { name: 'Dark Cyan 2', value: 'cyan-text text-darken-2 $' },
  //   { name: 'Dark Cyan 3', value: 'cyan-text text-darken-3 $' },
  //   { name: 'Light Pink 1', value: 'pink-text text-lighten-2 $' },
  //   { name: 'Light Pink 2', value: 'pink-text text-lighten-1 $' },
  //   { name: 'Pink', value: 'pink-text $' },
  //   { name: 'Dark Pink 1', value: 'pink-text text-darken-1 $' },
  //   { name: 'Dark Pink 2', value: 'pink-text text-darken-2 $' },
  //   { name: 'Dark Pink 3', value: 'pink-text text-darken-3 $' },
  //   { name: 'Light Indigo 1', value: 'indigo-text text-lighten-2 $' },
  //   { name: 'Light Indigo 2', value: 'indigo-text text-lighten-1 $' },
  //   { name: 'Indigo', value: 'indigo-text $' },
  //   { name: 'Dark Indigo 1', value: 'indigo-text text-darken-1 $' },
  //   { name: 'Dark Indigo 2', value: 'indigo-text text-darken-2 $' },
  //   { name: 'Dark Indigo 3', value: 'indigo-text text-darken-3 $' },
  // ];
  statement = "I declare that the above information to be true, and I authorize the Administrative Authority."
  fieldsChecked = false;
  selectFieldType = [];
  checkBoxFieldType = [];
  selectOptionValues = [];
  selectedColor = 'black-text $';
  order = 1;
  addSection = false;
  addFields = false;
  fields: any[];
  selectedFields: any[];
  currentSection = 0;
  currentField = 0;
  appCreated = false;
  selectSelected = false;
  anotherOptions = false;
  optionAdded = [];
  iconNotSelected = [];
  showFields = [];
  field = 0;
  fieldTypes = ['Input', 'Select', 'CheckBox', "Radio Button", "Text Box"];
  dataTypes = ['text', 'number', 'date', 'tel'];
  icons = ['person', 'tag', 'add_location', 'people', 'location_on', 'location_city', 'mail', 'house', 'phone', 'phone_iphone', 'today', 'apps'];
  selectOptions = ['maritalStatus', 'provinces', 'bandNames'];
  fieldOrder = 0;

  constructor(private formBuilder: UntypedFormBuilder, private CreateApplicationService: CreateCustomApplicationService, private router: Router) { }

  form: UntypedFormGroup;

  ngOnInit() {
    this.fields = [];
    this.fields.push({ name: 'First Name', checked: false, form_control: 'first_name', field_type: 'Input', select_options: [], data_type: 'text', icon: 'person', required: true, textMask: false });
    this.fields.push({ name: 'Last Name', checked: false, form_control: 'last_name', field_type: 'Input', select_options: [], data_type: 'text', icon: 'person', required: true, textMask: false });
    this.fields.push({ name: 'Indian Status No', checked: false, form_control: 'isn', field_type: 'Input', select_options: [], data_type: 'text', icon: 'tag', required: false, textMask: 'isn' });
    this.fields.push({ name: 'Band Name', checked: false, form_control: 'band_name', field_type: 'Select', select_options: 'Band Names', data_type: 'text', icon: 'add_location', required: false, textMask: false });
    this.fields.push({ name: 'Marital Status', checked: false, form_control: 'marital_status', field_type: 'Select', select_options: 'Marital Stauts', data_type: 'text', icon: 'people', required: true, textMask: false });
    this.fields.push({ name: 'Province', checked: false, form_control: 'province', field_type: 'Select', select_options: 'Provinces', data_type: 'text', icon: 'add_location', required: true, textMask: false });
    this.fields.push({ name: 'Address', checked: false, form_control: 'address', field_type: 'Input', select_options: [], data_type: 'text', icon: 'location_on', required: true, textMask: false });
    this.fields.push({ name: 'City', checked: false, form_control: 'city', field_type: 'Input', select_options: [], data_type: 'text', icon: 'location_city', required: true, textMask: false });
    this.fields.push({ name: 'Postal Code', checked: false, form_control: 'postal_code', field_type: 'Input', select_options: [], data_type: 'text', icon: 'mail', required: true, textMask: 'postalCode' });
    this.fields.push({ name: 'Mailing Address', checked: false, form_control: 'mailing_address', field_type: 'Input', select_options: [], data_type: 'text', icon: 'mail_outline', required: true, textMask: false });

    this.fields.push({ name: 'House Number', checked: false, form_control: 'house_number', field_type: 'Input', select_options: [], data_type: 'text', icon: 'house', required: false, textMask: false });
    this.fields.push({ name: 'Home Number', checked: false, form_control: 'home_number', field_type: 'Input', select_options: [], data_type: 'tel', icon: 'phone', required: false, textMask: false });
    this.fields.push({ name: 'Mobile Number', checked: false, form_control: 'mobile', field_type: 'Input', select_options: [], data_type: 'tel', icon: 'phone_iphone', required: false, textMask: false });
    this.fields.push({ name: 'Health Care Number', checked: false, form_control: 'health_care_no', field_type: 'Input', select_options: [], data_type: 'number', icon: 'tag', required: false, textMask: false });

    this.fields.push({ name: 'Date of Birth', checked: false, form_control: 'dob', field_type: 'Input', select_options: [], data_type: 'date', icon: 'today', required: true, textMask: false });
    this.fields.push({ name: 'SIN', checked: false, form_control: 'sin', field_type: 'Input', select_options: [], data_type: 'text', icon: 'apps', required: false, textMask: 'sin' });
   
    this.form = this.formBuilder.group({
      name: ['', [Validators.required]],
      description: [''],
      logo: [''],
      sections: this.formBuilder.array([this.newSection()]),
    });

    setTimeout(() => {
      M.updateTextFields();
      $('.dropdown-trigger').dropdown();
      $('.fixed-action-btn').floatingActionButton();
      // $('.fixed-action-btn').floatingActionButton({
      //   direction: 'left',
      //   toolbarEnabled: true
      // });
      $('.tooltipped').tooltip();
      $('select').formSelect();
      $('#statement').val(this.statement);
      M.textareaAutoResize($('#statement'));
    }, 25);
  }

  get appName() { return this.form.get('name'); }
  get sectionControls() { return this.form.get('sections') as UntypedFormArray; }
//get fieldControls() { return this.form.get('section.fields') as FormArray; }

  getSectionFields(s) {
    const section = this.sectionControls.controls[s] as UntypedFormGroup;
    const fields = section.controls.fields as UntypedFormArray;
    return fields
  }

  getFieldOptions(s, f) {
    const fields = this.getSectionFields(s);
    const field = fields.controls[f] as UntypedFormGroup;
    const options = field.get('select_options').value;
    return options;
  }

  get sectionName() { return `section${this.order}` };
  get fieldName() {
    this.field += 1;
    return `field${this.field}`;
  }

  options: Options = {
    animation: 150,
    handle: '.handle',
    onEnd: (evt) => {
      // const sections = this.form.get('sections') as FormArray;
      // const section = sections.controls[this.currentSection] as FormGroup;
      // const fields = section.controls.fields as FormArray;
      // // const section = (<FormArray>this.form.controls['sections']).at[this.currentSection];
      // // const fields: FormArray = 
      // const field = fields.controls[evt.oldIndex];
      // fields.removeAt(evt.oldIndex);
      // fields.insert(evt.newIndex, field);

      // (<FormArray>this.form.controls['sections']).at(this.currentSection).patchValue(section);
      // this.form.updateValueAndValidity();
      // this.getSectionFields(this.currentSection).setValue(fields);
      //this.getSectionFields(this.currentSection).updateValueAndValidity();

      //const field = fields.controls[evt.oldIndex];
      // fields.removeAt(evt.oldIndex);
      //   fields.insert(evt.newIndex, field);
      //     const section = this.sectionControls.controls[this.currentSection] as FormGroup;
      // //   console.log(fields);
      // // console.log(this.form);

    //  const oldField = (<FormArray>(<FormGroup>(<FormArray>this.form.controls['sections']).at(this.currentSection)).controls['fields']).at(evt.oldIndex);
    //  (<FormArray>(<FormGroup>(<FormArray>this.form.controls['sections']).at(this.currentSection)).controls['fields']).removeAt(evt.oldIndex);
    //  (<FormArray>(<FormGroup>(<FormArray>this.form.controls['sections']).at(this.currentSection)).controls['fields']).insert(evt.newIndex,oldField);
    //  (<FormArray>(<FormGroup>(<FormArray>this.form.controls['sections']).at(this.currentSection)).controls['fields']).updateValueAndValidity();
     
    //  console.log(this.form);
     
    }
  }

  handleFileInput(files: FileList) {
    if(files){
      if (!this.isImage(files[0])) {
        alert('Invalid Document Type. \n Only Images Are Allowed.');
        return;
      }
      this.getBase64(files[0]);

    }
  }

  getBase64(file) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.application.logo = reader.result as string;
      // console.log(this.application.logo);
    };
    reader.onerror = (error) => {
      console.log('Error: ', error);
    };
  }

  isImage(file: File): boolean {
    return /^image\//.test(file.type);
  }

  newSection() {
    return this.formBuilder.group({
      sec_title: ['', [Validators.required]],
      section_name: [this.sectionName, [Validators.required]],
      statement: [this.statement],
      order: [this.order],
      fields: this.formBuilder.array([]),
    });
  }

  onAddSection() {
    this.showFields[this.currentSection] = false;
    this.addSection = true;
    const sections = this.sectionControls;
    this.order += 1;
    sections.push(this.newSection());
    this.selectSelected = false;
    setTimeout(() => {
      M.updateTextFields();
      $('.dropdown-trigger').dropdown();
      $('.fixed-action-btn').floatingActionButton();
      $('.tooltipped').tooltip();
      $('select').formSelect();
    }, 25);
  }

  sectionSelected(index) {
    this.currentSection = index;
  }

  expandSection(seciton) {
    this.showFields[seciton] = !this.showFields[seciton];
    setTimeout(() => {
      M.updateTextFields();
      $('.dropdown-trigger').dropdown();
      $('.fixed-action-btn').floatingActionButton();
      $('.tooltipped').tooltip();
      $('select').formSelect();
    }, 25);
  }

  cardSelected(section) {
    this.currentSection = section;
  }

  newField() {
    return this.formBuilder.group({
      name: ["", [Validators.required]],
      form_control: [this.fieldName, Validators.required],
      default_value: [],
      field_type: ['Input', Validators.required],
      select_options: [],
      data_type: ['text', Validators.required],
      icon: [],
      color: [],
      required: [false],
      textMask: [false],
      methods: [],
      //validation: this.formBuilder.group([]),
    });
  }

  selectedField(values: any) {
    return this.formBuilder.group({
      name: [values.name],
      form_control: [values.form_control],
      default_value: [''],
      field_type: [values.field_type],
      select_options: [values.select_options],
      data_type: [values.data_type],
      icon: [values.icon],
      //color: [],
      required: [values.required],
      textMask: [values.textMask],
      methods: [],
      //validation: this.formBuilder.group([]),
    });
  }

  onAddField() {
    this.addFields = true;
    const section = this.sectionControls.controls[this.currentSection] as UntypedFormGroup;
    const fields = section.controls.fields as UntypedFormArray;
    fields.push(this.newField());
    this.showFields[this.currentSection] = true;
    setTimeout(() => {
      M.updateTextFields();
      $('.dropdown-trigger').dropdown();
      $('.fixed-action-btn').floatingActionButton();
      $('.tooltipped').tooltip();
      $('select').formSelect();
    }, 25);
  }

  ChangeColor(color: string) {
    this.selectedColor = color;
  }

  onAddFields() {
    this.addFields = true;
    this.selectedFields = this.fields.filter(field => field.checked == true);
    this.selectedFields = this.selectedFields.sort((a, b) => a.order > b.order && 1 || -1);
    if (this.sectionControls.controls.length == 0) {
      this.onAddSection();
    }
    const section = this.sectionControls.controls[this.currentSection] as UntypedFormGroup;
    const fields = section.controls.fields as UntypedFormArray;
    this.selectedFields.forEach(field => {
      fields.push(this.selectedField(field));
      field.checked = false;
    });
    this.showFields[this.currentSection] = true;
    setTimeout(() => {
      M.updateTextFields();
      $('.fixed-action-btn').floatingActionButton();
      $('.tooltipped').tooltip();
      $('select').formSelect();
    }, 25);
    toast({ html: 'Selected Fields are Successfully Added!', classes: 'green' });
    this.fieldsChecked = false;
  }

  fieldChecked(index) {
    const field = this.fields[index];
    field.checked = !field.checked;
    if (field.checked) {
      this.fieldOrder += 1;
      field.order = this.fieldOrder;
    } else {
      field.order = null;
    }
    this.fields[index] = field;
    if ((this.fields.filter(field => field.checked == true)).length == 0) {
      this.fieldsChecked = false;
    } else {
      this.fieldsChecked = true;
    }
  }

  dataTypeSelected(value, f) {
    // const field = this.getSectionFields(this.currentSection).controls[0] as FormGroup;
    // //console.log(field.get('textMask').value);
    // if(value == 'date'){
    //   field.get('textMask').patchValue(JSON.stringify(this.dateMask));
    // }if(value == 'tel'){
    //   field.get('textMask').patchValue('phoneNumberMask');
    // }
  }

  fieldTypeSelected(value, f) {
    if (value == 'Select') {
      this.selectFieldType[f] = true;
    } else if (value == 'CheckBox') {
      console.log('checkBox');
      this.checkBoxFieldType[f] = true;
    } else {
      this.selectFieldType[f] = false;
      this.checkBoxFieldType[f] = false;
    }


    setTimeout(() => {
      $('select').formSelect();
      $('.chips').chips();
      $('.chips-placeholder').chips({
        placeholder: 'Add options',
        secondaryPlaceholder: '+Add',
        chipData: this.selectOptionValues
      });
    }, 25);
  }

  // addotherOption() {
  //   this.anotherOptions = true;
  //   setTimeout(() => {
  //     $('.chips').chips();
  //     $('.chips-placeholder').chips({
  //       placeholder: 'Add options',
  //       secondaryPlaceholder: '+Add',
  //       chipData: this.selectOptionValues
  //     });
  //     // $('.chips').on('chip.add', function(e, chip){
  //     //   console.log('you have the added chip here');
  //     // });

  //   }, 25);
  // }

  onIconSelected(index) {
    this.iconNotSelected[index] = false;
  }

  addOption(fieldIndex) {
    let values = M.Chips.getInstance($('.chips')).chipsData;
    console.log(values);
    let options = [];
    values.forEach(value => {
      options.push(value.tag);
    });
    this.selectOptionValues = options;
    console.log(this.selectOptionValues);
    const fields = this.getSectionFields(this.currentSection);
    const field = fields.controls[fieldIndex] as UntypedFormGroup;
    field.get('select_options').patchValue(this.selectOptionValues);
    this.optionAdded[fieldIndex] = true;
  }

  deleteField(fieldIndex) {
    const section = this.sectionControls.controls[this.currentSection] as UntypedFormGroup;
    const fields = section.controls.fields as UntypedFormArray;
    fields.removeAt(fieldIndex);

    console.log(this.fieldOrder);
    //const formGroup = fields.controls[fieldIndex] as FormGroup;
    // const fieldControl = formGroup.get('form_control').value;
    // const field = this.fields.filter(field => field.form_control == fieldControl);
    // const index = this.fields.indexOf(field[0]);
    // this.fields[index].checked = false;

    // const field = this.fields[fieldIndex];
    // field.checked = !field.checked;
    // this.fields[fieldIndex] = field;
  }

  deleteSection(sectionIndex) {
    const sections = this.sectionControls as UntypedFormArray;
    console.log(sections);
    sections.removeAt(sectionIndex);
  }

  // etSectionName(formControlName) {
  //   const section = this.appData.sections.find(section => section.section_name == formControlName);
  //   const sectionName =  section.sec_title.replace(/\s+/g, '-');
  //   console.log(sectionName);
  //   return sectionName;
  // }

  createApplication(values) {
    this.application.appName = values.name;
    this.application.appDescription = values.description;
    this.application.sections = values.sections;
    this.CreateApplicationService.saveApplication(this.application).then(x=> {
      toast({ html: 'Application Successfully Created!', classes: 'green' });
    });
    this.appCreated = true;
    //console.log(this.application);
  }

  goToApplication() {
    this.router.navigate(['/customizable-form', this.application.appName]);
  }

  showForm() {
    console.log(this.form);
  }

}
