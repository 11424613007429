import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-poll-result',
  templateUrl: './poll-result.component.html',
  styleUrls: ['./poll-result.component.css']
})
export class PollResultComponent implements OnInit {
  themeColor = environment.appTheme.themeColor;
  constructor( private location: Location) { }

  ngOnInit() {
  }

  backClicked() {
    this.location.back();
  }

}
