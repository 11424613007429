import { Component, Input, OnInit } from '@angular/core';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-gov-chief-detail-shared',
  templateUrl: './gov-chief-detail-shared.component.html',
  styleUrls: ['./gov-chief-detail-shared.component.css']
})
export class GovChiefDetailSharedComponent implements OnInit {

  @Input() model: any;
  @Input() privacy: any;
  themeColor = environment.appTheme.themeColor;
  chiefTitle: any;

  constructor(public appSettingsService: AppSettingsService) {
    this.chiefTitle = this.appSettingsService.getGovTitle().chiefTitle ? this.appSettingsService.getGovTitle().chiefTitle : 'Chief';
  }

  ngOnInit() {
  }

  public getInitials(firstName: any, lastName: any) {
    return firstName.substring(0, 1) + lastName.substring(0, 1);
  }
}