import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, AbstractControl, UntypedFormControl } from '@angular/forms';
import { MemberModel } from '../../../models/memberModel';
import { MemberService } from 'src/app/services/api/member.service';
import { toast } from 'materialize-css';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { MobileAppConfigService } from 'src/app/services/api/mobile-app-config.service';
import { environment } from 'src/environments/environment';

import { FireMembersService } from 'src/app/services/firebase/fire-members.service';
import { EmailModel } from 'src/app/models/emailModel';
import { EmailService } from 'src/app/services/api/email.service';
import { RegistrationRequestService } from 'src/app/services/api/registration-request.service';

function passwordConfirming(c: AbstractControl): any {
  if (!c.parent || !c) { return; }
  const pwd = c.parent.get('createPassword');
  const cpwd = c.parent.get('confirmPassword');

  if (!pwd || !cpwd) { return; }
  if (pwd.value !== cpwd.value) {
    return { invalid: true };
  }
}

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})

export class RegisterComponent implements OnInit {

  // tslint:disable-next-line: max-line-length
  constructor(private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private memberService: MemberService,
    private authService: AuthService,
    private fireMemberService: FireMembersService,
    private emailService: EmailService,) { }

  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  loading = false;
  applicantID: string = null;
  member: MemberModel = null;
  firebaseMember: any;
  hasEmployeeAccount = false;
  observer: any;
  accountExists = false;
  accountLinked = false;

  step1 = true;
  minDate = new Date(new Date().getFullYear() - 100, 0, 1);
  maxDate = new Date(new Date().getFullYear() - 18, 0, 1);
  verificationForm: UntypedFormGroup;

  get sin() { return this.verificationForm.get('sin'); }
  get isn() { return this.verificationForm.get('isn'); }
  get dob() { return this.verificationForm.get('dob'); }
  get question() { return this.verificationForm.get('question'); }
  step2 = false;
  step3 = false;
  registrationForm: UntypedFormGroup;
  get createPassword() { return this.registrationForm.get('createPassword'); }
  get confirmPassword() { return this.registrationForm.get('confirmPassword'); }

  slicedIsnNumber: any;
  displayedISN: any;
  slicedSinNumber: any;
  displayedSIN: any;

  contactForm: UntypedFormGroup;
  contact = false;
  adminEmail = 'mihret@myNation.app';

  ngOnInit() {
    this.loading = true;
    this.route.paramMap.subscribe(params => {
      if (params.get('id')) {
        const id = + params.get('id');
        
        this.memberService.getMember(id).subscribe(data => {
          if (data) {
          
            this.member = data[0];
            if (!this.member.PWA_Verify_SIN && !this.member.PWA_Verify_ISN && !this.member.PWA_Secret_Question) {
              this.step1 = false;
              this.step2 = true;
            }

            this.verificationForm = this.fb.group({
            });

            if (this.member.PWA_Verify_SIN) {
              this.slicedSinNumber = this.member.SIN.slice(-3);
              this.displayedSIN = this.member.SIN.charAt(0)+'XXXXX' ;
              this.verificationForm.addControl('sin', new UntypedFormControl(this.displayedSIN, [Validators.required, Validators.minLength(9)]  ));
            }
            if (this.member.PWA_Verify_ISN) {
              this.slicedIsnNumber = this.member.IndianStatusNo.slice(-3);
              this.displayedISN = this.member.IndianStatusNo.charAt(0)+'XXXX' ;
              this.verificationForm.addControl('isn', new UntypedFormControl(this.displayedISN, [Validators.required, Validators.minLength(10)]  ));
            }
            if (this.member.PWA_Secret_Question) {
              this.verificationForm.addControl('question', new UntypedFormControl('', Validators.required));
            }

            this.observer = this.fireMemberService.getMemberByAppliciantId(id).valueChanges().subscribe(x => {
              if (x && x.length > 0) {
                this.firebaseMember = x[0];
                if (this.firebaseMember.role == 'Employee') {
                  if (this.firebaseMember.isMember) {
                    this.step1 = false;
                    this.step2 = false;
                    this.accountExists = true;
                    return;
                  } else {
                    this.hasEmployeeAccount = true;
                    $('.modal').modal({ dismissible: false });
                    $('#linkAccount').modal('open');
                    return;
                  }
                }
              }
              this.loading = false;
            });

            setTimeout(() => {
              M.updateTextFields();
            }, 25);
          }
        });
      }
    });


    this.registrationForm = this.fb.group({
      createPassword: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required, passwordConfirming]],
    });

    setTimeout(() => {
      $('.modal').modal();
    }, 25);
  }

  verification(data: any) {
    if (this.member.PWA_Verify_SIN) {
      if (Number(data.value.sin.slice(-3)) !== Number(this.member.SIN.slice(-3))) {
        // tslint:disable-next-line: max-line-length
        toast({ html: '<span> <i class="material-icons left">error_outline</i> Invalid Social Insurance Number ! </span>', classes: 'red' });
        return;
      }
    }

    if (this.member.PWA_Verify_ISN) {
      if (Number(data.value.isn.slice(-5)) !== Number(this.member.IndianStatusNo.slice(-5))) {
        toast({ html: '<span> <i class="material-icons left">error_outline</i> Invalid Indian Status Number ! </span>', classes: 'red' });
        return;
      }
    }

    if (this.member.PWA_Secret_Question) {
      const answer = this.member.PWA_Secret_Answer;
      const response = data.value.question;
      if (response.replace(/\s/g, '').toLowerCase() !== answer.replace(/\s/g, '').toLowerCase()) {
        toast({ html: '<span> <i class="material-icons left">error_outline</i> Invalid Answer ! </span>', classes: 'red' });
        return;
      }
    }

    toast({ html: '<span> <i class="material-icons left">check_circle_outline</i> Verification Success. </span> ', classes: 'green' });
    this.step1 = false;
    this.step2 = true;
  }

  register(data: any) {
    this.authService.emailSignUp(this.member, data.value.createPassword).then(() => {
      {
        this.memberService.updateInvitationStatus(this.member.applicantID).subscribe();
      }
    }).catch(error => {
      toast({ html: error.message, classes: 'red' });
    });
  }

  linkAccount() {
    //this.firebaseMember.role = 'Employee';
    this.firebaseMember.isMember = true;
    this.fireMemberService.updateAccountRole(this.firebaseMember).then(x => {
      this.observer.unsubscribe();
      this.step1 = false;
      this.step2 = false;
      this.accountExists = false;
      this.accountLinked = true;

      $('.modal').modal('close');
      toast({ html: "Your account has been linked. You can now access both accounts.", classes: 'green' });
    });
  }

  contactAdmin() {
    this.contactForm = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      subject: ['', [Validators.required]],
      body: ['', [Validators.required]],
    });
    this.contact = true;
    setTimeout(() => {
      $('.modal').modal();
      $('#emailModal').modal('open');
    }, 25);
  }

  get contactfirstName() { return this.contactForm.get('firstName'); }
  get contactlastName() { return this.contactForm.get('lastName'); }
  get contactemail() { return this.contactForm.get('email'); }
  get subject() { return this.contactForm.get('subject'); }
  get body() { return this.contactForm.get('body'); }

  sendEmail(model: any) {
    const mail = new EmailModel();
    mail.emailList = [this.adminEmail];
    mail.subject = 'You have a new message from ' + model.firstName + ' ' + model.lastName;
    mail.body = 'From : ' + model.email + ' <br/> Subject : <strong> ' + model.subject + ' </strong> <br/> <p>' + model.body + '</p>';
    this.emailService.sendEmail(mail).subscribe(x => {
      if (x.Status && x.Status == 'OK') {
        $('#emailModal').modal('close');
        this.accountExists = false;
        this.step3 = true;
      }
    })
  }

  dateToShortFormat(value: Date) {
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const day = value.getDate();
    const monthIndex = value.getMonth();
    const year = value.getFullYear();
    return day + '/' + monthNames[monthIndex] + '/' + year;
  }

}