import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { JobOfferService } from 'src/app/services/firebase/joboffer.service';

@Component({
  selector: 'app-job-archive',
  templateUrl: './job-archive.component.html',
  styleUrls: ['./job-archive.component.css']
})

export class JobArchiveComponent implements OnInit {

  themeColor = environment.appTheme.adminThemeColor;
  fontColor = environment.appTheme.adminFontColor;
  joblist: any[];
  hasRows = false;

  constructor( private jobofferService: JobOfferService) { }

  ngOnInit(): void {
    this.jobofferService.getJobOffersList().valueChanges().subscribe(jobs => {
      if (jobs) {
        this.joblist = jobs;     
        this.joblist = this.joblist.filter(e => e.archived === true ); 
        if (this.joblist.length > 0) {this.hasRows = true; }
      }
    });
  }

}