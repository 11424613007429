import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { GovernanceModel } from 'src/app/models/GovernanceModel';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class GovernanceService {

  constructor(private db: AngularFirestore, private router: Router) { }

  contentListRef: AngularFirestoreCollection<any>;
  contentRef: AngularFirestoreDocument<any>;

  // Manage Posts ---------------------------------------------------------------------------------------
  addGovernance(model: GovernanceModel) {
    const newContent = {
      id: this.db.createId(),
      name: model.name,
      chiefs: [],
      councillors: []
    };

    this.db.collection('Governance').doc(newContent.id).set(newContent).then(x => {
      this.router.navigate(['/admin/edit-governance'], { state: { governance_id: newContent.id } });
    });
  }

  getGovernanceList(): AngularFirestoreCollection<any[]> {
    this.contentListRef = this.db.collection("Governance");
    return this.contentListRef;
  }

  getGovernanceByID(id: string): AngularFirestoreDocument<any> {
    this.contentRef = this.db.doc('Governance/' + id);
    return this.contentRef;
  }

  DeleteGovernance(model: any) {
    return this.db.collection('Governance').doc(model.id).delete();
  }

  UpdateGovernanceChief(model: any) {
    const newContent = {
      chiefs: model.chiefs,
    };
    return this.db.collection('Governance').doc(model.id).update(newContent);
  }

  UpdateGovernanceCouncillor(model: any) {
    const obj = {
      councillors: model.councillors
    };
    return this.db.collection('Governance').doc(model.id).update(obj);
  }

  addBoard(model: any) {
    model.id = this.db.createId();
    return this.db.collection('Boards').doc(model.id).set(model);
  }

  updateBoard(model: any) {
    return this.db.collection('Boards').doc(model.id).update(model);
  }

  getBoardById(id: any) {
    this.contentRef = this.db.doc('Boards/' + id);
    return this.contentRef;
  }

  getBoardList() {
    return this.db.collection('Boards');
  }

  deleteBoard(id: any) {
    return this.db.collection('Boards').doc(id).delete();
  }

  getBoardMemberByUserIdAndBoardId(userId: any, boardId: any) {
    return this.db.collection('BoardMembers', ref => ref.where('memberId', '==', userId).where('boardId', '==', boardId));
  }

  geBoardMembersByBoardId(boardId: any) {
    return this.db.collection('BoardMembers', ref => ref.where('boardId', '==', boardId));
  }

  getBoardMemberByUserId(userId: any) {
    return this.db.collection('BoardMembers', ref => ref.where('memberId', '==', userId));
  }

  archiveBoardMember(model: any) {
    const newContent = {
      id: this.db.createId(),
      memberId: model.memberId,
      memberType: model.memberType,
      boardId: model.boardId,
      status: 'Archived'
    };
    this.db.collection('BoardMembers').doc(newContent.id).set(newContent);
  }

  deleteBoardMember(model: any) {
    return this.db.collection('BoardMembers').doc(model.id).delete();
  }

}