import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-modals',
  templateUrl: './modals.component.html',
  styleUrls: ['./modals.component.css']
})

export class ModalsComponent implements OnInit {

  @Input() modalType: string;
  @Input() data: any;
  @Output() cancel: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() confirm: EventEmitter<boolean> = new EventEmitter<boolean>();

  title: string;
  subTitle: string;
  description: string;
  confirmationTitle: string;
  confirmationText: string;
  confirmationText2: string;
  icon: string;
  valueEmitted = false;

  constructor() { }

  ngOnInit(): void {
      if (this.modalType == 'delete') {
        if (this.data) {
          setTimeout(() => {
            $(".modal").modal();
            $("#deleteModal").modal('open');
          }, 25);
        }
    }
  }

  delete() {
    $('.modal').modal('close');
    this.confirm.emit(true);
    this.valueEmitted = true;
  }

  closeModal() {
    $('.modal').modal('close');
    this.cancel.emit(true);
    //$('.modal').modal('close');
    this.valueEmitted = true;
  }


}
