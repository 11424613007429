import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { BandMemberModel } from 'src/app/models/BandMemberModel';
import { MemberService } from 'src/app/services/api/member.service';
import { EmailService } from 'src/app/services/api/email.service';
import * as firebase from 'firebase/app';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AdminDemoRequestService } from 'src/app/services/firebase/admin-demo-request.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { WindowService } from 'src/app/services/window-service/window.service';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { NationSettingsService } from 'src/app/services/api/nation-settings.service';
import { DataService } from 'src/app/services/data.service';
import { createMask } from '@ngneat/input-mask';

declare var $: any;

@Component({
  selector: 'app-demo-signup',
  templateUrl: './demo-signup.component.html',
  styleUrls: ['./demo-signup.component.css']
})

export class DemoSignupComponent implements OnInit {

  buttonType: any;
  themeColor: any;
  defaultThemeColor = environment.appTheme.themeColor;
  model: any;
  confirmationResult: firebase.auth.ConfirmationResult;
  step1 = true;
  step2 = false;
  step3 = false;
  windowRef: any;
  recaptchaVerified = false;
  // @ViewChild('ngOtpInput', { static: false }) ngOtpInput: any;
  otp: any;
  bands: any[];
  provinceBands: any[];
  accountType: any;
  demoRegistrationForm: UntypedFormGroup;

  provinces: any[];
  nationName = environment.firstNation.displayName
  otpConfig = {
    allowNumbersOnly: false,
    length: 6,
    isPasswordInput: false,
    disableAutoFocus: false,
    placeholder: '',
    inputStyles: {
      width: '30px',
      height: '50px'
    }
  };
  // public phoneNumberMask = ['(', /[0-9]/, /[0-9]/, /[0-9]/, ')',
  //   ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  
  phoneInputMask = createMask<string>({
    mask: '(999) 999-9999',
    guide: true,
    showMaskTyped: true,
  });
  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private memberService: MemberService,
    private emailService: EmailService,
    private router: Router,
    private location: Location,
    private adminDemo: AdminDemoRequestService,
    private winService: WindowService,
    private appSettingService: AppSettingsService,
    private nationSettingsService: NationSettingsService,
    private dataService: DataService) {
    if (localStorage.getItem('AccountType')) {
      this.accountType = localStorage.getItem('AccountType');
      localStorage.removeItem('AccountType');
    }
    this.provinces = this.dataService.getProvinceWithCode();
    this.themeColor = this.appSettingService.getAppThemeColor() ? this.appSettingService.getAppThemeColor() : this.defaultThemeColor;
    this.buttonType = this.appSettingService.getButtonType() ? this.appSettingService.getButtonType() : 'rectangularButton';

    this.nationSettingsService.getNationSettings().subscribe(sett => {
      if (sett) {
        let settingsModel = sett[0];
        if (settingsModel.band_name) this.nationName = settingsModel.band_name;
      }
    });

  }

  ngOnInit() {

    if (!this.accountType) {
      this.router.navigate(['/home']);
    }

    this.demoRegistrationForm = this.fb.group({
      userName: ['', [Validators.required]],
      mobileNumber: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
    });
  }

  get userName() { return this.demoRegistrationForm.get('userName'); }
  get mobileNumber() { return this.demoRegistrationForm.get('mobileNumber'); }
  get email() { return this.demoRegistrationForm.get('email'); }

  provinceChange(value: any) {
    this.provinceBands = this.bands.filter(b => value === this.getProvinceCodeFromAddress(b.Address));
    const autocompleteArray = {};
    this.provinceBands.forEach(element => {
      autocompleteArray[element.Band] = null;
    });
    setTimeout(() => {
      $('.autocomplete').autocomplete({
        data: autocompleteArray,
        onAutocomplete: (txt => {
          this.demoRegistrationForm.patchValue({ band: txt });
        }),
      });
    }, 20);

  }

  getProvinceCodeFromAddress(address: any) {
    let addr = address.substring(address.length - 11);
    addr = addr.substring(0, 2);
    return addr;
  }

  registerDemoAccount(val: any) {
    if (this.accountType === 'Admin') {
      this.model = {
        id: '',
        adminID: '',
        FirstName: val.userName,
        LastName: val.userName,
        Email_Address: val.email,
        phone: val.mobileNumber,
        requestDate: new Date(),
        approved: false,
      };
    }

    if (this.accountType === 'Member') {
      this.model = new BandMemberModel();
      this.model.GivenName = val.userName,
        this.model.LastName = val.userName,
        this.model.Email = val.email;
      this.model.cell_phone_num = val.mobileNumber;
      this.model.province = '';
      this.model.band_name = '';
      this.model.role = 'Demo';
    }

    if (this.accountType === 'Admin') {
      this.adminDemo.newAdminRequest(this.model).then(saved => {
        let registrationUrl = environment.firstNation.website;
        registrationUrl += '/auth/login';
        const email = {
          subject: 'Admin Demo Request',
          body: `<p> <strong> You Have a New Admin Demo Request From : </strong></p>
<p> <strong><span style="color:teal"> Username  : </span>` + this.model.FirstName + `   </strong>  </p>
<p><strong><span style="color:teal"> Email : </span> ` + this.model.Email_Address + ` </strong></p>
<p><strong><span style="color:teal"> Mobile : </span> ` + this.model.phone + ` </strong></p>
<p><strong>

Please <a href="`+ registrationUrl + `" target="_blank"> Click Here </a> to login as Admin and manage this request. </strong></p>
<p><strong> Thank you </strong></p>`,
          emailList: [],
          attachments: []
        };
        email.emailList.push('nordeen@mynation.app');
        this.emailService.sendEmail(email).subscribe(
          e => {
            if (e) {
              this.step1 = false;
              this.step3 = true;
            }
          }
        );
      });
    }

    if (this.accountType === 'Member') {
      this.memberService.saveMemberIfNotExist(this.model).subscribe(m => {
        if (m) {
          const id = m;
          let registrationUrl = environment.firstNation.website;
          registrationUrl += '/auth/register/' + id;
          const email = {
            subject: 'Registration Email',
            body: `<p> Good Day <strong style="color: green;"> ` + this.model.GivenName + ` </strong></p>
          <p> Welcome to the myNation mobile platform. </p>
      <p> This email is to confirm your registration as an official member of our demo platform. </p>
      <p> Click the link below to complete your registration by creating your password and choosing your security settings.  </p>
      <br/>          
      <a href="` + registrationUrl + `" target="_blank"> Please Click Here To Complete Registration </a>
      <br/>
      <p>  As a member of myNation you will be able to customize your own profile, chat with other members and stay up to date with news, events and contest updates. 
      <br/>
      You will also notice additional options such as contacting departments, signing and submitting documents, building your resume, searching job boards and more. While these features are not yet available for demo accounts, we encourage you to take a look around and get an idea of how this platform will work for your Nation. 
      </p>
      <p>
     <span style="color: red;"> Please note: </span> If you have signed up to be a part of our upcoming giveaways, you will also be required to have “Allow Emails” and “Allow Notifications” turned on, however these settings can be changed from within your account at any time.
      </p>
      <br/> <br/>          
      <a href="` + registrationUrl + `" target="_blank"> Please Click Here To Complete Registration </a>
      <br/> <p>Thank You & Welcome to myNation</p>`,
            emailList: [],
            attachments: []
          };
          email.emailList.push(this.model.Email);
          this.emailService.sendEmail(email).subscribe(
            e => {
              if (e) {
                this.step1 = false;
                this.step3 = true;
              }
            }
          );
        }
      });
    }
  }

  backClicked() {
    this.location.back();
  }

  memberAccountClick() {
    localStorage.setItem('AccountType', 'Member');
    this.router.navigate(['/auth/request-demo']);
  }

  adminAccountClick() {
    localStorage.setItem('AccountType', 'Admin');
    this.router.navigate(['/auth/request-demo']);
  }
}

function passwordConfirming(c: AbstractControl): any {
  if (!c.parent || !c) { return; }
  const pwd = c.parent.get('createPassword');
  const cpwd = c.parent.get('confirmPassword');

  if (!pwd || !cpwd) { return; }
  if (pwd.value !== cpwd.value) {
    return { invalid: true };
  }
}