import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';


@Injectable({
  providedIn: 'root'
})
export class SessionsMonitorService {

  ipAddress: any;
  ipLocation: any;
  sessionListRef: AngularFirestoreCollection<any>;
  sessionRef: AngularFirestoreDocument<any>;
  currentSession: any;
  lat: any;
  lng: any;

  constructor(
    private http: HttpClient,
    private db: AngularFirestore) {}

  startSession(user_ID) {
  
      // this.authenticatedUser.lastLogin = new Date();
      // this.authenticatedUser.loginCount = (this.authenticatedUser.loginCount) ? this.authenticatedUser.loginCount + 1 : 1;
      // this.db.collection('BandMembers').doc(this.authenticatedUser.uid).set(this.authenticatedUser);

      var options = {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0
      };

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(position => {
            if (position) {
                this.lat = position.coords.latitude;
                this.lng = position.coords.longitude;
             
                this.getIPAddress().subscribe(ip => {
                  if (ip) {
                    this.ipAddress = ip;
          
                    const sessionInfo = {
                      uid: this.db.createId(),
                       userID: user_ID,
                      ipAddress: this.ipAddress.ip,
                      device: this.isMobile(),
                      deviceType: this.deviceType(),
                      // city: this.ipLocation.city,
                      // province: this.ipLocation.region,
                      longitude: position.coords.longitude,
                      latitude: position.coords.latitude,
                      sessionStart: new Date(),
                      sessionEnd: '',
                      activity : []
                    };
                    
                    this.currentSession = sessionInfo;
                    this.db.collection('UserSession').doc(sessionInfo.uid).set(sessionInfo);
                    console.log(sessionInfo);                    
                  }
                });
               // this.getAddress = (this.lat, this.lng)
                console.log(position);
            }
        }, null,options )
    }
  }

  endSession(): any {
    if(this.currentSession) {
      this.currentSession.sessionEnd = new Date();
      return this.db.collection('UserSession').doc(this.currentSession.uid).update(this.currentSession)
        .then(s =>  true );
    } else return true;
    
  }

  newActivity(pageName: any, actionName: any): any {
    if (!this.currentSession) { return; }

    const activity = {
      page: pageName,
      action: actionName,
      date: new Date(),
    };

    this.currentSession.activity.push(activity);
    return this.db.collection('UserSession').doc(this.currentSession.uid).update(this.currentSession)
      .then(s => true);
  }

  getSessionsList(clientID: any): AngularFirestoreCollection<any> {
    this.sessionListRef = this.db.collection('UserSession', ref => ref.where('userID', '==', clientID));
    return this.sessionListRef;
  }

  getSessionsByID(sessionID: any): AngularFirestoreDocument<any> {
    this.sessionRef = this.db.doc('UserSession/' + sessionID);
    return this.sessionRef;
  }

  getAllUsersSessionsList(): AngularFirestoreCollection<any> {
    this.sessionListRef = this.db.collection('UserSession');
    return this.sessionListRef;
  }

  getIPAddress() {
    return this.http.get('https://api.ipify.org/?format=json');
  }

  getIPLocation() {
    return this.http.get('https://ipapi.co/' + this.ipAddress.ip + '/json/');
  }

  isMobile() {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(navigator.userAgent)) {
      return 'Mobile';
    } else {
      return 'Desktop';
    }
  }

  deviceType() {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(navigator.userAgent)) {
      if (/Android/i.test(navigator.userAgent)) { return 'Android'; }
      if (/iPhone/i.test(navigator.userAgent)) { return 'iPhone'; }
      if (/iPad/i.test(navigator.userAgent)) { return 'iPad'; }
      if (/iPod/i.test(navigator.userAgent)) { return 'iPod'; }
      if (/BlackBerry/i.test(navigator.userAgent)) { return 'BlackBerry'; }
    } else { return 'Desktop'; }
  }
}