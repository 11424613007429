import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiKeyManagerService } from './apiKeyManager.Service';

@Injectable({
  providedIn: 'root'
})
export class NationSettingsService {

  constructor(private httpClient: HttpClient, private keyManager: ApiKeyManagerService) { }  
  url = environment.firstNation.apiUrl + 'api/NationSettings/';

  getNationSettings(): Observable<any> {
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
    return this.httpClient.get<any>(this.url + 'GetSettings', { headers });
  }

  getSettings(): Observable<any> {
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
    return this.httpClient.get<any>(this.url + 'GetSettings', { headers });
  }

  updateNationSetting(model): Observable<any>{
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
    return this.httpClient.post<any>(this.url + 'UpdateNationSetting',
     JSON.stringify(model), { headers });
  }

  updateSettingIfExists(model): Observable<any>{
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
    return this.httpClient.post<any>(this.url + 'UpdateSettingIfExist',
     JSON.stringify(model), { headers });
  }
}
 