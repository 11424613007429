<div class="body-h">

  <div class="header-setting b-m-2">
    <div class="col l12 m12 s12">
      <h4 class="">Edit Job Objective </h4>
    </div>
  </div>

  <main class="StickyContent white row">
    <div id="step0" class="col s12 l10">
      <form (ngSubmit)="submitForm(editObjectiveForm.value)" [formGroup]="editObjectiveForm">
        <div class="row card-panel">

          <div class="input-field class col s12">
            <div class="label-input"> Job Objective <strong class="red-text">*</strong> </div>
            <input formControlName="Objective" id="Objective" type="text" maxlength="300" autocomplete="off" require
              class="capitalize validate">
            <div *ngIf="(Objective.dirty || Objective.touched) && Objective.invalid">
              <small *ngIf="Objective.errors.required" class="red-text">The Objective Is Required!
              </small>
            </div>
          </div>
        </div>

        <div class="section"></div>

        <div class="col s12">
          <button class='at-btn col s12 btn waves-effect success' style="margin-bottom: 15px;" type="submit"
            [disabled]="!editObjectiveForm.valid">
            <i class="material-icons right">add_circle</i> Update Objective
          </button>

          <button type="button" [ngClass]="'col s12 btn waves-effect ' + themeColor " (click)="backClicked()">
            <i class="material-icons left">keyboard_arrow_left</i> Back To Objective List
          </button>
        </div>
      </form>
    </div>
  </main>
</div>