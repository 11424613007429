<div class="container">

    <div class="row">
        <h5 style="font-weight: bolder; font-family: 'Anton', sans-serif;" > Board List</h5>
        <hr />

        <div class="row col s12">
            <div class="col l1 "></div>

            <div class="col s12 m12 l10" *ngIf="model">
                <div class="section"></div>
                <app-board-list-shared [viewModel]="model"></app-board-list-shared>
            </div>

            <div class="col l1 "></div>
        </div>

    </div>

</div>