import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppInfoModel } from 'src/app/models/AppInfoModel';
import { ApiKeyManagerService } from './apiKeyManager.Service';

@Injectable({
  providedIn: 'root'
})
export class AppInfoService {

url = environment.firstNation.apiUrl + 'api/MemberHome/';
 
  constructor(private httpClient: HttpClient, private keyManager: ApiKeyManagerService) { }

getAppInfo(id: number): Observable<AppInfoModel> {
  const headers = new HttpHeaders().set('SOCIALAPIKEY', this.keyManager.getKey());
  return this.httpClient.get<AppInfoModel>(this.url + 'GetApplicationInfo/' + id, {headers});
}

getBDCompliance(id: number): Observable<AppInfoModel[]> {
  const headers = new HttpHeaders().set('SOCIALAPIKEY', this.keyManager.getKey());
  return this.httpClient.get<AppInfoModel[]>(this.url + 'GetBDComplianceList/' + id, {headers});
}

getIfMemberHasCurrentBD(id: number): Observable<any> {
    const headers = new HttpHeaders().set('SOCIALAPIKEY', this.keyManager.getKey());
    return this.httpClient.get<any[]>(this.url + 'GetIfMemberHasCurrentBAndD/' + id, {headers});
  }


}
