<div class="container body" *ngIf="!profile_updated">
    <div class="row">

        <div class="card col s12  Linen lighten-2">
            <br>
            <div class="card-content">
                <div class="row col s12">
                    <h6><b>Do You Need to Update Your Personal Information ?</b></h6>
                </div>
                <div class="row input-field col s12">
                    <div class="row col s12">
                        <label class="col s6">
                            <input type="radio" value="yes" name="update_profile"
                                (change)="onUpdateProfileYes($event)" /> <span><b>Yes</b></span>
                        </label>
                        <label class="col s6">
                            <input type="radio" value="no" name="update_profile" (change)="onUpdateProfileNo($event)" />
                            <span><b>No</b></span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="body-h container-pi" *ngIf="profile_updated">
    <div class="header-setting b-m-2">
        <!-- <h4 class="">IS Application</h4> -->

        <h4 class="title-section1 title-member icon-texts"> <span class="material-symbols-outlined back-icon p-r-1"
            (click)=" backClicked()">
            arrow_circle_left</span>IS Application</h4>
    </div>
    <main class="StickyContent white row">
        <div class="row col s12 l10" *ngIf="treatyNo && bandList ">
            <p *ngIf="step > 0" class="center-align">Step {{step}} of 9</p>

            <!-- Family Members Section -->
            <form *ngIf="step == 1" class="row col s12 l12" [formGroup]="section4Form"
                (ngSubmit)="submitSection4(section4Form.controls.children.value, 'children')">
                <div class="row col s12 grey lighten-2">
                    <h5 class="center-align title-section3 bold-800">Section 1</h5>
                </div>
                <br>

                <section></section>

                <div formGroupName="children">
                    <div class="col s12 card white">
                        <br>

                        <div class="input-field col s12 m6">
                            <div class="label-input">Applicant Marital Status<span class="red-text">*</span></div>
                            <select formControlName="marital_status" class="validate browser-default"
                                id="marital_status" (change)="maritalStatusChange($event.target.value)">
                                <option *ngFor="let m of maritalStatus" [value]="m">{{m}}</option>
                            </select>
                            <div *ngIf="(marital_status.dirty || marital_status.touched) && marital_status.invalid">
                                <small class="red-text"> The Marital Status is required.</small>
                            </div>
                        </div>


                        <div class="input-field col s12 m6">
                            <div class="label-input">Applicant Education Level<span class="red-text">*</span></div>
                            <select formControlName="applicant_edu_level" class="validate browser-default"
                                id="applicant_edu_level">
                                <option *ngFor="let b of ApplicantEduLevelList" [value]="b">{{b}}</option>
                            </select>
                        </div>
                    </div>

                    <div class="col s12 card white">
                        <br>
                        <div class="row col s12">Do you want to Add Family Member?</div>
                        <div class="row input-field col s12">
                            <div class="row col s12">
                                <label class="col s6">
                                    <input type="radio" value="yes" formControlName="add_children"
                                        (change)="onAddChildrenYes()" /> <span>Yes</span>
                                </label>
                                <label class="col s6">
                                    <input type="radio" value="no" formControlName="add_children"
                                        (change)="onAddChildrenNo()" /> <span>No</span>
                                </label>
                            </div>
                        </div>
                    </div>
                    <br>
                    <div *ngIf="emptyChildren" class="center-align">
                        <span>No Family Members Added.</span>
                        <i class="col s12 material-icons medium">inventory</i>
                    </div>

                    <div *ngIf="displayChildren" class="row col s12 card white">
                        <h6 class="card-title"> Family Members Added</h6>
                        <div formArrayName="childrens" *ngFor="let child of childControls.controls; let i = index;">
                            <ul class="collapsible">
                                <li>
                                    <div class="row collapsible-header"><i class="material-icons">person</i>
                                        <span class="col s5"><strong>First Name: </strong>
                                            {{childControls.controls[i].controls.given_name.value}}</span>
                                        <span class="col s5"><strong>last Name: </strong>
                                            {{childControls.controls[i].controls.last_name.value}}</span>
                                        <span class="col s2">
                                            <i class="material-icons red-text small" (click)="onDeleteChildern(i)"
                                                style="cursor: pointer;">delete</i>
                                        </span>
                                    </div>
                                    <div class="row collapsible-body">
                                        <span class="col s6"> <strong>Birth Date: </strong>
                                            {{childControls.controls[i].controls.dob.value}}</span>
                                        <span class="col s6"> <strong>Relationship to Applicant: </strong>
                                            {{childControls.controls[i].controls.Relationship.value}}</span>
                                        <span class="col s6"> <strong>Education Level: </strong>
                                            {{(childControls.controls[i].controls.edu_level.value !=="") ?
                                            childControls.controls[i].controls.edu_level.value : ""}}</span>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <div class="row col s12" style="margin-bottom: 25px; margin-top: 25px;" *ngIf="!addChildren">
                            <p class="center-align">Do you wish to Add Another Member?</p>
                            <button class="col s12 btn green" type="button" (click)="addAnotherChild()"><i
                                    class="material-icons">add</i>Add Another Member</button>
                        </div>
                    </div>

                    <div class="section"></div>

                    <div *ngIf="addChildren" class="row col s12 card white">
                        <div class="col s12 card-content">
                            <span class="material-icons right" (click)="closeAddChild()"
                                style="cursor: pointer;">close</span>
                            <span class="card-title center-align col s12">Add Member</span>
                            <div class="input-field col s12 m6">
                                <div class="label-input">Member First Name<span class="red-text">*</span></div>
                                <input id="child_first_name" type="text" formControlName="child_first_name"
                                    class="capitalize validate" placeholder="Member First Name">
                                <div
                                    *ngIf="(child_first_name.dirty || child_first_name.touched) && child_first_name.invalid">
                                    <small *ngIf="child_first_name.errors.required" class="red-text"> The First Name is
                                        required. </small>
                                </div>
                            </div>

                            <div class="input-field col s12 m6">
                                <div class="label-input">Member Last Name<span class="red-text">*</span></div>
                                <input id="child_last_name" type="text" formControlName="child_last_name"
                                    class="capitalize  validate" placeholder="Member Last Name">
                                <div
                                    *ngIf="(child_last_name.dirty || child_last_name.touched) && child_last_name.invalid">
                                    <small *ngIf="child_last_name.errors.required" class="red-text"> The Last Name is
                                        required. </small>
                                </div>
                            </div>


                            <div class="input-field col s12 m6">
                                <div class="label-input">Date of Birth<span class="red-text">*</span></div>
                                <!-- <input id="child_dob" type="text" formControlName="child_dob" class="validate"
                                    [textMask]="{mask: dateMask, guide: true}" placeholder="dd/mm/yyyy"> -->
                                    <input id="child_dob" type="text" formControlName="child_dob" class="validate"
                                     placeholder="dd/mm/yyyy">
                                <div *ngIf="(child_dob.dirty || child_dob.touched) && child_dob.invalid">
                                    <small *ngIf="child_dob.errors.required" class="red-text"> The Date of Birth is
                                        required.</small>
                                </div>
                            </div>

                            <div class="input-field col s12 m6">
                                <div class="label-input">Relation to Applicant<span class="red-text">*</span></div>
                                <select formControlName="child_relationship" class="validate browser-default"
                                    id="child_relationship" (change)="childRelationshipChange($event.target.value)">
                                    <option *ngFor="let r of RelationshipList" [value]="r">{{r}}</option>
                                </select>
                            </div>

                            <div class="input-field col s12 m6">
                                <div class="label-input">Education Level (optional)</div>
                                <select formControlName="child_edu_level" class="validate browser-default"
                                    id="child_edu_level" (change)="childEduLevelChange($event.target.value)">
                                    <option *ngFor="let b of EduLevelList" [value]="b">{{b}}</option>
                                </select>
                            </div>
                        </div>

                        <button class="col s12 btn green" type="button" style="margin-bottom: 15px;"
                            (click)="addChild(section4Form.controls.children.value)"
                            [disabled]="!section4Form.controls.children.valid">Add Member</button>
                    </div>
                </div>

                <button *ngIf="!addChildren" class="col s12 m5 offset-m2 l3 btn right waves-effect blue" type="submit"
                    style="margin-bottom: 15px" title="Save">Next <i
                        class="material-icons right">arrow_forward</i></button>
            </form>

            <!-- Other Persons Section -->
            <form *ngIf="step == 2" class="row col s12 l12" [formGroup]="section4Form"
                (ngSubmit)="submitSection4(section4Form.controls.other.value, 'others')">
                <div class="row col s12 grey lighten-2">
                    <h5 class="center-align title-section3 bold-800">Section 2</h5>
                </div>
                <br>
                <div formGroupName="other">
                    <div class="col s12 card white">
                        <div class="row col s12 card-content">
                            <div class="row col s12">Do you want to add another person to your Household?</div>
                            <div class="row input-field">
                                <div class="col s12">
                                    <label class="col s6">
                                        <input type="radio" value="yes" formControlName="add_others"
                                            (change)="onAddOthersYes()" /> <span>Yes</span>
                                    </label>
                                    <label class="col s6">
                                        <input type="radio" value="no" formControlName="add_others"
                                            (change)="onAddOthersNo()" /> <span>No</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="emptyOthers" class="center-align">
                        <span>No Other Family Members Added.</span><i
                            class="col s12 material-icons medium">inventory</i>
                    </div>
                    <div *ngIf="displayOthers" class="row col s12 card white">
                        <h6 class="card-title">Other Family Members Added</h6>

                        <div formArrayName="others" *ngFor="let others of othersControls.controls; let i = index;">
                            <ul class="collapsible">
                                <li>
                                    <div class="row collapsible-header"><i class="material-icons">person</i>
                                        <span class="col s5"><strong>First Name:
                                            </strong>{{othersControls.controls[i].controls.given_name.value}}</span>
                                        <span class="col s5"><strong>Last Name:
                                            </strong>{{othersControls.controls[i].controls.last_name.value}}</span>
                                        <span class="col s2">
                                            <i class="material-icons red-text small" (click)="onDeleteOthers(i)"
                                                style="cursor: pointer;">delete</i>
                                        </span>
                                    </div>
                                    <div class="row collapsible-body">
                                        <span class="col s6"> <strong>Birth Date: </strong>
                                            {{othersControls.controls[i].controls.dob.value}}</span>
                                        <span class="col s6"><strong>Relations:
                                            </strong>{{othersControls.controls[i].controls.Relationship.value}}</span>
                                        <span class="col s6"><strong>Contribution $:
                                            </strong>{{othersControls.controls[i].controls.contribution.value}}</span>
                                    </div>
                                </li>
                            </ul>
                        </div>

                        <div class="row col s12" style="margin-bottom: 25px; margin-top: 25px;"
                            *ngIf="!addOtherMemebers">
                            <p class="center-align">Do you wish to Add Another Person ?</p>
                            <button class="col s12 btn green" type="button" (click)="addAnotherOther()"><i
                                    class="material-icons">add</i>Add Another Person</button>
                        </div>
                    </div>

                    <div *ngIf="addOtherMemebers">
                        <div class="col s12 card white">
                            <div class="col card-content s12">
                                <span class="material-icons right" (click)="closeAddOther()"
                                    style="cursor: pointer;">close</span>
                                <span class="card-title">Add Other Persons in Household</span>

                                <div class="input-field col s12 m6">
                                    <div class="label-input">First Name<span class="red-text">*</span></div>
                                    <input id="other_first_name" type="text" formControlName="other_first_name"
                                        class="capitalize  validate" placeholder="First Name">
                                    <div
                                        *ngIf="(other_first_name.dirty || other_first_name.touched) && other_first_name.invalid">
                                        <small *ngIf="other_first_name.errors.required" class="red-text"> The First Name
                                            is
                                            required. </small>
                                    </div>
                                </div>

                                <div class="input-field col s12 m6">
                                    <div class="label-input">Last Name<span class="red-text">*</span></div>
                                    <input id="other_last_name" type="text" formControlName="other_last_name"
                                        class="capitalize  validate" placeholder="Last Name">
                                    <div
                                        *ngIf="(other_last_name.dirty || other_last_name.touched) && other_last_name.invalid">
                                        <small *ngIf="other_last_name.errors.required" class="red-text"> The First Name
                                            is
                                            required. </small>
                                    </div>
                                </div>

                                <div class="input-field col s12 m6">
                                    <div class="label-input">Date of Birth<span class="red-text">*</span></div>
                                    <!-- <input id="other_dob" type="text" formControlName="other_dob" class="validate"
                                        [textMask]="{mask: dateMask, guide: true}" placeholder="dd/mm/yyyy"> -->
                                        <input id="other_dob" type="text" formControlName="other_dob" class="validate"
                                         placeholder="dd/mm/yyyy">
                                    <div *ngIf="(other_dob.dirty || other_dob.touched) && other_dob.invalid">
                                        <small *ngIf="other_dob.errors.required" class="red-text"> The Date of Birth is
                                            required.</small>
                                    </div>
                                </div>

                                <div class="input-field col s12 m6">
                                    <div class="label-input">Relations</div>
                                    <input id="other_relation" type="text" formControlName="other_relation"
                                        class="capitalize  validate" placeholder="Relations">
                                </div>

                                <div class="input-field col s12 m6">
                                    <div class="label-input"> Financial Contributon Amount $</div>
                                    <input type="number" formControlName="contribution"
                                        (change)="setTwoNumberDecimal($event.target.value)" min="0" max="10000"
                                        step="0.25" value="0.00" />
                                </div>
                            </div>
                        </div>
                        <button class="col s12 btn green" type="button" style="margin-bottom: 15px;"
                            (click)="addOthers(section4Form.controls.other.value)"
                            [disabled]="!section4Form.controls.other.valid"> <i
                                class="material-icons">person_add_alt</i>
                            Add Other Persons in Household</button>
                    </div>
                </div>

                <button *ngIf="!addOtherMemebers" class="col s12 m5 l3 btn left waves-effect blue" type="button"
                    style="margin-bottom: 15px" (click)="onBack()"><i class="material-icons left">arrow_back</i>Back
                </button>

                <!-- [disabled]='!section4Form.controls.other.valid' -->
                <button *ngIf="!addOtherMemebers" class="col s12 m5 l3 btn right waves-effect blue" type="submit"
                    style="margin-bottom: 15px" title="Save">Next <i
                        class="material-icons right">arrow_forward</i></button>
            </form>

            <!-- Previous Address Section -->
            <form *ngIf="step == 3" class="col s12 l12" [formGroup]="section2Form"
                (ngSubmit)="submitSection2(section2Form.value)">
                <div class="row col s12 grey lighten-2">

                    <h5 class="center-align title-section3 bold-800">Section 3</h5>
                </div>
                <br>
                <div class="row">

                    <div id="previous_address" class="col s12">
                        <div formGroupName="previous_address" class="col s12 card white">
                            <h6 class="card-title center-align">Previous Address</h6>
                            <div formGroupName="applicant" class="card-content" [ngClass]="{'col s12 m6': HasSpouse}">
                                <span class="card-title">Applicant</span>
                                <div class="input-field col s12 l6" (change)="getApplicantAddress($event.target.value)">
                                    <div class="label-input">Applicant Address</div>
                                    <input id="address" type="text" formControlName="address"
                                        class="capitalize  validate" placeholder="Applicant">
                                </div>

                                <div class="input-field col s12 l6">
                                    <div class="label-input">Start Date(dd/mm/yyyy)</div>
                                    <!-- <input id="address_from" type="text" formControlName="address_from" class="validate"
                                        (change)="getApplicantStartDate($event.target.value)"
                                        [textMask]="{mask:dateMask, guide: true}" placeholder="dd/mm/yyyy"> -->
                                        <input id="address_from" type="text" formControlName="address_from" class="validate"
                                        (change)="getApplicantStartDate($event.target.value)"
                                         placeholder="dd/mm/yyyy">
                                </div>

                                <div class="input-field col s12 l6">
                                    <div class="label-input">End Date(dd/mm/yyyy)</div>
                                    <!-- <input id="address_to" type="text" formControlName="address_to" class="validate"
                                        (change)="getApplicantEndDate($event.target.value)"
                                        [textMask]="{mask:dateMask, guide: true}" placeholder="dd/mm/yyyy"> -->
                                        <input id="address_to" type="text" formControlName="address_to" class="validate"
                                        (change)="getApplicantEndDate($event.target.value)"
                                        placeholder="dd/mm/yyyy">
                                </div>
                            </div>

                            <div formGroupName="spouse" class=" card-content col s12 m6" *ngIf="HasSpouse">
                                <span class="card-title">Spouse</span>
                                <div class="input-field col s12 l6">
                                    <div class="label-input">Spouse Address</div>
                                    <input id="spouse_address" type="text" formControlName="spouse_address"
                                        class="capitalize  validate" placeholder="Spouse Address">
                                </div>

                                <div class="input-field col s12 l6">
                                    <div class="label-input">Start Date(dd/mm/yyyy)</div>
                                    <!-- <input id="spouse_address_from" type="text" formControlName="spouse_address_from"
                                        class="validate" [textMask]="{mask:dateMask, guide: true}"
                                        placeholder="dd/mm/yyyy"> -->
                                        <input id="spouse_address_from" type="text" formControlName="spouse_address_from"
                                        class="validate" 
                                        placeholder="dd/mm/yyyy">
                                </div>

                                <div class="input-field col s12 l6">
                                    <div class="label-input">End Date(dd/mm/yyyy)</div>
                                    <!-- <input id="spouse_address_to" type="text" formControlName="spouse_address_to"
                                        class="validate" [textMask]="{mask:dateMask, guide: true}"
                                        placeholder="dd/mm/yyyy"> -->
                                        <input id="spouse_address_to" type="text" formControlName="spouse_address_to"
                                        class="validate" 
                                        placeholder="dd/mm/yyyy">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!--*ngIf="step2==1"-->
                <button class="col s12 m5 l3 btn left waves-effect blue" type="button" style="margin-bottom: 15px"
                    (click)="onBack()"><i class="material-icons left">arrow_back</i>Back
                </button>

                <!--*ngIf="step2==1"-->
                <button class="col s12 m5 offset-m2 l3 btn right waves-effect blue" type="submit"
                    [disabled]="!section2Form.valid" style="margin-bottom: 15px" title="Save"
                    [disabled]="!section2Form.valid">Next <i class="material-icons right">arrow_forward</i></button>
            </form>

            <!-- Vehicle Section -->
            <form *ngIf="step == 4" class="col s12 l12" [formGroup]="section31Form"
                (ngSubmit)="submitSection31(section31Form.value)">
                <div class="row col s12 grey lighten-2">
                    <h5 class="center-align title-section3 bold-800">Section 4 : Assets - Vehicle</h5>
                </div>
                <br>
                <div class="col s12 card white">
                    <div class="row col s12 card-content">
                        <div class="row col s12">Do you own a vehicle ?
                        </div>
                        <div class="row input-field ">
                            <div class="col s12">
                                <label class="col s6">
                                    <input name="add_vehicle" type="radio" value="yes" formControlName="add_vehicle"
                                        (change)="onAddVehicleYes()" /> <span>Yes</span>
                                </label>
                                <label class="col s6">
                                    <input type="radio" value="no" formControlName="add_vehicle"
                                        (change)="onAddVehicleNo()" /> <span>No</span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
                <div class="section"></div>
                <br>
                <div *ngIf="emptyVehicle" class="center-align">
                    <span>No Vehicle Added</span>
                    <i class="col s12 material-icons medium">inventory</i>
                </div>
                <div *ngIf="displayVehicle" class="row col s12 card white">
                    <h6 class="card-title">Vehicle Added</h6>
                    <br>
                    <div formArrayName="vehicle">
                        <ul class="collection">
                            <li class="row collection-item"
                                *ngFor="let vehicle of vehicleControls.controls; let i = index;">
                                <div class="col s12 m6"><strong>Vehicle:
                                    </strong>{{vehicleControls.controls[i].controls.model.value}}</div>
                                <div class="col s10 m4 "><strong>Value:
                                    </strong>{{vehicleControls.controls[i].controls.vehicle_value.value}}</div>
                                <div class="secondary-content col s2">
                                    <i class="material-icons red-text small" (click)="onDeleteVehicle(i)"
                                        style="cursor: pointer;">delete</i>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="row col s12" style="margin-bottom: 25px; margin-top: 25px;" *ngIf="!addVehicle">
                        <p class="center-align">Do you wish to Add Another Vehicle?</p>
                        <button class="col s12 btn green right" type="button" [disabled]="HasTwoVehicle"
                            (click)="addAnotherVehicle()"><i class="material-icons">add</i> Add Another Vehicle</button>
                    </div>
                </div>
                <br>

                <div class="section"></div>
                <div *ngIf="addVehicle" class="row col s12 card white">
                    <div class="card-content">
                        <span class="material-icons right" (click)="closeAddVehicle()"
                            style="cursor: pointer;">close</span>
                        <h6 class="card-title center-align">Add a new Vehicle</h6>


                        <div class="input-field col s12 m6 ">
                            <div class="label-input">Vehicle Model<span class="red-text">*</span></div>
                            <input id="model" type="text" formControlName="model" class="capitalize  validate"
                                placeholder="Vehicle Model">
                            <div *ngIf="(model.dirty || model.touched) && model.invalid">
                                <small *ngIf="model.errors.required" class="red-text"> Model is required. </small>
                            </div>
                        </div>

                        <div class="input-field col s12 m6 ">
                            <div class="label-input">Year Built<span class="red-text">*</span></div>
                            <input id="year_to_make" type="number" formControlName="year_to_make" min="1980" max="2100"
                                class="validate" placeholder="Year Built">
                            <div *ngIf="(year_to_make.dirty || year_to_make.touched) && year_to_make.invalid">
                                <small *ngIf="year_to_make.errors.required" class="red-text"> Year is required. </small>
                            </div>
                        </div>

                        <div class="input-field col s12 m6">
                            <div class="label-input">Vehicle Value $<span class="red-text">*</span></div>
                            <input type="number" formControlName="vehicle_value"
                                (change)="setCarTwoNumberDecimal($event.target.value)" min="0" max="50000" step="0.25"
                                value="0.00" placeholder="0.00" />
                        </div>

                        <button class="col s12 btn green" style="margin-bottom: 15px;" type="button"
                            [disabled]="!section31Form.valid" (click)="addNewVehicle(section31Form.value)">Add
                            Vehicle</button>
                    </div>

                </div>

                <button *ngIf="!addVehicle" class="col s12 m5 l3 btn left waves-effect blue" type="button"
                    style="margin-bottom: 15px" (click)="onBack()"><i class="material-icons left">arrow_back</i>Back
                </button>

                <!--  [disabled]="!section31Form.valid" -->
                <button *ngIf="!addVehicle" class="col s12 m5 offset-m2 l3 btn right waves-effect blue" type="submit"
                    style="margin-bottom: 15px" title="Save">Next <i
                        class="material-icons right">arrow_forward</i></button>
            </form>

            <!-- Assets Section -->
            <form *ngIf="step == 5" class="col s12 l12" [formGroup]="section3Form"
                (ngSubmit)="submitSection3(section3Form.value)">
                <div class="row col s12 grey lighten-2">

                    <h5 class="center-align title-section3 bold-800">Section 5 : Assets</h5>
                </div>
                <br>
                <div class="col s12 card white">
                    <div class="row col s12 card-content">
                        <div class="row col s12">Do you have any Assets to declare?
                        </div>
                        <div class="row input-field">
                            <div class="col s12">
                                <label class="col s6">
                                    <input name="add_assets" type="radio" value="yes" formControlName="add_assets"
                                        (change)="onAddAssetsYes()" /> <span>Yes</span>
                                </label>
                                <label class="col s6">
                                    <input type="radio" value="no" formControlName="add_assets"
                                        (change)="onAddAssetsNo()" /> <span>No</span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
                <div class="section"></div>
                <br>

                <div *ngIf="emptyAssets" class="center-align">
                    <span>No Assets Added</span>
                    <i class="col s12 material-icons medium">inventory</i>
                </div>
                <div *ngIf="displayAssets" class="row col s12 card white">
                    <h6 class="card-title">Assets Added</h6>
                    <br>
                    <div formArrayName="assets">
                        <ul class="collection">
                            <li class="row collection-item"
                                *ngFor="let asset of assetControls.controls; let i = index;">
                                <div class="col s12 m6"><strong>Asset:
                                    </strong>{{assetControls.controls[i].controls.asset.value}}</div>
                                <div class="col s10 m4 "><strong>Amount:
                                    </strong>{{assetControls.controls[i].controls.amount.value}}</div>
                                <div class="secondary-content col s2">
                                    <i class="material-icons red-text small" (click)="onDeleteAssets(i)"
                                        style="cursor: pointer;">delete</i>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="row col s12" style="margin-bottom: 25px; margin-top: 25px;" *ngIf="!addAssets">
                        <p class="center-align">Do you wish to Add Another Asset?</p>
                        <button class="col s12 btn green right" type="button" (click)="addAnotherAsset()"><i
                                class="material-icons">add</i> Add Another Asset</button>
                    </div>
                </div>
                <br>
                <div class="section"></div>
                <div *ngIf="addAssets" class="row col s12 card white">
                    <div class="card-content">
                        <span class="material-icons right" (click)="closeAddAsset()"
                            style="cursor: pointer;">close</span>
                        <h6 class="card-title center-align">Add a new asset</h6>

                        <div class="input-field col s12 m6">
                            <div class="label-input">Assets<span class="red-text">*</span></div>
                            <select id="asset_type" formControlName="asset_type" class="validate browser-default"
                                (change)="onAssetSelected($event.target.value)">
                                <option value="" disabled selected>Choose your option</option>
                                <option *ngFor="let asset of AssetsOfAlberta" [value]="asset">{{asset}}</option>
                            </select>
                            <div> <small *ngIf="!assetSelected" class="red-text"> Asset is required. </small> </div>
                        </div>

                        <div class="input-field col s12 m6">
                            <div class="label-input">Amount $<span class="red-text">*</span></div>
                            <input type="number" formControlName="asset_amount"
                                (change)="setAssetsTwoNumberDecimal($event.target.value)" min="0" max="10000"
                                step="0.25" value="0.00" placeholder="0.00" />
                            <div *ngIf="(asset_amount.dirty || asset_amount.touched) && asset_amount.invalid">
                                <small *ngIf="asset_amount.errors.required" class="red-text"> Amount is required.
                                </small>
                            </div>
                        </div>

                        <button class="col s12 btn green" style="margin-bottom: 15px;" type="button"
                            [disabled]="!section3Form.valid" (click)="addNewAssets(section3Form.value)">Add
                            Asset</button>
                    </div>
                </div>

                <button *ngIf="!addAssets" class="col s12 m5 l3 btn left waves-effect blue" type="button"
                    style="margin-bottom: 15px" (click)="onBack()"><i class="material-icons left">arrow_back</i>Back
                </button>
                <!--  [disabled]="!section3Form.valid" -->
                <button *ngIf="!addAssets" class="col s12 m5 offset-m2 l3 btn right waves-effect blue" type="submit"
                    style="margin-bottom: 15px" title="Save">Next <i
                        class="material-icons right">arrow_forward</i></button>
            </form>

            <!-- Applicant Income Section -->
            <form *ngIf="step == 6" class="col s12 l12" [formGroup]="section5Form"
                (ngSubmit)="submitSection5(section5Form.controls.applicant.value,'applicant')">
                <div class="row col s12 grey lighten-2">

                    <h5 class="center-align title-section3 bold-800">Section 6 : Applicant Previous Month Income</h5>
                </div>
                <br>
                <div class="section"></div>
                <br>
                <div formGroupName="applicant">
                    <div class="col s12 card white">
                        <div class="row col s12 card-content">
                            <div class="row col s12">Do you have any Income to declare?
                            </div>
                            <div class="row input-field col s12">
                                <div class="row col s12">
                                    <label class="col s6">
                                        <input type="radio" value="yes" formControlName="add_income"
                                            (change)="onAddIncomesYes()" /> <span>Yes</span>
                                    </label>
                                    <label class="col s6">
                                        <input type="radio" value="no" formControlName="add_income"
                                            (change)="onAddIncomesNo()" /> <span>No</span>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="emptyIncomes" class="center-align">
                        <span>No Income Declared.</span>
                        <i class="col s12 material-icons medium">inventory</i>
                    </div>
                    <div *ngIf="displayIncomes" class="row col s12 card white">
                        <h6 class="card-title">Incomes Added</h6>
                        <div formArrayName="incomes">
                            <ul class="collection">
                                <li class="row collection-item"
                                    *ngFor="let income of incomeControls.controls; let i = index;">
                                    <div class="row col s11">
                                        <div class="col s12 m6"><strong>Income:
                                            </strong>{{incomeControls.controls[i].controls.income.value}}</div>
                                        <div class="col s12 m2"><strong>Amount:
                                            </strong>{{incomeControls.controls[i].controls.amount.value}}</div>
                                        <div class="col s12 m4"><strong>Date Received:
                                            </strong>{{incomeControls.controls[i].controls.date_received.value}}</div>
                                    </div>
                                    <div class="secondary-content col s1">
                                        <i class="material-icons red-text small" (click)="OnDeleteIncome(i)"
                                            style="cursor: pointer;">delete</i>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="row col s12" style="margin-bottom: 25px; margin-top: 25px;" *ngIf="!addIncomes">
                            <p class="center-align">Do you wish to Add Another Income?</p>
                            <button class="col s12 btn green" type="button" (click)="addAnotherIncome()"><i
                                    class="material-icons">add</i>Add Another Income</button>
                        </div>
                    </div>

                    <div class="section"></div>
                    <div *ngIf="addIncomes">
                        <div class="col s12 card white">
                            <div class="col card-content s12">
                                <span class="material-icons right" (click)="closeAddIncome()"
                                    style="cursor: pointer;">close</span>
                                <span class="card-title">Add Income</span>
                                <div class="input-field col s12">
                                    <!-- <i class="material-icons prefix blue-text">account_balance</i> -->
                                    <select id="income_type" formControlName="income_type"
                                        class="validate browser-default"
                                        (change)="onIncomeSelected($event.target.value)">
                                        <option value="" disabled selected>Choose your Income Type</option>
                                        <option *ngFor="let income of incomes" [value]="income">{{income}}</option>
                                    </select>
                                    <label for="income">Income<span class="red-text">*</span></label>
                                    <div> <small *ngIf="!incomeSelected" class="red-text"> Income is required. </small>
                                    </div>
                                </div>

                                <div class="input-field col s12 m6 ">
                                    <div class="label-input">Amount<span class="red-text">*</span></div>
                                    <input id="income_amount" type="number" formControlName="income_amount"
                                        class="validate" (change)="setIncomesTwoNumberDecimal($event.target.value)"
                                        min="0" max="10000" step="0.25" value="0.00" placeholder="0.00">
                                    <div
                                        *ngIf="(income_amount.dirty || income_amount.touched) && income_amount.invalid">
                                        <small *ngIf="income_amount.errors.required" class="red-text"> Amount is
                                            required.</small>
                                    </div>
                                </div>

                                <div class="input-field col s12 m6 ">
                                    <div class="label-input">Date Received</div>
                                    
                                    <!-- <input id="income_date" type="text" formControlName="income_date" class="validate"
                                        [textMask]="{mask: dateMask, guide: true}" placeholder="dd/mm/yyyy"> -->
                                        <input id="income_date" type="text" formControlName="income_date" class="validate"
                                       placeholder="dd/mm/yyyy">
                                    <!-- <label for="income_date">Date Received</label> -->
                                </div>

                            </div>
                        </div>
                        <button class="col s12 btn green" type="button" style="margin-bottom: 15px;"
                            [disabled]="!section5Form.controls.applicant.valid"
                            (click)="addNewIncome(section5Form.controls.applicant.value)">Add Income</button>
                    </div>
                </div>

                <button *ngIf="!addIncomes" class="col s12 m5 l3 btn left waves-effect blue" type="button"
                    style="margin-bottom: 15px" (click)="onBack()"><i class="material-icons left">arrow_back</i>Back
                </button>

                <!--  [disabled]="!section5Form.controls.applicant.valid" -->
                <button *ngIf="!addIncomes" class="col s12 m5 offset-m2 l3 btn right waves-effect blue" type="submit"
                    style="margin-bottom: 15px" title="Save">Next <i
                        class="material-icons right">arrow_forward</i></button>
            </form>

            <!-- Spouse Income Section -->
            <form *ngIf="step == 7" class="col s12 l12" [formGroup]="section5Form"
                (ngSubmit)="submitSection5(section5Form.controls.spouse.value, 'spouse')">
                <div class="row col s12 grey lighten-2">

                    <h5 class="center-align title-section3 bold-800">Section 7 : Spouse Previous Month Income</h5>
                </div>
                <br>
                <div class="section"></div>
                <br>
                <div formGroupName='spouse'>
                    <div class="col s12 card white">

                        <div class="row col s12 card-content" *ngIf="HasSpouse">
                            <div class="row col s12">Does your spouse have any Income to declare? </div>
                            <div class="row input-field col s12">
                                <div class="row col s12">
                                    <label class="col s6">
                                        <input type="radio" value="yes" formControlName="add_spouse_income"
                                            (change)="onAddSpouseIncomeYes()" />
                                        <span>Yes</span>
                                    </label>
                                    <label class="col s6">
                                        <input type="radio" value="no" formControlName="add_spouse_income"
                                            (change)="onAddSpouseIncomeNo()" />
                                        <span>No</span>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="row col s12 card-content" *ngIf="!HasSpouse">
                            <div class="row input-field col s12">
                                <div class="row col s8">No Spouse Income to Declare : </div>
                                <div class="row col s4">
                                    <label class="col s4">
                                        <input type="radio" value="no" formControlName="add_spouse_income"
                                            (change)="onAddSpouseIncomeNo()" />
                                        <span>No</span>
                                    </label>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="emptySpouseIncomes" class="center-align">
                        <span>No Spousal Income Declared.</span>
                        <i class="col s12 material-icons medium">inventory</i>
                    </div>
                    <div *ngIf="displaySpouseIncomes" class="row col s12 card white">
                        <h6 class="card-title">Incomes Added</h6>
                        <div formArrayName="spouse_incomes">
                            <ul class="collection">
                                <li class="row collection-item"
                                    *ngFor="let income of spouseIncomeControls.controls; let i = index;">
                                    <div class="row col s11">
                                        <div class="col s12 m6"><strong>Income:
                                            </strong>{{spouseIncomeControls.controls[i].controls.income.value}}</div>
                                        <div class="col s12 m2"><strong>Amount:
                                            </strong>{{spouseIncomeControls.controls[i].controls.amount.value}}</div>
                                        <div class="col s12 m4"><strong>Date Received:
                                            </strong>{{spouseIncomeControls.controls[i].controls.date_received.value}}
                                        </div>
                                    </div>

                                    <div class="secondary-content col s1">
                                        <i class="material-icons red-text small" (click)="OnDeleteSpouseIncome(i)"
                                            style="cursor: pointer;">delete</i>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="row col s12" style="margin-bottom: 25px; margin-top: 25px;"
                            *ngIf="!addSpouseIncomes">
                            <p class="center-align">Do you wish to Add Another Income?</p>
                            <button class="col s12 btn green" type="button" (click)="addAnotherSpouseIncome()"><i
                                    class="material-icons">add</i>Add Another Income</button>
                        </div>
                    </div>
                    <div *ngIf="addSpouseIncomes" class="col s12 card white">
                        <div class="col s12 card-content">
                            <span class="material-icons right" (click)="closeAddSpouseIncome()"
                                style="cursor: pointer;">close</span>
                            <span class="card-title">Add Income</span>
                            <div class="col card-content s12">
                                <div class="input-field col s12">
                                    <i class="material-icons prefix blue-text">account_balance</i>
                                    <select id="spouse_income_type" formControlName="spouse_income_type"
                                        class="validate" (change)="onSpouseIncomeSelected($event.target.value)">
                                        <option value="" disabled selected>Choose your Income Type</option>
                                        <option *ngFor="let income of incomes" [value]="income">{{income}}</option>
                                    </select>
                                    <label for="spouse_income_type">Income<span class="red-text">*</span></label>
                                    <div> <small *ngIf="!spouseIncomeSelected" class="red-text"> Income is required.
                                        </small></div>
                                </div>

                                <div class="input-field col s12 m6 ">
                                    <div class="label-input">Amount<span class="red-text">*</span></div>
                                    <input id="spouse_income_amount" type="number"
                                        formControlName="spouse_income_amount" class="validate"
                                        (change)="setSpouseIncomesTwoNumberDecimal($event.target.value)" min="0"
                                        max="10000" step="0.25" value="0.00" placeholder="0.00">
                                    <div
                                        *ngIf="(spouse_income_amount.dirty || spouse_income_amount.touched) && spouse_income_amount.invalid">
                                        <small *ngIf="spouse_income_amount.errors.required" class="red-text"> Amount is
                                            required. </small>
                                    </div>
                                </div>

                                <div class="input-field col s12 m6 ">
                                    <div class="label-input">Date Received<span class="red-text">*</span></div>
                                    <!-- <input id="spouse_income_date" type="text" formControlName="spouse_income_date"
                                        class="validate" placeholder="dd/mm/yyyy"
                                        [textMask]="{mask: dateMask, guide: true}"> -->
                                        <input id="spouse_income_date" type="text" formControlName="spouse_income_date"
                                        class="validate" placeholder="dd/mm/yyyy"
                                       >
                                </div>
                            </div>
                        </div>
                        <button class="col s12 btn green" type="button" style="margin-bottom: 15px;"
                            [disabled]="!section5Form.controls.spouse.valid"
                            (click)="addNewSpouseIncome(section5Form.controls.spouse.value)">Add Income</button>
                    </div>
                </div>

                <button *ngIf="!addSpouseIncomes" class="col s12 m5 l3 btn left waves-effect blue" type="button"
                    style="margin-bottom: 15px" (click)="onBack()"><i class="material-icons left">arrow_back</i>Back
                </button>

                <!-- [disabled]="!section5Form.controls.spouse.valid" -->
                <button *ngIf="!addSpouseIncomes" class="col s12 m5 offset-m2 l3 btn right waves-effect blue"
                    type="submit" style="margin-bottom: 15px" title="Save">Next <i
                        class="material-icons right">arrow_forward</i></button>
            </form>

            <!-- Housing Section -->
            <form *ngIf="step == 8" class="col s12 l12" [formGroup]="section8Form"
                (ngSubmit)="submitSection8(section8Form.value)">

                <div class="row col s12 grey lighten-2">
                    <h5 class="center-align title-section3 bold-800">Section 8: Housing</h5>
                </div>
                <br>
                <div class="col s12 card white">
                    <div class="row col s12 card-content">
                        <div class="row input-field col s12">
                            <div class="row col s12"> Is Accommodation Shared ? <strong class="red-text">*</strong>
                            </div>
                            <div class="row col s12">
                                <label class="col s6">
                                    <input class="with-gap" type="radio" value="yes" formControlName="house_shared" />
                                    <span>Yes</span>
                                </label>
                                <label class="col s6">
                                    <input class="with-gap" type="radio" value="no" formControlName="house_shared" />
                                    <span>No</span>
                                </label>
                            </div>
                        </div>

                        <div class="input-field col s12 m8">
                            <div class="label-input">Who is Responsible for Shelter Costs ?<strong
                                    class="red-text">*</strong></div>
                            <input id="landlord" type="text" formControlName="landlord" class="validate"
                                placeholder="Responsible for shelter costs">
                        </div>

                        <div class="input-field col s12 m4">
                            <div class="label-input">Relationship<span class="red-text">*</span></div>
                            <input id="relation" type="text" formControlName="relation" class="validate"
                                placeholder="Relationship">
                        </div>

                        <div class="row input-field col s12">
                            <div class="row col s12"> Rented or owned ? <strong class="red-text">*</strong></div>
                            <div class="row col s12">
                                <label class="col s4">
                                    <input type="radio" value="rented" formControlName="ownership" />
                                    <span>Rented</span>
                                </label>
                                <label class="col s4">
                                    <input type="radio" value="owned" formControlName="ownership" /> <span>Owned</span>
                                </label>

                                <label class="col s4">
                                    <input type="radio" value="bandowned" formControlName="ownership" /> <span>Band
                                        Owned</span>
                                </label>
                            </div>
                        </div>

                        <div class="row input-field col s12">
                            <div class="row col s12"> CMHC assisted housing <strong class="red-text">*</strong></div>
                            <div class="row col s12">
                                <label class="col s6">
                                    <input type="radio" value="yes" formControlName="cmhc_assisted"
                                        (change)="onCMHCAssistedYes()" /> <span>Yes</span>
                                </label>
                                <label class="col s6">
                                    <input type="radio" value="no" formControlName="cmhc_assisted"
                                        (change)="onCMHCAssistedNo()" /> <span>No</span>
                                </label>
                            </div>
                        </div>

                        <div class="row input-field col s12">
                            <p><strong class="blue-text">Costs:</strong> </p>
                        </div>

                        <div class="input-field col s12 m6">
                            <div class="label-input">Utilities ($)</div>
                            <input id="utilities" type="number" formControlName="utilities" class="validate"
                                (change)="setUtilitiesTwoNumberDecimal($event.target.value)" min="0" max="10000"
                                step="0.25" value="0.00" placeholder="0.00">
                        </div>
                        <div class="input-field col s12 m6">
                            <div class="label-input">>Fuel ($)</div>
                            <input id="fuel" type="number" formControlName="fuel" class="validate"
                                (change)="setFuelTwoNumberDecimal($event.target.value)" min="0" max="10000" step="0.25"
                                value="0.00" placeholder="0.00">
                        </div>
                        <div class="input-field col s12 m6">
                            <div class="label-input">Maintenance ($)</div>
                            <input id="maintenance" type="number" formControlName="maintenance" class="validate"
                                (change)="setMaintenanceTwoNumberDecimal($event.target.value)" min="0" max="10000"
                                step="0.25" value="0.00" placeholder="0.00">
                            <!-- <label for="maintenance">Maintenance ($)</label> -->
                        </div>
                        <div class="input-field col s12 m6">
                            <div class="label-input">Garbage, Water, Sewer ($)</div>
                            <!-- <i class="material-icons prefix red-text">attach_money</i> -->
                            <input id="garbage" type="number" formControlName="garbage" class="validate"
                                (change)="setGarbageTwoNumberDecimal($event.target.value)" min="0" max="10000"
                                step="0.25" value="0.00" placeholder="0.00">
                            <!-- <label for="garbage">Garbage, Water, Sewer ($)</label> -->
                        </div>
                        <div class="input-field col s12 m6">
                            <div class="label-input">Rent / Housing loan PMT ($)</div>
                            <!-- <i class="material-icons prefix red-text">attach_money</i> -->
                            <input id="rent" type="number" formControlName="rent" class="validate"
                                (change)="setRentTwoNumberDecimal($event.target.value)" min="0" max="10000" step="0.25"
                                value="0.00" placeholder="0.00">
                            <!-- <label for="rent">Rent / Housing loan PMT ($)</label> -->
                        </div>

                        <div class="row input-field col s12"><span class="black-text"><strong>
                                    <h6>Comments:</h6>
                                </strong></span>
                            <div class="md-form mb-4 pink-textarea active-pink-textarea">
                                <textarea id="comments" formControlName="comments" class="md-textarea form-control"
                                    rows="5"></textarea>
                            </div>
                        </div>
                    </div>
                </div>

                <button class="col s12 m5 l3 btn left waves-effect blue" type="button" style="margin-bottom: 15px"
                    (click)="onBack()"><i class="material-icons left">arrow_back</i>Back
                </button>
                <button class="col s12 m5 offset-m2 l3 btn right waves-effect blue" type="submit"
                    [disabled]="!section8Form.valid" style="margin-bottom: 15px" title="Save">Next <i
                        class="material-icons right">arrow_forward</i></button>
            </form>

            <!-- Signature Section -->
            <form *ngIf="step == 9" class="col s12 l12" [formGroup]="section6Form"
                (ngSubmit)="submitSection6(section6Form.value)">
                <div class="row col s12 grey lighten-2">
                    <h5 class="center-align title-section3 bold-800">Section 9</h5>
                </div>
                <div class="row">

                    <div class="col s12 card white" style="text-align: justify;">
                        <p> I hereby apply for assistance under the Income Assistance Program of Indian and Northern
                            Affairs
                            Canada</p>
                        <p>I declare that the above information to be true, and I authorize the Administrative
                            Authority, to secure from any other source,information that may be required to determine my
                            eligibly for assistance.</p>
                        <p>I agree to inform the Administrative Authority immediately of any change in my address,
                            marital status, family financial and social conditions affecting my eligibility.</p>
                        <p>I authorize the Administrative Authority to release information to a third party as per the
                            Regional Social Assistance Manual. Details of these conditions will be provided upon
                            request.
                        </p>
                        <p><strong><i class="small material-icons prefix black-text">arrow_right</i>Income Support
                                benefits obtained under false pretence or misrepresentation may lead to prosecution
                                under
                                the Criminal Code of Canada</strong></p>
                    </div>

                    <br>
                    <div class="row col s12 white lighten-2">
                        <button type="button" (click)="ApplicantSign()" class="col s12 btn waves-effect blue white-text"
                            style="margin-right: 15px;">
                            <i class="material-icons left white-text">border_color</i> Sign Application
                        </button>
                    </div>
                </div>

                <div *ngIf="HasApplicantSign">
                    <div class="row">
                        <div [ngClass]="'card ' + themeColor">
                            <div class="row card-panel grey lighten-4 center">
                                <div class="row">
                                    <h5 class="center blue-text text-darken-2">Applicant: Please Sign Here</h5>
                                    <div class="section"></div>
                                    <canvas></canvas>
                                </div>
                            </div>
                        </div>

                        <!--  <div class="section"></div> -->
                        <a class="at-btn btn-flat center red lighten-1 col s12 white-text"
                            (click)="clearSignatureOfApplicant()"><i class="material-icons left">loop</i> Clear
                            Signature</a>
                    </div>
                </div>

                <button class="col s12 m5 l3 btn left waves-effect blue" type="button" style="margin-bottom: 15px"
                    (click)="onBack()"><i class="material-icons left">arrow_back</i>Back </button>

                <button class="col s12 m5 offset-m2 l3 btn right waves-effect green" type="submit"
                    style="margin-bottom: 15px" title="Save">Preview & Submit<i
                        class="material-icons right">send</i></button>
            </form>

            <!-- Preview Section -->
            <div *ngIf="step == 0" class="row col s12 l12">
                <h5 class="center-align">Preview Application</h5>
                <div class="row card white">
                    <ul class="collection">
                        <li class="section row collection-item">
                            <div class="row col s12">
                                <h5 class="col s8">Applicant Information</h5>
                            </div>

                            <div class="row col s12">
                                <div class="section row col s12">
                                    <div class="divider"></div>
                                    <p class="col s12 m6"><strong>First Name :</strong> {{ApplicationModel.firstName}}
                                    </p>
                                    <p class="col s12 m6"><strong>Last Name :</strong> {{ApplicationModel.lastName}}</p>
                                    <p class="col s12 m6"><strong>Maritl Status: </strong>
                                        {{ApplicationModel.maritalStatus}}</p>
                                    <p class="col s12 m6"> <strong>Education Level :</strong>
                                        {{ApplicationModel.EDU_LEVEL}}
                                    </p>
                                </div>
                            </div>

                            <div class="row col s12">
                                <h5 class="col s8">Family Members Information</h5>
                                <a class="col s4 blue-text right waves-effect waves-light btn-flat"
                                    (click)="editSection(1)" style="cursor: pointer;"><i
                                        class="material-icons left">edit</i>Edit</a>
                            </div>

                            <p class="col s12" *ngIf="tmpMemberModel.length==0">No Member Added</p>
                            <div class="row col s12" *ngIf="tmpMemberModel.length > 0">
                                <div class="section row col s12 " *ngFor="let child of tmpMemberModel; let i=index;">
                                    <div class="divider"></div>
                                    <p class="col s12 m6"><strong>First Name: </strong> {{child.given_name}}</p>
                                    <p class="col s12 m6"><strong>Last Name: </strong> {{child.last_name}}</p>
                                    <p class="col s12 m6"> <strong>Date of Birth :</strong> {{child.dob}}</p>
                                    <p class="col s12 m6"> <strong>Relationship :</strong> {{child.Relationship}}</p>
                                    <p class="col s12 m12"> <strong>Education Level :</strong> {{child.edu_level}}</p>

                                </div>
                            </div>
                        </li>

                        <li class="section row collection-item">
                            <div class="row col s12">
                                <h5 class="col s8">Other Family Member Information</h5>
                                <a class="col s4 blue-text right waves-effect waves-light btn-flat"
                                    (click)="editSection(2)" style="cursor: pointer;"><i
                                        class="material-icons left">edit</i>Edit</a>
                            </div>

                            <p class="col s12" *ngIf="tmpOtherMemberModel.length==0">No Other Information Added</p>
                            <div class="row col s12" *ngIf="tmpOtherMemberModel.length > 0">
                                <div class="section row col s12 " *ngFor="let other of tmpOtherMemberModel;let i=index">
                                    <p class="col s12 m6 l4 "><strong>{{i+1}} . First Name: </strong>
                                        {{other.given_name}}
                                    </p>
                                    <p class="col s12 m6 l4"><strong>Last Name: </strong> {{other.last_name}}</p>
                                    <p class="col s12 l4"> <strong>Relation :</strong> {{other.Relationship}}</p>
                                </div>
                            </div>
                        </li>

                        <li class="section row col s12 collection-item">
                            <div class="col s12">
                                <h5 class="col s10">Other Information</h5>
                                <a class="col s4 blue-text right waves-effect waves-light btn-flat"
                                    (click)="editSection(3)" style="cursor: pointer;"><i
                                        class="material-icons left">edit</i>Edit</a>
                            </div>
                            <h6 class="col s12">Previous Address</h6>
                            <div class="row col s12">
                                <div class="section row col s12" *ngIf="ApplicationModel.previous_address">
                                    <h6>Applicant</h6>
                                    <p class="col s12 m6"> <strong>Address :</strong>
                                        {{ApplicationModel.previous_address}}
                                    </p>
                                    <p class="col s12 m6"> <strong>Start Date :</strong>
                                        {{ApplicationModel.previous_address_startDate}}</p>
                                    <p class="col s12 m6"> <strong>End Date: </strong>
                                        {{ApplicationModel.previous_address_endDate}}</p>
                                </div>

                                <div class="section row col s12"
                                    *ngIf="ApplicationModel.previous_address_spouse && HasSpouse">
                                    <h6>Spouse</h6>
                                    <p class="col s12 m6"> <strong>Address :</strong>
                                        {{ApplicationModel.previous_address_spouse}}</p>
                                    <p class="col s12 m6"> <strong>Start Date :</strong>
                                        {{ApplicationModel.previous_address_startDate_spouse}}</p>
                                    <p class="col s12 m6"> <strong>End Date: </strong>
                                        {{ApplicationModel.previous_address_endDate_spouse}}</p>
                                </div>
                            </div>
                        </li>

                        <li class="section row collection-item">
                            <div class="row col s12">
                                <h5 class="col s8">Vehicle</h5>
                                <a class="col s4 blue-text right waves-effect waves-light btn-flat"
                                    (click)="editSection(4)" style="cursor: pointer;"><i
                                        class="material-icons left">edit</i>Edit</a>
                            </div>
                            <p class="col s12" *ngIf="ApplicationModel.car1_value==0 && ApplicationModel.car2_value==0">
                                No
                                Vehicle to Declare.</p>

                            <div class="row col s12">
                                <div class="section row col s12" *ngIf="ApplicationModel.car1_value > 0">
                                    <div class="divider"></div>
                                    <p class="col s12 m5"><strong>Model :</strong> {{ApplicationModel.car1_make}}</p>
                                    <p class="col s12 m3"><strong>Year Built :</strong> {{ApplicationModel.car1_year}}
                                    </p>
                                    <p class="col s12 m4"><strong>Value $: </strong> {{ApplicationModel.car1_value}}</p>
                                </div>
                                <div class="section row col s12" *ngIf="ApplicationModel.car2_value > 0">
                                    <div class="divider"></div>
                                    <p class="col s12 m5"><strong>Model :</strong> {{ApplicationModel.car2_make}}</p>
                                    <p class="col s12 m3"><strong>Year Built :</strong> {{ApplicationModel.car2_year}}
                                    </p>
                                    <p class="col s12 m4"><strong>Value $: </strong> {{ApplicationModel.car2_value}}</p>
                                </div>
                            </div>

                            <div class="row col s12">
                                <h5 class="col s8">Assets</h5>
                                <a class="col s4 blue-text right waves-effect waves-light btn-flat"
                                    (click)="editSection(5)" style="cursor: pointer;"><i
                                        class="material-icons left">edit</i>Edit</a>
                            </div>
                            <p class="col s12" *ngIf="tmpAssetsModel?.length==0">No Assets to Declare.</p>
                            <div class="row col s12" *ngIf="tmpAssetsModel?.length > 0">
                                <div class="row col s12" *ngFor="let asset of tmpAssetsModel; let i=index;">
                                    <p class="col s12 m6"> <strong> {{i+1}} . Asset: </strong> {{asset.asset}}</p>
                                    <p class="col s12 m6"> <strong>Amount :</strong> {{asset.amount}}</p>
                                </div>
                            </div>
                        </li>

                        <li class="section row collection-item">
                            <div class="row col s12">
                                <h5 class="col s8">Applicant Incomes Information</h5>
                                <a class="col s4 blue-text right waves-effect waves-light btn-flat"
                                    (click)="editSection(6)" style="cursor: pointer;"><i
                                        class="material-icons left">edit</i>Edit</a>
                            </div>
                            <p class="col s12" *ngIf="applicantIncomeModel?.length==0">No Incomes to Declare.</p>
                            <div class="row col s12" *ngIf="applicantIncomeModel?.length > 0">
                                <div class="section row col s12"
                                    *ngFor="let income of applicantIncomeModel; let i=index">
                                    <p class="col s12 m4"><strong> {{i+1}} . Income: </strong> {{income.income}}</p>
                                    <p class="col s12 m4"> <strong>Amount :</strong> {{income.amount}}</p>
                                    <p class="col s12 m4"> <strong>Date Recevied :</strong> {{income.date_received}}</p>
                                </div>
                            </div>
                        </li>

                        <li class="section row collection-item" *ngIf="spouseIncomeModel && HasSpouse">
                            <div class="row col s12">
                                <h5 class="col s8">Spouse Incomes Information</h5>
                                <a class="col s4 blue-text right waves-effect waves-light btn-flat"
                                    (click)="editSection(7)" style="cursor: pointer;"><i
                                        class="material-icons left">edit</i>Edit</a>
                            </div>
                            <p class="col s12" *ngIf="spouseIncomeModel.length==0">Spouse has No Incomes to Declare.</p>
                            <div class="row col s12" *ngIf="spouseIncomeModel.length > 0">
                                <div class="section row col s12" *ngFor="let income of spouseIncomeModel;let i=index">
                                    <p class="col s12 m4"><strong> {{i+1}} . Income: </strong> {{income.income}}</p>
                                    <p class="col s12 m4"> <strong>Amount :</strong> {{income.amount}}</p>
                                    <p class="col s12 m4"> <strong>Date Recevied :</strong> {{income.date_received}}</p>
                                </div>
                            </div>
                        </li>

                        <li class="section row collection-item">
                            <div class="col s12">
                                <h5 class="col s8">Housing</h5>
                                <a class="col s4 blue-text right waves-effect waves-light btn-flat"
                                    (click)="editSection(8)" style="cursor: pointer;"><i
                                        class="material-icons left">edit</i>Edit</a>
                            </div>
                            <div class="col s12">

                                <p class="col s12 m6" *ngIf="ApplicationModel.isYesOfOthers"><strong>Is Accommodation
                                        Shared
                                        ?</strong> Yes</p>
                                <p class="col s12 m6" *ngIf="!ApplicationModel.isYesOfOthers"><strong>Is Accommodation
                                        Shared ?</strong> No</p>


                                <p class="col s12 m6"><strong>Person for Shelter Costs ?</strong>
                                    {{ApplicationModel.WhoOfOthers }}</p>
                                <p class="col s12 m6"><strong>Relationship: </strong>
                                    {{ApplicationModel.relationship_headofhouse }}</p>

                                <p class="col s12 m6" *ngIf="ApplicationModel.isRentedOfOthers=='rented'"><strong>Rented
                                        or
                                        owned ?</strong> Rented</p>
                                <p class="col s12 m6" *ngIf="ApplicationModel.isRentedOfOthers=='owned'"><strong>Rented
                                        or
                                        owned ?</strong> Owned</p>
                                <p class="col s12 m6" *ngIf="ApplicationModel.isRentedOfOthers=='bandowned'">
                                    <strong>Rented
                                        or owned ?</strong>Band Owned
                                </p>

                                <p class="col s12 m6" *ngIf="ApplicationModel.isYesOfCMHC"><strong>CMHC assisted
                                        housing:
                                    </strong> Yes</p>
                                <p class="col s12 m6" *ngIf="!ApplicationModel.isYesOfCMHC"><strong>CMHC assisted
                                        housing:
                                    </strong> No</p>

                                <p class="col s12 m6"><strong>Utilities ($): </strong>
                                    {{ApplicationModel.UtilitiesOfOthers
                                    }}</p>
                                <p class="col s12 m6"><strong>Fuel ($): </strong> {{ApplicationModel.FuelOfOthers }}</p>
                                <p class="col s12 m6"><strong>Maintenance ($): </strong>
                                    {{ApplicationModel.MaintenanceOfOthers }}</p>
                                <p class="col s12 m6"><strong>Garbage, Water, Sewer ($): </strong>
                                    {{ApplicationModel.GarbageOfOthers }}</p>
                                <p class="col s12 m6"><strong>Rent / Housing loan PMT ($): </strong>
                                    {{ApplicationModel.LoanPMTOfOthers }}</p>
                                <br>
                                <p class="col s12"><strong>Comments: </strong> {{ApplicationModel.comments }}</p>
                            </div>
                        </li>
                    </ul>

                    <div class="row col s12">
                        <button type="button" (click)="BackToApp()" class="col s12 m4  waves-effect blue btn">Back to
                            Application<i class="material-icons right">Back to The Application</i></button>
                        <button type="button" (click)="SubmitApplication()"
                            class="col s12 m4 offset-m4 waves-effect green btn">Submit<i
                                class="material-icons right">send</i></button>
                    </div>
                </div>
            </div>
        </div>
    </main>
</div>