export let icons={
    'facebook':{
        text:'fa-facebook',
        color:'text-[#4267B2]'
    },
    'instagram':{
        text:'fa-instagram',
        color:'text-[#C32889]'
    },
    'pinterest':{
        text:'fa-pinterest-square',
        color:'text-[#E60023]'
    },
    "twitter":{
        text:'fa-twitter',
        color:'text-[#1da1f2]'
    },
    'reddit':{
        text:'fa-reddit',
        color:'text-[#FF4500]'
    },
    'youtube':{
        text:'fa-youtube-play',
        color:'text-[#c4302b]'
    },
    'fbg':{
        text:'fa-facebook-square',
        color:'text-[#4267B2]'
    },
    'telegram':{
        text:'fa-pinterest-telegram',
        color:'text-[#4267B2]'
    },
    "linkedin":{
        text:"fa-linkedin",
        color:'text-[#0072b1]'
    },
    'gmb':{
        text:'fa-google',
        color:'text-[#4267B2]'
    },
    'tiktok':{
        text:'fa-tiktok',
        color:'text-[#4267B2]'
    }

}