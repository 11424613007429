<div class="container">
  <div class="row">
    <div class="section"></div>
    <div class="row center">
      <h5>Employer Login </h5>
    </div>

    <div class="col m1 l2 "></div>
    <div class="col s12 m10 l8 ">
      <div class="card">
        <div class="card-content  white">
          <form [formGroup]="loginForm" (ngSubmit)="login(loginForm)">
            <div class="row">

              <div class="input-field col s12 l12">
                <i class="material-icons prefix">email</i>
                <input id="email" type="email" formControlName='email' required class="validate" autocomplete="off" />
                <label for="email">Email</label>
                <div *ngIf="(email.dirty || email.touched) && email.invalid">
                  <small *ngIf="email.errors.required" class="red-text"> Email is required. </small>
                  <small *ngIf="email.errors.email" class="red-text"> wrong email format. </small>
                </div>
              </div>

              <div class="input-field col s12 l12">
                <i class="material-icons prefix">lock</i>
                <input id="password" type="password" formControlName='password' required autocomplete="off"
                  class="validate" minlength="4" maxlength="25" />
                <label for="password">Password</label>
                <div *ngIf="(password.dirty || password.touched) && password.invalid">
                  <small *ngIf="password.errors.required" class="red-text"> The Password Is Required.
                  </small>
                  <small *ngIf="password.errors.minlength" class="red-text"> Password Length Must Be At Least 4
                    Characters . </small>
                  <small *ngIf="password.errors.maxlength" class="red-text"> Password Must Not Exceed 25 Characters .
                  </small>
                </div>
              </div>

              <label style='float: right;'>
                <a class='red-text' [routerLink]="['/auth/change-password']"><b>Forgot Password?</b></a>
              </label>
            </div>

            <div class="row">
              <div class="input-field col s12 center">
                <button type="submit" [disabled]="!loginForm.valid"
                  [ngClass]="'col s12 btn waves-effect ' + themeColor">
                  <i class="material-icons left">lock</i> Login
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    
    <div class="col m1 l2 "></div>
  </div>
</div>