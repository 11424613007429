import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppSettingsService {
  isDarkMode$: any;
  get(arg0: string) {
    throw new Error('Method not implemented.');
  }

  getLimitedContentList(arg0: number) {
    throw new Error('Method not implemented.');
  }

  collectionRef: AngularFirestoreCollection<any>;
  documentRef: AngularFirestoreDocument<any>;

  appThemeColor: any;
  buttonType: any;
  logoType: any;
  bodyBackgroundColor: any;
  directSignup: any;
  welcomeSetup: any;
  currentMenu: any;
  buttonCardType: any;
  authBackgroundColor: any;
  authSectionColor: any;
  govNames: any;

  gridType: any;
  screenType:any;

  appTitle:any;
  description:any;
  private metaTagDocId = 'defaultMetaTagId';

  private fromMenu = new BehaviorSubject<string>(null);
  private authformBgColorSubject: BehaviorSubject<string> = new BehaviorSubject<string>('#ffffff');

  constructor(private db: AngularFirestore) { }

  // -------------- Contact Settings -------------------------------
  saveContactSettings(model: any) {
    model.id = this.db.createId();
    return this.db.collection('ContactSettings').doc(model.id).set(model);
  }

  updateContactSettings(model: any) {
    return this.db.collection('ContactSettings').doc(model.id).update(model);
  }

  getContactSettingsList(): AngularFirestoreCollection<any[]> {
    this.collectionRef = this.db.collection('ContactSettings');
    return this.collectionRef;
  }

  // --------------- Landing Page Settings -------------------------------
  saveLandingPageSettings(model: any) {
    model.id = this.db.createId();
    return this.db.collection('LandingPageSettings').doc(model.id).set(model);
  }

  updateLandingPageSettings(model: any) {
    return this.db.collection('LandingPageSettings').doc(model.id).update(model);
  }

  getLandingPageSettings(): AngularFirestoreCollection<any[]> {
    this.collectionRef = this.db.collection('LandingPageSettings');
    return this.collectionRef;
  }

  // --------------- Home Page Settings -------------------------------
  saveHomePageSettings(model: any) {
    model.uid = this.db.createId();
    return this.db.collection('HomePage').doc(model.uid).set(model);
  }

  updateHomePageSettings(model: any) {
    return this.db.collection('HomePage').doc(model.uid).update(model);
  }

  getHomePageSettings(): AngularFirestoreCollection<any[]> {
    this.collectionRef = this.db.collection('HomePage');
    return this.collectionRef;
  }

  getSliderSettings(): any {
    this.collectionRef = this.db.collection('HomePage');
  }


  //  ------------------ App Menu Settings -------------------------------
  saveMenuSettings(model: any) {
    model.id = this.db.createId();
    return this.db.collection('AppMenu').doc(model.id).set(model);
  }

  updateMenuSettings(model: any) {
    return this.db.collection('AppMenu').doc(model.id).update(model);
  }

  getMenuSettings(): AngularFirestoreCollection<any[]> {
    this.collectionRef = this.db.collection('AppMenu');
    return this.collectionRef;
  }

  // -------------------------- Signup and login page Settings -------------------------------
  getSignUpLoginSetting() {
    this.collectionRef = this.db.collection('SignupAndLoginPages');
    return this.collectionRef;
  }

  saveSignUpPageSettings(model: any) {
    model.id = this.db.createId();
    return this.db.collection('SignupAndLoginPages').doc(model.id).set(model);
  }

  saveLoginPageSettings(model: any) {
    model.id = this.db.createId();
    return this.db.collection('SignupAndLoginPages').doc(model.id).set(model);
  }

  saveWelcomeModalSettings(model: any) {
    model.id = this.db.createId();
    return this.db.collection('SignupAndLoginPages').doc(model.id).set(model);
  }

  updateSignUpPageSettings(model: any) {
    return this.db.collection('SignupAndLoginPages').doc(model.id).update(model);
  }

  updateLoginPageSettings(model: any) {
    return this.db.collection('SignupAndLoginPages').doc(model.id).update(model);
  }

  updateWelcomeModalSettings(model: any) {
    return this.db.collection('SignupAndLoginPages').doc(model.id).update(model);
  }

  getSignUpPageSettings(): AngularFirestoreCollection<any[]> {
    this.collectionRef = this.db.collection('SignupAndLoginPages', ref => ref.where('name', '==', 'Signup'));
    return this.collectionRef;
  }

  getLoginPageSettings(): AngularFirestoreCollection<any[]> {
    this.collectionRef = this.db.collection('SignupAndLoginPages', ref => ref.where('name', '==', 'Login'));
    return this.collectionRef;
  }

  getWelcomeModalSettings(): AngularFirestoreCollection<any[]> {
    this.collectionRef = this.db.collection('SignupAndLoginPages', ref => ref.where('name', '==', 'Welcome'));
    return this.collectionRef;
  }

  // -------------- Admin Menu Settings ----------------
  getAdminMenus(): AngularFirestoreCollection<any[]> {
    this.collectionRef = this.db.collection('AdminMenus');
    return this.collectionRef;
  }

  saveAdminMenu(model: any) {
    model.id = this.db.createId();
    return this.db.collection('AdminMenus').doc(model.id).set(model);
  }

  updateAdminMenu(model: any) {
    return this.db.collection('AdminMenus').doc(model.id).update(model);
  }

  updateAdminMenus(model: any[]) {
    model.forEach(menu => {
      this.db.collection('AdminMenus').doc(menu.id).update(menu);
    });
  }

  getMenuName(name) {
    this.collectionRef = this.db.collection('AdminMenus', ref => ref.where('name', '==', name));
    return this.collectionRef;
  }

  getMenuDescription(name) {
    this.collectionRef = this.db.collection('AdminMenus', ref => ref.where('name', '==', name));
    return this.collectionRef;
  }

  UpdateMenu(mainMenu, updated): Promise<string> {
    const MainMenus = ["NewsAndEvents", "PollsAndSurveys", "Communication"];
    const collection = this.db.collection('AdminMenus', ref => ref.where('name', '==', mainMenu));
    return collection.get()
      .toPromise()
      .then((querySnapshot) => {
        const document = querySnapshot.docs[0];
        if (document) {
          if (MainMenus.includes(mainMenu)) {
            return document.ref.update({ 'displayName': updated }).then(() => "Successfully updated ")
              .catch(error => Promise.reject(`Error updating document: ${error}`));
          }
          else {
            return document.ref.update({ 'submenus': updated }).then(() => "Successfully updated ")
              .catch(error => Promise.reject(`Error updating document: ${error}`));
          }
        } else {
          return Promise.reject(`Document not found!`);
        }
      })
      .then((updated) => {
        return updated; // Return the confirmation message to the calling function
      })
      .catch((error) => {
        return Promise.reject(`Error retrieving document: ${error}`);
      });
  }

  updateMenuOnPublic(menu, updated, title?) {
    const collection = this.db.collection('HomePage');
    collection.get()
      .toPromise()
      .then((querySnapshot) => {
        const document = querySnapshot.docs[0];
        if (document) {
          const documentData: any = document.data();
          const sections: any[] = documentData.sections || [];
          const section = sections.find((s) => s.name === menu);
          if (section) {
            if(title == 'chief') section.chiefTitle = updated;
            else if(title == 'councillor') section.councillorTitle = updated;
            else section.displayName = updated;
            document.ref
              .update({ 'sections': sections })
              .then(() => "Successfully updated ¡")
              .catch(error => Promise.reject(`Error updating document: ${error}`));
          }
        } else {
          return Promise.reject(`Document not found!`);
        }
      })
      .then((updated) => {
        return updated; // Return the confirmation message to the calling function
      })
      .catch((error) => {
        return Promise.reject(`Error retrieving document: ${error}`);
      });
  }

  // ------------------- Member Menu Settings -------------------------------
  getMemberMenus(): AngularFirestoreCollection<any[]> {
    this.collectionRef = this.db.collection('MemberMenus');
    return this.collectionRef;
  }

  saveMemberMenu(model: any) {
    model.id = this.db.createId();
    return this.db.collection('MemberMenus').doc(model.id).set(model);
  }

  // saveMemberMenus(model: any) {
  //   model.forEach(menu => {
  //     menu.id = this.db.createId();
  //     this.db.collection('MemberMenus').doc(menu.id).update(menu);
  //   });
  // }

  updateMemberMenu(model: any) {
    return this.db.collection('MemberMenus').doc(model.id).update(model);
  }

  // updateMemberMenus(model: any[]) {
  //   model.forEach(menu => {
  //     this.db.collection('MemberMenus').doc(menu.id).update(menu);
  //   });
  // }

  // ------------------------------App Setting ------------------
  saveAppSettings(model: any) {
    model.id = this.db.createId();
    return this.db.collection('AppSettings').doc(model.id).set(model);
  }

  updateAppSettings(model: any) {
    return this.db.collection('AppSettings').doc(model.id).update(model);
  }

  getAppSettingsList(): AngularFirestoreCollection<any[]> {
    this.collectionRef = this.db.collection('AppSettings');
    return this.collectionRef;
  }

  saveAppSetting(model: any) {
    model.id = this.db.createId();
    return this.db.collection('AppSettings').doc(model.id).set(model);
  }

  updateAppSetting(model: any) {
    return this.db.collection('AppSettings').doc(model.id).update(model);
  }

  getAppSetting(): AngularFirestoreCollection<any[]> {
    this.collectionRef = this.db.collection('AppSettings');
    return this.collectionRef;
  }

  setBodyBackgroundColor(bodyBgColor) {
    this.bodyBackgroundColor = bodyBgColor;
  }
  getBodyBackgroundColor() {
    return this.bodyBackgroundColor;
  }
  setAuthBackgroundColor(authformBgColor) {
    this.authBackgroundColor = authformBgColor;
  }
  getAuthBackgroundColor() {
    return this.authBackgroundColor;
  }
  setAuthSectionColor(authformSColor) {
    this.authSectionColor = authformSColor;
  }
  getAuthSectionColor() {
    return this.authSectionColor;
  }

  setAppThemeColor(themeColor) {
    this.appThemeColor = themeColor;
  }
  setAppTitle(appTitle){
    this.appTitle = appTitle;
  }
  setAppDescription(description){
   this.description = description;
  }

  getAppThemeColor() {
    return this.appThemeColor;
  }

  setButtonType(buttonOption) {
    this.buttonType = buttonOption;
  }

  getButtonType() {
    return this.buttonType;
  }

  setLogoType(logoOption) {
    this.logoType = logoOption;
  }

  getLogoType() {
    return this.logoType;
  }

  setGridType(gridOption) {
    this.gridType = gridOption;
  }

  getGridType() {
    return this.gridType;
  }

  setDirectSignUp(option) {
    this.directSignup = option;
  }

  getDirectSignUp() {
    return this.directSignup;
  }

  setWelcomeSetup(welcomeOption) {
    this.welcomeSetup = welcomeOption;
  }

  getWelcomeSetup() {
    return this.welcomeSetup;
  }

  setCurrentMenu(menu) {
    if (menu) this.currentMenu = menu;
  }

  getCurrentMenu() {
    return this.currentMenu;
  }

  getGovTitle() {
    return this.govNames;
  }

  setGovTitle(names) {
    return this.govNames = names;
  }
  // ----------------------App Setting -------------

  updateFromMenu(from: string) {
    this.fromMenu.next(from);
  }

  getFromMenu() {
    return this.fromMenu.asObservable();
  }

  setScreenType(darkOption) {
    this.screenType = darkOption;
  }

  getScreenType() {
    return this.screenType;
  }
  
  // saveMetaDataSettings(model:any){
  //   model.id = this.db.createId();
  //   console.log("data is coming", model);
  //   return this.db.collection('MetaTag').doc(model.id).set(model);
  // }

  // getMetadata(){
  //   return this.db.collection('MetaTag');
  // }
  // updateMetaDataSettings(model: any){
  //   return this.db.collection('MetaTag').doc(model.id).update(model);
  // }
  

  saveMetaDataSettings(model: any): Promise<void> {
    model.id = this.metaTagDocId; // Set to a fixed ID
    return this.db.collection('MetaTag').doc(this.metaTagDocId).set(model, { merge: true });
  }


  updateMetaDataSettings(model: any): Promise<void> {
    return this.db.collection('MetaTag').doc(this.metaTagDocId).update(model);
  }

  getMetadata(){
    return this.db.collection('MetaTag');
    // return this.db.collection('MetaTag').doc(this.metaTagDocId); 
  }

  // saveSignature(id, model: any): Promise<void> {
  //   return this.db.collection('AppSettings').
  //   doc(id).set({messageSignature: model});
  // }

  updateSignature(id, model: any): Promise<void> {
    return this.db.collection('AppSettings').doc(id).update({messageSignature: model});
  }


}