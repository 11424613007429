import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {

  themeColor = environment.appTheme.themeColor;

  constructor( private location: Location) { }

  ngOnInit() {
  }

  backClicked() {
    this.location.back();
  }
}
