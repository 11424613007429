<div class="col s12">
  <div class="body-h">

    <div class="header-setting b-m-2">
      <div class="col l12 m12 s12">
        <h4 *ngIf="!editName">
          <span *ngIf="sectionName">{{sectionName}}</span>
          <span *ngIf="!sectionName">Department Statistics</span>
          <i class="material-icons edit-name" [ngStyle]="{'color': themeColor}" (click)="editNameClicked()">edit</i>
        </h4>

        <div class="input-field" *ngIf="editName">
          <input type="text" [(ngModel)]="sectionName" class="left col s6 validate" autocomplete="off" />
          <button type="button" class="btn ll-m-5" [ngStyle]="{'background-color': themeColor}" (click)="saveName()">Save</button>
        </div>

        <p class="col s12 bold-300 sub-title-section3">It is a long established fact that a reader will be distracted by the
          readable content of a
          page when looking at its layout.</p>
      </div>
    </div>

    <main class="StickyContent white ">
      <div class="row ">
        <div class="">
          <div *ngIf="departmentList" class="dep-stat-list">
            <div class="card-panel white col s12 m12 l5" *ngFor="let d of departmentList">
              <ul class="row collection">
                <li>
                  <h6 class="center bold-700" [innerHTML]="d.name"> </h6>
                </li>
                <li class="collection-item">
                  <div>Sub Departments<a class="secondary-content">{{getNumberOfSubDepartment(d.id)}}</a></div>
                </li>
                <li class="collection-item">
                  <div>Managers<a class="secondary-content">{{getNumberOfManagers(d.id)}}</a></div>
                </li>
                <li class="collection-item">
                  <div>Employees<a class="secondary-content">{{getNumberOfEmployees(d.id)}}</a></div>
                </li>
                <li class="collection-item">
                  <div>News / Events<a class="secondary-content">{{getNumberOfDepNews(d.id)}}</a></div>
                </li>
                <li class="collection-item">
                  <div>Messages Received<a class="secondary-content">{{getNumberOfMsgReceived(d.id)}}</a></div>
                </li>
                <li class="collection-item">
                  <div>Messages Sent<a class="secondary-content">{{getNumberOfMsgSent(d.id)}}</a></div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </main>

    <div id="underConstructionModal" class="modal center b-n-1">
      <div class="modal-content">
        <i class="material-icons right modal-close">close</i><br />
        <h6 class="title-section2 bold-600 black-text">Under Construction</h6>
        <p class="sub-title-section bold-100">We’re working hard to make this feature available soon. </p>
        <img src="https://img.icons8.com/wired/344/tow-truck.png" height="150px" />
        <p class="sub-title-section bold-100">In the meantimee if you need some support? Contact us on info&#64;mynation.app
        </p>
        <p class="sub-title-section bold-100">Please Contanct your Administrator to gain access.</p>
      </div>
    </div>

  </div>
</div>