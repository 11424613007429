<div class="content-left">
  <div class="card-yes ">
    <div class=" border-content col l11 s12 m12">
      <div class="row center-align">
        <strong *ngIf="model">
          <h6 class="center" [innerHTML]="model.name"></h6>
        </strong>
      </div>

      <div class="row ">
        <div class="col s12">

          <div *ngIf="model">

            <div class="card-panel white row">
              <p [innerHTML]="model.mandate"></p>
            </div>

            <div class="card-panel white row">
              <ul>
                <li class="col s12 l6 p-b-5"><a class="sub-title-section1 bold-100 black-text icon-text" ><i
                      class="material-icons title-section2 p-r-1">location_on</i>
                    <strong>Address</strong>: {{model.address}}</a>
                </li>

                <li class="col s12 l6 p-b-5"><a class="sub-title-section1 bold-100 black-text icon-text"><i
                      class="material-icons title-section2 p-r-1">location_on</i>
                    <strong>Postal Code</strong>: {{model.postal_code}}</a>
                </li>

                <li class="col s12 l6 p-b-5"><a class="sub-title-section1 bold-100 black-text icon-text" [href]="'tel:' + model.phone"><i
                      class="material-icons title-section2 p-r-1">phone</i>
                    <strong>Phone</strong>: {{model.phone}}</a>
                </li>

                <li class="col s12 l6 p-b-5"><a class="sub-title-section1 bold-100 black-text icon-text" [href]="'tel:' + model.fax"><i
                      class="material-icons title-section2 p-r-1">tty</i>
                    <strong> Fax</strong>: {{model.fax}}</a>
                </li>

              </ul>
            </div>


            <div class=" card-panel white row" *ngIf="false">
              <ul class="row collection ">

                <li class="collection-item">

                  <i class="material-icons purple-text left"
                    style="display: inline-flex; vertical-align: top; margin-right: 5px;">location_on</i>
                  <strong>Address </strong>
                  <span class="secondary-content"><strong>{{model.address}}</strong> </span>
                </li>

                <li class="collection-item">
                  <i class="material-icons teal-text left"
                    style="display: inline-flex; vertical-align: top; margin-right: 5px;">location_on</i>
                  <strong style="text-transform: uppercase;">Postal Code </strong> <span
                    class="secondary-content"><strong>{{model.postal_code}}</strong> </span>
                </li>

                <li class="collection-item">
                  <i class="material-icons blue-text left"
                    style="display: inline-flex; vertical-align: top; margin-right: 5px;">local_phone</i>
                  <strong> Phone </strong> <span class="secondary-content"><strong>{{model.phone}}</strong> </span>
                </li>

                <li class="collection-item">
                  <i class="material-icons pink-text left"
                    style="display: inline-flex; vertical-align: top; margin-right: 5px;">tty</i>
                  <strong> Fax</strong> <span class="secondary-content"><strong>{{model.fax}}</strong> </span>
                </li>
              </ul>
            </div>

          </div>
        </div>
      </div>
     
    </div>
  </div>
</div>

<!-- <div class="content-left ">
    <div class=" card-yes ">
      <div class=" border-content  col s12 m4 l7">
        <strong *ngIf="model">
          <h6 class="center" [innerHTML]="model.name"></h6>
        </strong>
        <div *ngIf="model">

          <div class="card-panel white row">
            <p><strong>{{model.mandate}}</strong></p>
          </div>
  
          <div class=" card-panel white row">
            <ul class="row collection ">
  
              <li class="collection-item">
  
                <i class="material-icons purple-text left"
                  style="display: inline-flex; vertical-align: top; margin-right: 5px;">location_on</i>
                <strong>Address </strong>
                <span class="secondary-content"><strong>{{model.address}}</strong> </span>
              </li>
  
              <li class="collection-item">
                <i class="material-icons teal-text left"
                  style="display: inline-flex; vertical-align: top; margin-right: 5px;">location_on</i>
                <strong style="text-transform: uppercase;">Postal Code </strong> <span
                  class="secondary-content"><strong>{{model.postal_code}}</strong> </span>
              </li>
  
              <li class="collection-item">
                <i class="material-icons blue-text left"
                  style="display: inline-flex; vertical-align: top; margin-right: 5px;">local_phone</i>
                <strong> Phone </strong> <span class="secondary-content"><strong>{{model.phone}}</strong> </span>
              </li>
  
              <li class="collection-item">
                <i class="material-icons pink-text left"
                  style="display: inline-flex; vertical-align: top; margin-right: 5px;">tty</i>
                <strong> Fax</strong> <span class="secondary-content"><strong>{{model.fax}}</strong> </span>
              </li>
            </ul>
          </div>
  
        </div>
      </div>
    </div>

  </div> -->