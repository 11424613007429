import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiKeyManagerService } from './apiKeyManager.Service';
import { EducationModel } from 'src/app/models/educationModel';

@Injectable({
  providedIn: 'root'
})
export class EducationService {

  constructor(private httpClient: HttpClient, private keyManager: ApiKeyManagerService) { }

  url = environment.firstNation.apiUrl + 'api/Education/';

  saveEducation(model: EducationModel): Observable<any> {
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
    return this.httpClient.post<EducationModel>(this.url + 'SaveEducation/', JSON.stringify(model), { headers });
  }

  updateEducation(model: EducationModel): Observable<any> {
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
    return this.httpClient.post<EducationModel>(this.url + 'EditEducation/', JSON.stringify(model), { headers });
  }

  getEducationById(id: number): Observable<EducationModel[]> {
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
    return this.httpClient.get<EducationModel[]>(
      this.url + 'GetEducationByID/' + id, { headers });
  }

  getEducationByMemberId(id: number): Observable<EducationModel[]> {
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
    return this.httpClient.get<EducationModel[]>(
      this.url + 'GetEducationByMemberId/' + id, { headers });
  }

  deleteEducation(model: EducationModel): Observable<any> {
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
    return this.httpClient.post<EducationModel>(this.url + 'DeleteEducation/', JSON.stringify(model), { headers });
  }


}
