<h6 class="center p-m-2 b-m-2" *ngIf="user"><strong> Welcome {{user.LastName}} {{user.GivenName}} </strong></h6>

<div class="row">
  <div class="col m1 l2"></div>

  <div class="col s12 m10 l8">
    <div class="row" *ngIf="user">
      <a (click)="boardMember()" *ngIf="isBoardMember" style="cursor: pointer;">
        <div class="col s12">
          <div class="row card-panel white hoverable">
            <div class="col s12">
              <div class="col s2 center">
                <i class="material-icons blue-text">vpn_key</i>
              </div>
              <div class="col s10 center">
                <strong class="black-text">Update Board Member Account</strong>
              </div>
            </div>
          </div>
        </div>
      </a>

      <a (click)="chief()" *ngIf="user.isChief" style="cursor: pointer;">
        <div class="col s12">
          <div class="row card-panel white hoverable">
            <div class="col s12">
              <div class="col s2 center">
                <i class="material-icons teal-text">vpn_key</i>
              </div>
              <div class="col s10 center">
                <strong class="black-text">Update My Chief Account</strong>
              </div>
            </div>
          </div>
        </div>
      </a>

      <a (click)="councillor()" *ngIf="user.isCouncillor" style="cursor: pointer;">
        <div class="col s12">
          <div class="row card-panel white hoverable">
            <div class="col s12">
              <div class="col s2 center">
                <i class="material-icons teal-text">vpn_key</i>
              </div>
              <div class="col s10 center">
                <strong class="black-text">Update My Counsillor Account</strong>
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>

    <div class="row">
      <button [ngClass]="'col s12 btn waves-effect ' + themeColor" (click)="backClicked()" style="width: 100%"><i
          class="material-icons left">arrow_back_ios</i> Back To Previous Page</button>
    </div>
  </div>

  <div class="col m1 l2"></div>
</div>