<div class="body-h">
  <div class="header-setting b-m-2">
    <h4>Add Reference</h4>
  </div>

  <main class="StickyContent white row">

    <div id="step0" class="col s12 l11">

      <form (ngSubmit)="submitForm(addReferenceForm.value)" [formGroup]="addReferenceForm">
        <div class="row card-panel">

          <div class="input-field class col s12 l6">
            <div class="label-input">First Name : <strong class="red-text">*</strong></div>
            <input formControlName="firstname" id="firstname" type="text" maxlength="50" autocomplete="off" require
              class="capitalize validate" placeholder="First Name">
            <div *ngIf="(firstname.dirty || firstname.touched) && firstname.invalid">
              <small *ngIf="firstname.errors.required" class="red-text">The First Name Is Required!
              </small>
            </div>
          </div>

          <div class="input-field class col s12 l6">
            <div class="label-input"> Last Name : <strong class="red-text">*</strong></div>
            <input formControlName="lastname" id="lastname" type="text" maxlength="50" autocomplete="off" require
              class="capitalize validate" placeholder="Last Name">
            <div *ngIf="(lastname.dirty || lastname.touched) && lastname.invalid">
              <small *ngIf="lastname.errors.required" class="red-text">The Last Name Is Required!
              </small>
            </div>
          </div>

          <div class="input-field class col s12 l6">
            <div class="label-input"> Business or Organization </div>
            <input formControlName="organization" id="organization" type="text" maxlength="100" autocomplete="off"
              require class="capitalize validate" placeholder="Busineess or Organization">
          </div>

          <div class="input-field class col s12 l6">
            <div class="label-input"> Postion or Relationship </div>
            <input formControlName="relationship" id="relationship" type="text" maxlength="100" autocomplete="off"
              require class="capitalize validate" placeholder="Postion or Relationship">
          </div>

          <div class="input-field col s12 l6">
            <div class="label-input">Telephone Number<strong class="red-text">*</strong></div>
            <!-- <input id="phone" type="tel" formControlName='phone' required class="validate"
              [textMask]="{mask: phoneNumberMask}" autocomplete="off" placeholder="Telephone Number" /> -->
              <input id="phone" type="tel" formControlName='phone' required class="validate"
              autocomplete="off" placeholder="Telephone Number" />
            <div *ngIf="(phone.dirty || phone.touched) && phone.invalid">
              <small *ngIf="phone.errors.required" class="red-text"> The Telephone number is required. </small>
            </div>
          </div>
        </div>

        <div class="section"></div>
        <div class="col s12">
          <button class='at-btn col s12 btn waves-effect success' style="margin-bottom: 15px;" type="submit"
            [disabled]="!addReferenceForm.valid">
            <i class="material-icons right">add_circle</i> Save Reference
          </button>

          <button type="button" [ngClass]="'col s12 btn waves-effect ' + themeColor " (click)="backClicked()">
            <i class="material-icons left">keyboard_arrow_left</i> Back To Reference List
          </button>
        </div>
      </form>
    </div>
  </main>
</div>