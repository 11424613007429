import { Component, OnInit } from '@angular/core';
import { MemberService } from 'src/app/services/api/member.service';
import { MemberModel } from 'src/app/models/memberModel';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-familymemberdetail',
  templateUrl: './familymemberdetail.component.html',
  styleUrls: ['./familymemberdetail.component.css']
})

export class FamilymemberdetailComponent implements OnInit {

  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  member: MemberModel;
  memberID: string;
  dep = true;

  constructor(private memberService: MemberService, private location: Location, private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.memberID = params.get('id');
    });

    if (this.memberID !== null) {
      const id = +this.memberID;
      this.memberService.getMember(id).subscribe(x => {
        this.member = x[0];
      });
    }
  }

  backClicked() {
    this.location.back();
  }

}
