import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ManageAdminService } from 'src/app/services/api/manage-admin.service';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { toast } from 'materialize-css';
import { environment } from 'src/environments/environment';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';

function passwordConfirming(c: AbstractControl): any {
  if (!c.parent || !c) { return; }
  const pwd = c.parent.get('createPassword');
  const cpwd = c.parent.get('confirmPassword');

  if (!pwd || !cpwd) { return; }
  if (pwd.value !== cpwd.value) {
    return { invalid: true };
  }
}

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-register-admin',
  templateUrl: './register-admin.component.html',
  styleUrls: ['./register-admin.component.css']
})
export class RegisterAdminComponent implements OnInit {

  defaultThemeColor = environment.appTheme.adminThemeColor;
  fontColor = environment.appTheme.adminFontColor;
  themeColor: any;
  isDemo = false;
  admin: any;
  verificationForm: UntypedFormGroup;
  registrationForm: UntypedFormGroup;
  step1 = true;
  step2 = false;

  showPassword = false;
  showConfirmPassword = false;

  constructor(private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private adminService: ManageAdminService,
    public appSettingsService: AppSettingsService,
    private authService: AuthService, private db: AngularFirestore,) {

    this.appSettingsService.getAppSettingsList().valueChanges().subscribe(
      appSettings => {
        if (appSettings && appSettings.length > 0) {
          let appSetting = appSettings[0];
          this.themeColor = this.appSettingsService.getAppThemeColor() ? this.appSettingsService.getAppThemeColor() : this.defaultThemeColor;
        }
      });
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      if (params.get('id')) {
        const id = + params.get('id');
        this.adminService.getAdmin(id).subscribe(data => {
          if (data) {
            this.admin = data[0];
            if (this.admin.userGroup === 'Demo') {
              this.registrationForm = this.fb.group({
                createPassword: ['', [Validators.required]],
                confirmPassword: ['', [Validators.required, passwordConfirming]],
              });

              this.isDemo = true;
              this.step1 = false;
              this.step2 = true;
            } else {

              this.verificationForm = this.fb.group({
                socialPassword: ['', [Validators.required]],
              });
            }
          }
        });
      }
    });
  }

  verification(data: any) {
    if (data.value.socialPassword !== this.admin.password) {
      // tslint:disable-next-line: max-line-length
      toast({ html: '<span> <i class="material-icons left">error_outline</i> Invalid Password! Please Try Again. </span>', classes: 'red' });
      return;
    }

    this.registrationForm = this.fb.group({
      createPassword: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required, passwordConfirming]],
    });
    toast({ html: '<span> <i class="material-icons left">check_circle_outline</i> Verification Success. </span> ', classes: 'green' });
    this.step1 = false;
    this.step2 = true;
  }

  toggleVisibility() {
    this.showPassword = !this.showPassword;
  }

  toggleVisibility1() {
    this.showConfirmPassword = !this.showConfirmPassword;
  }

  register(data: any) {
    this.authService.adminEmailSignUp(this.admin, this.isDemo, data.value.createPassword).then(x => {
      {
        // const newAdmin = {
        //             uid: x,
        //             id: this.admin.uid,
        //             FirstName: this.admin.FirstName,
        //             LastName: this.admin.LastName,
        //             Email: this.admin.Email_Address,
        //             DateAdded: new Date(),
        //             role: 'Admin',
        //             status: 'Enabled',
        //             isDemo : this.isDemo,
        //           };
        //          this.db.collection('BandMembers').doc(newAdmin.uid).set(newAdmin).then(() => {
        //             this.router.navigate(['admin/dashboard']);
        //           });

      }
    }).catch(error => {
      toast({ html: error.message, classes: 'red' });
    });
  }

  get createPassword() { return this.registrationForm.get('createPassword'); }
  get confirmPassword() { return this.registrationForm.get('confirmPassword'); }
  get socialPassword() { return this.verificationForm.get('socialPassword'); }

}