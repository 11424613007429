import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
//import { environment } from 'src/environments/environment.prod';
import { environment } from 'src/environments/environment';
import { PseContractModel } from 'src/app/models/PseContractModel';
import { Observable } from 'rxjs';
import { PseSignatureModel } from 'src/app/models/PseSignatureModel';
import { ApiKeyManagerService } from './apiKeyManager.Service';

@Injectable({
    providedIn: 'root'
  })
  export class PseContractService {
  
    url = environment.firstNation.apiUrl;
  
    constructor(private httpClient: HttpClient, private keyManager: ApiKeyManagerService) { }
    
    getContract(id: number): Observable<PseContractModel> {
        const headers = new HttpHeaders({SOCIALAPIKEY: this.keyManager.getKey()});
        return this.httpClient.get<PseContractModel>(
        this.url + 'api/PseContract/GetContract/' + id, {headers});
    }
    
    
    postContract(model: PseContractModel): any {
        const headers = new HttpHeaders({SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8'});
        return this.httpClient.post<any>(this.url + 'api/PseContract/SaveContract', JSON.stringify(model), {headers});
    }
    
  
    postReleaseForm(model: PseContractModel): any {
      const headers = new HttpHeaders({SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8'});
      return this.httpClient.post<any>(this.url + 'api/PseContract/SaveReleaseForm', JSON.stringify(model), {headers});
    }


    getClientInfo(id: number): Observable<any[]> {
      const headers = new HttpHeaders({SOCIALAPIKEY: this.keyManager.getKey()});
      return this.httpClient.get<any[]>( this.url + 'api/PseContract/GetClientInfo/' + id, {headers});
   }

  
  }