import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { JobOfferService } from 'src/app/services/firebase/joboffer.service';

@Component({
  selector: 'app-my-contracts',
  templateUrl: './my-contracts.component.html',
  styleUrls: ['./my-contracts.component.css']
})
export class MyContractsComponent implements OnInit {

  constructor( private jobofferService: JobOfferService) {}

  themeColor = environment.appTheme.adminThemeColor;
  fontColor = environment.appTheme.adminFontColor;
  joblist: any[];
  hasRows = false;
  post_to_archive: any[];

  ngOnInit() {
    this.jobofferService.getJobOffersList().valueChanges().subscribe(jobs => {
      if (jobs) {
        this.joblist = jobs;
        this.post_to_archive = jobs;
        const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];          
        const yesterday_date =  (new Date().getDate() - 1 ) + '/' + months[new Date().getMonth()] + '/' + new Date().getFullYear(); 
        this.joblist = this.joblist.filter(e => e.posted === true && (Date.parse(e.expiry_date) > Date.parse(yesterday_date))); 
        this.post_to_archive = this.post_to_archive.filter(e => e.posted === true && Date.parse(e.expiry_date) <= Date.parse(yesterday_date) );      
       
        if (this.post_to_archive.length > 0) { this.Archive_Post_on_ExpiryDate() };   
        if (this.joblist.length > 0) {this.hasRows = true; }
      }
    });
  }

  Archive_Post_on_ExpiryDate() {
    this.post_to_archive.forEach(element => {
      element.posted= false;
      element.archived = true;
      this.jobofferService.EditJobOffer(element).then(x => {});
    });
  }
  
}