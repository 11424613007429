import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { toast } from 'materialize-css';
import { StaticContentService } from 'src/app/services/firebase/static-content.service';
import { ActivatedRoute } from '@angular/router';
import { SessionsMonitorService } from 'src/app/services/firebase/sessions-monitor.service';

@Component({
  selector: 'app-edit-notification',
  templateUrl: './edit-notification.component.html',
  styleUrls: ['./edit-notification.component.css']
})

export class EditNotificationComponent implements OnInit {

  adminThemeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  myModel: any;
  id: any;
  valueEmittedFromChildComponent: any;

  constructor(
    private staticContentService: StaticContentService,
    private location: Location,
    private sessionMonitor: SessionsMonitorService,
    private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });
    if (this.id) {
      this.staticContentService.getContentByID(this.id).valueChanges().subscribe(obj => {
        if (obj) {
          this.myModel = obj;
        }
      });
    }
  }

  updateEventHandler(valueEmitted) {
    this.valueEmittedFromChildComponent = valueEmitted;
    if (this.valueEmittedFromChildComponent === true) {
      this.sessionMonitor.newActivity('Edit News', 'News Edited');
      toast({ html: 'News  Successfully Updated!', classes: 'green' });
    }
  }

  archiveEventHandler(valueEmitted) {
    this.valueEmittedFromChildComponent = valueEmitted;
    if (this.valueEmittedFromChildComponent === true) {
      this.sessionMonitor.newActivity('Edit News', 'News Archived');
      toast({ html: 'News Successfully Archived!', classes: 'green' });
      this.backClicked();
    }
  }

  unArchiveEventHandler(valueEmitted) {
    this.valueEmittedFromChildComponent = valueEmitted;
    if (this.valueEmittedFromChildComponent === true) {
      this.sessionMonitor.newActivity('Edit News', 'News Unarchived');
      toast({ html: 'News Successfully Published!', classes: 'green' });
      this.backClicked();
    }
  }

  deleteEventHandler(valueEmitted) {
    this.valueEmittedFromChildComponent = valueEmitted;
    if (this.valueEmittedFromChildComponent === true) {
      this.sessionMonitor.newActivity('Edit News', 'News Deleted');
      toast({ html: 'News Successfully Deleted!', classes: 'green' });
      this.backClicked();
    }
  }

  backClicked() {
    this.location.back();
  }

}
