import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { toast } from 'materialize-css';
import { MemberModel } from 'src/app/models/memberModel';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { MemberService } from 'src/app/services/api/member.service';
import { FireMembersService } from 'src/app/services/firebase/fire-members.service';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-client-detail',
  templateUrl: './client-detail.component.html',
  styleUrls: ['./client-detail.component.css']
})
export class ClientDetailComponent implements OnInit {

  @Input() id: any;
  @Output() back: EventEmitter<void> = new EventEmitter<void>();

  public phoneNumberMask = ['(', /[0-9]/, /[0-9]/, /[0-9]/, ')',
    ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];

  themeColor = environment.appTheme.adminThemeColor;
  fontColor = environment.appTheme.adminFontColor;
  loggedUser: any;
  loading = false;
  fromCandidates = false;
  member: MemberModel;
  dep = false;
  applicantID = 0;
  emailForm: UntypedFormGroup;
  SMSForm: UntypedFormGroup;
  firebaseMember: any;
  isEnabled = false;

  resetPasswordForm: UntypedFormGroup;
  client_email = '';
  hasEmail = false;
  contact = false;
  contactDetail: any = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNo: '',
  }
  message: any = {
    title: '',
    subject: '',
    body: '',
    files: []
  }
  reason: string = null;
  sendEmail = false;
  subject: string;
  body: string;

  constructor(private fb: UntypedFormBuilder,
    private router: Router,
    private authService: AuthService,
    private memberService: MemberService,
    private fireMembersService: FireMembersService) {
    this.loggedUser = this.authService.getGlobalUser();
  }

  ngOnInit() {
    if (this.id) {
      this.fireMembersService.getMemberByID(this.id).valueChanges().subscribe(x => {
        this.firebaseMember = x;
        if (this.firebaseMember.status === 'Enabled') { this.isEnabled = true; }
        this.memberService.getMember(this.firebaseMember.applicantID).subscribe(data => {
          if (data && data != 'No Result Found') {
            data[0].fromAdmin = true;
            this.member = data[0];
            this.client_email = this.member.Email;
            if (this.client_email) this.hasEmail = true;
          } else {
            toast({ html: 'Client was not found in the database.', classes: 'red' });
            this.backClicked();
          }
        });
      });
    }
    this.emailForm = this.fb.group({
      subject: [''],
      body: ['', [Validators.required]],
    });

    this.SMSForm = this.fb.group({
      message: ['', [Validators.required]]
    });

    this.resetPasswordForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });

    setTimeout(() => {
      $('.modal').modal();
    }, 25);
  }

  contactClient() {
    if (this.member) {
      this.contactDetail.firstName = this.member.GivenName;
      this.contactDetail.lastName = this.member.LastName;
      this.contactDetail.email = this.member.Email;
      this.contactDetail.phoneNo = this.member.cell_phone_num;
    }
    this.contact = true;
  }

  contactHandler(valueEmitted) {
    if (valueEmitted.status) toast({ html: 'Message sent to client Successfully!', classes: 'green' });
    else toast({ html: 'Message Not sent to the client!', classes: 'red' });
  }

  onSendEmail() {
    this.sendEmail = !this.sendEmail;
  }

  enableAccount() {
    this.firebaseMember.status = 'Enabled';
    this.firebaseMember.statusDetails = {
      updateDate: new Date(),
      by: this.loggedUser.FirstName + " " + this.loggedUser.LastName,
      reason: this.reason
    }
    this.fireMembersService.updateAccount(this.firebaseMember);
    this.isEnabled = true;
    this.reason = null;
    toast({ html: `${this.firebaseMember.Email} Enabled`, classes: 'green rounded' });
    if (this.sendEmail) {
      this.contactDetail.firstName = this.member.GivenName;
      this.contactDetail.lastName = this.member.LastName;
      this.contactDetail.email = this.member.Email;
      this.contactDetail.phoneNo = this.member.cell_phone_num;
      this.message.subject = 'Account Enabled';
      this.message.title = 'AccountStatus';
      this.contact = true;
    }
  }

  disableAccount() {
    this.firebaseMember.status = 'Disabled';
    this.firebaseMember.statusDetails = {
      updateDate: new Date(),
      by: this.loggedUser.FirstName + " " + this.loggedUser.LastName,
      reason: this.reason
    }
    this.fireMembersService.updateAccount(this.firebaseMember);
    this.isEnabled = false;
    this.reason = null;
    toast({ html: `${this.firebaseMember.Email} Disabled`, classes: 'red rounded' });
    if (this.sendEmail) {
      this.contactDetail.firstName = this.member.GivenName;
      this.contactDetail.lastName = this.member.LastName;
      this.contactDetail.email = this.member.Email;
      this.contactDetail.phoneNo = this.member.cell_phone_num;
      this.message.subject = 'Account Disabled';
      this.message.title = 'AccountStatus';
      this.contact = true;
    }
  }

  clientActivity() {
    this.router.navigate(['admin/members/member-activity', this.id]);
  }

  get email() { return this.resetPasswordForm.get('email'); }

  resetPassword() {
    $('.modal').modal('close');
    this.authService.resetPassword(this.email.value).then(() =>
      toast({ html: '<p> Reset Password Link Successfully Sent. </p>', classes: 'green' })
    ).catch((error) => toast({ html: error.message, classes: 'red' }));
  }

  cancel() {
    $('.modal').modal('close');
  }

  getInfo() {
    setTimeout(() => {
      M.updateTextFields();
    }, 25);
  }

  backClicked() {
    this.back.emit();
  }

}
