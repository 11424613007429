import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ApiKeyManagerService } from './apiKeyManager.Service';
import { IncomeSupportAppModel } from 'src/app/models/IncomeSupportAppModel';
import { IncomeSupportFamilyMemberModel } from 'src/app/models/IncomeSupportFamilyMemberModel';
import { AssetsModel } from 'src/app/models/AssetsModel';
import { IncomeModel } from 'src/app/models/IncomeModel';
import { EmployabilityModel } from 'src/app/models/EmployabilityModel';

@Injectable({
    providedIn: 'root'
})
export class ISApplicationService {
  
      constructor(private httpClient: HttpClient, private keyManager: ApiKeyManagerService) { }

      saveApplicatioin(model: IncomeSupportAppModel ): Observable<any> {
      
        const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
        return this.httpClient.post<any>(
          environment.firstNation.apiUrl + 'api/IncomeSupportApplication/SaveISApplication', JSON.stringify(model), { headers });
      }


      saveFamilyMembers(model: IncomeSupportFamilyMemberModel[]): Observable<any> {
        const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
        return this.httpClient.post<any>(
          environment.firstNation.apiUrl + 'api/IncomeSupportApplication/SaveISFamilyMembers', JSON.stringify(model), { headers });
      }


      saveOtherPersons(model: IncomeSupportFamilyMemberModel[]): Observable<any> {
        const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
        return this.httpClient.post<any>(
          environment.firstNation.apiUrl + 'api/IncomeSupportApplication/SaveISOtherPersons', JSON.stringify(model), { headers });
      }


      getApplication(id: number): Observable<any> {
        const url = environment.firstNation.apiUrl + 'api/IncomeSupportApplication/GetISApplication/' + id;
        const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
        return this.httpClient.get<any>(url, { headers });
      }

      getApplications(): Observable<any> {
        const url = environment.firstNation.apiUrl + 'api/IncomeSupportApplication/GetISApplications/';
        const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
        return this.httpClient.get<any>(url, { headers });
      }

      
      getFamilyMembers(id: number): Observable<any> {
        const url = environment.firstNation.apiUrl + 'api/IncomeSupportApplication/GetFamilyMembers/' + id;
        const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
        return this.httpClient.get<any>(url, { headers });
      }

      getOtherPersons(id: number): Observable<any> {
        const url = environment.firstNation.apiUrl + 'api/IncomeSupportApplication/GetOtherPersons/' + id;
        const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
        return this.httpClient.get<any>(url, { headers });
      }
     


      
      saveAssets(model: AssetsModel[]): Observable<any> {
        const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
        return this.httpClient.post<any>(
          environment.firstNation.apiUrl + 'api/IncomeSupportApplication/SaveISAssets', JSON.stringify(model), { headers });
      }

      getAssets(id: number): Observable<any> {
        const url = environment.firstNation.apiUrl + 'api/IncomeSupportApplication/GetAssets/' + id;
        const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
        return this.httpClient.get<any>(url, { headers });
      }


      saveRequestToFamilyMember(model: any[]): Observable<any> {
        const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
        return this.httpClient.post<any>(
          environment.firstNation.apiUrl + 'api/IncomeSupportApplication/SaveRequestToFamilyMember', JSON.stringify(model), { headers });
      }
    
      saveIncomes(model: IncomeModel[]): Observable<any> {
        const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
        return this.httpClient.post<any>(
          environment.firstNation.apiUrl + 'api/IncomeSupportApplication/SaveISIncomes', JSON.stringify(model), { headers });
      }


      getIncomes(id: number): Observable<any> {
        const url = environment.firstNation.apiUrl + 'api/IncomeSupportApplication/GetIncomes/' + id;
        const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
        return this.httpClient.get<any>(url, { headers });
      }


      saveHousing(model: any[]): Observable<any> {
        const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
        return this.httpClient.post<any>(
          environment.firstNation.apiUrl + 'api/IncomeSupportApplication/SaveISHousing', JSON.stringify(model), { headers });
      }

      
      saveEmployability(model: any): Observable<any> {
        const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
        return this.httpClient.post<any>(
          environment.firstNation.apiUrl + 'api/IncomeSupportApplication/SaveEmployability', JSON.stringify(model), { headers });
      }

      
      getEmployability(id: number): Observable<any> {
        const url = environment.firstNation.apiUrl + 'api/IncomeSupportApplication/GetEmployability/' + id;
        const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
        return this.httpClient.get<any>(url, { headers });
      }

  }