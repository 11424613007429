<div class="row container-pi">
    <h4 class="title-section1 title-member icon-texts"><span class="material-symbols-outlined back-icon p-r-1" (click)=" backClicked()">
        arrow_circle_left
    </span>Boards List</h4>
    <div class="row card-panel b-n-1">

        <div class="col s12 m12 l12" *ngIf="model">
            <app-board-list-shared [viewModel]="model"></app-board-list-shared>
        </div>
    </div>
</div>