
// import { Injectable } from '@angular/core';
// import { AngularFirestoreCollection } from '@angular/fire/compat/firestore/collection/collection';
// import { AngularFirestoreDocument } from '@angular/fire/compat/firestore/document/document';
// import { AngularFirestore } from '@angular/fire/compat/firestore';
// import { toast } from 'materialize-css';

// @Injectable({
//   providedIn: 'root'
// })
// export class IsetsFireMembersService {

//   constructor(private db: AngularFirestore) { }

//   membersListRef: AngularFirestoreCollection<any>;
//   memberRef: AngularFirestoreDocument<any>;



//   getClientByID(id: string): AngularFirestoreDocument<any> {
//     this.memberRef = this.db.doc('ASETS_Clients/' + id);
//     return this.memberRef;
//   }


  
//   getClientsList(): AngularFirestoreCollection<any> {
//     this.membersListRef = this.db.collection('ASETS_Clients');
//     return this.membersListRef;
//   }

//   updateAccount(model: any) {
      
//       this.db.collection('ASETS_Clients').doc(model.uid).set(model).then(
//         toast({html: 'Account Status Successfully Updated', classes: 'green'})
//       );
//   }

//   updatePermissions(model: any) {
  
//     this.db.collection('ASETS_Clients').doc(model.uid).set(model).then(
//       toast({html: 'Permissions Successfully Updated', classes: 'green'})
//     );  

// }


// }

import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { toast } from 'materialize-css';

@Injectable({
  providedIn: 'root'
})
export class IsetsFireMembersService {

  constructor(private db: AngularFirestore) { }

  membersListRef: AngularFirestoreCollection<any>;
  memberRef: AngularFirestoreDocument<any>;

  getClientByID(id: string): AngularFirestoreDocument<any> {
    this.memberRef = this.db.doc('ASETS_Clients/' + id);
    return this.memberRef;
  }

  getClientsList(): AngularFirestoreCollection<any> {
    this.membersListRef = this.db.collection('ASETS_Clients');
    return this.membersListRef;
  }

  async updateAccount(model: any) {
    try {
      await this.db.collection('ASETS_Clients').doc(model.uid).set(model);
      toast({ html: 'Account Status Successfully Updated', classes: 'green' });
    } catch (error) {
      // Handle error appropriately, e.g., log or show another toast
      console.error('Error updating account:', error);
    }
  }

  async updatePermissions(model: any) {
    try {
      await this.db.collection('ASETS_Clients').doc(model.uid).set(model);
      toast({ html: 'Permissions Successfully Updated', classes: 'green' });
    } catch (error) {
      // Handle error appropriately
      console.error('Error updating permissions:', error);
    }
  }
}

