import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FireMembersService } from 'src/app/services/firebase/fire-members.service';
import { ElectionService } from 'src/app/services/firebase/election.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-campaign-page',
  templateUrl: './campaign-page.component.html',
  styleUrls: ['./campaign-page.component.css']
})

export class CampaignPageComponent implements OnInit {

  id = '';
  candidateDetail: any;
  moreCandidateDetail: any;
  hasCandidateDetail = false;
  constructor(private route: ActivatedRoute,
    private fireMembersService: FireMembersService,
    private electionService: ElectionService,
    private location: Location) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });
    this.fireMembersService.getMemberByID(this.id).valueChanges().subscribe(candidateData => {
      this.candidateDetail = candidateData;
    });

    this.electionService.getCandidateDetailById(this.id).valueChanges().subscribe(candidateData => {
      if (candidateData.length !== 0) {
        this.hasCandidateDetail = true;
        this.moreCandidateDetail = candidateData[0];
      } else {
        this.hasCandidateDetail = false;
      }
    });
  }
  
  backClicked() {
    this.location.back();
  }
}
