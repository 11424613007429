import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { timeStamp } from 'console';
import { ThemeService } from 'ng2-charts';
import { Options } from 'sortablejs';
import { toast } from 'materialize-css';
import { CommunityService } from 'src/app/services/api/community.service';
import { RegistrationRequestService } from 'src/app/services/api/registration-request.service';
import { CreateCustomApplicationService } from 'src/app/services/firebase/create-custom-application.service';
import { CustomApplicationService } from 'src/app/services/firebase/custom-application.service';
import { environment } from 'src/environments/environment';
const pdfMake = require('pdfmake/build/pdfmake.js');
const pdfFonts = require('pdfmake-unicode/dist/pdfmake-unicode.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import * as pdfMakeX from 'pdfmake/build/pdfmake';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';

declare var $: any;
declare var M: any;
declare var navigator: any;

@Component({
  selector: 'app-customizable-income-form',
  templateUrl: './customizable-income-form.component.html',
  styleUrls: ['./customizable-income-form.component.css']
})
export class CustomizableIncomeFormComponent implements OnInit {

  nationofUse = environment.firstNation.name;
  nationName = environment.firstNation.displayName;
  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  provinceOfUse = environment.firstNation.province;

  application = {
    appName: '',
    description: '',
    values: {},
  }

  maritalStatusSelected = false;
  showSpouseInfo = false;
  addField = false;
  fieldSelected = false;
  selectedField: any;
  showForm = false;
  fieldDatas: any[];
  addedFields = [];
  step = 1;
  option = 1;
  //logoLink = "https://www.tailorbrands.com/wp-content/uploads/2020/07/twitter-logo.jpg";
 
  provinces: any = ['Alberta', 'British Columbia', 'Manitoba', 'New Brunswick',
    'Newfoundland and Labrador', 'Nova Scotia', 'Ontario', 'Prince Edward Island', 'Quebec', 'Saskatchewan'];
  provincesCode = ['AB', 'BC', "MB", 'NB', "NL", 'NS', 'ON', 'PE', 'QC', 'SK'];
  maritalStatus: any = ['Single', 'Married', 'Separated ', 'Widowed', "Common Law"];
  bandNames: any = ['band 1', 'band 2', 'band 3'];
 
  public postalCodeMask = [/[A-Za-z]/, /[0-9]/, /[A-Za-z]/, ' ', /[0-9]/, /[A-Za-z]/, /[0-9]/];
  public phoneNumberMask = ['(', /[0-9]/, /[0-9]/, /[0-9]/, ')',
    ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  public dateMask = [/[0-3]/, /[0-9]/, '/', /[0-1]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  //public fullDateMask = [/d{2}/,/d{2}/,/d{4}/];
  public dateformat = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
  public isnMask = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  public SINMask = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  public healthCareNoMask = [/ \d+ /];
  public noMask = [];

  application_name = 'New Income Support Application';
  nationCommunities: any[];
  treatyNo = '';
  bands: any[];
  bandUse: any[];
  bandName = '';
  provinceCode: any;
  bandList: any[];

  section1Form: UntypedFormGroup;
  section2Form: UntypedFormGroup;
  applicationForm: UntypedFormGroup;
  appData: any;
  band_name_control: any;
  
  options: Options = {
    animation: 150,
    //handle: '.handle',
  }

  constructor(private formBuilder: UntypedFormBuilder,
    private communityService: CommunityService,
    private sqlRegistrationRequest: RegistrationRequestService,
    private route: ActivatedRoute,
    private CreateApplicationService: CreateCustomApplicationService,
    private CustomeApplicationService: CustomApplicationService,
    private settingsService: AppSettingsService,) {
    this.fieldDatas = [];

    this.fieldDatas.push({ name: 'isn', req: false, label: 'Indian Status No', controlName: 'isn', icon: 'tag', textMask: this.isnMask, type: 'text', select: false, method: this.onIsnChange });
    this.fieldDatas.push({ name: 'bandName', req: false, label: 'Band Name', controlName: 'band_name', icon: 'add_location', type: "text", select: true, values: this.bandNames, method: this.bandChange });
    this.fieldDatas.push({ name: 'city', req: true, label: 'City', controlName: 'city', icon: 'location_city', type: 'text', select: false, textMask: false });
    this.fieldDatas.push({ name: 'homeNumber', req: false, label: 'Home Number', controlName: 'home_number', icon: 'phone', textMask: this.phoneNumberMask, type: 'text', select: false });
    this.fieldDatas.push({ name: 'mobile', req: false, label: 'Mobile', controlName: 'mobile', icon: 'phone_iphone', textMask: this.phoneNumberMask, type: 'text', select: false });
    this.fieldDatas.push({ name: 'postalCode', req: true, label: 'Postal Code', controlName: 'postal_code', icon: 'mail', textMask: this.postalCodeMask, type: 'text', select: false });
    this.fieldDatas.push({ name: 'healthCareNumber', req: false, label: 'Health Care Number', controlName: 'health_care_no', icon: 'tag', textMask: false, type: 'number', select: false });
    this.fieldDatas.push({ name: 'SIN', req: false, label: 'SIN', controlName: 'SIN', icon: 'apps', textMask: this.SINMask, type: 'text', select: false });
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.application_name = params.get('name');
      console.log(this.application_name);
    });

    if (this.nationofUse === 'Batc' || this.nationofUse === 'Pbcn') {
      this.communityService.getNationCommunities().subscribe(
        com => {
          if (com.length > 0) {
            this.nationCommunities = com;
          }
        }
      );
    }

    this.sqlRegistrationRequest.getTreatyNumber().subscribe(t => {
      if (t) {
        this.treatyNo = t;

        this.communityService.getBands().subscribe(band => {
          if (band) {
            this.bands = band;
            this.getBandListFromProvinceCode(this.provinceOfUse);
            this.bandUse = this.bands.filter(b => b.TreatyNo == this.treatyNo);
            if (this.bandUse && this.bandUse.length > 0) {
              this.bandName = this.bandUse[0].Band;
            }

            this.CreateApplicationService.getCustomApplicationByName(this.application_name).valueChanges().subscribe(x => {
              if (x) {
                this.appData = x[0];
                this.applicationForm = this.formBuilder.group({});

                this.appData.sections.forEach(section => {
                  this.applicationForm.addControl(section.section_name, new UntypedFormGroup({}));
                  const sectionGroup = this.applicationForm.get(`${section.section_name}`) as UntypedFormGroup;
                  section.fields.forEach(field => {

                    if (field.data_type == 'date') {
                      field.data_type = 'text';
                      field.textMask = this.dateMask;
                    } else if (field.data_type == 'tel') {
                      field.data_type = 'text';
                      field.textMask = this.phoneNumberMask;
                    }

                    if (field.textMask == 'isn') {
                      field.textMask = this.isnMask;
                      field.method = this.onIsnChange;
                      field.default_value = t;
                    } else if (field.textMask == 'postalCode') {
                      field.textMask = this.postalCodeMask;
                    } else if (field.textMask == 'sin') {
                      field.textMask = this.SINMask;
                    }
                    if (field.field_type == 'Select') {
                      if (field.select_options == "Marital Stauts") {
                        field.select_options = this.maritalStatus;
                      } else if (field.select_options == "Provinces") {
                        field.select_options = this.provinces;
                        field.default_value = this.provinceOfUse;
                        field.method = this.provinceChange;
                      } else if (field.select_options == "Band Names") {
                        field.method = this.bandChange;
                        field.select_options = this.bandList;
                        field.default_value = this.bandName;
                      }
                    }
                  
                    if (field.required) {

                      if (field.default_value) {
                        sectionGroup.addControl(field.form_control, new UntypedFormControl(field.default_value, Validators.required));
                      } else {
                        sectionGroup.addControl(field.form_control, new UntypedFormControl('', Validators.required));
                      }

                    } else {
                      if (field.default_value) {
                        sectionGroup.addControl(field.form_control, new UntypedFormControl(field.default_value));
                      } else {
                        sectionGroup.addControl(field.form_control, new UntypedFormControl(''));
                      }
                    }
                  });
                });

                this.section1Form = this.formBuilder.group({
                  applicant: this.formBuilder.group({
                    first_name: ['', [Validators.required, Validators.minLength(1)]],
                    last_name: ['', [Validators.required, Validators.minLength(1)]],
                    marital_status: [[Validators.required]],
                    province: [this.provinceOfUse, [Validators.required]],
                    address: ['', [Validators.required]],
                    dob: ['', [Validators.required]],
                  }),

                });


                if (this.nationofUse === 'Batc' || this.nationofUse === 'Pbcn') {
                  this.applicationForm.patchValue({ section1: { isn: '' } });
                  this.applicationForm.patchValue({ section1: { spouse: { spouse_isn: '' } } });
                }

                setTimeout(() => {
                  $('select').formSelect();
                  $('.modal').modal();
                  $('.tabs').tabs();
                  $('.collapsible').collapsible();
                  M.updateTextFields();
                }, 25);
              }
            });

          }
        });
      }
    });

    // this.settingsService.getLandingPageSettings().valueChanges().subscribe(
    //   lpSettings => {
    //     if (lpSettings) {
        
    //       if (lpSettings.length > 0) {
    //         this.model = lpSettings[0];
    //         this.logo = this.model.logo;
            
    //         console.log(this.logo);

    //         setTimeout(() => {
    //           $('.parallax').parallax();
    //         }, 25);
    //       }
    //     }
    //   }

    // );

  }

  get band_name() {
    let sectionName = '';
    let form_control: any = [];
    this.appData.sections.every(section => {
      form_control = section.fields.filter(field => field.form_control == 'band_name');
      if (form_control.length > 0) {
        sectionName = section.section_name;
        return false;
      }
    });
    if (sectionName && form_control.length > 0) {
      const control = this.applicationForm.get(`${sectionName}.${form_control[0].form_control}`);
      return control;
    }
    else return null;

  }

  newField(values) {
    return this.formBuilder.group({
      name: [values.name],
      form_control: [values.form_control],
      dataType: [],
      icon: [],
      color: [],
      required: [],
      validation: this.formBuilder.group([]),
    });
  }

  getBandListFromProvinceCode(province) {
    const index = this.provinces.indexOf(province);
    const provinceCode = this.provincesCode[index];
    if (this.bands) {
      const bandInProvince = this.bands.filter(band => provinceCode === this.getProvinceCodeFromAddress(band.Address));
      const bandList = [];
      bandInProvince.forEach(b => {
        bandList.push(b.Band);
      });
      this.bandList = bandList;
    }
  }

  getProvinceCodeFromAddress(address: any) {
    let addr = address.substring(address.length - 11);
    addr = addr.substring(0, 2);
    return addr;
  }

  onAddField() {
    this.addField = true;
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    });

  }

  onFieldSelected(e) {
    this.selectedField = this.fieldDatas.filter(field => e === field.name);
    this.fieldSelected = true;
  }

  onAddToForm() {
    this.fieldSelected = false;
    this.addField = false;
    this.showForm = true;
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    });
  }

  // fieldClicked(i) {

  // }

  maritalStatusChange(value: any) {
    this.maritalStatusSelected = true;
    if (value == 'Married' || value == "Common Law") {
      this.showSpouseInfo = true;
    } else {
      this.showSpouseInfo = false;
    }
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);

  }

  deleteField(index) {
    const field = this.addedFields.splice(index, 1);
    this.fieldDatas.push(field[0][0]);
  }

  onNext() {
    if(!this.isValid()){
      toast({ html: 'Please fill out the required fields to continue to the next section', classes: 'blue' });
    }else{
      this.step += 1;
    }
    setTimeout(() => {
      $('select').formSelect();
      $('.modal').modal();
      $('.tabs').tabs();
      $('.collapsible').collapsible();
      $('.tooltipped').tooltip();
      M.updateTextFields();
    }, 25);
  }

  onBack() {
    this.step -= 1;
    setTimeout(() => {
      $('select').formSelect();
      $('.modal').modal();
      $('.tabs').tabs();
      $('.collapsible').collapsible();
      M.updateTextFields();
    }, 25);

  }

  changeOption(option) {
    this.option = option;
    setTimeout(() => {
      $('select').formSelect();
      $('.modal').modal();
      $('.tabs').tabs();
      $('.collapsible').collapsible();
      M.updateTextFields();
    }, 25);
  }

  close() {
    this.addField = false;
  }

  bandChange(value) {
    console.log('band Change Methood called');
    console.log(value);
  }

  provinceChange(value: any) {
    this.getBandListFromProvinceCode(value);
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }

  onIsnChange(e) {
    const isn = e.toString().slice(0, 3)
    const isnArray = isn.split('');
    if (isnArray[0] != '_' && isnArray[1] != '_' && isnArray[2] != '_') {
      this.bandUse = this.bands.filter(b => b.TreatyNo == isn);
      if (this.bandUse && this.bandUse.length > 0) {
        this.bandName = this.bandUse[0].Band;
        const control = this.band_name;
        if (control) {
          this.band_name.patchValue(this.bandName);
        }
      }
      setTimeout(() => {
        $('select').formSelect();
        M.updateTextFields();
      }, 25);
    }
  }

  // showFormData() {
  //   console.log(this.applicationForm);
  // }

  getSectionName(formControlName) {
    const section = this.appData.sections.find(section => section.section_name == formControlName);
    const sectionName = section.sec_title.replace(/\s+/g, '-');
    console.log(sectionName);
    return sectionName;
  }

  isValid() {
    const section = this.appData.sections[this.step - 1].section_name;
    const sectionControl = this.applicationForm.get(section);
    return sectionControl.valid;
  }

  isRequired(f, field) {
    const section = this.appData.sections[this.step - 1].section_name;
    const control = this.applicationForm.get(`${section}.${field}`);
    if ((control.dirty || control.touched) && control.invalid) {
      if (control.errors.required) {
        return true;
      }
    }
    return false;
  }

  BackToApp() {
    this.step = this.appData.sections.length;
    setTimeout(() => {
      $('select').formSelect();
      $('.modal').modal();
      $('.tabs').tabs();
      $('.collapsible').collapsible();
      $('.tooltipped').tooltip();
      M.updateTextFields();
      $('.tooltipped').tooltip();
    }, 25);
  }

  previewApplication(values: any) {
    this.application.appName = this.application_name;
    this.application.values = values;
    this.step = 0;
    setTimeout(() => {
      $('select').formSelect();
      $('.modal').modal();
      $('.tabs').tabs();
      $('.collapsible').collapsible();
      $('.tooltipped').tooltip();
      M.updateTextFields();
      $('.tooltipped').tooltip();
    }, 25);
  }

  submitApplication(){
     this.CustomeApplicationService.saveApplication(this.application).then(x => {
      toast({ html: 'Application Successfully Created!', classes: 'green' });
    });
    //console.log("Application Submitted");

  }

  getDocumentDefinition() {
    return {
      info: {
        title: this.application.appName,
        subject: 'Custom Application',
      },
      content: [
        {
          columns: [
            [
              this.getLogo(),
            ],
            [{
                layout: 'lightHorizontalLines',
                text: this.application.appName.toUpperCase(),
                style: 'mainTitle',
              },
            ]
          ]
        },
        this.getSectionData(),
      ],
      styles: {
        mainTitle: {
          bold: true,
          fontSize: 20,
          alignment: 'left',
          margin: [0, 0, 0, 20]
        },
        title: {
          bold: true,
          fontSize: 15,
          alignment: 'left',
          margin: [10, 10, 10, 10],
        },
        header: {
          fontSize: 12,
          bold: true,
          alignment: 'center',
        },
      }
    }
  }

  getLogo(){
    if(this.appData.logo){
      return {
        image: this.appData.logo,
        width: 100,
        alignment : 'left'
      };
    }
    return null;
  }

//   getDataUrl(url) {
//     // Create canvas
//    var img = new Image();
//    img.setAttribute("crossOrigin", "anonymous");
//     //img.src = url;
//     const canvas = document.createElement('canvas');
//     const ctx = canvas.getContext('2d');
//     // Set width and height
//     canvas.width = img.width;
//     canvas.height = img.height;
//     // Draw the image
//     console.log(typeof img);
//     ctx.drawImage(img, 0, 0);
//     var dataURL = canvas.toDataURL('image/png');
//     console.log(dataURL);
//     img.src = url;
    
//     return dataURL;
//  }

//   getBase64ImageFromURL(url) {
//     return new Promise((resolve, reject) => {
//       var img = new Image();
//       img.setAttribute("crossOrigin", "anonymous");
//       img.onload = () => {
//         var canvas = document.createElement("canvas");
//         canvas.width = img.width;
//         canvas.height = img.height;
//         var ctx = canvas.getContext("2d");
//         ctx.drawImage(img, 0, 0);
//         var dataURL = canvas.toDataURL("image/png");
//         console.log(dataURL);
//         resolve(dataURL);
//       };
//       img.onerror = error => {
//         console.log(error);
//         reject(error);
//       };
//       img.src = url ;
//     });
//   }

  getSectionData() {
    const sectionData = [];
    this.appData.sections.forEach(section => {
      sectionData.push([
        {
          text: section.sec_title,
          style: 'title',
        }
      ]);
      this.getFieldData(section).forEach(field => sectionData.push(field));
    });
    return sectionData;
  }

  getFieldData(section) {
    const fields = [];
    section.fields.forEach(field => {
      const obj = {
        text: field.name + " : " + this.application.values[section.section_name][field.form_control]
      }
      fields.push(obj);
    });
    return fields;
  }

  openPdf() {
    const documentDefinition =  this.getDocumentDefinition();
    pdfMake.createPdf(documentDefinition).open();
    //const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
    // pdfDocGenerator.getBase64((data) => {
    //   console.log(data);
    // });
  }

  downloadPdf() {
    const documentDefinition =  this.getDocumentDefinition();
    pdfMake.createPdf(documentDefinition).download();
  }

  printPdf() {
    const documentDefinition =  this.getDocumentDefinition();
    pdfMake.createPdf(documentDefinition).print();
  }

  editSection(index){
    this.step = index + 1;
    setTimeout(() => {
      $('select').formSelect();
      $('.modal').modal();
      $('.tabs').tabs();
      $('.collapsible').collapsible();
      M.updateTextFields();
    }, 25);

  }

}
