import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { Location } from '@angular/common';
import { toast } from 'materialize-css';

@Component({
  selector: 'app-upload-isets-documents',
  templateUrl: './upload-isets-documents.component.html',
  styleUrls: ['./upload-isets-documents.component.css']
})

export class UploadIsetsDocumentsComponent implements OnInit {

  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  clientID = 0;
  valueEmittedFromChildComponent: any;
  callerName = 'member';
  isCordova = false;

  constructor(private location: Location, private authService: AuthService) {
    if (typeof window['cordova'] !== 'undefined') {
      this.isCordova = true;
    }
  }

  parentEventHandlerFunction(valueEmitted) {
    this.valueEmittedFromChildComponent = valueEmitted;
    if (this.valueEmittedFromChildComponent === 'saved') {
      toast({ html: 'Documents Successfully Submitted!', classes: 'green' });
    }
  }

  ngOnInit() {
    if (localStorage.getItem('applicantID')) {
      this.clientID = Number(localStorage.getItem('applicantID'));
    } else {
      this.clientID = this.authService.getCurrentApplicantID();
    }
  }

  backClicked() {
    this.location.back();
  }
}
