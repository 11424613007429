<div class="row" *ngIf="newsSection">
  <div class="row news_latest">
    <div class="header-title center">
      <h6 [ngStyle]="{ color: newsSection.displayNameColor }">
        {{ newsSection.displayName }}
      </h6>
      <hr class="divider" [ngStyle]="{
          'background-color': themeColor,
          'border-color': themeColor
        }" />
    </div>

    <div class="row col l10 push-l1 s12 m10 push-m1 container" *ngIf="!newsSection.cardView">
      <div class="col s12 m6 l6 xl6" *ngFor="let n of news">
        <div class="row col s12 card list-view" [ngStyle]="{ color: themeColor }" *ngIf="n.imgLink"
          [routerLink]="['/read-more', n.id]">
          <div class="col l8 s12">
            <p class="date-content sub-title-section3" style="right: 0">
              {{ n.datePublished.toDate() | date : "MMM dd , yyyy" }}
            </p>
            <h1 [innerHTML]="n.title" class="title-section4 bolder p-t-0" style="margin-top: 0"></h1>
            <span [innerHTML]="truncateHTML(n.body, 300) | noSanitize"
              class="small-body-snippet bold-100 hide-on-small-only"></span>

            <div class="p-t-2 hide-on-small-only">
              <span class="bold-900 sub-title-section3" style="display: flex; align-items: center"
                [ngStyle]="{ color: themeColor }">
                Read More
                <i class="material-symbols-outlined sub-title-section2 bold-500">arrow_forward</i>
              </span>
            </div>
          </div>

          <div class="col l4 s12">
            <img class="responsive-img b-n-1" [src]="n.imgLink" alt="News Image"/>
          </div>

          <div class="col s12 p-t-0 hide-on-med-and-up">
            <span class="bold-900 sub-title-section3 " style="display: flex; align-items: center"
              [ngStyle]="{ color: themeColor }">
              Read More
              <i class="material-symbols-outlined sub-title-section2 bold-500">arrow_forward</i>
            </span>
          </div>
        </div>

        <div class="row col s12 card list-view" [ngStyle]="{ color: themeColor }" *ngIf="!n.imgLink"
          [routerLink]="['/read-more', n.id]">
          <div class="col s12">
            <p class="date-content sub-title-section3" style="right: 0">
              {{ n.datePublished.toDate() | date : "MMM dd , yyyy" }}
            </p>
            <h1 [innerHTML]="n.title" class="title-section4 bolder p-t-0" style="margin-top: 0"></h1>
            <span [innerHTML]="truncateHTML(n.body, 300) | noSanitize" class="small-body-snippet bold-100"></span>
            <div class="p-t-2">
              <span class="bold-900 sub-title-section3 icon-text" style="display: flex; align-items: center"
                [ngStyle]="{ color: themeColor }">
                Read More
                <i class="material-symbols-outlined sub-title-section2 bold-500">arrow_forward</i>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="row col s12 m12 hide-on-med-and-up">
        <p class="seeAll-displayName teal-text center">
          <a (click)="navigate()" [routerLink]="['/news']" [ngStyle]="{
              color: newsSection.displayNameColor,
              text_decoration: 'underline'
            }">
            <i>See All {{ newsSection.displayName }} >></i>
          </a>
        </p>
      </div>

      <div class="row col s12 m12 hide-on-small-only">
        <p class="seeAll-displayName teal-text right">
          <a (click)="navigate()" [routerLink]="['/news']" [ngStyle]="{
              color: newsSection.displayNameColor,
              text_decoration: 'underline'
            }">
            <i>See All {{ newsSection.displayName }} >></i>
          </a>
        </p>
      </div>
    </div>

    <div class="row col s12 l10 m10 push-l1 push-m1 container" *ngIf="newsSection?.cardView">
      <div class="col s12 m6 l4" *ngFor="let n of news">
        <div class="content-wrapper">
          <div class="news-card" *ngIf="n.type === 'Photo'" [routerLink]="['/read-more', n.id]">
            <img class="responsive-img news-card__image" [src]="n.imgLink" *ngIf="n.type === 'Photo'" alt="News Image"/>
            <div [innerHTML]="n.iframe"></div>
            <div class="news-card__text-wrapper">
              <h2 class="news-card__title" [innerHTML]="n.title"></h2>
              <div class="news-card__post-date">
                {{ n.datePublished.toDate() | date : "MMM dd, yyyy" }}
              </div>
              <div class="news-card__details-wrapper">
                <p class="news-card__excerpt black-text" [innerHTML]="truncateHTML(n.body, 200) | noSanitize"></p>
                <a [routerLink]="['/read-more', n.id]" class="news-card__read-more">Read more <i
                    class="fas fa-long-arrow-alt-right"></i></a>
              </div>
            </div>
          </div>

          <div class="news-cardd" *ngIf="n.type !== 'Photo'" [routerLink]="['/read-more', n.id]">
            <div class="news-card__text-wrapper">
              <h2 class="news-card__title" [innerHTML]="n.title"></h2>
              <div class="news-card__post-date">
                {{ n.datePublished.toDate() | date : "MMM dd, yyyy" }}
              </div>
              <div class="news-card__details-wrapperr">
                <p class="news-card__excerpt black-text" [innerHTML]="truncateHTML(n.body, 250) | noSanitize"></p>
                <a [routerLink]="['/read-more', n.id]" class="news-card__read-more">Read more <i
                    class="fas fa-long-arrow-alt-right"></i></a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row col s12 m12 hide-on-large-only">
        <p class="seeAll-displayName teal-text center">
          <a (click)="navigate()" [routerLink]="['/news']" [ngStyle]="{
              color: newsSection.displayNameColor,
              text_decoration: 'underline'
            }">
            <i>See All {{ newsSection.displayName }} >></i>
          </a>
        </p>
      </div>

      <div class="row col s12 m12 hide-on-med-and-down p-t-3">
        <p class="seeAll-displayName teal-text right">
          <a (click)="navigate()" [routerLink]="['/news']" [ngStyle]="{
              color: newsSection.displayNameColor,
              text_decoration: 'underline'
            }">
            <i>See All {{ newsSection.displayName }} >></i>
          </a>
        </p>
      </div>
    </div>
  </div>
</div>