import { Component, OnInit } from '@angular/core';
import { EmploymentService } from 'src/app/services/api/employment.service';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-skills-databank',
  templateUrl: './skills-databank.component.html',
  styleUrls: ['./skills-databank.component.css']
})
export class SkillsDatabankComponent implements OnInit {

  constructor(private employmentService: EmploymentService) { }

  keywords: string[];
  searchResult: any[];
  ngOnInit() {
    setTimeout(() => {
      $('.chips-placeholder').chips({
        placeholder: ' Keyword',
        secondaryPlaceholder: ' + Keyword',
      });
    }, 25);
  }

  Search() {
    let key = M.Chips.getInstance($('.chips')).chipsData;
    this.keywords = [];
    key.forEach(element => {
      this.keywords.push(element.tag);
    });
    this.employmentService.searchByKeyword(this.keywords).subscribe( x => {
       if (x) {
         this.searchResult = x;
         setTimeout(() => {
          $('.dropdown-trigger').dropdown();
         }, 25);
       }
     });
  }

}
