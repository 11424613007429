import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class AdminInvitationService {

  invitationListRef: AngularFirestoreCollection<any>;
  invitationRef: AngularFirestoreDocument<any>;
  

  constructor(private db: AngularFirestore) { }

  newInvitation(model: any)  {
    //model.id = this.db.createId();
    //return this.db.collection('Admin-Invitation').doc(model.id).set(model);
    return this.db.collection('Admin-Invitation').add(model);
  }

  updateInvitationId(id){
    return this.db.collection('Admin-Invitation').doc(id).update({id: id});
  }

  getInvitationByID(id: string): AngularFirestoreDocument<any> {
    this.invitationRef = this.db.doc('Admin-Invitation/' + id);
    return this.invitationRef;
  }

  getInvitationByApplicantID(id: string): AngularFirestoreCollection<any> {
    this.invitationListRef = this.db.collection('Admin-Invitation',  ref => ref.where('applicantId', '==', id));
    return this.invitationListRef;
  }

  getInvitationByEmail(email: string): AngularFirestoreCollection<any> {
    this.invitationListRef = this.db.collection('Admin-Invitation',  ref => ref.where('email', '==', email));
    return this.invitationListRef;
  }

  updateInvitation(model: any){
    return this.db.collection('Admin-Invitation').doc(model.id).update(model);
  }

  updateInvitationStatus(id: any, status: string){
    return this.db.collection('Admin-Invitation').doc(id).update({status: status});
  }

 getAllInvitation(): AngularFirestoreCollection<any> {
    this.invitationListRef = this.db.collection('Admin-Invitation');
    return this.invitationListRef;
  }

  deleteInvitation(id: any) {
    return this.db.collection('Admin-Invitation').doc(id).delete();
  }
}
