import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class SectionSettingService {

  constructor(private db: AngularFirestore) { }

  sectionListRef: AngularFirestoreCollection<any>;
  sectionRef: AngularFirestoreDocument<any>;

  saveSection(model: any) {
    model.id = this.db.createId();
    return this.db.collection('Section').doc(model.id).set(model);
  }

  getSections() {
    this.sectionListRef = this.db.collection('Section');
    return this.sectionListRef;
  }

  getMemberSections(){
    this.sectionListRef = this.db.collection('Section', ref => ref.where('addToMember', '==', true));
    return this.sectionListRef;
  }

  getSectionById(id: any): AngularFirestoreDocument<any> {
    this.sectionRef = this.db.doc('Section/' + id);
    return this.sectionRef;
  }

  deleteSection(id: any) {
    const contentList = this.db.collection('SectionContent', ref => ref.where('sectionId', '==', id)).get().subscribe(querySnapshot => {
      querySnapshot.forEach(doc => {
        doc.ref.delete();
      });
    }, error => {
      console.error('Error fetching documents:', error);
    });

    return this.db.collection('Section').doc(id).delete();
  }

  updateSection(model: any) {
    return this.db.collection('Section').doc(model.id).update(model);
  }

  saveContent(model: any) {
    model.id = this.db.createId();
    return this.db.collection('SectionContent').doc(model.id).set(model);
  }

  getSectionContent(id: any) {
    this.sectionListRef = this.db.collection('SectionContent', ref => ref.where('sectionId', '==', id));
    return this.sectionListRef;
  }

  getContents() {
    this.sectionListRef = this.db.collection('SectionContent');
    return this.sectionListRef;
  }

  getContenById(id: any) {
    this.sectionRef = this.db.doc('SectionContent/' + id);
    return this.sectionRef;
  }

  updateContent(model: any) {
    return this.db.collection('SectionContent').doc(model.id).update(model);
  }

  updateComments(id: any, comments: any) {
    return this.db.collection('SectionContent').doc(id).update({ 'comments': comments });
  }

  deleteContent(id: any) {
    return this.db.collection('SectionContent').doc(id).delete();
  }
}