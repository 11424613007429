import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ParticipationService } from 'src/app/services/api/participation.service';
import { Location } from '@angular/common';
import { ActivityResultModel } from 'src/app/models/activityResultModel';
import { toast } from 'materialize-css';

declare var M: any;
declare var $: any;

@Component({
  selector: 'app-activity-detail',
  templateUrl: './activity-detail.component.html',
  styleUrls: ['./activity-detail.component.css']
})

export class ActivityDetailComponent implements OnInit {

  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  id = '';
  outcomeYes = false;
  outcomeNo = false;
  minDate = new Date(new Date().getFullYear() - 1, 0, 1);
  maxDate = new Date(new Date().getFullYear() + 1, 0, 1);
  resultForm: UntypedFormGroup;
  reasonForm: UntypedFormGroup;
  activity: any;
  objective = ['Life Stabilization', 'Preparing For Employment', 'Retiring Employment', 'Trainig', 'Finding Employment'];
  empMeasure = ['Addiction Service Initiave', 'Basic Education And Language Training', 'Community Placement',
    'Employment Information Session', 'Employment Placement', 'Employment Placement With Incentive', 'Independant Job Search',
    'Job Specific Skills Training', 'Learning, Earning And Parenting', 'Literacy Assessment And Training',
    'Retaining Employment', 'Self-Employment', 'Structured Job Search'];
  actObj: any;
  empObj: any;

  // tslint:disable-next-line: max-line-length
  constructor(private fb: UntypedFormBuilder, private route: ActivatedRoute, private location: Location, private participationService: ParticipationService) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });

    if (this.id) {
      const numID = +this.id;
      this.participationService.getActivityDetail(numID).subscribe(x => {
        if (x) {
          this.activity = x[0];

          this.actObj = this.objective[x[0].objective - 1];
          this.empObj = this.empMeasure[x[0].emp_measure - 1];

          setTimeout(() => {
            M.updateTextFields();
            $('.collapsible').collapsible();
          }, 50);
        }
      });
    }
  }

  YesClicked() {
    this.outcomeNo = false;
    this.outcomeYes = true;
    this.resultForm = this.fb.group({
      date: [this.dateToShortFormat(new Date()), Validators.required],
      result: ['', [Validators.required]]
    });
    setTimeout(() => {

      $('.datepicker').datepicker({
        format: 'dd/mmm/yyyy',
        maxDate: this.maxDate,
        minDate: this.minDate,
        yearRange: 20,
        onSelect(e) {
          localStorage.setItem('date', e);
        },
      });

      M.updateTextFields();
      M.textareaAutoResize($('#textarea1'));
      $('select').formSelect();
    }, 50);
  }

  NoClicked() {
    this.outcomeYes = false;
    this.outcomeNo = true;
    this.reasonForm = this.fb.group({
      reason: ['', [Validators.required]]
    });
    setTimeout(() => {
      M.textareaAutoResize($('#textarea1'));
    }, 50);
  }

  InProgressClicked() {
    this.outcomeYes = false;
    this.outcomeNo = false;
  }

  submitResult(val: any) {
    const model = new ActivityResultModel();
    const numID = +this.id;
    model.ID = numID;
    model.date_obtained = this.dateToShortFormat(new Date(localStorage.getItem('date')));
    model.result = val.value.result;
    this.participationService.postActivityResult(model).subscribe(x => {
      toast({ html: 'Activity Result Successfully Submitted!', classes: 'green' });
    });
  }

  submitReason(val: any) {
    const model = new ActivityResultModel();
    const numID = +this.id;
    model.ID = numID;
    model.result = val.value.result;
    this.participationService.postReason(model).subscribe(x => {
      toast({ html: 'Activity Successfully Submitted!', classes: 'green' });
    });
  }

  backClicked() {
    this.location.back();
  }

  get result() { return this.resultForm.get('result'); }
  get date() { return this.resultForm.get('date'); }
  get reason() { return this.reasonForm.get('reason'); }

  dateToShortFormat(value: Date) {
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const day = value.getDate();
    const monthIndex = value.getMonth();
    const year = value.getFullYear();
    return day + '/' + monthNames[monthIndex] + '/' + year;
  }

}