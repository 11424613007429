import { environment } from "src/environments/environment"

export class ShareDataModel{
    share:boolean
    post=''
    videoUrls=[]
    imageUrls=[]
}


export class UserProfile{
    title=''
    hideTitle=false
    displayTitle=''
    disableSocial=[]
}

export class UserProfileModel{
    userId=''
    id=''
    profileKey=''
    refId=''
    createdDate
    title
}

export class ProfileCreatedResponse{
    error:boolean
    errorMessage:string
    response:any
}


export class PostModel{
    post='';
    platforms =[];
    mediaUrls=[];
    profileKeys=[];
    scheduleDate:'' // "YYYY-MM-DDThh:mm:ssZ"

    // shortenLinks:''
    // autoSchedule:{} // premium or business plan
    // autoRepost:{} // premium or business plan
}

export let platforms =["facebook", "fbg", "twitter", "linkedin", "instagram", "youtube", "reddit", "telegram", "gmb", "pinterest", "tiktok"]
export let analysisPlatforms =["facebook","instagram","twitter","pinterest"] //tiktok, linkedin -company
export let analyticsPlatforms =["facebook","instagram","twitter","pinterest","gmb","reddit","youtube"] //tiktok, linkedin -company

export let commentPlatforms =["facebook","instagram","twitter","youtube","tiktok"] 

export enum postStatus{
    success='success',
    error='error',
    processing="processing",
    pending="pending"
}

export class JwtModel{
    domain=environment.socialMedia.domain
    privateKey=''
    profileKey=''
    logout=true   
}

export class UnlinkAccountModel{
    platform=''
    profileKey='' 
}

export let socialMediaIcons={
    "facebook":{
        "black":"assets/icons/socialMedia/facebook2.png",
        "color":"assets/icons/socialMedia/facebook.png"
    },
    "instagram":{
        "black":"assets/icons/socialMedia/instagram2.png",
        "color":"assets/icons/socialMedia/instagram.png"
    },
    "twitter":{
        "black":"assets/icons/socialMedia/twitter2.png",
        "color":"assets/icons/socialMedia/twitter.png"
    },
}

export class DeletePostModel{
    id=''
    // bulk=[]
    profileKeys=''
}

export class PostCommentModel{
    id=''
    platforms=[]
    profileKey=''
    comment=''

}

export class PostAnalyticsModel{
    id=''
    // platforms=[]
    profileKey=''
}

export class PlatformAnalyticsModel{
    platforms=[]
    profileKey=''
}

export let platformColors = {
    'facebook':'#4267B2',
    'instagram':'#C32889',
    'pinterest':'#E60023',
    "twitter":'#1da1f2',
    'reddit':'#FF4500',
    'youtube':'#c4302b',
    'fbg':'#4267B2',
    'telegram':'#0088cc',
    "linkedin":'#0072b1',
    'gmb':'#4267B2',
    'tiktok':'#014E4B',
}