import { Injectable } from '@angular/core';
import { AngularFirestoreCollection } from '@angular/fire/compat/firestore/collection/collection';
import { AngularFirestoreDocument } from '@angular/fire/compat/firestore/document/document';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { toast } from 'materialize-css';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FireMembersService {

  constructor(private db: AngularFirestore) { }

  membersListRef: AngularFirestoreCollection<any>;
  memberRef: AngularFirestoreDocument<any>;

  getMemberByID(id: string): AngularFirestoreDocument<any> {
    this.memberRef = this.db.doc('BandMembers/' + id);
    return this.memberRef;
  }

  getMemberByAdminID(id: number): AngularFirestoreCollection<any> {
    this.membersListRef = this.db.collection('BandMembers', ref => ref.where('id', '==', id));
    return this.membersListRef;
  }

  getMemberByEmail(email: string): AngularFirestoreCollection<any> {
    this.membersListRef = this.db.collection('BandMembers', ref => ref.where('Email', '==', email));
    return this.membersListRef;
  }

  getMembersList(): AngularFirestoreCollection<any> {
    //this.membersListRef = this.db.collection('BandMembers', ref => ref.where('role', '==', 'Member'));
    this.membersListRef = this.db.collection('BandMembers');
    return this.membersListRef;
  }
  getMemberByAppliciantId(applicantID: any) {
    this.membersListRef = this.db.collection('BandMembers', ref => ref.where('applicantID', '==', applicantID));
    return this.membersListRef;
  }

  getAdminAccounts() {
    this.membersListRef = this.db.collection('BandMembers', ref => ref.where('role', '==', 'Admin'));
    return this.membersListRef;
  }

  getAllAccounts(){
    this.membersListRef = this.db.collection('BandMembers');
    return this.membersListRef;
  }

  updateAccountByMemberId(model: any) {
    this.membersListRef = this.db.collection('BandMembers', ref => ref.where('applicantID', '==', model.applicantID));
    this.membersListRef.snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data();
        return data;
      }))).subscribe((_doc: any) => {
        let id = _doc[0].uid;
        this.db.collection('BandMembers').doc(id).update({ "profileImgLink": model.profileImgLink }).then(
        );
      });
  }

  updateAccount(model: any) {
    this.db.collection('BandMembers').doc(model.uid).set(model).then(
      // toast({html: 'Account Status Successfully Updated', classes: 'green'})
    );
  }

  updateAccountRole(model) {
    return this.db.collection('BandMembers').doc(model.uid).update(model);
  }

  setMemberAsChief(applicantID) {
    this.membersListRef = this.db.collection('BandMembers', ref => ref.where('applicantID', '==', applicantID));
    this.membersListRef.snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data();
        return data;
      }))).subscribe((doc: any) => {
        if (doc) {
          if (doc.length > 0) {
            const id = doc[0].uid;
            this.db.collection('BandMembers').doc(id).update({ isChief: true, isCouncillor: false }).then(
            );
          }
        }
      });
  }

  setMemberAsCouncillor(applicantID) {
    this.membersListRef = this.db.collection('BandMembers', ref => ref.where('applicantID', '==', applicantID));
    this.membersListRef.snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data();
        return data;
      }))).subscribe((doc: any) => {
        if (doc) {
          if (doc.length > 0) {
            const id = doc[0].uid;
            this.db.collection('BandMembers').doc(id).update({ isChief: false, isCouncillor: true }).then(
            );
            return;
          }
        }
      });
  }

  removeMemberFromGovernance(applicantID) {
    this.membersListRef = this.db.collection('BandMembers', ref => ref.where('applicantID', '==', applicantID));
    this.membersListRef.snapshotChanges().pipe(
      map(actions => actions.map(a => {
        const data = a.payload.doc.data();
        return data;
      }))).subscribe((doc: any) => {
        const id = doc[0].uid;
        this.db.collection('BandMembers').doc(id).update({ isChief: false, isCouncillor: false }).then(
        );
      });
  }

  // updateDemoAdminAccount(model: any) {
  //   this.db.collection('BandMembers').doc(model.uid).set(model).then(
  //     toast({ html: 'Demo Account Successfully ${model.status}' + model.status, classes: 'green' })
  //   );
  // }

  updateDemoAdminAccount(model: any) {
    this.db.collection('BandMembers').doc(model.uid).set(model).then(() => {
      toast({ html: `Demo Account Successfully Updated with status: ${model.status}`, classes: 'green' });
    }).catch(error => {
      console.error('Error updating demo admin account:', error);
      throw error;
    });
  }

  deleteDemoAdminAccount(model: any) {
    return this.db.collection('BandMembers').doc(model.uid).delete();
  }

  // updatePermissions(model: any) {
  //   // this.db.collection('BandMembers').doc(model.uid).update({permissions: permissions}).then(
  //   //   toast({ html: 'Admin Permissions Successfully Updated', classes: 'green' })
  //   // );
  //   this.db.collection('BandMembers').doc(model.uid).set(model).then(
  //     toast({ html: 'Admin Permissions Successfully Updated', classes: 'green' })
  //   );
  // }
  updatePermissions(model: any) {
    this.db.collection('BandMembers').doc(model.uid).set(model).then(() => {
      toast({ html: 'Admin Permissions Successfully Updated', classes: 'green' });
    }).catch(error => {
      console.error('Error updating permissions:', error);
      throw error;
    });
  }

  // updatePermission(id: string, permission: any){
  //   this.db.collection('BandMembers').doc(id).update({permissions: permission}).then(
  //     toast({ html: 'Admin Permissions Successfully Updated', classes: 'green' })
  //   );
  // }
  updatePermission(id: string, permission: any) {
    this.db.collection('BandMembers').doc(id).update({ permissions: permission }).then(() => {
      toast({ html: 'Admin Permissions Successfully Updated', classes: 'green' });
    }).catch(error => {
      console.error('Error updating permission:', error);
      throw error;
    });
  }

  updateVerification(id, verified, status){
    this.db.collection('BandMembers').doc(id).update({verified: verified, verificationStatus: status}).then(
      // toast({ html: 'Member Verified', classes: 'green' })
    );
  }

}