import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class ContactService {

  messageListRef: AngularFirestoreCollection<any>;
  messageRef: AngularFirestoreDocument<any>;

  constructor(private db: AngularFirestore) { }

  sendMessage(model: any)  {
    model.id = this.db.createId();
    model.discussionID = model.id;
    return this.db.collection('Message-Box').doc(model.id).set(model);
  }

  sendPublicMessage(model: any)  {
    model.id = this.db.createId();
    model.discussionID = model.id;
    return this.db.collection('Message-Box-Public').doc(model.id).set(model);
  }

  respondToMessage(model: any)  {
    model.id = this.db.createId();
    return this.db.collection('Message-Box').doc(model.id).set(model);
  }

  getMessageByID(msgID: string): AngularFirestoreDocument<any> {
    this.messageRef = this.db.doc('Message-Box/' + msgID);
    return this.messageRef;
  }


  updateMessage(model: any)  {
    return this.db.collection('Message-Box').doc(model.id).update(model);
  }

  deleteMessage(model: any)  {
    return this.db.collection('Message-Box').doc(model.id).delete();
  }

  getDiscussion(discussionID: string): AngularFirestoreCollection<any[]> {
    this.messageListRef = this.db.collection('Message-Box', ref => ref.where('discussionID', '==', discussionID));
    return this.messageListRef;
  }

  getUserMessageList(userID: string): AngularFirestoreCollection<any[]> {
    this.messageListRef = this.db.collection('Message-Box', ref => ref.where('toID', '==', userID));
    return this.messageListRef;
  }

  getAllMessageList(): AngularFirestoreCollection<any[]> {
    this.messageListRef = this.db.collection('Message-Box');
    return this.messageListRef;
  }


}
