import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { toast } from 'materialize-css';
import { HousingService } from 'src/app/services/api/housing.service';
import { MediaService } from 'src/app/services/api/media.service';
import { UntypedFormGroup } from '@angular/forms';
import { Attachment } from 'src/app/models/emailModel';
import { DataService } from 'src/app/services/data.service';

const pdfMake = require('pdfmake/build/pdfmake.js');
const pdfFonts = require('pdfmake-unicode/dist/pdfmake-unicode.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;

declare var $: any;

@Component({
  selector: 'app-property-detail',
  templateUrl: './property-detail.component.html',
  styleUrls: ['./property-detail.component.css']
})
export class PropertyDetailComponent implements OnInit {

  provinces: any[];
  loading = false;
  propertyId = '';
  property: any;
  fileName = "House.pdf";
  fileLink = '';
  reportFile: File;
  emailList: any[] = [];
  emailForm: UntypedFormGroup;
  occupantsIds: any[];
  member: any;
  occupants: any[];
  houseAge: number;
  members: any[];
  HoH: any;
  fileList: any[] = [];
  sendEmail = false;
  emailSent = false;
  ownerSelected = false;

  emailSentHandler(valueEmitted) {
    if (valueEmitted) this.emailSent = true;
    this.sendEmail = false;
  }

  constructor(private route: ActivatedRoute,
    private housingService: HousingService, 
    private location: Location,
    private mediaService: MediaService,
    private dataService: DataService) {
    this.provinces = this.dataService.getProvinceWithCode();
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.propertyId = params.get('id');
    });

    if (this.propertyId) {
      this.loading = true;
      this.housingService.getHouse(Number(this.propertyId)).subscribe(x => {
        if (x && x.length > 0) {
          this.property = x[0];
          if (this.property.aid_of_owner) this.ownerSelected = true;

          //get owner and occupant information
          if (this.property.ID) {
            this.housingService.getOccupants(this.property.ID).subscribe(x => {
              if (x && x.length > 0) this.occupants = x;
              else this.occupants = [];
              this.loading = false;

              setTimeout(() => {
                $('select').formSelect();
                $('.dropdown-trigger').dropdown();
                $('.modal').modal();
              }, 25);
            });
          } else {
            this.occupants = [];
          }
          const currentYear = new Date().getFullYear();
          this.property.age = currentYear - Number(this.property.year);
        }
      });
    }
  }

  get subject() { return this.emailForm.get('subject'); }
  get body() { return this.emailForm.get('body'); }
  get files() { return this.emailForm.get('files'); }

  getAge(year) {
    const currentYear = new Date().getFullYear();
    const age = currentYear - Number(year);
    return age;
  }

  removeHoH() {
    this.housingService.RemoveOwner(Number(this.propertyId)).subscribe(x => {
      if (x == 'Success') {
        toast({ html: 'HoH Removed!', classes: 'green' });
        this.cancel();
        this.back();
      }
    });
  }

  deleteHouse() {
    if (this.propertyId) {
      this.housingService.deleteHouse(Number(this.propertyId)).subscribe(x => {
        if (x) {
          toast({ html: 'House Deleted!', classes: 'red' });
          $('.modal').modal('close');
          this.back();
        }
      });
    }
  }

  async getReport() {
    let report = {
      info: {
        title: 'House Detail',
        subject: 'House Detail',
        keywords: 'House',
      },
      pageSize: 'LETTER',
      content: await this.getContent(),
      styles: this.getStyles(),
    }

    return report;
  }

  async getContent() {
    const property = this.property;
    let content = [];

    content.push(
      //header with title 
      {
        style: 'table',
        layout: 'noBorders',
        table: {
          widths: ['*', '*'],
          body: [
            [
              {
                columns: [
                  [
                    await this.getLogo()
                  ],
                  [
                    {
                      stack: [
                        {
                          text: 'MY NATION ',
                          style: 'mainTitle',
                          alignment: 'center',
                        },
                        {
                          text: 'Connecting Your Nation',
                          style: 'normalText',
                          alignment: 'center',
                        },
                        {
                          text: 'Sovereign Data For Sovereign Nations ',
                          fontSize: 8,
                          italics: true,
                          alignment: 'center',
                        },
                      ]
                    },
                  ],
                ]
              },
              {
                stack: [
                  {
                    text: "Department Of Housing",
                    style: 'title'
                  },
                  {
                    text: 'Houses',
                    style: 'title'
                  },
                  {},
                ]
              },
            ],
          ]
        }
      },

      //Proprty details
      {
        style: 'table',
        table: {
          widths: ['*', '*', '*'],
          body: [
            [
              {
                stack: [
                  {
                    text: 'House ID: ',
                    style: 'subTitle'
                  },
                  {
                    text: property.ID,
                    style: 'normalText'
                  },
                ]
              },
              {
                stack: [
                  {
                    text: 'Location: ',
                    style: 'subTitle'
                  },
                  {
                    text: property.location ? property.location : '',
                    style: 'normalText'
                  },
                ]
              },
              {
                stack: [
                  {
                    text: 'Community: ',
                    style: 'subTitle'
                  },
                  {
                    text: property.community,
                    style: 'normalText'
                  },
                ],
              },
            ],
            [
              {
                stack: [
                  {
                    text: 'Head of Household ID: ',
                    style: 'subTitle'
                  },
                  {
                    text: property.aid_of_owner,
                    style: 'normalText'
                  },
                ],

              },
              {
                stack: [
                  {
                    text: 'Head of Household: ',
                    style: 'subTitle'
                  },
                  {
                    text: property.GivenName + " " + property.LastName,
                    style: 'normalText'
                  },
                ],
                colSpan: 2
              },
              {},
            ],
            [
              {
                stack: [
                  {
                    text: 'Primary Heat Source: ',
                    style: 'subTitle'
                  },
                  {
                    text: property.Primary_heat ? property.Primary_heat : "",
                    style: 'normalText'
                  },
                ],
                colSpan: 2
              },
              {},
              {
                stack: [
                  {
                    text: 'Seconday Heat Source: ',
                    style: 'subTitle'
                  },
                  {
                    text: property.Secondary_heat ? property.Secondary_heat : "",
                    style: 'normalText'
                  },
                ],
              },
            ],
            [
              {
                stack: [
                  {
                    text: 'CMHC: ',
                    style: 'subTitle'
                  },
                  {
                    text: property.CMHC ? 'Yes' : "No",
                    style: 'normalText'
                  },
                ],
              },
              {
                stack: [
                  {
                    text: 'Financed: ',
                    style: 'subTitle'
                  },
                  {
                    text: property.finianced ? 'Yes' : "No",
                    style: 'normalText'
                  },
                ],
                colSpan: 2
              },
              {}
            ],
          ]
        }
      },

      property.desc_type == 'HN' ?
        {
          style: 'table',
          table: {
            widths: ['*', '*'],
            body: [

              [
                {
                  stack: [
                    {
                      text: 'House Number: ',
                      style: 'subTitle'
                    },
                    {
                      text: property.house_number ? property.house_number : '',
                      style: 'normalText'
                    },
                  ],
                  colSpan: 2
                },
                {}
              ],
            ]
          }
        } : '',

      property.desc_type == 'lotBlock' ?
        {
          style: 'table',
          table: {
            widths: ['*', '*'],
            body: [
              [
                {
                  stack: [
                    {
                      text: 'Block: ',
                      style: 'subTitle'
                    },
                    {
                      text: property.house_block,
                      style: 'normalText'
                    },
                  ]
                },
                {
                  stack: [
                    {
                      text: 'Lot: ',
                      style: 'subTitle'
                    },
                    {
                      text: property.house_lot,
                      style: 'normalText'
                    },
                  ],
                },
              ],
            ]
          }
        } : '',

      property.desc_type == 'landDesc' ?
        {
          style: 'table',
          table: {
            widths: ['*', '*', '*'],
            body: [

              [
                {
                  stack: [
                    {
                      text: 'QRT: ',
                      style: 'subTitle'
                    },
                    {
                      text: property.Quarter,
                      style: 'normalText'
                    },
                  ]
                },
                {
                  stack: [
                    {
                      text: 'Section: ',
                      style: 'subTitle'
                    },
                    {
                      text: property.section,
                      style: 'normalText'
                    },
                  ],
                  colSpan: 2
                },
                {}
              ],

              [
                {
                  stack: [
                    {
                      text: 'Township: ',
                      style: 'subTitle'
                    },
                    {
                      text: property.township,
                      style: 'normalText'
                    },
                  ]
                },
                {
                  stack: [
                    {
                      text: 'Range: ',
                      style: 'subTitle'
                    },
                    {
                      text: property.range,
                      style: 'normalText'
                    },
                  ],
                },
                {
                  stack: [
                    {
                      text: 'Meridian: ',
                      style: 'subTitle'
                    },
                    {
                      text: property.median,
                      style: 'normalText'
                    },
                  ],
                },
              ],
            ]
          }
        } : '',

      property.desc_type == 'otherLocation' ?
        {
          style: 'table',
          table: {
            widths: ['*', '*'],
            body: [

              [
                {
                  stack: [
                    {
                      text: 'Location Description: ',
                      style: 'subTitle'
                    },
                    {
                      text: property.Other_location,
                      style: 'normalText'
                    },
                  ],
                  colSpan: 3
                },
                {},
                {}
              ],
            ]
          }
        } : '',

      {
        style: 'table',
        table: {
          widths: ['*', '*', '*'],
          body: [
            [
              {
                stack: [
                  {
                    text: 'Mailing Address: ',
                    style: 'subTitle'
                  },
                  {
                    text: property.MailAddress ? property.MailAddress : '',
                    style: 'normalText'
                  },
                ],
              },
              {
                stack: [
                  {
                    text: 'Province: ',
                    style: 'subTitle'
                  },
                  {
                    text: property.province ? this.provinces[this.provinces.findIndex((pro) => pro.code === property.province)].name : '',
                    style: 'normalText'
                  },
                ],
              },

              {
                stack: [
                  {
                    text: 'Postal Code: ',
                    style: 'subTitle'
                  },
                  {
                    text: property.PostalCode,
                    style: 'normalText'
                  },
                ],
              },
            ],
            [
              {
                stack: [
                  {
                    text: 'Built On: ',
                    style: 'subTitle'
                  },
                  {
                    text: property.year ? property.year : '',
                    style: 'normalText'
                  },
                ],
              },
              {
                stack: [
                  {
                    text: 'Age of House : ',
                    style: 'subTitle'
                  },
                  {
                    text: property.year ? this.getAge(property.year) : '',
                    style: 'normalText'
                  },
                ],
              },

              {
                stack: [
                  {
                    text: 'Style: ',
                    style: 'subTitle'
                  },
                  {
                    text: property.style,
                    style: 'normalText'
                  },
                ],
              },
            ],
            [
              {
                stack: [
                  {
                    text: 'No of Rooms: ',
                    style: 'subTitle'
                  },
                  {
                    text: property.rooms,
                    style: 'normalText'
                  },
                ],
              },
              {
                stack: [
                  {
                    text: 'Size Of House: ',
                    style: 'subTitle'
                  },
                  {
                    text: property.size ? property.size : '' + " (sq ft)",
                    style: 'normalText'
                  },
                ],
              },
              {
                stack: [
                  {
                    text: 'Cost of Building: ',
                    style: 'subTitle'
                  },
                  {
                    text: "$ " + property.value,
                    style: 'normalText'
                  },
                ],
              },
            ],
          ]
        }
      },

      //Occupants
      {
        text: "",
        pageBreak: 'after'
      },
      this.occupants.length > 0 ?
        {
          style: 'table',
          table: {
            widths: ['*', '*', '*', '*'],
            body: this.getOccupants()
          }
        } : '',
    );
    return content;
  }

  getOccupants() {
    let occupantsBody = [];
    occupantsBody.push([{ text: "Occupants", colSpan: 4, style: 'title' }, {}, {}, {}]);
    occupantsBody.push([
      {
        text: 'Member ID',
        style: 'header',
        bold: true,
        alignment: 'center',
      },
      {
        text: 'First Name',
        style: 'header',
        bold: true,
        alignment: 'center',
      },
      {
        text: 'Last Name',
        style: 'header',
        bold: true,
        alignment: 'center',
      },
      {
        text: 'Classification',
        style: 'header',
        bold: true,
        alignment: 'center',
      },
    ]);

    this.occupants.forEach(occ => {
      occupantsBody.push(
        [{ text: occ.applicantID ? occ.applicantID : '', style: 'normalText' },
        { text: occ.GivenName ? occ.GivenName : '', style: 'normalText' },
        { text: occ.LastName ? occ.LastName : '', style: 'normalText' },
        { text: occ.Classification ? occ.Classification : '', style: 'normalText' }]
      );
    });

    return occupantsBody;
  };

  getStyles() {
    return {
      mainTitle: {
        bold: true,
        fontSize: 16,
        alignment: 'left',
        margin: [0, 0, 0, 20]
      },
      title: {
        bold: true,
        fontSize: 14,
        alignment: 'center',
        margin: [0, 10, 0, 10]
      },
      subTitle: {
        bold: true,
        fontSize: 12,
        alignment: 'left',
        margin: [0, 10, 0, 10]
      },
      normalText: {
        fontSize: 11,
        margin: [0, 5, 0, 5]
      },
      header: {
        fontSize: 12,
        bold: true,
        alignment: 'center',
      },
      links: {
        color: 'blue',
        decoration: 'underline',
        //italics: true,
        margin: [0, 5, 0, 5]
      },
      table: {
        margin: [0, 5, 0, 5]
      },
    }
  }

  async getLogo() {
    let imageLink = "assets/img/Nation-logo.png";
    const image = await this.getBase64FromUrl(imageLink);
    if (image) {
      return {
        image: image,
        width: 120,
        alignment: 'left'
      }
    }
  }

  getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      }
    });
  }

  async downloadReport() {
    const documentDefinition = await this.getReport();
    pdfMake.createPdf(documentDefinition).download(this.fileName);
  }

  async openReport() {
    const documentDefinition = await this.getReport();
    pdfMake.createPdf(documentDefinition).open();
  }

  async printReport() {
    const documentDefinition = await this.getReport();
    pdfMake.createPdf(documentDefinition).print();
  }

  async sendReport() {
    const documentDefinition = await this.getReport();
    const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
    pdfDocGenerator.getBlob((blob) => {
      this.reportFile = new File([blob], this.fileName, {
        type: blob.type
      });

      this.mediaService.uploadPDF(this.reportFile).subscribe(upload => {
        if (upload) {
          if (upload.imgLink) this.fileLink = upload.imgLink
          //this.fileLink = "https://api.mysabenefits.ca/Media/Demo/PDF/2f3dfc81-ab06-4e8f-9212-3546aea6e313-House.pdf";
          let report = new Attachment();
          report.url = this.fileLink;
          report.name = this.fileName;
          report.type = 'PDF';
          this.fileList.push(report);

          this.sendEmail = true;
        }
      });
    });
  }

  back() {
    this.location.back();
  }

  cancel() {
    $('.modal').modal('close');
  }
  
}
