<div class="">
  <div class="row p-t-2 p-b-10">


    <div class="col s8 m10 l5 push-l1 push-s4 push-m1">
      <div class="header-title">
        <h6>{{sectionName}}</h6>
      </div>
    </div>

    <div class="divider row col l10 m8 s8 push-m2 push-s2 push-l1 grey lighten-3"></div>

    <div class="row col l10 push-l1 m10 push-m1 s12  p-m-1 container-dep">
      <div class="col s12 l3 m6" *ngFor="let dep of depmodel">

        <article class="card" *ngIf='dep.logo' [ngStyle]="{'border-color': themeColor} "
          (click)="editDepartmentData($event, dep.id)">
          <div class="card-image">
            <img *ngIf='dep.logo' class="responsive-img" [src]="dep.logo" />
          </div>
          <div class="card-cont">
            <h3 [innerHTML]="dep.name" class="title-dep title-section3 bold-900 ">
            </h3>
            <p [innerHTML]="dep.mandate" class="middle-dep-body-snippet sub-title-section2 hide-on-small-only"></p>
          </div>
        </article>

        <article class="card" *ngIf='!dep.logo' [ngStyle]="{'border-color': themeColor} "
          (click)="editDepartmentData($event, dep.id)">
          <div class="card-cont">
            <h3 [innerHTML]="dep.name" class="title-dep title-section3 bold-900 ">
            </h3>
            <p [innerHTML]="dep.mandate" class="long-dep-body-snippet sub-title-section2"></p>
          </div>
        </article>
      </div>
    </div>
  </div>

</div>