import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { Comment } from 'src/app/models/AdminPosts.Model';
import { AdminPostsService } from 'src/app/services/firebase/admin-posts.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';

declare var M: any;
declare var $: any;

@Component({
  selector: 'app-posts-display',
  templateUrl: './posts-display.component.html',
  styleUrls: ['./posts-display.component.css']
})

export class PostsDisplayComponent implements OnInit {

  currentUser: any;
  id: any;
  currentUserPosts: any = [];
  isAdmin: boolean;
  commentOnPost: UntypedFormGroup;
  editPostForm: UntypedFormGroup;
  postForEdit: any;
  editAudience = false;
  selectedPost: any;
  currentNationName = '';
  currentNationLogo: any;

  constructor(private fb: UntypedFormBuilder,
    private authService: AuthService,
    private adminPostsService: AdminPostsService,
    private settingsService: AppSettingsService) { }

  ngOnInit() {
    this.commentOnPost = this.fb.group({
      comment: ['', [Validators.required]]
    });

    this.editPostForm = this.fb.group({
      message: ['', [Validators.required]]
    })

    this.currentNationName = environment.firstNation.displayName;
    this.settingsService.getLandingPageSettings().valueChanges().subscribe(
      lpSettings => {
        if (lpSettings) {
          if (lpSettings.length > 0) {
            this.currentNationLogo = lpSettings[0]['logo'];            
            }
          }
        }
    );

    this.currentUser = this.authService.getGlobalUser();
    if (this.currentUser) {
      if (this.currentUser.role == "Admin") {
        this.isAdmin = true;
      }
      this.id = this.currentUser.uid;
    }
    if (this.id) {
      if (this.isAdmin) {
        this.adminPostsService.getPostsByAdminId(this.id, '').valueChanges().subscribe(posts => {
          if (posts) {
            this.currentUserPosts = posts;
            this.currentUserPosts = this.currentUserPosts.sort((a, b) => (b as any).timeStamp.toDate() - (a as any).timeStamp.toDate());
            $(document).ready(function () {
              $('.dropdown-trigger').dropdown({ alignment: 'left' });
              $('.modal').modal();
              
                $('.posts-display-container').find(".scrollMe").each(function(){
                  let height = $($(this)[0]).prop("scrollHeight");
                  $($(this)[0]).animate({ scrollTop: height}, 10);
                });
            })
          }
        });
      } else {
        this.adminPostsService.getPostByMemberId(this.id).valueChanges().subscribe(posts => {
          if (posts) {
            this.currentUserPosts = posts;
            this.currentUserPosts = this.currentUserPosts.sort((a, b) => (b as any).timeStamp.toDate() - (a as any).timeStamp.toDate());
            $(document).ready(function () {
              $('.posts-display-container').find(".scrollMe").each(function(){
                let height = $($(this)[0]).prop("scrollHeight");
                $($(this)[0]).animate({ scrollTop: height}, 10);
              })
            });
          }
        });
      }
    }

    $(document).ready(function () {
      $('.modal').modal();
    })
  }

  checkIfReactionGiven(id) {
    let currentPost = this.currentUserPosts.find(post => post.id == id);
    if (currentPost.reactions.likes.userId.findIndex(userId => userId == this.id) >= 0) {
      return true;
    } else {
      return false;
    }
  }

  reactToPost(postId) {
    if (this.checkIfReactionGiven(postId)) {
      let currentPostindex = this.currentUserPosts.findIndex(post => post.id == postId);
      this.currentUserPosts[currentPostindex].reactions.likes.count -= 1;
      this.currentUserPosts[currentPostindex].reactions.likes.userId.pop(this.id);
      this.currentUserPosts[currentPostindex].reactions.likes.userId.pop(`${this.currentUser.GivenName || this.currentUser.FirstName} ${this.currentUser.LastName}`);
      this.adminPostsService.updateReactionOfApost(this.currentUserPosts[currentPostindex]);
    } else {
      let currentPostindex = this.currentUserPosts.findIndex(post => post.id == postId);
      this.currentUserPosts[currentPostindex].reactions.likes.count += 1;
      this.currentUserPosts[currentPostindex].reactions.likes.userId.push(this.id);
      this.currentUserPosts[currentPostindex].reactions.likes.userId.push(`${this.currentUser.GivenName || this.currentUser.FirstName} ${this.currentUser.LastName}`);
      this.adminPostsService.updateReactionOfApost(this.currentUserPosts[currentPostindex]);
    }
  }

  likeComment(postId, commentId) {
    let currentPostindex = this.currentUserPosts.findIndex(post => post.id == postId);
    let currentComment = this.currentUserPosts[currentPostindex].comments.findIndex(comment => comment.id == commentId);
    if (this.currentUserPosts[currentPostindex].comments[currentComment].likes.userId.findIndex(userId => userId == this.id) >= 0) {
      this.currentUserPosts[currentPostindex].comments[currentComment].likes.count -= 1;
      this.currentUserPosts[currentPostindex].comments[currentComment].likes.userId.pop(this.id);
      this.currentUserPosts[currentPostindex].comments[currentComment].likes.userId.pop(`${this.currentUser.GivenName || this.currentUser.FirstName} ${this.currentUser.LastName}`);
      this.adminPostsService.updateComments(this.currentUserPosts[currentPostindex]);
    } else {
      this.currentUserPosts[currentPostindex].comments[currentComment].likes.count += 1;
      this.currentUserPosts[currentPostindex].comments[currentComment].likes.userId.push(this.id);
      this.currentUserPosts[currentPostindex].comments[currentComment].likes.userId.push(`${this.currentUser.GivenName || this.currentUser.FirstName} ${this.currentUser.LastName}`);

      this.adminPostsService.updateComments(this.currentUserPosts[currentPostindex]);
    }
  }

  replyToComment(postId, commentId) {
  }

  addCommentToPost(post, comment) {
    let newComment = new Comment();
    newComment.postId = post.id;
    newComment.userId = this.id;
    newComment.userName = `${this.currentUser.GivenName || this.currentUser.FirstName} ${this.currentUser.LastName}`
    newComment.comment = comment;
    newComment.timestamp = new Date();
    newComment.likes = {
      count: 0,
      userId: [],
      usernames: []
    };
    newComment.replies = [];
    this.adminPostsService.addCommentToPost(post, newComment);
    this.commentOnPost.reset();
    M.textareaAutoResize($('.textarea'));
  }

  selectPost(post){
    this.selectedPost = post;
  }
  archivePost() {
    this.adminPostsService.archivePost(this.selectedPost.id);
    this.selectedPost = [];
  }

  editPostClicked(){
    this.postForEdit = this.selectedPost;
    this.editPostForm.reset();
    this.editPostForm.patchValue({
      message: this.postForEdit.message
    })
    $('.modal#editPost').modal('open');
  }

  editPost(value){
    this.postForEdit.message = value.message;
    this.adminPostsService.editPost(this.postForEdit);
    this.editPostForm.reset();
    this.selectedPost = [];
    this.postForEdit = [];
  }
  
  replaceURLs(message) {
    if (!message) return;

    var urlRegex = /((http:\/\/|https:\/\/)?(www.)?(([a-zA-Z0-9-]){2,}\.){1,4}([a-zA-Z]){2,6}(\/([a-zA-Z-_\/\.0-9#:?=&;,]*)?)?)/ig;
    return message.replace(urlRegex, function (url) {
      var hyperlink = url;
      if (!hyperlink.match('^https?:\/\/')) {
        hyperlink = 'http://' + hyperlink;
      }
      return '<a href="' + hyperlink + '" target="_blank" rel="noopener noreferrer">' + url + '</a>'
    });
  }

  viewPostsByAudience(audienceid){
    this.adminPostsService.getPostsByAdminId(this.id, audienceid).valueChanges().subscribe(posts =>{
      if(posts){
        this.currentUserPosts = posts;
      }
    })
  }
}