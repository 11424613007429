import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { DepartmentService } from 'src/app/services/firebase/department.service';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';

declare var $:any;
@Component({
  selector: 'app-contact-department-member',
  templateUrl: './contact-department-member.component.html',
  styleUrls: ['./contact-department-member.component.css']
})

export class ContactDepartmentMemberComponent implements OnInit {

  nationOfUSe = environment.firstNation.name;
  id: any;
  employeeList = [];
  governanceModel: any;
  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  contactForm: UntypedFormGroup;
  step1 = true;
  step2 = false;
  selectedCount = 0;
  showContactForm = false;
  showCouncillorsList = false;
  selectableCouncillorsList: any[];
  modalHeader = '';
  selectedEmployee: any; 
  formatedWorkPhone: any;
  formatedMobilePhone: any;
  showMsg = false;
  selectedDepName = '';

  constructor(private route: ActivatedRoute, 
     private departmentService: DepartmentService,
    private location: Location,
    private fb: UntypedFormBuilder) { }

  ngOnInit() {
    this.contactForm = this.fb.group({
      subject: ['', [Validators.required]],
      body: ['', [Validators.required]],
    });

    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });

    if (this.id) {
      this.getDepartmentInfo(this.id);
    }
    setTimeout(() => {
      $('select').formSelect();
      $('.modal').modal();
    }, 25);
  }

  getDepartmentInfo(id: any) {
      this.departmentService.getDepartmentEmployee(id).valueChanges().subscribe(
        emp => {
          if (emp) {
            if (emp.length > 0) {
              this.employeeList = emp;
            } else {
              this.employeeList = [];
              this.showMsg = true;
            }
          } else {
            this.showMsg = true;
            //if no department found
           }
        }
      );
  }

  showMobile(emp: any) {
    this.modalHeader = 'Mobile Number';
    this.selectedEmployee = emp;
    this.formatedMobilePhone = emp.mobilePhone.replace('(', '');
    this.formatedMobilePhone = this.formatedMobilePhone.replace(')', '');
    this.formatedMobilePhone = this.formatedMobilePhone.replace('-', '');
    this.formatedMobilePhone = this.formatedMobilePhone.replace(/\s/g, '');
    setTimeout(() => {
      $('.modal').modal();
      $('#mobilePhoneModal').modal('open');
    }, 25);
  }

  showWorkPhone(emp: any) {
    this.modalHeader = 'Work Phone';
    this.selectedEmployee = emp;
    this.formatedWorkPhone = emp.workPhone.replace('(', '');
    this.formatedWorkPhone = this.formatedWorkPhone.replace(')', '');
    this.formatedWorkPhone = this.formatedWorkPhone.replace('-', '');
    this.formatedWorkPhone = this.formatedWorkPhone.replace(/\s/g, '');
    setTimeout(() => {
      $('.modal').modal();
      $('#workPhoneModal').modal('open');
    }, 25);
  }

  showEmailModal(emp: any) {
    this.modalHeader = 'Send Email';
    this.selectedEmployee = emp;
    this.contactForm = this.fb.group({
      subject: ['', [Validators.required]],
      body: ['', [Validators.required]],
    });
    this.showContactForm = true;
    setTimeout(() => {
      $('.modal').modal();
      $('#emailModal').modal('open');
    }, 25);
  }

  formatPhoneNumber(val: any) {
    let phone = val;
    phone = phone.replace('(', '');
    phone = phone.replace(')', '');
    phone = phone.replace('-', '');
    phone = phone.replace(/\s/g, '');
    return phone;
  } 
  
  formatWorkNumber(model: any) {
    let phone = model.workPhone;
    let ext = model.ext;

    phone = phone.replace('(', '');
    phone = phone.replace(')', '');
    phone = phone.replace('-', '');
    phone = phone.replace(/\s/g, '');

    if(ext) {
      ext = ext.replace(/\s/g, '');
      phone = phone + 'p' + ext;
    }

    return phone;
  }

  backClicked() {
    this.location.back();
  }

}
