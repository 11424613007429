import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { EmploymentModel } from 'src/app/models/employmentModel';
import { EmploymentService } from 'src/app/services/api/employment.service';
import { EmploymentService as EmploymentFirebaseService } from 'src/app/services/firebase/employment.service';
import { toast } from 'materialize-css';
import { Location } from '@angular/common';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import { Router } from '@angular/router';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-add-employment',
  templateUrl: './add-employment.component.html',
  styleUrls: ['./add-employment.component.css']
})

export class AddEmploymentComponent implements OnInit {

  myDpOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'dd/mmm/yyyy',
    markCurrentDay: true,
  };

  constructor(private authService: AuthService, private fb: UntypedFormBuilder, private location: Location, private router: Router,
    private employmentService: EmploymentService, private employmentFirebaseService: EmploymentFirebaseService) { }

  showEndDate = false;
  province = environment.firstNation.province;
  applicantID = 0;
  step0 = true;
  step1 = false;
  step2 = false;
  provinces: any = ['Alberta', 'British Columbia', 'Manitoba', 'New Brunswick',
    'Newfoundland and Labrador', 'Nova Scotia', 'Ontario', 'Prince Edward Island', 'Quebec', 'Saskatchewan'];

  addEmploymentForm: UntypedFormGroup;
  //addEmploymentFormStep2: FormGroup;
  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  sqldb = environment.firstNation.SQLDB;
  model: EmploymentModel;
  require_end_date = false;
 
  ngOnInit() {
    if (localStorage.getItem('applicantID')) {
      this.applicantID = Number(localStorage.getItem('applicantID'));
    } else {
      this.applicantID = this.authService.getCurrentApplicantID();
    }

    this.addEmploymentForm = this.fb.group({
      Name: ['', [Validators.required]],
      employer: ['', [Validators.required]],
      City: ['', [Validators.required]],
      Prov: [this.province, [Validators.required]],
      description: ['', [Validators.required]],
      start_date: ['', [Validators.required]],
      end_date: [''],
      iscurrent: [null, [Validators.required]],
      reason: ['']
    });

    this.addEmploymentForm.get('iscurrent').valueChanges.subscribe(value => {
      if (value == 'yes') {
        this.addEmploymentForm.get('end_date').clearValidators();
      } else {
        this.addEmploymentForm.get('end_date').setValidators(Validators.required);
      }
      this.addEmploymentForm.get('end_date').updateValueAndValidity();
    });

    this.model = new EmploymentModel();
    this.model.client_id = this.applicantID;

    setTimeout(() => {
      $('select').formSelect();
    }, 25);
  }

  onDateChange1(event: IMyDateModel): void {
    localStorage.setItem('selectedDate', event.singleDate.jsDate.toDateString());
    if (this.addEmploymentForm) {
      this.addEmploymentForm.patchValue({ start_date: event.singleDate.formatted });
    }
  }

  onDateChange2(event: IMyDateModel): void {
    localStorage.setItem('selectedEndDate', event.singleDate.jsDate.toDateString());
    if (this.addEmploymentForm) {
      this.addEmploymentForm.patchValue({ end_date: event.singleDate.formatted });
    }
  }

  onCurrentEmploymentYes() {
    this.showEndDate = false;
    this.addEmploymentForm.patchValue({ end_date: '' });
    this.addEmploymentForm.patchValue({ reason: '' });
  }

  onCurrentEmploymentNo() {
    this.showEndDate = true;
    this.addEmploymentForm.patchValue({ end_date: '' });
  }

  submitForm(value: any) {

    this.model.Name = value.Name;
    this.model.description = value.description;
    this.model.employer = value.employer;
    this.model.City = value.City;
    this.model.Prov = value.Prov;

    this.model.start_date = new Date(localStorage.getItem('selectedDate'));
    this.model.iscurrent = (value.iscurrent === 'yes') ? true : false;


    if (!this.model.iscurrent) {
      this.model.end_date = new Date(localStorage.getItem('selectedEndDate'));
      this.model.reason = value.reason;
    } else {
      this.model.end_date = new Date();
      this.model.reason = "";
    }

    if (this.sqldb) {
      this.employmentService.saveEmployemnt(this.model).subscribe(x => {
        if (x) {
          toast({ html: 'Work Experience Successfully Added!', classes: 'green' });
          this.backClicked();
        }
      });
    } else {
      this.employmentFirebaseService.saveEmployment(this.model).then(x => {
        toast({ html: 'Work Experience Successfully Added!', classes: 'green' });
        this.backClicked();
      });
    }
  }


  submitForm2(value: any) {
    var d_arr = value.start_date.singleDate.formatted.split("-");
    var start_d = new Date(d_arr[1] + "-" + d_arr[0] + "-" + d_arr[2]);
    var start_date = new Date(start_d.getFullYear(), start_d.getMonth(), start_d.getDate());
    var end_date = new Date();
    if (value.end_date != "") {
      var d_arr = value.end_date.singleDate.formatted.split("-");
      var end_d = new Date(d_arr[1] + "-" + d_arr[0] + "-" + d_arr[2]);
      var end_date = new Date(end_d.getFullYear(), end_d.getMonth(), end_d.getDate());
    }
    this.model.start_date = start_date;
    this.model.end_date = end_date;
    this.model.iscurrent = (value.iscurrent === 'yes') ? true : false;
  }

  backToPrevious() {
    this.step1 = false;
    this.step0 = true;
    setTimeout(() => {
      M.updateTextFields();
    }, 25);
  }

  startDateChange() {
    //if (this.addEmploymentFormStep2) {this.addEmploymentFormStep2.patchValue({start_date : $('#start_date').val()});}
    if (this.addEmploymentForm) { this.addEmploymentForm.patchValue({ start_date: $('#start_date').val() }); }
  }

  endDateChange() {
    //if (this.addEmploymentFormStep2) {this.addEmploymentFormStep2.patchValue({end_date : $('#end_date').val()});}
    if (this.addEmploymentForm) { this.addEmploymentForm.patchValue({ end_date: $('#end_date').val() }); }
  }

  backClicked() {
    if (localStorage.getItem('employment_call_from') == 'case_plan') {
      this.router.navigate(['/member/member-main-home/profile/case-plan']);
    } else if (localStorage.getItem('employment_call_from') == 'employment') {
      this.router.navigate(['/member/member-main-home/profile/employment']);
    } else { this.location.back(); }
  }

  get Name() { return this.addEmploymentForm.get('Name'); }
  get employer() { return this.addEmploymentForm.get('employer'); }
  get City() { return this.addEmploymentForm.get('City'); }
  get Prov() { return this.addEmploymentForm.get('Prov'); }
  get description() { return this.addEmploymentForm.get('description'); }
  get start_date() { return this.addEmploymentForm.get('start_date'); }
  get end_date() { return this.addEmploymentForm.get('end_date'); }
  get iscurrent() { return this.addEmploymentForm.get('iscurrent'); }
  get reason() { return this.addEmploymentForm.get('reason'); }

}
