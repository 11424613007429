<div class="row center-align p-m-2">
    <h6><strong> Messenger </strong></h6>
</div>

<div class="row col s12">
  <div class="col l "></div>
  <div class="col s12 m12 l10">
    <div class="card-panel white darken-2">
      <h6 class="center black-text"><i class="material-icons blue-text left">info</i>
        <strong> Once ready, this functionality will allow internal office communication.</strong>
      </h6> </div>
<div class="section"></div>
      <a [ngClass]="'col s12 btn waves-effect ' + themeColor" (click)="backClicked()"><i
        class="material-icons left">house</i> Back To Home Page</a>

  </div>
  <div class="col l1 "></div>
</div>
