<div id="chatGptModal" class="modal">
  <div class="modal-content p-b-0" style="padding-top: 10px; padding-bottom: 0px">
    <div class="row">
      <div class="left">
        <h6 class="left-align bold-600 title-section3 black-text">
          Generate Content
        </h6>
        <p class="gray-text text-sm">{{ title }}</p>
        <div class="bold-600 p-t-1 text-darken-4" [ngClass]="counterCss()">
          {{ counterMessage() }}
        </div>
      </div>
      <div class="right">
        <svg xmlns="http://www.w3.org/2000/svg" class="close-icon right" (click)="closeModal()" viewBox="0 0 384 512">
          <!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
          <path
            d="M342.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L192 210.7 86.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L146.7 256 41.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L192 301.3 297.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L237.3 256 342.6 150.6z" />
        </svg>
      </div>
    </div>
    
    <div class="row p-t-1 no-padding no-margin">
      <form [formGroup]="chatForm" (ngSubmit)="submitChat(chatForm.value)" class="col s12 no-padding no-margin ">
        <div class="col s12 row">
          <!-- response -->
          <div class="b-n-3 message-container col s12 row no-margin">
            <div *ngIf="chatResponse && chatResponse.length == 0" class="no-response">
              <svg xmlns="http://www.w3.org/2000/svg" class="icon" viewBox="0 0 512 512">
                <path
                  d="M256 512A256 256 0 1 0 256 0a256 256 0 1 0 0 512zM164.1 325.5C182 346.2 212.6 368 256 368s74-21.8 91.9-42.5c5.8-6.7 15.9-7.4 22.6-1.6s7.4 15.9 1.6 22.6C349.8 372.1 311.1 400 256 400s-93.8-27.9-116.1-53.5c-5.8-6.7-5.1-16.8 1.6-22.6s16.8-5.1 22.6 1.6zM144.4 208a32 32 0 1 1 64 0 32 32 0 1 1 -64 0zm192-32a32 32 0 1 1 0 64 32 32 0 1 1 0-64z" />
              </svg>
              <p class="text-base text-gray-500">
                Type something...... <br />
                Get your first response
              </p>
            </div>

            <div *ngIf="chatResponse && chatResponse.length > 0" class="w-full" style="width: 100%">
              <div *ngFor="let response of chatResponse; let i = index" class="p-b-1">
                <!-- User question -->
                <div class="response-container user-response-container" *ngIf="response.message.role !== 'assistant'">

                  <div class="response-img grey lighten-2">
                    <span class="bold-700 black-text">
                      {{ user
                      ? (user.GivenName ? user.GivenName [0] : user.FirstName[0])
                      : "U" | titlecase }}
                    </span>
                  </div>

                  <div class="response grey lighten-2">
                    <p class="bold-700 text-sm response-text">
                      {{ response.message.role | titlecase }}
                    </p>
                    <p class="text-sm">{{ response.message.content }}</p>
                  </div>
                </div>

                <!-- ChatGPT response -->
                <div class="response-container chatgpt-response-container" *ngIf="response.message.role == 'assistant'">
                  <div class="white space-x-1 response chatgpt-response" [ngStyle]="{ 'border-color': themeColor }">
                    <p class="bold-700 text-sm">ChatGPT</p>
                    <p class="text-sm">{{ response.message.content }}</p>
                    <div class="actions-container">
                      <div class="text-sm actions hover:text-customTeal" (click)="selectResponseChoice(response, true)">
                        Use on textbox
                      </div>
                      <div>|</div>
                      <div id="{{ i }}" class="text-sm actions copy hover:text-customTeal hover:fill-customTeal"
                        (click)="copy(response.message, i)" data-tip="Copied">
                        <svg xmlns="http://www.w3.org/2000/svg" class="hover:fill-customTeal copy-icon"
                          viewBox="0 0 512 512">
                          <!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                          <path
                            d="M272 0H396.1c12.7 0 24.9 5.1 33.9 14.1l67.9 67.9c9 9 14.1 21.2 14.1 33.9V336c0 26.5-21.5 48-48 48H272c-26.5 0-48-21.5-48-48V48c0-26.5 21.5-48 48-48zM48 128H192v64H64V448H256V416h64v48c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V176c0-26.5 21.5-48 48-48z" />
                        </svg>
                        <span>Copy</span>
                      </div>
                    </div>
                  </div>

                  <div class="circle response-img" style="border: solid 1px;"
                    [ngStyle]="{ 'border-color': themeColor }">
                    <span class="bold-700 black-text">C</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Prompt -->
          <div class="w-full no-padding no-margin" style="width: 100%">
            <!-- prompt button -->
            <div class="mb-3">
              <div class="prompt no-padding no-margin" style="width: 100%">
                <input type="search" class="prompt-input" [ngClass]="{ 'bg-gray-100': resLoading }"
                  formControlName="content" placeholder="Type something...." maxlength="200" [disabled]="resLoading"
                  aria-label="Prompt ChatGPT" aria-describedby="button-addon1" />
                <!--Search button-->
                <button *ngIf="!resLoading" class="send-btn" type="submit" id="button-addon1" data-te-ripple-init
                  data-te-ripple-color="light" [disabled]="onMaxResponse() || resLoading" [ngStyle]="{
                    'background-color':
                      onMaxResponse() || resLoading ? 'grey' : themeColor
                  }">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" fill="white" class="h-5 w-5"
                    style="fill: white">
                    <!--! Font Awesome Pro 6.4.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                    <path
                      d="M498.1 5.6c10.1 7 15.4 19.1 13.5 31.2l-64 416c-1.5 9.7-7.4 18.2-16 23s-18.9 5.4-28 1.6L284 427.7l-68.5 74.1c-8.9 9.7-22.9 12.9-35.2 8.1S160 493.2 160 480V396.4c0-4 1.5-7.8 4.2-10.7L331.8 202.8c5.8-6.3 5.6-16-.4-22s-15.7-6.4-22-.7L106 360.8 17.7 316.6C7.1 311.3 .3 300.7 0 288.9s5.9-22.8 16.1-28.7l448-256c10.7-6.1 23.9-5.5 34 1.4z" />
                  </svg>
                </button>
                <button *ngIf="resLoading"
                  class="loading relative z-[2] flex items-center rounded-r bg-darkGray px-6 py-2.5 text-xs font-medium uppercase leading-tight text-white shadow-md transition duration-150 ease-in-out hover:bg-primary-700 hover:shadow-lg focus:bg-primary-700 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-primary-800 active:shadow-lg"
                  type="button" id="button-addon1" data-te-ripple-init data-te-ripple-color="light"
                  [disabled]="onMaxResponse() || resLoading">
                  <div
                    class="inline-block h-5 w-5 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-neutral-100 motion-reduce:animate-[spin_1.5s_linear_infinite]"
                    role="status">
                    <span
                      class="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">Generating
                      content...</span>
                  </div>
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>