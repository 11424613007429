<div class="row center-align">
  <h6 *ngIf="entitlements"><strong> Benefit of {{ entitlements[0]?.Month }}/{{ entitlements[0]?.Year }} </strong></h6>
  <h6 *ngIf="!entitlements"><strong> Benefits Received </strong></h6>
  <p *ngIf="userID" class="red-text"> My ID : {{userID}}</p>
</div>

<div class="row col s12">
  <div class="col l1"></div>
  <div class="col s12 m12 l10">

    <div *ngIf="hasEntitlement">

      <div *ngIf="step_0">

        <ul class="row collection with-header">
          <li class="collection-header">
            <h6>Please Select Fiscal Year</h6>
          </li>
          <div *ngFor="let year of years">
            <li class="collection-item" *ngIf="year === currentYear && currentMonth > 2"
              (click)="newYearSelected(year, year, year +1)">
              <div>
                <strong class="blue-text"> {{ (year) }} - {{ year + 1 }}</strong><a
                  (click)="newYearSelected(year, year, year +1)" class="secondary-content"><i
                    class="material-icons"></i>Select
                </a>
              </div>
            </li>

            <li class="collection-item" (click)="newYearSelected(year - 1, year - 1, year)">
              <div>
                <strong class="blue-text"> {{ (year - 1) }} - {{ year }} </strong><a
                  (click)="newYearSelected(year - 1, year - 1, year)" class="secondary-content"><i
                    class="material-icons"></i>Select </a>
              </div>
            </li>
          </div>
        </ul>

        <div class="row">
          <a [ngClass]="'at-btn col s12 btn waves-effect ' + themeColor" (click)="backClicked()"><i
              class="material-icons left ">house</i> Back To Home Page</a>
        </div>
      </div>

      <div *ngIf="step0">
        <div class="row">
          <ul *ngIf="result" class="row collection">
            <li class="collection-item">
              <span class="orange-text text-darken-2" *ngIf="isSpouse">
                <strong> <i class="material-icons left">
                    warning</i>= Missing Client Signature! </strong>
              </span>
              <span class="orange-text text-darken-2" *ngIf="!isSpouse">
                <strong> <i class="material-icons left">
                    warning</i>= Missing Spouse Signature </strong>
              </span>
            </li>
            <li class="collection-item">
              <span class="red-text">
                <strong> <i class="material-icons left">
                    cancel</i>= Missing Signature (Click to sign) </strong>
              </span>
            </li>
            <div class="section"></div>

            <li *ngFor="let e of result" class="collection-item" (click)="selectBD(e.BDNo)">
              <div>
                <span *ngIf="!isSpouse">
                  <!-- If Signature Is Ok And Dont Have a Spouse -->
                  <strong class="green-text" *ngIf="e.BD_Client_SIGOK === 'True' && !e.SpouseAID">
                    <i class="material-icons green-text left">thumb_up</i>
                    <span class="black-text">{{e.BDNo}}</span>
                  </strong>

                  <!-- If Signature Is Ok And Has a Spouse With Signature OK -->
                  <strong class="green-text"
                    *ngIf="e.BD_Client_SIGOK === 'True' && e.SpouseAID && e.BD_Spouse_SIGOK === 'True'">
                    <i class="material-icons green-text left">thumb_up</i>
                    <span class="black-text">{{e.BDNo}}</span>
                  </strong>

                  <!-- If Signature Is Ok And Has a Spouse With Signature False -->
                  <strong class="orange-text text-darken-2"
                    *ngIf="e.BD_Client_SIGOK === 'True' && e.SpouseAID && e.BD_Spouse_SIGOK === 'false'">
                    <i class="material-icons orange-text darken-2 left">warning</i>
                    <span class="black-text">{{e.BDNo}}</span>
                  </strong>

                  <!-- If Signature Is Ok And Has a Spouse With Signature False -->
                  <strong class="red-text" *ngIf="e.BD_Client_SIGOK === 'false'">
                    <i class="material-icons red-text left">cancel</i>
                    <span class="black-text">{{e.BDNo}}</span>
                  </strong>
                </span>

                <span *ngIf="isSpouse">
                  <!-- If Signature Is Ok And Spouse signature ok-->
                  <strong class="green-text" *ngIf="e.BD_Client_SIGOK === 'True' && e.BD_Spouse_SIGOK === 'True'">
                    <i class="material-icons green-text left">thumb_up</i>
                    <span class="black-text">{{e.BDNo}}</span>
                  </strong>

                  <strong class="orange-text text-darken-2"
                    *ngIf="e.BD_Client_SIGOK === 'false' && e.BD_Spouse_SIGOK === 'True'">
                    <i class="material-icons orange-text left darken-2">warning</i>
                    <span class="black-text">{{e.BDNo}}</span>
                  </strong>

                  <!-- If Signature Is Ok And Has a Spouse With Signature False -->
                  <strong class="red-text" *ngIf="e.BD_Spouse_SIGOK === 'false'">
                    <i class="material-icons red-text left">cancel</i>
                    <span class="black-text">{{e.BDNo}}</span>
                  </strong>
                </span>
                <span class="secondary-content black-text">
                  {{e.Month}}/{{e.Year}}
                </span>
              </div>
            </li>
          </ul>

          <div class="section"></div>
          <div class="row">
            <a [ngClass]="'at-btn col s12 btn waves-effect ' + themeColor" (click)="back_0()"><i
                class="material-icons left ">arrow_back_ios_new</i> Back To Previous Page</a>
          </div>
        </div>
      </div>

      <div *ngIf="step1">
        <div class="row">
          <app-entitlement [model]="entitlements"></app-entitlement>
        </div>

        <div class="row">
          <a class="at-btn btn-flat center green lighten-1 waves-effect waves-light col s12 white-text"
            *ngIf="!hasSignedEntitlement" (click)="approveAndSigne()"><i
              class="material-icons right ">border_color</i>Sign Entitlement</a>

          <a [ngClass]="'at-btn col s12 btn waves-effect ' + themeColor" (click)="back0()"><i
              class="material-icons left ">arrow_back_ios</i> Back To My Entitlements</a>
        </div>
      </div>

      <div class="row" *ngIf="step2">
        <div class="row card-panel grey lighten-4 center">
          <div class="row">
            <h6 class="blue-text text-darken-2 center"><strong>{{name}}</strong></h6>
            <canvas></canvas>
          </div>
        </div>

        <div class="section"></div>

        <a class="at-btn btn-flat center green lighten-1 col s12 white-text" (click)="submit()"><i
            class="material-icons left">check</i> Approve And Submit</a>

        <a class="at-btn btn-flat center red lighten-1 col s12 white-text" (click)="clearSignature()"><i
            class="material-icons left">loop</i> Clear Signature</a>

        <a [ngClass]="'at-btn col s12 btn waves-effect ' + themeColor" (click)="back1()"><i
            class="material-icons left">arrow_back_ios</i> Back To My Entitlement </a>

      </div>

      <div id="step3" *ngIf="step3" class="row ">

        <div class="row">
          <div class="row col s12">
            <div class="row card-panel">
              <div class="row center">
                <h5 class="green-text">Thank You!</h5>
                <h6>Your Entitlement was signed and successfully submitted to your case manager. </h6>
                <br />
              </div>
            </div>
          </div>
        </div>
        <div class="section"></div>
        <button class='at-btn col s12 btn waves-effect black' (click)="backClicked()">
          <i class="material-icons left">keyboard_arrow_left</i> Back To Previous Page
        </button>
      </div>
    </div>

    <div *ngIf="!hasEntitlement" class="card-panel blue darken-2 center">
      <h6 class="white-text"> <strong>
          You Dont Have Any Entitlement In The Database.</strong>
      </h6>
    </div>

  </div>
  <div class="col l1"></div>
</div>