import { toast } from 'materialize-css';
import { ElectionService } from 'src/app/services/firebase/election.service';
import { Component, OnInit } from '@angular/core';
import { NominationDataService } from 'src/app/services/firebase/nomination-data.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { NominationService } from 'src/app/services/firebase/nomination.service';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-my-nominations',
  templateUrl: './my-nominations.component.html',
  styleUrls: ['./my-nominations.component.css'],
})

export class MyNominationsComponent implements OnInit {

  constructor(
    private nominationDataService: NominationDataService,
    private nominationService: NominationService,
    private location: Location,
    private electionService: ElectionService,
    private authService: AuthService) { }

  themeColor = environment.appTheme.themeColor;
  userId: any;
  message = '';
  showNominations: boolean;
  nominationID: any;
  nominationDetails: any[] = [];
  candidateDetail: any;
  nominationD: any;
  nominationObject: any;

  ngOnInit() {
    this.userId = this.authService.getGlobalUser().uid;

    this.nominationDataService.getNominationsByNomineeId(this.userId).valueChanges().subscribe((data) => {
      if (data.length > 0) {
        this.showNominations = true;
        data.forEach((nomination) => {
          if (nomination) {
            this.nominationID = nomination;
            this.nominationService.getNominationbyId(this.nominationID.nominationId).valueChanges()
              .subscribe((nom) => {
                if (nom) {
                  this.nominationD = nom;
                  const nominationEndDate = new Date(this.nominationD.endDate);
                  const today = new Date(new Date().toLocaleDateString());
                  if (nominationEndDate < today) {
                    this.nominationID.nominationStatus = 'Expired';
                  } else {
                    this.nominationID = nomination;
                  }
                  this.nominationDetails.push({
                    id: this.nominationID.id,
                    nominationStatus: this.nominationID.nominationStatus,
                    startDate: this.nominationD.startDate,
                    endDate: this.nominationD.endDate,
                    name: this.nominationD.name,
                    numberOfNominees: this.nominationD.numberOfNominees
                  });
                } else {
                  this.message = 'Cant find election detail';
                }
              });
          }
        });
      } else {
        this.showNominations = false;
        this.message = 'No nominations';
      }
    });
  }

  AcceptNomination(id) {
    this.nominationDataService.updateNominationStatus(id, 'Accepted');
    this.nominationD.nominees.push(this.userId);
    this.nominationService.updateNomination(this.nominationD);
    toast({ html: 'Nomination Accepted succesfully!', classes: 'green' });
    this.backClicked();
  }

  RejectNomination(id) {
    this.nominationDataService.updateNominationStatus(id, 'Rejected');
    toast({ html: 'Nomination Rejected!', classes: 'red' });
    this.backClicked();
  }
  
  backClicked() {
    this.location.back();
  }

}

