
<h6 class="center"><strong>For Employers</strong></h6>
<div class="section"></div>

<div class="container">

  <div class="row col s12">
    <div class="col m1 l2 "></div>
      <div class="col s12 m10 l8 ">

  <a [routerLink]="['/skills-databank']">
    <div class="card pink darken-2">
      <div class="card-content white-text center">
        <span class="card-title"><strong><i class="material-icons white-text">search</i></strong></span>
        <h6><strong>Search Skills Databank</strong></h6>
      </div>
    </div>
  </a>

  <a [routerLink]="['/auth/employer-login']">
    <div class="card green darken-2">
      <div class="card-content white-text center">
        <span class="card-title"><strong><i class="material-icons white-text">lock</i></strong></span>
        <h6><strong>Login To Your Account</strong></h6>
      </div>
    </div>
  </a>

  <a [routerLink]="['/create-account']">
    <div class="card blue darken-2">
      <div class="card-content white-text center">
        <span class="card-title"><strong><i class="material-icons white-text">account_box</i></strong></span>
        <h6><strong>Create Account And Start Posting Jobs</strong></h6>
      </div>
    </div>
  </a>

  </div>

    <div class="col m1 l2 "></div>
  </div>

</div>


