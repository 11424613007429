<div class="row center-align p-m-2">
  <h6><strong>My Supplemental Support</strong> </h6>
</div>

<form (ngSubmit)="submitForm(supplementalSupportForm.value)" [formGroup]="supplementalSupportForm">
  <div class="row col s12">
    <div class="col l2"></div>

    <div id="step1" class="col s12 m12 l8">
      <div class="row col s12">
        <div class="card-panel">
          <div class="row">

            <div class="input-field col s12">
              <select id="documentType" formControlName="documentType">
                <option value="" disabled selected>Choose a support type</option>
                <option *ngFor="let t of supportType" [ngValue]="t">{{t}}</option>
              </select>
            </div>

            <div class="row col s12">
              <div class="input-field">
                <div class="col s12"><input formControlName="amount" placeholder="Amount"></div>
              </div>
            </div>

            <div class="row col s12">
              <div class="input-field">
                <div class="col s12" *ngIf="preview">
                  <div class="card-panel"><img class="responsive-img" [src]="preview" alt="Support image"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="section"></div>
    </div>

    <div class="col s12 m12 l8">
      <button class='at-btn col s12 btn waves-effect success' type="submit">
        <i class="material-icons right">edit</i> Update Support Request
      </button>
      <button class='at-btn col s12 btn waves-effect red' type="submit">
        <i class="material-icons right">delete</i> Delete Support Request
      </button>
      <button class='at-btn col s12 btn waves-effect black' (click)="backClicked()">
        <i class="material-icons left">keyboard_arrow_left</i> Back To Monthly Assistance
      </button>
    </div>
    <div class="col l2 "></div>
  </div>
</form>