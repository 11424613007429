import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { toast } from 'materialize-css';
import { JobOfferService } from 'src/app/services/firebase/joboffer.service';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'app-job-update',
  templateUrl: './job-update.component.html',
  styleUrls: ['./job-update.component.css']
})
export class JobUpdateComponent implements OnInit {

  myModel: any;
  id: any;
  valueEmittedFromChildComponent: any;


  constructor( private jobService: JobOfferService, private location: Location, private route: ActivatedRoute) {}

  ngOnInit() {
  
      this.route.paramMap.subscribe(params => {
        this.id = params.get('id');
      });
    
      if (this.id) {  
          this.jobService.getJobOfferByID(this.id).valueChanges().subscribe(x => {
            if (x) {         
              this.myModel = x;
             // console.log(this.myModel);
            }
          });
        }
  }
    
  
  
 
  updateEventHandler(valueEmitted) {
    this.valueEmittedFromChildComponent = valueEmitted;
    if (this.valueEmittedFromChildComponent === true) {
     toast({ html: 'Job Offer Successfully Updated!', classes: 'green' });
      this.backClicked();
    }
  }

  archiveEventHandler(valueEmitted) {
    this.valueEmittedFromChildComponent = valueEmitted;
    if (this.valueEmittedFromChildComponent === true) {
      toast({ html: 'Job Offer Successfully Archived!', classes: 'green' });
      this.backClicked();
    }
  }

  unArchiveEventHandler(valueEmitted) {
    this.valueEmittedFromChildComponent = valueEmitted;
    if (this.valueEmittedFromChildComponent === true) {
      toast({ html: 'Job Offer Successfully Posted again !', classes: 'green' });
      this.backClicked();
    }
  }

  deleteEventHandler(valueEmitted) {
    this.valueEmittedFromChildComponent = valueEmitted;
    if (this.valueEmittedFromChildComponent === true) {
      toast({ html: 'Job Offer Successfully Deleted!', classes: 'green' });
      this.backClicked();
    }
  }


     
  backClicked() {
    this.location.back();
  }


}
