<div class="row center-align">
  <h5 *ngIf="nationOfUSe !== 'Batc'"><strong>Sub Departments List</strong></h5>
  <h5 *ngIf="nationOfUSe === 'Batc'"><strong>Sub Offices List</strong></h5>
</div>

<div class="row col s12">
  <div class="col l1 "></div>
  <div class="col s12 l10">
      <div class="row">
          <div class="col s6 m4 l3">
              <a (click)="backToPrevious($event)">
                  <div class="card orange darken-4 hoverable " style="height: 95px;">
                      <div class="card-content">
                          <div class="row center">
                              <i class="material-icons white-text">house</i><br/>
                              <strong class="white-text">Back</strong>
                          </div>
                      </div>
                  </div>
              </a>
          </div>
          <div class="col s6 m4 l3" *ngFor="let department of model">
              <a [routerLink]="[]" class="" (click)="editSubDepartmentData($event, department.id)">
              <div class="card-panel white darken-3 hoverable" style="height: 95px;">
                  <div class="row center">
                      <u> <strong class="blue-text text-darken-3" style="margin-top: 12px; font-size: 15px;"> {{ department.name }}  </strong></u>
                  </div>
              </div>
              </a>
          </div>
      </div>
  </div>
  <div class="col l1 "></div>
</div>
