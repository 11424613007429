import { Component, OnInit, ViewChild } from '@angular/core';
import { Form, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MemberAccountSettingsService } from 'src/app/services/firebase/memberAccountSettings.service';
import { FireMembersService } from 'src/app/services/firebase/fire-members.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { MediaService } from 'src/app/services/api/media.service';
import { environment } from 'src/environments/environment';
import { CommunityService } from 'src/app/services/api/community.service';
import { AdminPosts } from 'src/app/models/AdminPosts.Model';
import { AdminPostsService } from 'src/app/services/firebase/admin-posts.service';
import { AdminSavedAudiences } from 'src/app/models/AdminSavedAudiences.Model';
import { PostsDisplayComponent } from '../posts-display/posts-display.component';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { Router } from '@angular/router';

declare var M: any;
declare var $: any;

@Component({
  selector: 'app-admin-circle',
  templateUrl: './admin-circle.component.html',
  styleUrls: ['./admin-circle.component.css'],
})

export class AdminCircleComponent implements OnInit {

  @ViewChild(PostsDisplayComponent) child: PostsDisplayComponent;
  AdminNewBroadcast: UntypedFormGroup;
  useAgeRange = false;
  useGender = false;
  useCommunityName = false;

  nationOfUse = environment.firstNation.displayName;
  nationCommunities: any = [];
  everyone = true;
  specificAudience = false;
  saveAudience = false;
  allUnfilteredMembers: any = [];
  currentUser: any;
  id: any;
  adminAccountIds: any = [];

  selectedFilesForDisplay: any = [];
  binDocList: any = [];

  savedAudienceByCurrentAdmin: any = [];
  exsistingAudience = false;
  selectedAudience: any;
  currentNationName = '';
  currentNationLogo: any;

  constructor(private fb: UntypedFormBuilder, private router: Router,
    private communityService: CommunityService,
    private authService: AuthService,
    private memberAccountSettingsService: MemberAccountSettingsService,
    private fireMembersService: FireMembersService,
    private adminPostsService: AdminPostsService,
    private mediaService: MediaService,
    private settingsService: AppSettingsService) { }

  ngOnInit() {
    this.AdminNewBroadcast = this.fb.group({
      message: ['', Validators.required],
      audienceName: [''],
      minAge: ['', Validators.min(0)],
      maxAge: ['',],
      gender: ['',],
      community: ['']
    });

    this.currentNationName = environment.firstNation.displayName;
    this.settingsService.getLandingPageSettings().valueChanges().subscribe(
      lpSettings => {
        if (lpSettings) {
          if (lpSettings.length > 0) {
            this.currentNationLogo = lpSettings[0]['logo'];
          }
        }
      }
    );
    this.currentUser = this.authService.getGlobalUser();
    if (this.currentUser) {
      this.id = this.currentUser.uid;
    }
    if (this.id) {
      this.adminPostsService.getSavedAudienceByAdminId(this.id).valueChanges().subscribe(saved => {
        if (saved) {
          this.savedAudienceByCurrentAdmin = saved;
          $(document).ready(function () {
            $('.tooltipped').tooltip();
            $('.modal').modal();
          });
        }
      });
    }

    this.getAllUsersWithChat();
    $(document).ready(function () {
      $('.materialboxed').materialbox();
      $('.tabs').tabs();
      $('.modal').modal();
      $('.sidenav').sidenav();
      $('.tooltipped').tooltip();
    });
  }

  refresh(): void {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['admin/messages']));
  }

  viewPostsByAudience(audienceId) {
    this.child.viewPostsByAudience(audienceId);
  }

  useAgeRangeSelected() {
    if (this.useAgeRange) {
      this.useAgeRange = false;
      this.AdminNewBroadcast.get('minAge').clearValidators()
      this.AdminNewBroadcast.get('minAge').patchValue('');
      this.AdminNewBroadcast.get('maxAge').clearValidators()
      this.AdminNewBroadcast.get('maxAge').patchValue('');
    }
    else {
      this.useAgeRange = true;
      this.AdminNewBroadcast.get('minAge').setValidators([Validators.required, Validators.min(0)])
      this.AdminNewBroadcast.get('maxAge').setValidators(Validators.required)
      M.updateTextFields();
    }
  }

  useGenderSelected() {
    if (this.useGender) {
      this.useGender = false;
      this.AdminNewBroadcast.get('gender').clearValidators()
      this.AdminNewBroadcast.get('gender').patchValue('');
    }
    else {
      this.useGender = true;
      this.AdminNewBroadcast.get('gender').setValidators(Validators.required)
      M.updateTextFields();
    }
  }

  useCommunityNameSelected() {
    if (this.useCommunityName) {
      this.useCommunityName = false;
      this.AdminNewBroadcast.get('community').clearValidators()
      this.AdminNewBroadcast.get('community').patchValue('');
    }
    else {
      this.AdminNewBroadcast.get('community').setValidators(Validators.required)
      this.useCommunityName = true;
      this.nationCommunities = [];
      setTimeout(() => {
        $('select').formSelect({ dropdownOptions: { belowOrigin: true, constrainWidth: true } });
      }, 2);
      if (this.nationOfUse === 'Batc' || this.nationOfUse === 'Pbcn') {
        this.communityService.getNationCommunities().subscribe(
          com => {
            if (com.length > 0) {
              com.forEach(element => {
                this.nationCommunities.push(element);
                const option = new Option(element.community, element.ID);
                $('select#communityName').append(option)
              });
              $('select').formSelect({ dropdownOptions: { belowOrigin: true, constrainWidth: true } });
            }
          });
      } else if (this.nationOfUse !== 'Batc' && this.nationOfUse !== 'Pbcn') {
        this.communityService.getCommunity().subscribe(
          com => {
            if (com.length > 0) {
              com.forEach(element => {
                this.nationCommunities.push(element);
                const option = new Option(element.community, element.ID);
                $('select#communityName').append(option)
              });
              $('select').formSelect({ dropdownOptions: { belowOrigin: true, constrainWidth: true } });
            }
          });
      }
    }
  }

  allAudiencesSelected() {
    this.everyone = true;
    this.specificAudience = false;
    this.useGender = true; this.useGenderSelected();
    this.useAgeRange = true; this.useAgeRangeSelected();
    this.useCommunityName = true; this.useCommunityNameSelected();
  }

  specificAudienceSelected() {
    this.specificAudience = true;
    this.everyone = false;
  }

  saveAudienceClicked() {
    if (this.saveAudience == false) {
      this.saveAudience = true;
      this.AdminNewBroadcast.get('audienceName').setValidators(Validators.required)
      M.updateTextFields();
    } else {
      this.saveAudience = false;
      this.AdminNewBroadcast.get('audienceName').clearValidators()
      this.AdminNewBroadcast.get('audienceName').patchValue('');
      return;
    }
  }

  getAllUsersWithChat() {
    this.memberAccountSettingsService.getAccountsWithChat()
      .valueChanges().subscribe(async data => {
        if (data) {
          let allUsers = data;
          allUsers.forEach(member => {
            this.fireMembersService.getMemberByID(member.memberId).valueChanges().subscribe(user => {
              if (user) {
                this.allUnfilteredMembers.push(user);
              }
            })
          });
        }
      });

    this.fireMembersService.getAdminAccounts().valueChanges().subscribe(admins => {
      if (admins) {
        admins.forEach(admin => {
          if (admin.udi !== this.id) {
            this.adminAccountIds.push(admin.uid);
          }
        });
      }
    });
  }

  newPostByAdmin(value) {
    let audience;
    let audienceList = this.adminAccountIds;
    if (this.everyone) {
      this.allUnfilteredMembers.forEach(member => {
        audienceList.push(member.uid)
      });
      audience = {
        // no Id since the audience is everyone no audience object created
        id: 'public',
        membersId: audienceList
      }
      this.createPost(audience, value.message)
    } else if (this.specificAudience) {

      this.allUnfilteredMembers.forEach(member => {
        if (Boolean(this.filterStatmentBasedOnSelection(member, value)) == true) {
          audienceList.push(member.uid);
        }
      })

      if (!this.exsistingAudience) {
        let newAudience = new AdminSavedAudiences();
        newAudience.adminId = this.id;
        if (this.useAgeRange) {
          newAudience.age = {
            selected: true,
            min: value.minAge,
            max: value.maxAge
          };
        }
        if (this.useGender) {
          newAudience.gender = {
            selected: true,
            selectedGender: value.gender
          };
        }
        if (this.useCommunityName) {
          newAudience.community = {
            selected: true,
            selectedCommunity: value.gender
          };
        }
        newAudience.postIds = [];
        newAudience.membersId = audienceList;

        if (this.saveAudience) {
          newAudience.saved = true;
          newAudience.audienceTitle = value.audienceName;
          // this.saveAudencesList();
        }
        audience = this.adminPostsService.createAudience(newAudience);
      } else {
        audience = this.selectedAudience;
      }
      this.createPost(audience, value.message);
      this.AdminNewBroadcast.reset();
    }
  }

  createPost(audience, message) {
    if (this.selectedFilesForDisplay.length > 0) {
      this.createMediaPost(audience, message);
      return;
    } else {
      let newPost = new AdminPosts();
      newPost.createdById = this.id;
      newPost.audienceId = audience.id;
      newPost.audienceList = audience.membersId;
      newPost.message = message;
      newPost.mediaLink = '';
      newPost.timeStamp = new Date();
      newPost.reactions = {
        likes: {
          count: 0,
          userId: [],
          usernames: []
        }
      };
      newPost.comments = [];
      this.adminPostsService.createPost(newPost);
      this.allAudiencesSelected();
      this.AdminNewBroadcast.reset();
    }
  }

  createMediaPost(audience, message) {
    if (this.binDocList.length == 1) {
      this.binDocList.forEach(file => {
        if (this.validateInput(file.name) == 'image') {
          let binDoc = file;
          if (binDoc) {
            this.mediaService.UploadBinImage(binDoc).subscribe(
              upload => {
                if (upload) {
                  let newPost = new AdminPosts();
                  newPost.createdById = this.id;
                  newPost.audienceId = audience.id;
                  newPost.audienceList = audience.membersId;;
                  newPost.message = message;
                  newPost.mediaLink = upload.imgLink;
                  newPost.timeStamp = new Date();
                  newPost.reactions = {
                    likes: {
                      count: 0,
                      userId: [],
                      usernames: []
                    }
                  };
                  newPost.comments = [];
                  this.adminPostsService.createPost(newPost);
                }
              }
            );
          }
        }
      })
      this.AdminNewBroadcast.reset();
    }
  }

  filterStatmentBasedOnSelection(user, value) {
    let filterStatment = ``;
    if (this.useAgeRange) {
      let today = new Date();
      let birthDate = new Date(user.DOB);
      let age = today.getFullYear() - birthDate.getFullYear();
      filterStatment = `${value.minAge <= age <= value.maxAge}`;
    }
    if (this.useGender) {
      if (filterStatment.length < 1) {
        filterStatment = `${value.gender == user.Sex}`;
      } else {
        filterStatment += `&& ${value.gender == user.Sex}`;
      }
    }
    if (this.useCommunityName && user.community) {

      if (filterStatment.length < 1) {
        filterStatment = `${value.communityName == user.community}`;
      } else {
        filterStatment += `&& ${value.communityName == user.community}`;
      }
    }
    filterStatment = Function('"use strict";return ' + filterStatment)();
    return filterStatment;
  }
  cancelFile(i: any) {
    this.selectedFilesForDisplay.splice(i, 1);
  }
  closeConfirmModal() {
    $('.modal#confirmBroadcast').modal('close');
  }

  // identify type of input given
  private validateInput(name: any) {
    const ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() === 'png') {
      return 'image';
    } else if (ext.toLowerCase() === 'jpg') {
      return 'image';
    } else if (ext.toLowerCase() === 'jpeg') {
      return 'image';
    } 
    else if (ext.toLowerCase() === 'svg') {
      return 'image';
    }
    else if (ext.toLowerCase() === 'webp') {
      return 'image';
    }else if (ext.toLowerCase() === 'mp4' || ext.toLowerCase() === 'mkv') {
      return 'video';
    } else {
      return false;
    }
  }

  // when file is selected
  onSelectFiles(files: FileList) {
    for (let i = 0; i < files.length; i++) {
      let FileSize = files[i].size / 1024 / 1024; // in MiB
      if (FileSize > 300) {
        M.toast({ html: 'Selected file size exceeds 300 MiB.', classes: 'rounded' });
        return;
      } else {
        this.binDocList.push(files[i])
        if (this.validateInput(files[i].name) == 'image') {
          const reader = new FileReader();
          reader.readAsDataURL(files[i]);
          let img = {
            name: 'image',
            result: null
          }
          reader.onload = () => {
            img.result = reader.result;
            this.selectedFilesForDisplay.push(img);
          }
        } else {
          return;
        }
      }
    }
  }

  createPostWithExsistingAudience(audience) {
    this.exsistingAudience = true;
    this.selectedAudience = audience;
    this.specificAudienceSelected();
    if (audience.age.selected) {
      this.useAgeRange = true;
      this.AdminNewBroadcast.patchValue({
        minAge: audience.age.min,
        maxAge: audience.age.max
      })
    }
    if (audience.gender.selected) {
      this.useGender = true;
      this.AdminNewBroadcast.patchValue({
        gender: audience.gender.selectedGender
      })
    }
    if (audience.community.selected) {
      this.useCommunityName = true;
      this.AdminNewBroadcast.patchValue({
        community: audience.community.selectedCommunity
      })
    }
    $('.modal#NewBroadcast').modal('open');
  }
}