
export class ActionPlanModel {

    ap_id: number;
    client_id:number;  
    lastname: string;
    givenname: string;
    middle_initial: string;  
    dob: string;
    gender = -1;  
    SIN: string;  
    address: string;   
    city: string;   
    postal_code: string;  
    province = -1;     
    telephone: string;    
    marital_Status = -1;

    funding_type = -1;        
    ap_start_date: string;   
    app_status: string;       
    
    disability = -1;   
    //minority = -1;    
    aboriginal_group = -1;   // indian_status
    lan = -1;                

    fax: string;        
    email: string;      
    band_id: string;     
    band_name: string;  
    dependants = -1;          
    child_care_required = -1;   
    child_care_Funded = -1;     

    education = -1;              
    edu_province = -1;      
    sa_status = -1;      
    ei_type = -1;       
    emp_barrier = -1;   
    AP_client_status = -1; 
    NOC: string;   
    goals: string;

    ph_ext: string;    
    office: string;   
   }
  
