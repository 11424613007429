import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { FireMembersService } from 'src/app/services/firebase/fire-members.service';
import { environment } from 'src/environments/environment';
import SignaturePad from 'signature_pad';
import { toast } from 'materialize-css';
import { MediaService } from 'src/app/services/api/media.service';
import { VerficationRequestService } from 'src/app/services/firebase/verfication-request.service';
import { Location } from '@angular/common';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-get-verified',
  templateUrl: './get-verified.component.html',
  styleUrls: ['./get-verified.component.css']
})
export class GetVerifiedComponent implements OnInit {

  nationName = environment.firstNation.displayName;
  nationOfUse = environment.firstNation.name;
  defaultThemeColor = environment.appTheme.themeColor;
  public phoneNumberMask = ['(', /[0-9]/, /[0-9]/, /[0-9]/, ')',
    ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  public dateFormat = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
  public isnMask = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  public treatyNoMask = [/[0-9]/, /[0-9]/, /[0-9]/];
  public isnMask2 = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  public dateMask = [/[0-3]/, /[0-9]/, '/', /[0-1]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  loading = false;
  themeColor: any;
  buttonType: any;
  setting: any;

  id: any;
  member: any;
  imgErrorMsg: any;
  binDoc: any;
  fileName: string;
  preview: any;

  verficationForm: UntypedFormGroup;

  canvas: any;
  signaturePad: SignaturePad;
  signed = false;
  model: any;

  constructor(private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    public settingsService: AppSettingsService,
    private fbMemberService: FireMembersService,
    public authService: AuthService,
    private mediaService: MediaService,
    private verificationService: VerficationRequestService,
    private location: Location) {

    this.settingsService.getAppSettingsList().valueChanges().subscribe(
      appSettings => {
        if (appSettings) {
          if (appSettings.length > 0) {
            this.setting = appSettings[0];
            this.themeColor = this.settingsService.getAppThemeColor() ? this.settingsService.getAppThemeColor() : this.defaultThemeColor;
          }
        }
      });
  }

  ngOnInit(): void {
    this.verficationForm = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      mobile: [''],
      dob: ['', []],
      isn: ['', [Validators.minLength(10), Validators.maxLength(10), Validators.pattern('^[0-9]*$')]],
      imgLink: [''],
      signature: [''],
    });

    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
      if (this.id) {
        this.fbMemberService.getMemberByID(this.id).valueChanges().subscribe(m => {
          if (m) {
            this.member = m;
            if (this.member.GivenName) {
              this.firstName.setValue(this.member.GivenName);
              this.firstName.disable();
            }
            if (this.member.LastName) {
              this.lastName.setValue(this.member.LastName);
              this.lastName.disable();
            }
            if (this.member.Email) {
              this.email.setValue(this.member.Email);
              this.email.disable();
            }
            if (this.member.DOB) this.dob.setValue(this.member.DOB);
          }
        })
      }
    });

    setTimeout(() => {
      M.updateTextFields();
      $('.collapsible').collapsible();
    }, 25);
    this.setupCanvas();
  }

  get firstName() { return this.verficationForm.get('firstName'); }
  get lastName() { return this.verficationForm.get('lastName'); }
  get email() { return this.verficationForm.get('email'); }
  get imgLink() { return this.verficationForm.get('mobile'); }
  get dob() { return this.verficationForm.get('dob'); }
  get isn() { return this.verficationForm.get('isn'); }
  get mobile() { return this.verficationForm.get('mobile'); }
  get signature() { return this.verficationForm.get('signature'); }

  handleFileInput(files: FileList) {
    if (!this.validateImage(files[0].name)) {
      this.imgErrorMsg = 'Invalid File Type, Please Select an Image File';
      this.imgLink.patchValue('');
      return;
    }
    this.binDoc = files[0];
    this.imgErrorMsg = undefined;
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.preview = reader.result as string;
    };
  }

  private validateImage(name: any) {
    const ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() === 'png') {
      return true;
    } else if (ext.toLowerCase() === 'jpg') {
      return true;
    } else if (ext.toLowerCase() === 'jpeg') {
      return true;
    } 
    else if (ext.toLowerCase() === 'svg') {
      return 'image';
    }
    else if (ext.toLowerCase() === 'webp') {
      return 'image';
    }else {
      return false;
    }
  }

  removeFile() {
    this.binDoc = null;
    this.preview = null;
    this.imgLink.patchValue('');
  }

  setupCanvas() {
    setTimeout(() => {
      this.canvas = document.querySelector('canvas');
      const parentWidth = $(this.canvas).parent().outerWidth();
      this.canvas.setAttribute('width', parentWidth);
      this.canvas.setAttribute('height', '250px');
      this.signaturePad = new SignaturePad(this.canvas, {
        backgroundColor: '#eeeeee'
      });
    });
  }

  approveSignature() {
    if (this.signaturePad.isEmpty()) {
      toast({ html: 'Please Sign the Application!', classes: 'red' });
      return;
    } else {
      const dataUrl = this.signaturePad.toDataURL();
      this.signature.patchValue(dataUrl);
      this.signed = true;
      toast({ html: 'Application Signed', classes: 'green' });
    }
  }

  clearSignature() {
    this.signaturePad.clear();
  }

  deleteSignature() {
    this.signature.patchValue('');
    this.signed = false;
    this.setupCanvas();
  }

  submitVerification(values) {
    if (!values.firstName) values.firstName = this.member.GivenName;
    if (!values.lastName) values.lastName = this.member.LastName;
    if (!values.email) values.email = this.member.Email;
    values.uid = this.id;
    values.status = 'Pending';
    values.reqDate = new Date();

    if (this.binDoc) {
      this.mediaService.UploadBinImage(this.binDoc).subscribe(upload => {
        if (upload) {
          values.imgLink = upload.imgLink;
        }
        this.verificationService.saveRequest(values).then(X => {
          toast({ html: 'Verification Request Submitted!', classes: 'green' });
          this.member.verificationStatus = 'Pending';
          this.fbMemberService.updateAccount(this.member);
          this.back();
        });
      });
    } else {
      this.verificationService.saveRequest(values).then(X => {
        toast({ html: 'Verification Request Submitted!', classes: 'green' });
        this.member.verificationStatus = 'Pending';
        this.fbMemberService.updateAccount(this.member);
        this.back();
      });
    }
  }

  back() {
    $('.collapsible').collapsible('close');
    this.location.back();
  }

}
