import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class PoliticalOrganizationService {

  fnListRef: AngularFirestoreCollection<any>;
  fnDocRef: AngularFirestoreDocument<any>;

  constructor(private db: AngularFirestore) { }

  savePoliticalOrganization(model: any) {
      const poModel = {
        id : this.db.createId(),
        PoliticalOrganization: model.PoliticalOrganization,
        Website: model.Website,
      };
      return this.db.collection('PoliticalOrganization').doc(poModel.id).set(poModel);
  }

  getPoliticalOrganizationList(): AngularFirestoreCollection<any> {
    this.fnListRef = this.db.collection('PoliticalOrganization');
    return this.fnListRef;
  }

}