import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { toast } from 'materialize-css';
import { AuthService } from 'src/app/services/firebase/auth.service';

@Component({
  selector: 'app-add-news',
  templateUrl: './add-news.component.html',
  styleUrls: ['./add-news.component.css']
})

export class AddNewsComponent implements OnInit {

  departmentId: any;
  isManager =  false;
  canManageNews = false;

  myModel = {
    id: '',
    departmentID: '',
    type: 'Post',
    title: '',
    body: '',
    imgLink: '',
    videoLink: '',
    visibility: 'Public',
    author: '',
    published: true,
    unPublished: false,
    datePublished: new Date(),
    index: Math.floor(Math.random() * (10000 - 5000 + 1)) + 5000,
  };

  valueEmittedFromChildComponent: any;

  constructor(private authService: AuthService, private router: Router) { }

  ngOnInit() {
    this.departmentId = this.authService.getGlobalUser().departmentId;
    this.isManager = this.authService.getGlobalUser().isManager;
    this.canManageNews = this.authService.getGlobalUser().manageDepartmentNews;
    this.myModel.departmentID = this.departmentId;
  }

  parentEventHandlerFunction(valueEmitted) {
    this.valueEmittedFromChildComponent = valueEmitted;
    if (this.valueEmittedFromChildComponent === true) {
      toast({ html: 'Department News Successfully Posted!', classes: 'green' });
      this.backClicked();
    }
  }

  backClicked() {
    this.router.navigate(['/admin/department-news', this.myModel.departmentID]);
  }
}