import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';


declare var $: any;
declare var M: any;

@Component({
  selector: 'app-optional-landing-page',
  templateUrl: './optional-landing-page.component.html',
  styleUrls: ['./optional-landing-page.component.css']
})
export class OptionalLandingPageComponent implements OnInit {

  iseasyChange = false;
  constructor(
  ) {

  }
  ngOnInit(): void {

    $(document).ready(function(){
      $('.modal').modal();
      $('.parallax').parallax();
    });

  }


}
