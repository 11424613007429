import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, FormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { PollsAndSurveyService } from 'src/app/services/firebase/polls-and-survey.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-poll-detail',
  templateUrl: './poll-detail.component.html',
  styleUrls: ['./poll-detail.component.css']
})

export class PollDetailComponent implements OnInit {

  id = '';
  clientID = '';
  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  model: any;
  surveyForm: UntypedFormGroup;
  answersArray: UntypedFormArray;
  valueEmittedFromChildComponent: any;
  step1 = true;
  step2 = false;

  parentEventHandlerFunction(valueEmitted) {
    this.valueEmittedFromChildComponent = valueEmitted;
    if (this.valueEmittedFromChildComponent === 'saved') {
      this.step1 = false;
      this.step2 = true;
    }
  }

  constructor(private route: ActivatedRoute,
    private surveyService: PollsAndSurveyService,
    private location: Location) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
      this.clientID = localStorage.getItem('applicantID');
    });

    if (this.id) {
      this.surveyService.getPollByID(this.id).valueChanges().subscribe(srv => {
        if (srv) {
          this.model = srv;
        }
      });
    }
  }

  backClicked() {
    this.location.back();
  }

}
