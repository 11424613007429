import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { ManageAdminService } from 'src/app/services/api/manage-admin.service';
import { Location } from '@angular/common';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { EmailService } from 'src/app/services/api/email.service';
import { EmailModel } from 'src/app/models/emailModel';
import { toast } from 'materialize-css';
import { FireMembersService } from 'src/app/services/firebase/fire-members.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { DepartmentService } from 'src/app/services/firebase/department.service';
import { DataService } from 'src/app/services/data.service';

declare var $: any;

@Component({
  selector: 'app-admin-detail',
  templateUrl: './admin-detail.component.html',
  styleUrls: ['./admin-detail.component.css']
})

export class AdminDetailComponent implements OnInit {

  nationOfUse = environment.firstNation.name;
  defaultThemeColor = environment.appTheme.adminThemeColor;
  themeColor: any;

  loggedUser: any;
  nationAdmin: any;
  firebaseAdmin: any;
  id = '';

  defaultMenus: any[];
  menus: any[];
  defaultPermission: any[];
  permissions: any[];

  showDefaultMenu = true;
  isEnabled = false;

  admin: any;
  emailForm: UntypedFormGroup;
  reason = null;
  sendMessage = false;
  contactDetail: any = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNo: '',
  }
  contact = false;
  message: any = {
    title: '',
    subject: '',
    body: '',
    files: []
  }

  menuChecked = new Set();
  allChecked = [];
  expand = false;
  
  constructor(private fb: UntypedFormBuilder,
    private manageAdminService: ManageAdminService,
    private location: Location,
    private route: ActivatedRoute,
    private emailService: EmailService,
    private fireMembersService: FireMembersService,
    private authService: AuthService,
    private router: Router,
    private settingsService: AppSettingsService,
    private dataService: DataService) {

    this.loggedUser = this.authService.getGlobalUser();
    this.themeColor = this.settingsService.getAppThemeColor() ? this.settingsService.getAppThemeColor() : this.defaultThemeColor;

    this.defaultMenus = this.dataService.getAdminMenus();
    this.defaultPermission = [];
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });

    if (this.id) {
      this.manageAdminService.getAdmin(this.id).subscribe(adm => {
        if (adm && adm != 'No Result Found') {
          this.nationAdmin = adm[0];
        }
      });

      //get menus
      this.settingsService.getAdminMenus().valueChanges().subscribe(m => {
        if (m && m.length > 0) {
          this.menus = m;
          this.showDefaultMenu = false;
        } else {
          this.menus = this.defaultMenus;
          this.showDefaultMenu = true
        }
        this.menus = this.menus.sort((a, b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0));
        this.menus = this.menus.filter(m => m.name != 'Logout');
      });

      //get permission
      this.fireMembersService.getMemberByAdminID(Number(this.id)).valueChanges().subscribe(x => {
        if (x && x.length > 0) {
          this.firebaseAdmin = x[0];
          if (this.firebaseAdmin.permissions) this.permissions = this.firebaseAdmin.permissions;
          else this.permissions = this.defaultPermission;

          if (this.firebaseAdmin.status === 'Enabled') { this.isEnabled = true; }
          if (this.permissions.length > 0) this.setPermission();
        }
      });

      this.emailForm = this.fb.group({
        subject: ['', [Validators.required]],
        body: ['', [Validators.required]],
      });

      setTimeout(() => {
        $('.modal').modal();
        $('.collapsible').collapsible();
      }, 25);
    }
  }

  setPermission() {
    for (const item of this.permissions) {
      this.menuChecked.add(item.menu);
      if (item.submenus.length > 0) {
        for (const subitem of item.submenus) {
          this.menuChecked.add(subitem);
        }
        if (item.submenus.every(i => this.menuChecked.has(i))) {
          if (!this.allChecked.includes(item.menu)) this.allChecked.push(item.menu);
        }
      }
    };
  }

  // Permissions
  getPermission(item, sub_item?) {
    if (this.permissions) {
      if (this.permissions.length > 0) {
        let p = this.permissions.find(p => p.menu === item.name);
        if (p) {
          if (sub_item) {
            let sp = p.submenus.includes(sub_item.name);
            if (sp) return true;
          } else return true;
        }
      }
      $(document).ready(function () {
        $('.collapsible').collapsible();
      });
    }
    return false;
  }

  toggleExpand() {
    this.expand = !this.expand;
  }

  menuClicked(item, sub_item) {
    if (sub_item) {
      if (this.menuChecked.has(sub_item.name)) {
        this.removeSubmenuItem(item.name, sub_item.name);
      } else {
        this.addSubmenuItem(item.name, sub_item.name);
      }
    } else {
      if (this.menuChecked.has(item.name)) {
        this.removeMenuItem(item.name);
      } else {
        this.addMenuItem(item.name);
      }
    }
  }

  addMenuItem(item, sub_item?) {
    let submenu = sub_item ? [sub_item] : [];
    this.permissions.push({ menu: item, submenus: submenu });
    this.menuChecked.add(item);
    if (sub_item) this.menuChecked.add(sub_item);
  }

  removeMenuItem(item) {
    let menu = this.permissions.find(p => p.menu == item);
    if (menu.submenus && menu.submenus.length > 0) {
      menu.submenus.forEach(submenu => {
        this.menuChecked.delete(submenu);
      });
    }
    let index = this.permissions.indexOf(menu);
    this.permissions.splice(index, 1);
    this.menuChecked.delete(item);
  }

  addSubmenuItem(item, sub_item) {
    let menu = this.permissions.find(p => p.menu == item);
    if (menu) {
      menu.submenus.push(sub_item);
      this.menuChecked.add(sub_item);
    }
    else this.addMenuItem(item, sub_item);
  }

  removeSubmenuItem(item, sub_item) {
    let menu = this.permissions.find(p => p.menu == item);
    const index = menu.submenus.indexOf(sub_item);
    menu.submenus.splice(index, 1);
    this.menuChecked.delete(sub_item);

    if (menu.submenus.length == 0) {
      this.removeMenuItem(item);
    }
  }

  selectAll(item) {
    if (this.allChecked.includes(item)) {
      this.removeMenuItem(item);
      let i = this.allChecked.indexOf(item);
      this.allChecked.splice(i, 1);
    } else {
      let menu = this.menus.find(menu => menu.name == item);
      if (menu.submenus && menu.submenus.length > 0) {
        menu.submenus.forEach(submenu => {
          this.addSubmenuItem(item, submenu.name);
        });
      } else {
        this.addMenuItem(item);
      }
      this.allChecked.push(item);
    }
  }

  updatePermissions() {
    this.fireMembersService.updatePermission(this.firebaseAdmin.uid, this.permissions);
  }

  get subject() { return this.emailForm.get('subject'); }
  get body() { return this.emailForm.get('body'); }

  onSendMessage() {
    this.sendMessage = !this.sendMessage;
  }

  enableAccount() {
    this.firebaseAdmin.status = 'Enabled';
    this.firebaseAdmin.statusDetails = {
      updateDate: new Date(),
      by: this.loggedUser.FirstName + " " + this.loggedUser.LastName,
      reason: this.reason
    }
    this.fireMembersService.updateAccount(this.firebaseAdmin);
    this.isEnabled = true;
    this.reason = null;

    toast({ html: `${this.firebaseAdmin.Email} Enabled`, classes: 'green rounded' });
    if (this.sendMessage) {
      this.contactDetail.firstName = this.firebaseAdmin.GivenName;
      this.contactDetail.lastName = this.firebaseAdmin.LastName;
      this.contactDetail.email = this.firebaseAdmin.Email;
      this.contactDetail.phoneNo = this.firebaseAdmin.cell_phone_num;
      this.message.subject = 'Account Enabled';
      this.message.title = 'AccountStatus';
      this.contact = true;
    }
  }

  disableAccount() {
    this.firebaseAdmin.status = 'Disabled';
    this.firebaseAdmin.statusDetails = {
      updateDate: new Date(),
      by: this.loggedUser.FirstName + " " + this.loggedUser.LastName,
      reason: this.reason
    }
    this.fireMembersService.updateAccount(this.firebaseAdmin);
    this.isEnabled = false;
    this.reason = null;
    toast({ html: `${this.firebaseAdmin.Email} Disabled`, classes: 'red rounded' });
    if (this.sendMessage) {
      this.contactDetail.firstName = this.firebaseAdmin.GivenName;
      this.contactDetail.lastName = this.firebaseAdmin.LastName;
      this.contactDetail.email = this.firebaseAdmin.Email;
      this.contactDetail.phoneNo = this.firebaseAdmin.cell_phone_num;
      this.message.subject = 'Account Disabled';
      this.message.title = 'AccountStatus';
      this.contact = true;
    }
  }

  Send(email: EmailModel) {
    let msg = new EmailModel();
    msg.emailList = [];
    msg.emailList.push(this.nationAdmin.Email_Address);
    msg.subject = email.subject;
    msg.body = email.body;

    $('.modal').modal('close');
    this.emailService.sendEmail(msg).subscribe(x => {
      toast({ html: 'Email Successfully Sent.', classes: 'green' });
    });
  }

  invite() {
    const email = new EmailModel();
    let url = environment.firstNation.website;
    url += '/auth/admin-registration/' + this.id;
    email.subject = 'Admin Registration Email';
    email.body = '<p> <strong>Hi ' + this.nationAdmin.FirstName + ', </strong></p> ';
    email.body += `<p> Please follow the link below to Register:  <br/>` +
      '<a href="' + url + '" target="_blank"> https://www.mynation.app/auth/demo </a>' +
      ' </p>';
    email.body += '<br/> <br/> <p>Thank You.</p>';
    email.emailList = [];
    if (this.nationAdmin) {
      email.emailList.push(this.nationAdmin.Email_Address);
    } else {
      email.emailList.push(this.firebaseAdmin.Email);
    }
    this.emailService.sendEmail(email).subscribe(
      e => {
        if (e) {
          toast({ html: 'Registration Email Successfully Sent', classes: 'green' });
        }
      }
    );
  }

  adminActivity() {
    this.router.navigate(['admin/dashboard-sub-menu/admin-activity', this.firebaseAdmin.uid]);
  }

  contactHandler(valueEmitted) {
    if (valueEmitted.status) toast({ html: 'Message sent to client Successfully!', classes: 'green' });
    else toast({ html: 'Message Not sent to the client!', classes: 'red' });
  }

  navigate(item) {
    const routeMappings = {
      'Admins': 'admin/dashboard-sub-menu/admins-list',
      'Membership': 'admin/members',
      'Applications': 'admin/dashboard-sub-menu/applications',
      'DemoRequest': 'admin/dashboard-sub-menu/demo-request',
    };

    const route = routeMappings[item];
    if (route) {
      this.router.navigate([route]);
    }
  }

  backClicked() {
    this.location.back();
  }

}
