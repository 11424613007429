import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-social-assistance-menu',
  templateUrl: './social-assistance-menu.component.html',
  styleUrls: ['./social-assistance-menu.component.css']
})
export class SocialAssistanceMenuComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
