<div class="row">
    <div class="container-pi">
        <div class="body-h">
<!-- 
            <div class="header-setting  ">
                <div class="col l12 m12 s12">
                    <h2 class="login-title center bold-900 col s12 m12 l12 title-section2">
                        Get Verified</h2>
                    <h2 class="center bold-700 col s12 m12 l12" style="font-family:'sans-serif'; font-size: 14px;"
                        [ngStyle]="{'color': themeColor}">Be a trusted member of our community - Get Verified Today!
                    </h2>
                </div>
            </div> -->

            <h4 class="title-section1 title-member  icon-texts"><span class="material-symbols-outlined back-icon p-r-1"
                (click)=" back()">
                arrow_circle_left
              </span>Get Verified</h4>
              <span class="bold-700 col s12 m12 l12" style="font-family:'sans-serif'; font-size: 14px;"
              [ngStyle]="{'color': themeColor}">Be a trusted member of our community - Get Verified Today!
            </span >

            <main class="StickyContent white">
                <form [formGroup]="verficationForm" (ngSubmit)="submitVerification(verficationForm.value)"
                    class="col l11 s12 m12">

                    <!--Basic Information-->
                    <ul class="collapsible">
                        <li class="row">
                            <div class="col l12 s12 m12">
                                <div class="collapsible-header">Basic Information</div>
                                <p class="orange-text sub-title-section2">You won't be able to edit your personal
                                    information.If you
                                    want to modify your
                                    personal information edit in your profile section.</p>
                            </div>

                            <div class="collapsible-body" style="height: 35vh;">

                                <div class="input-field col s12 m12 l6">
                                    <div class="label-input">First Name <span class="red-text">*</span></div>
                                    <input id="firstName" type="text" formControlName='firstName'
                                        class="validate white black-text bold-300" autocomplete="off"
                                        style="text-transform: capitalize;" placeholder="First Name" />
                                    <div *ngIf="(firstName.dirty || firstName.touched) && firstName.invalid">
                                        <small *ngIf="firstName.errors.required" class="red-text"> The First Name is
                                            required.
                                        </small>
                                    </div>
                                </div>

                                <div class="input-field col s12 m12 l6">
                                    <div class="label-input">Last Name<span class="red-text">*</span></div>
                                    <input id="lastName" type="text" formControlName='lastName'
                                        class="validate white black-text bold-300" autocomplete="off"
                                        style="text-transform: capitalize;" placeholder="Last Name" />
                                    <div *ngIf="(lastName.dirty || lastName.touched) && lastName.invalid">
                                        <small *ngIf="lastName.errors.required" class="red-text"> The Last Name is
                                            required.
                                        </small>
                                    </div>
                                </div>

                                <div class="input-field col s12 m12 l6">
                                    <div class="label-input">Email<span class="red-text">*</span></div>
                                    <input id="email" type="email" formControlName='email'
                                        class="validate white black-text bold-300 " autocomplete="off"
                                        placeholder="Email" />
                                    <div *ngIf="(email.dirty || email.touched) && email.invalid">
                                        <small *ngIf="email.errors.required" class="red-text">Email is required.
                                        </small>
                                        <small *ngIf="email.errors.email" class="red-text"> Wrong email format. </small>
                                    </div>
                                </div>

                                <div class="input-field col s12 m12 l6">
                                    <div class="label-input">Mobile Number</div>
                                    <!-- <input id="mobile" type="text" formControlName='mobile'
                                        class="validate white black-text bold-300" autocomplete="off"
                                        [textMask]="{mask: phoneNumberMask}" placeholder="Mobile Number" /> -->
                                        <input id="mobile" type="text" formControlName='mobile'
                                        class="validate white black-text bold-300" autocomplete="off"
                                        placeholder="Mobile Number" />
                                </div>

                                <div class="input-field col s12 m12 l6">
                                    <div class="label-input">Date of Birth<span class="red-text"></span></div>
                                    <input id="dob" type="date" formControlName="dob">
                                </div>

                                <div class="input-field col s12 m12 l6">
                                    <div class="label-input">Indian Status Number<span class="red-text"></span></div>
                                    <!-- <input id="isn" type="text" class="validate" formControlName='isn'
                                        [textMask]="{mask: isnMask2, guide: true}" autocomplete="off" /> -->
                                        <input id="isn" type="text" class="validate" formControlName='isn'
                                        autocomplete="off" />
                                    <div *ngIf="(isn.dirty || isn.touched) && isn.invalid">
                                        <small *ngIf="isn.errors.pattern" class="red-text">Invalid ISN Number</small>
                                        <small *ngIf="isn.errors.minlength || isn.errors.maxlength" class="red-text">
                                            The ISN must
                                            be 10 Digits.</small>
                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>

                    <!--Upload Documnet-->
                    <ul class="collapsible">
                        <li class="row">
                            <div class="col l12 s12 m12">
                                <div class="collapsible-header">Upload Image</div>

                                <p class="sub-title-section2">
                                    In order for the administrator to verify your identity and approve your application
                                    quickly,would you like to upload a piece of identification <span
                                        class="bold-600">such as
                                        Driver's,
                                        Fire Arms License, Treaty Card, Passport ...</span>
                                </p>
                            </div>

                            <div class="collapsible-body" style="height: 35vh;">
                                <div class="row uploadImg">

                                    <div class="card col s12 m5 l4 center" (change)="handleFileInput($event.target.files)">
                                        <div class="center-align">
                                            <input type="file" formControlName="imgLink" accept="image/*"
                                                (change)="handleFileInput($event.target.files)" />
                                            <i class="fa fa-cloud-upload p-t-2"></i>
                                            <h4 class="label-input p-b-2  bold-200">
                                                Drop & Drop file here or Click to Upload</h4>
                                        </div>

                                        <p class="red-text" *ngIf="imgErrorMsg">
                                            <strong><small><i class="material-icons">error_outline</i>
                                                    {{imgErrorMsg}}</small></strong>
                                        </p>
                                    </div>

                                    <div class="col s12 m6 push-m1 l6 push-l1 img-wrap" *ngIf="preview">
                                        <a class="delete-link btn-floating waves-effect waves-light right"
                                            (click)="removeFile()">
                                            <i class="material-symbols-outlined">
                                                delete
                                            </i>Delete</a>
                                        <img class="materialboxed responsive-img center" [src]="preview" width="80%"
                                            height="auto" />
                                    </div>

                                </div>
                            </div>
                        </li>
                    </ul>

                    <!--Signature-->
                    <ul class="collapsible">
                        <li class="row">
                            <div class="col l12 s12 m12">
                                <div class="collapsible-header">Signature</div>

                                <p class="sub-title-section2 green-text">
                                    Please sign here and date this document in the designated area
                                </p>
                            </div>

                            <div class="collapsible-body" style="height: 35vh;">
                                <canvas *ngIf="!signed" style="height: 100%; width: 80%;"></canvas>
                                <div class="row col s12" *ngIf="!signed">
                                    <button type="button" class="btn col s5 m4 l3 green white-text" (click)="approveSignature()"
                                        style="margin-bottom: 5px;">Approve
                                        Signature
                                    </button>

                                    <button type="button" class="btn col s5 push-s1 m4 push-m2 push-m2 l3 push-l3 red white-text"
                                        (click)="clearSignature()" style="margin-bottom: 5px;">Clear
                                        Signature</button>
                                </div>
                                <div class="row" *ngIf="signed">
                                    <img class="col s12 m6 left" [src]="signature.value">

                                    <div class="row col s12 m4 push-m1 l4 push-l1">
                                        <div class="col s12">

                                            <p class="col s10 m8 l8 green-text">
                                                Application Signed
                                            </p>
                                            <!-- <i class="material-icons col s2 m4 l4 ">check_circle</i> -->
                                        </div>
                                        <div class="section"></div>
                                        <div class="col s12">
                                        
                                            <button type="button" class="btn col s10 m8 l6 left red white-text" style="margin-top: 10px;"
                                                (click)="deleteSignature()">Delete<i
                                                    class="material-icons">delete</i>
                                            </button>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </li>
                    </ul>

                    <div class="row">
                        <button class="btn col s12 m6 l3 right" type="submit" [disabled]="!verficationForm.valid"
                            [ngStyle]="{'background-color': themeColor}">Submit
                        </button>
                    </div>

                </form>
            </main>
        </div>
    </div>
</div>