import { Component, EventEmitter, Input, OnInit, Output, Renderer2 } from '@angular/core';
import { toast } from 'materialize-css';
import { environment } from 'src/environments/environment';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { FormArray, FormGroup, UntypedFormBuilder, UntypedFormGroup, } from '@angular/forms';
import { Router } from '@angular/router';
import { MediaService } from 'src/app/services/api/media.service';
import { FbNationSettingsService } from 'src/app/services/firebase/fb-nation-settings.service';
import { NationSettingsService } from 'src/app/services/api/nation-settings.service';
import { DataService } from 'src/app/services/data.service';
import { Meta, Title } from '@angular/platform-browser';
import { RoutMetaService } from 'src/app/services/firebase/rout-meta.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { ManageAdminService } from 'src/app/services/api/manage-admin.service';
import { SocialMediaService } from 'src/app/services/firebase/social-media.service';
import { ChatGptService } from 'src/app/services/api/chat-gpt.service';
import { ChatGptResponseModel } from 'src/app/models/ChatGptModel';
import { Console } from 'console';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-app-settings',
  templateUrl: './app-settings.component.html',
  styleUrls: ['./app-settings.component.css']
})

export class AppSettingsComponent implements OnInit {
  defaultThemeColor = environment.appTheme.themeColor;
  defaultBgColor = '#ffffff';
  defaultauthBgColor = '#000';
  defaultdarkColor= "lightMode";
  themeColor: any;
  buttonType: any;
  logoType: any;
  bodyBgColor: any;
  loggedUser: any;
  adminInfo: any;

  authformBgColor: any;
  authformSColor: any;
  sectionName: string;
  appSettings: UntypedFormGroup;
  model: any;
  navbarOptionOn = false;
  directSignupOn = false;
  welcomeSetupOn = false;

  selectedThemeColor: any;
  isSidebarMenu = false;
  isBottomMenu = false;
  isRoundButton = false;
  isRectangularButton = false;
  isCurvedButton = false;

  isLogoOnly = false;
  isNationOnly = false;
  isBothNationLogo = false;

  // ====================================================== welcoming , login and signup ==================
  loginSetting: any;
  signupSetting: any;
  welcomeSetting: any;
  govSetting: any;
  imgLink: any;
  imgErrorMsg: any;

  isExpanded = false;

  logo: any;
  setting: any;
  nationName = environment.firstNation.displayName;
  isNewSetting = true;
  loginNewSetting = true;
  signupNewSetting = true;
  welcomeNewSetting = true;

  logoOption: any;
  defaultlogoType = 'logoOnly';

  isLightMode = false;
  isDarkMode = false;
  screenType:any;
  darkOption:any;
  changeMetaTagSetting :boolean = false;
  metaTagSettings: any;
  defaultSetting = [
    {
      name: 'Welcome',
      displayName: 'Welcome',
      title: 'Welcome to ' + this.nationName + ' Web Application',
      description: '-As a member of ' + this.nationName + ', your info is on our membership list. We will only use the info you enter to match it to our list.<br/><br/>-If the data matches our files then we will send you an invitation to complete your registration.<br/><br/>-Once registered you will have access to your profile, news, events and the ability to communicate with Test Nation departments and leadership.',
      image: ''
    },
    {
      name: 'Login',
      displayName: 'Login Page',
      icon: '',
      title: 'Welcome Back',
      description: 'Our suite of mobile applications have been designed with First Nations in mind to assist First Nations governments, organizations and advocacy groups with digital information management, online communications and community development.',
      image: 'https://api.mysabenefits.ca/Media/Demo/Images/6da6cf35-4671-4b9a-be99-edba6e21f7f4-thumbnail_IMG_0372.jpg',
    },
    {
      name: 'Signup',
      displayName: 'Request To Join',
      icon: '',
      title: 'Lets get you setup!',
      description: 'Our suite of mobile applications have been designed with First Nations in mind to assist First Nations governments, organizations and advocacy groups with digital information management, online communications and community development.',
      image: 'https://api.mysabenefits.ca/Media/Demo/Images/6da6cf35-4671-4b9a-be99-edba6e21f7f4-thumbnail_IMG_0372.jpg',
    }
  ];
  submenus: any[];
  editName = false;
  MetaModel : any;

  contactInfo: FormGroup;
  phoneInputMask;
  // appTitle : any;
  // description : any;   

  // mockAdminInfo = {
  //   uid: 1,
  //   FirstName: 'Mihret',
  //   LastName: 'Tamene',
  //   loginID: 'test1',
  //   password: 'password',
  //   userGroup: 'Socail',
  //   Active: 1,
  //   Email_Address: 'test@gmail.com',
  //   phone: '1234567890',
  //   ph_extension: '123',
  //   Cell: '0987654321',
  //   Title: 'Member',
  //   show_cell: 1,
  //   show_office: 1,
  //   show_title: 1,
  //   show_email: 1,
  //   default_band: 0
  // };

  //messageSignature: any;
  show_cell = false;
  show_office = false;
  show_title = false;
  show_email = false;

  // facebook = false;
  // x = false;
  // instagram = false;
  // youtube = false;
  // linkedin = false;
  // tiktok = false;

  expandSocials = true;
  socialMedias: any;

  contactDetail: any = {
    firstName: 'FirstName',
    lastName: 'LastName',
    email: 'example@email.com',
    phoneNo: '+1234567890',
  }

  phoneNoList = ['+1234567890']
  disabled = true;
  previewToShow = false;
  generateDescription: any;
  generateKeyword:any = [];
  generateHashTag:any=[];
  chatResponse = [];
  selectedResponse: any;
  resLoading: boolean = false;
  maxHashTags = 5;
  maxKeyWords = 5;
  @Input() theInput;
  @Output() onMessageSelect = new EventEmitter();
  metaTag: any;

  constructor(private authService: AuthService,
    public appSettingsService: AppSettingsService,
    private fb: UntypedFormBuilder,
    private router: Router,
    private mediaService: MediaService,
    private settingService: AppSettingsService,
    private nationSettingServicee: FbNationSettingsService,
    private appSettingService: AppSettingsService,
    private chatGptService: ChatGptService,
    private dataService: DataService,
    private nationSettingsService: NationSettingsService,
    private routMeta: RoutMetaService,
    private title: Title,
    private manageAdminService: ManageAdminService,
    private socialMediaService: SocialMediaService,
    private meta: Meta) {

    this.loggedUser = this.authService.getGlobalUser();

    this.appSettingsService.getAppSettingsList().valueChanges().subscribe(
      appSettings => {
        if (appSettings) {
          if (appSettings.length > 0) {
            this.model = appSettings[0];
            this.themeColor = this.appSettingsService.getAppThemeColor() ? this.appSettingsService.getAppThemeColor() : this.defaultThemeColor;
            // this.appTitle = this.appSettingService.appTitle ? this.appSettingService.appTitle : '',
            // this.description = this.appSettingService.description ? this.appSettingService.description: '',
            this.buttonType = this.appSettingsService.getButtonType() ? this.appSettingsService.getButtonType() : 'curvedButton';
            this.bodyBgColor = this.appSettingsService.getBodyBackgroundColor() ? this.appSettingsService.getBodyBackgroundColor() : this.defaultBgColor;
            this.authformBgColor = this.appSettingsService.getAuthBackgroundColor() ? this.appSettingsService.getAuthBackgroundColor() : this.defaultauthBgColor;
            this.authformSColor = this.appSettingsService.getAuthSectionColor() ? this.appSettingsService.getAuthSectionColor() : this.defaultauthBgColor;
          }

          this.appSettings.patchValue({
            appTitle: this.appTitle.value,
            description: this.description.value,
            keyWords : this.model?.keyWords,
            hashTags: this.model?.hashTags

          })
        }
      }
    );

    this.dataService.getAppName().subscribe(appName => {
      if (appName) this.nationName = appName;
      else {
        this.nationSettingsService.getNationSettings().subscribe(sett => {
          if (sett) {
            let settingsModel = sett[0];
            if (settingsModel.band_name) this.nationName = settingsModel.band_name;
          }
        });
      }
    });

    this.appSettings = this.fb.group({
      navbarOption: [false] // Assuming "navbarOption" is a form control for the switch
    });

    this.nationSettingServicee.getsettings().valueChanges().subscribe(setting => {
      if (setting && setting.length > 0) {
        if (setting[0].logo) this.logo = setting[0].logo;
      }
    });
  }

  ngOnInit() {
    // this.changeMetaTagSetting();
    console.log("APP SETTINGS")
    this.getMetaTagSettings();

    this.appSettingService.getAppSetting().valueChanges().subscribe(
      appSettings => {
        if (appSettings && appSettings.length > 0) {
          this.setting = appSettings[0];
          this.logoOption = this.setting.logoOption ? this.setting.logoOption : this.defaultlogoType;
          this.darkOption = this.setting.darkOption ? this.setting.darkOption : this.defaultdarkColor;
          
        } else {
          this.logoOption = this.defaultlogoType;
          this.darkOption = this.defaultdarkColor;
        }


      }
    );

      
      console.log("IT IS HERE 1")
    this.appSettings = this.fb.group({
      themeColor: [''],
      bodyBgColor: [''],
      authformBgColor: [''],
      authformSColor: [''],
      navbarOption: [],
      directSignup: [],
      welcomeSetup: [],
      appTitle: [''],
      description: [''],
      // keyWordQuery:[''],
      // keyWords: this.fb.array([]),
      mobileView: this.fb.group({
        menuPosition: [''],
        numberOfMenus: [],
      }),
      tabletView: this.fb.group({
        menuPosition: [''],
      }),
      desktopView: this.fb.group({
        menuPosition: [''],
        numberOfMenus: [],
      }),
      buttonOption: ['curvedButton'],
      logoOption: [''],
      darkOption: [''],
      keyWords: this.fb.array([]),
      keyWordQuery: [''],
      hashTagsQuery: [''],
      hashTags: this.fb.array([]),
      messageSignature: this.fb.group({
        greeting: [''],
        closing: [''],
        signName: [],
        facebook: [],
        x: [],
        instagram: [],
        youtube: [],
        linkedin: [],
        tiktok: []
      })
    });

    this.socialMediaService.getSocialMedia().valueChanges().subscribe(x => {
      if (x && x.length > 0) {
        this.socialMedias = x[0];
      }
    });
    // this.metaTagForm = this.fb.group({
    //   appTitle; [''],
    //   description:[''],
    //   keyWordQuery:[''],
    //   keyWords: this.fb.array([]);
    // })

    this.appSettingsService.getMetadata().valueChanges().subscribe((meta)=>{
      console.log("Here is the meta",meta)
      if(meta && meta.length > 0){
        this.metaTag = meta[0]
        console.log("Meta", this.metaTag.hashTags, "meta looks like", this.metaTag.keyWords)
        this.generateKeyword = this.metaTag.keyWords;
        this.generateHashTag = this.metaTag.hashTags;
        this.appSettings.setValue({
          keyWordQuery:  this.metaTag?.keyWords ?  this.metaTag.keyWords : [],
          hashTagsQuery:  this.metaTag?.hashTags ?  this.metaTag.hashTags : [],
        })
      }
    })



    this.appSettingsService.getAppSetting().valueChanges().subscribe(appSettings => {
      if (appSettings && appSettings.length > 0) {
        console.log("AppSettings", appSettings);

        this.model = appSettings[0];
        console.log("MODEL", this.model.id);
        this.appSettings.setValue({
          appTitle: this.model.appTitle ? this.model.appTitle : '',
          description: this.model.description ? this.model.description : '',
          keyWords: this.model?.keyWords ? this.model.keyWords : [],
          hashTags: this.model?.hashTags ? this.model.hashTags : [],
          keyWordQuery: this.model?.keyWordQuery ? this.model.keyWordQuery: '',
          hashTagsQuery: this.model?.hashTagsQuery ? this.model.hashTagsQuery : '',

          themeColor: this.model.themeColor ? this.model.themeColor : this.defaultThemeColor,
          bodyBgColor: this.model.bodyBgColor ? this.model.bodyBgColor : this.defaultBgColor,
          authformBgColor: this.model.authformBgColor ? this.model.authformBgColor : this.defaultauthBgColor,
          authformSColor: this.model.authformSColor ? this.model.authformSColor : this.defaultauthBgColor,
          navbarOption: this.model.navbarOption ? this.model.navbarOption : false,
          directSignup: this.model.directSignup ? this.model.directSignup : false,
          welcomeSetup: this.model.welcomeSetup ? this.model.welcomeSetup : false,
          mobileView: this.model.mobileView ? {
            menuPosition: this.model.mobileView.menuPosition ? this.model.mobileView.menuPosition : 'bottom',
            numberOfMenus: this.model.mobileView.numberOfMenus ? this.model.mobileView.numberOfMenus : 5,
          } : { menuPosition: 'bottom', numberOfMenus: 5 },
          tabletView: this.model.tabletView ? {
            menuPosition: this.model.tabletView.menuPosition ? this.model.tabletView.menuPosition : 'left',
          } : { menuPosition: 'left' },
          desktopView: this.model.desktopView ? {
            menuPosition: this.model.desktopView.menuPosition ? this.model.desktopView.menuPosition : 'right',
            numberOfMenus: this.model.desktopView.numberOfMenus ? this.model.desktopView.numberOfMenus : 5,
          } : { menuPosition: 'right', numberOfMenus: 5 },
          buttonOption: this.model.buttonOption ? this.model.buttonOption : 'curvedButton',
          logoOption: this.model.logoOption ? this.model.logoOption : 'logoOnly',
          darkOption: this.model.darkOption ? this.model.darkOption : 'lightMode',
          messageSignature: this.model.messageSignature ? {
            greeting: this.model.messageSignature.greeting ? this.model.messageSignature.greeting : 'Hello,',
            closing: this.model.messageSignature.closing ? this.model.messageSignature.closing : 'Thank You.',
            signName: this.model.messageSignature.signName ? this.model.messageSignature.signName : false,
            facebook: this.model.messageSignature.facebook ? this.model.messageSignature.facebook : false,
            x: this.model.messageSignature.x ? this.model.messageSignature.x : false,
            instagram: this.model.messageSignature.instagram ? this.model.messageSignature.instagram : false,
            youtube: this.model.messageSignature.youtube ? this.model.messageSignature.youtube : false,
            linkedin: this.model.messageSignature.linkedin ? this.model.messageSignature.linkedin : false,
            tiktok: this.model.messageSignature.tiktok ? this.model.messageSignature.tiktok : false,
          } : {
            greeting: 'Hello, ',
            closing: 'Thank You.',
            signName: false,
            // facebook: false,
            // x: false,
            // instagram: false,
            // youtube: false,
            // linkedin: false,
            // tiktok: false
          }
        });

        if (this.model.navbarOption) {
          this.navbarOptionOn = true;
        }

        if (this.model.logoOption) {
          if (this.model.logoOption == 'logoOnly') {
            this.isLogoOnly = true;
          } else if (this.model.logoOption == 'nationOnly') {
            this.isNationOnly = true;
          } else if (this.model.logoOption == 'bothNationLogo') {
            this.isBothNationLogo = true;
          }
        }

        if (this.model.darkOption) {
          if (this.model.darkOption == 'lightMode') {
            this.isLightMode = true;
          } else if (this.model.darkOption == 'darkMode') {
            this.isDarkMode = true;
          }
        }

        setTimeout(() => {
          $('.tabs').tabs();
          $('select').formSelect();
          $('.collapsible').collapsible();
        }, 25);
      } else {
        this.appSettings.setValue({
          themeColor: '#008080',
          authformBgColor: '#fff',
          authformSColor: '#fff',
          navbarOption: false,
          mobileView: {
            menuPosition: 'bottom',
            numberOfMenus: 5,
          },
          tabletView: { menuPosition: 'left' },
          desktopView: {
            menuPosition: 'left',
            numberOfMenus: 5
          },
          buttonOption: 'curvedButton',
          logoOption: 'logoOnly',
          darkOption: 'darkMode'
        });
        this.isRectangularButton = true;
        setTimeout(() => {
          $('.tabs').tabs();
          $('select').formSelect();
        }, 25);
      }
    });

    if (this.loggedUser) {
      this.manageAdminService.getAdmin(this.loggedUser.id).subscribe(adm => {
        if (adm && adm != 'No Result Found') {
          this.adminInfo = adm[0];
          this.show_cell = this.adminInfo.show_cell;
          this.show_email = this.adminInfo.show_email;
          this.show_office = this.adminInfo.show_office;
          this.show_title = this.adminInfo.show_title;
          this
        } else {
          this.adminInfo = undefined;
        }
      });
    }

    this.appSettingsService.getMenuName('Settings').valueChanges().subscribe(menus => {
      if (menus && menus.length > 0) {
        this.submenus = menus[0].submenus;
        let menu = this.submenus.find(m => m.name == 'MenuSetting');
        if (menu) this.sectionName = menu.displayName;
      }
    });

    // ====================================================== welcoming , login and signup ==================
    this.settingService.getSignUpLoginSetting().valueChanges().subscribe(setting => {
      if (setting && setting.length > 0) {

        if (setting.filter(s => s.name == 'Login')[0]) {
          this.loginSetting = setting.filter(s => s.name == 'Login')[0];
          this.loginNewSetting = false;
        } else {
          this.loginSetting = this.defaultSetting.filter(s => s.name == 'Login')[0];
          this.loginNewSetting = true;
        }

        if (setting.filter(s => s.name == 'Signup')[0]) {
          this.signupSetting = setting.filter(s => s.name == 'Signup')[0];
          this.signupNewSetting = false;
        } else {
          this.signupSetting = this.defaultSetting.filter(s => s.name == 'Signup')[0];
          this.signupNewSetting = true;
        }

        if (setting.filter(s => s.name == 'Welcome')[0]) {
          this.welcomeSetting = setting.filter(s => s.name == 'Welcome')[0];
          this.welcomeNewSetting = false;
        } else {
          this.welcomeSetting = this.defaultSetting.filter(s => s.name == 'Welcome')[0];
          this.welcomeNewSetting = true;
        }

      } else {
        this.loginSetting = this.defaultSetting.filter(s => s.name == 'Login')[0];
        this.signupSetting = this.defaultSetting.filter(s => s.name == 'Signup')[0];
        this.welcomeSetting = this.defaultSetting.filter(s => s.name == 'Welcome')[0];
        this.signupNewSetting = true;
        this.loginNewSetting = true;
        this.welcomeNewSetting = true;
      }
      $(document).ready(function () {
        $('.collapsible').collapsible();
        $('.modal').modal();
        $('.tooltipped').tooltip();
      });
    });

    this.contactInfo = this.fb.group({
      phone: [''],
      Cell: [''],
      Title: [''],
      ph_extension: [''],
      Email_Address: [''],
    });
  }

  editNameClicked() {
    this.editName = true;
  }

  selectKeyWord() {
    let wordExists = this.keyWords.value.some(w => w === this.keyWordQuery.value)
    if (wordExists) return
    this.generateKeyword.push(this.keyWordQuery.value)
    this.keyWordQuery.patchValue('')
  }
  selectHashTags() {
    let wordExists = this.hashTags.value.some(w => w === this.hashTagsQuery.value)
    if (wordExists) return
    this.generateHashTag.push(this.hashTagsQuery.value)
    this.hashTagsQuery.patchValue('')
  }

  get appTitle() { return this.appSettings.get('appTitle') };
  get description() { return this.appSettings.get('description') };
  get keyWords() { return this.appSettings.get('keyWords') as FormArray }
  get hashTags() { return this.appSettings.get('hashTags') as FormArray }
  get keyWordQuery() { return this.appSettings.get('keyWordQuery') }
  get hashTagsQuery() { return this.appSettings.get('hashTagsQuery') }

  // get keyWords() { return this.appSettings.get('keyWords') as FormArray }
  // get keyWordQuery() { return this.appSettings.get('keyWordQuery') }
saveName() {
    let index = this.submenus.findIndex(m => m.name == 'MenuSetting');
    this.submenus[index].displayName = this.sectionName;
    this.appSettingsService.UpdateMenu('Settings', this.submenus).then((update) => {
      this.editName = false;
      toast({ html: `${update}`, classes: 'green' });
    }).catch((error) => {
      toast({ html: `${error}`, classes: 'red' });
    });
  }

  roundButtonClicked() {
    this.isRoundButton = true;
    this.isRectangularButton = false;
    this.isCurvedButton = false;
  }

  rectagularButtonClicked() {
    this.isRectangularButton = true;
    this.isRoundButton = false;
    this.isCurvedButton = false;
  }

  curvedButtonClicked() {
    this.isCurvedButton = true;
    this.isRoundButton = false;
    this.isRectangularButton = false;
  }

  logoOnlyClicked() {
    this.logoType = 'logoOnly';
  }

  nationOnlyClicked() {
    this.logoType = 'nationOnly';
  }

  bothNationLogoClicked() {
    this.logoType = 'bothNationLogo';
  }

  themeColorChanged(event) {
    this.selectedThemeColor = event.target.value;
  }

  signupOptionChanged(): void {
    this.directSignupOn = !this.directSignupOn;
    this.SaveSettings(this.appSettings.value);
  }

  welcomeSetupChanged() {
    this.welcomeSetupOn = this.appSettings.get('welcomeSetup')?.value;
    this.SaveSettings(this.appSettings.value);
  }

  navbarOptionChanged() {
    this.navbarOptionOn = !this.navbarOptionOn;
  }

  lightClicked() {
    this.screenType = 'lightMode'
  }

  darkClicked() {
    this.screenType = 'darkMode'
  }

  updateInfoModal() {
    // console.log('update Info opn modal');
    $('.modal').modal();
    $('#updateContactModal').modal('open');
  }

  updateInfo(values) {

  }

  cancel() {
  }

  titleChecked() {
    this.show_title = !this.show_title;
  }
  emailChecked() {
    this.show_email = !this.show_email;
  }
  cellChecked() {
    this.show_cell = !this.show_cell;
  }
  officeChecked() {
    this.show_office = !this.show_office;
  }

  saveTitle() {
    this.model.appTitle = this.appTitle.value;
    this.model.description = this.description.value;
    let message = this.truncateHTML(this.description.value, '300')

    const fetchMetaKeyword = "Based on the description please generate 5 keywords and put them in an array format. The description: " + message
    this.chatGptService.startChat(fetchMetaKeyword).subscribe((res: any) => {
      if (res) {
        let response = JSON.parse(JSON.parse(res));
        this.generateKeyword = response.choices[0].message.content
        if (Array.isArray(this.generateKeyword) && this.generateKeyword.length > 0) {
          this.generateKeyword.forEach((word) => this.keyWords.push(this.fb.control(this.truncateHTML(word, '100'))));
          console.log("the Generated Keyword4", this.generateKeyword)

        } else if (this.isString(this.generateKeyword)) {
          this.generateKeyword = JSON.parse(this.generateKeyword);
          console.log("the Generated Keyword3", this.generateKeyword)

          if (Array.isArray(this.generateKeyword) && this.generateKeyword.length > 0) {
            this.generateKeyword.forEach((word) => this.keyWords.push(this.fb.control(this.truncateHTML(word, '100'))));
            console.log("the Generated Keyword2", this.generateKeyword)

          }
          // this.generateKeyword = this.generateKeyword.split(',').map(tag => tag.trim())
        } else {
          JSON.parse(this.generateKeyword).forEach((word) => this.keyWords.push(this.fb.control(this.truncateHTML(word, '100'))));
          console.log("the Generated Keyword1", this.generateKeyword)
        }
      }
    })
    const fetchMetaInfo = "Based on the sentences in the array below and the description please generate 5 hashtags and put them in an array format. The array: " + message
    this.chatGptService.startChat(fetchMetaInfo).subscribe((res: any) => {
      if (res) {
        let response = JSON.parse(JSON.parse(res));
        this.generateHashTag = response.choices[0].message.content;
        if (Array.isArray(this.generateHashTag) && this.generateHashTag.length > 0) {
          this.generateHashTag.forEach((word) => this.hashTags.push(this.fb.control(this.truncateHTML(word, '100'))));
        } else if (this.isString(this.generateHashTag)) {
          // this.generateHashTag = JSON.parse(this.generateHashTag);

          // var arrayData = this.generateHashTag.split(' ').map(tag => tag.trim())
          // var stringData = arrayData;
          // var joinedString = stringData.join(''); // Join all the strings to form a valid JSON array
          // this.generateHashTag = JSON.parse(joinedString); 
          const arrayData = this.generateHashTag
            .split(' ') // Split by spaces
            .filter(tag => tag.trim() !== '') // Remove empty strings
            .map(tag => this.truncateHTML(tag.trim(), '100')); // Trim each tag
          this.generateHashTag = arrayData; // Assign back as an array 

        }

        else {
          JSON.parse(this.generateHashTag).forEach((word) => this.hashTags.push(this.fb.control(this.truncateHTML(word, '100'))));
        }
      }
    })

    this.appSettingService.updateAppSetting(this.model);

   
  }
  save(){
    this.MetaModel = {
      appTitle: this.appTitle.value,
      description: this.description.value,
      keyWords: this.generateKeyword,
      hashTags: this.generateHashTag,
  
    }
    if (this.model.appTitle) {
      this.appSettingService.saveMetaDataSettings(this.MetaModel);
    } else {
      this.appSettingService.updateMetaDataSettings(this.MetaModel);
    }
    this.appSettingService.updateAppSetting(this.model);

    this.getMetaTagSettings();
  }

  selectResponseChoice(choice, useText: boolean) {
    this.selectedResponse = choice.message.content;
    if (useText) {
      this.selectMessage();
    }
  }

  isString(value: any): boolean {
    return typeof value === 'string';
  }

  selectMessage() {
    let response = new ChatGptResponseModel();
    response.input = this.theInput;
    response.message = this.selectedResponse;

    this.onMessageSelect.emit(response);
  }

  truncateHTML(text: string, limit: string): string{
    const charlimit = +limit;
    if (!text || text.length <= charlimit) {
      console.log("text",text)
      return text?.replace(/<(?:.|\n)*?>/gm, '').replace(/&nbsp;/gm, ' ');
    }
    console.log("Text2",text)
    return text?.replace(/<(?:.|\n)*?>/gm, '').replace(/&nbsp;/gm, ' ').substring(0, charlimit) + '...';

  }

  saveSMS(values) {
    this.appSettingService.updateSignature(this.model.id, values);
    if (this.adminInfo) {
      this.adminInfo.show_cell = this.show_cell;
      this.adminInfo.show_email = this.show_email;
      this.adminInfo.show_office = this.show_office;
      this.adminInfo.show_title = this.show_title;

      this.manageAdminService.UpdateAdminContact(this.adminInfo).subscribe(x => {
        if (x) toast({ html: 'App Settings Successfully Updated!', classes: 'green' });
        $('.collapsible').collapsible('close', 9);
      });
    } else {
      toast({ html: 'App Settings Successfully Updated!', classes: 'green' });
      $('.collapsible').collapsible('close', 9);
    }
  }

  // SaveSettings(modelValues: any) {
  //   if (this.model) {
  //     this.model.themeColor = modelValues.themeColor;
  //     this.model.bodyBgColor = modelValues.bodyBgColor;
  //     this.model.buttonOption = 'curvedButton';
  //     this.model.logoOption = modelValues.logoOption;
  //     this.model.navbarOption = modelValues.navbarOption;
  //     this.model.directSignup = modelValues.directSignup;
  //     this.model.welcomeSetup = modelValues.welcomeSetup;
  //     this.model.mobileView = modelValues.mobileView;
  //     this.model.tabletView = modelValues.tabletView;
  //     this.model.desktopView = modelValues.desktopView;
  //     this.model.authformBgColor = modelValues.authformBgColor;
  //     this.model.authformSColor = modelValues.authformSColor;

  //     this.appSettingsService.updateAppSetting(this.model).then(() => {
  //       this.appSettingsService.setAppThemeColor(this.model.themeColor);
  //       this.appSettingsService.setBodyBackgroundColor(this.model.bodyBgColor);
  //       this.appSettingsService.setLogoType(this.model.logoOption);
  //       this.appSettingsService.setDirectSignUp(this.model.directSignup);
  //       this.appSettingsService.setWelcomeSetup(this.model.welcomeSetup);
  //       this.appSettingsService.setAuthBackgroundColor(this.model.authformBgColor);
  //       this.appSettingsService.setAuthSectionColor(this.model.authformSColor);
  //       toast({ html: 'App Settings Successfully Updated!', classes: 'green' });
  //     });
  //   } else {
  //     this.appSettingsService.saveAppSetting(modelValues).then(() => {
  //       toast({ html: 'App Settings Successfully Saved!', classes: 'green' });
  //     });
  //   }
  // }

  SaveSettings(modelValues: any) {
    if (this.model) {
      this.model.themeColor = modelValues.themeColor;
      this.model.bodyBgColor = modelValues.bodyBgColor;
      this.model.buttonOption = 'curvedButton';
      this.model.logoOption = modelValues.logoOption;
      this.model.navbarOption = modelValues.navbarOption;
      this.model.directSignup = modelValues.directSignup;
      this.model.welcomeSetup = modelValues.welcomeSetup;
      this.model.mobileView = modelValues.mobileView;
      this.model.tabletView = modelValues.tabletView;
      this.model.desktopView = modelValues.desktopView;
      this.model.authformBgColor = modelValues.authformBgColor;
      this.model.authformSColor = modelValues.authformSColor;
      this.model.darkOption = modelValues.darkOption;
      this.model.appTitle = modelValues.appTitle;
      this.model.description = modelValues.description;
      this.model.keyWords = modelValues.keyWords;
      this.model.hashTags = modelValues.hashTags;
      this.model.messageSignature = modelValues.messageSignature;

      this.MetaModel = {
        appTitle: this.appTitle.value,
        description: this.description.value,
      }

      this.appSettingService.saveMetaDataSettings(this.MetaModel);

      this.appSettingsService.updateAppSetting(this.model).then(() => {

        this.appSettingsService.setAppThemeColor(this.model.themeColor);
        this.appSettingService.setAppTitle(this.model.appTitle);
        this.appSettingService.setAppDescription(this.model.description);
        this.appSettingsService.setBodyBackgroundColor(this.model.bodyBgColor);
        this.appSettingsService.setLogoType(this.model.logoOption);
        this.appSettingsService.setDirectSignUp(this.model.directSignup);
        this.appSettingsService.setWelcomeSetup(this.model.welcomeSetup);
        this.appSettingsService.setAuthBackgroundColor(this.model.authformBgColor);
        this.appSettingsService.setAuthSectionColor(this.model.authformSColor);
        this.appSettingsService.setScreenType(this.model.darkOption);
        this.appSettingsService.setAppThemeColor(this.model.themeColor);
        this.appSettingsService.setAppThemeColor(this.model.themeColor);
        this.appSettingsService.updateMetaDataSettings(this.MetaModel);

        toast({ html: 'App Settings Successfully Updated!', classes: 'green' });
      });
    } else {

      this.MetaModel = {
        appTitle: this.appTitle.value,
        description: this.description.value,
      }
      this.appSettingService.updateMetaDataSettings(this.MetaModel);
      this.appSettingsService.saveAppSetting(modelValues).then(() => {
        toast({ html: 'App Settings Successfully Saved!', classes: 'green' });
      });
    }
  }

  // to darken and lighten colors
  shadeColor(originalColor: string, amount) {
    return '#' + originalColor.replace(/^#/, '')
      .replace(/../g, color => ('0' + Math.min(255, Math.max(0, parseInt(color, 16) + amount))
        .toString(16))
        .substr(-2));
  }

  backClicked() {
    this.router.navigate(['/admin/dashboard']);
  }

  // ====================================================== welcoming , login and signup ==================
  handleFileInput(files: FileList, from: string) {
    if (!this.validateImage(files[0].name)) {
      this.imgErrorMsg = 'Invalid File Type, Please Select an Image File';
      this.imgLink = null;
      return;
    }
    if (files) {
      this.mediaService.UploadBinImage(files[0]).subscribe(upload => {
        if (upload) {
          this.imgLink = upload.imgLink;
          if (from == 'welcome') this.welcomeSetting.image = this.imgLink;
          if (from == 'signup') this.signupSetting.image = this.imgLink;
          if (from == 'login') this.loginSetting.image = this.imgLink;
        }
      });
    }
  }

  private validateImage(name: any) {
    const ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() === 'png') {
      return true;
    } else if (ext.toLowerCase() === 'jpg') {
      return true;
    } else if (ext.toLowerCase() === 'jpeg') {
      return true;
    }
    else if (ext.toLowerCase() === 'svg') {
      return true;
    }
    else if (ext.toLowerCase() === 'webp') {
      return true;
    } else {
      return false;
    }
  }

  styleChanged(event, from) {
    switch (from) {
      case 'Welcome Title':
        this.welcomeSetting.title = event;
        break;
      case 'Welcome Description':
        this.welcomeSetting.description = event;
        break;
      case 'Signup Title':
        this.signupSetting.title = event;
        break;
      case 'Signup Description':
        this.signupSetting.description = event;
        break;
      case 'Login Title':
        this.loginSetting.title = event;
        break;
      case 'Login Description':
        this.loginSetting.description = event;
        break;
      default:
    }
  }

  saveWelcomeSettings() {
    if (this.welcomeSetting) {
      if (this.welcomeNewSetting) {
        this.settingService.saveWelcomeModalSettings(this.welcomeSetting).then(x => {
          M.toast({ html: 'Changes Saved Successfully.', classes: 'green' });
          $('.collapsible').collapsible('close', 0);
        });
      } else {
        this.settingService.updateWelcomeModalSettings(this.welcomeSetting).then(x => {
          M.toast({ html: 'Changes Saved Successfully.', classes: 'green' });
          $('.collapsible').collapsible('close', 0);
        });
      }
    }
  }

  saveSignUpSettings() {
    if (this.signupSetting) {
      if (this.signupNewSetting) {
        this.settingService.saveSignUpPageSettings(this.signupSetting).then(x => {
          M.toast({ html: 'Changes Saved Successfully.', classes: 'green' });
          $('.collapsible').collapsible('close', 0);
        });
      } else {
        this.settingService.updateSignUpPageSettings(this.signupSetting).then(x => {
          M.toast({ html: 'Changes Saved Successfully.', classes: 'green' });
          $('.collapsible').collapsible('close', 0);
        });
      }
    }
  }

  toggleExpansion() {
    this.isExpanded = !this.isExpanded;
  }

  editNation(): void {
    this.router.navigateByUrl('/admin/dashboard-sub-menu/nation-settings');
  }

  // getMetaTagSettings() {
  //   this.appSettingService.getMetadata().valueChanges().subscribe((metaTags: any) => {
  //     console.log("MetaTags",metaTags)
  //     this.routMeta.updateTitle(metaTags[0]?.appTitle);
  //     this.routMeta.updateDescription(metaTags[0]?.description);
  //     this.routMeta.updateKeywords(metaTags[0].keyWords ? metaTags[0].keyWords : "");
  //   });
  // }
  getMetaTagSettings() {
    this.appSettingService.getMetadata().valueChanges().subscribe((metaTags: any) => {
      console.log("GET META IT IS HERE---", metaTags);
      let lastIndex = metaTags?.length - 1;
      if (lastIndex || lastIndex === 0) {
        this.metaTagSettings = metaTags?.[lastIndex];
        this.appSettings.get('appTitle').setValue(this.metaTagSettings?.appTitle)
        this.routMeta.updateTitle(this.appSettings.get('appTitle').value);
        this.appSettings.get('description').setValue(this.metaTagSettings?.description);
        this.routMeta.updateDescription(this.appSettings.get('description').value);
        // this.appSettingsForm.get('Keywords').setValue(this.metaTagSettings?.KeyWords);
        this.routMeta.updateKeywords(metaTags[0]?.keyWords);

      }
    });
  }

  removeHashTag(value) {
    this.generateHashTag = this.generateHashTag.filter((val) => val !== value);
  }

  removekeyWord(value){
    this.generateKeyword = this.generateKeyword.filter((val) => val !== value);
  }
  
  saveLogInSettings() {
    if (this.loginSetting) {
      if (this.loginNewSetting) {
        this.settingService.saveLoginPageSettings(this.loginSetting).then(x => {
          M.toast({ html: 'Changes Saved Successfully.', classes: 'green' });
          $('.collapsible').collapsible('close', 1);
        });
      } else {
        this.settingService.updateLoginPageSettings(this.loginSetting).then(x => {
          M.toast({ html: 'Changes Saved Successfully.', classes: 'green' });
          $('.collapsible').collapsible('close', 1);
        });
      }
    }
  }


  toggleExpand() {
    this.expandSocials = !this.expandSocials;
  }
}
