import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-participations',
  templateUrl: './participations.component.html',
  styleUrls: ['./participations.component.css']
})

export class ParticipationsComponent implements OnInit {

  constructor() { }

  ngOnInit() {}
  
}
