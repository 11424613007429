<div class="row" *ngIf="discussion">
  <ul class="row collapsible">
    <li *ngFor="let msg of discussion; let i = index;" [ngClass]="{'active' : i === 0}">
      <div class="collapsible-header white">
        <div class="valign-wrapper col s12" *ngIf="msg.fromID !== senderID">
          <div class="col s2 m1 ">
            <i class="material-icons green-text">sms</i>
          </div>
          <div class="col s10 m11 ">
            <h6><strong><span class="blue-text text-darken-2"> {{msg.fromName}} </span> </strong></h6>
            <strong><span class="black-text text-darken-2 truncate"> {{msg.subject}} </span> </strong>
          </div>

        </div>

        <div class="valign-wrapper col s12" *ngIf="msg.fromID === senderID">
          <div class="col s2 m1">
            <i class="material-icons pink-text text-darken-2">insert_comment</i>
          </div>

          <div class="col s10 m11 ">
            <h6><strong><span class="pink-text text-darken-2"> {{msg.fromName}} </span> </strong></h6>
            <strong>
              <span class="black-text text-darken-2 truncate"> {{msg.subject}} </span>
            </strong>
          </div>
        </div>
      </div>

      <div class="collapsible-body light-green lighten-5">
        <div class="row">
          <p><strong>{{msg.body}}</strong> </p>
          <a (click)="showEmailModal(discussion[0])" *ngIf="msg.fromID !== senderID"
            class="btn-floating btn-small waves-effect waves-light red right"><i class="material-icons">reply</i></a>
        </div>
      </div>
    </li>
  </ul>
</div>

<div id="emailModal" *ngIf="selectedMsg" class="modal">
  <form [formGroup]="contactForm" (ngSubmit)="sendMessage(contactForm.value)">
    <div class="modal-content">
      <div class="row col s12 center">
        <h6><strong> Respond </strong></h6>
        <div class="row">
          <div class="input-field col s12 l12">
            <i class="material-icons prefix pink-text">mail</i>
            <input id="subject" type="text" formControlName='subject' required class="validate" autocomplete="off" />
            <label for="subject">Subject</label>
            <div *ngIf="(subject.dirty || subject.touched) && subject.invalid">
              <small *ngIf="subject.errors.required" class="red-text"> The Subject is required. </small>
            </div>
          </div>

          <div class="input-field col s12 l12">
            <i class="material-icons prefix purple-text">subject</i>
            <textarea id="body" type="text" formControlName='body' required autocomplete="off"
              class="validate materialize-textarea" style="height: 200px;" maxlength="1000"> </textarea>
            <label for="body">Body</label>
            <div *ngIf="(body.dirty || body.touched) && body.invalid">
              <small *ngIf="body.errors.required" class="red-text"> The Body Is Required.
              </small>
            </div>
          </div>
        </div>
      </div>
    </div>
    
    <div class="modal-footer">
      <a class="modal-close waves-effect waves-green btn red">
        <i class="material-icons right">delete</i>
        Close</a>
      <button type="submit" [disabled]="!contactForm.valid" style="margin-left: 5px;" class="btn green">
        <i class="material-icons right">send</i>
        Send</button>
    </div>
  </form>
</div>