import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { toast } from 'materialize-css';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-employer-login',
  templateUrl: './employer-login.component.html',
  styleUrls: ['./employer-login.component.css']
})
export class EmployerLoginComponent implements OnInit {

  themeColor = environment.appTheme.themeColor;
  loginForm: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder, private route: ActivatedRoute, private router: Router, private authService: AuthService) { }

  ngOnInit() {
    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });
  }

  get email() { return this.loginForm.get('email'); }
  get password() { return this.loginForm.get('password'); }

  login(data: any) {
    this.authService.emailLogin(data.value.email, data.value.password).then(x => {
      toast({ html: 'Authenticated Successfully !', classes: 'green' });
      this.router.navigate(['/employer/home']);
    }).catch(error => toast({ html: error.message, classes: 'red' }));
  }
}
