import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-election-result',
  templateUrl: './election-result.component.html',
  styleUrls: ['./election-result.component.css']
})

export class ElectionResultComponent implements OnInit {

  themeColor = environment.appTheme.themeColor;
  constructor(private location: Location) { }

  ngOnInit() {
  }
  
  backClicked() {
    this.location.back();
  }
}
