
<br/>
<div class="row center-align">
  <h6> <strong> My Contracts List </strong></h6>
</div>

<div class="row col s12" *ngIf="myModel">
  <div class="col l1"></div>
      <div class="col s12 l10 "> 
        <app-job-posting [call_from]="'employer'" [model]="myModel" [actionType]="'Edit'"
        (jobUpdated)="updateEventHandler($event)" (jobArchived)="archiveEventHandler($event)"
        (jobUnArchived)="unArchiveEventHandler($event)" (jobDeleted)="deleteEventHandler($event)">
       </app-job-posting>  
      </div>
  <div class="col l1 "></div>
</div>

