

import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiKeyManagerService } from './apiKeyManager.Service';
import { ObjectiveModel } from 'src/app/models/ObjectiveModel';



@Injectable({
    providedIn: 'root'
  })
  export class ObjectiveService {
  
    constructor(private httpClient: HttpClient, private keyManager: ApiKeyManagerService) { }
  
    url = environment.firstNation.apiUrl + 'api/Objective/';
  
    saveObjective(model: ObjectiveModel): Observable<any> {
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
      return this.httpClient.post<ObjectiveModel>(this.url + 'SaveObjective/', JSON.stringify(model), { headers });
    }
  
    updateObjective(model: ObjectiveModel): Observable<any> {
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
      return this.httpClient.post<ObjectiveModel>(this.url + 'EditObjective/', JSON.stringify(model), { headers });
    }
  
  
    deleteObjective(model: ObjectiveModel): Observable<any> {
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
      return this.httpClient.post<ObjectiveModel>(this.url + 'DeleteObjective/', JSON.stringify(model), { headers });
    }
  
  
    getObjectiveById(id: number): Observable<ObjectiveModel[]> {
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
      return this.httpClient.get<ObjectiveModel[]>(this.url + 'GetObjectiveByID/' + id, { headers });
    }
  

    getObjectiveByMemberId(id: number): Observable<ObjectiveModel[]> {
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
      return this.httpClient.get<ObjectiveModel[]>(this.url + 'GetObjectiveByMemberId/' + id, { headers });
    }
  
  
  
  
  }