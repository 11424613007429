import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { toast } from 'materialize-css';
import { Location } from '@angular/common';

@Component({
  selector: 'app-documents-upload',
  templateUrl: './documents-upload.component.html',
  styleUrls: ['./documents-upload.component.css']
})

export class DocumentsUploadComponent implements OnInit {

  applicantID: any;
  valueEmittedFromChildComponent: any;
  callerName = 'upload_document';
  isCordova = false;
  parentEventHandlerFunction(valueEmitted) {
    this.valueEmittedFromChildComponent = valueEmitted;
    if (this.valueEmittedFromChildComponent === 'saved') {
      toast({ html: 'Document Successfully Submitted!', classes: 'green' });
      this.backClicked();
    }
  }

  constructor(private authService: AuthService, private location: Location) {
    if (typeof window['cordova'] !== 'undefined') {
      this.isCordova = true;
    }
   }

  ngOnInit() {
    if (localStorage.getItem('applicantID')) {
      this.applicantID = Number(localStorage.getItem('applicantID'));
    } else {
      this.applicantID = this.authService.getCurrentApplicantID();
    }
  }

  backClicked() {
    this.location.back();
  }

}
