import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class CreateCustomApplicationService {

  constructor(private db: AngularFirestore) { }

  applicationListRef: AngularFirestoreCollection<any>;
  applicationRefs: AngularFirestoreDocument<any>;

  saveApplication(model:any) {
    const newApplication = {
      id: this.db.createId(),
      appName: model.appName,
      appDescription: model.appDescription,
      logo: model.logo,
      sections: model.sections
    }
    return this.db.collection('CreatedApplications').doc(newApplication.id).set(newApplication);
  }

  getCustomApplicationByName(appName: string){
    return this.db.collection('CreatedApplications', ref => ref.where('appName','==', appName));
  }

  getCustomeApplicationList(): AngularFirestoreCollection<any[]> {
   this.applicationListRef = this.db.collection('CreatedApplications');
    return this.applicationListRef;
  }

  createApplication(model:any) {
    const application = {
      id: this.db.createId(),
      name: model.name,
      description: model.description,
      department: model.department,
      logo: model.logo,
      sections: model.sections
    }
    console.log(application);
    return this.db.collection('Applications').doc(application.id).set(application);
  }

  getApplication(name: string){
    return this.db.collection('Applications', ref => ref.where('name','==', name));
  }
}