<div class="row">
    <!-- =========== Left Navigation ============= -->
    <div class="col s12 m2 l1" *ngIf="adminMenus">
        <div class="small-side-nav hide-on-med-and-down center" [ngStyle]="{'background-color': themeColor }">
            <div class="">
                <a (click)="backClicked()"><i class=" material-symbols-outlined">
                        home</i>
                    <p>Dashboard</p>
                </a>
                <div *ngFor="let item of adminMenus;let i=index">
                    <a *ngIf="item.isEnabled && !(item.name == 'Communication' || item.name == 'Logout')"
                        (click)="menuClicked(item)"
                        [ngClass]="{'disabledDiv modal-trigger': !getPermission(item.name), 'active': item.name == from}"
                        data-target="permissionModal"> <i class="material-symbols-outlined">{{item.icon}}</i>
                        <p>{{item.displayName}}</p>
                        <!-- <p>{{item.name}} {{from}}</p> -->
                    </a>
                </div>
                <div *ngFor="let item of customMenus;let i=index">
                    <a *ngIf="item.isEnabled"
                        (click)="menuClicked(item)"
                        [ngClass]="{'disabledDiv modal-trigger': !getPermission(item.name), 'active': item.name == from}"
                        data-target="permissionModal"> <i class="material-symbols-outlined">{{item.icon}}</i>
                        <p>{{item.displayName}}</p>
                        <!-- <p>{{item.name}} {{from}}</p> -->
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- =========== Modals ============= -->
<div id="permissionModal" class="modal center b-n-1">
    <div class="modal-content">
        <i class="material-icons right modal-close">close</i><br />
        <h6 class="title-section2 bold-600 black-text">Unauthorized</h6>
        <p class="sub-title-section bold-100">Sorry, but you don't have permission to access this page.</p>
        <img src="https://img.icons8.com/dotty/344/id-not-verified.png" height="150px" />
        <p class="sub-title-section bold-100">Please Contanct your Administrator to gain access.</p>
    </div>
</div>