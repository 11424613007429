import { Component, OnInit } from '@angular/core';
import { trigger, transition, useAnimation } from '@angular/animations';
import { bounceIn } from 'ng-animate';
import { Location } from '@angular/common';
import { SocialMediaService } from 'src/app/services/firebase/social-media.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-home-sub-menu',
  templateUrl: './home-sub-menu.component.html',
  styleUrls: ['./home-sub-menu.component.css'],
  animations: [
    trigger('swing', [transition('* => *', useAnimation(bounceIn))])
  ],
})
export class HomeSubMenuComponent implements OnInit {

  EmployersMenu = false;
  NewsEventMenu = false;
  socialNetworkMenu = false;
  termsAndPolicy = false;
  contactUs = false;
  socialMedia = {
    facebook: '',
    twitter: '',
    instagram: '',
    youtube: ''
  };
title: any;


  constructor(private location: Location, private service: SocialMediaService,  private router: Router) { }

  ngOnInit() {
    if (localStorage.getItem('FromPath')) {
      if (localStorage.getItem('FromPath') === 'Employers') {this.EmployersMenu = true; }

     // if (localStorage.getItem('FromPath') === 'NewsEvents') {this.NewsEventMenu = true; tit }

      if (localStorage.getItem('FromPath') === 'SocialNetwork') {
        this.socialNetworkMenu = true;
        this.title = 'Social Media';
        this.service.getSocialMedia().valueChanges().subscribe(x => {
          if (x) {
            if (x.length > 0) {
              this.socialMedia = {
                facebook: x[0].facebook,
                twitter: x[0].twitter,
                instagram: x[0].instagram,
                youtube: x[0].youtube
              };
            }
          }
        });

      }

      if (localStorage.getItem('FromPath') === 'TermsAndPolicy') {this.termsAndPolicy = true; this.title = 'Terms And Policy'; }
      if (localStorage.getItem('FromPath') === 'ContactUs') {this.contactUs = true; }
    }

    console.log(localStorage.getItem('FromPath'));

  }


  backClicked() {
    this.router.navigate(['/home']);
  }

}
