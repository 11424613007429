import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HousingModel, PropertyFilterModel, OwnerModel, HouseFilterModel } from 'src/app/models/HousingModel';
import { environment } from 'src/environments/environment';
import { ApiKeyManagerService } from './apiKeyManager.Service';

@Injectable({
  providedIn: 'root'
})
export class HousingService {

  constructor(private httpClient: HttpClient, private keyManager: ApiKeyManagerService) { }

  getMember(id: number): Observable<any> {
    const url = environment.firstNation.apiUrl + 'api/members/Get/' + id;
    //console.log(url);
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
    return this.httpClient.get<any>(url, { headers });
  }
  
  getProperties(): Observable<any[]> {
    const url = environment.firstNation.apiUrl + 'api/housing/GetProperties';
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
    return this.httpClient.get<any>(url, { headers });
  }

  getHouses(): Observable<any[]> {
    const url = environment.firstNation.apiUrl + 'api/housing/Get';
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
    return this.httpClient.get<any>(url, { headers });
  }

  getProperty(id: number): Observable<any> {
    const url = environment.firstNation.apiUrl + 'api/housing/GetProperty/' + id;
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
    return this.httpClient.get<any>(url, { headers });
  }

  getHouse(id: number): Observable<any> {
    const url = environment.firstNation.apiUrl + 'api/housing/Get/' + id;
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
    return this.httpClient.get<any>(url, { headers });
  }

  getPropertyByOwnerID(id: number): Observable<any> {
    const url = environment.firstNation.apiUrl + 'api/housing/GetPropertyByOwnerId/' + id;
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
    return this.httpClient.get<any>(url, { headers });
  }

  getHouseByOwnerID(id: number): Observable<any> {
    const url = environment.firstNation.apiUrl + 'api/housing/GetByOwnerId/' + id;
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
    return this.httpClient.get<any>(url, { headers });
  }

  getPropertyByHouseNumber(houseNumber: string):Observable<any>{
    const model = {
      houseNo: houseNumber
    }
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
    return this.httpClient.post<any>
    (environment.firstNation.apiUrl + 'api/housing/GetProprtyByHouseNo', JSON.stringify(model) , { headers });
  }

  getHouseByHouseNumber(houseNumber: string):Observable<any>{
    const model = {
      houseNo: houseNumber
    }
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
    return this.httpClient.post<any>
    (environment.firstNation.apiUrl + 'api/housing/GetByHouseNo', JSON.stringify(model) , { headers });
  }

  hasHouse(id: number): Observable<any> {
    const url = environment.firstNation.apiUrl + 'api/housing/HasHouse/' + id;
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
    return this.httpClient.get<any>(url, { headers });
  }

  houseNoExists(houseNumber: string):Observable<any>{
    const model = {
      houseNo: houseNumber
    }
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
    return this.httpClient.post<any>
    (environment.firstNation.apiUrl + 'api/housing/HouseNoExists', JSON.stringify(model) , { headers });
  }
  
  FilterProperties(model: HouseFilterModel): Observable<HousingModel[]> {
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
    return this.httpClient.post<any>
    (environment.firstNation.apiUrl + 'api/housing/FilterProperties/', JSON.stringify(model) , { headers });
  }

  saveProprty(model: any): Observable<any> {
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
    return this.httpClient.post<any>(
      environment.firstNation.apiUrl + 'api/housing/SaveProperty', JSON.stringify(model), { headers });
  }

  saveHouse(model: any): Observable<any> {
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
    return this.httpClient.post<any>(
      environment.firstNation.apiUrl + 'api/housing/SaveHouse', JSON.stringify(model), { headers });
  }

  updateProperty(model:any): Observable<any> {
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
    return this.httpClient.post<any>(
      environment.firstNation.apiUrl + 'api/housing/UpdateProperty', JSON.stringify(model), { headers });
  }
  
  UpdateOwner(model: OwnerModel): Observable<any>{
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
    return this.httpClient.post<any>(
      environment.firstNation.apiUrl + 'api/housing/UpdateOwner', JSON.stringify(model), { headers });
  }

  RemoveOwner(id: number): Observable<any>{
    const url = environment.firstNation.apiUrl + 'api/housing/RemoveOwner/' + id;
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
    return this.httpClient.get<any>(url, { headers });
  }
  
  deleteHouse(id: number):Observable<any>{
    const url = environment.firstNation.apiUrl + 'api/housing/DeleteHouse/' + id;
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
    return this.httpClient.get<any>(url, { headers });
  }

  getOccupants(id: number): Observable<any[]>{
    const url = environment.firstNation.apiUrl + 'api/housing/getOccupants/' + id;
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
    return this.httpClient.get<any>(url, { headers });
  }
  
}
