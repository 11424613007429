import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Injectable } from '@angular/core';
import { FCMTokenModel } from 'src/app/models/FCMTokenModel';

@Injectable({
  providedIn: 'root'
})
export class FCMTokenService {

  tokenListRef: AngularFirestoreCollection<any>;
  tokenRef: AngularFirestoreDocument<any>;

  constructor(private db: AngularFirestore) { }

  saveUserToken(model: FCMTokenModel) {
    model.uid = this.db.createId();
    this.db.collection('FCMTokens').doc(model.uid).set(model);
  } 

  getTokenList(): AngularFirestoreCollection<FCMTokenModel> {
    this.tokenListRef = this.db.collection('FCMTokens');
    return this.tokenListRef;
  }

  getTokenByMemberID(id: string): AngularFirestoreCollection<FCMTokenModel> {
    this.tokenListRef = this.db.collection('FCMTokens', ref => ref.where('memberID', '==', id));
    return this.tokenListRef;
  }

  checkIfTokenExist(token: string) {
    this.tokenListRef = this.db.collection('FCMTokens', ref => ref.where('token', '==', token));
    return this.tokenListRef;

    // return this.tokenListRef.snapshotChanges().pipe(
    //   map(actions => {
    //     console.log(actions);
    //     return actions.map(action => {
    //       const data = action.payload.doc.data() as FCMTokenModel;
    //       console.log('Data : ' + data);
    //       return data;
    //     });
    //   })
    // );
  }

  updateToken(uid:string,token:string){
    return this.db.collection('FCMTokens').doc(uid).update({token})
  }

}