import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { Location } from '@angular/common';
import { EducationService } from 'src/app/services/api/education.service';
import { EducationService as EducationFirebaseService } from 'src/app/services/firebase/education.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-education',
  templateUrl: './education.component.html',
  styleUrls: ['./education.component.css']
})

export class EducationComponent implements OnInit {

  constructor(private location: Location, private authService: AuthService, private educationService: EducationService,
    private educationFirebaseService: EducationFirebaseService, private router: Router) {
    localStorage.setItem('from', 'PersonalInfo');
  }

  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  sqldb = environment.firstNation.SQLDB;
  nation = environment.firstNation.displayName;
  applicantID = 0;
  model: any[];
  hasRows = false;

  ngOnInit() {
    if (localStorage.getItem('applicantID')) {
      this.applicantID = Number(localStorage.getItem('applicantID'));
    } else {
      this.applicantID = this.authService.getCurrentApplicantID();
    }

    localStorage.setItem('education_call_from', 'education');

    if (this.sqldb) {
      this.educationService.getEducationByMemberId(this.applicantID).subscribe(x => {
        if (x) {
          this.model = x;
          if (this.model.length > 0) { this.hasRows = true; }
        }
      });
    } else {
      this.educationFirebaseService.getEducationByMemberId(this.applicantID).valueChanges().subscribe(x => {
        if (x) {
          this.model = x;
          if (this.model.length > 0) { this.hasRows = true; }
        }
      });
    }
  }

  backClicked() {
    this.location.back();
  }

}