import { Component, Input, OnInit, SimpleChange } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { Observable } from 'rxjs';
import { RegistrationRequestService } from 'src/app/services/api/registration-request.service';
import { RequesttojoinService } from 'src/app/services/firebase/requesttojoin.service';
import { OtherRolesService } from 'src/app/services/firebase/other-roles.service';
import { MemberService } from 'src/app/services/api/member.service';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';

declare var cordova: any;
declare var window: any;

@Component({
  selector: 'app-registration-request',
  templateUrl: './registration-request.component.html',
  styleUrls: ['./registration-request.component.css']
})
export class RegistrationRequestComponent implements OnInit {

  @Input() filters: Observable<any>;
  @Input() menuClicked: boolean;

  defaultThemeColor = environment.appTheme.themeColor;
  themeColor: any;
  buttonType: any;
  fontColor = environment.appTheme.adminFontColor;
  SQLDB = environment.firstNation.SQLDB;
  loading = false;
  registrationRequests: any[];
  invitations: any[];
  requests: any[];
  dtOptions: any;

  isMobile = false;
  tempUrl: null;
  permFolder: null;
  oldFile: null;
  permFile: null;
  reqFiters = {
    pending: false,
    invited: false,
    declined: false,
  }
  showDetail = false;
  id: any;
  showInvited = false;
  invitedId: any;
  showInvitedDetail = false;

  constructor(private requesttojoinService: RequesttojoinService,
    private sqlRegistrationRequest: RegistrationRequestService,
    private location: Location,
    private memberService: MemberService,
    private settingsService: AppSettingsService
  ) {
    this.themeColor = this.settingsService.getAppThemeColor() ? this.settingsService.getAppThemeColor() : this.defaultThemeColor;
    this.buttonType = this.settingsService.getButtonType() ? this.settingsService.getButtonType() : 'rectangularButton';
    if (typeof window['cordova'] !== 'undefined') {
      this.isMobile = true;
    }
    localStorage.setItem('from', 'ManageApp');
  }

  ngOnInit() {
    this.loading = true;
    if (this.SQLDB === true) {
      this.sqlRegistrationRequest.getRegistrationRequests().subscribe(x => {
        if (Array.isArray(x) && x) {
          this.registrationRequests = x;
          if (this.registrationRequests && this.registrationRequests.length > 0) {
            this.registrationRequests = this.registrationRequests.sort((a, b) => {
              const textA = a.Lname;
              const textB = b.Lname;
              this.dtOptions = {};
              this.dtOptions = {
                pagingType: 'simple_numbers',
                pageLength: 15,
                dom: 'Bfrtip',
                // Configure the buttons
                buttons: []
              };
              this.loading = false;
              return (textA > textB) ? -1 : (textA < textB) ? 1 : 0;
            });

            this.requests = this.registrationRequests;
            this.filterBy();
          }
        } else {
          this.registrationRequests = [];
        }
        this.loading = false;
      });
    }

    if (this.SQLDB === false) {
      this.requesttojoinService.getRegistrationRequestList().valueChanges().subscribe(x => {
        if (x && x.length > 0) {
          this.registrationRequests = x.filter(data => !data.archived);
          if (this.registrationRequests) this.registrationRequests = this.registrationRequests.sort((a, b) => {
            const textA = a.requestDate;
            const textB = b.requestDate;
            this.dtOptions = {};
            this.dtOptions = {
              pagingType: 'simple_numbers',
              pageLength: 15,
              dom: 'Bfrtip',
              // Configure the buttons
              buttons: [
                'copy',
                'print',
                'csv',
                'excel'
              ]
            };
            this.loading = false;
            return (textA > textB) ? -1 : (textA < textB) ? 1 : 0;
          });
          this.loading = false;
        }
      });
    }

    this.memberService.getInvitedMembers().subscribe(x => {
      if (x) {
        this.invitations = x;
        this.dtOptions = {};
        this.dtOptions = {
          pagingType: 'simple_numbers',
          pageLength: 15,
          dom: 'Bfrtip',
          // Configure the buttons
          buttons: []
        };
      }
    });
  }

  ngOnChanges(changes: SimpleChange) {
    if (this.showDetail) this.showDetail = false;
    let change: SimpleChange = changes['filters'];
    if (change) this.reqFiters = change.currentValue;
    this.filterBy();
  }

  filterBy() {
    let filteredRequest = [];
    if (this.reqFiters.invited) {
      if (this.invitations) {
        this.showInvited = true;
      }
    } else {
      this.showInvited = false;
      if (this.registrationRequests) {
        let filtered;
        if (this.reqFiters.pending) filtered = this.registrationRequests.filter(req => !req.status || (req.status && req.status == 1));
        if (this.reqFiters.declined) filtered = this.registrationRequests.filter(req => req.status && req.status == 3);
        for (var r of filtered) {
          filteredRequest.push(r);
        }
      }
      this.requests = filteredRequest;
    }
  }

  showDetails(id) {
    this.id = id;
    this.showDetail = true;
  }

  generateCsv() {
    let csv = ""
    let items = this.registrationRequests
    for (let row = 0; row < items.length; row++) {
      let keysAmount = Object.keys(items[0]).length
      let keysCounter = 0
      if (row === 0) {
        for (let key in items[row]) {
          csv += key + (keysCounter + 1 < keysAmount ? ',' : '\r\n')
          keysCounter++
        }
      }
      else {
        for (let key in items[row]) {
          csv += items[row][key] + (keysCounter + 1 < keysAmount ? ',' : '\r\n')
          keysCounter++
        }
      }
      keysCounter = 0
    }
    return csv
  }

  mobileCsv() {
    let path = cordova.file.externalApplicationStorageDirectory
    window.resolveLocalFileSystemURL(path,
      dirEntry => {

        dirEntry.getFile("myfile.csv", { create: true }, file => {
          alert("got the file" + file);
          let csv = this.generateCsv()
          alert("csvcc-");
          this.writeLog(file, csv);
        },
          err => {
            alert('failed to open or create file ' + err)
          }
        );
      },
      function (err) {
        alert("Unable to download " + err);
        console.error(err);
      }
    );
  }

  writeLog(logOb, str) {
    if (!logOb) return;
    logOb.createWriter(function (fileWriter) {
      fileWriter.seek(fileWriter.length);

      var blob = new Blob([str], {
        type: 'text/plain'
      });
      fileWriter.write(blob);
      alert("ok, in theory i worked");
    },
      function (e) {
        alert("FileSystem Error" + e);
      }
    );
  }

  back() {
    this.showDetail = false;
    this.ngOnInit();
  }

  backClicked() {
    this.location.back();
  }

  refreshPage() {
    this.ngOnInit();
  }
}
