import { Component, Input, OnInit } from '@angular/core';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';

@Component({
  selector: 'app-gov-member-detail-shared',
  templateUrl: './gov-member-detail-shared.component.html',
  styleUrls: ['./gov-member-detail-shared.component.css']
})
export class GovMemberDetailSharedComponent implements OnInit {
  @Input() model: any;
  @Input() privacy: any;

  councillorTitle: any;

  constructor( public appSettingsService: AppSettingsService) { 
    this.councillorTitle = this.appSettingsService.getGovTitle().councillorTitle ? this.appSettingsService.getGovTitle().councillorTitle : 'Councillors';
  }

  ngOnInit() {
  }

  public getInitials(firstName: any, lastName: any) {
    return firstName.substring(0, 1) + lastName.substring(0, 1);
  }

}
