import { Component, OnInit } from '@angular/core';
import { Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { NationWallService } from 'src/app/services/firebase/nation-wall.service';
import { PostCommentModel } from 'src/app/models/PostCommentModel';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { toast } from 'materialize-css';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-view-nation-wall-post',
	templateUrl: './view-nation-wall-post.component.html',
	styleUrls: ['./view-nation-wall-post.component.css']
})

export class ViewNationWallPostComponent implements OnInit {

	addWallPostCommentForm: UntypedFormGroup;
	mineMessage = '';
	div_scroller: any;
	model: PostCommentModel;
	wall_post_id: any;
	wal_post_comments: any;
	logged_in_user_id: any;
	wall_post_name: any;
	able_to_delete_wall_post: Boolean = false;
	themeColor = environment.appTheme.themeColor

	constructor(private fd: UntypedFormBuilder, private nationWallService: NationWallService, private route: ActivatedRoute, private authService: AuthService, private router: Router) {
		this.logged_in_user_id = this.authService.loggedInUser.uid;
		this.addWallPostCommentForm = this.fd.group({
			comment: ['', [Validators.required]]
		});
	}

	get comment() { return this.addWallPostCommentForm.get("comment"); }

	ngOnInit() {
		this.route.paramMap.subscribe(params => {
			this.wall_post_id = params.get('id');
			this.nationWallService.getWallPostById(this.wall_post_id).valueChanges().subscribe(x => {
				if (x) {
					this.wall_post_name = x.name;
					if (this.authService.loggedInUser.uid == x.created_member_id) {
						this.able_to_delete_wall_post = true;
					}
				}
			});
		});
		this.nationWallService.getWallPostComments(this.wall_post_id).valueChanges().subscribe(x => {
			if (x) {
				this.wal_post_comments = x;
				this.wal_post_comments = this.wal_post_comments.sort((a, b) => (a.created_date < b.created_date ? -1 : 1));
				console.log("this.wal_post_comments: ", this.wal_post_comments)
			}
		});
		this.div_scroller = $('.chat-messages-child');
		this.div_scroller.scrollTop(this.div_scroller.prop("scrollHeight"));
	}

	submitForm(value: any) {
		this.model = new PostCommentModel();
		this.model.comment = value.comment;
		this.model.wall_post_id = this.wall_post_id;

		this.nationWallService.addCommentToPost(this.model).then(x => {
			this.mineMessage = value.comment;
			this.addWallPostCommentForm.patchValue({
				comment: ""
			});
			this.div_scroller.scrollTop(this.div_scroller.prop("scrollHeight"));
		});
	}

	DeleteWallPost() {
		for (let i = 0; i < this.wal_post_comments.length; i++) {
			this.nationWallService.DeleteWallPostComment(this.wal_post_comments[i].id);
		}
		this.nationWallService.DeleteWallPost(this.wall_post_id).then(x => {
			toast({ html: 'Wall Post Removed Successfully!', classes: 'green' });
			this.backClicked();
		});
	}

	backClicked() {
		this.router.navigate(['/member/nation-wall']);
	}
}