<div class="row col s12 container p-m-2">
  <div class="col l1"></div>
  <div class="col s12 l10">

    <app-firebase-loading *ngIf="showLoading"></app-firebase-loading>

    <div class="row">
      <div class="col s6 ">
        <a (click)="backClicked()">
          <div class="card hoverable " [ngStyle]="{'border-color':themeColor}"
            style="height: 95px; border: 2px solid ;">
            <div class="card-content">
              <div class="row center">
                <i class="material-icons black-text">house</i><br />
                <strong class="black-text">Back</strong>
              </div>
            </div>
          </div>
        </a>
      </div>

      <div class="col s6">
        <a [routerLink]="['/admin/news-events/create-event']">
          <div class="card hoverable " [ngStyle]="{'background-color':themeColor}" style="height: 95px;">
            <div class="card-content">
              <div class="row center">
                <i class="material-icons white-text">add_circle</i><br />
                <strong class="white-text">Create Event</strong>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>

    <div class="row black-text center" *ngIf="!model || model?.length == 0" style="opacity: 0.5;">
      <img src="../../assets/img/empty-events.png" height="450" width="450" class="responsive-img">
      <h6 class="bold-700">Sorry , {{ nationOfUse }} did not post any event!</h6>
    </div>

    <div cdkDropList class="row" *ngIf="model" (cdkDropListDropped)="drop($event)">
        <div cdkDrag *ngFor="let content of model | paginate: { itemsPerPage: 10, currentPage: p }">
          <div class="row card news-card hoverable" [ngStyle]="{'background-color': content.backgroundColor}"
            [routerLink]="['/admin/news-events/edit-event', content.id]" style="cursor: pointer;">

            <span class="material-symbols-outlined drag-handle right" cdkDragHandle style="color:gray;  font-size: 20px;">
              open_with
            </span>

            <div class="col s12 card-content">
              <div class="col s12"
                [ngClass]="{'col l9 m8 s12': content.type == 'PDF' || content.type == 'Video' || content.type == 'uploadVideo' || content.type == 'Photo'}">
                <p class="date-content sub-title-section3 p-b-1">
                  <span class="right">
                    <p *ngIf="content.visibility === 'Public'" class="green-text"><strong>Public</strong> </p>
                    <p *ngIf="content.visibility === 'Private'" class="red-text"><strong>Members Only</strong> </p>
                  </span>
                </p>
                <p class="news-title">
                  <a [routerLink]="['/admin/news-events/edit-card', content.id]" class="" [innerHTML]="content.title">
                  </a>
                </p>
                <p *ngIf="content.imgLink" class="middle-body-snippet sub-title-section3 p-t-1 bold-100"
                  style="text-align: justify;">
                  {{truncateHTML(content.body,300)}} </p>
                <p *ngIf="!content.imgLink" class="long-body-snippet sub-title-section3 p-t-1 bold-100"
                  style="text-align: justify;">
                  {{truncateHTML(content.body,300)}} </p>

                <p class="col l3 m3 s12 date-content sub-title-section3  bold-700 p-t-2"
                  [ngStyle]="{'color': themeColor}"><i class="tiny material-symbols-outlined bold-700 p-r-1">
                    calendar_month</i>{{content.eventDate}} </p>
                <p class="col l5 m5 s12 date-content sub-title-section3  bold-700 p-t-2"
                  [ngStyle]="{'color': themeColor}"><i class="tiny material-symbols-outlined bold-700 p-r-1">
                    schedule</i>{{content.startsAt}} - {{content.endsAt}} </p>
                <p class="col l4 m5 s12 date-content sub-title-section3  bold-700 p-t-2"
                  [ngStyle]="{'color': themeColor}"><i class="tiny material-symbols-outlined bold-700 p-r-1">
                    place</i>{{content.eventPlace}} </p>
              </div>

              <div class="col l3 m4 hide-on-small-only"
                *ngIf="content.type == 'PDF' || content.type == 'Video' || content.type == 'uploadVideo' || content.type == 'Photo'">

                <div class="card-image" *ngIf="content.type === 'Photo' && content.imgLink">
                  <img [src]="content.imgLink" class="news-img">
                </div>

                <div class="col l12 s12" *ngIf="content.type === 'PDF' && content.videoLink">
                  <iframe title="iframe-box2" class="responsive-pdf" [src]="content.videoLink | safeUrl"></iframe>
                </div>

                <div class="card-image" *ngIf="content.type === 'Video' && content.videoLink">
                  <div class="video-container ">
                    <youtube-player [videoId]="embedVideo(content.videoLink)"></youtube-player>
                    <!-- <div [innerHTML]="embedVideo(content.videoLink)"></div> -->
                  </div>
                </div>

                <div class="card-image" *ngIf="content.type === 'uploadVideo' && content.videoLink">
                  <video class="responsive-video " controls>
                    <source [src]="content.videoLink" type="video/mp4">
                  </video>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="row center">
          <pagination-controls (pageChange)="p = $event"></pagination-controls>
        </div>
      </div>
    </div>
  </div>

  <div class="col l1"></div>
