import { Component, OnInit } from '@angular/core';
import { DepartmentService } from 'src/app/services/firebase/department.service';
import { Router } from '@angular/router';
import { trigger, transition, useAnimation } from '@angular/animations';
import { bounceIn } from 'ng-animate';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';

declare var $: any;

@Component({
  selector: 'app-department',
  templateUrl: './department.component.html',
  styleUrls: ['./department.component.css'],
  animations: [
    trigger('swing', [transition('* => *', useAnimation(bounceIn))])
  ],
})

export class DepartmentComponent implements OnInit {
  model: any[];
  nationOfUSe = environment.firstNation.name;  
  themeColor = environment.appTheme.themeColor;
  constructor(private departmentService: DepartmentService, 
    private router: Router, 
    private location: Location){ }

  ngOnInit() {
    this.departmentService.getDepartmentList().valueChanges().subscribe(x => {
      if (x) {
        this.model = x;
        this.model.sort((a, b) => (a.index < b.index ? -1 : 1));
        setTimeout(() => {
          $('.collapsible').collapsible();
        }, 25);
      }
    });
  }


  editDepartmentData(event: Event, id?: any) {
    event.preventDefault();
    this.router.navigate(['/member/member-main-home/manage-department/', id]);
  }

  public getInitials(departmentName: any) {
    if (departmentName) {
      departmentName = departmentName.replace(/<(?:.|\n)*?>/gm, '');
      return departmentName.substring(0, 1);
    } else { return ''; }
  }
  
  backClicked() {
    this.location.back();
  }
}
