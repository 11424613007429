import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { ChatGptChatModel, ChatGptResponseModel } from "src/app/models/ChatGptModel";
import { ChatGptService } from "src/app/services/api/chat-gpt.service";
import { AppSettingsService } from "src/app/services/firebase/app-settings.service";
import { AuthService } from "src/app/services/firebase/auth.service";
import { HelperService } from "src/app/services/helper/helper";
import { environment } from "src/environments/environment";

declare var $: any;
declare var M: any;

@Component({
  selector: "app-chat-gpt",
  templateUrl: "./chat-gpt.component.html",
  styleUrls: ['./chat-gpt.component.css']

})
export class ChatGptComponent implements OnInit {
  defaultThemeColor = environment.appTheme.themeColor;
  themeColor: any;
  buttonType: any;
  chatForm: UntypedFormGroup;
  chatResponse = [];
  selectedResponse: any;
  resLoading = false;
  maxResponseCount:number = 5
  originalQuestion: string =''
  user;
  title;
  titleOptions = [
    "Ask me anything, I'm here to help you like a trusted friend.",
    "No question is too big or small, I'm here to provide answers to them all.",
    "Think of me as your personal information guru, ask away!",
    "There's no need to hesitate, ask me anything on your mind.",
    "From the simple to the complex, I'm ready to answer any question you ask.",
    "Don't hold back, ask me anything and let's get the answers you need.",
    "Your curiosity is welcome here, ask me anything and satisfy your thirst for knowledge.",
    "I'm your go-to source for information, so ask me anything that comes to mind.",
    "Your questions are my priority, ask away and I'll provide the answers you seek.",
  ];

  @Input() theInput;
  @Input() inputText;
  @Output() onMessageSelect = new EventEmitter();
  @Output() onCloseModal = new EventEmitter<boolean>();

  constructor(
    private chatGptService: ChatGptService,
    private settingsService: AppSettingsService,
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private helperService: HelperService
  ) {
    this.themeColor = this.settingsService.getAppThemeColor()
      ? this.settingsService.getAppThemeColor()
      : this.defaultThemeColor;
    this.buttonType = this.settingsService.getButtonType()
      ? this.settingsService.getButtonType()
      : "rectangularButton";
  }

  ngOnInit() {
    setTimeout(() => {
      $(".modal").modal();
      $("#chatGptModal").modal({
        dismissible: false,
      });
      $("#chatGptModal").modal("open");
      M.updateTextFields();
    }, 25);
    this.user =this.authService.getGlobalUser();

    let index = Math.floor(Math.random() * this.titleOptions.length);
    this.title = this.titleOptions[index];

    this.chatForm = this.fb.group({
      content: ["", [Validators.required]],
      response: [""],
    });

    if (this.inputText && typeof this.inputText !== "undefined") {
      this.chatForm.get("content").patchValue(this.inputText);
      this.submitChat(this.chatForm.value)
      this.originalQuestion = this.inputText
    }
  }

  concatQuestion(question){
    this.originalQuestion = this.originalQuestion + '\n ' + question 
  }

  submitChat(values) {
    let model = new ChatGptChatModel();
    // model.message = values.content;
    this.concatQuestion(values.content)
    model.message = this.originalQuestion;

    let role = this.user ? this.user.GivenName : "User";

    let userRequest = {
      message: {
        role: role,
        content: model.message,
      },
    };
    this.chatResponse.push(userRequest);
    
    this.resLoading = true;
    this.chatGptService.startChat(values.content).subscribe((res: any) => {
      this.resLoading = false;
      if (res) {
        // the response from api is 'an over-stringified string' so need to 'JSON.parse' response twice
        let response = JSON.parse(JSON.parse(res));
        if (response.choices && response.choices.length > 0) {
          response.choices.map((choice) => this.chatResponse.push(choice));
          this.selectResponseChoice(
            this.chatResponse[this.chatResponse.length - 1],
            false
          );
        }
      }
    });
  }

  onMaxResponse() {
    return (
      this.chatResponse.filter((res) => res.message.role == "assistant")
        .length >= this.maxResponseCount
    );
  }

  selectResponseChoice(choice,useText: boolean) {
    this.selectedResponse = choice.message.content;
    if (useText) {
      this.selectMessage();
    }
  }

  selectMessage() {
    let response = new ChatGptResponseModel();
    response.input = this.theInput;
    response.message = this.selectedResponse;

    this.onMessageSelect.emit(response);
    this.closeModal();
  }

  closeModal() {
    M.updateTextFields();
    $("#chatGptModal").modal("close");
    this.onCloseModal.emit();
  }

  counterMessage(){
    let responseCount =  this.chatResponse.filter((res) => res.message.role == "assistant").length
    let text;
    if(this.onMaxResponse()){
      return 'Sorry, you have reached the maximum number of questions allowed.'
    }
    return text = `You have ${this.maxResponseCount - responseCount} questions left.`  
  }
  counterCss(){
    let responseCount =  this.chatResponse.filter((res) => res.message.role == "assistant").length
    let css = '';
    if(this.onMaxResponse()){
      css='red-text'
    }
    else if(responseCount <= this.maxResponseCount /2){
      css='green-text'
    }else {
      css='yellow-text'
    }
    return css
  }

  copy(message, index) {
    this.helperService.copy(message.content);
    let el = document.getElementById(index);

    el.classList.add("tooltip");
    el.classList.add("tooltip-open");

    setTimeout(() => {
      el.classList.remove("tooltip");
      el.classList.remove("tooltip-open");
    }, 2000);
  }
}
