import { Component, OnInit } from '@angular/core';
import { Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { SkillModel } from 'src/app/models/SkillModel';
import { SkillService } from 'src/app/services/api/skill.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { toast } from 'materialize-css';
import { Location } from '@angular/common';

@Component({
  selector: 'app-add-skill',
  templateUrl: './add-skill.component.html',
  styleUrls: ['./add-skill.component.css']
})

export class AddSkillComponent implements OnInit {

  clientID = 0;
  addSkillForm: UntypedFormGroup;
  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  model: SkillModel;

  constructor(private authService: AuthService,
    private fb: UntypedFormBuilder,
    private location: Location,
    private skillService: SkillService) { }

  ngOnInit(): void {

    if (localStorage.getItem('applicantID')) {
      this.clientID = Number(localStorage.getItem('applicantID'));
    } else {
      this.clientID = this.authService.getCurrentApplicantID();
    }
    this.addSkillForm = this.fb.group({
      skill: ['', [Validators.required]],
    });

    this.model = new SkillModel();
    this.model.client_id = this.clientID;
  }

  get skill() { return this.addSkillForm.get('skill'); }

  submitForm(value: any) {
    if (this.addSkillForm.valid) {
      this.model.SkillName = value.skill;
      this.skillService.saveSkill(this.model).subscribe(x => {
        if (x) {
          toast({ html: 'Skill Successfully Added!', classes: 'green' });
          this.location.back();
        }
      });
    }
  }

  backClicked() {
    this.location.back();
  }

}