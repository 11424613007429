<div class="container">


  <div class="">
      <div class="section"></div>
        <div class="row center">
          <h6><strong> Skills Data Bank</strong></h6>
        </div>


        <div class="col l1 "></div>
        <div class="row col s12 l10 ">






          <div class="card">
            <div class="card-content">



<div class="row">
  <h6 ><strong> If you like to search our Clients' Skills.  </strong></h6>
  <p> - You can enter multiple keywords</p>
  <p> - You need to press <strong><span class="red-text">ENTER</span></strong> after each key word.</p>
  <div class=" input-field col s12">
    <div id="keywords"  name='keywords'  class="chips-placeholder"></div>
  </div>




  <div class=" input-field col s12">
    <button  (click)="Search()" class = 'col s12 btn waves-effect' >
      <i class="material-icons left">search</i> Search
    </button>
  </div>

</div>

<div class="row" *ngIf="searchResult" >


  <table *ngIf="searchResult?.length > 0">
    <thead>
      <tr>
          <th class="center">Client Ref.</th>
          <th class="center">Title</th>
          <th class="center">Resume</th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let result of searchResult">
        <td class="center blue-text"><strong><u> {{result.client_id}} </u></strong></td>
        <td class="center" innerHTML="{{ result.title | highlight : keywords}}"></td>
        <td class="center">
        <i class="material-icons">mail</i>

        </td>
      </tr>

    </tbody>
  </table>

  <div class=" card-panel red white-text col s12" *ngIf="searchResult?.length === 0">
    <p>No records foud matching your search keywords.</p>
  </div>

</div>

            </div>
          </div>





            </div>



        <div class="col l1 "></div>
      </div>


</div>
