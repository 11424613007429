<div class="row center-align">
  <h5><strong> My Inbox </strong></h5>
</div>

<div class="row">
  <div class="col l1"></div>
  <div class="col s12 l10">

    <app-user-inbox [userType]="'Member'"></app-user-inbox>

  </div>
  <div class="col l1 "></div>
</div>