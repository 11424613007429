import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { DepartmentModel } from 'src/app/models/DepartmentModel';
import { DepartmentService } from 'src/app/services/firebase/department.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';

declare var M: any;

@Component({
  selector: 'app-view-department-info',
  templateUrl: './view-department-info.component.html',
  styleUrls: ['./view-department-info.component.scss']
})
export class ViewDepartmentInfoComponent implements OnInit {

  model: DepartmentModel;
  fromWhere: any;
  departmentId: any;
  defaultThemeColor = environment.appTheme.themeColor;
  themeColor: any;
  buttonType: any;

  constructor(
    private settingsService: AppSettingsService,
    private location: Location,
    private departmentService: DepartmentService,
    private route: ActivatedRoute
  ) {
    this.themeColor = this.settingsService.getAppThemeColor() ? this.settingsService.getAppThemeColor() : this.defaultThemeColor;
    this.buttonType = this.settingsService.getButtonType() ? this.settingsService.getButtonType() : 'rectangularButton';
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.departmentId = params.get('id');
    });

    if (this.departmentId) {
      this.departmentService.getDepartmentByID(this.departmentId).valueChanges().subscribe(x => {
        if (x) {
          this.model = x;
        }
      });
    }
  }

  backClicked() {
    this.location.back();
  }
}
