<div class="row center-align">
    <h5><strong>House Renovation</strong></h5>
</div>

<app-firebase-loading *ngIf="loading"></app-firebase-loading>

<div class="row col s12" *ngIf="step1 && !loading">
    <div class="col l1"></div>

    <div class="row center card white" *ngIf="!requests">
        <i style="font-size: 100px;" class="material-icons blue-text">
            info
        </i>
        <p class="blue-text"> <strong>You Have No Pending Requests.</strong></p>
    </div>

    <div *ngIf="requests">
       sssssssssssssss <div class="row card white col s12" *ngFor="let request of requests;let i=index;">
            <div class="row col s12">
                <div class="col m8 s12">
                    <h6><strong>{{request.title}}</strong></h6>
                    <p>{{request.description}}</p>
                    <a (click)="onShowMore(i)" class="btn-flat" style="margin-bottom: 15px; border: 1px black solid;">
                        <span *ngIf="!showMore[i]">
                            <i class="large material-icons">
                                arrow_drop_down</i>Show More
                        </span>
                        <span *ngIf="showMore[i]">
                            <i class="large material-icons">
                                arrow_drop_up</i>Show Less
                        </span>
                    </a>
                </div>

                <div class="col m4 s12">
                    <div class="row col s12">
                        <button class="btn white right tooltipped" data-position="bottom" data-tooltip="Open as a PDF"
                            type="button" style="margin-bottom: 25px;" (click)="openPdf(i)" style="margin:5px;"><i
                                class="material-icons black-text">open_in_new</i></button>

                        <button class="btn white right tooltipped" data-position="bottom" data-tooltip="Download as PDF"
                            type="button" (click)="downloadPdf(i)" style="margin:5px;"><i
                                class="material-icons black-text">download</i></button>
                    </div>

                    <p
                        [ngClass]="{'Pending': 'amber-text' ,'Approved': 'green-text', 'Declined' : 'red-text'}[request.status]">
                        <i class="material-icons tiny">
                            circle
                        </i><strong>{{request.status | uppercase}}</strong>
                    </p>
                    <p *ngIf="request.status === 'Approved'">In Progress</p>

                    <div *ngIf="request.status === 'Declined'">
                        <button *ngIf="!request.appeal" [ngClass]="{'hide':showMore[i] }"
                            class="col s12 btn waves-effect blue lighten-3" (click)="appealDecision(i)">
                            <i class="material-icons right">gavel</i> Appeal Decision
                        </button>

                        <p *ngIf="request.appeal">Appealed on {{ isDate(request.appeal.date) ? (request.appeal.date |
                            date:
                            'dd/MM/yyyy') : request.appeal.date.toDate().toDateString() | date:
                            'dd/MM/yyyy'}}</p>
                    </div>
                    <div *ngIf="request.status === 'Completed'">
                        <span *ngIf="!request.feedback">

                            <button class="col s12 btn waves-effect modal-trigger pulse" (click)="giveFeedback(i)"
                                data-target="reviewModel">
                                <i class="material-icons right">star_rate</i>Give Feedback
                            </button>
                        </span>
                        <span *ngIf="request.feedback">
                            <span *ngFor="let rate of createRange()">
                                <i style="display: inline-block" *ngIf="rate <= request.feedback.rating"
                                    class="material-icons green-text">
                                    star
                                </i>
                                <i style="display: inline-block" *ngIf="rate > request.feedback.rating"
                                    class="material-icons">
                                    star_outline
                                </i>
                            </span>
                        </span>
                    </div>
                </div>
            </div>

            <div class="row col s12" *ngIf="showMore[i]">

                <div class="col s12 grey lighten-4" style="padding: 25px;">
                    <h6>REQUEST STATUS: <Strong>{{request.status | uppercase}}</Strong></h6>

                    <div *ngIf="request.status === 'Pending'">
                        <p><strong>No Decision has been made regarding your request.</strong></p>
                    </div>

                    <div *ngIf="request.status === 'Declined'">
                        <div class="row">
                            <p>Reasone Your Request has been declined:
                                <strong>{{request.decision.reason}} </strong>
                            </p>
                        </div>

                        <div class="row">
                            <button *ngIf="!request.appeal" class="col s12 btn waves-effect blue lighten-3"
                                (click)="appealDecision(i)">
                                <i class="material-icons right">gavel</i> Appeal Decision
                            </button>
                            <div *ngIf="request.appeal">
                                <h6><strong>Appeal Submitted</strong></h6>
                                <p><strong>Submitted On: </strong>{{request.appeal.date.toDate().toDateString() |
                                    date: 'dd/MM/yyyy'}}</p>
                                <p><Strong>Statement: </Strong>{{request.appeal.statement}}</p>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="request.status === 'Approved'">
                        <p><strong>Your Request has been Approved</strong></p>
                        <p>{{request.decision.message}}</p>
                        <br />
                        <p><strong>Case Officer: </strong> {{request.decision.employee}}</p>
                        <p><strong>Start Date: </strong> {{request.decision.startDate.toDate().toDateString()}}</p>
                        <p><strong>End Date: </strong> {{request.decision.endDate.toDate().toDateString()}}</p>
                    </div>

                    <div *ngIf="request.status === 'Completed'">
                        <p>Completed On:
                            <strong>{{request.completedOn.toDate().toDateString()}} </strong>
                        </p>
                    </div>
                </div>

                <div class="section"></div>

                <div class="col s12 grey lighten-5" style="padding: 25px;">
                    <h6><Strong>REQUEST INFORMATION</Strong></h6>
                    <p><strong>Request Date: </strong> {{request.requestDate.toDate().toDateString()}}</p>
                    <p><strong>Purpose: </strong> {{request.purpose}}</p>
                    <p><strong>House Number: </strong> {{request.houseNumber}}</p>
                </div>

            </div>
        </div>
    </div>

    <div class="row">
        <button class="col s12 btn waves-effect green" (click)="requestRenovation()" style="margin-bottom: 15px;">
            <i class="material-icons right">construction</i> Request Renovation
        </button>

        <button [ngClass]="'col s12 btn waves-effect ' + themeColor" (click)="back()">
            <i class="material-icons left">arrow_back_ios</i> Back To Previous Page
        </button>
    </div>
    <div class="col l1"></div>
</div>

<div id="appealModal" class="modal">
    <div class="modal-content">
        <h4>Appeal Decision</h4>
        <p>To submit an appeal Please state why the decision mader regarding your request should be reconsidered.</p>
        <br />

        <form [formGroup]="appealForm" (ngSubmit)="appeal(appealForm.value)">

            <div class="input-field col s12">
                <i class="material-icons prefix orange-text">message</i>
                <textarea id="statement" rows="3" style="height: 100px;" formControlName="statement"
                    class="materialize-textarea" style="text-transform: capitalize;"></textarea>
                <label for="statement">Statement</label>
            </div>

            <div class="row">
                <button type="submit" class="col s12 btn waves-effect red" [disabled]="!appealForm.valid"
                    style="margin-bottom: 15px;">
                    <i class="material-icons right">send</i> Submit
                </button>

                <button type="button" class="col s12 btn-flat waves-effect blue-grey lighten-5" (click)="closeModal()"
                    style="margin-bottom: 15px;">
                    <i class="material-icons right">cancel</i> Close
                </button>
            </div>
        </form>
    </div>
</div>

<div id="reviewModel" class="modal">
    <div class="modal-content">
        <h4>GIVE US YOUR FEEDBACK</h4>
        <p>Tell us what you think about our service.</p>
        <ul class="col offset-s2 s10">
            <!-- <li *ngFor="let req of completedRequests">{{req.title}}</li> -->
        </ul>
        <p>Please Fill out this question for a better customer statisfaction.</p>
        <br />

        <form [formGroup]="feedbackForm" (ngSubmit)="submitReview(feedbackForm.value)">

            <div class="input-field col s12">
                <p>How Satisfied Are you with our service?</p>
                <br />
                <ngx-slider [options]="getScaleOption()" formControlName="rating"></ngx-slider>
            </div>

            <div class="section"></div>
            <p>If there is any comments you would like to add?</p>
            <br />
            <div class="input-field col s12">
                <i class="material-icons prefix orange-text">message</i>
                <textarea id="review" rows="3" style="height: 100px;" formControlName="review"
                    class="materialize-textarea" style="text-transform: capitalize;"></textarea>
                <label for="review">Comment</label>
            </div>

            <div class="row">
                <button type="submit" class="col s12 btn waves-effect red" [disabled]="!feedbackForm.valid"
                    style="margin-bottom: 15px;">
                    <i class="material-icons right">send</i> Submit
                </button>

                <button type="button" class="col s12 btn-flat waves-effect blue-grey lighten-5" (click)="closeModal()"
                    style="margin-bottom: 15px;">
                    <i class="material-icons right">cancel</i> Close
                </button>
            </div>
        </form>
    </div>
</div>

<div class="row col s12" *ngIf="step2">
    <div class="col l1"></div>

    <form class="col s12 m12 l10" [formGroup]="renovationForm" (ngSubmit)="submitRequest(renovationForm.value)">

        <div class="row col s12 card white">
            <div class="input-field col s12">
                <i class="material-icons prefix green-text">title</i>
                <input id="title" type="text" formControlName='title' class="validate" autocomplete="off"
                    style="text-transform: capitalize;" />
                <label for="title">Title<span class="red-text">*</span></label>
                <div *ngIf="(title.dirty || title.touched) && title.invalid" style="position: absolute;">
                    <small *ngIf="title.errors.required" class="red-text"> Title is required.
                    </small>
                </div>
            </div>

            <div class="input-field col s12">
                <i class="material-icons prefix blue-text">description</i>
                <textarea id="description" rows="3" style="height: 100px;" formControlName="description"
                    class="materialize-textarea" style="text-transform: capitalize;"></textarea>
                <label for="description">Description<span class="red-text">*</span></label>
                <div *ngIf="(description.dirty || description.touched) && description.invalid"
                    style="position: absolute;">
                    <small *ngIf="description.errors.required" class="red-text"> Description is required.
                    </small>
                </div>
            </div>

            <div class="input-field col s12">
                <i class="material-icons prefix teal-text">book</i>
                <textarea id="purpose" rows="3" style="height: 100px;" formControlName="purpose"
                    class="materialize-textarea" style="text-transform: capitalize;"></textarea>
                <label for="purpose">Purpose</label>
            </div>

            <div class="input-field col s12">
                <i class="material-icons prefix teal-text">today</i>
                <!-- <input id="renovation_date" type="text" formControlName='renovation_date' class="validate"
                    autocomplete="off" placeholder="dd/mm/yyyy" [textMask]="{mask: dateMask, guide: true}" /> -->
                    <input id="renovation_date" type="text" formControlName='renovation_date' class="validate"
                    autocomplete="off" placeholder="dd/mm/yyyy"  />
                <label for="renovation_date">Renovation Date (dd/mm/yyy)<span class="red-text">*</span></label>
                <div *ngIf="(renovation_date.dirty || renovation_date.touched) && renovation_date.invalid"
                    style="position: absolute;">
                    <small *ngIf="renovation_date.errors.required" class="red-text"> Renovation Date is
                        required.</small>
                    <small *ngIf="renovation_date.errors.invalidDate" class="red-text"> The Date is invalid</small>
                </div>
            </div>
        </div>

        <div class="row col s12 card white" formGroupName="renovation_items">
            <div class="input-field col s12">
                <div>
                    <p>Do you want to add Renovation Items? <span class="red-text">*</span></p>
                </div>
                <div class="row col s12">
                    <label class="col s6">
                        <input name="add_item" type="radio" formControlName="add_item" [value]="true"
                            (click)="addItemsYes()" />
                        <span>Yes</span>
                    </label>
                    <label class="col s6">
                        <input name="add_item" type="radio" formControlName="add_item" [value]="false"
                            (click)="addItemsNo()" />
                        <span>No</span>
                    </label>
                </div>
            </div>

            <div class="row col s12" *ngIf="renovation_items.controls.length > 0">
                <h5><strong>Renovation Items</strong></h5>
                <table class="highlight striped">
                    <thead>
                        <tr>
                            <th>Brand</th>
                            <th>Model</th>
                            <th>Serial #</th>
                            <th>Quantity</th>
                            <th>Remove</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of renovation_items.controls;let i=index;">
                            <td>{{renovation_items.controls[i].controls.brand.value}}</td>
                            <td>{{renovation_items.controls[i].controls.model.value}}</td>
                            <td>{{renovation_items.controls[i].controls.serial_no.value}}</td>
                            <td>{{renovation_items.controls[i].controls.quantity.value}}</td>
                            <td><i class="material-icons red-text" (click)="removeItem(i)">delete</i></td>
                        </tr>
                    </tbody>
                </table>
                <div class="section"></div>
                <div class="row col s12" *ngIf="!addItem">
                    <button type="button" class="col s12 btn waves-effect teal" style="margin-bottom: 15px;"
                        (click)="addItemsYes()">
                        <i class="material-icons right">add</i> Add Another Item
                    </button>
                </div>
            </div>

            <div *ngIf="addItem">
                <div class="row col s12">
                    <div class="input-field col s12">
                        <i class="material-icons prefix green-text">loyalty</i>
                        <input id="brand" type="text" formControlName='brand' class="validate" autocomplete="off"
                            style="text-transform: capitalize;" />
                        <label for="brand">Brand<span class="red-text">*</span></label>
                        <div *ngIf="(brand.dirty || brand.touched) && brand.invalid" style="position: absolute;">
                            <small *ngIf="brand.errors.required" class="red-text"> Brand is required.
                            </small>
                        </div>
                    </div>

                    <div class="input-field col s12">
                        <i class="material-icons prefix blue-text">bookmark</i>
                        <input id="model" type="text" formControlName='model' class="validate" autocomplete="off"
                            style="text-transform: capitalize;" />
                        <label for="model">Model</label>
                    </div>

                    <div class="input-field col s12">
                        <i class="material-icons prefix purple-text">tag</i>
                        <input id="serial_no" type="text" formControlName='serial_no' class="validate"
                            autocomplete="off" style="text-transform: capitalize;" />
                        <label for="serial_no">Serial Number</label>
                    </div>

                    <div class="input-field col s12">
                        <i class="material-icons prefix orange-text">pin</i>
                        <input id="quantity" min="0" step="1" value="0" placeholder="0" type="number"
                            formControlName='quantity' class="validate" autocomplete="off"
                            style="text-transform: capitalize;" />
                        <label for="quantity">Quantity<span class="red-text">*</span></label>
                        <div *ngIf="(quantity.dirty || quantity.touched) && quantity.invalid">
                            <small *ngIf="quantity.errors.required" class="red-text"> Quantity is required.
                            </small>
                        </div>
                    </div>

                    <div class="row">
                        <button type="button" class="col s12 btn waves-effect blue darken-4"
                            style="margin-bottom: 15px;" [disabled]="!renovationForm.get('renovation_items').valid"
                            (click)="addNewItem(renovationForm.get('renovation_items').value)">
                            <i class="material-icons right">add</i> Add Item
                        </button>

                        <button type="button" class="col s12 btn waves-effect red" style="margin-bottom: 15px;"
                            (click)="closeAddItem()">
                            <i class="material-icons right">cancel</i> Cancel
                        </button>
                    </div>
                </div>

            </div>
        </div>

        <div class="row col s12 card white" formGroupName="renovation_employees">
            <div class="input-field col s12">
                <div>
                    <p>Do you want to add Renovation Employees? <span class="red-text">*</span></p>
                </div>
                <div class="row col s12">
                    <label class="col s6">
                        <input name="add_employee" type="radio" formControlName="add_employee" [value]="true"
                            (click)="addEmployeeYes()" />
                        <span>Yes</span>
                    </label>
                    <label class="col s6">
                        <input name="add_employee" type="radio" formControlName="add_employee" [value]="false"
                            (click)="addEmployeeNo()" />
                        <span>No</span>
                    </label>
                </div>
            </div>

            <div class="row col s12" *ngIf="renovation_employees.controls.length > 0">
                <h5><strong>Renovation Items</strong></h5>
                <table class="highlight striped">
                    <thead>
                        <tr>
                            <th>Employee Name</th>
                            <th>Employee ID</th>
                            <th>Remove</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of renovation_employees.controls;let i=index;">
                            <td>{{renovation_employees.controls[i].controls.employee_name.value}}</td>
                            <td>{{renovation_employees.controls[i].controls.employee_id.value}}</td>
                            <td><i class="material-icons red-text" (click)="removeEmployee(i)">delete</i></td>
                        </tr>
                    </tbody>
                </table>
                <div class="section"></div>
                <div class="row col s12" *ngIf="!addEmployee">
                    <button type="button" class="col s12 btn waves-effect teal" style="margin-bottom: 15px;"
                        (click)="addEmployeeYes()">
                        <i class="material-icons right">add</i> Add Another Employee
                    </button>
                </div>
            </div>

            <div *ngIf="addEmployee">
                <div class="row col s12">

                    <div class="input-field col s12">
                        <i class="material-icons prefix green-text">person</i>
                        <input id="employee_name" type="text" formControlName='employee_name' class="validate"
                            autocomplete="off" style="text-transform: capitalize;" />
                        <label for="employee_name">Employee Name<span class="red-text">*</span></label>
                    </div>

                    <div class="input-field col s12">
                        <i class="material-icons prefix blue-text">perm_identity</i>
                        <input id="employee_id" type="text" formControlName='employee_id' class="validate"
                            autocomplete="off" style="text-transform: capitalize;" />
                        <label for="employee_id">Employee ID</label>
                    </div>

                    <div class="row">
                        <button type="button" class="col s12 btn waves-effect blue darken-4"
                            style="margin-bottom: 15px;" [disabled]="!renovationForm.get('renovation_employees').valid"
                            (click)="addNewEmployee(renovationForm.get('renovation_employees').value)">
                            <i class="material-icons right">add</i> Add Employee
                        </button>

                        <button type="button" class="col s12 btn waves-effect red" style="margin-bottom: 15px;"
                            (click)="closeAddEmployee()">
                            <i class="material-icons right">cancel</i> Cancel
                        </button>
                    </div>
                </div>

            </div>
        </div>

        <div class="row">
            <button type="submit" class="col s12 btn waves-effect green" style="margin-bottom: 15px;"
                [disabled]="!renovationForm.valid">
                <i class="material-icons right">construction</i> Submit Request
            </button>

            <button type="button" [ngClass]="'col s12 btn waves-effect ' + themeColor" (click)="backToPreviousPage()">
                <i class="material-icons left">arrow_back_ios</i>Back to Previous Page
            </button>
        </div>
    </form>

    <div class="col l1"></div>
</div>