<div class="container-pi ">

  <h4 class="title-section1 title-member  icon-texts"> <span class="material-symbols-outlined back-icon p-r-1"
      (click)=" backClicked()">
      arrow_circle_left
    </span>Member News</h4>

  <div class="row card-panel b-n-1">
    <app-firebase-loading *ngIf="!model"></app-firebase-loading>

    <div *ngIf="model" class="col s12">
      <div *ngFor="let data of model">
        <!-- <app-news-feed [callerForm]="'MemberNews'" [data]="data">
        </app-news-feed> -->
        <div class="col l12 m12 s12 ">
          <div class="card card-size hoverable" [routerLink]="['/read-more',data.id]">

            <div class="card-content">
              <div class="col l9 m8 s8 container3">

                <!-- <p class="date-content sub-title-section3">Author . 6 days ago </p> -->
                <p class="date-content sub-title-section3 bold-400" [ngStyle]="{'color' : themeColor}">
                  {{data.datePublished.toDate() |date: 'MMM dd, yyyy'}}</p>

                <p class="news-title short-body-snippet" [innerHTML]="data.title"></p>

                <p class="news-title short-body-snippet ">
                  <a *ngIf="callerForm === 'News'" [routerLink]="['/read-more',data.id]" [innerHTML]="data.title">
                  </a>
                </p>

                <p class="news-title short-body-snippet ">
                  <a *ngIf="callerForm === 'MemberNews'" [routerLink]="['/member/news-detail',data.id]"
                    [innerHTML]="data.title">
                  </a>
                </p>
                <p class="news-title short-body-snippet ">
                  <a *ngIf="callerForm === 'DepNews'" [routerLink]="['/member/view-department-news',data.id]"
                    [innerHTML]="data.title">
                  </a>
                </p>
                <p class="news-title short-body-snippet ">
                  <a *ngIf="callerForm === 'DepNews_Public'" [routerLink]="['/view-department-news',data.id]"
                    [innerHTML]="data.title">
                  </a>
                </p>

                <p *ngIf="data.imgLink " class="middle-body-snippet sub-title-section3 p-t-1 bold-100 ">
                  {{truncateHTML(data.body,300)}} </p>
                <p *ngIf="!data.imgLink" class="middle-body-snippet sub-title-section3 p-t-1 bold-100">
                  {{truncateHTML(data.body,300)}} </p>

                <!-- <div class=" col l3 read-more-card1" *ngIf="callerForm === 'News'">
                  <h4 class="read-more-card1 " [routerLink]="['/read-more',data.id]">Read More </h4>
                </div>

                <div class=" col l3 read-more-card1 " *ngIf="callerForm === 'MemberNews'">
                  <h4 class="read-more-card1 " [routerLink]="['/member/news-detail',data.id]">Read More </h4>
                </div> -->
              </div>

              <div class="col l3 m4 s4">
                <div class="card-image" *ngIf="data.imgLink">
                  <img [src]="data.imgLink" class="news-img">
                </div>
                <div class="col l12 s12" *ngIf="data.type === 'PDF' && data.videoLink">
                  <iframe title="iframe-box2" class="responsive-pdf " [src]="data.videoLink | safeUrl"></iframe>
                </div>

                <div class="card-image" *ngIf="data.type === 'Video'">
                  <div class="video-container ">
                    <div [innerHTML]="iframhtml"></div>
                  </div>
                </div>
                <div class="card-image " *ngIf="data.type === 'uploadVideo'">
                  <video class="responsive-video " controls>
                    <source [src]="data.videoLink" type="video/mp4">
                  </video>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>