<div class="container">
  <div class="">
    <div class="section"></div>
    <div class="row center">
      <h5 class="red-text"><strong> Skills Databank </strong></h5>
    </div>

    <div class="col l1 "></div>
    <div class="row col s12 l10 ">
      <div class="card">
        <div class="card-content">

          <div class="row">
            <h6><strong> If you like to search our Clients' Skills. </strong></h6>
            <p> - You can enter multiple keywords</p>
            <p> - You need to press <strong><span class="red-text">ENTER</span></strong> after each key word.</p>
            <div class=" input-field col s12">
              <div id="keywords" name='keywords' class="chips-placeholder"></div>
            </div>

            <div class=" input-field col s12">
              <button (click)="Search()" class='col s12 btn waves-effect'>
                <i class="material-icons left">search</i> Search
              </button>
            </div>
          </div>

          <div class="row" *ngIf="searchResult">
            <table *ngIf="searchResult?.length > 0">
              <thead>
                <tr>
                  <th class="center">ID</th>
                  <th class="center">Member</th>
                  <th class="center">Send Email</th>
                  <th class="center">View Resume</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let result of searchResult">
                  <td class="center blue-text"><strong> {{result.client_id}} </strong></td>
                  <td class="center blue-text"><strong> {{result.givenname | NameStars}} {{result.lastname | NameStars}}
                    </strong></td>

                  <td class="center">
                    <div class="col l3 "></div>
                    <div class="col l6 " *ngIf='result.Email'>
                      <i class="material-icons" (click)="SendEmail(result.client_id)">mail</i>
                    </div>
                    <div class="col l6 red-text" *ngIf='!result.Email'> --- </div>
                    <div class="col l3 "></div>
                  </td>

                  <td class="center">
                    <div class="col l3 "></div>
                    <div class="col l6 " *ngIf='result.Resume'>
                      <i class="material-icons center" (click)="ViewResume(result.client_id)">attachment</i>
                    </div>
                    <div class="col l6 red-text" *ngIf='!result.Resume'> --- </div>
                    <div class="col l3 "></div>
                  </td>
                </tr>
              </tbody>
            </table>

            <div class=" card-panel red white-text col s12" *ngIf="searchResult?.length === 0">
              <p>No records foud matching your search keywords.</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col l1 "></div>
  </div>
</div>

<div id="editEmailModal" class="modal">
  <div class="modal-content">
    <!--  <h6><strong> Edit Email</strong></h6>  -->
    <strong>This Client has No Email</strong>
    <!--  <form [formGroup]="editEmail" (ngSubmit)="editEmailSubmit(editEmail.value)">
      <div class="row">
        <p><strong> This client doesnt have an email address, please update the email address before contacting the client.  </strong> </p>
          <div class="input-field col s12 l12">
              <input id="email" type="text" formControlName='email' required class="validate" autocomplete="off"  />
              <label for="email">Email</label>
              <div *ngIf="(email.dirty || email.touched) && email.invalid">
                  <small *ngIf="email.errors.required" class="red-text"> The Email Address Is Required. </small>
              </div>
            </div>
      </div>

      <div class="row">
        <div class="input-field col s12 center">
          <button type="submit" [disabled]="!editEmail.valid" class = 'col s12 btn waves-effect green' >
            <i class="material-icons right">send</i> Update Email
          </button>
        </div>
      </div>
    </form> -->
  </div>
</div>

<div id="contactModal" class="modal">
  <div class="modal-content">
    <h4>Contact Member</h4>

    <form [formGroup]="emailForm" (ngSubmit)="Send(emailForm.value)">
      <div class="row">

        <div class="input-field col s12 l12">
          <input id="subject" type="text" formControlName='subject' required class="validate" autocomplete="off" />
          <label for="subject">Subject</label>
          <div *ngIf="(subject.dirty || subject.touched) && subject.invalid">
            <small *ngIf="subject.errors.required" class="red-text"> The Subject is required. </small>
          </div>
        </div>

        <div class="input-field col s12 l12">
          <textarea id="body" type="text" formControlName='body' required autocomplete="off"
            class="validate materialize-textarea" style="height: 175px;" maxlength="500"> </textarea>
          <label for="body">Body</label>
          <div *ngIf="(body.dirty || body.touched) && body.invalid">
            <small *ngIf="body.errors.required" class="red-text"> The Body Is Required.
            </small>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="input-field col s12 center">
          <button type="submit" [disabled]="!emailForm.valid" class='col s12 btn waves-effect green'>
            <i class="material-icons right">send</i> Send Email
          </button>
        </div>
      </div>
    </form>
  </div>
</div>

<div id="resumeModal" class="modal">
  <div class="modal-content">

    <h6><strong> Education:</strong></h6>
    <div class="row" *ngIf="educations">
      <table *ngIf="educations?.length > 0">
        <thead>
          <tr>
            <th class="center">Institution</th>
            <th class="center">Details</th>
            <th class="center">Description</th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let e of educations">
            <td class="center blue-text"><strong>{{e.Institution}} </strong></td>
            <td class="center blue-text"><strong> {{e.details}} </strong></td>
            <td class="center blue-text"><strong> {{e.description}} </strong></td>
          </tr>
        </tbody>
      </table>

      <div class=" card-panel red white-text col s12" *ngIf="educations?.length === 0">
        <p>No Education Records Found</p>
      </div>
    </div>

    <h6><strong> Certificate:</strong></h6>
    <div class="row" *ngIf="courses">
      <table *ngIf="courses?.length > 0">
        <thead>
          <tr>
            <th class="center">Course</th>
            <th class="center">Description</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let c of courses">
            <td class="center blue-text"><strong>{{c.course}} </strong></td>
            <td class="center blue-text"><strong> {{c.description}} </strong></td>
          </tr>
        </tbody>
      </table>

      <div class=" card-panel red white-text col s12" *ngIf="courses?.length === 0">
        <p>No Certificate Records Found</p>
      </div>
    </div>

    <h6><strong> Work Experience:</strong></h6>
    <div class="row" *ngIf="works">
      <table *ngIf="works?.length > 0">
        <thead>
          <tr>
            <th class="center">Job Title</th>
            <th class="center">Employer</th>
            <th class="center">Description</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let w of works">
            <td class="center blue-text">{{w.Name}}</td>
            <td class="center blue-text"> {{w.employer}}</td>
            <td class="center blue-text">{{w.description}} </td>
          </tr>
        </tbody>
      </table>

      <div class=" card-panel red white-text col s12" *ngIf="works?.length === 0">
        <p>No Work Experience Records Found</p>
      </div>
    </div>
  </div>
</div>