
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiKeyManagerService } from './apiKeyManager.Service';
import { ReferenceModel } from 'src/app/models/ReferenceModel';



@Injectable({
    providedIn: 'root'
  })
  export class ReferenceService {
  
    constructor(private httpClient: HttpClient, private keyManager: ApiKeyManagerService) { }
  
    url = environment.firstNation.apiUrl + 'api/Reference/';
  
    saveReference(model: ReferenceModel): Observable<any> {
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
      return this.httpClient.post<ReferenceModel>(this.url + 'SaveReference/', JSON.stringify(model), { headers });
    }
  
    updateReference(model: ReferenceModel): Observable<any> {
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
      return this.httpClient.post<ReferenceModel>(this.url + 'EditReference/', JSON.stringify(model), { headers });
    }
  
  
    deleteReference(model: ReferenceModel): Observable<any> {
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
      return this.httpClient.post<ReferenceModel>(this.url + 'DeleteReference/', JSON.stringify(model), { headers });
    }
  
  
    getReferenceById(id: number): Observable<ReferenceModel[]> {
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
      return this.httpClient.get<ReferenceModel[]>(this.url + 'GetReferenceByID/' + id, { headers });
    }
  

    getReferenceByResumeId(id: number): Observable<ReferenceModel[]> {
        const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
        return this.httpClient.get<ReferenceModel[]>(this.url + 'GetReferenceByResumeId/' + id, { headers });
      }
  
 
  }