import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NationSettingsService } from 'src/app/services/api/nation-settings.service';
import { FbNationSettingsService } from 'src/app/services/firebase/fb-nation-settings.service';
import { PoliticalOrganizationService } from 'src/app/services/firebase/political-organization.service';
import { TribalCouncilService } from 'src/app/services/firebase/tribal-council.service';
import { environment } from 'src/environments/environment';
import { toast } from 'materialize-css';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SocialMediaService } from 'src/app/services/firebase/social-media.service';
import { MediaService } from 'src/app/services/api/media.service';
import { DataService } from 'src/app/services/data.service';
import { createMask } from '@ngneat/input-mask';

declare var M: any;
declare var $: any;

@Component({
  selector: 'app-nation-settings',
  templateUrl: './nation-settings.component.html',
  styleUrls: ['./nation-settings.component.css']
})

export class NationSettingsComponent implements OnInit {

  themeColor: any;
  buttonType: any;
  defaultThemeColor = environment.appTheme.themeColor;
  adminThemeColor = environment.appTheme.adminThemeColor;
  public treatyNoMask = [/[0-9]/, /[0-9]/, /[0-9]/];
  // public postalCodeMask = [/[A-Za-z]/, /[0-9]/, /[A-Za-z]/, ' ', /[0-9]/, /[A-Za-z]/, /[0-9]/];
  // public phoneNumberMask = ['(', /[0-9]/, /[0-9]/, /[0-9]/, ')',
  //   ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];

  phoneInputMask = createMask<string>({
    mask: '(999) 999-9999',
    guide: true,
    showMaskTyped: true,
  });

  postalCodeMask = createMask<string>({
    mask: 'A9A 9A9' // Replace {1} with a space if desired
  });

  urlPattern = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
  loading = false;

  nationInfoForm: FormGroup;
  addLogo = false;
  imgErrorMsg: any;
  binDoc: any;
  uploadedImage: any;
  imgLink: any;
  sqlSettingsModel: any;
  fbSettingModel: any;
  socialMediaModel: any;
  councilList: any[];
  politicalOrgList: any[];
  tribalCouncil = false;
  politicalOrg = false;
  advocacy = false;
  hasSocial = false;
  selectedTCName = 'N / A';
  selectedPOName = 'N / A';
  advocacyName = 'N / A'
  nationName = '';
  socialID = '';

  country: any[];
  provinces: any[];
  cities: any[];
  states: any[];

  addForm: any;
  country_code: any;
  user: any;
  sectionName: string;
  submenus: any[];
  editName = false;

  constructor(public appSettingsService: AppSettingsService,
    private nationSettingsService: NationSettingsService,
    private tribalCouncilService: TribalCouncilService,
    private politicalOrgService: PoliticalOrganizationService,
    private router: Router,
    private fbNationSettingsService: FbNationSettingsService,
    private fb: FormBuilder,
    private mediaService: MediaService,
    private socialMediaService: SocialMediaService,
    private dataService: DataService) {
    this.themeColor = this.appSettingsService.getAppThemeColor() ? this.appSettingsService.getAppThemeColor() : this.defaultThemeColor;
    this.buttonType = this.appSettingsService.getButtonType() ? this.appSettingsService.getButtonType() : 'rectangularButton';

    this.country = this.dataService.getCountries();
    this.provinces = this.dataService.getProvince();
    this.cities = this.dataService.getCities();
    this.states = this.dataService.getStates();
  }

  ngOnInit() {
    this.appSettingsService.getMenuName('MyNation').valueChanges().subscribe(menus => {
      if (menus && menus.length > 0) {
        this.submenus = menus[0].submenus;
        let menu = this.submenus.find(m => m.name == 'NationInfo');
        if (menu) this.sectionName = menu.displayName;
      }
    });
    this.loading = true;

    this.nationSettingsService.getNationSettings().subscribe(sett => {
      if (sett) {
        this.sqlSettingsModel = sett[0];
        this.nationName = this.sqlSettingsModel.band_name;
      }

      this.fbNationSettingsService.getsettings().valueChanges().subscribe(s => {
        if (s) {
          if (s.length > 0) {
            this.fbSettingModel = s[0];

            if (this.fbSettingModel.logo) {
              this.addLogo = true;
              this.imgLink = this.fbSettingModel.logo;
            }

            this.tribalCouncil = this.fbSettingModel.tribalCouncilAffiliation;
            if (this.tribalCouncil) {
              this.tribalCouncilService.getTribalCouncilList().valueChanges().subscribe(tc => {
                if (tc) {
                  this.councilList = tc;
                  this.selectedTCName = this.fbSettingModel.tribalCouncilName;
                }
              });
            }

            this.politicalOrg = this.fbSettingModel.PoliticalOrganizationAffiliation;
            if (this.politicalOrg) {
              this.politicalOrgService.getPoliticalOrganizationList().valueChanges().subscribe(po => {
                if (po) {
                  this.politicalOrgList = po;
                  this.selectedPOName = this.fbSettingModel.PoliticalOrganizationName;
                }
              });
            }
            this.advocacy = this.fbSettingModel.advocacy;
            this.advocacyName = this.fbSettingModel.advocacyName;
          }
        }

        this.socialMediaService.getSocialMedia().valueChanges().subscribe(x => {
          if (x && x.length > 0) {
            this.socialMediaModel = x[0];
            this.hasSocial = true;
            this.socialID = x[0].id;
          } else {
            this.socialMediaModel = {
              facebook: '',
              instagram: '',
              twitter: '',
              youtube: '',
              linkedin: '',
              tiktok: ''
            }
          }

          this.nationInfoForm = this.fb.group({
            nationDetails: this.fb.group({
              nationName: [this.nationName],
              logo: [],
              PoliticalOrganizationAffiliation: [this.politicalOrg],
              PoliticalOrganizationName: [this.selectedPOName],
              tribalCouncilAffiliation: [this.tribalCouncil],
              tribalCouncilName: [this.selectedTCName],
              advocacy: [this.advocacy],
              advocacyName: [this.advocacyName],
            }),
            address: this.fb.group({
              address: [this.sqlSettingsModel.address],
              town: [this.sqlSettingsModel.Town],
              postalCode: [this.sqlSettingsModel.Postal],
              province: [this.sqlSettingsModel.Prov]
            }),
            contactInfo: this.fb.group({
              treatyNo: [this.sqlSettingsModel.treaty_no],
              telephone: [this.sqlSettingsModel.telephone],
              email: [this.sqlSettingsModel.email],
              fax: [this.sqlSettingsModel.fax]
            }),
            socialMedia: this.fb.group({
              facebook: [this.socialMediaModel ? this.socialMediaModel.facebook : '', [Validators.pattern(this.urlPattern)]],
              twitter: [this.socialMediaModel ? this.socialMediaModel.twitter : '', [Validators.pattern(this.urlPattern)]],
              instagram: [this.socialMediaModel ? this.socialMediaModel.instagram : '', [Validators.pattern(this.urlPattern)]],
              youtube: [this.socialMediaModel ? this.socialMediaModel.youtube : '', [Validators.pattern(this.urlPattern)]],
              linkedin: [this.socialMediaModel ? this.socialMediaModel.linkedin : '', [Validators.pattern(this.urlPattern)]],
              tiktok: [this.socialMediaModel ? this.socialMediaModel.tiktok : '', [Validators.pattern(this.urlPattern)]]
            })
          });

          setTimeout(() => {
            $('.tabs').tabs();
            $('select').formSelect();
            M.updateTextFields();
            $('.collapsible').collapsible();
          }, 25);
        });
      });

      this.loading = false;
    });

    setTimeout(() => {
      $('.tabs').tabs();
      $('select').formSelect();
      M.updateTextFields();
      $('.collapsible').collapsible();
    }, 25);
  }

  editNameClicked() {
    this.editName = true;
  }

  saveName() {
    let index = this.submenus.findIndex(m => m.name == 'NationInfo');
    this.submenus[index].displayName = this.sectionName;
    this.appSettingsService.UpdateMenu('MyNation', this.submenus).then((update) => {
      this.editName = false;
      toast({ html: `${update}`, classes: 'green' });
    }).catch((error) => {
      toast({ html: `${error}`, classes: 'red' });
    });
  }

  get logo() { return this.nationInfoForm.get('nationDetails.logo'); }

  get facebook() { return this.nationInfoForm.get('socialMedia.facebook'); }
  get twitter() { return this.nationInfoForm.get('socialMedia.twitter'); }
  get instagram() { return this.nationInfoForm.get('socialMedia.instagram'); }
  get youtube() { return this.nationInfoForm.get('socialMedia.youtube'); }
  get linkedin() { return this.nationInfoForm.get('socialMedia.linkedin'); }
  get tiktok() { return this.nationInfoForm.get('socialMedia.tiktok'); }

  yesClicked() {
    this.addLogo = true;
  }

  noClicked() {
    this.addLogo = false;
    if (this.binDoc) this.removeFile();
  }

  handleFileInput(files: FileList) {
    if (!this.validateImage(files[0].name)) {
      this.imgErrorMsg = 'Invalid File Type, Please Select an Image File';
      this.logo.patchValue('');
      return;
    }
    this.imgLink = null;
    this.binDoc = files[0];
    this.imgErrorMsg = undefined;
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.uploadedImage = reader.result as string;
    };
  }

  removeFile() {
    this.uploadedImage = null;
    this.imgLink = false;
    this.binDoc = undefined;
    this.logo.patchValue('');
    this.addLogo = false;
  }

  private validateImage(name: any) {
    const ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() === 'png') {
      return true;
    } else if (ext.toLowerCase() === 'jpg') {
      return true;
    } else if (ext.toLowerCase() === 'jpeg') {
      return true;
    } else if (ext.toLowerCase() === 'svg') {
      return true;
    }
    else if (ext.toLowerCase() === 'webp') {
      return true;
    } else {
      return false;
    }
  }

  tribalChange() {
    this.tribalCouncil = !this.tribalCouncil;
    if (this.tribalCouncil) {
      if (!this.councilList) {
        this.tribalCouncilService.getTribalCouncilList().valueChanges().subscribe(tc => {
          if (tc) {
            this.councilList = tc;
            setTimeout(() => {
              $('select').formSelect();
            }, 25);
          }
        })
      }
      setTimeout(() => {
        $('select').formSelect();
      }, 25);
    }
  }

  TCChange(val: any) {
    this.selectedTCName = val;
  }

  politicalOrgChange() {
    this.politicalOrg = !this.politicalOrg;
    if (this.politicalOrg) {
      if (!this.politicalOrgList) {
        this.politicalOrgService.getPoliticalOrganizationList().valueChanges().subscribe(po => {
          if (po) {
            this.politicalOrgList = po;
            setTimeout(() => {
              $('select').formSelect();
            }, 25);
          }
        })
      }
    }
    setTimeout(() => {
      $('select').formSelect();
    }, 25);
  }

  POChange(val: any) {
    this.selectedPOName = val;
  }

  advocayChange() {
    this.advocacy = !this.advocacy;
    if (this.advocacy) {
      setTimeout(() => {
        M.updateTextFields();
      }, 25);
    }
  }

  onChangeCountry(event) {
    let userProfile = this.addForm.controls["country"].value;
    const countryId = userProfile.id
    this.country_code - userProfile.code
    if (countryId) {
      this.user.getStates(countryId).subscribe(
        data => {
          this.states = data["data"]["states"];
          this.cities = null;
        }
      );
    }
    else {
      this.states = null;
      this.cities = null;
    }
  }

  onState(stateId) {
    if (stateId) {
      this.user.getStates(stateId).subscribe(
        data => {
          this.cities = data["data"]["cities"];
        }
      );
    }
    else {
      this.cities = null;
    }
  }

  saveSettings(values) {
    if (this.sqlSettingsModel) {
      this.sqlSettingsModel.Postal = values.address.postalCode ? values.address.postalCode : '';
      this.sqlSettingsModel.Prov = values.address.province ? values.address.province : '';
      this.sqlSettingsModel.Town = values.address.town ? values.address.town : '';
      this.sqlSettingsModel.address = values.address.address ? values.address.address : '';
      this.sqlSettingsModel.band_name = values.nationDetails.nationName ? values.nationDetails.nationName : '';
      this.sqlSettingsModel.fax = values.contactInfo.fax ? values.contactInfo.fax : '';
      this.sqlSettingsModel.telephone = values.contactInfo.telephone ? values.contactInfo.telephone : '';
      this.sqlSettingsModel.treaty_no = values.contactInfo.treatyNo ? values.contactInfo.treatyNo : '';
      this.sqlSettingsModel.email = values.contactInfo.email ? values.contactInfo.email : '';
    }

    this.nationSettingsService.updateNationSetting(this.sqlSettingsModel).subscribe(x => {
      if (x) {
        // Save
        if (!this.fbSettingModel) {
          var model = {
            id: '',
            tribalCouncilAffiliation: values.nationDetails.tribalCouncilAffiliation,
            tribalCouncilName: values.nationDetails.tribalCouncilName,
            PoliticalOrganizationAffiliation: values.nationDetails.PoliticalOrganizationAffiliation,
            PoliticalOrganizationName: values.nationDetails.PoliticalOrganizationName,
            advocacy: values.nationDetails.advocacy,
            advocacyName: values.nationDetails.advocacyName,
            logo: ''
          }

          if (this.binDoc) {
            this.mediaService.UploadBinImage(this.binDoc).subscribe(upload => {
              if (upload) {
                model.logo = upload.imgLink;
              }
              this.fbNationSettingsService.saveSettings(model).then(x => {
                this.updateSocials(values.socialMedia);
                toast({ html: 'Nation Settings Successfully Saved!', classes: 'green' });
                this.backToHome();
              });
            })
          } else {
            this.fbNationSettingsService.saveSettings(model).then(x => {
              this.updateSocials(values.socialMedia);
              toast({ html: 'Nation Settings Successfully Saved!', classes: 'green' });
              this.backToHome();
            });
          }
        }
        //Update
        if (this.fbSettingModel) {
          this.fbSettingModel.tribalCouncilAffiliation = values.nationDetails.tribalCouncilAffiliation;
          this.fbSettingModel.tribalCouncilName = values.nationDetails.tribalCouncilName;
          this.fbSettingModel.PoliticalOrganizationAffiliation = values.nationDetails.PoliticalOrganizationAffiliation;
          this.fbSettingModel.PoliticalOrganizationName = values.nationDetails.PoliticalOrganizationName;
          this.fbSettingModel.advocacy = values.nationDetails.advocacy;
          this.fbSettingModel.advocacyName = values.nationDetails.advocacyName;
          //this.fbSettingModel.logo = values.nationDetails.logo ? values.nationDetails.logo : ''

          if (this.binDoc) {
            this.mediaService.UploadBinImage(this.binDoc).subscribe(upload => {
              if (upload) {
                this.fbSettingModel.logo = upload.imgLink;
              }
              this.fbNationSettingsService.updateSettings(this.fbSettingModel).then(x => {
                this.updateSocials(values.socialMedia);
                toast({ html: 'Nation Settings Successfully Updated!', classes: 'green' });
                this.backToHome();
              });
            });
          } else {
            this.fbSettingModel.logo = '';
            this.fbNationSettingsService.updateSettings(this.fbSettingModel).then(x => {
              this.updateSocials(values.socialMedia);
              toast({ html: 'Nation Settings Successfully Updated!', classes: 'green' });
              this.backToHome();
            });
          }
        }
        this.dataService.updateAppName(this.sqlSettingsModel.band_name);
      }
    });
  }

  updateSocials(value: any) {
    const model: any = {
      facebook: value.facebook,
      twitter: value.twitter,
      instagram: value.instagram,
      youtube: value.youtube,
      linkedin: value.linkedin,
      tiktok: value.tiktok
    };
    if (!this.hasSocial) {
      this.socialMediaService.saveSocialMedia(model).then(resp => {
        return;
      });
    }
    if (this.hasSocial) {
      model.id = this.socialID;
      this.socialMediaService.updateSocialMedia(model).then(resp => {
        return;
      });
    }
  }

  navigate(item) {
    const routeMappings = {
      'AboutUs': 'admin/dashboard-sub-menu/about-us',
      'NationInfo': 'admin/dashboard-sub-menu/nation-settings',
      'Departments': 'admin/dashboard-sub-menu/department',
      'Governance': 'admin/dashboard-sub-menu/governance',
      'AreaOfInterest': 'admin/dashboard-sub-menu/areas-of-interest',
    };
    const route = routeMappings[item];
    if (route) {
      this.router.navigate([route]);
    }
  }

  backClicked() {
    localStorage.setItem('From', 'MyNation');
    this.router.navigate(['admin/dashboard-sub-menu']);
  }

  backToHome() {
    $('.collapsible').collapsible('close');
    this.router.navigate(['admin/dashboard']);
  }

}
