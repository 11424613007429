<div class="row">

    <div class="col l1" *ngIf="user">
        <app-side-menus [user]="user"></app-side-menus>
    </div>

    <div class="col l11 s12  purple">
        <div class="container">
            <div class="green row">
                <div class="col s11">
                    <h5>Header</h5>
                    <p class="bold-200">Clicking the "edit" button will direct you to the location where you can access
                        and
                        modify the settings for editing the content.</p>
                    <div class="header-rec row">
                        <div class="col s2 nationname">NationName</div>
                        <div class="col s1"></div>
                        <div class="col s9 right-align header-content">content</div>
                    </div>
                </div>
                <a class="col s1" (click)="editHeader()">
                    <span class="material-symbols-outlined">
                        edit
                    </span>
                </a>
            </div>

            <div class="yellow row">
                <div class="col s11">
                    <h5>Body</h5>
                    <p class="bold-200">Clicking the "edit" button will direct you to the location where you can access
                        and
                        modify the settings for editing the content.</p>
                    <div class="body-rec row">
                        <div class="col s12 ">
                            <div class="content">Slider</div>
                        </div>
                        <div class="col s3 ">
                            <div class="content">Registration / Login</div>
                        </div>
                        <div class="col s3 ">
                            <div class="content">About Us</div>
                        </div>
                        <div class="col s3 ">
                            <div class="content">Latest News</div>
                        </div>
                        <div class="col s3 ">
                            <div class="content">Upcomng Events</div>
                        </div>
                        <div class="col s3 ">
                            <div class="content">Download App Store</div>
                        </div>
                        <div class="col s3 ">
                            <div class="content">Social Media</div>
                        </div>
                        <div class="col s3 ">
                            <div class="content">Department</div>
                        </div>
                        <div class="col s3 ">
                            <div class="content">Governance</div>
                        </div>
                    </div>
                </div>

                <a class="col s1" (click)="editBody()">
                    <span class="material-symbols-outlined">
                        edit
                    </span>
                </a>
            </div>

            <div class="red row">
                <div class="col s11">
                    <h5>Footer</h5>
                    <p class="bold-200">Clicking the "edit" button will direct you to the location where you can access
                        and
                        modify the settings for editing the content.</p>
                    <div class="footer-rec row">
                        <div class="col s4">
                            <div class="title"></div>
                            <div class="list"></div>
                        </div>
                        <div class="col s4">
                            <div class="title"></div>
                            <div class="list"></div>
                        </div>
                        <div class="col s4">
                            <div class="title"></div>
                            <div class="list"></div>
                        </div>
                        <div class="col s12 ">
                            <div class="title container"></div>
                        </div>
                    </div>
                </div>
                <a class="col s1" (click)="editFooter()">
                    <span class="material-symbols-outlined">
                        edit
                    </span>
                </a>
            </div>
        </div>
    </div>
</div>