import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent implements OnInit {

  bodyBgColor: any;
  setting: any;
  defaultBgColor = '#ffffff';
  defaultThemeColor = environment.appTheme.themeColor;



  constructor(private location: Location ,  public appSettingService: AppSettingsService) {

      // =============================================   remove once added to app-menu =============
    
  }

  themeColor = environment.appTheme.themeColor;
  ngOnInit() {
  }

  backClicked() {
    this.location.back();
  }

}
