export class SupplementalSupportModel {
    id = 0;
    aid = 0;
    bdno = '';
    month = '';
    year = 0;
    document_type = 0;
    documentName = '';
    amount = 0.00;
    filestream_ID = '';
    date_scanned = null;
    base64Document = '';
    officerEmail = '';
    binDocument: any;
    // binDocument: any[];
}
