<div class="row">

  <div class="col s12 ">
    <div class="body-h">
      <div class="header-setting b-m-2 ">
        <div class="col l12 m12 s12">

          <h4 *ngIf="fromWhere === 'add'">
            <i class="material-symbols-outlined tiny bold-900 p-r-1" style="cursor: pointer;"
              (click)="cancelChiefForm()"> arrow_back </i>Add Profile
          </h4>
          <h4 *ngIf="showChiefForm && fromWhere === 'edit'">
            <i class="material-symbols-outlined tiny bold-900 p-r-1" style="cursor: pointer;"
              (click)="cancelChiefForm()">arrow_back </i>Update
            {{chiefTitle}} Profile
          </h4>

          <!-- <select class="browser-default hide-on-large-only" (change)="navigate($event.target.value)">
            <option value="AboutUs">About Us</option>
            <option value="NationInfo">Nation Info</option>
            <option value="Departments">Department</option>
            <option value="Governance" disabled selected>Governance</option>
            <option value="AreaOfInterest">Area of Interest</option>
            <option value="Board">Board</option>
          </select> -->
        </div>
      </div>

      <main class="StickyContent white">

        <div *ngIf="showChiefForm" id="profile">
          <div class="row col s12 l10 card-panel white">

            <div class="col l6 s4">
              <div class="row imgC center hide-on-small-only show-on-medium-and-up  ">
                <img *ngIf="profile_pic" [src]="profile_pic" />
                <span *ngIf="!profile_pic"
                  class="initials ">{{getInitials(selectedMemberFirstName,selectedMemberLastName)}}</span>
              </div>

              <div class="row imgC2 center hide-on-med-and-up" style="text-align: center;">
                <img *ngIf="profile_pic" [src]="profile_pic" />
                <span *ngIf="!profile_pic"
                  class="initials">{{getInitials(selectedMemberFirstName,selectedMemberLastName)}}</span>
              </div>
            </div>

            <div class="col s2">

              <div class="center  p-t-5" *ngIf="fromWhere === 'add' || showImageInput">
                <div class="col l2 s1 right" *ngIf="!isMobile">
                  <button class="btn add-button white " [ngStyle]="{'color': themeColor}"
                    (click)="hiddenfileinput.click()">
                    <i class="material-icons">edit</i>
                    Edit Photo
                  </button>

                  <input class="file-field input-field col s12 center" type="file" style="display:none" accept="image/*"
                    (change)="handleFileInput($event.target.files)" #hiddenfileinput>
                </div>

                <div class="col s12 center" *ngIf="isMobile">
                  <button class="btn-floating btn-small  green  center" (click)="mobilePicture();"
                    style="margin-right: 15px;"><i class="material-icons">camera_alt</i></button>
                  <button class="btn-floating btn-small  blue " (click)="libraryPicture();"><i
                      class="material-icons">photo_library</i></button>
                </div>
              </div>

              <div class="input-field col s12 center" *ngIf="fromWhere === 'edit' && !showImageInput">
                <a class="btn-floating btn-small red modal-trigger" (click)="deletePhoto()"
                  href="#confirmDeleteModal"><i class="material-icons">delete</i></a>
              </div>
            </div>
          </div>
        </div>

        <div class="row col s12">
          <div class="col s12 l10">
            <form autocomplete="off" [formGroup]="searchMemberForm" novalidate *ngIf="showSearch">
              <div class="row card-panel white darken-2">
                <h6 class="sub-title-section2 bold-500">Please Enter The First Or Last Name To Search In The Database
                </h6>

                <div class="input-field col s12 m6">
                  <div class="label-input">Last Name</div>
                  <input id="lastName" type="text" formControlName='lastName' class="validate" autocomplete="off"
                    placeholder="Last Name" />
                </div>

                <div class="input-field col s12 m6">
                  <div class="label-input">First Name</div>
                  <input id="firstName" type="text" formControlName='firstName' class="validate" autocomplete="off"
                    placeholder="First Name" />
                </div>
              </div>

              <div class="row card-panel white darken-2" *ngIf="showSearchTable">
                <div class="card-body news-list">
                  <div class="table-responsive">
                    <table datatable [dtOptions]="dtOptions" *ngIf="processing"
                      class="row-border hover notification-table centered striped" width="100%">
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Last Name</th>
                          <th>First Name</th>
                        </tr>
                      </thead>
                      <tbody *ngIf="employeesListData?.length !== undefined">
                        <tr *ngFor="let item of employeesListData; let i = index" (click)="setFormDetails(item)"
                          class="employeesListData" style="cursor: hand;">
                          <td>{{item?.applicantID}}</td>
                          <td>{{item?.LastName}}</td>
                          <td>{{item?.GivenName}}</td>
                        </tr>
                      </tbody>

                    </table>
                  </div>
                </div>
              </div>
            </form>

            <div *ngIf="showChiefForm">
              <form [formGroup]="addChiefForm">
                
                <div class="row">
                  <div class="row card-panel white">
                    <h6 style="margin-bottom: 15px; margin-top: 15px; font-weight: 600;">{{chiefTitle}} Information
                    </h6>
                    <div class="input-field col s12 l6">
                      <div class="label-input">First Name</div>
                      <input id="firstName" type="text" formControlName='firstName' class="validate" autocomplete="off"
                        placeholder="First Name" />
                    </div>

                    <div class="input-field col s12 l6">
                      <div class="label-input">Last Name</div>
                      <input id="lastName" type="text" formControlName='lastName' class="validate"
                        placeholder="Last Name" />
                    </div>

                    <div class="input-field col s12 l6">
                      <div class="label-input">Display Name</div>
                      <input id="displayName" type="text" formControlName='displayName' placeholder="Display Name"
                        class="validate" />
                    </div>

                    <div class="input-field col s12 l7">
                      <div class="label-input">Email <span class="red-text">(*)</span></div>
                      <input id="email" type="email" formControlName='email' class="validate" autocomplete="off" />
                      <div *ngIf="(getEmail.dirty || getEmail.touched) && getEmail.invalid">
                        <small *ngIf="getEmail.errors.required" class="red-text"> The Email is required. </small>
                        <small *ngIf="getEmail.errors.email" class="red-text"> wrong email format. </small>
                      </div>
                    </div>

                    <div class="input-field col s12 l5">
                      <div class="label-input">Website</div>
                      <input id="website" formControlName='website' type="text" class="validate">
                      <div *ngIf="(website.dirty || website.touched) && website.invalid">
                        <small *ngIf="website.errors.pattern" class="red-text"> Invalid web address. </small>
                      </div>
                    </div>

                    <div class="input-field col s12 l6">
                      <div class="label-input">Office Phone </div>
                      <!-- <input id="office_phone" type="text" formControlName='office_phone' class="validate"
                        autocomplete="off" [textMask]="{mask: phoneNumberMask}" /> -->
                        <input id="office_phone" type="text" [inputMask]="phoneInputMask" formControlName='office_phone' class="validate"
                        autocomplete="off" />
                      <div *ngIf="(getOffice_phone.dirty || getOffice_phone.touched) && getOffice_phone.invalid">
                        <small *ngIf="getOffice_phone.errors.required" class="red-text"> The Office Phone is required.
                        </small>
                      </div>
                    </div>

                    <div class="input-field col s12 l6">
                      <div class="label-input">Mobile Phone </div>
                      <!-- <input id="cell_phone" type="text" formControlName='cell_phone' class="validate"
                        autocomplete="off" [textMask]="{mask: phoneNumberMask}" /> -->
                        <input id="cell_phone" type="text" [inputMask]="phoneInputMask" formControlName='cell_phone' class="validate"
                        autocomplete="off"  />
                      <div *ngIf="(getCell_phone.dirty || getCell_phone.touched) && getCell_phone.invalid">
                        <small *ngIf="getCell_phone.errors.required" class="red-text"> The Mobile Phone is required.
                        </small>
                      </div>
                    </div>

                    <div class="input-field col s12">
                      <div class="label-input"> {{chiefTitle}}'s Bio</div>
                      <textarea id="chiefBio" type="text" formControlName='chiefBio' placeholder="Bio ..."
                        class="materialize-textarea validate"> </textarea>
                    </div>
                  </div>

                  <div class="row card-panel white">
                    <div class="input-field class col s11 l7">
                      <div class="label-input">Elected on</div>
                      <input id="appointmentDate" type="text" formControlName='appointmentDate'
                        class="input-box validate" autocomplete="off" angular-mydatepicker name="mydate"
                        (click)="dp1.toggleCalendar()" [options]="myDpOptions" #dp1="angular-mydatepicker"
                        (dateChanged)="onAppointmentDateChange($event)" placeholder="Mandate Ends On" />
                    </div>
                    <div class="col s1">
                      <div class="row center">
                        <br />
                        <i class="material-icons green-text" (click)=dp1.clearDate()>clear</i>
                      </div>
                    </div>

                    <div class="input-field class col s11 l7">
                      <div class="label-input">Mandate Ends On</div>
                      <input formControlName="expiryDate" id="expiryDate" class="validate input-box" type="text"
                        autocomplete="off" angular-mydatepicker name="mydate" (click)="dp2.toggleCalendar()"
                        [options]="myDpOptions" #dp2="angular-mydatepicker" (dateChanged)="onExpiryDateChange($event)"
                        placeholder="Mandate Ends On" />
                    </div>
                    <div class="col s1">
                      <div class="row center">
                        <br />
                        <i class="material-icons green-text" (click)=dp2.clearDate()>clear</i>
                      </div>
                    </div>
                  </div>


                  <div class="row card-panel white">

                    <!--  facebook  -->
                    <div class="input-field col s12 l10">
                      <div class="label-input">{{chiefTitle}}'s Facebook account</div>
                      <input id="chiefFacebook" type="text" formControlName='chiefFacebookAcc'
                        class="materialize-textarea" placeholder="Facebook Account">
                      <div
                        *ngIf="(getChiefFacebookAcc?.dirty || getChiefFacebookAcc?.touched) && getChiefFacebookAcc?.invalid">
                        <small *ngIf="getChiefFacebookAcc.errors.pattern" class="red-text"> Wrong Facebook url format
                        </small>
                      </div>
                    </div>

                    <!--  instagram  -->
                    <div class="input-field col s12 l10">
                      <div class="label-input">{{chiefTitle}}'s Instagram account</div>
                      <input id="chiefInsta" type="text" formControlName='chiefInstagramAcc'
                        class="materialize-textarea" placeholder="Instagram Account">
                      <div
                        *ngIf="(getChiefInstagramAcc?.dirty || getChiefInstagramAcc?.touched) && getChiefInstagramAcc?.invalid">
                        <small *ngIf="getChiefInstagramAcc.errors.pattern" class="red-text"> Wrong Instagram url format
                        </small>
                      </div>
                    </div>

                    <!--  twitter  -->
                    <div class="input-field col s12 l10">
                      <div class="label-input"> {{chiefTitle}}'s twitter account</div>
                      <input id="chiefTwitter" type="text" formControlName='chiefTwitterAcc'
                        class="materialize-textarea" placeholder="Twitter Account">
                      <div
                        *ngIf="(getChiefTwitterAcc?.dirty || getChiefTwitterAcc?.touched) && getChiefTwitterAcc?.invalid">
                        <small *ngIf="getChiefTwitterAcc.errors.pattern" class="red-text"> Wrong Twitter url format
                        </small>
                      </div>
                    </div>

                    <!--  linked in  -->
                    <div class="input-field col s12 l10">
                      <div class="label-input">{{chiefTitle}}'s linkedin account</div>
                      <input id="chiefLinkedin" type="text" formControlName='chiefLinkedinAcc'
                        class="materialize-textarea" placeholder="LinkedIn Account">
                      <div
                        *ngIf="(getChiefLinkedinAcc?.dirty || getChiefLinkedinAcc?.touched) && getChiefLinkedinAcc?.invalid">
                        <small *ngIf="getChiefLinkedinAcc.errors.pattern" class="red-text"> Wrong Linkedin url format
                        </small>
                      </div>
                    </div>

                    <!--  youtube  -->
                    <div class="input-field col s12 l10">
                      <div class="label-input"> {{chiefTitle}}'s youtube account</div>
                      <input id="chiefYoutube" type="text" formControlName='chiefYoutubeAcc'
                        class="materialize-textarea" placeholder="Youtube Account">
                      <div
                        *ngIf="(getChiefYoutubeAcc?.dirty || getChiefYoutubeAcc?.touched) && getChiefYoutubeAcc?.invalid">
                        <small *ngIf="getChiefYoutubeAcc.errors.pattern" class="red-text"> Wrong Youtube url format
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              </form>

              <div class="row card-panel white">
                <h6 style="margin-bottom: 15px; margin-top: 15px; font-weight: 600;">Contact Settings</h6>

                <div class="col s12">
                  <p><strong>Show Mobile Phone</strong></p>
                  <div class="col s12 m6 l4">
                    <label>
                      <input type="radio" name="group1" [checked]="showMobileNo === 'Public'"
                        (click)="publicMobileClick()" />
                      <span><strong>General Public</strong></span>
                    </label>
                  </div>
                  <div class="col s12 m6 l4">
                    <label>
                      <input type="radio" name="group1" [checked]="showMobileNo === 'Members'"
                        (click)="membersMobileClick()" />
                      <span class="text"><strong>Members Only</strong></span>
                    </label>
                  </div>
                  <div class="col s12 m6 l4">
                    <label>
                      <input type="radio" name="group1" [checked]="showMobileNo === 'Private'"
                        (click)="privateMobileClick()" />
                      <span class="red-text"><strong>Do Not Show </strong></span>
                    </label>
                  </div>

                </div>

                <div class="col s12">
                  <p><strong>Show Office Phone</strong></p>
                  <div class="col s12 m6 l4">
                    <label>
                      <input type="radio" value="Yes" name="group0" [checked]="showOfficeNo === 'Public'"
                        (click)="publicOfficeClick()" />
                      <span class="text"><strong>General Public</strong></span>
                    </label>
                  </div>
                  <div class="col s12 m6 l4">
                    <label>
                      <input type="radio" value="No" name="group0" [checked]="showOfficeNo === 'Members'"
                        (click)="membersOfficeClick()" />
                      <span class="text"><strong>Members Only</strong></span>
                    </label>
                  </div>

                  <div class="col s12 m6 l4">
                    <label>
                      <input type="radio" name="group0" [checked]="showOfficeNo === 'Private'"
                        (click)="privateOfficeClick()" />
                      <span class="red-text"><strong>Do Not Show</strong></span>
                    </label>
                  </div>

                </div>

                <div class="col s12">
                  <p><strong>Show Email Address</strong></p>
                  <div class="col s12 m6 l4">
                    <label>
                      <input type="radio" name="group2" [checked]="showEmailAdr === 'Public'"
                        (click)="publicEmailClick()" />
                      <span class="text"><strong>General Public</strong></span>
                    </label>
                  </div>
                  <div class="col s12 m6 l4">
                    <label>
                      <input type="radio" name="group2" [checked]="showEmailAdr === 'Members'"
                        (click)="membersEmailClick()" />
                      <span class="blue-text"><strong>Members Only</strong></span>
                    </label>
                  </div>
                  <div class="col s12 m6 l4">
                    <label>
                      <input type="radio" name="group2" [checked]="showEmailAdr === 'Private'"
                        (click)="privateEmailClick()" />
                      <span class="red-text"><strong>Do Not Show</strong></span>
                    </label>
                  </div>
                </div>

                <div class="col s12">
                  <p><strong>Allow Contact Us Messages </strong></p>
                  <div class="col s12 m6 l4">
                    <label>
                      <input type="radio" name="group3" [checked]="allowContact === 'Public'"
                        (click)="publicContactClick()" />
                      <span class="text"><strong>General Public</strong></span>
                    </label>
                  </div>
                  <div class="col s12 m6 l4">
                    <label>
                      <input type="radio" name="group3" [checked]="allowContact === 'Members'"
                        (click)="membersContactClick()" />
                      <span class="text"><strong>Members Only</strong></span>
                    </label>
                  </div>
                  <div class="col s12 m6 l4">
                    <label>
                      <input type="radio" name="group3" [checked]="allowContact === 'Private'"
                        (click)="privateContactClick()" />
                      <span class="red-text"><strong>Do Not Allow</strong></span>
                    </label>
                  </div>
                </div>

                <!-- facebook -->
                <div class="col s12">

                  <p><strong>Show Facebook Account</strong></p>
                  <div class="col s12 m6 l4">
                    <label>
                      <input type="radio" name="showChiefFacebookGroup" [checked]="showFacebookAcc === 'Public'"
                        (click)="publicFacebookClick()" />
                      <span><strong>General Public</strong></span>
                    </label>
                  </div>
                  <div class="col s12 m6 l4">
                    <label>
                      <input type="radio" name="showChiefFacebookGroup" [checked]="showFacebookAcc === 'Members'"
                        (click)="membersFacebookClick()" />
                      <span class="text"><strong>Members Only</strong></span>
                    </label>
                  </div>
                  <div class="col s12 m6 l4">
                    <label>
                      <input type="radio" name="showChiefFacebookGroup" [checked]="showFacebookAcc === 'Private'"
                        (click)="privateFacebookClick()" />
                      <span class="red-text"><strong>Do Not Show </strong></span>
                    </label>
                  </div>

                </div>

                <!-- instagram -->
                <div class="col s12">

                  <p><strong>Show Instagram Account</strong></p>
                  <div class="col s12 m6 l4">
                    <label>
                      <input type="radio" name="ShowChiefInstaGroup" [checked]="showInstagramAcc === 'Public'"
                        (click)="publicInstagramClick()" />
                      <span><strong>General Public</strong></span>
                    </label>
                  </div>
                  <div class="col s12 m6 l4">
                    <label>
                      <input type="radio" name="ShowChiefInstaGroup" [checked]="showInstagramAcc === 'Members'"
                        (click)="membersInstagramClick()" />
                      <span class="text"><strong>Members Only</strong></span>
                    </label>
                  </div>
                  <div class="col s12 m6 l4">
                    <label>
                      <input type="radio" name="ShowChiefInstaGroup" [checked]="showInstagramAcc === 'Private'"
                        (click)="privateInstagramClick()" />
                      <span class="red-text"><strong>Do Not Show </strong></span>
                    </label>
                  </div>

                </div>

                <!-- twitter -->
                <div class="col s12">

                  <p><strong>Show Twitter Account</strong></p>
                  <div class="col s12 m6 l4">
                    <label>
                      <input type="radio" name="showChiefTwitterGroup" [checked]="showTwitterAcc === 'Public'"
                        (click)="publicTwitterClick()" />
                      <span><strong>General Public</strong></span>
                    </label>
                  </div>
                  <div class="col s12 m6 l4">
                    <label>
                      <input type="radio" name="showChiefTwitterGroup" [checked]="showTwitterAcc === 'Members'"
                        (click)="membersTwitterClick()" />
                      <span class="text"><strong>Members Only</strong></span>
                    </label>
                  </div>
                  <div class="col s12 m6 l4">
                    <label>
                      <input type="radio" name="showChiefTwitterGroup" [checked]="showTwitterAcc === 'Private'"
                        (click)="privateTwitterClick()" />
                      <span class="red-text"><strong>Do Not Show </strong></span>
                    </label>
                  </div>

                </div>

                <!-- linkedin -->
                <div class="col s12">

                  <p><strong>Show Linkedin Account</strong></p>
                  <div class="col s12 m6 l4">
                    <label>
                      <input type="radio" name="showChiefLinkedinGroup" [checked]="showLinkedinAcc === 'Public'"
                        (click)="publicLinkedinClick()" />
                      <span><strong>General Public</strong></span>
                    </label>
                  </div>
                  <div class="col s12 m6 l4">
                    <label>
                      <input type="radio" name="showChiefLinkedinGroup" [checked]="showLinkedinAcc === 'Members'"
                        (click)="membersLinkedinClick()" />
                      <span class="text"><strong>Members Only</strong></span>
                    </label>
                  </div>
                  <div class="col s12 m6 l4">
                    <label>
                      <input type="radio" name="showChiefLinkedinGroup" [checked]="showLinkedinAcc === 'Private'"
                        (click)="privateLinkedinClick()" />
                      <span class="red-text"><strong>Do Not Show </strong></span>
                    </label>
                  </div>

                </div>

                <!-- youtube -->
                <div class="col s12">

                  <p><strong>Show Youtube Account</strong></p>
                  <div class="col s12 m6 l4">
                    <label>
                      <input type="radio" name="showChiefYoutubeGroup" [checked]="showYoutubeAcc === 'Public'"
                        (click)="publicYoutubeClick()" />
                      <span><strong>General Public</strong></span>
                    </label>
                  </div>
                  <div class="col s12 m6 l4">
                    <label>
                      <input type="radio" name="showChiefYoutubeGroup" [checked]="showYoutubeAcc === 'Members'"
                        (click)="membersYoutubeClick()" />
                      <span class="text"><strong>Members Only</strong></span>
                    </label>
                  </div>
                  <div class="col s12 m6 l4">
                    <label>
                      <input type="radio" name="showChiefYoutubeGroup" [checked]="showYoutubeAcc === 'Private'"
                        (click)="privateYoutubeClick()" />
                      <span class="red-text"><strong>Do Not Show </strong></span>
                    </label>
                  </div>

                </div>

                <div class="row">
                  <br />
                </div>
              </div>

              <div class="row   card-panel white">
                <h6 class="center" style="margin-bottom: 15px; margin-top: 15px;"><strong>Portfolio</strong></h6>
                <div class="row">
                  <ul class="collection col s12" *ngIf="departmentsOptions">

                    <li class="collection-item" *ngFor="let item of departmentsOptions">
                      <div>
                        <label>
                          <input type="checkbox" class="filled-in" [(ngModel)]="item.isSelected" value="item.id" />
                          <span [innerHTML]="item.name"></span>
                        </label>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="section"></div>

            <button *ngIf="showSearch" title="Search" (click)="onSearchEmployees()" [ngClass]="'btn ' + buttonType"
              [ngStyle]="{'background-color': themeColor}"
              [disabled]="searchMemberForm.get('firstName').invalid && searchMemberForm.get('lastName').invalid"
              class="col s12 btn waves-effect" style="margin-bottom: 15px;">
              <i class="material-icons right">search</i> Find In The Database
            </button>

            <button *ngIf="showChiefForm && fromWhere === 'add'" class="col s12 btn waves-effect" title="Save"
              [ngClass]="'btn ' + buttonType" [ngStyle]="{'background-color': themeColor}"
              [disabled]="!addChiefForm.valid" (click)="addChiefData()" style="margin-bottom: 15px;"><i
                class="material-icons right">cloud_upload</i>Save {{chiefTitle}}</button>

            <button *ngIf="showChiefForm && fromWhere === 'edit'" class="col s12 btn waves-effect" title="Save"
              [ngClass]="'btn ' + buttonType" [ngStyle]="{'background-color': themeColor}"
              [disabled]="!addChiefForm.valid" (click)="addChiefData()" style="margin-bottom: 15px;"><i
                class="material-icons right">cloud_upload</i>Update {{chiefTitle}}</button>

            <button *ngIf="showChiefForm && fromWhere === 'edit'" class="col s12 btn waves-effect red modal-trigger"
              type="button" [ngClass]="'btn ' + buttonType" title="Delete Chief" (click)="deleteChief()"
              data-target="confirmDeleteModal" style="margin-bottom: 15px;"><i
                class="material-icons right">delete</i>Delete {{chiefTitle}}</button>

          </div>
          <div class="col l1 "></div>
        </div>

        <div id="detailModal" class="modal" *ngIf="selected_member">
          <div class="modal-content">
            <h6>Selected Member</h6>
            <br />
            <div class="row">
              <ul class="collection">
                <li class="collection-item">
                  <div> First Name: <span class="secondary-content"> {{selected_member.GivenName}} </span></div>
                </li>
                <li class="collection-item">
                  <div> Last Name: <span class="secondary-content"> {{selected_member.LastName}} </span></div>
                </li>
                <li class="collection-item">
                  <div> DOB: <span class="secondary-content"> {{selected_member.DOB | date}} </span></div>
                </li>
                <li class="collection-item">
                  <div> Address: <span class="secondary-content"> {{selected_member.MailAddress}} </span></div>
                </li>
                <li class="collection-item">
                  <div> City: <span class="secondary-content"> {{selected_member.city}} </span></div>
                </li>
                <li class="collection-item">
                  <div> Province: <span class="secondary-content"> {{selected_member.province}} </span></div>
                </li>
                <li class="collection-item">
                  <div> Postal Code: <span class="secondary-content"> {{selected_member.PostalCode}} </span></div>
                </li>
              </ul>
            </div>
          </div>
          <div class="modal-footer">
            <a (click)="selectClick()" class="modal-close green btn-flat white-text"> <i class="material-icons">done</i>
              Select</a>
          </div>
        </div>

        <div id="confirmDeleteModal" class="modal">
          <div class="modal-content">
            <h5 class="bold-700" *ngIf="deleteItem == 'Photo'">Delete Photo</h5>
            <h5 class="bold-700" *ngIf="deleteItem == 'Chief'">Delete {{chiefTitle}}</h5>
            <div class="valign-wrapper card-panel red lighten-5">
              <i class="material-icons left red-text" style="font-size: 63px;">
                warning
              </i>
              <div *ngIf="deleteItem == 'Photo'">
                <h6>Are You sure you want to delete this photo? </h6>
                <h6><strong>The photo will be permanently deleted. This action cannot be reversed.</strong></h6>
              </div>

              <div *ngIf="deleteItem == 'Chief'">
                <h6>Are You sure you want to delete the {{chiefTitle}}? </h6>
                <h6><strong>This will permanently remove the {{chiefTitle}}'s information.This action is irreversible.</strong>
                </h6>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <a class="modal-close waves-effect waves-light btn-flat"><strong>Cancel</strong></a>
            <a (click)="removeSavedFile()" *ngIf="deleteItem == 'Photo'"
              class="modal-close btn waves-effect waves-light red"><strong>Delete</strong></a>
            <a (click)="archiveChief()" *ngIf="deleteItem == 'Chief'"
              class="modal-close btn waves-effect waves-light red"><strong>Delete</strong></a>
          </div>
        </div>

      </main>
    </div>
  </div>
</div>