<div class="row center-align">
    <h5><strong> Registration Request Shared </strong></h5>
</div>

<app-firebase-loading *ngIf="loading"></app-firebase-loading>

<div class="row col s12">
    <div class="col l1"></div>
    <div class="col s12 m12 l10">

        <div *ngIf="step1">
            <div *ngIf="!loading && (!registrationRequests || registrationRequests.length === 0)"
                class="row card-panel blue lighten-2">
                <h6 class="white-text center-align">
                    <strong>
                        No Pending Registration Request In The Database.
                    </strong>
                </h6>
            </div>

            <div class="row card-panel white" *ngIf="registrationRequests && registrationRequests.length > 0">
                <table class="striped highlight row-border hover">
                    <thead>
                        <tr>
                            <th>Last Name</th>
                            <th>First Name</th>
                            <th>Date</th>
                            <th>Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr style="margin-bottom: 25px;" *ngFor="let req of registrationRequests"
                            (click)="showReqDetails(req)">
                            <td><a style="cursor: pointer;">{{req.Lname}}</a></td>
                            <td><a style="cursor: pointer;">{{req.Fname}}</a></td>
                            <td>{{req.datetime.toDate() | date: 'dd/MMM/yyyy'}}</td>
                            <td
                                [ngClass]="{'1': 'amber-text' ,'2': 'blue-text', '3' : 'green-text', '4': 'red-text', '5':'grey-text'}[req.status]">
                                <strong>{{Status[req.status-1]}}</strong>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <a [ngClass]="'col s12 btn waves-effect ' + themeColor" (click)="backClicked()"><i
                    class="material-icons left">house</i> Back To Previouse Page</a>
        </div>

        <div *ngIf="step2">
            <h5><strong>Request Detail </strong></h5>

            <div *ngIf="request">
                <div class="row collection">
                    <a class="collection-item">
                        <div>Last Name:<a class="secondary-content blue-text"><strong>{{request.Lname}}</strong> </a>
                        </div>
                    </a>
                    <a class="collection-item">
                        <div>Middle Name:<a class="secondary-content blue-text"><strong>{{request.Mname}}</strong> </a>
                        </div>
                    </a>
                    <a class="collection-item">
                        <div>First Name:<a class="secondary-content blue-text"><strong>{{request.Fname}}</strong> </a>
                        </div>
                    </a>
                    <a class="collection-item">
                        <div>Email:<a class="secondary-content blue-text"><strong>{{request.email}}</strong> </a></div>
                    </a>
                    <a class="collection-item" *ngIf="request.dob">
                        <div>Date of Birth:<a
                                class="secondary-content blue-text"><strong>{{request.dob}}</strong> </a>
                        </div>
                    </a>
                    <a class="collection-item" *ngIf="!request.dob">
                        <div>Date of Birth:<a class="secondary-content blue-text"><strong></strong> </a>
                        </div>
                    </a>
                    <a class="collection-item">
                        <div>Gender:<a class="secondary-content blue-text"><strong>{{request.gender}}</strong> </a>
                        </div>
                    </a>
                    <a class="collection-item">
                        <div>Mobile:<a class="secondary-content blue-text"><strong>{{request.cellPhone}}</strong> </a>
                        </div>
                    </a>
                    <a class="collection-item">
                        <div>ISN:<a class="secondary-content blue-text"><strong>{{request.isn}}</strong> </a></div>
                    </a>
                    <a class="collection-item">
                        <div>Address:<a class="secondary-content blue-text"><strong>{{request.address}}</strong> </a>
                        </div>
                    </a>
                    <a class="collection-item">
                        <div>Province:<a class="secondary-content blue-text"><strong>{{request.province}}</strong> </a>
                        </div>
                    </a>
                    <a class="collection-item">
                        <div>Community:<a class="secondary-content blue-text"><strong>{{request.Community}}</strong>
                            </a>
                        </div>
                    </a>
                    <a class="collection-item">
                        <div>City:<a class="secondary-content blue-text"><strong>{{request.city}}</strong> </a></div>
                    </a>
                    <a class="collection-item">
                        <div>Postal Code:<a class="secondary-content blue-text"><strong>{{request.postalCode}}</strong>
                            </a>
                        </div>
                    </a>
                    <a class="collection-item">
                        <div>SIN:<a class="secondary-content blue-text"><strong>{{request.sin}}</strong> </a></div>
                    </a>
                    <a class="collection-item">
                        <div>Request Date:<a class="secondary-content blue-text"><strong>{{request?.datetime.toDate() |
                                    date: 'dd/MMM/yyyy'}}</strong> </a></div>
                    </a>
                </div>

                <div class="section"></div>

                <div class="row">
                    <a *ngIf="request.status == 1 || request.status == 4" class="col s12 btn waves-effect green"
                        (click)="onApprove()" style="margin-bottom: 15px;"><i
                            class="material-icons right">thumb_up_alt</i><strong>Approve
                            Request</strong></a>

                    <a *ngIf="request.status == 1" class="col s12 btn waves-effect orange" (click)="onDecline()"
                        style="margin-bottom: 15px;"><i class="material-icons right">thumb_down_alt</i><strong>Decline
                            Request</strong></a>

                    <a class="col s12 btn waves-effect red" (click)="OnDelete()" style="margin-bottom: 15px;"><i
                            class="material-icons right">delete</i><strong>Delete Request</strong></a>
                </div>

                <div id="deleteConfirmation" class="modal">
                    <div class="modal-content">
                        <h4>Delete Request</h4>
                        <p>Are You sure you want to permanetly delete this request? </p>
                    </div>
                    <div class="modal-footer">
                        <a (click)="DeleteRequest()"
                            class="modal-close waves-effect waves-green btn-flat"><strong>Delete</strong></a>
                        <a class="modal-close waves-effect waves-red btn-flat"><strong>Cancel</strong></a>
                    </div>
                </div>

                <div id="declinModal" class="modal">
                    <div class="modal-content">
                        <h4>Decline Request</h4>
                        <p>Please State why the Request has been declined.</p>
                        <div class="input-field col s12">
                            <textarea id="reason" [(ngModel)]="declineReason" class="materialize-textarea" rows="2"
                                style="height: 75px;"></textarea>
                            <label for="reason">Reason</label>
                        </div>
                        <div class="row">
                            <button class="col s12 btn waves-effect red" style="margin-bottom: 15px;"
                                (click)="Decline()" [disabled]="declineReason == ''">
                                <i class="material-icons right">thumb_down</i> Decline Request
                            </button>

                            <button class=" modal-close  col s12 btn-flat waves-effect blue-grey lighten-4"
                                style="margin-bottom: 15px;">
                                <i class="material-icons right">cancel</i> Close
                            </button>
                        </div>
                        <!-- <div class="input-field col s12">
                        <input placeholder="Reason for Declining" type="text" class="validate">
                        <label>Reason</label>
                      </div> -->
                    </div>

                </div>
            </div>

            <a [ngClass]="'col s12 btn waves-effect ' + themeColor" (click)="backToList()"><i
                    class="material-icons left">house</i> Back To List of Requests</a>
        </div>

        <div *ngIf="step3">
            <div class="row">
                <form [formGroup]="inviteMemberForm" (ngSubmit)="inviteEmployee(inviteMemberForm.value)">

                    <div class="row card-panel white">
                        <h6 class="center" style="margin-bottom: 25px; margin-top: 15px;"><strong>Employee
                                Information</strong></h6>

                        <div class="input-field col s12">
                            <i class="material-icons prefix teal-text">portrait</i>
                            <input id="fnme" type="text" [value]="selectedMember.GivenName" disabled />
                        </div>

                        <div class="input-field col s12">
                            <i class="material-icons prefix blue-text">portrait</i>
                            <input id="lnme" type="text" [value]="selectedMember.LastName" disabled />
                        </div>

                        <div class="input-field col s12 l12">
                            <i class="material-icons prefix pink-text">work</i>
                            <input id="jobTitle" type="text" formControlName='jobTitle'  class="validate"
                                autocomplete="off" />
                            <label for="jobTitle">Job Title</label>
                            <div *ngIf="(jobTitle.dirty || jobTitle.touched) && jobTitle.invalid">
                                <small *ngIf="jobTitle.errors.required" class="red-text"> The Job Title is required.
                                </small>
                            </div>
                        </div>

                        <div class="input-field col s12">
                            <i class="material-icons prefix pink-text">email</i>
                            <input id="email" type="text" formControlName='email' required class="validate"
                                autocomplete="off" />
                            <label for="email">Email<span class="red-text">*</span></label>
                            <div *ngIf="(email.dirty || email.touched) && email.invalid">
                                <small *ngIf="email.errors.required" class="red-text"> Email is required. </small>
                                <small *ngIf="email.errors.email" class="red-text"> wrong email format. </small>
                            </div>
                        </div>

                        <div class="input-field col s12 l12">
                            <i class="material-icons prefix teal-text">call</i>
                            <!-- <input id="workPhone" type="text" formControlName='workPhone'
                                [textMask]="{mask: phoneNumberMask}" class="validate" autocomplete="off" /> -->
                                <input id="workPhone" type="text" formControlName='workPhone'
                                 class="validate" autocomplete="off" />
                            <label for="workPhone">Work Phone</label>
                            <div *ngIf="(workPhone.dirty || workPhone.touched) && workPhone.invalid">
                                <small *ngIf="workPhone.errors.required" class="red-text"> The Work Phone is required.
                                </small>
                            </div>
                        </div>

                        <div class="input-field col s12 l12">
                            <i class="material-icons prefix purple-text text-darken-2">stay_current_portrait</i>
                            <!-- <input id="mobilePhone" type="text" [textMask]="{mask: phoneNumberMask}"
                                formControlName='mobilePhone' class="validate" autocomplete="off" /> -->
                                <input id="mobilePhone" type="text" 
                                formControlName='mobilePhone' class="validate" autocomplete="off" />
                            <label for="mobilePhone">Mobile Phone</label>
                            <div *ngIf="(mobilePhone.dirty || mobilePhone.touched) && mobilePhone.invalid">
                                <small *ngIf="mobilePhone.errors.required" class="red-text"> The Mobile Phone is
                                    required. </small>
                            </div>
                        </div>

                        <div class="input-field col s12 l12">
                            <i class="material-icons prefix green-text">description</i>
                            <textarea id="description" formControlName='description'
                                class=" validate materialize-textarea" autocomplete="off"></textarea>
                            <label for="description">Responsibility</label>
                            <div *ngIf="(description.dirty || description.touched) && description.invalid">
                                <small *ngIf="description.errors.required" class="red-text"> The Job Description is
                                    required. </small>
                            </div>
                        </div>
                    </div>

                    <div class="row card-panel white b-m-2">
                        <h6 class="center" style="margin-bottom: 15px; margin-top: 15px;"><strong>Employee Contact
                                Settings</strong></h6>

                        <div class="col s12">
                            <p><strong>Show Mobile Phone</strong></p>
                            <div class="col s12 m6 l4">
                                <label>
                                    <input type="radio" name="group1" [checked]="showMobileNo === 'Public'"
                                        (click)="publicMobileClick()" />
                                    <span><strong>General Public</strong></span>
                                </label>
                            </div>
                            <div class="col s12 m6 l4">
                                <label>
                                    <input type="radio" name="group1" [checked]="showMobileNo === 'Members'"
                                        (click)="membersMobileClick()" />
                                    <span class="text"><strong>Members Only</strong></span>
                                </label>
                            </div>
                            <div class="col s12 m6 l4">
                                <label>
                                    <input type="radio" name="group1" [checked]="showMobileNo === 'Private'"
                                        (click)="privateMobileClick()" />
                                    <span class="red-text"><strong>Do Not Show </strong></span>
                                </label>
                            </div>
                        </div>

                        <div class="col s12">
                            <p><strong>Show Office Phone</strong></p>
                            <div class="col s12 m6 l4">
                                <label>
                                    <input type="radio" value="Yes" name="group0" [checked]="showOfficeNo === 'Public'"
                                        (click)="publicOfficeClick()" />
                                    <span class="text"><strong>General Public</strong></span>
                                </label>
                            </div>
                            <div class="col s12 m6 l4">
                                <label>
                                    <input type="radio" value="No" name="group0" [checked]="showOfficeNo === 'Members'"
                                        (click)="membersOfficeClick()" />
                                    <span class="text"><strong>Members Only</strong></span>
                                </label>
                            </div>

                            <div class="col s12 m6 l4">
                                <label>
                                    <input type="radio" name="group0" [checked]="showOfficeNo === 'Private'"
                                        (click)="privateOfficeClick()" />
                                    <span class="red-text"><strong>Do Not Show</strong></span>
                                </label>
                            </div>
                        </div>

                        <div class="col s12">
                            <p><strong>Show Email Address</strong></p>
                            <div class="col s12 m6 l4">
                                <label>
                                    <input type="radio" name="group2" [checked]="showEmailAdr === 'Public'"
                                        (click)="publicEmailClick()" />
                                    <span class="text"><strong>General Public</strong></span>
                                </label>
                            </div>
                            <div class="col s12 m6 l4">
                                <label>
                                    <input type="radio" name="group2" [checked]="showEmailAdr === 'Members'"
                                        (click)="membersEmailClick()" />
                                    <span class="blue-text"><strong>Members Only</strong></span>
                                </label>
                            </div>
                            <div class="col s12 m6 l4">
                                <label>
                                    <input type="radio" name="group2" [checked]="showEmailAdr === 'Private'"
                                        (click)="privateEmailClick()" />
                                    <span class="red-text"><strong>Do Not Show</strong></span>
                                </label>
                            </div>
                        </div>

                        <div class="col s12">
                            <p><strong>Allow Contact Us Messages </strong></p>
                            <div class="col s12 m6 l4">
                                <label>
                                    <input type="radio" name="group3" [checked]="allowContact === 'Public'"
                                        (click)="publicContactClick()" />
                                    <span class="text"><strong>General Public</strong></span>
                                </label>
                            </div>
                            <div class="col s12 m6 l4">
                                <label>
                                    <input type="radio" name="group3" [checked]="allowContact === 'Members'"
                                        (click)="membersContactClick()" />
                                    <span class="text"><strong>Members Only</strong></span>
                                </label>
                            </div>
                            <div class="col s12 m6 l4">
                                <label>
                                    <input type="radio" name="group3" [checked]="allowContact === 'Private'"
                                        (click)="privateContactClick()" />
                                    <span class="red-text"><strong>Do Not Allow</strong></span>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="row card-panel white">
                        <h6 class="center" style="margin-bottom: 15px; margin-top: 15px;"><strong>Permissions</strong>
                        </h6>
                        <div class="col s12">
                            <div class="row">
                                <div class="col s12 l6">
                                    <label>
                                        <input type="checkbox" [checked]='manageAccount'
                                            (click)='manageAccountClicked()' />
                                        <span><strong>Update Account Information</strong></span>
                                    </label>
                                </div>

                                <div class="col s12 l6">
                                    <label>
                                        <input type="checkbox" [checked]='manageContact'
                                            (click)='manageContactClicked()' />
                                        <span><strong> Update Contact Settings</strong></span>
                                    </label>
                                </div>

                                <div class="col s12 l6">
                                    <label>
                                        <input type="checkbox" [checked]='manageDepartmentInfo'
                                            (click)='manageDepartmentInfoClicked()' />
                                        <span><strong> Update Department Information</strong></span>
                                    </label>
                                </div>

                                <div class="col s12 l6">
                                    <label>
                                        <input type="checkbox" [checked]='manageDepartmentNews'
                                            (click)='manageDepartmentNewsClicked()' />
                                        <span><strong>Manage Department News</strong></span>
                                    </label>
                                </div>

                                <div class="col s12 l6">
                                    <label>
                                        <input type="checkbox" [checked]='manageDepartmentEmployees'
                                            (click)='manageDepartmentEmployeesClicked()' />
                                        <span><strong> Manage Department Employees</strong></span>
                                    </label>
                                </div>

                                <div class="col s12 l6">
                                    <label>
                                        <input type="checkbox" [checked]='manageSubDepartments'
                                            (click)='manageSubDepartmentsClicked()' />
                                        <span> <strong> Manage Sub Department</strong></span>
                                    </label>
                                </div>

                            </div>
                        </div>
                    </div>

                    <div class="row card-panel white">
                        <h6 class="center" style="margin-bottom: 25px; margin-top: 15px;"><strong>Account
                                Validation</strong></h6>
                        <div class="row">
                            <div class="col s4">
                                <label>
                                    <input type="checkbox" [checked]='verifySIN' (click)='validateSinClicked()' [disabled]="!selectedMember.SIN" />
                                    <span> <strong>SIN</strong></span>
                                </label>
                            </div>

                            <div class="col s4">
                                <label>
                                    <input type="checkbox" [checked]='verifyISN' (click)='validateIsnClicked()'  [disabled]="!selectedMember.IndianStatusNo" />
                                    <span><strong>ISN</strong></span>
                                </label>
                            </div>

                            <div class="col s4">
                                <label>
                                    <input type="checkbox" [checked]='verifyDOB' (click)='validateDobClicked()'  [disabled]="!selectedMember.DOB" />
                                    <span><strong>DOB</strong></span>
                                </label>
                            </div>
                        </div>
                    </div>

                    <button type="submit" class="btn col s12 blue white-text" [disabled]="!inviteMemberForm.valid" style="margin-bottom: 25px;">
                        Send Registration Link <i class="material-icons right">send</i>
                    </button>

                    <a [ngClass]="'col s12 btn waves-effect ' + themeColor" (click)="back()"><i
                        class="material-icons left">house</i> Back To Previous Page</a>
                </form>
            </div>
        </div>

        <div class="col s12 l10 " *ngIf="step4">
            <div class="card">
                <div class="card-content">
                    <div class="row center">
                        <i style="font-size: 100px;" class="material-icons green-text">
                            check_circle
                        </i>
                        <p class="green-text"> <strong>Registration Link Successfully Sent</strong></p>
                    </div>
                </div>
            </div>
            <a [ngClass]="'col s12 btn waves-effect ' + themeColor" (click)="backToList()"><i
                    class="material-icons left">house</i> Back To List of Requests</a>
        </div>
    </div>
    <div class="col l1"></div>
</div>

<div id="similarMembersModal" class="modal">
    <div class="modal-content">
        <div class="row col s12" *ngIf="hasMatch">
            <ul class="collection with-header">
                <li class="collection-header">
                    <h5>Request Detail</h5>
                </li>
                <li class="collection-item ">
                    <div><strong>First Name: </strong><a class="secondary-content">{{request.Fname}}</a></div>
                </li>
                <li class="collection-item ">
                    <div> <strong>Last Name: </strong><a class="secondary-content">{{request.Lname}}</a></div>
                </li>
                <li class="collection-item ">
                    <div><strong>ISN: </strong><a class="secondary-content">{{request.isn}}</a></div>
                </li>
                <li class="collection-item ">
                    <div><strong>Date of Birth: </strong> <a class="secondary-content">{{request.dob ? (request.dob |
                            date: 'dd/MM/yyyy') : request.dob}} </a></div>
                </li>
                <li class="collection-item">
                    <div><strong>Email: </strong> <a class="secondary-content">{{request.email}} </a></div>
                </li>
            </ul>

            <button *ngIf="!hasISNMatch" type="button" class="col s12 btn waves-effect waves-light green btn"
                (click)="addMember()">
                <i class="material-icons left">person_add</i>Add Member</button>
        </div>

        <br>
        <h5>{{matches?.length}} Possible Matches Found</h5>
        <div class="row white darken-2">
            <table class="striped highlight responsive-table">
                <thead>
                    <tr>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>ISN</th>
                        <th>Date Of Birth</th>
                        <th>Email</th>
                        <th>Approve</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let m of matches; let i=index" style="cursor: pointer;"
                        [ngClass]="{'light-green lighten-5': ( m.IndianStatusNo != '' && m.IndianStatusNo==request.isn)}"
                        [checked]="m.IndianStatusNo ==request.isn">
                        <td class="blue-text">{{ m.GivenName }}</td>
                        <td class="blue-text">{{ m.LastName }}</td>
                        <td class="blue-text">{{ m.IndianStatusNo }}</td>
                        <td class="blue-text">{{ m.DOB | date: 'dd/MM/yyyy'}} </td>
                        <td class="blue-text">{{ m.Email }} </td>
                        <td> <a class="btn-floating btn-small green center btn" (click)="selectMatch(i)"><i
                                    class="material-icons">thumb_up</i></a></td>
                    </tr>
                </tbody>
            </table>
        </div>
        <div class="row">
            <div class="input-field col s12 center">
                <button type="button" class='col s12 btn waves-effect red' (click)="cancel()">
                    <i class="material-icons right">close</i> Cancel
                </button>
            </div>
        </div>
    </div>
</div>

<div id="noMatchFound" class="modal">
    <div class="modal-content center">
        <div *ngIf="hasNoMatch">
            <h5>No Match Found</h5>
            <p>Match was not found on the database.</p>
            <br>
            <p><strong>Do You want to add this member? </strong></p>
            <a class="waves-effect waves-light btn-large" (click)="addMember()"><i
                    class="material-icons left">person_add</i>Add Member</a>
        </div>
    </div>

    <div class="modal-footer">
        <a (click)="closeModal()" class="modal-close amber darken-4 white-text btn-flat white-text"> <i
                class="material-icons right">close</i>close</a>
    </div>
</div>

<div id="inviteMember" class="modal">
    <div class="modal-content">
        <h5 class="center green-text">This Person has a member account on my nation!</h5>
        <br />
        <div class="row center-align">
            <p><strong>Send an Invitation to give an Employee Access?</strong></p>
        </div>
        <div class="row">
            <div class="col s1"></div>
            <div class="col s10">
                <a (click)="invite()" class="col s12 waves-effect waves-blue blue center btn-large">Invite</a>
            </div>
            <div class="col s1"></div>
        </div>
    </div>

    <div class="modal-footer">
        <a (click)="closeModal()" class="modal-close waves-effect waves-blue btn-flat">Cancel</a>
    </div>
</div>
