<div class="row">
  <div class="col s12 m6">
    <div class="card">

      <div class="card-image" *ngIf="candidateDetail">
        <div class="background-img">
          <div class="background-with-color" [ngStyle]="{'background-color': themeColor}">
            <div></div>
          </div>
          <div class="prof-img">
            <div *ngIf="!candidateDetail.profileImgLink">
              <img class="background-img-avatar" src="../../../../../assets/img/profile.jpg"  style="width: 120px; border: white solid 3px; border-radius: 50%;"/>
            </div>
            <div  *ngIf="candidateDetail.profileImgLink">
              <img class="background-img-avatar" [src]="candidateDetail.profileImgLink"  style="width: 120px; height: 120px; border: white solid 3px; border-radius: 50%;"/>
            </div>
          </div>
        </div>
      </div>

      <div class="card-content">
        <div *ngIf="candidateDetail">
          <h6 class="center"><span  *ngIf="candidateDetail.GivenName">{{candidateDetail.GivenName}}</span> <span  *ngIf="candidateDetail.LastName"> {{candidateDetail.LastName}}</span> </h6>
          <div *ngIf="hasCandidateDetail">
            <div class="row center">
              <div *ngIf="moreCandidateDetail.campaignDescription">
                <p style="font-style: italic; font-weight: bold; color: gray;" >{{moreCandidateDetail.campaignDescription}}</p>
              </div>
            </div>
          </div>
          <p *ngIf="candidateDetail.Band"><strong>Band</strong> :{{candidateDetail.Band}}</p>
          <p *ngIf="candidateDetail.DOB"><strong>DOB</strong> :{{candidateDetail.DOB}}</p>
          <p *ngIf="candidateDetail.community"><strong>Community</strong> :{{candidateDetail.community}}</p>
        </div>

        <div *ngIf="hasCandidateDetail">
          <div class="row"  *ngIf="moreCandidateDetail.facebook || moreCandidateDetail.twitter || moreCandidateDetail.instagram || moreCandidateDetail.youtube">
            <h6><strong>Contact</strong></h6>
            <p *ngIf="moreCandidateDetail.facebook" style="text-decoration: underline;" ><i class="fa fa-facebook prefix " style="color: #3b5998; font-size:larger; padding-right: 2%;"></i><a [href]="moreCandidateDetail.facebook" target="_blank">{{moreCandidateDetail.facebook}}</a> </p>
            <p *ngIf="moreCandidateDetail.twitter" style="text-decoration: underline;"><i class="fa fa-twitter prefix" style="color: #1DA1F2; font-size:larger; padding-right: 2%;"></i><a [href]="moreCandidateDetail.twitter" target="_blank"> {{moreCandidateDetail.twitter}}</a></p>
            <p *ngIf="moreCandidateDetail.instagram" style="text-decoration: underline;"><i class="fa fa-instagram prefix " style="color: #8a3ab9; font-size:larger; padding-right: 2%;"></i><a [href]="moreCandidateDetail.instagram" target="_blank">{{moreCandidateDetail.instagram}}</a> </p>
            <p *ngIf="moreCandidateDetail.youtube" style="text-decoration: underline;"><i class="fa fa-youtube prefix red-text" style="font-size:larger; padding-right: 2%;"></i><a [href]="moreCandidateDetail.youtube" target="_blank">{{moreCandidateDetail.youtube}}</a> </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <button type="button" class="btn col s12 blue" (click)="backClicked()">
    <i class="material-icons left">arrow_back_ios</i> Back To Previous Page
  </button>
</div>
