<div class="col s12">
  <div class="body-h">

    <main class="StickyContent white ">
      <div class="row col s12 m12" *ngIf="!showProfile">

        <div class="header-setting b-m-2">
          <h4 class="">Search
            <span *ngIf="nationOfUse !== 'Batc'"> Members</span>
            <span *ngIf="nationOfUse === 'Batc'"> Clients</span>
          </h4>
        </div>

        <!-- search Form -->
        <form [formGroup]="filterForm" (ngSubmit)="searchMember(filterForm.value)">
          <div class="row card-panel white">

            <div class="input-field col s12 m6 l6">
              <div class="label-input">First Name</div>
              <input id="firstName" type="text" formControlName='firstName' autocomplete="off"
                style="text-transform: capitalize;" class="validate" placeholder="First Name" />
            </div>

            <div class="input-field col s12 m6 l6">
              <div class="label-input">Last Name</div>
              <input id="lastName" type="text" formControlName='lastName' autocomplete="off"
                style="text-transform: capitalize;" class="validate" placeholder="Last Name" />
            </div>

            <div class="col s12">

              <div class="input-field col s12 m7 l7" *ngIf="!showCommunity">
                <div class="label-input">ISN</div>
                <!-- <input id="isn" type="text" class="validate" formControlName='isn' autocomplete="off"
                  [textMask]="{mask: isnMask, guide: false}" /> -->
                  <input id="isn" type="text" [inputMask]="isnMask" class="validate" formControlName='isn' autocomplete="off"
                   />
                <div *ngIf="(isn.dirty || isn.touched) && isn.invalid">
                  <small *ngIf="isn.errors.minlength || isn.errors.maxlength" class="red-text"> The ISN must be 10
                    Digits.</small>
                </div>
              </div>

              <div class="input-field col s12 m6 l6" *ngIf="showCommunity">
                <div class="label-input">ISN</div>
                <!-- <input id="isn" type="text" class="validate" formControlName='isn' autocomplete="off"
                  [textMask]="{mask: isnMask, guide: false}" /> -->
                  <input id="isn" type="text" [inputMask]="isnMask" class="validate" formControlName='isn' autocomplete="off" />
                <div *ngIf="(isn.dirty || isn.touched) && isn.invalid">
                  <small *ngIf="isn.errors.minlength || isn.errors.maxlength" class="red-text"> The ISN must be 10
                    Digits.</small>
                </div>
              </div>

              <div class="input-field col s12 l6" *ngIf="showCommunity">
                <select multiple formControlName="community">
                  <option [value]="" disabled selected>Show All</option>
                  <option *ngFor="let c of communities" [value]="c.community">{{c.community}}</option>
                </select>
                <label>Community</label>
              </div>
            </div>

            <div class="input-field col s6 m3 l3">
              <div class="label-input">Minimum Age</div>
              <input id="startAge" type="number" formControlName='startAge' autocomplete="off" />
              <div *ngIf="(startAge.dirty || startAge.touched) && startAge.invalid">
                <small *ngIf="startAge.errors.min" class="red-text errMsg"> Minimum Age must be greater than 0
                </small>
              </div>
            </div>

            <div class="input-field col s6 m3 l3">
              <div class="label-input">Maximum Age</div>
              <input id="endAge" type="number" formControlName='endAge' autocomplete="off" />
              <div *ngIf="(endAge.dirty || endAge.touched) && endAge.invalid">
                <small *ngIf="endAge.errors.min" class="red-text errMsg"> Maximum Age must be greater than 0
                </small>
                <small *ngIf="endAge.errors.invalidAgeRange" class="red-text errMsg"> Maximum Age must be greater than
                  Minimum Age
                </small>
              </div>
            </div>

            <div class="input-field col s12 m6 l6">
              <div class="label-input">As of date</div>
              <input id="referenceDate" type="text" class="datepicker" disabled
                (change)="onReferenceDateChange($event.target.value)" formControlName='referenceDate' autocomplete="off"
                class="validate" />
              <div *ngIf="(referenceDate.dirty || referenceDate.touched) && referenceDate.invalid">
                <small *ngIf="referenceDate.errors.invalidDate" class="red-text"> The Date is invalid </small>
              </div>
            </div>
          </div>

          <div class="row col s12 p-m-1">
            <button type="submit" class="btn-small col s12 green white-text" [ngClass]="'btn ' + buttonType"
              style="margin-bottom: 5px;" [disabled]="!filterForm.valid" [ngStyle]="{'background-color': themeColor}">
              <i class="material-icons right">search</i><span *ngIf="nationOfUse !== 'Batc'">Search Members</span>
              <span *ngIf="nationOfUse === 'Batc'">Search Clients</span></button>
          </div>
        </form>

        <!--Search result-->
        <div class="row col s12" *ngIf="members?.length > 0">
          <!--Chart-->
          <ul class="collapsible" *ngIf="showCharts">
            <li>
              <div class="collapsible-header" style="cursor: pointer;">
                <a><i class="material-icons">pie_chart</i><strong>View Charts</strong></a>
              </div>

              <div class="collapsible-body">
                <div *ngIf="chartDatas.length > 1">
                  <p class="center-align">Chart {{chart+1}} of {{chartDatas.length}} </p>
                  <i *ngIf="chart != chartDatas.length-1" style="cursor: pointer;" class="material-icons right"
                    (click)="nextChart()">arrow_forward_ios</i>
                  <i *ngIf="chart !=  0" class="material-icons left" style="cursor: pointer;"
                    (click)="previousChart()">arrow_back_ios</i>
                </div>

                <div *ngFor="let chartData of chartDatas; let i = index">
                  <div *ngIf="chart==i">
                    <div class="row center-align">
                      <h6><strong>{{chartData.name}}</strong></h6>
                    </div>
                    <div class="chart" *ngIf="chartData.data">
                      <canvas class="hide-on-small-only" baseChart [data]="chartData.data" [plugins]="pieChartPlugins"
                        [labels]="chartData.labels" [chartType]="pieChartType" [options]="pieChartOptions"
                        [colors]="pieChartColors" [legend]="pieChartLegend">
                      </canvas>
                      <canvas class=" hide-on-med-and-up" width="400" height="400" baseChart [data]="chartData.data"
                        [plugins]="pieChartPlugins" [labels]="chartData.labels" [chartType]="pieChartType"
                        [options]="pieChartOptions" [colors]="pieChartColors" [legend]="pieChartLegend">
                      </canvas>
                    </div>
                  </div>
                </div>
              </div>
            </li>
          </ul>

          <!--Result Table-->
          <div class="row col s12 card white darken-2 ">

            <!-- Result Filters -->
            <div class="row col s12" style="margin-top: 25px;">
              <label class="col m4 s12">
                <input name="filter" type="checkbox" class="filled-in" [checked]="filters.hasEmail"
                  (click)="filterBy('email')" />
                <span>Show with Email </span>
              </label>
              <label class="col m4 s12">
                <input name="filter" type="checkbox" [checked]="filters.hasMobile" class="filled-in"
                  (click)="filterBy('mobile')" />
                <span>Show with Mobile </span>
              </label>
              <label class="col m4 s12 left">
                <input name="filter" type="checkbox" [disabled]="disablefilters && !filters.notRegistered"
                  [checked]="filters.notRegistered" class="filled-in" (click)="filterBy('notRegistered')" />
                <span>Show Not Registered </span>
              </label>
              <label class="col m4 s12 left">
                <input name="filter" type="checkbox" [disabled]="disablefilters && !filters.registered"
                  [checked]="filters.registered" class="filled-in" (click)="filterBy('registered')" />
                <span>Show Registered </span>
              </label>
              <label class="col m4 s12 left">
                <input name="filter" type="checkbox" [disabled]="disablefilters && !filters.invited"
                  [checked]="filters.invited" class="filled-in" (click)="filterBy('invited')" />
                <span>Show Invited </span>
              </label>
            </div>

            <!-- Show result-->
            <div class="row col s12">
              <h6 class="center red-text" style="font-weight: bolder;">Total : {{count}}</h6>
              <table class="striped" *ngIf="count > 0">
                <thead>
                  <tr>
                    <th>
                      <label>
                        <input type="checkbox" class="filled-in left" [checked]="selectAllChecked"
                          (click)="selectAll()" />
                        <span></span>
                      </label> Name<span class="badge"
                        *ngIf="selectedMembers.length > 0">{{selectedMembers.length}}</span>
                    </th>
                    <th class="center">DOB</th>
                    <th class="center">Registered</th>
                    <th class="center">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let m of membersData | paginate: { itemsPerPage: 10, currentPage: p }; let i=index;">
                    <td>
                      <label>
                        <input type="checkbox" style="width: 5px; height: 5px;" class="filled-in left"
                          [checked]="selectedMembers.includes(m)" (click)="selectMember(m)" />
                        <span></span>
                      </label>
                      <a style="cursor: pointer;" class="black-text"><strong> {{m.GivenName}}
                          {{m.LastName}} </strong></a>
                    </td>
                    <td class="m.dob">{{m.dob | date:
                      'dd/MMM/yyyy'}}</td>
                    <td *ngIf="m.isRegistred == 'Yes'" class="center"> <i
                        class="material-icons green-text">check_circle</i>
                    </td>
                    <td *ngIf="!m.isRegistred" class="center"><i class="material-icons red-text">cancel</i>
                    <td *ngIf="m.isRegistred == 'Invited'" class="center tooltipped" data-position="top"
                      data-tooltip="Invited"><i class="material-icons amber-text text-darken-3">task_alt</i>
                    </td>
                    <td class="hide-on-large-only center">
                      <a class='dropdown-trigger btn-flat' data-target='actionMenu'> <i class="material-icons">
                          more_horiz
                        </i></a>
                      <ul id='actionMenu' class='dropdown-content'>
                        <li (click)="onShowProfile(m.applicantID)"><a><i
                              class="material-icons blue-text text-darken-2">visibility</i>View profile</a></li>
                        <li (click)="onSendEmail(i)"><a><i class="material-icons green-text text-darken-2">email</i>Send
                            Email</a></li>
                        <li (click)="onSendSMS(i)"><a><i
                              class="material-icons yellow-text text-darken-2">textsms</i>Send
                            SMS</a></li>
                        <li *ngIf="!m.Invited" (click)="onInvite(i)"><a><i
                              class="material-icons red-text text-darken-2">person_add</i>Invite</a></li>
                        <li *ngIf="m.Invited"><a><i class="material-icons green-text">task_alt</i>Invited</a></li>
                      </ul>
                    </td>

                    <td class="hide-on-med-and-down center">
                      <a class="btn-flat" (click)="onShowProfile(m.applicantID)">
                        <i class="material-icons blue-text text-darken-2 center tooltipped" data-position="top"
                          data-tooltip="View Profile">
                          visibility
                        </i>
                      </a>
                      <a class="btn-flat" (click)="onSendEmail(i)">
                        <i class="material-icons  teal-text text-darken-2 center tooltipped" data-position="top"
                          data-tooltip="Send Email">
                          email
                        </i>
                      </a>
                      <a class="btn-flat" (click)="onSendSMS(i)">
                        <i class="material-icons yellow-text text-darken-2 center tooltipped" data-position="top"
                          data-tooltip="Send SMS">
                          textsms
                        </i>
                      </a>
                      <a *ngIf="!m.Invited" class="btn-flat" (click)="onInvite(m)">
                        <i class="material-icons red-text text-darken-2 center tooltipped" data-position="top"
                          data-tooltip="Invite">
                          person_add
                        </i>
                      </a>
                      <a *ngIf="m.Invited" class="btn-flat">
                        <i class="material-icons green-text center tooltipped" data-position="top"
                          data-tooltip="Invited">
                          task_alt
                        </i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="row center" *ngIf="count > 0">
                <pagination-controls (pageChange)="p = $event"></pagination-controls>
              </div>
            </div>
          </div>
        </div>

        <!--No result found-->
        <div class="row card-panel red darken-2" *ngIf="members?.length == 0" style="margin-top: 25px;">
          <h6 class="white-text"><strong> 0 Record Found In The Database </strong></h6>
        </div>

        <!--Action Buttons -->
        <button *ngIf="members?.length > 0" class="col s12 btn waves-effect green" (click)="onInviteMembers()"
          [disabled]="selectedMembers.length == 0" style="margin-bottom: 15px;"><i
            class="material-icons right">person_add</i> Invite Members<span *ngIf="selectedMembers.length > 0"> (
            {{selectedMembers.length}} )</span></button>

        <button *ngIf="selectedMembers?.length > 0" class="col s12 btn waves-effect green" (click)="onSendEmails()"
          [disabled]="emailList.length == 0" style="margin-bottom: 15px;"><i class="material-icons right">mail</i> Send
          Email <span *ngIf="emailList.length > 0"> ({{emailList.length}} )</span></button>

        <button *ngIf="selectedMembers?.length > 0" class='col s12 btn waves-effect blue darken-3'
          [disabled]="phoneNoList.length == 0" style="margin-bottom: 15px;">
          <i class="material-icons right">perm_phone_msg</i> Send Text Message<span *ngIf="phoneNoList.length > 0"> (
            {{phoneNoList.length}} )</span></button>

        <button class="col s12 white" style="border: 1px solid;margin-bottom: 25px;"
          [ngStyle]="{'border-color': themeColor, 'color': themeColor}" (click)="backClicked()"
          [ngClass]="'btn-flat ' + buttonType"><i class="material-icons left">arrow_back_ios</i>Back To Previous
          Page</button>
      </div>

      <!-- Other Components --->
      <div *ngIf="showProfile">
        <div *ngIf="memberDetail" class="row col s12">
          <div class="row col s12">
            <h6 class="center-align"><strong>Member Profile</strong></h6>
            <!-- <app-member-profile [model]="memberDetail"></app-member-profile> -->
            <app-personal-info [model]="memberDetail"></app-personal-info>

            <button class="col s12 btn waves-effect teal" (click)="contactClient()" style="margin-bottom: 15px;"><i
                class="material-icons right">mail</i> Contact
              Client</button>

            <button *ngIf="firebaseMember && firebaseMember.status !== 'Enabled'"
              class="col s12 btn waves-effect green modal-trigger" data-target="confirmationModal"
              [disabled]="loggedUser.isDemo" style="margin-bottom: 15px;"><i
                class="material-icons right">mobile_friendly</i>
              Enable Client Account</button>

            <button *ngIf="firebaseMember && firebaseMember.status == 'Enabled'"
              class="col s12 btn waves-effect red modal-trigger" data-target="confirmationModal"
              [disabled]="loggedUser.isDemo" style="margin-bottom: 15px;"><i class="material-icons right">mobile_off</i>
              Disable Client Account</button>

            <button *ngIf="firebaseMember && firebaseMember.Email" style="margin-bottom: 15px;"
              data-target="resetPasswordModal" class="col s12 btn waves-effect blue modal-trigger"> <i
                class="material-icons right">mail</i> Send Link to
              Reset
              Password
            </button>

            <a class="col s12 white" style="border: 1px solid;"
              [ngStyle]="{'border-color': themeColor, 'color': themeColor}" (click)="backToList()"
              [ngClass]="'btn-flat ' + buttonType"><i class="material-icons left">arrow_back_ios</i>Back To Search
              Members</a>
          </div>

        </div>
      </div>

      <div *ngIf="contact">
        <app-contact-members [message]=message [contactDetail]="contactDetail" (success)="contactHandler($event)">
        </app-contact-members>
      </div>

      <!--Modals-->
      <div id="invitationModal" class="modal max_modal card">
        <div class="modal-content card-content">
          <h5><strong>Invite members</strong>
            <button *ngIf="toBeInvited.length > 0" class="waves-effect waves-light btn-flat right red-text"
              (click)="clearSelection()"><i class="material-icons right">clear</i>Clear</button>
            <button *ngIf="toBeInvited.length == 0 || toBeInvited.length == selectedMembers.length"
              class="waves-effect waves-light btn right amber darken-4" (click)="inviteAll()"><i
                class="material-icons right">person_add</i>Invite All</button>
            <button *ngIf="toBeInvited.length > 0 && toBeInvited.length < selectedMembers.length"
              class="waves-effect waves-light btn right amber darken-4" (click)="sendInvitation()"><i
                class="material-icons right">person_add</i>Invite Selected ({{toBeInvited.length}})</button>
          </h5>
          <br>

          <div class="card" *ngFor="let member of selectedMembers; let i=index">
            <div class="card-content">
              <h6>
                <strong>{{member.LastName}}, {{member.GivenName}}</strong>
                <label class="right">
                  <input type="checkbox" class="filled-in" [checked]="toBeInvited.includes(member)"
                    [disabled]="member.isRegistred || (!member.Email && !member.cell_phone_num)"
                    (click)="addToInvite(member)" />
                  <span *ngIf="!member.isRegistred"></span>
                  <span *ngIf="!member.Email && !member.cell_phone_num" class="red-text">Can't send Invitation</span>
                  <span *ngIf="member.isRegistred"><small class="green-text">Member Registred</small></span>
                </label>

              </h6>
              <div class="row">
                <p class="col s12 m6">Email: <strong><span *ngIf="member.Email">{{member.Email}}</span> </strong><span
                    *ngIf="!member.Email" class="red-text">No Email Provided</span></p>
                <p class="col s12 m6">Mobile: <strong><span
                      *ngIf="member.cell_phone_num">{{member.cell_phone_num}}</span></strong><span
                    *ngIf="!member.cell_phone_num" class="red-text">No Phone Number Provided</span></p>
              </div>
            </div>
            <div class="card-action">
              <a class="btn-flat red-text" (click)="selectMember(member)">
                <i class="material-icons red-text">
                  delete
                </i>Remove
              </a>
              <!-- <button class="waves-effect waves-light btn btn-small right-align" (click)="onInvite(i)"
            [disabled]="!(!member.isRegistred || (!member.Email && !member.cell_phone_num))"><i
              class="material-icons right">person_add</i>Invite</button> -->
            </div>
          </div>

          <!-- <ul class="collection" *ngFor="let member of selectedMembers; let i=index">
        <li class="collection-item">
          <h6>
            <strong>{{member.LastName}}, {{member.GivenName}}</strong>
            <label class="right">
              <input type="checkbox" class="filled-in" checked="checked" />
              <span></span>
            </label>
          </h6>
          <p class="col s12 m6">Email: <strong><span *ngIf="member.Email">{{member.Email}}</span> </strong><span
              *ngIf="!member.Email" class="red-text">No Email Provided</span></p>
          <p class="col s12 m6">Mobile: <strong><span
                *ngIf="member.cell_phone_num">{{member.cell_phone_num}}</span></strong><span
              *ngIf="!member.cell_phone_num" class="red-text">No Phone Number Provided</span></p>


          <div class="secondary-content">

            <span class="left">
              <i class="material-icons red-text">
                delete
              </i>Remove
            </span>

            <a class="waves-effect waves-light btn btn-small"><i class="material-icons right">person_add</i>Invite</a>
          </div>
        </li>
      </ul> -->
        </div>

        <div class="modal-footer">
          <a class="modal-close waves-effect red waves-light btn" style="margin: 10px">Cancel</a>
          <button type="button" class="waves-effect green waves-light btn  modal-trigger"
            data-target="inviteConfirmationModal" [disabled]="toBeInvited.length == 0 " style="margin: 10px">Invite
            Selected
            &nbsp;<span *ngIf="toBeInvited.length>0">{{toBeInvited.length}}</span></button>
        </div>
        <br />

        <div class="card-action">
          <a class="waves-effect green waves-light btn modal-trigger">Invite Selected</a>
          <a class="modal-close waves-effect red waves-light btn">Cancel</a>
        </div>
      </div>

      <div id="inviteConfirmationModal" class="modal">
        <div class="modal-content center">
          <h5><strong>Send Invitation</strong></h5>
          <br>
          <div *ngIf="!multiple && selectedMember">
            <h6><strong>
                You are about to send an invitation to {{selectedMember.GivenName}} {{selectedMember.LastName}}.<br />
              </strong></h6>
            <p class="col s12 m6"><i class="material-icons green-text">
                email
              </i> : <span *ngIf="selectedMember.cell_phone_num">{{selectedMember.Email}}</span> <small
                *ngIf="!selectedMember.Email" class="red-text">Not provided</small></p>
            <p class="col s12 m6"><i class="material-icons green-text">
                call
              </i> : <span *ngIf="selectedMember.cell_phone_num">{{selectedMember.cell_phone_num}}</span> <small
                *ngIf="!selectedMember.cell_phone_num" class="red-text">Not provided</small></p>
          </div>

          <div *ngIf="multiple && selectedMembers?.length > 1">
            <h6><strong>
                You are about to send an invitation to {{toBeInvited.length}} members.<br />
              </strong></h6>
            <div class="row">
              <br>
              <p><strong>Send Invitation Via</strong></p>
              <div class="col s12 m6">
                <label>
                  <input type="checkbox" [checked]="viaEmail" (click)="viaEmailChecked()" />
                  <span>Email</span>
                </label>
              </div>
              <div class="col s12 m6">
                <label>
                  <input type="checkbox" [checked]="viaSms" (click)="viaSmsChecked()" />
                  <span>SMS</span>
                </label>
              </div>
            </div>
          </div>

          <div class="row">
            <br>
            <p><strong>Please Select One Or More Question.</strong></p>
            <div class="col s12 m6 l4">
              <label>
                <input type="checkbox" [checked]="verifySIN" (click)="sinChecked()" />
                <span>Ask For SIN Number.</span>
              </label>
            </div>
            <div class="col s12 m6 l4">
              <label>
                <input type="checkbox" [checked]="verifyISN" (click)="isnChecked()" />
                <span>Ask For ISN Number.</span>
              </label>
            </div>
            <div class="col s12 m6 l4">
              <label>
                <input type="checkbox" [checked]="secretQuestion" (click)="secretQuestionChecked()" />
                <span>Ask A Secret Question.</span>
              </label>
            </div>

            <div class="row input-field col s12" *ngIf="secretQuestion">
              <i class="material-icons prefix pink-text">help_outline</i>
              <input id="question" type="text" [(ngModel)]="question" autocomplete="off" />
              <label for="question">Secret Question :</label>
            </div>

            <div class="row input-field col s12" *ngIf="secretQuestion">
              <i class="material-icons prefix pink-text">help_outline</i>
              <input id="answer" type="text" [(ngModel)]="answer" autocomplete="off" />
              <label for="answer">Answer :</label>
            </div>
          </div>

          <p><strong><span class="red-text">Would you like to continue?</span></strong></p>
        </div>
        <div class="modal-footer">
          <a class="modal-close green darken-2 white-text btn-flat white-text right" style="margin-left: 15px;"
            (click)="sendInvitation()"> <i class="material-icons right">check</i>Yes</a>
          <a class="modal-close amber darken-4 white-text btn-flat white-text"> <i
              class="material-icons right">close</i>close</a>
        </div>
      </div>

      <div id="confirmationModal" class="modal modal_max" *ngIf="firebaseMember">
        <div class="modal-content">
          <div *ngIf="firebaseMember.status == 'Enabled'">
            <h5><strong>Disable Account</strong></h5>
            <div class="valign-wrapper card-panel red lighten-5">
              <i class="material-icons left red-text" style="font-size: 40px;">
                warning_amber
              </i>
              <div>
                <h6>Are You sure you want to Disable this Account? </h6>
                <h6 class="red-text"><strong>Users with a disabled accounts aren't able to sign in.</strong></h6>
              </div>
            </div>
          </div>

          <div *ngIf="firebaseMember.status !== 'Enabled'">
            <h5><strong>Enable Account</strong></h5>
            <div class="valign-wrapper card-panel blue lighten-5">
              <i class="material-icons left blue-text" style="font-size: 40px;">
                info
              </i>
              <div>
                <h6>Are You sure you want to Enable this Account? </h6>
                <h6><strong>Users with enabled accounts will be able to sign in again.</strong></h6>
              </div>
            </div>
          </div>

          <h6 class="grey-text">User account</h6>
          <h6><strong>{{firebaseMember.Email}}</strong></h6>
          <br />
          <h6>Please Enter the Reason for <span *ngIf="firebaseMember.status == 'Enabled' ">Disabling</span> <span
              *ngIf="firebaseMember.status !== 'Enabled' ">Enabling</span>
            this account</h6>
          <div class="input-field col s12">
            <input placeholder="Reason" id="reason" type="text" [(ngModel)]="reason" class="validate">
          </div>

          <br />
          <p class="col s12" style="margin-bottom: 40px;">
            <strong>Do you wish to send a message to the client?</strong>
            <label class="right">
              <input type="checkbox" (click)="onSendMessage()" [checked]='sendMessage' />
              <span>Send Email</span>
            </label>
          </p>

        </div>
        <div class="modal-footer">
          <a class="modal-close waves-effect waves-light btn-flat"><strong>Cancel</strong></a>
          <button *ngIf="firebaseMember.status !== 'Enabled'" (click)="enableAccount()"
            class="modal-close btn waves-effect waves-light blue" [disabled]="!reason"><strong>Enable</strong></button>
          <button *ngIf="firebaseMember.status == 'Enabled' " (click)="disableAccount()"
            class="modal-close btn waves-effect waves-light red" [disabled]="!reason"><strong>Disable</strong></button>
        </div>
      </div>

      <div id="resetPasswordModal" class="modal" *ngIf="firebaseMember">
        <div class="modal-content">
          <h4>Reset Member Password</h4>

          <form class="container" (ngSubmit)="resetPassword()" [formGroup]="resetPasswordForm">
            <div class="row">
              <div class="row">
                <div class="section"></div>
                <p>
                  Please enter the member email and Follow the link we will send to the member to reset account password
                </p>
                <div class="section"></div>
                <div class="input-field col s12">
                  <div> <label for="email">Email</label></div>
                  <div>
                    <input id="email" type="email" [(ngModel)]='client_email' required formControlName='email'
                      class="validate">
                    <div *ngIf="(email.dirty || email.touched) && email.invalid">
                      <small *ngIf="email.errors.required" class="red-text"> Email is required. </small>
                      <small *ngIf="email.errors.email" class="red-text"> wrong email format. </small>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="input-field col s12 center">
                  <button type='submit' [disabled]="!resetPasswordForm.valid" name='btn_login'
                    class='col s12 btn btn-large waves-effect indigo'> <i class="material-icons left">compare_arrows</i>
                    Send
                    Link to Reset Password</button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <!-- EMAIL AND SMS-->
      <div id="emailModal" class="modal">
        <div class="modal-content">
          <h4>Send Email</h4>
          <form [formGroup]="emailForm" (ngSubmit)="Send(emailForm.value)">
            <div class="row">

              <div class="chips email-chips chips-initial"></div>
              <div class="input-field col s12 l12">
                <input id="subject" type="text" formControlName='subject' required class="validate" autocomplete="off"
                  style="text-transform: capitalize;" />
                <label for="subject">Subject</label>
                <div *ngIf="(subject.dirty || subject.touched) && subject.invalid">
                  <small *ngIf="subject.errors.required" class="red-text errMsg"> The Subject is required. </small>
                </div>
              </div>

              <div class="input-field col s12 l12">
                <textarea id="body" type="text" formControlName='body' required autocomplete="off"
                  style="text-transform: capitalize;" class="validate materialize-textarea" style="height: 175px;"
                  maxlength="500"> </textarea>
                <label for="body">Body</label>
                <div *ngIf="(body.dirty || body.touched) && body.invalid">
                  <small *ngIf="body.errors.required" class="red-text errMsg"> The Body Is Required.
                  </small>
                </div>
              </div>
            </div>
            <div>
              <p class="blue-text"><strong>Current Email: </strong> {{adminInfo.Email_Address}}</p>
            </div>

            <div class="row">
              <div class="input-field col s12 center">
                <button type="submit" [disabled]="!emailForm.valid" class='col s12 btn waves-effect green'
                  style="margin-bottom: 15px;">
                  <i class="material-icons right">send</i> Send Email
                </button>
                <button *ngIf="!adminNotFound" type="button" class='col s12 btn waves-effect blue modal-trigger'
                  data-target="settingModal" (click)="getInfo()" style="margin-bottom: 15px;">
                  <i class="material-icons right">edit</i> Update My Info
                </button>
                <button type="button" class='col s12 btn waves-effect red' (click)="cancel()">
                  <i class="material-icons right">close</i> Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div id="SMSModal" class="modal" *ngIf="false">
        <div class="modal-content">
          <h4>Send Text Message</h4>
          <form [formGroup]="SMSForm">
            <div class="row">
              <div class="chips no-chips"></div>
              <div class="input-field col s12 l12">
                <textarea id="message" type="text" formControlName='message' required autocomplete="off"
                  style="text-transform: capitalize;" class="validate materialize-textarea" maxlength="500"> </textarea>
                <label for="message">Message</label>
                <div *ngIf="(message.dirty || message.touched) && message.invalid">
                  <small *ngIf="message.errors.required" class="red-text errMsg"> Message Is Required.
                  </small>
                </div>
              </div>
            </div>

            <div>
              <p class="blue-text"><strong>Current Cell: </strong> {{adminInfo.Cell}}</p>
            </div>

            <div class="row">
              <div class="input-field col s12 center">
                <button type="submit" [disabled]="!SMSForm.valid" class='col s12 btn waves-effect green'
                  style="margin-bottom: 15px;">
                  <i class="material-icons right">send</i> Send Text Message
                </button>
                <button *ngIf="!adminNotFound" type="button" class='col s12 btn waves-effect blue modal-trigger'
                  data-target="settingModal" (click)="getInfo()" style="margin-bottom: 15px;">
                  <i class="material-icons right">edit</i> Update My Info
                </button>
                <button type="button" class='col s12 btn waves-effect red' (click)="cancel()">
                  <i class="material-icons right">close</i> Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div id="settingModal" class="modal" *ngIf="false">
        <div class="modal-content">
          <h4>Update Contact Info</h4>
          <h6 class="red-text" *ngIf="send == 'email' && !adminInfo.Email_Address">Upate you Email Address to use this
            feature</h6>
          <h6 class="red-text" *ngIf="send == 'text' && !adminInfo.Cell">Update you Cell to use this feature</h6>
          <br>
          <form [formGroup]="contactInfo" (ngSubmit)="updateInfo(contactInfo.value)">
            <div class="input-field col s12 m8">
              <i class="material-icons prefix blue-text">smartphone</i>
              <!-- <input id="Cell" type="text" formControlName='Cell' class="validate" autocomplete="off"
                [textMask]="{mask: phoneNumberMask, guide: true}" /> -->
                <input id="Cell" type="text" [inputMask]="phoneInputMask" formControlName='Cell' class="validate" autocomplete="off"
                 />
              <label for="Cell">Cell</label>
              <div *ngIf="(Cell.dirty || Cell.touched) && Cell.invalid">
                <small *ngIf="Cell.errors.required" class="red-text errMsg"> Cell Number is required. </small>
              </div>
            </div>

            <label class="col s12 m4">
              <input name="filter" type="checkbox" formControlName='show_cell' />
              <span>show cell on Message </span>
            </label>

            <div class="input-field col s8 m6">
              <i class="material-icons prefix blue-text">phone</i>
              <!-- <input id="phone" type="text" formControlName='phone' class="validate" autocomplete="off"
                [textMask]="{mask: phoneNumberMask, guide: true}" /> -->
                <input id="phone" type="text" [inputMask]="phoneInputMask" formControlName='phone' class="validate" autocomplete="off"
                />
              <label for="phone">Phone</label>
              <div *ngIf="(phone.dirty || phone.touched) && phone.invalid">
                <small *ngIf="phone.errors.required" class="red-text errMsg"> Phone Number is required. </small>
              </div>
            </div>

            <div class="input-field col s4 m2">
              <input id="ph_extension" type="text" formControlName='ph_extension' class="validate" autocomplete="off" />
              <label for="ph_extension">Phone Ext</label>
              <div *ngIf="(ph_extension.dirty || ph_extension.touched) && ph_extension.invalid">
                <small *ngIf="ph_extension.errors.required" class="red-text errMsg"> Phone Extension is required.
                </small>
              </div>
            </div>

            <label class="col s12 m4">
              <input name="filter" type="checkbox" formControlName='show_office' />
              <span>show phone on Message </span>
            </label>

            <div class="input-field col s12 m8">
              <i class="material-icons prefix blue-text">email</i>
              <input id="Email_Address" type="text" formControlName='Email_Address' class="validate"
                autocomplete="off" />
              <label for="Email_Address">Email Address</label>
              <div *ngIf="(Email_Address.dirty || Email_Address.touched) && Email_Address.invalid">
                <small *ngIf="Email_Address.errors.email" class="red-tex errMsgt">Invalid Email Address</small>
                <small *ngIf="Email_Address.errors.required" class="red-text errMsg">Email Address is required</small>
              </div>
            </div>

            <label class="col s12 m4">
              <input name="filter" type="checkbox" formControlName='show_email' />
              <span>show email on Message </span>
            </label>

            <div class="input-field col s12 m8">
              <i class="material-icons prefix blue-text">title</i>
              <input id="Title" type="text" formControlName='Title' class="validate" autocomplete="off"
                style="text-transform: capitalize;" />
              <label for="Title">Title</label>
              <div *ngIf="(Title.dirty || Title.touched) && Title.invalid">
                <small *ngIf="Title.errors.required" class="red-text errMsg">Title is required</small>
              </div>
            </div>

            <label class="col s12 m4">
              <input name="filter" type="checkbox" formControlName='show_title' />
              <span>show title on Message </span>
            </label>

            <div class="row">
              <div class="input-field col s12 center">
                <button type="submit" [disabled]="!contactInfo.valid" class='col s12 btn waves-effect green'
                  style="margin-bottom: 15px;">
                  <i class="material-icons right">edit</i> Update Contact Info
                </button>

                <button type="button" class='col s12 btn waves-effect red' (click)="cancel()">
                  <i class="material-icons right">close</i> Cancel
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

    </main>

    <!----------------------------------  Attach File to email new update ------------------------------------------->
    <div class="row col s12" *ngIf="false">
      <p class="center"><strong> Total : <span class="green-text"></span> {{count}} </strong> </p>
      <table class="striped centered hide-on-med-and-down" *ngIf="count > 0">
        <thead>
          <tr>
            <th>
              <label style="transform: scale(0.5)">
                <input type="checkbox" class="filled-in left" />
                <span></span>
              </label> Name
            </th>
            <th class="hide-on-small-only">DOB</th>
            <th class="hide-on-small-only">Registered</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let m of membersData | paginate: { itemsPerPage: 20, currentPage: p }; let i=index;">
            <td>
              <label>
                <input type="checkbox" style="width: 15px; height: 15px;" class="filled-in left" />
                <span></span>
              </label>
              <a class="tooltipped" data-position="top" data-tooltip="View Member Profile" style="cursor: pointer;"
                (click)="onShowProfile(m.applicantID)"><strong> {{m.GivenName}}
                  {{m.LastName}} </strong></a>
            </td>
            <td *ngIf="m.dob" class="hide-on-small-only">{{m.dob | date:
              'dd/MMM/yyyy'}}</td>
            <td *ngIf="m.isRegistred" class="hide-on-small-only"> <i class="material-icons green-text">check_circle</i>
            </td>
            <td *ngIf="!m.isRegistred" class="hide-on-small-only"> <i class="material-icons red-text">cancel</i>
            </td>
            <td style="cursor: pointer;" class="row col s12 show-on-small">
              <i class="material-icons">
                more_horiz
              </i>
              <div class="hide-on-small-only">
                <span>
                  <a class="btn-flat blue-text">
                    <i class="material-icons blue-text text-darken-2 center tooltipped" data-position="top"
                      data-tooltip="Send Email">
                      email
                    </i>
                  </a>
                </span>
                <a class="btn-flat green-text">
                  <i class="material-icons green-text text-darken-2 center tooltipped" data-position="top"
                    data-tooltip="Send SMS">
                    textsms
                  </i>
                </a>
                <a class="btn-flat red-text">
                  <i class="material-icons red-text text-darken-2 center tooltipped" data-position="top"
                    data-tooltip="Invite">
                    person_add
                  </i>
                </a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="row col s12 right hide-on-large-only">
        <label class="col m6 s12">
          <input type="checkbox" class="filled-in" [checked]="selectAllChecked" (click)="selectAll()" />
          <span> Select All Emails<span class="badge" *ngIf="emailList.length > 0">(
              {{emailList.length}})</span></span>
        </label>

        <label class="col m6 s12">
          <input type="checkbox" class="filled-in" [checked]="selectAllNosChecked" (click)="selectAllNos(p)" />
          <span> Select All Mobile<span class="badge" *ngIf="phoneNoList.length > 0">(
              {{phoneNoList.length}})</span></span>
        </label>
      </div>

      <table class="striped centered hide-on-large-only" *ngIf="count > 0">
        <thead>
          <tr>
            <th>Name</th>
            <th>Dob</th>
            <th>View Profile</th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let m of membersData | paginate: { itemsPerPage: 20, currentPage: p }; let i=index;">
            <td (click)="showDetail(m)" Class="modal-trigger" href="#memberDetailModal"><strong> {{m.GivenName}}
                {{m.LastName}}</strong></td>
            <td (click)="showDetail(m)" Class="modal-trigger" href="#memberDetailModal">{{m.dob | date:
              'dd/MMM/yyyy'}}</td>
            <td> <a class="tooltipped center-align" data-position="top" data-tooltip="View Member Profile"
                (click)="onShowProfile(m.applicantID)"><i class="material-icons">
                  preview</i></a>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="row center" *ngIf="count > 0">
        <pagination-controls (pageChange)="p = $event"></pagination-controls>
      </div>
    </div>

    <table class="striped centered hide-on-med-and-down" *ngIf="false">
      <thead>
        <tr>
          <th>
            <label style="transform: scale(0.5)">
              <input type="checkbox" class="filled-in left" />
              <span></span>
            </label> Name
          </th>
          <!-- <th>Dob</th>
      <th class="hide-on-small-only">Email</th> -->
          <th class="hide-on-small-only">Registered</th>
          <!-- <th class="hide-on-small-only">Mobile Number</th> -->
          <!-- <th class="hide-on-small-only">Send Email
        <label class="col s12">
          <input type="checkbox" class="filled-in" [checked]="selectAllChecked" (click)="selectAll()" />
          <span> All</span>
        </label>
        <span class="badge" *ngIf="emailList.length > 0">{{emailList.length}}</span>
      </th> -->
          <!-- <th class="hide-on-small-only">Send SMS
        <label class="col s12">
          <input type="checkbox" class="filled-in" [checked]="selectAllNosChecked"
            (click)="selectAllNos(p)" />
          <span> All</span>
        </label>
        <span class="badge" *ngIf="phoneNoList.length > 0">{{phoneNoList.length}}</span>
      </th> -->
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let m of membersData | paginate: { itemsPerPage: 20, currentPage: p }; let i=index;">
          <td>
            <label>
              <input type="checkbox" style="width: 15px; height: 15px;" class="filled-in left" />
              <span></span>
            </label>
            <a class="tooltipped" data-position="top" data-tooltip="View Member Profile" style="cursor: pointer;"
              (click)="onShowProfile(m.applicantID)"><strong> {{m.GivenName}}
                {{m.LastName}} </strong></a>
          </td>
          <!-- <td>{{m.dob | date: 'dd/MMM/yyyy'}}</td>
      <td class="hide-on-small-only">{{m.Email}}</td> -->
          <td *ngIf="m.isRegistred" class="hide-on-small-only"> <i class="material-icons green-text">check_circle</i>
          </td>
          <td *ngIf="!m.isRegistred" class="hide-on-small-only"> <i class="material-icons red-text">cancel</i>
          </td>
          <!-- <td class="hide-on-small-only">{{m.mobileNumber}} </td> -->
          <!-- <td class="hide-on-small-only">
        <label>
          <input [disabled]="!m.Email" type="checkbox" [checked]="emailList.includes(m.Email)"
            class="filled-in" (click)="emailSelected(m.Email)" />
          <span></span>
        </label>
      </td>
      <td class="hide-on-small-only">
        <label>
          <input [disabled]="!m.mobileNumber" type="checkbox" [checked]="phoneNoList.includes(m.mobileNumber)"
            class="filled-in" (click)="phoneNoSelected(m.mobileNumber)" />
          <span></span>
        </label>
      </td> -->

        </tr>
      </tbody>
    </table>

    <div class="row col s12 right hide-on-large-only" *ngIf="false">
      <label class="col m6 s12">
        <input type="checkbox" class="filled-in" [checked]="selectAllChecked" (click)="selectAll()" />
        <span> Select All Emails<span class="badge" *ngIf="emailList.length > 0">(
            {{emailList.length}})</span></span>
      </label>

      <label class="col m6 s12">
        <input type="checkbox" class="filled-in" [checked]="selectAllNosChecked" (click)="selectAllNos(p)" />
        <span> Select All Mobile<span class="badge" *ngIf="phoneNoList.length > 0">(
            {{phoneNoList.length}})</span></span>
      </label>
    </div>

    <table class="striped centered hide-on-large-only" *ngIf="false">
      <thead>
        <tr>
          <th>Name</th>
          <th>Dob</th>
          <th>View Profile</th>
        </tr>
      </thead>

      <tbody>
        <tr *ngFor="let m of membersData | paginate: { itemsPerPage: 20, currentPage: p }; let i=index;">
          <td (click)="showDetail(m)" Class="modal-trigger" href="#memberDetailModal"><strong> {{m.GivenName}}
              {{m.LastName}}</strong></td>
          <td (click)="showDetail(m)" Class="modal-trigger" href="#memberDetailModal">{{m.dob | date:
            'dd/MMM/yyyy'}}</td>
          <!-- <td> <a class="tooltipped center-align" data-position="top" data-tooltip="View Member Profile"
          (click)="onShowProfile(m.applicantID)"><i class="material-icons">
            preview</i></a>
      </td> -->
          <td style="cursor: pointer;"><i class="material-icons">
              more_horiz
            </i></td>
        </tr>
      </tbody>
    </table>

  </div>
</div>