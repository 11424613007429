import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CalendarDateFormatter, CalendarEvent, CalendarView } from 'angular-calendar';
import { addDays, isSameDay } from 'date-fns';
import { Subject } from 'rxjs';
import { CustomDateFormatter } from 'src/app/models/custom-date-formatter.provider';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { EventsService } from 'src/app/services/firebase/events.service';
import { environment } from 'src/environments/environment';

declare var $: any;

@Component({
  selector: 'app-event-calendar-view',
  templateUrl: './event-calendar-view.component.html',
  styleUrls: ['./event-calendar-view.component.scss'],
  providers: [{
    provide: CalendarDateFormatter,
    useClass: CustomDateFormatter,
  }]
})

export class EventCalendarViewComponent implements OnInit {
  //@Input() model: any[];
  @Input() isMember: false;

  defaultThemeColor = environment.appTheme.themeColor;
  themeColor: any;
  model: any;
  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  //events: CalendarEvent[] = [];
  viewDate: Date = new Date();
  refresh: Subject<any> = new Subject();
  activeDayIsOpen = false;
  eventSection: any;
  events: any[];
  sectionName: any;
  isIncludePastEvents = true;
  filteredEvents: any[] = [];
  switchText: string = "Include Past Events";
  isActiveEvents: boolean = true;
  isInactiveEvents: boolean = false;

  constructor(
    private router: Router,
    private appSettingService: AppSettingsService,
    private eventService: EventsService) {
    this.themeColor = this.appSettingService.getAppThemeColor() ? this.appSettingService.getAppThemeColor() : this.defaultThemeColor;
    this.appSettingService.getAppSetting().valueChanges().subscribe(
      appSettings => {
        if (appSettings) {
          if (appSettings.length > 0) {
            this.model = appSettings[0];
            this.themeColor = this.model.themeColor ? this.model.themeColor : this.defaultThemeColor;
          }
        }
      });
  }

  ngOnInit() {
    this.sectionName = this.appSettingService.getCurrentMenu();
    this.eventService.getPublicEventsList().valueChanges().subscribe(events => {
      if (events) this.events = events;
      this.filterEvents();
      this.activeEvents();
    });

    $(document).ready(function () {
      $(".post-module").hover(function () {
        $(this).find(".description").stop().animate(
          {
            height: "toggle",
            opacity: "toggle"
          },
          300
        );
      });
    });

    this.fetchEvents(this.model);
    this.refresh.next(null);
    setTimeout(() => {
      $('.tabs').tabs();
    }, 25);
  }

  // fetchEvents(events: any[]) {
  //   events.forEach(element => {
  fetchEvents(events: any[]) {
    if (events && events.length > 0) {
      events.forEach(element => {

        const event = {
          eventDate: element.eventDate,
          eventEnd: element.eventDate,
          id: element.id,
          title: element.title
        };

        this.events.push({
          start: new Date(element.eventDate),
          end: addDays(new Date(element.expiryDate), -1),
          title: element.title,
          allDay: true,
          meta: {
            event,
          },
        });
      });
    }
  }
 


  activeEvents() {
    this.isIncludePastEvents = false;
    this.switchText = "Include Past Events";
    this.filterEvents();
    this.isActiveEvents = true; // Set isActiveEvents to true when Active Events button is clicked
    this.isInactiveEvents = false; // Reset isInactiveEvents
  }

  inactiveEvents() {
    this.isIncludePastEvents = true;
    this.switchText = "Exclude Past Events";
    this.filterEvents();
    this.isActiveEvents = false; // Reset isActiveEvents
    this.isInactiveEvents = true; // Set isInactiveEvents to true when Inactive Events button is clicked
  }

  allEvents() {
    this.isIncludePastEvents = false;
    this.switchText = "Include Past Events";
    this.filteredEvents = this.events;
    this.isActiveEvents = false; // Reset isActiveEvents
    this.isInactiveEvents = false; // Reset isInactiveEvents
  }


  filterEvents() {
    const currentDate = new Date();
    if (this.isIncludePastEvents) {
      // Show only inactive events (events before the current date)
      this.filteredEvents = this.events.filter(event => {
        const eventDate = new Date(event.eventDate);
        return eventDate < currentDate;
      });
    } else {
      // Show only active events (events after the current date)
      this.filteredEvents = this.events.filter(event => {
        const eventDate = new Date(event.eventDate);
        return eventDate >= currentDate;
      });
    }
  }


  toggleSwitch(event: any) {
    this.isIncludePastEvents = event.target.checked;
    this.switchText = this.isIncludePastEvents ? "Exclude Past Events" : "Include Past Events";
    this.filterEvents();
  }


  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (
      (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
      events.length === 0
    ) {
      this.activeDayIsOpen = false;
    } else {
      this.activeDayIsOpen = true;
    }
    this.viewDate = date;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }


  eventClicked({ event }: { event: CalendarEvent }): void {
    const eventLink = this.isMember ? '/member/event-detail' : '/events-detail';
    this.router.navigate([eventLink, event.meta.event.id]);
  }
}
