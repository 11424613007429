<div class="row container-pi">
  <h4 class="title-section1  title-member icon-texts"><span class="material-symbols-outlined back-icon p-r-1" (click)=" backClicked()">
    arrow_circle_left
</span>Member Events</h4>

  <app-firebase-loading *ngIf="!model"></app-firebase-loading>

  <div class="col s12 l12" *ngIf="model">

    <div class="row card-panel b-n-1" *ngIf="eventSection">
      <div class="col l12 m12 s12 container-events">
        <div class="col s12 m6 l4 xl4" *ngFor="let event of events">
          <a [routerLink]="['/events-detail', event.id]">
            <div class="example-2 card">

              <!-- =============================== image card -->
              <div class="wrapper" [ngStyle]="{'background-color': themeColor} " *ngIf="event.imgLink">
                <img class="wrapper responsive-img " [src]="event.imgLink">
                <div class="header">
                  <div class="date" [ngStyle]="{'color': themeColor} ">
                    <span class="day">{{event.eventDate.split('/')[0]}}</span>
                    <span class="month">{{event.eventDate.split('/')[1]}}</span>
                  </div>
                </div>

                <div class="data">
                  <div class="content">
                    <span class="author " *ngIf="event.startsAt"><i
                        class="tiny material-symbols-outlined p-r-1">schedule</i>
                      {{event.startsAt}} - {{event.endsAt}} </span>
                    <h1 class="title  short-body-snippet"><a href="#"><span [innerHTML]="event.title"></span></a></h1>
                    <p class="text"><span class="description-section bold-100 middle-body-snippet"
                        [innerHTML]="event.body"></span></p>
                    <a [routerLink]="['/events-detail', event.id]" class="button left"
                      [ngStyle]="{'color': themeColor} ">Read more</a>
                  </div>
                </div>
              </div>

              <!-- =============================== no image card -->
              <div class="wrapper" [ngStyle]="{'background-color': themeColor} " *ngIf="!event.imgLink">

                <div class="header">
                  <div class="date" [ngStyle]="{'color': themeColor} ">
                    <span class="day">{{event.eventDate.split('/')[0]}}</span>
                    <span class="month">{{event.eventDate.split('/')[1]}}</span>
                  </div>
                </div>

                <div class="data1">
                  <div class="content">
                    <span class="author " *ngIf="event.startsAt"><i
                        class="tiny material-symbols-outlined p-r-1">schedule</i>
                      {{event.startsAt}} - {{event.endsAt}} </span>
                    <h1 class="title short-body-snippet"><a href="#"><span [innerHTML]="event.title"></span></a></h1>
                    <p class="text"><span class="description-section bold-100 middle-body-snippet"
                        [innerHTML]="event.body"></span></p>
                    <a [routerLink]="['/events-detail', event.id]" class="button left"
                      [ngStyle]="{'color': themeColor} ">Read
                      more</a>
                  </div>
                </div>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</div>