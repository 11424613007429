import { Component, OnInit } from '@angular/core';
import { MemberService } from 'src/app/services/api/member.service';
import { FamilyCompositionService } from 'src/app/services/firebase/family-composition.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { MemberModel } from 'src/app/models/memberModel';
import {Location} from '@angular/common';
import { environment } from 'src/environments/environment';

declare var $: any;

@Component({
  selector: 'app-family-composition-firebase',
  templateUrl: './family-composition-firebase.component.html',
  styleUrls: ['./family-composition-firebase.component.css']
})
export class FamilyCompositionFirebaseComponent implements OnInit {

  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  nationOfuse = environment.firstNation.name;
  isDemo = false;
  member: MemberModel;
  familyMembers: any[];
  applicantID = 0;
  // tslint:disable-next-line: max-line-length
  constructor(private authService: AuthService, private memberService: MemberService, private familyCompositionService: FamilyCompositionService, private location: Location ) {
    localStorage.setItem('from', 'PersonalInfo');
  }

  ngOnInit() {
    if (this.nationOfuse === 'Demo') {this.isDemo = true; }

    if (localStorage.getItem('applicantID')) {
      this.applicantID = Number(localStorage.getItem('applicantID'));

    } else {
      this.applicantID = this.authService.getCurrentApplicantID();
    }
    this.memberService.getMember(this.applicantID).subscribe(x => {
      this.member = x[0];
    this.familyCompositionService.getFamilyMembersList(this.member.applicantID).valueChanges().subscribe(x => {
      if (x) {
        this.familyMembers = x;
        this.familyMembers.sort((a, b) => (a.index < b.index ? -1 : 1));
        setTimeout(() => {
          $('.collapsible').collapsible();
        }, 25);
      }
    });
    });
    $('.collapsible').collapsible();
  }

  backClicked() {
    this.location.back();
  }
}
