<div class="body-h">
  <div class="header-setting b-m-2">
    <div class="col l12 m12 s12">
      <h4 class="">Polls/Referendum List </h4>
    </div>
  </div>

  <main class="StickyContent white row">
    <div class="col s12">
      <app-firebase-loading *ngIf="showLoader"></app-firebase-loading>

      <div class="row card-panel white col s12" *ngIf="!model && !showLoader">
        <p class="center"> <strong class="red-text"> <i class="material-icons">warning</i> 0 Poll Found In the
            Database</strong> </p>
      </div>

      <div *ngIf="model">
        <div class="row card-panel white" *ngFor="let item of model; let i=index;">
          <div class="col s12">
            <div class="col s4">
              <a *ngIf="item.status.open" [routerLink]="['/member/poll-detail', item.id]"
                class="truncate">{{item.name}}</a>
              <a *ngIf="!item.status.open" (click)="closed()" class="truncate">{{item.name}}</a>
            </div>

            <div class="col s4">
              <!-- <span>Ends In 3 Days</span> -->
              <span class="col s12">{{item.status.endAt}}</span>
              <span *ngIf="item.status.open" class="green-text col s12">OPEN</span>
              <span *ngIf="!item.status.open" class="red-text col s12">CLOSED</span>
            </div>
            <div class="col s4" *ngIf="item.status.showResult">
              <a class="center col btn-flat s12 blue lighten-4" [routerLink]="['/member/poll-result', item.id]"> <span
                  style="cursor: pointer;"><i class="material-icons black-text" style="margin-right: 7px;">poll</i>
                  <strong class="black-text">View Results</strong></span> </a>
            </div>
            <div class="col s4" *ngIf="!item.status.showResult">
              <p class="red-text center ">Result Not Available</p>
            </div>
          </div>
        </div>
      </div>

      <div class="section"></div>
      <button [ngClass]="'col s12 btn waves-effect ' + themeColor" (click)="backClicked()">
        <i class="material-icons left">arrow_back_ios</i> Back To Previous Page
      </button>
    </div>
  </main>
</div>