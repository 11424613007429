<div class="col s12 p-t-5">
  <div class="body-h card-panel" *ngIf="invitation">
    <div class="header-setting">
      <div class="col l12 m12 s12">
        <h4 class="icon-text">Admin SignUp</h4>
      </div>
    </div>

    <main class="StickyContent">
      <div class="row col s12 m11 l11">
        <!--Step 1 - Verfication-->
        <div *ngIf="step1" class="row p-l-2">

          <div class="row title">
            <h2 class="center bold-600 col s12 title-section2">
              Welcome,&nbsp;
              <span [ngClass]="fontColor" *ngIf="invitation.firstName">{{invitation.firstName}} </span>
            </h2>
            <h2 class="center bold-100 col s12 m12 l12" style="font-family:'sans-serif'; font-size: 14px;"
              [ngStyle]="{'color': themeColor}">This
              is
              a secure site. Please help us verify your identity by answering the
              questions below.</h2>
          </div>

          <form [formGroup]="verificationForm">

            <!--Verify SIN-->
            <!-- <div class="row" *ngIf="verifiySIN">
            <div class="input-field col s11">
              <p class="sub-title-section3 bold-500">Please Enter The Last <strong class="orange-text">3
                  Digits </strong>Of Your Social Insurance Number<span class="red-text"> (*)</span></p>
              <input id="sin" type="text" maxlength="9" onkeypress="return isNumberKey(event)"
                onkeydown="return sinPreventDelete(event,6)" formControlName='sin' required
                class="validate white black-text bold-300" autocomplete="off" />
              <div *ngIf="(sin.dirty || sin.touched) && sin.invalid">
                <small *ngIf="sin.errors.required" class="red-text"> The SIN is Required. </small>
                <small *ngIf="sin.errors.minlength || sin.errors.minlength" class="red-text"> SIN Must be 9 digits.
                </small>
              </div>
            </div>
          </div> -->

            <!--Verify ISN-->
            <!-- <div class="row" *ngIf="verifiyISN">
            <div class="input-field col s11">
              <p class="sub-title-section3 bold-500">Please Enter The Last <strong class="orange-text">5 Digits</strong>
                Of Your
                Indian Status Number<span class="red-text"> (*)</span></p>

              <input id="isn" type="text" formControlName='isn' required class="validate white black-text bold-300"
                autocomplete="off" maxlength="10" onkeypress="return isNumberKey(event)"
                onkeydown="return isnPreventDelete(event,6)" />

              <div *ngIf="(isn.dirty || isn.touched) && isn.invalid">
                <small *ngIf="isn.errors.required" class="red-text"> The ISN is Required. </small>
                <small *ngIf="isn.errors.minlength || isn.errors.minlength" class="red-text"> ISN Must be 10 digits.
                </small>
              </div>
            </div>

            <div class="col s1">
              <div class="row valign-wrapper">
                <i class="material-icons gray-text darken-3 modal-trigger" style="padding-top: 10vh;cursor: pointer;"
                  data-target="ISNModal">info</i>
              </div>
            </div>
          </div> -->

            <!--Secret Question-->
            <div class="row" *ngIf="secretQuestion">
              <div class="input-field col s11">
                <p class="sub-title-section3 bold-500">{{invitation.questions[0].question}}<span class="red-text">
                    (*)</span></p>
                <input id="question1" type="text" formControlName='question1' required
                  class="materialize-textarea validate" />
                <div
                  *ngIf="(getFormControl('question1', verificationForm).dirty || getFormControl('question1', verificationForm).touched) && getFormControl('question1', verificationForm).invalid">
                  <small *ngIf="getFormControl('question1', verificationForm).errors.required" class="red-text"> Please
                    answer the secret question
                  </small>
                </div>
              </div>
            </div>

            <div class="row" *ngIf="secretQuestion2">
              <div class="input-field col s11">
                <p class="sub-title-section3 bold-500">{{invitation.questions[1].question}}<span class="red-text">
                    (*)</span></p>
                <input id="question2" type="text" formControlName='question2' required
                  class="materialize-textarea validate" />
                <div
                  *ngIf="(getFormControl('question2', verificationForm).dirty || getFormControl('question2', verificationForm).touched) && getFormControl('question2', verificationForm).invalid">
                  <small *ngIf="getFormControl('question2', verificationForm).errors.required" class="red-text"> Please
                    answer the secret question
                  </small>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="input-field col s11 center">
                <button type="submit" [disabled]="!verificationForm.valid" class="col s12 btn"
                  [ngClass]="'btn ' + buttonType" [ngStyle]="{'background-color': themeColor}"
                  (click)="verification(verificationForm)">
                  <i class="material-icons left">lock</i> Verify
                </button>
              </div>
            </div>
          </form>
        </div>

        <!-- Step 2 - Registration -->
        <div *ngIf="step2" class="row p-l-2">

          <div class="row title">
            <h2 class="center bold-600 col s12 title-section2">
              Create Password
            </h2>
            <h2 class="center bold-100 col s12 m12 l12" style="font-family:'sans-serif'; font-size: 14px;"
              [ngStyle]="{'color': themeColor}">
              Create a strong password with a mix of letters, numbers and symbols</h2>
          </div>

          <div class="card-panel red lighten-1 white-text" *ngIf="error">
            <!-- <h5 class="sub-title-section bold-500">Invitation Already Sent.</h5> -->
            <p>{{errorMessage}}</p>
          </div>

          <div class="row">
            <div class="input-field col s11 m10">
              <p class="label-input">Email<span class="red-text"> (*)</span></p>
              <input id="Email" type="text" [(ngModel)]="invitation.email" disabled />
            </div>

            <form [formGroup]="registrationForm" (ngSubmit)="register(registrationForm.value)">

              <div class="row">
                <div class="input-field col s11 m10">
                  <p class="label-input">Create Password<span class="red-text"> (*)</span></p>

                  <input *ngIf="!showPassword" id="createPassword" type="password" formControlName='createPassword'
                    required class="validate" minlength="4" maxlength="25" />
                  <input *ngIf="showPassword" id="createPassword" type="text" formControlName='createPassword' required
                    class="validate" minlength="4" maxlength="25" />
                  <span *ngIf="!showPassword" (click)="toggleVisibility()" class="material-icons field-icon" style="margin-left: -10%;display:inline;
                  vertical-align: middle; font-size: 2.5vh; cursor: pointer;">
                    visibility_off
                  </span>
                  <span *ngIf="showPassword" (click)="toggleVisibility()" class="material-icons field-icon" style="margin-left: -10%;display:inline;
                  vertical-align: middle; font-size: 2.5vh; cursor: pointer;">
                    visibility
                  </span>
                  <div *ngIf="(createPassword.dirty || createPassword.touched) && createPassword.invalid">
                    <small *ngIf="createPassword.errors.required" class="red-text">The Password Is Required.</small>
                    <small *ngIf="createPassword.errors.minlength" class="red-text"> Password Length Must Be At Least 4
                      Characters. </small>
                    <small *ngIf="createPassword.errors.maxlength" class="red-text"> Password Must Not Exceed 25
                      Characters. </small>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="input-field col s11 m10">
                  <p class="label-input">Confirm Password<span class="red-text"> (*)</span></p>
                  <input *ngIf="!showConfirmPassword" id="confirmPassword" type="password" required
                    formControlName='confirmPassword' class="validate">
                  <input *ngIf="showConfirmPassword" id="confirmPassword" type="text" required
                    formControlName='confirmPassword' class="validate">
                  <span *ngIf="!showConfirmPassword" (click)="toggleVisibility1()" class="material-icons field-icon"
                    style="margin-left: -10%;display:inline;
                  vertical-align: middle; font-size: 2.5vh; cursor: pointer;">
                    visibility_off
                  </span>
                  <span *ngIf="showConfirmPassword" (click)="toggleVisibility1()" class="material-icons field-icon"
                    style="margin-left: -10%;display:inline;
                  vertical-align: middle; font-size: 2.5vh; cursor: pointer;">
                    visibility
                  </span>
                  <div *ngIf="(confirmPassword.dirty || confirmPassword.touched) && confirmPassword.invalid">
                    <small *ngIf="confirmPassword.errors.required" class="red-text">Confirm Password is required.
                    </small>
                    <small *ngIf="confirmPassword.hasError('invalid')" class="red-text">Passwords Doesnt Match !
                    </small>
                  </div>
                </div>
              </div>

              <div class="section"></div>
              <div class="row center">
                <p><strong>By Clicking <span class="green-text">Create My Account</span> I hereby :</strong></p>
                <p><strong>Agree and consent to the <a [routerLink]="['/eula']">User Agreement</a>, its policies and the
                    <a [routerLink]="['/privacy-policy']">Privacy Policy</a>.</strong> </p>
              </div>

              <div class="row">
                <div class="input-field col s11 m10 center">
                  <button type="submit" [disabled]="!registrationForm.valid" class="col s12 btn"
                    [ngClass]="'btn ' + buttonType" [ngStyle]="{'background-color': themeColor}">
                    <i class="material-icons left">how_to_reg</i> Create My Account
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </main>
  </div>

  <!--Modals-->
  <div id="SINModal" class="modal">
    <div class="modal-content">
      <h6>Client Identification Numbers for Eligible First Nations</h6>
      <p>Provision of one of these identifiers is required for eligible First
        Nations clients (including registered Indians under the terms of the Indian Act):</p>
      <p>
        ISC Number (also known Treaty or Status Number)
      </p>
      <p>
        This is a 10-digit number issued by Indian and Northern Affairs Canada (ISC),
        formerly known as DIAND, to all eligible First Nations clients.
      </p>
      <p>
        The ISC number is the preferred method of identifying First Nations clients. This number is made up of:
        <br />
        The first 3 digits identify the band to which the client belongs;<br />
        The next 5 digits identify the client's family unit within the band;<br /> and
        The last 2 digits identify the client's position in the family (for example, 01, 02, and 03).
      </p>
    </div>
    <div class="modal-footer">
      <a class="modal-close amber darken-4 white-text btn-flat white-text"> <i
          class="material-icons right">close</i>close</a>
    </div>
  </div>

  <div id="ISNModal" class="modal">
    <div class="modal-content">
      <h6>Client Identification Numbers for Eligible First Nations</h6>
      <p>Provision of one of these identifiers is required for eligible First
        Nations clients (including registered Indians under the terms of the Indian Act):</p>
      <p>
        ISC Number (also known Treaty or Status Number)
      </p>
      <p>
        This is a 10-digit number issued by Indian and Northern Affairs Canada (ISC),
        formerly known as DIAND, to all eligible First Nations clients.
      </p>
      <p>
        The ISC number is the preferred method of identifying First Nations clients. This number is made up of:
        <br />
        The first 3 digits identify the band to which the client belongs;<br />
        The next 5 digits identify the client's family unit within the band;<br /> and
        The last 2 digits identify the client's position in the family (for example, 01, 02, and 03).
      </p>
    </div>
    <div class="modal-footer">
      <a class="modal-close amber darken-4 white-text btn-flat white-text"> <i
          class="material-icons right">close</i>close</a>
    </div>
  </div>

</div>