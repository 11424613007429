import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SessionsMonitorService } from 'src/app/services/firebase/sessions-monitor.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-activity-detail',
  templateUrl: './activity-detail.component.html',
  styleUrls: ['./activity-detail.component.css']
})
export class ActivityDetailComponent implements OnInit {

  themeColor = environment.appTheme.themeColor;
  sessionID: any;
  selectedSession: any;
  lat: any;
  lng: any;
  zoom = 15;

  constructor(private route: ActivatedRoute, private session: SessionsMonitorService, private location: Location) {
    this.route.paramMap.subscribe(params => {
      this.sessionID = params.get('id');
    });
  }

  ngOnInit() {
    if (this.sessionID) {
      this.session.getSessionsByID(this.sessionID).valueChanges().subscribe(s => {
        if (s) {
          this.selectedSession = s;
          this.lat = this.selectedSession.latitude
          this.lng = this.selectedSession.longitude
        }
      })
    }
  }

  getDateDiff(startDate, endDate) {
    if (startDate && endDate) {
      const diff = endDate.toDate().getTime() - startDate.toDate().getTime();
      const days = Math.floor(diff / (60 * 60 * 24 * 1000));
      const hours = Math.floor(diff / (60 * 60 * 1000)) - (days * 24);
      const minutes = Math.floor(diff / (60 * 1000)) - ((days * 24 * 60) + (hours * 60));
      const seconds = Math.floor(diff / 1000) - ((days * 24 * 60 * 60) + (hours * 60 * 60) + (minutes * 60));

      let datediff = '';
      if (hours > 0) { datediff += hours + 'h '; }
      if (minutes > 0) { datediff += minutes + 'm '; }
      if (seconds > 0) { datediff += seconds + 's '; }
      return datediff;
    }
    return 'N/A'
  }

  backClicked() {
    this.location.back();
  }
}
