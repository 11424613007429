import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PSEApplicationModel } from 'src/app/models/PSEApplicationModel';
import { CommunityService } from 'src/app/services/api/community.service';
import { MemberService } from 'src/app/services/api/member.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { environment } from 'src/environments/environment';
import { toast } from 'materialize-css';
import { PseApplicationService } from 'src/app/services/api/pse-application.service';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-pse-app-v2',
  templateUrl: './pse-app-v2.component.html',
  styleUrls: ['./pse-app-v2.component.css']
})

export class PseAppV2Component implements OnInit {

  nationofUse = environment.firstNation.name;
  provinceOfUse = environment.firstNation.province;

  step1 = true;
  step2 = false;
  step3 = false;
  step4 = false;
  step5 = false;
  step6 = false;
  step7 = false;
  step8 = false;
  step9 = false;

  provinces = [{ name: 'Alberta', code: 'AB' },
  { name: 'British Columbia', code: 'BC' },
  { name: 'Manitoba', code: 'MB' },
  { name: 'New Brunswick', code: 'NB' },
  { name: 'Newfoundland and Labrador', code: 'NL' },
  { name: 'Nova Scotia', code: 'NS' },
  { name: 'Ontario', code: 'ON' },
  { name: 'Prince Edward Island', code: 'PE' },
  { name: 'Quebec', code: 'QC' },
  { name: 'Saskatchewan', code: 'SK' }
  ];
  maritalStatusList: any =['Single', 'Married or Equivalent', 'Divorced', 'Widowed', 'Separated'];
  RelationshipList = ["Spouse", "Dependant"];
  EduLevelList = ["Preschool", "Up to Grade 7-8 (Sec. I = Grade 8)", "Grade 9-10 (Sec. II-III)", "Grade 11-12 (Sec. IV-V)",
      "Secondary School Diploma or GED",  "Some post-secondary training", "Apprenticeship or trades certificate or diploma", 
      "College", "CEGEP", "University certificate or diploma", "University - Bachelor Degree", "University – Master’s degree", "University – Doctorate", "Course or certificate", "Other"];
  grades = ['Grade 10', 'Grade 11', 'Grade 12'];
  graduation_result=['University/College Entrance Preparation', 'Diploma', 'Certificate', 'Bachelors', 'Masters', 'Doctorate', 'Postdoctoral'];
  courseTerms = ['FALL', 'WINTER',  'SPRING', 'SUMMER' ];
  
  public postalCodeMask = [/[A-Za-z]/, /[0-9]/, /[A-Za-z]/, ' ', /[0-9]/, /[A-Za-z]/, /[0-9]/];
  public phoneNumberMask = ['(', /[0-9]/, /[0-9]/, /[0-9]/, ')', ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  public dateMask = [/[0-3]/, /[0-9]/, '/', /[0-1]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  public SINMask = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  public isnMask = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  public dateformat = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
  public yearMask = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  public yearsMask = [/[0-9]/];
  public bankNoMask = [/[0-9]/, /[0-9]/, /[0-9]/];
  public branchNoMask = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];

  model: PSEApplicationModel;
  applicantID: any;
  member: any;
  communityList: any[];
  otherLocation = false;
  HasSpouse= false;
  isEmployed= false;
  hasIncome=false;
  spousehasIncome = false;
  leaveOfAbsence=false;
  studentInfoForm: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private memberService: MemberService,
    private communityService: CommunityService,
    private pseService : PseApplicationService,
  ) { }

  ngOnInit(): void {
    this.applicantID = this.authService.getCurrentApplicantID();
  }

  // ------------------------ Step 1 -------------------------------------------

  newApplication() {

    this.communityService.getCommunityList().subscribe(com => {
      if (com) { this.communityList = com; }})

    this.memberService.getMember(this.applicantID).subscribe(
      m => {
        if (m) {
          if (m.length == 0) return;
          this.member = m[0];

          var array = this.member.DOB.split("-");
          var date = `${array[2].substring(0, 2)}/${array[1]}/${array[0]}`;

          var phone = ''
          if (this.member.Telephone) {
            phone = this.member.Telephone;
            phone = phone.replace('(', '');
            phone = phone.replace(')', '');
            phone = phone.replace(' ', '');
            phone = phone.replace('-', '');
            phone = phone.replace('  ', '');
            phone = phone.replace('_', '');
          }

          var mobile = '';
          if (this.member.cell_phone_num) {
            mobile = this.member.cell_phone_num;
            mobile = mobile.replace('(', '');
            mobile = mobile.replace(')', '');
            mobile = mobile.replace(' ', '');
            mobile = mobile.replace('-', '');
            mobile = mobile.replace('  ', '');
            mobile = mobile.replace('_', '');
          }

          this.studentInfoForm = this.fb.group({
            name: [{ value: this.member.GivenName + ' ' + this.member.LastName, disabled: true }],
            dob: [{ value: date, disabled: true }],
            address: [this.member.MailAddress, [Validators.required]],
            city: [this.member.city, [Validators.required]],
            province: [this.member.province,[Validators.required]],
            postal_code: [this.member.PostalCode, [Validators.required]],
            home_number: [phone, []],
            mobile: [mobile, [Validators.required]],
            SIN: [this.member.SIN, [Validators.minLength(9), Validators.maxLength(9), Validators.pattern('^[0-9]*$')]],
            HealthCareNo: [this.member.HealthCareNo],
            isn_no: [this.member.IndianStatusNo],
            email: [this.member.Email, [Validators.email]],
            marital_status: [this.member.MaritalStatus, [Validators.required]],
            reside: ['', []],
            community: [this.member.Community, [Validators.required]],
            other_location: ['', []],
            employed: ['', []],
            employer: ['', []],
            leaveofabsence: ['', []],
            withpay: ['', []],
            income: ['', []],
            spouse_classification: ['', []],
            spouse_income: ['', []],
            bank_name: ['', []],
            bank_no: ['', []],
            transit_no: ['', []],
            account_no: ['', []],
            house_status: ['', [Validators.required]],
          });

          setTimeout(() => {
            this.step1 = false;
            this.step2 = true;
                   
          }, 25);

          setTimeout(() => {
            $('select').formSelect();
            M.updateTextFields();    
          }, 250);
        }
      }
    )
  }

  maritalStatusChange(value) {
    if (value == 'Married' || value == "Common Law" || value =="Married or Equivalent") {
      this.HasSpouse = true;
    } else {
      this.HasSpouse = false;
    }

    setTimeout(() => {
      M.updateTextFields();
    }, 25);
  }

  communitySelected(value) {
    if (value === 'Other') {
      this.otherLocation = true;
    } else {
      this.otherLocation = false;
    }

    setTimeout(() => {
      M.updateTextFields();
    }, 25);
  }

  onEmployedChecked(value) {
    if (value == 'yes') {
      this.isEmployed = true;
    } else {
      this.isEmployed = false;
      this.studentInfoForm.get('employer').reset();
    }
    
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25); 
  }

  onWithPayChecked(value){
    if (value == 'yes') {
      this.hasIncome = true;
    } else {
      this.hasIncome = false;
      this.studentInfoForm.get('income').reset();
    }    
    setTimeout(() => {
      M.updateTextFields();
    }, 25);    
  }

  onleaveofabsenceChange(value){
    if (value == 'yes') {
      this.leaveOfAbsence = true;
    } else {
      this.leaveOfAbsence = false;
    }    
    setTimeout(() => {
      M.updateTextFields();
    }, 25);    
  }

  onspouseClassificationChecked(value){
    if (value == 'Employed') {
      this.spousehasIncome = true;
    } else {
      this.spousehasIncome = false;
    }    
    setTimeout(() => {
      M.updateTextFields();
    }, 25);    
  }

  submitStudentInfo(values) {
    this.model = new PSEApplicationModel();
    if (!values.marital_status) {
      toast({ html: 'Please Select Marital Status !', classes: 'red' });
      return;
    }

    if (!values.SIN && !values.health_care_no && !values.IndianStatusNo) {
      toast({ html: 'Please Provide at Least One Of The Following : SIN / ISN / Health Care No !', classes: 'red' });
      return;
    }

    // if (this.create_new_app) 
    // { this.studentModel.current_app_id =  0;
    // }else{ this.studentModel.current_app_id =  this.pse_app_id;}

    if (this.member.SIN) {
      this.model.SIN = this.member.SIN;
    } else {
      if (!values.SIN) return;
      if (this.validate_sin(values.SIN)) {

        this.memberService.getSIN(values.SIN).subscribe(x => {
          if (x) {
            let count = x;
            if (count != 0) {
              toast({ html: 'SIN is Already in Use !', classes: 'red' });
              return;
            }
            else {
              this.model.SIN = values.SIN;
            }
          }
        });
      } else {
        toast({ html: 'Please Entet a Valid SIN !', classes: 'red' });
        return;
      }
    }

    if (this.member.HealthCareNo) {
      this.model.HealthCareNo = this.member.HealthCareNo;
    } else {
      if (values.HealthCareNo) {
        this.memberService.getHealthCareNo(values.HealthCareNo).subscribe(x => {
          if (x) {
            let count = x;
            if (count != 0) {
              toast({ html: 'Health Care No Already In Use !', classes: 'red' });
              return;
            } else {
              this.model.HealthCareNo = values.HealthCareNo;
            }
          }
        });

      } else { this.model.HealthCareNo = ""; }

    }

    if (this.member.IndianStatusNo) {
      this.model.IndianStatusNo = this.member.IndianStatusNo;
    } else {
      if (values.isn_no) {
        this.memberService.getISN(values.isn_no).subscribe(x => {
          if (x) {
            let count = x;
            if (count != 0) {
              toast({ html: 'Indian Status Already In Use !', classes: 'red' });
              return;
            } else {
              this.model.IndianStatusNo = values.isn_no;
            }
          }
        });
      } else { this.model.IndianStatusNo = ""; }

    }

    if (values.reside) {
      if (values.reside === 'yes') {
        this.model.reside = 'On Reserve';
      } else {
        this.model.reside = 'Off Reserve';
      }
    } else {
      this.model.reside = '';
    }

    this.model.applicantID = this.applicantID;
    this.model.firstName = this.member.GivenName;
    this.model.lastName = this.member.LastName;
    this.model.middle_name = this.member.middle_name;
    if (this.member.DOB) { this.model.dob = this.member.DOB; } else { this.model.dob = ""; }
    this.model.maritalStatus = values.marital_status;
    this.model.address = values.address;
    this.model.city = values.city;
    this.model.province = values.province;
    this.model.postalCode = values.postal_code;
    this.model.Telephone = values.home_number;
    this.model.mobile = values.mobile;
    this.model.email = values.email;
    this.model.location = values.community;
    this.model.other_location = values.other_location;
    this.model.employed = values.employed;
    if (values.employer) { this.model.employer = values.employer; } else { this.model.employer = ''; }
    this.model.leaveofabsence = (values.leaveofabsence) ? values.leaveofabsence : '';
    this.model.withpay = (values.withpay) ? values.withpay : '';
    if (values.income) { this.model.income = Number(values.income); } else { this.model.income = 0; }
    if (values.spouse_classification) { this.model.spouse_classification = values.spouse_classification; } else { this.model.spouse_classification = ''; }
    if (values.spouse_income) { this.model.spouse_income = Number(values.spouse_income); } else { this.model.spouse_income = 0; }
    //this.model.spouse_aid = this.spouse_aid;
    this.model.bank = values.bank_name;
    this.model.bankno = values.bank_no;
    this.model.account = values.account_no;
    this.model.branch = values.transit_no;
    let house = values.house_status;
    if (house == '1') { this.model.rent_required = '1'; this.model.deposit_required = '0'; this.model.house_description = 'Rent Only'; }
    else if (house == '2') { this.model.rent_required = '1'; this.model.deposit_required = '1'; this.model.house_description = 'Rent and Damage Deposit'; }
    else if (house == '3') { this.model.rent_required = '0'; this.model.deposit_required = '0'; this.model.house_description = 'No Rent Required'; }

    setTimeout(() => {
      this.step2 = false;
      this.step3 = true;
      console.log(this.model);

    }, 25);
  }
  
  validate_sin(sin_num) {
    let sin:string = sin_num.toString();
    if (sin.length !=9){ return false; }

    var sin_array:number[] = new Array(9); 
    for (let i = 0; i < 9; i++) {
      sin_array[i] = Number(sin.substring(i, i+1));
      //console.log(sin_array[i])
    }

    let F1 :number = sin_array[0];
    let F2 :number = sin_array[1] + sin_array[1];
    let F3 :number = sin_array[2];
    let F4 :number = sin_array[3] + sin_array[3];
    let F5 :number = sin_array[4];
    let F6 :number = sin_array[5] + sin_array[5];
    let F7 :number = sin_array[6];
    let F8 :number = sin_array[7] + sin_array[7];
    let F9 :number = sin_array[8];
    var no1 :number;
    var no2 :number;
    no1 = 0
    no2 = F1 + F3 + F5 + F7
    if (F2 > 9) { no1 = no1 + this.fix_number(F2); }else{ no1 = no1 + F2; } 
    if(F4 > 9){ no1 = no1 + this.fix_number(F4); }else{ no1 = no1 + F4; }
    if(F6 > 9){ no1 = no1 + this.fix_number(F6); }else{ no1 = no1 + F6; }
    if(F8 > 9){  no1 = no1 + this.fix_number(F8); }else{ no1 = no1 + F8; }
    var total: number;
    var mod_result: number;
    total = no1 + no2;
    mod_result = total % 10;
    if (mod_result == 0 && F9 == 0) { return true;
    }else{
      var new_total_string :string = total.toString();
      var new_total: number = Number(new_total_string.substring(0, 1));
      new_total = new_total + 1
      new_total = new_total * 10
      if( new_total - total == F9) { return true; }else{ return false; }     
    }    
  }

  fix_number(num: number){
    if(num > 9) {
      var str :string = num.toString();
      var n1 :number = Number(str.substring(0, 1));
      var n2 :number = Number(str.substring(1, 2));
      return  n1 + n2;
    }else{
      return num;
    }  
  }

  get address() { return this.studentInfoForm.get('address'); }
  get city() { return this.studentInfoForm.get('city'); }
  get province() { return this.studentInfoForm.get('province'); }
  get postal_code() { return this.studentInfoForm.get('postal_code'); }
  get home_number() { return this.studentInfoForm.get('home_number'); }
  get mobile() { return this.studentInfoForm.get('mobile'); }
  get SIN() { return this.studentInfoForm.get('SIN'); }
  get HealthCareNo() { return this.studentInfoForm.get('HealthCareNo'); }
  get isn_no() { return this.studentInfoForm.get('isn_no'); }
  get email() { return this.studentInfoForm.get('email'); }
  get marital_status() { return this.studentInfoForm.get('marital_status'); }
  get reside() { return this.studentInfoForm.get('reside'); }
  get community() { return this.studentInfoForm.get('community'); }
  get other_location() { return this.studentInfoForm.get('other_location'); }
  get employed() { return this.studentInfoForm.get('employed'); }
  get employer() { return this.studentInfoForm.get('employer'); }
  get leaveofabsence() { return this.studentInfoForm.get('leaveofabsence'); }
  get withpay() { return this.studentInfoForm.get('withpay'); }
  get income() { return this.studentInfoForm.get('income'); }
  get spouse_classification() { return this.studentInfoForm.get('spouse_classification'); }
  get spouse_income() { return this.studentInfoForm.get('spouse_income'); }
  get bank_name() { return this.studentInfoForm.get('bank_name'); }
  get bank_no() { return this.studentInfoForm.get('bank_no'); }
  get transit_no() { return this.studentInfoForm.get('transit_no'); }
  get account_no() { return this.studentInfoForm.get('account_no'); }
  get house_status() { return this.studentInfoForm.get('house_status'); }

  // ------------------------ Step 2 -------------------------------------------

}