import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { MemberAccountSettingsService } from 'src/app/services/firebase/memberAccountSettings.service';
import { FireMembersService } from 'src/app/services/firebase/fire-members.service';
import { NominationService } from 'src/app/services/firebase/nomination.service';
import { NominationDataService } from 'src/app/services/firebase/nomination-data.service';
import { NominationDataModel, NominationModel } from 'src/app/models/NominationModel';
import { ElectionService } from 'src/app/services/firebase/election.service';
import { toast } from 'materialize-css';
import { AuthService } from 'src/app/services/firebase/auth.service';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-nomination-detail',
  templateUrl: './nomination-detail.component.html',
  styleUrls: ['./nomination-detail.component.css']
})

export class NominationDetailComponent implements OnInit {

  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  nominateForm: UntypedFormGroup;
  nominationObject: any; nominationDataObj: any;
  users = []; id = ''; nomineeMinAge = 0;
  election: any;
  nominationDetailObj: any;
  userID = '';
  alreadyNominated = false;

  constructor(private fb: UntypedFormBuilder,
    private fireMembersService: FireMembersService,
    private memberAccountSettingsService: MemberAccountSettingsService,
    private nominationService: NominationService,
    private nominationDataService: NominationDataService,
    private electionService: ElectionService,
    private location: Location,
    private route: ActivatedRoute,
    private authService: AuthService) { }


  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });
    this.userID = this.authService.getGlobalUser().uid;
    // this.userID = "c1kTAPiQ5MgOY2923g38sFCOCXL2";

    this.nominationService.getNominationbyId(this.id).valueChanges().subscribe(data => {
      if (data) {
        this.nominationDataService.getMyNominationsById(this.id, this.userID).valueChanges().subscribe(nomDetail => {
          this.nominationDetailObj = nomDetail;
          if (this.nominationDetailObj.length > 0) {
            this.alreadyNominated = true;
          }
          if (this.nominationDetailObj.userId === this.authService.getGlobalUser().uid &&
            this.nominationDetailObj.nominationId === this.id) {
          }
        });
        this.nominationObject = data;

        this.electionService.getElectionById(this.nominationObject.electionId).valueChanges().subscribe(electionData => {
          this.election = electionData;
          this.nomineeMinAge = this.election.nomineeMinAge;
        });
      }
    });

    $(document).ready(function () {
      $('select').formSelect();
    });
    this.memberAccountSettingsService.getAccountsWithNominations().valueChanges().subscribe(data => {
      if (data) {
        data.forEach(element => {
          this.fireMembersService.getMemberByID(element.memberId).valueChanges().subscribe(user => {
            if (user) {
              let d = new Date();
              let curr_date = d.getDate();
              let curr_month = d.getMonth();
              let curr_year = d.getFullYear();
              if (environment.firstNation.name === 'Demo') {
                this.users.push(user);
                const option = new Option(user.GivenName + ' ' + user.LastName, user.uid);
                $('#selectedNominee').append(option);
              } else {
                if (new Date(user.DOB) < new Date(curr_year - this.nomineeMinAge, curr_month + 1, curr_date)) {
                  this.users.push(user);
                  const option = new Option(user.GivenName + ' ' + user.LastName, user.uid);
                  $('#selectedNominee').append(option);
                }
              }
            }
            $('select').formSelect();

          });
        });
      }
    });

    this.nominateForm = this.fb.group({
      selectedNominee: ['', [Validators.required]],
    });

  }

  submitForm(value: any) {
    this.nominationDataService.getIfMemberNominated(this.id, value.selectedNominee).valueChanges().subscribe(nominations => {
      if (nominations.length > 0) {
        toast({ html: 'Nominee Already Added!', classes: 'green' });
        this.backClicked();
      } else {
        // set the nomination-data object
        this.nominationDataObj = new NominationDataModel();
        this.nominationDataObj.nominationId = this.id;
        this.nominationDataObj.userId = this.userID;
        this.nominationDataObj.firstName = this.authService.getGlobalUser().GivenName;
        this.nominationDataObj.lastName = this.authService.getGlobalUser().LastName;
        this.nominationDataObj.nominatedMemberId = value.selectedNominee;
        this.nominationDataObj.nominationStatus = 'Pending';
        this.nominationObject.nominees.push(value.selectedNominee);
        if (!this.alreadyNominated) {
          this.nominationDataService.createNominationData(this.nominationDataObj).then(x => {
            this.nominationService.updateNomination(this.nominationObject).then(() => { });
            toast({ html: 'Nominee Successfully Added!', classes: 'green' });
            this.backClicked();
          });
        }
      }
    });
  }

  backClicked() {
    this.location.back();
  }

}
