import { Component } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Router } from '@angular/router';
import { updateCurrentUser } from '@firebase/auth';
import { id } from 'date-fns/locale';
import { finalize } from 'rxjs/operators';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { SectionSettingService } from 'src/app/services/firebase/section-setting.service';
import { createMask } from '@ngneat/input-mask';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent {

  userRole: any;
  isSuperAdmin = false;
  permissions: any;
  user: any;
  isAdminMember = false
  isChiefOrCouncillor = false;
  loading = false;
  defaultThemeColor = '#000000';
  themeColor: any;
  selectedFile: File;
  // public phoneNumberMask = ['(', /[0-9]/, /[0-9]/, /[0-9]/, ')',
  //   ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];

  phoneInputMask = createMask<string>({
    mask: '(999) 999-9999',
    guide: true,
    showMaskTyped: true,
  });

  profilePictureUrl: any;
  imageChanged = false;




  constructor(
    private authService: AuthService,
    private sectionService: SectionSettingService,
    private storage: AngularFireStorage,
    private settingsService: AppSettingsService,
    private router: Router,) {
    this.themeColor = this.settingsService.getAppThemeColor() ? this.settingsService.getAppThemeColor() : this.defaultThemeColor;
    this.loading = true;
    this.authService.getCurrentApplicantIDObservable().subscribe(
      user => {
        if (user) {
          this.userRole = user.role;
          if (user.isSuperAdmin) this.isSuperAdmin = true;
          if (user.permissions) this.permissions = user.permissions;
          else if (!this.isSuperAdmin) {
            $('.modal').modal();
            $('#permissionModal').modal('open');
          }
        }
      }
    );



    if (this.authService.globalUser) {
      if (this.authService.globalUser.isChief || this.authService.globalUser.isCouncillor) {
        this.isChiefOrCouncillor = true;
      }
      if (this.authService.globalUser.isMember) {
        this.isAdminMember = true;
      }
    }
  }

  ngOnInit() {


    this.authService.fireMember.subscribe(data => {
      if (data) {
        this.user = data;
        if (this.user.profilePictureUrl) {
          this.profilePictureUrl = this.user.profilePictureUrl;
        }
      }
    });

    $(document).ready(function () {
      $('.modal').modal();
    });

  }


  // onFileSelected(event: any) {
  //   this.selectedFile = event.target.files[0];
  //   this.imageChanged = true;
  // }

  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0];
    this.imageChanged = true;

    if (this.selectedFile) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        this.profilePictureUrl = e.target.result;
        M.Modal.getInstance(document.getElementById('UpdatePicture')).open();
      };
      reader.readAsDataURL(this.selectedFile);
    }
  }


  onUpdate() {
    this.authService.updateUserData(this.user.uid, this.user).subscribe({
      next: () => {
        console.log('User data updated successfully');
      },
      error: (err) => {
        console.error('Error updating user data:', err);
      }
    });
  }
  getInitials(firstName: string, lastName: string): string {
    const firstInitial = firstName ? firstName.charAt(0) : '';
    const lastInitial = lastName ? lastName.charAt(0) : '';
    return `${firstInitial}${lastInitial}`.toUpperCase();
  }


  // saveProfilePicture() {
  //   if (this.imageChanged && this.selectedFile) { // Check if imageChanged is true and a file is selected
  //     const filePath = 'profile-pictures/' + this.user.id;
  //     const fileRef = this.storage.ref(filePath);
  //     const uploadTask = this.storage.upload(filePath, this.selectedFile);

  //     uploadTask.snapshotChanges().pipe(
  //       finalize(() => {
  //         fileRef.getDownloadURL().subscribe(downloadURL => {
  //           this.user.profilePictureUrl = downloadURL;
  //           this.profilePictureUrl = downloadURL;
  //           M.Modal.getInstance(document.getElementById('UpdatePicture')).close();
  //           this.imageChanged = false; // Reset imageChanged after saving the picture
  //         });
  //       })
  //     ).subscribe();
  //   }
  // }

  saveProfilePicture() {
    if (this.imageChanged && this.selectedFile) { // Check if imageChanged is true and a file is selected
      // Disable the save button while uploading
      const saveButton = document.getElementById('saveButton');
      if (saveButton) {
        saveButton.setAttribute('disabled', 'true');
      }

      // Show loading indicator on the save button
      saveButton.innerHTML = '<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> Uploading...';

      const filePath = 'profile-pictures/' + this.user.id;
      const fileRef = this.storage.ref(filePath);
      const uploadTask = this.storage.upload(filePath, this.selectedFile);

      uploadTask.snapshotChanges().pipe(
        finalize(() => {
          fileRef.getDownloadURL().subscribe(downloadURL => {
            this.user.profilePictureUrl = downloadURL;
            this.profilePictureUrl = downloadURL;
            M.Modal.getInstance(document.getElementById('UpdatePicture')).close();
            this.imageChanged = false; // Reset imageChanged after saving the picture

            // Reset the save button after the upload is complete
            saveButton.removeAttribute('disabled');
            saveButton.innerHTML = 'Save';
          });
        })
      ).subscribe();
    }
  }



  deleteProfilePicture() {
    $('#deleteModal').modal('open');
  }

  confirmDeleteProfilePicture() {
    const filePath = 'profile-pictures/' + this.user.id;
    const fileRef = this.storage.ref(filePath);
    fileRef.delete().subscribe(() => {
      console.log('Profile picture deleted successfully');
      this.user.profilePictureUrl = '';
      this.profilePictureUrl = '';
      $('#UpdatePicture').modal('close');
      $('#deleteModal').modal('close');
    }, error => {
      console.error('Error deleting profile picture:', error);
    });
  }



  onBack() {
    this.router.navigate(['/admin/dashboard']);
  }
}




