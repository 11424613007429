import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { toast } from 'materialize-css';
import { ActivatedRoute } from '@angular/router';
import { EventsService } from 'src/app/services/firebase/events.service';
import { SessionsMonitorService } from 'src/app/services/firebase/sessions-monitor.service';
declare var $: any;

@Component({
  selector: 'app-edit-event',
  templateUrl: './edit-event.component.html',
  styleUrls: ['./edit-event.component.css']
})

export class EditEventComponent implements OnInit {

  adminThemeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  myModel: any;
  id: any;
  valueEmittedFromChildComponent: any;

  constructor(
    private eventService: EventsService,
    private location: Location,
    private sessionMonitor: SessionsMonitorService,
    private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });
    if (this.id) {
      this.eventService.getEventByID(this.id).valueChanges().subscribe(obj => {
        if (obj) {
          this.myModel = obj;
        }
      });
    }
  }

  updateEventHandler(valueEmitted) {
    this.valueEmittedFromChildComponent = valueEmitted;
    if (this.valueEmittedFromChildComponent === true) {
      this.sessionMonitor.newActivity('Edit Event', 'Event Edited');
      toast({ html: 'Event Successfully Updated!', classes: 'green' });
    }
  }

  archiveEventHandler(valueEmitted) {
    this.valueEmittedFromChildComponent = valueEmitted;
    if (this.valueEmittedFromChildComponent === true) {
      this.sessionMonitor.newActivity('Edit Event', 'Event Archived');
      toast({ html: 'Event Successfully Archived!', classes: 'green' });
      $('.modal').modal('close');
      this.backClicked();
    }
  }

  unArchiveEventHandler(valueEmitted) {
    this.valueEmittedFromChildComponent = valueEmitted;
    if (this.valueEmittedFromChildComponent === true) {
      this.sessionMonitor.newActivity('Edit Event', 'Event UnArchived');
      toast({ html: 'Event Successfully Published!', classes: 'green' });
      $('.modal').modal('close');
      this.backClicked();
    }
  }

  deleteEventHandler(valueEmitted) {
    this.valueEmittedFromChildComponent = valueEmitted;
    if (this.valueEmittedFromChildComponent === true) {
      this.sessionMonitor.newActivity('Edit Event', 'Event Deleted');
      toast({ html: 'Event Successfully Deleted!', classes: 'green' });
      $('.modal').modal('close');
      this.backClicked();
    }
  }

  backClicked() {
    this.location.back();
  }

}
