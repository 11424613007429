import { query } from '@angular/animations';
import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { MemberAccountSettingsModel } from 'src/app/models/MemberAccountSettingsModel';
import { FireMembersService } from 'src/app/services/firebase/fire-members.service';
import { setTimeout } from 'timers';


@Injectable({
  providedIn: 'root'
})
export class MemberAccountSettingsService {
  constructor(private db: AngularFirestore, private fireMemberService: FireMembersService) { }

  accoutSetting: AngularFirestoreDocument<any>;
  memberAccountSettings: AngularFirestoreCollection<any>;



  createAccountSetting(model: MemberAccountSettingsModel) {

    const newContent = {
        id: this.db.createId(),
        memberId: model.memberId,
        permissions: model.permissions
    };
    return this.db.collection('Member-Account-Settings').doc(newContent.id).set(newContent);
  }

  getAccoutSetting(uid: string):  AngularFirestoreCollection<any[]>{
    this.memberAccountSettings = this.db.collection('Member-Account-Settings', ref => ref.where('memberId', '==', uid));
    return this.memberAccountSettings;
  }

  updateAccountSetting(model:MemberAccountSettingsModel){
    const newContent = {
        id: model.id,
        memberId: model.memberId,
        permissions: model.permissions
    };
    return this.db.collection('Member-Account-Settings').doc(newContent.id).update(newContent);
  }

  getAccountsWithNominations(){
    this.memberAccountSettings = this.db.collection('Member-Account-Settings', ref => ref.where('permissions', "array-contains", { allowNomination: true }));
    return this.memberAccountSettings;
  }

  getAccountsWithChat(){
    this.memberAccountSettings = this.db.collection('Member-Account-Settings', ref => ref.where('permissions', "array-contains", { allowChat: true }));
    return this.memberAccountSettings;
  }

  getAccountsWithCandidateContact() {
    this.memberAccountSettings = this.db.collection('Member-Account-Settings',
    ref => ref.where('permissions', 'array-contains', { allowCandidateContact: true }));
    return this.memberAccountSettings;
  }

  //  getUserDataForAccountsWithChat(){
  //   this.memberAccountSettings = this.db.collection('Member-Account-Settings', ref => ref.where('permissions', "array-contains", { allowChat: true }));
  //   let members = [];
  //   const member = this.db.collection('BandMembers');

  //    this.memberAccountSettings.get().toPromise()
  //   .then( function(querySnapshot){
  //     querySnapshot.forEach( function(doc){
  //       let memberAccountSetting = doc.data();
  //       member.doc(memberAccountSetting.memberId).valueChanges().subscribe( user => {
  //         if (user) {
  //         members.push(user);
  //         }});
  //       // this.fireMemberService.getMemberByID(memberAccountSetting.memberId).valueChanges().subscribe(user => {
  //       //   if (user) {
  //       //     members.push(user);
  //       //   }})
  //     })
  //     // return await Promise.all(members)
  //     // setTimeout (() =>{
  //     //     console.log(members)
  //     // }, 5000)
  //     // return await Promise.all(members).then(allResults => {
  //     //   console.log(allResults)
  //     //   return allResults;
  //     // })
  //   })
  // }
  
}
