import { Component, OnInit } from '@angular/core';
import { PollsAndSurveyService } from 'src/app/services/firebase/polls-and-survey.service';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { PollsAndSurveyParticipationService } from 'src/app/services/firebase/polls-and-survey-participation.service';
import { toast } from 'materialize-css';

@Component({
  selector: 'app-polls-list',
  templateUrl: './polls-list.component.html',
  styleUrls: ['./polls-list.component.css']
})

export class PollsListComponent implements OnInit {

  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  model: any[];
  //status: any[] = [];
  participations: any[];
  showLoader = false;

  constructor(
    private surveyService: PollsAndSurveyService,
    private participationService: PollsAndSurveyParticipationService,
    private location: Location) { }

  ngOnInit() {
    this.showLoader = true;
    this.surveyService.getPollList().valueChanges().subscribe(srv => {
      if (srv.length > 0) {
        this.model = srv;
        
        this.model = this.model.sort(function(a,b){
          return new Date(b.endDate).getTime() - new Date(a.endDate).getTime();
        });
       
        this.model.forEach(poll => {
          let status = this.getStatus(poll.endDate); 
          if(poll.showResult == 'yes' && (poll.showResultTo == 'member' || poll.showResultTo == 'both')) status.showResult = true;
          poll.status = status;
          //this.status.push(status);
        });
       
        const memberID = localStorage.getItem('applicantID');
        this.participationService.getPollParticipationByClientID(memberID).valueChanges().subscribe(part => {
          if (part) {
            this.participations = part;
          }
        });
      }
      this.showLoader = false;
    });
  }

  backClicked() {
    this.location.back();
  }

  getStatus(date) {
    let info = {
      endAt: '',
      status: '',
      open: true,
      showResult: false,
    }
    let today = new Date();
    today.setHours(0, 0, 0, 0);

    let endDate = new Date(date);

    var time_diff = endDate.getTime() - today.getTime();
    var days_diff = time_diff / (1000 * 3600 * 24);

    if (days_diff == 0) {
      info.endAt = `Last Day`;
      info.status = "Open";
      info.open = true;
    } else if (days_diff < 0) {
      info.endAt = `Ended ${Math.abs(days_diff)} days Ago`;
      info.status = "Closed";
      info.open = false;
    } else {
      info.endAt = `Ends In ${Math.abs(days_diff)} days`;
      info.status = "Open";
      info.open = true;
    }
    return info;
  }

  closed() {
    toast({ html: 'The Poll is closed.', classes: 'red' });
  }
  
}