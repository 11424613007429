import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { Location } from '@angular/common';
import { SkillService } from 'src/app/services/api/skill.service';

@Component({
  selector: 'app-skills',
  templateUrl: './skills.component.html',
  styleUrls: ['./skills.component.css']
})

export class SkillsComponent implements OnInit {

  constructor(private location: Location,
    private authService: AuthService,
    private skillService: SkillService) {
    localStorage.setItem('from', 'myresume');
  }

  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  clientID = 0;
  model: any[];
  hasRows = false;

  ngOnInit() {
    if (localStorage.getItem('applicantID')) {
      this.clientID = Number(localStorage.getItem('applicantID'));
    } else {
      this.clientID = this.authService.getCurrentApplicantID();
    }

    this.skillService.getSkillByMemberId(this.clientID).subscribe(x => {
      if (x) {
        this.model = x;
        if (this.model.length > 0) { this.hasRows = true; }
      }
    });
  }

  backClicked() {
    this.location.back();
  }

}