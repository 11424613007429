<div class="row">
  <!-- Modal Trigger -->
  <a class="waves-effect waves-light btn modal-trigger" href="#deleteModal">Delete Modal</a>

  <a class="waves-effect waves-light btn modal-trigger" href="#comformdeleteModal">Comformation Modal</a>

  <!-- Modal Structure -->
  <div id="deleteModal" class="modal b-n-1">
    <i class="material-icons right modal-close col s1">close</i>
    <div class="modal-content">
      <div class="col s3">
        <span class="material-symbols-outlined red-text" style="font-size: 70px;">
          warning
        </span>
      </div>
      <div class="col s9">
        <h4 class="red-text">Delete department </h4>
        <p>Are you sure you want to delete “name of department”?</p>
      </div>

    </div>
    <div class="modal-footer row ">
      <a href="#!" class="cancel-button btn col l5 s5">Cancel</a>
      <a href="#!" class="continue-button btn col l5 s5">Continue</a>
    </div>
  </div>


  <div id="comformdeleteModal" class="modal b-n-1">
    <i class="material-icons right modal-close col s1">close</i>
    <div class="modal-content">

      <div class="col s12 center">
        <h4 class="red-text">Delete department? </h4>
        <p>Are you sure you want to delete “name of department”?</p>
      </div>

      <div class="col s12 warning-red p-t-1">
        <h4 class="red-text">Warning</h4>
        <p style="font-size: 14px;">Are you sure you want to delete this item? This action cannot be undone. Please
          confirm your decision to
          proceed with the deletion.</p>
      </div>

    </div>
    <div class="modal-footer row ">
      <a href="#!" class="cancel-button btn col l5 s5">Cancel</a>
      <a href="#!" class="delete-button btn col l5 s5">Delete</a>
    </div>
  </div>
</div>