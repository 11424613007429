import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-lrrcn-application',
  templateUrl: './lrrcn-application.component.html',
  styleUrls: ['./lrrcn-application.component.css']
})

export class LrrcnApplicationComponent implements OnInit {

  applicantID
  applicant

  constructor(private authService: AuthService, private location: Location) { }

  ngOnInit(): void {
    this.applicant = this.authService.getGlobalUser()
    if (this.applicant) {
      this.applicantID = this.applicant.applicantID
      console.log('this app id', this.applicantID)
    }
  }

  backClicked(valueEmitted) {
    if (valueEmitted == true) {
      this.location.back();
    }
  }
}