<h6 class="center p-m-2 b-m-2"><strong> My Contracts </strong> </h6>

<div class="row col s12">
  <div class="col l1"></div>

  <div class="col s12 l10">
    <div class="row" *ngIf="hasRows">
      <a class="" *ngFor="let e of joblist" [routerLink]="['/employer/my-contracts/job-update', e.id]">
        <div class="card-panel white center">
          <strong><i class="material-icons left brown-text">work</i> <span class="center">{{ e.title}}</span> </strong>
        </div>
      </a>
    </div>

    <div class="card-panel orange darken-2" *ngIf="!hasRows">
      <h6 class="center white-text"><i class="material-icons white-text left">info</i>
        <strong> You don't have job posted, Please post job first !</strong>
      </h6>
    </div>

    <a [ngClass]="'col s12 btn waves-effect ' + themeColor" [routerLink]="['/employer/home']">
      <i class="material-icons left">house</i> Back To Home Page
    </a>
  </div>

  <div class="col l1"></div>
</div>