
export class RegistrationFormModel {

    givenname: string;
    lastname: string;
    initial: string;
    title:string;
    dob: string;
    gender:string;
    SIN: string;
    cell: string;
    email: string;
    home_address: string;
    city: string;
    province:string;
    postal_code: string;
    mailing_address: string;

    Econtact1:string;
    contact1PH: string;
    Econtact2: string;
    contact2PH: string;

    driver_license:string;
    driver_class: string;
    license_expiry_date:string;
    province_issued: string;
   
    citizenship:string;
    language: string;

    reading_skill:string;
    writing_skill:string;
    listening_skill:string;
    speaking_skill:string;

    aboriginal_group:string;

    /////////////////////////////
    employment_status:string;
    emp_other_specify:string;
    ei_received:string;
    apply_ei:string;
    disability:string;
    marital_status:number;
    t_dependants:number;
    kids_age:string;
  
    request_resume: string;
    request_skill: string;
    request_career: string;
    request_job: string;
    request_funding: string;
    request_program: string;
    request_institute: string;
    request_startdate:string;
    request_enddate: string;


    highschool: string;
    hs_grade: string;
    hs_city: string;
    hs_province: string;

    pse_program1: string;
    pse_institution1: string;
    completed_year1: number;
    pse_city1: string;
    pse_province1: string;

    pse_program2: string;
    pse_institution2: string;
    completed_year2: number;
    pse_city2: string;
    pse_province2: string;

    has_trade: string;
    trade: string;
    trade_course: string;
    trade_institution: string;
    trade_level: string;
    trade_year: number;
    trade_city: string;
    trade_province: string;
    referred_by: string;

    comments: string;
   
    signature: string;
    sign_date: string;

    fileStreamID: number;
    description: string;

   }
  
