import { Component, OnInit } from '@angular/core';
import { Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { FamilyCompositionModel } from 'src/app/models/FamilyCompositionModel';
import { environment } from 'src/environments/environment';
import { FamilyCompositionService } from 'src/app/services/firebase/family-composition.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { toast } from 'materialize-css';
import { Location } from '@angular/common';

declare var $: any;

@Component({
  selector: 'app-new-family-member',
  templateUrl: './new-family-member.component.html',
  styleUrls: ['./new-family-member.component.css']
})

export class NewFamilyMemberComponent implements OnInit {

  constructor(private authService: AuthService, private fb: UntypedFormBuilder, private location: Location,
   private familyCompositionService: FamilyCompositionService) { }

  applicantID = 0;
  addFamilyMember: UntypedFormGroup;
  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  model: FamilyCompositionModel;
  public phoneNumberMask = ['(', /[0-9]/, /[0-9]/, /[0-9]/, ')',
  ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];

  ngOnInit() {
    $(document).ready(function(){
      $('select').formSelect();
    });
    if (localStorage.getItem('applicantID')) {
      this.applicantID = Number(localStorage.getItem('applicantID'));
    } else {
      this.applicantID = this.authService.getCurrentApplicantID();
    }

    this.addFamilyMember = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      dateOfBirth: [''],
      relation: [''],
      SIN: ['' ],
      ISN: ['' ],
      email: ['', Validators.email],
      mobile: ['',],
    });

    this.model = new FamilyCompositionModel();
    this.model.memberId = this.applicantID;
    this.model.relation = '';

    setTimeout(() => {
      $('.datepicker').datepicker({
        format: 'dd/mmm/yyyy',
        defaultDate: new Date(),
        setDefaultDate: new Date(),
        yearRange: 50,
        minDate: new Date(new Date().getFullYear() - 50, 0, 1),
        maxDate: new Date(new Date().getFullYear(), 12, 31),
        onSelect(e) {
          localStorage.setItem('selectedDate', e);
        },
      });
    }, 25);
  }

  dateChange() {
    if (this.addFamilyMember) { this.addFamilyMember.patchValue({ dateOfBirth: $('#dateOfBirth').val() }); }
  }

  submitForm(value: any) {
    this.model.firstName = value.firstName;
    this.model.lastName = value.lastName;
    var d_arr = value.dateOfBirth.split("/");
    var dateOfBirth_d = new Date(d_arr[1] + "-" + d_arr[0] + "-" + d_arr[2]);
    var dateOfBirth = new Date(dateOfBirth_d.getFullYear(), dateOfBirth_d.getMonth(), dateOfBirth_d.getDate());
    this.model.DOB = dateOfBirth;
    this.model.SIN = value.SIN;
    this.model.ISN = value.ISN;
    this.model.email = value.email;
    this.model.mobile = value.mobile;
      this.familyCompositionService.addFamilyMember(this.model).then(x => {
        toast({ html: 'New Family Member Successfully Added!', classes: 'green' });
        this.backClicked();
      });
    }
  
  relationOptionChange(selectedValue: any) {
    this.model.relation = selectedValue;
  }

  backClicked() {
    this.location.back();
  }

}