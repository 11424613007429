import { Attachment } from "./emailModel";

export class HousingModel {
    ID: number;
    aid_of_owner: number;
    //Name_of_Owner = '';
    BuiltOn: Date;
    lived_since = '';

    house_number = '';
    house_lot = '';
    house_block = '';
    Quarter = '';
    section = '';
    township = '';
    range = '';
    median = '';
    Other_location = '';
    desc_type = '';
    // default_nation = 0;
    CMHC: boolean;
    finianced: boolean;
    original_amount = '';
    rented: boolean;
    rental_amount = '';
    rooms = 0;

    style = '';
    year = '';
    value = '';
    size = '';
    SizeOfTheLot = '';
    community = '';
    Fuel_Type = '';
    Fuel_Type2 = '';
    // Address = '';
    // Province = '';
    // PostalCode = '';


    // Fuel_Type = '';
    // SizeOfTheLot = '';
    // SizeOfThesk_housing = '';
    // Insulation = '';
    // Deck = '';
    // WindowCovering = '';
    // SewerSystem = '';
    // PlumbingCondition = '';
    // ElecWiringCondition = '';
    // Warrenty = '';
    // occupants: number[] = [];
    // Occupants: number[] = [];

    //occupants = '';
}

export class HouseModel {
    //ID: number;
    aid_of_owner: number;
    house_number = '';
    house_lot = '';
    house_block = '';
    Quarter = '';
    section = '';
    township = '';
    range = '';
    median = '';
    Other_location = '';
    desc_type = '';
    // default_nation = 0;
    CMHC: boolean;
    finianced: boolean;
    rooms = 0;
    style = '';
    year = '';
    value = '';
    size = '';
    community = '';
    Fuel_Type = '';
    Fuel_Type2 = '';
}

export class UpdateHouseModel {
    ID: number;
    aid_of_owner: number;
    house_number = '';
    house_lot = '';
    house_block = '';
    Quarter = '';
    section = '';
    township = '';
    range = '';
    median = '';
    Other_location = '';
    desc_type = '';
    CMHC: boolean = false;
    finianced: boolean = false;
    rooms = 0;
    style = '';
    year = '';
    value = '';
    size = '';
    community = '';
    Fuel_Type = '';
    Fuel_Type2 = '';
}

export class PropertyFilterModel {
    name = '';
    location = '';
    community = '';
}

export class HouseFilterModel {
    ownerName = '';
    ownerID: number;
    houseNo = '';
    community = '';
}

export class OwnerModel {
    ID: number;
    ownerID: number;
    ownerName = ''
}

export class HousingApplicationModel {
    applicantID: number;
    givenName = '';
    middleName = '';
    lastName = '';
    aliasName = '';
    title = '';
    maritalStatus= '';
    dob: Date;
    SIN = '';
    FWFN: boolean = false;
    registrationNo= '';

    telephone= '';
    telephone2= '';
    cellphone= '';
    cellphone2= '';
    workNumber= '';
    ext= '';
    address= '';
    city= '';
    province= '';
    postalCode= '';

    accomodationAddress= '';
    homeInfo= '';
    
    co_applicant_givenName= '';
    co_applicant_lastName= '';
    co_applicant_middleName= '';
    co_applicant_aliasName= '';

    co_applicant_title= '';
    // co_applicant_age: '';
    co_applicant_gender= '';
    co_applicant_maritalStatus= '';
    co_applicant_dob: Date;
    co_applicant_SIN= '';
    co_applicant_bandName= '';
    co_applicant_registrationNo= '';
    
    landLord_givenName= '';
    landLord_lastName= '';
    landLord_address= '';
    landLord_province= '';
    landLord_city= '';
    landLord_postalCode= '';
    landLord_moveInDate: Date;
    landLord_leaseAgreement: boolean = false;
    landLord_expireDate: Date;

    otherAccoummodation= '';

    accessibilityNeeds: boolean = false;
    accessibilityExplain= '';
    reside: boolean = false;
    resideExplain= '';
    healthSafetyIssues: boolean = false;
    healthSafetyExplain= '';

    occupants: OccupantModel[];
    incomes: Incomes[];
    files: Attachment[];
    applicantSignature = '';
    co_applicantSignature = '';
    applicantDate: Date;
    co_applicantDate: Date;
}

export class OccupantModel{
    givenName = '';
    lastName = '';
    middleName= '';
    dob: Date;
    SIN= '';
    //age: number;
    gender= '';
    inSchool : boolean;
    school= '';
    needs: boolean;
    specialNeeds = '';
    relation = '';
    other_relative = '';
}

export class Incomes{
    name = '';
    role = '';
    total = '';
    incomes: Income[];
}

export class Income{
    source = '';
    amount: number;
}