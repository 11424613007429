<div class="row">
  <div class="col s12 ">
    <div class="body-h">

      <div class="header-setting b-m-2 ">
        <div class="col l12 m12 s12">
          <h4 *ngIf="fromWhere === 'add'"> <i (click)="cancelCouncillorForm()"
              class="tiny material-symbols-outlined bold-700 p-r-1" style="cursor: pointer;">arrow_back </i>Add
            {{councillorTitle}}</h4>
          <h4 *ngIf="fromWhere === 'edit'"> <i *ngIf="showCouncillorForm" (click)="cancelCouncillorForm()"
              class="tiny material-symbols-outlined bold-700 p-r-1" style="cursor: pointer;">arrow_back </i>Update
              {{councillorTitle}}</h4>
          <!-- <select class="browser-default hide-on-large-only" (change)="navigate($event.target.value)">
            <option value="AboutUs">About Us</option>
            <option value="NationInfo">Nation Info</option>
            <option value="Departments">Department</option>
            <option value="Governance" disabled selected>Governance</option>
            <option value="AreaOfInterest">Area of Interest</option>
            <option value="Board">Board</option>
          </select> -->
        </div>
      </div>

      <main class="StickyContent white">
        <div *ngIf="showCouncillorForm" id="profile1">
          <div class="row col s12 l10 card-panel white">

            <div class="col l6 s4">
              <div class="row imgC center hide-on-small-only show-on-medium-and-up">
                <img *ngIf="profile_pic" [src]="profile_pic" />
                <span *ngIf="!profile_pic"
                  class="initials ">{{getInitials(selectedMemberFirstName,selectedMemberLastName)}}</span>
              </div>

              <div class="row imgC2 center hide-on-med-and-up" style="text-align: center;">
                <img *ngIf="profile_pic" [src]="profile_pic" />
                <span *ngIf="!profile_pic"
                  class="initials">{{getInitials(selectedMemberFirstName,selectedMemberLastName)}}</span>
              </div>
            </div>

            <div class="col s2">
              <div *ngIf="fromWhere === 'add' || showImageInput" class="center  p-t-5">
                <div class="col s2 right" *ngIf="!isMobile">
                  <button class="btn add-button white" [ngStyle]="{'color': themeColor}"
                    (click)="hiddenfileinput.click()">
                    <i class="material-icons">edit</i>
                    Edit Photo
                  </button>

                  <input class="file-field input-field col s12 center" type="file" style="display:none" accept="image/*"
                    (change)="handleFileInput($event.target.files)" #hiddenfileinput>
                </div>

                <div class="col s12 center" *ngIf="isMobile">
                  <button class="btn-floating btn-small  green  center" (click)="mobilePicture();"
                    style="margin-right: 15px;"><i class="material-icons">camera_alt</i></button>
                  <button class="btn-floating btn-small  blue " (click)="libraryPicture();"><i
                      class="material-icons">photo_library</i></button>
                </div>
              </div>

              <div class="input-field col s12 center" *ngIf="fromWhere === 'edit' && !showImageInput">
                <a class="btn-floating btn-small red modal-trigger" (click)="deletePhoto()"
                  href="#confirmDeleteModal"><i class="material-icons">delete</i></a>
              </div>
            </div>
          </div>
        </div>

        <div class="row col s12">
          <div class="col s12 l10 ">

            <div class="" *ngIf="isEdit">
              <form autocomplete="off" [formGroup]="searchMemberForm" novalidate>

                <div class="row card-panel white darken-2" *ngIf="showSearch">
                  <h6 class="sub-title-section3 bold-500">Please Enter The First Or Last Name To Search In The
                    Database
                  </h6>

                  <div class="input-field col s12 m6">
                    <div class="label-input">Last Name</div>
                    <input id="lastName" type="text" formControlName='lastName' class="validate" autocomplete="off"
                      placeholder="Last Name" />
                  </div>

                  <div class="input-field col s12 m6">
                    <div class="label-input">First Name</div>
                    <input id="firstName" type="text" formControlName='firstName' class="validate" autocomplete="off"
                      placeholder="First Name" />
                  </div>
                </div>

                <div class="row card-panel white darken-2" *ngIf="showSearchTable">
                  <div class="card-body news-list">
                    <div class="table-responsive">
                      <table datatable [dtOptions]="dtOptions" *ngIf="processing"
                        class="row-border hover notification-table centered striped" width="100%">
                        <thead>
                          <tr>
                            <th class="hide-on-small-only show-on-medium-and-up">ID</th>
                            <th>Full Name</th>
                            <th>DOB</th>
                          </tr>
                        </thead>
                        <tbody *ngIf="employeesListData?.length !== undefined">
                          <tr *ngFor="let item of employeesListData; let i = index" (click)="setFormDetails(item)"
                            class="employeesListData" style="cursor: hand;">
                            <td class="hide-on-small-only show-on-medium-and-up">{{item?.applicantID}}</td>
                            <td>{{item?.LastName}} {{item?.GivenName}}</td>
                            <td>{{item?.DOB | date}}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

              </form>
            </div>

            <div class="row" *ngIf="showCouncillorForm && communities">

              <form [formGroup]="addCouncillorForm">

                <div class="row  card-panel white">
                  <h6 style="margin-bottom: 15px; margin-top: 15px; font-weight: 600;">{{councillorTitle}}
                    Information</h6>
                  <br />
                  <div class="row">
                    <div class="input-field col s12 l6">
                      <div class="label-input">First Name</div>
                      <input id="firstName" type="text" formControlName='firstName' class="validate"
                        placeholder="First Name" />
                    </div>

                    <div class="input-field col s12 l6">
                      <div class="label-input">Last Name</div>
                      <input id="lastName" type="text" formControlName='lastName' class="validate"
                        placeholder="Last Name" />
                    </div>

                    <div class="input-field col s12 l7">
                      <div class="label-input">Display Name</div>
                      <input id="displayName" type="text" formControlName='displayName' class="validate"
                        placeholder="Display Name" />
                    </div>

                    <div class="input-field col s12 l6">
                      <div class="label-input">Community Name</div>
                      <select id="community" formControlName="community" class="validate" placeholder="Community Name">
                        <option value="" disabled selected>Select</option>
                        <option *ngFor="let c of communities" [value]="c.community">{{c.community}}</option>
                      </select>
                    </div>

                    <div class="input-field col s12 l5">
                      <div class="label-input">Email <span class="red-text">(*)</span></div>
                      <input id="email" type="email" formControlName='email' class="validate" autocomplete="off"
                        placeholder="Email" />
                      <div *ngIf="(getEmail.dirty || getEmail.touched) && getEmail.invalid">
                        <small *ngIf="getEmail.errors.required" class="red-text"> The Email is required. </small>
                        <small *ngIf="getEmail.errors.email" class="red-text"> wrong email format. </small>
                      </div>
                    </div>

                    <div class="input-field col s12 l5">
                      <div class="label-input">Website</div>
                      <input id="website" formControlName='website' type="text" class="validate" placeholder="validate">
                      <div *ngIf="(website.dirty || website.touched) && website.invalid">
                        <small *ngIf="website.errors.pattern" class="red-text">Invalid web address.</small>
                      </div>
                    </div>

                    <div class="input-field col s12 l6">
                      <div class="label-input">Office Phone</div>
                      <!-- <input id="office_phone" type="text" formControlName='office_phone' class="validate"
                        autocomplete="off" [textMask]="{mask: phoneNumberMask}" placeholder="Office Phone" /> -->
                        <input id="office_phone" type="text" [inputMask]="phoneInputMask" formControlName='office_phone' class="validate"
                        autocomplete="off" placeholder="Office Phone" />
                      <div *ngIf="(getOffice_phone?.dirty || getOffice_phone?.touched) && getOffice_phone?.invalid">
                        <small *ngIf="getOffice_phone?.errors.required" class="red-text"> The Office Phone is
                          required.
                        </small>
                      </div>
                    </div>

                    <div class="input-field col s12 l6">
                      <div class="label-input">Phone</div>
                      <!-- <input id="cell_phone" type="text" formControlName='cell_phone' class="validate"
                        autocomplete="off" [textMask]="{mask: phoneNumberMask}" placeholder="Phone" /> -->
                        <input id="cell_phone" type="text" [inputMask]="phoneInputMask" formControlName='cell_phone' class="validate"
                        autocomplete="off"  placeholder="Phone" />
                      <div *ngIf="(getCell_phone.dirty || getCell_phone.touched) && getCell_phone.invalid">
                        <small *ngIf="getCell_phone.errors.required" class="red-text"> The Phone is required. </small>
                      </div>
                    </div>

                    <div class="input-field col s12">
                      <div class="label-input">{{councillorTitle}}'s Bio</div>
                      <textarea id="chiefBio" type="text" formControlName='chiefBio'
                        class="materialize-textarea validate" placeholder="Bio ..."> </textarea>
                      <div *ngIf="(getChiefBio.dirty || getChiefBio.touched) && getChiefBio.invalid">
                        <small *ngIf="getChiefBio.errors.required" class="red-text"> The Description / Bio is
                          required.
                        </small>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row card-panel white">
                  <div class="input-field class col s11 l7">
                    <div class="label-input">Elected On</div>
                    <input id="appointmentDate" type="text" formControlName='appointmentDate' class="input-box"
                      autocomplete="off" angular-mydatepicker name="mydate" (click)="dp1.toggleCalendar()"
                      [options]="myDpOptions" #dp1="angular-mydatepicker"
                      (dateChanged)="onAppointmentDateChange($event)" placeholder="Elected On" />
                    <div *ngIf="(getAppointmentDate.dirty || getAppointmentDate.touched) && getAppointmentDate.invalid">
                      <small *ngIf="getAppointmentDate.errors.required" class="red-text"> Please enter The
                        Appointment
                        Date.
                      </small>
                    </div>
                  </div>

                  <div class="col s1">
                    <div class="row center">
                      <br />
                      <i class="material-icons green-text" (click)=dp1.clearDate()>clear</i>
                    </div>
                  </div>

                  <div class="input-field class col s11 l7">
                    <div class="label-input">Mandate Ends On</div>
                    <input formControlName="expiryDate" id="expiryDate" type="text" class="validate input-box"
                      autocomplete="off" angular-mydatepicker name="mydate" (click)="dp2.toggleCalendar()"
                      [options]="myDpOptions" #dp2="angular-mydatepicker" (dateChanged)="onExpiryDateChange($event)"
                      placeholder="Mandate Ends On" />
                    <div *ngIf="(getExpiryDate.dirty || getExpiryDate.touched) && getExpiryDate.invalid">
                      <small *ngIf="getExpiryDate.errors.required" class="red-text"> Please enter The Expiry Date.
                      </small>
                    </div>
                  </div>

                  <div class="col s1">
                    <div class="row center">
                      <br />
                      <i class="material-icons green-text" (click)=dp2.clearDate()>clear</i>
                    </div>
                  </div>
                </div>

                <div class="row card-panel white">

                  <!--  facebook  -->
                  <div class="input-field col s12 l10">
                    <div class="label-input"> {{councillorTitle}}'s Facebook account</div>
                    <input id="councillorFacebook" type="text" formControlName='councillorFacebookAcc'
                      class="materialize-textarea" placeholder="Facebook account">
                    <div
                      *ngIf="(getCouncillorFacebookAcc?.dirty || getCouncillorFacebookAcc?.touched) && getCouncillorFacebookAcc?.invalid">
                      <small *ngIf="getCouncillorFacebookAcc.errors.pattern" class="red-text"> Wrong Facebook url
                        format </small>
                    </div>
                  </div>

                  <!--  instagram  -->
                  <div class="input-field col s12 l10">
                    <div class="label-input">{{councillorTitle}}'s Instagram account</div>
                    <input id="councillorInsta" type="text" formControlName='councillorInstagramAcc'
                      class="materialize-textarea" placeholder="Instagram account">
                    <div
                      *ngIf="(getCouncillorInstagramAcc?.dirty || getCouncillorInstagramAcc?.touched) && getCouncillorInstagramAcc?.invalid">
                      <small *ngIf="getCouncillorInstagramAcc.errors.pattern" class="red-text"> Wrong Instagram url
                        format </small>
                    </div>
                  </div>

                  <!--  twitter  -->
                  <div class="input-field col s12 l10">
                    <div class="label-input">{{councillorTitle}}'s Twitter account</div>
                    <input id="councillorTwitter" type="text" formControlName='councillorTwitterAcc'
                      class="materialize-textarea" placeholder="Twitter account">
                    <div
                      *ngIf="(getCouncillorTwitterAcc?.dirty || getCouncillorTwitterAcc?.touched) && getCouncillorTwitterAcc?.invalid">
                      <small *ngIf="getCouncillorTwitterAcc.errors.pattern" class="red-text"> Wrong Twitter url
                        format
                      </small>
                    </div>
                  </div>

                  <!--  linked in  -->
                  <div class="input-field col s12 l10">
                    <div class="label-input">{{councillorTitle}}'s LinkedIn account</div>
                    <input id="councillorLinkedin" type="text" formControlName='councillorLinkedinAcc'
                      class="materialize-textarea" placeholder="LinkedIn account">
                    <div
                      *ngIf="(getCouncillorLinkedinAcc?.dirty || getCouncillorLinkedinAcc?.touched) && getCouncillorLinkedinAcc?.invalid">
                      <small *ngIf="getCouncillorLinkedinAcc.errors.pattern" class="red-text"> Wrong Linkedin url
                        format </small>
                    </div>
                  </div>

                  <!--  youtube  -->
                  <div class="input-field col s12 l10">
                    <div class="label-input">{{councillorTitle}}'s Youtube account</div>
                    <input id="councillorYoutube" type="text" formControlName='councillorYoutubeAcc'
                      class="materialize-textarea" placeholder="Youtube account">
                    <div
                      *ngIf="(getCouncillorYoutubeAcc?.dirty || getCouncillorYoutubeAcc?.touched) && getCouncillorYoutubeAcc?.invalid">
                      <small *ngIf="getCouncillorYoutubeAcc.errors.pattern" class="red-text"> Wrong Youtube url
                        format
                      </small>
                    </div>
                  </div>
                </div>
              </form>

              <div class="row card-panel white">
                <h6 style="margin-bottom: 15px; margin-top: 15px; font-weight: 600;">Contact Settings
                </h6>

                <div class="col s12">
                  <p><strong>Show Mobile Phone</strong></p>
                  <div class="col s12 m6 l4">
                    <label>
                      <input type="radio" name="group1" [checked]="showMobileNo === 'Public'"
                        (click)="publicMobileClick()" />
                      <span><strong>General Public</strong></span>
                    </label>
                  </div>
                  <div class="col s12 m6 l4">
                    <label>
                      <input type="radio" name="group1" [checked]="showMobileNo === 'Members'"
                        (click)="membersMobileClick()" />
                      <span class="text"><strong>Members Only</strong></span>
                    </label>
                  </div>
                  <div class="col s12 m6 l4">
                    <label>
                      <input type="radio" name="group1" [checked]="showMobileNo === 'Private'"
                        (click)="privateMobileClick()" />
                      <span class="red-text"><strong>Do Not Show </strong></span>
                    </label>
                  </div>
                </div>

                <div class="col s12">
                  <p><strong>Show Office Phone</strong></p>
                  <div class="col s12 m6 l4">
                    <label>
                      <input type="radio" value="Yes" name="group0" [checked]="showOfficeNo === 'Public'"
                        (click)="publicOfficeClick()" />
                      <span class="text"><strong>General Public</strong></span>
                    </label>
                  </div>
                  <div class="col s12 m6 l4">
                    <label>
                      <input type="radio" value="No" name="group0" [checked]="showOfficeNo === 'Members'"
                        (click)="membersOfficeClick()" />
                      <span class="text"><strong>Members Only</strong></span>
                    </label>
                  </div>
                  <div class="col s12 m6 l4">
                    <label>
                      <input type="radio" name="group0" [checked]="showOfficeNo === 'Private'"
                        (click)="privateOfficeClick()" />
                      <span class="red-text"><strong>Do Not Show</strong></span>
                    </label>
                  </div>
                </div>

                <div class="col s12">
                  <p><strong>Show Email Address</strong></p>
                  <div class="col s12 m6 l4">
                    <label>
                      <input type="radio" name="group2" [checked]="showEmailAdr === 'Public'"
                        (click)="publicEmailClick()" />
                      <span class="text"><strong>General Public</strong></span>
                    </label>
                  </div>
                  <div class="col s12 m6 l4">
                    <label>
                      <input type="radio" name="group2" [checked]="showEmailAdr === 'Members'"
                        (click)="membersEmailClick()" />
                      <span class="blue-text"><strong>Members Only</strong></span>
                    </label>
                  </div>
                  <div class="col s12 m6 l4">
                    <label>
                      <input type="radio" name="group2" [checked]="showEmailAdr === 'Private'"
                        (click)="privateEmailClick()" />
                      <span class="red-text"><strong>Do Not Show</strong></span>
                    </label>
                  </div>
                </div>

                <div class="col s12">
                  <p><strong>Allow Contact Us Messages </strong></p>
                  <div class="col s12 m6 l4">
                    <label>
                      <input type="radio" name="group3" [checked]="allowContact === 'Public'"
                        (click)="publicContactClick()" />
                      <span class="text"><strong>General Public</strong></span>
                    </label>
                  </div>
                  <div class="col s12 m6 l4">
                    <label>
                      <input type="radio" name="group3" [checked]="allowContact === 'Members'"
                        (click)="membersContactClick()" />
                      <span class="text"><strong>Members Only</strong></span>
                    </label>
                  </div>
                  <div class="col s12 m6 l4">
                    <label>
                      <input type="radio" name="group3" [checked]="allowContact === 'Private'"
                        (click)="privateContactClick()" />
                      <span class="red-text"><strong>Do Not Allow</strong></span>
                    </label>
                  </div>
                </div>

                <!-- facebook -->
                <div class="col s12">
                  <p><strong>Show Facebook Account</strong></p>
                  <div class="col s12 m6 l4">
                    <label>
                      <input type="radio" name="cShowFacebookAcc" [checked]="showFacebookAcc === 'Public'"
                        (click)="publicFacebookClick()" />
                      <span><strong>General Public</strong></span>
                    </label>
                  </div>
                  <div class="col s12 m6 l4">
                    <label>
                      <input type="radio" name="cShowFacebookAcc" [checked]="showFacebookAcc === 'Members'"
                        (click)="membersFacebookClick()" />
                      <span class="text"><strong>Members Only</strong></span>
                    </label>
                  </div>
                  <div class="col s12 m6 l4">
                    <label>
                      <input type="radio" name="cShowFacebookAcc" [checked]="showFacebookAcc === 'Private'"
                        (click)="privateFacebookClick()" />
                      <span class="red-text"><strong>Do Not Show </strong></span>
                    </label>
                  </div>
                </div>

                <!-- instagram -->
                <div class="col s12">
                  <p><strong>Show Instagram Account</strong></p>
                  <div class="col s12 m6 l4">
                    <label>
                      <input type="radio" name="cShowInstagramGroup" [checked]="showInstagramAcc === 'Public'"
                        (click)="publicInstagramClick()" />
                      <span><strong>General Public</strong></span>
                    </label>
                  </div>
                  <div class="col s12 m6 l4">
                    <label>
                      <input type="radio" name="cShowInstagramGroup" [checked]="showInstagramAcc === 'Members'"
                        (click)="membersInstagramClick()" />
                      <span class="text"><strong>Members Only</strong></span>
                    </label>
                  </div>
                  <div class="col s12 m6 l4">
                    <label>
                      <input type="radio" name="cShowInstagramGroup" [checked]="showInstagramAcc === 'Private'"
                        (click)="privateInstagramClick()" />
                      <span class="red-text"><strong>Do Not Show </strong></span>
                    </label>
                  </div>
                </div>

                <!-- twitter -->
                <div class="col s12">
                  <p><strong>Show Twitter Account</strong></p>
                  <div class="col s12 m6 l4">
                    <label>
                      <input type="radio" name="cShowTwitterAcc" [checked]="showTwitterAcc === 'Public'"
                        (click)="publicTwitterClick()" />
                      <span><strong>General Public</strong></span>
                    </label>
                  </div>
                  <div class="col s12 m6 l4">
                    <label>
                      <input type="radio" name="cShowTwitterAcc" [checked]="showTwitterAcc === 'Members'"
                        (click)="membersTwitterClick()" />
                      <span class="text"><strong>Members Only</strong></span>
                    </label>
                  </div>
                  <div class="col s12 m6 l4">
                    <label>
                      <input type="radio" name="cShowTwitterAcc" [checked]="showTwitterAcc === 'Private'"
                        (click)="privateTwitterClick()" />
                      <span class="red-text"><strong>Do Not Show </strong></span>
                    </label>
                  </div>
                </div>

                <!-- linkedin -->
                <div class="col s12">
                  <p><strong>Show Linkedin Account</strong></p>
                  <div class="col s12 m6 l4">
                    <label>
                      <input type="radio" name="cShowLinkedinGroup" [checked]="showLinkedinAcc === 'Public'"
                        (click)="publicLinkedinClick()" />
                      <span><strong>General Public</strong></span>
                    </label>
                  </div>
                  <div class="col s12 m6 l4">
                    <label>
                      <input type="radio" name="cShowLinkedinGroup" [checked]="showLinkedinAcc === 'Members'"
                        (click)="membersLinkedinClick()" />
                      <span class="text"><strong>Members Only</strong></span>
                    </label>
                  </div>
                  <div class="col s12 m6 l4">
                    <label>
                      <input type="radio" name="cShowLinkedinGroup" [checked]="showLinkedinAcc === 'Private'"
                        (click)="privateLinkedinClick()" />
                      <span class="red-text"><strong>Do Not Show </strong></span>
                    </label>
                  </div>
                </div>

                <!-- youtube -->
                <div class="col s12">
                  <p><strong>Show Youtube Account</strong></p>
                  <div class="col s12 m6 l4">
                    <label>
                      <input type="radio" name="cShowYoutubeAcc" [checked]="showYoutubeAcc === 'Public'"
                        (click)="publicYoutubeClick()" />
                      <span><strong>General Public</strong></span>
                    </label>
                  </div>
                  <div class="col s12 m6 l4">
                    <label>
                      <input type="radio" name="cShowYoutubeAcc" [checked]="showYoutubeAcc === 'Members'"
                        (click)="membersYoutubeClick()" />
                      <span class="text"><strong>Members Only</strong></span>
                    </label>
                  </div>
                  <div class="col s12 m6 l4">
                    <label>
                      <input type="radio" name="cShowYoutubeAcc" [checked]="showYoutubeAcc === 'Private'"
                        (click)="privateYoutubeClick()" />
                      <span class="red-text"><strong>Do Not Show </strong></span>
                    </label>
                  </div>
                </div>

                <div class="row">
                  <br />
                </div>
              </div>

              <div class="row card-panel white">
                <h6 style="margin-bottom: 15px; margin-top: 15px; font-weight: 600;">Portfolio</h6>
                <div class="row">
                  <ul class="collection col s12" *ngIf="departmentsOptions">

                    <li class="collection-item" *ngFor="let item of departmentsOptions">
                      <div>
                        <label>
                          <input type="checkbox" class="filled-in" [(ngModel)]="item.isSelected" value="item.id" />
                          <span [innerHTML]="item.name"></span>
                        </label>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div class="section"></div>

            <button *ngIf="showCouncillorForm && fromWhere === 'add'" class="col s12 btn waves-effect"
              [ngClass]="'btn ' + buttonType" [ngStyle]="{'background-color': themeColor}" type="button" title="Save"
              [disabled]="!addCouncillorForm.valid" (click)="addCouncillorData()" style="margin-bottom: 15px;"><i
                class="material-icons right">cloud_upload</i>Add {{councillorTitle}}</button>

            <button *ngIf="showCouncillorForm && fromWhere === 'edit'" class="col s12 btn waves-effect"
              [ngClass]="'btn ' + buttonType" [ngStyle]="{'background-color': themeColor}" type="button" title="Save"
              [disabled]="!addCouncillorForm.valid" (click)="addCouncillorData()" style="margin-bottom: 15px;"><i
                class="material-icons right">edit</i>Update {{councillorTitle}}</button>

            <button *ngIf="showCouncillorForm && fromWhere === 'edit'" [ngClass]="'btn' + buttonType"
              class="col s12 btn waves-effect red modal-trigger" type="button" title="Delete Councillor"
              (click)="deleteCouncillor()" style="margin-bottom: 15px;" data-target="confirmDeleteModal"><i
                class="material-icons right">delete</i>Delete {{councillorTitle}}</button>

            <button *ngIf="showSearch" title="Search" (click)="onSearchEmployees()"
              [disabled]="searchMemberForm.get('firstName').invalid && searchMemberForm.get('lastName').invalid"
              class="col s12 btn waves-effect" [ngClass]="'btn' + buttonType"
              [ngStyle]="{'background-color': themeColor}" style="margin-bottom: 15px;">
              <i class="material-icons right">search</i> Search In The Database
            </button>

            <button *ngIf="showSearch" class="col s12 btn waves-effect secondary-button white" title="Search"
              [ngClass]="'btn' + buttonType" (click)="onClearSearchEmployees()" [ngStyle]="{'color': themeColor}"
              [disabled]="searchMemberForm.get('firstName').invalid && searchMemberForm.get('lastName').invalid"
              style="margin-bottom: 15px;">
              <i class="material-icons right">search</i> New Search
            </button>

          </div>
        </div>

        <div id="detailModal" class="modal" *ngIf="selected_member">
          <div class="modal-content">
            <h6>Selected Member</h6>
            <br />
            <div class="row">
              <ul class="collection">
                <li class="collection-item">
                  <div> First Name: <span class="secondary-content">{{selected_member.GivenName}} </span></div>
                </li>
                <li class="collection-item">
                  <div> Last Name: <span class="secondary-content"> {{selected_member.LastName}} </span></div>
                </li>
                <li class="collection-item">
                  <div> DOB: <span class="secondary-content"> {{selected_member.DOB | date}} </span></div>
                </li>
                <li class="collection-item">
                  <div> Address: <span class="secondary-content"> {{selected_member.MailAddress}} </span></div>
                </li>
                <li class="collection-item">
                  <div> City: <span class="secondary-content"> {{selected_member.city}} </span></div>
                </li>
                <li class="collection-item">
                  <div> Province: <span class="secondary-content"> {{selected_member.province}} </span></div>
                </li>
                <li class="collection-item">
                  <div> Postal Code: <span class="secondary-content"> {{selected_member.PostalCode}} </span></div>
                </li>
              </ul>
            </div>
          </div>
          <div class="modal-footer">
            <a (click)="selectClick()" class="modal-close green btn-flat white-text"> <i class="material-icons">done</i>
              Select</a>
          </div>
        </div>

        <div id="confirmDeleteModal" class="modal">
          <div class="modal-content">
            <h5 class="bold-700" *ngIf="deleteItem == 'Photo'">Delete Photo</h5>
            <h5 class="bold-700" *ngIf="deleteItem == 'Councillor'">Delete {{councillorTitle}}</h5>
            <div class="valign-wrapper card-panel red lighten-5">
              <i class="material-icons left red-text" style="font-size: 63px;">
                warning
              </i>
              <div *ngIf="deleteItem == 'Photo'">
                <h6>Are You sure you want to delete this photo? </h6>
                <h6><strong>The photo will be permanently deleted. This action cannot be reversed.</strong></h6>
              </div>

              <div *ngIf="deleteItem == 'Councillor'">
                <h6>Are You sure you want to delete the {{councillorTitle}}? </h6>
                <h6><strong>This will permanently remove the {{councillorTitle}}'s information.This action is
                    irreversible.</strong>
                </h6>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <a class="modal-close waves-effect waves-light btn-flat"><strong>Cancel</strong></a>
            <a (click)="removeSavedFile()" *ngIf="deleteItem == 'Photo'"
              class="modal-close btn waves-effect waves-light red"><strong>Delete</strong></a>
            <a (click)="archiveCouncillor()" *ngIf="deleteItem == 'Councillor'"
              class="modal-close btn waves-effect waves-light red"><strong>Delete</strong></a>
          </div>
        </div>

      </main>
    </div>
  </div>
</div>