<div class="container-iset body">

    <!-- personal information -->
    <div class="row" *ngIf="step == 1">
        <br />
        <div class="row grey lighten-3 blue-text">
            <h6 class="center-align">Part A : Personal Information</h6>
        </div>

        <form class="row col s12" [formGroup]="personalInfoForm"
            (ngSubmit)="submitPersonalInfo(personalInfoForm.value)">

            <div class="row card-panel">

                <div class="row">

                    <div class="row col s12 m9 l9">
                        <div class="label-input">Title<span class="red-text">*</span></div>
                        <label class="col s3 m3 form-group custom-control custom-radio">
                            <input formControlName="title" name="title" type="radio" value="Mr" />
                            <span>Mr.</span>
                        </label>
                        <label class="col s3 m3 ">
                            <input formControlName="title" name="title" type="radio" value="Mrs" />
                            <span>Mrs</span>
                        </label>
                        <label class="col s3 m3 ">
                            <input formControlName="title" name="title" type="radio" value="Miss" />
                            <span>Miss</span>
                        </label>
                        <label class="col s3 m3 ">
                            <input formControlName="title" name="title" type="radio" value="Ms" />
                            <span>Ms</span>
                        </label>
                    </div>


                    <div class="input-field col s12 m6 l4">
                        <div class="label-input">First Name<span class="red-text">*</span></div>
                        <input id="givenname" type="text" formControlName="givenname" maxlength="50" class="validate"
                            placeholder="First Name">
                        <!-- <label for="givenname">First Name<span class="red-text point">*</span></label> -->
                    </div>

                    <div class="input-field col s12 m6 l4">
                        <div class="label-input">Middle Name</div>
                        <input id="initial" type="text" formControlName="initial" maxlength="50" class="validate"
                            placeholder="Middle Name">
                        <!-- <label for="initial">Middle Name</label> -->
                    </div>

                    <div class="input-field col s12 m6 l4">
                        <div class="label-input">Last Name<span class="red-text">*</span></div>
                        <input id="lastname" type="text" formControlName="lastname" maxlength="50" class="validate"
                            placeholder="Last Name">
                        <!-- <label for="lastname">Last Name<span class="red-text point">*</span></label> -->
                    </div>






                    <div class="input-field col s12 m6 l4">
                        <div class="label-input">Date Of Birth<span class="red-text">*</span></div>
                        <input id="dob" formControlName="dob" type="text" class="input-box validate"
                            angular-mydatepicker name="mydate" (click)="dp1.toggleCalendar()" [options]="myDpOptions"
                            #dp1="angular-mydatepicker" (dateChanged)="onBirthDateChange($event)"
                            placeholder="DD / MM / YYYY" />
                    </div>


                    <!-- <div class="row col s12 m6  ">
                        <div class="form-group custom-control custom-radio ">
                        <input type="radio" id="title" name="title" class="custom-control-input">
                        <label class="custom-control-label" for="title">Testing</label>
                    </div>
                </div> -->




                    <div class="row col s12 m12">
                        <div class="label-input">Gender<span class="red-text">*</span></div>

                        <div class="row col s12 m12 ">
                            <label class="col s6 m6 l3">
                                <input formControlName="gender" name="gender" type="radio" value="M" />
                                <span>Male</span>
                            </label>
                            <label class="col s6 m6 l3">
                                <input formControlName="gender" name="gender" type="radio" value="F" />
                                <span>Female</span>
                            </label>
                        </div>

                    </div>




                    <div class="input-field col s12 m6 l4">
                        <div class="label-input">SIN</div>
                        <!-- <input id="SIN" type="text" formControlName="SIN" class="validate"
                            [textMask]="{mask: SINMask, guide: true}" placeholder="SIN"> -->
                        <input id="SIN" type="text" [inputMask]="SINMask" formControlName="SIN" class="validate"
                            placeholder="SIN">
                        <!-- <label for="SIN">SIN </label> -->
                    </div>


                    <div class="input-field col s12 m6 l4">
                        <div class="label-input">Contact Number</div>
                        <!-- <input id="cell" [textMask]="{mask: phoneNumberMask, guide: true}" type="text"
                            formControlName="cell" class="validate" placeholder="Contact Number"> -->
                        <input id="cell" type="text" [inputMask]="phoneInputMask" formControlName="cell"
                            class="validate" placeholder="Contact Number">
                        <!-- <label for="cell">Contact Number</label> -->
                    </div>


                    <div class="input-field col s12 m6 l4">
                        <div class="label-input">Email<span class="red-text">*</span></div>
                        <input id="email" type="text" formControlName="email" class="validate" placeholder="Email">
                        <!-- <label for="email">Email<span class="red-text point">*</span></label> -->
                        <div *ngIf="(email.dirty || email.touched) && email.invalid">
                            <small *ngIf="email.errors.email" class="red-text">Invalid Email Address</small>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <h6 class=""><strong>Home Address: </strong></h6>
            </div>
            <div class="row card-panel">

                <div class="row">
                    <div class="input-field col s12 m6 l4">
                        <div class="label-input">Home Address<span class="red-text">*</span></div>
                        <input id="home_address" type="text" formControlName="home_address" class="validate"
                            maxlength="80" placeholder="Home Address">
                        <!-- <label for="home_address">Home Address<span class="red-text point">*</span></label> -->
                    </div>

                    <div class="input-field col s12 m6 l4">
                        <div class="label-input">City<span class="red-text">*</span></div>
                        <input id="city" type="text" formControlName="city" class="validate" maxlength="50"
                            placeholder="City">
                        <!-- <label for="city">City<span class="red-text point">*</span></label> -->
                        <div *ngIf="(city.dirty || city.touched) && city.invalid">
                            <small *ngIf="city.errors.required" class="red-text point">City is required</small>
                        </div>
                    </div>

                    <div class="input-field col s12 m6 l4">
                        <div class="label-input">Postal Code<span class="red-text">*</span></div>
                        <!-- <input id="postal_code" [textMask]="{mask: postalCodeMask, guide: true}" type="text"
                            formControlName="postal_code" class="validate" placeholder="Postal Code"> -->
                        <input id="postal_code" type="text" [inputMask]="postalCodeMask" formControlName="postal_code"
                            class="validate" placeholder="Postal Code">
                        <!-- <label for="postal_code">Postal Code<span class="red-text point">*</span></label> -->
                        <div *ngIf="(postal_code.dirty || postal_code.touched) && postal_code.invalid">
                            <small *ngIf="postal_code.errors.required" class="red-text"> Postal Code is required.
                            </small>
                        </div>
                    </div>

                    <div class="col s12 m6 l6">
                        <div class="label-input">Province<span class="red-text">*</span></div>
                        <!-- <label for="province">Province<span class="red-text point">*</span></label> -->
                        <select id="province" formControlName="province" class="validate browser-default">
                            <option disabled value="">Please Select</option>
                            <option *ngFor="let p of provinces" [value]="p">{{p}}</option>
                        </select>
                    </div>



                    <div class="input-field col s12 m12">
                        <div class="label-input">Mailing Address (if different then above)<span
                                class="red-text">*</span></div>
                        <input id="mailing_address" type="text" formControlName="mailing_address" class="validate"
                            maxlength="80" placeholder="Mail Address">
                        <!-- <label for="mailing_address">Mailing Address (if different then above)</label> -->
                    </div>

                </div>
            </div>

            <div class="row">
                <h6 class=""><strong>Other Required Information: </strong> </h6>
            </div>
            <div class="row card-panel">

                <div class="row">

                    <div class="input-field col s12 m6">
                        <div class="label-input">Emergency Contact 1</div>
                        <input id="Econtact1" type="text" formControlName="Econtact1" class="validate"
                            placeholder="Emergency Contact 1">
                        <!-- <label for="Econtact1">Emergency Contact 1</label> -->
                    </div>

                    <div class="input-field col s12 m6">
                        <div class="label-input">Phone Number</div>
                        <!-- <input id="contct1PH" [textMask]="{mask: phoneNumberMask, guide: true}" type="text"
                            formControlName="contct1PH" class="validate" placeholder="Phone Number"> -->
                        <input id="contct1PH" type="text" [inputMask]="phoneInputMask" formControlName="contct1PH"
                            class="validate" placeholder="Phone Number">
                        <!-- <label for="contct1PH">Phone Number</label> -->
                        <!--<div *ngIf="(contct1PH.dirty || contct1PH.touched) && contct1PH.invalid">
                            <small *ngIf="contct1PH.errors.required" class="red-text">Phone Number is Required.</small>
                        </div>-->
                    </div>

                    <div class="input-field col s12 m6">
                        <div class="label-input">Emergency Contact 2</div>
                        <input id="Econtact2" type="text" formControlName="Econtact2" class="validate"
                            placeholder="Emergency Contact 2">
                        <!-- <label for="Econtact2">Emergency Contact 2</label> -->
                    </div>

                    <div class="input-field col s12 m6">
                        <div class="label-input">Phone Number</div>
                        <!-- <input id="contct2PH" [textMask]="{mask: phoneNumberMask, guide: true}" type="text"
                            formControlName="contct2PH" class="validate" placeholder="Phone Number"> -->
                        <input id="contct2PH" type="text" [inputMask]="phoneInputMask" formControlName="contct2PH"
                            class="validate" placeholder="Phone Number">
                        <!-- <label for="contct2PH">Phone Number</label> -->
                        <!--<div *ngIf="(contct2PH.dirty || contct2PH.touched) && contct2PH.invalid">
                            <small *ngIf="contct2PH.errors.required" class="red-text">Phone Number is Required.</small>
                        </div>-->
                    </div>

                    <div class="input-field col s12 m6">
                        <div class="label-input">Citizenship</div>
                        <input id="citizenship" type="text" formControlName="citizenship" class="validate"
                            placeholder="Citizenship">
                        <!-- <label for="citizenship">Citizenship</label> -->
                    </div>


                    <div class="input-field col s12 m6">
                        <div class="label-input">Language</div>
                        <input id="language" type="text" formControlName="language" class="validate"
                            placeholder="Langugage">
                        <!-- <label for="language">Language</label> -->
                    </div>

                </div>
            </div>


            <div class="row card-panel">

                <div class="row col s12 ">
                    <p>Do you have a Drivers License? <span class="red-text point">*</span></p>
                    <label class="col s6 m6">
                        <input formControlName="driver_license" name="driver_license" type="radio" value="yes"
                            (click)="onDriverLicenseYes()" />
                        <span>Yes</span>
                    </label>
                    <label class="col s6 m6">
                        <input formControlName="driver_license" name="driver_license" type="radio" value="no"
                            (click)="onDriverLicenseNo()" />
                        <span>No</span>
                    </label>
                </div>


                <div class="row">

                    <div class="input-field col s12 m6" *ngIf="hasDriverLicense">
                        <div class="label-input">Driver License Class<span class="red-text point">*</span></div>
                        <input id="driver_class" type="text" formControlName="driver_class" class="validate"
                            placeholder="Driver License Class">
                        <!-- <label for="driver_class">Driver License Class<span class="red-text point">*</span></label> -->
                    </div>


                    <div class="input-field col s12 m6" *ngIf="hasDriverLicense">
                        <div class="label-input">Expiry Date<span class="red-text point">*</span></div>
                        <input id="license_expiry_date" formControlName="license_expiry_date" type="text"
                            class="input-box validate" placeholder="Expiry Date" angular-mydatepicker name="mydate"
                            (click)="dp1.toggleCalendar()" [options]="myDpOptions" #dp1="angular-mydatepicker"
                            (dateChanged)="onLicenseExpiryDateChange($event)" />
                        <!-- <label for="license_expiry_date">Expiry Date<span class="red-text point">*</span></label> -->
                    </div>


                    <div class="col s12 m6" *ngIf="hasDriverLicense">
                        <div class="label-input">Province<span class="red-text point">*</span></div>
                        <!-- <label for="province_issued">Province<span class="red-text point">*</span></label> -->
                        <select id="province_issued" formControlName="province_issued" class="validate browser-default">
                            <option disabled value="">Please Select</option>
                            <option *ngFor="let p of provinces" [value]="p">{{p}}</option>
                        </select>
                    </div>
                </div>
            </div>


            <div class="row">
                <h6><strong>Language Skills: </strong></h6>
            </div>
            <div class="row card-panel">
                <div class="row">

                    <div class="col s12 m6">
                        <div class="label-input">Reading<span class="red-text point">*</span></div>
                        <!-- <label for="reading_skill">Reading<span class="red-text point">*</span></label> -->
                        <select id="reading_skill" formControlName="reading_skill" class="validate browser-default">
                            <option disabled value="">Please Select</option>
                            <option *ngFor="let p of languageSkills" [value]="p">{{p}}</option>
                        </select>
                    </div>

                    <div class="col s12 m6">
                        <div class="label-input">Writing<span class="red-text point">*</span></div>
                        <!-- <label for="writing_skill">Writing<span class="red-text point">*</span></label> -->
                        <select id="writing_skill" formControlName="writing_skill" class="validate browser-default">
                            <option disabled value="">Please Select</option>
                            <option *ngFor="let p of languageSkills" [value]="p">{{p}}</option>
                        </select>
                    </div>

                    <div class="col s12 m6">
                        <!-- <label for="listening_skill">Listening<span class="red-text point">*</span></label> -->
                        <div class="label-input">Listening<span class="red-text point">*</span></div>
                        <select id="listening_skill" formControlName="listening_skill" class="validate browser-default">
                            <option disabled value="">Please Select</option>
                            <option *ngFor="let p of languageSkills" [value]="p">{{p}}</option>
                        </select>
                    </div>

                    <div class="col s12 m6">
                        <!-- <label for="speaking_skill">Speaking<span class="red-text point">*</span></label> -->
                        <div class="label-input">Speaking<span class="red-text point">*</span></div>
                        <select id="speaking_skill" formControlName="speaking_skill" class="validate browser-default">
                            <option disabled value="">Please Select</option>
                            <option *ngFor="let p of languageSkills" [value]="p">{{p}}</option>
                        </select>
                    </div>


                </div>
            </div>


            <div class="row card-panel">
                <div class="row">
                    <div class="label-input">Indian Status:<span class="red-text point">*</span></div>
                    <!-- <p>Indian Status:<span class="red-text point">*</span></p> -->
                    <label class="col s12 m4">
                        <input formControlName="aboriginal_group" name="aboriginal_group" type="radio" value="1" />
                        <span>Treaty Status</span>
                    </label>
                    <label class="col s12 m4">
                        <input formControlName="aboriginal_group" name="aboriginal_group" type="radio" value="2" />
                        <span>Non-Status</span>
                    </label>
                    <label class="col s12 m4">
                        <input formControlName="aboriginal_group" name="aboriginal_group" type="radio" value="3" />
                        <span>Inuit</span>
                    </label>
                </div>
            </div>



            <div class="row"></div>
            <div class="row">
                <div class="col s6">
                    <button class="btn left waves-effect red" type="button" style="margin-bottom: 15px"
                        (click)="onBack()"><i class="material-icons left">arrow_back</i>Back
                    </button>
                </div>
                <div class="col s6">
                    <!--   [disabled]="!personalInfoForm.valid"  -->
                    <button class=" btn right  green" type="submit" style="margin-bottom: 15px" title="Save">Next <i
                            class="material-icons right">arrow_forward</i></button>
                </div>
            </div>

        </form>

    </div>


    <!-- Income Information -->
    <div class="row" *ngIf="step == 2">
        <br />
        <div class="row grey lighten-3 blue-text">
            <h6 class="center-align">Part B: Current Income Information</h6>
        </div>

        <form class="row col s12" [formGroup]="incomeInfoForm" (ngSubmit)="submitIncomeInfo(incomeInfoForm.value)">

            <div class="row card-panel">

                <div class="row">

                    <div class="col s12 m12">
                        <div class="label-input">Current Employment Status:<span class="red-text point">*</span></div>
                        <!-- <label for="employment_status"><strong>Current Employment Status:</strong><span
                                class="red-text point">*</span></label> -->
                        <select id="employment_status" formControlName="employment_status"
                            class="validate  browser-default" (change)="employmentStatusSelected($event.target.value)">
                            <option disabled value="">Please Select</option>
                            <option *ngFor="let e of employments" [value]="e">{{e}}</option>
                        </select>
                    </div>


                    <div class="input-field col s12 m12" *ngIf="isOtherEmpStatus">
                        <div class="label-input">If other, Please Specify<span class="red-text point">*</span></div>
                        <input id="emp_other_specify" type="text" formControlName="emp_other_specify" class="validate"
                            maxlength="50" placeholder="">
                        <!-- <label for="emp_other_specify">If other, Please Specify</label> -->
                    </div>



                    <div class="input-field col s12 ">
                        <p>Have you received EI within the last 3 years? : <span class="red-text point">*</span> </p>
                        <div class="row col s4 m4"> </div>
                        <div class="row col s8 m8">
                            <label class="col s6">
                                <input formControlName="ei_received" name="ei_received" type="radio" checked="checked"
                                    value="yes" />
                                <span>Yes</span>
                            </label>
                            <label class="col s6">
                                <input formControlName="ei_received" name="ei_received" type="radio" value="no" />
                                <span>No</span>
                            </label>
                        </div>
                    </div>


                    <div class="row col s12 m12">
                        <p>Are you in the process of applying for EI Benefits?<span class="red-text point">*</span></p>
                        <div class="row col s4 m4"> </div>
                        <div class="row col s8 m8">
                            <label class="col s6 m6">
                                <input formControlName="apply_ei" name="apply_ei" type="radio" value="yes" />
                                <span>Yes</span>
                            </label>
                            <label class="col s6 m6">
                                <input formControlName="apply_ei" name="apply_ei" type="radio" value="no" />
                                <span>No</span>
                            </label>
                        </div>
                    </div>


                    <div class="row col s12 m12">
                        <p>Do you consider yourself to be a Person with a Disability?<span
                                class="red-text point">*</span></p>
                        <div class="row col s4 m4"> </div>
                        <div class="row col s8 m8">
                            <label class="col s6 m6">
                                <input formControlName="disability" name="disability" type="radio" value="yes" />
                                <span>Yes</span>
                            </label>
                            <label class="col s6 m6">
                                <input formControlName="disability" name="disability" type="radio" value="no" />
                                <span>No</span>
                            </label>
                        </div>
                    </div>



                    <div class="col s12 m6">
                        <label for="marital_status">Marital Status:<span class="red-text point">*</span></label>
                        <select id="marital_status" formControlName="marital_status" class="validate browser-default">
                            <option disabled value="">Please Select</option>
                            <option *ngFor="let m of maritalStatus" [value]="m.code">{{m.name}}</option>
                        </select>
                    </div>



                    <div class="col s12 m6">

                        <div class="input-field col s12 m8">
                            <input id="kids_age" type="text" formControlName="kids_age" [(ngModel)]='list_of_kids_age'
                                class="validate" style="text-transform: capitalize;" style="text-transform: capitalize;"
                                maxlength="100">
                            <label for="kids_age">List age of each child</label>
                        </div>


                        <div class="col s12 m4">
                            <!-- <button type="button" (click)="addChildren()" class="btn waves-effect green white-text" style="margin-right: 15px;">
                                <i class="material-icons left white-text">person_add</i> Add Dependants (age 0-17) :
                            </button> -->

                            <!--  <button class=" btn-large blue" type="button" (click)="addChildren()"> Add Children</button> -->
                            <button class="button1" type="button" (click)="addChildren()"> Children</button>

                        </div>

                    </div>




                    <div id="AddAgeModal" class="modal">

                        <div class="modal-dialog" role="document">
                            <div class="modal-content">

                                <div class="modal-header">
                                    <!-- <button type="button" class="right grey white-text" (click)="submitChildrenAges()">X</button> -->
                                    <h5 class="modal-title center" id="myModalLabel"><strong>Please Select Each Child
                                            Age</strong></h5>
                                </div>


                                <div class="center col s12 m12">
                                    <div class="col s12 m3"></div>

                                    <div class="col s12 m2">
                                        <button class=" btn-small green" type="button" (click)="additionKids()"> <i
                                                class="material-icons green white-text">add</i></button>
                                    </div>

                                    <div class="col s12 m2">
                                        <h6><strong><span class="red-text">{{total_childs}}</span></strong></h6>
                                    </div>

                                    <div class="col s12 m2">
                                        <button class=" btn-small green" type="button" (click)="minusKids()"> <i
                                                class="material-icons green white-text">remove</i></button>
                                    </div>

                                    <div class="col s12 m3"></div>

                                </div>

                                <br> <br> <br>

                                <div class="modal-body">

                                    <div class="col s12 m4 " *ngIf="total_childs >= 1">
                                        <label for="child1">Child 1 Age<span class="red-text">*</span></label>
                                        <select formControlName="child1" size="5" class="validate "
                                            (change)="onChild1AgeChange($event.target.value)">
                                            <option *ngFor="let a of childAges " [value]="a">{{a}}</option>
                                        </select>
                                    </div>

                                    <div class="col s12 m4" *ngIf="total_childs >= 2">
                                        <label for="child2">Child 2 Age<span class="red-text">*</span></label>
                                        <select formControlName="child2" class="validate " size="5"
                                            (change)="onChild2AgeChange($event.target.value)">
                                            <option *ngFor="let a of childAges " [value]="a">{{a}}</option>
                                        </select>
                                    </div>

                                    <div class="col s12 m4" *ngIf="total_childs >= 3">
                                        <label for="child3">Child 3 Age<span class="red-text">*</span></label>
                                        <select formControlName="child3" class="validate " size="5"
                                            (change)="onChild3AgeChange($event.target.value)">
                                            <option *ngFor="let a of childAges " [value]="a">{{a}}</option>
                                        </select>
                                    </div>

                                    <div class="col s12 m4" *ngIf="total_childs >= 4">
                                        <label for="child4">Child 4 Age<span class="red-text">*</span></label>
                                        <select formControlName="child4" class="validate " size="5"
                                            (change)="onChild4AgeChange($event.target.value)">
                                            <option *ngFor="let a of childAges " [value]="a">{{a}}</option>
                                        </select>
                                    </div>


                                    <div class="col s12 m4" *ngIf="total_childs >= 5">
                                        <label for="child5">Child 5 Age<span class="red-text">*</span></label>
                                        <select formControlName="child5" class="validate " size="5"
                                            (change)="onChild5AgeChange($event.target.value)">
                                            <option *ngFor="let a of childAges " [value]="a">{{a}}</option>
                                        </select>
                                    </div>

                                    <div class="col s12 m4" *ngIf="total_childs >= 6">
                                        <label for="child6">Child 6 Age<span class="red-text">*</span></label>
                                        <select formControlName="child6" class="validate " size="5"
                                            (change)="onChild6AgeChange($event.target.value)">
                                            <option *ngFor="let a of childAges " [value]="a">{{a}}</option>
                                        </select>
                                    </div>

                                    <div class="col s12 m4" *ngIf="total_childs >= 7">
                                        <label for="child7">Child 7 Age<span class="red-text">*</span></label>
                                        <select formControlName="child7" class="validate " size="5"
                                            (change)="onChild7AgeChange($event.target.value)">
                                            <option *ngFor="let a of childAges " [value]="a">{{a}}</option>
                                        </select>
                                    </div>

                                    <div class="col s12 m4" *ngIf="total_childs >= 8">
                                        <label for="child8">Child 8 Age<span class="red-text">*</span></label>
                                        <select formControlName="child8" class="validate " size="5"
                                            (change)="onChild8AgeChange($event.target.value)">
                                            <option *ngFor="let a of childAges " [value]="a">{{a}}</option>
                                        </select>
                                    </div>


                                    <div class="col s12 m4" *ngIf="total_childs == 9">
                                        <label for="child9">Child 9 Age<span class="red-text">*</span></label>
                                        <select formControlName="child9" class="validate"
                                            (change)="onChild9AgeChange($event.target.value)">
                                            <option *ngFor="let a of childAges " [value]="a">{{a}}</option>
                                        </select>
                                    </div>

                                </div>

                            </div>

                        </div>


                        <div class="modal-footer">
                            <button type="button" (click)="submitChildrenAges()" class="btn waves-effect red white-text"
                                style="margin-right: 15px;">
                                <i class="material-icons right white-text">close</i> Close
                            </button>
                        </div>

                    </div>

                </div>

            </div>

            <div class="row">
                <h6 class=""><strong>TYPE OF SERVICES YOU ARE REQUESTING: </strong></h6>
            </div>
            <div class="row card-panel">
                <div class="row">

                    <div class="input-field col s12 ">
                        <p>Request Resume Assistance? : <span class="red-text point">*</span> </p>
                        <div class="row col s4 m4"> </div>
                        <div class="row col s8 m8">
                            <label class="col s6">
                                <input formControlName="request_resume" name="request_resume" type="radio"
                                    value="yes" />
                                <span>Yes</span>
                            </label>
                            <label class="col s6">
                                <input formControlName="request_resume" name="request_resume" type="radio" value="no" />
                                <span>No</span>
                            </label>
                        </div>
                    </div>


                    <div class="input-field col s12 ">
                        <p>Request Skills Enhancement? : <span class="red-text point">*</span> </p>
                        <div class="row col s4 m4"> </div>
                        <div class="row col s8 m8">
                            <label class="col s6">
                                <input formControlName="request_skill" name="request_skill" type="radio" value="yes" />
                                <span>Yes</span>
                            </label>
                            <label class="col s6">
                                <input formControlName="request_skill" name="request_skill" type="radio" value="no" />
                                <span>No</span>
                            </label>
                        </div>
                    </div>

                    <div class="input-field col s12 ">
                        <p>Request Career Decision Making? : <span class="red-text point">*</span> </p>
                        <div class="row col s4 m4"> </div>
                        <div class="row col s8 m8">
                            <label class="col s6">
                                <input formControlName="request_career" name="request_career" type="radio"
                                    value="yes" />
                                <span>Yes</span>
                            </label>
                            <label class="col s6">
                                <input formControlName="request_career" name="request_career" type="radio" value="no" />
                                <span>No</span>
                            </label>
                        </div>
                    </div>



                    <div class="input-field col s12 ">
                        <p>Request Job Search? : <span class="red-text point">*</span> </p>
                        <div class="row col s4 m4"> </div>
                        <div class="row col s8 m8">
                            <label class="col s6">
                                <input formControlName="request_job" name="request_job" type="radio" value="yes" />
                                <span>Yes</span>
                            </label>
                            <label class="col s6">
                                <input formControlName="request_job" name="request_job" type="radio" value="no" />
                                <span>No</span>
                            </label>
                        </div>
                    </div>


                    <div class="input-field col s12 ">
                        <p>Request Funding? : <span class="red-text point">*</span> </p>
                        <div class="row col s4 m4"> </div>
                        <div class="row col s8 m8">
                            <label class="col s6">
                                <input formControlName="request_funding" name="request_funding" type="radio"
                                    value="yes" />
                                <span>Yes</span>
                            </label>
                            <label class="col s6">
                                <input formControlName="request_funding" name="request_funding" type="radio"
                                    value="no" />
                                <span>No</span>
                            </label>
                        </div>
                    </div>


                    <div class="input-field col s12 m12">
                        <input id="request_program" type="text" formControlName="request_program" class="validate"
                            style="text-transform: capitalize;" style="text-transform: capitalize;" maxlength="100">
                        <label for="request_program">Program </label>
                    </div>

                    <div class="input-field col s12 m12">
                        <input id="request_institute" type="text" formControlName="request_institute" class="validate"
                            style="text-transform: capitalize;" style="text-transform: capitalize;" maxlength="100">
                        <label for="request_institute">Institute Name</label>
                    </div>

                    <div class="input-field col s12 m6">
                        <!-- <i class="material-icons prefix blue-text">today</i> -->
                        <input id="request_startdate" formControlName="request_startdate" type="text" class="input-box"
                            angular-mydatepicker name="mydate" (click)="dp3.toggleCalendar()" [options]="myDpOptions"
                            #dp3="angular-mydatepicker" (dateChanged)="onStartDateChange($event)" />
                        <label for="request_startdate">Start Date</label>
                    </div>

                    <div class="input-field col s12 m6">
                        <!-- <i class="material-icons prefix blue-text">today</i> -->
                        <input id="request_enddate" formControlName="request_enddate" type="text" class="input-box"
                            angular-mydatepicker name="mydate" (click)="dp4.toggleCalendar()" [options]="myDpOptions"
                            #dp4="angular-mydatepicker" (dateChanged)="onEndDateChange($event)" />
                        <label for="request_enddate">End Date</label>
                    </div>


                </div>
            </div>

            <div class="row"></div>
            <div class="row">
                <div class="col s6">
                    <button class="btn left waves-effect red" type="button" style="margin-bottom: 15px"
                        (click)="onBack()"><i class="material-icons left">arrow_back</i>Back
                    </button>
                </div>
                <div class="col s6">
                    <!--   [disabled]="!incomeInfoForm.valid"   -->
                    <button class=" btn right  green" type="submit" style="margin-bottom: 15px" title="Save">Next <i
                            class="material-icons right">arrow_forward</i></button>
                </div>
            </div>

        </form>

    </div>



    <!-- Education Information -->
    <div class="row" *ngIf="step == 3">
        <br />
        <div class="row grey lighten-3 blue-text">
            <h6 class="center-align">Part C: Education Information</h6>
        </div>
        <form class="row col s12" [formGroup]="educationInfoForm"
            (ngSubmit)="submitEducationInfo(educationInfoForm.value)">

            <div class="row">
                <h6 class=""><strong>High School: </strong></h6>
            </div>
            <div class="row card-panel">
                <div class="row">

                    <div class="input-field col s12 m6">
                        <input id="highschool" type="text" formControlName="highschool" class="validate"
                            style="text-transform: capitalize;" style="text-transform: capitalize;" maxlength="50">
                        <label for="highschool">High School <span class="red-text point">*</span></label>
                    </div>

                    <div class="input-field col s12 m6">
                        <input id="hs_city" type="text" formControlName="hs_city" class="validate"
                            style="text-transform: capitalize;">
                        <label for="hs_city">City<span class="red-text point">*</span></label>
                        <!--  <div *ngIf="(hs_city.dirty || hs_city.touched) && hs_city.invalid">
                           <small *ngIf="hs_city.errors.required" class="red-text">City is required</small> 
                       </div> -->
                    </div>


                    <div class=" col s12 m6">
                        <label for="hs_grade">Grade Achieved<span class="red-text point">*</span></label>
                        <select formControlName="hs_grade" class="validate browser-default">
                            <option disabled value="">Please Select</option>
                            <option *ngFor="let t of grades" [value]="t">{{t}}</option>
                        </select>
                    </div>


                    <div class="col s12 m6">
                        <label for="province">Province<span class="red-text point">*</span></label>
                        <select id="hs_province" formControlName="hs_province" class="validate browser-default">
                            <option disabled value="">Please Select</option>
                            <option *ngFor="let p of provinces" [value]="p">{{p}}</option>
                        </select>
                    </div>

                </div>
            </div>


            <div class="input-field col s12 ">
                <p>Do You Have Post Secondary ? : <span class="red-text point">*</span> </p>
                <div class="row col s4 m4"> </div>
                <div class="row col s8 m8">
                    <label class="col s6">
                        <input formControlName="has_PSE1" name="has_PSE1" type="radio" value="yes"
                            (click)="onPostSecondary1Yes()" />
                        <span>Yes</span>
                    </label>
                    <label class="col s6">
                        <input formControlName="has_PSE1" name="has_PSE1" type="radio" value="no"
                            (click)="onPostSecondary1No()" />
                        <span>No</span>
                    </label>
                </div>
            </div>

            <div class="row card-panel">
                <div class="row" *ngIf='hasPSE1'>

                    <div class="input-field col s12 m6">
                        <input id="pse_program1" type="text" formControlName="pse_program1" class="validate"
                            style="text-transform: capitalize;" style="text-transform: capitalize;" maxlength="50">
                        <label for="pse_program1">Program/Course <span class="red-text point">*</span></label>
                    </div>


                    <div class="input-field col s12 m6">
                        <input id="pse_institution1" type="text" formControlName="pse_institution1"
                            class="capitalize validate" style="text-transform: capitalize;" maxlength="50">
                        <label for="pse_institution1">Institution Name<span class="red-text point">*</span></label>
                    </div>



                    <div class="input-field col s12 m6">
                        <!-- <input id="completed_year1" type="text" formControlName="completed_year1" class="validate"
                            [textMask]="{mask: yearMask, guide: true}"> -->
                        <input id="completed_year1" type="text" [inputMask]="yearMask" formControlName="completed_year1"
                            class="validate">
                        <label for="completed_year1">Year completed<span class="red-text point">*</span></label>
                        <!--  <div *ngIf=" (completed_year1.dirty || completed_year1.touched) && completed_year1.invalid">  
                            <div *ngIf="completed_year1.errors?.min || completed_year1.errors?.max">
                                <small class="red-text"> The Year Must Be Between (2000 And {{todayDate}})</small>
                          </div>                         
                          </div> -->
                    </div>


                    <div class="input-field col s12 m6">
                        <input id="pse_city1" type="text" formControlName="pse_city1" class="validate"
                            style="text-transform: capitalize;" maxlength="50">
                        <label for="pse_city1">City<span class="red-text point">*</span></label>
                    </div>

                    <div class="col s12 m6">
                        <label for="pse_province1">Province <span class="red-text point">*</span></label>
                        <select id="pse_province1" formControlName="pse_province1" class="validate browser-default">
                            <option disabled value="">Please Select</option>
                            <option *ngFor="let p of provinces" [value]="p">{{p}}</option>
                        </select>
                    </div>

                </div>
            </div>



            <div class="input-field col s12 ">
                <p>Do You Have Another post Secondary ? : <span class="red-text point">*</span> </p>
                <div class="row col s4 m4"> </div>
                <div class="row col s8 m8">
                    <label class="col s6">
                        <input formControlName="has_PSE2" name="has_PSE2" type="radio" value="yes"
                            (click)="onPostSecondary2Yes()" />
                        <span>Yes</span>
                    </label>
                    <label class="col s6">
                        <input formControlName="has_PSE2" name="has_PSE2" type="radio" value="no"
                            (click)="onPostSecondary2No()" />
                        <span>No</span>
                    </label>
                </div>
            </div>

            <div class="row card-panel">
                <div class="row" *ngIf='hasPSE2'>

                    <div class="input-field col s12 m6">
                        <input id="pse_program2" type="text" formControlName="pse_program2" class="validate"
                            style="text-transform: capitalize;" style="text-transform: capitalize;" maxlength="50">
                        <label for="pse_program2">Program/Course <span class="red-text point">*</span></label>
                    </div>


                    <div class="input-field col s12 m6">
                        <input id="pse_institution2" type="text" formControlName="pse_institution2"
                            class="capitalize validate" style="text-transform: capitalize;" maxlength="50">
                        <label for="pse_institution2">Institution Name <span class="red-text point">*</span></label>
                    </div>


                    <div class="input-field col s12 m6">
                        <!-- <input id="completed_year2" type="text" formControlName="completed_year2" class="validate"
                            [textMask]="{mask: yearMask, guide: true}"> -->
                        <input id="completed_year2" type="text" [inputMask]="yearMask" formControlName="completed_year2"
                            class="validate">
                        <label for="completed_year2">Year completed <span class="red-text point">*</span></label>
                        <!--  <div *ngIf="(completed_year2.dirty || completed_year2.touched) && completed_year2.invalid">
                            <div *ngIf="completed_year2.errors?.min || completed_year2.errors?.max">
                                <small class="red-text"> The Year Must Be Between (2000 And {{todayDate}})</small>
                          </div>                         
                        </div> -->
                    </div>


                    <div class="input-field col s12 m6">
                        <input id="pse_city2" type="text" formControlName="pse_city2" class="validate"
                            style="text-transform: capitalize;" maxlength="50">
                        <label for="pse_city2">City <span class="red-text point">*</span></label>
                    </div>

                    <div class="col s12 m6">
                        <label for="pse_province2">Province <span class="red-text point">*</span></label>
                        <select id="pse_province2" formControlName="pse_province2" class="validate browser-default">
                            <option disabled value="">Please Select</option>
                            <option *ngFor="let p of provinces" [value]="p">{{p}}</option>
                        </select>
                    </div>


                </div>
            </div>


            <div class="input-field col s12 ">
                <p>Do You Have Trade ? : <span class="red-text point">*</span> </p>
                <div class="row col s4 m4"> </div>
                <div class="row col s8 m8">
                    <label class="col s6">
                        <input formControlName="has_trade" name="has_trade" type="radio" value="yes"
                            (click)="onTradeYes()" />
                        <span>Yes</span>
                    </label>
                    <label class="col s6">
                        <input formControlName="has_trade" name="has_trade" type="radio" value="no"
                            (click)="onTradeNo()" />
                        <span>No</span>
                    </label>
                </div>
            </div>

            <div class="row card-panel">
                <div class="row" *ngIf="hasTrade">

                    <div class="input-field col s12 m6">
                        <input id="trade" type="text" formControlName="trade" class="validate"
                            style="text-transform: capitalize;" style="text-transform: capitalize;" maxlength="50">
                        <label for="trade">Trade Name <span class="red-text point">*</span></label>
                    </div>


                    <div class="input-field col s12 m6">
                        <input id="trade_course" type="text" formControlName="trade_course" class="validate"
                            style="text-transform: capitalize;" maxlength="50">
                        <label for="trade_course">Program/Course <span class="red-text point">*</span></label>
                    </div>


                    <div class="input-field col s12 m6">
                        <input id="trade_institution" type="text" formControlName="trade_institution"
                            class="capitalize validate" style="text-transform: capitalize;" maxlength="50">
                        <label for="trade_institution">Institution Name <span class="red-text point">*</span></label>
                    </div>

                    <div class="input-field col s12 m6">
                        <input id="trade_level" type="text" formControlName="trade_level" class="validate"
                            style="text-transform: capitalize;" maxlength="10">
                        <label for="trade_level">Completed Level <span class="red-text point">*</span></label>
                    </div>


                    <div class="input-field col s12 m6">
                        <!-- <input id="trade_year" type="text" formControlName="trade_year" class="validate"
                            [textMask]="{mask: yearMask, guide: true}"> -->
                        <input id="trade_year" type="text" [inputMask]="yearMask" formControlName="trade_year"
                            class="validate">
                        <label for="trade_year">Completed Year <span class="red-text point">*</span></label>
                        <!--  <div *ngIf="(trade_year.dirty || trade_year.touched) && trade_year.invalid">
                            <div *ngIf="trade_year.errors?.min || trade_year.errors?.max">
                                <small class="red-text"> The Year Must Be Between (2000 And {{todayDate}})</small>
                          </div>                         
                        </div> -->
                    </div>


                    <div class="input-field col s12 m6">
                        <input id="trade_city" type="text" formControlName="trade_city" class="validate"
                            style="text-transform: capitalize;" maxlength="50">
                        <label for="trade_city">City <span class="red-text point">*</span></label>
                    </div>

                    <div class="col s12 m6">
                        <label for="trade_province">Province <span class="red-text point">*</span></label>
                        <select id="trade_province" formControlName="trade_province" class="validate browser-default">
                            <option disabled value="">Please Select</option>
                            <option *ngFor="let p of provinces" [value]="p">{{p}}</option>
                        </select>
                    </div>

                </div>
            </div>


            <div class="row card-panel">
                <div class="input-field col s12 ">
                    <input id="referred_by" type="text" formControlName="referred_by" class="validate"
                        style="text-transform: capitalize;" maxlength="50">
                    <label for="referred_by">How did you hear about Our {{nationName}} ? <span
                            class="red-text point">*</span></label>
                </div>
            </div>



            <div class="row card-panel">
                <div>Comments</div>
                <div class="input-field col s12 ">
                    <textarea id="comments" formControlName="comments" rows="4" cols="50"> </textarea>
                </div>
            </div>


            <div class="row"></div>
            <div class="row">
                <div class="col s6">
                    <button class="btn left waves-effect red" type="button" style="margin-bottom: 15px"
                        (click)="onBack()"><i class="material-icons left">arrow_back</i>Back
                    </button>
                </div>
                <div class="col s6">
                    <button class=" btn right  green" type="submit" style="margin-bottom: 15px" title="Save">Next<i
                            class="material-icons right">arrow_forward</i></button>
                </div>
            </div>


        </form>

    </div>





    <div class="row" *ngIf="step == 4">
        <br />
        <div class="row grey lighten-3 blue-text">
            <h6 class="center-align">Part D: Upload Documents</h6>
        </div>
        <form class="row col s12" [formGroup]="uploadDocumentsForm"
            (ngSubmit)="submitDocuments(uploadDocumentsForm.value)">


            <div class="input-field col s12 ">
                <!-- <p>Do You Have Post Secondary ? : <span class="red-text point">*</span> </p>  -->

                <p style="font-size: 14px; text-align: justify; font-weight: 100;" class="row">
                    In order for the administrator to verify your identity and approve your application
                    quickly, would you like to upload a piece of identification <span class="bold-600">such as Driver's,
                        Fire Arms License, Treaty Card, Passport ... ?</span>
                </p>


                <div class="row col s4 m4"> </div>
                <div class="row col s8 m8">
                    <label class="col s6">
                        <input formControlName="has_docs" name="has_docs" type="radio" value="yes"
                            (click)="uploadIdYes()" />
                        <span>Yes</span>
                    </label>
                    <label class="col s6">
                        <input formControlName="has_docs" name="has_docs" type="radio" value="no"
                            (click)="uploadIdNo()" />
                        <span>No</span>
                    </label>
                </div>

            </div>



            <div class="row card-panel">
                <div class="row">


                    <div *ngIf="showUpload">

                        <div class="col s12 file-field input-field" *ngIf="!isMobile">
                            <div class="btn" [ngStyle]="{'background-color': themeColor, 'color': 'white'}">
                                <span>Browse</span>
                                <input type="file" formControlName="document" accept="image/*"
                                    (change)="handleFileInput($event.target.files)" />
                            </div>

                            <div class="file-path-wrapper">
                                <input class="file-path validate" type="text" placeholder="ID / Driving Licence" />
                            </div>
                        </div>


                        <div class="col s12" *ngIf="isMobile">
                            <button class="btn-small  green  col s6" (click)="mobilePicture();"><i
                                    class="material-icons left">camera_alt </i> Camera</button>
                            <button class="btn-small  blue col s6" (click)="libraryPicture();">
                                <i class="material-icons left">photo_library</i> Library</button>
                        </div>


                        <div class="input-field col s12 ">
                            <input id="description" type="text" formControlName="description" class="validate"
                                style="text-transform: capitalize;" style="text-transform: capitalize;" maxlength="50">
                            <label for="description">Description <span class="red-text point">*</span></label>
                        </div>


                    </div>

                    <!-- <div class="row col center" style="font-size: 14px;  font-weight: 100;">
                        <p>By Clicking <span class="green-text bold-400">Request To Join</span> I hereby :</p>
                        <p>Agree and consent to the <a [routerLink]="['/eula']" target="_blank">User
                            Agreement</a>, its policies and the
                          <a [routerLink]="['/privacy-policy']" target="_blank">Privacy Policy</a>.
                        </p>
                      </div> -->

                    <div class="row section"></div>




                </div>
            </div>


            <div class="row"></div>
            <div class="row">
                <div class="col s6">
                    <button class="btn left waves-effect red" type="button" style="margin-bottom: 15px"
                        (click)="onBack()"><i class="material-icons left">arrow_back</i>Back
                    </button>
                </div>
                <div class="col s6">
                    <button class=" btn right  green" type="submit" style="margin-bottom: 15px" title="Save">Sign Form<i
                            class="material-icons right">arrow_forward</i></button>
                </div>
            </div>

        </form>

    </div>




    <div class="row" *ngIf="step == 5">
        <br />

        <form class="row col s12" [formGroup]="signatureForm" (ngSubmit)="submitSignature(signatureForm.value)">

            <div class="row s12 card-panel grey lighten-4 center">
                <div class="row">
                    <h5 class="blue-text text-darken-2 center"><strong>Sign Registration Form</strong></h5>
                    <br>
                    <canvas></canvas>
                </div>

                <button class="btn right waves-effect blue" type="button" style="margin-bottom: 15px"
                    (click)="clearSignature()"><i class="material-icons left">loop</i>Clear Signature
                </button>

            </div>


            <div class="row"></div>
            <div class="row">

                <button class="btn left waves-effect red" type="button" style="margin-bottom: 15px"
                    (click)="onBack()"><i class="material-icons left">arrow_back</i>Back
                </button>


                <button class=" btn right  green" type="submit" style="margin-bottom: 15px" title="Save">Signed &
                    Submit<i class="material-icons right">arrow_forward</i></button>

            </div>


        </form>

    </div>


</div>




<div id="previewModal" class="modal " style="height: fit-content;">
    <div class="modal-content">
        <h5 class="bold-600 title-section3">Selected Document</h5>
        <div class="row center">
            <img class="responsive-img" [src]="preview" alt="ISET Image">
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="modal-close btn green" style="margin-right: 15px;">
            <i class="material-icons right white-text">close</i> Ok
        </button>
    </div>
</div>


<div id="MessageModal" class="modal">
    <div class="modal-content">
        <h5><strong><span class="blue-text">Submit Registration Form</span></strong></h5>
        <br>
        <p>Are you ready to submit the registration form ?</p>
    </div>
    <div class="modal-footer">
        <button type="button" (click)="ReadytoSubmitYes()" class="btn waves-effect green white-text"
            style="margin-right: 15px;">
            <i class="material-icons right white-text">check</i> Yes
        </button>

        <button type="button" (click)="ReadytoSubmitNo()" class="btn waves-effect red white-text"
            style="margin-right: 15px;">
            <i class="material-icons right white-text">close</i> No
        </button>
    </div>
</div>