import { FireMembersService } from 'src/app/services/firebase/fire-members.service';
import { VotingService } from './../../../../services/firebase/voting.service';
import { ElectionService } from 'src/app/services/firebase/election.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-my-votes',
  templateUrl: './my-votes.component.html',
  styleUrls: ['./my-votes.component.css']
})

export class MyVotesComponent implements OnInit {

  electionId = '';
  electionDetail: any;
  resultMessage = '';
  votes = [];
  candidateData = [];
  winnersObject = [];
  winningVote = 1;
  showAllVote: any;
  role: any;
  userId: any;
  memberWhoVoteForMe = [];
  voteCount = 0;

  constructor(private route: ActivatedRoute,
    private votingService: VotingService,
    private electionService: ElectionService,
    private fireMembersService: FireMembersService,
    private authService: AuthService,
    private location: Location) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.electionId = params.get('id');
    });
    this.userId = this.authService.getGlobalUser().uid;

    if (this.electionId) {
      this.electionService.getElectionById(this.electionId).valueChanges().subscribe(res => {
        if (res) {
          if (res.length !== 0) {
            this.electionDetail = res;
            if (this.electionDetail.candidates.includes(this.userId)) {
              // get vote by election id and candidate id
              this.votingService.getVotes(this.electionId, this.userId).valueChanges().subscribe(votes => {
                if (votes && votes.length > 0) {
                  votes.forEach((vote: any) => {
                    this.voteCount = this.voteCount + 1;
                    if (vote.showVoting && this.electionDetail.showVoteRecieve) {
                      this.fireMembersService.getMemberByID(vote.voterId).valueChanges().subscribe(user => {
                        if (user) {
                          this.memberWhoVoteForMe.push(user);
                        }
                      });
                    }
                  });
                }
              });
            } else {
              this.resultMessage = 'No candidates were chosen';
            }
          }
        }
      });
    }
  }

  backClicked() {
    this.location.back();
  }

}
