<div class="row">

  <div class="row header-auth">
    <div class="col s6">
      <a class="sub-title-section" [routerLink]="['/home']">
        <span class="material-symbols-outlined">
          arrow_back
        </span>
        Back to Homepage
      </a>
    </div>
    <div class="col s6">
      <img class="responsive-img right" [src]=" logo" style=" height: 40px;
      object-fit: cover;" alt="Register Request image">
    </div>
  </div>

  <div class="container-reg">

    <div *ngIf="!(emailSent || requestSent)" class="card signup-card">


      <!--Form-->
      <div class="col s12 m12 l12 form-content" [ngStyle]="{'background-color': authformBgColor}">
        <div class="form-container">

          <div class="row">

            <!-- =======not direct -->
            <h2 *ngIf="!directSignup" class="login-title center bold-600 col s12 m12 l12 title-section2"
              [ngStyle]="{'color': authformSColor}">
              Request To Join</h2>

            <span *ngIf="!directSignup" class="center bold-200 col s12 m12 l12 p-t-1 p-b-1"
              style="font-family:'sans-serif'; font-size: 14px;" [ngStyle]="{'color': themeColor}">Sign up for our
              nation's access now, and afterward, reach out to our nation's team to begin your journey.</span>

            <!-- ======= direct -->

            <h2 *ngIf="directSignup" class="login-title center bold-600 col s12 m12 l12  title-section2"
              [ngStyle]="{'color': authformSColor}">
              Create an Account</h2>

            <span *ngIf="directSignup" class="center bold-200 col s12 m12 l12 p-t-1 p-b-1"
              style="font-family:'sans-serif'; font-size: 14px;" [ngStyle]="{'color': themeColor}">Take advantage of our
              nation's access by signing up today, and embark on your journey right away.</span>


            <div class="container">
              <ol class="progress-container">
                <li class="circles" [ngClass]="{ 'active': step1 }" (click)="goToStep(1)">
                  <span *ngIf="step1 black-text">✔</span>
                  <span *ngIf="!step1">1</span>
                </li>
                <!-- Step 2 -->
                <li class="circles" [ngClass]="{ 'active': step2 }" (click)="goToStep(2)">
                  <span *ngIf="step2">✔</span>
                  <span *ngIf="!step2">2</span>
                </li>
                <!-- Step 3 -->
                <li class="circles" [ngClass]="{ 'active': step3 }" (click)="goToStep(3)">
                  <span *ngIf="step3">✔</span>
                  <span *ngIf="!step3">3</span>
                </li>
              </ol>
            </div>






          </div>
          <div *ngIf="step1" id="section1" class="">
            <form [formGroup]="requestTojoinForm" (ngSubmit)="reviewRequest(requestTojoinForm.value)" class=""
              (keydown.enter)="markStepCompleted(1)">
              <div class="p-t-0" formGroupName="section1">



                <div class="input-field col s12 m12 l4 p-m-0"
                  [ngClass]="{'with-padding': (firstName.dirty || firstName.touched) && firstName.errors?.required}">
                  <div class="label-input">First Name <span class="red-text">*</span></div>
                  <input id="firstName" type="text" formControlName='firstName'
                    class="validate white black-text bold-300" autocomplete="off" style="text-transform: capitalize;"
                    placeholder="First Name" />

                  <div class="error-container">
                    <div *ngIf="(firstName.dirty || firstName.touched) && firstName.invalid" class="error-mes">
                      <small *ngIf="firstName.errors.required" class="red-text"> The First Name is required. </small>
                    </div>
                  </div>
                </div>

                <div class="input-field col s12 m12 l4 p-m-0">
                  <div class="label-input">Middle Name </div>
                  <input id="middleName" type="text" formControlName='middleName' autocomplete="off" class="validate"
                    style="text-transform: capitalize;" placeholder="Middle Name" />
                </div>

                <div class="input-field col s12 m12 l4 p-m-0"
                  [ngClass]="{'with-padding': (lastName.dirty || lastName.touched) && lastName.errors?.required}">
                  <div class="label-input">Last Name<span class="red-text">*</span></div>
                  <input id="lastName" type="text" formControlName='lastName' class="validate white black-text bold-300"
                    autocomplete="off" style="text-transform: capitalize;" placeholder="Last Name" />
                  <div class="error-container">
                    <div *ngIf="(lastName.dirty || lastName.touched) && lastName.invalid" class="error-mes">
                      <small *ngIf="lastName.errors.required" class="red-text"> The Last Name is required. </small>
                    </div>
                  </div>
                </div>


                <div class="input-field col s12 m12 l6 p-m-0" [ngClass]="{'m6 ': !isBoth}">
                  <div class="label-input">Mobile Number</div>
                  <!-- <input id="cellPhone" type="text" formControlName='cellPhone'
                    class="validate white black-text bold-300" autocomplete="off" [textMask]="{mask: phoneNumberMask}"
                    placeholder="Mobile Number" /> -->
                  <input id="cellPhone" type="text" formControlName='cellPhone' [inputMask]="phoneInputMask"
                    class="validate white black-text bold-300" autocomplete="off" placeholder="Mobile Number" />
                </div>


                <div class="input-field col s12 m12 l6 p-m-0" *ngIf="!(isEmployee)"
                  [ngClass]="{'with-padding': (email.dirty || email.touched) && email.errors?.required}">
                  <div class="label-input">Email<span class="red-text">*</span></div>
                  <input id="email" type="email" formControlName='email' class="validate white black-text bold-300 "
                    autocomplete="off" (blur)="emailSet($event.target.value)" placeholder="Email" />
                  <div class="error-container">
                    <div *ngIf="(email.dirty || email.touched) && email.invalid" class="error-mes">
                      <small *ngIf="email.errors.required" class="red-text">Email is required. </small>
                      <small *ngIf="email.errors.email" class="red-text"> Wrong email format. </small>
                      <small *ngIf="email.errors.invalidEmail" class="red-text">Can not use same email address for
                        personal and work purposes.</small>
                    </div>
                  </div>
                </div>



                <div class="input-field col s12 m12 l6 p-m-0" *ngIf="directSignup"
                  [ngClass]="{'with-padding': (password.dirty || password.touched) && password.errors?.required}">
                  <!-- <i class="material-icons prefix">lock</i> -->
                  <div class="label-input">Password<span class="red-text">*</span></div>
                  <input *ngIf="!showPwd" id="password" type="password" formControlName='password'
                    class="validate white black-text bold-300 " placeholder="Password" />
                  <input *ngIf="showPwd" id="password" type="text" formControlName='password'
                    class="validate white black-text bold-300 " placeholder="Password" />

                  <span *ngIf="!showPwd" (click)="toggleVisibility1()" class="material-icons field-icon" style="margin-left:-12%; display:inline;
                    vertical-align: middle; font-size: 2vh; cursor: pointer;"> visibility_off
                  </span>
                  <span *ngIf="showPwd" (click)="toggleVisibility1()" class="material-icons field-icon" style="margin-left:-12%; display:inline;
                    vertical-align: middle; font-size: 2vh; cursor: pointer;">visibility
                  </span>
                  <div class="error-container">
                    <div *ngIf="(password.dirty || password.touched) && password.invalid" class="error-mes">
                      <small *ngIf="password.errors.required" class="red-text">Password is required.</small>
                    </div>
                  </div>
                </div>


                <div class="input-field col s12 m12 l6 p-m-0" *ngIf="directSignup"
                  [ngClass]="{'with-padding': (confirmPassword.dirty || confirmPassword.touched) && confirmPassword.errors?.required}">
                  <div class="label-input">Confirm Password<span class="red-text">*</span></div>
                  <input *ngIf="!showConPwd" id="confirmPassword" type="password" formControlName='confirmPassword'
                    class="validate white black-text bold-300 " placeholder="Confirm Password">
                  <input *ngIf="showConPwd" id="confirmPassword" type="text" formControlName='confirmPassword'
                    class="validate white black-text bold-300 " placeholder="Confirm Password">

                  <span *ngIf="!showConPwd" (click)="toggleVisibility2()" class="material-icons field-icon" style="margin-left: -12%;display:inline;
                  vertical-align: middle; font-size: 2vh; cursor: pointer;">visibility_off
                  </span>
                  <span *ngIf="showConPwd" (click)="toggleVisibility2()" class="material-icons field-icon" style="margin-left: -12%;display:inline;
                  vertical-align: middle; font-size: 2vh; cursor: pointer;">visibility
                  </span>
                  <div class="error-container">
                    <div *ngIf="(confirmPassword.dirty || confirmPassword.touched) && confirmPassword.invalid"
                      class="error-mes">
                      <small *ngIf="confirmPassword.errors.required" class="red-text">Confirm Password is
                        required.</small>
                      <small *ngIf="confirmPassword.hasError('invalid')" class="red-text">Passwords doesn't match
                        !</small>
                    </div>
                  </div>
                </div>

                <div class="input-field col s12 l6 p-m-0" *ngIf="isEmployee || isBoth">
                  <div class="label-input">Department <span class="red-text">*</span></div>
                  <select formControlName="department" (change)="departmentSelected($event.target.value)"
                    class="browser-default">
                    <option value="" disabled selected>Please Select A Department</option>
                    <option *ngFor="let item of departmentsList" [value]="item.id"><span [innerHTML]="item.name"></span>
                    </option>
                  </select>
                </div>

                <div class="input-field col s12 m12 l6 p-m-0" *ngIf="isEmployee || isBoth"
                  [ngClass]="{'with-padding': (workEmail.dirty || workEmail.touched) && workEmail.errors?.required}">
                  <div class="label-input">Work Email<span class="red-text">*</span></div>
                  <input id="workEmail" type="email" formControlName='workEmail'
                    class="validate white black-text bold-300" autocomplete="off"
                    (blur)="emailSet($event.target.value, 'workEmail')" placeholder="Work Email" />
                  <div class="error-container">
                    <div *ngIf="(workEmail.dirty || workEmail.touched) && workEmail.invalid" class="error-mes">
                      <small *ngIf="workEmail.errors.required" class="red-text">Email is required. </small>
                      <small *ngIf="workEmail.errors.email" class="red-text"> Wrong email format. </small>
                      <small *ngIf="workEmail.errors.invalidEmail" class="red-text">Can not use same email address for
                        personal and work purposes.</small>
                    </div>
                  </div>
                </div>
              </div>


              <div class="row section"></div>

              <div class="row p-l-2 ">
                <button type="submit" [disabled]="!requestTojoinForm.controls.section1.valid"
                  class="btn center col l3 m4 s5 right next-button1 btn-next">Next
                  <i class="material-symbols-outlined right black-text">arrow_forward_ios</i>
                </button>

              </div>
            </form>
          </div>

          <div *ngIf="step2" id="section2" class="">
            <form [formGroup]="requestTojoinForm" (keydown.enter)="markStepCompleted(1)">
              <div formGroupName="section2" [ngStyle]="{'color': authformSColor}">
                <p style="font-size: 14px;  text-align: justify; font-weight: 100;" class="row">
                  In order for the administrator to verify your identity and approve your application quickly; Would You
                  like to enter your <span class="bold-600">Date of Birth </span> and
                  <span class="bold-600">Treaty Number</span>. If there is members with the same last name and first
                  name we will have to contact you.
                </p>

                <div class="">
                  <p class="label-input" [ngStyle]="{'color': authformSColor}">Do you want to enter DOB? </p>
                  <div class="row" [ngStyle]="{'color': authformSColor}">
                    <div class="col s6 m6">
                      <label>
                        <input type="radio" name="enterDob" [checked]="enterDob" (click)="enterDobYes()"
                          [ngStyle]="{'color': authformSColor}" />
                        <span [ngStyle]="{'color': authformSColor}"><strong>Yes</strong></span>
                      </label>
                    </div>
                    <div class="col s6 m6">
                      <label>
                        <input type="radio" name="enterDob" [checked]="!enterDob" (click)="enterDobNo()"
                          [ngStyle]="{'color': authformSColor}" />
                        <span [ngStyle]="{'color': authformSColor}"><strong>No</strong></span>
                      </label>
                    </div>
                  </div>


                  <div class="row " style="border-left: 4px solid rgba(0, 0, 0, 0.1) ; margin-left: 1.5vh;">
                    <div class="input-field col s10 push-s1" *ngIf="enterDob">
                      <div class="label-input">Date of Birth<span class="red-text"></span></div>
                      <input id="dob" type="date" formControlName="dob" (blur)="dobEntered($event.target.value)">
                      <div *ngIf="(dob.dirty || dob.touched) && dob.invalid">
                        <small *ngIf="dob.errors.invalidDate" class="red-text">The date you entered is not valid, please
                          try again. </small>
                        <small *ngIf="dob.errors.pattern" class="red-text"> Please Enter A Valid Date Format. </small>
                      </div>
                      The date you entered is not valid, please try again
                    </div>
                  </div>

                </div>

                <div class="">
                  <p class="label-input" [ngStyle]="{'color': authformSColor}">Do you want to enter treaty Number?</p>
                  <div class="row">
                    <div class="col s6 m6">
                      <label>
                        <input type="radio" name="enterIsn" [checked]="enterIsn" (click)="enterIsnYes()" />
                        <span [ngStyle]="{'color': authformSColor}"><strong>Yes</strong></span>
                      </label>
                    </div>

                    <div class="col s6 m6">
                      <label>
                        <input type="radio" name="enterIsn" [checked]="!enterIsn" (click)="enterIsnNo()" />
                        <span [ngStyle]="{'color': authformSColor}"><strong>No</strong></span>
                      </label>
                    </div>
                  </div>

                  <div *ngIf="enterIsn">

                    <p class="col s10 push-s1 sub-title-section3 orange-text lighten-4 bold-100">Please enter the 10
                      digits on your Unique Treaty Number</p>

                    <div class="row " style="border-left: 4px solid rgba(0, 0, 0, 0.1) ; margin-left: 1.5vh;">

                      <div class="input-field col s10 push-s1" *ngIf="isMember || isBoth">
                        <div class="label-input">ISN</div>

                        <span class="col s12">

                          <!-- <input id="isn" type="text" class="validate col s12" formControlName='isn'
                            [textMask]="{mask: isnMask, guide: true}" autocomplete="off" /> -->
                          <input id="isn" type="text" [inputMask]="isnMask" class="validate col s12"
                            formControlName='isn' autocomplete="off" />



                          <div *ngIf="(isn.dirty || isn.touched) && isn.invalid">
                            <small *ngIf="isn.errors.pattern" class="red-text">Invalid ISN Number</small>
                            <small *ngIf="isn.errors.minlength || isn.errors.maxlength" class="red-text"> The ISN must
                              be 10 Digits.</small>
                          </div>
                        </span>
                      </div>
                    </div>

                    <div class="input-field col s12" *ngIf="isEmployee">
                      <i class="material-icons prefix orange-text">apps</i>
                      <!-- <input id="isn" type="text" formControlName="isn" [textMask]="{mask: isnMask, guide: true}"> -->
                      <input id="isn" type="text" [inputMask]="isnMask" formControlName="isn">
                      <label for="isn">ISN<span class="red-text"></span></label>
                      <div *ngIf="(isn.dirty || isn.touched) && isn.invalid">
                        <small *ngIf="isn.errors.pattern" class="red-text">Invalid ISN Number</small>
                        <small *ngIf="isn.errors.minlength || isn.errors.maxlength" class="red-text"> The ISN must be
                          7
                          Digits.</small>
                      </div>
                    </div>

                  </div>



                </div>
              </div>


              <div class="row section"></div>

              <div class="row">
                <button *ngIf="enterIsn || enterDob" type="button"
                  [disabled]="(!isn.valid || !dob.valid) || (!enterIsn && !enterDob)" style="margin: 0px 0px;"
                  (click)="saveValues(requestTojoinForm.value)" class="btn center col l3 m5 s5 right next-button next"
                  [ngStyle]="{'background-color': themeColor}"> <i
                    class="material-symbols-outlined right white-text">arrow_forward_ios</i>Next</button>

                <button *ngIf="!(enterIsn || enterDob)" type="button" (click)="skip(requestTojoinForm.value)"
                  class="btn center col l3 m4 s5 right next-button1"><i class="material-icons right">
                    skip_next </i>Skip</button>

                <button type="submit" class="btn center col l3 m3 s4 left next-button1" (click)="back()">Back
                  <i class="material-symbols-outlined left black-text">arrow_back_ios</i>
                </button>
              </div>
            </form>
          </div>

          <div *ngIf="step3" id="section3" class="center-align ">
            <div class="" (keydown.enter)="markStepCompleted(1)">

              <div class="row col s12" [ngStyle]="{'color': authformSColor}">
                <p style="font-size: 14px; text-align: justify; font-weight: 100;" class="row">
                  In order for the administrator to verify your identity and approve your application
                  quickly,would you like to upload a piece of identification <span class="bold-600">such as Driver's,
                    Fire Arms License, Treaty Card, Passport ...</span>
                </p>

                <div class="row">

                  <div class="col s6 m6">
                    <label>
                      <input type="radio" value="Yes" name="group1" (click)="uploadIdYes()" />
                      <span [ngStyle]="{'color': authformSColor}"><strong>Yes</strong></span>
                    </label>
                  </div>

                  <div class="col s6 m6">
                    <label>
                      <input type="radio" value="No" name="group1" checked (click)="uploadIdNo()" />
                      <span [ngStyle]="{'color': authformSColor}"><strong>No</strong></span>
                    </label>
                  </div>
                </div>
              </div>

              <div *ngIf="showUpload">
                <div class="col s12 file-field input-field" *ngIf="!isMobile">
                  <div class="btn" [ngStyle]="{'background-color': themeColor, 'color': 'white'}">
                    <span>Browse</span>
                    <input type="file" name="document" accept="image/*"
                      (change)="handleFileInput($event.target.files)" />
                  </div>
                  <div class="file-path-wrapper">
                    <input class="file-path validate" type="text" placeholder="ID / Driving Licence" id="uploadFile" />
                  </div>
                </div>

                <div class="col s12" *ngIf="isMobile">
                  <button class="btn-small  green  col s6" (click)="mobilePicture();"><i
                      class="material-icons left">camera_alt </i> Camera</button>
                  <button class="btn-small  blue col s6" (click)="libraryPicture();">
                    <i class="material-icons left">photo_library</i> Library</button>
                </div>

                <div *ngIf="binDoc">
                  <p class="left green-text">Document Uploaded<i class="material-icons left">check</i><a
                      (click)="viewDoc()" style="margin-left: 10px; text-decoration: underline; cursor: pointer;"
                      [ngStyle]="{'color': themeColor}">View</a></p>
                  <p><a class="btn btn-flat btn-small right red-text" (click)="deleteDoc()">Delete<i
                        class="material-icons left">delete</i></a></p>
                </div>
              </div>

              <!-- <div class="row col center" style="font-size: 14px;  font-weight: 100;">
                <p>By Clicking <span class="green-text bold-400">Request To Join</span> I hereby :</p>
                <p>Agree and consent to the <a [routerLink]="['/eula']" target="_blank">User
                    Agreement</a>,
                  its policies and the
                  <a [routerLink]="['/privacy-policy']" target="_blank">Privacy Policy</a>.
                </p>
              </div> -->

              <div class="row section"></div>

              <div class="row">
                <button type="button" (click)="submit(requestTojoinForm.value);"
                  class="btn center col l5 m4 s5 next-button1 right white-text"
                  [ngStyle]="{'background-color': themeColor}">
                  Request To Join
                </button>
                <button type="submit" class="btn center col l3 m3 s4 left next-button1" (click)="back()">Back
                  <i class="material-symbols-outlined left black-text">arrow_back_ios</i>
                </button>
              </div>
            </div>
          </div>


          <!-- ==== privacy policy link ====== -->
          <div class="row center sub-title-section2 bold-300" [ngStyle]="{'color': authformSColor}">
            <p>By proceeding, you agree to the <a [routerLink]="['/eula']" [ngStyle]="{'color': themeColor}">User
                Agreement</a> , <a [ngStyle]="{'color': themeColor}">Terms and Conditions</a> and <a
                [routerLink]="['/privacy-policy']" [ngStyle]="{'color': themeColor}">Privacy Policy</a> </p>

            <!-- ==== already have accountn  ====== -->
            <p>Already have an account? <a [ngStyle]="{'color': themeColor}" style="text-decoration: underline;"
                [routerLink]="['/auth/login']">Login</a>
            </p>
          </div>

        </div>
      </div>
    </div>

    <!--Result-->
    <div class="row col s12 m10 l8 offset-m1 offset-l2 no-padding cont-signup" *ngIf="requestSent">
      <div class="section"></div>
      <div class="card white">
        <div class="card-content">
          <div class="row center">

            <img src="../../../../assets/img/ty.jpg" class="responsive-img" width="300px" height="300px">


            <p class="title ">
              Thank you <span style="text-transform: capitalize;" [ngStyle]="{'color':themeColor}">{{userName}}
              </span> for registering
              with<span [ngStyle]="{'color':themeColor}"> {{nationName}} </span>

            </p>
            <br />
            <p class="result-text">
              The Admnistrator will review the application and send you a registration link.
            </p>
            <p class="result-text">
              Please follow the link when you receive it .
            </p>
            <br />
            <hr />
            <p>If you have any other concers or question.
            </p>
            <p>Our Business Hours Are : <br> Monday - Friday 9 AM - 5 PM</p>
            <p> <strong> Have a wonderful day </strong></p>

            <div class="section"></div>
            <div class="row center">
              <button type="button" (click)="backClicked()"
                class="btn center col l6 m8 s10 push-s1 push-m2 push-l3 next-button b-n-5"
                [ngStyle]="{'background-color': themeColor}">Go Homepage</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row col s12 m10 l8 offset-m1 offset-l2 no-padding cont-signup" *ngIf="signupDone">
      <div class="section"></div>
      <div class="card white">
        <div class="card-content">
          <div class="row center">
            <!-- <i class="material-icons green-text checkmark">
              check_circle
            </i> -->
            <img src="../../../../assets/img/ty.jpg" class="responsive-img" width="300px" height="300px">

            <!-- <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
              <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
              <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
            </svg> -->
            <p class="title ">
              Thank you <span style="text-transform: capitalize;" [ngStyle]="{'color':themeColor}">{{userName}}
              </span> for Signing Up
              with<span [ngStyle]="{'color':themeColor}"> {{nationName}} </span>

            </p>
            <br />
            <p class="result-text">
              We're excited to have you on board.
            </p>

            <hr />
            <p>If you have any other concers or question.
            </p>
            <p>Our Business Hours Are : <br> Monday - Friday 9 AM - 5 PM</p>
            <p> <strong> Have a wonderful day </strong></p>

            <div class="section"></div>
            <div class="row center">
              <button type="button" (click)="login()"
                class="btn center col l6 m8 s10 push-s1 push-m2 push-l3 next-button b-n-5"
                [ngStyle]="{'background-color': themeColor}">Login</button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row col s12 m10 l8 offset-m1 offset-l2 no-padding" *ngIf="emailSent">
      <div class="section"></div>
      <div class="card white">
        <div class="card-content">
          <div class="row center">
            <!-- <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
            <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
            <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
          </svg> -->
            <i class="material-icons green-text checkmark">
              check_circle
            </i>
            <p class="title"><strong>
                Thank you for Contacting Us.
              </strong></p>
            <br />
            <p class="result-text"><strong>
                The Admnistrator will review your email and will reach out with the response.
              </strong></p>
            <hr />
            <p>If you have any other concers or question.</p>
            <p>Our Business Hours Are : <br> Monday - Friday 9 AM - 5 PM</p>
            <p><strong>Have a wonderful day</strong></p>

            <div class="section"></div>
            <div class="row center">
              <button type="button" (click)="backClicked()"
                class="btn center col l6 m8 s10 push-s1 push-m2 push-l3 next-button"
                [ngStyle]="{'background-color': themeColor}"><i
                  class="material-icons left next-button">arrow_back</i>Back to Previous Page</button>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
  <!----------------------------------------------------------------- Modals---------------------------------------------------------------------------------------------------->
  <div class="welcomeModal" *ngIf="welcomeSetting && welcomeSetup" [ngClass]="{'with-img': welcomeSetting.image}">

    <div id="welcomeModal" class="modal b-n-1 modal-fixed-footer">
      <div class="modal-content">

        <div class="col s12 m12 l12 center">
          <img *ngIf="welcomeSetting.image" class="responsive-img center-align" height="150px" width="150px"
            [src]="welcomeSetting.image">
        </div>

        <div class="col l12 m12 s12 welcome-content">

          <h6 class="center-align bold-600 title-section2" [innerHTML]="welcomeSetting.title"></h6>
          <p [innerHTML]="welcomeSetting.description"></p>
        </div>
      </div>

      <div class="modal-footer white">
        <a (click)='closeModal()' class="modal-close white-text btn white-text"
          [ngStyle]="{'background-color': themeColor}"> <i class="material-icons right">check</i>OK</a>
      </div>
    </div>
  </div>

  <div id="emailRegistredModal" class="modal">
    <div class="modal-content">
      <div class="card">
        <div class="card-content">
          <div class="row center">
            <i style="font-size: 80px;" class="material-icons red-text">
              warning
            </i>
            <h6 *ngIf="emailExists">
              <strong> Your Email: <span class="red-text">{{requestTojoinForm.value.section1.email }} </span>is
                Already Registered.
              </strong>
            </h6>

            <h6 *ngIf="workEmailExists">
              <strong>Your Work Email: <span class="red-text">{{requestTojoinForm.value.section1.workEmail }}
                </span>is Already
                Registered.
              </strong>
            </h6>

            <p><strong>Please Contact the Administrator.
              </strong></p>
          </div>
          <br />

          <div class="row center">
            <button class="btn center col l6 m8 s10 push-s1 push-m2 push-l3 next-button"
              [ngStyle]="{'background-color': themeColor}" (click)="contactAdmin()">Contact Administrator<i
                class="material-icons right white-text">mail</i></button>
          </div>
        </div>
      </div>

      <div class="modal-footer">
        <a (click)='closeModal()' class="modal-close amber darken-4 white-text btn-flat white-text"> <i
            class="material-icons right">close</i>close</a>
      </div>


      <!-- <div class="section"></div>
    <div class="row center">
      <button type="button modal-close" style="margin-bottom: 15px;" (click)="goTo(1,'form')" class="col s12 btn">
        <i class="material-icons left">arrow_back</i> Back to Previous Page
      </button>
    </div> -->
    </div>
  </div>

  <div id="requestModal" class="modal" style="height: fit-content;">
    <div class="modal-content center">
      <i class="material-symbols-outlined modal-close right">close</i>
      <h5 class="center-align bold-600 title-section2 ">Request Already Exists</h5>
      <br>

      <div *ngIf="memberRequestFound">
        <div *ngIf="!memberRequestFound[0].status">
          <h6 class="bold-400 sub-title-section">
            Your Member Application Is Pending For Approval.
          </h6>
          <p class="bold-100 sub-title-section2">
            The Admnistrator will review the application and get back to you shorlty.
          </p>
        </div>

        <div *ngIf="memberRequestFound[0].status == 1">
          <h6 class="bold-400 sub-title-section">
            Your Member Application Is Pending For Approval.
          </h6>
          <p class="bold-100 sub-title-section2">
            The Admnistrator will review the application and get back to you shorlty.
          </p>
        </div>

        <div *ngIf="memberRequestFound[0].status == 2">
          <h6 class="bold-400 sub-title-section">Your Member Application has been Approved.</h6>
          <p class="bold-100 sub-title-section2">A registration link was sent to your email address. Please follow
            the link to complete your registration
            process.</p>
        </div>

        <div *ngIf="memberRequestFound[0].status == 3">
          <h6 class="bold-400 sub-title-section">Your Member Account has been Activated.</h6>
          <p class="bold-100 sub-title-section2">Please Proceed to login to access you account.</p>
          <a class="waves-effect waves-green btn" [routerLink]="['/auth/login']">Login</a>
        </div>

      </div>

      <div *ngIf="EmployeeRequestFound">
        <div *ngIf="!EmployeeRequestFound[0].status">
          <h6 class="bold-400 sub-title-section">
            Your Application to register as an Employee Is Pending For Approval.
          </h6>
          <p class="bold-100 sub-title-section2">The Admnistrator will review the application and get back to you
            shorlty.</p>
        </div>

        <div *ngIf="EmployeeRequestFound[0].status == 1">
          <h6 class="bold-400 sub-title-section">
            Your Employee Application Is Pending For Approval.
          </h6>
          <p class="bold-100 sub-title-section2">The Admnistrator will review the application and get back to you
            shorlty.</p>
        </div>

        <div *ngIf="EmployeeRequestFound[0].status == 2">
          <h6 class="bold-400 sub-title-section">
            Your Employee Application has been Approved.
          </h6>
          <p class="bold-100 sub-title-section2">A registration link was sent to your work email address. Please
            follow the link to complete your
            registration
            process.</p>
          <br>
        </div>

        <div *ngIf="EmployeeRequestFound[0].status == 3">
          <h6 class="bold-400 sub-title-section">
            Your Employee Account has been Activated.
          </h6>
          <p class="bold-100 sub-title-section2">Please Proceed to login to access you account.</p>
          <a class="waves-effect waves-green btn" [routerLink]="['/auth/login']">Login</a>
        </div>
      </div>

      <br />
      <i class="large material-icons green-text">
        emoji_emotions
      </i>
      <p class="bold-100 sub-title-section2">Thank you for you patience.</p>
      <p class="bold-100 sub-title-section2">
        Have a wonderful day!!
      </p>



    </div>
    <!-- <div class="modal-footer">
        <a (click)='closeModal()' class="modal-close amber darken-4 white-text btn-flat white-text"> <i
            class="material-icons right">close</i>close</a>
      </div> -->

  </div>

  <div id="previewModal" class="modal previewModal">
    <div class="modal-content">
      <h4 class="bold-600 title-section3">Preview Document</h4>
      <p class="sub-title-section2 bold-400" [ngStyle]="{'color': themeColor}">Get a quick look at the contents of
        your uploaded document!</p>
      <div class="row center">
        <img class="responsive-img" [src]="preview" height="auto" width="300px" alt="Document image">
      </div>
    </div>
    <div class="modal-footer">
      <!-- <a (click)='closeModal()' class="modal-close white-text btn white-text"
            [ngStyle]="{'background-color': themeColor}"> <i class="material-icons right">check</i>OK</a> -->

      <button type="button" class="modal-close btn" style="margin-right: 15px;"
        [ngStyle]="{'background-color': themeColor}">
        <i class="material-icons right white-text">check</i> Ok
      </button>
    </div>
  </div>

  <div id="emailModal" class="modal modal_max" *ngIf="contact">
    <form [formGroup]="contactForm" (ngSubmit)="sendEmail(contactForm.value)">
      <div class="row modal-content">
        <h6 class="center title"><strong>Contact Adminstrator</strong></h6>

        <div class="input-field col s12 l6">
          <i class="material-icons prefix" [ngStyle]="{'color': themeColor}">person</i>
          <input id="firstName" type="text" formControlName='firstName' required class="validate" autocomplete="off" />
          <label for="firstName">First Name<span class="red-text">*</span></label>
          <div *ngIf="(contactfirstName.dirty || contactfirstName.touched) && contactfirstName.invalid">
            <small *ngIf="contactfirstName.errors.required" class="red-text"> The First Name is required. </small>
          </div>
        </div>

        <div class="input-field col s12 l6">
          <i class="material-icons prefix" [ngStyle]="{'color': themeColor}">person</i>
          <input id="lastName" type="text" formControlName='lastName' required class="validate" autocomplete="off" />
          <label for="lastName">Last Name<span class="red-text">*</span></label>
          <div *ngIf="(contactlastName.dirty || contactlastName.touched) && contactlastName.invalid">
            <small *ngIf="contactlastName.errors.required" class="red-text"> The Last Name is required. </small>
          </div>
        </div>

        <div class="input-field col s12 l12">
          <i class="material-icons prefix" [ngStyle]="{'color': themeColor}">alternate_email</i>
          <input id="email" type="email" formControlName='email' required class="validate" autocomplete="off" />
          <label for="email">My Email<span class="red-text">*</span></label>
          <div *ngIf="(contactemail.dirty || contactemail.touched) && contactemail.invalid">
            <small *ngIf="contactemail.errors.required" class="red-text"> Your Email is required. </small>
            <small *ngIf="contactemail.errors.email" class="red-text"> wrong email format. </small>
          </div>
        </div>

        <div class="input-field col s12 l12">
          <i class="material-icons prefix" [ngStyle]="{'color': themeColor}">mail</i>
          <input id="subject" type="text" formControlName='subject' required class="validate" autocomplete="off" />
          <label for="subject">Subject<span class="red-text">*</span></label>
          <div *ngIf="(subject.dirty || subject.touched) && subject.invalid">
            <small *ngIf="subject.errors.required" class="red-text"> The Subject is required. </small>
          </div>
        </div>

        <div class="input-field col s12 l12">
          <i class="material-icons prefix" [ngStyle]="{'color': themeColor}">subject</i>
          <textarea id="body" type="text" formControlName='body' required autocomplete="off"
            class="validate materialize-textarea" style="height: 100px;" maxlength="1000"> </textarea>
          <label for="body">Body<span class="red-text">*</span></label>
          <div *ngIf="(body.dirty || body.touched) && body.invalid">
            <small *ngIf="body.errors.required" class="red-text"> The Body Is Required.
            </small>
          </div>
        </div>

        <div class="row">
          <button type="submit" [disabled]="!contactForm.valid"
            class="btn center col l6 m8 s10 push-s1 push-m2 push-l3 next-button"
            [ngStyle]="{'background-color': themeColor}">
            <i class="material-icons right white-text">send</i> Send
          </button>
        </div>

        <div class="modal-footer">
          <a (click)='closeModal()' class="modal-close amber darken-4 white-text btn-flat white-text"> <i
              class="material-icons right">close</i>close</a>
        </div>

        <!-- <button type="submit" [disabled]="!contactForm.valid" style="margin-left: 5px;" class="btn green right">
      <i class="material-icons right">send</i>
      Send</button> -->

        <!-- <a class="modal-close btn red right"> <i class="material-icons right">close</i> Close</a> -->
      </div>
    </form>
  </div>

</div>