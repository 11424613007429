import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { IncomeDeclarationModel } from 'src/app/models/incomeDeclarationModel';
import { environment } from 'src/environments/environment.prod';
import { Observable } from 'rxjs';
import { ApiKeyManagerService } from './apiKeyManager.Service';

@Injectable({
  providedIn: 'root'
})
export class IncomeDeclarationService {

  constructor(private httpClient: HttpClient, private keyManager: ApiKeyManagerService) { }

  saveIncomDeclaration(model: IncomeDeclarationModel[]): any {
    const url = environment.firstNation.apiUrl + 'api/IncomeDeclaration/PostDeclaration';
    const headers = new HttpHeaders({SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8'});
    return this.httpClient.post<any>(url, JSON.stringify(model), {headers});
}

getPreviousDeclaration(id: number): Observable<IncomeDeclarationModel[]> {
  const url = environment.firstNation.apiUrl + 'api/IncomeDeclaration/GetIcomeDeclarationByDOI_ID/' + id;
  const headers = new HttpHeaders({SOCIALAPIKEY: this.keyManager.getKey()});
  return this.httpClient.get<IncomeDeclarationModel[]>(url, {headers});
}

}
