import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/firebase/auth.service';
import {Location} from '@angular/common';
import { ServicePlanService } from 'src/app/services/api/isets/servicePlan.service';

@Component({
  selector: 'app-service-plan',
  templateUrl: './service-plan.component.html',
  styleUrls: ['./service-plan.component.css']
})
export class ServicePlanComponent implements OnInit {

  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;

  sp_items: any[];    
  dtOptions: any = {};
  clientID: any;   
  hasRows = false;

  constructor(private authService: AuthService, private location: Location, private servicePlanService: ServicePlanService ) { }

  ngOnInit(): void {
    this.dtOptions = {};
    this.dtOptions = {
     pagingType: 'simple_numbers',
     pageLength: 15,
     dom: 'Bfrtip',
     // Configure the buttons
     buttons: [
       'copy',
       'print',
       'csv',
       'excel'
     ]
    };

    if (localStorage.getItem('applicantID')) {
      this.clientID = Number(localStorage.getItem('applicantID'));
    } else {
      this.clientID = this.authService.getCurrentApplicantID();
    }

    if (this.clientID) {
      const id = + this.clientID;
      this.servicePlanService.getServicePlanItems(id).subscribe(data => {
        if (data) {
          this.sp_items = [];   
          this.sp_items = data;
          if (this.sp_items.length > 0) {this.hasRows = true; }
        
          this.dtOptions = {};
          this.dtOptions = {
            pagingType: 'simple_numbers',
            pageLength: 25,
            dom: 'Bfrtip',
            responsive: true,
            buttons: [
             'copy',
             'print',
             'csv',
             'excel'
            ]
          };
        }
      }); 
    }
  }
  
  backClicked() {
    this.location.back();
  }
}