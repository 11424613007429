import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { GovernanceService } from 'src/app/services/firebase/governance.service';
import { toast } from 'materialize-css';
import { DepartmentService } from 'src/app/services/firebase/department.service';
import { CommunityService } from 'src/app/services/api/community.service';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { Location } from '@angular/common';
import { createMask } from '@ngneat/input-mask';

@Component({
  selector: 'app-manage-councillor-account',
  templateUrl: './manage-councillor-account.component.html',
  styleUrls: ['./manage-councillor-account.component.css']
})
export class ManageCouncillorAccountComponent implements OnInit {

  accountType: any;
  applicantID: any;
  governanceID: any;
  editGovernance: any;
  nationOfUse: any;
  communities: any;
  user: any;
  governance: any;
  selectedCouncillor: any = {};
  portfolioItems: any = [];
  selectedChief: any;
  councillorIndex: any;
  editCouncillorForm: UntypedFormGroup;
  departmentsList: any;
  departmentsOptions: any;
  mobilePattern = /^\s*([\(])[[0-9]{2}\d{1}[\)]\s*[\-]?[\.]?\s*\d{3}\s*[\-]?[\.]?\s*\d{4}$/;
  // public phoneNumberMask = ['(', /[0-9]/, /[0-9]/, /[0-9]/, ')', ' ', /[0-9]/, /[0-9]/,
  //   /[0-9]/, ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  phoneInputMask = createMask<string>({
    mask: '(999) 999-9999',
    guide: true,
    showMaskTyped: true,
  });

  public urlPattern = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
  isCouncillor = false;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private governanceService: GovernanceService,
    private departmentService: DepartmentService,
    private communityService: CommunityService,
    private authService: AuthService,
    private location: Location,
  ) {
    this.editCouncillorForm = this.formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      displayName: [''],
      chiefBio: [''],
      councillorFacebookAcc: ['', [Validators.pattern(this.urlPattern)]],
      councillorInstagramAcc: ['', [Validators.pattern(this.urlPattern)]],
      councillorTwitterAcc: ['', [Validators.pattern(this.urlPattern)]],
      councillorLinkedinAcc: ['', [Validators.pattern(this.urlPattern)]],
      councillorYoutubeAcc: ['', [Validators.pattern(this.urlPattern)]],
      appointmentDate: [''],
      expiryDate: [''],
      email: ['', [Validators.required, Validators.email]],
      cell_phone: [''],
      office_phone: [''],
      community: [''],
      imgLink: [''],
      showFacebookAcc: [''],
      showTwitterAcc: [''],
      showInstagramAcc: [''],
      showLinkedinAcc: [''],
      showYoutubeAcc: [''],
      showMobile: [''],
      showOfficePhone: [''],
      showEmail: [''],
      allowContact: ['']
    });

    this.user = this.authService.getGlobalUser();
    if (this.user.isCouncillor) {
      this.isCouncillor = true;
    }
  }

  get getFirstName() { return this.editCouncillorForm.get('firstName'); }
  get getLastName() { return this.editCouncillorForm.get('lastName'); }
  get getAppointmentDate() { return this.editCouncillorForm.get('appointmentDate'); }
  get getExpiryDate() { return this.editCouncillorForm.get('expiryDate'); }
  get getChiefBio() { return this.editCouncillorForm.get('chiefBio'); }
  get getEmail() { return this.editCouncillorForm.get('email'); }
  get getCell_phone() { return this.editCouncillorForm.get('cell_phone'); }
  get getcommunity() { return this.editCouncillorForm.get('community'); }
  get getOffice_phone() { return this.editCouncillorForm.get('office_phone'); }
  get getCouncillorFacebookAcc() { return this.editCouncillorForm.get('councillorFacebookAcc'); }
  get getCouncillorInstagramAcc() { return this.editCouncillorForm.get('councillorInstagramAcc'); }
  get getCouncillorTwitterAcc() { return this.editCouncillorForm.get('councillorTwitterAcc'); }
  get getCouncillorLinkedinAcc() { return this.editCouncillorForm.get('councillorLinkedinAcc'); }
  get getCouncillorYoutubeAcc() { return this.editCouncillorForm.get('councillorYoutubeAcc'); }

  ngOnInit() {
    this.nationOfUse = environment.firstNation.name;
    this.governanceService.getGovernanceList().valueChanges().subscribe(governance => {
      this.applicantID = this.user.applicantID;
      if (governance.length > 0) {

        this.governance = governance[0];
        if (this.user.isChief) {
          this.selectedChief = this.governance.chief[0];

        } else {
          const councillor = this.governance.councillors.filter((item) => {
            return item.applicantID === this.applicantID;
          });
          this.selectedCouncillor = councillor[0];

          this.departmentService.getDepartmentList().valueChanges().subscribe(x => {
            if (x) {
              this.departmentsList = x;
              this.departmentsOptions = [];
              this.departmentsList.forEach(dep => {
                dep.isSelected = false;
                if (this.selectedCouncillor.portfolio) {
                  this.selectedCouncillor.portfolio.forEach(element => {
                    if (element.id === dep.id) {
                      dep.isSelected = true;
                    }
                  });
                }
                const option = { id: dep.id, name: dep.name, isSelected: dep.isSelected };
                this.departmentsOptions.push(option);
              });
            }
          });

          this.councillorIndex = this.governance.councillors.indexOf(councillor[0]);

          if (this.nationOfUse === 'Batc' || this.nationOfUse === 'Pbcn') {
            this.communityService.getNationCommunities().subscribe(com => {
              if (com) {
                this.communities = com;
              }
            });
          } else {
            this.communityService.getCommunity().subscribe(com => {
              if (com) {
                this.communities = com;
              }
            });
          }

          this.editCouncillorForm = this.formBuilder.group({
            firstName: [{ value: this.selectedCouncillor.firstName, disabled: true }, [Validators.required]],
            lastName: [{ value: this.selectedCouncillor.lastName, disabled: true }, [Validators.required]],
            displayName: [this.selectedCouncillor.displayName],
            chiefBio: [''],
            councillorFacebookAcc: [this.selectedCouncillor.councillorFacebookAcc, [Validators.pattern(this.urlPattern)]],
            councillorInstagramAcc: [this.selectedCouncillor.councillorInstagramAcc, [Validators.pattern(this.urlPattern)]],
            councillorTwitterAcc: [this.selectedCouncillor.councillorTwitterAcc, [Validators.pattern(this.urlPattern)]],
            councillorLinkedinAcc: [this.selectedCouncillor.councillorLinkedinAcc, [Validators.pattern(this.urlPattern)]],
            councillorYoutubeAcc: [this.selectedCouncillor.councillorYoutubeAcc, [Validators.pattern(this.urlPattern)]],
            appointmentDate: [{ value: this.selectedCouncillor.appointmentDate, disabled: true }],
            expiryDate: [{ value: this.selectedCouncillor.expiryDate, disabled: true }],
            email: [this.selectedCouncillor.email, [Validators.required, Validators.email]],
            cell_phone: [this.selectedCouncillor.cell_phone],
            office_phone: [this.selectedCouncillor.office_phone],
            community: [this.selectedCouncillor.community],
            imgLink: [this.selectedCouncillor.imgLink],
            showFacebookAcc: [this.selectedCouncillor.showFacebookAcc],
            showTwitterAcc: [this.selectedCouncillor.showTwitterAcc],
            showInstagramAcc: [this.selectedCouncillor.showInstagramAcc],
            showLinkedinAcc: [this.selectedCouncillor.showLinkedinAcc],
            showYoutubeAcc: [this.selectedCouncillor.showYoutubeAcc],
            showMobile: [this.selectedCouncillor.showMobile],
            showOfficePhone: [this.selectedCouncillor.showOfficePhone],
            showEmail: [this.selectedCouncillor.showEmail],
            allowContact: [this.selectedCouncillor.allowContact]
          });
        }
      }
    });
  }

  updateCouncillorData() {
    if (this.editCouncillorForm.valid) {
      const formData = this.editCouncillorForm.value;
      const portfolioItems = [];
      this.departmentsOptions.forEach(element => {
        if (element.isSelected) {
          portfolioItems.push({ id: element.id, name: element.name });
        }
      });
      const postObj: any = {
        applicantID: this.applicantID,
        firstName: this.selectedCouncillor.firstName,
        lastName: this.selectedCouncillor.lastName,
        displayName: formData.displayName,
        appointmentDate: this.selectedCouncillor.appointmentDate,
        expiryDate: this.selectedCouncillor.expiryDate,
        chiefBio: formData.chiefBio,
        councillorFacebookAcc: formData.councillorFacebookAcc,
        councillorInstagramAcc: formData.councillorInstagramAcc,
        councillorTwitterAcc: formData.councillorTwitterAcc,
        councillorLinkedinAcc: formData.councillorLinkedinAcc,
        councillorYoutubeAcc: formData.councillorYoutubeAcc,
        email: formData.email,
        cell_phone: formData.cell_phone,
        office_phone: formData.office_phone,
        community: formData.community,
        archive: 0,
        portfolio: portfolioItems,
        showEmail: formData.showEmail,
        showMobile: formData.showMobile,
        showOfficePhone: formData.showOfficePhone,
        allowContact: formData.allowContact,
        showFacebookAcc: formData.showFacebookAcc,
        showTwitterAcc: formData.showTwitterAcc,
        showInstagramAcc: formData.showInstagramAcc,
        showLinkedinAcc: formData.showLinkedinAcc,
        showYoutubeAcc: formData.showYoutubeAcc
      };

      let councillorArr = [];
      councillorArr = this.governance.councillors;
      councillorArr[this.councillorIndex] = postObj;
      this.governance.councillors = councillorArr;
      this.governanceService.UpdateGovernanceCouncillor(this.governance).then(x => {
        toast({ html: 'Councillor account Successfully Updated!', classes: 'green' });
        this.backClicked();
      });
    }
  }

  backClicked() {
    this.location.back();
  }

}
