<div class="section p-t-4 hide-on-small-only"></div>
<div class=" b-n-1 p-t-4">
  <h6 class="center title-section2 black-text bold-900  p-b-1">Select your Account Type</h6>
  <h6 class="center bold-100" *ngIf="user"> Hello, {{user.LastName}} select User Account type :</h6>
  <div class="row">
    <div class="col m1 l2"></div>
    <div class="col s12 m10 l8">
      <div class="row container9">
        <a (click)="member()" class="acc-card" style="cursor: pointer;">
          <div class="col s12 l4">
            <div class="card-panel white b-n-1 p-t-3 center ">
              <div class=" col s12">
                <img src="https://img.icons8.com/ios-filled/73/000000/group-background-selected.png" />
                <div class="col s12 p-t-2">
                  <strong class="gray-text sub-title-section bold-500">Member Account</strong>
                </div>
              </div>
            </div>
          </div>
        </a>

        <a (click)="chief()" class="acc-card" *ngIf="user" style="cursor: pointer;">
          <div class="col s12 l4">
            <div class="card-panel white b-n-1 p-t-4 center">
              <div class="col s12">
                <img src="https://img.icons8.com/ios/73/000000/admin-settings-male.png" />
                <div class=" col s12 p-t-2 ">
                  <strong class="gray-text sub-title-section bold-500"
                    *ngIf="user.isMember || user.isCouncillor || user.isChief">Admin
                    Account</strong>
                </div>
              </div>
            </div>
          </div>
        </a>

        <a (click)="logout()" class="acc-card" style="cursor: pointer;">
          <div class="col s12 l4">
            <div class="card-panel white b-n-1 p-t-4 center">
              <div class=" col s12">
                <img
                  src="https://img.icons8.com/external-tanah-basah-detailed-outline-tanah-basah/75/000000/external-sign-out-user-interface-tanah-basah-detailed-outline-tanah-basah.png" />
                <div class=" col s12 center p-t-2">
                  <strong class="gray-text sub-title-section bold-500">Log Out</strong>
                </div>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>
    <div class="col m1 l2"></div>
  </div>
</div>