import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { DepartmentModel } from 'src/app/models/DepartmentModel';

@Injectable({
  providedIn: 'root'
})

export class DepartmentService {


  model_parent_id:any;

  constructor(private db: AngularFirestore) { }

  contentListRef: AngularFirestoreCollection<any>;
  contentRef: AngularFirestoreDocument<any>;

createID() {
  return this.db.createId();
}

// --------------------- Manage Posts ------------------------------
  addDepartment(model: DepartmentModel) {
    this.model_parent_id = '0';
    if(model.parent_id){
      this.model_parent_id = model.parent_id;
    }

    const newContent = {
      id: this.db.createId(),
      parent_id: this.model_parent_id,
      name: model.name,
      shortName: model.shortName,
      phone: model.phone,
      fax: model.fax,
      email: model.email,
      postal_code: model.postal_code,
      address: model.address,
      mandate: model.mandate,
      //website: model.website,
      //image: model.image,
      //status: model.status,
      logo: model.logo,
      contactName: model.contactName,
      contactOfficePhone: model.contactOfficePhone,
      contactEmail: model.contactEmail,
      contactMobile: model.contactMobile,
      contactTitle: model.contactTitle,
      backgroundColor: model.backgroundColor,
      deptFacebookAcc: model.deptFacebookAcc,
      deptInstagramAcc: model.deptInstagramAcc,
      deptTwitterAcc: model.deptTwitterAcc,
      deptLinkedinAcc: model.deptLinkedinAcc,
      deptYoutubeAcc: model.deptYoutubeAcc,
      applications: model.applications
    };
    return this.db.collection('Department').doc(newContent.id).set(newContent);
  }

  getDepartmentByID(id: string): AngularFirestoreDocument<any> {
    this.contentRef = this.db.doc('Department/' + id);
    return this.contentRef;
  }

  SortContentOnDep(model: any[]) {
    model.forEach(element => {
      this.db.collection('Department').doc(element.id).update(element);
    });
  }
  

  UpdateDepartment(model: any) {
    const newContent = {
      id: model.id,
      name: model.name,
      shortName: model.shortName,
      phone: model.phone,
      fax: model.fax,
      email: model.email,
      postal_code: model.postal_code,
      address: model.address,
      mandate: model.mandate,
      //website: model.website,
      //image: model.image,
      //status: model.status,
      logo: model.logo,
      contactName: model.contactName,
      contactOfficePhone: model.contactOfficePhone,
      contactEmail: model.contactEmail,
      contactMobile: model.contactMobile,
      contactTitle: model.contactTitle,
      backgroundColor: model.backgroundColor,
      deptFacebookAcc: model.deptFacebookAcc,
      deptInstagramAcc: model.deptInstagramAcc,
      deptTwitterAcc: model.deptTwitterAcc,
      deptLinkedinAcc: model.deptLinkedinAcc,
      deptYoutubeAcc: model.deptYoutubeAcc,
      applications: model.applications
    };
    return this.db.collection('Department').doc(model.id).update(newContent);
  }

  DeleteDepartment(model: any) {
    return this.db.collection('Department').doc(model.id).delete();
  }


  SortDepOnServer(model: any[]) {
    model.forEach(element => {
      this.db.collection('Department').doc(element.id).update(element);
    });
  }
  

  getDepartmentList(): AngularFirestoreCollection<any[]> {
    this.contentListRef = this.db.collection('Department', ref => ref.where('parent_id', '==', '0'));
    return this.contentListRef;
  }

  getDepartmentAndSubDepList(): AngularFirestoreCollection<any[]> {
    this.contentListRef = this.db.collection('Department');
    return this.contentListRef;
  }

  getLimitedContentList(limit: number): AngularFirestoreCollection<any[]> {
    // this.contentListRef = this.db.collection('Department', ref => ref.limit(limit));
    this.contentListRef = this.db.collection('Department', ref => ref.where('parent_id', '==', '0').limit(limit));
    return this.contentListRef;
  }

  getSubDepartmentList(departmentId): AngularFirestoreCollection<any[]> {
    this.contentListRef = this.db.collection('Department', ref => ref.where('parent_id', '==', departmentId));
    return this.contentListRef;
  }

  sendInvitationToDepartmentEmployee(model: any) {
       return this.db.collection('Dep-Emp-Invitation').doc(model.id).set(model);
  }

  saveEmployee(model: any) {
    return this.db.collection('BandMembers').doc(model.uid).set(model);
  }

  updateEmployee(model: any) {
    return this.db.collection('BandMembers').doc(model.uid).update(model);
  }

  getInvitationToDepartmentEmployee(invitationID: string): AngularFirestoreDocument<any> {
    this.contentRef = this.db.doc('Dep-Emp-Invitation/' + invitationID);
    return this.contentRef;
  }

  // ---------------- Add Employee ------------------------------------
  setMemberToDepartmentEmployee(model: any) {
    return this.db.collection('Department-Employee').doc(model.id).set(model);
  }

  UpdateMemberInDepartmentEmployee(model: any) {
    return this.db.collection('Department-Employee').doc(model.id).update(model);
  }

  DeleteMemberFromDepartmentEmployee(model: any) {
    return this.db.collection('Department-Employee').doc(model.id).delete();
  }

  getMemberOfDepartmentEmployeeByID(id: string): AngularFirestoreDocument<any> {
    this.contentRef = this.db.doc('Department-Employee/' + id);
    return this.contentRef;
  }

  getDepartmentEmployee(departmentId: any): AngularFirestoreCollection<any[]> {
    return this.db.collection('Department-Employee', ref => ref.where('departmentId', '==', departmentId));
  }

  getAllDepartmentEmployee(): AngularFirestoreCollection<any[]> {
    return this.db.collection('Department-Employee');
  }

  // -----------------------------------------------------------------

  updateDepartmentEmployee(model: any) {
    return this.db.collection('BandMembers').doc(model.uid).update(model);
  }

  // getDepartmentMembersList(departmentId): AngularFirestoreCollection<any[]> {
  //   console.log('fetching');

  //   return this.db.collection('BandMembers', ref => ref.where('departmentId', '==', departmentId));
  // }

  getDepartmentEmployeeList(departmentId: any): AngularFirestoreCollection<any[]> {
    return this.db.collection('BandMembers', ref => ref.where('departmentId', '==', departmentId));
  }

  getAllDepartmentEmployeeList(): AngularFirestoreCollection<any[]> {
    return this.db.collection('BandMembers', ref => ref.where('role', '==', 'Department-Employee'));
  }

  getDepartmentEmployeeByID(id: string): AngularFirestoreDocument<any> {
    this.contentRef = this.db.doc('BandMembers/' + id);
    return this.contentRef;
  }
  getDeptEmpByApplicantIdAndDeptId(applicantId: any, deptId: any) {
    return this.db.collection('Department-Employee', ref => ref.where('memberId', '==', applicantId).where('departmentId', '==', deptId));
  }

  // --------Assign employee to department----------

  getEmployeeInDepartmentWithApp(appName: string) {
    return this.db.collection('Dep-Emp-App', ref => ref.where('applicationName', '==', appName));
  }
  addAppWithEmployee(model: any) {
    const newContent = {
      id: this.db.createId(),
      applicationName: model.applicationName,
      departmentId: model.departmentId,
      employeeId: model.employeeId
    };
    return this.db.collection('Dep-Emp-App').doc(newContent.id).set(newContent);
  }

  getAppByEmpIdAndDeptId(empId: string, deptId: string){
    return this.db.collection('Dep-Emp-App', ref => ref.where('employeeId', '==', empId).where('departmentId', '==', deptId));
  }
}
