<app-firebase-loading *ngIf="loading"></app-firebase-loading>

<div class="row" *ngIf="application">
    <div *ngIf="((memberData && member) || !memberData) && ((assetData && assets) || !assetData) && ((incomeData && incomes) || !incomeData) ">
        <div class="row center-align">
            <h5><strong>{{application.name | uppercase}}</strong></h5>
        </div>

        <form class="row col s12 card white" [formGroup]="form" *ngIf="step > 0">
            <p class="center-align" *ngIf="step <= application.sections.length">Step {{step}} of
                {{application.sections.length}}</p>
            <div class="col s12" *ngFor="let section of application.sections;let si=index;">

                <div class="col s12 card-content" [formGroupName]="section.title" *ngIf="step == section.order">

                    <div class="col s12 teal lighten-2 white-text">
                        <h5 class="center-align card-title">{{section.title}}</h5>
                    </div>

                    <div class="row col s12">

                        <div *ngIf="section.multipleEntry" class="row col s12">
                            <ul class="collection with-header">
                                <li class="collection-header">
                                    <h5>Added Items</h5>
                                </li>

                                <div *ngFor="let value of getValues(section.title)?.controls; let vi=index">
                                    <li class="collection-item">
                                        <span *ngFor="let field of application.sections[si].fields;let i=index">
                                            <span
                                                style="margin-right: 10px;">{{getValue(vi,section.title,field.label)}}</span>
                                        </span>
                                    </li>
                                </div>
                            </ul>
                        </div>

                        <div *ngFor="let field of section.fields;let fi=index">
                            <div [ngSwitch]="field.field_type">
                                <div class="input-field col s12" *ngSwitchCase="'Input'">
                                    <i class="material-icons prefix green-text">{{field.icon}}</i>
                                    <input class="validate" autocomplete="off" [formControlName]="field.label"
                                        [id]="field.label" [type]="field.data_type">
                                    <label>{{field.label}}<span *ngIf="field.required" class="red-text">*</span></label>
                                </div>

                                <div class="input-field col s12" *ngSwitchCase="'TextArea'">
                                    <i class="material-icons prefix blue-text">{{field.icon}}</i>
                                    <textarea id="field.label" [formControlName]="field.label" style="height: 100px;"
                                        class="materialize-textarea"></textarea>
                                    <label>{{field.label}}<span *ngIf="field.required" class="red-text">*</span></label>
                                </div>

                                <div class="input-field col s12" *ngSwitchCase="'Select'">
                                    <i class="material-icons prefix purple-text">{{field.icon}}</i>
                                    <select [id]="field.label" [formControlName]="field.label" class="validate">
                                        <option value="" disabled selected>Choose your option</option>
                                        <option *ngFor="let value of field.options" [value]="value">{{value}}
                                        </option>
                                    </select>
                                    <label>{{field.label}}<span *ngIf="field.required" class="red-text">*</span></label>
                                </div>

                                <div class="input-field col s12" *ngSwitchCase="'Radio'">
                                    <div style="margin: 15px;">{{field.label}} <i
                                            class="material-icons prefix purple-text">{{field.icon}}</i></div>
                                    <div class="row col s12">
                                        <label *ngFor="let value of field.options" class="col">
                                            <input [name]="field.label" type="radio" [formControlName]="field.label"
                                                [value]="value" />
                                            <span>{{value}}</span>
                                        </label>
                                    </div>
                                </div>

                                <div class="input-field col s12" *ngSwitchCase="'Checkbox'">
                                    <div style="margin: 15px;">{{field.label}} <i
                                            class="material-icons prefix purple-text">{{field.icon}}</i></div>
                                    <div class="row col s12">
                                        <label *ngFor="let value of field.options" class="col">
                                            <input [name]="field.label" type="checkbox" [formControlName]="field.label"
                                                [value]="value" />
                                            <span>{{value}}</span>
                                        </label>
                                    </div>
                                </div>

                                <div class="col s12 file-field input-field" *ngSwitchCase="'File'">
                                    <div class="btn">
                                        <span>Choose File <i class="material-icons">file_upload
                                            </i></span>
                                        <input type="file" [formControlName]="field.label"
                                            accept="image/*, application/pdf" multiple />
                                    </div>
                                    <div class="file-path-wrapper">
                                        <input class="file-path validate" type="text" placeholder="No File Choosen" />
                                    </div>
                                </div>

                                <div *ngSwitchCase="'Scale'">
                                    <div style="margin: 15px;">{{field.label}} <i
                                            class="material-icons prefix purple-text">{{field.icon}}</i></div>
                                    <ngx-slider [options]="getScaleOption(field.scales)"
                                        [formControlName]="field.label">
                                    </ngx-slider>
                                    <br>
                                </div>
                            </div>
                        </div>

                        <button *ngIf="section.multipleEntry" style="margin-bottom: 15px;" (click)="addEntry(si)"
                            class="col s12 btn waves-effect green" type="button">Add <i
                                class="material-icons right">add</i></button>
                    </div>

                    <div class="row col s12 card-action">
                        <button [disabled]="!isValid()" *ngIf="application.sections.length > step"
                            style="margin-bottom: 15px;" class="col s12 m5 offset-m2 l3 btn right waves-effect blue"
                            type="button" (click)="onNext()" title="Save">Next <i
                                class="material-icons right">arrow_forward</i></button>

                        <button [disabled]="!form.valid" *ngIf="step == application.sections.length"
                            style="margin-bottom: 15px;" class="col s12 m5 offset-m2 l3 btn right waves-effect green"
                            type="submit" (click)="previewApplication(form.value)" title="Save">Preview<i
                                class="material-icons right">visibility</i></button>

                        <button *ngIf="step > 1" class="col s12 m5 l3 btn left waves-effect blue" type="button"
                            style="margin-bottom: 15px;" (click)="onBack()"><i
                                class="material-icons left">arrow_back</i>Back
                        </button>
                    </div>
                </div>
            </div>
        </form>

        <div class="row col s12 card white" *ngIf="step == application.sections.length+1">
            <div class="row col s12 summary">

                <div class="row col s12 teal lighten-2 white-text">
                    <h5 class="left-align col s6">SUMMARY</h5>
                    <div class="row right" style="margin-top: 15px; margin-right: 10px;">
                        <a class='dropdown-trigger btn-flat waves-effect waves-light white-text ' id="report"
                            data-target='dropdown1' style="font-weight: bold; border: 2px solid white;"><i
                                class="material-icons right">picture_as_pdf</i>Get Report</a>
                        <ul id='dropdown1' class='col dropdown-content'>
                            <li><a (click)="downloadReport()" class="btn-flat center-align"><i
                                        class="material-icons right">
                                        download
                                    </i>Download</a></li>
                            <li><a (click)="openReport()" class="btn-flat center-align"><i class="material-icons right">
                                        open_in_new
                                    </i>Open</a></li>
                            <li><a (click)="printReport()" class="btn-flat center-align"><i
                                        class="material-icons right">
                                        print
                                    </i>Print</a></li>
                            <li><a (click)="sendReport()" class="btn-flat center-align"><i class="material-icons right">
                                        share
                                    </i>Send</a></li>
                        </ul>
                    </div>
                </div>

                <div class="row">
                    <div *ngFor="let section of application.sections;">
                        <ul class="collection with-header col s12">
                            <li class="collection-header">
                                <h5><strong>{{section.title | uppercase}}</strong></h5>
                            </li>
                            <div *ngIf="!section.multipleEntry">
                                <li class="collection-item col m6 s12" *ngFor="let field of section.fields;">
                                    <div><strong>{{field.label}}</strong>
                                        <a class="secondary-content">{{model[section.title][field.label]}}
                                        </a>
                                    </div>
                                </li>
                            </div>

                            <div *ngIf="section.multipleEntry">
                                <div *ngFor="let value of model[section.title].values; let vi=index"
                                    class="row col s12">
                                    <li class="collection-item col m6 s12" *ngFor="let field of section.fields;">
                                        <div><strong>{{field.label}}</strong>
                                            <a class="secondary-content">{{value[field.label]}}
                                            </a>
                                        </div>
                                    </li>
                                </div>
                            </div>
                        </ul>
                    </div>
                </div>

                <div class="row col s12" style="margin-top: 25px;">
                    <button class="col s12 m5 offset-m2 l3 btn right waves-effect green" type="button"
                        style="margin-bottom: 15px" (click)="submitApplication()" title="Save">Submit <i
                            class="material-icons right">send</i></button>

                    <button class="col s12 m5 l3 btn left waves-effect blue" type="button" style="margin-bottom: 15px"
                        (click)="onBack()"><i class="material-icons left">arrow_back</i>Back
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>