import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { CommunityService } from 'src/app/services/api/community.service';
import { HousingService } from 'src/app/services/api/housing.service';
import { HouseFilterModel } from 'src/app/models/HousingModel';
import { MediaService } from 'src/app/services/api/media.service';
import { Attachment } from 'src/app/models/emailModel';
import { DataService } from 'src/app/services/data.service';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';

const pdfMake = require('pdfmake/build/pdfmake.js');
const pdfFonts = require('pdfmake-unicode/dist/pdfmake-unicode.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;

declare var $: any;

@Component({
  selector: 'app-housing',
  templateUrl: './housing.component.html',
  styleUrls: ['./housing.component.css']
})
export class HousingComponent implements OnInit {

  themeColor = environment.appTheme.adminThemeColor;
  defaultThemeColor = environment.appTheme.themeColor;
  provinces: any[];
  loading = false;
  communities: any[];
  filterForm: UntypedFormGroup;
  properties: any[];
  filteredProperties: any[];
  count = 0;
  p: number = 1;
  filters = {
    Name_of_Owner: null,
    aid_of_owner: null,
    house_number: null,
    community: '',
  }
  property: any;
  step1 = true;
  step2 = false;

  emailForm: UntypedFormGroup;
  reportFile: File;
  invalidEmail = false;
  emailList: any[] = [];
  fileList: any[] = [];
  fileName = "Houses.pdf";
  fileLink = '';
  propertyOccupants: any[] = [];

  binDoc: File;
  base64Image: any;
  sendEmail = false;
  emailSent = false;

  emailSentHandler(valueEmitted) {
    if (valueEmitted) this.emailSent = true;
    this.sendEmail = false;
  }

  constructor(private location: Location,
    private communityService: CommunityService,
    private housingService: HousingService,
    private mediaService: MediaService,
    public appSettingsService: AppSettingsService,
    private dataService: DataService) {
    this.provinces = this.dataService.getProvinceWithCode();
    this.themeColor = this.appSettingsService.getAppThemeColor() ? this.appSettingsService.getAppThemeColor() : this.defaultThemeColor;

  }

  ngOnInit() {
    this.loading = true;

    //From SQL
    this.housingService.getHouses().subscribe(x => {
      if (x && x.length > 0) {
        this.properties = x;
        this.properties = this.properties.sort((a, b) => (a.ID > b.ID) ? 1 : ((b.ID > a.ID) ? -1 : 0));
        this.count = this.properties.length;
      }
      this.loading = false;
      setTimeout(() => {
        $('select').formSelect();
        $('.dropdown-trigger').dropdown();
        $('.modal').modal();
      }, 25);
    });

    this.communityService.getCommunity().subscribe(com => {
      if (com.length > 0) {
        this.communities = com;
        setTimeout(() => {
          $('select').formSelect();
        }, 25);
      }
    });
  }

  filterHouses() {
    const model = new HouseFilterModel();
    if (this.filters.aid_of_owner) { model.ownerID = this.filters.aid_of_owner; }
    if (this.filters.Name_of_Owner && this.filters.Name_of_Owner !== '') { model.ownerName = this.filters.Name_of_Owner; }
    if (this.filters.house_number && this.filters.house_number !== '') { model.houseNo = this.filters.house_number; }
    if (this.filters.community && this.filters.community !== '') { model.community = this.filters.community; }

    this.housingService.FilterProperties(model).subscribe(x => {
      if (x) {
        this.filteredProperties = x;
        this.count = this.filteredProperties.length;
      }
    })
  }

  filterList() {
    if (this.properties && this.properties.length > 0) {
      var result = this.filterByOwner(this.properties);
      var output = this.filterByOthers(result);
      this.filteredProperties = output;
      this.count = this.filteredProperties.length;
    }
  }

  filterByOthers(values) {
    let output = values.filter(x => {
      if (this.filters.house_number && this.filters.community !== '') return (x.house_number === this.filters.house_number.toString()) && (x.community === this.filters.community);
      else if (this.filters.house_number) return x.house_number === this.filters.house_number.toString();
      else if (this.filters.community !== '') return x.community === this.filters.community;
      else return values;
    });
    return output;
  }

  filterByOwner(values) {
    let output = values.filter(x => {
      if (this.filters.Name_of_Owner && this.filters.aid_of_owner) return (x.Name_of_Owner === this.filters.Name_of_Owner) && (x.aid_of_owner === Number(this.filters.aid_of_owner));
      else if (this.filters.Name_of_Owner && !this.filters.aid_of_owner) return (x.Name_of_Owner === this.filters.Name_of_Owner);
      else if (!this.filters.Name_of_Owner && this.filters.aid_of_owner) return (x.aid_of_owner === Number(this.filters.aid_of_owner));
      else return values;
    });
    return output;
  }

  getAge(year) {
    const currentYear = new Date().getFullYear();
    const age = currentYear - Number(year);
    return age;
  }

  async getReport() {
    let report = {
      info: {
        title: 'List of Houses',
        subject: 'List of Houses',
        keywords: 'Houses',
      },
      pageSize: 'LETTER',
      content: await this.getContent(),
      styles: this.getStyles(),
    }
    return report;
  }

  async getContent() {
    let content = [];
    content.push(
      {
        style: 'table',
        layout: 'noBorders',
        table: {
          widths: ['*', '*'],
          body: [
            [
              {
                columns: [
                  [
                    await this.getLogo()
                  ],
                  [
                    {
                      stack: [
                        {
                          text: 'MY NATION ',
                          style: 'mainTitle',
                          alignment: 'center',
                        },
                        {
                          text: 'Connecting Your Nation',
                          style: 'normalText',
                          alignment: 'center',
                        },
                        {
                          text: 'Sovereign Data For Sovereign Nations ',
                          fontSize: 8,
                          italics: true,
                          alignment: 'center',
                        },
                      ]
                    },
                  ]
                ],
              },
              {
                stack: [
                  {
                    text: "Department Of Housing",
                    style: 'title'
                  },
                  {
                    text: 'Houses',
                    style: 'title'
                  },
                  {},
                ]
              },
            ],
          ]
        }
      },
      {
        style: 'table',
        layout: 'lightHorizontalLines',
        pageMargins: [100, 100],

        table: {
          widths: ['*', '*', '*', '*'],
          body: this.getSummary(this.properties)
        },
      },
      { text: '', pageBreak: 'after' }
    );

    //this.properties.forEach(property => {
    for await (const property of this.properties) {
      content.push(

        //Proprty details
        {
          style: 'table',
          table: {
            widths: ['*', '*', '*'],
            body: [
              [
                {
                  stack: [
                    {
                      text: 'House ID: ',
                      style: 'subTitle'
                    },
                    {
                      text: property.ID ? property.ID : '',
                      style: 'normalText'
                    },
                  ]
                },
                {
                  stack: [
                    {
                      text: 'Location: ',
                      style: 'subTitle'
                    },
                    {
                      text: property.location,
                      style: 'normalText'
                    },
                  ]
                },
                {
                  stack: [
                    {
                      text: 'Community: ',
                      style: 'subTitle'
                    },
                    {
                      text: property.community ? property.community : '',
                      style: 'normalText'
                    },
                  ],
                },
              ],
              [
                {
                  stack: [
                    {
                      text: 'Head of Household ID: ',
                      style: 'subTitle'
                    },
                    {
                      text: property.aid_of_owner,
                      style: 'normalText'
                    },
                  ],
                },
                {
                  stack: [
                    {
                      text: 'Head of Househole: ',
                      style: 'subTitle'
                    },
                    {
                      text: property.GivenName + " " + property.LastName,
                      style: 'normalText'
                    },
                  ],
                  colSpan: 2
                },
                {},
              ],
              [
                {
                  stack: [
                    {
                      text: 'Primary Heat Source: ',
                      style: 'subTitle'
                    },
                    {
                      text: property.Primary_heat ? property.Primary_heat : "",
                      style: 'normalText'
                    },
                  ],
                  colSpan: 2
                },
                {},
                {
                  stack: [
                    {
                      text: 'Seconday Heat Source: ',
                      style: 'subTitle'
                    },
                    {
                      text: property.Secondary_heat ? property.Secondary_heat : "",
                      style: 'normalText'
                    },
                  ],
                },
              ],
              [
                {
                  stack: [
                    {
                      text: 'CMHC: ',
                      style: 'subTitle'
                    },
                    {
                      text: property.CMHC ? 'Yes' : "No",
                      style: 'normalText'
                    },
                  ],
                },
                {
                  stack: [
                    {
                      text: 'Financed: ',
                      style: 'subTitle'
                    },
                    {
                      text: property.finianced ? 'Yes' : "No",
                      style: 'normalText'
                    },
                  ],
                  colSpan: 2
                },
                {}
              ],
            ]
          }
        },

        property.desc_type == 'HN' ?
          {
            style: 'table',
            table: {
              widths: ['*', '*'],
              body: [

                [
                  {
                    stack: [
                      {
                        text: 'House Number: ',
                        style: 'subTitle'
                      },
                      {
                        text: property.house_number ? property.house_number : '',
                        style: 'normalText'
                      },
                    ],
                    colSpan: 2
                  },
                  {}
                ],
              ]
            }
          } : '',

        property.desc_type == 'LBP' ?
          {
            style: 'table',
            table: {
              widths: ['*', '*'],
              body: [

                [
                  {
                    stack: [
                      {
                        text: 'Block: ',
                        style: 'subTitle'
                      },
                      {
                        text: property.house_block ? property.house_block : '',
                        style: 'normalText'
                      },
                    ]
                  },
                  {
                    stack: [
                      {
                        text: 'Lot: ',
                        style: 'subTitle'
                      },
                      {
                        text: property.house_lot ? property.house_lot : '',
                        style: 'normalText'
                      },
                    ],
                  },
                ],
              ]
            }
          } : '',

        property.desc_type == 'LLD' ?
          {
            style: 'table',
            table: {
              widths: ['*', '*', '*'],
              body: [

                [
                  {
                    stack: [
                      {
                        text: 'QRT: ',
                        style: 'subTitle'
                      },
                      {
                        text: property.Quarter ? property.Quarter : '',
                        style: 'normalText'
                      },
                    ]
                  },
                  {
                    stack: [
                      {
                        text: 'Section: ',
                        style: 'subTitle'
                      },
                      {
                        text: property.section ? property.section : '',
                        style: 'normalText'
                      },
                    ],
                    colSpan: 2
                  },
                  {}
                ],

                [
                  {
                    stack: [
                      {
                        text: 'Township: ',
                        style: 'subTitle'
                      },
                      {
                        text: property.township ? property.township : '',
                        style: 'normalText'
                      },
                    ]
                  },
                  {
                    stack: [
                      {
                        text: 'Range: ',
                        style: 'subTitle'
                      },
                      {
                        text: property.range ? property.range : '',
                        style: 'normalText'
                      },
                    ],
                  },
                  {
                    stack: [
                      {
                        text: 'Meridian: ',
                        style: 'subTitle'
                      },
                      {
                        text: property.median ? property.median : '',
                        style: 'normalText'
                      },
                    ],
                  },
                ],
              ]
            }
          } : '',

        property.desc_type == 'Other' ?
          {
            style: 'table',
            table: {
              widths: ['*', '*'],
              body: [
                [
                  {
                    stack: [
                      {
                        text: 'Location Description: ',
                        style: 'subTitle'
                      },
                      {
                        text: property.Other_location ? property.Other_location : '',
                        style: 'normalText'
                      },
                    ],
                    colSpan: 2
                  },
                  {},
                ],
              ]
            }
          } : '',

        {
          style: 'table',
          table: {
            widths: ['*', '*', '*'],
            body: [
              [
                {
                  stack: [
                    {
                      text: 'Mailing Address: ',
                      style: 'subTitle'
                    },
                    {
                      text: property.MailAddress ? property.MailAddress : '',
                      style: 'normalText'
                    },
                  ],
                },
                {
                  stack: [
                    {
                      text: 'Province: ',
                      style: 'subTitle'
                    },
                    
                    // {
                    //   text: property.province ? this.provinces[this.provinces.findIndex((pro) => pro.code === property.province)].name : '',
                    //   style: 'normalText'
                    // },
                  ],
                },

                {
                  stack: [
                    {
                      text: 'Postal Code: ',
                      style: 'subTitle'
                    },
                    {
                      text: property.PostalCode ? property.PostalCode : '',
                      style: 'normalText'
                    },
                  ],
                },
              ],
              [
                {
                  stack: [
                    {
                      text: 'Built On: ',
                      style: 'subTitle'
                    },
                    {
                      text: property.year ? property.year : '',
                      style: 'normalText'
                    },
                  ],
                },
                {
                  stack: [
                    {
                      text: 'Age of House : ',
                      style: 'subTitle'
                    },
                    {
                      text: property.year ? this.getAge(property.year) : '',
                      style: 'normalText'
                    },
                  ],
                },

                {
                  stack: [
                    {
                      text: 'Style: ',
                      style: 'subTitle'
                    },
                    {
                      text: property.style ? property.style : '',
                      style: 'normalText'
                    },
                  ],
                },
              ],
              [
                {
                  stack: [
                    {
                      text: 'No of Rooms: ',
                      style: 'subTitle'
                    },
                    {
                      text: property.rooms ? property.rooms : '',
                      style: 'normalText'
                    },
                  ],
                },
                {
                  stack: [
                    {
                      text: 'Size Of House: ',
                      style: 'subTitle'
                    },
                    {
                      text: property.size ? property.size : '' + " sq ft",
                      style: 'normalText'
                    },
                  ],
                },

                {
                  stack: [
                    {
                      text: 'Cost of Building: ',
                      style: 'subTitle'
                    },
                    {
                      text: "$ " + property.value ? property.value : '',
                      style: 'normalText'
                    },
                  ],

                },
              ],
            ]
          }
        },
        {
          text: "",
          pageBreak: 'after'
        }
      );
    };
    return content;
  }

  getSummary(houses) {
    let summary = [];

    summary.push([{ text: "Houses", colSpan: 4, style: 'title' }, {}, {}, {}]);
    summary.push([{ text: "Total: " + houses.length, colSpan: 4, alignment: 'center', margin: [10, 10] }])
    summary.push([
      {
        text: 'ID',
        bold: true,
      },
      {
        text: 'Head of Household',
        bold: true,
      },
      {
        text: 'Location',
        bold: true,
      },
      {
        text: 'Community',
        bold: true,
      },
    ]);
    let i = 2;
    houses.forEach(house => {
      summary.push(
        [{ text: i - 1, linkToPage: i, style: 'normalText', margin: [0, 5] },
        { text: house.GivenName + " " + house.LastName, linkToPage: i, style: 'normalText', margin: [0, 5] },
        { text: house.location ? house.location : '', linkToPage: i, style: 'normalText', margin: [0, 5] },
        { text: house.community ? house.community : '', linkToPage: i, style: 'normalText', margin: [0, 5] },
        ]);
      i++;
    });
    return summary;
  }

  async getOccupants(id: number) {
    let occupantsBody = [];
    let occupants = [];
    this.housingService.getOccupants(id).subscribe(x => {
      if (x) {
        occupants = x;
        if (occupants && occupants.length > 0) {
          occupantsBody.push([{ text: "Occupants", colSpan: 5, style: 'title' }, {}, {}]);
          occupantsBody.push([
            {
              text: 'Member ID',
              style: 'header',
              bold: true,
            },
            {
              text: 'First Name',
              style: 'header',
              bold: true,
            },
            {
              text: 'Last Name',
              style: 'header',
              bold: true,
            },
            {
              text: 'Mail Address',
              style: 'header',
              bold: true,
            },
            {
              text: 'Classification',
              style: 'header',
              bold: true,
            },
          ]);
          occupants.forEach(occ => {
            occupantsBody.push(
              [{ text: occ.applicantID, style: 'normalText' },
              { text: occ.GivenName, style: 'normalText' },
              { text: occ.LastName, style: 'normalText' },
              { text: occ.MailAddress, style: 'normalText' },
              { text: occ.Classification, style: 'normalText' }]
            );
          });
        }
        return occupantsBody;
      }
    });
  };

  getStyles() {
    return {
      mainTitle: {
        bold: true,
        fontSize: 16,
        alignment: 'left',
        margin: [0, 0, 0, 20]
      },
      title: {
        bold: true,
        fontSize: 14,
        alignment: 'center',
        margin: [0, 10, 0, 10]
      },
      subTitle: {
        bold: true,
        fontSize: 12,
        alignment: 'left',
        margin: [0, 10, 0, 10]
      },
      normalText: {
        fontSize: 11,
      },
      header: {
        fontSize: 12,
        bold: true,
        alignment: 'center',
      },
      links: {
        color: 'blue',
        decoration: 'underline',
        margin: [0, 5, 0, 5]
      },
      table: {
        margin: [10, 5, 0, 10]
      },
    }
  }

  async getLogo() {
    let imageLink = "assets/img/Nation-logo.png";
    const image = await this.getBase64FromUrl(imageLink);
    if (image) {
      return {
        image: image,
        width: 120,
        alignment: 'left'
      }
    }
  }

  getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      }
    });
  }

  async downloadReport() {
    const documentDefinition = await this.getReport();
    pdfMake.createPdf(documentDefinition).download(this.fileName);
  }

  async openReport() {
    const documentDefinition = await this.getReport();
    pdfMake.createPdf(documentDefinition).open();
  }

  async printReport() {
    const documentDefinition = await this.getReport();
    pdfMake.createPdf(documentDefinition).print();
  }

  async sendReport() {
    this.fileList = [];
    const documentDefinition = await this.getReport();
    const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
    pdfDocGenerator.getBlob((blob) => {
      this.reportFile = new File([blob], this.fileName, {
        type: blob.type
      });
      this.mediaService.uploadPDF(this.reportFile).subscribe(upload => {
        if (upload) {
          if (upload.imgLink) this.fileLink = upload.imgLink
          let report = new Attachment();
          report.url = this.fileLink;
          report.name = this.fileName;
          report.type = 'PDF';
          this.fileList.push(report);
          this.sendEmail = true;
        }
      });
    });
  }

  back() {
    this.location.back();
  }

  cancel() {
    $('.modal').modal('close');
  }

}
