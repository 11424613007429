import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MemberMainComponent } from './member-main/member-main.component';
import { MemberRoutingModule } from './member-routing.module';
import { PersonalInformationComponent } from './profile/personal-information/personal-information.component';
import { FamilyCompositionComponent } from './profile/family-composition/family-composition.component';
import { SharedModule } from '../shared/shared.module';
import { EntitlementDetailComponent } from './monthly-assistance/entitlement/entitlement-detail/entitlement-detail.component';
import { HistoryComponent } from './monthly-assistance/entitlement/history/history.component';
import { ProfileMainComponent } from './profile/profile-main/profile-main.component';
import { DocumentsComponent } from './profile/documents/documents.component';
import { FamilymemberdetailComponent } from './profile/family-composition/familymemberdetail/familymemberdetail.component';
import { DocumentDetailComponent } from './profile/documents/document-detail/document-detail.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { MonthlyAssistanceMainComponent } from './monthly-assistance/monthly-assistance-main/monthly-assistance-main.component';
import { MyEntitlementComponent } from './monthly-assistance/entitlement/my-entitlement/my-entitlement.component';
import { SupplementalSupportComponent } from './monthly-assistance/supplemental-support/supplemental-support.component';
import { SupportNewComponent } from './monthly-assistance/supplemental-support/support-new/support-new.component';
import { SupportDetailComponent } from './monthly-assistance/supplemental-support/support-detail/support-detail.component';
import { ParticipationsComponent } from './participations/participation/participations.component';
import { ActionDetailComponent } from './participations/action/action-detail/action-detail.component';
import { ActivityDetailComponent } from './participations/activity/activity-detail/activity-detail.component';
import { ParticipationDetailComponent } from './participations/participation-detail/participation-detail.component';
import { MyIncomeStatementComponent } from './monthly-assistance/my-income-statement/my-income-statement.component';
import { NewIncomeStatementComponent } from './monthly-assistance/my-income-statement/new-income-statement/new-income-statement.component';
// tslint:disable-next-line: max-line-length
import { PreviousIncomeStatementComponent } from './monthly-assistance/my-income-statement/previous-income-statement/previous-income-statement.component';

import { EducationComponent } from './profile/education/education.component';
import { EmploymentComponent } from './profile/employment/employment.component';
import { AddEmploymentComponent } from './profile/employment/add-employment/add-employment.component';
import { EditEmploymentComponent } from './profile/employment/edit-employment/edit-employment.component';


import { MemberNameStarPipe } from 'src/app/Pipes/MemberNameStarPipe';
import { HousingComponent } from './profile/housing/housing.component';
import { AddEducationComponent } from './profile/education/add-education/add-education.component';
import { EditEducationComponent } from './profile/education/edit-education/edit-education.component';
import { NewsComponent } from './news/news.component';
import { SocialAssistanceMenuComponent } from './monthly-assistance/social-assistance-menu/social-assistance-menu.component';
import { MemberHomeComponent } from './member-home/member-home.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { SettingsComponent } from './settings/settings.component';
// import { TextMaskModule } from 'angular2-text-mask';
import { MemberHomeSubMenuComponent } from './member-home-sub-menu/member-home-sub-menu.component';
import { NationWallComponent } from './nation-wall/nation-wall.component';
import { AddNationWallPostComponent } from './nation-wall/add-nation-wall-post/add-nation-wall-post.component';
import { ViewNationWallPostComponent } from './nation-wall/view-nation-wall-post/view-nation-wall-post.component';
import { GovernanceComponent } from './governance/governance.component';
import { DepartmentComponent } from './department/department.component';
import { ManageDepartmentComponent } from './department/manage-department/manage-department.component';
import { ManageSubDepartmentComponent } from './department/manage-sub-department/manage-sub-department.component';
import { ViewDepartmentInfoComponent } from './department/view-department-info/view-department-info.component';
import { ViewSubdepartmentInfoComponent } from './department/view-subdepartment-info/view-subdepartment-info.component';
import { UploadStatementComponent } from './monthly-assistance/my-income-statement/upload-statement/upload-statement.component';
import { DocumentsUploadComponent } from './documents-upload/documents-upload.component';
import { SurveyListComponent } from './pollAnsSurvey/survey-list/survey-list.component';
import { SurveyDetailComponent } from './pollAnsSurvey/survey-detail/survey-detail.component';
import { PollsListComponent } from './pollAnsSurvey/polls-list/polls-list.component';
import { PollDetailComponent } from './pollAnsSurvey/poll-detail/poll-detail.component';
import { DepNewsListComponent } from './department/Dep-news/dep-news-list/dep-news-list.component';
import { DepNewsDetailComponent } from './department/Dep-news/dep-news-detail/dep-news-detail.component';
import { MyInboxComponent } from './member-messaging/my-inbox/my-inbox.component';
import { MyDiscussionComponent } from './member-messaging/my-discussion/my-discussion.component';
import { MyHouseComponent } from './housing/my-house/my-house.component';
import { environment } from 'src/environments/environment';
import { ContactDepartmentMemberComponent } from './department/contact-department-member/contact-department-member.component';
import { FamilyCompositionFirebaseComponent } from './profile/family-composition-firebase/family-composition-firebase.component';
import { NewFamilyMemberComponent } from './profile/family-composition-firebase/new-family-member/new-family-member.component';
import { EditFamilyMemberComponent } from './profile/family-composition-firebase/edit-family-member/edit-family-member.component';
import { ElectionListComponent } from './pollAnsSurvey/election-list/election-list.component';
import { NominationListComponent } from './pollAnsSurvey/nomination-list/nomination-list.component';
import { ElectionDetailComponent } from './pollAnsSurvey/election-list/election-detail/election-detail.component';
import { NominationDetailComponent } from './pollAnsSurvey/nomination-list/nomination-detail/nomination-detail.component';
import { MemberEventsCallendarComponent } from './events/member-events-callendar/member-events-callendar.component';
import { MemberEventsDetailComponent } from './events/member-events-detail/member-events-detail.component';
import { NewsDetailComponent } from './news/news-detail/news-detail.component';
import { AccessScreenComponent } from './access-screen/access-screen.component';
import { MessagingComponent } from './messaging/messaging.component';

// import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { ActivityComponent } from './profile/activity/activity.component';
import { AddActivityComponent } from './profile/activity/add-activity/add-activity.component';
import { EditActivityComponent } from './profile/activity/edit-activity/edit-activity.component';
import { CertificateComponent } from './profile/certificate/certificate.component';
import { AddCertificateComponent } from './profile/certificate/add-certificate/add-certificate.component';
import { EditCertificateComponent } from './profile/certificate/edit-certificate/edit-certificate.component';
import { HighlightsComponent } from './profile/highlights/highlights.component';
import { AddHighlightComponent } from './profile/highlights/add-highlight/add-highlight.component';
import { EditHighlightComponent } from './profile/highlights/edit-highlight/edit-highlight.component';
import { ObjectiveComponent } from './profile/objective/objective.component';
import { AddObjectiveComponent } from './profile/objective/add-objective/add-objective.component';
import { EditObjectiveComponent } from './profile/objective/edit-objective/edit-objective.component';
import { ReferenceComponent } from './profile/reference/reference.component';
import { AddReferenceComponent } from './profile/reference/add-reference/add-reference.component';
import { EditReferenceComponent } from './profile/reference/edit-reference/edit-reference.component';
import { SkillsComponent } from './profile/skills/skills.component';
import { AddSkillComponent } from './profile/skills/add-skill/add-skill.component';
import { EditSkillComponent } from './profile/skills/edit-skill/edit-skill.component';

import { SignMultipleBdComponent } from './monthly-assistance/sign-multiple-bd/sign-multiple-bd.component';

import { IncomeSupportApplicationComponent } from './profile/income-support-application/income-support-application.component';
import { SaveApplicationComponent } from './profile/income-support-application/save-application/save-application.component';
import { SkCasePlanComponent } from './profile/sk-case-plan/sk-case-plan.component';
import { ActionItemPipe } from 'src/app/Pipes/ActionItemPipe';
import { SKCasePlanFRMComponent } from './profile/sk-case-plan-frm/sk-case-plan-frm.component';
import {JobBoardComponent } from './job-board/job-board.component';
import { PSSSPApplicationComponent } from './profile/psssp-application/psssp-application.component';
import { PseApplicationComponent } from './profile/pse-application/pse-application.component';
import { PollResultComponent } from './pollAnsSurvey/poll-result/poll-result.component';
import { BoardListComponent } from './boards/board-list/board-list.component';
import { BoardDetailsComponent } from './boards/board-details/board-details.component';
import { SurveyResultsComponent } from './pollAnsSurvey/survey-results/survey-results.component';
import { MyAreasOfInterestComponent } from './profile/my-areas-of-interest/my-areas-of-interest.component';
import { MyNominationsComponent } from './pollAnsSurvey/my-nominations/my-nominations.component';
import { VoteComponent } from './pollAnsSurvey/vote/vote.component';
import { CampaignPageComponent } from './pollAnsSurvey/campaign-page/campaign-page.component';
import { EditCampaignPageComponent } from './pollAnsSurvey/edit-campaign-page/edit-campaign-page.component';
import { ElectionResultComponent } from './pollAnsSurvey/election-result/election-result.component';
import { CandidatePageComponent } from './pollAnsSurvey/candidate-page/candidate-page.component';
import { MyVotesComponent } from './pollAnsSurvey/my-votes/my-votes.component';
import { HouseRepairComponent } from './housing/house-repair/house-repair.component';
import { HouseRenovationComponent } from './housing/house-renovation/house-renovation.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
// import { AgmCoreModule } from '@agm/core';
import { SKIncomeSupportAppComponent } from './profile/income-support-application/sk-income-support-app/sk-income-support-app.component';
import { AddDepNewsComponent } from './department/Dep-news/add-dep-news/add-dep-news.component';
import { EditDepNewsComponent } from './department/Dep-news/edit-dep-news/edit-dep-news.component';
import { ManageDepNewsComponent } from './department/Dep-news/manage-dep-news/manage-dep-news.component';
import { ManageDepartmentMembersComponent } from './department/manage-department-members/manage-department-members.component';
import { AddMemberToDepartmentComponent } from './department/manage-department-members/add-member-to-department/add-member-to-department.component';
import { EditDepartmentEmployeeComponent } from './department/manage-department-members/edit-department-employee/edit-department-employee.component';
import { ApplyDialogComponent } from './isets/apply-dialog/apply-dialog.component';
import { RequestApplicationComponent } from './isets/request-application/request-application.component';
import { RequestRegistrationComponent } from './isets/request-registration/request-registration.component';
import { PseEntitlementComponent } from './profile/pse-entitlement/pse-entitlement.component';
//import { PseEntitlementDetailComponent } from './profile/pse-entitlement/pse-entitlement-detail/pse-entitlement-detail.component';
import { HousingApplicationComponent } from './housing/housing-application/housing-application.component';
import { TestApplicationComponent } from './test-application/test-application.component';
import { ViewApplicationsComponent } from './view-applications/view-applications.component';
import { ScisApplicationComponent } from './profile/scis-application/scis-application.component';
import { PseCourseRegistrationComponent } from './profile/pse-course-registration/pse-course-registration.component';
import { LrrcnApplicationComponent } from './profile/lrrcn-application/lrrcn-application.component';
import { PseContractComponent } from './profile/pse-contract/pse-contract.component';
import { LrrcnApplicationMemberListComponent } from './profile/lrrcn-application/lrrcn-application-list/lrrcn-application-list.component';
import { PseReleaseFormComponent } from './profile/pse-release-form/pse-release-form.component';
import { PseSupplementalFundingComponent } from './profile/pse-entitlement/pse-supplemental-funding/pse-supplemental-funding.component';
import { PseAppV2Component } from './profile/pse-app-v2/pse-app-v2.component';
import { PseCourseComponent } from './profile/pse-course/pse-course.component';
import { PseFundingsComponent } from './profile/pse-entitlement/pse-fundings/pse-fundings.component';
import { InterventionComponent } from './isets/intervention/intervention.component';
import { InterventionDetailComponent } from './isets/intervention/intervention-detail/intervention-detail.component';
import { ServicePlanComponent } from './isets/service-plan/service-plan.component';
import { ServicePlanDetailComponent } from './isets/service-plan/service-plan-detail/service-plan-detail.component';
import { UploadIsetsDocumentsComponent } from './isets/upload-isets-documents/upload-isets-documents.component';
import { ActionPlanComponent } from './isets/action-plan/action-plan.component';
import { EditActionPlanComponent } from './isets/action-plan/edit-action-plan/edit-action-plan.component';
import { PseAppComponent } from './profile/pse-app/pse-app.component';
import { MemberMainHomeComponent } from './member-main-home/member-main-home.component';
import { GetVerifiedComponent } from './get-verified/get-verified.component';
import { CustomSectionsComponent } from './custom-sections/custom-sections.component';
import { BrowserModule } from '@angular/platform-browser';
import { AngularMyDatePickerModule } from '@nodro7/angular-mydatepicker';
import { NgxMaskDirective } from 'ngx-mask';

@NgModule({
  declarations: [
    JobBoardComponent,
    MemberMainComponent,
    PersonalInformationComponent,
    FamilyCompositionComponent,
    HistoryComponent,
    EntitlementDetailComponent,
    ProfileMainComponent,
    DocumentsComponent,
    FamilymemberdetailComponent,
    DocumentDetailComponent,
    MonthlyAssistanceMainComponent,
    MyEntitlementComponent,
    SupplementalSupportComponent,
    SupportDetailComponent,
    SupportNewComponent,
    ParticipationsComponent,
    ActionDetailComponent,
    ActivityDetailComponent,
    ParticipationDetailComponent,
    MyIncomeStatementComponent,
    NewIncomeStatementComponent,
    PreviousIncomeStatementComponent,
    EmploymentComponent,
    EducationComponent,
    AddEmploymentComponent,
    EditEmploymentComponent,
    MemberNameStarPipe,
    HousingComponent,
    AddEducationComponent,
    EditEducationComponent,
    NewsComponent,
    SocialAssistanceMenuComponent,
    MemberHomeComponent,
    ContactUsComponent,
    SettingsComponent,
    MemberHomeSubMenuComponent,
    NationWallComponent,
    AddNationWallPostComponent,
    ViewNationWallPostComponent,
    GovernanceComponent,
    DepartmentComponent,
    ManageDepartmentComponent,
    ManageSubDepartmentComponent,
    ViewDepartmentInfoComponent,
    ViewSubdepartmentInfoComponent,
    UploadStatementComponent,
    DocumentsUploadComponent,
    SurveyListComponent,
    SurveyDetailComponent,
    PollsListComponent,
    PollDetailComponent,
    DepNewsListComponent,
    DepNewsDetailComponent,
    MyInboxComponent,
    MyDiscussionComponent,
    MyHouseComponent,
    ContactDepartmentMemberComponent,
    FamilyCompositionFirebaseComponent,
    NewFamilyMemberComponent,
    EditFamilyMemberComponent,
    ElectionListComponent,
    NominationListComponent,
    ElectionDetailComponent,
    NominationDetailComponent,
    MemberEventsCallendarComponent,
    MemberEventsDetailComponent,
    NewsDetailComponent,
    AccessScreenComponent,
    MessagingComponent,
    ActivityComponent,
    AddActivityComponent,
    EditActivityComponent,
    CertificateComponent,
    AddCertificateComponent,
    EditCertificateComponent,
    HighlightsComponent,
    AddHighlightComponent,
    EditHighlightComponent,
    ObjectiveComponent,
    AddObjectiveComponent,
    EditObjectiveComponent,
    ReferenceComponent,
    AddReferenceComponent,
    EditReferenceComponent,
    SkillsComponent,
    AddSkillComponent,
    EditSkillComponent,
    SignMultipleBdComponent,
    IncomeSupportApplicationComponent,
    SaveApplicationComponent,
    SkCasePlanComponent,
    ActionItemPipe,
    SKCasePlanFRMComponent,
    PSSSPApplicationComponent,
    PseApplicationComponent,
    PollResultComponent,
    BoardListComponent,
    BoardDetailsComponent,
    SurveyResultsComponent,
    MyAreasOfInterestComponent,
    MyNominationsComponent,
    VoteComponent,
    CampaignPageComponent,
    EditCampaignPageComponent,
    ElectionResultComponent,
    CandidatePageComponent,
    MyVotesComponent,
    HouseRepairComponent,
    HouseRenovationComponent,
    SKIncomeSupportAppComponent,
    AddDepNewsComponent,
    EditDepNewsComponent,
    ManageDepNewsComponent,
    ManageDepartmentMembersComponent,
    AddMemberToDepartmentComponent,
    EditDepartmentEmployeeComponent,
    ApplyDialogComponent,
    RequestApplicationComponent,
    RequestRegistrationComponent,
    PseEntitlementComponent,
    //PseEntitlementDetailComponent,
    HousingApplicationComponent,
    TestApplicationComponent,
    ViewApplicationsComponent,
    ScisApplicationComponent,
    PseCourseRegistrationComponent,
    LrrcnApplicationComponent,
    PseContractComponent,
    LrrcnApplicationMemberListComponent,
    PseReleaseFormComponent,
    PseSupplementalFundingComponent,
    PseAppV2Component,
    PseCourseComponent,
    PseFundingsComponent,
    InterventionComponent,
    InterventionDetailComponent,
    ServicePlanComponent,
    ServicePlanDetailComponent,
    UploadIsetsDocumentsComponent,
    ActionPlanComponent,
    EditActionPlanComponent,
    PseAppComponent,
    MemberMainHomeComponent,
    GetVerifiedComponent,
    CustomSectionsComponent,
  ],
  imports: [
    BrowserModule,
    CommonModule,
    MemberRoutingModule,
    ReactiveFormsModule,
    SharedModule,
    FormsModule,
    AngularMyDatePickerModule ,
    NgxMaskDirective,
    // TextMaskModule,
    // AgmCoreModule.forRoot({
    //   apiKey: environment.googleMapKey.key
    // }),
    NgxSliderModule,
    
  ]
})


export class MemberModule { }
