<div class="row content">

  <div class="col l21/2 hide-on-med-and-down white sidebar ">
    <div class="col-md-3 border-right center">
      <div class="d-flex flex-column align-items-center profile-user text-center">
        <div class="row upper-content">
          <div class="col l3 m2 "></div>
          <!-- <div class="col l6 m8">
            <h3 class="initials circle" *ngIf="user" [ngStyle]="{'background-color': themeColor}">
              {{getInitials(user.FirstName,
              user.LastName)}}</h3>
             
          </div> -->

          <div class="col l6 m8">
            <ng-container *ngIf="user">
              <!-- Check if user object exists -->
              <ng-container *ngIf="!user.profilePictureUrl">
                <!-- Display initials if no image -->
                <h3 class="initials circle" [ngStyle]="{'background-color': themeColor}">
                  {{ getInitials(user.FirstName, user.LastName) }}
                </h3>
              </ng-container>

              <ng-container *ngIf="user.profilePictureUrl">
                <!-- Display image if image exists -->
                <img class="profile-image" [src]="user.profilePictureUrl">
              </ng-container>
            </ng-container>
          </div>

          <div class="col l3 m2"></div>
        </div>
        <h4 class="sub-title-section " *ngIf="user">{{user.GivenName ? user.GivenName : user.FirstName }}
          {{user.LastName}}</h4>
        <h5 class=" sub-title-section" *ngIf="user">{{user.Email}}</h5>
        <h6 class=" sub-title-section">admin</h6><br />
        <a [routerLink]="['/admin/profile']" class="update-profile">Update Profile</a>
      </div>

      <div class="center-align p-t-55 icon-setting" style="text-align: center; ">
        <hr />
        <h6 class="bold-400 p-b-2 modal-trigger" style="cursor: pointer;" data-target="switchModal"
          *ngIf="isChiefOrCouncillor">
          <i class="material-icons">sync</i> Switch Account
        </h6>
        <h6 class="bold-400" style="cursor:pointer;" (click)="signOut()"><i
            class="material-symbols-outlined">power_settings_new</i> Logout
        </h6>
      </div>
    </div>
  </div>

  <div class="row col l10 s12 m12 xl10 main p-t-0">
    <div class="container9 content">
      <h4 class="hello-h4" *ngIf="user"> Hello, {{user.GivenName ? user.GivenName: user.FirstName}}</h4>
      <p class="date-p">Today is {{ currentDate }}</p>

      <div class="row" *ngIf="adminMenus">
        <ng-container *ngFor="let item of adminMenus">
          <div class="col s6 m4 l3" *ngIf="item.isEnabled && item.name !== 'Logout'" (click)="itemClicked(item)"
            [ngClass]="{'disabledDiv modal-trigger': !getPermission(item.name)}" style="cursor: pointer;"
            data-target="permissionModal">
            <div class="card-panel hoverable" [ngStyle]="{'height': 'auto'}">
              <div class="row center-align">
                <div class="icon-wrapper">
                  <i class="material-symbols-outlined custom-icon white-text bold-300"
                    [ngStyle]="{'background-color': themeColor}">{{ item.icon }}</i>
                </div>
                <br />
                <span class="truncate black-text">{{ item.displayName }}</span>
              </div>
            </div>
          </div>
        </ng-container>

        <div class="col s6 m4 l4 modal-trigger" style="cursor: pointer;" data-target="switchModal"
          *ngIf="isChiefOrCouncillor">
          <div class="card-panel hoverable b-n-1" [ngStyle]="{'height': 'auto'}">
            <div class="row center-align">
              <div class="icon-wrapper"> <i class="material-icons custom-icon white-text"
                  [ngStyle]="{'background-color' : themeColor}">sync</i></div>
              <br />
              <strong class="truncate black-text">Switch Account</strong>
            </div>
          </div>
        </div>

        <div class="hide-on-large-only">
          <div class="col s6 m4 l4" style="cursor:pointer;" (click)="signOut()">
            <div class="card-panel hoverable b-n-1 bottom-content" [ngStyle]="{'height': 'auto'}">
              <div class="row center-align">
                <div class="icon-wrapper"> <i class="material-icons custom-icon white-text"
                    [ngStyle]="{'background-color' : themeColor}">logout</i></div>
                <br />
                <strong class="truncate black-text">Logout</strong>
              </div>
            </div>
          </div>
        </div>
      </div>


      <!----------------------------------------------- stat -------------------------------------------------------->
      <div class="row hide-on-med-and-down center p-l-1 stat" *ngIf="birthsModel || gendersModel">
        <div class="title-nation">
          <h6 class="nation-name">Stats</h6>
        </div>
        <div class="col l6 card-panel white" *ngIf="birthsModel">
          <p class="graph-title">Gender Distribution </p>
          <div style="display: block;">
            <canvas baseChart width="10vw" height="8.5vh" [data]="lineChartData" [labels]="lineChartLabels"
              [options]="lineChartOptions" [colors]="lineChartColors" [legend]="lineChartLegend"
              [chartType]="lineChartType" [plugins]="lineChartPlugins">
            </canvas>
          </div>
        </div>
        <div class="col l1"></div>
        <div class="col l5 card-panel white" *ngIf="pieChartData">
          <p class="graph-title">Gender Distribution </p>
          <canvas baseChart width="10vw" height="9vh" [type]="'pie'" [datasets]="pieChartData" [labels]="pieChartLabels"
            [options]="pieChartOptions" [colors]="pieChartColors" [legend]="pieChartLegend" [plugins]="pieChartPlugins" [chartType]="pieChartType">
          </canvas>
        </div>
      </div>

      <!----------------------------------------------- Modals -------------------------------------------------------->

      <div id="permissionModal" class="modal center b-n-1">
        <div class="modal-content">
          <i class="material-icons right modal-close">close</i><br />
          <h6 class="title-section2 bold-600 black-text">Unauthorized</h6>
          <p class="sub-title-section bold-100">Sorry, but you don't have permission to access this page.</p>
          <!-- <p class="sub-title-section bold-100">You do not have the official permission or approval to access any of
            the menus.</p> -->
          <img src="https://img.icons8.com/dotty/344/id-not-verified.png" height="150px" />
          <p class="sub-title-section bold-100">Please Contanct your Administrator to gain access.</p>
        </div>
      </div>

      <div id="switchModal" class="modal center b-n-1">
        <div class="modal-content">
          <i class="material-icons right modal-close">close</i><br />
          <img src="https://img.icons8.com/ultraviolet/120/000000/change-user-male.png" class="p-t-3" />
          <h6 class="title-section2 bold-600 black-text">Switch Account </h6>
          <p class="sub-title-section bold-100">Are you sure you want to switch to Member Account ? </p>
          <a class="modal-close  white-text  btn-flat col l8 s8 push-l2 push-s2 "
            [ngStyle]="{ 'background-color' : themeColor}" (click)="switchAccount()">Switch</a>
        </div>
      </div>
    </div>
  </div>
</div>