import { Component, OnInit } from '@angular/core';
import SignaturePad from 'signature_pad';
import { FormArray, UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { toast } from 'materialize-css';
import { PseContractModel } from 'src/app/models/PseContractModel';
import { MemberService } from 'src/app/services/api/member.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { PseApplicationService } from 'src/app/services/api/pse-application.service';
import { Location } from '@angular/common';
import { IsetsNationService } from 'src/app/services/api/isets/isets-nation.service';
import { PseContractService } from 'src/app/services/api/pse-contract.service';
import {IAngularMyDpOptions, IMyDateModel} from 'angular-mydatepicker';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-pse-contract',
  templateUrl: './pse-contract.component.html',
  styleUrls: ['./pse-contract.component.css']
})

export class PseContractComponent implements OnInit {

  nationofUse = environment.firstNation.name;
  nationName = environment.firstNation.displayName;
  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  //provinceOfUse = environment.firstNation.province;

  public dateMask = [/[0-3]/, /[0-9]/, '/', /[0-1]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  public dateformat = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;

  step=1;
  studentName='';
  start_date='';
  end_date ='';
  band_address='';
  applicantID=0;
  pse_app_id=0;
  
  //signature
  signed = false;
  canvas: any;
  signaturePad: SignaturePad;
  showSignaturePad = false;
  contractForm: UntypedFormGroup;

  contractModel: PseContractModel;
  
  myDpOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'dd/mm/yyyy',  
    markCurrentDay: true,
  };

  constructor(private fb: UntypedFormBuilder, private memberService: MemberService, private location: Location,
    private authService: AuthService, private pseService: PseApplicationService, 
    private nationService: IsetsNationService, private pseContractService: PseContractService  ) { }

  ngOnInit(): void {

    if (localStorage.getItem('applicantID')) {
      this.applicantID = Number(localStorage.getItem('applicantID'));
    } else {
      this.applicantID = this.authService.getCurrentApplicantID();
    }
    if (this.applicantID==0) {  this.authService.signOut();  }

  
    this.contractForm = this.fb.group({
      student: ['', [Validators.required]],
      signature: ['', [Validators.required]],
      date: ['', [Validators.required]],
    });

    this.nationService.getNationInfo().subscribe(x => { 
        let nation = x[0];
        
        if (nation.ADDR) {this.band_address = nation.ADDR;}
        if (nation.City) {this.band_address = this.band_address + ' ' + nation.City;}
        if (nation.Prov) {this.band_address = this.band_address + ' ' + nation.Prov;}
        if (nation.Postal) {this.band_address = this.band_address + ' ' + nation.Postal;}
        

        setTimeout(() => {
          $('select').formSelect();
          M.updateTextFields();
        }, 25);
    
      this.pseContractService.getClientInfo(this.applicantID).subscribe(x => {
        if (x) {
          let member = x[0];
          this.studentName = member.GivenName + ' '+ member.LastName;
          this.contractForm.patchValue({ 'student': this.studentName });
          
          setTimeout(() => {
            $('select').formSelect();
            M.updateTextFields();
          }, 25);

          if (member.sponsor_from_date) {this.start_date = this.formatDates(member.sponsor_from_date) };
          if (member.sponsor_to_date) {this.end_date = this.formatDates(member.sponsor_to_date) };
        };
      });
    });

    this.contractModel = new PseContractModel();

    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);

  }

  formatDates(dateValue) {
    if (dateValue) {
        //   //1987-07-19T00:00:00
        let dateArray = dateValue.split('-');
        let year = dateArray[0];
        let month = dateArray[1] ;   //- 1;
        let day = dateArray[2].substring(0, 2);
        return day + '/' + month + '/' + year;
    }else{
      return '';
    }
  }

  //signature
  get signature() { return this.contractForm.get('signature'); }
  get date() { return this.contractForm.get('date'); }

  submitContract(values) {
    this.contractModel.applicantID = this.applicantID;
    this.contractModel.student = values.student;
    this.contractModel.signature = this.signaturePad.toDataURL().replace('data:image/png;base64,', '');
    //this.contractModel.date =  this.changeDates(values.date).toDateString(); 
    this.contractModel.date = values.date.singleDate.jsDate.toDateString();
    this.contractModel.app_id = this.pse_app_id;
    
    //console.log("Model: ", this.contractModel);

    this.pseContractService.postContract(this.contractModel).subscribe(x => {
      toast({ html: 'Contract Successfully Signed!', classes: 'green' });
      this.backClicked();
    });

  }

  submitSignature(values: any) { 
    if (!this.signaturePad || this.signaturePad.isEmpty()) {
       toast({ html: 'Please Sign the Contract !', classes: 'red' });
       return;
     } 
   }

  sign() {
    this.step = 2;
    this.showSignaturePad = true;
    setTimeout(() => {
      this.canvas = document.querySelector('canvas');
      const parentWidth = $(this.canvas).parent().outerWidth();
      this.canvas.setAttribute('width', parentWidth);
      this.canvas.setAttribute('height', '175px');
      this.signaturePad = new SignaturePad(this.canvas, {
        backgroundColor: 'rgb(255,255,255)'
      });
    }, 50);
  }

  approveSignature() {
    if (this.signaturePad.isEmpty()) {
      toast({ html: 'Please Sign the Contract!', classes: 'red' });
      return;
    } else {
      const dataUrl = this.signaturePad.toDataURL();
      this.signature.patchValue(dataUrl);
      this.signed = true;
      this.showSignaturePad = false;
      toast({ html: 'Constract Signed', classes: 'green' });
      
      this.step = 1;
      this.showSignaturePad = false;

      setTimeout(() => {
        $('select').formSelect();
        M.updateTextFields();
      }, 25);
    }
  }

  clearSignature() {
    this.signed = false;
    this.signaturePad.clear();
    this.signature.reset();
  }

  onBack(){
    this.step = 1;
    this.showSignaturePad = false;
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }

  backClicked(){
    this.location.back();
  }

  onDateChange(event: IMyDateModel): void { 
    this.contractForm.patchValue({ 'date': event.singleDate.formatted }); 
  }

}
