<div class="body-h">
  <div class="header-setting b-m-2">
    <h4>  Edit Activity </h4>
  </div>

  <main class="StickyContent white row">
    <div id="step0" class="col s12 l11">
      <form (ngSubmit)="submitForm(editActivityForm.value)" [formGroup]="editActivityForm">
        <div class="row card-panel">

          <div class="input-field class col s12 l6">
            <div class="label-input">Activity or Hobby Name <strong class="red-text">*</strong></div>
            <input formControlName="Activity" id="Activity" type="text" maxlength="140" autocomplete="off" required
              class="validate" placeholder="Activity or Hobby Name">
            <div *ngIf="(Activity.dirty || Activity.touched) && Activity.invalid">
              <small *ngIf="Activity.errors.required" class="red-text">The Activity Is Required!
              </small>
            </div>
          </div>

          <div class="input-field class col s12 l6">
            <div class="label-input"> Description <strong class="red-text">*</strong></div>
            <input formControlName="description" id="description" type="text" maxlength="250" autocomplete="off"
              required class="validate" placeholder="description">
            <div *ngIf="(description.dirty || description.touched) && description.invalid">
              <small *ngIf="description.errors.required" class="red-text"> Please enter the description.
              </small>
            </div>
          </div>
        </div>

        <div class="section"></div>

        <div class="col s12">
          <button class='at-btn col s12 btn waves-effect success' style="margin-bottom: 15px;" type="submit"
            [disabled]="!editActivityForm.valid">
            <i class="material-icons right">add_circle</i> Update Activity
          </button>
          <button data-target="modal1" class="col s12 btn modal-trigger red " style="margin-bottom: 15px;">Remove
            Activity</button>
          <button type="button" [ngClass]="'col s12 btn waves-effect ' + themeColor " (click)="backClicked()">
            <i class="material-icons left">keyboard_arrow_left</i> Back To Activity List
          </button>
        </div>
      </form>
    </div>
  </main>
</div>

<div id="modal1" class="modal">
  <div class="modal-content">
    <h5>Delete Activity</h5>
    <strong> Do You Want to Remove This Activity ? </strong>
  </div>
  <div class="modal-footer">
    <button type="button" (click)="DeleteActivity()" class="btn waves-effect blue white-text"
      style="margin-right: 15px;"> Yes
    </button>
    <a class="modal-close amber darken-4 white-text btn-flat white-text">No</a>
  </div>
</div>