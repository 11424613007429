import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-auth-main',
  templateUrl: './auth-main.component.html',
  styleUrls: ['./auth-main.component.css']
})

export class AuthMainComponent implements OnInit {

  bodyBgColor: any;
  defaultThemeColor = environment.appTheme.themeColor;
  defaultBgColor = '#ffffff';
  setting :any;
  themeColor :any;

  constructor( public appSettingsService: AppSettingsService, private router: Router) { 
    this.appSettingsService.getAppSetting().valueChanges().subscribe(
      appSettings => {
        if (appSettings && appSettings.length > 0) {
          this.setting = appSettings[0];
          this.themeColor = this.setting.themeColor ? this.setting.themeColor : this.defaultThemeColor;
          this.bodyBgColor = this.setting.bodyBgColor ? this.setting.bodyBgColor : this.defaultBgColor;
        } else {
          this.themeColor = this.defaultThemeColor;
          this.bodyBgColor = this.defaultBgColor;
        }
      });
  }

  ngOnInit() {
  }

  shouldApplyContainerClass(): boolean {
    // Check the current route to decide whether to apply the "container" class.
    // For example, if you don't want it applied on the login page, you can do this:
    return !this.router.url.includes('/login');
  }

}
