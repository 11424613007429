import { Component, OnInit } from '@angular/core';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-app-store',
  templateUrl: './app-store.component.html',
  styleUrls: ['./app-store.component.css']
})

export class AppStoreComponent implements OnInit {

  defaultThemeColor = environment.appTheme.themeColor;
  model: any;
  themeColor: any;
  appStoreSection:any;
  mobilePhoneImg = "../../assets/img/backgrounds/login-back.jpeg";

  constructor(private appSettingService: AppSettingsService) {
    this.appSettingService.getAppSetting().valueChanges().subscribe(
      appSettings => {
        if (appSettings) {
          if (appSettings.length > 0) {
            this.model = appSettings[0];
            this.themeColor = this.model.themeColor ? this.model.themeColor : this.defaultThemeColor;
          }
        }
      });
   }

  ngOnInit(): void {
    this.appSettingService.getHomePageSettings().valueChanges().subscribe(hp => {
      if (hp) {
        if (hp.length > 0) {
          this.model = hp[0];
          const appStoreSection = this.model.sections.filter(s => s.name === 'AppStore' && s.isEnabled);
          if(appStoreSection){
            this.appStoreSection = appStoreSection[0];
          }
        }
      }
    }); 
  }

}
