import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { toast } from 'materialize-css';
import { DepartmentNewsService } from 'src/app/services/firebase/department-news.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/firebase/auth.service';

@Component({
  selector: 'app-edit-news',
  templateUrl: './edit-news.component.html',
  styleUrls: ['./edit-news.component.css']
})

export class EditNewsComponent implements OnInit {

  adminThemeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  myModel: any;
  id: any;
  isManager = false;
  valueEmittedFromChildComponent: any;
  canManageNews = false;

  constructor(
    private authService: AuthService,
    private newsService: DepartmentNewsService,
    private location: Location,
    private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });

    this.isManager = this.authService.getGlobalUser().isManager;
    this.canManageNews = this.authService.getGlobalUser().manageDepartmentNews;

    if (this.id) {
      this.newsService.getDepNewsByID(this.id).valueChanges().subscribe(obj => {
        if (obj) {
          this.myModel = obj;
        }
      });
    }
  }

  updateEventHandler(valueEmitted) {
    this.valueEmittedFromChildComponent = valueEmitted;
    if (this.valueEmittedFromChildComponent === true) {
      toast({ html: 'Department News Successfully Updated!', classes: 'green' });
      this.backClicked();
    }
  }

  archiveEventHandler(valueEmitted) {
    this.valueEmittedFromChildComponent = valueEmitted;
    if (this.valueEmittedFromChildComponent === true) {
      toast({ html: 'Department News Successfully Archived!', classes: 'green' });
      this.backClicked();
    }
  }

  unArchiveEventHandler(valueEmitted) {
    this.valueEmittedFromChildComponent = valueEmitted;
    if (this.valueEmittedFromChildComponent === true) {
      toast({ html: 'Department News Successfully Published!', classes: 'green' });
      this.backClicked();
    }
  }

  deleteEventHandler(valueEmitted) {
    this.valueEmittedFromChildComponent = valueEmitted;
    if (this.valueEmittedFromChildComponent === true) {
      toast({ html: 'Department News Successfully Deleted!', classes: 'green' });
      this.backClicked();
    }
  }

  backClicked() {
    this.location.back();
  }

}