<div class="container-pi">
    <div class="">
        <h4 class="title-section1 title-member  icon-texts"><span class="material-symbols-outlined back-icon p-r-1"
            (click)=" backClicked()">
            arrow_circle_left
        </span>My Service Plan</h4>
    </div>

    <main class="StickyContent white row" *ngIf="sp_items">
        <div class="col s12 m10 l10">
            <app-firebase-loading *ngIf="!dtOptions"></app-firebase-loading>
            <div class="row card-panel white darken-2" *ngIf="hasRows">
                <div style="overflow-x:auto;">
                    <table datatable [dtOptions]="dtOptions" class="center">
                        <thead>
                            <tr>
                                 <th>ID</th>
                                <th>Item</th>
                                <!-- <th>Client Responbility</th>           
                            <th>Counselor Responbility</th>  -->
                                <th>Start Date</th>
                                <th>Finish Date</th>
                                <th>Outcome Obtained</th>
                                <!--  <th>Date Outcome Obtained</th>
                            <th>Comments</th> -->
                            </tr>
                        </thead>

                        <tbody>
                            <tr *ngFor="let m of sp_items">
                                <td> <a [routerLink]="['/member/member-main-home/service-plan-detail/', m.item_id]">{{
                                        m.item_id}}
                                    </a></td>
                                <!--  <td>{{ m.Cost | currency}} </td>  -->
                                <td>
                                    <!-- 
                                <div *ngIf="m.sub_goal === 1"> <span class="green-text"> Non-Financial Support</span> </div>
                                <div *ngIf="m.sub_goal === 2"> <span class="green-text"> Employment</span> </div>
                                <div *ngIf="m.sub_goal === 3"> <span class="green-text"> Career Planning</span> </div>
                                <div *ngIf="m.sub_goal === 4"> <span class="green-text"> Training</span> </div>
                                <div *ngIf="m.sub_goal === 5"> <span class="green-text"> Information</span> </div>
                                <div *ngIf="m.sub_goal === 6"> <span class="green-text"> Financial Independence</span> </div>
                                -->
                                    <div *ngIf="m.sub_goal === 1"> <span> <a
                                                [routerLink]="['/member/member-main-home/service-plan-detail/', m.item_id]">Non-Financial
                                                Support </a></span> </div>
                                    <div *ngIf="m.sub_goal === 2"> <span> <a
                                                [routerLink]="['/member/member-main-home/service-plan-detail/', m.item_id]">Employment
                                            </a></span> </div>
                                    <div *ngIf="m.sub_goal === 3"> <span> <a
                                                [routerLink]="['/member/member-main-home/service-plan-detail/', m.item_id]">Career
                                                Planning </a></span> </div>
                                    <div *ngIf="m.sub_goal === 4"> <span> <a
                                                [routerLink]="['/member/member-main-home/service-plan-detail/', m.item_id]">Training
                                            </a></span> </div>
                                    <div *ngIf="m.sub_goal === 5"> <span> <a
                                                [routerLink]="['/member/member-main-home/service-plan-detail/', m.item_id]">Information
                                            </a></span> </div>
                                    <div *ngIf="m.sub_goal === 6"> <span> <a
                                                [routerLink]="['/member/member-main-home/service-plan-detail/', m.item_id]">Financial
                                                Independence </a></span> </div>
                                </td>
                                <!-- 
                            <td>{{ m.client_responsibility}} </td>
                            <td>{{ m.cm_responsibility}} </td>   -->
                                <td>{{ m.start_date_planned | date: 'dd/MMM/yyyy' }} </td>
                                <td>{{ m.finish_date_planned | date: 'dd/MMM/yyyy' }} </td>

                                <td>
                                    <div *ngIf="m.outcome_obtained"> <span class="green-text">
                                            <i class="material-icons">check_circle_outline</i> Yes </span> </div>
                                    <div *ngIf="!m.outcome_obtained"> <span class="red-text text-darken-1">
                                            <i class="material-icons">highlight_off</i> No </span> </div>
                                </td>
                                <!--   <td>{{ m.outcome_obtained_date | date: 'dd/MMM/yyyy' }} </td> -->
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <div class="card-panel orange darken-2" *ngIf="!hasRows">
                <h6 class="center white-text"><i class="material-icons white-text left">info</i>
                    <h5> You dont have any Service Plan.</h5>
                    <h5 class="red-text">Thank You!</h5>
                </h6>
            </div>
        </div>
    </main>

    <div class="row col s12">
        <div class="col s12 m10 l10">
            <a [ngClass]="'col s12 btn waves-effect ' + themeColor" (click)="backClicked()"><i
                    class="material-icons left">house</i> Back To My Services</a>
        </div>
    </div>
</div>