import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { NominationModel } from 'src/app/models/NominationModel';

@Injectable({
  providedIn: 'root'
})

export class NominationService {

  constructor(private db: AngularFirestore) { }

  createNewNomination(model: NominationModel) {
    const newContent = {
      id: this.db.createId(),
      name: model.name,
      electionId: model.electionId,
      startDate: model.startDate,
      endDate: model.endDate,
      numberOfNominees: model.numberOfNominees,
      nominees: model.nominees
    }
    return this.db.collection('Nomination').doc(newContent.id).set(newContent);
  }

  updateNomination(model: NominationModel) {
    const newContent = {
      id: model.id,
      name: model.name,
      electionId: model.electionId,
      startDate: model.startDate,
      endDate: model.endDate,
      numberOfNominees: model.numberOfNominees,
      nominees: model.nominees
    }
    return this.db.collection('Nomination').doc(model.id).update(newContent);
  }

  getNominationList() {
    return this.db.collection('Nomination');
  }

  getNominationbyId(id: string) {
    return this.db.doc('Nomination/' + id);
  }

  getNominationbyElectionId(electionId: string) {
    return this.db.collection('Nomination', ref => ref.where('electionId', '==', electionId));
  }

  deleteNominationById(id: string) {
    return this.db.collection('Nomination').doc(id).delete();;
  }
  
}