<div class="row container">
    <div class="row card white">
        <div class="row col s12 center">
            <h5 class="cart-title center-align"><strong> Release Form </strong> </h5>
        </div>

        <div class="col s12" style="text-align: justify;">
            <p>I, <span class="blue-text">{{studentName}}</span> authorize <span class="blue-text">{{Institute}}</span>
                to relase
                to the Employment Facilitator who has been assigned my file information regarding my academic standing
                and
                attendance records (attendance if possible) in the program <span class="blue-text">{{Program}}</span>
                During
                the approved period of study from: <span class="blue-text">{{start_date}}</span> to <span
                    class="blue-text">{{end_date}}</span>
            </p>
        </div>
    </div>

    <form *ngIf="step == 1 && !showSignaturePad" class="row col s12 card white" [formGroup]="releaseForm"
        (ngSubmit)="submitReleaseForm(releaseForm.value)">
        <div class="row card-content">

            <div class="input-field col s12">
                <input id="student" type="text" formControlName="student" class="validate"
                    style="text-transform: capitalize;">
                <label for="student">Student Name<span class="red-text">*</span></label>
            </div>

            <div class="row col s12 m6">
                <p class="col s12">Signature<span class="red-text">*</span></p>
                <a *ngIf="!signed" [ngClass]="'btn col s8 waves-effect ' + themeColor" (click)="sign()"
                    style="margin-top: 10px;">Sign</a>
                <a *ngIf="signed" class="col s4" style="margin-top: 10px;"><i
                        class="material-icons left green-text small">check_circle</i>Signed</a>
                <a *ngIf="signed" class="col s4 pink-text" style="margin-top: 10px;" (click)="clearSignature()"><i
                        class="material-icons left pink-text">clear</i>Clear</a>
            </div>

            <div class="input-field col s12 m6">
                <i class="material-icons prefix blue-text">calendar_today</i>
                <input id="date" formControlName="date" type="text" class="input-box" angular-mydatepicker name="mydate"
                    (click)="dp.toggleCalendar()" [options]="myDpOptions" #dp="angular-mydatepicker"
                    (dateChanged)="onDateChange($event)" />
                <label for="date">Date (dd/mm/yyyy)<span class="red-text">*</span></label>
            </div>
        </div>

        <button class="col s12 m5 l3 btn left waves-effect blue" type="button" style="margin-bottom: 15px"
            (click)="backClicked()"><i class="material-icons left">arrow_back</i>Back
        </button>

        <button class="col s12 m5 offset-m2 l3 btn right waves-effect blue" type="submit"
            [disabled]="!releaseForm.valid" style="margin-bottom: 15px" title="Save">Submit <i
                class="material-icons right">arrow_forward</i></button>

    </form>

    <div *ngIf="step == 2 && showSignaturePad" class="row card white" [formGroup]="releaseForm"
        (ngSubmit)="submitSignature(releaseForm.value)">

        <div class="row s12 card-panel grey lighten-4 center">
            <div class="row">
                <h6 class="blue-text text-darken-2 center"><strong>STUDENT SIGNATURE</strong></h6>
                <canvas></canvas>
            </div>
        </div>

        <div class="section"></div>

        <a class="at-btn btn-flat center green lighten-1 col s12 white-text" (click)="approveSignature()"
            style="margin-bottom: 15px;"><i class="material-icons left">check</i> Sign</a>
        <br>
        <a class="at-btn btn-flat center red lighten-1 col s12 white-text" (click)="clearSignature()"
            style="margin-bottom: 15px;"><i class="material-icons left">loop</i> Clear Signature</a>
        <br>

        <a [ngClass]="'at-btn col s12 btn waves-effect ' + themeColor" (click)="onBack()"><i
                class="material-icons left">arrow_back_ios</i> Back To Previous Page </a>
    </div>
</div>