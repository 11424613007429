<div class="row app-store" *ngIf="appStoreSection" >
    <div class="col s12 l7 m12">
        <div class="container">
            <div class="row col s11 m12 l12 l6 center">
                <div class="header-title-mobileapp ">
                    <h1 [ngStyle]="{'color': appStoreSection.displayNameColor}"> {{appStoreSection.displayName}}</h1>
                    <h7 class="description-appDownload" [innerHTML]="appStoreSection.description">
                    </h7>
                </div>
            </div>

            <div class="col s12 m12 l10 push-l1 center">
                <div class="section"></div>
                <div class="row col s12 m6 l6 ">
                    <a class="app-btn blu white-text flex vert  b-n-1 " [ngStyle]="{'background-color': themeColor} "
                        [href]="appStoreSection.appleStoreUrl">
                        <i class="fa fa-apple" style='font-size:44px'></i>
                        <p style="font-size: 10px; font-family: helvetica;">Available on the <br /> <span
                                style="font-size: 20px; font-family: helvetica;">App Store</span></p>
                    </a>
                </div>
                <div class="row col s12 m6 l6">
                    <a class="app-btn blu  white-text flex vert b-n-1 " [ngStyle]="{'background-color': themeColor} "
                        [href]="appStoreSection.googlePlayUrl">
                        <img src="https://img.icons8.com/ios-filled/40/ffffff/google-play.png" class="p-r-1" alt="Appstore Image"/>
                        <p style="font-size: 10px; font-family: helvetica;">Available on the <br /> <span
                                style="font-size: 20px; font-family: helvetica;">Play Store</span></p>
                    </a>
                </div>
            </div>
        </div>
    </div>

    <div class="image-overlay">
        <div class="col s12 l5 m6 hide-on-med-and-down img-mobile">
            <img src="../../assets/img/mobileapp.png" width=400; height="600" alt="Mobile App Image">
            <!-- <img class="centered2  b-n-3" src="../../assets/img/backgrounds/login-back.jpeg" width=260; height="555"> -->
        </div>
    </div>
</div>