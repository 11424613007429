import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { environment } from 'src/environments/environment';
// import { EmbedVideoService } from 'ngx-embed-video';
import { FireMembersService } from 'src/app/services/firebase/fire-members.service';
import { MessagingService } from 'src/app/services/firebase/messaging.service';
import { MemberService } from 'src/app/services/api/member.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MemberAccountSettingsService } from 'src/app/services/firebase/memberAccountSettings.service';
import { MemberAccountSettingsModel } from 'src/app/models/MemberAccountSettingsModel';
import { TempHousingService } from 'src/app/services/firebase/temp-housing.service';
import { HousingService } from 'src/app/services/api/housing.service';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { StaticContentService } from 'src/app/services/firebase/static-content.service';
import { DepartmentService } from 'src/app/services/firebase/department.service';
import { Subject } from 'rxjs';
import { CalendarDateFormatter, CalendarEvent, CalendarView } from 'angular-calendar';
import { EventsService } from 'src/app/services/firebase/events.service';
import { addDays, endOfDay, isSameDay, startOfDay, subDays } from 'date-fns';
import { StaticContentModel } from 'src/app/models/StaticContentModel';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-member-home',
  templateUrl: './member-home.component.html',
  styleUrls: ['./member-home.component.css']
})

export class MemberHomeComponent implements OnInit {
  [x: string]: any;
  fontColor = environment.appTheme.fontColor;
  nationName = environment.firstNation.displayName;
  nationOfUse = environment.firstNation.name;
  defaultThemeColor = '#000000';
  public phoneNumberMask = ['(', /[0-9]/, /[0-9]/, /[0-9]/, ')',
    ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];

  loading = false;
  themeColor: any;
  buttonType: any;
  isNewUser = false;
  currentUser: any;
  isChiefOrCouncillor = false;
  member: any;
  contactForm: UntypedFormGroup;
  isAdminMember = false;
  isEmployeeMember = false;
  menus: any;
  showDefaultMenu = false;
  user: any;
  userRole: any;
  model: any;
  modell: any[];
  modelll: any;
  @Input() isMember: false;
  @Input() callerForm: string;
  @Input() newsModel: StaticContentModel;


  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  viewDate: Date = new Date();
  refresh: Subject<any> = new Subject();
  activeDayIsOpen = false;
  eventSection: any;
  events: any[];
  countToLoad = 4;

  //*********** Settings *****************************/
  permissions: any;
  settingsModel: any;
  allowChat; allowEmail; allowNomination = false;
  allowNotifications; allowSearch; contactMe; shareLocation; allowContactByEmployer = false;
  allowViewResume = false; allowContactByChiefAndCouncil;
  //************************************************/

  applicantID = 0;
  SocialOK = environment.firstNation.SOCIAL;    //true;
  SHOW_SOCIAL = environment.firstNation.SOCIAL;

  constructor(
    private authService: AuthService,
    private router: Router,
    private appSettingService: AppSettingsService,
    private departmentService: DepartmentService,
    private messagingService: MessagingService,
    private memberService: MemberService,
    private fb: UntypedFormBuilder,
    private settings: MemberAccountSettingsService,
    private tempHousingService: TempHousingService,
    private housingService: HousingService,
    private memberAccountSettingsService: MemberAccountSettingsService,
    public appSettingsService: AppSettingsService,
    private staticContentService: StaticContentService,
    // private embedService: EmbedVideoService,
    private eventService: EventsService,
  ) {
    this.isNewUser = this.authService.isFirstLogin();
    this.themeColor = this.appSettingsService.getAppThemeColor() ? this.appSettingsService.getAppThemeColor() : this.defaultThemeColor;
    this.buttonType = this.appSettingsService.getButtonType() ? this.appSettingsService.getButtonType() : 'rectangularButton';

    this.currentUser = this.authService.getGlobalUser();
  }

  ngOnInit() {
    if (this.isNewUser) {
      let newMemberSettings = new MemberAccountSettingsModel();
      newMemberSettings.memberId = this.authService.currentUser.uid;
      newMemberSettings.permissions = [{ allowChat: true }, { allowEmail: true },
      { allowNomination: true }, { allowNotifications: true }, { allowSearch: true },
      { contactMe: true }, { shareLocation: true }, { allowContactByEmployer: true },
      { allowViewResume: true, allowContactByChiefAndCouncil: true }];
      this.memberAccountSettingsService.createAccountSetting(newMemberSettings);

      // this.settings.getAccoutSetting(this.authService.getGlobalUser().uid).valueChanges().subscribe(
      //   data => {
      //     if (data) {
      //       this.settingsModel = data[0];

      //       this.settingsModel.permissions.forEach(element => {
      //         if (Object.keys(element)[0] == 'shareLocation') {
      //           this.shareLocation = element.shareLocation;
      //         } else if (Object.keys(element)[0] == 'allowEmail') {
      //           this.allowEmail = element.allowEmail;
      //         } else if (Object.keys(element)[0] == 'allowNotifications') {
      //           this.allowNotifications = element.allowNotifications;
      //         } else if (Object.keys(element)[0] == 'allowChat') {
      //           this.allowChat = element.allowChat;
      //         } else if (Object.keys(element)[0] == 'contactMe') {
      //           this.contactMe = element.contactMe;

      //         } else if (Object.keys(element)[0] == 'allowSearch') {
      //           this.allowSearch = element.allowSearch;

      //         } else if (Object.keys(element)[0] == 'allowNomination') {
      //           this.allowNomination = element.allowNomination;

      //         } else if (Object.keys(element)[0] == 'allowContactByEmployer') {
      //           this.allowContactByEmployer = element.allowContactByEmployer;

      //         } else if (Object.keys(element)[0] == 'allowViewResume') {
      //           this.allowViewResume = element.allowViewResume;

      //         } else if (Object.keys(element)[0] == 'allowContactByChiefAndCouncil') {
      //           this.allowContactByChiefAndCouncil = element.allowContactByChiefAndCouncil;
      //         }
      //       });

      //       setTimeout(() => {
      //         if(!localStorage.getItem('isPreferenceSet')) {localStorage.setItem('isPreferenceSet', 'No'); }

      //         $('.tap-target').tapTarget();
      //         $('.modal').modal();
      //         // if (this.isNewUser) {
      //         // $('#welcomeModal').modal('open');
      //         if(localStorage.getItem('isPreferenceSet')) {            
      //           if(localStorage.getItem('isPreferenceSet') === 'No') {
      //             $('#settingModal').modal('open');
      //           }
      //         }

      //         // }       
      //       }, 25);
      //     }
      //   });

    } else {
      setTimeout(() => {
        $('.tap-target').tapTarget();
        $('.modal').modal();
      }, 25);

      this.departmentService.getDepartmentList().valueChanges().subscribe(x => {
        if (x) {
          this.modell = x;
          this.modell.sort((a, b) => (a.index < b.index ? -1 : 1));
          setTimeout(() => {
            $('.collapsible').collapsible();
          }, 25);
        }
      });
    }

    // =================================== news =================================
    this.staticContentService.getContentList().valueChanges().subscribe(x => {
      if (x) {
        this.modelll = x;
        this.modelll = this.modelll.filter(data => data.published === true);

        const userCommunity = this.authService.getGlobalUser().community;
        const uResidency = this.authService.getGlobalUser().residency;
        const userDOB = new Date(this.authService.getGlobalUser().DOB);

        let userResidency = '';
        if (uResidency) { userResidency = 'On Reserve'; }
        if (!uResidency) { userResidency = 'Urban'; }
        let userGender = this.authService.getGlobalUser().Sex;
        if (userGender === 'F') { userGender = 'Female'; }
        if (userGender === 'M') { userGender = 'Male'; }

        if (!this.currentUser?.verified) {
          this.modelll = this.modelll.filter(n => n.visibility === 'Public');
        }

        for (let i = this.modelll.length - 1; i >= 0; i--) {
          if (this.modelll[i].visibility === 'Private') {

            if (this.modelll[i].communityFilter) {
              if (this.modelll[i].communityFilter !== userCommunity) { this.modelll.splice(i, 1); }
            }
            if (this.modelll[i].genderFilter) {
              if (this.modelll[i].genderFilter !== userGender) { this.modelll.splice(i, 1); }
            }
            if (this.modelll[i].residencyFilter) {
              if (this.modelll[i].residencyFilter !== userResidency) { this.modelll.splice(i, 1); }
            }
            if (this.modelll[i].minAgeFilter) {
              const minDOB = new Date(new Date().getFullYear() - this.modelll[i].minAgeFilter, 1, 1);
              if (userDOB > minDOB) { this.model.splice(i, 1); }
            }
            if (this.modelll[i].maxAgeFilter) {
              const maxDOB = new Date(new Date().getFullYear() - this.modelll[i].maxAgeFilter, 1, 1);
              if (userDOB < maxDOB) { this.modelll.splice(i, 1); }
            }
          }
        }

        this.modelll.sort((a, b) => (a.index < b.index ? -1 : 1));
        this.modelll.forEach(el => {
          if (el.type === 'Video') {
            const link = el.videoLink;
            el.videoLink = this.embedService.embed(link, {
              query: { portrait: 0, color: '333' },
              attr: { height: 250 }
            });
            if (el.visibility === undefined) {
              el.visibility = 'Public';
            }
          }
        });
        setTimeout(() => {
          $('.materialboxed').materialbox();
        }, 100);
      }
    });


    // ==================================end news ================================

    $(document).on('click', '.add-button', function () {
      var show = $(this).data('show');
      $(show).removeClass("hide").siblings().addClass("hide");
    });

    if (this.authService.globalUser) {
      if (this.authService.globalUser.isChief || this.authService.globalUser.isCouncillor) {
        this.isChiefOrCouncillor = true;
      }
      if (this.authService.globalUser.role == 'Admin' && this.authService.globalUser.isMember) {
        this.isAdminMember = true;
      }
      if (this.authService.globalUser.role == 'Employee' && this.authService.globalUser.isMember) {
        this.isEmployeeMember = true;
      }
    }

    if (localStorage.getItem('From')) { localStorage.removeItem('From'); }



    if (localStorage.getItem('applicantID')) {
      this.applicantID = Number(localStorage.getItem('applicantID'));
    } else {
      this.applicantID = this.authService.getCurrentApplicantID();
    }

    // this.memberService.getMember(this.applicantID).subscribe(data => {
    //   if (data) {

    //     const tmp = data[0];
    //     console.log("Member: ", tmp);
    //     if (tmp) {
    //       if (tmp.PWA_invite_status && tmp.PWA_invite_status == 'Authenticated') {
    //         localStorage.setItem('SocialOK', 'YES');
    //         this.SocialOK = true;
    //       } else {
    //         localStorage.setItem('SocialOK', 'NO');
    //         this.SocialOK = false;
    //       }


    //       if (tmp.PSE_PWA_invite_status && tmp.PSE_PWA_invite_status == 'Authenticated') {
    //         localStorage.setItem('PSEOK', 'YES');
    //       } else {
    //         localStorage.setItem('PSEOK', 'NO');     
    //       }

    //       console.log("ISET_PWA_invite_status: ", tmp.ISETS_PWA_invite_status);
    //       if (tmp.ISETS_PWA_invite_status && tmp.ISETS_PWA_invite_status == 'Authenticated') {
    //         localStorage.setItem('ISETSOK', 'YES');
    //         console.log("ISETOK    YES");
    //       } else {
    //         localStorage.setItem('ISETSOK', 'NO');
    //         console.log("ISETOK    NO");
    //       }
    //     }
    //   }
    // });

    // this.appSettingsService.getMemberMenus().valueChanges().subscribe(menus => {
    //   if (menus && menus.length > 0) {
    //     this.menus = menus;
    //     this.menus = this.menus.sort((a, b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0));
    //     this.showDefaultMenu = false;
    //   } else {
    //     this.menus = this.defaultMenus.sort((a, b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0));
    //     this.showDefaultMenu = true;
    //   }

    //   this.loading = false;
    // });
    //new addition for testing================================ end


    //new addition for testing================================ end

    this.appSettingService.getHomePageSettings().valueChanges().subscribe(hp => {
      if (hp) {
        if (hp.length < this.countToLoad) {
          this.model = hp[0];
          const eventSection = this.model.sections.filter(s => s.name === 'Events' && s.isEnabled);
          if (eventSection) {
            this.eventSection = eventSection[0];
            this.eventService.getPublicEventsList().valueChanges().subscribe(events => {
              if (events) {
                // this.events = events;
                this.events = events;

                // this.eventService.getLimitedContentList(parseInt(eventSection[0].displayLimit))
              }
            });
          }
        }
      }

      // this.router.navigate(['member/member-main-home/member-home']);
    });


    if (this.model) { this.fetchEvents(this.model); this.refresh.next(null); }

    setTimeout(() => {
      $('.tabs').tabs();
    }, 25);

    if (!this.SHOW_SOCIAL) {  ///////////////////////
      //console.log("hello merci",this.SHOW_SOCIAL);
      //this.myservicesClicked();
      this.router.navigate(['member/member-main-home/member-home']);
      this.loading = false;
    } else {
      this.router.navigate(['member/member-main-home/member-home']);
      this.loading = false;
    }
  }

  public getInitials(depName: any) {
    if (depName) {
      depName = depName.replace(/<(?:.|\n)*?>/gm, '');
      return depName.substring(0, 1);
    } else { return ''; }
  }

  approvePreferences() {
    localStorage.setItem('isPreferenceSet', 'Yes');
    $('#settingModal').modal('close');
    let isPreferenceChanged = false;
    if (!this.allowEmail) isPreferenceChanged = true;
    if (!this.allowNomination) isPreferenceChanged = true;
    if (!this.allowNotifications) isPreferenceChanged = true;
    if (!this.allowSearch) isPreferenceChanged = true;
    if (!this.contactMe) isPreferenceChanged = true;
    if (!this.allowContactByEmployer) isPreferenceChanged = true;
    if (!this.shareLocation) isPreferenceChanged = true;
    if (!this.allowViewResume) isPreferenceChanged = true;
    if (!this.allowContactByChiefAndCouncil) isPreferenceChanged = true;

    if (!isPreferenceChanged) {
      let model = new MemberAccountSettingsModel();
      model.id = this.settingsModel.id;
      model.memberId = this.authService.getGlobalUser().uid;
      let permissions = [{ allowChat: this.allowChat },
      { allowEmail: this.allowEmail },
      { allowNomination: this.allowNomination },
      { allowNotifications: this.allowNotifications },
      { allowSearch: this.allowSearch },
      { contactMe: this.contactMe },
      { shareLocation: this.shareLocation },
      { allowContactByEmployer: this.allowContactByEmployer },
      { allowViewResume: this.allowViewResume },
      { allowContactByChiefAndCouncil: this.allowContactByChiefAndCouncil },
      ];
      model.permissions = permissions;
      this.settings.updateAccountSetting(model).then(x => {
        M.toast({ html: 'Preference Successfully Updated!', classes: 'green' });
        $('#prefConfirmationModal').modal('open');
      });
    } else {
      $('#prefConfirmationModal').modal('open');
    }

  }

  updateNumber(val: any) {
    $('#MobileModal').modal('close');
    this.memberService.updateCellPhone(val.cell, this.authService.getGlobalUser().applicantID)
      .subscribe(up => {
        if (up) {
          M.toast({ html: 'Mobile Number Successfully Updated.', classes: 'green' });
        }
      });
  }

  myservicesClicked() {
    // this.get_invitation_status();

    localStorage.setItem('From', 'MyServices');
    this.appSettingService.updateFromMenu('MyServices');
    this.router.navigate(['member/member-main-home/member-home-sub-Menu']);
  }

  switchAccount() {
    if (this.isEmployeeMember) {
      this.router.navigate(['/employee/home']);
    } else {
      this.router.navigate(['/admin/dashboard']);
    }
  }

  editDepartmentData(event: Event, id?: any) {
    event.preventDefault();
    this.router.navigate(['/member/manage-department', id]);
  }

  signOut() {
    this.authService.signOut();
    this.router.navigate(['/auth/login']);
  }

  //events
  fetchEvents(events: any[]) {

    events.forEach(element => {
      // console.log(moment(startOfDay(new Date(element.eventDate)), 'YYYY-MM-DD HH:mm').toDate());
      // console.log(moment(endOfDay(addDays(new Date(element.expiryDate), -1)), 'YYYY-MM-DD HH:mm').toDate());
      // console.log(moment(new Date(element.eventDate), 'YYYY-MM-DD HH:mm').toLocaleString());
      // console.log(startOfDay(new Date(element.eventDate).getTimezoneOffset()));

      const event = {
        eventDate: element.eventDate,
        eventEnd: element.eventDate,
        id: element.id,
        title: element.title
      };

      this.events.push({
        start: new Date(element.eventDate),
        end: addDays(new Date(element.expiryDate), -1),
        title: element.title,
        allDay: true,
        meta: {
          event,
        },
      });
    });
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (
      (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
      events.length === 0
    ) {
      this.activeDayIsOpen = false;
    } else {
      this.activeDayIsOpen = true;
    }
    this.viewDate = date;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  eventClicked({ event }: { event: CalendarEvent }): void {
    if (this.isMember) {
      this.router.navigate(['/member/event-detail', event.meta.event.id]);
    } else {
      this.router.navigate(['/events-detail', event.meta.event.id]);
    }
  }

  truncateHTML(text: string, limit: string): string {
    const charlimit = +limit;
    if (!text || text.length <= charlimit) {
      return text.replace(/<(?:.|\n)*?>/gm, '').replace(/&nbsp;/gm, ' ');
    }
    return text.replace(/<(?:.|\n)*?>/gm, '').replace(/&nbsp;/gm, ' ').substring(0, charlimit) + '...';
  }

  closeModal() {
    $('.modal').modal('close');
  }

  // get_invitation_status() {
  //   this.memberService.getMember(this.applicantID).subscribe(data => {
  //     if (data) {
  //       const tmp = data[0];
  //       if (tmp) {
  //         if (tmp.PWA_invite_status && tmp.PWA_invite_status == 'Authenticated') {
  //           localStorage.setItem('SocialOK', 'YES');
  //           this.SocialOK = true;
  //         } else {
  //           localStorage.setItem('SocialOK', 'NO');
  //           this.SocialOK = false;
  //         }
  //         // if (tmp.PSE_PWA_invite_status && tmp.PSE_PWA_invite_status == 'Authenticated') {
  //         //   localStorage.setItem('PSEOK', 'YES');
  //         // } else {
  //         //   localStorage.setItem('PSEOK', 'NO');
  //         // }

  //         // if (tmp.ISETS_PWA_invite_status && tmp.ISETS_PWA_invite_status == 'Authenticated') {
  //         //   localStorage.setItem('ISETSOK', 'YES');
  //         // } else {
  //         //   localStorage.setItem('ISETSOK', 'NO');
  //         // }
  //       }
  //       localStorage.setItem('From', 'MyServices');
  //       this.appSettingService.updateFromMenu('MyServices');
  //       this.router.navigate(['member/member-main-home/member-home-sub-Menu']);
  //     }
  //   });
  //   setTimeout(() => {
  //     $('select').formSelect();
  //     M.updateTextFields();
  //   }, 25);
  // }

}