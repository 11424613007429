<div class="col s12">
    <div class="body-h">

        <div class="header-setting b-m-2">
            <div class="col l12 m12 s12">
                <h4 class="">IA Income Support Survey</h4>

                <p class="bold-100 sub-title-section3">This is the menu that the general public will have
                    access to on your mobile app</p>
            </div>
        </div>

        <main class="StickyContent white ">

            <div class="row col s12" *ngIf="showSurvey">
                <div class="col l1"></div>
                <div class="col s12 l10">
                    <app-lrrcn-applications (onBackToSearch)="backToSearch($event)" [memberId]="applicantID">
                    </app-lrrcn-applications>
                </div>
                <div class="col l1"></div>
            </div>

            <div class="row col s12" *ngIf="!showSurvey">
                <div class="modal-content">
                    <h4 class="sub-title-section2 bold-800">Search a Member to Fill Out a Survey For</h4>

                    <form autocomplete="off" [formGroup]="searchMemberForm" novalidate>
                        <div class="row card-panel white darken-2" *ngIf="showSearch">
                            <div class="row">
                                <div class="input-field col s12 m6">
                                    <div class="label-input"> Last Name</div>
                                    <input id="lastName" type="text" formControlName='lastName' class="validate"
                                        autocomplete="off" placeholder="Last Name" />
                                </div>
                                <div class="input-field col s12 m6">
                                    <div class="label-input"> First Name</div>
                                    <input id="firstName" type="text" formControlName='firstName' class="validate"
                                        autocomplete="off" placeholder="First Name" />
                                </div>
                            </div>
                        </div>

                        <div class="row card-panel white darken-2" *ngIf="showSearchTable">
                            <div class="card-body">
                                <div class="table-responsive">
                                    <table class="row-border hover notification-table centered striped" width="100%">
                                        <thead>
                                            <tr>

                                                <th>Last Name</th>
                                                <th>First Name</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let item of memberListData; let i = index"
                                                class="memberListData">

                                                <td>{{item?.LastName}}</td>
                                                <td>{{item?.GivenName}}</td>
                                                <td><button class="btn-small"
                                                        (click)="selectMember(item)">Select</button></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="!searchHasRecord" class="row card-panel red lighten-1">
                            <h6 class="white-text"> <strong>
                                    No Member Found In The DataBase.</strong>
                            </h6>
                        </div>
                    </form>

                    <button *ngIf="showSearch" title="Search" (click)="onSearchMembers()"
                        [disabled]="searchMemberForm.get('firstName').invalid && searchMemberForm.get('lastName').invalid"
                        class="col s12 btn waves-effect green" style="margin-bottom: 15px;">
                        <i class="material-icons right">cloud_upload</i> Search Member
                    </button>

                    <button title="Search" (click)="backClicked()" [ngClass]="'col s12 btn waves-effect ' + themeColor"
                        style="margin-bottom: 15px;">
                        <i class="material-icons left">arrow_back_ios</i> Back
                    </button>
                </div>
            </div>
            
        </main>
    </div>
</div>