import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { EventsService } from 'src/app/services/firebase/events.service';
import { environment } from 'src/environments/environment';
import { CalendarEvent, CalendarView } from 'angular-calendar';
import { Subject } from 'rxjs';
import { Location } from '@angular/common';
import { addDays, endOfDay, isSameDay, startOfDay, subDays } from 'date-fns';
import { AuthService } from 'src/app/services/firebase/auth.service';


declare var $: any;

@Component({
  selector: 'app-member-events-callendar',
  templateUrl: './member-events-callendar.component.html',
  styleUrls: ['./member-events-callendar.component.css']
})
export class MemberEventsCallendarComponent implements OnInit {
  model: any;
  themeColor = environment.appTheme.themeColor;
  isMember = true;
  defaultThemeColor = environment.appTheme.themeColor;
  globalUser: any;

  view: CalendarView = CalendarView.Month;
  CalendarView = CalendarView;
  //events: CalendarEvent[] = [];
  viewDate: Date = new Date();
  refresh: Subject<any> = new Subject();
  activeDayIsOpen = false;
  eventSection: any;
  events: any[];

  constructor(private eventService: EventsService,
    private router: Router,
    private location: Location,
    private appSettingService: AppSettingsService,
    private settingsService: AppSettingsService,
    private authService: AuthService) {
    this.globalUser = this.authService.getGlobalUser();
    this.themeColor = this.settingsService.getAppThemeColor() ? this.settingsService.getAppThemeColor() : this.defaultThemeColor;
  }

  ngOnInit() {
    this.eventService.getEventsList().valueChanges().subscribe(
      evnt => {
        if (evnt) {
          if (evnt.length > 0) {
            this.model = evnt;
            this.model = this.model.filter(ev => ev.published === true);

            if (!this.globalUser?.verified) {
              this.model = this.model.filter(n => n.visibility === 'Public');
            }
          }
        }
      }
    );

    this.appSettingService.getHomePageSettings().valueChanges().subscribe(hp => {
      if (hp) {
        if (hp.length > 0) {
          this.model = hp[0];
          const eventSection = this.model.sections.filter(s => s.name === 'Events' && s.isEnabled);
          if (eventSection) {
            this.eventSection = eventSection[0];
            this.eventService.getPublicEventsList().valueChanges().subscribe(events => {
              if (events) {
                this.events = events;
              }
            });
          }
        }
      }
    });

    if (this.model) {
      this.fetchEvents(this.model);
      this.refresh.next(null);
    }

    setTimeout(() => {
      $('.tabs').tabs();
    }, 25);
  }

  fetchEvents(events: any[]) {
    events.forEach(element => {
      const event = {
        eventDate: element.eventDate,
        eventEnd: element.eventDate,
        id: element.id,
        title: element.title
      };

      this.events.push({
        start: new Date(element.eventDate),
        end: addDays(new Date(element.expiryDate), -1),
        title: element.title,
        allDay: true,
        meta: {
          event,
        },
      });
    });
  }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (
      (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
      events.length === 0
    ) {
      this.activeDayIsOpen = false;
    } else {
      this.activeDayIsOpen = true;
    }
    this.viewDate = date;
  }

  closeOpenMonthViewDay() {
    this.activeDayIsOpen = false;
  }

  eventClicked({ event }: { event: CalendarEvent }): void {
    if (this.isMember) {
      this.router.navigate(['/member/event-detail', event.meta.event.id]);
    } else {
      this.router.navigate(['/events-detail', event.meta.event.id]);
    }
  }

  back() {
    localStorage.setItem('From', 'NewsEvents');
    this.router.navigate(['member/member-home-sub-Menu']);
  }
  backClicked() {
    this.location.back();
  }

}
