<div class="row">
  <form [formGroup]="contentForm" (ngSubmit)="PostContent(contentForm)">

    <div *ngIf="model">

      <!-- ************************************** News/Event Title ******************************************************-->
      <div class="row card white">
        <div class="card-content">
          <p class="sub-title-section4 bold-700 ">Title<span class="red-text">*</span></p>
          <div class="input-field">
            <!-- <angular-editor formControlName="cardTitle" [config]="titleEditorConfig"></angular-editor> -->
            <app-editor [title]=true [control]="cardTitle"></app-editor>
            <div *ngIf="(cardTitle.dirty || cardTitle.touched) && cardTitle.invalid">
              <small *ngIf="cardTitle.errors.required" class="red-text"> The Title is required. </small>
            </div>
          </div>
        </div>
      </div>
      <!--------------------------------- Description ---------------------------------------------->
      <div class="row card white">
        <div class="card-content">
          <p class="sub-title-section4 bold-700 p-b-1">Description</p>
          <div class="input-field">
            <!-- <ckeditor  (ready)="onReady($event)" [editor]="Editor" [config]="editorConfig"  formControlName='content'></ckeditor> -->
            <!-- <angular-editor formControlName="content" [config]="editorConfig"></angular-editor> -->
            <app-editor [control]="content"></app-editor>
            <div *ngIf="(content.dirty || content.touched) && content.invalid">
              <small *ngIf="content.errors.required" class="red-text"> The Content is required. </small>
            </div>
          </div>
        </div>
      </div>

      <!-- ============== comment ==================== -->

      <div class="row card white">
        <div class="card-content">
          <p class="row sub-title-section4 bold-700 p-b-1">
            Allow Comments? </p>
          <p class="row sub-title-section5 bold-100 p-b-1">Would you want your users to be able to comment on this post
          </p>

          <div class="row">
            <div class="col s6">
              <label>
                <input type="radio" value="YesComment" name="comment" [checked]="model.allowComments"
                  (click)="yesCommentClicked()" />
                <span><strong class="green-text">Yes</strong></span>
              </label>
            </div>
            <div class="col s6">
              <label>
                <input type="radio" value="NoComment" name="comment" [checked]="!model.allowComments"
                  (click)="noCommentClicked()" />
                <span><strong class="blue-text">No</strong></span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <!-- ************************************** Events ******************************************************-->
      <div class="row card white" *ngIf="contentType === 'Event'">
        <div class="card-content">

          <div class="input-field">
            <p class="sub-title-section4 bold-700 p-b-1">Event Date<span class="red-text">*</span></p>
            <input id="eventDate" type="text" class="datepicker" (change)="EventdateChange()" maxlength="49"
              formControlName='eventDate' autocomplete="off" />
            <div *ngIf="(eventDate.dirty || eventDate.touched) && eventDate.invalid">
              <small *ngIf="eventDate.errors.required" class="red-text">The Event Date is required. </small>
            </div>
          </div>

          <div class="input-field">
            <p class="sub-title-section4 bold-700 p-b-1">Event Place<span class="red-text">*</span></p>
            <input id="eventPlace" type="text" formControlName='eventPlace' required class="validate"
              autocomplete="off" />
            <div *ngIf="(eventPlace.dirty || eventPlace.touched) && eventPlace.invalid">
              <small *ngIf="eventPlace.errors.required" class="red-text">The Place is required. </small>
            </div>
          </div>

          <div class="input-field col s6">
            <div class="sub-title-section4 bold-700 p-b-1">Starts At<span class="red-text">*</span></div>
            <input id="startsAt" type="text" class="timepicker" (change)="EventTimeChange($event, 'startsAt')"
              maxlength="49" formControlName='startsAt' autocomplete="off">
            <div *ngIf="(startsAt.dirty || startsAt.touched) && startsAt.invalid">
              <small *ngIf="startsAt.errors.required" class="red-text">The Event Time is required. </small>
            </div>
          </div>

          <div class="input-field col s6">
            <div class="sub-title-section4 bold-700 p-b-1">Ends At<span class="red-text">*</span></div>
            <input id="endsAt" type="text" class="timepicker" (change)="EventTimeChange($event, 'endsAt')"
              maxlength="49" formControlName='endsAt' autocomplete="off">
          </div>

        </div>
      </div>

      <div class="row card white">
        <div class="card-content">
          <p class="row sub-title-section4 bold-700 p-b-1">
            Would you like to Upload an Image, video or PDF File From Your Device ? <span class="red-text">*</span>
          </p>
          <div class="row">
            <div class="col s6">
              <label>
                <input type="radio" value="Yes" name="group1" [checked]="hasMedia" (click)="yesClicked()" />
                <span><strong class="green-text">Yes</strong></span>
              </label>
            </div>
            <div class="col s6">
              <label>
                <input type="radio" value="No" name="group1" [checked]="hasNoMedia" (click)="noClicked()" />
                <span><strong class="blue-text">No</strong></span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <div class="row card white" *ngIf="addMedia">
        <div class="card-content">
          <p class="row sub-title-section4 bold-700 p-b-1">Please Select a Media Type</p>
          <div class="row">
            <div class="col s6">
              <label>
                <input type="radio" [checked]="isPhoto" name="group2" (click)="photoClick()" />
                <span><strong>Photo</strong></span>
              </label>
            </div>
            <div class="col s6">
              <label>
                <input type="radio" [checked]="isVideo" name="group2" (click)="youTubeClick()" />
                <span><strong>YouTube Video</strong></span>
              </label>
            </div>
            <div class="col s6">
              <label>
                <input type="radio" [checked]="isUpload" name="group2" (click)="uploadClick()" />
                <span><strong>Upload Video</strong></span>
              </label>
            </div>
            <div class="col s6">
              <label>
                <input type="radio" [checked]="isPdf" name="group2" (click)="uploadPdf()" />
                <span><strong>PDF File</strong></span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <!--------------------------------- Link/URL ---------------------------------------------->
      <div class="row card white" *ngIf="model.type === 'Link' && addMedia">
        <div class="card-content">
          <div class="row col s12">
            <p class="sub-title-section4 bold-700 p-b-1">Please Copy/Paste The Link Or Url</p>
            <div class="input-field">
              <input id="videoLink" type="text" formControlName='videoLink' class='validate' autocomplete="off" />
              <label for="videoLink">URL </label>
              <div *ngIf="(videoLink.dirty || videoLink.touched) && videoLink.invalid">
                <small *ngIf="videoLink.errors.required" class="red-text">The Link/URL is required. </small>
              </div>
            </div>
          </div>
        </div>

      </div>

      <!--------------------------------- Photo ---------------------------------------------->
      <div class="row card white" *ngIf="model.type === 'Photo' && addMedia">
        <div class="card-content">
          <p class="sub-title-section4 bold-700 p-b-1">Please Select A Logo From Your Library</p>

          <div class="card col s12 m6 l4" *ngIf="!isMobile" (change)="handleFileInput($event.target.files)">
            <div class="center-align">
              <input type="file" formControlName="imgLink" accept="image/*"
                (change)="handleFileInput($event.target.files)" />
              <i class="fa fa-cloud-upload p-t-2"></i>
              <h4 class="label-input p-b-2  bold-200">
                Drop & Drop file here or Click to Upload</h4>
            </div>

            <p class="red-text" *ngIf="imgErrorMsg">
              <strong><small><i class="material-icons">error_outline</i> {{imgErrorMsg}}</small></strong>
            </p>
          </div>

          <div class="row col s12 m6 l6" *ngIf="isMobile">
            <p class="sub-title-section4 bold-700 p-b-1">Please Select A Logo From Your Library Mobile version</p>
            <div class="file-field input-field">
              <div class="btn white-text" [ngStyle]="{'background-color': themeColor}"
                (click)='handleMobilePhotoInput()'>Browse
              </div>
            </div>
            <p class="red-text" *ngIf="imgErrorMsg">
              <strong><small><i class="material-icons">error_outline</i> {{imgErrorMsg}}</small></strong>
            </p>
          </div>

          <div class="col s12 m6 l6 push-l1 img-wrap" *ngIf="uploadedImage">
            <a class="delete-link btn-floating waves-effect waves-light right" (click)="removeFile()">
              <i class="material-symbols-outlined">
                delete
              </i>Delete</a>
            <img class="materialboxed responsive-img" [src]="uploadedImage" width="100%" height="auto" />
          </div>
        </div>
      </div>

      <!--------------------------------- Video ---------------------------------------------->
      <div class="row card white" *ngIf="model.type === 'uploadVideo' && addMedia ">
        <div class="card-content">
          <p class="sub-title-section4 bold-700 p-b-1">Please Select A Video From Your Library</p>

          <div class="card col l5 s12 m6" (change)="handleVideoInput($event.target.files)">
            <div class="center-align">
              <input type="file" formControlName="imgLink" accept="video/*"
                (change)="handleVideoInput($event.target.files)" />
              <i class="fa fa-cloud-upload p-t-3"></i>
              <h4 class="label-input p-b-3  bold-200">
                Drop & Drop file here or Click to Upload</h4>
            </div>
          </div>

          <div class="col s12 m6 l6 push-l1 img-wrap" *ngIf="videoUploaded || model.videoLink">
            <a class="delete-link btn-floating waves-effect waves-light right" (click)="removeFile()">
              <i class="material-symbols-outlined">
                delete
              </i>Delete</a>

            <div class="center center-align">
              <video controls class="uploadedVideo" width="100%" height="auto" *ngIf="videoUploaded">
                <source id="video_here">
                Your browser does not support HTML5 video.
              </video>

              <video controls width="100%" height="auto" *ngIf="model.videoLink">
                <source id="video_here" [src]="model.videoLink" type="video/mp4">
                Your browser does not support HTML5 video.
              </video>
            </div>
          </div>
        </div>
      </div>

      <!--------------------------------- Youtube Video Link ---------------------------------------------->
      <div class="row card white" *ngIf="model.type === 'Video' && addMedia">
        <div class="card-content">
          <p class="sub-title-section bold-700 p-b-1">Please Copy/Paste The Video Link From YouTube</p>
          <div class="row col s12 m6 l5">
            <div class="input-field">
              <input id="videoLink" type="text" formControlName='videoLink' class='validate' autocomplete="off"
                (input)="youtubeLink($event)" />
              <label for="videoLink">YouTube Video Link </label>
              <div *ngIf="(videoLink.dirty || videoLink.touched) && videoLink.invalid">
                <small *ngIf="videoLink.errors.required" class="red-text">The Video Link is required. </small>
              </div>
            </div>
          </div>

          <div class="col s12 m6 l6 push-l1 img-wrap">
            <a class="delete-link btn-floating waves-effect waves-light right" (click)="removeFile()">
              <i class="material-symbols-outlined">
                delete
              </i>Delete</a>
            <div class="video-container">
              <div [innerHTML]="iframehtml"></div>
            </div>
          </div>
        </div>
      </div>

      <!--------------------------------- PDF ---------------------------------------------->
      <div class="row card white" *ngIf="model.type === 'PDF' && addMedia">
        <div class="card-content">
          <div class="row col s12">
            <p class="sub-title-section bold-700 p-b-1">Please Select A PDF File From Your Library</p>
            <div class="file-field input-field">
              <div class="btn white-text" [ngStyle]="{'background-color': themeColor}">
                <span>Browse</span>
                <input type="file" formControlName="imgLink" accept="file"
                  (change)="handlePDFInput($event.target.files)" />
              </div>
              <div class="file-path-wrapper">
                <input formControlName="imgLink" class="file-path validate" type="text"
                  placeholder="Select PDF File From Library" />
              </div>
            </div>
            <p class="red-text" *ngIf="pdfErrorMsg"> <strong><small>{{pdfErrorMsg}}</small></strong> </p>
          </div>
        </div>
      </div>

      <!--------------------------------- visibility ---------------------------------------------->
      <div class="row card white">
        <div class="card-content">
          <p class="row sub-title-section4 bold-700 p-b-1">Share the {{msgName}}</p>

          <div class="row" style="margin-top: 15px;">
            <div class="col s6">
              <label>
                <input type="radio" value="Yes" name="group0" [checked]="isPublic" (click)="publicClicked()" />
                <span><strong class="green-text">Public</strong></span>
              </label>
            </div>
            <div class="col s6">
              <label>
                <input type="radio" value="No" name="group0" [checked]="isPrivate" (click)="privateClicked()" />
                <span><strong class="blue-text">Members Only</strong></span>
              </label>
            </div>
          </div>

          <div class="row card" *ngIf="showFilters">
            <div class="card-content">
              <h4 class="card-title bold-700 sub-title-section3">Filter Members</h4>

              <div class="input-field col s12">
                <div class="label-input">Gender</div>
                <select class="browser-default" formControlName="genderFilter"
                  (change)="genderChange($event.target.value)">
                  <option value="" disabled selected>Please Select</option>
                  <option *ngFor="let g of genders" [value]="g">{{g}}</option>
                </select>
              </div>

              <div class="input-field col s12">
                <div class="label-input">Community</div>
                <select class="browser-default" formControlName="communityFilter"
                  (change)="communityChange($event.target.value)">
                  <option value="" disabled selected>Please Select</option>
                  <option *ngFor="let c of nationCommunities" [value]="c.community">{{c.community}}</option>
                </select>
              </div>

              <div class="input-field col s12 m6">
                <div class="label-input">Min Age</div>
                <input type="number" id="minAgeFilter" formControlName="minAgeFilter" autocomplete="off" min="1"
                  max="99" (input)="minAgeChange($event.target.value)" />
              </div>

              <div class="input-field col s12 m6">
                <div class="label-input">Max Age</div>
                <input type="number" id="maxAgeFilter" formControlName='maxAgeFilter' autocomplete="off" min="1"
                  max="99" (input)="maxAgeChange($event.target.value)" />
              </div>

              <div class="input-field col s12">
                <div class="label-input">Residency</div>
                <select class="browser-default" formControlName="residencyFilter"
                  (change)="residencyChange($event.target.value)">
                  <option value="" disabled selected>Please Select</option>
                  <option *ngFor="let r of residency" [value]="r">{{r}}</option>
                </select>
              </div>

            </div>
          </div>
        </div>
      </div>

      <!--------------------------------- Preview  ---------------------------------------------->
      <div class="row card">
        <div class="card-content">
          <h6 class="title-section3 bold-900 center"><strong><i class="material-icons">preview</i> Preview</strong>
          </h6>
          <br />
          <div class="row">
            <h6 class="center" [innerHTML]="cardTitle.value"></h6>
            <div class="section"></div>
            <div *ngIf="model.type === 'Photo'">
              <div class="center center-align" *ngIf="actionType === 'Add' && uploadedImage">
                <img class="responsive-img" [src]="uploadedImage" height="50%" width="50%" alt="New Image" />
              </div>

              <div class="center center-align" *ngIf="actionType === 'Edit' && uploadedImage">
                <img class="responsive-img" [src]="uploadedImage" height="50%" width="50%" alt="Show Image"/>
              </div>
            </div>

            <div class="row" *ngIf="model.type === 'Video'">
              <div class="video-container">
                <div [innerHTML]="iframehtml"></div>
              </div>
            </div>

            <div *ngIf="model.type === 'uploadVideo'">
              <div class="center center-align" *ngIf="videoUploaded || model.videoLink">
                <video controls class="uploadedVideo" width="100%" height="auto" *ngIf="videoUploaded">
                  <source id="video_here">
                  Your browser does not support HTML5 video.
                </video>

                <video controls width="100%" height="auto" *ngIf="model.videoLink">
                  <source id="video_here" [src]="model.videoLink" type="video/mp4">
                  Your browser does not support HTML5 video.
                </video>
              </div>
            </div>
          </div>

          <div *ngIf="model.type === 'PDF'">
            <div class="center center-align" *ngIf="actionType === 'Edit' && model.videoLink">
              <iframe [src]="model.videoLink | safeUrl" width="100%" height="500px"></iframe>
            </div>
          </div>

          <div class="row col s12">
            <span [innerHTML]="content.value | noSanitize"></span>
          </div>
        </div>
      </div>
    </div>

    <div class="row p-b-6 p-t-6">
      <a class="col  l3 s9 push-s1 btn waves-effect back-button white-text" *ngIf="actionType === 'Add'"
        [ngClass]="'btn ' + buttonType" style="margin: 15px;" [ngStyle]="{'background-color': adminThemeColor}"
        (click)="backClicked()">
        Back To Previous Page
      </a>

      <!--Add News / DepNews-->
      <button [disabled]="!contentForm.valid || (addMedia !== true && addMedia !== false )"
        *ngIf="actionType === 'Add' && (contentType === 'News' || contentType === 'DepNews' )"
        class="col  l3 s9 push-l4 push-s1  btn  publish-button waves-effect  modal-trigger"
        data-target="confirmationModal" [ngClass]="'btn ' + buttonType" style="margin: 15px;">
        Publish {{msgName}}
      </button>

      <!--Add Aboutus-->
      <button [disabled]="!contentForm.valid" *ngIf="actionType === 'Add' && contentType === 'AboutUs'"
        class="col l3 s9 push-l4 push-s1 btn waves-effect  publish-button modal-trigger" data-target="confirmationModal"
        [ngClass]="'btn ' + buttonType" style="margin: 15px;">
        Publish {{msgName}}
      </button>

      <!--Add Eveent-->
      <!-- <button [disabled]="!contentForm.valid" *ngIf="actionType === 'Add' && contentType === 'Event'"
        class="col l3 s9 push-s1 push-l4  btn waves-effect  publish-button modal-trigger" data-target="shareModal"
        [ngClass]="'btn ' + buttonType" style="margin: 15px; text-align:center;">
        Create Event
      </button> -->
      <button [disabled]="!contentForm.valid" *ngIf="actionType === 'Add' && contentType === 'Event'"
        class="col l3 s9 push-s1 push-l4  btn waves-effect  publish-button modal-trigger"
        data-target="confirmationModal" [ngClass]="'btn ' + buttonType" style="margin: 15px; text-align:center;">
        Create Event
      </button>

      <!--Unarchive-->
      <button [disabled]="loggedUser.isDemo" *ngIf="actionType === 'Edit'"
        class="col l3 s9 push-s1 btn waves-effect delete-button  modal-trigger" data-target="modal4"
        [ngClass]="'btn ' + buttonType" style="margin: 15px;">
        Delete {{msgName}}
      </button>

      <!--Archive-->
      <button type="button" [disabled]="loggedUser.isDemo"
        *ngIf="model.published && contentType !== 'AboutUs' && actionType === 'Edit'"
        class="col l3 s9 push-s1 push-l1 btn waves-effect edit-button  modal-trigger" data-target="modal2"
        [ngClass]="'btn ' + buttonType" style="margin: 15px;">
        Archive {{msgName}}
      </button>

      <!--Unarchive-->
      <button type="button" [disabled]="loggedUser.isDemo"
        *ngIf="model.unPublished && contentType !== 'AboutUs' && actionType === 'Edit'"
        class="col l3 s9 push-s1 btn waves-effect edit-button modal-trigger" data-target="modal3"
        [ngClass]="'btn ' + buttonType" style="margin: 15px;">
        Unarchive {{msgName}}
      </button>



      <!--Update-->
      <button [disabled]="!contentForm.valid || loggedUser.isDemo" *ngIf="actionType === 'Edit'"
        class="col l3 s9  push-s1 push-l1 btn waves-effect  update-button  modal-trigger" data-target="confirmationModal"
        [ngClass]="'btn ' + buttonType" style="margin: 15px;">
        Update {{msgName}}
      </button>
    </div>
     
    <!--*************************************** Modals ****************************************************-->

    <!--Modal for Confirmation -->
    <div id="confirmationModal" class="modal b-n-1">
      <i class="material-icons right modal-close col s1">close</i>
      <div class="modal-content">
        <div class="col s3">
          <span class="material-symbols-outlined orange-text" style="font-size: 70px;">
            lightbulb
          </span>
        </div>
        <div class="col s9">
          <h4 *ngIf="actionType === 'Add'">Publish {{msgName}}</h4>
          <h4 *ngIf="actionType === 'Edit'" class="bold-700">Update {{msgName}}</h4>
          <h6 *ngIf="actionType === 'Add'" class="bold-700">Are you sure you want to publish this new {{msgName}} ?</h6>
          <h6 *ngIf="actionType === 'Edit'">Are you sure you want to update this {{msgName}} ?</h6>
          <p *ngIf="actionType === 'Edit'">Make sure to review and confirm your modifications before
            proceeding.</p>
          <p *ngIf="actionType === 'Add'">
            Please take a moment to review your post before proceeding with publishing.</p>
        </div>
      </div>

      <div class="modal-footer row col s12">
        <button type="submit" *ngIf="actionType === 'Add'" class="btn continue-button col s4 right">
          Publish
        </button>
        <button type="submit" *ngIf="actionType === 'Edit'" class="btn continue-button col s4 right">
          Update
        </button>
        <button class="modal-close close-button btn col s4">Cancel</button>
      </div>
    </div>

    <!--Modal for Archive-->
    <div id="modal2" class="modal">
      <div class="modal-content">
        <h5>Archive {{msgName}}</h5>
        <p>Are you sure you want to Archive this {{msgName}} ?</p>
      </div>
      <div class="modal-footer">
        <button type="button" (click)="UnpublishContent()" class="btn waves-effect orange white-text"
          style="margin-right: 15px;">
          <i class="material-icons right white-text">archive</i> Archive
        </button>
        <a class="modal-close amber darken-4 white-text btn-flat white-text"> <i
            class="material-icons right">close</i>Cancel</a>
      </div>
    </div>

    <div id="modal3" class="modal">
      <div class="modal-content">
        <h5>Publish {{msgName}}</h5>
        <p>Are you sure you want to Publish this Archived {{msgName}} ?</p>
      </div>
      <div class="modal-footer">
        <button type="button" (click)="publishContent()" class="btn waves-effect blue white-text"
          style="margin-right: 15px;">
          <i class="material-icons right white-text">unarchive</i> Unarchive
        </button>
        <a class="modal-close amber darken-4 white-text btn-flat white-text"> <i
            class="material-icons right">close</i>Cancel</a>
      </div>
    </div>

    <!--Modal for Delete-->
    <div id="modal4" class="modal">
      <div class="modal-content">
        <h5>Delete {{msgName}}</h5>
        <p>Are you sure you want to Delete this {{msgName}} ?</p>
      </div>
      <div class="modal-footer">
        <button type="button" (click)="DeleteContent()" class="btn waves-effect red white-text"
          style="margin-right: 15px;">
          <i class="material-icons right white-text">close</i> Delete
        </button>
        <a class="modal-close amber darken-4 white-text btn-flat white-text"> <i
            class="material-icons right">close</i>Cancel</a>
      </div>
    </div>

    <!--Modal for Share-->
    <!-- <div id="modal5" class="modal modal_max b-n-1">
      <div class="modal-content">
        <i class="material-icons right modal-close">close</i><br />
        <h6 class="title-section2 bold-600 black-text">Share the {{msgName}}</h6>
        <img src="https://img.icons8.com/dotty/344/id-not-verified.png" height="150px" />

        <div class="row" style="margin-top: 15px;">
          <div class="col s6">
            <label>
              <input type="radio" value="Yes" name="group0" [checked]="isPublic" (click)="publicClicked()" />
              <span><strong class="green-text">Public</strong></span>
            </label>
          </div>
          <div class="col s6">
            <label>
              <input type="radio" value="No" name="group0" [checked]="isPrivate" (click)="privateClicked()" />
              <span><strong class="blue-text">Members Only</strong></span>
            </label>
          </div>
        </div>

        <br />
        <a class="btn col 12 center center-align" style="background-color: #3b5998;"
          *ngIf="actionType === 'Edit' && model.visibility === 'Public'" (click)="shareSocialMediaEdit()"
          [ngStyle]="{'background-color': themeColor}"> Do you want to share the
          <i class="fa fa-share-alt left"></i>Share on SocialMedia
        </a>

        <div class="row card" *ngIf="showFilters">

          <div class="card-content">
            <h4 class="card-title bold-700 sub-title-section">Filter Members</h4>
            <div class="input-field col s12">
              <div class="label-input">Gender</div>
              <select class="browser-default" formControlName="genderFilter"
                (change)="genderChange($event.target.value)">
                <option value="" disabled selected>Please Select</option>
                <option *ngFor="let g of genders" [value]="g">{{g}}</option>
              </select>
            </div>

            <div class="input-field col s12">
              <div class="label-input">Community</div>
              <select class="browser-default" formControlName="communityFilter"
                (change)="communityChange($event.target.value)">
                <option value="" disabled selected>Please Select</option>
                <option *ngFor="let c of nationCommunities" [value]="c.community">{{c.community}}</option>
              </select>
            </div>

            <div class="input-field col s12 m6">
              <div class="label-input">Min Age</div>
              <input type="number" id="minAgeFilter" formControlName="minAgeFilter" autocomplete="off" min="1" max="99"
                (input)="minAgeChange($event.target.value)" />
            </div>

            <div class="input-field col s12 m6">
              <div class="label-input">Max Age</div>
              <input type="number" id="maxAgeFilter" formControlName='maxAgeFilter' autocomplete="off" min="1" max="99"
                (input)="maxAgeChange($event.target.value)" />
            </div>

            <div class="input-field col s12">
              <div class="label-input">Residency</div>
              <select class="browser-default" formControlName="residencyFilter"
                (change)="residencyChange($event.target.value)">
                <option value="" disabled selected>Please Select</option>
                <option *ngFor="let r of residency" [value]="r">{{r}}</option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div class="modal-footer">
        <button [disabled]="!model.visibility" type="button" class="btn waves-effect green modal-close"
          style="margin-right: 15px;">Share</button>

        <a class="modal-close amber darken-4 white-text btn-flat white-text">
          Cancel
        </a>
      </div>
    </div> -->

    <div id="shareModal" class="modal b-n-1">
      <i class="material-icons right modal-close col s1" (click)="back()">close</i>
      <div class="modal-content">
        <h4>Share on Social Media</h4>
        <h6>Do you wish to share this post on facebook, twitter, linkedIn or pinterest.</h6>
        <br />
        <!-- Facebook -->
        <div class="facebook col s12 m6">
          <a *ngIf="contentType === 'News'" class="btn share-button" style="background-color: #3b5998;"
            (click)="postToFacebook()">
            <i class="fa fa-facebook prefix "></i>
            Facebook
          </a>
          <a *ngIf="contentType === 'Event'" class="btn share-button" style="background-color: #3b5998;"
            (click)="postEventToFacebook()">
            <i class="fa fa-facebook prefix "></i>
            Facebook
          </a>
          <a *ngIf="contentType === 'DepNews'" class="btn share-button" style="background-color: #3b5998;"
            (click)="postDepartmentNewsToFacebook()">
            <i class="fa fa-facebook prefix "></i>
            Facebook
          </a>
          <a *ngIf="contentType === 'AboutUs'" class="btn share-button" style="background-color: #3b5998;"
            (click)="postAboutUsToFacebook()">
            <i class="fa fa-facebook prefix "></i>
            Facebook
          </a>
        </div>

        <!-- Twitter -->
        <div class="twitter col s12 m6">
          <a *ngIf="contentType === 'News'" class="btn share-button twitter-share-button"
            [href]="twitterURL+postLinkTwitter" target='_blank' style="background-color: #1da1f2;">
            <!-- <i class="fa fa-twitter prefix "></i> -->
            <svg _ngcontent-yei-c148="" width="25" height="25" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path _ngcontent-yei-c148="" fill="currentcolor"
                d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z">
              </path>
            </svg>
            Twitter
          </a>
          <a *ngIf="contentType === 'Event'" class="btn share-button twitter-share-button"
            [href]="twitterURL+postEventLinkTwitter" target='_blank' style="background-color: #1da1f2;">
            <!-- <i class="fa fa-twitter prefix "></i> -->
            <svg _ngcontent-yei-c148="" width="25" height="25" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path _ngcontent-yei-c148="" fill="currentcolor"
                d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z">
              </path>
            </svg>
            Twitter
          </a>
          <a *ngIf="contentType === 'DepNews'" class="btn share-button twitter-share-button"
            [href]="twitterURL+postDeptNewsLinkTwitter" target='_blank' style="background-color: #1da1f2;">
            <!-- <i class="fa fa-twitter prefix "></i> -->
            <svg _ngcontent-yei-c148="" width="25" height="25" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path _ngcontent-yei-c148="" fill="currentcolor"
                d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z">
              </path>
            </svg>
            Twitter
          </a>
          <a *ngIf="contentType === 'AboutUs'" class="btn share-button twitter-share-button"
            [href]="twitterURL+aboutUsLinkTwitter" target='_blank' style="background-color: #1da1f2;">
            <!-- <i class="fa fa-twitter prefix "></i> -->
            <svg _ngcontent-yei-c148="" width="25" height="25" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
              <path _ngcontent-yei-c148="" fill="currentcolor"
                d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z">
              </path>
            </svg>
            Twitter
          </a>
        </div>

        <!-- LinkedIn -->
        <div class="linkedIn col s12 m6">
          <a *ngIf="contentType === 'News'" class="btn share-button linkedin-share-button"
            [href]="linkedinURL+postLinkLinkedIn" target='_blank' style="background-color: #1da1f2;">
            <i class="fa fa-linkedin prefix "></i>
            LinkedIn
          </a>
          <a *ngIf="contentType === 'Event'" class="btn share-button linkedin-share-button"
            [href]="linkedinURL+postEventLinkTwitter" target='_blank' style="background-color: #1da1f2;">
            <i class="fa fa-linkedin prefix "></i>
            LinkedIn
          </a>
          <a *ngIf="contentType === 'DepNews'" class="btn share-button linkedin-share-button"
            [href]="linkedinURL+postDeptNewsLinkTwitter" target='_blank' style="background-color: #1da1f2;">
            <i class="fa fa-linkedin prefix "></i>
            LinkedIn
          </a>
          <a *ngIf="contentType === 'AboutUs'" class="btn share-button linkedin-share-button"
            [href]="linkedinURL+aboutUsLinkTwitter" target='_blank' style="background-color: #1da1f2;">
            <i class="fa fa-linkedin prefix "></i>
            LinkedIn
          </a>
        </div>

        <!-- pinterest -->
        <div class="pageview col s12 m6">
          <a *ngIf="contentType === 'News' && (model.imgLink && model.imgLink !== '') "
            [href]="pinterestURL+postLinkLinkedIn+'&amp;media='+model.imgLink+'&amp;description=New%20Nation%20News'"
            target='_blank' class="btn share-button linkedin-share-button pin-it-button" count-layout="horizontal"
            style="background-color: #E60023;">
            <!-- <img border="0" src="http://assets.pinterest.com/images/PinExt.png" title="Pin It" /> -->
            <i class="fa fa-pinterest prefix "></i>
            Pinterest
          </a>
          <a *ngIf="contentType === 'Event' && (model.imgLink && model.imgLink !== '') "
            [href]="pinterestURL+postEventLinkTwitter+'&amp;media='+model.imgLink+'&amp;description=New%20Nation%20Events'"
            target='_blank' class="btn share-button linkedin-share-button pin-it-button" count-layout="horizontal"
            style="background-color: #E60023;">
            <!-- <img border="0" src="http://assets.pinterest.com/images/PinExt.png" title="Pin It" /> -->
            <i class="fa fa-pinterest prefix "></i>
            Pinterest
          </a>
          <a *ngIf="contentType === 'DepNews' && (model.imgLink && model.imgLink !== '') "
            [href]="pinterestURL+postDeptNewsLinkTwitter+'&amp;media='+model.imgLink+'&amp;description=New%20About%20Nation%20Post'"
            target='_blank' class="btn share-button linkedin-share-button pin-it-button" count-layout="horizontal"
            style="background-color: #E60023;">
            <!-- <img border="0" src="http://assets.pinterest.com/images/PinExt.png" title="Pin It" /> -->
            <i class="fa fa-pinterest prefix "></i>
            Pinterest
          </a>
          <a *ngIf="contentType === 'AboutUs' && (model.imgLink && model.imgLink !== '') "
            [href]="pinterestURL+aboutUsLinkTwitter+'&amp;media='+model.imgLink+'&amp;description=New%20Department%20Nation%20News'"
            target='_blank' class="btn share-button linkedin-share-button pin-it-button" count-layout="horizontal"
            style="background-color: #E60023;">
            <!-- <img border="0" src="http://assets.pinterest.com/images/PinExt.png" title="Pin It" /> -->
            <i class="fa fa-pinterest prefix "></i>
            Pinterest
          </a>
        </div>
      </div>
      <!-- <div class="modal-footer">
        <button class="modal-close close-button btn col s4 right" (click)="backClicked()">Cancel</button>
      </div> -->
    </div>

  </form>
</div>