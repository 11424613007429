export class AdminSavedAudiences{
    id: string;
    adminId: string;
    saved: boolean = false;
    age:{
        selected: boolean;
        min: number;
        max: number;
    } = {
        selected : false,
        min : 0,
        max :  0
    };
    gender:{
        selected: boolean;
        selectedGender: string;
    } = {
        selected: false,
        selectedGender: ''
    };
    community:{
        selected: boolean;
        selectedCommunity: string;
    }= {
        selected: false,
        selectedCommunity: ''
    };;
    postIds: [];
    membersId: [string];
    audienceTitle: string = '';
}