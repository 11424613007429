<br />
<h6 *ngIf="step0" class="center p-m-2"><strong>Please select a fiscal year to see if there is any documents that require
    your signature.
  </strong></h6>

<h6 *ngIf="step1" class="center">
  <strong>
    You can view each benefit
    payment before signing one
    or multiple B&D
  </strong>
</h6>
<h6 class="center blue-text" *ngIf="fiscalYear"> {{fiscalYear}} </h6>

<div class="row col s12 p-m-2">
  <div class="col l1 "></div>
  <div class=" col s12  l10">
    <div *ngIf="step0">
      <ul class="row collection">
        <div *ngFor="let year of years">
          <li class="collection-item" *ngIf="year === currentYear && currentMonth > 2"
            (click)="newYearSelected(year, year, year +1)">
            <div>
              <strong class="blue-text"> {{ (year) }} - {{ year + 1 }}</strong><a
                (click)="newYearSelected(year, year, year +1)" class="secondary-content"><i
                  class="material-icons"></i>Select
              </a>
            </div>
          </li>
          <li class="collection-item" (click)="newYearSelected(year - 1, year - 1, year)">
            <div>
              <strong class="blue-text"> {{ (year - 1) }} - {{ year }} </strong><a
                (click)="newYearSelected(year - 1, year - 1, year)" class="secondary-content"><i
                  class="material-icons"></i>Select </a>
            </div>
          </li>
        </div>
      </ul>
    </div>

    <div *ngIf="step1">
      <div *ngIf="result">
        <div *ngIf="result.length > 0">
          <ul class="row collapsible">
            <li *ngFor="let e of result">
              <div class="collapsible-header">
                <label class="">
                  <input type="checkbox" class="filled-in" [checked]="e.isChecked" (change)="checkChange(e)" />
                  <span> </span>
                </label>
                <span class="red-text" style="text-decoration: underline;"><strong>{{e.BDno}}</strong> </span>
              </div>
              <div class="collapsible-body">

                <ul class="row collection">
                  <!-- <li class="collection-item">
                    <div><strong> Basic Needs & Accomodation </strong><strong><a class="secondary-content black-text">{{
                          e.Core_essentials | currency}}</a></strong></div>
                  </li>
                  <li class="collection-item">
                    <div><strong> Discretionary Benefits<a class="secondary-content black-text">{{ e.Core_Shelter |
                          currency}}</a></strong></div>
                  </li>
                  <li class="collection-item">
                    <div><strong>Other <a class="secondary-content black-text">{{ e.Special_needs | currency}}</a></strong>
                    </div>
                  </li>
                  <li class="collection-item">
                    <div><strong>Temporary Care<a class="secondary-content black-text">{{ e.COPH | currency}}</a></strong>
                    </div>
                  </li> -->

                  <li class="collection-item">
                    <div><strong>Total:<a class="secondary-content green-text">{{ e.TotalAmount | currency }}
                        </a></strong></div>
                  </li>
                  <li class="collection-item">
                    <div><strong>Payable To:<a class="secondary-content green-text">{{ e.PayableTo }} </a></strong>
                    </div>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>

        <div *ngIf="result.length > 0" class="row">
          <a class="at-btn btn-flat center green lighten-1 waves-effect waves-light col s12 white-text"
            *ngIf="!hasSignedEntitlement" (click)="next()"><i class="material-icons right ">border_color</i>Sign
            Selected
            Entitlement</a>

          <a class="at-btn btn-flat center blue waves-effect waves-light col s12 white-text" (click)="back0()"
            style="margin-top: 10px;"><i class="material-icons left ">arrow_back_ios_new</i>Back To Previous Page</a>
        </div>

        <div *ngIf="result.length === 0">
          <div class="row card-panel white center">
            <p> <strong> <i class="material-icons green-text">info</i> You Dont Have Any Missing Signature
              </strong></p>
          </div>
          <div class="row">
            <a class="at-btn btn-flat center blue waves-effect waves-light col s12 white-text" (click)="back0()"><i
                class="material-icons left ">arrow_back_ios_new</i>Back To Previous Page</a>
          </div>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="step2">

      <div class="row card-panel grey lighten-4 center">
        <div class="row">
          <h6 class="blue-text text-darken-2 center"><strong>Please Sign Here</strong></h6>
          <canvas></canvas>
        </div>
      </div>
      <div class="section"></div>

      <a class="at-btn btn-flat center green lighten-1 col s12 white-text" style="margin-bottom: 10px;"
        (click)="submit()"><i class="material-icons left">check</i> Approve And Submit</a>

      <a class="at-btn btn-flat center red lighten-1 col s12 white-text" style="margin-bottom: 10px;"
        (click)="clearSignature()"><i class="material-icons left">loop</i> Clear Signature</a>

      <a [ngClass]="'at-btn col s12 btn waves-effect ' + themeColor" (click)="back()"><i
          class="material-icons left">arrow_back_ios</i> Back To Entitlement List </a>
    </div>

    <div id="step3" *ngIf="step3" class="row ">
      <div class="row">
        <div class="row col s12">
          <div class="row card-panel">
            <div class="row center">
              <h5 class="green-text">Thank You!</h5>
              <h6>Your Entitlements were signed and successfully submitted to your case manager. </h6>
              <br />
            </div>
          </div>
        </div>
      </div>
      <div class="section"></div>
    </div>

    <div class="row">
      <button class=' col s12 btn waves-effect blue' (click)="backClicked()">
        <i class="material-icons left">keyboard_arrow_left</i> Back To Previous Page
      </button>
    </div>
  </div>

  <div class="col l1 "></div>
</div>