<div class="row">

    <div class="col l2 side-member hide-on-med-and-down">
        <ul *ngIf="menus">
            <li [routerLink]="['/member/member-main-home/member-home']"
                [class.active]="selectedMenuItem === 'Dashboard'" (click)="navigate('Dashboard')">
                <i class="material-icons">
                    home
                </i>Dashboard
            </li>

            <li (click)="getVerified()" *ngIf="showGetVerified && !verificationReqExist" style="cursor: pointer;"
                [class.active]="selectedMenuItem === 'Get Verified'">
                <i class="material-icons ">
                    verified
                </i>
                <span class="bold-900">Get Verified</span>
            </li>
            <li *ngIf="showGetVerified && verificationReqExist" style="cursor: pointer;" data-target="verificationModal"
                class="modal-trigger">
                <i class="material-icons ">
                    verified
                </i>
                <span class="bold-900">Verification Status</span>
            </li>
            <div *ngFor="let item of menus; let i=index">
                <li (click)="navigate(item.name)" *ngIf="item.isEnabled" style="cursor: pointer;"
                    [class.active]="selectedMenuItem === item.name">
                    <i class="material-icons ">
                        {{item.icon}}
                    </i>{{item.displayName}}
                </li>
            </div>
            <div *ngFor="let item of customSections; let i=index">
                <li (click)="navigateTo(item.id)" style="cursor: pointer;"
                    [class.active]="selectedMenuItem === item.id">
                    <i class="material-icons ">
                        {{item.icon}}
                    </i>{{item.displayName}}
                </li>
            </div>
            <li *ngIf="logoutMenu" style="cursor: pointer;" (click)="navigate(logoutMenu.name)"
                [class.active]="selectedMenuItem === logoutMenu.name">
                <i class="material-icons ">
                    {{logoutMenu.icon}}
                </i>{{logoutMenu.displayName}}
            </li>
        </ul>
    </div>

    <select class="browser-default hide-on-large-only" (change)="navigate($event.target.value)" *ngIf="menus">
        <option value="">Menu</option>
        <option *ngFor="let item of menus; let i=index" [value]="item.name">{{item.displayName}} </option>
    </select>

    <div class="col l10 s12 main">
        <router-outlet></router-outlet>
    </div>

    <!--==============================modal====================================================-->
    <div id="verificationModal" class="modal center b-n-1">
        <div class="modal-content">
            <i class="material-icons right modal-close">close</i><br />
            <h6 class="title-section2 bold-600 black-text">Verfication</h6>
            <h3 class="title-section4 bold-100">Your Verification status is: <strong class="bold-700"
                    [ngStyle]="{'color': themeColor}">{{verificationStatus}}</strong></h3>
            <img src="https://img.icons8.com/wired/344/tow-truck.png" height="150px" />
            <p class="sub-title-section bold-100">
                In the meantime if you need some support? Contact us on
                <a href="mailto:info&#64;mynation.app">info&#64;mynation.app</a>
            </p>
            <p class="sub-title-section bold-100">Please Contanct your Administrator to gain access.</p>
        </div>
    </div>

    <div id="restrictedModal" class="modal center b-n-1">
        <div class="modal-content">
            <i class="material-icons right modal-close">close</i><br />
            <!-- <h6 class="title-section2 bold-600 black-text">Restricted Area</h6> -->
            <span class="material-icons" style="font-size:100px;">do_not_disturb_on</span>
            <!-- <h3 class="title-section4 bold-100">Your Verification status is: <strong class="bold-700"
                    [ngStyle]="{'color': themeColor}">{{verificationStatus}}</strong></h3> -->

            <p class="title-section5 bold-500">This area is for members of <span
                    [ngStyle]="{'color': themeColor}">{{nationName}}</span> only.</p>
            <p class="sub-title-section bold-300">
                If you are a member and you want access to this area please get verified or contact Us.
            </p>

            <div *ngIf="!verificationStatus">
                <!-- <p class="sub-title-section bold-300">Do you want to get verified?</p> -->
                <a class="btn waves-effect waves-light" [ngStyle]="{'background-color': themeColor}"
                    (click)="getVerified()">Get Verified</a>
            </div>

            <div *ngIf="nationContactInfo">
                <p class="sub-title-section bold-100">If you need some support? Contact us on: </p>
                <p><span class="material-icons" style="margin-right: 10px;" [ngStyle]="{'color': themeColor}">
                        email </span>{{nationContactInfo.email}} </p>
                <p> <span class="material-icons" style="margin-right: 10px;" [ngStyle]="{'color': themeColor}">
                        phone </span>{{nationContactInfo.telephone}}
                </p>
            </div>
        </div>
    </div>

    <div id="housingmodal" class="modal">
        <div class="modal-content">
            <h6><strong> My House </strong></h6>
            <!-- <p>This module requires the (nation Name)
            to be using our housing Software. It allows
            members to apply for housing, renovation and house
            inspections...
            </p> -->
            <p>
                Please contact the Housing Department to set up your house to be able to apply for housing, renovation
                and house inspections...
            </p>
        </div>
        <div class="modal-footer">
            <a class="modal-close waves-effect waves-green btn-flat">Close</a>
        </div>
    </div>
</div>