import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { DatePipe, Location } from '@angular/common';
import { environment } from 'src/environments/environment';
import { HousingApplicationModel } from 'src/app/models/HousingModel';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { MemberService } from 'src/app/services/api/member.service';
import { Attachment } from 'src/app/models/emailModel';
import { MediaService } from 'src/app/services/api/media.service';
import SignaturePad from 'signature_pad';
import { toast } from 'materialize-css';
import { HousingService } from 'src/app/services/firebase/housing.service';

const pdfMake = require('pdfmake/build/pdfmake.js');
const pdfFonts = require('pdfmake-unicode/dist/pdfmake-unicode.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-housing-application',
  templateUrl: './housing-application.component.html',
  styleUrls: ['./housing-application.component.css']
})
export class HousingApplicationComponent implements OnInit {

  provinceOfUse = environment.firstNation.province;
  title = ['Mr', 'Mrs', 'Dr', 'Miss'];
  maritalStatus = ["Single", "Married", "Common Law", "Separated", "Divorced"];
  accommodations = ["Own/Co-Own", "Rent", "Shelter", "Homeless", "Other"];
  provinces = [{ name: 'Alberta', code: 'AB' },
  { name: 'British Columbia', code: 'BC' },
  { name: 'Manitoba', code: 'MB' },
  { name: 'New Brunswick', code: 'NB' },
  { name: 'Newfoundland and Labrador', code: 'NL' },
  { name: 'Nova Scotia', code: 'NS' },
  { name: 'Ontario', code: 'ON' },
  { name: 'Prince Edward Island', code: 'PE' },
  { name: 'Quebec', code: 'QC' },
  { name: 'Saskatchewan', code: 'SK' }
  ];
  relationships = ["Child", "Grandchild", "Parent", "Grandparent", "Other Relative"];
  incomeSource = ["Works", "Disability Support Program", "Full-time Employment", "Part-time Employment", "Self Employed", "Rent Revenue", "Employment Insurance", "WSIB (Short Term)", "WSIB (Long Term)", "Canada Pension", "Education Living Allowance", "Other"];
  Documents = ["Proof of First Nation Registration", "Child Welfare Documentation", "Verification of all Sources of Income", "Accessibility Needs", "Reference Letters"];

  public phoneNumberMask = ['(', /[0-9]/, /[0-9]/, /[0-9]/, ')',
    ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  public extMask = [/[0-9]/, /[0-9]/, /[0-9]/];
  public dateMask = [/[0-3]/, /[0-9]/, '/', /[0-1]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  public SINMask = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  public postalCodeMask = [/[A-Za-z]/, /[0-9]/, /[A-Za-z]/, ' ', /[0-9]/, /[A-Za-z]/, /[0-9]/];
  public dateformat = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;

  loading = false;
  memberId: any;
  member: any;
  houseForm: UntypedFormGroup;
  step = 1;
  dobSet = false;
  dobValue: any;
  other = false;
  rent = false;
  Co_Own = false;
  moveInDateValue: any;
  moveInDateSet = false;
  expireDateValue: any;
  expireDateSet = false;
  accessibility = false;
  memberReside = false;
  healthSafety = false;
  co_dobValue: any;
  co_dobSet = false;
  addOccupants = false;
  occ_dobSet = false;
  occ_dobValue: any;
  inSchool = false;
  specialNeeds = false;
  model: HousingApplicationModel;
  other_relative = false;
  add_income = false;
  name = '';
  role = '';
  incomeForm: UntypedFormGroup;
  Incomes: any[] = [];
  upload = false;
  documentForm: UntypedFormGroup;
  documentsAttached = [false, false, false, false, false];
  uploadedDocuments: any[];
  binDoc: File;
  binDocList: any[] = [];
  fileList: any = [];
  showDeclaration = false;
  applicantSigned = false;
  co_applicantSigned = false;
  canvas: any;
  signaturePad: SignaturePad;
  signing = '';
  showCanvas = false;
  showOccupants = [];
  showIncome = [];
  fileName = 'Application For Housing';

  constructor(private authService: AuthService, 
    private memberService: MemberService, 
    private fb: UntypedFormBuilder, 
    private mediaService: MediaService, 
    private housingService: HousingService, 
    private location: Location) { }

  ngOnInit() {
    this.memberId = this.authService.getCurrentApplicantID();
    if (this.memberId) {
      const datePipe = new DatePipe('en-US');
      const today = datePipe.transform(new Date, 'dd/MM/yyyy');
      let provinceOfUse = this.provinces.find(x => x.name == this.provinceOfUse).code;

      this.houseForm = this.fb.group({
        applicantInfo: this.fb.group({
          givenName: ['', [Validators.required]],
          middleName: [''],
          lastName: ['', [Validators.required]],
          aliasName: [''],
          title: [''],
          maritalStatus: ['', [Validators.required]],
          dob: ['', [Validators.required, this.isValidDate]],
          SIN: ['', [Validators.minLength(9), Validators.maxLength(9), Validators.pattern('^[0-9]*$')]],
          FWFN: [''],
          registrationNo: ['', [Validators.required]],
        }),
        contactInfo: this.fb.group({
          //telephone: [this.member.Telephone, [Validators.required]],
          telephone: ['', [Validators.required]],
          telephone2: [''],
          //cellphone: [this.member.cell_phone_num],
          cellphone: [''],
          cellphone2: [''],
          workNumber: [''],
          ext: [''],
          address: ['', [Validators.required]],
          city: ['', [Validators.required]],
          province: [provinceOfUse, [Validators.required]],
          postalCode: ['', [Validators.required]],
        }),
        accommodation: this.fb.group({
          accomodationAddress: ['', [Validators.required]],
          homeInfo: ['', [Validators.required]],
          otherAccoummodation: [''],

          co_applicant: this.fb.group({
            givenName: [''],
            middleName: [''],
            lastName: [''],
            aliasName: [''],
            title: [''],
            age: [''],
            gender: [''],
            maritalStatus: [''],
            dob: [''],
            SIN: [''],
            isn: [''],
            bandName: [''],
            registrationNo: [''],
          }),

          landLord: this.fb.group({
            givenName: [''],
            lastName: [''],
            address: [''],
            province: [provinceOfUse],
            city: [''],
            postalCode: [''],
            moveInDate: [''],
            leaseAgreement: [],
            expireDate: [''],
          }),
        }),
        accessibility: this.fb.group({
          accessibilityNeeds: [''],
          accessibilityExplain: [''],
          reside: [''],
          resideExplain: [''],
          healthSafetyIssues: [''],
          healthSafetyExplain: [''],
        }),
        occupants: this.fb.group({
          add_occupants: [],
          occupants: this.fb.array([]),
          givenName: [''],
          lastName: [''],
          middleName: [''],
          dob: [''],
          SIN: [''],
          age: [''],
          gender: [''],
          inSchool: [],
          school: [''],
          needs: [],
          specialNeeds: [''],
          relation: [''],
          other_relative: [''],
        }),
        incomeVerification: this.fb.group({
          add_income: [''],
          source: [''],
          amount: [''],
          total: [''],
          incomes: this.fb.array([]),
        }),
        signature: this.fb.group({
          applicantSignature: [''],
          applicantDate: [today],
          co_applicantSignature: [''],
          co_applicantDate: [today],
        }),
        agree: [],
      });

      this.houseForm.get('accommodation.homeInfo').valueChanges.subscribe(x => {
        if (x == 'Co-Own') {
          this.houseForm.get('accommodation.co_applicant.givenName').setValidators(Validators.required);
          this.houseForm.get('accommodation.co_applicant.lastName').setValidators(Validators.required);
          this.houseForm.get('accommodation.co_applicant.lastName').setValidators([Validators.required, this.isValidDate]);
          this.houseForm.get('accommodation.co_applicant.registrationNo').setValidators([Validators.required]);
        } else if (x == 'Rent') {
          this.houseForm.get('accommodation.landLord.givenName').setValidators(Validators.required);
          this.houseForm.get('accommodation.landLord.lastName').setValidators(Validators.required);
        } else if (x == 'Other') this.houseForm.get('accommodation.otherAccoummodation').setValidators(Validators.required);
        else {
          this.houseForm.get('accommodation.co_applicant.givenName').clearValidators();
          this.houseForm.get('accommodation.co_applicant.lastName').clearValidators();
          this.houseForm.get('accommodation.co_applicant.lastName').clearValidators();
          this.houseForm.get('accommodation.co_applicant.registrationNo').clearValidators();
          this.houseForm.get('accommodation.landLord.givenName').clearValidators();
          this.houseForm.get('accommodation.landLord.lastName').clearValidators();
          this.houseForm.get('accommodation.otherAccoummodation').clearValidators;
        }
        this.houseForm.get('accommodation.co_applicant.givenName').updateValueAndValidity();
        this.houseForm.get('accommodation.co_applicant.lastName').updateValueAndValidity();
        this.houseForm.get('accommodation.co_applicant.lastName').updateValueAndValidity();
        this.houseForm.get('accommodation.co_applicant.registrationNo').updateValueAndValidity();
        this.houseForm.get('accommodation.landLord.givenName').updateValueAndValidity();
        this.houseForm.get('accommodation.landLord.lastName').updateValueAndValidity();
        this.houseForm.get('accommodation.otherAccoummodation').updateValueAndValidity;
      });

      this.houseForm.get('occupants.add_occupants').valueChanges.subscribe(x => {
        if (x) {
          this.houseForm.get('occupants.givenName').setValidators(Validators.required);
          this.houseForm.get('occupants.lastName').setValidators(Validators.required);
        } else {
          this.houseForm.get('occupants.givenName').clearValidators();
          this.houseForm.get('occupants.lastName').clearValidators();
        }
        this.houseForm.get('occupants.givenName').updateValueAndValidity();
        this.houseForm.get('occupants.lastName').updateValueAndValidity();
      });

      this.houseForm.get('incomeVerification.add_income').valueChanges.subscribe(x => {
        if (x) {
          this.houseForm.get('incomeVerification.source').setValidators(Validators.required);
          this.houseForm.get('incomeVerification.amount').setValidators(Validators.required);
        } else {
          this.houseForm.get('incomeVerification.source').clearValidators();
          this.houseForm.get('incomeVerification.amount').clearValidators();
        }
        this.houseForm.get('incomeVerification.source').updateValueAndValidity();
        this.houseForm.get('incomeVerification.amount').updateValueAndValidity();
      });

      this.incomeForm = this.fb.group({
        source: ['', [Validators.required]],
        amount: ['', [Validators.required]],
      });
      this.documentForm = this.fb.group({
        description: [],
        file: [],
        link: []
      });

      this.model = new HousingApplicationModel();
      setTimeout(() => {
        $('select').formSelect();
        M.updateTextFields();
      }, 25);
      //   }
      // });
    }

    this.model.givenName = "Mihret";
    this.model.lastName = "Tamene";
    this.model.co_applicant_givenName = "Abebe";
    this.model.co_applicant_lastName = 'Kebede';
    this.model.homeInfo = 'Co-Own';
    this.model.accessibilityNeeds = true;
    this.model.accessibilityExplain = "ABCDEFG ABEKSJfsdhfkashdfhuaslkhcmx";
    this.model.reside = false;
    this.model.resideExplain = "Explaination";
    this.model.healthSafetyIssues = true;
    this.model.healthSafetyExplain = "Explaination"
  }

  get givenName() { return this.houseForm.get('applicantInfo.givenName'); }
  get lastName() { return this.houseForm.get('applicantInfo.lastName'); }
  get dob() { return this.houseForm.get('applicantInfo.dob'); }
  get SIN() { return this.houseForm.get('applicantInfo.SIN'); }
  get registrationNo() { return this.houseForm.get('applicantInfo.registrationNo'); }
  get telephone() { return this.houseForm.get('contactInfo.telephone'); }
  get city() { return this.houseForm.get('contactInfo.city'); }
  get address() { return this.houseForm.get('contactInfo.address'); }
  get postalCode() { return this.houseForm.get('contactInfo.postalCode'); }
  get otherAccoummodation() { return this.houseForm.get('accommodation.otherAccoummodation'); }
  get co_givenName() { return this.houseForm.get('accommodation.co_applicant.givenName'); }
  get co_lastName() { return this.houseForm.get('accommodation.co_applicant.lastName'); }
  get co_dob() { return this.houseForm.get('accommodation.co_applicant.dob'); }

  get landlord_givenName() { return this.houseForm.get('accommodation.landLord.givenName'); }
  get landlord_lastName() { return this.houseForm.get('accommodation.landLord.lastName'); }
  get moveInDate() { return this.houseForm.get('accommodation.landLord.moveInDate'); }
  get expireDate() { return this.houseForm.get('accommodation.landLord.expireDate'); }

  get add_occupants() { return this.houseForm.get('occupants.add_occupants'); }
  get occupants() { return this.houseForm.get('occupants.occupants') as UntypedFormArray; }
  get occupant_dob() { return this.houseForm.get('occupants.dob'); }
  get add_incomes() { return this.houseForm.get('incomeVerification.add_income'); }
  get incomes() { return this.houseForm.get('incomeVerification.incomes') as UntypedFormArray; }
  get applicantSignature() { return this.houseForm.get('signature.applicantSignature'); }
  get co_applicantSignature() { return this.houseForm.get('signature.co_applicantSignature'); }

  isValidDate(control: UntypedFormControl) {
    let date = control.value;
    const dateformat = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
    if (date) {
      if (!date.match(dateformat) && date.length > 10) {
        const datePipe = new DatePipe('en-US');
        date = datePipe.transform(date, 'dd/MM/yyyy');
      }
      else if (date.match(dateformat)) {
        let dateArray = date.split('/');
        let year = dateArray[2];
        let month = dateArray[1] - 1;
        let day = dateArray[0];
        date = new Date(year, month, day);
        const today = new Date();
        if (Number(year) <= Number(today.getFullYear() - 100)) {
          return { 'invalidDate': true }
        } else if (date.valueOf() >= today.valueOf()) {
          return { 'invalidDate': true }
        } else {
          return null;
        }
      } else {
        return { 'invalidDate': true }
      }
    }
    return null;
  }

  setDate(e, control) {
    if (e.match(this.dateformat)) {
      if (control == 'dob') {
        this.dobValue = e;
        const date = this.changeDates(e);
        const datePipe = new DatePipe('en-US');
        const formatedDate = datePipe.transform(date, 'fullDate');
        this.dob.patchValue(formatedDate);
        this.dobSet = true;
      } else if (control == 'moveInDate') {
        this.moveInDateValue = e;
        const date = this.changeDates(e);
        const datePipe = new DatePipe('en-US');
        const formatedDate = datePipe.transform(date, 'fullDate');
        this.moveInDate.patchValue(formatedDate);
        this.moveInDateSet = true;
      } else if (control == 'expireDate') {
        this.expireDateValue = e;
        const date = this.changeDates(e);
        const datePipe = new DatePipe('en-US');
        const formatedDate = datePipe.transform(date, 'fullDate');
        this.expireDate.patchValue(formatedDate);
        this.expireDateSet = true;
      } else if (control == 'co_dob') {
        this.co_dobValue = e;
        const date = this.changeDates(e);
        const datePipe = new DatePipe('en-US');
        const formatedDate = datePipe.transform(date, 'fullDate');
        this.co_dob.patchValue(formatedDate);
        this.co_dobSet = true;
      } else if (control == 'occ_dob') {
        this.occ_dobValue = e;
        const date = this.changeDates(e);
        const datePipe = new DatePipe('en-US');
        const formatedDate = datePipe.transform(date, 'fullDate');
        this.occupant_dob.patchValue(formatedDate);
        this.occ_dobSet = true;
      }
    }
  }

  unSetDate(control) {
    if (control == 'dob') {
      this.dob.patchValue(this.dobValue);
      this.dobSet = false;
    } else if (control == 'moveInDate') {
      this.moveInDate.patchValue(this.moveInDateValue);
      this.moveInDateSet = false;
    } else if (control == 'expireDate') {
      this.expireDate.patchValue(this.expireDateValue);
      this.expireDateSet = false;
    } else if (control == 'co_dob') {
      this.co_dob.patchValue(this.co_dobValue);
      this.co_dobSet = false;
    } else if (control == 'occ_dob') {
      this.occupant_dob.patchValue(this.co_dobValue);
      this.occ_dobSet = false;
    }
  }

  changeDates(dateValue) {
    if (dateValue) {
      if (dateValue.match(this.dateformat)) {
        let dateArray = dateValue.split('/');
        let year = dateArray[2];
        let month = dateArray[1] - 1;
        let day = dateArray[0];
        const date = new Date(year, month, day);
        return date;
      } else {
        const date = new Date(dateValue);
        return date;
      }
    } else {
      return null
    }
  }

  onhouseInfoSelected(houseInfo) {
    if (houseInfo == 'Rent') {
      this.rent = true;
      this.Co_Own = false;
      this.other = false;
    } else if (houseInfo == 'Other') {
      this.other = true;
      this.rent = false;
      this.Co_Own = false;
    } else if (houseInfo == 'Co-Own') {
      this.Co_Own = true;
      this.rent = false;
      this.other = false;
    } else {
      this.rent = false;
      this.Co_Own = false;
      this.other = false;
    }
    setTimeout(() => {
      M.updateTextFields();
      $('select').formSelect();
    }, 25);
  }

  onOtherNo() {
    this.other = false;
  }

  onAccessibilityYes() {
    this.accessibility = true;
    setTimeout(() => {
      M.updateTextFields();
      M.textareaAutoResize($('#accessibilityExplain'));
    }, 25);
  }

  onAccessibilityNo() {
    this.accessibility = false;
  }

  resideYes() {
    this.memberReside = false;
  }

  resideNo() {
    this.memberReside = true;
    setTimeout(() => {
      M.updateTextFields();
      M.textareaAutoResize($('#resideExplain'));
    }, 25);
  }

  healthSafetyYes() {
    this.healthSafety = true;
    setTimeout(() => {
      M.updateTextFields();
      M.textareaAutoResize($('#healthSafetyExplain'));
    }, 25);
  }

  healthSafetyNo() {
    this.healthSafety = false;
  }

  onAddOccupantsYes() {
    this.addOccupants = true;
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }

  onAddOccupantsNo() {
    this.addOccupants = false;
    const occupants = this.occupants;
    while (occupants.length !== 0) {
      occupants.removeAt(0);
    }
  }

  inSchoolYes() {
    this.inSchool = true;
    setTimeout(() => {
      M.updateTextFields();
    }, 25);
  }

  inSchoolNo() {
    this.inSchool = false;
  }

  needsYes() {
    this.specialNeeds = true;
    setTimeout(() => {
      M.updateTextFields();
    }, 25);
  }

  needsNo() {
    this.specialNeeds = false;
  }

  onAgreeYes() {
  }

  onAgreeNo() {
  }

  newOccupant(values) {
    return this.fb.group({
      givenName: [values.givenName],
      lastName: [values.lastName],
      middleName: [values.middleName],
      dob: [values.dob],
      SIN: [values.SIN],
      age: [values.age],
      gender: [values.gender],
      inSchool: [values.inSchool],
      school: [values.school],
      needs: [values.needs],
      specialNeeds: [values.specialNeeds],
      relation: [values.relation],
      other_relative: [values.other_relative]
    });
  }

  addOccupant(values) {
    const occupants = this.occupants;
    occupants.push(this.newOccupant(values));
    this.addOccupants = false;
    this.add_occupants.reset();
    this.houseForm.get('occupants.givenName').reset();
    this.houseForm.get('occupants.lastName').reset();
    this.houseForm.get('occupants.middleName').reset();
    this.houseForm.get('occupants.dob').reset();
    this.houseForm.get('occupants.age').reset();
    this.houseForm.get('occupants.gender').reset();

    this.houseForm.get('occupants.inSchool').reset();
    this.houseForm.get('occupants.school').reset();
    this.houseForm.get('occupants.needs').reset();
    this.houseForm.get('occupants.specialNeeds').reset();
    this.houseForm.get('occupants.relation').reset();
    this.houseForm.get('occupants.other_relative').reset();

    setTimeout(() => {
      $('.collapsible').collapsible();
    }, 25);
  }

  removeOccupant(i) {
    const occupant = this.occupants;
    occupant.removeAt(i);
    if (occupant.length == 0) {
      this.addOccupants = false;
      this.add_occupants.patchValue(false);
    }
  }

  closeAddOccupant() {
    this.addOccupants = false;
    this.add_occupants.patchValue(false);
  }

  relationChanged(value) {
    if (value == "Other Relative") {
      this.other_relative = true;
    } else {
      this.other_relative = false;
    }
  }

  onAddIncome(person, index) {
    if (person == 'applicant') {
     
      this.name = this.model.givenName + " " + this.model.lastName;
      //this.name = 'Applicant Name';
      this.role = 'Applicant';
    } else if (person == 'co-applicant') {
     
      this.name = this.model.co_applicant_givenName + " " + this.model.co_applicant_lastName;
      this.role = 'Co-Applicant';
    } else if (person == 'occupant') {
    
      //this.name = this.model.occupants[index].givenName + " " +  this.model.occupants[index].lastName;
      this.name = "occupant Name";
      this.role = 'Occupant';
      //this.name = "Occupant Name";
    }
    this.add_income = true;
    $('.modal').modal();
    $('#incomeModal').modal('open');
  }

  addIncome(values) {
    const found = this.Incomes.some(el => el.name == this.name);
    if (!found) {
      const income = {
        name: this.name,
        role: this.role,
        total: values.amount,
        incomes: [{ source: values.source, amount: values.amount }]
      }
      this.Incomes.push(income);
    } else {
      const index = this.Incomes.findIndex(ele => ele.name == this.name);
      const source = { source: values.source, amount: values.amount };
      this.Incomes[index].incomes.push(source);
      this.Incomes[index].total += values.amount;
    }
  
    this.incomeForm.reset();
   
    this.incomeForm.get('source').patchValue("");
    $('#incomeModal').modal('close');
  }

  newIncome(values) {
    return this.fb.group({
      name: [this.name],
      role: [this.role],
      total: [],
      income_source: this.fb.array([this.newSource(values)]),
    });
  }

  newSource(values) {
    return this.fb.group({
      source: [values.source],
      amount: [values.amount]
    });
  }

  addSource() {
  }

  filterIncomes(role) {
    const memberIncome = this.Incomes.filter(x => x.role == role);
    if (memberIncome.length > 0) return memberIncome[0];
    else return null;
  }

  getTotoal() {
  }

  onUpload() {
    this.upload = true;
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }

  handleFileInput(files: FileList, values) {
   
    this.binDoc = undefined;
    // this.binDocList = [];
    if (files) {
   
      for (let i = 0; i < files.length; i++) {
        this.binDocList.push(files[i]);
      }
     
    }
  }

  uploadDoc(values) {
   
    for (let i = 0; i < this.binDocList.length; i++) {
      let file = this.binDocList[i];
      if (this.validateInput(file.name) == 'image') {
        this.loading = true;
        this.mediaService.UploadBinImage(file).subscribe(upload => {
          if (upload) {
            let att = new Attachment();
            att.url = upload.imgLink;
            att.name = file.name;
            att.type = 'Image';
            this.fileList.push(att);
          }
        
          this.loading = false;
        });
      } else if (this.validateInput(file.name) == 'pdf') {
        this.loading = true;
        this.mediaService.uploadPDF(file).subscribe(upload => {
          if (upload) {
            let att = new Attachment();
            att.url = upload.imgLink;
            att.name = file.name;
            att.type = 'PDF';
            this.fileList.push(att);
          }
        
          this.loading = false;
        });
      }
    }
    const index = this.Documents.indexOf(values.description);
    this.documentsAttached[index] = true;
    this.upload = false;
  }

  private validateInput(name: any) {
    const ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() === 'png') {
      return 'image';
    } else if (ext.toLowerCase() === 'jpg') {
      return 'image';
    } else if (ext.toLowerCase() === 'jpeg') {
      return 'image';
    } 
    else if (ext.toLowerCase() === 'svg') {
      return 'image';
    }
    else if (ext.toLowerCase() === 'webp') {
      return 'image';
    }else if (ext.toLowerCase() === 'mp4' || ext.toLowerCase() === 'mkv') {
      return 'video';
    } else if (ext.toLowerCase() === 'pdf') {
      return 'pdf';
    } else {
      return false;
    }
  }

  cancelUpload() {
    this.upload = false;
  }

  showFullDeclaration() {
    this.showDeclaration = !this.showDeclaration;
  }

  sign(signing) {
    this.showCanvas = true;
    this.signing = signing;
    setTimeout(() => {
      this.canvas = document.querySelector('canvas');
      const parentWidth = $(this.canvas).parent().outerWidth();
      this.canvas.setAttribute('width', parentWidth);
      this.canvas.setAttribute('height', '175px');
      this.signaturePad = new SignaturePad(this.canvas, {
        backgroundColor: 'rgb(255,255,255)'
      });
    }, 50);

  }

  approveSignature() {
    if (this.signaturePad.isEmpty()) {
      toast({ html: 'Please Sign the Application!', classes: 'red' });
      return;
    } else {
      const dataUrl = this.signaturePad.toDataURL().replace('data:image/png;base64,', '');
      if (this.signing == 'applicant') {
        this.applicantSignature.patchValue(dataUrl);
        this.applicantSigned = true;
      } else {
        this.co_applicantSignature.patchValue(dataUrl);
        this.co_applicantSigned = true;
      }
    }
    toast({ html: 'Application Signed', classes: 'green' });
    this.BackToApp();
  }

  clearSignature(i) {
    if (i && i == 1) {
      this.applicantSignature.reset();
      this.applicantSigned = false;
    } else if (i && i == 2) {
      this.co_applicantSignature.reset();
      this.co_applicantSigned = false;
    } else
      this.signaturePad.clear();
  }

  BackToApp() {
    this.showCanvas = false;
    setTimeout(() => {
      M.updateTextFields();
    }, 25);
  }

  closeModal() {
    $('.modal').modal('close');
  }

  onNext() {
    this.step += 1;
    setTimeout(() => {
      $('select').formSelect();
      $('.collapsible').collapsible();
      $('.tooltipped').tooltip();
      M.updateTextFields();
      $('.dropdown-trigger').dropdown();
      $('.dropdown-trigger').dropdown({ constrainWidth: false });
    }, 25);
    window.scrollTo(0, 0);
  }

  onBack() {
    this.step -= 1;
    setTimeout(() => {
      $('select').formSelect();
      $('.collapsible').collapsible();
      $('.tooltipped').tooltip();
      M.updateTextFields();
    }, 25);
    window.scrollTo(0, 0);
  }

  goto(step) {
    this.step = step;
    setTimeout(() => {
      M.updateTextFields();
      $('select').formSelect();
    }, 25);
    window.scrollTo(0, 0);
  }

  expantOccupatDetails(i) {
    this.showOccupants[i] = !this.showOccupants[i];
  }

  expandIncomeInfo(i) {
    this.showIncome[i] = !this.showIncome[i]
  }

  submitApplicantInfo(values) {
    this.model.applicantID = this.memberId;
    this.model.givenName = values.givenName;
    this.model.lastName = values.lastName;
    this.model.middleName = values.middleName;
    this.model.title = values.title;
    this.model.aliasName = values.aliasName;
    this.model.dob = this.changeDates(values.dob);
    this.model.maritalStatus = values.maritalStatus;
    this.model.SIN = values.SIN;
    this.model.FWFN = values.FWFN;
    this.onNext();
  }

  submitContactInfo(values) {
   
    this.model.telephone = values.telephone;
    this.model.telephone2 = values.telephone2;
    this.model.cellphone = values.cellphone;
    this.model.cellphone2 = values.cellphone2;
    this.model.workNumber = values.workNumber;
    this.model.ext = values.ext;
    this.model.address = values.address;
    this.model.city = values.city;
    this.model.province = values.province;
    this.model.postalCode = values.postalCode;
 
    this.onNext();
  }

  submitAccommodationInfo(values) {
   
    this.model.accomodationAddress = values.accomodationAddress;
    this.model.homeInfo = values.homeInfo;

    this.model.co_applicant_givenName = values.co_applicant.givenName;
    this.model.co_applicant_lastName = values.co_applicant.lastName;
    this.model.co_applicant_middleName = values.co_applicant.middleName;
    this.model.co_applicant_aliasName = values.co_applicant.aliasName;
    this.model.co_applicant_title = values.co_applicant.title;
    //this.model.co_applicant_age = values.con_applicant.age '';
    this.model.co_applicant_gender = values.co_applicant.gender;
    this.model.co_applicant_maritalStatus = values.co_applicant.maritalStatus;
    this.model.co_applicant_dob = this.changeDates(values.co_applicant.dob);
    this.model.co_applicant_SIN = values.co_applicant.SIN;
    this.model.co_applicant_bandName = values.co_applicant.bandName;
    this.model.co_applicant_registrationNo = values.co_applicant.registrationNo;

    this.model.landLord_givenName = values.landLord.givenName;
    this.model.landLord_lastName = values.landLord.lastName;
    this.model.landLord_address = values.landLord.address;
    this.model.landLord_province = values.landLord.province;
    this.model.landLord_city = values.landLord.city;
    this.model.landLord_postalCode = values.landLord.postalCode;
    this.model.landLord_moveInDate = this.changeDates(values.landLord.moveInDate);
    this.model.landLord_leaseAgreement = values.landLord.leaseAgreement;
    this.model.landLord_expireDate = this.changeDates(values.landLord.expireDate);

    this.model.otherAccoummodation = values.otherAccoummodation;

    this.onNext();
  }

  submitAccessibilityInfo(values) {
  
    this.model.accessibilityNeeds = values.accessibilityNeeds;
    this.model.accessibilityExplain = values.accessibilityExplain;
    this.model.reside = values.reside;
    this.model.resideExplain = values.resideExplain;
    this.model.healthSafetyIssues = values.healthSafetyIssues;
    this.model.healthSafetyExplain = values.healthSafetyExplain;
 
  }

  submitOccupantsInfo(values) {
  
    this.model.occupants = values.occupants;
    for (let i = 0; i < this.model.occupants.length; i++) {
      this.showOccupants.push(false);
    }
   
    this.onNext();
  }

  submitIncomeInfo() {
    this.model.incomes = this.Incomes;
    for (let i = 0; i < this.model.incomes.length; i++) {
      this.showIncome.push(false);
    }
  
    this.onNext();
  }

  submitFiles() {
    this.model.files = this.fileList;
   
    this.onNext();
  }

  submitSignatures(values) {
 
    this.model.applicantSignature = values.applicantSignature;
    this.model.applicantDate = values.applicantDate;
    this.model.co_applicantSignature = values.co_applicantSignature;
    this.model.co_applicantDate = values.co_applicantDate;
  
    this.onNext();
  }

  submitForm() {
    this.housingService.saveApplication(this.model).then(x => {
      toast({ html: 'Applicantion Successfully Send!', classes: 'green' });
      this.back();
    });
  }

  back() {
    this.location.back();
  }

  async getReport() {
    let report = {
      info: {
        title: 'Application For Housing',
        subject: 'Application For Housing',
        keywords: 'Housing Application',
      },
      pageSize: 'LETTER',
      content: await this.getContent(),
      styles: this.getStyles(),
    }
    return report;
  }

  async getContent() {
    this.model.occupants = [];
    this.model.incomes = [];

    const datePipe = new DatePipe('en-US');
    let content = [];
    content.push(
      {
        style: 'table',
        layout: 'noBorders',
        table: {
          widths: ['*', '*'],
          body: [
            [
              {
                columns: [
                  [
                    await this.getLogo()
                  ],
                  [
                    {
                      stack: [
                        {
                          text: 'MY NATION ',
                          style: 'mainTitle',
                          alignment: 'center',
                        },
                        {
                          text: 'Connecting Your Nation',
                          style: 'normalText',
                          alignment: 'center',
                        },
                        {
                          text: 'Sovereign Data For Sovereign Nations ',
                          fontSize: 8,
                          italics: true,
                          alignment: 'center',
                        },
                      ]
                    },
                  ]
                ],
              },
              {
                stack: [
                  {
                    text: "Housing Department",
                    style: 'title'
                  },
                  {
                    text: 'Application For Housing',
                    style: 'title'
                  },
                  {},
                ]
              },
            ],
          ]
        }
      },

      {
        style: 'table',
        table: {
          widths: ['*', '*', '*'],
          body: [
            [
              {
                text: 'PRIMARY APPLICANT DETAILS',
                style: 'subTitle',
                bold: true,
                colSpan: 3
              },
              {},
              {}
            ],
            [
              {
                stack: [
                  {
                    text: 'Last Name: ',
                    style: 'subTitle'
                  },
                  {
                    text: this.model.lastName ? this.model.lastName : "",
                    style: 'fields'
                  },
                ],
              },
              {
                stack: [
                  {
                    text: 'First Name: ',
                    style: 'subTitle'
                  },
                  {
                    text: this.model.givenName ? this.model.givenName : "",
                    style: 'fields'
                  },
                ],
              },
              {
                stack: [
                  {
                    text: 'Middle Name: ',
                    style: 'subTitle'
                  },
                  {
                    text: this.model.middleName ? this.model.middleName : "",
                    style: 'fields'
                  },
                ],
              },
            ],
            [
              {
                stack: [
                  {
                    text: 'Title: ',
                    style: 'subTitle'
                  },
                  {
                    text: this.model.title ? this.model.title : "",
                    style: 'fields'
                  },
                ],
              },
              {
                stack: [
                  {
                    text: 'Alias/Maiden Name: ',
                    style: 'subTitle'
                  },
                  {
                    text: this.model.aliasName ? this.model.aliasName : "",
                    style: 'fields'
                  },
                ],
                colSpan: 2
              },
              {},

            ],
            [
              {
                stack: [
                  {
                    text: 'Marital Status: ',
                    style: 'subTitle'
                  },
                  {
                    text: this.model.maritalStatus ? this.model.maritalStatus : '',
                    style: 'fields'
                  },
                ],
              },
              {
                stack: [
                  {
                    text: 'Date of Birth: ',
                    style: 'subTitle'
                  },
                  {
                    text: this.model.dob ? datePipe.transform(this.model.dob, 'fullDate') : '',
                    style: 'fields'
                  },
                ],
                colSpan: 2
              },
              {}
            ],
            [
              {
                stack: [
                  {
                    text: 'SIN: ',
                    style: 'subTitle'
                  },
                  {
                    text: this.model.SIN ? this.model.SIN : '',
                    style: 'fields'
                  },
                ],
              },
              {
                stack: [
                  {
                    text: 'Member of FWFN: ',
                    style: 'subTitle'
                  },
                  {
                    text: this.model.FWFN ? 'Yes' : 'No',
                    style: 'fields'
                  },
                ],
              },
              {
                stack: [
                  {
                    text: 'First Nation Registration Number: ',
                    style: 'subTitle'
                  },
                  {
                    text: this.model.registrationNo ? this.model.registrationNo : '',
                    style: 'fields'
                  },
                ],
              },
            ],
            [
              {
                text: 'APPLICANT’S CONTACT INFORMATION',
                style: 'subTitle',
                bold: true,
                colSpan: 3
              },
              {},
              {}
            ],
            [
              {
                stack: [
                  {
                    text: 'Telephone Number: ',
                    style: 'subTitle'
                  },
                  {
                    text: this.model.telephone ? this.model.telephone : '',
                    style: 'fields'
                  },
                ],
              },
              {
                stack: [
                  {
                    text: 'Cellular Number:: ',
                    style: 'subTitle'
                  },
                  {
                    text: this.model.cellphone ? this.model.cellphone : '',
                    style: 'fields'
                  },
                ],
                colSpan: 2
              },
              {},
            ],
            [
              {
                stack: [
                  {
                    text: 'Work Number: ',
                    style: 'subTitle'
                  },
                  {
                    text: this.model.workNumber ? this.model.workNumber : '',
                    style: 'fields'
                  },
                ],
                colSpan: 2
              },
              {},
              {
                stack: [
                  {
                    text: 'Extention ',
                    style: 'subTitle'
                  },
                  {
                    text: this.model.ext ? this.model.ext : '',
                    style: 'fields'
                  },
                ],
              },
            ],
            [
              {
                text: 'ALTERNATE CONTACT INFORMATION',
                style: 'subTitle',
                bold: true,
                colSpan: 3
              },
              {},
              {}
            ],
            [
              {
                stack: [
                  {
                    text: 'Telephone Number: ',
                    style: 'subTitle'
                  },
                  {
                    text: this.model.telephone2 ? this.model.telephone2 : '',
                    style: 'fields'
                  },
                ],
              },
              {
                stack: [
                  {
                    text: 'Cellular Number:: ',
                    style: 'subTitle'
                  },
                  {
                    text: this.model.cellphone2 ? this.model.cellphone2 : '',
                    style: 'fields'
                  },
                ],
                colSpan: 2
              },
              {},
            ],
            [
              {
                text: 'APPLICANT’S MAILING ADDRESS',
                style: 'subTitle',
                bold: true,
                colSpan: 3
              },
              {},
              {}
            ],
            [
              {
                stack: [
                  {
                    text: 'Address: ',
                    style: 'subTitle'
                  },
                  {
                    text: this.model.address ? this.model.address : '',
                    style: 'fields'
                  },
                ],
                colSpan: 2
              },
              {},
              {
                stack: [
                  {
                    text: 'Province: ',
                    style: 'subTitle'
                  },
                  {
                    text: this.model.province ? this.model.province : '',
                    style: 'fields'
                  },
                ],
              },
            ],
            [
              {
                stack: [
                  {
                    text: 'City: ',
                    style: 'subTitle'
                  },
                  {
                    text: this.model.city ? this.model.city : '',
                    style: 'fields'
                  },
                ],
                colSpan: 2
              },
              {},
              {
                stack: [
                  {
                    text: 'Postal Code: ',
                    style: 'subTitle'
                  },
                  {
                    text: this.model.postalCode ? this.model.postalCode : '',
                    style: 'fields'
                  },
                ],
              },
            ],
          ]
        }
      },

      {
        text: "",
        pageBreak: 'after'
      },

      {
        style: 'table',
        table: {
          widths: ['*', '*', '*'],
          body: [
            [
              {
                text: 'PRESENT ACCOMMODATION',
                style: 'subTitle',
                bold: true,
                colSpan: 3
              },
              {},
              {}
            ],
            [
              {
                stack: [
                  {
                    text: 'Address of Accommodation: ',
                    style: 'subTitle'
                  },
                  {
                    text: this.model.accomodationAddress ? this.model.accomodationAddress : "",
                    style: 'fields'
                  },
                ],
                colSpan: 2
              },
              {},
              {
                stack: [
                  {
                    text: 'Home Information: ',
                    style: 'subTitle'
                  },
                  {
                    text: this.model.homeInfo ? this.model.homeInfo == 'Other' ? this.model.otherAccoummodation : this.model.homeInfo : '',
                    style: 'fields'
                  },
                ],
              },
            ]
          ]
        }
      },

      this.model.homeInfo == 'Co-Own' ?
        {
          style: 'table',
          table: {
            widths: ['*', '*', '*'],
            body: [
              [
                {
                  text: 'CO-APPLICANT DETAILS',
                  style: 'subTitle',
                  bold: true,
                  colSpan: 3
                },
                {},
                {}
              ],
              [
                {
                  stack: [
                    {
                      text: 'Last Name: ',
                      style: 'subTitle'
                    },
                    {
                      text: this.model.co_applicant_lastName ? this.model.co_applicant_lastName : "",
                      style: 'fields'
                    },
                  ],
                },
                {
                  stack: [
                    {
                      text: 'First Name: ',
                      style: 'subTitle'
                    },
                    {
                      text: this.model.co_applicant_givenName ? this.model.co_applicant_givenName : "",
                      style: 'fields'
                    },
                  ],
                },
                {
                  stack: [
                    {
                      text: 'Middle Name: ',
                      style: 'subTitle'
                    },
                    {
                      text: this.model.middleName ? this.model.middleName : "",
                      style: 'fields'
                    },
                  ],
                },
              ],
              [
                {
                  stack: [
                    {
                      text: 'Title: ',
                      style: 'subTitle'
                    },
                    {
                      text: this.model.co_applicant_title ? this.model.co_applicant_title : "",
                      style: 'fields'
                    },
                  ],
                },
                {
                  stack: [
                    {
                      text: 'Maiden/Alias Name: ',
                      style: 'subTitle'
                    },
                    {
                      text: this.model.co_applicant_aliasName ? this.model.co_applicant_aliasName : "",
                      style: 'fields'
                    },
                  ],
                  colSpan: 2
                },
                {},
              ],
              [
                {
                  stack: [
                    {
                      text: 'Gender: ',
                      style: 'subTitle'
                    },
                    {
                      text: this.model.co_applicant_gender ? this.model.co_applicant_gender : "",
                      style: 'fields'
                    },
                  ],
                },
                {
                  stack: [
                    {
                      text: 'Marital Status: ',
                      style: 'subTitle'
                    },
                    {
                      text: this.model.co_applicant_maritalStatus ? this.model.co_applicant_maritalStatus : "",
                      style: 'fields'
                    },
                  ],
                },
                {
                  stack: [
                    {
                      text: 'Date of Birth: ',
                      style: 'subTitle'
                    },
                    {
                      text: this.model.co_applicant_dob ? datePipe.transform(this.model.co_applicant_dob, 'fullDate') : '',
                      style: 'fields'
                    },
                  ],
                },
              ],
              [
                {
                  stack: [
                    {
                      text: 'SIN: ',
                      style: 'subTitle'
                    },
                    {
                      text: this.model.co_applicant_SIN ? this.model.co_applicant_SIN : "",
                      style: 'fields'
                    },
                  ],
                },
                {
                  stack: [
                    {
                      text: 'Band Name: ',
                      style: 'subTitle'
                    },
                    {
                      text: this.model.co_applicant_bandName ? this.model.co_applicant_bandName : "",
                      style: 'fields'
                    },
                  ],
                },
                {
                  stack: [
                    {
                      text: 'First Nation Registration Number: ',
                      style: 'subTitle'
                    },
                    {
                      text: this.model.co_applicant_registrationNo ? this.model.co_applicant_registrationNo : '',
                      style: 'fields'
                    },
                  ],
                },
              ],
            ]
          }
        } : '',

      this.model.homeInfo == 'Rent' ?
        {
          style: 'table',
          table: {
            widths: ['*', '*', '*'],
            body: [
              [
                {
                  text: 'CURRENT LANDLORD',
                  style: 'subTitle',
                  bold: true,
                  colSpan: 3
                },
                {},
                {}
              ],
              [
                {
                  stack: [
                    {
                      text: 'Last Name: ',
                      style: 'subTitle'
                    },
                    {
                      text: this.model.landLord_lastName ? this.model.landLord_lastName : "",
                      style: 'fields'
                    },
                  ],
                },
                {
                  stack: [
                    {
                      text: 'First Name: ',
                      style: 'subTitle'
                    },
                    {
                      text: this.model.landLord_givenName ? this.model.landLord_givenName : "",
                      style: 'fields'
                    },
                  ],
                },
                {}
              ],
              [
                {
                  stack: [
                    {
                      text: 'Address: ',
                      style: 'subTitle'
                    },
                    {
                      text: this.model.landLord_address ? this.model.landLord_address : "",
                      style: 'fields'
                    },
                  ],
                  colSpan: 2
                },
                {},
                {
                  stack: [
                    {
                      text: 'Province: ',
                      style: 'subTitle'
                    },
                    {
                      text: this.model.landLord_province ? this.model.landLord_province : "",
                      style: 'fields'
                    },
                  ],

                },
              ],
              [
                {
                  stack: [
                    {
                      text: 'City: ',
                      style: 'subTitle'
                    },
                    {
                      text: this.model.landLord_city ? this.model.landLord_city : "",
                      style: 'fields'
                    },
                  ],
                  colSpan: 2
                },
                {},
                {
                  stack: [
                    {
                      text: 'Postal Code: ',
                      style: 'subTitle'
                    },
                    {
                      text: this.model.landLord_postalCode ? this.model.landLord_postalCode : "",
                      style: 'fields'
                    },
                  ],
                },
              ],
              [
                {
                  stack: [
                    {
                      text: 'Lease Agreement: ',
                      style: 'subTitle'
                    },
                    {
                      text: this.model.landLord_leaseAgreement ? 'Yes' : 'No',
                      style: 'fields'
                    },
                  ],
                },
                {
                  stack: [
                    {
                      text: 'Move-In Date:',
                      style: 'subTitle'
                    },
                    {
                      text: this.model.landLord_moveInDate ? datePipe.transform(this.model.landLord_moveInDate, 'fullDate') : "",
                      style: 'fields'
                    },
                  ],
                },
                {
                  stack: [
                    {
                      text: 'Expiry Date: ',
                      style: 'subTitle'
                    },
                    {
                      text: this.model.landLord_expireDate ? datePipe.transform(this.model.landLord_expireDate, 'fullDate') : "",
                      style: 'fields'
                    },
                  ],
                },
              ],
            ]
          }
        } : '',

      {
        style: 'table',
        table: {
          widths: ['*', '*', '*'],
          body: [
            [
              {
                text: 'ACCESSIBILITY',
                style: 'subTitle',
                bold: true,
                colSpan: 3
              },
              {},
              {}
            ],
            [
              {
                text: 'Do you or family members have accessibility needs? ' + " " + this.model.accessibilityNeeds ? 'Yes' : 'No',
                style: 'subTitle',
                colSpan: 3
              },
              {},
              {}
            ],
            this.model.accessibilityNeeds ?
              [
                {
                  text: this.model.accessibilityExplain ? this.model.accessibilityExplain : '',
                  style: 'fields',
                  colSpan: 3
                },
                {},
                {}
              ] : '',
            [
              {
                text: 'Do all household members currently reside at present accommodation? ' + " " + this.model.reside ? 'Yes' : 'No',
                style: 'subTitle',
                colSpan: 3
              },
              {},
              {}
            ],
            !this.model.reside ?
              [
                {
                  text: this.model.resideExplain ? this.model.resideExplain : '',
                  style: 'fields',
                  colSpan: 3
                },
                {},
                {}
              ] : '',
            [
              {
                text: 'Are there any Health and Safety issues at your current accommodation? (Overcrowding, hazards, etc.)? ' + " " + this.model.healthSafetyIssues ? 'Yes' : 'No',
                style: 'subTitle',
                colSpan: 3
              },
              {},
              {}
            ],
            this.model.healthSafetyIssues ?
              [
                {
                  text: this.model.healthSafetyExplain ? this.model.healthSafetyExplain : '',
                  style: 'fields',
                  colSpan: 3
                },
                {},
                {}
              ] : ''
          ]
        }
      },

      this.getOccupants(),
      this.model.incomes.length > 0 ?
        {
          style: 'table',
          table: {
            widths: ['*', '*'],
            body: this.getIncomes()
          }
        } : '',

      // this.model.files.length > 0 ?
      //   {
      //     style: 'table',
      //     table: {
      //       widths: ['*', '*'],
      //       body: this.getDocuments()
      //     }

      //   } : '',
    );
    return content;
  }

  getDocuments() {
  }

  getOccupants() {
    const datePipe = new DatePipe('en-US');
    let occupantsBody = [];
    this.model.occupants.forEach(occupant => {
      occupantsBody.push({
        style: 'table',
        table: {
          widths: ['*', '*', '*'],
          body: [
            [{
              text: 'OTHER OCCUPANT INFORMATION',
              style: 'subTitle',
              bold: true,
              colSpan: 3
            }, {}, {}],
            [
              {
                stack: [
                  {
                    text: 'Last Name: ',
                    style: 'subTitle'
                  },
                  {
                    text: occupant.lastName ? occupant.lastName : "",
                    style: 'fields',
                  },
                ],
              },
              {
                stack: [
                  {
                    text: 'First Name: ',
                    style: 'subTitle'
                  },
                  {
                    text: occupant.givenName ? occupant.givenName : "",
                    style: 'fields',
                  },
                ],
              },
              {
                stack: [
                  {
                    text: 'Middle Name: ',
                    style: 'subTitle'
                  },
                  {
                    text: occupant.middleName ? occupant.middleName : "",
                    style: 'fields',
                  },
                ],
              },
            ],
            [
              {
                stack: [
                  {
                    text: 'Date of Birth: ',
                    style: 'subTitle'
                  },
                  {
                    text: occupant.dob ? datePipe.transform(occupant.dob, 'fullDate') : "",
                    style: 'fields',
                  },
                ],
              },
              {
                stack: [
                  {
                    text: 'SIN: ',
                    style: 'subTitle'
                  },
                  {
                    text: occupant.SIN ? occupant.SIN : "",
                    style: 'fields',
                  },
                ],
              },
              {
                stack: [
                  {
                    text: 'Gender: ',
                    style: 'subTitle'
                  },
                  {
                    text: occupant.gender ? occupant.gender : "",
                    style: 'fields',
                  },
                ],
              },
            ],
            [
              {
                stack: [
                  {
                    text: 'In School: ',
                    style: 'subTitle'
                  },
                  {
                    text: occupant.inSchool ? 'Yes' : 'No',
                    style: 'fields',
                  },
                ],
                colSpan: occupant.inSchool ? 1 : 3
              },
              occupant.inSchool ?
                {
                  stack: [
                    {
                      text: 'School Name: ',
                      style: 'subTitle'
                    },
                    {
                      text: occupant.school ? occupant.school : "",
                      style: 'fields',
                    },
                  ],
                  colSpan: 2,
                } : {},
              {},
            ],
            [
              {
                stack: [
                  {
                    text: 'Special Needs: ',
                    style: 'subTitle'
                  },
                  {
                    text: occupant.needs ? 'Yes' : 'No',
                    style: 'fields',
                  },
                ],
                colSpan: occupant.needs ? 1 : 3
              },
              occupant.needs ?
                {
                  stack: [
                    {
                      text: 'Specify Special Needs: ',
                      style: 'subTitle'
                    },
                    {
                      text: occupant.specialNeeds ? occupant.specialNeeds : "",
                      style: 'fields',
                    },
                  ],
                  colSpan: 2,
                } : {},
              {},
            ],
            [
              {
                stack: [
                  {
                    text: 'Relation to Applicant: ',
                    style: 'subTitle'
                  },
                  {
                    text: occupant.relation ? occupant.relation : '',
                    style: 'fields',
                  },
                ],
                colSpan: 3
              },
              {},
              {},
            ],
          ]
        }
      });
    });

    return occupantsBody;
  };

  getIncomes() {
    let incomesBody = [];
    incomesBody.push([{ text: 'VERIFICATION OF INCOME', style: 'subTitle', bold: true, colSpan: 2 }, {}]);
    this.model.incomes.forEach(income => {
      incomesBody.push(
        [{
          text: income.name ? income.name : "",
          style: 'normalText',
          margin: [5, 10, 5, 10],
          bold: true,
        },
        {
          text: income.role ? income.role : "",
          style: 'normalText',
          margin: [5, 10, 5, 10],
          bold: true,
        },
        ],
        [
          {
            text: "Income Source",
            style: 'normalText',
            alignment: 'center',
            bold: true,
          },
          {
            text: "Monthly Amount",
            style: 'normalText',
            alignment: 'center',
            bold: true,
          }
        ]);
      income.incomes.forEach(source => {
        incomesBody.push([
          { text: source.source ? source.source : '', style: 'normalText' },
          { text: source.amount ? source.amount : '', style: 'normalText' }
        ])
      });
      incomesBody.push([
        {
          text: "Total",
          style: 'normalText',
          alignment: 'right',
          bold: true,
        },
        {
          text: income.total,
          style: 'normalText',
          alignment: 'right',
          bold: true,
          decoration: 'underline',
          decorationStyle: 'double'
        },
      ]);
    });
    return incomesBody;
  }

  async downloadReport() {
    const documentDefinition = await this.getReport();
    pdfMake.createPdf(documentDefinition).download(this.fileName);
  }

  async openReport() {
    const documentDefinition = await this.getReport();
    pdfMake.createPdf(documentDefinition).open();
  }

  async printReport() {
    const documentDefinition = await this.getReport();
    pdfMake.createPdf(documentDefinition).print();
  }

  async sendReport() {
    var fileList = [];
    var fileLink = '';
    var reportFile;
    const documentDefinition = await this.getReport();
    const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
    pdfDocGenerator.getBlob((blob) => {
      reportFile = new File([blob], this.fileName, {
        type: blob.type
      });
      this.mediaService.uploadPDF(reportFile).subscribe(upload => {
        if (upload) {
         
          if (upload.imgLink) fileLink = upload.imgLink
          //this.fileLink = "https://api.mysabenefits.ca/Media/Demo/PDF/0de131ee-7909-44cc-bbbc-8e12fb611dd4-Houses.pdf";
          let report = new Attachment();
          report.url = fileLink;
          report.name = this.fileName;
          report.type = 'PDF';
          this.fileList.push(report);
          //this.emailList.push('tamenemihret@gmail.com');
          // this.sendEmail = true;
        }
      });
    });

  }

  getStyles() {
    return {
      mainTitle: {
        bold: true,
        fontSize: 16,
        alignment: 'left',
        margin: [0, 0, 0, 20]
      },
      title: {
        bold: true,
        fontSize: 14,
        alignment: 'center',
        margin: [0, 10, 0, 10]
      },
      subTitle: {
        //bold: true,
        fontSize: 11,
        alignment: 'left',
        margin: [0, 10, 0, 10]
      },
      normalText: {
        fontSize: 10,
        alignment: 'justify',
      },
      header: {
        fontSize: 12,
        bold: true,
        alignment: 'left',
      },
      links: {
        color: 'blue',
        decoration: 'underline',
        //italics: true,
        margin: [0, 5, 0, 5]
      },
      table: {
        margin: [10, 5, 0, 10]
      },
      fields: {
        color: 'blue',
        fontSize: 10,
      }
    }
  }

  async getLogo() {
    let imageLink = "assets/img/Nation-logo.png";
    const image = await this.getBase64FromUrl(imageLink);
    if (image) {
      return {
        image: image,
        width: 120,
        alignment: 'left'
      }
    }
  }

  getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      }
    });
  }

  openDropdown() {
    $('.dropdown-trigger').dropdown();
    $('.dropdown-trigger').dropdown('open');
    $('.dropdown-trigger').dropdown({ constrainWidth: false });
  }

  backClicked() {
    this.location.back();
  }

}
