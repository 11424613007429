import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { PostModel } from 'src/app/models/SocialMediaPostModel';

@Injectable({
  providedIn: 'root'
})
export class AyrshareService {

  url = environment.socialMedia.url
  apiKey = environment.socialMedia.key
  constructor(private httpClient: HttpClient) { }


  // Post History for shared by ayshare
  getHistory(){
    const headers = new HttpHeaders({'Authorization': `Bearer ${this.apiKey}`,"Content-Type": "application/json"})

    return this.httpClient.get(this.url +'history', { headers })
  }

  getHistoryById(id,profileKey){
    let query = `/${id}?profileKey=${profileKey}`

    const headers = new HttpHeaders({'Authorization': `Bearer ${this.apiKey}`,"Content-Type": "application/json"})

    return this.httpClient.get(this.url +'history' + query, { headers })
  }

  getHistoryByPlatform(platform){
    const headers = new HttpHeaders({'Authorization': `Bearer ${this.apiKey}`,"Content-Type": "application/json"})

    return this.httpClient.get(this.url +'history/' + platform, { headers })
  }

  getHistoryByProfileKey(profileKey,platform=null,limit=50){
    let query =  platform !== null ? `/${platform}?profileKey=${profileKey}&limit=${limit}`: `?profileKey=${profileKey}&limit=${limit}`
    // let query = `/${platform}?profileKey=${profileKey}&limit=${limit}` ? platform : `?profileKey=${profileKey}&limit=${limit}`
    // console.log(query)
    const headers = new HttpHeaders({'Authorization': `Bearer ${this.apiKey}`,"Content-Type": "application/json"})

    return this.httpClient.get(this.url +'history' + query, { headers })
  }


  // User Info
  getUser(){
    const headers = new HttpHeaders({'Authorization': `Bearer ${this.apiKey}`,"Content-Type": "application/json"})
    return this.httpClient.get(this.url +'user', { headers })
  }

  getUserByProfileKey(profileKey){
    const headers = new HttpHeaders({'Authorization': `Bearer ${this.apiKey}`,"Content-Type": "application/json"})
    return this.httpClient.get(this.url +'user?profileKeys='+profileKey, { headers })
  }

  getUserDetailsByPlatform(platform){
    const headers = new HttpHeaders({'Authorization': `Bearer ${this.apiKey}`,"Content-Type": "application/json"})
    return this.httpClient.get(this.url +'user/details/'+platform, { headers })
  }

  /* Analytics - requries a business or premium plan on ayrshare*/ 

  getShortEndLinkAnalytics(){

  } 

  getPostAnalytics(model){
    const headers = new HttpHeaders({'Authorization': `Bearer ${this.apiKey}`,"Content-Type": "application/json"})
    return this.httpClient.post(this.url +'analytics/post',JSON.stringify(model), { headers })
    
  } 

  getPlatformAnalytics(platforms){
    const headers = new HttpHeaders({'Authorization': `Bearer ${this.apiKey}`,"Content-Type": "application/json"})
    return this.httpClient.post(this.url +'analytics/post',JSON.stringify(platforms), { headers })
    
  } 

  // getSocialNetworkAnalytics(platforms){
  //   const headers = new HttpHeaders({'Authorization': `Bearer ${this.apiKey}`,"Content-Type": "application/json"})
  //   return this.httpClient.post(this.url +'analytics/social',JSON.stringify({'platforms':platforms}), { headers })
    
  // } 

  getSocialNetworkAnalytics(model){
    const headers = new HttpHeaders({'Authorization': `Bearer ${this.apiKey}`,"Content-Type": "application/json"})
    return this.httpClient.post(this.url +'analytics/social',JSON.stringify(model), { headers })
    
  } 

  // Schedule a post - requires a business or premium plan


  // media - requires a business or premium plan

  uploadImageOrVideo(model){
    const headers = new HttpHeaders({'Authorization': `Bearer ${this.apiKey}`,"Content-Type": "application/json"})

    // return this.httpClient.post(this.url +'media/upload', body: JSON.stringify({
    //   file: base64,
    //   fileName: "test.png",
    //   description: "best image"
    // }), { headers })
  }

  // create a post

  sendPost(model:PostModel){
    const headers = new HttpHeaders({'Authorization': `Bearer ${this.apiKey}`,"Content-Type": "application/json"})
    return this.httpClient.post(this.url +'post',JSON.stringify(model), { headers })

  }

  deletePost(model){
    const headers = new HttpHeaders({'Authorization': `Bearer ${this.apiKey}`,"Content-Type": "application/json"})
    return this.httpClient.request('delete',this.url +'post', { headers ,body:JSON.stringify(model)})
    // return this.httpClient.delete(this.url +'post',JSON.stringify(model), { headers })

  }

  commentOnPost(model){
    const headers = new HttpHeaders({'Authorization': `Bearer ${this.apiKey}`,"Content-Type": "application/json"})
    return this.httpClient.post(this.url +'comments',JSON.stringify(model), { headers })
  }

  getCommentsOnPost(id,profileKey){
    let query=id + '&profileKey=' + profileKey
    const headers = new HttpHeaders({'Authorization': `Bearer ${this.apiKey}`,"Content-Type": "application/json"})
    return this.httpClient.get(this.url +'comments?id='+query, { headers })
  }

  getPost(id){
    const headers = new HttpHeaders({'Authorization': `Bearer ${this.apiKey}`,"Content-Type": "application/json"})
    return this.httpClient.get(this.url +'post/'+id,{ headers })
  }


  // Profile link
  getUserProfiles(){
    const headers = new HttpHeaders({'Authorization': `Bearer ${this.apiKey}`,"Content-Type": "application/json"})
    return this.httpClient.get(this.url +'profiles', { headers })
  }

  createUserProfile(model){
    const headers = new HttpHeaders({'Authorization': `Bearer ${this.apiKey}`,"Content-Type": "application/json"})
    return this.httpClient.post(this.url +'profiles/profile',JSON.stringify(model), { headers })
  }

  deleteUserProfile(model){
    const headers = new HttpHeaders({'Authorization': `Bearer ${this.apiKey}`,"Content-Type": "application/json"})
    return this.httpClient.request('delete',this.url +'profiles/profile', { headers ,body:JSON.stringify(model)})
   
  }


  generateJWT(model){
    const headers = new HttpHeaders({'Authorization': `Bearer ${this.apiKey}`,"Content-Type": "application/json"})
    return this.httpClient.post(this.url +'profiles/generateJWT',JSON.stringify(model), { headers })
  }

  unlinkAccount(model){
    const headers = new HttpHeaders({'Authorization': `Bearer ${this.apiKey}`,"Content-Type": "application/json"})
    // return this.httpClient.post(this.url +'profiles/social',JSON.stringify(model), { headers })
    return this.httpClient.request('delete',this.url +'profiles/social', { headers ,body:JSON.stringify(model)})

  }
}
