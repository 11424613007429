<div class="container body">

    <div class="row center-align">
        <h6 *ngIf="entitlements"><strong> Payment of {{ entitlements[0]?.month }}/{{ entitlements[0]?.year }} </strong>
        </h6>
        <h6 *ngIf="!entitlements"><strong> Payments Received </strong></h6>
        <p *ngIf="userID" class="red-text"><strong>My ID : {{userID}}</strong> </p>
    </div>

    <div class="row col s12">
        <div class="col l1"></div>
        <div class="col s12 m12 l10">

            <div *ngIf="hasEntitlement">

                <div *ngIf="step_0">
                    <ul class="row collection with-header">
                        <li class="collection-header">
                            <h6>Please Select Fiscal Year</h6>
                        </li>
                        <div *ngFor="let year of years">
                            <!--
                        <li class="collection-item" *ngIf="year === currentYear && currentMonth > 2" (click)="newYearSelected(year, year, year +1)">
                            <div>
                                <strong class="blue-text"> {{ (year) }} - {{ year + 1 }}</strong><a
                                (click)="newYearSelected(year, year, year +1)" class="secondary-content"><i class="material-icons"></i>Select
                                </a>
                            </div>
                        </li>
                
                        <li class="collection-item" (click)="newYearSelected(year - 1, year - 1, year)">
                            <div>
                                <strong class="blue-text"> {{ (year - 1) }} - {{ year }} </strong><a
                                (click)="newYearSelected(year - 1, year - 1, year)" class="secondary-content"><i class="material-icons"></i>Select </a>
                            </div>
                        </li> -->
                            <li class="collection-item" (click)="newYearSelected(year, year)">
                                <div>
                                    <strong class="blue-text"> {{ year }} </strong><a
                                        (click)="newYearSelected(year, year)" class="secondary-content"><i
                                            class="material-icons"></i>Select </a>
                                </div>
                            </li>
                        </div>
                    </ul>

                    <div class="row">
                        <a [ngClass]="'at-btn col s12 btn waves-effect ' + themeColor" (click)="backClicked()"><i
                                class="material-icons left ">house</i> Back To Home Page</a>
                    </div>
                </div>

                <div *ngIf="step0">
                    <div class="row">
                        <ul *ngIf="result" class="row collection">
                            <!-- 
                        <li class="collection-item">
                            <span class="orange-text text-darken-2" *ngIf="!requiredParentsSign">
                                <strong> <i class="material-icons left"> warning</i>= Missing Student Signature! </strong>
                            </span>
            
                            <span class="orange-text text-darken-2" *ngIf="requiredParentsSign">
                                <strong> <i class="material-icons left"> warning</i>= Missing Parent Signature </strong>
                            </span>
                        </li>
                        -->
                            <li class="collection-item">
                                <span class="green-text">
                                    <strong> <i class="material-icons green-text left">thumb_up</i>= Entitlement Signed
                                    </strong>
                                </span>
                            </li>

                            <li class="collection-item">
                                <span class="red-text">
                                    <strong> <i class="material-icons left"> cancel</i>= Missing Student Signature
                                        (Click to Sign) </strong>
                                </span>
                            </li>

                            <li class="collection-item">
                                <span class="orange-text">
                                    <strong> <i class="material-icons orange-text left darken-2">warning</i>= Missing
                                        Parents Signature (Click to Sign) </strong>
                                </span>
                            </li>

                            <div class="section"></div>

                            <li *ngFor="let e of result" class="collection-item" (click)="selectBD(e.funding_id)">
                                <div>
                                    <span *ngIf="!requiredParentsSign">

                                        <strong class="green-text" *ngIf="e.BD_Student_SIGOK === 'True'">
                                            <i class="material-icons green-text left">thumb_up</i>
                                            <span class="black-text">{{e.funding_id}}</span>
                                        </strong>

                                        <strong class="red-text" *ngIf="e.BD_Student_SIGOK === 'false'">
                                            <i class="material-icons red-text left">cancel</i>
                                            <span class="black-text">{{e.funding_id}}</span>
                                        </strong>
                                    </span>

                                    <span *ngIf="requiredParentsSign">
                                        <strong class="green-text"
                                            *ngIf="e.BD_Student_SIGOK === 'True' && e.BD_Parents_SIGOK === 'True'">
                                            <i class="material-icons green-text left">thumb_up</i>
                                            <span class="black-text">{{e.funding_id}}</span>
                                        </strong>

                                        <strong class="orange-text text-darken-2"
                                            *ngIf="e.BD_Student_SIGOK === 'True' && e.BD_Parents_SIGOK === 'false'">
                                            <i class="material-icons orange-text left darken-2">warning</i>
                                            <span class="black-text">{{e.funding_id}}</span>
                                        </strong>

                                        <strong class="red-text"
                                            *ngIf="e.BD_Student_SIGOK === 'false' && e.BD_Parents_SIGOK === 'false'">
                                            <i class="material-icons red-text left">cancel</i>
                                            <span class="black-text">{{e.funding_id}}</span>
                                        </strong>

                                        <strong class="red-text"
                                            *ngIf="e.BD_Student_SIGOK === 'false' && e.BD_Parents_SIGOK === 'True'">
                                            <i class="material-icons red-text left">cancel</i>
                                            <span class="black-text">{{e.funding_id}}</span>
                                        </strong>
                                    </span>

                                    <span class="secondary-content black-text">{{e.month}}/{{e.year}} </span>
                                </div>
                            </li>
                        </ul>

                        <div class="section"></div>

                        <div class="row">
                            <a [ngClass]="'at-btn col s12 btn waves-effect ' + themeColor" (click)="back_0()"><i
                                    class="material-icons left ">arrow_back_ios_new</i> Back To Previous Page</a>
                        </div>
                    </div>
                </div>

                <div *ngIf="step1">
                    <h6 class="orange-text text-darken-2 center"><strong>Funding BD : {{ entitlements[0]?.funding_id
                            }}</strong></h6>
                    <div class="section"></div>

                    <div class="row">
                        <app-entitlement [model]="entitlements"></app-entitlement>
                    </div>

                    <div class="row">
                        <a class="at-btn btn-flat center green lighten-1 waves-effect waves-light col s12 white-text"
                            *ngIf="!hasSignedEntitlement" (click)="approveAndSigned('student')"><i
                                class="material-icons right ">border_color</i>Student Sign Entitlement</a>

                        <a class="at-btn btn-flat center green lighten-1 waves-effect waves-light col s12 white-text"
                            *ngIf="!hasParentsSignedEntitlement" (click)="approveAndSigned('parents')"><i
                                class="material-icons right ">border_color</i>Parent Sign Entitlement</a>

                        <a [ngClass]="'at-btn col s12 btn waves-effect ' + themeColor" (click)="back0()"><i
                                class="material-icons left ">arrow_back_ios</i> Back To My Entitlements</a>
                    </div>
                </div>

                <div class="row" *ngIf="step2">
                    <h6 class="blue-text text-darken-2 center"><strong>{{name}}</strong></h6>

                    <div class="row card-panel grey lighten-4 center">
                        <div class="row">
                            <canvas></canvas>
                        </div>
                    </div>

                    <div class="section"></div>

                    <a class="at-btn btn-flat center green lighten-1 col s12 white-text" (click)="submit()"><i
                            class="material-icons left">check</i> Approve And Submit</a>

                    <a class="at-btn btn-flat center red lighten-1 col s12 white-text" (click)="clearSignature()"><i
                            class="material-icons left">loop</i> Clear Signature</a>

                    <a [ngClass]="'at-btn col s12 btn waves-effect ' + themeColor" (click)="back1()"><i
                            class="material-icons left">arrow_back_ios</i> Back To My Entitlement </a>
                </div>
            </div>

            <div *ngIf="!hasEntitlement" class="card-panel blue darken-2 center">
                <h6 class="white-text"> <strong>
                        You Dont Have Any Entitlement In The Database.</strong>
                </h6>
            </div>

            <div *ngIf="!hasEntitlement">
                <a [ngClass]="'at-btn col s12 btn waves-effect blue'" (click)="backClicked()"><i
                        class="material-icons left ">house</i> Back To Home Page</a>
            </div>
        </div>
        <div class="col l1"></div>
    </div>
</div>