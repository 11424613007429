export class EmailModel {
  emailList : string[];
  subject : string;
  body : string;
  attachments?: string[]=[];
}

export class Attachment{
  type: string;
  name: string;
  url: string;
}
