<div class="body-h">
    <div class="header-setting b-m-2">
        <h4 class="">Survey Result Member</h4>
    </div>

    <main class="StickyContent white row">
        <div class="col s12 l11">

            <div>
                <app-survey-result></app-survey-result>
            </div>
            <div class="row col s12">
                <button [ngClass]="'col s12 btn waves-effect ' + themeColor" (click)="backClicked()">
                    <i class="material-icons left">arrow_back_ios</i> Back To Previous Page
                </button>

            </div>
        </div>
    </main>
</div>