<div class="row center-align">
    <h6><strong> File Manager </strong></h6>
</div>

<div class="row col s12">
  <div class="col l1 "></div>

  <div class="col s12 l10">
    <div class="row" *ngIf="myfiles">
      <div class="row">
        <i class="material-icons">folder_open</i>  Reda Fahmi / 
      </div>
      <table class="striped">                 
        <tbody>
            <tr *ngFor="let a of myfiles">
              <td><i class="material-icons">insert_drive_file</i></td>
                <td class="center">
                   {{a.fileName}}
                </td>
                <td class="center truncate "> 
                  {{a.fileType}}
                </td>
                <td class="center "> 
                  {{a.fileSize}}
                </td>
                <td class="center"> 
                  <a [href]="a.fileUrl" [download]="a.fileName" target="_blank">
                   <i class="material-icons green-text">file_download</i>
                  </a>
                </td>
            </tr>
        </tbody>
      </table>
    </div>
  </div>
  
  <div class="col l1 "></div>
</div>
