<div class="row center-align p-m-2">
  <h6> <strong> New Supplemental Support </strong></h6>
</div>

<div class="row col s12">
  <div class="col l1"></div>

  <div id="step0" *ngIf="step0" class="col s12 l10">

    <div class="row card-panel" *ngIf="bdList">
      <div class="row">
        <h6><strong>Please Select the B&D for which you want to request supplemental assistance.</strong></h6>
        <div class="section"></div>
        <ul *ngIf="bdList" class="collection">
          <li *ngFor="let bd of bdList" class="collection-item">
            <div><a (click)="bdSelected(bd)"> <strong> <i class="material-icons left">arrow_right_alt</i> {{bd.Month}} /
                  {{bd.Year}} </strong> </a></div>
        </ul>
        <div *ngIf="!bdList" class="card-panel red darken-3">
          <h6 class="white-text"> <strong>
              No B&D found for the last 2 months.</strong>
          </h6>
        </div>
      </div>
    </div>
    <div class="card-panel blue" *ngIf="!bdList">
      <h6 class="center white-text"><i class="material-icons orange-text left">info</i><strong> You dont have any B&D in
          the current or previous month</strong></h6>
    </div>
    <div class="section"></div>
    <button [ngClass]="'at-btn col s12 btn waves-effect ' + themeColor " (click)="backClicked()">
      <i class="material-icons left">house</i> Back To Home Page
    </button>
  </div>

  <div id="step1" *ngIf="step1" class="col s12 l10">
    <form class="row" (ngSubmit)="submitForm(supplementalSupportForm.value)" [formGroup]="supplementalSupportForm">
      <div class="row card-panel">
        <div class="  input-field">
          <i class="material-icons prefix blue-text">add_circle</i>
          <input formControlName="description" id="description" type="text" maxlength="50" autocomplete="off" required
            class="validate">
          <label for="description"> What is the payment for ? <strong class="red-text">*</strong> </label>
          <div *ngIf="(description.dirty || description.touched) && description.invalid">
            <small *ngIf="description.errors.required" class="red-text"> Please answer the question
            </small>
          </div>
        </div>

        <div class="  input-field col s12">
          <i class="material-icons prefix green-text">monetization_on</i>
          <!-- <input formControlName="amount" id="amount" type="text" [textMask]="{mask: amountMask}" autocomplete="off"
            required class="validate"> -->
            <input formControlName="amount" id="amount" type="text"  autocomplete="off"
            required class="validate">
          <label for="amount"> Amount <strong class="red-text">*</strong> </label>
          <div *ngIf="(amount.dirty || amount.touched) && amount.invalid">
            <small *ngIf="amount.errors.required" class="red-text"> The income amount is required
            </small>
          </div>
        </div>
        <div class=" col s12 file-field input-field" *ngIf="!isCordova">
          <div class="btn">
            <span>Browse</span>
            <input type="file" formControlName="document" accept="image/*"
              (change)="handleFileInput($event.target.files)" />
          </div>
          <div class="file-path-wrapper">
            <input formControlName="document" class="file-path validate" type="text"
              placeholder="Supporting Document" />
          </div>
        </div>
        <div class="row" *ngIf="isCordova">
          <div class="col s6" style="display: inline;">
            <button class='at-btn col s12 btn waves-effect success' (click)="handleCameraInput()" type="button">
              Take Photo
            </button>
          </div>
          <div class="col s6" style="display: inline;">
            <button class='at-btn col s12 btn waves-effect success' (click)="getPhotoFromLibrary()" type="button">
              Browse Library
            </button>
          </div>
        </div>

        <!-- <div *ngIf="isCordova">
                <div class="  input-field">
                  <i class="material-icons prefix blue-text">add_circle</i>
                  <input formControlName="document" id="document" type="text" autocomplete="off" required
                  class="validate">
                  <label for="description"> Supporting Document  <strong class="red-text">*</strong> </label>
                </div>
              </div> -->

        <div class="row col s12 input-field">
          <div class="col s12" *ngIf="preview">
            <img class="responsive-img" [src]="preview" alt="Support news image">
            <a class="btn-floating btn-small waves-effect waves-light red right" (click)="removeFile()"><i
                class="material-icons">delete</i></a>
          </div>
        </div>
      </div>

      <div class="section"></div>

      <div class="col s12">
        <button class='at-btn col s12 btn waves-effect success' style="margin-bottom: 15px;" type="submit"
          [disabled]="!supplementalSupportForm.valid">
          <i class="material-icons right">cloud_upload</i> Request Assistance
        </button>

        <button [ngClass]="'at-btn col s12 btn waves-effect ' + themeColor " (click)="back1()">
          <i class="material-icons left">keyboard_arrow_left</i> Back To B&D List
        </button>
      </div>
    </form>
  </div>

  <div id="step2" *ngIf="step2" class="row s12 l10">
    <div class="row">
      <div class="col s12">
        <div class="card-panel">
          <div class="row center">
            <h4 class="green-text">Thank You!</h4>
            <h5>Your Supplemental support request was successfully submited</h5>
            <div class="section"></div>
            <h5>Our Officers will review your request and will get back to you shorty.</h5>
            <br />
          </div>
        </div>
      </div>
    </div>
    <div class="section"></div>
  </div>

  <div class="col l1 "></div>
</div>