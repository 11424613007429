<div *ngIf="sliderSection">

    <div *ngIf="fullscreenEnabled" class="slider fullscreen hide-on-small-only">
        <ul class="slides">
            <li *ngFor="let slide of sliderSection.options;let i = index;">
                <img *ngIf="slide.type == 'Image'" [src]="slide.imgUrl" (click)="sliderClicked(i);"
                    class="responsive-img" alt="Slider Image" />
                <div class="captions hide-on-small-only" [ngClass]="slide.textPosition? slide.textPosition: 'Center-k'">
                    <h3 [innerHTML]="slide.title"></h3>
                </div>
                <div class="captions Center-k  mobile-captions hide-on-med-and-up">
                    <h3 [innerHTML]="slide.title"></h3>
                </div>
            </li>
        </ul>
    </div>

    <div *ngIf="fullscreenEnabled" class="slider hide-on-med-and-up">
        <ul class="slides">
            <li *ngFor="let slide of sliderSection.options;let i = index;">
                <img *ngIf="slide.type == 'Image'" [src]="slide.imgUrl" (click)="sliderClicked(i);"
                    class="responsive-img" alt="Slider Image"/>
                <div class="captions hide-on-small-only" [ngClass]="slide.textPosition">
                    <h3 [innerHTML]="slide.title"></h3>
                </div>
                <div class="captions Center-k  mobile-captions hide-on-med-and-up">
                    <h3 [innerHTML]="slide.title"></h3>
                </div>
            </li>
        </ul>
    </div>



    <div *ngIf="!fullscreenEnabled" class="slider">
        <ul class="slides">
            <li *ngFor="let slide of sliderSection.options;let i = index;">
                <img *ngIf="slide.type == 'Image'" [src]="slide.imgUrl" (click)="sliderClicked(i);" class="responsive-img" alt="Slider Image"/>
                <div class="caption hide-on-small-only" [ngClass]="slide.textPosition? slide.textPosition: 'Center-k'">
                    <h3 [innerHTML]="slide.title"></h3>
                </div>
                <div class="caption Center-k  mobile-captions hide-on-med-and-up">
                    <h3 [innerHTML]="slide.title"></h3>
                </div>
            </li>
        </ul>
    </div>
</div>