import { Component, Input, OnInit } from '@angular/core';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { GovernanceService } from 'src/app/services/firebase/governance.service';
import { environment } from 'src/environments/environment';

declare var $: any;

@Component({
  selector: 'app-governance-list',
  templateUrl: './governance-list.component.html',
  styleUrls: ['./governance-list.component.css']
})
export class GovernanceListComponent implements OnInit {

  @Input() from;
  model: any;
  modell: any;
  defaultThemeColor = environment.appTheme.themeColor;
  themeColor: any;
  chiefTitle: any;
  councillorTitle: any;
  governanceSection: any;
  governance: any;
  chief: any;
  councillors: any[];
  viewModel: any;
  showMore = [];

  constructor(
    private appSettingService: AppSettingsService,
    private governanceService: GovernanceService,
    private govService: GovernanceService,) {
    this.themeColor = this.appSettingService.getAppThemeColor() ? this.appSettingService.getAppThemeColor() : this.defaultThemeColor;
    this.appSettingService.getAppSetting().valueChanges().subscribe(
      appSettings => {
        if (appSettings) {
          if (appSettings.length > 0) {
            this.model = appSettings[0];
            this.themeColor = this.model.themeColor ? this.model.themeColor : this.defaultThemeColor;
            this.chiefTitle = this.model.chiefTitle ? this.model.chiefTitle: 'Chief' ;
            this.councillorTitle = this.model.councillorTitle ? this.model.councillorTitle: 'Councillors';
          }
        }
      });
  }

  ngOnInit() {
    this.appSettingService.getHomePageSettings().valueChanges().subscribe(hp => {
      if (hp) {
        if (hp.length > 0) {
          this.model = hp[0];
          const governanceSection = this.model.sections.filter(s => s.name === 'Governance' && s.isEnabled);
          if (governanceSection) {
            this.governanceSection = governanceSection[0];
            if (!this.from || this.from !== 'landingPage') {
              this.governanceSection.showChief = true;
            }
            this.governanceService.getGovernanceList().valueChanges().subscribe(n => {
              if (n) {
                this.governance = n[0];
                if (this.governance.chiefs.length > 0) this.chief = this.governance.chiefs[0];
                //if (this.governance.councillors.length > 0) this.councillor = this.governance.councillors[0];
                if (this.from && this.from === 'landingPage') this.councillors = this.governance.councillors.splice(0, governanceSection[0].councillorsLimit);
                else this.councillors = this.governance.councillors;
              }
              setTimeout(() => {
                $('.tooltipped').tooltip();
                $('.tabs').tabs();
              }, 25);
            });
          }
        }
      }
      if (this.viewModel) {
        const len = this.viewModel.length;
        this.showMore = new Array(len).fill(false);
      }
    });

    // board list
    this.govService.getBoardList().valueChanges().subscribe(bList => {
      if (bList) {
        this.modell = bList;
      }
    });
  }

  selectOption(option){
    localStorage.setItem('selectedOption', option);
  }

  readMore(index) {
    this.showMore[index] = !this.showMore[index];
  }

  truncateHTML(text: string, limit: string): string {
    const charlimit = +limit;
    if (!text || text.length <= charlimit) {
      return text.replace(/<(?:.|\n)*?>/gm, '').replace(/&nbsp;/gm, ' ');
    }
    return text.replace(/<(?:.|\n)*?>/gm, '').replace(/&nbsp;/gm, ' ').substring(0, charlimit) + '...';
  }

}