<div class="row container5" *ngIf="model">

  <div class="col l6 s12 "
    *ngIf="model.imgLink || model.type === 'Video' ||  model.type === 'PDF' && model.videoLink || model.type === 'uploadVideo'">

    <div class="row col l12 s12 m12">
      <div class="col l12 about-img hide-on-med-and-down" [ngStyle]="{'color': themeColor}"
        *ngIf="model.type === 'Photo'">
        <img class="materialboxed responsive-img " [src]="model.imgLink">
      </div>

      <div class="col l12 about-vid hide-on-med-and-down" *ngIf="model.type === 'Video'">
        <div class="video-container ">
          <youtube-player [videoId]="videoId"></youtube-player>
          <!-- <div [innerHTML]="iframehtml"></div> -->
        </div>
      </div>

      <div class="col l12 about-iframe hide-on-med-and-down" *ngIf="model.type === 'PDF' && model.videoLink">
        <iframe title="iframe-box2" [src]="model.videoLink | safeUrl" height="600px" width="100%"></iframe>
      </div>

      <div class="col l12 about-video hide-on-med-and-down" *ngIf="model.type === 'uploadVideo'">
        <video class="responsive-video" controls>
          <source [src]="model.videoLink" type="video/mp4">
        </video>
      </div>
    </div>
  </div>

  <div class="col l6 m12 s12 container4">
    <div class="col l12 hide-on-large-only" *ngIf="model.type === 'Photo'">
      <img class="materialboxed responsive-img " [src]="model.imgLink">
    </div>

    <div class="col l12 hide-on-large-only " *ngIf="model.type === 'Video'">
      <div class="video-container ">
        <youtube-player [videoId]="videoId"></youtube-player>
        <!-- <div [innerHTML]="iframehtml"></div> -->
      </div>
    </div>

    <div class="col l12 hide-on-large-only" *ngIf="model.type === 'PDF' && model.videoLink">
      <iframe title="iframe-box2" [src]="model.videoLink | safeUrl" height="500px" width="100%"></iframe>
    </div>

    <div class="col l12 hide-on-large-only" *ngIf="model.type === 'uploadVideo'">
      <video class="responsive-video" controls>
        <source [src]="model.videoLink" type="video/mp4">
      </video>
    </div>

    <div class="col l12 s11 p-l-1 p-b-1 bolder">
      <h6 class=" title" [innerHTML]="model.title"></h6>
    </div>

    <div class="section"></div>
    <div class="col l12 ">
      <span [innerHTML]="model.body | noSanitize"></span>
    </div>
  </div>
</div>