import { Component, OnInit } from '@angular/core';
import { AyrshareService } from 'src/app/services/social-media/ayrshare.service';
import { ProfieKeyService } from 'src/app/services/social-media/profileKey.service';
import { icons } from 'src/app/models/SocialMediaIcons';
import { analysisPlatforms } from 'src/app/models/SocialMediaPostModel';
import { AuthService } from 'src/app/services/firebase/auth.service';

@Component({
  selector: 'app-social-media-post',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.css']
})

export class SocialMediaPostComponent implements OnInit {

  availablePlatforms = []
  allAccountsPosts = []
  selectedAccountPosts = []
  selectedAccount

  profileKey

  hasActiveAccounts = false
  userProfile
  socialMediaIcons = icons
  mediaAnalysisPlatforms = [...analysisPlatforms]
  loading = false

  //For Permissions
  user: any;
  isAdmin: any;
  perm: any;
  permission: any;

  permMember: any;
  permSocial: any;
  permPES: any;
  permSpecial: any;
  permAnalytics: any;

  constructor(
    private socialMediaService: AyrshareService,
    private profieKeyService: ProfieKeyService,
    // private permissionService: PermissionService,
    private authService: AuthService) { }

  ngOnInit() {
    this.mediaAnalysisPlatforms.push('youtube')

    this.user = this.authService.getGlobalUser();
    if (this.user.role == "Admin") {
      this.isAdmin = true
    }

    this.profieKeyService.currentProfileKey.subscribe(p => {
      this.profileKey = p
    }
    )

    this.getUserProfile()
  }

  getUserProfile() {
    if (this.profileKey) {
      this.loading = true;
      this.socialMediaService.getUserByProfileKey(this.profileKey).toPromise()
        .then(data => {
          this.userProfile = data
          this.hasActiveAccounts = true ? data['activeSocialAccounts'] && data['activeSocialAccounts'].length > 0 : false
          if (this.userProfile.displayNames.some(x => x.platform == 'linkedin' && x.type == 'corporate')) this.mediaAnalysisPlatforms.push('linkedin')
          this.loading = false;

          this.getPosts()
        })
        .catch(err => {

          this.loading = false;
        });
    }
  }

  getPosts() {
    if (this.profileKey) {
      this.socialMediaService.getHistoryByProfileKey(this.profileKey).toPromise().then(hist => {
        this.allAccountsPosts = <Array<any>>hist
        this.allAccountsPosts.forEach(post => {
          post.platforms.map(platform => {
            if (!this.availablePlatforms.includes(platform)) this.availablePlatforms.push(platform)
          })
        })

        this.selectedAccountPosts = this.allAccountsPosts
      }).catch(err => {
        this.allAccountsPosts = []
        this.selectedAccountPosts = []
      })
    }
  }

  getUserMediaPlatforms(post) {
    return post.platforms.filter(s => {
      return this.mediaAnalysisPlatforms.includes(s)
    })
  }

  imageMedia(url) {
    url = new URL(url)
    let urlSplit = url.pathname.split('/')
    let media = urlSplit[3]
    if ((url.host == "images.ayrshare.com") || ["images", 'image'].includes(media.toLowerCase())) {
      return true
    } else {
      return false
    }
  }

  selectPlatform(account) {
    this.selectedAccount = account
    this.selectedAccountPosts = this.allAccountsPosts.filter(p => (<Array<any>>p['platforms']).includes(this.selectedAccount))
  }
}