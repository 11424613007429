import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { GovernanceService } from 'src/app/services/firebase/governance.service';
import { environment } from 'src/environments/environment';

declare var $: any;

@Component({
  selector: 'app-governance',
  templateUrl: './governance.component.html',
  styleUrls: ['./governance.component.css']
})
export class GovernanceComponent implements OnInit {

  themeColor = environment.appTheme.themeColor;
  defaultThemeColor = environment.appTheme.themeColor;
  model: any;
  councillorTitle: any;
  chiefTitle: any;


  constructor(private governanceService: GovernanceService,
    private location: Location,
    public appSettingsService: AppSettingsService) {

    this.themeColor = this.appSettingsService.getAppThemeColor() ? this.appSettingsService.getAppThemeColor() : this.defaultThemeColor;
    this.chiefTitle = this.appSettingsService.getGovTitle().chiefTitle ? this.appSettingsService.getGovTitle().chiefTitle : 'Chief';
    this.councillorTitle = this.appSettingsService.getGovTitle().councillorTitle ? this.appSettingsService.getGovTitle().councillorTitle : 'Councillors';
  }

  ngOnInit() {
    this.governanceService.getGovernanceList().valueChanges().subscribe(gov => {
      if (gov.length > 0) {
        this.model = gov[0];
        setTimeout(() => {
          $('.collapsible').collapsible();
        }, 50);
      }
    });
  }

  getInitials(firstName: string, lastName: string): string {
    return `${firstName?.charAt(0)?.toUpperCase() || ''}${lastName?.charAt(0)?.toUpperCase() || ''}`;
  }

  backClicked() {
    this.location.back();
  }
}
