<h6 class="center p-m-2 b-m-2"><strong> My Participation Agreement </strong> </h6>

<div class="row col s12">
  <div class="col l1"></div>

  <div class="col s12 l10">
    <div class="row card-panel white" *ngIf="model">

      <ul class="row collection with-header">
        <li class="collection-header">
          <h6 class="teal-text"><strong> Objective </strong></h6>
        </li>
        <li class="collection-item"><strong>{{model?.goal}}</strong></li>
        <li class="collection-item">
          <div><strong>Review Date : </strong><a href="#!"
              class="secondary-content blue-text"><strong>{{model?.next_review_date | date: 'dd/MMM/yyyy'}}</strong></a>
          </div>
        </li>
      </ul>
      <div class="section">
      </div>

      <ul class="row collapsible">
        <li>
          <div class="collapsible-header "><a [routerLink]="[]" class="black-text"><i
                class="material-icons orange-text">group_work</i><strong>My Actions List </strong></a> </div>
          <div class="collapsible-body white">
            <div class="row">
              <ul class="collection" *ngIf="actionsList">
                <li class="collection-item" *ngFor="let action of actionsList">
                  <a [routerLink]="['/member/participation/action-detail',action.ID]"> <u> <strong><i
                          class="material-icons left">navigate_next</i> {{action?.item}} </strong></u></a>
                </li>
              </ul>
              <div *ngIf="!actionsList" class="card-panel blue lighten-3">
                <h6 class="white-text"> <strong>
                    No Actions Found.</strong>
                </h6>
              </div>
            </div>
          </div>
        </li>
        <li>
          <div class="collapsible-header"><a [routerLink]="[]" class="black-text"><i
                class="material-icons pink-text">assignment</i><strong>My Activiy List </strong></a> </div>
          <div class="collapsible-body white">

            <div class="row">
              <ul class="collection" *ngIf="activityList">
                <li class="collection-item" *ngFor="let activity of activityList">
                  <a [routerLink]="['/member/participation/activity-detail',activity.ID]"> <strong><u> <i
                          class="material-icons left">navigate_next</i> {{activity?.item}} </u> </strong></a>
                </li>
              </ul>
              <div *ngIf="!activityList" class="card-panel blue lighten-3">
                <h6 class="white-text"> <strong>
                    No Actions Found.</strong>
                </h6>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="card-panel blue" *ngIf="!model">
      <h6 class="center white-text"><i class="material-icons orange-text left">info</i><strong> You dont have any
          participation agreement</strong></h6>
    </div>

    <div class="section"></div>
    <button [ngClass]="'col s12 btn waves-effect ' + themeColor" (click)="backClicked()">
      <i class="material-icons left">house</i> Back To Home Page
    </button>
  </div>

  <div class="col l1"></div>
</div>