import { Component, Input, OnInit } from '@angular/core';
import { isThisSecond } from 'date-fns';
import { AboutusService } from 'src/app/services/firebase/aboutus.service';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-about-us-section',
  templateUrl: './about-us-section.component.html',
  styleUrls: ['./about-us-section.component.css']
})

export class AboutUsSectionComponent implements OnInit {

  defaultThemeColor = environment.appTheme.themeColor;
  model: any;
  themeColor: any;
  aboutUsSection: any;
  aboutUs: any[];
  bodyBgColor:any;
  @Input() cardView: any;

  constructor(
    private appSettingService: AppSettingsService,
    private aboutUsService: AboutusService) {
    this.appSettingService.getAppSetting().valueChanges().subscribe(
      appSettings => {
        if (appSettings) {
          if (appSettings.length > 0) {
            this.model = appSettings[0];
            this.themeColor = this.model.themeColor ? this.model.themeColor : this.defaultThemeColor;
          }
        }
      });
   }

  ngOnInit(): void {
    this.appSettingService.getHomePageSettings().valueChanges().subscribe(hp => {
      if (hp) {
        if (hp.length > 0) {
          this.model = hp[0];
          const aboutUsSection = this.model.sections.filter(s => s.name === 'AboutUs');
          if (aboutUsSection) {
            this.aboutUsSection = aboutUsSection[0];
            this.aboutUsService.getSectionsList().valueChanges().subscribe(list => {
              if (list && list.length > 0) {
                this.aboutUs = list;
                this.aboutUs = this.aboutUs.sort((a, b) => (a.datePublished > b.datePublished ? -1 : 1));
                this.aboutUs =  this.aboutUs.splice(0, this.aboutUsSection.displayLimit);
              }
            });

            this.aboutUsService.getPublishedList().valueChanges().subscribe(list => {
              if (list && list.length > 0) {
                this.aboutUs = list;
                this.aboutUs = this.aboutUs.sort((a, b) => (a.datePublished > b.datePublished ? -1 : 1));
                this.aboutUs =  this.aboutUs.splice(0, this.aboutUsSection.displayLimit);
              }
            });
          }
        }
      }
    });
  }

  truncateHTML(text: string, limit: string): string {
    const charlimit = +limit;
    if (!text || text.length <= charlimit) {
      return text.replace(/<(?:.|\n)*?>/gm, '').replace(/&nbsp;/gm, ' ');
    }
    return text.replace(/<(?:.|\n)*?>/gm, '').replace(/&nbsp;/gm, ' ').substring(0, charlimit) + '...';
  }

  navigate(){
    this.appSettingService.setCurrentMenu(this.aboutUsSection.displayName);
  }
  
}