<div class="body-h">

  <div class="header-setting b-m-2">
    <div class="col l12 m12 s12">
      <h4 class=""> Add New Skill </h4>
    </div>
  </div>

  <div class="row col s12">
    <div id="step0" class="col s12 l11">
      <form (ngSubmit)="submitForm(editSkillForm.value)" [formGroup]="editSkillForm">
        <div class="row card-panel">
          <div class="input-field class col s12 l7">
            <div class="label-input">Skill Name <strong class="red-text">*</strong></div>
            <input formControlName="skill" id="skill" type="text" maxlength="140" autocomplete="off" required
              class="capitalize validate" placeholder="Skill Name">
            <div *ngIf="(skill.dirty || skill.touched) && skill.invalid">
              <small *ngIf="skill.errors.required" class="red-text">The Skill Name Is Required!
              </small>
            </div>
          </div>
        </div>

        <div class="section"></div>

        <div class="col s12">
          <button class='at-btn col s12 btn waves-effect success' style="margin-bottom: 15px;" type="submit"
            [disabled]="!editSkillForm.valid">
            <i class="material-icons right">add_circle</i> Update Skill
          </button>

          <button data-target="modal1" class="col s12 btn modal-trigger red " style="margin-bottom: 15px;">Remove
            Skill</button>

          <button type="button" [ngClass]="'col s12 btn waves-effect ' + themeColor " (click)="backClicked()">
            <i class="material-icons left">keyboard_arrow_left</i> Back To Skill List
          </button>
        </div>
      </form>
    </div>
  </div>
</div>

<div id="modal1" class="modal">
  <div class="modal-content">
    <h5>Delete Skill</h5>
    <strong> Do You Want to Remove This Skill ? </strong>
  </div>
  <div class="modal-footer">
    <button type="button" (click)="DeleteSkill()" class="btn waves-effect blue white-text" style="margin-right: 15px;">
      Yes
    </button>
    <a class="modal-close amber darken-4 white-text btn-flat white-text">No</a>
  </div>
</div>