import { Component, OnInit } from '@angular/core';
import { Options } from 'sortablejs';
import { DepartmentNewsService } from 'src/app/services/firebase/department-news.service';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-manage-dep-news',
  templateUrl: './manage-dep-news.component.html',
  styleUrls: ['./manage-dep-news.component.css']
})

export class ManageDepNewsComponent implements OnInit {

  adminThemeColor = environment.appTheme.adminThemeColor;
  adminFontColor = environment.appTheme.adminFontColor;
  showLoading = false;
  privacy: any;
  model: any[];
  departmentID: any;
  options: Options = {
    animation: 150,
  };

  constructor(private route: ActivatedRoute,
    private newsService: DepartmentNewsService,
    private location: Location) {
    this.options = {
      onUpdate: (event: any) => {
        this.model.forEach(element => {
          element.index = this.model.findIndex(x => x.id === element.id);
        });
        this.newsService.SortContentOnServer(this.model);
      }
    };
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.departmentID = params.get('id');
    });

    this.showLoading = true;
    if (this.departmentID) {
      this.newsService.getDepNewsList(this.departmentID).valueChanges().subscribe(
        resp => {
          if (resp.length > 0) {
            this.model = resp;
            this.model.sort((a, b) => (a.index < b.index ? -1 : 1));
            this.showLoading = false;
          }
        },
        err => {
          this.showLoading = false;
        }
      );
    }
    this.showLoading = false;
  }

  backClicked() {
    this.location.back();
  }

}
