import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { GovernanceService } from 'src/app/services/firebase/governance.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-boards-list-public',
  templateUrl: './boards-list-public.component.html',
  styleUrls: ['./boards-list-public.component.css']
})
export class BoardsListPublicComponent implements OnInit {
 
  themeColor = environment.appTheme.adminThemeColor;
  model: any[];
  
  constructor(private govService: GovernanceService, private location: Location) { }

  ngOnInit() {
    this.govService.getBoardList().valueChanges().subscribe(bList => {
      if (bList) {
        this.model = bList;
      }
    });
  }

  backClicked(){
    this.location.back();
  }
}
