import { toast } from 'materialize-css';
import { FireMembersService } from 'src/app/services/firebase/fire-members.service';
import { VotingService } from './../../../../services/firebase/voting.service';
import { ElectionService } from 'src/app/services/firebase/election.service';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { VoteDataModel } from './../../../../models/VoteDataModel';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'app-vote',
  templateUrl: './vote.component.html',
  styleUrls: ['./vote.component.css']
})

export class VoteComponent implements OnInit {

  constructor(private route: ActivatedRoute,
    private electionService: ElectionService,
    private location: Location,
    private authService: AuthService,
    private votingService: VotingService,
    private fireMembersService: FireMembersService,
    private fb: UntypedFormBuilder) { }

  id = '';
  days = 0;
  hours = 0;
  minutes = 0;
  seconds = 0;
  votingEnded = false;
  voted = false;
  voterID = '';
  candidates: any;
  candidatesData = [];
  noCandidates = false;
  previousVote: any;
  isRevote = false;
  voteContent: VoteDataModel;
  updatedVoteContent: VoteDataModel;
  filterForm: UntypedFormGroup;
  searchResult: any;
  showVoting = false;

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');

    });
    this.filterForm = this.fb.group({
      firstName: [''],
    });

    this.voterID = this.authService.getGlobalUser().uid; // get logged in user id
    this.voteContent = new VoteDataModel();
    this.voteContent.voterId = this.voterID;
    this.voteContent.electionId = this.id;

    // check if the user voted already
    this.votingService.getMyVoteByElectionId(this.id, this.voterID).valueChanges().subscribe(votingDetail => {
      if (votingDetail.length > 0) {
        // if true already voted
        this.voted = true;
        this.previousVote = votingDetail[0];
      }
      this.electionService.getElectionById(this.id).valueChanges().subscribe(electionData => {

        if (electionData) {
          // count down
          const electionEndDay = new Date(electionData.endDate);
          const today = new Date(new Date().toLocaleDateString());
          const daysLeft = (electionEndDay.valueOf() - today.valueOf()) / (1000 * 3600 * 24);
          if (daysLeft > 0) {
            this.days = daysLeft;
          } else if (daysLeft === 0) {
            this.days = 1;
          } else {
            this.votingEnded = true;
          }
        }
        this.candidates = electionData.candidates; // get candidates
        if (this.candidates.length === 0) {
          this.noCandidates = true;
        }
        this.candidates.forEach(candidateID => {
          this.fireMembersService.getMemberByID(candidateID).valueChanges().subscribe(candidateData => {
            if (candidateData) {
              this.candidatesData.push(candidateData);
            }
          });

        });
        this.candidatesData = this.candidatesData.sort(() => Math.random() - 0.5);
        this.searchResult = this.candidatesData;
      });
    });
  }

  get firstName() { return this.filterForm.get('firstName'); }

  submitVote(candidateId) {
    this.voteContent.candidateId = candidateId;
    this.voteContent.electionTime = new Date().toLocaleString();
    this.voteContent.showVoting = this.showVoting;
    this.votingService.createVote(this.voteContent).then(res => {
      toast({ html: 'You have voted successfully!', classes: 'green' });
    });
  }

  changeVote() {
    this.isRevote = true;
    this.voted = false;
  }
  updateVote(candidateId) {
    this.updatedVoteContent = new VoteDataModel();
    this.updatedVoteContent.id = this.previousVote.id,
      this.updatedVoteContent.candidateId = candidateId;
    this.updatedVoteContent.voterId = this.voterID;
    this.updatedVoteContent.electionId = this.id;
    this.updatedVoteContent.showVoting = this.showVoting;

    this.updatedVoteContent.electionTime = new Date().toLocaleString();
    this.votingService.updateVote(this.updatedVoteContent);
    toast({ html: 'You have Updated your vote successfully!' });

    this.voted = true;
    this.isRevote = false;
  }

  onChangeShowVoting(event: any) {
    this.showVoting = event.target.value;
  }

  submitForm(filterModel: any) {
    this.candidatesData = this.searchResult;
    if (this.candidatesData && filterModel.firstName) {
      this.candidatesData = this.candidatesData.filter((member: any) => {
        console.log(member.GivenName.toLowerCase().includes(filterModel.firstName.toString().toLowerCase()));
        return member.GivenName.toLowerCase().includes(filterModel.firstName.toString().toLowerCase());
      });
    }
  }

  clearSearchInput() {
    this.firstName.reset();
    this.candidatesData = this.searchResult;
  }

  backClicked() {
    this.location.back();
  }
}
