import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';
import { MemberModel } from 'src/app/models/memberModel';
import { MemberService } from 'src/app/services/api/member.service';
import { IncomeDeclarationModel } from 'src/app/models/incomeDeclarationModel';

declare var $: any;

@Component({
  selector: 'app-my-income-statement',
  templateUrl: './my-income-statement.component.html',
  styleUrls: ['./my-income-statement.component.css']
})
export class MyIncomeStatementComponent implements OnInit {

  themeColor = environment.appTheme.themeColor;
  applicantID = 0;
  member: MemberModel;
  current_DOI: any;
  previousDeclaration: IncomeDeclarationModel[];
  previousDeclarationTotal = 0;

  constructor(private authService: AuthService,
    private location: Location,
    private memberService: MemberService) {
    localStorage.setItem('from', 'SocialAssistance');
  }

  ngOnInit() {
    if (localStorage.getItem('applicantID')) {
      this.applicantID = Number(localStorage.getItem('applicantID'));
    } else {
      this.applicantID = this.authService.getCurrentApplicantID();
    }

    this.memberService.getMember(this.applicantID).subscribe(x => {
      if (x) {
        this.current_DOI = x[0].current_DOI;
      }
    });
  }

  backClicked() {
    this.location.back();
  }
}