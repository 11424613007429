import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { toast } from 'materialize-css';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { ManageAdminService } from 'src/app/services/api/manage-admin.service';
import { EmailModel } from 'src/app/models/emailModel';
import { SMSService } from 'src/app/services/api/sms.service';
import { EmailService } from 'src/app/services/api/email.service';
import { SMSModel } from 'src/app/models/SMSModel';
import { createMask } from '@ngneat/input-mask';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-contact-members',
  templateUrl: './contact-members.component.html',
  styleUrls: ['./contact-members.component.css']
})
export class ContactMembersComponent implements OnInit {

  @Input() contactDetail: any;
  @Input() message: any;
  @Output() success: EventEmitter<any> = new EventEmitter<any>();

  // public phoneNumberMask = ['(', /[0-9]/, /[0-9]/, /[0-9]/, ')',
  //   ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  phoneInputMask = createMask<string>({
    mask: '(999) 999-9999',
    guide: true,
    showMaskTyped: true,
  });


  loading = false;
  loggedUser: any;

  adminInfo: any;
  adminContactInfo: UntypedFormGroup;

  contactForm: UntypedFormGroup;
  contactByEmail = false;
  contactBySms = false;
  emailSent = false;
  smsSent = false;
  includeAdminInfo = true;

  emailList = [];
  phoneNoList = [];

  constructor(private fb: UntypedFormBuilder,
    private authService: AuthService,
    private manageAdminService: ManageAdminService,
    private emailService: EmailService,
    private smsService: SMSService,
    private location: Location) {
    this.loggedUser = this.authService.getGlobalUser();
  }

  ngOnInit(): void {
    this.contactForm = this.fb.group({
      subject: [this.message ? this.message.subject : '', [Validators.required]],
      body: [this.message ? this.message.body : '', [Validators.required]]
    });

    if (this.contactDetail.multiple) {
      if (this.contactDetail.email) {
        this.emailList = this.contactDetail.emailList;
        let initialList = [];
        this.emailList.forEach(email => {
          initialList.push({ tag: email });
        });
        $('.modal').modal({ 'dismissible': false });
        $('.chips').chips();
        $('.email-chips').chips({
          data: initialList,
          placeholder: 'Enter a recipient',
          secondaryPlaceholder: '+recipient',
          onChipDelete: (e, data) => {
            var email = data.innerText.replace('close', '').trim();
            if (this.emailList.length > 0) {
              var index = this.emailList.indexOf(email);
              this.emailList.splice(index, 1);
            }
          }
        });
      }
      $('#multipleContactModel').modal('open');
      M.updateTextFields();
      M.textareaAutoResize($('#body'));
    } else {
      setTimeout(() => {
        $('.modal').modal({ 'dismissible': false });
        $('#contactModal').modal('open');
        M.updateTextFields();
        M.textareaAutoResize($('#body'));
      }, 25);
    }
  }

  get subject() { return this.contactForm.get('subject'); }
  get body() { return this.contactForm.get('body'); }

  get Email_Address() { return this.adminContactInfo.get('Email_Address'); }
  get Cell() { return this.adminContactInfo.get('Cell'); }
  get phone() { return this.adminContactInfo.get('phone'); }
  get ph_extension() { return this.adminContactInfo.get('ph_extension'); }
  get Title() { return this.adminContactInfo.get('Title'); }
  get show_email() { return this.adminContactInfo.get('show_email'); }
  get show_cell() { return this.adminContactInfo.get('show_cell'); }
  get show_office() { return this.adminContactInfo.get('show_office'); }
  get show_title() { return this.adminContactInfo.get('show_title'); }

  showAdminInfo() {
    this.includeAdminInfo = !this.includeAdminInfo;
  }

  update() {
    setTimeout(() => {
      M.updateTextFields();
      $('.tooltipped').tooltip();
      $('.modal').modal({ 'dismissible': false });
      $('#settingModal').modal('open');
    }, 25);
  }

  updateInfo(values) {
    values.uid = this.loggedUser.id;
    values.uid = this.loggedUser.id;
    values.Cell = values.Cell?.replace(/\D+/g, '');
    values.phone = values.phone?.replace(/\D+/g, '');

    this.manageAdminService.UpdateAdminContact(values).subscribe(x => {
      if (x) {
        this.adminInfo.Email_Address = values.Email_Address;
        this.adminInfo.Cell = values.Cell;
        this.adminInfo.phone = values.phone;
        this.adminInfo.ph_extension = values.ph_extension;
        this.adminInfo.Title = values.Title;
        this.adminInfo.show_cell = values.show_cell;
        this.adminInfo.show_email = values.show_email;
        this.adminInfo.show_office = values.show_office;
        this.adminInfo.show_title = values.show_title;
        toast({ html: 'Contact Info Successfully Updated!', classes: 'green' });
      }
    });
    $('#settingModal').modal('close');
    if (this.contactDetail.multiple) {
      $('#multipleContactModel').modal('open');
    } else {
      $('#contactModal').modal('open');
    }
  }

  cancelUpdateInfo() {
    $('#settingModal').modal('close');
    if (this.contactDetail.multiple) {
      $('#multipleContactModel').modal('open');
    } else {
      $('#contactModal').modal('open');
    }
  }

  select(via) {
    if (via == 'email') {
      this.contactByEmail = !this.contactByEmail;
    } else if (via == 'sms') {
      this.contactBySms = !this.contactBySms
    }
  }

  send(values) {
    const email = new EmailModel();
    let msg = new SMSModel();
    if (this.contactByEmail) {
      email.emailList = [];

      email.emailList.push(this.contactDetail.email);
      email.subject = values.subject;
      email.body = `<p> ${values.body} </p>`;
      email.body += '<br/> <p><em> Thank you for using Our App. </em> </p>';
    }

    if (this.contactBySms) {
      msg.phoneNumberList = [];
      msg.phoneNumberList.push(this.contactDetail.phoneNo);
      msg.body = values.subject + '\n' + values.body + '\nThank you for using Our App.';
    }

    if (this.contactByEmail && this.contactBySms) {
      this.emailService.sendEmail(email).subscribe(emailSent => {
        this.smsService.sendSMS(msg).subscribe(smsSent => {
          $('.modal').modal('close');
          this.return(true);
        })
      });
    } else if (this.contactByEmail) {
      this.emailService.sendEmail(email).subscribe(emailSent => {
        $('.modal').modal('close');
        this.return(true);
      });
    } else if (this.contactBySms) {
      this.smsService.sendSMS(msg).subscribe(smsSent => {
        $('.modal').modal('close');
        this.return(true);
      })
    } else {
      $('.modal').modal('close');
      this.return(false);
    }
  }

  sendMultiple(values) {
    if (this.contactDetail.email) {
      let email = new EmailModel();
      email.emailList = this.emailList;
      email.subject = values.subject;
      email.body = `<p> ${values.body} </p>`;

      if (this.includeAdminInfo) {
        let adminInfo = `<br/>${this.adminInfo.FirstName} ${this.adminInfo.LastName}`;
        if (this.adminInfo.Title && this.adminInfo.show_title) adminInfo += `<br/> ${this.adminInfo.Title}`;
        if (this.adminInfo.Email_Address && this.adminInfo.show_email) adminInfo += `<br/>EMAIL: ${this.adminInfo.Email_Address}`;
        if (this.adminInfo.Cell && this.adminInfo.show_cell) adminInfo += `<br/>CELL: 1${this.adminInfo.Cell}`;
        if (this.adminInfo.phone && this.adminInfo.show_office) adminInfo += `<br/>PHONE: ${this.adminInfo.ph_extension ? this.adminInfo.phone + "X" + this.adminInfo.ph_extension : this.adminInfo.phone}`;
        email.body += adminInfo;
      }

      email.body += '<br/> <p><em> Thank you for using Our App. </em> </p>';
      this.emailService.sendEmail(email).subscribe(x => {
        $('#multipleContactModel').modal('close');
        this.return(true);
      });
    } else if (this.contactDetail.sms) {
      let msg = new SMSModel();
      msg.phoneNumberList = [];
      msg.phoneNumberList.push(this.contactDetail.phoneNoList);
      msg.body = values.subject + '\n' + values.body + '\nThank you for using Our App.';
      msg.name = `${this.adminInfo.FirstName} ${this.adminInfo.LastName}`;
      if (this.adminInfo.Email_Address) msg.Email_Address = this.adminInfo.Email_Address;
      if (this.adminInfo.Cell) msg.cell = "1" + this.adminInfo.Cell;
      if (this.adminInfo.title) msg.title = this.adminInfo.Title;
      if (this.adminInfo.phone) msg.phone = this.adminInfo.ph_extension ? this.adminInfo.phone + "X" + this.adminInfo.ph_extension : this.adminInfo.phone;

      if (this.includeAdminInfo) {
        msg.show_cell = this.adminInfo.show_cell;
        msg.show_email = this.adminInfo.show_email;
        msg.show_phone = this.adminInfo.show_office;
        msg.show_title = this.adminInfo.show_title;
      } else {
        msg.show_cell = false;
        msg.show_email = false;
        msg.show_phone = false;
        msg.show_title = false;
      }
    } else {
      $('#multipleContactModel').modal('close');
      return this.return(false);
    }
  }

  return(status) {
    this.success.emit({ status: status, title: this.message ? this.message.title : '' });
    return;
  }

}
