<div class="col s12" [ngClass]="darkOption">
  <div class="body-h">

    <div class="header-setting b-m-2">
      <div class="col l10 m10 s12">
        <h4 *ngIf="!editName">
          <span *ngIf="sectionName">{{sectionName}}</span>
          <span *ngIf="!sectionName">App Setting</span>
          <i class="material-icons edit-name" [ngStyle]="{'color': themeColor}" (click)="editNameClicked()">edit</i>
        </h4>

        <div class="input-field" *ngIf="editName">
          <input type="text" [(ngModel)]="sectionName" class="left col s6 validate" autocomplete="off" />
          <button type="button" class="btn ll-m-5" [ngStyle]="{'background-color': themeColor}"
            (click)="saveName()">Save</button>
        </div>

        <p class="col s12 bold-300 sub-title-section3">It is where you configure your app's form, views , color and
          other items
          to adjust your app to your business needs. </p>
      </div>
      <div class="col s12 l2 m2 p-m-3">
        <form [formGroup]="appSettings" (ngSubmit)="SaveSettings(appSettings.value)">
          <button type="submit" class="col s12 btn save-button" [ngClass]="'btn ' + buttonType"
            style="margin-bottom: 15px;">Save</button><br />
        </form>
      </div>
    </div>

    <!-- ================= new design ================== -->
    <main class="StickyContent  row">
      <ul class="collapsible col l10 s12">


        <!-- ================= Dark and light Mode Setup ================== -->


        <li class="row">
          <!-- =========== dark and light mode ============ -->
          <div class="collapsible-header "> <span class="material-symbols-outlined p-r-1"
              [ngStyle]="{'color': themeColor}">
              light_mode
            </span>Dark Mode</div>
          <form [formGroup]="appSettings" (ngSubmit)="SaveSettings(appSettings.value)">
            <div class="row col l10 push-l1 p-b-3">

              <div class="logo-section col s12 l5">
                <label>
                  <input class="radioBtn with-gap" type="radio" name="darkOption" value="lightMode"
                    formControlName="darkOption" (click)="lightClicked()" />
                  <span class="label-content">

                    <div class="card b-n-1">
                      <div class="card-content center">
                        <div class="logo-image icon-text">
                          <span class="material-symbols-outlined bold-300" style="font-size: 30px;">
                            light_mode
                          </span>
                          <span class="p-l-1 sub-title-section2 bold-600 black-text"> Light Mode</span>
                        </div>

                      </div>
                    </div>
                  </span>
                </label>
              </div>


              <div class="logo-section col s12 l5">
                <label>
                  <input class="radioBtn with-gap" type="radio" name="darkOption" value="darkMode"
                    formControlName="darkOption" (click)="darkClicked()" />
                  <span class="label-content">
                    <div class="card black white-text b-n-1">
                      <div class="card-content center">
                        <div class="logo-image icon-text">
                          <span class="material-symbols-outlined bold-300 white-text" style="font-size: 30px;">
                            dark_mode
                          </span>
                          <span class="p-l-1 sub-title-section bold-600 white-text"> Dark Mode</span>
                        </div>

                      </div>
                    </div>
                  </span>
                </label>
              </div>

            </div>
          </form>


        </li>

        <!-- ================= Brand Setup ================== -->
        <li class="row">

          <!-- =========== Color Setup ============ -->
          <div class="collapsible-header "> <span class="material-symbols-outlined p-r-1"
              [ngStyle]="{'color': themeColor}">
              palette
            </span> Color Setup</div>
          <p> Setting up the color scheme for your website involves defining the theme color and background
            color. </p>

          <div class="collapsible-body ">
            <form [formGroup]="appSettings" (ngSubmit)="SaveSettings(appSettings.value)">

              <div class="row card-panel">

                <!-- =========== theme Color ============ -->
                <div class="col l4 s12 card-container">
                  <div class="col s12">
                    <h4 class="sub-title-section2 bold-600">Theme Color</h4>
                    <span> Themecolor: Primary color for consistency. </span>
                  </div>
                  <div class="col s12">
                    <div class="color-type row">
                      <p class="col s8">Choose Color</p>
                      <input formControlName="themeColor" type="color" class="col s4"
                        (input)="themeColorChanged($event)">
                    </div>
                  </div>
                </div>

                <!-- =========== bg Color ============ -->
                <div class="col l4 s12  card-container">
                  <div class="col s12">
                    <h4 class="sub-title-section2 bold-600">Background Color</h4>
                    <span> Public page background: Chosen for aesthetics and readability. </span>
                  </div>
                  <div class="col s12">
                    <div class="color-type row">
                      <p class="col s8">Choose Color</p>
                      <input formControlName="bodyBgColor" type="color" class="col s4 ">
                    </div>
                  </div>
                </div>
                <!-- =========== login bg Color ============ -->
                <div class="col l4 s12 card-container">
                  <div class="col s12">
                    <h4 class="sub-title-section2 bold-600">Login/ Register card Background</h4>
                    <span> Public page background: Chosen for aesthetics and readability. </span>
                  </div>
                  <div class="col s12">
                    <div class="color-type row">
                      <p class="col s8">Choose Color</p>
                      <input formControlName="authformBgColor" type="color" class="col s4 ">
                    </div>
                  </div>
                </div>
                <!-- =========== login section Color ============ -->
                <div class="col l4 s12   card-container">
                  <div class="col s12">
                    <h4 class="sub-title-section2 bold-600">Login/ Register card Setion Color</h4>
                    <span> Public page background: Chosen for aesthetics and readability. </span>
                  </div>
                  <div class="col s12">
                    <div class="color-type row">
                      <p class="col s8">Choose Color</p>
                      <input formControlName="authformSColor" type="color" class="col s4 ">
                    </div>
                  </div>
                </div>
              </div>

              <div class="col s12 l3 m3 offset-l9 offset-m9">
                <button type="submit" class="col s12 btn save-button" [ngClass]="'btn ' + buttonType"
                  style="margin-bottom: 15px;">save updates</button><br />
              </div>
            </form>
          </div>
        </li>

        <!-- =========== Button Option ============ -->
        <!-- -=================== nation Name of the header  ========== -->
        <li class="row">
          <div class="col l11 s9">
            <div class="collapsible-header">
              <span class="material-symbols-outlined p-r-1 " [ngStyle]="{'color': themeColor}">
                subheader
              </span>Header Logo Setup
            </div>
            <p>This is the content that will be displayed on the header section.</p>
          </div>

          <div class="col l1 s3 vertical-center p-t-3">
            <span class="tooltip center">
              <i class="material-symbols-outlined" (click)="editNation()" style="cursor: pointer;">info</i>
              <span class="tooltip-text">Please click on the icon to <br /> edit the nation's name and logo.
              </span>
            </span>
          </div>

          <div class="collapsible-body row col s12">
            <form [formGroup]="appSettings" (ngSubmit)="SaveSettings(appSettings.value)">
              <div class="row align-center">

                <!-- ========= logo only ============= -->
                <div class="logo-section col s12 l4" *ngIf="logo">
                  <label>
                    <input class="radioBtn with-gap" type="radio" name="logoOption" value="logoOnly"
                      formControlName="logoOption" (click)="logoOnlyClicked()" />
                    <span class="label-content">
                      <span class="sub-title-section2 bold-600 black-text">Logo Only</span>
                    </span>
                    <div class="card">
                      <div class="card-content">
                        <div class="logo-image ">
                          <img *ngIf="logo" class="responsive-img" width="80" [src]="logo">
                        </div>
                      </div>
                    </div>
                  </label>
                </div>

                <!-- ========= nation name only ============= -->
                <div class="logo-section col s12 l4">
                  <label>
                    <input class="radioBtn with-gap" type="radio" name="logoOption" value="nationOnly"
                      formControlName="logoOption" (click)="nationOnlyClicked()" />
                    <span class="label-content">
                      <span class="sub-title-section2 bold-600 black-text">Nation Name Only</span>
                    </span>
                    <div class="card" style="display: flex; align-items: center;">
                      <div class="card-content">
                        <div class="logo-image p-l-1 p-t-1 center">
                          <span class="label-content">
                            <a [routerLink]="[]" class="brand-logo" style="font-size: 15px;">{{nationName}}</a>
                          </span>
                        </div>
                      </div>
                    </div>
                  </label>
                </div>

                <!-- ========= logo and nation name ============= -->
                <div class="logo-section col s12 l4" *ngIf="logo">
                  <label>
                    <input class="radioBtn with-gap" type="radio" name="logoOption" value="bothNationLogo"
                      formControlName="logoOption" (click)="bothNationLogoClicked()" />
                    <span class="label-content">
                      <span class="sub-title-section2 bold-600 black-text">Logo with Nation Name </span>
                    </span>
                    <div class="card">
                      <div class="card-content">
                        <div class="logo-image ">
                          <span class="label-content" style="display: flex; align-items: center;">
                            <img *ngIf="logo" class="responsive-img" [src]="logo" width="80">
                            <a [routerLink]="[]" class="brand-logo"
                              style="font-size: 15px; margin-left: 10px;">{{nationName}}</a>
                          </span>
                        </div>
                      </div>
                    </div>
                  </label>
                </div>

              </div>

              <div class="col s12 l3 m3 offset-l9 offset-m9">
                <button type="submit" class="col s12 btn save-button" [ngClass]="'btn ' + buttonType"
                  style="margin-bottom: 15px;">save updates</button><br />
              </div>
            </form>
          </div>
        </li>

        <!-- ================= Register/Login Setup ================== -->
        <li class="row">
          <div class="col l11 s9">
            <div class="collapsible-header">
              <span class="material-symbols-outlined p-r-1 " [ngStyle]="{'color': themeColor}">
                how_to_reg
              </span>Member Login/Register Setup
            </div>
            <p>The edited content is what will appear on the Login and Registration Setup for the public-facing aspect.
            </p>
          </div>

          <div class="col l1 s3 p-t-3">
            <a class=" modal-trigger" data-target="modal-login-preview" style="cursor: pointer;">
              <span class="material-symbols-outlined black-text tooltipped center" data-position="bottom"
                data-tooltip="Preview">
                visibility
              </span>
            </a>
          </div>

          <div class="collapsible-body row col s12">
            <form [formGroup]="appSettings" (ngSubmit)="SaveSettings(appSettings.value)">
              <div class="card-panel" style="padding-left: 2vh;">
                <div class="col l4 s12 card-container">
                  <div class="col s12">
                    <h4 class="sub-title-section2 bold-600">Login/ Register card Background</h4>
                    <span> Public page background: Chosen for aesthetics and readability. </span>
                  </div>
                  <div class="col s12">
                    <div class="color-type row">
                      <p class="col s8">Choose Color</p>
                      <input formControlName="authformBgColor" type="color" class="col s4 ">
                    </div>
                  </div>
                </div>

                <div class="col l4 s12  offset-l1 card-container">
                  <div class="col s12">
                    <h4 class="sub-title-section2 bold-600">Login/ Register card Setion Color</h4>
                    <span> Public page background: Chosen for aesthetics and readability. </span>
                  </div>
                  <div class="col s12">
                    <div class="color-type row">
                      <p class="col s8">Choose Color</p>
                      <input formControlName="authformSColor" type="color" class="col s4 ">
                    </div>
                  </div>
                </div>
              </div>

              <div class="col s12 l3 m3 offset-l9 offset-m9 ">
                <button type="submit" class="col s12 btn save-button" [ngClass]="'btn ' + buttonType"
                  style="margin-bottom: 15px;">save updates</button><br />
              </div>
            </form>
          </div>
        </li>

        <!-- ================= nav bar ================== -->
        <li class="row">
          <form [formGroup]="appSettings" (ngSubmit)="SaveSettings(appSettings.value)">
            <div class="col l9 s9">
              <div class="collapsible-header">
                <span class="material-symbols-outlined p-r-1" [ngStyle]="{'color': themeColor}">
                  bottom_navigation
                </span> Navigation bar Option
              </div>
              <p>The navigation bar option enables customizable menus on desktop, tablet, and mobile, allowing for
                flexible placement and display of multiple menus.</p>
            </div>

            <div class="col l3 s3">
              <div class="switch">
                <label class="right">
                  <span class="hide-on-small-only">Hide</span>
                  <input type="checkbox" formControlName="navbarOption" (change)="navbarOptionChanged()">
                  <span class="lever"></span>
                  <span class="hide-on-small-only">Show</span>
                </label>
              </div>
            </div>

            <div class="col s12 collapsible-body" [ngClass]="{'disabledDiv': !navbarOptionOn}"
              [style.display]="navbarOptionOn ? 'block' : 'none'">

              <!-- =========== Nav BAr Option ============ -->
              <div class="row">

                <!-- Content for the navigation bar options -->
                <div class="row col s12 white">
                  <div class="card-content">
                    <div class="col s12">
                      <!-- Tabs for desktop, tablet, and mobile views -->
                      <ul class="tabs">
                        <li class="tab col s4"><a class="active" href="#desktopView">Desktop View</a></li>
                        <li class="tab col s4"><a href="#tabletView">Tablet View</a></li>
                        <li class="tab col s4"><a href="#mobileView">Mobile View</a></li>
                      </ul>
                    </div>

                    <!-- Contents for the desktop view -->
                    <div id="desktopView" class="row section col s12" formGroupName="desktopView">
                      <div class="col s12 l9 m12">
                        <h6 class="sub-title-section2 bold-700">Side of the Menu</h6>
                        <label class="right">
                          <input type="radio" value="right" class="with-gap" formControlName="menuPosition" />
                          <span>Menu on the right</span>
                        </label>
                        <label class="left">
                          <input type="radio" value="left" class="with-gap" formControlName="menuPosition" />
                          <span>Menu on the left</span>
                        </label>
                        <div class="row section"></div>

                        <div class="">
                          <div class="col l6 s12">
                            <h6 class="sub-title-section2 bold-700">Number of Items</h6>
                            <div class="input-field ">
                              <select id="numberOfMenu" formControlName="numberOfMenus">
                                <option value="" disabled selected>Choose</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <!-- Contents for the tablet view -->
                    <div id="tabletView" class="row section col s12" formGroupName="tabletView">
                      <div class="col s12 l8 m12">
                        <h6 class="sub-title-section2 bold-700">Side of the Menu</h6>
                        <label class="right">
                          <input type="radio" value="right" class="with-gap" formControlName="menuPosition" />
                          <span>Menu on the right</span>
                        </label>
                        <label class="left">
                          <input type="radio" value="left" class="with-gap" formControlName="menuPosition" />
                          <span>Menu on the left</span>
                        </label>
                      </div>
                    </div>

                    <!-- Contents for the mobile view -->
                    <div id="mobileView" class="row section col s12" formGroupName="mobileView">
                      <div class="col s12 l8 m12">
                        <h6 class="sub-title-section2 bold-700">Position of the Menu</h6>
                        <label class="left">
                          <input type="radio" value="top" class="with-gap" formControlName="menuPosition" />
                          <span>Menu on top</span>
                        </label>
                        <label class="right">
                          <input type="radio" value="bottom" class="with-gap" formControlName="menuPosition" />
                          <span>Menu on the bottom</span>
                        </label>
                        <div class="row section"></div>
                        <h6 class="sub-title-section2 bold-700">Number of Items</h6>
                        <div class="input-field col s6">
                          <select id="numberOfMenu" formControlName="numberOfMenus">
                            <option value="" disabled selected>Choose</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                          </select>
                        </div>
                      </div>
                    </div>

                    <!-- button -->
                    <div class="col s12 l3 m3 offset-l9 offset-m9">
                      <button type="submit" class="col s12 btn save-button" [ngClass]="'btn ' + buttonType"
                        style="margin-bottom: 15px;">save updates</button><br />
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </form>
        </li>

        <!-- ================= Welcome Setup ================== -->
        <li class="row" *ngIf="welcomeSetting">
          <form (ngSubmit)="SaveSettings(appSettings.value)" [formGroup]="appSettings">
            <div class="col l10 s9">
              <div class="collapsible-header">
                <span class="material-symbols-outlined p-r-1" [ngStyle]="{'color': themeColor}">
                  restart_alt
                </span>
                Pop Up Welcome On/Off
              </div>
              <p>This is the content that will be displayed on welcoming modal on the registration
                page.</p>
            </div>
            <div class="col l2 s3">
              <div class="switch">
                <label class="right">
                  Off
                  <input type="checkbox" formControlName="welcomeSetup" (change)="welcomeSetupChanged()">
                  <span class="lever"></span>
                  On
                </label>
              </div>
            </div>
          </form>

          <div *ngIf="welcomeSetupOn" class="collapsible-body" [ngClass]="{'disabledDiv': welcomeSetupOn}">
            <span>
              <div class="row">
                <!-- title -->
                <div class="input-field col l12 m11 s12 card">
                  <div class="sub-title-section bold-600 black-text">Title</div>
                  <app-editor [title]=true [model]="welcomeSetting.title" [styler]=false
                    (onModelChange)="styleChanged($event, 'Welcome Title')" [type]=true></app-editor>
                </div>

                <!-- Description -->
                <div class="input-field col l12 m11 s12 card">
                  <div class="sub-title-section bold-600 black-text">Descriptions</div>
                  <app-editor [title]=false [model]="welcomeSetting.description" [styler]=false
                    (onModelChange)="styleChanged($event, 'Welcome Description')" [type]=true></app-editor>
                </div>

                <!-- Upload Image -->
                <div class="card-panel1  col l12 m11 s12">
                  <div class="card" [ngClass]="[welcomeSetting.image ? 'col l4' : 'col s12']">
                    <div class="center-align ">
                      <input name="file" type="file" id="fileField" accept="image/*"
                        (change)="handleFileInput($event.target.files, 'welcome')" />
                      <i class="fa fa-cloud-upload p-t-2"></i>
                      <h4 class="label-input p-b-2  bold-200">
                        Drop & Drop to Upload file <br />
                        or <br />
                        <u class="bold-200">Click to Upload</u>
                      </h4>
                    </div>
                  </div>

                  <div class="card col l6 push-l1 s12" *ngIf="welcomeSetting.image">
                    <div class="card-image">
                      <img [src]="welcomeSetting.image" width="200px" height="200px">
                    </div>

                  </div>
                </div>

                <!-- Save Button -->
                <div class="col l12 m12 s12">
                  <button class="btn save-button col l3 s12 right" (click)="saveWelcomeSettings()"
                    [ngClass]="'btn ' + buttonType"><span *ngIf="welcomeSetting">Save</span> <span
                      *ngIf="!welcomeSetting">Update</span></button>
                </div>
              </div>
            </span>
          </div>
        </li>

        <!-- =================Registration setup  ================== -->
        <li class="row">
          <form [formGroup]="appSettings" (ngSubmit)="SaveSettings(appSettings.value)">
            <div class="col l10 s9">
              <div class="collapsible-header">
                <span class="material-symbols-outlined p-r-1" [ngStyle]="{'color': themeColor}">
                  switches
                </span>
                Access to the app
                <!-- Would you like to enable direct app sign-up for your app? -->
              </div>
              <p>Do you want members to be verified before they can access {{nationName}} ?</p>
            </div>

            <div class="col l2 s3">
              <div class="switch ">
                <label class="right">
                  No
                  <input type="checkbox" formControlName="directSignup" (change)="signupOptionChanged()">
                  <span class="lever"></span>
                  Yes
                </label>
              </div>
            </div>
          </form>
        </li>


        <!-- =================  Governance Setup ================== -->
        <li class="row">
          <div class="collapsible-header "> <span class="material-symbols-outlined p-r-1"
              [ngStyle]="{'color': themeColor}">
              gavel
            </span> Governance Setting</div>
          <p>This is the section where you have the ability to customize the titles for the leaders of the nations,
            ensuring consistency throughout the application.</p>

          <div class="collapsible-body ">
            <form [formGroup]="appSettings" (ngSubmit)="SaveSettings(appSettings.value)">

              <div class="row card-panel">

                <!-- =========== Chief ============ -->
                <div class="col s12">
                  <h4 class="sub-title-section2 bold-600">Chief Title</h4>
                  <span>Chief: The primary leader of a First Nation community. </span>
                  <input formControlName="chiefTitle" type="text" class="validate">
                </div>

                <!-- =========== Councillor ============ -->
                <div class="col s12">
                  <h4 class="sub-title-section2 bold-600">Councillor Title</h4>
                  <span>Councillors: Individuals elected or appointed to work alongside the Chief in the governance of
                    the First Nation. </span>
                  <input formControlName="councillorTitle" type="text" class="validate">
                </div>
              </div>

              <div class="col s12 l3 m3 offset-l9 offset-m9">
                <button type="submit" class="col s12 btn save-button" [ngClass]="'btn ' + buttonType"
                  style="margin-bottom: 15px;">save updates</button><br />
              </div>
            </form>
          </div>
        </li>

        <!-- =========================== SEO integrations ================================== -->
        <li class="row">

          <div class="collapsible-header "> <span class="material-symbols-outlined p-r-1"
              [ngStyle]="{'color': themeColor}">
              gavel
            </span> SEO Setup</div>

          <p>SEO (Search Engine Optimization) is the process of improving your website so it appears higher in search results, making it easier for people to find you online. </p>
          <div class="collapsible-body ">
           <form [formGroup]="appSettings" (ngSubmit)="SaveSettings(appSettings.value)">

              <!-- App name Section -->
              <div class="mt-6  ">
                <label for="appTitle" class="block tracking-wide text-gray-700 text-base font-bold mb-2">
                  Website Title
                  <div class="tooltip" data-tip="This is what will show to the public">
                    <span class="red-text
                    ">*</span>
                  </div>
                </label>
                <input
                  class="w-full px-3 pb-[6px] pt-2 lg:w-8/12 appearance-none block  text-gray-700 border border-solid border-gray-300 rounded  mb-3 leading-tight focus:outline-none focus:bg-gray-50 focus:border-customTeal"
                  id="appTitle" type="text" placeholder="Website title" formControlName="appTitle">
              </div>

              <div class="mt-6  ">
                <label for="appTitle" class="block tracking-wide text-gray-700 text-base font-bold mb-2">
                  Website Description
                  <div class="tooltip" data-tip="This is what will show to the public">
                    <span class="red-text
                    ">*</span>
                  </div>
                </label>
                <input
                  class="w-full px-3 pb-[6px] pt-2 lg:w-8/12 appearance-none block  text-gray-700 border border-solid border-gray-300 rounded  mb-3 leading-tight focus:outline-none focus:bg-gray-50 focus:border-customTeal"
                  id="appTitle" type="text" placeholder="Website Description" formControlName="description">
              </div>
              <div>
                <button type="button" (click)="
                saveTitle() "
                  class="btn-custom-solid float-right mt-5">Save</button>
              </div>
            </form>
          </div>
        </li>
        

        <!-- =================Modal Login -->
        <div id="modal-login-preview" class="modal bottom-sheet custom-modal-height">
          <div class="col s12 modal-close p-t-1">
            <a class="close_btn btn right" [ngStyle]="{'color': themeColor}">Close Preview</a>
          </div>
          <div class="modal-content ">
            <div class="col s12 l10 offset-l1 p-t-2">
              <app-login-preview></app-login-preview>
            </div>
          </div>
        </div>
      </ul>
    </main>

    <!-- ================= end new design ================== -->
  </div>
</div>