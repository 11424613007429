
export const environment = {
  production: true,

  appTheme: {
    themeColor: '#0d47a1',
    fontColor: 'teal-text text-darken-4',
    adminThemeColor: '#0d47a1',
    adminFontColor: 'teal-text text-darken-4'
  },

  firstNation: {
    name: 'Demo',
    displayName: 'Demo',
    province: 'Alberta',
    website: 'https://demo.mysabenefits.ca/#',
    apiUrl: 'https://api2.mynation.app/',
    //apiUrl: 'https://api.mysabenefits.ca/',
    goingMobileApi:'https://api.goingmobile.app/',
    SQLDB: true,
    SOCIAL: true, //truncateSync,
    ISET: true,
    PSE: true,
  },

  firebase: {
    apiKey: 'AIzaSyAnP3pQXGJu0YiQyof6R8x1znVXPZ8dqnU',
    authDomain: 'dome-my-sa-benefits.firebaseapp.com',
    databaseURL: 'https://dome-my-sa-benefits.firebaseio.com',
    projectId: 'dome-my-sa-benefits',
    storageBucket: 'dome-my-sa-benefits.appspot.com',
    messagingSenderId: '219289067355',
    appId: '1:219289067355:web:ae4f559862b3245343847e'
  },


  //  firebase: {
  //   apiKey: 'AIzaSyCzxN9VirHFZNTIUUt2NE9mPvCT3Clqgpw',
  //   authDomain: 'denetha-sabenefits.firebaseapp.com',
  //   databaseURL: 'https://denetha-sabenefits.firebaseio.com',
  //   projectId: 'denetha-sabenefits',
  //   storageBucket: 'denetha-sabenefits.appspot.com',
  //   messagingSenderId: '976491744289',
  //   appId: '1:976491744289:web:8e3dcf4b76bb2732e2a760',
  //   measurementId: 'G-2NRDF26E47'
  // },

  googleMapKey: {
    key: 'AIzaSyC8o5ZIQ2BmCfBXkbemb6QEDUaEceIEMaI'
  },

  pusher: {
    key: '51b3ca8b4eeca8b79cc2',
  },


  socialMedia: {
    key:"SM7YH55-AK5MH4Y-PF1WGX5-XYMR1YT",
    url: "https://app.ayrshare.com/api/",
    domain:'mynation',
    profileUrl:'https://profile.ayrshare.com/',
    privateKey:'-----BEGIN RSA PRIVATE KEY-----\nMIICWwIBAAKBgQCposQLmyEu2xhf61bsMkoo6tBK44xLsF4BXH2MiZSfeT0lgdlD\nvtuk1R2pCN4chtX54AuB7Mva7UPQLCtHuN7Q4BxMy3T7ggJ2Cct4HjoZnGCCDIt3\nH5KXZo47vM9Cxw27R/LmnIcU+kiUwsJv21UpWAtOZjutb8JE4P3pFg00mwIDAQAB\nAn8ROnautxKnr5jKJRyu4GynuzFsaa/WABOMD9iwbUiq6aVMKePm/XtRQlPA1yW3\nv/n7ZsXEbEhH89DvU40Ru7TlVz/vrfW+nWcpe6WL/3s+5XU7qO2haKaFo0RsFC02\nyewK87EhoYANBgEcCVNXU07JDQGuQhGmMcDcek4qs0JxAkEA6hMOoL8sc3O6aYfZ\nYyga0hIhhGldlLTUZtck0+gNxOPGGxSc3KzdbIbezx4H21DC2juWHcdjihPV//Vq\nBoKS1QJBALmGg8lFUz5V2kmIP8SjSdcSBsGfPZvKwCkspzHGyQqrnc0E2kN646Dn\nyT4gsFwXAH/cNa1hgmG3u19knItYAa8CQHdQa35otEHNVdIBG0A96x31e/xAsSDh\n4/rf47fYa1xXNl4LbcHWo690OBKfHiYezpPrF6PqRMTXQ5gLApZ67FkCQQCYNBkO\nGIfQjhKFXPgj1GqRvUI5uvCKTW4U2YNZPOJWlLDjL+X4kmsI6N+azqqhAmXwf7Kx\nyVtz244nFp2vW8YVAkEAiW7/rrRsskuqXewT9aaR/skFJBqnICA41PPd/kGU9ieO\nHMeGDF+3wDnCmig7Zlqxnw2ojf4+/KPURFt/r8KdRg==\n-----END RSA PRIVATE KEY-----'
  },
  

};
