import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { TempHousingService } from 'src/app/services/firebase/temp-housing.service';
import { toast } from 'materialize-css';
import { DatePipe, Location } from '@angular/common';
import { environment } from 'src/environments/environment';
import { Attachment } from 'src/app/models/emailModel';
import { MediaService } from 'src/app/services/api/media.service';
import { createMask } from '@ngneat/input-mask';

const pdfMake = require('pdfmake/build/pdfmake.js');
const pdfFonts = require('pdfmake-unicode/dist/pdfmake-unicode.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-renovation-requests',
  templateUrl: './renovation-requests.component.html',
  styleUrls: ['./renovation-requests.component.css']
})
export class RenovationRequestsComponent implements OnInit {

  //public dateMask = [/[0-3]/, /[0-9]/, '/', /[0-1]/, /[0-9]/, '/', /[1-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  public dateformat = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
  
  dateInputMask = createMask<Date>({
    alias: 'datetime',
    guide: true,
    inputFormat: 'dd/mm/yyyy',
    parser: (value: string) => {
      const values = value.split('/');
      const year = +values[2];
      const month = +values[1] - 1;
      const date = +values[0];
      return new Date(year, month, date);
    },
  });


  themeColor = environment.appTheme.themeColor;
  loading = false;
  requests: any[];
  pending: any[] = [];
  approved: any[] = [];
  declined: any[] = [];
  completed: any[] = [];
  approveForm: UntypedFormGroup;
  completeReqForm: UntypedFormGroup;
  index: number;
  employees: any;
  declineReason = '';
  propertyRequests: any[];
  hasOverdue = false;
  overdueRequests: any[];
  houseNumber: any;
  isAppeal = false;
  showMorePending: any[] = [];
  showMoreDeclined: any[] = [];
  emailList: any[] = [];
  fileList: any[] = [];
  reportFile: File;
  fileName = "Renovation-Request";
  fileLink = '';
  sendEmail = false;
  emailSent = false;
  status = ['Pending', 'Approved', 'Declined', 'Completed'];

  emailSentHandler(valueEmitted) {
    if (valueEmitted) this.emailSent = true;
    this.sendEmail = false;
  }

  constructor(private tempHousingService: TempHousingService,
    private fb: UntypedFormBuilder,
    private location: Location,
    private mediaService: MediaService) { }

  ngOnInit() {
    this.loading = true;

    this.tempHousingService.getRenovationRequests().valueChanges().subscribe(x => {
      if (x && x.length > 0) {
        this.requests = x;
        this.pending = this.requests.filter(x => x.status === this.status[0]);
        this.approved = this.requests.filter(x => x.status === this.status[1]);
        this.declined = this.requests.filter(x => x.status === this.status[2]);
        this.completed = this.requests.filter(x => x.status === this.status[3]);

        const today = new Date();
        today.setHours(0, 0, 0, 0);

        this.overdueRequests = this.approved.filter(x => x.decision.startDate.toDate().valueOf() < today.valueOf());

        if (this.overdueRequests.length > 0) {
          this.hasOverdue = true;
        } else {
          this.hasOverdue = false;
        }

        for (var i = 0; i < this.approved.length; i++) {
          this.showMorePending.push(false);
        };
      }
      this.loading = false;
      setTimeout(() => {
        $('.modal').modal();
        $('.tabs').tabs();
        $('.dropdown-trigger').dropdown();
      }, 25);
    });

    this.tempHousingService.getEmployees().valueChanges().subscribe(employees => {
      if (employees && employees.length > 0) {
        this.employees = employees;
      }
    });

    this.approveForm = this.fb.group({
      employee: ['', [Validators.required]],
      startDate: ['', [Validators.required, this.isValidDate]],
      endDate: ['', [Validators.required, this.isValidDate]],
      message: ['']
    });

    this.completeReqForm = this.fb.group({
      comment: ['', [Validators.required]],
      completedOn: ['', [Validators.required, this.isValidDate]]
    });

    setTimeout(() => {
      $('.modal').modal();
      $('.tabs').tabs();
      $('.dropdown-trigger').dropdown();
    }, 25);
  }

  get startDate() { return this.approveForm.get('startDate'); }
  get endDate() { return this.approveForm.get('endDate'); }
  get completedOn() { return this.completeReqForm.get('completedOn'); }
  get comment() { return this.completeReqForm.get('comment'); }

  isValidDate(control: UntypedFormControl) {
    let date = control.value;
    const dateformat = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
    if (date) {
      if (date.match(dateformat)) {
        let dateArray = date.split('/');
        let year = dateArray[2];
        let month = dateArray[1] - 1;
        let day = dateArray[0];
        date = new Date(year, month, day);

        const today = new Date();
        today.setHours(0, 0, 0, 0);
        if (date.valueOf() >= today.valueOf()) {
          return null;
        } else {
          return { 'invalidDate': true }
        }
      }
      else {
        return { 'invalidDate': true }
      }
    }
    return null;
  }

  changeDates(dateValue) {
    if (dateValue) {
      if (dateValue.match(this.dateformat)) {
        let dateArray = dateValue.split('/');
        let year = dateArray[2];
        let month = dateArray[1] - 1;
        let day = dateArray[0];
        const date = new Date(year, month, day);
        return date;
      } else {
        const date = new Date(dateValue);
        return date;
      }
    } else {
      return null
    }
  }

  requestOverDue(startDate) {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    const date = startDate.toDate();
    if (date.valueOf() < today.valueOf()) {
      return true;
    } else {
      return false;
    }
  }

  filterBy(value) {
    if (value) {
      this.houseNumber = value;
      this.propertyRequests = this.requests.filter(x => x.houseNumber == value);
      this.pending = this.propertyRequests.filter(x => x.status === this.status[0]);
      this.approved = this.propertyRequests.filter(x => x.status === this.status[1]);
      this.declined = this.propertyRequests.filter(x => x.status === this.status[2]);
      this.completed = this.propertyRequests.filter(x => x.status === this.status[3]);
    } else {
      this.houseNumber = null;
      this.pending = this.requests.filter(x => x.status === this.status[0]);
      this.approved = this.requests.filter(x => x.status === this.status[1]);
      this.declined = this.requests.filter(x => x.status === this.status[2]);
      this.completed = this.requests.filter(x => x.status === this.status[3]);
    }
  }

  isDate(date) {
    return date instanceof Date
  }

  onShowMore(index, from) {
    if (from == 'approved') this.showMorePending[index] = !this.showMorePending[index];
    else if (from == 'declined') this.showMoreDeclined[index] = !this.showMoreDeclined[index];
  }

  acceptRequest(index, isAppeal) {
    if (isAppeal == 'appeal') {
      this.isAppeal = true;
    } else {
      this.isAppeal = false;
    }
    this.index = index;
    $('.modal').modal();
    $('#approveModal').modal('open');
    $('select').formSelect();
    M.textareaAutoResize($('#message'));
    M.updateTextFields();
  }

  approve(values) {
    let request;
    if (this.isAppeal) request = this.declined[this.index];
    else request = this.pending[this.index];
    const decision = {
      employee: values.employee ? values.employee : '',
      startDate: this.changeDates(values.startDate),
      endDate: this.changeDates(values.endDate),
      message: values.message,
      date: new Date()
    }
    if (this.isAppeal) request.previouseDecision = request.decision;
    request.decision = decision;
    request.status = this.status[1];

    this.tempHousingService.updateRenovationRequest(request).then(x => {
      toast({ html: 'Request Has been Approved!', classes: 'green' });
      this.closeModal();
    });
  }

  declineRequest(index, isAppeal) {
    if (isAppeal == 'appeal') this.isAppeal = true;
    else this.isAppeal = false;
    this.index = index;
    $('.modal').modal();
    $('#declineModal').modal('open');
    M.textareaAutoResize($('#reason'));
  }

  decline() {
    let request;
    if (this.isAppeal) request = this.declined[this.index];
    else request = this.pending[this.index];

    const decision = {
      reason: this.declineReason,
      date: new Date()
    }
    if (this.isAppeal) request.previouseDecision = request.decision;
    request.decision = decision;
    request.status = this.status[2];

    this.tempHousingService.updateRenovationRequest(request).then(x => {
      toast({ html: 'Request Has been Declined!', classes: 'green' });
      this.closeModal();
      this.declineReason = '';
    });
  }

  requestCompleted(index) {
    this.index = index;
    const today = new Date();
    const date_pipe = new DatePipe('en-US');
    this.completedOn.patchValue(date_pipe.transform(today, 'dd/MM/yyyy'));
    $('.modal').modal();
    $('#completeModal').modal('open');
    M.textareaAutoResize($('#comment'));
    M.updateTextFields();
  }

  complete(values) {
    const request = this.approved[this.index];
    request.status = this.status[3];
    request.comment = values.comment;
    request.completedOn = this.changeDates(values.completedOn);
    this.tempHousingService.updateRenovationRequest(request).then(x => {
      toast({ html: 'Request Completed!', classes: 'green' });
      this.closeModal();
    });
  }

  createRange() {
    var items: number[] = [];
    for (var i = 1; i <= 5; i++) {
      items.push(i);
    }
    return items;
  }

  back() {
    this.location.back();
  }

  closeModal() {
    $('.modal').modal('close');
  }

  async getReport(status) {
    let report = {
      info: {
        title: 'RENOVATION REQUEST REPORT',
        subject: 'Renovation Request Report',
        keywords: 'Renovation Request',
      },
      pageSize: 'LETTER',
      content: await this.getContent(status),
      styles: this.getStyles(),
    }
    return report;
  }

  async getContent(status) {
    let content = [];
    let requests = [];
    if (status == 'pending') requests = this.pending;
    else if (status == 'approved') requests = this.approved;
    else if (status == 'declined') requests = this.declined;
    else if (status == 'completed') requests = this.completed;
    else if (status == 'house') requests = this.propertyRequests;
    else requests = this.requests;

    //summary
    content.push(
      {
        style: 'table',
        layout: 'noBorders',
        table: {
          widths: ['*', '*'],
          body: [
            [
              {
                columns: [
                  [
                    await this.getLogo(),
                  ],
                  [
                    {
                      stack: [
                        {
                          text: 'MY NATION ',
                          style: 'mainTitle',
                          alignment: 'center',
                        },
                        {
                          text: 'Connecting Your Nation',
                          style: 'normalText',
                          alignment: 'center',
                        },
                        {
                          text: 'Sovereign Data For Sovereign Nations ',
                          fontSize: 8,
                          italics: true,
                          alignment: 'center',
                        },
                      ]
                    },
                  ],
                ]
              },
              {
                stack: [
                  {},
                  {
                    text: 'RENOVATION REQUESTS',
                    style: 'title'
                  },
                  {},
                ]
              },
            ],
          ]
        }
      },
      {
        style: 'table',
        layout: 'lightHorizontalLines',
        pageMargins: [100, 100],
        table: {
          widths: ['*', '*', '*', '*'],
          body: this.getSummary(requests)
        }
      },
      { text: '', pageBreak: 'after' }
    );

    requests.forEach(request => {
      content.push(
        //request details
        {
          style: 'table',
          table: {
            widths: ['*', '*', '*'],
            body: [
              [
                {
                  stack: [
                    {
                      text: 'Title: ',
                      style: 'subTitle'
                    },
                    {
                      text: request.title.toUpperCase(),
                      style: 'normalText'
                    },
                  ]
                },

                {
                  stack: [
                    {
                      text: 'Description: ',
                      style: 'subTitle'
                    },
                    {
                      text: request.description,
                      style: 'normalText'
                    },
                  ],
                },
                {
                  stack: [
                    {
                      text: 'Purpose: ',
                      style: 'subTitle'
                    },
                    {
                      text: request.purpose,
                      style: 'normalText'
                    },
                  ],
                },
              ],
              [
                {
                  stack: [
                    {
                      text: 'House Number: ',
                      style: 'subTitle'
                    },
                    {
                      text: request.houseNumber,
                      style: 'normalText'
                    },
                  ]
                },

                {
                  stack: [
                    {
                      text: 'Requested On: ',
                      style: 'subTitle'
                    },
                    {
                      text: request.requestDate.toDate().toDateString(),
                      style: 'normalText'
                    },
                  ],
                },
                {
                  stack: [
                    {
                      text: 'Status: ',
                      style: 'subTitle'
                    },
                    {
                      text: request.status.toUpperCase(),
                      style: 'normalText'
                    },
                  ],
                },
              ],
            ]
          }
        },

        //Approved Requests
        request.decision && request.status === this.status[1] ?
          {
            style: 'table',
            table: {
              widths: ['*', '*', '*'],
              body: [
                [
                  {
                    text: 'Request Details: ',
                    style: 'subTitle',
                    colSpan: 3
                  },
                  {},
                  {}
                ],
                [
                  {
                    stack: [
                      {
                        text: 'Case Officer: ',
                        style: 'subTitle'
                      },
                      {
                        text: request.decision.employee,
                        style: 'normalText'
                      },
                    ]
                  },
                  {
                    stack: [
                      {
                        text: 'Start Date: ',
                        style: 'subTitle'
                      },
                      {
                        text: request.decision.startDate.toDate().toDateString(),
                        style: 'normalText'
                      },
                    ],
                  },
                  {
                    stack: [
                      {
                        text: 'End Date: ',
                        style: 'subTitle'
                      },
                      {
                        text: request.decision.endDate.toDate().toDateString(),
                        style: 'normalText'
                      },
                    ],
                  },
                ],
                [
                  {
                    stack: [
                      {
                        text: 'Message: ',
                        style: 'subTitle'
                      },
                      {
                        text: request.decision.message,
                        style: 'normalText'
                      },
                    ],
                    colSpan: 2
                  },
                  {},
                  {
                    stack: [
                      {
                        text: 'Decision Made On: ',
                        style: 'subTitle'
                      },
                      {
                        text: request.decision.date.toDate().toDateString(),
                        style: 'normalText'
                      },
                    ],
                  },
                ],
              ]
            }
          } : '',
        //Declined Requests
        request.decision && request.status === this.status[2] ?
          {
            style: 'table',
            table: {
              widths: ['*', '*', '*'],
              body: [
                [
                  {
                    text: 'Request Details: ',
                    style: 'subTitle',
                    colSpan: 3
                  },
                  {},
                  {}
                ],
                [
                  {
                    stack: [
                      {
                        text: 'Reason: ',
                        style: 'subTitle'
                      },
                      {
                        text: request.decision.reason,
                        style: 'normalText'
                      },
                    ],
                    colSpan: 2
                  },
                  {},
                  {
                    stack: [
                      {
                        text: 'Decision Made On: ',
                        style: 'subTitle'
                      },
                      {
                        text: request.decision.date.toDate().toDateString(),
                        style: 'normalText'
                      },
                    ],
                  },
                ],
              ]
            }
          } : '',
        //Completed Requests
        request.status === this.status[3] && request.decision ?
          {
            style: 'table',
            table: {
              widths: ['*', '*', '*'],
              body: [
                [
                  {
                    text: 'Request Details ',
                    style: 'subTitle',
                    colSpan: 3
                  },
                  {},
                  {}
                ],
                [
                  {
                    stack: [
                      {
                        text: 'Case Officer: ',
                        style: 'subTitle'
                      },
                      {
                        text: request.decision.employee,
                        style: 'normalText'
                      },
                    ]
                  },
                  {
                    stack: [
                      {
                        text: 'Start Date: ',
                        style: 'subTitle'
                      },
                      {
                        text: request.decision.startDate.toDate().toDateString(),
                        style: 'normalText'
                      },
                    ],
                  },
                  {
                    stack: [
                      {
                        text: 'End Date: ',
                        style: 'subTitle'
                      },
                      {
                        text: request.decision.endDate.toDate().toDateString(),
                        style: 'normalText'
                      },
                    ],
                  },
                ],
                [
                  {
                    stack: [
                      {
                        text: 'Message: ',
                        style: 'subTitle'
                      },
                      {
                        text: request.decision.message,
                        style: 'normalText'
                      },
                    ],
                    colSpan: 2
                  },
                  {},
                  {
                    stack: [
                      {
                        text: 'Completed On: ',
                        style: 'subTitle'
                      },
                      {
                        text: request.completedOn.toDate().toDateString(),
                        style: 'normalText'
                      },
                    ],
                  },
                ],
              ]
            }
          } : '',
        request.status === this.status[3] && request.feedback ?
          {
            style: 'table',
            table: {
              widths: ['*', '*', '*'],
              body: [
                [
                  {
                    text: 'Feedback ',
                    style: 'subTitle',
                    colSpan: 3
                  },
                  {},
                  {}
                ],
                [
                  {
                    stack: [
                      {
                        text: 'Rating: ',
                        style: 'subTitle'
                      },
                      {
                        text: request.feedback.rating + " " + "/ 5",
                        style: 'normalText'
                      },
                    ],

                  },
                  {
                    stack: [
                      {
                        text: 'Review: ',
                        style: 'subTitle'
                      },
                      {
                        text: request.feedback.review,
                        style: 'normalText'
                      },
                    ],
                    colSpan: 2
                  },
                  {},
                ],
              ]
            }
          } : '',
        {
          text: "",
          pageBreak: 'after'
        }
      );
    });

    return content;
  }

  getStyles() {
    return {
      mainTitle: {
        bold: true,
        fontSize: 16,
        alignment: 'left',
        margin: [0, 0, 0, 20]
      },
      title: {
        bold: true,
        fontSize: 14,
        alignment: 'center',
        margin: [0, 10, 0, 10]
      },
      subTitle: {
        bold: true,
        fontSize: 12,
        alignment: 'left',
        margin: [0, 10, 0, 10]
      },
      normalText: {
        fontSize: 11,
      },
      header: {
        fontSize: 12,
        bold: true,
        alignment: 'center',
      },
      links: {
        color: 'blue',
        decoration: 'underline',
        //italics: true,
        margin: [0, 5, 0, 5]
      },
      table: {
        margin: [0, 5, 0, 5]
      },
    }
  }

  getSummary(requests) {
    let summary = [];
    summary.push([{ text: "Renovation Requests", colSpan: 4, style: 'title' }, {}, {}, {}]);
    summary.push([{ text: "Total: " + requests.length, colSpan: 4, alignment: 'center', margin: [10, 10] }]);
    summary.push([
      {
        text: 'Title',
        bold: true,
        margin: [0, 5]
      },
      {
        text: 'House Number',
        bold: true,
        margin: [0, 5]
      },
      {
        text: 'Request Date',
        bold: true,
        margin: [0, 5]
      },
      {
        text: 'Status',
        bold: true,
        margin: [0, 5]
      },
    ]);
    let i = 2;
    requests.forEach(request => {
      summary.push(
        [{ text: request.title ? request.title : '', linkToPage: i, style: 'normalText', margin: [0, 5] },
        { text: request.houseNumber ? request.houseNumber : '', linkToPage: i, style: 'normalText', margin: [0, 5] },
        { text: request.requestDate ? request.requestDate.toDate().toDateString() : '', linkToPage: i, style: 'normalText', margin: [0, 5] },
        { text: request.status ? request.status : '', linkToPage: i, style: 'normalText', margin: [0, 5] },
        ]);
      i++;
    });
    return summary;
  }

  async getLogo() {
    let imageLink = "assets/img/Nation-logo.png";
    const image = await this.getBase64FromUrl(imageLink);
    if (image) {
      return {
        image: image,
        width: 120,
        alignment: 'left'
      }
    }
  }

  getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      }
    });
  }

  async downloadReport(status) {
    const documentDefinition = await this.getReport(status);
    pdfMake.createPdf(documentDefinition).download(this.fileName);
  }

  async sendReport(status) {
    this.fileList = [];
    const documentDefinition = await this.getReport(status);
    const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
    pdfDocGenerator.getBlob((blob) => {
      this.reportFile = new File([blob], this.fileName, {
        type: blob.type
      });
      this.mediaService.uploadPDF(this.reportFile).subscribe(upload => {
        if (upload) {
          if (upload.imgLink) this.fileLink = upload.imgLink
          //this.fileLink = "https://api.mysabenefits.ca/Media/Demo/PDF/0de131ee-7909-44cc-bbbc-8e12fb611dd4-Houses.pdf";
          let report = new Attachment();
          report.url = this.fileLink;
          report.name = this.fileName;
          report.type = 'PDF';
          this.fileList.push(report);
          this.sendEmail = true;
        }
      });
    });
  }

  async getDocumentDefinition(status, index) {
    let request;

    if (status == 'Pending') {
      request = this.pending[index];
    }
    else if (status == 'Approved') {
      request = this.approved[index];
    }
    else if (status === 'Declined') request = this.declined[index];
    else request = this.completed[index];

    return {
      info: {
        title: 'RENOVATION REQUEST',
        subject: 'Renovation Request',
        keywords: 'Renovation Request',
      },
      pageSize: 'LETTER',
      content: [
        {
          style: 'table',
          layout: 'noBorders',
          table: {
            widths: ['*', '*'],
            body: [
              [
                {
                  columns: [
                    [
                      await this.getLogo(),
                    ],
                    [
                      {
                        stack: [
                          {
                            text: 'MY NATION ',
                            style: 'mainTitle',
                            alignment: 'center',
                          },
                          {
                            text: 'Connecting Your Nation',
                            style: 'normalText',
                            alignment: 'center',
                          },
                          {
                            text: 'Sovereign Data For Sovereign Nations ',
                            fontSize: 8,
                            italics: true,
                            alignment: 'center',
                          },
                        ]
                      },
                    ],
                  ]
                },
                {
                  stack: [
                    {},
                    {
                      text: 'RENOVATION REQUESTS',
                      style: 'title'
                    },
                    {},
                  ]
                },
              ],
            ]
          }
        },
        {
          style: 'table',
          table: {
            widths: ['*', '*', '*'],
            body: [
              [
                {
                  stack: [
                    {
                      text: 'Title: ',
                      style: 'subTitle'
                    },
                    {
                      text: request.title.toUpperCase(),
                      style: 'normalText'
                    },
                  ]
                },

                {
                  stack: [
                    {
                      text: 'Description: ',
                      style: 'subTitle'
                    },
                    {
                      text: request.description,
                      style: 'normalText'
                    },
                  ],
                  colSpan: 2
                },
                {},
              ],
              [
                {
                  stack: [
                    {
                      text: 'Purpose: ',
                      style: 'subTitle'
                    },
                    {
                      text: request.purpose,
                      style: 'normalText'
                    },
                  ],
                  colSpan: 2
                },
                {},
                {
                  stack: [
                    {
                      text: 'Renovation Date: ',
                      style: 'subTitle'
                    },
                    {
                      text: request.renovationDate.toDate().toDateString(),
                      style: 'normalText'
                    },
                  ],
                },
              ],
              [
                {
                  stack: [
                    {
                      text: 'House Number: ',
                      style: 'subTitle'
                    },
                    {
                      text: request.houseNumber,
                      style: 'normalText'
                    },
                  ]
                },

                {
                  stack: [
                    {
                      text: 'Requested On: ',
                      style: 'subTitle'
                    },
                    {
                      text: request.requestDate.toDate().toDateString(),
                      style: 'normalText'
                    },
                  ],
                },
                {
                  stack: [
                    {
                      text: 'Status: ',
                      style: 'subTitle'
                    },
                    {
                      text: request.status.toUpperCase(),
                      style: 'normalText'
                    },
                  ],
                },
              ],
            ]
          }
        },

        //Approved Requests
        request.decision && request.status === this.status[1] ?
          {
            style: 'table',
            table: {
              widths: ['*', '*', '*'],
              body: [
                [
                  {
                    text: 'Request Details: ',
                    style: 'subTitle',
                    colSpan: 3
                  },
                  {},
                  {}
                ],
                [
                  {
                    stack: [
                      {
                        text: 'Case Officer: ',
                        style: 'subTitle'
                      },
                      {
                        text: request.decision.employee,
                        style: 'normalText'
                      },
                    ]
                  },
                  {
                    stack: [
                      {
                        text: 'Start Date: ',
                        style: 'subTitle'
                      },
                      {
                        text: request.decision.startDate.toDate().toDateString(),
                        style: 'normalText'
                      },
                    ],
                  },
                  {
                    stack: [
                      {
                        text: 'End Date: ',
                        style: 'subTitle'
                      },
                      {
                        text: request.decision.endDate.toDate().toDateString(),
                        style: 'normalText'
                      },
                    ],
                  },
                ],
                [
                  {
                    stack: [
                      {
                        text: 'Message: ',
                        style: 'subTitle'
                      },
                      {
                        text: request.decision.message,
                        style: 'normalText'
                      },
                    ],
                    colSpan: 2
                  },
                  {},
                  {
                    stack: [
                      {
                        text: 'Decision Made On: ',
                        style: 'subTitle'
                      },
                      {
                        text: request.decision.date.toDate().toDateString(),
                        style: 'normalText'
                      },
                    ],
                  },
                ],
              ]
            }
          } : '',

        //Declined Requests
        request.decision && request.status === this.status[2] ?
          {
            style: 'table',
            table: {
              widths: ['*', '*', '*'],
              body: [
                [
                  {
                    text: 'Request Details: ',
                    style: 'subTitle',
                    colSpan: 3
                  },
                  {},
                  {}
                ],
                [
                  {
                    stack: [
                      {
                        text: 'Reason: ',
                        style: 'subTitle'
                      },
                      {
                        text: request.decision.reason,
                        style: 'normalText'
                      },
                    ],
                    colSpan: 2
                  },
                  {},
                  {
                    stack: [
                      {
                        text: 'Decision Made On: ',
                        style: 'subTitle'
                      },
                      {
                        text: request.decision.date.toDate().toDateString(),
                        style: 'normalText'
                      },
                    ],
                  },
                ],
              ]
            }
          } : '',

        //Completed Requests
        request.status === this.status[3] && request.decision ?
          {
            style: 'table',
            table: {
              widths: ['*', '*', '*'],
              body: [
                [
                  {
                    text: 'Request Details ',
                    style: 'subTitle',
                    colSpan: 3
                  },
                  {},
                  {}
                ],
                [
                  {
                    stack: [
                      {
                        text: 'Case Officer: ',
                        style: 'subTitle'
                      },
                      {
                        text: request.decision.employee,
                        style: 'normalText'
                      },
                    ]
                  },
                  {
                    stack: [
                      {
                        text: 'Start Date: ',
                        style: 'subTitle'
                      },
                      {
                        text: request.decision.startDate.toDate().toDateString(),
                        style: 'normalText'
                      },
                    ],
                  },
                  {
                    stack: [
                      {
                        text: 'End Date: ',
                        style: 'subTitle'
                      },
                      {
                        text: request.decision.endDate.toDate().toDateString(),
                        style: 'normalText'
                      },
                    ],
                  },
                ],
                [
                  {
                    stack: [
                      {
                        text: 'Message: ',
                        style: 'subTitle'
                      },
                      {
                        text: request.decision.message,
                        style: 'normalText'
                      },
                    ],
                    colSpan: 2
                  },
                  {},
                  {
                    stack: [
                      {
                        text: 'Completed On: ',
                        style: 'subTitle'
                      },
                      {
                        text: request.completedOn.toDate().toDateString(),
                        style: 'normalText'
                      },
                    ],
                  },
                ],
              ]
            }
          } : '',

        request.status === this.status[3] && request.feedback ?
          {
            style: 'table',
            table: {
              widths: ['*', '*', '*'],
              body: [
                [
                  {
                    text: 'Feedback ',
                    style: 'subTitle',
                    colSpan: 3
                  },
                  {},
                  {}
                ],
                [
                  {
                    stack: [
                      {
                        text: 'Rating: ',
                        style: 'subTitle'
                      },
                      {
                        text: request.feedback.rating + " " + "/ 5",
                        style: 'normalText'
                      },
                    ],

                  },
                  {
                    stack: [
                      {
                        text: 'Review: ',
                        style: 'subTitle'
                      },
                      {
                        text: request.feedback.review,
                        style: 'normalText'
                      },
                    ],
                    colSpan: 2
                  },
                  {},
                ],
              ]
            }
          } : '',
      ],
      styles: {
        mainTitle: {
          bold: true,
          fontSize: 16,
          alignment: 'left',
          margin: [0, 0, 0, 20]
        },
        title: {
          bold: true,
          fontSize: 14,
          alignment: 'center',
          margin: [0, 10, 0, 10]
        },
        subTitle: {
          bold: true,
          fontSize: 12,
          alignment: 'left',
          margin: [0, 10, 0, 10]
        },
        normalText: {
          fontSize: 11,
        },
        header: {
          fontSize: 12,
          bold: true,
          alignment: 'center',
        },
        links: {
          color: 'blue',
          decoration: 'underline',
          margin: [0, 5, 0, 5]
        },
        table: {
          margin: [0, 5, 0, 5]
        },
      }
    };
  }

  async openPdf(status, index) {
    const documentDefinition = await this.getDocumentDefinition(status, index);
    pdfMake.createPdf(documentDefinition).open();
  }

  async downloadPdf(status, index) {
    const documentDefinition = await this.getDocumentDefinition(status, index);
    pdfMake.createPdf(documentDefinition).download();
  }

  async printPdf(status, index) {
    const documentDefinition = await this.getDocumentDefinition(status, index);
    pdfMake.createPdf(documentDefinition).print();
  }

}
