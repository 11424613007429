<div class="col s12 ">
  <div class="body-h">

    <div class="header-setting b-m-2">
      <div class="col l9 m9 s12">
        <h4 *ngIf="!editName">
          <span *ngIf="sectionName">{{sectionName}}</span>
          <span *ngIf="!sectionName">Departments</span>
          <i class="material-icons edit-name " [ngStyle]="{'color': themeColor}" (click)="editNameClicked()">edit</i>
        </h4>
        <div class="input-field" *ngIf="editName">
          <input type="text" [(ngModel)]="sectionName" class="left col s6 validate" autocomplete="off" />
          <button type="button" class="btn ll-m-5" [ngStyle]="{'background-color': themeColor}"
            (click)="saveName()">Save</button>
        </div>

        <p class="col s12 bold-300 sub-title-section3">This is where you can manage the departments in your nation</p>
      </div>

      <div class="col l3 m4 s12 p-m-2 " [ngClass]="{'disabledDiv': !getPermission('AddDepartment')}">
        <a [routerLink]="['/admin/dashboard-sub-menu/add-department']" class="add-button btn white center-align p-l-2"
          [ngStyle]="{'color': themeColor}"><i class="material-icons-outlined">add</i> Add&nbsp; 
          <span *ngIf="sectionName">{{sectionName}}</span>
          <span *ngIf="!sectionName">Departments</span> </a>
      </div>
    </div>

    <!-- <main class="StickyContent white "  [sortablejs]="model" [sortablejsOptions]="options"> -->
    <main class="departments-list StickyContent  row " style="padding-top:0%;">



      <div cdkDropList class="col s12 l12" (cdkDropListDropped)="drop($event)">
        <div class="col s12 m5 l3" cdkDrag *ngFor="let department of model; let i=index hoverable"
          (click)="editDepartmentData($event, department.id)"
          [ngClass]="{'disabledDiv': !getPermission(department.shortName)}">

          <div class="card white b-n-1 " style="max-height:250px; min-height:250px;"
            [ngStyle]="{'border-color': themeColor} ">
            <div class="card-info">
              <span>
                <div class="row" style=" margin: 0px; padding: 0px; text-align: right;">


                  <div class="col s10">
                    <div class="responsive-img image-data" *ngIf="department.logo">
                      <img [src]="department.logo" class="circle responsive-img">
                    </div>

                    <div class="responsive-img image-none" *ngIf="!department.logo">
                      <div class="avatar-circle hide-on-med-and-up">
                        <span class="initials">{{getInitials(department.name)}}</span>
                      </div>
                      <div class="avatar-circle2 hide-on-small-only ">
                        <span class="initials2">{{getInitials(department.name)}}</span>
                      </div>
                    </div>
                  </div>

                  <span class="material-symbols-outlined col s2 drag-handle" cdkDragHandle
                    style="color:gray;  font-size: 20px;">
                    open_with
                  </span>

                </div>

              </span>

              <span class="title-section3 bold-600 p-m-0" [innerHTML]="department.name"></span>
              <div class="description-section middle-body-snippet bold-100 p-m-0">
                <p [innerHTML]="department.mandate"></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  </div>
</div>