import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';
import { DepartmentModel } from 'src/app/models/DepartmentModel';
import { DepartmentService } from 'src/app/services/firebase/department.service';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { toast } from 'materialize-css';
import { Router } from '@angular/router';
import { MediaService } from 'src/app/services/api/media.service';
import { ImageModel } from 'src/app/models/ImageModel';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { DataService } from 'src/app/services/data.service';
import { createMask } from '@ngneat/input-mask';

declare var $: any;
declare var M: any;
declare var navigator: any;
declare var window: any;
declare var Camera: any;

@Component({
  selector: 'app-add-edit-department',
  templateUrl: './add-edit-department.component.html',
  styleUrls: ['./add-edit-department.component.scss']
})
export class AddEditDepartmentComponent implements OnInit {

  themeColor = environment.appTheme.adminThemeColor;
  model: DepartmentModel;
  // tslint:disable-next-line: variable-name
  edit_model: DepartmentModel;
  addUpdateDepartmentForm: FormGroup;
  postalCode = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
  mobilePattern = /^\s*([\(])[[0-9]{2}\d{1}[\)]\s*[\-]?[\.]?\s*\d{3}\s*[\-]?[\.]?\s*\d{4}$/;
  
  //public postalCodeMask = [/[A-Za-z]/, /[0-9]/, /[A-Za-z]/, ' ', /[0-9]/, /[A-Za-z]/, /[0-9]/];
  // tslint:disable-next-line: max-line-length
  //public phoneNumberMask = ['(', /[0-9]/, /[0-9]/, /[0-9]/, ')', ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  phoneInputMask = createMask<string>( {
    mask: '(999) 999-9999',
    guide: true,
    showMaskTyped: true,
  });

  postalCodeMask = createMask<string>({
    mask: 'A9A 9A9' // Replace {1} with a space if desired
  });

  fromWhere: any;
  departmentId: any;
  adminThemeColor = environment.appTheme.themeColor;
  imgErrorMsg: any;
  base64Image: any;
  binDoc: File;
  showLogoInput = true;
  showImageFromServer = false;
  oldUrl: any;
  buttonType: any;
  defaultThemeColor = environment.appTheme.themeColor;

  mobileBase64Image: any;
  isMobile = false;

  selectedColor = 'black-text $';
  colorPicker: any[];
  imgLink = 'assets/img/profile.jpg';
  applicationsList: any = [];
  sectionName: string;

  constructor(
    private location: Location,
    public appSettingsService: AppSettingsService,
    private departmentService: DepartmentService,
    private formBuilder: FormBuilder,
    private router: Router,
    private mediaService: MediaService,
    private dataService: DataService
  ) {
    if (typeof window['cordova'] !== 'undefined') {
      this.isMobile = true;
    }
    this.colorPicker = this.dataService.getColors();
    this.themeColor = this.appSettingsService.getAppThemeColor() ? this.appSettingsService.getAppThemeColor() : this.defaultThemeColor;
    this.buttonType = this.appSettingsService.getButtonType() ? this.appSettingsService.getButtonType() : 'rectangularButton';
  }

  ngOnInit() {
    this.appSettingsService.getMenuName('MyNation').valueChanges().subscribe(menus => {
      if (menus && menus.length > 0) {
        let submenus = menus[0].submenus;
        let menu = submenus.filter(m => m.name == 'Departments');
        if (menu && menu.length > 0) this.sectionName = menu[0].displayName;
      }
    });
    this.addUpdateDepartmentForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      shortName: [''],
      postal_code: ['', [Validators.pattern(this.postalCode)]],
      phone: ['', [Validators.pattern(this.mobilePattern)]],
      fax: ['', [Validators.pattern(this.mobilePattern)]],
      email: ['', [Validators.email, Validators.required]],
      address: [''],
      mandate: [''],
      logo: [],
      contactName: [''],
      contactOfficePhone: ['', [Validators.pattern(this.mobilePattern)]],
      contactEmail: ['', [Validators.email]],
      contactMobile: ['', [Validators.pattern(this.mobilePattern)]],
      contactTitle: [''],
      backgroundColor: ['#ffffff'],
      deptFacebookAcc: [''],
      deptInstagramAcc: [''],
      deptTwitterAcc: [''],
      deptYoutubeAcc: [''],
      deptLinkedinAcc: [''],
      applications: []
    });

    const path = this.router.url.split('/');
    if (path[path.length - 1] === 'add-department') {
      this.fromWhere = 'add';
    } else if (path[path.length - 1] === 'manage-department') {
      this.fromWhere = 'edit';
      this.departmentId = window.history.state.department_id;
      if (this.departmentId) {
        this.getDepartmentFormData(this.departmentId);
      } else {
        this.router.navigate(['/admin/dashboard-sub-menu/department']);
      }
    }

    $('.chip').chips();
    setTimeout(() => {
      M.updateTextFields();
      $('.dropdown-trigger').dropdown();

    }, 25);

    $(document).ready(() => {
      $('.modal').modal();
    });

    $('.attachment input[type="file"]').on("change", (event) => {
      let el = $(event.target).closest(".attachment").find(".btn-file");

      el.find(".btn-file__actions__item").css({
        padding: "135px"
      });

      el.find(".btn-file__preview").css({
        "background-image":
          "url(" + window.URL.createObjectURL(event.target.files[0]) + ")"
      });
    });

  }

  addApplications() {
    if (this.applications.value) {
      if (this.applications.value.length > 0 && typeof (this.applications.value) !== 'string') {
        this.applicationsList = this.applications.value;
        this.applications.patchValue('');
      }
      if (this.applicationsList.includes(this.applications.value)) {
        this.applications.patchValue('');
      } else {
        this.applicationsList.push(this.applications.value);
        this.applications.patchValue('');
      }
    }
  }

  removeApplication(value) {
    this.applicationsList = this.applicationsList.filter((val) => val !== value);
  }

  ChangeColor(color: string) {
    this.selectedColor = color;
  }

  // Getters For Reactive Form Validation
  get name() { return this.addUpdateDepartmentForm.get('name'); }
  get shortName() { return this.addUpdateDepartmentForm.get('shortName'); }
  get postal_code() { return this.addUpdateDepartmentForm.get('postal_code'); }
  get phone() { return this.addUpdateDepartmentForm.get('phone'); }
  get fax() { return this.addUpdateDepartmentForm.get('fax'); }
  get address() { return this.addUpdateDepartmentForm.get('address'); }
  get mandate() { return this.addUpdateDepartmentForm.get('mandate'); }
  get email() { return this.addUpdateDepartmentForm.get('email'); }
  get logo() { return this.addUpdateDepartmentForm.get('logo'); }
  get contactEmail() { return this.addUpdateDepartmentForm.get('contactEmail'); }
  get contactName() { return this.addUpdateDepartmentForm.get('contactName'); }
  get contactOfficePhone() { return this.addUpdateDepartmentForm.get('contactOfficePhone'); }
  get contactMobile() { return this.addUpdateDepartmentForm.get('contactMobile'); }
  get contactTitle() { return this.addUpdateDepartmentForm.get('contactTitle'); }
  get getdeptFacebookAcc() { return this.addUpdateDepartmentForm.get('deptFacebookAcc'); }
  get getdeptInstagramAcc() { return this.addUpdateDepartmentForm.get('deptInstagramAcc'); }
  get getdeptTwitterAcc() { return this.addUpdateDepartmentForm.get('deptTwitterAcc'); }
  get getdeptYoutubeAcc() { return this.addUpdateDepartmentForm.get('deptYoutubeAcc'); }
  get getdeptLinkedinAcc() { return this.addUpdateDepartmentForm.get('deptLinkedinAcc'); }
  get applications() { return this.addUpdateDepartmentForm.get('applications'); }

  // -------------------------------------------------------------------

  PostDepartment(value: any) {
    if (this.fromWhere === 'add') {
      this.model = new DepartmentModel();
    } else {
      this.model = this.edit_model;
    }
    this.model.parent_id = '0';
    this.model.name = '<span class="' + this.selectedColor + '"><strong>' + value.value.name + '</strong></span>';
    this.model.shortName = value.value.shortName;
    this.model.phone = value.value.phone;
    this.model.fax = value.value.fax;
    this.model.email = value.value.email;
    this.model.postal_code = value.value.postal_code;
    this.model.address = value.value.address;
    this.model.mandate = value.value.mandate;
    this.model.logo = value.value.logo;
    this.model.backgroundColor = value.value.backgroundColor;
    this.model.deptFacebookAcc = value.value.deptFacebookAcc;
    this.model.deptInstagramAcc = value.value.deptInstagramAcc;
    this.model.deptTwitterAcc = value.value.deptTwitterAcc;
    this.model.deptYoutubeAcc = value.value.deptYoutubeAcc;
    this.model.deptLinkedinAcc = value.value.deptLinkedinAcc;
    this.model.applications = this.applicationsList;

    if (this.binDoc && this.oldUrl) {
      if (this.isMobile) {
        const img = new ImageModel();
        img.imgBase64 = this.mobileBase64Image;

        this.mediaService.uploadImage(img).subscribe(upload => {
          if (upload) {
            this.model.logo = upload.imgLink;
            if (this.fromWhere === 'add') {
              this.departmentService.addDepartment(this.model).then(x => {
                toast({ html: 'New Department Successfully Added!', classes: 'green' });
                this.location.back();
              });
            } else {
              this.departmentService.UpdateDepartment(this.model).then(x => {
                toast({ html: 'Department Successfully Updated!', classes: 'green' });
              });
            }
            this.backClicked();
            return;
          }
        })
      }
      else {
        this.mediaService.UploadBinImage(this.binDoc).subscribe(
          upload => {
            if (upload) {
              this.model.logo = upload.imgLink;
              if (this.fromWhere === 'add') {
                this.departmentService.addDepartment(this.model).then(x => {
                  toast({ html: 'New Department Successfully Added!', classes: 'green' });
                  this.location.back();
                });
              } else {
                this.departmentService.UpdateDepartment(this.model).then(x => {
                  toast({ html: 'Department Successfully Updated!', classes: 'green' });
                });
              }
              this.backClicked();
              return;
            }
          }
        );
      }

    } else if (this.binDoc && !this.oldUrl) {

      if (this.isMobile) {
        const img = new ImageModel();
        img.imgBase64 = this.mobileBase64Image;

        this.mediaService.uploadImage(img).subscribe(upload => {
          if (upload) {
            this.model.logo = upload.imgLink;
            if (this.fromWhere === 'add') {
              this.departmentService.addDepartment(this.model).then(x => {
                toast({ html: 'New Department Successfully Added!', classes: 'green' });
                this.location.back();
              });
            } else {
              this.departmentService.UpdateDepartment(this.model).then(x => {
                toast({ html: 'Department Successfully Updated!', classes: 'green' });
              });
            }
            this.backClicked();
            return;
          }
        })
      }
      else {
        this.mediaService.UploadBinImage(this.binDoc).subscribe(
          upload => {
            if (upload) {
              this.model.logo = upload.imgLink;
              if (this.fromWhere === 'add') {
                this.departmentService.addDepartment(this.model).then(x => {
                  toast({ html: 'New Department Successfully Added!', classes: 'green' });
                  this.location.back();
                });
              } else {
                this.departmentService.UpdateDepartment(this.model).then(x => {
                  toast({ html: 'Department Successfully Updated!', classes: 'green' });
                });
              }
              this.backClicked();
              return;
            }
          }
        );
      }

    } else if (!this.binDoc && this.oldUrl) {
      this.model.logo = this.oldUrl;
      if (this.fromWhere === 'add') {
        this.departmentService.addDepartment(this.model).then(x => {
          toast({ html: 'New Department Successfully Added!', classes: 'green' });
          this.backClicked();
        });
      } else {
        this.departmentService.UpdateDepartment(this.model).then(x => {
          toast({ html: 'Department Successfully Updated!', classes: 'green' });
          this.backClicked();
        });
      }

    } else if (!this.binDoc && !this.oldUrl) {
      if (!this.model.logo) { this.model.logo = ''; }

      if (this.fromWhere === 'add') {
        this.departmentService.addDepartment(this.model).then(x => {
          toast({ html: 'New Department Successfully Added!', classes: 'green' });
          this.backClicked();
        });
      } else {
        this.departmentService.UpdateDepartment(this.model).then(x => {
          toast({ html: 'Department Successfully Updated!', classes: 'green' });
          this.backClicked();
        });
      }
    }
  }

  getDepartmentFormData(departmentId: any) {
    this.departmentService.getDepartmentByID(departmentId).valueChanges().subscribe(x => {
      if (x) {
        this.edit_model = x;
        if (!x.contactName) { x.contactName = ''; }
        if (!x.contactEmail) { x.contactEmail = ''; }
        if (!x.contactMobile) { x.contactMobile = ''; }
        if (!x.contactOfficePhone) { x.contactOfficePhone = ''; }
        if (!x.contactTitle) { x.contactTitle = ''; }
        if (!x.backgroundColor) { x.backgroundColor = '#ffffff'; }
        this.selectedColor = this.edit_model.name.substring(
          this.edit_model.name.lastIndexOf('=') + 2,
          this.edit_model.name.lastIndexOf('$') - 1,
        );
        this.edit_model.name = this.edit_model.name.replace(/<[^>]*>/g, '');

        this.addUpdateDepartmentForm.setValue({
          name: this.edit_model.name,
          shortName: this.edit_model.shortName ? this.edit_model.shortName : '',
          postal_code: this.edit_model.postal_code,
          phone: this.edit_model.phone,
          fax: this.edit_model.fax,
          email: (x.email) ? x.email : '',
          address: this.edit_model.address,
          mandate: this.edit_model.mandate,
          logo: '',
          contactEmail: (this.edit_model.contactEmail) ? this.edit_model.contactEmail : '',
          contactName: (this.edit_model.contactName) ? this.edit_model.contactName : '',
          contactOfficePhone: (this.edit_model.contactOfficePhone) ? this.edit_model.contactOfficePhone : '',
          contactMobile: (this.edit_model.contactMobile) ? this.edit_model.contactMobile : '',
          contactTitle: (this.edit_model.contactTitle) ? this.edit_model.contactTitle : '',
          backgroundColor: (this.edit_model.backgroundColor) ? this.edit_model.backgroundColor : '#ffffff',
          deptFacebookAcc: (this.edit_model.deptFacebookAcc) ? this.edit_model.deptFacebookAcc : '',
          deptInstagramAcc: (this.edit_model.deptInstagramAcc) ? this.edit_model.deptInstagramAcc : '',
          deptTwitterAcc: (this.edit_model.deptTwitterAcc) ? this.edit_model.deptTwitterAcc : '',
          deptLinkedinAcc: (this.edit_model.deptLinkedinAcc) ? this.edit_model.deptLinkedinAcc : '',
          deptYoutubeAcc: (this.edit_model.deptYoutubeAcc) ? this.edit_model.deptYoutubeAcc : '',
          applications: (this.edit_model.applications) ? this.edit_model.applications : []
        });

        this.model = this.edit_model;

        if (this.edit_model.logo) {
          this.showImageFromServer = true;
          this.oldUrl = this.model.logo;
        }
        setTimeout(() => {
          M.updateTextFields();
          $('.dropdown-trigger').dropdown();
        }, 25);
      }
    });
  }

  DeleteDepartment(value: any) {
    $('#deleteDepartmentConfirmation').modal('close');
    this.departmentService.DeleteDepartment(value).then(x => {
      toast({ html: 'Department Successfully Deleted!', classes: 'green' });
      this.router.navigate(['/admin/dashboard-sub-menu/department']);
    });
  }

  backClicked() {
    this.location.back();
  }

  openDeleteModal() {
    $('#deleteDepartmentConfirmation').modal('open');
  }

  handleImageInput(files: FileList) {
    if (!this.validateImage(files[0].name)) {
      this.imgErrorMsg = 'Invalid File Type, Please Select an Image File';
      this.logo.patchValue('');
      return;
    }
    this.binDoc = files[0];
    this.imgErrorMsg = undefined;
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.base64Image = reader.result as string;
    };
  }

  handleMobilePhotoInput() {
    navigator.camera.getPicture((image: any) => {
      const imageName = 'MyNation.jpeg';
      const imageBlob = this.dataURItoBlob(image);
      const imageFile = new File([imageBlob], imageName, { type: 'image/jpeg' });
      this.binDoc = imageFile;
      this.base64Image = 'data:image/jpeg;base64,' + image;
      this.mobileBase64Image = image
    }, (error) => {
      alert(error);
    },
      {
        quality: 50,
        destinationType: Camera.DestinationType.DATA_URL,
        encodingType: navigator.camera.EncodingType.JPEG,
        mediaType: navigator.camera.MediaType.PICTURE,
        sourceType: navigator.camera.PictureSourceType.PHOTOLIBRARY,
      }
    )
  }

  dataURItoBlob(dataURI, contentType = '') {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: contentType });
    return blob;
  }

  removeFile() {
    this.binDoc = undefined;
    this.base64Image = undefined;
    this.oldUrl = undefined;
    if (this.edit_model?.logo) this.edit_model.logo = null;
    this.logo.patchValue('');
  }

  removeSavedFile() {
    this.binDoc = undefined;
    this.base64Image = undefined;
    this.oldUrl = this.model.logo;
    this.model.logo = this.model.logo;
    this.logo.patchValue('');
    this.showImageFromServer = false;
    this.showLogoInput = true;
  }

  private validateImage(name: any) {
    const ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() === 'png') {
      return true;
    } else if (ext.toLowerCase() === 'jpg') {
      return true;
    } else if (ext.toLowerCase() === 'jpeg') {
      return true;
    } 
    else if (ext.toLowerCase() === 'svg') {
      return true;
    } 
    else if (ext.toLowerCase() === 'webp') {
      return true;
    } else {
      return false;
    }
  }

}