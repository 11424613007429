<div class="content-left ">
    <div class=" card-yes ">
        <div class=" border-content">
            <div class="row col s12">
                <div class="col l1 "></div>
                <div class="col s12 l10">
                    <div class="row">

                        <div class="col s6 m4 l3" *ngFor="let department of model">
                            <!-- (click)="editSubDepartmentData($event, department.id)" 
                            [routerLink]="['/department-sub-menu', departmentID,'view-subdepartment-info', department.id]"
                        -->
                            <a class="">
                                <!-- hoverable -->
                                <div class="card-panel white darken-3" [@swing]="swing" style="height: 95px;">
                                    <div class="row center">
                                        <h4 class="title-section3 bold-600 p-m-1 black-text" style="margin-top: 12px; font-size: 15px;">
                                            {{ department.name }}
                                        </h4>

                                    </div>

                                </div>
                            </a>
                        </div>

                    </div>
                </div>

                <!-- <a class="col s10 push-s1 btn" [ngClass]="'btn ' + buttonType"
                    [ngStyle]="{'background-color': themeColor}" (click)="backClicked()"><i
                        class="material-icons left">arrow_back_ios</i> Back To Previous Page</a> -->

            </div>
        </div>
    </div>
</div>