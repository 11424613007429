import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';
import { DepartmentModel } from 'src/app/models/DepartmentModel';
import { DepartmentService } from 'src/app/services/firebase/department.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-view-department-info',
  templateUrl: './view-department-info.component.html',
  styleUrls: ['./view-department-info.component.scss']
})

export class ViewDepartmentInfoComponent implements OnInit {

  model: DepartmentModel;
  fromWhere: any;
  departmentId: any;
  themeColor = environment.appTheme.adminThemeColor;
  nationOfUSe = environment.firstNation.name;
  constructor(
    private location: Location,
    private departmentService: DepartmentService,
    private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.departmentId = params.get('id');
    });
    if (this.departmentId) {
      this.getDepartmentFormData(this.departmentId);
    }
  }

  getDepartmentFormData(departmentId: any) {
    this.departmentService.getDepartmentByID(departmentId).valueChanges().subscribe(x => {
      if (x) {
        this.model = x;
      }
    });
  }

  backClicked() {
    this.location.back();
  }
}
