import { VoteDataModel } from './../../models/VoteDataModel';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class VotingService {
  constructor(private db: AngularFirestore) { }


  createVote(model: VoteDataModel) {
      const newContent = {
        id: this.db.createId(),
        voterId: model.voterId,
        candidateId: model.candidateId,
        electionTime: model.electionTime,
        electionId: model.electionId,
        showVoting: model.showVoting
      };
      return this.db.collection('Votes').doc(newContent.id).set(newContent);
  }
  updateVote(model: VoteDataModel) {
    const newContent = {
      id: model.id,
      voterId: model.voterId,
      candidateId: model.candidateId,
      electionTime: model.electionTime,
      electionId: model.electionId,
      showVoting: model.showVoting
    };
    return this.db.collection('Votes').doc(newContent.id).update(newContent);
}

  getMyVoteByElectionId(id: string, userId: string) {
    return this.db.collection('Votes', ref => ref.where('electionId', '==', id).where('voterId', '==', userId));
  }
  getVoteByElectionId(id: string) {
    return this.db.collection('Votes', ref => ref.where('electionId', '==', id));
  }
  getVotebyId(id: string) {
    return this.db.doc('Votes/' + id);
  }

  getVotesByCandidateId(candidateId: string) {
    return this.db.collection('Votes', ref => ref.where('candidateId', '==', candidateId));
  }

  getVotes(electionId: string, candidateId: string) {
    return this.db.collection('Votes', ref => ref.where('electionId', '==', electionId).where('candidateId', '==', candidateId));
  }
  deleteVoteById(voteId: string) {
    return this.db.collection('Votes').doc(voteId).delete();
  }


}
