import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Options } from '@angular-slider/ngx-slider';
import { ActivatedRoute } from '@angular/router';
import { ISApplicationService } from 'src/app/services/api/ISApplication.service';
import { MemberService } from 'src/app/services/api/member.service';
import { ApplicationService } from 'src/app/services/firebase/application.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { Location } from '@angular/common';

const pdfMake = require('pdfmake/build/pdfmake.js');
const pdfFonts = require('pdfmake-unicode/dist/pdfmake-unicode.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-preview-applications',
  templateUrl: './preview-applications.component.html',
  styleUrls: ['./preview-applications.component.css']
})
export class PreviewApplicationsComponent implements OnInit {

  maritalStatus = ["Single", "Married", "Common Law", "Separated", "Divorced"];
  EduLevelList = ["Preschool", "Up to Grade 7-8 (Sec. I = Grade 8)", "Grade 9-10 (Sec. II-III)", "Grade 11-12 (Sec. IV-V)",
    "Secondary School Diploma or GED", "Some post-secondary training", "Apprenticeship or trades certificate or diploma",
    "College", "CEGEP", "University certificate or diploma", "University - Bachelor Degree", "University – Master’s degree", "University – Doctorate", "Course or certificate", "Other"];

  applicationName: any;
  loading = false;
  memberId: any;
  member: any;
  application: any;
  form: UntypedFormGroup;
  step = 1;
  model: any;
  data: any;
  options: Options = {
    floor: 1,
    ceil: 10,
    showTicks: true,
    step: 1,
  }
  family_comp: any;
  familyMembers: any[] = [];
  assets: any[];
  incomes: any[];
  memberData = false;
  assetData = false;
  incomeData = false;

  constructor(private route: ActivatedRoute,
    private authService: AuthService,
    private applicationService: ApplicationService,
    private memberService: MemberService,
    private ISAppService: ISApplicationService,
    private fb: UntypedFormBuilder,
    private location: Location) { }

  ngOnInit(): void {
    this.loading = true;
    this.memberId = this.authService.getCurrentApplicantID();
    this.route.paramMap.subscribe(params => {
      this.applicationName = params.get('name');
      if (this.applicationName) {
        this.applicationService.getApplication(this.applicationName).valueChanges().subscribe(application => {
          if (application) {
            this.application = application[0];
            
            this.form = this.fb.group({});
            this.application.sections.forEach(section => {

              this.form.addControl(section.title, new UntypedFormGroup({}));
              let sectionGroup = this.form.get(`${section.title}`) as UntypedFormGroup;

              if (section.data && section.data != '') {
                this.memberData = true;
                this.loading = true;
               
                this.memberService.getMember(2655).subscribe(x => {
                  if (x && x.length > 0) {
                    this.member = x[0];
                  
                    if (section.data == 'Members') {
                      section.fields.forEach(field => {
                       
                        if (field.label == 'First Name') sectionGroup.addControl(field.label, new UntypedFormControl(this.member.GivenName));
                        else if (field.label == 'Last Name') sectionGroup.addControl(field.label, new UntypedFormControl(this.member.LastName));
                        else if (field.label == 'Middle Name') sectionGroup.addControl(field.label, new UntypedFormControl(this.member.LastName));
                        else if (field.label == 'Date of Birth') {
                          var array = this.member.DOB.split("-");
                          var dob = `${array[2].substring(0, 2)}/${array[1]}/${array[0]}`;
                          sectionGroup.addControl(field.label, new UntypedFormControl(dob));
                          // var maritalStatus = this.maritalStatus[Number(this.member.MaritalStatus)];
                        } else if (field.label == 'Marital Status') {
                          var maritalStatus = this.maritalStatus[Number(this.member.MaritalStatus)];
                          sectionGroup.addControl(field.label, new UntypedFormControl(maritalStatus));
                        } else if (field.label == 'SIN') sectionGroup.addControl(field.label, new UntypedFormControl(this.member.SIN));
                        else if (field.label == 'ISN') sectionGroup.addControl(field.label, new UntypedFormControl(this.member.IndianStatusNo));
                        else if (field.label == 'Band Name') sectionGroup.addControl(field.label, new UntypedFormControl(this.member.Band));
                        else if (field.label == 'Province') sectionGroup.addControl(field.label, new UntypedFormControl(this.member.province));
                        else if (field.label == 'Education Level') {
                          var eduLevel = this.EduLevelList[Number(this.member.edu_level)];
                          sectionGroup.addControl(field.label, new UntypedFormControl(eduLevel));
                        } else if (field.label == 'Health Care Number') sectionGroup.addControl(field.label, new UntypedFormControl(this.member.HealthCareNo));
                        else if (field.label == 'Province') sectionGroup.addControl(field.label, new UntypedFormControl(this.member.province));
                        else sectionGroup.addControl(field.label, new UntypedFormControl(''));
                        setTimeout(() => {
                          M.updateTextFields();
                          $('select').formSelect();
                        }, 25);
                      });
                      setTimeout(() => {
                        M.updateTextFields();
                        $('select').formSelect();
                      }, 25);
                      section.fields.forEach(field => {
                        sectionGroup.addControl(field.label, new UntypedFormControl(''));
                      });
                    } else if (section.data == 'Assets') {
                      const currentApp = this.member.current_application;
                      this.assetData = true;
                      this.loading = true;
                      this.ISAppService.getAssets(currentApp).subscribe((assets) => {
                        if (assets && assets.length > 0) {
                          this.assets = assets;
                        
                          section.fields.forEach(field => {
                            if (field.label == 'Asset') sectionGroup.addControl(field.label, new UntypedFormControl("")); //asset.type
                            if (field.label == 'Amount') sectionGroup.addControl(field.label, new UntypedFormControl('')); //asset.value
                            if (section.multipleEntry) sectionGroup.addControl('values', new UntypedFormArray([]));
                          });
                          this.assets.forEach(asset => {
                            if (section.multipleEntry) {
                              let values = sectionGroup.get('values') as UntypedFormArray;
                              let group = this.fb.group({
                                Asset: asset.type ? asset.type : "Null",
                                Amount: asset.value ? asset.value : '0'
                              });
                              values.push(group);
                            }
                          });
                        }
                        this.loading = false;

                        setTimeout(() => {
                          M.updateTextFields();
                          $('select').formSelect();
                        }, 25);
                      });
                      setTimeout(() => {
                        M.updateTextFields();
                        $('select').formSelect();
                      }, 25);

                    } else if (section.data == 'Incomes') {
                      const currentApp = this.member.current_application;
                      this.incomeData = true;
                      this.loading = true;
                     
                      this.ISAppService.getIncomes(currentApp).subscribe((incomes) => {
                        if (incomes && incomes.length > 0) {
                          this.incomes = incomes;
                        
                          section.fields.forEach(field => {
                            if (field.label == 'Source') sectionGroup.addControl(field.label, new UntypedFormControl('')); //asset.type
                            if (field.label == 'Amount') sectionGroup.addControl(field.label, new UntypedFormControl()); //asset.value
                            if (section.multipleEntry) sectionGroup.addControl('values', new UntypedFormArray([]));
                          });

                          this.incomes.forEach(income => {
                            if (section.multipleEntry) {
                              let values = sectionGroup.get('values') as UntypedFormArray;
                              let group = this.fb.group({
                                Source: income.type ? income.type : "Null",
                                Amount: income.value ? income.value : '0'
                              });
                              values.push(group);
                            }
                          });
                        }
                        this.loading = false;
                        setTimeout(() => {
                          M.updateTextFields();
                          $('select').formSelect();
                        }, 25);
                        
                      });
                    }
                    setTimeout(() => {
                      M.updateTextFields();
                      $('select').formSelect();
                    }, 25);
                  }
                  this.loading = false;
                  
                });
              } else {
                section.fields.forEach(field => {
                  sectionGroup.addControl(field.label, new UntypedFormControl(''));
                });
                if (section.multipleEntry) sectionGroup.addControl('values', new UntypedFormArray([]));
                setTimeout(() => {
                  M.updateTextFields();
                  $('select').formSelect();
                }, 25);
              }

            });
            setTimeout(() => {
              M.updateTextFields();
              $('select').formSelect();
            }, 25);
          }
        });
      }
      this.loading = false;
    });

  }

  sectionsData(sectionTitle) {
    return this.form.get(`${sectionTitle}`) as UntypedFormGroup;
  }

  getValues(sectionTitle) {
    const formGroup = this.form.get(`${sectionTitle}`) as UntypedFormGroup;
    return formGroup.get('values') as UntypedFormArray;
  }

  getValue(index, sectionTitle, fieldLabel) {
    const section = this.form.get(`${sectionTitle}`) as UntypedFormGroup;
    const valuesFA = section.get('values') as UntypedFormArray;

    const values = valuesFA.controls[index].get(`${fieldLabel}`).value;
    return values;
  }

  addEntry(index) {
    const section = this.application.sections[index];
    let sectionFG = this.form.get(`${section.title}`) as UntypedFormGroup;
    let values = sectionFG.get('values') as UntypedFormArray;
    let group = this.fb.group({});
    section.fields.forEach(field => {
      let value = sectionFG.get(`${field.label}`).value
      group.addControl(field.label, new UntypedFormControl(value));
    });

    values.push(group);
  }

  getScaleOption(scales) {
    const start = Number(scales.start);
    const end = Number(scales.end);
    const startLabel = scales.startLabel;
    const endLabel = scales.endLabel;
    let options: Options = {
      floor: start,
      ceil: end,
      showTicks: true,
      showTicksValues: true,
      step: 1,
      getLegend: (value: number): string => {
        if (value == start) return startLabel;
        else if (value == end) return endLabel
        // else return value.toString();
      },
    }
    return options;
  }

  onNext() {
    this.step += 1;
    setTimeout(() => {
      M.updateTextFields();
      $('select').formSelect();
    }, 25);
  }

  onBack() {
    this.step -= 1;
    setTimeout(() => {
      M.updateTextFields();
      $('select').formSelect();
    }, 25);
  }

  previewApplication(values: any) {
    this.model = values;
    this.onNext();
    setTimeout(() => {
      $('.dropdown-trigger').dropdown();
      $('.dropdown-trigger').dropdown({ constrainWidth: false });
    });

  }

  submitApplication() {
    this.onNext();
  }

  back() {
    this.location.back();
  }

  getReport() {
    let report = {
      info: {
        title: this.application.name,
        subject: this.application.name,
        keywords: this.application.name,
      },
      pageSize: 'LETTER',
      content: this.getContent(),
      styles: this.getStyles(),
    }
    return report;
  }

  getContent() {
    let content = [];
    content.push(
      {
        style: 'table',
        layout: 'noBorders',
        table: {
          widths: ['*', '*'],
          body: [
            [
              {
                columns: [
                  [
                    //this.getLogo()
                  ],
                  [
                    {
                      stack: [
                        {
                          text: 'MY NATION ',
                          style: 'mainTitle',
                          alignment: 'center',
                        },
                        {
                          text: 'Connecting Your Nation',
                          style: 'normalText',
                          alignment: 'center',
                        },
                        {
                          text: 'Sovereign Data For Sovereign Nations ',
                          fontSize: 8,
                          italics: true,
                          alignment: 'center',
                        },
                      ]
                    },
                  ]
                ],
              },
              {
                stack: [
                  {
                    text: this.application.department,
                    style: 'title'
                  },
                  {
                    text: this.application.name,
                    style: 'title'
                  },
                  {},
                ]
              },
            ],
          ]
        }
      },
      this.getSectionData(),

    );
    return content;
  }

  getSectionData() {
    const sectionData = [];
    this.application.sections.forEach(section => {
      sectionData.push(
        {
          style: 'table',
          table: {
            widths: ['*', '*'],
            body: this.getFieldData(section)

          }
        }
      );
    });
    return sectionData;
  }

  getFieldData(section) {
    const fieldData = [];
    fieldData.push([{
      text: section.title,
      style: 'subTitle',
      bold: true,
      colSpan: 2
    }, {}]);

    section.fields.forEach(field => {
      fieldData.push([
        {
          text: field.label,
          style: 'normalText',
          margin: [5, 5, 5, 5],
        },
        {
          text: this.model[section.title][field.label],
          style: 'fields',
          margin: [5, 5, 5, 5],
        }
      ]);
    });

    return fieldData;

  }

  getStyles() {
    return {
      mainTitle: {
        bold: true,
        fontSize: 16,
        alignment: 'left',
        margin: [0, 0, 0, 20]
      },
      title: {
        bold: true,
        fontSize: 14,
        alignment: 'center',
        margin: [0, 10, 0, 10]
      },
      subTitle: {
        //bold: true,
        fontSize: 11,
        alignment: 'left',
        margin: [0, 10, 0, 10]
      },
      normalText: {
        fontSize: 10,
        alignment: 'justify',
      },
      header: {
        fontSize: 12,
        bold: true,
        alignment: 'left',
      },
      links: {
        color: 'blue',
        decoration: 'underline',
        //italics: true,
        margin: [0, 5, 0, 5]
      },
      table: {
        margin: [10, 5, 0, 10]
      },
      fields: {
        color: 'blue',
        fontSize: 10,
      }
    }
  }

  getLogo() {
    if (this.application.logo) {
      return {
        image: this.application.logo,
        width: 100,
        alignment: 'left'
      };
    }
    return null;
  }

  downloadReport() {
    const documentDefinition = this.getReport();
    pdfMake.createPdf(documentDefinition).download();
  }

  openReport() {
    const documentDefinition = this.getReport();
    pdfMake.createPdf(documentDefinition).open();
  }

  printReport() {
    const documentDefinition = this.getReport();
    pdfMake.createPdf(documentDefinition).print();
  }

  sendReport() {
  }

  getDocumentDefinition() {
    return {
      info: {
        title: this.application.name,
        subject: this.application.name,
      },
      content: [
        {
          columns: [
            [
              this.getLogo(),
            ],
            [{
              layout: 'lightHorizontalLines',
              text: this.application.name.toUpperCase(),
              style: 'mainTitle',
            },
            ]
          ]
        },
        this.getSectionData(),
      ],
      styles: {
        mainTitle: {
          bold: true,
          fontSize: 20,
          alignment: 'left',
          margin: [0, 0, 0, 20]
        },
        title: {
          bold: true,
          fontSize: 15,
          alignment: 'left',
          margin: [10, 10, 10, 10],
        },
        header: {
          fontSize: 12,
          bold: true,
          alignment: 'center',
        },
      }
    }
  }
}
