import { Component, Input, OnInit } from '@angular/core';
import { sortedChanges } from '@angular/fire/compat/firestore';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { MessagingService } from 'src/app/services/firebase/messaging.service';
import { ISApplicationService } from 'src/app/services/api/ISApplication.service';
import { PssspApplicationService } from 'src/app/services/firebase/psssp-application.service';
import { UpdateProfileService } from 'src/app/services/firebase/update-profile.service';

@Component({
  selector: 'app-new-notification-badge',
  templateUrl: './new-notification-badge.component.html',
  styleUrls: ['./new-notification-badge.component.css']
})

export class NewNotificationBadgeComponent implements OnInit {

  @Input() content: any;
  count = 0;
  newMessageCount = 0;
  currentUser;
  color = 'black';
  textColor = 'white-text';

  constructor(private authService: AuthService,
    private ISAppService: ISApplicationService,
    private pssspService: PssspApplicationService,
    private updateProfileService: UpdateProfileService,
    private messagingService: MessagingService) { }

  ngOnInit() {
    if (this.content) {
      if (this.content.color) this.color = this.content.color;
      if (this.content.textColor) this.textColor = [this.content.textColor, 'text'].join('-');

      if (this.content.component == 'post-secondary') {
        this.pssspService.getApplications().valueChanges().subscribe(x => {
          if (x) {
            if (Array.isArray(x)) this.count = x.length;
          }
        });
      }

      if (this.content.component == 'income-assistance') {
        this.ISAppService.getApplication(21412).subscribe(y => {
          if (y) {
            if (Array.isArray(y)) this.count = y.length;
          }
        });
      }

      if (this.content.component == 'messaging') {
        // get unread counts
        this.currentUser = this.authService.getCurrentUser();
        if (this.currentUser) {
          let id = this.currentUser.uid;
          //  for group messages
          if (this.currentUser.role = 'member') {
            this.messagingService.getUnreadCountByUserId(id).valueChanges().subscribe(unreadCounts => {
              if (unreadCounts) {
                let unread = unreadCounts;
                unread.forEach(element => {
                  this.newMessageCount += element.unreadCount
                });
                this.count = this.newMessageCount;
                //this.count = 3;
              }
            });
          }
        }
      }

      if (this.content.component == 'profile-updates') {
        this.updateProfileService.getProfileUpdates().valueChanges().subscribe(x => {
          if (x) {
            if (Array.isArray(x)) this.count = x.length
          }
        });
      }

      if (this.content.component == 'membership') {
        this.updateProfileService.getProfileUpdates().valueChanges().subscribe(x => {
          if (x) {
            if (Array.isArray(x)) this.count += x.length
          }
        });
      }
    }
  }
}
