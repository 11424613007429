import { Component, OnInit } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MemberService } from 'src/app/services/api/member.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { DepartmentService } from 'src/app/services/firebase/department.service';
import { environment } from 'src/environments/environment';
import { toast } from 'materialize-css';
import { EmailService } from 'src/app/services/api/email.service';
import { createMask } from '@ngneat/input-mask';

declare var M: any;

function passwordConfirming(c: AbstractControl): any {
  if (!c.parent || !c) { return; }
  const pwd = c.parent.get('createPassword');
  const cpwd = c.parent.get('confirmPassword');

  if (!pwd || !cpwd) { return; }
  if (pwd.value !== cpwd.value) {
    return { invalid: true };
  }
}

@Component({
  selector: 'app-register-department-employee',
  templateUrl: './register-department-employee.component.html',
  styleUrls: ['./register-department-employee.component.css']
})

export class RegisterDepartmentEmployeeComponent implements OnInit {

  themeColor = environment.appTheme.adminThemeColor;
  fontColor = environment.appTheme.adminFontColor;
  invitationID: any;
  invitation: any;
  model: any;
  registrationForm: FormGroup;
  validationForm: FormGroup;
  step0 = false;
  step1 = false;
  step2 = false;
  showMobileNo = 'Public';
  showOfficeNo = 'Public';
  showEmailAdr = 'Public';
  allowContact = 'Public';
  public phoneNumberMask = ['(', /[0-9]/, /[0-9]/, /[0-9]/, ')',
    ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];

  phoneInputMask = createMask<string>({
    mask: '(999) 999-9999',
    guide: true,
    showMaskTyped: true,
  });

  constructor(
    private depService: DepartmentService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private authService: AuthService,
    private bandMemberService: MemberService,
    private emailService: EmailService) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.invitationID = params.get('id');
    });

    if (this.invitationID) {
      this.depService.getInvitationToDepartmentEmployee(this.invitationID).valueChanges().subscribe(
        inv => {
          if (inv) {
            this.invitation = inv;
            this.validationForm = this.fb.group({
            });

            if (this.invitation.validateSin) {
              this.validationForm.addControl('sin', new FormControl('', Validators.required));
            }

            if (this.invitation.validateIsn) {
              this.validationForm.addControl('isn', new FormControl('', Validators.required));
            }

            if (this.invitation.isManager) {
              this.registrationForm = this.fb.group({
                jobTitle: ['', [Validators.required]],
                description: ['', [Validators.required]],
                workPhone: ['', [Validators.required]],
                mobilePhone: ['', [Validators.required]],
                createPassword: ['', [Validators.required]],
                confirmPassword: ['', [Validators.required, passwordConfirming]],
              });
            } else {
              this.registrationForm = this.fb.group({
                createPassword: ['', [Validators.required]],
                confirmPassword: ['', [Validators.required, passwordConfirming]],
              });
            }
            this.step0 = true;
          }
        }
      );
    }
  }

  get sin() { return this.validationForm.get('sin'); }
  get isn() { return this.validationForm.get('isn'); }

  get jobTitle() { return this.registrationForm.get('jobTitle'); }
  get workPhone() { return this.registrationForm.get('workPhone'); }
  get mobilePhone() { return this.registrationForm.get('mobilePhone'); }
  get description() { return this.registrationForm.get('description'); }
  get createPassword() { return this.registrationForm.get('createPassword'); }
  get confirmPassword() { return this.registrationForm.get('confirmPassword'); }

  register(val: any) {
    if (!this.invitation.isManager) {
      this.model = {
        uid: '',
        memberId: this.invitation.memberId,
        departmentId: this.invitation.departmentId,
        givenName: this.invitation.givenName,
        lastName: this.invitation.lastName,
        email: this.invitation.email,
        jobTitle: this.invitation.jobTitle,
        workPhone: this.invitation.workPhone,
        mobilePhone: this.invitation.mobilePhone,
        description: this.invitation.description,
        showMobileNo: this.invitation.showMobileNo,
        showOfficeNo: this.invitation.showOfficeNo,
        showEmailAdr: this.invitation.showEmailAdr,
        allowContact: this.invitation.allowContact,
        manageAccount: this.invitation.manageAccount,
        manageContact: this.invitation.manageContact,
        manageDepartmentInfo: this.invitation.manageDepartmentInfo,
        manageDepartmentNews: this.invitation.manageDepartmentNews,
        manageDepartmentEmployees: this.invitation.manageDepartmentEmployees,
        manageSubDepartments: this.invitation.manageSubDepartments,
        role: 'Department-Employee',
        dateRegistred: new Date(),
        status: 'Enabled',
        isManager: false,
        accountVerified: false,
        verificationPassword: this.invitation.validationPassword
      };
    }
    if (this.invitation.isManager) {
      this.model = {
        uid: '',
        memberId: this.invitation.memberId,
        departmentId: this.invitation.departmentId,
        givenName: this.invitation.givenName,
        lastName: this.invitation.lastName,
        email: this.invitation.email,
        jobTitle: val.jobTitle,
        workPhone: val.workPhone,
        mobilePhone: val.mobilePhone,
        description: val.description,
        showMobileNo: this.showMobileNo,
        showOfficeNo: this.showOfficeNo,
        showEmailAdr: this.showEmailAdr,
        allowContact: this.allowContact,
        role: 'Department-Employee',
        dateRegistred: new Date(),
        status: 'Enabled',
        isManager: true,
      };
    }

    this.authService.employeeEmailSignUp(this.model, val.createPassword).then(
      cred => {
        const Email = {
          subject: 'Invitation Accepted',
          body: '<p> ' + this.invitation.givenName + ' ' + this.invitation.lastName + ' Accepted Your Invitation. </p>' +
            ' <p> Verification Code : <span style="color: blue;">' + this.invitation.validationPassword + '</span> </p> ' +
            ' <p> Please Forward This Verification Code To ' + this.invitation.givenName + ' ' + this.invitation.lastName + ' To Verify His Account </p>',
          emailList: ['reda@nationadmin.ca'] //[this.invitation.InvitedByEmail]
        };

        this.emailService.sendEmail(Email).subscribe(
          eml => {
            this.step1 = false;
            this.step2 = true;
          }
        );
      }).catch(error => {
        toast({ html: error.message, classes: 'red' });
      });
  }

  validate(data: any) {
    this.bandMemberService.getMember(this.invitation.memberId).subscribe(member => {
      if (member) {
        if (this.invitation.validateSin) {
          if (data.sin !== Number(member[0].SIN)) {
            // tslint:disable-next-line: max-line-length
            toast({ html: '<span> <i class="material-icons left">error_outline</i> Invalid Social Insurance Number ! </span>', classes: 'red' });
            return;
          }
        }

        if (this.invitation.validateIsn) {
          if (data.isn !== Number(member[0].IndianStatusNo)) {
            // tslint:disable-next-line: max-line-length
            toast({ html: '<span> <i class="material-icons left">error_outline</i> Invalid Indian Status Number ! </span>', classes: 'red' });
            return;
          }
        }
        toast({ html: '<span> <i class="material-icons left">check_circle_outline</i> Verification Success. </span> ', classes: 'green' });
        setTimeout(() => {
          this.step0 = false;
          this.step1 = true;
          M.updateTextFields();
        }, 25);
      }
    });
  }

  privateMobileClick() {
    this.showMobileNo = 'Private';
  }

  publicMobileClick() {
    this.showMobileNo = 'Public';
  }

  membersMobileClick() {
    this.showMobileNo = 'Members';
  }

  privateOfficeClick() {
    this.showOfficeNo = 'Private';
  }

  publicOfficeClick() {
    this.showOfficeNo = 'Public';
  }

  membersOfficeClick() {
    this.showOfficeNo = 'Members';
  }

  privateEmailClick() {
    this.showEmailAdr = 'Private';
  }

  publicEmailClick() {
    this.showEmailAdr = 'Public';
  }

  membersEmailClick() {
    this.showEmailAdr = 'Members';
  }

  privateContactClick() {
    this.allowContact = 'Private';
  }

  publicContactClick() {
    this.allowContact = 'Public';
  }

  membersContactClick() {
    this.allowContact = 'Members';
  }
}