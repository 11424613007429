import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule, FormGroup } from '@angular/forms';
// App Componenets
import { RegisterComponent } from './register/register.component';
import { AuthMainComponent } from './auth-main/auth-main.component';
import { AuthRoutingModule } from './auth-routing.module';
import { VerifyEmailComponent } from './verify-email/verify-email.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { LoginComponent } from './login/login.component';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { EmployerLoginComponent } from './employer-login/employer-login.component';
import { RegisterAdminComponent } from './register-admin/register-admin.component';
import { OauthRegistrationComponent } from './oauth-registration/oauth-registration.component';
import { MobileRegistrationComponent } from './mobile-registration/mobile-registration.component';
// import { TextMaskModule } from 'angular2-text-mask';
import { RegisterDepartmentEmployeeComponent } from './register-department-employee/register-department-employee.component';
import { DemoSignupComponent } from './demo-signup/demo-signup.component';
// import { NgOtpInputModule } from 'ng-otp-input';
import { RegisterOtherRolesComponent } from './register-other-roles/register-other-roles.component';
import { PseRegisterComponent } from './pse-register/pse-register.component';
import { EmployeeRegistrationComponent } from './employee-registration/employee-registration.component';
import { RegisterEmployeeComponent } from './register-employee/register-employee.component';
import { IsetsRegisterComponent } from './isets-register/isets-register.component';
import { AdminRegisterComponent } from './admin-register/admin-register.component';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';

@NgModule({
  declarations: [
    AuthMainComponent,
    RegisterComponent,
    VerifyEmailComponent,
    ChangePasswordComponent,
    LoginComponent,
    EmployerLoginComponent,
    RegisterAdminComponent,
    OauthRegistrationComponent,
    MobileRegistrationComponent,
    RegisterDepartmentEmployeeComponent,
    DemoSignupComponent,
    RegisterOtherRolesComponent,
    PseRegisterComponent,
    EmployeeRegistrationComponent,
    RegisterEmployeeComponent,
    IsetsRegisterComponent,
    AdminRegisterComponent,
  ],

  imports: [
    CommonModule,
    AuthRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    // TextMaskModule,
    // NgOtpInputModule
  ],

  providers: [
    AuthService,
    AppSettingsService
  ]
})

export class AuthModule { }