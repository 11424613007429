import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class VerficationRequestService {

  constructor(private db: AngularFirestore) { }
  requestListRef: AngularFirestoreCollection<any>;
  requestRef: AngularFirestoreDocument<any>;

  saveRequest(model: any){
    model.id = this.db.createId();
    return this.db.collection('VerificationRequest').doc(model.id).set(model);
  }

  getRequests(){
    this.requestListRef = this.db.collection('VerificationRequest');
    return this.requestListRef;
  }

  getRequestById(id: any): AngularFirestoreDocument<any>{
    this.requestRef = this.db.doc('VerificationRequest/'+ id);
    return this.requestRef;
  }

  getRequestByUID(id: any): AngularFirestoreCollection<any[]>{
    this.requestListRef = this.db.collection('VerificationRequest', ref => ref.where('uid', '==', id));
    return this.requestListRef;
  }

  updateRequest(model: any){
    return this.db.collection('VerificationRequest').doc(model.id).set(model);
  }

  updateRequestStatus(id: any, status: string){
    return this.db.collection('VerificationRequest').doc(id).update({status: status});
  }

  deleteRequest(id: any){
    return this.db.collection('VerificationRequest').doc(id).delete();

  }


}
