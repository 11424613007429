import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FireMembersService } from 'src/app/services/firebase/fire-members.service';
import { ElectionService } from 'src/app/services/firebase/election.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-election-detail',
  templateUrl: './election-detail.component.html',
  styleUrls: ['./election-detail.component.css']
})

export class ElectionDetailComponent implements OnInit {

  id = '';
 electionDetail: any;
  constructor(private route: ActivatedRoute,
              private electionService: ElectionService,
              private location: Location) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });

    this.electionService.getElectionById(this.id).valueChanges().subscribe(detail => {
      if (detail) {
        this.electionDetail = detail;
      }
    });
  }
  
  backClicked() {
    this.location.back();
  }

}
