import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';

declare var $: any;

@Component({
  selector: 'app-profile-main',
  templateUrl: './profile-main.component.html',
  styleUrls: ['./profile-main.component.css']
})
export class ProfileMainComponent implements OnInit {

  nationName = environment.firstNation.displayName;

  constructor() { }

  ngOnInit() {
    $(document).ready(function(){
      $('.tabs').tabs();
    });
  }

}
