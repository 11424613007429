<div class="flex justify-center h-screen" *ngIf="loading">
  <progress class="progress progress-[#219EBC] w-full"></progress>
</div>

<div *ngIf="!loading">
  <div class="main-container grid grid-cols-1 gap-y-5" *ngIf="userProfile">
    <div class="row justify-end gap-2 gap-y-4 pb:10 md:pb-0">
      <div *ngIf="hasActiveAccounts" class="justify-self-end col s6 offset-s6">
        <button (click)="toCreatePost()" [ngStyle]="{ 'background-color': themeColor }" class="btn add-button right">
          <i class="material-icons">post_add</i>
          Create A Post
        </button>
      </div>
    </div>

    <div class="mid-section row">
      <div class="profile-details col s12 m3 white">
        <div>
          <p class="gray-text lighten-2">Profile Details</p>
          <div class="py-6 profile-details-content">
            <div class="p-2">
              <i class="material-icons mr-2 float-left">email</i>{{ userProfile.title }}
            </div>
            <div class="p-2">
              <i class="material-icons mr-2 float-left">post_add</i>
              <span class="text-customTeal font-bold" [ngStyle]="{ color: themeColor }">{{ allAccountsPosts.length
                }}</span>
              Posts
            </div>
          </div>
        </div>
        
        <div>
          <p class="text-lightGray">Active media</p>
          <div class="py-2 flex flex-col">
            <div class="flex flex-wrap gap-x-2 pt-3 active-accounts" *ngIf="hasActiveAccounts">
              <div class="" *ngFor="let v of userProfile['activeSocialAccounts']">
                <i class="social-icons" *ngIf="v !== 'tiktok'" [ngClass]="
                    'text-xl mr-1 fa ' +
                    socialMediaIcons[v].text +
                    ' ' +
                    socialMediaIcons[v].color"></i>
                <svg class="mr-2" *ngIf="v == 'tiktok'" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24"
                  height="24" viewBox="0 0 50 50">
                  <path
                    d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z">
                  </path>
                </svg>
              </div>

              <app-link-account [link]="true"></app-link-account>
            </div>

            <div class="block font-bold" *ngIf="!hasActiveAccounts">
              <p [ngClass]="">No acounts linked yet</p>
              <app-link-account [link]="true" [new]="true" *ngIf="
                  !hasActiveAccounts &&
                  (isAdmin || (permSocial && permSocial.linkAccount))"></app-link-account>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>