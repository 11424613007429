import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MemberService } from 'src/app/services/api/member.service';
import { AdminInvitationService } from 'src/app/services/firebase/admin-invitation.service';
import { toast } from 'materialize-css';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/firebase/auth.service';

declare var $: any;

function passwordConfirming(c: AbstractControl): any {
  if (!c.parent || !c) { return; }
  const pwd = c.parent.get('createPassword');
  const cpwd = c.parent.get('confirmPassword');

  if (!pwd || !cpwd) { return; }
  if (pwd.value !== cpwd.value) {
    return { invalid: true };
  }
}

@Component({
  selector: 'app-admin-register',
  templateUrl: './admin-register.component.html',
  styleUrls: ['./admin-register.component.css']
})

export class AdminRegisterComponent implements OnInit {

  fontColor = environment.appTheme.adminFontColor;
  defaultThemeColor = environment.appTheme.themeColor;
  appSetting: any;
  themeColor: any;
  buttonType: any;

  invitationId: any;
  applicantID: any;
  invitation: any;
  admin: any;
  verificationForm: UntypedFormGroup;

  step1 = true;
  step2 = false;

  secretQuestion = false;
  secretQuestion2 = false;

  slicedIsnNumber: any;
  displayedISN: any;
  slicedSinNumber: any;
  displayedSIN: any;

  registrationForm: UntypedFormGroup;
  showPassword = false;
  showConfirmPassword = false;
  isDemo = false;
  error = false;
  errorMessage: any;

  constructor(private invitationService: AdminInvitationService,
    private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private memberService: MemberService,
    public appSettingsService: AppSettingsService,
    private authService: AuthService) {

    this.appSettingsService.getAppSettingsList().valueChanges().subscribe(
      appSettings => {
        if (appSettings && appSettings.length > 0) {
          let appSetting = appSettings[0];
          this.themeColor = this.appSettingsService.getAppThemeColor() ? this.appSettingsService.getAppThemeColor() : this.defaultThemeColor;
        }
      });
  }

  ngOnInit(): void {
    //get applicant id
    this.route.paramMap.subscribe(params => {
      if (params.get('id')) {
        this.invitationId = params.get('id');
        
        //Get Invitation Data from firebase
        this.invitationService.getInvitationByID(this.invitationId).valueChanges().subscribe(data => {
          if (data) {
            this.invitation = data;            
            this.verificationForm = this.fb.group({});
            if (!this.invitation.secretQuestion) {
              this.step1 = false;
              this.step2 = true;
            }

            //Get member data from SQL
            if (!this.invitation.newAdmin && this.invitation.applicantId) {
              this.applicantID = this.invitation.applicantId;
              // this.memberService.getMember(this.applicantID).subscribe(member => {
              //   if (member) {
              //     this.admin = member[0];
              //     // if (this.invitation.verifiySIN && this.admin.SIN) {
              //     //   this.verifiySIN = true;
              //     //   this.slicedSinNumber = this.admin.SIN.slice(-3);
              //     //   this.displayedSIN = this.admin.SIN.charAt(0) + 'XXXXX';
              //     //   this.verificationForm.addControl('sin', new FormControl(this.displayedSIN, [Validators.required, Validators.minLength(9)]));
              //     // }

              //     // if (this.invitation.verifyISN && this.admin.IndianStatusNo) {
              //     //   this.verifiyISN = true;
              //     //   this.slicedIsnNumber = this.admin.IndianStatusNo.slice(-3);
              //     //   this.displayedISN = this.admin.IndianStatusNo.charAt(0) + 'XXXX';
              //     //   this.verificationForm.addControl('isn', new FormControl(this.displayedISN, [Validators.required, Validators.minLength(10)]));
              //     // }
              //   }
              // });
            }

            if (this.invitation.secretQuestion) {
              this.secretQuestion = true;
              if (this.invitation.questions && this.invitation.questions.length > 0) {
                this.verificationForm.addControl('question1', new UntypedFormControl('', Validators.required));
                if (this.invitation.questions.length > 1) {
                  this.secretQuestion2 = true;
                  this.verificationForm.addControl('question2', new UntypedFormControl('', Validators.required));
                }
              }
            }
          }
        });

        this.registrationForm = this.fb.group({
          createPassword: ['', [Validators.required]],
          confirmPassword: ['', [Validators.required, passwordConfirming]],
        });

        setTimeout(() => {
          $(".modal").modal();
        }, 25);
      }
    });
  }

  getFormControl(name: string, formGroup: UntypedFormGroup) {
    return formGroup.get(name);
  }

  get sin() { return this.verificationForm.get('sin'); }
  get isn() { return this.verificationForm.get('isn'); }
  get createPassword() { return this.registrationForm.get('createPassword'); }
  get confirmPassword() { return this.registrationForm.get('confirmPassword'); }


  verification(data) {
    // if (this.verifiySIN) {
    //   if (Number(data.value.sin.slice(-3)) !== Number(this.admin.SIN.slice(-3))) {
    //     // tslint:disable-next-line: max-line-length
    //     toast({ html: '<span> <i class="material-icons left">error_outline</i> Invalid Social Insurance Number ! </span>', classes: 'red' });
    //     return;
    //   }
    // }

    // if (this.verifiyISN) {
    //   if (Number(data.value.isn.slice(-5)) !== Number(this.admin.IndianStatusNo.slice(-5))) {
    //     toast({ html: '<span> <i class="material-icons left">error_outline</i> Invalid Indian Status Number ! </span>', classes: 'red' });
    //     return;
    //   }
    // }

    if (this.secretQuestion) {
      const answer = this.invitation.questions[0].answer;
      const response = data.value.question1;
      if (response.replace(/\s/g, '').toLowerCase() !== answer.replace(/\s/g, '').toLowerCase()) {
        toast({ html: '<span> <i class="material-icons left">error_outline</i> Invalid Answer. Please check your answer and try again! </span>', classes: 'red' });
        return;
      }
    }

    if (this.secretQuestion2) {
      const answer = this.invitation.questions[1].answer;
      const response = data.value.question2;
      if (response.replace(/\s/g, '').toLowerCase() !== answer.replace(/\s/g, '').toLowerCase()) {
        toast({ html: '<span> <i class="material-icons left">error_outline</i> Invalid Answer. Please check your answers and try again ! </span>', classes: 'red' });
        return;
      }
    }

    toast({ html: '<span> <i class="material-icons left">check_circle_outline</i> Verification Success. </span> ', classes: 'green' });
    this.step1 = false;
    this.step2 = true;
  }

  toggleVisibility() {
    this.showPassword = !this.showPassword;
  }

  toggleVisibility1() {
    this.showConfirmPassword = !this.showConfirmPassword;
  }

  formatPhoneNumber(phoneNumber) {
    // Remove all non-numeric characters from the input
    const phoneNo = phoneNumber.replace(/\D/g, '');
    if (phoneNo.length === 10) {
      return phoneNo;
    } else {
      return 'Invalid phone number';
    }
  }
  
  register(data) {
    let admin: any = {
      FirstName: this.invitation.firstName,
      LastName: this.invitation.lastName,
      Email_Address: this.invitation.email,
      phone: this.formatPhoneNumber(this.invitation.mobile),
      password: data.createPassword,
      permissions: this.invitation.permissions,
    }

    this.memberService.saveAdmin(admin).subscribe(id => {
      if (id) {
        admin.uid = id;
        this.authService.adminEmailSignUp(admin, this.isDemo, data.createPassword).then(x => {
          this.invitationService.updateInvitationStatus(this.invitation.id, 'Registred');
          toast({ html: '<span> <i class="material-icons left">check_circle_outline</i> Registration is Successful. </span> ', classes: 'green' });
        }).catch(error => {
          this.error = true;
          this.errorMessage = error.message;
          toast({ html: error.message, classes: 'red' });
        });
      }
    });
  }
}