import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-access-screen',
  templateUrl: './access-screen.component.html',
  styleUrls: ['./access-screen.component.css']
})
export class AccessScreenComponent implements OnInit {

  defaultThemeColor = environment.appTheme.themeColor;
  themeColor: any;
  user: any;

  constructor(private authService: AuthService, 
    private router: Router,
    public appSettingsService: AppSettingsService) {
    this.themeColor = this.appSettingsService.getAppThemeColor() ? this.appSettingsService.getAppThemeColor() : this.defaultThemeColor;
  }

  ngOnInit() {
    this.authService.fireMember.subscribe(data => {
      if (data) {
        this.user = data;
      }
    });
  }

  member() {
    this.router.navigate(['/member/home']);
  }

  chief() {
    this.router.navigate(['/admin/dashboard']);
  }

  logout() {
    this.authService.signOut();
  }
}
