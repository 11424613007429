import { Component, OnInit } from '@angular/core';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { EventsService } from 'src/app/services/firebase/events.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-upcoming-events',
  templateUrl: './upcoming-events.component.html',
  styleUrls: ['./upcoming-events.component.css']
})

export class UpcomingEventsComponent implements OnInit {

  defaultThemeColor = environment.appTheme.themeColor;
  model: any;
  themeColor: any;
  eventSection: any;
  events: any[];

  constructor(
    private appSettingService: AppSettingsService,
    private eventService: EventsService) {
    this.themeColor = this.appSettingService.getAppThemeColor() ? this.appSettingService.getAppThemeColor() : this.defaultThemeColor;
    this.appSettingService.getAppSetting().valueChanges().subscribe(
      appSettings => {
        if (appSettings) {
          if (appSettings.length > 0) {
            this.model = appSettings[0];
            this.themeColor = this.model.themeColor ? this.model.themeColor : this.defaultThemeColor;
          }
        }
      });
  }

  ngOnInit(): void {
    this.appSettingService.getHomePageSettings().valueChanges().subscribe(hp => {
      if (hp) {
        if (hp.length > 0) {
          this.model = hp[0];
          const eventSection = this.model.sections.filter(s => s.name === 'Events' && s.isEnabled);
          if (eventSection) {
            this.eventSection = eventSection[0];

            this.eventService.getPublicEventsList().valueChanges().subscribe(events => {
              if (events) {
                this.events = events.splice(0, this.eventSection.displayLimit);
              }
            });
          }
        }
      }
    });
  }

  navigate() {
    this.appSettingService.setCurrentMenu(this.eventSection.displayName);
  }

}
