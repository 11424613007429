<div class="container body">
    <div class="row center-align">
        <h5><strong> Intervention Detail </strong></h5>
    </div>
    <div *ngIf="intervention" class="row col s12">  
      <div class="col l1 "></div>
      <div class="col s12 l10">
          <app-isets-intervention [model]="intervention"></app-isets-intervention>
      </div>
      <div class="col l1 "></div>
    </div>

    <div class="row col s12">
        <div class="col l1 "></div>      
        <div class="col s12 l10">
          <!--
          <a class="col s12 btn waves-effect teal" (click)="sendVerificationEmail()" style="margin-bottom: 15px;"><i
            class="material-icons right">link</i> Send Invitation Link</a>
      
          <a class="col s12 btn waves-effect teal" (click)="contactClient()" style="margin-bottom: 15px;"><i
            class="material-icons right">mail</i> Send Email</a>
            -->
          <a [ngClass]="'col s12 btn waves-effect ' + themeColor" (click)="backClicked()"><i
              class="material-icons left">arrow_back_ios</i> Back To Interventions List</a>
        </div>
      
        <div class="col l1"></div>
    </div>
</div>