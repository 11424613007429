import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { ElectionModel } from 'src/app/models/ElectionModel';

@Injectable({
  providedIn: 'root'
})
export class ElectionService {
  constructor(private db: AngularFirestore) { }

  election: AngularFirestoreDocument<any>;
  electionList: AngularFirestoreCollection<any>;

  createNewElection(model: ElectionModel){
      const newContent = {
        id: this.db.createId(),
        name: model.name,
        startDate : model.startDate,
        endDate: model.endDate,
        candidates: model.candidates,
        nomineeMinAge: model.nomineeMinAge || 0,
        voterMinAge: model.voterMinAge || 0,
        createdBy: model.createdBy,
        DateCreated: model.DateCreated,
        showVotingResult: model.showVotingResult,
        showVoteRecieve: model.showVoteRecieve,
        showAllCandidatesVotes: model.showAllCandidatesVotes,
      }
      return {
        obj: this.db.collection('Election').doc(newContent.id).set(newContent),
        id: newContent.id};
  }

  getElectionList(){
    return this.db.collection('Election');
  }

  getElectionById(id: string){
    this.election =  this.db.doc('Election/' + id);
    return this.election;
  }

  updateElection(model: any) {
    const newContent = {
        id: model.id,
        name: model.name,
        startDate : model.startDate,
        endDate: model.endDate,
        candidates: model.candidates,
        nomineeMinAge: model.nomineeMinAge || 0,
        voterMinAge: model.voterMinAge || 0,
        createdBy: model.createdBy,
        DateCreated: model.DateCreated,
        showVotingResult: model.showVotingResult ,
        showVoteRecieve: model.showVoteRecieve ,
        showAllCandidatesVotes: model.showAllCandidatesVotes,
      };

    return this.db.collection('Election').doc(newContent.id).update(newContent);
  }

  deleteElection(id: string){
    return this.db.collection('Election').doc(id).delete();
  }

  // addCandidate(model: any) {
  //   const newContent = {
  //     id: this.db.createId(),
  //     candidateId: model.candidateId,
  //     electionId : model.electionId,
  //   };
  //   return this.db.collection('ElectionCandidates').doc(newContent.id).set(newContent);
  // }

  getCandidateById(id: string) {
    return this.db.collection('ElectionCandidates', ref => ref.where('candidateId', '==', id));
  }

  addCandidateDetail(model: any) {
    const newContent = {
      id: this.db.createId(),
      candidateId: model.candidateId,
      facebook: model.facebook || '',
      twitter: model.twitter || '',
      instagram: model.instagram || '',
      youtube: model.youtube || '',
      campaignDescription: model.campaignDescription || '',
      candidateFor: model.candidateFor
    };
    return this.db.collection('CandidatesDetail').doc(newContent.id).set(newContent);
  }

  updateCandidateDetail(model: any) {
    const newContent = {
      id: model.id,
      candidateId: model.candidateId,
      facebook: model.facebook,
      twitter: model.twitter,
      instagram: model.instagram,
      youtube: model.youtube,
      campaignDescription: model.campaignDescription,
      candidateFor: model.candidateFor
    };
    return this.db.collection('CandidatesDetail').doc(newContent.id).update(newContent);
  }

  getCandidateDetailById(id: string) {
    return this.db.collection('CandidatesDetail', ref => ref.where('candidateId', '==', id));
  }

  getAllMembers() {
    return this.db.collection('BandMembers', ref => ref.where('role', '==', 'Member'));
  }

}