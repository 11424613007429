export class IncomeDeclarationModel {



    applicantID = 0;
    applicationNo = 0;
    catagory = '';
    description = '';
    value = 0;
    verified = false;
    type = '';
    member_name = '';
    date_recieved = new Date();
    DOI_ID = 0;
    mainApplicantID = 0;
    // earnings_gross_cl = 0;
    // earnings_gross_sp = 0;
    // earnings_gross_dp = 0;
    // earnings_net_cl = 0 ;
    // earnings_net_sp = 0 ;
    // earnings_net_dp = 0 ;
    // farmOrBus_cl = 0 ;
    // farmOrBus_sp = 0 ;
    // farmOrBus_dp = 0 ;
    // rentalIncome_cl = 0 ;
    // rentalIncome_sp = 0 ;
    // rentalIncome_dp = 0 ;
    // privatePension_cl = 0 ;
    // privatePension_sp = 0 ;
    // privatePension_dp = 0 ;
    // giftAndWindfalls_cl = 0 ;
    // giftAndWindfalls_sp = 0 ;
    // giftAndWindfalls_dp = 0 ;
    // oas_gis_sa_wsa_cl = 0 ;
    // oas_gis_sa_wsa_sp = 0 ;
    // oas_gis_sa_wsa_dp = 0 ;
    // gains_a_cl = 0 ;
    // gains_a_sp = 0 ;
    // gains_a_dp = 0 ;
    // insurance_benefits_cl = 0 ;
    // insurance_benefits_sp = 0 ;
    // insurance_benefits_dp = 0 ;
    // support_payments_cl = 0 ;
    // support_payments_sp = 0 ;
    // support_payments_dp = 0 ;
    // foreign_pension_cl = 0 ;
    // foreign_pension_sp = 0 ;
    // foreign_pension_dp = 0 ;
    // employment_insurance_cl = 0 ;
    // employment_insurance_sp = 0 ;
    // employment_insurance_dp = 0 ;
    // cpp_qpp_retirement_cl = 0 ;
    // cpp_qpp_retirement_sp = 0 ;
    // cpp_qpp_retirement_dp = 0 ;
    // cpp_qpp_disability_cl = 0 ;
    // cpp_qpp_disability_sp = 0 ;
    // cpp_qpp_disability_dp = 0 ;
    // cpp_qpp_survivor_cl = 0 ;
    // cpp_qpp_survivor_sp = 0 ;
    // cpp_qpp_survivor_dp = 0 ;
    // trust_inheritance_cl = 0 ;
    // trust_inheritance_sp = 0 ;
    // trust_inheritance_dp = 0 ;
    // loans_disability_cl = 0 ;
    // loans_disability_sp = 0 ;
    // loans_disability_dp = 0 ;
    // wsib_cl = 0 ;
    // wsib_sp = 0 ;
    // wsib_dp = 0 ;
    // training_Allowance_net_cl = 0 ;
    // training_Allowance_net_sp = 0 ;
    // training_Allowance_net_dp = 0 ;
    // training_Allowance_gross_cl = 0 ;
    // training_Allowance_gross_sp = 0 ;
    // training_Allowance_gross_dp = 0 ;
    // funds_Annuities_int_cl = 0 ;
    // funds_Annuities_int_sp = 0 ;
    // funds_Annuities_int_dp = 0 ;
    // other_cl = 0 ;
    // other_sp = 0 ;
    // other_dp = 0 ;
    // other_specify = '' ;
    // bd_no = '';
    // month = '';
    // year = 0;
    // aid = 0;
}