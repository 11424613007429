import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})

export class PollsAndSurveyParticipationService {

  surveyParticipationListRef: AngularFirestoreCollection<any>;
  surveyParticipationRef: AngularFirestoreDocument<any>;

  pollsParticipationListRef: AngularFirestoreCollection<any>;
  pollsParticipationRef: AngularFirestoreDocument<any>;

  constructor(private db: AngularFirestore) { }

  newPollParticipation(model: any)  {
    model.id  = this.db.createId();
    return this.db.collection('PollsParticipation').doc(model.id).set(model);
  }

  getPollParticipationByClientID(id: string): AngularFirestoreCollection<any> {
    this.pollsParticipationListRef = this.db.collection('PollsParticipation', ref => ref.where('clientID', '==', id));
    return this.pollsParticipationListRef;
  }

  getPollParticipationByPollID(id: string): AngularFirestoreCollection<any> {
    this.pollsParticipationListRef = this.db.collection('PollsParticipation', ref => ref.where('pollID', '==', id));
    return this.pollsParticipationListRef;
  }

  newSurveyParticipation(model: any)  {
    model.id  = this.db.createId();
    return this.db.collection('SurveyParticipation').doc(model.id).set(model);
  }

  getSurveyParticipationByClientID(id: string): AngularFirestoreCollection<any> {
    this.surveyParticipationListRef = this.db.collection('SurveyParticipation', ref => ref.where('memberID', '==', id));
    return this.surveyParticipationListRef;
  }

  getSurveyParticipationBySurveyID(id:string) {
    this.surveyParticipationListRef = this.db.collection('SurveyParticipation', ref => ref.where('surveyId', '==', id));
    return this.surveyParticipationListRef;
  }

  getSurveyParticipationByClientAndSurveyId(id: string, clientID: string  ): AngularFirestoreCollection<any> {
    this.surveyParticipationListRef = this.db.collection('SurveyParticipation', ref => ref.where('surveyId', '==', id).where('memberID', '==', clientID));
    return this.surveyParticipationListRef;
  }

}
