
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { PseEntitlementModel } from 'src/app/models/PseEntitlementModel';
import { Observable } from 'rxjs';
import { PseSignatureModel } from 'src/app/models/PseSignatureModel';
import { ApiKeyManagerService } from './apiKeyManager.Service';

@Injectable({
    providedIn: 'root'
  })
  export class PseEntitlementService {
  
    url = environment.firstNation.apiUrl;
  
    constructor(private httpClient: HttpClient, private keyManager: ApiKeyManagerService) { }
    
    getEntitlement(id: number): Observable<PseEntitlementModel[]> {
        const headers = new HttpHeaders({SOCIALAPIKEY: this.keyManager.getKey()});
        return this.httpClient.get<PseEntitlementModel[]>(
        this.url + 'api/PseEntitlement/GetEntitlementByMemberID/' + id, {headers});
    }
    
    getCurrentEntitlement(id: number): Observable<PseEntitlementModel[]> {
        const headers = new HttpHeaders().set('SOCIALAPIKEY', this.keyManager.getKey());
        return this.httpClient.get<PseEntitlementModel[]>(this.url + 'api/PseEntitlement/GetCurrentEntitlement/' + id, {headers});
    }
    
    getCurrentPaidEntitlement(id: number): Observable<PseEntitlementModel[]> {
        const headers = new HttpHeaders().set('SOCIALAPIKEY', this.keyManager.getKey());
        return this.httpClient.get<PseEntitlementModel[]>(this.url + 'api/PseEntitlement/GetCurrentPaidEntitlement/' + id, {headers});
    }
    
    getEntitlementByID(id: string): Observable<PseEntitlementModel[]> {
        const headers = new HttpHeaders().set('SOCIALAPIKEY', this.keyManager.getKey());
        return this.httpClient.get<PseEntitlementModel[]>(this.url + 'api/PseEntitlement/GetEntitlementByMonthlyRequirementID/' + id, {headers});
    }
    
    getEntitlementByBDNo(id: string): Observable<PseEntitlementModel[]> {
        const model = {bdno: id};
        const headers = new HttpHeaders({SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8'});
        return this.httpClient.post<any>(this.url + 'api/PseEntitlement/GetEntitlementByBDNo' , JSON.stringify(model), {headers});
    }
    
    
    postStudentSignature(model: PseSignatureModel): any {
        const headers = new HttpHeaders({SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8'});
        return this.httpClient.post<any>(this.url + 'api/PseEntitlement/PostStudentSignature', JSON.stringify(model), {headers});
    }
    
    postParentsSignature(model: PseSignatureModel): any {
        const headers = new HttpHeaders({SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8'});
        return this.httpClient.post<any>(this.url + 'api/PseEntitlement/PostParentsSignature', JSON.stringify(model), {headers});
    }


    /*
    PostMultipleSignature(model: PseSignatureModel): any {
        const headers = new HttpHeaders({SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8'});
        return this.httpClient.post<any>(this.url + 'api/entitlement/PostMultipleSignature', JSON.stringify(model), {headers});
    }
    
    PostSpouseMultipleSignature(model: PseSignatureModel): any {
        const headers = new HttpHeaders({SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8'});
        return this.httpClient.post<any>(this.url + 'api/PseEntitlement/PostSpouseMultipleSignature', JSON.stringify(model), {headers});
    }
    
    
    */

  
  }
  

  