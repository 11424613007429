import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';
import { MemberService } from 'src/app/services/api/member.service';
import { FireMembersService } from 'src/app/services/firebase/fire-members.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { SearchMemberModel } from 'src/app/models/searchMemberModel';
import { Subject } from 'rxjs';

declare var M: any;

@Component({
  selector: 'app-lrrcn-application',
  templateUrl: './lrrcn-application.component.html',
  styleUrls: ['./lrrcn-application.component.css']
})
export class LrrcnApplicationComponent implements OnInit {

  showSurvey = false
  members
  loading = false
  themeColor = environment.appTheme.adminThemeColor;
  count
  filters = {
    firstName: '',
    lastName: ''
  }
  filtredMembers: any[];
  showSearch: Boolean = true;
  searchMemberForm: UntypedFormGroup;
  showSearchTable: Boolean = false;
  showAddMemberForm: Boolean = false;
  memberModel: SearchMemberModel;
  dtTrigger: Subject<any> = new Subject();
  processing: Boolean = false;
  searchHasRecord = true;
  memberListData: any;
  postObj: any;
  applicantID: any;

  constructor(private location: Location,
    private memberService: MemberService,
    private formBuilder: UntypedFormBuilder) {
    this.searchMemberForm = this.formBuilder.group({
      firstName: ['', [Validators.required, Validators.minLength(1)]],
      lastName: ['', [Validators.required, Validators.minLength(1)]]
    });
  }


  ngOnInit(): void {
  }

  onSearchMembers() {
    this.processing = false;
    if (this.searchMemberForm.value.firstName !== '' || this.searchMemberForm.value.lastName !== '') {
      this.memberModel = new SearchMemberModel();
      this.memberModel.firstName = this.searchMemberForm.value.firstName;
      this.memberModel.lastName = this.searchMemberForm.value.lastName;

      this.memberService.searchMemberByName(this.memberModel).subscribe(x => {
        if (x) {
          this.memberListData = x;
          this.showSearchTable = true;
          this.searchHasRecord = true;
          this.dtTrigger.next(null);
        } else {
          this.searchHasRecord = false;
        }
      });
      this.postObj = { ...this.searchMemberForm.value };
    }
  }

  selectMember(member) {
    this.applicantID = member.applicantID
    this.showSurvey = true
  }

  backClicked() {
    this.location.back();
  }
  backFromSurvey() {
    this.applicantID = null;
    this.showSurvey = false
  }

  memberSelected() {
    this.showSurvey = true
  }

  backToSearch(valueEmitted) {
    if (valueEmitted == true) {
      this.applicantID = null
      this.showSurvey = false
      setTimeout(() => {
        M.updateTextFields();
      }, 25);
    }
  }

}
