
export class CertificateModel {
    id = 0;
    client_id = 0;
    course = '';
    institution = '';
    start_date: Date;
    completion_date: Date;
    expiry_date: Date;
    description = '';
    
  }