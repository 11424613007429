import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { VerficationRequestService } from 'src/app/services/firebase/verfication-request.service';
import { environment } from 'src/environments/environment';
import { toast } from 'materialize-css';
import { FireMembersService } from 'src/app/services/firebase/fire-members.service';

@Component({
  selector: 'app-member-verification',
  templateUrl: './member-verification.component.html',
  styleUrls: ['./member-verification.component.css']
})
export class MemberVerificationComponent implements OnInit {
  defaultThemeColor = environment.appTheme.themeColor;
  loading = false;
  requests: any[];
  themeColor: any;
  buttonType: any;

  pending: any[];
  approved: any[];
  declined: any[];
  request: any;

  showDetail = false;
  showPending = true;
  showDeclined = false;
  showApproved = false;
  contact = false;
  contactDetail: any = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNo: '',
  };
  message: any = {
    title: '',
    subject: '',
    body: '',
    files: []
  };

  constructor(private verificationService: VerficationRequestService,
    private location: Location,
    private settingsService: AppSettingsService,
    private fireMembersService: FireMembersService) {
    this.themeColor = this.settingsService.getAppThemeColor() ? this.settingsService.getAppThemeColor() : this.defaultThemeColor;
    this.buttonType = this.settingsService.getButtonType() ? this.settingsService.getButtonType() : 'rectangularButton';
  }

  ngOnInit(): void {
    this.loading = true;
    this.verificationService.getRequests().valueChanges().subscribe(req => {
      if (req && req.length > 0) {
        this.requests = req;

        this.pending = this.requests.filter(r => r.status == 'Pending');
        this.approved = this.requests.filter(r => r.status == 'Approved');
        this.declined = this.requests.filter(r => r.status == 'Declined');
      }
      this.loading = false;
    });
  }

  showPendingClicked(){
    this.showPending= true;
    this.showApproved= false;
    this.showDeclined= false;
  }

  showDeclinedClicked(){
    this.showDeclined= true;
    this.showPending= false;
    this.showApproved= false;
  }

  showApprovedClicked(){
    this.showApproved= true;
    this.showPending= false;
    this.showDeclined= false;
  }

  approve(id?) {
    if (id) this.request = this.requests.filter(r => r.id == id)[0];
    this.request.status = 'Approved';
    this.verificationService.updateRequestStatus(this.request.id, 'Approved').then(x => {
      this.fireMembersService.updateVerification(this.request.uid, true, 'Approved');
      toast({ html: 'The request has been approved!', classes: 'green' });
      this.back();
    });
  }

  decline(id?) {
    if (id) this.request = this.requests.filter(r => r.id == id)[0];
    this.request.status = 'Declined'
    this.verificationService.updateRequestStatus(this.request.id, 'Declined').then(x => {
      this.fireMembersService.updateVerification(this.request.uid, false, 'Declined');
      toast({ html: 'The request has been Declined!', classes: 'green' });
      this.back();
    });
  }

  showDetails(id) {
    let req = this.requests.filter(r => r.id == id);
    this.request = req[0];
    this.showDetail = true;
  }

  contactMember(id?) {
    if (id) this.request = this.requests.filter(r => r.id == id)[0];
    this.contactDetail.firstName = this.request.firstName;
    this.contactDetail.lastName = this.request.lastName;
    this.contactDetail.email = this.request.email;
    this.contactDetail.phoneNo = this.request.mobile;
    this.message.subject = 'Verfication Request';
    this.message.title = 'VerficationRequest';
    this.contact = true;
  }

  contactHandler(valueEmitted) {
    if (valueEmitted.status) toast({ html: 'Message sent to client Successfully!', classes: 'green' });
    else toast({ html: 'Message Not sent to the client!', classes: 'red' });
  }
  
  backClicked() {
    this.showDetail = false;
  }

  back() {
    this.location.back();
  }

}
