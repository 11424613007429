import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from '@angular/forms';
import { AboutLrrcn, AddictionSuuport, BarriersOfJob, CareGiving, Demographic, Education, EmploymentHistory, JobSatisfaction, License, LRRCNSurveyModel, ShiftWork, TraditionalEmployment, TrainingAndEmployment } from 'src/app/models/LRRCNSurveyModel';
import { MemberService } from 'src/app/services/api/member.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { FireMembersService } from 'src/app/services/firebase/fire-members.service';
import { LRRCNSurveyService } from 'src/app/services/firebase/lrrcn-survey.service';
import SignaturePad from 'signature_pad';
import { toast } from 'materialize-css';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { ManageAdminService } from 'src/app/services/api/manage-admin.service';
import { EmailService } from 'src/app/services/api/email.service';
import { EmailModel } from 'src/app/models/emailModel';
import { environment } from 'src/environments/environment';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-lrrcn-applications',
  templateUrl: './lrrcn-applications.component.html',
  styleUrls: ['./lrrcn-applications.component.css']
})
export class LrrcnApplicationsComponent implements OnInit {

  @Input() memberId: any;
  @Output() onBackToSearch: EventEmitter<boolean> = new EventEmitter<boolean>();

  nationOfUse = environment.firstNation.displayName;
  loggedUser = this.authService.getGlobalUser()
  step = 0;
  currentUser: any;
  lrrcnForm: UntypedFormGroup;
  date = new Date()

  maritalStatuses: any = [{ name: 'Single', value: '0' }, { name: 'Single-Parent', value: '1' }, { name: 'Married', value: '2' }, { name: "Common Law", value: '3' }];
  EduLevelList = ["Preschool", "Up to Grade 7-8 (Sec. I = Grade 8)", "Grade 9-10 (Sec. II-III)", "Grade 11-12 (Sec. IV-V)",
    "Secondary School Diploma or GED", "Some post-secondary training", "Apprenticeship or trades certificate or diploma",
    "College", "CEGEP", "University certificate or diploma", "University - Bachelor Degree", "University – Master’s degree", "University – Doctorate", "Course or certificate", "Other"];

  ageGroup = ["18-19", "20-24", "25-29", "30-34", "35-39", "40-44", "45-49", "50-54", "55-59", "60-64"];
  community = ["John D'or Prairie", "Garden River", "Fox Lake", "Other"];
  housingAccommodation = ["Rent", "Couch Surfing", "Own", "Shared Accommodations"];

  otherLanguage = '';
  yesNo = ['Yes', 'No']
  literacy = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '12+']

  numOfDependants = ["0", "1", "2", "3 or 4", "5 or 6", "7 +"];
  cares = ["No - not a care proivder", "Granparent(s)", "Your own child(ren)", "Niece(s)/Nephew(s)", "Step-child(ren)", "Someone with special needs",
    "Grandchild(ren)", "Foster-child(ren)", "Parents(s)", "Sibiling(s)"];
  dayCareAvailable: boolean
  levelOfEducation = ['Less than Grade 9', 'Grade 9', 'Grade 10', 'Grade 11', 'High School Diploma', 'GED', 'Some College',
    'College Certificate/Diploma', 'Apprenticeship', 'Some University', 'University Degree', 'Masters/Phd', 'Other Education or Certificates', 'No Education']

  educationToAchieve = ['No Education', 'Less than Grade 9', 'Grade 9', 'Grade 10', 'Grade 11', 'High School Diploma', 'GED', 'College Certificate/Diploma - Area of Study:',
    'Apprenticeship - Type of Trade: ', 'University Degree - Area of Study:', 'Masters/Phd - Area of Study:']

  licenseTypes = ['Class 1 (any vehicle)', 'Class 2 (large bus)', 'Class 3 (3 axle)', 'Class 4 (taxi/ambulance)', 'Class 5 (car, light truck)', 'Class 6 (motorcycle)',
    'Class 7 (learners)', 'No driver\'s license (never held one)', 'No driver\'s license (not currently held)']

  licenseBarriers = ['No access to a vehicle', 'Suspend', 'Unable to get testing of place', 'No access to driver training', 'Fines', 'Did not pass test'
    , 'Maintenance enforcment']
  licenseTypesToObtain: any[] = this.licenseTypes.slice(0, -3)

  safteyTrainings = ['ARC Flash Training', 'Bear Awareness', 'Chainsaw Safety', 'Confined Space', 'Construction Safety(CSTS)', 'Fall Protection', 'First Aid', 'Flag Person Training', 'Food Saftey',
    'GPS', 'Ground Distribution', 'H2S Alive', 'WHMIS', 'Wildland Fire', 'Fire Watch', 'BSO', 'Snowmobile Safety', 'TDG', 'Forklift Saftey', 'Computer/Microsoft Office etc.']

  notificationMetdhods = [`${this.nationOfUse} Website`, `${this.nationOfUse} Facebook`, `${this.nationOfUse} Electronic Advertisement`, 'Electronic Job Board', 'Job Board at Band Office', 'Specialized On-Line Programs: Economic Development', 'Specialized On-Line Programs: Human Resources',
    'Specialized On-Line Programs: Income Assistance', "None"]

  employmentStatuses = ['Full-time paid employment (35+ hrs/week)', 'Part-time paid employment (less than 35 hrs/week)', 'Casual Employment/Contract', 'Employment Insurance',
    'Self-Employed', 'Maternity Leave', 'Paternal Leave', 'Medical Leave', 'Seasonal Worker', 'unemployed', 'Other form of paid employment (please specify)']

  emptGoals = ['Not Working', 'Business Services(accountant, sales, humanresources etc.)', 'Environmental (forestry, wildlife etc.)', 'Health care provider (nurse, doctor, health care aide etc.)', 'Construction / General Labour (janitor, equipment operator etc.)',
    'Sport & Recreation (coordinator, coach etc.)', 'Service/Retail (cashier, clerk, gas jockey etc.)', 'Tourism (hotel, rafting guide,hunting guide etc.)', 'Social/Human Services(social worker, child services worker etc.)', 'Education/Training (teacher,trainer etc.)',
    'Transportation (truck driver,bus driver, water truck driver etc.)', 'Hospitality (server, housekeeper, cook, dishwasher etc.)', 'Manufacturing (sawmill worker etc.)', 'Public Administration (administrative, government program etc.)', 'Personal Services(babysitter, home care worker, cutting grass etc.) ', 'Traditional (hunter, trapper,fisherman etc.)', 'First Nations Governance',
    'Translator (Cree)', 'Oil & Gas Industry', 'Trades', 'Skilled Technician', 'Professional',
    'Administration', 'Engineering', 'Surveyor', 'Scientist']

  // emptGoals2 = ['Public Administration (administrative, government program etc.)', 'Personal Services(babysitter, home care worker, cutting grass etc.) ', 'Traditional (hunter, trapper,fisherman etc.)', 'First Nations Governance',
  //   'Translator (Cree)', 'Oil & Gas Industry', 'Trades', 'Skilled Technician', 'Professional',
  //   'Administration', 'Engineering', 'Surveyor', 'Scientist']

  workExpirience = ['Agri-tractor', 'Grader', 'Scrapere', 'Track how', 'Backhoe', 'Heavy hauler', 'Side boom', 'Pressure truck', 'Bobcat'
    , 'Dozer/crawler', 'Loader', 'Skidder', 'Vaccum truck', 'Rock truck']

  supportGroups = ['Mental Health', 'Single Parents', 'Addictions', 'Women(Domestic Violence)']
  NNDAInfos = ['One-on-one counselling', 'Support group', 'Cultural support', 'Treatment centre', 'Individual counselling for the whole family',
    'Group counselling with the whole family', 'Support group']

  satisfactionFactors = ['Health issues (diagnosed allergies, heart problems etc.)', 'Physical (lifting 50lbs, vision, etc.) ', 'Emotional (fear, self-confidence...etc.) ', 'Mental (learning issue, depression…etc.) ',
    'Family Situation', 'Housing', 'Transportation', 'Addictions or History of Addictions', 'None']

  jobBarriers = ['Accommodations', 'Addictions', 'Fear', 'Low confidence', 'Business knowledge', 'Competition for same job', 'Criminal record',
    'Distance (too far)', 'Care of Elder family member', 'Limited employable skills', 'Communicating issues (reading/writing/speaking)', , 'Socializing issues',
    'Language barrier', 'Being female', 'Single parent', 'Childcare', 'Limited work expirience', 'Getting fired', 'Physical disability',
    'Injury', 'Budgeting/finance  ', 'Health issues', 'Relationship issues', 'Transgender', 'Community environment', 'Lack of skills  ',
    'Do not know how to find a job', 'Age', 'Bills/depts', 'Maintenance enforcement', 'Behavioral', , 'Transportation',
    'Education Level', 'Family issues', 'Mental/emotional issues', 'Unable to relocate', 'Unwilling to relocate', 'Support system', 'Colour of skin (racism)',
    'Lack of education']

  // jobBarriers2 = ['Budgeting/finance  ', 'Health issues', 'Relationship issues', 'Transgender', 'Community environment', 'Lack of skills  ',
  //   'Do not know how to find a job', 'Age', 'Bills/depts', 'Maintenance enforcement', 'Behavioral', , 'Transportation',
  //   'Education Level', 'Family issues', 'Mental/emotional issues', 'Unable to relocate', 'Unwilling to relocate', 'Support system', 'Colour of skin (racism)',
  //   'Lack of education']

  employmentHistories = ["Business Services", "Environmental", "Health care provider", "Construction / General Labour", "Sport & Recreation", "Service/Retail",
    "Tourism", "Social/Human Services", "Education/Training", "Transportation", "Hospitality", "Manufacturing", "Personal Service", "First Nations Governance",
    "Translator(Cree)", "Traditional", "Public Administration", "Oil & Gas Industry", "Trades", "Skilled Technician", "Professional", "Administration", "Engineering", "Surveyor", "Scientist", "Volunteer"];

  // employmentHistories2 = ["Engineering", "Surveyor", "Scientist", "Volunteer"];
  relocateSupports = ["Workshop - Ready t Work", "Transportation", "Family support sessions", "Life skills trainig", "Assistance with moving costs", "Start up allowance for housing"]
  workshops = ["Life Skills", "Mental Health Supports", "Emotional Support", "Workforce development(career planning and resume writing)", "None"]
  skillsList = ['Traping', 'Boat Building', 'Fishing(commercial or by net)', 'Traditional weather forecast', 'Hunter', 'Tracking wildlife', 'Axe handling',
    'Fish hook saftey', 'Knife Sharpening', 'Trap saftey', 'Trap saftey', 'Log home building']

  addOtherLang = false;
  addOtherCare = false
  addOtherEducation = false
  otherLicenseBarriers = false
  addOtherSafteyTraining = false

  addOtherNotification = false
  otherEmploymentStatus = false
  otherCommunityPicked = false
  otherLiveCommunityPicked = false
  addOtherHousing = false
  addOtherEmploymentGoal = false
  addOtherJobBarrier = false
  addOtherEmploymentHistory = false
  addOtherPreviousExpirience = false
  addOtherWorkshop = false
  addOtherRelocateSupport = false
  addOtherInfo = false
  addOtherSkill = false
  addOtherSupportGroup = false

  sections = {
    0: "introduction", 1: "aboutLrrcn", 2: "demographic", 3: "languages", 4: "careGiving", 5: "education", 6: "license", 7: "safetyTraining", 8: "trainingAndEmployment", 9: "jobSatisfaction",
    10: "barriersOfJob", 11: "employmentHistory", 12: "shiftWork", 13: "addictionsSupport", 14: "traditionalEmployment", 15: "signature"
  }

  model: LRRCNSurveyModel = new LRRCNSurveyModel()
  member: any;
  loading = false;
  HasApplicantSign = false

  applicantCanvas: any;
  clientCanvas: any;
  applicantSignaturePad: SignaturePad;
  clientSignaturePad: SignaturePad;
  filledBy: any;
  clientOnSight: boolean
  adminsList = []

  sendEmail = false;
  emailSent = false;
  dtOptions: any;
  nationofUse = environment.firstNation.name;
  filtredResult: any[];
  nationAdmins: any[];
  registredMembers: any[];


  public phoneNumberMask = ['(', /[0-9]/, /[0-9]/, /[0-9]/, ')',
    ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  public dateMask = [/[0-3]/, /[0-9]/, '/', /[0-1]/, /[0-9]/, '/', /[1-9]/, /[0-9]/, /[0-9]/, /[0-9]/];

  nationCommunities: any[];

  constructor(private fb: UntypedFormBuilder, private authService: AuthService,
    private surveyService: LRRCNSurveyService,
    private memberService: MemberService,
    private fireMembersService: FireMembersService,
    private location: Location,
    private router: Router,
    private emailService: EmailService) { }

  ngOnInit(): void {
    this.loading = true;

    if (this.authService.getGlobalUser()) {
      this.filledBy = this.authService.getGlobalUser().role
    }

    this.memberService.getMember(this.memberId).subscribe(x => {
      if (x && x.length > 0) {
        this.member = x[0]
        this.model.applicantID = this.memberId

        if (x != "No Result Found") {
          this.demographic.controls['firstName'].patchValue(this.member.GivenName);
          this.demographic.controls['lastName'].patchValue(this.member.LastName);
          this.demographic.controls['email'].patchValue(this.member.Email);
          this.demographic.controls['liveCommunity'].patchValue(this.member.Community);
          this.demographic.controls['fromCommunity'].patchValue(this.member.Community);
          this.demographic.controls['gender'].patchValue(this.member.Sex);

          if (this.member.Telephone !== null && this.member.Telephone !== '') {
            this.demographic.controls['phone'].patchValue(parseInt(this.member.Telephone));
          }

          let value = this.maritalStatuses.filter(status => status.value == this.member.MaritalStatus)
          if (value.length > 0) {
            this.demographic.controls['maritalStatus'].patchValue(value[0].name)
          }

          if (this.member.Community && !this.community.includes(this.member.Community)) {
            this.community.push(this.member.Community)
          }

          if (this.member.edu_level !== null) {
            let eduIndex = this.getEduLevel(this.member.edu_level)
            this.education.controls['highestLevelOfEdu'].patchValue(this.levelOfEducation[eduIndex])
          }

          this.trainingAndEmployment.controls['notifcationEmail'].patchValue(this.member.Email);
          // if (!this.community.includes(this.member.Community)){
          //   this.community.push(this.member.Community)
          // }

          let age = this.getAgeGroup(this.getAge(this.member.DOB))
          this.demographic.controls['age'].patchValue(age[0])
        }

        this.loading = false

        setTimeout(() => {
          M.updateTextFields();
          $('.datepicker').datepicker();
          $('.modal').modal();
        }, 25);
      }
      setTimeout(() => {
        $('.modal').modal();
        M.updateTextFields();
      }, 25);

      this.loading = false
    });

    if (this.filledBy === "Member") {
      this.fireMembersService.getAdminAccounts().valueChanges().subscribe(admins => {
        if (admins) {
          this.adminsList = admins
          this.lrrcnForm.get('admin').setValidators(Validators.required);
        }
      });
    }
    this.licenseTypesToObtain.push('None')
    this.currentUser = this.authService.getGlobalUser();

    this.lrrcnForm = this.fb.group({
      aboutLrrcn: this.fb.group({
        knowAboutLrrcn: ['', [Validators.required]],
        registered: ['', [Validators.required]],
        knowHowToUse: ['', [Validators.required]],
      }),
      demographic: this.fb.group({
        firstName: ['', [Validators.required]],
        lastName: ['', [Validators.required]],
        email: ['', [Validators.required, Validators.email]],
        phone: ['', [Validators.required]],
        clientCategory: ['', [Validators.required]],
        gender: ['', [Validators.required]],
        maritalStatus: ['', [Validators.required]],
        age: ['', [Validators.required]],
        fromCommunity: ['', [Validators.required]],
        liveCommunity: ['', [Validators.required]],
        housing: this.fb.array([], [Validators.required, notFalseValueValidator])
      }),

      languages: this.fb.array([this.newLanguage('English'), this.newLanguage('Cree'), this.newLanguage('Dene'), this.newLanguage('French')]),
      careGiving: this.fb.group({
        numberOfDependants: ['', [Validators.required]],
        careProvider: this.fb.array([], [Validators.required, notFalseValueValidator]),
        dayeCareAvailable: ['', [Validators.required]],
        availableCare: [''],
        typeOfCareAvailable: [''],
        needsCare: [''],
        neededCare: [''],
      }),

      education: this.fb.group({
        highestLevelOfEdu: ['', [Validators.required]],
        postSecondaryEdu: [''],
        levelOfEduToAchieve: this.fb.array([], [Validators.required, notFalseValueValidator]),
        areaOfStudyToAchieve: ['', [Validators.required]],
        currentlyEnrolled: ['', [Validators.required]],
        typeOfProgram: [''],
        upgradeEdu: [''],
        // whenToUpgradeEducation: [''],
        typeOfProgramToUpgrade: [''],
        whyNoUpgrade: [''],
        knowWhoToContactForUpgrade: ['', [Validators.required]],
        collegeApply: ['', [Validators.required]],
        applyForFunding: ['', [Validators.required]],
      }),

      license: this.fb.group({
        typeOfLicense: this.fb.array([], [Validators.required, notFalseValueValidator]),
        barrierForLicense: this.fb.array([], [Validators.required, notFalseValueValidator]),
        typeOfLicenseToObtain: this.fb.array([], [Validators.required, notFalseValueValidator]),
        beNotifiedForTraining: ['', [Validators.required]],
        howToBeNotified: [''],
      }),

      safetyTraining: this.fb.array([]),
      trainingAndEmployment: this.fb.group({
        notificationPreference: this.fb.array([], [Validators.required, notFalseValueValidator]),
        currentlyEmployed: ['', [Validators.required]],
        currentEmploymentComment: ['', [Validators.required]],
        employmentStatus: [''],
        notifcationEmail: ['', [Validators.required, Validators.email]],
        currentlySeekingEmployment: ['', [Validators.required]],
        reasonForSeekingEmployment: ['', [Validators.required]],
        employmentGoals: this.fb.array([]),
      }),

      jobSatisfaction: this.fb.group({
        satisfiedWithEmployment: ['', [Validators.required]],
        satisfiedReason: ['', [Validators.required]],
        factorsForSatisfaction: this.fb.array([], [Validators.required, notFalseValueValidator]),
        detailsForSatisfaction: ['', [Validators.required]],
      }),

      barriersOfJob: this.fb.group({
        covidAffected: ['', [Validators.required]],
        explanation: ['', [Validators.required]],
        barriers: this.fb.array([]),
        // secondBarriers: this.fb.array([]),
      }),
      employmentHistory: this.fb.group({
        noTrainingReason: [''],
        catagory: this.fb.array([]),
        secondCatagory: this.fb.array([]),
        previousExpirence: this.fb.array([]),
        // hasFirstRecentJob: this.fb.control(''),
        // hasThirdRecentJob: this.fb.control(''),
        firstRecentJob: this.fb.group({
          employer: ['', [Validators.required]],
          jobTitle: ['', [Validators.required]],
          mainDuties: ['', [Validators.required]],
          noRecentJob: ['',],
          startDateOfEmployment: ['', [Validators.required]],
          endDateOfEmployment: ['', [Validators.required]],
          stillWorkingThere: ['', [Validators.required]]
        },
          // { validator: allOrNoneValidator }
        ),
        secondRecentJob: this.fb.group({
          employer: ['', [Validators.required]],
          jobTitle: ['', [Validators.required]],
          mainDuties: ['', [Validators.required]],
          startDateOfEmployment: ['', [Validators.required]],
          noRecentJob: [''],
          endDateOfEmployment: ['', [Validators.required]],
          stillWorkingThere: ['', [Validators.required]]
        },
          // { validator: allOrNoneValidator }
        ),
        thirdRecentJob: this.fb.group({
          employer: ['', [Validators.required]],
          jobTitle: ['', [Validators.required]],
          mainDuties: ['', [Validators.required]],
          noRecentJob: [''],
          startDateOfEmployment: ['', [Validators.required]],
          endDateOfEmployment: ['', [Validators.required]],
          stillWorkingThere: ['', [Validators.required]]
        },
          // { validator: allOrNoneValidator }
        )
      }),

      shiftWork: this.fb.group({
        workAlone: ['', [Validators.required]],
        workAloneReason: ['', [Validators.required]],
        shiftWorkOutsideCommunity: ['', [Validators.required]],
        noReason: [''],
        willingToRelocate: ['', [Validators.required]],
        commuteForWork: ['', [Validators.required]],
        commuteLength: this.fb.group({
          length: [''],
          comments: ['']
        }),
        supportForRelocate: this.fb.array([]),
        workshopsInCommunity: this.fb.array([], [Validators.required, notFalseValueValidator]),
        localOrInCommunityWorkshop: ['', [Validators.required]],
        supportGroup: ['', [Validators.required]],
        typesOfSupportGroup: this.fb.array([]),
        careerAssessment: ['', [Validators.required]]
      }),
      addictionsSupport: this.fb.group({
        infoOnProgram: ['', [Validators.required]],
        infoNNADAP: this.fb.array([]),
        communicateOnBehalf: this.fb.group({
          value: ['', [Validators.required]],
          comments: ['', [Validators.required]]
        }),
      }),
      traditionalEmployment: this.fb.group({
        skills: this.fb.array([]),
        additionalComments: ['']
        // additionalComments: ['', [Validators.required]]
      }),
      // admin:this.fb.control('')
      admin: [''],
      clientOnSight: this.fb.control('')
    })

    this.housingAccommodation.map(house => this.housing.push(this.newHousing(house)))
    this.cares.map(care => this.careProvider.push(this.newCare(care)))

    this.educationToAchieve.map(edu => this.levelOfEduToAchieve.push(this.newEducation(edu)))
    this.licenseBarriers.map(barrier => this.barrierForLicense.push(this.newLicenseBarrier(barrier)))

    // this.licenseTypes.map(license => this.typeOfLicense.push(this.fb.control(license)))
    this.licenseTypes.map(license => this.typeOfLicense.push(this.newLicense(license)))
    this.licenseTypesToObtain.map(license => this.typeOfLicenseToObtain.push(this.newLicense(license)))

    this.safteyTrainings.map(training => this.safetyTraining.push(this.newTraining(training)))
    this.notificationMetdhods.map(method => this.notificationPreference.push(this.newNotificationMethod(method)))
    this.emptGoals.map(goal => this.employmentGoals.push(this.newEmploymentGoals(goal)))
    // this.emptGoals2.map(goal => this.secondEmploymentGoals.push(this.newEmploymentGoals(goal)))

    this.jobBarriers.map(barrier => this.barriers.push(this.newJobBarrier(barrier)))
    // this.jobBarriers2.map(barrier => this.secondBarriers.push(this.newJobBarrier(barrier)))

    this.employmentHistories.map(history => this.catagory.push(this.newEmploymentHistory(history)))
    // this.employmentHistories2.map(history => this.secondCatagory.push(this.newEmploymentHistory(history)))

    this.workExpirience.map(exp => this.previousExpirence.push(this.newExpirience(exp)))
    this.relocateSupports.map(support => this.supportForRelocate.push(this.newRelocateSuuport(support)))
    this.workshops.map(workshop => this.workshopsInCommunity.push(this.newWorkshop(workshop)))
    this.NNDAInfos.map(info => this.infoNNADAP.push(this.newInfo(info)))
    this.skillsList.map(skill => this.skills.push(this.newSkill(skill)))
    this.supportGroups.map(group => this.typesOfSupportGroup.push(this.newSupportGroup(group)))

    this.satisfactionFactors.map(factor => this.factorsForSatisfaction.push(this.newFactor(factor)))
    // this.jobBarriers2.map(barrier=>this.secondBarriers.push(this.newJobBarrier(barrier)))

    // this.emptGoals.map(goal=>this.employmentGoals.push(new FormGroup({
    //   goal: new FormControl(goal),
    //   value: new FormControl(false)
    // })))

    setTimeout(() => {
      $('.modal').modal();
      $('select').formSelect();
      $('.datepicker').datepicker();
      M.updateTextFields();
    }, 25);
  }

  get aboutLrrcn() { { return this.lrrcnForm.get('aboutLrrcn') as UntypedFormGroup; } }
  get demographic() { return this.lrrcnForm.get('demographic') as UntypedFormGroup; }
  get firstName() { return (<UntypedFormGroup>this.lrrcnForm.get('demographic')).get('firstName') }
  get lastName() { return (<UntypedFormGroup>this.lrrcnForm.get('demographic')).get('lastName') }
  get email() { return (<UntypedFormGroup>this.lrrcnForm.get('demographic')).get('email') }
  get phone() { return (<UntypedFormGroup>this.lrrcnForm.get('demographic')).get('phone') }

  get housing() {
    return (<UntypedFormGroup>this.lrrcnForm.get('demographic')).get('housing') as UntypedFormArray
  }
  get languages() { return this.lrrcnForm.get('languages') as UntypedFormArray; }

  get literacyLevel() {
    return (<UntypedFormGroup>this.lrrcnForm.get('languages')).get('literacyLevel') as UntypedFormArray
  }
  get careGiving() { return this.lrrcnForm.get('careGiving') as UntypedFormGroup; }

  get careProvider() {
    return (<UntypedFormGroup>this.lrrcnForm.get('careGiving')).get('careProvider') as UntypedFormArray
  }
  get education() { { return this.lrrcnForm.get('education') as UntypedFormGroup; } }
  get levelOfEduToAchieve() {
    return (<UntypedFormGroup>this.lrrcnForm.get('education')).get('levelOfEduToAchieve') as UntypedFormArray
  }
  get license() { { return this.lrrcnForm.get('license') as UntypedFormGroup; } }
  get typeOfLicense() {
    return (<UntypedFormGroup>this.lrrcnForm.get('license')).get('typeOfLicense') as UntypedFormArray
  }
  get typeOfLicenseToObtain() {
    return (<UntypedFormGroup>this.lrrcnForm.get('license')).get('typeOfLicenseToObtain') as UntypedFormArray
  }
  get barrierForLicense() {
    return (<UntypedFormGroup>this.lrrcnForm.get('license')).get('barrierForLicense') as UntypedFormArray
  }

  get safetyTraining() { { return this.lrrcnForm.get('safetyTraining') as UntypedFormArray; } }
  get dateValue() {
    return (<UntypedFormArray>this.lrrcnForm.get('safetyTraining')).get('dateValue') as UntypedFormGroup
  }

  get trainingAndEmployment() { { return this.lrrcnForm.get('trainingAndEmployment') as UntypedFormGroup; } }
  get notificationPreference() {
    return (<UntypedFormGroup>this.lrrcnForm.get('trainingAndEmployment')).get('notificationPreference') as UntypedFormArray
  }

  get notifcationEmail() { return (<UntypedFormGroup>this.lrrcnForm.get('trainingAndEmployment')).get('notifcationEmail') }

  get employmentGoals() {
    return (<UntypedFormGroup>this.lrrcnForm.get('trainingAndEmployment')).get('employmentGoals') as UntypedFormArray
  }
  // get secondEmploymentGoals() {
  //   return (<FormGroup>this.lrrcnForm.get('trainingAndEmployment')).get('secondEmploymentGoals') as FormArray
  // }
  get jobSatisfaction() { { return this.lrrcnForm.get('jobSatisfaction') as UntypedFormGroup; } }
  // get trainingAnjobSatisfactiondEmployment() { { return this.lrrcnForm.get('jobSatisfaction') as FormGroup; } }
  get factorsForSatisfaction() {
    return (<UntypedFormGroup>this.lrrcnForm.get('jobSatisfaction')).get('factorsForSatisfaction') as UntypedFormArray
  }

  get barriersOfJob() { { return this.lrrcnForm.get('barriersOfJob') as UntypedFormGroup; } }
  get barriers() {
    return (<UntypedFormGroup>this.lrrcnForm.get('barriersOfJob')).get('barriers') as UntypedFormArray
  }
  // get secondBarriers() {
  //   return (<FormGroup>this.lrrcnForm.get('barriersOfJob')).get('secondBarriers') as FormArray
  // }

  get employmentHistory() { { return this.lrrcnForm.get('employmentHistory') as UntypedFormGroup; } }
  get catagory() {
    return (<UntypedFormGroup>this.lrrcnForm.get('employmentHistory')).get('catagory') as UntypedFormArray
  }
  // get secondCatagory() {
  //   return (<FormGroup>this.lrrcnForm.get('employmentHistory')).get('secondCatagory') as FormArray
  // }
  get previousExpirence() {
    return (<UntypedFormGroup>this.lrrcnForm.get('employmentHistory')).get('previousExpirence') as UntypedFormArray
  }

  get shiftWork() { { return this.lrrcnForm.get('shiftWork') as UntypedFormGroup; } }
  get supportForRelocate() {
    return (<UntypedFormGroup>this.lrrcnForm.get('shiftWork')).get('supportForRelocate') as UntypedFormArray
  }
  get workshopsInCommunity() {
    return (<UntypedFormGroup>this.lrrcnForm.get('shiftWork')).get('workshopsInCommunity') as UntypedFormArray
  }
  get typesOfSupportGroup() {
    return (<UntypedFormGroup>this.lrrcnForm.get('shiftWork')).get('typesOfSupportGroup') as UntypedFormArray
  }

  get addictionsSupport() { { return this.lrrcnForm.get('addictionsSupport') as UntypedFormGroup; } }

  get infoNNADAP() {
    return (<UntypedFormGroup>this.lrrcnForm.get('addictionsSupport')).get('infoNNADAP') as UntypedFormArray
  }

  get traditionalEmployment() { { return this.lrrcnForm.get('traditionalEmployment') as UntypedFormGroup; } }
  get skills() {
    return (<UntypedFormGroup>this.lrrcnForm.get('traditionalEmployment')).get('skills') as UntypedFormArray
  }

  getEduLevel(edu) {
    let index
    switch (edu) {
      case 0:
        index = 0;
        break
      case 1:
        index = 0;
        break
      case 2:
        index = 1 //or 2
        break
      case 3:
        index = 3 //or 4
        break
      case 4:
        index = 5 //or 4
        break
      case 5:
        index = 6//maybe
        break
      case 6: //apprentenship
        index = 8 //or
        break
      case 7: //cllege
        index = 6 //some college
        break
      case 8:
        index = 12//cgep to other
        break
      case 9:
        index = 7
        break
      case 10:
        index = 10
        break
      case 11:
        index = 11
        break
      case 12:
        index = 11
        break
      case 13:
        index = 12
        break
      case 14:
        index = 12
        break
    }
    return index
  }

  getAge(date) {
    var today = new Date();
    var birthDate = new Date(date);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  }

  getAgeGroup(age) {
    return this.ageGroup.filter(group => {
      let x = group.split('-')
      if ((parseInt(x[0]) <= age) && (age <= parseInt(x[1]))) {
        return group
      }
    });
  }

  scrollToQuestion(id) {
    document.getElementById(id).scrollIntoView({ behavior: 'smooth', block: "center" })
  }

  ApplicantSign() {
    this.HasApplicantSign = true;

    setTimeout(() => {
      this.applicantCanvas = document.querySelector('canvas');
      const parentWidth = $(this.applicantCanvas).parent().outerWidth();
      this.applicantCanvas.setAttribute('width', parentWidth - 50);
      this.applicantSignaturePad = new SignaturePad(this.applicantCanvas, {
        backgroundColor: 'rgb(255,255,255)'
      });
    }, 50);
  }

  clientSign() {
    setTimeout(() => {
      this.clientCanvas = document.querySelector('canvas');
      const parentWidth = $(this.clientCanvas).parent().outerWidth();
      this.clientCanvas.setAttribute('width', parentWidth - 50);
      this.clientSignaturePad = new SignaturePad(this.clientCanvas, {
        backgroundColor: 'rgb(255,255,255)'
      });
    }, 50);
  }

  clientSignNow(signNow) {
    if (signNow) {
      this.clientOnSight = true

      setTimeout(() => {
        this.clientCanvas = document.querySelector('#client');
        const parentWidth = $(this.clientCanvas).parent().outerWidth();
        this.clientCanvas.setAttribute('width', parentWidth - 1);
        // this.clientCanvas.setAttribute('width', parentWidth - 50);
        this.clientSignaturePad = new SignaturePad(this.clientCanvas, {
          backgroundColor: 'rgb(255,255,255)'
        });
        $('#clientSignModal').modal('open')
      }, 50);
    }
    else {
      this.model.applicantSignature = ''
      this.model.applicantDate = ''
      this.clientOnSight = false

      this.lrrcnForm.get('clientOnSight').patchValue('No')
      this.lrrcnForm.get('clientOnSight').updateValueAndValidity()
    }
  }

  clearSignatureOfApplicant() {
    this.applicantSignaturePad.clear();
  }

  clearSignatureOfClient() {
    this.clientSignaturePad.clear();
  }

  saveClientSignature() {
    this.model.applicantSignature = this.clientSignaturePad.toDataURL()
    this.model.applicantDate = new Date()

    $('.modal').modal('close')
  }

  openDatePicker(index, expiryDate) {
    let id = '#datePicker_' + index
    if (expiryDate) {
      this.safetyTraining.at(index).get('dateValue').get('value').patchValue('Expired')

      setTimeout(() => {
        $(id).datepicker(
          {
            format: 'dd/mmm/yyyy',
            // defaultDate: new Date(),
            setDefaultDate: new Date(),
            yearRange: 5,
            minDate: new Date(new Date().getFullYear() - 5, 0, 1),
            maxDate: new Date(),
          }
        )
        $(id).datepicker('open')
        M.updateTextFields();
      }, 25);
    } else {
      this.safetyTraining.at(index).get('dateValue').get('date').patchValue(null)
    }
    this.safetyTraining.at(index).updateValueAndValidity()
    let scrollId = index > 0 ? 'training_' + (index - 1) : 'training_' + index
    this.scrollToQuestion(scrollId)
  }

  onDateChange(event, index) {
    let date = event.target.value
    this.safetyTraining.at(index).get('dateValue').get('date').patchValue(date)
    this.safetyTraining.at(index).updateValueAndValidity()
  }

  expiredLicense(index) {
    return false
  }

  onCheckChange(event) {
    if (event.target.checked) {
      // Add a new control in the arrayForm
      this.housing.push(new UntypedFormControl(event.target.value));
    }
    /* unselected */
    else {
      // find the unselected element
      let i: number = 0;

      this.housing.controls.forEach((ctrl: UntypedFormControl) => {
        if (ctrl.value == event.target.value) {
          // Remove the unselected element from the arrayForm
          this.housing.removeAt(i);
          return;
        }
        i++;
      });
    }
  }

  changeFromCommunity(name) {
    this.otherCommunityPicked = name === 'Other' ? true : false
    setTimeout(() => {
      M.updateTextFields();
    }, 25);
  }

  changeLiveCommunity(name) {
    this.otherLiveCommunityPicked = name === 'Other' ? true : false
    setTimeout(() => {
      M.updateTextFields();
    }, 25);
  }
  otherCommunity() {
    return this.community
  }

  addCareProvider(event, value) {
    if (event.target.checked) {
      this.careProvider.push(this.fb.control(value))
    } else {
      let index = this.careProvider.value.indexOf(value)
      this.careProvider.removeAt(index)
    }
  }

  addEducation(event, value) {
    let last_index = this.educationToAchieve.length - 1
    if (value == this.educationToAchieve[last_index]) {
      this.addOtherEducation = event.target.checked ? true : false
    } else {
      if (event.target.checked) {
        this.levelOfEduToAchieve.push(this.fb.control(value))
      } else {
        let index = this.levelOfEduToAchieve.value.indexOf(value)
        this.levelOfEduToAchieve.removeAt(index)
      }
    }
  }

  addCurrentLicense(event, value) {
    if (event.target.checked) {
      this.typeOfLicense.push(this.fb.control(value))
    } else {
      let index = this.typeOfLicense.value.indexOf(value)
      this.typeOfLicense.removeAt(index)
    }
  }

  addLicenseToObtain(event, value) {
    if (event.target.checked) {
      this.typeOfLicenseToObtain.push(this.fb.control(value))
    } else {
      let index = this.typeOfLicenseToObtain.value.indexOf(value)
      this.typeOfLicenseToObtain.removeAt(index)
    }
  }

  addBarrierForLicense(event, value) {
    if (event.target.checked) {
      this.barrierForLicense.push(this.fb.control(value))
    } else {
      let index = this.barrierForLicense.value.indexOf(value)
      this.barrierForLicense.removeAt(index)
    }
  }

  addPreferedNotificationPreference(event, value) {
    // if(event.target.checked){
    //   this.notificationPreference.push(this.fb.control(value))
    // }else{
    //   let index = this.notificationPreference.value.indexOf(value)
    //   this.notificationPreference.removeAt(index)
    // }
  }

  addNewHousing(event, value) {
    if (event.target.checked) {
      this.housing.push(this.newHousing(value, true))
    } else {
      let index = this.housing.value.findIndex(item => item.factor === value)
      this.housing.removeAt(index)
    }
  }

  addSatisfactionFactor(event, value) {
    if (event.target.checked) {
      this.factorsForSatisfaction.push(this.fb.group({
        factor: value,
        detail: ['']
      }))
    } else {
      let index = this.factorsForSatisfaction.value.findIndex(item => item.factor === value)
      this.factorsForSatisfaction.removeAt(index)
    }
  }

  factorIncludes(factor) {
    return this.factorsForSatisfaction.value.filter(function (e) { return e.factor === factor; }).length > 0
  }

  changeFactorDetail(event, factor) {
    let index = this.factorsForSatisfaction.value.findIndex(item => item.factor === factor)
    let x = this.factorsForSatisfaction.at(index).patchValue({ detail: event.target.value })
  }

  changeDayCareAvailability(event) {
    if (event.target.value === "No") {
      this.careGiving.controls['typeOfCareAvailable'].patchValue('');
      this.careGiving.controls['typeOfCareAvailable'].clearValidators();
      this.careGiving.controls['needsCare'].setValidators(Validators.required)
      // this.careGiving.controls['neededCare'].setValidators(Validators.required)
      // this.scrollToQuestion('needsCare')
    } else if (event.target.value === "Yes") {
      this.careGiving.controls['needsCare'].patchValue('');
      this.careGiving.controls['needsCare'].clearValidators();
      // this.careGiving.controls['neededCare'].patchValue('');
      // this.careGiving.controls['neededCare'].clearValidators();
      this.careGiving.controls['typeOfCareAvailable'].setValidators(Validators.required)
      // this.scrollToQuestion('typeOfCareAvailable')
    }
    this.careGiving.controls['needsCare'].updateValueAndValidity()
    // this.careGiving.controls['neededCare'].updateValueAndValidity()
    this.careGiving.controls['typeOfCareAvailable'].updateValueAndValidity()
    this.dayCareAvailable = event.target.value === "No" ? false : true
    this.scrollToQuestion('dayeCareAvailable')
  }

  changeDayCareNeed(event) {
    if (event.target.value === "No") {
      this.careGiving.controls['neededCare'].patchValue('');
      this.careGiving.controls['neededCare'].clearValidators();
    } else if (event.target.value === "Yes") {
      this.careGiving.controls['neededCare'].setValidators(Validators.required)
      // this.scrollToQuestion('neededCare')
      // setTimeout(()=>{
      // this.scrollToQuestion('neededCare')
      // },200)
    }
    this.careGiving.controls['neededCare'].updateValueAndValidity()
    this.scrollToQuestion('needsCare')
  }

  updateWorkCommute(event) {
    if (event.target.value === "No") {
      this.shiftWork.get('commuteLength').get('length').patchValue('')
      this.shiftWork.get('commuteLength').get('length').clearValidators()
      this.shiftWork.get('commuteLength').get('comments').patchValue('')
      this.shiftWork.get('commuteLength').get('comments').clearValidators()
      this.shiftWork.controls['commuteLength'].clearValidators()
      if (this.shiftWork.controls['willingToRelocate'].value === "Yes") {
        this.supportForRelocate.setValidators([Validators.required, notFalseValueValidator])
      } else {
        this.supportForRelocate.clearValidators()
      }
    } else if (event.target.value === "Yes") {
      this.shiftWork.controls['commuteLength'].setValidators(Validators.required)
      this.shiftWork.get('commuteLength').get('length').setValidators([Validators.required])
      this.shiftWork.get('commuteLength').get('comments').setValidators([Validators.required])
      this.supportForRelocate.setValidators([Validators.required, notFalseValueValidator])
    }
    this.shiftWork.controls['commuteLength'].updateValueAndValidity()
    this.shiftWork.get('commuteLength').get('comments').updateValueAndValidity()
    this.shiftWork.get('commuteLength').get('length').updateValueAndValidity()
    this.supportForRelocate.updateValueAndValidity()
    this.scrollToQuestion('commuteForWork')
  }

  updateWillingTRelocate(event) {
    if (event.target.value === "No") {
      if (this.shiftWork.controls['commuteForWork'].value === "Yes") {
        this.supportForRelocate.setValidators([Validators.required, notFalseValueValidator])
      } else {
        this.supportForRelocate.clearValidators()
      }
    } else if (event.target.value === "Yes") {
      this.supportForRelocate.setValidators([Validators.required, notFalseValueValidator])
    }
    this.supportForRelocate.updateValueAndValidity()
    this.scrollToQuestion('commuteForWork')
  }

  // updateWillingTRelocate(event){
  //   if (event.target.value === "No") {
  //     this.typesOfSupportGroup.clearValidators()
  //   } else if (event.target.value === "Yes"){
  //     this.typesOfSupportGroup.setValidators([Validators.required,notFalseValueValidator])
  //   }
  //   this.typesOfSupportGroup.updateValueAndValidity()
  // }

  changeEducationEnrollment(event) {
    if (event.target.value === "No") {
      this.education.controls['typeOfProgram'].patchValue('');
      this.education.controls['typeOfProgram'].clearValidators();
      this.education.controls['typeOfProgram'].updateValueAndValidity();
      this.education.controls['upgradeEdu'].setValidators(Validators.required)
    } else {
      this.education.controls['upgradeEdu'].patchValue('');
      this.education.controls['upgradeEdu'].clearValidators();
      this.education.controls['upgradeEdu'].updateValueAndValidity();

      // this.education.controls['whenToUpgradeEducation'].patchValue('');
      // this.education.controls['whenToUpgradeEducation'].clearValidators();
      // this.education.controls['whenToUpgradeEducation'].updateValueAndValidity();

      this.education.controls['whyNoUpgrade'].patchValue('');
      this.education.controls['whyNoUpgrade'].clearValidators();
      this.education.controls['whyNoUpgrade'].updateValueAndValidity();

      this.education.controls['typeOfProgramToUpgrade'].patchValue('');
      this.education.controls['typeOfProgramToUpgrade'].clearValidators();
      this.education.controls['typeOfProgramToUpgrade'].updateValueAndValidity();

      this.education.controls['typeOfProgram'].setValidators(Validators.required)
      // this.scrollToQuestion("typeOfProgram")  
    }
    this.scrollToQuestion("currentlyEnrolled")
  }

  upgradeEducation(event) {
    if (event.target.value === "No") {
      // this.education.controls['whenToUpgradeEducation'].patchValue('');
      // this.education.controls['whenToUpgradeEducation'].clearValidators();
      // this.education.controls['whenToUpgradeEducation'].updateValueAndValidity();
      this.education.controls['typeOfProgramToUpgrade'].patchValue('');
      this.education.controls['typeOfProgramToUpgrade'].clearValidators();
      this.education.controls['typeOfProgramToUpgrade'].updateValueAndValidity();
      this.education.controls['whyNoUpgrade'].setValidators(Validators.required)
    } else {
      this.education.controls['whyNoUpgrade'].patchValue('');
      this.education.controls['whyNoUpgrade'].clearValidators();
      this.education.controls['whyNoUpgrade'].updateValueAndValidity();
      // this.education.controls['whenToUpgradeEducation'].setValidators(Validators.required)
      // this.education.controls['whenToUpgradeEducation'].updateValueAndValidity();
      this.education.controls['typeOfProgramToUpgrade'].setValidators(Validators.required)
      this.education.controls['typeOfProgramToUpgrade'].updateValueAndValidity();
    }
    this.scrollToQuestion("upgradeEdu")
  }

  changebeNotifiedForTraining(event) {
    if (event.target.value === "No") {
      this.license.controls['howToBeNotified'].patchValue('');
      this.license.controls['howToBeNotified'].clearValidators();
      // this.license.controls['howToBeNotified'].updateValueAndValidity();
      // this.education.controls['whyNoUpgrade'].setValidators(Validators.required)
    } else {
      this.license.controls['howToBeNotified'].setValidators(Validators.required)
    }
    this.license.controls['howToBeNotified'].updateValueAndValidity();
  }

  changeEmployment(event) {
    if (event.target.value !== "Employed") {
      this.trainingAndEmployment.controls['employmentStatus'].patchValue('');
      this.trainingAndEmployment.controls['employmentStatus'].clearValidators();
      this.trainingAndEmployment.controls['employmentStatus'].updateValueAndValidity();
    } else {
      this.trainingAndEmployment.controls['employmentStatus'].setValidators(Validators.required);
    }
    this.trainingAndEmployment.controls['employmentStatus'].updateValueAndValidity();
    // else{
    //   this.education.controls['upgradeEdu'].patchValue('');
    //   this.education.controls['whenToUpgradeEducation'].patchValue('');
    //   this.education.controls['whyNoUpgrade'].patchValue('');
    //   this.education.controls['typeOfProgramToUpgrade'].patchValue('');
    // }
  }

  // changeSeekingEmployment(event){
  //   if (event.target.value === "Yes") {
  //     this.trainingAndEmployment.controls['reasonForSeekingEmployment'].setValidators([Validators.required]);
  //     this.trainingAndEmployment.controls['reasonForSeekingEmployment'].updateValueAndValidity();
  //   }else{
  //     this.trainingAndEmployment.controls['reasonForSeekingEmployment'].patchValue('');
  //     this.trainingAndEmployment.controls['reasonForSeekingEmployment'].clearValidators();
  //     this.trainingAndEmployment.controls['reasonForSeekingEmployment'].updateValueAndValidity();
  //   }
  // }

  updateInfoaAboutLrrcn(event) {
    console.log(event.target.value, 'the targer')
    if (event.target.value === "No") {
      this.aboutLrrcn.controls['registered'].patchValue('');
      this.aboutLrrcn.controls['registered'].clearValidators();
    } else {
      this.aboutLrrcn.controls['registered'].setValidators(Validators.required);
    }
    this.aboutLrrcn.controls['registered'].updateValueAndValidity();
    // this.scrollToQuestion('workOutsideCommunity')
  }

  updateWorkAlone(event) {
    console.log(event.target.value, 'the targer')
    if (event.target.value === "No") {
      this.shiftWork.controls['workAloneReason'].patchValue('');
      this.shiftWork.controls['workAloneReason'].clearValidators();
    } else {
      this.shiftWork.controls['workAloneReason'].setValidators(Validators.required);
    }
    this.shiftWork.controls['workAloneReason'].updateValueAndValidity();
    this.scrollToQuestion('workOutsideCommunity')
  }

  updateShiftWork(event) {
    if (event.target.value === "Yes") {
      this.shiftWork.controls['noReason'].patchValue('');
      this.shiftWork.controls['noReason'].clearValidators();
    } else {
      this.shiftWork.controls['noReason'].setValidators(Validators.required);
    }
    this.shiftWork.controls['noReason'].updateValueAndValidity();
    this.scrollToQuestion('willingToRelocate')
  }

  pickedEmploymentStatus(event, status) {
    let index = this.employmentStatuses.length - 1
    if (status === this.employmentStatuses[index]) {
      this.otherEmploymentStatus = true
      this.trainingAndEmployment.controls['employmentStatus'].patchValue('');
    } else {
      this.otherEmploymentStatus = false
    }
    setTimeout(() => {
      M.updateTextFields();
    }, 25);
  }

  getLanguages() {
    let languages = [];
    for (let lang in this.languages.controls) {
      languages.push(lang);
    }
    return languages;
  }

  addLanguage(language) {
    const languages = this.languages;
    languages.push(this.newLanguage(language));
  }

  newHousing(housing, value = false) {
    return this.fb.group({
      housing: [housing, [Validators.required]],
      value: [value, [Validators.required]],
      // value: [value,[Validators.required,validateValue]],
    });
  }

  newFactor(factor, value = false) {
    return this.fb.group({
      factor: [factor],
      value: [value],
      detail: [''],
    })
  }

  newLanguage(language) {
    if (language === "Cree") {
      return this.fb.group({
        language: [language],
        speak: [false,],
        write: [false,],
        understand: [false],
        // literacyLevel:[''],
        literacyLevel: this.fb.group({
          speak: [{ value: '', disabled: true }],
          write: [{ value: '', disabled: true }]
        }),
        learnToSpeak: ['']
      })
    } else { }

    return this.fb.group({
      language: [language],
      speak: [false, [Validators.required]],
      write: [false, [Validators.required]],
      understand: [false, [Validators.required]],
      // literacyLevel:[''],
      literacyLevel: this.fb.group({
        speak: [{ value: '', disabled: true }],
        write: [{ value: '', disabled: true }]
      }),
      learnToSpeak: ['', [Validators.required]]
    })
  }

  changeLanguage(event, index, target) {
    if (event.target.checked) {
      this.languages.at(index).get('literacyLevel').get(target).setValidators(Validators.required)
      this.languages.at(index).get('literacyLevel').get(target).enable()
    } else {
      this.languages.at(index).get('literacyLevel').get(target).patchValue('')
      this.languages.at(index).get('literacyLevel').get(target).clearValidators()
      this.languages.at(index).get('literacyLevel').get(target).disable()
    }
    this.languages.at(index).get('literacyLevel').get(target).updateValueAndValidity()
    setTimeout(() => {
      M.updateTextFields();
      ($('select') as any).formSelect();
    }, 25);
    let scrollId = index > 0 ? 'language_' + (index - 1) : 'language_' + index
    this.scrollToQuestion(scrollId)
  }

  scroll(index, id) {
    // let scrollId =  index == this.languages.length -1  ?  'literacy' : 'language_' + index
    let scrollId = index > 0 ? `${id}_${index - 1}` : `${id}_${index}`
    this.scrollToQuestion(scrollId)
  }

  changeLiteracy(event, index) {
    let scrollId = index > 0 ? 'literacy_' + (index - 1) : 'literacy_' + index
    this.scrollToQuestion(scrollId)
  }

  changeCareProvider(event, index) {
    this.updateCheckBoxes(event, index, "care", this.careProvider, "No - not a care proivder")
  }

  changeEduToAchieve(event, index) {
    this.updateCheckBoxes(event, index, "education", this.levelOfEduToAchieve, "No Education")
    let scrollId = 'levelOfEduToAchieve'
    // let scrollId =  index > 0 ? 'levelOfEduToAchieve_' + (index -1) : 'levelOfEduToAchieve_' + index
    this.scrollToQuestion(scrollId)
  }

  changeCurrentLicense(event, index) {
    this.updateCheckBoxes(event, index, "license", this.typeOfLicense, 'No driver\'s license (never held one)')
  }

  changeLicenseToObtain(event, index) {
    this.updateCheckBoxes(event, index, "license", this.typeOfLicenseToObtain, 'None')

  }
  
  changeNotificationPreference(event, index) {
    this.updateCheckBoxes(event, index, "method", this.notificationPreference, "None")
  }

  changeWorkshop(event, index) {
    this.updateCheckBoxes(event, index, "workshop", this.workshopsInCommunity, "None")
  }

  changeFactor(event, index) {
    if (event.target.checked) {
      if (this.satisfactionFactors.slice(0, 4).includes(this.factorsForSatisfaction.at(index).get('factor').value)) {
        this.factorsForSatisfaction.at(index).get('detail').setValidators(Validators.required)
        this.factorsForSatisfaction.at(index).get('detail').updateValueAndValidity()
      }
    } else {
      if (this.satisfactionFactors.slice(0, 4).includes(this.factorsForSatisfaction.at(index).get('factor').value)) {
        this.factorsForSatisfaction.at(index).get('detail').clearValidators()
        this.factorsForSatisfaction.at(index).get('detail').patchValue('')
        this.factorsForSatisfaction.at(index).get('detail').updateValueAndValidity()
      }
    }
    this.updateCheckBoxes(event, index, "factor", this.factorsForSatisfaction, "None")

    let scrollId = index > 0 ? 'factorsForSatisfaction_' + (index - 1) : 'factorsForSatisfaction_' + index
    this.scrollToQuestion(scrollId)
  }

  changePreviousJob(event, target) {
    let value = true ? event.target.checked : false
    switch (target) {
      case 'firstRecentJob':
        this.updateJobExpirience('secondRecentJob', value)
        this.updateJobExpirience('thirdRecentJob', value)

        this.employmentHistory.get('secondRecentJob').get('noRecentJob').patchValue(value)
        this.employmentHistory.get('thirdRecentJob').get('noRecentJob').patchValue(value)
      // if (value === false) this.scrollToQuestion('firstRecentJobEmployer')
      case 'secondRecentJob':
        this.updateJobExpirience('thirdRecentJob', value)
        this.employmentHistory.get('thirdRecentJob').get('noRecentJob').patchValue(value)
      default:
        this.updateJobExpirience(target, value)
    }
  }

  updateJobExpirience(target, enable) {
    if (enable) {
      // this.employmentHistory.get(target).clearValidators()
      this.employmentHistory.get(target).get('employer').clearValidators()
      this.employmentHistory.get(target).get('jobTitle').clearValidators()
      this.employmentHistory.get(target).get('mainDuties').clearValidators()
      this.employmentHistory.get(target).get('startDateOfEmployment').clearValidators()
      this.employmentHistory.get(target).get('endDateOfEmployment').clearValidators()
      this.employmentHistory.get(target).get('stillWorkingThere').clearValidators()

      // this.employmentHistory.get(target).disable()
      // this.employmentHistory.get(target).get('employer').disable()
      // this.employmentHistory.get(target).get('jobTitle').disable()
      // this.employmentHistory.get(target).get('mainDuties').disable()
      // this.employmentHistory.get(target).get('startDateOfEmployment').disable()
      // this.employmentHistory.get(target).get('endDateOfEmployment').disable()
      // this.employmentHistory.get(target).get('stillWorkingThere').disable()

      this.employmentHistory.get(target).get('employer').patchValue('')
      this.employmentHistory.get(target).get('jobTitle').patchValue('')
      this.employmentHistory.get(target).get('mainDuties').patchValue('')
      this.employmentHistory.get(target).get('startDateOfEmployment').patchValue('')
      this.employmentHistory.get(target).get('endDateOfEmployment').patchValue('')
      this.employmentHistory.get(target).get('stillWorkingThere').patchValue('')
    } else {
      // this.employmentHistory.get(target).setValidators(Validators.required)
      this.employmentHistory.get(target).get('employer').setValidators(Validators.required)
      this.employmentHistory.get(target).get('jobTitle').setValidators(Validators.required)
      this.employmentHistory.get(target).get('mainDuties').setValidators(Validators.required)
      this.employmentHistory.get(target).get('startDateOfEmployment').setValidators(Validators.required)
      this.employmentHistory.get(target).get('endDateOfEmployment').setValidators(Validators.required)
      this.employmentHistory.get(target).get('stillWorkingThere').setValidators(Validators.required)

      // this.employmentHistory.get(target).enable()
      this.employmentHistory.get(target).get('employer').enable()
      this.employmentHistory.get(target).get('jobTitle').enable()
      this.employmentHistory.get(target).get('mainDuties').enable()
      this.employmentHistory.get(target).get('startDateOfEmployment').enable()
      this.employmentHistory.get(target).get('endDateOfEmployment').enable()
      this.employmentHistory.get(target).get('stillWorkingThere').enable()
    }
    // this.employmentHistory.get(target).updateValueAndValidity()
    this.employmentHistory.get(target).get('employer').updateValueAndValidity()
    this.employmentHistory.get(target).get('jobTitle').updateValueAndValidity()
    this.employmentHistory.get(target).get('mainDuties').updateValueAndValidity()
    this.employmentHistory.get(target).get('startDateOfEmployment').updateValueAndValidity()
    this.employmentHistory.get(target).get('endDateOfEmployment').updateValueAndValidity()
    this.employmentHistory.get(target).get('stillWorkingThere').updateValueAndValidity()
  }

  updateCheckBoxes(event, index, name, array, checkParameter) {
    if (array.at(index).get(name).value === checkParameter) {
      if (event.target.checked) {
        array.controls.forEach(control => {
          if (control.get(name).value !== checkParameter) {
            control.disable()
            control.get('value').patchValue(false)
          }
        });
      } else {
        array.controls.forEach(control => {
          control.enable()
        })
      }
    } else {
      if (event.target.checked) {
        let i = array.value.findIndex((item) => item[name] === checkParameter)
        array.at(i).get('value').patchValue(false)
        array.at(i).get('value').disable()
      } else {
        let noCareClicked = false
        let noIndex;
        array.at(index).get('value').setValue(false)
        array.controls.forEach((control, li) => {
          if (control.get('value').value === true) noCareClicked = true
          if (control.get(name).value === checkParameter) noIndex = li
        });
        if (noCareClicked === false) array.at(noIndex).get('value').enable()
      }
    }
  }

  newCare(care, value = false) {
    return this.fb.group({
      care: [care],
      value: [value]
    })
  }

  newLicenseBarrier(barrier, value = false) {
    return this.fb.group({
      barrier: [barrier],
      value: [value]
    });
  }

  newLicense(license, value = false) {
    return this.fb.group({
      license: [license],
      value: [value]
    });
  }

  newEducation(edu, value = false) {
    return this.fb.group({
      education: [edu],
      value: [value]
    });
  }

  newJobBarrier(barrier, value = null) {
    return this.fb.group({
      barrier: [barrier],
      value: [value]
      // value: [value, [Validators.required, validateValue]]
    })
  }

  newEmploymentHistory(history, value = null) {
    return this.fb.group({
      history: [history],
      value: [value]
      // value: [value, [Validators.required, validateValue]]
    })
  }

  newNotificationMethod(notif, value = false) {
    return this.fb.group({
      method: [notif],
      value: [value]
    })
  }

  newTraining(training, value = null) {
    return this.fb.group({
      training: [training],
      dateValue: this.fb.group({
        value: [value],
        // value: [value, [Validators.required, validateValue]],
        date: []
      })
      // value:[value]
      // currentlyValid: [false],
      // wouldLikeTraining: [false],
      // expiryDate:[false],
    });
  }

  newEmploymentGoals(goal, value = null) {
    return this.fb.group({
      goal: [goal],
      value: [value]
      // value: ['', [Validators.required, validateValue]]
    });
  }

  newExpirience(exp, value = null) {
    return this.fb.group({
      expirience: [exp],
      value: [null]
      // value: ['', [Validators.required, validateValue]]
    });
  }

  newSupportGroup(group, value = false) {
    return this.fb.group({
      group: [group],
      value: [value]
    });
  }

  newRelocateSuuport(support, value = null) {
    return this.fb.group({
      support: [support],
      value: [value]
    });
  }

  newWorkshop(workshop, value = false) {
    return this.fb.group({
      workshop: [workshop],
      value: [value]
    });
  }

  newInfo(info, value = false) {
    return this.fb.group({
      info: [info],
      value: [value]
    })
  }

  newSkill(skill, value = null) {
    return this.fb.group({
      skill: [skill],
      value: [value]
      // value: [value, [Validators.required, validateValue]]
    });
  }

  updateFormArray(array, name, checkParameter) {
    array.controls.forEach(control => control.enable())
    let index = array.value.findIndex((item, i) => item[name] === checkParameter)
    // let index= array.value.map((item,i)=>{item[name]===checkParameter;return i})
    array.at(index).get('value').patchValue(false)
    array.at(index).get('value').disable()
  }

  addOtherCares() {
    this.addOtherCare = true;
  }

  addOtherSafetyTrainings() {
    this.addOtherSafteyTraining = true;
  }

  addOtherLicenseBarriers() {
    this.otherLicenseBarriers = true;
  }

  capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  addHousing() {
    let newHouse = (document.getElementById('otherHousing') as HTMLInputElement).value
    this.housing.push(this.newHousing(this.capitalizeFirstLetter(newHouse), true))
    this.addOtherHousing = false;
  }

  addCare() {
    let care = (document.getElementById('otherCare') as HTMLInputElement).value
    this.careProvider.push(this.newCare(care, true))
    // this.careProvider.push(this.fb.control(value))
    // let index= this.careProvider.value.map((care,i)=>{care.care==='No - not a care proivder';return i})
    // this.careProvider.at(index[0]).disable()

    this.updateFormArray(this.careProvider, "care", "No - not a care proivder")
    this.addOtherCare = false;
  }

  addEdu() {
    let value = (document.getElementById('otherEdu') as HTMLInputElement).value
    let index = this.educationToAchieve.length - 2
    this.levelOfEduToAchieve.push(this.newEducation(value, true))
    let i = this.levelOfEduToAchieve.value.map((care, i) => { care.education === 'No Education'; return i })
    this.levelOfEduToAchieve.at(i[0]).disable()
    this.updateFormArray(this.levelOfEduToAchieve, "education", "No Education")
    this.addOtherEducation = false;
  }

  addLang() {
    let newLanguage = (document.getElementById('other') as HTMLInputElement).value
    this.addLanguage(newLanguage);
    this.addOtherLang = false;
    setTimeout(() => {
      M.updateTextFields();
      ($('select') as any).formSelect();
    }, 25);
  }

  addLicenseBarrier() {
    let newBarrier = (document.getElementById('otherBarrier') as HTMLInputElement).value
    this.barrierForLicense.push(this.newLicenseBarrier(newBarrier, true))
    this.otherLicenseBarriers = false;
  }

  addEmploymentGoal() {
    let goal = (document.getElementById('otherGoal') as HTMLInputElement).value
    this.employmentGoals.push(this.newEmploymentGoals(goal))
    this.addOtherEmploymentGoal = false;
  }

  addJobBarrier() {
    let barrier = (document.getElementById('otherJobBarrier') as HTMLInputElement).value
    this.barriers.push(this.newJobBarrier(barrier))
    this.addOtherJobBarrier = false;
  }

  addEmploymentHistory() {
    let barrier = (document.getElementById('otherEmploymentHistory') as HTMLInputElement).value
    this.catagory.push(this.newEmploymentHistory(barrier))
    // this.secondCatagory.push(this.newEmploymentHistory(barrier))
    this.addOtherEmploymentHistory = false;
  }

  addPreviousExpirience() {
    let exp = (document.getElementById('otherPreviousExpirience') as HTMLInputElement).value
    this.previousExpirence.push(this.newExpirience(exp))
    this.addOtherPreviousExpirience = false;
  }

  addPreferedNotification() {
    let notification = (document.getElementById('otherNotification') as HTMLInputElement).value
    this.notificationPreference.push(this.newNotificationMethod(notification, true))
    this.updateFormArray(this.notificationPreference, "method", "None")
    this.addOtherNotification = false;
  }

  addSupportForRelocate() {
    let support = (document.getElementById('otherRelocateSupport') as HTMLInputElement).value
    this.supportForRelocate.push(this.newRelocateSuuport(support, true))
    this.addOtherRelocateSupport = false;
  }

  addOtherWorkshops() {
    let workshop = (document.getElementById('otherWorkshop') as HTMLInputElement).value
    this.workshopsInCommunity.push(this.newWorkshop(workshop, true))
    this.updateFormArray(this.workshopsInCommunity, "workshop", "None")
    this.addOtherWorkshop = false;
  }

  addOtherInfos() {
    let info = (document.getElementById('otherInfo') as HTMLInputElement).value
    this.infoNNADAP.push(this.newInfo(info, true))
    this.addOtherInfo = false;
  }

  addOtherSkills() {
    let skill = (document.getElementById('otherSkill') as HTMLInputElement).value
    this.skills.push(this.newSkill(skill))
    this.addOtherSkill = false;
  }

  addOtherSupportGroups() {
    let group = (document.getElementById('otherSupportGroup') as HTMLInputElement).value
    this.typesOfSupportGroup.push(this.newSupportGroup(group))
    this.addOtherSupportGroup = false;
  }

  addSafteyTraining() {
    let training = (document.getElementById('otherTraining') as HTMLInputElement).value
    this.safetyTraining.push(this.newTraining(training))
    this.addOtherSafteyTraining = false
    // this.safetyTraining.push(this.fb.control(this.newTraining(training)))
  }

  updateSupportGroup(event) {
    if (event.target.value === "No") {
      this.typesOfSupportGroup.clearValidators()
    } else if (event.target.value === "Yes") {
      this.typesOfSupportGroup.setValidators([Validators.required, notFalseValueValidator])
    }
    this.typesOfSupportGroup.updateValueAndValidity()
    this.scrollToQuestion('supportGroup')
  }

  updateInfoOnProgram(event) {
    if (event.target.value === "No") {
      this.infoNNADAP.clearValidators()
    } else if (event.target.value === "Yes") {
      this.infoNNADAP.setValidators([Validators.required, notFalseValueValidator])
    }
    this.infoNNADAP.updateValueAndValidity()
    this.scrollToQuestion('infoOnProgram')
  }

  nextSection() {
    this.step += 1;
    if (this.step === 15) {
      if (this.filledBy === "Admin") {
        this.lrrcnForm.get("clientOnSight").setValidators(Validators.required)
      }
      this.ApplicantSign()
    }
    setTimeout(() => {
      $('.modal').modal();
      ($('select') as any).formSelect();
      $('.datepicker').datepicker();
      M.updateTextFields();
    }, 25);
    window.scrollTo(0, 0);
  }

  previousSection() {
    if (this.step == 15) this.HasApplicantSign = false
    this.step -= 1;
    setTimeout(() => {
      $('.modal').modal();
      ($('select') as any).formSelect();
      $('.datepicker').datepicker();
      M.updateTextFields();
    }, 25);
    window.scrollTo(0, 0);
  }

  getEmailForAdmin() {
    return {
      body: "You have been assigned to review and approve a survey; please login to your administrator account to review and approve the survey.",
      subject: "You've been assigned to a survey.",
    }
  }

  getEmailForClient() {
    return {
      body: "A survey has been completed on your behalf; please login to your account to review and sign the survey.",
      subject: "Survey to Sign",
    }
  }

  submitLrrcnForm(values) {
    this.model.filledBy = this.filledBy
    let msg = new EmailModel();
    if (this.filledBy === "Member") {
      this.model.applicantSignature = this.applicantSignaturePad.toDataURL()
      // this.model.applicantSignature = this.applicantSignaturePad.toDataURL().replace('data:image/png;base64,', '');
      this.model.applicantDate = new Date();
      this.model.verified = false
      // let admin = this.filtredResult[values.admin]
      // console.log(admin, 'theadmin')
      // console.log(this.filtredResult, 'filtredResult')
      let admin = this.adminsList[values.admin]
      this.model.workerUid = admin.uid
      this.model.workerFirstName = admin.FirstName
      this.model.workerLastName = admin.LastName

      let emails: string[] = []

      // emails.push(admin.Email_Address)
      emails.push(admin.Email)
      msg.emailList = emails;
      let { subject, body } = this.getEmailForAdmin()
      msg.subject = subject;
      msg.body = body;
    } else {
      this.model.workerSignature = this.applicantSignaturePad.toDataURL()
      // this.model.workerSignature = this.applicantSignaturePad.toDataURL().replace('data:image/png;base64,', '');
      this.model.workerDate = new Date();
      this.model.workerUid = this.loggedUser.uid
      this.model.workerFirstName = this.loggedUser.FirstName
      this.model.workerLastName = this.loggedUser.LastName
      this.model.verified = true

      let emails: string[] = []

      emails.push(values.demographic.email)
      msg.emailList = emails;
      let { subject, body } = this.getEmailForClient()
      msg.subject = subject;
      msg.body = body;
    }
    // this.model.applicantSignature = this.applicantSignaturePad.toDataURL().replace('data:image/png;base64,', '');
    // this.model.applicantDate  = new Date(); 

    //info
    let aboutLrrcn: AboutLrrcn = new AboutLrrcn()
    aboutLrrcn.knowAboutLrrcn = values.aboutLrrcn.knowAboutLrrcn
    aboutLrrcn.registered = values.aboutLrrcn.registered
    aboutLrrcn.knowHowToUse = values.aboutLrrcn.knowHowToUse

    this.model.aboutLrrcn = aboutLrrcn

    // demographic
    let dempographic: Demographic = new Demographic()
    dempographic.firstName = values.demographic.firstName
    dempographic.lastName = values.demographic.lastName
    dempographic.email = values.demographic.email
    dempographic.phone = values.demographic.phone
    dempographic.clientCategory = values.demographic.clientCategory
    dempographic.gender = values.demographic.gender
    dempographic.maritalStatus = values.demographic.maritalStatus
    dempographic.age = values.demographic.age
    dempographic.livingProvince = values.demographic.liveCommunity
    dempographic.community = values.demographic.fromCommunity
    dempographic.housing = values.demographic.housing.filter(house => house.value === true)
      .map(house => {
        return house.housing
      })
    this.model.demographic = dempographic

    // language
    // let languages:Languages = new Languages()
    let languages = values.languages
    this.model.languages = languages

    // Care giving
    let careGiving: CareGiving = new CareGiving()
    careGiving.availableCare = values.careGiving.availableCare
    careGiving.dayeCareAvailable = values.careGiving.dayeCareAvailable
    careGiving.neededCare = values.careGiving.neededCare
    careGiving.needsCare = values.careGiving.needsCare
    careGiving.numberOfDependants = values.careGiving.numberOfDependants
    careGiving.typeOfCareAvailable = values.careGiving.typeOfCareAvailable
    careGiving.careProvider = values.careGiving.careProvider.filter(care => care.value === true)
      .map(care => {
        return care.care
      })
    this.model.careGiving = careGiving

    let license = new License()
    license.beNotifiedForTraining = values.license.beNotifiedForTraining
    license.howToBeNotified = values.license.howToBeNotified
    license.typeOfLicense = values.license.typeOfLicense.filter(license => license.value === true)
      .map(license => {
        return license.license
      })
    license.typeOfLicenseToObtain = values.license.typeOfLicenseToObtain.filter(license => license.value === true)
      .map(license => {
        return license.license
      })
    license.barrierForLicense = values.license.barrierForLicense.filter(barrier => barrier.value === true)
      .map(barrier => {
        return barrier.barrier
      })

    this.model.license = license

    // Education
    let education = new Education()
    education.areaOfStudyToAchieve = values.education.areaOfStudyToAchieve
    education.currentlyEnrolled = values.education.currentlyEnrolled
    education.highestLevelOfEdu = values.education.highestLevelOfEdu
    education.knowWhoToContactForUpgrade = values.education.knowWhoToContactForUpgrade
    education.collegeApply = values.education.collegeApply
    education.applyForFunding = values.education.applyForFunding
    education.postSecondaryEdu = values.education.postSecondaryEdu
    education.typeOfProgram = values.education.typeOfProgram
    education.typeOfProgramToUpgrade = values.education.typeOfProgramToUpgrade
    education.upgradeEdu = values.education.upgradeEdu
    // education.whenToUpgradeEducation = values.education.whenToUpgradeEducation
    education.whyNoUpgrade = values.education.whyNoUpgrade
    education.levelOfEduToAchieve = values.education.levelOfEduToAchieve.filter(x => x.value === true).map(x => {
      return x.education
    })

    this.model.education = education
    // saftey training
    let safteyTraining = values.safetyTraining.filter(training => training.dateValue.value !== null)
    this.model.safetyTraining = safteyTraining
    // training and employment
    let trainingAndEmployment: TrainingAndEmployment = new TrainingAndEmployment()
    trainingAndEmployment.currentEmploymentComment = values.trainingAndEmployment.currentEmploymentComment;
    trainingAndEmployment.currentlyEmployed = values.trainingAndEmployment.currentlyEmployed;
    trainingAndEmployment.currentlySeekingEmployment = values.trainingAndEmployment.currentlySeekingEmployment;
    trainingAndEmployment.employmentStatus = values.trainingAndEmployment.employmentStatus;
    trainingAndEmployment.notifcationEmail = values.trainingAndEmployment.notifcationEmail;
    trainingAndEmployment.reasonForSeekingEmployment = values.trainingAndEmployment.reasonForSeekingEmployment;
    trainingAndEmployment.notificationPreference = values.trainingAndEmployment.
      notificationPreference.filter(method => method.value === true).map(method => {
        return method.method
      })
    trainingAndEmployment.employmentGoals = values.trainingAndEmployment.employmentGoals.filter(goal =>
      goal.value !== null)

    // trainingAndEmployment.secondEmploymentGoals = values.trainingAndEmployment.secondEmploymentGoals.filter(goal =>
    //   goal.value !== null)

    this.model.trainingAndEmployment = trainingAndEmployment
    // job staistfaction
    let jobSatisfaction: JobSatisfaction = new JobSatisfaction()
    jobSatisfaction.detailsForSatisfaction = values.jobSatisfaction.detailsForSatisfaction
    jobSatisfaction.satisfiedReason = values.jobSatisfaction.satisfiedReason
    jobSatisfaction.satisfiedWithEmployment = values.jobSatisfaction.satisfiedWithEmployment
    jobSatisfaction.factorsForSatisfaction = values.jobSatisfaction.factorsForSatisfaction.filter(factor => factor.value === true)
    this.model.jobSatisfaction = jobSatisfaction

    let barriersOfJob: BarriersOfJob = new BarriersOfJob()
    barriersOfJob.covidAffected = values.barriersOfJob.covidAffected
    barriersOfJob.explanation = values.barriersOfJob.explanation
    barriersOfJob.barriers = values.barriersOfJob.barriers.filter(b => b.value !== null)

    // barriersOfJob.secondBarriers = values.barriersOfJob.secondBarriers.filter(b =>
    //   b.value !== null)

    this.model.barriersOfJob = barriersOfJob

    // employmentHistory
    let employmentHistory: EmploymentHistory = new EmploymentHistory();
    employmentHistory.firstRecentJob = values.employmentHistory.firstRecentJob
    employmentHistory.secondRecentJob = values.employmentHistory.secondRecentJob
    employmentHistory.thirdRecentJob = values.employmentHistory.thirdRecentJob
    employmentHistory.noTrainingReason = values.employmentHistory.noTrainingReason
    employmentHistory.catagory = values.employmentHistory.catagory.filter(b => b.value !== null)
    employmentHistory.previousExpirence = values.employmentHistory.previousExpirence.filter(b => b.value !== null)

    // employmentHistory.secondCatagory = values.employmentHistory.secondCatagory.filter(b => b.value !== null)

    this.model.employmentHistory = employmentHistory
    // shift work
    let shiftWork: ShiftWork = new ShiftWork()
    shiftWork.workAlone = values.shiftWork.workAlone
    shiftWork.workAloneReason = values.shiftWork.workAloneReason
    shiftWork.careerAssessment = values.shiftWork.careerAssessment
    shiftWork.commuteForWork = values.shiftWork.commuteForWork
    shiftWork.commuteLength = values.shiftWork.commuteLength
    shiftWork.localOrInCommunityWorkshop = values.shiftWork.localOrInCommunityWorkshop
    shiftWork.noReason = values.shiftWork.noReason
    shiftWork.shiftWorkOutsideCommunity = values.shiftWork.shiftWorkOutsideCommunity
    shiftWork.supportGroup = values.shiftWork.supportGroup
    shiftWork.willingToRelocate = values.shiftWork.willingToRelocate
    shiftWork.supportForRelocate = values.shiftWork.supportForRelocate.filter(b => b.value !== null)
    shiftWork.workshopsInCommunity = values.shiftWork.workshopsInCommunity.filter(b => b.value == true).map(x => x.workshop)
    shiftWork.typesOfSupportGroup = values.shiftWork.typesOfSupportGroup.filter(x => x.value === true).map(x => x.group)

    this.model.shiftWork = shiftWork

    // addiction
    let addiction: AddictionSuuport = new AddictionSuuport()
    addiction.communicateOnBehalf = values.addictionsSupport.communicateOnBehalf
    addiction.infoOnProgram = values.addictionsSupport.infoOnProgram
    addiction.infoNNADAP = values.addictionsSupport.infoNNADAP.filter(x => x.value === true).map(x => x.info)

    this.model.addictionsSupport = addiction

    // traditionalEmployment
    let traditionalEmployment: TraditionalEmployment = new TraditionalEmployment()
    traditionalEmployment.additionalComments = values.traditionalEmployment.additionalComments
    traditionalEmployment.skills = values.traditionalEmployment.skills.filter(s => s.value !== null)
    this.model.traditionalEmployment = traditionalEmployment

    this.surveyService.saveSurvey(this.model).then(x => {
      toast({ html: 'Survey Successfully Submitted!', classes: 'green' });
      this.emailService.sendEmail(msg).subscribe(x => {
        if (x && x.Status == 'OK') {
          let recipient = msg.emailList[0]
          toast({ html: `Successfully Sent Email To ${recipient}!`, classes: 'green' });
        } else {
          toast({ html: 'Failed to send Email. Please Try again.', classes: 'red' });
        }
        if (this.loggedUser.role == "Member") {
          this.router.navigate(['member/profile/lrrcn-list']);
        } else {
          this.router.navigate(['admin/applications/lrrcn-list']);
        }
      });
    }).catch(err => {
      toast({ html: 'Error subimtting survey !', classes: 'red' });
    });
  }

  backClicked() {
    localStorage.setItem('Profile_updated', 'Yes');
    this.location.back();
  }

  backToSearch() {
    this.onBackToSearch.emit(true);
  }

  emailSentHandler(valueEmitted) {
    if (valueEmitted) this.emailSent = true;
    this.sendEmail = false;
  }

  signatureInvalid() {
    if (!this.applicantSignaturePad || this.applicantSignaturePad.isEmpty()) {
      return true;
    }
    return false
  }

  sectionIsInValid() {
    if (this.step == 15) {
      if (!this.applicantSignaturePad || this.applicantSignaturePad.isEmpty()) {
        return true;
      } else {
        return false
      }
    }
    if (this.step == 0) return false
    let currentSection = this.sections[this.step]
    let s = this.lrrcnForm.controls[currentSection]
    if (s.invalid) return true
    return false
  }
}

function validateValue(c: UntypedFormControl) {
  return c.value ? null : {
    invalidValue: true
  }
}

function notFalseValueValidator(formArray: UntypedFormArray) {
  let valid = false
  formArray.value.forEach(value => {
    if (value.value === true) {
      valid = true
    }
  })
  return valid ? null : {
    invalidValue: true
  }
}

function notNullValueValidator(formArray: UntypedFormArray) {
  let valid = false
  formArray.value.forEach(value => {
    if (value.value === null) {
      valid = true
    }
  })
  return valid ? null : {
    invalidValue: true
  }
}

function allOrNoneValidator(group: UntypedFormGroup) {
  const employer = group.get('employer');
  const jobTitle = group.get('jobTitle');
  const mainDuties = group.get('mainDuties');
  const startDateOfEmployment = group.get('startDateOfEmployment');
  const endDateOfEmployment = group.get('endDateOfEmployment');
  const stillWorkingThere = group.get('stillWorkingThere');

  const values = [
    employer.value,
    jobTitle.value,
    mainDuties.value,
    startDateOfEmployment.value,
    endDateOfEmployment.value,
    stillWorkingThere.value,
  ];

  if (values.every(x => x === '') || values.every(x => x !== '')) {
    return null
  } else {
    return { Incomplete: true };
  }
}

function editDate(array, index, date) {
  array.at(index).get('dateValue').get('date').patchValue(date)
  array.at(index).updateValueAndValidity()
}