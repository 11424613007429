<div class="row center-align">
  <br />
  <strong *ngIf="model">
    <h6 class="center" [innerHTML]="model.name"></h6>
  </strong>
</div>

<div class="container">
  <div class="row ">
    <div class="col l1"></div>
    <div class="col s12 l10">

      <div *ngIf="model">
        <div class="card-panel white row">
          <p><strong>{{model.mandate}}</strong></p>
        </div>

        <div class=" card-panel white row">
          <ul class="row collection">
            <li class="collection-item">
              <i class="material-icons purple-text left"
                style="display: inline-flex; vertical-align: top; margin-right: 5px;">location_on</i>
              <strong>Address </strong>
              <span class="secondary-content"><strong>{{model.address}}</strong> </span>
            </li>

            <li class="collection-item">
              <i class="material-icons teal-text left"
                style="display: inline-flex; vertical-align: top; margin-right: 5px;">location_on</i>
              <strong style="text-transform: uppercase;">Postal Code </strong> <span
                class="secondary-content"><strong>{{model.postal_code}}</strong> </span>
            </li>

            <li class="collection-item">
              <i class="material-icons blue-text left"
                style="display: inline-flex; vertical-align: top; margin-right: 5px;">local_phone</i>
              <strong> Phone </strong> <span class="secondary-content"><strong>{{model.phone}}</strong> </span>
            </li>

            <li class="collection-item">
              <i class="material-icons pink-text left"
                style="display: inline-flex; vertical-align: top; margin-right: 5px;">tty</i>
              <strong> Fax</strong> <span class="secondary-content"><strong>{{model.fax}}</strong> </span>
            </li>
          </ul>
        </div>

      </div>

      <div class="section"></div>
      <a *ngIf="nationOfUSe === 'Batc'" [ngClass]="'col s12 btn waves-effect ' + themeColor" (click)="backClicked()"><i
          class="material-icons left">arrow_back_ios</i> Back To Offices List</a>

      <a *ngIf="nationOfUSe !== 'Batc'" [ngClass]="'col s12 btn waves-effect ' + themeColor" (click)="backClicked()"><i
          class="material-icons left">arrow_back_ios</i> Back To Departments List</a>
    </div>
    <div class="col l1"></div>
  </div>
</div>