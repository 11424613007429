<div class="section hide-on-med-and-down"></div>
<div class="row col s12" style="width: 100%;">
  <div class="row">
    <div class="col s12">
      <ul class="tabs">
        <li class="tab col s6"><a class="active" href="#adminPosts" (click)="refresh()">Posts</a></li>
        <li class="tab col s6"><a href="#messaging">Messaging</a></li>
      </ul>
    </div>

    <div id="adminPosts" class="col s12">
      <div class="col l1 s0 hide-on-med-and-down"></div>
      <div class="col s12 padding-0">
        <div class="row card-panel white padding-0">
          <!-- left column - chat list and search -->
          <div class="col l3 s3 left-col-list hide-on-small-only" style=" min-height: 350px; padding-top: 16px;">
            <ng-container *ngTemplateOutlet="leftList"></ng-container>
          </div>
          <div class="hide-on-med-and-up">
            <div class="row" style="   margin: 0.2rem 0 0 1.3rem">
              <ul id="slide-out" class="sidenav" style="top: 57px;">
                <ng-container *ngTemplateOutlet="leftList"></ng-container>
              </ul>
              <div class="col s2"><a href="#" data-target="slide-out" class="sidenav-trigger"><i
                    style=" color: #256bd8;   margin-top: 8px;" class="material-icons">menu</i></a></div>
              <div class="col s3 offset-s7">
                <div class="btn-flat modal-trigger" data-target="NewBroadcast"
                  style="margin-top: 8px; margin-left: 38%;">
                  <span class="material-icons color-blue tooltipped margin-top-0" data-position="top"
                    data-tooltip="Create new post" style="color: #256bd8; z-index: 1000;">
                    edit
                  </span>
                </div>
              </div>
            </div>
          </div>

          <ng-template #leftList>
            <div class="row left-list ">
              <div class="row center" style="margin-bottom: 12px;" *ngIf="currentNationLogo">
                <img [src]="currentNationLogo" height="100px">
                <div>{{currentNationName}}</div>
                <div class="divider" style="color: #12a3e0;"></div>
              </div>
              <div class="row">
                <button class="col s10 offset-s1 m10 offset-m1 btn-flat modal-trigger" data-target="NewBroadcast"
                  style="background-color: #12a3e0;    color: white;">Broadcast Message</button>
              </div>
              <div class="row">
                <h6 class="header-1">
                  Saved Audiences
                </h6>
                <div class="divider"></div>
                <div class="audience-list">
                  <ul class="center" *ngIf="savedAudienceByCurrentAdmin.length > 0">
                    <li class="row" *ngFor="let audience of savedAudienceByCurrentAdmin">
                      <a class="col s8">{{audience.audienceTitle}}</a>
                      <div class="col s2 btn-flat tooltipped" data-position="top"
                        data-tooltip="Create new post for this audience"
                        (click)="createPostWithExsistingAudience(audience)"><span class="material-icons"
                          style="margin: 0;">
                          add
                        </span></div>
                      <div class="col s2 btn-flat tooltipped" data-position="top"
                        data-tooltip="View exsisting post of this audience" (click)="viewPostsByAudience(audience.id)">
                        <span class="material-icons" style="margin: 0;">
                          visibility
                        </span></div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </ng-template>

          <!-- New Broadcast Message -->
          <div id="NewBroadcast" class="modal">
            <form [formGroup]="AdminNewBroadcast" (ngSubmit)="newPostByAdmin(AdminNewBroadcast.value)">
              <div class="modal-content modal-header">
                <h6 class="center-align">Broadcast to Audiences</h6>
              </div>
              <div class="row modal-content" style="    max-height: 550px; overflow: hidden scroll;">
                <div class="input-field class col s10 offset-s1 m9 offset-m2 padding-right-0 margin-top-0">
                  <textarea class="materialize-textarea" formControlName="message" style="
                  min-height: 7rem;" placeholder="Write something here..."></textarea>
                </div>

                <div class="input-field class col s10 offset-s1 m9 offset-m2 padding-right-0 margin-top-0">
                  <button #Media type="button" class=" btn backg-color-blue col s12" data-position="top"
                    data-tooltip="Upload Media" (click)="mediaInput.click()"><span class="material-icons"
                      style="margin-top: 0.4rem;">
                      perm_media
                    </span> Photo / Video</button>
                  <div style="display: inline-block">
                    <input #mediaInput name="file" (change)="onSelectFiles($event.target.files)" type="file" hidden
                      multiple />
                  </div>
                </div>
                <!-- when files are selected for upload -->
                <div class="selected-all row col s12" *ngIf="selectedFilesForDisplay.length > 0">
                  <div class="col s5 selected-wrap" *ngFor="let file of selectedFilesForDisplay">

                    <button class="image-wrap padding-0" *ngIf="file.name == 'image'">
                      <img class="col s12 materialboxed" [src]="file.result">
                    </button>
                    <div class="cancel-wrap btn-flat" (click)="cancelFile(i)">
                      <span class="material-icons">
                        cancel
                      </span>
                    </div>
                  </div>
                </div>

                <!-- when files are selected for uplooad -->
                <div class="input-field class col s10 offset-s1 m9 offset-m2 padding-right-0 margin-top-0"
                  *ngIf="!exsistingAudience">
                  <div class="blue-grey-text text-darken-4" style="margin: 2% 0;">Who would you like to send this
                    message
                    to?</div>
                  <div class="col m5 s12">
                    <label>
                      <input type="radio" value="true" name="audience" class="backg-color-blue" [checked]="everyone"
                        (click)="allAudiencesSelected()" />
                      <span><strong>Everyone</strong></span>
                    </label>
                  </div>

                  <div class="col m6 offset-m1 s12">
                    <label>
                      <input type="radio" value="false" name="audience" [checked]="specificAudience"
                        (click)="specificAudienceSelected()" class="backg-color-blue" />
                      <span><strong>Specific Audience</strong></span>
                    </label>
                  </div>
                </div>

                <div *ngIf="specificAudience && !exsistingAudience">
                  <div class="center col s9 offset-s2 m8 offset-m3">
                    <h6 class="" style="font-size: 12px;">Filter Audiences </h6>
                  </div>
                  <div class="row filter-methods">
                    <div class="row">
                      <div
                        [ngClass]="useAgeRange ? 'method-name input-field class col m4 offset-m2  s10 offset-s1' : 'method-name input-field class col s10 offset-s1  m9 offset-m2' ">
                        <div class="col s12">
                          <label>
                            <input type="radio" value="Yes" name="ageLimit" [checked]="useAgeRange"
                              (click)="useAgeRangeSelected()" />
                            <span><span class="material-icons material-icon-margin-top-zero">
                                date_range
                              </span>Filter using Age</span>
                          </label>
                        </div>
                      </div>
                      <div *ngIf="useAgeRange" class="col m6 offset-m0 s10 offset-s2">
                        <div class="input-field class col s5 margin-bottom-0">
                          <input formControlName="minAge" type="number" min="0" maxlength="75" autocomplete="off"
                            class="validate margin-bottom-0" placeholder="Min Age :" style=" height: 2.5rem;">
                          <span class="helper-text" data-error="A valid Min Age is required." data-success=""
                            style="    min-width: 200px;"></span>

                        </div>
                        <div class="input-field class col s5 offset-s1 margin-bottom-0">
                          <input formControlName="maxAge" type="number" [min]="AdminNewBroadcast.value.minAge"
                            maxlength="75" autocomplete="off" class="validate margin-bottom-0" placeholder=" Max Age :"
                            style="height: 2.5rem;">
                          <span class="helper-text" data-error="A valid Maximum Age is required." data-success=""
                            style=" min-width: 200px;"></span>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div
                        [ngClass]="useGender ? 'method-name input-field class col m4 offset-m2  s10 offset-s1' : 'method-name input-field class col s10 offset-s1  m9 offset-m2' ">
                        <div class="col s12 padding-right-0">
                          <label>
                            <input type="radio" value="Yes" name="useGender" [checked]="useGender"
                              (click)="useGenderSelected()" />
                            <span><span class="material-icons material-icon-margin-top-zero">
                                wc
                              </span>Filter using Gender</span>
                          </label>
                        </div>
                      </div>
                      <div *ngIf="useGender" class="col m5 offset-m0 s10 offset-s2"
                        style="margin-top: 2.5%; margin-bottom:  4.5%;">
                        <div class="col s5">
                          <label>
                            <input type="radio" value="M" name="gender" formControlName="gender"
                              class="backg-color-blue" checked />
                            <span><strong>Male</strong></span>
                          </label>
                        </div>
                        <div class="col s5 offset-s1">
                          <label>
                            <input type="radio" value="F" name="gender" formControlName="gender"
                              class="backg-color-blue" />
                            <span><strong>Female</strong></span>
                          </label>

                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div
                        [ngClass]="useCommunityName ? 'method-name input-field class col m4 offset-m2  s11 offset-s1' : 'method-name input-field class col s10 offset-s1  m9 offset-m2' ">
                        <div class="col s12">
                          <label>
                            <input type="radio" value="Yes" name="communityName" [checked]="useCommunityName"
                              (click)="useCommunityNameSelected()" />
                            <span><span class="material-icons material-icon-margin-top-zero">
                                groups
                              </span>Community</span>
                          </label>
                        </div>
                      </div>
                      <div *ngIf="useCommunityName" class="col m6 offset-m0 s10 offset-s2">
                        <div class="input-field col s12">
                          <select formControlName="community" id="communityName">
                            <option value="" disabled selected>Choose your option</option>
                          </select>
                          <label for="communityName">Chooose Community: </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="modal-footer">
                <div class="row">
                  <div class="col s6  m3 offset-m5">
                    <button class="modal-close grey btn" type="button" style="margin-bottom: 2.5rem;">
                      Cancel</button>
                  </div>
                  <div class="col s5 offset-s1 m3 offset-m1 left">
                    <button [disabled]="!AdminNewBroadcast.valid" type="button"
                      class="modal-trigger btn waves-effect backg-color-blue" data-target="confirmBroadcast">
                      Broadcast
                    </button>
                  </div>
                </div>
              </div>

              <!-- Confirm Broadcast Modal -->
              <div id="confirmBroadcast" class="modal" style="width: 55%;max-height: 85%;">
                <div class="modal-content modal-header">
                  <div class="center">Confirm Message Broadcast</div>
                </div>
                <div class="modal-footer">
                  <div class="row center" style="margin-top: 1.5rem;">
                    <div class="col s10 offset-s1"
                      style="color: black;font-size: 12px; font-family: Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;">
                      You are about to send message to the selected members.<br> <br>
                      Please confirm if you want to continue</div>
                    <div class="col s10 offset-s1" *ngIf="!everyone && !exsistingAudience" style="margin-top: 1.2rem;">
                      <label>
                        <input [checked]="saveAudience" type="checkbox" (click)="saveAudienceClicked()" />
                        <span>Save Audience for later use</span>
                      </label>
                    </div>
                  </div>
                  <div *ngIf="saveAudience">
                    <div class="input-field class col s10 offset-s1 m9 offset-m2 margin-bottom-0">
                      <input formControlName="audienceName" type="text" maxlength="75" autocomplete="off" required
                        class="validate margin-bottom-0" placeholder="Audience Title : ">
                      <!-- <label for="circleName"> <strong class="red-text">*</strong> </label> -->
                      <span class="helper-text" data-error="Audience Name is required." data-success=""></span>
                    </div>
                  </div>
                  <div class="row" style="margin-top: 2.5rem;">
                    <div class="col s5 m3 offset-m5">
                      <button class="grey btn" type="button" (click)="closeConfirmModal()">
                        Cancel</button>
                    </div>
                    <div class="col s6 m3">
                      <button class="modal-close waves-effect backg-color-blue btn"
                        [disabled]="!AdminNewBroadcast.valid" type="submit">Broadcast</button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
          <!-- end of modal -->

          <!-- Right column - messaging area -->
          <div class="col s12 m9 posts-col-list">
            <app-posts-display></app-posts-display>
          </div>
        </div>
      </div>
    </div>
    <div id="messaging" class="col s12">
      <app-messaging-member></app-messaging-member>
    </div>
  </div>
</div>