<div class="col s12">
    <div class="body-h">
        <div class="header-setting b-m-2">
            <div class="col l8 m8 s12">
                <h4 *ngIf="!editName">
                    <span *ngIf="sectionName">{{sectionName}}</span>
                    <span *ngIf="!sectionName">Section Setting</span>
                    <i class="material-icons edit-name" [ngStyle]="{'color': themeColor}"
                        (click)="editNameClicked()">edit</i>
                </h4>

                <div class="input-field" *ngIf="editName">
                    <input type="text" [(ngModel)]="sectionName" class="left col s6 validate" autocomplete="off" />
                    <button type="button" class="btn ll-m-5" [ngStyle]="{'background-color': themeColor}"
                        (click)="saveName()">Save</button>
                </div>

                <p class="col s12 bold-300 sub-title-section3">This is where you can add, design and manage your
                    sections which can be customized according to their preferences.</p>
            </div>

            <div class="col l4 m4 s12 p-m-2">
                <a class="add-button btn white center-align p-l-2" [ngStyle]="{'color': themeColor}"
                    [routerLink]="['/admin/dashboard-sub-menu/settings/add-section']"><i
                        class="material-icons-outlined">add</i>
                    <span *ngIf="sectionName">Add {{sectionName}}</span>
                    <span *ngIf="!sectionName">Add Section</span> </a>
            </div>
        </div>

        <div class="row black-text center empty-card" *ngIf="!model || model.length === 0">
            <img src="../../assets/img/empty-folder.png" height="200" width="200">
            <h6 class="bold-700 title-section3">Sorry , No custom sections found !!</h6>
        </div>

        <main class="StickyContent " *ngIf="model && model.length>0">
            <div class="col s12 m6 l4 xl3" *ngFor="let item of model; let i=index hoverable">

                <div class="card white b-n-1" style="max-height:250px; min-height: 250px; "
                    [ngStyle]="{'border-color': themeColor} ">
                    <div class="card-info">
                        <span>
                            <div class="responsive-img image-none">
                                <div>
                                    <div class="avatar-circle circle hide-on-med-and-up col s2 m2 l4 offset-s5 offset-m5 offset-l4"
                                        [ngStyle]="{'background-color': themeColor}">
                                        <span class="initials">{{getInitials(item.displayName)}}</span>
                                    </div>
                                    <div class="avatar-circle2 circle hide-on-small-only show-on-medium-and-up col s2 m2 l4 offset-s5 offset-m5 offset-l4"
                                        [ngStyle]="{'background-color': themeColor}">
                                        <span class="initials2">{{getInitials(item.displayName)}}</span>
                                    </div>
                                </div>
                                <!-- <div *ngIf="item.icon">
                                    <div class="row center-align">
                                        <div class="icon-wrapper">
                                            <i class="material-symbols-outlined custom-icon white-text bold-300"
                                                [ngStyle]="{'background-color': themeColor}">{{ item.icon }}</i>
                                        </div>
                                    </div>
                                </div> -->
                            </div>
                        </span>

                        <span class="col s12" style="text-align: center;">
                            <h1 class="title-section3 bold-600 p-m-1" [innerHTML]="item.displayName"></h1>
                            <div class="description-section middle-body-snippet bold-100">
                                <p [innerHTML]="item.sectionDescription"></p>
                            </div>

                            <!-- <div class="sub-title-section2">
                                <p [innerHTML]="item.addToAdmin"></p>
                            </div> -->

                            <div class="divider col l10 m8 s8 push-m2 push-s2 push-l1 grey lighten-3"></div>

                            <div class="col s12">
                                <a class="col s4 center-align" [routerLink]="['/admin/custom-section/', item.id]"><i
                                        class="material-icons-outlined"
                                        [ngStyle]="{'color': themeColor}">pageview</i></a>
                                <a class="col s4 center-align"
                                    [routerLink]="['/admin/dashboard-sub-menu/settings/edit-section', item.id]"><i
                                        class="material-icons-outlined blue-text">edit</i></a>
                                <a class="col s4 center-align" (click)="onDelete(item.id)" style="cursor: pointer;"><i
                                        class="material-icons-outlined red-text">delete</i></a>
                            </div>

                            <div *ngIf="openDeleteModal">
                                <app-modals [modalType]="'delete'" [data]="modalData" (confirm)="confirmHandler($event)"
                                    (cancel)="cancelHandler($event)"></app-modals>
                            </div>

                        </span>
                    </div>
                </div>
            </div>
        </main>
    </div>
</div>