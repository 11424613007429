import { NgModule } from '@angular/core';
import { RegisterComponent } from './register/register.component';
import { AuthMainComponent } from './auth-main/auth-main.component';
import { Routes, RouterModule } from '@angular/router';
import { VerifyEmailComponent } from './verify-email/verify-email.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { LoginComponent } from './login/login.component';
import { EmployerLoginComponent } from './employer-login/employer-login.component';
import { RegisterAdminComponent } from './register-admin/register-admin.component';
import { OauthRegistrationComponent } from './oauth-registration/oauth-registration.component';
import { MobileRegistrationComponent } from './mobile-registration/mobile-registration.component';
import { RegisterDepartmentEmployeeComponent } from './register-department-employee/register-department-employee.component';
import { DemoSignupComponent } from './demo-signup/demo-signup.component';
import { RegisterOtherRolesComponent } from './register-other-roles/register-other-roles.component';
import { RegisterEmployeeComponent } from './register-employee/register-employee.component';
import { AdminRegisterComponent } from './admin-register/admin-register.component';

const routes: Routes = [
  {
    path: 'auth',
    component: AuthMainComponent,
    children: [
      {
        path: 'login',
        component: LoginComponent
      },
      {
        path: 'employer-login',
        component: EmployerLoginComponent
      },
      {
        path: 'register/:id',
        component: RegisterComponent
      },
      {
        path: 'employee-register/:id',
        component: RegisterEmployeeComponent
      },
      {
        path: 'verify-email',
        component: VerifyEmailComponent
      },
      {
        path: 'change-password',
        component: ChangePasswordComponent
      },
      {
        path: 'admin-registration/:id',
        component: RegisterAdminComponent
      },
      {
        path: 'mobile-registration/:id',
        component: MobileRegistrationComponent
      },
      {
        path: 'oauth-registeration',
        component: OauthRegistrationComponent
      },
      {
        path: 'regiter-department-employee/:id',
        component: RegisterDepartmentEmployeeComponent
      },
      {
        path: 'request-demo',
        component: DemoSignupComponent
      },
      {
        path: 'register-other-roles/:id',
        component: RegisterOtherRolesComponent
      },
      {
        path: 'register-admin/:id',
        component: AdminRegisterComponent
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class AuthRoutingModule { }
