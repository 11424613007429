<div class="row center-align container-pi">
    <h4 class="title-section1 title-member icon-texts"><span class="material-symbols-outlined back-icon p-r-1" (click)="backClicked($event)">
        arrow_circle_left
    </span>IA Income Support Survey</h4>

    <div class="row" *ngIf="applicant">
        <div class="col s12 l10">
            <div>
                <app-lrrcn-applications (onBackToSearch)="backClicked($event)" [memberId]="applicantID">
                </app-lrrcn-applications>
            </div>

        </div>
    </div>
</div>