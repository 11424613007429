<app-firebase-loading *ngIf="loading"></app-firebase-loading>
<div *ngIf="member">
    <div class="row" *ngIf="step > 0">
        <div class="col s12 m5">
            <b class="left-align"><span class="red-text">*</span> - required field</b>
        </div>
        <div class="col s12 m7 step-count">
            <p>Step {{step}} of 14</p>
        </div>
    </div>

    <div class="row">
        <button *ngIf="14>=step && step>=1" class="col s12 m5 offset-m2 l3 btn right  green" type="button"
            [disabled]="sectionIsInValid() || step >=15" (click)="nextSection()" style="margin-bottom: 15px;"><i
                class="material-icons right">skip_next</i>Next
            Section</button>
        <button *ngIf="step >=1" class="col s12 m5 l3 btn left  blue" type="button" [disabled]="1>=step"
            (click)="previousSection()" style="margin-bottom: 15px;"><i
                class="material-icons left">skip_previous</i>Previouse Section</button>
    </div>

    <div class="row col s12 card white" *ngIf="step==0">
        <div class="card-content">
            <h6 class="card-title">1. Income Support Survey</h6>
            <br />
            <div class="row">
                <h6><strong>
                        Note to Surveyors:
                    </strong>
                </h6>
            </div>
            <div class="row">
                <h6><strong>ETW: Mandatory</strong></h6>
                <h6><strong>BFE: Not mandatory but can be open to those clients.</strong></h6>
            </div>

            <div class="row">
                <h6><strong>Spouses included.</strong></h6>
            </div>
            <div class="row">
                <h6><strong>The intent of this survey is to help gather information on the skills and experiences of the
                        {{nationOfUse}} members who are currently part of the Income Support Program. We ask for your
                        cooperation in working with the Income Support staff on completing this assessment and survey
                        process.</strong></h6>
            </div>
            <div class="row">
                <h6><strong>AUTHORIZATION AND CONSENT FOR RELEASE OF STATISTICAL INFORMATION REGARDING
                        EMPLOYMENT AND BUSINESS.</strong></h6>
            </div>
            <div class="row">
                <h6><strong>This survey is conducted in the strictest confidentiality. A number will be assigned to each
                        person we
                        interview, and no names will appear in the results.
                    </strong></h6>
            </div>
        </div>
    </div>

    <form [formGroup]="lrrcnForm" class="row col s12 card white" (ngSubmit)="submitLrrcnForm(lrrcnForm.value)">
        <div class="row" formGroupName="aboutLrrcn" *ngIf="step == 1">
            <div class="row col s12 teal lighten-2 white-text">
                <h5 class="center-align">{{nationOfUse | uppercase }} Information</h5>
            </div>
            <div class="card-content">
                <div class="input-field col s12">
                    <h6><strong>1. Do you know about the {{nationOfUse}} app?
                            <span *ngIf="aboutLrrcn.get('knowAboutLrrcn').errors" class="red-text">*</span>
                        </strong></h6>
                    <div class="row col s12">
                        <label class="col m4 s6 ">
                            <input name="knowAboutLrrcn" type="radio" formControlName="knowAboutLrrcn" value="Yes"
                                (click)="updateInfoaAboutLrrcn($event)" />
                            <span>Yes</span>
                        </label>
                        <label class="col m4 s6">
                            <input name="knowAboutLrrcn" type="radio" formControlName="knowAboutLrrcn" value="No"
                                (click)="updateInfoaAboutLrrcn($event)" />
                            <span>No</span>
                        </label>
                    </div>
                </div>

                <div *ngIf="aboutLrrcn.controls['knowAboutLrrcn'].value === 'Yes'" class="input-field col s12 ">
                    <h6><strong>If yes, have you registered?
                            <span *ngIf="aboutLrrcn.get('registered').errors" class="red-text">*</span>
                        </strong></h6>
                    <div class="row col s12">
                        <label class="col m4 s6 ">
                            <input name="registered" type="radio" formControlName="registered" value="Yes" />
                            <span>Yes</span>
                        </label>

                        <label class="col m4 s6">
                            <input name="registered" type="radio" formControlName="registered" value="No" />
                            <span>No</span>
                        </label>
                    </div>
                </div>

                <div class="input-field col s12">
                    <h6><strong>2. Do you know know what you can use it for?
                            <span *ngIf="aboutLrrcn.get('knowHowToUse').errors" class="red-text">*</span>
                        </strong></h6>
                    <div class="row col s12">
                        <label class="col m4 s6 ">
                            <input name="knowHowToUse" type="radio" formControlName="knowHowToUse" value="Yes" />
                            <span>Yes</span>
                        </label>

                        <label class="col m4 s6">
                            <input name="knowHowToUse" type="radio" formControlName="knowHowToUse" value="No" />
                            <span>No</span>
                        </label>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" formGroupName="demographic" *ngIf="step == 2">
            <div class="row col s12 teal lighten-2 white-text">
                <h5 class="center-align">SECTION 1: Demographics Infomation</h5>
            </div>

            <div class="card-content">
                <h6><strong>1. Client Infomation</strong></h6>
                <div class="input-field col s12 m6" id="firstNameContainer">
                    <i class="material-icons prefix green-text">person</i>
                    <input id="firstName" type="text" formControlName='firstName' class="validate" autocomplete="off"
                        style="text-transform: capitalize;" (click)="scrollToQuestion('lastNameContainer')" />
                    <label for="firstName">First Name<span *ngIf="firstName.errors" class="red-text">*</span></label>
                    <div *ngIf="(firstName.dirty || firstName.touched) && firstName.invalid">
                        <small *ngIf="firstName.errors.required" class="red-text">First Name is
                            Required.</small>
                    </div>
                </div>

                <div class="input-field col s12 m6" id="lastNameContainer">
                    <i class="material-icons prefix green-text">person</i>
                    <input id="lastName" type="text" formControlName='lastName' class="validate" autocomplete="off"
                        style="text-transform: capitalize;" (click)="scrollToQuestion('emailContainer')" />
                    <label for="lastName">Last Name<span *ngIf="lastName.errors" class="red-text">*</span></label>
                    <div *ngIf="(lastName.dirty || lastName.touched) && lastName.invalid">
                        <small *ngIf="lastName.errors.required" class="red-text">Last Name is
                            Required.</small>
                    </div>
                </div>

                <div class="input-field col s12 m6" id="emailContainer">
                    <i class="material-icons prefix green-text">mail</i>
                    <input id="email" type="email" formControlName='email' class="validate" autocomplete="off"
                        (click)="scrollToQuestion('phoneContainer')" />
                    <label for="email">Email<span *ngIf="email.errors" class="red-text">*</span></label>
                    <div *ngIf="(email.dirty || email.touched) && email.invalid">
                        <small *ngIf="email.errors.required" class="red-text">Email is Required.</small>
                        <small *ngIf="email.errors.email" class="red-text">Invalid Email Address</small>
                    </div>
                </div>

                <div class="input-field col s12 m6" id="phoneContainer">
                    <i class="material-icons prefix green-text">phone</i>
                    <!-- <input id="phone" type="text" [textMask]="{mask: phoneNumberMask, guide: true}"
                        formControlName='phone' class="validate" autocomplete="off" style="text-transform: uppercase;"
                        (click)="scrollToQuestion('clientCategory')" /> -->
                        <input id="phone" type="text" 
                        formControlName='phone' class="validate" autocomplete="off" style="text-transform: uppercase;"
                        (click)="scrollToQuestion('clientCategory')" />
                    <label for="phone">Phone<span *ngIf="phone.errors" class="red-text">*</span></label>
                    <div *ngIf="(phone.dirty || phone.touched) && phone.invalid">
                        <small *ngIf="phone.errors.required" class="red-text">Phone Number is
                            Required.</small>
                    </div>
                </div>

                <div class="input-field col s12" id="clientCategory">
                    <h6><strong>2. What is the client category? </strong>
                        <span
                            *ngIf="demographic.get('clientCategory').errors && demographic.get('clientCategory').errors.required"
                            class="red-text">*</span>
                    </h6>
                    <div class="row col s12">
                        <label class="col s6">
                            <input name="clientCategory" type="radio" formControlName="clientCategory" value="ETW"
                                (click)="scrollToQuestion('gender')" />
                            <span>ETW</span>
                        </label>
                        <label class="col s6">
                            <input name="clientCategory" type="radio" formControlName="clientCategory" value="BFE"
                                (click)="scrollToQuestion('gender')" />
                            <span>BFE</span>
                        </label>
                    </div>
                </div>

                <div class="input-field col s12" id="gender">
                    <h6><strong>3. Gender
                            <span *ngIf="demographic.get('gender').errors && demographic.get('gender').errors.required"
                                class="red-text">*</span>
                        </strong></h6>
                    <div class="row col s12">
                        <label class="col m4 s6 ">
                            <input name="gender" type="radio" formControlName="gender" value="M"
                                (click)="scrollToQuestion('maritalStatus')" />
                            <span>Male</span>
                        </label>
                        <label class="col m4 s6">
                            <input name="gender" type="radio" formControlName="gender" value="F"
                                (click)="scrollToQuestion('maritalStatus')" />
                            <span>Female</span>
                        </label>
                        <label class="col m4 s6">
                            <input name="gender" type="radio" formControlName="gender" value="X"
                                (click)="scrollToQuestion('maritalStatus')" />
                            <span>Other</span>
                        </label>
                    </div>
                </div>

                <div class="input-field col s12" id="maritalStatus">
                    <h6><strong>4. Marital Status
                            <span
                                *ngIf="demographic.get('maritalStatus').errors && demographic.get('maritalStatus').errors.required"
                                class="red-text">*</span>
                        </strong></h6>
                    <div class="row col s12">
                        <label class="col m4 s6" *ngFor="let status of maritalStatuses">
                            <input name="maritalStatus" type="radio" formControlName="maritalStatus"
                                [value]="status.name" (click)="scrollToQuestion('age')" />
                            <span>{{status.name}}</span>
                        </label>
                    </div>
                </div>

                <div class="input-field col s12" id="age">
                    <h6><strong>5. Age
                            <span *ngIf="demographic.get('age').errors && demographic.get('age').errors.required"
                                class="red-text">*</span>
                        </strong></h6>
                    <div class="row col s12">
                        <label class="col m3 s6" *ngFor="let age of ageGroup">
                            <input name="age" type="radio" formControlName="age" [value]="age"
                                (click)="scrollToQuestion('fromCommunity')" />
                            <span>{{age}}</span>
                        </label>
                    </div>
                </div>

                <div class="input-field col s12" id="fromCommunity">
                    <h6><strong>6. Which community are you from originally?
                            <span
                                *ngIf="demographic.get('fromCommunity').errors && demographic.get('fromCommunity').errors.required"
                                class="red-text">*</span>
                        </strong></h6>
                    <div class="row col s12">
                        <label class="col s6" *ngFor="let name of community">
                            <input name="fromCommunity" (change)="changeFromCommunity(name)" type="radio"
                                formControlName="fromCommunity" [value]="name"
                                (click)="scrollToQuestion('liveCommunity')" />
                            <span>{{name}}</span>
                        </label>
                    </div>

                    <div *ngIf="otherCommunityPicked" class="input-field col s10 ">
                        <i class="material-icons prefix green-text">other_houses</i>
                        <input formControlName="fromCommunity" type="text" required class="validate" autocomplete="off"
                            style="text-transform: capitalize;" />
                        <label>Please Specify</label>
                    </div>
                </div>

                <div class="input-field col s12" id="liveCommunity">
                    <h6><strong>7. Where do you live now?
                            <span
                                *ngIf="demographic.get('liveCommunity').errors && demographic.get('liveCommunity').errors.required"
                                class="red-text">*</span>
                        </strong></h6>
                    <div class="row col s12">
                        <label class="col s6" *ngFor="let name of community">
                            <input name="liveCommunity" (change)="changeLiveCommunity(name)" type="radio"
                                formControlName="liveCommunity" [value]="name" (click)="scrollToQuestion('housing')" />
                            <span>{{name}}</span>
                        </label>
                    </div>

                    <div *ngIf="otherLiveCommunityPicked" class="input-field col s10 ">
                        <i class="material-icons prefix green-text">other_houses</i>
                        <input formControlName="liveCommunity" type="text" required class="validate" autocomplete="off"
                            style="text-transform: capitalize;" />
                        <label>Please Specify</label>
                    </div>
                </div>

                <div class="input-field col s12" id="housing">
                    <h6><strong>8. What is your current housing situation (please check all that apply)?

                            <sup *ngIf="housing.errors && housing.errors.invalidValue" class="red-text"> * select
                                atleast
                                one</sup>
                        </strong></h6>

                    <div class="row col s12">
                        <div class="col s6" *ngFor="let housing of housing.controls;let li=index" [formGroup]="housing">
                            <label>
                                <input class="filled-in" type="checkbox" formControlName='value' />
                                <span>{{housing.controls['housing'].value}}</span>
                            </label>
                        </div>

                        <button *ngIf="!addOtherHousing" class="btn-flat btn-large  right"
                            (click)="addOtherHousing=true" style="margin-top: 15px, bottom 15px;"><i
                                class="large material-icons right green-text">add_circle</i>Add Other</button>

                        <div *ngIf="addOtherHousing">
                            <div class="input-field col s10">
                                <i class="material-icons prefix green-text">other_houses</i>
                                <textarea id="otherHousing" class="validate" autocomplete="off"
                                    style="text-transform: capitalize;" class="materialize-textarea"></textarea>
                                <span class="helper-text">Please Specify Other Housing</span>
                            </div>

                            <a (click)="addHousing()" class="btn-flat waves-effect waves-light green col m1 s2 center"
                                style="margin-top: 15px;"><i class="material-icons">add</i></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" formGroupName="languages" *ngIf="step == 3">
            <div class="row col s12 teal lighten-2 white-text">
                <h5 class="center-align">SECTION 2: Language</h5>
            </div>
            <div class="card-content">
                <div class="row">
                    <h6><strong>9. What language(s) do you speak, write and understand? </strong><span
                            class="red-text">*</span></h6>
                    <table>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Speak</th>
                                <th>Write</th>
                                <th>Understand</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let language of languages.controls;let li=index" [formGroup]="language"
                                id="language_{{li}}">
                                <td> <i class="material-icons green-text left">language</i><span><strong>{{language.controls['language'].value}}</strong>
                                    </span></td>
                                <td><label class="col s6">
                                        <input class="filled-in" type="checkbox" formControlName='speak'
                                            (click)="changeLanguage($event,li,'speak')" />
                                        <span></span>
                                    </label></td>
                                <td><label class="col s6">
                                        <input class="filled-in" type="checkbox" formControlName='write'
                                            (click)="changeLanguage($event,li,'write')" />
                                        <span></span>
                                    </label></td>
                                <td><label class="col s6">
                                        <input class="filled-in" type="checkbox" formControlName='understand'
                                            (click)="scroll(li,'language')" />
                                        <span></span>
                                    </label></td>
                            </tr>
                        </tbody>
                    </table>
                    <button *ngIf="!addOtherLang" class="btn-flat btn-large  right" (click)="addOtherLang = true"
                        style="margin-top: 15px, bottom 15px;"><i
                            class="large material-icons right green-text">add_circle</i>Add Other</button>

                    <div *ngIf="addOtherLang">
                        <div class="input-field col s10 ">
                            <i class="material-icons prefix green-text">language</i>
                            <input id="other" type="text" class="validate" autocomplete="off"
                                style="text-transform: capitalize;" />
                            <span class="helper-text">Please Specify Other language(s)</span>
                        </div>
                        <a (click)="addLang()" class="btn-flat waves-effect waves-light green"><i
                                class="material-icons">add</i></a>
                    </div>
                </div>

                <div class="row" id="literacy">
                    <h6><strong>10. What is your literacy level? (grade level for reading and writing) </strong>
                    </h6>
                    <table>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Speak</th>
                                <th>Write</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let language of languages.controls;let li=index" [formGroup]="language"
                                id="literacy_{{li}}">
                                <ng-container formGroupName="literacyLevel">
                                    <td> <i class="material-icons green-text left">language</i><span><strong>{{language.controls['language'].value}}</strong>
                                        </span></td>
                                    <td>
                                        <div class="input-field col s12 m6">
                                            <select formControlName="speak" (change)="changeLiteracy($event,li)">
                                                <option value="" disabled selected>Literacy Level
                                                    <small
                                                        *ngIf="languages.at(li).get('literacyLevel').get('speak').errors && languages.at(li).get('literacyLevel').get('speak').errors.required"
                                                        class="red-text">*</small>
                                                </option>
                                                <option *ngFor="let c of literacy" [value]="c">{{c}}
                                                </option>
                                            </select>
                                            <sup *ngIf="languages.at(li).get('literacyLevel').get('speak').errors && languages.at(li).get('literacyLevel').get('speak').errors.required"
                                                class="red-text"> *
                                                Required</sup>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="input-field col s12 m6">
                                            <select formControlName="write" (change)="changeLiteracy($event,li)">
                                                <option value="" disabled selected>Literacy Level
                                                    <small
                                                        *ngIf="languages.at(li).get('literacyLevel').get('write').errors && languages.at(li).get('literacyLevel').get('write').errors.required"
                                                        class="red-text">*</small>
                                                </option>
                                                <option *ngFor="let c of literacy" [value]="c">{{c}}</option>
                                            </select>
                                            <sup *ngIf="languages.at(li).get('literacyLevel').get('write').errors && languages.at(li).get('literacyLevel').get('write').errors.required"
                                                class="red-text"> *
                                                Required</sup>
                                        </div>
                                    </td>
                                </ng-container>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div class="row">
                    <h6><strong>11.Would you like to learn how to speak? <span class="red-text">*</span></strong></h6>
                    <table>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Yes</th>
                                <th>No</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let language of languages.controls;let li=index" [formGroup]="language"
                                id="learnToSpeak_{{li}}">
                                <td> <i class="material-icons green-text left">language</i><span><strong>{{language.controls['language'].value}}
                                        </strong>
                                        <small
                                            *ngIf="languages.at(li).get('learnToSpeak').errors && languages.at(li).get('learnToSpeak').errors.required"
                                            class="red-text">*</small>
                                    </span></td>
                                <td><label class="col s6">
                                        <input type="radio" id="{{ 'acceptable' + i}}" formControlName='learnToSpeak'
                                            value="Yes" (click)="scroll(li,'learnToSpeak')" />
                                        <span></span>
                                    </label></td>

                                <td><label class="col s6">
                                        <input type="radio" id="{{ 'acceptable' + i}}" formControlName='learnToSpeak'
                                            value="No" />
                                        <span></span>
                                    </label></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>

        <div class="row" formGroupName="careGiving" *ngIf="step == 4">
            <div class="row col s12 teal lighten-2 white-text">
                <h5 class="center-align">SECTION 3: Care Giving</h5>
            </div>
            <div class="card-content">
                <div class="input-field col s12" id="numberOfDependants">
                    <h6><strong>12. Number of dependant (persons relying on you for financial Support)?<span
                                class="red-text"> *</span></strong></h6>
                    <div class="row col s12">
                        <label class="col s6" *ngFor="let name of numOfDependants"
                            (click)="scrollToQuestion('numberOfDependants')">
                            <input name="numberOfDependants" type="radio" formControlName="numberOfDependants"
                                [value]="name" />
                            <span>{{name}}</span>
                        </label>
                    </div>
                </div>

                <div class="input-field col s12" formArrayName="careProvider" id="careProvider">
                    <h6><strong>13. Are you a care provider (responsible for the care of others)? Check all that apply:
                            <sup *ngIf="careProvider.errors && careProvider.errors.invalidValue" class="red-text"> *
                                select atleast
                                one</sup></strong></h6>

                    <div class="col s6" *ngFor="let careProvider of careProvider.controls;let li=index"
                        [formGroup]="careProvider" (click)="scrollToQuestion('careProvider')">
                        <label>
                            <input class="filled-in" type="checkbox" formControlName='value'
                                (click)="changeCareProvider($event,li)" />
                            <span>{{careProvider.controls['care'].value}}</span>
                        </label>
                    </div>

                    <button *ngIf="!addOtherCare" class="btn-flat btn-large  right" (click)="addOtherCare = true"
                        style="margin-top: 15px, bottom 15px;"><i
                            class="large material-icons right green-text">add_circle</i>Add Other</button>

                    <div *ngIf="addOtherCare" class="row">
                        <div class="input-field col s10 ">
                            <i class="material-icons prefix green-text">people</i>
                            <input id="otherCare" type="text" required class="validate" autocomplete="off"
                                style="text-transform: capitalize;" />
                            <span class="helper-text">Please Specify Other Care(s)</span>
                        </div>
                        <a (click)="addCare()" class="btn-flat waves-effect waves-light green"><i
                                class="material-icons">add</i></a>
                    </div>
                </div>

                <div class="input-field col s12" id="dayeCareAvailable">
                    <h6><strong>14. Daycare available in the community? </strong><span class="red-text">*</span></h6>
                    <div class="row col s12">
                        <label class="col s6">
                            <input name="dayeCareAvailable" type="radio" formControlName="dayeCareAvailable"
                                (change)="changeDayCareAvailability($event)" value="Yes" />
                            <span>Yes</span>
                        </label>
                        <label class="col s6">
                            <input name="dayeCareAvailable" type="radio" formControlName="dayeCareAvailable"
                                (change)="changeDayCareAvailability($event)" value="No" />
                            <span>No</span>
                        </label>
                    </div>
                </div>

                <div *ngIf="careGiving.controls['dayeCareAvailable'].value === 'No'" class="input-field col s12"
                    id="needsCare">
                    <h6><strong>15. If no, does the community need one? </strong><span class="red-text">*</span>
                    </h6>
                    <div class="row col s12">
                        <label class="col s6">
                            <input (change)="changeDayCareNeed($event)" name="needsCare" type="radio"
                                formControlName="needsCare" value="Yes" />
                            <span>Yes</span>
                        </label>
                        <label class="col s6">
                            <input (change)="changeDayCareNeed($event)" name="needsCare" type="radio"
                                formControlName="needsCare" value="No" />
                            <span>No</span>
                        </label>
                    </div>
                </div>

                <div *ngIf="careGiving.controls['dayeCareAvailable'].value === 'Yes'" class="input-field col s12"
                    id="typeOfCareAvailable">
                    <h6><strong>16. If yes, what type of care is available? </strong><span class="red-text">*</span>
                    </h6>
                    <div class="row col s12">
                        <div class="input-field col s12">
                            <i class="material-icons prefix green-text">people</i>
                            <textarea id="otherCare" formControlName="typeOfCareAvailable"
                                class="materialize-textarea validate" required autocomplete="off"
                                style="text-transform: capitalize;"></textarea>
                            <span class="helper-text">Please Specify Care Available
                                <span
                                    *ngIf="careGiving.get('typeOfCareAvailable').errors && careGiving.get('typeOfCareAvailable').errors.required"
                                    class="red-text"> *</span>
                            </span>
                        </div>
                    </div>
                </div>

                <div *ngIf="careGiving.controls['needsCare'].value === 'Yes' " class="input-field col s12"
                    id="neededCare">
                    <h6><strong>17. If no, what type of care is needed ?</strong><span class="red-text">*</span>
                    </h6>
                    <div class="row col s12">
                        <div class="input-field col s12">
                            <i class="material-icons prefix green-text">people</i>
                            <textarea id="otherCare" formControlName="neededCare" class="validate" required
                                autocomplete="off" style="text-transform: capitalize;"
                                class="materialize-textarea"></textarea>

                            <span class="helper-text">Please Specify Care Needed
                                <span
                                    *ngIf="careGiving.get('neededCare').errors && careGiving.get('neededCare').errors.required"
                                    class="red-text"> *</span>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" formGroupName="education" *ngIf="step==5">
            <div class="row col s12 teal lighten-2 white-text">
                <h5 class="center-align">SECTION 4: Education</h5>
            </div>
            <div class="card-content">
                <div class="input-field col s12" id="levelOfEducation" (click)="scrollToQuestion('levelOfEducation')">
                    <h6><strong>18. What is the <b>highest level </b>of education you have completed and area of study?
                            (Check
                            ONLY ONE) <span *ngIf="education.get('highestLevelOfEdu').errors"
                                class="red-text">*</span></strong></h6>
                    <div class="row col s12">
                        <label class="col m6 s12" *ngFor="let status of levelOfEducation">
                            <input name="highestLevelOfEdu" type="radio" formControlName="highestLevelOfEdu"
                                [value]="status" />
                            <span>{{status}}</span>
                        </label>
                    </div>
                </div>

                <div class="input-field col s12 " id="postSecondaryEdu" (click)="scrollToQuestion('postSecondaryEdu')">
                    <p> Please give details of any post-secondary (area of study, what year you completed etc.)</p>
                    <div class="input-field col s12 ">
                        <i class="material-icons prefix green-text">history_edu</i>
                        <textarea id="secondaryEdu" type="text" formControlName='postSecondaryEdu'
                            class="materialize-textarea validate" autocomplete="off" data-length="300"></textarea>
                    </div>
                </div>

                <div class="input-field row col s12" id="levelOfEduToAchieve">
                    <h6><strong>19. What level of education would you like to acheive? (Check ANY that are applicable)
                            <sup *ngIf="levelOfEduToAchieve.errors && levelOfEduToAchieve.errors.invalidValue"
                                class="red-text"> * select
                                atleast
                                one</sup></strong></h6>

                    <div class="col s6" *ngFor="let levelOfEduToAchieve of levelOfEduToAchieve.controls;let li=index"
                        [formGroup]="levelOfEduToAchieve" id="levelOfEduToAchieve_{{li}}">
                        <label>
                            <input class="filled-in" type="checkbox" formControlName='value'
                                (click)="changeEduToAchieve($event,li)" />
                            <span>{{levelOfEduToAchieve.controls['education'].value}}</span>
                        </label>
                    </div>

                    <button *ngIf="!addOtherEducation" class="btn-flat btn-large  right"
                        (click)="addOtherEducation = true" style="margin-top: 15px, bottom 15px;"><i
                            class="large material-icons right green-text">add_circle</i>Other Education or Certificates
                        (please list):</button>

                    <div *ngIf="addOtherEducation" class="row">
                        <div class="input-field col s10 ">
                            <i class="material-icons prefix green-text">school</i>
                            <input id="otherEdu" type="text" required class="validate" autocomplete="off"
                                style="text-transform: capitalize;" />
                            <span class="helper-text">Please Specify Other Education or Certificates(s) </span>
                        </div>

                        <a (click)="addEdu()" class="btn-flat waves-effect waves-light green col m1 s2 center"
                            style="margin-top: 15px;"><i class="material-icons">add</i></a>
                    </div>

                    <div class="input-field row col s12 ">
                        <p> What area of study, trade or career would you like to acheive?
                            <span *ngIf="education.get('areaOfStudyToAchieve').errors" class="red-text">*</span>
                        </p>

                        <textarea id="areaOfStudyToAchieve" type="text" required formControlName='areaOfStudyToAchieve'
                            (click)="scrollToQuestion('currentlyEnrolled')" class="materialize-textarea validate"
                            autocomplete="off" data-length="300"></textarea>
                        <div
                            *ngIf="education.get('areaOfStudyToAchieve').dirty || education.get('areaOfStudyToAchieve').touched && education.get('areaOfStudyToAchieve').invalid">
                            <small
                                *ngIf="education.get('areaOfStudyToAchieve').errors && education.get('areaOfStudyToAchieve').errors.required"
                                class="red-text">Post-secondary is Required.</small>
                        </div>
                    </div>
                </div>

                <div class="input-field col s12" id="currentlyEnrolled">
                    <h6><strong>20. Are you currently enrolled in an education program?
                            <span *ngIf="education.get('currentlyEnrolled').errors" class="red-text">*</span>
                        </strong></h6>
                    <div class="row col s12">
                        <label class="col m4 s6 ">
                            <input name="currentlyEnrolled" type="radio" formControlName="currentlyEnrolled"
                                (click)="changeEducationEnrollment($event)" value="Full-Time" />
                            <span>Full-Time</span>
                        </label>
                        <label class="col m4 s6">
                            <input name="currentlyEnrolled" type="radio" formControlName="currentlyEnrolled"
                                (click)="changeEducationEnrollment($event)" value="Part-Time" />
                            <span>Part-Time</span>
                        </label>
                        <label class="col m4 s6">
                            <input name="currentlyEnrolled" type="radio" formControlName="currentlyEnrolled"
                                (click)="changeEducationEnrollment($event)" value="No" />
                            <span>No</span>
                        </label>
                    </div>

                    <div *ngIf="['Part-Time', 'Full-Time'].includes(education.controls['currentlyEnrolled'].value)"
                        class="input-field col s12" id="typeOfProgram">
                        <h6>Type of Program <span class="red-text">*</span></h6>
                        <div class="input-field row col s12">
                            <i class="material-icons prefix green-text">history_edu</i>
                            <input type="text" required formControlName="typeOfProgram" class="validate"
                                autocomplete="off" style="text-transform: capitalize;" />
                            <div
                                *ngIf="education.get('typeOfProgram').dirty || education.get('typeOfProgram').touched && education.get('typeOfProgram').invalid">
                                <small
                                    *ngIf="education.get('typeOfProgram').errors && education.get('typeOfProgram').errors.required"
                                    class="red-text">Type of Program is Required.</small>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="education.controls['currentlyEnrolled'].value === 'No' " class="input-field col s12"
                    id="upgradeEdu">

                    <h6><strong>21. If not, would you like to upgrade/further your education?
                        </strong></h6>
                    <div class="row col s12">
                        <label class="col m4 s6 ">
                            <input name="upgradeEdu" type="radio" formControlName="upgradeEdu"
                                (click)="upgradeEducation($event)" value="Yes" />
                            <span>Yes</span>
                        </label>

                        <label class="col m4 s6">
                            <input name="upgradeEdu" type="radio" formControlName="upgradeEdu"
                                (click)="upgradeEducation($event)" value="No" />
                            <span>No</span>
                        </label>
                    </div>
                </div>

                <div *ngIf="['Yes','No'].includes(education.controls['upgradeEdu'].value)" class="input-field col s12"
                    id="upgradeEduContainer">

                    <h6><strong>22. If you answered YES to the last question: Based on your answer to
                            upgrading/furthering your education: <span class="red-text">*</span>
                        </strong></h6>
                    <div *ngIf="education.controls['upgradeEdu'].value === 'Yes' ">
                        <div class="row col s12">
                            <h6>If yes, what type of program would you like to do?
                                <span *ngIf="education.get('typeOfProgramToUpgrade').errors" class="red-text">*</span>
                            </h6>
                            <div class="input-field row col s12">
                                <i class="material-icons prefix green-text">question_answer</i>

                                <input type="text" required formControlName="typeOfProgramToUpgrade" class="validate"
                                    autocomplete="off" style="text-transform: capitalize;" />
                                <div
                                    *ngIf="education.get('typeOfProgramToUpgrade').dirty || education.get('typeOfProgramToUpgrade').touched && education.get('typeOfProgramToUpgrade').invalid">
                                    <small
                                        *ngIf="education.get('typeOfProgramToUpgrade').errors && education.get('typeOfProgramToUpgrade').errors.required"
                                        class="red-text">Required.</small>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div *ngIf="education.controls['upgradeEdu'].value === 'No' ">
                        <div class="row col s12">
                            <h6>If no, please explain why not:
                            </h6>
                            <div class="input-field row col s12">
                                <i class="material-icons prefix green-text">question_answer</i>

                                <input type="text" required formControlName="whyNoUpgrade" class="validate"
                                    autocomplete="off" style="text-transform: capitalize;" />
                                <div
                                    *ngIf="education.get('whyNoUpgrade').dirty || education.get('whyNoUpgrade').touched && education.get('whyNoUpgrade').invalid">
                                    <small
                                        *ngIf="education.get('whyNoUpgrade').errors && education.get('whyNoUpgrade').errors.required"
                                        class="red-text">Required.</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="input-field col s12">
                    <h6><strong>23. Do you know who to contact to obtain upgrading or furthering your education?
                            <span *ngIf="education.get('knowWhoToContactForUpgrade').errors" class="red-text">*</span>
                        </strong></h6>
                    <div class="row col s12">
                        <label class="col m4 s6 ">
                            <input name="knowWhoToContactForUpgrade" type="radio"
                                formControlName="knowWhoToContactForUpgrade" value="Yes" />
                            <span>Yes</span>
                        </label>
                        <label class="col m4 s6">
                            <input name="knowWhoToContactForUpgrade" type="radio"
                                formControlName="knowWhoToContactForUpgrade" value="No" />
                            <span>No</span>
                        </label>
                    </div>
                </div>

                <div class="input-field col s12">
                    <h6><strong>24. Do you know how to apply to a College/University?
                            <span *ngIf="education.get('collegeApply').errors" class="red-text">*</span>
                        </strong></h6>
                    <div class="row col s12">
                        <label class="col m4 s6 ">
                            <input name="collegeApply" type="radio" formControlName="collegeApply" value="Yes" />
                            <span>Yes</span>
                        </label>

                        <label class="col m4 s6">
                            <input name="collegeApply" type="radio" formControlName="collegeApply" value="No" />
                            <span>No</span>
                        </label>
                    </div>
                </div>

                <div class="input-field col s12">
                    <h6><strong>25. Do you know how to apply for funding?
                            <span *ngIf="education.get('applyForFunding').errors" class="red-text">*</span>
                        </strong></h6>
                    <div class="row col s12">
                        <label class="col m4 s6 ">
                            <input name="applyForFunding" type="radio" formControlName="applyForFunding" value="Yes" />
                            <span>Yes</span>
                        </label>

                        <label class="col m4 s6">
                            <input name="applyForFunding" type="radio" formControlName="applyForFunding" value="No" />
                            <span>No</span>
                        </label>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" formGroupName="license" *ngIf="step==6">
            <div class="row col s12 teal lighten-2 white-text">
                <h5 class="center-align">SECTION 5: Class of Operators License</h5>
            </div>
            <div class="card-content">
                <div class="input-field col s12" id="typeOfLicense" (click)="scrollToQuestion('typeOfLicense')">
                    <h6><strong>26. What type(s) license(s) do you currently possess?
                            <sup *ngIf="typeOfLicense.errors " class="red-text"> * select
                                atleast one</sup>
                        </strong></h6>
                    <div class="col s6" *ngFor="let typeOfLicense of typeOfLicense.controls;let li=index"
                        [formGroup]="typeOfLicense">

                        <label>
                            <input class="filled-in" type="checkbox" formControlName='value'
                                (click)="changeCurrentLicense($event,li)" />
                            <span>{{typeOfLicense.controls['license'].value}}</span>
                        </label>
                    </div>
                </div>

                <div class="input-field col s12" id="barrierForLicense" (click)="scrollToQuestion('barrierForLicense')">
                    <h6><strong>27. Barriers to obtaining a license
                            <sup *ngIf="barrierForLicense.errors && barrierForLicense.errors.invalidValue"
                                class="red-text"> * select
                                atleast
                                one</sup>
                        </strong></h6>

                    <div class="col s6" *ngFor="let barrierForLicense of barrierForLicense.controls;let li=index"
                        [formGroup]="barrierForLicense">

                        <label>
                            <input class="filled-in" type="checkbox" formControlName='value' />
                            <span>{{barrierForLicense.controls['barrier'].value}}</span>
                        </label>
                    </div>

                    <button *ngIf="!otherLicenseBarriers" class="btn-flat btn-large  right"
                        (click)="otherLicenseBarriers = true" style="margin-top: 15px, bottom 15px;"><i
                            class="large material-icons right green-text">add_circle</i>Other Barriers (please
                        list):</button>

                    <div *ngIf="otherLicenseBarriers" class="row">
                        <div class="input-field col s10 ">
                            <i class="material-icons prefix green-text">fence</i>
                            <input id="otherBarrier" type="text" required class="validate" autocomplete="off"
                                style="text-transform: capitalize;" />
                            <label>Please Specify Other Barriers</label>
                        </div>

                        <a (click)="addLicenseBarrier()"
                            class="btn-flat waves-effect waves-light green col m1 s2 center"
                            style="margin-top: 15px;"><i class="material-icons">add</i></a>
                    </div>
                </div>

                <div class="input-field col s12" id="typeOfLicenseToObtain"
                    (click)="scrollToQuestion('typeOfLicenseToObtain')">
                    <h6><strong>28. What type(s) license(s) would do like to obtain?
                            <sup *ngIf="typeOfLicenseToObtain.errors " class="red-text"> * select
                                atleast
                                one</sup></strong></h6>

                    <div class="col s6"
                        *ngFor="let typeOfLicenseToObtain of typeOfLicenseToObtain.controls;let li=index"
                        [formGroup]="typeOfLicenseToObtain">

                        <label>
                            <input class="filled-in" type="checkbox" formControlName='value'
                                (click)="changeLicenseToObtain($event,li)" />
                            <span>{{typeOfLicenseToObtain.controls['license'].value}}</span>
                        </label>
                    </div>
                </div>

                <div class="input-field col s12" id="beNotifiedForTraining"
                    (click)="scrollToQuestion('beNotifiedForTraining')">

                    <h6><strong>29. Would you like to be notified when training is offered?
                            <span *ngIf="license.get('beNotifiedForTraining').errors" class="red-text">*</span></strong>
                    </h6>
                    <div class="row col s12">
                        <label class="col m4 s6 ">
                            <input name="beNotifiedForTraining" type="radio" formControlName="beNotifiedForTraining"
                                value="Yes" (click)="changebeNotifiedForTraining($event)" />
                            <span>Yes</span>
                        </label>

                        <label class="col m4 s6">
                            <input name="beNotifiedForTraining" type="radio" formControlName="beNotifiedForTraining"
                                value="No" (click)="changebeNotifiedForTraining($event)" />
                            <span>No</span>
                        </label>
                    </div>
                </div>

                <div *ngIf="license.controls['beNotifiedForTraining'].value === 'Yes'" class="input-field col s12"
                    id="howToBeNotified" (click)="scrollToQuestion('howToBeNotified')">

                    <h6><strong>30. If yes, how would you like to be notified?
                            <span *ngIf="license.get('howToBeNotified').errors" class="red-text">*</span>
                        </strong>
                    </h6>
                    <div class="row col s12">
                        <label class="col m4 s6 ">
                            <input name="howToBeNotified" type="radio" formControlName="howToBeNotified"
                                value="Email" />
                            <span>Email</span>
                        </label>

                        <label class="col m4 s6">
                            <input name="howToBeNotified" type="radio" formControlName="howToBeNotified"
                                value="Phone or text" />
                            <span>Phone or text</span>
                        </label>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" formArrayName="safetyTraining" *ngIf="step==7">
            <div class="row col s12 teal lighten-2 white-text">
                <h5 class="center-align">SECTION 6: Training - Safety Training and/or Tickets</h5>
            </div>
            <div class="card-content">
                <h6><strong>31. Do you currently have any of the following safety training/tickets: </strong></h6>
                <table>
                    <thead>
                        <tr>
                            <th></th>
                            <th>Currently valid?</th>
                            <th>Would like training?</th>
                            <th>Expiry Date</th>
                            <th>No Training</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let training of safetyTraining.controls;let li=index"
                            [formGroup]="training">
                            <tr id="training_{{li}}">
                                <!-- <tr *ngFor="let training of safetyTraining.controls;let li=index" [formGroup]="training" id="datePicker_{{li}}"> -->
                                <td><strong>{{training.controls['training'].value}} </strong>
                                    <span
                                        *ngIf="safetyTraining.at(li).get('dateValue').get('value').errors && safetyTraining.at(li).get('dateValue').get('value').errors.invalidValue"
                                        class="red-text">*</span>
                                    <div
                                        *ngIf="(safetyTraining.at(li).get('dateValue').get('value').dirty || safetyTraining.at(li).get('dateValue').get('value').touched) && safetyTraining.at(li).get('dateValue').get('value').invalid">
                                        <small *ngIf="firstName.errors.required" class="red-text">
                                            Required.</small>
                                    </div>
                                </td>
                                <ng-container formGroupName="dateValue">
                                    <td><label class="col s6">
                                            <input type="radio" id="{{ 'acceptable' + li}}" formControlName='value'
                                                value="Currently valid" (click)='openDatePicker(li,false)' />
                                            <span></span>
                                        </label></td>
                                    <td><label class="col s6">
                                            <input type="radio" id="{{ 'acceptable' + i}}" formControlName='value'
                                                value="Would like training" (click)='openDatePicker(li,false)' />
                                            <span></span>
                                        </label></td>
                                    <td><label class="col s6">
                                            <input type="radio" id="{{ 'acceptable' + i}}" formControlName='value'
                                                value="Expired" (click)='openDatePicker(li,true)' />
                                            <span></span>
                                        </label></td>
                                    <td><label class="col s6">
                                            <input type="radio" id="{{ 'acceptable' + i}}" formControlName='value'
                                                value="No Training" (click)='openDatePicker(li,false)' />
                                            <span></span>
                                        </label></td>
                                    <td>
                                        <label class="col s6">
                                            <input hidden type="text" id="datePicker_{{li}}" class="datepicker"
                                                formControlName='date' (change)="onDateChange($event,li)">
                                            <span></span>
                                        </label>
                                    </td>
                                </ng-container>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>

                <button *ngIf="!addOtherSafteyTraining" class="btn-flat btn-large  right"
                    (click)="addOtherSafteyTraining = true" style="margin-top: 15px, bottom 15px;"><i
                        class="large material-icons right green-text">add_circle</i>Add Other</button>

                <div *ngIf="addOtherSafteyTraining">
                    <div class="input-field col s10 ">
                        <i class="material-icons prefix green-text">fence</i>
                        <input id="otherTraining" type="text" required class="validate" autocomplete="off"
                            style="text-transform: capitalize;" />
                        <span class="helper-text">Please Specify Other Safety Training</span>
                    </div>

                    <a (click)="addSafteyTraining()" class="btn-flat waves-effect waves-light green col m1 s2 center"
                        style="margin-top: 15px;"><i class="material-icons">add</i></a>
                </div>
            </div>
        </div>

        <div class="row" formGroupName="trainingAndEmployment" *ngIf="step==8">
            <div class="row col s12 teal lighten-2 white-text">
                <h5 class="center-align">SECTION 7: Training & Employment Opportunities/ Employment & Skills</h5>
            </div>

            <div class="card-content">
                <div class="input-field col s12" id="notificationPreference">
                    <h6><strong>32. How would you prefer to be notified about training and/or employment opprotunities?
                            (Please check all
                            that apply) <sup
                                *ngIf="notificationPreference.errors && notificationPreference.errors.invalidValue"
                                class="red-text"> * select
                                atleast one</sup></strong></h6>
                    <div class="col s6"
                        *ngFor="let notificationPreference of notificationPreference.controls;let li=index"
                        id="notification_{{li}}" [formGroup]="notificationPreference"
                        (click)="scrollToQuestion('notificationPreference')">

                        <label>
                            <input class="filled-in" type="checkbox" formControlName='value'
                                (click)="changeNotificationPreference($event,li)" />
                            <span>{{notificationPreference.controls['method'].value}}</span>
                        </label>
                    </div>

                    <button *ngIf="!addOtherNotification" class="btn-flat btn-large  right"
                        (click)="addOtherNotification = true" style="margin-top: 15px, bottom 15px;"><i
                            class="large material-icons right green-text">add_circle</i>Other Notification Method
                        (please
                        list):</button>

                    <div *ngIf="addOtherNotification" class="row">
                        <div class="input-field col s10 ">
                            <i class="material-icons prefix green-text">notifications</i>
                            <input id="otherNotification" type="text" required class="validate" autocomplete="off"
                                style="text-transform: capitalize;" />
                            <span class="helper-text">Please Specify Other Notification Method </span>
                        </div>

                        <a (click)="addPreferedNotification()"
                            class="btn-flat waves-effect waves-light green col m1 s2 center"
                            style="margin-top: 15px;"><i class="material-icons">add</i></a>
                    </div>

                    <div class="input-field col s12 " id="notificationEmail">
                        <p> Email: please provide your address to be part of a mailing list for education, training and
                            employment opportunities:
                            <span *ngIf="notifcationEmail.errors" class="red-text"> *
                            </span>
                        </p>

                        <textarea type="email" formControlName='notifcationEmail' required
                            (click)="scrollToQuestion('notificationEmail')" class="materialize-textarea validate"
                            autocomplete="off" data-length="300"></textarea>
                        <div *ngIf="(notifcationEmail.dirty || notifcationEmail.touched) && notifcationEmail.invalid">
                            <small *ngIf="notifcationEmail.errors.required" class="red-text">Email is Required.</small>
                            <small *ngIf="notifcationEmail.errors.email" class="red-text">Invalid Email Address</small>
                        </div>
                    </div>
                </div>

                <div class="input-field col s12" id="currentlyEmployed">
                    <h6><strong>33. Are you currently employed?
                            <span *ngIf="trainingAndEmployment.get('currentlyEmployed').errors" class="red-text"> *
                            </span>
                        </strong></h6>
                    <div class="row col s12" (click)="scrollToQuestion('currentlyEmployed')">
                        <label class="col m4 s6 ">
                            <input name="currentlyEmployed" type="radio" formControlName="currentlyEmployed"
                                (click)="changeEmployment($event)" value="Employed" />
                            <span>Employed</span>
                        </label>
                        <label class="col m4 s6">
                            <input name="currentlyEmployed" type="radio" formControlName="currentlyEmployed"
                                (click)="changeEmployment($event)" value="Not in the labour force" />
                            <span>Not in the labour force</span>
                        </label>
                        <label class="col m4 s6">
                            <input name="currentlyEmployed" type="radio" formControlName="currentlyEmployed"
                                (click)="changeEmployment($event)" value="Unemployed" />
                            <span>Unemployed</span>
                        </label>
                    </div>

                    <div class="input-field col s12 " (click)="scrollToQuestion('currentlyEmployed')">
                        <p> Comments/Clarification
                            <span *ngIf="trainingAndEmployment.get('currentEmploymentComment').errors" class="red-text">
                                *
                            </span>
                        </p>
                        <textarea type="text" formControlName='currentEmploymentComment' required
                            class="materialize-textarea validate" autocomplete="off" data-length="300"></textarea>
                        <div
                            *ngIf="(trainingAndEmployment.get('currentEmploymentComment').dirty || trainingAndEmployment.get('currentEmploymentComment').touched) && trainingAndEmployment.get('currentEmploymentComment').invalid">
                            <small *ngIf="trainingAndEmployment.get('currentEmploymentComment').errors.required"
                                class="red-text">Comments/Clarification is Required.</small>
                        </div>
                    </div>
                </div>

                <div *ngIf="trainingAndEmployment.controls['currentlyEmployed'].value === 'Employed'"
                    class="input-field col s12" id="employmentStatus">
                    <h6><strong>34. If you answered YES to the previous question: Employment Status - which of the
                            following best descibes
                            your current status?</strong>
                        <span *ngIf="trainingAndEmployment.get('employmentStatus').errors" class="red-text"> *
                        </span>
                    </h6>
                    <div class="row col s12" (click)="scrollToQuestion('employmentStatus')">
                        <label class="col m6 s12" *ngFor="let status of employmentStatuses">
                            <input name="employmentStatus" type="radio" formControlName="employmentStatus"
                                [value]="status" (click)="pickedEmploymentStatus($event,status)" />
                            <span>{{status}}</span>
                        </label>
                    </div>

                    <div *ngIf="otherEmploymentStatus" class="input-field col s10 ">
                        <input id="el" type="text" required class="validate" autocomplete="off"
                            (change)="trainingAndEmployment.controls['employmentStatus'].patchValue($event.target.value)"
                            style="text-transform: capitalize;" />
                        <label>Other form of paid employment (please specify)<span class="red-text">*</span></label>
                    </div>
                </div>

                <div class="input-field col s12" id="currentlySeekingEmployment">
                    <h6><strong> 35. Are you currently seeking employment?
                            <span *ngIf="trainingAndEmployment.get('currentlySeekingEmployment').errors"
                                class="red-text"> *
                            </span></strong></h6>
                    <div class="row col s12" (click)="scrollToQuestion('currentlySeekingEmployment')">
                        <label class="col m4 s6 ">
                            <input name="currentlySeekingEmployment" type="radio"
                                formControlName="currentlySeekingEmployment" value="Yes" />
                            <span>Yes</span>
                        </label>
                        <label class="col m4 s6">
                            <input name="currentlySeekingEmployment" type="radio"
                                formControlName="currentlySeekingEmployment" value="No" />
                            <span>No</span>
                        </label>
                        <div class="input-field col s12 ">
                            <p> If yes, for how long? If no, what support do you need for you to enter/reenter the
                                workforce?
                                <span *ngIf="trainingAndEmployment.get('reasonForSeekingEmployment').errors"
                                    class="red-text"> *
                                </span>
                            </p>

                            <textarea type="text" required formControlName='reasonForSeekingEmployment'
                                class="materialize-textarea validate" autocomplete="off" data-length="300"></textarea>
                            <div
                                *ngIf="(trainingAndEmployment.get('reasonForSeekingEmployment').dirty || trainingAndEmployment.get('reasonForSeekingEmployment').touched) && trainingAndEmployment.get('reasonForSeekingEmployment').invalid">
                                <small *ngIf="trainingAndEmployment.get('reasonForSeekingEmployment').errors.required"
                                    class="red-text">Required.</small>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row input-field col s12">
                    <h6><strong>36 Employment Sector: What best describes your desired employment goals?
                            <span *ngIf="employmentGoals.errors" class="red-text"> *
                            </span></strong></h6>
                    <table>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Current</th>
                                <th>Desired</th>
                                <th>N/A</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container formArrayName="employmentGoals">
                                <tr *ngFor="let goal of employmentGoals.controls;let li=index" [formGroup]="goal"
                                    id="employmentGoals_{{li}}">
                                    <td><strong>{{goal.controls['goal'].value}}
                                            <span
                                                *ngIf="employmentGoals.at(li).get('value').errors && employmentGoals.at(li).get('value').errors.invalidValue"
                                                class="red-text">*</span></strong></td>

                                    <td><label class="col s6">
                                            <input type="radio" id="{{ 'acceptable' + i}}" formControlName='value'
                                                value="Current" (click)="scroll(li,'employmentGoals')" />
                                            <span></span>
                                        </label></td>

                                    <td><label class="col s6">
                                            <input type="radio" id="{{ 'acceptable' + i}}" formControlName='value'
                                                value="Desired" (click)="scroll(li,'employmentGoals')" />
                                            <span></span>
                                        </label></td>

                                    <td><label class="col s6">
                                            <input type="radio" id="{{ 'acceptable' + i}}" formControlName='value'
                                                value="N/A" (click)="scroll(li,'employmentGoals')" />
                                            <span></span>
                                        </label></td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>

                    <button *ngIf="!addOtherEmploymentGoal" class="btn-flat btn-large  right"
                        (click)="addOtherEmploymentGoal = true" style="margin-top: 15px, bottom 15px;"><i
                            class="large material-icons right green-text">add_circle</i>Other Employment Goals (please
                        list):</button>

                    <div *ngIf="addOtherEmploymentGoal" class="row">
                        <div class="input-field col s10 ">
                            <i class="material-icons prefix green-text">star</i>
                            <input id="otherGoal" type="text" required class="validate" autocomplete="off"
                                style="text-transform: capitalize;" />
                            <span class="helper-text">Please Specify Other Desired Employment Goals</span>
                        </div>
                        <a (click)="addEmploymentGoal()"
                            class="btn-flat waves-effect waves-light green col m1 s2 center"
                            style="margin-top: 15px;"><i class="material-icons">add</i></a>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" formGroupName="jobSatisfaction" *ngIf="step==9">
            <!-- <h6 class="card-title center-align">9. Job / Life Satisfaction</h6> -->
            <div class="row col s12 teal lighten-2 white-text">
                <h5 class="center-align">SECTION 8: Job / Life Satisfaction</h5>
            </div>

            <div class="card-content">
                <div class="input-field col s12" id="satisfiedWithEmployment">
                    <h6><strong> 37. Are you satisfied with your current employment situation?
                            <span *ngIf="jobSatisfaction.get('satisfiedWithEmployment').errors" class="red-text"> *
                            </span>
                        </strong></h6>
                    <div class="row col s12" (click)="scrollToQuestion('satisfiedWithEmployment')">
                        <label class="col m4 s6 ">
                            <input name="satisfiedWithEmployment" type="radio" formControlName="satisfiedWithEmployment"
                                value="Yes" />
                            <span>Yes</span>
                        </label>
                        <label class="col m4 s6">
                            <input name="satisfiedWithEmployment" type="radio" formControlName="satisfiedWithEmployment"
                                value="No" />
                            <span>No</span>
                        </label>
                        <div class="input-field col s12 ">
                            <p> Please explain why you are satisfied or not satisfied with your current
                                job/situation: <span *ngIf="jobSatisfaction.get('satisfiedReason').errors"
                                    class="red-text"> *
                                </span>
                            </p>

                            <textarea type="text" formControlName='satisfiedReason' required
                                class="materialize-textarea validate" autocomplete="off" data-length="300"></textarea>
                            <div
                                *ngIf="(jobSatisfaction.get('satisfiedReason').dirty || jobSatisfaction.get('satisfiedReason').touched) && jobSatisfaction.get('satisfiedReason').invalid">
                                <small *ngIf="jobSatisfaction.get('satisfiedReason').errors.required"
                                    class="red-text">Explanation is Required.</small>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="input-field col s12">
                    <h6><strong>38. Are any of these reasons for your unemployment?
                            <sup *ngIf="factorsForSatisfaction.errors && factorsForSatisfaction.errors.invalidValue"
                                class="red-text"> * select
                                atleast
                                one</sup></strong></h6>

                    <ng-container formArrayName="factorsForSatisfaction">
                        <div class="col s12" *ngFor="let factor of factorsForSatisfaction.controls;let li=index"
                            [formGroup]="factor" id="factorsForSatisfaction_{{li}}">
                            <label>
                                <input class="filled-in" type="checkbox" formControlName='value'
                                    (click)="changeFactor($event,li)" />
                                <span>{{factor.controls['factor'].value}}</span>
                            </label>

                            <div *ngIf="factorsForSatisfaction.at(li).get('value').value && satisfactionFactors.slice(0,4).includes(factor.controls['factor'].value)"
                                class="input-field col s10 ">
                                <input type="text" autocomplete="off" required style="text-transform: capitalize;"
                                    formControlName='detail' />
                                <label>Please Describe</label>
                                <small
                                    *ngIf="factorsForSatisfaction.at(li).get('detail').errors && factorsForSatisfaction.at(li).get('detail').errors.required"
                                    class="red-text">Description is Required.</small>
                            </div>
                        </div>
                    </ng-container>

                    <div class="input-field col s12 ">
                        <p> Please provide details here.
                            <span *ngIf="jobSatisfaction.get('detailsForSatisfaction').errors" class="red-text"> *
                            </span>
                        </p>
                        <textarea type="text" formControlName='detailsForSatisfaction' required
                            class="materialize-textarea validate" autocomplete="off" data-length="300"></textarea>
                        <div
                            *ngIf="(jobSatisfaction.get('detailsForSatisfaction').dirty || jobSatisfaction.get('detailsForSatisfaction').touched) && jobSatisfaction.get('detailsForSatisfaction').invalid">
                            <small *ngIf="jobSatisfaction.get('detailsForSatisfaction').errors.required"
                                class="red-text">Details is Required.</small>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" formGroupName="barriersOfJob" *ngIf="step==10">
            <div class="row col s12 teal lighten-2 white-text">
                <h5 class="center-align">SECTION 9: Barriers to Getting or Keeping a Job</h5>
            </div>

            <div class="card-content">
                <div class="row input-field col s12">
                    <h6><strong>39. 1/2 What do you believe is stopping you from getting or keeping a job? Check all
                            that apply
                        </strong></h6>
                    <table>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Top Barrier(s)</th>
                                <th>Somewhat</th>
                                <th>Not an issue</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container formArrayName="barriers">
                                <tr *ngFor="let barrier of barriers.controls;let li=index" [formGroup]="barrier"
                                    id="barriers_{{li}}">
                                    <td><strong>{{barrier.controls['barrier'].value}}
                                            <span
                                                *ngIf="barriers.at(li).get('value').errors && barriers.at(li).get('value').errors.invalidValue"
                                                class="red-text">*</span>
                                            <div
                                                *ngIf="(barriers.at(li).get('value').dirty || barriers.at(li).get('value').touched) && barriers.at(li).get('value').invalid">
                                                <small *ngIf="firstName.errors.required" class="red-text">
                                                    Required.</small>
                                            </div>
                                        </strong></td>
                                    <td><label class="col s6">
                                            <input type="radio" id="{{ 'acceptable' + i}}" formControlName='value'
                                                value="Top Barrier(s)" (click)="scroll(li,'barriers')" />
                                            <span></span>
                                        </label></td>
                                    <td><label class="col s6">
                                            <input type="radio" id="{{ 'acceptable' + i}}" formControlName='value'
                                                value="Somewhat" (click)="scroll(li,'barriers')" />
                                            <span></span>
                                        </label></td>

                                    <td><label class="col s6">
                                            <input type="radio" id="{{ 'acceptable' + i}}" formControlName='value'
                                                value="Not an issue" (click)="scroll(li,'barriers')" />
                                            <span></span>
                                        </label></td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>

                    <button *ngIf="!addOtherJobBarrier" class="btn-flat btn-large  right"
                        (click)="addOtherJobBarrier = true" style="margin-top: 15px, bottom 15px;"><i
                            class="large material-icons right green-text">add_circle</i>Other Job Barrier (please
                        list):</button>

                    <div *ngIf="addOtherJobBarrier" class="row">
                        <div class="input-field col s10 ">
                            <i class="material-icons prefix green-text">fence</i>
                            <input id="otherJobBarrier" type="text" required class="validate" autocomplete="off"
                                style="text-transform: capitalize;" />
                            <span class="helper-text">Please Specify Other Job Barrier</span>
                        </div>

                        <a (click)="addJobBarrier()" class="btn-flat waves-effect waves-light green col m1 s2 center"
                            style="margin-top: 15px;"><i class="material-icons">add</i></a>
                    </div>
                </div>

                <div class="row">
                    <h6><strong>40. Has your employment been affected by COVID -19?
                            <span *ngIf="barriersOfJob.get('covidAffected').errors" class="red-text">*</span>
                        </strong></h6>
                    <div class="row col s12" id="covidAffected" (click)="scrollToQuestion('covidAffected')">
                        <label class="col m4 s6 ">
                            <input name="covidAffected" type="radio" formControlName="covidAffected" value="Yes" />
                            <span>Yes</span>
                        </label>
                        <label class="col m4 s6">
                            <input name="covidAffected" type="radio" formControlName="covidAffected" value="No" />
                            <span>No</span>
                        </label>
                        <div class="input-field col s12 ">
                            <p> Please explain <span *ngIf="barriersOfJob.get('explanation').errors"
                                    class="red-text">*</span>
                            </p>

                            <textarea type="text" formControlName='explanation' class="materialize-textarea validate"
                                required autocomplete="off" data-length="300"></textarea>
                            <div
                                *ngIf="(barriersOfJob.get('explanation').dirty || barriersOfJob.get('explanation').touched) && barriersOfJob.get('explanation').invalid">
                                <small *ngIf="barriersOfJob.get('explanation').errors.required"
                                    class="red-text">Explanation is Required.</small>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <div class="row" formGroupName="employmentHistory" *ngIf="step==11">
            <div class="row col s12 teal lighten-2 white-text">
                <h5 class="center-align">SECTION 10: Employment Histroy</h5>
            </div>
            <div class="card-content">
                <div class="row input-field col s12">
                    <h6><strong>41. 1/2 What category best describes your employment history? How many years of
                            experience? <span *ngIf="catagory.errors" class="red-text">*</span>
                        </strong></h6>
                    <table>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Less than 1 yr</th>
                                <th>1-5 years</th>
                                <th>More than 5 years</th>
                                <th>N/A</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container formArrayName="catagory">
                                <tr *ngFor="let c of catagory.controls;let li=index" [formGroup]="c"
                                    id="catagory_{{li}}">
                                    <td><strong>{{c.controls['history'].value}}
                                            <span
                                                *ngIf="catagory.at(li).get('value').errors && catagory.at(li).get('value').errors.invalidValue"
                                                class="red-text">*</span>
                                        </strong></td>
                                    <td><label class="col s6">
                                            <input type="radio" id="{{ 'acceptable' + i}}" formControlName='value'
                                                value="Less than 1 yr" (click)="scroll(li,'catagory')" />
                                            <span></span>
                                        </label></td>
                                    <td><label class="col s6">
                                            <input type="radio" id="{{ 'acceptable' + i}}" formControlName='value'
                                                value="1-5 years" (click)="scroll(li,'catagory')" />
                                            <span></span>
                                        </label></td>
                                    <td><label class="col s6">
                                            <input type="radio" id="{{ 'acceptable' + i}}" formControlName='value'
                                                value="More than 5 years" (click)="scroll(li,'catagory')" />
                                            <span></span>
                                        </label></td>
                                    <td><label class="col s6">
                                            <input type="radio" id="{{ 'acceptable' + i}}" formControlName='value'
                                                value="N/A" (click)="scroll(li,'catagory')" />
                                            <span></span>
                                        </label></td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>

                    <button *ngIf="!addOtherEmploymentHistory" class="btn-flat btn-large  right"
                        (click)="addOtherEmploymentHistory = true" style="margin-top: 15px, bottom 15px;"><i
                            class="large material-icons right green-text">add_circle</i>Other Employment Catagory
                        (please
                        list):</button>

                    <div *ngIf="addOtherEmploymentHistory" class="row">
                        <div class="input-field col s10 ">
                            <i class="material-icons prefix green-text">work</i>
                            <input id="otherEmploymentHistory" type="text" required class="validate" autocomplete="off"
                                style="text-transform: capitalize;" />
                            <span class="helper-text">Please Specify Other Employment History </span>

                        </div>

                        <a (click)="addEmploymentHistory()"
                            class="btn-flat waves-effect waves-light green col m1 s2 center"
                            style="margin-top: 15px;"><i class="material-icons">add</i></a>
                    </div>
                </div>

                <div class="input-field col s12">
                    <h6><strong>42. Do you have any previous experience - please list. <span
                                class="red-text">*</span></strong></h6>
                    <table>
                        <thead>
                            <tr>
                                <th></th>
                                <th>Have expirience</th>
                                <th>Desire training</th>
                                <th>N/A</th>

                            </tr>
                        </thead>
                        <tbody>
                            <ng-container formArrayName="previousExpirence">
                                <tr *ngFor="let exp of previousExpirence.controls;let li=index" [formGroup]="exp"
                                    id="previousExpirence_{{li}}">
                                    <td><strong>{{exp.controls['expirience'].value}}
                                            <span
                                                *ngIf="previousExpirence.at(li).get('value').errors && previousExpirence.at(li).get('value').errors.invalidValue"
                                                class="red-text">*</span>
                                        </strong></td>
                                    <td><label class="col s6">
                                            <input type="radio" id="{{ 'acceptable' + i}}" formControlName='value'
                                                value="Have experience" (click)="scroll(li,'previousExpirence')" />
                                            <span></span>
                                        </label></td>
                                    <td><label class="col s6">
                                            <input type="radio" id="{{ 'acceptable' + i}}" formControlName='value'
                                                value="Desire training" (click)="scroll(li,'previousExpirence')" />
                                            <span></span>
                                        </label></td>
                                    <td><label class="col s6">
                                            <input type="radio" id="{{ 'acceptable' + i}}" formControlName='value'
                                                value="N/A" (click)="scroll(li,'previousExpirence')" />
                                            <span></span>
                                        </label></td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>

                    <button *ngIf="!addOtherPreviousExpirience" class="btn-flat btn-large  right"
                        (click)="addOtherPreviousExpirience = true" style="margin-top: 15px, bottom 15px;"><i
                            class="large material-icons right green-text">add_circle</i>Other Previous Expirience
                        (please
                        list):</button>

                    <div *ngIf="addOtherPreviousExpirience" class="row">
                        <div class="input-field col s10 ">
                            <i class="material-icons prefix green-text">work</i>
                            <input id="otherPreviousExpirience" type="text" required class="validate" autocomplete="off"
                                style="text-transform: capitalize;" />
                            <span class="helper-text">Please Specify Other Expirience</span>
                        </div>

                        <a (click)="addPreviousExpirience()"
                            class="btn-flat waves-effect waves-light green col m1 s2 center"
                            style="margin-top: 15px;"><i class="material-icons">add</i></a>
                    </div>
                </div>

                <div class="input-field col s12" id="noTrainingReason">
                    <h6><strong>43. If you would not like training in these areas, please explain why not. <span
                                *ngIf="employmentHistory.get('noTrainingReason').errors"
                                class="red-text">*</span></strong></h6>
                    <div class="input-field col s12 ">
                        <textarea type="text" formControlName='noTrainingReason' required
                            (click)="scrollToQuestion('noTrainingReason')" class="materialize-textarea validate"
                            autocomplete="off" data-length="300"></textarea>
                        <div
                            *ngIf="(employmentHistory.get('noTrainingReason').dirty || employmentHistory.get('noTrainingReason').touched) && employmentHistory.get('noTrainingReason').invalid">
                            <small *ngIf="employmentHistory.get('noTrainingReason').errors.required"
                                class="red-text">Reasioning is Required.</small>
                        </div>
                    </div>
                </div>

                <div class="input-field col s12">
                    <h6><strong>44. The next 3 questions are about your 3 most recent jobs. Please provide a brief job
                            history of your most
                            recent job <span class="red-text">*</span></strong></h6>
                    <div class="row " formGroupName="firstRecentJob">
                        <div class="input-field row col s12 right">
                            <div class="col s6">

                                <label>
                                    <input class="filled-in" type="checkbox" formControlName='noRecentJob'
                                        (change)="changePreviousJob($event,'firstRecentJob')" />
                                    <span>No First Job Expirience</span>
                                </label>
                            </div>
                        </div>

                        <div *ngIf="!employmentHistory.get('firstRecentJob').get('noRecentJob').value">
                            <div class="input-field col s12" id="firstRecentJobEmployer">
                                <i class="material-icons prefix green-text">person</i>
                                <input type="text" formControlName='employer' class="validate" autocomplete="off"
                                    style="text-transform: capitalize;" required
                                    (click)="scrollToQuestion('firstRecentJobTitle')" />
                                <span class="helper-text">Employer
                                    <span *ngIf="employmentHistory.get('firstRecentJob').get('employer').errors"
                                        class="red-text">*</span>
                                </span>
                                <div
                                    *ngIf="(employmentHistory.get('firstRecentJob').get('employer').dirty || employmentHistory.get('firstRecentJob').get('employer').touched) && employmentHistory.get('firstRecentJob').get('employer').invalid">
                                    <small
                                        *ngIf="employmentHistory.get('firstRecentJob').get('employer').errors.required"
                                        class="red-text">Employer Name is
                                        Required.</small>
                                </div>
                            </div>

                            <div class="input-field col s12" id="firstRecentJobTitle">
                                <i class="material-icons prefix green-text">work</i>
                                <input type="text" formControlName='jobTitle' class="validate" autocomplete="off"
                                    style="text-transform: capitalize;" required
                                    (click)="scrollToQuestion('firstRecentMainDuties')" />
                                <span class="helper-text">Job Title
                                    <span *ngIf="employmentHistory.get('firstRecentJob').get('jobTitle').errors"
                                        class="red-text">*</span>
                                </span>
                                <div
                                    *ngIf="(employmentHistory.get('firstRecentJob').get('jobTitle').dirty || employmentHistory.get('firstRecentJob').get('jobTitle').touched) && employmentHistory.get('firstRecentJob').get('jobTitle').invalid">
                                    <small
                                        *ngIf="employmentHistory.get('firstRecentJob').get('jobTitle').errors.required"
                                        class="red-text">Job Title is
                                        Required.</small>
                                </div>
                            </div>

                            <div class="input-field col s12" id="firstRecentMainDuties">
                                <i class="material-icons prefix green-text">person</i>
                                <input type="text" formControlName='mainDuties' class="validate" autocomplete="off"
                                    style="text-transform: capitalize;" required
                                    (click)="scrollToQuestion('firstRecentJobStartDate')" />
                                <span class="helper-text">Main Duties
                                    <span *ngIf="employmentHistory.get('firstRecentJob').get('mainDuties').errors"
                                        class="red-text">*</span>
                                </span>
                                <div
                                    *ngIf="(employmentHistory.get('firstRecentJob').get('mainDuties').dirty || employmentHistory.get('firstRecentJob').get('mainDuties').touched) && employmentHistory.get('firstRecentJob').get('mainDuties').invalid">
                                    <small
                                        *ngIf="employmentHistory.get('firstRecentJob').get('mainDuties').errors.required"
                                        class="red-text">Main Duties is
                                        Required.</small>
                                </div>
                            </div>

                            <div class="input-field col s12 m6" id="firstRecentJobStartDate">
                                <i class="material-icons prefix green-text">date_range</i>
                                <!-- <input type="text" formControlName='startDateOfEmployment' class="validate"
                                    autocomplete="off" [textMask]="{mask: dateMask, guide: true}"
                                    placeholder="dd/mm/yyyy" style="text-transform: capitalize;" required
                                    (click)="scrollToQuestion('firstRecentJobEndDate')" /> -->
                                    <input type="text" formControlName='startDateOfEmployment' class="validate"
                                    autocomplete="off" 
                                    placeholder="dd/mm/yyyy" style="text-transform: capitalize;" required
                                    (click)="scrollToQuestion('firstRecentJobEndDate')" />
                                <span class="helper-text">Approx. start date of employment
                                    <span
                                        *ngIf="employmentHistory.get('firstRecentJob').get('startDateOfEmployment').errors"
                                        class="red-text">*</span>
                                </span>
                                <div
                                    *ngIf="(employmentHistory.get('firstRecentJob').get('startDateOfEmployment').dirty || employmentHistory.get('firstRecentJob').get('startDateOfEmployment').touched) && employmentHistory.get('firstRecentJob').get('startDateOfEmployment').invalid">
                                    <small
                                        *ngIf="employmentHistory.get('firstRecentJob').get('startDateOfEmployment').errors.required"
                                        class="red-text">Start Date is
                                        Required.</small>
                                </div>
                            </div>

                            <div class="input-field col s12 m6" id="firstRecentJobEndDate">
                                <i class="material-icons prefix green-text">date_range</i>
                                <!-- <input type="text" formControlName='endDateOfEmployment' class="validate"
                                    autocomplete="off" [textMask]="{mask: dateMask, guide: true}"
                                    placeholder="dd/mm/yyyy" style="text-transform: capitalize;" required
                                    (click)="scrollToQuestion('firstRecentJobReason')" /> -->
                                    <input type="text" formControlName='endDateOfEmployment' class="validate"
                                    autocomplete="off" 
                                    placeholder="dd/mm/yyyy" style="text-transform: capitalize;" required
                                    (click)="scrollToQuestion('firstRecentJobReason')" />
                                <span class="helper-text">Approx. end date of employment
                                    <span
                                        *ngIf="employmentHistory.get('firstRecentJob').get('endDateOfEmployment').errors"
                                        class="red-text">*</span>
                                </span>
                                <div
                                    *ngIf="(employmentHistory.get('firstRecentJob').get('endDateOfEmployment').dirty || employmentHistory.get('firstRecentJob').get('endDateOfEmployment').touched) && employmentHistory.get('firstRecentJob').get('endDateOfEmployment').invalid">
                                    <small
                                        *ngIf="employmentHistory.get('firstRecentJob').get('endDateOfEmployment').errors.required"
                                        class="red-text">End Date is
                                        Required.</small>
                                </div>
                            </div>

                            <div class="input-field col s12" id="firstRecentJobReason">
                                <i class="material-icons prefix green-text">question_answer</i>
                                <input type="text" formControlName='stillWorkingThere' class="validate"
                                    autocomplete="off" style="text-transform: capitalize;" required />
                                <span class="helper-text">Still working there/reason you left
                                    <span
                                        *ngIf="employmentHistory.get('firstRecentJob').get('stillWorkingThere').errors"
                                        class="red-text">*</span>
                                </span>
                                <div
                                    *ngIf="(employmentHistory.get('firstRecentJob').get('stillWorkingThere').dirty || employmentHistory.get('firstRecentJob').get('stillWorkingThere').touched) && employmentHistory.get('firstRecentJob').get('stillWorkingThere').invalid">
                                    <small
                                        *ngIf="employmentHistory.get('firstRecentJob').get('stillWorkingThere').errors.required"
                                        class="red-text">Reasioning is
                                        Required.</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="input-field col s12">
                    <h6><strong>45. Please provide a brief job history of your previous job held: <span
                                class="red-text">*</span></strong></h6>
                    <div class="row " formGroupName="secondRecentJob">
                        <div class="input-field row col s12 right">
                            <div class="col s6">
                                <label>
                                    <input class="filled-in" type="checkbox" formControlName='noRecentJob'
                                        (change)="changePreviousJob($event,'secondRecentJob')" />
                                    <span>No Second Job</span>
                                </label>
                            </div>
                        </div>

                        <div *ngIf="!employmentHistory.get('secondRecentJob').get('noRecentJob').value">
                            <div class="input-field col s12" id="secondRecentJobEmployer">
                                <i class="material-icons prefix green-text">person</i>
                                <input type="text" formControlName='employer' class="validate" autocomplete="off"
                                    style="text-transform: capitalize;" required
                                    (click)="scrollToQuestion('secondRecentJobTitle')" />
                                <span class="helper-text">Employer
                                    <span *ngIf="employmentHistory.get('secondRecentJob').get('employer').errors"
                                        class="red-text">*</span>
                                </span>
                                <div
                                    *ngIf="(employmentHistory.get('secondRecentJob').get('employer').dirty || employmentHistory.get('secondRecentJob').get('employer').touched) && employmentHistory.get('secondRecentJob').get('employer').invalid">
                                    <small
                                        *ngIf="employmentHistory.get('secondRecentJob').get('employer').errors.required"
                                        class="red-text">Employer Name is
                                        Required.</small>
                                </div>
                            </div>

                            <div class="input-field col s12" id="secondRecentJobTitle">
                                <i class="material-icons prefix green-text">work</i>
                                <input type="text" formControlName='jobTitle' class="validate" autocomplete="off"
                                    style="text-transform: capitalize;" required
                                    (click)="scrollToQuestion('secondRecentMainDuties')" />
                                <span class="helper-text">Job Title
                                    <span *ngIf="employmentHistory.get('secondRecentJob').get('jobTitle').errors"
                                        class="red-text">*</span>
                                </span>
                                <div
                                    *ngIf="(employmentHistory.get('secondRecentJob').get('jobTitle').dirty || employmentHistory.get('secondRecentJob').get('jobTitle').touched) && employmentHistory.get('secondRecentJob').get('jobTitle').invalid">
                                    <small
                                        *ngIf="employmentHistory.get('secondRecentJob').get('jobTitle').errors.required"
                                        class="red-text">Job Title is
                                        Required.</small>
                                </div>
                            </div>

                            <div class="input-field col s12" id="secondRecentMainDuties">
                                <i class="material-icons prefix green-text">person</i>
                                <input type="text" formControlName='mainDuties' class="validate" autocomplete="off"
                                    style="text-transform: capitalize;" required
                                    (click)="scrollToQuestion('secondRecentJobStartDate')" />
                                <span class="helper-text">Main Duties
                                    <span *ngIf="employmentHistory.get('secondRecentJob').get('mainDuties').errors"
                                        class="red-text">*</span>
                                </span>
                                <div
                                    *ngIf="(employmentHistory.get('secondRecentJob').get('mainDuties').dirty || employmentHistory.get('secondRecentJob').get('mainDuties').touched) && employmentHistory.get('secondRecentJob').get('mainDuties').invalid">
                                    <small
                                        *ngIf="employmentHistory.get('secondRecentJob').get('mainDuties').errors.required"
                                        class="red-text">Main Duties is
                                        Required.</small>
                                </div>
                            </div>

                            <div class="input-field col s12 m6" id="secondRecentJobStartDate">
                                <i class="material-icons prefix green-text">date_range</i>
                                <!-- <input type="text" formControlName='startDateOfEmployment' class="validate"
                                    autocomplete="off" [textMask]="{mask: dateMask, guide: true}"
                                    placeholder="dd/mm/yyyy" style="text-transform: capitalize;" required
                                    (click)="scrollToQuestion('secondRecentJobEndDate')" /> -->
                                    <input type="text" formControlName='startDateOfEmployment' class="validate"
                                    autocomplete="off" 
                                    placeholder="dd/mm/yyyy" style="text-transform: capitalize;" required
                                    (click)="scrollToQuestion('secondRecentJobEndDate')" />
                                <span class="helper-text">Approx. start date of employment
                                    <span
                                        *ngIf="employmentHistory.get('secondRecentJob').get('startDateOfEmployment').errors"
                                        class="red-text">*</span>
                                </span>
                                <div
                                    *ngIf="(employmentHistory.get('secondRecentJob').get('startDateOfEmployment').dirty || employmentHistory.get('secondRecentJob').get('startDateOfEmployment').touched) && employmentHistory.get('secondRecentJob').get('startDateOfEmployment').invalid">
                                    <small
                                        *ngIf="employmentHistory.get('secondRecentJob').get('startDateOfEmployment').errors.required"
                                        class="red-text">Start Date is
                                        Required.</small>
                                </div>
                            </div>

                            <div class="input-field col s12 m6" id="secondRecentJobEndDate">
                                <i class="material-icons prefix green-text">date_range</i>
                                <!-- <input type="text" formControlName='endDateOfEmployment' class="validate"
                                    autocomplete="off" [textMask]="{mask: dateMask, guide: true}"
                                    placeholder="dd/mm/yyyy" style="text-transform: capitalize;" required
                                    (click)="scrollToQuestion('secondRecentJobReason')" /> -->
                                    <input type="text" formControlName='endDateOfEmployment' class="validate"
                                    autocomplete="off" 
                                    placeholder="dd/mm/yyyy" style="text-transform: capitalize;" required
                                    (click)="scrollToQuestion('secondRecentJobReason')" />
                                <span class="helper-text">Approx. end date of employment
                                    <span
                                        *ngIf="employmentHistory.get('secondRecentJob').get('endDateOfEmployment').errors"
                                        class="red-text">*</span>
                                </span>
                                <div
                                    *ngIf="(employmentHistory.get('secondRecentJob').get('endDateOfEmployment').dirty || employmentHistory.get('secondRecentJob').get('endDateOfEmployment').touched) && employmentHistory.get('secondRecentJob').get('endDateOfEmployment').invalid">
                                    <small
                                        *ngIf="employmentHistory.get('secondRecentJob').get('endDateOfEmployment').errors.required"
                                        class="red-text">End Date is
                                        Required.</small>
                                </div>
                            </div>
                            <div class="input-field col s12" id="secondRecentJobReason">
                                <i class="material-icons prefix green-text">question_answer</i>
                                <input type="text" formControlName='stillWorkingThere' class="validate"
                                    autocomplete="off" style="text-transform: capitalize;" required />
                                <span class="helper-text">Still working there/reason you left
                                    <span
                                        *ngIf="employmentHistory.get('secondRecentJob').get('stillWorkingThere').errors"
                                        class="red-text">*</span>
                                </span>
                                <div
                                    *ngIf="(employmentHistory.get('secondRecentJob').get('stillWorkingThere').dirty || employmentHistory.get('secondRecentJob').get('stillWorkingThere').touched) && employmentHistory.get('secondRecentJob').get('stillWorkingThere').invalid">
                                    <small
                                        *ngIf="employmentHistory.get('secondRecentJob').get('stillWorkingThere').errors.required"
                                        class="red-text">Reasioning is
                                        Required.</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="input-field col s12">
                    <h6><strong>46. Please provide a brief job history of the previous job held (third most
                            recent): <span class="red-text">*</span></strong></h6>
                    <div class="row " formGroupName="thirdRecentJob">
                        <div class="input-field row col s12 right">
                            <div class="col s6">

                                <label>
                                    <input class="filled-in" type="checkbox" formControlName='noRecentJob'
                                        (change)="changePreviousJob($event,'thirdRecentJob')" />
                                    <span>No First Job Expirience</span>
                                </label>
                            </div>
                        </div>
                        <div *ngIf="!employmentHistory.get('thirdRecentJob').get('noRecentJob').value">
                            <div class="input-field col s12" id="thirdRecentJobEmployer">
                                <i class="material-icons prefix green-text">person</i>
                                <input type="text" formControlName='employer' class="validate" autocomplete="off"
                                    style="text-transform: capitalize;" required
                                    (click)="scrollToQuestion('thirdRecentJobTitle')" />
                                <span class="helper-text">Employer
                                    <span *ngIf="employmentHistory.get('thirdRecentJob').get('employer').errors"
                                        class="red-text">*</span>
                                </span>
                                <div
                                    *ngIf="(employmentHistory.get('thirdRecentJob').get('employer').dirty || employmentHistory.get('thirdRecentJob').get('employer').touched) && employmentHistory.get('thirdRecentJob').get('employer').invalid">
                                    <small
                                        *ngIf="employmentHistory.get('thirdRecentJob').get('employer').errors.required"
                                        class="red-text">Employer Name is
                                        Required.</small>
                                </div>
                            </div>

                            <div class="input-field col s12" id="thirdRecentJobTitle">
                                <i class="material-icons prefix green-text">work</i>
                                <input type="text" formControlName='jobTitle' class="validate" autocomplete="off"
                                    style="text-transform: capitalize;" required
                                    (click)="scrollToQuestion('thirdRecentMainDuties')" />
                                <span class="helper-text">Job Title
                                    <span *ngIf="employmentHistory.get('thirdRecentJob').get('jobTitle').errors"
                                        class="red-text">*</span>
                                </span>
                                <div
                                    *ngIf="(employmentHistory.get('thirdRecentJob').get('jobTitle').dirty || employmentHistory.get('thirdRecentJob').get('jobTitle').touched) && employmentHistory.get('thirdRecentJob').get('jobTitle').invalid">
                                    <small
                                        *ngIf="employmentHistory.get('thirdRecentJob').get('jobTitle').errors.required"
                                        class="red-text">Job Title is
                                        Required.</small>
                                </div>
                            </div>

                            <div class="input-field col s12" id="thirdRecentMainDuties">
                                <i class="material-icons prefix green-text">person</i>
                                <input type="text" formControlName='mainDuties' class="validate" autocomplete="off"
                                    style="text-transform: capitalize;" required
                                    (click)="scrollToQuestion('thirdRecentJobStartDate')" />
                                <span class="helper-text">Main Duties
                                    <span *ngIf="employmentHistory.get('thirdRecentJob').get('mainDuties').errors"
                                        class="red-text">*</span>
                                </span>
                                <div
                                    *ngIf="(employmentHistory.get('thirdRecentJob').get('mainDuties').dirty || employmentHistory.get('thirdRecentJob').get('mainDuties').touched) && employmentHistory.get('thirdRecentJob').get('mainDuties').invalid">
                                    <small
                                        *ngIf="employmentHistory.get('thirdRecentJob').get('mainDuties').errors.required"
                                        class="red-text">Main Duties is
                                        Required.</small>
                                </div>
                            </div>

                            <div class="input-field col s12 m6" id="thirdRecentJobStartDate">
                                <i class="material-icons prefix green-text">date_range</i>
                                <!-- <input type="text" formControlName='startDateOfEmployment' class="validate"
                                    autocomplete="off" [textMask]="{mask: dateMask, guide: true}"
                                    placeholder="dd/mm/yyyy" style="text-transform: capitalize;" required
                                    (click)="scrollToQuestion('thirdRecentJobEndDate')" /> -->
                                    <input type="text" formControlName='startDateOfEmployment' class="validate"
                                    autocomplete="off" 
                                    placeholder="dd/mm/yyyy" style="text-transform: capitalize;" required
                                    (click)="scrollToQuestion('thirdRecentJobEndDate')" />
                                <span class="helper-text">Approx. start date of employment
                                    <span
                                        *ngIf="employmentHistory.get('thirdRecentJob').get('startDateOfEmployment').errors"
                                        class="red-text">*</span>
                                </span>
                                <div
                                    *ngIf="(employmentHistory.get('thirdRecentJob').get('startDateOfEmployment').dirty || employmentHistory.get('thirdRecentJob').get('startDateOfEmployment').touched) && employmentHistory.get('thirdRecentJob').get('startDateOfEmployment').invalid">
                                    <small
                                        *ngIf="employmentHistory.get('thirdRecentJob').get('startDateOfEmployment').errors.required"
                                        class="red-text">Start Date is
                                        Required.</small>
                                </div>
                            </div>

                            <div class="input-field col s12 m6" id="thirdRecentJobEndDate">
                                <i class="material-icons prefix green-text">date_range</i>
                                <!-- <input type="text" formControlName='endDateOfEmployment' class="validate"
                                    autocomplete="off" [textMask]="{mask: dateMask, guide: true}"
                                    placeholder="dd/mm/yyyy" style="text-transform: capitalize;" required
                                    (click)="scrollToQuestion('thirdRecentJobReason')" /> -->
                                    <input type="text" formControlName='endDateOfEmployment' class="validate"
                                    autocomplete="off" 
                                    placeholder="dd/mm/yyyy" style="text-transform: capitalize;" required
                                    (click)="scrollToQuestion('thirdRecentJobReason')" />
                                <span class="helper-text">Approx. end date of employment
                                    <span
                                        *ngIf="employmentHistory.get('thirdRecentJob').get('endDateOfEmployment').errors"
                                        class="red-text">*</span>
                                </span>
                                <div
                                    *ngIf="(employmentHistory.get('thirdRecentJob').get('endDateOfEmployment').dirty || employmentHistory.get('thirdRecentJob').get('endDateOfEmployment').touched) && employmentHistory.get('thirdRecentJob').get('endDateOfEmployment').invalid">
                                    <small
                                        *ngIf="employmentHistory.get('thirdRecentJob').get('endDateOfEmployment').errors.required"
                                        class="red-text">End Date is
                                        Required.</small>
                                </div>
                            </div>

                            <div class="input-field col s12" id="thirdRecentJobReason">
                                <i class="material-icons prefix green-text">question_answer</i>
                                <input type="text" formControlName='stillWorkingThere' class="validate"
                                    autocomplete="off" style="text-transform: capitalize;" required />
                                <span class="helper-text">Still working there/reason you left
                                    <span
                                        *ngIf="employmentHistory.get('thirdRecentJob').get('stillWorkingThere').errors"
                                        class="red-text">*</span>
                                </span>
                                <div
                                    *ngIf="(employmentHistory.get('thirdRecentJob').get('stillWorkingThere').dirty || employmentHistory.get('thirdRecentJob').get('stillWorkingThere').touched) && employmentHistory.get('thirdRecentJob').get('stillWorkingThere').invalid">
                                    <small
                                        *ngIf="employmentHistory.get('thirdRecentJob').get('stillWorkingThere').errors.required"
                                        class="red-text">Reasioning is
                                        Required.</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" formGroupName="shiftWork" *ngIf="step==12">
            <div class="row col s12 teal lighten-2 white-text">
                <h5 class="center-align">SECTION 11: Employment On-Reserve & Off-Reserve / Shift Work</h5>
            </div>
            <div class="card-content">
                <div class="input-field col s12">
                    <h6><strong>47. Do you feel more comfortable going out to work in groups then alone?
                            <span *ngIf="shiftWork.get('workAlone').errors" class="red-text">*</span>
                        </strong></h6>
                    <div class="row col s12">
                        <label class="col m4 s6 ">
                            <input name="workAlone" type="radio" formControlName="workAlone" value="Yes"
                                (click)="updateWorkAlone($event)" />
                            <span>Yes</span>
                        </label>

                        <label class="col m4 s6">
                            <input name="workAlone" type="radio" formControlName="workAlone" value="No"
                                (click)="updateWorkAlone($event)" />
                            <span>No</span>
                        </label>
                    </div>

                    <div *ngIf="shiftWork.controls['workAlone'].value === 'Yes'" class="input-field col s10 ">
                        <input type="text" formControlName="workAloneReason" required class="validate"
                            autocomplete="off" style="text-transform: capitalize;" />
                        <span class="helper-text">If so, why?
                            <span *ngIf="shiftWork.get('workAloneReason').errors" class="red-text">*</span>
                        </span>
                        <div
                            *ngIf="(shiftWork.get('workAloneReason').dirty || shiftWork.get('workAloneReason').touched) && shiftWork.get('workAloneReason').invalid">
                            <small *ngIf="shiftWork.get('workAloneReason').errors.required" class="red-text">Reasioning
                                is Required.</small>
                        </div>
                    </div>
                </div>


                <div class="input-field col s12" id="workOutsideCommunity">
                    <h6><strong>48. Are you willing to work shift work outside of the community?
                            <span *ngIf="shiftWork.get('shiftWorkOutsideCommunity').errors" class="red-text">*</span>
                        </strong></h6>
                    <div class="row col s12">
                        <label class="col m4 s6 ">
                            <input name="shiftWorkOutsideCommunity" type="radio"
                                formControlName="shiftWorkOutsideCommunity" value="Yes"
                                (click)="updateShiftWork($event)" />
                            <span>Yes</span>
                        </label>

                        <label class="col m4 s6">
                            <input name="shiftWorkOutsideCommunity" type="radio"
                                formControlName="shiftWorkOutsideCommunity" value="No"
                                (click)="updateShiftWork($event)" />
                            <span>No</span>
                        </label>
                    </div>

                    <div *ngIf="shiftWork.controls['shiftWorkOutsideCommunity'].value === 'No'"
                        class="input-field col s10 ">
                        <input type="text" formControlName="noReason" required class="validate" autocomplete="off"
                            style="text-transform: capitalize;" />
                        <span class="helper-text">If no, please explain
                            <span *ngIf="shiftWork.get('noReason').errors" class="red-text">*</span>
                        </span>
                        <div
                            *ngIf="(shiftWork.get('noReason').dirty || shiftWork.get('noReason').touched) && shiftWork.get('noReason').invalid">
                            <small *ngIf="shiftWork.get('noReason').errors.required" class="red-text">Reasioning is
                                Required.</small>
                        </div>
                    </div>
                </div>

                <div class="input-field col s12" id="willingToRelocate">
                    <h6><strong>49. Are you willing to relocate outside of the community? <span class="red-text"
                                *ngIf="shiftWork.get('willingToRelocate').errors">*</span></strong></h6>
                    <div class="row col s12">
                        <label class="col m4 s6 ">
                            <input name="willingToRelocate" type="radio" formControlName="willingToRelocate" value="Yes"
                                (click)="updateWillingTRelocate($event)" />
                            <span>Yes</span>
                        </label>
                        <label class="col m4 s6">
                            <input name="willingToRelocate" type="radio" formControlName="willingToRelocate" value="No"
                                (click)="updateWillingTRelocate($event)" />
                            <span>No</span>
                        </label>
                    </div>
                </div>

                <div class="input-field col s12" id="commuteForWork">
                    <h6><strong>50. Are you willing to commute for work? <span class="red-text"
                                *ngIf="shiftWork.get('commuteForWork').errors">*</span></strong></h6>
                    <div class="row col s12">
                        <label class="col m4 s6 ">
                            <input name="commuteForWork" type="radio" formControlName="commuteForWork" value="Yes"
                                (click)="updateWorkCommute($event)" />
                            <span>Yes</span>
                        </label>
                        <label class="col m4 s6">
                            <input name="commuteForWork" type="radio" formControlName="commuteForWork" value="No"
                                (click)="updateWorkCommute($event)" />
                            <span>No</span>
                        </label>
                    </div>
                </div>

                <div *ngIf="shiftWork.controls['commuteForWork'].value === 'Yes'" formGroupName="commuteLength"
                    class="input-field col s12">
                    <h6><strong>51. If you are you willing to commute, how far would you commute? <span class="red-text"
                                *ngIf="shiftWork.get('commuteLength').get('length').errors">*</span></strong></h6>
                    <div class="row col s12">
                        <label class="col m4 s6 ">
                            <input name="length" type="radio" formControlName="length" value="<100 km"
                                (click)="scrollToQuestion('commuteComment')" />
                            <span>
                                < 100 km </span>
                        </label>
                        <label class="col m4 s6 ">
                            <input name="length" type="radio" formControlName="length" value="100 km"
                                (click)="scrollToQuestion('commuteComment')" />
                            <span>100 km</span>
                        </label>

                        <label class="col m4 s6">
                            <input name="length" type="radio" formControlName="length" value=">500 km"
                                (click)="scrollToQuestion('commuteComment')" />
                            <span>>500 km</span>
                        </label>
                        <div class="input-field col s12" id="commuteComment">
                            <i class="material-icons prefix green-text">chat</i>
                            <textarea type="text" formControlName="comments" style="text-transform: capitalize;"
                                (click)="scrollToQuestion('supportForRelocate')"
                                class="materialize-textarea"></textarea>
                            <span class="helper-text">Comments</span>
                            <div
                                *ngIf="(shiftWork.get('commuteLength').get('comments').dirty || shiftWork.get('commuteLength').get('comments').touched) && shiftWork.get('commuteLength').get('comments').invalid">
                                <small *ngIf="shiftWork.get('commuteLength').get('comments').errors.required"
                                    class="red-text">Comments is Required.</small>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="shiftWork.controls['commuteForWork'].value === 'Yes' || shiftWork.controls['willingToRelocate'].value === 'Yes' "
                    formArrayName="supportForRelocate" class="input-field col s12" id="supportForRelocate">
                    <h6><strong>52. If you answered YES to relocation or commuting, what supports would you require to
                            relocate? <sup *ngIf="supportForRelocate.errors && supportForRelocate.errors.invalidValue"
                                class="red-text"> * select
                                atleast
                                one</sup>
                        </strong></h6>
                    <div class="col s6" *ngFor="let supportForRelocate of supportForRelocate.controls;let li=index"
                        [formGroup]="supportForRelocate">
                        <label>
                            <input class="filled-in" type="checkbox" formControlName='value' />
                            <span>{{supportForRelocate.controls['support'].value}}</span>
                        </label>
                    </div>

                    <button *ngIf="!addOtherRelocateSupport" class="btn-flat btn-large  right"
                        (click)="addOtherRelocateSupport = true" style="margin-top: 15px, bottom 15px;"><i
                            class="large material-icons right green-text">add_circle</i>Other Support
                        (please
                        list):</button>

                    <div *ngIf="addOtherRelocateSupport" class="row">
                        <div class="input-field col s10">
                            <i class="material-icons prefix green-text">help_outline</i>
                            <textarea id="otherRelocateSupport" class="materialize-textarea"></textarea>
                            <span class="helper-text">Please Specify Other
                                Support For Relocate </span>
                        </div>

                        <a (click)="addSupportForRelocate()"
                            class="btn-flat waves-effect waves-light green col m1 s2 center"
                            style="margin-top: 15px;"><i class="material-icons">add</i></a>
                    </div>
                </div>

                <div class="input-field col s12" formArrayName="workshopsInCommunity" id="workshopsInCommunity"
                    (click)="scrollToQuestion('workshopsInCommunity')">
                    <h6><strong>53. What type of workshops would you like to have in place in the community?
                            <sup *ngIf="workshopsInCommunity.errors && workshopsInCommunity.errors.invalidValue"
                                class="red-text"> * select
                                atleast
                                one</sup>
                        </strong>
                    </h6>
                    <div class="col s6" *ngFor="let workshopsInCommunity of workshopsInCommunity.controls;let li=index"
                        [formGroup]="workshopsInCommunity">
                        <label>
                            <input class="filled-in" type="checkbox" formControlName='value'
                                (click)="changeWorkshop($event,li)" />
                            <span>{{workshopsInCommunity.controls['workshop'].value}}</span>
                        </label>
                    </div>
                    <button *ngIf="!addOtherWorkshop" class="btn-flat btn-large  right"
                        (click)="addOtherWorkshop = true" style="margin-top: 15px, bottom 15px;"><i
                            class="large material-icons right green-text">add_circle</i>Other Workshop
                        (please
                        list):</button>
                    <div *ngIf="addOtherWorkshop" class="row">
                        <div class="input-field col s10 ">
                            <i class="material-icons prefix green-text">home_repair_service</i>
                            <textarea id="otherWorkshop" required class="validate" autocomplete="off"
                                style="text-transform: capitalize;" class="materialize-textarea"></textarea>
                            <span class="helper-text">Please Specify Other
                                Workshop</span>
                        </div>

                        <a (click)="addOtherWorkshops()"
                            class="btn-flat waves-effect waves-light green col m1 s2 center"
                            style="margin-top: 15px;"><i class="material-icons">add</i></a>
                    </div>
                </div>

                <div class="input-field col s12" id="localOrInCommunityWorkshop">
                    <h6><strong>54. Are you more comfortable with in-community workshop instructors or local community
                            member workshops? <span class="red-text"
                                *ngIf="shiftWork.get('localOrInCommunityWorkshop').errors">*</span></strong></h6>
                    <div class="row col s12">
                        <label class="col m4 s6 ">
                            <input name="localOrInCommunityWorkshop" type="radio"
                                formControlName="localOrInCommunityWorkshop" value="Yes"
                                (click)="scrollToQuestion('localOrInCommunityWorkshop')" />
                            <span>Yes</span>
                        </label>

                        <label class="col m4 s6">
                            <input name="localOrInCommunityWorkshop" type="radio"
                                formControlName="localOrInCommunityWorkshop" value="No"
                                (click)="scrollToQuestion('localOrInCommunityWorkshop')" />
                            <span>No</span>
                        </label>
                    </div>
                </div>

                <div class="input-field col s12" id="supportGroup">
                    <h6><strong>55. Are you interested in creating support groups? <span class="red-text"
                                *ngIf="shiftWork.get('supportGroup').errors">*</span></strong></h6>
                    <div class="row col s12">
                        <label class="col m4 s6 ">
                            <input name="supportGroup" type="radio" formControlName="supportGroup" value="Yes"
                                (click)="updateSupportGroup($event)" />
                            <span>Yes</span>
                        </label>

                        <label class="col m4 s6">
                            <input name="supportGroup" type="radio" formControlName="supportGroup" value="No"
                                (click)="updateSupportGroup($event)" />
                            <span>No</span>
                        </label>
                    </div>
                </div>

                <div *ngIf="shiftWork.controls['supportGroup'].value === 'Yes' " class="input-field col s12"
                    formArrayName="typesOfSupportGroup" id="typesOfSupportGroup"
                    (click)="scrollToQuestion('typesOfSupportGroup')">
                    <h6><strong>56. If yes, what types of support groups?
                            <sup *ngIf="typesOfSupportGroup.errors && typesOfSupportGroup.errors.invalidValue"
                                class="red-text"> * select
                                atleast
                                one</sup>
                        </strong></h6>
                    <div class="col s6" *ngFor="let typesOfSupportGroup of typesOfSupportGroup.controls;let li=index"
                        [formGroup]="typesOfSupportGroup">
                        <label>
                            <input class="filled-in" type="checkbox" formControlName='value' />
                            <span>{{typesOfSupportGroup.controls['group'].value}}</span>
                        </label>
                    </div>

                    <button *ngIf="!addOtherSupportGroup" class="btn-flat btn-large  right"
                        (click)="addOtherSupportGroup = true" style="margin-top: 15px, bottom 15px;"><i
                            class="large material-icons right green-text">add_circle</i>Other Support Group
                        (please
                        list):</button>

                    <div *ngIf="addOtherSupportGroup" class="row">
                        <div class="input-field col s10 ">
                            <i class="material-icons prefix green-text">help_outline</i>
                            <textarea id="otherSupportGroup" required class="validate" autocomplete="off"
                                style="text-transform: capitalize;" class="materialize-textarea"></textarea>
                            <span class="helper-text">Please Specify Other
                                Support Group</span>
                        </div>

                        <a (click)="addOtherSupportGroups()"
                            class="btn-flat waves-effect waves-light green col m1 s2 center"
                            style="margin-top: 15px;"><i class="material-icons">add</i></a>
                    </div>
                </div>

                <div class="input-field col s12" id="careerAssessment" (click)="scrollToQuestion('careerAssessment')">
                    <h6><strong>57. Would you be interested in a career assessment to help you figure out what careers
                            you might be suited
                            for? <span class="red-text"
                                *ngIf="shiftWork.get('careerAssessment').errors">*</span></strong></h6>
                    <div class="row col s12">
                        <label class="col m4 s6 ">
                            <input name="careerAssessment" type="radio" formControlName="careerAssessment"
                                value="Yes" />
                            <span>Yes</span>
                        </label>

                        <label class="col m4 s6">
                            <input name="careerAssessment" type="radio" formControlName="careerAssessment" value="No" />
                            <span>No</span>
                        </label>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" formGroupName="addictionsSupport" *ngIf="step==13">
            <div class="row col s12 teal lighten-2 white-text">
                <h5 class="center-align">SECTION 12: Addictions Support</h5>
            </div>

            <div class="card-content">
                <div class="input-field col s12" id="infoOnProgram">
                    <h6><strong>58. Would you like information about the NNADAP Program? <span class="red-text"
                                *ngIf="addictionsSupport.get('infoOnProgram').errors">*</span></strong></h6>
                    <div class="row col s12">
                        <label class="col m4 s6 ">
                            <input name="infoOnProgram" type="radio" formControlName="infoOnProgram" value="Yes"
                                (click)="updateInfoOnProgram($event)" />
                            <span>Yes</span>
                        </label>

                        <label class="col m4 s6">
                            <input name="infoOnProgram" type="radio" formControlName="infoOnProgram" value="No"
                                (click)="updateInfoOnProgram($event)" />
                            <span>No</span>
                        </label>
                    </div>
                </div>

                <div *ngIf="addictionsSupport.controls['infoOnProgram'].value === 'Yes'" class="input-field col s12"
                    formGroupName="infoNNADAP" id="infoNNADAP" (click)="scrollToQuestion('infoNNADAP')">
                    <h6><strong>59. If yes, what would you like to know?
                            <sup *ngIf="infoNNADAP.errors && infoNNADAP.errors.invalidValue" class="red-text"> * select
                                atleast
                                one</sup>
                        </strong></h6>
                    <div class="col s6" *ngFor="let infoNNADAP of infoNNADAP.controls;let li=index"
                        [formGroup]="infoNNADAP">

                        <label>
                            <input class="filled-in" type="checkbox" formControlName='value' />
                            <span>{{infoNNADAP.controls['info'].value}}</span>
                        </label>
                    </div>

                    <button *ngIf="!addOtherInfo" class="btn-flat btn-large  right" (click)="addOtherInfo = true"
                        style="margin-top: 15px, bottom 15px;"><i
                            class="large material-icons right green-text">add_circle</i>Other Information
                        (please
                        specify):</button>

                    <div *ngIf="addOtherInfo" class="row">
                        <div class="input-field col s10 ">
                            <i class="material-icons prefix green-text">chat</i>
                            <textarea id="otherInfo" style="text-transform: capitalize;" autocomplete="off"
                                class="validate materialize-textarea"></textarea>
                            <span class="helper-text">Please Specify
                                Information</span>
                        </div>

                        <a (click)="addOtherInfos()" class="btn-flat waves-effect waves-light green col m1 s2 center"
                            style="margin-top: 15px;"><i class="material-icons">add</i></a>
                    </div>
                </div>

                <div formGroupName="communicateOnBehalf" class="input-field col s12">
                    <h6><strong>60. Would you like us to communicate with NNADAP on your behalf? <span class="red-text"
                                *ngIf="addictionsSupport.get('communicateOnBehalf').get('value').errors">*</span></strong>
                    </h6>
                    <div class="row col s12">
                        <label class="col m4 s6 ">
                            <input name="value" type="radio" formControlName="value" value="Yes"
                                (click)="scrollToQuestion('Infocomments')" />
                            <span>Yes</span>
                        </label>

                        <label class="col m4 s6">
                            <input name="value" type="radio" formControlName="value" value="No"
                                (click)="scrollToQuestion('Infocomments')" />
                            <span>No</span>
                        </label>

                        <div class="input-field col s12" id="Infocomments">
                            <i class="material-icons prefix green-text">chat</i>
                            <textarea type="text" formControlName="comments" required
                                style="text-transform: capitalize;" class="materialize-textarea"></textarea>

                            <span class="helper-text">Comments</span>
                            <div
                                *ngIf="(addictionsSupport.get('communicateOnBehalf').get('comments').dirty || addictionsSupport.get('communicateOnBehalf').get('comments').touched) && addictionsSupport.get('communicateOnBehalf').get('comments').invalid">
                                <small
                                    *ngIf="addictionsSupport.get('communicateOnBehalf').get('comments').errors.required"
                                    class="red-text">Comments is Required.</small>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" formGroupName="traditionalEmployment" *ngIf="step==14">
            <div class="row col s12 teal lighten-2 white-text">
                <h5 class="center-align">SECTION 13: Traditional Employment</h5>
            </div>
            <div class="card-content">
                <div class="input-field col s12">
                    <h6><strong>61. Way of Life and Hunting
                            I have the skills for (check all that apply)
                            I would like to develop the skills for (check all that apply) <span
                                class="red-text">*</span> </strong></h6>
                    <table>
                        <thead>
                            <tr>
                                <th></th>
                                <th>I have th skill</th>
                                <th>I have some skills but could use more training</th>
                                <th>I would like to learn</th>
                                <th>N/A</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container formArrayName="skills">
                                <tr *ngFor="let skill of skills.controls;let li=index" [formGroup]="skill"
                                    id="skills_{{li}}">
                                    <td><strong>{{skill.controls['skill'].value}}
                                            <span
                                                *ngIf="skills.at(li).get('value').errors && skills.at(li).get('value').errors.invalidValue"
                                                class="red-text">*</span></strong></td>

                                    <td><label class="col s6">
                                            <input type="radio" id="{{ 'acceptable' + i}}" formControlName='value'
                                                value="I have th skill" (click)="scroll(li,'skills')" />
                                            <span></span>
                                        </label></td>
                                    <td><label class="col s6">
                                            <input type="radio" id="{{ 'acceptable' + i}}" formControlName='value'
                                                value="I have some skills but could use more training"
                                                (click)="scroll(li,'skills')" />
                                            <span></span>
                                        </label></td>
                                    <td><label class="col s6">
                                            <input type="radio" id="{{ 'acceptable' + i}}" formControlName='value'
                                                value="I would like to learn" (click)="scroll(li,'skills')" />
                                            <span></span>
                                        </label></td>
                                    <td><label class="col s6">
                                            <input type="radio" id="{{ 'acceptable' + i}}" formControlName='value'
                                                value="N/A" (click)="scroll(li,'skills')" />
                                            <span></span>
                                        </label></td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>

                    <button *ngIf="!addOtherSkill" class="btn-flat btn-large  right" (click)="addOtherSkill = true"
                        style="margin-top: 15px, bottom 15px;"><i
                            class="large material-icons right green-text">add_circle</i>Other Skill
                        (please specify):</button>

                    <div *ngIf="addOtherSkill" class="row">
                        <div class="input-field col s10 ">
                            <i class="material-icons prefix green-text">work</i>
                            <input id="otherSkill" type="text" required class="validate" autocomplete="off"
                                style="text-transform: capitalize;" />
                            <!-- <label>Please Specify Other Skill</label> -->
                            <span class="helper-text">Please Specify Other Skill</span>
                        </div>

                        <a (click)="addOtherSkills()" class="btn-flat waves-effect waves-light green col m1 s2 center"
                            style="margin-top: 15px;"><i class="material-icons">add</i></a>
                    </div>
                </div>

                <div class="input-field col s12">
                    <h6>62. <b>Thank you for taking the time to fill out this survey. Your information will be held
                            in confidence as per
                            the requirement of FOIP.
                            If you have any additional comments please write below.
                        </b></h6>
                    <div class="input-field col s12 ">

                        <textarea type="text" formControlName='additionalComments' class="materialize-textarea validate"
                            autocomplete="off" data-length="300"></textarea>
                    </div>
                </div>
            </div>
        </div>

        <div class="row" *ngIf="step==15">
            <div class="row col s12 teal lighten-2 white-text">
                <h5 class="center-align">SECTION 14: Signature</h5>
            </div>
            <div class="card-content">
                <div class="row col s12" *ngIf="loggedUser.role =='Member'">
                    <h6>I <b> <u> {{member.GivenName}} {{member.LastName}} </u></b> give consent and
                        authorization
                        of {{nationOfUse}}, Income Support staff conducting this {{date.getFullYear()}} Income Support
                        Survey with the
                        information I
                        have provided. I am allowing {{nationOfUse}} Income Support to use the data collected for the
                        purpose of
                        analyzing the First Nation’s training, education, and employment needs.
                    </h6>
                    <div>
                        <div class="input-field col s12">
                            <i class="material-icons prefix green-text">person</i>
                            <select formControlName="admin" class="validate">
                                <option value="" disabled selected>Select</option>
                                <option *ngFor="let c of adminsList;let li=index" [value]="li">{{c.FirstName}}
                                    {{c.LastName}}</option>
                            </select>
                            <label for="admin">Please Select Admin To Review<span class="red-text">*</span></label>
                        </div>
                    </div>
                </div>

                <div class="row col s12" *ngIf="loggedUser.role =='Admin'">
                    <div class="input-field col s12">
                        <!-- <h6><strong><i class="material-icons green-text left">label_important</i><span>Marital Status</span></strong></h6> -->
                        <h6><strong>Would you like to have the client sign the survey? </strong><span
                                class="red-text">*</span></h6>
                        <div class="row col s12">
                            <label class="col s6">
                                <input name="clientOnSight" formControlName="clientOnSight" type="radio" value="Yes"
                                    (click)="clientSignNow(true)" />
                                <span>Yes</span>
                            </label>
                            <label class="col s6">
                                <input name="clientOnSight" formControlName="clientOnSight" type="radio" value="No"
                                    (click)="clientSignNow(false)" />
                                <span>No</span>
                            </label>
                        </div>
                    </div>

                    <div class="row">
                        <br>
                        <div class="row col s10 center"
                            *ngIf="model.applicantSignature !=='' && model.applicantSignature">
                            <h6>Client Signature</h6>
                            <img [src]="model.applicantSignature" alt="Client Signature" class="col s12" />
                        </div>
                    </div>
                    <h6><b> <u> {{loggedUser.FirstName}} {{loggedUser.LastName}} </u></b> Income Support
                        Worker Signature:
                    </h6>
                </div>
                <div>
                    <div class="row">
                        <br>
                    </div>

                    <div *ngIf="HasApplicantSign">
                        <div class="row">
                            <div [ngClass]="'card ' + themeColor">
                                <div class="row card-panel grey lighten-4 center">
                                    <div class="row">
                                        <h5 class="center blue-text text-darken-2" *ngIf="loggedUser.role =='Admin'">
                                            Admin: Please Sign Here</h5>
                                        <h5 class="center blue-text text-darken-2" *ngIf="loggedUser.role =='Member'">
                                            Applicant: Please Sign Here</h5>
                                        <p>Sign your name inside the box without touching the borders. You may sign with
                                            an “X”,
                                            in syllabics or another language, as long as this is your usual legal
                                            signature.</p>
                                        <div class="section"></div>
                                        <div>
                                            <canvas></canvas>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="card-action">
                                <a style="cursor: pointer; margin-bottom: 15px;"
                                    class="btn-flat waves-effect red right white-text"
                                    (click)="clearSignatureOfApplicant()">
                                    <i class="material-icons right">border_color</i><strong>Clear
                                        Signature</strong></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <button *ngIf="step == 15" class="col s12 btn green" [disabled]="!lrrcnForm.valid || signatureInvalid()"
            type="submit" style="margin-bottom: 15px;"><i class="material-icons right">cloud_upload</i>Save and
            Submit</button>
    </form>

    <div class="row">
        <button *ngIf="14>=step && step>=1" class="col s12 m5 offset-m2 l3 btn right  green" type="button"
            [disabled]="sectionIsInValid() || step >=15" (click)="nextSection()" style="margin-bottom: 15px;"><i
                class="material-icons right">skip_next</i>Next
            Section</button>

        <button *ngIf="step >=1" class="col s12 m5 l3 btn left  blue" type="button" [disabled]="1>=step"
            (click)="previousSection()" style="margin-bottom: 15px;"><i
                class="material-icons left">skip_previous</i>Previouse Section</button>

        <button *ngIf="step==0" class="col s12 btn waves-effect green" type="button" (click)="nextSection()"
            style="margin-bottom: 15px;"><i class="material-icons right">skip_next</i>Start Your Survey</button>

        <button [ngClass]="'col s12 btn waves-effect '+ themeColor" type="button" (click)="backToSearch()"><i
                class="material-icons left">skip_previous</i>{{loggedUser.role =='Admin' ? "Back to Search" : "Back to
            List"}}</button>
    </div>
</div>

<div id="clientSignModal" class="modal">
    <div class="modal-content">
        <h4>Client Signature</h4>
        <div>
            <div class="row">
                <div [ngClass]="'card'">
                    <div class="row card-panel grey lighten-4 left-align">
                        <div class="row">
                            <p>Sign your name inside the box without touching the borders. You may sign with an “X”,
                                in syllabics or another language, as long as this is your usual legal signature.</p>
                            <div class="section"></div>
                            <div>
                                <canvas id="client"></canvas>
                            </div>
                        </div>
                    </div>
                </div>
                
                <div class=" row col s12">
                    <a style="cursor: pointer; margin-bottom: 15px;" (click)="saveClientSignature()"
                        class="btn-flat waves-effect green left  white-text">
                        <i class="material-icons right">border_color</i><strong>Approve
                            Signature</strong></a>

                    <a style="cursor: pointer; margin-bottom: 15px;" class="btn-flat waves-effect red right white-text"
                        (click)="clearSignatureOfClient()">
                        <i class="material-icons right">border_color</i><strong>Clear
                            Signature</strong></a>
                </div>

                <div class=" row col s12 modal-footer">
                    <a class="at-btn btn-flat center blue lighten-1 col s12 white-text modal-close"
                        (click)="clientSignNow(false)"><i class="material-icons left">skip_previous</i> Close Modal</a>
                </div>
            </div>
        </div>
    </div>
</div>