import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { AboutusService } from 'src/app/services/firebase/aboutus.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-view-about-section',
  templateUrl: './view-about-section.component.html',
  styleUrls: ['./view-about-section.component.css']
})
export class ViewAboutSectionComponent implements OnInit {

  nationName = environment.firstNation.displayName;
  id = '';
  myModel: any;

  constructor(
    private route: ActivatedRoute,
    private aboutService: AboutusService,
    private location: Location
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });

    if (this.id) {
      this.aboutService.getSectionByID(this.id).valueChanges().subscribe( resp => {
        if (resp) {
          this.myModel = resp;
        }
      });
      }
  }

}
