<div class="col s12">
  <div class="body-h">
    <div class="header-setting b-m-2">

      <div class="col l12 m12 s12">
        <h4 class="bold-500 icon-text title-nation"> <i class="material-symbols-outlined  bold-500 p-r-1 "
            (click)="backClicked()"> arrow_back </i> {{department_name}}</h4>

        <p class="bold-100 sub-title-section3">
          Brand and Customize Your Department's Edit, Upload & Add with Ease!. Customize Your Details and Showcase Your
          Team with a Personal Touch!</p>
      </div>

      <div class="col s12 l12">
        <ul class="tabs ">
          <li class="tab col s6"><a class="active" href="#test1">About Us</a></li>
          <li class="tab col s6 "><a href="#test3">Employees</a></li>
        </ul>
      </div>
    </div>

    <div class="row">
      <main class="StickyContent white">
        <div class="">
          <div id="test1" class="col s12">
            <app-add-edit-department></app-add-edit-department>
          </div>
          <div id="test3" class="col s12">
            <app-manage-department-members [id]=departmentID></app-manage-department-members>
          </div>
        </div>
      </main>
    </div>

  </div>
</div>