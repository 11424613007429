import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { JobOfferService } from 'src/app/services/firebase/joboffer.service';
import { Location } from '@angular/common';

declare var $: any;

@Component({
    selector: "app-job-board",
    templateUrl: "./job-board.component.html",
    styleUrls: ["./job-board.component.css"],
})
export class JobBoardComponent implements OnInit {

    constructor(private location: Location,
        private jobofferService: JobOfferService) {
    }

    themeColor = environment.appTheme.adminThemeColor;
    fontColor = environment.appTheme.adminFontColor;
    joblist: any[];
    hasRows = false;
    post_to_archive: any[];

    ngOnInit() {
        // const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
        // const yesterday_date = (new Date().getDate() - 1) + '/' + months[new Date().getMonth()] + '/' + new Date().getFullYear();
        let yesterday_date = new Date()
        yesterday_date.setDate(yesterday_date.getDate() - 1);

        this.jobofferService.getAllJobs().valueChanges().subscribe(jobs => {
            if (jobs) {
                if (jobs.length > 0) {
                    this.joblist = jobs;
                    this.post_to_archive = jobs;
                    this.joblist = this.joblist.filter(e => e.posted === true && (e.expiry_date.toDate() > yesterday_date));
                    this.post_to_archive = this.post_to_archive.filter(e => e.posted === true && e.expiry_date.toDate() <= yesterday_date);
                    if (this.joblist.length > 0) { this.hasRows = true; }
                    setTimeout(() => {
                        $('.collapsible').collapsible();
                    }, 25);
                }
            }
        });

        setTimeout(() => {
            if (this.post_to_archive) {
                if (this.post_to_archive.length > 0) {
                    this.Archive_Post_on_ExpiryDate()
                };
            }
        }, 100);
    }

    Archive_Post_on_ExpiryDate() {
        this.post_to_archive.forEach(element => {
            element.posted = false;
            element.archived = true;
            this.jobofferService.EditJobOffer(element).then(x => { });
        });
    }

    backClicked() {
        this.location.back();
    }
}