<div class="container">
    <h5 class="center-align">Create Application</h5>
    <div class="row">
        <form class="row col s12" [formGroup]="form" (ngSubmit)="createApplication(form.value)">
            <div class="row card white">
                <br>
                <!-- <a class="dropdown-trigger col s12 btn grey lighten-3 black-text" data-target='options1'
                    style="margin-bottom: 15px;">
                    <strong><i class="material-icons left pink-text">color_lens</i> Select Color</strong> </a> -->

                <div class="input-field col s12">
                    <i class="material-icons prefix green-text">title</i>
                    <input id="name" type="text" class="validate" formControlName="name" autocomplete="off"
                        style="text-transform: capitalize;">
                    <label for="name">Application Name<span class="red-text">*</span></label>
                    <div *ngIf="(appName.dirty || appName.touched) && appName.invalid">
                        <small *ngIf="appName.errors.required" class="red-text errorMsg"> Application Name is required.
                        </small>
                    </div>
                </div>

                <div class="input-field col s12">
                    <i class="material-icons prefix blue-text">description</i>
                    <input id="description" type="text" class="validate" formControlName="description"
                        autocomplete="off" style="text-transform: capitalize;">
                    <label for="description">Description</label>
                </div>

                <div class="col s12 file-field input-field">

                    <div class="btn">
                        <span>Browse <i class="material-icons">file_upload
                            </i></span>
                        <input type="file" (change)="handleFileInput($event.target.files)" formControlName="logo"
                            accept="image/*" />
                    </div>
                    <div class="file-path-wrapper">
                        <input class="file-path validate" type="text" placeholder="Choose a Logo" />
                    </div>
                    <img *ngIf="application.logo" [src]="application.logo" width="75px;">
                </div>
            </div>

            <div class="fixed-action-btn">
                <a class="btn-floating btn-large red">
                    <i class="large material-icons">mode_edit</i>
                </a>
                <ul>
                    <li><a class="btn-floating blue tooltipped" data-position="left" data-tooltip="Add Field"
                            (click)="onAddField()"><i class="material-icons">add_circle_outline</i></a></li>
                    <li><a class="btn-floating green tooltipped" data-position="left" data-tooltip="Add Section"
                            (click)="onAddSection()"><i class="material-icons">bookmark_add</i></a></li>
                </ul>
            </div>

            <div class="row card white">
                <div class="input-field col s12">
                    <p>Select the Fields you want to include in this application</p>
                    <div class="col s12 m6 l4" *ngFor="let field of fields; let f = index">
                        <div class="col s12">
                            <label>
                                <input class="filled-in" type="checkbox" [checked]="field.checked"
                                    (click)='fieldChecked(f)' />
                                <span>{{field.name}}</span>
                            </label>
                        </div>
                    </div>
                </div>
                <button class="btn col s12 green waves-effect waves-light" type="button" (click)="onAddFields()"
                    [disabled]="!fieldsChecked">Add
                    Selected Fields</button>
            </div>
            <br>
            <div class="row card white" formArrayName="sections" (click)="sectionSelected(s)"
                *ngFor="let section of sectionControls.controls;let s=index">
                <div class="col s12 card-content" [formGroupName]='s'
                    [ngClass]="{'currentSection': (currentSection == s)}">

                    <h6 class="col s6 m9 center-align">Section {{s+1}} of {{sectionControls.controls.length}}</h6>
                    <a *ngIf="!showFields[s]" style="cursor: pointer;" (click)="expandSection(s)"
                        class="col s6 m3 right-align">
                        Show Fields<i class="material-icons prefix blue-text right">expand_more</i></a>
                    <a *ngIf="showFields[s]" style="cursor: pointer;" (click)="expandSection(s)"
                        class="col s6 m3 right-align">
                        Hide Fields<i class="material-icons prefix blue-text right">expand_less</i></a>
                    <br>
                    <div class="input-field col s12">
                        <i class="material-icons prefix blue-text">info</i>
                        <input id="sec_title" type="text" class="validate" formControlName="sec_title"
                            placeholder="untitled section" autocomplete="off" style="text-transform: capitalize;">
                        <label for="sec_title">Section Title<span class="red-text">*</span></label>
                        <div
                            *ngIf="(sectionControls.controls[s].get('sec_title').dirty || sectionControls.controls[s].get('sec_title').touched) && sectionControls.controls[s].get('sec_title').invalid">
                            <small *ngIf="sectionControls.controls[s].get('sec_title').errors.required"
                                class="red-text errorMsg"> Section Title is required. </small>
                        </div>
                    </div>
                    <div class="input-field col s12">
                        <i class="material-icons prefix blue-text">description</i>
                        <textarea id="statement" class="materialize-textarea" type="text" formControlName="statement"
                            style="text-transform: capitalize;"></textarea>
                        <label for="statement">Statement</label>
                    </div>

                    <div [sortablejs]="getSectionFields(s).controls" [sortablejsOptions]="options"
                        *ngIf="showFields[s]">
                        <div class="col s11 " style="margin-left: 25px;" formArrayName="fields">
                            <h6 *ngIf="getSectionFields(s).controls.lenght >0" class="center-align col s12">Fields</h6>
                            <div [sortablejs]="getSectionFields(s).controls" [sortablejsOptions]="options">
                                <div class="row col s12 card-panel white"
                                    *ngFor="let field of getSectionFields(s).controls; let f=index">
                                    <div class="col s12" style="font-weight: bold;">
                                        <!-- <i class="material-icons prefix green-text handle left small"
                                            style="cursor: move;">drag_indicator</i> -->
                                        <a class="waves-effect waves-teal btn-flat right red-text"
                                            (click)="deleteField(f)"><i
                                                class="small material-icons prefix">delete</i></a>
                                    </div>
                                    <div [formGroupName]="f">
                                        <div class="input-field col s12 m6">
                                            <i class="material-icons prefix blue-text">info</i>
                                            <input id="name" type="text" class="validate" formControlName="name"
                                                placeholder="Untitled Field" autocomplete="off"
                                                style="text-transform: capitalize;">
                                            <label for="name">Name<span class="red-text">*</span></label>
                                            <div
                                                *ngIf="(getSectionFields(s).controls[f].get('name').dirty || getSectionFields(s).controls[f].get('name').touched) && getSectionFields(s).controls[f].get('name').invalid">
                                                <small
                                                    *ngIf="getSectionFields(s).controls[f].get('name').errors.required"
                                                    class="red-text errorMsg"> Field Name is required. </small>
                                            </div>
                                        </div>
                                        <div class="input-field col s12 m6">
                                            <i class="material-icons prefix blue-text">description</i>
                                            <input id="default_value" type="text" formControlName="default_value"
                                                class="validate" autocomplete="off" style="text-transform: capitalize;">
                                            <label for="default_value">default_value</label>
                                        </div>

                                        <div class="input-field col s12 m6">
                                            <i class="material-icons prefix blue-text">list</i>
                                            <select formControlName="field_type" class="validate" id="field_type"
                                                (change)="fieldTypeSelected($event.target.value,f)">
                                                <option value="" disabled selected>Choose your option</option>
                                                <option *ngFor="let type of fieldTypes" [value]="type">{{type}}</option>
                                            </select>
                                            <label for="field_type">Field Type<span class="red-text">*</span></label>
                                            
                                            <!-- <div *ngIf="(getSectionFields(s).controls[f].get('field_type').dirty || getSectionFields(s).controls[f].get('field_type').touched) && getSectionFields(s).controls[f].get('field_type').invalid">
                <small *ngIf="getSectionFields(s).controls[f].get('field_type').errors.required" class="red-text errorMsg"> Field Type is required. </small>
            </div> -->
                                        </div>

                                        <!-- <div class="input-field col s12 m6" *ngIf="selectSelected && !anotherOptions">
            <i class="material-icons prefix blue-text">List</i>
            <select formControlName="select_options" class="validate" id="select_options">
                <option value="" disabled selected>Choose your option</option>
                <option *ngFor="let option of selectOptions" [value]="option">{{option}}
                </option>
            </select>
            <label for="data_type">Select Options</label>
            <button class="btn" (click)="addotherOption()">Add other Option</button>
        </div> -->

                                        <div class="input-field col s12 m6"
                                            *ngIf="selectFieldType[f] && !optionAdded[f]">
                                            <div class="col s8 chips chips-placeholder"></div>
                                            <button type='button' class="btn col s4" (click)="addOption(f)">Add
                                                Options</button>
                                        </div>

                <!-- <div class="input-field col s12 m6" *ngIf="checkBoxFieldType[f]">
            <div class="col s8 chips chips-placeholder"></div>
            <button type='button' class="btn col s4" (click)="addOption(f)">Add Options</button>
        </div>-->
                                        <!-- <div class="col s12"  *ngIf="optionAdded[f]">
            <ul class="row col s12">
                <li *ngFor="let option of getFieldOptions(s,f)" class="col s4">{{option}}</li>
            </ul> -->
                                        <!-- <ul class="collapsible" *ngIf="optionAdded[f]">
                <li>
                    <div class="collapsible-header"><i class="material-icons">list</i>Select Options
                    </div>
                    <div *ngFor="let option of getFieldOptions(s,f)" class="collapsible-body"><span>{{option}}</span></div>
                </li>
            </ul> -->
                                        <!-- </div> -->

                                        <div class="input-field col s12 m6">
                                            <i class="material-icons prefix blue-text">feed</i>
                                            <select formControlName="data_type" class="validate" id="data_type"
                                                (change)="dataTypeSelected($event.target.value, f)">
                                                <option value="" disabled selected>Choose your option</option>
                                                <option *ngFor="let type of dataTypes" [value]="type">{{type}}</option>
                                            </select>
                                            <label for="data_type">Data Type<span class="red-text">*</span></label>
                                        </div>

                                        <div class="input-field col s12 m6">
                                            <i class="material-icons prefix blue-text">feed</i>
                                            <select formControlName="icon" class="validate" id="icon"
                                                (change)="onIconSelected(f)">
                                                <option value="" disabled selected>Choose your option</option>
                                                <option *ngFor="let icon of icons" [value]="icon">
                                                    <i class="material-icons blue-text"
                                                        *ngIf="iconNotSelected[f]">{{icon}}</i> {{icon}}
                                                </option>
                                            </select>
                                            <label for="icon">Icon</label>
                                        </div>

                                        <div class="row col s12"><strong>Is the field required?</strong><span
                                                class="red-text">*</span></div>
                                        <div class="row input-field col s12 m6">
                                            <div class="row col s12">
                                                <label class="col s6">
                                                    <input type="radio" [value]="true" formControlName="required" />
                                                    <span>Required</span>
                                                </label>
                                                <label class="col s6">
                                                    <input type="radio" [value]="false" formControlName="required" />
                                                    <span>Optional</span>
                                                </label>
                                            </div>

                                        </div>

                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="card-action col s12">
                        <!-- <a *ngIf="!currentSection == s" style="cursor: pointer;" (click)="sectionSelected(s)">
                            <i class="material-icons prefix blue-text right">expand_more</i>Expand
                        </a> -->
                        <a *ngIf="s != 0" class="right red-text left" (click)="deleteSection(s)"
                            style="cursor: pointer;"><i class="material-icons prefix">delete</i>Delete Section</a>
                    </div>
                </div>
            </div>
            <button class="btn green col s12" type="submit" [disabled]="!form.valid">Create Application</button>
            <button [disabled]="!appCreated" class="btn blue col s12" type="button" style="margin-top: 25px;"
                (click)="goToApplication()">Go To Application</button>
            <!-- <button  class="btn blue col s12" type="button" style="margin-top: 25px;"
                (click)="showForm()">Show form</button> -->
        </form>
    </div>
</div>