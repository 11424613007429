<div class="row ">
    <div class="col l1" *ngIf="user">
        <app-side-menus [user]="user"></app-side-menus>
    </div>
    <div class="col s12 m12 l11 container-profile">

        <div class="user-profile">
            <h4 class="bold-700 sub-title-section black-text">Edit Profile</h4>
            <div *ngIf="user">
                <div class="row">
                    <div class="col s12 l3 center-align">
                        <div *ngIf="!profilePictureUrl" class="initial-profile center-align">
                            <h3 class="initials circle" [ngStyle]="{'background-color': themeColor}">
                                {{ getInitials(user.FirstName, user.LastName) }}
                            </h3>
                        </div>
                        <div *ngIf="profilePictureUrl">
                            <img class="circle" width="170" height="170" [src]="profilePictureUrl" /><br />
                        </div>
                        <a class=" sub-title-section3 modal-trigger" href="#UpdatePicture">Update Profile Picture ></a>
                    </div>
                    <div class="col s12 l7">
                        <div class="col s12 l6 input-field ">
                            <div class="label-input">User ID</div>
                            <input type="text" [value]="user.id" name="uid" placeholder="user id" disabled>
                        </div>
                        <div class="col s12 l6 input-field ">
                            <div class="label-input">GivenName</div>
                            <input type="text" class="validate" [(ngModel)]="user.GivenName" name="givenName"
                                placeholder="Given Name">
                        </div>
                        <div class="col s12 l6 input-field ">
                            <div class="label-input">First Name</div>
                            <input type="text" class="validate" [(ngModel)]="user.FirstName" name="firstName"
                                placeholder="First Name">
                        </div>
                        <div class="col s12 l6 input-field ">
                            <div class="label-input">Last Name</div>
                            <input type="text" class="validate" [(ngModel)]="user.LastName" name="lastName"
                                placeholder="Last Name">
                        </div>
                        <div class="col s12 l6 input-field ">
                            <div class="label-input">Role</div>
                            <input type="text" class="validate" [(ngModel)]="user.role" name="role" placeholder="Role"
                                disabled>
                        </div>
                        <div class="col s12 l6 input-field ">
                            <div class="label-input">Email</div>
                            <input type="text" class="validate" [(ngModel)]="user.Email" name="email"
                                placeholder="Email" disabled>
                        </div>
                        <div class="col s12 l6 input-field ">
                            <div class="label-input">Phone Number</div>
                            <!-- <input type="text" class="validate" [(ngModel)]="user.Phone" name="phone Number"
                                [textMask]="{mask: phoneNumberMask}" placeholder="Phone Number"> -->
                            <input type="text" [inputMask]="phoneInputMask" class="validate" [(ngModel)]="user.Phone"
                                name="phone Number" placeholder="Phone Number">
                        </div>

                        <div class="col s12 l12 p-t-2">
                            <button class="back-button left" (click)="onBack()">Go Back</button>
                            <button class="update-button right" (click)="onUpdate()">Update Profile</button>
                        </div>
                    </div>




                </div>
            </div>
            <ng-template #loading>
                <p>Loading user data...</p>
            </ng-template>



            <div id="UpdatePicture" class="modal">
                <div class="modal-content">
                    <label for="file-input">
                        <input type="file" id="file-input" (change)="onFileSelected($event)">
                        <!-- <img id="img-preview" [src]="profilePictureUrl ? profilePictureUrl : ''" /> -->

                        <div *ngIf="!profilePictureUrl">
                            <h3 class="initials circle" [ngStyle]="{'background-color': themeColor}">
                                {{ getInitials(user?.FirstName, user?.LastName) }}
                            </h3>
                        </div>
                        <img *ngIf="profilePictureUrl" id="img-preview" class="circle" width="170" height="170"
                            [src]="profilePictureUrl" />

                        <p>Click the image to upload</p>
                    </label>
                </div>
                <div class="modal-footer row">
                    <button id="saveButton" class="btn save-button left" (click)="saveProfilePicture()"
                        [disabled]="!imageChanged || !selectedFile">
                        Save
                    </button>
                    <a href="#!" class="btn modal-close cancel-button right white-text col l2 s10">Cancel</a>
                    <button class="btn delete-button right col l2 s10" *ngIf="profilePictureUrl"
                        (click)="deleteProfilePicture()">Delete</button>
                </div>
            </div>



            <!-- Delete Modal -->
            <div id="deleteModal" class="modal">
                <i class="material-icons right modal-close col s1">close</i>
                <div class="modal-content">
                    <div class="col s3">
                        <span class="material-symbols-outlined red-text" style="font-size: 70px;">
                            warning
                        </span>
                    </div>
                    <div class="col s9">
                        <h4 class="red-text">Delete profile picture?</h4>
                        <p>Are you sure you want to delete the profile picture?</p>
                    </div>
                </div>
                <div class="modal-footer row ">
                    <a href="#!" class="cancel-button btn col l5 s5 modal-close white-text">Cancel</a>
                    <a href="#!" class="continue-button btn col l5 s5 white-text"
                        (click)="confirmDeleteProfilePicture()">Delete</a>
                </div>
            </div>



        </div>
    </div>
</div>