<div class="body-h">

  <div class="header-setting b-m-2">
    <div class="col l9 m9 s12">
      <h4 class="">My Work Experience / Training </h4>
      <p class="bold-100 sub-title-section3">It is a long established fact that a reader will be distracted by the
        readable content of a page when looking at its layout.</p>
    </div>
    <div class="col l3  m4 s12 p-m-2 ">
      <a [routerLink]="['/member/member-main-home/addemployment']"
        class="add-button btn white center-align p-l-3 black-text"><i class="material-icons-outlined">add</i> Add Work
        Experience</a>
    </div>
  </div>
  <main class="StickyContent white">
    <div class="col s12 l11">

      <div class="row" *ngIf="hasRows && sqldb">
        <a class="" *ngFor="let exp of model" [routerLink]="['/member/member-main-home/edit-employment',exp.ID]">
          <div class="card-panel white center">
            <strong><i class="material-icons left brown-text">work</i> <span class="center">{{ exp.Name }}</span>
            </strong>
          </div>
        </a>
      </div>

      <div class="row" *ngIf="hasRows && !sqldb">
        <a class="" *ngFor="let exp of model" [routerLink]="['/member/profile/edit-employment',exp.id]">
          <div class="card-panel white center">
            <strong><i class="material-icons left brown-text">work</i> <span class="center">{{ exp.Name }}</span>
            </strong>
          </div>
        </a>
      </div>

      <div class="card-panel orange darken-2" *ngIf="!hasRows">
        <h6 class="center white-text"><i class="material-icons white-text left">info</i>
          <strong> You did not update your Work Experience/Training. <div class="section"></div> Please Keep it up to
            date. Potential
            employers will be able to search {{nation}} Database and contact you
            if your skills or education match their requirements.</strong>
        </h6>
      </div>

      <a [ngClass]="'col s12 btn waves-effect ' + themeColor" (click)="  backClicked() ">
        <i class="material-icons left">house</i> Back To Home Page
      </a>
    </div>
  </main>
</div>