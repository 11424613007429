import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Application } from 'src/app/models/ApplicationModel';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {

  constructor(private db: AngularFirestore) { }

  applicationListRef: AngularFirestoreCollection<any[]>;
  applicationRefs: AngularFirestoreDocument<any>;

  applicationNameExists(name: string) {
    return this.db.collection('Applications', ref => ref.where('name', '==', name));
  }

  createApplication(model: any) {
    const application = {
      id: this.db.createId(),
      name: model.name,
      description: model.description,
      department: model.department,
      logo: model.logo,
      menu: model.menu,
      sections: model.sections
    }
    console.log(application);
    return this.db.collection('Applications').doc(application.id).set(application);
  }

  updateApplication(id, model){
    const application = {
      id: id,
      name: model.name,
      description: model.description,
      department: model.department,
      logo: model.logo,
      menu: model.menu,
      sections: model.sections
    }
    return this.db.collection('Applications').doc(application.id).update(application);
  }

  getApplication(name: string) {
    return this.db.collection('Applications', ref => ref.where('name', '==', name));
  }

  getApplications(): AngularFirestoreCollection<Application> {
    return this.db.collection('Applications');
  }

  saveApplication(model: any) {
    model.id = this.db.createId();
    // const newApplication = {
    //   id: this.db.createId(),
    //   applicationName: model.applicationName,
    //   values: model.values,
    // }
    return this.db.collection('Application-Response').doc(model.id).set(model);
  }


  saveTemplate(model) {
    model.id = this.db.createId();
    return this.db.collection('Application-Templates').doc(model.id).set(model);
  }

  getTemplates(): AngularFirestoreCollection<any[]> {
    this.applicationListRef = this.db.collection('Application-Templates');
    return this.applicationListRef;
  }

}