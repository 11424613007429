<div class="row col s12">
  <h6 class="icon-text bold-700">Archived Posts</h6>
  <div class="col l1"></div>

  <div class="col s12 l10">
    <app-firebase-loading *ngIf="!model"></app-firebase-loading>

    <div class="row black-text center" *ngIf="!model || model?.length == 0" style="opacity: 0.5;">
      <img src="../../assets/img/empty-archived.png" height="450" width="450" class="responsive-img">
      <h6 class="bold-700">Sorry , no archived news!</h6>
    </div>

    <div class="row" *ngIf="model">
      <div *ngFor="let content of model | paginate: { itemsPerPage: 5, currentPage: p }">
        <div class="row card news-card hoverable" [ngStyle]="{'background-color': content.backgroundColor}"
          [routerLink]="['/admin/news-events/edit-card', content.id]" style="cursor: pointer;">
          <div class="row col s12 card-content">
            <div class="col s12"
              [ngClass]="{'col l9 m8 s12': content.type == 'PDF' || content.type == 'Video' || content.type == 'uploadVideo' || content.type == 'Photo'}">
              <p class="date-content sub-title-section3 p-b-1">
                {{content.author}} . {{content.datePublished.toDate() | date: 'MMM dd, yyyy'}}
                <span class="right">
                  <p *ngIf="content.visibility === 'Public'" class="green-text"><strong>Public</strong> </p>
                  <p *ngIf="content.visibility === 'Private'" class="red-text"><strong>Members Only</strong> </p>
                </span>
              </p>

              <p class="news-title">
                <a [routerLink]="['/admin/news-events/edit-card', content.id]" class="" [innerHTML]="content.title">
                </a>
              </p>

              <p *ngIf="content.imgLink" class="middle-body-snippet p-t-1 bold-100" style="text-align: justify;"
                [innerHTML]="truncateHTML(content.body,300)">
              </p>
              <p *ngIf="!content.imgLink" class="long-body-snippet p-t-1 bold-100" style="text-align: justify;"
                [innerHTML]="truncateHTML(content.body,300)"></p>
            </div>

            <div class="col l3 m4 hide-on-small-only"
              *ngIf="content.type == 'PDF' || content.type == 'Video' || content.type == 'uploadVideo' || content.type == 'Photo'">

              <div class="card-image" *ngIf="content.type === 'Photo' && content.imgLink">
                <img [src]="content.imgLink" class="news-img">
              </div>

              <div class="col l12 s12" *ngIf="content.type === 'PDF' && content.videoLink">
                <iframe title="iframe-box2" class="responsive-pdf" [src]="content.videoLink | safeUrl"></iframe>
              </div>

              <div class="card-image" *ngIf="content.type === 'Video' && content.videoLink">
                <div class="video-container ">
                  <youtube-player [videoId]="embedVideo(content.videoLink)"></youtube-player>
                  <!-- <div [innerHTML]="embedVideo(content.videoLink)"></div> -->
                </div>
              </div>

              <div class="card-image" *ngIf="content.type === 'uploadVideo' && content.videoLink">
                <video class="responsive-video " controls>
                  <source [src]="content.videoLink" type="video/mp4">
                </video>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row center">
        <pagination-controls (pageChange)="p = $event"></pagination-controls>
      </div>
    </div>

    <div class="row" *ngIf="model  && false">
      <div *ngFor="let content of model">
        <div class="row card news-card hoverable" [ngStyle]="{'background-color': content.backgroundColor}"
          [routerLink]="['/admin/news-events/edit-card', content.id]" style="cursor: pointer;">
          <div class="row col s12 card-content">
            <p class="news-title">
              <a [routerLink]="['/admin/news-events/edit-card', content.id]" class="" [innerHTML]="content.title">
              </a>
            </p>

            <p class="long-body-snippet sub-title-section3 p-t-1 bold-100" style="text-align: justify;">
              {{truncateHTML(content.body,300)}} </p>
          </div>
        </div>
      </div>
    </div>

    <div class="section"></div>
  </div>

  <div class="col l1"></div>
</div>