import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-approve-payments',
  templateUrl: './approve-payments.component.html',
  styleUrls: ['./approve-payments.component.css']
})
export class ApprovePaymentsComponent implements OnInit {

  constructor(private location: Location) { }

  themeColor = environment.appTheme.adminThemeColor;
  fontColor = environment.appTheme.adminFontColor;

  ngOnInit() {
  }

  backClicked() {
    this.location.back();
  }

}
