import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { ActivatedRoute, Router } from '@angular/router';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-test',
  templateUrl: './test.component.html',
  styleUrls: ['./test.component.css']
})

export class TestComponent implements OnInit {

  user: any;
  isSuperAdmin = false;
  permissions: any[];

  constructor(private authService: AuthService, 
    private router: Router) {

    this.authService.getCurrentApplicantIDObservable().subscribe(
      user => {
        if (user) {
          this.user = user;
          if (user.isSuperAdmin) this.isSuperAdmin = true;
          if (user.permissions) this.permissions = user.permissions;
          else if (!this.isSuperAdmin) {
            $('.modal').modal();
            $('#permissionModal').modal('open');
          }
        }
      }
    );
  }

  ngOnInit(): void {
  }

  editHeader() {
    this.router.navigate(['/admin/dashboard-sub-menu/settings/app-menu-settings']);
  }
  editBody() {
    this.router.navigate(['/admin/dashboard-sub-menu/settings/landing-page-settings']);
  }
  editFooter() {
    this.router.navigate(['admin/dashboard-sub-menu/settings/landing-page-settings']);
  }

}

