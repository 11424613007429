import { Injectable } from '@angular/core';
import { AngularFirestoreDocument, AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class AyshareUserService {

  socialMediaListRef: AngularFirestoreCollection<any>;
  socialMediaRef: AngularFirestoreDocument<any>;

  constructor(private db: AngularFirestore) { }

  createUserProile(model: any) {
    model.id = this.db.createId();
    return this.db.collection('AyrshareUserProfile').doc(model.id).set(model);
  }

  getEmployeeProfileById(uid){
    return this.db.collection('AyrshareUserProfile', ref=>ref.where('employeeId', '==',uid))

  }
  updateUserProfile(model: any) {
    return this.db.collection('AyrshareUserProfile').doc(model.id).update(model);
  }
  getProfiles(): AngularFirestoreCollection<any[]>{
    return this.db.collection('AyrshareUserProfile');
}
getOwnerProfiles(uid): AngularFirestoreCollection<any[]>{
  return this.db.collection('AyrshareUserProfile', ref=>ref.where('userId', '==',uid))



}

 createProfileRequest(model:any){
    model.id = this.db.createId();
    return this.db.collection('ProfileRequests').doc(model.id).set(model);
  }

  deleteProfile(profileKey){
      this.db.collection('AyrshareUserProfile', ref => ref.where('profileKey', '==', profileKey)).valueChanges().subscribe(row => {
        Object.keys(row).map(x => {
          console.log("Deleting Profile key: ",row[x])
          this.db.collection('AyrshareUserProfile').doc(row[x].id).delete();
        });
    });
      // then(function(querySnapshot) {
      //   querySnapshot.forEach(function(doc) {
      //     doc.ref.delete();
      //   });
      // });
  }
//   getSocialMedia() {
//     this.socialMediaListRef = this.db.collection('SocialMedia');
//     return this.socialMediaListRef;
//   }

}
