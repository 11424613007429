import { Component, Input, OnInit, SimpleChange } from '@angular/core';
import { MemberService } from 'src/app/services/api/member.service';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { FormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { EmailService } from 'src/app/services/api/email.service';
import { toast } from 'materialize-css';
import { ManageAdminService } from 'src/app/services/api/manage-admin.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { SMSService } from 'src/app/services/api/sms.service';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-upcoming-birthdays',
  templateUrl: './upcoming-birthdays.component.html',
  styleUrls: ['./upcoming-birthdays.component.css']
})
export class UpcomingBirthdaysComponent implements OnInit {

  public phoneNumberMask = ['(', /[0-9]/, /[0-9]/, /[0-9]/, ')',
  ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  defaultThemeColor = environment.appTheme.themeColor;
  themeColor: any;
  buttonType: any;
  @Input() filters: any;
  contact = false;
  contactDetail: any = {
    multiple: false,
  }
  message: any = {
    title: '',
    subject: '',
    body: '',
    files: []
  }
  members: any[];
  daysInterval = 0;
  loggedUser: any;
  count = 0;
  selectedMember: any;
  membersData: any[];
  emailForm: UntypedFormGroup;
  SMSForm: UntypedFormGroup;
  contactInfo: UntypedFormGroup;
  adminInfo: any = {};
  emailList = [];
  phoneNoList = [];
  selectAllChecked = false;
  selectAllNosChecked = false;
  send = '';
  selectEmail = false;
  showOnlyEmail = false;
  showOnlyMobile = false;
  showOnlyRegistered = false;

  constructor(private location: Location,
    private memberService: MemberService,
    private manageAdminService: ManageAdminService,
    private settingsService: AppSettingsService) { 
      this.themeColor = this.settingsService.getAppThemeColor() ? this.settingsService.getAppThemeColor() : this.defaultThemeColor;
      this.buttonType = this.settingsService.getButtonType() ? this.settingsService.getButtonType() : 'rectangularButton';
    }

  ngOnInit() {
    setTimeout(() => {
      $('.modal').modal();
      $('.chips').chips();
      $('.chips').chips({
        onChipDelete: (e, data) => {
          var email = data.innerText.replace('close', '').trim();
          if (this.emailList.length > 0) {
            var index = this.emailList.indexOf(email);
            this.emailList.splice(index, 1);
          }
        }
      });
      $('.no-chips').chips({
        onChipDelete: (e, data) => {
          var number = data.innerText.replace('close', '').trim();
          if (this.phoneNoList.length > 0) {
            var index = this.phoneNoList.indexOf(number);
            this.phoneNoList.splice(index, 1);
          }
        }
      });
    }, 25);
  }

  ngOnChanges(changes: SimpleChange) {
    let change: SimpleChange = changes['filters'];
    this.filters = change.currentValue;
    const filterSelected = Object.values(this.filters).every(value => !value);
    if (!filterSelected) {
      if (this.filters.today) {
        this.today();
      }
      if (this.filters.thisWeek) {
        this.thisWeek();
      }
      if (this.filters.thisMonth) {
        this.thisMonth();
      }
    }
  }

  today() {
    this.reset();
    this.filters.today = true;
    this.daysInterval = 0;
    this.memberService.getUpcomingBirthDays(this.daysInterval).subscribe(x => {
      if (x) {
        this.members = x;
        this.membersData = this.members;
        this.count = this.members.length;
      } else {
        this.count = 0;
      }
    });
  }

  thisWeek() {
    this.reset();
    this.filters.thisWeek = true;
    this.daysInterval = 7;
    this.memberService.getUpcomingBirthDays(this.daysInterval).subscribe(x => {
      if (x) {
        this.members = x;
        this.count = this.members.length;
        this.membersData = this.members;
      } else {
        this.count = 0;
      }
    });
  }

  thisMonth() {
    this.reset();
    this.filters.thisMonth = true;
    this.daysInterval = 30;
    this.memberService.getUpcomingBirthDays(this.daysInterval).subscribe(x => {
      if (x) {
        this.members = x;
        this.count = this.members.length;
        this.membersData = this.members;
      } else {
        this.count = 0;
      }
    });
  }

  backClicked() {
    this.location.back();
  }

  reset() {
    Object.keys(this.filters).forEach(v => this.filters[v] = false)
    this.emailList = [];
    this.phoneNoList = [];
    this.showOnlyEmail = false;
    this.showOnlyMobile = false;
    this.showOnlyRegistered = false;
    this.selectAllChecked = false;
    this.selectAllNosChecked = false;
  }

  filterBy(filter) {
    if (filter == 'email') {
      this.filterByEmail();
    }
    if (filter == 'mobile') {
      this.filterByMobile();
    }
    if (filter == 'registered') {
      this.filterByRegistred();
    }
  }

  filterByEmail() {
    this.showOnlyEmail = !this.showOnlyEmail;
    if (this.showOnlyEmail) {
      this.membersData = this.members.filter(member => member.Email);
      this.count = this.membersData.length;
    } else {
      this.membersData = this.members;
      this.count = this.members.length;
    }
  }

  filterByMobile() {
    this.showOnlyMobile = !this.showOnlyMobile;
    if (this.showOnlyMobile) {
      this.membersData = this.members.filter(member => member.mobileNumber);
      this.count = this.membersData.length;
    } else {
      this.membersData = this.members;
      this.count = this.members.length;
    }
  }

  filterByRegistred() {
    this.showOnlyRegistered = !this.showOnlyRegistered;
    if (this.showOnlyRegistered) {
      this.membersData = this.members.filter(member => member.isRegistred);
      this.count = this.membersData.length;
    } else {
      this.membersData = this.members;
      this.count = this.members.length;
    }
  }

  selectAll() {
    this.selectAllChecked = !this.selectAllChecked;
    this.members.forEach(member => {
      if (member.Email) {
        if (this.selectAllChecked) {
          if (!this.emailList.includes(member.Email)) {
            this.emailList.push(member.Email);
            //$('.chips').chips('addChip', { tag: member.Email });
          }
        } else {
          var index = this.emailList.indexOf(member.Email);
          if (index >= 0) this.emailList.splice(index, 1);
          //if(index >= 0) $('.email-chips').chips('deleteChip', index);
          if (this.emailList.length == 0) this.selectAllChecked = false;
        }
      }
    });
  }

  emailSelected(email) {
    if (this.emailList.includes(email)) {
      const index = this.emailList.indexOf(email);
      this.emailList.splice(index, 1);
      //$('.chips').chips('deleteChip', index);
      if (this.emailList.length == 0) this.selectAllChecked = false;
    } else {
      this.emailList.push(email);
      //$('.chips').chips('addChip', { tag: email });
    }
  }

  selectAllNos() {
    this.selectAllNosChecked = !this.selectAllNosChecked;
    this.members.forEach(member => {
      if (member.mobileNumber) {
        if (this.selectAllNosChecked) {
          if (!this.phoneNoList.includes(member.mobileNumber)) {
            this.phoneNoList.push(member.mobileNumber);
            //$('.no-chips').chips('addChip', { tag: member.mobileNumber });
          }
        } else {
          var index = this.phoneNoList.indexOf(member.mobileNumber);
          if (index >= 0) this.phoneNoList.splice(index, 1);
          if (this.phoneNoList.length == 0) this.selectAllNosChecked = false;
        }
      }
    });
  }

  phoneNoSelected(number) {
    if (this.phoneNoList.includes(number)) {
      const index = this.phoneNoList.indexOf(number);
      this.phoneNoList.splice(index, 1);
      //$('.no-chips').chips('deleteChip', index);
      if (this.phoneNoList.length == 0) this.selectAllNosChecked = false;
    } else {
      this.phoneNoList.push(number);
      //$('.no-chips').chips('addChip', { tag: number });
    }
  }

  sendEmail() {
    this.contactDetail.multiple = true;
    this.contactDetail.email = true;
    this.contactDetail.emailList = this.emailList;
    this.message.subject = 'Wishing You a Happy Birthday';
    this.contact = true;
  }

  sendSMS() {
    this.contactDetail.multiple = true;
    this.contactDetail.sms = true;
    this.contactDetail.phoneNoList = this.phoneNoList;
    this.message.subject = 'Wishing You a Happy Birthday';
    this.contact = true;
  }

  contactHandler(valueEmitted) {
    if (valueEmitted.status == null) {
      return;
    }
    if (this.contactDetail.email) {
      if (valueEmitted.status) {
        toast({ html: 'Email Successfully Sent.', classes: 'green' });
      } else {
        toast({ html: 'Unable to Send Email!.', classes: 'red' });
      }
    } else if (this.contactDetail.sms) {
      if (valueEmitted.status) {
        toast({ html: 'SMS Successfully Sent.', classes: 'green' });
      } else {
        toast({ html: 'Unable to Send Text Message!.', classes: 'red' });
      }
    }
    this.contact = false;
    this.contactDetail.email = false;
    this.contactDetail.sms = false;
  }
 
  cancel() {
    $('.modal').modal('close');
  }

  showDetail(member) {
    this.selectedMember = member;
  }

  updateInfo(values) {
    values.uid = this.loggedUser.id;
    if (values.Cell) values.Cell = values.Cell.replace(/\D+/g, '');
    if (values.phone) values.phone = values.phone.replace(/\D+/g, '');
    this.manageAdminService.UpdateAdminContact(values).subscribe(x => {
      if (x) {
        this.adminInfo.Email_Address = values.Email_Address;
        this.adminInfo.Cell = values.Cell;
        this.adminInfo.phone = values.phone;
        this.adminInfo.ph_extension = values.ph_extension;
        this.adminInfo.Title = values.Title;
        this.adminInfo.show_cell = values.show_cell;
        this.adminInfo.show_email = values.show_email;
        this.adminInfo.show_office = values.show_office;
        this.adminInfo.show_title = values.show_title;
        toast({ html: 'Contact Info Successfully Updated!', classes: 'green' });
      }
    });
    $('#settingModal').modal('close');
    if (this.send == 'email') $('#emailModal').modal('open');
    if (this.send == 'text') $('#SMSModal').modal('open');
  }
}
