import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Dimensions, ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { ImageModel } from 'src/app/models/ImageModel';
import { MediaService } from 'src/app/services/api/media.service';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { DepartmentService } from 'src/app/services/firebase/department.service';
import { EventsService } from 'src/app/services/firebase/events.service';
import { GovernanceService } from 'src/app/services/firebase/governance.service';
import { SocialMediaService } from 'src/app/services/firebase/social-media.service';
import { StaticContentService } from 'src/app/services/firebase/static-content.service';
import { HelperService } from 'src/app/services/helper/helper';
import { environment } from 'src/environments/environment';
import { toast } from 'materialize-css';
import { cloneDeep } from "lodash";
import { createMask } from '@ngneat/input-mask';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-all-menu-settings',
  templateUrl: './all-menu-settings.component.html',
  styleUrls: ['./all-menu-settings.component.css']
})
export class AllMenuSettingsComponent implements OnInit {

  // public phoneNumberMask = ['(', /[0-9]/, /[0-9]/, /[0-9]/, ')', ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  // public postalCodeMask = [/[A-Za-z]/, /[0-9]/, /[A-Za-z]/, ' ', /[0-9]/, /[A-Za-z]/, /[0-9]/];
  
  postalCodeMask = createMask<string>({
    mask: 'A9A 9A9' // Replace {1} with a space if desired
  });

  phoneInputMask = createMask<string>( {
    mask: '(999) 999-9999',
    guide: true,
    showMaskTyped: true,
  });

  defaultThemeColor = environment.appTheme.themeColor;
  submenus: any[];
  sectionName: string;
  isNewSetting = true;
  activeModalIndex: number = -1;
  defaultMenus: any[];
  themeColor: any;
  editName = false;
  selectedMenu: string = 'admin';
  menu: any;
  menus: any;
  menuss: any;
  pageSections: any;
  defaultPublicMenus: any;
  sectionTitle: string = 'Admin Menu';
  cardV: boolean = false;
  isActiveReorder: boolean = true;
  isActiveCozy: boolean = false;
  searchQuery: string = '';
  filteredMenus: any[];
  selectedSection: any;
  hasISET = environment.firstNation.ISET;
  // ===== REF
  loginRef: any;
  newsRef: any = {};
  sliderRef: any;
  slideRef: any;
  eventRef: any;
  appStoreRef: any;
  footerRef: any = {};
  isetRegRef: any;
  governanceRef: any = {};
  departmentsRef: any = {};
  aboutUsRef: any = {};
  socialMediaRef: any = {};
  contactUsRef: any = {};
  demoAccountRef:any={};
  // ===========SETTING
  loginSettings = false;
  sectionsSetting = true;
  departmentsSettings = false;
  governanceSettings = false;
  footerSettings = false;
  aboutUsSetting = false;
  isetRegSetting = false;
  socialMediaSettings = false;
  appStoreSettings = false;
  eventsSettings = false;
  newsSettings = false;
  contactUsSetting = false;
  demoAccountSetting=false;
  // ===============

  emptySlideOption = false;
  sliderSettings = false;
  bgImage = false;
  logoUrl: any;
  bgColor: any;
  textColor: any;

  // ===========Array
  newsArray: any;
  eventArray: any;
  aboutUsArray: any;
  governanceArray: any;
  departmentsArray: any;
  //===============

  publishedPublicNews: any;
  eventsModel: any;
  socialMediaModel: any;
  // ===========view
  cardView: boolean = true;
  currentEditingSection: any;
  // ==========slider 
  editSlider = false;
  croppedImage: any = '';
  imageChangedEvent: any;
  selectedSlider: any;
  isPositionChanged = false;
  selectedNewPosition: any;

  // ========= chatgpt
  openChatGpt = false
  selectedInput: any;
  inputText: any;
  slideIndex: any;
  changedSlideValue: any;

  // ==========resizer 
  showCropper = false;
  canvasRotation = 0;
  scale = 1;
  rotation = 0;
  transform: ImageTransform = {};
  zoomScale = 10;
  containWithinAspectRatio = false;
  mediaList = [];
  showImageCropperModal = false;
  // apply all =========
  previewSection: any;
  previewBefore
  // footer all =========
  footerStyle: any;
  // Social Media=========
  socialForm: UntypedFormGroup;
  socialID = '';
  hasSocial = false;
  urlPattern = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
  // =======apperance ======
  isCollapsed: boolean = true;
  isCollapsedd: boolean = true;
  desktopGridSize = 'l4';
  mobileGridSize = 's2';
  cardOption = 'Rectangle';
  cardIconOption = 'Rectangle';
  cardSizeOption = 'Small';
  titlePositionOption = 'Bottom';
  imageOption = "no";
  imageCoverOption = "InhertContent";
// filter icons /
selectedIcon: string;
  filteredIcons: string[] = [];
  allIcons: string[] = [
    "accessibility", "account_balance", "alarm", "android", "assignment", "backup",
    "book", "camera", "cloud", "dashboard", "email", "favorite",
    "grade", "home", "info", "language", "mail", "notifications",
    "people", "settings", "3d_rotation", "ac_unit", "access_alarm", "access_alarms",
    "access_time", "accessible", "account_balance_wallet", "account_box", "account_circle", "adb",
    "add", "add_a_photo", "add_alarm", "add_alert", "add_box", "add_circle",
    "add_circle_outline", "add_location", "add_shopping_cart", "add_to_photos", "add_to_queue", "adjust",
    "airline_seat_flat", "airline_seat_flat_angled", "airline_seat_individual_suite", "airline_seat_legroom_extra", "airline_seat_legroom_normal", "airline_seat_legroom_reduced",
    "airline_seat_recline_extra", "airline_seat_recline_normal", "airplanemode_active", "airplanemode_inactive", "airplay", "airport_shuttle",
    "alarm_add", "alarm_off", "alarm_on", "album", "all_inclusive", "all_out",
    "announcement", "apps", "archive", "arrow_back", "arrow_downward", "arrow_drop_down",
    "arrow_drop_down_circle", "arrow_drop_up", "arrow_forward", "arrow_upward", "art_track", "aspect_ratio",
    "assessment", "assignment_ind", "assignment_late", "assignment_return", "assignment_returned", "assignment_turned_in",
    "assistant", "assistant_photo", "attach_file", "attach_money", "attachment", "audiotrack",
    "autorenew", "av_timer", "backspace", "battery_alert", "battery_charging_full", "battery_full",
    "battery_std", "battery_unknown", "beach_access", "beenhere", "block", "bluetooth", "bluetooth_audio",
    "bluetooth_connected", "bluetooth_disabled", "bluetooth_searching", "blur_circular", "blur_linear", "blur_off",
    "blur_on", "bookmark", "bookmark_border", "border_all", "border_bottom", "border_clear", "border_color",
    "border_horizontal", "border_inner", "border_left", "border_outer", "border_right", "border_style",
    "border_top", "border_vertical", "branding_watermark", "brightness_1", "brightness_2", "brightness_3", "brightness_4",
    "brightness_5", "brightness_6", "brightness_7", "brightness_auto", "brightness_high", "brightness_low",
    "brightness_medium", "broken_image", "brush", "bubble_chart", "bug_report", "build", "burst_mode",
    "business", "business_center", "cached", "cake", "call", "call_end", "call_made", "call_merge", "call_missed",
    "call_missed_outgoing", "call_received", "call_split", "call_to_action", "camera_alt", "camera_enhance", "camera_front",
    "camera_rear", "camera_roll", "cancel", "card_giftcard", "card_membership", "card_travel", "casino",
    "cast", "cast_connected", "center_focus_strong", "center_focus_weak", "change_history", "chat", "chat_bubble",
    "chat_bubble_outline", "check", "check_box", "check_box_outline_blank", "check_circle", "check_circle_outline",
    "chevron_left", "chevron_right", "child_care", "child_friendly", "chrome_reader_mode", "class", "clear",
    "clear_all", "close", "closed_caption", "cloud_circle", "cloud_done", "cloud_download", "cloud_off", "cloud_queue",
    "cloud_upload", "code", "collections", "color_lens", "colorize", "comment", "compare", "compare_arrows",
    "computer", "confirmation_number", "contact_mail", "contact_phone", "contacts", "content_copy", "content_cut",
    "content_paste", "control_point", "control_point_duplicate", "create", "create_new_folder", "credit_card", "crop",
    "crop_16_9", "crop_3_2", "crop_5_4", "crop_7_5", "crop_din", "crop_free", "crop_landscape", "crop_original",
    "crop_portrait", "crop_rotate", "crop_square", "data_usage", "date_range", "dehaze", "delete", "delete_forever",
    "delete_outline", "delete_sweep", "departure_board", "description", "desktop_mac", "desktop_windows", "details",
    "developer_board", "developer_mode", "device_hub", "device_unknown", "devices", "devices_other", "dialer_sip",
    "dialpad", "directions", "directions_bike", "directions_boat", "directions_bus", "directions_car", "directions_railway",
    "directions_run", "directions_subway", "directions_transit", "directions_walk", "disc_full", "dns", "do_not_disturb",
    "do_not_disturb_alt", "do_not_disturb_off", "do_not_disturb_on", "dock", "domain", "done", "done_all", "done_outline",
    "donut_large", "donut_small", "drafts", "drag_handle", "drive_eta", "dvr", "edit", "edit_location", "eject",
    "enhanced_encryption", "equalizer", "error", "error_outline", "euro_symbol", "ev_station", "event", "event_available",
    "event_busy", "event_note", "event_seat", "exit_to_app", "expand_less", "expand_more", "explicit", "explore", "exposure",
    "exposure_neg_1", "exposure_neg_2", "exposure_plus_1", "exposure_plus_2", "exposure_zero", "extension", "face",
    "fast_forward", "fast_rewind", "favorite_border", "featured_play_list", "featured_video", "feedback", "fiber_dvr",
    "fiber_manual_record", "fiber_new", "fiber_pin", "fiber_smart_record", "file_download", "file_upload", "filter",
    "filter_1", "filter_2", "filter_3", "filter_4", "filter_5", "filter_6", "filter_7", "filter_8", "filter_9",
    "filter_9_plus", "filter_b_and_w", "filter_center_focus", "filter_drama", "filter_frames", "filter_hdr", "filter_list",
    "filter_none", "filter_tilt_shift", "filter_vintage", "find_in_page", "find_replace", "fingerprint", "first_page",
    "fitness_center", "flag", "flare", "flash_auto", "flash_off", "flash_on", "flight", "flight_land", "flight_takeoff",
    "flip", "flip_to_back", "flip_to_front", "folder", "folder_open", "folder_shared", "folder_special", "font_download",
    "format_align_center", "format_align_justify", "format_align_left", "format_align_right", "format_bold", "format_clear",
    "format_color_fill", "format_color_reset", "format_color_text", "format_indent_decrease", "format_indent_increase",
    "format_italic", "format_line_spacing", "format_list_bulleted", "format_list_numbered", "format_paint", "format_quote",
    "format_shapes", "format_size", "format_strikethrough", "format_textdirection_l_to_r", "format_textdirection_r_to_l",
    "format_underlined", "forum", "forward", "forward_10", "forward_30", "forward_5", "free_breakfast", "fullscreen",
    "fullscreen_exit", "functions", "g_translate", "gamepad", "games", "gavel", "gesture", "get_app", "gif",
    "golf_course", "gps_fixed", "gps_not_fixed", "gps_off", "gradient", "grain", "graphic_eq", "grid_off", "grid_on",
    "group", "group_add", "group_work", "hd", "hdr_off", "hdr_on", "hdr_strong", "hdr_weak", "headset", "headset_mic",
    "healing", "hearing", "height", "help", "help_outline", "high_quality", "highlight", "highlight_off", "history",
    "hot_tub", "hotel", "hourglass_empty", "hourglass_full", "http", "https", "image", "image_aspect_ratio", "import_contacts",
    "import_export", "important_devices", "inbox", "indeterminate_check_box", "input", "insert_chart", "insert_comment",
    "insert_drive_file", "insert_emoticon", "insert_invitation", "insert_link", "insert_photo", "invert_colors",
    "invert_colors_off", "iso", "keyboard", "keyboard_arrow_down", "keyboard_arrow_left", "keyboard_arrow_right",
    "keyboard_arrow_up", "keyboard_backspace", "keyboard_capslock", "keyboard_hide", "keyboard_return", "keyboard_tab",
    "keyboard_voice", "kitchen", "label", "label_outline", "landscape", "laptop", "laptop_chromebook", "laptop_mac",
    "laptop_windows", "last_page", "launch", "layers", "layers_clear", "leak_add", "leak_remove", "lens", "library_add",
    "library_books", "library_music", "lightbulb_outline", "line_style", "line_weight", "linear_scale", "link",
    "linked_camera", "list", "live_help", "live_tv", "local_activity", "local_airport", "local_atm", "local_bar",
    "local_cafe", "local_car_wash", "local_convenience_store", "local_dining", "local_drink", "local_florist",
    "local_gas_station", "local_grocery_store", "local_hospital", "local_hotel", "local_laundry_service", "local_library",
    "local_mall", "local_movies", "local_offer", "local_parking", "local_pharmacy", "local_phone", "local_pizza",
    "local_play", "local_post_office", "search", "account_circle", "chat", "lock", "help", "arrow_back", "arrow_forward",
    "menu", "delete", "edit", "done", "clear", "refresh", "star", "star_border", "flag", "share", "more_vert",
    "attach_file", "file_download", "file_upload", "picture_in_picture", "videocam", "photo", "library_books", "map",
    "location_on", "event", "group", "work", "shopping_cart", "payment", "receipt", "local_offer", "restaurant", "flight",
    "hotel", "directions", "local_phone", "web", "public", "today", "event_available", "event_busy", "timer", "location_city",
    "place", "local_gas_station", "local_hospital", "local_movies", "local_grocery_store", "local_pizza", "local_cafe",
    "local_bar", "local_pharmacy", "local_library", "local_mall", "local_airport", "local_atm", "local_car_wash",
    "local_convenience_store", "local_dining", "local_drink", "local_florist", "local_play", "local_post_office",
    "local_laundry_service", "local_offer", "flight_land", "flight_takeoff", "directions_car", "directions_transit",
    "directions_bike", "directions_walk", "directions_run", "train", "tram", "subway", "directions_railway", "directions_boat",
    "directions_bus", "restaurant_menu", "store", "store_mall_directory", "local_printshop", "local_shipping", "storefront",
    "business", "school", "wifi", "bluetooth", "data_usage", "battery_full", "signal_cellular_alt", "signal_wifi_4_bar",
    "airplanemode_active", "location_disabled", "gps_fixed", "settings_brightness", "brightness_medium", "screen_rotation",
    "screen_lock_portrait", "developer_mode", "security", "vpn_lock", "lock_outline", "credit_card", "payment", "money",
    "attach_money", "monetization_on", "trending_up", "trending_down", "thumbs_up_down", "thumb_up", "thumb_down", "stars",
    "star_border", "star_half", "whatshot", "favorite_border", "share", "sms", "phone", "call", "call_end", "contact_phone",
    "contact_mail", "location_on", "place", "map", "directions", "directions_run", "explore", "near_me",
  ];
  // ################### importeddd #######################
  @ViewChild('imageCropperModal') imageCropperModal: ElementRef;
  @Output() closeCropModal = new EventEmitter<string>();
  @Input() theImage = '';
  @Input() useBase64 = false;
  @Input() aspectRatio = '';
  @Output() returnCroppedImage = new EventEmitter<string>();
  @Input() sections: any;
  // @ViewChild('cardEditModal') cardEditModal: ElementRef;

  get facebook() { return this.socialForm.get('facebook'); }
  get twitter() { return this.socialForm.get('twitter'); }
  get instagram() { return this.socialForm.get('instagram'); }
  get youtube() { return this.socialForm.get('youtube'); }
  

  constructor(
    private settingService: AppSettingsService,
    private router: Router,
    private newsService: StaticContentService,
    private eventsService: EventsService,
    private socialService: SocialMediaService,
    private departmentService: DepartmentService,
    private governanceService: GovernanceService,
    public appSettingsService: AppSettingsService,
    private helperService: HelperService,
    private cdRef: ChangeDetectorRef,
    private mediaService: MediaService,
    private fb: UntypedFormBuilder,
    private service: SocialMediaService,
    private cdr: ChangeDetectorRef
  ) {
    this.themeColor = this.settingService.getAppThemeColor() ? this.settingService.getAppThemeColor() : this.defaultThemeColor;
    this.filteredIcons = this.allIcons;
  }

  ngOnInit(): void {
    // ============= admin Menu ======================
    this.settingService.getMenuName('Settings').valueChanges().subscribe(menus => {
      if (menus && menus.length > 0) {
        this.submenus = menus[0].submenus;
        let menu = this.submenus.find(m => m.name == 'AdminMenu');
        if (menu) this.sectionName = menu.displayName;
      }
    });
    this.settingService.getAdminMenus().valueChanges().subscribe(adminMenus => {
      if (adminMenus && adminMenus.length > 0) {
        this.menu = adminMenus;
        this.isNewSetting = false;
      } else {
        this.menu = this.defaultMenus;
        this.isNewSetting = true;
      }
      // this.menu = this.menu.sort((a, b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0));
      if (this.menu && this.menu.length > 0) {
        this.menu.sort((a, b) => (a.index > b.index) ? 1 : -1);
      }
      setTimeout(() => {
        M.updateTextFields();
        $('.collapsible').collapsible();
        $('.tooltipped').tooltip();
        $('.modal').modal();
        $('.modal').modal('close');
      }, 25);
    });
    // ============= Member Menu ======================

    this.settingService.getMenuName('Settings').valueChanges().subscribe(menus => {
      if (menus && menus.length > 0) {
        this.submenus = menus[0].submenus;
        let menu = this.submenus.find(m => m.name == 'MemberMenu');
        if (menu) this.sectionName = menu.displayName;
      }
    });
    this.settingService.getMemberMenus().valueChanges().subscribe(menus => {
      if (menus && menus.length > 0) {
        this.menus = menus;
        this.isNewSetting = false;
      } else {
        this.menus = this.defaultMenus;
        this.isNewSetting = true;
      }

      // this.menus = this.menus.sort((a, b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0));

      if (this.menus && this.menus.length > 0) {
        this.menus.sort((a, b) => (a.index > b.index) ? 1 : -1);
      }
      setTimeout(() => {
        M.updateTextFields();
        $('.collapsible').collapsible();
        $('.modal').modal();
        $('.modal').modal('close');
      }, 25);
    });
    // ============= Public Menu ======================
    this.settingService.getHomePageSettings().valueChanges().subscribe(hp => {
      if (hp && hp.length > 0) {
        this.pageSections = hp[0];
        this.isNewSetting = false;
        this.pageSections.sections = this.pageSections.sections.sort((a, b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0));
      } else {
        this.pageSections = this.defaultPublicMenus;
        this.pageSections.sections = this.pageSections.sections.sort((a, b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0));
        this.isNewSetting = true;
      }
      // this.menuss = this.menuss.sort((a, b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0));

      if (this.menuss && this.menuss.length > 0) {
        this.menuss.sort((a, b) => (a.index > b.index) ? 1 : -1);
      }
      this.cardView = this.pageSections.cardView;
      if (this.pageSections.desktopGridSize) this.desktopGridSize = this.pageSections.desktopGridSize;
      else this.pageSections.desktopGridSize = this.desktopGridSize;
      if (this.pageSections.mobileGridSize) this.mobileGridSize = this.pageSections.mobileGridSize;
      else this.pageSections.mobileGridSize = this.mobileGridSize;
      if (this.pageSections.cardShape) this.cardOption = this.pageSections.cardShape;
      else this.pageSections.cardShape = this.cardOption;
      if (this.pageSections.cardIconShape) this.cardIconOption = this.pageSections.cardIconShape;
      else this.pageSections.cardIconShape = this.cardIconOption;
      if (this.pageSections.cardSize) this.cardSizeOption = this.pageSections.cardSize;
      else this.pageSections.cardSize = this.cardSizeOption;
      if (this.pageSections.imageShape) this.imageOption = this.pageSections.imageShape;
      else this.pageSections.imageShape = this.imageOption;
      if (this.pageSections.titlePosition) this.titlePositionOption = this.pageSections.titlePosition;
      else this.pageSections.titlePosition = this.titlePositionOption;
      if (this.pageSections.imageCover) this.imageCoverOption = this.pageSections.imageCover;
      else this.pageSections.imageCover = this.imageCoverOption;

      setTimeout(() => {
        M.updateTextFields();
        $('.collapsible').collapsible();
        $('.modal').modal();
        // $('.modal').modal('close');
      }, 25);
    });
    this.filteredMenus = this.menu;
    // =========== card view =========
    this.cardView = true; // Default to card view
    this.pageSections = {
      cardView: this.cardView
    };
    // ============= social media ======================
    this.socialForm = this.fb.group({
      facebook: ['', [ Validators.pattern(this.urlPattern)]],
      twitter: ['', [Validators.pattern(this.urlPattern)]],
      instagram: ['', [ Validators.pattern(this.urlPattern)]],
      youtube: ['', [ Validators.pattern(this.urlPattern)]],
    });

    this.service.getSocialMedia().valueChanges().subscribe(x => {
      if (x) {
        if (x.length > 0) {
          this.socialForm.patchValue({
            facebook: x[0].facebook,
            twitter: x[0].twitter,
            instagram: x[0].instagram,
            youtube: x[0].youtube
          });
          this.hasSocial = true;
          this.socialID = x[0].id;
          setTimeout(() => {
            M.updateTextFields();
          }, 50);
        }
      }
    });
    // ============= card view ======================
    // M.Modal.init(this.cardEditModal.nativeElement);
  }

  //  ==*======*======*========******=============== Header changes ======******================**======== 
  onMenuChange() {
    switch (this.selectedMenu) {
      case 'admin':
        this.sectionTitle = 'Admin Menu';
        break;
      case 'member':
        this.sectionTitle = 'Member Menu';
        break;
      case 'public':
        this.sectionTitle = 'Public Menu';
        break;
      case 'all':
        this.sectionTitle = 'All Menu';
        break;
      default:
        this.sectionTitle = 'Admin Menu';
    }
  }
  onReorderClick(): void {
    this.isActiveReorder = !this.isActiveReorder; // Toggle active state for reorder
    this.isActiveCozy = false; // Ensure cozy view is not active
    if (this.selectedMenu === 'admin') {
      this.cardV = false;
    }
    if (this.selectedMenu === 'member') {
      this.cardV = false;
    }
    if (this.selectedMenu === 'public') {
      this.cardV = false;
    }
    if (this.selectedMenu === 'all') {
      this.cardV = false;
    }
  }
  onViewCozyClick(): void {
    this.isActiveCozy = !this.isActiveCozy; // Toggle active state for cozy view
    this.isActiveReorder = false; // Ensure reorder view is not active
    this.cardV = true;
  }
  // ==*===*====*======******======== Admin Menu , Member Menu and Public Menu ======******================**======== 
  // =================== admin  Move Menu and Submenu ==================================

  moveAdminSection(element, position: number) {
    var index = this.menu.indexOf(element);
    var newIndex = index + position;
    if (newIndex < 0 || newIndex == this.menu.length) return; //Already at the top or bottom.   
    this.menu.splice(index, 1);
    this.menu.splice(newIndex, 0, element);
    this.menu.forEach((element, i) => {
      element.index = i;
    });
  };

  moveAdminSubmenu(section, element, position: number) {
    var index = this.menu[section].submenus.indexOf(element);
    var newIndex = index + position;
    if (newIndex < 0 || newIndex == this.menu[section].submenus.length) return; //Already at the top or bottom.   
    this.menu[section].submenus.splice(index, 1);
    this.menu[section].submenus.splice(newIndex, 0, element);
    this.menu[section].submenus.forEach(element => {
      element.index = this.menu[section].submenus.indexOf(element);
    });
  };
  // =================== Member Move the menu and SubMenu ==================================
  moveMemberSection(element, position: number) {
    var index = this.menus.indexOf(element);
    var newIndex = index + position;
    if (newIndex < 0 || newIndex == this.menus.length) return; //Already at the top or bottom.   
    this.menus.splice(index, 1);
    this.menus.splice(newIndex, 0, element);
    this.menus.forEach(element => {
      element.index = this.menus.indexOf(element);
    });
  };

  moveMemberSubmenu(sectionIndex: number, submenu: any, position: number) {
    const parentMenu = this.menus[sectionIndex];
    const index = parentMenu.submenus.indexOf(submenu);
    const newIndex = index + position;
    if (newIndex < 0 || newIndex >= parentMenu.submenus.length) return;
    parentMenu.submenus.splice(index, 1);
    parentMenu.submenus.splice(newIndex, 0, submenu);
    parentMenu.submenus.forEach((sub, subIndex) => {
      sub.index = subIndex;
    });
  }
  // =================== Public Menu ==================================
  moveLandingSection(element, position: number) {
    var index = this.pageSections.sections.indexOf(element);
    var newIndex = index + position;
    if (newIndex < 0 || newIndex == this.pageSections.sections.length) return; //Already at the top or bottom.
    var indexes = [index, newIndex].sort(function (a, b) {
      return a - b;
    });
    var section1 = Object.assign({}, this.pageSections.sections[indexes[1]]);
    var section2 = Object.assign({}, this.pageSections.sections[indexes[0]]);
    this.pageSections.sections.splice(indexes[0], 2, section1, section2); //Replace from lowest index, two elements, reverting the order
    this.pageSections.sections.forEach((element, i) => {
      element.index = i;
    });
  };
  // =================== Edit Public Menu ==================================
  // =================== edit Slider ========================
  editSliderSection(section: any): void {
    if (section.name !== "Slider") {
      return; // Exit if the section is not the Slider
    }

    this.sliderRef = section;
    this.slideRef = 0;

    if (this.isNewSetting) {
      this.emptySlideOption = true;
    } else {
      if (this.sliderRef.options.length > 0) {
        this.slideRef = 0;
        this.sliderRef.options.forEach((element) => {
          element.title =
            element.title?.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
              letter.toUpperCase()
            ) || "";
          element.text =
            element.text?.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
              letter.toUpperCase()
            ) || "";
        });
      } else {
      }
    }

    this.sectionsSetting = false;
    this.sliderSettings = true;

    setTimeout(() => {
      M.updateTextFields();
      $(".slider").slider({
        interval: this.sliderRef.interval,
        transition: 800,
        indicators: false,
      });
      $(".collapsible").collapsible();
      $("#sliderModal").modal({
        onCloseStart: () => {
          $(".slider").slider({
            interval: this.sliderRef.interval,
            transition: 800,
            indicators: false,
          });
        },
      });
      $('#public-menu-modal').modal();
      $(".carousel").carousel({
        noWrap: true,
      });
    }, 25);
  }
  // =================== edit Login ========================
  editLoginSection(section: any): void {
    if (section.name === "Login") {
      this.loginRef = section;
      this.sectionsSetting = false;
      this.loginSettings = true;
      this.bgColor = this.loginRef.bgColor;
      this.textColor = this.loginRef.displayNameColor;

      if (!this.loginRef.button) {
        let button = {
          title: "Login/Signup",
          titleColor: "#000000",
          bgColor: "#ffffff",
          borderColor: "#ffffff",
        };
        this.loginRef.button = button;
      }
      if (this.loginRef.logoUrl) this.bgImageYes();

      setTimeout(() => {
        M.updateTextFields();
        $(".tooltipped").tooltip();
        $(".collapsible").collapsible();
        $('#public-menu-modal').modal();
      }, 25);
    }
  }
  // =================== edit ISET ========================
  editISETRegSection(section: any): void {
    if (section.name === "ISETReg" && this.hasISET) {
      this.isetRegRef = section;
      this.sectionsSetting = false;
      this.isetRegSetting = true;
      if (!this.isetRegRef.button) {
        let button = {
          title: "Login/Signup",
          titleColor: "#000000",
          bgColor: "#ffffff",
          borderColor: "#ffffff",
        };
        this.isetRegRef.button = button;
      }
      if (this.isetRegRef.logoUrl) this.bgImageYes();
      setTimeout(() => {
        M.updateTextFields();
        $(".collapsible").collapsible();
        $('#public-menu-modal').modal();
      }, 25);
    }
  }
  // =================== edit Department ========================
  editDepartmentsSection(section: any): void {
    if (section.name === "Departments") {
      this.departmentService
        .getLimitedContentList(parseInt(section.displayLimit))
        .valueChanges()
        .subscribe((n) => {
          if (n) {
            this.departmentsArray = n;
            this.departmentsRef = section;
            this.bgColor = this.departmentsRef.bgColor;
            this.textColor = this.departmentsRef.displayNameColor;
            this.sectionsSetting = false;
            this.departmentsSettings = true;

            setTimeout(() => {
              M.updateTextFields();
              $(".tooltipped").tooltip();
              $(".collapsible").collapsible();
              $('#public-menu-modal').modal();
            }, 25);
          }
        });
    }
  }
  // =================== edit Governance ========================
  editGovernanceSection(section: any): void {
    if (section.name === "Governance") {
      this.governanceService
        .getGovernanceList()
        .valueChanges()
        .subscribe((n) => {
          if (n) {
            this.governanceArray = n[0];
            this.governanceRef = section;
            this.bgColor = this.governanceRef.bgColor;
            this.textColor = this.governanceRef.displayNameColor;
            this.governanceArray.councillors =
              this.governanceArray.councillors.splice(
                0,
                parseInt(this.governanceRef.councillorsLimit)
              );

            this.sectionsSetting = false;
            this.governanceSettings = true;
            if (this.governanceRef.logoUrl) this.bgImageYes();

            setTimeout(() => {
              M.updateTextFields();
              $(".collapsible").collapsible();
              $('#public-menu-modal').modal();
            }, 25);
          }
        });
    }
  }
  // =================== edit News ========================
  editNewsSection(section: any): void {
    if (section.name === "News") {
      this.newsService
        .getSpecificContentList("Public", true)
        .valueChanges()
        .subscribe((n) => {
          if (n) {
            this.publishedPublicNews = n;
            this.publishedPublicNews.sort(
              (a, b) => b.datePublished - a.datePublished
            );
            if (n.length >= section.displayLimit)
              this.newsArray = this.publishedPublicNews.slice(
                0,
                section.displayLimit
              );
            else this.newsArray = this.publishedPublicNews;

            this.newsRef = section;
            if (this.newsRef.logoUrl) this.bgImageYes();
            this.sectionsSetting = false;
            this.newsSettings = true;
            this.bgColor = this.newsRef.bgColor;
            this.textColor = this.newsRef.displayNameColor;

            if (this.newsRef.logoUrl) this.bgImageYes();

            setTimeout(() => {
              M.updateTextFields();
              $(".tooltipped").tooltip();
              $(".collapsible").collapsible();
              $('#public-menu-modal').modal();
            }, 25);
          }
        });
    }
  }
  // =================== edit Events ========================
  editEventsSection(section: any): void {
    if (section.name === "Events") {
      this.eventRef = section;
      this.bgColor = this.eventRef.bgColor;
      this.textColor = this.eventRef.displayNameColor;
      if (this.eventRef.logoUrl) this.bgImageYes();
      this.eventsService
        .getPublicEventsList()
        .valueChanges()
        .subscribe((n) => {
          if (n) {
            this.eventsModel = n;
            this.sectionsSetting = false;
            this.eventsSettings = true;

            setTimeout(() => {
              M.updateTextFields();
              $(".tooltipped").tooltip();
              $(".collapsible").collapsible();
              $('#public-menu-modal').modal();
            }, 25);
          }
        });
    }
  }
  // =================== edit Slider ========================
  editAppStoreSection(section: any): void {
    if (section.name === "AppStore") {
      this.appStoreRef = section;
      this.sectionsSetting = false;
      this.appStoreSettings = true;
      this.bgColor = this.appStoreRef.bgColor;
      this.textColor = this.appStoreRef.displayNameColor;
      if (this.appStoreRef.logoUrl) this.bgImageYes();

      setTimeout(() => {
        M.updateTextFields();
        $(".tooltipped").tooltip();
        $(".collapsible").collapsible();
        $('#public-menu-modal').modal();
      }, 25);
    }
  }
  // =================== edit Demo ========================
  editDemoSection(section: any): void {
    this.demoAccountRef = section;
    this.bgColor = this.demoAccountRef.bgColor;
    this.textColor = this.demoAccountRef.displayNameColor;
    this.sectionsSetting = false;
    this.demoAccountSetting = true;
    if (this.demoAccountRef.logoUrl) this.bgImageYes();
    setTimeout(() => {
      M.updateTextFields();
      $('.tooltipped').tooltip();
      $('#public-menu-modal').modal();
    }, 25);
  }
  // =================== edit Social Media ========================
  editSocialMediaSection(section: any): void {
    if (section.name === "SocialMedia") {
      this.socialService
        .getSocialMedia()
        .valueChanges()
        .subscribe((sm) => {
          if (sm) {
            if (sm.length > 0) {
              this.socialMediaModel = sm[0];
              this.socialMediaRef = section;
              this.bgColor = this.socialMediaRef.bgColor;
              this.textColor = this.socialMediaRef.displayNameColor;
              this.sectionsSetting = false;
              this.socialMediaSettings = true;
              if (this.socialMediaRef.logoUrl) this.bgImageYes();

              setTimeout(() => {
                M.updateTextFields();
                $(".tooltipped").tooltip();
                $(".collapsible").collapsible();
                $('#public-menu-modal').modal();
              }, 25);
            }
          }
        });
    }
  }
  // =================== edit ContactUs ========================
  editContactUsSection(section: any): void {
    this.contactUsRef = section;
    this.bgColor = this.contactUsRef.bgColor;
    this.textColor = this.contactUsRef.displayNameColor;
    this.sectionsSetting = false;
    this.contactUsSetting = true;
    if (this.contactUsRef.logoUrl) this.bgImageYes();
    setTimeout(() => {
      M.updateTextFields();
      $('.tooltipped').tooltip();
      $('#public-menu-modal').modal();
    }, 25);
  }
  // =================== edit Aboutus ========================
  editAboutUsSection(section: any): void {
    if (section.name == "AboutUs") {
      this.aboutUsRef = section;
      this.bgColor = this.aboutUsRef.bgColor;
      this.textColor = this.aboutUsRef.displayNameColor;
      this.sectionsSetting = false;
      this.aboutUsSetting = true;
      if (this.aboutUsRef.logoUrl) this.bgImageYes();

      setTimeout(() => {
        M.updateTextFields();
        $(".tooltipped").tooltip();
        $(".collapsible").collapsible();
        $('#public-menu-modal').modal();
      }, 25);
    }
  }
  // =================== edit Footer ========================
  editFooterSection(section: any): void {
    if (section.name === "Footer") {
      this.footerRef = section;
      this.sectionsSetting = false;
      this.footerSettings = true;
      setTimeout(() => {
        M.updateTextFields();
        $("styleModal").modal();
        $(".collapsible").collapsible();
        $('#public-menu-modal').modal();
      }, 25);
    }
  }
  // ======= ########### common menu ################# ======
  visibilityChanged(section: any) {
    section.isEnabled = !section.isEnabled;
  }
  bgImageYes() {
    this.bgImage = true;
  }
  bgImageNo() {
    this.bgImage = false;
  }
  reset() {
    this.logoUrl = '';
    this.bgImage = false;
  }
  sectionViewChanged() {
    this.pageSections.cardView = !this.pageSections.cardView;
    this.cardView = this.pageSections.cardView;
  }
  filterMenus(): void {
    console.log('Search Query:', this.searchQuery); // Check what is being typed

    if (!this.searchQuery) {
      this.filteredMenus = this.menu;
    } else {
      this.filteredMenus = this.menu.filter(item =>
        item.displayName.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    }
    console.log('Filtered Menus:', this.filteredMenus); // Check the filtered result
  }
  handlelogoInput(files: FileList, from) {
    if (files) {
      this.mediaService.UploadBinImage(files[0]).subscribe(upload => {
        if (upload) {
          this.logoUrl = upload.imgLink;
          if (from == 'Login') this.loginRef.logoUrl = this.logoUrl;
          else if (from == 'News') this.newsRef.logoUrl = this.logoUrl;
          else if (from == 'Events') this.eventRef.logoUrl = this.logoUrl;
          else if (from == 'Departments') this.departmentsRef.logoUrl = this.logoUrl;
          else if (from == 'Governance') this.governanceRef.logoUrl = this.logoUrl;
          else if (from == 'AppStore') this.appStoreRef.logoUrl = this.logoUrl;
          else if (from == 'Demo') this.demoAccountRef.logoUrl = this.logoUrl;
          else if (from == 'ContactUs') this.contactUsRef.logoUrl = this.logoUrl;
          else if (from == 'SocialMedia') this.socialMediaRef.logoUrl = this.logoUrl;
          else if (from == 'AboutUs') this.aboutUsRef.logoUrl = this.logoUrl;
          else if (from == 'ISETReg') this.isetRegRef.logoUrl = this.logoUrl;
        }
      });
    }
  }
  deleteImage(from) {
    if (from == 'Login') this.loginRef.logoUrl = '';
    else if (from == 'News') this.newsRef.logoUrl = '';
    else if (from == 'Events') this.eventRef.logoUrl = '';
    else if (from == 'Departments') this.departmentsRef.logoUrl = '';
    else if (from == 'Governance') this.governanceRef.logoUrl = '';
    else if (from == 'AppStore') this.appStoreRef.logoUrl = '';
    else if (from == 'ContactUs') this.contactUsRef.logoUrl = '';
    else if (from == 'Demo') this.demoAccountRef.logoUrl = '';
    else if (from == 'SocialMedia') this.socialMediaRef.logoUrl = '';
    else if (from == 'AboutUs') this.aboutUsRef.logoUrl = '';
    else if (from == 'ISETReg') this.isetRegRef.logoUrl = this.logoUrl;
    this.logoUrl = '';
  }
  filterIcons(): void {
    this.filteredIcons = this.allIcons.filter((icon) =>
      icon.toLowerCase().includes(this.searchQuery.toLowerCase())
    );
  }
  openIconPickerModal(section: any): void {
    this.currentEditingSection = section;
    // $("#iconPickerModal").modal("open");
    setTimeout(() => {
    M.updateTextFields();
    $('.modal').modal();
    $("#iconPickerModal").modal("open");
  }, 25);
  }
  saveSelectedIcon(selectedIcon: string): void {
    if (selectedIcon && this.currentEditingSection) {
      const editedSection = cloneDeep(this.currentEditingSection);

      // editedSection.icon = selectedIcon;

      if (editedSection.name === "Login") {
        // this.loginRef = editedSection;
        this.loginRef.icon = selectedIcon;
      } else if (editedSection.name === "ISETReg") {
        // this.isetRegRef = editedSection;
        this.isetRegRef.icon = selectedIcon;
      } else if (editedSection.name === "Departments") {
        // this.departmentsRef = editedSection;
        this.departmentsRef.icon = selectedIcon;
      } else if (editedSection.name === "Governance") {
        // this.governanceRef = editedSection;
        this.governanceRef.icon = selectedIcon;
      } else if (editedSection.name === "News") {
        // this.newsRef = editedSection;
        this.newsRef.icon = selectedIcon;
      } else if (editedSection.name === "Events") {
        // this.eventRef = editedSection;
        this.eventRef.icon = selectedIcon;
      } else if (editedSection.name === "AppStore") {
        // this.appStoreRef = editedSection;
        this.appStoreRef.icon = selectedIcon;
      } else if (editedSection.name === "SocialMedia") {
        // this.socialMediaRef = editedSection;
        this.socialMediaRef.icon = selectedIcon;
      } else if (editedSection.name === "AboutUs") {
        // this.aboutUsRef = editedSection;
        this.aboutUsRef.icon = selectedIcon;
      }
      else if (editedSection.name === "ContactUs") {
        // this.aboutUsRef = editedSection;
        this.contactUsRef.icon = selectedIcon;
      }

      this.cdr.detectChanges();
      console.log(
        `Icon updated for ${editedSection.name} section:`,
        selectedIcon
      );
      this.closeModal();
    }
  }
  
  selectIcon(newIcon: string): void {
    this.selectedIcon = newIcon;
  }

  // ############################ Slider ######################

  // ========== intervaalll ===========
  intervalChange(interval) {
    this.sliderRef.interval = interval * 1000;
  }
  get intervalValue(): number {
    return this.sliderRef.interval / 1000;
  }
  // ==========######### slider ####===========
  sliderClicked(index) {
    this.editSlider = true;
    this.slideRef = index
    setTimeout(() => {
      M.updateTextFields();
      $('.modal').modal();
      $('#sliderModal').modal('open');
      $('.slider').slider('pause');
    }, 25);
  }
  addSlider() {
    this.editSlider = false;
    if (this.sliderRef) {
      let slide = {
        index: this.sliderRef.options.length,
        title: '',
        titleColor: '#FFFFFF',
        text: '',
        textColor: '#FFFFFF',
        imgUrl: '',
        type: '',
        link: ''
      }
      this.sliderRef.options.push(slide);
      let index = this.sliderRef.options.length - 1;
      this.slideRef = index
      setTimeout(() => {
        M.updateTextFields();
        // $('.modal').modal();
        $('#sliderModal').modal('open');
        $('.slider').slider('pause');
      }, 15);
    }
  }

  editCard(){
    // const modal = M.Modal.getInstance(this.cardEditModal.nativeElement);
    // modal.open();
    // $('.modal').modal();
    
    setTimeout(() => {
      M.updateTextFields();
      $("#card-edit").modal("open");
      $('.collapsible').collapsible();
    }, 25);
  }
  
  closeEditCard(): void {
    // const modal = M.Modal.getInstance(this.cardEditModal.nativeElement);
    // modal.close();
    setTimeout(() => {
      M.updateTextFields();
    $('#card-edit').modal('close');
  }, 25);
  }
  openImageCropperModal() {
    const modalElement: any = this.imageCropperModal.nativeElement;
    if (modalElement) {
      let instance = M.Modal.getInstance(modalElement);
      if (!instance) {
        instance = M.Modal.init(modalElement);
      }
      instance.open();
    }
  }

  handleFileInput(files: FileList, slider: any) {
    if (files) {
      this.croppedImage = null;
      this.imageChangedEvent = files.item(0);

      // Set the slider type based on the file type
      if (this.imageChangedEvent.type === 'image/jpeg' || this.imageChangedEvent.type === 'image/png' || this.imageChangedEvent.type === 'image/jpg' || this.imageChangedEvent.type === 'image/svg' || this.imageChangedEvent.type === 'image/webp') {
        slider.type = 'Image';
      } else if (this.imageChangedEvent.type === 'video/mp4') {
        slider.type = 'Video';
      }
      this.openImageCropperModal();
    }
  }
  sliderTitleStyleChanged(event, slideRef) {
    this.sliderRef.options[slideRef].title = event;
  }
  saveSliderChanges(element) {
    if (this.isPositionChanged) {
      var newIndex = this.selectedNewPosition //parseInt((document.getElementById("position") as HTMLInputElement).value);
      var oldIndex = element.index
      if (newIndex == oldIndex) return; //Already at the top or bottom.
      if (newIndex >= this.sliderRef.options.length) {
        newIndex = this.sliderRef.options.length - 1
      }
      let el = this.sliderRef.options.splice(oldIndex, 1)[0]
      this.sliderRef.options.splice(newIndex, 0, el);
      this.sliderRef.options.forEach(ele => {
        ele.index = this.sliderRef.options.indexOf(ele);
      });
    }

    let sliderIndex = this.pageSections.sections.findIndex(sec => sec.name == 'Slider');
    this.pageSections.sections[sliderIndex] = this.sliderRef;
    this.saveChanges();
    $('#sliderModal').modal('close');
    setTimeout(() => {
      M.updateTextFields();
      $('.modal').modal('close');
      $('.slider').slider({
        interval: this.sliderRef.interval,
        transition: 800,
        indicators: false,
      });
    }, 25);
  }
  cancelDelete() {
    this.selectedSlider = null;
    $('#confirmationModal').modal('close');
  }
  deleteSlider(slider) {
    this.selectedSlider = slider;
    $('#sliderModal').modal('close');
    setTimeout(() => {
      // $('.modal').modal();
      $('#confirmationModal').modal('open');
    }, 25);
  }
  removeSlider() {
    if (this.sliderRef.options.length > 0) {
      const index = this.sliderRef.options.indexOf(this.selectedSlider);
      if (index !== -1) {
        this.sliderRef.options.splice(index, 1);
        this.sliderRef.options.forEach(element => {
          element.index = this.sliderRef.options.indexOf(element);
        });
        this.selectedSlider = null;
        M.toast({ html: 'Slider Successfully Deleted', classes: 'red' });
        setTimeout(() => {
          M.updateTextFields();
          $('.modal').modal('close');
          $('.slider').slider({
            interval: this.sliderRef.interval,
            transition: 800,
            indicators: false,
            onCloseStart: () => {
              $('.slider').slider({
                interval: this.sliderRef.interval,
                transition: 800,
                indicators: false,
              });
            }
          });
        }, 25);
      }
      else {
        this.emptySlideOption = true;
        this.selectedSlider = null;
        setTimeout(() => {
          M.updateTextFields();
          $('.modal').modal('close');
          $('.slider').slider('destroy');
        }, 25);
      }
    }
    let sliderIndex = this.pageSections.sections.findIndex(sec => sec.name == 'Slider');
    this.pageSections.sections[sliderIndex] = this.sliderRef;
    this.saveChanges();
  }
  // ==========######### Image Cropper ####===========
  closeCroppingModal() {
    this.closeCropModal.emit();
  }
  imageLoaded() {
    this.showCropper = true;
  }
  cropperReady(sourceImageDimensions: Dimensions) {
  }
  loadImageFailed() {
  }
  rotateLeft() {
    this.canvasRotation--;
    this.flipAfterRotate();
  }
  rotateRight() {
    this.canvasRotation++;
    this.flipAfterRotate();
  }
  flipAfterRotate() {
    const flippedH = this.transform.flipH;
    const flippedV = this.transform.flipV;
    this.transform = {
      ...this.transform,
      flipH: flippedV,
      flipV: flippedH
    };
  }
  flipHorizontal() {
    this.transform = {
      ...this.transform,
      flipH: !this.transform.flipH
    };
  }
  flipVertical() {
    this.transform = {
      ...this.transform,
      flipV: !this.transform.flipV
    };
  }
  resetImage() {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
  }
  zoomOut() {
    if (this.scale > 0.11) {
      this.zoomScale -= 10;
      this.scale -= .1;
      this.transform = {
        ...this.transform,
        scale: this.scale
      };
    }
  }
  zoomIn() {
    this.zoomScale += 10;
    if (this.scale < 3.1) {
      this.scale += .1;
      this.transform = {
        ...this.transform,
        scale: this.scale
      };
    }
  }
  zoomFromRange() {
    this.scale = this.zoomScale * 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale
    };
  }
  toggleContainWithinAspectRatio() {
    this.containWithinAspectRatio = !this.containWithinAspectRatio;
  }
  imageCropped(event: ImageCroppedEvent) {
    if (event) {
      // Create a FileReader to read the blob data as base64
      const reader = new FileReader();
      reader.onload = () => {
        this.croppedImage = reader.result as string;
        if (this.croppedImage) {
          let base64result = this.croppedImage.split(',')[1];
          const img = new ImageModel();
          img.imgBase64 = base64result;
          this.mediaList.push({ type: 'Image', file: img });
        }
        this.cdRef.detectChanges();
      };
      reader.readAsDataURL(event.blob);
    } else {
      console.error('Cropped image data is undefined.');
    }
  }
  // ======####### footer ##########========

  styleChanged(event) {
    this.footerStyle = event;
    this.footerRef.style = this.footerStyle;
  }
  // ==========apply to all ========
  applyStyle() {
    this.previewSection = this.pageSections;
    this.previewSection.sections.forEach(sec => {
      if (sec.name != 'Footer' && sec.bgColor) sec.bgColor = this.bgColor;
      if (sec.name != 'Footer' && sec.displayNameColor) sec.displayNameColor = this.textColor;
    });

    this.previewBefore = true;
    setTimeout(() => {
      M.updateTextFields();
      $('.modal').modal();
      $('#applyStyleModal').modal('open');
    }, 25);
  }
  applyToAll() {
    if (this.bgColor) {
      this.pageSections.sections.forEach(sec => {
        if (sec.name != 'Footer' && sec.bgColor) sec.bgColor = this.bgColor;
      });
      setTimeout(() => {
        M.updateTextFields();
      $('#applyStyleModa').modal('close');
    }, 25);
    }

    if (this.textColor) {
      this.pageSections.sections.forEach(sec => {
        if (sec.name != 'Footer' && sec.displayNameColor) sec.displayNameColor = this.textColor;
      });
      setTimeout(() => {
        M.updateTextFields();
      $('#applyStyleModa').modal('close');
    }, 25);
    }
  }
  closeStyleModal() {
    setTimeout(() => {
      M.updateTextFields();
    $('#applyStyleModal').modal('close');
  }, 25);
  }
   // ------ ==========Apperance code ========------
   toggleCollapsibleApperance() {
    this.isCollapsed = !this.isCollapsed;
  }
  toggleCollapsibleAccessibility() {
    this.isCollapsedd = !this.isCollapsedd;
  }
  imageOptionClicked(value) {
    this.imageOption = value;
    this.pageSections.imageShape = this.imageOption;
  }
  gridNoChanged(value, screen) {
    if (screen == 'desktop') {
      this.desktopGridSize = value;
      this.pageSections.desktopGridSize = this.desktopGridSize;
    }
    else if (screen == 'mobile') {
      this.mobileGridSize = value;
      this.pageSections.mobileGridSize = this.mobileGridSize;
    }
  }
  cardOptionClicked(value) {
    this.cardOption = value;
    this.pageSections.cardShape = this.cardOption;
  }
  cardIconOptionClicked(value) {
    this.cardIconOption = value;
    this.pageSections.cardIconShape = this.cardIconOption;
  }
  imageCoverOptionClicked(value) {
    this.imageCoverOption = value;
    this.pageSections.imageCover = this.imageCoverOption;
  }
  cardSizeOptionClicked(value) {
    this.cardSizeOption = value;
    this.pageSections.cardSize = this.cardSizeOption;
  }
  titlePositionOptionClicked(value) {
    this.titlePositionOption = value;
    this.pageSections.titlePosition = this.titlePositionOption;
  }
  setBgColor(color) {
    this.bgColor = color;
  }
  setTextColor(color) {
    this.textColor = color;
  }

  // ======= ########### Modal ################# ======
  openModal(index: number) {
    this.activeModalIndex = index;
    const modalElement = document.getElementById(`modal${index}`);
    if (modalElement) {
      let modalInstance = M.Modal.getInstance(modalElement);
      if (!modalInstance) {
        // If the instance is not found, initialize the modal
        modalInstance = M.Modal.init(modalElement);
      }
      modalInstance.open();
    }
  }
  openPublicModal(section: any): void {
    if (section && section.name) {
      this.selectedSection = section;
      switch (section.name) {
        case "Slider":
          console.log('Editing Slider Section');
          this.editSliderSection(section);
          break;
        case "Login":
          console.log('Editing Login Section');
          this.editLoginSection(section);
          break;
        case "ISETReg":
          this.editISETRegSection(section);
          break;
        case "Departments":
          this.editDepartmentsSection(section);
          break;
        case "Governance":
          this.editGovernanceSection(section);
          break;
        case "News":
          this.editNewsSection(section);
          break;
        case "Events":
          this.editEventsSection(section);
          break;
        case "AppStore":
          this.editAppStoreSection(section);
          break;
        case "Demo":
          this.editDemoSection(section);
          break;
        case "SocialMedia":
          this.editSocialMediaSection(section);
          break;
        case "ContactUs":
          this.editContactUsSection(section);
          break;
        case "AboutUs":
          this.editAboutUsSection(section);
          break;
        case "Footer":
          this.editFooterSection(section);
          break;
        default:
          break;
      }

      this.openPModal(this.selectedSection);
    }
  }
  openPModal(selectedSection: any) {
    const modalElement = document.getElementById('modal-Public');
    const modalInstance = M.Modal.init(modalElement);
    modalInstance.open();
  }
  dataURItoBlob(dataURI: string): Blob {
    const byteString = atob(dataURI.split(",")[1]);
    const mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  }
  closeImageCropperModal() {
    console.log("Closing image cropper modal...");
    this.showImageCropperModal = false;
    const modalElement: any = this.imageCropperModal.nativeElement;
    if (modalElement) {
      const modalInstance = M.Modal.getInstance(modalElement);
      modalInstance.close(); // Close the modal

      document.body.style.overflow = "auto";

      // Resume the slider
      $(".slider").slider({
        interval: this.sliderRef.interval,
        transition: 800,
        indicators: false,
      });
    }
  }
  saveCroppedImage() {
    if (this.croppedImage) {
      console.log("Cropped Image Data:", this.croppedImage);

      try {
        // Parse the cropped image data into a URL
        const imgUrl = new URL(this.croppedImage);
        const blob = this.dataURItoBlob(this.croppedImage);
        const fileName = new File([blob], "cropped_image.png");

        // Assuming this.mediaService.UploadBinImage is the function to upload the image
        this.mediaService.UploadBinImage(fileName).subscribe((upload) => {
          if (upload) {
            // Update the imgUrl property with the uploaded image URL
            this.sliderRef.options[this.slideRef].imgUrl = upload.imgLink;

            // Trigger change detection to update the template
            this.cdRef.detectChanges();

            // Optionally, you can close the cropping modal
            this.croppedImage = "";
            this.closeImageCropperModal();
          }
        });
      } catch (error) {
        console.error("Error parsing cropped image data as URL:", error);
      }
    } else {
      console.warn("No cropped image data available.");
    }
  }
  //************************* ChatGpt modal ******************** */
  openChatGptModal(slideRef, value) {
    this.openChatGpt = true
    this.slideIndex = slideRef
    this.changedSlideValue = value
    this.selectedInput = this.sliderRef.options[slideRef]
    this.inputText = this.helperService.getInnerText(this.selectedInput[value]);
    setTimeout(() => {
      M.updateTextFields();
      $('#sliderModal').modal('close');
    }, 25);
  }
  updateInputValue(event) {
    this.sliderRef.options[this.slideIndex][this.changedSlideValue] = event.message;
    this.closeModalChatGptModal();
  }
  closeModalChatGptModal() {
    this.openChatGpt = false
    this.sliderClicked(this.slideIndex)
  }
  // ==========================================   #################  Action #########+===============
  closeModal() {
    // const modalInstance = M.Modal.getInstance(document.getElementById('#modal-Public'));
    // modalInstance.close();
    setTimeout(() => {
      M.updateTextFields();
      $('#modal-Public').modal('close');
    }, 25);

  }
  closeSliderModal() {
    if (this.slideRef == this.sliderRef.options.length - 1) {
      let slide = this.sliderRef.options[this.slideRef];
      if (!slide.imgUrl && !slide.title) {
        this.sliderRef.options.pop();
      }
    }
  }
  closeIModal(): void {
    const modalInstance = M.Modal.getInstance(document.getElementById('#iconPickerModal'));
    modalInstance.close();
  }

  submitForm(value: any) {
    const model =  {
      facebook: value.facebook,
      twitter: value.twitter,
      instagram: value.instagram,
      youtube: value.youtube
    };

    if (!this.hasSocial) {
      this.service.saveSocialMedia(value).then(resp => {
        toast({ html: 'Social Media Links Successfully Saved!', classes: 'green' });
        // this.backClicked();
      });
    }

    if (this.hasSocial) {
      value.id = this.socialID;
      this.service.updateSocialMedia(value).then(resp => {
        toast({ html: 'Social Media Links Successfully Updated!', classes: 'green' });
        // this.backClicked();
      });
    }
  }

  saveChanges() {
    let saved = false;
    if (this.menus && this.isNewSetting) {
      this.menus.forEach(m => {
        this.settingService.saveAdminMenu(m);
        saved = true;
      });
      if (saved) {
        M.toast({ html: 'Changes Saved Successfully.', classes: 'green' });
      }
    }

    if (this.menus && this.isNewSetting) {
      this.menus.forEach(m => {
        this.settingService.saveMemberMenu(m);
        saved = true;
      });
    }

    if (this.menus && !this.isNewSetting) {
      let updated = false;
      this.menus.forEach(m => {
        this.settingService.updateMemberMenu(m);
        updated = true;
      });
      if (updated) {
        M.toast({ html: 'Menus Successfully Updated.', classes: 'green' });
      }
    }

    if (this.menu && !this.isNewSetting) {
      let updated = false;
      this.menu.forEach(m => {
        this.settingService.updateAdminMenu(m);
        updated = true;
      });
      if (updated) {
        M.toast({ html: 'Changes Saved Successfully.', classes: 'green' });
      }
    }
    if (this.pageSections && this.isNewSetting) {
      this.settingService.saveHomePageSettings(this.pageSections).then(x => {
        M.toast({ html: 'Changes Saved Successfully.', classes: 'green' });
      });
    }
    if (this.pageSections && !this.isNewSetting) {
      this.settingService.updateHomePageSettings(this.pageSections).then(x => {
        M.toast({ html: 'Changes updated Successfully.', classes: 'green' });
      });
    }
    this.closeModal();
  }


  saveChangess() {
    if (this.pageSections && this.isNewSetting) {
      this.settingService.saveHomePageSettings(this.pageSections).then(x => {
        M.toast({ html: 'Changes Saved Successfully.', classes: 'green' });
        this.reset();
      });
    }
    if (this.pageSections && !this.isNewSetting) {
      this.settingService.updateHomePageSettings(this.pageSections).then(x => {
        M.toast({ html: 'Changes updated Successfully.', classes: 'green' });
      });
    }
    this.closeEditCard();
  }


  backClicked() {
    this.router.navigate(['/admin/dashboard']);
  }

}
