import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { environment } from 'src/environments/environment';

declare var $: any;

@Component({
  selector: 'app-applications',
  templateUrl: './applications.component.html',
  styleUrls: ['./applications.component.css']
})
export class ApplicationsComponent implements OnInit {

  defaultThemeColor = environment.appTheme.themeColor;
  themeColor: any;
  buttonType: any;
  user: any;
  isSuperAdmin = false;
  adminMenus: any[];
  permissions: any[];

  selectedMenu = {
    name: 'Applications',
    icon: 'phonelink_setup',
  }
  
  constructor(public appSettingsService: AppSettingsService,private authService: AuthService,
    private router: Router) {
      this.themeColor = this.appSettingsService.getAppThemeColor() ? this.appSettingsService.getAppThemeColor(): this.defaultThemeColor;
      this.buttonType =  this.appSettingsService.getButtonType() ? this.appSettingsService.getButtonType(): 'rectangularButton';
      this.authService.getCurrentApplicantIDObservable().subscribe(
        user => {
          if (user) {
            this.user = user;
            if (user.isSuperAdmin) this.isSuperAdmin = true;
            if (user.permissions) this.permissions = user.permissions;
            //this.newsPermission = this.permissions.filter(p =>)
            else if (!this.isSuperAdmin) {
              $('.modal').modal();
              $('#permissionModal').modal('open');
            }
          }
        }
      );
     }

  ngOnInit(): void {
    this.router.navigate(['admin/applications/list']);
    setTimeout(() => {
      $('.dropdown-trigger').dropdown({
        constrainWidth: false,
        isScrollable: true,
      });
      $('.tooltipped').tooltip();
      $('.modal').modal();
    }, 25);
  }

  select(menu, icon) {
    this.selectedMenu.name = menu;
    this.selectedMenu.icon = icon;
  }

  backClicked() {
    // this.location.back();
    this.router.navigate(['/admin/dashboard']);
  }

}
