import { Component, OnInit } from '@angular/core';
import { SupplementalSupportModel } from 'src/app/models/supplementalSupportModel';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { DocumentsService } from 'src/app/services/api/documents.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { SupplementalSupportService } from 'src/app/services/api/SupplementalSupport.service';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-support-detail',
  templateUrl: './support-detail.component.html',
  styleUrls: ['./support-detail.component.css']
})

export class SupportDetailComponent implements OnInit {

  supportID: string;
  userID: 0;
  applicantID = 0;
  model: SupplementalSupportModel;
  supplementalSupportForm: UntypedFormGroup;
  preview: any;
  fileName: string;
  supportType: any = ['Fuel/Heating', 'Utilities/Hydro', 'Water', 'Sewer/Waste Water',
    'Taxes', 'Telephone', 'Insurance', 'Accommodations'];

  constructor(private fb: UntypedFormBuilder, private location: Location, private documentsService: DocumentsService,
    private authService: AuthService, private supplementalSupportService: SupplementalSupportService,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.supportID = params.get('id');
    });

    if (this.supportID !== null) {

      this.supplementalSupportForm = this.fb.group({
        documentType: ['', [Validators.required]],
        amount: ['', [Validators.required]],
      });

      const id = +this.supportID;
      this.supplementalSupportService.getSupplementalSupportByID(id).subscribe(x => {
        this.model = x[0];

        if (this.model) {
          const fsId = +this.model.filestream_ID;
          this.documentsService.getImage(fsId).subscribe(data => {
            if (data) {
              this.createImageFromBlob(data);
            }
            setTimeout(() => {
              this.supplementalSupportForm.patchValue({
                amount: this.model.amount,
                documentType: this.model.documentName
              });
              $('select').formSelect();
            }, 25);
          });
        }
      });
    }

    if (localStorage.getItem('applicantID')) {
      this.applicantID = Number(localStorage.getItem('applicantID'));
    } else {
      this.applicantID = this.authService.getCurrentApplicantID();
    }
  }

  createImageFromBlob(image: Blob) {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      this.preview = reader.result;
    }, false);
    if (image) {
      reader.readAsDataURL(image);
    }
  }

  submitForm(value: any) {
    const model = new SupplementalSupportModel();
    model.aid = this.applicantID;
    model.documentName = value.documentType;
    model.amount = value.amount;
    //  toast({ html: 'Supplemental Support Request Successfully Submitted!', classes: 'green' });
  }

  backClicked() {
    this.location.back();
  }

}
