<div class="row col s12">
  <form [formGroup]="postJobForm" (ngSubmit)="postJob(postJobForm.value)">
    <div class="row card-panel white darken-2" *ngIf="step1">

      <div class="row">
        <div class="input-field col s12">
          <input id="employer" type="text" formControlName='employer' [(ngModel)]='default_employer' required
            class="validate" autocomplete="off" style="text-transform: capitalize;" />
          <label for="employer">Hiring Organization : <strong class="red-text">*</strong></label>
          <div *ngIf="(employer.dirty || employer.touched) && employer.invalid">
            <small *ngIf="employer.errors.required" class="red-text"> The Hiring Organization is required. </small>
          </div>
        </div>

        <div class="input-field col s12">
          <input id="title" type="text" formControlName='title' required class="validate" autocomplete="off"
            style="text-transform: capitalize;" />
          <label for="title">Job Title : <strong class="red-text">*</strong></label>
          <div *ngIf="(title.dirty || title.touched) && title.invalid">
            <small *ngIf="title.errors.required" class="red-text"> The Job Title is required. </small>
          </div>
        </div>

        <div class="input-field col s12">
          <textarea id="description" style="height: 175px;" type="text" formControlName='description' required
            class="materialize-textarea validate" style="text-transform: capitalize;"></textarea>
          <label for="description">Highlights of Qualification : <strong class="red-text">*</strong></label>
          <div *ngIf="(description.dirty || description.touched) && description.invalid">
            <small *ngIf="description.errors.required" class="red-text"> The Highlights of qualification is required.
            </small>
          </div>
        </div>

        <div class="input-field col s12">
          <input id="skills" type="text" formControlName='skills' required class="validate" autocomplete="off"
            style="text-transform: capitalize;" />
          <label for="skills">Skills : <strong class="red-text">*</strong></label>
          <div *ngIf="(skills.dirty || skills.touched) && skills.invalid">
            <small *ngIf="skills.errors.required" class="red-text"> Skills are required. </small>
          </div>
        </div>

        <div class="row">
          <div class="input-field col s12">
            <label>Job Type : <strong class="red-text">*</strong></label>
          </div>

          <div class="input-field col s12">
            <div class="input-field col l5 s4">
              <label class="col s6">
                <input class="with-gap" formControlName="duration" name="duration" type="radio" value="Full Time"
                  (change)="onFullTime($event)" />
                <span>Full Time</span>
              </label>
            </div>

            <div class="input-field col l5 s4">
              <label class="row col s6">
                <input class="with-gap" formControlName="duration" name="duration" type="radio" value="Part Time"
                  (change)="onPartTime($event)" />
                <span>Part Time</span>
              </label>
            </div>
          </div>
        </div>

        <div class="input-field col s12">
          <input id="officer" type="text" formControlName='officer' [(ngModel)]='officer_name' class="validate"
            autocomplete="off" style="text-transform: capitalize;" />
          <label for="officer">Contact Person: </label>
        </div>

        <div class="input-field col s12">
          <input id="email" type="email" formControlName='email' class="validate" autocomplete="off" />
          <label for="email">Email: </label>
          <div *ngIf="(email.dirty || email.touched) && email.invalid">
            <!--   <small *ngIf="email.errors.required" class="red-text"> The Email is required. </small>  -->
            <small *ngIf="email.errors.email" class="red-text"> wrong email format. </small>
          </div>
        </div>

        <div class="input-field col s12">
          <!-- <input id="phone" type="tel" formControlName='phone' class="validate" [textMask]="{mask: phoneNumberMask}"
            autocomplete="off" /> -->
            <input id="phone" type="tel" formControlName='phone' class="validate" 
            autocomplete="off" />
          <label for="home">Phone Number: </label>
        </div>

        <div class="input-field col s12">
          <!--   <i class="material-icons prefix blue-text">calendar_today</i>  -->
          <label for="expiry_date"><strong>Job Start Date:</strong> </label>
          <input id="start_date" formControlName="start_date" type="text" required class="validate datepicker1"
            (change)="StartDateChange($event)">
        </div>

        <div class="input-field col s12">
          <label for="expiry_date"><strong>Date to Expire and to be Archived:</strong> </label>
          <input id="expiry_date" formControlName="expiry_date" type="text" required class="validate datepicker2"
            (change)="ExpiryDateChange($event)">
        </div>

        <div class="row">
          <div class="section"></div>
        </div>
      </div>
    </div>

    <div class="row card-panel white darken-2" *ngIf="step2">
      <div class="row">
        <div class="row col s12"> <strong>Are you sure you want to Delete this Job Offer ?</strong> </div>
        <div class="row col s12">
          <label class="col s4">
            <input class="with-gap" formControlName="deleted" name="deleted" type="radio" value="Yes"
              (change)="onDeleteYes($event)" />
            <span class="black-text"><strong>Yes</strong></span>
          </label>
          <label class="col s4">
            <input class="with-gap" formControlName="deleted" name="deleted" type="radio" value="No"
              (change)="onDeleteNo($event)" />
            <span class="black-text"><strong>No</strong></span>
          </label>
        </div>
      </div>

      <div class="md-form mb-4 pink-textarea active-pink-textarea"> <!--   *ngIf="step3"-->
        <label for="reason"><span class="black-text"><strong>
              <h6>Reason to Delete:</h6>
            </strong></span></label>
        <textarea id="reason" formControlName="reason" class="md-textarea form-control" rows="10"></textarea>
      </div>

      <br />
      <div class="row center-align">
        <button type="button" (click)="DeleteJob()" class="btn waves-effect blue white-text"
          style="margin-bottom: 5px;">
          <i class="material-icons left">cloud_upload</i> OK
        </button>
      </div>
    </div>

    <div class="row">
      <button [disabled]="!postJobForm.valid" *ngIf="actionType === 'Post' && !step2"
        class="col s12 btn waves-effect green  modal-trigger" data-target="modal1" style="margin-bottom: 15px;">
        <i class="material-icons right">cloud_upload</i> Post Job Offer
      </button>

      <button [disabled]="!postJobForm.valid" *ngIf="model.posted && actionType === 'Edit' && !step2"
        class="col s12 btn waves-effect green  modal-trigger" data-target="modal1" style="margin-bottom: 15px;">
        <i class="material-icons right">cloud_upload</i> Update Job Offer
      </button>

      <button type="button" *ngIf="model.posted && actionType === 'Edit' && !step2"
        class="col s12 btn waves-effect orange  modal-trigger" data-target="modal2" style="margin-bottom: 15px;">
        <i class="material-icons right red-text">archive</i> Archive Job Offer
      </button>

      <button type="button" *ngIf="model.archived && actionType === 'Edit' && !step2"
        class="col s12 btn waves-effect blue modal-trigger" data-target="modal3" style="margin-bottom: 15px;">
        <i class="material-icons right white-text">unarchive</i> Unarchive Job Offer
      </button>

      <button [disabled]="!postJobForm.valid" *ngIf="actionType === 'Edit'"
        class="col s12 btn waves-effect red  modal-trigger" data-target="modal4" style="margin-bottom: 15px;">
        <i class="material-icons right">close</i> Delete Job Offer
      </button>

      <a [ngClass]="'col s12 btn waves-effect ' + themeColor" (click)="backClicked()"><i
          class="material-icons left">house</i> Back To Home Page</a>
    </div>

    <div id="modal1" class="modal">

      <div class="modal-content">
        <h5 *ngIf="actionType === 'Post'">Post Job</h5>
        <h5 *ngIf="actionType === 'Edit'">Update Job</h5>
        <p *ngIf="actionType === 'Post'">Are you sure you want to post this new job offer ?</p>
        <p *ngIf="actionType === 'Edit'">Are you sure you want to update this job offer ?</p>
      </div>

      <div class="modal-footer">
        <button type="submit" *ngIf="actionType === 'Post'" class="btn waves-effect green" style="margin-right: 15px;">
          <i class=" material-icons right white-text">cloud_upload</i> Post
        </button>
        <button type="submit" *ngIf="actionType === 'Edit'" class="btn waves-effect green" style="margin-right: 15px;">
          <i class=" material-icons right white-text">cloud_upload</i> Update
        </button>
        <a class="modal-close amber darken-4 white-text btn-flat white-text"> <i
            class="material-icons right">close</i>Cancel</a>
      </div>
    </div>

    <div id="modal2" class="modal">
      <div class="modal-content">
        <h5>Archive Job</h5>
        <p>Are you sure you want to Archive this Job Offer ?</p>
      </div>
      <div class="modal-footer">
        <button type="button" (click)="archive_job()" class="btn waves-effect orange white-text"
          style="margin-right: 15px;">
          <i class="material-icons right white-text">archive</i> Archive
        </button>
        <a class="modal-close amber darken-4 white-text btn-flat white-text"> <i
            class="material-icons right">close</i>Cancel</a>
      </div>
    </div>

    <div id="modal3" class="modal">
      <div class="modal-content">
        <h5>Unarchive Job</h5>
        <p>Are you sure you want to Post this Archived Job Offer ?</p>
      </div>
      <div class="modal-footer">
        <button type="button" (click)="unarchive_job()" class="btn waves-effect blue white-text"
          style="margin-right: 15px;">
          <i class="material-icons right white-text">unarchive</i> Unarchive
        </button>
        <a class="modal-close amber darken-4 white-text btn-flat white-text"> <i
            class="material-icons right">close</i>Cancel</a>
      </div>
    </div>

    <div id="modal4" class="modal">
      <div class="modal-content">
        <h5>Delete Job Offer</h5>
        <p>Are you sure you want to Delete this Job Offer ?</p>
      </div>
      <div class="modal-footer">
        <button type="button" (click)="DeleteJob()" class="btn waves-effect red white-text" style="margin-right: 15px;">
          <i class="material-icons right white-text">close</i> Delete
        </button>
        <a class="modal-close amber darken-4 white-text btn-flat white-text"> <i
            class="material-icons right">close</i>Cancel</a>
      </div>
    </div>
  </form>
</div>