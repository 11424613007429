import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DepartmentService } from 'src/app/services/firebase/department.service';
import { trigger, transition, useAnimation } from '@angular/animations';
import { bounceIn } from 'ng-animate';
import { environment } from 'src/environments/environment';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';

declare var $: any;

@Component({
  selector: 'app-manage-department-members',
  templateUrl: './manage-department-members.component.html',
  styleUrls: ['./manage-department-members.component.css'],
  animations: [
    trigger('swing', [transition('* => *', useAnimation(bounceIn))])
  ],
})
export class ManageDepartmentMembersComponent implements OnInit {

  defaultThemeColor = '#000000';
  themeColor: any;
  buttonType: any;
  
  departmentID: any;
  departmentMembers: any;
  department:any;
  @Input() id: any;

  constructor(private router: Router, 
    public appSettingsService: AppSettingsService,
    private departmentService: DepartmentService) { 
      this.themeColor = this.appSettingsService.getAppThemeColor() ? this.appSettingsService.getAppThemeColor(): this.defaultThemeColor;
      this.buttonType =  this.appSettingsService.getButtonType() ? this.appSettingsService.getButtonType(): 'rectangularButton';
    }

  ngOnInit() {
    if(this.id) this.departmentID = this.id;
    this.departmentService.getDepartmentByID(this.departmentID).valueChanges().subscribe(dep=>{
      if(dep){
        this.department =dep
      }
    })
    this.getDepartmentMembersList();
    setTimeout(() => {
      $('.tabs').tabs();
    }, 25);
  }

  getDepartmentMembersList(){
    this.departmentService.getDepartmentEmployee(this.departmentID).valueChanges().subscribe(x => {
      if (x && x.length > 0 ) {
        this.departmentMembers = x;
        this.departmentMembers.sort((a, b) => (a.index < b.index ? -1 : 1));
      }
    });
  }

  addDepartmentManager(event: Event) {
    event.preventDefault();
    this.router.navigate(['/admin/dashboard-sub-menu/add-member-to-department'], { state: { departmentID: this.departmentID, userType: 'Manager' } });
  }
  addDepartmentEmployee(event: Event) {
    event.preventDefault();
    this.router.navigate(['/admin/dashboard-sub-menu/add-member-to-department'], { state: { departmentID: this.departmentID, userType: 'Employee' } });
  }

  editDepartmentMember(empID: string, event: Event ) {
    event.preventDefault();
    this.router.navigate(['/admin/dashboard-sub-menu/edit-department-employee', empID]);
  }
}
