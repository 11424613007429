import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { HousingApplicationModel } from 'src/app/models/HousingModel';

@Injectable({
  providedIn: 'root'
})

export class HousingService {

  hasHouse(memberId: number) {
    throw new Error('Method not implemented.');
  }

  houseListRef: AngularFirestoreCollection<any>;
  houseRef: AngularFirestoreDocument<any>;

  constructor(private db: AngularFirestore) { }

  saveApplication(model: HousingApplicationModel){
    var application = {
      id: this.db.createId(),
      applicantID: model.applicantID,
      givenName: model.givenName,
      middleName: model.middleName,
      lastName: model.lastName,
      aliasName: model.aliasName,
      maritalStatus: model.maritalStatus,
      dob: model.dob,
      SIN: model.SIN,
      FWFN: model.FWFN,
      registrationNo: model.registrationNo,
  
      telephone: model.telephone,
      alt_telephone: model.telephone2,
      cellphone: model.cellphone,
      alt_cellphone: model.cellphone2,
      workNumber: model.workNumber,
      ext: model.ext,
      address: model.address,
      city: model.city,
      province: model.province,
      postalCode: model.postalCode,
  
      accomodationAddress: model.accomodationAddress,
      homeInfo: model.homeInfo,

      co_applicant_givenName: model.co_applicant_givenName,
      co_applicant_lastName: model.co_applicant_lastName,
      co_applicant_middleName: model.co_applicant_middleName,
      co_applicant_aliasName: model.co_applicant_aliasName,
      co_applicant_title: model.co_applicant_title,
      co_applicant_gender: model.co_applicant_gender,
      co_applicant_maritalStatus: model.co_applicant_maritalStatus,
      co_applicant_dob: model.co_applicant_dob,
      co_applicant_SIN: model.co_applicant_SIN,
      co_applicant_bandNam: model.co_applicant_bandName,
      co_applicant_registrationNo: model.co_applicant_registrationNo,
      
      landLord_givenName: model.landLord_givenName,
      landLord_lastName: model.landLord_lastName,
      landLord_address: model.landLord_address,
      landLord_province: model.landLord_province,
      landLord_city: model.landLord_city,
      landLord_postalCode: model.landLord_postalCode,
      landLord_moveInDate: model.landLord_moveInDate,
      landLord_leaseAgreement: model.landLord_leaseAgreement,
      landLord_expireDate: model.landLord_expireDate,

      otherAccoummodation: model.otherAccoummodation,

      accessibilityNeeds: model.accessibilityNeeds,
      accessibilityExplain: model.accessibilityExplain,
      reside: model.reside,
      resideExplain: model.resideExplain,
      healthSafetyIssues: model.healthSafetyIssues,
      healthSafetyExplain: model.healthSafetyExplain,
  
      occupants: model.occupants,
      incomes: model.incomes,
      files: model.files,
      applicantSignature : model.applicantSignature,
      co_applicantSignature : model.co_applicantSignature,
      applicantDate:  model.applicantDate,
      co_applicantDate:  model.co_applicantDate,
    }

    return this.db.collection('House-Application').doc(application.id).set(application);
  }

  getApplications(): AngularFirestoreCollection<any[]>{
    this.houseListRef = this.db.collection('House-Application');
    return this.houseListRef;
  }

}