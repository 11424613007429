<div class="content-left ">
  <div class="card-yes ">
    <div class=" border-content">
      <h6 *ngIf="nationOfuse !== 'Batc'" class="center"><strong>Department News/Events</strong></h6>
      <h6 *ngIf="nationOfuse === 'Batc'" class="center"><strong>Office News/Envents</strong></h6>
      <div class="section"></div>
      <div class="container">
        <div class="row col s12">

          <!-- <app-firebase-loading *ngIf="!model">
          </app-firebase-loading> -->

          <div *ngIf="!model || model.length == 0" class="center p-t-6 p-b-6">
            <img src="../../assets/img/empty-folder.png" height="150" width="150">
            <h6 class="bold-200"> The department did not update their contact list please try
              again later!!</h6>
          </div>
          <div class="col l2"></div>
          <div class="col s12 l8" *ngIf="model && model.length > 0">

            <div *ngFor="let data of model">
              <app-news-feed *ngIf="data.visibility === 'Public'" [callerForm]="'DepNews_Public'" [newsModel]="data">
              </app-news-feed>
            </div>
          </div>

          <!-- <div class="col s12 l8">
              <a *ngIf="nationOfuse !== 'Batc' " class="col s12 btn " [ngStyle]="{'background-color': themeColor}"
                (click)="backClicked()"><i class="material-icons left">arrow_back_ios</i> Back To Departments List</a>

              <a *ngIf="nationOfuse === 'Batc' " class="col s12 btn" [ngStyle]="{'background-color': themeColor}"
                (click)="backClicked()"><i class="material-icons left">arrow_back_ios</i> Back To Offices List</a>
            </div> -->
          <!-- <a class="col s12 btn" [ngStyle]="{'background-color': themeColor}" (click)="backClicked()"><i
              class="material-icons left">arrow_back_ios</i> Back To Previous Page</a> -->
          <div class="col l2"></div>
        </div>
      </div>
    </div>
  </div>
</div>