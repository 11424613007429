<div class="row col s12" *ngIf="model">
    <div class="col s12 l12 p-b-6">

        <div class="row container5">

            <div class="col l12 s12 p-l-1">
                <h6 class="bold-700" [innerHTML]="model.title"></h6>
            </div>

            <div class="row"></div>

            <!-- comment and like  -->
            <div class="card-comments row p-l-1">
                <div class="col l3 s4" style="cursor: pointer" *ngIf="model.allowComment">
                    <span class="material-icons">
                        calendar_month
                    </span>
                    <span class="date-content sub-title-section1 black-text bold-300">
                        {{ model?.datePublished?.toDate() | date : "MMM dd,yyyy" }}
                    </span>
                </div>

                <div class="col l3 s4" style="cursor: pointer" *ngIf="model.allowComment">
                    <i class="fa fa-comment-o" aria-hidden="true"></i>
                    <span> {{ model.comments.length }}</span>
                </div>

                <div class="col l8 s7"></div>
            </div>


            <div class="col l6 s12"
                *ngIf="model.mediaLink || model.mediaType === 'Photo' ||  model.mediaType === 'Video' || model.mediaType === 'Youtube' || model.mediaType === 'PDF'">

                <div class="row col l12 s12 m12">
                    <div class="col l12 about-img hide-on-med-and-down" [ngStyle]="{'color': themeColor}"
                        *ngIf="model.mediaType === 'Photo' && model.mediaLink">
                        <img class="materialboxed responsive-img " [src]="model.mediaLink">
                    </div>

                    <div class="col l12 about-video hide-on-med-and-down"
                        *ngIf="model.mediaType === 'Video' && model.mediaLink">
                        <video class="responsive-video" controls>
                            <source [src]="model.mediaLink" type="video/mp4">
                        </video>
                    </div>

                    <div class="col l12 about-vid hide-on-med-and-down about-vid "
                        *ngIf="model.mediaType === 'Youtube' && model.mediaLink">
                        <div class="video-container ">
                            <youtube-player [videoId]="videoId"></youtube-player>
                            <!-- <div [innerHTML]="iframehtml"></div> -->
                        </div>
                        <!-- <div class="video-container ">
                            <div [innerHTML]="embedVideo(model.mediaLink)"></div>
                        </div> -->
                    </div>

                    <div class="col l12 about-iframe hide-on-med-and-down"
                        *ngIf="model.mediaType === 'PDF' && model.mediaLink">
                        <iframe title="iframe-box2" [src]="model.mediaLink | safeUrl" height="600px"
                            width="100%"></iframe>
                    </div>
                </div>
            </div>

            <div class="col l6 m12 s12 container4">
                <div class="col l12 hide-on-large-only" *ngIf="model.mediaType === 'Photo'">
                    <img class="materialboxed responsive-img " [src]="model.mediaLink">
                </div>

                <div class="col l12 hide-on-large-only" *ngIf="model.mediaType === 'Video'">
                    <video class="responsive-video" controls>
                        <source [src]="model.mediaLink" type="video/mp4">
                    </video>
                </div>

                <div class="col l12 hide-on-large-only " *ngIf="model.mediaType === 'Youtube'">
                    <div class="video-container ">
                        <div [innerHTML]="iframehtml"></div>
                    </div>
                </div>

                <div class="col l12 hide-on-large-only" *ngIf="model.mediaType === 'PDF' && model.mediaLink">
                    <iframe title="iframe-box2" [src]="model.mediaLink | safeUrl" height="500px" width="100%"></iframe>
                </div>

                <div class="col l12 s11 p-l-1 p-b-1 bolder">
                    <h6 class=" title" [innerHTML]="model.title"></h6>
                </div>

                <div class="section"></div>
                <div class="col l12 ">
                    <span [innerHTML]="model.description"></span>
                </div>

                <div class="section"></div>

                <div class="row col s12 p-m-5">
                    <!-- =========  display comment =========== -->
                    <div class="comments" #commentsSection id="commentsSection" *ngIf="model.allowComment">

                        <div class="fixed-title b-m-5">
                            <h4 class="card">
                                <span class="col s10 title-section3 bold-700">
                                    Comments <span class="bold-200">({{ model.comments?.length }})</span>
                                </span>

                                <span class="material-icons-outlined right col s2 tooltipped blue-text"
                                    data-position="top" data-tooltip="Add Comment" (click)="addCommentClicked()"
                                    style="font-size: 4vh; cursor: pointer;">
                                    rate_review
                                </span>
                            </h4>
                        </div>

                        <div *ngIf="!comments || comments.length === 0">
                            <p class="p-l-1">No comments yet.</p>
                        </div>

                        <div *ngIf="comments.length > 0" class="scrollable-content">
                            <div class="card row" *ngFor="let comment of comments">
                                <div class="col s10 l10">
                                    <div class="content-column">
                                        <div class="user-info">
                                            <span class="description-section2 bold-300">
                                                <i>{{
                                                    comment.timeStamp.seconds * 1000 +
                                                    comment.timeStamp.nanoseconds / 1000000
                                                    | date
                                                    : "MMM dd, yyyy, h:mm a"
                                                    }}</i>
                                            </span>
                                        </div>
                                        <p class="sub-title-section2 bold-400">{{ comment.comment }}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div id="commentModal" class="modal b-n-1">
                        <i class="material-icons right modal-close col s1 red-text">close</i>
                        <div class="modal-content">
                            <div class="col s3">
                                <span class="material-symbols-outlined" style="font-size: 70px;"
                                    [ngStyle]="{'color': themeColor}">
                                    rate_review
                                </span>
                            </div>
                            <div class="col s9">
                                <h4 class="bold-700">Add Comment </h4>
                                <h6>Share Your Thoughts </h6>
                                <br />
                                <div class="row p-l-2 icon-text">
                                    <div class="col s12 fixed-input-footer">
                                        <div class="input-with-button">

                                            <textarea type="text" [(ngModel)]="newCommentText"
                                                placeholder="Write your comment here" class="validate b-n-1 "
                                                id="commentTextArea" class="materialize-textarea"></textarea>

                                            <button class="btn b-n-1 update-button p-m-2" (click)="addComment()">
                                                Add Comment
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="modal-footer row ">
                            <a class="modal-close cancel-button btn">Cancel</a>
                            <a class="modal-close edit-button btn" (click)="addComment()">Add</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>