export class MessagesModel {
    id : '';
    timeStamp : Date;
    message: '';
    fromId : string;
    toId: string;
    conversationId: string;
    edited: false;
    attachment: {
      type: string,
      link: string,
      name: ''
    };
    likes: {
      count: number;
      users: [];
    };
  }

  export class ForwardedMessageModel{

    id : '';
    timeStamp : Date;
    message: '';
    fromId : string;
    forwardedFrom: string; 
    toId: string;
    conversationId: string;
    attachment: {
      type: string,
      link: string,
      name: ''
    };
    likes: {
      count: number;
      users: [];
    };
    edited: false;
    forwardedMessage: {
      id : '';
      timeStamp : Date;
      message: '';
      fromId : string;
      toId: string;
      conversationId: string;
      attachment: {
        type: string,
        link: string,
        name: ''
      };
      likes: {
        count: number;
        users: [];
      };
    }
  }