export class DepartmentModel {
  id = '';
  parent_id = '0';
  name = '';
  shortName = '';
  phone = '';
  fax = '';
  email = '';
  postal_code = '';
  address = '';
  mandate = '';
  logo = '';
  contactName = '';
  contactOfficePhone = '';
  contactEmail = '';
  contactMobile = '';
  contactTitle = '';
  backgroundColor = '';
  deptFacebookAcc = '';
  deptInstagramAcc = '';
  deptTwitterAcc = '';
  deptYoutubeAcc = '';
  deptLinkedinAcc = '';
  applications = [];
}
