<div class="more-option hide-on-med-and-up">

        <div class="row">
                <div *ngIf="logo">
                        <img class="left responsive-img" width="20%" [src]="logo">
                </div>
                <div *ngIf="!logo">
                        <img class="left responsive-img" src="../../assets/img/Nation-logo.png" width="20%">
                </div>
                <a class="btn-flat btn-small right close-btn" (click)="backClicked()">Close</a>
        </div>

        <ul *ngIf="menus">
                <div *ngIf="login && login.isEnabled" class="partition">
                        <h5 [ngStyle]="{'color': themeColor}">
                                Authentication</h5>
                        <li (click)="navigate(login.link)"><i
                                        class="material-symbols-outlined">{{login.icon}}</i>{{login.displayName}}</li>
                </div>

                <div *ngIf="governace && governace.isEnabled" class="partition">
                        <h5 [ngStyle]="{'color': themeColor}">{{governace.displayName}}</h5>
                        <li (click)="chiefClicked();"><i class="material-symbols-outlined">
                                        person_pin_circle
                                </i>Chief</li>
                        <li (click)="councillorsClicked();"><i class="material-symbols-outlined">
                                        person_pin
                                </i>Councillors </li>
                        <li [routerLink]="['/boards-list']"><i class="material-symbols-outlined">
                                        gavel
                                </i>Boards</li>
                </div>

                <div *ngIf="follow && follow.isEnabled && socialMedia" class="partition">
                        <h5 [ngStyle]="{'color': themeColor}">{{follow.displayName}}</h5>
                        <li><a [href]="socialMedia.facebook" target="_blank">
                                        <i class="fa fa-facebook prefix title-section5"></i>Facebook</a>
                        </li>
                        <li><a [href]="socialMedia.instagram" target="_blank">
                                        <i class="fa fa-instagram prefix title-section5 "></i>Instagram</a>
                        </li>
                        <li><a [href]="socialMedia.twitter" target="_blank">
                                        <i class="fa fa-twitter prefix title-section5"></i>Twitter</a>
                        </li>
                        <li><a [href]="socialMedia.youtube" target="_blank">
                                        <i class="fa fa-youtube prefix title-section5"></i>Youtube</a>
                        </li>
                </div>

                <div *ngIf="privacy" class="partition">
                        <h5 [ngStyle]="{'color': themeColor}">Legal</h5>
                        <li [routerLink]="['/terms-conditions']"><i class="material-symbols-outlined">
                                        checklist
                                </i>Terms & Conditions</li>
                        <li [routerLink]="['/privacy-policy']"><i class="material-symbols-outlined">
                                        policy
                                </i>Privacy Policy </li>
                        <li [routerLink]="['/eula']"><i class="material-symbols-outlined">
                                        verified
                                </i>End User Agreement</li>
                </div>

                <div *ngIf="news?.isEnabled || events?.isEnabled || aboutUs?.isEnabled || departments?.isEnabled || contact?.isEnabled || demo?.isEnabled"
                        class="partition">
                        <h5 [ngStyle]="{'color': themeColor}">
                                More options</h5>
                        <li *ngIf="news && news.isEnabled" (click)="navigate(news.link)"><i
                                        class="material-symbols-outlined">{{news.icon}}</i>{{news.displayName}}</li>

                        <li *ngIf="events && events.isEnabled" (click)="navigate(events.link)"><i
                                        class="material-symbols-outlined">{{events.icon}}</i>{{events.displayName}}</li>

                        <li *ngIf="aboutUs && aboutUs.isEnabled" (click)="navigate(aboutUs.link)"><i
                                        class="material-symbols-outlined">{{aboutUs.icon}}</i>{{aboutUs.displayName}}
                        </li>

                        <li *ngIf="departments && departments.isEnabled" (click)="navigate(departments.link)"><i
                                        class="material-symbols-outlined">{{departments.icon}}</i>{{departments.displayName}}
                        </li>

                        <li *ngIf="contact && contact.isEnabled" (click)="navigate(contact.link)"><i
                                        class="material-symbols-outlined">{{contact.icon}}</i>{{contact.displayName}}
                        </li>

                        <li *ngIf="demo && demo.isEnabled" (click)="navigate(demo.link)"><i
                                        class="material-symbols-outlined">{{demo.icon}}</i>{{demo.displayName}}
                        </li>

                        <li (click)="navigate('')"><i class="material-symbols-outlined">home</i>Home</li>
                </div>
        </ul>
</div>