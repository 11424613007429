import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { ContactService } from 'src/app/services/firebase/contact.service';

@Component({
  selector: 'app-my-inbox',
  templateUrl: './my-inbox.component.html',
  styleUrls: ['./my-inbox.component.css']
})
export class MyInboxComponent implements OnInit {

  messageList: any;

  constructor(
    private contactService: ContactService,
    private authService: AuthService,
    ) { }

  ngOnInit() {
    this.contactService.getUserMessageList(this.authService.currentUser.uid).valueChanges().subscribe(
      msgList => {
        if ( msgList.length > 0) {
          this.messageList = msgList;
        }
      }
    );
  }

}
