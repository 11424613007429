import { Component, OnInit } from '@angular/core';
import { Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { Location } from '@angular/common';
import { ActionPlanService } from 'src/app/services/api/isets/actionPlan.service';
import { ActionPlanModel } from 'src/app/models/isets/ActionPlanModel';
import { toast } from 'materialize-css';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-action-plan',
  templateUrl: './action-plan.component.html',
  styleUrls: ['./action-plan.component.css']
})

export class ActionPlanComponent implements OnInit {

  clientID = 0;
  addActionPlanForm: UntypedFormGroup;
  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  model: ActionPlanModel;
  public SINMask = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  client: any;
  hasSIN = false;
  public dateformat = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
  current_ap_id = 0;

  myDpOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'dd/mm/yyyy',
    markCurrentDay: true,
  };

  constructor(private authService: AuthService,
    private fb: UntypedFormBuilder,
    private location: Location,
    private actionPlanService: ActionPlanService) { }

  ngOnInit(): void {

    if (localStorage.getItem('applicantID')) {
      this.clientID = Number(localStorage.getItem('applicantID'));
    } else {
      this.clientID = this.authService.getCurrentApplicantID();
    }

    this.addActionPlanForm = this.fb.group({
      firstname: [{ value: '', disabled: true }],
      lastname: [{ value: '', disabled: true }],
      SIN: ['', [Validators.minLength(9), Validators.maxLength(9), Validators.pattern('^[0-9]*$')]],
      funding_type: ['', [Validators.required]],
      purpose: [''],
      start_date: ['', [Validators.required]],
    });

    this.current_ap_id = 0;
    this.actionPlanService.getIncompleteActionPlans(this.clientID).subscribe(x => {
      if (x && x.length > 0) { this.current_ap_id = x[0].id; }
      setTimeout(() => {
        $('select').formSelect();
        M.updateTextFields();
      }, 25);

      if (this.current_ap_id == 0) {

        this.model = new ActionPlanModel();
        this.model.client_id = this.clientID;
        this.model.ap_id = 0;
        this.actionPlanService.getClient(this.clientID).subscribe(x => {
          if (x) {
            this.client = x[0];
            this.addActionPlanForm.patchValue({ 'firstname': this.client.GivenName });
            this.addActionPlanForm.patchValue({ 'lastname': this.client.LastName });
            this.model.lastname = this.client.LastName;
            this.model.givenname = this.client.GivenName;

            var array = this.client.DOB.split("-");
            var date = `${array[2].substring(0, 2)}/${array[1]}/${array[0]}`;
            this.model.dob = this.changeDates(date).toDateString();

            // if (this.client.middle_initial) {this.model.middle_initial = this.client.middle_initial;}
            if (this.client.middle_name) {
              var mid_name = this.client.middle_name.toString();
              this.model.middle_initial = mid_name.substr(0, 1);
            }

            if (this.client.Sex == 'M') { this.model.gender = 1; }
            else if (this.client.Sex == 'F') { this.model.gender = 2; } else { this.model.gender = -1; }

            if (this.client.SIN) {
              this.model.SIN = this.client.SIN;
              this.addActionPlanForm.patchValue({ 'SIN': this.client.SIN }); this.hasSIN = true;
            }
            if (this.client.MailAddress) { this.model.address = this.client.MailAddress; }
            if (this.client.city) { this.model.city = this.client.city; }

            if (this.client.province == 'NL') { this.model.province = 1; }
            else if (this.client.province == 'NS') { this.model.province = 2; }
            else if (this.client.province == 'NB') { this.model.province = 3; }
            else if (this.client.province == 'PE') { this.model.province = 4; }
            else if (this.client.province == 'QC') { this.model.province = 5; }
            else if (this.client.province == 'ON') { this.model.province = 6; }
            else if (this.client.province == 'MB') { this.model.province = 7; }
            else if (this.client.province == 'SK') { this.model.province = 8; }
            else if (this.client.province == 'AB') { this.model.province = 9; }
            else if (this.client.province == 'NT') { this.model.province = 10; }
            else if (this.client.province == 'BC') { this.model.province = 11; }
            else if (this.client.province == 'YK') { this.model.province = 12; }
            else if (this.client.province == 'NU') { this.model.province = 16; }

            if (this.client.PostalCode) { this.model.postal_code = this.client.PostalCode; }
            if (this.client.Telephone) { this.model.telephone = this.client.Telephone; }

            //if (this.client.MaritalStatus) {this.model.marital_Status = this.client.MaritalStatus;}  //
            if (this.client.MaritalStatus == 'Married' || this.client.MaritalStatus == 'Married or Equivalent') { this.model.marital_Status = 1; }
            else if (this.client.MaritalStatus == 'Single') { this.model.marital_Status = 2; }
            else if (this.client.MaritalStatus == 'Divorced') { this.model.marital_Status = 4; }
            else if (this.client.MaritalStatus == 'Widowed') { this.model.marital_Status = 5; }
            else if (this.client.MaritalStatus == 'Separated') { this.model.marital_Status = 6; }

            if (this.client.disability) { this.model.disability = this.client.disability; }
            //if (this.client.minority) {this.model.minority = this.client.minority;}
            if (this.client.Aboriginal_group) { this.model.aboriginal_group = this.client.Aboriginal_group; }
            if (this.client.lan) { this.model.lan = this.client.lan; }
            //if (this.client.language) {this.model.language = this.client.language;}
            if (this.client.Fax) { this.model.fax = this.client.Fax; }
            if (this.client.Email) { this.model.email = this.client.Email; }
            if (this.client.band_id) { this.model.band_id = this.client.band_id; }
            if (this.client.IndianStatusNo) { this.model.band_id = this.client.IndianStatusNo; }

            // if (this.client.band_name) {this.model.band_name = this.client.band_name;}
            if (this.client.Band) { this.model.band_name = this.client.Band; }

            if (this.client.dependants) { this.model.dependants = this.client.dependants; }
            if (this.client.Education) { this.model.education = this.client.Education; }
            if (this.client.edu_prov) { this.model.edu_province = this.client.edu_prov; }
            if (this.client.SA_Status) { this.model.sa_status = this.client.SA_Status; }
            if (this.client.EI_type) { this.model.ei_type = this.client.EI_type; }
            if (this.client.Emp_barrier) { this.model.emp_barrier = this.client.Emp_barrier; }

            if (this.client.ph_ext) { this.model.ph_ext = this.client.ph_ext; }
            if (this.client.office) { this.model.office = this.client.office; }
          }

          setTimeout(() => {
            $('select').formSelect();
            M.updateTextFields();
          }, 25);
        });
      }
    });
  }

  get SIN() { return this.addActionPlanForm.get('SIN'); }
  get funding_type() { return this.addActionPlanForm.get('funding_type'); }
  get purpose() { return this.addActionPlanForm.get('purpose'); }
  get start_date() { return this.addActionPlanForm.get('start_date'); }

  changeDates(dateValue) {
    if (dateValue) {
      if (dateValue.match(this.dateformat)) {
        let dateArray = dateValue.split('/');
        let year = dateArray[2];
        let month = dateArray[1] - 1;
        let day = dateArray[0];
        const date = new Date(year, month, day);
        return date;
      } else {
        const date = new Date(dateValue);
        return date;
      }
    } else {
      return null
    }
  }


  submitForm(values: any) {
    if (!values.SIN) {
      toast({ html: 'Please Enter SIN !', classes: 'red' });
      return;
    }

    if (!values.funding_type) {
      toast({ html: 'Please Select  !', classes: 'red' });
      return;
    }

    if (this.client.SIN) {
      this.model.SIN = values.SIN;
    } else {

      if (this.validate_sin(values.SIN)) {

      } else if (values.SIN == '') {
        this.model.SIN = "";

      } else {
        toast({ html: 'SIN Number Incorrect !', classes: 'red' });
        return;
      }
    }

    if (values.funding_type == 'Non-Funded') {
      this.model.funding_type = 0;
    } else if (values.funding_type == 'CRF') {
      this.model.funding_type = 1;
    } else if (values.funding_type == 'EI') {
      this.model.funding_type = 2;
    } else if (values.funding_type == 'FNJF') {
      this.model.funding_type = 3;
    } else if (values.funding_type == 'Pending') { this.model.funding_type = 4; }

    this.model.goals = values.purpose;
    this.model.ap_start_date = this.changeDates(values.start_date.singleDate.formatted).toDateString();
    this.model.app_status = "Unfinished";

    if (this.addActionPlanForm.valid) {
      if (!this.hasSIN) {
        this.actionPlanService.getSIN(values.SIN).subscribe(x => {
          if (x) {
            let count = x;
            if (count != 0) {
              toast({ html: 'SIN is used by other person !', classes: 'red' });
              return;
            }
          } else {
            this.model.SIN = values.SIN;
            // this.actionPlanService.saveActionPlan(this.model).subscribe(x => {
            //   if (x) {
            //     toast({ html: 'Application Successfully Created!', classes: 'green' });
            //     this.location.back();
            //   }
            // });

            setTimeout(() => {
              $('.modal').modal();
              $('select').formSelect();
              $('#QuestionModal').modal('open');
              M.updateTextFields();
            }, 25);
          }
        });
      } else {
        // this.actionPlanService.saveActionPlan(this.model).subscribe(x => {
        //   if (x) {
        //     toast({ html: 'Application Successfully Created! ', classes: 'green' });
        //     this.location.back();
        //   }
        // });

        setTimeout(() => {
          $('.modal').modal();
          $('select').formSelect();
          $('#QuestionModal').modal('open');
          M.updateTextFields();
        }, 25);
      }
    }
  }

  ReadytoSubmitYes() {
    $('#QuestionModal').modal('close');
    this.actionPlanService.saveActionPlan(this.model).subscribe(x => {
      if (x) {
        toast({ html: 'Funding Application Successfully Created! ', classes: 'green' });
        this.location.back();
      }
    });
  }

  ReadytoSubmitNo() {
    $('#QuestionModal').modal('close');
  }

  validate_sin(sin_num) {
    let sin: string = sin_num.toString();
    if (sin.length != 9) { return false; }

    var sin_array: number[] = new Array(9);
    for (let i = 0; i < 9; i++) {
      sin_array[i] = Number(sin.substring(i, i + 1));
    }

    let F1: number = sin_array[0];
    let F2: number = sin_array[1] + sin_array[1];
    let F3: number = sin_array[2];
    let F4: number = sin_array[3] + sin_array[3];
    let F5: number = sin_array[4];
    let F6: number = sin_array[5] + sin_array[5];
    let F7: number = sin_array[6];
    let F8: number = sin_array[7] + sin_array[7];
    let F9: number = sin_array[8];

    var no1: number;
    var no2: number;
    no1 = 0
    no2 = F1 + F3 + F5 + F7
    if (F2 > 9) { no1 = no1 + this.fix_number(F2); } else { no1 = no1 + F2; }
    if (F4 > 9) { no1 = no1 + this.fix_number(F4); } else { no1 = no1 + F4; }
    if (F6 > 9) { no1 = no1 + this.fix_number(F6); } else { no1 = no1 + F6; }
    if (F8 > 9) { no1 = no1 + this.fix_number(F8); } else { no1 = no1 + F8; }

    var total: number;
    var mod_result: number;
    total = no1 + no2;
    mod_result = total % 10;

    if (mod_result == 0 && F9 == 0) {
      return true;
    } else {
      var new_total_string: string = total.toString();
      var new_total: number = Number(new_total_string.substring(0, 1));
      new_total = new_total + 1
      new_total = new_total * 10
      if (new_total - total == F9) { return true; } else { return false; }
    }
  }

  fix_number(num: number) {
    if (num > 9) {
      var str: string = num.toString();
      var n1: number = Number(str.substring(0, 1));
      var n2: number = Number(str.substring(1, 2));
      return n1 + n2;
    } else {
      return num;
    }
  }

  onStartDateChange(event: IMyDateModel): void {
    if (this.addActionPlanForm) { this.addActionPlanForm.patchValue({ start_date: event.singleDate.formatted }); }
  }

  backClicked() {
    this.location.back();
  }

}
