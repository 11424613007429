import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { SearchMemberModel } from 'src/app/models/searchMemberModel';
import { BandMemberModel } from 'src/app/models/BandMemberModel';
import { MemberService } from 'src/app/services/api/member.service';
import { Subject } from 'rxjs';
import { DepartmentService } from 'src/app/services/firebase/department.service';
import { toast } from 'materialize-css';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';
import { CommunityService } from 'src/app/services/api/community.service';
import { createMask } from '@ngneat/input-mask';

declare var M: any;
declare var $: any;
@Component({
  selector: 'app-add-member-to-department',
  templateUrl: './add-member-to-department.component.html',
  styleUrls: ['./add-member-to-department.component.css']
})
export class AddMemberToDepartmentComponent implements OnInit {

  postalCode = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
  mobilePattern = /^\s*([\(])[[0-9]{2}\d{1}[\)]\s*[\-]?[\.]?\s*\d{3}\s*[\-]?[\.]?\s*\d{4}$/;
  public postalCodeMask = [/[A-Za-z]/, /[0-9]/, /[A-Za-z]/, ' ', /[0-9]/, /[A-Za-z]/, /[0-9]/];
  // tslint:disable-next-line: max-line-length
 // public phoneNumberMask = ['(', /[0-9]/, /[0-9]/, /[0-9]/, ')', ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  phoneInputMask = createMask<string>( {
    mask: '(999) 999-9999',
    guide: true,
    showMaskTyped: true,
  });

  public extention = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  nationName = environment.firstNation.displayName;
  url = environment.firstNation.website;

  searchHasRecord = true;
  departmentID: any;
  departmentMembers: any;
  showSearch: Boolean = true;
  searchMemberForm: UntypedFormGroup;
  addMemberToDepartmentForm: UntypedFormGroup;
  showSearchTable: Boolean = false;
  showAddMemberForm: Boolean = false;
  processing: Boolean = false;
  memberModel: SearchMemberModel;
  bandMemberModel: BandMemberModel;
  showAddMemberToDepartmentForm: Boolean = false;
  SetMemberToDepartmentBtn: Boolean = false;
  employeesListData: any;
  loadingTxt: string = '';
  postObj: any;
  dtTrigger: Subject<any> = new Subject();
  showChiefForm: Boolean = true;
  selectedMember: any;
  selectedMemberGivenName: any;
  selectedMemberLastName: any;
 
  showMobileNo = 'Public';
  showOfficeNo = 'Public';
  showEmailAdr = 'Public';
  allowContact = 'Public';
  communities: any[];
  nationofUse = environment.firstNation.name;
  isHead = false;
  SelectChoice = ['Yes', 'No'];
  appsInDepartment: any;
  isManager: any;
  userType: any;
  userExists = false;
  managerTitles = ['Head', 'Manager', 'Director', 'Administrator'];

  constructor(
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    private memberService: MemberService,
    private location: Location,
    private departmentService: DepartmentService,
    private communityService: CommunityService) {

    this.searchMemberForm = this.formBuilder.group({
      firstName: ['', [Validators.required, Validators.minLength(1)]],
      lastName: ['', [Validators.required, Validators.minLength(1)]]
    });
  }

  ngOnInit() {
    this.departmentID = window.history.state.departmentID;
    this.userType = window.history.state.userType;

    if (this.userType) {
      if (this.userType === 'Manager') {
        this.isManager = true;
      }
      if (this.userType === 'Employee') {
        this.isManager = false;
      }
    }
    if (this.nationofUse === 'Batc' || this.nationofUse === 'Pbcn') {
      this.communityService.getNationCommunities().subscribe(
        com => {
          if (com.length > 0) {
            this.communities = com;
          }
        }
      );
    } else {
      this.communityService.getCommunity().subscribe(
        com => {
          if (com.length > 0) {
            this.communities = com;
          }
        }
      );
    }

    setTimeout(() => {
      M.updateTextFields();
      $('.modal').modal();
    }, 25);

    this.departmentService.getDepartmentByID(this.departmentID).valueChanges().subscribe((apps: any) => {
      if (apps) {
        this.appsInDepartment = apps.applications;
      }
    });

  }

  headOfficeChange(val: any) {
    if (val === 'Yes') this.isHead = true;
    if (val === 'No') this.isHead = false;
    setTimeout(() => {
      $('select').formSelect();
    }, 25);
  }

  onSearchEmployees() {
    this.processing = false;
    if (this.searchMemberForm.value.firstName !== '' || this.searchMemberForm.value.lastName !== '') {
      this.memberModel = new SearchMemberModel();
      this.memberModel.firstName = this.searchMemberForm.value.firstName;
      this.memberModel.lastName = this.searchMemberForm.value.lastName;

      this.memberService.searchMember(this.memberModel).subscribe(x => {
        if (x) {
          this.employeesListData = x;
          this.showSearchTable = true;
          this.searchHasRecord = true;
          this.dtTrigger.next(null);
        } else {
          this.searchHasRecord = false;
        }
      });
      this.postObj = { ...this.searchMemberForm.value };
    }
  }

  onClearSearchEmployees() {
    this.showSearchTable = false;
    this.searchMemberForm.setValue({
      firstName: '',
      lastName: ''
    });
  }
  
  get jobTitle() { return this.addMemberToDepartmentForm.get('jobTitle'); }
  get workPhone() { return this.addMemberToDepartmentForm.get('workPhone'); }
  get mobilePhone() { return this.addMemberToDepartmentForm.get('mobilePhone'); }
  get description() { return this.addMemberToDepartmentForm.get('description'); }
  get email() { return this.addMemberToDepartmentForm.get('email'); }
  get community() { return this.addMemberToDepartmentForm.get('community'); }
  get headOffice() { return this.addMemberToDepartmentForm.get('headOffice'); }

  setFormDetails(value: any) {
    this.selectedMember = {
      applicantID: value.applicantID,
      GivenName: value.GivenName,
      LastName: value.LastName,
      Email: value.Email,
    };

    this.addMemberToDepartmentForm = this.formBuilder.group({
      jobTitle: ['', [Validators.required]],
      description: [''],
      workPhone: [''],
      ext: [''],
      mobilePhone: [''],
      email: [value.Email, [Validators.required, Validators.email]],
      community: [''],
      headOffice: [''],
      applicationName: [],
      displayName: [this.selectedMember.GivenName + ' ' + this.selectedMember.LastName],
      isManager: [false],
      permissions: this.formBuilder.group({
        allowDeptCreate: [false],
        allowAddEmp: [false]
      })
    });

    this.showAddMemberToDepartmentForm = true;
    this.showSearchTable = false;
    this.showSearch = false;
    this.SetMemberToDepartmentBtn = true;
    setTimeout(() => {
      M.updateTextFields();
      $('select').formSelect();
    }, 25);
  }

  showSetForm() {
    this.showAddMemberToDepartmentForm = true;
    this.showSearchTable = false;
    this.showSearch = false;
  }

  AddEmployee(value: any) {
    const applicationName = [];
    this.departmentService.getDeptEmpByApplicantIdAndDeptId(this.selectedMember.applicantID, this.departmentID)
      .valueChanges().subscribe(result => {
        if (result.length > 0) {
          this.userExists = true;
        } else {
          const model = {
            id: this.departmentService.createID(),
            departmentId: this.departmentID,
            memberId: this.selectedMember.applicantID,
            givenName: this.selectedMember.GivenName,
            lastName: this.selectedMember.LastName,
            email: value.email,
            jobTitle: value.jobTitle,
            workPhone: value.workPhone,
            ext: ((value.ext) ? ' / ' + value.ext : ''),
            mobilePhone: (value.mobilePhone) ? value.mobilePhone : '',
            description: value.description,
            showMobileNo: this.showMobileNo,
            showOfficeNo: this.showOfficeNo,
            showEmailAdr: this.showEmailAdr,
            allowContact: this.allowContact,
            invitationDate: new Date(),
            community: (value.community) ? value.community : '',
            isHeadOffice: value.headOffice,
            displayName: value.displayName,
            applicationName: value.applicationName,
            isManager: this.isManager,
            permissions: value.permissions
          };

          this.departmentService.setMemberToDepartmentEmployee(model).then(x => {
            toast({ html: model.givenName + ' ' + model.lastName + ' Successfully Added To Department.', classes: 'green' });
            this.backToDeartmentEmployees();
          });
        }
      });
    return;
  }

  backToDeartmentEmployees() {
    this.location.back();
  }

  headYesClick() {
    this.isHead = true;
  }
  headNoClick() {
    this.isHead = false;
  }
  privateMobileClick() {
    this.showMobileNo = 'Private';
  }

  publicMobileClick() {
    this.showMobileNo = 'Public';
  }

  membersMobileClick() {
    this.showMobileNo = 'Members';
  }

  privateOfficeClick() {
    this.showOfficeNo = 'Private';
  }

  publicOfficeClick() {
    this.showOfficeNo = 'Public';
  }

  membersOfficeClick() {
    this.showOfficeNo = 'Members';
  }

  privateEmailClick() {
    this.showEmailAdr = 'Private';
  }

  publicEmailClick() {
    this.showEmailAdr = 'Public';
  }

  membersEmailClick() {
    this.showEmailAdr = 'Members';
  }

  privateContactClick() {
    this.allowContact = 'Private';
  }

  publicContactClick() {
    this.allowContact = 'Public';
  }

  membersContactClick() {
    this.allowContact = 'Members';
  }

  navigate(item) {
    const routeMappings = {
      'AboutUs': 'admin/dashboard-sub-menu/about-us',
      'NationInfo': 'admin/dashboard-sub-menu/nation-settings',
      'Departments': 'admin/dashboard-sub-menu/department',
      'Governance': 'admin/dashboard-sub-menu/governance',
      'AreaOfInterest': 'admin/dashboard-sub-menu/areas-of-interest',
    };

    const route = routeMappings[item];
    if (route) {
      this.router.navigate([route]);
    }
  }
}
