<div class="row center-align p-m-2">
  <h6> <strong> My Past Benefits </strong> </h6>
</div>

<div class="row col s12">
  <div class="col l2 "></div>
  <div class="card col s12 m12 l8">
    <div class="input-field ">
      <select #t (change)="newYearSelected(t.value - 1, t.value - 1, t.value)">
        <option value="" disabled selected>Please Select a Fiscal Year</option>
        <div *ngFor="let year of years">
          <option *ngIf="year === currentYear && currentMonth > 2" (click)="newYearSelected(year, year, year +1)"
            [value]="year">{{ (year) }} - {{ year + 1 }}</option>
          <option [value]="year">{{ (year - 1) }} - {{ year }}</option>
        </div>
      </select>
      <label>Year :</label>
    </div>
  </div>
  <div class="col l2 "></div>
</div>

<div class="row col s12">
  <div class="col l2 "></div>
  <div class="row col s12 m12 l8">

    <ul *ngIf="result" class="collection">
      <li *ngFor="let e of result" class="collection-item">
        <div> <u><strong> <a [routerLink]="['/member/monthly-assistance/entitlement-detail',e.id]">
                {{e.BDno}}</a></strong></u> <a class="secondary-content black-text">{{e.Month}}/{{e.Year}}</a></div>
    </ul>
  </div>
  <div class="col l2 "></div>
</div>

<div class="row col s12">
  <div class="col l1 "></div>
  <div class="row col s12 m12 l10">
    <a [ngClass]="'at-btn col s12 btn waves-effect ' + themeColor" (click)="backClicked()"><i
        class="material-icons left">keyboard_backspace</i> Back To Previous Page</a>
  </div>
  <div class="col l1"></div>
</div>