<div class="row center-align p-m-2">
    <h6><strong>My Family Composition</strong></h6>
</div>

<div class="row col s12" *ngIf="familyMembers">
    <div class="col l2 "></div>
    <div class="col s12 m12 l8">
        <ul class="collection">
            <li *ngFor="let member of familyMembers" class="collection-item">
                    <a [routerLink]="['/member/profile/edit-family-member',member.id]">
                        {{member?.firstName}} {{member?.lastName}}
                    </a>
                    <strong class="secondary-content">{{member.relation}}</strong>
            </li>
        </ul>
    </div>
    <div class="col l2"></div>
</div>

<div class="row col s12">
    <div class="col l2 "></div>
    <div class="col s12 m12 l8">
        <a [routerLink]="['/member/profile/new-family-member']" class='col s12 btn waves-effect green'
            style="margin-bottom: 15px;">
            <i class="material-icons left">add_circle</i> Add a New Family Member
        </a>
    </div>
    <div class="col l2"></div>
</div>

<div class="row col s12">
    <div class="col l2 "></div>
    <div class="col s12 m12 l8">
        <a [ngClass]="'col s12 btn waves-effect ' + themeColor" (click)="backClicked()"><i
                class="material-icons left">house</i> Back To Home Page</a>
    </div>
    <div class="col l2"></div>
</div>