
import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { ISETSRequestToJoinModel } from 'src/app/models/isets/ISETSRequestToJoinModel';

@Injectable({
  providedIn: 'root'
})
export class IsetsRequesttojoinService {

  constructor(private db: AngularFirestore) { }

  requestToJoinListRef: AngularFirestoreCollection<any>;
  requestToJoinRef: AngularFirestoreDocument<any>;

  newRegistrationRequest(model: ISETSRequestToJoinModel) {

    const newRequest = {
      id : this.db.createId(),
      firstName : model.firstName,
      lastName : model.lastName,  
      middle_initial : model.middle_initial,
      dob: model.dob,
      gender: model.gender,
      sin: model.sin,
      address: model.address,
      city: model.city,
      province: model.province,
      postcode: model.postcode,
      same_address: model.same_address,
      mailing_address: model.mailing_address,
      mailing_city: model.mailing_city,
      mailing_province: model.mailing_province,
      mailing_postcode: model.mailing_postcode,

      homephone: model.homephone,
      cellphone: model.cellphone,
      messages: model.messages,
      email: model.email,
      have_license: model.have_license,
      classno: model.classno,
      license_expiry_date: model.license_expiry_date,
      license_province: model.license_province,
      citizenship: model.citizenship,
      language: model.language,
      
      status: model.status,
      bandname: model.bandname,
      treatyno: model.treatyno,

      requestDate : new Date(),
      archived: false,
      signature: model.signature,
      sign_date: model.sign_date,
    };

    return this.db.collection('IsetsRequestToJoin').doc(newRequest.id).set(newRequest);
  }




  archiveRequest(model: ISETSRequestToJoinModel) {
    const newRequest = {
      id : model.id,
      firstName : model.firstName,
      middle_initial : model.middle_initial,
      lastName : model.lastName,
      //email : model.email,
      requestDate : model.requestDate,
      archived: true,
    };

    return this.db.collection('IsetsRequestToJoin').doc(newRequest.id).update(newRequest);
  }

  getRegistrationRequestList(): AngularFirestoreCollection<ISETSRequestToJoinModel> {
    this.requestToJoinListRef = this.db.collection('IsetsRequestToJoin');
    return this.requestToJoinListRef;
  }

  getRegistrationRequestByID(id: string): AngularFirestoreDocument<ISETSRequestToJoinModel> {
    this.requestToJoinRef = this.db.doc('IsetsRequestToJoin/' + id);
    return this.requestToJoinRef;
  }


  DeleteRegistrationRequest(model: any) {
    return this.db.collection('IsetsRequestToJoin').doc(model.id).delete();
  }

  
  ISETFixDBTables(): AngularFirestoreCollection<any>{
    this.requestToJoinListRef = this.db.collection('ASETS_Clients');
    return this.requestToJoinListRef
  }

  createUser(model: any){
    return this.db.collection('BandMembers').doc(model.uid).set(model)
  }
}
