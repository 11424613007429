import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { LRRCNSurveyService } from 'src/app/services/firebase/lrrcn-survey.service';
import SignaturePad from 'signature_pad';
import { toast } from 'materialize-css';

declare var $: any;
declare var M: any;

@Component({
    selector: 'app-lrrcn-application-list',
    templateUrl: './lrrcn-application-list.component.html',
    styleUrls: ['./lrrcn-application-list.component.css']
})
export class LrrcnApplicationListComponent implements OnInit {

    themeColor = environment.appTheme.adminThemeColor;

    loading = false;
    applications = []
    count: number;
    showToday
    detail: any;
    showDetails = false
    applicantID: any
    surveyId: any
    adminSignature = false
    applicantCanvas: any;
    applicantSignaturePad: SignaturePad;
    surveyToApprove: any
    loggedUser: any

    constructor(
        private authService: AuthService,
        private location: Location,
        private lrrcnService: LRRCNSurveyService) { }

    ngOnInit() {
        this.loading = true;
        this.loggedUser = this.authService.getGlobalUser()
        if (this.loggedUser) {
            this.lrrcnService.getLRRCNSurveyByAdminId(this.loggedUser.uid).valueChanges().subscribe(x => {
                if (x) {
                    this.applications = x
                    // this.applications = x.sort((a, b) => (a.applicantDate > b.applicantDate) ? 1 : ((b.applicantDate > a.applicantDate) ? -1 : 0));
                    this.count = this.applications.length;
                }
                setTimeout(() => {
                    $('.modal').modal();
                    M.updateTextFields();
                }, 25);
                this.loading = false
            })
        }
    }

    backClicked() {
        this.location.back();
    }

    onShowDetails(index) {
        this.applicantID = this.applications[index].applicantID
        this.surveyId = this.applications[index].id
        this.showDetails = true
    }

    adminSign(index) {
        this.surveyToApprove = this.applications[index]
        this.adminSignature = true;

        if (this.surveyToApprove.workerSignature === '') {
            setTimeout(() => {
                $('.modal').modal('open');
                this.applicantCanvas = document.querySelector('canvas');
                const parentWidth = $(this.applicantCanvas).parent().outerWidth();
                this.applicantCanvas.setAttribute('width', parentWidth - 50);
                this.applicantSignaturePad = new SignaturePad(this.applicantCanvas, {
                    backgroundColor: 'rgb(255,255,255)'
                });
            }, 50);
        } else {
            setTimeout(() => {
                $('.modal').modal('open');
            }, 50);
        }
    }

    clearSignatureOfApplicant() {
        this.applicantSignaturePad.clear();
    }

    verifySurvey() {
        this.surveyToApprove.workerDate = new Date()
        this.surveyToApprove.verified = true
        if (this.surveyToApprove.workerSignature === '') {
            this.surveyToApprove.workerSignature = this.applicantSignaturePad.toDataURL()
        }
        $('.modal').modal('close');
        this.lrrcnService.updateLRRCNSurvey(this.surveyToApprove).then(survey => {
            toast({ html: 'Successfully Verified Survey!', classes: 'green' });

        }).catch(err => {
            toast({ html: 'Error Signing Survey Please Try Again!', classes: 'green' });
        });
    }

    canBeVerified(app) {
        return !app.verified && (app.filledBy === 'Member' || (app.filledBy === 'Admin' && app.applicantSignature !== ''));
    }


    cancel() {
        $('.modal').modal('close');
    }

    backToList(valueEmitted) {
        if (valueEmitted == true) {
            this.applicantID = null

            this.surveyId = null
            this.showDetails = false
            setTimeout(() => {
                $('.modal').modal();
                M.updateTextFields();
            }, 25);
        }
    }

}
