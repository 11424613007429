import { Injectable } from '@angular/core';
import { Platform } from '@angular/cdk/platform';
import { Subject } from 'rxjs';
import { AuthService } from './auth.service';
import { FCMTokenModel } from 'src/app/models/FCMTokenModel';
import { FCMTokenService } from './FCMToken.service';
import * as firebase from 'firebase/app';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiKeyManagerService } from '../api/apiKeyManager.Service';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';

declare var FirebasePlugin: any;

@Injectable({
  providedIn: 'root'
})

export class FCMpushService {

  private messaging;
  isCordova=false
  private messageSource = new Subject();
  currentMessage = this.messageSource.asObservable();
  nation = environment.firstNation.displayName

  private mynationChannel = {
    id: 'MyNation_default_channel',
    name: 'MyNation_default_channel',
    sound: 'blackberry',
    vibration: [300, 200, 300],
    lightColor: '0xFF0000FF',
    importance: 4,
    badge: true,
    visibility: 1
  };

  constructor(
    private platform: Platform,
    private authService: AuthService,
    private FcmTokenService: FCMTokenService,
    private router:Router,
    private keyManager: ApiKeyManagerService,
    private httpClient: HttpClient,
    private location: Location) {
    if (typeof window['cordova'] !== 'undefined') {
      this.isCordova = true;
    }else{
      this.messaging = firebase.messaging();
    }

    if (this.isCordova && platform.ANDROID) {
      FirebasePlugin.createChannel(this.mynationChannel ,
        () => {
        },
        (error) => {
           alert('Create channel error: ' + error);
        });
    }
  }

  getToken() {
    if(this.isCordova){
      if (this.platform.IOS) {
        FirebasePlugin.grantPermission(hasPermission => {
           if (hasPermission) {
            
            FirebasePlugin.onApnsTokenReceived(apnsToken => {
              this.sendTokenToServer(apnsToken,'IOS')
              // alert(apnsToken)
             }, (error) => {
               alert('Enable to get Token : ' + error)
             });
           }
         });
       }
      if(this.platform.ANDROID){
    
      FirebasePlugin.onTokenRefresh(fcmToken => {
        this.sendTokenToServer(fcmToken,'Android')
      }, (error) => {
        alert('Unable to get Token for Push Notifications. ' + error);
      });
      }
    }    
  }

  sendTokenToServer(token:string,platform:string){
      this.FcmTokenService.checkIfTokenExist(token).valueChanges().subscribe(checkedToken=>{
        if(checkedToken){
         
        }else{
              const model: FCMTokenModel = {
              uid: '',
              memberID: this.authService.getCurrentUser().uid,
              token: token,
              platform: platform,
              date: new Date()
            };
            this.FcmTokenService.saveUserToken(model)
        }
        if(this.isCordova){
          // this.subscribeToPushNotification(token,'News_Events') 
          let topic = this.nation + '_News_Events'
          this.subscribeToPushNotification(token,topic) 
        }
      })
  }
  
  subscribeToPushNotification(token,topic){
    
    FirebasePlugin.subscribe(topic,function(){
      
    },function(error){
      alert("Error subscribing to topic: " + error)
    })
  }

  subscribeToPushNotificationAngular(token,topic){
    let url = 'https://iid.googleapis.com/iid/v1/'+token+'/rel/topics/'+topic
    const headers = new HttpHeaders({
      Authorization: this.keyManager.getFCMKey(),
      'Content-Type': 'application/json;'
    });

    this.httpClient.post<any>(url, null, { headers }).subscribe(o => {
    });
  }

  onMessageReceived() {
    if (this.isCordova) {
      try {
        return FirebasePlugin.onMessageReceived(message => {
          FirebasePlugin.hasPermission(hasPermission => {
            if (hasPermission) {
              if (this.platform.IOS) {this.setBadgeNumber(); }
              this.handleNotificationMessage(message);
                    }
          });
        });
      } catch (error) {
        // alert('Failed receiving message ' + error.message);
      }
    }
  }

  setBadgeNumber() {
    if (this.platform.IOS) {
      FirebasePlugin.getBadgeNumber(current => {
        const badgeNum = current + 1;
        FirebasePlugin.setBadgeNumber(badgeNum);
      }, error => {
        console.log('Failed to get badge number', error);
      });
    }
  }

  private handleNotificationMessage(message: any) {
    let title;
    if (message.title) {
      title = message.title;
    } else if (message.notification && message.notification.title) {
      title = message.notification.title;
    } else if (message.aps && message.aps.alert && message.aps.alert.title) {
      title = message.aps.alert.title;
    }

    let body;
    if (message.body) {
      body = message.body;
    } else if (message.notification && message.notification.body) {
      body = message.notification.body;
    } else if (message.aps && message.aps.alert && message.aps.alert.body) {
      body = message.aps.alert.body;
    }
    title =title.toString()
    body = body.toString()

    let msg =  title + ' \n' + body 
    
    let notificationType = title.split(' ')[1]

    if(typeof message.tap !== "undefined"){
      setTimeout(()=>{  
        if(notificationType == 'Events'){
          this.navigate('/member/events-calendar')           
        }else{
          this.navigate('/member/news')
        }  
      },200)
    } 
  }

  private navigate(url:string,){
    this.location.go(url)
    this.router.navigate([url])
    this.location.go('/member/events-calendar')
    this.router.navigate(['/member/events-calendar'])
    this.location.back()
  }
  
}