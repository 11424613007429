import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EventsService } from 'src/app/services/firebase/events.service';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';

@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrls: ['./event-details.component.css']
})
export class EventDetailsComponent implements OnInit {

  id: any;
  model: any[];
  themeColor = environment.appTheme.themeColor;

  constructor(private eventService: EventsService,
     private router: Router, private route: ActivatedRoute, private location: Location) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });

    if (this.id) {
      this.eventService.getEventByID(this.id).valueChanges().subscribe(data => {
        if (data) {
          this.model = data;
        }
      });
    }
  }

  back() {
    this.location.back();

  }
}
