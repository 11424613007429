import { Component, OnInit } from "@angular/core";
import { environment } from "src/environments/environment";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { EmailService } from "src/app/services/api/email.service";
import { AyrshareService } from "src/app/services/social-media/ayrshare.service";
import { AyshareUserService } from "src/app/services/firebase/ayrshare-social-media.service";
import { ProfieKeyService } from "src/app/services/social-media/profileKey.service";
import { AuthService } from "src/app/services/firebase/auth.service";
import { ActivatedRoute, Router } from "@angular/router";
import { AppSettingsService } from "src/app/services/firebase/app-settings.service";
import { UserProfile } from "src/app/models/SocialMediaPostModel";
import { toast } from "materialize-css";

declare var $: any;

@Component({
  selector: "app-social-media-main",
  templateUrl: "./social-media-main.component.html",
  styleUrls: ["./social-media-main.component.css"],
})

export class SocialMediaMainComponent implements OnInit {

  privateKey = environment.socialMedia.privateKey;
  url = environment.socialMedia.profileUrl;
  defaultThemeColor = "#000000";
  themeColor: any;
  buttonType: any;

  user;
  profileKey;
  profileForm: UntypedFormGroup;
  loading = false;

  hasProfile = false;
  userProfile;
  showProfiles = true;
  selectedProfile;

  menuItems = [
    {
      name: "Dashboard",
      icon: "post_add",
      url: "dashboard",
    },
    {
      name: "Post",
      icon: "post_add",
      url: "posts",
    },
    {
      name: "Analytics",
      icon: "analytics",
      url: "analytics",
    },
    {
      name: "Settings",
      icon: "settings",
      url: "settings",
    },
  ];

  constructor(
    private userProfileService: AyshareUserService,
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private profieKeyService: ProfieKeyService,
    private settingsService: AppSettingsService,
    private fb: UntypedFormBuilder,
    private socialMediaService: AyrshareService) {
    this.themeColor = this.settingsService.getAppThemeColor() ? this.settingsService.getAppThemeColor() : this.defaultThemeColor;
    this.buttonType = this.settingsService.getButtonType() ? this.settingsService.getButtonType() : "rectangularButton";
  }

  ngOnInit() {
    this.user = this.authService.getGlobalUser();

    if (this.user) {
      this.loading = true;
      this.userProfileService
        .getOwnerProfiles(this.user.uid)
        .valueChanges()
        .subscribe((p) => {
          console.log(p, "the profile");
          if (p) {
            if (p.length > 0) {
              this.userProfile = p;
              this.profileSelect(this.userProfile[0]);
              this.hasProfile = true;
              this.loading = false;
            }
            this.loading = false;
          }
        });
    }

    this.profileForm = this.fb.group({
      title: ["", [Validators.required]],
      displayTitle: [""],
      hideTitle: [true],
      terms: ["", [Validators.required]],
      employeeId: [""],
    });

    setTimeout(() => {
      $(".modal").modal();
    }, 25);
  }

  get title() {
    return this.profileForm.get("title");
  }
  get terms() {
    return this.profileForm.get("terms");
  }

  profileSelect(profile) {
    this.profileKey = profile.profileKey;
    this.selectedProfile = profile;
    this.showProfiles = false;

    this.profieKeyService.updateProfileKey(this.profileKey);

    if (this.profieKeyService.shouldShare) {
      this.navigate("create-post");
      return true;
    }
    this.navigate("dashboard");
  }

  menuClicked(item) { }

  closeProfileModal() {
    $("#createProfileModal").modal("close");
    this.profileForm.reset();
    // this.addEmployee=false
  }

  openSuccessModal() {
    $("#profileCreatedModal").modal("open");
  }

  createUserProfile(values) {
    let model = new UserProfile();

    Object.keys(values).forEach((key) => {
      model[key] = values[key];
    });

    this.closeProfileModal();
    this.socialMediaService
      .createUserProfile(model)
      .toPromise()
      .then((x) => {
        if (x["status"] == "success") {
          let userProfile = {};
          userProfile["refId"] = x["refId"];
          userProfile["userId"] = this.user.uid;
          userProfile["profileKey"] = x["profileKey"];
          userProfile["title"] = x["title"];

          if (values.employeeId) userProfile["employeeId"] = values.employeeId;

          this.userProfileService
            .createUserProile(userProfile)
            .then((p) => {
              setTimeout(() => {
                this.openSuccessModal();
              }, 2);
              if (values.employeeId) {
                // let employee = this.employees.filter(x=>x.uid ==values.employeeId)
                //   let msg = this.getEmailForEmployee(employee[0].Email)
                //   this.emailService.sendEmail(msg).subscribe(x => {
                //       if (x && x.Status == 'OK') {
                //         let recipient = msg.emailList[0]
                //         console.log(x,'email sent')
                //         this.toast({html: 'Email sent', classes: 'green', type: 'success'});
                //       } else {
                //         console.log(x,'the error emial')
                //       }
                //     })
              }
            })
            .catch((err) => {
              console.log(err);
              toast({ html: "Error creating profile", classes: "red" });
            });
        } else {
          console.log(x, "the error");
        }
      })
      .catch((err) => {
        toast({ html: `${err.error.message}`, classes: "red" });

        console.log(err, "the error ayrshare");
      });
  }

  navigate(url) {
    if (["settings", "analytics", "posts"].includes(url)) {
      $(".modal").modal();
      $("#underConstructionModal").modal("open");

      return;
    }
    this.router.navigate([url], {
      relativeTo: this.activatedRoute,
    });
  }

}
