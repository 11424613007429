import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Attachment, EmailModel } from 'src/app/models/emailModel';
import { EmailService } from 'src/app/services/api/email.service';
import { toast } from 'materialize-css';
import { MediaService } from 'src/app/services/api/media.service';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-send-email',
  templateUrl: './send-email.component.html',
  styleUrls: ['./send-email.component.css']
})
export class SendEmailComponent implements OnInit {

  @Input() emailList: any[];
  @Input() fileList: any[];
  @Output() emailSent: EventEmitter<boolean> = new EventEmitter<boolean>();

  loading = false;
  emailForm: UntypedFormGroup;
  invalidEmail = false;
  binDoc: File;
  binDocList: any = [];

  constructor(private fb: UntypedFormBuilder, 
    private emailService: EmailService, 
    private mediaService: MediaService) { }

  ngOnInit() {
    this.loading = true;
    this.emailForm = this.fb.group({
      subject: ['', [Validators.required]],
      body: [''],
    });

    setTimeout(() => {
      $('.modal').modal();
      $('.modal').modal({
        dismissible: false,
      }),
        $('.chips-placeholder').chips({
          placeholder: 'Recipent',
          secondaryPlaceholder: '+Recipent',
        });
      $('.chips-autocomplete').chips({
        autocompleteOptions: {
          limit: Infinity,
          minLength: 1
        }
      });
      $('.chips').chips({
        onChipAdd: (e, data) => {
          var email = data.innerText.replace('close', '').trim();
          this.emailList.push(email);
          let isValid = this.validateEmail(email);
          if (!isValid) {
            this.invalidEmail = true;
            $('.chips').addClass("red");
          }
          // else this.invalidEmail = false;
        },
        onChipDelete: (e, data) => {
          var email = data.innerText.replace('close', '').trim();
          if (this.emailList.length > 0) {
            var index = this.emailList.indexOf(email);
            this.emailList.splice(index, 1);
          }
          this.invalidEmail = false;
          this.emailList.forEach(email => {
            if (!this.validateEmail(email)) this.invalidEmail = true;
          });
          if (!this.invalidEmail) {
            $('.chips').removeClass("red");
          }
        }
      });
      $('#emailModal').modal('open');
      M.updateTextFields();
      this.loading = false;
      $('#attachFile').click(function () {
        $('#files').click();
      });
    }, 25);
  }

  handleFileInput(files: FileList) {
    for (let i = 0; i < files.length; i++) {
      let file = files.item(i);
      if (this.validateInput(file.name) == 'image') {
        this.loading = true;
        this.mediaService.UploadBinImage(file).subscribe(upload => {
          if (upload) {
            let att = new Attachment();
            att.url = upload.imgLink;
            att.name = file.name;
            att.type = 'Image';
            this.fileList.push(att);
          }
          this.loading = false;
        });
      } else if (this.validateInput(file.name) == 'pdf') {
        this.loading = true;
        this.mediaService.uploadPDF(file).subscribe(upload => {
          if (upload) {
            let att = new Attachment();
            att.url = upload.imgLink;
            att.name = file.name;
            att.type = 'PDF';
            this.fileList.push(att);
          }
          this.loading = false;
        });
      }
    }
  }

  removeFile(index) {
    this.fileList.splice(index, 1);
  }

  validateEmail(email) {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  get subject() { return this.emailForm.get('subject'); }
  get body() { return this.emailForm.get('body'); }

  private validateInput(name: any) {
    const ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() === 'png') {
      return 'image';
    } else if (ext.toLowerCase() === 'jpg') {
      return 'image';
    } else if (ext.toLowerCase() === 'jpeg') {
      return 'image';
    } else if (ext.toLowerCase() === 'mp4' || ext.toLowerCase() === 'mkv') {
      return 'video';
    } else if (ext.toLowerCase() === 'pdf') {
      return 'pdf';
    } else {
      return false;
    }
  }

  Send(email: EmailModel) {
    let msg = new EmailModel();
    msg.emailList = this.emailList;
    msg.subject = email.subject;
    msg.body = email.body;
    if (this.fileList && this.fileList.length > 0) msg.attachments = this.fileList
    $('.modal').modal('close');
    this.emailService.sendEmail(msg).subscribe(x => {
      if (x && x.Status == 'OK') {
        this.emailSent.emit(true);
        toast({ html: 'Email Successfully Sent.', classes: 'green' });
      } else {
        this.emailSent.emit(false);
        toast({ html: 'Failed to send Email. Please Try again.', classes: 'red' });
      }
    });
  }

  cancel() {
    this.emailSent.emit(false);
    $('.modal').modal('close');
  }

}