import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { toast } from 'materialize-css';

import { Children, PseCollege, PseSupportHistory, PseDocument, PSEApplicationModel } from 'src/app/models/PSEApplicationModel';

import { AuthService } from 'src/app/services/firebase/auth.service';
import { MemberService } from 'src/app/services/api/member.service';
import SignaturePad from 'signature_pad';
import { MediaService } from 'src/app/services/api/media.service';
import { PseApplicationService } from 'src/app/services/api/pse-application.service';
import { CommunityService } from 'src/app/services/api/community.service';

import { Location } from '@angular/common';
import { ImageModel } from 'src/app/models/ImageModel';

import { IncomeSupportFamilyMemberModel } from 'src/app/models/IncomeSupportFamilyMemberModel';
import { FindMemberModel } from 'src/app/models/FindMemberModel';

import { PseHighSchoolModel } from 'src/app/models/PseHighSchoolModel';
import { PseCollegeModel } from 'src/app/models/PseCollegeModel';
import { PseEduPlanModel } from 'src/app/models/PseEduPlanModel';
import { PseSupportHistoryModel } from 'src/app/models/PseSupportHistoryModel';
import { PseLoadDocumentModel } from 'src/app/models/PseLoadDocumentModel';
import { NationSettingsService } from 'src/app/services/api/nation-settings.service';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import { EmailService } from 'src/app/services/api/email.service';
import { FireMembersService } from 'src/app/services/firebase/fire-members.service';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { FbNationSettingsService } from 'src/app/services/firebase/fb-nation-settings.service';
import { DataService } from 'src/app/services/data.service';

const pdfMake = require('pdfmake/build/pdfmake.js');
const pdfFonts = require('pdfmake-unicode/dist/pdfmake-unicode.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-pse-app',
  templateUrl: './pse-app.component.html',
  styleUrls: ['./pse-app.component.css']
})
export class PseAppComponent implements OnInit {

  nationofUse = environment.firstNation.name;
  nationName = environment.firstNation.displayName;
  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  provinceOfUse = environment.firstNation.province;

  showUpload2 = false;
  logo: any;

  provinces = [{ name: 'Alberta', code: 'AB' },
  { name: 'British Columbia', code: 'BC' },
  { name: 'Manitoba', code: 'MB' },
  { name: 'New Brunswick', code: 'NB' },
  { name: 'Newfoundland and Labrador', code: 'NL' },
  { name: 'Nova Scotia', code: 'NS' },
  { name: 'Ontario', code: 'ON' },
  { name: 'Prince Edward Island', code: 'PE' },
  { name: 'Quebec', code: 'QC' },
  { name: 'Saskatchewan', code: 'SK' }
  ];

  todayDate = new Date().getFullYear();

  maritalStatusList: any = ['Single', 'Married or Equivalent', 'Divorced', 'Widowed', 'Separated'];
  RelationshipList = ["Spouse", "Dependant"];

  EduLevelList = ["Preschool", "Up to Grade 7-8 (Sec. I = Grade 8)", "Grade 9-10 (Sec. II-III)", "Grade 11-12 (Sec. IV-V)",
    "Secondary School Diploma or GED", "Some post-secondary training", "Apprenticeship or trades certificate or diploma",
    "College", "CEGEP", "University certificate or diploma", "University - Bachelor Degree", "University – Master’s degree", "University – Doctorate", "Course or certificate", "Other"];
  grades = ['Grade 10', 'Grade 11', 'Grade 12'];
  graduation_result = ['University/College Entrance Preparation', 'Diploma', 'Certificate', 'Bachelors', 'Masters', 'Doctorate', 'Postdoctoral', 'Other'];


  courseTerms = ['FALL', 'WINTER', 'SPRING', 'SUMMER'];

  fileTypes: any = ['ID Documents', 'Transcripts/Marks', 'Letter of Acceptance', 'Registration of classes', 'Hospitalization Card', 'Treaty/Status card', 'Banking Information', 'Letter of Intent', 'Diploma', 'Certificate'];
  ChildCareLevelList = ['Infant (18 months and under)', 'Toddler (19 months up to Grade 1)', 'Grades 1 to 6', 'Dependent Needing Care (Any age)'];
  ChildCarecategoryList = ['Licensed Day Care, Out-of-School Care', 'Approved Family Day Home, Licensed Group Family Child Care', 'Extended Hours Care', 'Kin Care'];

  public postalCodeMask = [/[A-Za-z]/, /[0-9]/, /[A-Za-z]/, ' ', /[0-9]/, /[A-Za-z]/, /[0-9]/];
  public phoneNumberMask = ['(', /[0-9]/, /[0-9]/, /[0-9]/, ')', ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  public dateMask = [/[0-3]/, /[0-9]/, '/', /[0-1]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  public SINMask = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  //public treatyNomask = [/[0-9]/, /[0-9]/, /[0-9]/];
  public isnMask = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];

  public dateformat = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
  public yearMask = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  public yearsMask = [/[0-9]/];
  public bankNoMask = [/[0-9]/, /[0-9]/, /[0-9]/];
  public branchNoMask = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  //public accountNoMask = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  programs = ['Agriculture, Natural Resources and Conservation', 'Architecture, Engineering, and Related Technologies',
    'Business, Management and Public Administration', 'Education', 'Health, Parks, Recreation and Fitness',
    'Humanities', 'Mathematics, Computer and Information Sciences', 'Personal Improvement and Leisure',
    'Personal, Protective and Transportation Services', 'Physical and Life Sciences and Technologies',
    'Social and Behavioural Sciences and Law', 'Visual and Performing Arts, and Communications Technologies',
    'Other'];

  subPrograms = ['Agriculture, Agriculture Operations and Related Sciences', 'Natural Resources and Conservation'];
  DeliveryMethods = ['Classroom', 'Distance Education', 'Virtual (Internet)', 'Blended: Classroom and Distance Education', 'Blended: Classroom and Virtual (Internet)'];
  ProgramLength = ['less than 1 year', '1', '2', '3', '4', '5'];
  genders = ['Male', 'Female'];
  educationYears = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11'];

  loading = false;
  currentUser: any;
  step = -1;   //0;
  step0 = true;

  //Student information
  studentInfoForm: UntypedFormGroup;
  studentModel: PSEApplicationModel;
  Student_name: any;
  student_dob: any;
  otherLocation = false;
  communityList: any[];
  isEmployed = false;
  hasIncome = false;
  pseApp: any;
  pse_app_id = 0;
  maritalStatusValue = '';
  applicantID = 0;
  member: any;
  applicant_Edu_level = '';

  //family members
  childrenForm: UntypedFormGroup;
  tmpMemberModel: any[];
  FamilyMemberModel: IncomeSupportFamilyMemberModel[];
  HasSpouse = false;
  SpouseAdded = false;
  addChildren = false;
  displayChildren = false;
  emptyChildren = false;
  findMemberModel: FindMemberModel;
  aMember: IncomeSupportFamilyMemberModel;
  memberID = 0;
  spouse_aid = 0;
  current_family_comp_id = 0;
  tmp_family_comp_id = 0;
  spouseAdded = false;
  applicantIDOfHead = 0;
  childcare_required = false;

  //Education - High School
  highSchoolForm: UntypedFormGroup;
  highSchoolModel: PseHighSchoolModel;
  highSchoolData: any;

  //Edcation - College
  collegeForm: UntypedFormGroup;
  tmpCollegeModel: any[];
  collegeModel: PseCollegeModel[];
  addCollege = false;
  displayCollege = false;
  emptyCollege = false;
  aCollege: PseCollegeModel;

  //Edcation - University
  universityForm: UntypedFormGroup;
  tmpUniversityModel: any[];
  universityModel: PseCollegeModel[];
  addUniversity = false;
  displayUniversity = false;
  emptyUniversity = false;
  aUniversity: PseCollegeModel;

  //Edcation - Other Education
  trainingForm: UntypedFormGroup;
  tmpTrainingModel: any[];
  trainingModel: PseCollegeModel[];
  addTraining = false;
  displayTraining = false;
  emptyTraining = false;
  aTraining: PseCollegeModel;

  //Education Plan
  eduPlanModel: PseEduPlanModel;
  eduPlanForm: UntypedFormGroup;
  eduPlanData: any;
  showOtherDetails = false;
  institutions: any[];

  //Support History
  supportHistoryForm: UntypedFormGroup;
  tmpHistoryModel: any[];
  historyModel: PseSupportHistoryModel[];
  addHistory = false;
  displayHistory = false;
  emptyHistory = false;
  aHistory: PseSupportHistory;   // PseSupportHistoryModel;
  hasSupportHistory = false;

  //signature
  signed = false;
  canvas: any;
  signaturePad: SignaturePad;
  showSignaturePad = false;
  declarationForm: UntypedFormGroup;

  //load documents
  showUpload = false;
  binDoc: File;
  fileType: string;
  filesUploaded = false;
  base64Image: any;
  otherInfoForm: UntypedFormGroup;
  docsUploaded = false;
  tmpDocumentModel: any[];
  documentsModel: PseLoadDocumentModel[];
  aDoc: PseDocument;   // PseLoadDocumentModel;

  showDocumentsType = false; ///

  nationINFO: any;
  landingModel: any;
  nationLogo: string;

  myDpOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'dd/mm/yyyy',
    markCurrentDay: true,
  };

  hasSIN = false;
  hasISN = false;
  hasHealthCareNo = false;
  hasBank = false;
  hasBankNo = false;
  hasTransitNo = false;
  hasAccountNo = false;

  show_sign_date = false;
  signRequired = false;
  yearList: any[];
  app_approved = false;
  hasApplication = false;
  house_description = '';
  Enable_SubmitApp = false;
  student_firstname = '';

  hasApp = false;
  isAppApproved = false;
  t_fundingBD = 0;

  create_new_app = true;
  update_app = false;
  disable_app = false;

  last_apply_date = '';
  winter_term_checked = false;
  spring_term_checked = false;
  fall_term_checked = false;
  summer_term_checked = false;

  aInstitution: PseCollege;
  aDependant: Children;
  preview: any;
  showSignedDate = false;

  schoolYears: any[];
  schoolPeriods: any[];
  current_school_year = '';
  selected_school_period = ''

  constructor(
    private fb: UntypedFormBuilder,
    private fireMembersService: FireMembersService,
    private nationSettingServicee: FbNationSettingsService,
    private location: Location, public settingsService: AppSettingsService,
    private nationSettingsService: NationSettingsService,
    private authService: AuthService, private router: Router,
    private memberService: MemberService, private sanitizer: DomSanitizer,
    private mediaService: MediaService, private emailService: EmailService,
    private pseService: PseApplicationService, private communityService: CommunityService,
    private dataService: DataService) {
  }

  ngOnInit() {

    const province = this.provinces.filter(prov => prov.name == this.provinceOfUse);
    this.provinceOfUse = province[0].code;

    if (localStorage.getItem('applicantID')) {
      this.applicantID = Number(localStorage.getItem('applicantID'));
    } else {
      this.applicantID = this.authService.getCurrentApplicantID();
    }
    
    this.nationSettingServicee.getsettings().valueChanges().subscribe(setting => {
      if (setting && setting.length > 0) {
        if (setting[0].logo) this.logo = setting[0].logo;
      }
    });
    this.dataService.getAppName().subscribe(appName => {
      if (appName) this.nationName = appName;
      else {
        this.nationSettingsService.getNationSettings().subscribe(sett => {
          if (sett) {
            let settingsModel = sett[0];
            if (settingsModel.band_name) this.nationName = settingsModel.band_name;
          }
        });
      }
    });
    
    this.studentInfoForm = this.fb.group({
      name: [{ value: '', disabled: true }],
      dob: [{ value: '', disabled: true }],
      address: [''],
      city: ['', [Validators.required]],
      province: [this.provinceOfUse, [Validators.required]],
      postal_code: ['', [Validators.required]],

      home_number: ['', []],
      mobile: ['', [Validators.required]],
      SIN: ['', [Validators.minLength(9), Validators.maxLength(9), Validators.pattern('^[0-9]*$')]],
      HealthCareNo: [''],
      isn_no: [''],
      email: ['', [Validators.email]],
      marital_status: ['', [Validators.required]],

      reside: ['', []],
      community: ['', [Validators.required]],
      other_location: ['', []],
      employed: ['', []],
      employer: ['', []],
      leaveofabsence: ['', []],
      withpay: ['', []],
      income: ['', []],
      spouse_classification: ['', []],
      spouse_income: ['', []],

      bank_name: ['', []],
      bank_no: ['', []],
      transit_no: ['', []],
      account_no: ['', []],

      house_status: ['', [Validators.required]],
    });

    this.studentInfoForm.get('bank_name').valueChanges.subscribe(value => {
      if (value != '') {
        this.studentInfoForm.get('bank_no').setValidators(Validators.required);
        this.studentInfoForm.get('transit_no').setValidators(Validators.required);
        this.studentInfoForm.get('account_no').setValidators(Validators.required);
      } else {
        this.studentInfoForm.get('bank_no').clearValidators();
        this.studentInfoForm.get('transit_no').clearValidators();
        this.studentInfoForm.get('account_no').clearValidators();
      }
      this.studentInfoForm.get('bank_no').updateValueAndValidity();
      this.studentInfoForm.get('transit_no').updateValueAndValidity();
      this.studentInfoForm.get('account_no').updateValueAndValidity();
    });

    this.childrenForm = this.fb.group({
      children: this.fb.group({
        add_children: ['', [Validators.required]],
        childrens: this.fb.array([]),
        first_name: [''],
        last_name: [''],
        dob: [''],
        relationship: [''],
        child_care_level: [''],
        child_care_category: [''],
        ChildHealthCareNo: [''],
        ISNofChild: [''],
        gender: [''],
      }),
    });

    this.childrenForm.get('children.add_children').valueChanges.subscribe(value => {
      if (value == 'yes') {
        this.childrenForm.get('children.first_name').setValidators(Validators.required);
        this.childrenForm.get('children.last_name').setValidators(Validators.required);
        this.childrenForm.get('children.dob').setValidators(Validators.required);
        this.childrenForm.get('children.relationship').setValidators(Validators.required);
        //this.childrenForm.get('children.care_required').setValidators(Validators.required); 
        this.childrenForm.get('children.gender').setValidators(Validators.required);
      } else {
        this.childrenForm.get('children.first_name').clearValidators();
        this.childrenForm.get('children.last_name').clearValidators();
        this.childrenForm.get('children.dob').clearValidators();
        this.childrenForm.get('children.relationship').clearValidators();
        //this.childrenForm.get('children.care_required').clearValidators();
        this.childrenForm.get('children.gender').clearValidators();
      }
      this.childrenForm.get('children.first_name').updateValueAndValidity();
      this.childrenForm.get('children.last_name').updateValueAndValidity();
      this.childrenForm.get('children.dob').updateValueAndValidity();
      this.childrenForm.get('children.relationship').updateValueAndValidity();
      //this.childrenForm.get('children.care_required').updateValueAndValidity();
      this.childrenForm.get('children.gender').updateValueAndValidity();
    });

    this.highSchoolForm = this.fb.group({
      school: ['', [Validators.required]],
      school_year: ['', [Validators.required, Validators.pattern('^[0-9]*$'), Validators.min(1975), Validators.max(new Date().getFullYear())]],
      grade: ['', [Validators.required]],
      completed: ['', [Validators.required]],
      transcripts: ['', [Validators.required]]
    });

    this.collegeForm = this.fb.group({
      college: this.fb.group({
        add_college: ['', [Validators.required]],
        colleges: this.fb.array([]),
        institute: [''],
        college_year: ['', [Validators.pattern('^[0-9]*$'), Validators.min(2000), Validators.max(new Date().getFullYear())]],
        years: [''],
        completed1: [''],
        transcripts1: ['']
      }),
    });

    this.collegeForm.get('college.add_college').valueChanges.subscribe(value => {
      if (value == 'yes') {
        this.collegeForm.get('college.institute').setValidators(Validators.required);
        this.collegeForm.get('college.college_year').setValidators([Validators.minLength(4), Validators.maxLength(4), Validators.pattern('^[0-9]*$'), Validators.min(2000), Validators.max(new Date().getFullYear())]);
        this.collegeForm.get('college.years').setValidators([Validators.minLength(1), Validators.maxLength(1), Validators.pattern('^[0-9]*$')]);
        this.collegeForm.get('college.completed1').setValidators(Validators.required);
        this.collegeForm.get('college.transcripts1').setValidators(Validators.required);
      } else {
        this.collegeForm.get('college.institute').clearValidators();
        this.collegeForm.get('college.college_year').clearValidators();
        this.collegeForm.get('college.years').clearValidators();
        this.collegeForm.get('college.completed1').clearValidators();
        this.collegeForm.get('college.transcripts1').clearValidators();
      }
      this.collegeForm.get('college.institute').updateValueAndValidity();
      this.collegeForm.get('college.college_year').updateValueAndValidity();
      this.collegeForm.get('college.years').updateValueAndValidity();
      this.collegeForm.get('college.completed1').updateValueAndValidity();
      this.collegeForm.get('college.transcripts1').updateValueAndValidity();
    });


    this.universityForm = this.fb.group({
      university: this.fb.group({
        add_university: ['', [Validators.required]],
        universitys: this.fb.array([]),
        institute2: [''],
        university_year: ['', [Validators.pattern('^[0-9]*$'), Validators.min(2000), Validators.max(new Date().getFullYear())]],
        years2: ['',],
        completed2: [''],
        transcripts2: ['']
      }),
    });

    this.universityForm.get('university.add_university').valueChanges.subscribe(value => {
      if (value == 'yes') {
        this.universityForm.get('university.institute2').setValidators(Validators.required);
        this.universityForm.get('university.university_year').setValidators([Validators.minLength(4), Validators.maxLength(4), Validators.pattern('^[0-9]*$')]);
        this.universityForm.get('university.years2').setValidators([Validators.minLength(1), Validators.maxLength(1), Validators.pattern('^[0-9]*$')]);
        this.universityForm.get('university.completed2').setValidators(Validators.required);
        this.universityForm.get('university.transcripts2').setValidators(Validators.required);
      } else {
        this.universityForm.get('university.institute2').clearValidators();
        this.universityForm.get('university.university_year').clearValidators();
        this.universityForm.get('university.years2').clearValidators();
        this.universityForm.get('university.completed2').clearValidators();
        this.universityForm.get('university.transcripts2').clearValidators();
      }
      this.universityForm.get('university.institute2').updateValueAndValidity();
      this.universityForm.get('university.university_year').updateValueAndValidity();
      this.universityForm.get('university.years2').updateValueAndValidity();
      this.universityForm.get('university.completed2').updateValueAndValidity();
      this.universityForm.get('university.transcripts2').updateValueAndValidity();
    });

    this.trainingForm = this.fb.group({
      training: this.fb.group({
        add_training: ['', [Validators.required]],
        trainings: this.fb.array([]),
        institute3: [''],
        training_year: ['', [Validators.pattern('^[0-9]*$'), Validators.min(2000), Validators.max(new Date().getFullYear())]],
        completed3: [''],
        transcripts3: ['']
      }),
    });

    this.trainingForm.get('training.add_training').valueChanges.subscribe(value => {
      if (value == 'yes') {
        this.trainingForm.get('training.institute3').setValidators(Validators.required);
        this.trainingForm.get('training.training_year').setValidators([Validators.minLength(4), Validators.maxLength(4), Validators.pattern('^[0-9]*$')]);
        this.trainingForm.get('training.completed3').setValidators(Validators.required);
        this.trainingForm.get('training.transcripts3').setValidators(Validators.required);
      } else {
        this.trainingForm.get('training.institute3').clearValidators();
        this.trainingForm.get('training.training_year').clearValidators();
        this.trainingForm.get('training.completed3').clearValidators();
        this.trainingForm.get('training.transcripts3').clearValidators();
      }
      this.trainingForm.get('training.institute3').updateValueAndValidity();
      this.trainingForm.get('training.training_year').updateValueAndValidity();
      this.trainingForm.get('training.completed3').updateValueAndValidity();
      this.trainingForm.get('training.transcripts3').updateValueAndValidity();
    });

    this.eduPlanForm = this.fb.group({
      objective: ['', [Validators.required]],
      completion_date: ['', [Validators.required]],
      program: ['', [Validators.required]],
      institution: ['', [Validators.required]],
      t_years: ['', [Validators.required]],    /////
      classification: ['', [Validators.required]],   ////
      studentID: [''],
      fall_term: [''],
      winter_term: [''],
      spring_term: [''],
      summer_term: [''],
      subprogram: ['', [Validators.required]],
      delivery_method: ['', [Validators.required]],
      program_start_date: ['', [Validators.required]],
      education_sought: ['', [Validators.required]],   /////
      other_details: ['', []],

      fall_tution_amt: ['', []],
      fall_books_amt: ['', []],
      fall_other_amt: ['', []],

      winter_tution_amt: ['', []],
      winter_books_amt: ['', []],
      winter_other_amt: ['', []],

      spring_tution_amt: ['', []],
      spring_books_amt: ['', []],
      spring_other_amt: ['', []],

      summer_tution_amt: ['', []],
      summer_books_amt: ['', []],
      summer_other_amt: ['', []],
    });


    this.supportHistoryForm = this.fb.group({
      history: this.fb.group({
        add_history: ['', [Validators.required]],
        historys: this.fb.array([]),
        agency: [''],
        support_year: ['', [Validators.pattern('^[0-9]*$'), Validators.min(2000), Validators.max(new Date().getFullYear())]],
        support_institute: [''],
        support_classification: ['']
      }),
    });


    this.supportHistoryForm.get('history.add_history').valueChanges.subscribe(value => {
      if (value == 'yes') {
        this.supportHistoryForm.get('history.agency').setValidators(Validators.required);
        this.supportHistoryForm.get('history.support_year').setValidators([Validators.minLength(4), Validators.maxLength(4), Validators.pattern('^[0-9]*$')]);
        this.supportHistoryForm.get('history.support_institute').setValidators(Validators.required);
        this.supportHistoryForm.get('history.support_classification').setValidators(Validators.required);
      } else {
        this.supportHistoryForm.get('history.agency').clearValidators();
        this.supportHistoryForm.get('history.support_year').clearValidators();
        this.supportHistoryForm.get('history.support_institute').clearValidators();
        this.supportHistoryForm.get('history.support_classification').clearValidators();
      }
      this.supportHistoryForm.get('history.agency').updateValueAndValidity();
      this.supportHistoryForm.get('history.support_year').updateValueAndValidity();
      this.supportHistoryForm.get('history.support_institute').updateValueAndValidity();
      this.supportHistoryForm.get('history.support_classification').updateValueAndValidity();
    });

    this.declarationForm = this.fb.group({
      name: ['', [Validators.required]],
      signature: [''],
      date: [''],
      sign_app: ['']
    });

    this.otherInfoForm = this.fb.group({
      upload_documents: ['', [Validators.required]],
      documents: this.fb.array([]),
      document: [''],
      document_type: [''],
      document_name: [''],
      document_link: ['']
    });

    this.otherInfoForm.get('upload_documents').valueChanges.subscribe(value => {
      if (value == 'yes') {
        this.document.setValidators([Validators.required]);
        this.document_type.setValidators([Validators.required]);
      } else {
        this.document.clearValidators();
        this.document_type.clearValidators();
      }
      this.document.updateValueAndValidity();
      this.document_type.updateValueAndValidity();
    });


    const d_date = new Date();
    let model11: IMyDateModel = { isRange: false, singleDate: { jsDate: d_date }, dateRange: null };
    this.declarationForm.patchValue({ 'date': model11 });

    this.studentModel = new PSEApplicationModel();
    this.highSchoolModel = new PseHighSchoolModel();
    this.eduPlanModel = new PseEduPlanModel();

    this.tmpMemberModel = [];   //initialize

    this.current_school_year = (new Date().getFullYear()) + "-" + (new Date().getFullYear() + 1);
    this.schoolYears = [];
    this.schoolPeriods = [];
    this.pseService.getAppShoolYears(this.applicantID).subscribe(x => {
      if (x.length > 0) {

        this.schoolYears = x;
        const results = this.schoolYears.filter(b => b.school_period == this.current_school_year);
        if (results.length == 0) { this.schoolPeriods.push(this.current_school_year); }

        this.schoolYears.forEach(c => {
          this.schoolPeriods.push(c.school_period);
        });
      } else {
        this.schoolPeriods.push(this.current_school_year);
      }

      setTimeout(() => {
        $('select').formSelect();
        M.updateTextFields();
      }, 25);

    });
  }

  //student   
  get address() { return this.studentInfoForm.get('address'); }
  get city() { return this.studentInfoForm.get('city'); }
  get province() { return this.studentInfoForm.get('province'); }
  get postal_code() { return this.studentInfoForm.get('postal_code'); }
  get home_number() { return this.studentInfoForm.get('home_number'); }
  get mobile() { return this.studentInfoForm.get('mobile'); }
  get SIN() { return this.studentInfoForm.get('SIN'); }
  get HealthCareNo() { return this.studentInfoForm.get('HealthCareNo'); }
  get isn_no() { return this.studentInfoForm.get('isn_no'); }
  get email() { return this.studentInfoForm.get('email'); }
  get marital_status() { return this.studentInfoForm.get('marital_status'); }

  get reside() { return this.studentInfoForm.get('reside'); }
  get community() { return this.studentInfoForm.get('community'); }
  get other_location() { return this.studentInfoForm.get('other_location'); }
  get employed() { return this.studentInfoForm.get('employed'); }
  get employer() { return this.studentInfoForm.get('employer'); }
  get leaveofabsence() { return this.studentInfoForm.get('leaveofabsence'); }
  get withpay() { return this.studentInfoForm.get('withpay'); }
  get income() { return this.studentInfoForm.get('income'); }
  get spouse_classification() { return this.studentInfoForm.get('spouse_classification'); }
  get spouse_income() { return this.studentInfoForm.get('spouse_income'); }

  get bank_name() { return this.studentInfoForm.get('bank_name'); }
  get bank_no() { return this.studentInfoForm.get('bank_no'); }
  get transit_no() { return this.studentInfoForm.get('transit_no'); }
  get account_no() { return this.studentInfoForm.get('account_no'); }
  get house_status() { return this.studentInfoForm.get('house_status'); }

  //family members
  get childrens() { return this.childrenForm.get('children.childrens'); }
  get first_name() { return this.childrenForm.get('children.first_name'); }
  get last_name() { return this.childrenForm.get('children.last_name'); }
  get dob() { return this.childrenForm.get('children.dob'); }
  get relationship() { return this.childrenForm.get('children.relationship'); }
  get child_care_level() { return this.childrenForm.get('children.child_care_level'); }
  get child_care_category() { return this.childrenForm.get('children.child_care_category'); }
  get ChildHealthCareNo() { return this.childrenForm.get('children.ChildHealthCareNo'); }
  get ISNofChild() { return this.childrenForm.get('children.ISNofChild'); }
  get gender() { return this.childrenForm.get('children.gender'); }
  get childControls() { return this.childrenForm.get('children.childrens') as UntypedFormArray; }

  //High School
  get school() { return this.highSchoolForm.get('school'); }
  get school_year() { return this.highSchoolForm.get('school_year'); }
  get grade() { return this.highSchoolForm.get('grade'); }
  get completed() { return this.highSchoolForm.get('completed'); }
  get transcripts() { return this.highSchoolForm.get('transcripts'); }

  //college
  get colleges() { return this.collegeForm.get('college.colleges'); }
  get institute() { return this.collegeForm.get('college.institute'); }
  get college_year() { return this.collegeForm.get('college.college_year'); }
  get years() { return this.collegeForm.get('college.years'); }
  get completed1() { return this.collegeForm.get('college.completed1'); }
  get transcripts1() { return this.collegeForm.get('college.transcripts1'); }
  get collegeControls() { return this.collegeForm.get('college.colleges') as UntypedFormArray; }

  //university
  get universitys() { return this.universityForm.get('university.universitys'); }
  get institute2() { return this.universityForm.get('university.institute2'); }
  get university_year() { return this.universityForm.get('university.university_year'); }
  get years2() { return this.universityForm.get('university.years2'); }
  get completed2() { return this.universityForm.get('university.completed2'); }
  get transcripts2() { return this.universityForm.get('university.transcripts2'); }
  get universityControls() { return this.universityForm.get('university.universitys') as UntypedFormArray; }

  //Other Education
  get trainings() { return this.trainingForm.get('training.trainings'); }
  get institute3() { return this.trainingForm.get('training.institute3'); }
  get training_year() { return this.trainingForm.get('training.training_year'); }
  // get years3() { return this.trainingForm.get('training.years3'); }
  get completed3() { return this.trainingForm.get('training.completed3'); }
  get transcripts3() { return this.trainingForm.get('training.transcripts3'); }
  get trainingControls() { return this.trainingForm.get('training.trainings') as UntypedFormArray; }

  //Education Plan
  get objective() { return this.eduPlanForm.get('objective'); }
  get completion_date() { return this.eduPlanForm.get('completion_date'); }
  get program() { return this.eduPlanForm.get('program'); }
  get institution() { return this.eduPlanForm.get('institution'); }
  get t_years() { return this.eduPlanForm.get('t_years'); }
  get classification() { return this.eduPlanForm.get('classification'); }
  get studentID() { return this.eduPlanForm.get('studentID'); }
  get fall_term() { return this.eduPlanForm.get('fall_term'); }
  get winter_term() { return this.eduPlanForm.get('winter_term'); }
  get spring_term() { return this.eduPlanForm.get('spring_term'); }
  get summer_term() { return this.eduPlanForm.get('summer_term'); }
  get subprogram() { return this.eduPlanForm.get('subprogram'); }
  get delivery_method() { return this.eduPlanForm.get('delivery_method'); }
  get program_start_date() { return this.eduPlanForm.get('program_start_date'); }
  get education_sought() { return this.eduPlanForm.get('education_sought'); }
  get other_details() { return this.eduPlanForm.get('other_details'); }
  get fall_tution_amt() { return this.eduPlanForm.get('fall_tution_amt'); }
  get fall_books_amt() { return this.eduPlanForm.get('fall_books_amt'); }
  get fall_other_amt() { return this.eduPlanForm.get('fall_other_amt'); }
  get winter_tution_amt() { return this.eduPlanForm.get('winter_tution_amt'); }
  get winter_books_amt() { return this.eduPlanForm.get('winter_books_amt'); }
  get winter_other_amt() { return this.eduPlanForm.get('winter_other_amt'); }
  get spring_tution_amt() { return this.eduPlanForm.get('spring_tution_amt'); }
  get spring_books_amt() { return this.eduPlanForm.get('spring_books_amt'); }
  get spring_other_amt() { return this.eduPlanForm.get('spring_other_amt'); }
  get summer_tution_amt() { return this.eduPlanForm.get('summer_tution_amt'); }
  get summer_books_amt() { return this.eduPlanForm.get('summer_books_amt'); }
  get summer_other_amt() { return this.eduPlanForm.get('summer_other_amt'); }

  //Support History
  get historys() { return this.supportHistoryForm.get('history.historys'); }
  get agency() { return this.supportHistoryForm.get('history.agency'); }
  get support_year() { return this.supportHistoryForm.get('history.support_year'); }
  get support_institute() { return this.supportHistoryForm.get('history.support_institute'); }
  get support_classification() { return this.supportHistoryForm.get('history.support_classification'); }
  get historyControls() { return this.supportHistoryForm.get('history.historys') as UntypedFormArray; }

  //signature
  get signature() { return this.declarationForm.get('signature'); }
  get date() { return this.declarationForm.get('date'); }
  get sign_app() { return this.declarationForm.get('sign_app'); }

  get documentsControls() { return this.otherInfoForm.get('documents') as UntypedFormArray; }
  get document() { return this.otherInfoForm.get('document'); }
  get document_type() { return this.otherInfoForm.get('document_type'); }
  get document_name() { return this.otherInfoForm.get('document_name'); }
  get document_link() { return this.otherInfoForm.get('document_link'); }

  newYearSelected(value: string) {
   this.selected_school_period = value; 
    var data: any[];
    data = [];
    data = this.schoolYears.filter(b => b.school_period == value);
    if (data.length > 1) {
      toast({ html: 'Have Multiple Applications for School Year ' + value + ', Please Fix it First !', classes: 'red' });
      return;
    } else {

      //reset values
      this.hasApp = false;
      this.isAppApproved = false;
      this.t_fundingBD = 0;

      this.create_new_app = true;
      this.update_app = false;
      this.disable_app = false;

      if (data.length == 0) {

        this.create_new_app = true;
        this.step = 0;
        this.step0 = false;
        this.pse_app_id = 0;
        this.tmp_family_comp_id = 0;

      } else if (data.length == 1) {

        if (data[0].app_id) this.pse_app_id = data[0].app_id;
        if (this.pse_app_id != 0) { this.hasApplication = true; }
        this.t_fundingBD = data[0].countofBD;
        this.tmp_family_comp_id = data[0].family_id;

        if (this.pse_app_id) {
          this.hasApp = true;
          this.isAppApproved = data[0].approved ? data[0].approved : false;
        }

        setTimeout(() => {
          $('select').formSelect();
          M.updateTextFields();
        }, 35);

        if (this.hasApp === false) {
          this.create_new_app = true;
        } else {
          this.create_new_app = false;
          if (this.isAppApproved === true) {
            if (this.t_fundingBD > 0) {
              this.create_new_app = true;
            } else {
              this.disable_app = true;
            }
          } else {
            this.update_app = true;
          }
        }

        this.step = 0;
        this.step0 = false;
      }

      setTimeout(() => {
        $('select').formSelect();
        M.updateTextFields();
      }, 45);

      this.pseService.getPseStudentInfo(this.applicantID).subscribe(x => {
        if (x) {
          this.member = x[0];
          
          if (this.pse_app_id) {
            this.current_family_comp_id = this.tmp_family_comp_id;
          } else {
            if (this.member.current_family_comp) { this.current_family_comp_id = this.member.current_family_comp; }
          }

          if (this.member.BankName) { this.studentInfoForm.patchValue({ 'bank_name': this.member.BankName }); this.hasBank = true; }
          if (this.member.BankNo) { this.studentInfoForm.patchValue({ 'bank_no': this.member.BankNo }); this.hasBankNo = true; }
          if (this.member.TransitNo) { this.studentInfoForm.patchValue({ 'transit_no': this.member.TransitNo }); this.hasTransitNo = true; }
          if (this.member.AccountNo) { this.studentInfoForm.patchValue({ 'account_no': this.member.AccountNo }); this.hasAccountNo = true; }

          setTimeout(() => {
            $('select').formSelect();
            M.updateTextFields();
          }, 25);

          this.pseService.getInstitutions().subscribe(d => {
            if (d) { this.institutions = d; }

            setTimeout(() => {
              $('select').formSelect();
              M.updateTextFields();
            }, 25);

            this.communityService.getCommunityList().subscribe(com => {
              if (com) { this.communityList = com; }
            });
          });
        }
      });
    }
  }

  start_application() {
    this.loading = true;
    this.currentUser = this.authService.getGlobalUser();

    if (this.member.SpouseAID) {
      this.SpouseAdded = true;
      this.spouse_aid = this.member.SpouseAID;
    }

    if (this.member.MaritalStatus) this.maritalStatusValue = this.member.MaritalStatus;
    if (this.member.edu_level) { this.applicant_Edu_level = this.memberEducatonLevel(this.member.edu_level); }
    if (this.maritalStatusValue == 'Married' || this.maritalStatusValue == "Common Law" || this.maritalStatusValue == "Married or Equivalent") {
      this.HasSpouse = true;
    } else {
      this.HasSpouse = false;
    }

    this.student_firstname = this.member.GivenName;
    this.Student_name = this.member.GivenName + ' ' + this.member.LastName;
    this.declarationForm.patchValue({ 'name': this.Student_name });

    if (this.member.DOB) {
      var array = this.member.DOB.split("-");
      var date = `${array[2].substring(0, 2)}/${array[1]}/${array[0]}`;
      this.student_dob = date;
    } else {
      this.student_dob = "";
    }

    this.studentInfoForm.patchValue({ 'name': this.member.GivenName + ' ' + this.member.LastName })
    this.studentInfoForm.patchValue({ 'dob': this.student_dob })
    this.studentInfoForm.patchValue({ 'address': this.member.MailAddress });
    this.studentInfoForm.patchValue({ 'city': this.member.city });
    this.studentInfoForm.patchValue({ 'province': this.member.province });
    this.studentInfoForm.patchValue({ 'postal_code': this.member.PostalCode });

    if (this.member.Telephone) {
      var phone = this.member.Telephone;
      phone = phone.replace('(', '');
      phone = phone.replace(')', '');
      phone = phone.replace(' ', '');
      phone = phone.replace('-', '');
      phone = phone.replace('  ', '');
      phone = phone.replace('_', '');
      this.studentInfoForm.patchValue({ 'home_number': phone });
    }

    if (this.member.cell_phone_num) {
      var phone = this.member.cell_phone_num;
      phone = phone.replace('(', '');
      phone = phone.replace(')', '');
      phone = phone.replace(' ', '');
      phone = phone.replace('-', '');
      phone = phone.replace('  ', '');
      phone = phone.replace('_', '');
      this.studentInfoForm.patchValue({ 'mobile': phone });
    }

    if (this.member.SIN) { this.studentInfoForm.patchValue({ 'SIN': this.member.SIN }); this.hasSIN = true; }

    this.studentInfoForm.patchValue({ 'email': this.member.Email });
    if (this.member.HealthCareNo) { this.studentInfoForm.patchValue({ 'HealthCareNo': this.member.HealthCareNo }); this.hasHealthCareNo = true; }
    if (this.member.IndianStatusNo) { this.studentInfoForm.patchValue({ 'isn_no': this.member.IndianStatusNo }); this.hasISN = true; }
    if (this.member.MaritalStatus) this.studentInfoForm.patchValue({ 'marital_status': this.member.MaritalStatus });

    this.studentInfoForm.patchValue({ 'community': this.member.Community });
    if (this.member.Community === 'Other') {
      this.otherLocation = true;
    } else {
      this.otherLocation = false;
    }

    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);


    this.applicantIDOfHead = 0;
    while (this.childControls.length !== 0) { this.childControls.removeAt(0); }
    this.pseService.getFamilyMembers(this.current_family_comp_id).subscribe(fm => {
      if (fm.length > 0) {

        (<UntypedFormGroup>this.childrenForm.controls['children']).controls['add_children'].patchValue('yes');

        let child_care_level = "";
        let child_care_category = "";
        let child_health_care = "";
        let child_isn = "";
        let child_gender = "";

        fm.forEach(member => {

          this.applicantIDOfHead = member.applicantIDOfHead;

          if (member.child_care_level === 1) {
            child_care_level = 'Infant (18 months and under)';
          } else if (member.child_care_level === 2) {
            child_care_level = 'Toddler (19 months up to Grade 1)';
          } else if (member.child_care_level === 3) {
            child_care_level = 'Grades 1 to 6';
          } else if (member.child_care_level === 4) {
            child_care_level = 'Dependent Needing Care (Any age)';
          } else {
            child_care_level = "";
          }

          if (member.child_care_category === 1) {
            child_care_category = 'Licensed Day Care, Out-of-School Care';
          } else if (member.child_care_category === 2) {
            child_care_category = 'Approved Family Day Home, Licensed Group Family Child Care';
          } else if (member.child_care_category === 3) {
            child_care_category = 'Extended Hours Care';
          } else if (member.child_care_category === 4) {
            child_care_category = 'Kin Care';
          } else {
            child_care_category = "";
          }

          if (member.health_care_no) { child_health_care = member.health_care_no; } else { child_health_care = ""; }
          if (member.ISN) { child_isn = member.ISN; } else { child_isn = ""; }
          if (member.sex) {
            if (member.sex == 'M') { child_gender = 'Male'; } else { child_gender = 'Female'; }

          } else { child_gender = ""; }

          let tmpFromgroup = this.fb.group({
            given_name: member.given_name,
            last_name: member.last_name,
            dob: this.formatDates(member.DOB),
            Relationship: member.Relationship,
            // edu_level: this.memberEducatonLevel(member.edu_level),

            child_care_level: child_care_level,
            child_care_category: child_care_category,
            care_required: '',

            applicantIDOfHead: member.applicantIDOfHead,
            applicantIDOfChild: member.applicantIDOfChild,

            ChildHealthCareNo: child_health_care,
            ISNofChild: child_isn,
            gender: child_gender,
          });
          this.childControls.push(tmpFromgroup);
        });

        this.emptyChildren = false;
        this.displayChildren = true;
        this.addChildren = false;
      } else {
        (<UntypedFormGroup>this.childrenForm.controls['children']).controls['add_children'].patchValue('no');
        this.onAddChildrenNo();
      }

      setTimeout(() => {
        $('.collapsible').collapsible();
        $('select').formSelect();
        M.updateTextFields();
      }, 25);

      if (this.pse_app_id > 0) {

        this.pseService.getPSEApplication(this.pse_app_id).subscribe(y => {
          if (y) {
            this.pseApp = y[0];
            this.last_apply_date = this.formatDates(this.pseApp.apply_date);

            if (this.pseApp.reside !== null) {
              if (this.pseApp.reside === 'On Reserve') {
                this.studentInfoForm.patchValue({ 'reside': 'yes' });
              } if (this.pseApp.reside === 'Off Reserve') {
                this.studentInfoForm.patchValue({ 'reside': 'no' });
              } else {
                this.studentInfoForm.patchValue({ 'reside': '' });
              }
            }

            this.studentInfoForm.patchValue({ 'other_location': this.pseApp.other_location });

            if (this.pseApp.employed !== null) {
              if (this.pseApp.employed === true) {
                this.studentInfoForm.patchValue({ 'employed': 'yes' });
                this.isEmployed = true;
              } else {
                this.studentInfoForm.patchValue({ 'employed': 'no' });
                this.isEmployed = false;
              }
            }

            this.studentInfoForm.patchValue({ 'employer': this.pseApp.employer });
            if (this.pseApp.leaveofabsence !== null) {
              if (this.pseApp.leaveofabsence === true) {
                this.studentInfoForm.patchValue({ 'leaveofabsence': 'yes' });
              } else {
                this.studentInfoForm.patchValue({ 'leaveofabsence': 'no' });
              }
            }

            if (this.pseApp.withpay !== null) {
              if (this.pseApp.withpay === true) {
                this.studentInfoForm.patchValue({ 'withpay': 'yes' });
                this.hasIncome = true;
              } else {
                this.studentInfoForm.patchValue({ 'withpay': 'no' });
                this.hasIncome = false;
              }
            }

            this.studentInfoForm.patchValue({ 'income': this.pseApp.income });
            this.studentInfoForm.patchValue({ 'spouse_classification': this.pseApp.spouse_classification });
            this.studentInfoForm.patchValue({ 'spouse_income': this.pseApp.spouse_income });


            if (this.pseApp.deposit_required == true) {
              this.studentInfoForm.patchValue({ 'house_status': "2" });
            } else if (this.pseApp.rent_required == true) {
              this.studentInfoForm.patchValue({ 'house_status': "1" });
            } else { this.studentInfoForm.patchValue({ 'house_status': "3" }); }

            if (this.pseApp.Fall_Tuition_AMT) { this.eduPlanForm.patchValue({ 'fall_tution_amt': this.pseApp.Fall_Tuition_AMT }); }
            if (this.pseApp.Fall_Books_AMT) { this.eduPlanForm.patchValue({ 'fall_books_amt': this.pseApp.Fall_Books_AMT }); }
            if (this.pseApp.Fall_Other_AMT) { this.eduPlanForm.patchValue({ 'fall_other_amt': this.pseApp.Fall_Other_AMT }); }

            if (this.pseApp.Winter_Tuition_AMT) { this.eduPlanForm.patchValue({ 'winter_tution_amt': this.pseApp.Winter_Tuition_AMT }); }
            if (this.pseApp.Winter_Books_AMT) { this.eduPlanForm.patchValue({ 'winter_books_amt': this.pseApp.Winter_Books_AMT }); }
            if (this.pseApp.Winter_Other_AMT) { this.eduPlanForm.patchValue({ 'winter_other_amt': this.pseApp.Winter_Other_AMT }); }

            if (this.pseApp.Spring_Tuition_AMT) { this.eduPlanForm.patchValue({ 'spring_tution_amt': this.pseApp.Spring_Tuition_AMT }); }
            if (this.pseApp.Spring_Books_AMT) { this.eduPlanForm.patchValue({ 'spring_books_amt': this.pseApp.Spring_Books_AMT }); }
            if (this.pseApp.Spring_Other_AMT) { this.eduPlanForm.patchValue({ 'spring_other_amt': this.pseApp.Spring_Other_AMT }); }

            if (this.pseApp.Summer_Tuition_AMT) { this.eduPlanForm.patchValue({ 'summer_tution_amt': this.pseApp.Summer_Tuition_AMT }); }
            if (this.pseApp.Summer_Books_AMT) { this.eduPlanForm.patchValue({ 'summer_books_amt': this.pseApp.Summer_Books_AMT }); }
            if (this.pseApp.Summer_Other_AMT) { this.eduPlanForm.patchValue({ 'summer_other_amt': this.pseApp.Summer_Other_AMT }); }


            this.highSchoolForm.patchValue({ 'school': this.pseApp.school });
            this.highSchoolForm.patchValue({ 'school_year': this.pseApp.school_year });
            this.highSchoolForm.patchValue({ 'grade': this.pseApp.grade_achieved });
            if (this.pseApp.completed) {
              this.highSchoolForm.patchValue({ 'completed': "yes" });
            } else {
              this.highSchoolForm.patchValue({ 'completed': "no" });
            }

            if (this.pseApp.transcript) {
              this.highSchoolForm.patchValue({ 'transcripts': "yes" });
            } else {
              this.highSchoolForm.patchValue({ 'transcripts': "no" });
            }

            this.eduPlanForm.patchValue({ 'objective': this.pseApp.objective });
            this.eduPlanForm.patchValue({ 'program': this.pseApp.Program });
            this.ProgramChange(this.pseApp.Program);

            this.eduPlanForm.patchValue({ 'institution': this.pseApp.Institute });


            const completion_date = new Date(this.pseApp.completion_date);
            let model1: IMyDateModel = { isRange: false, singleDate: { jsDate: completion_date }, dateRange: null };
            this.eduPlanForm.patchValue({ completion_date: model1 });

            if (this.pseApp.total_years) { this.eduPlanForm.patchValue({ 't_years': this.pseApp.total_years }); }
            if (this.pseApp.Classification) {
              this.eduPlanForm.patchValue({ 'classification': "Full Time" });
            } else {
              this.eduPlanForm.patchValue({ 'classification': "Part Time" });
            }

            if (this.pseApp.student_id) { this.eduPlanForm.patchValue({ 'studentID': this.pseApp.student_id }); }

            if (this.pseApp.fall_term) { this.eduPlanForm.patchValue({ 'fall_term': "fall" }); this.fall_term_checked = true; }
            else { this.eduPlanForm.patchValue({ 'fall_term': "" }); this.fall_term_checked = false; }

            if (this.pseApp.winter_term) { this.eduPlanForm.patchValue({ 'winter_term': "winter" }); this.winter_term_checked = true; }
            else { this.eduPlanForm.patchValue({ 'winter_term': "" }); this.winter_term_checked = false; }

            if (this.pseApp.spring_term) { this.eduPlanForm.patchValue({ 'spring_term': "spring" }); this.spring_term_checked = true; }
            else { this.eduPlanForm.patchValue({ 'spring_term': "" }); this.spring_term_checked = false; }

            if (this.pseApp.summer_term) { this.eduPlanForm.patchValue({ 'summer_term': "summer" }); this.summer_term_checked = true; }
            else { this.eduPlanForm.patchValue({ 'summer_term': "" }); this.summer_term_checked = false; }

            this.eduPlanForm.patchValue({ 'subprogram': this.pseApp.program_subcategory });
            this.eduPlanForm.patchValue({ 'delivery_method': this.pseApp.delivery_method });

            if (this.pseApp.program_start_date) {
              const start_date = new Date(this.pseApp.program_start_date);
              let model5: IMyDateModel = { isRange: false, singleDate: { jsDate: start_date }, dateRange: null };
              this.eduPlanForm.patchValue({ program_start_date: model5 });
            }

            if (this.pseApp.Education_Level_Sought) {
              this.eduPlanForm.patchValue({ 'education_sought': this.pseApp.Education_Level_Sought });
              if (this.pseApp.Education_Level_Sought == 'Other') { this.showOtherDetails = true; } else { this.showOtherDetails = false; }
            } else {
              this.eduPlanForm.patchValue({ 'education_sought': "" });
              this.showOtherDetails = false;
            }

            if (this.pseApp.other_details) {
              this.eduPlanForm.patchValue({ 'other_details': this.pseApp.other_details });
            } else {
              this.eduPlanForm.patchValue({ 'other_details': "" });
            }

            setTimeout(() => {
              $('select').formSelect();
              M.updateTextFields();
            }, 25);


            this.pseService.getCollege(this.pse_app_id).subscribe(c => {
              if (c.length > 0) {

                (<UntypedFormGroup>this.collegeForm.controls['college']).controls['add_college'].patchValue('yes');

                var iscompleted = "";
                var istranscript = "";
                c.forEach(clg => {
                  if (clg.completed) { iscompleted = "yes" } else { iscompleted = "no" };
                  if (clg.transcript) { istranscript = "yes" } else { istranscript = "no" };
                  let tmpFromgroup = this.fb.group({
                    institute: clg.college,
                    college_year: clg.school_year,
                    years: clg.years,
                    completed1: iscompleted,
                    transcripts1: istranscript,
                  });
                  this.collegeControls.push(tmpFromgroup);
                });

                this.emptyCollege = false;
                this.displayCollege = true;
                this.addCollege = false;

              } else {
                (<UntypedFormGroup>this.collegeForm.controls['college']).controls['add_college'].patchValue('no');
                this.onAddCollegeNo();
              }

              setTimeout(() => {
                $('.collapsible').collapsible();
                $('select').formSelect();
                M.updateTextFields();
              }, 25);

              this.pseService.getUniversity(this.pse_app_id).subscribe(u => {
                if (u.length > 0) {

                  (<UntypedFormGroup>this.universityForm.controls['university']).controls['add_university'].patchValue('yes');

                  var iscompleted = "";
                  var istranscript = "";
                  u.forEach(clg => {
                    if (clg.completed) { iscompleted = "yes" } else { iscompleted = "no" };
                    if (clg.transcript) { istranscript = "yes" } else { istranscript = "no" };

                    let tmpFromgroup = this.fb.group({
                      institute2: clg.university,
                      university_year: clg.school_year,
                      years2: clg.years,
                      completed2: iscompleted,
                      transcripts2: istranscript,
                    });
                    this.universityControls.push(tmpFromgroup);
                  });

                  this.emptyUniversity = false;
                  this.displayUniversity = true;
                  this.addUniversity = false;

                } else {
                  (<UntypedFormGroup>this.universityForm.controls['university']).controls['add_university'].patchValue('no');
                  this.onAddUniversityNo();
                }

                setTimeout(() => {
                  $('.collapsible').collapsible();
                  $('select').formSelect();
                  M.updateTextFields();
                }, 25);


                this.pseService.getTraining(this.pse_app_id).subscribe(t => {
                  if (t.length > 0) {
                    (<UntypedFormGroup>this.trainingForm.controls['training']).controls['add_training'].patchValue('yes');

                    var iscompleted = "";
                    var istranscript = "";
                    t.forEach(clg => {
                      if (clg.completed) { iscompleted = "yes" } else { iscompleted = "no" };
                      if (clg.certification) { istranscript = "yes" } else { istranscript = "no" };

                      let tmpFromgroup = this.fb.group({
                        institute3: clg.description,
                        training_year: clg.school_year,
                        completed3: iscompleted,
                        transcripts3: istranscript,
                      });
                      this.trainingControls.push(tmpFromgroup);
                    });

                    this.emptyTraining = false;
                    this.displayTraining = true;
                    this.addTraining = false;

                  } else {
                    (<UntypedFormGroup>this.trainingForm.controls['training']).controls['add_training'].patchValue('no');
                    this.onAddTrainingNo();
                  }

                  setTimeout(() => {
                    $('.collapsible').collapsible();
                    $('select').formSelect();
                    M.updateTextFields();
                  }, 25);

                  this.pseService.getSupportHistory(this.pse_app_id).subscribe(s => {
                    if (s.length > 0) {

                      (<UntypedFormGroup>this.supportHistoryForm.controls['history']).controls['add_history'].patchValue('yes');
                      s.forEach(h => {

                        let prior_support = h.prior_support;
                        if (h.prior_support) {
                          let classfy = "";
                          if (h.Classification) { classfy = "yes"; } else { classfy = "no"; }

                          let tmpFromgroup = this.fb.group({
                            agency: h.sponsor_agency,
                            support_year: h.support_year,
                            support_institute: h.Institute,
                            support_classification: classfy,
                            prior_support: "yes"
                          });
                          this.historyControls.push(tmpFromgroup);
                          this.hasSupportHistory = true;

                          this.emptyHistory = false;
                          this.displayHistory = true;
                          this.addHistory = false;

                        } else {

                          this.hasSupportHistory = false;
                          (<UntypedFormGroup>this.supportHistoryForm.controls['history']).controls['add_history'].patchValue('no');
                          this.onAddHistoryNo();
                        }
                      });

                    } else {
                      // (< FormGroup > this.supportHistoryForm.controls['history']).controls['add_history'].patchValue('no');
                      (<UntypedFormGroup>this.supportHistoryForm.controls['history']).controls['add_history'].patchValue('');
                      this.onAddHistoryNo();
                    }

                    setTimeout(() => {
                      $('.collapsible').collapsible();
                      $('select').formSelect();
                      M.updateTextFields();
                    }, 15);


                    while (this.documentsControls.length !== 0) { this.documentsControls.removeAt(0); }
                    this.pseService.getDocuments(this.pse_app_id).subscribe(o => {
                      if (o.length > 0) {
                        this.otherInfoForm.controls['upload_documents'].patchValue('yes');
                        o.forEach(clg => {

                          var ext: string = "";
                          if (clg.Extension) {
                            ext = clg.Extension;
                          } else {
                            let file_name: string = clg.doc_name;
                            ext = file_name.substring(file_name.lastIndexOf('.') + 1);
                            ext = ext.toUpperCase();
                            if (ext != "PDF") { ext = "JPG" }
                          }

                          let tmpFromgroup = this.fb.group({
                            docType: ext,   //file_type,     
                            type: clg.doc_type,
                            name: clg.doc_name,
                            link: clg.doc_url,    //clg.doc_link, 
                            photo: clg.doc_photo,
                          });
                          this.documentsControls.push(tmpFromgroup);

                        });

                        this.filesUploaded = true;
                        this.showUpload = true;

                      } else {

                        //this.otherInfoForm.controls['upload_documents'].patchValue('no'); 
                        this.otherInfoForm.controls['upload_documents'].patchValue('');
                        this.onUplodNo();
                      }


                    });  //other documents


                  });  //support history

                });   //training


              });  //university


            }); //college

          }

        });   //  application

      }

    }); //  family members


    setTimeout(() => {
      $('select').formSelect();
      $('.modal').modal();
      M.updateTextFields();
    }, 25);

    this.loading = false;
    this.step += 1;
    window.scroll(0, 0);
  }

  changeDates(dateValue) {
    if (dateValue) {
      if (dateValue.match(this.dateformat)) {
        let dateArray = dateValue.split('/');
        let year = dateArray[2];
        let month = dateArray[1] - 1;
        let day = dateArray[0];
        const date = new Date(year, month, day);
        return date;
      } else {
        const date = new Date(dateValue);
        return date;
      }
    } else {
      return null
    }
  }


  maritalStatusChange(value) {
    this.maritalStatusValue = value;
    if (this.maritalStatusValue == 'Married' || this.maritalStatusValue == "Common Law" || this.maritalStatusValue == "Married or Equivalent") {
      this.HasSpouse = true;
    } else {
      this.HasSpouse = false;
    }

    //this.maritalStatusValue = this.maritalStatus[Number(this.maritalStatusSelected)].name;

    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }

  provinceChange(value) {

  }

  communitySelected(value) {
    if (value === 'Other') {
      this.otherLocation = true;
    } else {
      this.otherLocation = false;
    }

    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }


  onEmployedChecked(value) {
    if (value == 'yes') {
      this.isEmployed = true;
    } else {
      this.isEmployed = false;
      this.studentInfoForm.get('employer').reset();
    }

    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }

  onWithPayChecked(value) {
    if (value == 'yes') {
      this.hasIncome = true;
      // this.studentInfoForm.get('income').setValidators(Validators.required);
      // this.studentInfoForm.get('income').updateValueAndValidity();
    } else {
      this.hasIncome = false;
      this.studentInfoForm.get('income').reset();

      // this.studentInfoForm.get('income').clearValidators();
      //this.studentInfoForm.get('income').updateValueAndValidity();
    }

    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);

  }

  setIncomesTwoNumberDecimal(value: any) {
    let amount = parseFloat(value).toFixed(2);
    this.studentInfoForm.controls['income'].patchValue(amount);
  }

  setSpouseIncomesTwoNumberDecimal(value: any) {
    let amount = parseFloat(value).toFixed(2);
    this.studentInfoForm.controls['spouse_income'].patchValue(amount);
  }

  setFallTuitionIncomesTwoNumberDecimal(value: any) {
    let amount = parseFloat(value).toFixed(2);
    this.eduPlanForm.controls['fall_tution_amt'].patchValue(amount);
  }

  setFallBooksIncomesTwoNumberDecimal(value: any) {
    let amount = parseFloat(value).toFixed(2);
    this.eduPlanForm.controls['fall_books_amt'].patchValue(amount);
  }

  setFallOtherIncomesTwoNumberDecimal(value: any) {
    let amount = parseFloat(value).toFixed(2);
    this.eduPlanForm.controls['fall_other_amt'].patchValue(amount);
  }

  setWinterTuitionIncomesTwoNumberDecimal(value: any) {
    let amount = parseFloat(value).toFixed(2);
    this.eduPlanForm.controls['winter_tution_amt'].patchValue(amount);
  }

  setWinterBooksIncomesTwoNumberDecimal(value: any) {
    let amount = parseFloat(value).toFixed(2);
    this.eduPlanForm.controls['winter_books_amt'].patchValue(amount);
  }

  setWinterOtherIncomesTwoNumberDecimal(value: any) {
    let amount = parseFloat(value).toFixed(2);
    this.eduPlanForm.controls['winter_other_amt'].patchValue(amount);
  }

  setSpringTuitionIncomesTwoNumberDecimal(value: any) {
    let amount = parseFloat(value).toFixed(2);
    this.eduPlanForm.controls['spring_tution_amt'].patchValue(amount);
  }

  setSpringBooksIncomesTwoNumberDecimal(value: any) {
    let amount = parseFloat(value).toFixed(2);
    this.eduPlanForm.controls['spring_books_amt'].patchValue(amount);
  }

  setSpringOtherIncomesTwoNumberDecimal(value: any) {
    let amount = parseFloat(value).toFixed(2);
    this.eduPlanForm.controls['spring_other_amt'].patchValue(amount);
  }

  setSummerTuitionIncomesTwoNumberDecimal(value: any) {
    let amount = parseFloat(value).toFixed(2);
    this.eduPlanForm.controls['summer_tution_amt'].patchValue(amount);
  }

  setSummerBooksIncomesTwoNumberDecimal(value: any) {
    let amount = parseFloat(value).toFixed(2);
    this.eduPlanForm.controls['summer_books_amt'].patchValue(amount);
  }

  setSummerOtherIncomesTwoNumberDecimal(value: any) {
    let amount = parseFloat(value).toFixed(2);
    this.eduPlanForm.controls['summer_other_amt'].patchValue(amount);
  }


  //==================Add Children=================================
  onAddChildrenYes() {
    this.addChildren = true;
    setTimeout(() => {
      $('.modal').modal();
      // $('#AddFamilyMemberModal').modal('open')
      $('.collapsible').collapsible();
      $('select').formSelect();
      M.updateTextFields();
    }, 25);


    this.emptyChildren = false;
    this.childrenForm.get('children.first_name').reset();
    this.childrenForm.get('children.last_name').reset();
    this.childrenForm.get('children.dob').reset();
    this.childrenForm.get('children.relationship').reset();
    //this.childrenForm.get('children.edu_level').reset();
    //this.childrenForm.get('children.care_required').reset();
    this.childrenForm.get('children.ChildHealthCareNo').reset();
    this.childrenForm.get('children.ISNofChild').reset();
    this.childrenForm.get('children.child_care_level').reset();
    this.childrenForm.get('children.child_care_category').reset();
    this.childrenForm.get('children.gender').reset();
  }

  onAddChildrenNo() {
    this.addChildren = false;
    const children = this.childControls;
    while (children.length !== 0) {
      children.removeAt(0);
    }
    this.emptyChildren = true;
    this.displayChildren = false;
    children.setValue([]);
  }

  addChild(values: any): void {
    if (this.SpouseAdded) {
      if (values.relationship == 'Spouse') {
        toast({ html: '<p>Warning: Duplicated Spouse !</p> ', classes: 'red' });
        return;
      }
    }

    const children = this.childControls;

    this.findMemberModel = new FindMemberModel();
    this.findMemberModel.firstName = values.first_name.trim();
    this.findMemberModel.lastName = values.last_name.trim();

    var dob_arr = values.dob.singleDate.formatted.split("/");
    var dob_d = dob_arr[0] + "/" + dob_arr[1] + "/" + dob_arr[2];
    this.findMemberModel.dob = this.formatDate2(dob_d);

    this.memberID = 0;
    this.memberService.findFamilyMemberByName(this.findMemberModel).subscribe(x => {
      if (x) {
        let oneChild = x[0];
        this.memberID = oneChild.applicantID;
      }

      // $('#AddFamilyMemberModal').modal('close')

      let aidOfHead: number = 0;
      if (this.applicantIDOfHead != 0) { aidOfHead = this.applicantIDOfHead; } else { aidOfHead = this.applicantID; }
      let tmpFromgroup = this.fb.group({
        given_name: [values.first_name.trim()],
        last_name: [values.last_name.trim()],
        dob: dob_d,

        Relationship: [values.relationship],
        //edu_level: [values.edu_level],
        //care_required: [values.care_required],
        child_care_level: [values.child_care_level],
        child_care_category: [values.child_care_category],
        applicantIDOfHead: aidOfHead,
        applicantIDOfChild: this.memberID,
        ChildHealthCareNo: [values.ChildHealthCareNo],
        ISNofChild: [values.ISNofChild],
        gender: [values.gender],

      });
      children.push(tmpFromgroup);


      if (values.relationship == 'Spouse') {
        this.SpouseAdded = true;
        this.spouse_aid = this.memberID;
      }

      this.emptyChildren = false;
      this.displayChildren = true;
      this.addChildren = false;
      setTimeout(() => {
        $('.collapsible').collapsible();
        $('select').formSelect();

      }, 25);
      toast({ html: 'Member Successfully Added!', classes: 'green' });
    });
  }

  addAnotherChild() {
    this.addChildren = true;
    this.childcare_required = false;  ///////////

    this.childrenForm.get('children.first_name').reset();
    this.childrenForm.get('children.last_name').reset();
    this.childrenForm.get('children.dob').reset();
    this.childrenForm.get('children.relationship').reset();

    this.childrenForm.get('children.child_care_level').reset();
    this.childrenForm.get('children.child_care_category').reset();
    this.childrenForm.get('children.ChildHealthCareNo').reset();
    this.childrenForm.get('children.ISNofChild').reset();
    this.childrenForm.get('children.gender').reset();


    this.childrenForm.get('children.first_name').setValidators(Validators.required);
    this.childrenForm.get('children.last_name').setValidators(Validators.required);
    this.childrenForm.get('children.dob').setValidators(Validators.required);
    this.childrenForm.get('children.relationship').setValidators(Validators.required);
    this.childrenForm.get('children.gender').setValidators(Validators.required);

    this.childrenForm.get('children.first_name').updateValueAndValidity();
    this.childrenForm.get('children.last_name').updateValueAndValidity();
    this.childrenForm.get('children.dob').updateValueAndValidity();
    this.childrenForm.get('children.relationship').updateValueAndValidity();
    this.childrenForm.get('children.gender').updateValueAndValidity();

    setTimeout(() => {
      // $('#AddFamilyMemberModal').modal('open')
      $('.collapsible').collapsible();
      $('select').formSelect();
      M.updateTextFields();
    }, 25);

  }

  onDeleteChildern(index) {
    const child = this.childControls;

    const deleted_child = child.controls[index].value.Relationship;
    if (deleted_child == 'Spouse') {
      this.SpouseAdded = false;
      this.spouse_aid = 0;
    }

    child.removeAt(index);
    if (child.length == 0) {
      this.addChildren = false;
      this.displayChildren = false;
      this.emptyChildren = true;
      this.childrenForm.patchValue({ 'children': { 'add_children': 'no' } });
    }
    toast({ html: 'Member deleted!', classes: 'red' });
  }

  closeAddChild() {
    this.addChildren = false;
    this.childcare_required = false;  ///////////////

    this.childrenForm.get('children.first_name').clearValidators();
    this.childrenForm.get('children.last_name').clearValidators();
    this.childrenForm.get('children.dob').clearValidators();
    this.childrenForm.get('children.relationship').clearValidators();
    //this.childrenForm.get('children.edu_level').clearValidators();
    //this.childrenForm.get('children.care_required').clearValidators();
    this.childrenForm.get('children.gender').clearValidators();

    this.childrenForm.get('children.first_name').updateValueAndValidity();
    this.childrenForm.get('children.last_name').updateValueAndValidity();
    this.childrenForm.get('children.dob').updateValueAndValidity();
    this.childrenForm.get('children.relationship').updateValueAndValidity();
    //this.childrenForm.get('children.edu_level').updateValueAndValidity();
    //this.childrenForm.get('children.care_required').updateValueAndValidity();
    this.childrenForm.get('children.gender').updateValueAndValidity();

    if (this.childControls.length == 0) {
      this.childrenForm.patchValue({ 'children': { 'add_children': 'no' } });
    }
  }

  formatDate2(dateValue) {
    if (dateValue) {
      //   //1987-07-19T00:00:00
      let dateArray = dateValue.split('/');
      let day = dateArray[0];
      let month = dateArray[1];   //- 1;
      let year = dateArray[2].substring(0, 4);
      return year + '-' + month + '-' + day;
    } else {
      return '';
    }
  }

  formatDates(dateValue) {

    if (dateValue) {
      //   //1987-07-19T00:00:00
      let dateArray = dateValue.split('-');
      let year = dateArray[0];
      let month = dateArray[1];   //- 1;
      let day = dateArray[2].substring(0, 2);
      return day + '/' + month + '/' + year;
    } else {
      return '';
    }
  }

  memberEducatonLevel(level) {

    let applicant_education = '';
    switch (level) {
      case 0: {
        applicant_education = "Preschool";
        break;
      }
      case 1: {
        applicant_education = "Up to Grade 7-8 (Sec. I = Grade 8)";
        break;
      }
      case 2: {
        applicant_education = "Grade 9-10 (Sec. II-III)";
        break;
      }
      case 3: {
        applicant_education = "Grade 11-12 (Sec. IV-V)";
        break;
      }
      case 4: {
        applicant_education = "Secondary School Diploma or GED";
        break;
      }
      case 5: {
        applicant_education = "Some post-secondary training";
        break;
      }
      case 6: {
        applicant_education = "Apprenticeship or trades certificate or diploma";
        break;
      }
      case 7: {
        applicant_education = "College";
        break;
      }
      case 8: {
        applicant_education = "CEGEP";
        break;
      }
      case 9: {
        applicant_education = "University certificate or diploma";
        break;
      }
      case 10: {
        applicant_education = "University - Bachelor Degree";
        break;
      }
      case 11: {
        applicant_education = "University – Master’s degree";
        break;
      }
      case 12: {
        applicant_education = "University – Doctorate";
        break;
      }
      case 13: {
        applicant_education = "Course or certificate";
        break;
      }
      case 14: {
        applicant_education = "Other";
        break;
      }
      default: {
        applicant_education = "";
        break;
      }
    }
    return applicant_education;

  }


  RelationshipChange(value) {
    if (value === 'Spouse') {
      this.spouseAdded = true;
      this.childrenForm.get('children.child_care_level').reset();
      this.childrenForm.get('children.child_care_category').reset();

      this.childrenForm.get('children.child_care_level').clearValidators();
      this.childrenForm.get('children.child_care_category').clearValidators();
      this.childrenForm.get('children.child_care_level').updateValueAndValidity();
      this.childrenForm.get('children.child_care_category').updateValueAndValidity();
      this.childcare_required = false;

      setTimeout(() => {
        $('select').formSelect();
        M.updateTextFields();
      }, 25);

    } else {

      this.spouseAdded = false;

      setTimeout(() => {
        $('.modal').modal();
        $('#FamilyMemberModal').modal('open')
        $('.collapsible').collapsible();
        $('select').formSelect();
        M.updateTextFields();
      }, 25);
    }
  }

  onDateChange5(event: IMyDateModel): void {
    (<UntypedFormGroup>this.childrenForm.controls['children']).controls['dob'].patchValue(event.singleDate.formatted);
  }


  childcareRequiredYes() {
    this.childcare_required = true;

    this.childrenForm.get('children.child_care_level').setValidators(Validators.required);
    this.childrenForm.get('children.child_care_category').setValidators(Validators.required);
    this.childrenForm.get('children.child_care_level').updateValueAndValidity();
    this.childrenForm.get('children.child_care_category').updateValueAndValidity();

    setTimeout(() => {
      // $('.modal').modal();
      $('#FamilyMemberModal').modal('close');
      $('#AddFamilyMemberModal').modal('open');
      $('.collapsible').collapsible();
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }

  childcareRequiredNo() {
    this.childcare_required = false;
    this.childrenForm.get('children.child_care_level').reset();
    this.childrenForm.get('children.child_care_category').reset();

    this.childrenForm.get('children.child_care_level').clearValidators();
    this.childrenForm.get('children.child_care_category').clearValidators();
    this.childrenForm.get('children.child_care_level').updateValueAndValidity();
    this.childrenForm.get('children.child_care_category').updateValueAndValidity();

    setTimeout(() => {
      // $('.modal').modal();
      $('#FamilyMemberModal').modal('close');
      $('#AddFamilyMemberModal').modal('open');
      $('.collapsible').collapsible();
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }

  //==================Add College=================================
  onAddCollegeYes() {
    this.addCollege = true;
    setTimeout(() => {
      $('.modal').modal();
      $('#AddCollegeModal').modal('open');
      $('.collapsible').collapsible();
      $('select').formSelect();
      M.updateTextFields();
    }, 25);

    this.emptyCollege = false;
    this.collegeForm.get('college.institute').reset();
    this.collegeForm.get('college.college_year').reset();
    this.collegeForm.get('college.college_year').setValidators([Validators.pattern('^[0-9]*$'), Validators.min(2000), Validators.max(new Date().getFullYear())]);
    this.collegeForm.get('college.years').reset();
    this.collegeForm.get('college.completed1').reset();
    this.collegeForm.get('college.transcripts1').reset();

  }

  onAddCollegeNo() {
    this.addCollege = false;
    const colleges = this.collegeControls;
    while (colleges.length !== 0) {
      colleges.removeAt(0);
    }
    this.emptyCollege = true;
    this.displayCollege = false;
    colleges.setValue([]);
  }

  addMyCollege(values: any): void {

    const colleges = this.collegeControls;
    let tmpFromgroup = this.fb.group({
      institute: [values.institute.trim()],
      college_year: [values.college_year],
      years: [values.years],
      completed1: [values.completed1],
      transcripts1: [values.transcripts1],

    });

    colleges.push(tmpFromgroup);

    this.emptyCollege = false;
    this.displayCollege = true;
    this.addCollege = false;
    setTimeout(() => {
      $('#AddCollegeModal').modal('close');
      $('.collapsible').collapsible();
      $('select').formSelect();
    }, 25);
    toast({ html: 'College Successfully Added!', classes: 'green' });
  }

  getEndYear(start: any, duration: any) {
    if (start && duration) {
      return Number(start) + Number(duration);
    }
  }

  addAnotherCollege() {
    this.addCollege = true;
    this.collegeForm.get('college.institute').reset();
    this.collegeForm.get('college.college_year').reset();
    this.collegeForm.get('college.college_year').setValidators([Validators.pattern('^[0-9]*$'), Validators.min(2000), Validators.max(new Date().getFullYear())]);
    this.collegeForm.get('college.years').reset();
    this.collegeForm.get('college.completed1').reset();
    this.collegeForm.get('college.transcripts1').reset();

    setTimeout(() => {
      $('#AddCollegeModal').modal('open');
      $('.collapsible').collapsible();
      $('select').formSelect();
      M.updateTextFields();
    }, 25);

  }

  onDeleteCollege(index) {
    const colleges = this.collegeControls;
    colleges.removeAt(index);
    if (colleges.length == 0) {
      this.addCollege = false;
      this.displayCollege = false;
      this.emptyCollege = true;
      //this.collegeForm.patchValue({ 'college': { 'add_college': 'no' } });
      this.collegeForm.patchValue({ 'college': { 'add_college': '' } });
    }
    toast({ html: 'College deleted!', classes: 'red' });
  }

  closeAddCollege() {
    this.addCollege = false;

    this.collegeForm.get('college.institute').clearValidators();
    this.collegeForm.get('college.college_year').clearValidators();
    this.collegeForm.get('college.years').clearValidators();
    this.collegeForm.get('college.completed1').updateValueAndValidity();
    this.collegeForm.get('college.transcripts1').updateValueAndValidity();
    if (this.collegeControls.length == 0) {
      this.collegeForm.patchValue({ 'college': { 'add_college': 'no' } });
    }

  }

  cancelCollege() {
    const colleges = this.collegeControls;
    if (colleges.length == 0) {
      this.addCollege = false;
      this.displayCollege = false;
      this.emptyCollege = true;
      this.collegeForm.patchValue({ 'college': { 'add_college': 'no' } });
    }
  }

  //==================Add University=================================
  onAddUniversityYes() {
    this.addUniversity = true;
    setTimeout(() => {
      $('.modal').modal();
      $('#AddUniversityModal').modal('open');
      $('.collapsible').collapsible();
      $('select').formSelect();
      M.updateTextFields();
    }, 25);

    this.emptyUniversity = false;
    this.universityForm.get('university.institute2').reset();
    this.universityForm.get('university.university_year').reset();
    this.universityForm.get('university.university_year').setValidators([Validators.pattern('^[0-9]*$'), Validators.min(2000), Validators.max(new Date().getFullYear())])
    this.universityForm.get('university.years2').reset();
    this.universityForm.get('university.completed2').reset();
    this.universityForm.get('university.transcripts2').reset();

  }

  onAddUniversityNo() {
    this.addUniversity = false;
    const universitys = this.universityControls;
    while (universitys.length !== 0) {
      universitys.removeAt(0);
    }
    this.emptyUniversity = true;
    this.displayUniversity = false;
    universitys.setValue([]);
  }

  addMyUniversity(values: any): void {

    const universitys = this.universityControls;
    let tmpFromgroup = this.fb.group({
      institute2: [values.institute2.trim()],
      university_year: [values.university_year],
      years2: [values.years2],
      completed2: [values.completed2],
      transcripts2: [values.transcripts2],

    });

    universitys.push(tmpFromgroup);

    this.emptyUniversity = false;
    this.displayUniversity = true;
    this.addUniversity = false;
    setTimeout(() => {
      $('#AddUniversityModal').modal('close');
      $('.collapsible').collapsible();
      $('select').formSelect();
    }, 25);
    toast({ html: 'University Successfully Added!', classes: 'green' });

  }

  addAnotherUniversity() {
    this.addUniversity = true;
    this.universityForm.get('university.institute2').reset();
    this.universityForm.get('university.university_year').reset();
    this.universityForm.get('university.university_year').setValidators([Validators.pattern('^[0-9]*$'), Validators.min(2000), Validators.max(new Date().getFullYear())])
    this.universityForm.get('university.years2').reset();
    this.universityForm.get('university.completed2').reset();
    this.universityForm.get('university.transcripts2').reset();

    setTimeout(() => {
      $('#AddUniversityModal').modal('open');
      $('.collapsible').collapsible();
      $('select').formSelect();
      M.updateTextFields();
    }, 25);

  }

  onDeleteUniversity(index) {
    const universitys = this.universityControls;
    universitys.removeAt(index);
    if (universitys.length == 0) {
      this.addUniversity = false;
      this.displayUniversity = false;
      this.emptyUniversity = true;
      this.universityForm.patchValue({ 'university': { 'add_university': '' } });
    }
    toast({ html: 'university deleted!', classes: 'red' });
  }

  closeAddUniversity() {
    this.addUniversity = false;

    this.universityForm.get('university.institute2').clearValidators();
    this.universityForm.get('university.university_year').clearValidators();
    this.universityForm.get('university.years2').clearValidators();
    this.universityForm.get('university.completed2').updateValueAndValidity();
    this.universityForm.get('university.transcripts2').updateValueAndValidity();
    if (this.universityControls.length == 0) {
      this.universityForm.patchValue({ 'university': { 'add_university': 'no' } });
    }
  }

  cancelUniversity() {
    const universitys = this.universityControls;
    if (universitys.length == 0) {
      this.addUniversity = false;
      this.displayUniversity = false;
      this.emptyUniversity = true;
      this.universityForm.patchValue({ 'university': { 'add_university': 'no' } });
    }
  }


  //==================Add Other Education=================================
  onAddTrainingYes() {
    this.addTraining = true;
    setTimeout(() => {
      $('.modal').modal();
      $('#AddTrainingModal').modal('open');
      $('.collapsible').collapsible();
      $('select').formSelect();
      M.updateTextFields();
    }, 25);

    this.emptyTraining = false;
    this.trainingForm.get('training.institute3').reset();
    this.trainingForm.get('training.training_year').reset();
    this.trainingForm.get('training.training_year').setValidators([Validators.pattern('^[0-9]*$'), Validators.min(2000), Validators.max(new Date().getFullYear())]);
    this.trainingForm.get('training.completed3').reset();
    this.trainingForm.get('training.transcripts3').reset();

  }

  onAddTrainingNo() {
    this.addTraining = false;
    const trainings = this.trainingControls;
    while (trainings.length !== 0) {
      trainings.removeAt(0);
    }
    this.emptyTraining = true;
    this.displayTraining = false;
    trainings.setValue([]);
  }

  addMyTraining(values: any): void {

    const trainings = this.trainingControls;
    let tmpFromgroup = this.fb.group({
      institute3: [values.institute3.trim()],
      training_year: [values.training_year],
      completed3: [values.completed3],
      transcripts3: [values.transcripts3],
    });

    trainings.push(tmpFromgroup);

    this.emptyTraining = false;
    this.displayTraining = true;
    this.addTraining = false;
    setTimeout(() => {
      $('#AddTrainingModal').modal('close');
      $('.collapsible').collapsible();
      $('select').formSelect();
    }, 25);
    toast({ html: 'Other Education Successfully Added!', classes: 'green' });

  }

  addAnotherTraining() {
    this.addTraining = true;
    this.trainingForm.get('training.institute3').reset();
    this.trainingForm.get('training.training_year').reset();
    this.trainingForm.get('training.training_year').setValidators([Validators.pattern('^[0-9]*$'), Validators.min(2000), Validators.max(new Date().getFullYear())]);
    this.trainingForm.get('training.completed3').reset();
    this.trainingForm.get('training.transcripts3').reset();

    setTimeout(() => {
      $('#AddTrainingModal').modal('open');
      $('.collapsible').collapsible();
      $('select').formSelect();
      M.updateTextFields();
    }, 25);

  }

  onDeleteTraining(index) {
    const trainings = this.trainingControls;
    trainings.removeAt(index);
    if (trainings.length == 0) {
      this.addTraining = false;
      this.displayTraining = false;
      this.emptyTraining = true;
      this.trainingForm.patchValue({ 'training': { 'add_training': '' } });
    }
    toast({ html: 'Other Education deleted!', classes: 'red' });
  }

  closeAddTraining() {
    this.addTraining = false;

    this.trainingForm.get('training.institute3').clearValidators();
    this.trainingForm.get('training.training_year').clearValidators();
    //this.trainingForm.get('training.years2').clearValidators();
    this.trainingForm.get('training.completed3').updateValueAndValidity();
    this.trainingForm.get('training.transcripts3').updateValueAndValidity();
    if (this.trainingControls.length == 0) {
      this.trainingForm.patchValue({ 'training': { 'add_training': 'no' } });
    }
  }

  cancelTraining() {
    const trainings = this.trainingControls;
    if (trainings.length == 0) {
      this.addTraining = false;
      this.displayTraining = false;
      this.emptyTraining = true;
      this.trainingForm.patchValue({ 'training': { 'add_training': 'no' } });
    }
  }

  //==================Add Support History =================================

  onAddHistoryYes() {
    this.addHistory = true;
    setTimeout(() => {
      $('.modal').modal();
      $('#AddHistoryModal').modal('open');
      $('.collapsible').collapsible();
      $('select').formSelect();
      M.updateTextFields();
    }, 25);

    this.emptyHistory = false;
    this.supportHistoryForm.get('history.agency').reset();
    this.supportHistoryForm.get('history.support_year').reset();
    this.supportHistoryForm.get('history.support_year').setValidators([Validators.pattern('^[0-9]*$'), Validators.min(2000), Validators.max(new Date().getFullYear())]);
    this.supportHistoryForm.get('history.support_institute').reset();
    this.supportHistoryForm.get('history.support_classification').reset();
  }

  onAddHistoryNo() {
    this.addHistory = false;
    const historys = this.historyControls;
    while (historys.length !== 0) {
      historys.removeAt(0);
    }
    this.emptyHistory = true;
    this.displayHistory = false;
    // historys.setValue([]);
  }

  addMyHistory(values: any): void {

    const historys = this.historyControls;
    let tmpFromgroup = this.fb.group({
      agency: [values.agency.trim()],
      support_year: [values.support_year],
      support_institute: [values.support_institute],
      support_classification: [values.support_classification],
      prior_support: "yes"
    });
    historys.push(tmpFromgroup);

    this.emptyHistory = false;
    this.displayHistory = true;
    this.addHistory = false;
    setTimeout(() => {
      $('#AddHistoryModal').modal('close');
      $('.collapsible').collapsible();
      $('select').formSelect();
    }, 25);
    toast({ html: 'Support History Successfully Added!', classes: 'green' });
  }

  addAnotherHistory() {
    this.addHistory = true;
    this.supportHistoryForm.get('history.agency').reset();
    this.supportHistoryForm.get('history.support_year').reset();
    this.supportHistoryForm.get('history.support_year').setValidators([Validators.pattern('^[0-9]*$'), Validators.min(2000), Validators.max(new Date().getFullYear())]);
    this.supportHistoryForm.get('history.support_institute').reset();
    this.supportHistoryForm.get('history.support_classification').reset();

    setTimeout(() => {
      $('#AddHistoryModal').modal('open');
      $('.collapsible').collapsible();
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }

  onDeleteHistory(index) {
    const historys = this.historyControls;
    historys.removeAt(index);
    if (historys.length == 0) {
      this.addHistory = false;
      this.displayHistory = false;
      this.emptyHistory = true;
      this.hasSupportHistory = false;
      //this.supportHistoryForm.patchValue({ 'history': { 'add_history': 'no' } });
      this.supportHistoryForm.patchValue({ 'history': { 'add_history': '' } });
    }
    toast({ html: 'Support History deleted!', classes: 'red' });
  }

  closeAddHistory() {
    this.addHistory = false;
    this.supportHistoryForm.get('history.agency').clearValidators();
    this.supportHistoryForm.get('history.support_year').clearValidators();
    this.supportHistoryForm.get('history.support_institute').clearValidators();
    this.supportHistoryForm.get('history.support_classification').clearValidators();
    if (this.historyControls.length == 0) {
      this.supportHistoryForm.patchValue({ 'history': { 'add_history': 'no' } });
    }
  }

  cancelHistory() {
    const historys = this.historyControls;
    if (historys.length == 0) {
      this.addHistory = false;
      this.displayHistory = false;
      this.emptyHistory = true;
      this.hasSupportHistory = false;
      this.supportHistoryForm.patchValue({ 'history': { 'add_history': 'no' } });
    }
  }

  //=============== upload documents =====================
  onUplodYes() {
    this.showUpload = true;
    this.showUpload2 = false;
    setTimeout(() => {
      $('.modal').modal();
      $('#FileUploadModal').modal('open');
      $('select').formSelect();
    });
  }

  onUplodNo() {
    this.showUpload = false;
    const myDocuments = this.documentsControls;
    while (myDocuments.length !== 0) { myDocuments.removeAt(0); }
    // this.documentsControls.setValue([]);
  }

  addAnotherFile() {
    this.showUpload = true;
    this.filesUploaded = false;
    this.showDocumentsType = false;
    this.fileType = '';

    setTimeout(() => {
      $('#FileUploadModal').modal('open');
      $('select').formSelect();
      $('#DocumentsLoadModal').modal('close');
      M.updateTextFields();
    }, 25);
  }

  newDoc(values: any): UntypedFormGroup {
    return this.fb.group({
      docType: [values.docType],
      type: [values.type],
      name: [values.name],
      link: [values.link],
      photo: [values.photo]
    });
  }

  docTypeChange(value) {
    //  this.document_type.patchValue(value);
  }

  async handleFileInput(files: FileList) {
    this.binDoc = undefined;
    if (files) {
      this.binDoc = files[0];

      this.base64Image = await this.imageToBase64(files[0]);
      this.document_name.patchValue(files[0].name);
      this.fileType = this.validateInput(this.binDoc.name);

      this.showDocumentsType = true;

      setTimeout(() => {
        $('select').formSelect();
        $('.collapsible').collapsible();
        $('.tooltipped').tooltip();
        M.updateTextFields();
      }, 25);
    }
  }

  imageToBase64 = async (file: File) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      }
    });
  }

  uploadFile(values) {
    this.loading = true;

    if (this.fileType == '') {
      toast({ html: 'Please Select The Document Type.', classes: 'red' });
      return;
    }

    // $('#FileUploadModal').modal('close');

    if (this.binDoc) {
      if (this.validateInput(this.binDoc.name) == 'image') {
        const img = new ImageModel();
        img.imgBase64 = this.base64Image.replace(/^data:image\/[a-z]+;base64,/, '');

        this.mediaService.uploadImage(img).subscribe(upload => {
          if (upload) {
            if (upload.imgLink) {
              const value = {
                docType: 'JPG', //'image',
                name: values.document_name,
                type: values.document_type,
                link: upload.imgLink,
                photo: this.base64Image.replace(/^data:image\/[a-z]+;base64,/, '')
              }
              this.documentsControls.push(this.newDoc(value));
              this.filesUploaded = true;
              this.showUpload2 = false;
              this.document_type.reset();
              this.document.reset();
              this.document_type.setValue('');
              $('#document').val('');
            } else {
              toast({ html: 'Something Went Wrong While uploading image document! Please Try again.', classes: 'red' });

            }
            this.loading = false;

          }
        });

      } else if (this.validateInput(this.binDoc.name) == 'pdf') {
        this.mediaService.uploadPDF(this.binDoc).subscribe(upload => {
          if (upload) {
            if (upload.imgLink) {
              const value = {
                docType: 'PDF',     //'pdf',
                name: values.document_name,
                type: values.document_type,
                link: upload.imgLink,
                photo: this.base64Image.replace(/^data:application\/[a-z]+;base64,/, '')
              }
              this.documentsControls.push(this.newDoc(value));
              this.filesUploaded = true;
              this.loading = false;
              this.showUpload2 = false;
              this.document_type.reset();
              this.document.reset();
              this.document_type.setValue('');
              $('#document').val('');
            } else {
              toast({ html: 'Something Went Wrong While uploading PDF document! Please Try again.', classes: 'red' });

            }
            this.loading = false;
          }
        });
      }
      this.showDocumentsType = false;
    }

    setTimeout(() => {
      $('#FileUploadModal').modal('close');
      $('select').formSelect();
      $('#DocumentsLoadModal').modal('open');
      M.updateTextFields();
    }, 25);

  }

  showUploadInput() {
    this.showUpload2 = true;
  }

  private validateInput(name: any) {
    const ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() === 'png') {
      return 'image';
    } else if (ext.toLowerCase() === 'jpg') {
      return 'image';
    } else if (ext.toLowerCase() === 'jpeg') {
      return 'image';
      //} else if (ext.toLowerCase() === 'mp4' || ext.toLowerCase() === 'mkv') {
      // return 'video';
    }
      else if (ext.toLowerCase() === 'svg') {
      return 'image';
    }
    else if (ext.toLowerCase() === 'webp') {
      return 'image';
    } else if (ext.toLowerCase() === 'pdf') {
      return 'pdf';
    } else {
      return '';
    }
  }

  removeFile(index) {
    const documents = this.documentsControls;
    documents.removeAt(index);
    if (documents.length == 0) {
      this.showUpload = false;
      //this.otherInfoForm.patchValue({ 'upload_documents': 'no' });
      this.otherInfoForm.patchValue({ 'upload_documents': '' });
    }
    toast({ html: 'File deleted!', classes: 'red' });

    this.fileType = '';
    this.base64Image = null;

    setTimeout(() => {
      $('select').formSelect();
      $('.collapsible').collapsible();
      $('.tooltipped').tooltip();
      M.updateTextFields();
    }, 25);
  }

  viewFile(index) {
    const documents = this.documentsControls;
    var doc_link = documents.value[index].link;
    if (doc_link) { window.open(doc_link); }
    else { toast({ html: 'Unable to open the document !', classes: 'red' }); }
  }

  cancelUploadFile() {
    const documents = this.documentsControls;
    if (documents.length == 0) {
      this.showUpload = false;
      this.otherInfoForm.patchValue({ 'upload_documents': 'no' });
    }

    this.fileType = '';
    this.base64Image = null;

    setTimeout(() => {
      $('select').formSelect();
      $('.collapsible').collapsible();
      $('.tooltipped').tooltip();
      M.updateTextFields();
    }, 25);
  }

  closeDocUpload() {
    this.filesUploaded = true;
    this.document.reset();
    this.document_type.reset()

    if (this.documentsControls.length == 0) {
      this.showUpload = false;
      this.otherInfoForm.get('upload_documents').patchValue('no');
    }
    setTimeout(() => {
      $('select').formSelect();
      $('#DocumentsLoadModal').modal('close');
      M.updateTextFields();
    }, 25);
    // this.documentsControls.setValue([]);
  }


  onNext() {

    this.step += 1;

    setTimeout(() => {
      $('select').formSelect();
      $('.collapsible').collapsible();
      $('.tooltipped').tooltip();
      $('.modal').modal();
      // $('.tabs').tabs();
      M.updateTextFields();
    }, 25);

    window.scrollTo(0, 0);

  }

  onBack() {

    if (this.step == 1) {
      this.backClicked();
      this.loading = false;
    }
    if (this.step > 1) { this.step -= 1; }

    setTimeout(() => {
      $('select').formSelect();
      $('.collapsible').collapsible();
      $('.tooltipped').tooltip();
      $('.modal').modal();
      //$('.tabs').tabs();
      M.updateTextFields();
    }, 25);
    window.scrollTo(0, 0);

    this.showSignaturePad = false;
  }

  onMyBack() {
    this.loading = false;
    this.step0 = true;
    this.step = -1;
    this.backClicked();
  }

  onMyBack1() {
    this.loading = false;
    this.step0 = true;
    this.step = -1;
  }

  editSection(section) {
    this.step = section;
    $(document).ready(function () {
      $('select').formSelect();
      $('.modal').modal();
      $('.tabs').tabs();
      $('.collapsible').collapsible();
      M.updateTextFields();
    });
  }

  onBack1() {
    this.step = 11;

    setTimeout(() => {
      $('select').formSelect();
      $('.collapsible').collapsible();
      $('.tooltipped').tooltip();
      $('.modal').modal();
      $('.tabs').tabs();
      M.updateTextFields();
    }, 25);

    window.scrollTo(0, 0);
    this.showSignaturePad = false;
  }

  gradeChange(value) {
  }

  resultChange(value) {
  }

  courseTermChange(value) {
  }

  coursesChange(value) {
  }

  InstitutionChange(value) {
  }

  ProgramChange(value) {
    this.subPrograms = [''];
    let program = value;
    if (program === 'Agriculture, Natural Resources and Conservation') {
      this.subPrograms = ['Agriculture, Agriculture Operations and Related Sciences', 'Natural Resources and Conservation'];

    } else if (program === 'Architecture, Engineering, and Related Technologies') {
      this.subPrograms = ['', 'Architecture and Related Services', 'Construction Trades', 'Engineering', 'Engineering Technologies/Technicians',
        'Historic Preservation and Conservation', 'Mechanic and Repair Technologies/Technicians', 'Precision Production'];

    } else if (program === 'Business, Management and Public Administration') {
      this.subPrograms = ['', 'Accounting and Computer Science', 'Business, Management, Marketing and Related Support Services', 'Public Administration and Social Service Professions'];

    } else if (program === 'Education') {
      this.subPrograms = ['', 'Education'];

    } else if (program === 'Health, Parks, Recreation and Fitness') {
      this.subPrograms = ['', 'Dental, Medical and Veterinary Residency Programs', 'Health Professions and Related Clinical Sciences', 'Parks, Recreation, Leisure and Fitness Studies'];

    } else if (program === 'Humanities') {
      this.subPrograms = ['', 'Aboriginal Languages', 'Classical and Ancient Studies', 'English Language and Literature/Letters',
        'Foreign Languages, Literatures and Linguistics', 'French Language and Literature/Letters',
        'History', 'Holocaust and Related Studies', 'Liberal Arts and Sciences, General Studies and Humanities',
        'Medieval and Renaissance Studies', 'Philosophy and Religious Studies', 'Theology and Religious Vocations'];

    } else if (program === "Mathematics, Computer and Information Sciences") {
      this.subPrograms = ['', 'Computer and Information Sciences and Support Services', 'Library Science', 'Mathematics and Computer Science',
        'Mathematics and Statistics', 'Systems Science and Theory'];

    } else if (program === "Personal Improvement and Leisure") {
      this.subPrograms = ['', 'Basic Skills', 'Citizenship Activities', 'Health-related Knowledge and Skills',
        'Interpersonal and Social Skills', 'Leisure and Recreational Activities', 'Personal Awareness and Self-improvement'];

    } else if (program === "Personal, Protective and Transportation Services") {
      this.subPrograms = ['', 'Military Technologies', 'Personal and Culinary Services', 'Reserve Entry Scheme for Officers in the Armed Forces',
        'Security and Protective Services', 'Transportation and Materials Moving'];

    } else if (program === "Physical and Life Sciences and Technologies") {
      this.subPrograms = ['', 'Biological and Biomedical Sciences', 'Biological and Physical Sciences', 'Natural Sciences',
        'Neuroscience', 'Nutrition Sciences', 'Physical Sciences', 'Science Technologies/Technicians'];

    } else if (program === "Social and Behavioural Sciences and Law") {
      this.subPrograms = ['', 'Area, Ethnic, Cultural and Gender Studies', 'Behavioural Sciences', 'Biopsychology',
        'Cognitive Science', 'Communication, Journalism and Related Programs', 'Family and Consumer Sciences/Human Sciences',
        'Gerontology', 'Intercultural/Multicultural and Diversity Studies', 'International/Global Studies',
        'Legal Professions and Studies', 'Museology/Museum Studies', 'Peace Studies and Conflict Resolution',
        'Psychology', 'Science, Technology and Society', 'Social Sciences'];

    } else if (program === "Visual and Performing Arts, and Communications Technologies") {
      this.subPrograms = ['', 'Communications Technologies/Technicians and Support Services', 'Visual and Performing Arts'];

    } else if (program === "Other") {
      this.subPrograms = ['', 'High School/Secondary Diploma and Certificate Programs', 'Multidisciplinary/Interdisciplinary Studies, Other', 'Technology Education/Industrial Arts Programs'];

      //  }else if (program ==="UCEP") {
      //   this.subPrograms  = ['', 'UCEP'];

    } else {
      this.subPrograms = [''];
    }

    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);

  }

  educationSoughtChange(value) {

    if (value == 'Other') {
      this.showOtherDetails = true;
    } else {
      this.showOtherDetails = false;
      this.eduPlanForm.get('other_details').reset();
    }

    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);

  }


  validate_sin(sin_num) {
    let sin: string = sin_num.toString();
    if (sin.length != 9) { return false; }

    var sin_array: number[] = new Array(9);
    for (let i = 0; i < 9; i++) {
      sin_array[i] = Number(sin.substring(i, i + 1));
      //console.log(sin_array[i])
    }

    let F1: number = sin_array[0];
    let F2: number = sin_array[1] + sin_array[1];
    let F3: number = sin_array[2];
    let F4: number = sin_array[3] + sin_array[3];
    let F5: number = sin_array[4];
    let F6: number = sin_array[5] + sin_array[5];
    let F7: number = sin_array[6];
    let F8: number = sin_array[7] + sin_array[7];
    let F9: number = sin_array[8];


    var no1: number;
    var no2: number;
    no1 = 0
    no2 = F1 + F3 + F5 + F7
    if (F2 > 9) { no1 = no1 + this.fix_number(F2); } else { no1 = no1 + F2; }
    if (F4 > 9) { no1 = no1 + this.fix_number(F4); } else { no1 = no1 + F4; }
    if (F6 > 9) { no1 = no1 + this.fix_number(F6); } else { no1 = no1 + F6; }
    if (F8 > 9) { no1 = no1 + this.fix_number(F8); } else { no1 = no1 + F8; }

    var total: number;
    var mod_result: number;
    total = no1 + no2;
    mod_result = total % 10;

    if (mod_result == 0 && F9 == 0) {
      return true;
    } else {
      var new_total_string: string = total.toString();
      var new_total: number = Number(new_total_string.substring(0, 1));
      new_total = new_total + 1
      new_total = new_total * 10
      if (new_total - total == F9) { return true; } else { return false; }
    }
  }

  fix_number(num: number) {
    if (num > 9) {
      var str: string = num.toString();
      var n1: number = Number(str.substring(0, 1));
      var n2: number = Number(str.substring(1, 2));
      return n1 + n2;
    } else {
      return num;
    }
  }

  submitStudentInfo(values) {

    this.studentModel.applicantID = this.applicantID;
    this.studentModel.firstName = this.member.GivenName;
    this.studentModel.lastName = this.member.LastName;
    this.studentModel.middle_name = this.member.middle_name;

    if (this.create_new_app) {
      this.studentModel.current_app_id = 0;
    } else { this.studentModel.current_app_id = this.pse_app_id; }

    if (this.member.DOB) { this.studentModel.dob = this.member.DOB; } else { this.studentModel.dob = ""; }
    this.studentModel.address = values.address;

    if (!values.city) {
      toast({ html: 'Please Enter City !', classes: 'red' });
      return;
    }
    this.studentModel.city = values.city;

    if (!values.province) {
      toast({ html: 'Please Select Province !', classes: 'red' });
      return;
    }
    this.studentModel.province = values.province;

    if (!values.postal_code) {
      toast({ html: 'Please Enter Postal Code !', classes: 'red' });
      return;
    }
    this.studentModel.postalCode = values.postal_code;

    if (!values.mobile) {
      toast({ html: 'Please Enter Mobile Number !', classes: 'red' });
      return;
    }
    this.studentModel.mobile = values.mobile;

    if (!values.marital_status) {
      toast({ html: 'Please Select Marital Status !', classes: 'red' });
      return;
    }
    this.studentModel.maritalStatus = values.marital_status;

    this.studentModel.Telephone = values.home_number;
    this.studentModel.email = values.email;

    if (this.member.SIN) {
      this.studentModel.SIN = values.SIN;
    } else {
      if (this.validate_sin(values.SIN)) {
        this.memberService.getSIN(values.SIN).subscribe(x => {
          if (x) {
            let count = x;
            if (count != 0) {
              toast({ html: 'SIN is used by other person !', classes: 'red' });

              this.step = 1;
              setTimeout(() => {
                $('select').formSelect();
                M.updateTextFields();
              }, 25);
              return;
            }
          }
        });

        this.studentModel.SIN = values.SIN;

      } else if (values.SIN == '') {
        this.studentModel.SIN = "";

      } else {
        toast({ html: 'SIN Number Incorrect !', classes: 'red' });
        return;
      }
    }


    if (this.member.HealthCareNo) {
      this.studentModel.HealthCareNo = values.HealthCareNo;
    } else {
      if (values.HealthCareNo) {
        this.memberService.getHealthCareNo(values.HealthCareNo).subscribe(x => {
          if (x) {
            let count = x;
            if (count != 0) {
              toast({ html: 'Health Care No is used by other person !', classes: 'red' });

              this.step = 1;
              setTimeout(() => {
                $('select').formSelect();
                M.updateTextFields();
              }, 25);

              return;
            }
          }
        });
        this.studentModel.HealthCareNo = values.HealthCareNo;
      } else { this.studentModel.HealthCareNo = ""; }

    }

    // this.studentModel.IndianStatusNo = values.isn_no;
    if (this.member.IndianStatusNo) {
      this.studentModel.IndianStatusNo = values.isn_no;
    } else {
      if (values.isn_no) {
        this.memberService.getISN(values.isn_no).subscribe(x => {
          if (x) {
            let count = x;
            if (count != 0) {
              toast({ html: 'Indian Status No is used by other person !', classes: 'red' });

              this.step = 1;
              setTimeout(() => {
                $('select').formSelect();
                M.updateTextFields();
              }, 25);

              return;
            }
          }
        });
        this.studentModel.IndianStatusNo = values.isn_no;
      } else { this.studentModel.IndianStatusNo = ""; }
    }

    if (values.reside) {
      if (values.reside === 'yes') {
        this.studentModel.reside = 'On Reserve';
      } else {
        this.studentModel.reside = 'Off Reserve';
      }
    } else {
      this.studentModel.reside = '';
    }


    if (!values.community) {
      toast({ html: 'Please Enter Community !', classes: 'red' });
      return;
    }
    this.studentModel.location = values.community;


    this.studentModel.other_location = values.other_location;
    this.studentModel.employed = values.employed;
    if (values.employer) { this.studentModel.employer = values.employer; } else { this.studentModel.employer = ''; }
    this.studentModel.leaveofabsence = values.leaveofabsence;
    this.studentModel.withpay = values.withpay;
    if (values.withpay == 'yes') {
      if (!values.income) {
        toast({ html: 'Please Enter My Income !', classes: 'red' });
        return;
      }
    }
    if (values.income) { this.studentModel.income = Number(values.income); } else { this.studentModel.income = 0; }


    if (values.marital_status == "Married or Equivalent") {
      if (values.spouse_classification) { this.studentModel.spouse_classification = values.spouse_classification; } else { this.studentModel.spouse_classification = ''; }
      if (values.spouse_income) { this.studentModel.spouse_income = Number(values.spouse_income); } else { this.studentModel.spouse_income = 0; }
    } else {
      this.studentModel.spouse_classification = '';
      this.studentModel.spouse_income = 0;
    }
    this.studentModel.spouse_aid = this.spouse_aid;

    this.studentModel.bank = values.bank_name;
    this.studentModel.bankno = values.bank_no;
    this.studentModel.account = values.account_no;
    this.studentModel.branch = values.transit_no;

    let house = values.house_status;
    if (house == '1') { this.studentModel.rent_required = '1'; this.studentModel.deposit_required = '0'; this.house_description = 'Rent Only'; }
    else if (house == '2') { this.studentModel.rent_required = '1'; this.studentModel.deposit_required = '1'; this.house_description = 'Rent and Damage Deposit'; }
    else if (house == '3') { this.studentModel.rent_required = '0'; this.studentModel.deposit_required = '0'; this.house_description = 'No Rent Required'; }
    else { toast({ html: 'Please Select Housing Requirement !', classes: 'red' }); return; }

    this.onNext();
  }

  submitFamilyMeber(values: any) {

    if (this.maritalStatusValue == 'Married' || this.maritalStatusValue == "Common Law" || this.maritalStatusValue == "Married or Equivalent") {
      this.HasSpouse = true;
    } else {
      this.HasSpouse = false;
    }

    // temporary disable add family members
    if (this.HasSpouse && !this.SpouseAdded) {
      toast({ html: 'Please Add Spouse !', classes: 'red' });
      return;
    }

    this.tmpMemberModel = values.childrens;
    this.onNext();
  }

  submitHighSchool(values: any) {

    //this.studentModel.applicantID = this.applicantID;
    this.studentModel.school = values.school;
    this.studentModel.school_year = Number(values.school_year);
    this.studentModel.grade = values.grade;
    this.studentModel.completed = values.completed;
    this.studentModel.transcript = values.transcripts;
    this.onNext();
  }


  submitCollege(values: any) {
    this.tmpCollegeModel = values.colleges;
    // console.log("college: ", this.tmpCollegeModel);
    this.onNext();
  }


  submitUniversity(values: any) {
    this.tmpUniversityModel = values.universitys;
    // console.log("university: ", this.tmpUniversityModel);
    this.onNext();
  }

  submitTraining(values: any) {
    this.tmpTrainingModel = values.trainings;
    this.onNext();
  }


  submitEduPlan(values: any) {

    this.studentModel.applicantID = this.applicantID;
    this.studentModel.objective = values.objective;
    this.studentModel.completion_date = values.completion_date.singleDate.jsDate.toDateString();
    this.studentModel.program = values.program;
    this.studentModel.institute = values.institution;
    if (values.t_years) { this.studentModel.t_years = values.t_years; } else { this.studentModel.t_years = ''; }

    this.studentModel.classification = values.classification;
    if (values.studentID) { this.studentModel.studentID = values.studentID; } else { this.studentModel.studentID = ""; }

    var term_selected = false;
    if (values.fall_term) {
      this.studentModel.fall_term = "fall";
      if (values.fall_tution_amt) { this.studentModel.fall_tution_amt = Number(values.fall_tution_amt); } else { this.studentModel.fall_tution_amt = 0; }
      if (values.fall_books_amt) { this.studentModel.fall_books_amt = Number(values.fall_books_amt); } else { this.studentModel.fall_books_amt = 0; }
      if (values.fall_other_amt) { this.studentModel.fall_other_amt = Number(values.fall_other_amt); } else { this.studentModel.fall_other_amt = 0; }
      term_selected = true;
    } else {
      this.studentModel.fall_term = "";
      this.studentModel.fall_tution_amt = 0;
      this.studentModel.fall_books_amt = 0;
      this.studentModel.fall_other_amt = 0;
    }

    if (values.winter_term) {
      this.studentModel.winter_term = "winter";
      if (values.winter_tution_amt) { this.studentModel.winter_tution_amt = Number(values.winter_tution_amt); } else { this.studentModel.winter_tution_amt = 0; }
      if (values.winter_books_amt) { this.studentModel.winter_books_amt = Number(values.winter_books_amt); } else { this.studentModel.winter_books_amt = 0; }
      if (values.winter_other_amt) { this.studentModel.winter_other_amt = Number(values.winter_other_amt); } else { this.studentModel.winter_other_amt = 0; }
      term_selected = true;
    } else {
      this.winter_term_checked = false;
      this.studentModel.winter_term = "";
      this.studentModel.winter_tution_amt = 0;
      this.studentModel.winter_books_amt = 0;
      this.studentModel.winter_other_amt = 0;
    }

    if (values.spring_term) {
      this.studentModel.spring_term = "spring";
      if (values.spring_tution_amt) { this.studentModel.spring_tution_amt = Number(values.spring_tution_amt); } else { this.studentModel.spring_tution_amt = 0; }
      if (values.spring_books_amt) { this.studentModel.spring_books_amt = Number(values.spring_books_amt); } else { this.studentModel.spring_books_amt = 0; }
      if (values.spring_other_amt) { this.studentModel.spring_other_amt = Number(values.spring_other_amt); } else { this.studentModel.spring_other_amt = 0; }
      term_selected = true;
    } else {
      this.spring_term_checked = false;
      this.studentModel.spring_term = "";
      this.studentModel.spring_tution_amt = 0;
      this.studentModel.spring_books_amt = 0;
      this.studentModel.spring_other_amt = 0;
    }

    if (values.summer_term) {
      this.studentModel.summer_term = "summer";
      if (values.summer_tution_amt) { this.studentModel.summer_tution_amt = Number(values.summer_tution_amt); } else { this.studentModel.summer_tution_amt = 0; }
      if (values.summer_books_amt) { this.studentModel.summer_books_amt = Number(values.summer_books_amt); } else { this.studentModel.summer_books_amt = 0; }
      if (values.summer_other_amt) { this.studentModel.summer_other_amt = Number(values.summer_other_amt); } else { this.studentModel.summer_other_amt = 0; }
      term_selected = true;
    } else {
      this.studentModel.summer_term = "";
      this.studentModel.summer_tution_amt = 0;
      this.studentModel.summer_books_amt = 0;
      this.studentModel.summer_other_amt = 0;
    }
    if (term_selected == false) {
      toast({ html: 'Please Select Term of Sponsorship !', classes: 'red' });
      return;
    }
    this.studentModel.subprogram = values.subprogram;
    this.studentModel.delivery_method = values.delivery_method;

    if (values.program_start_date) { this.studentModel.program_start_date = values.program_start_date.singleDate.jsDate.toDateString(); }
    if (values.education_sought) { this.studentModel.education_sought = values.education_sought; } else { this.studentModel.education_sought = ""; }
    if (values.other_details) { this.studentModel.other_details = values.other_details; } else { this.studentModel.other_details = ""; }

    //console.log("Edu Plan: ", this.eduPlanModel);
    this.onNext();
  }


  checkFall(checkbox) {
    var element = <HTMLInputElement>document.getElementById("fall_term");
    var isChecked = element.checked;
    if (isChecked) { this.fall_term_checked = true; } else { this.fall_term_checked = false; }
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }


  checkWinter(checkbox) {
    var element = <HTMLInputElement>document.getElementById("winter_term");
    var isChecked = element.checked;
    if (isChecked) { this.winter_term_checked = true; } else { this.winter_term_checked = false; }

    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }

  checkSpring(checkbox) {
    var element = <HTMLInputElement>document.getElementById("spring_term");
    var isChecked = element.checked;
    if (isChecked) { this.spring_term_checked = true; } else { this.spring_term_checked = false; }
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }

  checkSummer(checkbox) {
    var element = <HTMLInputElement>document.getElementById("summer_term");
    var isChecked = element.checked;
    if (isChecked) { this.summer_term_checked = true; } else { this.summer_term_checked = false; }
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }

  submitSupportHistory(values: any) {
    this.tmpHistoryModel = values.historys;

    this.tmpHistoryModel.forEach(c => {
      if (c.prior_support == "yes") {
        this.hasSupportHistory = true;
      }
    });

    this.onNext();
  }

  submitOtherInfo(values) {
    this.tmpDocumentModel = values.documents;
    this.onNext();
  }


  submitPreview() {
    this.onNext();
  }

  submitDeclaration(values) {
    /*
      this.studentModel.applicantName = values.name;
      if (!this.showSignaturePad ) {  
        this.studentModel.signature = '';
      } else {
        this.studentModel.signature = this.signaturePad.toDataURL().replace('data:image/png;base64,', '');
      }
      this.studentModel.date = values.date.singleDate.jsDate.toDateString();
    this.SubmitApplication();   ////////////////
      */
  }

  submitSection() {
    this.signRequired = true;
    setTimeout(() => {
      $('select').formSelect();
      $('.collapsible').collapsible();
      //$('.tooltipped').tooltip();
      //$('.modal').modal();
      // $('.tabs').tabs();
      M.updateTextFields();
    }, 25);
  }

  ///---new
  submitSection6(values) {

    if (this.signaturePad.isEmpty()) {
      toast({ html: 'Please Sign the Application!', classes: 'red' });
      return;
    } else {

      const dataUrl = this.signaturePad.toDataURL();
      this.signature.patchValue(dataUrl);
      this.signed = true;
      this.showSignaturePad = false;
      this.Enable_SubmitApp = true;
      toast({ html: 'Application Signed', classes: 'green' });

      this.studentModel.applicantName = values.name;
      this.studentModel.signature = this.signaturePad.toDataURL().replace('data:image/png;base64,', '');
      this.studentModel.date = values.date.singleDate.jsDate.toDateString();
      setTimeout(() => {
        $('.modal').modal();
        $('select').formSelect();
        $('#SignedModal').modal('open');
        M.updateTextFields();
      }, 25);
    }
  }

  //---- new
  submit_application() {
    if (this.Enable_SubmitApp == false) {
      toast({ html: 'Please answer if you want to sign the application !', classes: 'red' });
      return;
    } else {
      this.SubmitApplication();   ////////////////
    }
  }

  //---new
  ReadytoSubmitYes() {
    $('#SignedModal').modal('close');
    this.submit_application();
  }

  ReadytoSubmitNo() {
    $('#SignedModal').modal('close');
    // this.Enable_SubmitApp=false;
  }

  SubmitApplication() {
    this.loading = true;
    let t_dependents = 0;

    this.studentModel.familyMembers = [];
    this.tmpMemberModel.forEach(child => {

      this.aDependant = new Children();
      this.aDependant.applicantIDOfHead = child.applicantIDOfHead;
      this.aDependant.applicantIDOfChild = child.applicantIDOfChild;
      this.aDependant.given_name = child.given_name;
      this.aDependant.last_name = child.last_name;

      this.aDependant.Relationship = child.Relationship;
      this.aDependant.family_comp_id = 0;   //family_comp_id;
      this.aDependant.application_no = 0;
      this.aDependant.financial_contribution = 0;

      this.aDependant.dob = this.changeDates(child.dob).toDateString();

      if (child.Relationship === 'Spouse' || child.Relationship === 'Applicant') {
        this.aDependant.care_required = "";
        this.aDependant.child_care_level = -1;
        this.aDependant.child_care_category = -1;
      } else {
        this.aDependant.care_required = "";
        this.aDependant.child_care_level = this.childcare_level_to_int(child.child_care_level);
        this.aDependant.child_care_category = this.childcare_category_to_int(child.child_care_category);
        t_dependents = t_dependents + 1;
      }

      if (this.create_new_app) { this.aDependant.apply_date = new Date().toDateString(); } else { this.aDependant.apply_date = this.changeDates(this.last_apply_date).toDateString(); }

      this.aDependant.ChildHealthCareNo = child.ChildHealthCareNo;
      this.aDependant.ISNofChild = child.ISNofChild;
      this.aDependant.gender = child.gender;

      this.studentModel.familyMembers.push(this.aDependant);
    });

    this.studentModel.colleges = [];
    this.tmpCollegeModel.forEach(cl => {
      this.aInstitution = new PseCollege();
      this.aInstitution.applicantID = this.applicantID;
      this.aInstitution.institute = cl.institute;
      this.aInstitution.college_year = Number(cl.college_year);
      this.aInstitution.years = Number(cl.years);
      this.aInstitution.completed = cl.completed1;
      this.aInstitution.transcript = cl.transcripts1;
      this.aInstitution.funding_app_id = 0;
      this.studentModel.colleges.push(this.aInstitution);
    });

    this.studentModel.universities = [];
    this.tmpUniversityModel.forEach(cl => {
      this.aInstitution = new PseCollege();
      this.aInstitution.applicantID = this.applicantID;
      this.aInstitution.institute = cl.institute2;
      this.aInstitution.college_year = Number(cl.university_year);
      this.aInstitution.years = Number(cl.years2);
      this.aInstitution.completed = cl.completed2;
      this.aInstitution.transcript = cl.transcripts2;
      this.aInstitution.funding_app_id = 0;
      this.studentModel.universities.push(this.aInstitution);
    });

    this.studentModel.trainings = [];
    this.tmpTrainingModel.forEach(cl => {
      this.aInstitution = new PseCollege();
      this.aInstitution.applicantID = this.applicantID;
      this.aInstitution.institute = cl.institute3;
      this.aInstitution.college_year = Number(cl.training_year);
      this.aInstitution.years = 0;  // Number(cl.years2);  
      this.aInstitution.completed = cl.completed3;
      this.aInstitution.transcript = cl.transcripts3;
      this.aInstitution.funding_app_id = 0;
      this.studentModel.trainings.push(this.aInstitution);
    });

    this.studentModel.dependents = t_dependents;

    //support history 
    this.studentModel.support_historys = [];
    if (this.hasSupportHistory) {
      this.tmpHistoryModel.forEach(c => {
        this.aHistory = new PseSupportHistory();
        this.aHistory.applicantID = this.applicantID;
        this.aHistory.prior_support = "yes";
        this.aHistory.agency = c.agency;
        this.aHistory.support_year = c.support_year;
        this.aHistory.support_institute = c.support_institute;
        if (c.support_classification == "yes") { this.aHistory.support_classification = "1"; } else { this.aHistory.support_classification = "0"; }
        this.aHistory.funding_app_id = 0;   //pse_id;
        this.studentModel.support_historys.push(this.aHistory);
      });
    } else {
      this.aHistory = new PseSupportHistory();
      this.aHistory.applicantID = this.applicantID;
      this.aHistory.prior_support = "no";
      this.aHistory.agency = "";
      this.aHistory.support_year = 0;
      this.aHistory.support_institute = "";
      this.aHistory.support_classification = "";
      this.aHistory.funding_app_id = 0;
      this.studentModel.support_historys.push(this.aHistory);
    }

    //loading documents
    this.studentModel.documents = [];
    this.tmpDocumentModel.forEach(cl => {
      this.aDoc = new PseDocument();
      this.aDoc.applicantID = this.applicantID;
      this.aDoc.docType = cl.docType;
      this.aDoc.name = cl.name;
      this.aDoc.type = cl.type;
      this.aDoc.link = cl.link;
      if (cl.photo) { this.aDoc.photo = cl.photo; } else { this.aDoc.photo = ""; }
      this.aDoc.funding_app_id = 0;
      this.studentModel.documents.push(this.aDoc);
    });

    this.studentModel.spouse_aid = this.spouse_aid;
    this.studentModel.school_period = this.selected_school_period
    //console.log("student: ", this.studentModel );
    this.pseService.saveApplication(this.studentModel).subscribe(data => {
      if (data) {

        if (this.studentModel.email) {

          this.emailService.sendSubmitApplicationNotification(this.member.Email, this.member.GivenName).subscribe(e => {
            if (e) {
              var student_email = this.studentModel.email;
              student_email = student_email.toLowerCase();
              if (student_email.endsWith('nationadmin.ca') || student_email.endsWith('hl360.ca') || student_email.endsWith('taxexempt.ca')) {
                this.showMessage();
                this.loading = false;
              } else {
                let emails: string[];
                this.fireMembersService.getAdminAccounts().valueChanges().subscribe(x => {
                  if (x) {
                    emails = [];
                    x.forEach(o => {
                      if (o.Email == 'mirandad@lrrcn.ab.ca' || o.Email == 'marylou.grande@lrrcn.ab.ca' || o.Email == 'daled@lrrcn.ab.ca' || o.Email == 'colindas@lrrcn.ab.ca' || o.Email == 'ivyl@lrrcn.ab.ca') {
                        emails.push(o.Email);
                      }
                      // if (o.Email === 'michael@hl360.ca'){  emails.push(o.Email);  }   
                    });

                    this.emailService.sendApplicationNotificationToAdmin(this.Student_name, emails).subscribe(m => {
                      if (m) {
                        this.showMessage();
                        this.loading = false;
                      }
                    });
                  }
                });
              }
            }
          });
        }
      }
    });
  }

  backClicked() {
    this.location.back();
  }

  backClicked2() {
    $('#messageModal').modal('close');
    this.location.back();
    //window.location.reload();
  }

  sign() {
    this.showSignaturePad = true;
    setTimeout(() => {
      this.canvas = document.querySelector('canvas');
      const parentWidth = $(this.canvas).parent().outerWidth();
      this.canvas.setAttribute('width', parentWidth);
      this.canvas.setAttribute('height', '175px');
      this.signaturePad = new SignaturePad(this.canvas, {
        backgroundColor: 'rgb(255,255,255)'
      });
    }, 50);
  }

  approveSignature() {
    if (this.signaturePad.isEmpty()) {
      toast({ html: 'Please Sign the Application!', classes: 'red' });
      return;
    } else {

      const dataUrl = this.signaturePad.toDataURL();
      this.signature.patchValue(dataUrl);
      this.signed = true;
      this.showSignaturePad = false;
      this.Enable_SubmitApp = true;
      toast({ html: 'Application Signed', classes: 'green' });

      setTimeout(() => {
        $('.modal').modal();
        $('select').formSelect();
        $('#SignedModal').modal('open');
        M.updateTextFields();
      }, 25);
    }
  }

  clearSignature() {
    this.signed = false;
    this.signaturePad.clear();
    this.signature.reset();
    this.Enable_SubmitApp = false;
  }

  onBacktoDeclaration() {
    this.showSignaturePad = false;
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }

  onDateChange1(event: IMyDateModel): void {
    if (this.eduPlanForm) { this.eduPlanForm.patchValue({ completion_date: event.singleDate.formatted }); }
  }

  onDateChange3(event: IMyDateModel): void {
    if (this.eduPlanForm) { this.eduPlanForm.patchValue({ program_start_date: event.singleDate.formatted }); }
  }

  onDateChange6(event: IMyDateModel): void {
    //  (< FormGroup > this.coursePlanForm.controls['course']).controls['start_date'].patchValue(event.singleDate.formatted);
  }

  onDateChange7(event: IMyDateModel): void {
    //  (< FormGroup > this.coursePlanForm.controls['course']).controls['end_date'].patchValue(event.singleDate.formatted);
  }

  onDateChange8(event: IMyDateModel): void {
    this.declarationForm.patchValue({ 'date': event.singleDate.formatted });
  }

  onSignYes($event) {
    this.Enable_SubmitApp = true;
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);

    this.sign();
  }

  onSignNo($event) {
    this.Enable_SubmitApp = true;
    this.studentModel.applicantName = this.Student_name;
    this.studentModel.signature = "";
    this.studentModel.date = "";
    this.showSignaturePad = false;

    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
    //this.onNext();
  }
  showMessage() {
    setTimeout(() => {
      M.updateTextFields();
      $('.modal').modal();
      $('#messageModal').modal('open');
      $('.tooltipped').tooltip();
    }, 25);
  }


  childcare_level_to_int(level: string) {
    if (level === 'Infant (18 months and under)') {
      return 1;
    } else if (level === 'Toddler (19 months up to Grade 1)') {
      return 2;
    } else if (level === 'Grades 1 to 6') {
      return 3;
    } else if (level === 'Dependent Needing Care (Any age)') {
      return 4;
    } else {
      return -1;
    }
  }

  childcare_category_to_int(category: string) {
    if (category === 'Licensed Day Care, Out-of-School Care') {
      return 1;
    } else if (category === 'Approved Family Day Home, Licensed Group Family Child Care') {
      return 2;
    } else if (category === 'Extended Hours Care') {
      return 3;
    } else if (category === 'Kin Care') {
      return 4;
    } else {
      return -1;
    }
  }
}