import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

// Modules
import { AdminModule } from './comp/admin/admin.module';
import { MemberModule } from './comp/member/member.module';
import { SharedModule } from './comp/shared/shared.module';
import { AuthModule } from './comp/auth/auth.module';


// Component
import { HeaderComponent } from './comp/navigation/header/header.component';
import { MemberService } from './services/api/member.service';

// Firebase
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestore, AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';

import { LoaderInterceptorService } from './interceptors/loader-interceptor.service';
import { MemberAuthGuard } from './guards/member-auth.guard';
import { FooterComponent } from './comp/navigation/footer/footer.component';
// import { SortablejsModule } from 'ngx-sortablejs';
import { AdminAuthGuard } from './guards/admin-auth.guard';
import { EmployerModule } from './comp/employer/employer.module';
import { EmployerOnlyComponent } from './comp/app/Employer/employer-only/employer-only.component';
import { SkillsDatabankComponent } from './comp/app/Employer/skills-databank/skills-databank.component';
import { KeyWordsHighlight1 } from './Pipes/KeyworkdHighLight1';
import { CreateAccountComponent } from './comp/app/Employer/create-account/create-account.component';
import { RegistrationRequestComponent } from './comp/app/registration-request/registration-request.component';
import { EmailService } from './services/api/email.service';
import { HomePageComponent } from './comp/app/home/home-page/home-page.component';
import { ReadMoreComponent } from './comp/app/home/read-more/read-more.component';
// import { EmbedVideo } from 'ngx-embed-video';
// import { ShareButtonsModule } from '@ngx-share/buttons';
import { Platform } from '@angular/cdk/platform';

import { DepartmentsComponent } from './comp/app/departments/departments.component';
import { AngularFireMessagingModule } from '@angular/fire/compat/messaging';
import { TermsConditionsComponent } from './comp/app/terms-conditions/terms-conditions.component';
import { PrivacyPolicyComponent } from './comp/app/privacy-policy/privacy-policy.component';
import { EulaComponent } from './comp/app/eula/eula.component';
import { FCMpushService } from './services/firebase/FCMpush.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NewsComponent } from './comp/app/news/news.component';
import { HomeSubMenuComponent } from './comp/app/home/home-sub-menu/home-sub-menu.component';
import { GovernanceComponent } from './comp/app/governance/governance.component';
import { AboutUsComponent } from './comp/app/about-us/about-us.component';
import { ManageSubDepartmentComponent } from './comp/app/departments/manage-sub-department/manage-sub-department.component';
import { ViewDepartmentInfoComponent } from './comp/app/departments/view-department-info/view-department-info.component';
import { ViewSubdepartmentInfoComponent } from './comp/app/departments/view-subdepartment-info/view-subdepartment-info.component';
// import { TextMaskModule } from '../../node_modules/angular2-text-mask';
import { InputMaskModule } from '@ngneat/input-mask';
import { ViewAboutSectionComponent } from './comp/app/about-us/view-about-section/view-about-section.component';
import { DepNewsListComponent } from './comp/app/departments/department-news/dep-news-list/dep-news-list.component';
import { ReadDepNewsComponent } from './comp/app/departments/department-news/read-dep-news/read-dep-news.component';
import { DepSubMenuComponent } from './comp/app/departments/dep-sub-menu/dep-sub-menu.component';
import { EmployeeModule } from './comp/employee/employee.module';
import { ContactNationComponent } from './comp/app/contact-nation/contact-nation.component';
import { ContactDepartmentPublicComponent } from './comp/app/departments/contact-department-public/contact-department-public.component';
// import { DemoVideosComponent } from './comp/app/demo-videos/demo-videos.component';
// import { DemoVideoDetailComponent } from './comp/app/demo-videos/demo-video-detail/demo-video-detail.component';

import { EventCalendarComponent } from './comp/app/events/event-calendar/event-calendar.component';
import { EventDetailsComponent } from './comp/app/events/event-details/event-details.component';
import { LandingComponent } from './comp/app/landing/landing.component';
import { GovMemberDetailComponent } from './comp/app/governance/gov-member-detail/gov-member-detail.component';
import { GovChiefDetailComponent } from './comp/app/governance/gov-chief-detail/gov-chief-detail.component';

// import { AngularMyDatePickerModule } from 'angular-mydatepicker';
import { MoreMenuComponent } from './comp/app/more-menu/more-menu.component';
import { IncomeSupportApplicationComponent } from './comp/app/income-support-application/income-support-application.component';
import { CustomizableIncomeFormComponent } from './comp/app/customizable-income-form/customizable-income-form.component';
import { CreateApplicationComponent } from './comp/app/create-application/create-application.component';
import { SafeUrlPipe } from './Pipes/SafeUrlPipe';
import { PollsListComponent } from './comp/app/polls/polls-list/polls-list.component';
import { PollResultComponent } from './comp/app/polls/poll-result/poll-result.component';
import { BoardsListPublicComponent } from './comp/app/boards/boards-list-public/boards-list-public.component';
import { BoardsDetailComponent } from './comp/app/boards/boards-detail/boards-detail.component';
import { SurveyListComponent } from './comp/app/survey/survey-list/survey-list.component';
import { SurveyResultsComponent } from './comp/app/survey/survey-results/survey-results.component';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { ElectionResultComponent } from './comp/app/election/election-result/election-result.component';
import { LangingPageComponent } from './comp/app/landing/langing-page/langing-page.component';
import { MoreComponent } from './comp/navigation/more/more.component';
import { IsetRegistrationFormComponent } from './comp/app/iset-registration-form/iset-registration-form.component';
import { OptionalLandingPageComponent } from './comp/app/landing/optional-landing-page/optional-landing-page.component';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { AppSettingsService } from './services/firebase/app-settings.service';
import { TestComponent } from './comp/admin/test/test.component';
import { ImageCropperModule } from 'ngx-image-cropper';
import { CustomSectionComponent } from './comp/app/custom-section/custom-section.component';
import { SectionDetailComponent } from './comp/app/custom-section/section-detail/section-detail.component';
import { CommonModule } from '@angular/common';
import { MemberHomeComponent } from './comp/member/member-home/member-home.component';
import { SurveyDetailComponent } from './comp/member/pollAnsSurvey/survey-detail/survey-detail.component';
import { MemberRoutingModule } from './comp/member/member-routing.module';
import { AdminRoutingModule } from './comp/admin/admin-routing.module';
import { AngularMyDatePickerModule } from '@nodro7/angular-mydatepicker';
import { NgxMaskDirective , provideNgxMask } from 'ngx-mask';
import { CalendarDateFormatter, CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { CustomDateFormatter } from './models/custom-date-formatter.provider';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';


@NgModule({ declarations: [
        AppComponent,
        HeaderComponent,
        FooterComponent,
        EmployerOnlyComponent,
        SkillsDatabankComponent,
        KeyWordsHighlight1,
        CreateAccountComponent,
        RegistrationRequestComponent,
        HomePageComponent,
        ReadMoreComponent,
        DepartmentsComponent,
        TermsConditionsComponent,
        PrivacyPolicyComponent,
        EulaComponent,
        NewsComponent,
        HomeSubMenuComponent,
        GovernanceComponent,
        AboutUsComponent,
        ManageSubDepartmentComponent,
        ViewDepartmentInfoComponent,
        ViewSubdepartmentInfoComponent,
        ViewAboutSectionComponent,
        DepNewsListComponent,
        ReadDepNewsComponent,
        DepSubMenuComponent,
        ContactNationComponent,
        ContactDepartmentPublicComponent,
        EventCalendarComponent,
        EventDetailsComponent,
        LandingComponent,
        GovMemberDetailComponent,
        GovChiefDetailComponent,
        MoreMenuComponent,
        IncomeSupportApplicationComponent,
        CustomizableIncomeFormComponent,
        CreateApplicationComponent,
        PollsListComponent,
        PollResultComponent,
        BoardsListPublicComponent,
        BoardsDetailComponent,
        SurveyListComponent,
        SurveyResultsComponent,
        ElectionResultComponent,
        LangingPageComponent,
        MoreComponent,
        IsetRegistrationFormComponent,
        OptionalLandingPageComponent,
        TestComponent,
        CustomSectionComponent,
        SectionDetailComponent,
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        CommonModule,
        AppRoutingModule,
        MemberRoutingModule,
        AdminRoutingModule,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
        AngularFireAuthModule,
        AngularFirestoreModule,
        AngularFireMessagingModule,
        AngularFireModule.initializeApp(environment.firebase),
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        AdminModule,
        MemberModule,
        EmployerModule,
        EmployeeModule,
        AuthModule,
        // SortablejsModule.forRoot({ animation: 150 }),
        // EmbedVideo.forRoot(),
        // ShareButtonsModule,
        BrowserAnimationsModule,
        // TextMaskModule,
        InputMaskModule.forRoot(),
        NgxSliderModule,
        ImageCropperModule,
        AngularMyDatePickerModule,
        NgxMaskDirective,
        CKEditorModule
        // CalendarModule.forRoot({
        //   provide: DateAdapter,
        //   useFactory: adapterFactory,
        // }),
    ], providers: [MemberService, MemberAuthGuard, AdminAuthGuard, EmailService, Platform, FCMpushService, SafeUrlPipe, provideNgxMask(), [
            {
                provide: HTTP_INTERCEPTORS,
                useClass: LoaderInterceptorService,
                multi: true,
            }
        ], provideHttpClient(withInterceptorsFromDi()),] })

export class AppModule {
  constructor() { }
  // constructor(private afs: AngularFirestore) {
  //   const firestore = afs.firestore;
  
  //   const settings = { experimentalForceLongPolling: true };
  //   firestore.settings(settings);
  // }
}
