<div class="navbar-fixed">
  <nav [ngClass]="{ 'red-class': isLargeScreen, 'black-class': !isLargeScreen }">
    <!-- sliderSection -->
    <div class="nav-wrapper" [style.background-color]="
      isLogged || (!isLogged && !isLargeScreen) ? themeColor : 'black lighten-4'
    " [ngClass]="{
      'logged-in': isLogged,
      'not-logged-in': !isLogged && isLargeScreen,
      'not-logged-in-small': !isLogged && !isLargeScreen
    }">
      <!-------------------- After Logged In------------------->
      <div [ngClass]="{
          'container-logo': logoType === 'bothNationLogo',
          container10: logoType !== 'bothNationLogo'
        }" *ngIf="isLogged">
        <div class="p-t-0">
          <ng-container *ngIf="logoOption === 'logoOnly' && logo">
            <img *ngIf="logo" class="responsive-img brand-logo" [src]="logo"
              style="height: 100%; object-fit: contain" />
          </ng-container>

          <ng-container *ngIf="logoOption === 'nationOnly'">
            <a [routerLink]="[]" class="brand-logo" style="font-size: 20px">{{
              nationName
              }}</a>
          </ng-container>

          <ng-container *ngIf="logoOption === 'bothNationLogo' && logo">
            <img *ngIf="logo" class="responsive-img brand-logo" width="70" [src]="logo"
              style="height: 100%; object-fit: contain" />
            <a [routerLink]="[]" class="brand-logo p-l-9" style="font-size: 20px">{{ nationName }}</a>
          </ng-container>
        </div>

        <div *ngIf="isLogged && role === 'Admin'">
          <div class=" ">
            <a class="btn backed-button left hide-on-med-and-up" (click)="backAdminClicked()" style="margin-top: 10px">
              <i class="material-symbols-outlined left" style="
                  margin-right: 5px;
                  color: white;
                  font-weight: 800;
                  vertical-align: middle;
                ">arrow_back_ios</i>
            </a>
          </div>
          <div class="nav-wrapper">
            <ul class="right hide-on-med-and-down">
              <li>
                <a (click)="navigate('/admin/social-media')">
                  <strong class="white-text">Social Media</strong>
                </a>
              </li>
              <li>
                <a (click)="signOut()">
                  <strong class="white-text">Logout</strong>
                </a>
              </li>
            </ul>
          </div>

          <div id="permissionModal" class="modal center b-n-1">
            <div class="modal-content">
              <i class="material-icons right modal-close">close</i><br />
              <h6 class="title-section2 bold-600 black-text">Unauthorized</h6>
              <p class="sub-title-section bold-100">
                Sorry, but you don't have permission to access this page.
              </p>
              <img src="https://img.icons8.com/dotty/344/id-not-verified.png" height="100px" />
              <p class="sub-title-section bold-100">
                Please Contanct your Administrator to gain access.
              </p>
            </div>
          </div>
        </div>

        <div *ngIf="isLogged && role === 'Member'">
          <div class="nav-wrapper">
            <a href="#" data-target="member-mobile-navbar" [class]="'sidenav-trigger ' + rightOrLeftSidebar"><i
                class="material-icons">menu</i></a>
            <ul class="right hide-on-med-and-down">
              <li>
                <a (click)="navigate('/member/member-main-home/member-home')">Home</a>
              </li>
              <li>
                <a (click)="navigate('/member/member-main-home/job-board')">Job Board
                </a>
              </li>
              <li>
                <a (click)="navigate('/member/member-main-home/messages')">My Chat/Circle</a>
              </li>
              <li>
                <a (click)="navigate('/member/member-main-home/contact-us')">Nation Contact</a>
              </li>
              <li><a (click)="signOut()">Logout</a></li>
            </ul>
          </div>
        </div>

        <div *ngIf="
            isLogged &&
            role === 'Department-Employee' &&
            navbarOption === 'sidenavbar'
          ">
          <div class="nav-wrapper">
            <a href="#" data-target="employee-mobile-navbar" [class]="'sidenav-trigger ' + rightOrLeftSidebar"><i
                class="material-icons">menu</i></a>
            <ul class="right hide-on-med-and-down">
              <li><a (click)="navigate('/employee/account')">My Account</a></li>
              <li><a (click)="navigate('/employee/inbox')">My Inbox</a></li>
              <li>
                <a (click)="navigate('/employee/department-info')">Information</a>
              </li>
              <li>
                <a (click)="navigate('//employee/department-news-list')">News / Events</a>
              </li>
              <li>
                <a (click)="navigate('/employee/employee-list')">Employees</a>
              </li>
              <li><a (click)="signOut()">Logout</a></li>
            </ul>
          </div>
        </div>

        <div *ngIf="
            isLogged && role === 'Employer' && navbarOption === 'sidenavbar'
          ">
          <div class="nav-wrapper">
            <a href="#" data-target="employer-mobile-navbar" [class]="'sidenav-trigger ' + rightOrLeftSidebar"><i
                class="material-icons">menu</i></a>
            <ul class="right hide-on-med-and-down">
              <li>
                <a (click)="navigate('/employer/search-skills')">Search Our Skills Databank</a>
              </li>
              <li>
                <a (click)="navigate('/employer/post-job')">Post A Job Offer</a>
              </li>
              <li>
                <a (click)="navigate('/employer/my-contracts')">My Contracts</a>
              </li>
              <li>
                <a (click)="navigate('/employer/job-archived')">Archived Job Offer</a>
              </li>
              <li><a (click)="signOut()">Logout</a></li>
            </ul>
          </div>
        </div>

        <a [routerLink]="['/home']" *ngIf="!isLogged" class="right"><i class="material-icons white-text">house</i></a>
        <a [routerLink]="['/employee/home']" *ngIf="
            isLogged &&
            role === 'Department-Employee' &&
            (navbarOption === 'bottomnavbar' || !navbarOption)
          " class="right"><i class="material-icons white-text">house</i></a>
        <a [routerLink]="['/employer/home']" *ngIf="
            isLogged &&
            role === 'Employer' &&
            (navbarOption === 'bottomnavbar' || !navbarOption)
          " class="right"><i class="material-icons white-text">house</i></a>
      </div>

      <!------------------ Before Logged In --------------------->
      <div *ngIf="!isLogged">
        <div class="btn backed-button black-text left hide-on-med-and-up p-m-1" (click)="backPublicClicked()">
          <i class="material-symbols-outlined left" style="
              margin-right: 5px;
              color: white;
              font-weight: 800;
              vertical-align: middle;
            ">arrow_back_ios</i>
        </div>

        <div class="hide-on-med-and-down logo-img">
          <ng-container *ngIf="logoOption === 'logoOnly' && logo">
            <a (click)="navigate('')">
              <img *ngIf="logo" class="responsive-img brand-logo" [src]="logo" [ngClass]="
                  { right: 'left ll-m-5', left: 'right .rr-m-5 ' }[
                    desktopView?.menuPosition
                  ]
                " style="height: 100%; object-fit: contain" />
            </a>
          </ng-container>

          <ng-container *ngIf="logoOption === 'nationOnly'">
            <a (click)="navigate('')" class="brand-logo col l2 small" style="font-size: 20px" [ngClass]="
                { right: 'left p-l-5', left: 'right p-r-5' }[
                  desktopView?.menuPosition
                ]
              ">{{ nationName }}</a>
          </ng-container>

          <ng-container *ngIf="logoOption === 'bothNationLogo' && logo">
            <div class="logo-and-nation-container">
              <a (click)="navigate('')" [ngClass]="
                  { right: 'left p-l-5', left: 'right p-r-6' }[
                    desktopView?.menuPosition
                  ]
                " class="brand-logo" style="display: flex; align-items: center">
                <img *ngIf="logo" class="responsive-img col l10" width="" [src]="logo"
                  style="height: 65px; object-fit: contain" />

                <a class="col l2 small" style="font-size: 20px">{{
                  nationName
                  }}</a>
              </a>
            </div>
          </ng-container>
        </div>

        <!-- <div class="p-t-0 hide-on-med-and-up  mobile-nav-setup">
          <ng-container>
            <a (click)="navigate('')" *ngIf="logo">
              <img class="responsive-img  hide-on-med-and-up col l3" [src]=" logo" style=" height:8vh; object-fit: cover;">
            </a>
  
            <a [routerLink]="[]" class="nation-info-logo col l9" style="font-size: 20px;"   >{{ nationName }}</a>
          </ng-container>
        </div> -->

        <div class="p-t-0 hide-on-large-only logo-img">
          <ng-container *ngIf="logoOption === 'logoOnly' && logo">
            <a (click)="navigate('')">
              <img *ngIf="logo" class="responsive-img brand-logo centered" [src]="logo"
                style="height: 100%; object-fit: cover" />
            </a>
          </ng-container>

          <ng-container *ngIf="logoOption === 'nationOnly'">
            <a (click)="navigate('')" class="brand-logo col l2 small center" style="font-size: 20px">{{ nationName
              }}</a>
          </ng-container>

          <ng-container *ngIf="logoOption === 'bothNationLogo' && logo">
            <a (click)="navigate('')" class="brand-logo" style="display: flex; align-items: center">
              <img class="responsive-img col l10" [src]="logo" style="height: 65px; object-fit: cover"  alt="both image"/>
              <a [routerLink]="[]" class="col l2 small" style="font-size: 20px">{{ nationName }}</a>
            </a>
          </ng-container>
        </div>

        <!-- *************************** LARGE SCREEN *****************************************************-->
        <div class="hide-on-med-and-down">
          <div class="row">
            <div class="col s12 m12 l12">
              <div class="nav-wrapper" *ngIf="menus">
                <!----APP menu setting      l8 xl7-->

                <ul class="hide-on-med-and-down col l8 xl7" [ngClass]="
                    { right: 'right', left: 'left' }[desktopView?.menuPosition]
                  ">
                  <li class="center" class="custom-col">
                    <a (click)="navigateToHome()">Home </a>
                  </li>

                  <div *ngFor="let item of menus; let i = index">
                    <li class="center" class="custom-col" [ngClass]="
                        { '4': 'menu5', '5': 'menu6', '6': 'menu7' }[
                          desktopView?.numberOfMenus
                        ]
                      " *ngIf="
                        i < desktopView?.numberOfMenus - 2 && item.isEnabled
                      ">
                      <a (click)="navigate(item.link, item)">{{ item.displayName }}
                      </a>
                    </li>
                  </div>

                  <!-- *ngIf="menus.length > 4" -->
                  <li class="custom-col" [ngClass]="
                      { '4': 'menu5', '5': 'menu6', '6': 'menu7' }[
                        desktopView?.numberOfMenus
                      ]
                    ">
                    <a class="dropdown-trigger white-text icon-text" data-target="menu-dropdown"
                      data-activates="menu_escrow_accounts" data-beloworigin="true">
                      More<i class="material-icons no-margin">expand_more</i></a>

                    <ul id="menu-dropdown" class="dropdown-content white" [ngStyle]="{ 'border-color': themeColor }">
                      <div *ngFor="let item of menus; let i = index">
                        <li *ngIf="
                            i >= desktopView?.numberOfMenus - 2 &&
                            item.isEnabled
                          ">
                          <a (click)="navigate(item.link, item)">{{
                            item.displayName
                            }}</a>
                        </li>
                      </div>
                    </ul>
                  </li>

                  <li class="custom-col" [ngClass]="
                      { '3': 'menu4', '4': 'menu5', '5': 'menu6' }[
                        desktopView?.numberOfMenus
                      ]
                    " *ngIf="favoriteMenu">
                    <a (click)="navigate(favoriteMenu.link, favoriteMenu)" class="btn btn-header"
                      style="font-size: 12px">{{ favoriteMenu.name }}</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <!-- *************************** SMALL SCREEN *****************************************************-->
        <!-- top-nav -->
        <div class="top-nav hide-on-med-and-up" [ngStyle]="{ 'background-color': themeColor }"
          *ngIf="mobileView && mobileView.menuPosition == 'top'">
          <ng-template [ngTemplateOutlet]="small_menus"></ng-template>
        </div>

        <!-- bottom-nav -->
        <div class="bottom-nav hide-on-med-and-up" [ngStyle]="{ 'background-color': themeColor }"
          *ngIf="mobileView && mobileView.menuPosition == 'bottom'">
          <ng-template [ngTemplateOutlet]="small_menus"></ng-template>
        </div>

        <ng-template #small_menus>
          <div class="row" *ngIf="menus">
            <div class="nav-wrapper">
              <ul class="center" style="cursor: pointer">
                <div *ngFor="let item of menus; let i = index">
                  <li *ngIf="i < mobileView?.numberOfMenus - 1 && item.isEnabled" class="col custom-col grid-example"
                    [ngClass]="
                      { '3': 'menu3', '4': 'menu4', '5': 'menu5' }[
                        mobileView?.numberOfMenus
                      ]
                    ">
                    <i class="material-symbols-outlined" (click)="navigate(item.link, item)">{{ item.icon
                      }}<a style="font-size: 12px">{{ item.displayName }}</a></i>
                  </li>
                </div>

                <li class="col custom-col grid-example" *ngIf="menus.length > 4" [ngClass]="
                    { '3': 'menu3', '4': 'menu4', '5': 'menu5' }[
                      mobileView?.numberOfMenus
                    ]
                  ">
                  <i class="material-symbols-outlined" (click)="navigate('/more-sidenav')">menu<a
                      style="font-size: 12px">More</a></i>
                </li>
              </ul>
            </div>
          </div>
        </ng-template>

        <!-- *************************** Middle SCREEN *****************************************************-->

        <!--  left-side-nav -->
        <div class="left-nav" [ngStyle]="{ 'background-color': themeColor }"
          *ngIf="tabletView && tabletView.menuPosition == 'left'">
          <ng-template [ngTemplateOutlet]="middle_menus"></ng-template>
        </div>

        <!-- right-side-nav -->
        <div class="right-nav" [ngStyle]="{ 'background-color': themeColor }"
          *ngIf="tabletView && tabletView.menuPosition == 'right'">
          <ng-template [ngTemplateOutlet]="middle_menus"></ng-template>
        </div>

        <ng-template #middle_menus>
          <div class="row" *ngIf="menus">
            <ul id="slide-out" class="side-nav fixed hide-on-small-only hide-on-large-only center"
              [ngStyle]="{ 'background-color': themeColor }">
              <div *ngFor="let item of menus; let i = index">
                <li *ngIf="item.isEnabled" class="col m12">
                  <i class="material-symbols-outlined" data-position="top" data-tooltip="item.displayName"
                    (click)="navigate(item.link, item)">{{ item.icon }}</i>
                </li>
              </div>
            </ul>
          </div>
        </ng-template>
      </div>
    </div>
  </nav>
</div>

<ul class="sidenav" id="public-mobile-navbar">
  <div *ngIf="menus">
    <ul class="collapsible" *ngIf="menus && showMenus">
      <li *ngFor="let item of menus">
        <div *ngIf="
            item.name !== 'SocialMedia' &&
            item.name !== 'Demo' &&
            item.name !== 'Governance' &&
            item.isEnabled
          " class="collapsible-header" (click)="navigate(item.link)">
          <i class="material-icons">{{ item.icon }}</i>
          <strong>{{ item.displayName }} </strong>
        </div>
        <div *ngIf="
            (item.name === 'SocialMedia' ||
              item.name === 'Demo' ||
              item.name === 'Governance') &&
            item.isEnabled
          " class="collapsible-header">
          <i class="material-icons">{{ item.icon }}</i>
          <strong>{{ item.displayName }} </strong>
        </div>

        <div class="collapsible-body">
          <div *ngIf="item.name === 'SocialMedia' && socialMedia && item.isEnabled" class="row col s12"
            style="margin-left: 10%">
            <a class="col s12 hoverable" [href]="socialMedia.facebook" target="_blank" style="margin-right: 15%">
              <span [ngStyle]="{ color: item.color }"> Facebook </span>
            </a>

            <a class="col s12 hoverable" [href]="socialMedia.instagram" target="_blank" style="margin-right: 15%">
              <span [ngStyle]="{ color: item.color }"> Instagram </span>
            </a>

            <a class="col s12 hoverable" [href]="socialMedia.youtube" target="_blank" style="margin-right: 15%">
              <span [ngStyle]="{ color: item.color }"> Youtube </span>
            </a>

            <a class="col s12 hoverable" [href]="socialMedia.twitter" target="_blank" style="margin-right: 15%">
              <span [ngStyle]="{ color: item.color }"> Twitter </span>
            </a>
          </div>

          <div *ngIf="item.name === 'Demo' && item.isEnabled" class="row col s12" style="margin-left: 10%">
            <a class="col s12 hoverable" (click)="memberAccountClick()" style="margin-right: 15%">
              <span [ngStyle]="{ color: item.color }"> Member Account </span>
            </a>

            <a (click)="adminAccountClick()" class="col s12 hoverable" style="margin-right: 15%">
              <span [ngStyle]="{ color: item.color }"> Admin Account </span>
            </a>
          </div>

          <div *ngIf="item.name === 'Governance' && item.isEnabled" class="row col s12" style="margin-left: 10%">
            <a class="col s12 hoverable" (click)="chiefClicked()" style="margin-right: 15%">
              <span [ngStyle]="{ color: item.color }"> Chief </span>
            </a>

            <a class="col s12 hoverable" (click)="councillorsClicked()" style="margin-right: 15%">
              <span [ngStyle]="{ color: item.color }"> Councillors </span>
            </a>

            <a class="col s12 hoverable" [routerLink]="['/boards-list']" style="margin-right: 15%">
              <span [ngStyle]="{ color: item.color }"> Boards </span>
            </a>
          </div>
        </div>
      </li>
    </ul>
  </div>

  <div *ngIf="!menus">
    <ul class="collapsible" *ngIf="!menus && showMenus">
      <li>
        <div class="collapsible-header" (click)="navigate('/home')">
          <i class="material-icons blue-text">house</i>
          <strong>Home</strong>
        </div>
        <div class="collapsible-body"></div>
      </li>

      <li>
        <div class="collapsible-header" (click)="navigate('/news')">
          <i class="material-icons pink-text text-darken-2">circle_notifications</i>
          <strong>News</strong>
        </div>
        <div class="collapsible-body"></div>
      </li>

      <li>
        <div class="collapsible-header" (click)="navigate('/auth/login')">
          <i class="material-icons green-text">login</i>
          <strong>Login</strong>
        </div>
        <div class="collapsible-body"></div>
      </li>

      <li>
        <div class="collapsible-header" (click)="navigate('/events-calendar')">
          <i class="material-icons orange-text text-darken-2">event_available</i>
          <strong>Events</strong>
        </div>
        <div class="collapsible-body"></div>
      </li>

      <li>
        <div class="collapsible-header">
          <i class="material-icons red-text text-darken-2">account_balance</i>
          <strong>Governance</strong>
        </div>
        <div class="collapsible-body">
          <div class="row col s12">
            <a class="col s12 hoverable black-text" (click)="chiefClicked()" style="margin-left: 15%">
              <span> Chief </span>
            </a>

            <a class="col s12 hoverable black-text" (click)="councillorsClicked()" style="margin-left: 15%">
              <span> Councillors </span>
            </a>

            <a class="col s12 hoverable black-text" [routerLink]="['/boards-list']" style="margin-left: 15%">
              <span> Boards </span>
            </a>
          </div>
        </div>
      </li>

      <li>
        <div class="collapsible-header" (click)="navigate('/departments')">
          <i class="material-icons purple-text text-darken-1">scatter_plot</i>
          <strong>Departments</strong>
        </div>
        <div class="collapsible-body"></div>
      </li>

      <li>
        <div class="collapsible-header" (click)="navigate('/about-us')">
          <i class="material-icons green-text text-darken-1">feed</i>
          <strong>About Us</strong>
        </div>
        <div class="collapsible-body"></div>
      </li>

      <li>
        <div class="collapsible-header" (click)="navigate('/contact-nation')">
          <i class="material-icons cyan-text text-darken-3">email</i>
          <strong>Contact Us</strong>
        </div>
        <div class="collapsible-body"></div>
      </li>
      <li>
        <div class="collapsible-header" (click)="navigate('/auth/login')">
          <i class="material-icons green-text">login</i>
          <strong>Login</strong>
        </div>
        <div class="collapsible-body"></div>
      </li>

      <li>
        <div class="collapsible-header" (click)="navigate('/events-calendar')">
          <i class="material-icons orange-text text-darken-2">event_available</i>
          <strong>Events</strong>
        </div>
        <div class="collapsible-body"></div>
      </li>

      <li>
        <div class="collapsible-header">
          <i class="material-icons red-text text-darken-3">security</i>
          <strong>Privacy Policy</strong>
        </div>
        <div class="collapsible-body">
          <a class="col s12 hoverable black-text" style="margin-left: 15%" [routerLink]="['/terms-conditions']">
            <span> Terms & Conditions </span>
          </a>

          <a class="col s12 hoverable black-text" (click)="councillorsClicked()" style="margin-left: 15%">
            <span> Councillors </span>
          </a>

          <a class="col s12 hoverable black-text" [routerLink]="['/boards-list']" style="margin-left: 15%">
            <span> Boards </span>
          </a>
        </div>
      </li>

      <li>
        <div class="collapsible-header" (click)="navigate('/registration-request')">
          <i class="material-icons blue-text text-darken-3">person_add</i>
          <strong>Register</strong>
        </div>
        <div class="collapsible-body"></div>
      </li>

      <li>
        <div class="collapsible-header">
          <i class="material-icons blue-text text-darken-3">account_circle</i>
          <strong>Demo Account</strong>
        </div>
        <div class="collapsible-body">
          <a class="col s12 hoverable black-text" (click)="memberAccountClick()" style="margin-left: 15%">
            <span> Member Account </span>
          </a>
          <a class="col s12 hoverable black-text" (click)="adminAccountClick()" style="margin-left: 15%">
            <span> Admin Account </span>
          </a>
        </div>
      </li>

      <li>
        <div class="collapsible-header" (click)="navigate('/contact-nation')">
          <i class="material-icons cyan-text text-darken-3">email</i>
          <strong>Contact Us</strong>
        </div>
        <div class="collapsible-body"></div>
      </li>

      <li>
        <div class="collapsible-header">
          <i class="material-icons teal-text text-darken-2">thumb_up_off_alt</i>
          <strong>Follow Us</strong>
        </div>
        <div class="collapsible-body">
          <a class="col s12 hoverable" style="margin-left: 15%">
            <span> Facebook </span>
          </a>

          <a class="col s12 hoverable" style="margin-left: 15%">
            <span> Instagram </span>
          </a>

          <a class="col s12 hoverable" style="margin-left: 15%">
            <span> Youtube </span>
          </a>

          <a class="col s12 hoverable" style="margin-left: 15%">
            <span> Twitter </span>
          </a>
        </div>
      </li>

      <li>
        <div class="collapsible-header">
          <i class="material-icons red-text text-darken-3">security</i>
          <strong>Privacy Policy</strong>
        </div>
        <div class="collapsible-body">
          <a class="col s12 hoverable black-text" style="margin-left: 15%" [routerLink]="['/terms-conditions']">
            <span> Terms & Conditions </span>
          </a>

          <a class="col s12 hoverable black-text" style="margin-left: 15%" [routerLink]="['/privacy-policy']">
            <span> Privacy Policy </span>
          </a>

          <a class="col s12 hoverable black-text" style="margin-left: 15%" [routerLink]="['/eula']">
            <span> End-User Licence Agreement </span>
          </a>
        </div>
      </li>
    </ul>
  </div>
</ul>

<ul class="sidenav" id="admin-mobile-navbar">
  <img class="responsive-img center" [src]="logo" style="height: 100%; object-fit: cover" alt="logo image" />
  <li>
    <a (click)="navigate('/admin/dashboard')"><i class="material-icons">house</i>Home</a>
  </li>
  <li>
    <a (click)="navigate('/admin/messages')"><i class="material-icons">question_answer</i> Communications</a>
  </li>
  <li>
    <a (click)="navigate('/admin/about-us')"><i class="material-icons">supervised_user_circle</i>About us</a>
  </li>
  <li>
    <a (click)="navigate('/admin/department')"><i class="material-icons">scatter_plot</i>Departments</a>
  </li>
  <li>
    <a (click)="navigate('/admin/governance-main')"><i class="material-icons">gavel</i>Governance</a>
  </li>
  <li>
    <a (click)="incomingAppsClick()"><i class="material-icons">list_alt</i>Incoming Applications</a>
  </li>
  <li>
    <a (click)="signOut()"><i class="material-icons">lock</i>Logout</a>
  </li>
</ul>

<ul class="sidenav" id="member-mobile-navbar">
  <p style="font-weight: bold" class="center">{{ nationName }}</p>
  <li>
    <a (click)="navigate('/member/home')"><i class="material-icons">house</i>Home</a>
  </li>
  <li>
    <a (click)="navigate('/member/job-board')"><i class="material-icons">work</i>Job Board</a>
  </li>
  <li>
    <a (click)="navigate('/member/messages')"><i class="material-icons">mail</i>My Chat/Circle</a>
  </li>
  <li>
    <a (click)="navigate('member/governance')"><i class="material-icons">gavel</i>Governance</a>
  </li>
  <li>
    <a (click)="navigate('/member/department')">
      <i class="material-icons">scatter_plot</i>
      <span *ngIf="nationOfuse === 'Batc'">Offices</span>
      <span *ngIf="nationOfuse !== 'Batc'">Departments</span></a>
  </li>

  <li>
    <a (click)="navigate('/member/contact-us')"><i class="material-icons">email</i>Nation Contact</a>
  </li>
  <li>
    <a (click)="signOut()"><i class="material-icons">lock</i>Logout</a>
  </li>
</ul>

<ul class="sidenav" id="employer-mobile-navbar">
  <img class="responsive-img center" [src]="logo" style="height: 100%; object-fit: cover"  alt="logo image" />
  <li>
    <a (click)="navigate('/employer/home')"><i class="material-icons">house</i>Home</a>
  </li>
  <li>
    <a (click)="navigate('/employer/search-skills')"><i class="material-icons">account_box</i>Search Our Skills
      Databank</a>
  </li>
  <li>
    <a (click)="navigate('/employer/post-job')"><i class="material-icons">add_circle</i>Post A Job Offer</a>
  </li>
  <li>
    <a (click)="navigate('/employer/my-contracts')"><i class="material-icons">work</i>My Contracts</a>
  </li>
  <li>
    <a (click)="navigate('/employer/job-archived')"><i class="material-icons">archive</i>Archived Job Offer</a>
  </li>
  <li>
    <a (click)="signOut()"><i class="material-icons">lock</i>Logout</a>
  </li>
</ul>

<ul class="sidenav" id="employee-mobile-navbar">
  <img class="responsive-img center" [src]="logo" style="height: 100%; object-fit: cover"  alt="logo image" />
  <li>
    <a (click)="navigate('/employee/account')"><i class="material-icons">settings</i>My Account</a>
  </li>
  <li>
    <a (click)="navigate('/employee/inbox')"><i class="material-icons">mail</i>My Inbox</a>
  </li>
  <li>
    <a (click)="navigate('/employee/department-info')"><i class="material-icons">info</i>Information</a>
  </li>
  <li>
    <a (click)="navigate('/employee/department-news-list')"><i class="material-icons">notifications</i>News / Events</a>
  </li>
  <li>
    <a (click)="navigate('/employee/employee-list')"><i class="material-icons">group</i><br />Employees</a>
  </li>
  <li>
    <a (click)="signOut()"><i class="material-icons">lock</i>Logout</a>
  </li>
</ul>

<div id="socialModal" class="modal b-n-1">
  <div class="modal-content">
    <!-- ============ header ====================== -->
    <div class="row" style="border-bottom: 2px solid rgb(230, 230, 230)">
      <h4 class="title-section2 bold-600 black-text col s11">Follow Us</h4>
      <i class="material-icons title-section2 right modal-close col s1">close</i>
    </div>
    <!-- ============ content ====================== -->

    <div class="social-follow row">
      <h4 class="bold-300 title-section3 black-text">Follow us via :</h4>
      <a *ngIf="socialMedia.facebook" [href]="socialMedia.facebook" target="_blank" class="blue-text col s4 m2 l2">
        <i class="fa fa-facebook circle"></i>
        <!-- <img src="https://img.icons8.com/arcade/344/facebook-new.png" height="100px" /> -->
      </a>
      <a *ngIf="socialMedia.instagram" [href]="socialMedia.instagram" target="_blank" class="pink-text col s4 m2 l2">
        <i class="fa fa-instagram circle"></i>
        <!-- <img src="https://img.icons8.com/arcade/344/instagram-new.png" height="100px" /> -->
      </a>
      <a *ngIf="socialMedia.twitter" [href]="socialMedia.twitter" target="_blank" class="black-text col s4 m2 l2">
        <!-- <i class="fa fa-twitter circle"></i> -->
        <i class="fa circle">
          <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="none" viewBox="0 0 512 512" id="twitter">
            <g clip-path="url(#clip0_84_15698)">
              <rect width="512" height="512" fill="none" rx="60">
              </rect>
              <path fill="#000"
                d="M355.904 100H408.832L293.2 232.16L429.232 412H322.72L239.296 302.928L143.84 412H90.8805L214.56 270.64L84.0645 100H193.28L268.688 199.696L355.904 100ZM337.328 380.32H366.656L177.344 130.016H145.872L337.328 380.32Z">
              </path>
            </g>
            <defs>
              <clipPath id="clip0_84_15698">
                <rect width="512" height="512" fill="#fff">
                </rect>
              </clipPath>
            </defs>
          </svg>
        </i>
        <!-- <img src="https://img.icons8.com/arcade/344/twitter.png" height="100px" /> -->
      </a>
      <a *ngIf="socialMedia.youtube" [href]="socialMedia.youtube" target="_blank" class="red-text col s4 m2 l2">
        <i class="fa fa-youtube circle"></i>
        <!-- <img src="https://img.icons8.com/arcade/344/youtube.png" height="100px" /> -->
      </a>

      <a *ngIf="socialMedia.linkedin" [href]="socialMedia.linkedin" target="_blank" class="blue-text col s4 m2 l2">
        <i class="fa fa-linkedin circle"></i>
        <!-- <img src="https://img.icons8.com/arcade/344/youtube.png" height="100px" /> -->
      </a>

      <a *ngIf="socialMedia.tiktok" [href]="socialMedia.tiktok" target="_blank" class="black-text col s4 m2 l2">
        <!-- <i class="fa fa-tiktok circle"></i> -->
        <i class="fa  circle">
          <svg _ngcontent-yei-c68="" width="28" height="28" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"
            class="prefix">
            <path _ngcontent-yei-c68="" fill="currentcolor"
              d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z">
            </path>
          </svg>
        </i>
        <!-- <img src="https://img.icons8.com/arcade/344/youtube.png" height="100px" /> -->
      </a>
      <p class="bold-300 title-section3 black-text col s12">
        Don't forget to follow us
      </p>
    </div>
  </div>
</div>