import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { toast } from 'materialize-css';
import { SessionsMonitorService } from 'src/app/services/firebase/sessions-monitor.service';

declare var $: any;

@Component({
  selector: 'app-post-notification',
  templateUrl: './post-notification.component.html',
  styleUrls: ['./post-notification.component.css']
})

export class PostNotificationComponent implements OnInit {

  myModel = {
    id: '',
    type: 'Post',
    title: '',
    body: '',
    imgLink: '',
    videoLink: '',
    visibility: '',
    expiryDate: '',
    author: '',
    published: true,
    unPublished: false,
    datePublished: new Date(),
    index: Math.floor(Math.random() * (10000 - 5000 + 1)) + 5000,
    genderFilter: '',
    communityFilter: '',
    minAgeFilter: '',
    maxAgeFilter: '',
    residencyFilter: '',
    comments: [],
    like:''
  };

  valueEmittedFromChildComponent: any;
  parentEventHandlerFunction(valueEmitted) {
    this.valueEmittedFromChildComponent = valueEmitted;
    if (this.valueEmittedFromChildComponent === true) {
      this.sessionMonitor.newActivity('Add News', 'News Created');
      toast({ html: 'News / Event Successfully Posted!', classes: 'green' });
    }
  }

  constructor(
    private location: Location,
    private sessionMonitor: SessionsMonitorService) {}

  ngOnInit() {
  }

  backClicked() {
    this.location.back();
  }

}
