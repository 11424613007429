import { Component, OnInit } from '@angular/core';
import { FileSharingServiceService } from 'src/app/services/firebase/file-sharing-service.service';

@Component({
  selector: 'app-file-manager',
  templateUrl: './file-manager.component.html',
  styleUrls: ['./file-manager.component.css']
})
export class FileManagerComponent implements OnInit {

  constructor(private fsharingService: FileSharingServiceService) { }

  myfiles: any[];

  ngOnInit(): void {
    this.fsharingService.getUserFiles()
      .valueChanges().subscribe(f => {
        if (f) {
          if (f.length > 0) {
            this.myfiles = f;
          }
        }
      });
  }

}
