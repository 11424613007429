import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiKeyManagerService } from './apiKeyManager.Service';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ImageModel } from 'src/app/models/ImageModel';

@Injectable({
  providedIn: 'root'
})
export class MediaService {

  url = environment.firstNation.apiUrl + 'api/Media/';
  constructor(private httpClient: HttpClient,  private keyManager: ApiKeyManagerService) { }

  uploadImage(model: ImageModel): Observable<any> {
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
    return this.httpClient.post<Observable<any>>(this.url + 'UploadImage/', JSON.stringify(model), { headers });
  }

  UploadBinImage(image: File): Observable<any> {
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
    const formData: FormData = new FormData();
    formData.append('MyImage', image, image.name);
    return this.httpClient.post<any>(this.url + 'UploadBinImage/', formData, { headers });
  }

  DeleteBinImage(oldFileUrl: any): Observable<any> {
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
    const formData: FormData = new FormData();
    formData.append('OldImage', oldFileUrl);
    return this.httpClient.post<any>(this.url + 'DeleteBinImage/', formData, { headers });
  }

  UpdateBinImage(image: File, oldFileUrl: any): Observable<any> {
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
    const formData: FormData = new FormData();
    formData.append('NewImage', image, image.name);
    formData.append('OldImage', oldFileUrl);
    return this.httpClient.post<any>(this.url + 'UpdateBinImage/', formData, { headers });
  }

  uploadVideo(video: File): Observable<any> {
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
    const formData: FormData = new FormData();
    formData.append('MyVideo', video, video.name);
    return this.httpClient.post<any>(this.url + 'UploadVideo/', formData, { headers });
  }

  uploadPDF(pdf: File): Observable<any> {
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
    const formData: FormData = new FormData();
    formData.append('PDFFile', pdf, pdf.name);
    return this.httpClient.post<any>(this.url + 'UploadPDF/', formData, { headers });
  }

  EditPDF(pdf: File, oldFile: string): Observable<any> {
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
    const formData: FormData = new FormData();
    formData.append('NewFile', pdf, pdf.name);
    formData.append('oldFileUrl', oldFile);
    return this.httpClient.post<any>(this.url + 'UpdatePDF/', formData, { headers });
  }
}
