<div class="row" *ngIf="model">
  <ul class="row collapsible" >
      <li>
        <div class="collapsible-header">
            <div class="row col s12 center">
              <h6 class="center blue-text "><strong> My Total Entitlement : </strong></h6>
              <h6 class="center"><strong> <span class="green-text"> {{ model[0]?.TotalAmount | currency }} </span></strong> </h6>
            </div>
        </div>

        <div class="collapsible-body white">
            <div class="row collection">
                <a class="collection-item">
                  <div><strong>Total:<a class="secondary-content green-text">{{ model[0]?.TotalAmount | currency }}</a></strong></div>
                </a>
              </div>
        </div>
      </li>
    </ul>

    <ul class="row collapsible">
        <li class="active">
          <div class="collapsible-header">
              <div class="row col s12 center">
                  <h6 class="center blue-text"><strong> Payment Detail </strong></h6>
              </div>
          </div>
          <div class="collapsible-body white">
              <div class="row collection">
                  <a *ngFor="let e of model" class="collection-item">
                    <div>
                      <div class="row">
                        <strong class="teal-text">{{(e?.PayableTo !== null) ? e?.PayableTo : ""}}</strong>
                      </div>
                      <strong class="black-text"> {{e?.PMTMethod}} <a class="secondary-content green-text"> {{e?.Amount | currency}} </a></strong>
                    </div>
                  </a>
                </div>
          </div>
        </li>
      </ul>
  </div>