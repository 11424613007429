<h6 *ngIf="nationOfuse === 'Batc'" class="center"><strong>Office News/Envents</strong></h6>
<h6 *ngIf="nationOfuse !== 'Batc'" class="center"><strong>Department News/Envents</strong></h6>
<div class="section"></div>
<div class="container">
  <div class="row col s12">

    <app-firebase-loading *ngIf="!model"></app-firebase-loading>
    <div class="col l2"></div>
    <div class="col s12 l8" *ngIf="model">
      <div *ngFor="let data of model">
        <app-news-feed *ngIf="data.visibility === 'Public'" [callerForm]="'DepNews'" [newsModel]="data">
        </app-news-feed>
      </div>
      <div class="row">
        <br />
        <a [ngClass]="'col s12 btn waves-effect ' + themeColor" (click)="backClicked()"><i
            class="material-icons left">arrow_back_ios</i> Back To Previous Page</a>
      </div>
    </div>
    <div class="col l2"></div>
  </div>
</div>