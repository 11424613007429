
export class SKBarrierModel {
    caseplan_id: number;
    barrier: string;
    //other1: string;
    //other2: string;
    //other3: string;
    other: string;
   
    address_barrier: number; 
  }
