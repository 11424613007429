<div class="col s12">
  <div class="body-h">
    <div class="header-setting b-m-2">
      <div class="col l12 m12 s12">
        <h4 *ngIf="!editName">
          <span *ngIf="sectionName">{{sectionName}}</span>
          <span *ngIf="!sectionName">Demographics</span>
          <i class="material-icons edit-name" [ngStyle]="{'color': themeColor}" (click)="editNameClicked()">edit</i>
        </h4>
        
        <div class="input-field" *ngIf="editName">
          <input type="text" [(ngModel)]="sectionName" class="left col s6 validate" autocomplete="off" />
          <button type="button" class="btn ll-m-5" [ngStyle]="{'background-color': themeColor}"
            (click)="saveName()">Save</button>
        </div>

        <p class="col s12 bold-300 sub-title-section3">It is a long established fact that a reader will be distracted by
          the
          readable content of a
          page when looking at its layout.</p>
      </div>
    </div>

    <div class="row">
      <div class="row black-text center" *ngIf="!birthsModel || !gendersModel">
        <img src="../../assets/img/empty-folder.png" height="80" width="80">
        <h6 class="bold-700">Sorry, there is no enough stat data available.</h6>
        <h6 class="bold-700">Please, try another time</h6>
      </div>

      <div class="col s12 l12" *ngIf="birthsModel || gendersModel">
        <div class="row col s12 l12 m12  white">
          <div class="col l6 card-panel" *ngIf="birthsModel && lineChartData">
            <h6><strong>Population Growth (Last 10 years)</strong></h6>
            <div style="display: block;">
              <canvas baseChart width="400" height="400" [data]="lineChartData" [labels]="lineChartLabels"
                [options]="lineChartOptions" [colors]="lineChartColors" [legend]="lineChartLegend"
                [chartType]="lineChartType" [plugins]="lineChartPlugins">
              </canvas>
            </div>
          </div>

          <div class="col l6 card-panel" *ngIf="gendersModel && pieChartData">
            <h6><strong>Population By Gender</strong></h6>
            <div class="chart" *ngIf="pieChartData">
              <canvas baseChart width="400" height="400" [datasets]="pieChartData" [labels]="pieChartLabels"
                [chartType]="pieChartType" [options]="pieChartOptions" [colors]="pieChartColors"
                [legend]="pieChartLegend">
              </canvas>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="underConstructionModal" class="modal center b-n-1">
      <div class="modal-content">
        <i class="material-icons right modal-close">close</i><br />
        <h6 class="title-section2 bold-600 black-text">Under Construction</h6>
        <p class="sub-title-section bold-100">We’re working hard to make this feature available soon. </p>
        <img src="https://img.icons8.com/wired/344/tow-truck.png" height="150px" />
        <p class="sub-title-section bold-100">In the meantimee if you need some support? Contact us on info&#64;mynation.app
        </p>
        <p class="sub-title-section bold-100">Please Contanct your Administrator to gain access.</p>
      </div>
    </div>
  </div>
</div>