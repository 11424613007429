<div *ngIf="model" class="collection">
  <a class="collection-item">
    <div>Invention ID:<a class="secondary-content blue-text"><strong>{{model?.ID}}</strong></a></div>
  </a>

  <a class="collection-item">
    <div>Intervention Frequency:<a class="secondary-content blue-text"><strong> {{model?.frequency |
          FrequencyName}}</strong> </a></div>
  </a>

  <a class="collection-item">
    <div>Start Date:<a class="secondary-content blue-text"><strong> {{model?.Start_date | date: 'dd/MMM/yyyy'}}</strong>
      </a></div>
  </a>

  <a class="collection-item">
    <div>End Date:<a class="secondary-content blue-text"><strong>{{(model?.End_date !== null) ? (model?.End_date | date:
          'dd/MMM/yyyy') : ""}}</strong></a></div>
  </a>

  <a class="collection-item">
    <div>Description:<a class="secondary-content blue-text"><strong>{{model?.Description}}</strong></a></div>
  </a>

  <a class="collection-item">
    <div>NOC:<a class="secondary-content blue-text"><strong>{{model?.NOC}}</strong></a></div>
  </a>

  <a class="collection-item">
    <div>Category:<a class="secondary-content blue-text"><strong>{{model?.expense_category}}</strong></a></div>
  </a>

  <a class="collection-item">
    <div>Cost:<a class="secondary-content blue-text"><strong>{{model?.Cost | currency}}</strong></a></div>
  </a>

  <a class="collection-item">
    <div>Payable to:<a class="secondary-content blue-text"><strong>{{model?.payable_to}}</strong></a></div>
  </a>

</div>