
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ActionPlanModel } from 'src/app/models/isets/ActionPlanModel';
import { ApiKeyManagerService } from './../apiKeyManager.Service';


@Injectable({
    providedIn: 'root'
  })
  export class ActionPlanService {
  
    url = environment.firstNation.apiUrl + 'api/ActionPlan/';
    
    constructor(private httpClient: HttpClient, private keyManager: ApiKeyManagerService) { }
   
    
    getClient(id: number): Observable<any> {
        const url = this.url + 'GetClient/' + id;
        const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
        return this.httpClient.get<any>(url, { headers });
      }


    getActionPlan(apid: number): Observable<ActionPlanModel> {    
        const url = this.url + 'GetActionPlan/' + apid;
        const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
        return this.httpClient.get<any>(url, { headers });
    }
    

    saveActionPlan(model: ActionPlanModel): Observable<any>{
        const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
        return this.httpClient.post<any>(this.url + 'SaveActionPlan', JSON.stringify(model), { headers });
    } 

    
    getSIN(id: string): Observable<any> {
      const url = this.url + 'GetSIN/' + id ;
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
      return this.httpClient.get<any>(url, { headers });
    }

    /*
    getUncompletedActionPlans(id: number): Observable<any[]> {    
      const url = this.url + 'GetUncompletedActionPlans/' + id;
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
      return this.httpClient.get<any>(url, { headers });
    }
  */

    getNOCs(id: string): Observable<any[]> {    
      const url = this.url + 'GetNOCs/' + id;
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
      return this.httpClient.get<any>(url, { headers });
    }


    
    updateActionPlan(model: ActionPlanModel): Observable<any>{
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
      return this.httpClient.post<any>(this.url + 'UpdateActionPlan', JSON.stringify(model), { headers });
    } 

    getNOCGroups(sql: string): Observable<any[]> {    
      const url = this.url + 'GetNOCGroups/' + sql;
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
      return this.httpClient.get<any>(url, { headers });
    }

    getIncompleteActionPlans(id: number): Observable<any[]> {    
      const url = this.url + 'GetIncompleteActionPlans/' + id;
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
      return this.httpClient.get<any>(url, { headers });
    }


    getIncomingActionPlans(id: number): Observable<any[]> {    
      const url = this.url + 'GetIncomingActionPlans/' + id;
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
      return this.httpClient.get<any>(url, { headers });
    }


  }
