import { Component, OnInit } from '@angular/core';
import { DepartmentService } from 'src/app/services/firebase/department.service';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { GovernanceService } from 'src/app/services/firebase/governance.service';

declare var $: any;
@Component({
  selector: 'app-contact-nation',
  templateUrl: './contact-nation.component.html',
  styleUrls: ['./contact-nation.component.css']
})
export class ContactNationComponent implements OnInit {

  departments: any[];
  contactModalValue: any;
  themeColor: any;
  defaultThemeColor = environment.appTheme.themeColor;
  model: any;
  governanceSection: any;
  governance: any;
  chief: any;
  councillors: any[];

  bodyBgColor: any;
  setting: any;
  defaultBgColor = '#ffffff';
  sectionName: any;
  councillorTitle: any;
  chiefTitle: any;

  constructor(private depService: DepartmentService,
    private location: Location,
    private appSettingService: AppSettingsService,
    private governanceService: GovernanceService) {
    this.themeColor = this.appSettingService.getAppThemeColor() ? this.appSettingService.getAppThemeColor() : this.defaultThemeColor;
    this.chiefTitle = this.appSettingService.getGovTitle().chiefTitle ? this.appSettingService.getGovTitle().chiefTitle: 'Chief' ;
    this.councillorTitle = this.appSettingService.getGovTitle().councillorTitle ? this.appSettingService.getGovTitle().councillorTitle : 'Councillors';
  
    this.appSettingService.getAppSetting().valueChanges().subscribe(
      appSettings => {
        if (appSettings) {
          if (appSettings.length > 0) {
            this.model = appSettings[0];
            this.themeColor = this.model.themeColor ? this.model.themeColor : this.defaultThemeColor;
          }
        }
      });

    // =============================================   remove once added to app-menu =============

  }

  ngOnInit() {
    this.sectionName = this.appSettingService.getCurrentMenu();
    this.appSettingService.getHomePageSettings().valueChanges().subscribe(hp => {
      if (hp) {
        if (hp.length > 0) {
          this.model = hp[0];
          const governanceSection = this.model.sections.filter(s => s.name === 'Governance' && s.isEnabled);
          if (governanceSection) {
            this.governanceSection = governanceSection[0];
            this.governanceService.getGovernanceList().valueChanges().subscribe(n => {
              if (n) {
                this.governance = n[0];
                if (this.governance.chiefs.length > 0) {
                  this.chief = this.governance.chiefs[0];
                }
                //if (this.governance.councillors.length > 0) this.councillor = this.governance.councillors[0];
                this.councillors = this.governance.councillors.splice(0, governanceSection[0].councillorsLimit);
              }
              setTimeout(() => {
                $('.tooltipped').tooltip();
                $('.tabs').tabs();
              }, 25);
            });
          }
        }
      }
    });

    this.depService.getDepartmentList().valueChanges().subscribe(d => {
      if (d) {
        if (d.length > 0) {
          this.departments = d;
          this.departments = this.departments.sort((a, b) => (this.parseHTML(a.name) < this.parseHTML(b.name) ? -1 : 1));
        }
      }
    });
  }

  showModal(val: any) {
    this.contactModalValue = val;
    setTimeout(() => {
      $('.modal').modal();
      $('#modal1').modal('open');
      $('.tabs').tabs();
    }, 25);
  }

  formatPhone(number: any) {
    number = number.replace('(', '');
    number = number.replace(')', '');
    number = number.replace('-', '');
    number = number.replace(/\s/g, '');
    return number;
  }

  parseHTML(html) {
    return html.replace(/<(?:.|\n)*?>/gm, '').replace(/&nbsp;/gm, ' ');
  }

  backClicked() {
    this.location.back();
  }

}
