
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiKeyManagerService } from './apiKeyManager.Service';
import { ActivityModel } from 'src/app/models/ActivityModel';



@Injectable({
    providedIn: 'root'
  })
  export class ActivityService {
  
    constructor(private httpClient: HttpClient, private keyManager: ApiKeyManagerService) { }
  
    url = environment.firstNation.apiUrl + 'api/Activity/';
    
  
    saveActivity(model: ActivityModel): Observable<any> {
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
      return this.httpClient.post<ActivityModel>(this.url + 'SaveActivity/', JSON.stringify(model), { headers });
    }
  
    updateActivity(model: ActivityModel): Observable<any> {
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
      return this.httpClient.post<ActivityModel>(this.url + 'EditActivity/', JSON.stringify(model), { headers });
    }
  
  
    deleteActivity(model: ActivityModel): Observable<any> {
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
      return this.httpClient.post<ActivityModel>(this.url + 'DeleteActivity/', JSON.stringify(model), { headers });
    }
  
  
    getActivityById(id: number): Observable<ActivityModel[]> {
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
      return this.httpClient.get<ActivityModel[]>(this.url + 'GetActivityByID/' + id, { headers });
    }
  

    getActivityByMemberId(id: number): Observable<ActivityModel[]> {
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
      return this.httpClient.get<ActivityModel[]>(this.url + 'GetActivityByMemberId/' + id, { headers });
    }
  
  
  
}