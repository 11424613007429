import { Component, OnInit } from '@angular/core';
import { Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { EducationModel } from 'src/app/models/educationModel';
import { environment } from 'src/environments/environment';
import { EducationService } from 'src/app/services/api/education.service';
import { EducationService as EducationFirebaseService } from 'src/app/services/firebase/education.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { toast } from 'materialize-css';
import { Location } from '@angular/common';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import { Router } from '@angular/router';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-add-education',
  templateUrl: './add-education.component.html',
  styleUrls: ['./add-education.component.css']
})
export class AddEducationComponent implements OnInit {

  myDpOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'dd/mmm/yyyy',
    markCurrentDay: true,
  };

  applicantID = 0;
  addEducationForm: UntypedFormGroup;
  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  sqldb = environment.firstNation.SQLDB;

  model: EducationModel;
  //dateSelected;

  EduLevelList = ["Up to Grade 7-8 (Sec. I = Grade 8)", "Grade 9-10 (Sec. II-III)", "Grade 11-12 (Sec. IV-V)",
    "Secondary School Diploma or GED", "Some post-secondary training", "Apprenticeship or trades certificate or diploma",
    "College, CEGEP,Other", "University certificate or diploma", "University - Bachelor Degree", "University – Master’s degree",
    "University – Doctorate", "Course or certificate", "Other"];

  constructor(private authService: AuthService, private fb: UntypedFormBuilder, private location: Location, private router: Router,
    private educationService: EducationService, private educationFirebaseService: EducationFirebaseService) { }


  ngOnInit() {

    if (localStorage.getItem('applicantID')) {
      this.applicantID = Number(localStorage.getItem('applicantID'));
    } else {
      this.applicantID = this.authService.getCurrentApplicantID();
    }

    this.addEducationForm = this.fb.group({
      Institution: ['', [Validators.required]],
      completion_date: ['', [Validators.required]],
      details: ['', [Validators.required]],
      description: ['', [Validators.required]],
    });

    this.model = new EducationModel();
    this.model.client_id = this.applicantID;

    setTimeout(() => {
      $('select').formSelect();
    }, 25);

    // setTimeout(() => {
    //   $('.datepicker').datepicker({
    //     format: 'dd/mmm/yyyy',
    //     defaultDate: new Date(),
    //     setDefaultDate: new Date(),
    //     yearRange: 50,
    //     minDate: new Date(new Date().getFullYear() - 50, 0, 1),
    //     maxDate: new Date(new Date().getFullYear(), 12, 31),
    //     onSelect(e) {
    //       localStorage.setItem('selectedDate', e);
    //     },
    //   });
    // }, 25);
  }

  get Institution() { return this.addEducationForm.get('Institution'); }
  get completion_date() { return this.addEducationForm.get('completion_date'); }
  get details() { return this.addEducationForm.get('details'); }
  get description() { return this.addEducationForm.get('description'); }

  onDateChanged(event: IMyDateModel): void {
    localStorage.setItem('selectedDate', event.singleDate.jsDate.toDateString());
    if (this.addEducationForm) { this.addEducationForm.patchValue({ completion_date: event.singleDate.formatted }); }
    //this.dateSelected = event.singleDate.formatted;
  }

  submitForm(value: any) {
    this.model.Institution = value.Institution;
    this.model.details = value.details;
    this.model.description = value.description;
    var d_arr = value.completion_date.singleDate.formatted.split("/");
    var completion_date_d = new Date(d_arr[1] + "-" + d_arr[0] + "-" + d_arr[2]);
    var completion_date = new Date(completion_date_d.getFullYear(), completion_date_d.getMonth(), completion_date_d.getDate());
    this.model.completion_date = completion_date;
    this.model.completion_date = new Date(localStorage.getItem('selectedDate'));
    if (this.sqldb) {
      this.educationService.saveEducation(this.model).subscribe(x => {
        if (x) {
          toast({ html: 'Education Successfully Added!', classes: 'green' });
          this.location.back();
        }
      });
    } else {
      this.educationFirebaseService.saveEducation(this.model).then(x => {
        toast({ html: 'New Education Successfully Added!', classes: 'green' });
        this.backClicked();
      });
    }
  }

  backClicked() {
    if (localStorage.getItem('educaton_call_from') == 'case_plan') {
      this.router.navigate(['/member/profile/case-plan']);
    } else if (localStorage.getItem('education_call_from') == 'education') {
      this.router.navigate(['/member/profile/education']);
    } else { this.location.back(); }

  }

}
