import { Component, OnInit } from '@angular/core';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { SectionSettingService } from 'src/app/services/firebase/section-setting.service';
import { environment } from 'src/environments/environment';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-langing-page',
  templateUrl: './langing-page.component.html',
  styleUrls: ['./langing-page.component.css']
})
export class LangingPageComponent implements OnInit {

  //defaultThemeColor = environment.appTheme.themeColor;
  defaultThemeColor = '#000000';
  themeColor: any;
  buttonType: any;
  setting: any;
  model: any;
  loading = false;
  hasISET = environment.firstNation.ISET;
  hasSocial = environment.firstNation.SOCIAL;
  isCardView: boolean = true;

  customSections: any[];
  contents: any[];

  constructor(private appSettingService: AppSettingsService,
    private sectionService: SectionSettingService,
  ) {
    this.appSettingService.getAppSetting().valueChanges().subscribe(
      appSettings => {
        if (appSettings && appSettings.length > 0) {
          this.setting = appSettings[0];
          this.themeColor = this.setting.themeColor ? this.setting.themeColor : this.defaultThemeColor;
          this.buttonType = this.setting.buttonOption ? this.setting.buttonOption : 'rectangularButton';
        } else {
          this.themeColor = this.defaultThemeColor;
          this.buttonType = 'rectangularButton';
        }
      });
  }

  ngOnInit() {
    this.loading = true;
    this.appSettingService.getHomePageSettings().valueChanges().subscribe(hp => {
      if (hp) {
        if (hp.length > 0) {
          this.model = hp[0];
          this.model.sections.sort((a, b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0));
        }
      }
      this.loading = false;
    });


    this.sectionService.getSections().valueChanges().subscribe(sec => {
      if (sec && sec.length > 0) {
        this.customSections = sec;
      }
    });


    document.addEventListener('DOMContentLoaded', function () {
      var elems = document.querySelectorAll('.fixed-action-btn');
      var instances = M.FloatingActionButton.init(elems, {
        direction: 'left'
      });
    });
  }

  getContent(id) {
    this.sectionService.getSectionContent(id).valueChanges().subscribe(content => {
      if (content) return content;
    });
  }

  toggleView(): void {
    this.isCardView = !this.isCardView;

  }
}
