export class NominationModel {
    id : string;
    electionId : string;
    name: string;
    startDate : Date;
    endDate: Date;
    numberOfNominees = 0;
    nominees: Array<string>;
  }
export class NominationDataModel {
    id : string;
    nominationId: string;
    userId: string;
    firstName: string;
    lastName: string;
    nominatedMemberId: string;
    nominationStatus: string;

}