import { Component, OnInit } from '@angular/core';
import { Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { ReferenceModel } from 'src/app/models/ReferenceModel';
import { ReferenceService } from 'src/app/services/api/reference.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { toast } from 'materialize-css';
import { Location } from '@angular/common';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-add-reference',
  templateUrl: './add-reference.component.html',
  styleUrls: ['./add-reference.component.css']
})
export class AddReferenceComponent implements OnInit {

  public phoneNumberMask = ['(', /[0-9]/, /[0-9]/, /[0-9]/, ')', ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];

  clientID = 0;
  addReferenceForm: UntypedFormGroup;
  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  model: ReferenceModel;
  resume_id = 0;

  constructor(private authService: AuthService, private fb: UntypedFormBuilder, private location: Location, private referenceService: ReferenceService) { }

  ngOnInit(): void {
    if (localStorage.getItem('applicantID')) {
      this.clientID = Number(localStorage.getItem('applicantID'));
    } else {
      this.clientID = this.authService.getCurrentApplicantID();
    }
    if (localStorage.getItem('resume_id')) {
      this.resume_id = Number(localStorage.getItem('resume_id'));
    }
    this.addReferenceForm = this.fb.group({
      firstname: ['', [Validators.required]],
      lastname: ['', [Validators.required]],
      organization: [''],
      relationship: [''],
      phone: ['', [Validators.required]],
    });

    this.model = new ReferenceModel();
    this.model.resume_id = this.resume_id;
  }

  get firstname() { return this.addReferenceForm.get('firstname'); }
  get lastname() { return this.addReferenceForm.get('lastname'); }
  get organization() { return this.addReferenceForm.get('organization'); }
  get relationship() { return this.addReferenceForm.get('relationship'); }
  get phone() { return this.addReferenceForm.get('phone'); }


  submitForm(value: any) {

    if (this.addReferenceForm.valid) {

      this.model.RefFirst = value.firstname;
      this.model.RefLast = value.lastname;
      this.model.RefOrg = value.organization;
      this.model.RefRelationship = value.relationship;

      var phone = value.phone;
      phone = phone.replace('(', '');
      phone = phone.replace(')', '');
      phone = phone.replace(' ', '');
      phone = phone.replace('-', '');
      phone = phone.replace('  ', '');
      phone = phone.replace('_', '');
      this.model.RefTel = phone;

      this.referenceService.saveReference(this.model).subscribe(x => {
        if (x) {
          toast({ html: 'Reference Successfully Added!', classes: 'green' });
          this.location.back();
        }
      });
    }
  }

  backClicked() {
    this.location.back();
  }

}