import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { IncomeDeclarationService } from 'src/app/services/api/income-declaration.service';
import { MemberService } from 'src/app/services/api/member.service';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';
import { MemberModel } from 'src/app/models/memberModel';
import { IncomeDeclarationModel } from 'src/app/models/incomeDeclarationModel';
import { toast } from 'materialize-css';

declare var $: any;

@Component({
  selector: 'app-previous-income-statement',
  templateUrl: './previous-income-statement.component.html',
  styleUrls: ['./previous-income-statement.component.css']
})
export class PreviousIncomeStatementComponent implements OnInit {

  step1 = true;
  step2 = false;
  themeColor = environment.appTheme.themeColor;
  applicantID = 0;
  member: MemberModel;
  current_DOI: any;
  previousDeclaration: IncomeDeclarationModel[];
  previousDeclarationTotal = 0;

  constructor(private authService: AuthService, private location: Location, private memberService: MemberService
            , private incomeDeclarationService: IncomeDeclarationService) { }

  ngOnInit() {
    if (localStorage.getItem('applicantID')) {
      this.applicantID = Number(localStorage.getItem('applicantID'));
    } else {
      this.applicantID = this.authService.getCurrentApplicantID();
    }

    this.memberService.getMember(this.applicantID).subscribe(x => {
      if (x) {
        this.current_DOI = x[0].current_DOI;
        if (this.current_DOI) {
          this.incomeDeclarationService.getPreviousDeclaration(this.current_DOI).subscribe(data => {
            if (data) {
              this.previousDeclaration = data.filter( z => z.applicationNo === x[0].current_application);
              this.previousDeclaration.forEach(y => {
                y.mainApplicantID = this.applicantID;
                this.previousDeclarationTotal += Number(y.value);
              });
              setTimeout(() => {
                $('.collapsible').collapsible();
              }, 50);
            }
          });
        }
      }
    });
  }

  submitStatement() {
    this.incomeDeclarationService.saveIncomDeclaration(this.previousDeclaration).subscribe(x => {
      toast({ html: 'Income Statement Successfully Submited!', classes: 'green' });
      this.step1 = false;
      this.step2 = true;
    });

  }

  backClicked() {
    this.location.back();
  }
}
