import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-housing',
  templateUrl: './housing.component.html',
  styleUrls: ['./housing.component.css']
})

export class HousingComponent implements OnInit {

  themeColor = environment.appTheme.adminThemeColor;
  fontColor = environment.appTheme.adminFontColor;

  constructor(private location: Location) {
    localStorage.setItem('from', 'PersonalInfo');
   }

  ngOnInit() {
  }

  backClicked() {
    this.location.back();
  }
}
