import { Component, OnInit } from '@angular/core';
import { PseEntitlementModel } from 'src/app/models/PseEntitlementModel';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { PseEntitlementService } from 'src/app/services/api/pse-entitlement.service';

import { Location } from '@angular/common';
import { toast } from 'materialize-css';
import { environment } from 'src/environments/environment';
import { PseAppInfoService } from 'src/app/services/api/pse-app-info.service';
import { Router } from '@angular/router';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-pse-fundings',
  templateUrl: './pse-fundings.component.html',
  styleUrls: ['./pse-fundings.component.css']
})

export class PseFundingsComponent implements OnInit {

  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;

  userID: any;
  name = '';
  studentname = '';
  complianceList: any[];
  data: any[];
  result: any = [];
  hasSignedEntitlement = false;
  hasEntitlement = false;
  entitlements: PseEntitlementModel[];
  applicantID = 0;

  last_month = '';
  yearofLastMonth = 0;

  constructor(private authService: AuthService,
    private PseEntitlementService: PseEntitlementService,
    private location: Location,
    private router: Router,
    private pseAppInfoService: PseAppInfoService) {
    this.userID = authService.getGlobalUser().applicantID;
  }

  ngOnInit() {

    if (localStorage.getItem('applicantID')) {
      this.applicantID = Number(localStorage.getItem('applicantID'));
    } else {
      this.applicantID = this.authService.getCurrentApplicantID();
    }

    let aDate: Date = new Date();
    const current_year = Number(aDate.getFullYear());
    let intMonth = Number(aDate.getMonth());
    const current_month = this.month_to_string(intMonth);
    //const current_month = this.month_to_string(intMonth+1);   /////??????????????

    if (intMonth == 1) {
      this.last_month = 'December';
      this.yearofLastMonth = current_year - 1;
    } else {
      this.last_month = this.month_to_string(intMonth - 1);
      this.yearofLastMonth = current_year;
    }

    this.pseAppInfoService.getFundingBDCompliance(this.applicantID).subscribe(comp => {
      if (comp) {
        if (comp.length > 0) {
          this.hasEntitlement = true;
          this.complianceList = comp;

          setTimeout(() => {
            $('select').formSelect();
            M.updateTextFields();
          }, 25);

          this.data = [];
          for (const entry of this.complianceList) {
            if (Number(entry.year) === current_year && entry.month === current_month) {
              this.data.push(entry);
            } else if (Number(entry.year) === this.yearofLastMonth && entry.month === this.last_month) {
              this.data.push(entry);
            }
          }

          this.result = Array.from(new Set(this.data.map(a => a.funding_id))).map(funding_id => {
            const obj = {
              applicantID: this.data.find(x => x.funding_id === funding_id).applicantID,
              funding_id: this.data.find(x => x.funding_id === funding_id).funding_id,
              month: this.data.find(x => x.funding_id === funding_id).month,
              year: this.data.find(x => x.funding_id === funding_id).year,
              //BD_Student_SIGOK: this.data.find(x => x.funding_id === funding_id).BD_Student_SIGOK,
              //BD_Parents_SIGOK: this.data.find(x => x.funding_id === funding_id).BD_Parents_SIGOK,
              dateCreated: this.dateCreated(this.data.find(x => x.funding_id === funding_id).month, this.data.find(x => x.funding_id === funding_id).year),
              GivenName: this.data.find(x => x.funding_id === funding_id).GivenName,
              LastName: this.data.find(x => x.funding_id === funding_id).LastName,
              school_period: this.data.find(x => x.funding_id === funding_id).school_period,
            };

            return obj;
          }).sort((a, b) => +new Date(b.dateCreated) - +new Date(a.dateCreated));
        } else { this.hasEntitlement = false; }
      } else { this.hasEntitlement = false; }
      setTimeout(() => {
        $('select').formSelect();
        M.updateTextFields();
      }, 25);
    });

    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }

  month_to_string(month: number) {

    if (month === 0) {
      return 'January';
    } else if (month === 1) {
      return 'February';
    } else if (month === 2) {
      return 'March';
    } else if (month === 3) {
      return 'April';
    } else if (month === 4) {
      return 'May';
    } else if (month === 5) {
      return 'June';
    } else if (month === 6) {
      return 'July';
    } else if (month === 7) {
      return 'August';
    } else if (month === 8) {
      return 'September';
    } else if (month === 9) {
      return 'October';
    } else if (month === 10) {
      return 'November';
    } else if (month === 11) {
      return 'December';
    } else {
      return '';
    }
  }

  selectBD(BDNo: string) {
    this.PseEntitlementService.getEntitlementByBDNo(BDNo).subscribe(ent => {
      if (ent) {
        this.entitlements = ent;
        const compliance = this.result.find(cmp => cmp.funding_id === BDNo);
        if (compliance) {
          this.studentname = compliance.LastName + ' ' + compliance.GivenName;
        }
      }
    });
  }

  back0() {
    this.entitlements = undefined;
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }

  backClicked() {
    this.location.back();
  }

  dateCreated(month: string, year: number) {
    const months = ['January', 'February', 'March', 'April',
      , 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const monthIndex = months.indexOf(month);
    return new Date(year, monthIndex, 1);
  }

  apply_supplemental_funding() {

    let selected_bd: string = '';
    if (this.entitlements[0].funding_id) { selected_bd = this.entitlements[0].funding_id; }
    if (selected_bd == '') {
      toast({ html: 'No funding BD is selected !', classes: 'red' });
      return;
    }

    let selected_year: number = this.entitlements[0].year;
    let selected_month: string = this.entitlements[0].month;

    localStorage.setItem('selected_BD_Year', selected_year.toString());
    localStorage.setItem('selected_BD_Month', selected_month);

    //'pse-supplemental-funding'
    this.router.navigate(['/member/profile/pse-supplemental-funding']);
  }

  selectBD_new(BDNo: string) {

    this.PseEntitlementService.getEntitlementByBDNo(BDNo).subscribe(ent => {
      if (ent) {
        this.entitlements = ent;
        const compliance = this.result.find(cmp => cmp.funding_id === BDNo);
        if (compliance) {
          this.studentname = compliance.LastName + ' ' + compliance.GivenName;

          let selected_year: number = compliance.year;
          let selected_month: string = compliance.month;
          let selected_period: string = compliance.school_period;

          localStorage.setItem('selected_BD_Year', selected_year.toString());
          localStorage.setItem('selected_BD_Month', selected_month);
          localStorage.setItem('selected_School_Period', selected_period);

          this.router.navigate(['/member/profile/pse-supplemental-funding']);
        }
      }
    });
  }

}