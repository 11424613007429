import { Component, OnInit } from '@angular/core';
import { Children, Document } from 'src/app/models/PSSPApplicationModel';
import { PssspApplicationService } from 'src/app/services/firebase/psssp-application.service';
import { Location } from '@angular/common';
import { DatePipe } from '@angular/common';

const pdfMake = require('pdfmake/build/pdfmake.js');
const pdfFonts = require('pdfmake-unicode/dist/pdfmake-unicode.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;

declare var $: any;

@Component({
  selector: 'app-post-seconday',
  templateUrl: './post-seconday.component.html',
  styleUrls: ['./post-seconday.component.css']
})
export class PostSecondayComponent implements OnInit {

  maritalStatus: any = [{ name: 'Single', value: '0' }, { name: 'Single-Parent', value: '1' }, { name: 'Married', value: '2' }, { name: "Common Law", value: '3' }];
  loading = false;
  applications: any[];
  count: number;
  showToday = false;
  showThisWeek = false;
  showThisMonth = false;
  showDetails = false;
  detail: any;
  image: any;
  imageName: any;
  childrenModel: Children;
  documentModel: Document;
  maritalStatusSelected: any;

  constructor(private pssspService: PssspApplicationService, private location: Location) { }

  ngOnInit() {
    this.loading = true;
    this.pssspService.getApplications().valueChanges().subscribe(x => {
      if (x) {
        this.applications = x.sort((a, b) => (a.date > b.date) ? 1 : ((b.date > a.date) ? -1 : 0));
        this.count = this.applications.length;
      }
      this.loading = false;
    });
  }

  onShowDetails(index) {
    this.detail = this.applications[index];
    this.maritalStatusSelected = this.maritalStatus[Number(this.detail.maritalStatus)].name;
    this.showDetails = true;
    setTimeout(() => {
      $('.modal').modal();
      $('.tooltipped').tooltip();
    }, 25);
    window.scrollTo(0, 0);
  }

  backClicked() {
    this.location.back();
  }

  backToList() {
    this.showDetails = false;
    window.scrollTo(0, 0);
  }

  showSignature() {
    this.image = this.detail.signature;
    this.imageName = 'SIGNATURE';
    setTimeout(() => {
      $('.modal').modal();
      $('#imageModal').modal('open');
    }, 25);
  }

  showDocument(index) {
    const doc = this.detail.documents[index];
    this.imageName = doc.name;
    this.image = doc.link;
    setTimeout(() => {
      $('.modal').modal();
      $('#imageModal').modal('open');
    }, 25);

  }
  
  closeModal() {
    $('.modal').modal('close');
    window.scrollTo(0, 0);
  }

  async getDocumentDefinition() {
    const datePipe = new DatePipe('en-US');
    return {
      info: {
        title: 'PETER BALLANTYNE CREE NATION POST-SECONDARY STUDENT SUPPORT PROGRAM APPLICATION FORM',
        subject: 'PSSSP',
        keywords: 'PSSSP Application',
      },
      pageSize: 'LETTER',
      content: [
        {
          columns: [
            [
              await this.getLogo(),
            ],
            [{
              text: 'PETER BALLANTYNE CREE NATION POST-SECONDARY STUDENT SUPPORT PROGRAM APPLICATION FORM',
              style: 'mainTitle',
            }]
          ]
        },
        {
          columns: [
            [{
              text: 'Student Information',
              style: 'title',
            },
            {
              text: 'First Name : ' + this.detail.firstName,
              style: 'normalText',
            },
            {
              text: 'Last Name : ' + this.detail.lastName,
              style: 'normalText',
            },
            {
              text: this.detail.middleName ? 'Middle Name : ' + this.detail.middleName : 'Middle Name : ' + '',
              style: 'normalText',
            },
            {
              text: 'Mailing Information',
              style: 'subTitle',
            },
            {
              text: 'Street : ' + this.detail.street,
              style: 'normalText',
            },
            {
              text: 'Apartment : ' + this.detail.apt,
              style: 'normalText',
            },
            {
              text: 'City : ' + this.detail.city,
              style: 'normalText',
            },
            {
              text: 'Province : ' + this.detail.province,
              style: 'normalText',
            },
            {
              text: 'Postal Code : ' + this.detail.postalCode,
              style: 'normalText',
            },
            {
              text: 'Home Number : ' + this.detail.homeNumber,
              style: 'normalText',
            },
            {
              text: 'Mobile Number : ' + this.detail.mobile,
              style: 'normalText',
            },
            {
              text: 'Date of Birth : ' + this.detail.dob ? this.detail.dob.toDate().toDateString() : '',
              style: 'normalText',
            },
            {
              text: 'SIN : ' + this.detail.SIN,
              style: 'normalText',
            },
            {
              text: 'Student Number : ' + this.detail.studentNo,
              style: 'normalText',
            },
            {
              text: 'Treaty Number : ' + this.detail.treatyNo,
              style: 'normalText',
            },
            {
              text: 'Email : ' + this.detail.email,
              style: 'normalText',
            },
            {
              text: 'Marital Status : ' + this.maritalStatus[Number(this.detail.maritalStatus)].name,
              style: 'normalText',
            }],
            (this.detail.maritalStatus == '2' || this.detail.maritalStatus == '3') ?
              [{
                text: 'Spouse Information',
                style: 'title',
              },
              {
                text: this.detail.spouseFirstName ? 'Spouse First Name : ' + this.detail.spouseFirstName : "",
                style: 'normalText',
              },
              {
                text: this.detail.spouseLastName ? 'Spouse Last Name : ' + this.detail.spouseLastName : "",
                style: 'normalText',
              },
              {
                text: this.detail.spouseMiddleName ? 'Spouse Middle Name : ' + this.detail.spouseMiddleName : '',
                style: 'normalText',
              },
              {
                text: this.detail.spouseLiveTogether ? 'Will your spouse be living with you during the time of your funding : ' + this.detail.spouseLiveTogether : "",
                style: 'normalText',
              },
              {
                text: this.detail.spouseEmployed ? 'Will your spouse be employed? funded student? : ' + this.detail.spouseEmployed : "",
                style: 'normalText',
              }] : ''
          ]
        },
        {
          columns: [
            (this.detail.dependents.length > 0) ?
              [
                {
                  text: 'Dependents',
                  style: 'title',
                },
                {
                  table: {
                    widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
                    body: [
                      [
                        {
                          text: 'First Name',
                          style: 'header',
                          bold: true,
                        },
                        {
                          text: 'Last Name',
                          style: 'header',
                          bold: true,
                        },
                        {
                          text: 'Date of Birth',
                          style: 'header',
                          bold: true,
                        },
                        {
                          text: 'Age',
                          style: 'header',
                          bold: true,
                        },
                        {
                          text: 'Grade',
                          style: 'header',
                          bold: true,
                        },
                        {
                          text: 'school',
                          style: 'header',
                          bold: true,
                        },
                        {
                          text: 'comment',
                          style: 'header',
                          bold: true,
                        },
                      ],
                      ...this.detail.dependents.map(child => {
                        return [{ text: child.first_name ? child.first_name : '', style: 'normalText' }, { text: child.last_name ? child.last_name : '', style: 'normalText' }, { text: child.dob ? child.dob.toDate().toDateString() : '', style: 'normalText' }, { text: child.age ? child.age : '', style: 'normalText' }, { text: child.grade ? child.grade : '', style: 'normalText' }, { text: child.school ? child.school : '', style: 'normalText' }, { text: child.comments ? child.comments : '', style: 'normalText' }];
                      })
                    ]
                  }
                }
              ] : ''
          ]
        },
        {
          text: 'Assistance Required',
          style: 'title',
        },
        {
          text: 'Assistance Type : ' + this.detail.assistanceType,
          style: 'normalText'
        },
        {
          text: 'Institution Name : ' + this.detail.institution,
          style: 'normalText'
        },
        {
          text: 'Location: ' + this.detail.location,
          style: 'normalText'
        },
        {
          text: 'Program : ' + this.detail.program,
          style: 'normalText'
        },
        {
          text: this.detail.startDate ? 'Starts On : ' + this.detail.startDate.toDate().toDateString() : 'Starts On : ' + '',
          style: 'normalText'
        },
        {
          text: this.detail.endDate ? 'Ends On : ' + this.detail.endDate.toDate().toDateString() : 'Ends On : ' + '',
          style: 'normalText'
        },
        {
          text: 'length : ' + this.detail.length,
          style: 'normalText'
        },
        {
          text: 'Years Completed: ' + this.detail.yearsCompleted,
          style: 'normalText'
        },
        {
          text: 'Terms',
          style: 'normalText'
        },
        {
          text: 'Fall : ' + this.detail.terms.fall,
          style: 'normalText'
        },
        {
          text: 'Winter : ' + this.detail.terms.winter,
          style: 'normalText'
        },
        {
          text: 'Inter-Session : ' + this.detail.terms.inter_session,
          style: 'normalText'
        },
        {
          text: 'Summe-Session : ' + this.detail.terms.summer_session,
          style: 'normalText'
        },
        {
          columns: [
            (this.detail.documents && this.detail.documents.length > 0) ?
              [
                {
                  text: 'Other Information',
                  style: 'title',
                  pageBreak: 'before'
                },
                {
                  text: 'Document Uploaded',
                  style: 'subTitle',
                },
                {
                  table: {
                    widths: ['auto', 'auto', 'auto'],
                    body: [
                      [
                        {
                          text: 'Document',
                          style: 'header',
                          bold: true,
                        },
                        {
                          text: 'Document Description',
                          style: 'header',
                          bold: true,
                        },
                        {
                          text: 'Document Type',
                          style: 'header',
                          bold: true,
                        },
                      ],
                      ...this.detail.documents.map(doc => {
                        return [{
                          text: doc.name,
                          link: doc.link,
                          style: ['links', 'normalText'],
                        }, { text: doc.type, style: 'normalText' }, { text: doc.docType, style: 'normalText' }];
                      })
                    ]
                  }
                }
              ] : ''
          ]
        },
        {
          text: "Signature",
          style: 'title',
          pageBreak: 'before'
        },
        {
          image: this.detail.signature
        },
      ],
      styles: {
        mainTitle: {
          bold: true,
          fontSize: 16,
          alignment: 'left',
          margin: [0, 0, 0, 20]
        },
        title: {
          bold: true,
          fontSize: 14,
          alignment: 'center',
          margin: [0, 10, 0, 10]
        },
        subTitle: {
          bold: true,
          fontSize: 12,
          alignment: 'left',
          margin: [0, 10, 0, 10]
        },
        normalText: {
          fontSize: 12,
        },
        header: {
          fontSize: 12,
          bold: true,
          alignment: 'center',
        },
        links: {
          color: 'blue',
          decoration: 'underline',
          margin: [0, 5, 0, 5]
        }
      }
    };
  }

  async getLogo() {
    let imageLink = "assets/img/PSSSP-image.jpg";
    const image = await this.getBase64FromUrl(imageLink);
    if (image) {
      return {
        image: image,
        width: 100,
        alignment: 'left'
      }
    }
  }

  getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      }
    });
  }

  async openPdf() {
    const documentDefinition = await this.getDocumentDefinition();
    pdfMake.createPdf(documentDefinition).open();
  }

  async downloadPdf() {
    const documentDefinition = await this.getDocumentDefinition();
    pdfMake.createPdf(documentDefinition).download();
  }

  async printPdf() {
    const documentDefinition = await this.getDocumentDefinition();
    pdfMake.createPdf(documentDefinition).print();
  }

}
