<div class="body-h">
  <div class="col l12  header-setting">
    <h4 class="icon-text"> <i class="material-symbols-outlined  p-r-1" (click)="backClicked()"> arrow_back </i>Edit Event</h4>
  </div>

  <div class="row col s12 StickyContent" *ngIf="myModel">
    <div class="col l1"></div>
    <div class="col s12 l10 ">
      <app-content-editor [contentType]="'Event'" [model]="myModel" [actionType]="'Edit'"
        (contentUpdated)="updateEventHandler($event)" (contentArchived)="archiveEventHandler($event)"
        (contentUnArchived)="unArchiveEventHandler($event)" (contentDeleted)="deleteEventHandler($event)">
      </app-content-editor>
    </div>
    <div class="col l1"></div>
  </div>
</div>