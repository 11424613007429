import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { GovernanceService } from 'src/app/services/firebase/governance.service';
import { GovernanceModel } from 'src/app/models/GovernanceModel';
import { environment } from 'src/environments/environment';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';

@Component({
  selector: 'app-governance',
  templateUrl: './governance.component.html',
  styleUrls: ['./governance.component.css']
})
export class GovernanceComponent implements OnInit {

  defaultThemeColor = environment.appTheme.themeColor;
  themeColor = environment.appTheme.adminThemeColor;
  buttonType: any;

  governances: any;
  loadingTxt = "Please wait";
  fixed_governance_id = "oiSioV2aie1zRoPprXGE";
  governance: GovernanceModel;
  addGovernanceForm: UntypedFormGroup;

  constructor(private governanceService: GovernanceService,
    private router: Router,
    private formBuilder: UntypedFormBuilder,
    public appSettingsService: AppSettingsService,
  ) {
    this.themeColor = this.appSettingsService.getAppThemeColor() ? this.appSettingsService.getAppThemeColor() : this.defaultThemeColor;
    this.buttonType = this.appSettingsService.getButtonType() ? this.appSettingsService.getButtonType() : 'rectangularButton';
  }

  ngOnInit() {
    this.governanceService.getGovernanceList().valueChanges().subscribe(x => {
      if (x.length > 0) {
        this.governances = x;
        this.router.navigate(['/admin/dashboard-sub-menu/edit-governance'], { state: { governance_id: this.governances[0].id } });
      } else {
        let model = new GovernanceModel();
        model.chiefs = [];
        model.councillors = [];
        model.name = 'Current Governance';
        this.governanceService.addGovernance(model);
      }
    });
    
    this.addGovernanceForm = this.formBuilder.group({
      name: ['', [Validators.required]]
    });
  }

  editGovernanceData(event: Event, id?: any) {
    event.preventDefault();
    this.router.navigate(['/admin/dashboard-sub-menu/edit-governance'], { state: { governance_id: id } });
  }

  PostGovernance(value: any) {
    this.governance = new GovernanceModel();
    this.governance.name = value.value.name;
    this.governanceService.addGovernance(this.governance);
  }
}
