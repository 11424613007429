<div *ngIf="step1" class="row" >
    <div class="row card white" >
            <div class="row center">
                <img *ngIf="nationofUse === 'Demo'" class="responsive-img" width="150px" height="150px" src="../../../../assets/icons/Demo/Logo-512x512.png">
                <img *ngIf="nationofUse === 'Pbcn'" class="responsive-img" width="150px" height="150px" src="../../../../assets/icons/PBCN/pbcn256x256.png">
                <img *ngIf="nationofUse === 'Batc'" class="responsive-img" width="150px" height="150px" src="../../../../assets/icons/BATC/batc256x256.png">
                <img *ngIf="nationofUse === 'DeneTha'" class="responsive-img" width="150px" height="150px" src="../../../../assets/icons/Denetha/DeneTha[1916].png">
                <img *ngIf="nationofUse === 'Montana'" class="responsive-img" width="150px" height="150px" src="../../../../assets/icons/Montana/PlayStore-Icon[2035].png">
                <img *ngIf="nationofUse === 'LouisBull'" class="responsive-img" width="150px" height="150px" src="../../../../assets/icons/LuisBull/luisBull.jpg">
                <img *ngIf="nationofUse === 'Lrrcn'" class="responsive-img" width="150px" height="150px" src="../../../../assets/icons/LRRCN/lrrcn384x384.jpg">
                <img *ngIf="nationofUse === 'Oneida'" class="responsive-img" width="150px" height="150px" src="../../../../assets/icons/Oneida/Logo-512x512.png">
              </div>
    
        <div class="row">
            <h6 class="cart-title center-align" style="text-transform: uppercase;"><strong>{{nationName}} <br/>
                    POST-SECONDARY STUDENT SUPPORT PROGRAM APPLICATION FORM – Continuing Student</strong>
            </h6>
            <ul class="center-align" *ngIf="nationINFO">                
                <li>{{nationINFO.address + ' ' + nationINFO.Town + ' ' + nationINFO.Postal + ' ' + nationINFO.Prov}} </li>
                <li><strong>Phone: </strong> {{nationINFO.telephone}}</li>
                <li *ngIf="nationINFO.fax"><strong>Fax: </strong> {{nationINFO.fax}}</li>
            </ul>    
        </div>

        <div class="col s12" style="text-align: justify;">
            <p><strong>Fully completed and signed original</strong> application forms and related documentation must be
                received at the Post-Secondary Office by the following deadline dates: </p>
            <p class="center-align"><strong>June 15th for Fall Semester (September) commencement</strong></p>
            <p class="center-align"><strong>March 31st for Intersession and Summer school commencement</strong></p>
            <p class="center-align">**All continuing students must apply for Fall/Winter and Spring, if needed.</p>
            <p><strong>PLEASE NOTE: </strong>Faxed applications will not be accepted for final approval (must be
                followed by original application). Scanned and emailed applications will be accepted.
                Late applications will not be considered for funding.
                Incomplete applications will not be accepted
            </p>

            <ul class="collection with-header">
                <li class="collection-header">
                    <h6><strong>APPLICATIONS WILL NOT BE CONSIDERED WITHOUT THE FOLLOWING DOCUMENTS:</strong></h6>
                </li>
                <li class="collection-header">
                    <p><strong>PLEASE CHECK OFF DOCUMENTATION ATTACHED:</strong></p>
                </li>
                <li class="collection-item">Transcript/Marks (Post-Secondary)</li>
                <li class="collection-item">Letter of Acceptance (If new program)</li>
                <li class="collection-item">Registration of classes</li>
                <li class="collection-item">Copy of Hospitalization Card ( for new Dependents)</li>
                <li class="collection-item">Copy of Treaty/Status card</li>
                <li class="collection-item">Banking information for direct deposit</li>
                <li class="collection-item">Letter of Intent (Future Goals and Academic plans)</li>
            </ul>
        </div>

        <div class="row col s12">
            <button class="col s12 btn waves-effect green" type="button" style="margin-top: 15px; margin-bottom: 15px"
            *ngIf="create_new_app" (click)="start_application()">Start Your Application
            </button>
            <button class="col s12 btn waves-effect green" type="button" style="margin-top: 15px; margin-bottom: 15px"
            *ngIf="update_app" (click)="start_application()">Amend Your Application
            </button>
            <button class="col s12 btn waves-effect green" type="button" style="margin-top: 15px; margin-bottom: 15px"
            *ngIf="disable_app" >Your Application is Locked
            </button>
        </div>
     </div>

     <button class="col s12 btn green" type="button" (click)="newApplication()">Start Your Application
     </button>
</div>

<div *ngIf="step2" class="row">

    <h6 class="center-align"><span class="red-text">PART A:</span> Student Information</h6>

    <form  class="row col s12" [formGroup]="studentInfoForm" (ngSubmit)="submitStudentInfo(studentInfoForm.value)">
      
        <div class="row card-panel">
            <div class="input-field col s12 m6">
                <i class="material-icons prefix green-text">location_on</i>
                <input id="name" type="text" formControlName="name"  style="text-transform: capitalize;">
                <label for="name">Name</label>
            </div>

            <div class="input-field col s12 m6">
                <i class="material-icons prefix green-text">location_on</i>
                <input id="dob" type="text" formControlName="dob" style="text-transform: capitalize;">
                <label for="dob">Date Of Birth</label>
            </div>
        </div>
            
        <div class="row">
            <h6 class=""><strong>MAILING ADDRESS: </strong></h6>
         </div>
            <div class="row card-panel">
           
                <div class="row">
                    <div class="input-field col s12 m6">
                        <i class="material-icons prefix green-text">location_on</i>
                        <input id="address" type="text" formControlName="address" class="validate" style="text-transform: capitalize;">
                        <label for="address">P.O.Box/Address</label>
                    </div>

                    <div class="input-field col s12 m6">
                        <i class="material-icons prefix purple-text">location_city</i>
                        <input id="city" type="text" formControlName="city" class="validate" style="text-transform: capitalize;">
                        <label for="city">City<span class="red-text">*</span></label>
                        <div *ngIf="(city.dirty || city.touched) && city.invalid">
                            <small *ngIf="city.errors.required" class="red-text">City is required</small>
                        </div>
                    </div>
    
                    <div class="input-field col s12 m6">
                        <i class="material-icons prefix pink-text">add_location</i>
                        <select id="province" formControlName="province" class="validate">
                            <option *ngFor="let p of provinces" [value]="p.code">{{p.name}}</option>
                        </select>
                        <label for="province">Province<span class="red-text">*</span></label>
                    </div>
              
                    <div class="input-field col s12 m6">
                        <i class="material-icons prefix purple-text">mail</i>
                        <!-- <input id="postal_code" [textMask]="{mask: postalCodeMask, guide: true}" type="text" formControlName="postal_code" class="validate" style='text-transform:uppercase'> -->
                        <input id="postal_code" type="text" formControlName="postal_code" class="validate" style='text-transform:uppercase'>
                        <label for="postal_code">Postal Code<span class="red-text">*</span></label>
                        <div *ngIf="(postal_code.dirty || postal_code.touched) && postal_code.invalid">
                            <small *ngIf="postal_code.errors.required" class="red-text"> The Postal Code is required.
                            </small>
                        </div>
                    </div>
                </div>
            </div>

            <div class="row">
                <h6 class=""><strong>OTHER REQUIRED INFORMATION: </strong> </h6>
            </div>
            <div class="row card-panel">
           
                <div class="row">
                    <div class="input-field col s12 m6">
                        <i class="material-icons prefix blue-text">phone</i>
                        <!-- <input id="home_number" [textMask]="{mask: phoneNumberMask, guide: true}" type="text" formControlName="home_number" class="validate"> -->
                        <input id="home_number"  type="text" formControlName="home_number" class="validate">
                        <label for="home_number">Home Phone #</label>
                    </div>
    
                    <div class="input-field col s12 m6">
                        <i class="material-icons prefix blue-text">phone_iphone</i>
                        <!-- <input id="mobile" [textMask]="{mask: phoneNumberMask, guide: true}" type="text" formControlName="mobile" class="validate"> -->
                        <input id="mobile"  type="text" formControlName="mobile" class="validate">
                        <label for="mobile">Mobile Number<span class="red-text">*</span></label>
                        <div *ngIf="(mobile.dirty || mobile.touched) && mobile.invalid">
                            <small *ngIf="mobile.errors.required" class="red-text">Mobile Number is Required.</small>
                        </div>
                    </div>
    
                    <div class="input-field col s12 m6" >
                        <i class="material-icons prefix blue-text">tag</i>
                        <!-- <input id="HealthCareNo" type="text" formControlName="HealthCareNo" class="validate" [textMask]="{mask: SINMask, guide: true}" [readonly]="hasHealthCareNo"> -->
                        <input id="HealthCareNo" type="text" formControlName="HealthCareNo" class="validate"  [readonly]="hasHealthCareNo">
                        <label for="HealthCareNo">Health Card No  </label>
                    </div>
    
                    <div class="input-field col s12 m6" >
                        <i class="material-icons prefix blue-text">pin</i>
                        <!-- <input id="isn_no" type="text" formControlName="isn_no" class="validate" [textMask]="{mask: isnMask, guide: true}" [readonly]="hasISN"> -->
                        <input id="isn_no" type="text" formControlName="isn_no" class="validate"  [readonly]="hasISN">
                        <label for="isn_no">Indian Status Number</label>
                    </div>
    
                    <div class="input-field col s12 m6" >
                        <i class="material-icons prefix pink-text">apps</i>
                        <!-- <input id="SIN" type="text" formControlName="SIN" class="validate" [textMask]="{mask: SINMask, guide: true}" [readonly]="hasSIN" > -->
                        <input id="SIN" type="text" formControlName="SIN" class="validate"  [readonly]="hasSIN" >
                        <label for="SIN">SIN </label>
                        <div *ngIf="(SIN.dirty || SIN.touched) && SIN.invalid">
                            <small *ngIf="SIN.errors.minlength || SIN.errors.maxlength" class="red-text">The SIN must be 9 Digits.</small>
                        </div>
                    </div>
                    
                    <div class="input-field col s12 m6">
                        <i class="material-icons prefix purple-text">mail</i>
                        <input id="email" type="text" formControlName="email" class="validate">
                        <label for="email">Email</label>
                        <div *ngIf="(email.dirty || email.touched) && email.invalid">
                            <small *ngIf="email.errors.email" class="red-text">Invalid Email Address</small>
                        </div>
                    </div>

                    <div class="input-field col s12 m6">
                        <i class="material-icons prefix blue-text">people</i>
                        <select id="marital_status" formControlName="marital_status" class="validate" (change)="maritalStatusChange($event.target.value)">
                           <option value="" disabled selected>Select</option>
                            <option *ngFor="let status of maritalStatusList" [value]="status">{{status}}</option>
                        </select>
                        <label for="marital_status">Marital Status<span class="red-text">*</span></label>
                        <div *ngIf="(marital_status.dirty || marital_status.touched) && marital_status.invalid"> <small class="red-text"> The Marital Status is required.</small>
                        </div>
                    </div>

                    <div class="input-field col s12 m6">
                        <i class="material-icons prefix blue-text">people</i>
                        <select id="reside" formControlName="reside" class="validate" >
                           <option value="" disabled selected>Select</option>
                           <option value="yes" selected>On Reserve</option>
                           <option value="no" selected>Off Reserve</option>
                        </select>
                        <label for="reside">Usually Reside: <span class="red-text">*</span></label>
                        <div *ngIf="(reside.dirty || reside.touched) && reside.invalid"> <small class="red-text"> Please Select An Option.</small>
                        </div>
                    </div>

                    <div class="input-field col s12 m6">
                        <i class="material-icons prefix blue-text">business</i>
                        <select formControlName="community" class="validate" (change)="communitySelected($event.target.value)">
                            <option value="" disabled selected>Choose your Community</option>
                            <option *ngFor="let c of communityList" [value]="c.community">{{c.community}}</option> 
                            <option value="Other">Other</option>
                        </select>
                        <label for="community">Community</label>
                    </div>
    
                    <div class="input-field col s12 m6" *ngIf="otherLocation" >  
                        <i class="material-icons prefix blue-text">location_on</i>
                        <input id="other_location" type="text" formControlName="other_location" class="validate" style="text-transform: capitalize;">
                        <label for="other_location">Other Location</label>
                    </div>
                </div>
            </div>

            <div class="row">
                <h6 class=""><strong>BANK INFORMATION: </strong> </h6>
            </div>
            <div class="row card-panel">               
                <div class="row">

                    <div class="input-field col s12 m6" >
                        <i class="material-icons prefix blue-text">description</i>
                        <input id="bank_name" type="text" formControlName="bank_name" class="validate" style="text-transform: capitalize;" [readonly]="hasBank">
                        <label for="bank_name">Bank Name</label>
                    </div>
               
                    <div class="input-field col s12 m6" >
                        <i class="material-icons prefix blue-text">sms</i>
                        <!-- <input id="bank_no" type="text" formControlName="bank_no" class="validate" style="text-transform: capitalize;" [textMask]="{mask: bankNoMask, guide: true}" [readonly]="hasBankNo"> -->
                        <input id="bank_no" type="text" formControlName="bank_no" class="validate" style="text-transform: capitalize;" [readonly]="hasBankNo">
                        <label for="bank_no">Bank No</label>
                        <div *ngIf="(bank_no.dirty || bank_no.touched) && bank_no.invalid">
                            <small *ngIf="bank_no.errors.minlength || bank_no.errors.maxlength" class="red-text">The bank number must be 3 Digits.</small>
                        </div>
                    </div>

                    <div class="input-field col s12 m6" >
                        <i class="material-icons prefix blue-text">lock</i>
                        <!-- <input id="transit_no" type="text" formControlName="transit_no" class="validate" style="text-transform: capitalize;" [textMask]="{mask: branchNoMask, guide: true}" [readonly]="hasTransitNo"> -->
                        <input id="transit_no" type="text" formControlName="transit_no" class="validate" style="text-transform: capitalize;" [readonly]="hasTransitNo">
                        <label for="transit_no">Transit No</label>
                     
                    </div>
               
                    <div class="input-field col s12 m6" >
                        <i class="material-icons prefix blue-text">paid</i>
                        <input id="account_no" type="text" formControlName="account_no" class="validate" style="text-transform: capitalize;" [readonly]="hasAccountNo">
                        <label for="account_no">Account No</label>
                    </div>
                </div>
            </div>

            <div class="row">
                <h6 class=""><strong>DURING SPONSORSHIP PERIOD:</strong> </h6>
            </div>

            <div class="row card-panel">
                <div class="row">
                    <p>I Will Be Employed</p>
                    <label class="col s6">
                        <input class="with-gap" formControlName="employed" name="employed" type="radio" value="yes" (change)="onEmployedChecked($event.target.value)" />
                        <span>Yes</span>
                    </label>
                    <label class="col s6">
                        <input class="with-gap" formControlName="employed" name="employed" type="radio" value="no" (change)="onEmployedChecked($event.target.value)" />
                        <span>No</span>
                    </label>                    
                </div>
            </div>

            <div class="row card-panel" *ngIf="isEmployed">
                <div class="input-field col s12" >
                    <i class="material-icons prefix blue-text">business</i>
                    <input id="employer" type="text" formControlName="employer" class="validate" style="text-transform: capitalize;">
                    <label for="employer">Employer Name</label>
                </div>
            </div>

            <div class="row card-panel">    
                <div class="row">
                    <p>Leave Of Absence</p>
                    <label class="col s6">
                        <input class="with-gap" formControlName="leaveofabsence" name="leaveofabsence" type="radio" value="yes" (change)="onleaveofabsenceChange($event.target.value)"/>
                        <span>Yes</span>
                    </label>
                    <label class="col s6">
                        <input class="with-gap" formControlName="leaveofabsence" name="leaveofabsence" type="radio" value="no" (change)="onleaveofabsenceChange($event.target.value)"/>
                        <span>No</span>
                    </label>                    
                </div>
            </div>

            <div class="row card-panel" *ngIf="leaveOfAbsence">
                <div class="row">
                    <p>With Pay</p>
                    <label class="col s6">
                        <input class="with-gap" formControlName="withpay" name="withpay" type="radio" value="yes" (change)="onWithPayChecked($event.target.value)" />
                        <span>Yes</span>
                    </label>
                    <label class="col s6">
                        <input class="with-gap" formControlName="withpay" name="withpay" type="radio" value="no" (change)="onWithPayChecked($event.target.value)" />
                        <span>No</span>
                    </label>                    
                </div>
            </div>

            <div class="row card-panel" *ngIf="hasIncome">
                <div class="input-field col s12 ">
                    <i class="material-icons prefix green-text">paid</i>
                    <input id="income" type="number" formControlName="income" (change)="setIncomesTwoNumberDecimal($event.target.value)" min="0" max="10000" step="0.25" value="0.00">
                    <label for="income">My Income $</label>
                </div>
            </div>

            <div class="row card-panel" *ngIf="hasSpouse">
                <div class="row">
                    <p>Spouse Classification</p>
                    <label class="col s6">
                        <input class="with-gap" formControlName="spouse_classification" name="spouse_classification" type="radio" value="Dependent" (change)="onspouseClassificationChecked($event.target.value)" />
                        <span>Dependent</span>
                    </label>
                    <label class="col s6">
                        <input class="with-gap" formControlName="spouse_classification" name="spouse_classification" type="radio" value="Employed" (change)="onspouseClassificationChecked($event.target.value)" />
                        <span>Employed</span>
                    </label>                    
                </div>
            </div>

            <div class="row card-panel" *ngIf="spousehasIncome">
                <div class="input-field col s12 ">
                    <i class="material-icons prefix green-text">paid</i>
                    <input id="spouse_income" type="number" formControlName="spouse_income" (change)="setIncomesTwoNumberDecimal($event.target.value)" min="0" max="10000" step="0.25" value="0.00">
                    <label for="income">Spouse Income $</label>
                </div>
            </div>

            <div class="row">
                <h6><strong>HOUSING REQUIREMENTS: </strong></h6>
            </div>
            <div class="row card-panel">    
                <div class="row">
                    <label class="col s12 m6">
                        <input class="with-gap" formControlName="house_status" name="house_status" type="radio" value="1"  />
                        <span>Rent Only</span>
                    </label>
                    <label class="col s12 m6">
                        <input class="with-gap" formControlName="house_status" name="house_status" type="radio" value="2" />
                        <span>Rent and Damage Deposit</span>
                    </label>

                    <label class="col s12 m6">
                        <input class="with-gap" formControlName="house_status" name="house_status" type="radio" value="3" />
                        <span>No Rent Required</span>
                    </label>                
                </div>
            </div>

            <button class="col s12 btn green" type="submit"
            [disabled]="!studentInfoForm.valid" >Next
            </button>
    </form>
</div>

<div *ngIf="step3" class="row">
    <h6 class="center-align"><span class="red-text">PART B:</span> Family Members</h6>
</div>