import { transition, trigger, useAnimation } from '@angular/animations';
import { Component, OnInit } from '@angular/core';
import { bounceIn } from 'ng-animate';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { DepartmentService } from 'src/app/services/firebase/department.service';
import { environment } from 'src/environments/environment';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';

declare var $: any;

@Component({
  selector: 'app-dep-sub-menu',
  templateUrl: './dep-sub-menu.component.html',
  styleUrls: ['./dep-sub-menu.component.css'],
  animations: [
    trigger('swing', [transition('* => *', useAnimation(bounceIn))])
  ],
})
export class DepSubMenuComponent implements OnInit {

  id: any;
  depname: any;
  nationOfUse =  environment.firstNation.name;

  defaultThemeColor = environment.appTheme.themeColor;
  themeColor: any;
  buttonType: any;

  selectedMenu = {
    name: 'Department Info',
    icon: 'grading',
  }

  constructor(private location: Location, private router: Router, private settingsService: AppSettingsService, private route: ActivatedRoute, private depservice: DepartmentService) {
    this.themeColor = this.settingsService.getAppThemeColor() ? this.settingsService.getAppThemeColor() : this.defaultThemeColor;
    this.buttonType = this.settingsService.getButtonType() ? this.settingsService.getButtonType() : 'rectangularButton';
   }

  ngOnInit() {
  
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });

    //this.router.navigate(['app/departments/dep-sub-menu']);
    setTimeout(() => {
      // $('.dropdown-trigger-dep').dropdown({
      //   constrainWidth: false,
      //   isScrollable: true,
      // });
      $('.dropdown-trigger').dropdown({
        constrainWidth: false,
        isScrollable: true,
      });
      $('.tooltipped').tooltip();
      $('.tabs').tabs();
    }, 25);
    
    if (this.id) {
      this.router.navigate(['department-sub-menu', this.id, 'view-department-info', this.id]);
      this.depservice.getDepartmentByID(this.id).valueChanges().subscribe(dep => {
        if (dep) {
          this.depname = dep.name;
        }
      });
    }
  }

  mangeSubDepartments(){
    this.select('Sub Departments', 'account_tree')
    this.router.navigate(['department-sub-menu', this.id, 'manage-sub-department']);
  }

  select(menu, icon) {
    this.selectedMenu.name = menu;
    this.selectedMenu.icon = icon;
  }

  // navigate(){
    
  // }

  backClicked() {
    this.router.navigate(['/departments']);

  }



}
