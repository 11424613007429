import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { SupplementalSupportModel } from 'src/app/models/supplementalSupportModel';
import { DocumentsService } from 'src/app/services/api/documents.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { toast } from 'materialize-css';
import { environment } from 'src/environments/environment';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

declare var $: any;
declare var navigator: any;

@Component({
  selector: 'app-support-new',
  templateUrl: './support-new.component.html',
  styleUrls: ['./support-new.component.css']
})
export class SupportNewComponent implements OnInit {
  isCordova = false;

  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  bdList: any[];
  selectedBD : any;
  step0 = true;
  step1 = false;
  step2 = false;
  userID: 0;
  applicantID = 0;
  model: SupplementalSupportModel;
  supplementalSupportForm: UntypedFormGroup;
  preview: any;
  binDoc: File;
  fileName: string;
  supportType: any = ['Fuel/Heating', 'Utilities/Hydro', 'Water', 'Sewer/Waste Water',
    'Taxes', 'Telephone', 'Insurance', 'Accommodations'];

  monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'];

  thisYear = new Date().getFullYear();
  thisMonth = this.monthNames[new Date().getMonth()];
  previousYear: number;
  previousMonth: string;

  public amountMask = createNumberMask({
    prefix: '$ ',
    allowDecimal: true,
    integerLimit: 4,
  });

  constructor(private fb: UntypedFormBuilder, private location: Location,
    private documentsService: DocumentsService, private authService: AuthService) {
    if (typeof window['cordova'] !== 'undefined') {
      this.isCordova = true;
    }
  }

  ngOnInit() {
    if (localStorage.getItem('applicantID')) {
      this.applicantID = Number(localStorage.getItem('applicantID'));
    } else {
      this.applicantID = this.authService.getCurrentApplicantID();
    }

    this.documentsService.getLast2MonthsBDByApplicantID(this.applicantID).subscribe(x => {
      if (x) {
        this.setDates();
        // tslint:disable-next-line: max-line-length
        //const filtredTable = x.filter(el => (el.Month === this.thisMonth && el.Year === this.thisYear) || (el.Month === this.previousMonth && el.Year === this.previousYear));
        //if (filtredTable.length > 0) {
          this.bdList = x;
        //}
      }
    });
  }

  get amount() { return this.supplementalSupportForm.get('amount'); }
  get description() { return this.supplementalSupportForm.get('description'); }

  bdSelected(value: any) {
    this.supplementalSupportForm = this.fb.group({
      description: ['', [Validators.required]],
      amount: ['', [Validators.required]],
      document: ['']
    });

    this.selectedBD = value;

    this.step0 = false;
    this.step1 = true;

    setTimeout(() => {
      $('select').formSelect();
    }, 25);
  }

  back1() {
    this.step1 = false;
    this.step0 = true;
  }

  handleFileInput(files: FileList) {
    if (files) {
      this.binDoc = files[0];
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = () => {
        this.fileName = files[0].name;
        this.preview = reader.result as string;
      };
    }
  }

  handleCameraInput() {
    navigator.camera.getPicture(onSuccess, onFail, {
      quality: 100,
      saveToPhotoAlbum: true,
      destinatinType: navigator.camera.DestinationType.DATA_URL
    });

    function onSuccess(imageData) {
      this.preview = 'data:image/jpeg;base64,' + imageData;
      this.fileName = 'Photo From Gallery';
    }

    function onFail(message) {
      console.log(message);
    }
  }

  capturePhoto() {
    // Take picture using device camera and retrieve image as base64-encoded string
    navigator.camera.getPicture(this.onPhotoDataSuccess, this.onFail, {
      quality: 50,
      destinationType: navigator.camera.DestinationType.DATA_URL
    });
  }

  getPhotoFromLibrary() {
    // Retrieve image file location from specified source
    navigator.camera.getPicture(this.onPhotoURISuccess, this.onFail, {
      quality: 50,
      destinationType: navigator.camera.DestinationType.DATA_URL,
      sourceType: navigator.camera.PictureSourceType.PHOTOLIBRARY,
    });
  }

   onPhotoURISuccess(imageURI) {
    console.log(imageURI);
    this.preview = 'data:image/jpeg;base64,' + imageURI;
  }

  onPhotoDataSuccess(imageData) {
    // Uncomment to view the base64 encoded image data
    console.log(imageData);
    this.preview = 'data:image/jpeg;base64,' + imageData;
  }

   onFail(message) {
    console.log('Failed because: ' + message);
  }

  // getPhotoFromAlbum() {
  //   // Retrieve image file location from specified source
  //   navigator.camera.getPicture(onPhotoURISuccess, onFail, {
  //     quality: 50,
  //     destinationType: navigator.camera.DestinationType.DATA_URL,
  //     sourceType: navigator.camera.PictureSourceType.SAVEDPHOTOALBUM,
  //   });

  // }

  removeFile() {
    this.fileName = null;
    this.preview = null;
    this.supplementalSupportForm.patchValue({
      document: ''
    });
  }

  submitForm(value: any) {
    if (!this.preview) {
      alert('No File Selected. \n Please attach a supporting file to your request.');
      return;
    }

    const model = new SupplementalSupportModel();
    model.aid = this.applicantID;
    model.documentName = value.description;
    let amt = value.amount;
    amt = amt.replace('$ ', '');
    amt = amt.replace(',', '');
    model.amount = Number(amt);
   // model.base64Document = this.preview.replace(/^data:image\/[a-z]+;base64,/, '');
   // model.binDocument = this.binDoc;
    model.month = this.selectedBD.Month;
    model.year = this.selectedBD.Year;
    model.officerEmail = this.selectedBD.Email_Address;
    model.document_type = 1;

    // this.documentsService.postDocument(model).subscribe(x => {
    //   toast({ html: 'Supplemental Support Request Successfully Submitted!', classes: 'green' });
    //   this.step1 = false;
    //   this.step2 = true;
    // });

    this.documentsService.postDoc(this.binDoc).subscribe(x => {
      model.filestream_ID = x;
      this.documentsService.postDocInfo(model).subscribe(x2 => {
          toast({ html: 'Supplemental Support Request Successfully Submitted!', classes: 'green' });
          this.step1 = false;
          this.step2 = true;
        });
    });
  }

  backClicked() {
    this.location.back();
  }

  setDates() {
    this.thisYear = new Date().getFullYear();
    this.thisMonth = this.monthNames[new Date().getMonth()];
    if (this.thisMonth === 'January') {
      this.previousMonth = this.monthNames[11];
      this.previousYear = new Date().getFullYear() - 1;
    } else {
      this.previousMonth = this.monthNames[new Date().getMonth() - 1];
      this.previousYear = new Date().getFullYear();
    }
  }

}
