<div class="container body">


    <div class="row" *ngIf="bandList && currentUser">
        <p *ngIf="step > 0" class="center-align">Step {{step}} of 11</p>

        <!-- ----------------------------------- applicant Information ----------------------------------------------- -->
        <form *ngIf="step == 1" class="row col s12 card white" [formGroup]="section1Form"
            (ngSubmit)="submitSection1(section1Form.controls.applicant.value, 'applicant')">

            <div (click)="nextSection(section1Form.controls.applicant.valid)"
                class="row col s12 teal lighten-2 white-text">
                <button class="btn-flat right-align right valign-wrapper btn-large" type="submit"
                    [disabled]="!section1Form.controls.applicant.valid">
                    <i class="material-icons large white-text" style="font-size: 30px;">arrow_forward_ios</i>
                </button>
                <h5 class=" center-align">Section 1</h5>
            </div>

            <div class="row card-content" formGroupName="applicant">
                <span class="card-title">Applicant</span>

                <div class="input-field col s12 m6">
                    <i class="material-icons prefix red-text">person</i>
                    <input [readonly]="currentUser.GivenName" id="first_name" type="text" class="validate"
                        formControlName="first_name" autocomplete="off" style="text-transform: capitalize;">
                    <label for="first_name">Applicant's First Name<span class="red-text">*</span></label>
                    <div *ngIf="(first_name.dirty || first_name.touched) && first_name.invalid">
                        <small *ngIf="first_name.errors.required" class="red-text"> The First Name is required. </small>
                    </div>
                </div>

                <div class="input-field col s12 m6">
                    <i class="material-icons prefix red-text">person</i>
                    <input [readonly]="currentUser.LastName" id="last_name" type="text" class="validate"
                        formControlName="last_name" autocomplete="off" style="text-transform: capitalize;">
                    <label for="last_name">Applicant's Last Name<span class="red-text">*</span></label>
                    <div *ngIf="(last_name.dirty || last_name.touched) && last_name.invalid">
                        <small *ngIf="last_name.errors.required" class="red-text"> The Last Name is required. </small>
                    </div>
                </div>



                <div class="input-field col s12 m6">
                    <i class="material-icons prefix green-text">tag</i>
                    <!-- <input id="isn" type="text" formControlName="isn" [readonly]="hasISN"
                        [textMask]="{mask: isnMask, guide: false}" class="validate"> -->
                    <input id="isn" type="text" [inputMask]="isnMask" formControlName="isn" [readonly]="hasISN"
                        class="validate">
                    <label for="isn">Indian Status Number</label>
                </div>

                <div class="input-field col s12 m6">
                    <i class="material-icons prefix green-text">tag</i>
                    <!-- <input id="treaty_no" type="text" formControlName="treaty_no" class="validate"
                        (keyup)="onIsnChange($event.target.value)" [textMask]="{mask: treatyNoMask, guide: false}"> -->
                    <input id="treaty_no" type="text" formControlName="treaty_no" class="validate"
                        (keyup)="onIsnChange($event.target.value)">
                    <label for="treaty_no">Treaty Number</label>

                </div>

                <div class="input-field col s12 m6">
                    <i class="material-icons prefix purple-text">add_location</i>
                    <select formControlName="band_name" class="validate" id="band_name"
                        (change)="bandChange($event.target.value)">
                        <option value="" disabled selected>Choose your option</option>
                        <option *ngFor="let b of bandList" [value]="b">{{b}}</option>
                    </select>
                    <label for="band_name">Band Name</label>
                </div>

                <div class="input-field col s12 m6">
                    <i class="material-icons prefix blue-text">people</i>
                    <select id="marital_status" formControlName="marital_status" class="validate"
                        (change)="maritalStatusChange($event.target.value)">
                        <option value="" disabled selected>Choose your option</option>
                        <option *ngFor="let status of maritalStatus" [value]="status">{{status}}</option>
                    </select>
                    <label for="marital_status">Marital Status<span class="red-text">*</span></label>
                    <div *ngIf="(marital_status.dirty || marital_status.touched) && marital_status.invalid"> <small
                            class="red-text"> The Marital Status is required.</small>
                    </div>
                </div>

                <div class="input-field col s12 m6">
                    <i class="material-icons prefix blue-text">school</i>
                    <select id="edu_level" formControlName="edu_level" class="validate">
                        <option value="" disabled selected>Choose your option</option>
                        <option *ngFor="let edu of EduLevelList" [value]="edu">{{edu}}</option>
                    </select>
                    <label for="edu_level">Education Levels<span class="red-text">*</span></label>

                </div>

                <div class="input-field col s12 m6">
                    <i class="material-icons prefix pink-text">add_location</i>
                    <select formControlName="province" class="validate" (change)="provinceChange($event.target.value)">
                        <option *ngFor="let t of Provinces" [value]="t.code">{{t.name}}</option>
                    </select>
                    <label for="province">Province of membership<span class="red-text">*</span></label>
                </div>

                <div class="input-field col s12 m6">
                    <i class="material-icons prefix green-text">location_on</i>
                    <input id="address" type="text" formControlName="address" class="validate"
                        style="text-transform: capitalize;">
                    <label for="address">Address<span class="red-text">*</span></label>
                    <div *ngIf="(address.dirty || address.touched) && address.invalid">
                        <small *ngIf="address.errors.required" class="red-text">Address is required</small>
                    </div>
                </div>

                <div class="input-field col s12 m6">
                    <i class="material-icons prefix purple-text">location_city</i>
                    <input id="city" type="text" formControlName="city" class="validate"
                        (change)="cityChanged($event.target.value)" style="text-transform: capitalize;">
                    <label for="city">City<span class="red-text">*</span></label>
                    <div *ngIf="(city.dirty || city.touched) && city.invalid">
                        <small *ngIf="city.errors.required" class="red-text">City is required</small>
                    </div>
                </div>

                <div class="input-field col s12 m6">
                    <i class="material-icons prefix purple-text">mail</i>
                    <!-- <input id="postal_code" [textMask]="{mask: postalCodeMask, guide: true}" type="text"
                        formControlName="postal_code" class="validate" style='text-transform:uppercase'> -->
                    <input id="postal_code" type="text" [inputMask]="postalCodeMask" formControlName="postal_code"
                        class="validate" style='text-transform:uppercase'>
                    <label for="postal_code">Postal Code<span class="red-text">*</span></label>
                    <div *ngIf="(postal_code.dirty || postal_code.touched) && postal_code.invalid">
                        <small *ngIf="postal_code.errors.required" class="red-text"> The Postal Code is required.
                        </small>
                    </div>
                </div>

                <div class="row input-field col s12 m6">
                    <div class="row col s12">Mailing Address same as Home Address ? <span class="red-text">*</span>
                    </div>
                    <div class="row col s12">
                        <label class="col s6">
                            <input class="with-gap" formControlName="sameMailingAddress" name="sameMailingAddress"
                                type="radio" value="yes" (change)="onSameMailingAddressYes($event)" />
                            <span>Yes</span>
                        </label>
                        <label class="col s6">
                            <input class="with-gap" formControlName="sameMailingAddress" name="sameMailingAddress"
                                type="radio" value="no" (change)="onSameMailingAddressNo($event)" />
                            <span>No</span>
                        </label>
                    </div>

                    <div class="input-field col s12" *ngIf="showMailingAddress">
                        <i class="material-icons prefix purple-text">mail_outline</i>
                        <input id="mailing_address" type="text" formControlName="mailing_address" class="validate"
                            style="text-transform: capitalize;">
                        <label for="mailing_address">Mailing Address<span class="red-text">*</span></label>
                        <div *ngIf="(mailing_address.dirty || mailing_address.touched) && mailing_address.invalid">
                            <small *ngIf="mailing_address.errors.required" class="red-text">Mailing Address is
                                required</small>
                        </div>
                    </div>
                </div>

                <div class="input-field col s12 m6">
                    <i class="material-icons prefix green-text">house</i>
                    <input id="house_no" type="text" formControlName="house_no" class="validate">
                    <label for="house_no">House Number</label>
                </div>

                <div class="input-field col s12 m6">
                    <i class="material-icons prefix blue-text">phone</i>
                    <!-- <input id="telephone" [textMask]="{mask: phoneNumberMask, guide: true}" type="text"
                        formControlName="telephone" class="validate"> -->
                    <input id="telephone" type="text" [inputMask]="phoneInputMask" formControlName="telephone"
                        class="validate">
                    <label for="telephone">Home Number</label>
                </div>

                <div class="input-field col s12 m6">
                    <i class="material-icons prefix blue-text">phone_iphone</i>
                    <!-- <input id="mobile" [textMask]="{mask: phoneNumberMask, guide: true}" type="text"
                        formControlName="mobile" class="validate"> -->
                    <input id="mobile" type="text" [inputMask]="phoneInputMask" formControlName="mobile"
                        class="validate">
                    <label for="mobile">Mobile Number</label>
                </div>

                <div class="input-field col s12 m6">
                    <i class="material-icons prefix red-text">tag</i>
                    <input id="health_care_no" type="number" formControlName="health_care_no" class="validate">
                    <label for="health_care_no">Health Care No</label>
                </div>

                <div class="input-field col s12 m6">
                    <i class="material-icons prefix blue-text">today</i>
                    <!-- <input *ngIf="!dates[0].set" id="dob" type="text" formControlName="dob" class="validate"
                        [textMask]="{mask: dateMask, guide: true}" placeholder="dd/mm/yyyy"
                        (blur)="setDate(dob,0,$event.target.value)"> -->
                    <input *ngIf="!dates[0].set" id="dob" type="text" [inputMask]="dateInputMask" formControlName="dob"
                        class="validate" placeholder="dd/mm/yyyy" (blur)="setDate(dob,0,$event.target.value)">
                    <input *ngIf="dates[0].set" id="dob" type="text" formControlName="dob" class="validate"
                        placeholder="dd/mm/yyyy" (focus)="unSetDate(dob,0,$event.target.value)">
                    <label for="dob">Date of Birth (dd/mm/yyyy)<span class="red-text">*</span></label>
                    <div *ngIf="(dob.dirty || dob.touched) && dob.invalid">
                        <small *ngIf="dob.errors.required" class="red-text"> The Date Of Birth is required. </small>
                        <small *ngIf="dob.errors.invalidDate" class="red-text"> The Date invalid </small>
                    </div>
                </div>

                <div class="input-field col s12 m6">
                    <i class="material-icons prefix pink-text">apps</i>
                    <!-- <input [readonly]="currentUser.SIN" id="SIN" type="text" formControlName="SIN" class="validate"
                        [textMask]="{mask: SINMask}"> -->
                    <input [readonly]="currentUser.SIN" id="SIN" type="text" [inputMask]="SINMask" formControlName="SIN"
                        class="validate">
                    <label for="SIN">SIN</label>
                    <div *ngIf="(SIN.dirty || SIN.touched) && SIN.invalid">
                        <small *ngIf="SIN.errors.minlength || SIN.errors.maxlength" class="red-text">The SIN must be 9
                            Digits.</small>
                    </div>
                </div>
            </div>

            <button class="col s12 m5 offset-m2 l3 btn right waves-effect blue" type="submit"
                [disabled]="!section1Form.controls.applicant.valid" style="margin-bottom: 15px" title="Save">Next <i
                    class="material-icons right">arrow_forward</i></button>
        </form>

        <!-- ------------------------------------------ Spouse Information -------------------------------------------- -->
        <form *ngIf="step == 2" class="row col s12 card white" [formGroup]="section1Form"
            (ngSubmit)="submitSection1(section1Form.controls.spouse.value, 'spouse')">

            <div class="row col s12 teal lighten-2 white-text">
                <button class="btn-flat left-align left valign-wrapper btn-large" type="button" (click)="onBack()">
                    <i class="material-icons large white-text" (click)="onBack()" style="font-size: 30px;"
                        style="cursor: pointer;">arrow_back_ios</i>
                </button>
                <div class="col right" (click)="nextSection(section1Form.controls.spouse.valid)">
                    <button class="btn-flat right-align right valign-wrapper btn-large" type="submit">
                        <i class="material-icons large white-text"
                            style="font-size: 30px;">arrow_forward_ios</i></button>
                </div>
                <h5 class=" center-align">Section 1</h5>
            </div>

            <div formGroupName="spouse" class="row card-content" *ngIf="showSpouseInfo">

                <span class="card-title">Spouse</span>

                <div class="input-field col s12 m6">
                    <i class="material-icons prefix pink-text">person</i>
                    <input id="spouse_first_name" type="text" formControlName="spouse_first_name" class="validate"
                        (blur)="addSpouse('firstName', $event.target.value)" style="text-transform: capitalize;">
                    <label for="spouse_first_name">spouse's First Name<span class="red-text">*</span></label>
                    <div *ngIf="(spouse_first_name.dirty || spouse_first_name.touched) && spouse_first_name.invalid">
                        <small *ngIf="spouse_first_name.errors.required" class="red-text"> The First Name is required.
                        </small>
                    </div>
                </div>

                <div class="input-field col s12 m6">
                    <i class="material-icons prefix pink-text">person</i>
                    <input id="spouse_last_name" type="text" formControlName="spouse_last_name" class="validate"
                        (blur)="addSpouse('lastName', $event.target.value)" style="text-transform: capitalize;">
                    <label for="spouse_last_name">spouse's Last Name<span class="red-text">*</span></label>
                    <div *ngIf="(spouse_last_name.dirty || spouse_last_name.touched) && spouse_last_name.invalid">
                        <small *ngIf="spouse_last_name.errors.required" class="red-text"> The Last Name is required.
                        </small>
                    </div>
                </div>

                <div class="input-field col s12 m6">
                    <i class="material-icons prefix green-text">tag</i>
                    <!-- <input id="spouse_isn" type="text" formControlName="spouse_isn" class="validate"
                        (keyup)="onSpouseIsnChange($event.target.value)" [textMask]="{mask: isnMask, guide: false}"> -->
                    <input id="spouse_isn" type="text" [inputMask]="isnMask" formControlName="spouse_isn"
                        class="validate" (keyup)="onSpouseIsnChange($event.target.value)">
                    <label for="spouse_isn">Indian Status No</label>
                    <div *ngIf="(spouse_isn.dirty || spouse_isn.touched) && spouse_isn.invalid">
                        <small *ngIf="spouse_isn.errors.minlength || spouse_isn.errors.maxlength" class="red-text"> The
                            ISN must be 10 Digits.</small>
                    </div>
                </div>

                <div class="input-field col s12 m6">
                    <i class="material-icons prefix purple-text">add_location</i>
                    <select formControlName="spouse_band_name" class="validate" id="spouse_band_name"
                        (change)="spouseBandChange($event.target.value)">
                        <option value="" disabled selected>Choose your option</option>
                        <option *ngFor="let b of bandList" [value]="b">{{b}}</option>
                    </select>
                    <label for="spouse_band_name">Band Name</label>
                </div>

                <div class="input-field col s12 m6">
                    <i class="material-icons prefix pink-text">location_city</i>
                    <select formControlName="spouse_province" class="validate">
                        <option *ngFor="let t of Provinces" [value]="t.code">{{t.name}}</option>
                    </select>
                    <label for="spouse_province">Province of Membership<span class="red-text">*</span></label>
                    <div *ngIf="(spouse_province.dirty || spouse_province.touched) && spouse_province.invalid">
                        <small *ngIf="spouse_province.errors.required" class="red-text">Mailing Address is
                            required</small>
                    </div>
                </div>

                <div class="input-field col s12 m6">
                    <i class="material-icons prefix purple-text">home</i>
                    <input id="spouse_city" type="text" formControlName="spouse_city" class="validate"
                        style="text-transform: capitalize;">
                    <label for="spouse_city">City<span class="red-text">*</span></label>
                    <div *ngIf="(spouse_city.dirty || spouse_city.touched) && spouse_city.invalid">
                        <small *ngIf="spouse_city.errors.required" class="red-text">City is required</small>
                    </div>
                </div>

                <div class="input-field col s12 m6">
                    <i class="material-icons prefix blue-text">phone_iphone</i>
                    <!-- <input id="spouse_mobile" [textMask]="{mask: phoneNumberMask, guide: true}" type="text"
                        formControlName="spouse_mobile" class="validate"> -->
                    <input id="spouse_mobile" type="text" [inputMask]="phoneInputMask" formControlName="spouse_mobile"
                        class="validate">
                    <label for="spouse_mobile">Mobile Number</label>
                </div>

                <div class="input-field col s12 m6">
                    <i class="material-icons prefix purple-text">mail_outline</i>
                    <input id="spouse_email" type="text" formControlName="spouse_email" class="validate">
                    <label for="spouse_email">Email !!!!</label>
                    <div *ngIf="(spouse_email.dirty || spouse_email.touched) && spouse_email.invalid">
                        <small *ngIf="spouse_email.errors.email" class="red-text">Invalid Email Address</small>
                    </div>
                </div>

                <div class="input-field col s12 m6">
                    <i class="material-icons prefix blue-text">tag</i>
                    <input type="number" formControlName="spouse_health_care_no" class="validate">
                    <label for="spouse_health_care_no">Health Care No</label>
                </div>

                <div class="input-field col s12 m6">
                    <i class="material-icons prefix blue-text">today</i>
                    <!-- <input *ngIf="!dates[1].set" id="spouse_dob" type="text" formControlName="spouse_dob"
                        class="validate" [textMask]="{mask:dateMask, guide: true}" placeholder="dd/mm/yyyy"
                        (blur)="setDate(spouse_dob,1,$event.target.value)"> -->
                    <input *ngIf="!dates[1].set" id="spouse_dob" type="text" [inputMask]="dateInputMask"
                        formControlName="spouse_dob" class="validate" placeholder="dd/mm/yyyy"
                        (blur)="setDate(spouse_dob,1,$event.target.value)">
                    <input *ngIf="dates[1].set" id="spouse_dob" type="text" formControlName="spouse_dob"
                        class="validate" placeholder="dd/mm/yyyy"
                        (focus)="unSetDate(spouse_dob,1, $event.target.value)">
                    <label for="spouse_dob">Date of Birth (dd/mm/yyyy)<span class="red-text">*</span></label>
                    <div *ngIf="(spouse_dob.dirty || spouse_dob.touched) && spouse_dob.invalid">
                        <small *ngIf="spouse_dob.errors.required" class="red-text"> The Date Of Birth is
                            required.</small>
                        <small *ngIf="spouse_dob.errors.invalidDate" class="red-text"> The Date invalid </small>
                    </div>
                </div>

                <div class="input-field col s12 m6">
                    <i class="material-icons prefix blue-text">apps</i>
                    <!-- <input id="spouse_SIN" type="text" formControlName="spouse_SIN" class="validate"
                        [textMask]="{mask: SINMask, guide: true}"> -->
                    <input id="spouse_SIN" type="text" [inputMask]="SINMask" formControlName="spouse_SIN"
                        class="validate">
                    <label for="spouse_SIN">SIN</label>
                    <div *ngIf="(spouse_SIN.dirty || spouse_SIN.touched) && spouse_SIN.invalid">
                        <small *ngIf="spouse_SIN.errors.minlength || spouse_SIN.errors.maxlength" class="red-text"> The
                            The SIN must be 9 Digits.</small>
                    </div>
                </div>

            </div>
            <button class="col s12 m5 l3 btn left waves-effect blue" type="button" style="margin-bottom: 15px"
                (click)="onBack()"><i class="material-icons left">arrow_back</i>Back
            </button>

            <button class="col s12 m5 offset-m2 l3 btn right waves-effect blue" type="submit"
                [disabled]="!section1Form.controls.spouse.valid" style="margin-bottom: 15px" title="Save">Next <i
                    class="material-icons right">arrow_forward</i></button>

        </form>

        <!-- --------------------------------------- Childres Information ---------------------------------------------- -->
        <form *ngIf="step == 3" class="row col s12" [formGroup]="section4Form"
            (ngSubmit)="submitSection4(section4Form.controls.children.value, 'children')">
            <div class="row col s12 teal lighten-2 white-text">
                <button class="btn-flat left-align left valign-wrapper btn-large" (click)="onBack()">
                    <i class="material-icons large white-text" (click)="onBack()" style="font-size: 30px;"
                        style="cursor: pointer;">arrow_back_ios</i>
                </button>
                <div class="col right" (click)="nextSection(section4Form.controls.children.valid)">
                    <button class="btn-flat right-align right valign-wrapper btn-large" type="submit"
                        [disabled]="!section4Form.controls.children.valid"><i class="material-icons large white-text"
                            style="font-size: 30px;">arrow_forward_ios</i></button>
                </div>
                <h5 class=" center-align">Section 2</h5>
            </div>
            <section></section>

            <div formGroupName="children">
                <div class="col s12 card white">
                    <div class="row col s12 card-content">
                        <div class="row col s12"><strong>Do you want to Add children?</strong><span
                                class="red-text">*</span></div>
                        <div class="row input-field col s12">
                            <div class="row col s12">
                                <label class="col s6">
                                    <input type="radio" value="yes" formControlName="add_children"
                                        (change)="onAddChildrenYes()" />
                                    <span>Yes</span>
                                </label>
                                <label class="col s6">
                                    <input type="radio" value="no" formControlName="add_children"
                                        (change)="onAddChildrenNo()" />
                                    <span>No</span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <br>

                <div *ngIf="displayChildren" class="row col s12 card white">
                    <h6 class="card-title">Childrens Added</h6>
                    <div formArrayName="childrens" *ngFor="let child of childControls.controls; let i = index;">
                        <ul class="collapsible">
                            <li>
                                <div class="row collapsible-header"><i class="material-icons">person</i>
                                    <span class="col s5"><strong>First Name: </strong>
                                        {{childControls.controls[i].controls.given_name.value}}</span>
                                    <span class="col s5"><strong>last Name: </strong>
                                        {{childControls.controls[i].controls.last_name.value}}</span>
                                    <span class="col s2">
                                        <i class="material-icons red-text small" (click)="onDeleteChildern(i)"
                                            style="cursor: pointer;">delete</i>
                                    </span>
                                </div>
                                <div class="row collapsible-body">
                                    <span class="col s6"> <strong>Birth Date: </strong>
                                        {{childControls.controls[i].controls.dob.value}}</span>
                                    <span class="col s6"> <strong> Education Level: </strong>
                                        {{childControls.controls[i].controls.edu_level.value}}</span>
                                    <span class="col s6"> <strong>Relationship: </strong>
                                        {{childControls.controls[i].controls.Relationship.value}}</span>

                                </div>
                            </li>
                        </ul>
                    </div>

                    <div class="row col s12" style="margin-bottom: 25px; margin-top: 25px;" *ngIf="!addChildren">
                        <p class="center-align"><strong>Do you wish to Add Another Child?</strong></p>
                        <button class="col s12 btn green" type="button" (click)="addAnotherChild()"><i
                                class="material-icons">add</i>Add Another Child</button>
                    </div>
                </div>

                <div class="section"></div>

                <div *ngIf="addChildren" class="row col s12 card white">
                    <br>
                    <div class="col s12 card-content">
                        <span class="material-icons right" (click)="closeAddChild()"
                            style="cursor: pointer;">close</span>
                        <span class="card-title center-align col s12">Add Child</span>
                        <div class="input-field col s12 m6">
                            <i class="material-icons prefix blue-text">face</i>
                            <input id="child_first_name" type="text" formControlName="child_first_name" class="validate"
                                style="text-transform: capitalize;">
                            <label for="child_first_name">Child First Name<span class="red-text">*</span></label>
                            <div
                                *ngIf="(child_first_name.dirty || child_first_name.touched) && child_first_name.invalid">
                                <small *ngIf="child_first_name.errors.required" class="red-text"> The First Name is
                                    required. </small>
                            </div>
                        </div>

                        <div class="input-field col s12 m6">
                            <i class="material-icons prefix pink-text">face</i>
                            <input id="child_last_name" type="text" formControlName="child_last_name" class="validate"
                                style="text-transform: capitalize;">
                            <label for="child_last_name">Child Last Name<span class="red-text">*</span></label>
                            <div *ngIf="(child_last_name.dirty || child_last_name.touched) && child_last_name.invalid">
                                <small *ngIf="child_last_name.errors.required" class="red-text"> The Last Name is
                                    required. </small>
                            </div>
                        </div>

                        <div class="input-field col s12 m6">
                            <i class="material-icons prefix green-text">label_outline</i>
                            <select formControlName="child_relationship" class="validate" id="child_relationship">
                                <option value="" disabled selected>Choose your option</option>
                                <option value="son">Son</option>
                                <option value="daughter">Daughter</option>
                            </select>
                            <label for="child_relationship">Relationship</label>
                        </div>

                        <div class="input-field col s12 m6">
                            <i class="material-icons prefix green-text">school</i>
                            <select formControlName="child_edu_level" class="validate" id="child_edu_level">
                                <option value="" disabled selected>Choose your option</option>
                                <option *ngFor="let edu of EduLevelList" [value]="edu">{{edu}}</option>
                            </select>
                            <label for="child_edu_level">Education Level</label>
                        </div>



                        <div class="input-field col s12 m6">
                            <i class="material-icons prefix blue-text">today</i>
                            <!-- <input *ngIf="!dates[18].set" id="child_dob" type="text" formControlName="child_dob"
                                class="validate" [textMask]="{mask: dateMask, guide: true}" placeholder="dd/mm/yyyy"
                                (blur)="setDate(child_dob,18,$event.target.value)"> -->
                            <input *ngIf="!dates[18].set" id="child_dob" type="text" [inputMask]="dateInputMask"
                                formControlName="child_dob" class="validate" placeholder="dd/mm/yyyy"
                                (blur)="setDate(child_dob,18,$event.target.value)">
                            <input *ngIf="dates[18].set" id="child_dob" type="text" formControlName="child_dob"
                                class="validate" placeholder="dd/mm/yyyy"
                                (focus)="unSetDate(child_dob,18, $event.target.value)">
                            <label for="child_dob">Date of Birth (dd/mm/yyyy)<span class="red-text">*</span></label>
                            <div *ngIf="(child_dob.dirty || child_dob.touched) && child_dob.invalid">
                                <small *ngIf="child_dob.errors.required" class="red-text"> The Date of Birth is
                                    required.</small>
                                <small *ngIf="child_dob.errors.invalidDate" class="red-text"> The Date is
                                    Invalid</small>
                            </div>
                        </div>


                    </div>
                    <button class="col s12 btn green" type="button" style="margin-bottom: 15px;"
                        (click)="addChild(section4Form.controls.children.value)"
                        [disabled]="!section4Form.controls.children.valid">Add
                        Child</button>

                    <button class="col s12 btn red" type="button" style="margin-bottom: 15px;"
                        (click)="closeAddChild()">Close</button>

                </div>

            </div>

            <button *ngIf="!addChildren" class="col s12 m5 l3 btn left waves-effect blue" type="button"
                style="margin-bottom: 15px" (click)="onBack()"><i class="material-icons left">arrow_back</i>Back
            </button>

            <button *ngIf="!addChildren" class="col s12 m5 offset-m2 l3 btn right waves-effect blue" type="submit"
                [disabled]="!section4Form.controls.children.valid" style="margin-bottom: 15px" title="Save">Next <i
                    class="material-icons right">arrow_forward</i></button>
        </form>

        <!-- -------------------------------------- Other family Members Information ----------------------------------- -->
        <form *ngIf="step == 4" class="row col s12" [formGroup]="section4Form"
            (ngSubmit)="submitSection4(section4Form.controls.other.value, 'others')">
            <div class="row col s12 teal lighten-2 white-text">
                <button class="btn-flat left-align left valign-wrapper btn-large" (click)="onBack()">
                    <i class="material-icons large white-text" (click)="onBack()" style="font-size: 30px;"
                        style="cursor: pointer;">arrow_back_ios</i>
                </button>
                <div class="col right" (click)="nextSection(section4Form.controls.other.valid)">
                    <button class="btn-flat right-align right valign-wrapper btn-large" type="submit"
                        [disabled]='!section4Form.controls.other.valid'><i class="material-icons large white-text"
                            style="font-size: 30px;">arrow_forward_ios</i></button>
                </div>
                <h5 class=" center-align">Section 2</h5>
            </div>
            <br>
            <div formGroupName="other">
                <div class="col s12 card white">
                    <div class="row col s12 card-content">
                        <div class="row col s12"><strong>Do you want to Add Other persons in the
                                HouseHold?</strong><span class="red-text">*</span></div>
                        <div class="row input-field col s12">
                            <div class="row col s12">
                                <label class="col s6">
                                    <input type="radio" value="yes" formControlName="add_others"
                                        (change)="onAddOthersYes()" />
                                    <span>Yes</span>
                                </label>
                                <label class="col s6">
                                    <input type="radio" value="no" formControlName="add_others"
                                        (change)="onAddOthersNo()" />
                                    <span>No</span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <br>

                <div *ngIf="displayOthers" class="row col s12 card white">
                    <h6 class="card-title">Other Family Members Added</h6>
                    <div formArrayName="others">
                        <ul class="collection">
                            <li class="row collection-item"
                                *ngFor="let others of othersControls.controls; let i = index;">
                                <div class="row col s10">
                                    <div class="col s12 m6"><strong>First Name:
                                        </strong>{{othersControls.controls[i].controls.given_name.value}}</div>
                                    <div class="col s12 m6 "><strong>Last Name:
                                        </strong>{{othersControls.controls[i].controls.last_name.value}}</div>
                                    <div class="col s12 m6 "><strong>Date of Birth:
                                        </strong>{{othersControls.controls[i].controls.dob.value}}</div>
                                    <div class="col s12 m6 "><strong>Relations:
                                        </strong>{{othersControls.controls[i].controls.Relationship.value}}</div>
                                </div>
                                <div class="secondary-content col s2 right">
                                    <i class="material-icons red-text small" (click)="onDeleteOthers(i)"
                                        style="cursor: pointer;">delete</i>
                                </div>

                            </li>
                        </ul>
                    </div>

                    <div class="row col s12" style="margin-bottom: 25px; margin-top: 25px;" *ngIf="!addOtherMemebers">
                        <p class="center-align"><strong> Do you wish to Add Another Family Member?</strong></p>
                        <button class="col s12 btn green" type="button" style="margin-bottom: 25px;"
                            (click)="addAnotherOther()"><i class="material-icons">add</i>Add Another Family
                            Members</button>
                    </div>
                </div>

                <div *ngIf="addOtherMemebers">
                    <div class="col s12 card white">
                        <div class="col card-content s12">
                            <span class="material-icons right" (click)="closeAddOther()"
                                style="cursor: pointer;">close</span>
                            <span class="card-title">Add Other Persons in Household</span>
                            <div class="input-field col s12 m6">
                                <i class="material-icons prefix blue-text">person_outline</i>
                                <input id="other_first_name" type="text" formControlName="other_first_name"
                                    class="validate" style="text-transform: capitalize;">
                                <label for="other_first_name">First Name<span class="red-text">*</span></label>
                                <div
                                    *ngIf="(other_first_name.dirty || other_first_name.touched) && other_first_name.invalid">
                                    <small *ngIf="other_first_name.errors.required" class="red-text"> The First Name is
                                        required. </small>
                                </div>
                            </div>
                            <div class="input-field col s12 m6">
                                <i class="material-icons prefix pink-text">person_outline</i>
                                <input id="other_last_name" type="text" formControlName="other_last_name"
                                    class="validate" style="text-transform: capitalize;">
                                <label for="other_last_name">Last Name<span class="red-text">*</span></label>
                                <div
                                    *ngIf="(other_last_name.dirty || other_last_name.touched) && other_last_name.invalid">
                                    <small *ngIf="other_last_name.errors.required" class="red-text"> The First Name is
                                        required. </small>
                                </div>
                            </div>

                            <div class="input-field col s12 m6">
                                <i class="material-icons prefix blue-text">today</i>
                                <!-- <input *ngIf="!dates[19].set" id="other_dob" type="text" formControlName="other_dob"
                                    class="validate" [textMask]="{mask: dateMask, guide: true}" placeholder="dd/mm/yyyy"
                                    (blur)="setDate(other_dob,19,$event.target.value)"> -->
                                <input *ngIf="!dates[19].set" id="other_dob" type="text" [inputMask]="dateInputMask"
                                    formControlName="other_dob" class="validate" placeholder="dd/mm/yyyy"
                                    (blur)="setDate(other_dob,19,$event.target.value)">
                                <input *ngIf="dates[19].set" id="other_dob" type="text" formControlName="other_dob"
                                    class="validate" placeholder="dd/mm/yyyy"
                                    (focus)="unSetDate(other_dob,19, $event.target.value)">
                                <label for="other_dob">Date of Birth (dd/mm/yyyy)</label>
                                <div *ngIf="(other_dob.dirty || other_dob.touched) && other_dob.invalid">
                                    <!-- <small *ngIf="other_dob.errors.required" class="red-text"> The Date of Birth is
                                        required.</small> -->
                                    <small *ngIf="other_dob.errors.invalidDate" class="red-text"> The Date is
                                        Invalid</small>
                                </div>
                            </div>


                            <div class="input-field col s12 m6">
                                <i class="material-icons prefix green-text">label</i>
                                <select formControlName="relationship" class="validate" id="relation"
                                    (change)="onRelationChange($event.target.value)">
                                    <option value="" disabled selected>Choose your option</option>
                                    <option *ngFor="let r of RelationshipList" [value]="r">{{r}}</option>
                                </select>
                                <label for="relationship">Relationship</label>
                            </div>

                            <div *ngIf="showOtherRelation" class="input-field col s12 m6">
                                <i class="material-icons prefix blue-text">label</i>
                                <input id="other_relationship" type="text" formControlName="other_relationship"
                                    class="validate" style="text-transform: capitalize;">
                                <label for="other_relationship">Relationship<span class="red-text">*</span></label>
                            </div>
                        </div>
                    </div>

                    <button class="col s12 btn green" type="button" style="margin-bottom: 15px;"
                        (click)="addOthers(section4Form.controls.other.value)"
                        [disabled]="!section4Form.controls.other.valid">
                        <i class="material-icons">person_add_alt</i>
                        Add Other Persons in Household</button>
                    <button class="col s12 btn red" type="button" style="margin-bottom: 15px;"
                        (click)="closeAddOther()">Close</button>
                </div>
            </div>
            <button *ngIf="!addOtherMemebers" class="col s12 m5 l3 btn left waves-effect blue" type="button"
                style="margin-bottom: 15px" (click)="onBack()"><i class="material-icons left">arrow_back</i>Back
            </button>

            <button *ngIf="!addOtherMemebers" class="col s12 m5 l3 btn right waves-effect blue" type="submit"
                [disabled]='!section4Form.controls.other.valid' style="margin-bottom: 15px" title="Save">Next <i
                    class="material-icons right">arrow_forward</i></button>
        </form>

        <!-- ------------------------------------ Address, education, employement Section ------------------------------ -->
        <form *ngIf="step == 5" class="col s12" [formGroup]="section2Form"
            (ngSubmit)="submitSection2(section2Form.value)">
            <div class="row col s12 teal lighten-2 white-text">
                <button class="btn-flat left-align left valign-wrapper btn-large" (click)="onBack()" type="button">
                    <i class="material-icons large white-text" (click)="onBack()" style="font-size: 30px;"
                        style="cursor: pointer;">arrow_back_ios</i>
                </button>
                <div class="col right" (click)="nextSection(section2Form.valid)">
                    <button class="btn-flat right-align right valign-wrapper btn-large" type="submit"
                        [disabled]="!section2Form.valid"><i class="material-icons large white-text"
                            style="font-size: 30px;">arrow_forward_ios</i></button>
                </div>
                <h5 class="center-align">Section 3</h5>
            </div>
            <br>
            <div class="row">
                <div class="col s12">
                    <ul class="tabs">
                        <li class="tab col s4"><a (click)="tabSelected(1)" [ngClass]="{'active': step2==1}"
                                href="#previous_address"><i class="material-icons">place</i><span
                                    class="hide-on-med-and-down"> Previous
                                    Address</span></a></li>
                        <li class="tab col s4"><a (click)="tabSelected(2)" [ngClass]="{'active': step2==2}"
                                href="#recent_education"><i class="material-icons">school</i><span
                                    class="hide-on-med-and-down">
                                    Recent Education</span></a></li>
                        <li class="tab col s4 "><a (click)="tabSelected(3)" [ngClass]="{'active': step2==3}"
                                href="#employment"><i class="material-icons">person</i> <span
                                    class="hide-on-med-and-down">
                                    Employment</span></a></li>

                    </ul>
                </div>

                <div id="previous_address" class="col s12">
                    <div formGroupName="previous_address" class="col s12 card white">
                        <div formGroupName="applicant" class="card-content col s12">
                            <div class="row col s12"><strong>Did you move in the Last 3 years ?</strong><span
                                    class="red-text">*</span>
                            </div>
                            <div class="row col s12">
                                <label class="col s6">
                                    <input class="with-gap" formControlName="moved" name="moved" type="radio"
                                        value="yes" (change)="onMovedYes($event)" />
                                    <span>Yes</span>
                                </label>
                                <label class="col s6">
                                    <input class="with-gap" formControlName="moved" name="moved" type="radio" value="no"
                                        (change)="onMovedNo($event)" />
                                    <span>No</span>
                                </label>
                            </div>
                            <div *ngIf="moved">
                                <h6 class="card-title center-align">{{first_name.value}} Previous Adddress</h6>
                                <div class="input-field col s12">
                                    <i class="material-icons prefix pink-text">add_location</i>
                                    <input id="address" type="text" formControlName="address" class="validate"
                                        style="text-transform: capitalize;">
                                    <label for="address">Previous Address</label>
                                </div>


                                <div class="input-field col s12">
                                    <i class="material-icons prefix purple-text">today</i>
                                    <!-- <input *ngIf="!dates[2].set" id="address_from" type="text"
                                        formControlName="address_from" class="validate"
                                        [textMask]="{mask:dateMask, guide: true}" placeholder="dd/mm/yyyy"
                                        (blur)="setDate(address_from,2,$event.target.value)"> -->
                                    <input *ngIf="!dates[2].set" id="address_from" type="text"
                                        [inputMask]="dateInputMask" formControlName="address_from" class="validate"
                                        placeholder="dd/mm/yyyy" (blur)="setDate(address_from,2,$event.target.value)">
                                    <input *ngIf="dates[2].set" id="address_from" type="text"
                                        formControlName="address_from" class="validate" placeholder="dd/mm/yyyy"
                                        (focus)="unSetDate(address_from,2, $event.target.value)">
                                    <label for="address_from">From (dd/mm/yyyy)</label>
                                    <div *ngIf="(address_from.dirty || address_from.touched) && address_from.invalid">
                                        <small *ngIf="address_from.errors.invalidDate" class="red-text"> The Date
                                            invalid </small>
                                    </div>
                                </div>

                                <div class="input-field col s12">
                                    <i class="material-icons prefix blue-text">today</i>
                                    <!-- <input *ngIf="!dates[3].set" id="address_to" type="text"
                                        formControlName="address_to" class="validate"
                                        [textMask]="{mask:dateMask, guide: true}" placeholder="dd/mm/yyyy"
                                        (blur)="setDate(address_to,3,$event.target.value)"> -->
                                    <input *ngIf="!dates[3].set" id="address_to" type="text" [inputMask]="dateInputMask"
                                        formControlName="address_to" class="validate" placeholder="dd/mm/yyyy"
                                        (blur)="setDate(address_to,3,$event.target.value)">
                                    <input *ngIf="dates[3].set" id="address_to" type="text" formControlName="address_to"
                                        class="validate" placeholder="dd/mm/yyyy"
                                        (focus)="unSetDate(address_to,3, $event.target.value)">
                                    <label for="address_to">To(dd/mm/yyyy)</label>
                                    <div *ngIf="(address_to.dirty || address_to.touched) && address_to.invalid">
                                        <small *ngIf="address_to.errors.invalidDate" class="red-text"> The Date invalid
                                        </small>
                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>
                </div>

                <div id="recent_education" class="col s12">
                    <div formGroupName="recent_education" class="col s12 card white">
                        <h6 class="card-title center-align">Most recent/present education or training program !!!!!</h6>
                        <div formGroupName="applicant" class="card-content" [ngClass]="{'col s12 m6': showSpouseInfo}">
                            <span class="card-title">{{first_name.value}}</span>
                            <div class="input-field col s12">
                                <i class="material-icons prefix pink-text">school</i>
                                <input id="edu_name" type="text" formControlName="edu_name" class="validate"
                                    style="text-transform: capitalize;">
                                <label for="edu_name">{{first_name.value}} Education/Training</label>
                            </div>

                            <div class="input-field col s12">
                                <i class="material-icons prefix purple-text">today</i>
                                <!-- <input *ngIf="!dates[6].set" id="edu_from" type="text" formControlName="edu_from"
                                    class="validate" [textMask]="{mask:dateMask, guide: true}" placeholder="dd/mm/yyyy"
                                    (blur)="setDate(edu_from,6,$event.target.value)"> -->
                                <input *ngIf="!dates[6].set" id="edu_from" type="text" [inputMask]="dateInputMask"
                                    formControlName="edu_from" class="validate" placeholder="dd/mm/yyyy"
                                    (blur)="setDate(edu_from,6,$event.target.value)">
                                <input *ngIf="dates[6].set" id="edu_from" type="text" formControlName="edu_from"
                                    class="validate" placeholder="dd/mm/yyyy"
                                    (focus)="unSetDate(edu_from,6, $event.target.value)">
                                <label for="edu_from">From (dd/mm/yyyy)</label>
                                <div *ngIf="(edu_from.dirty || edu_from.touched) && edu_from.invalid">
                                    <small *ngIf="edu_from.errors.invalidDate" class="red-text"> The Date invalid
                                    </small>
                                </div>
                            </div>

                            <div class="input-field col s12">
                                <i class="material-icons prefix blue-text">today</i>
                                <!-- <input *ngIf="!dates[7].set" id="edu_to" type="text" formControlName="edu_to"
                                    class="validate" [textMask]="{mask:dateMask, guide: true}" placeholder="dd/mm/yyyy"
                                    (blur)="setDate(edu_to,7,$event.target.value)"> -->
                                <input *ngIf="!dates[7].set" id="edu_to" type="text" [inputMask]="dateInputMask"
                                    formControlName="edu_to" class="validate" placeholder="dd/mm/yyyy"
                                    (blur)="setDate(edu_to,7,$event.target.value)">
                                <input *ngIf="dates[7].set" id="edu_to" type="text" formControlName="edu_to"
                                    class="validate" placeholder="dd/mm/yyyy"
                                    (focus)="unSetDate(edu_to,7, $event.target.value)">
                                <label for="edu_to">To (dd/mm/yyyy)</label>
                                <div *ngIf="(edu_to.dirty || edu_to.touched) && edu_to.invalid">
                                    <small *ngIf="edu_to.errors.invalidDate" class="red-text"> The Date invalid </small>
                                </div>
                            </div>

                        </div>
                        <div formGroupName="spouse" class="col card-content s12 m6" *ngIf="showSpouseInfo">
                            <span class="card-title">{{spouse_first_name.value}}</span>
                            <div class="input-field col s12">
                                <i class="material-icons prefix pink-text">school</i>
                                <input id="spouse_edu_name" type="text" formControlName="spouse_edu_name"
                                    style="text-transform: capitalize;" class="validate">
                                <label for="spouse_edu_name">{{spouse_first_name.value}} Education/Training</label>
                            </div>

                            <div class="input-field col s12">
                                <i class="material-icons prefix purple-text">today</i>
                                <!-- <input *ngIf="!dates[8].set" id="spouse_edu_from" type="text"
                                    formControlName="spouse_edu_from" class="validate"
                                    [textMask]="{mask:dateMask, guide: true}" placeholder="dd/mm/yyyy"
                                    (blur)="setDate(spouse_edu_from,8,$event.target.value)"> -->
                                <input *ngIf="!dates[8].set" id="spouse_edu_from" type="text"
                                    [inputMask]="dateInputMask" formControlName="spouse_edu_from" class="validate"
                                    placeholder="dd/mm/yyyy" (blur)="setDate(spouse_edu_from,8,$event.target.value)">
                                <input *ngIf="dates[8].set" id="spouse_edu_from" type="text"
                                    formControlName="spouse_edu_from" class="validate" placeholder="dd/mm/yyyy"
                                    (focus)="unSetDate(spouse_edu_from,8, $event.target.value)">
                                <label for="spouse_edu_from">From (dd/mm/yyyy)</label>
                                <div
                                    *ngIf="(spouse_edu_from.dirty || spouse_edu_from.touched) && spouse_edu_from.invalid">
                                    <small *ngIf="spouse_edu_from.errors.invalidDate" class="red-text"> The Date invalid
                                    </small>
                                </div>
                            </div>

                            <div class="input-field col s12">
                                <i class="material-icons prefix blue-text">today</i>
                                <!-- <input *ngIf="!dates[9].set" id="spouse_edu_to" type="text"
                                    formControlName="spouse_edu_to" class="validate"
                                    [textMask]="{mask:dateMask, guide: true}" placeholder="dd/mm/yyyy"
                                    (blur)="setDate(spouse_edu_to,9,$event.target.value)"> -->
                                <input *ngIf="!dates[9].set" id="spouse_edu_to" type="text" [inputMask]="dateInputMask"
                                    formControlName="spouse_edu_to" class="validate" placeholder="dd/mm/yyyy"
                                    (blur)="setDate(spouse_edu_to,9,$event.target.value)">
                                <input *ngIf="dates[9].set" id="spouse_edu_to" type="text"
                                    formControlName="spouse_edu_to" class="validate" placeholder="dd/mm/yyyy"
                                    (focus)="unSetDate(spouse_edu_to,9, $event.target.value)">
                                <label for="spouse_edu_to">To (dd/mm/yyyy)</label>
                                <div *ngIf="(spouse_edu_to.dirty || spouse_edu_to.touched) && spouse_edu_to.invalid">
                                    <small *ngIf="spouse_edu_to.errors.invalidDate" class="red-text"> The Date invalid
                                    </small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div formGroupName="employment" id="employment" class="row col s12 card white">
                    <div formGroupName="applicant" class="card-content" [ngClass]="{'col s12 m6': showSpouseInfo}">
                        <div class="row col s12"><strong>Are you presently employed? </strong> <span
                                class="red-text">*</span></div>
                        <div class="row col s12">
                            <label class="col s6">
                                <input class="with-gap" formControlName="presently_employed" name="presently_employed"
                                    type="radio" value="yes" (change)="onPresentlyEmployedYes($event)" />
                                <span>Yes</span>
                            </label>
                            <label class="col s6">
                                <input class="with-gap" formControlName="presently_employed" name="presently_employed"
                                    type="radio" value="no" (change)="onPresentlyEmployedNo($event)" />
                                <span>No</span>
                            </label>
                        </div>

                        <div *ngIf="showEmployemnt">
                            <div>
                                <h6 *ngIf="!presentlyEmployed" class="card-title center-align">{{first_name.value}}
                                    Previous
                                    Employer</h6>
                                <h6 *ngIf="presentlyEmployed" class="card-title center-align">{{first_name.value}}
                                    Present Employer
                                </h6>
                                <div class="col s12">
                                    <div class="input-field col s12">
                                        <i class="material-icons prefix pink-text">person_outline</i>
                                        <input id="employer_name" type="text" formControlName="employer_name"
                                            style="text-transform: capitalize;" class="validate">
                                        <label for="employer_name">Employer Name</label>
                                    </div>

                                    <div class="input-field col s12">
                                        <i class="material-icons prefix green-text">location_on</i>
                                        <input id="employer_address" type="text" formControlName="employer_address"
                                            style="text-transform: capitalize;" class="validate">
                                        <label for="employer_address">Address</label>
                                    </div>
                                    <div class="input-field col s12">
                                        <i class="material-icons prefix red-text">star</i>
                                        <input id="position" type="text" formControlName="position" class="validate"
                                            style="text-transform: capitalize;">
                                        <label for="position">Position Held</label>
                                    </div>
                                    <div class="input-field col s12">
                                        <i class="material-icons prefix purple-text">today</i>
                                        <!-- <input *ngIf="!dates[10].set" id="employment_from" type="text"
                                            formControlName="employment_from" class="validate"
                                            [textMask]="{mask:dateMask, guide: true}" placeholder="dd/mm/yyyy"
                                            (blur)="setDate(employment_from,10,$event.target.value)"> -->
                                        <input *ngIf="!dates[10].set" id="employment_from" type="text"
                                            [inputMask]="dateInputMask" formControlName="employment_from"
                                            class="validate" placeholder="dd/mm/yyyy"
                                            (blur)="setDate(employment_from,10,$event.target.value)">
                                        <input *ngIf="dates[10].set" id="employment_from" type="text"
                                            formControlName="employment_from" class="validate" placeholder="dd/mm/yyyy"
                                            (focus)="unSetDate(employment_from,10, $event.target.value)">
                                        <label for="employment_from">From (dd/mm/yyyy)</label>
                                        <div
                                            *ngIf="(employment_from.dirty || employment_from.touched) && employment_from.invalid">
                                            <small *ngIf="employment_from.errors.invalidDate" class="red-text"> The Date
                                                invalid </small>
                                        </div>
                                    </div>
                                    <div *ngIf="!presentlyEmployed" class="input-field col s12">
                                        <i class="material-icons prefix blue-text">today</i>
                                        <!-- <input *ngIf="!dates[11].set" id="employment_to" type="text"
                                            formControlName="employment_to" class="validate"
                                            [textMask]="{mask:dateMask, guide: true}" placeholder="dd/mm/yyyy"
                                            (blur)="setDate(employment_to,11,$event.target.value)"> -->
                                        <input *ngIf="!dates[11].set" id="employment_to" type="text"
                                            [inputMask]="dateInputMask" formControlName="employment_to" class="validate"
                                            placeholder="dd/mm/yyyy"
                                            (blur)="setDate(employment_to,11,$event.target.value)">
                                        <input *ngIf="dates[11].set" id="employment_to" type="text"
                                            formControlName="employment_to" class="validate" placeholder="dd/mm/yyyy"
                                            (focus)="unSetDate(employment_to,11, $event.target.value)">
                                        <label for="employment_to">To (dd/mm/yyyy)</label>
                                        <div
                                            *ngIf="(employment_to.dirty || employment_to.touched) && employment_to.invalid">
                                            <small *ngIf="employment_to.errors.invalidDate" class="red-text"> The Date
                                                invalid </small>
                                        </div>
                                    </div>
                                </div>
                            </div>



                        </div>
                    </div>

                    <div formGroupName="spouse" class="col card-content s12 m6" *ngIf="showSpouseInfo">
                        <div class="row col s12"><strong>Is {{spouse_first_name.value}} presently employed?
                            </strong><span class="red-text">*</span>
                        </div>
                        <div class="row col s12">
                            <label class="col s6">
                                <input class="with-gap" formControlName="spouse_presently_employed"
                                    name="spouse_presently_employed" type="radio" value="yes"
                                    (change)="onSpousePresentlyEmployedYes($event)" />
                                <span>Yes</span>
                            </label>
                            <label class="col s6">
                                <input class="with-gap" formControlName="spouse_presently_employed"
                                    name="spouse_presently_employed" type="radio" value="no"
                                    (change)="onSpousePresentlyEmployedNo($event)" />
                                <span>No</span>
                            </label>
                        </div>
                        <div *ngIf="showSpouseEmployemnt" class="row card-content">
                            <h6 *ngIf="!spousePresentlyEmployed" class="card-title center-align">
                                {{spouse_first_name.value}} Previous
                                Employement</h6>
                            <h6 *ngIf="spousePresentlyEmployed" class="card-title center-align">
                                {{spouse_first_name.value}} Present
                                Employement</h6>
                            <div class="input-field col s12">
                                <i class="material-icons prefix pink-text">person_outline</i>
                                <input id="spouse_employer_name" type="text" formControlName="spouse_employer_name"
                                    class="validate">
                                <label for="spouse_employer_name">Employer Name</label>
                            </div>

                            <div class="input-field col s12">
                                <i class="material-icons prefix green-text">location_on</i>
                                <input id="spouse_employer_address" type="text"
                                    formControlName="spouse_employer_address" class="validate">
                                <label for="spouse_employer_address">Address</label>
                            </div>
                            <div class="input-field col s12">
                                <i class="material-icons prefix red-text">star</i>
                                <input id="spouse_position" type="text" formControlName="spouse_position"
                                    class="validate">
                                <label for="spouse_position">Position Held</label>
                            </div>
                            <div class="input-field col s12">
                                <i class="material-icons prefix purple-text">today</i>
                                <!-- <input *ngIf="!dates[12].set" id="spouse_employment_from" type="text"
                                    formControlName="spouse_employment_from" class="validate"
                                    [textMask]="{mask:dateMask, guide: true}" placeholder="dd/mm/yyyy"
                                    (blur)="setDate(spouse_employment_from,12,$event.target.value)"> -->
                                <input *ngIf="!dates[12].set" id="spouse_employment_from" type="text"
                                    [inputMask]="dateInputMask" formControlName="spouse_employment_from"
                                    class="validate" placeholder="dd/mm/yyyy"
                                    (blur)="setDate(spouse_employment_from,12,$event.target.value)">
                                <input *ngIf="dates[12].set" id="spouse_employment_from" type="text"
                                    formControlName="spouse_employment_from" class="validate" placeholder="dd/mm/yyyy"
                                    (focus)="unSetDate(spouse_employment_from,12, $event.target.value)">
                                <label for="spouse_employment_from">From (dd/mm/yyyy)</label>
                                <div
                                    *ngIf="(spouse_employment_from.dirty || spouse_employment_from.touched) && spouse_employment_from.invalid">
                                    <small *ngIf="spouse_employment_from.errors.invalidDate" class="red-text"> The Date
                                        invalid </small>
                                </div>
                            </div>

                            <div *ngIf="!spousePresentlyEmployed" class="input-field col s12">
                                <i class="material-icons prefix blue-text">today</i>
                                <!-- <input *ngIf="!dates[13].set" id="spouse_employment_to" type="text"
                                    formControlName="spouse_employment_to" class="validate"
                                    [textMask]="{mask:dateMask, guide: true}" placeholder="dd/mm/yyyy"
                                    (blur)="setDate(spouse_employment_to,13,$event.target.value)"> -->
                                <input *ngIf="!dates[13].set" id="spouse_employment_to" type="text"
                                    [inputMask]="dateInputMask" formControlName="spouse_employment_to" class="validate"
                                    placeholder="dd/mm/yyyy"
                                    (blur)="setDate(spouse_employment_to,13,$event.target.value)">
                                <input *ngIf="dates[13].set" id="spouse_employment_to" type="text"
                                    formControlName="spouse_employment_to" class="validate" placeholder="dd/mm/yyyy"
                                    (focus)="unSetDate(spouse_employment_to,13, $event.target.value)">
                                <label for="spouse_employment_to">To (dd/mm/yyyy)</label>
                                <div
                                    *ngIf="(spouse_employment_to.dirty || spouse_employment_to.touched) && spouse_employment_to.invalid">
                                    <small *ngIf="spouse_employment_to.errors.invalidDate" class="red-text"> The Date
                                        invalid </small>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </div>

            <button *ngIf="step2 == 2 || step2 == 3" class="col s12 m5 l3 btn left waves-effect blue" type="button"
                style="margin-bottom: 15px" (click)="onBack2()"><i class="material-icons left">arrow_back</i>Back
            </button>

            <button *ngIf="step2==1" class="col s12 m5 l3 btn left waves-effect blue" type="button"
                style="margin-bottom: 15px" (click)="onBack()"><i class="material-icons left">arrow_back</i>Back
            </button>

            <button *ngIf="step2==1 || step2 == 2" class="col s12 m5 offset-m2 l3 btn right waves-effect blue"
                type="button" (click)='onNext2()' style="margin-bottom: 15px" title="Save"
                [disabled]="(step2 == 1 && !section2Form.controls.previous_address.valid) || (step2 == 2 && !section2Form.controls.recent_education.valid)">Next
                <i class="material-icons right">arrow_forward</i></button>

            <button *ngIf="step2==3" class="col s12 m5 offset-m2 l3 btn right waves-effect blue" type="submit"
                [disabled]="!section2Form.valid" style="margin-bottom: 15px" title="Save">Next
                <i class="material-icons right">arrow_forward</i></button>
        </form>

        <!-- --------------------------------- Vechile section -------------------------------------------------------- -->
        <form *ngIf="step == 6" class="col s12" [formGroup]="section31Form"
            (ngSubmit)="submitSection31(section31Form.value)">

            <div class="row col s12 teal lighten-2 white-text">
                <button class="btn-flat left-align left valign-wrapper btn-large" (click)="onBack()" type="button">
                    <i class="material-icons large white-text" (click)="onBack()" style="font-size: 30px;"
                        style="cursor: pointer;">arrow_back_ios</i>
                </button>
                <div class="col right" (click)="nextSection(section31Form.valid)">
                    <button class="btn-flat right-align right valign-wrapper btn-large" type="submit"
                        [disabled]="!section31Form.valid"><i class="material-icons large white-text"
                            style="font-size: 30px;">arrow_forward_ios</i></button>
                </div>
                <h5 class="center-align">Section 4 : Vehicle</h5>
            </div>


            <br>
            <div class="col s12 card white">
                <div class="row col s12 card-content">
                    <div class="row col s12">Do You Own any Vehicle ?
                    </div>
                    <div class="row input-field col s12">
                        <div class="row col s12">
                            <label class="col s6">
                                <input name="add_vehicle" type="radio" value="yes" formControlName="add_vehicle"
                                    (change)="onAddVehicleYes()" /> <span>Yes</span>
                            </label>
                            <label class="col s6">
                                <input type="radio" value="no" formControlName="add_vehicle"
                                    (change)="onAddVehicleNo()" /> <span>No</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <br>
            <div class="section"></div>
            <br>

            <div *ngIf="displayVehicle" class="row col s12 card white">
                <h6 class="card-title">Vehicle Added</h6>
                <br>
                <div formArrayName="vehicle">
                    <ul class="collection">
                        <li class="row collection-item"
                            *ngFor="let vehicle of vehicleControls.controls; let i = index;">
                            <div class="col s12 m6"><strong>Vehicle:
                                </strong>{{vehicleControls.controls[i].controls.model.value}}</div>
                            <div class="col s10 m4 "><strong>Value:
                                </strong>{{vehicleControls.controls[i].controls.vehicle_value.value}}</div>
                            <div class="secondary-content col s2">
                                <i class="material-icons red-text small" (click)="onDeleteVehicle(i)"
                                    style="cursor: pointer;">delete</i>
                            </div>
                        </li>
                    </ul>
                </div>

                <div *ngIf="!HasTwoVehicle && !addVehicle" class="row col s12"
                    style="margin-bottom: 25px; margin-top: 25px;">
                    <p class="center-align">Do you wish to Add Another Vehicle?</p>
                    <button class="col s12 btn green right" type="button" [disabled]="HasTwoVehicle"
                        (click)="addAnotherVehicle()"><i class="material-icons">add</i> Add Another Vehicle</button>
                </div>
                <div *ngIf="HasTwoVehicle && !addVehicle" class="row col s12 center-align">
                    <p><strong>Two Vechiles Added!</strong></p>
                </div>

            </div>
            <br>

            <div class="section"></div>

            <div *ngIf="addVehicle" class="row col s12 card white">
                <div class="card-content">
                    <span class="material-icons right" (click)="closeAddVehicle()" style="cursor: pointer;">close</span>
                    <h6 class="card-title center-align">Add a new Vehicle</h6>


                    <div class="input-field col s12 m6 ">
                        <i class="material-icons prefix red-text">bookmarks</i>
                        <input id="model" type="text" formControlName="model" class="capitalize  validate">
                        <label for="model">Vehicle Model<span class="red-text">*</span></label>
                        <div *ngIf="(model.dirty || model.touched) && model.invalid">
                            <small *ngIf="model.errors.required" class="red-text"> Model is required. </small>
                        </div>
                    </div>


                    <div class="input-field col s12 m6 ">
                        <i class="material-icons prefix blue-text">bookmarks</i>
                        <input id="year_to_make" type="number" formControlName="year_to_make" min="1980" max="2100"
                            class="validate">
                        <label for="year_to_make">Year Built<span class="red-text">*</span></label>
                        <div *ngIf="(year_to_make.dirty || year_to_make.touched) && year_to_make.invalid">
                            <small *ngIf="year_to_make.errors.required" class="red-text"> Year is required. </small>
                        </div>
                    </div>



                    <div class="input-field col s12 m6">
                        <i class="material-icons prefix green-text">attach_money</i>
                        <input type="number" formControlName="vehicle_value"
                            (change)="setCarTwoNumberDecimal($event.target.value)" min="0" max="50000" step="0.25"
                            value="0.00" placeholder="0.00" />
                        <label for="vehicle_value">Vehicle Value $<span class="red-text">*</span></label>
                    </div>



                    <button class="col s12 btn green" style="margin-bottom: 15px;" type="button"
                        [disabled]="!section31Form.valid" (click)="addNewVehicle(section31Form.value)">Add
                        Vehicle</button>
                </div>

            </div>

            <button *ngIf="!addVehicle" class="col s12 m5 l3 btn left waves-effect blue" type="button"
                style="margin-bottom: 15px" (click)="onBack()"><i class="material-icons left">arrow_back</i>Back
            </button>

            <!--  [disabled]="!section31Form.valid" -->
            <button *ngIf="!addVehicle" class="col s12 m5 offset-m2 l3 btn right waves-effect blue" type="submit"
                [disabled]="!section31Form.valid" style="margin-bottom: 15px" title="Save">Next <i
                    class="material-icons right">arrow_forward</i></button>
        </form>

        <!-- --------------------------------------- Asset Section --------------------------------------------------- -->
        <form *ngIf="step == 7" class="col s12" [formGroup]="section3Form"
            (ngSubmit)="submitSection3(section3Form.value)">
            <div class="row col s12 teal lighten-2 white-text">
                <button class="btn-flat left-align left valign-wrapper btn-large" (click)="onBack()">
                    <i class="material-icons large white-text" (click)="onBack()" style="font-size: 30px;"
                        style="cursor: pointer;">arrow_back_ios</i>
                </button>
                <div class="col right" (click)="nextSection(section3Form.valid)">
                    <button class="btn-flat right-align right valign-wrapper btn-large" type="submit"
                        [disabled]='!section3Form.valid'><i class="material-icons large white-text"
                            style="font-size: 30px;">arrow_forward_ios</i></button>
                </div>
                <h5 class="center-align">Section 4 : Assets</h5>
            </div>
            <br>
            <div class="col s12 card white">
                <div class="row col s12 card-content">
                    <div class="row col s12"><strong> Please list you Assets if Any ?</strong><span
                            class="red-text">*</span>
                    </div>
                    <div class="row input-field col s12">
                        <div class="row col s12">
                            <label class="col s6">
                                <input name="add_assets" type="radio" value="yes" formControlName="add_assets"
                                    (change)="onAddAssetsYes()" />
                                <span>Yes</span>
                            </label>
                            <label class="col s6">
                                <input type="radio" value="no" formControlName="add_assets"
                                    (change)="onAddAssetsNo()" />
                                <span>No</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <br>
            <div class="section"></div>
            <br>
            <div *ngIf="displayAssets" class="row col s12 card white">
                <h6 class="card-title">Assets Added</h6>
                <br>
                <div formArrayName="assets">
                    <ul class="collection">
                        <li class="row collection-item" *ngFor="let asset of assetControls.controls; let i = index;">
                            <div class="col s12 m6"><strong>Asset:
                                </strong>{{assetControls.controls[i].controls.asset.value}}</div>
                            <div class="col s10 m4 "><strong>Amount:
                                </strong>{{assetControls.controls[i].controls.amount.value}}</div>
                            <div class="secondary-content col s2">
                                <i class="material-icons red-text small" (click)="onDeleteAssets(i)"
                                    style="cursor: pointer;">delete</i>
                            </div>
                        </li>
                    </ul>
                </div>
                <div class="row col s12" style="margin-bottom: 25px; margin-top: 25px;" *ngIf="!addAssets">
                    <p class="center-align"><strong>Do you wish to Add Another Asset?</strong></p>
                    <button class="col s12 btn green right" type="button" (click)="addAnotherAsset()"><i
                            class="material-icons">add</i> Add Another Asset</button>
                </div>

            </div>
            <br>

            <div class="section"></div>

            <div *ngIf="addAssets" class="row col s12 card white">
                <div class="card-content">
                    <span class="material-icons right" (click)="closeAddAsset()" style="cursor: pointer;">close</span>
                    <h6 class="card-title center-align">Add a new asset</h6>
                    <div class="input-field col s12 m6">
                        <i class="material-icons prefix blue-text">bookmarks</i>
                        <select id="asset_type" formControlName="asset_type" class="validate"
                            (change)="onAssetSelected($event.target.value)">
                            <option value="" disabled selected>Choose your option</option>
                            <option *ngFor="let asset of AssetsOfAlberta" [value]="asset">{{asset}}</option>
                        </select>
                        <label for="assets">Assets<span class="red-text">*</span></label>
                        <div>
                            <small *ngIf="!assetSelected" class="red-text"> Asset is required. </small>
                        </div>
                    </div>
                    <div class="input-field col s12 m6 ">
                        <i class="material-icons prefix red-text">attach_money</i>
                        <input id="asset_amount" type="number" formControlName="asset_amount" class="validate">
                        <label for="asset_amount">Amount<span class="red-text">*</span></label>
                        <div *ngIf="(asset_amount.dirty || asset_amount.touched) && asset_amount.invalid">
                            <small *ngIf="asset_amount.errors.required" class="red-text"> Amount is required. </small>
                        </div>
                    </div>

                    <button class="col s12 btn green" style="margin-bottom: 15px;" type="button"
                        [disabled]="!section3Form.valid" (click)="addNewAssets(section3Form.value)">Add Asset</button>
                    <button class="col s12 btn red" type="button" style="margin-bottom: 15px;"
                        (click)="closeAddAsset()">Close</button>
                </div>

            </div>

            <button *ngIf="!addAssets" class="col s12 m5 l3 btn left waves-effect blue" type="button"
                style="margin-bottom: 15px" (click)="onBack()"><i class="material-icons left">arrow_back</i>Back
            </button>

            <button *ngIf="!addAssets" class="col s12 m5 offset-m2 l3 btn right waves-effect blue" type="submit"
                style="margin-bottom: 15px" title="Save" [disabled]="!section3Form.valid">Next <i
                    class="material-icons right">arrow_forward</i></button>
        </form>

        <!-- ------------------------------------- Applicant Income Section ------------------------------------------- -->
        <form *ngIf="step == 8" class="col s12" [formGroup]="section5Form"
            (ngSubmit)="submitSection5(section5Form.controls.applicant.value,'applicant')">
            <div class="row col s12 teal lighten-2 white-text">
                <button class="btn-flat left-align left valign-wrapper btn-large" (click)="onBack()">
                    <i class="material-icons large white-text" (click)="onBack()" style="font-size: 30px;"
                        style="cursor: pointer;">arrow_back_ios</i>
                </button>
                <div class="col right" (click)="nextSection(section5Form.controls.applicant.valid)">
                    <button class="btn-flat right-align right valign-wrapper btn-large" type="submit"
                        [disabled]="!section5Form.controls.applicant.valid"><i class="material-icons large white-text"
                            style="font-size: 30px;">arrow_forward_ios</i></button>
                </div>

                <h5 class=" center-align">Section 5 : Previous Month Income</h5>
            </div>
            <br>
            <div class="section"></div>
            <br>
            <div formGroupName="applicant">
                <div class="col s12 card white">
                    <div class="row col s12 card-content">
                        <div class="row col s12"><strong> Do you have any Income to declare?</strong><span
                                class="red-text">*</span>
                        </div>
                        <div class="row input-field col s12">
                            <div class="row col s12">
                                <label class="col s6">
                                    <input type="radio" value="yes" formControlName="add_income"
                                        (change)="onAddIncomesYes()" />
                                    <span>Yes</span>
                                </label>
                                <label class="col s6">
                                    <input type="radio" value="no" formControlName="add_income"
                                        (change)="onAddIncomesNo()" />
                                    <span>No</span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="displayIncomes" class="row col s12 card white">
                    <h6 class="card-title">Incomes Added</h6>
                    <div formArrayName="incomes">
                        <ul class="collection">
                            <li class="row collection-item"
                                *ngFor="let income of incomeControls.controls; let i = index;">
                                <div class="row col s11">
                                    <div class="col s12 m4"><strong>Income:
                                        </strong>{{incomeControls.controls[i].controls.income.value}}</div>
                                    <div class="col s12 m4 "><strong>Amount:
                                        </strong>{{incomeControls.controls[i].controls.amount.value}}</div>
                                    <div class="col s12 m4"><strong>Date Received:
                                        </strong>{{incomeControls.controls[i].controls.date_recevied.value}}</div>
                                </div>
                                <div class="secondary-content col s1">
                                    <i class="material-icons red-text small" (click)="OnDeleteIncome(i)"
                                        style="cursor: pointer;">delete</i>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="row col s12" style="margin-bottom: 25px; margin-top: 25px;" *ngIf="!addIncomes">
                        <p class="center-align"><strong>Do you wish to Add Another Income? </strong></p>
                        <button class="col s12 btn green" type="button" (click)="addAnotherIncome()"><i
                                class="material-icons">add</i>Add Another Income</button>
                    </div>

                </div>
                <div class="section"></div>
                <div *ngIf="addIncomes">
                    <div class="col s12 card white">
                        <div class="col card-content s12">
                            <span class="material-icons right" (click)="closeAddIncome()"
                                style="cursor: pointer;">close</span>
                            <span class="card-title">Add Income</span>
                            <div class="input-field col s12">
                                <i class="material-icons prefix blue-text">account_balance</i>
                                <select id="income_type" formControlName="income_type" class="validate"
                                    (change)="onIncomeSelected($event.target.value)">
                                    <option value="" disabled selected>Choose your Income Type</option>
                                    <option *ngFor="let income of incomes" [value]="income">{{income}}</option>
                                </select>
                                <label for="income">Income<span class="red-text">*</span></label>
                                <div>
                                    <small *ngIf="!incomeSelected" class="red-text"> Income is required. </small>
                                </div>
                            </div>
                            <div class="input-field col s12 m6 ">
                                <i class="material-icons prefix red-text">attach_money</i>
                                <input id="income_amount" type="number" formControlName="income_amount"
                                    class="validate">
                                <label for="income_amount">Amount<span class="red-text">*</span></label>
                                <div *ngIf="(income_amount.dirty || income_amount.touched) && income_amount.invalid">
                                    <small *ngIf="income_amount.errors.required" class="red-text"> Amount is required.
                                    </small>
                                </div>
                            </div>
                            <div class="input-field col s12 m6 ">
                                <i class="material-icons prefix red-text">event</i>
                                <!-- <input *ngIf="!dates[19].set" id="income_date" type="text" formControlName="income_date"
                                    class="validate" [textMask]="{mask: dateMask, guide: true}" placeholder="dd/mm/yyyy"
                                    (blur)="setDate(income_date,19,$event.target.value)"> -->
                                <input *ngIf="!dates[19].set" id="income_date" type="text" [inputMask]="dateInputMask"
                                    formControlName="income_date" class="validate" placeholder="dd/mm/yyyy"
                                    (blur)="setDate(income_date,19,$event.target.value)">
                                <input *ngIf="dates[19].set" id="income_date" type="text" formControlName="income_date"
                                    class="validate" placeholder="dd/mm/yyyy"
                                    (focus)="unSetDate(income_date,19, $event.target.value)">
                                <label for="income_date">Date Received (dd/mm/yyyy)</label>
                                <div *ngIf="(income_date.dirty || income_date.touched) && income_date.invalid">
                                    <small *ngIf="income_date.errors.invalidDate" class="red-text"> The Date invalid
                                    </small>
                                </div>
                            </div>


                        </div>
                    </div>
                    <button class="col s12 btn green" type="button" style="margin-bottom: 15px;"
                        [disabled]="!section5Form.controls.applicant.valid"
                        (click)="addNewIncome(section5Form.controls.applicant.value)">Add Income</button>
                    <button class="col s12 btn red" type="button" style="margin-bottom: 15px;"
                        (click)="closeAddIncome()">Close</button>
                </div>

                <div class="section"></div>

            </div>

            <button *ngIf="!addIncomes" class="col s12 m5 l3 btn left waves-effect blue" type="button"
                style="margin-bottom: 15px" (click)="onBack()"><i class="material-icons left">arrow_back</i>Back
            </button>

            <button *ngIf="!addIncomes" class="col s12 m5 offset-m2 l3 btn right waves-effect blue" type="submit"
                [disabled]="!section5Form.controls.applicant.valid" style="margin-bottom: 15px" title="Save">Next <i
                    class="material-icons right">arrow_forward</i></button>
        </form>

        <!-- -------------------------------------- Spouse Income Section ---------------------------------------------- -->
        <form *ngIf="step == 9" class="col s12" [formGroup]="section5Form"
            (ngSubmit)="submitSection5(section5Form.controls.spouse.value, 'spouse')">
            <div class="row col s12 teal lighten-2 white-text">
                <button class="btn-flat left-align left valign-wrapper btn-large" (click)="onBack()">
                    <i class="material-icons large white-text" (click)="onBack()" style="font-size: 30px;"
                        style="cursor: pointer;">arrow_back_ios</i>
                </button>
                <div class="col right" (click)="nextSection(section5Form.controls.spouse.valid)">
                    <button class="btn-flat right-align right valign-wrapper btn-large" type="submit"
                        [disabled]="!section5Form.controls.spouse.valid"><i class="material-icons large white-text"
                            style="font-size: 30px;">arrow_forward_ios</i></button>
                </div>

                <h5 class=" center-align">Section 5 : Previous Month Income</h5>
            </div>
            <br>
            <div class="section"></div>
            <br>
            <div formGroupName='spouse'>
                <div class="col s12 card white">
                    <div class="row col s12 card-content">
                        <div class="row col s12"><strong>Does {{spouse_first_name.value}} have any Income to
                                declare?</strong><span class="red-text">*</span>
                        </div>
                        <div class="row input-field col s12">
                            <div class="row col s12">
                                <label class="col s6">
                                    <input type="radio" value="yes" formControlName="add_spouse_income"
                                        (change)="onAddSpouseIncomeYes()" />
                                    <span>Yes</span>
                                </label>
                                <label class="col s6">
                                    <input type="radio" value="no" formControlName="add_spouse_income"
                                        (change)="onAddSpouseIncomeNo()" />
                                    <span>No</span>
                                </label>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div *ngIf="emptySpouseIncomes" class="center-align">
                    <span>No Spouse Incomes Declared.</span>
                    <i class="col s12 material-icons medium">inventory</i>
                </div> -->
                <div *ngIf="displaySpouseIncomes" class="row col s12 card white">
                    <h6 class="card-title">Incomes Added</h6>
                    <div formArrayName="spouse_incomes">
                        <ul class="collection">
                            <li class="row collection-item"
                                *ngFor="let income of spouseIncomeControls.controls; let i = index;">
                                <div class="row col s11">
                                    <div class="col s12 m6"><strong>Income:
                                        </strong>{{spouseIncomeControls.controls[i].controls.income.value}}</div>
                                    <div class="col s12 m6 "><strong>Amount:
                                        </strong>{{spouseIncomeControls.controls[i].controls.amount.value}}</div>
                                    <div class="col s12 m6 "><strong>Date Received:
                                        </strong>{{spouseIncomeControls.controls[i].controls.date_recevied.value}}</div>
                                </div>

                                <div class="secondary-content col s1">
                                    <i class="material-icons red-text small" (click)="OnDeleteSpouseIncome(i)"
                                        style="cursor: pointer;">delete</i>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="row col s12" style="margin-bottom: 25px; margin-top: 25px;" *ngIf="!addSpouseIncomes">
                        <p class="center-align">Do you wish to Add Another Income?</p>
                        <button class="col s12 btn green" type="button" (click)="addAnotherSpouseIncome()"><i
                                class="material-icons">add</i>Add Another Income</button>
                    </div>
                </div>
                <div *ngIf="addSpouseIncomes" class="col s12 card white">
                    <div class="col s12 card-content">
                        <span class="material-icons right" (click)="closeAddSpouseIncome()"
                            style="cursor: pointer;">close</span>
                        <span class="card-title">Add Income</span>
                        <div class="col card-content s12">
                            <div class="input-field col s12">
                                <i class="material-icons prefix blue-text">account_balance</i>
                                <select id="spouse_income_type" formControlName="spouse_income_type" class="validate"
                                    (change)="onSpouseIncomeSelected($event.target.value)">
                                    <option value="" disabled selected>Choose your Income Type</option>
                                    <option *ngFor="let income of incomes" [value]="income">{{income}}</option>
                                </select>
                                <label for="spouse_income_type">Income<span class="red-text">*</span></label>
                                <div>
                                    <small *ngIf="!spouseIncomeSelected" class="red-text"> Income is required. </small>
                                </div>
                            </div>
                            <div class="input-field col s12 m6 ">
                                <i class="material-icons prefix red-text">attach_money</i>
                                <input id="spouse_income_amount" type="number" formControlName="spouse_income_amount"
                                    class="validate">
                                <label for="spouse_income_amount">Amount<span class="red-text">*</span></label>
                                <div
                                    *ngIf="(spouse_income_amount.dirty || spouse_income_amount.touched) && spouse_income_amount.invalid">
                                    <small *ngIf="spouse_income_amount.errors.required" class="red-text"> Amount is
                                        required. </small>
                                </div>
                            </div>
                            <div class="input-field col s12 m6 ">
                                <i class="material-icons prefix red-text">event</i>
                                <!-- <input *ngIf="!dates[21].set" id="spouse_income_date" type="text"
                                    formControlName="spouse_income_date" class="validate" placeholder="dd/mm/yyyy"
                                    [textMask]="{mask: dateMask, guide: true}"
                                    (blur)="setDate(spouse_income_date,21,$event.target.value)"> -->
                                <input *ngIf="!dates[21].set" id="spouse_income_date" type="text"
                                    [inputMask]="dateInputMask" formControlName="spouse_income_date" class="validate"
                                    placeholder="dd/mm/yyyy"
                                    (blur)="setDate(spouse_income_date,21,$event.target.value)">
                                <input *ngIf="dates[21].set" id="spouse_income_date" type="text"
                                    formControlName="spouse_income_date" class="validate" placeholder="dd/mm/yyyy"
                                    (focus)="unSetDate(spouse_income_date,21, $event.target.value)">
                                <label for="spouse_income_date">Date Received (dd/mm/yyyy)</label>
                                <div
                                    *ngIf="(spouse_income_date.dirty || spouse_income_date.touched) && spouse_income_date.invalid">
                                    <small *ngIf="spouse_income_date.errors.invalidDate" class="red-text"> The Date
                                        invalid </small>
                                </div>
                            </div>


                        </div>
                    </div>

                    <button class="col s12 btn green" type="button" style="margin-bottom: 15px;"
                        [disabled]="!section5Form.controls.spouse.valid"
                        (click)="addNewSpouseIncome(section5Form.controls.spouse.value)">Add Income</button>
                    <button class="col s12 btn red" type="button" style="margin-bottom: 15px;"
                        (click)="closeAddSpouseIncome()">Close</button>
                </div>
            </div>

            <button *ngIf="!addSpouseIncomes" class="col s12 m5 l3 btn left waves-effect blue" type="button"
                style="margin-bottom: 15px" (click)="onBack()"><i class="material-icons left">arrow_back</i>Back
            </button>

            <button *ngIf="!addSpouseIncomes" class="col s12 m5 offset-m2 l3 btn right waves-effect blue" type="submit"
                [disabled]="!section5Form.controls.spouse.valid" style="margin-bottom: 15px" title="Save">Next <i
                    class="material-icons right">arrow_forward</i></button>
        </form>

        <!-- ----------------------------------------- Housing Information ------------------------------------------------ -->
        <form *ngIf="step == 10" class="col s12" [formGroup]="section61Form"
            (ngSubmit)="submitSection61(section61Form.value)">
            <div class="row col s12 teal lighten-2 white-text">
                <button class="btn-flat left-align left valign-wrapper btn-large" (click)="onBack()">
                    <i class="material-icons large white-text" (click)="onBack()" style="font-size: 30px;"
                        style="cursor: pointer;">arrow_back_ios</i>
                </button>
                <div class="col right" (click)="nextSection(section61Form.valid)">
                    <button class="btn-flat right-align right valign-wrapper btn-large" type="submit"
                        [disabled]="!section5Form.controls.spouse.valid"><i class="material-icons large white-text"
                            style="font-size: 30px;">arrow_forward_ios</i></button>
                </div>
                <h5 class=" center-align">Section 6: Housing</h5>
            </div>

            <div class="col s12 card white">
                <div class="row col s12 card-content">

                    <div class="row input-field col s12">
                        <!--  <i class="material-icons prefix pink-text">add_location</i>   -->
                        <div class="row col s12"> Is Accommodation Shared ? <strong class="red-text">*</strong></div>
                        <div class="row col s12">
                            <label class="col s6">
                                <input class="with-gap" type="radio" value="yes" formControlName="house_shared" />
                                <span>Yes</span>
                            </label>
                            <label class="col s6">
                                <input class="with-gap" type="radio" value="no" formControlName="house_shared" />
                                <span>No</span>
                            </label>
                        </div>
                    </div>


                    <div class="input-field col s12 m8">
                        <i class="material-icons prefix purple-text">people</i>
                        <input id="landlord" type="text" formControlName="landlord" class="validate">
                        <label for="landlord">Who is Responsible for Shelter Costs ?<strong
                                class="red-text">*</strong></label>
                    </div>

                    <div class="input-field col s12 m4">
                        <i class="material-icons prefix pink-text">quickreply</i>
                        <input id="relation" type="text" formControlName="relation" class="validate">
                        <label for="relation">Relationship</label>
                    </div>


                    <div class="row input-field col s12">
                        <!--  <i class="material-icons prefix pink-text">add_location</i> -->
                        <div class="row col s12"> Rented or owned ? <strong class="red-text">*</strong></div>
                        <div class="row col s12">
                            <label class="col s4">
                                <input type="radio" value="rented" formControlName="ownership" /> <span>Rented</span>
                            </label>
                            <label class="col s4">
                                <input type="radio" value="owned" formControlName="ownership" /> <span>Owned</span>
                            </label>

                            <label class="col s4">
                                <input type="radio" value="bandowned" formControlName="ownership" /> <span>Band
                                    Owned</span>
                            </label>
                        </div>
                    </div>


                    <div class="row input-field col s12">
                        <!-- <i class="material-icons prefix pink-text">add_location</i>   -->
                        <div class="row col s12"> CMHC assisted housing <strong class="red-text">*</strong></div>
                        <div class="row col s12">
                            <label class="col s6">
                                <input type="radio" value="yes" formControlName="cmhc_assisted"
                                    (change)="onCMHCAssistedYes()" /> <span>Yes</span>
                            </label>
                            <label class="col s6">
                                <input type="radio" value="no" formControlName="cmhc_assisted"
                                    (change)="onCMHCAssistedNo()" /> <span>No</span>
                            </label>
                        </div>
                    </div>


                    <div class="row input-field col s12">
                        <p><strong class="blue-text">Costs:</strong> </p>
                    </div>

                    <div class="input-field col s12 m6">
                        <i class="material-icons prefix red-text">attach_money</i>
                        <input id="utilities" type="number" formControlName="utilities" class="validate"
                            (change)="setUtilitiesTwoNumberDecimal($event.target.value)" min="0" max="10000" step="0.25"
                            value="0.00" placeholder="0.00">
                        <label for="utilities">Utilities ($)</label>
                    </div>
                    <div class="input-field col s12 m6">
                        <i class="material-icons prefix red-text">attach_money</i>
                        <input id="fuel" type="number" formControlName="fuel" class="validate"
                            (change)="setFuelTwoNumberDecimal($event.target.value)" min="0" max="10000" step="0.25"
                            value="0.00" placeholder="0.00">
                        <label for="fuel">Fuel ($)</label>
                    </div>
                    <div class="input-field col s12 m6">
                        <i class="material-icons prefix red-text">attach_money</i>
                        <input id="maintenance" type="number" formControlName="maintenance" class="validate"
                            (change)="setMaintenanceTwoNumberDecimal($event.target.value)" min="0" max="10000"
                            step="0.25" value="0.00" placeholder="0.00">
                        <label for="maintenance">Maintenance ($)</label>
                    </div>
                    <div class="input-field col s12 m6">
                        <i class="material-icons prefix red-text">attach_money</i>
                        <input id="garbage" type="number" formControlName="garbage" class="validate"
                            (change)="setGarbageTwoNumberDecimal($event.target.value)" min="0" max="10000" step="0.25"
                            value="0.00" placeholder="0.00">
                        <label for="garbage">Garbage, Water, Sewer ($)</label>
                    </div>
                    <div class="input-field col s12 m6">
                        <i class="material-icons prefix red-text">attach_money</i>
                        <input id="rent" type="number" formControlName="rent" class="validate"
                            (change)="setRentTwoNumberDecimal($event.target.value)" min="0" max="10000" step="0.25"
                            value="0.00" placeholder="0.00">
                        <label for="rent">Rent / Housing loan PMT ($)</label>
                    </div>

                    <div class="row input-field col s12"><span class="black-text"><strong>
                                <h6>Comments:</h6>
                            </strong></span>
                        <div class="md-form mb-4 pink-textarea active-pink-textarea">
                            <textarea id="comments" formControlName="comments" class="md-textarea form-control"
                                rows="5"></textarea>
                        </div>
                    </div>


                </div>
            </div>


            <button class="col s12 m5 l3 btn left waves-effect blue" type="button" style="margin-bottom: 15px"
                (click)="onBack()"><i class="material-icons left">arrow_back</i>Back
            </button>


            <button class="col s12 m5 offset-m2 l3 btn right waves-effect blue" type="submit"
                [disabled]="!section61Form.valid" style="margin-bottom: 15px" title="Save">Next <i
                    class="material-icons right">arrow_forward</i></button>


        </form>

        <!-- ----------------------------------------- Declaration, Signature Section ------------------------------------ -->
        <form *ngIf="step == 11" class="col s12" [formGroup]="section6Form"
            (ngSubmit)="submitSection6(section6Form.value)">
            <div class="row col s12 teal lighten-2 white-text">
                <i class="material-icons medium valign-wrapper prefix left" (click)="onBack()"
                    style="cursor: pointer;">navigate_before</i>
                <h5 class="center-align">Section 7</h5>
            </div>
            <div class="row right">
                <button class="btn tooltipped" data-position="bottom" data-tooltip="Open as a PDF" type="button"
                    (click)="openPdf()" style="margin:5px;"><i class="material-icons">open_in_new</i></button>
                <button class="btn blue tooltipped" data-position="bottom" data-tooltip="Download PDF" type="button"
                    (click)="downloadPdf()" style="margin:5px;"><i class="material-icons blue">download</i></button>
                <button class="btn green tooltipped" data-position="bottom" data-tooltip="Print PDF" type="button"
                    (click)="printPdf()" style="margin:5px;"> <i class="material-icons">print</i></button>
            </div>
            <div class="row">
                <div class="col s12 card white" style="text-align: justify;">
                    <p> I hereby apply for assistance under the Income Assistance Program of Indian and Northern
                        Affairs
                        Canada</p>
                    <p>I declare that the above information to be true, and I authorize the Administrative
                        Authority, to
                        secure from any other
                        source,information that may be required to determine my eligibly for assistance.</p>
                    <p>I agree to inform the Administrative Authority immediately of any change in my address,
                        marital
                        status, family financial and social
                        conditions affecting my eligibility.</p>
                    <p>I authorize the Administrative Authority to release information to a third party as per the
                        Regional Social Assistance Manual. Details
                        of these conditions will be provided upon request.</p>
                    <p><strong><i class="small material-icons prefix black-text">arrow_right</i>Income Support
                            benefits obtained under false pretence or misrepresentation may lead to prosecution
                            under the
                            Criminal Code of Canada</strong></p>
                </div>

                <div class="row col s12 card white">
                    <div class="row input-field col s12">
                        <div class="row col s12">Do You Agree?</div>
                        <div class="row col s12">
                            <label class="col s6">
                                <input class="with-gap" name="agree" formControlName="agree" type="radio" value="yes"
                                    (change)="onAgreeYes($event)" />
                                <span class="text"><strong>Yes</strong></span>
                            </label>
                            <label class="col s6">
                                <input class="with-gap" name="agree" formControlName="agree" type="radio" value="no"
                                    (change)="onAgreeNo($event)" />
                                <span class="text"><strong>No</strong></span>
                            </label>
                        </div>
                    </div>

                </div>



                <div class="container col s12 card white">
                    <div class="col s12 m6 card-content">
                        <label for="applicant_signature">Signature of {{first_name.value}}<span
                                class="red-text">*</span></label>
                        <a *ngIf="!applicantSigned" [ngClass]="'btn col s12 btn waves-effect ' + themeColor"
                            style="margin-top: 15px;" (click)="sign(first_name.value)">Sign</a>
                        <a *ngIf="applicantSigned" class="col s12 waves-effect" style="margin-top: 15px;"><i
                                class="material-icons left green-text small">check_circle</i>Signed</a>
                    </div>
                    <div class="input-field col s12 m6 card-content">
                        <!-- <input *ngIf="!dates[22].set" id="applicant_date" type="text" class="validate"
                            formControlName="applicant_date" [textMask]="{mask: dateMask, guide: true}"
                            placeholder="dd/mm/yyyy" (blur)="setDate(applicant_date,22,$event.target.value)"> -->
                        <input *ngIf="!dates[22].set" id="applicant_date" type="text" [inputMask]="dateInputMask"
                            class="validate" formControlName="applicant_date" placeholder="dd/mm/yyyy"
                            (blur)="setDate(applicant_date,22,$event.target.value)">
                        <input *ngIf="dates[22].set" id="applicant_date" type="text" class="validate"
                            formControlName="applicant_date" placeholder="dd/mm/yyyy"
                            (focus)="unSetDate(applicant_date,22, $event.target.value)">
                        <label for="applicant_date">Date (dd/mm/yyyy)</label>
                    </div>

                    <div *ngIf="showSpouseInfo">
                        <div class="col s12 m6 card-content">
                            <label for="applicant_signature">Signature of {{spouse_first_name.value}}<span
                                    class="red-text">*</span></label>
                            <a *ngIf="!spouseSigned" [ngClass]="'btn col s12 btn waves-effect ' + themeColor"
                                style="margin-top: 15px;" (click)="sign(spouse_first_name.value)">Sign</a>
                            <a *ngIf="spouseSigned" class="col s12 waves-effect" style="margin-top: 15px;"><i
                                    class="material-icons left green-text small">check_circle</i>Signed</a>
                        </div>

                        <div class="input-field col s12 m6 card-content" *ngIf="showSpouseInfo">
                            <!-- <input *ngIf="!dates[23].set" id="spouse_date" type="text" class="validate"
                                formControlName="spouse_date" [textMask]="{mask: dateMask, guide: true}"
                                placeholder="dd/mm/yyyy" (blur)="setDate(spouse_date,23,$event.target.value)"> -->
                            <input *ngIf="!dates[23].set" id="spouse_date" type="text" [inputMask]="dateInputMask"
                                class="validate" formControlName="spouse_date" placeholder="dd/mm/yyyy"
                                (blur)="setDate(spouse_date,23,$event.target.value)">
                            <input *ngIf="dates[23].set" id="spouse_date" type="text" class="validate"
                                formControlName="spouse_date" placeholder="dd/mm/yyyy"
                                (focus)="unSetDate(spouse_date,23, $event.target.value)">
                            <label for="spouse_date">Date (dd/mm/yyyy)</label>
                        </div>
                    </div>

                </div>

                <div class="section"></div>

            </div>

            <button class="col s12 m5 l3 btn left waves-effect blue" type="button" style="margin-bottom: 15px"
                (click)="onBack()"><i class="material-icons left">arrow_back</i>Back </button>

            <button class="col s12 m5 offset-m2 l3 btn right waves-effect green" type="submit"
                [disabled]="!agree || !section6Form.valid" style="margin-bottom: 15px" title="Save">Preview & Submit<i
                    class="material-icons right">send</i></button>

        </form>

        <!-- ------------------------------ Signature Pad --------------------------------------------------------------- -->
        <div class="row card white" *ngIf="step==12" [formGroup]="section6Form"
            (ngSubmit)="submitSection6(section6Form.value)">

            <div class="row s12 card-panel grey lighten-4 center">
                <div class="row">
                    <h6 class="blue-text text-darken-2 center"><strong>{{signing | uppercase}}'S SIGNATURE</strong></h6>
                    <canvas></canvas>
                </div>
            </div>

            <div class="section"></div>

            <a class="at-btn btn-flat center green lighten-1 col s12 white-text" (click)="approveSignature()"
                style="margin-bottom: 15px;"><i class="material-icons left">check</i> Sign</a>
            <br>
            <a class="at-btn btn-flat center red lighten-1 col s12 white-text" (click)="clearSignature()"
                style="margin-bottom: 15px;"><i class="material-icons left">loop</i> Clear Signature</a>
            <br>
            <a [ngClass]="'at-btn col s12 btn waves-effect ' + themeColor" (click)="BackToApp()"><i
                    class="material-icons left">arrow_back_ios</i> Back To My Application </a>

        </div>

        <!-- ---------------------------- Preview Application --------------------------------------------------------- -->
        <div *ngIf="step == 0" class="row col s12">
            <div class="teal lighten-2 white-text" style="margin-top: 25px; padding-top: 5px; padding-bottom: 5px;">
                <i class="material-icons medium valign-wrapper prefix left" (click)="BackToApp()"
                    style="cursor: pointer;">navigate_before</i>
                <h5 class="center-align">Preview Application</h5>
            </div>
            <div class="row card white">
                <ul class="collection">
                    <li class="section row collection-item">
                        <div class="col s12">
                            <h5 class="col s8"><strong>Personal Information</strong></h5>
                            <a class="col blue-text right waves-effect waves-light btn-flat" (click)="editSection(1)"
                                style="cursor: pointer;"><i class="material-icons left">edit</i>Edit</a>
                        </div>
                        <div class="col s12">
                            <!-- <h6>Applicant</h6> -->
                            <p class="col s12 m6"> <strong>First Name :</strong> {{ApplicationModel.firstName}}</p>
                            <p class="col s12 m6"> <strong>Last Name :</strong> {{ApplicationModel.lastName}}</p>
                            <p class="col s12 m6"> <strong>Date of Birth: </strong> {{ApplicationModel.dob}}</p>
                            <p class="col s12 m6"> <strong>Marital Status :</strong> {{ApplicationModel.maritalStatus}}
                            </p>
                            <p class="col s12 m6"> <strong>Education Level: </strong> {{ApplicationModel.EDU_LEVEL}}</p>
                            <p class="col s12 m6"> <strong>Indian Status Number: </strong> {{ApplicationModel.isn}}</p>
                            <p class="col s12 m6"> <strong>Treaty Number: </strong> {{ApplicationModel.TreatyNo}}</p>
                            <p class="col s12 m6"> <strong>Band Name: </strong> {{ApplicationModel.bandName}}</p>
                            <p class="col s12 m6"> <strong>Province :</strong> {{ApplicationModel.province}}</p>
                            <p class="col s12 m6"> <strong>Address: </strong> {{ApplicationModel.address}}</p>
                            <p class="col s12 m6"> <strong>City: </strong> {{memberProfile.city}}</p>
                            <p class="col s12 m6"> <strong>Postal Code:</strong> {{ApplicationModel.postalCode}}</p>
                            <p class="col s12 m6"> <strong>Mailing Address</strong>{{memberProfile.MailAddress}}</p>
                            <p class="col s12 m6"> <strong>House Number: </strong>{{memberProfile.HouseNumber}}</p>
                            <p class="col s12 m6"> <strong>Home Number: </strong> {{ApplicationModel.telephone}}</p>
                            <p class="col s12 m6"> <strong>Mobile Number: </strong> {{memberProfile.cell_phone_num}}</p>
                            <p class="col s12 m6"> <strong>Health Care Number: </strong>
                                {{ApplicationModel.healthCareNo}}</p>
                            <p class="col s12 m6"> <strong>SIN: </strong> {{ApplicationModel.SIN}}</p>
                        </div>
                    </li>

                    <li class="section row col s12 collection-item" *ngIf="hasSpouse && spouse">
                        <div class="col s12">
                            <h5 class="col s8"><strong>Spouse Personal Information</strong></h5>
                            <a class="col s4 blue-text right waves-effect waves-light btn-flat" (click)="editSection(2)"
                                style="cursor: pointer;"><i class="material-icons left">edit</i>Edit</a>
                        </div>
                        <div class="col s12">
                            <!-- <h6>Spouse</h6> -->
                            <p class="col s12 m6"> <strong>Spouse First Name :</strong>
                                {{ApplicationModel.spouseFirstName}}</p>
                            <p class="col s12 m6"> <strong>Spouse Last Name :</strong>
                                {{ApplicationModel.spouseLastName}}</p>
                            <p class="col s12 m6"> <strong>Spouse Indian Status Number : </strong>
                                {{ApplicationModel.spouseISN}}</p>
                            <p class="col s12 m6"> <strong>Spouse Band :</strong> {{spouse.Band}}</p>
                            <p class="col s12 m6"> <strong>Spouse Province :</strong>
                                {{ApplicationModel.spouseProvince}}</p>
                            <p class="col s12 m6"> <strong>Spouse City : </strong> {{spouse.city}}</p>
                            <p class="col s12 m6"> <strong>Spouse Mobile Number :</strong> {{spouse.cell_phone_num}}</p>
                            <!-- <p> <strong>Spouse Email :</strong> {{spouseMobile}}</p> -->
                            <p class="col s12 m6"> <strong>Spouse Health Care Number :</strong>
                                {{ApplicationModel.spouseHealtCareNo}}</p>
                            <p class="col s12 m6"> <strong>Spouse Date of Birth :</strong>
                                {{ApplicationModel.spouseDob}}</p>
                            <p class="col s12 m6"> <strong>Spouse SIN: </strong> {{ApplicationModel.spouseSIN}}</p>
                        </div>

                    </li>

                    <li class="section row collection-item">
                        <div class="row col s12">
                            <h5 class="col s8"><strong>Children</strong></h5>
                            <a class="col blue-text right waves-effect waves-light btn-flat" (click)="editSection(3)"
                                style="cursor: pointer;"><i class="material-icons left">edit</i>Edit</a>
                        </div>
                        <p class="col s12 center-align"
                            *ngIf="tmpMemberModel?.length==0 || (spouseAdded && tmpMemberModel.length == 1)">No Children
                            Added</p>
                        <div class="row col s12"
                            *ngIf="(!spouseAdded && tmpMemberModel?.length > 0) || (spouseAdded && tmpMemberModel.length > 1)">
                            <div class="section row col s12" *ngFor="let child of tmpMemberModel; let i=index;">
                                <div *ngIf="!(child.Relationship == 'Spouse')">
                                    <div class="divider"></div>
                                    <p class="col s12 m6"><strong>First Name : </strong> {{child.given_name}}</p>
                                    <p class="col s12 m6"><strong>Last Name : </strong> {{child.last_name}}</p>
                                    <p class="col s12 m6"> <strong>Date of Birth :</strong> {{child.dob}}</p>
                                    <p class="col s12 m6"> <strong>Education Level:</strong>{{child.edu_level}}</p>
                                    <p class="col s12 m6"><strong>Relationship : </strong> {{child.Relationship}}</p>
                                </div>

                            </div>
                        </div>
                    </li>

                    <li class="section row collection-item">
                        <div class="row col s12">
                            <h5 class="col s8"><strong>Other Family Member</strong></h5>
                            <a class="col blue-text right waves-effect waves-light btn-flat" (click)="editSection(4)"
                                style="cursor: pointer;"><i class="material-icons left">edit</i>Edit</a>
                        </div>

                        <p class="col s12 center-align" *ngIf="tmpOtherMemberModel?.length==0">No Other Family Member
                            Added</p>
                        <div class="row col s12" *ngIf="tmpOtherMemberModel?.length > 0">
                            <div class="section row col s12" *ngFor="let other of tmpOtherMemberModel;let i=index">
                                <p class="col s12 m6 l4 "><strong>{{i+1}} . First Name: </strong>
                                    {{other.given_name}}</p>
                                <p class="col s12 m6 l4"><strong>Last Name: </strong> {{other.last_name}}</p>
                                <p class="col s12 m6 l4"><strong>Date of Birth: </strong> {{other.dob}}</p>
                                <p class="col s12 m6 l4"> <strong>Relationship :</strong> {{other.Relationship}}</p>
                                <p class="col s12 m6 l4"><strong>Contribution: </strong> {{other.contribution}} $</p>

                            </div>
                        </div>
                    </li>

                    <li class="section row col s12 collection-item">
                        <div class="col s12">
                            <h5 class="col s8"><strong>Address Information</strong></h5>
                            <a class="col blue-text right waves-effect waves-light btn-flat" (click)="editSection(5, 1)"
                                style="cursor: pointer;"><i class="material-icons left">edit</i>Edit</a>
                        </div>
                        <div class="col s12 center-align" *ngIf="!moved">
                            <p>Applicant has not moved in the last 3 years.</p>
                        </div>
                        <div class="col s12" *ngIf="moved">
                            <h6><strong> {{ApplicationModel.firstName}} Previous Address</strong></h6>
                            <p> <strong>Address :</strong> {{ApplicationModel.previous_address}}</p>
                            <p> <strong>Start Date :</strong> {{ApplicationModel.previous_address_startDate}}</p>
                            <p> <strong>End Date: </strong> {{ApplicationModel.previous_address_endDate}}</p>
                        </div>

                    </li>

                    <li class="section row col s12 collection-item">
                        <div class="col s12">
                            <h5 class="col s8"><strong>Recent Education/ Training</strong></h5>
                            <a class="col blue-text right waves-effect waves-light btn-flat" (click)="editSection(5, 2)"
                                style="cursor: pointer;"><i class="material-icons left">edit</i>Edit</a>
                        </div>
                        <div class="col s12 m6">
                            <h6><strong>{{ApplicationModel.firstName}} Education</strong></h6>
                            <p> <strong>Education :</strong> {{ApplicationModel.education}}</p>
                            <p> <strong>Start Date :</strong> {{ApplicationModel.education_startDate}}</p>
                            <p> <strong>End Date: </strong> {{ApplicationModel.education_endDate}}</p>
                        </div>
                        <div class="col s12 m6" *ngIf="showSpouseInfo">
                            <h6>{{ApplicationModel.spouseFirstName}}</h6>
                            <p> <strong>Education :</strong> {{ApplicationModel.education_spouse}}</p>
                            <p> <strong>Start Date :</strong> {{ApplicationModel.education_startDate_spouse}}</p>
                            <p> <strong>End Date: </strong> {{ApplicationModel.education_endDate_spouse}}
                            </p>
                        </div>
                    </li>

                    <li class="section row col s12 collection-item">
                        <div class="col s12">
                            <h5 class="col s8"><strong>Employment</strong></h5>
                            <a class="col blue-text right waves-effect waves-light btn-flat" (click)="editSection(5, 3)"
                                style="cursor: pointer;"><i class="material-icons left">edit</i>Edit</a>
                        </div>

                        <div class="col s12 m6" *ngIf="presentlyEmployed">
                            <h6><strong>{{ApplicationModel.firstName}} Present Employment</strong></h6>
                            <p><strong>Employer Name :</strong>{{ApplicationModel.present_employer}}</p>
                            <!-- <p> <strong>Employer Contact Name :</strong>{{model.employment.contactName}}
                            </p> -->
                            <p> <strong>Address :</strong> {{ApplicationModel.present_employment_address}}</p>
                            <p> <strong>Position :</strong> {{ApplicationModel.present_employment_position}}</p>
                            <p *ngIf="ApplicationModel.present_employment_startDate"> <strong>Start Date :</strong>
                                {{ApplicationModel.present_employment_startDate | date: 'fullDate'}}</p>
                            <p> <strong>End Date: </strong>Current</p>
                        </div>
                        <div class="col s12 m6" *ngIf="!presentlyEmployed">
                            <h6><strong>{{ApplicationModel.firstName}} Previous Employment</strong></h6>
                            <!-- <h6 *ngIf="!presentlyEmployed">{{ApplicationModel.firstName}} Previous
                                Employment</h6> -->
                            <p> <strong>Employer Name :</strong>{{ApplicationModel.previous_employer}}</p>
                            <!-- <p> <strong>Employer Contact Name :</strong>{{model.employment.contactName}}
                            </p> -->
                            <p> <strong>Address :</strong> {{ApplicationModel.previous_employment_address}}</p>
                            <p> <strong>Position :</strong> {{ApplicationModel.previous_employment_position}}</p>
                            <p *ngIf="ApplicationModel.previous_employment_startDate"> <strong>Start Date :</strong>
                                {{ApplicationModel.previous_employment_startDate | date: 'fullDate'}}
                            </p>
                            <p *ngIf="ApplicationModel.previous_employment_endDate"> <strong>End Date: </strong>
                                {{ApplicationModel.previous_employment_endDate | date: 'fullDate'}}</p>
                        </div>
                        <div *ngIf="hasSpouse">
                            <div class="col s12 m6" *ngIf="spousePresentlyEmployed">
                                <h6> {{ApplicationModel.spouseFirstName}} Present Employment</h6>
                                <p> <strong>Employer Name :</strong> {{ApplicationModel.present_employer_spouse }}</p>
                                <!-- <p> <strong>Employer Contact Name :</strong>{{model.employment.contactName}} </p> -->
                                <p> <strong>Address :</strong> {{ApplicationModel.present_employment_address_spouse}}
                                </p>
                                <p> <strong>Position :</strong> {{ApplicationModel.present_employment_position_spouse}}
                                </p>
                                <p *ngIf="ApplicationModel.present_employment_startDate_spouse"> <strong>Start Date
                                        :</strong> {{ApplicationModel.present_employment_startDate_spouse | date:
                                    'fullDate'}}</p>
                                <p *ngIf="ApplicationModel.present_employment_endDate_spouse"> <strong>End Date:
                                    </strong> {{ApplicationModel.present_employment_endDate_spouse | date: 'fullDate'}}
                                </p>
                            </div>
                            <div class="col s12 m6" *ngIf="!spousePresentlyEmployed">
                                <h6> {{ApplicationModel.spouseFirstName}} Previous Employment</h6>
                                <p> <strong>Employer Name :</strong> {{ApplicationModel.previous_employer_spouse}}</p>
                                <!-- <p> <strong>Employer Contact Name :</strong>{{model.employment.contactName}} </p> -->
                                <p> <strong>Address :</strong> {{ApplicationModel.previous_employment_address_spouse}}
                                </p>
                                <p> <strong>Position :</strong> {{ApplicationModel.previous_employment_position_spouse}}
                                </p>
                                <p *ngIf="ApplicationModel.previous_employment_startDate_spouse"> <strong>Start Date
                                        :</strong> {{ApplicationModel.previous_employment_startDate_spouse | date:
                                    'fullDate'}}</p>
                                <p *ngIf="ApplicationModel.previous_employment_endDate_spouse"> <strong>End Date:
                                    </strong> {{ApplicationModel.previous_employment_endDate_spouse | date: 'fullDate'}}
                                </p>
                            </div>
                        </div>
                    </li>


                    <li class="section row collection-item">
                        <div class="row col s12">
                            <h5 class="col s8"><strong>Vehicles</strong></h5>
                            <a class="col blue-text right waves-effect waves-light btn-flat" (click)="editSection(6)"
                                style="cursor: pointer;"><i class="material-icons left">edit</i>Edit</a>
                        </div>

                        <div class="row col s12" *ngIf="ApplicationModel.car1_make">
                            <p class="col s12 m4"> <strong> 1. Vehicle Model: </strong>{{ApplicationModel.car1_make }}
                            </p>
                            <p class="col s12 m4"> <strong>Vehicle Year Built :</strong> {{ApplicationModel.car1_year}}
                            </p>
                            <p class="col s12 m4"> <strong>Vehicle Value :</strong> {{ApplicationModel.car1_value}}</p>
                        </div>
                        <div class="row col s12" *ngIf="ApplicationModel.car2_make">
                            <p class="col s12 m4"> <strong>2. Vehicle Model: </strong>{{ApplicationModel.car2_make }}
                            </p>
                            <p class="col s12 m4"> <strong>Vehicle Year Built :</strong> {{ApplicationModel.car2_year}}
                            </p>
                            <p class="col s12 m4"> <strong>Vehicle Value :</strong> {{ApplicationModel.car2_value}}</p>
                        </div>
                    </li>

                    <li class="section row collection-item">
                        <div class="row col s12">
                            <h5 class="col s8"><strong>Assets</strong></h5>
                            <a class="col blue-text right waves-effect waves-light btn-flat" (click)="editSection(7)"
                                style="cursor: pointer;"><i class="material-icons left">edit</i>Edit</a>
                        </div>
                        <p class="col s12 center-align" *ngIf="tmpAssetsModel?.length==0">No Assets Declared.</p>
                        <div class="row col s12" *ngIf="tmpAssetsModel?.length > 0">
                            <div class="row col s12" *ngFor="let asset of tmpAssetsModel; let i=index;">
                                <p class="col s12 m6"> <strong> {{i+1}} . Asset: </strong> {{asset.asset}}</p>
                                <p class="col s12 m6"> <strong>Amount :</strong> {{asset.amount}}</p>
                            </div>
                        </div>
                    </li>

                    <li class="section row collection-item">
                        <div class="row col s12">
                            <h5 class="col s8"><strong>{{ApplicationModel.firstName}}'s Incomes</strong></h5>
                            <a class="col blue-text right waves-effect waves-light btn-flat" (click)="editSection(8)"
                                style="cursor: pointer;"><i class="material-icons left">edit</i>Edit</a>
                        </div>

                        <p class="col s12 center-align" *ngIf="applicantIncomeModel?.length==0">No Incomes Declared.</p>
                        <div class="row col s12" *ngIf="applicantIncomeModel?.length > 0">
                            <div class="section row col s12" *ngFor="let income of applicantIncomeModel; let i=index">
                                <p class="col s12 m4"><strong> {{i+1}} . Income: </strong> {{income.income}}</p>
                                <p class="col s12 m4"> <strong>Amount :</strong> {{income.amount}}</p>
                                <p class="col s12 m4"> <strong>Date Recevied :</strong> {{income.date_recevied}}</p>
                            </div>

                        </div>
                    </li>

                    <li class="section row collection-item" *ngIf="hasSpouse">
                        <div class="row col s12">
                            <h5 class="col s8">{{ApplicationModel.spouseFirstName}}'s Incomes</h5>
                            <a class="col blue-text right waves-effect waves-light btn-flat" (click)="editSection(9)"
                                style="cursor: pointer;"><i class="material-icons left">edit</i>Edit</a>
                        </div>
                        <p class="col s12 center-align" *ngIf="spouseIncomeModel?.length==0">
                            {{ApplicationModel.spouseFirstName}} has No
                            Incomes Declared.</p>
                        <div class="row col s12" *ngIf="spouseIncomeModel?.length > 0">
                            <div class="section row col s12" *ngFor="let income of spouseIncomeModel;let i=index">
                                <p class="col s12 m4"><strong> {{i+1}} . Income: </strong> {{income.income}}</p>
                                <p class="col s12 m4"> <strong>Amount :</strong> {{income.amount}}</p>
                                <p class="col s12 m4"> <strong>Date Recevied :</strong> {{income.date_recevied}}</p>
                            </div>
                        </div>
                    </li>


                    <li class="section row collection-item">
                        <div class="row col s12">
                            <h5 class="col s8"><strong>Housing Information</strong></h5>
                            <a class="col blue-text right waves-effect waves-light btn-flat" (click)="editSection(10)"
                                style="cursor: pointer;"><i class="material-icons left">edit</i>Edit</a>
                        </div>
                        <div class="col s12">
                            <p class="col s12 m6"> <strong>Accomodation Shared? :</strong>
                                {{ApplicationModel.isYesOfOthers}}</p>
                            <p class="col s12 m6"> <strong>Who is Responsible for Shelter Costs? :</strong>
                                {{ApplicationModel.WhoOfOthers}}</p>
                            <p class="col s12 m6"> <strong>Relationship: </strong>
                                {{ApplicationModel.relationship_headofhouse}}</p>
                            <p class="col s12 m6"> <strong>Rented or owned ?: </strong>
                                {{ApplicationModel.isRentedOfOthers}}</p>
                            <p class="col s12 m6"> <strong>CMHC assisted housing: </strong>
                                {{ApplicationModel.isYesOfCMHC}}</p>
                            <h6 class="col s12 center-align"><strong>Costs</strong></h6>
                            <p class="col s12 m6"> <strong>Utilities :</strong> {{ApplicationModel.UtilitiesOfOthers}}
                            </p>
                            <p class="col s12 m6"> <strong>Fuel :</strong> {{ApplicationModel.FuelOfOthers}}</p>
                            <p class="col s12 m6"> <strong>Maintenance: </strong>
                                {{ApplicationModel.MaintenanceOfOthers}}</p>
                            <p class="col s12 m6"> <strong>Garbage, Water, Sewer: </strong>
                                {{ApplicationModel.GarbageOfOthers}}</p>
                            <p class="col s12 m6"> <strong>Rent / Housing loan PMT ($): </strong>
                                {{ApplicationModel.LoanPMTOfOthers}}</p>
                            <p class="col s12"> <strong>Comments: </strong> {{ApplicationModel.comments}}</p>
                            <!-- <p> <strong>SIN: </strong> {{ApplicationModel.SIN}}</p>
                            <p> <strong>Education Level: </strong> {{ApplicationModel.EDU_LEVEL}}</p> -->
                        </div>
                    </li>
                </ul>
                <div class="row col s12">
                    <button type="button" (click)="BackToApp()" class="col s12 m4  waves-effect blue btn">Back to
                        Application<i class="material-icons right">Back to The Application</i></button>
                    <button type="button" (click)="SubmitApplication()"
                        class="col s12 m4 offset-m4 waves-effect green btn">Submit<i
                            class="material-icons right">send</i></button>
                </div>
            </div>
        </div>
    </div>
</div>