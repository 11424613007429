<div *ngIf="isCandidate">
  <div class="row col s12">
    <div class="col l1"></div>

    <div class="col s12 l10">
     <form (ngSubmit)="submitForm(campaignForm.value)"  [formGroup]="campaignForm">
       <div class="row card-panel">
        <div *ngIf="candidateDetail">
          <div class="card-image">
            <div class="background-img">
              <div class="background-with-color">
                <div></div>
              </div>
              <div class="prof-img">
                <div *ngIf="!candidateDetail.profileImgLink">
                  <img class="background-img-avatar" src="../../../../../assets/img/profile.jpg"  style="width: 120px; border: white solid 3px; border-radius: 50%;"/>
                </div>
                <div  *ngIf="candidateDetail.profileImgLink">
                  <img class="background-img-avatar" [src]="candidateDetail.profileImgLink"  style="width: 120px; height: 120px; border: white solid 3px; border-radius: 50%;"/>
                </div>
              </div>
            </div>
          </div>
          <br/>
          <h5 class="center"><strong><span  *ngIf="candidateDetail.GivenName">{{candidateDetail.GivenName}}</span> <span  *ngIf="candidateDetail.LastName"> {{candidateDetail.LastName}}</span></strong> </h5>
        </div>

        <h6><strong>If you want to say something to your supporters?</strong></h6>
        <br/>
        <div class="input-field">
          <i class="material-icons prefix">mode_edit</i>
          <textarea formControlName="campaignDescription" id="campaignDescriptionTA" class="materialize-textarea"></textarea>
          <label for="campaignDescriptionTA">Campaign Message</label>
        </div>

         <h6><strong>Please Enter The Link To Your Social Accounts</strong></h6>
         <br/>

         <div class="input-field class col s12">
           <i class="fa fa-facebook prefix blue-text"></i>
           <input formControlName="facebook" id="facebook" class="validate" type="text" maxlength="250" autocomplete="off" >
           <label for="facebook"> Facebook :  </label>
           <div *ngIf="(facebook.dirty || facebook.touched) && facebook.invalid">
             <small *ngIf="facebook.errors.pattern" class="red-text"> Please enter a valid Url.
             </small>
           </div>
         </div>

         <div class="input-field class col s12">
           <i class="fa fa-twitter prefix blue-text text-lighten-2"></i>
           <input formControlName="twitter" id="twitter" class="validate" type="text" maxlength="250" autocomplete="off" >
           <label for="twitter"> Twitter :  </label>
           <div *ngIf="(twitter.dirty || twitter.touched) && twitter.invalid">
             <small *ngIf="twitter.errors.pattern" class="red-text"> Please enter a valid Url.
             </small>
           </div>
         </div>

         <div class="input-field class col s12">
           <i class="fa fa-instagram prefix" style="color: #3f729b;"></i>
           <input formControlName="instagram" id="instagram" class="validate" type="text" maxlength="250" autocomplete="off" >
           <label for="instagram"> Instagram : </label>

           <div *ngIf="(instagram.dirty || instagram.touched) && instagram.invalid">
             <small *ngIf="instagram.errors.pattern" class="red-text"> Please enter a valid Url.
             </small>
           </div>
         </div>

         <div class="input-field class col s12">
           <i class="fa fa-youtube prefix red-text"></i>
           <input formControlName="youtube" id="youtube" class="validate" type="text" maxlength="250" autocomplete="off" >
           <label for="youtube"> Youtube :  </label>
           <div *ngIf="(youtube.dirty || youtube.touched) && youtube.invalid">
             <small *ngIf="youtube.errors.pattern" class="red-text"> Please enter a valid Url.
             </small>
           </div>
         </div>

         <div class="col s12" >

          <button class='at-btn col s12 btn waves-effect' style="margin-bottom: 15px;" type="submit" [disabled]="!campaignForm.valid">
            <i class="material-icons right">cloud_done</i> Update Data
          </button>
          <button type="button" [ngClass]="'col s12 btn waves-effect ' + themeColor " (click)="backClicked()">
            <i class="material-icons left">arrow_back_ios</i> Back To Previous Page
          </button>
        </div>

       </div>

       <div class="section"></div>
     </form>
    </div>
    <div class="col l1"></div>
  </div>
</div>

<div *ngIf="!isCandidate">
  <p class="red-text center">NOT A CANDIDATE</p>
</div>
