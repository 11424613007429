import { Component, ElementRef, OnInit, Input } from "@angular/core";
import { AuthService } from "src/app/services/firebase/auth.service";
import { ActivatedRoute, Router } from "@angular/router";
import { trigger, transition, useAnimation } from "@angular/animations";
// import { bounceIn } from "ng-animate";
import { Location } from "@angular/common";
import { JwtModel, platforms, PlatformAnalyticsModel, analyticsPlatforms } from "src/app/models/SocialMediaPostModel";
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";
import { AyrshareService } from "src/app/services/social-media/ayrshare.service";
import { ProfieKeyService } from "src/app/services/social-media/profileKey.service";
import { ChartData, ChartDataset } from "chart.js";
// import { Label } from "ng2-charts";
import { environment } from "src/environments/environment";
import { AppSettingsService } from "src/app/services/firebase/app-settings.service";
import { icons } from 'src/app/models/SocialMediaIcons';
import { bounceIn } from "ng-animate";

@Component({
  selector: "app-social-media-dashboard",
  templateUrl: "./dashboard.component.html",
  styleUrls: ["./dashboard.component.css"],
  animations: [
    trigger("swing", [transition("* => *", useAnimation(bounceIn))]),
  ],
})

export class SocialMediaDashBoardComponent implements OnInit {
  defaultThemeColor = "#000000";
  themeColor: any;
  buttonType: any;

  constructor(
    private authService: AuthService,
    private router: Router,
    private location: Location,
    private socialMediaService: AyrshareService,
    private fb: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    private profieKeyService: ProfieKeyService,
    private settingsService: AppSettingsService // private permissionService: PermissionService
  ) {
    this.themeColor = this.settingsService.getAppThemeColor() ? this.settingsService.getAppThemeColor() : this.defaultThemeColor;
    this.buttonType = this.settingsService.getButtonType() ? this.settingsService.getButtonType() : "rectangularButton";
  }

  // public lineChartData: ChartDataSets[] = [
  //   { data: [61, 59, 80, 65, 45, 55], label: "Data" },
  //   { data: [90, 80, 70, 60, 50, 40], label: "Data2" },
  // ];
  public lineChartData: ChartData<'line'> = {
    labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    datasets: [
      { data: [61, 59, 80, 65, 45, 55], label: "Data" },
      { data: [90, 80, 70, 60, 50, 40], label: "Data2" },
    ]
  };

  // public lineChartLabels: Label[] = [
  //   "Jan",
  //   "Feb",
  //   "Mar",
  //   "Apr",
  //   "May",
  //   "Jun",
  //   "Jul",
  //   "Aug",
  //   "Sep",
  //   "Oct",
  //   "Nov",
  //   "Dec",
  // ];

  public lineChartOptions = {
    responsive: true,
    scales: {
      xAxes: [
        {
          stacked: true,
          gridLines: {
            display: false,
          },
        },
      ],
      yAxes: [
        {
          stacked: false,
          gridLines: {
            display: false,
          },
        },
      ],
    },
    borderRadius: 15,
  };

  public lineChartLegend = true;
  public lineChartType = "bar";
  public lineChartPlugins = [];
  profileKey;
  privateKey = environment.socialMedia.privateKey;
  userProfile;
  hasActiveAccounts = false;
  hasHistory = false;
  selectedAccount;
  loading = false;
  allAccountsPosts = [];
  availablePlatforms = platforms;

  postForm: UntypedFormGroup;
  postDeleteForm: UntypedFormGroup;
  postCommentForm: UntypedFormGroup;
  imgErrorMsg;
  selectedPost;
  laterSchedule = false;
  latestPosts = [];
  // mediaAnalysisPlatforms=[...analysisPlatforms]
  mediaAnalysisPlatforms = [...analyticsPlatforms];
  user;
  objectKeys = Object.keys;
  socialMediaIcons = icons

  // toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;

  platformAnalyticsmodel: PlatformAnalyticsModel = new PlatformAnalyticsModel();
  platformAnalytics;
  error;

  //Permission
  currentUser: any;
  isAdmin: boolean;
  perm: any;
  permission: any;

  permMember: any;
  permSocial: any;
  permPES: any;
  permSpecial: any;
  permAnalytics: any;

  ngOnInit() {
    this.currentUser = this.authService.getGlobalUser();
    if (this.currentUser.role == "Admin") {
      this.isAdmin = true;
    }

    this.openToast = false;
    this.mediaAnalysisPlatforms.push("tiktok");

    this.user = this.authService.getGlobalUser();

    this.postForm = this.fb.group({
      post: ["", [Validators.required]],
      platforms: this.fb.array([], [Validators.required]),
      mediaUrls: this.fb.array([]),
    });

    this.profieKeyService.currentProfileKey.subscribe((p) => {
      this.profileKey = p;
    });

    console.log(this.profileKey, " the profile key");
    this.getProfileInfo();
  }

  getProfileInfo() {
    if (this.profileKey) {
      this.loading = true;
      this.socialMediaService
        .getUserByProfileKey(this.profileKey)
        .toPromise()
        .then((data) => {
          this.userProfile = data;
          this.hasActiveAccounts = true
            ? data["activeSocialAccounts"] &&
            data["activeSocialAccounts"].length > 0
            : false;

          if (this.hasActiveAccounts) {
            this.loading = false;
            this.selectedAccount = data["activeSocialAccounts"][0];
            if (
              this.userProfile.displayNames.some(
                (x) => x.platform == "linkedin" && x.type == "corporate"
              )
            )
              this.mediaAnalysisPlatforms.push("linkedin");
            let p = this.getUserMediaPlatforms();
            if (p.length > 0) {
              this.platformAnalyticsmodel.platforms = p;
              this.platformAnalyticsmodel.profileKey = this.profileKey;
              this.socialMediaService
                .getSocialNetworkAnalytics(this.platformAnalyticsmodel)
                .toPromise()
                .then((a) => {
                  this.platformAnalytics = a;
                })
                .catch((err) => {
                  console.log(err, "platform analytics error");
                  Object.keys(err.error).forEach((p) => {
                    if (err.error[p]["status"] == "error") {
                      this.toast({
                        html: `Analytics error:  ${p} - ${err.error[p]["message"]}. Please try reloading the page`,
                        classes: "red",
                        type: "error",
                      });
                    }
                  });
                });
            }
          }
          this.loading = false;
        })
        .catch((err) => {
          this.error = err.error.message;
          console.log(err, "user profile error");
          this.loading = false;
        });

      this.socialMediaService
        .getHistoryByProfileKey(this.profileKey)
        .toPromise()
        .then((hist) => {
          this.hasHistory = true;
          this.allAccountsPosts = <Array<any>>hist;

          if (this.allAccountsPosts.length > 0) {
            this.latestPosts = this.allAccountsPosts
              .sort((a, b) => {
                let dateA = new Date(a.createDate._seconds * 1000);
                let dateB = new Date(b.createDate._seconds * 1000);
                return dateB.getTime() - dateA.getTime();
              })
              .splice(0, 5);
          }
        })
        .catch((err) => {
          this.allAccountsPosts = [];

          this.latestPosts = [];
          console.log(err.error.message);
        });
    }
  }

  toast(obj) {
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : "green";
    this.toastType = obj.type ? obj.type : "success";
    this.openToast = true;
    setTimeout(() => {
      this.openToast = false;
    }, 10000);
  }

  getPlatformData(platform, analytics) {
    let handler;
    switch (platform) {
      case "facebook":
        handler = this.getFacebookData;
        break;
      case "twitter":
        handler = this.getTwitterData;
        break;
      case "pinterest":
        handler = this.getPinterestData;
        break;
      case "instagram":
        handler = this.getInstagramData;
        break;
      case "linkedin":
        handler = this.getLinkedinData;
        break;
      case "tiktok":
        handler = this.getTikTokData;
        break;
      case "reddit":
        handler = this.getRedditData;
        break;
      case "youtube":
        handler = this.getYoutubeData;
        break;
      case "gmb":
        handler = this.getGmbData;
        break;
    }
    return handler.bind(this)(analytics);
  }

  getGmbData(analytics) {
    let result = {
      color: "#4267B2",
      subText: "Views detail",
      username: this.userProfile.displayNames.filter(
        (u) => u.platform == "gmb"
      )[0].displayName,
      platform: "gmb",
      image: this.userProfile.displayNames.filter((u) => u.platform == "gmb")[0]
        .userImage,
    };
    if (analytics.code == 322) {
      result["content"] = {
        message: analytics.message,
        showError: true,
      };
    } else {
      result["content"] = {
        "View search": analytics.viewsSearch,
        "View map": analytics.viewsMaps,
        "Photo view customers": analytics.photosViewsCustomers,
        "Photo view merchants": analytics.photosViewsMerchant,
      };
    }
    return result;
  }

  getYoutubeData(analytics) {
    return {
      color: "#c4302b",
      content: {
        Views: analytics.views,
        Subscribers: analytics.subscriberCount,
        Comments: analytics.comments,
        Likes: analytics.likes,
        // 'Videos count':analytics.videoCount,
      },
      username: this.userProfile.displayNames.filter(
        (u) => u.platform == "youtube"
      )[0].displayName,
      subText: "Karma details",
      platform: "youtube",
      image: this.userProfile.displayNames.filter(
        (u) => u.platform == "youtube"
      )[0].userImage,
    };
  }

  getRedditData(analytics) {
    return {
      color: "#FF4500",
      content: {
        "Karma awardee": analytics.awardeeKarma,
        "Karma awarder": analytics.awarderKarma,
        "Karma coment": analytics.commentKarma,
        "Total karma": analytics.totalKarma,
      },
      username: this.userProfile.displayNames.filter(
        (u) => u.platform == "reddit"
      )[0].displayName,
      subText: "Karma details",
      platform: "reddit",
      image: this.userProfile.displayNames.filter(
        (u) => u.platform == "reddit"
      )[0].userImage,
    };
  }

  getLinkedinData(analytics) {
    return {
      color: "#0072b1",
      content: {
        "Career page views": analytics.views
          ? analytics.views.careersPageViews
          : "-",
        "Job pge view": analytics.views ? analytics.views.jobsPageViews : "-",
        "People Page views": analytics.views
          ? analytics.views.peoplePageViews
          : "-",
        Followers: analytics.followers.organicFollowerCount
          ? analytics.followers.organicFollowerCount
          : 0,
      },
      username: this.userProfile.displayNames.filter(
        (u) => u.platform == "linkedin"
      )[0].displayName,
      subText: "Past 500 post reactions",
      platform: "linkedin",
      image: this.userProfile.displayNames.filter(
        (u) => u.platform == "linkedin"
      )[0].userImage,
    };
  }

  getFacebookData(analytics) {
    return {
      color: "#4267B2",
      content: {
        Engagment:
          analytics && analytics.engagement ? analytics.engagement.count : "-",
        Followers: analytics.followersCount,
        Fan: analytics.fanCount,
        Reactions: analytics.reactions ? analytics.reactions.total : "-",
      },
      username: analytics.name,
      subText: "Past 500 post reactions",
      platform: "facebook",
      image: this.userProfile.displayNames.filter(
        (u) => u.platform == "facebook"
      )[0].userImage,
    };
  }

  getTwitterData(analytics) {
    return {
      color: "#1da1f2",
      content: {
        Favourites: analytics.favoritesCount,
        Followers: analytics.followersCount,
        Friends: analytics.friendsCount,
      },
      username: analytics.username,
      subText: "Past 500 post reactions",
      platform: "twitter",
      image: analytics.profileImageUrl,
    };
  }

  getPinterestData(analytics) {
    return {
      color: "#E60023",
      content: {
        Engagment: analytics.engagement,
        Impression: analytics.impression,
        Save: analytics.save,
        "Pin click": analytics.pinClick,
      },
      username: this.userProfile.displayNames.filter(
        (u) => u.platform == "pinterest"
      )[0].displayName,
      // username:'',
      subText: "30 days summary and daily details",
      platform: "pinterest",
      image: this.userProfile.displayNames.filter(
        (u) => u.platform == "pinterest"
      )[0].userImage,
    };
  }

  getInstagramData(analytics) {
    return {
      color: "#C32889",
      content: {
        Posts: analytics.mediaCount,
        Likes: analytics.likeCount,
        Followers: analytics.followersCount,
        Comments: analytics.commentsCount,
      },
      username: analytics.name,
      subText: "Past 500 post reactions",
      platform: "instagram",
    };
  }

  getTikTokData(analytics) {
    return {
      color: "#014E4B",
      content: {
        Views: analytics.durationAverage,
        Likes: analytics.likeCountTotal,
        Comments: analytics.commentCountTotal,
        Share: analytics.shareCountTotal,
      },
      username: this.userProfile.displayNames.filter(
        (u) => u.platform == "tiktok"
      )[0].displayName,
      image: this.userProfile.displayNames.filter(
        (u) => u.platform == "tiktok"
      )[0].userImage,
      subText: "Past 500 post reactions",
      platform: "tiktok",
    };
  }

  linkSocialMedia() {
    let model = new JwtModel();

    model.privateKey = this.privateKey;
    model.profileKey = this.profileKey;

    this.socialMediaService.generateJWT(model).subscribe((x) => {
      if (x["status"] === "success") {
        window.open(x["url"], "_blank");
      } else {
        console.log("something happened");
      }
    });
  }
  
  getUserMediaPlatforms() {
    return this.userProfile["activeSocialAccounts"].filter((s) => {
      return this.mediaAnalysisPlatforms.includes(s);
    });
  }

  getBadgeClass(status) {
    let css;
    switch (status) {
      case "success":
        css = "badge-success";
        break;
      case "error":
        css = "badge-error";
        break;
      case "pending":
        css = "badge-warning";
        break;
      case "processing":
        css = "badge-info";
        break;
    }
    return css;
  }

  toCreatePost() {
    this.router.navigate(["../create-post"], {
      state: {
        profileKey: this.profileKey,
        platforms: this.userProfile["activeSocialAccounts"],
      },
      relativeTo: this.activatedRoute,
    });
  }
}
