<div class="col s12">
  <div class="body-h">
    <div class="header-setting b-m-2">

      <div class="col l12 m12 s12">
        <h4 class="bold-500 icon-text title-nation"> <i class="material-symbols-outlined  bold-500 p-r-1 "
            (click)="backClicked()"> arrow_back </i> Manage Administrators</h4>

        <select class="browser-default hide-on-large-only" (change)="navigate($event.target.value)">
          <option value="Admins" disabled selected>Admins</option>
          <option value="Membership">Membership</option>
          <option value="Applications">Applications</option>
          <option value="DemoRequest">Demo Request</option>
        </select>

        <p class="bold-100 sub-title-section3">This is where you can oversee and control administrative user accounts.
          Easily assign roles, adjust permissions, and maintain activities.</p>
      </div>
    </div>

    <main class="StickyContent white ">

      <div class="row">
        <div class="col s12 m11 l11">

          <!--**************************** Admin Details **************************************-->
          <div class="row card-panel white">
            <div class="row collection" *ngIf="nationAdmin">
              <a class="row collection-item">
                <div>First Name:<a class="secondary-content blue-text">
                    <strong *ngIf="loggedUser.isDemo"> {{(nationAdmin?.FirstName !== null) ? (nationAdmin?.FirstName |
                      NameStars) : ""}}</strong>
                    <strong *ngIf="!loggedUser.isDemo"> {{nationAdmin?.FirstName}}</strong>
                  </a></div>
              </a>
              <a class="row collection-item">
                <div>Last Name:<a class="secondary-content blue-text">
                    <strong *ngIf="loggedUser.isDemo"> {{(nationAdmin?.LastName !== null) ? (nationAdmin?.LastName |
                      NameStars)
                      : ""}}</strong>
                    <strong *ngIf="!loggedUser.isDemo"> {{nationAdmin?.LastName}}</strong>
                  </a></div>
              </a>
              <a class="row collection-item">
                <div>User Name:
                  <a class="secondary-content black-text">
                    <strong *ngIf="loggedUser.isDemo"> {{(nationAdmin?.loginID !== null) ? (nationAdmin?.loginID |
                      NameStars)
                      :
                      ""}}</strong>
                    <strong *ngIf="!loggedUser.isDemo"> {{nationAdmin?.loginID}}</strong>
                  </a>
                </div>
              </a>
              <a class="row collection-item">
                <div>User Group:<a class="secondary-content black-text">
                    <strong *ngIf="loggedUser.isDemo"> {{(nationAdmin?.userGroup !== null) ? (nationAdmin?.userGroup |
                      NameStars) : ""}}</strong>
                    <strong *ngIf="!loggedUser.isDemo"> {{nationAdmin?.userGroup}}</strong>
                  </a></div>
              </a>
              <a class="row collection-item">
                <div>Email:<a class="secondary-content black-text">
                    <strong *ngIf="loggedUser.isDemo"> {{(nationAdmin?.Email_Address !== null) ?
                      (nationAdmin?.Email_Address
                      |
                      NameStars) : ""}}</strong>
                    <strong *ngIf="!loggedUser.isDemo"> {{nationAdmin?.Email_Address}}</strong>
                  </a></div>
              </a>
              <a class="row collection-item" *ngIf="!dependant">
                <div>Phone:<a class="secondary-content black-text">
                    <strong *ngIf="loggedUser.isDemo"> {{(nationAdmin?.phone !== null) ? (nationAdmin?.phone |
                      NameStars)
                      :
                      ""}}</strong>
                    <strong *ngIf="!loggedUser.isDemo"> {{nationAdmin?.phone}}</strong>
                  </a></div>
              </a>
              <a class="row collection-item">
                <div>Registred :<a class="secondary-content black-text">
                    <span class="green-text" *ngIf="firebaseAdmin"> <strong> Yes </strong> </span>
                    <span class="red-text" *ngIf="!firebaseAdmin"><strong> No </strong></span>
                  </a></div>
              </a>
              <a class="row collection-item">
                <div>Status:<a class="secondary-content black-text">{{(nationAdmin?.Active === true) ? "Active" :
                    "Disabled"}}</a></div>
              </a>
            </div>

            <div class="row collection" *ngIf="!nationAdmin && firebaseAdmin">
              <a class="row collection-item">
                <div>First Name:<a class="secondary-content blue-text">
                    <strong *ngIf="loggedUser.isDemo"> {{(firebaseAdmin?.FirstName !== null) ? (firebaseAdmin?.FirstName
                      |
                      NameStars) : ""}}</strong>
                    <strong *ngIf="!loggedUser.isDemo"> {{firebaseAdmin?.FirstName}}</strong>
                  </a></div>
              </a>
              <a class="row collection-item">
                <div>Last Name:<a class="secondary-content blue-text">
                    <strong *ngIf="loggedUser.isDemo"> {{(firebaseAdmin?.LastName !== null) ? (firebaseAdmin?.LastName |
                      NameStars)
                      : ""}}</strong>
                    <strong *ngIf="!loggedUser.isDemo"> {{firebaseAdmin?.LastName}}</strong>
                  </a></div>
              </a>
              
              <a class="row collection-item">
                <div>Email:<a class="secondary-content black-text">
                    <strong *ngIf="loggedUser.isDemo"> {{(firebaseAdmin?.Email !== null) ? (firebaseAdmin?.Email |
                      NameStars) : ""}}</strong>
                    <strong *ngIf="!loggedUser.isDemo"> {{firebaseAdmin?.Email}}</strong>
                  </a></div>
              </a>
             
              <a class="row collection-item">
                <div>Registred :<a class="secondary-content black-text">
                    <span class="green-text" *ngIf="firebaseAdmin"> <strong> Yes </strong> </span>
                    <span class="red-text" *ngIf="!firebaseAdmin"><strong> No </strong></span>
                  </a></div>
              </a>
              <a class="row collection-item">
                <div>Status:<a class="secondary-content black-text">{{firebaseAdmin?.status }}</a></div>
              </a>
            </div>
          </div>

          <!--**************************** Permissions **************************************-->
          <div *ngIf="firebaseAdmin">
            <div class="row card-panel white">
              <div class="col l11 s9">
                <h6 class="title-section3 bold-500">Grant Access</h6>
                <p>Expand to customize and set the prefered accesss for admins.</p>
              </div>

              <div class="col l1 s3 p-t-3">
                <a style="cursor: pointer;">
                  <span *ngIf="!expand" class="material-icons black-text center" (click)="toggleExpand()">
                    expand_more
                  </span>
                  <span *ngIf="expand" class="material-icons black-text center" (click)="toggleExpand()">
                    expand_less
                  </span>
                </a>
              </div>

              <div *ngIf="expand">
                <div *ngIf="menus && menus.length > 0">
                  <div *ngFor="let menu of menus; let i = index">
                    <div class="col s12 l12 card-panel menu-cards">

                      <div class="col s12">
                        <label>
                          <input name="menu" type="checkbox" class="filled-in" [checked]="menuChecked.has(menu.name)"
                            *ngIf="!menus[i].submenus || menus[i].submenus.length == 0" (click)='menuClicked(menu)' />
                          <span class="icon-text"><span class="material-symbols-outlined p-r-1"
                              [ngStyle]="{'color': themeColor}">
                              {{menu.icon}}
                            </span>{{menu.displayName}}</span>
                        </label>

                        <label class="right" *ngIf="menus[i].submenus && menus[i].submenus.length >0">
                          <input type="checkbox" (click)="selectAll(menu.name)"
                            [checked]="allChecked.includes(menu.name)" />
                          <span>Select All</span>
                        </label>
                      </div>

                      <div *ngIf="menus[i].submenus && menus[i].submenus.length >0">
                        <div class="row col s12" style="margin-top: 2.5vh;"
                          *ngIf="menus[i].submenus && menus[i].submenus.length >0">
                          <div *ngFor="let submenu of menus[i].submenus; let s = index">
                            <div class="col s11 m5 l3 offset-s1 offset-m1 offset-l1">
                              <label>
                                <input name="submenu" type="checkbox" class="filled-in"
                                  [checked]="menuChecked.has(submenu.name)" (click)='menuClicked(menu, submenu)' />
                                <span class="icon-text"><span class="material-symbols-outlined p-r-1"
                                    [ngStyle]="{'color': themeColor}">
                                    {{submenu.icon}}
                                  </span>{{submenu.displayName}}</span>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div *ngIf="false">
            <h6 class="bold-700" *ngIf="firebaseAdmin">Permissions</h6>
            <div class="row card white" *ngIf="firebaseAdmin">
              <div class="row card-content">

                <span class="row col s12 m12 l12">
                  <p class="right">
                    <label>
                      <input type="checkbox" (click)="selectAll()" [checked]="allChecked" />
                      <span>Select All</span>
                    </label>
                  </p>
                </span>

                <div class="row" *ngIf="menus">
                  <div class="col s12 l12 card-panel" *ngFor="let menu of menus; let i = index">

                    <div class="col s12">
                      <label>
                        <input name="menu" type="checkbox" class="filled-in" [checked]="getPermission(menu)"
                          (click)='menuClicked(menu)' />
                        <span>{{menu.displayName}}</span>
                      </label>
                    </div>

                    <div *ngIf="getPermission(menu)">
                      <div *ngIf="menus[i].submenus && menus[i].submenus.length >0">
                        <div *ngFor="let submenu of menus[i].submenus; let s = index">
                          <div class="col s11 m5 l3 offset-s1 offset-m1 offset-l1">
                            <label>
                              <input name="submenu" type="checkbox" class="filled-in"
                                [checked]="getPermission(menu, submenu)" (click)='menuClicked(menu, submenu)' />
                              <span>{{submenu.displayName}}</span>
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="row col s12">

            <div class="col s12">
              <button *ngIf="firebaseAdmin" class="col s12 btn waves-effect blue" [disabled]="loggedUser.isDemo"
                (click)="updatePermissions()" style="margin-bottom: 15px;"><i class="material-icons right">lock</i>
                Update
                Permissions</button>
              <button *ngIf="firebaseAdmin" class="col s12 btn waves-effect orange darken-2" (click)="adminActivity()"
                style="margin-bottom: 15px;"><i class="material-icons right">phonelink</i> Admin Activity </button>
              <button *ngIf="firebaseAdmin" class="col s12 btn waves-effect teal modal-trigger"
                data-target="contactModal" [disabled]="loggedUser.isDemo" style="margin-bottom: 15px;"><i
                  class="material-icons right">mail</i>
                Contact
                Admin</button>
              <button *ngIf="!isEnabled && firebaseAdmin" class="col s12 btn waves-effect green modal-trigger"
                data-target="confirmationModal" [disabled]="loggedUser.isDemo" style="margin-bottom: 15px;"><i
                  class="material-icons right">mobile_friendly</i> Enable
                Admin Account</button>
              <button *ngIf="isEnabled  && firebaseAdmin" class="col s12 btn waves-effect red modal-trigger"
                data-target="confirmationModal" [disabled]="loggedUser.isDemo" style="margin-bottom: 15px;"><i
                  class="material-icons right">mobile_off</i> Disable
                Admin Account</button>
             
              <a [ngClass]="'col s12 btn waves-effect ' + themeColor" (click)="backClicked()"><i
                  class="material-icons left">arrow_back_ios</i> Back To Adminis List</a>
            </div>
          </div>

          <div id="confirmationModal" class="modal b-n-1">
            <div class="modal-content" *ngIf="firebaseAdmin">
              <div *ngIf="isEnabled">
                <h4 class="col s11">Disable Account</h4>
                <i class="material-icons right modal-close col s1">close</i>
                <div class="valign-wrapper card-panel red lighten-5 row col s12">
                  <i class="material-icons left red-text" style="font-size: 40px; font-weight: 100;">
                    warning_amber
                  </i>
                  <div class="modal-delete">
                    <h6>Are You sure you want to Disable this Account? </h6>
                    <h6 class="red-text"><strong>Users with a disabled accounts aren't able to sign in.</strong></h6>
                  </div>
                </div>
              </div>

              <div *ngIf="!isEnabled">
                <h4 class="col s11">Enable Account</h4>
                <i class="material-icons right modal-close col s1">close</i>
                <div class="valign-wrapper card-panel blue lighten-5">
                  <i class="material-icons left blue-text" style="font-size: 40px; font-weight: 100;">
                    info
                  </i>
                  <div class="modal-delete">
                    <h6>Are You sure you want to Enable this Account? </h6>
                    <h6><strong>Users with enabled accounts will be able to sign in again.</strong></h6>
                  </div>
                </div>
              </div>

              <h6 class="label-input">User account</h6>
              <h6 class="grey-text p-b-3"><strong>{{firebaseAdmin.Email}}</strong></h6>

              <h6 class="label-input">Please Enter the Reason for <span *ngIf="isEnabled">Disabling</span> <span
                  *ngIf="!isEnabled">Enabling</span>
                this account</h6>
              <div class="input-field col s12">
                <input placeholder="Reason" id="reason" type="text" [(ngModel)]="reason" class="validate">
              </div>

              <br />
              <p class="col s12 " style="margin-bottom: 40px; font-size: 13px;">
                <strong>Do you wish to send a message to the client?</strong>
                <label class="right">
                  <input type="checkbox" (click)="onSendMessage()" [checked]='sendMessage' />
                  <span>Send Email</span>
                </label>
              </p>
            </div>

            <div class="modal-footer">
              <a *ngIf="!isEnabled" (click)="enableAccount()" class="modal-close btn add-button"
                [ngClass]="{'disabled': !reason}" [ngClass]="'btn ' + buttonType"><strong>Enable</strong></a>
              <a *ngIf="isEnabled" (click)="disableAccount()" class="modal-close btn delete-button"
                [ngClass]="{'disabled': !reason}" [ngClass]="'btn ' + buttonType"><strong>Disable</strong></a>
            </div>
          </div>

          <div id="contactModal" class="modal b-n-1">
            <div class="modal-content">

              <h4 class="col s11">Contact Client</h4>
              <i class="material-icons right modal-close col s1">close</i>
              <form [formGroup]="emailForm" (ngSubmit)="Send(emailForm.value)">
                <div class="row">

                  <div class="input-field col s12 l12">
                    <div class="label-input">Subject</div>
                    <input id="subject" type="text" formControlName='subject' required class="validate"
                      autocomplete="off" placeholder="Subject" />
                    <div *ngIf="(subject.dirty || subject.touched) && subject.invalid">
                      <small *ngIf="subject.errors.required" class="red-text"> The Subject is required. </small>
                    </div>
                  </div>

                  <div class="input-field col s12 l12">
                    <div class="label-input">Body</div>
                    <textarea id="body" type="text" formControlName='body' required autocomplete="off"
                      class="validate materialize-textarea" style="height: 175px;" maxlength="500"
                      placeholder="Body"> </textarea>
                    <div *ngIf="(body.dirty || body.touched) && body.invalid">
                      <small *ngIf="body.errors.required" class="red-text"> The Body Is Required.
                      </small>
                    </div>
                  </div>
                </div>

                <div class="row">
                  <div class="input-field col s12 center">
                    <button type="submit" [disabled]="!emailForm.valid" class='col s10 push-s1 btn waves-effect green'>
                      Send Email
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

      </div>
    </main>
  </div>
</div>