<div class="row" *ngIf="member">
  <br />
  <h5 class="center p-m-2 b-m-2">Welcome, <span [ngClass]="fontColor"> {{ member.GivenName }} </span></h5>
  <br />

  <app-firebase-loading *ngIf="loading"></app-firebase-loading>

  <div class="col m1 l2 "></div>
  <div class="col s12 m10 l8 ">

    <div class="row card" *ngIf="step1">
      <div class="card-content">
        <span class="card-title center">
          <h6 class="red-text"><strong> This is a secure site. Please help us verify your identity by answering the
              questions below. </strong></h6>
        </span>
        <div class="section"></div>

        <div *ngIf="step1">
          <form [formGroup]="verificationForm">
            <div class="row" *ngIf="member?.PWA_Verify_SIN">
              <p>Please Enter The Last <strong class="orange-text">3 Digits</strong> Of Your Social Insurance Number
                <span class="red-text">(*)</span>
              </p>
              <div class="input-field col s12 l12">
                <i class="material-icons prefix">lock</i>
                <input id="sin" type="text" maxlength="9" onkeypress="return isNumberKey(event)"
                  onkeydown="return sinPreventDelete(event,6)" formControlName='sin' required class="validate" />
                <div *ngIf="(sin.dirty || sin.touched) && sin.invalid">
                  <small *ngIf="sin.errors.required" class="red-text"> The SIN Is Required. </small>
                  <small *ngIf="sin.errors.minlength || sin.errors.minlength" class="red-text"> SIN Must be 9 digits.
                  </small>
                </div>
              </div>
            </div>

            <div class="row" *ngIf="member?.PWA_Verify_ISN">
              <p>Please Enter The Last <strong class="orange-text">5 Digits</strong> Of Your Indian Status Number <span
                  class="red-text">(*)</span></p>
              <div class="input-field col s11">
                <i class="material-icons prefix">lock</i>
                <input id="isn" type="text" formControlName='isn' required class="materialize-textarea validate"
                  maxlength="10" onkeypress="return isNumberKey(event)" onkeydown="return isnPreventDelete(event,6)" />
                <div *ngIf="(isn.dirty || isn.touched) && isn.invalid">
                  <small *ngIf="isn.errors.required" class="red-text"> The ISN Is Required. </small>
                  <small *ngIf="isn.errors.minlength || isn.errors.minlength" class="red-text"> ISN Must be 10 digits.
                  </small>
                </div>
              </div>
              <div class="col s1">
                <div class="row center">
                  <br />
                  <i class="material-icons green-text modal-trigger" data-target="modal2">help</i>
                </div>
              </div>
            </div>

            <div class="row" *ngIf="member?.PWA_Secret_Question">
              <div class="input-field col s12 l12">
                <i class="material-icons prefix">help_outline</i>
                <input id="question" type="text" formControlName='question' required
                  class="materialize-textarea validate" />
                <label for="question">{{member?.PWA_Secret_Question}} </label>
                <div *ngIf="(question.dirty || question.touched) && question.invalid">
                  <small *ngIf="question.errors.required" class="red-text"> Please answer the secret question! </small>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="input-field col s12 center">
                <button type="submit" [disabled]="!verificationForm.valid"
                  [ngClass]="'col s12 btn waves-effect ' + themeColor" (click)="verification(verificationForm)">
                  <i class="material-icons left">lock</i> Verify
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div class="row card" *ngIf="step2">
      <div class="row card-content">
        <div class="input-field col s12 l12">
          <i class="material-icons prefix">email</i>
          <input id="Email" type="text" [(ngModel)]="member.Email" disabled />
        </div>

        <form [formGroup]="registrationForm" (ngSubmit)="register(registrationForm)">
          <div class="row">
            <div class="input-field col s12 l12">
              <i class="material-icons prefix">lock</i>
              <input id="createPassword" type="password" formControlName='createPassword' required class="validate"
                minlength="4" maxlength="25" />
              <label for="createPassword">Create Password</label>
              <div *ngIf="(createPassword.dirty || createPassword.touched) && createPassword.invalid">
                <small *ngIf="createPassword.errors.required" class="red-text"> The Password Is Required.
                </small>
                <small *ngIf="createPassword.errors.minlength" class="red-text"> Password Length Must Be At Least 4
                  Characters . </small>
                <small *ngIf="createPassword.errors.maxlength" class="red-text"> Password Must Not Exceed 25
                  Characters . </small>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="input-field col s12">
              <i class="material-icons prefix">lock</i>
              <input id="confirmPassword" type="password" required formControlName='confirmPassword' class="validate">
              <label for="confirmPassword">Confirm Password</label>
              <div *ngIf="(confirmPassword.dirty || confirmPassword.touched) && confirmPassword.invalid">
                <small *ngIf="confirmPassword.errors.required" class="red-text">Confirm Password is required. </small>
                <small *ngIf="confirmPassword.hasError('invalid')" class="red-text">Passwords Doesnt Match ! </small>
              </div>
            </div>
          </div>

          <div class="section"></div>
          <div class="row center">
            <p><strong>By Clicking <span class="green-text">Create My Account</span> I hereby :</strong></p>
            <p><strong>Agree and consent to the <a [routerLink]="['/eula']">User Agreement</a>, its policies and the
                <a [routerLink]="['/privacy-policy']">Privacy Policy</a>.</strong> </p>
          </div>

          <div class="row">
            <div class="input-field col s12 center">
              <button type="submit" [disabled]="!registrationForm.valid"
                [ngClass]="'col s12 btn waves-effect ' + themeColor">
                <i class="material-icons left">how_to_reg</i> Create My Account
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="row card" *ngIf="accountExists">
      <div class="card-content">
        <div class="row center">
          <i style="font-size: 80px;" class="material-icons blue-text">
            info
          </i>
          <h5 class="blue-text"><strong> You are Already Registered.
            </strong></h5>
          <p>A member with this id is already Registred in our
            system.
          </p>
          <!-- <h6>Your Account has been Enabled.You can sign in with your password.</h6> -->
          <br /> <br />
          <h6 class="red-text"><strong>If you think this is a mistake Please Contact the Administrator.
            </strong></h6>
        </div>
        <div class="row center">
          <a class="btn green" (click)="contactAdmin()">Contact Administrator<i class="material-icons left">mail</i></a>
        </div>
      </div>
    </div>

    <div class="row card" *ngIf="accountLinked">
      <div class="card-content">
        <div class="row center">
          <i style="font-size: 80px;" class="material-icons green-text">
            check_circle
          </i>
          <p><strong>
              Your Accounts has been linked.
            </strong></p>
          <br />
          <p> <strong>
              You Can now access both accounts with you Employee account credentials.
            </strong></p>
          <p> <strong>
              If you have any other concers or question.
            </strong></p>
          <br />
          <p class="red-text"><strong>Our Business Hours Are : <br> Monday - Friday 9 AM - 5 PM</strong></p>
          <br />
          <p> <strong>
              Have a wonderful day
            </strong></p>
        </div>
      </div>
    </div>

    <div class="row card" *ngIf="step3">
      <div class="card-content">
        <div class="row center">
          <i style="font-size: 80px;" class="material-icons green-text">
            check_circle
          </i>
          <p><strong>
              Thank you for Contacting Us.
            </strong></p>
          <br />
          <p> <strong>
              The Admnistrator will review Your Email and will reach out with the response.
            </strong></p>
          <p> <strong>
              If you have any other concers or question.
            </strong></p>
          <br />
          <p class="red-text"><strong>Our Business Hours Are : <br> Monday - Friday 9 AM - 5 PM</strong></p>
          <br />
          <p> <strong>
              Have a wonderful day
            </strong></p>
        </div>
      </div>
    </div>
  </div>
  <div class="col m1 l2 "></div>
</div>

<div id="modal2" class="modal">
  <div class="modal-content">
    <h6>Client Identification Numbers for Eligible First Nations</h6>
    <p>Provision of one of these identifiers is required for eligible First
      Nations clients (including registered Indians under the terms of the Indian Act):</p>

    <p>
      ISC Number (also known Treaty or Status Number)
    </p>
    <p>
      This is a 10-digit number issued by Indian and Northern Affairs Canada (ISC),
      formerly known as DIAND, to all eligible First Nations clients.
    </p>
    <p>
      The ISC number is the preferred method of identifying First Nations clients. This number is made up of:
      <br />
      The first 3 digits identify the band to which the client belongs;<br />
      The next 5 digits identify the client's family unit within the band;<br /> and
      The last 2 digits identify the client's position in the family (for example, 01, 02, and 03).
    </p>
  </div>
  <div class="modal-footer">
    <a class="modal-close amber darken-4 white-text btn-flat white-text"> <i
        class="material-icons right">close</i>close</a>
  </div>
</div>

<div id="linkAccount" class="modal">
  <div class="modal-content">
    <h5 class="center green-text">You're Already Registred as an Employee!</h5>
    <br />
    <div class="row center-align">
      <p><strong>Do you want to link your Member accout to your Employee account ?</strong></p>
      <!-- <p><strong> OR </strong></p>
      <p><strong>Do you want to create a separate account with your work Email?</strong> </p> -->
    </div>
    <div class="row">
      <div class="col s1"></div>
      <div class="col s10">
        <a (click)="linkAccount()" class="col s12 waves-effect waves-blue blue center btn-large">Link Accounts</a>
        <!-- <a (click)="createEmployeeAccount()" class="col s12 m5 waves-effect waves-green green btn-large right"
          style="margin-bottom: 15px;">Create Employee
          Account</a> -->
      </div>
      <div class="col s1"></div>
    </div>
  </div>
</div>

<div id="emailModal" class="modal" *ngIf="contact">
  <form [formGroup]="contactForm" (ngSubmit)="sendEmail(contactForm.value)">
    <div class="row modal-content">
      <h6 class="center"><strong>Contact Adminstrator</strong></h6>

      <div class="input-field col s12 l6">
        <i class="material-icons prefix pink-text">person</i>
        <input id="firstName" type="text" formControlName='firstName' required class="validate" autocomplete="off" />
        <label for="firstName">First Name<span class="red-text">*</span></label>
        <div *ngIf="(contactfirstName.dirty || contactfirstName.touched) && contactfirstName.invalid">
          <small *ngIf="contactfirstName.errors.required" class="red-text"> The First Name is required. </small>
        </div>
      </div>

      <div class="input-field col s12 l6">
        <i class="material-icons prefix purple-text">person</i>
        <input id="lastName" type="text" formControlName='lastName' required class="validate" autocomplete="off" />
        <label for="lastName">Last Name<span class="red-text">*</span></label>
        <div *ngIf="(contactlastName.dirty || contactlastName.touched) && contactlastName.invalid">
          <small *ngIf="contactlastName.errors.required" class="red-text"> The Last Name is required. </small>
        </div>
      </div>

      <div class="input-field col s12 l12">
        <i class="material-icons prefix blue-text">alternate_email</i>
        <input id="email" type="email" formControlName='email' required class="validate" autocomplete="off" />
        <label for="email">My Email<span class="red-text">*</span></label>
        <div *ngIf="(contactemail.dirty || contactemail.touched) && contactemail.invalid">
          <small *ngIf="contactemail.errors.required" class="red-text"> Your Email is required. </small>
          <small *ngIf="contactemail.errors.email" class="red-text"> wrong email format. </small>
        </div>
      </div>

      <div class="input-field col s12 l12">
        <i class="material-icons prefix pink-text">mail</i>
        <input id="subject" type="text" formControlName='subject' required class="validate" autocomplete="off" />
        <label for="subject">Subject<span class="red-text">*</span></label>
        <div *ngIf="(subject.dirty || subject.touched) && subject.invalid">
          <small *ngIf="subject.errors.required" class="red-text"> The Subject is required. </small>
        </div>
      </div>

      <div class="input-field col s12 l12">
        <i class="material-icons prefix purple-text">subject</i>
        <textarea id="body" type="text" formControlName='body' required autocomplete="off"
          class="validate materialize-textarea" style="height: 100px;" maxlength="1000"> </textarea>
        <label for="body">Body<span class="red-text">*</span></label>
        <div *ngIf="(body.dirty || body.touched) && body.invalid">
          <small *ngIf="body.errors.required" class="red-text"> The Body Is Required.
          </small>
        </div>
      </div>

      <button type="submit" [disabled]="!contactForm.valid" style="margin-left: 5px;" class="btn green right">
        <i class="material-icons right">send</i>
        Send</button>

      <a class="modal-close btn red right"> <i class="material-icons right">close</i> Close</a>
    </div>
  </form>
</div>