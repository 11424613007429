import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ContactService } from 'src/app/services/firebase/contact.service';

@Component({
  selector: 'app-discussion',
  templateUrl: './discussion.component.html',
  styleUrls: ['./discussion.component.css']
})

export class DiscussionComponent implements OnInit {

  discussionID: any;
  messageID: any;
  message: any;

  constructor(
    private route: ActivatedRoute,
    private contactService: ContactService,
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.discussionID = params.get('id');
    });

    this.messageID = localStorage.getItem('msgID');

    if (this.messageID) {
      localStorage.removeItem('msgID');
      this.contactService.getMessageByID(this.messageID).valueChanges().subscribe(
        msg => {
          if (msg) {
            this.message = msg;
            this.message.isRead = true;
            this.contactService.updateMessage(this.message);
          }
        });
    }
  }

}