import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ShareDataModel } from 'src/app/models/SocialMediaPostModel';

@Injectable({
 providedIn: 'root'
})
export class ProfieKeyService{
    private profileKey= new BehaviorSubject('')
    private shareData = new BehaviorSubject(ShareDataModel)


    currentProfileKey=this.profileKey.asObservable();
    currentShareData=this.shareData.asObservable();

    private _shouldShare: boolean = false

    constructor(){

    }

    updateProfileKey(profileKey){
        this.profileKey.next(profileKey)
    }

    updateShareData(shareData){
        this.shareData.next(shareData)
    }

    public get shouldShare(){
        return this._shouldShare
    }

    public set shouldShare(share: boolean){
        this._shouldShare = share
    }
}