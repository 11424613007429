import { Component, OnInit } from '@angular/core';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { toast } from 'materialize-css';
import { Options } from 'sortablejs';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';

declare var $: any;

@Component({
  selector: 'app-app-menu',
  templateUrl: './app-menu.component.html',
  styleUrls: ['./app-menu.component.css']
})

export class AppMenuComponent implements OnInit {

  defaultThemeColor = environment.appTheme.themeColor;
  themeColor: any;
  buttonType: any;
  sectionName: string;
  isNewSettings = false;
  appMenu: any;
  menu: any;
  MenuItems: any[];
  submenus: any[];
  options: Options = {
    animation: 150,
  };

  constructor(
    private settingsService: AppSettingsService,
    private dataService: DataService,
    private router: Router) {
    this.themeColor = this.settingsService.getAppThemeColor() ? this.settingsService.getAppThemeColor() : this.defaultThemeColor;
    this.buttonType = this.settingsService.getButtonType() ? this.settingsService.getButtonType() : 'rectangularButton';

    this.MenuItems = this.dataService.getAppMenu();
  }

  ngOnInit() {
    this.settingsService.getMenuName('Settings').valueChanges().subscribe(menus => {
      if (menus && menus.length > 0) {
        let submenus = menus[0].submenus;
        let menu = submenus.filter(m => m.name == 'AppMenu');
        if (menu && menu.length > 0) this.sectionName = menu[0].displayName;

        let menuss = this.submenus.find(m => m.name == 'AdminMenu');
        if (menuss) this.sectionName = menu.displayName;
      }
    });

    this.settingsService.getMenuSettings().valueChanges().subscribe(
      menu => {
        if (menu) {
          if (menu.length > 0) {
            this.appMenu = menu[0];
            this.isNewSettings = false;
          } else {
            this.appMenu = { MenuItems: this.MenuItems};
            this.isNewSettings = true;
          }
          setTimeout(() => {
            $('.tooltipped').tooltip();
            $('.modal').modal();
          }, 25);
        }
      }
    );

    
  }

  visibilityChanged(item: any) {
    item.isEnabled = !item.isEnabled;
    if (!item.isEnabled) item.favorite = false;
  }

  favoriteSelected(item) {
    this.appMenu.MenuItems.forEach(menu => {
      menu.favorite = false;
    });
    item.favorite = item.favorite ? !item.favorite : true;
  }

  moveSection(element, position: number) {
    var index = this.appMenu.MenuItems.indexOf(element);
    var newIndex = index + position;
    if (newIndex < 0 || newIndex == this.appMenu.MenuItems.length) return; //Already at the top or bottom.   
    this.appMenu.MenuItems.splice(index, 1);
    this.appMenu.MenuItems.splice(newIndex, 0, element);
    this.appMenu.MenuItems.forEach(element => {
      element.index = this.appMenu.MenuItems.indexOf(element);
    });
  };

  saveMenu() {
    this.appMenu.MenuItems.forEach(element => {
      element.index = this.appMenu.MenuItems.findIndex(x => x.item === element.item);
    });
    if (this.isNewSettings) {
      this.settingsService.saveMenuSettings(this.appMenu);
      toast({ html: 'Menu Successfully Saved', classes: 'green' });
      this.backClicked();
    } else {
      this.settingsService.updateMenuSettings(this.appMenu);
      toast({ html: 'Menu Successfully Saved', classes: 'green' });
      this.backClicked();
    }
  }

  backClicked() {
    this.router.navigate(['/admin/dashboard']);
  }

}
