<div class="content-left ">
  <div class="card-yes ">
    <div class=" border-content  col s12 m4 l12">
      <div class="row">

        <div class="col s12 m12 l12 ">
          <div class="row center-align">
            <strong>
              <h6 *ngIf="nationOfUse === 'Batc'" class="center">Contact Office</h6>
              <h6 *ngIf="nationOfUse !== 'Batc'" class="center">Contact Department</h6>
            </strong>
          </div>

          <div *ngIf="employeeList" class="row">

            <div class="col s12 l6" *ngFor="let emp of employeeList">

              <div class="card activator white" [ngStyle]="{'border-color': themeColor}">

                <div class="row col s12">
                  <div class="card-content center col s12 m4">
                    <h6 style="font-family: 'Anton', sans-serif; font-size: 1em; padding: 0;"
                      [routerLink]="['/governance']" class="activator card-title " [ngStyle]="{'color': themeColor}">
                      {{emp.givenName +' ' +
                      emp.lastName}}
                    </h6>
                    <p class="bold-700">{{emp.jobTitle}}</p>
                  </div>

                  <div class="row col s12 m8 center">
                    <h6 class="col s12"> <strong class="teal-text"> Responsibilities : </strong></h6>
                    <div class="col s12" style="text-align: justify;"> <strong> {{emp.description}} </strong></div>
                  </div>
                  <div class="tu-tu">
                    <div class="col s12 center ">
                      <div class="col s4 cont-email" *ngIf="emp.showEmailAdr !== 'Private'">
                        <a [href]="'mailto:' + emp.email" style="cursor: pointer;"><i class="fa fa-envelope-o"></i>
                          <!-- <p>{{emp.email}}</p> -->
                        </a>
                      </div>

                      <div class="col s4 cont-email" *ngIf="emp.showOfficeNo !== 'Private'">
                        <a (click)="showWorkPhone(emp)" style="cursor: pointer;"><i class="fa fa-phone"></i>
                          <!-- <p>{{emp.workPhone}}</p> -->
                        </a>
                      </div>

                      <div class="col s4 cont-phone" [ngStyle]="{'color': themeColor}"
                        *ngIf="emp.showMobileNo !== 'Private'"> <a (click)="showMobile(emp)">
                          <i class="fa fa-mobile"></i>
                          <!-- <p> {{emp.mobilePhone}}</p> -->
                        </a>
                      </div>

                    </div>
                  </div>
                </div>
              </div>

              <div class="row card-panel white col s12" *ngIf="false">
                <div class="row col s12">
                  <div class="section"></div>
                  <ul class="collection">
                    <li class="collection-item">
                      <div>
                        <span> <strong class="teal-text"> First Name : </strong></span>
                        <span class="right black-text"> <strong> {{emp.givenName}} </strong></span>
                      </div>
                    </li>
                    <li class="collection-item">
                      <div>
                        <span> <strong class="teal-text"> Last Name : </strong></span>
                        <span class="right black-text"> <strong> {{emp.lastName}} </strong></span>
                      </div>
                    </li>
                    <li class="collection-item">
                      <div>
                        <span> <strong class="teal-text"> Title : </strong></span>
                        <span class="right blue-text"> <strong> {{emp.jobTitle}} </strong></span>
                      </div>
                    </li>
                  </ul>

                  <div class="card-panel col s12">
                    <div class="row">
                      <h6 class="col s6"> <strong class="teal-text"> Responsibilities : </strong></h6>
                      <div class="col s6"> <strong> {{emp.description}} </strong></div>
                    </div>
                  </div>
                </div>

                <div class="row col s12 hide-on-small-only show-on-medium-and-up">
                  <a href="mailto:{{emp.email}}" class="center col s4" *ngIf="emp.showEmailAdr !== 'Private'"> <span
                      style="cursor: pointer;"><i class=" material-symbols-outlined blue-text">mail</i> <strong
                        class="black-text">Email</strong></span> </a>

                  <a (click)="showWorkPhone(emp)" class="center col s4" *ngIf="emp.showOfficeNo !== 'Private'"><span
                      style="cursor: pointer;"><i class="center material-symbols-outlined teal-text">call</i><strong
                        class="center black-text">Work</strong> </span></a>

                  <a (click)="showMobile(emp)" class="center col s4" *ngIf="emp.showMobileNo !== 'Private'"><span
                      style="cursor: pointer;"> <i
                        class="material-symbols-outlined orange-text">stay_current_portrait</i>
                      <strong class="black-text">Mobile</strong></span> </a>
                </div>


                <div class="row col s12 show-on-small hide-on-med-and-up">
                  <a href="mailto:{{emp.email}}" class="center col s4" *ngIf="emp.showEmailAdr !== 'Private'"> <span
                      style="cursor: pointer;"><i class="material-symbols-outlined blue-text">mail</i> <strong
                        class="black-text">Email</strong></span> </a>

                  <a href="tel:{{formatWorkNumber(emp)}}" class="center col s4"
                    *ngIf="emp.showOfficeNo !== 'Private'"><span style="cursor: pointer;"><i
                        class="center material-symbols-outlined teal-text">call</i><strong
                        class="center black-text">Work</strong>
                    </span></a>

                  <a href="tel:{{formatPhoneNumber(emp.mobilePhone)}}" class="center col s4"
                    *ngIf="emp.showMobileNo !== 'Private'"><span style="cursor: pointer; "> <i
                        class="material-symbols-outlined orange-text">stay_current_portrait</i> <strong
                        class="black-text">Mobile</strong></span> </a>
                </div>

              </div>
            </div>
          </div>
          <div *ngIf="showMsg" class="row col s12 card-panel white darken-2 center p-t-6 p-b-6">
            <div *ngIf="nationOfUSe === 'Batc'">
              <img src="../../assets/img/empty-folder.png" height="150" width="150">
              <h6 class="bold-200"> The Office did not update their contact list please try
                again later!!</h6>
            </div>

            <div *ngIf="nationOfUSe !== 'Batc'" class="center p-t-6 p-b-6">
              <img src="../../assets/img/empty-folder.png" height="150" width="150">
              <h6 class="bold-200"> The department did not update their contact list please try
                again later!!</h6>

            </div>
          </div>


          <!-- <a class="col s12 btn" [ngStyle]="{'background-color': themeColor}" (click)="backClicked()"><i
              class="material-symbols-outlined left">arrow_back_ios</i> Back To Previous Page</a> -->
        </div>

      </div>
      <!-- ========================Work Phone Modal =================== -->

      <div id="workPhoneModal" *ngIf="selectedEmployee" class=" modal valign-wrapper">
        <div class="modal-content">

          <div class=" col s1 right">
            <span class="material-symbols-outlined modal-close">
              close
            </span>
          </div>
          <!-- ======================== have phone number =================== -->
          <div class="row col s12 ">
            <div class="col s4">
              <span class="material-symbols-outlined black-text "
                style="border:2px solid rgb(0, 0, 0); border-radius:50%; padding:2vh; font-size: 5vh;">
                call
              </span>
            </div>
            <a href="tel:{{formatedWorkPhone}}" class="col s8">
              <div class=" bold-400">Work Phone</div>
              <h5 class="black-text bold-400 sub-title-section p-b-2">{{selectedEmployee.workPhone}}</h5>
              <a href="tel:{{formatedWorkPhone}}" [ngStyle]="{'background-color':themeColor}" class="btn">Call</a>
            </a>
          </div>
          <!-- ======================== no have phone number =================== -->
          <!-- <div class="row col s12 center" *ngIf="!workPhone">
            <span class="material-symbols-outlined gray-text " style=" padding:0vh 2vh; font-size:9vh; color: gray;">
              block
            </span>
            <h5 class="gray-text bold-400 sub-title-section p-b-2">No Work Phone</h5>

          </div> -->
        </div>
      </div>

      <!-- ========================Mobile Phone Modal =================== -->
      <div id="mobilePhoneModal" *ngIf="selectedEmployee" class=" modal valign-wrapper">
        <div class="modal-content">

          <div class=" col s1 right">
            <span class="material-symbols-outlined modal-close">
              close
            </span>
          </div>

          <!-- ======================== have phone number =================== -->
          <div class="row col s12" >
            <div class="col s4">
              <span class="material-symbols-outlined "
                style="border:2px solid rgb(0, 0, 0); border-radius:50%; padding:2vh; font-size: 5vh;">
                phone
              </span>
            </div>

            <a href="tel:{{formatedMobilePhone}}" class="col s8">
              <div class="bold-400"> Mobile Phone </div>
              <h5 class="black-text bold-400 sub-title-section p-b-2">{{selectedEmployee.mobilePhone}}</h5>
              <a href="tel:{{formatedMobilePhone}}" [ngStyle]="{'background-color':themeColor}" class="btn">Call</a>
            </a>
          </div>
          <!-- ======================== no  phone number =================== -->

          <!-- <div class="row col s12 center" *ngIf="!mobilePhone">

            <span class="material-symbols-outlined " style=" padding:0vh 2vh; font-size:9vh; color: gray;">
              block
            </span>
            <h5 class="gray-text bold-400 sub-title-section p-b-2">No Mobile Phone</h5>

          </div> -->
        </div>

      </div>

    </div>
  </div>
</div>