<div class="row">
  <div class="col s12 m6">
    <div class="card">
      <div class="center card-image election-title" *ngIf="electionDetail">
        <h5 *ngIf="electionDetail.name">{{electionDetail.name}}</h5>
      </div>
      <div class="card-content" *ngIf="electionDetail">

        <p *ngIf="electionDetail.DateCreated"><strong>Created On:</strong> {{electionDetail.DateCreated}}</p>
        <p *ngIf="electionDetail.createdBy"> <strong>Created by:</strong> {{electionDetail.createdBy}}</p>
        <p *ngIf="electionDetail.startDate"><strong>Start Date</strong>{{electionDetail.startDate}}</p>
        <p *ngIf="electionDetail.endDate"><strong>End Date</strong>{{electionDetail.endDate}}</p>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <button type="button" class="btn col s12 blue" (click)="backClicked()">
    <i class="material-icons left">arrow_back_ios</i> Back To Previous Page
  </button>
</div>
