import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EntitlementModel } from 'src/app/models/entitlementModel';
import { Observable } from 'rxjs';
import { SignatureModel } from 'src/app/models/signatureModel';
import { ApiKeyManagerService } from './apiKeyManager.Service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class EntitlementService {

  url = environment.firstNation.apiUrl;

  constructor(private httpClient: HttpClient, private keyManager: ApiKeyManagerService) { }

getEntitlement(id: number): Observable<EntitlementModel[]> {
  const headers = new HttpHeaders({SOCIALAPIKEY: this.keyManager.getKey()});
  return this.httpClient.get<EntitlementModel[]>(
    this.url + 'api/entitlement/GetEntitlementByMemberID/' + id, {headers});
}

getCurrentEntitlement(id: number): Observable<EntitlementModel[]> {
  const headers = new HttpHeaders().set('SOCIALAPIKEY', this.keyManager.getKey());
  return this.httpClient.get<EntitlementModel[]>(this.url + 'api/entitlement/GetCurrentEntitlement/' + id, {headers});
}

getCurrentPaidEntitlement(id: number): Observable<EntitlementModel[]> {
  const headers = new HttpHeaders().set('SOCIALAPIKEY', this.keyManager.getKey());
  return this.httpClient.get<EntitlementModel[]>(this.url + 'api/entitlement/GetCurrentPaidEntitlement/' + id, {headers});
}

getEntitlementByID(id: string): Observable<EntitlementModel[]> {
  const headers = new HttpHeaders().set('SOCIALAPIKEY', this.keyManager.getKey());
  return this.httpClient.get<EntitlementModel[]>(this.url + 'api/entitlement/GetEntitlementByMonthlyRequirementID/' + id, {headers});
}

getEntitlementByBDNo(id: string): Observable<EntitlementModel[]> {
  const model = {bdno: id};
  const headers = new HttpHeaders({SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8'});
  return this.httpClient.post<any>(this.url + 'api/entitlement/GetEntitlementByBDNo' , JSON.stringify(model), {headers});
}

postSignature(model: SignatureModel): any {
  const headers = new HttpHeaders({SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8'});
  return this.httpClient.post<any>(this.url + 'api/entitlement/PostSignature', JSON.stringify(model), {headers});
}

PostMultipleSignature(model: SignatureModel): any {
  const headers = new HttpHeaders({SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8'});
  return this.httpClient.post<any>(this.url + 'api/entitlement/PostMultipleSignature', JSON.stringify(model), {headers});
}

PostSpouseMultipleSignature(model: SignatureModel): any {
  const headers = new HttpHeaders({SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8'});
  return this.httpClient.post<any>(this.url + 'api/entitlement/PostSpouseMultipleSignature', JSON.stringify(model), {headers});
}

postSpouseSignature(model: SignatureModel): any {
  const headers = new HttpHeaders({SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8'});
  return this.httpClient.post<any>(this.url + 'api/entitlement/PostSpouseSignature', JSON.stringify(model), {headers});
}

}

