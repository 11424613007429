import { Injectable } from '@angular/core';
import { AngularFirestoreDocument, AngularFirestoreCollection, AngularFirestore } from '@angular/fire/compat/firestore';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AboutusService {

  constructor(private db: AngularFirestore) { }

  aboutUsSectionListRef: AngularFirestoreCollection<any>;
  aboutUsSectionRef: AngularFirestoreDocument<any>;
  private sortMode = new BehaviorSubject<string>('newest');

  newAboutUsSection(model: any)  {
    model.id = this.db.createId();
    return this.db.collection('AboutUs').doc(model.id).set(model);
  }

  UpdateAboutUsSection(model: any)  {
    return this.db.collection('AboutUs').doc(model.id).update(model);
  }

  getSectionByID(id: string): AngularFirestoreDocument<any> {
    this.aboutUsSectionRef = this.db.doc('AboutUs/' + id);
    return this.aboutUsSectionRef;
  }

  getSectionsList(): AngularFirestoreCollection<any[]> {
    this.aboutUsSectionListRef = this.db.collection('AboutUs');
    return this.aboutUsSectionListRef;
  }

  DeleteContent(model: any) {
    return this.db.collection('AboutUs').doc(model.id).delete();
  }

  SortContentOnServer(model: any[]) {
    model.forEach(element => {
      this.db.collection('AboutUs').doc(element.id).update(element);
    });
  }

  getLimitedContentList(limit: number): AngularFirestoreCollection<any[]> {
    this.aboutUsSectionListRef = this.db.collection('AboutUs', ref => ref.orderBy('datePublished', 'desc').limit(limit));
    return this.aboutUsSectionListRef;
  }

  getPublishedList(): AngularFirestoreCollection<any[]> {
    this.aboutUsSectionListRef = this.db.collection('AboutUs', ref => ref.where('published', '==', true));
    return this.aboutUsSectionListRef;
  }
}
