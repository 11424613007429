<div class="row">

  <!-- =========== Left Navigation ============= -->
  <div class="col l1" *ngIf="user">
    <app-side-menus [user]="user"></app-side-menus>
  </div>

  <div class="col s12 m12 l10">
    <div class="body-h">
      <div class="header-setting b-m-2 ">
        <div class="col l8 m9 s12">
          <h4 *ngIf="!editName">
            <span *ngIf="sectionName">{{sectionName}}</span>
            <span *ngIf="!sectionName">Survey / Polls List</span>
            <i class="material-icons edit-name " [ngStyle]="{'color': themeColor}" (click)="editNameClicked()">edit</i>
          </h4>
          <div class="input-field" *ngIf="editName">
            <input type="text" [(ngModel)]="sectionName" class="left col s6 validate" autocomplete="off" />
            <button type="button" class="btn ll-m-5" [ngStyle]="{'background-color': themeColor}"
              (click)="saveName()">Save</button>
          </div>
          <p class="col s12 bold-100 sub-title-section3">{{description}}</p>
        </div>

        <div class="col l4  m4 s12 p-m-2">
          <a [routerLink]="['/admin/polls-surveys']" class="add-button btn white center-align p-l-2"
            [ngStyle]="{'color': themeColor}"><i class="material-icons-outlined">add</i>Add&nbsp;
            <span *ngIf="sectionName">{{sectionName}}</span>
            <span *ngIf="!sectionName">Survey / Polls List</span></a>
        </div>
      </div>

      <main class="StickyContent">

        <app-firebase-loading *ngIf="showLoader"></app-firebase-loading>
        <div class="card-info">
          <div class="row card-panel white col s12" *ngIf="!model && !showLoader">
            <p class="center"> <strong class="red-text"> <i class="material-icons">warning</i> 0 Survey / Poll Found In
                the
                Database</strong> </p>
          </div>
        </div>

        <div class="col s12 l12" *ngIf="model">
          <div class="col s12 m5 l5" *ngFor="let item of model; let i=index hoverable">
            <div class="card white b-n-1" style="max-height:250px; min-height:250px;"
              [ngStyle]="{'border-color': themeColor}" [routerLink]="['/admin/edit-survey', item.id]">
              <div class="card-info">
                <!-- <span>
                  <div class="row" style=" margin: 0px; padding: 0px; text-align: right;">
                    <div class="col s10">
                      <div class="responsive-img image-none" *ngIf="item.name">
                        <div class="avatar-circle hide-on-med-and-up">
                          <span class="initials">{{getInitials(item.name)}}</span>
                        </div>
                        <div class="avatar-circle2 hide-on-small-only ">
                          <span class="initials2">{{getInitials(item.name)}}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </span> -->
                <span class="title-section3 bold-600 p-m-0" [innerHTML]="item.name"></span>
                <div class="description-section middle-body-snippet bold-100 p-m-0">
                  <ul class="row collection">
                    <li class="row collection-item">
                      <div class="col s4"> <strong class="black-text">Start Date</strong></div>
                      <div class="col s8"> <strong class="right"> {{item.startDate}} </strong></div>
                    </li>
                    <li class="row collection-item">
                      <div class="col s4"> <strong class="black-text">End Date</strong></div>
                      <div class="col s8"> <strong class="right red-text"> {{item.endDate}} </strong></div>
                    </li>
                  </ul>
                </div>
                <div class="row">
                  <div class="col s12 m12 l6" style="margin-top: 5px; margin-bottom: 5px;">
                    <a class="center col btn-flat s12 blue lighten-4" [routerLink]="['/admin/survey-result', item.id]">
                      <span style="cursor: pointer;"><i class="material-icons black-text"
                          style="margin-right: 7px;">poll</i>
                        <strong class="black-text btn-text">Live Results</strong></span></a>
                  </div>
                  <div class="col s12 m12 l6" style="margin-top: 5px; margin-bottom: 5px;">
                    <a class="center btn-flat col s12 green lighten-4"
                      [routerLink]="['/admin/edit-survey', item.id]"><span style="cursor: pointer;"><i
                          class="center material-icons black-text" style="margin-right: 7px;">edit</i><strong
                          class="center black-text btn-text">Edit
                          Survey</strong>
                      </span></a>
                  </div>
                </div>
                <!-- <div class="description-section middle-body-snippet bold-100 p-m-0">
                  <p [innerHTML]="department.mandate"></p>
                </div> -->
              </div>
            </div>
          </div>
        </div>

      </main>
    </div>
  </div>
</div>