<div *ngIf="user" class="p-m-2">

  <h5 class="center show-on-medium-and-up hide-on-small-only" *ngIf="depName">
    <strong class="blue-text">Welcome To {{depName}} Employee Account</strong>
  </h5>
  <h6 class="center hide-on-med-and-up show-on-small" *ngIf="depName">
    <strong class="blue-text">Welcome To {{depName}} Employee Account</strong>
  </h6>

  <div class="row p-m-2" *ngIf="accountVerified">
    <div class="col s6 m4 l3">
      <a [routerLink]="['/employee/account']">
        <div class="card teal lighten-1 hoverable" style="height: 95px;">
          <div class="card-content">
            <div class="row center">
              <i class="material-icons white-text">settings</i>
              <br />
              <strong class="white-text">My Account</strong>
            </div>
          </div>
        </div>
      </a>
    </div>

    <div class="col s6 m4 l3">
      <a [routerLink]="['/employee/inbox']">
        <div class="card orange darken-3 hoverable" style="height: 95px;">
          <div class="card-content">
            <div class="row center">
              <i class="material-icons white-text">mail</i>
              <br />
              <strong class="white-text">My Inbox</strong>
            </div>
          </div>
        </div>
      </a>
    </div>

    <div class="col s6 m4 l3">
      <a [routerLink]="['/employee/department-info']">
        <div class="card green darken-2 hoverable " style="height: 95px;">
          <div class="card-content">
            <div class="row center">
              <i class="material-icons white-text">info</i><br />
              <strong class="white-text">Information</strong>
            </div>
          </div>
        </div>
      </a>
    </div>

    <div class="col s6 m4 l3">
      <a [routerLink]="['/employee/department-news-list']">
        <div class="card blue darken-2 hoverable" style="height: 95px;">
          <div class="card-content">
            <div class="row center">
              <i class="material-icons white-text">notifications</i><br />
              <strong class="white-text">News / Events</strong>
            </div>
          </div>
        </div>
      </a>
    </div>

    <div class="col s6 m4 l3">
      <a [routerLink]="['/employee/sub-department']">
        <div class="card cyan darken-2 hoverable " style="height: 95px;">
          <div class="card-content">
            <div class="row center">
              <i class="material-icons white-text">account_tree</i><br />
              <strong class="white-text">Sub Departments</strong>
            </div>
          </div>
        </div>
      </a>
    </div>

    <div class="col s6 m4 l3">
      <a [routerLink]="['/employee/employee-list']">
        <div class="card pink lighten-1 hoverable " style="height: 95px;">
          <div class="card-content">
            <div class="row center">
              <i class="material-icons white-text">group</i><br />
              <strong class="white-text">Employees</strong>
            </div>
          </div>
        </div>
      </a>
    </div>

    <div class="col s6 m4 l3" *ngIf="user.isManager">
      <a [routerLink]="['/employee/reg-request']">
        <div class="card purple lighten-1 hoverable " style="height: 95px;">
          <div class="card-content">
            <div class="row center">
              <i class="material-icons white-text">person_add</i><br />
              <strong class="white-text">Registration Request</strong>
            </div>
          </div>
        </div>
      </a>
    </div>

    <a class="col s6 m4 l3 modal-trigger" href="#switchModal" *ngIf="user.isMember">
      <div class="card black lighten-2 hoverable" style="height: 95px;">
        <div class="card-content">
          <div class="row center">
            <i class="material-icons white-text">change_circle</i><br />
            <strong class="white-text">Switch Account</strong>
          </div>
        </div>
      </div>
    </a>

    <div class="col s6 m4 l3">
      <a (click)="signOut()">
        <div class="card red darken-2 hoverable" style="height: 95px;">
          <div class="card-content">
            <div class="row center">
              <i class="material-icons white-text">lock</i><br />
              <strong class="white-text">Logout</strong>
            </div>
          </div>
        </div>
      </a>
    </div>
  </div>

  <div *ngIf="!accountVerified">
    <h6 class="center"> <strong>Your Department Manager Will Send You A Verification Code To Complete Your
        Registration.</strong></h6>
    <br>
    <div class="row card-panel white">
      <form [formGroup]="verificationForm" (ngSubmit)="verify(verificationForm.value)">
        <p>Please Enter The Verification Code Here</p>
        <div class="input-field col s12 l12">
          <i class="material-icons prefix pink-text">lock</i>
          <input id="verificationCode" type="text" formControlName='verificationCode' required class="validate"
            autocomplete="off" />
          <label for="verificationCode">Verification Code</label>
          <div *ngIf="(verificationCode.dirty || verificationCode.touched) && verificationCode.invalid">
            <small *ngIf="verificationCode.errors.required" class="red-text"> The Verification Code is required.
            </small>
          </div>
        </div>

        <button type="submit" [disabled]="!verificationCode.valid" [ngClass]="'col s12 btn waves-effect ' + themeColor">
          <i class="material-icons right">navigate_next</i> Complete Registration
        </button>
        <button type="button" style="margin-top: 15px;" (click)="signOut()" class="col s12 btn waves-effect red">
          <i class="material-icons right">lock</i> Log Out
        </button>
      </form>
    </div>
  </div>
</div>

<div id="switchModal" class="modal">
  <div class="modal-content">
    <h6><strong> Switch Account </strong></h6>
    <p>Are You Sure You Want To Switch to Your Member Account ?
    </p>
  </div>
  <div class="modal-footer">
    <a class="modal-close  btn-flat">Close</a>
    <a class="modal-close green white-text  btn-flat" (click)="switchAccount()">Switch</a>
  </div>
</div>