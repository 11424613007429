<div class=" body-h container-pi">
  <div class="header-setting b-m-2">
    <!-- <h4 class="">Case Plan</h4> -->

    <h4 class="title-section1 title-member icon-texts"> <span class="material-symbols-outlined back-icon p-r-1"
      (click)=" backClicked()">
      arrow_circle_left
  </span>Case Plan</h4>
  </div>

  <main class="StickyContent white row">
    <div class="col s12 l11">
      <form *ngIf="step == 1" class="row col s12" [formGroup]="section1Form"
        (ngSubmit)="submitSection1(section1Form.controls.ExperienceFB.value)">

        <section></section>
        <div class="row col s12 card white">
          <div class="col s12 card-content">
            <h6 class="center"><strong> My Work Experience / Training </strong> </h6>
            <br>

            <div class="row" *ngIf="hasRowsOfEmp && sqldb">
              <a class="" *ngFor="let exp of EmpModel"
                [routerLink]="['/member/member-main-home/profile/edit-employment',exp.ID]">
                <div class="card-panel white center">
                  <strong><i class="material-icons left brown-text">work</i> <span class="center">{{ exp.Name
                      }}</span> </strong>
                </div>
              </a>
            </div>

            <div class="row" *ngIf="hasRowsOfEmp && !sqldb">
              <a class="" *ngFor="let exp of EmpModel"
                [routerLink]="['/member/member-main-home/profile/edit-employment',exp.id]">
                <div class="card-panel white center">
                  <strong><i class="material-icons left brown-text">work</i> <span class="center">{{ exp.Name
                      }}</span> </strong>
                </div>
              </a>
            </div>

            <div class="section"></div>
            <a [routerLink]="['/member/member-main-home/profile/addemployment']" class='col s12 btn waves-effect green'
              style="margin-bottom: 15px;">
              <i class="material-icons right">add_circle</i> Add New Work Experience
            </a>
          </div>
        </div>

        <div class="row col s12 card white">
          <div class="col s12 card-content">
            <h6 class="center"><strong> My Education </strong> </h6>
            <br>
            <div class="row" *ngIf="hasRowsOfEducation">
              <div class="card-panel white center" *ngFor="let edu of modelOfEducation">
                <a [routerLink]="['/member/member-main-home/profile/edit-education',edu.id]" *ngIf="!sqldb"><strong><i
                      class="material-icons left black-text">import_contacts</i> <span class="center">{{ edu.details
                      }}</span> </strong></a>
                <a [routerLink]="['/member/member-main-home/profile/edit-education',edu.ID]" *ngIf="sqldb"><strong><i
                      class="material-icons left black-text">import_contacts</i> <span class="center">{{ edu.details
                      }}</span> </strong></a>
              </div>
            </div>
            <div class="section"></div>
            <a [routerLink]="['/member/member-main-home/profile/add-education']" class='col s12 btn waves-effect green'
              style="margin-bottom: 15px;">
              <i class="material-icons right">add_circle</i> Add New Education
            </a>
          </div>
        </div>

        <div class="row col s12 card white">
          <div class="col s12 card-content">
            <h6 class="center"><strong> My Course / Certificate </strong> </h6>
            <br>
            <div class="row" *ngIf="hasRowsOfCertificate">
              <div class="card-panel white center" *ngFor="let edu of modelOfCertificate">
                <a [routerLink]="['/member/member-main-home/profile/edit-certificate',edu.id]" *ngIf="!sqldb"><strong><i
                      class="material-icons left black-text">import_contacts</i> <span class="center">{{ edu.course
                      }}</span> </strong></a>
                <a [routerLink]="['/member/member-main-home/profile/edit-certificate',edu.ID]" *ngIf="sqldb"><strong><i
                      class="material-icons left black-text">import_contacts</i> <span class="center">{{ edu.course
                      }}</span> </strong></a>
              </div>
            </div>

            <div class="section"></div>
            <a [routerLink]="['/member/member-main-home/profile/add-certificate']"
              class='col s12 btn waves-effect green' style="margin-bottom: 15px;">
              <i class="material-icons right">add_circle</i> Add New Certificate
            </a>
          </div>
        </div>

        <div formGroupName="ExperienceFB">
          <div *ngIf="emptyItem" class="row col s12 card white">
            <h6 class="center"><strong> My Experience/Skills </strong> </h6>
            <br>
            <div class="center-align">
              <span>No Experience Item Added.</span>
              <i class="col s12 material-icons medium">inventory</i>
            </div>
          </div>

          <div class="section"></div>

          <div *ngIf="displayItem" class="row col s12 card white">
            <h6 class="center"><strong> My Experience/Skills </strong> </h6>
            <br>

            <div formArrayName="experienceItems" *ngFor="let e of experienceControls.controls; let i = index;">
              <ul class="collapsible">
                <li>
                  <div class="row collapsible-header"><i class="material-icons prefix red-text">push_pin</i>
                    <span class="col s5"><strong>Experience: </strong>
                      {{experienceControls.controls[i].controls.experience.value }}
                      {{experienceControls.controls[i].controls.other_dec.value }}</span>
                    <span class="col s2">
                      <i class="material-icons red-text small" (click)="onDeleteItem(i)"
                        style="cursor: pointer;">delete</i>
                    </span>
                  </div>

                </li>
              </ul>
            </div>
          </div>

          <div class="section"></div>

          <div *ngIf="addItem" class="row col s12 card white">
            <div class="col s12 card-content">

              <div class="section"></div>
              <span class="card-title center-align col s12">Add Experience/Skills</span>

              <div class="input-field col s12 ">
                <select formControlName="experience" class="validate" id="experience"
                  (change)="ExperienceChange($event.target.value)">
                  <option *ngFor="let b of ExperienceList" [value]="b">{{b}}</option>
                </select>
                <label for="experience"><span>Experience/Skills:</span></label>
                <div *ngIf="(experience.dirty || experience.touched) && experience.invalid"> <small class="red-text">
                    The Experience/Skills is required.</small>
                </div>
              </div>

              <div *ngIf="OtherExperienceSelected" class="input-field  col s12">
                <input formControlName="other_dec" id="other_dec" type="text" maxlength="100" autocomplete="off"
                  class="capitalize validate">
                <label for="other_dec"> Other : </label>
              </div>

              <div class="section"></div>

              <button class="col s12 btn green" type="button" style="margin-bottom: 15px;"
                (click)="addFBGItem(section1Form.controls.ExperienceFB.value)"
                [disabled]="!section1Form.controls.ExperienceFB.valid">Add Experience</button>
            </div>
          </div>
        </div>

        <button class="col s12 m5 l3 btn left waves-effect blue" type="button" [disabled]="step==1"
          style="margin-bottom: 15px" (click)="onBack()"><i class="material-icons left">arrow_back</i>Back
        </button>

        <button class="col s12 m5 l3 btn right waves-effect blue" type="submit" style="margin-bottom: 15px"
          title="Save">Next <i class="material-icons right">arrow_forward</i></button>
      </form>

      <form *ngIf="step == 2" class="row col s12" [formGroup]="section2Form"
        (ngSubmit)="submitSection2(section2Form.controls.BarrierFB.value)">

        <section></section>
        <div formGroupName="BarrierFB">
          <div *ngIf="emptyBarrier" class="row col s12 card white">
            <h6 class="center"><strong> Barriers to Employement </strong> </h6>
            <br>
            <div class="center-align">
              <span>No Barriers Item Added.</span>
              <i class="col s12 material-icons medium">inventory</i>
            </div>
          </div>

          <div class="section"></div>

          <div *ngIf="displayBarrier" class="row col s12 card white">
            <h6 class="center"><strong> Barriers to Employement </strong> </h6>
            <br>

            <div formArrayName="barrierItems" *ngFor="let e of barrierControls.controls; let i = index;">
              <ul class="collapsible">
                <li>
                  <div class="row collapsible-header"><i class="material-icons prefix red-text">push_pin</i>
                    <span class="col s10"><strong>Barrier: </strong>
                      {{barrierControls.controls[i].controls.barrier.value }}
                      {{barrierControls.controls[i].controls.other.value }}</span>
                    <span class="col s2">
                      <i class="material-icons red-text small" (click)="onDeleteBarrier(i)"
                        style="cursor: pointer;">delete</i>
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div class="section"></div>

          <div *ngIf="addBarrier" class="row col s12 card white">
            <div class="col s12 card-content">
              <span class="card-title center-align col s12">Add Barrier to Employment</span>
              <div class="section"></div>

              <div class="input-field col s12 ">
                <select formControlName="barrier" class="validate" id="barrier"
                  (change)="BarrierChange($event.target.value)">
                  <option *ngFor="let b of BarriersList" [value]="b">{{b}}</option>
                </select>
                <label for="barrier"><span>Barrier to Employment:</span></label>
                <div *ngIf="(barrier.dirty || barrier.touched) && barrier.invalid"> <small class="red-text"> The
                    Barrier is required.</small>
                </div>
              </div>

              <div *ngIf="OtherSelected" class="input-field  col s12">
                <input formControlName="other" id="other" type="text" maxlength="100" autocomplete="off"
                  class="capitalize validate">
                <label for="other"> Other : </label>
              </div>

              <div class="section"></div>

              <button class="col s12 btn green" type="button" style="margin-bottom: 15px;"
                (click)="addBarrierItem(section2Form.controls.BarrierFB.value)"
                [disabled]="!section2Form.controls.BarrierFB.valid">Add Barrier</button>
            </div>
          </div>

          <div *ngIf="addBarrier" class="row col s12 card white">
            <div class="col s12 card-content">
              <div class="row col s12">Are you willing to address any of the barriers you have indicated above? </div>
              <div class="row input-field col s12">
                <div class="row col s12">
                  <label class="col s12 m6">
                    <input type="radio" value="yes" formControlName="address_barrier" /> <span>Yes</span>
                  </label>
                  <label class="col s12 m6">
                    <input type="radio" value="no" formControlName="address_barrier" /> <span>No</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>

        <button class="col s12 m5 l3 btn left waves-effect blue" type="button" [disabled]="step==1"
          style="margin-bottom: 15px" (click)="onBack()"><i class="material-icons left">arrow_back</i>Back
        </button>

        <button class="col s12 m5 l3 btn right waves-effect blue" type="submit" style="margin-bottom: 15px"
          title="Save">Next <i class="material-icons right">arrow_forward</i></button>
      </form>

      <form *ngIf="step == 3" class="row col s12" [formGroup]="section3Form"
        (ngSubmit)="submitSection3(section3Form.controls.InterestFB.value)">

        <section></section>

        <div formGroupName="InterestFB">
          <div *ngIf="emptyInterest" class="row col s12 card white">
            <h6 class="center"><strong> Interests to Employement </strong> </h6>
            <br>
            <div class="center-align">
              <span>No Interests Item Added.</span>
              <i class="col s12 material-icons medium">inventory</i>
            </div>
          </div>

          <div class="section"></div>

          <div *ngIf="displayInterest" class="row col s12 card white">
            <h6 class="center"><strong> Interests to Employement </strong> </h6>
            <br>
            <div formArrayName="interestItems" *ngFor="let e of interestControls.controls; let i = index;">
              <ul class="collapsible">
                <li>
                  <div class="row collapsible-header"><i class="material-icons prefix red-text">push_pin</i>
                    <span class="col s10"><strong>Career Interest: </strong>
                      {{interestControls.controls[i].controls.job.value }}</span>

                    <span class="col s2">
                      <i class="material-icons red-text small" (click)="onDeleteInterest(i)"
                        style="cursor: pointer;">delete</i>
                    </span>
                  </div>
                </li>
              </ul>
            </div>
          </div>

          <div class="section"></div>

          <div *ngIf="addInterest" class="row col s12 card white">
            <div class="col s12 card-content">
              <span class="card-title center-align col s12">Add Career Interest </span>
              <div class="section"></div>

              <div class="input-field col s12 ">
                <select formControlName="job_field" class="validate" id="job_field"
                  (change)="CareerInterestChange($event.target.value)">
                  <option *ngFor="let b of CareerList" [value]="b">{{b}}</option>
                </select>
                <label for="job_field"><span>Career Category:</span></label>
              </div>

              <div *ngIf="BusinessSelected" class="input-field col s12 ">
                <select formControlName="job" class="validate" id="job">
                  <option *ngFor="let b of businessJobList" [value]="b">{{b}}</option>
                </select>
                <label for="job"><span>Job:</span></label>
              </div>

              <div *ngIf="ComputersSelected" class="input-field col s12 ">
                <select formControlName="job" class="validate" id="job">
                  <option *ngFor="let b of computerJobList" [value]="b">{{b}}</option>
                </select>
                <label for="job"><span>Job:</span></label>
              </div>

              <div *ngIf="ConstructionSelected" class="input-field col s12 ">
                <select formControlName="job" class="validate" id="job">
                  <option *ngFor="let b of constructionJobList" [value]="b">{{b}}</option>
                </select>
                <label for="job"><span>Job:</span></label>
              </div>

              <div *ngIf="EducationSelected" class="input-field col s12 ">
                <select formControlName="job" class="validate" id="job">
                  <option *ngFor="let b of educationJobList" [value]="b">{{b}}</option>
                </select>
                <label for="job"><span>Job:</span></label>
              </div>

              <div *ngIf="EngineeringSelected" class="input-field col s12 ">
                <select formControlName="job" class="validate" id="job">
                  <option *ngFor="let b of engineeringJobList" [value]="b">{{b}}</option>
                </select>
                <label for="job"><span>Job:</span></label>
              </div>

              <div *ngIf="HealthSelected" class="input-field col s12 ">
                <select formControlName="job" class="validate" id="job">
                  <option *ngFor="let b of healthJobList" [value]="b">{{b}}</option>
                </select>
                <label for="job"><span>Job:</span></label>
              </div>

              <div *ngIf="HospitalitySelected" class="input-field col s12 ">
                <select formControlName="job" class="validate" id="job">
                  <option *ngFor="let b of hospitalityJobList" [value]="b">{{b}}</option>
                </select>
                <label for="job"><span>Job:</span></label>
              </div>

              <div *ngIf="LawSelected" class="input-field col s12 ">
                <select formControlName="job" class="validate" id="job">
                  <option *ngFor="let b of lawJobList" [value]="b">{{b}}</option>
                </select>
                <label for="job"><span>Job:</span></label>
              </div>

              <div *ngIf="TransportationSelected" class="input-field col s12 ">
                <select formControlName="job" class="validate" id="job">
                  <option *ngFor="let b of transportationJobList" [value]="b">{{b}}</option>
                </select>
                <label for="job"><span>Job:</span></label>
              </div>

              <div *ngIf="OtherCareerSelected" class="input-field  col s12">
                <input formControlName="job" id="job" type="text" maxlength="100" autocomplete="off"
                  class="capitalize validate">
                <label for="job"> Other Job: </label>
              </div>

              <div class="section"></div>

              <button class="col s12 btn green" type="button" style="margin-bottom: 15px;"
                [disabled]="!section3Form.controls.InterestFB.valid"
                (click)="addCareerInterestItem(section3Form.controls.InterestFB.value)">Add Career Interest</button>
            </div>
          </div>
        </div>

        <button class="col s12 m5 l3 btn left waves-effect blue" type="button" [disabled]="step==1"
          style="margin-bottom: 15px" (click)="onBack()"><i class="material-icons left">arrow_back</i>Back
        </button>

        <button class="col s12 m5 l3 btn right waves-effect blue" type="submit" style="margin-bottom: 15px"
          title="Save">Next <i class="material-icons right">arrow_forward</i></button>
      </form>

      <form *ngIf="step == 4" class="row col s12" [formGroup]="sectionForm"
        (ngSubmit)="submitCasePlan(sectionForm.value)">
        <div class="row col s12 card white">
          <div class="col s12 card-content">
            <div class="section"></div>

            <div class="input-field col s12 ">
              <select formControlName="edu_level" class="validate" id="edu_level">
                <option *ngFor="let b of EduLevelList" [value]="b">{{b}}</option>
              </select>
              <label for="edu_level"><span>Education Level:</span></label>
              <div *ngIf="(edu_level.dirty || edu_level.touched) && edu_level.invalid"> <small class="red-text"> The
                  Education Level is required.</small>
              </div>
            </div>

            <div class="row col s12">Have you contributed to Employment Insurance Benefits in the past 5 years? </div>
            <div class="row input-field col s12">
              <div class="row col s12">
                <label class="col s12 m6">
                  <input type="radio" value="yes" formControlName="EI_Contribute" (change)="onEIContributeYes()" />
                  <span>Yes</span>
                </label>
                <label class="col s12 m6">
                  <input type="radio" value="no" formControlName="EI_Contribute" (change)="onEIContributeNo()" />
                  <span>No</span>
                </label>
              </div>
            </div>

            <div *ngIf="HasEIContribute" class="row col s12">If Yes, have you applied for Employment Insurance
              Benefits? </div>
            <div *ngIf="HasEIContribute" class="row input-field col s12">
              <div class="row col s12">
                <label class="col s12 m6">
                  <input type="radio" value="yes" formControlName="EI_Claim" /> <span>Yes</span>
                </label>
                <label class="col s12 m6">
                  <input type="radio" value="no" formControlName="EI_Claim" /> <span>No</span>
                </label>
              </div>
            </div>

            <div class="row input-field col s12"><span class="black-text"><strong>Please indicate what you might need
                  to be ready for work? </strong></span>
              <div class="md-form mb-4 pink-textarea active-pink-textarea">
                <textarea id="Comment1" formControlName="Comment1" class="md-textarea form-control" rows="5"
                  maxlength="240"></textarea>
              </div>
            </div>

            <div class="row input-field col s12"><span class="black-text"><strong>What will you do to look for work
                  and/or education?</strong></span>
              <div class="md-form mb-4 pink-textarea active-pink-textarea">
                <textarea id="Comment2" formControlName="Comment2" class="md-textarea form-control" rows="5"
                  maxlength="240"></textarea>
              </div>
            </div>

            <div class="row input-field col s12"><span class="black-text"><strong>Please indicate if you require any
                  additional supports and/or information below:</strong></span>
              <div class="md-form mb-4 pink-textarea active-pink-textarea">
                <textarea id="Comment3" formControlName="Comment3" class="md-textarea form-control" rows="5"
                  maxlength="240"></textarea>
              </div>
            </div>
          </div>
        </div>

        <button class="col s12 m5 l3 btn left waves-effect blue" type="button" [disabled]="step==1"
          style="margin-bottom: 15px" (click)="onBack()"><i class="material-icons left">arrow_back</i>Back
        </button>

        <button type="button" [disabled]="!sectionForm.valid"
          class="col s12 m5 l3 offset-m2 btn right waves-effect blue  modal-trigger" data-target="modal1"
          style="margin-bottom: 15px;">Save Case Plan <i class="material-icons right">send</i></button>

        <div id="modal1" class="modal">
          <div class="modal-content">
            <h5>Case Plan</h5>
            <p> You can review or update your Caseplan anytime you want or if there is any
              changes in your Circumstances</p>
            <p>Are you sure you want to save Case Plan ?</p>
          </div>
          <div class="modal-footer">
            <button type="submit" class="btn waves-effect green" style="margin-right: 15px;">
              <i class=" material-icons right white-text">cloud_upload</i> Save
            </button>
            <a class="modal-close amber darken-4 white-text btn-flat white-text"> <i
                class="material-icons right">close</i>Cancel</a>
          </div>
        </div>
      </form>
    </div>
  </main>
</div>