import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { JobOfferService } from 'src/app/services/firebase/joboffer.service';
import { AngularFirestore} from '@angular/fire/compat/firestore';
import { AuthService } from 'src/app/services/firebase/auth.service';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-job-posting',
  templateUrl: './job-posting.component.html',
  styleUrls: ['./job-posting.component.css']
})

export class JobPostingComponent implements OnInit {

  @Input() model: any;
  @Input() call_from: string;
  @Input() actionType: string;
 
  @Output() jobSaved: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() jobUpdated: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() jobArchived: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() jobUnArchived: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() jobDeleted: EventEmitter<boolean> = new EventEmitter<boolean>();

  themeColor = environment.appTheme.adminThemeColor;
  fontColor = environment.appTheme.adminFontColor;
  postJobForm: UntypedFormGroup;
  ftime = true;
  ptime = false;
  form_validefields : any;
  wage_period_text: string = '';
  selectedWagePeriod : any;
  
  default_employer: string='';
  officer_name: string='';

  public phoneNumberMask = ['(', /[0-9]/, /[0-9]/, /[0-9]/, ')' , ' ' , /[0-9]/, /[0-9]/, /[0-9]/, ' ' , '-' , ' ' ,/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];

  step1 = true;  
  step2 = false;
  step3 = false;

  constructor(private location: Location, private fb: UntypedFormBuilder, private jobOfferService: JobOfferService,
     private db: AngularFirestore, private authService: AuthService) { 
  }

  ngOnInit() {

    if (this.call_from === 'employer') {
       if (localStorage.getItem('company')) {this.default_employer = localStorage.getItem('company'); } 
    }
  
    if (this.call_from =='admin') {
        this.officer_name = localStorage.getItem('userFirstName') + ' ' + localStorage.getItem('userLastName')
    }
   
    this.form_validefields = {
      title: ['', [Validators.required]],
      description: ['', [Validators.required]],
      skills: ['', [Validators.required]],
      duration:  ['Full Time', [Validators.required]],
      officer: ['', []],
      email: ['', [Validators.email]],
      phone: ['', []],
      employer: ['', [Validators.required]],   
      start_date: ['', [Validators.required]],
      expiry_date: ['', []],
      deleted: ['', []],
      reason: ['', []]
    };

    this.postJobForm = this.fb.group(this.form_validefields);
    if (this.actionType === 'Edit') {  this.patchFormValue() }

    setTimeout(() => {
      if (this.actionType === 'Post') {
        $('select').formSelect();
        $('.modal').modal();
        $('.dropdown-trigger').dropdown();

        $('.datepicker1').datepicker({
          format: 'dd/mmm/yyyy',  
          defaultDate: new Date(),   
          setDefaultDate: new Date(),     
          yearRange: 1,
          minDate: new Date(new Date().getFullYear(), 0, 1),    
          maxDate: new Date(new Date().getFullYear() + 1, 12, 31),
        });
      }
      if (this.actionType === 'Edit') {
        $('select').formSelect();
        $('.modal').modal();
        $('.dropdown-trigger').dropdown();

        $('.datepicker1').datepicker({
          format: 'dd/mmm/yyyy',  
          defaultDate: new Date(),   
          //setDefaultDate: new Date(),     
          yearRange: 1,
          minDate: new Date(new Date().getFullYear(), 0, 1),    
          maxDate: new Date(new Date().getFullYear() + 1, 12, 31),
        });
      }

      const selectedDate = new Date();
      selectedDate.setDate(selectedDate.getDate() + 30); 
     if (this.actionType === 'Post') {
         
        $('.datepicker2').datepicker({
          format: 'dd/mmm/yyyy',           
          defaultDate: selectedDate,   
          setDefaultDate: selectedDate,    
          yearRange: 5,
          minDate: new Date(),     
          maxDate: new Date(new Date().getFullYear() + 5, 12, 31),
        });
      }

      if (this.actionType === 'Edit') {  
        $('.datepicker2').datepicker({
          format: 'dd/mmm/yyyy',   
          defaultDate: selectedDate,         
          yearRange: 5,
          minDate: new Date(),      
          maxDate: new Date(new Date().getFullYear() + 5, 12, 31),
        });
      }

      M.updateTextFields(); //////
    }, 25);
    
  }

  patchFormValue() {
    this.postJobForm.patchValue({ title: this.model.title });
    this.postJobForm.patchValue({ description: this.model.description });
    this.postJobForm.patchValue({ skills: this.model.skills });
    this.postJobForm.patchValue({ officer: this.model.officer });
    this.postJobForm.patchValue({ email: this.model.email});
    this.postJobForm.patchValue({ phone: this.model.phone });
    this.postJobForm.patchValue({ duration: this.model.duration });
    //this.postJobForm.patchValue({ employer: this.model.employer });
    this.postJobForm.patchValue({ start_date: this.model.start_date });
    this.postJobForm.patchValue({ expiry_date: this.model.expiry_date });

   this.default_employer = this.model.employer;
   this.officer_name = this.model.officer;
  }

  get title() { return this.postJobForm.get('title'); }
  get description() { return this.postJobForm.get('description'); }
  get skills() { return this.postJobForm.get('skills'); }
  get duration() { return this.postJobForm.get('duration'); }
  get officer() { return this.postJobForm.get('officer'); }
  get email() { return this.postJobForm.get('email'); }
  get phone() { return this.postJobForm.get('phone'); }
  get employer() { return this.postJobForm.get('employer'); }
  get start_date() { return this.postJobForm.get('start_date'); }
  get expiry_date() { return this.postJobForm.get('expiry_date'); }
  get deleted() { return this.postJobForm.get('deleted'); }
  get reason() { return this.postJobForm.get('reason'); }

  onFullTime(evt) {
    this.ftime = true;
    this.ptime = false;
    this.wage_period_text = this.selectedWagePeriod;
   
    setTimeout(() => {
      $('select').formSelect();
      $('.modal').modal();
      $('.dropdown-trigger').dropdown();
    }, 25);
  }

  onPartTime(evt) {
      this.ftime = false;
      this.ptime = true;
      this.wage_period_text = "Per Hour";
  }

  StartDateChange(evt) {
    if (this.postJobForm ) {
      this.postJobForm.patchValue({start_date : $('#start_date').val()});
     }
     setTimeout(() => {

      $('select').formSelect();
      $('.datepicker1').datepicker({
        format: 'dd/mmm/yyyy',  
        defaultDate: new Date(), 
        //setDefaultDate: new Date(),
        yearRange: 1,
        minDate: new Date(new Date().getFullYear(), 0, 1),    
        maxDate: new Date(new Date().getFullYear() + 1, 12, 31),
      });
    }, 25);
   }

   ExpiryDateChange(evt) {
    if (this.postJobForm ) {
      this.postJobForm.patchValue({expiry_date : $('#expiry_date').val()});
     }

     const selectedDate = new Date();
     selectedDate.setDate(selectedDate.getDate() + 30); 
     setTimeout(() => {
      $('select').formSelect();
      $('.datepicker2').datepicker({
        format: 'dd/mmm/yyyy',   
        defaultDate: selectedDate,         
        yearRange: 5,
        minDate: new Date(),      
        maxDate: new Date(new Date().getFullYear() + 5, 12, 31),
      });
    }, 25);
   }

  postJob(value: any) {
    $('.modal').modal('close');

    this.model.title = value.title;
    this.model.description = value.description;
    this.model.skills = value.skills;
    this.model.duration = value.duration;

    this.model.officer = this.officer_name; //value.officer;
    this.model.email = value.email;
    this.model.phone = value.phone;
    this.model.employer = value.employer;  

    this.model.expiry_date = new Date(this.expiry_date.value);  
    this.model.start_date = this.start_date.value; 

    if (this.actionType === 'Post') {

      this.model.posted = true;
      this.model.archived = false;
      this.model.deleted = false;
      this.model.id = this.db.createId();
      this.model.uid = this.authService.loggedInUser.uid;

      this.jobOfferService.PostJobOffer(this.model).then(x => {
       this.jobSaved.emit(true);
      });
    }

    if (this.actionType === 'Edit') {    
      this.jobOfferService.EditJobOffer(this.model).then(x => {
        this.jobUpdated.emit(true);
       });
    }
  }
  
  archive_job() {
    this.model.posted = false;
    this.model.archived = true;
    this.model.deleted = false;

    $('.modal').modal('close');

    this.jobOfferService.EditJobOffer(this.model).then(x => {
      this.jobArchived.emit(true);
    });
  }

  unarchive_job() {
    this.model.posted = true;
    this.model.archived = false;
    this.model.deleted = false;

    $('.modal').modal('close');

    this.jobOfferService.EditJobOffer(this.model).then(x => {
      this.jobUnArchived.emit(true);
    });
  }


  DeleteJob() {  
    $('.modal').modal('close');

    this.model.posted = false;
    this.model.archived = false;
    this.model.deleted = true;
    this.model.reason_to_delete = this.postJobForm.value.reason;
    
    this.jobOfferService.DeleteJobOffer(this.model).then(x => {
      this.jobDeleted.emit(true);
    });
  }

  deleteClicked() {
    this.step1 = false;
    this.step2 = true;  
  }
  
  onDeleteYes(evt){
    this.step3 = true;
  }
    
  onDeleteNo(evt){
    this.step3 = false;
    this.backClicked()
  }

  backClicked() {
    this.location.back();
  }
}