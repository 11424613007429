<div *ngIf="!loading">
  <div *ngIf="userProfile">
    <div class="border-b-4 border-customTeal py-5 title-container">
      <h4 class="bold-800 black-text">Create a post</h4>
    </div>

    <div *ngIf="hasActiveAccounts">
      <div class="row">
        <div class="pt-8 hidden md:flex flex-wrap gap-8 col m4" *ngIf="platforms.value">
          <ul class="list-disc text-red-700">
            <li *ngIf="
                imageUrls.errors &&
                pickedPlatforms(platforms.value).includes(
                  'instagram',
                  'pinterest')">
              Image is required for Pinterest and Instagram.
            </li>
            <li *ngIf="
                videoUrls.errors &&
                pickedPlatforms(platforms.value).includes('pinterest', 'reddit')">
              Video is not supported fo Pinterest.
            </li>
            <li *ngIf="
                videoUrls.errors &&
                (pickedPlatforms(platforms.value).includes('tiktok') ||
                  pickedPlatforms(platforms.value).includes('youtube'))">
              Video url is required for TikTok and Youtube.
            </li>
            <li *ngIf="pickedPlatforms(platforms.value).includes('tiktok')">
              TikTok require that after uploading the video, users open their
              mobile TikTok app to finalize the processing.
            </li>
          </ul>

          <ng-container *ngIf="imageUrls && imageUrls.length > 0">
            <p class="heading-title">Images</p>
            <div class="col s4 m2 l2" *ngFor="let i of imageUrls.value; let li = index">
              <div class="slider-upload">
                <div class="slider-preview">
                  <div class="card">
                    <div class="card-image">
                      <img [src]="i" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>

          <ng-container *ngIf="videoUrls && videoUrls.length > 0">
            <p class="heading-title">Videos</p>
            <div class="col s4 m2 l2" *ngFor="let i of videoUrls.value; let li = index">
              <div class="slider-upload">
                <div class="slider-preview">
                  <div class="card">
                    <div class="card-image">
                      <video class="video-fluid" controls autoplay loop>
                        <source [src]="i" type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-container>
        </div>

        <div class="col m8 s12">
          <form [formGroup]="postForm" class="" (ngSubmit)="submitPost(postForm.value)">
            <div class="form-control w-full max-w-x">
              <label class="label">
                <span class="heading-title">Post Text / Caption</span>
              </label>
              <textarea class="textarea textarea-bordered" formControlName="post"></textarea>
            </div>

            <div class="form-control w-full max-w-x static">
              <label class="label">
                <span class="heading-title"> Media(Image/video)</span>
              </label>

              <div class="hidden md:flex gap-x-3 w-full row">
                <div class="col s6">
                  <div class="media-container" [ngClass]="{
                      'gray lighten-2  black-text ':
                        videoUrls.value.length >= 1 || uploadingMedia}">
                    <label class="media-label" [ngClass]="{
                        '!cursor-not-allowed':
                          videoUrls.value.length >= 1 || uploadingMedia}">
                      <i class="fa fa-plus-circle media-icon" [ngClass]="{
                          'gray-text darken-2':
                            videoUrls.value.length >= 1 || uploadingMedia
                        }" [ngStyle]="{ color: themeColor }" *ngIf="!imageUploading"></i>
                      <input type="file" type="text" type="file" accept="image/*"
                        (change)="handleFileInput($event.target.files)" [disabled]="
                          videoUrls.value.length >= 1 || uploadingMedia" />
                      <span class="block bold-900 pt-1 black-text">{{
                        imageUploading ? "Uploading Image" : "Upload Images"
                        }}</span>
                    </label>
                  </div>
                  <small *ngIf="videoUrls.value.length >= 1" class="red-text italic text-xs">Can't upload images and
                    video at the same time.
                  </small>
                </div>

                <div class="col s6">
                  <div class="media-container" [ngClass]="{
                      '!bg-gray-200  !text-black !border-0':
                        videoUrls.value.length >= 1 ||
                        imageUrls.value.length >= 1 ||
                        uploadingMedia}">
                    <label class="media-label" [ngClass]="{
                        '!cursor-not-allowed':
                          videoUrls.value.length >= 1 ||
                          imageUrls.value.length >= 1 ||
                          uploadingMedia}">
                      <i class="fa fa-plus-circle media-icon" *ngIf="!videoUploading" [ngStyle]="{ color: themeColor }"
                        [ngClass]="{
                          'text-gray-500':
                            videoUrls.value.length >= 1 ||
                            imageUrls.value.length >= 1 ||
                            uploadingMedia}"></i>
                      <app-loader *ngIf="videoUploading" class="text-center self-center"></app-loader>
                      <input type="file" type="text" type="file" accept="video/*"
                        (change)="handleVideoInput($event.target.files)" [disabled]="
                          videoUrls.value.length >= 1 ||
                          imageUrls.value.length >= 1 ||
                          uploadingMedia" />
                      <span class="block bold-900 pt-1 black-text">{{
                        videoUploading ? "Uploading Video" : "Upload Video"
                        }}</span>
                    </label>
                  </div>
                  <small *ngIf="videoUrls.value.length >= 1" class="red-text italic text-xs">Only one video can be
                    posted at a time.
                  </small>
                  <small *ngIf="imageUrls.value.length >= 1" class="red-text italic text-xs">Can't upload images and
                    video at the same time.
                  </small>
                </div>
              </div>
            </div>
            <div class="form-control w-full max-w-x static">
              <label class="label">
                <span class="heading-title"> Select Platform</span>
              </label>
              <ul>
                <li *ngFor="let platform of platforms.controls; let li = index" [formGroup]="platform">
                  <app-social-media-platform formGroupName="platform" [imageUrls]="imageUrls.value"
                    [videoUrls]="videoUrls.value" (platformError)="onPlatformError($event)">
                  </app-social-media-platform>
                </li>
              </ul>
            </div>
            <div class="form-control w-full max-w-x">
              <label class="label py-2">
                <span class="heading-title">When to post</span>
              </label>
              <div class="flex justify-between w-1/2 row">
                <div class="form-control col s6">
                  <label class="label cursor-pointer">
                    <input type="radio" name="schedule" value="Schedule" formControlName="schedule"
                      class="radio checked:bg-customTeal" (click)="pickSchedule($event)" />
                    <span class="label-text ml-4 font-normal text-black">Schedule for Later</span>
                  </label>
                </div>
                <div class="form-control col s6">
                  <label class="label cursor-pointer">
                    <input type="radio" name="schedule" value="Immediate" formControlName="schedule"
                      class="radio checked:bg-customTeal" (click)="pickSchedule($event)" />
                    <span class="label-text ml-4 font-normal text-black">Post Now</span>
                  </label>
                </div>
              </div>

              <div class="form-control row w-full max-w-x md:w-9/12 flex flex-col md:flex-row justify-between gap-4"
                *ngIf="postForm.controls['schedule'].value === 'Schedule'">
                <div class="form-control col s12 m9 w-full md:w-3/4">
                  <label class="label">
                    <span class="heading-title">Date</span>
                  </label>
                  <input type="date" placeholder="Type here" formControlName="scheduleDate"
                    class="input input-bordered w-full max-w-xs" />
                  <small *ngIf="scheduleDate.errors && scheduleDate.errors.required"
                    class="red-text darken-2 italic text-xs">Date is required for scheduled post.
                  </small>
                </div>

                <div class="w-1/2 col s12 m3 md:w-1/4">
                  <label class="label">
                    <span class="heading-title">Time</span>
                  </label>
                  <input type="time" placeholder="Type here" formControlName="scheduleTime"
                    class="input input-bordered w-full max-w-xs" />
                  <small *ngIf="scheduleTime.errors && scheduleTime.errors.required"
                    class="red-text darken-2 italic text-xs">Time is required for scheduled post.
                  </small>
                </div>

              </div>

              <div class="flex flex-row justify-between w-full max-w-x pt-20 p-b10 buttons-container">
                <div class="form-control">
                  <button type="button" (click)="cancelModalOpen = true"
                    class="black-text bold-800 rounded-lg w-full btn cancel-button"
                    [ngStyle]="{ 'border-color': themeColor }">
                    Cancel
                  </button>
                </div>
                <div class="form-control flex flex-row gap-x-5">
                  <button type="submit" [disabled]="!postForm.valid"
                    class="py-2 px-12 modal-button gap-2 rounded-lg white-text w-full flex gap-x-1 btn bold-800 cancel-button"
                    [ngClass]="{
                      'gray lighten-1 black-text cursor-not-allowed':
                        !postForm.valid
                    }" [ngStyle]="{ 'background-color': themeColor }">
                    Post
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>

    <div *ngIf="!hasActiveAccounts"
      class="mt-5 px-3 py-6 md:px-12 md:py-8 w-full border-2 border-red bg-white rounded-lg">
      <div class="p-b-8">
        <div class="px-5 md:px-0 py-14 flex flex-col w-full jusify-center gap-y-3">
          <div class="w-full py-4 text-center">
            <span class="text-lg font-bold pb-3 block">Link you accounts and create our first post today!</span>
          </div>

          <div class="flex justify-center">
            <app-link-account [link]="false" [new]="true"></app-link-account>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>