import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SupplementalSupportModel } from 'src/app/models/supplementalSupportModel';
import { ApiKeyManagerService } from './apiKeyManager.Service';
import { IDModel } from 'src/app/models/isets/IDModel';


@Injectable({
  providedIn: 'root'
})

export class DocumentsService {

    url = environment.firstNation.apiUrl + 'api/documents/';

    constructor(private httpClient: HttpClient, private keyManager: ApiKeyManagerService ) { }

    getLast2MonthsBDByApplicantID(id: number): Observable<any[]> {
      const headers = new HttpHeaders().set('SOCIALAPIKEY', this.keyManager.getKey());
      // tslint:disable-next-line: max-line-length
      return this.httpClient.get<any[]>(environment.firstNation.apiUrl + 'api/SupplementalSupport/GetLast2MonthsBAndDByApplicantID/' + id, {headers});
    }


    getImage(id: number) {
      const headers = new HttpHeaders().set('SOCIALAPIKEY', this.keyManager.getKey());
      return this.httpClient.get(this.url + 'get/' + id, { headers, responseType: 'blob' });
    }

    postDocument(model: SupplementalSupportModel): any {
      const headers = new HttpHeaders({SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8'});
      return this.httpClient.post<any>(this.url + 'PostDocument/', JSON.stringify(model), {headers});
    }

    postDoc(fileToUpload: File): any {
      const headers = new HttpHeaders({SOCIALAPIKEY: this.keyManager.getKey()});
      const formData: FormData = new FormData();
      formData.append('MyFile', fileToUpload, fileToUpload.name);
      return this.httpClient.post<any>(this.url + 'saveDoc/', formData, {headers});
    }

    postSignature(signatureToUpload: any,fileToUpload: File): any {
      const headers = new HttpHeaders({SOCIALAPIKEY: this.keyManager.getKey()});
      const formData: FormData = new FormData();
      formData.append('MySignature', signatureToUpload);
      formData.append('MyDocument', fileToUpload, fileToUpload.name);
      return this.httpClient.post<any>(this.url + 'SaveBase64Signature/', formData, {headers});
    }

    postDocInfo(model: any): any {
      const headers = new HttpHeaders({SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8'});
      return this.httpClient.post<any>(this.url + 'saveDocInfo/', JSON.stringify(model), {headers});
    }


    // asets documents upload
    //getIsetsImage(id: number) {
      //const headers = new HttpHeaders().set('SOCIALAPIKEY', this.keyManager.getKey());
      //return this.httpClient.get(this.url + 'GetIsetsImage/' + id, { headers, responseType: 'blob' });
    //}

    postIsetsDocument(model: IDModel): any { 
        //console.log(this.url);
        const headers = new HttpHeaders({SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8'});
        return this.httpClient.post<any>(this.url + 'PostIsetsDocument/', JSON.stringify(model), {headers});
    }


}
