<ul class="row collection" *ngIf="messageList">
  <a *ngFor="let msg of messageList" (click)="openDiscussion(msg.discussionID,$event,msg.id)">

    <li class="collection-item valign-wrapper">
      <div class="col s2 m1 center">
        <i class="material-icons green-text" *ngIf="msg.isRead">mark_email_read</i>
        <i class="material-icons red-text" *ngIf="!msg.isRead">mark_email_unread</i>
      </div>
      <div class="col s10 m11">
        <div class="">
          <strong class="blue-text">{{msg.fromName}}</strong>
          <span class="badge right">
            <strong> <small> {{msg.dateSent.toDate() | DateAgo}}</small> </strong>
          </span>
        </div>
        <p>
          <span class="teal-text">{{msg.subject}}</span>
          <span class="black-text" class="truncate"><small>{{msg.body}} </small> </span>
        </p>
      </div>
    </li>
  </a>
</ul>

<div class="row card-panel green" *ngIf="!messageList">
  <p><strong class="white-text">Your Dont Have Any New Message</strong> </p>
</div>