import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import { CommunityService } from 'src/app/services/api/community.service';
import { RegistrationRequestService } from 'src/app/services/api/registration-request.service';
import { IncomeSupportModel, Children, OtherFamilyMembers, Asset, Income } from 'src/app/models/incomeSupportModel';
import { IncomeSupportApplicationService } from 'src/app/services/firebase/income-support-application.service';
import { toast } from 'materialize-css';
import { Location } from '@angular/common';
import { DatePipe } from '@angular/common';
import SignaturePad from 'signature_pad';
import { Signatures } from 'src/app/models/incomeSupportModel';
import { MediaService } from 'src/app/services/api/media.service';
import { SafeUrlPipe } from 'src/app/Pipes/SafeUrlPipe';

const pdfMake = require('pdfmake/build/pdfmake.js');
const pdfFonts = require('pdfmake-unicode/dist/pdfmake-unicode.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import * as pdfMakeX from 'pdfmake/build/pdfmake';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { MemberService } from 'src/app/services/api/member.service';
import { IncomeSupportAppModel } from 'src/app/models/IncomeSupportAppModel';
import { IncomeSupportFamilyMemberModel } from 'src/app/models/IncomeSupportFamilyMemberModel';
import { IncomeSupportApplicationModel } from 'src/app/models/IncomeSupportApplicationModel';
import { FindMemberModel } from 'src/app/models/FindMemberModel';
//import { IncomeSupportApplicationService } from 'src/app/services/firebase/income-support-application.service';
import { ISApplicationService } from 'src/app/services/api/ISApplication.service';
import { AssetsModel } from 'src/app/models/AssetsModel';
import { IncomeModel } from 'src/app/models/IncomeModel';
import { createMask } from '@ngneat/input-mask';

declare var $: any;
declare var M: any;
declare var navigator: any;

@Component({
  selector: 'app-income-support-application',
  templateUrl: './income-support-application.component.html',
  styleUrls: ['./income-support-application.component.css']
})
export class IncomeSupportApplicationComponent implements OnInit {

  nationofUse = environment.firstNation.name;
  nationName = environment.firstNation.displayName;
  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  provinceOfUse = environment.firstNation.province;

  genders: any = ['Male', 'Female', 'Other'];
  maritalStatus: any = ['Single', 'Married', 'Separated ', 'Widowed', "Common Law"];
  provinces: any = ['Alberta', 'British Columbia', 'Manitoba', 'New Brunswick',
    'Newfoundland and Labrador', 'Nova Scotia', 'Ontario', 'Prince Edward Island', 'Quebec', 'Saskatchewan'];
  provincesCode = ['AB', 'BC', "MB", 'NB', "NL", 'NS', 'ON', 'PE', 'QC', 'SK'];
  Provinces = [{ name: 'Alberta', code: 'AB' },
  { name: 'British Columbia', code: 'BC' },
  { name: 'Manitoba', code: 'MB' },
  { name: 'New Brunswick', code: 'NB' },
  { name: 'Newfoundland and Labrador', code: 'NL' },
  { name: 'Nova Scotia', code: 'NS' },
  { name: 'Ontario', code: 'ON' },
  { name: 'Prince Edward Island', code: 'PE' },
  { name: 'Quebec', code: 'QC' },
  { name: 'Saskatchewan', code: 'SK' }
  ];
  EduLevelList = ["Preschool", "Up to Grade 7-8 (Sec. I = Grade 8)", "Grade 9-10 (Sec. II-III)", "Grade 11-12 (Sec. IV-V)",
    "Secondary School Diploma or GED", "Some post-secondary training", "Apprenticeship or trades certificate or diploma",
    "College", "CEGEP", "University certificate or diploma", "University - Bachelor Degree", "University – Master’s degree", "University – Doctorate", "Course or certificate", "Other"];
  //fileType = ['Id', 'Driver License'];

  // public postalCodeMask = [/[A-Za-z]/, /[0-9]/, /[A-Za-z]/, ' ', /[0-9]/, /[A-Za-z]/, /[0-9]/];
  // public phoneNumberMask = ['(', /[0-9]/, /[0-9]/, /[0-9]/, ')',
  //   ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  // public dateMask = [/[0-3]/, /[0-9]/, '/', /[0-1]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  public dateformat = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
  // public isnMask = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  // public SINMask = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  public healthCareNoMask = [/ \d+ /];
  public treatyNoMask = [/[0-9]/, /[0-9]/, /[0-9]/];

  dateInputMask = createMask<Date>({
    alias: 'datetime',
    guide: true,
    inputFormat: 'dd/mm/yyyy',
    parser: (value: string) => {
      const values = value.split('/');
      const year = +values[2];
      const month = +values[1] - 1;
      const date = +values[0];
      return new Date(year, month, date);
    },
  });

  phoneInputMask = createMask<string>( {
    mask: '(999) 999-9999',
    guide: true,
    showMaskTyped: true,
  });

  postalCodeMask = createMask<string>({
    mask: 'A9A 9A9' // Replace {1} with a space if desired
  });

  isnMask = createMask({
    mask: '9999999999',
    placeholderChar: '',
    maskChar: null,
  });

  SINMask = createMask({
    mask: '999 999 999',
    placeholderChar: '',
    maskChar: null,
  });



  nationCommunities: any[];
  treatyNo = '';
  bands: any[];
  bandUse: any[];
  bandName = '';
  provinceCode: any;
  bandList: any[];

  AssetsOfAlberta = ["Owing from other persons", "In Trust with other persons", "Life Insurance", "Savings", "Equipment / Trapping gear", "Farm Assets", "Other"];
  //AssetsOfAlberta = ["Owing from other persons", "In trust with other persons", "Life Insurance", "Savings", "Trapping Equipment", "Farm equipment", "Other"];
  incomes = ["Wages - including Serverance And Holiday Pay", "Pension (State Pay)", "Workers Compensation", "Unemployment Insurance", "Post Secondary Education Support", "Other Educational Training Allowance", "Fur & Fish Sales, Farming or Small Business", "Band Distribution", "Rental or Land Lease", "Family Support Payments", "Other Income", "Lump Sum or Settlement Within The Past Year"];
  //relations = ['Stepson', 'stepdaughter', 'Grandson', 'Granddaugter', 'Mother', 'Father', 'Nephew', 'Niece', 'Cousin', 'Other'];
  RelationshipList = ["Grandson", "Granddaughter", "Niece", "Nephew", "Cousin", "Brother", "Sister", "Relative", 'Other'];

  section1Form: UntypedFormGroup;
  section2Form: UntypedFormGroup;
  section3Form: UntypedFormGroup;
  section31Form: UntypedFormGroup;
  section4Form: UntypedFormGroup;
  section5Form: UntypedFormGroup;
  section6Form: UntypedFormGroup;
  section61Form: UntypedFormGroup;

  selectedValue = '';
  dates = [];
  spouseDobSet = false;
  showSpouseInfo = false;
  showMailingAddress = false;
  moved = false;
  spouseMoved = false;
  showEmployemnt = false;
  presentlyEmployed = false;
  showSpouseEmployemnt = false;
  spousePresentlyEmployed = false;

  maritalStatusSelected = false;
  showOtherRelation = false;
  assetSelected = false;
  incomeSelected = false;
  spouseIncomeSelected = false;
  agree = false;

  canvas: any;
  signaturePad: SignaturePad;
  signing = 'APPLICANT';
  applicantSigned = false;
  spouseSigned = false;
  // witnessSigned = false;
  signModel: Signatures;

  step = 1;
  step2 = 1;

  addVehicle = false;
  displayVehicle = false;
  emptyVehicle = false;
  HasTwoVehicle = false;   /// at most add two vehicle

  addAssets = false;
  displayAssets = false;
  emptyAssets = false;

  addChildren = false;
  displayChildren = false;
  emptyChildren = false;

  addOtherMemebers = false;
  displayOthers = false;
  emptyOthers = false;

  addIncomes = false;
  displayIncomes = false;
  emptyIncomes = false;

  addSpouseIncomes = false;
  displaySpouseIncomes = false;
  emptySpouseIncomes = false;

  displayFiles = false;

  model: IncomeSupportModel;
  childrenModel: Children;
  othersModel: OtherFamilyMembers;
  assetModel: Asset;

  incomeModel: Income;
  ApplicationModel: IncomeSupportAppModel;
  FamilyMemberModel: IncomeSupportFamilyMemberModel[];
  otherPersonModel: IncomeSupportFamilyMemberModel[];
  tmpSpouseModel: any;
  tmpMemberModel: any[] = [];
  tmpOtherMemberModel: any[] = [];
  tmpAssetsModel: any[];
  tmpVehicleModel: any[];
  AssetsModel: AssetsModel[];
  oneAssets: AssetsModel;
  NewRequestOfFamilyMemberModel: IncomeSupportFamilyMemberModel[];
  applicantIncomeModel: any[] = [];
  spouseIncomeModel: any[] = [];
  IncomesModel: IncomeModel[];
  aIncome: IncomeModel;


  aMember: IncomeSupportFamilyMemberModel;
  findMemberModel: FindMemberModel;
  applicant_name = '';
  spouse_name = '';
  memberProfile: any;
  hasISN = false;


  logoLink = "https://www.tailorbrands.com/wp-content/uploads/2020/07/twitter-logo.jpg";
  logo = "https://api.mysabenefits.ca/Media/Demo/Images/06bd330b-ce43-42ec-b91a-6f82213e4311-Logo-512x512.png";
  logoBase64 = "";
  logoModel: any;

  //newly added
  applicantID: number;
  currentUser: any;
  familyMembers: any[];
  currentApplication: any;
  spouseID: number;
  spouse: any;
  hasSpouse = false;
  memberID = 0;
  spouseAdded = false;
  spouseFirstName;
  spouseLastName;

  constructor(private formBuilder: UntypedFormBuilder,
    private communityService: CommunityService,
    private sqlRegistrationRequest: RegistrationRequestService,
    private mediaService: MediaService,
    private authService: AuthService,
    private memberService: MemberService,
    private safeUrlPipe: SafeUrlPipe,
    private settingsService: AppSettingsService,
    private ISAppService: ISApplicationService,
    private location: Location, private IncomeSupportApplicationService: IncomeSupportApplicationService) { }


  ngOnInit() {
    //get logged in user
    this.currentUser = this.authService.getGlobalUser();
    if (this.currentUser) this.applicantID = this.currentUser.applicantID;

    if (this.applicantID) {
      this.memberService.getMember(this.applicantID).subscribe(member => {
        if (member) {
          this.currentUser = member[0];
          this.applicant_name = this.currentUser.GivenName + ' ' + this.currentUser.LastName;
          // console.log(this.currentUser);

          if (this.currentUser) {
            this.communityService.getBands().subscribe(bands => {
              if (bands) {
                this.bands = bands;
                this.getBandListFromProvinceCode(this.currentUser.province);

                //get Treaty Number
                if (this.currentUser.TreatyNo) {
                  this.treatyNo = this.currentUser.TreatyNo;
                } else {
                  this.sqlRegistrationRequest.getTreatyNumber().subscribe(t => {
                    if (t) {
                      this.treatyNo = t;
                    }
                  });
                }
              
                //set up for dates
                for (var i = 0; i < 25; i++) {
                  this.dates.push({ selected: '', set: false })
                };

                if (this.currentUser.MaritalStatus == '0') this.currentUser.MaritalStatus = 'Single';
                var array = this.currentUser.DOB.split("-");
                var applicantDob = `${array[2].substring(0, 2)}/${array[1]}/${array[0]}`;

                //applicant and spouse form
                if (this.currentUser.IndianStatusNo) this.hasISN = true;
                this.section1Form = this.formBuilder.group({
                  applicant: this.formBuilder.group({
                    first_name: [this.currentUser.GivenName, [Validators.required, Validators.minLength(1)]],
                    last_name: [this.currentUser.LastName, [Validators.required, Validators.minLength(1)]],
                    treaty_no: [this.treatyNo, [Validators.required]],
                    band_name: [this.currentUser.Band],
                    isn: [this.currentUser.IndianStatusNo, [Validators.minLength(10), Validators.maxLength(10), Validators.pattern('^[0-9]*$')]],
                    province: [this.currentUser.province, [Validators.required]],
                    marital_status: [this.currentUser.MaritalStatus, [Validators.required]],
                    edu_level: [this.EduLevelList[Number(this.currentUser.edu_level)], [Validators.required]],
                    city: [this.currentUser.city, [Validators.required]],
                    telephone: ['', []],
                    mobile: [this.currentUser.cell_phone_num, []],
                    address: ["", [Validators.required]],
                    house_no: [this.currentUser.HouseNumber],
                    postal_code: [this.currentUser.PostalCode, [Validators.required]],
                    health_care_no: [this.currentUser.HealthCareNo],
                    dob: [applicantDob, [Validators.required, this.isBeforeToday]],
                    SIN: [this.currentUser.SIN, [Validators.minLength(9), Validators.maxLength(9), Validators.pattern('^[0-9]*$')]],
                    sameMailingAddress: ['', [Validators.required]],
                    mailing_address: [this.currentUser.MailAddress],

                  }),
                  spouse: this.formBuilder.group({
                    spouse_first_name: [],
                    spouse_last_name: [''],
                    spouse_isn: [''],
                    spouse_band_name: [],
                    spouse_email: [''],
                    spouse_mobile: [''],
                    spouse_health_care_no: [''],
                    spouse_dob: [null],
                    spouse_SIN: [''],
                    spouse_city: ['',],
                    spouse_province: [],
                  }),
                });

                this.section1Form.get('applicant.sameMailingAddress').valueChanges.subscribe(value => {
                  if (value == 'no') {
                    this.mailing_address.setValidators(Validators.required);
                  } else {
                    this.mailing_address.clearValidators();
                  }
                  this.mailing_address.updateValueAndValidity()
                });

                //children form
                this.section4Form = this.formBuilder.group({
                  children: this.formBuilder.group({
                    add_children: ['', [Validators.required]],
                    childrens: this.formBuilder.array([]),
                    child_first_name: [''],
                    child_last_name: [''],
                    child_dob: [''],
                    child_relationship: [''],
                    child_edu_level: [''],
                  }),
                  other: this.formBuilder.group({
                    add_others: ['', [Validators.required]],
                    others: this.formBuilder.array([]),
                    other_first_name: [''],
                    other_last_name: [''],
                    other_dob: [''],
                    relationship: [''],
                    other_relationship: [''],
                    contribution: ['0.00'],
                  })
                });

                this.section4Form.get('children.add_children').valueChanges.subscribe(value => {
                  if (value == 'yes') {
                    this.section4Form.get('children.child_first_name').setValidators(Validators.required);
                    this.section4Form.get('children.child_last_name').setValidators(Validators.required);
                    this.section4Form.get('children.child_dob').setValidators([Validators.required, this.isBeforeToday]);
                    //this.section4Form.get('children.child_ISN').setValue(t);
                    //this.section4Form.get('children.child_ISN').setValidators([Validators.minLength(10), Validators.maxLength(10), Validators.pattern('^[0-9]*$')]);
                  } else {
                    this.section4Form.get('children.child_first_name').clearValidators();
                    this.section4Form.get('children.child_last_name').clearValidators();
                    this.section4Form.get('children.child_dob').clearValidators();
                    //this.section4Form.get('children.child_ISN').clearValidators();
                  }
                  this.section4Form.get('children.child_first_name').updateValueAndValidity();
                  this.section4Form.get('children.child_last_name').updateValueAndValidity();
                  this.section4Form.get('children.child_dob').updateValueAndValidity();
                  //this.section4Form.get('children.child_ISN').updateValueAndValidity();
                });

                this.section4Form.get('other.add_others').valueChanges.subscribe(value => {
                  if (value == 'yes') {
                    this.section4Form.get('other.other_first_name').setValidators(Validators.required);
                    this.section4Form.get('other.other_last_name').setValidators(Validators.required);
                    this.section4Form.get('other.other_dob').setValidators([this.isBeforeToday]);
                  } else {
                    this.section4Form.get('other.other_first_name').clearValidators();
                    this.section4Form.get('other.other_last_name').clearValidators();
                    this.section4Form.get('other.other_dob').clearValidators();
                  }
                  this.section4Form.get('other.other_first_name').updateValueAndValidity();
                  this.section4Form.get('other.other_last_name').updateValueAndValidity();
                  this.section4Form.get('other.other_dob').updateValueAndValidity();
                });

                //previous address,education and employement form
                this.section2Form = this.formBuilder.group({
                  previous_address: this.formBuilder.group({
                    applicant: this.formBuilder.group({
                      moved: ['', [Validators.required]],
                      address: [''],
                      address_from: [null, this.isBeforeToday],
                      address_to: [null, this.isBeforeToday],
                    }),
            
                  }),
                  recent_education: this.formBuilder.group({
                    applicant: this.formBuilder.group({
                      edu_name: [''],
                      edu_from: [null, this.isBeforeToday],
                      edu_to: [null, this.isBeforeToday],
                    }),
                    spouse: this.formBuilder.group({
                      spouse_edu_name: [''],
                      spouse_edu_from: [null, this.isBeforeToday],
                      spouse_edu_to: [null, this.isBeforeToday],
                    }),
                  }),
                  employment: this.formBuilder.group({
                    applicant: this.formBuilder.group({
                      presently_employed: ['', [Validators.required]],
                      employer_name: [''],
                      // employer_contact_name: [''],
                      employer_address: [''],
                      position: [''],
                      employment_from: [null, this.isBeforeToday],
                      employment_to: [, this.isBeforeToday]
                    }),
                    spouse: this.formBuilder.group({
                      spouse_presently_employed: [''],
                      spouse_employer_name: [''],
                      // spouse_employer_contact_name: [''],
                      spouse_employer_address: [''],
                      spouse_position: [''],
                      spouse_employment_from: [null, this.isBeforeToday],
                      spouse_employment_to: [null, this.isBeforeToday]
                    })
                  }),
                });

                //vechile form
                this.section31Form = this.formBuilder.group({
                  add_vehicle: ['', [Validators.required]],
                  vehicle: this.formBuilder.array([]),
                  model: [''],
                  year_to_make: [''],
                  vehicle_value: ['0.00']    //vehicle_value: [null]
                });

                this.section31Form.get('add_vehicle').valueChanges.subscribe(value => {
                  if (value == 'yes' && this.addVehicle) {
                    this.section31Form.get('model').setValidators(Validators.required);
                    this.section31Form.get('year_to_make').setValidators(Validators.required);
                    this.section31Form.get('vehicle_value').setValidators(Validators.required);
                  } else {
                    this.section31Form.get('model').clearValidators();
                    this.section31Form.get('year_to_make').clearValidators();
                    this.section31Form.get('vehicle_value').clearValidators();
                  }
                  this.section31Form.get('model').updateValueAndValidity();
                  this.section31Form.get('year_to_make').updateValueAndValidity();
                  this.section31Form.get('vehicle_value').updateValueAndValidity();
                });

                //Assets form
                this.section3Form = this.formBuilder.group({
                  add_assets: ['', [Validators.required]],
                  assets: this.formBuilder.array([]),
                  asset_type: [''],
                  asset_amount: [null]
                });

                this.section3Form.get('add_assets').valueChanges.subscribe(value => {
                  if (value == 'yes' && this.addAssets) {
                    this.section3Form.get('asset_type').setValidators(Validators.required);
                    this.section3Form.get('asset_amount').setValidators(Validators.required);
                  } else {
                    this.section3Form.get('asset_type').clearValidators();
                    this.section3Form.get('asset_amount').clearValidators();
                  }
                  this.section3Form.get('asset_type').updateValueAndValidity();
                  this.section3Form.get('asset_amount').updateValueAndValidity();
                });

                //Incomes Form
                this.section5Form = this.formBuilder.group({
                  applicant: this.formBuilder.group({
                    add_income: ['', [Validators.required]],
                    incomes: this.formBuilder.array([]),
                    income_type: [''],
                    income_amount: [],
                    income_date: [null, this.isBeforeToday],
                    date_of_last_assistance: [null, this.isBeforeToday],
                    administrative_authority: [''],
                    amount: []
                  }),
                  spouse: this.formBuilder.group({
                    add_spouse_income: ['', [Validators.required]],
                    spouse_incomes: this.formBuilder.array([]),
                    spouse_income_type: [''],
                    spouse_income_amount: [],
                    spouse_income_date: [null, this.isBeforeToday],
                  }),
                });

                this.section5Form.get('applicant.add_income').valueChanges.subscribe(value => {
                  if (value == 'yes') {
                    this.section5Form.get('applicant.income_type').setValidators(Validators.required);
                    this.section5Form.get('applicant.income_amount').setValidators(Validators.required);
                  } else {
                    this.section5Form.get('applicant.income_type').clearValidators();
                    this.section5Form.get('applicant.income_amount').clearValidators();
                  }
                  this.section5Form.get('applicant.income_type').updateValueAndValidity();
                  this.section5Form.get('applicant.income_amount').updateValueAndValidity();
                });

                this.section5Form.get('spouse.add_spouse_income').valueChanges.subscribe(value => {
                  if (value == 'yes') {
                    this.section5Form.get('spouse.spouse_income_type').setValidators(Validators.required);
                    this.section5Form.get('spouse.spouse_income_amount').setValidators(Validators.required);
                  } else {
                    this.section5Form.get('spouse.spouse_income_type').clearValidators();
                    this.section5Form.get('spouse.spouse_income_amount').clearValidators();
                  }
                  this.section5Form.get('spouse.spouse_income_type').updateValueAndValidity();
                  this.section5Form.get('spouse.spouse_income_amount').updateValueAndValidity();
                });

                //Housing Form
                this.section61Form = this.formBuilder.group({
                  house_shared: ['', [Validators.required]],
                  landlord: ['', [Validators.required]],
                  relation: [''],
                  ownership: ['', [Validators.required]],
                  cmhc_assisted: [[Validators.required]],
                  utilities: ['0.00'],
                  fuel: ['0.00'],
                  maintenance: ['0.00'],
                  garbage: ['0.00'],
                  rent: ['0.00'],
                  comments: ['']
                });

                const today = new Date();
                const date_pipe = new DatePipe('en-US');
                const todayDate = date_pipe.transform(today, 'fullDate');
                const todayFullDate = date_pipe.transform(today, 'dd/MM/yyyy');
                this.dates[22].set = true; this.dates[22].selected = todayFullDate;
                this.dates[23].set = true; this.dates[23].selected = todayFullDate;
                this.dates[24].set = true; this.dates[24].selected = todayFullDate;

                //Declatation Form
                this.section6Form = this.formBuilder.group({
                  applicant_signature: ['', [Validators.required]],
                  applicant_date: [todayDate],
                  spouse_signature: [''],
                  spouse_date: [todayDate],
                  // witness_signature: ['', [Validators.required]],
                  // witness_date: [todayDate],
                  agree: ['', [Validators.required]],
                  comments: [''],
                  message: [''],
                  files: this.formBuilder.group({
                    upload: ['no'],
                    document: [''],
                    document_link: [''],
                  }),

                });

                this.section6Form.get('files.upload').valueChanges.subscribe(value => {
                  if (value == 'yes') {
                    this.section6Form.get('files.document').setValidators([Validators.required]);
                  } else {
                    this.section6Form.get('files.document').clearValidators();
                  }
                  this.section6Form.get('files.document').updateValueAndValidity();
                });

                this.marital_status.valueChanges.subscribe(value => {
                  if (value == 'Married' || value == "Common Law") {
                    this.spouse_first_name.setValidators(Validators.required);
                    this.spouse_last_name.setValidators(Validators.required);
                    this.spouse_dob.setValidators([Validators.required, this.isBeforeToday]);
                    this.spouse_province.setValidators(Validators.required);
                    this.spouse_isn.setValidators([Validators.minLength(10), Validators.maxLength(10), Validators.pattern('^[0-9]*$')]);
                    this.spouse_SIN.setValidators([Validators.minLength(9), Validators.maxLength(9), Validators.pattern('^[0-9]*$')]);
                    this.spouse_city.setValidators([Validators.required]);
                    this.spouse_email.setValidators([Validators.email]);
                    // this.section2Form.get('previous_address.spouse.spouse_moved').setValidators([Validators.required]);
                    //this.section6Form.get('spouse_signature').setValidators([Validators.required]);
                  } else {
                    this.spouse_first_name.clearValidators();
                    this.spouse_last_name.clearValidators();
                    this.spouse_dob.clearValidators();
                    this.spouse_province.clearValidators();
                    this.spouse_isn.clearValidators();
                    this.spouse_SIN.clearValidators();
                    this.spouse_city.clearValidators();
                    this.spouse_email.clearValidators();
                    // this.section2Form.get('previous_address.spouse.spouse_moved').clearValidators();
                    //this.section6Form.get('spouse_signature').clearValidators();
                  }
                  this.spouse_first_name.updateValueAndValidity();
                  this.spouse_last_name.updateValueAndValidity();
                  this.spouse_dob.updateValueAndValidity();
                  this.spouse_province.updateValueAndValidity();
                  this.spouse_isn.updateValueAndValidity();
                  this.spouse_SIN.updateValueAndValidity();
                  this.spouse_city.updateValueAndValidity();
                  this.spouse_email.updateValueAndValidity();
                  // this.section2Form.get('previous_address.spouse.spouse_moved').updateValueAndValidity();
                  //this.section6Form.get('spouse_signature').updateValueAndValidity();
                });

                /***********************************************************************populate data*********************************************** */

                //get spouse info if exists
                const marital_status = this.currentUser.MaritalStatus;
                if (marital_status == 'Married' || marital_status == "Common Law") {
                  this.spouseID = this.currentUser.aidofspouse;
                  this.hasSpouse = true;
                } else {
                  // console.log("has no spouse");
                }

                //get Family Members
                const familyComp = this.currentUser.current_family_comp;
                if (familyComp) {
                  this.ISAppService.getFamilyMembers(familyComp).subscribe(fam => {
                    if (fam && fam.length > 0) {
                      this.familyMembers = fam;
                      this.familyMembers.forEach(fm => {
                        if (fm.Relationship == 'Spouse') {
                          //this.showSpouseInfo = true;
                          this.spouseID = fm.applicantIDOfChild;
                          this.hasSpouse = true;
                        } else {
                          let values = this.formBuilder.group({
                            given_name: fm.given_name,
                            last_name: fm.last_name,
                            dob: this.formatDates(fm.DOB),
                            Relationship: fm.Relationship,
                            edu_level: this.EduLevelList[Number(fm.edu_level)],
                            applicantIDOfHead: fm.applicantIDOfHead,
                            applicantIDOfChild: fm.applicantIDOfChild,
                          });
                          this.childControls.push(values);
                          this.section4Form.get('children.add_children').patchValue('yes');
                          this.emptyChildren = false;
                          this.displayChildren = true;
                          this.section4Form.get('children.child_first_name').clearValidators();
                          this.section4Form.get('children.child_last_name').clearValidators();
                          this.section4Form.get('children.child_dob').clearValidators();
                          this.section4Form.get('children.child_first_name').updateValueAndValidity();
                          this.section4Form.get('children.child_last_name').updateValueAndValidity();
                          this.section4Form.get('children.child_dob').updateValueAndValidity();
                        }
                        setTimeout(() => {
                          $('.collapsible').collapsible();
                          $('select').formSelect();
                          M.updateTextFields();
                        }, 25);
                      });
                    } else {
                      this.section4Form.get('children.add_children').patchValue('no');
                      this.onAddChildrenNo();
                    }
                  });
                }

                //populate spouse Data
                if (this.hasSpouse && this.spouseID && this.spouseID > 0) {
                  this.showSpouseInfo = true;
                  this.memberService.getMember(this.spouseID).subscribe(spouse => {
                    if (spouse) {
                      this.spouseAdded = true;
                      this.spouse = spouse[0];
                      this.spouse_name = this.spouse.GivenName + ' ' + this.spouse.LastName;
                      this.spouseAdded = true;
                      this.section1Form.get('spouse.spouse_first_name').patchValue(this.spouse.GivenName);
                      this.section1Form.get('spouse.spouse_first_name').disable();
                      this.section1Form.get('spouse.spouse_last_name').patchValue(this.spouse.LastName);
                      this.section1Form.get('spouse.spouse_last_name').disable();
                      this.section1Form.get('spouse.spouse_isn').patchValue(this.spouse.IndianStatusNo);
                      this.section1Form.get('spouse.spouse_isn').disable();
                      this.section1Form.get('spouse.spouse_band_name').patchValue(this.spouse.Band);
                      this.section1Form.get('spouse.spouse_email').patchValue(this.spouse.Email);
                      this.section1Form.get('spouse.spouse_mobile').patchValue(this.spouse.cell_phone_num);
                      this.section1Form.get('spouse.spouse_health_care_no').patchValue(this.spouse.HealthCareNo);
                      var array = this.currentUser.DOB.split("-");
                      var spouseDob = `${array[2].substring(0, 2)}/${array[1]}/${array[0]}`;
                      this.section1Form.get('spouse.spouse_dob').patchValue(spouseDob);
                      this.section1Form.get('spouse.spouse_SIN').patchValue(this.spouse.SIN);
                      this.section1Form.get('spouse.spouse_city').patchValue(this.spouse.city);
                      this.section1Form.get('spouse.spouse_province').patchValue(this.spouse.province);
                    }
                  });
                } else {
                  this.spouseAdded = false;
                }

                const currentApp = this.currentUser.current_application;
                // console.log(currentApp);
            
                //get current IS application
                if (currentApp && currentApp > 0) {
                  //get other person in the househole
                  this.ISAppService.getOtherPersons(currentApp).subscribe(persons => {
                   
                    if (persons && persons.length > 0) {
                      persons.forEach(person => {
                        var values = this.formBuilder.group({
                          given_name: person.given_name,
                          last_name: person.last_name,
                          dob: this.formatDates(person.dob),
                          Relationship: person.Relationship,
                          applicantIDOfHead: person.applicantIDOfHead,
                          applicantIDOfPerson: person.applicantIDOfChild,
                          contribution: person.financial_contribution,
                        });
                        this.section4Form.get('other.add_others').patchValue('yes');
                        this.othersControls.push(values);
                        // this.addOtherMemebers = false;
                        this.emptyOthers = false;
                        this.displayOthers = true;
                        this.section4Form.get('other.other_first_name').clearValidators();
                        this.section4Form.get('other.other_last_name').clearValidators();
                        this.section4Form.get('other.other_dob').clearValidators();
                        this.section4Form.get('other.other_first_name').updateValueAndValidity();
                        this.section4Form.get('other.other_last_name').updateValueAndValidity();
                        this.section4Form.get('other.other_dob').updateValueAndValidity();
                        //this.addOthers(values);
                        setTimeout(() => {
                          $('.collapsible').collapsible();
                          $('select').formSelect();
                          M.updateTextFields();
                        }, 25);
                      });
                    }
                    else {
                      this.section4Form.get('other.add_others').patchValue('no');
                    }
                  });

                  //get current IS application
                
                  this.ISAppService.getApplication(currentApp).subscribe(app => {
                    if (app) {
                      this.currentApplication = app[0];
                      
                      if (this.currentApplication) {
                        this.section1Form.get('applicant.address').patchValue(this.currentApplication.address);
                        setTimeout(() => {
                          M.updateTextFields();
                        }, 25);
                        if (this.currentUser.MailAddress == this.currentApplication.address) {
                          this.section1Form.get('applicant.sameMailingAddress').patchValue('yes');
                          this.showMailingAddress = false;
                        } else {
                          this.section1Form.get('applicant.sameMailingAddress').patchValue('no');
                          this.showMailingAddress = true;
                          setTimeout(() => {
                            M.updateTextFields();
                          }, 25);
                        }
                        //this.currentApplication.previous_address = "previous address example";
                        if (this.currentApplication.previous_address) {
                        
                          this.section2Form.get('previous_address.applicant.moved').patchValue('yes');
                          this.onMovedYes();
                          this.previous_address.patchValue(this.currentApplication.previous_address);
                          this.address_from.patchValue(this.currentApplication.previous_address_startDate);
                          this.address_to.patchValue(this.currentApplication.previous_address_endDate);
                        } else {
                          
                          this.section2Form.get('previous_address.applicant.moved').patchValue('no');
                          this.onMovedNo();
                        }
                
                        if (this.currentApplication.present_employer) {
                        
                          this.presently_employed.patchValue('yes');
                          this.employer_name.patchValue(this.currentApplication.present_employer);
                          this.employer_address.patchValue(this.currentApplication.present_employment_address);
                          this.employment_position.patchValue(this.currentApplication.present_employment_position);
                          this.employment_from.patchValue(this.currentApplication.present_employment_startDate);
                          this.employment_to.patchValue(this.currentApplication.present_employment_endDate);
                          this.showEmployemnt = true;
                          this.presentlyEmployed = true;
                        } else if (this.currentApplication.previous_employer) {
                         
                          this.presently_employed.patchValue('no');
                          this.employer_name.patchValue(this.currentApplication.previous_employer);
                          this.employer_address.patchValue(this.currentApplication.previous_employment_address);
                          this.employment_position.patchValue(this.currentApplication.previous_employment_position);
                          this.employment_from.patchValue(this.currentApplication.previous_employment_startDate);
                          this.employment_to.patchValue(this.currentApplication.previous_employment_endDate);
                          this.showEmployemnt = true;
                          this.presentlyEmployed = false;
                        }
                        if (this.currentApplication.present_employer_spouse) {
                          
                          this.spouse_presently_employed.patchValue('yes');
                          this.spouse_employer_name.patchValue(this.currentApplication.present_employer_spouse);
                          this.spouse_employer_address.patchValue(this.currentApplication.present_employment_address_spouse);
                          this.spouse_employment_position.patchValue(this.currentApplication.present_employment_position_apouse);
                          this.spouse_employment_from.patchValue(this.currentApplication.present_employment_startDate_spouse);
                          this.spouse_employment_to.patchValue(this.currentApplication.present_employment_endDate_spouse);
                          this.showSpouseEmployemnt = true;
                          this.spousePresentlyEmployed = true;
                        } else if (this.currentApplication.previous_employer_spouse) {
                         
                          this.spouse_presently_employed.patchValue('no');
                          this.spouse_employer_name.patchValue(this.currentApplication.previous_employer_spouse);
                          this.spouse_employer_address.patchValue(this.currentApplication.previous_employment_address_spouse);
                          this.spouse_employment_position.patchValue(this.currentApplication.previous_employment_position_apouse);
                          this.spouse_employment_from.patchValue(this.currentApplication.previous_employment_startDate_spouse);
                          this.spouse_employment_to.patchValue(this.currentApplication.previous_employment_endDate_spouse);
                          this.showSpouseEmployemnt = true;
                          this.spousePresentlyEmployed = false;
                        }

                        if (this.currentApplication.car1_value > 0 || this.currentApplication.car2_value > 0) {
                          this.section31Form.get('add_vehicle').patchValue('yes');
                          if (this.currentApplication.car1_value > 0) {
                            let value = this.formBuilder.group({
                              model: this.currentApplication.car1_make,
                              year_to_make: this.currentApplication.car1_year,
                              vehicle_value: this.currentApplication.car1_value,
                            });
                            this.vehicleControls.push(value);
                            this.displayVehicle = true;
                            this.emptyVehicle = false;
                            this.section31Form.get('model').clearValidators();
                            this.section31Form.get('year_to_make').clearValidators();
                            this.section31Form.get('vehicle_value').clearValidators();
                            this.section31Form.get('model').updateValueAndValidity();
                            this.section31Form.get('year_to_make').updateValueAndValidity();
                            this.section31Form.get('vehicle_value').updateValueAndValidity();
                            //this.addNewVehicle(value);

                          }
                          if (this.currentApplication.car2_value > 0) {
                            let value = this.formBuilder.group({
                              model: this.currentApplication.car2_make,
                              year_to_make: this.currentApplication.car2_year,
                              vehicle_value: this.currentApplication.car2_value,
                            });
                            this.vehicleControls.push(value);
                            this.displayVehicle = true;
                            this.emptyVehicle = false;
                            //this.addNewVehicle(value);
                          }
                        } else {
                          this.section31Form.get('add_vehicle').patchValue('no');
                          this.onAddVehicleNo();
                        }
                        //housing 
                        if (this.currentApplication.isYesOfOthers == true) { this.section61Form.controls['house_shared'].patchValue('yes'); }
                        if (this.currentApplication.isYesOfOthers == false) { this.section61Form.controls['house_shared'].patchValue('no'); }
                        if (this.currentApplication.WhoOfOthers) { this.section61Form.controls['landlord'].patchValue(this.currentApplication.WhoOfOthers); }
                        if (this.currentApplication.relationship_headofhouse) { this.section61Form.controls['relation'].patchValue(this.currentApplication.relationship_headofhouse); }
                        if (this.currentApplication.isRentedOfOthers) { this.section61Form.controls['ownership'].patchValue(this.currentApplication.isRentedOfOthers); }
                        if (this.currentApplication.isYesOfCMHC == true) { this.section61Form.controls['cmhc_assisted'].patchValue('yes'); }
                        if (this.currentApplication.isYesOfCMHC == false) { this.section61Form.controls['cmhc_assisted'].patchValue('no'); }
                        if (this.currentApplication.UtilitiesOfOthers) { this.section61Form.controls['utilities'].patchValue(this.currentApplication.UtilitiesOfOthers); }
                        if (this.currentApplication.FuelOfOthers) { this.section61Form.controls['fuel'].patchValue(this.currentApplication.FuelOfOthers); }
                        if (this.currentApplication.MaintenanceOfOthers) { this.section61Form.controls['maintenance'].patchValue(this.currentApplication.MaintenanceOfOthers); }
                        if (this.currentApplication.GarbageOfOthers) { this.section61Form.controls['garbage'].patchValue(this.currentApplication.GarbageOfOthers); }
                        if (this.currentApplication.LoanPMTOfOthers) { this.section61Form.controls['rent'].patchValue(this.currentApplication.LoanPMTOfOthers); }
                        if (this.currentApplication.comments) { this.section61Form.controls['comments'].patchValue(this.currentApplication.comments); }
                      }

                    }
                  });

                  //get Assets
                  this.ISAppService.getAssets(currentApp).subscribe(assets => {
                   
                    if (assets && assets.length) {
                      this.section3Form.get('add_assets').patchValue('yes');
                      assets.forEach(asset => {
                        let values = this.formBuilder.group({
                          asset: asset.catagory,
                          amount: asset.value,
                        });
                        this.assetControls.push(values);
                        this.displayAssets = true;
                        this.emptyAssets = false;
                        this.section3Form.get('asset_type').clearValidators();
                        this.section3Form.get('asset_amount').clearValidators();
                        this.section3Form.get('asset_type').updateValueAndValidity();
                        this.section3Form.get('asset_amount').updateValueAndValidity();
                        // this.addNewAssets(values);
                      });
                    } else {
                      this.section3Form.get('add_assets').patchValue('no');
                      this.onAddAssetsNo();
                    }
                  });

                  //get Incomes
                  this.ISAppService.getIncomes(currentApp).subscribe(incomes => {
                   
                    let applicantHasIncome = false;
                    let spouseHasIncome = false;
                    if (incomes && incomes.length > 0) {
                      incomes.forEach(income => {
                        if (income.Fam_Mem_Type == 'A') {
                          applicantHasIncome = true;
                          let values = this.formBuilder.group({
                            income: income.catagory,
                            amount: income.value,
                            date_recevied: income.date_recevied
                          })
                          this.incomeControls.push(values);
                          //this.addNewIncome(values);
                        }
                        if (income.Fam_Mem_Type == 'S') {
                          spouseHasIncome = true;
                          this.section5Form.get('spouse.add_spouse_income').patchValue('yes');
                          let values = this.formBuilder.group({
                            income: income.catagory,
                            amount: income.value,
                            date_recevied: income.date_recevied
                          });
                          this.spouseIncomeControls.push(values);

                          //this.addNewSpouseIncome(values);
                        }
                      });
                    }
                    if (applicantHasIncome) {
                      this.section5Form.get('applicant.add_income').patchValue('yes');
                      this.displayIncomes = true;
                      this.emptyIncomes = false;
                      this.section5Form.get('applicant.income_type').clearValidators();
                      this.section5Form.get('applicant.income_amount').clearValidators();
                      this.section5Form.get('applicant.income_type').updateValueAndValidity();
                      this.section5Form.get('applicant.income_amount').updateValueAndValidity();

                    } else {
                      this.section5Form.get('applicant.add_income').patchValue('no');
                    }
                    if (spouseHasIncome) {
                      this.section5Form.get('spouse.add_spouse_income').patchValue('yes');
                      this.displaySpouseIncomes = true;
                      this.emptySpouseIncomes = false;
                      this.section5Form.get('spouse.spouse_income_type').clearValidators();
                    this.section5Form.get('spouse.spouse_income_amount').clearValidators();
                    this.section5Form.get('spouse.spouse_income_type').updateValueAndValidity();
                    this.section5Form.get('spouse.spouse_income_amount').updateValueAndValidity();
                    } else {
                      this.section5Form.get('spouse.add_spouse_income').patchValue('no');
                    }
                  });
                }

   

                setTimeout(() => {
                  $('select').formSelect();
                  $('.modal').modal();
                  $('.tabs').tabs();
                  $('.collapsible').collapsible();
                  M.updateTextFields();
                }, 25);
                //}
              }
            });
          }
        }
      });



      this.model = new IncomeSupportModel();
      this.ApplicationModel = new IncomeSupportAppModel();
      this.childrenModel = new Children();
      this.othersModel = new OtherFamilyMembers();
      this.assetModel = new Asset();
      this.incomeModel = new Income();
    }
  }

  get first_name() { return this.section1Form.get('applicant.first_name'); }
  get last_name() { return this.section1Form.get('applicant.last_name'); }
  get isn() { return this.section1Form.get('applicant.isn'); }
  get province() { return this.section1Form.get('applicant.province'); }
  get address() { return this.section1Form.get('applicant.address'); }
  get dob() { return this.section1Form.get('applicant.dob'); }
  get band_name() { return this.section1Form.get('applicant.band_name'); }
  get marital_status() { return this.section1Form.get('applicant.marital_status'); }
  get postal_code() { return this.section1Form.get('applicant.postal_code'); }
  get SIN() { return this.section1Form.get('applicant.SIN'); }
  get telephone() { return this.section1Form.get('applicant.telephone'); }
  get city() { return this.section1Form.get('applicant.city'); }
  get mailing_address() { return this.section1Form.get('applicant.mailing_address'); }
  //spouse
  get spouse_first_name() { return this.section1Form.get('spouse.spouse_first_name'); }
  get spouse_last_name() { return this.section1Form.get('spouse.spouse_last_name'); }
  get spouse_isn() { return this.section1Form.get('spouse.spouse_isn'); }
  get spouse_dob() { return this.section1Form.get('spouse.spouse_dob'); }
  get spouse_SIN() { return this.section1Form.get('spouse.spouse_SIN'); }
  get spouse_province() { return this.section1Form.get('spouse.spouse_province'); }
  get spouse_city() { return this.section1Form.get('spouse.spouse_city'); }
  get spouse_email() { return this.section1Form.get('spouse.spouse_email'); }
  get spouse_band_name() { return this.section1Form.get('spouse.spouse_band_name'); }
  get spouse_mobile() { return this.section1Form.get('spouse.spouse_mobile') }
  get spouse_health_care_no() { return this.section1Form.get('spouse.spouse_health_care_no') }
  //section 2
  get previous_address() { return this.section2Form.get('previous_address.applicant.address') }
  get address_from() { return this.section2Form.get('previous_address.applicant.address_from'); }
  get address_to() { return this.section2Form.get('previous_address.applicant.address_to'); }
  get spouse_previous_address() { return this.section2Form.get('previous_address.spouse.spouse_address'); }
  get spouse_address_from() { return this.section2Form.get('previous_address.spouse.spouse_address_from'); }
  get spouse_address_to() { return this.section2Form.get('previous_address.spouse.spouse_address_to'); }

  get edu_from() { return this.section2Form.get('recent_education.applicant.edu_from'); }
  get edu_to() { return this.section2Form.get('recent_education.applicant.edu_to'); }
  get spouse_edu_from() { return this.section2Form.get('recent_education.spouse.spouse_edu_from'); }
  get spouse_edu_to() { return this.section2Form.get('recent_education.spouse.spouse_edu_to'); }

  get presently_employed() { return this.section2Form.get('employment.applicant.presently_employed') }
  get employer_name() { return this.section2Form.get('employment.applicant.employer_name'); }
  get employer_address() { return this.section2Form.get('employment.applicant.employer_address'); }
  get employment_position() { return this.section2Form.get('employment.applicant.position') }
  get employment_from() { return this.section2Form.get('employment.applicant.employment_from'); }
  get employment_to() { return this.section2Form.get('employment.applicant.employment_to'); }

  get spouse_presently_employed() { return this.section2Form.get('employment.spouse.spouse_presently_employed') }
  get spouse_employer_name() { return this.section2Form.get('employment.spouse.spouse_employer_name'); }
  get spouse_employer_address() { return this.section2Form.get('employment.spouse.spouse_employer_address') }
  get spouse_employment_position() { return this.section2Form.get('employment.spouse.spouse_position') }
  get spouse_employment_from() { return this.section2Form.get('employment.spouse.spouse_employment_from'); }
  get spouse_employment_to() { return this.section2Form.get('employment.spouse.spouse_employment_to'); }
  //section 31
  get vehicle() { return this.section31Form.get('vehicle'); }
  get vehicle_model() { return this.section31Form.get('model'); }
  get year_to_make() { return this.section31Form.get('year_to_make'); }
  get vehicle_value() { return this.section31Form.get('vehicle_value'); }
  get vehicleControls() { return this.section31Form.get('vehicle') as UntypedFormArray; }

  //section 3
  get asset() { return this.section3Form.get('assets'); }
  get asset_type() { return this.section3Form.get('asset_type'); }
  get asset_amount() { return this.section3Form.get('asset_amount'); }
  get assetControls() { return this.section3Form.get('assets') as UntypedFormArray; }
  //section 4
  get childrens() { return this.section4Form.get('children.childrens'); }
  get child_first_name() { return this.section4Form.get('children.child_first_name'); }
  get child_last_name() { return this.section4Form.get('children.child_last_name'); }
  get child_relation() { return this.section4Form.get('children.child_relation'); }
  get child_band() { return this.section4Form.get('children.child_band'); }
  get child_dob() { return this.section4Form.get('children.child_dob'); }
  get child_ISN() { return this.section4Form.get('children.child_ISN'); }
  get other_first_name() { return this.section4Form.get('other.other_first_name'); }
  get other_last_name() { return this.section4Form.get('other.other_last_name'); }
  get other_dob() { return this.section4Form.get('other.other_dob'); }
  get othersControls() { return this.section4Form.get('other.others') as UntypedFormArray; }
  get childControls() { return this.section4Form.get('children.childrens') as UntypedFormArray; }
  //section 5
  get income_type() { return this.section5Form.get('applicant.income_type'); }
  get income_amount() { return this.section5Form.get('applicant.income_amount'); }
  get income_date() { return this.section5Form.get('applicant.income_date'); }
  get date_of_last_assistance() { return this.section5Form.get('a;pplicant.date_of_last_assistance') }
  get spouse_income_type() { return this.section5Form.get('spouse.spouse_income_type'); }
  get spouse_income_amount() { return this.section5Form.get('spouse.spouse_income_amount'); }
  get spouse_income_date() { return this.section5Form.get('spouse.spouse_income_date'); }
  get incomeControls() { return this.section5Form.get('applicant.incomes') as UntypedFormArray; }
  get spouseIncomeControls() { return this.section5Form.get('spouse.spouse_incomes') as UntypedFormArray; }
  //section 6
  get applicant_date() { return this.section6Form.get('applicant_date'); }
  get spouse_date() { return this.section6Form.get('spouse_date'); }
  //get witness_date() { return this.section6Form.get('witness_date') }
  get documnet_link() { return this.section6Form.get('files.document_link'); }


  isBeforeToday(control: UntypedFormControl) {
    let date = control.value;
    const dateformat = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
    if (date) {
      if (!date.match(dateformat) && date.length > 10) {
        const datePipe = new DatePipe('en-US');
        date = datePipe.transform(date, 'dd/MM/yyyy');
      }
      if (date.match(dateformat)) {
        let dateArray = date.split('/');
        let year = dateArray[2];
        let month = dateArray[1] - 1;
        let day = dateArray[0];
        date = new Date(year, month, day);
        const today = new Date();
        if (date.valueOf() < today.valueOf()) {
          return null;
        } else {
          return { 'invalidDate': true }
        }
      }
    }
    return null;
  }

  setDate(controlName, i, e) {
    if (e.match(this.dateformat)) {
      this.dates[i].selected = e;
      const date = this.changeDates(e);
      const datePipe = new DatePipe('en-US');
      const formatedDate = datePipe.transform(date, 'fullDate');
      controlName.patchValue(formatedDate);
      this.dates[i].set = true;
    }
  }

  unSetDate(controlName, i, date) {
    controlName.patchValue(this.dates[i].selected);
    this.dates[i].set = false;
  }

  getBandListFromProvinceCode(provinceCode) {
    if (this.bands) {
      const bandInProvince = this.bands.filter(band => provinceCode === this.getProvinceCodeFromAddress(band.Address));
      const bandList = [];
      bandInProvince.forEach(b => {
        bandList.push(b.Band);
      });
      this.bandList = bandList;
    }
  }

  getProvinceCodeFromAddress(address: any) {
    let addr = address.substring(address.length - 11);
    addr = addr.substring(0, 2);
    return addr;
  }

 
  onIsnChange(e) {
    const treaty = e.toString().slice(0, 3)
    const treatyArray = treaty.split('');
    if (treatyArray[0] != '_' && treatyArray[1] != '_' && treatyArray[2] != '_') {

      if (!this.hasISN) this.isn.patchValue(treaty);
      this.bandUse = this.bands.filter(b => b.TreatyNo == treaty);
      if (this.bandUse && this.bandUse.length > 0) {
        if (this.bandUse[0].Address) {
          var provinceCode = this.getProvinceCodeFromAddress(this.bandUse[0].Address);
          this.province.patchValue(provinceCode);
          this.getBandListFromProvinceCode(provinceCode);
        }
        this.bandName = this.bandUse[0].Band;
        this.band_name.patchValue(this.bandName);
        if (this.showSpouseInfo) {
          this.spouse_isn.patchValue(treaty);
          this.spouse_band_name.patchValue(this.bandName);
        }
      } else {
        this.province.patchValue('');
        this.band_name.patchValue('');
        this.spouse_band_name.patchValue('');
      }

      setTimeout(() => {
        $('select').formSelect();
        M.updateTextFields();
      }, 25);
    }
  }

  onSpouseIsnChange(e) {
    const isn = e.toString().slice(0, 3)
    const isnArray = isn.split('');
    if (isnArray[0] != '_' && isnArray[1] != '_' && isnArray[2] != '_') {
      const bandUse = this.bands.filter(b => b.TreatyNo == isn);
      if (bandUse && bandUse.length > 0) {
        const bandName = bandUse[0].Band;
        this.spouse_band_name.patchValue(bandName)
      } else {
        this.spouse_band_name.patchValue('');
      }
      setTimeout(() => {
        $('select').formSelect();
        M.updateTextFields();
      }, 25);
    }

  }

  onChildIsnChange(e) {
    const isn = e.toString().slice(0, 3)
    const isnArray = isn.split('');
    if (isnArray[0] != '_' && isnArray[1] != '_' && isnArray[2] != '_') {
      const bandUse = this.bands.filter(b => b.TreatyNo == isn);
      if (bandUse && bandUse.length > 0) {
        const bandName = bandUse[0].Band;
        this.section4Form.patchValue({ children: { 'child_band': bandName } });
      } else {
        this.section4Form.patchValue({ children: { 'child_band': '' } });
      }
      setTimeout(() => {
        $('select').formSelect();
        M.updateTextFields();
      }, 25);
    }
  }

  //radio button value changes
  onSameMailingAddressYes() {
    this.showMailingAddress = false;
    const homeAddress = this.address.value;
    this.mailing_address.patchValue(homeAddress);
  }

  onSameMailingAddressNo() {
    this.mailing_address.reset();
    this.showMailingAddress = true;
  }

  onMovedYes() {
    this.moved = true;
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }

  onMovedNo() {
    this.moved = false;
  }



  onPresentlyEmployedYes() {
    this.showEmployemnt = true;
    this.presentlyEmployed = true;
    this.section2Form.get('employment.applicant.employer_name').reset();
    // this.section2Form.get('employment.applicant.employer_contact_name').reset();
    this.section2Form.get('employment.applicant.employer_address').reset();
    this.section2Form.get('employment.applicant.position').reset();
    this.employment_from.reset();
    this.employment_to.reset();
    setTimeout(() => {
      M.updateTextFields();
    }, 25);
  }

  onPresentlyEmployedNo() {
    this.showEmployemnt = true;
    this.presentlyEmployed = false;
    this.section2Form.get('employment.applicant.employer_name').reset();
    // this.section2Form.get('employment.applicant.employer_contact_name').reset();
    this.section2Form.get('employment.applicant.employer_address').reset();
    this.section2Form.get('employment.applicant.position').reset();
    this.employment_from.reset();
    this.employment_to.reset();
    setTimeout(() => {
      M.updateTextFields();
    }, 25);
  }

  onSpousePresentlyEmployedYes() {
    this.showSpouseEmployemnt = true;
    this.spousePresentlyEmployed = true;
    setTimeout(() => {
      M.updateTextFields();
    }, 25);
  }

  onSpousePresentlyEmployedNo() {
    this.showSpouseEmployemnt = true;
    this.spousePresentlyEmployed = false;
    setTimeout(() => {
      M.updateTextFields();
    }, 25);
  }

  onAgreeYes() {
    this.agree = true;
  }

  onAgreeNo() {
    this.agree = false;
  }




  addSpouse(control, value) {
    if (control == 'firstName') {
      this.spouseFirstName = value;
    } else if (control == 'lastName') {
      this.spouseLastName = value;
    }
    if (this.spouseFirstName && this.spouseLastName) {
      this.findMemberModel = new FindMemberModel();
      this.findMemberModel.firstName = this.spouse_first_name.value.trim();
      this.findMemberModel.lastName = this.spouse_last_name.value.trim();
      this.memberID = 0;
      this.memberService.findFamilyMemberByName(this.findMemberModel).subscribe(x => {
       
        if (x) {
          let spouse = x[0];
          this.spouseID = spouse.applicantID;
         
          this.memberService.getMember(this.spouseID).subscribe(spouse => {
            if (spouse) {
              
              this.spouseAdded = true;
              this.spouse = spouse[0];
              this.spouse_name = this.spouse.GivenName + ' ' + this.spouse.LastName;
              this.spouseAdded = true;
              this.section1Form.get('spouse.spouse_first_name').patchValue(this.spouse.GivenName);
              this.section1Form.get('spouse.spouse_first_name').disable();
              this.section1Form.get('spouse.spouse_last_name').patchValue(this.spouse.LastName);
              this.section1Form.get('spouse.spouse_last_name').disable();
              this.section1Form.get('spouse.spouse_isn').patchValue(this.spouse.IndianStatusNo);
              this.section1Form.get('spouse.spouse_isn').disable();
              this.section1Form.get('spouse.spouse_band_name').patchValue(this.spouse.Band);
              this.section1Form.get('spouse.spouse_email').patchValue(this.spouse.Email);
              this.section1Form.get('spouse.spouse_mobile').patchValue(this.spouse.cell_phone_num);
              this.section1Form.get('spouse.spouse_health_care_no').patchValue(this.spouse.HealthCareNo);
              var array = this.currentUser.DOB.split("-");
              var spouseDob = `${array[2].substring(0, 2)}/${array[1]}/${array[0]}`;
              this.section1Form.get('spouse.spouse_dob').patchValue(spouseDob);
              this.section1Form.get('spouse.spouse_SIN').patchValue(this.spouse.SIN);
              this.section1Form.get('spouse.spouse_city').patchValue(this.spouse.city);
              this.section1Form.get('spouse.spouse_province').patchValue(this.spouse.province);

              setTimeout(() => {
                $('select').formSelect();
                $('.modal').modal();
                $('.tabs').tabs();
                $('.collapsible').collapsible();
                M.updateTextFields();
              }, 25);
            }
          });
        }
      });
    }
  }

  // ADD vechiles
  onAddVehicleYes() {
    this.addVehicle = true;
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
    this.emptyVehicle = false;
    this.section31Form.get('model').reset();
    this.section31Form.get('year_to_make').reset();
    this.section31Form.get('vehicle_value').reset();
  }
  onAddVehicleNo() {
    this.addVehicle = false;
    const cars = this.vehicleControls;
    while (cars.length !== 0) {
      cars.removeAt(0);
    }
    this.emptyVehicle = true;
    this.displayVehicle = false;
    cars.setValue([]);
  }
  newVehicle(values: any) {
    return this.formBuilder.group({
      model: [values.model],
      year_to_make: [values.year_to_make],
      vehicle_value: [values.vehicle_value],
    });
  }
  addNewVehicle(values: any) {
    const vehicle = this.vehicleControls;
    vehicle.push(this.newVehicle(values));
    this.emptyVehicle = false;
    this.addVehicle = false;
    this.displayVehicle = true;
    if (vehicle.length == 2) { this.HasTwoVehicle = true; }

    //toast({ html: 'Vehicle Successfully Added!', classes: 'green' });
  }
  addAnotherVehicle() {
    //this.vehicleSelected= false;
    this.addVehicle = true;
    this.section31Form.get('model').reset();
    this.section31Form.get('year_to_make').reset();
    this.section31Form.get('vehicle_value').reset();
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }
  onDeleteVehicle(index) {
    const vehicle = this.vehicleControls;
    vehicle.removeAt(index);
    if (vehicle.length == 0) {
      this.addVehicle = false;
      this.displayVehicle = false;
      this.emptyVehicle = true;
      this.section31Form.patchValue({ 'add_vehicle': 'no' });
    }
    this.HasTwoVehicle = false;
    toast({ html: 'Vehicle deleted!', classes: 'red' });
  }
  closeAddVehicle() {
    this.addVehicle = false;
    this.section31Form.get('model').clearValidators();
    this.section31Form.get('year_to_make').clearValidators();
    this.section31Form.get('vehicle_value').clearValidators();
    this.section31Form.get('model').updateValueAndValidity();
    this.section31Form.get('year_to_make').updateValueAndValidity();
    this.section31Form.get('vehicle_value').updateValueAndValidity();
    if (this.vehicleControls.length == 0) {
      this.section31Form.patchValue({ 'add_vehicle': 'no' });
    }
  }
  setCarTwoNumberDecimal(value: any) {
    let amount = parseFloat(value).toFixed(2);
    this.section31Form.controls['vehicle_value'].patchValue(amount);
    //this.section31Form.get('vehicle_value').setValue(amount) 
  }

  //Add Assets
  onAddAssetsYes() {
    this.addAssets = true;
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
    this.emptyAssets = false;
    this.section3Form.get('asset_type').reset();
    this.section3Form.get('asset_amount').reset();
  }
  onAddAssetsNo() {
    this.addAssets = false;
    const assets = this.assetControls;
    while (assets.length !== 0) {
      assets.removeAt(0);
    }
    this.emptyAssets = true;
    this.displayAssets = false;
    assets.setValue([]);
  }
  newAsset(values: any) {
    return this.formBuilder.group({
      asset: [values.asset_type],
      amount: [values.asset_amount],
    })
  }
  addNewAssets(values: any) {
    const assets = this.assetControls;
    assets.push(this.newAsset(values));
    this.emptyAssets = false;
    this.addAssets = false;
    this.displayAssets = true;
    //toast({ html: 'Asset Successfully Added!', classes: 'green' });
  }
  addAnotherAsset() {
    this.assetSelected = false;
    this.addAssets = true;
    this.section3Form.get('asset_type').reset();
    this.section3Form.get('asset_amount').reset();
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }
  onDeleteAssets(index) {
    const asset = this.assetControls;
    asset.removeAt(index);
    if (asset.length == 0) {
      this.addAssets = false;
      this.displayAssets = false;
      this.emptyAssets = true;
      this.section3Form.patchValue({ 'add_assets': 'no' });
    }
    toast({ html: 'Asset deleted!', classes: 'red' });
  }
  closeAddAsset() {
    this.addAssets = false;
    this.section3Form.get('asset_type').clearValidators();
    this.section3Form.get('asset_amount').clearValidators();
    this.section3Form.get('asset_type').updateValueAndValidity();
    this.section3Form.get('asset_amount').updateValueAndValidity();
    if (this.assetControls.length == 0) {
      this.section3Form.patchValue({ 'add_assets': 'no' });
    }
  }

  //Add Children
  onAddChildrenYes() {
    this.addChildren = true;
    setTimeout(() => {
      $('.collapsible').collapsible();
      $('select').formSelect();
      M.updateTextFields();
    }, 25);

    //this.emptyChildren = false;
    this.section4Form.get('children.child_first_name').reset();
    this.section4Form.get('children.child_last_name').reset();
    this.section4Form.get('children.child_dob').reset();
    this.section4Form.get('children.child_relationship').reset();
    this.section4Form.get('children.child_edu_level').reset();
   
  }
  onAddChildrenNo() {
    this.addChildren = false;
    const children = this.childControls;
    while (children.length !== 0) {
      children.removeAt(0);
    }
    this.emptyChildren = true;
    this.displayChildren = false;
    children.setValue([]);
  }

  addChild(values: any): void {
    
    const children = this.childControls;
    this.findMemberModel = new FindMemberModel();
    this.findMemberModel.firstName = values.child_first_name.trim();
    this.findMemberModel.lastName = values.child_last_name.trim();
    this.findMemberModel.dob = '';
    

    this.memberID = 0;
    this.memberService.findFamilyMemberByName(this.findMemberModel).subscribe(x => {
      
      if (x) {
        let child = x[0];
        this.memberID = child.applicantID;
        let tempFormGroup = this.formBuilder.group({
          given_name: [values.child_first_name.trim()],
          last_name: [values.child_last_name.trim()],
          dob: [values.child_dob],
          Relationship: [values.child_relationship],
          edu_level: [values.child_edu_level],
          applicantIDOfHead: this.applicantID,
          applicantIDOfChild: this.memberID,
        });
        children.push(tempFormGroup);
        
        this.emptyChildren = false;
        this.displayChildren = true;
        this.addChildren = false;
        toast({ html: 'Child Successfully Added!', classes: 'green' });
        setTimeout(() => {
          $('.collapsible').collapsible();
          $('select').formSelect();
        }, 25);
      } else {
        toast({ html: '<p>This member does not exist on the system!. Please Check the infomation you entered.</p> ', classes: 'red' });
        return;
      }
    });
    
  }
  addAnotherChild() {
    this.addChildren = true;
    this.section4Form.get('children.child_first_name').reset();
    this.section4Form.get('children.child_last_name').reset();
    this.section4Form.get('children.child_dob').reset();
    this.section4Form.get('children.child_relationship').reset();
    this.section4Form.get('children.child_edu_level').reset();
    this.dates[18].set = false;
    this.dates[18].selected = '';

    // this.section4Form.get('children.child_health_care_no').reset();
    // this.section4Form.get('children.child_band').reset();
    // this.section4Form.get('children.child_ISN').reset();
    // this.section4Form.get('children.child_school').reset();

    setTimeout(() => {
      $('.collapsible').collapsible();
      $('select').formSelect();
      M.updateTextFields();
    }, 25);

  }
  onDeleteChildern(index) {
    const child = this.childControls;
    child.removeAt(index);
    if (child.length == 0) {
      this.addChildren = false;
      this.displayChildren = false;
      this.emptyChildren = true;
      this.section4Form.patchValue({ 'children': { 'add_children': 'no' } });
    }
    toast({ html: 'Child deleted!', classes: 'red' });
  }
  closeAddChild() {
    this.addChildren = false;
    this.section4Form.get('children.child_first_name').clearValidators();
    this.section4Form.get('children.child_first_name').updateValueAndValidity();
    this.section4Form.get('children.child_last_name').clearValidators();
    this.section4Form.get('children.child_last_name').updateValueAndValidity();
    this.section4Form.get('children.child_dob').clearValidators();
    this.section4Form.get('children.child_dob').updateValueAndValidity();
    this.section4Form.get('children.child_relationship').clearValidators();
    this.section4Form.get('children.child_relationship').updateValueAndValidity();
    this.section4Form.get('children.child_edu_level').clearValidators();
    this.section4Form.get('children.child_edu_level').updateValueAndValidity();
    if (this.childControls.length == 0) {
      this.section4Form.patchValue({ 'children': { 'add_children': 'no' } });
    }
  }

  //Add Others
  onAddOthersYes() {
    this.addOtherMemebers = true;
    this.emptyOthers = false;
    this.section4Form.get('other.other_first_name').reset();
    this.section4Form.get('other.other_last_name').reset();
    this.section4Form.get('other.relationship').reset();
    this.section4Form.get('other.other_dob').reset();

    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }
  onAddOthersNo() {
    this.addOtherMemebers = false;
    const others = this.othersControls;
    while (others.length !== 0) {
      others.removeAt(0);
    }
    this.emptyOthers = true;
    this.displayOthers = false;
    others.setValue([]);
  }
  // newOthers(values: any): FormGroup {
  //   var rel = values.relationship == 'Other' ? values.other_relationship : values.relationship;
  //   if (rel == null) {
  //     rel = '';
  //   }
  //   return this.formBuilder.group({
  //     given_name: [values.other_first_name],
  //     last_name: [values.other_last_name],
  //     relationship: [rel],
  //   });
  // }
  addOthers(values: any): void {
    const others = this.othersControls;
    var relationship = values.relationship == 'Other' ? values.other_relationship : values.relationship;
    if (relationship == null) {
      relationship = '';
    }
    this.findMemberModel = new FindMemberModel();
    this.findMemberModel.firstName = values.other_first_name.trim();
    this.findMemberModel.lastName = values.other_last_name.trim();
    //this.findMemberModel.dob = this.formatDate2(values.other_dob);  
    this.findMemberModel.dob = '';
   
    this.memberID = 0;
    this.memberService.findFamilyMemberByName(this.findMemberModel).subscribe(x => {
      if (x) {
        let other = x[0];
        this.memberID = other.applicantID;

        let tempFormGroup = this.formBuilder.group({
          given_name: values.other_first_name.trim(),
          last_name: values.other_last_name.trim(),
          dob: [values.other_dob],
          Relationship: [relationship],
          applicantIDOfHead: this.applicantID,
          applicantIDOfPerson: this.memberID,
          contribution: values.contribution,
        });
      
        others.push(tempFormGroup);
        this.emptyOthers = false;
        this.displayOthers = true;
        this.addOtherMemebers = false;
        setTimeout(() => {
          $('.collapsible').collapsible();
          $('select').formSelect();
        }, 25);
        toast({ html: 'Other Members Successfully Added!', classes: 'green' });

      } else {
        toast({ html: '<p>This person does not exist on the system !</p> ', classes: 'red' });
        return;
      }
    });
    
  }
  addAnotherOther() {
    this.addOtherMemebers = true;
    this.section4Form.get('other.other_first_name').reset();
    this.section4Form.get('other.other_last_name').reset();
    this.section4Form.get('other.other_dob').reset();
    this.section4Form.get('other.relationship').reset();
    setTimeout(() => {
      M.updateTextFields();
      $('select').formSelect();
    }, 25);
  }
  onDeleteOthers(index) {
    const others = this.othersControls;
    others.removeAt(index);
    if (others.length == 0) {
      this.addOtherMemebers = false;
      this.displayOthers = false;
      this.emptyOthers = true;
      this.section4Form.patchValue({ 'other': { 'add_others': 'no' } });
    }
    toast({ html: 'Other Member deleted!', classes: 'red' });
  }
  closeAddOther() {
    this.addOtherMemebers = false;
    this.section4Form.get('other.other_first_name').clearValidators();
    this.section4Form.get('other.other_first_name').updateValueAndValidity();
    this.section4Form.get('other.other_last_name').clearValidators();
    this.section4Form.get('other.other_last_name').updateValueAndValidity();
    this.section4Form.get('other.other_dob').clearValidators();
    this.section4Form.get('other.other_dob').updateValueAndValidity();
    if (this.othersControls.length == 0) {
      this.section4Form.patchValue({ 'other': { 'add_others': 'no' } });
    }
  }

  //Add Incomes
  onAddIncomesYes() {
    this.addIncomes = true;
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
    this.emptyIncomes = false;
    this.section5Form.get('applicant.income_type').reset();
    this.section5Form.get('applicant.income_amount').reset();
    this.section5Form.get('applicant.income_date').reset();
  }
  onAddIncomesNo() {
    this.addIncomes = false;
    const incomes = this.incomeControls;
    while (incomes.length !== 0) {
      incomes.removeAt(0);
    }
    this.emptyIncomes = true;
    this.displayIncomes = false;
    incomes.setValue([]);
  }
  newIncome(values: any) {
    return this.formBuilder.group({
      income: [values.income_type],
      amount: [values.income_amount],
      date_recevied: [values.income_date]
    });
  }
  addNewIncome(Values: any) {
    const income = this.incomeControls;
    income.push(this.newIncome(Values));
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
    this.emptyIncomes = false;
    this.addIncomes = false;
    this.displayIncomes = true;
    toast({ html: 'Income Successfully Added!', classes: 'green' });
  }
  addAnotherIncome() {
    this.incomeSelected = false;
    this.addIncomes = true;
    this.section5Form.get('applicant.income_type').reset();
    this.section5Form.get('applicant.income_amount').reset();
    this.section5Form.get('applicant.income_date').reset();
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);

  }
  OnDeleteIncome(index) {
    const income = this.incomeControls;
    income.removeAt(index);
    if (income.length == 0) {
      this.addIncomes = false;
      this.displayIncomes = false;
      this.emptyIncomes = true;
      this.section5Form.patchValue({ 'applicant': { 'add_income': 'no' } });
    }
    toast({ html: 'Income deleted!', classes: 'red' });
  }
  closeAddIncome() {
    this.addIncomes = false;
    this.section5Form.get('applicant.income_type').clearValidators();
    this.section5Form.get('applicant.income_amount').clearValidators();
    this.section5Form.get('applicant.income_type').updateValueAndValidity();
    this.section5Form.get('applicant.income_amount').updateValueAndValidity();
    if (this.incomeControls.length == 0) {
      this.section5Form.patchValue({ 'applicant': { 'add_income': 'no' } });
    }
  }

  //Add Spouse Income
  onAddSpouseIncomeYes() {
    this.addSpouseIncomes = true;
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
    this.emptySpouseIncomes = false;
    this.section5Form.get('spouse.spouse_income_type').reset();
    this.section5Form.get('spouse.spouse_income_amount').reset();
    this.section5Form.get('spouse.spouse_income_date').reset();

  }
  onAddSpouseIncomeNo() {
    this.addSpouseIncomes = false;
    const incomes = this.spouseIncomeControls;
    while (incomes.length !== 0) {
      incomes.removeAt(0);
    }
    this.emptySpouseIncomes = true;
    this.displaySpouseIncomes = false;
    incomes.setValue([]);

  }
  newSpouseIncome(values: any) {
    return this.formBuilder.group({
      income: [values.spouse_income_type],
      amount: [values.spouse_income_amount],
      date_recevied: [values.spouse_income_date]
    })
  }
  addNewSpouseIncome(values: any) {
    this.emptySpouseIncomes = false;
    const income = this.spouseIncomeControls;
    income.push(this.newSpouseIncome(values));
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
    this.addSpouseIncomes = false;
    this.displaySpouseIncomes = true;
    toast({ html: 'Income Successfully Added!', classes: 'green' });
  }
  addAnotherSpouseIncome() {
    this.spouseIncomeSelected = false;
    this.addSpouseIncomes = true;
    this.section5Form.get('spouse.spouse_income_type').reset();
    this.section5Form.get('spouse.spouse_income_amount').reset();
    this.section5Form.get('spouse.spouse_income_date').reset();
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }
  OnDeleteSpouseIncome(index) {
    const income = this.spouseIncomeControls;
    income.removeAt(index);
    if (income.length == 0) {
      this.addSpouseIncomes = false;
      this.displaySpouseIncomes = false;
      this.emptySpouseIncomes = true;
      this.section5Form.patchValue({ 'spouse': { 'add_spouse_income': 'no' } });
    }
    toast({ html: 'Income deleted!', classes: 'red' });
  }
  closeAddSpouseIncome() {
    this.addSpouseIncomes = false;
    this.section5Form.get('spouse.spouse_income_type').clearValidators();
    this.section5Form.get('spouse.spouse_income_amount').clearValidators();
    this.section5Form.get('spouse.spouse_income_type').updateValueAndValidity();
    this.section5Form.get('spouse.spouse_income_amount').updateValueAndValidity();
    if (this.spouseIncomeControls.length == 0) {
      this.section5Form.patchValue({ 'spouse': { 'add_spouse_income': 'no' } });
    }
  }

  //on Selects changed
  maritalStatusChange(value: any) {
    this.maritalStatusSelected = true;
    if (value == 'Married' || value == "Common Law") {
      this.showSpouseInfo = true;
      this.hasSpouse = true;
      if (this.province.value) {
        this.spouse_province.patchValue(this.province.value);
      }
      // if (this.city.value) {
      //   this.spouse_city.patchValue(this.city.value);
      // }
    } else {
      this.showSpouseInfo = false;
      this.spouseAdded = false;
      this.spouse = null;
      this.hasSpouse = false;
    }
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }

  provinceChange(value: any) {
    this.getBandListFromProvinceCode(value);
    this.section1Form.get('applicant.band_name').updateValueAndValidity();
    if (this.showSpouseInfo) {
      this.spouse_province.patchValue(value);
    }
    this.spouse_province.patchValue(value);
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }

  cityChanged(value) {
    if (this.showSpouseInfo) {
      this.spouse_city.patchValue(value)
    }
    setTimeout(() => {
      M.updateTextFields();
    }, 25);
  }

  bandChange(value: any) {
    this.child_band.patchValue(value);
  }

  spouseBandChange() {

  }

  childBandChange(value: any) {
  }

  onRelationChange(value: any) {
    if (value == 'Other') {
      this.showOtherRelation = true;
      this.section4Form.get('other.other_relationship').reset();
    } else {
      this.showOtherRelation = false;
    }
  }

  onAssetSelected(value: any) {
    this.assetSelected = true;
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }

  onIncomeSelected(value: any) {
    this.incomeSelected = true;
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }

  onSpouseIncomeSelected(value: any) {
    this.spouseIncomeSelected = true;
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }

  tabSelected(tab) {
    this.step2 = tab;
  }

  onNext2() {
    if (this.step2 == 1) {
      $('.tabs').tabs('select', 'recent_education');
      this.step2 = 2;
    } else if (this.step2 == 2) {
      $('.tabs').tabs('select', 'employment');
      this.step2 = 3;
    }
  }

  onBack2() {
    if (this.step2 == 2) {
      $('.tabs').tabs('select', 'previous_address');
      this.step2 = 1;
    } if (this.step2 == 3) {
      $('.tabs').tabs('select', 'recent_education');
      this.step2 = 2;
    }
  }

  //navigations
  onNext() {
    if (this.step == 1 && !this.showSpouseInfo) {
      this.step += 1;
    }
    if (this.step == 8 && !this.showSpouseInfo) {
      this.step += 1;
    }
    this.step += 1;
    setTimeout(() => {
      $('select').formSelect();
      $('.modal').modal();
      $('.tabs').tabs();
      $('.collapsible').collapsible();
      $('.tooltipped').tooltip();
      M.updateTextFields();
    }, 25);
  }

  onBack() {
    if (this.step == 10 && !this.showSpouseInfo) {
      this.step -= 1;
    }
    if (this.step == 3 && !this.showSpouseInfo) {
      this.step -= 1;
    }
    this.step -= 1;
    setTimeout(() => {
      $('select').formSelect();
      $('.modal').modal();
      $('.tabs').tabs();
      $('.collapsible').collapsible();
      M.updateTextFields();
      $('.tooltipped').tooltip();
    }, 25);
  }

  changeDates(dateValue) {
    if (dateValue) {
      if (dateValue.match(this.dateformat)) {
        let dateArray = dateValue.split('/');
        let year = dateArray[2];
        let month = dateArray[1] - 1;
        let day = dateArray[0];
        const date = new Date(year, month, day);
       
        return date;
      } else {
        const date = new Date(dateValue);
        return date;
      }
    } else {
      return null
    }
  }

  //submit applicant and spouse info
  submitSection1(values: any, content: any) {
   
    if (content == "applicant") {
      this.memberProfile = {
        applicantID: this.applicantID,
        // Band: value.band,
        TreatyNo: values.treaty_no,
        MaritalStatus: values.marital_status,
        MailAddress: values.mailing_address,
        city: values.city,
        province: values.province,
        PostalCode: values.postal_code.toUpperCase(),
        HouseNumber: values.house_no,
        Telephone: values.telephone,
        Fax: this.currentUser.Fax,   // value.fax,
        cell_phone_num: values.mobile, //value.cell,
        HealthCareNo: values.health_care_no,
        SIN: values.SIN,
        Email: this.currentUser.Email,
        spouse_aid: this.spouseID ? this.spouseID : 0,
      }
      
      if (this.applicantID) this.ApplicationModel.applicantID = this.applicantID;
      this.ApplicationModel.firstName = values.first_name;
      this.ApplicationModel.lastName = values.last_name;
      this.ApplicationModel.isn = values.isn;
      this.ApplicationModel.bandName = values.band_name;
      this.ApplicationModel.maritalStatus = values.marital_status;
      this.ApplicationModel.TreatyNo = values.treaty_no;
      this.ApplicationModel.province = values.province;
      this.ApplicationModel.address = values.address;
      this.ApplicationModel.postalCode = values.postal_code.toUpperCase();
      this.ApplicationModel.telephone = values.telephone;
      this.ApplicationModel.healthCareNo = values.health_care_no;
      this.ApplicationModel.dob = this.changeDates(values.dob).toDateString();
      this.ApplicationModel.SIN = values.SIN;
      this.ApplicationModel.EDU_LEVEL = values.edu_level;
      //this.model.mailingAddress = values.mailing_address;
      //this.model.houseNo = values.house_no;
      //this.model.mobile = values.mobile;
    }

    if (this.hasSpouse && (!this.spouse || !this.spouseAdded)) {
      toast({ html: 'Please Add Spouse !', classes: 'red' });

      this.step = 2;
      setTimeout(() => {
        $('select').formSelect();
        $('.modal').modal();
        $('.tabs').tabs();
        $('.collapsible').collapsible();
        $('.tooltipped').tooltip();
        M.updateTextFields();
      }, 25);
      return;
    }
    else if (content == 'spouse') {



      if (this.spouse) {
        this.memberProfile.spouse_aid = this.spouseID;
      
        let tmpFormGroup = {
          given_name: this.spouse.GivenName,
          last_name: this.spouse.LastName,
          dob: this.changeDates(values.spouse_dob).toDateString(),
          Relationship: "Spouse",
          edu_level: this.EduLevelList[Number(this.spouse.edu_level)],
          applicantIDOfHead: this.applicantID,
          applicantIDOfChild: this.spouseID
        };
        this.tmpSpouseModel = tmpFormGroup;
        // this.section4Form.get('children.add_children').patchValue('yes');
        // this.emptyChildren = false;
        // this.displayChildren = true;
      }

      this.ApplicationModel.spouse_aid = this.spouseID;
      this.ApplicationModel.spouseFirstName = this.spouse.GivenName;
      this.ApplicationModel.spouseLastName = this.spouse.LastName;
      this.ApplicationModel.spouseDob = this.changeDates(values.spouse_dob).toDateString();
      //this.model.spouseDob = this.changeDates(values.spouse_dob);
      this.ApplicationModel.spouseISN = this.spouse.IndianStatusNo;
      this.ApplicationModel.spouseHealtCareNo = values.spouse_health_care_no;
      this.ApplicationModel.spouseSIN = values.spouse_SIN;
      this.ApplicationModel.spouseProvince = values.spouse_province;

      
    };
   
    this.onNext();
  }

  submitSection2(values: any) {
    //Applicant Previous Address
    if (this.moved) {
      this.ApplicationModel.previous_address = values.previous_address.applicant.address;
      if (values.previous_address.applicant.address_from) {
        this.ApplicationModel.previous_address_startDate = this.changeDates(values.previous_address.applicant.address_from).toDateString();
      } else {
        this.ApplicationModel.previous_address_startDate = "";
      }
      if (values.previous_address.applicant.address_to) {
        this.ApplicationModel.previous_address_endDate = this.changeDates(values.previous_address.applicant.address_to).toDateString();
      } else {
        this.ApplicationModel.previous_address_endDate = "";
      }
    } else {
      this.ApplicationModel.previous_address = "";
      this.ApplicationModel.previous_address_startDate = "";
      this.ApplicationModel.previous_address_endDate = "";
    }

    //Applicant recent Education
    this.ApplicationModel.education = values.recent_education.applicant.edu_name;
    if (values.recent_education.applicant.edu_from) {
      this.ApplicationModel.education_startDate = this.changeDates(values.recent_education.applicant.edu_from).toDateString();
    } else {
      this.ApplicationModel.education_startDate = "";
    }
    if (values.recent_education.applicant.edu_to) {
      this.ApplicationModel.education_endDate = this.changeDates(values.recent_education.applicant.edu_to).toDateString();
    } else {
      this.ApplicationModel.education_endDate = "";
    }

    //Spouse recent Education
    this.ApplicationModel.education_spouse = values.recent_education.spouse.spouse_edu_name;
    if (values.recent_education.spouse.spouse_edu_from) {
      this.ApplicationModel.education_startDate_spouse = this.changeDates(values.recent_education.spouse.spouse_edu_from).toDateString();
    } else {
      this.ApplicationModel.education_startDate_spouse = "";
    }
    if (values.recent_education.spouse.spouse_edu_to) {
      this.ApplicationModel.education_endDate_spouse = this.changeDates(values.recent_education.spouse.spouse_edu_to).toDateString();
    } else {
      this.ApplicationModel.education_endDate_spouse = "";
    }

    //Applicant Employment
    if (values.employment.applicant.presently_employed == 'yes') {
      this.ApplicationModel.present_employer = values.employment.applicant.employer_name;
      this.ApplicationModel.present_employment_address = values.employment.applicant.employer_address;
      this.ApplicationModel.present_employment_position = values.employment.applicant.position;
      if (values.employment.applicant.employment_from) {
        this.ApplicationModel.present_employment_startDate = this.changeDates(values.employment.applicant.employment_from);
      }
      if (values.employment.spouse.spouse_employment_to) {
        this.ApplicationModel.present_employment_endDate = this.changeDates(values.employment.applicant.employment_to);
      }
      this.ApplicationModel.present_employment_iscurrent = true;
    } else {
      this.ApplicationModel.previous_employer = values.employment.applicant.employer_name;
      this.ApplicationModel.previous_employment_address = values.employment.applicant.employer_address;
      this.ApplicationModel.previous_employment_position = values.employment.applicant.position;
      if (values.employment.applicant.employment_from) {
        this.ApplicationModel.previous_employment_startDate = this.changeDates(values.employment.applicant.employment_from);
      }
      if (values.employment.applicant.employment_to) {
        this.ApplicationModel.previous_employment_endDate = this.changeDates(values.employment.applicant.employment_to);
      }
    }

    //Spouse Employment
    if (values.employment.spouse.spouse_presently_employed == 'yes') {
      this.ApplicationModel.present_employer_spouse = values.employment.applicant.spouse_employer_name;
      this.ApplicationModel.present_employment_address_spouse = values.employment.spouse.spouse_employer_address;
      this.ApplicationModel.present_employment_position_spouse = values.employment.spouse.spouse_position;
      if (values.employment.spouse.spouse_employment_from) {
        this.ApplicationModel.present_employment_startDate_spouse = this.changeDates(values.employment.spouse.spouse_employment_from);
      }
      if (values.employment.spouse.spouse_employment_to) {
        this.ApplicationModel.present_employment_endDate_spouse = this.changeDates(values.employment.spouse.spouse_employment_to);
      }
      this.ApplicationModel.present_employment_iscurrent_spouse = true;
    } else {
      this.ApplicationModel.previous_employer_spouse = values.employment.spouse.spouse_employer_name;
      this.ApplicationModel.previous_employment_address_spouse = values.employment.spouse.spouse_employer_address;
      this.ApplicationModel.previous_employment_position_spouse = values.employment.spouse.spouse_position;
      if (values.employment.spouse.spouse_employment_from) {
        this.ApplicationModel.previous_employment_startDate_spouse = this.changeDates(values.employment.spouse.spouse_employment_from);
      }
      if (values.employment.spouse.spouse_employment_to) {
        this.ApplicationModel.previous_employment_endDate_spouse = this.changeDates(values.employment.spouse.spouse_employment_to);
      }
    }



    this.onNext();
  }

  submitSection3(values: any) {
    this.tmpAssetsModel = values.assets;
    this.onNext();
  }

  submitSection31(values: any) {
    this.ApplicationModel.car1_make = '';
    this.ApplicationModel.car2_make = '';
    this.ApplicationModel.car1_year = '';
    this.ApplicationModel.car2_year = '';
    this.ApplicationModel.car1_value = 0;
    this.ApplicationModel.car2_value = 0;


    const vehicleList = values.vehicle;
    if (vehicleList.length > 0) {
      this.ApplicationModel.car1_make = vehicleList[0].model;
      this.ApplicationModel.car1_year = vehicleList[0].year_to_make;
      this.ApplicationModel.car1_value = vehicleList[0].vehicle_value;
    }

    if (vehicleList.length > 1) {
      this.ApplicationModel.car2_make = vehicleList[1].model;
      this.ApplicationModel.car2_year = vehicleList[1].year_to_make;
      this.ApplicationModel.car2_value = vehicleList[1].vehicle_value;
    }
   
    this.onNext();
  }

  //submit children and other family Members Info
  submitSection4(values: any, content: string) {
    
    if (content == "children") {
      values.childrens.forEach(child => {
        if(child.dob) child.dob = this.changeDates(child.dob).toDateString();
      });
      this.tmpMemberModel = values.childrens;
      if (this.tmpSpouseModel && this.tmpSpouseModel.length > 0) {
        this.tmpMemberModel.push(this.tmpSpouseModel);
      }

      //this.model.children = values.childrens;
    }
    else if (content == 'others') {
      values.others.forEach(other => {
        if (other.dob) other.dob = this.changeDates(other.dob).toDateString();
      });
     
    }
    this.onNext();
  }

  submitSection5(values: any, content: string) {
  
    if (content == 'applicant') {
      values.incomes.forEach(income => {
        if (income.date_recevied) income.date_recevied = this.changeDates(income.date_recevied).toDateString();
      });
      this.applicantIncomeModel = values.incomes;


    }
    else if (content == 'spouse') {
      values.spouse_incomes.forEach(income => {
        if (income.date_recevied) income.date_recevied = this.changeDates(income.date_recevied).toDateString();
      });
      this.spouseIncomeModel = values.spouse_incomes;

     
    }
    this.onNext();
  }

  submitSection61(values: any) {
    this.ApplicationModel.isYesOfOthers = values.house_shared;
    this.ApplicationModel.WhoOfOthers = values.landlord;
    this.ApplicationModel.relationship_headofhouse = values.relation;
    this.ApplicationModel.isRentedOfOthers = values.ownership;
    this.ApplicationModel.isYesOfCMHC = values.cmhc_assisted;
    this.ApplicationModel.LoanPMTOfOthers = Number(values.rent);
    this.ApplicationModel.MaintenanceOfOthers = Number(values.maintenance);
    this.ApplicationModel.GarbageOfOthers = Number(values.garbage);
    this.ApplicationModel.FuelOfOthers = Number(values.fuel);
    this.ApplicationModel.UtilitiesOfOthers = Number(values.utilities);
    this.ApplicationModel.comments = values.comments;

    
    this.onNext();
  }

  submitSection6(values: any) {

    if (!values.applicant_signature) {
      toast({ html: 'Applicant Need to Sign The Application !', classes: 'red' });
      return;
    }

    this.ApplicationModel.applicant_signature = values.applicant_signature;
    this.ApplicationModel.applicant_date = new Date();


    this.step = 0;
  }

  SubmitApplication() {
    console.log(this.ApplicationModel);
    if (this.memberProfile) {
     
      this.memberService.updateMemberProfile(this.memberProfile).subscribe(x => {
        if (x) {
         
          toast({ html: 'Member Profile Successfully Updates!', classes: 'green' });
        }
      });
    }
    this.ISAppService.saveApplicatioin(this.ApplicationModel).subscribe(data => {
      if (data) {
      
        let result: any[];
        result = data;
        toast({ html: 'Application Successfully Sent!', classes: 'green' });

        const family_com_id = result[0].family_comp_id;
        this.FamilyMemberModel = [];
        this.aMember = new IncomeSupportFamilyMemberModel();
        this.aMember.applicantIDOfHead = this.applicantID;
        this.aMember.applicantIDOfChild = this.applicantID;
        this.aMember.given_name = this.ApplicationModel.firstName;
        this.aMember.last_name = this.ApplicationModel.lastName;
        this.aMember.dob = this.ApplicationModel.dob;
        this.aMember.Relationship = 'Applicant';
        this.aMember.edu_level = this.ApplicationModel.EDU_LEVEL;
        this.aMember.family_comp_id = family_com_id;
        this.aMember.application_no = 0;
        this.aMember.financial_contribution = 0;
        this.FamilyMemberModel.push(this.aMember);

        this.NewRequestOfFamilyMemberModel = [];
        this.tmpMemberModel.forEach(child => {

          this.aMember = new IncomeSupportFamilyMemberModel();
          this.aMember.applicantIDOfHead = child.applicantIDOfHead;
          this.aMember.applicantIDOfChild = child.applicantIDOfChild;
          this.aMember.given_name = child.given_name;
          this.aMember.last_name = child.last_name;

          this.aMember.Relationship = child.Relationship;
          this.aMember.edu_level = child.edu_level;
          this.aMember.family_comp_id = family_com_id;
          this.aMember.application_no = 0;
          this.aMember.financial_contribution = 0;
          if (child.applicantIDOfChild > 0) {
            this.aMember.dob = child.dob ? this.changeDates(child.dob).toDateString() : '';
            this.FamilyMemberModel.push(this.aMember);
          } else {
            this.aMember.dob = this.formatDate2(child.dob);
            this.NewRequestOfFamilyMemberModel.push(this.aMember);
          }
        });

        this.ISAppService.saveFamilyMembers(this.FamilyMemberModel).subscribe(x => {
          if (x) {
           
            // this.IncomeSupportApplicationService.saveRequestToFamilyMember(this.NewRequestOfFamilyMemberModel).subscribe(x => {
            // if (x) { }

            this.otherPersonModel = [];
            this.tmpOtherMemberModel.forEach(member => {
              this.aMember = new IncomeSupportFamilyMemberModel();
              this.aMember.applicantIDOfHead = this.applicantID; //member.applicantIDOfHead;
              this.aMember.applicantIDOfChild = member.applicantIDOfPerson;
              this.aMember.given_name = member.given_name;
              this.aMember.last_name = member.last_name;
              this.aMember.dob = member.dob ? this.changeDates(member.dob).toDateString() : '';
              this.aMember.Relationship = member.Relationship;
              this.aMember.edu_level = '';
              this.aMember.family_comp_id = family_com_id;
              this.aMember.application_no = 0;
              this.aMember.financial_contribution = Number(member.contribution);
              this.otherPersonModel.push(this.aMember);
            });

            this.ISAppService.saveOtherPersons(this.otherPersonModel).subscribe(x => {
              if (x) {
               
              }

              this.AssetsModel = [];
              this.tmpAssetsModel.forEach(asset => {
                this.oneAssets = new AssetsModel();
                this.oneAssets.applicantID = this.applicantID;
                this.oneAssets.applicationNo = 0;
                this.oneAssets.catagory = asset.asset;
                this.oneAssets.value = asset.amount;
                this.oneAssets.description = '';
                this.oneAssets.member_name = '';
                this.oneAssets.Fam_Mem_Type = 'A';
                this.oneAssets.verified = false;
                this.oneAssets.type = 'R';
                this.AssetsModel.push(this.oneAssets);
              });

              this.ISAppService.saveAssets(this.AssetsModel).subscribe(y => {
                if (y) {}

                this.IncomesModel = [];
              
                this.applicantIncomeModel.forEach(i => {
                  this.aIncome = new IncomeModel();
                  this.aIncome.applicantID = this.applicantID;
                  this.aIncome.applicationNo = 0;    //////////////////
                  this.aIncome.catagory = i.income;
                  this.aIncome.value = i.amount;
                  this.aIncome.description = '';
                  this.aIncome.member_name = this.applicant_name;
                  this.aIncome.Fam_Mem_Type = 'A';
                  this.aIncome.verified = false;
                  this.aIncome.type = 'R';
                  this.aIncome.date_recieved = this.formatDate2(i.date_received);
                  this.IncomesModel.push(this.aIncome);
                });

                this.spouseIncomeModel.forEach(i => {
                  this.aIncome = new IncomeModel();
                  this.aIncome.applicantID = this.spouseID;
                  this.aIncome.applicationNo = 0;    //////////////////
                  this.aIncome.catagory = i.income;
                  this.aIncome.value = i.amount;
                  this.aIncome.description = '';
                  this.aIncome.member_name = this.spouse_name;
                  this.aIncome.Fam_Mem_Type = 'S';
                  this.aIncome.verified = false;
                  this.aIncome.type = 'R';
                  this.aIncome.date_recieved = this.formatDate2(i.date_received);
                  this.IncomesModel.push(this.aIncome);
                });

                this.ISAppService.saveIncomes(this.IncomesModel).subscribe(y => {
                  if (y) {
                   
                    toast({ html: 'Application Successfully Sent!', classes: 'green' });
                    this.backClicked();
                  }
                });
              });
            });
            // });  
          }
        });
      } else {
        toast({ html: 'Failed to Save Application!', classes: 'red' });
      }
    });
  }

  backClicked() {
    this.location.back();
  }

  updateMemberProfile() {
  }



  isImage(file: File): boolean {
    return /^image\//.test(file.type);
  }

  sign(person) {
    this.signing = person;
    this.step = 12;
    setTimeout(() => {
      this.canvas = document.querySelector('canvas');
      const parentWidth = $(this.canvas).parent().outerWidth();
      this.canvas.setAttribute('width', parentWidth);
      this.canvas.setAttribute('height', '175px');
      this.signaturePad = new SignaturePad(this.canvas, {
        backgroundColor: 'rgb(255,255,255)'
      });
    }, 50);
  }

  approveSignature() {
    if (this.signaturePad.isEmpty()) {
      toast({ html: 'Please Sign the Application!', classes: 'red' });
      return;
    } else {
      if (this.signing == this.first_name.value) {

        //const dataUrl = this.signaturePad.toDataURL();
        const dataUrl = this.signaturePad.toDataURL().replace('data:image/png;base64,', '');
        //console.log(dataUrl);
        this.section6Form.patchValue({ 'applicant_signature': dataUrl });
        this.applicantSigned = true;
      } else if (this.signing == this.spouse_first_name.value) {
        //const dataUrl = this.signaturePad.toDataURL();
        const dataUrl = this.signaturePad.toDataURL().replace('data:image/png;base64,', '');
        this.section6Form.patchValue({ 'spouse_signature': dataUrl });
        this.spouseSigned = true;
      }
      toast({ html: 'Application Signed', classes: 'green' });
      this.BackToApp();
    }

  }

  clearSignature() {
    this.signaturePad.clear();
  }

  BackToApp() {
    this.step = 11;
  }

  nextSection(valid) {
    if (!valid) {
      toast({ html: 'Please fill out the required fields to continue to the next section', classes: 'blue' });
    }
  }

  onCMHCAssistedYes() {

  }

  onCMHCAssistedNo() {

  }

  setUtilitiesTwoNumberDecimal(value: any) {
    let amount = parseFloat(value).toFixed(2);
    this.section61Form.controls['utilities'].patchValue(amount);
  }

  setFuelTwoNumberDecimal(value: any) {
    let amount = parseFloat(value).toFixed(2);
    this.section61Form.controls['fuel'].patchValue(amount);
  }

  setMaintenanceTwoNumberDecimal(value: any) {
    let amount = parseFloat(value).toFixed(2);
    this.section61Form.controls['maintenance'].patchValue(amount);
  }

  setGarbageTwoNumberDecimal(value: any) {
    let amount = parseFloat(value).toFixed(2);
    this.section61Form.controls['garbage'].patchValue(amount);
  }

  setRentTwoNumberDecimal(value: any) {
    let amount = parseFloat(value).toFixed(2);
    this.section61Form.controls['rent'].patchValue(amount);
  }

  formatDate(date: Date) {
    if (date) {
      var month = '' + (date.getMonth() + 1);
      var day = '' + date.getDate();
      var year = date.getFullYear();
      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;
      // console.log([year, month, day].join('-'));
      return [year, month, day].join('-');
    }
  }

  formatDates(dateValue) {
    if (dateValue) {
      //   //1987-07-19T00:00:00
      let dateArray = dateValue.split('-');
      let year = dateArray[0];
      let month = dateArray[1];   //- 1;
      let day = dateArray[2].substring(0, 2);
      return day + '/' + month + '/' + year;
    } else {
      return '';
    }
  }

  formatDate2(dateValue) {
  
    if (dateValue) {
      //   //1987-07-19T00:00:00
      let dateArray = dateValue.split('/');
      let day = dateArray[0];
      let month = dateArray[1];   //- 1;
      let year = dateArray[2].substring(0, 4);
      // console.log(year + '-' + month + '-' + day)
      return year + '-' + month + '-' + day;
    } else {
      return '';
    }

  }

  async getDocumentDefinition() {
    const datePipe = new DatePipe('en-US');
    return {
      info: {
        title: 'Income Support Application',
        author: this.model.firstName + this.model.lastName,
        subject: 'IS',
        keywords: 'IS Request',
      },
      content: [
        {
          columns: [
            [
              await this.getLogo(),
            ],
            [{
              text: 'INCOME SUPPORT APPLICATION',
              style: 'mainTitle',
            }]
          ]
        },
        {
          columns: [
            [{
              text: 'APPLICANT INFORMATION',
              style: 'title',
            },
            {
              text: 'First Name : ' + this.ApplicationModel.firstName
            },
            {
              text: 'Last Name : ' + this.ApplicationModel.lastName
            },
            {
              text: 'Date of Birth : ' + this.ApplicationModel.dob
            },
            {
              text: 'Marital Stauts : ' + this.ApplicationModel.maritalStatus
            },
            {
              text: 'Education Level : ' + this.ApplicationModel.EDU_LEVEL
            },
            {
              text: 'Treaty Number : ' + this.ApplicationModel.TreatyNo
            },
            {
              text: 'ISN Number : ' + this.ApplicationModel.isn
            },
            {
              text: 'Band Name : ' + this.ApplicationModel.bandName
            },
            {
              text: 'Province of Residency : ' + this.ApplicationModel.province
            },
            {
              text: 'Address : ' + this.ApplicationModel.address
            },
            {
              text: 'City : ' + this.memberProfile.city
            },
            {
              text: 'Postal Code : ' + this.ApplicationModel.postalCode
            },
            {
              text: 'Mailing Address : ' + this.memberProfile.MailAddress
            },
            {
              text: 'House Number : ' + this.memberProfile.HouseNumber
            },
            {
              text: 'Home Number : ' + this.ApplicationModel.telephone
            },
            {
              text: 'Mobile Number : ' + this.memberProfile.cell_phone_num
            },
            {
              text: 'Health Care Number : ' + this.ApplicationModel.healthCareNo
            },
            {
              text: 'SIN : ' + this.ApplicationModel.SIN
            }],
            this.showSpouseInfo || this.hasSpouse ?
              [{
                text: 'SPOUSE INFORMATION',
                style: 'title',
              },
              {
                text: this.ApplicationModel.spouseFirstName ? 'Spouse First Name : ' + this.ApplicationModel.spouseFirstName : ""
              },
              {
                text: this.ApplicationModel.spouseLastName ? 'Spouse Last Name : ' + this.ApplicationModel.spouseLastName : ""
              },
              {
                text: this.ApplicationModel.spouseISN ? 'Spouse ISN Number : ' + this.ApplicationModel.spouseISN : ''
              },
              {
                text: this.ApplicationModel.spouseProvince ? 'Spouse Province : ' + this.ApplicationModel.spouseProvince : ''
              },
              {
                text: this.ApplicationModel.spouseHealtCareNo ? 'Spouse Health Care Number : ' + this.ApplicationModel.spouseHealtCareNo : ""
              },
              {
                text: this.ApplicationModel.spouseDob ? 'Spouse Date of Birth : ' + this.ApplicationModel.spouseDob : ""
              },
              {
                text: this.ApplicationModel.spouseSIN ? 'Spouse SIN : ' + this.ApplicationModel.spouseSIN : ""
              }
              ] : ''

       
          ]
        },
        {
          text: 'Children',
          style: 'title',
        },
        this.tmpMemberModel.length > 0?
        {
          table: {
            widths: ['*', '*', '*', '*', '*'],
            body: [
              [
                {
                  text: 'First Name',
                  style: 'header',
                  bold: true,
                },
                {
                  text: 'Last Name',
                  style: 'header',
                  bold: true,
                },
                {
                  text: 'Date of birth',
                  style: 'header',
                  bold: true,
                },
                {
                  text: 'Education Level',
                  style: 'header',
                  bold: true,
                },
                {
                  text: 'Relationship',
                  style: 'header',
                  bold: true,
                }],
              ...this.tmpMemberModel.map(child => {
                return [child.given_name, child.last_name, child.dob, child.edu_level, child.Relationship];
              })
            ]
          }
        }: {
          text: 'No children Added'
        },
        {
          text: 'Other Family Memebers',
          style: 'title',
        },
        this.tmpOtherMemberModel.length > 0 ?
        {
          table: {
            widths: ['*', '*', '*', '*', '*'],
            body: [
              [
                {
                  text: 'First Name',
                  style: 'header',
                  bold: true,
                },
                {
                  text: 'Last Name',
                  style: 'header',
                  bold: true,
                },
                {
                  text: 'Date of Birth',
                  style: 'header',
                  bold: true,
                },
                {
                  text: 'Relationship',
                  style: 'header',
                  bold: true,
                },
                {
                  text: 'Contribution',
                  style: 'header',
                  bold: true,
                }],
              ...this.tmpOtherMemberModel.map(other => {
                return [other.given_name, other.last_name, other.dob, other.Relationship, other.contribution];
              })
            ]
          }
        }: {
          text: 'No Other Family Member Added'
        },
        {
          text: "Applicant Previous Address",
          style: 'title',
        },
        {
          columns: [
            this.moved ?
              [
                {
                  text: this.ApplicationModel.previous_address ? "Address : " + this.ApplicationModel.previous_address : "Address : " + ""
                },
                // {
                //   text: "Province : " + this.model.previousAddress.province
                // },
                // {
                //   text: "City : " + this.model.previousAddress.city
                // },
                // {
                //   text: "Postal Code : " + this.model.previousAddress.postalCode
                // },
                {
                  text: this.ApplicationModel.previous_address_startDate ? "From : " + this.ApplicationModel.previous_address_startDate : "From : " + ""
                },
                {
                  text: this.ApplicationModel.previous_address_endDate ? "To : " + this.ApplicationModel.previous_address_endDate : "To : " + ""
                }] : {
                  text: 'Applicant Has not moved'
                },
          ]
        },
        {
          columns: [
            [
              {
                text: "Applicant Recent Education",
                style: 'title',
              },
              {
                text: "Education : " + this.ApplicationModel.education
              },

              {
                text: this.ApplicationModel.education_startDate ? "From : " + this.ApplicationModel.education_startDate : "From : " + ""
              },
              {
                text: this.ApplicationModel.education_endDate ? "To : " + this.ApplicationModel.education_endDate : "To : " + ""
              }],
            this.showSpouseInfo ?
              [
                {
                  text: "Spouse Recent Education",
                  style: 'title',
                },
                {
                  text: "Education : " + this.ApplicationModel.education_spouse
                },

                {
                  text: this.ApplicationModel.education_startDate_spouse ? "From : " + this.ApplicationModel.education_startDate_spouse : "From : " + ''
                },
                {
                  text: this.ApplicationModel.education_endDate_spouse ? "To : " + this.ApplicationModel.education_endDate_spouse : "To : " + ''
                }] : ''
          ]
        },
        this.presentlyEmployed ?
          {
            columns: [
              [{
                text: "Applicant Present Employment",
                style: 'title',
              },
              {
                text: "Employer Name : " + this.ApplicationModel.present_employer
              },
              {
                text: "Address : " + this.ApplicationModel.present_employment_address
              },
              {
                text: "Position Held : " + this.ApplicationModel.present_employment_position
              },
              {
                text: this.ApplicationModel.present_employment_startDate ? "From : " + this.ApplicationModel.present_employment_startDate : "From : " + ""
              },
              {
                text: this.ApplicationModel.present_employment_startDate ? "To : " + this.ApplicationModel.present_employment_startDate : "To : Current "
              }]
            ]
          } : '',
        !this.presentlyEmployed ?
          {
            columns: [
              [{
                text: "Applicant Previous Employmnet",
                style: 'title',
              },
              {
                text: "Employer Name : " + this.ApplicationModel.previous_employer
              },
              {
                text: "Address : " + this.ApplicationModel.previous_employment_address
              },
              {
                text: "Position Held : " + this.ApplicationModel.previous_employment_position
              },
              {
                text: this.ApplicationModel.previous_employment_startDate ? "From : " + this.ApplicationModel.previous_employment_startDate : "From :" + " "
              },
              {
                text: this.ApplicationModel.previous_employment_endDate ? "To: " + this.ApplicationModel.previous_employment_endDate : "To : " + " "
              }]
            ]
          } : '',

        this.showSpouseInfo || this.hasSpouse ?
          {
            columns: [
              this.presentlyEmployed ?
                [{
                  text: "Spouse Present Employment",
                  style: 'title',
                },
                {
                  text: "Employer Name : " + this.ApplicationModel.present_employer_spouse
                },
                {
                  text: "Address : " + this.ApplicationModel.present_employment_address_spouse
                },
                {
                  text: "Position Held : " + this.ApplicationModel.present_employment_position_spouse
                },
                {
                  text: this.ApplicationModel.present_employment_startDate_spouse ? "From : " + this.ApplicationModel.present_employment_startDate_spouse : "From : " + ""
                },
                {
                  text: this.ApplicationModel.present_employment_startDate_spouse ? "To : " + this.ApplicationModel.present_employment_startDate_spouse : "To : Current "
                }] : "",
              !this.presentlyEmployed ?
                [{
                  text: "Spouse Previous Employmnet",
                  style: 'title',
                },
                {
                  text: "Employer Name : " + this.ApplicationModel.previous_employer_spouse
                },
                {
                  text: "Address : " + this.ApplicationModel.previous_employment_address_spouse
                },
                {
                  text: "Position Held : " + this.ApplicationModel.previous_employment_position_spouse
                },
                {
                  text: this.ApplicationModel.previous_employment_startDate_spouse ? "From : " + this.ApplicationModel.previous_employment_startDate_spouse : "From :" + " "
                },
                {
                  text: this.ApplicationModel.previous_employment_endDate_spouse ? "To: " + this.ApplicationModel.previous_employment_endDate_spouse : "To : " + " "
                }
                ] : ""
            ]
          } : '',
        {
          text: 'Vehicles',
          style: 'title',
        },
        this.ApplicationModel.car1_make ?
          {
            text: 'Vehicle 1',
            bold: true,
          } : '',
        this.ApplicationModel.car1_make ?
          {
            columns: [
              [
                {
                  text: 'Model' + this.ApplicationModel.car1_make
                }
              ],
              [
                {
                  text: 'Year Built' + this.ApplicationModel.car1_year
                }
              ],
              [
                {
                  text: 'Value' + this.ApplicationModel.car1_value
                }
              ]
            ]
          } : '',
        this.ApplicationModel.car2_make ?
          {
            text: 'Vehicle 2',
            bold: true,
          } : '',
        this.ApplicationModel.car2_make ?
          {
            columns: [
              [
                {
                  text: 'Model : ' + this.ApplicationModel.car2_make
                }
              ],
              [
                {
                  text: 'Year Built : ' + this.ApplicationModel.car2_year
                }
              ],
              [
                {
                  text: 'Value : ' + this.ApplicationModel.car2_value
                }
              ]
            ]
          } : '',
        {
          text: 'Assets',
          style: 'title',
        },
        this.tmpAssetsModel.length > 0 ?
        {
          table: {
            widths: ['*', '*'],
            body: [
              [
                {
                  text: 'Asset',
                  style: 'header',
                  bold: true,
                },
                {
                  text: 'Amount',
                  style: 'header',
                  bold: true,
                }],
              ...this.tmpAssetsModel.map(asset => {
                return [asset.asset, asset.amount + " $"];
              })
            ]
          }
        }:{
          text: 'No Asset Added'
        },
        {
          text: 'Applicant Incomes',
          style: 'title',
        },
        this.applicantIncomeModel.length > 0?
        {
          table: {
            widths: ['*', '*', "*"],
            body: [
              [
                {
                  text: 'Income',
                  style: 'header',
                  bold: true,
                },
                {
                  text: 'Amount',
                  style: 'header',
                  bold: true,
                },
                {
                  text: 'Date Recevied',
                  style: 'header',
                  bold: true,
                }],
              ...this.applicantIncomeModel.map(income => {
                return [income.income, income.amount + " $ ", income.date_recevied ? income.date_recevied : ''];
              })
            ]
          }
        } :{
          text: 'No income Declared'
        },
        this.spouseAdded && this.spouse ?
          {
            text: 'Spouse Incomes',
            style: 'title',
          } : '',
        this.showSpouseInfo || this.hasSpouse ?
          {
            table: {
              widths: ['*', '*', "*"],
              body: [
                [
                  {
                    text: 'Income',
                    style: 'header',
                    bold: true,
                  },
                  {
                    text: 'Amount',
                    style: 'header',
                    bold: true,
                  },
                  {
                    text: 'Date Recevied',
                    style: 'header',
                    bold: true,
                  }],
                ...this.model.spouseIncomes.map(income => {
                  return [income.income, income.amount + " $ ", income.date_recevied ? income.date_recevied : ''];
                })
              ]
            }
          } : ''
      ],
      styles: {
        mainTitle: {
          bold: true,
          fontSize: 20,
          alignment: 'left',
          margin: [0, 0, 0, 20]
        },
        title: {
          bold: true,
          fontSize: 15,
          alignment: 'left',
          margin: [10, 10, 10, 10]
        },
        header: {
          fontSize: 12,
          bold: true,
          alignment: 'center',
        },
      }
    };
  }

  async getLogo() {
    let imageLink = "assets/img/Nation-logo.png";
    // if(this.logoModel){
    //   if(this.logoModel.logo){
    //     let imageBuffer = Buffer.from(this.logoModel.logo);
    //     let imageBase64 = imageBuffer.toString('base64');
    //     console.log(imageBase64);
    //   }
    // }
    const image = await this.getBase64FromUrl(imageLink);
    if (image) {
      return {
        image: image,
        width: 100,
        alignment: 'left'
      }
    }

    // if (this.logoBase64) {
    //   return {
    //     image: this.logoBase64,
    //     width: 75,
    //     alignment: 'left'
    //   };
    // }
    return null;
  }

  getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL("image/png");
        resolve(dataURL);
      };
      img.onerror = error => {
        reject(error);
      };
      img.src = url;
    });
  }

  getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      }
    });
  }

  async openPdf() {
    const documentDefinition = await this.getDocumentDefinition();
    pdfMake.createPdf(documentDefinition).open();
    //const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
    // pdfDocGenerator.getBase64((data) => {
    //   console.log(data);
    // });
  }

  downloadPdf() {
    const documentDefinition = this.getDocumentDefinition();
    pdfMake.createPdf(documentDefinition).download();
  }

  printPdf() {
    const documentDefinition = this.getDocumentDefinition();
    pdfMake.createPdf(documentDefinition).print();
  }

  editSection(section, step) {
    this.step = section;
    if (step) {
      this.step2 = step;
    }
    $(document).ready(function () {
      $('select').formSelect();
      $('.modal').modal();
      $('.tabs').tabs();
      $('.collapsible').collapsible();
      M.updateTextFields();
    });
  }


}
