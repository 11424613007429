import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { toast } from 'materialize-css';
import { SessionsMonitorService } from 'src/app/services/firebase/sessions-monitor.service';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';

declare var $: any;

@Component({
  selector: 'app-new-about-section',
  templateUrl: './new-about-section.component.html',
  styleUrls: ['./new-about-section.component.css']
})

export class NewAboutSectionComponent implements OnInit {

  myModel = {
    id: '',
    type: 'Post', 
    title: '',
    body: '',
    imgLink: '',
    videoLink: '',
    visibility: '',
    author: '',
    published: true,
    unPublished: false,
    datePublished: new Date(),
    index: Math.floor(Math.random() * (10000 - 5000 + 1)) + 5000,
  };

  valueEmittedFromChildComponent: any;
  sectionName: string;

  constructor(
    private location: Location, 
    private sessionMonitor: SessionsMonitorService,
    private settingsService: AppSettingsService) { }

  ngOnInit() {
    this.settingsService.getMenuName('MyNation').valueChanges().subscribe(menus => {
      if (menus && menus.length > 0) {
        let submenus = menus[0].submenus;
        let menu = submenus.filter(m => m.name == 'AboutUs');
        if (menu && menu.length > 0) this.sectionName = menu[0].displayName;
      }
    });
  }

  parentEventHandlerFunction(valueEmitted) {
    this.valueEmittedFromChildComponent = valueEmitted;
    if (this.valueEmittedFromChildComponent === true) {
      this.sessionMonitor.newActivity('About Us', 'Section Added');
      toast({ html: 'About Us Section Successfully Posted!', classes: 'green' });
    }
  }

  backClicked() {
    this.location.back();
  }

}
