import { Component, OnInit } from '@angular/core';
import { Validators, UntypedFormBuilder, UntypedFormGroup, AbstractControl } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { Location } from '@angular/common';
import { toast } from 'materialize-css';
import { createMask } from '@ngneat/input-mask';

function passwordConfirming(c: AbstractControl): any {
  if (!c.parent || !c) { return; }
  const pwd = c.parent.get('createPassword');
  const cpwd = c.parent.get('confirmPassword');

  if (!pwd || !cpwd) { return; }
  if (pwd.value !== cpwd.value) {
    return { invalid: true };
  }
}

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.css']
})
export class CreateAccountComponent implements OnInit {

  step1 = true;
  step2 = false;
  themeColor = environment.appTheme.adminThemeColor;
  fontColor = environment.appTheme.adminFontColor;
  addEmployerForm: UntypedFormGroup;
  provinces: any = ['Alberta', 'British Columbia', 'Manitoba', 'New Brunswick',
  'Newfoundland and Labrador', 'Nova Scotia', 'Ontario', 'Prince Edward Island', 'Quebec', 'Saskatchewan' ];

 // public phoneNumberMask = ['(', /[0-9]/, /[0-9]/, /[0-9]/, ')', ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  phoneInputMask = createMask<string>( {
    mask: '(999) 999-9999',
    guide: true,
    showMaskTyped: true,
  });

  constructor(private location: Location, private fb: UntypedFormBuilder, private authService: AuthService) { }


  get name() { return this.addEmployerForm.get('name'); }
  get email() { return this.addEmployerForm.get('email'); }
  get phone() { return this.addEmployerForm.get('phone'); }
  get address() { return this.addEmployerForm.get('address'); }
  get city() { return this.addEmployerForm.get('city'); }
  get province() { return this.addEmployerForm.get('province'); }
  get password() { return this.addEmployerForm.get('password'); }
  get confirmPassword() { return this.addEmployerForm.get('confirmPassword'); }

  ngOnInit() {
    this.addEmployerForm = this.fb.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required]],
      address: ['', [Validators.required]],
      city: ['', [Validators.required]],
      province: [environment.firstNation.province, [Validators.required]],
      password: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required, passwordConfirming]],
    });

    setTimeout(() => {
      $('select').formSelect();
    }, 25);
  }

  next() {
    this.step1 = false;
    this.step2 = true;
    setTimeout(() => {
      M.updateTextFields();
    }, 25);
  }

  back() {
    this.step1 = true;
    this.step2 = false;
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }

  addEmployer(value: any) {

    this.authService.employerEmailSignUp(value, value.password).then(x => {
     

        toast({ html: 'Account Successfully Created!', classes: 'green' });
        //this.location.back();


    }).catch(error => toast({ html: error.message, classes: 'red' }));

  }

  backClicked() {
    this.location.back();
  }

}
