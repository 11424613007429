<div class="row center-align">
    <h5><strong>HOUSING APPLICATIONS</strong></h5>
</div>

<app-firebase-loading *ngIf="loading"></app-firebase-loading>

<div class="row col s12">
    <div class="card">
        <div class="card-content">
            <div class="card-tabs">
                <ul class="tabs tabs-fixed-width">
                    <li class="tab"><a class="active" href="#pending">Pending </a></li>
                    <li class="tab"><a href="#approved">In Progress </a></li>
                    <li class="tab"><a href="#declined">Declined</a></li>
                    <li class="tab"><a href="#completed">Completed</a></li>
                </ul>
            </div>

            <div class="card-content grey lighten-5">

                <div id="pending">
                    <div class="row" *ngIf="pending">
                        <div class="row center-align" *ngIf="pending.length == 0">
                            <i class="large material-icons blue-text">info</i>
                            <p class="blue-text"><strong>No Pending Requests.</strong></p>
                        </div>
                        <div *ngIf="pending.length > 0">
                            <h6 class="center-align"><strong> Pending Requests</strong></h6>
                        </div>
                    </div>
                </div>

                <div id="approved">
                    <div class="row" *ngIf="approved">
                        <div class="row center-align" *ngIf="approved.length == 0">
                            <i class="large material-icons blue-text">info</i>
                            <p class="blue-text"><strong>No Approved Requests.</strong></p>
                        </div>
                        <div *ngIf="approved.length > 0">
                            <h6 class="center-align"><strong> Approved Requests</strong></h6>
                        </div>
                    </div>
                </div>

                <div id="declined">
                    <div class="row" *ngIf="declined">
                        <div class="row center-align" *ngIf="declined.length == 0">
                            <i class="large material-icons blue-text">info</i>
                            <p class="blue-text"><strong>No Declined Requests.</strong></p>
                        </div>
                        <div *ngIf="declined.length > 0">
                            <h6 class="center-align"><strong> Declined Requests</strong></h6>
                        </div>
                    </div>
                </div>

                <div id="completed">
                    <div class="row" *ngIf="completed">
                        <div class="row center-align" *ngIf="completed.length == 0">
                            <i class="large material-icons blue-text">info</i>
                            <p class="blue-text"><strong>No completed Requests.</strong></p>
                        </div>
                        <div *ngIf="completed.length > 0">
                            <h6 class="center-align"><strong>Completed Requests</strong></h6>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>