<div class="row" *ngIf="section && (model && model.length > 0)">
    <div class="row p-t-3 p-b-2">
        <div class="col s12 m9 l5 push-l1 push-m1">
            <div class="header-title">
                <h6 [ngStyle]="{ color: section.displayNameColor }">
                    {{ section.displayName }}
                </h6>
            </div>
        </div>
        <div class="col l6  hide-on-small-only " style="padding: 30px 0px;">
            <p class="seeAll-displayName teal-text right"><a [routerLink]="['/custome-section', section.id]"
                    (click)="navigate()" [ngStyle]="{'color': section.displayNameColor}">
                    <i>See All {{section.displayName}} >>
                    </i></a>
            </p>
        </div>
        <div class="divider col l10 m10 s8 push-l1 push-m1 push-s2 grey lighten-3"></div>
    </div>
    <!--Lis -->
    <div class="row col l10 push-l1 s12 m10 push-m1 container" *ngIf="model">
        <div class="col s12 m6 l6 xl6" *ngFor="let content of model">

            <div class="row col s12 card list-view" [ngStyle]="{ color: themeColor }" *ngIf="content.mediaType == 'Photo' && content.mediaLink"
                [routerLink]="['/section-detail', content.id]">

                <div class="card-image col s12 l4 hide-on-med-and-up" style="padding: 0" *ngIf="content.mediaType == 'Photo'">
                    <img class="responsive-img b-n-1" [sr`c]="content.mediaLink" alt="section image"/>
                </div>

                <!-- <div class="card-image col s12 l4 hide-on-med-and-up" *ngIf="content.mediaType == 'Video'">
                    <video class="responsive-video" controls>
                        <source [src]="content.mediaLink" type="video/mp4" />
                    </video>
                </div>

                <div class="card-image col s12 l4 hide-on-med-and-up" *ngIf="content.mediaType === 'Youtube'">
                    <div class="video-container ">
                        <div [innerHTML]="embedVideo(content.mediaLink)"></div>
                    </div>
                </div>

                <div class="card-image col s12 l4 hide-on-med-and-up" *ngIf="content.mediaType === 'PDF'">
                    <iframe title="iframe-box2" class="responsive-pdf" [src]="content.mediaLink | safeUrl"></iframe>
                </div> -->

                <div class="col s12 l8">
                    <!-- <div class="date"> {{item.datePublished .toDate() | date: 'MMM dd, yyyy'}}</div> -->
                    <h1 [innerHTML]="content.title" class="title-section3 bolder p-m-1"></h1>
                    <span [innerHTML]="truncateHTML(content.description, 300) | noSanitize"
                        class="small-body-snippet bold-100 hide-on-small-only"></span>

                    <div class="p-t-2">
                        <span class="bold-900 sub-title-section3" style="display: flex; align-items: center"
                            [ngStyle]="{ color: themeColor }">Read More
                            <i class="material-symbols-outlined sub-title-section2 bold-500">arrow_forward</i>
                        </span>
                    </div>
                </div>
                <div class="col s12 l4 hide-on-small-only">
                    <img class="responsive-img b-n-1" [src]="content.mediaLink" alt="section-list image"/>
                </div>
            </div>

            <div class="row col s12 card list-view" [ngStyle]="{ color: themeColor }" *ngIf="!content.mediaLink"
                [routerLink]="['/section-detail', content.id]">
                <div class="col s12">
                    <!-- <div class="date1"> {{item.datePublished .toDate() | date: 'MMM dd, yyyy'}}</div> -->
                    <h1 [innerHTML]="content.title" class="title-section3 bolder" style="margin-top: 0"></h1>
                    <span [innerHTML]="truncateHTML(content.description, 300) | noSanitize"
                        class="small-body-snippet bold-100"></span>

                    <div class="p-t-2">
                        <span class="bold-900 sub-title-section3 icon-text" style="display: flex; align-items: center"
                            [ngStyle]="{ color: themeColor }">Read More<i
                                class="material-symbols-outlined sub-title-section2 bold-500">arrow_forward</i></span>
                    </div>
                </div>
            </div>
        </div>

        <div class="row col s12 m12 hide-on-med-and-up p-t-3">
            <p class="seeAll-displayName teal-text center">
                <a (click)="navigate()" [routerLink]="['/custome-section', section.id]" [ngStyle]="{
              color: section.displayNameColor,
              text_decoration: 'underline'
            }"><i>See All {{ section.displayName }} >> </i></a>
            </p>
        </div>


    </div>
</div>



<div class="row" *ngIf="section && false">
    <div class="row news_latest">
        <div class="header-title center">
            <h6 [ngStyle]="{ color: section.displayNameColor }">
                {{ section.displayName }}
            </h6>
            <hr class="divider" [ngStyle]="{
            'background-color': themeColor,
            'border-color': themeColor
          }" />
        </div>

        <div class="row col l10 push-l1 s12 m10 push-m1 container" *ngIf="model && model.length > 0">
            <div class="col s12 m6 l6 xl6" *ngFor="let content of model">
                <div class="row col s12 card list-view" [ngStyle]="{ color: themeColor }" *ngIf="content.mediaLink"
                    [routerLink]="['/section-detail', content.id]">
                    <div class="col l8 s12">
                        <p class="date-content sub-title-section3" style="right: 0">
                            {{ content.datePublished.toDate() | date : "MMM dd , yyyy" }}
                        </p>
                        <h1 [innerHTML]="content.title" class="title-section3 bolder p-t-0" style="margin-top: 0"></h1>
                        <span [innerHTML]="truncateHTML(content.description, 300) | noSanitize"
                            class="small-body-snippet bold-100 hide-on-small-only"></span>

                        <div class="p-t-2 hide-on-small-only">
                            <span class="bold-900 sub-title-section3" style="display: flex; align-items: center"
                                [ngStyle]="{ color: themeColor }">
                                Read More
                                <i class="material-symbols-outlined sub-title-section2 bold-500">arrow_forward</i>
                            </span>
                        </div>
                    </div>

                    <div class="col l4 s12">
                        <img class="responsive-img b-n-1" [src]="content.mediaLink" alt="section-list image" />
                    </div>

                    <div class="col s12 p-t-0 hide-on-med-and-up">
                        <span class="bold-900 sub-title-section3 " style="display: flex; align-items: center"
                            [ngStyle]="{ color: themeColor }">
                            Read More
                            <i class="material-symbols-outlined sub-title-section2 bold-500">arrow_forward</i>
                        </span>
                    </div>
                </div>

                <div class="row col s12 card list-view" [ngStyle]="{ color: themeColor }" *ngIf="!content.mediaLink"
                    [routerLink]="['/section-detail', content.id]">
                    <div class="col s12">
                        <p class="date-content sub-title-section3" style="right: 0">
                            {{ content.datePublished.toDate() | date : "MMM dd , yyyy" }}
                        </p>
                        <h1 [innerHTML]="content.title" class="title-section3 bolder p-t-0" style="margin-top: 0"></h1>
                        <span [innerHTML]="truncateHTML(n.description, 300) | noSanitize"
                            class="small-body-snippet bold-100"></span>
                        <div class="p-t-2">
                            <span class="bold-900 sub-title-section3 icon-text"
                                style="display: flex; align-items: center" [ngStyle]="{ color: themeColor }">
                                Read More
                                <i class="material-symbols-outlined sub-title-section2 bold-500">arrow_forward</i>
                            </span>
                        </div>
                    </div>
                </div>
            </div>



            <div class="row col s12 m12 hide-on-med-and-up">
                <p class="seeAll-displayName teal-text center">
                    <a (click)="navigate()" [routerLink]="['/custome-section', section.id]" [ngStyle]="{
                color: section.displayNameColor,
                text_decoration: 'underline'
              }">
                        <i>See All {{ section.displayName }} >></i>
                    </a>
                </p>
            </div>

            <div class="row col s12 m12 hide-on-small-only">
                <p class="seeAll-displayName teal-text right">
                    <a (click)="navigate()" [routerLink]="['/custome-section', section.id]" [ngStyle]="{
                color: section.displayNameColor,
                text_decoration: 'underline'
              }">
                        <i>See All {{ section.displayName }} >></i>
                    </a>
                </p>
            </div>
        </div>

        <!-- <div class="row col s12 l10 m10 push-l1 push-m1 container" *ngIf="newsSection?.cardView">
        <div class="col s12 m6 l4" *ngFor="let n of news">
          <div class="content-wrapper">
            <div class="news-card" *ngIf="n.type === 'Photo'" [routerLink]="['/read-more', n.id]">
              <img class="responsive-img news-card__image" [src]="n.imgLink" *ngIf="n.type === 'Photo'" />
              <div [innerHTML]="n.iframe"></div>
              <div class="news-card__text-wrapper">
                <h2 class="news-card__title" [innerHTML]="n.title"></h2>
                <div class="news-card__post-date">
                  {{ n.datePublished.toDate() | date : "MMM dd, yyyy" }}
                </div>
                <div class="news-card__details-wrapper">
                  <p class="news-card__excerpt black-text" [innerHTML]="truncateHTML(n.body, 200) | noSanitize"></p>
                  <a [routerLink]="['/read-more', n.id]" class="news-card__read-more">Read more <i
                      class="fas fa-long-arrow-alt-right"></i></a>
                </div>
              </div>
            </div>
  
            <div class="news-cardd" *ngIf="n.type !== 'Photo'" [routerLink]="['/read-more', n.id]">
              <div class="news-card__text-wrapper">
                <h2 class="news-card__title" [innerHTML]="n.title"></h2>
                <div class="news-card__post-date">
                  {{ n.datePublished.toDate() | date : "MMM dd, yyyy" }}
                </div>
                <div class="news-card__details-wrapperr">
                  <p class="news-card__excerpt black-text" [innerHTML]="truncateHTML(n.body, 250) | noSanitize"></p>
                  <a [routerLink]="['/read-more', n.id]" class="news-card__read-more">Read more <i
                      class="fas fa-long-arrow-alt-right"></i></a>
                </div>
              </div>
            </div>
          </div>
        </div>
  
        <div class="row col s12 m12 hide-on-large-only">
          <p class="seeAll-displayName teal-text center">
            <a (click)="navigate()" [routerLink]="['/news']" [ngStyle]="{
                color: newsSection.displayNameColor,
                text_decoration: 'underline'
              }">
              <i>See All {{ newsSection.displayName }} >></i>
            </a>
          </p>
        </div>
  
        <div class="row col s12 m12 hide-on-med-and-down p-t-3">
          <p class="seeAll-displayName teal-text right">
            <a (click)="navigate()" [routerLink]="['/news']" [ngStyle]="{
                color: newsSection.displayNameColor,
                text_decoration: 'underline'
              }">
              <i>See All {{ newsSection.displayName }} >></i>
            </a>
          </p>
        </div>
      </div> -->
    </div>
</div>