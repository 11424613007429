
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiKeyManagerService } from './apiKeyManager.Service';
import { CasePlanModel } from 'src/app/models/CasePlanModel';
import { SKCasePlanModel } from 'src/app/models/SKCasePlanModel';
import { SKExperienceModel } from 'src/app/models/SKExperienceModel';
import { SKBarrierModel } from 'src/app/models/SKBarrierModel';
import { SKCareerInterestModel } from 'src/app/models/SKCareerInterestModel';

@Injectable({
    providedIn: 'root'
  })
  export class CasePlanService {
  
    constructor(private httpClient: HttpClient, private keyManager: ApiKeyManagerService) { }
  
    url = environment.firstNation.apiUrl + 'api/CasePlan/';
  
    saveCasePlan(model: CasePlanModel[]): Observable<any> {
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
      return this.httpClient.post<CasePlanModel>(this.url + 'SaveCasePlan/', JSON.stringify(model), { headers });
    }

    
    getCasePlanID(id: number): Observable<any> {
        const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
        return this.httpClient.get<any>(this.url + 'GetCasePlanID/' + id, { headers });
    }

    
    getActionItems(id: number): Observable<any> {
        const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
        return this.httpClient.get<any>(this.url + 'GetActionItemsById/' + id, { headers });
    }




    saveSKCasePlan(model: SKCasePlanModel): Observable<any> {
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
      return this.httpClient.post<SKCasePlanModel>(this.url + 'SaveSKCasePlan/', JSON.stringify(model), { headers });
    }

    getSKCasePlan(id: number): Observable<any> {
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
      return this.httpClient.get<any>(this.url + 'GetSKCasePlan/' + id, { headers });
    }

    saveSKExperience(model: SKExperienceModel[]): Observable<any> {
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
      return this.httpClient.post<SKExperienceModel[]>(this.url + 'SaveSKExperience/', JSON.stringify(model), { headers });
    }

    getSKExperience(id: number): Observable<any> {
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
      return this.httpClient.get<any>(this.url + 'GetSKExperience/' + id, { headers });
    }


    
    saveSKBarriers(model: SKBarrierModel[]): Observable<any> {
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
      return this.httpClient.post<SKBarrierModel[]>(this.url + 'SaveSKBarriers/', JSON.stringify(model), { headers });
    }

    getSKBarriers(id: number): Observable<any> {
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
      return this.httpClient.get<any>(this.url + 'GetSKBarriers/' + id, { headers });
    }


    
    saveSKCareerInterest(model: SKCareerInterestModel[]): Observable<any> {
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
      return this.httpClient.post<SKCareerInterestModel[]>(this.url + 'SaveSKCareerInterest/', JSON.stringify(model), { headers });
    }

    getSKCareerInterest(id: number): Observable<any> {
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
      return this.httpClient.get<any>(this.url + 'GetSKCareerInterest/' + id, { headers });
    }


}