
export class PseCoursesToRegisterModel {

    student_aid: number;
    course_id: number;
    course_year: number;
    course_term: string;
    course_name: string;
    institution: string;
    start_date: string;
    end_date: string;
    pse_application_id: number;

}