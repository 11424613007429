import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TempHousingService } from 'src/app/services/firebase/temp-housing.service';
import { Location } from '@angular/common';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { CommunityService } from 'src/app/services/api/community.service';
import { MemberService } from 'src/app/services/api/member.service';
import { toast } from 'materialize-css';
import { ISApplicationService } from 'src/app/services/api/ISApplication.service';
import { DatePipe } from '@angular/common';
import { BandMemberFilterModel } from 'src/app/models/BandMemberFilterModel';
import { HousingService } from 'src/app/services/api/housing.service';
import { UpdateHouseModel } from 'src/app/models/HousingModel';
import { createMask } from '@ngneat/input-mask';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-update-property',
  templateUrl: './update-property.component.html',
  styleUrls: ['./update-property.component.css']
})
export class UpdatePropertyComponent implements OnInit {

  houseTypes = ['Bungalow', 'Townhouse', 'Condominium', 'Detached House', 'Semi-detached House'];
  fuelType = ['Natural Gas', 'Propane', 'Oil', 'Wood', 'Electric'];

  public postalCodeMask = [/[A-Za-z]/, /[0-9]/, /[A-Za-z]/, ' ', /[0-9]/, /[A-Za-z]/, /[0-9]/];
  public dateMask = [/[0-3]/, /[0-9]/, '/', /[0-1]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  //public yearMask = [/[1-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  public dateformat = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;

  yearMask = createMask<string>({
    mask: '9999',
    placeholder: 'YYYY',
    definitions: {
      '9': {
        validator: '[0-9]',
        cardinality: 1,
      },
    },
  });


  loading = false;
  members: any[];
  propertyId = '';
  property: any;
  communities: any[] = [];
  CMHC = false;
  propertyForm: UntypedFormGroup;
  familyForm: UntypedFormGroup;
  member: any;
  filters = {
    firstName: '',
    lastName: ''
  }
  ownerSelected = false;
  houseNo = false;
  lotBlock = false;
  landDesc = false;
  other = false;
  rented = false;
  financed = false;
  filtredMembers: any[];
  familyMembers: any[];
  occupants: any[] = [];
  step1 = true;
  step2 = false;
  addFamily = false;
  ownerChanged = false;
  selectedOccupants: any[] = [];
  confirmationMessage = '';
  model: UpdateHouseModel;
  yearSet = false;
  ageOfHouse: number;
  
  constructor(private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private communityService: CommunityService,
    private memberService: MemberService,
    private location: Location,
    private housingService: HousingService) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.propertyId = params.get('id');
    });

    this.communityService.getCommunity().subscribe(com => {
      if (com.length > 0) {
        this.communities = com;
        setTimeout(() => {
          $('select').formSelect();
          M.updateTextFields();
        }, 25);
      }
    });

    this.loading = true;
    if (JSON.parse(localStorage.getItem("membersList"))) {
      this.members = JSON.parse(localStorage.getItem("membersList"));
      this.loading = false;
      setTimeout(() => {
        $('.modal').modal();
        M.updateTextFields();
      }, 25);
    } else {
      this.memberService.getManageMembersList().subscribe(x => {
        if (x) {
          this.members = x;
          localStorage.setItem("membersList", JSON.stringify(this.members));
        }
        this.loading = false;
        setTimeout(() => {
          $('select').formSelect();
          $('.modal').modal();
          M.updateTextFields();
        }, 25);
      });
    }

    if (this.propertyId) {
      this.housingService.getHouse(Number(this.propertyId)).subscribe(x => {
        if (x) {
          this.property = x[0];   
          this.propertyForm = this.fb.group({
            HoH_id: [this.property.aid_of_owner],
            HoH: [""],
            house_number: [this.property.house_number],
            block: [this.property.house_block],
            lot: [this.property.house_lot],
            QRT: [this.property.Quarter],
            section: [this.property.section],
            township: [this.property.township],
            range: [this.property.range],
            meridian: [this.property.median],
            other_location: [this.property.Other_location],
            desc_type: [this.property.desc_type, [Validators.required]],
            CMHC: [this.property.CMHC, [Validators.required]],
            financed: [this.property.finianced],
            rooms: [this.property.rooms],
            community: [this.property.community, [Validators.required]],
            style: [this.property.style],
            year: [this.property.year],
            value: [this.property.value],
            size: [this.property.size],
            fuel_type: [this.property.Primary_heat],
            fuel_type2: [this.property.Secondary_heat],
          });

          if(this.property.aid_of_owner) {
            this.ownerSelected= true;
            this.propertyForm.get('HoH').patchValue(this.property.GivenName+ " " + this.property.LastName);
          }
        
          if (this.property.desc_type == 'HN') this.onHouseNo();
          else if (this.property.desc_type == 'LB') this.onLotBlock();
          else if (this.property.desc_type == 'LD') this.onLandDesc();
          else if (this.property.desc_type == 'OL') this.onOther();

        
          this.propertyForm.get('desc_type').valueChanges.subscribe(x => {
            if (x == 'HN') this.propertyForm.get('house_number').setValidators(Validators.required);
            else this.propertyForm.get('house_number').clearValidators();

            if (x == 'LB') {
              this.propertyForm.get('block').setValidators(Validators.required);
              this.propertyForm.get('lot').setValidators(Validators.required);
            }
            else {
              this.propertyForm.get('block').clearValidators();
              this.propertyForm.get('lot').clearValidators();
            }
            if (x == 'LD') {
              this.propertyForm.get('QRT').setValidators(Validators.required);
              this.propertyForm.get('section').setValidators(Validators.required);
              this.propertyForm.get('township').setValidators(Validators.required);
              this.propertyForm.get('range').setValidators(Validators.required);
              this.propertyForm.get('meridian').setValidators(Validators.required);
            }
            else {
              this.propertyForm.get('QRT').clearValidators();
              this.propertyForm.get('section').clearValidators();
              this.propertyForm.get('township').clearValidators();
              this.propertyForm.get('range').clearValidators();
              this.propertyForm.get('meridian').clearValidators();
            }
            if (x === 'OL') this.propertyForm.get('other_location').setValidators(Validators.required);
            else this.propertyForm.get('other_location').clearValidators();

            this.propertyForm.get('house_number').updateValueAndValidity();
            this.propertyForm.get('block').updateValueAndValidity();
            this.propertyForm.get('lot').updateValueAndValidity();
            this.propertyForm.get('QRT').updateValueAndValidity();
            this.propertyForm.get('section').updateValueAndValidity();
            this.propertyForm.get('township').updateValueAndValidity();
            this.propertyForm.get('range').updateValueAndValidity();
            this.propertyForm.get('meridian').updateValueAndValidity();
            this.propertyForm.get('other_location').updateValueAndValidity();
          });

          if(this.property.year){
            this.yearSet = true;
            const currentYear = new Date().getFullYear();
            this.ageOfHouse = currentYear - this.property.year;
          }
        }
      });
    }
    setTimeout(() => {
      $('select').formSelect();
      $('.modal').modal();
      M.updateTextFields();
    }, 25);
  }

  get house_number() { return this.propertyForm.get('house_number'); }
  get block() { return this.propertyForm.get('block'); }
  get lot() { return this.propertyForm.get('lot'); }
  get QRT() { return this.propertyForm.get('QRT'); }
  get section() { return this.propertyForm.get('section'); }
  get township() { return this.propertyForm.get('township'); }
  get range() { return this.propertyForm.get('range'); }
  get meridian() { return this.propertyForm.get('meridian'); }
  get other_location() { return this.propertyForm.get('other_location'); }
  get ageOfProperty() { return this.propertyForm.get('year'); }
  get building_cost() { return this.propertyForm.get('value'); }

  onHouseNo() {
    this.houseNo = true;
    this.lotBlock = false;
    this.landDesc = false;
    this.other = false;
    setTimeout(() => {
      M.updateTextFields();
    }, 25);
  }

  onLotBlock() {
    this.lotBlock = true;
    this.houseNo = false;
    this.landDesc = false;
    this.other = false;
    setTimeout(() => {
      M.updateTextFields();
    }, 25);
  }

  onLandDesc() {
    this.landDesc = true;
    this.lotBlock = false;
    this.houseNo = false;
    this.other = false;
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }

  onOther() {
    this.other = true;
    this.landDesc = false;
    this.lotBlock = false;
    this.houseNo = false;
    setTimeout(() => {
      M.updateTextFields();
    }, 25);
  }

  setTwoNumberDecimal(value: any, control) {
    let amount = parseFloat(value).toFixed(2);
    if (control === 'costOfBuilding') {
      this.building_cost.patchValue(amount);
      this.building_cost.updateValueAndValidity();
    }
  }

  isValidDate(control: UntypedFormControl) {
    let date = control.value;
    const dateformat = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
    if (date) {
      if (!date.match(dateformat) && date.length > 10) {
        const datePipe = new DatePipe('en-US');
        date = datePipe.transform(date, 'dd/MM/yyyy');
      }
      if (date.match(dateformat)) {
        let dateArray = date.split('/');
        let year = dateArray[2];
        let month = dateArray[1] - 1;
        let day = dateArray[0];
        date = new Date(year, month, day);
        const today = new Date();
        if (date.valueOf() < today.valueOf()) {
          return null;
        } else {
          return { 'invalidDate': true }
        }
      }
    }
    return null;
  }

  checkHouseNo(value) {
    let observer = this.housingService.houseNoExists(value).subscribe(x => {
      if (x && x.length > 0) {
        toast({ html: 'House Number already exists!', classes: 'red' });
        this.house_number.setErrors({ 'inUse': true });
      } else {
        observer.unsubscribe();
      }
    })
  }

  yearChanged(value) {
    this.yearSet = true;
    const currentYear = new Date().getFullYear();
    const age = currentYear - Number(value);
    this.ageOfHouse = age;
  }

  changeDates(dateValue) {
    if (dateValue) {
      if (dateValue.match(this.dateformat)) {
        let dateArray = dateValue.split('/');
        let year = dateArray[2];
        let month = dateArray[1] - 1;
        let day = dateArray[0];
        const date = new Date(year, month, day);
        return date;
      } else {
        const date = new Date(dateValue);
        return date;
      }
    } else {
      return null
    }
  }

  showMembers() {
    if (!this.members) {
      this.loading = true;
      this.memberService.getMembers().subscribe(x => {
        if (x) {
          this.members = x;
        }
        this.loading = true;
      });
    }
    setTimeout(() => {
      $('.modal').modal();
      $('#memberListModal').modal('open');
    }, 25);
  }

  filterMembers() {
    const model = new BandMemberFilterModel();
    if (this.filters.firstName && this.filters.firstName !== '') { model.firstName = this.filters.firstName; }
    if (this.filters.lastName && this.filters.lastName !== '') { model.lastName = this.filters.lastName; }
    if (this.filters.firstName === '' && this.filters.lastName === '') {
      this.resetfilters();
    } else {
      this.memberService.SearchBandMembers(model).subscribe(x => {
        if (x) {
          this.filtredMembers = x;
        }
      });
    }
  }

  resetfilters() {
    this.filtredMembers = undefined;
    this.filters.firstName = '';
    this.filters.lastName = '';
  }

  selectHoH(member: any) {
    if (this.property.HoH_id != member.applicantID) {
      this.ownerChanged = true;
      this.member = member;
      this.housingService.hasHouse(member.applicantID).subscribe(x => {
        if (this.member) {
          if (x) {
            $('.model').modal();
            $('#messageModel').modal('open');
          } else {
            const name = this.member.GivenName + ' ' + this.member.LastName;
            this.propertyForm.get('HoH').patchValue(name);
            this.propertyForm.get('HoH_id').patchValue(this.member.applicantID);
            $('#memberListModal').modal('close');
            this.ownerSelected = true;
            setTimeout(() => {
              M.updateTextFields();
            }, 25);
          }
        }
      });
      $('.modal').modal('close');
      this.resetfilters();
    }
  }

  clearHoH() {
    this.ownerChanged = true;
    this.ownerSelected = false;
    this.propertyForm.get('HoH').reset();
    this.propertyForm.get('HoH_id').reset();
  }

  getOccupants() {
    this.housingService.getOccupants(this.property.ID).subscribe(m => {
      if (m) {
        this.familyMembers = m;
      }
    });

    this.step1 = false;
    this.step2 = true;
  }

  submitForm(value) {
    this.model = new UpdateHouseModel();
    this.model.ID = this.property.ID;
    this.model.aid_of_owner = value.HoH_id;
    this.model.house_number = value.house_number ? value.house_number.toString() : '';
    this.model.house_lot = value.lot ? value.lot.toString() : '';
    this.model.house_block = value.block ? value.block.toString() : '';
    this.model.Quarter = value.QRT ? value.QRT : '';
    this.model.section = value.section ? value.section : '';
    this.model.township = value.township ? value.township : '';
    this.model.range = value.range ? value.range : '';
    this.model.median = value.meridian ? value.meridian : '';
    this.model.Other_location = value.other_location;
    this.model.desc_type = value.desc_type;
    this.model.CMHC = value.CMHC;
    this.model.finianced = value.financed;
    this.model.community = value.community;
    this.model.rooms = value.rooms;
    this.model.style = value.style;
    this.model.year = value.year;
    this.model.value = value.value ? value.value.toString() : '';
    this.model.size = value.size ? value.size.toString() : '';
    this.model.Fuel_Type = value.fuel_type;
    this.model.Fuel_Type2 = value.fuel_type2;

    this.housingService.updateProperty(this.model).subscribe(x => {
      if (x) {
        toast({ html: 'Property Successfully Updated!', classes: 'green' });
        this.back();
      }
    });
  }

  back() {
    $('.modal').modal('close');
    this.location.back();
  }

  backToPreviousPage() {
    this.step1 = true;
    this.step2 = false;
    this.filtredMembers = this.members;
    setTimeout(() => {
      $('select').formSelect();
      $('.modal').modal();
      M.updateTextFields();
    }, 25);
  }

}
