import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { EmploymentService } from 'src/app/services/api/employment.service';
import { EmploymentService as EmploymentFirebaseService } from 'src/app/services/firebase/employment.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-employment',
  templateUrl: './employment.component.html',
  styleUrls: ['./employment.component.css']
})

export class EmploymentComponent implements OnInit {

  constructor(private location: Location, private authService: AuthService, private employmentService: EmploymentService,
    private employmentFirebaseService: EmploymentFirebaseService, private router: Router) {
    localStorage.setItem('from', 'PersonalInfo');
  }

  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  nation = environment.firstNation.displayName;
  applicantID = 0;
  model: any[];
  hasRows = false;
  sqldb = environment.firstNation.SQLDB;

  ngOnInit() {
    if (localStorage.getItem('applicantID')) {
      this.applicantID = Number(localStorage.getItem('applicantID'));
    } else {
      this.applicantID = this.authService.getCurrentApplicantID();
    }
    localStorage.setItem('employment_call_from', 'employment');
    if(this.sqldb){
      this.employmentService.getEmploymentListByMemberID(this.applicantID).subscribe(x => {
        if (x) {
          this.model = x;
          if (this.model.length > 0) {this.hasRows = true; }
         }
      });
    }else{
      this.employmentFirebaseService.getEmploymentByMemberId(this.applicantID).valueChanges().subscribe(x => {
        if (x) {
          this.model = x;
          if (this.model.length > 0) { this.hasRows = true; }
        }
      });
    }
  }

  backClicked() {
    this.location.back();
  }

}