<div class="row col s12" *ngIf="depList">
    <div class="row center-align">
        <h5><strong>Sub Departments</strong></h5>
    </div>
    <div class="col l1 "></div>

    <div class="col s12 l10" *ngIf="!subDepartments || subDepartments.length == 0 ">
        <div class="row">
            <div class="card red lighten 3">
                <div class="card-content">
                    <div class="row center">
                        <i class="material-icons white-text">warning</i><br />
                        <strong class="white-text">No sub Departments Found.</strong>
                    </div>
                </div>
            </div>
            <button class="col s12 btn wave-effect" (click)="backClicked()" type="button">
                <i class="material-icons left">arrow_back</i>Back To Previous Page
            </button>
        </div>
    </div>

    <div class="col s12 l10" *ngIf="subDepartments && subDepartments.length >0">
        <div class="row">
            <div class="col s6 m4 l4">
                <a (click)="backClicked();">
                    <div class="card orange darken-4 hoverable " style="height: 105px;">
                        <div class="card-content">
                            <div class="row center">
                                <i class="material-icons white-text">house</i><br />
                                <strong class="white-text">Back</strong>
                            </div>
                        </div>
                    </div>
                </a>
            </div>

            <div class="col s6 m4 l4" *ngFor="let department of subDepartments">
                <a (click)="showDepDetail(department.id)">
                    <div class="card-panel white darken-3 hoverable" style="height: 105px;">
                        <div class="row center">
                            <u> <strong class="blue-text text-darken-3" style="margin-top: 12px; font-size: 15px;">
                                    {{ department.name }} </strong></u>
                        </div>
                    </div>
                </a>
            </div>
        </div>
    </div>

    <div class="col l1 "></div>
</div>

<div class="row col s12" *ngIf="depDetail">
    <div class="row center-align">
        <h5><strong>{{departmentDetail.name}}</strong></h5>
    </div>
    <div class="col l1"></div>
    <div class="col s12 l10" *ngIf="departmentDetail">
        <div class="row">
            <img *ngIf="departmentDetail.logo" [src]="departmentDetail.logo" style="text-align: center;" />
            <span *ngIf="!departmentDetail.logo" class="initials">{{departmentDetail.shortName}}</span>
        </div>

        <ul class="collection with-header">
            <li class="collection-item">
                <div>Address<a class="secondary-content">{{departmentDetail.address}} </a></div>
            </li>
            <li class="collection-item">
                <div>Email<a class="secondary-content">{{departmentDetail.email}} </a></div>
            </li>
            <li class="collection-item">
                <div>Fax<a class="secondary-content"> {{departmentDetail.phone}}</a></div>
            </li>
            <li class="collection-item">
                <div>Fax<a class="secondary-content"> {{departmentDetail.fax}}</a></div>
            </li>
            <li class="collection-item">
                <div>Fax<a class="secondary-content"> {{departmentDetail.postal_code}}</a></div>
            </li>
            <li class="collection-item">
                <div>Mandate<a class="secondary-content">{{departmentDetail.mandate}} </a></div>
            </li>
        </ul>

        <ul class="collection with-header">
            <li class="collection-header">
                <h5>Contact</h5>
            </li>
            <li class="collection-item">
                <div>Name<a class="secondary-content"> {{departmentDetail.contactName}}</a></div>
            </li>
            <li class="collection-item">
                <div>Title<a class="secondary-content"> {{departmentDetail.contactTitle}}</a></div>
            </li>
            <li class="collection-item">
                <div>Email<a class="secondary-content"> {{departmentDetail.contactEmail}}</a></div>
            </li>
            <li class="collection-item">
                <div>Mobile<a class="secondary-content"> {{departmentDetail.contactMobile}}</a></div>
            </li>
            <li class="collection-item">
                <div>Office Phone Number<a class="secondary-content">{{departmentDetail.contactOfficePhone}} </a></div>
            </li>
        </ul>

        <ul class="collection with-header">
            <li class="collection-header">
                <h5>Social Media</h5>
            </li>
            <li class="collection-item">
                <div>Address<a class="secondary-content">{{departmentDetail.deptFacebookAcc}} </a></div>
            </li>
            <li class="collection-item">
                <div>Email<a class="secondary-content">{{departmentDetail.deptInstagramAcc}} </a></div>
            </li>
            <li class="collection-item">
                <div>Fax<a class="secondary-content"> {{departmentDetail.deptLinkedinAcc}}</a></div>
            </li>
            <li class="collection-item">
                <div>Mandate<a class="secondary-content">{{departmentDetail.deptTwitterAcc}} </a></div>
            </li>
        </ul>
    </div>

    <button class="col s12 btn wave-effect" (click)="backToList()" type="button">
        <i class="material-icons left">arrow_back</i>Back To Previous Page
    </button>
    <div class="col l1"></div>
</div>