import { Component, OnInit } from '@angular/core';
import { Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { ObjectiveService } from 'src/app/services/api/objective.service';
import { ObjectiveModel } from 'src/app/models/ObjectiveModel';
import { toast } from 'materialize-css';
import { ActivatedRoute } from '@angular/router';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-edit-objective',
  templateUrl: './edit-objective.component.html',
  styleUrls: ['./edit-objective.component.css']
})
export class EditObjectiveComponent implements OnInit {

  clientID = 0;
  editObjectiveForm: UntypedFormGroup;
  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  model: ObjectiveModel;
  id = '';

  constructor(private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private location: Location,
    private objectiveService: ObjectiveService) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });

    if (this.id) {
      let newId = 0;
      newId = + this.id;

      this.editObjectiveForm = this.fb.group({
        Objective: ['', [Validators.required]],
      });

      this.objectiveService.getObjectiveById(newId).subscribe(x => {
        if (x) {
          this.model = x[0];
          this.editObjectiveForm.patchValue({ Objective: this.model.Objective });

          setTimeout(() => {
            M.updateTextFields();
          }, 25);
        }
      });
    }
  }

  get Objective() { return this.editObjectiveForm.get('Objective'); }

  submitForm(value: any) {
    if (this.editObjectiveForm.valid) {
      this.model.Objective = value.Objective;
      this.objectiveService.updateObjective(this.model).subscribe(x => {
        if (x) {
          toast({ html: 'Objective Successfully Updated!', classes: 'green' });
          this.location.back();
        }
      });
    }
  }

  backClicked() {
    this.location.back();
  }

}
