
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { RegistrationFormModel } from 'src/app/models/isets/RegistrationFormModel';
import { ApiKeyManagerService } from './../apiKeyManager.Service';


@Injectable({
    providedIn: 'root'
  })
  export class RegistrationFormService {
  
    url = environment.firstNation.apiUrl + 'api/ISETRegistrationForm/';
    
    constructor(private httpClient: HttpClient, private keyManager: ApiKeyManagerService) { }

    saveRegistrationForm(model: RegistrationFormModel): Observable<any>{
        const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
        return this.httpClient.post<any>(this.url + 'SaveRegistrationForm', JSON.stringify(model), { headers });
    } 

    checkIFMemberExist(model: any): Observable<any> {
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
      return this.httpClient.post<any>(this.url + 'checkIfClientExist/', JSON.stringify(model), { headers });
    }
    

  }