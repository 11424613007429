import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { StaticContentService } from 'src/app/services/firebase/static-content.service';
//import { EmbedVideoService } from 'ngx-embed-video';
import { Location } from '@angular/common';
import { el } from 'date-fns/locale';
import { AuthService } from 'src/app/services/firebase/auth.service';

declare var $: any;

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})

export class NewsComponent implements OnInit {

  model: any[];
  themeColor = environment.appTheme.themeColor;
  globalUser: any;
  videoId: any;

  constructor(private location: Location, 
    private staticContentService: StaticContentService,
   // private embedService: EmbedVideoService, 
    private authService: AuthService) {
      this.globalUser = this.authService.getGlobalUser();
  }

  ngOnInit() {
    this.staticContentService.getContentList().valueChanges().subscribe(x => {
      if (x) {
        this.model = x;
        this.model = this.model.filter(data => data.published === true);
        if (!this.globalUser?.verified) {
          this.model = this.model.filter(data => data.visibility === 'Public');
        }

        const userCommunity = this.authService.getGlobalUser().community;
        const uResidency = this.authService.getGlobalUser().residency;
        const userDOB = new Date(this.authService.getGlobalUser().DOB);

        let userResidency = '';
        if (uResidency) { userResidency = 'On Reserve'; }
        if (!uResidency) { userResidency = 'Urban'; }
        let userGender = this.authService.getGlobalUser().Sex;
        if (userGender === 'F') { userGender = 'Female'; }
        if (userGender === 'M') { userGender = 'Male'; }

        if (!this.globalUser?.verified) {
          this.model = this.model.filter(n => n.visibility === 'Public');
        }

        for (let i = this.model.length - 1; i >= 0; i--) {
          if (this.model[i].visibility === 'Private') {

            if (this.model[i].communityFilter) {
              if (this.model[i].communityFilter !== userCommunity) { this.model.splice(i, 1); }
            }
            if (this.model[i].genderFilter) {
              if (this.model[i].genderFilter !== userGender) { this.model.splice(i, 1); }
            }
            if (this.model[i].residencyFilter) {
              if (this.model[i].residencyFilter !== userResidency) { this.model.splice(i, 1); }
            }
            if (this.model[i].minAgeFilter) {
              const minDOB = new Date(new Date().getFullYear() - this.model[i].minAgeFilter, 1, 1);
              if (userDOB > minDOB) { this.model.splice(i, 1); }
            }
            if (this.model[i].maxAgeFilter) {
              const maxDOB = new Date(new Date().getFullYear() - this.model[i].maxAgeFilter, 1, 1);
              if (userDOB < maxDOB) { this.model.splice(i, 1); }
            }
          }
        }

        this.model.sort((a, b) => (a.index < b.index ? -1 : 1));
        this.model.forEach(el => {
          if (el.type === 'Video') {
            const link = el.videoLink;
            el.videoLink = this.embedVideo(link);
            if (el.visibility === undefined) {
              el.visibility = 'Public';
            }
          }
        });
        setTimeout(() => {
          $('.materialboxed').materialbox();
        }, 100);
      }
    });

    setTimeout(() => {
      $('.parallax').parallax();
    }, 25);


  }

  embedVideo(videoLink) {
    const videoIdMatch = videoLink.match(/(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([^&]+)|(?:https?:\/\/)?(?:www\.)?youtu\.be\/([^?]+)/);
    this.videoId = videoIdMatch ? (videoIdMatch[1] || videoIdMatch[2]) : '';
    return this.videoId;
  }

  //news
  truncateHTML(text: string, limit: string): string {

    const charlimit = +limit;
    if (!text || text.length <= charlimit) {
      return text.replace(/<(?:.|\n)*?>/gm, '').replace(/&nbsp;/gm, ' ');
    }
    return text.replace(/<(?:.|\n)*?>/gm, '').replace(/&nbsp;/gm, ' ').substring(0, charlimit) + '...';
  }

  backClicked() {
    this.location.back();
  }

}
