<div class="col s12">
  <div class="body-h">

    <div class="header-setting b-m-2">
      <div class="col l9 m9 s12">
        <h4 *ngIf="!editName">
          <span *ngIf="sectionName">{{sectionName}}</span>
          <span *ngIf="!sectionName">Manage Admins</span>
          <i class="material-icons edit-name " [ngStyle]="{'color': themeColor}" (click)="editNameClicked()">edit</i>
        </h4>
        <div class="input-field" *ngIf="editName">
          <input type="text" [(ngModel)]="sectionName" class="left col s6 validate" autocomplete="off" />
          <button type="button" class="btn ll-m-5" [ngStyle]="{'background-color': themeColor}" (click)="saveName()">Save</button>
        </div>

        <p class="col s12 bold-300 sub-title-section3">Streamline the management of your
          admin team and ensure smooth operations.</p>
      </div>

      <div class="col l3  m4 s12 p-m-2 ">
        <a [routerLink]="['/admin/dashboard-sub-menu/invite-admin']" class="add-button btn white center-align p-l-3"
          [ngStyle]="{'color': themeColor}"><i class="material-icons-outlined">add</i> Add&nbsp;
          <span *ngIf="sectionName">{{sectionName}}</span>
          <span *ngIf="!sectionName">Admin</span></a>
      </div>
    </div>

    <main class=" container10">

      <div class="col l5 right s12 p-t-3 p-b-3 hide-on-med-and-up">
        <ul class="tabs">
          <li class="tab col s6"><a href="#registred">Admin</a></li>
          <li class="tab col s6"><a href="#invited">Invited</a></li>
        </ul>
      </div>

      <div class="col l4 m4 hide-on-small-only">
        <ul class="tabs">
          <li class="tab col s6"><a href="#registred">Admin</a></li>
          <li class="tab  col s6"><a href="#invited">Invited</a></li>
        </ul>
      </div>

      <div class="row white" id="registred">
        <app-firebase-loading *ngIf="loading || !admins"></app-firebase-loading>
        <div *ngIf="admins && admins.length > 0">

          <p class="right-align">
            <label>
              <input type="checkbox" (click)="includeDisabled()" />
              <span>Include Disabled Account</span>
            </label>
          </p>

          <table class="row-border hover centered">
            <thead>
              <tr>
                <th>Name</th>
                <th>Registered</th>
              </tr>
            </thead>

            <tbody>
              <tr *ngFor="let m of admins">
                <td>
                  <a [routerLink]="['/admin/dashboard-sub-menu/admin-detail', m.id]"> {{m.LastName}}
                    {{m.FirstName}}</a>
                  <i *ngIf="m.status === 'Disabled'" class="material-icons left red-text tooltipped"
                    data-position="right" data-tooltip="Account Disabled">
                    person_off
                  </i>
                </td>
                <td><i class="material-icons green-text">check_circle</i></td>
              </tr>
            </tbody>
          </table>

          <div class="row col s12 center" *ngIf="disabledAdmins && disabledAdmins.length > 0">
            <a *ngIf="!include" class="btn-small waves-effect waves-grey white grey-text" (click)="includeDisabled()"
              style="border-radius: 10px; border: 1px #e0e0e0 solid; margin-top: 25px;"> Show Disabled Accounts
              <i class="material-icons right">
                unfold_more
              </i></a>

            <a *ngIf="include" class="btn-small waves-effect waves-grey white red-text" (click)="includeDisabled()"
              style="border-radius: 10px; border: 1px #e0e0e0 solid; margin-top: 25px;"> Hide Disabled Accounts
              <i class="material-icons right">
                unfold_less
              </i></a>
          </div>
        </div>

        <div class="row card-panel black-text center p-t-10" *ngIf="!admins || admins.length == 0">
          <img src="../../assets/img/empty-folder.png" height="150" width="150">
          <h6 class="bold-200">Sorry , No Registred Admins found !!</h6>
        </div>
      </div>

      <div class="row" id="invited">
        <app-firebase-loading *ngIf="loading || !adminInvitations"></app-firebase-loading>

        <div *ngIf="adminInvitations && adminInvitations.length > 0">
          <table class="striped highlight centered hover responsive-table">
            <thead>
              <tr>
                <th>Given Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Status</th>
              </tr>
            </thead>

            <tbody>
              <tr *ngFor="let admin of adminInvitations">
                <td>
                  <a>{{admin.firstName}}</a>
                </td>
                <td>
                  <a>{{ admin.lastName}}</a>
                </td>
                <td>
                  <a>{{ admin.email}}</a>
                </td>
                <td> {{ admin.status}}</td>
                <td> {{admin.invitationDate.toDate() | date: 'dd/MMM/yyyy' }}</td>
                <td> <i class="material-icons red-text modal-trigger tooltipped" data-position="top"
                    data-tooltip="Delete Invitation" (click)="deleteInviation(admin.id)" style="cursor: pointer;"
                    data-target="confirmDeleteModal">delete</i></td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="row" *ngIf="!adminInvitations || adminInvitations.length == 0">
          <div class="card-panel black-text center p-t-10">
            <img src="../../assets/img/empty-folder.png" height="150" width="150">
            <h6 class="bold-200">Sorry , No Invited Admins found !!</h6>
          </div>
        </div>

        <div id="confirmDeleteModal" class="modal">
          <div class="modal-content">
            <h5 class="bold-700">Delete Invitation</h5>
            <div class="valign-wrapper card-panel red lighten-5">
              <i class="material-icons left red-text" style="font-size: 63px;">
                warning
              </i>
              <div>
                <h6>Are You sure you want to delete this Invitation? </h6>
                <h6><strong>This data will be permanently deleted. This action cannot be reversed.</strong></h6>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <a class="modal-close waves-effect waves-light btn-flat"><strong>Cancel</strong></a>
            <a (click)="delete()" class="modal-close btn waves-effect waves-light red"><strong>Delete</strong></a>
          </div>
        </div>

      </div>
    </main>

  </div>
</div>