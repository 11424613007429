<div class="row">
  <div>
    <div class="center" *ngIf="electionDetail">
      <h5 *ngIf="electionDetail.name"> {{electionDetail.name}}</h5>
    </div>
  </div>
</div>
<div *ngIf="candidateData.length > 0">
  <div>
    <div class="row">
      <div class="col s12 m12">
        <div class="card" *ngFor="let candidate of candidateData">
          <div class="card-image">
            <div class="background-img">
              <div class="background-with-color" [ngStyle]="{'background-color': themeColor}">
                <div></div>
              </div>
              <div class="prof-img">
                <div *ngIf="!candidate.candidateData.profileImgLink">
                  <img class="background-img-avatar" src="../../../../../assets/img/profile.jpg"
                    style="width: 120px; border: white solid 3px; border-radius: 50%;" />
                </div>
                <div *ngIf="candidate.candidateData.profileImgLink">
                  <img class="background-img-avatar" [src]="candidate.candidateData.profileImgLink"
                    style="width: 120px; height: 120px; border: white solid 3px; border-radius: 50%;" />
                </div>
              </div>
            </div>
          </div>

          <div class="card-content">
            <div class="center">
              <h5 class="gray-text text-lighten-2" *ngIf="candidate.candidateData.GivenName">
                {{candidate.candidateData.GivenName }} {{candidate.candidateData.LastName}}</h5>
            </div>
            <div class="center">
              <p *ngIf="candidate.candidateData.Band"> {{candidate.candidateData.Band}}</p>

            </div>
            <div class="center">
              <p *ngIf="candidate.candidateData.community"> {{candidate.candidateData.community}}</p>
            </div>
            <br />
            <div class="center">
              <button *ngIf="candidate.vote" class="btn btn-with-shadow">{{candidate.vote.length}} Votes</button>
            </div>
          </div>
          <hr />
          <br />
          <div class="progress blue lighten-4" style="width: 96%; margin: auto;">
            <div class="determinate blue" style="animation: grow 2s;"
              [style.width.%]="candidate.vote.length/totalVote * 100">65%</div>
          </div>
          <div class="center" *ngIf="winningVote > 0 && totalVote > 0">
            <span class="green-text">{{(candidate.vote.length/totalVote)*100}}% </span>
            <span style="padding-left: 5px;"> {{candidate.vote.length}}/{{totalVote}}</span>
          </div>
          <br />
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row" *ngIf="electionDetail && showWinner">
  <div class="col s12 m12">
    <div class="card">
      <div class="card-content">
        <div *ngIf="winnersObject.length > 0 ">
          <div *ngIf="winnersObject.length === 1">
            <p class="red-text center-align"><strong>Winner</strong></p>
            <hr />
            <div style="border: gray solid 1px;">
              <p *ngIf="winnersObject[0].candidateData.GivenName && winnersObject[0].candidateData.LastName">
                <strong>{{winnersObject[0].candidateData.GivenName}}
                  {{winnersObject[0].candidateData.LastName}}</strong>
              </p>
              <p></p>
              <p *ngIf="winnersObject[0].vote">With <strong>{{winnersObject[0].vote.length}} vote</strong></p>
            </div>
          </div>
          <div *ngIf="winnersObject.length > 1">
            <p class="red-text center-align"><strong>Multiple winners</strong></p>
            <hr />
            <div *ngFor="let winner of winnersObject; let i = index">
              <p><strong>Winner {{i+1}}</strong></p>
              <div style="border: gray solid 1px;">
                <p *ngIf="winner.candidateData.GivenName && winner.candidateData.LastName">
                  <strong>{{winner.candidateData.GivenName}} {{winner.candidateData.LastName}}</strong></p>
                <p></p>
                <p *ngIf="winner.vote">With <strong>{{winner.vote.length}} vote</strong></p>
              </div>
              <br />
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="!electionDetail" class="center  red lighten-4">
        <br />
        <p>Result unavailable</p>
        <br />
        <div class="center ">
          <button class="btn light-blue darken-3" (click)="backClicked()">Okay</button>
        </div>
        <br />
      </div>
    </div>
  </div>
</div>

<div class="row col s12" *ngIf="!showWinner && candidateData.length> 0">
  <button [ngClass]="'col s12 btn waves-effect green lighten-2' + themeColor" (click)="displayWinner()">
    <i class="material-icons left">emoji_events</i> View Winner
  </button>
</div>

<div *ngIf="candidateData.length == 0 && electionDetail">
  <div class="row">
    <div class="col s12 m6">
      <div class="card red lighten-4">
        <div class="card-content black-text center">
          <p>No candidates have been choosen</p>
          <br />
          <div class="center">
            <button class="waves-effect waves-light btn" (click)="backClicked()">Okay</button>
          </div>
          <br />
        </div>
      </div>
    </div>
  </div>
</div>