import { Component, Input, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { EmployeeRegRequestService } from 'src/app/services/firebase/employee-reg-request.service';
import { Location } from '@angular/common';
import { AddBandMemberModel, MemberMatchModel } from 'src/app/models/AddMemberModel';
import { MemberService } from 'src/app/services/api/member.service';
import { toast } from 'materialize-css';
import { environment } from 'src/environments/environment';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { DepartmentService } from 'src/app/services/firebase/department.service';
import { FireMembersService } from 'src/app/services/firebase/fire-members.service';
import { EmailModel } from 'src/app/models/emailModel';
import { EmailService } from 'src/app/services/api/email.service';
import { DataService } from 'src/app/services/data.service';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-registration-request-employee',
  templateUrl: './registration-request-employee.component.html',
  styleUrls: ['./registration-request-employee.component.css']
})
export class RegistrationRequestEmployeeComponent implements OnInit {

  @Input() departmentID: any;
  provinces: any[];

  Status = ['Pending', 'Invited', 'Authenticated', 'Declined', 'Archived'];
  public dateformat = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;

  url = environment.firstNation.website;
  loading = false;
  registrationRequests: any[];
  request: any;
  hasMatch = false;
  hasISNMatch = false;
  hasNoMatch = false;
  matches: any[];
  memberId: any;
  memberSelected = false;
  memberAdded = false;
  selectedMember: any;
  firebaseMember: any;
  model: AddBandMemberModel;
  inviteMemberForm: UntypedFormGroup;
  declineReason = '';

  verifySIN = false;
  verifyISN = false;
  verifyDOB = false;
  isManager = false;
  showMobileNo = 'Public';
  showOfficeNo = 'Public';
  showEmailAdr = 'Public';
  allowContact = 'Public';
  manageAccount = false;
  manageContact = false;
  manageDepartmentInfo = false;
  manageDepartmentNews = false;
  manageDepartmentEmployees = false;
  manageSubDepartments = false;
  canManageEmployees = false;
  managerName: any;
  managerEmail: any;

  step1 = true;
  step2 = false;
  step3 = false;
  step4 = false;

  constructor(private formBuilder: UntypedFormBuilder,
    private empRegReq: EmployeeRegRequestService,
    private authService: AuthService,
    private memberService: MemberService,
    private departmentService: DepartmentService,
    private fireMemberService: FireMembersService,
    private emailService: EmailService,
    private location: Location,
    private dataService: DataService) {
    this.provinces = this.dataService.getProvinceWithCode();
  }

  ngOnInit(): void {
    if (this.departmentID) {
      this.loading = true;
      if (this.departmentID) {
        this.empRegReq.getDepartmentRequests(this.departmentID).valueChanges().subscribe(x => {
          if (x && x.length > 0) {
            this.registrationRequests = x;
            this.registrationRequests = this.registrationRequests.sort((d1, d2) => new Date(d2.datetime.toDate()).getTime() - new Date(d1.datetime.toDate()).getTime());
          }
          this.loading = false;
        });
      }
    }

    let loggedInUser = this.authService.getGlobalUser();
    if (loggedInUser) {
      this.managerName = loggedInUser.GivenName + ' ' + loggedInUser.LastName;
      this.managerEmail = loggedInUser.Email;
    }
  }

  changeDates(dateValue) {
    if (dateValue) {
      if (dateValue.match(this.dateformat)) {
        let dateArray = dateValue.split('/');
        let year = dateArray[2];
        let month = dateArray[1] - 1;
        let day = dateArray[0];
        const date = new Date(year, month, day);
        return date;
      } else {
        const date = new Date(dateValue);
        return date;
      }
    } else {
      return null
    }
  }

  showReqDetails(request) {
    this.request = request;
    this.step1 = false;
    this.step2 = true;
  }

  onApprove() {
    this.memberSelected = false;
    this.memberAdded = false;
    this.memberId = null;
    this.selectedMember = null;
    this.hasMatch = false;
    this.hasNoMatch = false;
    this.hasISNMatch = false;
    this.matches = [];
    this.verifySIN = false;
    this.verifyISN = false;
    this.checkForMatch();
  }

  checkForMatch() {
    let model = new MemberMatchModel();
    model.GivenName = this.request.Fname;
    model.LastName = this.request.Lname;
    model.IndianStatusNo = this.request.isn;
    this.memberService.chekForPossibleMatch(model).subscribe(result => {
      if (result && result.length > 0) {
        if (model.IndianStatusNo && model.IndianStatusNo != "") {
          let isnMatch = result.filter(member => member.IndianStatusNo == model.IndianStatusNo);
          if (isnMatch.length > 0) this.hasISNMatch = true;
        }
        this.hasMatch = true;
        this.matches = result;
        $('.modal').modal();
        $('#similarMembersModal').modal('open');
      } else {
        this.hasMatch = false;
        this.hasNoMatch = true;
        $('.modal').modal();
        $('#noMatchFound').modal('open');
      }
    });
  }

  selectMatch(index) {
    this.memberSelected = true;
    this.selectedMember = this.matches[index];
    this.memberId = this.matches[index].applicantID;
    $('.modal').modal('close');
    this.inviteMember();
  }

  addMember() {
    this.model = new AddBandMemberModel();
    this.model.GivenName = this.request.Fname;
    this.model.LastName = this.request.Lname;
    this.model.middle_name = this.request.Mname;
    if (this.request.dob && (typeof this.request.dob) == 'string') {
      this.request.dob = this.changeDates(this.request.dob);
    }
    this.model.DOB = this.request.dob;
    //this.model.DOB = new Date();
    if (this.request.gender == 'Female') this.request.gender = 'F';
    else if (this.request.gender == 'Male') this.request.gender = 'M';
    this.model.Sex = this.request.gender;
    this.model.IndianStatusNo = this.request.isn;
    this.model.Email = this.request.email;
    this.model.cell_phone_num = this.request.cellPhone;
    this.model.HealthCareNo = this.request.healthCareNumber;
    this.model.MailAddress = this.request.address;
    this.model.city = this.request.city;
    const province = this.provinces.filter(prov => prov.name == this.request.province);
    this.model.province = province[0].code;;
    this.model.PostalCode = this.request.postalCode;
    this.model.SIN = this.request.sin;
    this.model.Community = this.request.community;
    this.model.Officer = this.request.officer;
    this.model.date_added = new Date();
    this.model.is_employee = true;

    this.memberService.AddMember(this.model).subscribe(resp => {
      if (resp) {
        toast({ html: 'Member Added successfully!', classes: 'green' });
        this.memberAdded = true;
        this.memberId = resp;
        this.selectedMember = this.model;
        this.selectedMember.applicantID = resp;
        $('.modal').modal('close');
        this.inviteMember();
      }
    });
  }

  inviteMember() {
    this.inviteMemberForm = this.formBuilder.group({
      jobTitle: [''],
      description: [''],
      workPhone: [''],
      mobilePhone: [''],
      email: [this.request.email]
    });
    this.step2 = false;
    this.step3 = true;

    setTimeout(() => {
      M.updateTextFields();
    }, 25);
  }

  get jobTitle() { return this.inviteMemberForm.get('jobTitle'); }
  get workPhone() { return this.inviteMemberForm.get('workPhone'); }
  get mobilePhone() { return this.inviteMemberForm.get('mobilePhone'); }
  get description() { return this.inviteMemberForm.get('description'); }
  get email() { return this.inviteMemberForm.get('email'); }

  inviteEmployee(values) {
    const model = {
      id: this.departmentService.createID(),
      departmentId: this.departmentID,
      memberId: this.selectedMember.applicantID,
      givenName: this.selectedMember.GivenName,
      lastName: this.selectedMember.LastName,
      email: values.email,
      validateSin: this.verifySIN,
      validateIsn: this.verifyISN,
      validateDob: this.verifyDOB,
      jobTitle: values.jobTitle,
      workPhone: values.workPhone,
      mobilePhone: values.mobilePhone,
      description: values.description,
      showMobileNo: this.showMobileNo,
      showOfficeNo: this.showOfficeNo,
      showEmailAdr: this.showEmailAdr,
      allowContact: this.allowContact,
      manageAccount: this.manageAccount,
      manageContact: this.manageContact,
      manageDepartmentInfo: this.manageDepartmentInfo,
      manageDepartmentNews: this.manageDepartmentNews,
      manageDepartmentEmployees: this.manageDepartmentEmployees,
      manageSubDepartments: this.manageSubDepartments,
      role: 'Employee',
      status: 'Pending',
      isManager: false,
      invitationDate: new Date(),
      validationPassword: this.generateRandomPassword(),
      invitedByName: this.managerName,
      InvitedByEmail: this.managerEmail,
    };

    this.loading = true;
    this.departmentService.sendInvitationToDepartmentEmployee(model).then(x => {
      this.fireMemberService.getMemberByAppliciantId(this.memberId).valueChanges().subscribe(x => {
        if (x && x.length > 0) {
          this.firebaseMember = x[0];
          if (this.firebaseMember.role == 'Member') {
            $('.modal').modal({ dismissible: false });
            $('#inviteMember').modal('open');
          }
        } else {
          this.SendRegistrationLink(this.memberId);
        }
        this.loading = false;
      })
    })
  }

  SendRegistrationLink(applicantID) {
    this.url = window.location.hostname !== 'localhost' ? this.url : 'http://localhost:4200/#';
    //const url = this.url + '/auth/regiter-department-employee/' + applicantID;
    const url = this.url + '/auth/employee-register/' + applicantID;

    const invitationModel = {
      ApplicantID: applicantID,
      verifySIN: this.verifySIN,
      verifyISN: this.verifyISN,
      secretQuestion: '',
      secretAnswer: '',
      email: this.request.email,
    };
    const msg = new EmailModel();
    msg.emailList = [];
    msg.emailList.push(this.request.email);
    msg.subject = 'Registration Request Approved';
    msg.body = '<h2>Your Request to join the my nation app has been Approved </h2>' +
      '<p>Please click the following link to complete your registration.</p>' +
      '<p> <strong> Registration Link : </strong> </p> <a href="' + url + '" target="_blank" class="center"> ' + url + ' </a>' +
      '<br/> <p><em> Thank you for using our App.</em> </p>';

    this.loading = true;
    this.emailService.sendEmail(msg).subscribe(x => {
      if (x.Status && x.Status == "OK") {
        toast({ html: 'Registration Link sent successfully!', classes: 'green' });
        this.memberService.InviteMember(invitationModel).subscribe(res => {
          if (res == 'Success') {
            $('.modal').modal('close');
            this.changeRequestStatus(2, applicantID);
            this.step3 = false;
            this.step4 = true;
            this.loading = false;
          }
        });
      }
      else {
        toast({ html: 'Unable to send Registration Link!', classes: 'red' });
        this.loading = false;
        $('.modal').modal('close');
        this.backToList();
      }
    });
  }

  invite() {
    const mail = new EmailModel();
    mail.emailList = [this.request.email];
    mail.subject = 'Account Update ';
    mail.body = '<h2>Hello, </h2> <p>Your account has been upgraded to an employee. </p> <p> <strong>Please Use your member account credentials to log into the app. </strong> </p> </p> <br/> <p>Thank you for using Our App. </p>';

    this.firebaseMember.role = 'Employee';
    this.firebaseMember.isMember = true;

    this.loading = true;
    this.fireMemberService.updateAccountRole(this.firebaseMember).then(x => {
      this.emailService.sendEmail(mail).subscribe(x => {
        if (x.Status && x.Status == "OK") {
          this.changeRequestStatus(3, this.firebaseMember.applicantID);
          $('.modal').modal('close');
          toast({ html: 'Employee Invitaion sent successfully!', classes: 'green' });
          this.backToList();
        }
        else {
          toast({ html: 'Unable to send Invitation Link!', classes: 'red' });
          $('.modal').modal('close');
          this.backToList();
        }
      });
      this.loading = false;
    });
  }

  onDecline() {
    this.declineReason = '';
    $('.modal').modal();
    $('#declinModal').modal('open');
    M.textareaAutoResize($('#reason'));
  }

  Decline() {
    const msg = new EmailModel();
    msg.emailList = [];
    msg.emailList.push(this.request.email);
    msg.subject = 'Registration Request Declined';
    msg.body = '<h2>Your Request has been Approved. </h2> <p> Your request to register at as an employee on myNation was Declined Because of </p> <p>' + this.declineReason + '<p/> <br/> <p><strong> Thank you for using Our App.  </strong> </p>';

    this.loading = true;
    this.emailService.sendEmail(msg).subscribe(x => {
      if (x.Status && x.Status == "OK") {
        this.changeRequestStatus(4);
        toast({ html: 'The request has been declined!', classes: 'blue' });
        this.backToList();
        this.loading = false;
        $('#declinModal').modal('close');
      }
      else {
        toast({ html: 'Unable to decline the reqeust!', classes: 'red' });
        this.loading = false;
        this.backToList();
        $('#declinModal').modal('close');
      }
    });
  }

  changeRequestStatus(status, applicantID?) {
    if (status == 4) {
      this.empRegReq.updateRequestStatus(this.request.id, status, this.declineReason).then(x => {
      });
    } else {
      this.empRegReq.updateRequestStatus(this.request.id, status).then(x => {
      });
    }
    if (applicantID) {
      this.empRegReq.linkRequestToMember(this.request.id, applicantID).then(x => {
      })
    }
  }

  OnDelete() {
    $('.modal').modal();
    $('#deleteConfirmation').modal('open');
  }

  DeleteRequest() {
    this.empRegReq.deleteRequest(this.request.id).then(x => {
      toast({ html: 'Request has been Deleted!', classes: 'green' });
      this.backClicked();
    });
  }

  validateSinClicked() {
    this.verifySIN = !this.verifySIN;
  }

  validateIsnClicked() {
    this.verifyISN = !this.verifyISN;
  }

  validateDobClicked() {
    this.verifyDOB = !this.verifyDOB;
  }

  manageAccountClicked() {
    this.manageAccount = !this.manageAccount;
  }

  manageContactClicked() {
    this.manageContact = !this.manageContact;
  }

  manageDepartmentInfoClicked() {
    this.manageDepartmentInfo = !this.manageDepartmentInfo;
  }

  manageDepartmentNewsClicked() {
    this.manageDepartmentNews = !this.manageDepartmentNews;
  }

  manageDepartmentEmployeesClicked() {
    this.manageDepartmentEmployees = !this.manageDepartmentEmployees;
  }

  manageSubDepartmentsClicked() {
    this.manageSubDepartments = !this.manageSubDepartments;
  }

  privateMobileClick() {
    this.showMobileNo = 'Private';
  }

  publicMobileClick() {
    this.showMobileNo = 'Public';
  }

  membersMobileClick() {
    this.showMobileNo = 'Members';
  }

  privateOfficeClick() {
    this.showOfficeNo = 'Private';
  }

  publicOfficeClick() {
    this.showOfficeNo = 'Public';
  }

  membersOfficeClick() {
    this.showOfficeNo = 'Members';
  }

  privateEmailClick() {
    this.showEmailAdr = 'Private';
  }

  publicEmailClick() {
    this.showEmailAdr = 'Public';
  }

  membersEmailClick() {
    this.showEmailAdr = 'Members';
  }

  privateContactClick() {
    this.allowContact = 'Private';
  }

  publicContactClick() {
    this.allowContact = 'Public';
  }

  membersContactClick() {
    this.allowContact = 'Members';
  }

  generateRandomPassword() {
    length = 10;
    const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let retVal = '';
    for (let i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }

  cancel() {
    $('.modal').modal('close');
    $('select').formSelect();
    M.updateTextFields();
  }

  closeModal() {
    $('.modal').modal('close');
  }

  backToList() {
    this.step1 = true;
    this.step2 = false;
    this.step3 = false;
    this.step4 = false;
  }

  back() {
    this.step3 = false;
    this.step2 = true;
  }

  backClicked() {
    this.location.back();
  }
}
