import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { EmploymentService } from 'src/app/services/api/employment.service';
import { EmploymentService as EmploymentFirebaseService } from 'src/app/services/firebase/employment.service';
import { toast } from 'materialize-css';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';
import { EmploymentModel } from 'src/app/models/employmentModel';
import { ActivatedRoute } from '@angular/router';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import { Router } from '@angular/router';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-edit-employment',
  templateUrl: './edit-employment.component.html',
  styleUrls: ['./edit-employment.component.css']
})

export class EditEmploymentComponent implements OnInit {

  myDpOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'dd/mmm/yyyy',
    markCurrentDay: true,
  };

  constructor(private fb: UntypedFormBuilder,
    private location: Location,
    private router: Router,
    private employmentService: EmploymentService,
    private route: ActivatedRoute,
    private employmentFirebaseService: EmploymentFirebaseService) { }

  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  provinces: any = ['Alberta', 'British Columbia', 'Manitoba', 'New Brunswick',
    'Newfoundland and Labrador', 'Nova Scotia', 'Ontario', 'Prince Edward Island', 'Quebec', 'Saskatchewan'];
  editEmploymentForm: UntypedFormGroup;
  model: any;
  id = '';
  collectionEmploymentId = '';
  sqldb = environment.firstNation.SQLDB;
  require_end_date = false;
  showEndDate = false;

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
      if (!this.sqldb) {
        this.collectionEmploymentId = this.id.toString();
      }
    });

    if (this.id) {
      let newId = 0;
      newId = + this.id;
      if (this.sqldb) {
        this.employmentService.getEmploymentByID(newId).subscribe(x => {
          if (x) {
            this.model = x[0];
            this.makeFormValidation();
            this.patchFormValue(this.model);
          }
          setTimeout(() => {
            $('select').formSelect();
            $('.modal').modal();
            M.updateTextFields();
          }, 25);

        });
      } else {
        this.employmentFirebaseService.getEmploymentById(this.collectionEmploymentId).valueChanges().subscribe(x => {
          if (x) {
            this.model = { ...x, start_date: new Date(x.start_date['seconds'] * 1000), end_date: new Date(x.end_date['seconds'] * 1000) }
            this.makeFormValidation();
            this.patchFormValue(this.model);
          }
          setTimeout(() => {
            $('select').formSelect();
            $('.modal').modal();
            M.updateTextFields();
          }, 25);
        });
      }
    }
  }

  makeFormValidation() {
    this.editEmploymentForm = this.fb.group({
      Name: ['', [Validators.required]],
      employer: ['', [Validators.required]],
      City: ['', [Validators.required]],
      Prov: ['', [Validators.required]],
      description: ['', [Validators.required]],
      start_date: ['', [Validators.required]],
      end_date: [''],
      iscurrent: [null, [Validators.required]],
      reason: [''],
    });

    this.editEmploymentForm.get('iscurrent').valueChanges.subscribe(value => {
      if (value == 'yes') {
        this.editEmploymentForm.get('end_date').clearValidators();
      } else {
        this.editEmploymentForm.get('end_date').setValidators(Validators.required);
      }
      this.editEmploymentForm.get('end_date').updateValueAndValidity();
    });
  }

  submitForm(value: any) {
    this.model.start_date = new Date(localStorage.getItem('selectedDate'));
    this.model.iscurrent = (value.iscurrent === 'yes') ? true : false;
    if (!this.model.iscurrent) {
      this.model.end_date = new Date(localStorage.getItem('selectedEndDate'));
      this.model.reason = value.reason;
    } else {
      this.model.end_date = new Date();
      this.model.reason = "";
    }
    this.model.Name = value.Name;
    this.model.description = value.description;
    this.model.employer = value.employer;
    this.model.City = value.City;
    this.model.Prov = value.Prov;
   
    if (this.sqldb) {
      this.employmentService.editEmployemnt(this.model).subscribe(x => {
        if (x) {
          toast({ html: 'Work Experience Successfully Updated!', classes: 'green' });
          this.backClicked();
        }
      });
    } else {
      this.employmentFirebaseService.updateEmployment(this.model).then(x => {
        toast({ html: 'Employment Successfully Updated!', classes: 'green' });
        this.backClicked();
      });
    }
  }

  patchFormValue(values: EmploymentModel) {
    this.editEmploymentForm.patchValue({ Name: values.Name });
    this.editEmploymentForm.patchValue({ description: values.description });
    this.editEmploymentForm.patchValue({ employer: values.employer });
    this.editEmploymentForm.patchValue({ City: values.City });
    this.editEmploymentForm.patchValue({ Prov: values.Prov });

    const newStartDate = new Date(values.start_date);
    let model1: IMyDateModel = { isRange: false, singleDate: { jsDate: newStartDate }, dateRange: null };
    this.editEmploymentForm.patchValue({ start_date: model1 });

    if (!values.iscurrent) {
      this.showEndDate = true;
      const newEndDate = new Date(values.end_date);
      this.editEmploymentForm.patchValue({ iscurrent: 'no' });
      let model2: IMyDateModel = { isRange: false, singleDate: { jsDate: newEndDate }, dateRange: null };
      this.editEmploymentForm.patchValue({ end_date: model2 });
      this.editEmploymentForm.patchValue({ reason: this.model.reason });
    } else {
      this.showEndDate = false;
      this.editEmploymentForm.patchValue({ iscurrent: 'yes' });
      this.editEmploymentForm.patchValue({ end_date: null });
      this.editEmploymentForm.patchValue({ reason: '' });
    }
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }

  onStartDateChange(event: IMyDateModel): void {
    localStorage.setItem('selectedDate', event.singleDate.jsDate.toString());
    if (this.editEmploymentForm) { this.editEmploymentForm.patchValue({ start_date: event.singleDate.formatted }); }
  }

  onEndDateChange(event: IMyDateModel): void {
    localStorage.setItem('selectedEndDate', event.singleDate.jsDate.toString());
    if (this.editEmploymentForm) { this.editEmploymentForm.patchValue({ end_date: event.singleDate.formatted }); }
  }

  onCurrentEmploymentYes() {
    this.showEndDate = false;
    this.editEmploymentForm.patchValue({ end_date: '' });
    this.editEmploymentForm.patchValue({ reason: '' });
  }

  onCurrentEmploymentNo() {
    this.showEndDate = true;
    this.editEmploymentForm.patchValue({ end_date: '' });
  }

  DeleteEmployment() {
    $('.modal').modal('close');
    if (this.sqldb) {
      this.employmentService.deleteEmployemnt(this.model.ID).subscribe(resp => {
        toast({ html: 'Employment Removed Successfully!', classes: 'green' });
        this.backClicked();
      });
    }

    if (!this.sqldb) {
      this.employmentFirebaseService.DeleteEmployment(this.model.id).then(x => {
        toast({ html: 'Employment Removed Successfully!', classes: 'green' });
        this.backClicked();
      });
    }
  }

  backClicked() {
    if (localStorage.getItem('employment_call_from') == 'case_plan') {
      this.router.navigate(['/member/profile/case-plan']);
    } else if (localStorage.getItem('employment_call_from') == 'employment') {
      this.router.navigate(['/member/profile/employment']);
    } else { this.location.back(); }
  }

  get Name() { return this.editEmploymentForm.get('Name'); }
  get employer() { return this.editEmploymentForm.get('employer'); }
  get City() { return this.editEmploymentForm.get('City'); }
  get Prov() { return this.editEmploymentForm.get('Prov'); }
  get description() { return this.editEmploymentForm.get('description'); }
  get start_date() { return this.editEmploymentForm.get('start_date'); }
  get end_date() { return this.editEmploymentForm.get('end_date'); }
  get iscurrent() { return this.editEmploymentForm.get('iscurrent'); }
  get reason() { return this.editEmploymentForm.get('reason'); }
  
}
