
<br/>
  <div class="row col s12" *ngIf="!menu && showDefault">

       <a class="col s6 m4" style="cursor: pointer;" (click)="navigate('/about-us')">
      <div class="col s12">
       <div class="row card-panel white z-depth-1 hoverable grey lighten-4">
          <div >
              <div class="col s12 center">
              <i class="material-icons blue-text ">feed</i>
            </div>
            <div class="col s12 center">
              <strong class="black-text  ">About Us</strong>
            </div>
          </div>
        </div>
      </div>
    </a>


    <a class="col s6 m4" style="cursor: pointer;" (click)="navigate('/events-calendar')">
      <div class="col s12">
       <div class="row card-panel white z-depth-1 hoverable grey lighten-4">
          <div class="">
              <div class="col s12 center">
              <i class="material-icons green-text ">event_available</i>
            </div>
            <div class="col s12 center">
              <strong class="black-text ">Events</strong>
            </div>
          </div>
        </div>
      </div>
    </a>


       <a class="col s6 m4" style="cursor: pointer;" (click)="navigate('/departments')">
      <div class="col s12">
       <div class="row card-panel white z-depth-1 hoverable grey lighten-4">
          <div class="">
              <div class="col s12 center">
              <i class="material-icons green-text ">scatter_plot</i>
            </div>
            <div class="col s12 center">
              <strong *ngIf="nationOfUSe === 'Batc'" class="black-text ">Offices</strong>
              <strong *ngIf="nationOfUSe !== 'Batc'" class="black-text ">Departments</strong>
            </div>
          </div>
        </div>
      </div>
    </a>

       <a class="col s6 m4" style="cursor: pointer;" (click)="navigate('/governance')">
      <div class="col s12">
       <div class="row card-panel white z-depth-1 hoverable grey lighten-4">
          <div class="">
              <div class="col s12 center">
              <i class="material-icons orange-text">account_balance</i>
            </div>
            <div class="col s12 center">
              <strong class="black-text ">Governance</strong>
            </div>
          </div>
        </div>
      </div>
    </a>

       <a class="col s6 m4" style="cursor: pointer;" (click)="socialNetworkClicked()">
      <div class="col s12">
       <div class="row card-panel white z-depth-1 hoverable grey lighten-4">
          <div >
              <div class="col s12 center">
              <i class="material-icons teal-text">thumb_up_off_alt</i>
            </div>
            <div class="col s12 center">
              <strong class="black-text ">Follow Us</strong>
            </div>
          </div>
        </div>
      </div>
    </a>

       <a class="col s6 m4" style="cursor: pointer;" (click)="termsAndPolicyClicked()">
      <div class="col s12">
       <div class="row card-panel white z-depth-1 hoverable grey lighten-4">
          <div class="col s12">
              <div class="col s12 center">
              <i class="material-icons blue-text ">security</i>
            </div>
            <div class="col s12 center">
              <strong class="black-text ">Privacy</strong>
            </div>
          </div>
        </div>
      </div>
    </a>

       <a class="col s6 m4" style="cursor: pointer;" (click)="navigate('/contact-nation')">
      <div class="col s12">
       <div class="row card-panel white z-depth-1 hoverable grey lighten-4">
          <div>
              <div class="col s12 center">
              <i class="material-icons pink-text ">email</i>
            </div>
            <div class="col s12 center">
              <strong class="black-text ">Contact</strong>
            </div>
          </div>
        </div>
      </div>
    </a>

       <a class="col s6 m4" style="cursor: pointer;" (click)="navigate('/registration-request')">
      <div class="col s12">
       <div class="row card-panel white z-depth-1 hoverable grey lighten-4">
          <div  >
              <div class="col s12 center">
              <i class="material-icons red-text">person_add</i>
            </div>
            <div class="col s12  center">
              <strong class="black-text">Register</strong>
            </div>
          </div>
        </div>
      </div>
    </a>

       <a class="col s6 m4" style="cursor: pointer;" (click)="employersClicked()">
      <div class="col s12">
       <div class="row card-panel white z-depth-1 hoverable grey lighten-4">
          <div  >
              <div class="col s12 center">
              <i class="material-icons cyan-text">work</i>
            </div>
            <div class="col s12  center">
              <strong class="black-text">Employers</strong>
            </div>
          </div>
        </div>
      </div>
    </a>

    <a class="col s6 m4 modal-trigger" *ngIf="nationOfUSe === 'Demo'" href="#infoModal" style="cursor: pointer;">
        <div class="col s12">
         <div class="row card-panel white z-depth-1 hoverable grey lighten-4">
            <div class="">
                <div class="col s12 center">
                <i class="material-icons pink-text text-darken-3">person_add</i>
              </div>
                <div class="col s12 center">
                <strong class="black-text">Demo Account</strong>
              </div>
            </div>
          </div>
        </div>
      </a>

  </div>


  <!-- <div class="row col s12" *ngIf="menu && !showDefault"> 

    <div *ngFor="let item of moreMenu">
         <a class="col s6 m4" style="cursor: pointer;"
      *ngIf="item.item !== 'Social' && item.item !== 'Privacy' && item.item !== 'Employer' && item.item !== 'Demo Account' && item.item !== 'Departments'"
      (click)="navigate(item.link)">
        <div class="col s12">
          <div class="row card-panel white z-depth-1 hoverable grey lighten-4">
            <div class="">
                <div class="col s12 center">
                <i [ngClass]="'material-icons ' + item.color">{{item.icon}}</i>
              </div>
                <div class="col s12 center">
                <strong class="black-text">{{item.item}}</strong>
              </div>
            </div>
          </div>
        </div>
      </a>

      <a class="col s6 m4" style="cursor: pointer;"
      *ngIf="item.item === 'Departments'"
      (click)="navigate(item.link)">
        <div class="col s12">
         <div class="row card-panel white z-depth-1 hoverable grey lighten-4">
            <div class="">
                <div class="col s12 center">
                <i [ngClass]="'material-icons ' + item.color">{{item.icon}}</i>
              </div>
                <div class="col s12 center">
                  <strong *ngIf="nationOfUSe === 'Batc'" class="black-text ">Offices</strong>
                <strong *ngIf="nationOfUSe !== 'Batc'" class="black-text">{{item.item}}</strong>
              </div>
            </div>
          </div>
        </div>
      </a>

         <a class="col s6 m4" style="cursor: pointer;"
      *ngIf="item.item === 'Social'"
      (click)="socialNetworkClicked()">
        <div class="col s12">
         <div class="row card-panel white z-depth-1 hoverable grey lighten-4">
            <div class="">
                <div class="col s12 center">
                <i [ngClass]="'material-icons ' + item.color">{{item.icon}}</i>
              </div>
                <div class="col s12 center">
                <strong class="black-text">{{item.item}}</strong>
              </div>
            </div>
          </div>
        </div>
      </a>

         <a class="col s6 m4" style="cursor: pointer;"
      *ngIf="item.item === 'Privacy'"
      (click)="termsAndPolicyClicked()">
        <div class="col s12">
         <div class="row card-panel white z-depth-1 hoverable grey lighten-4">
            <div>
              <div class="col s12 center">
                <i [ngClass]="'material-icons ' + item.color">{{item.icon}}</i>
              </div>
              <div class="col s12 center">
                <strong class="black-text">{{item.item}}</strong>
              </div>
            </div>
          </div>
        </div>
      </a>

         <a class="col s6 m4" style="cursor: pointer;"
      *ngIf="item.item === 'Employer'"
      (click)="employersClicked()">
        <div class="col s12">
         <div class="row card-panel white z-depth-1 hoverable grey lighten-4">
            <div class="">
                <div class="col s12 center">
                <i [ngClass]="'material-icons ' + item.color">{{item.icon}}</i>
              </div>
                <div class="col s12 center">
                <strong class="black-text">{{item.item}}</strong>
              </div>
            </div>
          </div>
        </div>
      </a>


      <a class="col s6 m4 modal-trigger" href="#infoModal" style="cursor: pointer;"
      *ngIf="item.item === 'Demo Account' && nationOfUSe === 'Demo'">
        <div class="col s12">
         <div class="row card-panel white z-depth-1 hoverable grey lighten-4">
            <div class="">
                <div class="col s12 center">
                <i [ngClass]="'material-icons ' + item.color">{{item.icon}}</i>
              </div>
                <div class="col s12 center">
                <strong class="black-text">{{item.item}}</strong>
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>






  </div>

-->

  <div class="row col s12" *ngIf="menu && !showDefault">

    <div *ngFor="let item of moreMenu">
      <a class="col s6 m4" style="cursor: pointer;"
      *ngIf="item.item == 'Events'"
      (click)="navigate(item.link)">
        <div class="col s12 m10 offset-m1" >
          <div class="row card-panel teal darken-3 z-depth-1 hoverable wrap">
            <div class="">
                <div class="col s12 center">
                <i [ngClass]="'material-icons ' + 'white-text'">{{item.icon}}</i>
              </div>
                <div class="col s12 center">
                <strong class="white-text">{{item.item}}</strong>
              </div>
            </div>
          </div>
        </div>
      </a>
      <a class="col s6 m4" style="cursor: pointer;"
      *ngIf="item.item == 'About Us'"
      (click)="navigate(item.link)">
        <div class="col s12 m10 offset-m1">
          <div class="row card-panel teal z-depth-1 hoverable wrap" >
            <div class="">
                <div class="col s12 center">
                <i [ngClass]="'material-icons ' + 'white-text'">{{item.icon}}</i>
              </div>
                <div class="col s12 center">
                <strong class="white-text">{{item.item}}</strong>
              </div>
            </div>
          </div>
        </div>
      </a>
      <!-- <a class="col s6 m4" style="cursor: pointer;"
      *ngIf="item.item == 'Apply For IS'"
      (click)="navigate(item.link)">
        <div class="col s12 m10 offset-m1">
          <div class="row card-panel deep-purple darken-2 z-depth-1 hoverable wrap">
            <div class="">
                <div class="col s12 center">
                <i [ngClass]="'material-icons ' + 'white-text'">{{item.icon}}</i>
              </div>
                <div class="col s12 center">
                <strong class="white-text">{{item.item}}</strong>
              </div>
            </div>
          </div>
        </div>
      </a> -->
      <a class="col s6 m4" style="cursor: pointer;"
      *ngIf="item.item == 'Governance'"
      (click)="navigate(item.link)">
        <div class="col s12 m10 offset-m1">
          <div class="row card-panel green darken-3 z-depth-1 hoverable wrap">
            <div class="">
                <div class="col s12 center">
                <i [ngClass]="'material-icons ' + 'white-text'">{{item.icon}}</i>
              </div>
                <div class="col s12 center">
                <strong class="white-text">{{item.item}}</strong>
              </div>
            </div>
          </div>
        </div>
      </a>
      <a class="col s6 m4" style="cursor: pointer;"
      *ngIf="item.item == 'Contact'"
      (click)="navigate(item.link)">
        <div class="col s12 m10 offset-m1">
          <div class="row card-panel  indigo darken-1 z-depth-1 hoverable wrap">
            <div class="">
                <div class="col s12 center">
                <i [ngClass]="'material-icons ' + 'white-text'">{{item.icon}}</i>
              </div>
                <div class="col s12 center">
                <strong class="white-text">{{item.item}}</strong>
              </div>
            </div>
          </div>
        </div>
      </a>
      <a class="col s6 m4" style="cursor: pointer;"
      *ngIf="item.item == 'Register'"
      (click)="navigate(item.link)">
        <div class="col s12 m10 offset-m1">
          <div class="row card-panel red darken-4 z-depth-1 hoverable wrap" >
            <div class="">
                <div class="col s12 center">
                <i [ngClass]="'material-icons ' + 'white-text'">{{item.icon}}</i>
              </div>
                <div class="col s12 center">
                <strong class="white-text">{{item.item}}</strong>
              </div>
            </div>
          </div>
        </div>
      </a>

      <a class="col s6 m4" style="cursor: pointer;"
      *ngIf="item.item === 'Departments'"
      (click)="navigate(item.link)">
        <div class="col s12 m10 offset-m1">
         <div class="row card-panel white z-depth-1 hoverable orange darken-3 wrap" >
            <div class="">
                <div class="col s12 center">
                <i [ngClass]="'material-icons ' + 'white-text'">{{item.icon}}</i>
              </div>
                <div class="col s12 center">
                  <strong *ngIf="nationOfUSe === 'Batc'" class="white-text ">Offices</strong>
                <strong *ngIf="nationOfUSe !== 'Batc'" class="white-text">{{item.item}}</strong>
              </div>
            </div>
          </div>
        </div>
      </a>

         <a class="col s6 m4" style="cursor: pointer;"
      *ngIf="item.item === 'Follow Us'"
      (click)="socialNetworkClicked()"> 
        <div class="col s12 m10 offset-m1">
         <div class="row card-panel white z-depth-1 hoverable blue darken-2 wrap" >
            <div class="">
                <div class="col s12 center">
                <i [ngClass]="'material-icons ' + 'white-text'">{{item.icon}}</i>
              </div>
                <div class="col s12 center">
                <strong class="white-text">{{item.item}}</strong>
              </div>
            </div>
          </div>
        </div>
      </a>

         <a class="col s6 m4" style="cursor: pointer;"
      *ngIf="item.item === 'Privacy'"
      (click)="termsAndPolicyClicked()">
        <div class="col s12 m10 offset-m1">
         <div class="row card-panel white z-depth-1 hoverable cyan darken-1 wrap">
            <div>
              <div class="col s12 center">
                <i [ngClass]="'material-icons ' + 'white-text'">{{item.icon}}</i>
              </div>
              <div class="col s12 center">
                <strong class="white-text">{{item.item}}</strong>
              </div>
            </div>
          </div>
        </div>
      </a>

         <a class="col s6 m4" style="cursor: pointer;"
      *ngIf="item.item === 'Employers'"
      (click)="employersClicked()">
        <div class="col s12 m10 offset-m1">
         <div class="row card-panel white z-depth-1 hoverable pink darken-4 wrap">
            <div class="">
                <div class="col s12 center">
                <i [ngClass]="'material-icons ' + 'white-text'">{{item.icon}}</i>
              </div>
                <div class="col s12 center">
                <strong class="white-text">{{item.item}}</strong>
              </div>
            </div>
          </div>
        </div>
      </a>


      <a class="col s6 m4 modal-trigger" href="#infoModal" style="cursor: pointer;"
      *ngIf="item.item === 'Demo Account' && nationOfUSe === 'Demo'">
        <div class="col s12 m10 offset-m1">
         <div class="row card-panel white z-depth-1 hoverable pink darken-3 wrap" >
            <div class="">
                <div class="col s12 center">
                <i [ngClass]="'material-icons ' + 'white-text'">{{item.icon}}</i>
              </div>
                <div class="col s12 center">
                <strong class="white-text">{{item.item}}</strong>
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>






  </div>



  <!-- <div id="DemoModal" class="modal">
    <div class="modal-content">
      <div class="section"></div>
        <h5><strong> Do you want to request a demo as a <span class="blue-text">Member</span>  or <span class="green-text">Administrator</span>  ? </strong></h5>


    </div>
    <div class="modal-footer">
      <div class="row">
        <a class="modal-close blue btn-large left white-text col s12" style="margin-bottom: 15px;" (click)="memberAccountClick()"><i class="material-icons" style="margin-right: 5px;">account_circle</i> Member</a>
        <a class="modal-close green btn-large right white-text col s12"  (click)="adminAccountClick()"><i class="material-icons" style="margin-right: 5px;">supervised_user_circle</i> Admin</a>

      </div>
    </div>
  </div> -->

  <div id="infoModal" class="modal">
    <div class="modal-content">
      <h4>Request Demo Account</h4>
      <p><strong> You can create a Member Account or an Admin Account.</strong></p>
        <br>
        <p><strong class="blue-text">Member Account : </strong>
          Will allow you to see the app from a member perpesctive,
          update you profile, build a resume, chat with fellow members, check members only
          news and events, answer polls and make suggestions to make this App better.        </p>
       <p><strong class="green-text">Admin Account : </strong>
        Will allow you to create news, manage membership, create departments, review demographics,
        communicate with all members or a specific demographic, create polls
        and all other managerial functions. And if you notice something is not there, please feel free to make suggestions

      </p>

      <p>
        You will be required to have a valid email account and a mobile number to recieve
        a link and an SMS message
      </p>
      <p>
        Your information will not be shared or used for anything other than sending
a link. once you are logged and you want to delete your information you will
be able to do so anytime and you information will not be stored or shared with anyone.

      </p>
    </div>
    <div class="modal-footer">
      <a (click)="createAccountClick()" class="modal-close white-text btn-flat blue">
        <i class="material-icons" style="margin-right: 10px;  display: inline-flex;  vertical-align: top;">
          person_add
        </i>
        Create Demo Account</a>
    </div>
  </div>

  <div id="DemoModal" class="modal">
    <div class="modal-content">
      <h4>Request Demo Account</h4>
      <p><strong> Do you want to request a demo as a <span class="blue-text">Member</span>  or <span class="green-text">Administrator</span>  ? </strong></p>
    </div>
    <div class="modal-footer">
      <a  (click)="adminAccountClick()" style="margin-right: 10px;"  class="modal-close white-text btn-flat green">
        <i class="material-icons" style="margin-right: 10px;   display: inline-flex;  vertical-align: top;">
          manage_accounts
        </i>
        Admin</a>
      <a (click)="memberAccountClick()" class="modal-close white-text btn-flat blue">
        <i class="material-icons" style="margin-right: 10px;  display: inline-flex;  vertical-align: top;">
          person_add
        </i>
        Member</a>
    </div>
  </div>
