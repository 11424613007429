<div class="row" *ngIf="hasCandidateDetail && electionsDetail.length > 0">
  <p class="center"><strong>Elections List</strong></p>
  <div class="col s12 m6" *ngFor="let electionDetail of electionsDetail">
    <div class="card">
      <div class="card-content">
        <div class="row">
          <div class="col s12">
            <p><strong>{{electionDetail.name  | titlecase }} </strong></p>
          </div>
        </div>

        <div class="row" style="border: 1px solid lightgray">
          <div class="col s4">End date </div>
          <div class="col s4 offset-s4">
            <span class="red-text"><strong>{{electionDetail.DateCreated  | date }} </strong></span>
          </div>
        </div>

        <div class="row">
          <div class="col s6">
            <a [routerLink]="['/member/my-votes', electionDetail.id]"  class="btn green lighten-4 "><strong class="black-text"><i class="material-icons" style="margin-right: 7px;">how_to_vote</i>Votes</strong></a>
          </div>
          <div class="col s6" >
            <a [routerLink]="['/member/election-result', electionDetail.id]"  class="btn orange lighten-4"><strong class="black-text"><i class="material-icons" style="margin-right: 7px;">poll</i>Result</strong></a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div *ngIf="!isCandidate">
  <p class="red-text center">NOT A CANDIDATE</p>
</div>

<div class="row">
  <button type="button" class="btn col s12 blue" (click)="backClicked()">
    <i class="material-icons left">arrow_back_ios</i> Back To Previous Page
  </button>
</div>