<div class="row container" *ngIf="appData && treatyNo && bandChange">
    <h5 class="col s12 center-align">{{appData.appName | uppercase}}</h5>
    <!-- <p class="col s12 center-align" style="text-align: justify">{{appData.appDescription}}</p> -->
    <br>

    <!-----------------------------------------------Form------------------------------------------------->
    <p *ngIf="step > 0" class="center-align">Step {{step}} of {{appData.sections.length}}</p>

    <form class="row col s12" [formGroup]="applicationForm" *ngIf="step != 0">
        <div class="col s12" *ngFor="let section of appData.sections;let i=index;">
            <div class="col s12" [formGroupName]="section.section_name" *ngIf="step == section.order">
                <div class="row card white">
                    <div class="col s12" style="text-align: justify;">
                        <p>{{section.statement}}</p>
                    </div>
                </div>
                <div class="col s12 teal lighten-2 white-text">
                    <button *ngIf="step > 1" class="btn-flat left-align left btn-large" type="button" (click)="onBack()">
                        <i class="material-icons large white-text" style="font-size: 25px; cursor: pointer;">arrow_back_ios</i>
                    </button>
                    <div *ngIf="step < appData.sections.length" class="col right" (click)="onNext()">
                        <button class="btn-flat right-align right btn-large" type="button" [disabled]="!isValid()">
                            <i class="material-icons large white-text"
                                style="font-size: 25px;cursor: pointer;">arrow_forward_ios</i></button>
                    </div>
                    <h5 class="center-align">{{section.sec_title}}</h5>
                </div>

                <div class="col s12 card white">
                    <div *ngFor="let field of section.fields;let f=index">
                        <div class="input-field col s12"
                            *ngIf="field.field_type=='Input' && field.form_control != 'isn'">
                            <i class="material-icons prefix blue-text">{{field.icon}}</i>
                            <!-- <input class="validate" autocomplete="off" [textMask]="{mask: field.textMask}"
                                [formControlName]="field.form_control" [id]="field.form_control"
                                [type]="field.data_type" [style.text-transform]="field.form_control == 'postal_code' ? 'uppercase' : 'capitalize'"> -->
                                <input class="validate" autocomplete="off" 
                                [formControlName]="field.form_control" [id]="field.form_control"
                                [type]="field.data_type" [style.text-transform]="field.form_control == 'postal_code' ? 'uppercase' : 'capitalize'">
                            <label>{{field.name}}<span *ngIf="field.required" class="red-text">*</span></label>
                            <div *ngIf="field.required">
                                <small *ngIf='isRequired(f, field.form_control)' class="red-text errorMsg">
                                    {{field.name}} is required. </small>
                            </div>

                        </div>
                        <div class="input-field col s12"
                            *ngIf="field.field_type == 'Input' && field.form_control == 'isn'">
                            <i class="material-icons prefix blue-text">{{field.icon}}</i>
                            <!-- <input class="validate" autocomplete="off" [textMask]="{mask: field.textMask}"
                                [formControlName]="field.form_control" [id]="field.form_control"
                                [type]="field.data_type" style="text-transform: capitalize;"
                                (keyup)="onIsnChange($event.target.value)"> -->
                                <input class="validate" autocomplete="off" 
                                [formControlName]="field.form_control" [id]="field.form_control"
                                [type]="field.data_type" style="text-transform: capitalize;"
                                (keyup)="onIsnChange($event.target.value)">
                            <label>{{field.name}}<span *ngIf="field.required" class="red-text">*</span></label>
                        </div>
                        <div class="input-field col s12"
                            *ngIf="field.field_type=='Select' && field.form_control != 'province' && field.form_control != 'band_name'">
                            <i class="material-icons prefix blue-text">{{field.icon}}</i>
                            <select [formControlName]="field.form_control" class="validate" [id]="field.form_control"
                                style="text-transform: capitalize;">
                                <option value="" disabled selected>Choose your option</option>
                                <option *ngFor="let v of field.select_options" [value]="v">{{v}}</option>
                            </select>
                            <label>{{field.name}}</label>
                        </div>
                        <div class="input-field col s12"
                            *ngIf="field.field_type=='Select' && field.form_control == 'province'">
                            <i class="material-icons prefix blue-text">{{field.icon}}</i>
                            <select [formControlName]="field.form_control" class="validate" [id]="field.form_control"
                                style="text-transform: capitalize;" (change)="provinceChange($event.target.value)">
                                <option value="" disabled selected>Choose your option</option>
                                <option *ngFor="let v of field.select_options" [value]="v">{{v}}</option>
                            </select>
                            <label>{{field.name}}</label>
                        </div>
                        <div class="input-field col s12"
                            *ngIf="field.field_type=='Select' && field.form_control == 'band_name'">
                            <i class="material-icons prefix blue-text">{{field.icon}}</i>
                            <select [formControlName]="field.form_control" class="validate" [id]="field.form_control"
                                style="text-transform: capitalize;" (change)="bandChange($event.target.value)">
                                <option value="" disabled selected>Choose your option</option>
                                <option *ngFor="let v of bandList" [value]="v">{{v}}</option>
                            </select>
                            <label>{{field.name}}</label>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div formGroupName="applicant">
               
                <div [sortablejs]="addedFields" [sortablejsOptions]="options" class="row card-content" *ngIf="addedFields">
                    <div *ngFor="let field of addedFields;let i=index;" class="row col s12 m6 valign-wrapper ">
                        <i class="material-icons prefix green-text col s1 handle small" style="cursor: move;">drag_indicator</i>
                        
                        <div class="input-field col s10" *ngIf="field[0].select">
                            <i class="material-icons prefix purple-text">{{field[0].icon}}</i>
                            <select #field[0].controlName [formControlName]="field[0].controlName" class="validate" [id]="field[0].controlName" (change)="field[0].method($event.target.value)">
                                <option value="" disabled selected>Choose your option</option>
                                <option *ngFor="let v of field[0].values" [value]="v">{{v}}</option>
                            </select>
                            <label>{{field[0].label}}</label>
                        </div>
    
                        <div class="input-field col s10" *ngIf="!field[0].select && field[0].controlName != 'isn'">
                        
                            <i class="material-icons prefix blue-text">{{field[0].icon}}</i>
                            <input class="validate" autocomplete="off" [textMask]="{mask: field[0].textMask}" [class]="field[0].controlName"
                                (change)="field[0].method($event.target.value)" [formControlName]="field[0].controlName" [id]="field[0].controlName" [type]="field[0].type">
                            <label>{{field[0].label}}<span *ngIf="field[0].req" class="red-text">*</span></label>
                        </div>

                        <div class="input-field col s10" *ngIf="!field[0].select && field[0].controlName == 'isn'">
                        
                            <i class="material-icons prefix blue-text">{{field[0].icon}}</i>
                                
                            <input  class="validate" autocomplete="off" [textMask]="{mask: field[0].textMask}" [class]="field[0].controlName"
                                (keyup)="field[0].method($event.target.value)" [formControlName]="field[0].controlName" [id]="field[0].controlName" [type]="field[0].type">
                            <label>{{field[0].label}}<span *ngIf="field[0].req" class="red-text">*</span></label>
                        </div>

                        <i class="material-icons red-text col s1" style="cursor: pointer;"
                            (click)="deleteField(i)">delete</i>
                    </div>
    
                </div>
                <button *ngIf="!addField && fieldDatas.length >0" class="btn col s12" (click)="onAddField()" style="margin-bottom: 25px;">Add a Field</button>
                <button *ngIf="!addField" class="btn right blue" (click)="onNext()">Next</button>    
            </div> -->

        <button [disabled]="!isValid()" *ngIf="appData.sections.length > step"
            class="col s12 m5 offset-m2 l3 btn right waves-effect blue" type="button" (click)="onNext()"
            style="margin-bottom: 5px" title="Save">Next <i class="material-icons right">arrow_forward</i></button>
            
        <button *ngIf="step > 1" class="col s12 m5 l3 btn left waves-effect blue" type="button"
            style="margin-bottom: 5px" (click)="onBack()"><i class="material-icons left">arrow_back</i>Back
        </button>
        <button [disabled]="!applicationForm.valid" *ngIf="step ==  appData.sections.length"
            class="col s12 m5 offset-m2 l3 btn right waves-effect green" type="submit"
            (click)="previewApplication(applicationForm.value)" style="margin-bottom: 15px" title="Save">Preview<i
                class="material-icons right">visibility</i></button>
    </form>

    <div class="row col s12" *ngIf="step == 0">
        <!-- <div class="col s12">
            <div *ngIf="model.logo" class="logo hide-on-med-and-up center">
                <img id="my-logo" class="responsive-img " [src]="model.logo | safeUrl" />
            </div>

            <div *ngIf="model.logo" class="logo-big-screen hide-on-small-only show-on-medium-and-up center">
                <img id="my-logo" class="responsive-img " [src]="model.logo | safeUrl" />
            </div>
        </div> -->
        <div style="margin-top: 25px; padding-top: 5px; padding-bottom: 5px;" class="teal lighten-2 white-text">
            <i class="material-icons medium valign-wrapper prefix left" (click)="BackToApp()"
                style="cursor: pointer;">navigate_before</i>
            <h5 class="center-align">Preview Application</h5>
        </div>
        
        <div class="right-align" >
            <button class="btn tooltipped" data-position="bottom" data-tooltip="Open as a PDF" type="button"
                (click)="openPdf()" style="margin:5px;"><i class="material-icons">open_in_new</i></button>
            <button class="btn blue tooltipped" data-position="bottom" data-tooltip="Download PDF" type="button"
                (click)="downloadPdf()" style="margin:5px;"><i class="material-icons blue">download</i></button>
            <button class="btn green tooltipped" data-position="bottom" data-tooltip="Print PDF" type="button"
                (click)="printPdf()" style="margin:5px;"> <i class="material-icons">print</i></button>
        </div>
    
        <div class="row card white">
            <ul class="collection">
                <li class="row col s12 collection-item" *ngFor="let section of appData.sections; let s=index">
                    <div class="col s12">
                        <h5 class="col s8"> {{section.sec_title}}</h5>
                        <a class="col s4 blue-text right waves-effect waves-light btn-flat" (click)="editSection(s)"
                            style="cursor: pointer;"><i class="material-icons left">edit</i>Edit</a>
                    </div>
                    <div class="col s12">
                        <p *ngFor="let field of section.fields"><strong>{{field.name}} :
                            </strong>{{application.values[section.section_name][field.form_control]}}</p>
                    </div>

                </li>
            </ul>
        </div>

        <!-- <button class="btn green col s12 " type="button" (click)="BackToApp()" style="margin:5px;"> <i
                class="material-icons">arrow_back</i>Back To the Application</button> -->
        <button type="button" (click)="BackToApp()" class="col s12 m4 waves-effect blue btn"
            style="margin-bottom:5px;">Back to
            Application<i class="material-icons left">navigate_before</i></button>
        <button type="button" (click)="submitApplication()" class="col s12 m4 offset-m4 waves-effect green btn">Submit<i
                class="material-icons right">send</i></button>
    </div>
</div>