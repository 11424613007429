import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { PollsAndSurveyService } from 'src/app/services/firebase/polls-and-survey.service';


@Component({
  selector: 'app-survey-list',
  templateUrl: './survey-list.component.html',
  styleUrls: ['./survey-list.component.css']
})
export class SurveyListComponent implements OnInit {

  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  model: any[];
  loading = false;

  constructor(private surveyService: PollsAndSurveyService, private location: Location) { }

  ngOnInit() {
    this.loading = true;
    this.surveyService.getSurveyList().valueChanges().subscribe(srv => {
      if (srv.length > 0) {
        this.model = srv;
      }
      this.model = this.model.sort(function(a,b){
        return new Date(b.endDate).getTime() - new Date(a.endDate).getTime();
      });

      this.model = this.model.filter(poll => poll.showResult == 'yes' && (poll.showResultTo == 'public' || poll.showResultTo == 'both'));
      
      this.loading = false;
    });
  }

  backClicked() {
    this.location.back();
  }


}
