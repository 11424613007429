<div class="row col s12">

  <app-firebase-loading *ngIf="showLoader"></app-firebase-loading>

  <div class="col l2"></div>
  <div class="col s12 l12" *ngIf="model">
    <app-event-calendar-view class="row col s12" [model]="model" [isMember]="isMember">
    </app-event-calendar-view>
  </div>

  <div class="col s12 l8" *ngIf="!model && !showLoader">
    <div class="row card-panel white">
      <strong>
        <span class="material-icons blue-text" style="display: inline-flex; vertical-align: top;">info</span>
        {{nationName}} Did Not Post Any Event</strong>
    </div>
  </div>

  <div class="col l2"></div>

</div>