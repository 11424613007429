import { Component, OnInit } from '@angular/core';
import { EmploymentService } from 'src/app/services/api/employment.service';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';
import { FireMembersService } from 'src/app/services/firebase/fire-members.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators} from '@angular/forms';
import { MemberService } from 'src/app/services/api/member.service';
import { EmailModel } from 'src/app/models/emailModel';
import { EmailService } from 'src/app/services/api/email.service';
import { toast } from 'materialize-css';
import { UpdateEmailModel } from 'src/app/models/UpdateEmailModel';
import { EducationService } from 'src/app/services/api/education.service';
import { CertificateService } from 'src/app/services/api/certificate.service';
import { MemberAccountSettingsService } from 'src/app/services/firebase/memberAccountSettings.service';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-search-skills',
  templateUrl: './search-skills.component.html',
  styleUrls: ['./search-skills.component.css']
})
export class SearchSkillsComponent implements OnInit {

  themeColor = environment.appTheme.themeColor; 
  fontColor = environment.appTheme.fontColor;
  firebaseClients: any[];
  client: any;
  email_client: any;
  permissions: any[];
  ableToContact = false;
  ableToSeeResume = false;
  keywords: string[];
  searchResult: any[];
  emailForm: UntypedFormGroup;
  editEmail: UntypedFormGroup;
  educations: any[];
  works: any[];
  courses: any[];
  settingsModel: any;
  memberId='';
  
  constructor(private location: Location, private employmentService: EmploymentService, private emailService: EmailService,
    private fireMembersService: FireMembersService, private fb: UntypedFormBuilder, private memberService: MemberService,
    private memberAccountSettingsService: MemberAccountSettingsService,
    private educationService: EducationService, private certificateService: CertificateService) {}

    ngOnInit() {
      this.emailForm = this.fb.group({
        subject: ['', [Validators.required]],
        body: ['', [Validators.required]],
      });
  
      this.editEmail = this.fb.group({
        email: ['', [Validators.required]],
      });
   
      setTimeout(() => {
        $('.modal').modal();
      }, 25);
  
      setTimeout(() => {
        $('.chips-placeholder').chips({
          placeholder: ' Keyword',
          secondaryPlaceholder: ' + Keyword',
        });
      }, 25);  
    }
  
    Search() {
      let key = M.Chips.getInstance($('.chips')).chipsData;
      this.keywords = [];
      key.forEach(element => {
        this.keywords.push(element.tag);
      });
      
      this.employmentService.searchByKeyword(this.keywords).subscribe( x => {
         if (x) {
            this.searchResult = x;
            this.searchResult.forEach(element => { 
              element.Email = false;
              element.Resume = false;

             let id = element.client_id;
             this.fireMembersService.getMemberByAppliciantId(id).valueChanges().subscribe( y => {
              if (y) {
                this.client = y[0];
                this.memberId = this.client.uid;
               
                this.memberAccountSettingsService.getAccoutSetting(this.memberId).valueChanges().subscribe(z => {
                  if (z.length > 0) {
                    this.settingsModel = z[0];
                    
                    this.ableToContact = false;
                    this.ableToSeeResume = false;

                    this.settingsModel.permissions.forEach(element => {
                     if(Object.keys(element)[0] == 'allowContactByEmployer'){
                        this.ableToContact = element.allowContactByEmployer;
                      }else if(Object.keys(element)[0] == 'allowViewResume'){
                        this.ableToSeeResume = element.allowViewResume;
                      }
                    });
    
                    if (this.ableToContact) element.Email = true;
                    if (!this.ableToContact) element.Email = false;
    
                    if (this.ableToSeeResume) element.Resume = true;
                    if (!this.ableToSeeResume) element.Resume = false;
                  }
                });
              }
            });  
            });
  
           setTimeout(() => {
            $('.dropdown-trigger').dropdown();
           }, 25);
         }
       });
    }
  
    ViewResume(clientid){
      if (clientid) {
        const id = + clientid;
        this.educationService.getEducationByMemberId(id).subscribe(x => {
          this.educations = x;
          this.certificateService.getCertificateByMemberId(id).subscribe(x => {
            this.courses = x;        
            this.employmentService.getEmploymentListByMemberID(id).subscribe(x => {
              this.works = x;
            });
          });
        });
      }
      $('#resumeModal').modal('open');
    }
  

    SendEmail(clientid){
      this.emailForm.patchValue({ subject: '' });
      this.emailForm.patchValue({ body: '' });
  
      if (clientid) {
        const id = + clientid;
        this.memberService.getMember(id).subscribe(x => {
          this.email_client = x[0];
          if (!this.email_client.Email) {
            $('#editEmailModal').modal('open');
          } else {
            $('#contactModal').modal('open');
          }
        });
      }
    }
  
    get subject() { return this.emailForm.get('subject'); }
    get body() { return this.emailForm.get('body'); }
    get email() { return this.editEmail.get('email'); }
  
    editEmailSubmit(value: any) {
      const email_Model = new UpdateEmailModel();
      email_Model.applicantID = this.client.ID;
      email_Model.email = value.email;
      this.client.Email = value.email;
      $('.modal').modal('close');
      this.memberService.updateMemberEmail(email_Model).subscribe( x => {
        if (x) {
          toast({html: 'Email Successfully Updated.', classes: 'green'});
          $('.modal').modal('close');
        }
      });
    }
  
    Send(email: EmailModel) {
      const msg = new EmailModel();
      msg.emailList = [];
      msg.emailList.push(this.client.Email);
      msg.subject = email.subject;
      msg.body = email.body;

      $('.modal').modal('close');
      this.emailService.sendEmail(msg).subscribe(x => {
       toast({html: 'Email Successfully Sent.', classes: 'green'});
      });
   }
    
    backClicked() {
      this.location.back();
    }

}