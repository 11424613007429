
import { Component, OnInit } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl, FormArray } from "@angular/forms";
import { environment } from "src/environments/environment";
import { toast } from "materialize-css";
import { Location, DatePipe, ViewportScroller } from "@angular/common";
import { Router } from "@angular/router";
import { RegistrationFormModel } from 'src/app/models/isets/RegistrationFormModel';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
//import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';
import { RegistrationFormService } from "src/app/services/api/isets/registration-form.service";
import SignaturePad from 'signature_pad';
import { DocumentsService } from "src/app/services/api/documents.service";
import { createMask } from '@ngneat/input-mask';

declare var $: any;
declare var M: any;
declare var navigator: any;


@Component({
  selector: 'app-iset-registration-form',
  templateUrl: './iset-registration-form.component.html',
  styleUrls: ['./iset-registration-form.component.css']
})
export class IsetRegistrationFormComponent implements OnInit {

  nationName = environment.firstNation.displayName;
  nationOfUse = environment.firstNation.name;
  defaultThemeColor = environment.appTheme.themeColor;
  themeColor: any;
  buttonType: any;

  languageSkills: any = ['Very Good', 'Good', 'Average', 'Poor'];
  titles: any = ['Mr', 'Mrs', 'Miss', 'Ms'];
  provinces: any = ['', 'Alberta', 'British Columbia', 'Manitoba', 'New Brunswick', 'Newfoundland and Labrador', 'Nova Scotia', 'Ontario', 'Prince Edward Island', 'Quebec', 'Saskatchewan'];
  employments: any = ['Unemployed', 'Employed P/T', 'Employed F/T', 'Student Finance Board', 'AISH', 'Income Support', 'Employment Insurance Benefits', 'Worker’s Compensation Benefits', 'Other'];

  maritalStatus = [
    { name: 'Single no dependants', code: '1' },
    { name: 'Single Parent', code: '2' },
    { name: 'Seprated / Divorced', code: '3' },
    { name: 'Married / Common Law with Employed Spouse', code: '4' },
    { name: 'Married / Common Law with Unemployed Souse', code: '5' }
  ];

  grades = ['Grade 10', 'Grade 11', 'Grade 12'];
  //Dependants = ['0', '1', '2', '3', '4', '5', '6', '7', '8' ];
  childAges = ['under 1', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17'];


  public dateFormat = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
  //public SINMask = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  //public phoneNumberMask = ['(', /[0-9]/, /[0-9]/, /[0-9]/, ')',' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  //public postalCodeMask = [/[A-Za-z]/, /[0-9]/, /[A-Za-z]/, ' ', /[0-9]/, /[A-Za-z]/, /[0-9]/];
  public numberMask = [/[0-9]/, /[0-9]/];
  //public yearMask = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  //public ChildAges = ['1 y/o', '2 y/o', '3 y/o', '4 y/o', '5 y/o', '6 y/o', '7 y/o', '8 y/o', '9 y/o',
  //                '10 y/o', '11 y/o', '12 y/o', '13 y/o', '14 y/o', '15 y/o', '16 y/o', '17 y/o'];
  phoneInputMask = createMask<string>({
    mask: '(999) 999-9999',
    guide: true,
    showMaskTyped: true,
  });

  postalCodeMask = createMask<string>({
    mask: 'A9A 9A9' // Replace {1} with a space if desired
  });

  SINMask = createMask({
    mask: '999 999 999',
    placeholderChar: '',
    maskChar: null,
  });

  yearMask = {
    mask: '9999',
    placeholder: 'YYYY',
    definitions: {
      '9': {
        validator: '[0-9]',
        cardinality: 1,
      },
    },
  };

  todayDate = new Date().getFullYear();

  personalInfoForm: UntypedFormGroup;
  incomeInfoForm: UntypedFormGroup;
  educationInfoForm: UntypedFormGroup;
  signatureForm: UntypedFormGroup;
  uploadDocumentsForm: UntypedFormGroup;

  hasDriverLicense = false;
  step = 1;
  registrationFormModel: RegistrationFormModel;
  isOtherEmpStatus = false;
  hasTrade = false;
  hasPSE1 = false;
  hasPSE2 = false;

  //signature
  canvas: any;
  signaturePad: SignaturePad;

  list_of_kids_age = '';
  total_childs = 0;
  child1_age = '';
  child2_age = '';
  child3_age = '';
  child4_age = '';
  child5_age = '';
  child6_age = '';
  child7_age = '';
  child8_age = '';
  child9_age = '';

  //documents upload
  binDoc: File;
  fileName: string;
  isMobile = false;
  showUpload = false;
  preview: any;


  myDpOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'dd/mm/yyyy',
    markCurrentDay: true,
  };

  constructor(private fb: UntypedFormBuilder,
    private location: Location,
    private router: Router,
    private registration_service: RegistrationFormService,
    private documentService: DocumentsService
  ) { }

  ngOnInit(): void {

    this.personalInfoForm = this.fb.group({

      givenname: ["", [Validators.required, Validators.maxLength(50)]],
      lastname: ["", [Validators.required, Validators.maxLength(50)]],
      initial: [""],
      title: ["", [Validators.required]],
      dob: ['', [Validators.required]],       // ['', [Validators.pattern(this.dateFormat), this.isValidDate]],
      gender: ["", [Validators.required]],
      SIN: [""],      //['', [Validators.minLength(9), Validators.maxLength(9), Validators.pattern('^[0-9]*$')]],
      cell: [""],
      email: ["", [Validators.required, Validators.email]],
      home_address: ["", [Validators.required, Validators.maxLength(80)]],
      city: ["", [Validators.required, Validators.maxLength(50)]],
      province: ["", [Validators.required]],
      postal_code: ['', [Validators.required]],
      mailing_address: ["", [Validators.maxLength(80)]],

      Econtact1: [""],   //["", [Validators.required, Validators.maxLength(50)]],
      contct1PH: [""],   //['', [Validators.required]],
      Econtact2: [""],   // ["", [Validators.required, Validators.maxLength(50)]],
      contct2PH: [""],   //['', [Validators.required]],

      driver_license: ['', [Validators.required]],
      driver_class: [],
      license_expiry_date: [""],
      province_issued: [""],

      citizenship: [""],
      language: [""],

      reading_skill: ['', [Validators.required]],
      writing_skill: ['', [Validators.required]],
      listening_skill: ['', [Validators.required]],
      speaking_skill: ['', [Validators.required]],

      aboriginal_group: ['', [Validators.required]],

    });

    this.personalInfoForm.get('driver_license').valueChanges.subscribe(value => {
      if (value == 'yes') {
        this.personalInfoForm.get('driver_class').setValidators(Validators.required);
        // this.personalInfoForm.get('license_expiry_date').setValidators([ Validators.pattern(this.dateFormat), this.isValidDate]);  
        this.personalInfoForm.get('license_expiry_date').setValidators(Validators.required);
        this.personalInfoForm.get('province_issued').setValidators([Validators.required]);

      } else {
        this.personalInfoForm.get('driver_class').clearValidators();
        this.personalInfoForm.get('license_expiry_date').clearValidators();
        this.personalInfoForm.get('province_issued').clearValidators();

      }
      this.personalInfoForm.get('driver_class').updateValueAndValidity();
      this.personalInfoForm.get('license_expiry_date').updateValueAndValidity();
      this.personalInfoForm.get('province_issued').updateValueAndValidity();

    });

    this.incomeInfoForm = this.fb.group({
      employment_status: ['', [Validators.required]],
      emp_other_specify: [''],
      ei_received: ['', [Validators.required]],
      apply_ei: ['', [Validators.required]],
      disability: ['', [Validators.required]],
      marital_status: ['', [Validators.required]],
      //t_dependants: ['', [Validators.required]],
      kids_age: [''],
      // child_ages:[''],

      request_resume: ['', [Validators.required]],
      request_skill: ['', [Validators.required]],
      request_career: ['', [Validators.required]],
      request_job: ['', [Validators.required]],
      request_funding: ['', [Validators.required]],
      request_program: [''],   //['', [Validators.required]],
      request_institute: [''],  //['', [Validators.required]],
      request_startdate: [''],   // ['', [Validators.required]],  
      request_enddate: [''],  //['', [Validators.required]],  

      child1: [''],
      child2: [''],
      child3: [''],
      child4: [''],
      child5: [''],
      child6: [''],
      child7: [''],
      child8: [''],
      child9: [''],
    });

    this.educationInfoForm = this.fb.group({

      highschool: ['', [Validators.required]],
      hs_grade: ['', [Validators.required]],
      hs_city: ['', [Validators.required]],
      hs_province: ['', [Validators.required]],

      has_PSE1: ['', [Validators.required]],
      pse_program1: [''],
      pse_institution1: [''],
      //completed_year1: ['', [Validators.pattern('^[0-9]*$'), Validators.min(2000), Validators.max(new Date().getFullYear())]],
      completed_year1: [''],
      pse_city1: [''],
      pse_province1: [''],

      has_PSE2: ['', [Validators.required]],
      pse_program2: [''],
      pse_institution2: [''],
      //completed_year2: ['', [Validators.pattern('^[0-9]*$'), Validators.min(2000), Validators.max(new Date().getFullYear())]],
      completed_year2: [''],
      pse_city2: [''],
      pse_province2: [''],

      has_trade: ['', [Validators.required]],
      trade: [''],
      trade_course: [''],
      trade_institution: [''],
      trade_level: [''],
      trade_year: [''],
      trade_city: [''],
      trade_province: [''],

      referred_by: ['', [Validators.required]],
      comments: ['', [Validators.maxLength(200)]],
    });

    this.uploadDocumentsForm = this.fb.group({
      has_docs: ['', [Validators.required]],
      document: [""],
      description: [""],
    });

    this.uploadDocumentsForm.get('has_docs').valueChanges.subscribe(value => {
      if (value == 'yes') {
        this.uploadDocumentsForm.get('document').setValidators(Validators.required);
        // this.uploadDocumentsForm.get('description').setValidators(Validators.required);

      } else {
        this.uploadDocumentsForm.get('document').clearValidators();
        // this.uploadDocumentsForm.get('description').clearValidators();

      }
      this.uploadDocumentsForm.get('document').updateValueAndValidity();
      // this.uploadDocumentsForm.get('description').updateValueAndValidity();

    });

    this.signatureForm = this.fb.group({
      // name: ['', [Validators.required]],
      signature: ['', [Validators.required]],
      //date: [''],
    });


    this.registrationFormModel = new RegistrationFormModel()

  }


  get givenname() { return this.personalInfoForm.get("givenname"); }
  get lastname() { return this.personalInfoForm.get("lastname"); }
  get initial() { return this.personalInfoForm.get("initial"); }
  get title() { return this.personalInfoForm.get("title"); }
  get gender() { return this.personalInfoForm.get("gender"); }
  get dob() { return this.personalInfoForm.get('dob'); }
  get SIN() { return this.personalInfoForm.get('SIN'); }
  get cell() { return this.personalInfoForm.get('cell'); }
  get email() { return this.personalInfoForm.get('email'); }
  get home_address() { return this.personalInfoForm.get('home_address'); }
  get city() { return this.personalInfoForm.get('city'); }
  get province() { return this.personalInfoForm.get('province'); }
  get postal_code() { return this.personalInfoForm.get('postal_code'); }
  get mailing_address() { return this.personalInfoForm.get('mailing_address'); }


  get Econtact1() { return this.personalInfoForm.get("Econtact1"); }
  get contct1PH() { return this.personalInfoForm.get("contct1PH"); }
  get Econtact2() { return this.personalInfoForm.get("Econtact2"); }
  get contct2PH() { return this.personalInfoForm.get("contct2PH"); }

  get driver_license() { return this.personalInfoForm.get("driver_license"); }
  get driver_class() { return this.personalInfoForm.get("driver_class"); }
  get license_expiry_date() { return this.personalInfoForm.get("license_expiry_date"); }
  get province_issued() { return this.personalInfoForm.get("province_issued"); }
  get citizenship() { return this.personalInfoForm.get("citizenship"); }
  get language() { return this.personalInfoForm.get("language"); }

  get reading_skill() { return this.personalInfoForm.get("reading_skill"); }
  get writing_skill() { return this.personalInfoForm.get("writing_skill"); }
  get listening_skill() { return this.personalInfoForm.get("listening_skill"); }
  get speaking_skill() { return this.personalInfoForm.get("speaking_skill"); }
  get aboriginal_group() { return this.personalInfoForm.get("aboriginal_group"); }


  get employment_status() { return this.incomeInfoForm.get("employment_status"); }
  get emp_other_specify() { return this.incomeInfoForm.get("emp_other_specify"); }
  get ei_received() { return this.incomeInfoForm.get("ei_received"); }
  get apply_ei() { return this.incomeInfoForm.get("apply_ei"); }
  get disability() { return this.incomeInfoForm.get("disability"); }
  get marital_status() { return this.incomeInfoForm.get("marital_status"); }
  //get t_dependants() { return this.incomeInfoForm.get("t_dependants"); }
  //get kids_age() { return this.incomeInfoForm.get("kids_age"); }
  get child_ages() { return this.incomeInfoForm.get("child_ages"); }
  get child1() { return this.incomeInfoForm.get("child1"); }
  get child2() { return this.incomeInfoForm.get("child2"); }
  get child3() { return this.incomeInfoForm.get("child3"); }
  get child4() { return this.incomeInfoForm.get("child4"); }
  get child5() { return this.incomeInfoForm.get("child5"); }
  get child6() { return this.incomeInfoForm.get("child6"); }
  get child7() { return this.incomeInfoForm.get("child7"); }
  get child8() { return this.incomeInfoForm.get("child8"); }
  get child9() { return this.incomeInfoForm.get("child9"); }

  get request_resume() { return this.incomeInfoForm.get("request_resume"); }
  get request_skill() { return this.incomeInfoForm.get("request_skill"); }
  get request_career() { return this.incomeInfoForm.get("request_career"); }
  get request_job() { return this.incomeInfoForm.get("request_job"); }
  get request_funding() { return this.incomeInfoForm.get("request_funding"); }
  get request_program() { return this.incomeInfoForm.get("request_program"); }
  get request_institute() { return this.incomeInfoForm.get("request_institute"); }
  get request_startdate() { return this.incomeInfoForm.get("request_startdate"); }
  get request_enddate() { return this.incomeInfoForm.get("request_enddate"); }

  get highschool() { return this.educationInfoForm.get("highschool"); }
  get hs_grade() { return this.educationInfoForm.get("hs_grade"); }
  get hs_city() { return this.educationInfoForm.get("hs_city"); }
  get hs_province() { return this.educationInfoForm.get("hs_province"); }

  //has_PSE1
  get has_PSE1() { return this.educationInfoForm.get("has_PSE1"); }
  get pse_program1() { return this.educationInfoForm.get("pse_program1"); }
  get pse_institution1() { return this.educationInfoForm.get("pse_institution1"); }
  get completed_year1() { return this.educationInfoForm.get("completed_year1"); }
  get pse_city1() { return this.educationInfoForm.get("pse_city1"); }
  get pse_province1() { return this.educationInfoForm.get("pse_province1"); }


  get has_PSE2() { return this.educationInfoForm.get("has_PSE2"); }
  get pse_program2() { return this.educationInfoForm.get("pse_program2"); }
  get pse_institution2() { return this.educationInfoForm.get("pse_institution2"); }
  get completed_year2() { return this.educationInfoForm.get("completed_year2"); }
  get pse_city2() { return this.educationInfoForm.get("pse_city2"); }
  get pse_province2() { return this.educationInfoForm.get("pse_province2"); }

  get has_trade() { return this.educationInfoForm.get("has_trade"); }
  get trade() { return this.educationInfoForm.get("trade"); }
  get trade_course() { return this.educationInfoForm.get("trade_course"); }
  get trade_institution() { return this.educationInfoForm.get("trade_institution"); }
  get trade_level() { return this.educationInfoForm.get("trade_level"); }
  get trade_year() { return this.educationInfoForm.get("trade_year"); }
  get trade_city() { return this.educationInfoForm.get("trade_city"); }
  get trade_province() { return this.educationInfoForm.get("trade_province"); }
  get referred_by() { return this.educationInfoForm.get("referred_by"); }
  get comments() { return this.educationInfoForm.get("comments"); }

  get signature() { return this.signatureForm.get("signature"); }

  get has_docs() { return this.uploadDocumentsForm.get("has_docs"); }
  get description() { return this.uploadDocumentsForm.get("description"); }




  isValidDate(control: UntypedFormControl) {
    const dateformat = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
    let date = control.value;
    if (date) {
      if (date.match(dateformat)) {
        let dateArray = date.split('/');
        let day = dateArray[0];
        let month = dateArray[1] - 1;
        let year = dateArray[2];
        date = new Date(year, month, day);
        const today = new Date();
        if (Number(year) <= Number(today.getFullYear() - 100)) {
          return { 'invalidDate': true }
        } else if (date.valueOf() >= today.valueOf()) {
          return { 'invalidDate': true }
        } else {
          return null;
        }
      } else {
        return { 'invalidDate': true }
      }
    }
    return null;
  }



  onDriverLicenseYes() {
    this.hasDriverLicense = true;
    this.personalInfoForm.get('driver_class').setValidators(Validators.required);
    this.personalInfoForm.get('license_expiry_date').setValidators(Validators.required);
    this.personalInfoForm.get('province_issued').setValidators([Validators.required]);
    this.personalInfoForm.get('driver_class').updateValueAndValidity();
    this.personalInfoForm.get('license_expiry_date').updateValueAndValidity();
    this.personalInfoForm.get('province_issued').updateValueAndValidity();
    setTimeout(() => {
      M.updateTextFields();
    }, 25);
  }

  onDriverLicenseNo() {
    this.personalInfoForm.patchValue({ 'driver_class': '' });
    this.personalInfoForm.patchValue({ 'license_expiry_date': '' });
    this.personalInfoForm.patchValue({ 'province_issued': '' });

    this.personalInfoForm.get('driver_class').clearValidators();
    this.personalInfoForm.get('license_expiry_date').clearValidators();
    this.personalInfoForm.get('province_issued').clearValidators();

    this.personalInfoForm.get('driver_class').updateValueAndValidity();
    this.personalInfoForm.get('license_expiry_date').updateValueAndValidity();
    this.personalInfoForm.get('province_issued').updateValueAndValidity();
    this.hasDriverLicense = false;
    setTimeout(() => {
      M.updateTextFields();
    }, 25);
  }



  validate_sin(sin_num) {
    let sin: string = sin_num.toString();
    if (sin.length != 9) { return false; }

    var sin_array: number[] = new Array(9);
    for (let i = 0; i < 9; i++) {
      sin_array[i] = Number(sin.substring(i, i + 1));
      //console.log(sin_array[i])
    }

    let F1: number = sin_array[0];
    let F2: number = sin_array[1] + sin_array[1];
    let F3: number = sin_array[2];
    let F4: number = sin_array[3] + sin_array[3];
    let F5: number = sin_array[4];
    let F6: number = sin_array[5] + sin_array[5];
    let F7: number = sin_array[6];
    let F8: number = sin_array[7] + sin_array[7];
    let F9: number = sin_array[8];


    var no1: number;
    var no2: number;
    no1 = 0
    no2 = F1 + F3 + F5 + F7
    if (F2 > 9) { no1 = no1 + this.fix_number(F2); } else { no1 = no1 + F2; }
    if (F4 > 9) { no1 = no1 + this.fix_number(F4); } else { no1 = no1 + F4; }
    if (F6 > 9) { no1 = no1 + this.fix_number(F6); } else { no1 = no1 + F6; }
    if (F8 > 9) { no1 = no1 + this.fix_number(F8); } else { no1 = no1 + F8; }

    var total: number;
    var mod_result: number;
    total = no1 + no2;
    mod_result = total % 10;

    if (mod_result == 0 && F9 == 0) {
      return true;
    } else {
      var new_total_string: string = total.toString();
      var new_total: number = Number(new_total_string.substring(0, 1));
      new_total = new_total + 1
      new_total = new_total * 10
      if (new_total - total == F9) { return true; } else { return false; }
    }

  }


  fix_number(num: number) {
    if (num > 9) {
      var str: string = num.toString();
      var n1: number = Number(str.substring(0, 1));
      var n2: number = Number(str.substring(1, 2));
      return n1 + n2;
    } else {
      return num;
    }

  }


  validate_email(myEmail) {
    let tmpEmail: string = myEmail.toString();
    //let email = New Regex("([\w-+]+(?:\.[\w-+]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,7})")
    const expression: RegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    const result: boolean = expression.test(tmpEmail);
    return result;
  }

  changeDates(dateValue) {
    if (dateValue) {
      if (dateValue.match(this.dateFormat)) {
        let dateArray = dateValue.split('/');
        let year = dateArray[2];
        let month = dateArray[1] - 1;
        let day = dateArray[0];
        const date = new Date(year, month, day);
        return date;
      } else {
        const date = new Date(dateValue);
        return date;
      }
    } else {
      return null
    }
  }


  get_phone_number(num: string) {
    var phone = num;
    phone = phone.replace('(', '');
    phone = phone.replace(')', '');
    phone = phone.replace(' ', '');
    phone = phone.replace('-', '');
    phone = phone.replace('  ', '');
    phone = phone.replace('_', '');
    return phone;
  }

  addYears(date, years) {
    const dateCopy = new Date(date);
    dateCopy.setFullYear(dateCopy.getFullYear() + years);
    return dateCopy;
  }

  submitPersonalInfo(values) {

    if (this.validate_sin(values.SIN)) {
      /*
      this.memberService.getSIN(values.SIN).subscribe(x => {  
          if (x) {
              let count = x;
              if (count != 0){
                toast({ html: 'SIN is used by other person !', classes: 'red' });

                this.step = 1;
                setTimeout(() => {
                  $('select').formSelect();
                  M.updateTextFields();
                }, 25);
                return;
              }
          }
      });
      */
      this.registrationFormModel.SIN = values.SIN;

    } else if (values.SIN == '') {
      this.registrationFormModel.SIN = '';
    } else {
      this.registrationFormModel.SIN = '';
    }

    if (!values.givenname) {
      toast({ html: 'Please Enter First Name !', classes: 'red' });
      return;
    }

    if (!values.lastname) {
      toast({ html: 'Please Enter Last Name !', classes: 'red' });
      return;
    }

    if (!values.dob) {
      toast({ html: 'Please Enter Date Of Birth !', classes: 'red' });
      return;
    } else {
      const limitDate = this.addYears(new Date(), -12);
      let myDob = values.dob.singleDate.formatted;
      console.log("0", myDob);
      let dateArray = myDob.split('/');
      let day = dateArray[0];
      let month = dateArray[1] - 1;
      let year = dateArray[2];
      const tmpDOB = new Date(year, month, day);

      //console.log("1", tmpDOB);
      //console.log("2", limitDate);
      if (tmpDOB > limitDate) {
        toast({ html: 'Must be over 12 years old !', classes: 'red' });
        return;
      }
    }

    if (!values.title) {
      toast({ html: 'Please Select Title !', classes: 'red' });
      return;
    }

    if (!values.gender) {
      toast({ html: 'Please Select Gender !', classes: 'red' });
      return;
    }

    if (!values.email) {
      toast({ html: 'Please Enter E-mail !', classes: 'red' });
      return;
    } else {
      const result = this.validate_email(values.email)
      if (!result) {
        toast({ html: 'E-mail is Incorrect', classes: 'red' });
        return;
      }
    }

    if (!values.home_address) {
      toast({ html: 'Please Enter Home Address !', classes: 'red' });
      return;
    }

    if (!values.city) {
      toast({ html: 'Please Enter City !', classes: 'red' });
      return;
    }

    if (!values.province) {
      toast({ html: 'Please Select Province !', classes: 'red' });
      return;
    }

    if (!values.postal_code) {
      toast({ html: 'Please Enter Postal Code !', classes: 'red' });
      return;
    }

    this.registrationFormModel.givenname = values.givenname;
    this.registrationFormModel.lastname = values.lastname;
    if (values.initial) { this.registrationFormModel.initial = values.initial; } else { this.registrationFormModel.initial = ""; }
    this.registrationFormModel.title = values.title;
    this.registrationFormModel.gender = values.gender;
    this.registrationFormModel.dob = this.changeDates(values.dob.singleDate.formatted).toDateString();

    if (values.cell) { this.registrationFormModel.cell = this.get_phone_number(values.cell); } else { this.registrationFormModel.cell = ""; }

    this.registrationFormModel.email = values.email;
    this.registrationFormModel.home_address = values.home_address;
    this.registrationFormModel.city = values.city;
    this.registrationFormModel.province = values.province;
    this.registrationFormModel.postal_code = values.postal_code;

    if (values.mailing_address) { this.registrationFormModel.mailing_address = values.mailing_address; }
    else { this.registrationFormModel.mailing_address = ""; }

    if (values.Econtact1) { this.registrationFormModel.Econtact1 = values.Econtact1; }
    else { this.registrationFormModel.Econtact1 = ""; }
    if (values.contct1PH) { this.registrationFormModel.contact1PH = values.contct1PH; }
    else { this.registrationFormModel.contact1PH = ""; }

    if (values.Econtact2) { this.registrationFormModel.Econtact2 = values.Econtact2; }
    else { this.registrationFormModel.Econtact2 = ""; }
    if (values.contct2PH) { this.registrationFormModel.contact2PH = values.contct2PH; }
    else { this.registrationFormModel.contact2PH = ""; }



    if (values.driver_license == "yes") {

      if (!values.driver_class) {
        toast({ html: 'Please Enter Driver License Class !', classes: 'red' });
        return;
      }

      if (!values.license_expiry_date) {
        toast({ html: 'Please Enter License Expiry Date !', classes: 'red' });
        return;
      }

      if (!values.province_issued) {
        toast({ html: 'Please Select License Province !', classes: 'red' });
        return;
      }


    }

    this.registrationFormModel.driver_license = values.driver_license;
    if (values.driver_class) { this.registrationFormModel.driver_class = values.driver_class; }
    else { this.registrationFormModel.driver_class = ""; }

    if (values.license_expiry_date) { this.registrationFormModel.license_expiry_date = this.changeDates(values.license_expiry_date.singleDate.formatted).toDateString(); }
    else { this.registrationFormModel.license_expiry_date = ""; }

    if (values.province_issued) { this.registrationFormModel.province_issued = values.province_issued; }
    else { this.registrationFormModel.province_issued = ""; }


    if (values.citizenship) { this.registrationFormModel.citizenship = values.citizenship; }
    else { this.registrationFormModel.citizenship = ""; }

    if (values.language) { this.registrationFormModel.language = values.language; }
    else { this.registrationFormModel.language = ""; }

    if (!values.reading_skill) {
      toast({ html: 'Please Select Reading Skill !', classes: 'red' });
      return;
    }

    if (!values.writing_skill) {
      toast({ html: 'Please Select Writing Skill !', classes: 'red' });
      return;
    }

    if (!values.listening_skill) {
      toast({ html: 'Please Select Listening Skill !', classes: 'red' });
      return;
    }

    if (!values.speaking_skill) {
      toast({ html: 'Please Select Speaking Skill !', classes: 'red' });
      return;
    }

    if (!values.aboriginal_group) {
      toast({ html: 'Please Select Indian Status !', classes: 'red' });
      return;
    }

    this.registrationFormModel.reading_skill = values.reading_skill;
    this.registrationFormModel.writing_skill = values.writing_skill;
    this.registrationFormModel.listening_skill = values.listening_skill;
    this.registrationFormModel.speaking_skill = values.speaking_skill;

    this.registrationFormModel.aboriginal_group = values.aboriginal_group;

    //console.log("Form Data A: ", this.registrationFormModel);
    this.onNext();
  }

  submitIncomeInfo(values) {

    if (!values.employment_status) {
      toast({ html: 'Please Select Current Employment Status !', classes: 'red' });
      return;
    }

    if (!values.ei_received) {
      toast({ html: 'Please Check if Received EI within the last 3 years', classes: 'red' });
      return;
    }

    if (!values.apply_ei) {
      toast({ html: 'Please Check if Applying for EI', classes: 'red' });
      return;
    }

    if (!values.disability) {
      toast({ html: 'Please Check if You Are a Person with a Disability', classes: 'red' });
      return;
    }

    if (!values.marital_status) {
      toast({ html: 'Please Select Marital Status', classes: 'red' });
      return;
    }

    this.registrationFormModel.employment_status = values.employment_status;
    if (values.emp_other_specify) { this.registrationFormModel.emp_other_specify = values.emp_other_specify; }
    else { this.registrationFormModel.emp_other_specify = ''; }

    this.registrationFormModel.ei_received = values.ei_received;
    this.registrationFormModel.apply_ei = values.apply_ei;
    this.registrationFormModel.disability = values.disability;
    this.registrationFormModel.marital_status = Number(values.marital_status);
    this.registrationFormModel.t_dependants = this.total_childs;  // Number(values.t_dependants);

    if (values.kids_age) { this.registrationFormModel.kids_age = this.list_of_kids_age; }   //     values.kids_age
    else { this.registrationFormModel.kids_age = ''; }


    if (!values.request_resume) {
      toast({ html: 'Please Check if Reqest Resume Assistance', classes: 'red' });
      return;
    }

    if (!values.request_skill) {
      toast({ html: 'Please Check if Reqest Skills Enhancement', classes: 'red' });
      return;
    }


    if (!values.request_career) {
      toast({ html: 'Please Check if Reqest Career Decision Making', classes: 'red' });
      return;
    }

    if (!values.request_job) {
      toast({ html: 'Please Check if Reqest Job Search', classes: 'red' });
      return;
    }

    if (!values.request_funding) {
      toast({ html: 'Please Check if Reqest Funding', classes: 'red' });
      return;
    }


    this.registrationFormModel.request_resume = values.request_resume;
    this.registrationFormModel.request_skill = values.request_skill;
    this.registrationFormModel.request_career = values.request_career;
    this.registrationFormModel.request_job = values.request_job;
    this.registrationFormModel.request_funding = values.request_funding;

    if (values.request_program) { this.registrationFormModel.request_program = values.request_program; }
    else { this.registrationFormModel.request_program = ''; }

    if (values.request_institute) { this.registrationFormModel.request_institute = values.request_institute; }
    else { this.registrationFormModel.request_institute = ''; }

    if (values.request_startdate) { this.registrationFormModel.request_startdate = this.changeDates(values.request_startdate.singleDate.formatted).toDateString(); }
    else { this.registrationFormModel.request_startdate = ""; }

    if (values.request_enddate) { this.registrationFormModel.request_enddate = this.changeDates(values.request_enddate.singleDate.formatted).toDateString(); }
    else { this.registrationFormModel.request_enddate = ""; }


    //console.log("Form Data B: ", this.registrationFormModel);
    this.onNext();
  }

  submitEducationInfo(values) {

    //completed_year1
    if (values.completed_year1) {
      const year1 = Number(values.completed_year1);
      if (year1 > this.todayDate || year1 < 2000) {
        toast({ html: 'Year completed Must Be Between 2000 And ' + this.todayDate + ' !', classes: 'red' });
        return;
      }
    }
    //completed_year2
    if (values.completed_year2) {
      const year2 = Number(values.completed_year2);
      if (year2 > this.todayDate || year2 < 2000) {
        toast({ html: 'Year completed Must Be Between 2000 And ' + this.todayDate + ' !', classes: 'red' });
        return;
      }
    }

    //values.trade_year
    if (values.trade_year) {
      const year3 = Number(values.trade_year);
      if (year3 > this.todayDate || year3 < 2000) {
        toast({ html: 'Completed Year in Trade Must Be Between 2000 And ' + this.todayDate + ' !', classes: 'red' });
        return;
      }
    }


    if (!values.highschool) {
      toast({ html: 'Please Enter High School', classes: 'red' });
      return;
    }
    if (!values.hs_grade) {
      toast({ html: 'Please Selec Grade Achieved in High School', classes: 'red' });
      return;
    }
    if (!values.hs_city) {
      toast({ html: 'Please Enter City in High School', classes: 'red' });
      return;
    }
    if (!values.hs_province) {
      toast({ html: 'Please Select Province in High School', classes: 'red' });
      return;
    }
    this.registrationFormModel.highschool = values.highschool;
    this.registrationFormModel.hs_grade = values.hs_grade;
    this.registrationFormModel.hs_city = values.hs_city;
    this.registrationFormModel.hs_province = values.hs_province;

    if (!values.has_PSE1) {
      toast({ html: 'Please Check if You Have Post Secondary', classes: 'red' });
      return;
    }
    if (values.has_PSE1 == 'yes') {

      if (!values.pse_program1) {
        toast({ html: 'Please Enter Program/Course in Post-Secondary', classes: 'red' });
        return;
      }

      if (!values.pse_institution1) {
        toast({ html: 'Please Enter Institution Name in Post-Secondary', classes: 'red' });
        return;
      }

      if (!values.completed_year1) {
        toast({ html: 'Please Enter Year in Post-Secondary', classes: 'red' });
        return;
      }

      if (!values.pse_city1) {
        toast({ html: 'Please Enter City in Post-Secondary', classes: 'red' });
        return;
      }

      if (!values.pse_province1) {
        toast({ html: 'Please Select Province in Post-Secondary', classes: 'red' });
        return;
      }

      this.registrationFormModel.pse_program1 = values.pse_program1;
      this.registrationFormModel.pse_institution1 = values.pse_institution1;
      this.registrationFormModel.completed_year1 = Number(values.completed_year1);
      this.registrationFormModel.pse_city1 = values.pse_city1;
      this.registrationFormModel.pse_province1 = values.pse_province1;
    } else {
      this.registrationFormModel.pse_program1 = '';
      this.registrationFormModel.pse_institution1 = '';
      this.registrationFormModel.completed_year1 = 0;
      this.registrationFormModel.pse_city1 = '';
      this.registrationFormModel.pse_province1 = '';
    }


    if (!values.has_PSE2) {
      toast({ html: 'Please Check if You Have Another post Secondary', classes: 'red' });
      return;
    }

    if (values.has_PSE2 == 'yes') {

      if (!values.pse_program2) {
        toast({ html: 'Please Enter Program/Course in Post-Secondary', classes: 'red' });
        return;
      }

      if (!values.pse_institution2) {
        toast({ html: 'Please Enter Institution Name in Post-Secondary', classes: 'red' });
        return;
      }

      if (!values.completed_year2) {
        toast({ html: 'Please Enter Year in Post-Secondary', classes: 'red' });
        return;
      }

      if (!values.pse_city2) {
        toast({ html: 'Please Enter City in Post-Secondary', classes: 'red' });
        return;
      }

      if (!values.pse_province2) {
        toast({ html: 'Please Select Province in Post-Secondary', classes: 'red' });
        return;
      }


      this.registrationFormModel.pse_program2 = values.pse_program2;
      this.registrationFormModel.pse_institution2 = values.pse_institution2;
      this.registrationFormModel.completed_year2 = Number(values.completed_year2);
      this.registrationFormModel.pse_city2 = values.pse_city2;
      this.registrationFormModel.pse_province2 = values.pse_province2;

    } else {
      this.registrationFormModel.pse_program2 = '';
      this.registrationFormModel.pse_institution2 = '';
      this.registrationFormModel.completed_year2 = 0;
      this.registrationFormModel.pse_city2 = '';
      this.registrationFormModel.pse_province2 = '';
    }


    if (!values.has_trade) {
      toast({ html: 'Please Check if You Have Trade', classes: 'red' });
      return;
    }
    this.registrationFormModel.has_trade = values.has_trade;
    if (values.has_trade == 'yes') {

      if (!values.trade) {
        toast({ html: 'Please Enter Trade Name', classes: 'red' });
        return;
      }
      if (!values.trade_course) {
        toast({ html: 'Please Enter Program/Course in Trade', classes: 'red' });
        return;
      }
      if (!values.trade_institution) {
        toast({ html: 'Please Enter Institution Name in Trade', classes: 'red' });
        return;
      }

      if (!values.trade_level) {
        toast({ html: 'Please Enter Completed Level in Trade', classes: 'red' });
        return;
      }

      if (!values.trade_year) {
        toast({ html: 'Please Enter Completed Year in Trade', classes: 'red' });
        return;
      }
      if (!values.trade_city) {
        toast({ html: 'Please Enter City in Trade', classes: 'red' });
        return;
      }

      if (!values.trade_province) {
        toast({ html: 'Please Select Province in Trade', classes: 'red' });
        return;
      }

      this.registrationFormModel.trade = values.trade;
      this.registrationFormModel.trade_course = values.trade_course;
      this.registrationFormModel.trade_institution = values.trade_institution;
      this.registrationFormModel.trade_level = values.trade_level;
      this.registrationFormModel.trade_year = Number(values.trade_year);
      this.registrationFormModel.trade_city = values.trade_city;
      this.registrationFormModel.trade_province = values.trade_province;

    } else {

      this.registrationFormModel.trade = '';
      this.registrationFormModel.trade_course = '';
      this.registrationFormModel.trade_institution = '';
      this.registrationFormModel.trade_level = '';
      this.registrationFormModel.trade_year = 0;
      this.registrationFormModel.trade_city = '';
      this.registrationFormModel.trade_province = '';

    }

    if (!values.referred_by) {
      toast({ html: 'Please Answer How did you hear about ' + this.nationName, classes: 'red' });
      return;
    }
    this.registrationFormModel.referred_by = values.referred_by;
    this.registrationFormModel.comments = values.comments;


    this.onNext();
    //this.sign();

  }

  submitDocuments(values) {

    if (values.description) { this.registrationFormModel.description = values.description; }
    else { this.registrationFormModel.description = ''; }

    if (!values.document) {
      toast({ html: 'Please upload documents ', classes: 'red' });
      return;
    }

    //console.log("Form Data D: ", this.registrationFormModel);
    this.onNext();
    this.sign();
  }


  sign() {

    setTimeout(() => {
      this.canvas = document.querySelector('canvas');
      const parentWidth = $(this.canvas).parent().outerWidth();
      this.canvas.setAttribute('width', parentWidth);
      this.canvas.setAttribute('height', '175px');
      this.signaturePad = new SignaturePad(this.canvas, {
        backgroundColor: 'rgb(255,255,255)'
      });
    }, 50);

  }

  submitSignature() {

    if (this.signaturePad.isEmpty()) {
      toast({ html: 'Please Sign the Registraton Form!', classes: 'red' });
      return;
    } else {

      const dataUrl = this.signaturePad.toDataURL();
      this.signature.patchValue(dataUrl);

      this.registrationFormModel.signature = this.signaturePad.toDataURL().replace('data:image/png;base64,', '');
      this.registrationFormModel.sign_date = new Date().toDateString();

      //console.log("Form Data: ", this.registrationFormModel);


      setTimeout(() => {
        $('.modal').modal();
        $('select').formSelect();
        $('#MessageModal').modal('open');
        M.updateTextFields();
      }, 25);


    }

  }



  ReadytoSubmitYes() {

    this.registrationFormModel.fileStreamID = 0;
    if (this.binDoc && this.preview) {
      this.documentService.postDoc(this.binDoc).subscribe(x => {
        this.registrationFormModel.fileStreamID = x;

        $('#MessageModal').modal('close');
        this.registration_service.saveRegistrationForm(this.registrationFormModel).subscribe(x => {
          if (x) {
            toast({ html: 'Registration From is Submitted Successfully !', classes: 'green' });
            this.router.navigate(['/home']);
          }
        });

      });

    } else {

      $('#MessageModal').modal('close');
      this.registration_service.saveRegistrationForm(this.registrationFormModel).subscribe(x => {
        if (x) {
          toast({ html: 'Registration From is Submitted Successfully !', classes: 'green' });
          this.router.navigate(['/home']);
        }
      });

    }

  }


  ReadytoSubmitNo() {
    $('#MessageModal').modal('close');
  }




  onNext() {

    this.step += 1;

    setTimeout(() => {
      $('select').formSelect();
      $('.collapsible').collapsible();
      $('.tooltipped').tooltip();
      $('.modal').modal();
      // $('.tabs').tabs();
      M.updateTextFields();
    }, 25);

    window.scrollTo(0, 0);

  }

  onBack() {

    if (this.step == 1) {
      //this.backClicked();
      this.location.back();
    }
    if (this.step > 1) { this.step -= 1; }

    setTimeout(() => {
      $('select').formSelect();
      $('.collapsible').collapsible();
      $('.tooltipped').tooltip();
      $('.modal').modal();
      //$('.tabs').tabs();
      M.updateTextFields();
    }, 25);
    // window.scrollTo(0, 0);

  }


  onBirthDateChange(event: IMyDateModel): void {
    if (this.personalInfoForm) { this.personalInfoForm.patchValue({ dob: event.singleDate.formatted }); }
  }

  onLicenseExpiryDateChange(event: IMyDateModel): void {
    if (this.personalInfoForm) { this.personalInfoForm.patchValue({ license_expiry_date: event.singleDate.formatted }); }
  }

  onStartDateChange(event: IMyDateModel): void {
    if (this.incomeInfoForm) { this.incomeInfoForm.patchValue({ request_startdate: event.singleDate.formatted }); }
  }

  onEndDateChange(event: IMyDateModel): void {
    if (this.incomeInfoForm) { this.incomeInfoForm.patchValue({ request_enddate: event.singleDate.formatted }); }
  }




  submitChildrenAges() {

    if (this.total_childs == 1) {
      if (!this.child1_age) {
        toast({ html: 'Please Select Child 1 Age', classes: 'red' });
        return;
      }
      this.list_of_kids_age = this.child1_age;
    }

    if (this.total_childs == 2) {
      if (!this.child1_age || !this.child2_age) {
        toast({ html: 'Please Select All Children Age', classes: 'red' });
        return;
      }
      this.list_of_kids_age = this.child1_age + ', ' + this.child2_age;
    }

    if (this.total_childs == 3) {
      if (!this.child1_age || !this.child2_age || !this.child3_age) {
        toast({ html: 'Please Select All Children Age', classes: 'red' });
        return;
      }
      this.list_of_kids_age = this.child1_age + ', ' + this.child2_age + ', ' + this.child3_age;
    }

    if (this.total_childs == 4) {
      if (!this.child1_age || !this.child2_age || !this.child3_age || !this.child4_age) {
        toast({ html: 'Please Select All Children Age', classes: 'red' });
        return;
      }
      this.list_of_kids_age = this.child1_age + ', ' + this.child2_age + ', ' + this.child3_age + ', ' + this.child4_age;
    }

    if (this.total_childs == 5) {
      if (!this.child1_age || !this.child2_age || !this.child3_age || !this.child4_age || !this.child5_age) {
        toast({ html: 'Please Select All Children Age', classes: 'red' });
        return;
      }
      this.list_of_kids_age = this.child1_age + ', ' + this.child2_age + ', ' + this.child3_age + ', ' + this.child4_age + ', ' + this.child5_age;

    }

    if (this.total_childs == 6) {
      if (!this.child1_age || !this.child2_age || !this.child3_age || !this.child4_age || !this.child5_age || !this.child6_age) {
        toast({ html: 'Please Select All Children Age', classes: 'red' });
        return;
      }
      this.list_of_kids_age = this.child1_age + ', ' + this.child2_age + ', ' + this.child3_age + ', ' + this.child4_age + ', ' + this.child5_age + ', ' + this.child6_age;

    }

    if (this.total_childs == 7) {
      if (!this.child1_age || !this.child2_age || !this.child3_age || !this.child4_age || !this.child5_age || !this.child6_age || !this.child7_age) {
        toast({ html: 'Please Select All Children Age', classes: 'red' });
        return;
      }
      this.list_of_kids_age = this.child1_age + ', ' + this.child2_age + ', ' + this.child3_age + ', ' + this.child4_age + ', ' + this.child5_age + ', ' + this.child6_age + ', ' + this.child7_age;

    }

    if (this.total_childs == 8) {
      if (!this.child1_age || !this.child2_age || !this.child3_age || !this.child4_age || !this.child5_age || !this.child6_age || !this.child7_age || !this.child8_age) {
        toast({ html: 'Please Select All Children Age', classes: 'red' });
        return;
      }
      this.list_of_kids_age = this.child1_age + ', ' + this.child2_age + ', ' + this.child3_age + ', ' + this.child4_age + ', ' + this.child5_age + ', ' + this.child6_age + ', ' + this.child7_age + ', ' + this.child8_age;

    }

    if (this.total_childs == 9) {
      if (!this.child1_age || !this.child2_age || !this.child3_age || !this.child4_age || !this.child5_age || !this.child6_age || !this.child7_age || !this.child8_age || !this.child9_age) {
        toast({ html: 'Please Select All Children Age', classes: 'red' });
        return;
      }
      this.list_of_kids_age = this.child1_age + ', ' + this.child2_age + ', ' + this.child3_age + ', ' + this.child4_age + ', ' + this.child5_age + ', ' + this.child6_age + ', ' + this.child7_age + ', ' + this.child8_age + ', ' + this.child9_age;
    }


    setTimeout(() => {
      $('#AddAgeModal').modal('close');
      $('select').formSelect();
      M.updateTextFields();
    }, 25);

  }


  addChildren() {

    setTimeout(() => {
      $('.modal').modal();
      $('#AddAgeModal').modal('open');
      // $('.collapsible').collapsible();
      $('select').formSelect();
      M.updateTextFields();
    }, 25);

  }


  additionKids() {

    if (this.total_childs < 9) {
      this.total_childs += 1;
    }

    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);

  }

  minusKids() {

    if (this.total_childs > 0) {
      this.total_childs -= 1;
    }

    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }


  onChild1AgeChange(value) {
    this.child1_age = value;
  }

  onChild2AgeChange(value) {
    this.child2_age = value;
  }

  onChild3AgeChange(value) {
    this.child3_age = value;
  }

  onChild4AgeChange(value) {
    this.child4_age = value;
  }

  onChild5AgeChange(value) {
    this.child5_age = value;
  }

  onChild6AgeChange(value) {
    this.child6_age = value;
  }

  onChild7AgeChange(value) {
    this.child7_age = value;
  }

  onChild8AgeChange(value) {
    this.child8_age = value;
  }

  onChild9AgeChange(value) {
    this.child9_age = value;
  }

  employmentStatusSelected(value) {
    if (value === 'Other') {
      this.isOtherEmpStatus = true;
    } else {
      this.isOtherEmpStatus = false;
    }

    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }



  onTradeYes() {

    this.hasTrade = true;
    this.educationInfoForm.get('trade').setValidators(Validators.required);
    this.educationInfoForm.get('trade_course').setValidators(Validators.required);
    this.educationInfoForm.get('trade_institution').setValidators([Validators.required]);
    this.educationInfoForm.get('trade_level').setValidators(Validators.required);
    this.educationInfoForm.get('trade_year').setValidators(Validators.required);
    this.educationInfoForm.get('trade_city').setValidators(Validators.required);
    this.educationInfoForm.get('trade_province').setValidators(Validators.required);

    this.educationInfoForm.get('trade').updateValueAndValidity();
    this.educationInfoForm.get('trade_course').updateValueAndValidity();
    this.educationInfoForm.get('trade_institution').updateValueAndValidity();
    this.educationInfoForm.get('trade_level').updateValueAndValidity();
    this.educationInfoForm.get('trade_year').updateValueAndValidity();
    this.educationInfoForm.get('trade_city').updateValueAndValidity();
    this.educationInfoForm.get('trade_province').updateValueAndValidity();

    setTimeout(() => {
      M.updateTextFields();
    }, 25);


  }

  onTradeNo() {

    this.educationInfoForm.patchValue({ 'trade': '' });
    this.educationInfoForm.patchValue({ 'trade_course': '' });
    this.educationInfoForm.patchValue({ 'trade_institution': '' });
    this.educationInfoForm.patchValue({ 'trade_level': '' });
    this.educationInfoForm.patchValue({ 'trade_year': '' });
    this.educationInfoForm.patchValue({ 'trade_city': '' });
    this.educationInfoForm.patchValue({ 'trade_province': '' });

    this.educationInfoForm.get('trade').clearValidators();
    this.educationInfoForm.get('trade_course').clearValidators();
    this.educationInfoForm.get('trade_institution').clearValidators();
    this.educationInfoForm.get('trade_level').clearValidators();
    this.educationInfoForm.get('trade_year').clearValidators();
    this.educationInfoForm.get('trade_city').clearValidators();
    this.educationInfoForm.get('trade_province').clearValidators();

    this.educationInfoForm.get('trade').updateValueAndValidity();
    this.educationInfoForm.get('trade_course').updateValueAndValidity();
    this.educationInfoForm.get('trade_institution').updateValueAndValidity();
    this.educationInfoForm.get('trade_level').updateValueAndValidity();
    this.educationInfoForm.get('trade_year').updateValueAndValidity();
    this.educationInfoForm.get('trade_city').updateValueAndValidity();
    this.educationInfoForm.get('trade_province').updateValueAndValidity();

    this.hasTrade = false;
    setTimeout(() => {
      M.updateTextFields();
    }, 25);
  }


  onPostSecondary1Yes() {
    this.hasPSE1 = true;
    this.educationInfoForm.get('pse_program1').setValidators(Validators.required);
    this.educationInfoForm.get('pse_institution1').setValidators(Validators.required);
    this.educationInfoForm.get('completed_year1').setValidators([Validators.required]);
    this.educationInfoForm.get('pse_city1').setValidators(Validators.required);
    this.educationInfoForm.get('pse_province1').setValidators(Validators.required);

    this.educationInfoForm.get('pse_program1').updateValueAndValidity();
    this.educationInfoForm.get('pse_institution1').updateValueAndValidity();
    this.educationInfoForm.get('completed_year1').updateValueAndValidity();
    this.educationInfoForm.get('pse_city1').updateValueAndValidity();
    this.educationInfoForm.get('pse_province1').updateValueAndValidity();

    setTimeout(() => {
      M.updateTextFields();
    }, 25);

  }


  onPostSecondary1No() {

    this.educationInfoForm.patchValue({ 'pse_program1': '' });
    this.educationInfoForm.patchValue({ 'pse_institution1': '' });
    this.educationInfoForm.patchValue({ 'completed_year1': '' });
    this.educationInfoForm.patchValue({ 'pse_city1': '' });
    this.educationInfoForm.patchValue({ 'pse_province1': '' });

    this.educationInfoForm.get('pse_program1').clearValidators();
    this.educationInfoForm.get('pse_institution1').clearValidators();
    this.educationInfoForm.get('completed_year1').clearValidators();
    this.educationInfoForm.get('pse_city1').clearValidators();
    this.educationInfoForm.get('pse_province1').clearValidators();

    this.educationInfoForm.get('pse_program1').updateValueAndValidity();
    this.educationInfoForm.get('pse_institution1').updateValueAndValidity();
    this.educationInfoForm.get('completed_year1').updateValueAndValidity();
    this.educationInfoForm.get('pse_city1').updateValueAndValidity();
    this.educationInfoForm.get('pse_province1').updateValueAndValidity();

    this.hasPSE1 = false;
    setTimeout(() => {
      M.updateTextFields();
    }, 25);

  }

  onPostSecondary2Yes() {
    this.hasPSE2 = true;
    this.educationInfoForm.get('pse_program2').setValidators(Validators.required);
    this.educationInfoForm.get('pse_institution2').setValidators(Validators.required);
    this.educationInfoForm.get('completed_year2').setValidators([Validators.required]);
    this.educationInfoForm.get('pse_city2').setValidators(Validators.required);
    this.educationInfoForm.get('pse_province2').setValidators(Validators.required);

    this.educationInfoForm.get('pse_program2').updateValueAndValidity();
    this.educationInfoForm.get('pse_institution2').updateValueAndValidity();
    this.educationInfoForm.get('completed_year2').updateValueAndValidity();
    this.educationInfoForm.get('pse_city2').updateValueAndValidity();
    this.educationInfoForm.get('pse_province2').updateValueAndValidity();

    setTimeout(() => {
      M.updateTextFields();
    }, 25);

  }

  onPostSecondary2No() {

    this.educationInfoForm.patchValue({ 'pse_program2': '' });
    this.educationInfoForm.patchValue({ 'pse_institution2': '' });
    this.educationInfoForm.patchValue({ 'completed_year2': '' });
    this.educationInfoForm.patchValue({ 'pse_city2': '' });
    this.educationInfoForm.patchValue({ 'pse_province2': '' });

    this.educationInfoForm.get('pse_program2').clearValidators();
    this.educationInfoForm.get('pse_institution2').clearValidators();
    this.educationInfoForm.get('completed_year2').clearValidators();
    this.educationInfoForm.get('pse_city2').clearValidators();
    this.educationInfoForm.get('pse_province2').clearValidators();

    this.educationInfoForm.get('pse_program2').updateValueAndValidity();
    this.educationInfoForm.get('pse_institution2').updateValueAndValidity();
    this.educationInfoForm.get('completed_year2').updateValueAndValidity();
    this.educationInfoForm.get('pse_city2').updateValueAndValidity();
    this.educationInfoForm.get('pse_province2').updateValueAndValidity();

    this.hasPSE2 = false;
    setTimeout(() => {
      M.updateTextFields();
    }, 25);

  }


  clearSignature() {
    this.signaturePad.clear();
    this.signature.reset();
  }


  //////////////////////////////////////////////////////////////////////
  uploadIdYes() {
    this.showUpload = true;
  }

  uploadIdNo() {
    this.showUpload = false;
  }

  handleFileInput(files: FileList) {
    if (files) {
      if (!this.isImage(files[0])) {
        alert('Invalid Document Type. \n Only Images Are Allowed.');
        return;
      }
      this.binDoc = files[0];
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = () => {
        this.fileName = files[0].name;
        this.preview = reader.result as string;
        $('#previewModal').modal('open');
      };
    }
  }

  isImage(file: File): boolean {
    return /^image\//.test(file.type);
  }

  mobilePicture() {
    navigator.camera.getPicture(
      (imageData: any) => {
        if (imageData) {
          const imageName = 'MyNation.jpeg';
          const imageBlob = this.dataURItoBlob(imageData);
          const imageFile = new File([imageBlob], imageName, { type: 'image/jpeg' });
          this.binDoc = imageFile;
          this.preview = 'data:image/jpeg;base64,' + imageData;
        }
      },
      this.onMobileCameraFail,
      {
        quality: 50,
        destinationType: navigator.camera.DestinationType.DATA_URL,
        encodingType: navigator.camera.EncodingType.JPEG,
        mediaType: navigator.camera.MediaType.PICTURE,
        sourceType: navigator.camera.PictureSourceType.CAMERA,
      });
  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/png' });
    return blob;
  }

  libraryPicture() {
    navigator.camera.getPicture(
      (imageData: any) => {
        if (imageData) {
          const imageName = 'MyNation.jpeg';
          const imageBlob = this.dataURItoBlob(imageData);
          const imageFile = new File([imageBlob], imageName, { type: 'image/jpeg' });
          this.binDoc = imageFile;
          this.preview = 'data:image/jpeg;base64,' + imageData;
        }
      },
      this.onMobileCameraFail,
      {
        quality: 50,
        destinationType: navigator.camera.DestinationType.DATA_URL,
        encodingType: navigator.camera.EncodingType.JPEG,
        mediaType: navigator.camera.MediaType.PICTURE,
        sourceType: navigator.camera.PictureSourceType.PHOTOLIBRARY,
      });
  }

  onMobileCameraFail(message) {
    alert(message);
  }


}
