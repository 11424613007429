import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})

export class OtherRolesService {

  constructor(private db: AngularFirestore) { }
  
  ListRef: AngularFirestoreCollection<any>;

  saveInvitation(model) {
    const invitationDetail = {
      id: this.db.createId(),
      email: model.email,
      firstName: model.firstName,
      lastName: model.lastName,
      invitationDate: model.invitationDate,
      applicantID: model.applicantID,
      displayName: model.displayName,
      isChief: model.isChief ? model.isChief : false,
      isCouncillor: model.isCouncillor ? model.isCouncillor : false
    };
    return this.db.collection('Other-Roles-Invitation').doc(invitationDetail.id).set(invitationDetail);
  }

  getInvitationByApplicantId(applicantId: number) {
    return this.db.collection('Other-Roles-Invitation', ref => ref.where('applicantID', '==', applicantId));
  }

  updateInvitation(model) {
    const updatedContent = {
      id: model.id,
      invitedByEmail: model.invitedByEmail,
      email: model.email,
      otherRoles: model.otherRoles
    };
    return this.db.collection('Other-Roles-Invitation').doc(model.id).update(updatedContent);
  }

  deleteInvitation(id) {
      return this.db.collection('Other-Roles-Invitation').doc(id).delete();
  }

  getInvitationList(): AngularFirestoreCollection<any> {
   this.ListRef =  this.db.collection('Other-Roles-Invitation' );
   return this.ListRef;
  }

}