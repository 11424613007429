<h6 class="center p-m-2 b-m-2"> <strong> My Previous Income Statement </strong></h6>

<div class="row col s12">
  <div class="col l2"></div>

  <div class="row col s12 m12 l8" id="step1" *ngIf="step1">

    <div class="row card-panel white">

      <h6 class="center red-text"><strong>Would You like to declare the same income as the your previous declaration
          ?</strong></h6>
      <div class="section"></div>
      <ul class="row collapsible">
        <li *ngFor="let d of previousDeclaration">
          <div class="collapsible-header" style="display: block">
            <i class="material-icons blue-text">add</i>
            <strong class="black-text">{{d.catagory}}</strong>
            <span class="right blue-text"><strong> {{d.value | currency}} </strong> </span>
          </div>
          <div class="collapsible-body white">
            <div class="collection">
              <a class="collection-item">
                <div class="row"><strong>Desc:</strong><a class="secondary-content black-text"><strong>{{ d.description
                      }}</strong> </a></div>
              </a>
              <a class="collection-item">
                <div class="row"><strong>Owner:</strong><a class="secondary-content black-text"><strong>{{ d.member_name
                      }}</strong> </a></div>
              </a>
              <a class="collection-item">
                <div class="row"><strong>Date Received:</strong><a class="secondary-content black-text"><strong>{{
                      d.date_recieved | date:'dd/MMM/yyyy' }}</strong> </a></div>
              </a>
            </div>
          </div>
        </li>
        <li>
          <div class="collapsible-header" style="display: block">
            <i class="material-icons green-text">monetization_on</i>
            <strong class="black-text">Total Declared Income</strong>
            <span class="right green-text"><strong> {{previousDeclarationTotal | currency}} </strong> </span>
          </div>
          <div class="collapsible-body">
            <h6 class="center green-text"><strong> {{previousDeclarationTotal | currency}} </strong> </h6>
          </div>
        </li>
      </ul>
      <div class="section"></div>
      <button class="row btn waves-effect waves-light col s12 green " (click)="submitStatement()">
        <i class="material-icons right">cloud_upload</i> Submit Income Statement
      </button>

      <button [ngClass]="'row btn waves-effect waves-light col s12 ' + themeColor " (click)="backClicked()">
        <i class="material-icons left">keyboard_backspace</i> Back To My Income Statement Menu
      </button>
    </div>
  </div>

  <div class="row col s12 m12 l8" id="step2" *ngIf="step2">
    <div class="row">
      <div class="col s12">
        <div class="card-panel">
          <div class="row center">
            <h5 class="green-text"><strong>Thank You!</strong></h5>
            <h6><strong> Your Income Statement was successfully sent to our officers. </strong></h6>
            <br />
          </div>
        </div>
      </div>
    </div>

    <div class="section"></div>

    <button [ngClass]="'row btn waves-effect waves-light col s12 ' + themeColor " (click)="backClicked()">
      <i class="material-icons left">keyboard_backspace</i> Back To My Income Statement
    </button>

  </div>
  <div class="col l2"></div>
</div>