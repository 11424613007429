import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { SessionsMonitorService } from 'src/app/services/firebase/sessions-monitor.service';

declare var $: any;

@Component({
  selector: 'app-admin-activity',
  templateUrl: './admin-activity.component.html',
  styleUrls: ['./admin-activity.component.css']
})
export class AdminActivityComponent implements OnInit {

  showMessage = false;
  clientID: any;
  sessions: any[];
  groupedSessionsArray: any[];

  constructor(
    private sessionMonitor: SessionsMonitorService, 
    private route: ActivatedRoute,
    private location: Location,
    private router: Router) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.clientID = params.get('id');
    });

    if (this.clientID) {
      this.sessionMonitor.getSessionsList(this.clientID).valueChanges().subscribe(
        ss => {
          if (ss.length > 0) {
            this.showMessage = false;
            this.sessions = ss;
            this.sessions = this.sessions.sort(
              (a, b) => {
                return b.sessionStart.toDate() - a.sessionStart.toDate();
              }
            );

            const groups = this.sessions.reduce((groups, sessionList) => {
              const date = sessionList.sessionStart.toDate().toDateString();
              if (!groups[date]) {
                groups[date] = [];
              }
              groups[date].push(sessionList);
              return groups;
            }, {});

            // Edit: to add it in the array format instead
            this.groupedSessionsArray = Object.keys(groups).map((date) => {
              return {
                date,
                sessions: groups[date]
              };
            });

            setTimeout(() => {
              $('.collapsible').collapsible();
            }, 25);
          } else {
            this.showMessage = true;
          }
        }
      );
    }
  }

  getDateDiff(startDate, endDate) {
    if (startDate && endDate) {
      const diff = endDate.toDate().getTime() - startDate.toDate().getTime();
      const days = Math.floor(diff / (60 * 60 * 24 * 1000));
      const hours = Math.floor(diff / (60 * 60 * 1000)) - (days * 24);
      const minutes = Math.floor(diff / (60 * 1000)) - ((days * 24 * 60) + (hours * 60));
      const seconds = Math.floor(diff / 1000) - ((days * 24 * 60 * 60) + (hours * 60 * 60) + (minutes * 60));

      let datediff = '';
      if (hours > 0) { datediff += hours + 'h '; }
      if (minutes > 0) { datediff += minutes + 'm '; }
      if (seconds > 0) { datediff += seconds + 's '; }
      return datediff;
    }
    return 'N/A'
  }

  navigate(item) {
    const routeMappings = {
      'Admins': 'admin/dashboard-sub-menu/admins-list',
      'Membership': 'admin/members',
      'Applications': 'admin/dashboard-sub-menu/applications',
      'DemoRequest': 'admin/dashboard-sub-menu/demo-request',
    };

    const route = routeMappings[item];
    if (route) {
      this.router.navigate([route]);
    }
  }

  backClicked() {
    this.location.back();
  }
  
}
