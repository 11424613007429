<div class="container-pi">
    <div class="">
        <h4 class="title-section1 title-member  icon-texts"><span class="material-symbols-outlined back-icon p-r-1"
            (click)=" back()">
            arrow_circle_left
        </span>House Repairs</h4>
    </div>
    
    <app-firebase-loading *ngIf="loading"></app-firebase-loading>
    
    <div class="row" *ngIf="step1 && !loading">
        
    
        <div class="col s12 m12 l12">
    
            <div class="row center card-panel white b-n-1" *ngIf="!requests">
                <i style="font-size: 100px;" class="material-icons blue-text">
                    info
                </i>
                <p class="blue-text"> <strong>You Have No Pending Requests.</strong></p>
            </div>
    
            <div *ngIf="requests">
                <div class="row card-panel white col s12 b-n-1" *ngFor="let request of requests;let i=index;">
                    <div class="row col s12">
                        <div class="col m8 s12">
                            <h6><strong>{{request.title}}</strong></h6>
                            <p>{{request.description}}</p>
    
                            <a (click)="onShowMore(i)" class="btn-flat"
                                style="margin-bottom: 15px; border: 1px black solid;">
                                <span *ngIf="!showMore[i]">
                                    <i class="large material-icons">
                                        arrow_drop_down</i>Show More
                                </span>
                                <span *ngIf="showMore[i]">
                                    <i class="large material-icons">
                                        arrow_drop_up</i>Show Less
                                </span>
                            </a>
                        </div>
    
                        <div class="row col m4 s12">
                            <div class="row col s12">
                                <button class="btn white right tooltipped" data-position="bottom"
                                    data-tooltip="Open as a PDF" type="button" style="margin-bottom: 25px;"
                                    (click)="openPdf(i)" style="margin:5px;"><i
                                        class="material-icons black-text">open_in_new</i></button>
    
                                <button class="btn white right tooltipped" data-position="bottom"
                                    data-tooltip="Download as PDF" type="button" (click)="downloadPdf(i)"
                                    style="margin:5px;"><i class="material-icons black-text">download</i></button>
                            </div>
    
                            <p
                                [ngClass]="{'Pending': 'amber-text' ,'Approved': 'green-text', 'Declined' : 'red-text'}[request.status]">
                                <i class="material-icons tiny">
                                    circle
                                </i><strong class="pulse">{{request.status | uppercase}}</strong>
                            </p>
    
    
                            <div *ngIf="request.status === 'Approved'">
                                <p>In Progress</p>
                                <div *ngIf="request.previouseDecision" class="col s12">
    
                                    <p *ngIf="request.appeal" class="col s8">Appealed on {{ isDate(request.appeal.date) ?
                                        (request.appeal.date | date:
                                        'dd/MM/yyyy') : request.appeal.date.toDate().toDateString() | date:
                                        'dd/MM/yyyy'}}</p>
                                    <p class="green-text col s4">Appeal Accepted</p>
                                </div>
                            </div>
    
                            <div *ngIf="request.status === 'Declined'">
                                <button *ngIf="!request.appeal" [ngClass]="{'hide':showMore[i] }"
                                    class="col s12 btn waves-effect blue lighten-3" (click)="appealDecision(i)">
                                    <i class="material-icons right">gavel</i> Appeal Decision
                                </button>
    
                                <div class="col s12">
                                    <p class="col s12 m8" *ngIf="request.appeal">Appealed on {{ isDate(request.appeal.date)
                                        ?
                                        (request.appeal.date | date:
                                        'dd/MM/yyyy') : request.appeal.date.toDate().toDateString() | date:
                                        'dd/MM/yyyy'}}</p>
                                    <p *ngIf="request.previouseDecision && request.appeal" class="col s12 m4 red-text">
                                        Declined
                                    </p>
                                </div>
    
                            </div>
                            <div *ngIf="request.status === 'Completed'">
                                <span *ngIf="!request.feedback">
    
                                    <button class="col s12 btn waves-effect modal-trigger pulse" (click)="giveFeedback(i)"
                                        data-target="reviewModel">
                                        <i class="material-icons right">star_rate</i>Give Feedback
                                    </button>
    
                                </span>
                                <span *ngIf="request.feedback">
                                    <span *ngFor="let rate of createRange()">
                                        <i style="display: inline-block" *ngIf="rate <= request.feedback.rating"
                                            class="material-icons green-text">
                                            star
                                        </i>
                                        <i style="display: inline-block" *ngIf="rate > request.feedback.rating"
                                            class="material-icons">
                                            star_outline
                                        </i>
                                    </span>
                                </span>
                            </div>
    
                        </div>
                    </div>
    
                    <div class="row col s12" *ngIf="showMore[i]">
    
                        <div class="col s12 grey lighten-4" style="padding: 25px;">
                            <h6>REQUEST STATUS: <Strong>{{request.status | uppercase}}</Strong></h6>
    
                            <div *ngIf="request.status === 'Pending'">
                                <p><strong>No Decision has been made regarding your request.</strong></p>
                                <p class="pulse">It may take 2 to 3 days.</p>
                            </div>
    
                            <div *ngIf="request.status === 'Declined'">
                                <div class="row">
                                    <p>Reasone Your Request has been declined:
                                        <strong>{{request.decision.reason}} </strong>
                                    </p>
                                </div>
    
                                <div class="row">
                                    <button *ngIf="!request.appeal" class="col s12 btn waves-effect blue lighten-3"
                                        (click)="appealDecision(i)">
                                        <i class="material-icons right">gavel</i> Appeal Decision
                                    </button>
                                    <div *ngIf="request.appeal">
                                        <h6><strong>Appeal Submitted</strong></h6>
                                        <p><strong>Submitted On: </strong>{{request.appeal.date.toDate().toDateString() |
                                            date: 'dd/MM/yyyy'}}</p>
                                        <p><Strong>Statement: </Strong>{{request.appeal.statement}}</p>
                                    </div>
                                </div>
                            </div>
    
                            <div *ngIf="request.status === 'Approved'">
                                <p><strong>Your Request has been Approved</strong></p>
                                <p>{{request.decision.message}}</p>
                                <br />
                                <p><strong>Case Officer: </strong> {{request.decision.employee}}</p>
                                <p><strong>Start Date: </strong> {{request.decision.startDate.toDate().toDateString()}}</p>
                                <p><strong>End Date: </strong> {{request.decision.endDate.toDate().toDateString()}}</p>
                            </div>
    
                            <div *ngIf="request.status === 'Completed'">
                                <p>Completed On:
                                    <strong>{{request.completedOn.toDate().toDateString()}} </strong>
                                </p>
                                <p>Comment:
                                    <strong>{{request.comment}} </strong>
                                </p>
                            </div>
                        </div>
    
                        <div class="section"></div>
    
                        <div class="col s12 grey lighten-5" style="padding: 25px;">
                            <h6><Strong>REQUEST INFORMATION</Strong></h6>
                            <p><strong>Request Date: </strong> {{request.requestDate.toDate().toDateString()}}</p>
                            <p><strong>House Number: </strong> {{request.houseNumber}}</p>
                        </div>
    
                    </div>
                </div>
            </div>
    
            <div class="row">
                <button class="col s12 btn waves-effect green" (click)="requestRepair()" style="margin-bottom: 15px;">
                    <i class="material-icons right">home_repair_service</i> Request Repair
                </button>
    
                <button [ngClass]="'col s12 btn waves-effect ' + themeColor" (click)="back()">
                    <i class="material-icons left">arrow_back_ios</i> Back To Previous Page
                </button>
            </div>
        </div>
    
    </div>
    
    <div id="appealModal" class="modal">
        <div class="modal-content">
            <h4>Appeal Decision</h4>
            <p>To submit an appeal Please state why the decision mader regarding your request should be reconsidered.</p>
            <br />
    
            <form [formGroup]="appealForm" (ngSubmit)="appeal(appealForm.value)">
    
                <div class="input-field col s12">
                    <i class="material-icons prefix orange-text">message</i>
                    <textarea id="statement" rows="3" style="height: 100px;" formControlName="statement"
                        class="materialize-textarea" style="text-transform: capitalize;"></textarea>
                    <label for="statement">Statement</label>
                </div>
    
                <div class="row">
                    <button type="submit" class="col s12 btn waves-effect red" [disabled]="!appealForm.valid"
                        style="margin-bottom: 15px;">
                        <i class="material-icons right">send</i> Submit
                    </button>
    
                    <button type="button" class="col s12 btn-flat waves-effect blue-grey lighten-5" (click)="closeModal()"
                        style="margin-bottom: 15px;">
                        <i class="material-icons right">cancel</i> Close
                    </button>
                </div>
            </form>
        </div>
    </div>
    
    <div id="reviewModel" class="modal">
        <div class="modal-content">
            <h4>GIVE US YOUR FEEDBACK</h4>
            <p>Tell us what you think about our service.</p>
            <ul class="col offset-s2 s10">
                <li *ngFor="let req of completedRequests">{{req.title}}</li>
            </ul>
            <p>Please Fill out this question for a better customer statisfaction.</p>
            <br />
    
            <form [formGroup]="feedbackForm" (ngSubmit)="submitReview(feedbackForm.value)">
    
                <div class="input-field col s12">
                    <p>How Satisfied Are you with our service?</p>
                    <br />
                    <ngx-slider [options]="getScaleOption()" formControlName="rating"></ngx-slider>
                </div>
    
                <div class="section"></div>
                <p>If there is any comments you would like to add?</p>
                <br />
                <div class="input-field col s12">
                    <i class="material-icons prefix orange-text">message</i>
                    <textarea id="review" rows="3" style="height: 100px;" formControlName="review"
                        class="materialize-textarea" style="text-transform: capitalize;"></textarea>
                    <label for="review">Comment</label>
                </div>
    
                <div class="row">
                    <button type="submit" class="col s12 btn waves-effect red" [disabled]="!feedbackForm.valid"
                        style="margin-bottom: 15px;">
                        <i class="material-icons right">send</i> Submit
                    </button>
    
                    <button type="button" class="col s12 btn-flat waves-effect blue-grey lighten-5" (click)="closeModal()"
                        style="margin-bottom: 15px;">
                        <i class="material-icons right">cancel</i> Close
                    </button>
                </div>
            </form>
        </div>
    </div>
    
    <div class="row col s12" *ngIf="step2">
        <div class="col l1"></div>
    
        <form class="col s12 m12 l10" [formGroup]="repairForm" (ngSubmit)="submitRequest(repairForm.value)">
            <div class="row col s12 card white">
    
                <div class="input-field col s12">
                    <i class="material-icons prefix green-text">title</i>
                    <input id="title" type="text" formControlName='title' class="validate" autocomplete="off"
                        style="text-transform: capitalize;" />
                    <label for="title">Title<span class="red-text">*</span></label>
                    <div *ngIf="(title.dirty || title.touched) && title.invalid" style="position: absolute;">
                        <small *ngIf="title.errors.required" class="red-text"> Title is required.
                        </small>
                    </div>
                </div>
    
                <div class="input-field col s12">
                    <i class="material-icons prefix blue-text">description</i>
                    <textarea id="description" rows="3" style="height: 100px;" formControlName="description"
                        class="materialize-textarea" style="text-transform: capitalize;"></textarea>
                    <label for="description">Description<span class="red-text">*</span></label>
                    <div *ngIf="(description.dirty || description.touched) && description.invalid">
                        <small *ngIf="description.errors.required" class="red-text"> Description is required.
                        </small>
                    </div>
                </div>
            </div>
    
            <div class="row">
                <button type="submit" class="col s12 btn waves-effect green" style="margin-bottom: 15px;"
                    [disabled]="!repairForm.valid">
                    <i class="material-icons right">home_repair_service</i> Submit Request
                </button>
            </div>
        </form>
    
        <div class="row">
            <button type="button" [ngClass]="'col s12 btn waves-effect ' + themeColor" (click)="backToPreviousPage()">
                <i class="material-icons left">arrow_back_ios</i>Back to Previous Page
            </button>
        </div>
    
        <div class="col l1"></div>
    </div>
    </div>