import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { Location } from '@angular/common';
import { ActivityService } from 'src/app/services/api/activity.service';

@Component({
  selector: 'app-activity',
  templateUrl: './activity.component.html',
  styleUrls: ['./activity.component.css']
})

export class ActivityComponent implements OnInit {

  constructor(private location: Location,
    private authService: AuthService,
    private activityService: ActivityService) {
    localStorage.setItem('from', 'myresume');
  }

  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  //sqldb = environment.firstNation.SQLDB;
  clientID = 0;
  model: any[];
  hasRows = false;
  MoreActivity = true

  ngOnInit() {
    if (localStorage.getItem('applicantID')) {
      this.clientID = Number(localStorage.getItem('applicantID'));
    } else {
      this.clientID = this.authService.getCurrentApplicantID();
    }

    this.activityService.getActivityByMemberId(this.clientID).subscribe(x => {
      if (x) {
        this.model = x;
        if (this.model.length > 0) { this.hasRows = true; }
        if (this.model.length > 4) { this.MoreActivity = false; }  // at most 5 activities allowed
      }
    });
  }

  backClicked() {
    this.location.back();
  }

}