import { Component, OnInit } from '@angular/core';
import { toast } from 'materialize-css';
import { Location } from '@angular/common';
import { UntypedFormBuilder, UntypedFormGroup, Validators, NgModel } from '@angular/forms';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { EducationService } from 'src/app/services/api/education.service';
import { EducationService as EducationFirebaseService } from 'src/app/services/firebase/education.service';
import { environment } from 'src/environments/environment';
import { EducationModel } from 'src/app/models/educationModel';
import { ActivatedRoute } from '@angular/router';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import { Router } from '@angular/router';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-edit-education',
  templateUrl: './edit-education.component.html',
  styleUrls: ['./edit-education.component.css']
})
export class EditEducationComponent implements OnInit {
  
  myDpOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'dd/mmm/yyyy',
    markCurrentDay: true,
  };

  applicantID = 0;
  editEducationForm: UntypedFormGroup;
  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  model: EducationModel;
  id = '';
  collectionEducationId = '';
  sqldb = environment.firstNation.SQLDB;
    
  EduLevelList = ["Up to Grade 7-8 (Sec. I = Grade 8)", "Grade 9-10 (Sec. II-III)", "Grade 11-12 (Sec. IV-V)",
  "Secondary School Diploma or GED",  "Some post-secondary training", "Apprenticeship or trades certificate or diploma", 
  "College, CEGEP,Other", "University certificate or diploma", "University - Bachelor Degree", "University – Master’s degree",
   "University – Doctorate", "Course or certificate", "Other"];

  constructor(private authService: AuthService, private fb: UntypedFormBuilder, private location: Location, private router: Router,
    private educationService: EducationService, private route: ActivatedRoute, private educationFirebaseService: EducationFirebaseService) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
      if(!this.sqldb){
        this.collectionEducationId = this.id.toString();
      }
    });

    if (this.id) {
      let newId = 0;
      newId = + this.id;

      this.editEducationForm = this.fb.group({
        Institution: ['', [Validators.required]],
        completion_date: ['', [Validators.required]],
        details: ['', [Validators.required]],
        description: ['', [Validators.required]],
      });

      if(this.sqldb){
        this.educationService.getEducationById(newId).subscribe(x => {
          if (x) {
            this.model = x[0];
            this.editEducationForm.patchValue({ Institution: this.model.Institution });
            this.editEducationForm.patchValue({ completion_date: this.model.completion_date });
            this.editEducationForm.patchValue({ details: this.model.details });
            this.editEducationForm.patchValue({ description: this.model.description });
            const completion_date1 = new Date(this.model.completion_date);
            const model1: IMyDateModel = {isRange: false, singleDate: {jsDate: completion_date1}, dateRange: null};
            this.editEducationForm.patchValue({ completion_date: model1 });
          }

          setTimeout(() => {
            $('.modal').modal();
            $('select').formSelect();
            M.updateTextFields();    
          }, 25);
        });
      }else{
        this.educationFirebaseService.getEducationById(this.collectionEducationId).valueChanges().subscribe(x => {
          if (x) {
            this.model = x;
            this.editEducationForm.setValue({
              Institution: this.model.Institution,
              completion_date: this.model.completion_date,
              details: this.model.details,
              description: this.model.description
            });
            this.model.completion_date = new Date(this.model.completion_date['seconds']*1000);

            const completion_date2 = new Date(this.model.completion_date.getFullYear(), this.model.completion_date.getMonth(), this.model.completion_date.getDate());
            let model2: IMyDateModel = {isRange: false, singleDate: {jsDate: completion_date2}, dateRange: null};
            this.editEducationForm.patchValue({ completion_date: model2 });
            
             setTimeout(() => {
              $('.modal').modal();
              $('select').formSelect();
              M.updateTextFields();
             }, 25);
          }
        });
      }
    }
  }

  get Institution() { return this.editEducationForm.get('Institution'); }
  get completion_date() { return this.editEducationForm.get('completion_date'); }
  get details() { return this.editEducationForm.get('details'); }
  get description() { return this.editEducationForm.get('description'); }

  onDateChange(event: IMyDateModel): void {
    localStorage.setItem('selectedDate', event.singleDate.jsDate.toString());
    if (this.editEducationForm) {this.editEducationForm.patchValue({completion_date : event.singleDate.formatted});}
  }

  submitForm(value: any) {
    this.model.Institution = value.Institution;
    this.model.details = value.details;
    this.model.description = value.description;
    this.model.completion_date = new Date(localStorage.getItem('selectedDate'));
    if(this.sqldb){
      this.educationService.updateEducation(this.model).subscribe(x => {
        if (x) {
          toast({ html: 'Education Successfully Updated!', classes: 'green' });
          this.backClicked();
        }
      });
    }else{
      this.educationFirebaseService.updateEducation(this.model).then(x => {
        toast({ html: 'Education Successfully Updated!', classes: 'green' });
        this.backClicked();
      });
    }
  }

  dateChange () {
    if (this.editEducationForm) {this.editEducationForm.patchValue({completion_date : $('#completion_date').val()});}
  }

  DeleteEducation() {
    /*
    this.educationFirebaseService.DeleteEducation(this.model.id).then(x => {
      toast({ html: 'Education Removed Successfully!', classes: 'green' });
      this.backClicked();
    });
    */
    $('.modal').modal('close');
    if(this.sqldb) {
      this.educationService.deleteEducation(this.model).subscribe(resp => {
        toast({ html: 'Education Removed Successfully!', classes: 'green' });
        this.backClicked();
    });
    }else{
      this.educationFirebaseService.DeleteEducation(this.model.id).then(x => {
        toast({ html: 'Education Removed Successfully!', classes: 'green' });
        this.backClicked();
      });
    }
  }

  backClicked() {
    if (localStorage.getItem('educaton_call_from')=='case_plan') {
      this.router.navigate(['/member/member-main-home/case-plan']);
    }else if (localStorage.getItem('education_call_from')=='education') { 
      this.router.navigate(['/member/member-main-home/education']);
    }else{  this.location.back(); }
  }

}
