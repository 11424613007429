
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
//import { ISETSApiKeyManagerService } from 'src/app/services/api/isets/ISETSApiKeyManager.Service';
import { ApiKeyManagerService } from 'src/app/services/api/apiKeyManager.Service';

import { BandModel } from 'src/app/models/isets/BandModel';

@Injectable({
  providedIn: 'root'
})


export class IsetsBandsService {

    constructor(private httpClient: HttpClient, private keyManager: ApiKeyManagerService) { }
  
    getBands(): Observable<BandModel[]> {
      const url = environment.firstNation.apiUrl + 'api/bands/Get/';
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
      return this.httpClient.get<any>(url, { headers });
    }
  

    getBandByID(id: number): Observable<BandModel> {
      const url = environment.firstNation.apiUrl + 'api/bands/getBandByID/' + id;
      const headers = new HttpHeaders({SOCIALAPIKEY: this.keyManager.getKey() });
      return this.httpClient.get<any>(url, { headers });
    }

    
  }
