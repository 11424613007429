import { Component, OnInit } from '@angular/core';
import { MemberModel } from 'src/app/models/memberModel';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { MemberService } from 'src/app/services/api/member.service';
import { Location } from '@angular/common';

declare var $: any;

@Component({
  selector: 'app-documents',
  templateUrl: './documents.component.html',
  styleUrls: ['./documents.component.css']
})

export class DocumentsComponent implements OnInit {

  // tslint:disable-next-line: max-line-length
  constructor(private authService: AuthService, private memberService: MemberService, private location: Location) {
    localStorage.setItem('from', 'PersonalInfo');
  }

  member: MemberModel;

  ngOnInit() {
    this.memberService.getMember(this.authService.getCurrentApplicantID()).subscribe(x => {
      this.member = x[0];
    });
  }

  backClicked() {
    this.location.back();
  }
}
