import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GovernanceService } from 'src/app/services/firebase/governance.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-board-members',
  templateUrl: './board-members.component.html',
  styleUrls: ['./board-members.component.css']
})
export class BoardMembersComponent implements OnInit {

  id: any;
  model: any;
  themeColor = environment.appTheme.adminThemeColor;

  constructor(private govService: GovernanceService, private route: ActivatedRoute, private router: Router, private location: Location) {}

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });

    if (this.id) {
      this.govService.getBoardById(this.id).valueChanges().subscribe(b => {
        if (b) {
          this.model = b;
        }
      });
    }
  }

  addChair() {
    localStorage.setItem('boardID', this.id);
    localStorage.setItem('boardMember', 'Chair');
    this.router.navigate(['/admin/dashboard-sub-menu/add-board-chair/']);
  }

  addMember() {
    localStorage.setItem('boardID', this.id);
    localStorage.setItem('boardMember', 'Member');
    this.router.navigate(['/admin/dashboard-sub-menu/add-board-chair/']);
  }

  selectChair(ids: any) {
    localStorage.setItem('boardID', this.id);
    localStorage.setItem('boardMember', 'Chair');
    this.router.navigate(['/admin/dashboard-sub-menu/add-board-chair/', ids]);
  }

  selectMember(ids: any) {
    localStorage.setItem('boardID', this.id);
    localStorage.setItem('boardMember', 'Member');
    this.router.navigate(['/admin/dashboard-sub-menu/add-board-chair/', ids]);
  }

  navigate(item) {
    const routeMappings = {
      'AboutUs': 'admin/dashboard-sub-menu/about-us',
      'NationInfo': 'admin/dashboard-sub-menu/nation-settings',
      'Departments': 'admin/dashboard-sub-menu/department',
      'Governance': 'admin/dashboard-sub-menu/governance',
      'AreaOfInterest': 'admin/dashboard-sub-menu/areas-of-interest',
    };

    const route = routeMappings[item];
    if (route) {
      this.router.navigate([route]);
    }
  }
 
  backClicked() {
    this.router.navigate(['/admin/dashboard-sub-menu/edit-board/', this.id]);
  }
}
