import { Component, OnInit } from '@angular/core';
import { MemberAccountSettingsModel } from 'src/app/models/MemberAccountSettingsModel';
import { MemberAccountSettingsService } from 'src/app/services/firebase/memberAccountSettings.service';
import { Location } from '@angular/common';
import { toast } from 'materialize-css';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { environment } from 'src/environments/environment';
import { AreaOfInterestService } from 'src/app/services/firebase/area-of-interest.service';

declare var $: any;

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})

export class SettingsComponent implements OnInit {

  nationName = environment.firstNation.displayName;
  nationOfUse = environment.firstNation.name;
  isOteenow = false;
  themeColor = environment.appTheme.themeColor
  id: ''; memberId = '';
  saveBtnDisable = true;
  permissions: any;
  settingsModel: any;
  allowChat; allowEmail; allowNomination = false; allowNotifications; allowSearch; contactMe; shareLocation;
  allowContactByEmployer = false;
  allowViewResume = false; allowContactByChiefAndCouncil = false;
  allowCandidateContact = true;
  selectedFont: string;
  fontPicker = [
    { name: "ldjf", value: "times new roman" },
    { name: 'cursive', value: 'cursive' }
  ];

  areasList: any[];
  checkBoxList: any[];
  userID
  myInterestModel: any;

  constructor(private authService: AuthService, private areaService: AreaOfInterestService,
    private memberAccountSettingsService: MemberAccountSettingsService,
    private location: Location) {
    if (this.nationOfUse == 'Oteenow') this.isOteenow = true;
  }

  ngOnInit() {
    this.id = this.authService.getGlobalUser().uid;

    if (this.id) {
      //=============================== begin area of interest
      this.areaService.getAreasList().valueChanges().subscribe(areas => {
        if (areas) {
          if (areas.length > 0) {
            this.areasList = areas;
            this.addCheckboxes();

            this.areaService.getMyAreasList(this.id).valueChanges().subscribe(aList => {
              if (aList) {
                if (aList.length > 0) {
                  this.myInterestModel = aList[0];
                  this.setCheckboxes();
                }
              }
            });
          }
        }
      });

      //=============================== end area of interest

      this.memberAccountSettingsService.getAccoutSetting(this.id).valueChanges().subscribe(x => {
        if (x.length > 0) {
          this.settingsModel = x[0];
          this.id = this.settingsModel.id;
          this.memberId = this.settingsModel.memberId;
          this.settingsModel.permissions.forEach(element => {
            if (Object.keys(element)[0] == 'shareLocation') {
              this.shareLocation = element.shareLocation;
            } else if (Object.keys(element)[0] == 'allowEmail') {
              this.allowEmail = element.allowEmail;
            } else if (Object.keys(element)[0] == 'allowNotifications') {
              this.allowNotifications = element.allowNotifications;
            } else if (Object.keys(element)[0] == 'allowChat') {
              this.allowChat = element.allowChat;
            } else if (Object.keys(element)[0] == 'contactMe') {
              this.contactMe = element.contactMe;

            } else if (Object.keys(element)[0] == 'allowSearch') {
              this.allowSearch = element.allowSearch;

            } else if (Object.keys(element)[0] == 'allowNomination') {
              this.allowNomination = element.allowNomination;

            } else if (Object.keys(element)[0] == 'allowContactByEmployer') {
              this.allowContactByEmployer = element.allowContactByEmployer;

            } else if (Object.keys(element)[0] == 'allowViewResume') {
              this.allowViewResume = element.allowViewResume;

            } else if (Object.keys(element)[0] == 'allowContactByChiefAndCouncil') {
              this.allowContactByChiefAndCouncil = element.allowContactByChiefAndCouncil;

            } else if (Object.keys(element)[0] == 'allowCandidateContact') {
              this.allowCandidateContact = element.allowCandidateContact;
            }
          });

          //check if under 18 and disable nomination checkbox
          if (new Date(this.authService.getGlobalUser().DOB) >= new Date(new Date().getFullYear() - 18, new Date().getMonth(), new Date().getDate())) {
            this.allowNomination = false;
            $('#allowNomination').prop('disabled', true)
          }
        } else {
          let newMemberSettings = new MemberAccountSettingsModel();
          newMemberSettings.memberId = this.id;
          newMemberSettings.permissions = [{ allowChat: true }, { allowEmail: true }, { allowNomination: true }, { allowNotifications: true }, { allowSearch: true }, { contactMe: true }, { shareLocation: false }, { allowContactByEmployer: false }, { allowViewResume: false }];
          this.memberAccountSettingsService.createAccountSetting(newMemberSettings);
        }
      });
    }
  }

  //=============================== begin area of interest
  private addCheckboxes() {
    this.checkBoxList = [];
    this.areasList.forEach(a => {
      const option = { id: a.id, name: a.name, isSelected: false };
      this.checkBoxList.push(option);
    });
  }

  private setCheckboxes() {
    this.myInterestModel.myInterests.forEach(element => {
      this.checkBoxList.forEach(a => {
        if (a.id === element.id) {
          a.isSelected = true;
        }
      });
    });
  }

  itemCheckedChange(id: any, value: any) {
    if (this.myInterestModel) {

      if (value) {
        this.editMyInterests();
        this.areaService.incrementMembersCount(id);
      } else {
        this.editMyInterests();
        this.areaService.decrementMembersCount(id);
      }
    }
  }

  saveMyInterests() {
    const myInterests = {
      id: '',
      uid: this.userID,
      myInterests: this.checkBoxList.filter(i => i.isSelected === true),
    };

    myInterests.myInterests.forEach(element => {
      this.areaService.incrementMembersCount(element.id);
    });

    this.areaService.saveMyAreas(myInterests).then(s => {
      toast({ html: 'Areas Of Interest Successfully Saved!', classes: 'green' });
    });
  }

  editMyInterests() {
    this.myInterestModel.myInterests = this.checkBoxList.filter(i => i.isSelected === true);
    this.areaService.updateMyAreas(this.myInterestModel).then(s => {
    });
  }

  //=============================== end area of interest

  onChange(event: any) {
    this.saveBtnDisable = false;
  }

  saveChanges() {
    let model = new MemberAccountSettingsModel();
    model.id = this.id;
    model.memberId = this.memberId;
    let permissions = [{ allowChat: this.allowChat },
    { allowEmail: this.allowEmail },
    { allowNomination: this.allowNomination },
    { allowNotifications: this.allowNotifications },
    { allowSearch: this.allowSearch },
    { contactMe: this.contactMe },
    { shareLocation: this.shareLocation },
    { allowContactByEmployer: this.allowContactByEmployer },
    { allowViewResume: this.allowViewResume },
    { allowContactByChiefAndCouncil: this.allowContactByChiefAndCouncil },
    { allowCandidateContact: this.allowCandidateContact }
    ];
    model.permissions = permissions;
    this.memberAccountSettingsService.updateAccountSetting(model).then(x => {
      toast({ html: 'Setting Preference Successfully Updated!', classes: 'green' });
      this.backClicked();
    });
  }

  backClicked() {
    this.location.back();
  }

  ChangeFont(font) {
    this.selectedFont = font;
  }
}