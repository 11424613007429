<div class="row container-pi">
  <h4 class="title-section1 title-member icon-texts"> <span class="material-symbols-outlined back-icon p-r-1"
      (click)=" backClicked()">
      arrow_circle_left
    </span>Privacy Setting</h4>
  <div class="row card-panel b-n-1">
    <div>
      <div class="title-section4 bold-800">Allow needed?</div>
      <p class="bold-100">Please allow things that are needed</p>

      <div class="col s12 m12 l6">
        <label>
          <input type="checkbox" [(ngModel)]="allowEmail" (ngModelChange)="onChange($event)" />
          <span>Allow Emails</span>
        </label>
      </div>

      <div class="col s12 m12 l6">
        <label>
          <input type="checkbox" [(ngModel)]="allowNotifications" (ngModelChange)="onChange($event)" />
          <span>Allow Notifications</span>
        </label>
      </div>

      <div class="col s12 m12 l6">
        <label>
          <input type="checkbox" [(ngModel)]="allowChat" (ngModelChange)="onChange($event)" />
          <span>Allow Chat</span>
        </label>
      </div>

      <div class="col s12 m12 l6" *ngIf="!isOteenow">
        <label>
          <input type="checkbox" [(ngModel)]="allowContactByChiefAndCouncil" (ngModelChange)="onChange($event)" />
          <span>Allow Chief and Council to Contact me</span>
        </label>
      </div>

      <div class="col s12 m12 l6">
        <label>
          <input type="checkbox" [(ngModel)]="contactMe" (ngModelChange)="onChange($event)" />
          <span>Allow members to contact me</span>
        </label>
      </div>

      <div class="col s12 m12 l6">
        <label>
          <input type="checkbox" [(ngModel)]="allowSearch" (ngModelChange)="onChange($event)" />
          <span>Allow other members to search for my profile</span>
        </label>
      </div>

      <div class="col s12 m12 l6" *ngIf="!isOteenow">
        <label>
          <input type="checkbox" [(ngModel)]="allowNomination" id="allowNomination"
            (ngModelChange)="onChange($event)" />
          <span>Allow members to Nominate me for election</span>
        </label>
      </div>

      <div class="col s12 m12 l6">
        <label>
          <input type="checkbox" [(ngModel)]="allowContactByEmployer" id="allowContactByEmployer"
            (ngModelChange)="onChange($event)" />
          <span>Allow Employers to Contact Me</span>
        </label>
      </div>

      <div class="col s12 m12 l6">
        <label>
          <input type="checkbox" [(ngModel)]="allowViewResume" id="allowViewResume"
            (ngModelChange)="onChange($event)" />
          <span>Allow Employers to See My Resume</span>
        </label>
      </div>

      <div class="col s12 m12 l6" *ngIf="!isOteenow">
        <label>
          <input type="checkbox" [(ngModel)]="allowCandidateContact" id="allowCandidateContact"
            (ngModelChange)="onChange($event)" />
          <span> Allow Candidate to add me to chat </span>
        </label>
      </div>
    </div>

    <div class="divider col s12 p-m-3 b-m-3"></div>

    <!-- <div>
      <div class="col sl6 s12">
        <div class="title-section4 bold-800">Font Setting</div>
        <p class="bold-100">Please select a Font </p>
      </div>
      <div class="col l6 s12">
        <div class="card white">
          <a class="dropdown-trigger col s12 btn grey lighten-3 black-text" data-target='options1'
            style="margin-bottom: 15px;">
            <strong><i class="material-symbols-outlined icons-text">
                palette
              </i> Select Title Color</strong> </a>

          <ul id='sth' class='dropdown-content'>
            <li>Please select a Font</li>
            <li *ngFor="let f of fontPicker" (click)="ChangeFont(f.value)"><a><i
                  class="material-icons {{f.value}}">fiber_manual_record</i>{{f.name}}</a></li>
          </ul>
        </div>
      </div>
    </div> -->

    <div class="section"></div>
    <div class="row ">
      <button class='at-btn col s12 l4 right  btn save-button success' style="margin-top: 10vh;" type="button"
        [disabled]="saveBtnDisable" (click)="saveChanges()">
        Save Preference changes
      </button>
    </div>
  </div>
</div>