<div class="col s12">
  <div class="body-h">
    <div class="header-setting b-m-2">
      <div class="col l9 m9 s12">
        <h4 class="">
          <span *ngIf="sectionName">{{sectionName}}</span>
          <span *ngIf="!sectionName">Area of Interest</span>
        </h4>
        <p class="bold-300 sub-title-section3">This is where you can add various subjects, topics, or domains that captures your focus, passion, and expertise.</p>
      </div>
      <div class="col l3  m4 s12 p-m-2 ">
        <a (click)="addArea();" class="add-button btn white center-align p-l-1" [ngStyle]="{'color': themeColor}"><i
            class="material-icons-outlined">add</i> 
            <span *ngIf="sectionName">{{sectionName}}</span>
            <span *ngIf="!sectionName">Area of Interest</span> 
          </a>
      </div>
    </div>

    <main class="StickyContent white ">
      <div *ngIf="areasListModel && showMsg" class="row col l10 s12 m9  ">
        <div class=" row card b-n-1" *ngFor="let area of areasListModel">
          <div class="col l5 s5 m5">
            <div class="p-l-3 bold-600"><a class="black-text" (click)="selectArea(area)">
                <span>{{area.name}} </span>
              </a></div>
          </div>
          <div class="col l5 s4 m4">
            <div class="p-l-3"> <strong class="green-text text-darken-1"> {{area.membersCount }}</strong>
            </div>
          </div>
          <div class="col l2 s3 m3">
            <div class=""><span class="material-icons-outlined " (click)="selectArea(area)">
                edit
              </span>
            </div>
          </div>
        </div>
      </div>

      <div class="row card-panel black-text center p-t-10"
        *ngIf="(!areasListModel || areasListModel.length === 0) && showMsg">
        <img src="../../assets/img/empty-folder.png" height="150" width="150">
        <h6 class="bold-200">Sorry , no data found !!</h6>
      </div>

      <div class="section"></div>

      <div class="row">
        <button type="submit" class="col l3 push-l8 s11 btn save-button" [ngClass]="'btn ' + buttonType"
          style="margin: 15px;"> save updates</button><br />
      </div>

      <div id="editModal" class="modal b-n-1">
        <form [formGroup]="areaOfInterestForm" (ngSubmit)="manageAreasOfInterest(areaOfInterestForm.value)">
          <div class="modal-content">
            <h6 class="sub-title-section bold-600 black-text col s11">Add areas of interest here </h6>
            <i class="material-icons modal-close col s1">close</i>

            <div class="p-t-5">
              <div class="input-field col s11">
                <div class="label-input">Area Of Interest Name<span class="red-text">*</span></div>
                <input id="name" type="text" formControlName='name' required class="validate" autocomplete="off"
                  placeholder="Area Of Interest Name" />
                <div *ngIf="(name.dirty || name.touched) && name.invalid">
                  <small *ngIf="name.errors.required" class="red-text"> The Area Of Interest Name is required.
                  </small>
                </div>
              </div>
            </div>
          </div>

          <div class="modal-footer col s12 center  " style="margin-top: 20px;">
            <button type="submit" [disabled]="!areaOfInterestForm.valid" style="margin-right: 10px;"
              class="btn save-button" [ngClass]="'btn ' + buttonType" [ngStyle]="{'background-color': themeColor}">
              <span *ngIf="selectedModel">Update</span>
              <span *ngIf="!selectedModel"> Save </span>
            </button>
            <button *ngIf="selectedModel" type="button" class="btn delete-button" style="margin-right: 10px;"
              [ngClass]="'btn ' + buttonType">
              Delete
            </button>
          </div>
        </form>
      </div>
    </main>
  </div>
</div>