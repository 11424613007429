import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { DepartmentService } from 'src/app/services/firebase/department.service';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';

declare var $: any;

@Component({
  selector: 'app-manage-department',
  templateUrl: './manage-department.component.html',
  styleUrls: ['./manage-department.component.css']
})

export class ManageDepartmentComponent implements OnInit {

  departmentID: any;
  department_name = '';
  sectionName: string;

  constructor(
    private router: Router,
    private departmentService: DepartmentService,
    private appSettingsService: AppSettingsService) { }

  ngOnInit() {
    this.appSettingsService.getMenuName('MyNation').valueChanges().subscribe(menus => {
      if (menus && menus.length > 0) {
        let submenus = menus[0].submenus;
        let menu = submenus.filter(m => m.name == 'Departments');
        if (menu && menu.length > 0) this.sectionName = menu[0].displayName;
      }
    });

    this.departmentID = window.history.state.department_id;
    this.departmentService.getDepartmentByID(this.departmentID).valueChanges().subscribe(x => {
      if (x) {
        this.department_name = x.name;
        this.department_name = this.department_name.replace(/<[^>]*>/g, '');
      }
    });

    setTimeout(() => {
      $('.tabs').tabs();
    }, 25);

    $(document).ready(function () {
      $('.pushpin').pushpin();
    });
  }

  editDepartmentData(event: Event) {
    event.preventDefault();
    this.router.navigate(['/admin/dashboard-sub-menu/edit-department'], { state: { department_id: this.departmentID } });
  }

  manageSubDepartmentData(event: Event) {
    event.preventDefault();
    this.router.navigate(['/admin/dashboard-sub-menu/manage-sub-department'], { state: { department_id: this.departmentID } });
  }

  manageDepartmentMembersData(event: Event) {
    event.preventDefault();
    this.router.navigate(['/admin/dashboard-sub-menu/manage-department-members'], { state: { department_id: this.departmentID } });
  }

  navigate(item) {
    const routeMappings = {
      'AboutUs': 'admin/dashboard-sub-menu/about-us',
      'NationInfo': 'admin/dashboard-sub-menu/nation-settings',
      'Departments': 'admin/dashboard-sub-menu/department',
      'Governance': 'admin/dashboard-sub-menu/governance',
      'AreaOfInterest': 'admin/dashboard-sub-menu/areas-of-interest',
    };

    const route = routeMappings[item];
    if (route) {
      this.router.navigate([route]);
    }
  }

  backClicked() {
    this.router.navigate(['/admin/dashboard-sub-menu/department']);
  }
}