
import { Component, OnInit } from '@angular/core';
import { PseEntitlementModel } from 'src/app/models/PseEntitlementModel';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { PseEntitlementService } from 'src/app/services/api/pse-entitlement.service';
import { Location } from '@angular/common';
import SignaturePad from 'signature_pad';
import { PseSignatureModel } from 'src/app/models/PseSignatureModel';
import { toast } from 'materialize-css';
import { environment } from 'src/environments/environment';
import { EmailService } from 'src/app/services/api/email.service';
import { MemberService } from 'src/app/services/api/member.service';

import { PseAppInfoService } from 'src/app/services/api/pse-app-info.service';
import { Router } from '@angular/router';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-pse-entitlement',
  templateUrl: './pse-entitlement.component.html',
  styleUrls: ['./pse-entitlement.component.css']
})

export class PseEntitlementComponent implements OnInit {

  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  step_0 = true;
  step0 = false;
  step1 = false;
  step2 = false;
  step3 = false;
  step4 = false;

  userID: any;
  //isSpouse = false;
  name = '';
  studentname = '';

  complianceList: any[];
  years: number[];
  data: any[];
  result: any = [];
  tmpResult: any = [];

  hasSignedEntitlement = false;
  hasEntitlement = false;
  entitlements: PseEntitlementModel[];

  model: PseSignatureModel;
  canvas: any;
  signaturePad: SignaturePad;

  applicantID = 0;
  currentYear = new Date().getFullYear();
  currentMonth = new Date().getMonth();
  fiscalYear: any;
  PSE_PWA_status = '';

  requiredParentsSign = false;
  hasParentsSignedEntitlement = false;

  isRegularBD = false;

  constructor(private authService: AuthService,
    private PseEntitlementService: PseEntitlementService,
    private location: Location,
    private router: Router,
    private pseAppInfoService: PseAppInfoService,) {
    this.userID = authService.getGlobalUser().applicantID;
  }

  ngOnInit() {
    if (localStorage.getItem('applicantID')) {
      this.applicantID = Number(localStorage.getItem('applicantID'));
    } else {
      this.applicantID = this.authService.getCurrentApplicantID();
    }

    /*
    this.memberService.getPsePWAStatus(this.applicantID).subscribe(x => {
      if (x) {
        this.PSE_PWA_status = x;
      }
      if(this.PSE_PWA_status !='Authenticated') {
        toast({ html: 'You Are Not Student Now and No Your Benefits to Show !', classes: 'red' });
        this.location.back();
      };

      setTimeout(() => {
        $('.collapsible').collapsible();
        $('select').formSelect();
        M.updateTextFields();
      }, 15);
      */

    this.pseAppInfoService.getFundingBDCompliance(this.applicantID).subscribe(comp => {
      if (comp) {
        if (comp.length > 0) {
          const is_over_18 = comp[0].student_adult_OK
          if (is_over_18 === 1) { this.requiredParentsSign = false; } else { this.requiredParentsSign = true; }
          this.hasEntitlement = true;
          this.complianceList = comp;
          this.years = Array.from(new Set(this.complianceList.map(a => a.year))).sort((a, b) => b - a);
        } else { this.hasEntitlement = false; }
      } else { this.hasEntitlement = false; }
    });
  }

  newYearSelected(value: number, selected_year: any) {

    this.data = [];
    this.fiscalYear = selected_year;

    for (const entry of this.complianceList) {
      //if (!entry.date_signed_applicant ) {  
      if (Number(entry.year) === Number(value)) {
        this.data.push(entry);
      }
      //}
    }

    this.result = Array.from(new Set(this.data.map(a => a.funding_id))).map(funding_id => {
      const obj = {
        applicantID: this.data.find(x => x.funding_id === funding_id).applicantID,
        funding_id: this.data.find(x => x.funding_id === funding_id).funding_id,
        month: this.data.find(x => x.funding_id === funding_id).month,
        year: this.data.find(x => x.funding_id === funding_id).year,
        BD_Student_SIGOK: this.data.find(x => x.funding_id === funding_id).BD_Student_SIGOK,
        BD_Parents_SIGOK: this.data.find(x => x.funding_id === funding_id).BD_Parents_SIGOK,
        dateCreated: this.dateCreated(this.data.find(x => x.funding_id === funding_id).month, this.data.find(x => x.funding_id === funding_id).year),
        GivenName: this.data.find(x => x.funding_id === funding_id).GivenName,
        LastName: this.data.find(x => x.funding_id === funding_id).LastName,
      };
      setTimeout(() => {
        this.step_0 = false;
        this.step0 = true;
      }, 25);

      return obj;
    }).sort((a, b) => +new Date(b.dateCreated) - +new Date(a.dateCreated));
  }

  selectBD(BDNo: string) {

    this.PseEntitlementService.getEntitlementByBDNo(BDNo).subscribe(ent => {
      if (ent) {
        this.entitlements = ent;
        let selected_bd: string = this.entitlements[0].funding_id;
        if (selected_bd.startsWith('PSE')) { this.isRegularBD = true; } else { this.isRegularBD = false; }

        const compliance = this.result.find(cmp => cmp.funding_id === BDNo);
        if (compliance) {
          this.studentname = compliance.LastName + ' ' + compliance.GivenName;

          if (compliance.BD_Student_SIGOK === 'True') { this.hasSignedEntitlement = true; }
          if (compliance.BD_Student_SIGOK === 'false') { this.hasSignedEntitlement = false; }

          if (this.requiredParentsSign) {
            if (compliance.BD_Parents_SIGOK === 'True') { this.hasParentsSignedEntitlement = true; }
            if (compliance.BD_Parents_SIGOK === 'false') { this.hasParentsSignedEntitlement = false; }
          } else { this.hasParentsSignedEntitlement = true; }

        }
        this.step0 = false;
        this.step1 = true;
      }
    });
  }

  approveAndSigned(value: string) {
    if (value === 'student') {
      this.name = this.studentname;
    } else {
      this.name = "Parents";
    }

    this.step1 = false;
    this.step2 = true;

    setTimeout(() => {
      this.canvas = document.querySelector('canvas');
      const parentWidth = $(this.canvas).parent().outerWidth();
      this.canvas.setAttribute('width', parentWidth);
      this.canvas.setAttribute('height', '175px');
      this.signaturePad = new SignaturePad(this.canvas, {
        backgroundColor: 'rgb(255,255,255)'
      });
    }, 50);
  }

  submit() {
    if (this.signaturePad.isEmpty()) {
      toast({ html: 'Please Signe The Entitlement!', classes: 'red' });
      return;
    }

    if (this.name === this.studentname) {

      this.model = new PseSignatureModel();
      this.model.funding_id = this.entitlements[0].funding_id;
      this.model.studentSignature = this.signaturePad.toDataURL().replace('data:image/png;base64,', '');
      //this.model.applicantName = this.authService.getGlobalUser().GivenName + ' ' +  this.authService.getGlobalUser().LastName;

      this.PseEntitlementService.postStudentSignature(this.model).subscribe(x => {

        if (this.hasParentsSignedEntitlement === false) {
          toast({ html: 'Student Successfully Signed, Please Parents Sign it Next', classes: 'green' });
          this.hasSignedEntitlement = true;
          this.refresh_entitlement(this.model.funding_id);
          this.step2 = false;
          this.step1 = true;
        } else {
          toast({ html: 'Entitlement Successfully Signed!', classes: 'green' });
          this.backClicked();
        }
      });
    }

    if (this.name === "Parents") {
      this.model = new PseSignatureModel();
      this.model.funding_id = this.entitlements[0].funding_id;
      this.model.parentsSignature = this.signaturePad.toDataURL().replace('data:image/png;base64,', '');
      //this.model.applicantName = this.authService.getGlobalUser().GivenName + ' ' +  this.authService.getGlobalUser().LastName;
      this.PseEntitlementService.postParentsSignature(this.model).subscribe(x => {
        if (this.hasSignedEntitlement === false) {
          toast({ html: 'Parents Successfully Signed, Please Student Sign it Next', classes: 'green' });
          this.hasParentsSignedEntitlement = true;
          this.refresh_entitlement(this.model.funding_id);
          this.step2 = false;
          this.step1 = true;
        } else {
          // this.emailService.sendFundingApprovalNotification(this.entitlements[0].GivenName + ' ' + this.entitlements[0].LastName);
          toast({ html: 'Entitlement Successfully Signed!', classes: 'green' });
          this.backClicked();
        }
      });
    }
  }

  clearSignature() {
    this.signaturePad.clear();
  }

  back_0() {
    this.step_0 = true;
    this.step0 = false;
  }

  back0() {
    this.entitlements = undefined;
    this.step0 = true;
    this.step1 = false;

    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }

  back1() {
    this.step1 = true;
    this.step2 = false;
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }

  backClicked() {
    this.location.back();
  }

  dateCreated(month: string, year: number) {
    const months = ['January', 'February', 'March', 'April',
      , 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const monthIndex = months.indexOf(month);
    return new Date(year, monthIndex, 1);
  }

  refresh_entitlement(BDNo: string) {
    this.tmpResult = this.result;

    let student_signed = '';
    let parent_signed = '';
    this.tmpResult = Array.from(new Set(this.result.map(a => a.funding_id))).map(funding_id => {

      if (this.hasSignedEntitlement === true) { student_signed = 'True'; }
      if (this.hasSignedEntitlement === false) { student_signed = 'false'; }

      if (this.hasParentsSignedEntitlement === true) { parent_signed = 'True'; }
      if (this.hasParentsSignedEntitlement === false) { parent_signed = 'false'; }

      const bd: string = this.result.find(x => x.funding_id === funding_id).funding_id;
      if (bd === BDNo) {
        const obj = {
          applicantID: this.result.find(x => x.funding_id === funding_id).applicantID,
          funding_id: this.result.find(x => x.funding_id === funding_id).funding_id,
          month: this.result.find(x => x.funding_id === funding_id).month,
          year: this.result.find(x => x.funding_id === funding_id).year,
          BD_Student_SIGOK: student_signed,
          BD_Parents_SIGOK: parent_signed,
          dateCreated: this.dateCreated(this.result.find(x => x.funding_id === funding_id).month, this.result.find(x => x.funding_id === funding_id).year),
          GivenName: this.result.find(x => x.funding_id === funding_id).GivenName,
          LastName: this.result.find(x => x.funding_id === funding_id).LastName,
        };
        return obj;
      } else {
        const obj = {
          applicantID: this.result.find(x => x.funding_id === funding_id).applicantID,
          funding_id: this.result.find(x => x.funding_id === funding_id).funding_id,
          month: this.result.find(x => x.funding_id === funding_id).month,
          year: this.result.find(x => x.funding_id === funding_id).year,
          BD_Student_SIGOK: this.result.find(x => x.funding_id === funding_id).BD_Student_SIGOK,
          BD_Parents_SIGOK: this.result.find(x => x.funding_id === funding_id).BD_Parents_SIGOK,
          dateCreated: this.dateCreated(this.result.find(x => x.funding_id === funding_id).month, this.result.find(x => x.funding_id === funding_id).year),
          GivenName: this.result.find(x => x.funding_id === funding_id).GivenName,
          LastName: this.result.find(x => x.funding_id === funding_id).LastName,
        };
        return obj;
      }
    }).sort((a, b) => +new Date(b.dateCreated) - +new Date(a.dateCreated));

    this.result = this.tmpResult;

    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }

  apply_supplemental_funding() {

    let selected_bd: string = '';
    if (this.entitlements[0].funding_id) { selected_bd = this.entitlements[0].funding_id; }
    if (selected_bd == '') {
      toast({ html: 'No funding BD is selected !', classes: 'red' });
      return;
    }
   
    let selected_year: number = this.entitlements[0].year;
    let selected_month: string = this.entitlements[0].month;
    
    localStorage.setItem('selected_BD_Year', selected_year.toString());
    localStorage.setItem('selected_BD_Month', selected_month);

    this.router.navigate(['/member/profile/pse-supplemental-funding']);
  }

}