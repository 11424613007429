<div class="row">
  <div class="title-editor col l8 s12 m12 ">
    <h6 class="p-l-1 bold-700 icon-text"> <i class="material-symbols-outlined tiny bold-900 p-r-1"
        (click)="backClicked()"> arrow_back </i>Edit&nbsp;
      <span *ngIf="sectionName">{{sectionName}}</span>
      <span *ngIf="!sectionName">About Us</span>&nbsp;
    </h6>
  </div>

  <div *ngIf="myModel">
    <app-content-editor-new [contentType]="'AboutUs'" [model]="myModel" [actionType]="'Edit'"
      (contentUpdated)="updateEventHandler($event)" (contentDeleted)="deleteEventHandler($event)">
    </app-content-editor-new>
  </div>
</div>