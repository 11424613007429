import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { DataService } from 'src/app/services/data.service';
import { SectionSettingService } from 'src/app/services/firebase/section-setting.service';

declare var $: any;

@Component({
  selector: 'app-side-menus',
  templateUrl: './side-menus.component.html',
  styleUrls: ['./side-menus.component.css']
})

export class SideMenusComponent implements OnInit {

  nationOfUse = environment.firstNation.name;
  defaultThemeColor = '#000000';
  themeColor: any;
  buttonType: any;

  @Input() user: any;
  @Output() onMenuClicked: EventEmitter<String> = new EventEmitter<String>();

  permissions: any;
  isSuperAdmin: any;
  from: any;
  adminMenus: any[];
  defaultMenus: any[];
  menu: any;
  selectedMenu = {
    name: '',
    icon: '',
  }
  myNationMenu = false;
  manageMenu = false;
  newsAndEventsMenu = false;
  statisticsMenu = false;
  settingsMenu = false;
  housingMenu = false;
  pollsAndSurveyMenu = false;
  customSectionMenu = false;
  customMenus: any[];

  constructor(
    private router: Router,
    private settingsService: AppSettingsService,
    private dataService: DataService,
    private sectionService: SectionSettingService) {
    this.themeColor = this.settingsService.getAppThemeColor() ? this.settingsService.getAppThemeColor() : this.defaultThemeColor;
    this.buttonType = this.settingsService.getButtonType() ? this.settingsService.getButtonType() : 'rectangularButton';

    this.defaultMenus = this.dataService.getAdminMenus();
  }

  ngOnInit(): void {
    if (this.user) {
      if (this.user.isSuperAdmin) this.isSuperAdmin = true;
      if (this.user.permissions) this.permissions = this.user.permissions;
      else if (!this.isSuperAdmin) {
        $('.modal').modal();
        $('#permissionModal').modal('open');
      }
    }

    this.settingsService.getAdminMenus().valueChanges().subscribe(menus => {
      if (menus && menus.length > 0) {
        this.adminMenus = menus;
        this.adminMenus = this.adminMenus.sort((a, b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0));
      } else {
        this.adminMenus = this.defaultMenus.sort((a, b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0));
      }

      this.sectionService.getSections().valueChanges().subscribe(sec => {
        if (sec && sec.length > 0) {
          let sections = sec.filter(s => s.addToAdmin == 'dashboard');
          if (sections && sections.length > 0) {
            this.customMenus = [];
            let indexCount = this.adminMenus.length;
            sections.forEach(sec => {
              this.customMenus.push(
                {
                  name: sec.name,
                  displayName: sec.displayName,
                  isEnabled: true,
                  icon: sec.icon,
                  index: indexCount++,
                  id: sec.id
                }
              )
            });
            //this.adminMenus = this.adminMenus.concat(customMenus);
            this.customMenus = this.customMenus.sort((a, b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0));
          }
        }
      });


      if (this.adminMenus) {
        if (localStorage.getItem('From')) {
          this.from = localStorage.getItem('From');
          if (this.from == 'MyNation') this.myNationMenu = true;
          if (this.from == 'Manage') this.manageMenu = true;
          // if (this.from == 'NewsAndEvents') this.newsAndEventsMenu = true;
          // if (this.from == 'PollsAndSurveys') this.pollsAndSurveyMenu = true;
          if (this.from == 'Settings') this.settingsMenu = true;
          if (this.from == 'Stats') this.statisticsMenu = true;
          if (this.from == 'Stats') this.statisticsMenu = true;
          if (this.from == 'Housing') this.housingMenu = true;
          if (this.from == 'CustomSection') this.customSectionMenu = true;

          let menu = this.adminMenus.filter(m => m.name == this.from);
          if (menu && menu.length > 0) {
            this.menu = menu[0];
            this.selectedMenu.name = this.menu.displayName;
            this.selectedMenu.icon = this.menu.icon;

            if (this.menu.submenus && this.menu.submenus.length < 0) this.menu.submenus = this.menu.submenus.sort((a, b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0));
          }
        }
      }
    });
  }

  getPermission(menu, submenu?) {
    if (this.isSuperAdmin) return true;
    if (this.permissions) {
      if (this.permissions.length > 0) {
        let p = this.permissions.find(p => p.menu == menu);
        if (p) {
          if (!submenu) return true;
          else if (p.submenus && p.submenus.length > 0) {
            let sp = p.submenus.includes(submenu);
            if (sp) return true;
          }
        }
      }
    }
    return false;
  }

  menuClicked(item: any) {
    if (this.getPermission(item.name)) {
      if (item.name === 'MyNation') {
        localStorage.setItem('From', 'MyNation');
        this.from = 'MyNation';
        this.router.navigate(['admin/dashboard-sub-menu/about-us']);
        this.onMenuClicked.emit('MyNation');
      }
      else if (item.name === 'Manage') {
        localStorage.setItem('From', 'Manage');
        this.from = 'Manage';
        this.router.navigate(['admin/dashboard-sub-menu/admins-list']);
        this.onMenuClicked.emit('Manage');
      }
      else if (item.name === 'Settings') {
        localStorage.setItem('From', 'Settings');
        this.from = 'Settings';
        this.router.navigate(['admin/dashboard-sub-menu/settings/app-settings']);
        this.onMenuClicked.emit('Settings');
      }
      else if (item.name === 'Stats') {
        localStorage.setItem('From', 'Stats');
        this.from = 'Stats';
        this.router.navigate(['admin/dashboard-sub-menu/stats/app-statistics']);
        this.onMenuClicked.emit('Stats');
      }
      else if (item.name === 'Housing') {
        localStorage.setItem('From', 'Housing');
        this.from = 'Housing';
        this.router.navigate(['admin/dashboard-sub-menu/housing']);
        this.onMenuClicked.emit('Stats');
      }
      else if (item.name === 'NewsAndEvents') {
        localStorage.setItem('From', 'NewsAndEvents');
        this.from = 'NewsAndEvents';
        this.router.navigate(['admin/news-events']);
        this.onMenuClicked.emit('NewsAndEvents');
      }
      else if (item.name === 'PollsAndSurveys') {
        localStorage.setItem('From', 'PollsAndSurveys');
        this.from = 'PollsAndSurveys';
        this.router.navigate(['admin/survey-list']);
        this.onMenuClicked.emit('PollsAndSurveys');
      }
      else if (item.name === 'Communication') {
        this.router.navigate(['admin/messages']);
      }
      else if (item.name === 'CustomSection') {
        localStorage.setItem('From', 'CustomSection');
        this.from = 'CustomSection';
        this.router.navigate(['admin/custom-section', item.id]);
        this.onMenuClicked.emit('CustomSection');
      }
    }
  }

  getSubmenuPermission(menu) {
    if (this.permissions && this.permissions.length > 0) {
      let p = this.permissions.find(p => p.menu == menu);
      if (p) {
        return p.submenus;
      }
    }
    return false;
  }

  backClicked() {
    this.router.navigate(['/admin/dashboard']);
  }

}