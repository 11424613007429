import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { toast } from 'materialize-css';
import { Location } from '@angular/common';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-add-dep-news',
  templateUrl: './add-dep-news.component.html',
  styleUrls: ['./add-dep-news.component.css']
})

export class AddDepNewsComponent implements OnInit {

  myModel = {
    id: '',
    departmentID: '',
    type: 'Post',
    title: '',
    body: '',
    imgLink: '',
    videoLink: '',
    visibility: 'Public',
    author: '',
    published: true,
    unPublished: false,
    datePublished: new Date(),
    index: Math.floor(Math.random() * (10000 - 5000 + 1)) + 5000,
  };

  valueEmittedFromChildComponent: any;

  constructor(private route: ActivatedRoute, private router: Router, private location: Location) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.myModel.departmentID = params.get('id');
    });

    setTimeout(() => {
      M.updateTextFields();
    }, 25);

  }

  parentEventHandlerFunction(valueEmitted) {
    this.valueEmittedFromChildComponent = valueEmitted;
    if (this.valueEmittedFromChildComponent === true) {
      toast({ html: 'Department News Successfully Posted!', classes: 'green' });
    }
  }

  backClicked() {
    this.location.back();
  }

}
