<div class="body-h">

  <div class="header-setting b-m-2">
    <div class="col l12 m12 s12">
      <h4 class="">Add New Skill </h4>
    </div>
  </div>

  <main class="StickyContent white row">
    <div id="step0" class="col s12 l11">
      <form (ngSubmit)="submitForm(addSkillForm.value)" [formGroup]="addSkillForm">

        <div class="row card-panel">
          <div class="input-field class col s12 l7">
            <div class="label-input">Skill Name<strong class="red-text">*</strong></div>
            <input formControlName="skill" id="skill" type="text" maxlength="140" autocomplete="off" required
              class="capitalize validate" placeholder="Skill Name">
            <div *ngIf="(skill.dirty || skill.touched) && skill.invalid">
              <small *ngIf="skill.errors.required" class="red-text">The Skill Name Is Required!
              </small>
            </div>
          </div>
        </div>

        <div class="section"></div>
        <div class="col s12">
          <button class='at-btn col s12 btn waves-effect success' style="margin-bottom: 15px;" type="submit"
            [disabled]="!addSkillForm.valid">
            <i class="material-icons right">add_circle</i> Save Skill
          </button>

          <button type="button" [ngClass]="'col s12 btn waves-effect ' + themeColor " (click)="backClicked()">
            <i class="material-icons left">keyboard_arrow_left</i> Back To Skill List
          </button>
        </div>
      </form>
    </div>
  </main>
</div>