<div class="row center-align p-m-2">
  <h6> <strong> Edit Family Member Information </strong></h6>
</div>

<div class="row col s12">
  <div class="col l1"></div>
  <div id="step0" class="col s12 l10">
    <form [formGroup]="editFamilyMember" (ngSubmit)="submitForm(editFamilyMember.value)">
      <div class="row card-panel">

        <div class="input-field class col s12">
          <i class="material-icons prefix blue-text">person</i>
          <input formControlName="firstName" id="firstName" type="text" maxlength="75" autocomplete="off" required
            class="validate">
          <label for="firstName"> First Name : <strong class="red-text">*</strong> </label>
          <span class="helper-text" data-error="First Name is required." data-success=""></span>
        </div>

        <div class="input-field class col s12">
          <i class="material-icons prefix blue-text">person</i>
          <input formControlName="lastName" id="lastName" type="text" maxlength="75" autocomplete="off" required
            class="validate">
          <label for="lastName"> Last Name : <strong class="red-text">*</strong> </label>
          <span class="helper-text" data-error="Last Name is required." data-success=""></span>
        </div>

        <div class="input-field class col s12">
          <i class="material-icons prefix blue-text">edit</i>
          <input id="dateOfBirth" formControlName="dateOfBirth" type="text" class="datepicker" (change)="dateChange()">
          <label for="dateOfBirth"> Date of Birth : <strong class="red-text">*</strong> </label>
          <span class="helper-text" data-error="Date of Birth is Required." data-success=""></span>
        </div>

        <div class="input-field class col s12">
          <i class="material-icons prefix blue-text">work</i>
          <input formControlName="SIN" id="SIN" type="text" maxlength="100" autocomplete="off" class="validate">
          <label for="SIN"> SIN : </label>
        </div>

        <div class="input-field class col s12">
          <i class="material-icons prefix blue-text">work</i>
          <input formControlName="ISN" id="ISN" type="text" maxlength="100" autocomplete="off" class="validate">
          <label for="ISN"> ISN : </label>
        </div>

        <div class="input-field col s12">
          <i class="material-icons prefix blue-text">group</i>
          <select formControlName="relation" (change)="relationOptionChange($event.target.value)">
            <option value="" disabled selected>Please Select</option>
            <option value="daughter">Daughter</option>
            <option value="nephew">Nephew</option>
            <option value="niece">Niece</option>
            <option value="Son">Son</option>
            <option value="spouse">Spouse</option>
            <option value="step Daughter">Step Daughter</option>
            <option value="step Son">Step Son</option>
            <option value="wife">Wife</option>
          </select>
        </div>

        <div class="input-field class col s12">
          <i class="material-icons prefix blue-text">email</i>
          <input formControlName="email" id="email" type="text" maxlength="100" autocomplete="off" class="validate">
          <label for="email"> Email : </label>
        </div>

        <div class="input-field class col s12">
          <i class="material-icons prefix blue-text">phone</i>
          <!-- <input formControlName="mobile" id="mobile" type="text" maxlength="100" autocomplete="off" class="validate"
            [textMask]="{mask: phoneNumberMask}"> -->
            <input formControlName="mobile" id="mobile" type="text" maxlength="100" autocomplete="off" class="validate"
           >
          <label for="mobile"> Mobile : </label>
        </div>
      </div>

      <div class="section"></div>

      <div class="col s12">
        <button class='at-btn col s12 btn waves-effect success modal-trigger' data-target="editModal"
          style="margin-bottom: 15px;" type="button" [disabled]="!editFamilyMember.valid">
          <i class="material-icons right">add_circle</i> Update Family Member Information
        </button>
        <button type="button" class="col s12 btn waves-effect red modal-trigger" data-target="deleteModal"
          style="margin-bottom: 15px;">Remove Family Member</button>

        <button type="button" [ngClass]="'col s12 btn waves-effect ' + themeColor " (click)="backClicked()">
          <i class="material-icons left">keyboard_arrow_left</i> Back To Family Composition
        </button>
      </div>

      <!-- Edit Modal -->
      <div id="editModal" class="modal">
        <div class="modal-content">
          <h5>Update Family Member Information</h5>
          <p>Are you sure you want to Update Family Member Information?</p>
        </div>
        <div class="modal-footer">
          <a [routerLink]="[]" class="modal-close orange  btn" style="margin-right: 15px;"> <i
              class="material-icons right">close</i>Cancel</a>
          <button type="submit" class="modal-close btn waves-effect " style="margin-right: 15px;">
            <i class="material-icons right">edit</i> Save Changes
          </button>
        </div>
      </div>

      <!-- Delete Modal -->
      <div id="deleteModal" class="modal">
        <div class="modal-content">
          <h5>Delete Demo Video</h5>
          <p>Are you sure you want to Remove this family member?</p>
        </div>
        <div class="modal-footer">
          <a [routerLink]="[]" class="modal-close orange  btn" style="margin-right: 15px;"> <i
              class="material-icons right">close</i>Cancel</a>

          <button type="button" (click)="deleteFamilyMember()" class="modal-close btn waves-effect red"
            style="margin-right: 15px;">
            <i class="material-icons right">delete</i> Remove
          </button>
        </div>
      </div>
    </form>
  </div>
  <div class="col l1 "></div>
</div>