
import { Injectable } from '@angular/core';

//import { environment } from 'src/environments/environment.prod';
import { environment } from 'src/environments/environment';

import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { InterventionModel } from 'src/app/models/InterventionModel';
import { ApiKeyManagerService } from './apiKeyManager.Service';

@Injectable({
  providedIn: 'root'
})
export class InterventionsService {

url = environment.firstNation.apiUrl + 'api/Interventions/';

constructor(private httpClient: HttpClient, private keyManager: ApiKeyManagerService) { }


getInterventions(client_id: number): Observable<InterventionModel[]> {
  
  const url = this.url + 'GetClientInterventions/' + client_id;
  const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
  return this.httpClient.get<any>(url, { headers });

}


getInterventionByID(int_id: number): Observable<InterventionModel> {
  
  const url = this.url + 'GetInterventionByID/' + int_id;
  const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
  return this.httpClient.get<any>(url, { headers });


}



}
