import { Component, OnInit } from '@angular/core';
import { toast } from 'materialize-css';
import { Location } from '@angular/common';
import { UntypedFormBuilder, UntypedFormGroup, Validators, NgModel } from '@angular/forms';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { FamilyCompositionService } from 'src/app/services/firebase/family-composition.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { FamilyCompositionModel } from 'src/app/models/FamilyCompositionModel';
import { IAngularMyDpOptions } from 'angular-mydatepicker';
import { AngularMyDatePickerModule } from 'angular-mydatepicker';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-edit-family-member',
  templateUrl: './edit-family-member.component.html',
  styleUrls: ['./edit-family-member.component.css']
})

export class EditFamilyMemberComponent implements OnInit {

  constructor(private authService: AuthService, private fb: UntypedFormBuilder, private location: Location,
    private familyCompositionService: FamilyCompositionService, private route: ActivatedRoute) { }

  applicantID = 0;
  editFamilyMember: UntypedFormGroup;
  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  model: FamilyCompositionModel;
  id = '';
  public phoneNumberMask = ['(', /[0-9]/, /[0-9]/, /[0-9]/, ')',
    ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];

  public myDatePickerOptions: IAngularMyDpOptions  = {
    dateFormat: 'dd/mmm/yyyy',
    minYear: new Date().getFullYear() - 50,
    maxYear: new Date().getFullYear() + 5
  };

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });

    $(document).ready(function () {
      $('select').formSelect();
    });

    if (localStorage.getItem('applicantID')) {
      this.applicantID = Number(localStorage.getItem('applicantID'));
    } else {
      this.applicantID = this.authService.getCurrentApplicantID();
    }

    this.model = new FamilyCompositionModel()
    if (this.id) {
      this.editFamilyMember = this.fb.group({
        firstName: ['', [Validators.required]],
        lastName: ['', [Validators.required]],
        dateOfBirth: [''],
        relation: [''],
        SIN: [''],
        ISN: [''],
        email: ['', Validators.email],
        mobile: ['',],
      });

      this.familyCompositionService.getFamilyMember(this.id).valueChanges().subscribe(x => {
        if (x) {
          this.model = x;
          this.editFamilyMember.setValue({
            firstName: this.model.firstName,
            lastName: this.model.lastName,
            dateOfBirth: this.model.DOB,
            SIN: this.model.SIN,
            ISN: this.model.ISN,
            relation: this.model.relation,
            email: this.model.email,
            mobile: this.model.mobile
          });
          this.model.DOB = new Date(this.model.DOB['seconds'] * 1000);
          setTimeout(() => {
            M.updateTextFields();
            $('.datepicker').datepicker({
              format: 'dd/mmm/yyyy',
              defaultDate: new Date(this.model.DOB.getFullYear(), this.model.DOB.getMonth(), this.model.DOB.getDate()),
              setDefaultDate: new Date(this.model.DOB.getFullYear(), this.model.DOB.getMonth(), this.model.DOB.getDate()),
              yearRange: 50,
              minDate: new Date(new Date().getFullYear() - 50, 0, 1),
              maxDate: new Date(new Date().getFullYear(), 12, 31),
              onSelect(e) {
                localStorage.setItem('selectedDate', e);
              },
            });
          }, 25);
        }
      });
    }
    setTimeout(() => {
      $('.modal').modal();
    }, 25);
  }

  relationOptionChange(selectedValue: any) {
    this.model.relation = selectedValue;
  }

  submitForm(value: any) {
    this.model.memberId = this.applicantID;
    this.model.firstName = value.firstName;
    this.model.lastName = value.lastName;
    var d_arr = value.dateOfBirth.split("/");
    var dateOfBirth_d = new Date(d_arr[1] + "-" + d_arr[0] + "-" + d_arr[2]);
    var dateOfBirth = new Date(dateOfBirth_d.getFullYear(), dateOfBirth_d.getMonth(), dateOfBirth_d.getDate());
    this.model.DOB = dateOfBirth;
    this.model.SIN = value.SIN;
    this.model.ISN = value.ISN;
    this.model.email = value.email;
    this.model.mobile = value.mobile;

    this.familyCompositionService.updateFamilyMember(this.model, this.id).then(x => {
      toast({ html: 'Family Member Information Successfully Updated!', classes: 'green' });
      this.backClicked();
    });
  }
  dateChange() {
    if (this.editFamilyMember) { this.editFamilyMember.patchValue({ dateOfBirth: $('#dateOfBirth').val() }); }
  }

  deleteFamilyMember() {
    this.familyCompositionService.deleteFamilyMember(this.id).then(x => {
      toast({ html: 'Family Member Removed Successfully!', classes: 'green' });
      this.backClicked();
    });
  }

  backClicked() {
    this.location.back();
  }
}
