<h6 class="center"><strong>Welcome To Your Employer Account</strong></h6>
<div class="section"></div>

<div class="row col s12">
  <div class="col m2 l3 "></div>
  <div class="row col s12 m8 l6">

    <ul class="collapsible">
      <li>
        <div class="collapsible-header blue darken-2"><a [routerLink]="['/employer/search-skills']"
            class="white-text"><i class="material-icons white-text">account_box</i><strong>Search Our Skills
              Databank</strong></a> </div>
        <div class="collapsible-body white">
        </div>
      </li>
      <li>
        <div class="collapsible-header green"><a [routerLink]="['/employer/post-job']" class="white-text"><i
              class="material-icons white-text">add_circle</i><strong>Post A Job Offer</strong></a> </div>
        <div class="collapsible-body white" style="display: none;">
        </div>
      </li>
      <li>
        <div class="collapsible-header teal darken-2"><a [routerLink]="['/employer/my-contracts']" class="white-text"><i
              class="material-icons white-text">work</i><strong>My Contracts</strong></a> </div>
        <div class="collapsible-body white" style="display: none;">
        </div>
      </li>
      <li>
        <div class="collapsible-header cyan lighten-1"><a [routerLink]="['/employer/job-archived']" class="white-text">
            <i class="material-icons white-text">archive</i><strong>Archived Job Offer</strong></a> </div>
        <div class="collapsible-body white" style="display: none;">
        </div>
      </li>
      <li>
        <a class="collapsible-header red darken-2  white-text" (click)="signOut()"><i
            class="material-icons">lock</i><strong>Sign Out </strong>
        </a>
        <div class="collapsible-body white"> </div>
      </li>
    </ul>
  </div>

  <div class="col m2 l3 "></div>
</div>