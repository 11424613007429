import { Component, OnInit } from '@angular/core';
import { Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { SkillModel } from 'src/app/models/SkillModel';
import { environment } from 'src/environments/environment';
import { SkillService } from 'src/app/services/api/skill.service';
import { toast } from 'materialize-css';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-edit-skill',
  templateUrl: './edit-skill.component.html',
  styleUrls: ['./edit-skill.component.css']
})

export class EditSkillComponent implements OnInit {

  clientID = 0;
  editSkillForm: UntypedFormGroup;
  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  model: SkillModel;
  id = '';

  constructor(private route: ActivatedRoute, 
    private fb: UntypedFormBuilder, 
    private location: Location, 
    private skillService: SkillService) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });

    if (this.id) {
      let newId = 0;
      newId = + this.id;
     
      this.editSkillForm = this.fb.group({
        skill: ['', [Validators.required]],
      });

      this.skillService.getSkillById(newId).subscribe(x => {
        if (x) {
          this.model = x[0];
          this.editSkillForm.patchValue({ skill: this.model.SkillName });  

          setTimeout(() => {
            $('.modal').modal();
            M.updateTextFields();
          }, 25);
        }
      });
    }
  }

  get skill() { return this.editSkillForm.get('skill'); }
  
  submitForm(value: any) {
    if (this.editSkillForm.valid){
      this.model.SkillName = value.skill;
      this.skillService.updateSkill(this.model).subscribe(x => {
        if (x) {
          toast({ html: 'Skill Successfully Updated!', classes: 'green' });
          this.location.back();
        }
      });
    }
  }

  DeleteSkill() {
    this.skillService.deleteSkill(this.model).subscribe(resp => {
      toast({ html: 'Skill Removed Successfully!', classes: 'green' });
      this.backClicked();
    });
  }

  backClicked() {
    this.location.back();
  }

}
