import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class TempHousingService {

  proprtyListRef: AngularFirestoreCollection<any>;
  propertyRef: AngularFirestoreDocument<any>;
  repairReqRef: AngularFirestoreDocument<any>;
  repairReqListRef: AngularFirestoreCollection<any>;
  renovationReqRef: AngularFirestoreDocument<any>;
  renovationReqListRef: AngularFirestoreCollection<any>;
  employeeListRef:AngularFirestoreCollection<any>;

  constructor(private db: AngularFirestore) { }

  newProperty(model: any)  {
    model.id  = this.db.createId();
    return this.db.collection('Property').doc(model.id).set(model);
  }

  getProperties(): AngularFirestoreCollection<any[]> {
    this.proprtyListRef = this.db.collection('Property');
    return this.proprtyListRef;
  }

  getPropertyByID(id: string): AngularFirestoreDocument<any> {
    this.propertyRef = this.db.doc('Property/' + id);
    return this.propertyRef;
  }

  getPropertyByMemberID(id: number): AngularFirestoreCollection<any> {
    this.proprtyListRef = this.db.collection('Property', ref => ref.where('aid_of_owner', '==', id));
    return this.proprtyListRef;
  }

  getPropertyByHouseNumber(houseNo: number):AngularFirestoreCollection<any>{
    this.proprtyListRef = this.db.collection('Property', ref => ref.where('house_number', '==', houseNo));
    return this.proprtyListRef;
  }

  updateProperty(model: any){
    return this.db.collection('Property').doc(model.id).update(model);
  }

  deleteProperty(id: string) {
    return this.db.collection('Property').doc(id).delete();
  }

  //House Repair 
  saveRepairRequest(model: any){
    model.id = this.db.createId();
    return this.db.collection('RepairRequest').doc(model.id).set(model);
  }

  getRepairRequests():AngularFirestoreCollection<any[]>{
    this.repairReqListRef = this.db.collection('');
    return this.repairReqListRef;
  }

  getRepairRequest(id:string): AngularFirestoreDocument<any>{
    this.repairReqRef = this.db.doc('RepairRequest/' + id);
    return this.repairReqRef;
  }

  getRepairRequestByMemberID(id: number): AngularFirestoreCollection<any>{
    this.repairReqListRef = this.db.collection('RepairRequest', ref => ref.where('memberID', '==', id));
    return this.repairReqListRef;
  }

  updateRepairRequest(model){
    return this.db.collection('RepairRequest').doc(model.id).update(model);
  }

  //House Renovation 
  saveRenovationRequest(model: any){
    model.id = this.db.createId();
    return this.db.collection('RenovationRequest').doc(model.id).set(model);
  }

  getRenovationRequests():AngularFirestoreCollection<any[]>{
    this.renovationReqListRef = this.db.collection('RenovationRequest');
    return this.renovationReqListRef;
  }

  getRenovationRequest(id:string): AngularFirestoreDocument<any>{
    this.renovationReqRef = this.db.doc('RenovationRequest/' + id);
    return this.renovationReqRef;
  }

  getRenovationRequestByMemberID(id: number): AngularFirestoreCollection<any>{
    this.renovationReqListRef = this.db.collection('RenovationRequest', ref => ref.where('memberID', '==', id));
    return this.renovationReqListRef;
  }
  
  updateRenovationRequest(model){
    return this.db.collection('RenovationRequest').doc(model.id).update(model);
  }

  getEmployees(): AngularFirestoreCollection<any[]> {
    this.employeeListRef = this.db.collection('Department-Employee');
    return this.employeeListRef;
  }

  updateDecision(model: any){
    return this.db.collection('RepairRequest').doc(model.id).update({decision: model.decision, status: model.status });
  }

}
