export class ImageModel {
  imgBase64: string;
}
export class ImageUploaderResponseModel{
  imgBase64: string;
  binDoc
  url: string
  uploaded: boolean
  error:boolean =false
  errorMessage:string
}