import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { IncomeSupportModel } from '../../models/incomeSupportModel';

@Injectable({
  providedIn: 'root'
})

export class IncomeSupportApplicationService {

  constructor(private db: AngularFirestore) { }

  applicationListRef: AngularFirestoreCollection<any>;
  applicationRefs: AngularFirestoreDocument<any>;

  saveApplication(model: IncomeSupportModel) {

    var newApplication;
  
    if(model.hasSpouse){
      newApplication = {
        id: this.db.createId(),
        firstName: model.firstName,
        lastName: model.lastName,
        isn: model.isn,
        bandName: model.bandName,
        maritalStatus: model.maritalStatus,
        province: model.province,
        address: model.address,
        city: model.city,
        postalCode: model.postalCode,
        mailingAddress: model.mailingAddress,
        houseNo: model.houseNo,
        homeNumber: model.homeNumber,
        mobile: model.mobile,    
        healthCareNo: model.healthCareNo,
        dob: model.dob,
        SIN: model.SIN,

        previousAddress: model.previousAddress,
        recentEducation: model.recentEducation,
        employmnet : model.employment,
        // previousEmployement: model.previousEmployement,
        // presentEmployement: model.presentEmployement,
        assets: model.assets,
        children: model.children,
        otherFamilyMembers: model.otherFamilyMembers,
        incomes: model.incomes,
        dateOfLastAssistance: model.dateOfLastAssistance,
        administrativeAuthority: model.administrativeAuthority,
        amount: model.amount,
        signature: model.signature,
        date: model.date,
        
        spouseFirstName: model.spouseFirstName,
        spouselastName: model.spouseLastName,
        spouseIsn: model.spouseIsn,
        spouseBand: model.spouseBand,
        spouseProvince: model.spouseProvince,
        spouseCity: model.spouseCity,
        spouseMobile: model.spouseMobile,
        spouseEmail: model.spouseEmail,
        spouseHealtCareNo: model.spouseHealtCareNo,
        spouseDob: model.spouseDob,
        spouseSIN: model.spouseSIN,
        //spousePreviousAddress: model.spousePreviousAddress,
        spouseRecentEducation: model.spouseRecentEducation,
        // spousePreviousEmployment: model.spousePreviousEmployment,
        // spousePresentEmployment: model.spousePresentEmployment,
        spouseIncomes: model.spouseIncomes? model.spouseIncomes: [],
        spouseSignature: model.spouseSignature,
        spouseDate: model.spouseDate,
  
        witnessSignature: model.witnessSignature,
        witnessDate: model.witnessDate,
        //applicationReceived: model.applicationReceived,
        //reviwedBy: model.reviwedBy,
        //comments: model.comments,
        messageToAdministrator : model.messageToAdministrator,
        documnet: model.documnet,
        pdfDoc: model.pdfDoc,
      }
    }else{
      newApplication = {
        id: this.db.createId(),
        firstName: model.firstName,
        lastName: model.lastName,
        isn: model.isn,
        bandName: model.bandName,
        maritalStatus: model.maritalStatus,
        province: model.province,
        address: model.address,
        city: model.city,
        postalCode: model.postalCode,
        houseNo: model.houseNo,
        homeNumber: model.homeNumber,
        mobile: model.mobile,
        healthCareNo: model.healthCareNo,
        dob: model.dob,
        SIN: model.SIN,

        previousAddress: model.previousAddress,
        recentEducation: model.recentEducation,
        employmnet : model.employment,
        assets: model.assets,
        children: model.children,
        otherFamilyMembers: model.otherFamilyMembers,
        incomes: model.incomes,
        dateOfLastAssistance: model.dateOfLastAssistance,
        administrativeAuthority: model.administrativeAuthority,
        amount: model.amount,
        signature: model.signature,
        date: model.date,
        witnessSignature: model.witnessSignature,
        witnessDate: model.witnessDate,
        //applicationReceived: model.applicationReceived,
        //reviwedBy: model.reviwedBy,
        //comments: model.comments,
        messageToAdministrator : model.messageToAdministrator,
        documnet: model.documnet,
        pdfDoc: model.pdfDoc,
    }
  }
  return this.db.collection('IncomeSupportApplication').doc(newApplication.id).set(newApplication);
  }

  getIncomeSupportApplication(id): AngularFirestoreCollection<any[]> {
    this.db.collection('IncomeSupportApplication', ref => ref.where('application_is', '==', id));
    return this.applicationListRef;
  }

  getApplications(){
    this.applicationListRef = this.db.collection('IncomeSupportApplication');
    return this.applicationListRef;
  }

}
