<div class="row event-detail">
  <div class="col l1 hide-on-med-and-down center date p-t-1">
    <span class=" month">{{model.eventDate.split('/')[1]}} </span><br />
    <span class=" day">{{model.eventDate.split('/')[0]}} </span>
  </div>

  <div class="col l6 m12 s12">
    <div class="card">
      <div class="col s12  icon-text">
        <i class="material-symbols-outlined sub-title-section1"> calendar_today</i>
        <span class="p-l-1 sub-title-section1 black-text">{{model.eventDate.split('/')[1]}}
          {{model.eventDate.split('/')[0]}} ,
          {{model.eventDate.split('/')[2]}}</span>
      </div>
      <div class="col s12  icon-text">
        <i class="material-symbols-outlined sub-title-section1"> location_on</i> <span
          class="p-l-1 sub-title-section1 black-text">{{model.eventPlace}}</span>
      </div>
      <div class="col s12  icon-text">
        <i class="material-symbols-outlined sub-title-section1">schedule</i> <span
          class="p-l-1 sub-title-section1 black-text"> {{model.startsAt}} - {{model.endsAt}}</span>
      </div>
    </div>
    <div class="card" style="padding:2vh;">
      <!-- title -->
      <h5 [innerHTML]="model.title" class="bold-600 "></h5>
      <span [innerHTML]="model.body | noSanitize"></span>
    </div>
  </div>

  <div class="col l5 m12 s12 event-mediacard" style="padding:2vh;">
    <!-- image -->
    <div class="row center card-image" *ngIf="model.type === 'Photo'">
      <img class="materialboxed responsive-img news-img" [src]="model.imgLink">
    </div>
    <!-- video -->
    <div class="row" *ngIf="model.type === 'Video'">
      <div class="video-container">
        <youtube-player [videoId]="videoId"></youtube-player>
        <!-- <div [innerHTML]="iframehtml"></div> -->
      </div>
    </div>
    <!-- video url -->
    <div class="row black center card-image " *ngIf="model.type === 'uploadVideo'">
      <video class="responsive-video " controls>
        <source [src]="model.videoLink" type="video/mp4">
      </video>
    </div>
    <!-- pdf  -->
    <div class="row" *ngIf="model.type === 'PDF' && model.videoLink">
      <iframe class="ifram-res responsive-pdf" title="iframe-box2" [src]="model.videoLink | safeUrl"></iframe>
    </div>
  </div>
</div>