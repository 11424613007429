import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'highlight'
})

export class KeyWordsHighlight1 implements PipeTransform {

    transform(value: any, args: any[]): any {
      if(!value) return;
        if (!args) {return value; }

        let re: any;
        args.forEach(element => {
         re = new RegExp(element, 'gi');
         value = value.replace(re, '<strong><span class="yellow">$&</strong>');
        });
        return value;
    }
}
