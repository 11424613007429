

export class PSEApplicationModel {

    school_period: string;
    applicantID: number;
    firstName: string;
    lastName: string;
    middle_name: string;
    dob: string;
    maritalStatus: string;

    address: string;
    city: string;
    province: string;
    postalCode: string;

    Telephone:string;  
    mobile: string;
    email: string;
    SIN: string;
    HealthCareNo: string;   
    IndianStatusNo: string;  
   
    reside: string;
    location: string;
    other_location: string;

    employed: string;
    employer: string;
    leaveofabsence: string; 
    withpay: string;
    income: number;
    spouse_classification: string;
    spouse_income: number;
    spouse_aid: number;

    applicantName: '';
    signature: string;
    date: string;  //Date;

    bank: string;
    bankno: string;
    branch: string;
    account: string;
    
    current_app_id: number;

    rent_required: string;
    house_description: string = '';
    deposit_required: string;


    //----high school----------------------
    //applicantID: number;
    school: string;
    school_year: number;
    grade: string;
    completed: string;
    transcript: string;
    //funding_app_id: number;


    //----education plan----------------------
    //applicantID: number;
    objective: string;
    completion_date: string;
    program: string;
    institute: string;
    t_years: String;
    classification: string;
    studentID: string;
    fall_term: string;
    winter_term: string;
    spring_term: string;
    summer_term: string;
    //funding_app_id: number;
    dependents: number;

    subprogram: string;
    delivery_method: string;
    program_start_date: string;
    education_sought: string;
    other_details: string;

    fall_tution_amt: number;
    fall_books_amt: number;
    fall_other_amt: number;

    winter_tution_amt: number;
    winter_books_amt: number;
    winter_other_amt: number;
   
    spring_tution_amt: number;
    spring_books_amt: number;
    spring_other_amt: number;

    summer_tution_amt: number;
    summer_books_amt: number;
    summer_other_amt: number;


    familyMembers: Children[];    //[Children];
    colleges: PseCollege[];
    universities: PseCollege[];
    trainings: PseCollege[];
    support_historys: PseSupportHistory[];
    documents: PseDocument[];

}

export class Children {
  
    applicantIDOfHead: number;
    applicantIDOfChild: number;
    given_name: string;
    last_name: string; 
    dob: string;
    Relationship: string;
    //edu_level: string; 
    application_no: number;
    family_comp_id: number;
    financial_contribution: number;

    care_required = '';
    child_care_level = -1;
    child_care_category = -1;
    apply_date = '';
    ChildHealthCareNo = '';
    ISNofChild = '';
    gender = '';
}


export class PseCollege {

    applicantID: number;
    institute: string;
    college_year: number;
    years: number;
    completed: string;
    transcript: string;
    funding_app_id: number;

}

export class PseSupportHistory {

    applicantID: number;
    prior_support: string;
    agency: string;
    support_year: number;
    support_institute: string;
    support_classification: string;
    funding_app_id: number;
}


export class PseDocument {
    applicantID: number;
    docType: string = '';
    name: string = '';
    type: string = '';
    link: string = '';
    funding_app_id: number;
    photo: string='';

}

