import { Component, OnInit } from '@angular/core';
import { DepartmentService } from 'src/app/services/firebase/department.service';
import { ActivatedRoute, Router } from '@angular/router';
import { trigger, transition, useAnimation } from '@angular/animations';
import { bounceIn } from 'ng-animate';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';

@Component({
  selector: 'app-manage-sub-department',
  templateUrl: './manage-sub-department.component.html',
  styleUrls: ['./manage-sub-department.component.css'],
  animations: [
    trigger('swing', [transition('* => *', useAnimation(bounceIn))])
  ],
})
export class ManageSubDepartmentComponent implements OnInit {

  departmentID: any;
  model: any;
  defaultThemeColor = environment.appTheme.themeColor;
  themeColor: any;
  buttonType: any;
  fontColor = environment.appTheme.fontColor;

  constructor(
    private departmentService: DepartmentService,
    private router: Router,
    private location: Location,
    private settingsService: AppSettingsService, 
    private route: ActivatedRoute,
  ) {
    this.departmentID = window.history.state.department_id;
    this.themeColor = this.settingsService.getAppThemeColor() ? this.settingsService.getAppThemeColor() : this.defaultThemeColor;
    this.buttonType = this.settingsService.getButtonType() ? this.settingsService.getButtonType() : 'rectangularButton';
 
   }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.departmentID = params.get('id');
    });
    
    this.departmentService.getSubDepartmentList(this.departmentID).valueChanges().subscribe(x => {
      if (x.length > 0) {
        this.model = x;
        this.model.sort((a, b) => (a.index < b.index ? -1 : 1));
      }
    });
  }

    // editSubDepartmentData(event: Event, id?: any) {
    //   
    //   event.preventDefault();
    //   if(id){
    //     this.router.navigate(['view-subdepartment-info', id]);
    //   }
     
    // }

    backToPrevious(event: Event) {
      event.preventDefault();
      this.router.navigate(['/department-sub-menu', this.departmentID]);
    }

    backClicked() {
      this.location.back();
    }
}
