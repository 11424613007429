import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { DepartmentService } from 'src/app/services/firebase/department.service';
import { toast } from 'materialize-css';
import { environment } from 'src/environments/environment';
import { CommunityService } from 'src/app/services/api/community.service';

declare var M: any;
declare var $: any;

@Component({
  selector: 'app-edit-department-employee',
  templateUrl: './edit-department-employee.component.html',
  styleUrls: ['./edit-department-employee.component.css']
})

export class EditDepartmentEmployeeComponent implements OnInit {

  employeeID: any;
  routID: any;
  employeeForm: UntypedFormGroup;
  isManager = false;
  model: any;
  canManageAccount = false;
  canManageContact = false;
  accountOwner = true;

  showMobileNo = 'Public';
  showOfficeNo = 'Public';
  showEmailAdr = 'Public';
  allowContact = 'Public';
  manageAccount = false;
  manageContact = false;
  manageDepartmentInfo = false;
  manageDepartmentNews = false;
  manageDepartmentEmployees = false;
  manageSubDepartments = false;

  public phoneNumberMask = ['(', /[0-9]/, /[0-9]/, /[0-9]/, ')',
    ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
    public extention = [/[0-9]/, /[0-9]/, /[0-9]/,/[0-9]/, /[0-9]/, /[0-9]/];

  communities : any[];
  nationofUse = environment.firstNation.name;
  isHead = false;
  isHeadYes = false;
  isHeadNo = true;

  SelectChoice = ['Yes', 'No'];
  appsInDepartment: any;
  managerTitles = ['Head', 'Manager', 'Director', 'Administrator'];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private departmentService: DepartmentService,
    private route: ActivatedRoute,
    private router: Router,
    private communityService: CommunityService
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.employeeID = params.get('id');
    });

    if (this.nationofUse === 'Batc' || this.nationofUse === 'Pbcn') {
      this.communityService.getNationCommunities().subscribe(
        com => {
          if (com.length > 0) {
            this.communities = com;
            setTimeout(() => {
              $('select').formSelect();
            }, 25);
          }
        }
      );
    } else {
      this.communityService.getCommunity().subscribe(
        com => {
          if (com.length > 0) {
            this.communities = com;
            setTimeout(() => {
              $('select').formSelect();
            }, 25);
          }
        }
      );
    }

    setTimeout(() => {
      this.departmentService.getMemberOfDepartmentEmployeeByID(this.employeeID).valueChanges().subscribe(
        emp => {
          if (emp) {
            this.model = emp;
            // Emp Info
            this.employeeForm = this.formBuilder.group({
              jobTitle: [emp.jobTitle, [Validators.required]],
              description: [emp.description],
              workPhone: [emp.workPhone ],
              mobilePhone: [emp.mobilePhone],
              community: [emp.community],
              headOffice: [emp.isHeadOffice],
              ext: [emp.ext],
              applicationName: [emp.applicationName],
              permissions: this.formBuilder.group({
                allowDeptCreate: [emp.permissions.allowDeptCreate],
                allowAddEmp: [emp.permissions.allowAddEmp]
              })
            });
            // Contact
            this.showMobileNo = emp.showMobileNo;
            this.showOfficeNo = emp.showOfficeNo;
            this.showEmailAdr = emp.showEmailAdr;
            this.allowContact = emp.allowContact;
            this.isManager = emp.isManager ? emp.isManager : false;
            this.departmentService.getDepartmentByID(emp.departmentId).valueChanges().subscribe((apps: any) => {
              if (apps) {
                  this.appsInDepartment = apps.applications;
              }
            });

            if(emp.isHeadOffice === 'Yes') {this.isHead = true;
            } else if(emp.isHeadOffice === 'No') {this.isHead = false;}
            // Permissions
            // this.manageAccount = emp.manageAccount;
            // this.manageContact = emp.manageContact;
            // this.manageDepartmentInfo = emp.manageDepartmentInfo;
            // this.manageDepartmentNews = emp.manageDepartmentNews;
            // this.manageDepartmentEmployees = emp.manageDepartmentEmployees;
            // this.manageSubDepartments = emp.manageSubDepartments;

            setTimeout(() => {
              M.updateTextFields();
              M.textareaAutoResize($('#description'));
              $('select').formSelect();
            }, 25);
          }
        }
      );
    }, 50);

  }

  headOfficeChange(val: any) {
    if(val === 'Yes') {this.isHead = true;
    } else
    if(val === 'No') {this.isHead = false;}

    setTimeout(() => {
      $('select').formSelect();
    }, 25);
  }

  get jobTitle() { return this.employeeForm.get('jobTitle'); }
  get workPhone() { return this.employeeForm.get('workPhone'); }
  get mobilePhone() { return this.employeeForm.get('mobilePhone'); }
  get description() { return this.employeeForm.get('description'); }
  get community() { return this.employeeForm.get('community'); }

  updateEmployee(value: any) {
    this.model.jobTitle =  value.jobTitle ;
    this.model.workPhone =  value.workPhone ;
    this.model.mobilePhone =  value.mobilePhone;
    this.model.description = value.description;
    this.model.community = value.community;
    this.model.showMobileNo = this.showMobileNo;
    this.model.showOfficeNo = this.showOfficeNo;
    this.model.showEmailAdr = this.showEmailAdr;
    this.model.allowContact = this.allowContact;
    this.model.isHeadOffice = this.isHead;
    this.model.ext = value.ext ;
    this.model.applicationName = value.applicationName;
    this.model.permissions = value.permissions;
    // this.model.manageAccount = this.manageAccount;
    // this.model.manageContact = this.manageContact;
    // this.model.manageDepartmentInfo = this.manageDepartmentInfo;
    // this.model.manageDepartmentNews = this.manageDepartmentNews;
    // this.model.manageDepartmentEmployees = this.manageDepartmentEmployees;
    // this.model.manageSubDepartments = this.manageSubDepartments;

    this.departmentService.UpdateMemberInDepartmentEmployee(this.model).then(resp => {
      toast({ html: 'Account Successfully Updated', classes: 'green' });
      this.backToDeartmentEmployees();
    });
  }


  backToDeartmentEmployees() {
    //localStorage.removeItem('isManager');
    this.router.navigate(['/member/manage-department-members'], { state: { department_id: this.model.departmentId } });
  }
  headYesClick() {
    this.isHead = true;
      }
      headNoClick() {
        this.isHead = false;
      }

  manageAccountClicked() {
    this.manageAccount = !this.manageAccount;
  }

  manageContactClicked() {
    this.manageContact = !this.manageContact;
  }

  manageDepartmentInfoClicked() {
    this.manageDepartmentInfo = !this.manageDepartmentInfo;
  }

  manageDepartmentNewsClicked() {
    this.manageDepartmentNews = !this.manageDepartmentNews;
  }

  manageDepartmentEmployeesClicked() {
    this.manageDepartmentEmployees = !this.manageDepartmentEmployees;
  }

  manageSubDepartmentsClicked() {
    this.manageSubDepartments = !this.manageSubDepartments;
  }

  privateMobileClick() {
    this.showMobileNo = 'Private';
  }

  publicMobileClick() {
    this.showMobileNo = 'Public';
  }

  membersMobileClick() {
    this.showMobileNo = 'Members';
  }

  privateOfficeClick() {
    this.showOfficeNo = 'Private';
  }

  publicOfficeClick() {
    this.showOfficeNo = 'Public';
  }

  membersOfficeClick() {
    this.showOfficeNo = 'Members';
  }

  privateEmailClick() {
    this.showEmailAdr = 'Private';
  }

  publicEmailClick() {
    this.showEmailAdr = 'Public';
  }

  membersEmailClick() {
    this.showEmailAdr = 'Members';
  }

  privateContactClick() {
    this.allowContact = 'Private';
  }

  publicContactClick() {
    this.allowContact = 'Public';
  }

  membersContactClick() {
    this.allowContact = 'Members';
  }

}