import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { environment } from 'src/environments/environment';
import { MediaService } from 'src/app/services/api/media.service';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-login-and-signup-settings',
  templateUrl: './login-and-signup-settings.component.html',
  styleUrls: ['./login-and-signup-settings.component.css']
})

export class LoginAndSignupSettingsComponent implements OnInit {

  nationName = environment.firstNation.displayName;
  defaultThemeColor = environment.appTheme.themeColor;
  themeColor: any;
  buttonType: any;
  sectionName: string;
  menu: any;
  isNewSetting = true;
  loginNewSetting = true;
  signupNewSetting = true;
  welcomeNewSetting = true;
  defaultSetting = [
    {
      name: 'Welcome',
      displayName: 'Welcome',
      title: 'Welcome to ' + this.nationName + ' Web Application',
      description: '-As a member of ' + this.nationName + ', your info is on our membership list. We will only use the info you enter to match it to our list.<br/><br/>-If the data matches our files then we will send you an invitation to complete your registration.<br/><br/>-Once registered you will have access to your profile, news, events and the ability to communicate with Test Nation departments and leadership.',
      image: ''
    },
    {
      name: 'Login',
      displayName: 'Login Page',
      icon: '',
      title: 'Welcome Back',
      description: 'Our suite of mobile applications have been designed with First Nations in mind to assist First Nations governments, organizations and advocacy groups with digital information management, online communications and community development.',
      image: 'https://api.mysabenefits.ca/Media/Demo/Images/6da6cf35-4671-4b9a-be99-edba6e21f7f4-thumbnail_IMG_0372.jpg',
    },
    {
      name: 'Signup',
      displayName: 'Request To Join',
      icon: '',
      title: 'Lets get you setup!',
      description: 'Our suite of mobile applications have been designed with First Nations in mind to assist First Nations governments, organizations and advocacy groups with digital information management, online communications and community development.',
      image: 'https://api.mysabenefits.ca/Media/Demo/Images/6da6cf35-4671-4b9a-be99-edba6e21f7f4-thumbnail_IMG_0372.jpg',
    }
  ];
  loginSetting: any;
  signupSetting: any;
  welcomeSetting: any;
  imgErrorMsg: any;
  imgLink: any;

  constructor(private router: Router,
    private location: Location,
    private settingService: AppSettingsService,
    private mediaService: MediaService) {
    this.themeColor = this.settingService.getAppThemeColor() ? this.settingService.getAppThemeColor() : this.defaultThemeColor;
    this.buttonType = this.settingService.getButtonType() ? this.settingService.getButtonType() : 'rectangularButton';
  }

  ngOnInit(): void {
    this.settingService.getMenuName('Settings').valueChanges().subscribe(menus => {
      if (menus && menus.length > 0) {
        let submenus = menus[0].submenus;
        let menu = submenus.filter(m => m.name == 'LoginAndSignup');
        if (menu && menu.length > 0) this.sectionName = menu[0].displayName;
      }
    });

    this.settingService.getSignUpLoginSetting().valueChanges().subscribe(setting => {
      if (setting && setting.length > 0) {
        if (setting.filter(s => s.name == 'Login')[0]) {
          this.loginSetting = setting.filter(s => s.name == 'Login')[0];
          this.loginNewSetting = false;
        } else {
          this.loginSetting = this.defaultSetting.filter(s => s.name == 'Login')[0];
          this.loginNewSetting = true;
        }
        if (setting.filter(s => s.name == 'Signup')[0]) {
          this.signupSetting = setting.filter(s => s.name == 'Signup')[0];
          this.signupNewSetting = false;
        } else {
          this.signupSetting = this.defaultSetting.filter(s => s.name == 'Signup')[0];
          this.signupNewSetting = true;
        }
        if (setting.filter(s => s.name == 'Welcome')[0]) {
          this.welcomeSetting = setting.filter(s => s.name == 'Welcome')[0];
          this.welcomeNewSetting = false;
        } else {
          this.welcomeSetting = this.defaultSetting.filter(s => s.name == 'Welcome')[0];
          this.welcomeNewSetting = true;
        }
      } else {
        this.loginSetting = this.defaultSetting.filter(s => s.name == 'Login')[0];
        this.signupSetting = this.defaultSetting.filter(s => s.name == 'Signup')[0];
        this.welcomeSetting = this.defaultSetting.filter(s => s.name == 'Welcome')[0];
        this.signupNewSetting = true;
        this.loginNewSetting = true;
        this.welcomeNewSetting = true;
      }
      $(document).ready(function () {
        $('.collapsible').collapsible();
        $('.modal').modal();
        $('.tooltipped').tooltip();
      });
    });

    $(".toggle-arrow").click(function () {
      $(this).toggleClass("fa-eye fa-eye-slash");
      var collapsible = $($(this).attr("toggle"));
    });
  }

  //Upload slider Image
  handleFileInput(files: FileList, from: string) {
    if (!this.validateImage(files[0].name)) {
      this.imgErrorMsg = 'Invalid File Type, Please Select an Image File';
      this.imgLink = null;
      return;
    }
    if (files) {
      this.mediaService.UploadBinImage(files[0]).subscribe(upload => {
        if (upload) {
          this.imgLink = upload.imgLink;
          if (from == 'welcome') this.welcomeSetting.image = this.imgLink;
          if (from == 'signup') this.signupSetting.image = this.imgLink;
          if (from == 'login') this.loginSetting.image = this.imgLink;
        }
      });
    }
  }

  styleChanged(event, from) {
    switch (from) {
      case 'Welcome Title':
        this.welcomeSetting.title = event;
        break;
      case 'Welcome Description':
        this.welcomeSetting.description = event;
        break;
      case 'Signup Title':
        this.signupSetting.title = event;
        break;
      case 'Signup Description':
        this.signupSetting.description = event;
        break;
      case 'Login Title':
        this.loginSetting.title = event;
        break;
      case 'Login Description':
        this.loginSetting.description = event;
        break;
      default:
    }
  }

  saveWelcomeSettings() {
    if (this.welcomeSetting) {
      if (this.welcomeNewSetting) {
        this.settingService.saveWelcomeModalSettings(this.welcomeSetting).then(x => {
          M.toast({ html: 'Changes Saved Successfully.', classes: 'green' });
          $('.collapsible').collapsible('close', 0);
        });
      } else {
        this.settingService.updateWelcomeModalSettings(this.welcomeSetting).then(x => {
          M.toast({ html: 'Changes Saved Successfully.', classes: 'green' });
          $('.collapsible').collapsible('close', 0);
        });
      }
    }
  }

  saveSignUpSettings() {
    if (this.signupSetting) {
      if (this.signupNewSetting) {
        this.settingService.saveSignUpPageSettings(this.signupSetting).then(x => {
          M.toast({ html: 'Changes Saved Successfully.', classes: 'green' });
          $('.collapsible').collapsible('close', 0);
        });
      } else {
        this.settingService.updateSignUpPageSettings(this.signupSetting).then(x => {
          M.toast({ html: 'Changes Saved Successfully.', classes: 'green' });
          $('.collapsible').collapsible('close', 0);
        });
      }
    }
  }

  saveLogInSettings() {
    if (this.loginSetting) {
      if (this.loginNewSetting) {
        this.settingService.saveLoginPageSettings(this.loginSetting).then(x => {
          M.toast({ html: 'Changes Saved Successfully.', classes: 'green' });
          $('.collapsible').collapsible('close', 1);
        });
      } else {
        this.settingService.updateLoginPageSettings(this.loginSetting).then(x => {
          M.toast({ html: 'Changes Saved Successfully.', classes: 'green' });
          $('.collapsible').collapsible('close', 1);
        });
      }
    }
  }

  private validateImage(name: any) {
    const ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() === 'png') {
      return true;
    } else if (ext.toLowerCase() === 'jpg') {
      return true;
    } else if (ext.toLowerCase() === 'jpeg') {
      return true;
    } 
    else if (ext.toLowerCase() === 'svg') {
      return true;
    }
    else if (ext.toLowerCase() === 'webp') {
      return true;
    }else {
      return false;
    }
  }

  navigate(item) {
    const routeMappings = {
      'AboutUs': 'admin/dashboard-sub-menu/about-us',
      'NationInfo': 'admin/dashboard-sub-menu/nation-settings',
      'Departments': 'admin/dashboard-sub-menu/department',
      'Governance': 'admin/dashboard-sub-menu/governance',
      'AreaOfInterest': 'admin/dashboard-sub-menu/areas-of-interest',
    };

    const route = routeMappings[item];
    if (route) {
      this.router.navigate([route]);
    }
  }

  back() {
    this.location.back();
  }

}