import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { LoadingState } from 'src/app/models/loadingState';
@Injectable({
  providedIn: 'root'
})
export class LoadingService {

  private loaderSubject = new Subject<LoadingState>();
  loaderState = this.loaderSubject.asObservable();

  constructor() { }

  show() {
    this.loaderSubject.next({ show: true } as LoadingState);
  }
  hide() {
    this.loaderSubject.next({ show: false } as LoadingState);
  }
}
