<h6 class="center"><strong>Edit Department News</strong></h6>
<div class="row col s12" *ngIf="myModel">
  <div class="col l1"></div>
  
  <div class="col s12 l10 ">
    <app-content-editor *ngIf="isManager || canManageNews" [contentType]="'DepNews'" [model]="myModel" [actionType]="'Edit'"
      (contentUpdated)="updateEventHandler($event)" (contentArchived)="archiveEventHandler($event)"
      (contentUnArchived)="unArchiveEventHandler($event)" (contentDeleted)="deleteEventHandler($event)">
    </app-content-editor>

    <app-content-viewer *ngIf="!isManager && !canManageNews" [model]="myModel"></app-content-viewer>
  </div>
  <div class="col l1"></div>
</div>