<div class="body-h">
  <div class="header-setting b-m-2">
    <div class="col l9 m9 s12">
      <h4 class="">My Job Objectives </h4>
      <p class="bold-100 sub-title-section3">It is a long established fact that a reader will be distracted by the
        readable content of a page when looking at its layout.</p>
    </div>
    <div class="col l3  m4 s12 p-m-2 ">
      <a [routerLink]="['/member/member-main-home/add-objective']" class="add-button btn white center-align p-l-3 black-text"><i
          class="material-icons-outlined">add</i> Add Job Objectives</a>
    </div>
  </div>

  <main class="StickyContent white row">
    <div class="col s12 l11">
      <div class="row" *ngIf="hasRows">

        <div class="card-panel white center" *ngFor="let m of model">
          <!--  <a [routerLink]="['/member/profile/edit-objective', m.id]" *ngIf="!sqldb"><strong><i class="material-icons left black-text">import_contacts</i> <span class="center">{{ m.Objective }}</span>   </strong></a>
            <a [routerLink]="['/member/profile/edit-objective', m.ID]" *ngIf="sqldb"><strong><i class="material-icons left black-text">import_contacts</i> <span class="center">{{ m.Objective }}</span>   </strong></a>
          -->
          <a [routerLink]="['/member/member-main-home/edit-objective', m.ID]"><strong><i
                class="material-icons left black-text">import_contacts</i> <span class="center">{{ m.Objective
                }}</span>
            </strong></a>
        </div>

        <div class="card-panel orange darken-2" *ngIf="!hasRows">
          <h6 class="center white-text"><i class="material-icons white-text left">info</i>
            <strong> You dont have any Job Objective. <div class="section"></div> Please enter your Job Objective.
              Potential Employers will
              be able to search this database and contact you if you meet their requirements. </strong>
          </h6>
        </div>

        <!-- <a [routerLink]="['/member/profile/add-objective']" class='col s12 btn waves-effect green'
          style="margin-bottom: 15px;">
          <i class="material-icons right">add_circle</i> Add Job Objective
        </a> -->

        <button [ngClass]="'col s12 btn waves-effect ' + themeColor" (click)="backClicked()">
          <i class="material-icons left">house</i> Back To Home Page
        </button>
      </div>
    </div>
  </main>
</div>