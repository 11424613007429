<div class="row p-t-5">
  <div class="card row " style="padding: 0vh 3vh;">
    <div class="col l3 s6 chief-img">
      <img *ngIf="model.profile_pic" [src]="model.profile_pic" alt="" class="center circle responsive-img"
        style="  border-radius: 50%;  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;">

      <img *ngIf="!model.profile_pic" class="responsive-img" [ngStyle]="{'border-color': themeColor}"
        style=" border-radius: 50%; box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;"
        src="../../../../assets/img/avatar.jpeg">
    </div>

    <div class="col l3 s6 chief-name">
      <h5 class="title collection">{{model.firstName}} {{model.lastName}}</h5>
      <h6 class="sub-title-section2 collection bold-600 ">{{chiefTitle}}</h6>
      <!-- <p *ngIf="model.showEmail == 'Public'">{{model.email}}</p> -->
    </div>

    <div class="col l6 s12 coll-chief">
      <ul class="collection ">
        <li class="collection-item" *ngIf="!model.displayName">
          <strong>
            <div><span class="bold-text">First Name : </span><a class="secondary-content black-text">
                {{model.firstName}}
              </a></div>
          </strong>
        </li>
        <li class="collection-item" *ngIf="!model.displayName">
          <strong>
            <div><span class="bold-text">Last Name : </span><a class="secondary-content black-text">
                {{model.lastName}}
              </a></div>
          </strong>
        </li>

        <li class="collection-item" *ngIf="model.displayName">
          <strong>
            <div><span class="bold-text">Name : </span><a class="secondary-content black-text">
                {{model.displayName}}
              </a></div>
          </strong>
        </li>

        <li class="collection-item" *ngIf="model.showEmail === privacy">
          <strong>
            <div><span class="bold-text"> Email : </span><a class="secondary-content" [href]="'mailto:' + model.email">
                {{model.email}}
              </a></div>
          </strong>
        </li>
        <li class="collection-item" *ngIf="model.showOfficePhone === privacy">
          <strong>
            <div><span class="bold-text">Office Phone : </span><a class="secondary-content"
                [href]="'tel:' + model.office_phone">
                {{model.office_phone}}
              </a></div>
          </strong>
        </li>
        <li class="collection-item" *ngIf="model.showMobile === privacy">
          <strong>
            <div><span class="bold-text" class="bold-text">Mobile Phone : </span><a class="secondary-content"
                [href]="'tel:' + model.cell_phone">
                {{model.cell_phone}}
              </a></div>
          </strong>
        </li>
      </ul>

      <div class="section soc-med">
        <a href="{{model.chiefFacebookAcc}}" target="_blank" class="center col s2"
          *ngIf="(model.showFacebookAcc === 'Public') && model.chiefFacebookAcc"><span style="cursor: pointer;"> <i
              class="fa fa-facebook prefix blue-text" style="font-size:larger;"></i></span> </a>

        <a href="{{model.chiefInstagramAcc}}" target="_blank" class="center col s2"
          *ngIf="(model.showInstagramAcc === 'Public' ) && model.chiefInstagramAcc"><span style="cursor: pointer;"> <i
              class="fa fa-instagram prefix pink-text" style="font-size:larger;"></i> </span></a>

        <a href="{{model.chiefTwitterAcc}}" target="_blank" class="center col s2"
          *ngIf="(model.showTwitterAcc === 'Public' ) && model.chiefTwitterAcc"><span style="cursor: pointer;"> <i
              class="prefix black-text bold-600" style="font-size:larger;">X</i></span> </a>

        <a href="{{model.chiefLinkedinAcc}}" target="_blank" class="center col s2"
          *ngIf="(model.showLinkedinAcc === 'Public') && model.chiefLinkedinAcc"><span style="cursor: pointer;"> <i
              class="fa fa-linkedin prefix blue-text " style="font-size:larger;"></i></span> </a>

        <a href="{{model.chiefYoutubeAcc}}" target="_blank" class="center col s2"
          *ngIf="(model.showYoutubeAcc === 'Public') && model.chiefYoutubeAcc"><span style="cursor: pointer;"> <i
              class="fa fa-youtube prefix red-text" style="font-size:larger;"></i></span> </a>
      </div>
    </div>
  </div>

  <div class=" p-t-1 row bottom-card">
    <div class="collection col s12 l3 card">

      <ul class="row collection">
        <li class="collection-item">
          <strong>
            <div><span class="sub-title-section2 bold-700">Portfolio</span></div>
          </strong>
        </li>
        <ol>
          <li class="collection-item" *ngFor="let item2 of model.portfolio">
            <a class="secondary-content black-text " [innerHTML]="item2.name"></a>
          </li>
        </ol>
        <hr>
        <li class="collection-item">
          <strong>
            <div> <span class="sub-title-section2 bold-700">Website</span></div>
          </strong>
        </li>
        <li class="collection-item">
          <a class="blue-text" href="{{model.website}}" target="_blank">
            {{model.website}}
          </a>
        </li>
      </ul>
    </div>

    <div class="row col l8 s12 push-l1 card" *ngIf="model.portfolio && model.portfolio.length > 0">
      <h6 style="font-weight: bolder;  font-family: 'Roboto', sans-serif; margin-top: 5%; padding-left: 10px;">
        {{chiefTitle}} Bio
      </h6>
      <div class="row col s12 l12 bold-100 " *ngIf="model.chiefBio">
        <p>{{model.chiefBio}}</p>
      </div>
    </div>
  </div>
</div>