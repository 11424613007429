import { Component, OnInit } from '@angular/core';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { SectionSettingService } from 'src/app/services/firebase/section-setting.service';
import { environment } from 'src/environments/environment';
import { toast } from 'materialize-css';
import { Location } from '@angular/common';

declare var $: any;

@Component({
  selector: 'app-section-setting',
  templateUrl: './section-setting.component.html',
  styleUrls: ['./section-setting.component.css']
})

export class SectionSettingComponent implements OnInit {

  defaultThemeColor = environment.appTheme.themeColor;
  themeColor: any;
  buttonType: any;
  sectionName: string;
  model: any[];
  submenus: any[];
  editName = false;
  sectionId: any;
  openDeleteModal = false;
  modalData: any;

  constructor(private appSettingsService: AppSettingsService,
    private sectionService: SectionSettingService,
    private location: Location) {
    this.themeColor = this.appSettingsService.getAppThemeColor() ? this.appSettingsService.getAppThemeColor() : this.defaultThemeColor;
    this.buttonType = this.appSettingsService.getButtonType() ? this.appSettingsService.getButtonType() : 'rectangularButton';
  }

  ngOnInit(): void {
    this.appSettingsService.getMenuName('Settings').valueChanges().subscribe(menus => {
      if (menus && menus.length > 0) {
        this.submenus = menus[0].submenus;
        let menu = this.submenus.filter(m => m.name == 'SectionSetting');
        if (menu && menu.length > 0) this.sectionName = menu[0].displayName;
      }
    });

    this.sectionService.getSections().valueChanges().subscribe(sec => {
      if (sec && sec.length > 0) {
        this.model = sec;
      }
    });
    $(document).ready(function () {
      $(".modal").modal();
    });
  }

  editNameClicked() {
    this.editName = true;
  }

  saveName() {
    let index = this.submenus.findIndex(m => m.name == 'SectionSetting');
    this.submenus[index].displayName = this.sectionName;
    this.appSettingsService.UpdateMenu('Settings', this.submenus).then((update) => {
      this.editName = false;
      toast({ html: `${update}`, classes: 'green' });
    }).catch((error) => {
      toast({ html: `${error}`, classes: 'red' });
    });
  }

  getInitials(name: any) {
    if (name) {
      let sectionName = name.replace(/<(?:.|\n)*?>/gm, '');
      return sectionName.substring(0, 1);
    } else { return ''; }
  }

  editSection() {
  }

  onDelete(id) {
    this.sectionId = id;
    this.modalData = {
      title: 'Delete Section',
      icon: 'warning',
      subTitle: 'Are you sure you want to delete this Section?',
      description: 'The section and its contents will be permanently deleted.',
      confirmation: {
        title: 'Are you really sure you want to delete this section?',
        text: 'Please, be absolutely certain before proceeding, as this action this action cannot be undone.',
        text2: 'Confirm your decision to proceed with the deletion.'
      }
    }
    this.openDeleteModal = true;
  }

  cancelHandler(valueEmitted) {
    if (valueEmitted) this.openDeleteModal = false;
    $('.modal').modal('close');
  }

  confirmHandler(valueEmitted) {
    if (valueEmitted) this.delete();
    this.openDeleteModal = false;
    $('.moda').modal('close');
  }

  delete() {
    if (this.sectionId) {
      this.sectionService.deleteSection(this.sectionId).then(() => {
        toast({ html: 'Section Successfully Deleted!', classes: 'green' });
        this.backClicked();
      });
    }
  }

  backClicked() {
    this.location.back();
  }

}
