import { ElectionService } from 'src/app/services/firebase/election.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FireMembersService } from 'src/app/services/firebase/fire-members.service';
import { Location } from '@angular/common';
import { UntypedFormGroup, Validators, UntypedFormBuilder } from '@angular/forms';
import { CandidateDetailModel } from 'src/app/models/ElectionModel';
import { toast } from 'materialize-css';

@Component({
  selector: 'app-edit-campaign-page',
  templateUrl: './edit-campaign-page.component.html',
  styleUrls: ['./edit-campaign-page.component.css']
})

export class EditCampaignPageComponent implements OnInit {

  id = '';
  candidateDetail: any;
  campaignForm: UntypedFormGroup;
  urlPattern = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
  addCandidateModel: CandidateDetailModel;
  editCandidateModel: CandidateDetailModel;
  model: CandidateDetailModel;
  isAdding = true;
  isCandidate: any;
  constructor(private route: ActivatedRoute,
    private fireMembersService: FireMembersService,
    private electionService: ElectionService,
    private fb: UntypedFormBuilder,
    private location: Location) { }

  get facebook() { return this.campaignForm.get('facebook'); }
  get twitter() { return this.campaignForm.get('twitter'); }
  get instagram() { return this.campaignForm.get('instagram'); }
  get youtube() { return this.campaignForm.get('youtube'); }

  ngOnInit() {
    this.campaignForm = this.fb.group(
      {
        facebook: ['', [Validators.pattern(this.urlPattern)]],
        twitter: ['', [Validators.pattern(this.urlPattern)]],
        instagram: ['', [Validators.pattern(this.urlPattern)]],
        youtube: ['', [Validators.pattern(this.urlPattern)]],
        campaignDescription: ['', [Validators.required]]
      }
    );

    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });
    this.fireMembersService.getMemberByID(this.id).valueChanges().subscribe(candidateData => {
      this.candidateDetail = candidateData;
    });

    this.electionService.getCandidateDetailById(this.id).valueChanges().subscribe(candidateData => {
      //
      if (candidateData) {
        if (candidateData.length !== 0) {
          this.editCandidateModel = candidateData[0] as CandidateDetailModel;
          if (this.editCandidateModel.candidateFor.length !== 0) {
            this.isCandidate = true;
            this.isAdding = false;
            this.campaignForm.setValue({
              facebook: this.editCandidateModel.facebook,
              twitter: this.editCandidateModel.twitter,
              instagram: this.editCandidateModel.instagram,
              youtube: this.editCandidateModel.youtube,
              campaignDescription: this.editCandidateModel.campaignDescription
            });
          } else {
            this.isCandidate = false;
          }
        } else {
          this.isCandidate = false;
        }
      }
    });
  }

  submitForm(value) {
    this.model = new CandidateDetailModel();
    this.model.candidateId = this.id;
    this.model.twitter = value.twitter;
    this.model.facebook = value.facebook;
    this.model.instagram = value.instagram;
    this.model.youtube = value.youtube;
    this.model.campaignDescription = value.campaignDescription;
    this.model.candidateFor = this.editCandidateModel.candidateFor;
    this.model.id = this.editCandidateModel.id;
    this.electionService.updateCandidateDetail(this.model);
    toast({ html: 'Campaign Detail Successfully Updated!', classes: 'green' });
    this.backClicked();
  }

  backClicked() {
    this.location.back();
  }
  
}
