import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ApiKeyManagerService } from './apiKeyManager.Service';

@Injectable({
  providedIn: 'root'
})

export class ManageAdminService {

  constructor(private httpClient: HttpClient,  private keyManager: ApiKeyManagerService) { }

  getAdmin(id: any): Observable<any> {
    const url = environment.firstNation.apiUrl + 'api/ManageAdmins/Get/' + id;
    const headers = new HttpHeaders({SOCIALAPIKEY: this.keyManager.getKey()});
    return this.httpClient.get<any>(url, {headers});
}

getAdminList(): Observable<any[]> {
  const url = environment.firstNation.apiUrl + 'api/ManageAdmins/GetList';
  const headers = new HttpHeaders({SOCIALAPIKEY: this.keyManager.getKey()});
  return this.httpClient.get<any>(url, {headers});
}

getActiveAdminsInTheLast3Months(): Observable<any[]> {
  const url = environment.firstNation.apiUrl + 'api/ManageAdmins/GetActiveAdminsList';
  const headers = new HttpHeaders({SOCIALAPIKEY: this.keyManager.getKey()});
  return this.httpClient.get<any>(url, {headers});
}

saveAdmin(model: any): Observable<any> {
  const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
  return this.httpClient.post<any>(
    environment.firstNation.apiUrl + 'api/ManageAdmins/SaveAdmin', JSON.stringify(model), { headers });
}

UpdateAdminContact(model: any): Observable<any> {
  const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
  return this.httpClient.post<any>(
    environment.firstNation.apiUrl + 'api/ManageAdmins/UpdateAdminContact', JSON.stringify(model), { headers });
}



}
