
export class PseFundingModel {

    applicantID: number;
    deposit_amt: number;
    tuition_amt: number;
    books_amt: number;
    rent_amt: number;
    utilities_amt: number;
    living_allowance_amt: number;
    childcare_amt: number;
    other_amt: number;
    mileage: number;
    breakfast: number;
    lunch: number;
    accommodation: number;
    dinner: number;
    funding_app_id: number;

    year: number;
    month: string;
    travel_amt: number;
    date_serial: Date;

    documents: FundingDocumentModel[];
}


export class FundingDocumentModel {
    applicantID: number;
    docType: string = '';
    name: string = '';
    type: string = '';
    link: string = '';
    funding_app_id: number;
    photo: string='';
    //bdno: string='';

}
