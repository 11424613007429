import { Component, OnInit } from '@angular/core';
import { Location, DatePipe } from '@angular/common';
import { ISApplicationService } from 'src/app/services/api/ISApplication.service';
import { MemberService } from 'src/app/services/api/member.service';

const pdfMake = require('pdfmake/build/pdfmake.js');
const pdfFonts = require('pdfmake-unicode/dist/pdfmake-unicode.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-income-assistance',
  templateUrl: './income-assistance.component.html',
  styleUrls: ['./income-assistance.component.css']
})
export class IncomeAssistanceComponent implements OnInit {

  loading = false;
  applications: any[] = [];
  detail: any;
  showDetails = false;
  image: any;
  imageName: any;
  count: number;
  apply_date: Date;
  assets: any[] = [];
  applicantIncomes: any[] = [];
  spouseIncomes: any[] = [];
  applicantID: number;
  current_application: number;
  family_comp: number;
  family_members: any[] = [];
  otherPersons: any[] = [];
  hasSpouse = false;
  p: number = 1;

  constructor(private ISAppService: ISApplicationService, private location: Location, private memberService: MemberService) { }

  ngOnInit() {
    this.loading = true;
    this.ISAppService.getApplications().subscribe(x => {
      if (x && x !== "No Result Found") {

        this.applications = x;
        this.count = this.applications.length;

        this.applicantID = this.applications[0].applicantID;

        this.memberService.getMember(this.applicantID).subscribe(m => {
          if (m) {
            this.family_comp = m[0].current_family_comp;
            this.current_application = m[0].current_application;

            //get family composition
            if (this.family_comp) {
              this.ISAppService.getFamilyMembers(this.family_comp).subscribe(f => {
                if (f) {
                  this.family_members = f;
                }
              });
            }

            if (this.current_application) {

              this.ISAppService.getOtherPersons(this.current_application).subscribe(p => {
                if (p) {
                  this.otherPersons = p;
                }
              })
              //get assets
              this.ISAppService.getAssets(this.current_application).subscribe(assets => {
                if (assets) {
                  this.assets = assets;
                }
              });

              //get incomes
              this.ISAppService.getIncomes(this.current_application).subscribe(incomes => {
                let applicant_incomes = [];
                let spouse_income = [];
                if (incomes) {
                  incomes.forEach(income => {
                    if (income.Fam_Mem_Type == 'A') {
                      applicant_incomes.push(income);
                    } else if (income.Fam_Mem_Type == 'S') {
                      spouse_income.push(income);
                    }
                  });
                  this.applicantIncomes = applicant_incomes;
                  this.spouseIncomes = spouse_income;
                }
              });
            }
          }
        });
      }
      this.loading = false;
    });
  }

  onShowDetails(index) {
    this.detail = this.applications[index];
    this.showDetails = true;
    setTimeout(() => {
      $('.modal').modal();
      $('.tooltipped').tooltip();
    }, 25);

    window.scrollTo(0, 0);
  }

  backClicked() {
    this.location.back();
  }

  backToList() {
    this.showDetails = false;
    window.scrollTo(0, 0);
  }

  async getLogo() {
    let imageLink = "assets/img/Nation-logo.png";
    const image = await this.getBase64FromUrl(imageLink);
    if (image) {
      return {
        image: image,
        width: 100,
        alignment: 'left'
      }
    }
  }

  getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      }
    });
  }

  async getDocumentDefinition() {
    const datePipe = new DatePipe('en-US');
    return {
      info: {
        title: 'INCOME ASSISTANCE APPLICATION',
        subject: 'IS',
        keywords: 'IS Application',
      },
      pageSize: 'LETTER',
      content: [
        {
          columns: [
            [
              await this.getLogo(),
            ],
            [{
              text: 'INCOME SUPPORT/ASSISTANCE APPLICATION',
              style: 'mainTitle',
            }],
          ]
        },
        {
          text: 'Section 1',
          style: 'title',
        },
        {
          columns: [
            [{
              text: 'APPLICANT INFORMATION',
              style: 'subTitle',
            },
            {
              text: 'First Name : ' + this.detail.firstName,
              style: 'normalText',
            },
            {
              text: 'Last Name : ' + this.detail.lastName,
              style: 'normalText',
            },
            {
              text: this.detail.dob ? 'Date of Birth : ' + this.detail.dob : 'Date of Birth : ' + '',
              style: 'normalText',
            },
            {
              text: 'Indian Status Number : ' + this.detail.isn,
              style: 'normalText',
            },
            {
              text: 'Marital Status : ' + this.detail.maritalStatus,
              style: 'normalText',
            },
            {
              text: 'Province of Membership : ' + this.detail.province,
              style: 'normalText',
            },
            {
              text: 'Band Name : ' + this.detail.bandName,
              style: 'normalText',
            },
            {
              text: 'Address : ' + this.detail.address,
              style: 'normalText',
            },
            {
              text: 'Postal Code : ' + this.detail.postalCode,
              style: 'normalText',
            },
            {
              text: 'Health Care Number : ' + this.detail.healthCareNo,
              style: 'normalText',
            },
            {
              text: 'SIN : ' + this.detail.SIN,
              style: 'normalText',
            }],
            (this.detail.maritalStatus == 'Married' || this.detail.maritalStatus == 'Common Law') ?
              [
                {
                  text: 'SPOUSE INFORMATION',
                  style: 'subTitle',
                },
                {
                  text: 'First Name : ' + this.detail.spouseFirstName,
                  style: 'normalText',
                },
                {
                  text: 'Last Name : ' + this.detail.spouseLastName,
                  style: 'normalText',
                },
                {
                  text: this.detail.spouseDob ? 'Date of Birth : ' + this.detail.spouseDob : 'Date of Birth : ' + '',
                  style: 'normalText',
                },
                {
                  text: 'Indian Status Number : ' + this.detail.spouseISN,
                  style: 'normalText',
                },
                {
                  text: 'Province of Membership : ' + this.detail.spouseProvince,
                  style: 'normalText',
                },
                {
                  text: 'Health Care Number : ' + this.detail.spouseHealtCareNo,
                  style: 'normalText',
                },
                {
                  text: 'SIN : ' + this.detail.spouseSIN,
                  style: 'normalText',
                }
              ] : ''
          ]
        },
        {
          text: 'Section 2',
          style: 'title',
        },
        {
          text: 'CHILDREN',
          style: 'subTitle',
        },
        {
          columns: [
            (this.family_members.length > 0) ?
              [
                {
                  table: {
                    widths: ['auto', 'auto', 'auto', 'auto', 'auto'],
                    body: [
                      [
                        {
                          text: 'First Name',
                          style: 'header',
                          bold: true,
                        },
                        {
                          text: 'Last Name',
                          style: 'header',
                          bold: true,
                        },
                        {
                          text: 'Date of Birth',
                          style: 'header',
                          bold: true,
                        },

                        {
                          text: 'Education Level',
                          style: 'header',
                          bold: true,
                        },
                        {
                          text: 'Relationship',
                          style: 'header',
                          bold: true,
                        },
                      ],
                      ...this.family_members.map(child => {
                        return [{ text: child.family_members ? child.family_members : '', style: 'normalText' }, { text: child.last_name ? child.last_name : '', style: 'normalText' }, { text: child.DOB ? child.DOB : '', style: 'normalText' }, { text: child.edu_level != -1 ? child.edu_level : '', style: 'normalText' }, { text: child.Relationship ? child.Relationship : '', style: 'normalText' }];
                      })
                    ]
                  }
                }
              ] : {
                text: "No Childrens Added.",
                style: 'notes'
              }
          ]
        },
        {
          columns: [
            (this.otherPersons.length > 0) ?
              [
                {
                  text: 'OTHER FAMILY MEMBERS',
                  style: 'subTitle',
                },
                {
                  table: {
                    widths: ['auto', 'auto', 'auto', 'auto', 'auto'],
                    body: [
                      [
                        {
                          text: 'First Name',
                          style: 'header',
                          bold: true,
                        },
                        {
                          text: 'Last Name',
                          style: 'header',
                          bold: true,
                        },
                        {
                          text: 'Date of Birth',
                          style: 'header',
                          bold: true,
                        },
                        {
                          text: 'Relationship',
                          style: 'header',
                          bold: true,
                        },
                        {
                          text: 'Contribution',
                          style: 'header',
                          bold: true,
                        },
                      ],
                      ...this.otherPersons.map(other => {
                        return [{ text: other.given_name ? other.given_name : '', style: 'normalText' }, { text: other.last_name ? other.last_name : '', style: 'normalText' }, { text: other.dob ? other.dob : '', style: 'normalText' }, { text: other.Relationship ? other.Relationship : '', style: 'normalText' }, { text: other.financial_contribution ? other.financial_contribution : '', style: 'normalText' }];
                      })
                    ]
                  }
                }
              ] : {
                text: "No Other Family Members Added.",
                style: 'notes'
              }
          ]
        },
        {
          text: 'Section 3',
          style: 'title',
        },
        this.detail.previous_address ?
          {
            columns: [
              [{
                text: 'PREVIOUS ADDRESS',
                style: 'subTitle',
              },
              {
                text: 'Address : ' + this.detail.previous_address,
                style: 'normalText',
              },
              {
                columns: [
                  [{
                    text: this.detail.previous_address_startDate ? 'From : ' + this.detail.previous_address_startDate : 'From : ' + '',
                    style: 'normalText',
                  }],
                  [{
                    text: this.detail.previous_address_endDate ? 'To : ' + this.detail.previous_address_endDate : 'To : ' + '',
                    style: 'normalText',
                  }]
                ]
              },

              ]
            ]
          } : '',
        {
          columns: [
            [{
              text: 'Previous Employment',
              style: 'subTitle'
            },
            {
              text: 'Previous Employer Name : ' + this.detail.previous_employer,
              style: 'normalText',
            },
            {
              text: 'Address : ' + this.detail.previous_employment_address,
              style: 'normalText',
            },
            {
              text: 'Position : ' + this.detail.previous_employment_position,
              style: 'normalText',
            },
            {
              columns: [
                [{
                  text: this.detail.previous_employment_startDate ? 'From : ' + this.detail.previous_employment_startDate : 'From : ' + '',
                  style: 'normalText',
                }],
                [{
                  text: this.detail.previous_employment_endDate ? 'To : ' + this.detail.previous_employment_endDate : 'To : ' + '',
                  style: 'normalText',
                }]
              ]
            },
            ],
            [{
              text: 'Present Employment',
              style: 'subTitle'
            },
            {
              text: 'Present Employer Name : ' + this.detail.present_employer,
              style: 'normalText',
            },
            {
              text: 'Address : ' + this.detail.present_employment_address,
              style: 'normalText',
            },
            {
              text: 'Position : ' + this.detail.present_employment_position,
              style: 'normalText',
            },
            {
              columns: [
                [{
                  text: this.detail.present_employment_startDate ? 'From : ' + this.detail.present_employment_startDate : 'From : ' + '',
                  style: 'normalText',
                }],
                [{
                  text: this.detail.present_employment_endDate ? 'To : ' + this.detail.present_employment_endDate : 'To : ' + '',
                  style: 'normalText',
                }]
              ]
            },
            ],
          ]
        },
        (this.detail.maritalStatus == 'Married' || this.detail.maritalStatus == 'Common Law') ?
          {
            columns: [
              [{
                text: 'PSpouse revious Employment',
                style: 'subTitle'
              },
              {
                text: 'Previous Employer Name : ' + this.detail.previous_employer_spouse,
                style: 'normalText',
              },
              {
                text: 'Address : ' + this.detail.previous_employment_address_spouse,
                style: 'normalText',
              },
              {
                text: 'Position : ' + this.detail.previous_employment_position_spouse,
                style: 'normalText',
              },
              {
                columns: [
                  [{
                    text: this.detail.previous_employment_startDate_spouse ? 'From : ' + this.detail.previous_employment_startDate_spouse : 'From : ' + '',
                    style: 'normalText',
                  }],
                  [{
                    text: this.detail.previous_employment_endDate_spouse ? 'To : ' + this.detail.previous_employment_endDate_spouse : 'To : ' + '',
                    style: 'normalText',
                  }]
                ]
              },
              ],
              [{
                text: 'Spouse Present Employment',
                style: 'subTitle'
              },
              {
                text: 'Present Employer Name : ' + this.detail.present_employer_spouse,
                style: 'normalText',
              },
              {
                text: 'Address : ' + this.detail.present_employment_address_spouse,
                style: 'normalText',
              },
              {
                text: 'Position : ' + this.detail.present_employment_position_spouse,
                style: 'normalText',
              },
              {
                columns: [
                  [{
                    text: this.detail.present_employment_startDate_spouse ? 'From : ' + this.detail.present_employment_startDate_spouse : 'From : ' + '',
                    style: 'normalText',
                  }],
                  [{
                    text: this.detail.present_employment_endDate_spouse ? 'To : ' + this.detail.present_employment_endDate_spouse : 'To : ' + '',
                    style: 'normalText',
                  }]
                ]
              },
              ],
            ]

          } : '',
        {
          text: 'Section 4',
          style: 'title',
        },
        {
          text: 'VEHICLES',
          style: 'subTitle',
        },
        this.detail.car1_make ?
          {
            text: 'Vehicle 1',
            bold: true,
          } : '',
        this.detail.car1_make ?
          {
            columns: [
              [
                {
                  text: 'Model' + this.detail.car1_make
                }
              ],
              [
                {
                  text: 'Year Built' + this.detail.car1_year
                }
              ],
              [
                {
                  text: 'Value' + this.detail.car1_value
                }
              ]
            ]
          } : '',
        this.detail.car2_make ?
          {
            text: 'Vehicle 2',
            bold: true,
          } : '',
        this.detail.car2_make ?
          {
            columns: [
              [
                {
                  text: 'Model : ' + this.detail.car2_make
                }
              ],
              [
                {
                  text: 'Year Built : ' + this.detail.car2_year
                }
              ],
              [
                {
                  text: 'Value : ' + this.detail.car2_value
                }
              ]
            ]
          } : '',

        {
          text: 'ASSETS',
          style: 'subTitle',
        },
        {
          columns: [
            (this.assets.length > 0) ?
              [
                {
                  table: {
                    widths: ['auto', 'auto', 'auto'],
                    body: [
                      [
                        {
                          text: 'Asset',
                          style: 'header',
                          bold: true,
                        },
                        {
                          text: 'Value',
                          style: 'header',
                          bold: true,
                        },
                        {
                          text: 'Description',
                          style: 'header',
                          bold: true,
                        },

                      ],
                      ...this.assets.map(asset => {
                        return [{ text: asset.catagory, style: 'normalText' }, { text: asset.value, style: 'normalText' }, { text: asset.description, style: 'normalText' }];
                      })
                    ]
                  }
                }
              ] : { text: 'No Assets Declared', style: 'notes' }
          ]
        },
        {
          text: 'Section 5',
          style: 'title',
        },
        {
          text: 'INCOMES',
          style: 'subTitle',
        },
        {
          columns: [
            (this.applicantIncomes.length > 0) ?
              [
                {
                  table: {
                    widths: ['auto', 'auto', 'auto', 'auto', 'auto'],
                    body: [
                      [
                        {
                          text: 'Income',
                          style: 'header',
                          bold: true,
                        },
                        {
                          text: 'Amount',
                          style: 'header',
                          bold: true,
                        },
                        {
                          text: 'Date Recevied',
                          style: 'header',
                          bold: true,
                        },
                        {
                          text: 'Description',
                          style: 'header',
                          bold: true,
                        },
                        {
                          text: 'Type',
                          style: 'header',
                          bold: true,
                        },
                      ],
                      ...this.applicantIncomes.map(income => {
                        return [{ text: income.catagory, style: 'normalText' }, { text: income.value, style: 'normalText' }, { text: income.date_recieved ? income.date_recieved : '', style: 'normalText' }, { text: income.description ? income.description : '', style: 'normalText' }, { text: income.type ? income.type : '', style: 'normalText' }];
                      })
                    ]
                  }
                }
              ] : { text: "No Incomes Declared", style: 'notes' }
          ]
        },
        {
          text: 'SPOUSE INCOMES',
          style: 'subTitle',
        },
        {
          columns: [
            (this.spouseIncomes && this.spouseIncomes.length > 0) ?
              [
                {
                  table: {
                    widths: ['auto', 'auto', 'auto', 'auto', 'auto'],
                    body: [
                      [
                        {
                          text: 'Income',
                          style: 'header',
                          bold: true,
                        },
                        {
                          text: 'Amount',
                          style: 'header',
                          bold: true,
                        },
                        {
                          text: 'Date Recevied',
                          style: 'header',
                          bold: true,
                        },
                        {
                          text: 'Description',
                          style: 'header',
                          bold: true,
                        },
                        {
                          text: 'Type',
                          style: 'header',
                          bold: true,
                        },
                      ],
                      ...this.spouseIncomes.map(income => {
                        return [{ text: income.catagory, style: 'normalText' }, { text: income.value, style: 'normalText' }, { text: income.date_recieved ? income.date_recieved : '', style: 'normalText' }, { text: income.description ? income.description : '', style: 'normalText' }, { text: income.type ? income.type : '', style: 'normalText' }];
                      })
                    ]
                  }
                }
              ] : {
                text: "No Incomes Declared",
                style: 'notes'
              }
          ]
        },
      ],
      styles: {
        mainTitle: {
          bold: true,
          fontSize: 16,
          alignment: 'left',
          margin: [0, 0, 0, 20]
        },
        title: {
          bold: true,
          fontSize: 14,
          alignment: 'center',
          margin: [0, 10, 0, 10]
        },
        subTitle: {
          bold: true,
          fontSize: 12,
          alignment: 'left',
          margin: [0, 10, 0, 10]
        },
        normalText: {
          fontSize: 12,
        },
        image: {
          alignment: 'left',
        },
        notes: {
          fontSize: 12,
          alignment: 'left',
          margin: [20, 10, 10, 10],
          italics: true,
        },
        header: {
          fontSize: 12,
          bold: true,
          alignment: 'center',
        },
        links: {
          color: 'blue',
          decoration: 'underline',
          margin: [0, 5, 0, 5]
        }
      }
    };
  }

  async openPdf() {
    const documentDefinition = await this.getDocumentDefinition();
    pdfMake.createPdf(documentDefinition).open();
  }

  async downloadPdf() {
    const documentDefinition = await this.getDocumentDefinition();
    pdfMake.createPdf(documentDefinition).download();
  }

  async printPdf() {
    const documentDefinition = await this.getDocumentDefinition();
    pdfMake.createPdf(documentDefinition).print();
  }

  showSignature(role) {
    if (role == 'applicant') {
      this.image = this.detail.signature;
      this.imageName = 'APPLICANT SIGNATURE';
    } else if (role == 'spouse') {
      this.image = this.detail.spouseSignature;
      this.imageName = 'SPOUSE SIGNATURE';
    } else if (role == 'witness') {
      this.image = this.detail.witnessSignature;
      this.imageName = 'WITNESS SIGNATURE';
    }
    setTimeout(() => {
      $('.modal').modal();
      $('#imageModal').modal('open');
    }, 25);
  }

  closeModal() {
    $('.modal').modal('close');
  }

}
