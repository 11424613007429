<div class="row">

  <ng-template #menus class="sideNav">
    <li [routerLink]="['/admin/dashboard-sub-menu/settings/app-settings']" class="active"
      (click)="select('Menu Setting', 'phonelink_setup')"><a><i
          class="material-symbols-outlined black-text">phonelink_setup</i>App
        Setting</a>
    </li>

    <li [routerLink]="['/admin/dashboard-sub-menu/settings/landing-page-settings']"
      (click)="select('Landing Page', 'web')"><a><i class="material-symbols-outlined black-text">web</i>Landing Page
        Preferences</a>
    </li>

    <li [routerLink]="['/admin/dashboard-sub-menu/settings/app-menu-settings']" (click)="select('App Menu', 'list')">
      <a><i class="material-symbols-outlined black-text">list</i>App Menu</a>
    </li>

    <li [routerLink]="['/admin/dashboard-sub-menu/settings/admin-menu-settings']"
      (click)="select('Admin Menu', 'menu')">
      <a><i class="material-symbols-outlined black-text">menu</i>Admin Menu</a>
    </li>

    <li [routerLink]="['/admin/dashboard-sub-menu/settings/member-menu-settings']"
      (click)="select('Member menu', 'menu_book')">
      <a><i class="material-symbols-outlined black-text">menu_book</i>Member Menu</a>
    </li>

    <li [routerLink]="['/admin/dashboard-sub-menu/settings/login-signup-settings']"
      (click)="select('Login/Signup Pages', 'lock_open')">
      <a><i class="material-symbols-outlined black-text">lock_open</i>Login/Signup Pages</a>
    </li>

    <li [routerLink]="['/admin/dashboard-sub-menu/settings/section-settings']"
      (click)="select('Section Settings', 'settings_overscan')">
      <a><i class="material-symbols-outlined black-text">settings_overscan</i>Section Settings</a>
    </li>

    <li [routerLink]="['/admin/dashboard-sub-menu/settings/app-all-menu-settings']"
      (click)="select('All Menus', 'settings_overscan')">
      <a><i class="material-symbols-outlined black-text">settings_overscan</i>All Menus</a>
    </li>

    <li (click)="backClicked()">
      <a><i class="material-symbols-outlined black-text">arrow_back</i>Back</a>
    </li>
  </ng-template>

  <router-outlet>
  </router-outlet>

</div>