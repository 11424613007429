<div class="row center">
  <h6><strong>Update Profile</strong></h6>
</div>

<div *ngIf="editMemberModel" class="row col s12">
  <div class="col l1 "></div>
  <div class="col s12 m12 l10">
    <div>
      <div class="row center">
        <img *ngIf="profile_pic" class="responsive-img " style="object-fit: contain; width: 50%; height:250px;"
          [src]="profile_pic">
        <img *ngIf="!profile_pic" class="responsive-img " style="object-fit: contain; width: 50%; height:250px;"
          src="../../../../assets/img/emptyProfilePic.png">
        <p *ngIf="showImageUpload">
          <button class="btn-floating btn-small waves-effect waves-light blue" (click)="hiddenfileinput.click()">
            <i class="material-icons">add_a_photo</i>
          </button>

          <input class="file-field input-field col s12 center" type="file" style="display:none" accept="image/*"
            (change)="handleFileInput($event.target.files)" #hiddenfileinput>
        </p>
        <p *ngIf="!showImageUpload">
          <a class="btn-floating btn-small red" (click)="removeSavedFile()"><i class="material-icons">delete</i></a>
        </p>
      </div>

      <form [formGroup]="chiefForm" (ngSubmit)="processFormBeforeSaving(chiefForm.value)">
        <div class="row">
          <div class="input-field col s12">
            <i class="material-icons prefix blue-text">person</i>
            <input id="firstName" type="text" formControlName='firstName' />
            <label for="firstName">First Name :</label>
          </div>

          <div class="input-field col s12">
            <i class="material-icons prefix cyan-text text-darken-2">person</i>
            <input id="lastName" type="text" formControlName='lastName' />
            <label for="lastName">Last Name :</label>
          </div>

          <div class="input-field col s12">
            <i class="material-icons prefix blue-text">person</i>
            <input id="displayName" type="text" formControlName='displayName' />
            <label for="firstName">Display Name :</label>
          </div>

          <div class="input-field class col s11">
            <i class="material-icons prefix green-text">today</i>
            <input id="appointmentDate" type="text" formControlName='appointmentDate' class="input-box"
              autocomplete="off" angular-mydatepicker name="mydate" (click)="dp1.toggleCalendar()"
              [options]="myDpOptions" #dp1="angular-mydatepicker" (dateChanged)="onAppointmentDateChange($event)" />
            <label for="appointmentDate">Elected On :</label>
          </div>

          <div class="col s1">
            <div class="row center">
              <br />
              <i class="material-icons green-text" (click)=dp1.clearDate()>clear</i>
            </div>
          </div>

          <div class="input-field class col s11">
            <i class="material-icons prefix red-text text-lighten-1">event</i>
            <input formControlName="expiryDate" id="expiryDate" class="validate input-box" type="text"
              autocomplete="off" angular-mydatepicker name="mydate" (click)="dp2.toggleCalendar()"
              [options]="myDpOptions" #dp2="angular-mydatepicker" (dateChanged)="onExpiryDateChange($event)" />
            <label for="expiryDate"> Mandate Ends On : </label>
          </div>
          <div class="col s1">
            <div class="row center">
              <br />
              <i class="material-icons green-text" (click)=dp2.clearDate()>clear</i>
            </div>
          </div>

          <div class="input-field col s12">
            <i class="material-icons prefix pink-text">email</i>
            <input id="email" type="email" formControlName='email' (input)="showEmailPrivacyRB()" class="validate"
              autocomplete="off" />
            <label for="email">Email</label>
            <div *ngIf="(getEmail.dirty || getEmail.touched) && getEmail.invalid">
              <small *ngIf="getEmail.errors.required" class="red-text"> The Email is required. </small>
              <small *ngIf="getEmail.errors.email" class="red-text"> wrong email format. </small>
            </div>
          </div>

          <div class="input-field col s12" *ngIf="showEmailPrivacy">
            <p>Show Email To :</p>
            <div class="col s12 m6 l4">
              <label>
                <input type="radio" value="Public" formControlName="showEmailAdr" />
                <span class="text"><strong>General Public</strong></span>
              </label>
            </div>
            <div class="col s12 m6 l4">
              <label>
                <input type="radio" value="Members" formControlName="showEmailAdr" />
                <span class="blue-text"><strong>Members Only</strong></span>
              </label>
            </div>
            <div class="col s12 m6 l4">
              <label>
                <input type="radio" value='Private' formControlName="showEmailAdr" />
                <span class="red-text"><strong>Do Not Show</strong></span>
              </label>
            </div>

            <p><strong>Allow Contact Us Messages </strong></p>
            <div class="col s12 m6 l4">
              <label>
                <input type="radio" value='Public' formControlName="allowContact" />
                <span class="text"><strong>General Public</strong></span>
              </label>
            </div>
            <div class="col s12 m6 l4">
              <label>
                <input type="radio" value='Members' formControlName="allowContact" />
                <span class="text"><strong>Members Only</strong></span>
              </label>
            </div>
            <div class="col s12 m6 l4">
              <label>
                <input type="radio" value='Private' formControlName="allowContact" />
                <span class="red-text"><strong>Do Not Allow</strong></span>
              </label>
            </div>
          </div>

          <div class="input-field col s12">
            <i class="material-icons prefix purple-text ">smartphone</i>
            <!-- <input id="office_phone" type="text" (input)="showOfficeNoPrivacyRB()" formControlName='office_phone'
              class="validate" autocomplete="off" [textMask]="{mask: phoneNumberMask}" /> -->
              <input id="office_phone" type="text" [inputMask]="phoneInputMask" (input)="showOfficeNoPrivacyRB()" formControlName='office_phone'
              class="validate" autocomplete="off"  />
            <label for="office_phone">Office Phone :</label>
            <div *ngIf="(getOffice_phone.dirty || getOffice_phone.touched) && getOffice_phone.invalid">
              <small *ngIf="getOffice_phone.errors.required" class="red-text"> The Office Phone is required. </small>
            </div>
          </div>

          <div class="input-field col s12" *ngIf="showOfficePhonePrivacy">
            <p><strong>Show Office Phone</strong></p>
            <div class="col s12 m6 l4">
              <label>
                <input type="radio" value="Yes" value='Public' formControlName="showOfficePhone" />
                <span class="text"><strong>General Public</strong></span>
              </label>
            </div>
            <div class="col s12 m6 l4">
              <label>
                <input type="radio" value="No" value='Members' formControlName="showOfficePhone" />
                <span class="text"><strong>Members Only</strong></span>
              </label>
            </div>

            <div class="col s12 m6 l4">
              <label>
                <input type="radio" value='Private' formControlName="showOfficePhone" />
                <span class="red-text"><strong>Do Not Show</strong></span>
              </label>
            </div>
          </div>

          <div class="input-field col s12">
            <i class="material-icons prefix teal-text text-darken-1">smartphone</i>
            <!-- <input id="cell_phone" type="text" (input)="showMobileNoPrivacyRB()" formControlName='cell_phone'
              class="validate" autocomplete="off" [textMask]="{mask: phoneNumberMask}" /> -->
              <input id="cell_phone" type="text" [inputMask]="phoneInputMask" (input)="showMobileNoPrivacyRB()" formControlName='cell_phone'
              class="validate" autocomplete="off" />
            <label for="cell_phone">Mobile Phone :</label>
            <div *ngIf="(getCell_phone.dirty || getCell_phone.touched) && getCell_phone.invalid">
              <small *ngIf="getCell_phone.errors.required" class="red-text"> The Mobile Phone is required. </small>
            </div>
          </div>

          <div class="input-field col s12" *ngIf="showMobilePrivacy">

            <p><strong>Show Mobile Phone</strong></p>
            <div class="col s12 m6 l4">
              <label>
                <input type="radio" value='Public' formControlName="showMobile" />
                <span><strong>General Public</strong></span>
              </label>
            </div>
            <div class="col s12 m6 l4">
              <label>
                <input type="radio" value='Members' formControlName="showMobile" />
                <span class="text"><strong>Members Only</strong></span>
              </label>
            </div>
            <div class="col s12 m6 l4">
              <label>
                <input type="radio" value='Private' formControlName="showMobile" />
                <span class="red-text"><strong>Do Not Show </strong></span>
              </label>
            </div>
          </div>

          <div class="input-field col s12">
            <i class="material-icons prefix orange-text">description</i>
            <textarea id="chiefBio" type="text" formControlName='chiefBio' class="materialize-textarea"> </textarea>
            <label for="chiefBio">Biography</label>
          </div>

          <!--  facebook  -->
          <div class="input-field col s12">
            <i class="material-icons prefix " style="color: #3b5998;">facebook</i>
            <input id="chiefFacebook" type="text" formControlName='chiefFacebookAcc' (input)="showFaceBookPrivacyRB()"
              class="materialize-textarea">
            <label for="chiefFacebook"> Facebook account</label>
            <div *ngIf="(getChiefFacebookAcc?.dirty || getChiefFacebookAcc?.touched) && getChiefFacebookAcc?.invalid">
              <small *ngIf="getChiefFacebookAcc.errors.pattern" class="red-text"> Wrong Facebook url format </small>
            </div>
          </div>

          <div class="input-field col s12" *ngIf="showFacebookPrivacy">
            <p><strong>Show Facebook Account</strong></p>
            <div class="col s12 m6 l4">
              <label>
                <input type="radio" value='Public' formControlName="showFacebookAcc" />
                <span><strong>General Public</strong></span>
              </label>
            </div>
            <div class="col s12 m6 l4">
              <label>
                <input type="radio" value='Members' formControlName="showFacebookAcc" />
                <span class="text"><strong>Members Only</strong></span>
              </label>
            </div>
            <div class="col s12 m6 l4">
              <label>
                <input type="radio" value='Private' formControlName="showFacebookAcc" />
                <span class="red-text"><strong>Do Not Show </strong></span>
              </label>
            </div>
          </div>

          <!--  instagram  -->
          <div class="input-field col s12">
            <i class="fa fa-instagram prefix " style="color: #8a3ab9;"></i>
            <input id="chiefInsta" type="text" formControlName='chiefInstagramAcc' (input)="showInstagramPrivacyRB()"
              class="materialize-textarea">
            <label for="chiefInsta"> Instagram account</label>
            <div
              *ngIf="(getChiefInstagramAcc?.dirty || getChiefInstagramAcc?.touched) && getChiefInstagramAcc?.invalid">
              <small *ngIf="getChiefInstagramAcc.errors.pattern" class="red-text"> Wrong Instagram url format </small>
            </div>
          </div>

          <div class="input-field col s12" *ngIf="showInstagramPrivacy">
            <p><strong>Show Instagram Account</strong></p>
            <div class="col s12 m6 l4">
              <label>
                <input type="radio" value='Public' formControlName="showInstagramAcc" />
                <span><strong>General Public</strong></span>
              </label>
            </div>
            <div class="col s12 m6 l4">
              <label>
                <input type="radio" value='Members' formControlName="showInstagramAcc" />
                <span class="text"><strong>Members Only</strong></span>
              </label>
            </div>
            <div class="col s12 m6 l4">
              <label>
                <input type="radio" value='Private' formControlName="showInstagramAcc" />
                <span class="red-text"><strong>Do Not Show </strong></span>
              </label>
            </div>
          </div>

          <!--  twitter  -->
          <div class="input-field col s12">
            <i class="fa fa-twitter prefix" style="color: #1DA1F2;"></i>
            <input id="chiefTwitter" type="text" formControlName='chiefTwitterAcc' (input)="showTwitterPrivacyRB()"
              class="materialize-textarea">
            <label for="chiefTwitter">Twitter account</label>
            <div *ngIf="(getChiefTwitterAcc?.dirty || getChiefTwitterAcc?.touched) && getChiefTwitterAcc?.invalid">
              <small *ngIf="getChiefTwitterAcc.errors.pattern" class="red-text"> Wrong Twitter url format </small>
            </div>
          </div>

          <div class="input-field col s12" *ngIf="showTwitterPrivacy">
            <p><strong>Show Twitter Account</strong></p>
            <div class="col s12 m6 l4">
              <label>
                <input type="radio" value='Public' formControlName="showTwitterAcc" />
                <span><strong>General Public</strong></span>
              </label>
            </div>
            <div class="col s12 m6 l4">
              <label>
                <input type="radio" value='Members' formControlName="showTwitterAcc" />
                <span class="text"><strong>Members Only</strong></span>
              </label>
            </div>
            <div class="col s12 m6 l4">
              <label>
                <input type="radio" value='Private' formControlName="showTwitterAcc" />
                <span class="red-text"><strong>Do Not Show </strong></span>
              </label>
            </div>
          </div>

          <!--  linked in  -->
          <div class="input-field col s12">
            <i class="fa fa-linkedin prefix " style="color: #0077b5;"></i>
            <input id="chiefLinkedin" type="text" formControlName='chiefLinkedinAcc' (input)="showLinkedInPrivacyRB()"
              class="materialize-textarea">
            <label for="chiefLinkedin">linkedin account</label>
            <div *ngIf="(getChiefLinkedinAcc?.dirty || getChiefLinkedinAcc?.touched) && getChiefLinkedinAcc?.invalid">
              <small *ngIf="getChiefLinkedinAcc.errors.pattern" class="red-text"> Wrong Linkedin url format </small>
            </div>
          </div>

          <div class="input-field col s12" *ngIf="showLinkedInPrivacy">
            <p><strong>Show Linkedin Account</strong></p>
            <div class="col s12 m6 l4">
              <label>
                <input type="radio" value='Public' formControlName="showLinkedinAcc" />
                <span><strong>General Public</strong></span>
              </label>
            </div>
            <div class="col s12 m6 l4">
              <label>
                <input type="radio" value='Members' formControlName="showLinkedinAcc" />
                <span class="text"><strong>Members Only</strong></span>
              </label>
            </div>
            <div class="col s12 m6 l4">
              <label>
                <input type="radio" value='Private' formControlName="showLinkedinAcc" />
                <span class="red-text"><strong>Do Not Show </strong></span>
              </label>
            </div>

          </div>
          <!--  youtube  -->
          <div class="input-field col s12">
            <i class="fa fa-youtube prefix red-text"></i>
            <input id="chiefYoutube" type="text" formControlName='chiefYoutubeAcc' (input)="showYouTubePrivacyRB()"
              class="materialize-textarea">
            <label for="chiefYoutube">Youtube account</label>
            <div *ngIf="(getChiefYoutubeAcc?.dirty || getChiefYoutubeAcc?.touched) && getChiefYoutubeAcc?.invalid">
              <small *ngIf="getChiefYoutubeAcc.errors.pattern" class="red-text"> Wrong Youtube url format </small>
            </div>
          </div>

          <div class="input-field col s12" *ngIf="showYoutubePrivacy">
            <p><strong>Show Youtube Account</strong></p>
            <div class="col s12 m6 l4">
              <label>
                <input type="radio" value='Public' formControlName="showYoutubeAcc" />
                <span><strong>General Public</strong></span>
              </label>
            </div>
            <div class="col s12 m6 l4">
              <label>
                <input type="radio" value='Members' formControlName="showYoutubeAcc" />
                <span class="text"><strong>Members Only</strong></span>
              </label>
            </div>
            <div class="col s12 m6 l4">
              <label>
                <input type="radio" value='Private' formControlName="showYoutubeAcc" />
                <span class="red-text"><strong>Do Not Show </strong></span>
              </label>
            </div>
          </div>
        </div>

        <div class="row col s12">
          <button type="submit" class="col s12 btn waves-effect green" title="Save" [disabled]="!chiefForm.valid"
            style="margin-bottom: 15px;"><i class="material-icons right">cloud_upload</i>
            <span *ngIf="editMemberModel"> Update Board Member </span>
          </button>
        </div>

        <a (click)="backClicked();" [ngClass]="'col s12 btn waves-effect ' + themeColor">
          <i class="material-icons left">arrow_back_ios</i>
          Back To Previous Page </a>

      </form>
    </div>
  </div>
  <div class="col l1 "></div>
</div>

<div *ngIf="!editMemberModel" class="center">
  You are not a member of any board
</div>