<div class="container-pi  ">

    <h4 class="title-section1 title-member icon-texts">
        <span class="material-symbols-outlined back-icon p-r-1" (click)=" backClicked()">
            arrow_circle_left
        </span>Personal Information
    </h4>

    <div class="row card-panel b-n-1">
        <div class="row container-image" *ngIf="member">
            <div class="col s12 l2 m12 center-align">
                <div *ngIf="member" class="row imgC  hide-on-small-only show-on-medium-and-up">
                    <img *ngIf="imgLink" class="" [src]="imgLink" />
                    <span *ngIf="!imgLink" class="initials"
                        [ngStyle]="{'color' : themeColor}">{{getInitials(member.GivenName,member.LastName)}}</span>
                </div>

                <div *ngIf="member" class="  imgC2  hide-on-med-and-up">
                    <img *ngIf="imgLink" class="" [src]="imgLink" />
                    <span *ngIf="!imgLink" class="initials"
                        [ngStyle]="{'color' : themeColor}">{{getInitials(member.GivenName,member.LastName)}}</span>
                </div>
            </div>
            <!-- <button class="btn btn-small waves-effect blue" [disabled]="isUploading" (click)="triggerFileInput()">
                {{ isUploading ? 'Uploading...' : 'Upload Picture' }}
            </button>
            <input type="file" accept="image/*" #fileInput (change)="handleFileUpload($event)" style="display: none;" /> -->

            <!-- Profile Picture Section -->
            <div class="profile-image-container center">
                <!-- <img [src]="imgLink || 'https://www.w3schools.com/howto/img_avatar.png'" class="profile-img" /> -->
                <button class="btn-small waves-effect blue" (click)="openModal()">Change Picture</button>
            </div>


            <div class="col s12 l6 m12 user-info">
                <div class="sub-title-section bold-800 flex-item ">{{member.GivenName}} {{member.LastName}}</div>
                <div class="sub-title-section flex-item ">{{ member.Email}}</div>
            </div>
        </div>

        <div *ngIf="member && sqldb" class="row col s12">
            <div class="col s12">
                <app-personal-info [model]="member" [dependant]="dep"
                    (addressUpdated)="parentEventHandlerFunction($event)"></app-personal-info>
            </div>
        </div>

        <div class="col s12 l12 m12" *ngIf="!sqldb">
            <form [formGroup]="addUpdateDepartmentForm" (ngSubmit)="PostDepartment(addUpdateDepartmentForm)">
                <div class="row container-profile">

                    <div class="input-field col s12 l6">
                        <div class="label-input">First Name<span class="red-text">*</span></div>
                        <input id="GivenName" type="text" formControlName='GivenName' required class="validate"
                            autocomplete="off" placeholder="First Name" />
                    </div>

                    <div class="input-field col s12 l6">
                        <div class="label-input">Middle Name<span class="red-text">*</span></div>
                        <input id="middle_name" type="text" formControlName='middle_name' required class="validate"
                            autocomplete="off" placeholder="Middle Name" />
                    </div>

                    <div class="input-field col s12 l6">
                        <div class="label-input">Last Name<span class="red-text">*</span></div>
                        <input id="LastName" type="text" formControlName='LastName' required class="validate"
                            autocomplete="off" placeholder="Last Name" />
                    </div>

                    <div class="divider col s12 p-m-1 b-m-1"></div>

                    <div class="input-field col s12 l6">
                        <div class="label-input">Date of Birth</div>
                        <input id="DOB" formControlName="DOB" type="text" class="form-control datepicker"
                            (change)="dateOfBirthChange()" placeholder="Date of Birth">
                    </div>

                    <div class="input-field col s12 l6">
                        <div class="label-input">Email Address</div>
                        <input id="Email" type="text" formControlName='Email' required class="validate"
                            autocomplete="off" placeholder="Email Address" />
                    </div>

                    <div class="input-field col s12 l6">
                        <div class="label-input">Telephone Number</div>
                        <input id="Telephone" type="text" formControlName='Telephone' required class="validate"
                            [inputMask]="phoneInputMask" autocomplete="off" [textMask]="{mask: phoneNumberMask}"
                            placeholder="Telephone Number" />
                    </div>

                    <div class="input-field col s12 l6">
                        <div class="label-input">Band</div>
                        <input id="Band" type="text" formControlName='Band' required class="validate" autocomplete="off"
                            placeholder="Band" />
                    </div>

                    <div class="divider col s12 p-m-1 b-m-1"></div>

                    <div class="input-field col s12 l6">
                        <div class="label-input">Treaty Number</div>
                        <input id="TreatyNo" type="text" formControlName='TreatyNo' required class="validate"
                            autocomplete="off" placeholder="Treaty Number" />
                    </div>

                    <div class="input-field col s12 l6">
                        <div class="label-input">Fax</div>
                        <input id="Fax" type="text" formControlName='Fax' required class="validate" autocomplete="off"
                            [inputMask]="phoneInputMask" [textMask]="{mask: phoneNumberMask}" placeholder="Fax" />
                    </div>

                    <div class="divider col s12 p-m-1 b-m-1"></div>

                    <div class="input-field col s12 l6">
                        <div class="label-input">Address</div>
                        <input id="MailAddress" type="text" formControlName='MailAddress' required
                            class="materialize-textarea validate" autocomplete="off" maxlength="150"
                            placeholder="Address" />
                    </div>

                    <div class="input-field col s12 l6">
                        <div class="label-input">City</div>
                        <input id="city" type="text" formControlName='city' required class="validate" autocomplete="off"
                            placeholder="City" />
                    </div>

                    <div class="input-field col s12 l6">
                        <div class="label-input">Province</div>
                        <input id="province" type="text" formControlName='province' required class="validate"
                            autocomplete="off" placeholder="Province" />
                    </div>

                    <div class="input-field col s12 l6">
                        <div class="label-input">Postal Code</div>
                        <input id="PostalCode" type="text" formControlName='PostalCode' required
                            class="validate postalcode" autocomplete="off" [textMask]="{mask: postalCodeMask}"
                            placeholder="Postal Code" />
                    </div>
                </div>
            </form>
        </div>
    </div>

    <div class="row col s12">
        <div class="col l1 "></div>
        <div class="col s12 l10">
            <button title="Search" *ngIf="!sqldb" (click)="onUpdateMemberProfile()"
                class="col s12 btn waves-effect green" style="margin-bottom: 15px;"
                [disabled]="!addUpdateDepartmentForm.valid">
                Update Profile
            </button>
        </div>
        <div class="col l1"></div>
    </div>
</div>


<!-- Image Upload Modal -->
<div id="imageUploadModal" class="modal">
    <div class="modal-content">
      <h5>Update Profile Picture</h5>
  
      <!-- Show Selected Image Preview -->
      <div class="center">
        <img [src]="previewImg || imgLink || 'https://www.w3schools.com/howto/img_avatar.png'" class="preview-img" />
      </div>
  
      <!-- Loading Indicator -->
      <div *ngIf="isUploading" class="loading-overlay">
        <div class="preloader-wrapper active">
          <div class="spinner-layer spinner-blue-only">
            <div class="circle-clipper left"><div class="circle"></div></div>
            <div class="gap-patch"><div class="circle"></div></div>
            <div class="circle-clipper right"><div class="circle"></div></div>
          </div>
        </div>
        <p>Uploading...</p>
      </div>
  
      <!-- Upload & Delete Buttons -->
      <input type="file" accept="image/*" #fileInput (change)="handleFileUpload($event)" hidden />
      <button class="btn blue" (click)="triggerFileInput()">Choose Image</button>
      <button class="btn green" (click)="uploadSelectedImage()" [disabled]="!previewImg || isUploading">Upload</button>
      <button class="btn red" (click)="deleteProfileImage()" [disabled]="isUploading">Delete Image</button>
    </div>
  
    <div class="modal-footer">
      <button class="modal-close btn grey" [disabled]="isUploading">Cancel</button>
    </div>
  </div>
  