import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { environment } from 'src/environments/environment';
import { StaticContentService } from 'src/app/services/firebase/static-content.service';
//import { EmbedVideoService } from 'ngx-embed-video';

declare var $: any;

@Component({
  selector: 'app-archived-post',
  templateUrl: './archived-post.component.html',
  styleUrls: ['./archived-post.component.css']
})

export class ArchivedPostComponent implements OnInit {

  adminThemeColor = environment.appTheme.adminThemeColor;
  adminFontColor = environment.appTheme.adminFontColor;
  model: any[];
  iframehtml: any;
  videoId: any;

  constructor(private location: Location,
     private staticContentService: StaticContentService,
    //  private embedService: EmbedVideoService 
    ) { }

  ngOnInit() {
    this.staticContentService.getContentList().valueChanges().subscribe(x => {
      if (x) {
        this.model = x;
        this.model = this.model.filter(e => e.published === false);
        this.model.sort((a, b) => b.datePublished - a.datePublished);
        this.model.sort((a, b) => (a.index < b.index ? -1 : 1));
      }
    });
  }

  truncateHTML(text: string, limit: string): string {
    const charlimit = +limit;
    if (!text || text.length <= charlimit) {
      return text.replace(/<(?:.|\n)*?>/gm, '').replace(/&nbsp;/gm, ' ');
    }
    return text.replace(/<(?:.|\n)*?>/gm, '').replace(/&nbsp;/gm, ' ').substring(0, charlimit) + '...';
  }

  embedVideo(videoLink) {
    const videoIdMatch = videoLink.match(/(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([^&]+)|(?:https?:\/\/)?(?:www\.)?youtu\.be\/([^?]+)/);
    this.videoId = videoIdMatch ? (videoIdMatch[1] || videoIdMatch[2]) : '';
    return this.videoId;
  }

  backClicked() {
    this.location.back();
  }

}
