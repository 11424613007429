<div class="row center-align p-m-2">
  <h6><strong> My Supplemental Support of {{  months[declarationDate.getMonth()] }}</strong></h6>
</div>

<div class="row col s12">
  <div class="col l1"></div>
  <div *ngIf="list" class="row col s12 l10">
    <div class="section"></div>
    <div class="row card-panel">
      <ul class="collection">
        <li *ngFor="let e of list" class="collection-item">
          <div> <u> <a
                [routerLink]="['/member/monthly-assistance/support-request-detail',e.id]">{{e.documentName}}</a></u> <a
              class="secondary-content black-text">{{e.amount | currency}}</a></div>
      </ul>
    </div>
  </div>

  <div *ngIf="!list">
    <div class="section"></div>
    <div class="col s12 m8 l6">
      <div class="card-panel blue lighten-2 white-text">You Dont Have Any Supplemental Support For {{  months[declarationDate.getMonth()] }}</div>
    </div>
  </div>

  <div class="row col s12 l10">
    <button [ngClass]="'row btn waves-effect waves-light col s12' + themeColor " (click)="backClicked()">
      <i class="material-icons left">house</i> Back To Previous Page
    </button>
  </div>

  <div class="col l1"></div>
</div>
