import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { PollsAndSurveyService } from 'src/app/services/firebase/polls-and-survey.service';
import { environment } from 'src/environments/environment';
import { toast } from 'materialize-css';
import { Location } from '@angular/common';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { AuthService } from 'src/app/services/firebase/auth.service';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-edit-survey',
  templateUrl: './edit-survey.component.html',
  styleUrls: ['./edit-survey.component.css']
})

export class EditSurveyComponent implements OnInit {

  defaultThemeColor = environment.appTheme.themeColor;
  themeColor: any;
  buttonType: any;
  user: any;
  permissions: any[];
  isSuperAdmin = false;

  fontColor = environment.appTheme.fontColor;
  model: any;
  editedModel: any;
  surveyId = '';
  surveyForm: UntypedFormGroup;
  questionForm: UntypedFormGroup;
  questionArray: UntypedFormArray;
  answersArray: UntypedFormArray;
  questionNumber = 1;
  showMultiAnswer = false;
  answerTypes: any = [
    { 'type': 'Multiple Choice', 'helpText': 'Displays a list of answers the participant can choose from' },
    { 'type': 'Open Ended Answer', 'helpText': 'Allows participants to answer in open text format' },
    { 'type': 'Scale', 'helpText': 'Displays a scale from any range from 0 to 10' }];
  scales = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9', '10'];
  numberOfAnswers: any = ['2', '3', '4'];
  showSurveyResult = false;
  step1 = true;
  step2 = false;
  step3 = false;
  createPoll = false;

  constructor(private authService: AuthService,
    private route: ActivatedRoute, 
    private surveyService: PollsAndSurveyService, 
    private fb: UntypedFormBuilder, 
    private location: Location,
    private appSettingsService: AppSettingsService) {
      
    this.authService.getCurrentApplicantIDObservable().subscribe(
      user => {
        if (user) {
          this.user = user;
          if (user.isSuperAdmin) this.isSuperAdmin = true;
          if (user.permissions) this.permissions = user.permissions;
          else if (!this.isSuperAdmin) {
            $('.modal').modal();
            $('#permissionModal').modal('open');
          }
        }
      }
    );
    this.themeColor = this.appSettingsService.getAppThemeColor() ? this.appSettingsService.getAppThemeColor(): this.defaultThemeColor;
    this.buttonType =  this.appSettingsService.getButtonType() ? this.appSettingsService.getButtonType(): 'rectangularButton';
   }

  get surveyName() { return this.surveyForm.get('surveyName'); }
  get startDate() { return this.surveyForm.get('startDate'); }
  get endDate() { return this.surveyForm.get('endDate'); }
  get showResult() { return this.surveyForm.get('showResult'); }
  get showResultTo() { return this.surveyForm.get('showResultTo'); }
  get questions() { return this.questionForm.get('questionItems') as UntypedFormArray; }

  getAnswers(index) {
    const questions = this.questionForm.get('questionItems') as UntypedFormArray;
    return questions.controls[index].get('answers') as UntypedFormArray;
  }

  getScales(index){
    const questions = this.questionForm.get('questionItems') as UntypedFormArray;
    return questions.controls[index].get('scales');
  }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.surveyId = params.get('id');
    });

    if (this.surveyId) {
      this.surveyService.getSurveyByID(this.surveyId).valueChanges().subscribe(survey => {
        if (survey) {
          this.model = survey;
          
          this.surveyForm = this.fb.group({
            type: [{value:this.model.type, disabled: true}, [Validators.required]],
            surveyName: [this.model.name, [Validators.required]],
            startDate: [this.model.startDate, [Validators.required]],
            endDate: [this.model.endDate, [Validators.required]],
            showResult: [this.model.showResult, [Validators.required]],
            showResultTo: [this.model.showResultTo ? this.model.showResultTo : '']
          });
          if(this.model.type == 'Poll') {
            this.answerTypes = [{ 'type': 'Multiple Choice', 'helpText': 'Displays a list of answers the participant can choose from' },];
            this.createPoll = true;
          }
          if (this.model.showResult == 'yes') {
            this.onShowResultYes();
          }
          this.surveyForm.get('showResult').valueChanges.subscribe(x => {
            if (x == 'yes') {
              this.surveyForm.get('showResultTo').setValidators([Validators.required]);
            } else {
              this.surveyForm.get('showResultTo').clearValidators();
            }
            this.surveyForm.get('showResultTo').updateValueAndValidity();
          });
          const tomorrow = new Date();
          tomorrow.setDate(tomorrow.getDate() + 1);
          const endDate1 = new Date();
          endDate1.setDate(endDate1.getDate() + 30);
          setTimeout(() => {
            $('#startDate').datepicker(
              {
                format: 'dd/mmm/yyyy',
                minDate: tomorrow,
                maxDate: endDate1,
                defaultDate: tomorrow,
                setDefaultDate: tomorrow,
                yearRange: 1,
                onSelect: (date => {
                  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                  const selectedDate = new Date(date);
                  const formattedDate = selectedDate.getDate() + '/' + months[selectedDate.getMonth()] + '/' + selectedDate.getFullYear();
                  localStorage.setItem('surveyStartDate', date);
                })
              }
            );
            const startDate2 = new Date();
            startDate2.setDate(startDate2.getDate() + 2);
            const endDate2 = new Date();
            endDate2.setDate(endDate2.getDate() + 120);
            $('#endDate').datepicker(
              {
                format: 'dd/mmm/yyyy',
                minDate: startDate2,
                maxDate: endDate2,
                defaultDate: startDate2,
                setDefaultDate: startDate2,
                yearRange: 1,
                onSelect: (date => {
                  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
                  const selectedDate = new Date(date);
                  const formattedDate = selectedDate.getDate() + '/' + months[selectedDate.getMonth()] + '/' + selectedDate.getFullYear();
                  localStorage.setItem('surveyEndDate', date);
                })
              }
            );
            M.updateTextFields();
            $('select').formSelect();
            $('.tooltipped').tooltip();
            $('.collapsible').collapsible();
          }, 25);
        }
      });
    }
  }

  startDateChange() {
    if (this.surveyForm) { this.surveyForm.patchValue({ startDate: $('#startDate').val() }); }
  }

  endDateChange() {
    if (this.surveyForm) { this.surveyForm.patchValue({ endDate: $('#endDate').val() }); }
  }

  onShowResultNo() {
    this.showSurveyResult = false;
    this.showResultTo.reset();
  }

  onShowResultYes() {
    this.showSurveyResult = true;
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
      $('.collapsible').collapsible();
    }, 25);
  }

  submitSurveyInfo(value: any) {
    value.type = this.model.type;
    this.editedModel = {
      id: this.model.id,
      name: value.surveyName,
      type: value.type,
      numberOfQuestions: this.model.numberOfQuestions,
      startDate: value.startDate,
      endDate: value.endDate,
      showResult: value.showResult,
      showResultTo: value.showResultTo,
      questions: [],
      adminID: '1',
      adminName: 'Reda',
      datePosted: new Date(),
    };

    this.questionForm = this.fb.group({
      questionItems: this.fb.array([])
    });

    this.model.questions.forEach(question => {
      this.addQuestionFromModel(question);
    });

    this.step1 = false;
    this.step2 = true;

    setTimeout(() => {
      $('select').formSelect();
      $('.collapsible').collapsible();
    }, 25);
  }

  addQuestionFromModel(question: any): void {
    this.questionNumber += 1;
    this.questionArray = this.questionForm.get('questionItems') as UntypedFormArray;
    this.questionArray.push(this.createQuestionFromModel(question));
  }

  createQuestionFromModel(question: any) {
    const scales =  this.fb.group({
      start: [""],
      startLabel: [],
      end: [""],
      endLabel: []
    });
    
    let answers = this.fb.array([]);
    if(question.answerType === 'Multiple Choice'){
      question.answers.forEach(a => {
        answers.push(this.addAnswersFromModel(a.answer));
      });
    }else if(question.answerType === 'Scale'){
      scales.get('start').patchValue(question.scales.start);
      scales.get('startLabel').patchValue(question.scales.startLabel);
      scales.get('end').patchValue(question.scales.end);
      scales.get('endLabel').patchValue(question.scales.endLabel);
      setTimeout(() => {
        $('select').formSelect();
        M.updateTextFields();
        $('.collapsible').collapsible();
      }, 25); 
    }
   
    return this.fb.group({
      question: [question.question, [Validators.required]],
      answerType: [question.answerType, [Validators.required]],
      allowMultipleAnswers: [question.allowMultipleAnswers, [Validators.required]],
      answers: answers,
      scales : scales,
    });
  }

  addAnswersFromModel(value) {
    return this.fb.group({
      answer: [value, [Validators.required]]
    });
  }

  addQuestion(): void {
    this.questionNumber += 1;
    this.questionArray = this.questionForm.get('questionItems') as UntypedFormArray;
    this.questionArray.push(this.createQuestion());
    setTimeout(() => {
      $('select').formSelect();
      $('.collapsible').collapsible();
    }, 25);
  }

  createQuestion() {
    return this.fb.group({
      question: ['', [Validators.required]],
      answerType: ['', [Validators.required]],
      allowMultipleAnswers: [false, [Validators.required]],
      answers: this.fb.array([]),
      scales: this.fb.group({
        start: [''],
        startLabel: [],
        end: [''],
        endLabel: []
      }),
    });
  }

  removeFile(index: any) {
    this.questionArray = this.questionForm.get('questionItems') as UntypedFormArray;
    this.questionArray.removeAt(index);
  }

  answerTypeChange(e: any, index: any) {
    this.resetControls(index);
    const answers = this.getAnswers(index);
    if (e === 'Multiple Choice') {
      if (answers.length < 2) {
        answers.push(this.addAnswers());
        answers.push(this.addAnswers());
      } 
    }else if(e === 'Scale'){
      this.getScales(index).get('start').patchValue('1');
      this.getScales(index).get('end').patchValue('10');
    } else{}

    setTimeout(() => {
      $('select').formSelect();
      $('.collapsible').collapsible();
    }, 25);
  }

  resetControls(index){
    const answers = this.getAnswers(index);
    while (answers.length !== 0) {
        answers.removeAt(0);
      }
    const scales = this.getScales(index);
    answers.clearValidators();
    answers.updateValueAndValidity();
    scales.reset();
    scales.clearValidators();
    scales.updateValueAndValidity();
  }

  addAnswers() {
    return this.fb.group({
      answer: ['', [Validators.required]]
    });
  }

  onAddAnswer(index) {
    const answers = this.getAnswers(index);
    answers.push(this.addAnswers());
  }

  removeAnswer(question_index, answer_index) {
    const answers = this.getAnswers(question_index);
    answers.removeAt(answer_index);
  }

  submitQuestions(value: any) {
    this.editedModel.questions = [];
    let qNumber = 0;
    value.questionItems.forEach(element => {
      qNumber += 1;
      const question = {
        questionNumber: qNumber,
        question: element.question,
        answerType: element.answerType,
        allowMultipleAnswers: element.allowMultipleAnswers,
        answers: element.answers,
        scales:  element.scales
      };

      if(element.answerType === 'Scale'){
        for (var i = element.scales.start; i <= element.scales.end; i++) {
          element.answers.push({'answer': i.toString()});
        }
      }

      this.editedModel.questions.push(question);
    });

    this.editedModel.numberOfQuestions = this.editedModel.questions.length;
    this.surveyService.updateSurvey(this.editedModel).then(x => {
      toast({ html: 'Survey Successfully updated!', classes: 'green' });
      this.step2 = false;
      this.location.back();
    });
  }

  backClicked() {
    this.location.back();
  }

}
