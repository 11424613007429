import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiKeyManagerService } from './apiKeyManager.Service';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class StatsService {

  url = environment.firstNation.apiUrl + 'api/stats/';
  constructor(private httpClient: HttpClient,  private keyManager: ApiKeyManagerService) { }

  GetDemographics(): Observable<any> {
    const headers = new HttpHeaders({ 'SOCIALAPIKEY': this.keyManager.getKey() });
    return this.httpClient.get<any>(this.url + 'getdemographics', { headers });
  }

}
