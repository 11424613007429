import { Component, OnInit } from '@angular/core';
import { DepartmentService } from 'src/app/services/firebase/department.service';
import { Router } from '@angular/router';
import { trigger, transition, useAnimation } from '@angular/animations';
import { bounceIn } from 'ng-animate';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { toast } from 'materialize-css';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-department',
  templateUrl: './department.component.html',
  styleUrls: ['./department.component.css'],
  animations: [
    trigger('swing', [transition('* => *', useAnimation(bounceIn))])
  ],
})

export class DepartmentComponent implements OnInit {
  defaultThemeColor = environment.appTheme.themeColor;
  themeColor: any;
  buttonType: any;

  model: any[];
  community = environment.firstNation.name;
  userRole: any;
  isSuperAdmin = false;
  permission: any[]
  sectionName: string;
  submenus: any[];
  editName = false;

  constructor(private departmentService: DepartmentService,
    private authService: AuthService,
    private router: Router,
    private appSettingsService: AppSettingsService) {
    this.authService.getCurrentApplicantIDObservable().subscribe(user => {
      if (user) {
        this.userRole = user.role;
        if (user.isSuperAdmin) this.isSuperAdmin = true;
        if (user.permission) {
          this.permission = user.permission.submenus.find(s => s.menu === 'Departments').submenus;
        }
      }
    });
    this.themeColor = this.appSettingsService.getAppThemeColor() ? this.appSettingsService.getAppThemeColor() : this.defaultThemeColor;
    this.buttonType = this.appSettingsService.getButtonType() ? this.appSettingsService.getButtonType() : 'rectangularButton';
  }

  ngOnInit() {
    this.appSettingsService.getMenuName('MyNation').valueChanges().subscribe(menus => {
      if (menus && menus.length > 0) {
        this.submenus = menus[0].submenus;
        let menu = this.submenus.find(m => m.name == 'Departments');
        if (menu) this.sectionName = menu.displayName;
      }
    });

    this.departmentService.getDepartmentList().valueChanges().subscribe(x => {
      if (x) {
        this.model = x;
        this.model.sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1));
        // this.model = this.model.sort((a, b) => (this.parseHTML(a.displayOrder) < this.parseHTML(b.displayOrder) ? -1 : 1));

        // this.model = this.model.sort((a, b) => (this.parseHTML(a.name) < this.parseHTML(b.name) ? -1 : 1));
      }
    });
  }

  editNameClicked() {
    this.editName = true;
  }

  saveName() {
    let index = this.submenus.findIndex(m => m.name == 'Departments');
    this.submenus[index].displayName = this.sectionName;
    this.appSettingsService.UpdateMenu('MyNation', this.submenus).then((update) => {
      this.appSettingsService.updateMenuOnPublic('Departments', this.sectionName);
      this.editName = false;
      toast({ html: `${update}`, classes: 'green' });
    }).catch((error) => {
      toast({ html: `${error}`, classes: 'red' });
    });
  }


  parseHTML(html) {
    return html.replace(/<(?:.|\n)*?>/gm, '').replace(/&nbsp;/gm, ' ');
  }

  editDepartmentData(event: Event, id?: any) {
    event.preventDefault();
    this.router.navigate(['/admin/dashboard-sub-menu/manage-department'], { state: { department_id: id } });
  }

  public getInitials(departmentName: any) {
    if (departmentName) {
      departmentName = departmentName.replace(/<(?:.|\n)*?>/gm, '');
      return departmentName.substring(0, 1);
    } else { return ''; }
  }

  getPermission(departmentName) {
    // if (departmentName) {
    //   if (this.permission && this.permission.length > 0) {
    //       let p = this.permission.includes(departmentName);
    //       if (p) return true;
    //     }
    //   }
    //   return false;
    return true;
  }

  // drop(event: CdkDragDrop<string[]>) {
  //   moveItemInArray(this.model, event.previousIndex, event.currentIndex);
  // }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.model, event.previousIndex, event.currentIndex);
    // Update the display order
    this.model.forEach((item, index) => {
      item.displayOrder = index;
    });
    // Save the new order
    this.departmentService.SortDepOnServer(this.model);
  }




  navigate(item) {
    const routeMappings = {
      'AboutUs': 'admin/dashboard-sub-menu/about-us',
      'NationInfo': 'admin/dashboard-sub-menu/nation-settings',
      'Departments': 'admin/dashboard-sub-menu/department',
      'Governance': 'admin/dashboard-sub-menu/governance',
      'AreaOfInterest': 'admin/dashboard-sub-menu/areas-of-interest',
    };

    const route = routeMappings[item];
    if (route) {
      this.router.navigate([route]);
    }
  }

}
