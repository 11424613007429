import { Component, OnInit } from '@angular/core';
import { Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { ActivityModel } from 'src/app/models/ActivityModel';
import { environment } from 'src/environments/environment';
import { ActivityService } from 'src/app/services/api/activity.service';
import { toast } from 'materialize-css';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-edit-activity',
  templateUrl: './edit-activity.component.html',
  styleUrls: ['./edit-activity.component.css']
})

export class EditActivityComponent implements OnInit {

  clientID = 0;
  editActivityForm: UntypedFormGroup;
  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  model: ActivityModel;
  id = '';

  constructor(private route: ActivatedRoute, private fb: UntypedFormBuilder, private location: Location, private activityService: ActivityService) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });

    if (this.id) {
      let newId = 0;
      newId = + this.id;
     
      this.editActivityForm = this.fb.group({
        Activity: ['', [Validators.required]],
        description: ['', [Validators.required]],
      });

      this.activityService.getActivityById(newId).subscribe(x => {
        if (x) {
          this.model = x[0];
          this.editActivityForm.patchValue({ Activity: this.model.Activity });  
          this.editActivityForm.patchValue({ description: this.model.ActivityDesc }); 

          setTimeout(() => {
            $('.modal').modal();
            M.updateTextFields();
          }, 25);
        }
      });
    }
  }

  get Activity() { return this.editActivityForm.get('Activity'); }
  get description() { return this.editActivityForm.get('description'); }

  submitForm(value: any) {
    if (this.editActivityForm.valid){
      this.model.Activity = value.Activity;
      this.model.ActivityDesc = value.description;

      this.activityService.updateActivity(this.model).subscribe(x => {
        if (x) {
          toast({ html: 'Activity Successfully Updated!', classes: 'green' });
          this.location.back();
        }
      });
    }
  }

  DeleteActivity() {
    this.activityService.deleteActivity(this.model).subscribe(resp => {
      toast({ html: 'Activity Removed Successfully!', classes: 'green' });
      this.backClicked();
    });
  }

  backClicked() {
    this.location.back();
  }

}
