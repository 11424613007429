import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { ActionPlanService } from 'src/app/services/api/isets/actionPlan.service';
import { ActionPlanModel } from 'src/app/models/isets/ActionPlanModel';
import { Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import { toast } from 'materialize-css';
import { environment } from 'src/environments/environment';
import { IsetsBandsService } from 'src/app/services/api/isets/isets-bands.service';
import { DataService } from 'src/app/services/data.service';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-edit-action-plan',
  templateUrl: './edit-action-plan.component.html',
  styleUrls: ['./edit-action-plan.component.css']
})
export class EditActionPlanComponent implements OnInit {

  nationofUse = environment.firstNation.name;
  nationName = environment.firstNation.displayName;
  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  provinceOfUse = environment.firstNation.province;

  step = 1;
  ap_id: any;
  AP: any;
  client_first_name = "";
  client_last_name = "";
  client = "";
  birthdate = "";
  client_SIN = "";
  funding_type = "";
  budget = 0;
  ap_start_date = "";
  ap_status = "";
  clientForm: UntypedFormGroup;
  educationForm: UntypedFormGroup;

  provinces = [
    { name: 'Newfoundland / Labrador', code: "1" },
    { name: 'Nova Scotia', code: "2" },
    { name: 'New Brunswick', code: "3" },
    { name: 'Prince Edward Island', code: "4" },
    { name: 'Quebec', code: "5" },
    { name: 'Ontario', code: "6" },
    { name: 'Manitoba', code: "7" },
    { name: 'Saskatchewan', code: "8" },
    { name: 'Alberta', code: "9" },
    { name: 'Northwest Territories', code: "10" },
    { name: 'British Columbia', code: "11" },
    { name: 'Yukon', code: 'NT', value: "12" },
    { name: 'United States', code: "13" },
    { name: 'Other country', code: "14" },
    { name: 'Nunavut', code: "16" },
  ];

  public registerNumberMask = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  public SINMask = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  disablitys = [{ name: 'Yes', code: '1' }, { name: 'No', code: '0' }];
  aboriginal_status = [{ name: 'Registered Indian', code: '1' }, { name: 'Non-Status Indian', code: '2' }, { name: 'Metis', code: '3' }, { name: 'Inuit', code: '4' }];
  languages = [{ name: 'Indigenous Only', code: '1' },
  { name: 'English Only', code: '2' },
  { name: 'French Only', code: '3' },
  { name: 'Indigenous And English', code: '4' },
  { name: 'Indigenous And French', code: '5' },
  { name: 'English And French', code: '6' },
  { name: 'Indigenous,English,and French', code: '7' },
  { name: 'None Of The Above', code: '8' }
  ];
  genders = [{ name: 'Male', code: '1' }, { name: 'Female', code: '2' }, { name: 'Unspecified', code: '3' }];

  public dependantNumberMask = [/[0-9]/, /[0-9]/];
  //public postalCodeMask = [/[A-Za-z]/, /[0-9]/, /[A-Za-z]/, ' ', /[0-9]/, /[A-Za-z]/, /[0-9]/];
  public postalCodeMask = [/[A-Za-z]/, /[0-9]/, /[A-Za-z]/, /[0-9]/, /[A-Za-z]/, /[0-9]/];
  //public phoneNumberMask = ['(', /[0-9]/, /[0-9]/, /[0-9]/, ')', ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  public phoneNumberMask = ['1', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  bands = [];
  educations = [{ name: 'No formal education', code: '1' },
  { name: 'Up to Grade 7-8 (Sec. I = Grade 8)', code: '2' },
  { name: 'Grade 9-10 (Sec. II-III)', code: '3' },
  { name: 'Grade 11-12 (Sec. IV-V)', code: '4' },
  { name: 'Secondary School Diploma or GED', code: '5' },
  { name: 'Some post-secondary training', code: '6' },
  { name: 'Apprenticeship or trades certificate or diploma', code: '7' },
  { name: 'College, CEGEP,Other', code: '8' },
  { name: 'University certificate or diploma', code: '9' },
  { name: 'University - Bachelor Degree', code: '10' },
  { name: 'University – Master’s degree', code: '11' },
  { name: 'University – Doctorate', code: '12' }
  ];

  Barriers = [{ name: 'None', code: '1' },
  { name: 'Lack of labour force attachment', code: '2' },
  { name: 'Lack of work experience', code: '3' },
  { name: 'Lack of transportation', code: '4' },
  { name: 'Remoteness', code: '5' },
  { name: 'Language', code: '6' },
  { name: 'Education', code: '7' },
  { name: 'Economic', code: '8' },
  { name: 'Dependent(care)', code: '9' },
  { name: 'Lack of marketable skills', code: '10' },
  { name: 'Physical, emotional, or mental health', code: '11' },
  { name: 'Other barrier not listed above', code: '12' },
  ];
  EIStatus = [{ name: 'Employment insurance claimant', code: '1' },
  { name: 'Reach-back client/former claimant', code: '2' },
  { name: 'Non-insured client', code: '3' }
  ];

  EmpStatus = [{ name: 'Unemployed', code: '1' },
  { name: 'Employed-Full Time', code: '2' },
  { name: 'Employed-Part Time', code: '3' },
  { name: 'Student', code: '4' }
  ];

  MaritalStatus = [{ name: 'Married or Equivalent', code: '1' },
  { name: 'Single', code: '2' },
  { name: 'Divorced', code: '4' },
  { name: 'Widowed', code: '5' },
  { name: 'Separated', code: '6' }
  ];

  ChildCareFundings = [{ name: 'Not applicable', code: '1' },
  { name: 'FNICCI', code: '2' },
  { name: 'EI/CRF', code: '3' },
  { name: 'Provincial funding or subsidy', code: '4' },
  { name: 'No funding received', code: '5' },
  { name: 'Daycare space not available', code: '6' },
  { name: 'Assisted by family/self-funded', code: '7' }
  ];

  //ver. 2016
  /*
skillTypes=['Management Occupations', 'Business, Finance and Administration Occupations', 
'Natural and Applied Sciences and Related Occupations', 'Health Occupations',
'Occupations in Social Science, Education, Government Service and Religion', 
'Occupations in Art, Culture, Recreation and Sport',
'Sales and Service Occupations', 'Trades, Transport and Equipment Operators and Related Occupations',
'Occupations Unique to Primary Industry', 'Occupations Unique to Processing, Manufacturing and Utilities'
];
*/

  skillTypes = ['Legislative and senior management occupations',
    'Business, finance and administration occupations',
    'Natural and applied sciences and related occupations',
    'Health occupations',
    'Occupations in education, law and social, community and government services',
    'Occupations in art, culture, recreation and sport',
    'Sales and service occupations',
    'Trades, transport and equipment operators and related occupations',
    'Natural resources, agriculture and related production occupations',
    'Occupations in manufacturing and utilities'
  ];

  occupations = [];
  NOCs = [];
  selected_NOC = '';
  actionPlanModel: ActionPlanModel;
  enableNOCGroup = false;


  myDpOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'dd/mm/yyyy',
    markCurrentDay: true,
  };

  constructor(private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private location: Location,
    private actionPlanService: ActionPlanService,
    private bandService: IsetsBandsService) { }

  ngOnInit(): void {

    this.route.paramMap.subscribe(params => {
      this.ap_id = params.get('id');
    });
    console.log("ap id: ", this.ap_id);

    this.actionPlanModel = new ActionPlanModel();
    this.actionPlanModel.ap_id = this.ap_id;

    this.clientForm = this.fb.group({
      gender: ['', [Validators.required]],
      disablity: ['', [Validators.required]],
      indian_status: ['', [Validators.required]],
      language: ['', [Validators.required]],

      telephone: ['', []],
      fax: ['', []],
      email: ['', [Validators.email]],

      address: ['', []],
      city: ['', [Validators.required]],
      province: ['', [Validators.required]],    //this.provinceOfUse
      postal_code: ['', [Validators.required]],
      register_no: ['', []],
      band: ['', []],
      // marital_status: ['', [Validators.required]],
    });

    this.educationForm = this.fb.group({
      education: ['', [Validators.required]],
      edu_province: ['', [Validators.required]],
      sa_status: ['', [Validators.required]],
      emp_barrier: ['', [Validators.required]],
      ei_status: ['', [Validators.required]],
      emp_status: ['', [Validators.required]],
      emp_noc: [{ value: '', disabled: true }],
      marital_status: ['', [Validators.required]],
      dependant_no: ['', [Validators.pattern('^[0-9]*$'), Validators.min(0), Validators.max(20)]],
      childcare_required: ['', [Validators.required]],
      childcare_funding: ['', [Validators.required]],

      skill_type: ['', []],
      occupation: ['', []],
      //noc:['', []],
      goals: ['', []]
    });


    this.bandService.getBands().subscribe(x => {
      if (x) {
        this.bands = x;
        setTimeout(() => {
          $('select').formSelect();
          M.updateTextFields();
        }, 25);
      }
    });

    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);


    if (this.ap_id > 0) {
      this.actionPlanService.getActionPlan(this.ap_id).subscribe(x => {
        if (x) {
          this.AP = x[0];
          this.client_first_name = this.AP.givenname;
          this.client_last_name = this.AP.lastname;
          this.birthdate = this.AP.dob;
          this.client_SIN = this.AP.SIN;
          if (this.AP.funding_type === 0) { this.funding_type = "Non-Funded"; }
          else if (this.AP.funding_type === 1) { this.funding_type = "CRF"; }
          else if (this.AP.funding_type === 2) { this.funding_type = "EI"; }
          else if (this.AP.funding_type === 3) { this.funding_type = "FNJF"; }
          else if (this.AP.funding_type === 4) { this.funding_type = "Pending"; }

          if (this.AP.budget_amount) { this.budget = this.AP.budget_amount; }
          this.ap_start_date = this.AP.AP_Start;
          this.ap_status = this.AP.app_status;

          this.actionPlanModel.client_id = this.AP.client_id;
          this.actionPlanModel.lastname = this.AP.lastname;;
          this.actionPlanModel.givenname = this.AP.givenname;
          if (this.AP.middle_initial) { this.actionPlanModel.middle_initial = this.AP.middle_initial; } else { this.actionPlanModel.middle_initial = ''; }
          this.actionPlanModel.dob = this.AP.dob;
          this.actionPlanModel.SIN = this.AP.SIN;
          this.actionPlanModel.funding_type = this.AP.funding_type;
          //this.actionPlanModel.budget_amount = this.AP.budget_amount;

          this.actionPlanModel.ap_start_date = this.AP.AP_Start;
          this.actionPlanModel.app_status = this.AP.app_status;

          //--------------load data
          var tmp = this.genders.filter(item => Number(item.code) == this.AP.gender);
          if (tmp.length > 0) { this.clientForm.patchValue({ 'gender': tmp[0].code }); }

          tmp = this.disablitys.filter(item => Number(item.code) == this.AP.Disability);
          if (tmp.length > 0) { this.clientForm.patchValue({ 'disablity': tmp[0].code }); }

          tmp = this.aboriginal_status.filter(item => Number(item.code) == this.AP.Aboriginal_group);
          if (tmp.length > 0) { this.clientForm.patchValue({ 'indian_status': tmp[0].code }); }

          tmp = this.languages.filter(item => Number(item.code) == this.AP.lan);
          if (tmp.length > 0) { this.clientForm.patchValue({ 'language': tmp[0].code }); }

          this.clientForm.patchValue({ 'telephone': this.AP.Phone });
          this.clientForm.patchValue({ 'fax': this.AP.fax });
          this.clientForm.patchValue({ 'email': this.AP.email });

          this.clientForm.patchValue({ 'address': this.AP.ADDR });
          this.clientForm.patchValue({ 'city': this.AP.City });
          this.clientForm.patchValue({ 'postal_code': this.AP.Postal });

          tmp = this.provinces.filter(prov => Number(prov.code) == this.AP.Prov);
          if (tmp.length > 0) { this.clientForm.patchValue({ 'province': tmp[0].code }); }

          if (this.AP.band_id) { this.clientForm.patchValue({ 'register_no': this.AP.band_id }); }
          //this.clientForm.patchValue({ 'band': this.AP.band_name });
          this.clientForm.patchValue({ 'band': this.AP.band });

          tmp = this.educations.filter(item => Number(item.code) == this.AP.Education);
          if (tmp.length > 0) { this.educationForm.patchValue({ 'education': tmp[0].code }); }

          tmp = this.provinces.filter(prov => Number(prov.code) == this.AP.edu_prov);
          if (tmp.length > 0) { this.educationForm.patchValue({ 'edu_province': tmp[0].code }); }

          tmp = this.disablitys.filter(item => Number(item.code) == this.AP.SA_Status);
          if (tmp.length > 0) { this.educationForm.patchValue({ 'sa_status': tmp[0].code }); }

          tmp = this.Barriers.filter(item => Number(item.code) == this.AP.Emp_barrier);
          if (tmp.length > 0) { this.educationForm.patchValue({ 'emp_barrier': tmp[0].code }); }

          tmp = this.EIStatus.filter(item => Number(item.code) == this.AP.EI_type);
          if (tmp.length > 0) { this.educationForm.patchValue({ 'ei_status': tmp[0].code }); }

          this.educationForm.patchValue({ 'emp_status': this.AP.AP_client_status });

          //if(this.AP.NOC) {this.educationForm.patchValue({ 'emp_noc': this.AP.NOC });}
          if (this.AP.NOC) { this.selected_NOC = this.AP.NOC; }

          if (this.AP.ltgoals) { this.educationForm.patchValue({ 'goals': this.AP.ltgoals }); }

          tmp = this.MaritalStatus.filter(item => Number(item.code) == this.AP.marital_Status);
          if (tmp.length > 0) { this.educationForm.patchValue({ 'marital_status': tmp[0].code }); }

          // if(this.AP.dependants) {}
          this.educationForm.patchValue({ 'dependant_no': this.AP.dependants });

          tmp = this.disablitys.filter(item => Number(item.code) == this.AP.AP_Child_care);
          if (tmp.length > 0) { this.educationForm.patchValue({ 'childcare_required': tmp[0].code }); }

          tmp = this.ChildCareFundings.filter(item => Number(item.code) == this.AP.AP_Child_care_Funded);
          if (tmp.length > 0) { this.educationForm.patchValue({ 'childcare_funding': tmp[0].code }); }


          setTimeout(() => {
            $('select').formSelect();
            M.updateTextFields();
          }, 35);

        }
      });
    }
  }


  get lastname() { return this.clientForm.get('lastname'); }
  get firstname() { return this.clientForm.get('firstname'); }
  get initial() { return this.clientForm.get('initial'); }
  get SIN() { return this.clientForm.get('SIN'); }
  get dob() { return this.clientForm.get('dob'); }
  get disablity() { return this.clientForm.get('disablity'); }
  get indian_statusl() { return this.clientForm.get('indian_status'); }
  get language() { return this.clientForm.get('language'); }
  get gender() { return this.clientForm.get('gender'); }

  get telephone() { return this.clientForm.get('telephone'); }
  get fax() { return this.clientForm.get('fax'); }
  get email() { return this.clientForm.get('email'); }

  get address() { return this.clientForm.get('address'); }
  get city() { return this.clientForm.get('city'); }
  get province() { return this.clientForm.get('province'); }
  get postal_code() { return this.clientForm.get('postal_code'); }
  get register_no() { return this.clientForm.get('register_no'); }
  get band() { return this.clientForm.get('band'); }

  get education() { return this.educationForm.get('education'); }
  get edu_province() { return this.educationForm.get('edu_province'); }
  get sa_status() { return this.educationForm.get('sa_status'); }

  get emp_barrier() { return this.educationForm.get('emp_barrier'); }
  get ei_status() { return this.educationForm.get('ei_status'); }
  get emp_status() { return this.educationForm.get('emp_status'); }
  get marital_status() { return this.educationForm.get('marital_status'); }
  get dependant_no() { return this.educationForm.get('dependant_no'); }
  get childcare_required() { return this.educationForm.get('childcare_required'); }
  get childcare_funding() { return this.educationForm.get('childcare_funding'); }

  get skill_type() { return this.educationForm.get('skill_type'); }
  get occupation() { return this.educationForm.get('occupation'); }
  //get noc() { return this.educationForm.get('noc'); }
  get goals() { return this.educationForm.get('goals'); }


  onBirthDateChange(event: IMyDateModel): void {
    if (this.clientForm) { this.clientForm.patchValue({ start_date: event.singleDate.formatted }); }
  }


  submitClient(values: any) {

    this.actionPlanModel.gender = values.gender;
    this.actionPlanModel.disability = values.disability;
    this.actionPlanModel.aboriginal_group = values.indian_status;
    this.actionPlanModel.lan = values.language;
    this.actionPlanModel.telephone = values.telephone;
    this.actionPlanModel.fax = values.fax;
    this.actionPlanModel.email = values.email;

    this.actionPlanModel.address = values.address;
    this.actionPlanModel.city = values.city;
    this.actionPlanModel.province = values.province;
    this.actionPlanModel.postal_code = values.postal_code;
    this.actionPlanModel.band_name = values.band;
    this.actionPlanModel.band_id = values.register_no;

    this.onNext();
  }


  submitEducation(values: any) {
    this.actionPlanModel.marital_Status = values.marital_status;
    //this.actionPlanModel.minority = values.minority;

    if (values.dependant_no) { this.actionPlanModel.dependants = Number(values.dependant_no); } else { this.actionPlanModel.dependants = 0; }
    this.actionPlanModel.child_care_required = values.childcare_required;
    this.actionPlanModel.child_care_Funded = values.childcare_funding;
    this.actionPlanModel.education = values.education;
    this.actionPlanModel.edu_province = values.edu_province;

    this.actionPlanModel.sa_status = values.sa_status;
    this.actionPlanModel.emp_barrier = values.emp_barrier;
    this.actionPlanModel.ei_type = values.ei_status;
    this.actionPlanModel.AP_client_status = values.emp_status;
    this.actionPlanModel.NOC = this.selected_NOC;    //values.emp_noc;
    this.actionPlanModel.goals = values.goals;

    this.actionPlanModel.ph_ext = '';
    this.actionPlanModel.office = '';

    setTimeout(() => {
      $('.modal').modal();
      $('select').formSelect();
      $('#QuestionModal').modal('open');
      M.updateTextFields();
    }, 25);

    // this.actionPlanService.updateActionPlan(this.actionPlanModel).subscribe(x => {
    //   if (x) {
    //     toast({ html: 'Application Successfully Saved!', classes: 'green' });
    //     this.location.back();
    //   }
    // });
  }

  ReadytoSubmitYes() {
    $('#QuestionModal').modal('close');

    this.actionPlanService.updateActionPlan(this.actionPlanModel).subscribe(x => {
      if (x) {
        toast({ html: 'Funding Request is Successfully Updated!', classes: 'green' });
        this.location.back();
      }
    });
  }


  ReadytoSubmitNo() {
    $('#QuestionModal').modal('close');
  }

  onEmploymentStatusChange(value) {
    this.educationForm.get('emp_noc').reset();
    if (value == '2' || value == "3") {

      setTimeout(() => {
        $('#AddNOCModal').modal('open');
        $('.collapsible').collapsible();
        $('select').formSelect();
        M.updateTextFields();
      }, 25);
    }
  }

  onEmploymentStatusChange_new(value) {
    /*
    this.educationForm.get('emp_noc').reset();
    if (value == '2' || value == "3" ) {
    
      this.enableNOCGroup = true;   

      this.actionPlanService.getNOCGroups().subscribe(x => { 
        if(x){ 
          this.occupations = x;
          //console.log("NOC Groups: ", this.occupations);

           setTimeout(() => {
            //$('#AddNOCModal').modal('open');
            $('.collapsible').collapsible();
            $('select').formSelect();
            M.updateTextFields();
          }, 25);
        }
      });
    } 
    */
  }

  //ver. 2022
  onSkillTypesChange(value) {

    this.occupations = [];
    const tmp = value;
    if (tmp === 'Legislative and senior management occupations') {   //0 
      this.load_subgroupA('0');

    } else if (tmp === 'Business, finance and administration occupations') {  //1
      this.load_subgroupA('1');

    } else if (tmp === 'Natural and applied sciences and related occupations') {  //2
      this.load_subgroupA('2');

    } else if (tmp === 'Health occupations') {   //3
      this.load_subgroupA('3');

    } else if (tmp === 'Occupations in education, law and social, community and government services') {  //4
      this.load_subgroupA('4');

    } else if (tmp === 'Occupations in art, culture, recreation and sport') {   //5
      this.load_subgroupA('5');

    } else if (tmp === 'Sales and service occupations') {   //6
      this.load_subgroupA('6');

    } else if (tmp === 'Trades, transport and equipment operators and related occupations') {  //7
      this.load_subgroupA('7');

    } else if (tmp === 'Natural resources, agriculture and related production occupations') {   //8
      this.load_subgroupA('8');

    } else if (tmp === 'Occupations in manufacturing and utilities') { //9
      this.load_subgroupA('9');

    } else { this.occupations = []; }

    setTimeout(() => {
      $('.collapsible').collapsible();
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }

  onOccupationChange(value) {
    this.selected_NOC = value;
    setTimeout(() => {
      $('.collapsible').collapsible();
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }

  load_subgroupA(sql) {
    this.actionPlanService.getNOCGroups(sql).subscribe(x => {
      if (x) {
        this.occupations = x;
        setTimeout(() => {
          $('select').formSelect();
          M.updateTextFields();
        }, 25);
      }
    });
  }

  onNext() {
    this.step += 1;

    setTimeout(() => {
      $('select').formSelect();
      $('.collapsible').collapsible();
      $('.tooltipped').tooltip();
      $('.modal').modal();
      // $('.tabs').tabs();
      M.updateTextFields();
    }, 25);

    window.scrollTo(0, 0);
  }

  onBack() {
    if (this.step > 1) { this.step -= 1; }
    setTimeout(() => {
      $('select').formSelect();
      $('.collapsible').collapsible();
      $('.tooltipped').tooltip();
      $('.modal').modal();
      //$('.tabs').tabs();
      M.updateTextFields();
    }, 25);
    window.scrollTo(0, 0);
  }

  backClicked() {
    $('#messageModal').modal('close');
    this.location.back();
  }

  backToServices() {
    this.location.back();
  }

  showMessage() {
    setTimeout(() => {
      M.updateTextFields();
      $('.modal').modal();
      $('#messageModal').modal('open');
      $('.tooltipped').tooltip();
    }, 25);
  }
}
