import { Component, OnInit } from '@angular/core';
import { Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { CertificateModel } from 'src/app/models/certificateModel';
import { environment } from 'src/environments/environment';
import { CertificateService } from 'src/app/services/api/certificate.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { toast } from 'materialize-css';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-add-certificate',
  templateUrl: './add-certificate.component.html',
  styleUrls: ['./add-certificate.component.css']
})

export class AddCertificateComponent implements OnInit {

  clientID = 0;
  addCertificateForm: UntypedFormGroup;
  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  sqldb = environment.firstNation.SQLDB;
  model: CertificateModel;
  courses: any = ['WHMIS', 'H2S Alive', 'Chainsaw Safety', 'Confined Entry', 'Petroleum Safety Training', 'Standard First Aid'];

  constructor(private authService: AuthService, 
    private fb: UntypedFormBuilder, 
    private location: Location,
    private router: Router, 
    private certificateService: CertificateService) { }

  ngOnInit(): void {
    if (localStorage.getItem('applicantID')) {
      this.clientID = Number(localStorage.getItem('applicantID'));
    } else {
      this.clientID = this.authService.getCurrentApplicantID();
    }

    this.addCertificateForm = this.fb.group({
      course: ['', [Validators.required]],
      Institution: ['', [Validators.required]],
      start_date: ['', [Validators.required]],
      completion_date: ['', []],
      expiry_date: ['', [Validators.required]],
      description: ['', []],
    });

    this.model = new CertificateModel();
    this.model.client_id = this.clientID;

    setTimeout(() => {
      $('select').formSelect();
      $('.datepicker1').datepicker({
        format: 'dd/mmm/yyyy',
        defaultDate: new Date(),
        setDefaultDate: new Date(),
        yearRange: 20,
        minDate: new Date(new Date().getFullYear() - 20, 0, 1),
        maxDate: new Date(new Date().getFullYear() + 1, 12, 31),
        onSelect(e) {
          // localStorage.setItem('selectedDate', e);
        },
      });

      $('.datepicker2').datepicker({
        format: 'dd/mmm/yyyy',
        defaultDate: new Date(),
        //setDefaultDate: new Date(),
        yearRange: 20,
        minDate: new Date(new Date().getFullYear() - 20, 0, 1),
        maxDate: new Date(new Date().getFullYear() + 1, 12, 31),
        onSelect(e) {
          //localStorage.setItem('selectedDate', e);
        },
      });

      const selectedDate = new Date();
      selectedDate.setDate(selectedDate.getDate() + 30);
      $('.datepicker3').datepicker({
        format: 'dd/mmm/yyyy',
        defaultDate: selectedDate,
        setDefaultDate: selectedDate,
        yearRange: 20,
        minDate: new Date(new Date().getFullYear() - 20, 0, 1),
        maxDate: new Date(new Date().getFullYear() + 1, 12, 31),
        onSelect(e) {
          //  localStorage.setItem('selectedDate', e);
        },
      });
      M.updateTextFields();
    }, 25);
  }

  get course() { return this.addCertificateForm.get('course'); }
  get Institution() { return this.addCertificateForm.get('Institution'); }
  get start_date() { return this.addCertificateForm.get('start_date'); }
  get completion_date() { return this.addCertificateForm.get('completion_date'); }
  get expiry_date() { return this.addCertificateForm.get('expiry_date'); }
  get description() { return this.addCertificateForm.get('description'); }

  startDateChange() {
    if (this.addCertificateForm) { this.addCertificateForm.patchValue({ start_date: $('#start_date').val() }); }

    setTimeout(() => {
      $('.datepicker1').datepicker({
        format: 'dd/mmm/yyyy',
        defaultDate: new Date(),
        //setDefaultDate: new Date(),
        yearRange: 20,
        minDate: new Date(new Date().getFullYear() - 20, 0, 1),
        maxDate: new Date(new Date().getFullYear() + 1, 12, 31),
        onSelect(e) {
          // localStorage.setItem('selectedDate', e);
        },
      });
    }, 25);
  }

  completedDateChange() {
    if (this.addCertificateForm) { this.addCertificateForm.patchValue({ completion_date: $('#completion_date').val() }); }
    setTimeout(() => {
      $('.datepicker2').datepicker({
        format: 'dd/mmm/yyyy',
        defaultDate: new Date(),
        yearRange: 20,
        minDate: new Date(new Date().getFullYear() - 20, 0, 1),
        maxDate: new Date(new Date().getFullYear() + 1, 12, 31),
        onSelect(e) {
          //localStorage.setItem('selectedDate', e);
        },
      });
    }, 25);
  }

  expiryDateChange() {
    if (this.addCertificateForm) { this.addCertificateForm.patchValue({ expiry_date: $('#expiry_date').val() }); }
    setTimeout(() => {
      const selectedDate = new Date();
      selectedDate.setDate(selectedDate.getDate() + 30);
      $('.datepicker3').datepicker({
        format: 'dd/mmm/yyyy',
        defaultDate: selectedDate,
        yearRange: 20,
        minDate: new Date(new Date().getFullYear() - 20, 0, 1),
        maxDate: new Date(new Date().getFullYear() + 1, 12, 31),
        onSelect(e) {
          //  localStorage.setItem('selectedDate', e);
        },
      });
    }, 25);
  }

  submitForm(value: any) {
    if (this.addCertificateForm.valid) {
      this.model.course = value.course;
      this.model.institution = value.Institution;
      this.model.start_date = value.start_date;
      this.model.completion_date = value.completion_date;
      if (value.completion_date === '') {
        this.model.completion_date = null;
      }
      this.model.expiry_date = value.expiry_date;
      this.model.description = value.description;
      /*
      var d_arr = value.completion_date.split("/");
      var completion_date_d = new Date(d_arr[1]+"-"+d_arr[0]+"-"+d_arr[2]);
      var completion_date = new Date(completion_date_d.getFullYear(), completion_date_d.getMonth(), completion_date_d.getDate());
      this.model.completion_date = completion_date; */

      if (this.sqldb) {
        this.certificateService.saveCertificate(this.model).subscribe(x => {
          if (x) {
            toast({ html: 'Certificate Successfully Added!', classes: 'green' });
            this.location.back();
          }
        });
      } else {
        /*
        this.certificateFirebaseService.saveCertificate(this.model).then(x => {
          toast({ html: 'New Certificate Successfully Added!', classes: 'green' });
          this.backClicked();
        });
        */
      }
    }
  }

  backClicked() {
    if (localStorage.getItem('certificate_call_from') == 'case_plan') {
      this.router.navigate(['/member/member-main-home/profile/case-plan']);
    } else if (localStorage.getItem('certificate_call_from') == 'certificate') {
      this.router.navigate(['/member/member-main-home/profile/certificate']);
    } else { this.location.back(); }
  }
}