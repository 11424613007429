import { Component, OnInit, Renderer2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
// import { FCMpushService } from './services/firebase/FCMpush.service';
// import { Platform } from '@angular/cdk/platform';
import { SwUpdate } from '@angular/service-worker';
import { environment } from 'src/environments/environment';
import { Location } from "@angular/common";
import { filter } from 'rxjs/internal/operators/filter';
// import { FCMpushService } from './services/firebase/FCMpush.service';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { map } from 'rxjs/operators';
import { AuthService } from './services/firebase/auth.service';
import { ImageUploaderResponseModel } from './models/ImageModel';

declare var M: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit {

  title = 'My Nation App';
  displayName = environment.firstNation.displayName;
  route = '';
  model: any;
  navbarOption: any;
  themeColor: string;
  buttonOption: string;
  directSignup: boolean;
  chiefTitle: any;
  councillorTitle: any;
  screenType:any;

  // menuOptions: any;
  mobileView: any;
  tabletView: any;
  desktopView: any;
  //defaultThemeColor = environment.appTheme.themeColor;
  defaultThemeColor = '#000000';
  landingModel: any;
  nationLogo: string;
  rightOrLeftSidebar: boolean;


  bodyBgColor: any;
  darkOption:any;
  setting: any;
  defaultBgColor = '#ffffff';
  defaultdarkColor= 'lightMode';

  isLogged = false;
  role = '';
  isLoginPage: boolean;
  isRegisterPage: boolean;
  islandingEditor:boolean;
 
  constructor(private swUpdate: SwUpdate,
    private router: Router,
    private location: Location,
    public settingsService: AppSettingsService,
    public appSettingsService: AppSettingsService,
    public appSettingService: AppSettingsService,
    private renderer: Renderer2,
    private authService: AuthService) {

    this.authService.afAuth.authState.subscribe(auth => {
      if (auth) {
        // this.fireMember = this.authService.getCurrentApplicantIDObservable();
        this.isLogged = true;
      }
    });

    //   this.fcmPushService.onMessageReceived();
    //   // to check which route when navigating
    // router.events.pipe(filter(event => event instanceof NavigationEnd)
    // ).subscribe((event: NavigationEnd) => {
    //   if (location.path() != "") {
    //     this.route = location.path();
    //   }
    // });

    // =============================================   remove once added to app-menu =============
    // this.appSettingService.getAppSetting().valueChanges().subscribe(
    //   appSettings => {
    //     if (this.isLogged && this.role === 'Admin') {
    //       if (appSettings && appSettings.length > 0) {
    //         console.log('app setting: ', appSettings);
    //         this.setting = appSettings[0];
    //         this.themeColor = this.setting.themeColor ? this.setting.themeColor : this.defaultThemeColor;
    //         this.bodyBgColor = this.setting.bodyBgColor ? this.setting.bodyBgColor : this.defaultBgColor;
    //       } else {
    //         this.themeColor = this.defaultThemeColor;
    //         this.bodyBgColor = this.defaultBgColor;
    //       }
    //     }
    //     else {
    //       return '#ffffff'; // Default background color
    //     }
    //   });
    // =============================================   remove once added to app-menu =============


    const available = swUpdate.versionUpdates.pipe(
      filter(evt => evt.type === 'VERSION_READY'),
      map(evt => ({
        type: 'UPDATE_AVAILABLE'
      })),
    );

    available.subscribe(up => {
      if (up.type === 'UPDATE_AVAILABLE') {
        if (confirm('New update available. \n Please click OK to install and run the new version of ' + this.displayName + ' App ?')) {
          window.location.reload();
        } else {
          window.location.reload();
        }
      }

    });
  }

  // constructor(private platform: Platform, private fcmPushService: FCMpushService) {
  //   if (this.platform.ANDROID || this.platform.IOS) {
  //     this.fcmPushService.onMessageReceived();
  //   }
  // }

  //  constructor(){}

  ngOnInit() {


    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        // Check if the current route is the login page
        this.isLoginPage = this.router.url === '/auth/login';
        this.isRegisterPage = this.router.url === '/registration-request';
        this.islandingEditor = this.router.url === '/admin/landing-editor-page';
      
      }
    });

    // this.changeBackgroundColor('#ff0000');
    //   this.router.events.subscribe((evt) => {
    //     if (!(evt instanceof NavigationEnd)) {
    //         return;
    //     }
    //     window.scrollTo(0, 0);
    // });

    // this.appSettingService.getAppSetting().valueChanges().subscribe(
    //   appSettings => {
    //     if (appSettings && appSettings.length > 0) {
    //       this.setting = appSettings[0];
    //       this.themeColor = this.setting.themeColor ? this.setting.themeColor : this.defaultThemeColor;
    //       this.bodyBgColor = this.setting.bodyBgColor ? this.setting.bodyBgColor : this.defaultBgColor;

    //     } else {
    //       this.themeColor = this.defaultThemeColor;
    //       this.bodyBgColor = this.defaultBgColor;
    //     }

    //     if (!this.isLogged) document.body.style.backgroundColor = this.bodyBgColor;

    //   });

    this.appSettingService.getAppSetting().valueChanges().subscribe(
      appSettings => {
        if (appSettings && appSettings.length > 0) {
          this.setting = appSettings[0];
          this.themeColor = this.setting.themeColor ? this.setting.themeColor : this.defaultThemeColor;
          this.bodyBgColor = this.setting.bodyBgColor ? this.setting.bodyBgColor : this.defaultBgColor;
          this.darkOption = this.setting.darkOption ? this.setting.darkOption : this.defaultdarkColor;

          console.log('these is the dark' , this.darkOption)

        } else {
          this.themeColor = this.defaultThemeColor;
          this.bodyBgColor = this.defaultBgColor;
          this.darkOption = this.defaultBgColor;
        }
    
        if (this.isLogged) {
          // If the user is logged in, set the background color to white (#FFFFFF)
          document.body.style.backgroundColor = '#FFFFFF';
        } else {
          // If the user is not logged in, set the background color based on appSettings
          document.body.style.backgroundColor = this.bodyBgColor;
        }

        document.body.classList.remove('darkMode');

        // Add dark mode class if darkOption is 'darkMode'
        if (this.darkOption === 'darkMode') {
          document.body.classList.add('darkMode');
        }
      
    });
    

    this.settingsService.getAppSetting().valueChanges().subscribe(
      appSettings => {
        if (appSettings && appSettings.length > 0) {
          this.model = appSettings[0];
          this.themeColor = this.model.themeColor ? this.model.themeColor : this.defaultThemeColor;
          this.buttonOption = this.model.buttonOption;
          this.directSignup = this.model.directSignup;
          this.chiefTitle = this.model.chiefTitle;
          this.councillorTitle = this.model.councillorTitle;

          this.navbarOption = this.model.navbarOption;
          if (this.navbarOption) {
            this.mobileView = this.model.mobileView;
            this.tabletView = this.model.tabletView;
            this.desktopView = this.model.desktopView;
          } else {
            this.mobileView = {
              menuPosition: 'bottom',
              numberOfMenus: 5
            };
            this.tabletView = {
              menuPosition: 'right',
            };
            this.desktopView = {
              menuPosition: 'left',
              numberOfMenus: 5
            };
          }
          this.settingsService.setAppThemeColor(this.themeColor);
          this.settingsService.setButtonType(this.buttonOption);
          this.settingsService.setDirectSignUp(this.directSignup);
          this.settingsService.setGovTitle({chiefTitle: this.chiefTitle, councillorTitle: this.councillorTitle});
          this.screenType = this.appSettingsService.getScreenType() ? this.appSettingsService.getScreenType() : 'lightMode';
        } else {
          this.themeColor = this.defaultThemeColor;
          this.buttonOption = 'rectangularButton';
          this.mobileView = {
            menuPosition: 'bottom',
            numberOfMenus: 5
          };
          this.tabletView = {
            menuPosition: 'right',
          };
          this.desktopView = {
            menuPosition: 'right',
            numberOfMenus: 5
          };
        }
      }
    );

    this.settingsService.getLandingPageSettings().valueChanges().subscribe(
      lpSettings => {
        if (lpSettings) {
          if (lpSettings.length > 0) {
            this.landingModel = lpSettings[0];
            this.nationLogo = this.landingModel.logo;
          }
        }
      }
    );
  }

  changeBackgroundColor(color: string): void {
    this.renderer.setStyle(document.body, 'background-color', color);
  }

  back() {
    this.location.back();
  }
  forward() {
    this.location.forward();
  }
  
  imageChangedEvent: any;
  useBase64 = false;

  onImageUpload(response: ImageUploaderResponseModel) {
    this.imageChangedEvent = 'data:image/jpeg;base64,' + response.imgBase64;
    this.useBase64 = true;
  }

  processCroppedImage(croppedImage: string) {
    // Handle cropped image here (e.g., save, display, etc.)
  }

  closeCroppingModal() {
    this.imageChangedEvent = '';
    this.useBase64 = false;
  }


  toggleDarkMode() {
    this.appSettingService.getScreenType();
  }
}
