<div class="container-pi">
    <div class="">
        <h4 class="title-section1 title-member  icon-texts"><span class="material-symbols-outlined back-icon p-r-1"
                (click)=" backClicked()">
                arrow_circle_left
            </span>PSE Application</h4>
    </div>

    <main class="StickyContent white row">
        <div class="row col s12">
            <div class="col s12 l10">

                <div *ngIf="step0">
                    <ul class="row collection with-header">
                        <li class="collection-header">
                            <h6>Please Select Fiscal Year</h6>
                        </li>
                        <div *ngFor="let year of schoolPeriods">
                            <li class="collection-item" (click)="newYearSelected(year)">
                                <div>
                                    <strong class="blue-text"> {{ year }} </strong><a (click)="newYearSelected(year)"
                                        class="secondary-content"><i class="material-icons"></i>Select </a>
                                </div>
                            </li>
                        </div>
                    </ul>

                    <div class="row">
                        <a [ngClass]="'at-btn col s12 btn waves-effect ' + themeColor" (click)="onMyBack()"><i
                                class="material-icons left ">house</i> Back To Home Page</a>
                    </div>
                </div>

                <div class="row" *ngIf="step == 0">
                    <div class="row card-panel">
                        <div class="row center" *ngIf="logo">
                            <img class="responsive-img" width="150px" height="150px" [src]="logo" alt="pse image">
                        </div>

                        <div class="row center" *ngIf="!logo">
                            <img *ngIf="nationofUse === 'Demo'" class="responsive-img" width="150px" height="150px"
                                src="../../../../assets/icons/Demo/Logo-512x512.png">
                            <img *ngIf="nationofUse === 'Pbcn'" class="responsive-img" width="150px" height="150px"
                                src="../../../../assets/icons/PBCN/pbcn256x256.png">
                            <img *ngIf="nationofUse === 'Batc'" class="responsive-img" width="150px" height="150px"
                                src="../../../../assets/icons/BATC/batc256x256.png">
                            <img *ngIf="nationofUse === 'DeneTha'" class="responsive-img" width="150px" height="150px"
                                src="../../../../assets/icons/Denetha/DeneTha[1916].png">
                            <img *ngIf="nationofUse === 'Montana'" class="responsive-img" width="150px" height="150px"
                                src="../../../../assets/icons/Montana/PlayStore-Icon[2035].png">
                            <img *ngIf="nationofUse === 'LouisBull'" class="responsive-img" width="150px" height="150px"
                                src="../../../../assets/icons/LuisBull/luisBull.jpg">
                            <img *ngIf="nationofUse === 'Lrrcn'" class="responsive-img" width="150px" height="150px"
                                src="../../../../assets/icons/LRRCN/lrrcn384x384.jpg">
                            <img *ngIf="nationofUse === 'Oneida'" class="responsive-img" width="150px" height="150px"
                                src="../../../../assets/icons/Oneida/Logo-512x512.png">
                        </div>

                        <div class="row col s12">
                            <h5 class="center-align" style="text-transform: uppercase;"><strong>{{nationName}} <br />
                                    POST-SECONDARY STUDENT SUPPORT PROGRAM APPLICATION FORM – Continuing
                                    Student</strong>
                            </h5>
                            <ul class="center-align" *ngIf="nationINFO">
                                <li>{{nationINFO.address + ' ' + nationINFO.Town + ' ' + nationINFO.Postal + ' ' +
                                    nationINFO.Prov}}
                                </li>
                                <li><strong>Phone: </strong> {{nationINFO.telephone}}</li>
                                <li *ngIf="nationINFO.fax"><strong>Fax: </strong> {{nationINFO.fax}}</li>
                            </ul>
                        </div>

                        <div class="col s12">
                            <p><strong>Fully completed and signed original</strong> application forms and related
                                documentation
                                must be
                                received at the Post-Secondary Office by the following deadline dates: </p>
                            <p class="center-align"><strong>June 15th for Fall Semester (September)
                                    commencement</strong></p>
                            <p class="center-align"><strong>March 31st for Intersession and Summer school
                                    commencement</strong>
                            </p>
                            <p class="center-align">**All continuing students must apply for Fall/Winter and Spring, if
                                needed.
                            </p>
                            <p><strong>PLEASE NOTE: </strong>Faxed applications will not be accepted for final approval
                                (must be
                                followed by original application). Scanned and emailed applications will be accepted.
                                Late applications will not be considered for funding.
                                Incomplete applications will not be accepted
                            </p>

                            <ul class="collection with-header">
                                <li class="collection-header">
                                    <h6><strong>APPLICATIONS WILL NOT BE CONSIDERED WITHOUT THE FOLLOWING
                                            DOCUMENTS:</strong>
                                    </h6>
                                </li>
                                <li class="collection-item">
                                    <p><strong>PLEASE CHECK OFF DOCUMENTATION ATTACHED:</strong></p>
                                </li>
                                <li class="collection-item">Transcript/Marks (Post-Secondary)</li>
                                <li class="collection-item">Letter of Acceptance (If new program)</li>
                                <li class="collection-item">Registration of classes</li>
                                <li class="collection-item">Copy of Hospitalization Card ( for new Dependents)</li>
                                <li class="collection-item">Copy of Treaty/Status card</li>
                                <li class="collection-item">Banking information for direct deposit</li>
                                <li class="collection-item">Letter of Intent (Future Goals and Academic plans)</li>
                            </ul>
                        </div>
                    </div>

                    <div class="row">
                        <button class="col s12 btn green" type="button" style="margin-top: 15px; margin-bottom: 15px"
                            *ngIf="create_new_app" (click)="start_application()">Start Your Application
                        </button>
                        <button class="col s12 btn green" type="button" style="margin-top: 15px; margin-bottom: 15px"
                            *ngIf="update_app" (click)="start_application()">Amend Your Application
                        </button>
                        <button class="col s12 btn  red" type="button" style="margin-top: 15px; margin-bottom: 15px"
                            *ngIf="disable_app">Your Application is Approved & Locked
                        </button>

                        <div class="row">
                            <!-- <a [ngClass]="'at-btn col s12 btn waves-effect ' + themeColor" (click)="backClicked()"><i class="material-icons left ">house</i> Back To Home Page</a> -->
                            <a [ngClass]="'at-btn col s12 btn waves-effect ' + themeColor" (click)="onMyBack1()"><i
                                    class="material-icons left ">house</i> Back To Home Page</a>
                        </div>
                    </div>
                </div>

                <div class="row" *ngIf="step > 0">

                    <div class="row" *ngIf="step == 1">
                        <br />
                        <div class="row grey lighten-3 black-text">
                            <h6 class="center-align">Part <span class="red-text">A</span> : Student Information</h6>
                        </div>

                        <form class="row col s12" [formGroup]="studentInfoForm"
                            (ngSubmit)="submitStudentInfo(studentInfoForm.value)">

                            <div class="row card-panel">
                                <div class="input-field col s12 m6">
                                    <i class="material-icons prefix green-text">badge</i>
                                    <input id="name" type="text" formControlName="name"
                                        style="text-transform: capitalize;">
                                    <label for="name">Name</label>
                                </div>

                                <div class="input-field col s12 m6">
                                    <i class="material-icons prefix blue-text">calendar_month</i>
                                    <input id="dob" type="text" formControlName="dob"
                                        style="text-transform: capitalize;">
                                    <label for="dob">Date Of Birth</label>
                                </div>
                            </div>

                            <div class="row">
                                <h6 class=""><strong>MAILING ADDRESS: </strong></h6>
                            </div>
                            <div class="row card-panel">

                                <div class="row">
                                    <div class="input-field col s12 m6">
                                        <i class="material-icons prefix orange-text">home</i>
                                        <input id="address" type="text" formControlName="address" class="validate"
                                            style="text-transform: capitalize;" style="text-transform: capitalize;"
                                            maxlength="80">
                                        <label for="address">P.O.Box/Address</label>
                                    </div>

                                    <div class="input-field col s12 m6">
                                        <i class="material-icons prefix purple-text">location_city</i>
                                        <input id="city" type="text" formControlName="city" class="validate"
                                            style="text-transform: capitalize;" style="text-transform: capitalize;"
                                            maxlength="50">
                                        <label for="city">City<span class="red-text point">*</span></label>
                                        <div *ngIf="(city.dirty || city.touched) && city.invalid">
                                            <small *ngIf="city.errors.required" class="red-text">City is
                                                required</small>
                                        </div>
                                    </div>

                                    <div class="col s12 m6">
                                        <label for="province">Province<span class="red-text point">*</span></label>
                                        <select id="province" formControlName="province"
                                            class="validate browser-default">
                                            <option *ngFor="let p of provinces" [value]="p.code">{{p.name}}</option>
                                        </select>
                                    </div>

                                    <div class="input-field col s12 m6">
                                        <i class="material-icons prefix teal-text">local_post_office</i>
                                        <input id="postal_code" [textMask]="{mask: postalCodeMask, guide: true}"
                                            type="text" formControlName="postal_code" class="validate"
                                            style='text-transform:uppercase'>
                                        <label for="postal_code">Postal Code<span
                                                class="red-text point">*</span></label>
                                        <div *ngIf="(postal_code.dirty || postal_code.touched) && postal_code.invalid">
                                            <small *ngIf="postal_code.errors.required" class="red-text"> The Postal Code
                                                is
                                                required.
                                            </small>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <h6 class=""><strong>OTHER REQUIRED INFORMATION: </strong> </h6>
                            </div>
                            <div class="row card-panel">

                                <div class="row">
                                    <div class="input-field col s12 m6">
                                        <i class="material-icons prefix pink-text text-darken-3">phone</i>
                                        <input id="home_number" [textMask]="{mask: phoneNumberMask, guide: true}"
                                            type="text" formControlName="home_number" class="validate">
                                        <label for="home_number">Home Phone #</label>
                                    </div>

                                    <div class="input-field col s12 m6">
                                        <i class="material-icons prefix blue-text">phone_iphone</i>
                                        <input id="mobile" [textMask]="{mask: phoneNumberMask, guide: true}" type="text"
                                            formControlName="mobile" class="validate">
                                        <label for="mobile">Mobile Number<span class="red-text point">*</span></label>
                                        <div *ngIf="(mobile.dirty || mobile.touched) && mobile.invalid">
                                            <small *ngIf="mobile.errors.required" class="red-text">Mobile Number is
                                                Required.</small>
                                        </div>
                                    </div>

                                    <div class="input-field col s12 m6">
                                        <i class="material-icons prefix blue-text">tag</i>
                                        <input id="HealthCareNo" type="text" formControlName="HealthCareNo"
                                            class="validate" [textMask]="{mask: SINMask, guide: true}"
                                            [readonly]="hasHealthCareNo">
                                        <label for="HealthCareNo">Health Card No </label>
                                    </div>

                                    <div class="input-field col s12 m6">
                                        <i class="material-icons prefix green-text">tag</i>
                                        <input id="isn_no" type="text" formControlName="isn_no" class="validate"
                                            [textMask]="{mask: isnMask, guide: true}" [readonly]="hasISN">
                                        <label for="isn_no">Indian Status Number</label>
                                    </div>

                                    <div class="input-field col s12 m6">
                                        <i class="material-icons prefix purple-text text-lighten-3">tag</i>
                                        <input id="SIN" type="text" formControlName="SIN" class="validate"
                                            [textMask]="{mask: SINMask, guide: true}" [readonly]="hasSIN">
                                        <label for="SIN">SIN </label>
                                        <div *ngIf="(SIN.dirty || SIN.touched) && SIN.invalid">
                                            <small *ngIf="SIN.errors.minlength || SIN.errors.maxlength"
                                                class="red-text">The SIN
                                                must be
                                                9 Digits.</small>
                                        </div>
                                    </div>

                                    <div class="input-field col s12 m6">
                                        <i class="material-icons prefix red-text">alternate_email</i>
                                        <input id="email" type="text" formControlName="email" class="validate">
                                        <label for="email">Email</label>
                                        <div *ngIf="(email.dirty || email.touched) && email.invalid">
                                            <small *ngIf="email.errors.email" class="red-text">Invalid Email
                                                Address</small>
                                        </div>
                                    </div>

                                    <div class="col s12 m6">
                                        <label for="marital_status">Marital Status<span
                                                class="red-text point">*</span></label>

                                        <select id="marital_status" formControlName="marital_status"
                                            class="validate browser-default"
                                            (change)="maritalStatusChange($event.target.value)">
                                            <option value="" disabled selected>Please Select</option>
                                            <option *ngFor="let status of maritalStatusList" [value]="status">{{status}}
                                            </option>
                                        </select>

                                        <div
                                            *ngIf="(marital_status.dirty || marital_status.touched) && marital_status.invalid">
                                            <small class="red-text"> The Marital Status is required.</small>
                                        </div>
                                    </div>

                                    <div class="col s12 m6">
                                        <label for="reside">Usually Reside: </label>
                                        <select id="reside" formControlName="reside" class="validate browser-default">
                                            <option value="" disabled>Select</option>
                                            <option value="yes">On Reserve</option>
                                            <option value="no">Off Reserve</option>
                                        </select>

                                        <div *ngIf="(reside.dirty || reside.touched) && reside.invalid"> <small
                                                class="red-text"> Please
                                                Select An Option.</small>
                                        </div>
                                    </div>

                                    <div class=" col s12 m6">
                                        <label for="community">Community</label>
                                        <select formControlName="community" class="validate browser-default"
                                            (change)="communitySelected($event.target.value)">
                                            <option value="" disabled selected>Choose your Community</option>
                                            <option *ngFor="let c of communityList" [value]="c.community">
                                                {{c.community}}
                                            </option>
                                            <option value="Other">Other</option>
                                        </select>
                                    </div>

                                    <div class="input-field col s12 m6" *ngIf="otherLocation">
                                        <i class="material-icons prefix blue-text">location_on</i>
                                        <input id="other_location" type="text" formControlName="other_location"
                                            class="validate" style="text-transform: capitalize;"
                                            style="text-transform: capitalize;" maxlength="50">
                                        <label for="other_location">Other Location</label>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <h6 class=""><strong>BANK INFORMATION: </strong> </h6>
                            </div>
                            <div class="row card-panel">
                                <div class="row">

                                    <div class="input-field col s12 m6">
                                        <i class="material-icons prefix green-text">account_balance</i>
                                        <input id="bank_name" type="text" formControlName="bank_name" class="validate"
                                            style="text-transform: capitalize;" [readonly]="hasBank">
                                        <label for="bank_name">Bank Name</label>
                                    </div>

                                    <div class="input-field col s12 m6">
                                        <i class="material-icons prefix blue-text text-lighten-2">tag</i>
                                        <input id="bank_no" type="text" formControlName="bank_no" class="validate"
                                            style="text-transform: capitalize;"
                                            [textMask]="{mask: bankNoMask, guide: true}" [readonly]="hasBankNo">
                                        <label for="bank_no">Bank No</label>
                                        <div *ngIf="(bank_no.dirty || bank_no.touched) && bank_no.invalid">
                                            <small *ngIf="bank_no.errors.minlength || bank_no.errors.maxlength"
                                                class="red-text">The
                                                bank number must be 3 Digits.</small>
                                        </div>
                                    </div>

                                    <div class="input-field col s12 m6">
                                        <i class="material-icons prefix orange-text">tag</i>
                                        <input id="transit_no" type="text" formControlName="transit_no" class="validate"
                                            style="text-transform: capitalize;"
                                            [textMask]="{mask: branchNoMask, guide: true}" [readonly]="hasTransitNo">
                                        <label for="transit_no">Transit No</label>
                                    </div>

                                    <div class="input-field col s12 m6">
                                        <i class="material-icons prefix teal-text">paid</i>
                                        <input id="account_no" type="text" formControlName="account_no" class="validate"
                                            style="text-transform: capitalize;" [readonly]="hasAccountNo">
                                        <label for="account_no">Account No</label>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <h6 class=""><strong>DURING SPONSORSHIP PERIOD:</strong> </h6>
                            </div>

                            <div class="row card-panel">
                                <div class="row">
                                    <p>I Will Be Employed</p>
                                    <label class="col s6">
                                        <input formControlName="employed" name="employed" type="radio" value="yes"
                                            (change)="onEmployedChecked($event.target.value)" />
                                        <span>Yes</span>
                                    </label>
                                    <label class="col s6">
                                        <input formControlName="employed" name="employed" type="radio" value="no"
                                            (change)="onEmployedChecked($event.target.value)" />
                                        <span>No</span>
                                    </label>
                                </div>
                            </div>

                            <div class="row card-panel" *ngIf="isEmployed">
                                <div class="input-field col s12">
                                    <i class="material-icons prefix blue-text">business</i>
                                    <input id="employer" type="text" formControlName="employer" class="validate"
                                        style="text-transform: capitalize;">
                                    <label for="employer">Employer Name</label>
                                </div>
                            </div>

                            <div class="row card-panel">
                                <div class="row">
                                    <p>Leave Of Absence</p>
                                    <label class="col s6">
                                        <input formControlName="leaveofabsence" name="leaveofabsence" type="radio"
                                            value="yes" />
                                        <span>Yes</span>
                                    </label>
                                    <label class="col s6">
                                        <input formControlName="leaveofabsence" name="leaveofabsence" type="radio"
                                            value="no" />
                                        <span>No</span>
                                    </label>
                                </div>
                            </div>

                            <div class="row card-panel">
                                <div class="row">
                                    <p>With Pay</p>
                                    <label class="col s6">
                                        <input formControlName="withpay" name="withpay" type="radio" value="yes"
                                            (change)="onWithPayChecked($event.target.value)" />
                                        <span>Yes</span>
                                    </label>
                                    <label class="col s6">
                                        <input formControlName="withpay" name="withpay" type="radio" value="no"
                                            (change)="onWithPayChecked($event.target.value)" />
                                        <span>No</span>
                                    </label>
                                </div>
                            </div>

                            <div class="row card-panel" *ngIf="hasIncome">
                                <div class="input-field col s12 ">
                                    <i class="material-icons prefix green-text">paid</i>
                                    <input id="income" type="number" formControlName="income"
                                        (change)="setIncomesTwoNumberDecimal($event.target.value)" min="0" max="10000"
                                        step="0.25" value="0.00">
                                    <label for="income">My Income $</label>
                                </div>
                            </div>

                            <div class="row card-panel" *ngIf="HasSpouse">
                                <div class="row">
                                    <p>Spouse Classification</p>
                                    <label class="col s6">
                                        <input formControlName="spouse_classification" name="spouse_classification"
                                            type="radio" value="Dependent" />
                                        <span>Dependent</span>
                                    </label>
                                    <label class="col s6">
                                        <input formControlName="spouse_classification" name="spouse_classification"
                                            type="radio" value="Employed" />
                                        <span>Employed</span>
                                    </label>
                                </div>
                            </div>

                            <div class="row card-panel" *ngIf="HasSpouse">
                                <div class="input-field col s12 ">
                                    <i class="material-icons prefix green-text">paid</i>
                                    <input id="spouse_income" type="number" formControlName="spouse_income"
                                        (change)="setIncomesTwoNumberDecimal($event.target.value)" min="0" max="10000"
                                        step="0.25" value="0.00">
                                    <label for="income">Spouse Income $</label>
                                </div>
                            </div>

                            <div class="row">
                                <h6><strong>HOUSING REQUIREMENTS: </strong></h6>
                            </div>
                            <div class="row card-panel">
                                <div class="row">
                                    <label class="col s12 m6">
                                        <input formControlName="house_status" name="house_status" type="radio"
                                            value="1" />
                                        <span>Rent Only</span>
                                    </label>
                                    <label class="col s12 m6">
                                        <input formControlName="house_status" name="house_status" type="radio"
                                            value="2" />
                                        <span>Rent and Damage Deposit</span>
                                    </label>

                                    <label class="col s12 m6">
                                        <input formControlName="house_status" name="house_status" type="radio"
                                            value="3" />
                                        <span>No Rent Required</span>
                                    </label>
                                </div>
                            </div>

                            <div class="row"></div>
                            <div class="row">
                                <div class="col s6">
                                    <button class="btn left waves-effect red" type="button" style="margin-bottom: 15px"
                                        (click)="onMyBack1()"><i class="material-icons left">arrow_back</i>Back
                                    </button>
                                </div>
                                <div class="col s6">
                                    <button class=" btn right  green" type="submit" style="margin-bottom: 15px"
                                        title="Save">Next <i class="material-icons right">arrow_forward</i></button>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div class="row" *ngIf="step == 2">
                        <br />
                        <div class="row grey lighten-3 black-text">
                            <h6 class="center-align">Part <span class="red-text">B</span> : Family Members</h6>
                        </div>

                        <form class="row col s12" [formGroup]="childrenForm"
                            (ngSubmit)="submitFamilyMeber(childrenForm.controls.children.value)">

                            <div class="row" formGroupName="children">

                                <div class="row card-panel white">

                                    <p>Do you want to Add Family Members ?</p>
                                    <div class=" input-field col s12">
                                        <div class="row col s12">
                                            <label class="col s6">
                                                <input type="radio" value="yes" formControlName="add_children"
                                                    (change)="onAddChildrenYes()" />
                                                <span>Yes</span> <!-- disabled -->
                                            </label>
                                            <label class="col s6">
                                                <input type="radio" value="no" formControlName="add_children"
                                                    (change)="onAddChildrenNo()" />
                                                <span>No</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <br>

                                <div *ngIf="emptyChildren" class="center-align">
                                    <span class="red-text"><i class="material-icons">info</i> No Family Members Added
                                        !</span>
                                </div>

                                <div *ngIf="displayChildren" class="row">
                                    <h6 class="card-title"> Family Members</h6>

                                    <div formArrayName="childrens" class="row col s12 ">
                                        <ul class="collection">
                                            <li *ngFor="let child of childControls.controls; let i = index;"
                                                class="collection-item">
                                                <div class="truncate"><i class="material-icons"
                                                        style="margin-right: 10px;">account_circle</i>
                                                    <span
                                                        class="blue-text">{{childControls.controls[i].controls.Relationship.value}}</span>
                                                    - {{childControls.controls[i].controls.given_name.value}}
                                                    {{childControls.controls[i].controls.last_name.value}} <a
                                                        class="secondary-content" (click)="onDeleteChildern(i)"><i
                                                            class="material-icons">close</i></a>
                                                </div>
                                            </li>

                                            <li class="collection-item">
                                                <div class="center">
                                                    <button class=" btn-small blue" type="button"
                                                        (click)="addAnotherChild()">Add New Family Member</button>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div *ngIf="addChildren" class="row col s12 card white">

                                    <div class="col s12 card-content">
                                        <span class="material-icons right" (click)="closeAddChild()"
                                            style="cursor: pointer;">close</span>
                                        <span class="card-title center-align col s12">Add Member</span>
                                        <div class="input-field col s12 m6">
                                            <i class="material-icons prefix black-text">face</i>
                                            <input id="first_name" type="text" formControlName="first_name"
                                                class="capitalize validate" style="text-transform: capitalize;"
                                                maxlength="50">
                                            <label for="first_name">Member First Name<span
                                                    class="red-text point">*</span></label>
                                            <div *ngIf="(first_name.dirty || first_name.touched) && first_name.invalid">
                                                <small *ngIf="first_name.errors.required" class="red-text"> The First
                                                    Name is
                                                    required. </small>
                                            </div>
                                        </div>

                                        <div class="input-field col s12 m6">
                                            <i class="material-icons prefix pink-text">face</i>
                                            <input id="last_name" type="text" formControlName="last_name"
                                                class="capitalize  validate" style="text-transform: capitalize;"
                                                maxlength="50">
                                            <label for="last_name">Member Last Name<span
                                                    class="red-text point">*</span></label>
                                            <div *ngIf="(last_name.dirty || last_name.touched) && last_name.invalid">
                                                <small *ngIf="last_name.errors.required" class="red-text"> The Last Name
                                                    is
                                                    required. </small>
                                            </div>
                                        </div>

                                        <div class="input-field class col s12 ">
                                            <i class="material-icons prefix blue-text">today</i>
                                            <input id="dob" formControlName="dob" type="text" class="input-box"
                                                angular-mydatepicker name="mydate" (click)="dp5.toggleCalendar()"
                                                [options]="myDpOptions" #dp5="angular-mydatepicker"
                                                (dateChanged)="onDateChange5($event)" />
                                            <label for="dob">Date of Birth<span class="red-text point">*</span></label>
                                        </div>

                                        <div class="input-field col s12 m6">
                                            <i class="material-icons prefix red-text">school</i>
                                            <select formControlName="gender" class="validate" id="gender">
                                                <option value="" disabled selected>Choose your option</option>
                                                <option *ngFor="let g of genders" [value]="g">{{g}}</option>
                                            </select>
                                            <label for="gender">Gender <span class="red-text point">*</span> </label>
                                        </div>

                                        <div class="input-field col s12 m6">
                                            <i class="material-icons prefix green-text">apps</i>
                                            <select formControlName="relationship" class="validate" id="relationship"
                                                (change)="RelationshipChange($event.target.value)">
                                                <option value="" disabled selected>Choose your option</option>
                                                <option *ngFor="let r of RelationshipList" [value]="r">{{r}}</option>
                                            </select>
                                            <label for="relationship">Classification<span
                                                    class="red-text point">*</span></label>
                                        </div>

                                        <div class="input-field col s12 m6">
                                            <i class="material-icons prefix blue-text">tag</i>
                                            <input id="ChildHealthCareNo" type="text"
                                                formControlName="ChildHealthCareNo" class="validate"
                                                [textMask]="{mask: SINMask, guide: true}">
                                            <label for="ChildHealthCareNo">Health Card No </label>
                                        </div>

                                        <div class="input-field col s12 m6">
                                            <i class="material-icons prefix black-text">pin</i>
                                            <input id="ISNofChild" type="text" formControlName="ISNofChild"
                                                class="validate" [textMask]="{mask: isnMask, guide: true}">
                                            <label for="ISNofChild">Indian Status Number</label>
                                        </div>

                                        <div class="col s12" *ngIf="childcare_required">
                                            <label for="child_care_level">Child Care Level </label>
                                            <select formControlName="child_care_level" class="validate browser-default"
                                                id="child_care_level">
                                                <option value="" disabled selected>Please Select</option>
                                                <option *ngFor="let r of ChildCareLevelList" [value]="r">{{r}}</option>
                                            </select>
                                        </div>

                                        <div class="col s12" *ngIf="childcare_required">
                                            <label for="child_care_category">Child Care category </label>
                                            <select formControlName="child_care_category"
                                                class="validate browser-default" id="child_care_category">
                                                <option value="" disabled selected>Please Select</option>
                                                <option *ngFor="let r of ChildCarecategoryList" [value]="r">{{r}}
                                                </option>
                                            </select>
                                        </div>
                                    </div>

                                    <div class="row">
                                        <div class="col s3 "></div>
                                        <div class="col s6 ">
                                            <button class="col s12 btn-small  blue" type="button"
                                                style="margin-bottom: 15px;"
                                                (click)="addChild(childrenForm.controls.children.value)"
                                                [disabled]="!childrenForm.controls.children.valid">Add Member</button>
                                        </div>
                                        <div class="col s3 "></div>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col s6">
                                    <button class="btn left waves-effect red" type="button" style="margin-bottom: 15px"
                                        (click)="onBack()"><i class="material-icons left">arrow_back</i>Back
                                    </button>
                                </div>
                                <div class="col s6">
                                    <button class=" btn right  green" type="submit" style="margin-bottom: 15px"
                                        title="Save">Next <i class="material-icons right">arrow_forward</i></button>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div class="row" *ngIf="step == 3">
                        <br />
                        <div class="row grey lighten-3 black-text">
                            <h6 class="center-align">Part <span class="red-text">C</span> : Education - High School</h6>
                        </div>

                        <form [formGroup]="highSchoolForm" (ngSubmit)="submitHighSchool(highSchoolForm.value)">
                            <div class="row card-panel">

                                <div class="input-field col s12 ">
                                    <i class="material-icons prefix green-text">location_on</i>
                                    <input id="school" type="text" formControlName="school" class="validate"
                                        style="text-transform: capitalize;" style="text-transform: capitalize;"
                                        maxlength="50">
                                    <label for="school">High School Attended<span
                                            class="red-text point">*</span></label>
                                </div>

                                <div class="input-field col s12 ">
                                    <i class="material-icons prefix blue-text">pin</i>
                                    <input id="school_year" type="text" formControlName="school_year" class="validate"
                                        [textMask]="{mask: yearMask, guide: true}">
                                    <label for="school_year">Year<span class="red-text point">*</span></label>
                                    <div *ngIf="(school_year.dirty || school_year.touched) && school_year.invalid">
                                        <div *ngIf="school_year.errors?.min || school_year.errors?.max">
                                            <small class="red-text"> The Year Must Be Between (1975 And
                                                {{todayDate}})</small>
                                        </div>
                                    </div>
                                </div>

                                <div class=" col s12 ">
                                    <label for="grade">Grade Achieved<span class="red-text point">*</span></label>
                                    <select formControlName="grade" class="validate browser-default"
                                        (change)="gradeChange($event.target.value)">
                                        <option disabled value="">Please Select</option>
                                        <option *ngFor="let t of grades" [value]="t">{{t}}</option>
                                    </select>
                                </div>
                            </div>

                            <div class="row card-panel">
                                <div class="input-field col s12 ">
                                    <div class="row col s12"> Completed:<span class="red-text point">*</span> </div>
                                    <div class="row col s12">
                                        <label class="col s6">
                                            <input formControlName="completed" name="completed" type="radio"
                                                checked="checked" value="yes" />
                                            <span>Yes</span>
                                        </label>
                                        <label class="col s6">
                                            <input formControlName="completed" name="completed" type="radio"
                                                value="no" />
                                            <span>No</span>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div class="row card-panel">
                                <div class="input-field col s12 ">
                                    <div class="row col s12"><strong>Transcripts<span
                                                class="red-text point">*</span></strong>
                                    </div>
                                    <div class="row col s12">
                                        <label class="col s6">
                                            <input formControlName="transcripts" name="transcripts" type="radio"
                                                value="yes" />
                                            <span>Yes</span>
                                        </label>
                                        <label class="col s6">
                                            <input formControlName="transcripts" name="transcripts" type="radio"
                                                value="no" />
                                            <span>No</span>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col s6">
                                    <button class="btn left waves-effect red" type="button" style="margin-bottom: 15px"
                                        (click)="onBack()"><i class="material-icons left">arrow_back</i>Back
                                    </button>
                                </div>
                                <div class="col s6">
                                    <button class=" btn right  green" type="submit" [disabled]="!highSchoolForm.valid"
                                        style="margin-bottom: 15px" title="Save">Next <i
                                            class="material-icons right">arrow_forward</i></button>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div class="row" *ngIf="step == 4">
                        <br />
                        <div class="row grey lighten-3 black-text">
                            <h6 class="center-align">Part <span class="red-text">D</span> : Education - Technical
                                Institute/College</h6>
                        </div>

                        <form [formGroup]="collegeForm" (ngSubmit)="submitCollege(collegeForm.controls.college.value)">
                            <div formGroupName="college">
                                <div class="row col s12 card-panel white">
                                    <br>
                                    <p>Do you want to Add College ?</p>
                                    <div class="row input-field col s12">
                                        <div class="row col s12">
                                            <label class="col s6">
                                                <input type="radio" value="yes" formControlName="add_college"
                                                    (change)="onAddCollegeYes()" /> <span>Yes</span>
                                            </label>
                                            <label class="col s6">
                                                <input type="radio" value="no" formControlName="add_college"
                                                    (change)="onAddCollegeNo()" /> <span>No</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="emptyCollege" class="center-align">
                                    <span class="red-text"><i class="material-icons">info</i> No College Added </span>
                                </div>

                                <div *ngIf="displayCollege" class="row">
                                    <h6 class="card-title"> College List</h6>

                                    <div formArrayName="colleges" class="row col s12 ">

                                        <ul class="collection">
                                            <li *ngFor="let c of collegeControls.controls; let i = index;"
                                                class="collection-item">
                                                <div class="truncate"><i class="material-icons"
                                                        style="margin-right: 15px;">school</i>{{collegeControls.controls[i].controls.college_year.value}}
                                                    -
                                                    {{getEndYear(collegeControls.controls[i].controls.college_year.value
                                                    ,
                                                    collegeControls.controls[i].controls.years.value)}} :
                                                    {{collegeControls.controls[i].controls.institute.value}} <a
                                                        class="secondary-content" (click)="onDeleteCollege(i)"><i
                                                            class="material-icons">close</i></a></div>
                                            </li>

                                            <li class="collection-item">
                                                <div class="center">
                                                    <button class=" btn-small blue" type="button"
                                                        (click)="addAnotherCollege()">Add New College</button>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div id="AddCollegeModal" class="modal">
                                    <div class="modal-content">
                                        <!-- <h6>Add College</h6>-->
                                        <h5 class="modal-title center"><strong>Add College</strong></h5>
                                        <div class="input-field col s12">
                                            <i class="material-icons prefix blue-text">school</i>
                                            <input id="institute" type="text" formControlName="institute"
                                                class="capitalize validate" style="text-transform: capitalize;"
                                                maxlength="50">
                                            <label for="institute">Institute/College<span
                                                    class="red-text point">*</span></label>
                                            <div *ngIf="(institute.dirty || institute.touched) && institute.invalid">
                                                <small *ngIf="institute.errors.required" class="red-text"> The
                                                    Institute/college
                                                    Name is required. </small>
                                            </div>
                                        </div>

                                        <div class="input-field col s12 ">
                                            <i class="material-icons prefix blue-text">calendar_today</i>
                                            <input id="college_year" type="text" formControlName="college_year"
                                                class="validate" [textMask]="{mask: yearMask, guide: true}">
                                            <label for="college_year">Graduation Year<span
                                                    class="red-text point">*</span></label>
                                            <div
                                                *ngIf="(college_year.dirty || college_year.touched) && college_year.invalid">
                                                <div *ngIf="college_year.errors?.min || college_year.errors?.max">
                                                    <small class="red-text"> The Year Must Be Between (2000 And
                                                        {{todayDate}})</small>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col s12 ">
                                            <label for="t_years">How Manu Years ?</label>
                                            <select formControlName="years" class="validate browser-default">
                                                <option disabled value="">Please Select</option>
                                                <option *ngFor="let m of educationYears" [value]="m">{{m}}</option>
                                            </select>
                                        </div>

                                        <div class="input-field col s12 ">
                                            <p> Completed: <span class="red-text point">*</span> </p>
                                            <div class="row col s12">
                                                <label class="col s6">
                                                    <input formControlName="completed1" name="completed1" type="radio"
                                                        checked="checked" value="yes" />
                                                    <span>Yes</span>
                                                </label>
                                                <label class="col s6">
                                                    <input formControlName="completed1" name="completed1" type="radio"
                                                        value="no" />
                                                    <span>No</span>
                                                </label>
                                            </div>
                                        </div>

                                        <div class="input-field col s12 ">
                                            <p><strong>Transcripts: <span class="red-text point">*</span></strong></p>
                                            <div class="row col s12">
                                                <label class="col s6">
                                                    <input formControlName="transcripts1" name="transcripts1"
                                                        type="radio" value="yes" />
                                                    <span>Yes</span>
                                                </label>
                                                <label class="col s6">
                                                    <input formControlName="transcripts1" name="transcripts1"
                                                        type="radio" value="no" />
                                                    <span>No</span>
                                                </label>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="modal-footer">
                                        <a class="modal-close btn red " style="margin-right: 5px;"
                                            (click)="cancelCollege()">Cancel</a>
                                        <a class="btn green" (click)="addMyCollege(collegeForm.controls.college.value)"
                                            [disabled]="!collegeForm.controls.college.valid">Add College</a>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col s6">
                                    <button class="btn left waves-effect red" type="button" style="margin-bottom: 15px"
                                        (click)="onBack()"><i class="material-icons left">arrow_back</i>Back
                                    </button>
                                </div>
                                <div class="col s6">
                                    <button class=" btn right  green" type="submit"
                                        [disabled]="!collegeForm.valid && collegeControls.controls.length==0"
                                        style="margin-bottom: 15px" title="Save">Next <i
                                            class="material-icons right">arrow_forward</i></button>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div class="row" *ngIf="step == 5">
                        <br />
                        <div class="row grey lighten-3 black-text">
                            <h6 class="center-align">Part <span class="red-text">D</span> : Education - University</h6>
                        </div>

                        <form [formGroup]="universityForm"
                            (ngSubmit)="submitUniversity(universityForm.controls.university.value)">

                            <div formGroupName="university">

                                <div class="row col s12 card-panel white">
                                    <br>
                                    <p>Do you want to Add University ?</p>
                                    <div class="row input-field col s12">
                                        <div class="row col s12">
                                            <label class="col s6">
                                                <input type="radio" value="yes" formControlName="add_university"
                                                    (change)="onAddUniversityYes()" /> <span>Yes</span>
                                            </label>
                                            <label class="col s6">
                                                <input type="radio" value="no" formControlName="add_university"
                                                    (change)="onAddUniversityNo()" /> <span>No</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="emptyUniversity" class="center-align">
                                    <span class="red-text"><i class="material-icons">info</i> No University Added
                                    </span>
                                </div>

                                <div *ngIf="displayUniversity" class="row">
                                    <h6 class="card-title"> University List</h6>

                                    <div formArrayName="universitys" class="row col s12 ">

                                        <ul class="collection">
                                            <li *ngFor="let c of universityControls.controls; let i = index;"
                                                class="collection-item">
                                                <div class="truncate"><i class="material-icons"
                                                        style="margin-right: 15px ;">school</i>{{universityControls.controls[i].controls.university_year.value}}
                                                    -
                                                    {{getEndYear(universityControls.controls[i].controls.university_year.value
                                                    ,
                                                    universityControls.controls[i].controls.years2.value)}}
                                                    :{{universityControls.controls[i].controls.institute2.value}} <a
                                                        class="secondary-content" (click)="onDeleteUniversity(i)"><i
                                                            class="material-icons">close</i></a></div>
                                            </li>
                                            <li class="collection-item">
                                                <div class="center">
                                                    <button class=" btn-small blue" type="button"
                                                        (click)="addAnotherUniversity()">Add New University</button>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div id="AddUniversityModal" class="modal">
                                    <div class="modal-content">
                                        <!-- <h6>Add University</h6> -->
                                        <h5 class="modal-title center"><strong>Add University</strong></h5>

                                        <div class="input-field col s12">
                                            <i class="material-icons prefix red-text">school</i>
                                            <input id="institute2" type="text" formControlName="institute2"
                                                class="capitalize validate" style="text-transform: capitalize;"
                                                maxlength="50">
                                            <label for="institute2">University<span
                                                    class="red-text point">*</span></label>
                                            <div *ngIf="(institute2.dirty || institute2.touched) && institute2.invalid">
                                                <small *ngIf="institute2.errors.required" class="red-text"> The
                                                    university is
                                                    required.
                                                </small>
                                            </div>
                                        </div>

                                        <div class="input-field col s12 ">
                                            <i class="material-icons prefix green-text">calendar_today</i>
                                            <input id="university_year" type="text" formControlName="university_year"
                                                class="validate" [textMask]="{mask: yearMask, guide: true}">
                                            <label for="university_year">Graduation Year<span
                                                    class="red-text point">*</span></label>
                                            <div
                                                *ngIf="(university_year.dirty || university_year.touched) && university_year.invalid">
                                                <div *ngIf="university_year.errors?.min || university_year.errors?.max">
                                                    <small class="red-text"> The Year Must Be Between (2000 And
                                                        {{todayDate}})</small>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col s12 ">
                                            <label for="t_years">How Manu Years ?</label>
                                            <select formControlName="years2" class="validate browser-default">
                                                <option disabled value="">Please Select</option>
                                                <option *ngFor="let m of educationYears" [value]="m">{{m}}</option>
                                            </select>
                                        </div>

                                        <div class="input-field col s12 ">
                                            <p> Completed:<span class="red-text point">*</span> </p>
                                            <div class="row col s12">
                                                <label class="col s6">
                                                    <input formControlName="completed2" name="completed2" type="radio"
                                                        checked="checked" value="yes" />
                                                    <span>Yes</span>
                                                </label>
                                                <label class="col s6">
                                                    <input formControlName="completed2" name="completed2" type="radio"
                                                        value="no" />
                                                    <span>No</span>
                                                </label>
                                            </div>
                                        </div>

                                        <div class="input-field col s12 ">
                                            <p><strong>Transcripts<span class="red-text point">*</span></strong></p>
                                            <div class="row col s12">
                                                <label class="col s6">
                                                    <input formControlName="transcripts2" name="transcripts2"
                                                        type="radio" value="yes" />
                                                    <span>Yes</span>
                                                </label>
                                                <label class="col s6">
                                                    <input formControlName="transcripts2" name="transcripts2"
                                                        type="radio" value="no" />
                                                    <span>No</span>
                                                </label>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="modal-footer">
                                        <a class="modal-close btn red " style="margin-right: 5px;"
                                            (click)="cancelUniversity()">Cancel</a>
                                        <a class="btn green"
                                            (click)="addMyUniversity(universityForm.controls.university.value)"
                                            [disabled]="!universityForm.controls.university.valid">Add University</a>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col s6">
                                    <button class="btn left waves-effect red" type="button" style="margin-bottom: 15px"
                                        (click)="onBack()"><i class="material-icons left">arrow_back</i>Back
                                    </button>
                                </div>
                                <div class="col s6">
                                    <button class=" btn right  green" type="submit"
                                        [disabled]="!universityForm.valid && universityControls.controls.length==0"
                                        style="margin-bottom: 15px" title="Save">Next <i
                                            class="material-icons right">arrow_forward</i></button>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div class="row" *ngIf="step == 6">
                        <br />
                        <div class="row grey lighten-3 black-text">
                            <h6 class="center-align">Part <span class="red-text">E</span> : Education - Other Education
                            </h6>
                        </div>

                        <form [formGroup]="trainingForm"
                            (ngSubmit)="submitTraining(trainingForm.controls.training.value)">

                            <div formGroupName="training">

                                <div class="row col s12 card-panel white">
                                    <br>
                                    <p>Do you want to Add Other Education ?</p>
                                    <div class="row input-field col s12">
                                        <div class="row col s12">
                                            <label class="col s6">
                                                <input type="radio" value="yes" formControlName="add_training"
                                                    (change)="onAddTrainingYes()" /> <span>Yes</span>
                                            </label>
                                            <label class="col s6">
                                                <input type="radio" value="no" formControlName="add_training"
                                                    (change)="onAddTrainingNo()" /> <span>No</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="emptyTraining" class="center-align">
                                    <span class="red-text"><i class="material-icons">info</i> No Other Education Added
                                    </span>
                                </div>

                                <div *ngIf="displayTraining" class="row">
                                    <h6 class="card-title"> Other Education / Training List</h6>

                                    <div formArrayName="trainings" class="row col s12 ">

                                        <ul class="collection">
                                            <li *ngFor="let c of trainingControls.controls; let i = index;"
                                                class="collection-item">
                                                <div class="truncate"><i class="material-icons"
                                                        style="margin-right: 15px;">school</i>{{trainingControls.controls[i].controls.training_year.value}}
                                                    - {{trainingControls.controls[i].controls.institute3.value}} <a
                                                        class="secondary-content" (click)="onDeleteTraining(i)"><i
                                                            class="material-icons">close</i></a></div>
                                            </li>

                                            <li class="collection-item">
                                                <div class="center">
                                                    <button class=" btn-small blue" type="button"
                                                        (click)="addAnotherTraining()">Add Other
                                                        Education/Training</button>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div id="AddTrainingModal" class="modal">
                                    <div class="modal-content">
                                        <!-- <h6>Add Other Education/Training</h6> -->
                                        <h5 class="modal-title center"><strong>Add Other Education/Training</strong>
                                        </h5>
                                        <div class="input-field col s12">
                                            <i class="material-icons prefix blue-text">school</i>
                                            <input id="institute3" type="text" formControlName="institute3"
                                                class="capitalize validate" style="text-transform: capitalize;"
                                                maxlength="50">
                                            <label for="institute3">Description<span
                                                    class="red-text point">*</span></label>
                                            <div *ngIf="(institute3.dirty || institute3.touched) && institute3.invalid">
                                                <small *ngIf="institute3.errors.required" class="red-text"> The
                                                    Education
                                                    Description is required. </small>
                                            </div>
                                        </div>

                                        <div class="input-field col s12 ">
                                            <i class="material-icons prefix blue-text">calendar_today</i>
                                            <input id="training_year" type="text" formControlName="training_year"
                                                class="validate" [textMask]="{mask: yearMask, guide: true}">
                                            <label for="training_year">Graduation Year<span
                                                    class="red-text point">*</span></label>
                                            <div
                                                *ngIf="(training_year.dirty || training_year.touched) && training_year.invalid">
                                                <div *ngIf="training_year.errors?.min || training_year.errors?.max">
                                                    <small class="red-text"> The Year Must Be Between (2000 And
                                                        {{todayDate}})</small>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="input-field col s12 ">
                                            <p> Completed:<span class="red-text point">*</span> </p>
                                            <div class="row col s12">
                                                <label class="col s6">
                                                    <input formControlName="completed3" name="completed3" type="radio"
                                                        checked="checked" value="yes" />
                                                    <span>Yes</span>
                                                </label>
                                                <label class="col s6">
                                                    <input formControlName="completed3" name="completed3" type="radio"
                                                        value="no" />
                                                    <span>No</span>
                                                </label>
                                            </div>
                                        </div>

                                        <div class="input-field col s12 ">
                                            <p><strong>Certificaton/Diploma<span
                                                        class="red-text point">*</span></strong></p>
                                            <div class="row col s12">
                                                <label class="col s6">
                                                    <input formControlName="transcripts3" name="transcripts3"
                                                        type="radio" value="yes" />
                                                    <span>Yes</span>
                                                </label>
                                                <label class="col s6">
                                                    <input formControlName="transcripts3" name="transcripts3"
                                                        type="radio" value="no" />
                                                    <span>No</span>
                                                </label>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="modal-footer">
                                        <a class="modal-close btn red " style="margin-right: 5px;"
                                            (click)="cancelTraining()">Cancel</a>
                                        <a class="btn green"
                                            (click)="addMyTraining(trainingForm.controls.training.value)"
                                            [disabled]="!trainingForm.controls.training.valid">Add
                                            Education/Training</a>
                                    </div>
                                </div>

                            </div>

                            <div class="row">
                                <div class="col s6">
                                    <button class="btn left waves-effect red" type="button" style="margin-bottom: 15px"
                                        (click)="onBack()"><i class="material-icons left">arrow_back</i>Back
                                    </button>
                                </div>
                                <div class="col s6">
                                    <button class=" btn right  green" type="submit"
                                        [disabled]="!trainingForm.valid && trainingControls.controls.length==0"
                                        style="margin-bottom: 15px" title="Save">Next <i
                                            class="material-icons right">arrow_forward</i></button>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div class="row" *ngIf="step == 7">

                        <div class="row grey lighten-3 black-text">
                            <h6 class="center-align">Part <span class="red-text">F</span> : Education Plan</h6>
                        </div>

                        <form [formGroup]="eduPlanForm" (ngSubmit)="submitEduPlan(eduPlanForm.value)">
                            <div class="row card-panel">
                                <div class="input-field col s12 ">
                                    <i class="material-icons prefix green-text">business_center</i>
                                    <input id="objective" type="text" formControlName="objective" class="validate"
                                        style="text-transform: capitalize;" style="text-transform: capitalize;"
                                        maxlength="100">
                                    <label for="objective">Career Objective<span class="red-text point">*</span></label>
                                </div>

                                <div class="col s12 ">
                                    <label for="Name of Institution">PS Institution<span
                                            class="red-text point">*</span></label>
                                    <select formControlName="institution" class="validate browser-default"
                                        (change)="InstitutionChange($event.target.value)">
                                        <option disabled value="">Please Select</option>
                                        <option *ngFor="let t of institutions" [value]="t.company">{{t.company}}
                                        </option>
                                    </select>
                                </div>

                                <div class="col s12 ">
                                    <label for="program">Program / Area of Study - Category<span
                                            class="red-text point">*</span></label>
                                    <select formControlName="program" class="validate browser-default"
                                        (change)="ProgramChange($event.target.value)">
                                        <option disabled value="">Please Select</option>
                                        <option *ngFor="let p of programs" [value]="p">{{p}}</option>
                                    </select>
                                </div>

                                <div class=" col s12 ">
                                    <label for="subprogram">Area of Study - Sub-Category<span
                                            class="red-text point">*</span></label>
                                    <select formControlName="subprogram" class="validate  browser-default">
                                        <option disabled value="">Please Select</option>
                                        <option *ngFor="let p of subPrograms" [value]="p">{{p}}</option>
                                    </select>
                                </div>

                                <div class="col s12 ">
                                    <label for="t_years">Program Length</label>
                                    <!--  <input id="t_years" type="text" formControlName="t_years" class="validate" [textMask]="{mask: yearsMask, guide: true}"> -->
                                    <select formControlName="t_years" class="validate browser-default">
                                        <option disabled value="">Please Select</option>
                                        <option *ngFor="let m of ProgramLength" [value]="m">{{m}}</option>
                                    </select>
                                </div>

                                <div class="input-field class col s12">
                                    <i class="material-icons prefix orange-text">calendar_today</i>
                                    <input id="program_start_date" formControlName="program_start_date" type="text"
                                        class="input-box" angular-mydatepicker name="mydate"
                                        (click)="dp3.toggleCalendar()" [options]="myDpOptions"
                                        #dp3="angular-mydatepicker" (dateChanged)="onDateChange3($event)" />
                                    <label for="program_start_date">Program Start Date <span
                                            class="red-text point">*</span></label>
                                </div>

                                <div class="input-field class col s12">
                                    <i class="material-icons prefix blue-text text-darken-3">calendar_today</i>
                                    <input id="completion_date" formControlName="completion_date" type="text"
                                        class="input-box" angular-mydatepicker name="mydate"
                                        (click)="dp1.toggleCalendar()" [options]="myDpOptions"
                                        #dp1="angular-mydatepicker" (dateChanged)="onDateChange1($event)" />
                                    <label for="completion_date">Expected Completion Date <span
                                            class="red-text point">*</span></label>
                                </div>

                                <div class="input-field col s12 ">
                                    <p>Classification : <span class="red-text point">*</span> </p>
                                    <div class="row col s12">
                                        <label class="col s6">
                                            <input formControlName="classification" name="classification" type="radio"
                                                checked="checked" value="Full Time" />
                                            <span>Full Time</span>
                                        </label>
                                        <label class="col s6">
                                            <input formControlName="classification" name="classification" type="radio"
                                                value="Part Time" />
                                            <span>Part Time</span>
                                        </label>
                                    </div>
                                </div>

                                <div class="col s12 ">
                                    <label for="education_sought">Expected Graduation Result <span
                                            class="red-text point">*</span> </label>
                                    <select formControlName="education_sought" class="validate browser-default"
                                        (change)="educationSoughtChange($event.target.value)">
                                        <option disabled value="">Please Select</option>
                                        <option *ngFor="let t of graduation_result" [value]="t">{{t}}</option>
                                    </select>
                                </div>

                                <div class="input-field col s12 " *ngIf="showOtherDetails">
                                    <i class="material-icons prefix red-text">history_edu</i>
                                    <input id="other_details" type="text" formControlName="other_details"
                                        class="validate" style="text-transform: capitalize;"
                                        style="text-transform: capitalize;" maxlength="50">
                                    <label for="other_details">If Result is Other, Please Enter Details </label>
                                </div>


                                <p><strong>Term of Sponsorship <span class="red-text point">*</span></strong></p>
                                <div class="row col s12">
                                    <label class="col s6 m3">
                                        <input formControlName="fall_term" name="fall_term" id="fall_term"
                                            type="checkbox" (change)="checkFall()" value="fall" /><span>Fall</span>
                                    </label>
                                    <label class="col s6 m3">
                                        <input formControlName="winter_term" name="winter_term" id="winter_term"
                                            type="checkbox" (change)="checkWinter()"
                                            value="winter" /><span>Winter</span>
                                    </label>
                                    <label class="col s6 m3">
                                        <input formControlName="spring_term" name="spring_term" id="spring_term"
                                            type="checkbox" (change)="checkSpring()"
                                            value="spring" /><span>Spring</span>
                                    </label>
                                    <label class="col s6 m3">
                                        <input formControlName="summer_term" name="summer_term" id="summer_term"
                                            type="checkbox" (change)="checkSummer()"
                                            value="summer" /><span>Summer</span>
                                    </label>
                                </div>

                                <div class="row" *ngIf="fall_term_checked">

                                    <div class="col s12 m3 l3"><span>Fall Amount</span> </div>

                                    <div class="input-field col s12 m3 l3">
                                        <i class="material-icons prefix green-text">paid</i>
                                        <input id="fall_tution_amt" type="number" formControlName="fall_tution_amt"
                                            (change)="setFallTuitionIncomesTwoNumberDecimal($event.target.value)"
                                            min="0" max="10000" step="0.25" value="0.00">
                                        <label for="fall_tution_amt">Tution $</label>
                                    </div>

                                    <div class="input-field col s12 m3 l3">
                                        <i class="material-icons prefix green-text">paid</i>
                                        <input id="fall_books_amt" type="number" formControlName="fall_books_amt"
                                            (change)="setFallBooksIncomesTwoNumberDecimal($event.target.value)" min="0"
                                            max="10000" step="0.25" value="0.00">
                                        <label for="fall_books_amt">Books $</label>
                                    </div>

                                    <div class="input-field col s12 m3 l3">
                                        <i class="material-icons prefix green-text">paid</i>
                                        <input id="fall_other_amt" type="number" formControlName="fall_other_amt"
                                            (change)="setFallOtherIncomesTwoNumberDecimal($event.target.value)" min="0"
                                            max="10000" step="0.25" value="0.00">
                                        <label for="fall_other_amt">Other $</label>
                                    </div>
                                </div>

                                <div class="row" *ngIf="winter_term_checked">

                                    <div class="col s12 m3 l3"><span>Winter Amount</span> </div>

                                    <div class="input-field col s12 m3 l3">
                                        <i class="material-icons prefix green-text">paid</i>
                                        <input id="winter_tution_amt" type="number" formControlName="winter_tution_amt"
                                            (change)="setWinterTuitionIncomesTwoNumberDecimal($event.target.value)"
                                            min="0" max="10000" step="0.25" value="0.00">
                                        <label for="winter_tution_amt">Tution $</label>
                                    </div>

                                    <div class="input-field col s12 m3 l3">
                                        <i class="material-icons prefix green-text">paid</i>
                                        <input id="winter_books_amt" type="number" formControlName="winter_books_amt"
                                            (change)="setWinterBooksIncomesTwoNumberDecimal($event.target.value)"
                                            min="0" max="10000" step="0.25" value="0.00">
                                        <label for="winter_books_amt">Books $</label>
                                    </div>

                                    <div class="input-field col s12 m3 l3">
                                        <i class="material-icons prefix green-text">paid</i>
                                        <input id="winter_other_amt" type="number" formControlName="winter_other_amt"
                                            (change)="setWinterOtherIncomesTwoNumberDecimal($event.target.value)"
                                            min="0" max="10000" step="0.25" value="0.00">
                                        <label for="winter_other_amt">Other $</label>
                                    </div>
                                </div>

                                <div class="row" *ngIf="spring_term_checked">

                                    <div class="col s12 m3 l3"><span>Spring Amount</span> </div>

                                    <div class="input-field col s12 m3 l3">
                                        <i class="material-icons prefix green-text">paid</i>
                                        <input id="spring_tution_amt" type="number" formControlName="spring_tution_amt"
                                            (change)="setSpringTuitionIncomesTwoNumberDecimal($event.target.value)"
                                            min="0" max="10000" step="0.25" value="0.00">
                                        <label for="spring_tution_amt">Tution $</label>
                                    </div>

                                    <div class="input-field col s12 m3 l3">
                                        <i class="material-icons prefix green-text">paid</i>
                                        <input id="spring_books_amt" type="number" formControlName="spring_books_amt"
                                            (change)="setSpringBooksIncomesTwoNumberDecimal($event.target.value)"
                                            min="0" max="10000" step="0.25" value="0.00">
                                        <label for="spring_books_amt">Books $</label>
                                    </div>

                                    <div class="input-field col s12 m3 l3">
                                        <i class="material-icons prefix green-text">paid</i>
                                        <input id="spring_other_amt" type="number" formControlName="spring_other_amt"
                                            (change)="setSpringOtherIncomesTwoNumberDecimal($event.target.value)"
                                            min="0" max="10000" step="0.25" value="0.00">
                                        <label for="spring_other_amt">Other $</label>
                                    </div>
                                </div>

                                <div class="row" *ngIf="summer_term_checked">

                                    <div class="col s12 m3 l3"><span>Summer Amount</span> </div>

                                    <div class="input-field col s12 m3 l3">
                                        <i class="material-icons prefix green-text">paid</i>
                                        <input id="summer_tution_amt" type="number" formControlName="summer_tution_amt"
                                            (change)="setSummerTuitionIncomesTwoNumberDecimal($event.target.value)"
                                            min="0" max="10000" step="0.25" value="0.00">
                                        <label for="summer_tution_amt">Tution $</label>
                                    </div>

                                    <div class="input-field col s12 m3 l3">
                                        <i class="material-icons prefix green-text">paid</i>
                                        <input id="summer_books_amt" type="number" formControlName="summer_books_amt"
                                            (change)="setSummerBooksIncomesTwoNumberDecimal($event.target.value)"
                                            min="0" max="10000" step="0.25" value="0.00">
                                        <label for="summer_books_amt">Books $</label>
                                    </div>

                                    <div class="input-field col s12 m3 l3">
                                        <i class="material-icons prefix green-text">paid</i>
                                        <input id="summer_other_amt" type="number" formControlName="summer_other_amt"
                                            (change)="setSummerOtherIncomesTwoNumberDecimal($event.target.value)"
                                            min="0" max="10000" step="0.25" value="0.00">
                                        <label for="summer_other_amt">Other $</label>
                                    </div>
                                </div>

                                <div class=" col s12 ">
                                    <label for="delivery_method">Method of Delivery<span
                                            class="red-text point">*</span></label>
                                    <select formControlName="delivery_method" class="validate browser-default">
                                        <option disabled value="">Please Select</option>
                                        <option *ngFor="let m of DeliveryMethods" [value]="m">{{m}}</option>
                                    </select>
                                </div>

                                <div class="input-field col s12 ">
                                    <i class="material-icons prefix blue-text">tag</i>
                                    <input id="studentID" type="text" formControlName="studentID" class="validate"
                                        style="text-transform: capitalize;" maxlength="50">
                                    <label for="studentID">Student ID No.</label>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col s6">
                                    <button class="btn left waves-effect red" type="button" style="margin-bottom: 15px"
                                        (click)="onBack()"><i class="material-icons left">arrow_back</i>Back </button>
                                </div>
                                <div class="col s6">
                                    <button class=" btn right  green" type="submit" [disabled]="!eduPlanForm.valid"
                                        style="margin-bottom: 15px" title="Save">Next <i
                                            class="material-icons right">arrow_forward</i></button>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div class="row" *ngIf="step == 8">
                        <div class="row grey lighten-3 black-text">
                            <h6 class="center-align">Part <span class="red-text">G</span> : Support History</h6>
                        </div>

                        <form [formGroup]="supportHistoryForm"
                            (ngSubmit)="submitSupportHistory(supportHistoryForm.controls.history.value)">

                            <div formGroupName="history">

                                <div class="row col s12 card-panel white">
                                    <br>
                                    <p>Did You Receive Support in the Past ?</p>
                                    <div class="row input-field col s12">
                                        <div class="row col s12">
                                            <label class="col s6">
                                                <input type="radio" value="yes" formControlName="add_history"
                                                    (change)="onAddHistoryYes()" /> <span>Yes</span>
                                            </label>
                                            <label class="col s6">
                                                <input type="radio" value="no" formControlName="add_history"
                                                    (change)="onAddHistoryNo()" /> <span>No</span>
                                            </label>
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="emptyHistory" class="center-align">
                                    <span class="red-text"><i class="material-icons">info</i> No Support History Added
                                    </span>
                                </div>

                                <div *ngIf="displayHistory" class="row">
                                    <h6 class="card-title"> Support List</h6>

                                    <div formArrayName="historys" class="row col s12 ">

                                        <ul class="collection">
                                            <li *ngFor="let c of historyControls.controls; let i = index;"
                                                class="collection-item">
                                                <div class="truncate"><i class="material-icons"
                                                        style="margin-right: 15px;">account_balance</i><span
                                                        class="blue-text">{{historyControls.controls[i].controls.support_year.value}}</span>
                                                    - {{historyControls.controls[i].controls.agency.value}} <a
                                                        class="secondary-content" (click)="onDeleteHistory(i)"><i
                                                            class="material-icons">close</i></a></div>
                                            </li>

                                            <li class="collection-item">
                                                <div class="center">
                                                    <button class=" btn-small blue" type="button"
                                                        (click)="addAnotherHistory()">Add Other Support</button>
                                                </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div id="AddHistoryModal" class="modal">
                                    <div class="modal-content">
                                        <!-- <h6>Add Support Histroy</h6> -->
                                        <h5 class="modal-title center"><strong>Add Support History</strong></h5>

                                        <div class="input-field col s12">
                                            <i class="material-icons prefix blue-text">account_balance</i>
                                            <input id="agency" type="text" formControlName="agency"
                                                class="capitalize validate" style="text-transform: capitalize;"
                                                maxlength="50">
                                            <label for="agency">Sponsor Agency<span
                                                    class="red-text point">*</span></label>
                                            <div *ngIf="(agency.dirty || agency.touched) && agency.invalid">
                                                <small *ngIf="agency.errors.required" class="red-text"> The Sponsor
                                                    Agency is
                                                    required.
                                                </small>
                                            </div>
                                        </div>

                                        <div class="input-field col s12 ">
                                            <i class="material-icons prefix green-text">calendar_today</i>
                                            <input id="support_year" type="text" formControlName="support_year"
                                                class="validate" [textMask]="{mask: yearMask, guide: true}">
                                            <label for="support_year">Year<span class="red-text point">*</span></label>
                                            <div
                                                *ngIf="(support_year.dirty || support_year.touched) && support_year.invalid">
                                                <div *ngIf="support_year.errors?.min || support_year.errors?.max">
                                                    <small class="red-text"> The Year Must Be Between (2000 And
                                                        {{todayDate}})</small>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="input-field col s12">
                                            <i class="material-icons prefix orange-text">school</i>
                                            <input id="support_institute" type="text"
                                                formControlName="support_institute" class="capitalize validate"
                                                style="text-transform: capitalize;" maxlength="50">
                                            <label for="support_institute">Institute<span
                                                    class="red-text point">*</span></label>
                                            <div
                                                *ngIf="(support_institute.dirty || support_institute.touched) && support_institute.invalid">
                                                <small *ngIf="support_institute.errors.required" class="red-text"> The
                                                    Institute
                                                    is required. </small>
                                            </div>
                                        </div>

                                        <div class="input-field col s12 ">
                                            <p><strong>Classification<span class="red-text point">*</span></strong>
                                            </p>
                                            <div class="row col s12">
                                                <label class="col s6">
                                                    <input formControlName="support_classification"
                                                        name="support_classification" type="radio" value="yes" />
                                                    <span>Yes</span>
                                                </label>
                                                <label class="col s6">
                                                    <input formControlName="support_classification"
                                                        name="support_classification" type="radio" value="no" />
                                                    <span>No</span>
                                                </label>
                                            </div>
                                        </div>

                                    </div>
                                    <div class="modal-footer">
                                        <a class="modal-close btn red " style="margin-right: 5px;"
                                            (click)="cancelHistory()">Cancel</a>
                                        <a class="btn green"
                                            (click)="addMyHistory(supportHistoryForm.controls.history.value)"
                                            [disabled]="!supportHistoryForm.controls.history.valid">Add Support</a>
                                    </div>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col s6">
                                    <button class="btn left waves-effect red" type="button" style="margin-bottom: 15px"
                                        (click)="onBack()"><i class="material-icons left">arrow_back</i>Back
                                    </button>
                                </div>
                                <div class="col s6">
                                    <button class=" btn right  green" type="submit"
                                        [disabled]="!supportHistoryForm.valid && historyControls.controls.length==0"
                                        style="margin-bottom: 15px" title="Save">Next <i
                                            class="material-icons right">arrow_forward</i></button>
                                </div>
                            </div>
                        </form>
                    </div>

                    <div class="row" *ngIf="step == 9">
                        <div class="row grey lighten-3 black-text">
                            <h6 class="center-align">Part <span class="red-text">H</span> : Supporting Documents</h6>
                        </div>

                        <form [formGroup]="otherInfoForm" (ngSubmit)="submitOtherInfo(otherInfoForm.value)">

                            <div class="row col s12 card-panel white">
                                <br>
                                <p>Please provide any other information that might assist Post-Secondary selection
                                    committee in
                                    making the application decision (Attach letter)</p>
                                <br>
                                <p>Would You Like to Upload Supporting Documents with Your Application ?</p>
                                <div class="row input-field col s12">
                                    <div class="row col s12">
                                        <label class="col s6">
                                            <input name="upload_documents" formControlName="upload_documents"
                                                type="radio" value="yes" (click)="onUplodYes($event)" />
                                            <span class="text"><strong>Yes</strong></span>
                                        </label>
                                        <label class="col s6">
                                            <input name="upload_documents" formControlName="upload_documents"
                                                type="radio" value="no" (click)="onUplodNo($event)" />
                                            <span class="text"><strong>No</strong></span>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="showUpload" class="row">
                                <div class="row col s12 ">
                                    <ul class="collection" *ngIf="documentsControls && documentsControls.length > 0">

                                        <h6 class="card-title">Attached Files</h6>

                                        <li *ngFor="let file of documentsControls.value; let i = index;"
                                            class="collection-item">
                                            <div class="truncate"><i class="material-icons"
                                                    style="margin-right: 15px;">note_add</i>{{file.type}} -
                                                {{file.name}}
                                                <a class="secondary-content" (click)="removeFile(i)"><i
                                                        class="material-icons">close</i></a>
                                                <a class="secondary-content" (click)="viewFile(i)"><i
                                                        class="material-icons right ">open_in_browser </i></a>
                                                <!--  <a [href]="file.link" target="_blank"><i class="material-icons"> open_in_new </i></a>  -->

                                            </div>
                                        </li>

                                        <li class="collection-item">
                                            <div class="center">
                                                <button class=" btn-small blue" type="button"
                                                    (click)="addAnotherFile()">Add
                                                    Supporting Document</button>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div id="FileUploadModal" class="modal">
                                <div class="modal-content">
                                    <h6>Add Supporting Document</h6>

                                    <div class="row">
                                        <div class=" col s12">
                                            <label for="document_type">Document Type<span
                                                    class="red-text point">*</span></label>
                                            <select id="fileType" formControlName="document_type"
                                                class="validate browser-default" (change)="showUploadInput()">
                                                <option value="" disabled selected>Please Select</option>
                                                <option *ngFor="let type of fileTypes" [value]="type">{{type}}</option>
                                            </select>
                                        </div>

                                        <div class="col s12 file-field input-field" *ngIf="showUpload2">
                                            <div class="btn">
                                                <span>Browse <i class="material-icons">file_upload </i></span>
                                                <input type="file" formControlName="document"
                                                    accept="image/*, application/pdf"
                                                    (change)="handleFileInput($event.target.files)" />
                                            </div>
                                            <div class="file-path-wrapper">
                                                <input class="file-path validate" type="text"
                                                    placeholder="No File Choosen" />
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="modal-footer">
                                    <a class="modal-close btn red " (click)="cancelUploadFile()"
                                        style="margin-right: 5px;">Cancel</a>
                                    <a class="btn green" (click)="uploadFile(otherInfoForm.value)">Upload File</a>
                                </div>
                            </div>

                            <div class="row">
                                <div class="col s6">
                                    <button class="btn left waves-effect red" type="button" style="margin-bottom: 15px"
                                        (click)="onBack()"><i class="material-icons left">arrow_back</i>Back
                                    </button>
                                </div>
                                <div class="col s6">
                                    <button class=" btn right  green" type="submit"
                                        [disabled]="!otherInfoForm.valid && documentsControls.controls.length==0"
                                        style="margin-bottom: 15px" title="Save">Next <i
                                            class="material-icons right">arrow_forward</i></button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div class="row">
                    <div class="row" *ngIf="step > 0">
                        <!-- preview  -->
                        <div *ngIf="step == 10" class="row col s12">
                            <div class="teal lighten-2 white-text"
                                style="margin-top: 25px; padding-top: 5px; padding-bottom: 5px;">
                                <!-- <i class="material-icons medium valign-wrapper prefix left" style="cursor: pointer;">navigate_before</i> -->
                                <h5 class="center-align">Preview Application</h5>
                            </div>

                            <div class="row card white">
                                <div class="section"></div>
                                <ul class="row col s12 collection">
                                    <li class="section row collection-item">
                                        <div class="col s12">
                                            <h5 class="col s8"><strong>Part A: STUDENT INFORMATION </strong></h5>
                                            <a class="col s4 blue-text right waves-effect waves-light btn-flat"
                                                (click)="editSection(1)" style="cursor: pointer;"><i
                                                    class="material-icons left">edit</i>Edit</a>
                                        </div>
                                        <div class="col s12">
                                            <p class="col s12 m6"> <strong>First Name : </strong> {{member.GivenName}}
                                            </p>
                                            <p class="col s12 m6"> <strong>Last Name : </strong>{{member.LastName}} </p>
                                            <p class="col s12 m6"> <strong>Date of Birth : </strong> {{this.member.DOB}}
                                            </p>

                                            <p class="col s12 m6"> <strong>Marital Status : </strong>
                                                {{studentModel.maritalStatus}}</p>

                                            <h6 class="col s12"><strong><span class="blue-text">MAILING
                                                        ADDRESS</span></strong>
                                            </h6>
                                            <p class="col s12 m6"> <strong>Address : </strong> {{studentModel.address}}
                                            </p>
                                            <p class="col s12 m6"> <strong>City : </strong> {{studentModel.city}}</p>
                                            <p class="col s12 m6"> <strong>Province : </strong>{{studentModel.province}}
                                            </p>
                                            <p class="col s12 m6"> <strong>Postal Code :
                                                </strong>{{studentModel.postalCode}}
                                            </p>

                                            <h6 class="col s12"><strong><span class="blue-text">OTHER
                                                        INFORMATION</span></strong></h6>
                                            <p class="col s12 m6"> <strong>Home Number
                                                    :</strong>{{studentModel.Telephone}} </p>
                                            <p class="col s12 m6"> <strong>Mobile Number : </strong>
                                                {{studentModel.mobile}}</p>

                                            <p class="col s12 m6"> <strong>SIN : </strong> {{studentModel.SIN}}</p>
                                            <p class="col s12 m6"> <strong>Email : </strong>{{studentModel.email}} </p>

                                            <p class="col s12 m6"> <strong>Health Care Number :
                                                </strong>{{studentModel.HealthCareNo}}
                                            </p>
                                            <p class="col s12 m6"> <strong>ISN Number : </strong>
                                                {{studentModel.IndianStatusNo}}</p>

                                            <p class="col s12 m6"> <strong>Usually Redide :
                                                </strong>{{studentModel.reside}}
                                            </p>
                                            <p class="col s12 m6"> <strong>Location : </strong>{{studentModel.location}}
                                            </p>
                                            <p class="col s12 m6"> <strong>Reside City/Town :
                                                </strong>{{studentModel.other_location}}
                                            </p>

                                            <p class="col s12 m6"> <strong>Bank Name : </strong>{{studentModel.bank}}
                                            </p>
                                            <p class="col s12 m6"> <strong>Bank No : </strong>{{studentModel.bankno}}
                                            </p>
                                            <p class="col s12 m6"> <strong>Transit No : </strong>{{studentModel.branch}}
                                            </p>
                                            <p class="col s12 m6"> <strong>Account No :
                                                </strong>{{studentModel.account}} </p>

                                            <p class="col s12 m6"> <strong>Employed : </strong>{{studentModel.employed}}
                                            </p>
                                            <p class="col s12 m6"> <strong>Employer Name :
                                                </strong>{{studentModel.employer }}
                                            </p>
                                            <p class="col s12 m6"> <strong>Leave of Absence :
                                                </strong>{{studentModel.leaveofabsence }}
                                            </p>
                                            <p class="col s12 m6"> <strong>With Pay : </strong>{{studentModel.withpay }}
                                            </p>
                                            <p class="col s12 m6"> <strong>Income : </strong>{{studentModel.income }}
                                            </p>

                                            <p class="col s12 m6"> <strong>Spouse Classification :
                                                </strong>{{studentModel.spouse_classification}} </p>
                                            <p class="col s12 m6"> <strong>Spouse Income :
                                                </strong>{{studentModel.spouse_income}} </p>

                                            <h6 class="col s12"><strong><span class="blue-text">Housing
                                                        Requirements</span></strong>
                                            </h6>
                                            <p class="col s12 m6"> <strong>House :</strong>{{house_description}} </p>
                                        </div>
                                    </li>
                                </ul>

                                <ul class="row col s12 collection">
                                    <li class="section row collection-item">
                                        <div class="row col s12">
                                            <h5 class="col s8"><strong>Part B: FAMILY INFORMATION </strong></h5>
                                        </div>

                                        <div class="row col s12">
                                            <div class="section row col s12">
                                                <h6 class="col s12"><strong><span class="blue-text">Applicant
                                                            Information</span></strong> </h6>

                                                <p class="col s12 m6"><strong>First Name :</strong>
                                                    {{this.member.GivenName}}
                                                </p>
                                                <p class="col s12 m6"><strong>Last Name :</strong> {{member.LastName}}
                                                </p>
                                                <p class="col s12 m6"><strong>Maritl Status: </strong>
                                                    {{maritalStatusValue}}
                                                </p>
                                                <p class="col s12 m6"> <strong>Education Level :</strong>
                                                    {{applicant_Edu_level}}</p>
                                            </div>
                                        </div>

                                        <div class="row col s12">
                                            <h6 class="col s12"><strong><span class="blue-text">Family Members
                                                        Information</span></strong> </h6>
                                            <a class="col s4 blue-text right waves-effect waves-light btn-flat"
                                                (click)="editSection(2)" style="cursor: pointer;"><i
                                                    class="material-icons left">edit</i>Edit</a>
                                        </div>

                                        <p class="col s12" *ngIf="tmpMemberModel.length==0">No Member Added</p>
                                        <div class="row col s12" *ngIf="tmpMemberModel.length > 0">
                                            <div class="section row col s12 "
                                                *ngFor="let child of tmpMemberModel; let i=index;">
                                                <p class="col s12 m6"><strong>First Name: </strong> {{child.given_name}}
                                                </p>
                                                <p class="col s12 m6"><strong>Last Name: </strong> {{child.last_name}}
                                                </p>
                                                <p class="col s12 m6"> <strong>Date of Birth :</strong> {{child.dob}}
                                                </p>
                                                <p class="col s12 m6"> <strong>Gender :</strong> {{child.gender}}</p>
                                                <p class="col s12 m6"> <strong>Classification :</strong>
                                                    {{child.Relationship}}
                                                </p>
                                                <!--   <p class="col s12 m6"> <strong>Education Level :</strong> {{child.edu_level}}</p>  -->
                                                <p class="col s12 m6"><strong>Health Care #: </strong>
                                                    {{child.ChildHealthCareNo}}</p>
                                                <p class="col s12 m6"><strong>ISN: </strong> {{child.ISNofChild}}</p>
                                                <p class="col s12 m6"> <strong>Childcare Level :</strong>
                                                    {{child.child_care_level}}</p>
                                                <p class="col s12 m6"> <strong>Childcare Category :</strong>
                                                    {{child.child_care_category}}</p>
                                            </div>
                                        </div>
                                    </li>
                                </ul>

                                <ul class="row col s12 collection">
                                    <li class="section row collection-item">
                                        <div class="col s12">
                                            <h5 class="col s8"><strong>Part C: EDUCATION </strong></h5>
                                        </div>

                                        <div class="row col s12">
                                            <h6 class="col s12"><strong><span class="blue-text">Section 1: High
                                                        School</span></strong>
                                            </h6>
                                            <a class="col s4 blue-text right waves-effect waves-light btn-flat"
                                                (click)="editSection(3)" style="cursor: pointer;"><i
                                                    class="material-icons left">edit</i>Edit</a>
                                        </div>
                                        <div class="col s12">
                                            <p class="col s12 m6"> <strong>School Attended : </strong>
                                                {{studentModel.school}}
                                            </p>
                                            <p class="col s12 m6"> <strong>Year : </strong>{{studentModel.school_year}}
                                            </p>
                                            <p class="col s12 m6"> <strong>Grade Achieved: </strong>
                                                {{studentModel.grade}}</p>
                                            <p class="col s12 m6"> <strong>Completed :
                                                </strong>{{studentModel.completed}} </p>
                                            <p class="col s12 m6"> <strong>Transcripts: </strong>
                                                {{studentModel.transcript}}
                                            </p>
                                        </div>
                                        <div class="divider"></div>

                                        <div class="row col s12">
                                            <h6 class="col s12"><strong><span class="blue-text">Section 2: Technical
                                                        Institute/College</span></strong> </h6>
                                            <a class="col s4 blue-text right waves-effect waves-light btn-flat"
                                                (click)="editSection(4)" style="cursor: pointer;"><i
                                                    class="material-icons left">edit</i>Edit</a>
                                        </div>

                                        <p class="col s12" *ngIf="tmpCollegeModel.length==0">No College Added</p>
                                        <div class="row col s12" *ngIf="tmpCollegeModel.length > 0">
                                            <div class="section row col s12 "
                                                *ngFor="let college of tmpCollegeModel; let i=index;">
                                                <p class="col s12 m6"><strong>Institute/College : </strong>
                                                    {{college.institute}}</p>
                                                <p class="col s12 m6"><strong>Year : </strong> {{college.college_year}}
                                                </p>
                                                <p class="col s12 m6"> <strong>Years :</strong> {{college.years}}</p>
                                                <p class="col s12 m6"> <strong>Completed :</strong>
                                                    {{college.completed1}}</p>
                                                <p class="col s12 m6"> <strong>Transcript :</strong>
                                                    {{college.transcripts1}}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="divider"></div>


                                        <div class="row col s12">
                                            <h6 class="col s12"><strong><span class="blue-text">Section 3:
                                                        University</span></strong>
                                            </h6>
                                            <a class="col s4 blue-text right waves-effect waves-light btn-flat"
                                                (click)="editSection(5)" style="cursor: pointer;"><i
                                                    class="material-icons left">edit</i>Edit</a>
                                        </div>

                                        <p class="col s12" *ngIf="tmpUniversityModel.length==0">No University Added</p>
                                        <div class="row col s12" *ngIf="tmpUniversityModel.length > 0">
                                            <div class="section row col s12 "
                                                *ngFor="let u of tmpUniversityModel; let i=index;">
                                                <p class="col s12 m6"><strong>University : </strong> {{u.institute2}}
                                                </p>
                                                <p class="col s12 m6"><strong>Year : </strong> {{u.university_year}}</p>
                                                <p class="col s12 m6"> <strong>Years :</strong> {{u.years2}}</p>
                                                <p class="col s12 m6"> <strong>Completed :</strong> {{u.completed2}}</p>
                                                <p class="col s12 m6"> <strong>Transcript :</strong> {{u.transcripts2}}
                                                </p>
                                            </div>
                                        </div>
                                        <div class="divider"></div>

                                        <div class="row col s12">
                                            <h6 class="col s12"><strong><span class="blue-text">Section 4: Other
                                                        Education</span></strong> </h6>
                                            <a class="col s4 blue-text right waves-effect waves-light btn-flat"
                                                (click)="editSection(6)" style="cursor: pointer;"><i
                                                    class="material-icons left">edit</i>Edit</a>
                                        </div>

                                        <p class="col s12" *ngIf="tmpTrainingModel.length==0">No Other Education Added
                                        </p>
                                        <div class="row col s12" *ngIf="tmpTrainingModel.length > 0">
                                            <div class="section row col s12 "
                                                *ngFor="let t of tmpTrainingModel; let i=index;">
                                                <p class="col s12 m6"><strong>Education Description : </strong>
                                                    {{t.institute3}}
                                                </p>
                                                <p class="col s12 m6"><strong>Year : </strong> {{t.training_year}}</p>

                                                <p class="col s12 m6"> <strong>Completed :</strong> {{t.completed3}}</p>
                                                <p class="col s12 m6"> <strong>Certificaton/Diploma :</strong>
                                                    {{t.transcripts3}}</p>
                                            </div>
                                        </div>
                                        <div class="divider"></div>
                                    </li>
                                </ul>

                                <ul class="row col s12 collection">
                                    <li class="section row collection-item">
                                        <div class="col s12">
                                            <h5 class="col s8"><strong>Part D: EDUCATION PLAN </strong></h5>
                                        </div>

                                        <div class="row col s12">
                                            <!--  <h6 class="col s12"><strong><span class="blue-text">Section 1: Education Plan</span></strong> </h6>  -->
                                            <a class="col s4 blue-text right waves-effect waves-light btn-flat"
                                                (click)="editSection(7)" style="cursor: pointer;"><i
                                                    class="material-icons left">edit</i>Edit</a>
                                        </div>
                                        <div class="col s12">
                                            <p class="col s12 m6"> <strong>Objective : </strong>
                                                {{studentModel.objective}}</p>
                                            <p class="col s12 m6"> <strong>Institute :
                                                </strong>{{studentModel.institute}} </p>
                                            <p class="col s12 m6"> <strong>Program/Area of Study - Category:
                                                </strong>{{studentModel.program}}</p>
                                            <p class="col s12 m6"> <strong>Area of Study - Sub-Category: </strong>
                                                {{studentModel.subprogram}}</p>
                                            <p class="col s12 m6"> <strong>Method of Delivery
                                                    :</strong>{{studentModel.delivery_method}} </p>
                                            <p class="col s12 m6"> <strong>Program Length: </strong>
                                                {{studentModel.t_years}}
                                            </p>
                                            <p class="col s12 m6"> <strong>Program Start Date :
                                                </strong>{{studentModel.program_start_date}} </p>
                                            <p class="col s12 m6"> <strong>Expected Completion Date
                                                    :</strong>{{studentModel.completion_date}} </p>

                                            <p class="col s12 m6"> <strong>Classification: </strong>
                                                {{studentModel.classification}}</p>


                                            <p class="col s12 m6"> <strong>Student ID No.: </strong>
                                                {{studentModel.studentID}}
                                            </p>
                                            <p class="col s12 m6"> <strong>Level of Education Sought: </strong>
                                                {{studentModel.education_sought}}</p>
                                            <p class="col s12 m6" *ngIf="showOtherDetails"> <strong>If Level of
                                                    Education Sought
                                                    is Other, Details: </strong> {{studentModel.other_details}}</p>

                                            <p class="col s12 "> <strong>Term of Sponsorship: </strong></p>

                                            <p class="col s12 m6" *ngIf="fall_term_checked"> <strong>Fall: </strong>
                                                Tuition:
                                                {{studentModel.fall_tution_amt}}
                                                Books: {{studentModel.fall_books_amt}} Other:
                                                {{studentModel.fall_other_amt}}
                                            </p>

                                            <p class="col s12 m6" *ngIf="winter_term_checked"> <strong>Winter: </strong>
                                                Tuition: {{studentModel.winter_tution_amt}}
                                                Books: {{studentModel.winter_books_amt}} Other:
                                                {{studentModel.winter_other_amt}}
                                            </p>

                                            <p class="col s12 m6" *ngIf="spring_term_checked"> <strong>Spring: </strong>
                                                Tuition: {{studentModel.spring_tution_amt}}
                                                Books: {{studentModel.spring_books_amt}} Other:
                                                {{studentModel.spring_other_amt}}
                                            </p>

                                            <p class="col s12 m6" *ngIf="summer_term_checked"> <strong>Summer: </strong>
                                                Tuition: {{studentModel.summer_tution_amt}}
                                                Books: {{studentModel.summer_books_amt}} Other:
                                                {{studentModel.summer_other_amt}}
                                            </p>

                                        </div>
                                        <div class="divider"></div>

                                    </li>
                                </ul>

                                <ul class="row col s12 collection">
                                    <li class="section row collection-item">
                                        <div class="col s12">
                                            <h5 class="col s8"><strong>Part E: SUPPORTING HISTORY </strong></h5>
                                        </div>

                                        <div class="row col s12">
                                            <!-- <h6 class="col s12"><strong><span class="blue-text">Section 1: Support History</span></strong> </h6>  -->
                                            <a class="col s4 blue-text right waves-effect waves-light btn-flat"
                                                (click)="editSection(8)" style="cursor: pointer;"><i
                                                    class="material-icons left">edit</i>Edit</a>
                                        </div>

                                        <p class="col s12" *ngIf="tmpHistoryModel.length==0">Support in the Past: No</p>
                                        <div class="row col s12" *ngIf="tmpHistoryModel.length > 0">
                                            <div class="section row col s12 "
                                                *ngFor="let support of tmpHistoryModel; let i=index;">
                                                <p class="col s12 m6"><strong><span class="blue-text">Sponsor Agency
                                                            :</span></strong>
                                                    {{support.agency}}</p>
                                                <p class="col s12 m6"><strong>Support Year : </strong>
                                                    {{support.support_year}}
                                                </p>
                                                <p class="col s12 m6"> <strong>Institute :</strong>
                                                    {{support.support_institute}}</p>
                                                <p class="col s12 m6"> <strong>Classification :</strong>
                                                    {{support.support_classification}}</p>
                                            </div>
                                        </div>
                                        <div class="divider"></div>

                                    </li>
                                </ul>


                                <ul class="row col s12 collection">
                                    <li class="section row collection-item" *ngIf="documentsControls.length > 0">
                                        <div class="col s12">
                                            <h5 class="col s8"><strong>LOADING DOCUMENTS</strong></h5>
                                            <a class="col s4 blue-text right waves-effect waves-light btn-flat"
                                                (click)="editSection(9)" style="cursor: pointer;"><i
                                                    class="material-icons left">edit</i>Edit</a>
                                        </div>
                                        <div class="col s12" *ngFor="let doc of tmpDocumentModel; let i=index;">
                                            <p class="col s12 m6"><strong>{{doc.name}} - </strong>{{doc.type}}</p>
                                            <!--<a [href]="doc.link" target="_blank"><i class="material-icons"> open_in_new </i></a> -->
                                            <a class="secondary-content" (click)="viewFile(i)"><i
                                                    class="material-icons">open_in_new </i></a>
                                        </div>
                                    </li>
                                </ul>


                                <div class="row col s12">

                                    <button class="col s12 m4 l3 btn left waves-effect red" type="button"
                                        style="margin-bottom: 15px" (click)="onBack()"><i
                                            class="material-icons left">arrow_back</i>Back</button>
                                    <button type="button" (click)="submitPreview()"
                                        class="col s12 m4 l3 offset-m4 waves-effect right blue btn">Next <i
                                            class="material-icons right">arrow_forward</i></button>
                                </div>
                            </div>
                        </div>

                        <!-- Declare step == 11 && !showSignaturePad   -->
                        <!--  <form *ngIf="step == 11 && !showSignaturePad" class="row col s12 card white" [formGroup]="declarationForm"
            (ngSubmit)="submitDeclaration(declarationForm.value)"> -->

                        <div *ngIf="step == 11 && !showSignaturePad" class="row col s12 card white"
                            [formGroup]="declarationForm" (ngSubmit)="submitDeclaration(declarationForm.value)">

                            <div class="row col s12 teal lighten-2 white-text">
                                <!--  <h5 class="center-align">PART E: DECLARATION</h5>  -->
                                <h5 class="center-align">PART G: DECLARATION</h5>
                            </div>
                            <div class="row card-content">
                                <p>I hereby undertake the following as conditions for sponsorship by the Post-Secondary
                                    Student
                                    Support
                                    Program (PSSSP) of the {{nationName}} for the duration of my program of studies:</p>
                                <ol>
                                    <li>To attend classes on a regular basis.</li>
                                    <li>To consult with my counselor if any problems arise academically, emotionally,
                                        physically
                                        or
                                        financially.</li>
                                    <li>To adhere to sponsorship regulations and meet the academic requirements for
                                        continuation
                                        for my
                                        program of studies. </li>
                                    <li>To provide my transcript of marks and report to PSSSP upon completion of each
                                        academic
                                        year.
                                    </li>
                                    <li>To adhere to sponsorship rules and regulations stated in the Post-Secondary
                                        Student
                                        Support
                                        Handbook. </li>
                                    <li>To consult with counselor of any changes of residence, dependents, etc. </li>
                                    <li>I declare that the information provided by me on the application form is
                                        complete,
                                        correct and
                                        is given in order to substantiate my entitlement for sponsorship. </li>
                                    <li>
                                        <p>I, </p>
                                        <div class="input-field col s12">
                                            <input id="name" type="text" class="validate" formControlName="name"
                                                style="text-transform: capitalize;">
                                            <label for="name">Applicant Name<span class="red-text">*</span></label>
                                        </div>
                                        <p>authorize the PSSSP to obtain any information required to determine my and/or
                                            dependent(s)
                                            eligibility for sponsorship.</p>
                                    </li>
                                    <li>I hereby authorize the release of my mid-term/final marks, progress reports, and
                                        attendance
                                        records.</li>
                                    <li>I hereby give permission to the {{nationName}} Post-Secondary Support Program to
                                        verify or confirm with any source the correctness and accuracy of the
                                        information
                                        contained in
                                        this application. </li>
                                </ol>

                                <div class="row input-field col s12" *ngIf="!signed">
                                    <div class="row col s12">
                                        <h6><strong>Would you like to Sign the Application ?</strong></h6>
                                    </div>
                                    <div class="row col s12">
                                        <label class="col s6">
                                            <input name="sign_app" formControlName="sign_app" type="radio" value="yes"
                                                (click)="onSignYes($event)" />
                                            <span class="text"><strong>Yes</strong></span>
                                        </label>
                                        <label class="col s6">
                                            <input name="sign_app" formControlName="sign_app" type="radio" value="no"
                                                (click)="onSignNo($event)" />
                                            <span class="text"><strong>No</strong></span>
                                        </label>
                                    </div>
                                </div>

                                <div class="row col s12">
                                    <div class="col s12 m3 l3" *ngIf="signed"> <strong>Signature: </strong> </div>
                                    <div class="col s12 m9 l9">
                                        <a *ngIf="signed" class="col s6" style="margin-top: 10px;"><i
                                                class="material-icons left green-text small">check_circle</i>Signed</a>
                                        <a *ngIf="signed" class="col s6 pink-text" style="margin-top: 10px;"
                                            (click)="clearSignature()"><i
                                                class="material-icons left pink-text">clear</i>Clear</a>
                                    </div>
                                </div>

                                <div class="input-field col s12 m6" *ngIf="showSignedDate">
                                    <!-- *ngIf="signed"-->
                                    <i class="material-icons prefix blue-text">calendar_today</i>
                                    <input id="date" type="text" class="input-box" formControlName="date"
                                        angular-mydatepicker name="mydate" (click)="dp8.toggleCalendar()"
                                        [options]="myDpOptions" #dp8="angular-mydatepicker"
                                        (dateChanged)="onDateChange8($event)" />
                                    <label for="date">Date Signed (dd/mm/yyyy)<span class="red-text">*</span></label>
                                </div>
                            </div>

                            <button class="col s12 m5 l3 btn left waves-effect blue" type="button"
                                style="margin-bottom: 15px" (click)="onBack()"><i
                                    class="material-icons left">arrow_back</i>Back
                            </button>

                            <a class="col s12 m5 offset-m2 l3 btn right waves-effect green"
                                (click)="submit_application()" *ngIf="create_new_app"
                                style="margin-bottom: 15px;">Submit Application <i
                                    class="material-icons right">save</i></a>

                            <a class="col s12 m5 offset-m2 l3 btn right waves-effect green"
                                (click)="submit_application()" *ngIf="update_app" style="margin-bottom: 15px;">Update &
                                Send Application<i class="material-icons right">save</i></a>

                        </div>

                        <!--   step == 11 -->
                        <form *ngIf="step == 11 && showSignaturePad" class="row card white"
                            [formGroup]="declarationForm" (ngSubmit)="submitSection6(declarationForm.value)">


                            <div class="row s12 card-panel grey lighten-4 center">
                                <div class="row">
                                    <h6 class="blue-text text-darken-2 center"><strong>Applicant'S SIGNATURE</strong>
                                    </h6>
                                    <br>
                                    <canvas></canvas>
                                </div>
                            </div>

                            <div class="section"></div>

                            <button class="btn center green lighten-1 col s12 white-text" type="submit"
                                style="margin-bottom: 15px" title="Save"> Sign <i
                                    class="material-icons left">save</i></button>
                            <br>
                            <a class="at-btn btn-flat center red lighten-1 col s12 white-text"
                                (click)="clearSignature()" style="margin-bottom: 15px;"><i
                                    class="material-icons left">loop</i> Clear Signature</a>
                            <br>
                            <a [ngClass]="'at-btn col s12 btn waves-effect ' + themeColor" (click)="onBack1()"><i
                                    class="material-icons left">arrow_back_ios</i> Back To My Application </a>
                        </form>
                    </div>
                </div>

                <app-firebase-loading *ngIf="loading"></app-firebase-loading>
            </div>
        </div>
    </main>

    <div id="messageModal" class="modal">
        <div class="modal-content">
            <h5><strong><span class="blue-text">Application</span></strong></h5>
            <br>
            <h6><strong> Thank you for sending your application {{student_firstname}}, Our business hours are Monday -
                    Friday, 8:00 AM to 5:00 PM, we will notify when your application is processed </strong></h6>
        </div>

        <div class="modal-footer">
            <button class="blue btn white-text col s12" style="margin-bottom: 15px;"
                (click)="backClicked2()">Close</button>
        </div>
    </div>

    <div id="DocumentsLoadModal" class="modal">
        <div class="modal-content">
            <h5><strong><span class="blue-text">Supporting Documents</span></strong></h5>
            <br>
            <p>Would you like to upload a new document ?</p>
        </div>
        <div class="modal-footer">
            <button type="button" (click)="addAnotherFile()" class="btn waves-effect green white-text"
                style="margin-right: 15px;">
                <i class="material-icons right white-text">check</i> Yes
            </button>

            <button type="button" (click)="closeDocUpload()" class="btn waves-effect red white-text"
                style="margin-right: 15px;">
                <i class="material-icons right white-text">close</i> No
            </button>
        </div>
    </div>

    <div id="FamilyMemberModal" class="modal">
        <div class="modal-content">
            <h5><strong><span class="blue-text">Family Member</span></strong></h5>
            <br>
            <p>Does the dependant require Child Care ?</p>
        </div>
        <div class="modal-footer">
            <button type="button" (click)="childcareRequiredYes()" class="btn waves-effect green white-text"
                style="margin-right: 15px;">
                <i class="material-icons right white-text">check</i> Yes
            </button>

            <button type="button" (click)="childcareRequiredNo()" class="btn waves-effect red white-text"
                style="margin-right: 15px;">
                <i class="material-icons right white-text">close</i> No
            </button>
        </div>
    </div>

    <div id="SignedModal" class="modal">
        <div class="modal-content">
            <h5><strong><span class="blue-text">Submit Application</span></strong></h5>
            <br>
            <p>Are you ready to submit the application ?</p>
        </div>
        <div class="modal-footer">
            <button type="button" (click)="ReadytoSubmitYes()" class="btn waves-effect green white-text"
                style="margin-right: 15px;">
                <i class="material-icons right white-text">check</i> Yes
            </button>

            <button type="button" (click)="ReadytoSubmitNo()" class="btn waves-effect red white-text"
                style="margin-right: 15px;">
                <i class="material-icons right white-text">close</i> No
            </button>
        </div>
    </div>
</div>