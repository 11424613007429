

export class PseHighSchoolModel {

    applicantID: number;
    school: string;
    school_year: number;
    grade: string;
    completed: string;
    transcript: string;
    funding_app_id: number;
}
