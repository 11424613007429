import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { StaticContentModel } from 'src/app/models/StaticContentModel';
import { JobOffersModel } from 'src/app/models/JobOffersModel';
import { AuthService } from 'src/app/services/firebase/auth.service';

@Injectable({
  providedIn: 'root'
})
export class JobOfferService {

  constructor(private db: AngularFirestore, private authService: AuthService) { }

  contentListRef: AngularFirestoreCollection<any>;
  contentRef: AngularFirestoreDocument<any>;

  addJobOffers(model: JobOffersModel) {
    const offer = {
      /*
      id : this.db.createId(),
      uid : this.authService.loggedInUser.uid,
      title: model.title,
      description : model.description,
      skills: model.skills,
      duration: model.duration,
      number_of_hours: model.number_of_hours,
      wage_period: model.wage_period,
      wage: model.wage,
      */
      id : this.db.createId(),
      uid : this.authService.loggedInUser.uid,
      title: model.title,
      description : model.description,
      skills: model.skills,
      duration: model.duration,
      officer: model.officer,
      email: model.email,
      phone: model.phone,
      employer: model.employer

    };
    return this.db.collection('JobOffers').doc(offer.id).set(offer);
  }

  getJobOfferByID(id: string): AngularFirestoreDocument<any> {
    this.contentRef = this.db.doc('JobOffers/' + id);
    return this.contentRef;
  }

  getJobOffersList(): AngularFirestoreCollection<any[]> {
    return this.db.collection('JobOffers', ref => ref.where('uid', '==', this.authService.loggedInUser.uid));
  }

  getAllJobs(): AngularFirestoreCollection<any[]> {
    return this.db.collection('JobOffers');
  }

  UpdateJobOffer(model: any) {
    const offer = {
      id: model.id,
      uid:model.uid,
      title: model.title,
      description: model.description, 
      skills:model.skills,  
      duration: model.duration,  
      officer: model.officer,  
      email: model.email,   
      phone:  model.phone, 
      employer: model.employer,   
    };
    return this.db.collection('JobOffers').doc(model.id).update(offer);
  }

  PublishJobOffer(model: any) {
    const offer = {
    };
    return this.db.collection('JobOffers').doc(model.id).update(offer);
  }

  UnPublishJobOffer(model: any) {
    const offer = {
    };
    return this.db.collection('JobOffers').doc(model.id).update(offer);
  }

  DeleteJobOffer(model: any) {
    return this.db.collection('JobOffers').doc(model.id).delete();
  }

  PostJobOffer(model: any) {
    return this.db.collection('JobOffers').doc(model.id).set(model);
  }

  EditJobOffer(model: any) {
    return this.db.collection('JobOffers').doc(model.id).update(model);
  }


}
