

export class IncomeSupportAppModel {

    applicantID: number;
    firstName: string;
    lastName: string;
    dob: string;
    isn: string;
    bandName: string;
    TreatyNo: string;
    maritalStatus: string;
    province: string;
    telephone: string;
    address: string;
    postalCode: string;
    healthCareNo: string;
    SIN: string;
    EDU_LEVEL: string;

    previous_address: '';
    previous_address_startDate: string;
    previous_address_endDate: string;

    education: '';
    education_startDate: string;
    education_endDate: string;

    previous_employer: '';
    previous_employment_address: '';
    previous_employment_position: '';
    previous_employment_startDate: Date;
    previous_employment_endDate: Date;

    present_employer: '';
    present_employment_address: '';
    present_employment_position: '';
    present_employment_startDate: Date;
    present_employment_endDate: Date;
    present_employment_iscurrent: boolean;

    spouse_aid: number;
    spouseFirstName: string;
    spouseLastName: string;
    spouseDob: string;
    spouseISN: string;
    spouseHealtCareNo: string;  
    spouseSIN: string;
    spouseProvince: string;

    previous_address_spouse: '';
    previous_address_startDate_spouse: string;
    previous_address_endDate_spouse: string;

    education_spouse: '';
    education_startDate_spouse: string;
    education_endDate_spouse: string;

    previous_employer_spouse: '';
    previous_employment_address_spouse: '';
    previous_employment_position_spouse: '';
    previous_employment_startDate_spouse: Date;
    previous_employment_endDate_spouse: Date;

    present_employer_spouse: '';
    present_employment_address_spouse: '';
    present_employment_position_spouse: '';
    present_employment_startDate_spouse: Date;
    present_employment_endDate_spouse: Date;
    present_employment_iscurrent_spouse:  boolean;
    
    //dateOfLastAssistance: Date;
    //administrativeAuthority: '';
    //amount: '';

    car1_make: string; 
    car2_make: string;
    car1_year: string; 
    car2_year: string; 
    car1_value: number; 
    car2_value: number;


    applicant_signature: string;
    applicant_date: Date;
  
    isYesOfOthers: string;
    WhoOfOthers: string;
    relationship_headofhouse: string;
    isRentedOfOthers: string;
    isYesOfCMHC: string;
    LoanPMTOfOthers: number;
    MaintenanceOfOthers: number;
    GarbageOfOthers: number;
    FuelOfOthers: number;
    UtilitiesOfOthers: number;

    comments: '';

}