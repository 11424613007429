<div class="row">
    <div class="col s12 m2 l1" *ngIf="user">
        <app-side-menus [user]="user"></app-side-menus>
    </div>
    <div class="col s12 m10 l2  hide-on-med-and-down">
        <div class="submenu-sidenav">
            <ul class=" submenu-contents">

                <div class="hide-on-med-and-down">
                    <ul style="cursor: pointer;">
                        <ng-template [ngTemplateOutlet]="menus"></ng-template>
                    </ul>
                </div>

                <ng-template #menus class="">
                    <li [routerLink]="['/admin/applications/list']" class="active"
                        (click)="select('Applications List', 'apps')">
                        <i class="material-icons-outlined black-text">phonelink_setup</i>
                        <a class="black-text">Applications</a>
                    </li>

                    <li [routerLink]="['/admin/applications/create']"
                        (click)="select('New Application', 'dashboard_customize')">
                        <i class="material-icons-outlined black-text">dashboard_customize</i>
                        <a class="black-text"> Create New Application</a>
                    </li>

                    <li [routerLink]="['/admin/applications/incoming-apps/post-secondary']"
                        (click)="select('Post Secondary', 'school')">
                        <i class="material-icons-outlined black-text">school</i>
                        <a class="black-text">Post Secondary</a>
                    </li>

                    <li [routerLink]="['/admin/applications/incoming-apps/income-assistance']"
                        (click)="select('Income Assistance', 'request_quote')">
                        <i class="material-icons-outlined black-text">request_quote</i>
                        <a class="black-text">Income Assistance</a>
                    </li>

                    <!-- class="modal-trigger" data-target="underConstructionModal" -->
                    <li  [routerLink]="['/admin/applications/incoming-apps/housing']"
                        (click)="select('Housing', 'cottage')">
                        <i class="material-icons-outlined black-text ">cottage</i>
                        <a class="black-text">Housing</a>
                    </li>

                    <li class="modal-trigger" data-target="underConstructionModal"
                        (click)="select('ISETS', 'miscellaneous_services')">
                        <i class="material-icons-outlined black-text">miscellaneous_services</i>
                        <a class="black-text">ISETS</a>
                    </li>

                    <li class="modal-trigger" data-target="underConstructionModal"
                        (click)="select('Lands', 'crop_landscape')">
                        <i class="material-symbols-outlined black-text">crop_landscape</i>
                        <a class="black-text">Lands</a>
                    </li>

                    <li class="modal-trigger" data-target="underConstructionModal"
                        (click)="select('CFS', 'family_restroom')">
                        <i class="material-icons-outlined black-text">family_restroom</i>
                        <a class="black-text">CFS</a>
                    </li>

                    <li [routerLink]="['/admin/applications/lrrcn-list']" (click)="select('IA Survey', 'list_alt')">
                        <i class="material-icons-outlined black-text">list_alt</i>
                        <a class="black-text"> IA Survey</a>
                    </li>

                    <li (click)="backClicked()">
                        <i class="material-icons-outlined black-text">arrow_back</i>
                        <a class="black-text">Back</a>
                    </li>
                </ng-template>
            </ul>
        </div>
    </div>

    <div class="row col s12 m12 l9 ">
        <div class="hide-on-large-only drop-down valign-wrapper submenu-contents">
            <a class='col s12 m12 dropdown-trigger btn-flat hide-on-large-only black-text' data-target='menu-dropdown'>
                <i class="material-icons left">{{selectedMenu.icon}}</i>{{selectedMenu.name}}<i
                    class="material-icons col s2 right">expand_more</i></a>
            <!-- Dropdown Structure -->
            <ul id='menu-dropdown' class='dropdown-content col s12 m12 '>
                <ng-template [ngTemplateOutlet]="menus" class="drop-list"></ng-template>
            </ul>
        </div>
        <router-outlet></router-outlet>
    </div>
</div>

<div id="underConstructionModal" class="modal center b-n-1">
    <div class="modal-content">
        <i class="material-icons right modal-close">close</i><br />
        <h6 class="title-section2 bold-600 black-text">Under Construction</h6>
        <p class="sub-title-section bold-100">We’re working hard to make this feature available soon. </p>
        <img src="https://img.icons8.com/wired/344/tow-truck.png" height="150px" />
        <p class="sub-title-section bold-100">In the meantimee if you need some support? Contact us on info&#64;mynation.app
        </p>
        <p class="sub-title-section bold-100">Please Contanct your Administrator to gain access.</p>
    </div>
</div>