<h6 class="center p-m-2"><strong>Share The New File Whith :</strong></h6>
<div class="section"></div>

<div class="row">
    <div class="col l1"></div>
    <div class="col s12 l10">
        <div *ngIf="step1SelectVisibility">
            <div class="row col s12">
                <div class="card-panel col s12 hoverable" (click)="shareWithPublicClick();">
                    <div class="row center col s12">
                        <p><i class="material-icons green-text">groups</i></p>
                        <p>
                            <strong>Public</strong> <br />
                            (All Registred Members)
                        </p>
                    </div>
                </div>
                <div class="card-panel col s12 hoverable" (click)="shareWithGroupClick();">
                    <div class="row center col s12">
                        <p><i class="material-icons blue-text">people</i></p>
                        <p>
                            <strong class="blue-text">Groups</strong> <br />
                            (Chief, Admins, Councillors And Employees )
                        </p>
                    </div>
                </div>
                <div class="card-panel col s12 hoverable" (click)="shareWithSpecificMemberClick();">
                    <div class="row center col s12">
                        <p><i class="material-icons orange-text text-darken-2">person_search</i></p>
                        <p>
                            <strong class="orange-text text-darken-2">Specific Members</strong> <br />
                            (Invited Members Only)
                        </p>
                    </div>
                </div>
                <div class="card-panel col s12 hoverable">
                    <div class="row center col s12">
                        <p><i class="material-icons red-text">enhanced_encryption</i></p>
                        <p>
                            <strong class="red-text">Private</strong> <br />
                            (Only Me Can See This File)
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="step2SelectGroup">
            <div class="row card col s12">
                <div class="row col s12">
                    <p>
                        <label>
                            <input type="checkbox" [checked]="shareWithAdmins" (change)="shareWithAdminsCheck();" />
                            <span><strong>Administrators</strong> </span>
                        </label>
                    </p>
                    <div class="row" *ngIf="adminList">
                        <table class="striped">
                            <thead>
                                <tr>
                                    <th class="center">Select</th>
                                    <th class="center">Administrator Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let a of adminList">
                                    <td class="center">
                                        <label>
                                            <input type="checkbox" [checked]="a.return.isChecked"
                                                (change)="CheckedChange(a);" />
                                            <span></span>
                                        </label>
                                    </td>
                                    <td class="center">
                                        <strong>{{a.return.LastName + ' ' + a.return.FirstName}}</strong>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <p>
                        <label>
                            <input type="checkbox" [checked]="shareWithChief" (change)="shareWithChiefClick();" />
                            <span><strong>Chief</strong> </span>
                        </label>
                    </p>
                    <div class="row" *ngIf="shareWithChief && chiefModel">
                        <table class="striped">
                            <thead>
                                <tr>
                                    <th class="center">Select</th>
                                    <th class="center">Chief Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="center">
                                        <label>
                                            <input type="checkbox" [checked]="chiefModel.isChecked" />
                                            <span></span>
                                        </label>
                                    </td>
                                    <td class="center">
                                        <strong>{{chiefModel.lastName + ' ' + chiefModel.firstName}}</strong>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <p>
                        <label>
                            <input type="checkbox" [checked]="shareWithCouncillors"
                                (change)="shareWithCouncilorsClick();" />
                            <span><strong>Councillors</strong> </span>
                        </label>
                    </p>
                    <div class="row" *ngIf="shareWithCouncillors && governanceModel.councillors">
                        <table class="striped">
                            <thead>
                                <tr>
                                    <th class="center">Select</th>
                                    <th class="center">Councillor Name</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let a of governanceModel.councillors">
                                    <td class="center">
                                        <label>
                                            <input type="checkbox" [checked]="a.isChecked" />
                                            <span></span>
                                        </label>
                                    </td>
                                    <td class="center">
                                        <strong>{{a.lastName + ' ' + a.firstName}}</strong>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>

            <div class="row">
                <button type="button" (click)="GoToFileUpload()" class="btn  col s12 green">Share File <i
                        class="material-icons right">cloud</i></button>
            </div>
        </div>

        <div *ngIf="step3SelectMembers && filtredList">
            <div class="row card-panel">
                <div class="input-field col s12 m6">
                    <i class="material-icons prefix blue-text">portrait</i>
                    <input id="lastName" type="text" [(ngModel)]="lnameFilter" (input)="filterResult();"
                        autocomplete="off" />
                    <label for="lastName">Last Name</label>
                </div>
                <div class="input-field col s12 m6">
                    <i class="material-icons prefix teal-text">portrait</i>
                    <input id="firstName" type="text" [(ngModel)]="fnameFilter" (input)="filterResult();"
                        autocomplete="off" />
                    <label for="firstName">First Name</label>
                </div>
            </div>
            <div class="row">
                <table class="striped">
                    <thead>
                        <tr>
                            <th class="center">Select</th>
                            <th class="center">Member Name</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let a of filtredList">
                            <td class="center">
                                <label>
                                    <input type="checkbox" [checked]="a.return.isChecked"
                                        (change)="CheckedChange(a);" />
                                    <span></span>
                                </label>
                            </td>
                            <td class="center">
                                <strong>{{a.return.LastName + ' ' + a.return.GivenName}}</strong>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="row">
                <button type="button" class="btn  col s12 green" (click)="GoToFileUpload()">Share File <i
                        class="material-icons right">cloud</i></button>
            </div>
        </div>

        <div *ngIf="step4uploadStep">
            <div class="row card-panel col s12">
                <p><strong>Please Select A File To Upload</strong></p>
                <div class="row col s12">
                    <div class="file-field input-field">
                        <div class="btn">
                            <span>File</span>
                            <input type="file" (change)="handleFileInput($event.target.files)">
                        </div>
                        <div class="file-path-wrapper">
                            <input class="file-path validate" type="text">
                        </div>
                    </div>
                </div>

                <div class="row col s12" *ngIf="showProgress">
                    <p class="center"><strong><i class="material-icons blue-text">backup</i> Uploading And Sharing Your
                            File</strong> </p>
                    <p class="center green-text"> {{uploadProgerss}}% <span class="black-text">Completed</span></p>
                    <div class="progress ceter">
                        <div class="indeterminate"></div>
                    </div>
                </div>

                <div class="row center">
                    <button type="button" class="btn green" (click)="shareFile();"> <i class="material-icons">share</i>
                        Upload And Share </button>
                </div>
            </div>
        </div>

        <div *ngIf="step5Success">
            <div class="card">
                <div class="card-content">
                    <div class="row center">
                        <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                            <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                            <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                        </svg>
                        <p><strong>
                                Thank you <span style="text-transform: capitalize;">{{model.ownerName}} </span>
                            </strong></p>
                        <br>
                        <p>Your File Was Successfully Uploaded And Shared.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="col l1"></div>
</div>