import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { EmailService } from 'src/app/services/api/email.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { ContactService } from 'src/app/services/firebase/contact.service';
import { toast } from 'materialize-css';
import { EmailModel } from 'src/app/models/emailModel';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-user-discussion',
  templateUrl: './user-discussion.component.html',
  styleUrls: ['./user-discussion.component.css']
})

export class UserDiscussionComponent implements OnInit {

  @Input() discussionID: any;
  discussion: any[];
  contactForm: UntypedFormGroup;
  selectedMsg: any;
  senderID: any;

  constructor(
    private contactService: ContactService,
    private authService: AuthService,
    private fb: UntypedFormBuilder,
    private emailService: EmailService) { }

  ngOnInit() {
    this.senderID = this.authService.getGlobalUser().uid;
    this.contactForm = this.fb.group({
      subject: ['', [Validators.required]],
      body: ['', [Validators.required]],
    });

    this.contactService.getDiscussion(this.discussionID).valueChanges().subscribe(
      dsc => {
        if (dsc.length > 0) {
          this.discussion = dsc;
          this.discussion.sort(function (a, b): any {
            return (b.dateSent - a.dateSent);
          });
          setTimeout(() => {
            $('.collapsible').collapsible();
          }, 25);
        }
      }
    );
  }

  showEmailModal(msg: any) {
    this.selectedMsg = msg;
    this.contactForm = this.fb.group({
      subject: ['RE: ' + msg.subject, [Validators.required]],
      body: ['', [Validators.required]],
    });
    setTimeout(() => {
      $('.modal').modal();
      $('#emailModal').modal('open');
      M.updateTextFields();
    }, 25);
  }

  sendMessage(email: any) {
    const toList = [];
    const mail = new EmailModel();
    mail.emailList = toList;
    mail.subject = 'New Contact Message';
    mail.body = '<strong> You Received A New Message Please Login To Your Account </strong>';

    const responseMsg = {
      id: '',
      discussionID: this.selectedMsg.discussionID,
      departmentID: this.selectedMsg.departmentID,
      toID: this.selectedMsg.fromID,
      toEmail: this.selectedMsg.fromEmail,
      toName: this.selectedMsg.fromName,
      fromID: this.authService.getCurrentUser().uid,
      fromEmail: this.authService.getCurrentUser().email,
      fromName: localStorage.getItem('userFirstName') + ' ' + localStorage.getItem('userLastName'),
      subject: email.subject,
      body: email.body,
      dateSent: new Date(),
      isRead: false,
    };

    toList.push(this.selectedMsg.fromEmail);
    $('#emailModal').modal('close');
    this.contactService.respondToMessage(responseMsg).then(
      () => {
        this.emailService.sendEmail(mail).subscribe(e => {
          if (e) {
            // this.step1 = false;
            toast({ html: '<i class="material-icons">lock</i> Email Successfully Sent.', classes: 'green' });
          }
        });
      });
  }

  get subject() { return this.contactForm.get('subject'); }
  get body() { return this.contactForm.get('body'); }

}