import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { EmailService } from 'src/app/services/api/email.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { ContactService } from 'src/app/services/firebase/contact.service';
import { DepartmentService } from 'src/app/services/firebase/department.service';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { toast } from 'materialize-css';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';

declare var $: any;
@Component({
  selector: 'app-contact-department-public',
  templateUrl: './contact-department-public.component.html',
  styleUrls: ['./contact-department-public.component.css']
})
export class ContactDepartmentPublicComponent implements OnInit {

  id: any;
  employeeList = [];
  governanceModel: any;
  defaultThemeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  contactForm: UntypedFormGroup;
  step1 = true;
  step2 = false;
  selectedCount = 0;
  showContactForm = false;
  showCouncillorsList = false;
  selectableCouncillorsList: any[];
  modalHeader = '';
  selectedEmployee: any;
  formatedWorkPhone: any;
  formatedMobilePhone: any;
  showMsg = false;
  selectedDepName = '';
  themeColor: any;
  buttonType: any;

  nationOfUse = environment.firstNation.name;
  constructor(private route: ActivatedRoute, private departmentService: DepartmentService, private settingsService: AppSettingsService,
    private location: Location,
    private contactService: ContactService,
    private emailService: EmailService,
    private fb: UntypedFormBuilder,
    private router: Router,
    private authService: AuthService,) {
    this.themeColor = this.settingsService.getAppThemeColor() ? this.settingsService.getAppThemeColor() : this.defaultThemeColor;
    this.buttonType = this.settingsService.getButtonType() ? this.settingsService.getButtonType() : 'rectangularButton';

  }

  ngOnInit() {

    this.contactForm = this.fb.group({
      subject: ['', [Validators.required]],
      body: ['', [Validators.required]],
    });

    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });

    if (this.id) {
      this.getDepartmentInfo(this.id);

    }
    setTimeout(() => {
      $('select').formSelect();
      $('.modal').modal();
    }, 25);
  }
  getDepartmentInfo(id: any) {

    this.departmentService.getDepartmentEmployee(id).valueChanges().subscribe(
      emp => {
        try {
          if (emp) {
            if (emp.length > 0) {
              this.employeeList = emp;

            } else {


              this.employeeList = [];
              this.showMsg = true;
            }
          } else {
            this.showMsg = true;
            //i f no department found
          }
        } catch (err) {
          this.showMsg = true;

          console.log(err)
        }

      }
    );



  }
  showMobile(emp: any) {
    this.modalHeader = 'Mobile Number';
    this.selectedEmployee = emp;
    this.formatedMobilePhone = emp.mobilePhone.replace('(', '');
    this.formatedMobilePhone = this.formatedMobilePhone.replace(')', '');
    this.formatedMobilePhone = this.formatedMobilePhone.replace('-', '');
    this.formatedMobilePhone = this.formatedMobilePhone.replace(/\s/g, '');
    setTimeout(() => {
      $('.modal').modal();
      $('#mobilePhoneModal').modal('open');
    }, 25);
  }

  showWorkPhone(emp: any) {
    this.modalHeader = 'Work Phone';
    this.selectedEmployee = emp;
    this.formatedWorkPhone = emp.workPhone.replace('(', '');
    this.formatedWorkPhone = this.formatedWorkPhone.replace(')', '');
    this.formatedWorkPhone = this.formatedWorkPhone.replace('-', '');
    this.formatedWorkPhone = this.formatedWorkPhone.replace(/\s/g, '');
    setTimeout(() => {
      $('.modal').modal();
      $('#workPhoneModal').modal('open');
    }, 25);
  }

  showEmailModal(emp: any) {
    this.modalHeader = 'Send Email';
    this.selectedEmployee = emp;
    this.contactForm = this.fb.group({
      subject: ['', [Validators.required]],
      body: ['', [Validators.required]],
    });
    this.showContactForm = true;
    setTimeout(() => {
      $('.modal').modal();
      $('#emailModal').modal('open');
    }, 25);
  }

  Send(msg: any) {
    const toList = [];
    const mail = {
      emailList: toList,
      subject: 'New Contact Message',
      body: '<strong> You Received A New Email Please Login To Your Account </strong>'
    };
    const outboxMsg = {
      id: '',
      discussionID: '',
      departmentID: this.selectedEmployee.departmentId,
      toID: this.selectedEmployee.uid,
      toEmail: this.selectedEmployee.email,
      toName: this.selectedEmployee.givenName + ' ' + this.selectedEmployee.lastName,
      fromID: this.authService.getCurrentUser().uid,
      fromEmail: this.authService.getCurrentUser().email,
      fromName: localStorage.getItem('userFirstName') + ' ' + localStorage.getItem('userLastName'),
      subject: msg.subject,
      body: msg.body,
      dateSent: new Date(),
      isRead: false,
      responses: []
    };
    toList.push(this.selectedEmployee.email);
    $('#emailModal').modal('close');
    this.contactService.sendMessage(outboxMsg).then(
      () => {
        this.emailService.sendEmail(mail).subscribe(e => {
          if (e) {
            // this.step1 = false;
            toast({ html: '<i class="material-icons">lock</i> Email Successfully Sent.', classes: 'green' });
          }
        });
      });

  }

  formatWorkNumber(model: any) {
    let phone = model.workPhone;
    let ext = model.ext;

    phone = phone.replace('(', '');
    phone = phone.replace(')', '');
    phone = phone.replace('-', '');
    phone = phone.replace(/\s/g, '');

    if (ext) {
      ext = ext.replace(/\s/g, '');
      phone = phone + 'p' + ext;
    }

    return phone;
  }

  formatPhoneNumber(val: any) {
    let phone = val;
    phone = phone.replace('(', '');
    phone = phone.replace(')', '');
    phone = phone.replace('-', '');
    phone = phone.replace(/\s/g, '');
    return phone;
  }



  backClicked() {
    this.location.back();
  }

}
