<div class="col s12">
  <div class="body-h">

    <div class="header-setting b-m-2">
      <div class="col l12 m12 s12">
        <h4 class="bold-500 icon-text title-nation"> <i class="material-symbols-outlined bold-500 p-r-1 "
          (click)="backClicked()"> arrow_back </i>Admin Sessions</h4>
        <select class="browser-default hide-on-large-only" (change)="navigate($event.target.value)" >
          <option value="Admins" disabled selected>Admins</option>
          <option value="Membership">Membership</option>
          <option value="Applications">Applications</option>
          <option value="DemoRequest">Demo Request</option>
        </select>

        <p class="bold-100 sub-title-section3">It is a long established fact that a reader will be distracted by the
          readable content of a
          page when looking at its layout.</p>
      </div>
    </div>

    <main class="StickyContent white ">
      <div class="row col s12">
        <div class="row col s12 m12 l10" *ngIf="sessions && !showMessage">
          <ul class="row collapsible" *ngIf="groupedSessionsArray">
            <li *ngFor="let s of groupedSessionsArray">
              <div class="collapsible-header">
                <div class="col s12 center">
                  <div class="col s2">
                    <i class="material-icons blue-text" style="margin-right: 15px;">tap_and_play</i>
                  </div>
                  <div class="col s10">
                    <strong> {{s.date}} </strong>
                  </div>
                </div>
              </div>
              <div class="collapsible-body white">

                <ul class="collection with-header">
                  <li class="collection-header">
                    <h6>Sessions List</h6>
                  </li>
                  <li class="collection-item" *ngFor="let ss of s.sessions">
                    <div>
                      {{ss.sessionStart.toDate() | date: 'h:mm:ss a'}}
                      <a [routerLink]="['/admin/activity-detail', ss.uid]" class="secondary-content"> Session Details
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </li>
          </ul>
        </div>

        <div class="row col s12 m12 l10" *ngIf="showMessage">
          <div class="row card-panel white">
            <i class="material-icons red-text">info</i> <strong> Admin Has No Activity </strong>
          </div>
        </div>
      </div>
    </main>
    
  </div>
</div>