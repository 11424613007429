<div class="row center-align p-m-2">
  <h6> <strong> Edit Highlight </strong></h6>
</div>

<div class="row col s12">
  <div class="col l1"></div>
  <div id="step0" class="col s12 l10">
    <form (ngSubmit)="submitForm(editHighlightForm.value)" [formGroup]="editHighlightForm">
      <div class="row card-panel">

        <div class="input-field class col s12">
          <i class="material-icons prefix blue-text">work</i>
          <input formControlName="Highlight" id="Highlight" type="text" maxlength="300" autocomplete="off" require
            class="capitalize validate">
          <label for="Highlight"> Highlight : <strong class="red-text">*</strong> </label>
          <div *ngIf="(Highlight.dirty || Highlight.touched) && Highlight.invalid">
            <small *ngIf="Highlight.errors.required" class="red-text">The Highlight Is Required!
            </small>
          </div>
        </div>
      </div>

      <div class="section"></div>

      <div class="col s12">
        <button class='at-btn col s12 btn waves-effect success' style="margin-bottom: 15px;" type="submit"
          [disabled]="!editHighlightForm.valid">
          <i class="material-icons right">add_circle</i> Update Highlight
        </button>

        <button data-target="modal1" class="col s12 btn modal-trigger red " style="margin-bottom: 15px;">Remove
          Highlight</button>

        <button type="button" [ngClass]="'col s12 btn waves-effect ' + themeColor " (click)="backClicked()">
          <i class="material-icons left">keyboard_arrow_left</i> Back To Highlight List
        </button>
      </div>
    </form>
  </div>

  <div class="col l1 "></div>
</div>

<div id="modal1" class="modal">
  <div class="modal-content">
    <h5>Delete Highlight</h5>
    <strong> Do You Want to Remove This Highlight ? </strong>
  </div>
  <div class="modal-footer">
    <button type="button" (click)="DeleteHighlight()" class="btn waves-effect blue white-text"
      style="margin-right: 15px;"> Yes
    </button>
    <a class="modal-close amber darken-4 white-text btn-flat white-text">No</a>
  </div>
</div>