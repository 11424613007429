import { Component, OnInit, Input } from '@angular/core';
import { EntitlementModel } from 'src/app/models/entitlementModel';

declare var $: any;

@Component({
  selector: 'app-entitlement',
  templateUrl: './entitlement.component.html',
  styleUrls: ['./entitlement.component.css']
})
export class EntitlementComponent implements OnInit {

  @Input() model: EntitlementModel[];

  constructor() { }

  ngOnInit() {
    setTimeout(() => {
      $('.collapsible').collapsible();
      $('.tooltipped').tooltip();
    }, 25);
  }

}
