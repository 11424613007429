import { Component, Input, OnInit, SecurityContext } from '@angular/core';
//import { EmbedVideoService } from 'ngx-embed-video';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Comment } from '../../../models/StaticContentModel'
import { StaticContentService } from 'src/app/services/firebase/static-content.service';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';

@Component({
  selector: 'app-content-viewer1',
  templateUrl: './content-viewer1.component.html',
  styleUrls: ['./content-viewer1.component.css']
})
export class ContentViewer1Component implements OnInit {

  @Input() model: any;
  iframehtml: any;
  pdfURL: SafeUrl;
  buttonType: any;
  fontColor = environment.appTheme.fontColor;
  isLogged = false;
  commentOnNews: UntypedFormGroup;
  defaultThemeColor = environment.appTheme.themeColor;
  themeColor: any;
  videoId: any;

  constructor(public appSettingsService: AppSettingsService, private fb: UntypedFormBuilder, private authservice: AuthService, 
    // private embedService: EmbedVideoService,
    private location: Location, private sanitizer: DomSanitizer, private staticContentService: StaticContentService) {
      this.themeColor = this.appSettingsService.getAppThemeColor() ? this.appSettingsService.getAppThemeColor(): this.defaultThemeColor;
      this.buttonType =  this.appSettingsService.getButtonType() ? this.appSettingsService.getButtonType(): 'rectangularButton';
     }

  ngOnInit() {
    this.commentOnNews = this.fb.group({
      comment: ['', [Validators.required]]
    })
    if (this.model) {
      if (this.model.type === undefined) {
        this.model.type = 'Post';
      } else if (this.model.type === 'Video') {
        this.videoId = this.embedVideo(this.model.videoLink);
      }
      if (this.authservice.globalUser) { this.isLogged = true; } else { this.isLogged = false; }
    }
  }

  embedVideo(videoLink) {
    const videoIdMatch = videoLink.match(/(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([^&]+)|(?:https?:\/\/)?(?:www\.)?youtu\.be\/([^?]+)/);
    this.videoId = videoIdMatch ? (videoIdMatch[1] || videoIdMatch[2]) : '';
    return this.videoId;
  }

  addCommentToContent(model, value) {
    let newComment = new Comment();
    newComment.comment = value;
    newComment.userName = `${this.authservice.globalUser.GivenName} ${this.authservice.globalUser.LastName}`;
    newComment.userId = this.authservice.globalUser.uid;
    newComment.timeStamp = new Date();
    this.staticContentService.updateContentComments(model, newComment).then(resp => {
      this.commentOnNews.reset();
    });
  }
  
  backClicked() {
    this.location.back();
  }
}