<div class="section"></div>

<div class="row">
  <div class="row col s12">
    <div class="section"></div>
    <div class="row col s12 m6 l4 push-l2">
      <a (click)="memberAccountClick();" class="btn-large col s9 m12 push-s1  white-text"
        [ngStyle]="{'background-color': themeColor}" [ngClass]="'btn ' + buttonType">Demo Member Account</a>
    </div>

    <div class="row col s12 m6 l4 push-l2">
      <a (click)="adminAccountClick();" class="btn-large col s9 m12 push-s1  white  black-text "
        [ngStyle]="{'border-color': themeColor} " style="border: 2px solid;" [ngClass]="'btn ' + buttonType">Demo Admin
        Account</a>
    </div>
  </div>

  <div class="section  p-t-9"></div>
  <hr [ngStyle]="{'border-color': themeColor} " />
  <div class="col m1"></div>

  <div class="col s12 m10 p-t-4">
    <div *ngIf="step1">
      <form [formGroup]="demoRegistrationForm" (ngSubmit)="registerDemoAccount(demoRegistrationForm.value)">
        <div class="row card-panel white">
          <div class="row col l12 s12 m12 ">
            <h6 class="" style="font-weight:bolder; font-family: sans-serif; color: #4b5563;"
              *ngIf="accountType === 'Admin'">Demo Admin Account</h6>
            <h6 class="" style="font-weight:bolder; font-family: sans-serif; color: #4b5563;"
              *ngIf="accountType === 'Member'">Demo Member Account</h6>

            <div class="col s12 l12 m12  card-panel pink lighten-5" style="border-left: 3px solid red ;">
              <p class="red-text">Please make sure that you
                enter a valid email address. Once you fill out the info we will send you an email to register.
              </p>
            </div>

            <div class="input-field col s12 l6">
              <div class="label-input">User Name <span class="red-text"><strong>*</strong></span></div>
              <input id="userName" type="text" formControlName='userName' maxlength="49" required class="validate"
                autocomplete="off" style="text-transform: capitalize;" placeholder="Username" />
              <div *ngIf="(userName.dirty || userName.touched) && userName.invalid">
                <small *ngIf="userName.errors.required" class="red-text"> The User Name is required. </small>
              </div>
            </div>

            <div class="input-field col s12 l6">
              <div class="label-input">Email <span class="red-text"><strong>*</strong></span></div>
              <input id="email" type="email" formControlName='email' maxlength="49" required class="validate"
                autocomplete="off" placeholder="Email" />
              <div *ngIf="(email.dirty || email.touched) && email.invalid">
                <small *ngIf="email.errors.required" class="red-text"> The Email is required. </small>
                <small *ngIf="email.errors.email" class="red-text"> wrong email format. </small>
              </div>
            </div>

            <div class="input-field col s12 l6">
              <div class="label-input">Valid Phone Number <span class="red-text"><strong>*</strong></span></div>
              <!-- <input id="mobileNumber" type="text" formControlName='mobileNumber' required class="validate"
                autocomplete="off" [textMask]="{mask: phoneNumberMask}" placeholder="Phone Number" /> -->
              <input id="mobileNumber" type="text" [inputMask]="phoneInputMask" formControlName='mobileNumber' required
                class="validate" autocomplete="off" placeholder="Phone Number" />

              <div *ngIf="(mobileNumber.dirty || mobileNumber.touched) && mobileNumber.invalid">
                <small *ngIf="mobileNumber.errors.required" class="red-text"> The Mobile Number is required. </small>
              </div>
            </div>

            <button *ngIf="accountType === 'Member'" type="submit" style="margin-bottom: 15px;"
              class="col s12 l7 push-l3 btn " [ngStyle]="{'background-color': themeColor}"
              [ngClass]="'btn ' + buttonType">
              Create Member Account
            </button>

            <button *ngIf="accountType === 'Admin'" type="submit" style="margin-bottom: 15px;"
              class="col s12 l7 push-l3  btn " [ngStyle]="{'background-color': themeColor}"
              [ngClass]="'btn ' + buttonType">
              Create Admin Account
            </button>
          </div>
        </div>
      </form>
    </div>

    <div *ngIf="step3">
      <div class="col l5 s12 m6 push-l3">
        <div class="card">
          <div class="card-content">
            <div class="row center">

              <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
              </svg>
              <div *ngIf="accountType === 'Member'">
                <p><strong style="font-weight: bold; font-family: sans-serif;">
                    Thank you <span style="text-transform: capitalize; " class="blue-text">{{model.GivenName}} </span>
                    and
                    welcome to the {{nationName}} mobile platform.
                  </strong></p>
                <br />
                <h6> <strong class="red-text">
                    Please verify your email and complete your registration by following the registration link we sent
                    you.
                  </strong></h6>
                <br />
                <p> <strong>
                    Have a wonderful day
                  </strong></p>
              </div>

              <div *ngIf="accountType === 'Admin'">
                <p><strong>
                    Thank you <span style="text-transform: capitalize;" class="blue-text">{{model.FirstName}} </span>,
                    for
                    your interest in {{nationName}} mobile platform.
                  </strong></p>
                <br />
                <p> <strong>
                    Once we review your application we will send you a link to create an Admin Account.
                  </strong></p>
                <br />
                <p> <strong>
                    Have a wonderful day
                  </strong></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col m1"></div>
</div>