import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { EmailModel } from 'src/app/models/emailModel';
import { EmailService } from 'src/app/services/api/email.service';
import { MemberService } from 'src/app/services/api/member.service';
import { DepartmentService } from 'src/app/services/firebase/department.service';
import { environment } from 'src/environments/environment';
import { toast } from 'materialize-css';
import { Location } from '@angular/common';
import { AuthService } from 'src/app/services/firebase/auth.service';

declare var M: any;

@Component({
  selector: 'app-invite-employee',
  templateUrl: './invite-employee.component.html',
  styleUrls: ['./invite-employee.component.css']
})
export class InviteEmployeeComponent implements OnInit {

  step1 = true;
  step2 = false;
  departmentId: any;
  searchMemberForm: UntypedFormGroup;
  inviteMemberForm: UntypedFormGroup;
  searchResult: any[];
  validateSin = false;
  validateIsn = false;
  validateDob = false;
  isManager = false;
  selectedMember: any;
  showMobileNo = 'Public';
  showOfficeNo = 'Public';
  showEmailAdr = 'Public';
  allowContact = 'Public';
  manageAccount = false;
  manageContact = false;
  manageDepartmentInfo = false;
  manageDepartmentNews = false;
  manageDepartmentEmployees = false;
  manageSubDepartments = false;
  canManageEmployees = false;
  public phoneNumberMask = ['(', /[0-9]/, /[0-9]/, /[0-9]/, ')',
    ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];

  constructor(
    private authService: AuthService,
    private formBuilder: UntypedFormBuilder,
    private memberService: MemberService,
    private departmentService: DepartmentService,
    private emailService: EmailService,
    private location: Location) { }

  ngOnInit() {
    this.departmentId = this.authService.getGlobalUser().departmentId;
    this.isManager = this.authService.getGlobalUser().isManager;
    this.canManageEmployees = this.authService.getGlobalUser().manageDepartmentEmployees;

    this.searchMemberForm = this.formBuilder.group({
      firstName: [''],
      lastName: ['']
    });
  }

  searchMember(value: any) {
    const searchMemberModel = {
    firstName : value.firstName,
    lastName : value.lastName,
    };
    this.memberService.searchMemberByName(searchMemberModel).subscribe(x => {
      if (x) {
        this.searchResult = x;
      }
    });
  }

  newResearch() {
    this.searchMemberForm.setValue({
      firstName : '',
      lastName : ''
    });
    this.searchResult = undefined;
  }

  selectMember(value: any) {
    this.selectedMember = {
      applicantID : value.applicantID,
      GivenName : value.GivenName,
      LastName : value.LastName,
      Email : value.Email,
    };

    this.inviteMemberForm = this.formBuilder.group({
      jobTitle: ['', [Validators.required]],
      description: [''],
      workPhone: [''],
      mobilePhone: [''],
      email: [value.Email, [Validators.required, Validators.email]]
    });

    setTimeout(() => {
      M.updateTextFields();
      this.step1 = false;
      this.step2 = true;
      M.updateTextFields();
    }, 25);
  }

  get jobTitle() { return this.inviteMemberForm.get('jobTitle'); }
  get workPhone() { return this.inviteMemberForm.get('workPhone'); }
  get mobilePhone() { return this.inviteMemberForm.get('mobilePhone'); }
  get description() { return this.inviteMemberForm.get('description'); }
  get email() { return this.inviteMemberForm.get('email'); }

  inviteEmployee(value: any) {
    let adminName = '';
    let adminEmail = '';
    if (this.authService.getGlobalUser().role === 'Admin') {
      adminName = this.authService.getGlobalUser().FirstName + ' ' + this.authService.getGlobalUser().LastName;
      adminEmail = this.authService.getGlobalUser().Email;
    } else if (this.authService.getGlobalUser().role === 'Department-Employee') {
      adminName = this.authService.getGlobalUser().givenName + ' ' + this.authService.getGlobalUser().lastName;
      adminEmail = this.authService.getGlobalUser().email;
    }
    const model = {
      id : this.departmentService.createID(),
      departmentId : this.departmentId,
      memberId : this.selectedMember.applicantID,
      givenName : this.selectedMember.GivenName,
      lastName : this.selectedMember.LastName,
      email : value.email,
      validateSin : this.validateSin,
      validateIsn:  this.validateIsn,
      validateDob :  this.validateDob,
      jobTitle: value.jobTitle,
      workPhone: value.workPhone,
      mobilePhone: value.mobilePhone,
      description: value.description,
      showMobileNo: this.showMobileNo,
      showOfficeNo: this.showOfficeNo,
      showEmailAdr: this.showEmailAdr,
      allowContact: this.allowContact,
      manageAccount : this.manageAccount,
      manageContact : this.manageContact,
      manageDepartmentInfo : this.manageDepartmentInfo,
      manageDepartmentNews : this.manageDepartmentNews,
      manageDepartmentEmployees : this.manageDepartmentEmployees,
      manageSubDepartments : this.manageSubDepartments,
      role: 'Department-Employee',
      status: 'Pending',
      isManager : false,
      invitationDate: new Date(),
      validationPassword: this.generateRandomPassword(),
      invitedByName: adminName,
      InvitedByEmail: adminEmail,
    };

    const email = new EmailModel();
    const url = environment.firstNation.website + '/auth/regiter-department-employee/' + model.id;
    email.subject = environment.firstNation.displayName + ' APP Invitation';

    this.departmentService.sendInvitationToDepartmentEmployee(model).then(x => {
      email.body = '<p>Your Department Administrator Invited you to join the my nation app</p>' +
        ' <p>Please click on the folowing link to complete your registration</p> ' +
        ' <a href="' + url + '" target="_blank" class="center"> ' + url + ' </a>';
      email.emailList = [model.email];
      this.emailService.sendEmail(email).subscribe(
        eml => {
          toast({ html: 'Invitation Successfully Sent.', classes: 'green' });
          this.backClicked();
        }
      );
    });
  }

  backClicked() {
    this.location.back();
  }

  validateSinClicked() {
    this.validateSin = !this.validateSin;
  }

  validateIsnClicked() {
    this.validateIsn = !this.validateIsn;
  }

  validateDobClicked() {
    this.validateDob = !this.validateDob;
  }

  manageAccountClicked() {
    this.manageAccount = !this.manageAccount;
  }

  manageContactClicked() {
    this.manageContact = !this.manageContact;
  }

  manageDepartmentInfoClicked() {
    this.manageDepartmentInfo = !this.manageDepartmentInfo;
  }

  manageDepartmentNewsClicked() {
    this.manageDepartmentNews = !this.manageDepartmentNews;
  }

  manageDepartmentEmployeesClicked() {
    this.manageDepartmentEmployees = !this.manageDepartmentEmployees;
  }

  manageSubDepartmentsClicked() {
    this.manageSubDepartments = !this.manageSubDepartments;
  }

  privateMobileClick() {
    this.showMobileNo = 'Private';
  }

  publicMobileClick() {
    this.showMobileNo = 'Public';
  }

  membersMobileClick() {
    this.showMobileNo = 'Members';
  }

  privateOfficeClick() {
    this.showOfficeNo = 'Private';
  }

  publicOfficeClick() {
    this.showOfficeNo = 'Public';
  }

  membersOfficeClick() {
    this.showOfficeNo = 'Members';
  }

  privateEmailClick() {
    this.showEmailAdr = 'Private';
  }

  publicEmailClick() {
    this.showEmailAdr = 'Public';
  }

  membersEmailClick() {
    this.showEmailAdr = 'Members';
  }

  privateContactClick() {
    this.allowContact = 'Private';
  }

  publicContactClick() {
    this.allowContact = 'Public';
  }

  membersContactClick() {
    this.allowContact = 'Members';
  }

  generateRandomPassword() {
    length = 10;
    const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let retVal = '';
    for (let i = 0, n = charset.length; i < length; ++i) {
      retVal += charset.charAt(Math.floor(Math.random() * n));
    }
    return retVal;
  }
}