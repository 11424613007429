<div [class.hidden]="!show">
  <div *ngIf="show && isLogged" class="preloader-background center">
    <div class="preloader-y active">
      <div class="preloader">
        <div class="line line-1"></div>
        <div class="line line-2"></div>
        <div class="line line-3"></div>
        <div class="line line-4"></div>
        <div class="line line-5"></div>
      </div>
    </div>
    <p class="blinking">Processing ...</p>
  </div>

  <div *ngIf="show && !isLogged" class="preloader-background center">
    <div class="preloader-y active">
      <div class="preloader ">
        <div class="line line-1"></div>
        <div class="line line-2"></div>
        <div class="line line-3"></div>
        <div class="line line-4"></div>
        <div class="line line-5"></div>
      </div>
    </div>
  </div>
</div>