import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DocumentsService } from 'src/app/services/api/documents.service';
import { environment } from 'src/environments/environment';
import { SupplementalSupportModel } from 'src/app/models/supplementalSupportModel';
import { Location } from '@angular/common';

declare var $: any;
declare var navigator: any;

@Component({
  selector: 'app-upload-document',
  templateUrl: './upload-document.component.html',
  styleUrls: ['./upload-document.component.css']
})

export class UploadDocumentComponent implements OnInit {

  @Input() applicantID: any;
  @Input() isCordova: boolean;
  @Input() callerForm: any;
  @Output() documentSaved: EventEmitter<string> = new EventEmitter<string>();

  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  preview: any;
  binDoc: File;
  fileName: string;
  uploadDocumentForm: UntypedFormGroup;
  documentTypes: any = ['Utility', 'Special Diets', 'Special needs', 'ID', 'Other'];
  showDesciption = false;

  constructor(private fb: UntypedFormBuilder,
    private documentsService: DocumentsService,
    private location: Location) { }

  ngOnInit() {
    this.uploadDocumentForm = this.fb.group({
      documentType: ['', [Validators.required]],
      document: ['', [Validators.required]],
      description: [''],
      amount: ['0'],
    });

    if (this.callerForm === 'income_statement') {
      this.uploadDocumentForm.patchValue({
        documentType: 'Income Statement',
        document: '',
        description: '',
        amount: 0
      });
    }

    setTimeout(() => {
      $('.modal').modal();
      $('select').formSelect();
    }, 25);
  }

  get documentType() { return this.uploadDocumentForm.get('documentType'); }
  get description() { return this.uploadDocumentForm.get('description'); }
  get amount() { return this.uploadDocumentForm.get('amount'); }

  docTypeChange() {
    if (this.documentType.value === 'Other') {
      this.showDesciption = true;
    }
    if (this.documentType.value !== 'Other') {

      this.showDesciption = false;
      this.uploadDocumentForm.patchValue({
        description: '',
        amount: '',
      });
    }
  }

  submitForm(value: any) {
    if (!this.preview) {
      alert('No File Selected. \n Please select or scan a file from your device.');
      return;
    }
    $('.modal').modal('close');

    const model = new SupplementalSupportModel();
    model.aid = this.applicantID;
    model.document_type = 2;
    if (value.description === 'Other') {
      model.documentName = 'Other / ' + value.description;
    } else {
      model.documentName = value.documentType;
    }

    if (this.isCordova) {
      model.base64Document = this.preview.replace(/^data:image\/[a-z]+;base64,/, '');
      model.binDocument = this.binDoc;
      this.documentsService.postDocument(model).subscribe(x => {
        this.documentSaved.emit('saved');
      });
    } else {
      this.documentsService.postDoc(this.binDoc).subscribe(x => {
        model.filestream_ID = x;
        this.documentsService.postDocInfo(model).subscribe(x2 => {
          this.documentSaved.emit('saved');
        });
      });
    }
  }

  // Handle Web Upload
  handleFileInput(files: FileList) {
    this.binDoc = files[0];
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.fileName = files[0].name;
      this.preview = reader.result as string;
    };
  }

  calculateImageSize(base64String) {
    let kbytes;
    let padding;
    let inBytes;
    let base64StringLength;
    if (base64String.endsWith('==')) { padding = 2; }
    else if (base64String.endsWith('=')) { padding = 1; }
    else { padding = 0; }

    base64StringLength = base64String.length;
    inBytes = (base64StringLength / 4) * 3 - padding;
    kbytes = inBytes / 1000;
    return kbytes;
  }


  // Handle Mobile Camera/Library
  mobilePicture() {
    navigator.camera.getPicture(
      (imageData: any) => {
        if (imageData) {
          const imageName = 'MyNation.jpeg';
          const imageBlob = this.dataURItoBlob(imageData);
          const imageFile = new File([imageBlob], imageName, { type: 'image/jpeg' });
          this.binDoc = imageFile;
          this.preview = 'data:image/jpeg;base64,' + imageData;
        }
      },
      this.onMobileCameraFail,
      {
        quality: 50,
        saveToPhotoAlbum: true,
        destinationType: navigator.camera.DestinationType.DATA_URL,
        encodingType: navigator.camera.EncodingType.JPEG,
        mediaType: navigator.camera.MediaType.PICTURE,
        sourceType: navigator.camera.PictureSourceType.CAMERA,
      });
  }

  libraryPicture() {
    navigator.camera.getPicture(
      (imageData: any) => {
        if (imageData) {
          const imageName = 'MyNation.jpeg';
          const imageBlob = this.dataURItoBlob(imageData);
          const imageFile = new File([imageBlob], imageName, { type: 'image/jpeg' });
          this.binDoc = imageFile;
          this.preview = 'data:image/jpeg;base64,' + imageData;
        }
      },
      this.onMobileCameraFail,
      {
        quality: 50,
        saveToPhotoAlbum: true,
        destinationType: navigator.camera.DestinationType.DATA_URL,
        encodingType: navigator.camera.EncodingType.JPEG,
        mediaType: navigator.camera.MediaType.PICTURE,
        sourceType: navigator.camera.PictureSourceType.PHOTOLIBRARY,
      });
  }

  onMobileCameraFail(message) {
    alert(message);
  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/png' });
    return blob;
  }

  removeFile() {
    this.fileName = null;
    this.preview = null;
    this.uploadDocumentForm.patchValue({
      documentType: 'Please Select Document Type',
      document: '',
      description: '',
    });
  }

  backClicked() {
    this.location.back();
  }
}