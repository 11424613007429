<div class="row container-pi">
    <!-- <div class="header-setting b-m-2">
        <h4 class="">Application for Housing</h4>
    </div> -->

    <h4 class="title-section1 title-member icon-texts">
        <span class="material-symbols-outlined back-icon p-r-1" (click)=" backClicked()">
          arrow_circle_left
        </span>Application for Housing
      </h4>

    <app-firebase-loading *ngIf="loading"></app-firebase-loading>

    <main class="StickyContent white row">
        <div class="col s12 l11">

            <form [formGroup]="houseForm" class="row col s12 card ">
                <div class="row col s12" *ngIf="step < 10">
                    <ul class="pagination row col s12 center-align"
                        style="cursor: pointer; padding-top: 5px; padding-bottom: 5px;">
                        <li class="col s1" [ngClass]="{'active':step == 1}"><a>1</a></li>
                        <li class="col s1" [ngClass]="{'active':step == 2}"><a>2</a></li>
                        <li class="col s1" [ngClass]="{'active':step == 3}"><a>3</a></li>
                        <li class="col s1" [ngClass]="{'active':step == 4}"><a>4</a></li>
                        <li class="col s1" [ngClass]="{'active':step == 5}"><a>5</a></li>
                        <li class="col s1" [ngClass]="{'active':step == 6}"><a>6</a></li>
                        <li class="col s1" [ngClass]="{'active':step == 7}"><a>7</a></li>
                        <li class="col s1" [ngClass]="{'active':step == 8}"><a>8</a></li>
                        <li class="col s1" [ngClass]="{'active':step == 9}"><a>9</a></li>
                        <li class="col s3" [ngClass]="{'active':step == 10}"><a>Summary</a>
                    </ul>
                </div>
                <div class="row col s12  white" *ngIf="step == 1" formGroupName="applicantInfo">
                    <div class="card-content">
                        <div class="row col s12 teal lighten-2 white-text">
                            <h5 class="center-align card-title">PRIMARY APPLICANT DETAILS</h5>
                        </div>

                        <div class="input-field col s12 m6">
                            <div class="label-input">First Name<span class="red-text">*</span></div>
                            <input id="givenName" type="text" formControlName='givenName' class="validate"
                                autocomplete="off" style="text-transform: capitalize;" placeholder="First Name" />
                            <div *ngIf="(givenName.dirty || givenName.touched) && givenName.invalid">
                                <small *ngIf="givenName.errors.required" class="red-text">First Name is
                                    Required.</small>
                            </div>
                        </div>

                        <div class="input-field col s12 m6">
                            <div class="label-input">Last Name<span class="red-text">*</span></div>
                            <input id="lastName" type="text" formControlName='lastName' class="validate"
                                autocomplete="off" style="text-transform: capitalize;" placeholder="Last Name" />
                            <div *ngIf="(lastName.dirty || lastName.touched) && lastName.invalid">
                                <small *ngIf="lastName.errors.required" class="red-text">Last Name is Required.</small>
                            </div>
                        </div>

                        <div class="input-field col s12 m6">
                            <div class="label-input">Middle Name</div>
                            <input id="middleName" type="text" formControlName='middleName' class="validate"
                                autocomplete="off" style="text-transform: capitalize;" placeholder="Middle Name" />
                        </div>

                        <div class="input-field col s12 m6">
                            <div class="label-input">Title</div>
                            <input id="title" type="text" formControlName='title' class="validate" autocomplete="off"
                                style="text-transform: capitalize;" placeholder="Title" />
                        </div>

                        <div class="input-field col s12 m6">
                            <div class="label-input">Alias/Maiden Name</div>
                            <input id="aliasName" type="text" formControlName='aliasName' class="validate"
                                autocomplete="off" style="text-transform: capitalize;"
                                placeholder="Alias/Maiden Name" />
                        </div>

                        <div class="input-field col s12 m6">
                            <div class="label-input">Date of Birth (dd/mm/yyyy)<span class="red-text">*</span></div>
                            <!-- <input *ngIf="!dobSet" id="dob" type="text" formControlName="dob" class="validate"
                                [textMask]="{mask: dateMask, guide: true}" placeholder="dd/mm/yyyy"
                                (blur)="setDate($event.target.value, 'dob')"> -->
                                <input *ngIf="!dobSet" id="dob" type="text" formControlName="dob" class="validate"
                                placeholder="dd/mm/yyyy"
                                (blur)="setDate($event.target.value, 'dob')">
                            <input *ngIf="dobSet" id="dob" type="text" formControlName="dob" class="validate"
                                placeholder="dd/mm/yyyy" (focus)="unSetDate('dob')">
                            <div *ngIf="(dob.dirty || dob.touched) && dob.invalid">
                                <small *ngIf="dob.errors.required" class="red-text"> The Date Of Birth is required.
                                </small>
                                <small *ngIf="dob.errors.invalidDate" class="red-text"> The Date is invalid. </small>
                            </div>
                        </div>

                        <div class="input-field col s12 m6">
                            <div class="label-input">Marital Status<span class="red-text">*</span></div>
                            <select formControlName="maritalStatus" class="browser-default">
                                <option value="" disabled selected>Select</option>
                                <option *ngFor="let status of maritalStatus" [value]="status">{{status}}</option>
                            </select>
                        </div>

                        <div class="input-field col s12 m6">
                            <div class="label-input">SIN</div>
                            <!-- <input id="SIN" type="text" formControlName="SIN" class="validate"
                                [textMask]="{mask: SINMask, guide: true}" placeholder="SIN"> -->
                                <input id="SIN" type="text" formControlName="SIN" class="validate"
                                 placeholder="SIN">
                            <div *ngIf="(SIN.dirty || SIN.touched) && SIN.invalid">
                                <small *ngIf="SIN.errors.minlength || SIN.errors.maxlength" class="red-text">The SIN
                                    must be
                                    9 Digits.</small>
                            </div>
                        </div>

                        <div class="input-field col s12 m6">
                            <div class="label-input">Indian Status Number<span class="red-text">*</span></div>
                            <input id="registrationNo" type="number" formControlName="registrationNo" class="validate"
                                placeholder="Indian Status Number">
                            <div *ngIf="(registrationNo.dirty || registrationNo.touched) && registrationNo.invalid">
                                <small *ngIf="registrationNo.errors.required" class="red-text">Registration Number is
                                    Required
                                    Digits.</small>
                            </div>
                        </div>

                        <div class="input-field col s12 m8">
                            <div class="label-input">Member of FWFN</div>
                            <div class="row col s12">
                                <label class="col s6">
                                    <input name="FWFN" type="radio" formControlName="FWFN" [value]="true" class="with-gap" />
                                    <span>Yes</span>
                                </label>
                                <label class="col s6">
                                    <input name="FWFN" type="radio" formControlName="FWFN" [value]="false" class="with-gap" />
                                    <span>No</span>
                                </label>
                            </div>
                        </div>

                        <div class="row section"></div>

                        <div class="row">
                            <button class="col s12 m5 offset-m2 l3 btn right waves-effect blue" type="button"
                                (click)="submitApplicantInfo(houseForm.controls.applicantInfo.value)"
                                style="margin-bottom: 15px" title="Save">Next <i
                                    class="material-icons right">arrow_forward</i></button>
                        </div>
                    </div>

                </div>

                <div class="row col s12  white" *ngIf="step == 2" formGroupName="contactInfo">
                    <div class="card-content">
                        <div class="row col s12 teal lighten-2 white-text">
                            <h5 class="center-align">APPLICANT'S CONTACT INFORMATION</h5>
                        </div>

                        <div class="input-field col s12 m6">
                            <div class="label-input">Telephone Number<span class="red-text">*</span></div>
                            <!-- <input id="telephone" [textMask]="{mask: phoneNumberMask, guide: true}" type="text"
                                formControlName="telephone" class="validate" placeholder=""> -->
                                <input id="telephone"  type="text"
                                formControlName="telephone" class="validate" placeholder="">
                            <div *ngIf="(telephone.dirty || telephone.touched) && telephone.invalid">
                                <small *ngIf="telephone.errors.required" class="red-text">Telephone Number is
                                    Required.</small>
                            </div>
                        </div>

                        <div class="input-field col s12 m6">
                            <div class="label-input">Mobile Number</div>
                            <!-- <input id="cellphone" [textMask]="{mask: phoneNumberMask, guide: true}" type="text"
                                formControlName="cellphone" class="validate" placeholder="Mobile Number"> -->
                                <input id="cellphone"  type="text"
                                formControlName="cellphone" class="validate" placeholder="Mobile Number">
                        </div>

                        <div class="input-field col s12 m8">
                            <div class="label-input">WorK Number</div>
                            <!-- <input id="workNumber" [textMask]="{mask: phoneNumberMask, guide: true}" type="text"
                                formControlName="workNumber" class="validate" placeholder="Work Number"> -->
                                <input id="workNumber"  type="text"
                                formControlName="workNumber" class="validate" placeholder="Work Number">
                        </div>

                        <div class="input-field col s12 m4">
                            <div class="label-input">Extension</div>
                            <!-- <input id="ext" type="text" [textMask]="{mask: extMask, guide: true}" formControlName="ext"
                                class="validate" placeholder="Extension"> -->
                                <input id="ext" type="text"  formControlName="ext"
                                class="validate" placeholder="Extension">
                        </div>

                        <h6 class="row col s12 ">ALTERNATE CONTACT INFORMATION</h6>

                        <div class="input-field col s12 m6">
                            <div class="label-input">Alternative Home Number</div>
                            <!-- <input id="telephone2" [textMask]="{mask: phoneNumberMask, guide: true}" type="text"
                                formControlName="telephone2" class="validate" placeholder="Alternative Home Number"> -->
                                <input id="telephone2" type="text"
                                formControlName="telephone2" class="validate" placeholder="Alternative Home Number">
                        </div>

                        <div class="input-field col s12 m6">
                            <div class="label-input">Alternative Mobile Number</div>
                            <!-- <input id="cellphone2" [textMask]="{mask: phoneNumberMask, guide: true}" type="text"
                                formControlName="cellphone2" class="validate" placeholder="Alternative Mobile Number"> -->
                                <input id="cellphone2"  type="text"
                                formControlName="cellphone2" class="validate" placeholder="Alternative Mobile Number">
                        </div>

                        <h6 class="row col s12"><strong>APPLICANT'S MAILING ADDRESS </strong></h6>

                        <div class="input-field col s12 m6">
                            <div class="label-input">Address<span class="red-text">*</span></div>

                            <input id="address" type="text" formControlName="address" class="validate"
                                style="text-transform: capitalize;" placeholder="Address">
                            <div *ngIf="(address.dirty || address.touched) && address.invalid">
                                <small *ngIf="address.errors.required" class="red-text">Address is required</small>
                            </div>
                        </div>

                        <div class="input-field col s12 m6">
                            <div class="label-input">Province<span class="red-text">*</span></div>
                            <select formControlName="province" class="validate">
                                <option *ngFor="let t of provinces" [value]="t.code">{{t.name}}</option>
                            </select>
                        </div>

                        <div class="input-field col s12 m6">
                            <div class="label-input">City<span class="red-text">*</span></div>
                            <input id="city" type="text" formControlName="city" class="validate" placeholder="City"
                                style="text-transform: capitalize;">
                            <div *ngIf="(city.dirty || city.touched) && city.invalid">
                                <small *ngIf="city.errors.required" class="red-text">City is required</small>
                            </div>
                        </div>

                        <div class="input-field col s12 m6">
                            <div class="label-input">Postal Code<span class="red-text">*</span></div>
                            <!-- <input id="postalCode" [textMask]="{mask: postalCodeMask, guide: true}" type="text"
                                formControlName="postalCode" class="validate" style='text-transform:uppercase'
                                placeholder="Postal code"> -->
                                <input id="postalCode"  type="text"
                                formControlName="postalCode" class="validate" style='text-transform:uppercase'
                                placeholder="Postal code">
                            <div *ngIf="(postalCode.dirty || postalCode.touched) && postalCode.invalid">
                                <small *ngIf="postalCode.errors.required" class="red-text"> The Postal Code is required.
                                </small>
                            </div>
                        </div>

                        <div class="row section"></div>

                        <div class="row">
                            <button class="col s12 m5 l3 btn left waves-effect blue" type="button"
                                style="margin-bottom: 15px" (click)="onBack()"><i
                                    class="material-icons left">arrow_back</i>Back
                            </button>

                            <button class="col s12 m5 offset-m2 l3 btn right waves-effect blue" type="button"
                                (click)="submitContactInfo(houseForm.controls.contactInfo.value)"
                                style="margin-bottom: 15px" title="Save">Next <i
                                    class="material-icons right">arrow_forward</i></button>

                        </div>
                    </div>
                </div>

                <div class="row col s12  white" *ngIf="step == 3" formGroupName="accommodation">
                    <div class="card-content">
                        <div class="row col s12 teal lighten-2 white-text">
                            <h5 class="center-align">PRESENT ACCOMMODATION</h5>
                        </div>

                        <div class="input-field col s12">
                            <div class="label-input">Address of Accommodation<span class="red-text">*</span></div>
                            <input id="accomodationAddress" type="text" formControlName='accomodationAddress'
                                class="validate" autocomplete="off" style="text-transform: capitalize;"
                                placeholder="Address of Accommodation" />
                        </div>

                        <div class="input-field col s12">
                            <div>House Information <span class="red-text">*</span></div>
                            <div class="row col s12">
                                <label class="col m4 s6">
                                    <input name="homeInfo" type="radio" formControlName="homeInfo" value="Own" class="with-gap"
                                        (click)="onhouseInfoSelected('Own')" />
                                    <span>Own</span>
                                </label>
                                <label class="col m4 s6">
                                    <input name="homeInfo" type="radio" formControlName="homeInfo" value="Co-Own" class="with-gap"
                                        (click)="onhouseInfoSelected('Co-Own')" />
                                    <span>Co-Own</span>
                                </label>
                                <label class="col m4 s6">
                                    <input name="homeInfo" type="radio" formControlName="homeInfo" value="Rent" class="with-gap"
                                        (click)="onhouseInfoSelected('Rent')" />
                                    <span>Rent</span>
                                </label>
                                <label class="col m4 s6">
                                    <input name="homeInfo" type="radio" formControlName="homeInfo" value="Shelter" class="with-gap"
                                        (click)="onhouseInfoSelected('Shelter')" />
                                    <span>Shelter</span>
                                </label>
                                <label class="col m4 s6">
                                    <input name="homeInfo" type="radio" formControlName="homeInfo" value="Homeless" class="with-gap"
                                        (click)="onhouseInfoSelected('Homeless')" />
                                    <span>Homeless</span>
                                </label>
                                <label class="col m4 s6">
                                    <input name="homeInfo" type="radio" formControlName="homeInfo" value="Other" class="with-gap"
                                        (click)="onhouseInfoSelected('Other')" />
                                    <span>Other</span>
                                </label>
                            </div>
                        </div>

                        <div class="row" formGroupName="co_applicant" *ngIf="Co_Own">
                            <h6 class="row col s12"><strong>CO-APPLICANT DETAILS</strong></h6>

                            <div class="input-field col s12 m6">
                                <i class="material-icons prefix green-text">person</i>
                                <input id="givenName" type="text" formControlName='givenName' class="validate"
                                    autocomplete="off" style="text-transform: capitalize;" />
                                <label for="givenName">First Name<span class="red-text">*</span></label>
                                <div *ngIf="(co_givenName.dirty || co_givenName.touched) && co_givenName.invalid">
                                    <small *ngIf="co_givenName.errors.required" class="red-text">First Name is
                                        Required.</small>
                                </div>
                            </div>

                            <div class="input-field col s12 m6">
                                <i class="material-icons prefix blue-text">person</i>
                                <input id="lastName" type="text" formControlName='lastName' class="validate"
                                    autocomplete="off" style="text-transform: capitalize;" />
                                <label for="lastName">Last Name<span class="red-text">*</span></label>
                                <div *ngIf="(co_lastName.dirty || co_lastName.touched) && co_lastName.invalid">
                                    <small *ngIf="co_lastName.errors.required" class="red-text">Last Name is
                                        Required.</small>
                                </div>
                            </div>

                            <div class="input-field col s12 m6">
                                <i class="material-icons prefix cyan-text">person</i>
                                <input id="middleName" type="text" formControlName='middleName' class="validate"
                                    autocomplete="off" style="text-transform: capitalize;" />
                                <label for="middleName">Middle Name</label>
                            </div>

                            <div class="input-field col s12 m6">
                                <i class="material-icons prefix amber-text">person</i>
                                <input id="title" type="text" formControlName='title' class="validate"
                                    autocomplete="off" style="text-transform: capitalize;" />
                                <label for="title">Title</label>
                            </div>

                            <div class="input-field col s12 m6">
                                <i class="material-icons prefix amber-text">person</i>
                                <input id="aliasName" type="text" formControlName='aliasName' class="validate"
                                    autocomplete="off" style="text-transform: capitalize;" />
                                <label for="aliasName">Alias/Maiden Name</label>
                            </div>

                            <div class="input-field col s12 m6">
                                <i class="material-icons prefix amber-text">person</i>
                                <input id="age" type="number" min="1" formControlName='age' class="validate"
                                    autocomplete="off" style="text-transform: capitalize;" />
                                <label for="age">Age</label>
                            </div>

                            <div class="input-field col s12">
                                <div>Gender</div>
                                <div class="row col s12">
                                    <label class="col s6">
                                        <input name="gender" type="radio" formControlName="gender" [value]="Male" class="with-gap" />
                                        <span>Male</span>
                                    </label>
                                    <label class="col s6">
                                        <input name="gender" type="radio" formControlName="gender" [value]="Female" class="with-gap" />
                                        <span>Female</span>
                                    </label>
                                </div>
                            </div>

                            <div class="input-field col s12">
                                <i class="material-icons prefix green-text">people</i>
                                <select formControlName="maritalStatus">
                                    <option value="" disabled selected>Select</option>
                                    <option *ngFor="let status of maritalStatus" [value]="status">{{status}}</option>
                                </select>
                                <label for="maritalStatus">Marital Status</label>
                            </div>

                            <div class="input-field col s12 m6">
                                <i class="material-icons prefix green-text">today</i>
                                <!-- <input *ngIf="!co_dobSet" id="dob" type="text" formControlName="dob" class="validate"
                                    [textMask]="{mask: dateMask, guide: true}" placeholder="dd/mm/yyyy"
                                    (blur)="setDate($event.target.value, 'co_dob')"> -->
                                    <input *ngIf="!co_dobSet" id="dob" type="text" formControlName="dob" class="validate"
                                    placeholder="dd/mm/yyyy"
                                    (blur)="setDate($event.target.value, 'co_dob')">
                                <input *ngIf="co_dobSet" id="dob" type="text" formControlName="dob" class="validate"
                                    placeholder="dd/mm/yyyy" (focus)="unSetDate('co_dob')">
                                <label for="dob">Date of Birth (dd/mm/yyyy)<span class="red-text">*</span></label>
                                <div *ngIf="(co_dob.dirty || co_dob.touched) && co_dob.invalid">
                                    <small *ngIf="co_dob.errors.required" class="red-text"> The Date Of Birth is
                                        required. </small>
                                    <small *ngIf="co_dob.errors.invalidDate" class="red-text"> The Date is invalid
                                    </small>
                                </div>
                            </div>

                            <div class="input-field col s12 m6">
                                <i class="material-icons prefix orange-text">apps</i>
                                <!-- <input id="SIN" type="text" formControlName="SIN" class="validate"
                                    [textMask]="{mask: SINMask, guide: true}"> -->
                                    <input id="SIN" type="text" formControlName="SIN" class="validate"
                                   >
                                <label for="SIN">SIN</label>
                            </div>

                            <div class="input-field col s12">
                                <div>Registered Indian</div>
                                <div class="row col s12">
                                    <label class="col s6">
                                        <input name="isn" type="radio" formControlName="isn" [value]="true" class="with-gap" />
                                        <span>Yes</span>
                                    </label>
                                    <label class="col s6">
                                        <input name="isn" type="radio" formControlName="isn" [value]="false" class="with-gap" />
                                        <span>No</span>
                                    </label>
                                </div>
                            </div>

                            <div class="input-field col s12 m6">
                                <i class="material-icons prefix amber-text">person</i>
                                <input id="bandName" type="text" formControlName='bandName' class="validate"
                                    autocomplete="off" style="text-transform: capitalize;" />
                                <label for="bandName">Band Name</label>
                            </div>

                            <div class="input-field col s12 m6">
                                <i class="material-icons prefix blue-text">app_registration</i>
                                <input id="registrationNo" type="text" formControlName="registrationNo"
                                    class="validate">
                                <label for="registrationNo">First Nation Registration Number<span
                                        class="red-text">*</span></label>
                            </div>
                        </div>

                        <div class="row" formGroupName="landLord" *ngIf="rent">
                            <h6 class="row col s12"><strong>CURRENT LANDLORD</strong></h6>

                            <div class="input-field col s12 m6">
                                <i class="material-icons prefix green-text">person</i>
                                <input id="givenName" type="text" formControlName='givenName' class="validate"
                                    autocomplete="off" style="text-transform: capitalize;" />
                                <label for="givenName">First Name<span class="red-text">*</span></label>
                                <div
                                    *ngIf="(landlord_givenName.dirty || landlord_givenName.touched) && landlord_givenName.invalid">
                                    <small *ngIf="landlord_givenName.errors.required" class="red-text">First Name is
                                        Required.</small>
                                </div>
                            </div>

                            <div class="input-field col s12 m6">
                                <i class="material-icons prefix blue-text">person</i>
                                <input id="lastName" type="text" formControlName='lastName' class="validate"
                                    autocomplete="off" style="text-transform: capitalize;" />
                                <label for="lastName">Last Name<span class="red-text">*</span></label>
                                <div
                                    *ngIf="(landlord_lastName.dirty || landlord_lastName.touched) && landlord_lastName.invalid">
                                    <small *ngIf="landlord_lastName.errors.required" class="red-text">Last Name is
                                        Required.</small>
                                </div>
                            </div>

                            <div class="input-field col s12 m6">
                                <i class="material-icons prefix green-text">location_on</i>
                                <input id="address" type="text" formControlName="address" class="validate"
                                    style="text-transform: capitalize;">
                                <label for="address">Address</label>
                            </div>

                            <div class="input-field col s12 m6">
                                <i class="material-icons prefix pink-text">add_location</i>
                                <select formControlName="province" class="validate">
                                    <option *ngFor="let t of provinces" [value]="t.code">{{t.name}}</option>
                                </select>
                                <label for="province">Province</label>
                            </div>

                            <div class="input-field col s12 m6">
                                <i class="material-icons prefix teal-text">location_city</i>
                                <input id="city" type="text" formControlName="city" class="validate"
                                    style="text-transform: capitalize;">
                                <label for="city">City</label>
                            </div>

                            <div class="input-field col s12 m6">
                                <i class="material-icons prefix purple-text">mail</i>
                                <!-- <input id="postalCode" [textMask]="{mask: postalCodeMask, guide: true}" type="text"
                                    formControlName="postalCode" class="validate" style='text-transform:uppercase'> -->
                                    <input id="postalCode" type="text"
                                    formControlName="postalCode" class="validate" style='text-transform:uppercase'>
                                <label for="postalCode">Postal Code</label>
                            </div>

                            <div class="input-field col s12">
                                <i class="material-icons prefix green-text">today</i>
                                <!-- <input *ngIf="!moveInDateSet" id="moveInDate" type="text" formControlName="moveInDate"
                                    class="validate" [textMask]="{mask: dateMask, guide: true}" placeholder="dd/mm/yyyy"
                                    (blur)="setDate($event.target.value, 'moveInDate')"> -->
                                    <input *ngIf="!moveInDateSet" id="moveInDate" type="text" formControlName="moveInDate"
                                    class="validate"  placeholder="dd/mm/yyyy"
                                    (blur)="setDate($event.target.value, 'moveInDate')">
                                <input *ngIf="moveInDateSet" id="moveInDate" type="text" formControlName="moveInDate"
                                    class="validate" placeholder="dd/mm/yyyy" (focus)="unSetDate('moveInDate')">
                                <label for="moveInDate">Move-In Date (dd/mm/yyyy)</label>
                            </div>

                            <div class="input-field col s12">
                                <div>Lease Agreement</div>
                                <div class="row col s12">
                                    <label class="col s6">
                                        <input name="leaseAgreement" type="radio" formControlName="leaseAgreement" class="with-gap"
                                            [value]="true" />
                                        <span>Yes</span>
                                    </label>
                                    <label class="col s6">
                                        <input name="leaseAgreement" type="radio" formControlName="leaseAgreement" class="with-gap"
                                            [value]="false" />
                                        <span>No</span>
                                    </label>
                                </div>
                            </div>

                            <div class="input-field col s12">
                                <i class="material-icons prefix green-text">today</i>
                                <!-- <input *ngIf="!expireDateSet" id="expireDate" type="text" formControlName="expireDate"
                                    class="validate" [textMask]="{mask: dateMask, guide: true}" placeholder="dd/mm/yyyy"
                                    (blur)="setDate($event.target.value, 'expireDate')"> -->
                                    <input *ngIf="!expireDateSet" id="expireDate" type="text" formControlName="expireDate"
                                    class="validate"  placeholder="dd/mm/yyyy"
                                    (blur)="setDate($event.target.value, 'expireDate')">
                                <input *ngIf="expireDateSet" id="expireDate" type="text" formControlName="expireDate"
                                    class="validate" placeholder="dd/mm/yyyy" (focus)="unSetDate('expireDate')">
                                <label for="moveInDate">Expire Date (dd/mm/yyyy)</label>
                            </div>
                        </div>

                        <div class="input-field col s12" *ngIf="other">
                            <i class="material-icons prefix green-text">person</i>
                            <input id="otherAccoummodation" type="text" formControlName='otherAccoummodation'
                                class="validate" autocomplete="off" style="text-transform: capitalize;" />
                            <label for="otherAccoummodation">If Other, please explain: </label>
                            <div
                                *ngIf="(otherAccoummodation.dirty || otherAccoummodation.touched) && otherAccoummodation.invalid">
                                <small *ngIf="otherAccoummodation.errors.required" class="red-text"> This field is
                                    Required.</small>
                            </div>
                        </div>

                        <div class="row">
                            <button class="col s12 m5 l3 btn left waves-effect blue" type="button"
                                style="margin-bottom: 15px" (click)="onBack()"><i
                                    class="material-icons left">arrow_back</i>Back
                            </button>

                            <button class="col s12 m5 offset-m2 l3 btn right waves-effect blue" type="button"
                                (click)="submitAccommodationInfo(houseForm.controls.accommodation.value)"
                                style="margin-bottom: 15px" title="Save">Next <i
                                    class="material-icons right">arrow_forward</i></button>
                        </div>
                    </div>
                </div>

                <div class="row col s12  white" *ngIf="step == 4" formGroupName="accessibility">
                    <div class="card-content">
                        <div class="row col s12 teal lighten-2 white-text">
                            <h5 class="center-align">ACCESSIBILITY</h5>
                        </div>

                        <div class="input-field col s12">
                            <div>Do you or family members have accessibility needs? <span class="red-text">*</span>
                            </div>
                            <div class="row col s12">
                                <label class="col s6">
                                    <input name="accessibilityNeeds" type="radio" formControlName="accessibilityNeeds" class="with-gap"
                                        [value]="true" (click)="onAccessibilityYes()" />
                                    <span>Yes</span>
                                </label>
                                <label class="col s6">
                                    <input name="accessibilityNeeds" type="radio" formControlName="accessibilityNeeds" class="with-gap"
                                        [value]="false" (click)="onAccessibilityNo()" />
                                    <span>No</span>
                                </label>
                            </div>
                        </div>

                        <div class="input-field col s12" *ngIf="accessibility">
                            <i class="material-icons prefix green-text">comment</i>
                            <textarea id="accessibilityExplain" formControlName="accessibilityExplain"
                                class="materialize-textarea" rows="3" style="height: 100px;"></textarea>
                            <label for="accessibilityExplain">If Yes, please explain:</label>
                        </div>

                        <div class="input-field col s12">
                            <div>Do all household members currently reside at present accommodation? <span
                                    class="red-text">*</span></div>
                            <div class="row col s12">
                                <label class="col s6">
                                    <input name="reside" type="radio" formControlName="reside" [value]="true" class="with-gap"
                                        (click)="resideYes()" />
                                    <span>Yes</span>
                                </label>
                                <label class="col s6">
                                    <input name="reside" type="radio" formControlName="reside" [value]="false" class="with-gap"
                                        (click)="resideNo()" />
                                    <span>No</span>
                                </label>
                            </div>
                        </div>

                        <div class="input-field col s12" *ngIf="memberReside">
                            <i class="material-icons prefix green-text">comment</i>
                            <textarea id="resideExplain" formControlName="resideExplain" class="materialize-textarea"
                                rows="3" style="height: 100px;"></textarea>
                            <label for="resideExplain">If No, please explain:</label>
                        </div>

                        <h6 class="row col s12"><strong>SUPPORTING INFORMATION</strong></h6>

                        <div class="input-field col s12">
                            <div>Are there any Health and Safety issues at your current accommodation? (Overcrowding,
                                hazards,
                                etc.) <span class="red-text">*</span></div>
                            <div class="row col s12">
                                <label class="col s6">
                                    <input name="healthSafetyIssues" type="radio" formControlName="healthSafetyIssues" class="with-gap"
                                        [value]="true" (click)="healthSafetyYes()" />
                                    <span>Yes</span>
                                </label>
                                <label class="col s6">
                                    <input name="healthSafetyIssues" type="radio" formControlName="healthSafetyIssues" class="with-gap"
                                        [value]="false" (click)="healthSafetyNo()" />
                                    <span>No</span>
                                </label>
                            </div>
                        </div>

                        <div class="input-field col s12" *ngIf="healthSafety">
                            <i class="material-icons prefix green-text">comment</i>
                            <textarea id="healthSafetyExplain" formControlName="healthSafetyExplain"
                                class="materialize-textarea" rows="3" style="height: 100px;"></textarea>
                            <label for="healthSafetyExplain">If Yes, please explain:</label>
                        </div>

                        <div class="divider"></div>

                        <div class="row">
                            <button class="col s12 m5 l3 btn left waves-effect blue" type="button"
                                style="margin-bottom: 15px" (click)="onBack()"><i
                                    class="material-icons left">arrow_back</i>Back
                            </button>

                            <button class="col s12 m5 offset-m2 l3 btn right waves-effect blue" type="button"
                                (click)="submitAccessibilityInfo(houseForm.controls.accessibility.value)"
                                (click)="onNext()" style="margin-bottom: 15px" title="Save">Next <i
                                    class="material-icons right">arrow_forward</i></button>
                        </div>
                    </div>
                </div>

                <div class="row col s12" *ngIf="step == 5" formGroupName="occupants">
                    <div class="row col s12 white">
                        <div class="card-content">

                            <div class="row col s12 teal lighten-2 white-text">
                                <h5 class="center-align">OTHER OCCUPANT INFORMATION</h5>
                            </div>

                            <div class="input-field col s12 card-content">
                                <div>Do you want to add Other Occupants? <span class="red-text">*</span></div>
                                <div class="row col s12">
                                    <label class="col s6">
                                        <input name="add_occupants" type="radio" formControlName="add_occupants" class="with-gap"
                                            [value]="true" (click)="onAddOccupantsYes()" />
                                        <span>Yes</span>
                                    </label>
                                    <label class="col s6">
                                        <input name="add_occupants" type="radio" formControlName="add_occupants" class="with-gap"
                                            [value]="false" (click)="onAddOccupantsNo" />
                                        <span>No</span>
                                    </label>
                                </div>
                            </div>

                            <div class="section"></div>

                            <div class="row col s12" *ngIf="occupants.controls.length > 0">
                                <h5><strong>Occupants Added</strong></h5>
                                <ul class="collapsible">
                                    <li *ngFor="let occ of occupants.controls;let i=index;">
                                        <div class="collapsible-header"><i
                                                class="material-icons green-text">how_to_reg</i><strong>{{occ.get('lastName').value
                                                | uppercase}},
                                                {{occ.get('givenName').value | uppercase}}</strong></div>
                                        <div class="collapsible-body">
                                            <ul class="collection">
                                                <li class="collection-item">
                                                    <div><strong>Date of Birth: </strong><a
                                                            class="secondary-content">{{occ.get('dob').value}} </a>
                                                    </div>
                                                </li>
                                                <li class="collection-item">
                                                    <div><strong>Age: </strong><a
                                                            class="secondary-content">{{occ.get('age').value}} </a>
                                                    </div>
                                                </li>
                                                <li class="collection-item">
                                                    <div><strong>SIN: </strong><a
                                                            class="secondary-content">{{occ.get('SIN').value}}</a></div>
                                                </li>
                                                <li class="collection-item">
                                                    <div><strong>Gender: </strong><a
                                                            class="secondary-content">{{occ.get('gender').value}}</a>
                                                    </div>
                                                </li>
                                                <li class="collection-item">
                                                    <div><strong>In School: </strong><a class="secondary-content"
                                                            *ngIf="occ.get('inSchool').value">Yes </a><a
                                                            class="secondary-content"
                                                            *ngIf="!occ.get('inSchool').value">No
                                                        </a></div>
                                                </li>
                                                <li class="collection-item">
                                                    <div><strong>School: </strong><a
                                                            class="secondary-content">{{occ.get('school').value}}</a>
                                                    </div>
                                                </li>
                                                <li class="collection-item">
                                                    <div><strong>Has Special Needs: </strong><a
                                                            class="secondary-content" *ngIf="occ.get('needs').value">Yes
                                                        </a><a class="secondary-content"
                                                            *ngIf="!occ.get('inSchool').value">No
                                                        </a></div>
                                                </li>
                                                <li class="collection-item">
                                                    <div><strong>Special Needs: </strong><a
                                                            class="secondary-content">{{occ.get('specialNeeds').value}}</a>
                                                    </div>
                                                </li>
                                                <li class="collection-item">
                                                    <div><strong>Relationship: </strong><a
                                                            class="secondary-content">{{occ.get('relation').value}}</a>
                                                    </div>
                                                </li>
                                                <li class="collection-item"
                                                    *ngIf="occ.get('relation').value == 'Other Relative'">
                                                    <div><strong>Other: </strong><a
                                                            class="secondary-content">{{occ.get('other_relative').value}}</a>
                                                    </div>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                                <div class="section"></div>
                                <div class="row col s12" *ngIf="!addOccupants">
                                    <button type="button" class="col s12 center-align btn waves-effect"
                                        style="margin-bottom: 15px;" (click)="onAddOccupantsYes()">
                                        <i class="material-icons right">add</i> Add Another Occupants
                                    </button>
                                </div>
                            </div>

                            <div class="divider"></div>

                            <div class="row" *ngIf="!addOccupants">
                                <button class="col s12 m5 l3 btn left waves-effect blue" type="button"
                                    style="margin-bottom: 15px" (click)="onBack()"><i
                                        class="material-icons left">arrow_back</i>Back
                                </button>

                                <button class="col s12 m5 offset-m2 l3 btn right waves-effect blue" type="button"
                                    [disabled]="!houseForm.controls.occupants.valid"
                                    (click)="submitOccupantsInfo(houseForm.controls.occupants.value)"
                                    style="margin-bottom: 15px" title="Save">Next <i
                                        class="material-icons right">arrow_forward</i></button>
                                <!-- [disabled]="!houseForm.valid"  -->
                            </div>
                        </div>
                    </div>

                    <div class="section"></div>

                    <div class="row col s12 card white" *ngIf="addOccupants">
                        <div class="card-content">
                            <div class="col s12">
                                <span class="material-icons right" (click)="closeAddOccupant()"
                                    style="cursor: pointer;">close</span>
                                <span class="card-title center-align col s12">Add Occupants</span>

                                <div class="input-field col s12 m6">
                                    <i class="material-icons prefix green-text">person</i>
                                    <input id="givenName" type="text" formControlName='givenName' class="validate"
                                        autocomplete="off" style="text-transform: capitalize;" />
                                    <label for="givenName">First Name<span class="red-text">*</span></label>
                                </div>

                                <div class="input-field col s12 m6">
                                    <i class="material-icons prefix blue-text">person</i>
                                    <input id="lastName" type="text" formControlName='lastName' class="validate"
                                        autocomplete="off" style="text-transform: capitalize;" />
                                    <label for="lastName">Last Name<span class="red-text">*</span></label>
                                </div>

                                <div class="input-field col s12 m6">
                                    <i class="material-icons prefix cyan-text">person</i>
                                    <input id="middleName" type="text" formControlName='middleName' class="validate"
                                        autocomplete="off" style="text-transform: capitalize;" />
                                    <label for="middleName">Middle Name</label>
                                </div>

                                <div class="input-field col s12 m6">
                                    <i class="material-icons prefix orange-text">apps</i>
                                    <!-- <input id="SIN" type="text" formControlName="SIN" class="validate"
                                        [textMask]="{mask: SINMask, guide: true}"> -->
                                        <input id="SIN" type="text" formControlName="SIN" class="validate"
                                        >
                                    <label for="SIN">SIN</label>
                                </div>

                                <div class="input-field col s12 m6">
                                    <i class="material-icons prefix green-text">today</i>
                                    <!-- <input *ngIf="!occ_dobSet" id="dob" type="text" formControlName="dob"
                                        class="validate" [textMask]="{mask: dateMask, guide: true}"
                                        placeholder="dd/mm/yyyy" (blur)="setDate($event.target.value, 'occ_dob')"> -->
                                        <input *ngIf="!occ_dobSet" id="dob" type="text" formControlName="dob"
                                        class="validate" 
                                        placeholder="dd/mm/yyyy" (blur)="setDate($event.target.value, 'occ_dob')">
                                    <input *ngIf="occ_dobSet" id="dob" type="text" formControlName="dob"
                                        class="validate" placeholder="dd/mm/yyyy" (focus)="unSetDate('occ_dob')">
                                    <label for="dob">Date of Birth (dd/mm/yyyy)<span class="red-text">*</span></label>
                                </div>

                                <div class="input-field col s12 m6">
                                    <i class="material-icons prefix amber-text">person</i>
                                    <input id="age" type="number" min="1" formControlName='age' class="validate"
                                        autocomplete="off" style="text-transform: capitalize;" />
                                    <label for="age">Age</label>
                                </div>

                                <div class="input-field col s12">
                                    <div>Gender</div>
                                    <div class="row col s12">
                                        <label class="col s6">
                                            <input name="gender" type="radio" formControlName="gender" value="Male" class="with-gap" />
                                            <span>Male</span>
                                        </label>
                                        <label class="col s6">
                                            <input name="gender" type="radio" formControlName="gender" value="Female" class="with-gap" />
                                            <span>Female</span>
                                        </label>
                                    </div>
                                </div>

                                <div class="input-field col s12">
                                    <div>In School</div>
                                    <div class="row col s12">
                                        <label class="col s6">
                                            <input name="inSchool" type="radio" formControlName="inSchool" class="with-gap"
                                                [value]="true" (click)="inSchoolYes()" />
                                            <span>Yes</span>
                                        </label>
                                        <label class="col s6">
                                            <input name="inSchool" type="radio" formControlName="inSchool" class="with-gap"
                                                (click)="inSchoolNo()" [value]="false" />
                                            <span>No</span>
                                        </label>
                                    </div>
                                </div>

                                <div class="input-field col s12" *ngIf="inSchool">
                                    <i class="material-icons prefix orange-text">school</i>
                                    <input id="school" type="text" formControlName="school" class="validate">
                                    <label for="school">School</label>
                                </div>

                                <div class="input-field col s12">
                                    <div>Special Needs</div>
                                    <div class="row col s12">
                                        <label class="col s6">
                                            <input name="needs" type="radio" formControlName="needs" [value]="true" class="with-gap"
                                                (click)="needsYes()" />
                                            <span>Yes</span>
                                        </label>
                                        <label class="col s6">
                                            <input name="needs" type="radio" formControlName="needs" (click)="needsNo()" class="with-gap"
                                                [value]="false" />
                                            <span>No</span>
                                        </label>
                                    </div>
                                </div>

                                <div class="input-field col s12" *ngIf="specialNeeds">
                                    <i class="material-icons prefix orange-text">emoji_people</i>
                                    <input id="specialNeeds" type="text" formControlName="specialNeeds"
                                        class="validate">
                                    <label for="specialNeeds">Specify Special Needs</label>
                                </div>

                                <div class="input-field col s12">
                                    <i class="material-icons prefix green-text">people</i>
                                    <select formControlName="relation" (change)="relationChanged($event.target.value)">
                                        <option value="" disabled selected>Select</option>
                                        <option *ngFor="let r of relationships" [value]="r">{{r}}</option>
                                    </select>
                                    <label for="relation">Relation to Applicant</label>
                                </div>

                                <div class="input-field col s12" *ngIf="other_relative">
                                    <i class="material-icons prefix orange-text">other_relative</i>
                                    <input id="other_relative" type="text" formControlName="other_relative"
                                        class="validate">
                                    <label for="other_relative">Relationship</label>
                                </div>
                            </div>

                            <div class="row">
                                <button type="button" class="col s12 btn waves-effect green"
                                    style="margin-bottom: 15px;" [disabled]="!houseForm.get('occupants').valid"
                                    (click)="addOccupant(houseForm.get('occupants').value)">
                                    <i class="material-icons right">add</i> Add
                                </button>

                                <button type="button" class="col s12 btn waves-effect red" style="margin-bottom: 15px;"
                                    (click)="closeAddOccupant()">
                                    <i class="material-icons right">cancel</i> Cancel
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row col s12  white" *ngIf="step == 6" formGroupName="incomeVerification">
                    <div class="card-content">
                        <div class="row col s12 teal lighten-2 white-text">
                            <h5 class="center-align">VERIFICATION OF INCOME</h5>
                        </div>

                        <div class="col s12">

                            <ul class="collection with-header">
                                <li class="collection-header">
                                    <span><strong>{{model.givenName}} {{model.lastName}}</strong></span>
                                    <a (click)="onAddIncome('applicant')" style="cursor: pointer;"
                                        class="right black-text"><i class="material-icons" style="margin-right: 5px;">
                                            add_circle
                                        </i>Add Income</a>
                                </li>
                                <div *ngIf="filterIncomes('Applicant')">
                                    <li *ngFor="let income of filterIncomes('Applicant').incomes"
                                        class="collection-item">
                                        <div>{{income.source}}<a class="secondary-content">{{income.amount}}</a></div>
                                    </li>
                                    <li class="collection-item">
                                        <div><strong>Total: </strong><a class="secondary-content black-text"
                                                style="font-weight: bold; text-decoration: underline;">{{filterIncomes('Applicant').total}}</a>
                                        </div>
                                    </li>
                                </div>
                            </ul>

                            <ul class="collection with-header" *ngIf="model.homeInfo == 'Co-Own'">
                                <li class="collection-header">
                                    <span><strong>{{model.co_applicant_givenName}}
                                            {{model.co_applicant_lastName}}</strong></span>
                                    <a (click)="onAddIncome('co-applicant')" style="cursor: pointer;"
                                        class="right black-text"><i class="material-icons" style="margin-right: 5px;">
                                            add_circle
                                        </i>Add Income</a>
                                </li>
                                <div *ngIf="filterIncomes('Co-Applicant')">
                                    <li *ngFor="let income of filterIncomes('Co-Applicant').incomes"
                                        class="collection-item">
                                        <div>{{income.source}}<a class="secondary-content">{{income.amount}}</a></div>
                                    </li>
                                    <li class="collection-item">
                                        <div><strong>Total: </strong><a class="secondary-content black-text"
                                                style="font-weight: bold; text-decoration: underline;">{{filterIncomes('Co-Applicant').total}}</a>
                                        </div>
                                    </li>
                                </div>
                            </ul>

                            <div *ngIf="occupants.controls.length > 0">
                                <ul class="collection with-header" *ngFor="let occ of model.occupants;let i=index;">
                                    <li class="collection-header">
                                        <span><strong>{{occ.givenName}}
                                                {{occ.lastName}}</strong></span>
                                        <a (click)="onAddIncome('occupant')" style="cursor: pointer;"
                                            class="right black-text"><i class="material-icons"
                                                style="margin-right: 5px;">
                                                add_circle
                                            </i>Add Income</a>
                                    </li>
                                    <div *ngIf="filterIncomes('Occupant')">
                                        <li *ngFor="let income of filterIncomes('Occupant').incomes"
                                            class="collection-item">
                                            <div>{{income.source}}<a class="secondary-content">{{income.amount}}</a>
                                            </div>
                                        </li>
                                        <li class="collection-item">
                                            <div><strong>Total: </strong><a class="secondary-content black-text"
                                                    style="font-weight: bold; text-decoration: underline;">{{filterIncomes('Occupant').total}}</a>
                                            </div>
                                        </li>
                                    </div>
                                </ul>
                            </div>

                            <div id="incomeModal" class="modal">
                                <div class="modal-content">
                                    <div class="row col s12">
                                        <span class="col m6 s12">
                                            <h5>Add Income</h5>
                                        </span>
                                        <span class="col m6 s12">
                                            <h6 class="right teal-text"><strong>{{name}}</strong></h6>
                                        </span>
                                    </div>

                                    <form [formGroup]="incomeForm">
                                        <div class="input-field col s12">
                                            <i class="material-icons prefix green-text">source</i>
                                            <select formControlName="source">
                                                <option value="" disabled selected>Select</option>
                                                <option *ngFor="let income of incomeSource" [value]="income">{{income}}
                                                </option>
                                            </select>
                                            <label for="source">Income Source<span class="red-text">*</span></label>
                                        </div>

                                        <div class="input-field col s12">
                                            <i class="material-icons prefix orange-text">attach_money</i>
                                            <input id="amount" type="number" min=0 formControlName="amount"
                                                class="validate">
                                            <label for="amount">Monthly Amount<span class="red-text">*</span></label>
                                        </div>
                                    </form>


                                    <div class="row">
                                        <button class="col s12 btn waves-effect green" type="button"
                                            [disabled]="!incomeForm.valid" (click)="addIncome(incomeForm.value)"
                                            style="margin-bottom: 15px"><i class="material-icons right">add</i>Add
                                            Income
                                        </button>
                                        <button type="button" class='col s12 btn waves-effect red'
                                            (click)="closeModal()">
                                            <i class="material-icons right">close</i> Close
                                        </button>
                                    </div>
                                </div>

                            </div>
                        </div>

                        <div class="section"></div>
                        <br /><br />

                        <div class="row">
                            <button class="col s12 m5 l3 btn left waves-effect blue" type="button"
                                style="margin-bottom: 15px" (click)="onBack()"><i
                                    class="material-icons left">arrow_back</i>Back
                            </button>

                            <button class="col s12 m5 offset-m2 l3 btn right waves-effect blue" type="button"
                                (click)="submitIncomeInfo()" style="margin-bottom: 15px" title="Save">Next <i
                                    class="material-icons right">arrow_forward</i></button>
                        </div>
                    </div>
                </div>

                <div class="row col s12  white" *ngIf="step == 7">
                    <div class="card-content">
                        <div class="row col s12 teal lighten-2 white-text">
                            <h5 class="center-align">SUPPORTING DOCUMENTATION </h5>
                        </div>
                        <div class="row">
                            <p>Please ensure to attach your supporting documentation to your Housing Application.
                                Failure to submit ALL supporting documentation can result in a delay in processing your
                                Application.
                            </p>
                        </div>
                        <div class="row">
                            <ul class="collection with-header">
                                <li class="collection-header">
                                    <h6><strong>Attached Documents</strong></h6>
                                </li>
                                <li class="collection-item">
                                    Proof of First Nation Registration
                                    <p class="secondary-content" *ngIf="documentsAttached[0]">
                                        <label>
                                            <input type="checkbox" [checked]="documentsAttached[0]" disabled="true" />
                                            <span></span>
                                        </label>
                                    </p>
                                </li>
                                <li class="collection-item">Child Welfare Documentation
                                    <p class="secondary-content" *ngIf="documentsAttached[1]">
                                        <label>
                                            <input type="checkbox" [checked]="documentsAttached[1]" disabled="true" />
                                            <span></span>
                                        </label>
                                    </p>
                                </li>
                                <li class="collection-item">Verification of all Sources of Income
                                    <p class="secondary-content" *ngIf="documentsAttached[2]">
                                        <label>
                                            <input type="checkbox" [checked]="documentsAttached[2]" disabled="true" />
                                            <span></span>
                                        </label>
                                    </p>
                                </li>
                                <li class="collection-item">Accessibility Needs
                                    <p class="secondary-content" *ngIf="documentsAttached[3]">
                                        <label>
                                            <input type="checkbox" checked="documentsAttached[3]" disabled="true" />
                                            <span></span>
                                        </label>
                                    </p>
                                </li>
                                <li class="collection-item">Reference Letters
                                    <p class="secondary-content" *ngIf="documentsAttached[4]">
                                        <label>
                                            <input type="checkbox" checked="documentsAttached[4]" disabled="true" />
                                            <span></span>
                                        </label>
                                    </p>
                                </li>
                            </ul>
                            <button class="col s12 btn green" type="button" (click)="onUpload()"
                                style="margin-bottom: 15px">Upload Documents<i
                                    class="material-icons right">upload</i></button>
                        </div>

                        <div class="row " *ngIf="upload">
                            <div class="center-align">
                                <h5>Attach Documents</h5>
                            </div>
                            <form [formGroup]="documentForm" (ngSubmit)="uploadDoc(documentForm.value)">
                                <div class="input-field col s12">
                                    <div class="label-input">Document Description<span class="red-text">*</span></div>
                                    <select formControlName="description" class="browser-default">
                                        <option value="" disabled selected>Select</option>
                                        <option *ngFor="let doc of Documents" [value]="doc">{{doc}}
                                        </option>
                                    </select>
                                </div>

                                <div class="col s12 file-field input-field">
                                    <div class="btn">
                                        <span>Choose File <i class="material-icons">file_upload
                                            </i></span>
                                        <input type="file" formControlName="file" accept="image/*, application/pdf"
                                            multiple (change)="handleFileInput($event.target.files)" />
                                    </div>
                                    <div class="file-path-wrapper">
                                        <input class="file-path validate" type="text" placeholder="No File Choosen" />
                                    </div>
                                </div>

                                <button type="submit" class='col s12 btn waves-effect' style="margin-bottom: 15px;">
                                    <i class="material-icons right">attach_file</i> Attach File
                                </button>
                            </form>
                        </div>

                        <div class="row">

                            <button class="col s12 m5 l3 btn left waves-effect blue" type="button"
                                style="margin-bottom: 15px" (click)="onBack()"><i
                                    class="material-icons left">arrow_back</i>Back
                            </button>

                            <button class="col s12 m5 offset-m2 l3 btn right waves-effect blue" type="button"
                                (click)="submitFiles()" style="margin-bottom: 15px" title="Save">Next <i
                                    class="material-icons right">arrow_forward</i></button>
                            <!-- [disabled]="!houseForm.controls.accessibility.valid"  -->
                        </div>
                    </div>
                </div>

                <div class="row col s12  white" *ngIf="step == 8">
                    <div class="card-content">
                        <div class="row col s12 teal lighten-2 white-text">
                            <h5 class="center-align">DECLARATION CONSENT & RELEASE OF INFORMATION </h5>
                        </div>

                        <div class="row">
                            <div class="row col s12" style="text-align: justify;">
                                <br />
                                <p>I declare that all information provided in this application is correct and complete
                                    to
                                    the
                                    best of my knowledge. The application and supporting documents, once received by
                                    FWFN
                                    become
                                    the property of FWFN Housing Department for file, reference and selection purposes.
                                    FWFN
                                    reserves the right to place applications on waiting lists as deemed necessary.</p>
                                <br />
                                <p>Personal Information contained in this application or in the attachments is
                                    collected by
                                    the
                                    FWFN Housing Department pursuant to the Freedom of Information and Protection of
                                    Privacy
                                    Act, (R.S.O. 1990,c.M.56). This information will be used solely to determine
                                    eligibility
                                    of
                                    FWFN Low Income, Rent-to Own, Special Needs Housing, size and type of housing
                                    unit and
                                    placement of the household on waiting lists on Reserve.</p>
                                <br />
                                <p>Personal Information may be disclosed to internal FWFN departments that assist in
                                    the
                                    provision of services and financial assistance to the applicant. Information
                                    provided by
                                    the
                                    household may be shared for the purpose of making decisions or verifying
                                    eligibility for
                                    assistance under the Ontario Disability Support Program Act (1997), Ontario
                                    Works Act
                                    (1997).</p>
                                <br />
                                <p>The Applicant consents to the verification, disclosure and transfer of
                                    information given
                                    on
                                    this form and attachments by or to any of the above entities and will provide
                                    any
                                    supporting
                                    material or documents.</p>
                                <br />
                                <p>Any questions relative to the collection of this information can be directed to
                                    the Fort
                                    William First Nation Housing Manager 90 Anemki Drive – Suite 200, Fort William
                                    First
                                    Nation,
                                    Ontario, P7J 1L3 (807- 623-9543.</p>
                                <!-- </div> -->
                            </div>
                        </div>

                        <div class="row col right">
                            <div class="input-field">
                                <!-- <div class="row col s12">Do You Agree?</div> -->
                                <div class="row col s12">
                                    <label class="col s6">
                                        <input class="with-gap" name="agree" formControlName="agree" type="radio"
                                            [value]="true" (change)="onAgreeYes($event)" />
                                        <span class="text"><strong>Agree</strong></span>
                                    </label>
                                    <label class="col s6">
                                        <input class="with-gap" name="agree" formControlName="agree" type="radio"
                                            [value]="false" (change)="onAgreeNo($event)" />
                                        <span class="text"><strong>Disagree</strong></span>
                                    </label>
                                </div>
                            </div>
                        </div>

                        <div class="row col s12">

                            <button class="col s12 m5 l3 btn left waves-effect blue" type="button"
                                style="margin-bottom: 15px" (click)="onBack()"><i
                                    class="material-icons left">arrow_back</i>Back
                            </button>

                            <button class="col s12 m5 offset-m2 l3 btn right waves-effect blue" type="button"
                                [disabled]="!houseForm.controls.agree.value" style="margin-bottom: 15px"
                                (click)="onNext()" title="Save">Next <i
                                    class="material-icons right">arrow_forward</i></button>
                            <!-- [disabled]="!houseForm.controls.accessibility.valid"  -->
                        </div>
                    </div>
                </div>

                <div class="row col s12  white" formGroupName="signature" *ngIf="step == 9 && !showCanvas">
                    <div class="card-content">
                        <div class="row col s12 teal lighten-2 white-text">
                            <h5 class="center-align">SIGNATURE</h5>
                        </div>

                        <div class="row col s12">
                            <div class="col s12 m6">
                                <p class="col s12">Signature of Applicant<span class="red-text">*</span></p>
                                <a *ngIf="!applicantSigned" [ngClass]="'btn col s8 btn waves-effect ' + themeColor"
                                    style="margin-top: 15px;" (click)="sign('applicant')">Sign</a>

                                <a *ngIf="applicantSigned" class="col s4 waves-effect" style="margin-top: 15px;"><i
                                        class="material-icons left green-text small">check_circle</i>Signed</a>
                                <a *ngIf="applicantSigned" class="col s4 pink-text" style="margin-top: 15px;"
                                    (click)="clearSignature(1)"><i
                                        class="material-icons left pink-text">clear</i>Clear</a>
                            </div>

                            <div class="input-field col s12 m6">
                                <!-- <input id="applicantDate" type="text" class="validate" formControlName="applicantDate"
                                    [textMask]="{mask: dateMask, guide: true}" placeholder="dd/mm/yyyy"> -->
                                    <input id="applicantDate" type="text" class="validate" formControlName="applicantDate"
                                     placeholder="dd/mm/yyyy">
                                <label for="applicantDate">Date (dd/mm/yyyy)</label>
                            </div>
                        </div>

                        <div *ngIf="model.homeInfo == 'Co-Own'">
                            <div class="col s12 m6">
                                <p class="col s12">Signature of Co-applicant<span class="red-text">*</span></p>
                                <a *ngIf="!co_applicantSigned" [ngClass]="'btn col s8 btn waves-effect ' + themeColor"
                                    style="margin-top: 15px;" (click)="sign('co_applicant')">Sign</a>

                                <a *ngIf="co_applicantSigned" class="col s4 waves-effect" style="margin-top: 15px;"><i
                                        class="material-icons left green-text small">check_circle</i>Signed</a>
                                <a *ngIf="co_applicantSigned" class="col s4 pink-text" style="margin-top: 15px;"
                                    (click)="clearSignature(2)"><i
                                        class="material-icons left pink-text">clear</i>Clear</a>
                            </div>

                            <div class="input-field col s12 m6">
                                <!-- <input id="co_applicantDate" type="text" class="validate"
                                    formControlName="co_applicantDate" [textMask]="{mask: dateMask, guide: true}"
                                    placeholder="dd/mm/yyyy"> -->
                                    <input id="co_applicantDate" type="text" class="validate"
                                    formControlName="co_applicantDate" 
                                    placeholder="dd/mm/yyyy">
                                <label for="co_applicantDate">Date (dd/mm/yyyy)</label>
                            </div>
                        </div>

                        <div class="row col s12" style="margin-top: 25px;">
                            <button class="col s12 m5 l3 btn left waves-effect blue" type="button"
                                style="margin-bottom: 15px" (click)="onBack()"><i
                                    class="material-icons left">arrow_back</i>Back
                            </button>

                            <button class="col s12 m5 offset-m2 l3 btn right waves-effect green" type="button"
                                [disabled]="!houseForm.controls.signature.value" style="margin-bottom: 15px"
                                (click)="submitSignatures(houseForm.controls.signature.value)" title="Save">Submit <i
                                    class="material-icons right">send</i></button>
                            <!-- [disabled]="!houseForm.controls.accessibility.valid"  -->
                        </div>
                    </div>
                </div>

                <div class="row" *ngIf="step==9 && showCanvas" formGroupName="signature">

                    <div class="row s12 card-panel grey lighten-4 center">
                        <div class="row">
                            <h6 class="blue-text text-darken-2 center"><strong> SIGNATURE</strong></h6>
                            <canvas></canvas>
                        </div>
                    </div>

                    <div class="section"></div>

                    <a class="btn-flat center green lighten-1 col s12 white-text" (click)="approveSignature()"
                        style="margin-bottom: 15px;"><i class="material-icons left">check</i> Sign</a>
                    <br>
                    <a class="btn-flat center red lighten-1 col s12 white-text" (click)="clearSignature()"
                        style="margin-bottom: 15px;"><i class="material-icons left">loop</i> Clear Signature</a>
                    <br>
                    <a [ngClass]="'col s12 btn waves-effect ' + themeColor" (click)="BackToApp()"><i
                            class="material-icons left">arrow_back_ios</i> Back To My Application </a>
                </div>

                <!-- <div class="row col s12" *ngIf="step < 10">
                    <ul class="pagination row col s12 center-align"
                        style="cursor: pointer; padding-top: 5px; padding-bottom: 5px;">
                        <li class="col s1"><a></a>
                        <li class="col s1" [ngClass]="{'active':step == 1}"><a>1</a></li>
                        <li class="col s1" [ngClass]="{'active':step == 2}"><a>2</a></li>
                        <li class="col s1" [ngClass]="{'active':step == 3}"><a>3</a></li>
                        <li class="col s1" [ngClass]="{'active':step == 4}"><a>4</a></li>
                        <li class="col s1" [ngClass]="{'active':step == 5}"><a>5</a></li>
                        <li class="col s1" [ngClass]="{'active':step == 6}"><a>6</a></li>
                        <li class="col s1" [ngClass]="{'active':step == 7}"><a>7</a></li>
                        <li class="col s1" [ngClass]="{'active':step == 8}"><a>8</a></li>
                        <li class="col s1" [ngClass]="{'active':step == 9}"><a>9</a></li>
                        <li class="col s2" [ngClass]="{'active':step == 10}"><a>Summary</a>
                    </ul>
                </div> -->
            </form>

        </div>
    </main>

    <main class="StickyContent white row">

        <div class="row col s12  summary" *ngIf="step == 10">
            <div class="row col s12 teal lighten-2 white-text">
                <h5 class="left-align col s6">SUMMARY</h5>
                <div class="row right" style="margin-top: 15px; margin-right: 10px;">
                    <a class='dropdown-trigger btn-flat waves-effect waves-light white-text ' id="report"
                        data-target='dropdown1' style="font-weight: bold; border: 2px solid white;"><i
                            class="material-icons right">picture_as_pdf</i>Get Report</a>
                    <!-- Dropdown Structure -->
                    <ul id='dropdown1' class='col dropdown-content'>
                        <li><a (click)="downloadReport()" class="btn-flat center-align"><i class="material-icons right">
                                    download
                                </i>Download</a></li>
                        <li><a (click)="openReport()" class="btn-flat center-align"><i class="material-icons right">
                                    open_in_new
                                </i>Open</a></li>
                        <li><a (click)="printReport()" class="btn-flat center-align"><i class="material-icons right">
                                    print
                                </i>Print</a></li>
                        <li><a (click)="sendReport()" class="btn-flat center-align"><i class="material-icons right">
                                    share
                                </i>Send</a></li>
                    </ul>
                </div>
            </div>


            <div class="row col l11 ">
                <ul class="collection with-header col s12">
                    <li class="collection-header grey lighten-4">
                        <h5 class="bold-700 sub-title-section">PRIMARY APPLICANT DETAILS</h5>
                    </li>
                    <li class="collection-item col m6 s12">
                        <div>First Name<span class="secondary-content">{{model.givenName}}</span></div>
                    </li>
                    <li class="collection-item col m6 s12">
                        <div>Last Name<span class="secondary-content">{{model.lastName}}</span></div>
                    </li>
                    <li class="collection-item col m6 s12">
                        <div>Middle Name<span class="secondary-content">{{model.middleName}}</span></div>
                    </li>
                    <li class="collection-item col m6 s12">
                        <div>Alias/Maiden Name<span class="secondary-content">{{model.aliasName}}</span></div>
                    </li>
                    <li class="collection-item col m6 s12">
                        <div>Title<span class="secondary-content">{{model.title}}</span></div>
                    </li>
                    <li class="collection-item col m6 s12">
                        <div>Marital Status<span class="secondary-content">{{model.maritalStatus}}</span></div>
                    </li>
                    <li class="collection-item col m6 s12">
                        <div>Date of Birth <span class="secondary-content">{{model.dob | date: 'dd/MM/yyyy'}}</span>
                        </div>
                    </li>
                    <li class="collection-item col m6 s12">
                        <div>SIN<span class="secondary-content">{{model.SIN}}</span></div>
                    </li>
                    <li class="collection-item col m6 s12">
                        <div>Member of FWFN
                            <span class="secondary-content" *ngIf="model.FWFN">Yes</span>
                            <span class="secondary-content" *ngIf="!model.FWFN">No</span>
                        </div>
                    </li>
                    <li class="collection-item col m6 s12">
                        <div>First Nation Registration Number<span
                                class="secondary-content">{{model.registrationNo}}</span>
                        </div>
                    </li>
                </ul>

                <ul class="collection with-header col s12">
                    <li class="collection-header grey lighten-4">
                        <h5 class="bold-700 sub-title-section">APPLICANT'S CONTACT INFORMATION</h5>
                    </li>
                    <li class="collection-item col m6 s12">
                        <div>Telephone Number<span class="secondary-content">{{model.telephone}}</span></div>
                    </li>
                    <li class="collection-item col m6 s12">
                        <div>Mobile Number<span class="secondary-content">{{model.cellphone}}</span></div>
                    </li>
                    <li class="collection-item col m6 s12">
                        <div>WorK Number<span class="secondary-content">{{model.workNumber}}</span></div>
                    </li>
                    <li class="collection-item col m6 s12">
                        <div>Extension<span class="secondary-content">{{model.ext}}</span></div>
                    </li>
                    <li class="collection-item col s12 grey lighten-5">
                        <h6 class="bold-500 sub-title-section">ALTERNATE CONTACT INFORMATION</h6>
                    </li>
                    <li class="collection-item col m6 s12">
                        <div>Alternative Telephone Number<span class="secondary-content">{{model.telephone2}}</span>
                        </div>
                    </li>
                    <li class="collection-item col m6 s12">
                        <div>Alternative Mobile Number <span class="secondary-content">{{model.cellphone2}}</span>
                        </div>
                    </li>
                    <li class="collection-item col s12 grey lighten-2">
                        <h6 class="bold-700 sub-title-section">APPLICANT'S MAILING ADDRESS</h6>
                    </li>
                    <li class="collection-item col m6 s12">
                        <div>Address<span class="secondary-content">{{model.address}}</span></div>
                    </li>
                    <li class="collection-item col m6 s12">
                        <div>City<span class="secondary-content">{{model.city}}</span></div>
                    </li>
                    <li class="collection-item col m6 s12">
                        <div>Province<span class="secondary-content">{{model.province}}</span></div>
                    </li>
                    <li class="collection-item col m6 s12">
                        <div>Postal Code<span class="secondary-content">{{model.postalCode}}</span></div>
                    </li>
                </ul>

                <ul class="collection with-header col s12">
                    <li class="collection-header grey lighten-4">
                        <h5 class="bold-700 sub-title-section">PRESENT ACCOMMODATION</h5>
                    </li>
                    <li class="collection-item">
                        <div>Address of Accommodation<span
                                class="secondary-content">{{model.accomodationAddress}}</span>
                        </div>
                    </li>
                    <li class="collection-item">
                        <div>House Information<span class="secondary-content">{{model.homeInfo}}</span></div>
                    </li>
                    <div *ngIf="model.homeInfo == 'Co-Own'">
                        <li class="collection-header">
                            <h5><strong>CO-APPLICANT DETAILS</strong></h5>
                        </li>
                        <li class="collection-item col m6 s12" style="padding-top: 5px; padding-bottom: 5px">
                            <div>First Name<span class="secondary-content">{{model.co_applicant_givenName}}</span>
                            </div>
                        </li>
                        <li class="collection-item col m6 s12" style="padding-top: 5px; padding-bottom: 5px">
                            <div>Last Name <span class="secondary-content">{{model.co_applicant_lastName}}</span>
                            </div>
                        </li>
                        <li class="collection-item col m6 s12" style="padding-top: 5px; padding-bottom: 5px">
                            <div>Middle Name<span class="secondary-content">{{model.co_applicant_middleName}}</span>
                            </div>
                        </li>
                        <li class="collection-item col m6 s12" style="padding-top: 5px; padding-bottom: 5px">
                            <div>Maiden/Alias Name <span
                                    class="secondary-content">{{model.co_applicant_aliasName}}</span>
                            </div>
                        </li>
                        <li class="collection-item col m6 s12" style="padding-top: 5px; padding-bottom: 5px">
                            <div>Title<span class="secondary-content">{{model.co_applicant_title}}</span></div>
                        </li>
                        <li class="collection-item col m6 s12" style="padding-top: 5px; padding-bottom: 5px">
                            <div>Gender<span class="secondary-content">{{model.co_applicant_gender}}</span></div>
                        </li>
                        <li class="collection-item col m6 s12" style="padding-top: 5px; padding-bottom: 5px">
                            <div>Marital Status<span
                                    class="secondary-content">{{model.co_applicant_maritalStatus}}</span>
                            </div>
                        </li>
                        <li class="collection-item col m6 s12" style="padding-top: 5px; padding-bottom: 5px">
                            <div>Date of Birth<span class="secondary-content">{{model.co_applicant_dob | date:
                                    'dd/MM/yyyy'}}</span>
                            </div>
                        </li>
                        <li class="collection-item col m6 s12" style="padding-top: 5px; padding-bottom: 5px">
                            <div>SIN<span class="secondary-content">{{model.co_applicant_SIN}}</span></div>
                        </li>
                        <li class="collection-item col m6 s12" style="padding-top: 5px; padding-bottom: 5px">
                            <div>Band Name<span class="secondary-content">{{model.co_applicant_bandName}}</span>
                            </div>
                        </li>
                        <li class="collection-item col m6 s12" style="padding-top: 5px; padding-bottom: 5px">
                            <div>First Nation Registration Number<span
                                    class="secondary-content">{{model.co_applicant_registrationNo}}</span></div>
                        </li>
                    </div>

                    <div *ngIf="model.homeInfo == 'Rent'">
                        <li class="collection-header">
                            <h5><strong>CURRENT LANDLORD</strong></h5>
                        </li>
                        <li class="collection-item col m6 s12" style="padding-top: 5px; padding-bottom: 5px">
                            <div>First Name<span class="secondary-content">{{model.landLord_givenName}}</span></div>
                        </li>
                        <li class="collection-item col m6 s12" style="padding-top: 5px; padding-bottom: 5px">
                            <div>Last Name <span class="secondary-content">{{model.landLord_lastName}}</span></div>
                        </li>
                        <li class="collection-item col m6 s12" style="padding-top: 5px; padding-bottom: 5px">
                            <div>Address<span class="secondary-content">{{model.landLord_address}}</span></div>
                        </li>
                        <li class="collection-item col m6 s12" style="padding-top: 5px; padding-bottom: 5px">
                            <div>City<span class="secondary-content">{{model.landLord_province}}</span></div>
                        </li>
                        <li class="collection-item col m6 s12" style="padding-top: 5px; padding-bottom: 5px">
                            <div>Province<span class="secondary-content">{{model.landLord_city}}</span></div>
                        </li>
                        <li class="collection-item col m6 s12" style="padding-top: 5px; padding-bottom: 5px">
                            <div>Postal Code<span class="secondary-content">{{model.landLord_postalCode}}</span>
                            </div>
                        </li>
                        <li class="collection-item col m6 s12" style="padding-top: 5px; padding-bottom: 5px">
                            <div>Move-In Date<span class="secondary-content">{{model.landLord_moveInDate | date:
                                    'dd/MM/yyyy'}}</span>
                            </div>
                        </li>
                        <li class="collection-item col m6 s12" style="padding-top: 5px; padding-bottom: 5px">
                            <div>Lease Agreement<span class="secondary-content">{{model.landLord_leaseAgreement}}</span>
                            </div>
                        </li>
                        <li class="collection-item col m6 s12 " style="padding-top: 5px; padding-bottom: 5px">
                            <div>Expiry Date<span class="secondary-content">{{model.landLord_expireDate | date:
                                    'dd/MM/yyyy'}}</span>
                            </div>
                        </li>
                    </div>

                    <div *ngIf="model.homeInfo == 'Other'">
                        <li class="collection-header">
                            <h6><strong>OTHER ACCOMODATION</strong></h6>
                        </li>
                        <li class="collection-item">
                            <div>Description<span class="secondary-content">{{model.otherAccoummodation}}</span>
                            </div>
                        </li>
                    </div>
                </ul>

                <ul class="collection with-header col s12">
                    <li class="collection-header grey lighten-4">
                        <h5 class="bold-700 sub-title-section">ACCESSIBILITY</h5>
                    </li>
                    <li class="collection-item">
                        <div>Do you or family members have accessibility needs?
                            <span class="secondary-content" *ngIf="model.accessibilityNeeds">Yes</span>
                            <span class="secondary-content" *ngIf="!model.accessibilityNeeds">No</span>
                        </div>
                    </li>
                    <li class="collection-item" *ngIf="model.accessibilityNeeds">
                        <p>{{model.accessibilityExplain}}</p>
                    </li>
                    <li class="collection-item">
                        <div>Do all household members currently reside at present accommodation?
                            <span class="secondary-content" *ngIf="!model.reside">No</span>
                            <span class="secondary-content" *ngIf="model.reside">Yes</span>
                        </div>
                    </li>
                    <li class="collection-item" *ngIf="!model.reside">
                        <p>{{model.resideExplain}}</p>
                    </li>

                    <li class="collection-item">
                        <div>Are there any Health and Safety issues at your current accommodation? (Overcrowding,
                            hazards, etc.)
                            <span class="secondary-content" *ngIf="model.healthSafetyIssues">Yes</span>
                            <span class="secondary-content" *ngIf="!model.healthSafetyIssues">No</span>
                        </div>
                    </li>
                    <li class="collection-item" *ngIf="model.healthSafetyIssues">
                        <p>{{model.healthSafetyExplain}}</p>
                    </li>
                </ul>

                <ul class="collection with-header col s12" *ngIf="model.occupants?.length > 0">
                    <li class="collection-header">
                        <h5 class="bold-700 sub-title-section">OTHER OCCUPANT INFORMATION</h5>
                    </li>
                    <div *ngFor="let occ of model.occupants; let i=index">
                        <li class="collection-header">
                            <h6><strong>{{occ.givenName}} {{occ.lastName}}</strong><a class="right"
                                    (click)="expantOccupatDetails(i)" style="cursor: pointer;">Details <i
                                        class="material-icons right">expand_more</i></a></h6>
                        </li>
                        <div *ngIf="showOccupants[i]">
                            <li class="collection-item s6"><strong>First Name: </strong><span
                                    class="secondary-content">{{occ.givenName}}</span></li>
                            <li class="collection-item s6"><strong>Last Name: </strong><span
                                    class="secondary-content">{{occ.lastName}}</span></li>
                            <li class="collection-item s6"><strong>Middle Name: </strong><span
                                    class="secondary-content">{{occ.middleName}}</span></li>
                            <li class="collection-item s6"><strong>Date of Birth: </strong><span
                                    class="secondary-content">{{occ.dob | date: 'dd/MM/yyyy'}}</span></li>
                            <li class="collection-item s6"><strong>SIN: </strong><span
                                    class="secondary-content">{{occ.SIN}}</span></li>
                            <li class="collection-item s6"><strong>Gender: </strong><span
                                    class="secondary-content">{{occ.gender}}</span></li>
                            <li class="collection-item s6"><strong>In School: </strong><span
                                    class="secondary-content">{{occ.inSchool}}</span></li>
                            <li *ngIf="occ.inSchool" class="collection-item s6"><strong>School:</strong><span
                                    class="secondary-content">{{occ.school}}</span></li>
                            <li class="collection-item s6"><strong>Special Needs: </strong><span
                                    class="secondary-content">{{occ.needs}}</span></li>
                            <li *ngIf="occ.needs" class="collection-item s6"><span
                                    class="secondary-content">{{occ.specialNeeds}}</span></li>
                            <li class="collection-item s6"><strong>Relation to Applicant: </strong><span
                                    class="secondary-content">{{occ.relation}}</span></li>
                            <li *ngIf="occ.relation == 'Other'" class="collection-item s6"><span
                                    class="secondary-content">{{occ.other_relative}}</span></li>
                        </div>
                    </div>
                </ul>

                <ul class="collection with-header col s12" *ngIf="model.incomes?.length > 0">
                    <li class="collection-header grey lighten-4">
                        <h5 class="bold-700 sub-title-section">VERIFICATION OF INCOME</h5>
                    </li>
                    <div *ngFor="let income of model.incomes; let i=index">
                        <li class="collection-header">
                            <h6><strong>{{income.name}}</strong><a class="right" (click)="expandIncomeInfo(i)"
                                    style="cursor: pointer;">Details <i class="material-icons right">expand_more</i></a>
                            </h6>
                        </li>
                        <div *ngIf="showIncome[i]">
                            <li class="collection-item s6">Name: <span class="secondary-content">{{income.name}}</span>
                            </li>
                            <li class="collection-item s6">Role: <span class="secondary-content">{{income.role}}</span>
                            </li>
                            <li class="collection-header s12">
                                <h6><strong>Incomes</strong></h6>
                            </li>
                            <li class="collection-item s6" *ngFor="let i of income.incomes;let n=index">
                                {{i.source}}: <span class="secondary-content">{{i.amount}}</span></li>
                            <li class="collection-item s6"><strong>Total</strong><span
                                    class="secondary-content"><strong>{{income.total}}</strong></span></li>
                        </div>
                    </div>
                </ul>

                <ul class="collection with-header col s12" *ngIf="model.files?.length > 0">
                    <li class="collection-header grey lighten-4">
                        <h5 class="bold-700 sub-title-section">SUPPORTING DOCUMENTATION</h5>
                    </li>
                    <div *ngFor="let file of model.files; let i=index">
                        <li class="collection-item">
                            <h6><strong>{{file.name}}</strong><a [href]="file.url" target="_blank"
                                    class="secondary-content right"><i class="material-icons">
                                        open_in_new
                                    </i></a></h6>
                        </li>
                        <div *ngIf="showIncome[i]">
                            <li class="collection-item s6">Name: <span class="secondary-content">{{income.name}}</span>
                            </li>
                            <li class="collection-item s6">Role: <span class="secondary-content">{{income.role}}</span>
                            </li>
                            <li class="collection-header s12">
                                <h6><strong>Incomes</strong></h6>
                            </li>
                            <li class="collection-item s6" *ngFor="let i of income.incomes;let n=index">
                                {{i.source}}: <span class="secondary-content">{{i.amount}}</span></li>
                            <li class="collection-item s6"><strong>Total</strong><span
                                    class="secondary-content"><strong>{{income.total}}</strong></span></li>
                        </div>
                    </div>
                </ul>

                <div class="row col s12" style="margin-top: 25px;">
                    <button class="col s12 m5 l3 btn left waves-effect blue" type="button" style="margin-bottom: 15px"
                        (click)="onBack()"><i class="material-icons left">arrow_back</i>Back
                    </button>

                    <button class="col s12 m5 offset-m2 l3 btn right waves-effect green" type="button"
                        [disabled]="!houseForm.controls.signature.value" style="margin-bottom: 15px"
                        (click)="submitForm()" title="Save">Submit <i class="material-icons right">send</i></button>
                    <!-- [disabled]="!houseForm.controls.accessibility.valid"  -->
                </div>
            </div>
        </div>

    </main>

</div>