<div class="col s12 ">
    <div class="body-h">


        <div class="header-setting b-m-2 ">
            <div class="col l12 m12 s12 p-b-2">
                <h4>Renovation Requests</h4>
            </div>
        </div>
        <!-- <div class="row center-align">
    <h5><strong>Renovation Requests</strong></h5>
</div> -->
        <main class="departments-list StickyContent white row " style="padding-top:0%;">

            <app-firebase-loading *ngIf="loading"></app-firebase-loading>

            <div class="row col s12">
                <div class="input-field col s12">
                    <i class="material-icons prefix">search</i>
                    <input id="houseNo" type="number" placeholder="House Number" (input)="filterBy($event.target.value)"
                        class="validate">
                </div>
            </div>

            <div class="row col s12">
                <div class="card">
                    <div class="card-content">
                        <br />
                        <div class="card-tabs">
                            <ul class="tabs tabs-fixed-width">
                                <li class="tab"><a class="active" href="#pending_requests">Pending <span
                                            class="badge right hide-on-small-only">{{pending?.length}}</span></a></li>
                                <li class="tab"><a [ngClass]="{'red-text': hasOverdue,'blink': hasOverdue}"
                                        href="#approved_requests">In Progress <span
                                            class="badge right hide-on-small-only ">{{approved?.length}}</span></a></li>
                                <li class="tab"><a href="#declined_requests">Declined <span
                                            class="badge right hide-on-small-only">{{declined?.length}}</span></a></li>
                                <li class="tab"><a href="#compeleted_request">Completed <span
                                            class="badge right hide-on-small-only">{{completed?.length}}</span></a></li>
                            </ul>
                        </div>

                        <div class="card-content grey lighten-5">
                            <div id="pending_requests">
                                <div class="row" *ngIf="pending">
                                    <div class="row center-align" *ngIf="pending.length == 0">
                                        <i class="large material-icons blue-text">info</i>
                                        <p class="blue-text"><strong>No Pending Requests.</strong></p>
                                    </div>

                                    <div *ngIf="pending.length > 0">
                                        <h6 class="center-align"><strong> Pending Requests</strong></h6>
                                        <br />
                                        <div class="row" *ngFor="let req of pending; let i=index">
                                            <div class="col s12">
                                                <button class="btn white right tooltipped" data-position="bottom"
                                                    data-tooltip="Open as a PDF" type="button"
                                                    style="margin-bottom: 25px;" (click)="openPdf('Pending',i)"
                                                    style="margin:5px;"><i
                                                        class="material-icons black-text">open_in_new</i></button>

                                                <button class="btn white right tooltipped" data-position="bottom"
                                                    data-tooltip="Download Application" type="button"
                                                    (click)="downloadPdf('Pending', i)" style="margin:5px;"><i
                                                        class="material-icons black-text">download</i></button>
                                            </div>

                                            <ul class="collection with-header">
                                                <li class="collection-header">
                                                    <h5>{{req.title | uppercase}}</h5>
                                                </li>
                                                <li class="collection-item">
                                                    <div><strong>Description: </strong><a
                                                            class="secondary-content">{{req.description}}</a>
                                                    </div>
                                                </li>
                                                <li class="collection-item">
                                                    <div><strong>Purpose: </strong><a
                                                            class="secondary-content">{{req.purpose?req.purpose:''}}</a>
                                                    </div>
                                                </li>
                                                <li class="collection-item">
                                                    <div> <strong>House Number: </strong><a
                                                            class="secondary-content">{{req.houseNumber}}</a>
                                                    </div>
                                                </li>
                                                <li class="collection-item">
                                                    <div><strong>Request Date: </strong><a class="secondary-content">
                                                            {{req.requestDate.toDate().toDateString()}}</a></div>
                                                </li>
                                                <li class="collection-item">
                                                    <div> <strong>Request Status: </strong><a
                                                            class="secondary-content">{{req.status}}</a>
                                                    </div>
                                                </li>
                                            </ul>
                                            <br />
                                            <div class="row col s12">
                                                <div class="col s12 m6">
                                                    <a class="center col btn-flat s12 green lighten-4"
                                                        (click)="acceptRequest(i)"
                                                        style="cursor: pointer; margin-bottom: 15px;"><i
                                                            class="material-icons black-text"
                                                            style="margin-right: 7px;">thumb_up</i>
                                                        <strong class="black-text">Approve
                                                            Request</strong></a>
                                                </div>
                                                <div class="col s12 m6">
                                                    <a class="center btn-flat col s12 red" (click)="declineRequest(i)"
                                                        style="cursor: pointer;"><i
                                                            class="center material-icons white-text"
                                                            style="margin-right: 7px;">thumb_down</i><strong
                                                            class="center white-text">Decline
                                                            Request</strong></a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div id="approved_requests">
                                <div class="row" *ngIf="approved">
                                    <div class="row center-align" *ngIf="approved.length == 0">
                                        <i class="large material-icons blue-text">info</i>
                                        <p class="blue-text"><strong>No Approved Requests.</strong></p>
                                    </div>

                                    <div *ngIf="approved.length > 0">
                                        <h6 class="center-align"><strong> Approved Requests</strong></h6>
                                        <br />
                                        <div class="row" *ngFor="let req of approved; let i=index">
                                            <div class="col s12">
                                                <button class="btn white right tooltipped" data-position="bottom"
                                                    data-tooltip="Open as a PDF" type="button"
                                                    style="margin-bottom: 25px;" (click)="openPdf('Approved',i)"
                                                    style="margin:5px;"><i
                                                        class="material-icons black-text">open_in_new</i></button>

                                                <button class="btn white right tooltipped" data-position="bottom"
                                                    data-tooltip="Download Application" type="button"
                                                    (click)="downloadPdf('Approved', i)" style="margin:5px;"><i
                                                        class="material-icons black-text">download</i></button>
                                            </div>

                                            <ul class="collection with-header">
                                                <li class="collection-header">
                                                    <h5
                                                        [ngClass]="{'red-text': requestOverDue(req.decision.startDate)}">
                                                        {{req.title
                                                        | uppercase}} <span
                                                            *ngIf="requestOverDue(req.decision.startDate)"
                                                            class="red-text right"
                                                            style="font-size: 16px; font-weight: bold;">Overdue</span>
                                                    </h5>
                                                </li>
                                                <li class="collection-item">
                                                    <div><strong>Description: </strong><a
                                                            class="secondary-content">{{req.description}}</a>
                                                    </div>
                                                </li>
                                                <li class="collection-item">
                                                    <div><strong>Purpose: </strong><a
                                                            class="secondary-content">{{req.purpose?req.purpose:''}}</a>
                                                    </div>
                                                </li>
                                                <li class="collection-item">
                                                    <div> <strong>House Number: </strong><a
                                                            class="secondary-content">{{req.houseNumber}}</a>
                                                    </div>
                                                </li>
                                                <li class="collection-item">
                                                    <div><strong>Request Date: </strong><a class="secondary-content">
                                                            {{req.requestDate.toDate().toDateString()}}</a></div>
                                                </li>
                                                <li class="collection-item">
                                                    <div> <strong>Request Status: </strong><a
                                                            class="secondary-content">{{req.status}}</a>
                                                    </div>
                                                </li>

                                                <div *ngIf="req.previouseDecision">
                                                    <li class="collection-header" (click)="onShowMore(i, 'approved')"
                                                        style="cursor: pointer;">
                                                        <h5>History<i class="material-icons right">expand_more</i></h5>
                                                    </li>
                                                    <li class="collection-item" *ngIf="showMorePending[i]">
                                                        <div> <strong>Request Declined On: </strong><a
                                                                class="secondary-content">{{req.previouseDecision.date.toDate().toDateString()}}</a>
                                                        </div>
                                                    </li>
                                                    <li class="collection-item" *ngIf="showMorePending[i]">
                                                        <div> <strong>Reason: </strong><a
                                                                class="secondary-content">{{req.previouseDecision.reason}}</a>
                                                        </div>
                                                    </li>
                                                    <li class="collection-item" *ngIf="showMorePending[i]">
                                                        <div> <strong>Appealed On: </strong><a
                                                                class="secondary-content">{{req.appeal.date.toDate().toDateString()}}</a>
                                                        </div>
                                                    </li>
                                                    <li class="collection-item" *ngIf="showMorePending[i]">
                                                        <div> <strong>Appeal Statement: </strong><a
                                                                class="secondary-content">{{req.appeal.statement}}</a>
                                                        </div>
                                                    </li>
                                                    <li class="collection-item" *ngIf="showMorePending[i]">
                                                        <div> <strong>Appeal Approved On: </strong><a
                                                                class="secondary-content">{{req.decision.date.toDate().toDateString()}}</a>
                                                        </div>
                                                    </li>
                                                </div>

                                                <li class="collection-header">
                                                    <h6>Details</h6>
                                                </li>
                                                <li class="collection-item">
                                                    <div> <strong>Employee: </strong><a
                                                            class="secondary-content">{{req.decision.employee}}</a>
                                                    </div>
                                                </li>
                                                <li class="collection-item">
                                                    <div> <strong>Start Date: </strong><a
                                                            class="secondary-content">{{req.decision.startDate.toDate().toDateString()}}</a>
                                                    </div>
                                                </li>
                                                <li class="collection-item">
                                                    <div> <strong>End Date: </strong><a
                                                            class="secondary-content">{{req.decision.endDate.toDate().toDateString()}}</a>
                                                    </div>
                                                </li>
                                                <li class="collection-item">
                                                    <div> <strong>Date: </strong><a class="secondary-content">{{
                                                            isDate(req.decision.date) ? (req.decision.date | date:
                                                            'dd/MM/yyyy'):
                                                            req.decision.date.toDate().toDateString()| date:
                                                            'dd/MM/yyyy'}}</a>
                                                    </div>
                                                </li>
                                            </ul>
                                            <div class="row col s12">
                                                <a class="col s12 btn green" (click)="requestCompleted(i)"
                                                    style="cursor: pointer; margin-bottom: 15px;"><i
                                                        class="material-icons white-text right">task_alt</i> <strong
                                                        class="white-text">
                                                        Completed</strong></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div id="declined_requests">
                                <div class="row" *ngIf="declined">
                                    <div class="row center-align" *ngIf="declined.length == 0">
                                        <i style="font-size: 100px;" class="material-icons blue-text">
                                            info
                                        </i>
                                        <p class="blue-text"> <strong>No Declined Requests.</strong></p>
                                    </div>

                                    <div *ngIf="declined.length > 0">
                                        <h6 class="center-align"><strong> Declined Requests</strong></h6>
                                        <br />
                                        <div class="row" *ngFor="let req of declined; let i=index">
                                            <div class="col s12">
                                                <button class="btn white right tooltipped" data-position="bottom"
                                                    data-tooltip="Open as a PDF" type="button"
                                                    style="margin-bottom: 25px;" (click)="openPdf('Declined',i)"
                                                    style="margin:5px;"><i
                                                        class="material-icons black-text">open_in_new</i></button>

                                                <button class="btn white right tooltipped" data-position="bottom"
                                                    data-tooltip="Download Application" type="button"
                                                    (click)="downloadPdf('Declined', i)" style="margin:5px;"><i
                                                        class="material-icons black-text">download</i></button>
                                            </div>

                                            <ul class="collection with-header">
                                                <li class="collection-header">
                                                    <h5>{{req.title | uppercase}}</h5>
                                                </li>
                                                <li class="collection-item">
                                                    <div><strong>Description: </strong><a
                                                            class="secondary-content">{{req.description}}</a>
                                                    </div>
                                                </li>
                                                <li class="collection-item">
                                                    <div><strong>Purpose: </strong><a
                                                            class="secondary-content">{{req.purpose?req.purpose:''}}</a>
                                                    </div>
                                                </li>
                                                <li class="collection-item">
                                                    <div> <strong>House Number: </strong><a
                                                            class="secondary-content">{{req.houseNumber}}</a>
                                                    </div>
                                                </li>
                                                <li class="collection-item">
                                                    <div><strong>Request Date: </strong><a class="secondary-content">
                                                            {{req.requestDate.toDate().toDateString()}}</a></div>
                                                </li>
                                                <li class="collection-item">
                                                    <div> <strong>Request Status: </strong><a
                                                            class="secondary-content">{{req.status}}</a>
                                                    </div>
                                                </li>

                                                <div *ngIf="req.previouseDecision">
                                                    <li class="collection-header" (click)="onShowMore(i, 'declined')"
                                                        style="cursor: pointer;">
                                                        <h6>History<i class="material-icons right">expand_more</i></h6>
                                                    </li>

                                                    <li class="collection-item" *ngIf="showMoreDeclined[i]">
                                                        <div> <strong>Request Declined On: </strong><a
                                                                class="secondary-content">{{req.previouseDecision.date.toDate().toDateString()}}</a>
                                                        </div>
                                                    </li>
                                                    <li class="collection-item" *ngIf="showMoreDeclined[i]">
                                                        <div> <strong>Reason: </strong><a
                                                                class="secondary-content">{{req.previouseDecision.reason}}</a>
                                                        </div>
                                                    </li>
                                                    <li class="collection-item" *ngIf="showMoreDeclined[i]">
                                                        <div> <strong>Appealed On: </strong><a
                                                                class="secondary-content">{{req.appeal.date.toDate().toDateString()}}</a>
                                                        </div>
                                                    </li>
                                                    <li class="collection-item" *ngIf="showMoreDeclined[i]">
                                                        <div> <strong>Appeal Statement: </strong><a
                                                                class="secondary-content">{{req.appeal.statement}}</a>
                                                        </div>
                                                    </li>
                                                    <li class="collection-item" *ngIf="showMoreDeclined[i]">
                                                        <div> <strong>Appeal Approved On: </strong><a
                                                                class="secondary-content">{{req.decision.date.toDate().toDateString()}}</a>
                                                        </div>
                                                    </li>
                                                </div>


                                                <li class="collection-header">
                                                    <h5>Details</h5>
                                                </li>
                                                <li class="collection-item">
                                                    <div> <strong>Reason: </strong><a
                                                            class="secondary-content">{{req.decision.reason}}</a>
                                                    </div>
                                                </li>
                                                <li class="collection-item">
                                                    <div> <strong>Date: </strong><a class="secondary-content">{{
                                                            isDate(req.decision.date) ? (req.decision.date | date:
                                                            'dd/MM/yyyy'):
                                                            req.decision.date.toDate().toDateString()| date:
                                                            'dd/MM/yyyy'}}</a>
                                                    </div>
                                                </li>

                                                <div *ngIf="req.appeal && !req.previouseDecision">
                                                    <li class="collection-header">
                                                        <h5>Appeal</h5>
                                                    </li>
                                                    <li class="collection-item">
                                                        <div> <strong>Statement: </strong><a
                                                                class="secondary-content">{{req.appeal.statement}}</a>
                                                        </div>
                                                    </li>
                                                    <li class="collection-item">
                                                        <div> <strong>Submitted On: </strong><a
                                                                class="secondary-content">{{req.appeal.date.toDate().toDateString()}}</a>
                                                        </div>
                                                    </li>

                                                    <li class="collection-item" *ngIf="!req.previouseDecision">
                                                        <div class="row col s12">
                                                            <div class="col s12 m6">
                                                                <a class="center col btn-flat s12 green lighten-4 waves-effect waves-light"
                                                                    (click)="acceptRequest(i, 'appeal')"
                                                                    style="cursor: pointer; margin-bottom: 15px;"><i
                                                                        class="material-icons black-text"
                                                                        style="margin-right: 7px;">check</i>
                                                                    <strong class="black-text">Accept
                                                                        Appeal</strong></a>
                                                            </div>

                                                            <div class="col s12 m6">
                                                                <a class="center btn-flat col s12 red waves-effect waves-light"
                                                                    (click)="declineRequest(i, 'appeal')"
                                                                    style="cursor: pointer;"><i
                                                                        class="center material-icons white-text"
                                                                        style="margin-right: 7px;">close</i><strong
                                                                        class="center white-text">Decline
                                                                        Appeal</strong></a>
                                                            </div>
                                                        </div>
                                                    </li>
                                                </div>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div id="compeleted_request">

                                <div class="row" *ngIf="completed">
                                    <div class="row center-align" *ngIf="completed.length == 0">
                                        <i class="large material-icons blue-text">info</i>
                                        <p class="blue-text"><strong>No Completed Requests.</strong></p>
                                    </div>

                                    <div *ngIf="completed.length > 0">
                                        <h6 class="center-align"><strong> completed Requests</strong></h6>
                                        <br />
                                        <div class="row" *ngFor="let req of completed; let i=index">
                                            <div class="col s12">
                                                <button class="btn white right tooltipped" data-position="bottom"
                                                    data-tooltip="Open as a PDF" type="button"
                                                    style="margin-bottom: 25px;" (click)="openPdf('Completed',i)"
                                                    style="margin:5px;"><i
                                                        class="material-icons black-text">open_in_new</i></button>

                                                <button class="btn white right tooltipped" data-position="bottom"
                                                    data-tooltip="Download Application" type="button"
                                                    (click)="downloadPdf('Completed', i)" style="margin:5px;"><i
                                                        class="material-icons black-text">download</i></button>
                                            </div>

                                            <ul class="collection with-header">
                                                <li class="collection-header">
                                                    <h5>{{req.title | uppercase}}</h5>
                                                </li>
                                                <li class="collection-item">
                                                    <div><strong>Description: </strong><a
                                                            class="secondary-content">{{req.description}}</a>
                                                    </div>
                                                </li>
                                                <li class="collection-item">
                                                    <div><strong>Purpose: </strong><a
                                                            class="secondary-content">{{req.purpose?
                                                            req.purpose:''}}</a>
                                                    </div>
                                                </li>
                                                <li class="collection-item">
                                                    <div> <strong>House Number: </strong><a
                                                            class="secondary-content">{{req.houseNumber}}</a>
                                                    </div>
                                                </li>
                                                <li class="collection-item">
                                                    <div><strong>Request Date: </strong><a class="secondary-content">
                                                            {{req.requestDate.toDate().toDateString()}}</a></div>
                                                </li>
                                                <li class="collection-item">
                                                    <div> <strong>Request Status: </strong><a
                                                            class="secondary-content">{{req.status}}</a>
                                                    </div>
                                                </li>
                                                <li class="collection-item">
                                                    <div> <strong>Completed On: </strong><a
                                                            class="secondary-content">{{req.completedOn.toDate().toDateString()}}</a>
                                                    </div>
                                                </li>

                                                <div *ngIf="req.feedback">
                                                    <li class="collection-item" *ngIf="req.feedback.rating">
                                                        <div> <strong>Rating: </strong><a class="secondary-content">
                                                                <span *ngFor="let rate of createRange()">
                                                                    <i style="display: inline-block"
                                                                        *ngIf="rate <= req.feedback.rating"
                                                                        class="material-icons green-text">
                                                                        star
                                                                    </i>
                                                                    <i style="display: inline-block"
                                                                        *ngIf="rate > req.feedback.rating"
                                                                        class="material-icons">
                                                                        star_outline
                                                                    </i>
                                                                </span>

                                                            </a>
                                                        </div>
                                                    </li>
                                                    <li class="collection-item" *ngIf="req.feedback.review != ''">
                                                        <div> <strong>Review/Feedback: </strong><a
                                                                class="secondary-content">{{req.feedback.review}}</a>
                                                        </div>
                                                    </li>
                                                </div>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row card-content">
                        <a class='col s12 btn waves-effect blue dropdown-trigger' data-target='dropdown1'
                            *ngIf="requests?.length>0"
                            [ngClass]="{'disabled': (pending.length== 0 && approved.length==0 && declined.length==0 && completed.length == 0)}"
                            style="margin-bottom: 15px;"><i class="material-icons right">download</i>Get Report</a>
                        <!-- Dropdown Structure -->
                        <ul id='dropdown1' class='col s12 dropdown-content'>
                            <li><a (click)="downloadReport('pending')" [ngClass]="{'disabled': pending.length == 0}"
                                    class="btn-flat">Pending Requests</a></li>
                            <li><a (click)="downloadReport('approved')" [ngClass]="{'disabled': approved.length == 0}"
                                    class="btn-flat">Approved Requests</a></li>
                            <li><a (click)="downloadReport('declined')" [ngClass]="{'disabled': declined.length == 0}"
                                    class="btn-flat">Declined Requests</a></li>
                            <li><a (click)="downloadReport('completed')" [ngClass]="{'disabled': completed.length == 0}"
                                    class="btn-flat">Completed Requests</a></li>
                            <li class="divider" tabindex="-1"></li>
                            <li *ngIf="houseNumber"><a (click)="downloadReport('house')" style="font-weight: bold;"
                                    class="btn-flat" [ngClass]="{'disabled': propertyRequests.length == 0}"
                                    class="green-text">House Requests</a>
                            </li>
                            <li><a (click)="downloadReport('all')" style="font-weight: bold;" class="btn-flat"
                                    [ngClass]="{'disabled': (pending.length== 0 && approved.length==0 && declined.length==0 && completed.length == 0)}"
                                    class="green-text">All Requests</a></li>
                        </ul>

                        <a class='col s12 btn waves-effect green dropdown-trigger' data-target='dropdown2'
                            *ngIf="requests?.length>0"
                            [ngClass]="{'disabled': (pending.length== 0 && approved.length==0 && declined.length==0 && completed.length == 0)}"
                            style="margin-bottom: 15px;"><i class="material-icons right">share</i>share Report</a>
                        <!-- Dropdown Structure -->
                        <ul id='dropdown2' class='col s12 dropdown-content'>
                            <li><a (click)="sendReport('pending')" [ngClass]="{'disabled': pending.length == 0}"
                                    class="btn-flat">Pending Requests</a></li>
                            <li><a (click)="sendReport('approved')" [ngClass]="{'disabled': approved.length == 0}"
                                    class="btn-flat">Approved Requests</a></li>
                            <li><a (click)="sendReport('declined')" [ngClass]="{'disabled': declined.length == 0}"
                                    class="btn-flat">Declined Requests</a></li>
                            <li><a (click)="sendReport('completed')" [ngClass]="{'disabled': completed.length == 0}"
                                    class="btn-flat">Completed Requests</a></li>
                            <li class="divider" tabindex="-1"></li>
                            <li *ngIf="houseNumber"><a (click)="sendReport('house')" style="font-weight: bold;"
                                    class="btn-flat" [ngClass]="{'disabled': propertyRequests.length == 0}"
                                    class="green-text">House Requests</a>
                            </li>
                            <li><a (click)="sendReport('all')" style="font-weight: bold;" class="btn-flat"
                                    [ngClass]="{'disabled': (pending.length== 0 && approved.length==0 && declined.length==0 && completed.length == 0)}"
                                    class="green-text">All Requests</a></li>
                        </ul>

                        <a [ngClass]="'col s12 btn waves-effect ' + themeColor" (click)="back()"><i
                                class="material-icons left">back</i>
                            Back To Home Page </a>
                    </div>
                </div>
            </div>

            <div id="declineModal" class="modal">
                <div class="modal-content">
                    <h4>Decline Request</h4>
                    <p>Please Enter the reason why this Request has been Declined.</p>
                    <br />
                    <div class="input-field col s12">
                        <textarea id="reason" [(ngModel)]="declineReason" class="materialize-textarea" rows="3"
                            style="height: 100px;"></textarea>
                        <label for="reason">Reason</label>
                    </div>

                    <div class="row">
                        <button class="col s12 btn waves-effect red" style="margin-bottom: 15px;" (click)="decline()">
                            <i class="material-icons right">thumb_down</i> Decline Request
                        </button>

                        <button class="col s12 btn-flat waves-effect blue-grey lighten-5" (click)="closeModal()"
                            style="margin-bottom: 15px;">
                            <i class="material-icons right">cancel</i> Close
                        </button>
                    </div>
                </div>
            </div>

            <div id="approveModal" class="modal">
                <div class="modal-content">
                    <h4>Approve Request</h4>
                    <p>Please assign an Employee to the case and the time to do the repair.</p>
                    <br />

                    <form [formGroup]="approveForm" (ngSubmit)="approve(approveForm.value)">
                        <div class="row card-panel white">
                            <div class="input-field col s12">
                                <i class="material-icons prefix green-text">person</i>
                                <select formControlName="employee">
                                    <option value="" disabled selected>Select Employee</option>
                                    <option *ngFor="let employee of employees" [value]='employee.displayName'>
                                        {{employee.givenName}}
                                        {{employee.lastName}}</option>
                                </select>
                                <label for="employee">Employee</label>
                            </div>

                            <div class="input-field col s12 m6">
                                <i class="material-icons prefix blue-text">event</i>
                                <!-- <input id="startDate" type="text" formControlName='startDate' class="validate"
                                    autocomplete="off" [textMask]="{mask: dateMask, guide: true}"
                                    placeholder="dd/mm/yyyy" /> -->
                                    <input id="startDate" type="text" [inputMask]="dateInputMask" formControlName='startDate' class="validate"
                                    autocomplete="off" 
                                    placeholder="dd/mm/yyyy" />
                                <!-- (keyup)="startDateChanged($event.target.value)"  -->
                                <label for="startDate">Start Date</label>
                                <div *ngIf="(startDate.dirty || startDate.touched) && startDate.invalid"
                                    style="position: absolute;">
                                    <small *ngIf="startDate.errors.required" class="red-text"> Start Date is required.
                                    </small>
                                    <small *ngIf="startDate.errors.invalidDate" class="red-text"> The Date is invalid
                                    </small>
                                </div>
                            </div>

                            <div class="input-field col s12 m6">
                                <i class="material-icons prefix purple-text">event</i>
                                <!-- <input id="endDate" type="text" formControlName='endDate' class="validate"
                                    autocomplete="off" [textMask]="{mask: dateMask, guide: true}"
                                    placeholder="dd/mm/yyyy" /> -->
                                    <input id="endDate" type="text" [inputMask]="dateInputMask" formControlName='endDate' class="validate"
                                    autocomplete="off" 
                                    placeholder="dd/mm/yyyy" />
                                <label for="endDate">End Date</label>
                                <div *ngIf="(endDate.dirty || endDate.touched) && endDate.invalid"
                                    style="position: absolute;">
                                    <small *ngIf="endDate.errors.required" class="red-text"> End Date is required.
                                    </small>
                                    <small *ngIf="endDate.errors.invalidDate" class="red-text"> The Date is invalid
                                    </small>
                                </div>
                            </div>

                            <div class="input-field col s12">
                                <i class="material-icons prefix orange-text">message</i>
                                <textarea id="message" rows="3" style="height: 100px;" formControlName="message"
                                    class="materialize-textarea" style="text-transform: capitalize;"></textarea>
                                <label for="message">Message</label>
                            </div>
                        </div>

                        <div class="row">
                            <button type="submit" class="col s12 btn waves-effect green" [disabled]="!approveForm.valid"
                                style="margin-bottom: 15px;">
                                <i class="material-icons right">thumb_up</i> Accept Request
                            </button>

                            <button type="button" class="col s12 btn-flat waves-effect blue-grey lighten-5"
                                (click)="closeModal()" style="margin-bottom: 15px;">
                                <i class="material-icons right">cancel</i> Close
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            <div id="completeModal" class="modal">
                <div class="modal-content">
                    <h4>Complete Request</h4>
                    <p>Please Enter the Date the Request was Completed On.</p>
                    <br />

                    <form [formGroup]="completeReqForm" (ngSubmit)="complete(completeReqForm.value)">
                        <div class="row card-panel white">

                            <div class="input-field col s12">
                                <i class="material-icons prefix orange-text">message</i>
                                <textarea id="comment" rows="3" style="height: 100px;" formControlName="comment"
                                    class="materialize-textarea" style="text-transform: capitalize;"></textarea>
                                <label for="comment">Comment</label>
                            </div>

                            <div class="input-field col s12">
                                <i class="material-icons prefix blue-text">event</i>
                                <!-- <input id="completedOn" type="text" formControlName='completedOn' class="validate"
                                    autocomplete="off" [textMask]="{mask: dateMask, guide: true}"
                                    placeholder="dd/mm/yyyy" /> -->
                                    <input id="completedOn" type="text" [inputMask]="dateInputMask" formControlName='completedOn' class="validate"
                                    autocomplete="off" 
                                    placeholder="dd/mm/yyyy" />
                                <label for="completedOn">Completed On</label>
                                <div *ngIf="(completedOn.dirty || completedOn.touched) && completedOn.invalid"
                                    style="position: absolute;">
                                    <small *ngIf="completedOn.errors.required" class="red-text"> Completed On Date is
                                        required.
                                    </small>
                                    <small *ngIf="completedOn.errors.invalidDate" class="red-text"> The Date is invalid
                                    </small>
                                </div>
                            </div>
                        </div>

                        <div class="row">
                            <button type="submit" class="col s12 btn waves-effect green"
                                [disabled]="!completeReqForm.valid" style="margin-bottom: 15px;">
                                <i class="material-icons right">task_alt</i> Mark Request as Completed
                            </button>

                            <button type="button" class="col s12 btn-flat waves-effect blue-grey lighten-5"
                                (click)="closeModal()" style="margin-bottom: 15px;">
                                <i class="material-icons right">cancel</i> Close
                            </button>
                        </div>
                    </form>
                </div>
            </div>

            <div id="overdueModal" class="modal">
                <div class="modal-content">
                    <h4 class="red-text">Overdue Request</h4>
                    <div class="center">
                        <i class="large material-icons red-text">report_problem</i>
                        <p class="red-text">You have {{overdueRequests?.length}} overdue Requests.</p>
                    </div>
                </div>
                <div class="modal-footer">
                    <a class="modal-close waves-effect waves-green btn-flat">Close</a>
                </div>
            </div>

            <div *ngIf="sendEmail" class="row col s12">
                <div class="col l1"></div>
                <div class="col s12 s10">
                    <div>
                        <app-send-email [emailList]="emailList" [fileList]="fileList"
                            (emailSent)="emailSentHandler($event)">
                        </app-send-email>
                    </div>
                </div>
            </div>
        </main>
    </div>
</div>