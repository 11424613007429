
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiKeyManagerService } from './apiKeyManager.Service';
import { CertificateModel } from 'src/app/models/certificateModel';

@Injectable({
    providedIn: 'root'
  })
  export class CertificateService {
  
    constructor(private httpClient: HttpClient, private keyManager: ApiKeyManagerService) { }
  
    url = environment.firstNation.apiUrl + 'api/Certificate/';
  
    saveCertificate(model: CertificateModel): Observable<any> {
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
      return this.httpClient.post<CertificateModel>(this.url + 'SaveCertificate/', JSON.stringify(model), { headers });
    }
  
    updateCertificate(model: CertificateModel): Observable<any> {
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
      return this.httpClient.post<CertificateModel>(this.url + 'EditCertificate/', JSON.stringify(model), { headers });
    }
  
  
    deleteCertificate(model: CertificateModel): Observable<any> {
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
      return this.httpClient.post<CertificateModel>(this.url + 'DeleteCertificate/', JSON.stringify(model), { headers });
    }
  
  
    getCertificateById(id: number): Observable<CertificateModel[]> {
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
      return this.httpClient.get<CertificateModel[]>(this.url + 'GetCertificateByID/' + id, { headers });
    }
  
    getCertificateByMemberId(id: number): Observable<CertificateModel[]> {
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
      return this.httpClient.get<CertificateModel[]>(this.url + 'GetCertificateByMemberId/' + id, { headers });
    }
  
  
  
  
  }
  