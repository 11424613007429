<app-firebase-loading *ngIf="loading"></app-firebase-loading>

<div id="contactModal" class="modal">
    <form [formGroup]="contactForm" (ngSubmit)="send(contactForm.value)">

        <div class="modal-content">
            <h5 class="center"><strong>Contact client</strong></h5>

            <div class="card white hoverable col s10 m6 offset-s1" style="cursor: pointer;"
                [ngClass]="{'selectedCard': contactByEmail}" (click)="select('email')"
                [ngClass]="{'disabledDiv blue-grey lighten-4': !contactDetail?.email}">
                <i *ngIf="contactByEmail" class="material-icons right green-text" style="margin-top: 10px;">
                    check_circle
                </i>
                <div class="card-content">
                    <div class="row col s12 center">
                        <i class="material-icons green-text">email</i>
                        <p><strong>Email</strong></p>
                        <p *ngIf="contactDetail?.email"><strong>{{contactDetail.email}}</strong></p>
                        <small *ngIf="!contactDetail?.email" class="red-text"><strong>No Email Provided</strong></small>
                    </div>
                </div>
            </div>

            <div class="card hoverable col s10 m6 offset-s1" style="cursor: pointer;"
                [ngClass]="{'selectedCard': contactBySms}"
                [ngClass]="{'disabledDiv blue-grey lighten-4': !contactDetail?.phoneNo}" (click)="select('sms')">
                <i *ngIf="contactBySms" class="material-icons right green-text" style="margin-top: 10px;">
                    check_circle
                </i>
                <div class="card-content">
                    <div class="row col s12 center">
                        <i class="material-icons green-text">perm_phone_msg</i>
                        <p><strong>Text Message</strong></p>
                        <p *ngIf="contactDetail?.phoneNo"><strong>{{contactDetail.phoneNo}}</strong></p>
                        <small *ngIf="!contactDetail?.phoneNo" class="red-text"><strong>No Phone
                                Provided</strong></small>
                    </div>
                </div>
            </div>

            <div class="input-field col s12 l12">
                <input id="subject" type="text" formControlName='subject' required class="validate"
                    autocomplete="off" />
                <label for="subject">Subject<span class="red-text">*</span></label>
                <div *ngIf="(subject.dirty || subject.touched) && subject.invalid">
                    <small *ngIf="subject.errors.required" class="red-text"> The Subject is required. </small>
                </div>
            </div>

            <div class="input-field col s12 l12">
                <textarea id="body" type="text" formControlName='body' autocomplete="off"
                    class="validate materialize-textarea" style="height: 75px;"> </textarea>
                <label for="body">Body<span class="red-text">*</span></label>
                <div *ngIf="(body.dirty || body.touched) && body.invalid">
                    <small *ngIf="body.errors.required" class="red-text"> The Body Is Required.
                    </small>
                </div>
            </div>

            <div *ngIf="adminInfo">
                <p class="col s12">
                    <label class="right">
                        <input type="checkbox" class="filled-in" [checked]="includeAdminInfo"
                            (click)="showAdminInfo()" />
                        <span>Include Admin Info</span>
                    </label>
                </p>
                <div *ngIf="includeAdminInfo">
                    <p class="col m5 s10">
                        <strong>Email: </strong> {{adminInfo.Email_Address}}
                        <em class="red-text" *ngIf="contactByEmail && !adminInfo.Email_Address">Update Profile</em>
                    </p>
                    <p class="col m5 s10">
                        <strong>Cell: </strong>{{adminInfo.Cell}}
                        <em class="red-text" *ngIf="contactBySms && !adminInfo.Cell">Update Profile</em>
                    </p>
                    <button type="button" class="btn-floating btn-small waves-light blue tooltipped" data-position="top"
                        data-tooltip="Update My Profile" (click)="update()"><i
                            class="material-icons">edit</i>Update</button>
                </div>
            </div>

            <div class="section"></div>

            <div class="row">
                <div class="input-field col s12 center">
                    <button type="submit" [disabled]="!contactForm.valid || !(contactByEmail || contactBySms)"
                        class='modal-close col s10 offset-s1 btn waves-effect green' style="margin-bottom:15px;">
                        <i class="material-icons right">send</i> Send
                    </button>

                    <a class="modal-close col s10 offset-s1 btn waves-effect waves-red red" (click)="return(null)"
                        style="margin-bottom:15px;">cancel</a>
                </div>
            </div>
        </div>
    </form>
</div>

<div id="multipleContactModel" class="modal">
    <div class="modal-content card-content">
        <h5 *ngIf="contactDetail.email" class="center"><strong>Send Email</strong></h5>
        <h5 *ngIf="contactDetail.sms" class="center"><strong>Send Text Message</strong></h5>

        <form [formGroup]="contactForm" (ngSubmit)="sendMultiple(contactForm.value)">
            <p><strong>Recipients</strong><span class="red-text"> ({{emailList.length}})</span></p>
            <div class="chips email-chips"></div>

            <div class="input-field col s12 l12">
                <input id="subject" type="text" formControlName='subject' required class="validate"
                    autocomplete="off" />
                <label for="subject">Subject<span class="red-text">*</span></label>
                <div *ngIf="(subject.dirty || subject.touched) && subject.invalid">
                    <small *ngIf="subject.errors.required" class="red-text"> The Subject is required. </small>
                </div>
            </div>

            <div class="input-field col s12 l12">
                <textarea id="body" type="text" formControlName='body' autocomplete="off"
                    class="validate materialize-textarea" style="height: 75px;"> </textarea>
                <label for="body">Body<span class="red-text">*</span></label>
                <div *ngIf="(body.dirty || body.touched) && body.invalid">
                    <small *ngIf="body.errors.required" class="red-text"> The Body Is Required.
                    </small>
                </div>
            </div>

            <div *ngIf="adminInfo">
                <p class="col s12">
                    <label class="right">
                        <input type="checkbox" class="filled-in" [checked]="includeAdminInfo"
                            (click)="showAdminInfo()" />
                        <span>Include Admin Info</span>
                    </label>
                </p>
                <div *ngIf="includeAdminInfo">
                    <p class="col s10" *ngIf="contactDetail.email">
                        <strong>Email: </strong> {{adminInfo.Email_Address}}
                        <em class="red-text" *ngIf="contactDetail.email && !adminInfo.Email_Address">Update Profile</em>
                    </p>
                    <p class="col s10" *ngIf="contactDetail.sms">
                        <strong>Cell: </strong>{{adminInfo.Cell}}
                        <em class="red-text" *ngIf="contactDetail.sms && !adminInfo.Cell">Update Profile</em>
                    </p>
                    <button type="button" class="btn-floating btn-small waves-light blue tooltipped" data-position="top"
                        data-tooltip="Update My Profile" (click)="update()"><i
                            class="material-icons">edit</i>Update</button>
                </div>
            </div>

            <div class="section"></div>

            <div class="row">
                <div class="input-field col s12 center">
                    <button type="submit" [disabled]="!contactForm.valid"
                        class='modal-close col s10 offset-s1 btn waves-effect green' style="margin-bottom:15px;">
                        <i class="material-icons right">send</i> Send Emails</button>

                    <a class="modal-close col s10 offset-s1 btn waves-effect waves-red red" (click)="return(null)"
                        style="margin-bottom:15px;">cancel</a>
                </div>
            </div>
        </form>
    </div>
</div>

<div id="settingModal" class="modal" *ngIf="adminInfo">
    <div class="modal-content">
        <h4>Update Contact Info</h4>
        <h6 class="red-text" *ngIf="contactByEmail && !adminInfo.Email_Address">Upate you Email Address to use this
            feature</h6>
        <h6 class="red-text" *ngIf="contactBySms && !adminInfo.Cell">Update you Cell to use this feature</h6>
        <br>

        <form [formGroup]="adminContactInfo" (ngSubmit)="updateInfo(adminContactInfo.value)">
            <div class="row">
                <div class="input-field col s12 m8">
                    <i class="material-icons prefix blue-text">smartphone</i>
                    <!-- <input id="Cell" type="text" formControlName='Cell' class="validate" autocomplete="off"
                        [textMask]="{mask: phoneNumberMask, guide: true}" /> -->
                    <input id="Cell" type="text" [inputMask]="phoneInputMask" formControlName='Cell' class="validate"
                        autocomplete="off" />
                    <label for="Cell">Cell</label>
                    <div *ngIf="(Cell.dirty || Cell.touched) && Cell.invalid">
                        <small *ngIf="Cell.errors.required" class="red-text"> Cell Number is required. </small>
                    </div>
                </div>

                <label class="col s12 m4">
                    <input name="filter" type="checkbox" formControlName='show_cell' />
                    <span>show cell on Message </span>
                </label>

                <div class="input-field col s8 m6">
                    <i class="material-icons prefix blue-text">phone</i>
                    <!-- <input id="phone" type="text" formControlName='phone' class="validate" autocomplete="off"
                        [textMask]="{mask: phoneNumberMask, guide: true}" /> -->
                    <input id="phone" type="text" [inputMask]="phoneInputMask" formControlName='phone' class="validate"
                        autocomplete="off" />
                    <label for="phone">Phone</label>
                    <div *ngIf="(phone.dirty || phone.touched) && phone.invalid">
                        <small *ngIf="phone.errors.required" class="red-text"> Phone Number is required. </small>
                    </div>
                </div>

                <div class="input-field col s4 m2">
                    <input id="ph_extension" type="text" formControlName='ph_extension' class="validate"
                        autocomplete="off" />
                    <label for="ph_extension">Phone Ext</label>
                    <div *ngIf="(ph_extension.dirty || ph_extension.touched) && ph_extension.invalid">
                        <small *ngIf="ph_extension.errors.required" class="red-text"> Phone Ext is required. </small>
                    </div>
                </div>

                <label class="col s12 m4">
                    <input name="filter" type="checkbox" formControlName='show_office' />
                    <span>show phone on Message </span>
                </label>

                <div class="input-field col s12 m8">
                    <i class="material-icons prefix blue-text">email</i>
                    <input id="Email_Address" type="text" formControlName='Email_Address' class="validate"
                        autocomplete="off" />
                    <label for="Email_Address">Email Address</label>
                    <div *ngIf="(Email_Address.dirty || Email_Address.touched) && Email_Address.invalid">
                        <small *ngIf="Email_Address.errors.email" class="red-text">Invalid Email Address</small>
                        <small *ngIf="Email_Address.errors.required" class="red-text">Email Address is required</small>
                    </div>
                </div>

                <label class="col s12 m4">
                    <input name="filter" type="checkbox" formControlName='show_email' />
                    <span>show email on Message </span>
                </label>

                <div class="input-field col s12 m8">
                    <i class="material-icons prefix blue-text">title</i>
                    <input id="Title" type="text" formControlName='Title' class="validate" autocomplete="off"
                        style="text-transform: capitalize;" />
                    <label for="Title">Title</label>
                    <div *ngIf="(Title.dirty || Title.touched) && Title.invalid">
                        <small *ngIf="Title.errors.required" class="red-text">Title is required</small>
                    </div>
                </div>

                <label class="col s12 m4">
                    <input name="filter" type="checkbox" formControlName='show_title' />
                    <span>show title on Message </span>
                </label>

                <div class="row">
                    <div class="input-field col s12 center">
                        <button type="submit" [disabled]="!adminContactInfo.valid"
                            class='modal-close col s10 offset-s1 btn waves-effect green' style="margin-bottom: 15px;">
                            <i class="material-icons right">edit</i> Update Contact Info
                        </button>

                        <button type="button" class='modal-close col s10 offset-s1 btn waves-effect red'
                            (click)="cancelUpdateInfo()">
                            <i class="material-icons right">close</i> Cancel
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>