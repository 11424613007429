import { Component, OnInit } from '@angular/core';
import { AreaOfInterestService } from 'src/app/services/firebase/area-of-interest.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { toast } from 'materialize-css';
import { Router } from '@angular/router';

@Component({
  selector: 'app-my-areas-of-interest',
  templateUrl: './my-areas-of-interest.component.html',
  styleUrls: ['./my-areas-of-interest.component.css']
})
export class MyAreasOfInterestComponent implements OnInit {

  areasList: any[];
  checkBoxList: any[];
  userID
  myInterestModel: any;

  constructor(private areaService: AreaOfInterestService, private authservice: AuthService, private router: Router) { 
    if(this.authservice.getGlobalUser()) {
      this.userID = this.authservice.getGlobalUser().uid;
    } else {
      this.router.navigate(['/member/home']);
    }
  }

  ngOnInit() {
    this.areaService.getAreasList().valueChanges().subscribe(areas => {
      if(areas) {
        if(areas.length > 0) {
          this.areasList = areas;
          this.addCheckboxes();

          this.areaService.getMyAreasList(this.userID).valueChanges().subscribe(aList => {
            if(aList) {
              if(aList.length > 0) {
                this.myInterestModel = aList[0];
                this.setCheckboxes();
              }              
            }
          });
        }
      }
    });    
  }

  private addCheckboxes() {
    this.checkBoxList = [];
    this.areasList.forEach(a => {
      const option = { id: a.id, name: a.name ,isSelected: false };
      this.checkBoxList.push(option);
    });    
  }

  private setCheckboxes() {
    this.myInterestModel.myInterests.forEach(element => {
      this.checkBoxList.forEach(a => {
        if(a.id === element.id) {
          a.isSelected = true;
        }
      }); 
    });   
  }

  itemCheckedChange(id: any, value: any) {
   if(this.myInterestModel) {
     if(value) {       
      this.editMyInterests();
       this.areaService.incrementMembersCount(id);
     } else {
      this.editMyInterests();
      this.areaService.decrementMembersCount(id);
     }
   }
  }

  saveMyInterests(){
    const myInterests = {
      id: '',
      uid: this.userID,
      myInterests: this.checkBoxList.filter(i => i.isSelected === true),
    };

    myInterests.myInterests.forEach(element => {
      this.areaService.incrementMembersCount(element.id);
    });

    this.areaService.saveMyAreas(myInterests).then(s => {      
      toast({ html: 'Areas Of Interest Successfully Saved!', classes: 'green' });
    });    
  }

  editMyInterests() {
    this.myInterestModel.myInterests = this.checkBoxList.filter(i => i.isSelected === true);
    this.areaService.updateMyAreas(this.myInterestModel).then(s => {      
    });    
  }
}
