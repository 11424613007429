<div class="col s12">
    <div class="body-h">
        <div class="header-setting b-m-2">
            <div class="col l12 m12 s12">
              <h4 class="">IA SURVEY APPLICATIONS</h4>
      
              <p class="bold-100 sub-title-section3">This is the menu that the general public will have
                access to on your mobile app</p>
            </div>
          </div>

        <main class="StickyContent white ">

            <app-firebase-loading *ngIf="loading"></app-firebase-loading>

            <div class="row col s12" *ngIf="!loading && !showDetails">
                <div class="row ">
                    <a [routerLink]="['/admin/applications/lrrcn']"
                        [ngClass]="'col s12 m5 offset-m2 l3 btn right waves-effect ' + themeColor" type="button"
                        style="margin-bottom: 15px;"><i class="material-icons right">list_alt</i>Take A New
                        Survey</a>
                </div>
                <div class="row col s12" *ngIf="applications && applications.length > 0">
                    <div class="col s12 m12 l12">

                        <div class="row  white" *ngIf='applications'>
                            <div class="row center-align">
                                <h5><strong> IA SURVEY Applications</strong></h5>
                                <p>Total: {{count}}</p>
                            </div>

                            <table class="hover highlight responsive-table">
                                <thead>
                                    <tr>
                                        <th>Applicant ID</th>
                                        <th>Given Name</th>
                                        <th>Last Name</th>
                                        <th>Application Submitted On</th>
                                        <th>Approved By</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    <tr *ngFor="let app of applications; let i=index" style="cursor: pointer;">
                                        <td class="blue-text" (click)="onShowDetails(i)">{{app.applicantID}}</td>
                                        <td class="blue-text" (click)="onShowDetails(i)">
                                            {{app.demographic.firstName}}</td>
                                        <td class="blue-text" (click)="onShowDetails(i)">
                                            {{app.demographic.lastName}}</td>
                                        <td class="blue-text" (click)="onShowDetails(i)">{{app.applicantDate ?
                                            (app.applicantDate.toDate().toDateString() | date: 'dd/MMM/yyyy') :
                                            "Client Not
                                            Signed"}}</td>
                                        <td class="blue-text" (click)="onShowDetails(i)" *ngIf="app.verified">
                                            {{app.workerFirstName}} {{app.workerLastName}}</td>
                                        <td (click)="adminSign(i)" *ngIf="!app.verified"> <a
                                                class="waves-effect waves-light btn">Approve</a></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>

                    <div id="modal1" class="modal">
                        <div class="modal-content">
                            <h4>Approve Application</h4>

                            <div *ngIf="surveyToApprove" class="col s12 row left-align card-content"
                                style="padding-top: 3%;">
                                <div class="col s12 m6 l4">
                                    <b class="black-text"> First Name: </b>
                                    <strong> {{surveyToApprove.demographic.firstName}} </strong>
                                </div>

                                <div class="col s12 m6 l4">
                                    <b class="black-text"> Last Name: </b>
                                    <strong> {{surveyToApprove.demographic.lastName}} </strong>
                                </div>
                            </div>

                            <div *ngIf="surveyToApprove && surveyToApprove.workerSignature === ''">
                                <div class="row">
                                    <div class="section"></div>
                                </div>

                                <div *ngIf="adminSignature">
                                    <div class="row">
                                        <div [ngClass]="'card ' + themeColor">
                                            <div class="row card-panel grey lighten-4 left-align">
                                                <div class="row">
                                                    <h5 class=" blue-text text-darken-2">Admin please sign here</h5>
                                                    <div class="section"></div>
                                                    <canvas></canvas>
                                                </div>
                                            </div>
                                        </div>

                                        <a class="at-btn btn-flat center red lighten-1 col s12 white-text"
                                            (click)="clearSignatureOfApplicant()"><i
                                                class="material-icons left">loop</i> Clear
                                            Signature</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        
                        <div class="col s12 left-align">
                            <button type="submit" class="waves-effect waves-light green darken-3 btn-small col s12"
                                style="margin-top: 15px; margin-bottom: 25px;" (click)="verifySurvey()">
                                <i class="material-icons right">person_add</i><span>Verify Survey</span> </button>

                            <a [ngClass]="'col s12 btn waves-effect ' + themeColor" (click)="cancel()"
                                style="margin-bottom: 25px;"><i class="material-icons left">arrow_back_ios</i> Back
                                To Previous
                                Page</a>
                        </div>

                    </div>
                </div>

                <div *ngIf="!applications || applications.length === 0" class="row card-panel red lighten-1">
                    <h6 class="white-text"> <strong>
                            No Survey Found In The DataBase.</strong>
                    </h6>
                </div>
                <a [ngClass]="'col s12 btn waves-effect ' + themeColor" (click)="backClicked()"
                    style="margin-bottom: 25px;"><i class="material-icons left">arrow_back_ios</i> Back To Previous
                    Page</a>
            </div>

            <div class="row col s12" *ngIf="showDetails">
                <app-lrrcn-applications-detail (onBackToList)="backToList($event)" [surveyId]="surveyId">
                </app-lrrcn-applications-detail>
            </div>
        </main>

    </div>
</div>