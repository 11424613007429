export class BandMemberModel {
  applicantID: number;
  GivenName  = '';
  LastName  = '';
  DOB: Date;
  MailAddress = '';
  city = '';
  province = '';
  PostalCode = '';
  Telephone = '';
  cell_phone_num = '';
  Email = '';
  band_name = '';
  profileImgLink = '';
  isCandidate = false;
  role = '';
}
