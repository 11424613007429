import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { EmploymentModel } from 'src/app/models/employmentModel';

@Injectable({
  providedIn: 'root'
})
export class EmploymentService {

  model_parent_id:any;
  
  constructor(private db: AngularFirestore) { }

  contentListRef: AngularFirestoreCollection<any>;
  contentRef: AngularFirestoreDocument<any>;

// Manage Posts ---------------------------------------------------------------------------------------
  saveEmployment(model: EmploymentModel) {
    const newContent = {
      id: this.db.createId(),
      client_id: model.client_id,
      Name: model.Name,
      employer: model.employer,
      City: model.City,
      Prov: model.Prov,
      description: model.description,
      start_date: model.start_date,
      end_date: model.end_date,
      iscurrent: model.iscurrent,
      reason: model.reason,
    };

    return this.db.collection('Employment').doc(newContent.id).set(newContent);
  }

  updateEmployment(model: any) {
    const newContent = {
      id: model.id,
      client_id: model.client_id,
      Name: model.Name,
      employer: model.employer,
      City: model.City,
      Prov: model.Prov,
      description: model.description,
      start_date: model.start_date,
      end_date: model.end_date,
      iscurrent: model.iscurrent,
      reason: model.reason,
    };
    return this.db.collection('Employment').doc(model.id).update(newContent);
  }

  getEmploymentByMemberId(client_id){
    return this.db.collection('Employment', ref => ref.where('client_id', '==', client_id));
  }

  getEmploymentById(id: string): AngularFirestoreDocument<EmploymentModel> {
    return this.db.doc('Employment/' + id);
  }

  DeleteEmployment(model_id: any) {
    return this.db.collection('Employment').doc(model_id).delete();
  }

}