import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-stats-main',
  templateUrl: './stats-main.component.html',
  styleUrls: ['./stats-main.component.css']
})
export class StatsMainComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
