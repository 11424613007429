// import { convertActionBinding } from '@angular/compiler/src/compiler_util/expression_converter';
import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { catchError, first, map, take, timestamp } from 'rxjs/operators';
import { ForwardedMessageModel, MessagesModel } from 'src/app/models/Messages.Model';
import { UnreadCountModel } from 'src/app/models/UnreadCountModel';

@Injectable({
  providedIn: 'root'
})
export class MessagingService {

  messageListRef: AngularFirestoreCollection<any>;
  messageRef: AngularFirestoreDocument<any>;
  unreadCountListRef: AngularFirestoreCollection<any>;

  constructor(private db: AngularFirestore) { }

  createMessage(model: MessagesModel) {
    const newContent = {
      id: this.db.createId(),
      timeStamp: model.timeStamp,
      message: model.message,
      fromId: model.fromId,
      toId: model.toId,
      conversationId: model.conversationId,
      attachment: model.attachment,
      edited: model.edited,
      likes: model.likes
    }
    return this.db.collection('Messages').doc(newContent.id).set(newContent);
  }

  createForwardedMessage(model: ForwardedMessageModel) {

    const newContent = {
      id: this.db.createId(),
      timeStamp: model.timeStamp,
      message: model.message,
      fromId: model.fromId,
      forwardedFrom: model.forwardedFrom,
      toId: model.toId,
      conversationId: model.conversationId,
      likes: model.likes,
      edited: model.edited,
      forwardedMessage: model.forwardedMessage,
      attachment: model.attachment
    }
    return this.db.collection('Messages').doc(newContent.id).set(newContent);
  }


  getMessageByChatId(id: string): AngularFirestoreCollection<any[]> {
    this.messageListRef = this.db.collection('Messages', ref => ref.where('conversationId', '==', id));
    return this.messageListRef;
  }

  createUnreadCount(model: UnreadCountModel) {
    const newContent = {
      id: this.db.createId(),
      ConverId: model.ConverId,
      userId: model.userId,
      unreadCount: model.unreadCount
    }
    this.db.collection('Unread-Count').doc(newContent.id).set(newContent);
    return newContent;
  }

  getUserCountById(id: any) {
    return this.db.doc('Unread-Count/' + id)
  }

  getUnreadCountByUserId(id: any) {
    return this.messageListRef = this.db.collection('Unread-Count', ref => ref.where('userId', '==', id));
  }

  getUnreadCountByConverId(id: any) {
    return this.messageListRef = this.db.collection('Unread-Count', ref => ref.where('ConverId', '==', id));
  }

  // update unread count whenever a new message is sent
  updateUnreadCount(userId, conversationId, increment) {
    this.unreadCountListRef = this.db.collection('Unread-Count', ref => ref.where('userId', '==', userId).where('ConverId', '==', conversationId));
    const unreadCollection = this.db.collection('Unread-Count');
    this.unreadCountListRef.get().toPromise()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          let unreadCountDoc = doc.data();
          if (increment) {
            unreadCountDoc.unreadCount += 1;
          } else {
            unreadCountDoc.unreadCount = 0;
          }
          unreadCollection.doc(doc.id).update(unreadCountDoc);
        });
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });
    // .pipe(map(querySnapshot => {
    //   console.log("here ++1", querySnapshot);
    //   querySnapshot.forEach(function (doc) {
    //     let unreadCountDoc = doc.data();
    //     console.log(unreadCountDoc);
    //     unreadCountDoc.unreadCount += 1;
    //     return this.db.collection('Unread-Count').doc(doc.id).update(unreadCountDoc);
    //   }),
    //     catchError(error => {
    //       console.log("Error getting documents: ", error);
    //       return []; // You need to determine what to do here, return an empty array, or possibly re-throw the error with throwError(error)
    //     });
    // }
    // ));
    // .toPromise()
    // this.unreadCountListRef.snapshotChanges().pipe(map(changes=>{
    //     return changes.map(a=>{
    //         const data = a.payload.doc.data();
    //         const id = a.payload.doc.id;
    //         return {id, ...data}
    //     })
    //   }), take(1)).subscribe(items=>{
    //       items.forEach(unreadCount=>{
    //         unreadCount.unreadCount += 1;
    //         return this.db.collection('Unread-Count').doc(unreadCount.id).update(unreadCount);
    //       })
    //    });
  }

  // getUnreadMessages(conversationId: any, timeStamp: any) : AngularFirestoreCollection<any[]> {
  //   this.messageListRef = this.db.collection('Messages', ref => ref.where('conversationId', '==', conversationId).where('timeStamp', '>=', timestamp));
  //   return this.messageListRef;
  // }

  deleteMessage(id: string) {
    return this.db.collection('Messages').doc(id).delete();
  }

  deleteUnreadCountByConverId(converId: any, userId: any) {
    this.unreadCountListRef = this.db.collection('Unread-Count', ref => ref.where('ConverId', '==', converId).where('userId', '==', userId));
    const unreadCollection = this.db.collection('Unread-Count');
    this.unreadCountListRef.get().toPromise()
      .then(function (querySnapshot) {
        querySnapshot.forEach(function (doc) {
          let unreadCountDoc = doc.data();
          console.log(doc.id);
          unreadCollection.doc(doc.id).delete();
        });
      })
      .catch(function (error) {
        console.log("Error getting documents: ", error);
      });
  }

  deleteUnreadCount(id: string) {
    return this.db.collection('Unread-Count').doc(id).delete();
  }

  updateMessageLikes(model: MessagesModel) {
    return this.db.collection('Messages').doc(model.id).update({ likes: model.likes });
  }

  editMessage(model: MessagesModel) {
    return this.db.collection('Messages').doc(model.id).update({ message: model.message, edited: true });
  }

}