import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';
@Pipe({ name: 'noSanitize' })
export class NoSanitizePipe implements PipeTransform {
   constructor(private DOMsanitizer: DomSanitizer) {
   }

   transform(html: string): SafeHtml {
	return this.DOMsanitizer.bypassSecurityTrustHtml(html);
 }
}
