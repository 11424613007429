import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { toast } from 'materialize-css';
import { Location, DatePipe } from '@angular/common';
import { CommunityService } from 'src/app/services/api/community.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { MemberService } from 'src/app/services/api/member.service';
import { MediaService } from 'src/app/services/api/media.service';
import { Attachment } from 'src/app/models/emailModel';
import { RegistrationRequestService } from 'src/app/services/api/registration-request.service';
import { SCISApplicationModel } from 'src/app/models/SCISApplicationModel';
import { ScisApplicationService } from 'src/app/services/firebase/scis-application.service';
import SignaturePad from 'signature_pad';

const pdfMake = require('pdfmake/build/pdfmake.js');
const pdfFonts = require('pdfmake-unicode/dist/pdfmake-unicode.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-scis-application',
  templateUrl: './scis-application.component.html',
  styleUrls: ['./scis-application.component.css']
})
export class ScisApplicationComponent implements OnInit {

  provinceOfUse = environment.firstNation.province;
  provinces = [{ name: 'Alberta', code: 'AB' },
  { name: 'British Columbia', code: 'BC' },
  { name: 'Manitoba', code: 'MB' },
  { name: 'New Brunswick', code: 'NB' },
  { name: 'Newfoundland and Labrador', code: 'NL' },
  { name: 'Nova Scotia', code: 'NS' },
  { name: 'Ontario', code: 'ON' },
  { name: 'Prince Edward Island', code: 'PE' },
  { name: 'Quebec', code: 'QC' },
  { name: 'Saskatchewan', code: 'SK' }
  ];
  state = [{ name: 'Alabama', code: 'AL' },
  { name: 'Alaska', code: 'AK' },
  { name: 'Arkansas', code: 'AR' },
  { name: 'Arizona', code: 'AZ' },
  { name: 'California', code: 'CA' },
  { name: 'Colorado', code: 'CO' },
  { name: 'Connecticut', code: 'CT' },
  { name: 'Delaware', code: 'DE' },
  { name: 'Florida', code: 'FL' },
  { name: 'Georgia', code: 'GA' },
  { name: 'Hawaii', code: 'HI' },
  { name: 'Iowa', code: 'IA' },
  { name: 'Idaho', code: 'ID' },
  { name: 'Illinois', code: 'IL' },
  { name: 'Indiana', code: 'IN' },
  { name: 'Kansas', code: 'KS' },
  { name: 'Kentucky', code: 'KY' },
  { name: 'Louisiana', code: 'LA' },
  { name: 'Massachusetts', code: 'MA' },
  { name: 'Maryland', code: 'MD' },
  { name: 'Maine', code: 'ME' },
  { name: 'Michigan', code: 'MI' },
  { name: 'Minnesita', code: 'MN' },
  { name: 'Missouri', code: 'MO' },
  { name: 'Mississippi', code: 'MS' },
  { name: 'North Carolina', code: 'NC' },
  { name: 'North Dakota', code: 'ND' },
  { name: 'Nebraska', code: 'NE' },
  { name: 'New Hampshire', code: 'NH' },
  { name: 'New Jersey', code: 'NJ' },
  { name: 'New Mexico', code: 'NM' },
  { name: 'Nevada', code: 'NV' },
  { name: 'New York', code: 'NY' },
  { name: 'Ohio', code: 'OH' },
  { name: 'Oklahoma', code: 'OK' },
  { name: 'Oregno', code: 'OR' },
  { name: 'Pennsylvania', code: 'PA' },
  { name: 'Rhode Island', code: 'RI' },
  { name: 'South Carolina', code: 'SC' },
  { name: 'South Dakota', code: 'SD' },
  { name: 'Tennessee', code: 'TN' },
  { name: 'Texas', code: 'TX' },
  { name: 'Utah', code: 'UT' },
  { name: 'Virginia', code: 'VA' },
  { name: 'Vermont', code: 'VT' },
  { name: 'Washington', code: 'WA' },
  { name: 'West Virginia', code: 'WV' },
  { name: 'Wisconsin', code: 'WI' },
  { name: 'Wyoming', code: 'WY' },
  ];

  public phoneNumberMask = ['(', /[0-9]/, /[0-9]/, /[0-9]/, ')',
    ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  public extMask = [/[0-9]/, /[0-9]/, /[0-9]/];
  public dateMask = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, '/', /[0-1]/, /[0-9]/, '/', /[0-3]/, /[0-9]/];
  public SINMask = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  public registrationNoMask = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  public postalCodeMask = [/[A-Za-z]/, /[0-9]/, /[A-Za-z]/, ' ', /[0-9]/, /[A-Za-z]/, /[0-9]/];
  public dateformat = /^\d{4}[\/\-](0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])$/;
  public treatyNoMask = [/[0-9]/, /[0-9]/, /[0-9]/];
  RelationshipList = ["Grandson", "Granddaughter", "Niece", "Nephew", "Cousin", "Brother", "Sister", "Relative", 'Other'];
  maternalGPsList = ['Grandfather', 'Grandmother', 'Great-Grandfather(1)', 'Great-Grandmother(1)', 'Great-Grandfather(2)', 'Great-Grandmother(2)'];
  paternalGPsList = ['Grandfather', 'Grandmother', 'Great-Grandfather(1)', 'Great-Grandmother(1)', 'Great-Grandfather(2)', 'Great-Grandmother(2)']

  step = 0;
  loading = false;
  country = 'CA';
  currentUser: any;
  applicantID: number;
  personalInfoForm: UntypedFormGroup;
  firstNationInfoForm: UntypedFormGroup;
  documentsForm: UntypedFormGroup;
  adoptionInfoForm: UntypedFormGroup;
  familyInfoForm: UntypedFormGroup;
  photoSignatureForm: UntypedFormGroup;
  declarationForm: UntypedFormGroup;
  IRAForm: UntypedFormGroup;

  treatyNo = '';
  bandList: any[];
  bands: any[];
  bandUse: any[];
  showMailingAddress = false;
  motherRegistred: boolean;
  uploadNameDoc: boolean;
  uploadSupportingDocs: boolean;
  isAdopted = false;
  addMaternalGP = false;
  addPaternalGP = false;
  addOtherMember = false;
  SCISRequested = false;
  IRA_assisted = false;

  proofOfBirth: any = null;
  nameLinkingDocs: any[] = [];
  supportingDocs: any[] = [];

  defaultPhoto = 'assets/img/profile.jpg';
  photo: any;
  signature: any;

  signed = false;
  canvas: any;
  signaturePad: SignaturePad;

  model: SCISApplicationModel;
  isRegistred = {
    mother: false,
    father: null,
    maternal_grandparents: false,
    paternal_grandparents: false,
  };

  expandSection = {
    A: false,
    B: false,
    C: false,
    D: false,
    E: false,
  };
  summary1 = true;
  summary2 = false;

  fileName = 'SCIS Application'
  reportFile: File;
  fileLink = '';
  emailList: any[] = [];
  fileList: any[] = [];
  sendEmail = false;
  emailSent = false;

  emailSentHandler(valueEmitted) {
    if (valueEmitted) this.emailSent = true;
    this.sendEmail = false;
  }

  constructor(private fb: UntypedFormBuilder,
    private location: Location,
    private communityService: CommunityService,
    private sqlRegistrationRequest: RegistrationRequestService,
    private authService: AuthService,
    private memberService: MemberService,
    private mediaService: MediaService,
    private scisService: ScisApplicationService) { }

  ngOnInit(): void {
    this.currentUser = this.authService.getGlobalUser();
    if (this.currentUser) this.applicantID = this.currentUser.applicantID;
    //this.applicantID = 2652;

    if (this.applicantID) {
      this.memberService.getMember(this.applicantID).subscribe(member => {

        if (member) {
          this.currentUser = member[0];

          if (this.currentUser) {
            this.communityService.getBands().subscribe(bands => {

              if (bands) {
                this.bands = bands;
                this.getBandListFromProvinceCode(this.currentUser.province);

                if (this.currentUser.TreatyNo) {
                  this.treatyNo = this.currentUser.TreatyNo;
                  //this.firstNationInfoForm.get('bandNumber').patchValue(this.treatyNo);
                } else {
                  this.sqlRegistrationRequest.getTreatyNumber().subscribe(t => {
                    if (t) {
                      this.treatyNo = t;
                      // this.onBandNoChange(this.treatyNo, this.bandName);
                    }
                  });
                }
                var dob = '';
                if (this.currentUser && this.currentUser.DOB) {
                  var array = this.currentUser.DOB.split("-");
                  // var applicantDob = `${array[2].substring(0, 2)}/${array[1]}/${array[0]}`;
                  dob = `${array[0]}/${array[1]}/${array[2].substring(0, 2)}`
                }

                this.personalInfoForm = this.fb.group({
                  familyName: [this.currentUser ? this.currentUser.LastName : '', [Validators.required]],
                  givenName: [this.currentUser ? this.currentUser.GivenName : '', [Validators.required]],
                  birth_familyName: [''],
                  aliasName: [''],
                  gender: [this.currentUser ? this.currentUser.Sex : '', [Validators.required]],
                  dob: [dob, [Validators.required, this.isValidDate]],
                  number: ['', [Validators.required]],
                  street: ['', [Validators.required]],
                  apartment: [''],
                  POBox: [''],
                  city: [this.currentUser ? this.currentUser.city : '', [Validators.required]],
                  province_state: [this.currentUser ? this.currentUser.province : '', [Validators.required]],
                  postalCode: [this.currentUser ? this.currentUser.PostalCode : '', [Validators.required]],
                  telephone: ['', [Validators.required]],
                  telephone2: [''],
                  email: [this.currentUser ? this.currentUser.Email : '', [Validators.required, Validators.email]],
                  sameMailingAddress: ['', [Validators.required]],
                  mailing_number: [''],
                  mailing_street: [''],
                  mailing_apartment: [''],
                  mailing_POBox: [''],
                  mailing_city: [''],
                  mailing_province_state: [this.currentUser ? this.currentUser.province : ''],
                  mailing_postalCode: [''],
                });

                this.personalInfoForm.get('sameMailingAddress').valueChanges.subscribe(value => {
                  if (!value) {
                    this.personalInfoForm.get('mailing_number').setValidators(Validators.required);
                    this.personalInfoForm.get('mailing_street').setValidators(Validators.required);

                    this.personalInfoForm.get('mailing_city').setValidators(Validators.required);
                    this.personalInfoForm.get('mailing_province_state').setValidators(Validators.required);
                    this.personalInfoForm.get('mailing_postalCode').setValidators(Validators.required);
                  } else {
                    this.personalInfoForm.get('mailing_number').clearValidators();
                    this.personalInfoForm.get('mailing_street').clearValidators();

                    this.personalInfoForm.get('mailing_city').clearValidators();
                    this.personalInfoForm.get('mailing_province_state').clearValidators();
                    this.personalInfoForm.get('mailing_postalCode').clearValidators();
                  }
                  this.personalInfoForm.get('mailing_number').updateValueAndValidity();
                  this.personalInfoForm.get('mailing_street').updateValueAndValidity();

                  this.personalInfoForm.get('mailing_city').updateValueAndValidity();
                  this.personalInfoForm.get('mailing_province_state').updateValueAndValidity();
                  this.personalInfoForm.get('mailing_postalCode').updateValueAndValidity();
                });

                this.firstNationInfoForm = this.fb.group({
                  bandNumber: [this.treatyNo, [Validators.required]],
                  bandName: [this.currentUser ? this.currentUser.Band : '', [Validators.required]],
                });

                this.firstNationInfoForm.get('bandNumber').patchValue(this.treatyNo);
                this.onBandNoChange(this.treatyNo, this.bandName);

                this.documentsForm = this.fb.group({
                  proofOfBirth: this.fb.group({
                    registrationNo: [Number(this.treatyNo), [Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern('^[0-9]*$')]],
                    province_state: [this.currentUser ? this.currentUser.province : '', [Validators.required]],
                    //includeOriginalDoc: [],
                    //document: this.fb.array([])
                  }),
                  nameLinkingDoc: this.fb.group({
                    uploadDoc: [''],
                    documentName: [''],
                    documentType: [''],
                    //documents: this.fb.array([])
                  }),
                  supportingIdentityDocs: this.fb.group({
                    uploadSupportingDocs: [''],
                    documentName: [''],
                    documentType: [''],
                    documentNumber: [''],
                    expiryDate: ['', [Validators.pattern(this.dateformat)]],
                    //documents: this.fb.array([]),
                  })
                });

                this.documentsForm.get('nameLinkingDoc.uploadDoc').valueChanges.subscribe(value => {
                  if (!value) {
                    this.documentsForm.get('nameLinkingDoc.documentName').clearValidators();
                    this.documentsForm.get('nameLinkingDoc.documentType').clearValidators();
                  } else {
                    this.documentsForm.get('nameLinkingDoc.documentName').setValidators([Validators.required]);
                    this.documentsForm.get('nameLinkingDoc.documentType').setValidators([Validators.required]);
                  }
                  this.documentsForm.get('nameLinkingDoc.documentName').updateValueAndValidity();
                  this.documentsForm.get('nameLinkingDoc.documentType').updateValueAndValidity();
                });

                this.documentsForm.get('supportingIdentityDocs.uploadSupportingDocs').valueChanges.subscribe(value => {
                  if (!value) {
                    this.documentsForm.get('supportingIdentityDocs.documentName').clearValidators();
                    this.documentsForm.get('supportingIdentityDocs.documentType').clearValidators();
                    this.documentsForm.get('supportingIdentityDocs.documentNumber').clearValidators();
                  } else {
                    this.documentsForm.get('supportingIdentityDocs.documentName').setValidators([Validators.required]);
                    this.documentsForm.get('supportingIdentityDocs.documentType').setValidators([Validators.required]);
                    this.documentsForm.get('supportingIdentityDocs.documentNumber').setValidators([Validators.required]);
                  }
                  this.documentsForm.get('supportingIdentityDocs.documentName').updateValueAndValidity();
                  this.documentsForm.get('supportingIdentityDocs.documentType').updateValueAndValidity();
                  this.documentsForm.get('supportingIdentityDocs.documentNumber').updateValueAndValidity();
                });

                this.adoptionInfoForm = this.fb.group({
                  adopted: ['', [Validators.required]],
                  ISNEntitlement: this.fb.group({
                    adoptiveMother: [false],
                    adoptiveFather: [false],
                    birthMother: [false],
                    birthFather: [false]
                  }),
                  adoptiveMother: this.fb.group({
                    familyName: [''],
                    givenName: [''],
                    dob: ['', [this.isValidDate]],
                  }),

                  adoptiveFather: this.fb.group({
                    familyName: [''],
                    givenName: [''],
                    dob: ['', [this.isValidDate]]
                  }),

                  birthMother: this.fb.group({
                    familyName: [''],
                    givenName: [''],
                  }),

                  birthFather: this.fb.group({
                    familyName: [''],
                    givenName: [''],
                  })
                });

                // this.adoptionInfoForm.get('ISNEntitlement').setValidators(this.isEntitlementValid());
                this.familyInfoForm = this.fb.group({
                  father: this.fb.group({
                    familyName: [''],
                    birth_familyName: [''],
                    givenName: [''],
                    dob: ['', [this.isValidDate]],
                    bandNumber: [''],
                    bandName: [''],
                    adopted: [''],
                    parentStated: [''],
                    registred: [''],
                    registrationNumber: ['']
                  }),

                  mother: this.fb.group({
                    familyName: [''],
                    birth_familyName: [''],
                    givenName: [''],
                    dob: ['', [this.isValidDate]],
                    bandNumber: [''],
                    bandName: [''],
                    adopted: [''],
                    parentStated: [''],
                    registred: [''],
                    registrationNumber: ['']
                  }),

                  maternal_grandparents: this.fb.group({
                    type: [''],
                    familyName: [''],
                    birth_familyName: [''],
                    givenName: [''],
                    dob: ['', [this.isValidDate]],
                    bandNumber: [''],
                    bandName: [''],
                    adopted: [''],
                    registred: [''],
                    registrationNumber: [''],
                  }),
                  maternalGrandparents: this.fb.array([]),

                  paternal_grandparents: this.fb.group({
                    type: [''],
                    familyName: [''],
                    birth_familyName: [''],
                    givenName: [''],
                    dob: ['', [this.isValidDate]],
                    bandNumber: [''],
                    bandName: [''],
                    adopted: [''],
                    registred: [''],
                    registrationNumber: [''],
                    //grandparents: this.fb.array([])
                  }),
                  paternalGrandparents: this.fb.array([]),

                  otherMembers: this.fb.group({
                    familyName: [''],
                    givenName: [''],
                    relationship: [''],
                  }),
                  otherFamilyMembers: this.fb.array([])

                });

                this.father.get('registred').valueChanges.subscribe(value => {
                  if (value) {
                    this.father.get('registrationNumber').setValidators([Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern('^[0-9]*$')]);
                  } else {
                    this.father.get('registrationNumber').clearValidators();
                  }
                  this.father.get('registrationNumber').updateValueAndValidity();
                });

                this.mother.get('registred').valueChanges.subscribe(value => {
                  if (value) {
                    this.mother.get('registrationNumber').setValidators([Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern('^[0-9]*$')]);
                  } else {
                    this.mother.get('registrationNumber').clearValidators();
                  }
                  this.mother.get('registrationNumber').updateValueAndValidity();
                });

                this.maternal_grandparents.get('registred').valueChanges.subscribe(value => {
                  if (value) {
                    this.maternal_grandparents.get('registrationNumber').setValidators([Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern('^[0-9]*$')]);
                  } else {
                    this.maternal_grandparents.get('registrationNumber').clearValidators();
                  }
                  this.maternal_grandparents.get('registrationNumber').updateValueAndValidity();
                });

                this.paternal_grandparents.get('registred').valueChanges.subscribe(value => {
                  if (value) {
                    this.paternal_grandparents.get('registrationNumber').setValidators([Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern('^[0-9]*$')]);
                  } else {
                    this.paternal_grandparents.get('registrationNumber').clearValidators();
                  }
                  this.paternal_grandparents.get('registrationNumber').updateValueAndValidity();
                });

                const today = new Date();
                const date_pipe = new DatePipe('en-US');
                //const todayDate = date_pipe.transform(today, 'fullDate');
                const todayFullDate = date_pipe.transform(today, 'yyyy/MM/dd');

                this.photoSignatureForm = this.fb.group({
                  SCIS_requested: ['', [Validators.required]],
                  photo: [''],
                  photoBy: [''],
                  photoAddress: [''],
                  photoDate: ['', [this.isValidDate]],
                  signature: [''],
                });

                this.declarationForm = this.fb.group({
                  applicantName: ['Mihret Tamene', [Validators.required]],
                  signature: [''],
                  signatureDate: [todayFullDate, [Validators.required]],
                });

                this.IRAForm = this.fb.group({
                  IRA_assisted: ['', [Validators.required]],
                  IRA_name: [''],
                  bandNo_initiatorCode: [''],
                  // IRA_signature: [''],
                  // IRA_signatureDate: [todayFullDate],
                });

                this.IRAForm.get('IRA_assisted').valueChanges.subscribe(value => {
                  if (value) {
                    this.IRAForm.get('IRA_name').setValidators([Validators.required]);
                  } else {
                    this.IRAForm.get('IRA_name').clearValidators();
                  }
                  this.IRAForm.get('IRA_name').updateValueAndValidity();
                })

              }
            });
          }
        }
      });
    }

    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
      $('.tooltipped').tooltip();
    }, 25);

    this.model = new SCISApplicationModel();
  }

  getBase64ImageFromURL(url) {
    return new Promise((resolve, reject) => {
      var img = new Image();
      img.setAttribute("crossOrigin", "anonymous");
      img.onload = () => {
        var canvas = document.createElement("canvas");
        canvas.width = img.width;
        canvas.height = img.height;
        var ctx = canvas.getContext("2d");
        ctx.drawImage(img, 0, 0);
        var dataURL = canvas.toDataURL("image/png");
        resolve(dataURL);
      };
      img.onerror = error => {
        reject(error);
      };
      img.src = url;
    });
  }

  get familyName() { return this.personalInfoForm.get('familyName'); }
  get givenName() { return this.personalInfoForm.get('givenName'); }
  get email() { return this.personalInfoForm.get('email'); }
  //get province_state() { return this.personalInfoForm.get('province_state'); }
  get gender() { return this.personalInfoForm.get('gender'); }
  get dob() { return this.personalInfoForm.get('dob'); }
  get number() { return this.personalInfoForm.get('number'); }
  get street() { return this.personalInfoForm.get('street'); }
  get apartment() { return this.personalInfoForm.get('apartment'); }
  get POBox() { return this.personalInfoForm.get('POBox'); }
  get city() { return this.personalInfoForm.get('city'); }
  get postalCode() { return this.personalInfoForm.get('postalCode'); }
  get telephone() { return this.personalInfoForm.get('telephone'); }
  get mailing_number() { return this.personalInfoForm.get('mailing_number'); }
  get mailing_street() { return this.personalInfoForm.get('mailing_street'); }
  get mailing_apartment() { return this.personalInfoForm.get('mailing_apartment'); }
  get mailing_POBox() { return this.personalInfoForm.get('mailing_POBox'); }
  get mailing_city() { return this.personalInfoForm.get('mailing_city'); }
  //get mailing_province_state() { return this.personalInfoForm.get('mailing_province_state'); }
  get mailing_state() { return this.personalInfoForm.get('mailing_state'); }
  get mailing_postalCode() { return this.personalInfoForm.get('mailing_postalCode'); }

  get bandNumber() { return this.firstNationInfoForm.get('bandNumber'); }
  get bandName() { return this.firstNationInfoForm.get('bandName'); }

  get registrationNo() { return this.documentsForm.get('proofOfBirth.registrationNo'); }
  get issuance_province_state() { return this.documentsForm.get('proofOfBirth.province_state'); }
  get nameLinking_documentName() { return this.documentsForm.get('nameLinkingDoc.documentName'); }
  get nameLinking_documentType() { return this.documentsForm.get('nameLinkingDoc.documentType'); }
  get supporting_documentName() { return this.documentsForm.get('supportingIdentityDocs.documentName'); }
  get supporting_documentType() { return this.documentsForm.get('supportingIdentityDocs.documentType'); }
  get supporting_documentExpiryDate() { return this.documentsForm.get('supportingIdentityDocs.expiryDate'); }
  get supporting_documentNumber() { return this.documentsForm.get('supportingIdentityDocs.documentNumber'); }

  get adoptiveMother() { return this.adoptionInfoForm.get('adoptiveMother'); }
  get adoptiveFather() { return this.adoptionInfoForm.get('adoptiveFather'); }
  get father() { return this.familyInfoForm.get('father'); }
  get mother() { return this.familyInfoForm.get('mother'); }

  get maternal_grandparents() { return this.familyInfoForm.get('maternal_grandparents'); }
  get maternalGrandparents() { return this.familyInfoForm.get('maternalGrandparents') as UntypedFormArray; }
  get paternal_grandparents() { return this.familyInfoForm.get('paternal_grandparents'); }
  get paternalGrandparents() { return this.familyInfoForm.get('paternalGrandparents') as UntypedFormArray }

  get otherMembers() { return this.familyInfoForm.get('otherMembers'); }
  get otherFamilyMembers() { return this.familyInfoForm.get('otherFamilyMembers') as UntypedFormArray; }
  get photoDate() { return this.photoSignatureForm.get('photoDate'); }
  get applicantName() { return this.declarationForm.get('applicantName'); }
  get signatureDate() { return this.declarationForm.get('signatureDate') }
  get IRA_name() { return this.IRAForm.get('IRA_name'); }
  // get proofOfBirth() { return this.documentsForm.get('proofOfBirth.documnets') as FormArray;}

  //Custom Validators
  // isEntitlementValid(): ValidatorFn{
  //   return (group: FormGroup):ValidationErrors => {
  //     const adoptiveMother =  group.controls['adoptiveMother'];
  //     const adoptiveFather =  group.controls['adoptiveMother'];
  //     const birthMother =  group.controls['adoptiveMother'];
  //     const birthFather =  group.controls['adoptiveMother'];
  //     if(!adoptiveMother && !adoptiveFather && !birthMother && !birthFather){
  //       return { 'invalid': true }
  //     }else{
  //       return null;
  //     }      
  //   }
  //  //return null;
  // }

  isValidDate(control: UntypedFormControl) {
    let date = control.value;
    const dateformat = /^\d{4}[\/\-](0?[1-9]|1[012])[\/\-](0?[1-9]|[12][0-9]|3[01])$/;
    if (date) {
      // if (!date.match(dateformat) && date.length > 10) {
      //   const datePipe = new DatePipe('en-US');
      //   date = datePipe.transform(date, 'dd/MM/yyyy');
      // }
      // else 
      if (date.match(dateformat)) {

        let dateArray = date.split('/');
        let day = dateArray[2];
        let month = dateArray[1] - 1;
        let year = dateArray[0];
        date = new Date(year, month, day);
        const today = new Date();
        if (Number(year) <= Number(today.getFullYear() - 100)) {
          return { 'invalidDate': true }
        } else if (date.valueOf() >= today.valueOf()) {
          return { 'invalidDate': true }
        } else {
          return null;
        }
      } else {
        return { 'invalidDate': true }
      }
    }
    return null;
  }

  getBandListFromProvinceCode(provinceCode) {
    if (this.bands) {
      const bandInProvince = this.bands.filter(band => provinceCode === this.getProvinceCodeFromAddress(band.Address));
      const bandList = [];
      bandInProvince.forEach(b => {
        bandList.push(b.Band);
      });
      this.bandList = bandList;
    } else {
      //this.province_state.patchValue('');
      this.bandName.patchValue('');
    }
  }

  getProvinceCodeFromAddress(address: any) {
    let addr = address.substring(address.length - 11);
    addr = addr.substring(0, 2);
    return addr;
  }

  onBandNoChange(e, control) {
    const treaty = e.toString().slice(0, 3)
    const treatyArray = treaty.split('');
    if (treatyArray[0] != '_' && treatyArray[1] != '_' && treatyArray[2] != '_') {
      if (this.bands) {
        this.bandUse = this.bands.filter(b => b.TreatyNo == treaty);
        if (this.bandUse && this.bandUse.length > 0) {
          control.patchValue(this.bandUse[0].Band);
        } else {
          control.patchValue('');
        }
      }

      // this.bandName.patchValue(this.bandUse);
      // if(this.bandUse && this.bandUse.length < 0){
      //   if(this.bandUse[0].Address){
      //     var provinceCode = this.getProvinceCodeFromAddress(this.bandUse[0].Address);
      //     this.province.patchValue(provinceCode);
      //     this.getBandListFromProvinceCode(provinceCode);
      //   }
      //   // this.bandName = this.bandUse[0].Band;
      //   this.bandName.patchValue(this.bandUse[0].Band);
      // }
    }
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();

    }, 25);
  }

  treatNoChange(e, control) {
    const treaty = e.toString().slice(0, 3)
    const treatyArray = treaty.split('');
    if (treatyArray[0] != '_' && treatyArray[1] != '_' && treatyArray[2] != '_') {
      if (this.bands) {
        this.bandUse = this.bands.filter(b => b.TreatyNo == treaty);
        if (this.bandUse && this.bandUse.length > 0) {
          if (this.bandUse[0].Address) {
            var provinceCode = this.getProvinceCodeFromAddress(this.bandUse[0].Address);
            this.documentsForm.get('proofOfBirth.province_state').patchValue('AB');
            control.patchValue('AB');
            control.updateValueAndValidity();
          }
        }
      }
    }
  }

  onSameMailingAddressYes() {
    this.showMailingAddress = false;
    // const homeAddress = this.address.value;
    // this.mailing_address.patchValue(homeAddress);
  }
  onSameMailingAddressNo() {
    //this.mailing_address.reset();
    this.showMailingAddress = true;
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }

  uploadNameDocYes() {
    this.uploadNameDoc = true;
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }
  uploadNameDocNo() {
    this.uploadNameDoc = false;
    this.documentsForm.get('nameLinkingDoc.documentName').clearValidators();
    this.documentsForm.get('nameLinkingDoc.documentType').clearValidators();
    this.nameLinkingDocs = [];
  }
  uploadSupportingDocYes() {
    this.uploadSupportingDocs = true;
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);

  }
  uploadSupportingDocNo() {
    this.uploadSupportingDocs = false;
    this.documentsForm.get('supportingIdentityDocs.documentName').clearValidators();
    this.documentsForm.get('supportingIdentityDocs.documentType').clearValidators();
    this.documentsForm.get('supportingIdentityDocs.documentNumber').clearValidators();
    this.supportingDocs = [];
  }

  adoptedYes() {
    this.isAdopted = true;
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);

  }
  adoptedNo() {
    this.isAdopted = false;
  }

  addGrandParentYes(type) {
    if (type == 'maternal') {
      this.maternal_grandparents.reset();
      this.maternal_grandparents.get('familyName').setValidators([Validators.required]);
      this.maternal_grandparents.get('givenName').setValidators([Validators.required]);
      this.maternal_grandparents.get('type').setValidators([Validators.required]);
      this.maternal_grandparents.get('givenName').updateValueAndValidity();
      this.maternal_grandparents.get('familyName').updateValueAndValidity();
      this.maternal_grandparents.get('type').updateValueAndValidity();
      this.maternalGPsList.sort();
      this.addMaternalGP = true;
    } else if (type == 'paternal') {
      this.paternal_grandparents.reset();
      this.paternal_grandparents.get('familyName').setValidators([Validators.required]);
      this.paternal_grandparents.get('givenName').setValidators([Validators.required]);
      this.paternal_grandparents.get('type').setValidators([Validators.required]);
      this.paternal_grandparents.get('familyName').updateValueAndValidity();
      this.paternal_grandparents.get('givenName').updateValueAndValidity();
      this.paternal_grandparents.get('type').updateValueAndValidity();
      this.paternalGPsList.sort();
      this.addPaternalGP = true;
    }
    setTimeout(() => {
      $('select').formSelect();
      $('.collapsible').collapsible();
      M.updateTextFields();
    }, 25);
  }
  addGrandParentNo(type) {
    if (type == 'maternal') {
      this.addMaternalGP = false;
      this.maternal_grandparents.get('familyName').clearValidators();
      this.maternal_grandparents.get('givenName').clearValidators();
      this.maternal_grandparents.get('type').clearValidators();
      this.maternal_grandparents.get('givenName').updateValueAndValidity();
      this.maternal_grandparents.get('familyName').updateValueAndValidity();
      this.maternal_grandparents.get('type').updateValueAndValidity();
    } else if (type == 'paternal') {
      this.addPaternalGP = false;
      this.paternal_grandparents.get('familyName').clearValidators();
      this.paternal_grandparents.get('givenName').clearValidators();
      this.paternal_grandparents.get('type').clearValidators();

      this.paternal_grandparents.get('familyName').updateValueAndValidity();
      this.paternal_grandparents.get('givenName').updateValueAndValidity();
      this.paternal_grandparents.get('type').updateValueAndValidity();
    }
  }

  addOtherMembersYes() {
    this.otherMembers.reset();
    this.otherMembers.get('familyName').setValidators([Validators.required]);
    this.otherMembers.get('givenName').setValidators([Validators.required]);
    this.otherMembers.get('relationship').setValidators([Validators.required]);
    this.otherMembers.get('familyName').updateValueAndValidity();
    this.otherMembers.get('givenName').updateValueAndValidity();
    this.otherMembers.get('relationship').updateValueAndValidity();
    this.addOtherMember = true;

    setTimeout(() => {
      $('select').formSelect();
      $('.collapsible').collapsible();
      M.updateTextFields();
    }, 25);
  }
  addOtherMembersNo() {
    this.addOtherMember = false;
    this.otherMembers.get('familyName').clearValidators();
    this.otherMembers.get('givenName').clearValidators();
    this.otherMembers.get('relationship').clearValidators();
    this.otherMembers.get('familyName').updateValueAndValidity();
    this.otherMembers.get('givenName').updateValueAndValidity();
    this.otherMembers.get('relationship').updateValueAndValidity();
  }

  SCISRequestedYes() {
    this.SCISRequested = true;
  }
  SCISRequestedNo() {
    this.SCISRequested = false;
  }
  IRA_assistedYes() {
    this.IRA_assisted = true;
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);

  }
  IRA_assistedNo() {
    this.IRA_assisted = false;
  }

  famMember(values) {
    return this.fb.group({
      familyName: [values.familyName],
      givenName: [values.givenName],
      relationship: [values.relationship],
    });
  }

  attachFiles(type) {
    if (type == 'proofOfBirth') {
      $('#proofOfBirth').click()
    }
    else if (type == 'nameLinkingDoc') {
      $('#nameLinkingDoc').click();
    }
    else if (type == 'supportingDoc') {
      $('#supportingDoc').click();
    } else if (type == 'photo') {
      $('#photo').click();
    }
  }

  uploadProofOfBirth(files: FileList, values) {
    const file = files[0];
    this.loading = true;
    if (this.validateInput(file.name) == 'image') {
      this.mediaService.UploadBinImage(file).subscribe(upload => {
        if (upload) {
          let doc = {
            file_url: upload.imgLink,
            file_type: 'Image',
            file_name: file.name,
            registrationNo: values.registrationNo,
            province_state: values.province_state,
          }
          this.proofOfBirth = doc;
        }
        this.loading = false;
        setTimeout(() => {
          $('.tooltipped').tooltip();
        }, 25);
      });
    } else if (this.validateInput(file.name) == 'pdf') {
      this.loading = true;
      this.mediaService.uploadPDF(file).subscribe(upload => {
        if (upload) {
          let doc = {
            file_url: upload.imgLink,
            file_type: 'PDF',
            file_name: file.name,
            registrationNo: values.registrationNo,
            province_state: values.province_state,
          }
          this.proofOfBirth = doc;
        }
        this.loading = false;
        setTimeout(() => {
          $('.tooltipped').tooltip();
        }, 25);
      });
    }
  }

  uploadNameLinkingDoc(files: FileList, values) {
    const file = files[0];
    this.loading = true;
    if (this.validateInput(file.name) == 'image') {
      this.mediaService.UploadBinImage(file).subscribe(upload => {
        if (upload) {
          let doc = {
            file_url: upload.imgLink,
            file_type: 'Image',
            file_name: file.name,
            documentName: values.documentName,
            documentType: values.documentType,
          }
          this.nameLinkingDocs.push(doc);
          this.documentsForm.get('nameLinkingDoc').reset();
        }
        this.loading = false;
        setTimeout(() => {
          $('.tooltipped').tooltip();
        }, 25);
      });
    } else if (this.validateInput(file.name) == 'pdf') {
      this.loading = true;
      this.mediaService.uploadPDF(file).subscribe(upload => {
        if (upload) {
          let doc = {
            file_url: upload.imgLink,
            //file_url: 'link',
            file_type: 'PDF',
            file_name: file.name,
            documentName: values.documentName,
            documentType: values.documentType,
          }
          this.nameLinkingDocs.push(doc);
          this.documentsForm.get('nameLinkingDoc').reset();
        }
        this.loading = false;
        setTimeout(() => {
          $('.tooltipped').tooltip();
        }, 25);
      });
    }

  }

  uploadSupportingDoc(files: FileList, values) {

    const file = files[0];
    this.loading = true;
    if (this.validateInput(file.name) == 'image') {
      this.mediaService.UploadBinImage(file).subscribe(upload => {
        if (upload) {

          let doc = {
            file_url: upload.imgLink,
            file_type: 'Image',
            file_name: file.name,
            documentName: values.documentName,
            documentType: values.documentType,
            documentNumber: values.documentNumber,
            expiryDate: values.expiryDate
          }
          this.supportingDocs.push(doc);
          this.documentsForm.get('supportingIdentityDocs').reset();
        }
        this.loading = false;
        setTimeout(() => {
          $('.tooltipped').tooltip();
        }, 25);
      });
    } else if (this.validateInput(file.name) == 'pdf') {
      this.loading = true;
      this.mediaService.uploadPDF(file).subscribe(upload => {
        if (upload) {
          let doc = {
            file_url: upload.imgLink,
            //file_url: 'link',
            file_type: 'PDF',
            file_name: file.name,
            documentName: values.documentName,
            documentType: values.documentType,
            documentNumber: values.documentNumber,
            expiryDate: values.expiryDate
          }
          this.supportingDocs.push(doc);
          this.documentsForm.get('supportingIdentityDocs').reset();
        }
        this.loading = false;
        setTimeout(() => {
          $('.tooltipped').tooltip();
        }, 25);
      });
    }
  }

  uploadPhoto(files) {
    const file = files[0];
    this.loading = true;
    if (this.validateInput(file.name) == 'image') {
      this.mediaService.UploadBinImage(file).subscribe(upload => {
        if (upload) {
          let photo = {
            file_url: upload.imgLink,
            file_type: 'Image',
            file_name: file.name,
          }
          this.photo = photo;
        }
        this.loading = false;
        setTimeout(() => {
          $('.tooltipped').tooltip();
        }, 25);
      });
    }
  }

  newDoc(values) {
    return this.fb.group({
      name: [values.name, [Validators.required]],
      type: [values.type],
      link: [''],
    });
  }

  closeAddNameDoc() {
    this.uploadNameDoc = false;
    this.documentsForm.get('nameLinkingDoc.documentName').clearValidators();
    this.documentsForm.get('nameLinkingDoc.documentType').clearValidators();
    this.documentsForm.get('nameLinkingDoc.uploadDoc').patchValue('');
  }

  closeAddSupportingDoc() {
    this.uploadSupportingDocs = false;
    this.documentsForm.get('supportingIdentityDocs.documentName').clearValidators();
    this.documentsForm.get('supportingIdentityDocs.documentType').clearValidators();
    this.documentsForm.get('supportingIdentityDocs.documentNumber').clearValidators();
    this.documentsForm.get('supportingIdentityDocs.uploadSupportingDocs').patchValue('')
  }

  private validateInput(name: any) {
    const ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() === 'png') {
      return 'image';
    } else if (ext.toLowerCase() === 'jpg') {
      return 'image';
    } else if (ext.toLowerCase() === 'jpeg') {
      return 'image';
    } else if (ext.toLowerCase() === 'mp4' || ext.toLowerCase() === 'mkv') {
      return 'video';
    } else if (ext.toLowerCase() === 'pdf') {
      return 'pdf';
    } else {
      return false;
    }
  }

  newDocument(values) {
    return this.fb.group({
      name: [''],
      type: [''],
      link: []
    })
  }

  removeFile(type, index) {
    if (type == 'proofOfBirth') {
      this.mediaService.DeleteBinImage(this.proofOfBirth.file_url).subscribe(x => {
        if (x) this.proofOfBirth = null;
      });

    } else if (type == 'nameLinkingDoc') {
      const doc = this.nameLinkingDocs[index];
      this.mediaService.DeleteBinImage(doc.file_url).subscribe(x => {
        if (x) {
          this.nameLinkingDocs.splice(index);
          if (this.nameLinkingDocs.length == 0) {
            this.uploadNameDoc = false;
          }
        }
      });

    } else if (type == 'supportingDoc') {
      const doc = this.supportingDocs[index];
      this.mediaService.DeleteBinImage(doc.file_url).subscribe(x => {
        if (x) {
          this.supportingDocs.splice(index);
          if (this.supportingDocs.length == 0) {
            this.uploadSupportingDocs = false;
          }
        }
      });
    } else if (type == 'photo') {
      this.mediaService.DeleteBinImage(this.photo.file_url).subscribe(x => {
        if (x) {
          this.photo = null;
        }
      });
    }
  }

  showPhoto() {
    $('.modal').modal();
    $('#photoModal').modal('open');
  }

  registred(person) {
    this.isRegistred[person] = !this.isRegistred[person];
  }

  expand(currentsection) {
    this.expandSection[currentsection] = !this.expandSection[currentsection];
    for (const section in this.expandSection) {
      if (section != currentsection) {
        this.expandSection[section] = false;
      }
    }
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }

  grandParent(values) {
    return this.fb.group({
      type: [values.type],
      familyName: [values.familyName],
      birth_familyName: [values.birth_familyName],
      givenName: [values.givenName],
      dob: [values.dob],
      bandNumber: [values.bandNumber],
      bandName: [values.bandName],
      adopted: [values.adopted],
      registred: [values.registred],
      registrationNumber: [values.registrationNumber],
    });
  }

  addGrandParent(type, values) {
    if (type == 'maternal') {
      const grandParents = this.maternalGrandparents;
      grandParents.push(this.grandParent(values));
      this.addMaternalGP = false;
      const index = this.maternalGPsList.indexOf(values.type);
      this.maternalGPsList.splice(index, 1);
    } else if (type == 'paternal') {
      const grandParents = this.paternalGrandparents;
      grandParents.push(this.grandParent(values));
      this.addPaternalGP = false;
      const index = this.paternalGPsList.indexOf(values.type);
      this.paternalGPsList.splice(index, 1);
    }
  }

  onDeleteGP(type, GPType, index) {
    if (type == 'maternal') {
      const grandParents = this.maternalGrandparents;
      grandParents.removeAt(index);
      this.maternalGPsList.push(GPType);
      if (grandParents.length == 0) {
        this.addMaternalGP = false;
      }
    } else if (type == 'paternal') {
      const grandParents = this.paternalGrandparents;
      grandParents.removeAt(index);
      this.paternalGPsList.push(GPType);
      if (grandParents.length == 0) {
        this.addPaternalGP = false;
      }
    }
  }

  closeAddGP(type) {
    if (type == 'maternal') {
      this.addMaternalGP = false;
      this.addGrandParentNo('maternal');
    } else if (type == 'paternal') {
      this.addPaternalGP = false;
      this.addGrandParentNo('paternal');
    }
  }

  addFamilyMember(values) {
    const members = this.otherFamilyMembers;
    members.push(this.famMember(values));
    this.addOtherMember = false;
  }

  closeAddOthers() {
    this.addOtherMember = false;
    this.addOtherMembersNo();
  }

  onDeleteOthers(index) {
    const members = this.otherFamilyMembers;
    members.removeAt(index);
    if (members.length == 0) {
      this.addOtherMember = false
    }
  }

  setUpCanvas() {
    setTimeout(() => {
      this.canvas = document.querySelector('canvas');
      const parentWidth = $(this.canvas).parent().outerWidth();
      this.canvas.setAttribute('width', parentWidth);
      this.canvas.setAttribute('height', '200px');
      this.signaturePad = new SignaturePad(this.canvas, {
        backgroundColor: 'rgb(255,255,255)',
      });
    }, 50);
  }

  sign() {
    if (this.signature) {
      this.declarationForm.get('signature').patchValue(this.signature);
      this.signed = true;
    }

    // setTimeout(() => {
    //   this.canvas = document.querySelector('canvas');
    //   const parentWidth = $(this.canvas).parent().outerWidth();
    //   this.canvas.setAttribute('width', parentWidth);
    //   this.canvas.setAttribute('height', '175px');
    //   this.signaturePad = new SignaturePad(this.canvas, {
    //     backgroundColor: 'rgb(255,255,255)'
    //   });
    // }, 50);

  }

  clearSig() {
    this.declarationForm.get('signature').reset();
    this.signed = false;
  }

  approveSignature() {
    if (this.signaturePad.isEmpty()) {
      toast({ html: 'Please Sign the Application!', classes: 'red' });
      return;
    } else {
      const dataUrl = this.signaturePad.toDataURL();
      // .replace('data:image/png;base64,', '')
      this.signature = dataUrl;
      $('#signatureModal').modal('close');
      //toast({ html: 'Application Signed', classes: 'green' });
      //this.onBack();
      setTimeout(() => {
        M.updateTextFields();
        $('.modal').modal();
      }, 25);
    }
  }

  clearSignature() {
    this.signaturePad.clear();
    // this.signed = false;
    this.signature = null;
    this.setUpCanvas();
  }

  // Navigations
  next() {
    this.step += 1;
    if (this.step == 6) {
      this.setUpCanvas();
    }
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
      $('.tooltipped').tooltip();
      $('.dropdown-trigger').dropdown();
      $('.dropdown-trigger').dropdown({ constrainWidth: false });
      $('.collapsible').collapsible();
    }, 25);
    window.scrollTo(0, 0);
  }

  previous() {
    this.step -= 1;
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
      $('.tooltipped').tooltip();
      $('.dropdown-trigger').dropdown();
      $('.dropdown-trigger').dropdown({ constrainWidth: false });
      $('.collapsible').collapsible();
    }, 25);
    window.scrollTo(0, 0);
  }

  summaryNext() {
    this.summary1 = false;
    this.summary2 = true;
  }

  summaryBack() {
    this.summary1 = true;
    this.summary2 = false;
  }

  back() {
    this.location.back();
  }

  //Form submission 
  submitPersonalInfo(values) {
    this.model.givenName = values.givenName.toUpperCase();
    this.model.familyName = values.familyName.toUpperCase();
    this.model.birth_familyName = values.birth_familyName.toUpperCase();
    this.model.aliasName = values.aliasName.toUpperCase();
    this.model.gender = values.gender;
    this.model.dob = values.dob;
    this.model.number = values.number;
    this.model.street = values.street.toUpperCase();
    this.model.apartment = values.apartment;
    this.model.POBox = values.POBox.toUpperCase();
    this.model.city = values.city.toUpperCase();
    this.model.province_state = values.province_state;
    this.model.postalCode = values.postalCode.toUpperCase();
    this.model.telephone = values.telephone;
    this.model.telephone2 = values.telephone2;
    this.model.email = values.email.toUpperCase();
    this.model.sameMailingAddress = values.sameMailingAddress;
    if (!values.sameMailingAddress) {
      this.model.mailing_number = values.mailing_number;
      this.model.mailing_street = values.mailing_street.toUpperCase();
      this.model.mailing_apartment = values.mailing_apartment;
      this.model.mailing_POBox = values.mailing_POBox.toUpperCase();
      this.model.mailing_city = values.mailing_city.toUpperCase();
      this.model.mailing_province_state = values.mailing_province_state.toUpperCase();
      this.model.mailing_postalCode = values.mailing_postalCode.toUpperCase();
    }

    const applicantName = values.givenName + " " + values.familyName;
    this.nameLinking_documentName.patchValue(applicantName);
    this.supporting_documentName.patchValue(applicantName);
    this.declarationForm.get('applicantName').patchValue(applicantName);

    this.next();
  }

  submitFirstNationInfo(values) {
    this.model.bandName = values.bandName.toUpperCase();
    this.model.bandNumber = values.bandNumber;

    this.registrationNo.patchValue(values.bandNumber);
    this.next();
  }

  submitdocuments(values) {
    this.model.proofOfBirthDocument = this.proofOfBirth;
    this.model.nameLinkingDocumnet = this.nameLinkingDocs;
    this.model.supportingIdentityDocuments = this.supportingDocs;
    this.next();
  }

  submitAdoptionInfo(values) {
    this.model.adopted = values.adopted;
    if (values.adopted) {
      this.model.ISNEntitlement = values.ISNEntitlement;
      this.model.adoptiveMother = values.adoptiveMother;
      this.model.adoptiveFather = values.adoptiveFather;
      this.model.birthMother = values.birthMother;
      this.model.birthFather = values.birthFather;
    }
    this.next();
  }

  submitFamilyInfo(values) {
    this.model.father = values.father;
    this.model.mother = values.mother;
    this.model.maternal_grandparents = values.maternalGrandparents;
    this.model.paternal_grandparents = values.paternalGrandparents;
    this.model.other_familyMembers = values.otherFamilyMembers;
    this.next();
  }

  submitPhotoSignature(values) {
    this.model.SCISRequested = values.SCIS_requested;
    if (values.SCIS_requested) {
      this.model.photo = this.photo;
      this.model.photoBy = values.photoBy;
      this.model.photoAddress = values.photoAddress;
      this.model.photoDate = values.photoDate;
    }
    this.model.signature = this.signature;
    this.next();
  }

  submitDeclaration(values) {
    this.model.applicantName = values.applicantName;
    this.model.signatureDate = values.signatureDate;
    this.next();
  }

  submitIRA(values) {
    this.model.IRA_assisted = values.IRA_assisted;
    this.model.IRA_name = values.IRA_name;
    this.model.bandNo_initiatorCode = values.bandNo_initiatorCode;
    // this.model.familyName = 'Tamene',
    //   this.model.givenName = 'Mihret',
    //   this.model.sameMailingAddress = false;
    // const birthDoc = {
    //   registrationNo: 1234,
    //   province_state: 'AB',
    //   file_url: 'https://api.mysabenefits.ca/Media/Demo/Images/f47e22e7-f957-40c6-a404-acadaf19f841-id.png',
    //   file_name: 'BirthCertificat.png',
    //   file_type: 'Image'
    // }
    // this.model.proofOfBirthDocument = birthDoc;
    // this.model.nameLinkingDocumnet = [];
    // this.model.nameLinkingDocumnet.push({
    //   documentName: 'Document Name',
    //   documentType: 'Passport',
    //   file_url: 'https://api.mysabenefits.ca/Media/Demo/Images/f47e22e7-f957-40c6-a404-acadaf19f841-id.png',
    //   file_name: 'NameLinkingDoc1.jpg',
    //   file_type: 'Image'
    // });
    // this.model.nameLinkingDocumnet.push({
    //   documentName: 'Document Name',
    //   documentType: 'Passport',
    //   file_url: 'https://api.mysabenefits.ca/Media/Demo/Images/f47e22e7-f957-40c6-a404-acadaf19f841-id.png',
    //   file_name: 'NameLinkingDoc2.png',
    //   file_type: 'Image'
    // });
    // this.model.supportingIdentityDocuments = [];
    // this.model.supportingIdentityDocuments.push({
    //   documentName: 'Document Name',
    //   documentType: 'Passport',
    //   documentNumber: 1234,
    //   expiryDate: '01/01/2000',
    //   file_url: 'https://api.mysabenefits.ca/Media/Demo/Images/f47e22e7-f957-40c6-a404-acadaf19f841-id.png',
    //   file_name: 'supportingDoc1.jpg',
    //   file_type: 'Image'
    // });
    // this.model.supportingIdentityDocuments.push({
    //   documentName: 'Document Name',
    //   documentType: 'Passport',
    //   documentNumber: 1234,
    //   expiryDate: '01/01/2000',
    //   file_url: 'https://api.mysabenefits.ca/Media/Demo/Images/f47e22e7-f957-40c6-a404-acadaf19f841-id.png',
    //   file_name: 'supportingDoc2.jpg',
    //   file_type: 'Image'
    // });

    // this.model.SCISRequested = true;
    // this.model.photo = {
    //   file_url: 'https://api.mysabenefits.ca/Media/Demo/Images/df866151-86ef-4bdc-bebe-4831323f3e62-Nationlogo.png',
    //   file_name: 'photo.png',
    //   file_type: 'Image'
    // }
    // this.model.adopted = true;
    // this.model.ISNEntitlement = this.adoptionInfoForm.get('ISNEntitlement').value;
    // this.model.adoptiveMother = this.adoptionInfoForm.get('adoptiveMother').value;
    // this.model.adoptiveFather = this.adoptionInfoForm.get('adoptiveFather').value;
    // this.model.birthMother = this.adoptionInfoForm.get('birthMother').value;
    // this.model.birthFather = this.adoptionInfoForm.get('birthFather').value;
    // // this.model.father.registred = true;
    // this.model.father = this.father.value;
    // // this.model.mother.registred = true;
    // this.model.mother = this.mother.value;
    // this.model.maternal_grandparents = [];
    // //this.model.maternal_grandparents.push(this.maternal_grandparents.value);
    // this.model.paternal_grandparents = [];
    // // this.model.paternal_grandparents.push(this.paternal_grandparents.value);
    // this.model.other_familyMembers = [];
    // //this.model.other_familyMembers.push(this.otherMembers.value);

    this.next();
  }

  SubmitApplication() {
    this.scisService.saveApplication(this.model).then(x => {
      toast({ html: 'Applicantion Successfully Submitted!', classes: 'green' });
      this.next();
    });
  }

  // ------------------------------------------Report --------------------------------
  async getReport() {
    let report = {
      info: {
        title: 'APPLICATION FOR REGISTRATION ON THE INDIAN REGISTER AND FOR THE SECURE CERTIFICATE OF INDIAN STATUS',
        subject: 'SCIS Application',
        keywords: 'SCIS Application',
      },
      pageSize: 'LETTER',
      //content: await this.getContent(),
      content: await this.getContent2(),
      //content: 'This is an sample PDF printed with pdfMake',
      styles: this.getStyles(),
    }
    return report;
  }

  async getContent() {
    let content = [];
    content.push(

      {
        style: 'table',
        layout: 'noBorders',
        table: {
          widths: ['*', '40%', '*'],
          headerRows: 1,
          body: [
            [
              {
                columns: [
                  [
                    await this.getFlag()
                  ],
                  {
                    text: "Government of Canada",
                    style: 'headerText',

                  },
                  {
                    text: "Gouvernement du Canada",
                    style: 'headerText'
                  },
                ],
                columnGap: 5
              },
              {},
              {}
              // {
              //   columns: [
              //     {
              //       stack: [
              //         {
              //           text: 'Powered By',
              //           style: 'headerText',
              //           alignment: 'center',
              //           margin: [0, 0, 0, 5]
              //         },
              //         {
              //           text: 'MY NATION ',
              //           bold: true,
              //           style: 'headerText',
              //           alignment: 'center',
              //           //margin: [ 0, 0, 0, 5 ]
              //         },
              //         // {
              //         //   text: 'Connecting Your Nation',
              //         //   style: 'headerText',
              //         //   alignment: 'center',
              //         // },
              //       ],
              //     },
              //     [
              //       await this.getLogo()
              //     ],
              //   ],
              // }
            ],
          ]
        }
      },
      {
        stack: [
          {
            text: "APPLICATION FOR REGISTRATION ON THE INDIAN REGISTER AND FOR THE SECURE CERTIFICATE OF INDIAN STATUS (SCIS)",
            style: 'mainTitle'
          },
          {
            text: "(FOR ADULTS 16 YEARS OF AGE OR OLDER)",
            fontSize: 10,
            alignment: 'center'
          },
        ],
        margin: [0, 0, 0, 20]
      },
      {
        stack: [
          {
            text: "Privacy Act Statement",
            fontSize: 11,
            italics: true,
            bold: true,
            margin: [0, 0, 0, 10]
          },
          {
            text: [
              { text: 'This statement outlines the purposes for the collection and use of personal information. Only information needed to respond to program requirements will be requested. Collection, use, and disclosure of personal information are in accordance with the Privacy Act. In some cases, information may be disclosed without your consent pursuant to subsection 8(2) of the Privacy Act. The authority to collect and use personal information for the Indian Registration and the Secure Certificate of Indian Status programs is derived from the Indian Act. We use the personal information we collect to determine entitlement to registration in the Indian Register and membership in a First Nation for which the Band List is maintained by the Department, to issue a Secure Certificate of Indian Status to registered persons, and for the provision of benefits and services conferred exclusively to those who are registered. We may share the personal information you provide as outlined under Personal Information Bank AANDC PPU110 (Info Source ', style: 'paragraphText' },
              { text: 'https://www.sac-isc.gc.ca/ eng/1353081939455', link: 'https://www.sac-isc.gc.ca/ eng/1353081939455', color: 'blue', decoration: 'underline', fontSize: 10 },
              { text: '). The information collected is retained by the Department for 30 years after the last administrative action and then transferred to Library and Archives Canada (or as described in the Personal Information Bank). As stated in the Privacy Act, you have the right to access the personal information you give us and request changes to incorrect information. If you have questions or wish to notify us of incorrect information, you may call us at 1-800-567-9604. For more information on privacy issues and the Privacy Act in general, you can consult the Privacy Commissioner at 1-800-282-1376.', style: 'paragraphText' },
            ],
          },
          // {
          //   text: `This statement outlines the purposes for the collection and use of personal information. Only information needed to respond to program requirements will be requested. Collection, use, and disclosure of personal information are in accordance with the Privacy Act. In some cases, information may be disclosed without your consent pursuant to subsection 8(2) of the Privacy Act. The authority to collect and use personal information for the Indian Registration and the Secure Certificate of Indian Status programs is derived from the Indian Act. We use the personal information we collect to determine entitlement to registration in the Indian Register and membership in a First Nation for which the Band List is maintained by the Department, to issue a Secure Certificate of Indian Status to registered persons, and for the provision of benefits and services conferred exclusively to those who are registered. We may share the personal information you provide as outlined under Personal Information Bank AANDC PPU110 (Info Source https://www.sac-isc.gc.ca/ eng/1353081939455). The information collected is retained by the Department for 30 years after the last administrative action and then transferred to Library and Archives Canada (or as described in the Personal Information Bank). As stated in the Privacy Act, you have the right to access the personal information you give us and request changes to incorrect information. If you have questions or wish to notify us of incorrect information, you may call us at 1-800-567-9604. For more information on privacy issues and the Privacy Act in general, you can consult the Privacy Commissioner at 1-800-282-1376.`,
          //   style: 'paragraphText',
          // },
        ],
      },
      {
        stack: [
          {
            text: "NOTICE TO APPLICATS",
            fontSize: 11,
            bold: true,
            margin: [0, 10, 0, 10]
          },
          {
            text: `If you identify with an Indigenous group that is not recognized under the Indian Act (non-status), you may wish to consult with that group before proceeding with an application for registration for yourself, a minor child or dependent adult. Registration under the Indian Act in Canada may affect your entitlement to join or be recognized by some non-status groups and your entitlement to the programs and services they may offer. The Indian Registrar does not have the power to remove a name from the Indian Register if the individual has been correctly registered, even when the individual wishes to deregister.`,
            style: 'paragraphText',
            margin: [0, 0, 0, 5]
          },
          {
            text: `The Secure Certificate of Indian Status (SCIS) remains at all times the property of the Government of Canada and must only be used by the person in whose name it is issued. Any false or misleading statement with respect to this application and any supporting document, including the concealment of any material fact, selling or permitting the use of your SCIS by any other individual or agency may lead to criminal prosecution, and is cause for revocation of your SCIS and refusal to issue a SCIS in the future. Any false or misleading statement, including the concealment of any material fact, may lead to a review of your entitlement to registration and revocation of your registered Indian status.`,
            style: 'paragraphText',
            margin: [0, 10, 0, 5]
          },
        ],
      },
      {
        text: "",
        pageBreak: 'after'
      },

      {
        style: 'table',
        layout: 'lightHorizontalLines',
        table: {
          widths: ['*', '*', '*', '*'],
          body: [
            [
              {
                text: 'SECTION 1: Personal Information',
                style: 'title',
                colSpan: 4
              },
              {},
              {},
              {}
            ],
            [
              {
                stack: [
                  {
                    text: 'Family Name',
                    style: 'fieldName'
                  },
                  {
                    text: this.model.familyName,
                    style: 'fieldValue'
                  },
                ],
                colSpan: 2,
              },
              {},
              {
                stack: [
                  {
                    text: 'Given Name(s)',
                    style: 'fieldName'
                  },
                  {
                    text: this.model.givenName,
                    style: 'fieldValue'
                  },
                ],
                colSpan: 2
              },
              {}
            ],
            [
              {
                stack: [
                  {
                    text: 'Family Name at Birth',
                    style: 'fieldName'
                  },
                  {
                    text: this.model.birth_familyName,
                    style: 'fieldValue'
                  },
                ],
                colSpan: 2
              },
              {},
              {
                stack: [
                  {
                    text: 'Alias/Cultural Name',
                    style: 'fieldName'
                  },
                  {
                    text: this.model.aliasName,
                    style: 'fieldValue'
                  },
                ],
                colSpan: 2
              },
              {},
            ],
            [
              {
                stack: [
                  {
                    text: 'Gender',
                    style: 'fieldName'
                  },
                  {
                    text: this.model.gender,
                    style: 'fieldValue'
                  },
                ],
                colSpan: 2
              },
              {},
              {
                stack: [
                  {
                    text: 'Date of Birth',
                    style: 'fieldName'
                  },
                  {
                    text: this.model.dob ? this.model.dob : '',
                    style: 'fieldValue'
                  },
                ],
                colSpan: 2
              },
              {},
            ],
            [
              {
                text: 'Permanent Address',
                style: 'subTitle',
                colSpan: 4
              },
              {},
              {},
              {}
            ],
            [
              {
                stack: [
                  {
                    text: 'Number, Street, Apartment, P.O.Box',
                    style: 'fieldName'
                  },
                  {
                    text: this.model.number + " " + this.model.street + " " + this.model.apartment + " " + this.model.POBox,
                    style: 'fieldValue'
                  },
                ],
                colSpan: 4,
              },
              {},
              {},
              {}
            ],
            [
              {
                stack: [
                  {
                    text: 'City/Town',
                    style: 'fieldName'
                  },
                  {
                    text: this.model.city,
                    style: 'fieldValue'
                  },
                ],
                colSpan: 2,
              },
              {},
              {
                stack: [
                  {
                    text: this.country == 'CA' ? "Province/Territory" : 'State',
                    style: 'fieldName'
                  },
                  {
                    text: this.model.province_state,
                    style: 'fieldValue'
                  },
                ]
              },
              {
                stack: [
                  {
                    text: 'Postal/ZIP Code',
                    style: 'fieldName'
                  },
                  {
                    text: this.model.postalCode,
                    style: 'fieldValue'
                  },
                ],
              },
            ],
            [
              {
                stack: [
                  {
                    text: 'Telephone Number(Daytime)',
                    style: 'fieldName'
                  },
                  {
                    text: this.model.telephone,
                    style: 'fieldValue'
                  },
                ],
              },
              {
                stack: [
                  {
                    text: 'Telephone Number(Other)',
                    style: 'fieldName'
                  },
                  {
                    text: this.model.telephone2,
                    style: 'fieldValue'
                  },
                ]
              },
              {
                stack: [
                  {
                    text: 'Email Address',
                    style: 'fieldName'
                  },
                  {
                    text: this.model.email,
                    style: 'fieldValue'
                  },
                ],
                colSpan: 2
              },
              {},
            ],
            [
              {
                text: 'Mailing Address',
                style: 'subTitle',
                colSpan: 4
              },
              {},
              {},
              {}
            ],
            this.model.sameMailingAddress ?
              [
                {
                  text: 'Mailing Address Same as Permanent Address',
                  style: 'fieldValue',
                  colSpan: 4
                },
                {},
                {},
                {}
              ] :
              [
                {
                  stack: [
                    {
                      text: 'Number, Street, Apartment, P.O.Box',
                      style: 'fieldName'
                    },
                    {
                      text: this.model.mailing_number + " " + this.model.mailing_street + " " + this.model.mailing_apartment + " " + this.model.mailing_POBox,
                      style: 'fieldValue'
                    },
                  ],
                  colSpan: 4,
                },
                {},
                {},
                {}
              ],
            this.model.sameMailingAddress ?
              [{}, {}, {}, {}] :
              [
                {
                  stack: [
                    {
                      text: 'City/Town',
                      style: 'fieldName'
                    },
                    {
                      text: this.model.city,
                      style: 'fieldValue'
                    },
                  ],
                  colSpan: 2,
                },
                {},
                {
                  stack: [
                    {
                      text: this.country == 'CA' ? "Province/Territory" : 'State',
                      style: 'fieldName'
                    },
                    {
                      text: this.model.province_state,
                      style: 'fieldValue'
                    },
                  ]
                },
                {
                  stack: [
                    {
                      text: 'Postal/ZIP Code',
                      style: 'fieldName'
                    },
                    {
                      text: this.model.postalCode,
                      style: 'fieldValue'
                    },
                  ],
                },
              ]
          ]
        }
      },

      {
        style: 'table',
        layout: 'lightHorizontalLines',
        table: {
          widths: ['*', '*'],
          body: [
            [
              {
                text: 'SECTION 2: First Nation/Band Choice',
                style: 'title',
                colSpan: 2
              },
              {},
            ],
            [
              {
                stack: [
                  {
                    text: 'First Nation/Band Name',
                    style: 'fieldName'
                  },
                  {
                    text: this.model.bandName,
                    style: 'fieldValue'
                  },
                ],
              },
              {
                stack: [
                  {
                    text: 'First Nation/Band Number',
                    style: 'fieldName'
                  },
                  {
                    text: this.model.bandNumber,
                    style: 'fieldValue'
                  },
                ],
              },
            ],
          ]
        }
      },

      {
        style: 'table',
        layout: 'lightHorizontalLines',
        table: {
          widths: ['*', '*', '*'],
          body: [
            [
              {
                text: 'SECTION 3: Documnet Requirements',
                style: 'title',
                colSpan: 3
              },
              {},
              {},
            ],
            [
              {
                text: 'A. Proof of Birth Document',
                style: ['subTitle', 'links'],
                colSpan: 3,
                linkToDestination: 'proofOfBirthDocument',
              },
              {},
              {},
            ],

            (this.model.proofOfBirthDocument) ?
              [
                {
                  colSpan: 3,
                  margin: [0, 10, 0, 10],
                  table: {
                    widths: ['auto', '*', '*'],
                    body: [
                      [
                        {
                          text: 'Registration Number on Birth Documnet',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, true, true]
                        },
                        {
                          text: this.country == 'CA' ? "Province/Territory" : 'State',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, true, true]
                        },
                        {
                          text: 'Document',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, false, true]
                        },
                      ],
                      [
                        {
                          text: this.model.proofOfBirthDocument.registrationNo,
                          style: 'fieldValue',
                          border: [false, false, true, true]
                        },
                        {
                          text: this.model.proofOfBirthDocument.province_state,
                          style: 'fieldValue',
                          border: [false, false, true, true]
                        },
                        // {
                        //   text: this.model.proofOfBirthDocument.file_name,
                        //   link: this.model.proofOfBirthDocument.file_url,
                        //   style: 'links'
                        // },
                        {
                          text: this.model.proofOfBirthDocument.file_name,
                          // linkToDestination: 'proofOfBirthDocument',
                          style: ['fieldValue']
                        }
                      ],

                    ]
                  }
                }, {}, {}
              ] :
              [
                {
                  text: 'No Documnets Provided',
                  style: 'fieldValue',
                  colSpan: 3
                },
                {},
              ],

            [
              {
                text: 'B. Name Linking Document(s)',
                style: ['subTitle', 'links'],
                colSpan: 3,
                linkToDestination: 'nameLinkingDocumnet',
              },
              {},
              {},
            ],
            (this.model.nameLinkingDocumnet.length > 0) ?
              [
                {
                  colSpan: 3,
                  margin: [0, 10, 0, 10],
                  table: {
                    widths: ['auto', '*', '*'],
                    body: [
                      [
                        {
                          text: 'Name',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, true, true]
                        },
                        {
                          text: 'Document Type',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, true, true]
                        },
                        {
                          text: 'Document',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, false, true]
                        },
                      ],
                      ...this.model.nameLinkingDocumnet.map(doc => {
                        return [
                          { text: doc.documentName, style: 'fieldValue', border: [false, false, true, true] },
                          { text: doc.documentType, style: 'fieldValue', border: [false, false, true, true] },
                          {
                            text: doc.file_name,
                            //link: doc.file_url,
                            //style: 'links',
                            style: 'fieldValue',
                            border: [false, false, false, true]
                          },];
                      })
                    ]
                  }
                }, {}, {}
              ] : [
                {
                  text: 'No Documnets Provided',
                  style: 'fieldValue',
                  colSpan: 3
                },
                {},
              ],
            [
              {
                text: 'C. Supporting Identity Document(s)',
                style: ['subTitle', 'links'],
                colSpan: 3,
                linkToDestination: 'supportingIdentityDocuments'
              },
              {},
              {},
            ],
            (this.model.supportingIdentityDocuments.length > 0) ?
              [
                {
                  colSpan: 3,
                  margin: [0, 10, 0, 10],
                  table: {
                    widths: ['auto', '*', '*', '*', '*'],
                    body: [
                      [
                        {
                          text: 'Name',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, true, true]
                        },
                        {
                          text: 'Document Type',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, true, true]
                        },
                        {
                          text: 'Document Number',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, true, true]
                        },
                        {
                          text: 'Expiry Date',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, true, true]
                        },
                        {
                          text: 'Document',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, false, true]
                        },
                      ],
                      ...this.model.supportingIdentityDocuments.map(doc => {
                        return [
                          { text: doc.documentName, style: 'fieldValue', border: [false, false, true, true] },
                          { text: doc.documentType, style: 'fieldValue', border: [false, false, true, true] },
                          { text: doc.documentNumber, style: 'fieldValue', border: [false, false, true, true] },
                          { text: doc.expiryDate, style: 'fieldValue', border: [false, false, true, true] },
                          {
                            text: doc.file_name,
                            style: 'fieldValue',
                            //link: doc.file_url,
                            //style: 'links',
                            border: [false, false, false, true]
                          },];
                      })
                    ]
                  }
                }, {}, {}
              ] : [
                {
                  text: 'No Documnets Provided',
                  style: 'fieldValue',
                  colSpan: 3
                },
                {},
              ]
          ]
        }
      },

      {
        style: 'table',
        layout: 'lightHorizontalLines',
        table: {
          widths: ['*', '*', '*'],
          body: [
            [
              {
                text: 'SECTION 4: Adoption Information',
                style: 'title',
                colSpan: 3
              },
              {},
              {}
            ],
            (this.model.adopted) ?
              [
                {
                  colSpan: 3,
                  margin: [0, 10, 0, 10],
                  table: {
                    widths: ['*', '*', '*', '*'],
                    body: [
                      [
                        {
                          text: 'I was adopted as a child, and I believe that I have entitlement to Indian status through',
                          style: 'fieldName',
                          colSpan: 4,
                          border: [false, false, false, true]
                        },
                        {},
                        {},
                        {}
                      ],
                      [
                        {
                          stack: [
                            {
                              text: 'My Adoptive Mother ',
                              style: 'fieldName'
                            },
                            {
                              text: this.model.ISNEntitlement.adoptiveMother ? 'Yes' : 'NO',
                              style: 'fieldValue'
                            },
                          ],
                          border: [false, false, true, true]
                        },
                        {
                          stack: [
                            {
                              text: 'My Adoptive Father ',
                              style: 'fieldName'
                            },
                            {
                              text: this.model.ISNEntitlement.adoptiveFather ? 'Yes' : 'NO',
                              style: 'fieldValue'
                            },
                          ],
                          border: [false, false, true, true]
                        },
                        {
                          stack: [
                            {
                              text: 'My Birth Mother ',
                              style: 'fieldName'
                            },
                            {
                              text: this.model.ISNEntitlement.birthMother ? 'Yes' : 'NO',
                              style: 'fieldValue'
                            },
                          ],
                          border: [false, false, true, true]
                        },
                        {
                          stack: [
                            {
                              text: 'My Adoptive Father ',
                              style: 'fieldName'
                            },
                            {
                              text: this.model.ISNEntitlement.birthFather ? 'Yes' : 'NO',
                              style: 'fieldValue'
                            },
                          ],
                          border: [false, false, false, true]
                        },
                      ],
                      [
                        {
                          text: 'Adoptive Mother',
                          style: 'subTitle',
                          colSpan: 4,
                          border: [false, false, false, true]
                        },
                        {},
                        {},
                        {}
                      ],

                      [
                        {
                          stack: [
                            {
                              text: 'Family Name ',
                              style: 'fieldName'
                            },
                            {
                              text: this.model.adoptiveMother.familyName,
                              style: 'fieldValue'
                            },
                          ],
                          border: [false, false, true, true]
                        },
                        {
                          stack: [
                            {
                              text: 'Given Name ',
                              style: 'fieldName'
                            },
                            {
                              text: this.model.adoptiveMother.givenName,
                              style: 'fieldValue'
                            },
                          ],
                          border: [false, false, true, true]
                        },
                        {
                          stack: [
                            {
                              text: 'Birth Date ',
                              style: 'fieldName'
                            },
                            {
                              text: this.model.adoptiveMother.dob,
                              style: 'fieldValue'
                            },
                          ],
                          border: [false, false, false, true],
                          colSpan: 2
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Adoptive Father',
                          style: 'subTitle',
                          colSpan: 4,
                          border: [false, false, false, true]
                        },
                        {},
                        {},
                        {}
                      ],
                      [
                        {
                          stack: [
                            {
                              text: 'Family Name ',
                              style: 'fieldName'
                            },
                            {
                              text: this.model.adoptiveFather.familyName,
                              style: 'fieldValue'
                            },
                          ],
                          border: [false, false, true, true]
                        },
                        {
                          stack: [
                            {
                              text: 'Given Name ',
                              style: 'fieldName'
                            },
                            {
                              text: this.model.adoptiveFather.givenName,
                              style: 'fieldValue'
                            },
                          ],
                          border: [false, false, true, true]
                        },
                        {
                          stack: [
                            {
                              text: 'Birth Date ',
                              style: 'fieldName'
                            },
                            {
                              text: this.model.adoptiveFather.dob,
                              style: 'fieldValue'
                            },
                          ],
                          border: [false, false, false, true],
                          colSpan: 2
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Birth Mother',
                          style: 'subTitle',
                          colSpan: 4,
                          border: [false, false, false, true]
                        },
                        {},
                        {},
                        {}
                      ],
                      [
                        {
                          stack: [
                            {
                              text: 'Family Name ',
                              style: 'fieldName'
                            },
                            {
                              text: this.model.birthMother.familyName,
                              style: 'fieldValue'
                            },
                          ],
                          border: [false, false, true, true],
                          colSpan: 2
                        },
                        {},
                        {
                          stack: [
                            {
                              text: 'Given Name ',
                              style: 'fieldName'
                            },
                            {
                              text: this.model.birthMother.givenName,
                              style: 'fieldValue'
                            },
                          ],
                          border: [false, false, false, true],
                          colSpan: 2
                        },
                        {}
                      ],

                      [
                        {
                          text: 'Birth Father',
                          style: 'subTitle',
                          colSpan: 4,
                          border: [false, false, false, true]
                        },
                        {},
                        {},
                        {}
                      ],
                      [
                        {
                          stack: [
                            {
                              text: 'Family Name ',
                              style: 'fieldName'
                            },
                            {
                              text: this.model.birthFather.familyName,
                              style: 'fieldValue'
                            },
                          ],
                          border: [false, false, true, true],
                          colSpan: 2
                        },
                        {},
                        {
                          stack: [
                            {
                              text: 'Given Name ',
                              style: 'fieldName'
                            },
                            {
                              text: this.model.birthFather.givenName,
                              style: 'fieldValue'
                            },
                          ],
                          border: [false, false, false, true],
                          colSpan: 2
                        },
                        {}
                      ],
                    ]
                  }
                }, {}, {}
              ] :

              [
                {
                  text: 'Not Adopted',
                  style: 'fieldValue',
                  colSpan: 3
                },
                {},
                {}
              ],
          ]
        }
      },

      {
        style: 'table',
        layout: 'lightHorizontalLines',
        table: {
          widths: ['*', '*', '*'],
          body: [
            [
              {
                text: 'SECTION 5: Family Information',
                style: 'title',
                colSpan: 3
              },
              {},
              {}
            ],
            [
              {
                text: 'A. Father',
                style: 'subTitle',
                colSpan: 3
              },
              {},
              {}
            ],
            [
              {
                stack: [
                  {
                    text: 'Family Name',
                    style: 'fieldName'
                  },
                  {
                    text: this.model.father.familyName,
                    style: 'fieldValue'
                  },
                ],
              },
              {
                stack: [
                  {
                    text: 'Given Name(s)',
                    style: 'fieldName'
                  },
                  {
                    text: this.model.father.givenName,
                    style: 'fieldValue'
                  },
                ],
              },
              {
                stack: [
                  {
                    text: 'Family Name at Birth',
                    style: 'fieldName'
                  },
                  {
                    text: this.model.father.birth_familyName,
                    style: 'fieldValue'
                  },
                ],
              },
            ],
            [
              {
                stack: [
                  {
                    text: 'Date of Birth',
                    style: 'fieldName'
                  },
                  {
                    text: this.model.father.dob,
                    style: 'fieldValue'
                  },
                ],
              },
              {
                stack: [
                  {
                    text: 'First Nation/Band Name',
                    style: 'fieldName'
                  },
                  {
                    text: this.model.father.bandName,
                    style: 'fieldValue'
                  },
                ],
              },
              {
                stack: [
                  {
                    text: 'First Nation/Band Number',
                    style: 'fieldName'
                  },
                  {
                    text: this.model.father.bandNumber,
                    style: 'fieldValue'
                  },
                ],
              },
            ],
            [
              {
                stack: [
                  {
                    text: 'Adopted',
                    style: 'fieldName'
                  },
                  {
                    text: this.model.father.adopted,
                    style: 'fieldValue'
                  },
                ],
              },
              {
                stack: [
                  {
                    text: 'Parent stated on the Birth Document',
                    style: 'fieldName'
                  },
                  {
                    text: this.model.father.parentStated ? 'Yes' : 'No',
                    style: 'fieldValue'
                  },
                ],
                colSpan: 2
              },
              {},
            ],
            [
              {
                stack: [
                  {
                    text: 'Registred',
                    style: 'fieldName'
                  },
                  {
                    text: this.model.father.registred ? 'yes' : 'No',
                    style: 'fieldValue'
                  },
                ],
                colSpan: this.model.father.registred ? 1 : 3
              },
              this.model.father.registred ?
                {
                  stack: [
                    {
                      text: 'Registration Number',
                      style: 'fieldName'
                    },
                    {
                      text: this.model.father.registrationNumber,
                      style: 'fieldValue'
                    },
                  ],
                  colSpan: 2
                } : {},
              {},
            ],
            [
              {
                text: 'B. Mother',
                style: 'subTitle',
                colSpan: 3
              },
              {},
              {}
            ],
            [
              {
                stack: [
                  {
                    text: 'Family Name',
                    style: 'fieldName'
                  },
                  {
                    text: this.model.mother.familyName,
                    style: 'fieldValue'
                  },
                ],
              },
              {
                stack: [
                  {
                    text: 'Given Name(s)',
                    style: 'fieldName'
                  },
                  {
                    text: this.model.mother.givenName,
                    style: 'fieldValue'
                  },
                ],
              },
              {
                stack: [
                  {
                    text: 'Family Name at Birth',
                    style: 'fieldName'
                  },
                  {
                    text: this.model.mother.birth_familyName,
                    style: 'fieldValue'
                  },
                ],
              },
            ],
            [
              {
                stack: [
                  {
                    text: 'Date of Birth',
                    style: 'fieldName'
                  },
                  {
                    text: this.model.mother.dob,
                    style: 'fieldValue'
                  },
                ],
              },
              {
                stack: [
                  {
                    text: 'First Nation/Band Name',
                    style: 'fieldName'
                  },
                  {
                    text: this.model.mother.bandName,
                    style: 'fieldValue'
                  },
                ],
              },
              {
                stack: [
                  {
                    text: 'First Nation/Band Number',
                    style: 'fieldName'
                  },
                  {
                    text: this.model.mother.bandNumber,
                    style: 'fieldValue'
                  },
                ],
              },
            ],
            [
              {
                stack: [
                  {
                    text: 'Adopted',
                    style: 'fieldName'
                  },
                  {
                    text: this.model.mother.adopted,
                    style: 'fieldValue'
                  },
                ],
              },
              {
                stack: [
                  {
                    text: 'Registred',
                    style: 'fieldName'
                  },
                  {
                    text: this.model.mother.registred ? 'yes' : 'No',
                    style: 'fieldValue'
                  },
                ],
                colSpan: this.model.mother.registred ? 1 : 2
              },
              this.model.mother.registred ?
                {
                  stack: [
                    {
                      text: 'Registration Number',
                      style: 'fieldName'
                    },
                    {
                      text: this.model.mother.registrationNumber,
                      style: 'fieldValue'
                    },
                  ],
                } : {},
            ],
            [
              {
                text: 'C. Maternal Grandparents',
                style: 'subTitle',
                colSpan: 3
              },
              {},
              {}
            ],
            (this.model.maternal_grandparents.length > 0) ?
              [
                {
                  colSpan: 3,
                  margin: [0, 10, 0, 10],
                  table: {
                    widths: ['auto', '*', '*', '*', '*', '*', '*', '*', '*'],
                    body: [
                      [
                        {
                          text: '',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, true, true]
                        },
                        {
                          text: 'Family Name',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, true, true]
                        },
                        {
                          text: 'Given Name(s)',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, true, true]
                        },
                        {
                          text: 'Family Name at Birth',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, true, true]
                        },
                        {
                          text: 'Date of Birth',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, true, true]
                        },
                        {
                          text: 'First Nation/Band Number',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, true, true]
                        },
                        {
                          text: 'First Nation/Band Name',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, true, true]
                        },
                        {
                          text: 'Registration Number',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, true, true]
                        },
                        {
                          text: 'Adopted',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, false, true]
                        },
                      ],
                      ...this.model.maternal_grandparents.map(person => {
                        return [
                          { text: person.type, style: 'fieldValue', border: [false, false, true, true] },
                          { text: person.familyName, style: 'fieldValue', border: [false, false, true, true] },
                          { text: person.givenName, style: 'fieldValue', border: [false, false, true, true] },
                          { text: person.birth_familyName, style: 'fieldValue', border: [false, false, true, true] },
                          { text: person.dob, style: 'fieldValue', border: [false, false, true, true] },
                          { text: person.bandNumber, style: 'fieldValue', border: [false, false, true, true] },
                          { text: person.bandName, style: 'fieldValue', border: [false, false, true, true] },
                          { text: person.registred ? person.registrationNumber : '', style: 'fieldValue', border: [false, false, true, true] },
                          { text: person.adopted, style: 'fieldValue', border: [false, false, false, true] },];
                      })
                    ]
                  }
                }, {}, {}
              ] : [
                {
                  text: 'No Maternal Grandparents Added.',
                  style: 'fieldValue',
                  colSpan: 3
                },
                {},
              ],
            [
              {
                text: 'D. Paternal Grandparents',
                style: 'subTitle',
                colSpan: 3
              },
              {},
              {}
            ],
            (this.model.paternal_grandparents.length > 0) ?
              [
                {
                  colSpan: 3,
                  margin: [0, 10, 0, 10],
                  table: {
                    widths: ['auto', '*', '*', '*', '*', '*', '*', '*', '*'],
                    body: [
                      [
                        {
                          text: '',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, true, true]
                        },
                        {
                          text: 'Family Name',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, true, true]
                        },
                        {
                          text: 'Given Name(s)',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, true, true]
                        },
                        {
                          text: 'Family Name at Birth',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, true, true]
                        },
                        {
                          text: 'Date of Birth',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, true, true]
                        },
                        {
                          text: 'First Nation/Band Number',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, true, true]
                        },
                        {
                          text: 'First Nation/Band Name',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, true, true]
                        },
                        {
                          text: 'Registration Number',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, true, true]
                        },
                        {
                          text: 'Adopted',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, false, true]
                        },
                      ],
                      ...this.model.paternal_grandparents.map(person => {
                        return [
                          { text: person.type, style: 'fieldValue', border: [false, false, true, true] },
                          { text: person.familyName, style: 'fieldValue', border: [false, false, true, true] },
                          { text: person.givenName, style: 'fieldValue', border: [false, false, true, true] },
                          { text: person.birth_familyName, style: 'fieldValue', border: [false, false, true, true] },
                          { text: person.dob, style: 'fieldValue', border: [false, false, true, true] },
                          { text: person.bandNumber, style: 'fieldValue', border: [false, false, true, true] },
                          { text: person.bandName, style: 'fieldValue', border: [false, false, true, true] },
                          { text: person.registred ? person.registrationNumber : '', style: 'fieldValue', border: [false, false, true, true] },
                          { text: person.adopted, style: 'fieldValue', border: [false, false, false, true] },];
                      })
                    ]
                  }
                }, {}, {}
              ] : [
                {
                  text: 'No Paternal Grandparents Added.',
                  style: 'fieldValue',
                  colSpan: 3
                },
                {},
              ],
            [
              {
                text: 'Additional Family Information',
                style: 'subTitle',
                colSpan: 3
              },
              {},
              {}
            ],
            (this.model.other_familyMembers.length > 0) ?
              [
                {
                  colSpan: 3,
                  margin: [0, 10, 0, 10],
                  table: {
                    widths: ['*', '*', '*'],
                    body: [
                      [
                        {
                          text: 'Family Name',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, true, true]
                        },
                        {
                          text: 'Given Name(s)',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, true, true]
                        },
                        {
                          text: 'Relationship',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, false, true]
                        },
                      ],
                      ...this.model.other_familyMembers.map(person => {
                        return [

                          { text: person.familyName, style: 'fieldValue', border: [false, false, true, true] },
                          { text: person.givenName, style: 'fieldValue', border: [false, false, true, true] },
                          { text: person.relationship, style: 'fieldValue', border: [false, false, false, true] },];
                      })
                    ]
                  }
                }, {}, {}
              ] : [
                {
                  text: 'No Additional Family Infomation Added.',
                  style: 'fieldValue',
                  colSpan: 3
                },
                {},
              ],
          ]
        }
      },

      {
        style: 'table',
        layout: 'lightHorizontalLines',
        table: {
          widths: ['*', '*'],
          body: [
            [
              {
                text: 'SECTION 6: Photo And Signature',
                style: 'title',
                colSpan: 2
              },
              {},
            ],
            [
              {
                text: 'SCIS Requested ',
                style: 'fieldName'
              },
              {
                text: this.model.SCISRequested ? 'Yes' : 'No',
                style: 'fieldValue'
              },
            ],

            this.model.SCISRequested ?
              [
                {
                  colSpan: 2,
                  margin: [0, 10, 0, 10],
                  table: {
                    widths: ['*', '*', '*', '*'],
                    body: [
                      [{
                        text: 'Photo To Appear on the Secure Certificate of Indian Status(SCIS)',
                        style: ['subTitle', 'link'],
                        linkToDestination: 'photo',
                        border: [false, false, false, true],
                        colSpan: 4
                      }, {}, {}, {}],
                      [
                        {
                          text: 'Name of the studio or Person Who took the photo',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, true, true]
                        },
                        {
                          text: "Address of Studio or Person Who took the photo",
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, true, true]
                        },
                        {
                          text: 'The Date the photo was taken',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, true, true]
                        },
                        {
                          text: 'Photo',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, false, true]
                        },
                      ],
                      [
                        {
                          text: this.model.photoBy,
                          style: 'fieldValue',
                          border: [false, false, true, true]
                        },
                        {
                          text: this.model.photoAddress,
                          style: 'fieldValue',
                          border: [false, false, true, true]
                        },
                        {
                          text: this.model.photoDate,
                          style: 'fieldValue',
                          border: [false, false, true, true]
                        },
                        this.model.photo ?
                          {
                            text: this.model.photo.file_name,
                            //link: this.model.photo.file_url,
                            //style: 'links',
                            style: 'fieldValue',
                            border: [false, false, false, true]
                          } : { text: 'No photo was uploaded', style: 'fieldValue' },
                      ],
                    ]
                  }
                }, {}
              ] :
              [
                {
                  text: 'Photo to Appear on the Secure Certificate of Indian Status(SCIS)',
                  style: 'subTitle',
                },
                {
                  text: 'No Photo was Uploaded',
                  style: 'fieldValue',
                },
              ],
            [
              {
                text: 'Signature to Appear on the Secure Certificate of Indian Status(SCIS)',
                style: 'subTitle',
              },
              {
                image: this.model.signature,
                width: 250,
              },
            ],
          ]
        }
      },

      {
        style: 'table',
        table: {
          widths: ['*', '*'],
          body: [
            [
              {
                text: 'SECTION 7: Declaration and Signature',
                style: 'title',
                colSpan: 2,
                border: [false, false, false, true]
              },
              {},
            ],
            [
              {
                text: [
                  { text: 'I solemnly declare that all statements made in this application are true, all documents provided to support this application are unaltered, and the enclosed photographs (if requesting a Secure Certificate of Indian Status) are a true likeness of me. I have read and understand the Notice to Applicants and the Privacy Act Statement.', style: 'fieldName' },
                  { text: 'Privacy Act Statement.', italics: true },
                ],
                colSpan: 2,
                border: [false, false, false, false]
              },
              {}
            ],
            [
              {
                text: [

                  { text: 'If eligible I ', style: 'fieldName' },
                  { text: this.model.applicantName, style: 'fieldValue' },
                  { text: ' to be registered in the Indian Register and, if applicable, to have my name entered on a First Nation/Band List, as provided for under the Indian Act. I further request to have a Secure Certificate of Indian Status issued to me (if applicable). ', style: 'fieldName' }
                ],
                colSpan: 2,
                border: [false, false, false, true]
              },
              {}
            ],
            [
              {
                text: 'Signature',
                style: 'subTitle',
              },
              {
                image: this.model.signature,
                width: 100,
              },
            ],
            [
              {
                text: 'Date',
                style: 'fieldName',
              },
              {
                text: this.model.signatureDate,
                style: 'fieldValue'
              },
            ],

          ]
        }
      },

      {
        style: 'table',
        layout: 'lightHorizontalLines',
        table: {
          widths: ['*', '*'],
          body: [
            [
              {
                text: 'SECTION 7: Indian Registration Administrator(IRA)',
                style: 'title',
                colSpan: 2
              },
              {},
            ],
            [
              {
                stack: [
                  {
                    text: 'IRA Assisted in Completing this form',
                    style: 'fieldName'
                  },
                  {
                    text: this.model.IRA_assisted ? 'Yes' : 'No',
                    style: 'fieldValue'
                  },
                ],
                colSpan: 2
              },
              {},
            ],
            this.model.IRA_assisted ?
              [
                {
                  stack: [
                    {
                      text: 'IRA Name',
                      style: 'fieldName'
                    },
                    {
                      text: this.model.IRA_name,
                      style: 'fieldValue'
                    },
                  ],
                },
                {
                  stack: [
                    {
                      text: 'IRA First Nation/Band Number or Initiator Code',
                      style: 'fieldName'
                    },
                    {
                      text: this.model.bandNo_initiatorCode,
                      style: 'fieldValue'
                    },
                  ],
                },
              ] : [{}, {}],
          ]
        }
      },

      {
        text: "",
        pageBreak: 'after'
      },

      {
        style: 'table',
        layout: 'lightHorizontalLines',
        table: {
          widths: ['*'],
          body: [
            [
              {
                text: 'Attached Documents)',
                style: 'title',
              },
            ],
            [
              {
                text: 'Proof of Birth',
                style: 'subTitle',
                id: 'proofOfBirthDocument'
              },

            ],
            this.model.proofOfBirthDocument ?
              [
                await this.getImage(this.model.proofOfBirthDocument.file_url),
              ] : [{ text: 'No Document Provided', style: 'fieldValue' }],
            [
              {
                text: 'Name Linking Documents(s)',
                style: 'subTitle',
                id: 'nameLinkingDocumnet'
              },
            ],
            this.model.nameLinkingDocumnet.length > 0 ?
              [
                await this.getDocuments(this.model.nameLinkingDocumnet)
              ] : [{ text: 'No Document Provided', style: 'fieldValue' }],
            [
              {
                text: 'Supporting Identity Document(s)',
                style: 'subTitle',
                id: 'supportingIdentityDocuments'
              },
            ],
            this.model.supportingIdentityDocuments.length > 0 ?
              [
                await this.getDocuments(this.model.supportingIdentityDocuments)
              ] : [{ text: 'No Document Provided', style: 'fieldValue' }],
            // [
            //   {
            //     text: "",
            //     pageBreak: 'after'
            //   },  
            // ],
            [
              {
                text: 'Photo To Appear on the Secure Certificate of Indian Status(SCIS)',
                style: 'subTitle',
                id: 'photo'
              },

            ],
            this.model.photo ?
              [
                await this.getImage(this.model.photo.file_url)
              ] : [{ text: 'No photo uploaded', style: 'fieldValue' }],



          ]
        }
      },
    );
    return content;
  }

  async getContent2() {
    let content = [];
    content.push(

      {
        style: 'table',
        layout: 'noBorders',
        table: {
          widths: ['*', '40%', '*'],
          headerRows: 1,
          body: [
            [
              {
                columns: [
                  [
                    await this.getFlag()
                  ],
                  {
                    text: "Government of Canada",
                    style: 'headerText',

                  },
                  {
                    text: "Gouvernement du Canada",
                    style: 'headerText'
                  },
                ],
                columnGap: 5
              },
              {},
              {}
              // {
              //   columns: [
              //     {
              //       stack: [
              //         {
              //           text: 'Powered By',
              //           style: 'headerText',
              //           alignment: 'center',
              //           margin: [0, 0, 0, 5]
              //         },
              //         {
              //           text: 'MY NATION ',
              //           bold: true,
              //           style: 'headerText',
              //           alignment: 'center',
              //           //margin: [ 0, 0, 0, 5 ]
              //         },
              //         // {
              //         //   text: 'Connecting Your Nation',
              //         //   style: 'headerText',
              //         //   alignment: 'center',
              //         // },
              //       ],
              //     },
              //     [
              //       await this.getLogo()
              //     ],
              //   ],
              // }
            ],
          ]
        }
      },
      {
        stack: [
          {
            text: "APPLICATION FOR REGISTRATION ON THE INDIAN REGISTER AND FOR THE SECURE CERTIFICATE OF INDIAN STATUS (SCIS)",
            style: 'mainTitle'
          },
          {
            text: "(FOR ADULTS 16 YEARS OF AGE OR OLDER)",
            fontSize: 10,
            alignment: 'center'
          },
        ],
        margin: [0, 0, 0, 20]
      },
      {
        stack: [
          {
            text: "Privacy Act Statement",
            fontSize: 11,
            italics: true,
            bold: true,
            margin: [0, 0, 0, 10]
          },
          {
            text: [
              { text: 'This statement outlines the purposes for the collection and use of personal information. Only information needed to respond to program requirements will be requested. Collection, use, and disclosure of personal information are in accordance with the Privacy Act. In some cases, information may be disclosed without your consent pursuant to subsection 8(2) of the Privacy Act. The authority to collect and use personal information for the Indian Registration and the Secure Certificate of Indian Status programs is derived from the Indian Act. We use the personal information we collect to determine entitlement to registration in the Indian Register and membership in a First Nation for which the Band List is maintained by the Department, to issue a Secure Certificate of Indian Status to registered persons, and for the provision of benefits and services conferred exclusively to those who are registered. We may share the personal information you provide as outlined under Personal Information Bank AANDC PPU110 (Info Source ', style: 'paragraphText' },
              { text: 'https://www.sac-isc.gc.ca/ eng/1353081939455', link: 'https://www.sac-isc.gc.ca/ eng/1353081939455', color: 'blue', decoration: 'underline', fontSize: 10 },
              { text: '). The information collected is retained by the Department for 30 years after the last administrative action and then transferred to Library and Archives Canada (or as described in the Personal Information Bank). As stated in the Privacy Act, you have the right to access the personal information you give us and request changes to incorrect information. If you have questions or wish to notify us of incorrect information, you may call us at 1-800-567-9604. For more information on privacy issues and the Privacy Act in general, you can consult the Privacy Commissioner at 1-800-282-1376.', style: 'paragraphText' },
            ],
          },
          // {
          //   text: `This statement outlines the purposes for the collection and use of personal information. Only information needed to respond to program requirements will be requested. Collection, use, and disclosure of personal information are in accordance with the Privacy Act. In some cases, information may be disclosed without your consent pursuant to subsection 8(2) of the Privacy Act. The authority to collect and use personal information for the Indian Registration and the Secure Certificate of Indian Status programs is derived from the Indian Act. We use the personal information we collect to determine entitlement to registration in the Indian Register and membership in a First Nation for which the Band List is maintained by the Department, to issue a Secure Certificate of Indian Status to registered persons, and for the provision of benefits and services conferred exclusively to those who are registered. We may share the personal information you provide as outlined under Personal Information Bank AANDC PPU110 (Info Source https://www.sac-isc.gc.ca/ eng/1353081939455). The information collected is retained by the Department for 30 years after the last administrative action and then transferred to Library and Archives Canada (or as described in the Personal Information Bank). As stated in the Privacy Act, you have the right to access the personal information you give us and request changes to incorrect information. If you have questions or wish to notify us of incorrect information, you may call us at 1-800-567-9604. For more information on privacy issues and the Privacy Act in general, you can consult the Privacy Commissioner at 1-800-282-1376.`,
          //   style: 'paragraphText',
          // },
        ],
      },
      {
        stack: [
          {
            text: "NOTICE TO APPLICATS",
            fontSize: 11,
            bold: true,
            margin: [0, 10, 0, 10]
          },
          {
            text: `If you identify with an Indigenous group that is not recognized under the Indian Act (non-status), you may wish to consult with that group before proceeding with an application for registration for yourself, a minor child or dependent adult. Registration under the Indian Act in Canada may affect your entitlement to join or be recognized by some non-status groups and your entitlement to the programs and services they may offer. The Indian Registrar does not have the power to remove a name from the Indian Register if the individual has been correctly registered, even when the individual wishes to deregister.`,
            style: 'paragraphText',
            margin: [0, 0, 0, 5]
          },
          {
            text: `The Secure Certificate of Indian Status (SCIS) remains at all times the property of the Government of Canada and must only be used by the person in whose name it is issued. Any false or misleading statement with respect to this application and any supporting document, including the concealment of any material fact, selling or permitting the use of your SCIS by any other individual or agency may lead to criminal prosecution, and is cause for revocation of your SCIS and refusal to issue a SCIS in the future. Any false or misleading statement, including the concealment of any material fact, may lead to a review of your entitlement to registration and revocation of your registered Indian status.`,
            style: 'paragraphText',
            margin: [0, 10, 0, 5]
          },
        ],
      },
      {
        text: "",
        pageBreak: 'after'
      },

      {
        style: 'table',
        layout: 'lightHorizontalLines',
        table: {
          widths: ['*', '*', '*', '*'],
          body: [
            [
              {
                text: 'SECTION 1: Personal Information',
                style: 'title',
                colSpan: 4
              },
              {},
              {},
              {}
            ],
            [
              {
                stack: [
                  {
                    text: 'Family Name',
                    style: 'fieldName'
                  },
                  {
                    text: this.model.familyName,
                    style: 'fieldValue'
                  },
                ],
                colSpan: 2,
              },
              {},
              {
                stack: [
                  {
                    text: 'Given Name(s)',
                    style: 'fieldName'
                  },
                  {
                    text: this.model.givenName,
                    style: 'fieldValue'
                  },
                ],
                colSpan: 2
              },
              {}
            ],
            [
              {
                stack: [
                  {
                    text: 'Family Name at Birth',
                    style: 'fieldName'
                  },
                  {
                    text: this.model.birth_familyName,
                    style: 'fieldValue'
                  },
                ],
                colSpan: 2
              },
              {},
              {
                stack: [
                  {
                    text: 'Alias/Cultural Name',
                    style: 'fieldName'
                  },
                  {
                    text: this.model.aliasName,
                    style: 'fieldValue'
                  },
                ],
                colSpan: 2
              },
              {},
            ],
            [
              {
                stack: [
                  {
                    text: 'Gender',
                    style: 'fieldName'
                  },
                  {
                    text: this.model.gender,
                    style: 'fieldValue'
                  },
                ],
                colSpan: 2
              },
              {},
              {
                stack: [
                  {
                    text: 'Date of Birth',
                    style: 'fieldName'
                  },
                  {
                    text: this.model.dob ? this.model.dob : '',
                    style: 'fieldValue'
                  },
                ],
                colSpan: 2
              },
              {},
            ],
            [
              {
                text: 'Permanent Address',
                style: 'subTitle',
                colSpan: 4
              },
              {},
              {},
              {}
            ],
            [
              {
                stack: [
                  {
                    text: 'Number, Street, Apartment, P.O.Box',
                    style: 'fieldName'
                  },
                  {
                    text: this.model.number + " " + this.model.street + " " + this.model.apartment + " " + this.model.POBox,
                    style: 'fieldValue'
                  },
                ],
                colSpan: 4,
              },
              {},
              {},
              {}
            ],
            [
              {
                stack: [
                  {
                    text: 'City/Town',
                    style: 'fieldName'
                  },
                  {
                    text: this.model.city,
                    style: 'fieldValue'
                  },
                ],
                colSpan: 2,
              },
              {},
              {
                stack: [
                  {
                    text: this.country == 'CA' ? "Province/Territory" : 'State',
                    style: 'fieldName'
                  },
                  {
                    text: this.model.province_state,
                    style: 'fieldValue'
                  },
                ]
              },
              {
                stack: [
                  {
                    text: 'Postal/ZIP Code',
                    style: 'fieldName'
                  },
                  {
                    text: this.model.postalCode,
                    style: 'fieldValue'
                  },
                ],
              },
            ],
            [
              {
                stack: [
                  {
                    text: 'Telephone Number(Daytime)',
                    style: 'fieldName'
                  },
                  {
                    text: this.model.telephone,
                    style: 'fieldValue'
                  },
                ],
              },
              {
                stack: [
                  {
                    text: 'Telephone Number(Other)',
                    style: 'fieldName'
                  },
                  {
                    text: this.model.telephone2,
                    style: 'fieldValue'
                  },
                ]
              },
              {
                stack: [
                  {
                    text: 'Email Address',
                    style: 'fieldName'
                  },
                  {
                    text: this.model.email,
                    style: 'fieldValue'
                  },
                ],
                colSpan: 2
              },
              {},
            ],
            [
              {
                text: 'Mailing Address',
                style: 'subTitle',
                colSpan: 4
              },
              {},
              {},
              {}
            ],
            this.model.sameMailingAddress ?
              [
                {
                  text: 'Mailing Address Same as Permanent Address',
                  style: 'fieldValue',
                  colSpan: 4
                },
                {},
                {},
                {}
              ] :
              [
                {
                  stack: [
                    {
                      text: 'Number, Street, Apartment, P.O.Box',
                      style: 'fieldName'
                    },
                    {
                      text: this.model.mailing_number + " " + this.model.mailing_street + " " + this.model.mailing_apartment + " " + this.model.mailing_POBox,
                      style: 'fieldValue'
                    },
                  ],
                  colSpan: 4,
                },
                {},
                {},
                {}
              ],
            this.model.sameMailingAddress ?
              [{}, {}, {}, {}] :
              [
                {
                  stack: [
                    {
                      text: 'City/Town',
                      style: 'fieldName'
                    },
                    {
                      text: this.model.city,
                      style: 'fieldValue'
                    },
                  ],
                  colSpan: 2,
                },
                {},
                {
                  stack: [
                    {
                      text: this.country == 'CA' ? "Province/Territory" : 'State',
                      style: 'fieldName'
                    },
                    {
                      text: this.model.province_state,
                      style: 'fieldValue'
                    },
                  ]
                },
                {
                  stack: [
                    {
                      text: 'Postal/ZIP Code',
                      style: 'fieldName'
                    },
                    {
                      text: this.model.postalCode,
                      style: 'fieldValue'
                    },
                  ],
                },
              ]
          ]
        }
      },

      {
        style: 'table',
        layout: 'lightHorizontalLines',
        table: {
          widths: ['*', '*'],
          body: [
            [
              {
                text: 'SECTION 2: First Nation/Band Choice',
                style: 'title',
                colSpan: 2
              },
              {},
            ],
            [
              {
                stack: [
                  {
                    text: 'First Nation/Band Name',
                    style: 'fieldName'
                  },
                  {
                    text: this.model.bandName,
                    style: 'fieldValue'
                  },
                ],
              },
              {
                stack: [
                  {
                    text: 'First Nation/Band Number',
                    style: 'fieldName'
                  },
                  {
                    text: this.model.bandNumber,
                    style: 'fieldValue'
                  },
                ],
              },
            ],
          ]
        }
      },

      {
        style: 'table',
        layout: 'lightHorizontalLines',
        table: {
          widths: ['*', '*', '*'],
          body: [
            [
              {
                text: 'SECTION 3: Documnet Requirements',
                style: 'title',
                colSpan: 3
              },
              {},
              {},
            ],
            [
              {
                text: 'A. Proof of Birth Document',
                style: 'subTitle',
                colSpan: 3,
                //linkToDestination: 'proofOfBirthDocument',
              },
              {},
              {},
            ],

            (this.model.proofOfBirthDocument) ?
              [
                {
                  colSpan: 3,
                  margin: [0, 10, 0, 10],
                  table: {
                    widths: ['auto', '*', '*'],
                    body: [
                      [
                        {
                          text: 'Registration Number on Birth Documnet',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, true, true]
                        },
                        {
                          text: this.country == 'CA' ? "Province/Territory" : 'State',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, true, true]
                        },
                        {
                          text: 'Document',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, false, true]
                        },
                      ],
                      [
                        {
                          text: this.model.proofOfBirthDocument.registrationNo,
                          style: 'fieldValue',
                          border: [false, false, true, true]
                        },
                        {
                          text: this.model.proofOfBirthDocument.province_state,
                          style: 'fieldValue',
                          border: [false, false, true, true]
                        },
                        {
                          text: this.model.proofOfBirthDocument.file_name,
                          link: this.model.proofOfBirthDocument.file_url,
                          style: 'links'
                        },
                        // {
                        //   text: this.model.proofOfBirthDocument.file_name,
                        //   // linkToDestination: 'proofOfBirthDocument',
                        //   style: ['fieldValue']
                        // }
                      ],

                    ]
                  }
                }, {}, {}
              ] :
              [
                {
                  text: 'No Documnets Provided',
                  style: 'fieldValue',
                  colSpan: 3
                },
                {},
              ],

            [
              {
                text: 'B. Name Linking Document(s)',
                style: 'subTitle',
                colSpan: 3,
                //linkToDestination: 'nameLinkingDocumnet',
              },
              {},
              {},
            ],
            (this.model.nameLinkingDocumnet.length > 0) ?
              [
                {
                  colSpan: 3,
                  margin: [0, 10, 0, 10],
                  table: {
                    widths: ['auto', '*', '*'],
                    body: [
                      [
                        {
                          text: 'Name',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, true, true]
                        },
                        {
                          text: 'Document Type',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, true, true]
                        },
                        {
                          text: 'Document',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, false, true]
                        },
                      ],
                      ...this.model.nameLinkingDocumnet.map(doc => {
                        return [
                          { text: doc.documentName, style: 'fieldValue', border: [false, false, true, true] },
                          { text: doc.documentType, style: 'fieldValue', border: [false, false, true, true] },
                          {
                            text: doc.file_name,
                            link: doc.file_url,
                            style: 'links',
                            //style: 'fieldValue',
                            border: [false, false, false, true]
                          },];
                      })
                    ]
                  }
                }, {}, {}
              ] : [
                {
                  text: 'No Documnets Provided',
                  style: 'fieldValue',
                  colSpan: 3
                },
                {},
              ],
            [
              {
                text: 'C. Supporting Identity Document(s)',
                style: 'subTitle',
                colSpan: 3,
                //linkToDestination: 'supportingIdentityDocuments'
              },
              {},
              {},
            ],
            (this.model.supportingIdentityDocuments.length > 0) ?
              [
                {
                  colSpan: 3,
                  margin: [0, 10, 0, 10],
                  table: {
                    widths: ['auto', '*', '*', '*', '*'],
                    body: [
                      [
                        {
                          text: 'Name',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, true, true]
                        },
                        {
                          text: 'Document Type',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, true, true]
                        },
                        {
                          text: 'Document Number',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, true, true]
                        },
                        {
                          text: 'Expiry Date',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, true, true]
                        },
                        {
                          text: 'Document',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, false, true]
                        },
                      ],
                      ...this.model.supportingIdentityDocuments.map(doc => {
                        return [
                          { text: doc.documentName, style: 'fieldValue', border: [false, false, true, true] },
                          { text: doc.documentType, style: 'fieldValue', border: [false, false, true, true] },
                          { text: doc.documentNumber, style: 'fieldValue', border: [false, false, true, true] },
                          { text: doc.expiryDate, style: 'fieldValue', border: [false, false, true, true] },
                          {
                            text: doc.file_name,
                            //style: 'fieldValue',
                            link: doc.file_url,
                            style: 'links',
                            border: [false, false, false, true]
                          },];
                      })
                    ]
                  }
                }, {}, {}
              ] : [
                {
                  text: 'No Documnets Provided',
                  style: 'fieldValue',
                  colSpan: 3
                },
                {},
              ]
          ]
        }
      },

      {
        style: 'table',
        layout: 'lightHorizontalLines',
        table: {
          widths: ['*', '*', '*'],
          body: [
            [
              {
                text: 'SECTION 4: Adoption Information',
                style: 'title',
                colSpan: 3
              },
              {},
              {}
            ],
            (this.model.adopted) ?
              [
                {
                  colSpan: 3,
                  margin: [0, 10, 0, 10],
                  table: {
                    widths: ['*', '*', '*', '*'],
                    body: [
                      [
                        {
                          text: 'I was adopted as a child, and I believe that I have entitlement to Indian status through',
                          style: 'fieldName',
                          colSpan: 4,
                          border: [false, false, false, true]
                        },
                        {},
                        {},
                        {}
                      ],
                      [
                        {
                          stack: [
                            {
                              text: 'My Adoptive Mother ',
                              style: 'fieldName'
                            },
                            {
                              text: this.model.ISNEntitlement.adoptiveMother ? 'Yes' : 'NO',
                              style: 'fieldValue'
                            },
                          ],
                          border: [false, false, true, true]
                        },
                        {
                          stack: [
                            {
                              text: 'My Adoptive Father ',
                              style: 'fieldName'
                            },
                            {
                              text: this.model.ISNEntitlement.adoptiveFather ? 'Yes' : 'NO',
                              style: 'fieldValue'
                            },
                          ],
                          border: [false, false, true, true]
                        },
                        {
                          stack: [
                            {
                              text: 'My Birth Mother ',
                              style: 'fieldName'
                            },
                            {
                              text: this.model.ISNEntitlement.birthMother ? 'Yes' : 'NO',
                              style: 'fieldValue'
                            },
                          ],
                          border: [false, false, true, true]
                        },
                        {
                          stack: [
                            {
                              text: 'My Adoptive Father ',
                              style: 'fieldName'
                            },
                            {
                              text: this.model.ISNEntitlement.birthFather ? 'Yes' : 'NO',
                              style: 'fieldValue'
                            },
                          ],
                          border: [false, false, false, true]
                        },
                      ],
                      [
                        {
                          text: 'Adoptive Mother',
                          style: 'subTitle',
                          colSpan: 4,
                          border: [false, false, false, true]
                        },
                        {},
                        {},
                        {}
                      ],

                      [
                        {
                          stack: [
                            {
                              text: 'Family Name ',
                              style: 'fieldName'
                            },
                            {
                              text: this.model.adoptiveMother.familyName,
                              style: 'fieldValue'
                            },
                          ],
                          border: [false, false, true, true]
                        },
                        {
                          stack: [
                            {
                              text: 'Given Name ',
                              style: 'fieldName'
                            },
                            {
                              text: this.model.adoptiveMother.givenName,
                              style: 'fieldValue'
                            },
                          ],
                          border: [false, false, true, true]
                        },
                        {
                          stack: [
                            {
                              text: 'Birth Date ',
                              style: 'fieldName'
                            },
                            {
                              text: this.model.adoptiveMother.dob,
                              style: 'fieldValue'
                            },
                          ],
                          border: [false, false, false, true],
                          colSpan: 2
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Adoptive Father',
                          style: 'subTitle',
                          colSpan: 4,
                          border: [false, false, false, true]
                        },
                        {},
                        {},
                        {}
                      ],
                      [
                        {
                          stack: [
                            {
                              text: 'Family Name ',
                              style: 'fieldName'
                            },
                            {
                              text: this.model.adoptiveFather.familyName,
                              style: 'fieldValue'
                            },
                          ],
                          border: [false, false, true, true]
                        },
                        {
                          stack: [
                            {
                              text: 'Given Name ',
                              style: 'fieldName'
                            },
                            {
                              text: this.model.adoptiveFather.givenName,
                              style: 'fieldValue'
                            },
                          ],
                          border: [false, false, true, true]
                        },
                        {
                          stack: [
                            {
                              text: 'Birth Date ',
                              style: 'fieldName'
                            },
                            {
                              text: this.model.adoptiveFather.dob,
                              style: 'fieldValue'
                            },
                          ],
                          border: [false, false, false, true],
                          colSpan: 2
                        },
                        {},
                      ],
                      [
                        {
                          text: 'Birth Mother',
                          style: 'subTitle',
                          colSpan: 4,
                          border: [false, false, false, true]
                        },
                        {},
                        {},
                        {}
                      ],
                      [
                        {
                          stack: [
                            {
                              text: 'Family Name ',
                              style: 'fieldName'
                            },
                            {
                              text: this.model.birthMother.familyName,
                              style: 'fieldValue'
                            },
                          ],
                          border: [false, false, true, true],
                          colSpan: 2
                        },
                        {},
                        {
                          stack: [
                            {
                              text: 'Given Name ',
                              style: 'fieldName'
                            },
                            {
                              text: this.model.birthMother.givenName,
                              style: 'fieldValue'
                            },
                          ],
                          border: [false, false, false, true],
                          colSpan: 2
                        },
                        {}
                      ],

                      [
                        {
                          text: 'Birth Father',
                          style: 'subTitle',
                          colSpan: 4,
                          border: [false, false, false, true]
                        },
                        {},
                        {},
                        {}
                      ],
                      [
                        {
                          stack: [
                            {
                              text: 'Family Name ',
                              style: 'fieldName'
                            },
                            {
                              text: this.model.birthFather.familyName,
                              style: 'fieldValue'
                            },
                          ],
                          border: [false, false, true, true],
                          colSpan: 2
                        },
                        {},
                        {
                          stack: [
                            {
                              text: 'Given Name ',
                              style: 'fieldName'
                            },
                            {
                              text: this.model.birthFather.givenName,
                              style: 'fieldValue'
                            },
                          ],
                          border: [false, false, false, true],
                          colSpan: 2
                        },
                        {}
                      ],
                    ]
                  }
                }, {}, {}
              ] :

              [
                {
                  text: 'Not Adopted',
                  style: 'fieldValue',
                  colSpan: 3
                },
                {},
                {}
              ],
          ]
        }
      },

      {
        style: 'table',
        layout: 'lightHorizontalLines',
        table: {
          widths: ['*', '*', '*'],
          body: [
            [
              {
                text: 'SECTION 5: Family Information',
                style: 'title',
                colSpan: 3
              },
              {},
              {}
            ],
            [
              {
                text: 'A. Father',
                style: 'subTitle',
                colSpan: 3
              },
              {},
              {}
            ],
            [
              {
                stack: [
                  {
                    text: 'Family Name',
                    style: 'fieldName'
                  },
                  {
                    text: this.model.father.familyName,
                    style: 'fieldValue'
                  },
                ],
              },
              {
                stack: [
                  {
                    text: 'Given Name(s)',
                    style: 'fieldName'
                  },
                  {
                    text: this.model.father.givenName,
                    style: 'fieldValue'
                  },
                ],
              },
              {
                stack: [
                  {
                    text: 'Family Name at Birth',
                    style: 'fieldName'
                  },
                  {
                    text: this.model.father.birth_familyName,
                    style: 'fieldValue'
                  },
                ],
              },
            ],
            [
              {
                stack: [
                  {
                    text: 'Date of Birth',
                    style: 'fieldName'
                  },
                  {
                    text: this.model.father.dob,
                    style: 'fieldValue'
                  },
                ],
              },
              {
                stack: [
                  {
                    text: 'First Nation/Band Name',
                    style: 'fieldName'
                  },
                  {
                    text: this.model.father.bandName,
                    style: 'fieldValue'
                  },
                ],
              },
              {
                stack: [
                  {
                    text: 'First Nation/Band Number',
                    style: 'fieldName'
                  },
                  {
                    text: this.model.father.bandNumber,
                    style: 'fieldValue'
                  },
                ],
              },
            ],
            [
              {
                stack: [
                  {
                    text: 'Adopted',
                    style: 'fieldName'
                  },
                  {
                    text: this.model.father.adopted,
                    style: 'fieldValue'
                  },
                ],
              },
              {
                stack: [
                  {
                    text: 'Parent stated on the Birth Document',
                    style: 'fieldName'
                  },
                  {
                    text: this.model.father.parentStated ? 'Yes' : 'No',
                    style: 'fieldValue'
                  },
                ],
                colSpan: 2
              },
              {},
            ],
            [
              {
                stack: [
                  {
                    text: 'Registred',
                    style: 'fieldName'
                  },
                  {
                    text: this.model.father.registred ? 'yes' : 'No',
                    style: 'fieldValue'
                  },
                ],
                colSpan: this.model.father.registred ? 1 : 3
              },
              this.model.father.registred ?
                {
                  stack: [
                    {
                      text: 'Registration Number',
                      style: 'fieldName'
                    },
                    {
                      text: this.model.father.registrationNumber,
                      style: 'fieldValue'
                    },
                  ],
                  colSpan: 2
                } : {},
              {},
            ],
            [
              {
                text: 'B. Mother',
                style: 'subTitle',
                colSpan: 3
              },
              {},
              {}
            ],
            [
              {
                stack: [
                  {
                    text: 'Family Name',
                    style: 'fieldName'
                  },
                  {
                    text: this.model.mother.familyName,
                    style: 'fieldValue'
                  },
                ],
              },
              {
                stack: [
                  {
                    text: 'Given Name(s)',
                    style: 'fieldName'
                  },
                  {
                    text: this.model.mother.givenName,
                    style: 'fieldValue'
                  },
                ],
              },
              {
                stack: [
                  {
                    text: 'Family Name at Birth',
                    style: 'fieldName'
                  },
                  {
                    text: this.model.mother.birth_familyName,
                    style: 'fieldValue'
                  },
                ],
              },
            ],
            [
              {
                stack: [
                  {
                    text: 'Date of Birth',
                    style: 'fieldName'
                  },
                  {
                    text: this.model.mother.dob,
                    style: 'fieldValue'
                  },
                ],
              },
              {
                stack: [
                  {
                    text: 'First Nation/Band Name',
                    style: 'fieldName'
                  },
                  {
                    text: this.model.mother.bandName,
                    style: 'fieldValue'
                  },
                ],
              },
              {
                stack: [
                  {
                    text: 'First Nation/Band Number',
                    style: 'fieldName'
                  },
                  {
                    text: this.model.mother.bandNumber,
                    style: 'fieldValue'
                  },
                ],
              },
            ],
            [
              {
                stack: [
                  {
                    text: 'Adopted',
                    style: 'fieldName'
                  },
                  {
                    text: this.model.mother.adopted,
                    style: 'fieldValue'
                  },
                ],
              },
              {
                stack: [
                  {
                    text: 'Registred',
                    style: 'fieldName'
                  },
                  {
                    text: this.model.mother.registred ? 'yes' : 'No',
                    style: 'fieldValue'
                  },
                ],
                colSpan: this.model.mother.registred ? 1 : 2
              },
              this.model.mother.registred ?
                {
                  stack: [
                    {
                      text: 'Registration Number',
                      style: 'fieldName'
                    },
                    {
                      text: this.model.mother.registrationNumber,
                      style: 'fieldValue'
                    },
                  ],
                } : {},
            ],
            [
              {
                text: 'C. Maternal Grandparents',
                style: 'subTitle',
                colSpan: 3
              },
              {},
              {}
            ],
            (this.model.maternal_grandparents.length > 0) ?
              [
                {
                  colSpan: 3,
                  margin: [0, 10, 0, 10],
                  table: {
                    widths: ['auto', '*', '*', '*', '*', '*', '*', '*', '*'],
                    body: [
                      [
                        {
                          text: '',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, true, true]
                        },
                        {
                          text: 'Family Name',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, true, true]
                        },
                        {
                          text: 'Given Name(s)',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, true, true]
                        },
                        {
                          text: 'Family Name at Birth',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, true, true]
                        },
                        {
                          text: 'Date of Birth',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, true, true]
                        },
                        {
                          text: 'First Nation/Band Number',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, true, true]
                        },
                        {
                          text: 'First Nation/Band Name',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, true, true]
                        },
                        {
                          text: 'Registration Number',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, true, true]
                        },
                        {
                          text: 'Adopted',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, false, true]
                        },
                      ],
                      ...this.model.maternal_grandparents.map(person => {
                        return [
                          { text: person.type, style: 'fieldValue', border: [false, false, true, true] },
                          { text: person.familyName, style: 'fieldValue', border: [false, false, true, true] },
                          { text: person.givenName, style: 'fieldValue', border: [false, false, true, true] },
                          { text: person.birth_familyName, style: 'fieldValue', border: [false, false, true, true] },
                          { text: person.dob, style: 'fieldValue', border: [false, false, true, true] },
                          { text: person.bandNumber, style: 'fieldValue', border: [false, false, true, true] },
                          { text: person.bandName, style: 'fieldValue', border: [false, false, true, true] },
                          { text: person.registred ? person.registrationNumber : '', style: 'fieldValue', border: [false, false, true, true] },
                          { text: person.adopted, style: 'fieldValue', border: [false, false, false, true] },];
                      })
                    ]
                  }
                }, {}, {}
              ] : [
                {
                  text: 'No Maternal Grandparents Added.',
                  style: 'fieldValue',
                  colSpan: 3
                },
                {},
              ],
            [
              {
                text: 'D. Paternal Grandparents',
                style: 'subTitle',
                colSpan: 3
              },
              {},
              {}
            ],
            (this.model.paternal_grandparents.length > 0) ?
              [
                {
                  colSpan: 3,
                  margin: [0, 10, 0, 10],
                  table: {
                    widths: ['auto', '*', '*', '*', '*', '*', '*', '*', '*'],
                    body: [
                      [
                        {
                          text: '',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, true, true]
                        },
                        {
                          text: 'Family Name',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, true, true]
                        },
                        {
                          text: 'Given Name(s)',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, true, true]
                        },
                        {
                          text: 'Family Name at Birth',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, true, true]
                        },
                        {
                          text: 'Date of Birth',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, true, true]
                        },
                        {
                          text: 'First Nation/Band Number',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, true, true]
                        },
                        {
                          text: 'First Nation/Band Name',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, true, true]
                        },
                        {
                          text: 'Registration Number',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, true, true]
                        },
                        {
                          text: 'Adopted',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, false, true]
                        },
                      ],
                      ...this.model.paternal_grandparents.map(person => {
                        return [
                          { text: person.type, style: 'fieldValue', border: [false, false, true, true] },
                          { text: person.familyName, style: 'fieldValue', border: [false, false, true, true] },
                          { text: person.givenName, style: 'fieldValue', border: [false, false, true, true] },
                          { text: person.birth_familyName, style: 'fieldValue', border: [false, false, true, true] },
                          { text: person.dob, style: 'fieldValue', border: [false, false, true, true] },
                          { text: person.bandNumber, style: 'fieldValue', border: [false, false, true, true] },
                          { text: person.bandName, style: 'fieldValue', border: [false, false, true, true] },
                          { text: person.registred ? person.registrationNumber : '', style: 'fieldValue', border: [false, false, true, true] },
                          { text: person.adopted, style: 'fieldValue', border: [false, false, false, true] },];
                      })
                    ]
                  }
                }, {}, {}
              ] : [
                {
                  text: 'No Paternal Grandparents Added.',
                  style: 'fieldValue',
                  colSpan: 3
                },
                {},
              ],
            [
              {
                text: 'Additional Family Information',
                style: 'subTitle',
                colSpan: 3
              },
              {},
              {}
            ],
            (this.model.other_familyMembers.length > 0) ?
              [
                {
                  colSpan: 3,
                  margin: [0, 10, 0, 10],
                  table: {
                    widths: ['*', '*', '*'],
                    body: [
                      [
                        {
                          text: 'Family Name',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, true, true]
                        },
                        {
                          text: 'Given Name(s)',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, true, true]
                        },
                        {
                          text: 'Relationship',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, false, true]
                        },
                      ],
                      ...this.model.other_familyMembers.map(person => {
                        return [

                          { text: person.familyName, style: 'fieldValue', border: [false, false, true, true] },
                          { text: person.givenName, style: 'fieldValue', border: [false, false, true, true] },
                          { text: person.relationship, style: 'fieldValue', border: [false, false, false, true] },];
                      })
                    ]
                  }
                }, {}, {}
              ] : [
                {
                  text: 'No Additional Family Infomation Added.',
                  style: 'fieldValue',
                  colSpan: 3
                },
                {},
              ],
          ]
        }
      },

      {
        style: 'table',
        layout: 'lightHorizontalLines',
        table: {
          widths: ['*', '*'],
          body: [
            [
              {
                text: 'SECTION 6: Photo And Signature',
                style: 'title',
                colSpan: 2
              },
              {},
            ],
            [
              {
                text: 'SCIS Requested ',
                style: 'fieldName'
              },
              {
                text: this.model.SCISRequested ? 'Yes' : 'No',
                style: 'fieldValue'
              },
            ],

            this.model.SCISRequested ?
              [
                {
                  colSpan: 2,
                  margin: [0, 10, 0, 10],
                  table: {
                    widths: ['*', '*', '*', '*'],
                    body: [
                      [{
                        text: 'Photo To Appear on the Secure Certificate of Indian Status(SCIS)',
                        style: ['subTitle', 'link'],
                        linkToDestination: 'photo',
                        border: [false, false, false, true],
                        colSpan: 4
                      }, {}, {}, {}],
                      [
                        {
                          text: 'Name of the studio or Person Who took the photo',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, true, true]
                        },
                        {
                          text: "Address of Studio or Person Who took the photo",
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, true, true]
                        },
                        {
                          text: 'The Date the photo was taken',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, true, true]
                        },
                        {
                          text: 'Photo',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, false, true]
                        },
                      ],
                      [
                        {
                          text: this.model.photoBy,
                          style: 'fieldValue',
                          border: [false, false, true, true]
                        },
                        {
                          text: this.model.photoAddress,
                          style: 'fieldValue',
                          border: [false, false, true, true]
                        },
                        {
                          text: this.model.photoDate,
                          style: 'fieldValue',
                          border: [false, false, true, true]
                        },
                        this.model.photo ?
                          {
                            text: this.model.photo.file_name,
                            link: this.model.photo.file_url,
                            style: 'links',
                            //style: 'fieldValue',
                            border: [false, false, false, true]
                          } : { text: 'No photo was uploaded', style: 'fieldValue' },
                      ],
                    ]
                  }
                }, {}
              ] :
              [
                {
                  text: 'Photo to Appear on the Secure Certificate of Indian Status(SCIS)',
                  style: 'subTitle',
                },
                {
                  text: 'No Photo was Uploaded',
                  style: 'fieldValue',
                },
              ],
            [
              {
                text: 'Signature to Appear on the Secure Certificate of Indian Status(SCIS)',
                style: 'subTitle',
              },
              {
                image: this.model.signature,
                width: 250,
              },
            ],
          ]
        }
      },

      {
        style: 'table',
        table: {
          widths: ['*', '*'],
          body: [
            [
              {
                text: 'SECTION 7: Declaration and Signature',
                style: 'title',
                colSpan: 2,
                border: [false, false, false, true]
              },
              {},
            ],
            [
              {
                text: [
                  { text: 'I solemnly declare that all statements made in this application are true, all documents provided to support this application are unaltered, and the enclosed photographs (if requesting a Secure Certificate of Indian Status) are a true likeness of me. I have read and understand the Notice to Applicants and the Privacy Act Statement.', style: 'fieldName' },
                  { text: 'Privacy Act Statement.', italics: true },
                ],
                colSpan: 2,
                border: [false, false, false, false]
              },
              {}
            ],
            [
              {
                text: [

                  { text: 'If eligible I ', style: 'fieldName' },
                  { text: this.model.applicantName, style: 'fieldValue' },
                  { text: ' to be registered in the Indian Register and, if applicable, to have my name entered on a First Nation/Band List, as provided for under the Indian Act. I further request to have a Secure Certificate of Indian Status issued to me (if applicable). ', style: 'fieldName' }
                ],
                colSpan: 2,
                border: [false, false, false, true]
              },
              {}
            ],
            [
              {
                text: 'Signature',
                style: 'subTitle',
              },
              {
                image: this.model.signature,
                width: 100,
              },
            ],
            [
              {
                text: 'Date',
                style: 'fieldName',
              },
              {
                text: this.model.signatureDate,
                style: 'fieldValue'
              },
            ],

          ]
        }
      },

      {
        style: 'table',
        layout: 'lightHorizontalLines',
        table: {
          widths: ['*', '*'],
          body: [
            [
              {
                text: 'SECTION 7: Indian Registration Administrator(IRA)',
                style: 'title',
                colSpan: 2
              },
              {},
            ],
            [
              {
                stack: [
                  {
                    text: 'IRA Assisted in Completing this form',
                    style: 'fieldName'
                  },
                  {
                    text: this.model.IRA_assisted ? 'Yes' : 'No',
                    style: 'fieldValue'
                  },
                ],
                colSpan: 2
              },
              {},
            ],
            this.model.IRA_assisted ?
              [
                {
                  stack: [
                    {
                      text: 'IRA Name',
                      style: 'fieldName'
                    },
                    {
                      text: this.model.IRA_name,
                      style: 'fieldValue'
                    },
                  ],
                },
                {
                  stack: [
                    {
                      text: 'IRA First Nation/Band Number or Initiator Code',
                      style: 'fieldName'
                    },
                    {
                      text: this.model.bandNo_initiatorCode,
                      style: 'fieldValue'
                    },
                  ],
                },
              ] : [{}, {}],
          ]
        }
      },

      {
        text: "",
        pageBreak: 'after'
      },
    );

    return content;
  }


  async getDocuments(documents) {
    let content = [];
    if (documents.length > 0) {
      for (let i = 0; i < documents.length; i++) {

        content.push(
          {
            style: 'table',
            layout: 'lightHorizontalLines',
            table: {
              widths: ['*'],
              body: [
                [
                  {
                    text: documents[i].documentType,
                    style: 'fieldValue',
                  },

                ],
                this.model.nameLinkingDocumnet[i] ?
                  [
                    await this.getImage(documents[i].file_url),
                  ] : [{ text: 'Document can not be displayed', style: 'fieldValue' }],
              ]
            }
          },

        )

      }

    } else {
      content.push(
        {
          text: 'No Document Provided',
          style: 'subTitle',
        },
      )
    }

    return content;


  }

  async getFlag() {
    let imageLink = "assets/img/flag.png";
    const image = await this.getBase64FromUrl(imageLink);
    if (image) {
      return {
        image: image,
        width: 40,
        alignment: 'left'
      }
    }

  }

  async getLogo() {
    let imageLink = "assets/img/Nation-logo.png";
    const image = await this.getBase64FromUrl(imageLink);
    if (image) {
      return {
        image: image,
        width: 40,
        alignment: 'left'
      }
    }
  }

  async getImage(file_url) {
    let imageLink = file_url;
    //let imageLink = 'https://api.mysabenefits.ca/Media/Demo/Images/b5b45384-5a7e-4ef3-87c5-f620c5e8cbb4-empty.jpg';
    //let imageLink = "https://api.mysabenefits.ca/Media/Demo/PDF/f611e3ae-fd98-4c9a-9546-e39cbec3d340-Test-pdf.pdf";
    const image = await this.getBase64ImageFromURL(imageLink);
    if (image) {
      return {
        image: image,
        //width: 40,
        alignment: 'left'
      }
    }

  }

  getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      }
    });
  }

  getStyles() {
    return {
      headerText: {
        fontSize: 8,
      },
      mainTitle: {
        bold: true,
        fontSize: 14,
        alignment: 'center',
      },
      paragraphText: {
        fontSize: 10,
        alignment: 'justify',
      },
      title: {
        bold: true,
        fontSize: 12,
        alignment: 'left',
        margin: [0, 10, 0, 10]
      },
      subTitle: {
        fontSize: 10,
        bold: true,
        alignment: 'left',
        margin: [0, 5, 0, 5]
      },
      fieldName: {
        fontSize: 10,
        alignment: 'left',
        //bold: true,
        margin: [0, 3, 0, 3]
      },
      fieldValue: {
        fontSize: 10,
        alignment: 'left',
        color: 'blue'
      },
      links: {
        fontSize: 10,
        color: 'blue',
        decoration: 'underline',
        //italics: true,
        //alignment: 'right',
      },
      warning: {
        fontSize: 12,
        color: 'red',
        alignment: 'center',
      },
      normalText: {
        fontSize: 10,
        alignment: 'justify',
      },

      header: {
        fontSize: 12,
        bold: true,
        alignment: 'left',
      },

      table: {
        margin: [10, 5, 0, 10]
      },
      fields: {
        color: 'blue',
        fontSize: 10,
      }
    }
  }

  async downloadReport() {
    const documentDefinition = await this.getReport();
    pdfMake.createPdf(documentDefinition).download(this.fileName);
  }

  async openReport() {
    const documentDefinition = await this.getReport();
    pdfMake.createPdf(documentDefinition).open();
  }

  async printReport() {
    const documentDefinition = await this.getReport();
    pdfMake.createPdf(documentDefinition).print();
  }

  getSupportingDocuments() {
    this.fileList.push(this.getAttachment(this.model.proofOfBirthDocument));
    this.model.nameLinkingDocumnet.forEach(document => {
      this.fileList.push(this.getAttachment(document));
    });
    this.model.supportingIdentityDocuments.forEach(document => {
      this.fileList.push(this.getAttachment(document));
    })
  }

  getAttachment(file) {
    let att = new Attachment();
    att.url = file.file_url;
    att.name = file.file_name;
    att.type = file.file_type;
    return att;
  }

  async sendReport(status) {
    this.fileList = [];
    this.getSupportingDocuments();
    const documentDefinition = await this.getReport();
    const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
    pdfDocGenerator.getBlob((blob) => {
      this.reportFile = new File([blob], this.fileName, {
        type: blob.type
      });
      this.mediaService.uploadPDF(this.reportFile).subscribe(upload => {
        if (upload) {
          if (upload.imgLink) this.fileLink = upload.imgLink
          //this.fileLink = "https://api.mysabenefits.ca/Media/Demo/PDF/0de131ee-7909-44cc-bbbc-8e12fb611dd4-Houses.pdf";
          let report = new Attachment();
          report.url = this.fileLink;
          report.name = this.fileName;
          report.type = 'PDF';
          this.fileList.push(report);
          this.sendEmail = true;
        }
      });
    });
  }
}
