<div class="container">
  <div class="row col s12 p-m-1">
    <div class="col l2"></div>

    <div id="step0" class="col s12 l8">
      <form (ngSubmit)="submitForm(dialogForm.value)" [formGroup]="dialogForm">

        <div class="row card-panel">
          <div class="row col s12 center">
            <h5><b>Welcome to Oteenow Web Application </b></h5>
            <b>https://oteenow.com/</b>
          </div>

          <div class="row col s12"> </div>
          <div class="row col s12"> </div>

          <div class="row col s12">
            <div class="col s6">
              <b><span class="green-text">Former or Current</span></b>
            </div>

            <div class="col s6">
              <button type="button" [ngClass]="'col s12 btn waves-effect blue' + themeColor "
                (click)="RegisterClicked()"> Register
              </button>
            </div>
          </div>

          <div class="row col s12"> </div>

          <div class="row col s12">
            <div class="col s6">
              <b><span class="blue-text">New Client</span></b>
            </div>

            <div class="col s6">
              <button type="button" [ngClass]="'col s12 btn waves-effect blue' + themeColor " (click)="ApplyClicked()">
                Apply
              </button>
            </div>
          </div>
          <div class="row col s12"> </div>
        </div>
      </form>
      <div class="section"></div>
    </div>
    <div class="col l2 "></div>
  </div>
</div>