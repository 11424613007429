import { Component, OnInit } from '@angular/core';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { SocialMediaService } from 'src/app/services/firebase/social-media.service';

@Component({
  selector: 'app-social-media-links',
  templateUrl: './social-media-links.component.html',
  styleUrls: ['./social-media-links.component.css']
})

export class SocialMediaLinksComponent implements OnInit {

  model: any;
  socialMediaSection: any;
  socialMediaModel: any[];

  constructor(private appSettingService: AppSettingsService,
    private socialService: SocialMediaService) { }

  ngOnInit(): void {
    this.appSettingService.getHomePageSettings().valueChanges().subscribe(hp => {
      if (hp) {
        if (hp.length > 0) {
          this.model = hp[0];
          const socialSection = this.model.sections.filter(s => s.name === 'SocialMedia' && s.isEnabled);
          if (socialSection) {
            this.socialMediaSection = socialSection;
            this.socialService.getSocialMedia().valueChanges().subscribe(n => {
              if (n) {
                if (n.length > 0) this.socialMediaModel = n[0];
              }
            });
          }
        }
      }
    });
  }

}
