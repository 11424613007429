import { Injectable } from "@angular/core";
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from "@angular/fire/compat/firestore";
import { AuthService } from 'src/app/services/firebase/auth.service';
import { WallPostModel } from 'src/app/models/WallPostModel';
import{ PostCommentModel } from 'src/app/models/PostCommentModel';

@Injectable({
	providedIn: "root"
})

export class NationWallService{

	constructor(private db: AngularFirestore, private authService: AuthService){}
    
	contentref: AngularFirestoreCollection<any>;
    content: AngularFirestoreDocument<any>;
    
    addWallPost(model: WallPostModel) {
        const wallPost = {
            id : this.db.createId(),
            created_member_id : this.authService.loggedInUser.uid,
            created_member_give_name: this.authService.loggedInUser.GivenName,
            name: model.name,
            created_date : new Date()
        };
        return this.db.collection('WallPost').doc(wallPost.id).set(wallPost);
    }

    addCommentToPost(model: PostCommentModel){
        const data = {
            id: this.db.createId(),
            member_id : this.authService.loggedInUser.uid,
            member_name: this.authService.loggedInUser.GivenName,
            wall_post_id: model.wall_post_id,
            comment: model.comment,
            created_date: new Date().getTime()
        };
        return this.db.collection("PostComment").doc(data.id).set(data);
    }

    getNationWallPostList(): AngularFirestoreCollection<any[]>{
        return this.db.collection("WallPost");
    }

    getWallPostById(id: string): AngularFirestoreDocument<any>{
        return this.db.doc("WallPost/"+id);
    }

    getWallPostComments(wall_post_id): AngularFirestoreCollection<any[]>{
        return this.db.collection("PostComment", ref => ref.where("wall_post_id", "==", wall_post_id));
    }

    DeleteWallPost(wall_post_id) {
        return this.db.collection('WallPost').doc(wall_post_id).delete();
    }

    DeleteWallPostComment(comment_id) {
        return this.db.collection('PostComment').doc(comment_id).delete();
    }

    updateLikeUnlikeMemberInWallPost(model: any) {
        const newContent = { liked_members: model.liked_members };
        this.db.collection('WallPost').doc(model.id).update(newContent);
    }
}