<div class="section"></div>

<div class="container">
    <div class="row col s12">
        <app-firebase-loading *ngIf="!nation_wall_posts"></app-firebase-loading>
        <div class="col l2"></div>
        <div class="col s12 l8" *ngIf="nation_wall_posts">
            <div *ngFor="let nation_wall_post of nation_wall_posts; let i = index">
                <div>
                    <div class="card">
                        <div class="row col s12">
                            <div class="section"></div>
                            <span>
                                <strong style="display: inline-flex;">
                                    <span>
                                        <span style="display: inline-flex; vertical-align: top;">
                                            <i class="material-icons green-text">keyboard_arrow_right</i> {{nation_wall_post.created_member_give_name}}
                                        </span>
                                        <br />
                                        <small class="grey-text" style="margin-left:1.5px ;"> <strong> {{nation_wall_post.created_date.toDate()  | DateAgo}} </strong></small>
                                        <br />
                                        <p>Likes: {{ nation_wall_post.liked_count }}</p>
                                    </span>
                                    <span style="display: inline-flex; vertical-align: top;">
                                    <i class="material-icons blue-text">keyboard_arrow_right</i>
                                    <span [innerHTML]="nation_wall_post.name"> </span>
                                    </span>
                                </strong>
                            </span>
                        </div>

                        <div class="row card-content">
                            <!-- <div class="row col s12">Description</div> -->
                            <span class=" blue-text">
                                <a [routerLink]="['/member/view-nation-wall-post', nation_wall_post.id]" class="">
                                    <strong class="activator">Read More</strong>
                                </a>
                                <a class="btn-floating btn-small waves-effect blue right" *ngIf="nation_wall_post.liked" (click)="likeUnlike(nation_wall_post.id, i)"><i class="material-icons">thumb_down</i></a>

                                <a class="btn-floating btn-small waves-effect blue right" *ngIf="!nation_wall_post.liked" (click)="likeUnlike(nation_wall_post.id, i)"><i class="material-icons">thumb_up</i></a>

                                <a class="btn-floating btn-small waves-effect waves-light red right"><i class="material-icons">favorite</i></a>

                                <a class="btn-floating btn-small waves-effect waves-light black right">{{nation_wall_post.comment_count}}</a>
                            </span>
                        </div>

                    </div>
                </div>
            </div>
        </div>
       
        <div class="col l2"></div>

        <a class="col s12 btn waves-effect" title="Add Wall Post" [routerLink]="['/member/add-nation-wall-post']" style="margin-bottom: 15px;">
            <i class="material-icons right">add_circle</i>Add Wall Post
        </a>
    </div>
</div>