<div class="row center-align">
  <h5><strong>Manage Department Members</strong></h5>
</div>

<div class="row col s12">
  <div class="col l1 "></div>
  <div class="col s12 l10">

    <div class="row">
      <div class="col s6 m4 l3">
        <a [routerLink]="" (click)="backClicked()">
          <div class="card orange darken-4 hoverable " style="height: 95px;">
            <div class="card-content">
              <div class="row center">
                <i class="material-icons white-text">house</i><br />
                <strong class="white-text">Back</strong>
              </div>
            </div>
          </div>
        </a>
      </div>

      <div class="col s6 m4 l3">
        <a [routerLink]="" (click)="addDepartmentEmployee($event);">
          <div class="card pink hoverable " style="height: 95px;">
            <div class="card-content">
              <div class="row center">
                <i class="material-icons white-text">add_circle</i><br />
                <strong class="white-text">Add Employee</strong>
              </div>
            </div>
          </div>
        </a>
      </div>

      <div class="col s6 m4 l3">
        <a [routerLink]="" (click)="addDepartmentManager($event);">
          <div class="card green hoverable " style="height: 95px;">
            <div class="card-content">
              <div class="row center">
                <i class="material-icons white-text">add_circle</i><br />
                <strong class="white-text">Add Manager</strong>
              </div>
            </div>
          </div>
        </a>
      </div>

      <!-- <div class="col s6 m4 l3">
        <a [routerLink]="" (click)="inviteDepartmentEmployee($event);">
        <div class="card green hoverable " style="height: 95px;">
          <div class="card-content">
          <div class="row center">
            <i class="material-icons white-text">add_circle</i><br/>
            <strong class="white-text">Invite Employees</strong>
          </div>
          </div>
        </div>
      </a>
      </div> -->

      <div class="col s12 m6 l6" *ngFor="let departmentMember of departmentMembers" [@swing]="swing">
        <a [routerLink]="[]" (click)="editDepartmentMember(departmentMember.id,$event);">
          <div class="card-panel white darken-3 hoverable" style="height: 95px;">
            <div class="row center">
              <span class="truncate"> <strong class="black-text" style="margin-top: 12px;"> {{
                  departmentMember.givenName }} {{ departmentMember.lastName }}
                </strong>
              </span>
              <span class="truncate blue-text text-darken-3"><strong>{{departmentMember.jobTitle}}</strong></span>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
  <div class="col l1 "></div>
</div>