import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DepartmentService } from 'src/app/services/firebase/department.service';
import { trigger, transition, useAnimation } from '@angular/animations';
import { bounceIn } from 'ng-animate';

@Component({
  selector: 'app-manage-department-members',
  templateUrl: './manage-department-members.component.html',
  styleUrls: ['./manage-department-members.component.css'],
  animations: [
    trigger('swing', [transition('* => *', useAnimation(bounceIn))])
  ],
})
export class ManageDepartmentMembersComponent implements OnInit {

  departmentID: any;
  departmentMembers: any;

  constructor(private router: Router, private departmentService: DepartmentService) { }

  ngOnInit() {
    this.departmentID = window.history.state.department_id;
    this.getDepartmentMembersList();
  }

  getDepartmentMembersList(){
    this.departmentService.getDepartmentEmployee(this.departmentID).valueChanges().subscribe(x => {
      if (x) {
        this.departmentMembers = x;
        this.departmentMembers.sort((a, b) => (a.index < b.index ? -1 : 1));
      }
    });
  }

  addDepartmentManager(event: Event) {
    event.preventDefault();
    this.router.navigate(['/member/add-member-to-department'], { state: { departmentID: this.departmentID, userType: 'Manager' } });
  }
  addDepartmentEmployee(event: Event) {
    event.preventDefault();
    this.router.navigate(['/member/add-member-to-department'], { state: { departmentID: this.departmentID, userType: 'Employee' } });
  }
  editDepartmentMember(empID: string, event: Event ) {
    event.preventDefault();
    this.router.navigate(['/member/edit-department-employee', empID]);
  }
  backClicked(){
    this.router.navigate(['/member/manage-department/', this.departmentID]);
  }
}
