<div class="col s12">
  <div class="body-h">

    <div class="header-setting b-m-2">
      <h4 class=""> Manage Clients </h4>
    </div>

    <main class="StickyContent white ">
      <div class="col s12" *ngIf="!showDetail">
        <app-firebase-loading *ngIf="loading"></app-firebase-loading>

        <div class="row  white darken-2" *ngIf="registredMembers && registredMembers.length >0"
          style="min-width: fit-content;">
          <div>
            <h5 *ngIf="membersFilter.members"><strong>Members</strong></h5>
            <h5 *ngIf="membersFilter.admins"><strong>Admins</strong></h5>
            <h5 *ngIf="membersFilter.employers"><strong>Employers</strong></h5>
            <h5 *ngIf="membersFilter.employees"><strong>Employees</strong></h5>
            <h6 class="center red-text"><strong>Total: {{registredMembers.length}}</strong></h6>
          </div>

          <table datatable [dtOptions]="dtOptions" class="row-border hover">
            <thead>
              <tr>
                <th>Last Name</th>
                <th>First Name</th>
                <th>DOB</th>
                <th>Registered On</th>
                <th>Last Login</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let m of registredMembers">
                <td>
                  <a *ngIf="!loggedUser.isDemo && m.role != 'Admin'" (click)="showDetails(m.uid)"
                    style="cursor: pointer;">
                    {{ m.LastName }}
                  </a>
                  <a *ngIf="loggedUser.isDemo && m.role != 'Admin'" (click)="showDetails(m.uid)"
                    style="cursor: pointer;">
                    {{(m.LastName !==
                    null) ? (m.LastName | NameStars) : ""}} </a>
                  <p *ngIf="m.role == 'Admin'"> {{ m.LastName }}
                  </p>
                </td>
                <td>
                  <a *ngIf="!loggedUser.isDemo && m.role != 'Admin'" (click)="showDetails(m.uid)"
                    style="cursor: pointer;">
                    {{ m.GivenName }}
                  </a>
                  <a *ngIf="loggedUser.isDemo && m.role != 'Admin'" (click)="showDetails(m.uid)"
                    style="cursor: pointer;">
                    {{(m.GivenName !==
                    null) ? (m.GivenName | NameStars) : ""}} </a>
                  <p *ngIf="m.role == 'Admin'"> {{ m.GivenName }}
                  </p>
                </td>
                <td *ngIf="m.DOB" class="center">{{m.DOB | date: 'dd/MMM/yyyy'}}</td>
                <td *ngIf="!m.DOB" class="center">-</td>
                <td *ngIf="!m.DateAdded" class="center">-</td>
                <td *ngIf="m.DateAdded" class="center">
                  {{m.DateAdded.toDate() | date: 'dd/MMM/yyyy'}}
                </td>

                <td *ngIf="m.lastLogin" class="center">
                  {{m.lastLogin.toDate() | date: 'dd/MMM/yyyy'}}
                </td>
                <td *ngIf="!m.lastLogin" class="center"> - </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div *ngIf="!registredMembers || registredMembers?.length === 0"
          class="row card-panel center-align red darken-2">
          <i class="medium material-icons white-text">
            warning
          </i>
          <h6 class="white-text"><strong>
              No records found in the database.</strong>
          </h6>
        </div>

        <div class="section"></div>

        <button class="col s12 white" style="border: 1px solid;"
          [ngStyle]="{'border-color': themeColor, 'color': themeColor}" (click)="backClicked()"
          [ngClass]="'btn-flat ' + buttonType"><i class="material-icons left">house</i>Back To Previous Page</button>
      </div>

      <div *ngIf="showDetail">
        <app-client-detail [id]=memberId (back)="back()"></app-client-detail>
      </div>
    </main>
  </div>
</div>