<div class="body-h">
  <div class="header-setting b-m-2">
    <div class="col l12 m12 s12">
      <h4 class=""> Add New Activity </h4>
    </div>
  </div>

  <main class="StickyContent white row">
    <div id="step0" class="col s12 l11">
      <form (ngSubmit)="submitForm(addActivityForm.value)" [formGroup]="addActivityForm">
        <div class="row card-panel">

          <div class="input-field class col s12 l6">
            <div class="label-input"> Activity or Hobby Name <strong class="red-text">*</strong> </div>
            <input formControlName="Activity" id="Activity" type="text" maxlength="140" autocomplete="off" required
              class="validate" placeholder="Activity or Hobby Name ">
            <div *ngIf="(Activity.dirty || Activity.touched) && Activity.invalid">
              <small *ngIf="Activity.errors.required" class="red-text">The Activity Is Required!
              </small>
            </div>
          </div>

          <div class="input-field class col s12 l6">
            <div class="label-input"> Description <strong class="red-text">*</strong> </div>
            <input formControlName="description" id="description" type="text" maxlength="250" autocomplete="off"
              required class="validate" placeholder="Description">
            <div *ngIf="(description.dirty || description.touched) && description.invalid">
              <small *ngIf="description.errors.required" class="red-text"> Please enter the description.
              </small>
            </div>
          </div>
        </div>

        <div class="section"></div>

        <div class="col s12">
          <button class='at-btn col s12 btn waves-effect success' style="margin-bottom: 15px;" type="submit"
            [disabled]="!addActivityForm.valid">
            <i class="material-icons right">add_circle</i> Save Activity
          </button>

          <button type="button" [ngClass]="'col s12 btn waves-effect ' + themeColor " (click)="backClicked()">
            <i class="material-icons left">keyboard_arrow_left</i> Back To Activity List
          </button>
        </div>
      </form>
    </div>
  </main>
</div>