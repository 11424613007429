
export class PseEduPlanModel {

    applicantID: number;
    objective: string;
    completion_date: string;
    program: string;
    institute: string;
    t_years: String;
    classification: string;
    studentID: string;
    fall_term: string;
    winter_term: string;
    spring_term: string;
    summer_term: string;
    funding_app_id: number;
    dependents: number;

    subprogram: string;
    delivery_method: string;
    program_start_date: string;
    education_sought: string;
    other_details: string;

    fall_tution_amt: number;
    fall_books_amt: number;
    fall_other_amt: number;

    winter_tution_amt: number;
    winter_books_amt: number;
    winter_other_amt: number;
   
    spring_tution_amt: number;
    spring_books_amt: number;
    spring_other_amt: number;

    summer_tution_amt: number;
    summer_books_amt: number;
    summer_other_amt: number;

}
