
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ISETSClientDataModel } from 'src/app/models/isets/ISETSClientDataModel';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
//import { ISETSApiKeyManagerService } from './ISETSApiKeyManager.Service';
import { ApiKeyManagerService } from 'src/app/services/api/apiKeyManager.Service';


import { SearchISETSClientModel } from 'src/app/models/isets/searchISETSClientModel';
import { UpdateEmailModel } from 'src/app/models/UpdateEmailModel';
//import { ISETSBandClientModel } from 'src/app/models/isets/ISETSBandClientModel';

@Injectable({
  providedIn: 'root'
})
export class ISETSClientService {

  constructor(private httpClient: HttpClient, private keyManager: ApiKeyManagerService) { }

  getClient(id: number): Observable<ISETSClientDataModel> {
    const url = environment.firstNation.apiUrl + 'api/IsetsClients/Get/' + id;
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
    return this.httpClient.get<any>(url, { headers });
  }

  getClients(): Observable<ISETSClientDataModel[]> {
    const url = environment.firstNation.apiUrl + 'api/IsetsClients/Get';
    //console.log(url);
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
    return this.httpClient.get<any>(url, { headers });
  }

  
  getClientsCount(): Observable<any> {
    const url = environment.firstNation.apiUrl + 'api/IsetsClients/GetClientsCount';
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
    return this.httpClient.get<any>(url, { headers });
  }

  getManageClientsList(): Observable<ISETSClientDataModel[]> {
    const url = environment.firstNation.apiUrl + 'api/IsetsClients/GetClientsList';
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
    return this.httpClient.get<any>(url, { headers });
  }

  searchClientByName(model: SearchISETSClientModel): Observable<ISETSClientDataModel> {
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
    return this.httpClient.post<any>(environment.firstNation.apiUrl + 'api/IsetsClients/searchClientByName', JSON.stringify(model), { headers });
  }


  findClientByName(model: SearchISETSClientModel): Observable<ISETSClientDataModel> {
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
    return this.httpClient.post<any>(environment.firstNation.apiUrl + 'api/IsetsClients/findClientByName', JSON.stringify(model), { headers });
  }


  
  findApplicantByName(model: SearchISETSClientModel): Observable<ISETSClientDataModel> {
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
    return this.httpClient.post<any>(environment.firstNation.apiUrl + 'api/IsetsClients/findApplicantByName', JSON.stringify(model), { headers });
  }


  //findRegistrationByName
  findRegistrationByName(model: SearchISETSClientModel): Observable<ISETSClientDataModel> {
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
    return this.httpClient.post<any>(environment.firstNation.apiUrl + 'api/IsetsClients/findRegistrationByName', JSON.stringify(model), { headers });
  }


  
  findNewApplicationByName(model: SearchISETSClientModel): Observable<ISETSClientDataModel> {
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
    return this.httpClient.post<any>(environment.firstNation.apiUrl + 'api/IsetsClients/findNewApplicationByName', JSON.stringify(model), { headers });
  }



  updateClientEmail(model: UpdateEmailModel): Observable<any> {
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
    return this.httpClient.post<any>(environment.firstNation.apiUrl + 'api/IsetsClients/UpdateEmail', JSON.stringify(model), { headers });
  }


  saveClientIfNotExist(model: any): Observable<any> {
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
    return this.httpClient.post<any>(
      environment.firstNation.apiUrl + 'api/IsetsClients/SaveClietIfNotExist', JSON.stringify(model), { headers });
  }
  

  
  updateClient_PWA_invite_status(model: any): Observable<any> {
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
    return this.httpClient.post<any>(environment.firstNation.apiUrl + 'api/IsetsClients/Update_PWA_Status', JSON.stringify(model), { headers });
  }


}
