import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { toast } from 'materialize-css';

declare var $: any;

@Component({
  selector: 'app-post-job',
  templateUrl: './post-job.component.html',
  styleUrls: ['./post-job.component.css']
})

export class PostJobComponent implements OnInit {

  myModel = {
    uid: '',
    title: '',
    description: '',
    skills: '',
    duration: '',
    officer: '',
    email: '',
    phone: '',
    employer: '',

    posted: true,
    archived: false,
    dateposted: new Date(),
    start_date: '',
    expiry_date: '', // new Date(new Date().getFullYear(), new Date().getMonth() + 1, 1)
    deleted: false,
    reason_to_delete: '',
  };

  valueEmittedFromChildComponent: any;
  parentEventHandlerFunction(valueEmitted) {
    this.valueEmittedFromChildComponent = valueEmitted;
    if (this.valueEmittedFromChildComponent === true) {
      toast({ html: 'Job Successfully Posted!', classes: 'green' });
      this.backClicked();
    }
  }

  constructor(private location: Location) { }

  ngOnInit() {
  }

  backClicked() {
    this.location.back();
  }

}