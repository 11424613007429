import { Component, OnInit } from "@angular/core";
import { NationWallService } from 'src/app/services/firebase/nation-wall.service';
import { AuthService } from 'src/app/services/firebase/auth.service';

@Component({
	selector: "app-nation-wall",
	templateUrl: "./nation-wall.component.html",
	styleUrls: ["./nation-wall.component.css"]
})

export class NationWallComponent implements OnInit {
	blogposts: any;
	nation_wall_posts: any;
	innser_object: any;

	constructor(private nationWallService: NationWallService, private authService: AuthService) { }

	ngOnInit() {
		this.nationWallService.getNationWallPostList().valueChanges().subscribe(x => {
			if (x) {
				this.nation_wall_posts = x;
				for (let i = 0; i < this.nation_wall_posts.length; i++) {
					this.nation_wall_posts[i].liked_count = 0;
					if (typeof this.nation_wall_posts[i].liked_members != "undefined") {
						for (let j = 0; j < this.nation_wall_posts[i].liked_members.length; j++) {
							if (this.authService.loggedInUser.uid == this.nation_wall_posts[i].liked_members[j].uid) {
								this.nation_wall_posts[i].liked = true;
							}
						}
						this.nation_wall_posts[i].liked_count = this.nation_wall_posts[i].liked_members.length;
					}
					this.nationWallService.getWallPostComments(this.nation_wall_posts[i].id).valueChanges().subscribe(x1 => {
						if (x1) {
							this.nation_wall_posts[i]["comment_count"] = x1.length;
						}
					});
				}
			}
		});
	}

	likeUnlike(id, post_index) {
		let selected_post = this.nation_wall_posts[post_index];
		let liked_members = selected_post.liked_members;
		let got = 0;
		let key;
		if (typeof liked_members != "undefined") {
			for (let i = 0; i < liked_members.length; i++) {
				if (this.authService.loggedInUser.uid == liked_members[i].uid) {
					got = 1;
					key = i;
					break;
				}
			}
		} else {
			liked_members = [];
		}
		if (got) {
			liked_members.splice(key, 1);
		} else {
			liked_members.push(this.authService.loggedInUser);
		}
		selected_post.liked_members = liked_members;
		this.nationWallService.updateLikeUnlikeMemberInWallPost(selected_post);
	}
}