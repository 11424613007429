import { Component, Input, OnInit, SimpleChange } from '@angular/core';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { FireMembersService } from 'src/app/services/firebase/fire-members.service';
import { Observable } from 'rxjs';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';

declare var $: any;

@Component({
  selector: 'app-registred-clients',
  templateUrl: './registred-clients.component.html',
  styleUrls: ['./registred-clients.component.css']
})

export class RegistredClientsComponent implements OnInit {

  @Input() filters: Observable<any>;
  @Input() menuClicked: boolean;

  defaultThemeColor = environment.appTheme.themeColor;
  themeColor = environment.appTheme.adminThemeColor;
  fontColor = environment.appTheme.adminFontColor;
  nationOfuse = environment.firstNation.name;

  buttonType: any;
  loading = false;
  nationCommunities: any;
  loggedUser: any;
  firebaseMembers: any[];
  registredMembers: any[];
  members: any[] = [];
  admins: any[] = [];
  employers: any[] = [];
  employees: any[] = [];
  dtOptions: any;

  showDetail = false;
  memberId: any;
  membersFilter = {
    members: false,
    admins: false,
    employers: false,
    employees: false
  }

  constructor(private authService: AuthService,
    private fireMembersService: FireMembersService,
    private location: Location,
    private settingsService: AppSettingsService,) {
    localStorage.setItem('from', 'ManageApp');
    this.loggedUser = this.authService.getGlobalUser();
    this.themeColor = this.settingsService.getAppThemeColor() ? this.settingsService.getAppThemeColor() : this.defaultThemeColor;
    this.buttonType = this.settingsService.getButtonType() ? this.settingsService.getButtonType() : 'rectangularButton';
  }

  ngOnInit() {
    this.loading = true;
    this.fireMembersService.getMembersList().valueChanges().subscribe(x => {
      if (x && x.length > 0) {
        this.firebaseMembers = x;
        const memberIds = this.firebaseMembers.map((m) => {
          return m.uid
        });

        this.firebaseMembers = this.firebaseMembers.sort((a, b) => a.LastName - b.LastName);
        if (this.firebaseMembers.length > 1) {
          this.firebaseMembers = this.firebaseMembers.sort((a, b) => {
            const textA = a.LastName;
            const textB = b.LastName;
            return (textA > textB) ? -1 : (textA < textB) ? 1 : 0;
          });
        }
        this.dtOptions = {};
        this.dtOptions = {
          pagingType: 'simple_numbers',
          pageLength: 15,
          dom: 'Bfrtip',
          buttons: [] // Configure the buttons
        };
        this.registredMembers = this.firebaseMembers;
        // this.filterBy();
        this.loading = false;

        this.registredMembers = this.registredMembers.sort((a, b) => {
          if (a.DateAdded && b.DateAdded) {
            return (b.DateAdded.toDate() - a.DateAdded.toDate());
          }
          // const dateA = new Date(a.DateAdded);
          // const dateB = new Date(b.DateAdded);
        });

        this.members = this.registredMembers.filter(m => m.role == 'Member');
        this.admins = this.registredMembers.filter(m => m.role == 'Admin');
        this.employees = this.registredMembers.filter(m => m.role == 'Employee' || m.role == 'Department-Employee');
        this.employers = this.registredMembers.filter(m => m.role == 'Employer');
      }
      this.loading = false
    });

    setTimeout(() => {
      $('.tabs').tabs();
    }, 25);
  }

  showDetails(id) {
    this.memberId = id;
    this.showDetail = true;
  }

  ngOnChanges(changes: SimpleChange) {
    if (this.showDetail) this.showDetail = false;
    let change: SimpleChange = changes['filters'];
    if (change) this.membersFilter = change.currentValue;
    this.filterBy();
  }

  filterBy() {
    if (this.firebaseMembers) {
      if (this.membersFilter.members) this.registredMembers = this.firebaseMembers.filter(m => m.role == 'Member');
      else if (this.membersFilter.admins) this.registredMembers = this.firebaseMembers.filter(m => m.role == 'Admin');
      else if (this.membersFilter.employers) this.registredMembers = this.firebaseMembers.filter(m => m.role == 'Employer');
      else if (this.membersFilter.employees) this.registredMembers = this.firebaseMembers.filter(m => m.role == 'Employee' || m.role == 'Department-Employee');
      else this.registredMembers = this.firebaseMembers;
      this.registredMembers.sort((a, b) => a.LastName > b.LastName ? 1 : -1);
    }
  }

  back() {
    this.showDetail = false;
  }

  backClicked() {
    this.location.back();
  }
}
