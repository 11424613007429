import { Component, OnInit } from '@angular/core';
import { Validators, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { toast } from 'materialize-css';
import { environment } from 'src/environments/environment';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})

export class ChangePasswordComponent implements OnInit {

  resetPasswordForm: UntypedFormGroup;
  themeColor: any;
  model: any;
  defaultThemeColor = environment.appTheme.themeColor;

  constructor(private fb: UntypedFormBuilder, private authService: AuthService,private settingsService: AppSettingsService) {
    this.settingsService.getAppSettingsList().valueChanges().subscribe(
      appSettings => {
        if (appSettings) {
          if (appSettings.length > 0) {
            this.model = appSettings[0];
            this.themeColor = this.model.themeColor ? this.model.themeColor : this.defaultThemeColor;
          }
        }
      });
   }

  ngOnInit() {
    this.resetPasswordForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  get email() {
    return this.resetPasswordForm.get('email');
  }

  resetPassword() {
    this.authService.resetPassword(this.email.value)
      .then(() => toast({ html: '<p> Reset Password Link Successfully Sent To Your Inbox. </p>', classes: 'green' })
      ).catch((error) => toast({ html: error.message, classes: 'red' }));
  }
}
