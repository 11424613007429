import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DepartmentNewsService } from 'src/app/services/firebase/department-news.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-dep-news-detail',
  templateUrl: './dep-news-detail.component.html',
  styleUrls: ['./dep-news-detail.component.css']
})
export class DepNewsDetailComponent implements OnInit {

  newsID: any;
  myModel: any;

  constructor(public depNewsSerice: DepartmentNewsService, private route: ActivatedRoute, private location: Location) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.newsID = params.get('id');
    });

    if (this.newsID) {
      this.depNewsSerice.getDepNewsByID(this.newsID).valueChanges().subscribe(data => {
        if (data) {
          this.myModel = data;
        }
      });
    }
  }

  backClicked() {
    this.location.back();
  }

}
