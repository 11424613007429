import { Component, OnInit, Input, Output } from "@angular/core";
import { AyrshareService } from "src/app/services/social-media/ayrshare.service";
import { JwtModel } from "src/app/models/SocialMediaPostModel";
import { environment } from "src/environments/environment";
import { ProfieKeyService } from "src/app/services/social-media/profileKey.service";
import { AuthService } from "src/app/services/firebase/auth.service";
import { AppSettingsService } from "src/app/services/firebase/app-settings.service";

@Component({
  selector: "app-link-account",
  templateUrl: "./link-account.component.html",
  styleUrls: ["./link-account.component.css"],
})

export class LinkAccountComponent implements OnInit {
  defaultThemeColor = "#000000";
  themeColor: any;

  profileKey;
  privateKey = environment.socialMedia.privateKey;

  perm: any;
  permission: any;
  currentUser: any;
  isAdmin: boolean;

  @Input() link;
  @Input() new;
  @Output() action;
  text;

  permMember: any;
  permSocial: any;
  permPES: any;
  permSpecial: any;
  permAnalytics: any;

  constructor(
    private socialMediaService: AyrshareService,
    private profieKeyService: ProfieKeyService,
    private settingsService: AppSettingsService
  ) {
    this.themeColor = this.settingsService.getAppThemeColor() ? this.settingsService.getAppThemeColor() : this.defaultThemeColor;
  }

  ngOnInit() {
    this.profieKeyService.currentProfileKey.subscribe((p) => {
      this.profileKey = p;
    });
    this.text = this.new ? "Link social media account" : "Add more accounts";
  }

  linkSocialMedia() {
    let model = new JwtModel();

    model.privateKey = this.privateKey;
    model.profileKey = this.profileKey;
    
    this.socialMediaService.generateJWT(model).subscribe((x) => {
      if (x["status"] === "success") {
        window.open(x["url"], "_blank");
      } else {
        console.log(x, "something happened");
      }
    });
  }
}
