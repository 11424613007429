import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment.prod';
import { MemberModel } from 'src/app/models/memberModel';
import { ApiKeyManagerService } from './apiKeyManager.Service';

@Injectable({
  providedIn: 'root'
})
export class FamilyCompositionService {

  constructor(private httpClient: HttpClient, private keyManager: ApiKeyManagerService) { }

  getFamilyMember(id: number): Observable<MemberModel[]> {
    const url = environment.firstNation.apiUrl + 'api/FamilyComposition/get/' + id;
    const headers = new HttpHeaders().set('SOCIALAPIKEY', this.keyManager.getKey());
    return this.httpClient.get<MemberModel[]>(url, { headers });
  }

  SearchForMember(model: any): Observable<any>{
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
    return this.httpClient.post<any>(environment.firstNation.apiUrl + 'api/FamilyComposition/SearchMember',
      JSON.stringify(model), { headers });
  }
  FindMember(model: any): Observable<any>{
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
    return this.httpClient.post<any>(environment.firstNation.apiUrl + 'api/FamilyComposition/FindMember',
      JSON.stringify(model), { headers });
  }

  addFamilyMember(model: any): Observable<any> {
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
    return this.httpClient.post<any>(environment.firstNation.apiUrl + 'api/FamilyComposition/AddFamiliyMembers',
      JSON.stringify(model), { headers });
  }
}
