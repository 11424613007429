<div class="body-bg">

    <div class="row p-b-7 p-t-2" *ngIf="section">

        <app-firebase-loading *ngIf="showLoader"></app-firebase-loading>

        <div class="p-b-2">
            <div class="col s7 m10 l5 push-l1 push-s5 push-m1">
                <div class="header-title ">
                    <h6>{{section.displayName}}</h6>
                </div>
            </div>

            <div class="divider col l10 m8 s9 push-l1 push-m2 push-s2 grey lighten-3"></div>
        </div>

        <div class="container">
            <div *ngIf="model" class="col s12 m12 l12 p-t-3">
                <div *ngFor="let data of model">

                    <div class="col l12 m12 s12 card hide-on-small-only">
                        <div class="card-size hoverable" (click)="navigateTo(data.id)"
                            [routerLink]="['/section-detail', data.id]" style="cursor: pointer">
                            <div class="card-content">
                                <div [ngClass]="data.mediaType ? 'col l9 m8 s7 container3' :'col s12 container3'">
                                    <p class="news-title short-body-snippet">
                                        <a [routerLink]="['/section-detail', data.id]" [innerHTML]="data.title">
                                        </a>
                                    </p>

                                    <p *ngIf="data.mediaLink"
                                        class="middle-body-snippet sub-title-section3 p-t-1 bold-100">
                                        {{ truncateHTML(data.description, 600) }}
                                    </p>

                                    <p *ngIf="!data.mediaLink"
                                        class="middle-body-snippet sub-title-section3 p-t-1 bold-100">
                                        {{ truncateHTML(data.description, 600) }}
                                    </p>

                                    <p class="col l3 date-content sub-title-section3 p-t-2" style="right: 0">
                                        {{ data.datePublished.toDate() | date : "MMM dd , yyyy"}}
                                    </p>

                                    <div class="col l3 read-more-card1 hide-on-small-only">
                                        <h4 class="read-more-card1" [routerLink]="['/section-detail', data.id]">
                                            Read More
                                        </h4>
                                    </div>
                                </div>

                                <div class="col l3 m4 s5"
                                    *ngIf="data.mediaType == 'Photo' || data.mediaType == 'PDF' || data.mediaType == 'Video' || data.mediaType == 'Youtube'">

                                    <div class="card-image" *ngIf="data.mediaType === 'Photo' && data.mediaLink">
                                        <img [src]="data.mediaLink" class="news-img" />
                                    </div>

                                    <div class="card-image" *ngIf="data.mediaType === 'Video' && data.mediaLink">
                                        <video class="responsive-video" controls>
                                            <source [src]="data.mediaLink" type="video/mp4" />
                                        </video>
                                    </div>

                                    <!-- <div class="card-image" *ngIf="data.mediaType === 'Youtube' && data.mediaLink">
                                        <div class="video-container">
                                            <div [innerHTML]="iframehtml"></div>
                                        </div>
                                    </div> -->

                                    <div class="card-image" *ngIf="data.mediaType === 'Youtube' && data.mediaLink">
                                        <div class="video-container ">
                                            <youtube-player [videoId]="embedVideo(data.mediaLink)"></youtube-player>
                                            <!-- <div [innerHTML]="embedVideo(data.mediaLink)"></div> -->
                                        </div>
                                    </div>

                                    <div class="card-image" *ngIf="data.mediaType === 'PDF' && data.mediaLink">
                                        <iframe title="iframe-box2" class="responsive-pdf"
                                            [src]="data.mediaLink | safeUrl"></iframe>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card hide-on-med-and-up" *ngIf="model">
                        <div class="card-size hoverable" (click)="navigateTo(data.id)"
                            [routerLink]="['/section-detail', data.id]" style="cursor: pointer">
                            <div class="card-content">
                                <div [ngClass]="data.mediaType ? 'col l12 m12 s12 container3': 'col s12 container3'">
                                    <!-- ===================img =========== -->
                                    <div class="s12 p-b-2"
                                        *ngIf="data.mediaType == 'PDF' || data.mediaType == 'Video' || data.mediaType == 'Youtube' || data.mediaType == 'Photo'">

                                        <div class="card-image" *ngIf="data.mediaType === 'Photo' && data.mediaLink">
                                            <img [src]="data.mediaLink" class="news-img" />
                                        </div>

                                        <div class="card-image" *ngIf="data.mediaType === 'Video' && data.mediaLink">
                                            <video class="responsive-video" controls>
                                                <source [src]="data.mediaLink" type="video/mp4" />
                                            </video>
                                        </div>

                                        <div class="card-image" *ngIf="data.mediaLink === 'Youtube' && data.mediaLink">
                                            <div class="video-container">
                                                <div [innerHTML]="iframehtml"></div>
                                            </div>
                                        </div>

                                        <div class="card-image" *ngIf="data.mediaType === 'PDF' && data.mediaLink">
                                            <iframe title="iframe-box2" class="responsive-pdf"
                                                [src]="data.mediaLink | safeUrl"></iframe>
                                        </div>
                                    </div>
                                    <!-- ============= date ================== -->
                                    <p class="col s12 date-content sub-title-section3" style="right: 0"> {{
                                        data.datePublished.toDate() | date : "MMM dd , yyyy"}}
                                    </p>
                                    <!-- ============= title ================== -->
                                    <p class="col s12  news-title short-body-snippet">
                                        <a [routerLink]="['/section-detail', data.id]"
                                            [innerHTML]="data.title">
                                        </a>
                                    </p>

                                    <p *ngIf="!data.mediaLink"
                                        class="col s12 middle-body-snippet sub-title-section3 p-t-1 bold-100">
                                        {{ truncateHTML(data.description, 600) }}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="col s12 m10 l10 push-m1 push-l1 container1" *ngIf="!model && !showLoader">
            <div class="row  white">
                <strong>
                    <span class="material-icons blue-text"
                        style="display: inline-flex; vertical-align: top;">info</span>
                    Sorry, {{nationName}} Did Not Share Any {{section.displayName}}</strong>
            </div>
        </div>
    </div>
</div>