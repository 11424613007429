<div class="col l12 m12 s12 card hide-on-small-only " *ngIf="newsModel">
  <div class="card-size hoverable" (click)="navigateTo(newsModel.id)" [routerLink]="['/read-more', newsModel.id]"
    style="cursor: pointer">
    <div class="card-content">
      <!-- <p class="date-content sub-title-section3 " style="padding-left: 10px;">
        {{
        newsModel.datePublished.toDate()
        | date
        : "MMM dd , yyyy"
        }}  |  {{newsModel.author}} 
      </p> -->
      <p class="date-content sub-title-section3 " style="padding-left: 10px;">
        {{
        newsModel.datePublished.toDate()
        | date
        : "MMM dd , yyyy"
        }}
        <span *ngIf="callerForm !== 'News'"> | {{newsModel.author}}</span>
      </p>

      <div [ngClass]="
          newsModel.type == 'Post'
            ? 'col s12 container3'
            : 'col l9 m8 s7 container3'
        ">

        <p class="news-title short-body-snippet">
          <a *ngIf="callerForm === 'News'" [routerLink]="['/read-more', newsModel.id]" [innerHTML]="newsModel.title">
          </a>
        </p>

        <p class="news-title short-body-snippet">
          <a *ngIf="callerForm === 'MemberNews'" [routerLink]="['/member/news-detail', newsModel.id]"
            [innerHTML]="newsModel.title">
          </a>
        </p>

        <p class="news-title short-body-snippet">
          <a *ngIf="callerForm === 'DepNews'" [routerLink]="['/member/view-department-news', newsModel.id]"
            [innerHTML]="newsModel.title">
          </a>
        </p>

        <p class="news-title short-body-snippet">
          <a *ngIf="callerForm === 'DepNews_Public'" [routerLink]="['/view-department-news', newsModel.id]"
            [innerHTML]="newsModel.title">
          </a>
        </p>

        <p *ngIf="newsModel.imgLink" class="middle-body-snippet sub-title-section3 p-t-1 bold-100">
          {{ truncateHTML(newsModel.body, 600) }}
        </p>
        <p *ngIf="!newsModel.imgLink" class="middle-body-snippet sub-title-section3 p-t-1 bold-100">
          {{ truncateHTML(newsModel.body, 600) }}
        </p>



        <div class="col l3 read-more-card1 hide-on-small-only" *ngIf="callerForm === 'News'">
          <h4 class="read-more-card1" [routerLink]="['/read-more', newsModel.id]" [ngStyle]="{ 'color': themeColor }">
            Read More ›
          </h4>
        </div>

        <div class="col l3 read-more-card1 hide-on-small-only" *ngIf="callerForm === 'MemberNews'">
          <h4 class="read-more-card1" [routerLink]="['/member/news-detail', newsModel.id]"
            [ngStyle]="{ 'color': themeColor }">
            Read More ›
          </h4>
        </div>

        <div class="col l3 read-more-card1 hide-on-small-only" *ngIf="callerForm === 'DepNews'">
          <h4 class="read-more-card1" [routerLink]="['/member/view-department-news', newsModel.id]"
            [ngStyle]="{ 'color': themeColor }">
            Read More ›
          </h4>
        </div>

        <div class="read-more-card1 hide-on-small-only" *ngIf="callerForm === 'DepNews_Public'">
          <h4 class="read-more-card1" [routerLink]="['/view-department-news', newsModel.id]"
            [ngStyle]="{ 'color': themeColor }">
            Read More ›
          </h4>
        </div>
      </div>

      <div class="col l3 m4 s5" *ngIf="
          newsModel.type == 'PDF' ||
          newsModel.type == 'Video' ||
          newsModel.type == 'uploadVideo' ||
          newsModel.type == 'Photo'
        ">
        <div class="card-image" *ngIf="newsModel.type === 'Photo' && newsModel.imgLink">
          <img [src]="newsModel.imgLink" class="news-img" />
        </div>

        <div class="card-image" *ngIf="newsModel.type === 'Video' && newsModel.videoLink">
          <div class="video-container">
            <youtube-player [videoId]="videoId"></youtube-player>
            <!-- <div [innerHTML]="iframehtml"></div> -->
          </div>
        </div>

        <div class="card-image" *ngIf="newsModel.type === 'uploadVideo' && newsModel.videoLink">
          <video class="responsive-video" controls>
            <source [src]="newsModel.videoLink" type="video/mp4" />
          </video>
        </div>

        <div class="card-image" *ngIf="newsModel.type === 'PDF' && newsModel.videoLink">
          <iframe title="iframe-box2" class="responsive-pdf" [src]="newsModel.videoLink | safeUrl"></iframe>
        </div>
      </div>
    </div>
  </div>
</div>



<div class="card hide-on-med-and-up" *ngIf="newsModel">
  <div class="card-size hoverable" (click)="navigateTo(newsModel.id)" [routerLink]="['/read-more', newsModel.id]"
    style="cursor: pointer">
    <div class="card-content">
      <div [ngClass]="
          newsModel.type == 'Post'
            ? 'col s12 container3'
            : 'col l12 m12 s12 container3'
        ">
        <!-- ===================img =========== -->
        <div class="s12 p-b-2" *ngIf="
          newsModel.type == 'PDF' ||
          newsModel.type == 'Video' ||
          newsModel.type == 'uploadVideo' ||
          newsModel.type == 'Photo'
        ">
          <div class="card-image" *ngIf="newsModel.type === 'Photo' && newsModel.imgLink">
            <img [src]="newsModel.imgLink" class="news-img" />
          </div>

          <div class="card-image" *ngIf="newsModel.type === 'Video' && newsModel.videoLink">
            <div class="video-container">
              <youtube-player [videoId]="videoId"></youtube-player>
              <!-- <div [innerHTML]="iframehtml"></div> -->
            </div>
          </div>

          <div class="card-image" *ngIf="newsModel.type === 'uploadVideo' && newsModel.videoLink">
            <video class="responsive-video" controls>
              <source [src]="newsModel.videoLink" type="video/mp4" />
            </video>
          </div>

          <div class="card-image" *ngIf="newsModel.type === 'PDF' && newsModel.videoLink">
            <iframe title="iframe-box2" class="responsive-pdf" [src]="newsModel.videoLink | safeUrl"></iframe>
          </div>
        </div>
        <!-- ============= date ================== -->
        <p class="col s12 date-content sub-title-section3" style="right: 0">
          {{
          newsModel.datePublished.toDate()
          | date
          : "MMM dd , yyyy"
          }}
        </p>
        <!-- ============= title ================== -->
        <p class="col s12  news-title short-body-snippet">
          <a *ngIf="callerForm === 'News'" [routerLink]="['/read-more', newsModel.id]" [innerHTML]="newsModel.title">
          </a>
        </p>

        <p class="col s12  news-title short-body-snippet">
          <a *ngIf="callerForm === 'MemberNews'" [routerLink]="['/member/news-detail', newsModel.id]"
            [innerHTML]="newsModel.title">
          </a>
        </p>

        <p class="col s12  news-title short-body-snippet">
          <a *ngIf="callerForm === 'DepNews'" [routerLink]="['/member/view-department-news', newsModel.id]"
            [innerHTML]="newsModel.title">
          </a>
        </p>

        <p class=" col s12 news-title short-body-snippet">
          <a *ngIf="callerForm === 'DepNews_Public'" [routerLink]="['/view-department-news', newsModel.id]"
            [innerHTML]="newsModel.title">
          </a>
        </p>
        <!-- ============= img ================== -->
        <!-- <p
          *ngIf="newsModel.imgLink"
          class="middle-body-snippet sub-title-section3 p-t-1 bold-100"
        >
          {{ truncateHTML(newsModel.body, 600) }}
        </p> -->
        <p *ngIf="!newsModel.imgLink && !newsModel.videoLink  && !newsModel.videoLink "
          class="col s12 middle-body-snippet sub-title-section3 p-t-1 bold-100">
          {{ truncateHTML(newsModel.body, 600) }}
        </p>

      </div>

    </div>
  </div>
</div>