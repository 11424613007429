<!--Delete Modal-->
<div id="deleteModal" class="modal b-n-1">
    <i class="material-icons right modal-close col s1" (click)="closeModal()">close</i>
    <div class="modal-content">
        <div class="col s3">
            <span class="material-symbols-outlined red-text" style="font-size: 70px;">
                {{data.icon}}
            </span>
        </div>
        <div class="col s9">
            <h4 class="red-text"> {{data.title}}</h4>
            <h6>{{data.subTitle}} </h6>
            <h6><strong>{{data.description}}</strong>
            </h6>
        </div>
    </div>
    <div class="modal-footer row">
        <a class="modal-close cancel-button btn col l5 s5" (click)="closeModal()">Cancel</a>
        <a class="modal-close continue-button btn col l5 s5 modal-trigger" href="#comformdeleteModal">Continue</a>
    </div>
</div>

<!--Delete Confirmation Modal-->
<div id="comformdeleteModal" class="modal b-n-1">
    <i class="material-icons right modal-close col s1" (click)="closeModal()">close</i>
    <div class="modal-content">

        <div class="col s12 center">
            <h4 class="red-text">{{data.title}}? </h4>
            <p>{{data.confirmation.title}}</p>
        </div>

        <div class="col s12 warning-red p-t-1">
            <h4 class="red-text">{{data.icon}}</h4>
            <p style="font-size: 14px;">{{data.confirmation.text}} </p>
            <p class="bold-700">{{data.confirmation.text2}}
            </p>
        </div>
    </div>
    <div class="modal-footer row">
        <a class="modal-close cancel-button btn col l5 s5" (click)="closeModal()">Cancel</a>
        <a (click)="delete()" class="modal-close delete-button btn col l5 s5">Delete</a>
    </div>
</div>