
export class PseCoursesModel {

    student_aid: number;
    course_id: number;
    fiscal_year: string;
    term: string;
    course: string;
    start_date: string; 
    end_date: string;  
    tuition: number;
    books: number;
    tutoring: number;
    other: number;
    description: string; 
    completed: string;
    completion_date: string; 
    mark: string; 
    reason: string; 
    status: string; 
}