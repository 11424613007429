<div class="row col s12">
  <div>
    <div class="row center-align">
      <br />
      <strong>
        <h5 *ngIf="nationOfUSe === 'Batc'" class="center">Contact Office</h5>
        <h5 *ngIf="nationOfUSe !== 'Batc'" class="center">Contact Department</h5>
      </strong>
    </div>

    <div *ngIf="employeeList" class="row">
      <div class="col s12 l6 " *ngFor="let emp of employeeList">
        <div class="row card-panel white col s12">
          <div class="row col s12">
            <div class="section"></div>
            <ul class="collection">
              <li class="collection-item">
                <div>
                  <span> <strong class="teal-text"> First Name : </strong></span>
                  <span class="right black-text"> <strong> {{emp.givenName}} </strong></span>
                </div>
              </li>
              <li class="collection-item">
                <div>
                  <span> <strong class="teal-text"> Last Name : </strong></span>
                  <span class="right black-text"> <strong> {{emp.lastName}} </strong></span>
                </div>
              </li>
              <li class="collection-item">
                <div>
                  <span> <strong class="teal-text"> Title : </strong></span>
                  <span class="right blue-text"> <strong> {{emp.jobTitle}} </strong></span>
                </div>
              </li>
              <li class="collection-item">
                <div>
                  <span> <strong class="teal-text"> Community : </strong></span>
                  <span class="right blue-text"> <strong> {{emp.community}} </strong></span>
                </div>
              </li>
            </ul>
            <div class="card-panel col s12">
              <div class="row">
                <h6 class="center"> <strong class="teal-text"> Responsibilities : </strong></h6>
                <div class="col s12"> <strong> {{emp.description}} </strong></div>
              </div>
            </div>
          </div>

          <div class="row col s12 hide-on-small-only show-on-medium-and-up">
            <a href="mailto:{{emp.email}}" class="center col s4" *ngIf="emp.showEmailAdr !== 'Private'"> <span
                style="cursor: pointer;"><i class="material-icons blue-text">mail</i> <strong
                  class="black-text">Email</strong></span> </a>

            <a (click)="showWorkPhone(emp)" class="center col s4" *ngIf="emp.showOfficeNo !== 'Private'"><span
                style="cursor: pointer;"><i class="center material-icons teal-text">call</i><strong
                  class="center black-text">Work</strong> </span></a>

            <a (click)="showMobile(emp)" class="center col s4" *ngIf="emp.showMobileNo !== 'Private'"><span
                style="cursor: pointer;"> <i class="material-icons orange-text">stay_current_portrait</i> <strong
                  class="black-text">Mobile</strong></span> </a>
          </div>

          <div class="row col s12 show-on-small hide-on-med-and-up">
            <a href="mailto:{{emp.email}}" class="center col s4" *ngIf="emp.showEmailAdr !== 'Private'"> <span
                style="cursor: pointer;"><i class="material-icons blue-text">mail</i> <strong
                  class="black-text">Email</strong></span> </a>

            <a href="tel:{{formatWorkNumber(emp)}}" class="center col s4" *ngIf="emp.showOfficeNo !== 'Private'"><span
                style="cursor: pointer;"><i class="center material-icons teal-text">call</i><strong
                  class="center black-text">Work</strong> </span></a>

            <a href="tel:{{formatPhoneNumber(emp.mobilePhone)}}" class="center col s4"
              *ngIf="emp.showMobileNo !== 'Private'"><span style="cursor: pointer;"> <i
                  class="material-icons orange-text">stay_current_portrait</i> <strong
                  class="black-text">Mobile</strong></span> </a>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="showMsg" class="row col s12 card-panel white darken-2">
      <p *ngIf="nationOfUSe === 'Batc'">
        <strong>
          The Office did not update their contact list please try
          again later </strong>
      </p>
      <p *ngIf="nationOfUSe !== 'Batc'">
        <strong>
          The department did not update their contact list please try
          again later </strong>
      </p>
    </div>

    <a [ngClass]="'col s12 btn waves-effect ' + themeColor" (click)="backClicked()"><i
        class="material-icons left">arrow_back_ios</i> Back To Previous Page</a>
  </div>
</div>

<div id="workPhoneModal" *ngIf="selectedEmployee" class=" modal valign-wrapper">
  <div class="modal-content">
    <div class="row col s12 center">
      <h5><strong> Work Phone </strong></h5>
      <a href="tel:{{formatedWorkPhone}}">
        <h5><strong>{{selectedEmployee.workPhone}}</strong> </h5>
      </a>
    </div>
  </div>
  <div class="modal-footer">
    <a class="modal-close  btn red">Close</a>
  </div>
</div>

<div id="mobilePhoneModal" *ngIf="selectedEmployee" class=" modal valign-wrapper">
  <div class="modal-content">
    <div class="row col s12 center">
      <h5><strong> Mobile Phone </strong></h5>
      <a href="tel:{{formatedMobilePhone}}">
        <h5><strong>{{selectedEmployee.mobilePhone}}</strong> </h5>
      </a>
    </div>
  </div>
  <div class="modal-footer">
    <a class="modal-close btn red">Close</a>
  </div>
</div>
