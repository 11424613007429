import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmployerMainComponent } from './employer-main/employer-main.component';
import { SearchSkillsComponent } from './search-skills/search-skills.component';
import { EmployerRoutingModule } from './employer-routing.module';
import { PostJobComponent } from './post-job/post-job.component';
import { HomeComponent } from './home/home.component';
import { KeyWordsHighlight } from 'src/app/Pipes/KeyWordsHighlightPipe';
import { MyContractsComponent } from './my-contracts/my-contracts.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { JobOfferService } from 'src/app/services/firebase/joboffer.service';
import { SharedModule } from '../shared/shared.module';
import { JobUpdateComponent } from './my-contracts/job-update/job-update.component';
import { JobArchiveComponent } from './job-archive/job-archive.component';


@NgModule({
  declarations: [
    EmployerMainComponent,
    SearchSkillsComponent,
    PostJobComponent,
    HomeComponent,
    KeyWordsHighlight,
    MyContractsComponent,
    JobUpdateComponent,
    JobArchiveComponent,
  ],
  imports: [
    CommonModule,
    EmployerRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
  ],
  providers:  [
    JobOfferService,
  ]
})

export class EmployerModule { }