import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';
import { StaticContentService } from 'src/app/services/firebase/static-content.service';
import { Options } from 'sortablejs';
import { Router } from '@angular/router';
// import { EmbedVideoService } from 'ngx-embed-video';
import { DatePipe } from '@angular/common';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';

declare var $: any;

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.css']
})

export class HomePageComponent implements OnInit {

  adminThemeColor = environment.appTheme.adminThemeColor;
  adminFontColor = environment.appTheme.adminFontColor;
  showLoading = false;
  model: any[];
  iframehtml: any;
  defaultThemeColor = environment.appTheme.themeColor;
  themeColor: any;
  options: Options = {
    animation: 150,
  };
  videoId: any;

  constructor(private location: Location,
    private staticContentService: StaticContentService,
    // private embedService: EmbedVideoService,
    private settingsService: AppSettingsService,
    public appSettingsService: AppSettingsService,
    private router: Router) {
    localStorage.setItem('from', 'Notification');
    this.themeColor = this.appSettingsService.getAppThemeColor() ? this.appSettingsService.getAppThemeColor() : this.defaultThemeColor;
    this.options = {
      onUpdate: (event: any) => {
        this.model.forEach(element => {
          element.index = this.model.findIndex(x => x.id === element.id);
        });
        this.staticContentService.SortContentOnServer(this.model);
      }
    };
  }

  ngOnInit() {
    this.showLoading = true;
    this.staticContentService.getContentList().valueChanges().subscribe(x => {
      if (x) {
        this.model = x;
        this.model = this.model.filter(e => e.published === true);
        this.model.sort((a, b) => a.datePublished - b.datePublished);
        this.model.sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1));

        this.showLoading = false;
      }
    });
    this.showLoading = false;
  }

  embedVideo(videoLink) {
    const videoIdMatch = videoLink.match(/(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([^&]+)|(?:https?:\/\/)?(?:www\.)?youtu\.be\/([^?]+)/);
    this.videoId = videoIdMatch ? (videoIdMatch[1] || videoIdMatch[2]) : '';
    return this.videoId;
  }

  truncateHTML(text: string, limit: string): string {
    const charlimit = +limit;
    if (!text || text.length <= charlimit) {
      return text.replace(/<(?:.|\n)*?>/gm, '').replace(/&nbsp;/gm, ' ');
    }
    return text.replace(/<(?:.|\n)*?>/gm, '').replace(/&nbsp;/gm, ' ').substring(0, charlimit) + '...';
  }

  getHowLongAgo(postDate) {
    const datePipe = new DatePipe('en-US');
    let today = new Date();
    let difference = datePipe.transform(today.getTime() - postDate.getTime(), 'dd');
    return parseInt(difference);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.model, event.previousIndex, event.currentIndex);
    // Update the display order
    this.model.forEach((item, index) => {
      item.displayOrder = index;
    });
    // Save the new order
    this.staticContentService.SortContentOnServer(this.model);
  }


  backClicked() {
    this.router.navigate(['/admin/dashboard']);
  }

}
