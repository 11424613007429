<h6 class="center"><strong>Share Department News/Event</strong></h6>

<div class="row col s12">
  <div class="col l1"></div>
  <div class="col s12 l10">
    <app-content-editor [contentType]="'DepNews'" [model]="myModel" [actionType]="'Add'"
      (contentSaved)="parentEventHandlerFunction($event)"></app-content-editor>
  </div>
  <div class="col l1"></div>
</div>
