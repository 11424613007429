<div class="col s12 ">
  <div class="body-h">

    <div class="header-setting b-m-2 ">
      <div class="col l9 m9 s12  p-b-2">
        <h4>Houses</h4>
      </div>

      <div class="col l3 m4 s12 p-m-2">
        <a [routerLink]="['/admin/dashboard-sub-menu/new-property']" class="add-button btn white center-align p-l-3"
          [ngStyle]="{'color': themeColor}"><i class="material-icons-outlined">add</i> Add&nbsp;
          <span>Houses</span></a>
      </div>
    </div>

    <main class="departments-list StickyContent  row " style="padding-top:0%;">


    <app-firebase-loading *ngIf="loading"></app-firebase-loading>

    <div class="row " *ngIf="properties">

      <div class="col s12 m12 l11">

        <div class="section"></div>

        <div class="row card-panel" *ngIf="properties.length == 0" style="margin-top: 15px;">
          <h6 class="red-text center-align">
            <i class="material-icons prefix">warning</i>
            <strong> 0 Houses Found In The Database </strong>
          </h6>
        </div>

        <form class="row card white" #filterForm2="ngForm" style="padding: 10px;" *ngIf="properties">
          <h6>Filter By</h6>
          <div class="input-field col s12 m6">
            <i class="material-icons prefix green-text">account_circle</i>
            <input name="Name_of_Owner" type="text" [(ngModel)]="filters.Name_of_Owner" (input)="filterList()"
              autocomplete="off" style="text-transform: capitalize;" />
            <label for="Name_of_Owner">Owner Name</label>
          </div>

          <div class="input-field col s12 m6">
            <i class="material-icons prefix red-text">tag</i>
            <input name="aid_of_owner" type="text" [(ngModel)]="filters.aid_of_owner" autocomplete="off"
              (input)="filterList()" style="text-transform: capitalize;" />
            <label for="aid_of_owner">Owner ID</label>
          </div>

          <div class="input-field col s12 m6">
            <i class="material-icons prefix blue-text">place</i>
            <input name="house_number" type="number" [(ngModel)]="filters.house_number" autocomplete="off"
              (input)="filterList()" style="text-transform: capitalize;" />
            <label for="house_number">House Number</label>
          </div>

          <div class="input-field col s12 m6">
            <i class="material-icons prefix orange-text">location_on</i>
            <select name="community" [(ngModel)]="filters.community" (change)="filterList()">
              <option value=''>All</option>
              <option *ngFor="let c of communities" [value]="c.community">{{c.community}}</option>
            </select>
            <label for="community">Community</label>
          </div>
        </form>

        <div class="row col s12 card white" *ngIf="properties?.length > 0">
          <div class="row col s12">
            <p class="center"><strong> Total : <span class="green-text"></span> {{count}} </strong> </p>

            <table class="striped highlight responsive-table" *ngIf="count > 0">
              <thead>
                <tr>
                  <th>House ID</th>
                  <th>Location</th>
                  <th>Owner ID</th>
                  <th>Owner Name</th>
                  <th>Mail Address</th>
                  <th>Community</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  *ngFor="let p of filteredProperties? filteredProperties: properties | paginate: { itemsPerPage: 20, currentPage: p }; let i=index;"
                  [routerLink]="['/admin/dashboard-sub-menu/property-detail', p.ID]" style="cursor: pointer;">
                  <td>{{p.ID}}</td>
                  <td>{{p.location}}</td>
                  <td>{{p.aid_of_owner > 0? p.aid_of_owner: ''}}</td>
                  <td>{{p.GivenName}} {{p.LastName}} </td>
                  <td>{{p.MailAddress}} </td>
                  <td>{{p.community}}</td>
                </tr>
              </tbody>
            </table>
            <div class="row center" *ngIf="count > 0">
              <pagination-controls (pageChange)="p = $event"></pagination-controls>
            </div>
          </div>
        </div>

        <div class="row col s12 card">
          <a [routerLink]="['/admin/dashboard-sub-menu/new-property']" style="margin-bottom: 15px;"
            class="col s12 btn waves-effect green">
            New House <i class="material-icons right">add</i></a>

          <a class='col s12 btn waves-effect blue dropdown-trigger' data-target='dropdown1'
            [ngClass]="{'disabled': properties?.length== 0}" style="margin-bottom: 15px;"><i
              class="material-icons right">picture_as_pdf</i>Report</a>
          <!-- Dropdown Structure -->
          <ul id='dropdown1' class='col s12 dropdown-content'>
            <li><a (click)="downloadReport()" class="btn-flat center-align"><i class="material-icons right">
                  download
                </i>Download Report</a></li>
            <li><a (click)="openReport()" class="btn-flat center-align"><i class="material-icons right">
                  open_in_new
                </i>Open Report</a></li>
            <li><a (click)="printReport()" class="btn-flat center-align"><i class="material-icons right">
                  print
                </i>Print Report</a></li>
            <li><a (click)="sendReport()" class="btn-flat center-align"><i class="material-icons right">
                  share
                </i>Send Report</a></li>
          </ul>

          <a [ngClass]="'col s12 btn waves-effect ' + themeColor" (click)="back()" style="margin-bottom: 25px;"><i
              class="material-icons left">arrow_back_ios</i> Back To Previous Page</a>
        </div>
      </div>
    
    </div>

    <div class="row  black-text center p-t-3" *ngIf="!properties" style="opacity: 0.5;">
      <img src="../../assets/img/empty-housing.png" height="450" width="450" class="responsive-img">
    </div>

    <div *ngIf="sendEmail" class="row col s12">
      <div class="col l1"></div>
      <div class="col s12 s10">
        <div>
          <app-send-email [emailList]="emailList" [fileList]="fileList" (emailSent)="emailSentHandler($event)">
          </app-send-email>
        </div>
      </div>
    </div>
</main>
  </div>
</div>