import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-firebase-loading',
  templateUrl: './firebase-loading.component.html',
  styleUrls: ['./firebase-loading.component.css']
})
export class FirebaseLoadingComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
