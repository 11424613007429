import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class UpdateProfileService {

  constructor(private db: AngularFirestore) { }

  profileUpdatesListRef: AngularFirestoreCollection<any>;
  profileUpdateRef: AngularFirestoreDocument<any>;

  saveUpdates(model: any){
    model.id = this.db.createId();
    return this.db.collection('ProfileUpdates').doc(model.id).set(model);
  }

  getProfileUpdates(){
    this.profileUpdatesListRef = this.db.collection('ProfileUpdates');
    return this.profileUpdatesListRef;
  }

  getProfileUpdateById(id: any): AngularFirestoreDocument<any>{
    this.profileUpdateRef = this.db.doc('ProfileUpdates/'+ id);
    return this.profileUpdateRef;
  }

  updateFamilyComp(model: any){
    model.id = this.db.createId();
    return this.db.collection('ProfileUpdates').doc(model.id).set(model);
  }

  saveFamilyCompUpdates(id: any, model: any){
    return this.db.collection('ProfileUpdates').doc(id).update({changes: model});
  }

  deleteFamilyCompUpdates(id: any){
    return this.db.collection('ProfileUpdates').doc(id).delete();

  }

}
