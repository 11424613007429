import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { LoadingComponent } from './loading/loading.component';
import { PersonalInfoComponent } from './member/personal-info/personal-info.component';
import { EntitlementComponent } from './member/monthly-assistance/entitlement/entitlement.component';
import { IsnStarsPipe } from 'src/app/Pipes/IsnStarsPipe';
import { SinStarsPipe } from 'src/app/Pipes/SinStarsPipe';
import { NameStarPipe } from 'src/app/Pipes/NameStarPipe';
import { NewsCardComponent } from './news-card/news-card.component';
import { FirebaseLoadingComponent } from './loading/firebase-loading/firebase-loading.component';
import { MobileNewsComponent } from './news-card/mobile-news/mobile-news.component';
import { NewsFeedComponent } from './news-feed/news-feed.component';
import { DateAgoPipe } from 'src/app/Pipes/DateAgoPipe';
import { TruncateTextPipe } from 'src/app/Pipes/TruncateTextPipe';
import { GovernanceviewComponent } from './governanceview/governanceview.component';
import { UploadDocumentComponent } from './upload-document/upload-document.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { PollsSurveyViewComponent } from './polls-survey-view/polls-survey-view.component';
import { PollsViewComponent } from './polls-view/polls-view.component';
// import { TextMaskModule } from 'angular2-text-mask';
import { ContentEditorComponent } from './content-editor/content-editor.component';
import { ContentViewerComponent } from './content-viewer/content-viewer.component';
import { ContactEditorComponent } from './contact-Editor/contact-Editor.component';
import { UserInboxComponent } from './user-inbox/user-inbox.component';
import { UserDiscussionComponent } from './user-discussion/user-discussion.component';
import { EventCalendarViewComponent } from './events/event-calendar-view/event-calendar-view.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { EventDetailViewComponent } from './events/event-detail-view/event-detail-view.component';

import { SafeUrlPipe } from 'src/app/Pipes/SafeUrlPipe';
import { GovMemberDetailSharedComponent } from './governanceview/gov-member-detail-shared/gov-member-detail-shared.component';
import { GovChiefDetailSharedComponent } from './governanceview/gov-chief-detail-shared/gov-chief-detail-shared.component';
import { JobPostingComponent } from './job-posting/job-posting.component';
import { MessagingMainComponent } from './messaging-main/messaging-main.component';
import { SmokeSignalComponent } from './messaging-main/smoke-signal/smoke-signal.component';
import { ChatContextMenuComponent } from './messaging-main/chat-context-menu/chat-context-menu.component';
import { AdminCircleComponent } from './messaging-main/admin-circle/admin-circle.component';
import { MessagingMemberComponent } from './messaging-main/messaging-member/messaging-member.component';
import { PostsDisplayComponent } from './messaging-main/posts-display/posts-display.component';

import { ShortNumberPipe } from '../../Pipes/short-number.pipe';
import { FileUploadPreviewComponent } from './messaging-main/file-upload-preview/file-upload-preview.component';
import { NewNotificationBadgeComponent } from './new-notification-badge/new-notification-badge.component';
import { PollsResultComponent } from './polls-result/polls-result.component';
import { NgChartsModule } from 'ng2-charts';
import { BoardListSharedComponent } from './boards/board-list/board-list-shared.component';
import { BoardDetailSharedComponent } from './boards/board-detail-shared/board-detail-shared.component';
import { SurveyResultComponent } from './survey-result/survey-result.component';
// import { GaugeChartModule } from 'angular-gauge-chart';
import { ElectionsResultComponent } from './elections-result/elections-result.component';
import { PropertyDetailsComponent } from './property-details/property-details.component';
import { SendEmailComponent } from './send-email/send-email.component';
import { NoSanitizePipe } from 'src/app/Pipes/NoSanitizePipe';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { LrrcnApplicationsComponent } from './lrrcn-applications/lrrcn-applications.component';
import { LRRCNSurveyDetailComponent } from './lrrcn-applications/lrrcn-survey-details/lrrcn-survey-detail.component';
import { RegistrationRequestEmployeeComponent } from './registration-request-employee/registration-request-employee.component';
import { IsetsInterventionComponent } from './isets-intervention/isets-intervention.component';
import { FrequencyNamePipe } from 'src/app/Pipes/FrequencyNamePipe';
import { UploadAsetsDocumentComponent } from './upload-asets-document/upload-asets-document.component';
import { AppStoreComponent } from './app-store/app-store.component';
import { DepartmentListComponent } from './department-list/department-list.component';
import { GovernanceListComponent } from './governance-list/governance-list.component';
import { LandingPageFooterComponent } from './landing-page-footer/landing-page-footer.component';
import { LandingPageSliderComponent } from './landing-page-slider/landing-page-slider.component';
import { LatestNewsComponent } from './latest-news/latest-news.component';
import { SocialMediaLinksComponent } from './social-media-links/social-media-links.component';
import { UpcomingEventsComponent } from './upcoming-events/upcoming-events.component';
import { ContentViewer1Component } from './content-viewer1/content-viewer1.component';
import { EditorComponent } from './editor/editor.component';
import { SideMenusComponent } from './side-menus/side-menus.component';
import { ContentEditorNewComponent } from './content-editor-new/content-editor-new.component';
import { RegisterLoginButtonsComponent } from './register-login-buttons/register-login-buttons.component';
import { LoginPreviewComponent } from './login-preview/login-preview.component';
import { ChatGptComponent } from './chat-gpt/chat-gpt.component';
import { CardViewLandingPageComponent } from './card-view-landing-page/card-view-landing-page.component';
import { SocialMediaMainComponent } from './social-media/social-media-main.component';
import { SocialMediaDashBoardComponent } from './social-media/dashboard/dashboard.component';
import { SocialMediaPostComponent } from './social-media/post/post.component';
import { SocialMediaAnalyticsComponent } from './social-media/analytics/analytics.component';
import { SocialMediaSettingsComponent } from './social-media/settings/settings.component';
import { SocialMediaCreatePostComponent } from './social-media/post/create-post/create-post.component';
import { LinkAccountComponent } from './social-media/link-account/link-account.component';
import { SocialMediaPlatformComponent } from './social-media/post/platform/platform.component';
import { AboutUsSectionComponent } from './about-us-section/about-us-section.component';
import { ImageUploaderComponent } from './image-uploader/image-uploader.component';
import { SectionListComponent } from './section-list/section-list.component';
import { ModalsComponent } from './modals/modals.component';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
// import { AngularMyDatePickerModule } from 'angular-mydatepicker';

@NgModule({
  declarations: [
    LoadingComponent,
    PersonalInfoComponent,
    EntitlementComponent,
    IsnStarsPipe,
    SinStarsPipe,
    NameStarPipe,
    NoSanitizePipe,
    NewsCardComponent,
    DateAgoPipe,
    TruncateTextPipe,
    FirebaseLoadingComponent,
    MobileNewsComponent,
    NewsFeedComponent,
    GovernanceviewComponent,
    UploadDocumentComponent,
    PollsSurveyViewComponent,
    PollsViewComponent,
    ContentEditorComponent,
    ContentViewerComponent,
    ContactEditorComponent,
    UserInboxComponent,
    UserDiscussionComponent,
    EventCalendarViewComponent,
    EventDetailViewComponent,
    SafeUrlPipe,
    GovMemberDetailSharedComponent,
    GovChiefDetailSharedComponent,
    JobPostingComponent,
    MessagingMainComponent,
    SmokeSignalComponent,
    ChatContextMenuComponent,
    AdminCircleComponent,
    MessagingMemberComponent,
    PostsDisplayComponent,
    ShortNumberPipe,
    FileUploadPreviewComponent,
    NewNotificationBadgeComponent,
    PollsResultComponent,
    BoardListSharedComponent,
    BoardDetailSharedComponent,
    SurveyResultComponent,
    ElectionsResultComponent,
    PropertyDetailsComponent,
    SendEmailComponent,
    LrrcnApplicationsComponent,
    LRRCNSurveyDetailComponent,
    RegistrationRequestEmployeeComponent,
    FrequencyNamePipe,
    IsetsInterventionComponent,
    UploadAsetsDocumentComponent,
    AppStoreComponent,
    DepartmentListComponent,
    GovernanceListComponent,
    LandingPageFooterComponent,
    LandingPageSliderComponent,
    LatestNewsComponent,
    SocialMediaLinksComponent,
    UpcomingEventsComponent,
    ContentViewer1Component,
    EditorComponent,
    SideMenusComponent,
    ContentEditorNewComponent,
    RegisterLoginButtonsComponent,
    LoginPreviewComponent,
    ChatGptComponent,
    CardViewLandingPageComponent,
    SocialMediaMainComponent,
    SocialMediaDashBoardComponent,
    SocialMediaPostComponent,
    SocialMediaAnalyticsComponent,
    SocialMediaSettingsComponent,
    SocialMediaCreatePostComponent,
    SocialMediaPlatformComponent,
    LinkAccountComponent,
    AboutUsSectionComponent,
    ImageUploaderComponent,
    SectionListComponent,
    ModalsComponent,
  ],

  exports: [
    LoadingComponent,
    PersonalInfoComponent,
    EntitlementComponent,
    NewsCardComponent,
    FirebaseLoadingComponent,
    MobileNewsComponent,
    NewsFeedComponent,
    DateAgoPipe,
    TruncateTextPipe,
    NameStarPipe,
    GovernanceviewComponent,
    UploadDocumentComponent,
    PollsSurveyViewComponent,
    PollsViewComponent,
    ContentEditorComponent,
    ContentViewerComponent,
    ContactEditorComponent,
    UserInboxComponent,
    UserDiscussionComponent,
    EventCalendarViewComponent,
    EventDetailViewComponent,
    GovMemberDetailSharedComponent,
    GovChiefDetailSharedComponent,
    JobPostingComponent,
    SafeUrlPipe,
    MessagingMainComponent,
    SmokeSignalComponent,
    ChatContextMenuComponent,
    AdminCircleComponent,
    MessagingMemberComponent,
    PostsDisplayComponent,
    NewNotificationBadgeComponent,
    PollsResultComponent,
    BoardListSharedComponent,
    BoardDetailSharedComponent,
    SurveyResultComponent,
    ElectionsResultComponent,
    PropertyDetailsComponent,
    SendEmailComponent,
    LrrcnApplicationsComponent,
    LRRCNSurveyDetailComponent,
    RegistrationRequestEmployeeComponent,
    FrequencyNamePipe,
    IsetsInterventionComponent,
    UploadAsetsDocumentComponent,
    AppStoreComponent,
    DepartmentListComponent,
    GovernanceListComponent,
    LandingPageFooterComponent,
    LandingPageSliderComponent,
    LatestNewsComponent,
    SocialMediaLinksComponent,
    UpcomingEventsComponent,
    ContentViewer1Component,
    EditorComponent,
    SideMenusComponent,
    ContentEditorNewComponent,
    RegisterLoginButtonsComponent,
    LoginPreviewComponent,
    ChatGptComponent,

    CardViewLandingPageComponent,
    SocialMediaMainComponent,
    SocialMediaDashBoardComponent,
    SocialMediaPostComponent,
    SocialMediaAnalyticsComponent,
    SocialMediaSettingsComponent,
    SocialMediaCreatePostComponent,
    SocialMediaPlatformComponent,
    LinkAccountComponent,
    AboutUsSectionComponent,
    ImageUploaderComponent,
    SectionListComponent,
    ModalsComponent,
    CommonModule
  ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    // TextMaskModule,
    CKEditorModule,
    NgChartsModule,
    // AngularMyDatePickerModule,
    // CalendarModule.forRoot({
    //   provide: DateAdapter,
    //   useFactory: adapterFactory,
    // }),
    // GaugeChartModule, 
    AngularEditorModule
  ],

})
export class SharedModule { }
