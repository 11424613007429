import { Component, OnInit } from '@angular/core';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { environment } from 'src/environments/environment';
import { Options } from 'sortablejs';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { toast } from 'materialize-css';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-admin-menu-settings',
  templateUrl: './admin-menu-settings.component.html',
  styleUrls: ['./admin-menu-settings.component.css']
})

export class AdminMenuSettingsComponent implements OnInit {

  defaultThemeColor = environment.appTheme.themeColor;
  themeColor: any;
  buttonType: any;
  sectionName: string;
  menu: any;
  isNewSetting = true;
  activeModalIndex: number = -1;

  defaultMenus: any[];

  options: Options = {
    animation: 150,
  };
  disciption = 'This is the content that will be displayed on the registration page';
  submenus: any[];
  editName = false;

  constructor(private settingService: AppSettingsService,
    private router: Router,
    private dataService: DataService) {
    this.themeColor = this.settingService.getAppThemeColor() ? this.settingService.getAppThemeColor() : this.defaultThemeColor;
    this.buttonType = this.settingService.getButtonType() ? this.settingService.getButtonType() : 'rectangularButton';
    
    this.defaultMenus = this.dataService.getAdminMenus();
    this.options = {
      animation: 150,
      filter: '.disabled',
      sort: true,
      onMove: event => {
        return !event.related.classList.contains('disabled');
      },
      onUpdate: (event: any) => {
        let element = this.menu[event.oldDraggableIndex];
        this.moveSection(element, event.newDraggableIndex);
      }
    };
  }

  ngOnInit(): void {
    this.settingService.getMenuName('Settings').valueChanges().subscribe(menus => {
      if (menus && menus.length > 0) {
        this.submenus = menus[0].submenus;
        let menu = this.submenus.find(m => m.name == 'AdminMenu');
        if (menu) this.sectionName = menu.displayName;
      }
    });
    this.settingService.getAdminMenus().valueChanges().subscribe(adminMenus => {
      if (adminMenus && adminMenus.length > 0) {
        this.menu = adminMenus;
        this.isNewSetting = false;
      } else {
        this.menu = this.defaultMenus;
        this.isNewSetting = true;
      }
      this.menu = this.menu.sort((a, b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0));
      // this.menu.forEach(menu => {
      //   if(menu.submenus && menu.submenus.length > 0){
      //     menu.submenus = menu.submenus.sort((a, b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0));
      //   }      
      // });


      setTimeout(() => {
        $('.collapsible').collapsible();
        $('.tooltipped').tooltip();
        $('.modal').modal();
        $('.modal').modal('close');
      }, 25);
    });
  }

  editNameClicked() {
    this.editName = true;
  }

  saveName() {
    let index = this.submenus.findIndex(m => m.name == 'AdminMenu');
    this.submenus[index].displayName = this.sectionName;
    this.settingService.UpdateMenu('Settings', this.submenus).then((update) => {
      this.editName = false;
      toast({ html: `${update}`, classes: 'green' });
    }).catch((error) => {
      toast({ html: `${error}`, classes: 'red' });
    });
  }

  visibilityChanged(section: any) {
    section.isEnabled = !section.isEnabled;
  }

  openModal(index: number) {
    this.activeModalIndex = index;
    const modalElement = document.getElementById(`modal${index}`);
    if (modalElement) {
      const modalInstance = M.Modal.getInstance(modalElement);
      modalInstance.open();
    }
  }

  moveSection(element, position: number) {
    var index = this.menu.indexOf(element);
    var newIndex = index + position;
    if (newIndex < 0 || newIndex == this.menu.length) return; //Already at the top or bottom.   
    this.menu.splice(index, 1);
    this.menu.splice(newIndex, 0, element);
    this.menu.forEach((element, i) => {
      element.index = i;
    });
  };

  moveSubmenu(section, element, position: number) {
    var index = this.menu[section].submenus.indexOf(element);
    var newIndex = index + position;
    if (newIndex < 0 || newIndex == this.menu[section].submenus.length) return; //Already at the top or bottom.   
    this.menu[section].submenus.splice(index, 1);
    this.menu[section].submenus.splice(newIndex, 0, element);
    this.menu[section].submenus.forEach(element => {
      element.index = this.menu[section].submenus.indexOf(element);
    });
  };

  saveChanges() {
    let saved = false;
    if (this.menu && this.isNewSetting) {
      this.menu.forEach(m => {
        this.settingService.saveAdminMenu(m);
        saved = true;
      });
      if (saved) {
        M.toast({ html: 'Changes Saved Successfully.', classes: 'green' });
      }
    }

    if (this.menu && !this.isNewSetting) {
      let updated = false;
      this.menu.forEach(m => {
        this.settingService.updateAdminMenu(m);
        updated = true;
      });
      if (updated) {
        M.toast({ html: 'Changes Saved Successfully.', classes: 'green' });
      }
    }
    this.closeModal();
  }

  closeModal() {
    $('.modal').modal('close');
  }

  backClicked() {
    this.router.navigate(['/admin/dashboard']);
  }
}
