import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { GovernanceService } from 'src/app/services/firebase/governance.service';
import { FireMembersService } from 'src/app/services/firebase/fire-members.service';
import { MemberService } from 'src/app/services/api/member.service';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/firebase/auth.service';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-archived-board-members',
  templateUrl: './archived-board-members.component.html',
  styleUrls: ['./archived-board-members.component.css']
})
export class ArchivedBoardMembersComponent implements OnInit {

  boardId: any;
  userId: any;
  model: any = [];
  board: any;
  themeColor = environment.appTheme.adminThemeColor;

  constructor(private govService: GovernanceService,
              private route: ActivatedRoute,
              private router: Router,
              private location: Location,
              private authService: AuthService,
              private memberService: MemberService) {}

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.boardId = params.get('id');
    });
    this.userId = this.authService.getGlobalUser().applicantID;
    if (this.boardId) {
      this.govService.getBoardById(this.boardId).valueChanges().subscribe(board => {
        if (board) {
          this.board = board;
        }
      });
    }

    this.govService.geBoardMembersByBoardId(this.boardId).valueChanges().subscribe(res => {
      if (res.length > 0) {
        res.forEach((member: any) => {
            this.memberService.getMember(member.memberId).subscribe(memberDetail => {
              if (memberDetail.length > 0) {
                  this.model.push({memberData: memberDetail[0], boardMember: member});
              }
            });
        });
      }
    });
    $(document).ready(() => {
      $('.modal').modal();
    });
  }

  backClicked() {
    this.location.back();
  }

  restoreMember(applicantID: any, boardMember: any) {
    if (boardMember.memberType === 'Chair') {
      if (this.board.boardChair.length > 0) {
        $('#chairExistsModal').modal('open');
      } else {
        localStorage.setItem('boardID', this.boardId);
        localStorage.setItem('boardMember', 'Chair');
        this.router.navigate(['admin/add-board-chair', applicantID]);
      }

    } else {
      localStorage.setItem('boardID', this.boardId);
      localStorage.setItem('boardMember', 'Member');
      this.router.navigate(['admin/add-board-chair', applicantID]);
    }
  }

  deleteMember(applicantID){
    this.govService.getBoardMemberByUserIdAndBoardId(applicantID, this.boardId).valueChanges().subscribe(member => {
      if (member.length > 0){
        this.govService.deleteBoardMember(member[0]);
      }
    });
    this.backClicked();
  }

  navigate(item) {
    const routeMappings = {
      'AboutUs': 'admin/dashboard-sub-menu/about-us',
      'NationInfo': 'admin/dashboard-sub-menu/nation-settings',
      'Departments': 'admin/dashboard-sub-menu/department',
      'Governance': 'admin/dashboard-sub-menu/governance',
      'AreaOfInterest': 'admin/dashboard-sub-menu/areas-of-interest',
    };

    const route = routeMappings[item];
    if (route) {
      this.router.navigate([route]);
    }
  }

}
