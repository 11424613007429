export class ElectionModel {
    id: string;
    name: string;
    startDate: Date;
    endDate: Date;
    canNominate: boolean;
    candidates: Array<string>;
    nomineeMinAge: number;
    voterMinAge: number;
    createdBy: string;
    DateCreated: string;
    showVotingResult: string;
    showVoteRecieve: string;
    showAllCandidatesVotes: string;
  }
export class CandidateModel {
  id: string;
  candidateId: string;
  candidateApprovedBy: string;
  electionId: string;

}
export class CandidateDetailModel {
  id: string;
  candidateId: string;
  facebook: string;
  twitter: string;
  instagram: string;
  youtube: string;
  campaignDescription: string;
  candidateFor: Array<string>;
  notifyVote: boolean;

}
