export class Application {
    name: string = '';
    description: string = '';
    department: string = '';
    logo: string = '';
    menu: Menu;
    sections: Section[];
}

export class Menu {
    name: string = '';
    color: string = ''
    icon: string = '';
}
export class Section {
    title: string = '';
    statement: string = '';
    order: number;
    fields: Fields[];
}

export class Fields {
    label: string = '';
    default_value: string = '';
    field_type: string = '';
    data_type: string = '';
    icon: string = '';
    required: boolean;
}