import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GovernanceService } from 'src/app/services/firebase/governance.service';

@Component({
  selector: 'app-board-details',
  templateUrl: './board-details.component.html',
  styleUrls: ['./board-details.component.css']
})
export class BoardDetailsComponent implements OnInit {

  id: any;
  model: any;
  
  constructor(private govservice: GovernanceService, private route: ActivatedRoute) { 
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });
  }

  ngOnInit() {
    if(this.id) {
      this.govservice.getBoardById(this.id).valueChanges().subscribe(b => {
        if(b) {
          this.model = b;          
        }
      })
    }
  }

}
