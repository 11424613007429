import { Injectable, Injector } from '@angular/core';
import * as firebase from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { MemberModel } from 'src/app/models/memberModel';
import { FireMembersService } from './fire-members.service';
import { from, Observable } from 'rxjs';
import { DepartmentService } from './department.service';
import { MemberAccountSettingsModel } from 'src/app/models/MemberAccountSettingsModel';
import { MemberAccountSettingsService } from './memberAccountSettings.service';
import { SessionsMonitorService } from './sessions-monitor.service';
import { FCMpushService } from './FCMpush.service';

declare var M: any;

@Injectable({
  providedIn: 'root'
})

export class AuthService {

  currentUser: firebase.User = null;
  fireMember: Observable<any>;
  member: Observable<any>;
  isLogged = false;
  role = '';
  status = '';
  applicantID = 0;
  GivenName = '';
  loggedInUser: any;
  confirmationResult: firebase.auth.ConfirmationResult;
  public isNewUser = false;

  globalUser: any;
  isMember = false;
  adminMember: any;

  // tslint:disable-next-line: max-line-length
  constructor(
    public afAuth: AngularFireAuth,
    private db: AngularFirestore,
    private router: Router,
    private fireMembersService: FireMembersService,
    private memberAccountSettingsService: MemberAccountSettingsService,
    private depService: DepartmentService,
    private session: SessionsMonitorService,
    private injector: Injector
  ) {

    this.afAuth.authState.subscribe(user => {
      if (user) {
        this.currentUser = user;
        this.processAccount(user.uid);
      }
      else {
        this.role = '';
        this.fireMember = null;
        this.isLogged = false;
        this.globalUser = null;
        this.role = null;
        this.status = null;
        this.loggedInUser = null;
        // this.router.navigate(['home']);
        this.isMember = false
      }
    });
  }

  getGlobalUser(): any {
    return this.globalUser;
  }

  getUserDataById(userId: string): Observable<any> {
    return this.db.collection('BandMembers').doc(userId).valueChanges();
  }
  updateUserData(userId: string, userData: any): Observable<void> {
    console.log('Updating user data:', userId, userData);
    const userDocRef = this.db.collection('BandMembers').doc(userId);
    return from(userDocRef.update(userData));
  }
  
  
  
  private processAccount(userId: any) {
    this.fireMember = this.fireMembersService.getMemberByID(userId).valueChanges();
    this.fireMember.subscribe(data => {
      if (data) {

        this.isLogged = true;
        this.globalUser = data;
        this.role = data.role;
        this.status = data.status;
        this.loggedInUser = data;
        this.isMember = data.isMember

        if (this.status !== 'Enabled') {
          this.signOut();
          M.toast({ html: 'Your account is disabled please contact your case manager.', classes: 'red' });
          return;
        }
        if (this.role === 'Member') {

          this.applicantID = data.applicantID;
          localStorage.setItem('applicantID', data.applicantID.toString());
          localStorage.setItem('userFirstName', data.GivenName);
          localStorage.setItem('userLastName', data.LastName);
          if (data.isChief || data.isCouncillor) {
            this.router.navigate(['/member/access']);
            return;
          } else {
            this.router.navigate(['/member/member-main-home/member-home']);
            return;
          }

        } else if (this.role === 'Admin') {
          // this.session.startSession(data.uid);
          localStorage.setItem('userFirstName', data.FirstName);
          localStorage.setItem('userLastName', data.LastName);;
          if (data.isMember) {
            this.router.navigate(['/member/access']);
            return
          } else {
            this.router.navigate(['/admin/dashboard']);
            return
          }
        } else if (this.role === 'Department-Employee' && this.isLogged) {
          localStorage.setItem('userFirstName', data.givenName);
          localStorage.setItem('userLastName', data.lastName);
          this.router.navigate(['/employee/home']);
          return;
        } else if (this.role === 'Employer') {
          localStorage.setItem('company', data.name);
          //  this.router.navigate(['/employer/home']);
          return;
        }
      }
    });
  }

  emailLogin(email: string, password: string) {
    return this.afAuth
      .signInWithEmailAndPassword(email, password)
      .then(x => {
        this.isNewUser = x.additionalUserInfo.isNewUser;
        this.fireMember = this.fireMembersService.getMemberByID(x.user.uid).valueChanges();
        this.fireMember.subscribe(data => {
          if (data) {
            // auth().setPersistence(auth.Auth.Persistence.LOCAL);
            this.loggedInUser = data;
            this.role = data.role;
            this.status = data.status;
            this.globalUser = data;
            if (this.status !== 'Enabled') {
              this.signOut();
              M.toast({ html: 'Your account is disabled please contact your case manager.', classes: 'red' });
              return;
            }

            if (data.role === 'Member') {
              this.applicantID = data.applicantID;
              localStorage.setItem('applicantID', data.applicantID.toString());
              localStorage.setItem('userFirstName', data.GivenName);
              localStorage.setItem('userLastName', data.LastName);

              let fcmService = this.injector.get<FCMpushService>(FCMpushService)
              fcmService.getToken()
              if (data.isChief || data.isCouncillor) {
                this.router.navigate(['/member/access']);
                return;
              } else {

                this.router.navigate(['/member/member-main-home/member-home']);
                return;
              }
            } else if (data.role === 'Admin') {
              localStorage.setItem('userFirstName', data.FirstName);
              localStorage.setItem('userLastName', data.LastName);

              if (data.isMember) {
                this.router.navigate(['/member/access']);
                return
              } else {
                this.router.navigate(['/admin/dashboard']);
                return
              }
            } else if (data.role === 'Department-Employee') {
              localStorage.setItem('userFirstName', data.givenName);
              localStorage.setItem('userLastName', data.lastName);
              this.router.navigate(['/employee/home']);
              return;
            } else if (data.role === 'Employer') {
              localStorage.setItem('company', data.name);
              this.router.navigate(['/employer/home']);
            } else if (data.role === 'Employee') {
              this.applicantID = data.applicantID;
              localStorage.setItem('userFirstName', data.givenName);
              localStorage.setItem('userLastName', data.lastName);
              this.router.navigate(['/employee/home']);
              return;
            }
          }
        });
        return x.user;
      });
  }

  phoneNumberVerification(num: any, appVerifier: any) {
    return this.afAuth.signInWithPhoneNumber(num, appVerifier).then(result => {
      this.confirmationResult = result;
    });
  }

  phoneNumberLogin(verificationCode: any) {
    return this.confirmationResult.confirm(verificationCode).then(x => {
      this.isNewUser = x.additionalUserInfo.isNewUser;
      this.fireMember = this.fireMembersService.getMemberByID(x.user.uid).valueChanges();
      this.fireMember.subscribe(data => {
        this.loggedInUser = data;
        this.applicantID = data.applicantID;
        this.role = data.role;
        this.status = data.status;

        if (this.status !== 'Enabled') {
          this.signOut();
          M.toast({ html: 'Your account is disabled please contact your case manager.', classes: 'red' });
          return;
        }

        // firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION);
        // toast({ html: 'Authenticated Successfully !', classes: 'green' });
        if (data.role === 'Member') {
          localStorage.setItem('applicantID', data.applicantID.toString());
          this.router.navigate(['/member/member-main-home/member-home']);
        } else if (data.role === 'Admin') {
          this.router.navigate(['/admin/dashboard']);
        } else if (data.role === 'Employer') {
          this.router.navigate(['/employer/home']);
        }
      });
      return x.user;
    })
      .catch(error => M.toast({ html: error.message, classes: 'red' }));
  }

  // facebookLogin() {
  //   const provider = new auth.FacebookAuthProvider();
  //   provider.addScope('id');
  //   provider.addScope('first_name');
  //   provider.addScope('last_name');
  //   provider.addScope('picture');
  //   return auth().signInWithPopup(provider).then(x => {
  //     // The signed-in user info.
  //     const user = x.user;
  //    
  //     // ...
  //   }).catch(error => {
  //     // Handle Errors here.
  //     const errorCode = error.code;
  //     const errorMessage = error.message;
  //     // The email of the user's account used.
  //     const email = error.email;
  //     // The firebase.auth.AuthCredential type that was used.
  //     const credential = error.credential;

  //   });
  // }

  emailSignUpWithOtherRoles(member: any, password: string, additionalRoles: any) {
    return this.afAuth
      .createUserWithEmailAndPassword(member.Email, password)
      .then(credential => {
        this.isNewUser = credential.additionalUserInfo.isNewUser;
        const newUser = {
          uid: credential.user.uid,
          applicantID: member.applicantID,
          GivenName: member.GivenName,
          middle_name: (member.middle_name) ? member.middle_name : '',
          LastName: member.LastName,
          DOB: (member.DOB) ? member.DOB : '',
          Sex: (member.Sex) ? member.Sex : '',
          Band: member.Band,
          Email: member.Email,
          DateAdded: new Date(),
          role: 'Member',
          status: 'Enabled',
          lastLogin: new Date(),
          loginCount: 0,
          community: (member.Community) ? member.Community : '',
          residency: (member.Residency) ? member.Residency : '',
          isChief: additionalRoles.isChief,
          isCoucillor: additionalRoles.isCouncillor,
          isBoardMember: additionalRoles.isBoardMember,
          profileImgLink: member.profileImgLink ? member.profileImgLink : '',
          isCandidate: member.isCandidate ? member.isCandidate : false
        };

        setTimeout(() => {
          this.db.collection('BandMembers').doc(newUser.uid).set(newUser).then(() => {
            this.router.navigate(['/member/member-main-home/member-home']);
          });

          const newMemberSettings = new MemberAccountSettingsModel();
          newMemberSettings.memberId = credential.user.uid;
          newMemberSettings.permissions = [{ allowChat: true }, { allowEmail: true },
          { allowNomination: false }, { allowNotifications: true }, { allowSearch: true },
          { contactMe: true }, { shareLocation: true }, { allowContactByEmployer: true },
          { allowViewResume: true, allowContactByChiefAndCouncil: true }];
          this.memberAccountSettingsService.createAccountSetting(newMemberSettings);
        }, 25);

      }).catch(error => { throw error; });
  }

  emailSignUp(member: any, password: string) {
    return this.afAuth
      .createUserWithEmailAndPassword(member.Email, password)
      .then(credential => {
        this.isNewUser = credential.additionalUserInfo.isNewUser;
        const newUser = {
          uid: credential.user.uid,
          applicantID: member.applicantID,
          GivenName: member.GivenName,
          middle_name: (member.middle_name) ? member.middle_name : '',
          LastName: member.LastName,
          DOB: (member.DOB) ? member.DOB : '',
          Sex: (member.Sex) ? member.Sex : '',
          Band: member.Band,
          Email: member.Email,
          DateAdded: new Date(),
          role: 'Member',
          status: 'Enabled',
          lastLogin: new Date(),
          loginCount: 0,
          community: (member.Community) ? member.Community : '',
          residency: (member.Residency) ? member.Residency : '',
          isDemo: (member.role) ? true : false,
          verified: (member.verified === true || member.verified === false) ? member.verified : true,
        };

        setTimeout(() => {
          this.db.collection('BandMembers').doc(newUser.uid).set(newUser).then(() => {
            this.router.navigate(['/member/member-main-home/member-home']);
          });
        }, 35);
      })
      .catch(error => {
        if (error.code == 'auth/email-already-in-use') {
          this.fireMembersService.getMemberByEmail(member.Email).get().toPromise().then(result => {
            result.forEach(x => {
              if (x && x.data().role == 'Admin' && !x.data().isMember) {
                this.adminMember = x.data()
                this.adminMember.isMember = true
                this.adminMember.applicantID = member.applicantID
                this.adminMember.GivenName = member.GivenName
                this.adminMember.middle_name = (member.middle_name) ? member.middle_name : ''
                this.adminMember.LastName = member.LastName
                this.adminMember.DOB = (member.DOB) ? member.DOB : ''
                this.adminMember.Sex = (member.Sex) ? member.Sex : ''
                this.adminMember.Band = member.Band
                // this.adminMember.Email = member.Email
                this.adminMember.status = 'Enabled'
                this.adminMember.lastLogin = new Date()
                this.adminMember.loginCount = 0
                this.adminMember.community = (member.Community) ? member.Community : ''
                this.adminMember.residency = (member.Residency) ? member.Residency : ''
                this.adminMember.isDemo = (member.role) ? true : false

                let newMemberSettings = new MemberAccountSettingsModel();
                newMemberSettings.memberId = this.adminMember.uid;
                newMemberSettings.permissions = [{ allowChat: true }, { allowEmail: true },
                { allowNomination: false }, { allowNotifications: true }, { allowSearch: true },
                { contactMe: true }, { shareLocation: true }, { allowContactByEmployer: true },
                { allowViewResume: true, allowContactByChiefAndCouncil: true }];

                this.fireMembersService.updateAccount(this.adminMember)
                // this.fireMembersService.updateAccountByMemberId(this.adminMember)
                this.memberAccountSettingsService.createAccountSetting(newMemberSettings);

                this.router.navigate(['auth/login']);
              }

              if ((x.data().role == 'Admin' && x.data().isMember) || x.data().role == 'Member') {
                M.toast({ html: `User ${x.data().GivenName} is already registered as a member.`, classes: 'red' });
              }
            })
          })
        }
      })
  }

  demoEmailSignUp(model: any, password: string) {
    return this.afAuth
      .createUserWithEmailAndPassword(model.email, password)
      .then(credential => {
        model.uid = credential.user.uid;
        this.db.collection('BandMembers').doc(model.uid).set(model).then(() => {
          this.router.navigate(['/member/member-main-home/member-home']);
        }
        );
      }).catch(error => { throw error; });
  }

  phoneSignUp(member: MemberModel, phone: any, verifier: any) {
    return this.afAuth.signInWithPhoneNumber(phone, verifier).then(resp => {
    });
  }

  adminEmailSignUp(model: any, isDemoAccount: any, password: string) {
    return this.afAuth
      .createUserWithEmailAndPassword(model.Email_Address, password)
      .then(credential => {
        if (credential) {
          const newAdmin = {
            uid: credential.user.uid,
            id: model.uid,
            FirstName: model.FirstName,
            LastName: model.LastName,
            Email: model.Email_Address,
            DateAdded: new Date(),
            role: 'Admin',
            status: 'Enabled',
            isDemo: isDemoAccount,
            permissions: model.permissions ? model.permissions : [],
          };
          setTimeout(() => {
            this.db.collection('BandMembers').doc(newAdmin.uid).set(newAdmin).then(() => {
              this.router.navigate(['/admin/dashboard']);
            });
          }, 25);
        }
      }).catch(error => { throw error; });
  }

  employeeEmailSignUp(model: any, password: string) {
    return this.afAuth
      .createUserWithEmailAndPassword(model.email ? model.email : model.Email, password)
      .then(credential => {
        setTimeout(() => {
          model.uid = credential.user.uid;
          this.depService.saveEmployee(model).then(() => {
          });
          let newMemberSettings = new MemberAccountSettingsModel();
          newMemberSettings.memberId = credential.user.uid;
          newMemberSettings.permissions = [{ allowChat: true }, { allowEmail: true },
          { allowNomination: false }, { allowNotifications: true }, { allowSearch: true },
          { contactMe: true }, { shareLocation: true }, { allowContactByEmployer: true },
          { allowViewResume: true, allowContactByChiefAndCouncil: true }];
          this.memberAccountSettingsService.createAccountSetting(newMemberSettings);
        }, 25);
      })
      .catch(error => { throw error; });
  }

  linkAccounts(model: any, password: string) {

  }

  // employeeEmailSignUp2(email: string, password: string) {
  //   return this.afAuth.auth
  //     .createUserWithEmailAndPassword(email, password)
  //     .then(credential => {
  //       return credential;
  //     })
  //     .catch(error => { throw error; });
  // }

  // departmenetEmployeeEmailSignUp(member: MemberModel, password: string) {
  //   return this.afAuth.auth
  //     .createUserWithEmailAndPassword(member.Email, password)
  //     .then(credential => {
  //       this.isNewUser = credential.additionalUserInfo.isNewUser;
  //       const newUser = {
  //         uid: credential.user.uid,
  //         applicantID: member.applicantID,
  //         GivenName: member.GivenName,
  //         middle_name: member.middle_name,
  //         LastName: member.LastName,
  //         DOB: member.DOB,
  //         Sex: member.Sex,
  //         Band: member.Band,
  //         Email: member.Email,
  //         DateAdded: new Date(),
  //         role: 'Member',
  //         status: 'Enabled'
  //       };
  //       this.db.collection('BandMembers').doc(newUser.uid).set(newUser).then(() => {
  //         this.router.navigate(['member/home']);
  //       }
  //       );
  //     }).catch(error => { throw error; });
  // }

  employerEmailSignUp(model: any, password: string) {
    return this.afAuth
      .createUserWithEmailAndPassword(model.email, password)
      .then(credential => {
        const newEmployer = {
          uid: credential.user.uid,
          name: model.name,
          address: model.address,
          city: model.city,
          province: model.province,
          phone: model.phone,
          email: model.email,
          DateAdded: new Date(),
          role: 'Employer',
          status: 'Enabled'
        };

        setTimeout(() => {
          this.db.collection('BandMembers').doc(newEmployer.uid).set(newEmployer).then(() => {
            localStorage.setItem('company', newEmployer.name);
            //this.router.navigate(['/employer/home']);
          });
        }, 25);
      }).catch(error => { throw error; });
  }

  getCurrentUser() {
    return this.currentUser;
  }

  isFirstLogin() {
    return this.isNewUser;
  }

  getCurrentApplicantID() {
    return this.applicantID;
  }

  getCurrentApplicantIDObservable() {
    if (this.currentUser) {
      this.fireMember = this.fireMembersService.getMemberByID(this.currentUser.uid).valueChanges();
      return this.fireMember;
    }
  }

  resetPassword(email: string) {
    return this.afAuth.sendPasswordResetEmail(email);
  }

  signOut() {
    localStorage.clear();
    this.role = '';
    this.fireMember = null;
    this.isLogged = false;
    this.globalUser = null;
    this.status = null;
    this.loggedInUser = null;

    this.afAuth.signOut().then(() => {
      // M.toast({ html: '<i class="material-icons">lock</i> Successfully Loged Out.', classes: 'green' });
      // this.router.navigate(['home']);
      window.location.reload();
    });
  }

  getRole() {
    return this.role;
  }

  signOutWithoutRedirect() {
    localStorage.clear();
    this.role = '';
    return this.afAuth.signOut().then(() => {
    });
  }

  get currentUserObservable(): any {
    return this.afAuth.authState;
  }

  // --------------------------- SESSION MONITOR -----------------------------------

}