import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

const startApp = () => {
  platformBrowserDynamic().bootstrapModule(AppModule);
};



if (typeof window['cordova'] !== 'undefined') {
  document.addEventListener('deviceready', () => {
    // Start The App After Cordova Loads
    startApp();
    // ---------------------------------
  }, false);
} else {
  console.log('Cordova not loaded');
  startApp();
}





