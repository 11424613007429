import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
// import { EmbedVideoService } from 'ngx-embed-video';
import { MemberAccountSettingsModel } from 'src/app/models/MemberAccountSettingsModel';
import { MemberService } from 'src/app/services/api/member.service';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { DepartmentService } from 'src/app/services/firebase/department.service';
import { EventsService } from 'src/app/services/firebase/events.service';
import { FireMembersService } from 'src/app/services/firebase/fire-members.service';
import { HousingService } from 'src/app/services/firebase/housing.service';
import { MemberAccountSettingsService } from 'src/app/services/firebase/memberAccountSettings.service';
import { MessagingService } from 'src/app/services/firebase/messaging.service';
import { StaticContentService } from 'src/app/services/firebase/static-content.service';
import { TempHousingService } from 'src/app/services/firebase/temp-housing.service';
import { VerficationRequestService } from 'src/app/services/firebase/verfication-request.service';
import { environment } from 'src/environments/environment';
import { toast } from 'materialize-css';
import { NationSettingsService } from 'src/app/services/api/nation-settings.service';
import { DataService } from 'src/app/services/data.service';
import { SectionSettingService } from 'src/app/services/firebase/section-setting.service';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-member-main-home',
  templateUrl: './member-main-home.component.html',
  styleUrls: ['./member-main-home.component.css']
})

export class MemberMainHomeComponent implements OnInit {

  user: any;
  isNewUser = false;
  fontColor = environment.appTheme.fontColor;
  nationName = environment.firstNation.displayName;
  nationOfUse = environment.firstNation.name;
  defaultThemeColor = '#000000';
  themeColor: any;
  buttonType: any;
  defaultMenus: any;
  menus: any;
  showDefaultMenu = false;
  loading = false;
  isEmployeeMember = false;
  model: any;

  isChiefOrCouncillor = false;
  member: any;

  isAdminMember = false;
  userRole: any;

  //*********** Settings *****************************/
  permissions: any;
  settingsModel: any;
  allowChat; allowEmail; allowNomination = false;
  allowNotifications; allowSearch; contactMe; shareLocation; allowContactByEmployer = false;
  allowViewResume = false; allowContactByChiefAndCouncil;
  //************************************************/

  applicantID = 0;
  selectedItem = {
    displayName: 'My Account',
    icon: 'account_circle',
  }
  directSignup = true;
  globalUser: any;
  showGetVerified = false;
  verificationStatus: any;
  verificationReqExist = false;
  nationContactInfo: any;
  customSections: any[];
  logoutMenu: any;
  selectedMenuItem: any;

  constructor(
    private authService: AuthService,
    private router: Router,
    private appSettingService: AppSettingsService,
    private departmentService: DepartmentService,
    private memberService: MemberService,
    private memberAccountSettingsService: MemberAccountSettingsService,
    public appSettingsService: AppSettingsService,
    private verficationRequestService: VerficationRequestService,
    private nationSettingsService: NationSettingsService,
    private dataService: DataService,
    private sectionService: SectionSettingService,
  ) {
    this.isNewUser = this.authService.isFirstLogin();
    this.globalUser = this.authService.getGlobalUser();

    this.appSettingsService.getAppSettingsList().valueChanges().subscribe(
      appSettings => {
        if (appSettings) {
          if (appSettings.length > 0) {
            this.model = appSettings[0];
            this.themeColor = this.model.themeColor ? this.model.themeColor : this.defaultThemeColor;
            this.buttonType = this.model.buttonType ? this.model.buttonType : 'rectangularButton';
            this.directSignup = this.model.directSignup ? this.model.directSignup : false;
          }
        }
      });

    this.defaultMenus = this.dataService.getMemberMenus();
  }

  ngOnInit() {
    if (localStorage.getItem('applicantID')) {
      this.applicantID = Number(localStorage.getItem('applicantID'));
    } else {
      this.applicantID = this.authService.getCurrentApplicantID();
    }

    if (this.globalUser && this.directSignup) {
      this.verficationRequestService.getRequestByUID(this.globalUser.uid).valueChanges().subscribe(x => {
        if (x && x.length > 0) {
          this.verificationReqExist = true;
          let verficationReq: any = x[0];
          this.verificationStatus = verficationReq.status;
        }
      });

      if (!this.globalUser.verified) this.showGetVerified = true;
    }
    this.appSettingsService.getMemberMenus().valueChanges().subscribe(menus => {
      if (menus && menus.length > 0) {
        this.menus = menus;
        this.menus = this.menus.sort((a, b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0));
        this.showDefaultMenu = false;
      } else {
        this.menus = this.defaultMenus.sort((a, b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0));
        this.showDefaultMenu = true;
      }

      let logoutIndex = this.menus.findIndex(menus => menus.name == 'Logout');
      if(logoutIndex) this.logoutMenu = this.menus.splice(logoutIndex, 1)[0];
      // this.logoutMenu = this.menus.find(menu => menu.name == 'Logout');
      this.loading = false;
    });


    this.sectionService.getMemberSections().valueChanges().subscribe(cs => {
      if(cs && cs.length > 0){
        this.customSections = cs;
      }

    });

    if (!this.globalUser?.verified) {
      this.nationSettingsService.getNationSettings().subscribe(sett => {
        if (sett) {
          this.nationContactInfo = sett[0];
        }
      });
    }

    if (this.isNewUser) {
      let newMemberSettings = new MemberAccountSettingsModel();
      newMemberSettings.memberId = this.authService.currentUser.uid;
      newMemberSettings.permissions = [{ allowChat: true }, { allowEmail: true },
      { allowNomination: true }, { allowNotifications: true }, { allowSearch: true },
      { contactMe: true }, { shareLocation: true }, { allowContactByEmployer: true },
      { allowViewResume: true, allowContactByChiefAndCouncil: true }];
      this.memberAccountSettingsService.createAccountSetting(newMemberSettings);

      // this.settings.getAccoutSetting(this.authService.getGlobalUser().uid).valueChanges().subscribe(
      //   data => {
      //     if (data) {
      //       this.settingsModel = data[0];

      //       this.settingsModel.permissions.forEach(element => {
      //         if (Object.keys(element)[0] == 'shareLocation') {
      //           this.shareLocation = element.shareLocation;
      //         } else if (Object.keys(element)[0] == 'allowEmail') {
      //           this.allowEmail = element.allowEmail;
      //         } else if (Object.keys(element)[0] == 'allowNotifications') {
      //           this.allowNotifications = element.allowNotifications;
      //         } else if (Object.keys(element)[0] == 'allowChat') {
      //           this.allowChat = element.allowChat;
      //         } else if (Object.keys(element)[0] == 'contactMe') {
      //           this.contactMe = element.contactMe;

      //         } else if (Object.keys(element)[0] == 'allowSearch') {
      //           this.allowSearch = element.allowSearch;

      //         } else if (Object.keys(element)[0] == 'allowNomination') {
      //           this.allowNomination = element.allowNomination;

      //         } else if (Object.keys(element)[0] == 'allowContactByEmployer') {
      //           this.allowContactByEmployer = element.allowContactByEmployer;

      //         } else if (Object.keys(element)[0] == 'allowViewResume') {
      //           this.allowViewResume = element.allowViewResume;

      //         } else if (Object.keys(element)[0] == 'allowContactByChiefAndCouncil') {
      //           this.allowContactByChiefAndCouncil = element.allowContactByChiefAndCouncil;
      //         }
      //       });

      //       setTimeout(() => {
      //         if(!localStorage.getItem('isPreferenceSet')) {localStorage.setItem('isPreferenceSet', 'No'); }

      //         $('.tap-target').tapTarget();
      //         $('.modal').modal();
      //         // if (this.isNewUser) {
      //         // $('#welcomeModal').modal('open');
      //         if(localStorage.getItem('isPreferenceSet')) {            
      //           if(localStorage.getItem('isPreferenceSet') === 'No') {
      //             $('#settingModal').modal('open');
      //           }
      //         }

      //         // }       
      //       }, 25);
      //     }
      //   });

    } else {
      setTimeout(() => {
        $('.tap-target').tapTarget();
        $('.modal').modal();
        $('.dropdown-trigger').dropdown({
          constrainWidth: false,
          isScrollable: true,
        });
      }, 25);

      this.departmentService.getDepartmentList().valueChanges().subscribe(x => {
        if (x) {
          this.model = x;
          this.model.sort((a, b) => (a.index < b.index ? -1 : 1));
          setTimeout(() => {
            $('.collapsible').collapsible();
            $('.dropdown-trigger').dropdown({
              constrainWidth: false,
              isScrollable: true,
            });
          }, 25);
        }
      });
    }
  }



  select(menu, icon) {
    this.selectedItem.displayName = menu;
    this.selectedItem.icon = icon;
  }

  navigate(item) {
    //for Batc 
    this.selectedMenuItem = item;
    // if (item == 'Logout') this.signOut();
    if (item.toLowerCase() === 'logout') {
      this.signOut();
      return;
    }
    else if (item === 'MyAccount') this.myProfileClicked();
    else if (item == 'NewsAndEvents') this.NewsEventsClick();
    else if (item == 'News') this.NewsClicked();
    else if (item == 'Events') this.EventsClicked();
    else if (item === 'MyChat') this.router.navigate(['member/member-main-home/messages']);
    else if (item == 'MyNation') this.myNationClicked();
    else if (item == 'MyServices') this.myservicesClicked();
    else if (item == 'PollsAndElection') this.pollsAndSurveysClicked();
    else if (item == 'JobRelated') this.jobRelatedClicked();
    else if (item == 'MyHouse') this.myHouseClicked();
    else if (item == 'SocialDevelopment') this.socialAssistanceClicked();
  }

  navigateTo(sectionId){
    this.router.navigate(['member/member-main-home/section', sectionId]);
  }

  getVerified() {
    if (this.globalUser) {
      this.router.navigate(['/member/member-main-home/get-verified', this.globalUser?.uid]);
    } else {
      toast({ html: 'User ID can not be found.', classes: 'red' });
    }
    $('.modal').modal('close');
  }

  changeFrom(from) {
    this.appSettingService.updateFromMenu(from);
  }

  myProfileClicked() {
    localStorage.setItem('From', 'MyAccount');
    this.changeFrom('MyAccount');
    this.router.navigate(['member/member-main-home/member-home-sub-Menu']);
  }

  NewsEventsClick() {
    localStorage.setItem('From', 'NewsAndEvents');
    this.changeFrom('NewsAndEvents');
    this.router.navigate(['member/member-main-home/member-home-sub-Menu']);
  }

  NewsClicked() {
    localStorage.setItem('From', 'NewsAndEvents');
    this.router.navigate(['member/member-main-home/news']);
  }

  EventsClicked() {
    localStorage.setItem('From', 'Events');
    this.router.navigate(['member/member-main-home/events-calendar']);
  }

  myservicesClicked() {
    localStorage.setItem('From', 'MyServices');
    this.changeFrom('MyServices');
    this.router.navigate(['member/member-main-home/member-home-sub-Menu']);
  }

  myNationClicked() {
    localStorage.setItem('From', 'MyNation');
    this.changeFrom('MyNation');
    this.router.navigate(['member/member-main-home/member-home-sub-Menu']);
  }

  jobRelatedClicked() {
    localStorage.setItem('From', 'JobRelated');
    this.changeFrom('JobRelated');
    this.router.navigate(['member/member-main-home/member-home-sub-Menu']);
  }

  socialAssistanceClicked() {
    localStorage.setItem('From', 'SocialDevelopment');
    this.changeFrom('SocialDevelopment');
    this.router.navigate(['member/member-main-home/member-home-sub-Menu']);
  }

  socialNetworkClicked() {
    localStorage.setItem('From', 'SocialDevelopment');
    this.changeFrom('SocialDevelopment');
    this.router.navigate(['member/member-main-home/member-home-sub-Menu']);
  }

  pollsAndSurveysClicked() {
    localStorage.setItem('From', 'PollsAndElection');
    this.changeFrom('PollsAndElection');
    this.router.navigate(['member/member-main-home/member-home-sub-Menu']);
  }

  ContactUsClicked() {
    localStorage.setItem('From', 'ContactUs');
    this.changeFrom('ContactUs');
    this.router.navigate(['member/member-home-sub-Menu']);
  }

  myHouseClicked() {
    localStorage.setItem('From', 'MyHouse');
    this.changeFrom('MyHouse');
    this.router.navigate(['member/member-main-home/member-home-sub-Menu']);
  }

  myResumeClicked() {
    localStorage.setItem('From', 'MyResume');
    this.router.navigate(['member/member-home-sub-Menu']);
  }

  switchAccount() {
    if (this.isEmployeeMember) {
      this.router.navigate(['/employee/home']);
    } else {
      this.router.navigate(['/admin/dashboard']);
    }
  }

  editDepartmentData(event: Event, id?: any) {
    event.preventDefault();
    this.router.navigate(['/member/manage-department', id]);
  }

  signOut() {
    this.authService.signOut();
    this.router.navigate(['/auth/login']);
  }

  get_invitation_status() {
    this.memberService.getMember(this.applicantID).subscribe(data => {
      if (data) {

        const tmp = data[0];

        if (tmp) {
          if (tmp.PWA_invite_status && tmp.PWA_invite_status == 'Authenticated') {
            localStorage.setItem('SocialOK', 'YES');
            // this.SocialOK = true;
          } else {
            localStorage.setItem('SocialOK', 'NO');
            // this.SocialOK = false;
          }

          if (tmp.PSE_PWA_invite_status && tmp.PSE_PWA_invite_status == 'Authenticated') {
            localStorage.setItem('PSEOK', 'YES');
          } else {
            localStorage.setItem('PSEOK', 'NO');
          }

          if (tmp.ISETS_PWA_invite_status && tmp.ISETS_PWA_invite_status == 'Authenticated') {
            localStorage.setItem('ISETSOK', 'YES');
          } else {
            localStorage.setItem('ISETSOK', 'NO');
          }
        }
        localStorage.setItem('From', 'myServices');
        this.router.navigate(['member/member-main-home/member-home-sub-Menu']);
      }
    });
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }
}
