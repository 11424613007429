import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { Location } from '@angular/common';
import { CertificateService } from 'src/app/services/api/certificate.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-certificate',
  templateUrl: './certificate.component.html',
  styleUrls: ['./certificate.component.css']
})
export class CertificateComponent implements OnInit {

  constructor(private location: Location, 
    private authService: AuthService, 
    private certificateService: CertificateService, 
    private router: Router) {   
    localStorage.setItem('from', 'myresume');
  }

  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  sqldb = environment.firstNation.SQLDB;
  clientID = 0;
  model: any[];
  hasRows = false;

  ngOnInit(): void {
    if (localStorage.getItem('applicantID')) {
      this.clientID = Number(localStorage.getItem('applicantID'));
    } else {
      this.clientID = this.authService.getCurrentApplicantID();
    }

    localStorage.setItem('certificate_call_from', 'certificate');
    if(this.sqldb){
      this.certificateService.getCertificateByMemberId(this.clientID).subscribe(x => {
        if (x) {
          this.model = x;
          if (this.model.length > 0) { this.hasRows = true; }
        }
      });
    }else{
      /*
      this.certificateFirebaseService.getCertificateByMemberId(this.clientID).valueChanges().subscribe(x => {
        if (x) {
          this.model = x;
          if (this.model.length > 0) { this.hasRows = true; }
        }
      });
    */
    }
  }

  backClicked() {
    this.location.back();
    // this.router.navigate(['/member/member-home-sub-Menu']);
  }
}