

export class SKCasePlanModel {
     applicantID: number;
     caseplan_id: number;
     EI_Contribute: string;
     EI_Claim: string;
     Comment1: string;
     Comment2: string;
     Comment3: string;
     edu_level: string;
   }
 

  