<br />
<div class="row mynation" *ngIf="from && !socialDevelopmentMenu">
  <div class="col s12 m10 l10">
    <div class="submenu-sidenav" *ngIf="menu?.submenus">
      <h4 class="title-section1 title-member icon-texts"> <span class="material-symbols-outlined back-icon p-r-1" (click)=" backClicked()">
        arrow_circle_left
    </span>{{menu.displayName}}</h4>

      <div class="col s12 m6 l6" [@swing]="swing" *ngIf="back_flag">
        <a (click)="myservicesClicked()" style="cursor: pointer;">
          <div class="card" [ngStyle]="{'border-color':themeColor}">
            <div class="card-content">
              <div class="row center">
                <div class="row col l2 s2">
                  <i class="material-icons circle-icon" [ngStyle]="{'color':themeColor}">house</i>
                </div>
                <div class="row content-card">
                  <strong class="black-text  col s10 l10 bold-600 title-section4 displayName">Back</strong>
                  <!-- <strong class="black-text  col s10 bold-100 sub-title-section3">Back</strong> -->
                </div>
                <p></p>
              </div>
            </div>
          </div>
        </a>
      </div>

      <!-- <div class="col s12 m6 l6" *ngIf="from == 'MyServices' && !SHOW_SOCIAL"> -->
      <!-- <div class="col s12 m6 l6" *ngIf="!back_flag">
        <a (click)="signOut()" style="cursor: pointer;">
          <div class="card " [ngStyle]="{'border-color':themeColor}">
            <div class="card-content">
              <div class="row center">
                <i class="material-icons red-text row col l2 s2 circle-icon">lock</i>
                <div class="row content-card">
                  <strong class="black-text  col s10 bold-600 title-section4">Logout</strong>
                  <strong class="black-text  col s10 bold-100 sub-title-section3">Logout</strong>
                </div>
                <p></p>
              </div>
            </div>
          </div>
        </a>
      </div> -->

      <div *ngFor="let item of menu.submenus; let i=index">
        <div *ngIf="(!SHOW_SOCIAL && from == 'MyServices') ? getVisibility(item.name): true">
          <div class="col s12 m6 l6" [@swing]="swing" *ngIf="item.isEnabled">
            <a (click)="itemClicked(item.name)" style="cursor: pointer;">
              <div class="card" [style.border-color]="themeColor">
                <div class="card-content">
                  <div class="row center">
                    <div class="row col l2 s2">
                      <i class="material-icons  circle-icon" [style.color]="themeColor">{{item.icon}}</i>
                    </div>

                    <div class="row content-card">
                      <strong
                        class="black-text  col s10 l10 bold-600 title-section4 displayName">{{item.displayName}}</strong>
                      <!-- <strong class="black-text  col s10 bold-100 sub-title-section3">{{item.displayName}}</strong> -->
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<!------------------------- Social Development ------------------ -->
<div class="row container-social" *ngIf="socialDevelopmentMenu">

  <h4 class="title-section1 title-member icon-texts">  <span class="material-symbols-outlined back-icon p-r-1" (click)=" backClicked()">
    arrow_circle_left
</span>Social Development</h4>
  <!-- ============================================= New Design  ===================== -->
  <div class="row card-panel b-n-1 ">
    <div class="col s12">
      <ul class="tabs">
        <li class="tab col s4"><a href="#reqsig">Required Signature</a></li>
        <li class="tab col s4"><a href="#myben">My Benefits</a></li>
        <li class="tab col s4"><a href="#reqben">Request Benefits</a></li>
      </ul>
    </div>

    <div class="row ">
      <div id="reqsig" class="col s12">
        <app-sign-multiple-bd></app-sign-multiple-bd>
      </div>
      <div id="myben" class="col s12">
        <app-my-entitlement></app-my-entitlement>
      </div> 
      <div id="reqben" class="col s12">
        <app-support-new></app-support-new>
      </div>
    </div>
  </div>
</div>

<!----------------------------- Modals --------------------------->
<div id="Housing container" class="modal">
  <div class="modal-content">
    <h6><strong> Under Construction </strong></h6>
    <p>You will be able to enter the data about your house including Number of people living in the house
      age of the house, equipemnt GPS location etc...</p>
  </div>
  <div class="modal-footer">
    <a class="modal-close waves-effect waves-green btn-flat">Close</a>
  </div>
</div>

<div id="SocialAccounts" class="modal">
  <div class="modal-content">
    <h6><strong> Under Construction </strong></h6>
    <p>Once set up you will be able to link the Nation's Social Media accounts </p>
  </div>
  <div class="modal-footer">
    <a class="modal-close waves-effect waves-green btn-flat">Close</a>
  </div>
</div>

<div id="PseApplication" class="modal">
  <div class="modal-content">
    <h6>Application</h6>
    <p>This Application is Approved and Locked !</p>
  </div>
  <div class="modal-footer">
    <a class="modal-close amber darken-4 white-text btn-flat white-text"> <i
        class="material-icons right">close</i>Close</a>
  </div>
</div>

<div id="restrictedModal" class="modal center b-n-1">
  <div class="modal-content">
    <i class="material-icons right modal-close">close</i><br />
    <span class="material-icons" style="font-size:100px;">do_not_disturb_on</span>
    <p class="title-section5 bold-500">This area is for members of <span
        [ngStyle]="{'color': themeColor}">{{nationName}}</span> only.</p>
    <p class="sub-title-section bold-300">
      If you are a member and you want access to this area please get verified or contact Us.
    </p>

    <div>
      <!-- <p class="sub-title-section bold-300">Do you want to get verified?</p> -->
      <a class="btn waves-effect waves-light" [ngStyle]="{'background-color': themeColor}" (click)="getVerified()">Get
        Verified</a>
    </div>

    <div *ngIf="nationContactInfo">
      <p class="sub-title-section bold-100">If you need some support? Contact us on: </p>
      <p><span class="material-icons" style="margin-right: 10px;" [ngStyle]="{'color': themeColor}">
          email </span>{{nationContactInfo.email}} </p>
      <p> <span class="material-icons" style="margin-right: 10px;" [ngStyle]="{'color': themeColor}">
          phone </span>{{nationContactInfo.telephone}}
      </p>
    </div>
  </div>
</div>

<!----------------------------- My Resume --------------------------->

<div class="row mynation" *ngIf="myResume">
  <h4 class="title-section1  title-member">Social Development</h4>

  <div class="col s12 m6 l6" [@swing]="swing">
    <a (click)="backToJobRelated()">
      <div class="card " [ngStyle]="{'border-color':themeColor}">
        <div class="card-content">
          <div class="row center">
            <i class="material-icons blue-text row col l2 s2 circle-icon">house</i>
            <div class="row content-card">
              <strong class="black-text  col s10 bold-600 title-section4">Back</strong>
              <strong class="black-text  col s10 bold-100 sub-title-section3">Back</strong>

            </div>
          </div>
        </div>
      </div>
    </a>
  </div>

  <div class="col s12 m6 l6" [@swing]="swing">
    <a [routerLink]="['/member/member-main-home/objective']">
      <div class="card " [ngStyle]="{'border-color':themeColor}">
        <div class="card-content">
          <div class="row center">
            <i class="material-icons blue-text row col l2 s2 circle-icon">home_work</i>
            <div class="row content-card">
              <strong class="black-text  col s10 bold-600 title-section4">Objective</strong>
              <strong class="black-text  col s10 bold-100 sub-title-section3">Objective</strong>
            </div>
          </div>
        </div>
      </div>
    </a>
  </div>

  <div class="col s12 m6 l6" [@swing]="swing">
    <a [routerLink]="['/member/member-main-home/highlight']">
      <div class="card" [ngStyle]="{'border-color':themeColor}">
        <div class="card-content">
          <div class="row center">
            <i class="material-icons blue-text row col l2 s2 circle-icon">person_add</i>
            <div class="row content-card">
              <strong class="black-text  col s10 bold-600 title-section4">Highlights</strong>
              <strong class="black-text  col s10 bold-100 sub-title-section3">Highlights</strong>
            </div>
          </div>
        </div>
      </div>
    </a>
  </div>

  <div class="col s12 m6 l6" [@swing]="swing">
    <a [routerLink]="['/member/member-main-home/employment']">
      <div class="card" [ngStyle]="{'border-color':themeColor}">
        <div class="card-content">
          <div class="row center">
            <i class="material-icons blue-text row col l2 s2 circle-icon">apartment</i>
            <div class="row content-card">
              <strong class="black-text  col s10 bold-600 title-section4">Work Experience / Training</strong>
              <strong class="black-text  col s10 bold-100 sub-title-section3">Work Experience / Training</strong>
            </div>
          </div>
        </div>
      </div>
    </a>
  </div>

  <div class="col s12 m6 l6" [@swing]="swing">
    <a [routerLink]="['/member/member-main-home/skill']">
      <div class="card" [ngStyle]="{'border-color':themeColor}">
        <div class="card-content">
          <div class="row center">
            <i class="material-icons blue-text row col l2 s2 circle-icon">home_work</i>
            <div class="row content-card">
              <strong class="black-text  col s10 bold-600 title-section4">Skills</strong>
              <strong class="black-text  col s10 bold-100 sub-title-section3">Skills</strong>
            </div>
          </div>
        </div>
      </div>
    </a>
  </div>

  <div class="col s12 m6 l6" [@swing]="swing">
    <a [routerLink]="['/member/member-main-home/education']">
      <div class="card" [ngStyle]="{'border-color':themeColor}">
        <div class="card-content">
          <div class="row center">
            <i class="material-icons blue-text row col l2 s2 circle-icon">import_contacts</i>
            <div class="row content-card">
              <strong class="black-text  col s10 bold-600 title-section4">Education</strong>
              <strong class="black-text  col s10 bold-100 sub-title-section3">Education</strong>
            </div>
          </div>
        </div>
      </div>
    </a>
  </div>
</div>

<!-------------------------- Contact ------------------ -->
<div class="row container" *ngIf="contactUs">

  <div class="col s12 m6 l6" [@swing]="swing">
    <a [routerLink]="['/member/member-main-home/certificate']">
      <div class="card " [ngStyle]="{'border-color':themeColor}">
        <div class="card-content">
          <div class="row center">
            <i class="material-icons blue-text row col l2 s2 circle-icon">note</i>
            <div class="row content-card">
              <strong class="black-text  col s10 bold-600 title-section4">Courses / Certificates</strong>
              <strong class="black-text  col s10 bold-100 sub-title-section3">Courses / Certificates</strong>
            </div>
          </div>
        </div>
      </div>
    </a>
  </div>

  <div class="col s12 m6 l6" [@swing]="swing">
    <a [routerLink]="['/member/member-main-home/activity']">
      <div class="card" [ngStyle]="{'border-color':themeColor}">
        <div class="card-content">
          <div class="row center">
            <i class="material-icons blue-text row col l2 s2 circle-icon">home_work</i>
            <div class="row content-card">
              <strong class="black-text  col s10 bold-600 title-section4">Activities</strong>
              <strong class="black-text  col s10 bold-100 sub-title-section3">Activities</strong>
            </div>
          </div>
        </div>
      </div>
    </a>
  </div>

  <div class="col s12 m6 l6" [@swing]="swing">
    <a [routerLink]="['/member/member-main-home/reference']">
      <div class="card" [ngStyle]="{'border-color':themeColor}">
        <div class="card-content">
          <div class="row center">
            <i class="material-icons blue-text row col l2 s2 circle-icon">group_add</i>
            <div class="row content-card">
              <strong class="black-text  col s10 bold-600 title-section4">References</strong>
              <strong class="black-text  col s10 bold-100 sub-title-section3">References</strong>
            </div>
          </div>
        </div>
      </div>
    </a>
  </div>

</div>