<div class="col s12">
    <div class="body-h">
        <div class="header-setting b-m-2">
            <div class="col l10 m10 s12">
                <h4 *ngIf="!editName">
                    <span *ngIf="sectionName"> {{sectionName}}</span>
                    <span *ngIf="!sectionName"> Admin Menu</span>
                    <i class="material-icons edit-name" [ngStyle]="{'color': themeColor}" (click)="editNameClicked()">edit</i>
                </h4>

                <div class="input-field" *ngIf="editName">
                    <input type="text" [(ngModel)]="sectionName" class="left col s6 validate" autocomplete="off" />
                    <button type="button" class="btn ll-m-5" [ngStyle]="{'background-color': themeColor}"
                        (click)="saveName()">Save</button>
                </div>

                <p class="col s12 bold-300 sub-title-section3">In this area, you have the ability to customize the menu
                    items
                    visibility, including sections and sub-sections, that administrators will see when they log in.</p>
            </div>
            <div class="col s12 l2 m2 p-m-3">
                <button class="col s12  btn save-button" style="margin-bottom: 15px;" (click)="saveChanges();">Save
                </button>
            </div>
        </div>

        <div class="padding-collapse">
            <ul class="collapsibles col s12 m11">
                <li class="row" *ngFor="let section of menu; let i=index;">
                    <div>
                        <div class="collapsibles-header">
                            <table class="menu-items">
                                <tbody>
                                    <tr
                                        [ngClass]="{'disabled': !section.isEnabled || section.name === 'Logout', 'notCollapsible': !section.submenus || section.submenus.length === 0}">
                                        <td class="hide-on-small-only">
                                            <i class="material-icons-outlined"
                                                [ngStyle]="{'color': 'black'}">{{section.icon}}</i>
                                        </td>

                                        <td>
                                            <div class="input-field">
                                                <input id="" type="text" autocomplete="off" class="validate"
                                                    [(ngModel)]="section.displayName" />
                                            </div>
                                        </td>

                                        <td>
                                            <div class="switch ">
                                                <label>
                                                    <!-- <span class="hide-on-small-only bold-700 black-text">HIDE</span> -->
                                                    <input type="checkbox" [checked]="section.isEnabled" dis
                                                        (change)="visibilityChanged(section)">
                                                    <span class="lever"></span>
                                                    <!-- <span class="hide-on-small-only bold-700 black-text ">SHOW</span> -->
                                                    <span class="hide-on-small-only bold-700 black-text">{{
                                                        section.isEnabled ? 'SHOW' : 'HIDE' }}</span>

                                                </label>
                                            </div>
                                        </td>

                                        <td class="move-btn">
                                            <button class="btn white"
                                                [disabled]="!section.isEnabled || section.name === 'Logout' ||  section.index >= section.length - 2"
                                                (click)="moveSection(section, 1);"> <i
                                                    class="material-icons-outlined black-text">expand_more</i></button>
                                            <button class="btn white"
                                                [disabled]="!section.isEnabled || section.name === 'Logout' || section.index === 0"
                                                (click)="moveSection(section, -1);"> <i
                                                    class="material-icons-outlined black-text">expand_less</i></button>
                                        </td>

                                        <td>
                                            <a class="" (click)="openModal(i)">
                                                <span class="sub-sections-menu"
                                                    [ngStyle]="{'color': !section.submenus || section.submenus.length === 0 ? 'grey' : themeColor}">
                                                    {{ !section.submenus ? 'No Sub-Section' : 'Sub-Sections(' +
                                                    (section.submenus?.length || 'NULL') + ')' }}
                                                </span>
                                            </a>
                                        </td>



                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>


                    <div id="modal{{i}}" class="modal bottom-sheet modal-fixed-footer"
                        *ngIf="section.submenus && section.submenus.length > 0">
                        <div class="modal-content">
                            <div class="admin-modal-header">
                                <h4 class="col s11">Admin Sub-Menu</h4>
                                <span class="col s1 modal-close material-symbols-outlined"
                                    [ngStyle]="{'color': themeColor}" style="cursor: pointer;">close</span>
                            </div>

                            <div class="sub-menu-items" *ngFor="let menu of section.submenus">
                                <div class="col s12">
                                    <table class="menu-items">
                                        <tbody>
                                            <tr class="table-container">
                                                <td>
                                                    <i class="material-icons-outlined bold-100"
                                                        [ngStyle]="{'color': themeColor}">{{menu.icon}}</i>
                                                </td>
                                                <td>
                                                    <div class="input-field">
                                                        <input id="" type="text" autocomplete="off" class="validate"
                                                            [(ngModel)]="menu.displayName" />
                                                    </div>
                                                </td>

                                                <td class="p-r-2">
                                                    <div class="switch ">
                                                        <label>
                                                            <!-- <span class="hide-on-small-only bold-700 black-text">HIDE</span> -->
                                                            <input type="checkbox" [checked]="menu.isEnabled" dis
                                                                (change)="visibilityChanged(menu)">
                                                            <span class="lever"></span>
                                                            <span class="hide-on-small-only bold-700 black-text">{{
                                                                menu.isEnabled ? 'SHOW' : 'HIDE' }}</span>
                                                        </label>
                                                    </div>
                                                </td>

                                                <td class="move-btn">
                                                    <button class="btn white"
                                                        [disabled]="!menu.isEnabled ||  menu.index >= section.submenus.length - 1"
                                                        (click)="moveSubmenu(i, menu, 1);"> <i
                                                            class="material-icons-outlined black-text">expand_more</i></button>
                                                    <button class="btn white"
                                                        [disabled]="!menu.isEnabled || menu.index === 0"
                                                        (click)="moveSubmenu(i, menu, -1);"> <i
                                                            class="material-icons-outlined black-text">expand_less</i></button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                            </div>

                        </div>
                        <div class="modal-footer">
                            <button class="save-button btn-small col s3 l2" [ngStyle]="{'background-color': themeColor}"
                                (click)="saveChanges();">Save</button>
                            <button class="modal-close cancel-button btn-small col s3 l2"
                                [ngStyle]="{'color': themeColor}">Cancel</button>
                        </div>
                    </div>



                </li>
                <div class="row ">
                    <button class="col s12 l3 m3 offset-l9 offset-m9  btn save-button p-m-3"
                        style="margin-bottom: 15px;" [ngClass]="'btn ' + buttonType" (click)="saveChanges();">Save
                        Changes</button>
                </div>
            </ul>
        </div>

        <main class="StickyContent white" *ngIf="false">
            <!-- ---------------------- Menu Items -------------------- -->
            <div class="col l11 m11 s12" *ngIf="menu">
                <table class="menu-items">
                    <thead>
                        <tr>
                            <th></th>
                            <th>Visibility</th>
                            <th>Move</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let section of menu" class="card"
                            [ngClass]="{'disabled': !section.isEnabled || section.name === 'Logout'}">
                            <td>
                                <div class="input-field">
                                    <i class="material-icons-outlined prefix">{{section.icon}}</i>
                                    <input id="" type="text" autocomplete="off" class="validate"
                                        [(ngModel)]="section.displayName" />
                                </div>
                            </td>
                            <td class="p-r-2">
                                <div class="switch ">
                                    <label>
                                        <span class="hide-on-small-only bold-700 black-text">HIDE</span>
                                        <input type="checkbox" [checked]="section.isEnabled" dis
                                            (change)="visibilityChanged(section)">
                                        <span class="lever"></span>
                                        <span class="hide-on-small-only bold-700 black-text ">SHOW</span>
                                    </label>
                                </div>
                            </td>
                            <td class="move-btn">
                                <button class="btn white"
                                    [disabled]="!section.isEnabled || section.name === 'Logout' ||  section.index >= section.length - 2"
                                    (click)="moveSection(section, 1);"> <i
                                        class="material-icons-outlined black-text">expand_more</i></button>
                                <button class="btn white"
                                    [disabled]="!section.isEnabled || section.name === 'Logout' || section.index === 0"
                                    (click)="moveSection(section, -1);"> <i
                                        class="material-icons-outlined black-text">expand_less</i></button>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <div class="row ">
                    <button class="col s12 l3 m3 offset-l9 offset-m9  btn save-button p-m-3"
                        style="margin-bottom: 15px;" [ngClass]="'btn ' + buttonType" (click)="saveChanges();">Save
                        Changes</button>
                </div>
            </div>
        </main>
    </div>
</div>









<!-- <div class="row collapsible-body col l12 s12 m12 p-l-4"
                        *ngIf="section.submenus && section.submenus.length > 0">

                        <div class="row sub-menu-items" *ngFor="let menu of section.submenus">
                            <div class="col s12">
                                <table class="menu-items">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div class="input-field">
                                                    <i class="material-icons-outlined prefix">{{menu.icon}}</i>
                                                    <input id="" type="text" autocomplete="off" class="validate"
                                                        [(ngModel)]="menu.displayName" />
                                                </div>
                                            </td>

                                            <td class="p-r-2">
                                                <div class="switch ">
                                                    <label>
                                                        <span class="hide-on-small-only bold-700 black-text">HIDE</span>
                                                        <input type="checkbox" [checked]="menu.isEnabled" dis
                                                            (change)="visibilityChanged(menu)">
                                                        <span class="lever"></span>
                                                        <span
                                                            class="hide-on-small-only bold-700 black-text ">SHOW</span>
                                                    </label>
                                                </div>
                                            </td>

                                            <td class="move-btn">
                                                <button class="btn white"
                                                    [disabled]="!menu.isEnabled ||  menu.index >= section.submenus.length - 1"
                                                    (click)="moveSubmenu(i, menu, 1);"> <i
                                                        class="material-icons-outlined black-text">expand_more</i></button>
                                                <button class="btn white"
                                                    [disabled]="!menu.isEnabled || menu.index === 0"
                                                    (click)="moveSubmenu(i, menu, -1);"> <i
                                                        class="material-icons-outlined black-text">expand_less</i></button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>


                    </div> -->