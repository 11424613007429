<div class="col s12">
    <div class="body-h">

        <div class="header-setting b-m-2">
            <h4 class="">Members Profile Updates</h4>
        </div>

        <main class="StickyContent white ">
            <app-firebase-loading *ngIf="loading"></app-firebase-loading>

            <div class="row" *ngIf="!showFCReq">
                <div class="col s12">
                    <ul class="tabs">
                        <li class="tab col s6"><a href="#personalInfo">Personal Information Updates
                                <span class="badge right">{{profileUpdates?.length}}</span></a></li>
                        <li class="tab col s6"><a href="#familyComp">Family Composition Updates
                                <span class="badge right">{{famCompUpdates?.length}}</span>
                            </a></li>
                    </ul>
                </div>

                <!-- *******************************  Personal Information Updates ********************************************************** -->
                <div id="personalInfo" class="col s12">
                    <div class="row card-panel white" *ngIf="profileUpdates && profileUpdates.length>0">
                        <table class="responsive-table">
                            <thead>
                                <tr>
                                    <th>Applicant ID</th>
                                    <th>Given Name</th>
                                    <th>last Name</th>
                                    <th>Date</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr *ngFor="let m of profileUpdates; let i=index" style="cursor: pointer;"
                                    (click)="showDetails(i)">
                                    <td class="blue-text">{{m.applicantID}}</td>
                                    <td class="blue-text">{{m.GivenName}}</td>
                                    <td class="blue-text">{{m.LastName}}</td>
                                    <td class="blue-text">{{m.date.toDate().toDateString() | date: 'dd/MMM/yyyy'}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="row card-panel blue" *ngIf="profileUpdates?.length==0">
                        <h6 class="white-text"> <strong>
                                No Record Found in the Database.</strong>
                        </h6>
                    </div>
                </div>

                <!-- *******************************  Family composition Updates ********************************************************** -->
                <div id="familyComp" class="col s12">
                    <div class="row card-panel white" *ngIf="famCompUpdates && famCompUpdates.length >0">
                        <table class="responsive-table">
                            <thead>
                                <tr>
                                    <th>Applicant ID</th>
                                    <th>Given Name</th>
                                    <th>last Name</th>
                                    <th>Date</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr *ngFor="let m of famCompUpdates; let i=index" style="cursor: pointer;"
                                    (click)="showFCDetails(i)">
                                    <td class="blue-text">{{m.applicantID}}</td>
                                    <td class="blue-text">{{m.GivenName}}</td>
                                    <td class="blue-text">{{m.LastName}}</td>
                                    <td class="blue-text">{{m.date.toDate().toDateString() | date: 'dd/MMM/yyyy'}}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <div class="row card-panel blue" *ngIf="famCompUpdates?.length==0">
                        <h6 class="white-text"> <strong>
                                No Record Found in the Database.</strong>
                        </h6>
                    </div>
                </div>

                <div id="personalInfoModel" class="modal" *ngIf="updateDetails">
                    <div class="modal-content">
                        <h4 class="center-align">Update Details</h4>
                        <table class="hover striped responsive-table">
                            <thead>
                                <tr>
                                    <th>Updated</th>
                                    <th>New Value</th>
                                    <th>Previous Value</th>
                                </tr>
                            </thead>

                            <tbody>
                                <tr *ngFor="let changes of updateDetails">
                                    <td>{{changes.name}}</td>
                                    <td>{{changes.new_value}}</td>
                                    <td>{{changes.old_value}}</td>
                                </tr>
                            </tbody>
                        </table>
                        <br>
                        <button type="button" class='col s12 btn waves-effect red' (click)="closeModal()"
                            style="margin-bottom: 25px;">
                            <i class="material-icons right">close</i> Cancel
                        </button>
                    </div>
                </div>

                <button class="col s12 white" style="border: 1px solid;"
                    [ngStyle]="{'border-color': themeColor, 'color': themeColor}" (click)="backClicked()"
                    [ngClass]="'btn-flat ' + buttonType"><i class="material-icons left">arrow_back_ios</i>Back To
                    Previous
                    Page</button>
            </div>

            <!-- *******************************  Family composition Details ********************************************************** -->
            <div class="row col s12" *ngIf="showFCReq && member">
                <div *ngIf="step1">
                    <!-- Current Family Composition --->
                    <ul class="collapsible">
                        <li class="active">
                            <div class="collapsible-header">
                                <i class="material-icons blue-text">family_restroom</i>Current Family Composition
                            </div>
                            <div class="collapsible-body card">
                                <table class="hover striped responsive-table">
                                    <thead>
                                        <tr>
                                            <th>ID</th>
                                            <th>Given Name</th>
                                            <th>Last Name</th>
                                            <th>Date of Birth</th>
                                            <th>Role/Relationship</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr *ngFor="let member of familyMembers">
                                            <td>{{member.applicantID}}</td>
                                            <td>{{member.GivenName}}</td>
                                            <td>{{member.LastName}}</td>
                                            <td>{{member.DOB | date:'dd/MM/yyyy'}}</td>
                                            <td>{{member.Classification }}</td>
                                        </tr>
                                        <tr *ngFor="let member of confirmed">
                                            <td>{{member.applicantID}}</td>
                                            <td>{{member.GivenName}}</td>
                                            <td>{{member.LastName}}</td>
                                            <td>{{member.DOB | date:'dd/MM/yyyy'}}</td>
                                            <td *ngIf="tobeAdded.relationship">{{tobeAdded.relationship}}</td>
                                            <td *ngIf="!tobeAdded.relationship">{{relationship}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div class="card-action">
                                    <button type="button" class="light-blue darken-1 btn right"
                                        (click)="searchMember()">
                                        <i class="material-icons right">add</i>Add Others</button>
                                </div>
                            </div>
                        </li>
                    </ul>

                    <!-- Family Composition updates (Members to be added to FC)  --->
                    <ul class="collapsible">
                        <li class="active">
                            <div class="collapsible-header">
                                <i class="material-icons blue-text">group_add</i><strong>Members to be Added</strong>
                            </div>
                            <div class="collapsible-body card">
                                <table class="hover responsive-table" *ngIf="updateDetails.length > 0">
                                    <thead>
                                        <tr>
                                            <th>First Name</th>
                                            <th>Last Name</th>
                                            <th>Date of Birth</th>
                                            <th>ISN</th>
                                            <th>Relationship</th>
                                            <th>Approved</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr *ngFor="let change of updateDetails; let n=index" style="cursor: pointer;"
                                            (click)="selected(n)">
                                            <td><a>{{change.firstName}} </a></td>
                                            <td><a>{{change.lastName}}</a></td>
                                            <td><a>{{change.dob?.toDate().toDateString() | date: 'dd/MMM/yyyy'}}</a>
                                            </td>
                                            <td><a>{{change.isn? change.isn : ''}}</a></td>
                                            <td><a>{{change.relationship? change.relationship : ''}}</a></td>
                                            <td>
                                                <span *ngIf="change.confirmed" class="material-icons green-text">
                                                    thumb_up_alt
                                                </span>
                                                <span *ngIf="change.confirmed != null && !change.confirmed"
                                                    class="material-icons red-text">
                                                    thumb_down_alt
                                                </span>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>

                                <div class="card-content" *ngIf="updateDetails.length == 0">
                                    <div class="center-align card-title">
                                        <p>No Family Member to be added.</p>
                                    </div>
                                </div>

                                <div class="card-action">
                                    <button type="button" class="waves-effect waves-light btn-small col s12"
                                        [ngClass]="'btn-flat ' + buttonType"
                                        [ngStyle]="{'background-color': themeColor}" (click)="saveUpdate()"
                                        style="margin-bottom: 25px;">
                                        <i class="material-icons right">save</i>Save Updates</button>
                                </div>
                            </div>
                        </li>
                    </ul>

                    <div class="row col s12">
                        <button type="button" class="waves-effect waves-light red btn-small col s12"
                            [ngClass]="'btn-flat ' + buttonType" (click)="deleteUpdate()"
                            style="margin-bottom: 25px; margin-top: 10px;">
                            <i class="material-icons right">delete</i>Delete Updates</button>

                        <button *ngIf="member" class="col s12 btn waves-effect green"
                            [ngClass]="'btn-flat ' + buttonType" (click)="contactClient()"
                            style="margin-bottom: 25px;"><i class="material-icons right">
                                email
                            </i>Contact Client</button>

                        <button class="col s12 white" style="border: 1px solid;"
                            [ngStyle]="{'border-color': themeColor, 'color': themeColor}" (click)="backToUpdates()"
                            [ngClass]="'btn-flat ' + buttonType"><i class="material-icons left">arrow_back_ios</i>Back
                            To Previous
                            Page</button>
                    </div>
                </div>

                <!-- Update details and similar members ------------->
                <div id="similarMembers" *ngIf="step2" class="row col s12">
                    <div class="row card white" *ngIf="matches">
                        <h6 class="row col s12 center red-text "><strong style="font-weight: bolder;">{{matches.length}}
                                Possible
                                Matches Found in the Database</strong></h6>
                        <div class="col s7 m8">
                            <table>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Applicant Data</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <th>Last Name</th>
                                        <td>
                                            <span>{{tobeAdded?.lastName}}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Middle Name</th>
                                        <td>
                                            <span>{{tobeAdded?.middleName}}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>First Name</th>
                                        <td>
                                            <span>{{tobeAdded?.firstName}}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Date Of Birth</th>
                                        <td *ngIf="tobeAdded.dob">
                                            <span>{{tobeAdded?.dob.toDate() | date:'dd/MMM/yyyy'}}</span>
                                        </td>
                                        <td *ngIf="!tobeAdded.dob">
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>ISN</th>
                                        <td>
                                            <span>{{tobeAdded?.isn}}</span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <th></th>
                                        <td></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="col s5 m4" *ngIf="matches">
                            <table>
                                <thead>
                                    <tr>
                                        <th class="center">
                                            <span *ngIf="matchI < matches.length">Match {{matchI +1}}</span>
                                            <span *ngIf="matches.length >0 && matchI == matches.length"
                                                class="red-text">No More
                                                Matches</span>
                                            <span *ngIf="matches.length==0" class="red-text">No Match Found</span>
                                            <a style="cursor: pointer;" (click)="previousMatch()" *ngIf="matchI > 0"><i
                                                    class="material-icons left">
                                                    arrow_back_ios
                                                </i></a>
                                            <a style="cursor: pointer;" (click)="nextMatch()"
                                                *ngIf="matchI < matches.length"><i class="material-icons right">
                                                    arrow_forward_ios
                                                </i></a>
                                        </th>
                                    </tr>
                                </thead>
                                <div *ngFor="let match of matches; let i = index">
                                    <div *ngIf="matchI == i">
                                        <tr class="col s12">
                                            <td>{{match.LastName}}</td>
                                        </tr>
                                        <tr class="col s12">
                                            <td>{{match.middle_name}}</td>
                                        </tr>
                                        <tr class="col s12">
                                            <td>{{match.GivenName}}</td>
                                        </tr>
                                        <tr class="col s12">
                                            <td>{{match.DOB | date: 'dd/MMM/yyyy'}}</td>
                                        </tr>
                                        <tr class="col s12">
                                            <td>{{match.IndianStatusNo}}</td>
                                        </tr>
                                        <tr>
                                            <td class="right">
                                                <a class=" modal-close green btn-small right"
                                                    (click)="setUser(match.applicantID)"><i
                                                        class="material-icons right">thumb_up</i>Approve</a>
                                            </td>
                                        </tr>
                                    </div>
                                </div>

                                <div *ngIf="(matches.length == 0) || matchI >= matches.length" class="center-align"
                                    style="margin-top: 25px;height: inherit;">
                                    <i class="material-icons center blue-text" style="font-size: 50px;">
                                        info
                                    </i>
                                    <h5 *ngIf="matches.length == 0"><strong>No Match found</strong></h5>
                                    <br />
                                    <p>Would you like to add this member?</p>
                                    <br />
                                    <button class="col s12 btn waves-effect green" (click)="addMember()"
                                        style="margin-bottom: 25px;"><i class="material-icons right">
                                            person_add
                                        </i>Add Member</button>
                                </div>
                            </table>
                        </div>
                    </div>

                    <button type="button" class="btn col s12 red" style="margin-bottom: 15px;"
                        (click)="declineUpdate()">
                        <i class="material-icons right">thumb_down_alt</i>Disregard this change</button>

                    <a [ngClass]="'btn col s12 waves-effect ' + themeColor" (click)="back()"><i
                            class="material-icons left">arrow_back_ios</i> Back To Preious
                        Page</a>
                </div>
            </div>
        </main>
    </div>
</div>

<!-- Modals -->
<div id="searchModal" class="modal" [ngClass]="{'modal_max': searchResult?.length > 0}">
    <div class="row col s12 modal-content">
        <h5 class="center-align">Search Member</h5>
        <form [formGroup]="searchMemberForm" (ngSubmit)="search(searchMemberForm.value)">
            <div class="row col s12">
                <div class="input-field col s12 m6 ">
                    <input id="first_name" type="text" formControlName='firstName' class="validate">
                    <label for="first_name">First Name</label>
                </div>
                <div class="input-field col s12 m6 ">
                    <input id="last_name" type="text" formControlName='lastName' class="validate">
                    <label for="last_name">Last Name</label>
                </div>
            </div>
            <div class="row col s12">
                <button type="submit" class="waves-effect waves-light blue darken-3 btn-small col s12"
                    style="margin-bottom: 25px;" [disabled]="!searchMemberForm.valid">
                    <i class="material-icons right">search</i>Search</button>
            </div>
        </form>

        <div class="row col s12" *ngIf="noResult">
            <div class="col s8 offset-s2">
                <h6 class="center-align red-text">No Result was found for this Member.</h6>
                <p class="center-align">Please Try again.</p>
            </div>
        </div>

        <div class="row col s12" *ngIf="searchResult && searchResult.length >0">
            <table class="hover striped responsive-table">
                <thead>
                    <tr>
                        <th>Given Name</th>
                        <th>Last Name</th>
                        <th>Date of Birth</th>
                        <th>ISN</th>
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let m of searchResult; let n=index">
                        <td>{{m.GivenName}}</td>
                        <td>{{m.LastName}}</td>
                        <td>{{m.DOB | date:'dd/MM/yyyy'}}</td>
                        <td>{{m.IndianStatusNo? m.IndianStatusNo : ''}}</td>
                        <td><button class="modal-close btn tooltipped" data-position="top"
                                data-tooltip='Member is currently listed as H 0f H on application'
                                [ngClass]="{'red white-text': m.role == 'H of H'}"
                                (click)="selectMember(m)">Add</button></td>
                    </tr>
                </tbody>
            </table>
        </div>

        <div class="section"></div>
        <br />
        <div class="row">
            <button type="button" class='col s12 btn waves-effect red' (click)="closeModal()">
                <i class="material-icons right">close</i> Cancel
            </button>
        </div>
    </div>
</div>

<div id="relationModal" class="modal">
    <div class="modal-content">
        <div class="center-align">
            <h5>Relationship is required!</h5>
            <p><strong>Please enter the relationship to the Applicant</strong></p>
        </div>
        <br>
        <div class="input-field col s12">
            <select (change)="onRelationshipChange($event.target.value)">
                <option value="" disabled selected>Choose your option</option>
                <option *ngFor="let relation of relationships" [value]="relation">{{relation}}</option>
            </select>
            <label>Relationship</label>
        </div>
        <br>
        <div class="row">
            <button type="button" class="modal-close green btn white-text col s12" style="margin-bottom: 15px;"
                [disabled]="!relationship" (click)="addSelected(selectedMember)">Add Member</button>
            <button class="modal-close red btn white-text col s12" (click)="goBackToSearch()"
                style="margin-bottom: 15px;">Cancel</button>
            <br>
        </div>
    </div>
</div>

<div id="messageModal" class="modal">
    <div class="modal-content">
        <div class="center-align">
            <i class="large material-icons blue-text md-36">info</i>
            <h6>{{message}}</h6>
            <p><strong>Would you like to continue? </strong> </p>
        </div>
        <div class="row col s12">
            <button class="modal-close green btn white-text col s12" style="margin-bottom: 15px;"
                (click)="confirmMembers(memberFound)">Continue</button>
            <button class="modal-close red btn white-text col s12" style="margin-bottom: 15px;"
                (click)="cancel()">Cancel</button>
        </div>
    </div>
</div>

<div *ngIf="contact">
    <app-contact-members [contactDetail]="contactDetail" (success)="contactHandler($event)">
    </app-contact-members>
</div>