import { Component, OnInit } from "@angular/core";
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl, FormArray, AbstractControl } from "@angular/forms";
import { environment } from "src/environments/environment";
import { RequestToJoinModel } from "src/app/models/RequestToJoin.Model";
import { RequesttojoinService } from "src/app/services/firebase/requesttojoin.service";
import { toast } from "materialize-css";
import { RegistrationRequestService } from "src/app/services/api/registration-request.service";
import { Location, DatePipe, ViewportScroller } from "@angular/common";
// import { ManageAdminService } from 'src/app/services/api/manage-admin.service';
// import { AdminsListComponent } from '../../admin/manage-admins/admins-list/admins-list.component';
import { DocumentsService } from "src/app/services/api/documents.service";
import { DepartmentService } from "src/app/services/firebase/department.service";
import { ContactService } from "src/app/services/firebase/contact.service";
import { EmailService } from "src/app/services/api/email.service";
import { EmailModel } from "src/app/models/emailModel";
import { CommunityService } from "src/app/services/api/community.service";
import { EmployeeRegRequestService } from "src/app/services/firebase/employee-reg-request.service";
import { MemberService } from "src/app/services/api/member.service";
import { FireMembersService } from "src/app/services/firebase/fire-members.service";
import { ManageAdminService } from "src/app/services/api/manage-admin.service";
import { AppSettingsService } from "src/app/services/firebase/app-settings.service";
import { Router } from "@angular/router";
import { AuthService } from "src/app/services/firebase/auth.service";
import { NationSettingsService } from "src/app/services/api/nation-settings.service";
import { AddBandMemberModel } from "src/app/models/AddMemberModel";
import { MediaService } from "src/app/services/api/media.service";
import { FbNationSettingsService } from "src/app/services/firebase/fb-nation-settings.service";
import { DataService } from "src/app/services/data.service";
import { createMask } from '@ngneat/input-mask';

declare var $: any;
declare var M: any;
declare var navigator: any;

function passwordConfirming(c: AbstractControl): any {
  if (!c.parent || !c) { return; }
  const pwd = c.parent.get('password');
  const cpwd = c.parent.get('confirmPassword');

  if (!pwd || !cpwd) { return; }
  if (pwd.value !== cpwd.value) {
    return { invalid: true };
  }
}

@Component({
  selector: "app-registration-request",
  templateUrl: "./registration-request.component.html",
  styleUrls: ["./registration-request.component.css"],
})

export class RegistrationRequestComponent implements OnInit {
  nationName = environment.firstNation.displayName;
  nationOfUse = environment.firstNation.name;
  defaultThemeColor = environment.appTheme.themeColor;
  themeColor: any;
  buttonType: any;

  // public phoneNumberMask = ['(', /[0-9]/, /[0-9]/, /[0-9]/, ')',
  //   ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  // public dateFormat = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
  public dateFormat = /^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/;
 // public isnMask = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  public treatyNoMask = [/[0-9]/, /[0-9]/, /[0-9]/];
  public isnMask2 = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  public dateMask = [/[0-3]/, /[0-9]/, '/', /[0-1]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];

  isnMask = createMask({
    mask: '9999999999',
    placeholderChar: '',
    maskChar: null,
  });

  phoneInputMask = createMask<string>( {
    mask: '(999) 999-9999',
    guide: true,
    showMaskTyped: true,
  });

  loading = false;

  //Forms
  requestTojoinForm: UntypedFormGroup;
  contactForm: UntypedFormGroup;

  //radio buttons
  isMember = true;
  isEmployee = false;
  isBoth = false;

  //data
  TreatyNo = '';
  requests: any[];
  employeesRequests: any[];
  departmentsList: any[];
  employeeList: any[];
  //requestFound: any;
  memberRequestFound: any;
  EmployeeRequestFound: any;
  binDoc: File;
  fileName: string;
  preview: any;
  defaultEmails = ['info@mynation.app', 'nordeen@hl360.ca'];
  // defaultEmails = ['mihret@mynation.app'];
  model: any;
  userName = 'test';

  //conditions
  emailExists = false;
  workEmailExists = false;
  isnHasValue = false;
  dobHasValue = false;
  enterDob = false;
  enterIsn = false;
  showUpload = false;
  contact = false;
  directSignup = false;
  welcomeSetup = false;
  //noISNDOB = false;

  //navigation
  step1 = true;
  step2 = false;
  step3 = false;
  emailSent = false;
  requestSent = false;
  signupDone = false;
  showPwd = false;
  showConPwd = false;

  //cordova
  isMobile = false;
  setting: any;
  signupSetting: any;
  welcomeSetting: any;
  defaultSetting = {
    name: 'Signup',
    displayName: 'Request To Join',
    icon: '',
    title: 'Lets get you setup!',
    description: 'Our suite of mobile applications have been designed with First Nations in mind to assist First Nations governments, organizations and advocacy groups with digital information management, online communications and community development.',
    image: 'https://api.mysabenefits.ca/Media/Demo/Images/6da6cf35-4671-4b9a-be99-edba6e21f7f4-thumbnail_IMG_0372.jpg',
  }
  defaultWelcomeSetting = {
    name: 'Welcome',
    displayName: 'Welcome',
    title: 'Welcome to ' + this.nationName + ' Web Application',
    description: '-As a member of ' + this.nationName + ', your info is on our membership list. We will only use the info you enter to match it to our list.<br/><br/>-If the data matches our files then we will send you an invitation to complete your registration.<br/><br/>-Once registered you will have access to your profile, news, events and the ability to communicate with Test Nation departments and leadership.',
    image: ''
  }
  sqlModel: AddBandMemberModel;
  observer: any;

  activeStep: number = 1;
  logo: any;

  authformBgColor: any;
  authformSColor: any;
  defaultauthBgColor = '#000';



  constructor(
    private fb: UntypedFormBuilder,
    private depService: DepartmentService,
    private requesttojoinService: RequesttojoinService,
    private communityService: CommunityService,
    private documentService: DocumentsService,
    private location: Location,
    private contactService: ContactService,
    private emailService: EmailService,
    private sqlRegistrationRequest: RegistrationRequestService,
    private employeeRegReq: EmployeeRegRequestService,
    private memberService: MemberService,
    private adminsService: ManageAdminService,
    private fireMemberService: FireMembersService,
    public settingsService: AppSettingsService,
    private scroller: ViewportScroller,
    private router: Router,
    private authService: AuthService,
    private nationSettingsService: NationSettingsService,
    private mediaService: MediaService,
    private nationSettingServicee: FbNationSettingsService,
    public appSettingService: AppSettingsService,
    private dataService: DataService
  ) {
    if (typeof window["cordova"] !== "undefined") {
      this.isMobile = true;
    }
    // this.settingsService.getAppSettingsList().valueChanges().subscribe(
    //   appSettings => {
    //     if (appSettings) {
    //       if (appSettings.length > 0) {
    //         this.model = appSettings[0];
    //         this.themeColor = this.settingsService.getAppThemeColor() ? this.settingsService.getAppThemeColor() : this.defaultThemeColor;
    //         this.directSignup = this.model.directSignup ? this.model.directSignup : false;
    //       }
    //     }
    //   });

    this.nationSettingServicee.getsettings().valueChanges().subscribe(setting => {
      if (setting && setting.length > 0) {
        if (setting[0].logo) this.logo = setting[0].logo;
      }
    });

  }


  ngOnInit() {

    this.appSettingService.getAppSetting().valueChanges().subscribe(
      appSettings => {
        if (appSettings && appSettings.length > 0) {
          this.setting = appSettings[0];
          this.authformBgColor = this.setting.authformBgColor ? this.setting.authformBgColor : this.defaultauthBgColor;
          this.authformSColor = this.setting.authformSColor ? this.setting.authformSColor : this.defaultauthBgColor;
        } else {
          this.authformBgColor = this.defaultauthBgColor;
          this.authformSColor = this.defaultauthBgColor;
        }

      });

    this.settingsService.getAppSettingsList().valueChanges().subscribe(
      appSettings => {
        if (appSettings) {
          if (appSettings.length > 0) {
            this.model = appSettings[0];
            this.themeColor = this.settingsService.getAppThemeColor() ? this.settingsService.getAppThemeColor() : this.defaultThemeColor;
            this.directSignup = this.model.directSignup ? this.model.directSignup : false;
            this.welcomeSetup = this.model.welcomeSetup ? this.model.welcomeSetup : false;

            if (this.model && this.directSignup) {
              this.password.addValidators([Validators.required]);
              this.confirmPassword.addValidators([Validators.required, passwordConfirming]);
            }

            if (this.model && this.welcomeSetup) {
              this.welcomeSetting;
            }

            if (this.model && !this.welcomeSetup) {
              !this.welcomeSetting;
            }
          }
        }
      });

    this.dataService.getAppName().subscribe(appName => {
      if (appName) {
        this.nationName = appName;
        this.defaultWelcomeSetting.title = 'Welcome to ' + this.nationName + ' Web Application';
        this.defaultWelcomeSetting.description = '-As a member of ' + this.nationName + ', your info is on our membership list. We will only use the info you enter to match it to our list.<br/><br/>-If the data matches our files then we will send you an invitation to complete your registration.<br/><br/>-Once registered you will have access to your profile, news, events and the ability to communicate with Test Nation departments and leadership.';
      }
      else {
        this.nationSettingsService.getNationSettings().subscribe(sett => {
          if (sett) {
            let settingsModel = sett[0];
            if (settingsModel.band_name) this.nationName = settingsModel.band_name;
            this.defaultWelcomeSetting.title = 'Welcome to ' + this.nationName + ' Web Application';
            this.defaultWelcomeSetting.description = '-As a member of ' + this.nationName + ', your info is on our membership list. We will only use the info you enter to match it to our list.<br/><br/>-If the data matches our files then we will send you an invitation to complete your registration.<br/><br/>-Once registered you will have access to your profile, news, events and the ability to communicate with Test Nation departments and leadership.';
          }
        });
      }
    });

    this.settingsService.getSignUpPageSettings().valueChanges().subscribe(setting => {
      if (setting && setting.length > 0) {
        this.signupSetting = setting[0];
      } else {
        this.signupSetting = this.defaultSetting;
      }
    });


    this.observer = this.settingsService.getWelcomeModalSettings().valueChanges().subscribe(setting => {
      if (setting && setting.length > 0) {
        this.welcomeSetting = setting[0];
      } else {
        this.welcomeSetting = this.defaultWelcomeSetting;
      }
      setTimeout(() => {
        $(".modal").modal();
        $("#welcomeModal").modal("open");
      }, 25);
    });

    if (this.model && !this.directSignup) {
      this.sqlRegistrationRequest.getRegistrationRequests().subscribe((r) => {
        if (r && Array.isArray(r)) {
          if (r.length > 0) {
            this.requests = r;
            //this.requests = this.requests.filter(rq => rq.status && rq.status === 1);
          }
        }
      });
    }
    // this.employeeRegReq.getRequests().valueChanges().subscribe((r) => {
    //   if (r && r.length > 0) {
    //     this.employeesRequests = r;
    //     this.employeesRequests = this.employeesRequests.filter(
    //       (rq) => rq.status && rq.status <= 3
    //     );
    //   }
    // });

    // this.depService.getDepartmentList().valueChanges().subscribe((x) => {
    //   if (x) {
    //     this.departmentsList = x;
    //   }
    // });

    this.nationSettingsService.getNationSettings().subscribe(sett => {
      if (sett) {
        let setting = sett[0];
        this.TreatyNo = setting.treaty_no;
      }
    });

    // this.sqlRegistrationRequest.getTreatyNumber().subscribe(t => {
    //   if (t) {
    //     this.TreatyNo = t;
    //   }
    // });

    this.requestTojoinForm = this.fb.group({
      section1: this.fb.group({
        firstName: ["", [Validators.required, Validators.maxLength(50)]],
        lastName: ["", [Validators.required, Validators.maxLength(50)]],
        middleName: [""],
        cellPhone: [""],
        email: ["", [Validators.required, Validators.email]],
        workEmail: ["", [Validators.email]],
        department: [""],
        password: [''],
        confirmPassword: [''],
      }),
      section2: this.fb.group({
        dob: ['', [Validators.pattern(this.dateFormat)]],
        // Validators.pattern(this.dateFormat),this.isValidDate
        //treatyNo: ['', [Validators.maxLength(3), Validators.minLength(3), Validators.pattern('^[0-9]*$')]],
        isn: ['', [Validators.minLength(10), Validators.maxLength(10), Validators.pattern('^[0-9]*$')]],
      }),
      document: [""],
      message: [""],
    });

    this.memberClicked();

    setTimeout(() => {
      $(window).scrollTop(0);
      //$(".modal").modal();
      // $("#welcomeModal").modal("open");
      M.updateTextFields();
      $(".input-field>.material-icons").css("color", this.themeColor);
      $('.carousel').carousel({
        fullWidth: false,
        indicators: true,
        noWrap: true
      });
    }, 25);

  }

  isValidDate(control: UntypedFormControl) {
    let dateformat = /^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/;
    let date = control.value;
    if (date) {
      if (date.match(dateformat)) {
        let dateArray = date.split('-');
        let day = dateArray[2];
        let month = dateArray[1] - 1;
        let year = dateArray[0];
        date = new Date(year, month, day);
        const today = new Date();
        if (Number(year) <= Number(today.getFullYear() - 100)) {
          return { 'invalidDate': true }
        } else if (date.valueOf() >= today.valueOf()) {
          return { 'invalidDate': true }
        } else {
          return null;
        }
      } else {
        return { 'invalidDate': true }
      }
    }
    return null;
  }

  toggleVisibility1() {
    this.showPwd = !this.showPwd;
  }

  toggleVisibility2() {
    this.showConPwd = !this.showConPwd;
  }

  get firstName() { return this.requestTojoinForm.get("section1.firstName"); }
  get lastName() { return this.requestTojoinForm.get("section1.lastName"); }
  get email() { return this.requestTojoinForm.get("section1.email"); }
  get workEmail() { return this.requestTojoinForm.get("section1.workEmail"); }
  get department() { return this.requestTojoinForm.get("section1.department"); }
  get dob() { return this.requestTojoinForm.get('section2.dob'); }
  get treatyNo() { return this.requestTojoinForm.get('section2.treatyNo'); }
  get isn() { return this.requestTojoinForm.get('section2.isn'); }

  get password() { return this.requestTojoinForm.get('section1.password'); }
  get confirmPassword() { return this.requestTojoinForm.get('section1.confirmPassword'); }

  get document() { return this.requestTojoinForm.get('document') }

  dobEntered(value) {
    this.dob.patchValue(value);
  }
  memberClicked() {
    this.reset();
    this.isMember = true;
    this.isEmployee = false;
    this.isBoth = false;

    this.email.setValidators([Validators.email, Validators.required]);
    this.email.updateValueAndValidity();
    this.workEmail.clearValidators();
    this.workEmail.updateValueAndValidity();
    this.department.clearValidators();
    this.department.updateValueAndValidity();
    setTimeout(() => {
      $("select").formSelect();
      M.updateTextFields();
    }, 25);
  }

  employeeClicked() {
    this.reset();
    this.isMember = false;
    this.isEmployee = true;
    this.isBoth = false;

    this.email.clearValidators();
    this.email.updateValueAndValidity();
    this.workEmail.setValidators([Validators.email, Validators.required]);
    this.workEmail.updateValueAndValidity();
    this.department.setValidators(Validators.required);
    this.department.updateValueAndValidity();
    setTimeout(() => {
      $("select").formSelect();
      M.updateTextFields();
    }, 25);
  }

  bothClicked() {
    this.reset();
    this.isMember = false;
    this.isEmployee = false;
    this.isBoth = true;

    this.email.setValidators([Validators.email, Validators.required]);
    this.email.updateValueAndValidity();
    this.workEmail.setValidators([Validators.required, Validators.email]);
    this.workEmail.updateValueAndValidity();
    this.department.setValidators([Validators.required]);
    this.department.updateValueAndValidity();
    setTimeout(() => {
      $("select").formSelect();
      M.updateTextFields();
    }, 25);
  }

  emailSet(value, type) {
    if (this.isBoth) {
      let email; let workEmail;
      this.workEmail.setErrors({ 'invalidEmail': null });
      this.workEmail.updateValueAndValidity();
      this.email.setErrors({ 'invalidEmail': null });
      this.email.updateValueAndValidity();
      if (type == 'workEmail') {
        workEmail = value.toLowerCase();
        email = this.email.value.toLowerCase();
        if (email && email != '' && email === workEmail) {
          toast({ html: 'You can not enter the same email address for personal and work purposes .', classes: 'red' });
          this.workEmail.setErrors({ 'invalidEmail': true });
          this.email.setErrors({ 'invalidEmail': true });
        }
      } else {
        email = value.toLowerCase();
        workEmail = this.workEmail.value.toLowerCase();
        if (workEmail && workEmail != '' && email === workEmail) {
          toast({ html: 'You can not enter the same email address for personal and work purposes.', classes: 'red' });
          this.email.setErrors({ 'invalidEmail': true });
          this.workEmail.setErrors({ 'invalidEmail': true });
        }
      }
    }
  }

  reset() {
    this.emailExists = false;
    this.workEmailExists = false;
    this.memberRequestFound = null;
    this.EmployeeRequestFound = null;
    this.dobHasValue = false;
    this.isnHasValue = false;
    this.enterDob = false;
    this.enterIsn = false;
    this.isn.reset();
  }

  departmentSelected(selectedValue: any) {
    if (selectedValue) {
      this.depService
        .getDepartmentEmployeeList(selectedValue)
        .valueChanges()
        .subscribe((emp) => {
          if (emp) {
            this.employeeList = emp;
            this.employeeList = this.employeeList.filter(
              (e) => e.isManager === true
            );
          }
        });
    }
  }

  reviewRequest(values: any) {
    // if (this.isMember || this.isBoth) {
    this.fireMemberService.getMemberByEmail(values.section1.email).valueChanges().subscribe((x) => {
      if (x && x.length > 0) {
        this.emailExists = true;
        // toast({ html: `<i class="material-icons" style="margin-right: 15px;">info</i><span class="white-text">${values.email} is already registred.`, classes: "red rounded" });
        if (this.isMember) {
          $('.modal').modal();
          $('#emailRegistredModal').modal('open');
          return;
        }
      } else if (!this.directSignup) {
        let exist: any;
        if (this.requests && this.requests.length > 0) {
          this.requests = [];
          //exist = this.requests.filter(r => r.email.toLowerCase() === model.email.toLowerCase() && r.Fname.toLowerCase() == model.firstName.toLowerCase() && r.Lname.toLowerCase() == model.lastName.toLowerCase());
          exist = this.requests.filter((r) => r.email.toLowerCase() === values.section1.email.toLowerCase());
        }
        if (exist && exist.length > 0) {
          this.memberRequestFound = exist;
          //toast({ html: '<i class="material-icons" style="margin-right: 15px;">info</i>  <span class="white-text"> Your Membership Application Is Pending For Approval. </pending>', classes: "red rounded" });
          if (this.isMember) {
            $(".modal").modal({ dismissible: false });
            $("#requestModal").modal("open");
            return;
          }
        } else if (this.isMember) {
          this.next(2);
          return;
          // if (!this.isnHasValue || !this.dobHasValue) {
          //   // $('.modal').modal();
          //   // $('#isnDobModal').modal('open');
          //   this.noISNDOB = true;
          //   return;
          // }
          // this.next(3);
        }
      } else {
        this.next(2);
        return;
      }
    });

    //  }

    // if (this.isEmployee || this.isBoth) {
    //   this.fireMemberService.getMemberByEmail(values.section1.workEmail).valueChanges().subscribe((x) => {
    //     if (x && x.length > 0) {
    //       this.workEmailExists = true;
    //       //toast({ html: `<i class="material-icons" style="margin-right: 15px;">info</i><span class="white-text">${values.workEmail} is already registred.`, classes: "red rounded" });
    //       $('.modal').modal();
    //       $('#emailRegistredModal').modal('open');
    //       return;
    //     } else if (this.isBoth && this.emailExists) {
    //       $('.modal').modal();
    //       $('#emailRegistredModal').modal('open');
    //     } else {
    //       let empExist: any;
    //       if (this.employeesRequests && this.employeesRequests.length > 0) {
    //         if (this.employeesRequests.length > 0) {
    //           empExist = this.employeesRequests.filter(
    //             (r) => r.workEmail === values.section1.workEmail
    //           );
    //         }
    //       }
    //       if (empExist && empExist.length > 0) {
    //         this.EmployeeRequestFound = empExist;
    //         //toast({ html: '<i class="material-icons" style="margin-right: 15px;">info</i>  <span class="white-text"> Your Employee Application Is Pending For Approval. </pending>', classes: "red rounded" });
    //         $(".modal").modal({ dismissible: false });
    //         $("#requestModal").modal("open");
    //         return;
    //       } else if (this.memberRequestFound) {

    //         $(".modal").modal({ dismissible: false });
    //         $("#requestModal").modal("open");
    //         return;
    //       } else {
    //         this.next(2);
    //         return;
    //       }
    //       // if (!this.isnHasValue || !this.dobHasValue) {
    //       //   // $('.modal').modal();
    //       //   // $('#isnDobModal').modal('open');
    //       //   this.noISNDOB = true;
    //       //   this.next(2);
    //       //   return;
    //       // }
    //     }
    //   });
    // }
  }

  enterIsnYes() {
    // if (this.isMember || this.isBoth) {
    //   if (this.TreatyNo) this.treatyNo.patchValue(this.TreatyNo);
    //   this.treatyNo.disable();
    //   this.isn.setValidators([Validators.minLength(7), Validators.maxLength(7), Validators.pattern('^[0-9]*$')]);
    // } else if (this.isEmployee) {
    //   this.isn.setValidators([Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern('^[0-9]*$')]);
    // }
    this.isn.setValidators([Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern('^[0-9]*$')]);
    this.isn.updateValueAndValidity();
    this.enterIsn = true;

    setTimeout(() => {
      M.updateTextFields();
    }, 25);
  }

  enterIsnNo() {
    this.enterIsn = false;
    // this.treatyNo.enable();
    // this.treatyNo.patchValue('');
    // this.treatyNo.clearValidators();
    // this.treatyNo.updateValueAndValidity();
    this.isn.patchValue(null);
    this.isn.clearValidators()
    this.isn.updateValueAndValidity();
  }

  enterDobYes() {
    this.enterDob = true;
    this.dob.setValidators([Validators.pattern(this.dateFormat), this.isValidDate, Validators.required]);
    this.dob.updateValueAndValidity();
    setTimeout(() => {
      M.updateTextFields();
    }, 25);
  }

  enterDobNo() {
    this.dob.patchValue('');
    this.dob.clearValidators();
    this.dob.updateValueAndValidity();
    this.enterDob = false;
  }

  saveValues(values) {
    this.isnHasValue = (this.isn.value && this.isn.value !== '') ? true : false;
    this.dobHasValue = (this.dob.value && this.dob.value !== '') ? true : false;
    // $('#isnDobModal').modal('close');
    this.next(3);
  }

  skip(values: any) {
    this.isnHasValue = false;
    this.dobHasValue = false;
    // $('#isnDobModal').modal('close');
    //this.NoISN = true;
    this.next(3);
  }

  uploadIdYes() {
    this.showUpload = true;
  }

  uploadIdNo() {
    this.showUpload = false;
  }

  handleFileInput(files: FileList) {
    if (files) {
      if (!this.isImage(files[0])) {
        alert('Invalid Document Type. \n Only Images Are Allowed.');
        return;
      }
      this.binDoc = files[0];
      const reader = new FileReader();
      reader.readAsDataURL(files[0]);
      reader.onload = () => {
        this.fileName = files[0].name;
        this.preview = reader.result as string;
        $('#previewModal').modal('open');
      };
    }
  }

  isImage(file: File): boolean {
    return /^image\//.test(file.type);
  }

  mobilePicture() {
    navigator.camera.getPicture(
      (imageData: any) => {
        if (imageData) {
          const imageName = 'MyNation.jpeg';
          const imageBlob = this.dataURItoBlob(imageData);
          const imageFile = new File([imageBlob], imageName, { type: 'image/jpeg' });
          this.binDoc = imageFile;
          this.preview = 'data:image/jpeg;base64,' + imageData;
        }
      },
      this.onMobileCameraFail,
      {
        quality: 50,
        destinationType: navigator.camera.DestinationType.DATA_URL,
        encodingType: navigator.camera.EncodingType.JPEG,
        mediaType: navigator.camera.MediaType.PICTURE,
        sourceType: navigator.camera.PictureSourceType.CAMERA,
      });
  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/png' });
    return blob;
  }

  libraryPicture() {
    navigator.camera.getPicture(
      (imageData: any) => {
        if (imageData) {
          const imageName = 'MyNation.jpeg';
          const imageBlob = this.dataURItoBlob(imageData);
          const imageFile = new File([imageBlob], imageName, { type: 'image/jpeg' });
          this.binDoc = imageFile;
          this.preview = 'data:image/jpeg;base64,' + imageData;
        }
      },
      this.onMobileCameraFail,
      {
        quality: 50,
        destinationType: navigator.camera.DestinationType.DATA_URL,
        encodingType: navigator.camera.EncodingType.JPEG,
        mediaType: navigator.camera.MediaType.PICTURE,
        sourceType: navigator.camera.PictureSourceType.PHOTOLIBRARY,
      });
  }

  onMobileCameraFail(message) {
    alert(message);
  }

  viewDoc() {
    $('#previewModal').modal('open');
  }

  deleteDoc() {
    this.binDoc = null;
    this.fileName = null;
    this.preview = null;
    this.document.reset();
    $("#uploadFile").val('');
  }

  submit(values) {
    console.log('values: ', values);
    if (this.directSignup) this.signup(values);
    else this.submitRequest(values);
  }

  changeDates(dateValue) {
    if (dateValue) {
      if (dateValue.match(this.dateFormat)) {
        let dateArray = dateValue.split('-');
        let year = dateArray[0];
        let month = dateArray[1] - 1;
        let day = dateArray[2];
        const date = new Date(year, month, day);
        return date;
      } else {
        const date = new Date(dateValue);
        return date;
      }
    } else {
      return null
    }
  }

  signup(values: any) {
    this.sqlModel = new AddBandMemberModel();

    this.sqlModel.GivenName = values.section1.firstName;
    this.sqlModel.middle_name = values.section1.middleName;
    this.sqlModel.LastName = values.section1.lastName;
    this.sqlModel.Email = values.section1.email;
    if (values.section2.dob) this.sqlModel.DOB = this.changeDates(values.section2.dob);
    if (values.section2.isn) this.sqlModel.IndianStatusNo = this.TreatyNo.concat(values.section2.isn);
    this.sqlModel.date_added = new Date();

    this.model = {
      applicantID: 0,
      GivenName: values.section1.firstName,
      LastName: values.section1.lastName,
      middle_name: values.section1.middleName,
      Email: values.section1.email,
      DOB: values.section2.dob ? values.section2.dob : '',
      Band: '',
      role: 'Member',
      verified: false,
      // verificationStatus: 'Unverified',
      fileStreamID: '',
    };

    if (this.binDoc && this.preview) {
      this.mediaService.UploadBinImage(this.binDoc).subscribe(x => {
        this.model.fileStreamID = x;
        this.memberService.AddMember(this.sqlModel).subscribe(x => {
          this.model.applicantID = x;
          this.authService.emailSignUp(this.model, values.section1.password).then(() => {
            // let sent: any = this.sendConfirmation();
            // if (sent) 
            toast({ html: 'Your registration was sucessfull', classes: 'green' });
            this.observer.unsubscribe();
            $('.modal').modal('close');
          }).catch(error => {
            toast({ html: error.message, classes: 'red' });
          });
        });
      });
    } else {
      this.memberService.AddMember(this.sqlModel).subscribe(x => {
        this.model.applicantID = x;
        this.authService.emailSignUp(this.model, values.section1.password).then(() => {
          // let sent: any = this.sendConfirmation();
          // if (sent) 
          toast({ html: 'Your registration was sucessfull', classes: 'green' });
          this.observer.unsubscribe();
          $('.modal').modal('close');
        }).catch(error => {
          toast({ html: error.message, classes: 'red' });
        });
      });
    }
  }

  submitRequest(values) {
    this.model = {
      Fname: values.section1.firstName,
      Lname: values.section1.lastName,
      Mname: values.section1.middleName,
      email: values.section1.email,
      dob: values.section2.dob ? values.section2.dob : '',
      isn: '',
      cellPhone: values.section1.cellPhone,
      datetime: new Date(),
      processed: false,
      officer: 'nordeen@nationadmin.ca',
      fileStreamID: '',
      status: 1
    };

    this.userName = this.model.Fname + " " + this.model.Lname;

    if (this.binDoc && this.preview) {
      this.documentService.postDoc(this.binDoc).subscribe(x => {
        this.model.fileStreamID = x; 

        this.loading = true;
    if (this.isMember) {
      //tobe deleted
      this.model.gender = '';
      this.model.sin = '';
      this.model.address = '';
      this.model.city = '';
      this.model.province = '';
      this.model.postalCode = '';
      this.model.community = '';
      this.model.healthCareNumber = '';
      //
      if (values.section2.isn) this.model.isn = this.TreatyNo.concat(values.section2.isn);
      this.sqlRegistrationRequest.saveRegistrationRequest(this.model).subscribe(x => {

        let sent: any = this.sendConfirmation();
        if (sent) this.loading = false;
      });
    }

    this.loading = true;
    if (this.isEmployee) {
      this.model.workEmail = values.section1.workEmail;
      this.model.department = values.section1.department;
      this.model.isMember = false;
      if (values.section2.isn) this.model.isn = values.section2.isn;
      this.employeeRegReq.saveRequest(this.model).then(x => {
        let sent: any = this.sendConfirmation();
        if (sent) this.loading = false;
      });
    }

    this.loading = true;
    if (this.isBoth) {
      this.model.workEmail = values.section1.workEmail;
      this.model.department = values.section1.department;
      this.model.isMember = true;

      this.model.gender = '';
      this.model.sin = '';
      this.model.address = '';
      this.model.city = '';
      this.model.province = '';
      this.model.postalCode = '';
      this.model.community = '';
      this.model.healthCareNumber = '';
      if (values.section2.isn) this.model.isn = this.TreatyNo.concat(values.section2.isn);
      this.sqlRegistrationRequest.saveRegistrationRequest(this.model).subscribe(x2 => {
        this.employeeRegReq.saveRequest(this.model).then(x => {
          let sent: any = this.sendConfirmation();
          if (sent) this.loading = false;
        });
      });
    }
    return;
      });
    } else {

    this.loading = true;
    if (this.isMember) {
      //tobe deleted
      this.model.gender = '';
      this.model.sin = '';
      this.model.address = '';
      this.model.city = '';
      this.model.province = '';
      this.model.postalCode = '';
      this.model.community = '';
      this.model.healthCareNumber = '';

      //if (values.section2.isn) this.model.isn = this.TreatyNo.concat(values.section2.isn);
      if (values.section2.isn) this.model.isn = values.section2.isn;

      //console.log('Model: ', this.model);
      this.sqlRegistrationRequest.saveRegistrationRequest(this.model).subscribe(x => {
        let sent: any = this.sendConfirmation();
        if (sent) this.loading = false;
      });
    }

    this.loading = true;
    if (this.isEmployee) {
      this.model.workEmail = values.section1.workEmail;
      this.model.department = values.section1.department;
      this.model.isMember = false;
      if (values.section2.isn) this.model.isn = values.section2.isn;
      this.employeeRegReq.saveRequest(this.model).then(x => {
        let sent: any = this.sendConfirmation();
        if (sent) this.loading = false;
      });
    }

    this.loading = true;
    if (this.isBoth) {
      this.model.workEmail = values.section1.workEmail;
      this.model.department = values.section1.department;
      this.model.isMember = true;

      this.model.gender = '';
      this.model.sin = '';
      this.model.address = '';
      this.model.city = '';
      this.model.province = '';
      this.model.postalCode = '';
      this.model.community = '';
      this.model.healthCareNumber = '';
      //if (values.section2.isn) this.model.isn = this.TreatyNo.concat(values.section2.isn);
      if (values.section2.isn) this.model.isn = values.section2.isn;
      this.sqlRegistrationRequest.saveRegistrationRequest(this.model).subscribe(x2 => {
        this.employeeRegReq.saveRequest(this.model).then(x => {
          let sent: any = this.sendConfirmation();
          if (sent) this.loading = false;
        });
      });
    }
  }
  }

  sendConfirmation() {
    const mail = new EmailModel();
    if (this.isMember) mail.emailList = [this.model.email];
    else if (this.isEmployee) mail.emailList = [this.model.workEmail];
    else if (this.isBoth) mail.emailList = [this.model.email, this.model.workEmail];
    if (this.directSignup) {
      mail.subject = ' Thank you for registering with ' + this.nationName;
      mail.body = '<p> Thank you for your signing up .<br/> We are excited to have you on board.</p> <br/> <p><strong> Thank you for using Our App.  </strong> </p>';
    } else {
      mail.subject = ' Thank you for registering with ' + this.nationName;
      mail.body = '<p> Thank you for your application.<br/>The administrator will review your application and get back to you shorlty.</p> <br/> <p><strong> Thank you for using Our App.  </strong> </p>';
    }

    this.emailService.sendEmail(mail).subscribe(x => {
      if (x) {
        this.showConfirmation('requestSent');
        return true;
      }
    });
  }

  contactAdmin() {
    const member = this.requestTojoinForm.value;
    this.contactForm = this.fb.group({
      firstName: [member.firstName, [Validators.required]],
      lastName: [member.lastName, [Validators.required]],
      email: [member.email, [Validators.required, Validators.email]],
      subject: ['Email Already Inuse', [Validators.required]],
      body: ['', [Validators.required]],
    });
    this.contact = true;
    setTimeout(() => {
      $('.modal').modal();
      $('#emailModal').modal('open');
      M.updateTextFields();
    }, 25);
  }

  get subject() { return this.contactForm.get('subject'); }
  get body() { return this.contactForm.get('body'); }
  get contactemail() { return this.contactForm.get('email'); }
  get contactfirstName() { return this.contactForm.get('firstName'); }
  get contactlastName() { return this.contactForm.get('lastName'); }

  sendEmail(model: any) {
    const mail = new EmailModel();
    mail.emailList = this.defaultEmails;
    mail.subject = 'You have a new message from ' + model.firstName + ' ' + model.lastName;
    mail.body = 'From : ' + model.email + ' <br/> Subject : <strong> ' + model.subject + ' </strong> <br/> <p>' + model.body + '</p>';

    this.emailService.sendEmail(mail).subscribe(x => {
      if (x.Status && x.Status == 'OK') {
        $('#emailModal').modal('close');
        this.showConfirmation('emailSent');
      }
    });
  }

  // closeModal() {
  //   $('.modal').modal('close');
  //   this.welcomeSetup = false;
  //   // this.reset();
  // }

  closeModal() {
    $('.modal').modal('close');
    this.welcomeSetup = false;
  }

  //Navigation
  next(step) {

    if (step == 2) {
      this.step1 = false;
      this.step2 = true;
      this.step3 = false;
    } else if (step == 3) {
      this.step1 = false;
      this.step2 = false;
      this.step3 = true;
    }

    setTimeout(() => {
      $('select').formSelect();
      $('.modal').modal();
      M.updateTextFields();
    }, 25);
  }

  back() {
    if (this.step3) {
      this.step2 = true;
      this.step3 = false;
    } else if (this.step2) {
      this.step1 = true;
      this.step2 = false;
    }

    $('.modal').modal('close');
    setTimeout(() => {
      $('select').formSelect();
      $('.modal').modal();
      M.updateTextFields();
    }, 25);
  }



  showConfirmation(info) {
    this.step1 = false;
    this.step2 = false;
    this.step3 = false;
    if (info == 'emailSent') this.emailSent = true;
    if (info == 'requestSent') {
      if (!this.directSignup) this.requestSent = true;
    }
  }

  backClicked() {
    //this.location.back();
    this.router.navigate(['/']);
  }


  // Implement functions to handle form submission, skipping steps, etc.
  // For example:
  submitForm(formData: any) {
    // Process the form data and submit it.
  }



}
// //Forms

// familyForm: FormGroup;

// //conditionals
// addFamilyMembers: boolean = null;
// showFamilyForm = false;
// dobSet = false;
// isMember = false;
// isEmployee = false;
// isBoth = false;
// isResident = false;
// isMale = false;
// isFemale = false;
// otherNationMember: boolean;
// showIsn: boolean;

//

// //save datas
// dobSelected = '';
// requests: any[];
// employeesRequests: any[];
// departmentsList: any[];

// //registredEmails: any[];

//
// userlastName = '';

// nationAdmins: any[];
// registredMembers: any[];
// filtredResult: any[];
// adminEmails: any[];

// spouse = false;

// step0 = false;
// // step2 = false;
// // step3 = false;
// step4 = false;
// officers: any;
// isClient = false;
// isNotACliet = false;
// //isResident = false;
// isNotResident = false;
// isNotMember = false;
// step0isMember = false;
// step0isNotMember = false;
// step0isResident = false;
// step0isNotResident = false;
// isNotEmployee = false;
// isNone = false;

// messageToAdmin: any;
// step0isEmployee = false;
// step0isNotEmployee = false;
// contactDep = false;
// selectedEmployee: any;
// nationCommunities: any[];
// selectedCommunity: any = '';
// //dateSelected: any;
// dates = [];
// myDpOptions: IAngularMyDpOptions = {
//   dateRange: false,
//   dateFormat: 'dd/mm/yyyy',
//   markCurrentDay: true
// };
// // (?:(?:0[1-9]|1[0-9]|2[0-9])\.(?:0[1-9]|1[0-2])|(?:30)\.(?:(?!02)(?:0[1-9]|1[0-2]))|(?:31)\.(?:0[13578]|1[02]))\.(?:19|20)[0-9]{2}

// isDOBValid = true;
// selectedGender: any;
// NoISN = false;
// showSignaturePad = false;
// isSignYes = false;
// isSignNo = false;
// canvas: any;
// signaturePad: SignaturePad;
// addFamily = false;
// displayAdded = false;

// ngOnInit() {
//   if (this.nationofUse === 'Batc' || this.nationofUse === 'Pbcn') {
//     // if (this.nationofUse === 'Pbcn') {
//     //   // this.showUpload = true;
//     // }
//     this.communityService.getNationCommunities().subscribe(
//       com => {
//         if (com.length > 0) {
//           this.nationCommunities = com;
//         }
//       }
//     );
//   } else if (this.nationofUse === 'Lrrcn' || this.nationofUse === 'DeneTha') {
//     this.communityService.getCommunity().subscribe(
//       com => {
//         if (com.length > 0) {
//           this.nationCommunities = com;
//         }
//       }
//     );
//   }

//   this.sqlRegistrationRequest.getRegistrationRequests().subscribe(r => {
//     if (r) {
//       if (r.length > 0) {

//         this.requests = r;
//         //this.requests = this.requests.filter(rq => rq.status && rq.status === 1);
//       }
//     }
//   });

//   this.employeeRegReq.getRequests().valueChanges().subscribe(r => {
//     if (r && r.length > 0) {
//       this.employeesRequests = r;
//       this.employeesRequests = this.employeesRequests.filter(rq => rq.status && rq.status <= 3);
//     }
//   });

//   this.sqlRegistrationRequest.getTreatyNumber().subscribe(t => {
//     if (t) {
//       this.treatyNo = t;
//       this.requestTojoinForm = this.fb.group({
//         firstName: ['', [Validators.required, Validators.maxLength(50)]],
//         lastName: ['', [Validators.required, Validators.maxLength(50)]],
//         middleName: [''],
//         dob: ['', [Validators.pattern(this.dateFormat), this.isValidDate]],
//         cellPhone: [''],
//         gender: [''],
//         email: ['', [Validators.required, Validators.email]],
//         isn: ['', [Validators.minLength(10), Validators.maxLength(10), Validators.pattern('^[0-9]*$')]],
//         treatyNo: ['', [Validators.maxLength(3), Validators.minLength(3), Validators.pattern('^[0-9]*$')]],
//         //isn: [''],
//         healthCareNumber: [''],
//         address: [''],
//         city: [''],
//         province: [this.provinceOfUSe],
//         postalCode: [''],
//         document: [''],
//         message: [''],
//         department: [''],
//       });

//       if (this.nationofUse === 'Batc' || this.nationofUse === 'Pbcn' || this.nationofUse === 'Lrrcn' || this.nationofUse === 'DeneTha') {
//         this.requestTojoinForm.patchValue({ isn: '' });
//       }

//       this.familyForm = this.fb.group({
//         addFamilyMembers: ['', [Validators.required]],
//         firstName: [''],
//         middleName: [''],
//         lastName: [''],
//         dob: [''],
//         isn: [''],
//         relationship: [''],
//         members: this.fb.array([]),
//         // firstName: ['', [Validators.required, Validators.minLength(1)]],
//         // middleName: [''],
//         // lastName: ['', [Validators.required, Validators.minLength(1)]],
//         // dob: ['', [Validators.required, this.isValidDate]],
//         // isn: ['', [Validators.minLength(10), Validators.maxLength(10), Validators.pattern('^[0-9]*$')]],
//         // relationship: ['', [Validators.required]],
//         // members: this.fb.array([]),
//       });

//       this.familyForm.get('addFamilyMembers').valueChanges.subscribe(value => {
//         if (value) {
//           this.fam_firstName.setValidators(Validators.required);
//           this.fam_lastName.setValidators(Validators.required);
//           this.relationship.setValidators(Validators.required);
//         } else {
//           this.fam_firstName.clearValidators();
//           this.fam_lastName.clearValidators();
//           this.relationship.clearValidators();
//         }
//         this.fam_firstName.updateValueAndValidity();
//         this.fam_lastName.updateValueAndValidity();
//         this.relationship.updateValueAndValidity();
//       });

//       //  else if (this.isMember) {
//       //   this.requestTojoinForm.patchValue({ isn: t });
//       // }
//       setTimeout(() => {
//         $('select').formSelect();
//         $('.modal').modal();
//         $('#welcomeModal').modal('open');
//         M.updateTextFields();
//       }, 25);
//     }
//   });

//   this.depService.getDepartmentList().valueChanges().subscribe(x => {
//     if (x) {
//       this.departmentsList = x;
//     }
//   });
// }


// get cellPhone() { return this.requestTojoinForm.get('cellPhone'); }
// get gender() { return this.requestTojoinForm.get('gender'); }
//
// get address() { return this.requestTojoinForm.get('address'); }
// get city() { return this.requestTojoinForm.get('city'); }
// get province() { return this.requestTojoinForm.get('province'); }
// get postalCode() { return this.requestTojoinForm.get('postalCode'); }
// get message() { return this.requestTojoinForm.get('message'); }
// get workEmail() { return this.requestTojoinForm.get('workEmail'); }

// get fam_firstName() { return this.familyForm.get('firstName'); }
// get fam_middleName() { return this.familyForm.get('middleName'); }
// get fam_lastName() { return this.familyForm.get('lastName'); }
// get fam_dob() { return this.familyForm.get('dob'); }
// get fam_isn() { return this.familyForm.get('isn'); }
// get relationship() { return this.familyForm.get('relationship'); }
// get memberControls() { return this.familyForm.get('members') as FormArray; }

// isValidDate(control: FormControl) {
//   const dateformat = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
//   let date = control.value;
//   if (date) {
//     if (date.match(dateformat)) {
//       let dateArray = date.split('/');
//       let day = dateArray[0];
//       let month = dateArray[1] - 1;
//       let year = dateArray[2];
//       date = new Date(year, month, day);
//       const today = new Date();
//       if (Number(year) <= Number(today.getFullYear() - 100)) {
//         return { 'invalidDate': true }
//       } else if (date.valueOf() >= today.valueOf()) {
//         return { 'invalidDate': true }
//       } else {
//         return null;
//       }
//     } else {
//       return { 'invalidDate': true }
//     }
//   }
//   return null;
// }

// setDob(e) {
//   if (e.match(this.dateFormat)) {
//     this.dobSelected = e;
//     const date = this.changeDates(e);
//     const datePipe = new DatePipe('en-US');
//     const formatedDate = datePipe.transform(date, 'fullDate');
//     this.dob.patchValue(formatedDate);
//     this.dobSet = true;
//   }
// }

// unsetDob() {
//   this.dob.patchValue(this.dobSelected);
//   this.dobSet = false;
// }

// changeDates(dateValue) {
//   if (dateValue) {
//     if (dateValue.match(this.dateFormat)) {
//       let dateArray = dateValue.split('/');
//       let year = dateArray[2];
//       let month = dateArray[1] - 1;
//       let day = dateArray[0];
//       const date = new Date(year, month, day);
//       return date;
//     } else {
//       const date = new Date(dateValue);
//       return date;
//     }
//   } else {
//     return null
//   }
// }

// memberClicked() {
//   this.reset();
//   this.isMember = true;
//   this.isEmployee = false;
//   this.isResident = false;

//   this.requestTojoinForm.patchValue({ treatyNo: this.treatyNo });
//   this.requestTojoinForm.get('treatyNo').disable();
//   this.requestTojoinForm.get('isn').setValidators([Validators.minLength(7), Validators.maxLength(7), Validators.pattern('^[0-9]*$')]);
//   this.requestTojoinForm.get('isn').updateValueAndValidity();
//   // this.requestTojoinForm.get('isn').patchValue(this.treatyNo);
//   this.showIsn = true;
//   this.requestTojoinForm.get('department').clearValidators();
//   this.requestTojoinForm.get('department').updateValueAndValidity();
//   setTimeout(() => {
//     M.updateTextFields();
//   }, 25);
// }

// employeeClicked() {
//   this.reset();
//   this.isEmployee = true;
//   this.isMember = false;
//   this.isResident = false;
//   this.isBoth = false;
//   this.showIsn = null;

//   this.requestTojoinForm.get('treatyNo').enable();
//   this.requestTojoinForm.patchValue({ treatyNo: '' });
//   this.requestTojoinForm.get('treatyNo').clearValidators();
//   this.requestTojoinForm.get('treatyNo').updateValueAndValidity();

//   this.requestTojoinForm.patchValue({ isn: '' });
//   this.requestTojoinForm.get('isn').clearValidators();
//   this.requestTojoinForm.get('isn').updateValueAndValidity();

//   this.requestTojoinForm.get('department').setValidators(Validators.required);
//   this.requestTojoinForm.get('department').updateValueAndValidity();

//   setTimeout(() => {
//     $('select').formSelect();
//     M.updateTextFields();
//     $('.tooltipped').tooltip();
//   }, 25);
// }

// residentClicked() {
//   this.reset();
//   this.isResident = true;
//   this.isMember = false;
//   this.isEmployee = false;
//   this.isBoth = false;

//   this.requestTojoinForm.get('isn').setValidators([Validators.minLength(10), Validators.maxLength(10), Validators.pattern('^[0-9]*$')]);
//   this.requestTojoinForm.get('isn').updateValueAndValidity();
//   this.showIsn = true;

//   this.requestTojoinForm.get('department').clearValidators();
//   this.requestTojoinForm.get('department').updateValueAndValidity();
//   setTimeout(() => {
//     $('select').formSelect();
//     M.updateTextFields();
//     $('.tooltipped').tooltip();
//   }, 25);
// }

// bothClicked() {
//   this.reset();
//   this.isBoth = true;
//   this.isEmployee = false;
//   this.isResident = false;
//   this.isMember = false;
//   // this.requestTojoinForm.patchValue({ treatyNo: this.treatyNo });
//   // this.requestTojoinForm.get('treatyNo').disable();
//   // this.requestTojoinForm.get('isn').setValidators([Validators.minLength(7), Validators.maxLength(7), Validators.pattern('^[0-9]*$')]);
//   // this.requestTojoinForm.get('isn').updateValueAndValidity();
//   // this.requestTojoinForm.addControl('workEmail', new FormControl('', [Validators.email]));
//   this.requestTojoinForm.get('department').setValidators(Validators.required);
//   this.requestTojoinForm.get('department').updateValueAndValidity();
//   setTimeout(() => {
//     $('select').formSelect();
//     M.updateTextFields();
//     $('.tooltipped').tooltip();
//   }, 25);
// }

// reset() {
//   this.enterDob = false;
//   this.enterIsn = false;
//   this.isn.reset();
// }

// maleClick() {
//   this.isMale = !this.isMale
//   if (this.isMale) {
//     this.requestTojoinForm.patchValue({ gender: 'Male' })
//   }
//   this.isFemale = false;
// }

// femaleClick() {
//   this.isFemale = !this.isFemale
//   if (this.isFemale) {
//     this.requestTojoinForm.patchValue({ gender: 'Female' })
//   }
//   this.isMale = false;
// }

// MemberOfNationYes() {
//   this.otherNationMember = true;
// }

// MemberOfNationNo() {
//   this.otherNationMember = false;
//   this.showIsn = null;
//   this.isn.patchValue('');
//   this.requestTojoinForm.get('isn').clearValidators();
//   this.requestTojoinForm.get('isn').updateValueAndValidity();
// }

// isnYes() {
//   this.showIsn = true;
//   this.isn.setValidators([Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern('^[0-9]*$')]);
//   this.isn.updateValueAndValidity();
// }

// isnNo() {
//   this.showIsn = false;
//   this.isn.patchValue('');
//   this.isn.clearValidators();
//   this.isn.updateValueAndValidity();
// }

// reviewRequest(model: any) {
//   $('.modal').modal('close');
//   //check if email is already used
//   this.fireMemberService.getMemberByEmail(model.email).valueChanges().subscribe(x => {
//     if (x && x.length > 0) {
//       this.goTo(3, 'result');
//       return;

//     } else {
//       // //check if request alredy exists
//       // if (this.isMember || this.isResident) {
//       //   let exist: any;
//       //   if (this.requests && this.requests.length > 0) {
//       //     if (this.requests.length > 0) {
//       //       //exist = this.requests.filter(r => r.email.toLowerCase() === model.email.toLowerCase() && r.Fname.toLowerCase() == model.firstName.toLowerCase() && r.Lname.toLowerCase() == model.lastName.toLowerCase());
//       //       exist = this.requests.filter(r => r.email.toLowerCase() === model.email.toLowerCase());
//       //     }
//       //   }

//       //   if (exist) {
//       //     if (exist.length > 0) {
//       //       this.requestFound = exist;
//       //       $('.modal').modal({ 'dismissible': false });
//       //       $('#requestModal').modal('open');
//       //       //toast({ html: '<i class="material-icons" style="margin-right: 15px;">info</i>  <span class="white-text"> Your Membership Application Is Pending For Approval. </pending>', classes: 'red' });
//       //       return;
//       //     }
//       //   }
//       // } else if (this.isEmployee) {
//       //   let empExist: any;
//       //   if (this.employeesRequests && this.employeesRequests.length > 0) {
//       //     if (this.employeesRequests.length > 0) {
//       //       empExist = this.employeesRequests.filter(r => r.email === model.email);
//       //     }
//       //   }

//       //   if (empExist) {
//       //     if (empExist.length > 0) {
//       //       this.requestFound = empExist;
//       //       $('.modal').modal({ 'dismissible': false });
//       //       $('#requestModal').modal('open');
//       //       //toast({ html: '<i class="material-icons" style="margin-right: 15px;">info</i>  <span class="white-text"> Your Employee Application Is Pending For Approval. </pending>', classes: 'red' });
//       //       return;
//       //     }
//       //   }
//       // }

//       //Check if isn id provided if user is member or both
//       if (this.isMember || this.isResident) {
//         this.isnHasValue = (this.isn.value && this.isn.value !== '') ? true : false;
//         this.dobHasValue = (this.dob.value && this.dob.value !== '') ? true : false;
//         if (!this.isnHasValue || !this.dobHasValue) {
//           $('.modal').modal();
//           $('#isnModal').modal('open');
//           return;
//         }
//       }
//       this.SubmitRequest(model);
//     }
//   });
// }

// // isnYes() {
// //   this.isn.addValidators(Validators.required);
// //   this.isn.updateValueAndValidity();
// //   $('#isnModal').modal('close');
// //   $('#isnInputModal').modal('open');
// // }

// // cancelIsn() {
// //   $('#isnInputModal').modal('close');
// //   this.isn.patchValue('');
// //   this.isn.removeValidators(Validators.required);
// //   this.isn.updateValueAndValidity();
// // }

// // familyStep() {
// //   this.step1 = false;
// //   this.step2 = true;
// // }

// addFamilyMembersYes() {
//   //this.addFamilyMembers = true;
//   this.showFamilyForm = true;
//   this.dobSelected = '';
//   this.fam_firstName.reset();
//   this.fam_middleName.reset();
//   this.fam_lastName.reset();
//   this.fam_dob.reset();
//   this.fam_isn.reset()
//   this.relationship.reset();
//   setTimeout(() => {
//     $('.collapsible').collapsible();
//     $('select').formSelect();
//     $('.modal').modal();
//     M.updateTextFields();
//   }, 25);
// }

// addFamilyMembersNo() {
//   //this.addFamilyMembers = false;
//   this.showFamilyForm = false;
//   while (this.memberControls.length !== 0) {
//     this.memberControls.removeAt(0);
//   }

// }

// newMember(values: any) {
//   return this.fb.group({
//     firstName: [values.firstName],
//     middleName: [values.middleName],
//     lastName: [values.lastName],
//     dob: [values.dob],
//     isn: [values.isn],
//     relationship: [values.relationship]
//   });
// }

// addNewMember(values: any) {
//   const members = this.memberControls;
//   values.dob = this.changeDates(values.dob);
//   members.push(this.newMember(values));
//   this.showFamilyForm = false;
//   this.displayAdded = true;
//   setTimeout(() => {
//     $('.collapsible').collapsible();
//   }, 25);
// }

// closeForm() {
//   this.showFamilyForm = false;
//   if (this.memberControls.length == 0) {
//     this.familyForm.patchValue({ 'addFamilyMembers': false });
//   }
// }

// onDeleteFamilyMember(index) {
//   const members = this.memberControls;
//   members.removeAt(index);
//   if (members.length == 0) {
//     this.displayAdded = false;
//   }
//   if (this.memberControls.length == 0) {
//     this.familyForm.patchValue({ 'addFamilyMembers': false });
//   }
// }

// relationshipSelected(value) {
//   if (value == 'Spouse') {
//     this.spouse = true;
//     this.fam_dob.setValidators([Validators.required, this.isValidDate]);
//     this.fam_dob.updateValueAndValidity();
//     this.fam_isn.setValidators([Validators.required, Validators.minLength(10), Validators.maxLength(10), Validators.pattern('^[0-9]*$')]);
//     this.fam_isn.updateValueAndValidity();
//   } else {
//     this.spouse = false;
//     this.fam_dob.clearValidators();
//     this.fam_dob.updateValueAndValidity();
//     this.fam_isn.clearValidators();
//     this.fam_isn.updateValueAndValidity();
//   }
// }

// SubmitRequest(model: any) {
//   // if(this.isSignYes && this.showSignaturePad) {
//   //   if(this.signaturePad.isEmpty()) {
//   //     M.toast({ html: 'Please Signe Your Application!', classes: 'red' });
//   //   return;
//   //   }
//   // }

