import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Application } from 'src/app/models/ApplicationModel';
import { ApplicationService } from 'src/app/services/firebase/application.service';
import { Location } from '@angular/common';
import { MediaService } from 'src/app/services/api/media.service';
import { toast } from 'materialize-css';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-edit-application',
  templateUrl: './edit-application.component.html',
  styleUrls: ['./edit-application.component.css']
})

export class EditApplicationComponent implements OnInit {

  departmentList = ['Social Development', 'Administration', 'Housing and Maintenace', 'Education'];
  fieldTypes = [{ name: "Short Answer", value: 'Input' }, { name: 'Paragraph', value: 'TextArea' }, { name: 'Dropdown', value: 'Select' }, { name: 'Multiple Choice', value: 'Radio' }, { name: 'Multiple Choice (Allow Multiple Answers)', value: 'Checkbox' }, { name: 'File Upload', value: 'File' }, { name: 'Scale', value: 'Scale' }];
  dataTypes = ['text', 'number', 'date', 'tel'];
  icons = ['person', 'tag', 'add_location', 'description', 'people', 'location_on', 'location_city', 'mail', 'house', 'phone', 'phone_iphone', 'school', 'today', 'apps', 'attach_money', 'attachment', 'call', 'edit', 'event_note', 'face', 'group', 'home', 'link', 'send', 'share'];
  scales = ['2', '3', '4', '5', '6', '7', '8', '9', '10'];
  tables = ['Members', 'Family Composition', 'Assets', 'Incomes'];

  loading = false;
  applicationName: any;
  application: any;
  templates: any[];
  step = 0;
  appForm: UntypedFormGroup;
  appDetail: UntypedFormGroup;
  logoSrc: any;
  model: Application;
  currentSection = 0;
  currentField = 0;
  order = 1;
  appButton = false;
  iconNotSelected = [];
  showFieldDetail = [true];
  fieldType = 'Input';
  
  constructor(private route: ActivatedRoute, private fb: UntypedFormBuilder,
    private applicationService: ApplicationService,
    private location: Location,
    private mediaService: MediaService) { }

  ngOnInit(): void {
    this.loading = true;

    this.applicationService.getTemplates().valueChanges().subscribe(x => {
      if (x) {
        this.templates = x;
        this.templates.forEach(template => {
          template.include = false;
        });
        this.templates.sort((a, b) => (a.title < b.title ? -1 : 1));
      }
      this.loading = false;
    });

    this.route.paramMap.subscribe(params => {
      this.applicationName = params.get('name');
      if (this.applicationName) {
        this.applicationService.getApplication(this.applicationName).valueChanges().subscribe(application => {
          if (application) {
            this.application = application[0];
           
            this.appForm = this.fb.group({
              name: [this.application.name, [Validators.required]],
              description: [this.application.description],
              department: [this.application.department, [Validators.required]],
              logo: [],
              menu: this.fb.group({
                //name: ['', [Validators.required]],
                color: [this.application.menu.color],
                icon: [this.application.menu.icon],
              })
            });

            if(this.application.logo) this.logoSrc = this.application.logo;
            if(this.application.menu) this.appButton = true;

            this.appDetail = this.fb.group({
              sections: this.fb.array([]),
            });
        
            let sections = this.appDetail.get('sections') as UntypedFormArray;
           
            this.application.sections.sort((a, b) => (a.order < b.order ? -1 : 1));
          
            this.application.sections.forEach(section => {
             sections.push(this.addSection(section));
            });
          }
        });
      }
    });

    this.model = new Application();
  }

  get appName() { return this.appForm.get('name'); }
  get logo() { return this.appForm.get('logo'); }
  get sectionControls() { return this.appDetail.get('sections') as UntypedFormArray; }

  getSectionFields(s) {
    const section = this.sectionControls.controls[s] as UntypedFormGroup;
    const fields = section.controls.fields as UntypedFormArray;
    return fields
  }

  getOptions(si, fi) {
    const fields = this.sectionControls.controls[si].get('fields') as UntypedFormArray;
    const field = fields.controls[fi];
    return field.get('options') as UntypedFormArray;
  }
  
  uploadLogo(files: FileList) {
    const file = files[0];
    if (this.isImage(file)) {
      this.loading = true;
      this.mediaService.UploadBinImage(file).subscribe(upload => {
        if (upload) this.logoSrc = upload.imgLink;
        this.loading = false;
      },error => {
        toast({ html: 'Error uploading image: ${error}', classes: 'red' });
        this.loading = false;
      }
      );
    }
  }

  clearLogo() {
    this.mediaService.DeleteBinImage(this.logoSrc).subscribe(status =>{
      if(status) this.logoSrc = null;
    });
  }

  addAppButton(){
    this.appButton = !this.appButton;
    setTimeout(() => {
      M.updateTextFields();
      $('select').formSelect();
      $('.dropdown-trigger').dropdown();

    }, 10);
  }

  sectionSelected(title, index) {
    const section = this.application.sections.filter(section => section.title == title);
    if (section.length > 0) {
     this.templates[index].include = true;
      return true;
    } else {
      this.templates[index].include = true;
      return false;
    }
  }

  selectSection(index) {
    this.templates[index].include = !this.templates[index].include;
    const sections = this.sectionControls;
    if (this.templates[index].include) {
      const section = this.templates[index];
      sections.push(this.addSection(section))
    }else{
      sections.removeAt(index);
    }
  }
  
  isImage(file: File): boolean {
    return /^image\//.test(file.type);
  }

  newSection() {
    return this.fb.group({
      title: ['', [Validators.required]],
      statement: [],
      order: [],
      data: [''],
      fields: this.fb.array([this.newField()]),
      multipleEntry: [],
    });
  }

  addSection(values) {
    let section = this.fb.group({
      title: [values.title, [Validators.required]],
      statement: [values.statement],
      data: [values.data],
      order: [],
      fields: this.fb.array([]),
      multipleEntry: [values.multipleEntry],
    });
    //this.order += 1;
    let fields = section.get('fields') as UntypedFormArray;
    values.fields.forEach(field => {
      fields.push(this.addField(field));
    });

    //this.currentSection += 1;
    return section;
  }

  newField() {
    return this.fb.group({
      label: ['', [Validators.required]],
      //form_control: ['', Validators.required],
      default_value: [''],
      field_type: ['Input', Validators.required],
      //select_options: [],
      data_type: ['text', Validators.required],
      icon: [''],
      //color: [],
      required: [false],
      //textMask: [false],
      //methods: [],
      //validation: this.formBuilder.group([]),
    });
  }

  addField(values) {
  let group = this.fb.group({
      label: [values.label, [Validators.required]],
      default_value: [values.default_value],
      field_type: [values.field_type, Validators.required],
      data_type: [values.data_type, Validators.required],
      icon: [values.icon],
      required: [values.required],
      //textMask: [false],
      //methods: [],
      //validation: this.formBuilder.group([]),
    });

    if (values.field_type == 'Select' || values.field_type == 'Checkbox' || values.field_type == 'Radio') {
    group.addControl('options', new UntypedFormArray([]));
    let options = group.get('options') as UntypedFormArray;
    values.options.forEach(option => {
      options.push(this.optionAdd(option));
    });
    }else if (values.field_type == 'Scale') {
      group.addControl('scales', new UntypedFormGroup({
        start: new UntypedFormControl('1'),
        end: new UntypedFormControl('10'),
        startLabel: new UntypedFormControl(''),
        endLabel: new UntypedFormControl('')
      }));
    }
      return group;
  }

  onAddField() {
    //this.addFields = true;
    this.showFieldDetail[this.currentField] = false;
    this.fieldType = 'Input';
    const section = this.sectionControls.controls[this.currentSection] as UntypedFormGroup;
    const fields = section.controls.fields as UntypedFormArray;
    fields.push(this.newField());
    this.currentField += 1;
    this.showFieldDetail[this.currentField] = true;
    setTimeout(() => {
      M.updateTextFields();
      $('select').formSelect();
    }, 25);
  }

  fieldTypeSelected(value, si, fi) {
    this.fieldType = value;
    const fields = this.sectionControls.controls[si].get('fields') as UntypedFormArray;
    let field = fields.controls[fi] as UntypedFormGroup;
    if (value == 'Select' || value == 'Checkbox' || value == 'Radio') {
      field.addControl('options', new UntypedFormArray([this.newOption()]));

    } else if (value == 'Scale') {
      field.addControl('scales', new UntypedFormGroup({
        start: new UntypedFormControl('1'),
        end: new UntypedFormControl('10'),
        startLabel: new UntypedFormControl(''),
        endLabel: new UntypedFormControl('')
      }))
    }

    setTimeout(() => {
      M.updateTextFields();
      $('select').formSelect();
    }, 25);
  }

  optionAdd(option){
    return this.fb.control(option);
  }

  newOption() {
    return this.fb.control('', [Validators.required])
  }

  addOption(si, fi) {
    const options = this.getOptions(si, fi);
    options.push(this.newOption());
  }

  dataTypeSelected(value, index) {
  }

  iconSelected(index) {
    this.iconNotSelected[index] = false;
  }
  
  onShowFieldDetail(index) {
    this.showFieldDetail[index] = !this.showFieldDetail[index];
    setTimeout(() => {
      M.updateTextFields();
      $('select').formSelect();
    }, 25);
  }

  onAddSection() {
    const sections = this.sectionControls;
    sections.push(this.newSection());
    //this.order += 1;
    this.currentSection += 1;
    setTimeout(() => {
      M.updateTextFields();
      $('select').formSelect();
      $('.fixed-action-btn').floatingActionButton({
        direction: screen.width <= 600 ? 'bottom' : 'left',
        hoverEnabled: false
      });
      $('.tooltipped').tooltip();
    }, 25);
  }

  deleteSection(index) {
    const sections = this.sectionControls as UntypedFormArray;
    sections.removeAt(index);
    this.currentSection -= 1;
    setTimeout(() => {
      M.updateTextFields();
      $('select').formSelect();
      $('.fixed-action-btn').floatingActionButton({
        direction: screen.width <= 600 ? 'bottom' : 'left',
        hoverEnabled: false
      });
      $('.tooltipped').tooltip();
    }, 25);

  }

  deleteField(si, fi) {
    const section = this.sectionControls.controls[si] as UntypedFormGroup;
    const fields = section.controls.fields as UntypedFormArray;
    fields.removeAt(fi);
  }

  duplicateSection(index) {
    const sections = this.sectionControls as UntypedFormArray;
    const section = sections.controls[index];
    sections.push(section);
    //sections.controls[index+1].reset();
    this.currentSection += 1;

    setTimeout(() => {
      M.updateTextFields();
      $('select').formSelect();
      $('.fixed-action-btn').floatingActionButton({
        direction: screen.width <= 600 ? 'bottom' : 'left',
        hoverEnabled: false
      });
      $('.tooltipped').tooltip();
    }, 25);
  }

  saveSection(index) {
    const sections = this.sectionControls as UntypedFormArray;
    const section = sections.controls[index];
    this.applicationService.saveTemplate(section.value).then(x => {
      toast({ html: 'Section is saved as a Template!', classes: 'green' });
    });
  }

  previousSection() {
    this.currentSection -= 1;
    setTimeout(() => {
      M.updateTextFields();
      $('select').formSelect();
    }, 25);
  }

  nextSection() {
    this.currentSection += 1;
    this.showFieldDetail = [true];
    setTimeout(() => {
      M.updateTextFields();
      $('select').formSelect();
    }, 25);
  }

  goto(section) {
    this.currentSection = section;
    setTimeout(() => {
      M.updateTextFields();
      $('select').formSelect();
    }, 25);
  }

  submitAppForm(values) {
    //this.applicationService.applicationNameExists(values.name)
    this.model.name = values.name;
    this.model.description = values.description;
    this.model.department = values.department;
    this.model.logo = this.logoSrc ? this.logoSrc : '';
    this.model.menu = values.menu;
    this.model.menu.name = values.name;
  
    this.next();
  }

  submitAppDetails(values) {
    this.model.sections = values.sections;
    this.model.sections.forEach(section => {
      section.order = this.order;
      this.order += 1;
    });
    this.applicationService.updateApplication(this.application.id, this.model).then(x => {
      toast({ html: 'Application Successfully Created!', classes: 'green' });
      this.step += 1;
    });
  }

  next() {
    this.step += 1;
    if (this.step == 1) {
      if (this.sectionControls.length == 0) {
        this.sectionControls.push(this.newSection());
      }
    }
    if (this.step > 2) {
      this.currentSection += 1;
    }

    setTimeout(() => {
      M.updateTextFields();
      $('select').formSelect();
      M.textareaAutoResize($('.materialize-textarea'));
      $('.fixed-action-btn').floatingActionButton({
        direction: screen.width <= 600 ? 'bottom' : 'left',
        hoverEnabled: false
      });
      $('.tooltipped').tooltip();
    }, 10);
  }

  back() {
    this.step -= 1;
    if (this.step > 2) {
      this.currentSection -= 1;
    }
    setTimeout(() => {
      M.updateTextFields();
      $('select').formSelect();
      M.textareaAutoResize($('.materialize-textarea'));
    }, 25);
  }

  backToPreviousePage() {
    this.location.back();
  }

  editApp(){
    this.step = 0;
  }
}
