<div class="row" *ngIf="aboutUsSection">
  <div class="row p-t-3 p-b-2">
    <div class="col s12 m9 l5 push-l1 push-m1">
      <div class="header-title">
        <h6 [ngStyle]="{ color: aboutUsSection.displayNameColor }">
          {{ aboutUsSection.displayName }}
        </h6>
      </div>
    </div>
    <div class="col l6  hide-on-small-only " style="padding: 30px 0px;">
      <p class="seeAll-displayName teal-text right"><a [routerLink]="['/about-us']" (click)="navigate()"
          [ngStyle]="{'color': aboutUsSection.displayNameColor}">
          <i>See All {{aboutUsSection.displayName}} >>
          </i></a>
      </p>
    </div>
    <div class="divider col l10 m10 s8 push-l1 push-m1 push-s2 grey lighten-3"></div>
  </div>
  <!--Lis -->
  <div class="row col l10 push-l1 s12 m10 push-m1 container">
    <div class="col s12 m6 l6 xl6" *ngFor="let item of aboutUs">
      <div class="row col s12 card list-view" [ngStyle]="{ color: themeColor }" *ngIf="item.imgLink"
        [routerLink]="['/view-about-us-section', item.id]">
        <div class="col s12 l4 hide-on-med-and-up" style="padding: 0">
          <img class="responsive-img b-n-1" [src]="item.imgLink" alt="aboutus image" />
        </div>

        <div class="col s12 l8">
          <!-- <div class="date"> {{item.datePublished .toDate() | date: 'MMM dd, yyyy'}}</div> -->
          <h1 [innerHTML]="item.title" class="title-section3 bolder p-m-1"></h1>
          <span [innerHTML]="truncateHTML(item.body, 300) | noSanitize"
            class="small-body-snippet bold-100 hide-on-small-only"></span>

          <div class="p-t-2">
            <span class="bold-900 sub-title-section3" style="display: flex; align-items: center"
              [ngStyle]="{ color: themeColor }">Read More
              <i class="material-symbols-outlined sub-title-section2 bold-500">arrow_forward</i>
            </span>
          </div>
        </div>
        <div class="col s12 l4 hide-on-small-only">
          <img class="responsive-img b-n-1" [src]="item.imgLink" alt="aboutus image" />
        </div>
      </div>

      <div class="row col s12 card list-view" [ngStyle]="{ color: themeColor }" *ngIf="!item.imgLink"
        [routerLink]="['/view-about-us-section', item.id]">
        <div class="col s12">
          <!-- <div class="date1"> {{item.datePublished .toDate() | date: 'MMM dd, yyyy'}}</div> -->
          <h1 [innerHTML]="item.title" class="title-section3 bolder" style="margin-top: 0"></h1>
          <span [innerHTML]="truncateHTML(item.body, 300) | noSanitize" class="small-body-snippet bold-100"></span>

          <div class="p-t-2">
            <span class="bold-900 sub-title-section3 icon-text" style="display: flex; align-items: center"
              [ngStyle]="{ color: themeColor }">Read More<i
                class="material-symbols-outlined sub-title-section2 bold-500">arrow_forward</i></span>
          </div>
        </div>
      </div>
    </div>

    <div class="row col s12 m12 hide-on-med-and-up p-t-3">
      <p class="seeAll-displayName teal-text center">
        <a (click)="navigate()" [routerLink]="['/about-us']" [ngStyle]="{
            color: aboutUsSection.displayNameColor,
            text_decoration: 'underline'
          }"><i>See All {{ aboutUsSection.displayName }} >> </i></a>
      </p>
    </div>


  </div>
</div>