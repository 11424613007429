<div class="row p-m-2 p-t-7">
  <div class="col s12 m10 l8 push-l2 ">
    <div class="card p-t-1 b-s-1">
      <div class="card-content">
        <span class="card-title center">
          <h3 class="bold-600 title-section2 ">Forgot Password?</h3>
        </span>

        <form class="container" (ngSubmit)="resetPassword()" [formGroup]="resetPasswordForm">
          <div class="row">
            <div class="row ">
              <h5 class="center sub-title-section">
                Please enter the email address you used when you joined and we'll send you instructions to reset your
                password. </h5>
              <div class="input-field col s11">
                <div class="label-input ">Email</div>
                <input id="email" type="email" required formControlName='email' class="validate" placeholder="Email" >
                <div *ngIf="(email.dirty || email.touched) && email.invalid">
                  <small *ngIf="email.errors.required" class="red-text"> Email is required. </small>
                  <small *ngIf="email.errors.email" class="red-text"> wrong email format. </small>
                </div>
              </div>
            </div>
            <div class="row center-align">
              <div class="input-field col s11 center">
                <button type='submit'  name='btn_login'
                  class='col s12 btn  waves-effect  rectangle-button' [ngStyle]="{'background-color': themeColor}" >Reset Password</button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>