
import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { toast } from 'materialize-css';
import { ISETSRequestToJoinModel } from 'src/app/models/isets/ISETSRequestToJoinModel';
import { IsetsRequesttojoinService } from 'src/app/services/firebase/isets/IsetsRequesttojoin.service';
import { ISETSEmailService } from 'src/app/services/api/isets/ISETSEmail.service';
import { IsetsNationService } from 'src/app/services/api/isets/isets-nation.service';
import { BandModel } from 'src/app/models/isets/BandModel';
import { IsetsBandsService } from 'src/app/services/api/isets/isets-bands.service';
import { ISETSClientService } from 'src/app/services/api/isets/ISETSClient.Service'; //small case ISETSClient.service
import { FindClientModel } from 'src/app/models/isets/FindClientModel';
import { BandClientModel } from 'src/app/models/isets/BandClientModel';
import { Router } from '@angular/router';
import SignaturePad from 'signature_pad';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-request-application',
  templateUrl: './request-application.component.html',
  styleUrls: ['./request-application.component.css']
})

export class RequestApplicationComponent implements OnInit {

  themeColor = environment.appTheme.adminThemeColor;
  fontColor = environment.appTheme.adminFontColor;

  requestTojoinForm: UntypedFormGroup;
  step0 = false;
  step1 = true;
  step2 = false;
  step3 = false;
  step4 = false;
  step5 = true;
  step6 = false;
  step7 = false;

  nation: any = {};
  bands: BandModel[];
  provinces: any = ['Alberta', 'British Columbia', 'Manitoba', 'New Brunswick', 'Newfoundland and Labrador', 'Nova Scotia', 'Ontario', 'Prince Edward Island', 'Quebec', 'Saskatchewan'];
  provinces2: any = ['Alberta', 'British Columbia', 'Manitoba', 'New Brunswick', 'Newfoundland and Labrador', 'Nova Scotia', 'Ontario', 'Prince Edward Island', 'Quebec', 'Saskatchewan'];
  provinces3: any = ['Alberta', 'British Columbia', 'Manitoba', 'New Brunswick', 'Newfoundland and Labrador', 'Nova Scotia', 'Ontario', 'Prince Edward Island', 'Quebec', 'Saskatchewan'];

  public postalCodeMask = [/[Tt]/, /[0-9]/, /[A-Za-z]/, ' ', /[0-9]/, /[A-Za-z]/, /[0-9]/];
  public phoneNumberMask = ['(', /[0-9]/, /[0-9]/, /[0-9]/, ')', ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];

  default_province = environment.firstNation.province;
  default_city: string = "";
  default_address: string = "";

  default_citizenship: string = "Canada";
  default_language: string = "English";

  clientModel: FindClientModel;
  newclientModel: BandClientModel;
  RequestLists: ISETSRequestToJoinModel[];

  default_mailing_address: string = "";
  default_mailing_city: string = "";
  default_mailing_province = environment.firstNation.province;
  default_mailing_postcode: string = "T";

  default_classno: string = "";
  default_expiry_date: Date;
  default_license_province = environment.firstNation.province;
  band_name: string = "";

  showLicenseInfo = true;
  showMailingAddress = false;
  public treatyNoMask = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  public sinMask = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];

  canvas: any;
  signaturePad: SignaturePad;
  isAgreed = false;

  constructor(
    private fb: UntypedFormBuilder,
    private requesttojoinService: IsetsRequesttojoinService,
    private nationService: IsetsNationService,
    private bandsService: IsetsBandsService,
    private clientService: ISETSClientService,
    private emailService: ISETSEmailService,
    private router: Router) {
  }

  SearchForBand() {
    this.band_name = '';
    var str = "" + this.requestTojoinForm.value.treatyno + "";
    str = str.replace('_', '');
    if (str.length > 0 && str.length < 10) {
      toast({ html: 'Please Enter Treaty Number Competely !', classes: 'red' });
      return;
    }

    var band_id = str.substring(0, 3)
    this.bandsService.getBandByID(Number(band_id)).subscribe(x => {
      if (x) {
        this.band_name = x[0].band;
      }
    })
  }

  ngOnInit() {
    this.nationService.getNationInfo().subscribe(x => {
      this.nation = x[0];
      this.default_city = this.nation.City;

      this.requesttojoinService.getRegistrationRequestList().valueChanges().subscribe(x => {
        if (x.length > 0) { this.RequestLists = x }
      });
    })

    this.requestTojoinForm = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      middle_initial: [''],
      dob: ['', [Validators.required]],
      gender: ['', [Validators.required]],
      sin: [''],
      address: ['', [Validators.required]],

      city: ['', [Validators.required]],

      province: ['', [Validators.required]],
      postcode: ['T', [Validators.required]],
      same_address: ['yes', [Validators.required]],
      mailing_address: [''],
      mailing_city: [''],
      mailing_province: [''],
      mailing_postcode: ['T'],

      homephone: ['', [Validators.required]],
      cellphone: [''],
      messages: [''],
      email: ['', [Validators.required, Validators.email]],
      have_license: ['yes', [Validators.required]],
      classno: [''],
      license_expiry_date: [''],
      license_province: [''],
      citizenship: ['Canada'],
      language: ['English'],

      status: ['', [Validators.required]],
      bandname: [''],
      treatyno: [''],
      signature: [''],
      sign_date: [''],
      agree: ['', [Validators.required]],
      message: [''],
      id_photo: ['']  // id_photo: ['no']
    });

    setTimeout(() => {
      $('select').formSelect();
      $('.datepicker1').datepicker({
        format: 'dd/mmm/yyyy',
        defaultDate: new Date(new Date().getFullYear() - 18, 0, 1),
        setDefaultDate: new Date(new Date().getFullYear() - 18, 0, 1),
        yearRange: 62,
        minDate: new Date(new Date().getFullYear() - 80, 0, 1),
        maxDate: new Date(new Date().getFullYear(), 12, 31),
        onSelect(e) {
          localStorage.setItem('selected_dob', e);
        },
      });
    }, 25);

    setTimeout(() => {
      M.updateTextFields();
    }, 25);
  }

  onChangesOfAddress() {
    this.requestTojoinForm.get('same_address').valueChanges.subscribe(selected_value => {
      if (selected_value != 'yes') {
        this.default_mailing_postcode = "T";
      }
      else {
        this.requestTojoinForm.get('mailing_address').reset();
        this.requestTojoinForm.get('mailing_city').reset();
        this.requestTojoinForm.get('mailing_province').reset('');
        this.requestTojoinForm.get('mailing_postcode').reset('');
      }
    });
  }

  onChangesOfLicence() {
    this.requestTojoinForm.get('have_license').valueChanges.subscribe(selected_value => {
      if (selected_value != 'yes') {
        this.requestTojoinForm.get('classno').reset();
        this.requestTojoinForm.get('license_province').setValue('')
      }
    });
  }

  get firstName() { return this.requestTojoinForm.get('firstName'); }
  get lastName() { return this.requestTojoinForm.get('lastName'); }
  get gender() { return this.requestTojoinForm.get('gender'); }
  get middle_initial() { return this.requestTojoinForm.get('middle_initial '); }
  get sin() { return this.requestTojoinForm.get('sin'); }
  get dob() { return this.requestTojoinForm.get('dob'); }
  get address() { return this.requestTojoinForm.get('address'); }
  get city() { return this.requestTojoinForm.get('city'); }
  get province() { return this.requestTojoinForm.get('province'); }
  get postcode() { return this.requestTojoinForm.get('postcode'); }
  get same_address() { return this.requestTojoinForm.get('same_address'); }
  get mailing_address() { return this.requestTojoinForm.get('mailing_address'); }
  get mailing_city() { return this.requestTojoinForm.get('mailing_city'); }
  get mailing_province() { return this.requestTojoinForm.get('mailing_province'); }
  get mailing_postcode() { return this.requestTojoinForm.get('mailing_postcode'); }

  get homephone() { return this.requestTojoinForm.get('homephone'); }
  get cellphone() { return this.requestTojoinForm.get('cellphone'); }
  get messages() { return this.requestTojoinForm.get('messages'); }
  get email() { return this.requestTojoinForm.get('email'); }
  get have_license() { return this.requestTojoinForm.get('have_license'); }
  get classno() { return this.requestTojoinForm.get('classno'); }
  get license_expiry_date() { return this.requestTojoinForm.get('license_expiry_date'); }
  get license_province() { return this.requestTojoinForm.get('license_province'); }
  get citizenship() { return this.requestTojoinForm.get('citizenship'); }
  get language() { return this.requestTojoinForm.get('language'); }

  get status() { return this.requestTojoinForm.get('status'); }
  get bandname() { return this.requestTojoinForm.get('bandname'); }
  get treatyno() { return this.requestTojoinForm.get('treatyno'); }
  get signature() { return this.requestTojoinForm.get('signature'); }
  get sign_date() { return this.requestTojoinForm.get('sign_date'); }
  get id_photo() { return this.requestTojoinForm.get('id_photo'); }

  //only work for Level 2 selected. 
  approveAndSign() {
    var phone = this.requestTojoinForm.value.homephone;
    phone = phone.replace('(', '');
    phone = phone.replace(')', '');
    phone = phone.replace(' ', '');
    phone = phone.replace('-', '');
    phone = phone.replace('  ', '');
    phone = phone.replace('_', '');

    var cell = this.requestTojoinForm.value.cellphone;
    cell = cell.replace('(', '');
    cell = cell.replace(')', '');
    cell = cell.replace(' ', '');
    cell = cell.replace('-', '');
    cell = cell.replace('  ', '');
    cell = cell.replace('_', '');

    var messages = this.requestTojoinForm.value.messages;
    messages = messages.replace('(', '');
    messages = messages.replace(')', '');
    messages = messages.replace(' ', '');
    messages = messages.replace('-', '');
    messages = messages.replace('  ', '');
    messages = messages.replace('_', '');

    if (phone.length != 10) {
      toast({ html: 'Please Enter Home Phone Completely !', classes: 'red' });
      this.step2 = true;
      return;
    }

    if (cell.length > 0 && cell.length != 10) {
      toast({ html: 'Please Enter Cell Phone Completely !', classes: 'red' });
      this.step2 = true;
      return;
    }

    if (messages.length > 0 && messages.length < 10) {
      toast({ html: 'Please Enter Messages Completely !', classes: 'red' });
      this.step2 = true;
      return;
    }

    this.step2 = false;
    this.step3 = true;

    setTimeout(() => {
      this.canvas = document.querySelector('canvas');
      const parentWidth = $(this.canvas).parent().outerWidth();
      this.canvas.setAttribute('width', parentWidth - 50);
      this.signaturePad = new SignaturePad(this.canvas, {
        backgroundColor: 'rgb(255,255,255)'
      });
    }, 50);
  }

  clearSignature() {
    this.signaturePad.clear();
  }

  back1() {
    this.step1 = false;
    this.step2 = true;
    this.step3 = false;

    setTimeout(() => {
      $('select').formSelect();

      //for license expiry date
      $('.datepicker2').datepicker({
        format: 'dd/mmm/yyyy',
        //defaultDate: new Date(),
        //setDefaultDate: new Date(),
        yearRange: 5,
        minDate: new Date(new Date().getFullYear() - 5, 0, 1),
        maxDate: new Date(new Date().getFullYear() + 5, 12, 31),
        onSelect(e) {
          localStorage.setItem('selected_expiry_date', e);
        },
      });

      M.updateTextFields();
    }, 25);
  }

  goAhead() {
    this.step0 = false;
    this.step1 = true;
    this.step2 = false;
    this.step3 = false;

    setTimeout(() => {
      $('select').formSelect();

      //for DOB date
      $('.datepicker1').datepicker({
        format: 'dd/mmm/yyyy',
        defaultDate: new Date(new Date().getFullYear() - 18, 0, 1),
        setDefaultDate: new Date(new Date().getFullYear() - 18, 0, 1),

        //yearRange: 22, //62,
        //minDate: new Date(new Date().getFullYear() - 50, 0, 1),   
        //maxDate: new Date(new Date().getFullYear(), 12, 31),

        yearRange: 57,
        minDate: new Date(new Date().getFullYear() - 75, 0, 1),
        // minDate: new Date(new Date().getFullYear() , 0, 1),
        maxDate: new Date(new Date().getFullYear(), 12, 31),

        onSelect(e) {
          localStorage.setItem('selected_dob', e);
        },
      });
      M.updateTextFields();
    }, 25);
  }

  showMore() {
    this.step4 = false;
    this.step5 = true;
  }

  HideContent() {
    this.step4 = true;
    this.step5 = false;
  }

  download(dataURL, filename) {
    var blob = this.dataURLToBlob(dataURL);
    var url = window.URL.createObjectURL(blob);
    var a = document.createElement("a");
    a.href = url;
    a.download = filename;

    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  }

  dataURLToBlob(dataURL) {
    var parts = dataURL.split(';base64,');
    var contentType = parts[0].split(":")[1];
    var raw = window.atob(parts[1]);
    var rawLength = raw.length;
    var uInt8Array = new Uint8Array(rawLength);
    for (var i = 0; i < rawLength; ++i) {
      uInt8Array[i] = raw.charCodeAt(i);
    }
    return new Blob([uInt8Array], { type: contentType });
  }

  save_png_image() {
    if (this.signaturePad.isEmpty()) {
      alert("Please provide a signature first.");
    } else {
      var dataURL = this.signaturePad.toDataURL();
      this.download(dataURL, "signature.png");
    }
  }

  SubmitRequest(model: ISETSRequestToJoinModel) {
    if (this.signaturePad.isEmpty()) {
      toast({ html: 'Please Signe The Application !', classes: 'red' });
      return;
    }
    model.signature = this.signaturePad.toDataURL().replace('data:image/png;base64,', '');
    model.sign_date = new Date();
    model.mailing_address = this.default_mailing_address;
    model.mailing_city = this.default_mailing_city;
    model.mailing_province = this.default_mailing_province;
    model.mailing_postcode = this.default_mailing_postcode;
    if (model.same_address === 'yes') {
      model.mailing_address = "";
      model.mailing_city = "";
      model.mailing_province = "";
      model.mailing_postcode = "";
    }
    model.classno = this.default_classno;
    model.license_province = this.default_license_province;
    if (model.have_license === 'no') {
      model.classno = "";
      model.license_province = "";
      model.license_expiry_date = null;

    }
    model.bandname = this.band_name;
    model.firstName = this.requestTojoinForm.value.firstName.trim();
    model.lastName = this.requestTojoinForm.value.lastName.trim();

    let result = [];
    if (this.RequestLists) {
      result = this.RequestLists.filter(data => (!data.archived) && data.firstName == model.firstName && data.lastName == model.lastName && data.dob == model.dob);
    }

    if (result.length > 0) {
      toast({ html: '<p>The information you entered matches what we have on file, Please Conatct your Employment Councillor or Call us at 780 444 0911.Thank you</p> ', classes: 'red' });
      return;
    } else {

      if (this.requestTojoinForm.value.firstName != '' || this.requestTojoinForm.value.lastName != '') {

        this.clientModel = new FindClientModel();
        this.clientModel.firstName = this.requestTojoinForm.value.firstName.trim();
        this.clientModel.lastName = this.requestTojoinForm.value.lastName.trim();
        this.clientModel.dob = this.requestTojoinForm.value.dob;

        this.clientService.findClientByName(this.clientModel).subscribe(x => {
          if (x) {
            toast({ html: '<p>The information you entered matches what we have on file, Please Register as Existing Client.Thank you!</p> ', classes: 'red' });
            return;

          } else {

            this.clientService.findApplicantByName(this.clientModel).subscribe(data => {
              if (data) {
                toast({ html: '<p>The information you entered matches what we have on file and no need to apply again.Thank you!</p> ', classes: 'red' });
                return;
              } else {

                this.requesttojoinService.newRegistrationRequest(model).then(y => {

                  this.save_client_into_database()

                }).catch(error => {
                  toast({ html: error.message, classes: 'red' });
                });
              }
            });
          }
        });
      }
    }
  }

  save_client_into_database() {
    this.newclientModel = new BandClientModel();
    this.newclientModel.givenname = this.requestTojoinForm.value.firstName.trim();
    this.newclientModel.lastname = this.requestTojoinForm.value.lastName.trim();
    this.newclientModel.middle_initial = this.requestTojoinForm.value.middle_initial;
    this.newclientModel.dob = this.requestTojoinForm.value.dob;
    if (this.requestTojoinForm.value.gender === 'Male') {
      this.newclientModel.gender = 1;
    } else if (this.requestTojoinForm.value.gender === 'Female') {
      this.newclientModel.gender = 2;
    } else {
      this.newclientModel.gender = 3;
    }
    this.newclientModel.address = this.requestTojoinForm.value.address;
    this.newclientModel.city = this.requestTojoinForm.value.city;


    if (this.requestTojoinForm.value.province === 'Newfoundland and Labrador') {
      this.newclientModel.province = 1;
    } else if (this.requestTojoinForm.value.province === 'Nova Scotia') {
      this.newclientModel.province = 2;
    } else if (this.requestTojoinForm.value.province === 'New Brunswick') {
      this.newclientModel.province = 3;
    } else if (this.requestTojoinForm.value.province === 'Prince Edward Island') {
      this.newclientModel.province = 4;
    } else if (this.requestTojoinForm.value.province === 'Quebec') {
      this.newclientModel.province = 5;
    } else if (this.requestTojoinForm.value.province === 'Ontario') {
      this.newclientModel.province = 6;
    } else if (this.requestTojoinForm.value.province === 'Manitoba') {
      this.newclientModel.province = 7;
    } else if (this.requestTojoinForm.value.province === 'Saskatchewan') {
      this.newclientModel.province = 8;
    } else if (this.requestTojoinForm.value.province === 'Alberta') {
      this.newclientModel.province = 9;
    } else if (this.requestTojoinForm.value.province === 'Northwest Territories') {
      this.newclientModel.province = 10;
    } else if (this.requestTojoinForm.value.province === 'British Columbia') {
      this.newclientModel.province = 11;
    } else if (this.requestTojoinForm.value.province === 'Yukon') {
      this.newclientModel.province = 12;
    } else if (this.requestTojoinForm.value.province === 'Nunavut') {
      this.newclientModel.province = 16;
    } else { this.newclientModel.province = 0; }

    this.newclientModel.postalcode = this.requestTojoinForm.value.postcode.replace(' ', '');
    this.newclientModel.SIN = this.requestTojoinForm.value.sin;

    var phone = this.requestTojoinForm.value.homephone;
    phone = phone.replace('(', '');
    phone = phone.replace(')', '');
    phone = phone.replace(' ', '');
    phone = phone.replace('-', '');
    phone = phone.replace('  ', '');
    this.newclientModel.Phone = phone;
    //this.newclientModel.Phone= this.requestTojoinForm.value.homephone.replace(/<[^>]*>/g, '');

    this.newclientModel.email = this.requestTojoinForm.value.email;
    this.newclientModel.band_name = this.requestTojoinForm.value.bandname;
    this.newclientModel.band_id = this.requestTojoinForm.value.treatyno;
    this.newclientModel.cell_phone = this.requestTojoinForm.value.cellphone;

    var cell = this.requestTojoinForm.value.cellphone;
    cell = cell.replace('(', '');
    cell = cell.replace(')', '');
    cell = cell.replace(' ', '');
    cell = cell.replace('-', '');
    cell = cell.replace('  ', '');
    this.newclientModel.cell_phone = cell;

    if (this.requestTojoinForm.value.same_address === 'yes') {
      this.newclientModel.same_address = true;
    } else if (this.requestTojoinForm.value.same_address === 'no') {
      this.newclientModel.same_address = false;
    }

    this.newclientModel.mailing_address = this.default_mailing_address;
    this.newclientModel.mailing_city = this.default_mailing_city;
    this.newclientModel.mailing_province = this.default_mailing_province;
    this.newclientModel.mailing_postcode = this.default_mailing_postcode.replace(' ', '');
    if (this.requestTojoinForm.value.same_address === 'yes') {
      this.newclientModel.mailing_address = "";
      this.newclientModel.mailing_city = "";
      this.newclientModel.mailing_province = "";
      this.newclientModel.mailing_postcode = "";
    }

    var messages = this.requestTojoinForm.value.messages;
    messages = messages.replace('(', '');
    messages = messages.replace(')', '');
    messages = messages.replace(' ', '');
    messages = messages.replace('-', '');
    messages = messages.replace('  ', '');
    this.newclientModel.messages = messages;

    if (this.requestTojoinForm.value.have_license === 'yes') {
      this.newclientModel.have_license = true;
    } else if (this.requestTojoinForm.value.have_license === 'no') {
      this.newclientModel.have_license = false;
    }

    this.newclientModel.classno = this.default_classno;
    this.newclientModel.license_province = this.default_license_province;
    this.newclientModel.license_expiry_date = this.requestTojoinForm.value.license_expiry_date;
    if (this.requestTojoinForm.value.have_license === 'no') {
      this.newclientModel.classno = "";
      this.newclientModel.license_province = "";
      this.newclientModel.license_expiry_date = null;
    }

    this.newclientModel.citizenship = this.requestTojoinForm.value.citizenship;
    this.newclientModel.language = this.requestTojoinForm.value.language;

    if (this.requestTojoinForm.value.status === 'Treaty Status') {
      this.newclientModel.status = 1;
    } else if (this.requestTojoinForm.value.status === 'Non-Status') {
      this.newclientModel.status = 2;
    } else if (this.requestTojoinForm.value.status === 'Inuit') {
      this.newclientModel.status = 4;
    }

    this.newclientModel.signature = "";
    this.newclientModel.sign_date = null;
    //if (this.isLevel2Selected) {
    //this.newclientModel.signature = this.signaturePad.toDataURL().replace('data:image/png;base64,', '');
    //this.newclientModel.sign_date = new Date();
    // }

    this.newclientModel.comments = this.requestTojoinForm.value.message;

    this.clientService.saveClientIfNotExist(this.newclientModel).subscribe(data => {
      if (data) {
        // localStorage.setItem('clientID', data);
        this.emailService.sendEmailToApplicantNew(this.newclientModel.email, this.newclientModel.givenname, this.newclientModel.lastname, this.requestTojoinForm.value.message, this.requestTojoinForm.value.homephone);

        setTimeout(() => {
          $('select').formSelect();
          M.updateTextFields();
        }, 25);

        this.step1 = false;
        this.step2 = false;
        this.step3 = false;
        this.step7 = true;        // skip step6 uploading documents.......
      }
    });
  }

  next() {
    this.step1 = false;
    this.step2 = true;
    this.step3 = false;
    this.step6 = false;

    setTimeout(() => {
      $('select').formSelect(); // dirver license province issued

      //for license expiry date
      $('.datepicker2').datepicker({
        format: 'dd/mmm/yyyy',
        defaultDate: new Date(),
        setDefaultDate: new Date(),
        yearRange: 5,
        minDate: new Date(new Date().getFullYear() - 5, 0, 1),
        maxDate: new Date(new Date().getFullYear() + 5, 12, 31),
        onSelect(e) {
          localStorage.setItem('selected_expiry_date', e);
        },
      });
      M.updateTextFields();
    }, 25);

    if (this.requestTojoinForm.value.lastName === '') {
      this.step0 = false;
      this.step1 = true;
      this.step2 = false;
      toast({ html: 'Please Enter Last Name', classes: 'red' });
      return;
    }

    if (this.requestTojoinForm.value.firstName === '') {
      this.step0 = false;
      this.step1 = true;
      this.step2 = false;
      toast({ html: 'Please Enter First Name', classes: 'red' });
      return;
    }

    if (this.requestTojoinForm.value.gender === '') {
      this.step0 = false;
      this.step1 = true;
      this.step2 = false;
      toast({ html: 'Please Select Gender', classes: 'red' });
      return;
    }

    /*
    if (this.isLevel2Selected && (this.requestTojoinForm.value.sin === '')){
      this.step0 = false;
      this.step1 = true;
      this.step2 = false;
      toast({ html: 'Please Enter Social Insurance Number', classes: 'red' });
      return;
    }
    */

    if (this.requestTojoinForm.value.address === '') {
      this.step0 = false;
      this.step1 = true;
      this.step2 = false;
      toast({ html: 'Please Enter Home Address', classes: 'red' });
      return;
    }

    if (this.requestTojoinForm.value.city === '') {
      this.step0 = false;
      this.step1 = true;
      this.step2 = false;
      toast({ html: 'Please Enter City', classes: 'red' });
      return;
    }

    if (this.requestTojoinForm.value.province === '') {
      this.step0 = false;
      this.step1 = true;
      this.step2 = false;
      toast({ html: 'Please Select Province', classes: 'red' });
      return;
    }

    if (this.requestTojoinForm.value.postcode === '') {
      this.step0 = false;
      this.step1 = true;
      this.step2 = false;
      toast({ html: 'Please Enter Postal Code', classes: 'red' });
      return;
    }
  }

  back() {
    this.step0 = false;
    this.step1 = true;
    this.step2 = false;
    this.step3 = false;
    this.step6 = false;

    setTimeout(() => {
      $('select').formSelect();

      //for DOB date
      $('.datepicker1').datepicker({
        format: 'dd/mmm/yyyy',
        //defaultDate: new Date(),
        // setDefaultDate: new Date(),

        yearRange: 57, //22, //62,
        //yearRange: '1946:+0',
        //minDate: new Date(new Date().getFullYear() - 50, 0, 1), 
        minDate: new Date(new Date().getFullYear() - 75, 0, 1),

        //yearRange: 62, 
        //minDate: new Date(new Date().getFullYear() - 80, 0, 1),  
        maxDate: new Date(new Date().getFullYear(), 12, 31),

        onSelect(e) {
          localStorage.setItem('selected_dob', e);
        },
      });

      M.updateTextFields();
    }, 25);
  }

  backClicked() {
    this.router.navigate(['/member/member-home-sub-Menu']);
  }

  BirthDateChange(evt) {
    if (this.requestTojoinForm) {
      this.requestTojoinForm.patchValue({ dob: $('#dob').val() });
    }

    setTimeout(() => {
      $('.datepicker1').datepicker({
        format: 'dd/mmm/yyyy',
        defaultDate: new Date(new Date().getFullYear() - 18, 0, 1),
        yearRange: 62,
        minDate: new Date(new Date().getFullYear() - 80, 0, 1),
        maxDate: new Date(new Date().getFullYear(), 12, 31),
        onSelect(e) {
          localStorage.setItem('selected_dob', e);
        },
      });
      $('select').formSelect();
    }, 25);
  }

  LicenseDateChange(evt) {
    if (this.requestTojoinForm) {
      this.requestTojoinForm.patchValue({ license_expiry_date: $('#license_expiry_date').val() });
    }

    setTimeout(() => {
      $('.datepicker2').datepicker({
        format: 'dd/mmm/yyyy',
        defaultDate: new Date(),
        //setDefaultDate: new Date(),
        yearRange: 5,
        minDate: new Date(new Date().getFullYear() - 5, 0, 1),
        maxDate: new Date(new Date().getFullYear() + 5, 12, 31),
        onSelect(e) {
          localStorage.setItem('selected_expiry_date', e);
        },
      });
      $('select').formSelect();
    }, 25);

  }

  onDriverLicenseYes(evt) {
    this.showLicenseInfo = true;
    this.onChangesOfLicence()

    setTimeout(() => {
      $('select').formSelect();

      $('.datepicker2').datepicker({
        format: 'dd/mmm/yyyy',
        //defaultDate: new Date(),
        // setDefaultDate: new Date(),
        yearRange: 5,
        minDate: new Date(new Date().getFullYear() - 5, 0, 1),
        maxDate: new Date(new Date().getFullYear() + 5, 12, 31),
        onSelect(e) {
          localStorage.setItem('selected_expiry_date', e);
        },
      });
      M.updateTextFields();
    }, 25);
  }

  onDriverLicenseNo(evt) {
    this.showLicenseInfo = false;
    this.onChangesOfLicence()
  }

  onMailingAddressYes(evt) {
    this.showMailingAddress = false;
    this.onChangesOfAddress()
  }

  onMailingAddressNo(evt) {
    this.showMailingAddress = true;
    this.onChangesOfAddress()

    setTimeout(() => {
      $('select').formSelect();
    }, 25);
  }

  onAgreeYes(evt) {
    this.isAgreed = true;
  }

  onAgreeNo(evt) {
    this.isAgreed = false;
  }

  onUploadPhotoYes(evt) {
    this.router.navigate(['/submit-document']);
  }

  onUploadPhotoNo(evt) {
    this.step6 = false;
    this.step7 = true;
  }

  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
}