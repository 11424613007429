import { Component, OnInit } from '@angular/core';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { DepartmentService } from 'src/app/services/firebase/department.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-department-list',
  templateUrl: './department-list.component.html',
  styleUrls: ['./department-list.component.css']
})
export class DepartmentListComponent implements OnInit {
 
  defaultThemeColor = environment.appTheme.themeColor;
  model: any;
  themeColor: any;
  departmentSection: any;
  departments: any[];

  constructor(
    private appSettingService: AppSettingsService,
    private departmentService: DepartmentService,
   ) { 
    this.themeColor = this.appSettingService.getAppThemeColor() ? this.appSettingService.getAppThemeColor() : this.defaultThemeColor;
    this.appSettingService.getAppSetting().valueChanges().subscribe(
      appSettings => {
        if (appSettings) {
          if (appSettings.length > 0) {
            this.model = appSettings[0];
            this.themeColor = this.model.themeColor ? this.model.themeColor : this.defaultThemeColor;
          }
        }
      });
   }

  ngOnInit(): void {
    this.appSettingService.getHomePageSettings().valueChanges().subscribe(hp => {
      if (hp) {
        if (hp.length > 0) {
          this.model = hp[0];
          const departmentsSection = this.model.sections.filter(s => s.name === 'Departments' && s.isEnabled);
          if (departmentsSection) {
            this.departmentSection = departmentsSection[0];
            this.departmentService.getLimitedContentList(parseInt(departmentsSection[0].displayLimit)).valueChanges().subscribe(n => {
              if (n) {
                this.departments = n;
                this.departments = this.departments.sort((a, b) => (this.parseHTML(a.name) < this.parseHTML(b.name) ? -1 : 1));
              }
            });
          }
        }
      }
    });   
  }

  parseHTML(html) {
    return html.replace(/<(?:.|\n)*?>/gm, '').replace(/&nbsp;/gm, ' ');
  }

  navigate(){
    this.appSettingService.setCurrentMenu(this.departmentSection.displayName);
  }

}
