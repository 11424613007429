
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiKeyManagerService } from './apiKeyManager.Service';
import { SkillModel } from 'src/app/models/SkillModel';



@Injectable({
    providedIn: 'root'
  })
  export class SkillService {
  
    constructor(private httpClient: HttpClient, private keyManager: ApiKeyManagerService) { }
  
    url = environment.firstNation.apiUrl + 'api/Skill/';
  
    saveSkill(model: SkillModel): Observable<any> {
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
      return this.httpClient.post<SkillModel>(this.url + 'SaveSkill/', JSON.stringify(model), { headers });
    }
  
    updateSkill(model: SkillModel): Observable<any> {
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
      return this.httpClient.post<SkillModel>(this.url + 'EditSkill/', JSON.stringify(model), { headers });
    }
  
  
    deleteSkill(model: SkillModel): Observable<any> {
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
      return this.httpClient.post<SkillModel>(this.url + 'DeleteSkill/', JSON.stringify(model), { headers });
    }
  
  
    getSkillById(id: number): Observable<SkillModel[]> {
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
      return this.httpClient.get<SkillModel[]>(this.url + 'GetSkillByID/' + id, { headers });
    }
  

    getSkillByMemberId(id: number): Observable<SkillModel[]> {
        const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
        return this.httpClient.get<SkillModel[]>(this.url + 'GetSkillByMemberId/' + id, { headers });
      }
  
 
  }