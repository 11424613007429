import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { ContactService } from 'src/app/services/firebase/contact.service';

@Component({
  selector: 'app-user-inbox',
  templateUrl: './user-inbox.component.html',
  styleUrls: ['./user-inbox.component.css']
})

export class UserInboxComponent implements OnInit {

  messageList: any;
  @Input() userType: any;

  constructor( private contactService: ContactService,
    private authService: AuthService,
    private router: Router) { }

  ngOnInit() {
    this.contactService.getUserMessageList(this.authService.currentUser.uid).valueChanges().subscribe(
      msgList => {
        if (msgList.length > 0) {
          this.messageList = msgList;
          this.messageList.sort((a, b) => {
            return (b.dateSent - a.dateSent);
          });
        }
      }
    );
  }

  openDiscussion(discussionID: any, event: Event, messageID: any) {
    event.preventDefault();
    localStorage.setItem('msgID', messageID);
    if (this.userType === 'Employee') {
      this.router.navigate(['/employee/discussion', discussionID]);
    }
    if (this.userType === 'Member') {
      this.router.navigate(['/member/discussion', discussionID]);
    }
  }
  
}