import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})

export class FbNationSettingsService {
  settingtRef: AngularFirestoreCollection<any>;
  constructor(private db: AngularFirestore) { }

  getsettings(): AngularFirestoreCollection<any> {
    this.settingtRef = this.db.collection('NationSettings');
    return this.settingtRef;
  }

  saveSettings(model: any) {
    model.id = this.db.createId();
    return this.db.collection('NationSettings').doc(model.id).set(model);
  }

  updateSettings(model: any) {
    return this.db.collection('NationSettings').doc(model.id).update(model);
  }
}
 