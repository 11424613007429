<div class="">
    <router-outlet></router-outlet>
</div>

<div class="container3" *ngIf="false">

    <h4 class="title-section1  title-member">About Me</h4>
    <div class="row card-panel b-n-1 ">
        <div class="col l12 s12">
            <ul class="tabs">
                <!-- [ngClass]="{'col s3': nationName == 'LRRCN', 'col s4': nationName != 'LRRCN' }" -->
                <li class="tab" [ngClass]="nationName == 'LRRCN' ? 'col s2' : 'col s3'"><a href="#test1">My Info</a>
                </li>
                <li class="tab" [ngClass]="nationName == 'LRRCN' ? 'col s2' : 'col s3'"><a href="#test2">My Family</a>
                </li>
                <li class="tab" [ngClass]="nationName == 'LRRCN' ? 'col s2' : 'col s3'"><a href="#test3">My Settings</a>
                </li>
                <li class="tab" [ngClass]="nationName == 'LRRCN' ? 'col s2' : 'col s3'"><a href="#test5">Service</a>
                </li>
                <li class="tab col s2" *ngIf="nationName == 'LRRCN'"><a href="#test4">IA Survey</a></li>
            </ul>
        </div>
        <div id="test1" class="col s12">
            <app-personal-information></app-personal-information>
        </div>
        <div id="test2" class="col s12">
            <app-family-composition></app-family-composition>
        </div>
        <div id="test3" class="col s12">
            <app-settings></app-settings>
        </div>
        <div id="test4" class="col s12" *ngIf="nationName == 'LRRCN'">
            <app-lrrcn-application-list></app-lrrcn-application-list>
        </div>
        <div id="test5" class="col s12">
            <router-outlet></router-outlet>
        </div>
    </div>

</div>