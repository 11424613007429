import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
// import 'rxjs/add/operator/take';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AreaOfInterestService {

  collectionRef: AngularFirestoreCollection<any>;
  documentRef: AngularFirestoreDocument<any>;
  
  constructor(private db: AngularFirestore) { }

  saveArea(model: any) {
    model.id = this.db.createId();
    return this.db.collection('AreasOfInterest').doc(model.id).set(model);
  }

  updateArea(model: any)  {
    return this.db.collection('AreasOfInterest').doc(model.id).update(model);
  }

  incrementMembersCount(id: any) {
    this.documentRef = this.db.doc('AreasOfInterest/' + id);
    const item = this.documentRef.valueChanges().pipe(take(1));
    item.subscribe(i => {
      if(i) {
        i.membersCount += 1
        this.updateArea(i);
      }
    })
  }

  decrementMembersCount(id: any) {
    this.documentRef = this.db.doc('AreasOfInterest/' + id);
    const item = this.documentRef.valueChanges().pipe(take(1));
    item.subscribe(i => {
      if(i) {
        if(i.membersCount > 0) {
          i.membersCount -= 1
          this.updateArea(i);
        }        
      }
    })
  }

  deleteArea(model: any)  {
    return this.db.collection('AreasOfInterest').doc(model.id).update(model);
  }

  getAreasList(): AngularFirestoreCollection<any[]> {
    this.collectionRef = this.db.collection('AreasOfInterest');
    return this.collectionRef;
  }

  // ------------------------ Member Areas Of Interest -------------------
  saveMyAreas(model: any) {
    model.id = this.db.createId();   
    return this.db.collection('AreasOfInterest-Member').doc(model.id).set(model);
  }

  updateMyAreas(model: any)  {
    return this.db.collection('AreasOfInterest-Member').doc(model.id).update(model);
  }

  getMyAreasList(uid: any): AngularFirestoreCollection<any[]> {
    this.collectionRef = this.db.collection('AreasOfInterest-Member', ref => ref.where('uid', '==', uid));
    return this.collectionRef;
  }

}