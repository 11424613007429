import { Component, OnInit } from '@angular/core';
import { DepartmentService } from 'src/app/services/firebase/department.service';
import { environment } from 'src/environments/environment';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { Router } from '@angular/router';
import { useAnimation, trigger, transition } from '@angular/animations';
import { bounceIn } from 'ng-animate';
import { DepartmentModel } from 'src/app/models/DepartmentModel';

declare var $: any;

@Component({
  selector: 'app-departments',
  templateUrl: './departments.component.html',
  styleUrls: ['./departments.component.css'],
  animations: [
    trigger('swing', [transition('* => *', useAnimation(bounceIn))])
  ],
})
export class DepartmentsComponent implements OnInit {


  themeColor: any;
  defaultThemeColor = environment.appTheme.themeColor;
  model: any;
  depmodel: any;
  fontColor = environment.appTheme.fontColor;
  nationOfUSe = environment.firstNation.name;
  fromWhere: any;
  departmentId: any;
  sectionName: any;

  bodyBgColor: any;
  setting: any;
  defaultBgColor = '#ffffff';

  constructor(private departmentService: DepartmentService, private appSettingService: AppSettingsService, private router: Router) {
    this.themeColor = this.appSettingService.getAppThemeColor() ? this.appSettingService.getAppThemeColor() : this.defaultThemeColor;
    this.appSettingService.getAppSetting().valueChanges().subscribe(
      appSettings => {
        if (appSettings) {
          if (appSettings.length > 0) {
            this.model = appSettings[0];
            this.themeColor = this.model.themeColor ? this.model.themeColor : this.defaultThemeColor;
          }
        }
      });
    // =============================================   remove once added to app-menu =============

  }

  ngOnInit() {
    this.sectionName = this.appSettingService.getCurrentMenu();
    this.departmentService.getDepartmentList().valueChanges().subscribe(dep => {
      if (dep) {
        this.depmodel = dep;
        this.depmodel.sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1));
        // this.depmodel = this.depmodel.sort((a, b) => (this.parseHTML(a.displayOrder) < this.parseHTML(b.displayOrder) ? -1 : 1));

        // this.depmodel = this.depmodel.sort((a, b) => (this.parseHTML(a.name) < this.parseHTML(b.name) ? -1 : 1));
        setTimeout(() => {
          $('.collapsible').collapsible();
        }, 25);
      }
    });
  }

  parseHTML(html) {
    return html.replace(/<(?:.|\n)*?>/gm, '').replace(/&nbsp;/gm, ' ');
  }

  editDepartmentData(event: Event, id?: any) {
    event.preventDefault();
    this.router.navigate(['/department-sub-menu', id]);
  }
}
