import { Component, OnInit } from '@angular/core';
import { DepartmentService } from 'src/app/services/firebase/department.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-manage-sub-department',
  templateUrl: './manage-sub-department.component.html',
  styleUrls: ['./manage-sub-department.component.css']
})
export class ManageSubDepartmentComponent implements OnInit {

  departmentID: any;
  model: any;
  nationOfUSe = environment.firstNation.name;

  constructor(
    private location: Location,
    private departmentService: DepartmentService,
    private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.departmentID = params.get('id');
    });

    if (this.departmentID) {
      this.departmentService.getSubDepartmentList(this.departmentID).valueChanges().subscribe(x => {
        if (x.length > 0) {
          this.model = x;
          this.model.sort((a, b) => (a.index < b.index ? -1 : 1));
        }
      });
    }
  }

  editSubDepartmentData(event: Event, id?: any) {
    event.preventDefault();
    this.router.navigate(['/member/view-subdepartment-info', id]);
  }

  backToPrevious(event: Event) {
    this.router.navigate(['/member/manage-department', this.departmentID]);
  }
}
