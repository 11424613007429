import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { StaticContentService } from 'src/app/services/firebase/static-content.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-news-detail',
  templateUrl: './news-detail.component.html',
  styleUrls: ['./news-detail.component.css']
})

export class NewsDetailComponent implements OnInit {

  id = '';
  myModel: any;

  constructor(private route: ActivatedRoute, private staticContenet: StaticContentService,
              private location: Location) {}

  ngOnInit() {

    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });

    if (this.id) {
      this.staticContenet.getContentByID(this.id).valueChanges().subscribe(data => {
        if (data) {
          this.myModel = data;
        }
      });
    }
  }

  backClicked() {
    this.location.back();
  }

}
