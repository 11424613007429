<div class="col s12">
    <div class="body-h">

        <div class="header-setting b-m-2">
            <div class="col l12 m12 s12">
                <h4 class="">PSSSP Applications</h4>
                <p class="bold-100 sub-title-section3">This is the menu that the general public will have
                    access to on your mobile app</p>
            </div>
        </div>

        <main class="StickyContent white ">
            <app-firebase-loading *ngIf="loading"></app-firebase-loading>

            <div class="row col s12" *ngIf="!showDetails">
                <div class="row card-panel white" *ngIf='applications'>
                    <p class="center-align">Total: {{count}}</p>
                    <table class="hover striped responsive-table">
                        <thead>
                            <tr>
                                <th>Applicant ID</th>
                                <th>Given Name</th>
                                <th>last Name</th>
                                <th>Application Submitted On</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let app of applications; let i=index" style="cursor: pointer;"
                                (click)="onShowDetails(i)">
                                <td class="blue-text">{{app.applicantID}}</td>
                                <td class="blue-text">{{app.firstName}}</td>
                                <td class="blue-text">{{app.lastName}}</td>
                                <td class="blue-text">{{app.date.toDate().toDateString() | date: 'dd/MMM/yyyy'}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <a [ngClass]="'col s12 btn waves-effect ' + themeColor" (click)="backClicked()"
                    style="margin-bottom: 25px;"><i class="material-icons left">arrow_back_ios</i> Back To Previous
                    Page</a>
            </div>

            <div class="row col s12" *ngIf="showDetails">
                <ul class="collection with-header">
                    <li class="collection-header center-align">
                        <h5>PART A: STUDENT INFORMATION</h5>
                    </li>
                    <li class="collection-item">
                        <div>First Name<a class="secondary-content">{{detail.firstName}}</a></div>
                    </li>
                    <li class="collection-item">
                        <div>Last Name<a class="secondary-content">{{detail.lastName}}</a></div>
                    </li>
                    <li class="collection-item">
                        <div>Middle Name<a class="secondary-content">{{detail.middleName}}</a></div>
                    </li>
                    <li class="collection-header">
                        <p> <strong>MAILING ADDRESS</strong> </p>
                    </li>
                    <li class="collection-item">
                        <div>P.O.Box/Street<a class="secondary-content">{{detail.street}}</a></div>
                    </li>
                    <li class="collection-item">
                        <div>Apartment<a class="secondary-content">{{detail.apt}}</a></div>
                    </li>
                    <li class="collection-item">
                        <div>City<a class="secondary-content">{{detail.city}}</a></div>
                    </li>
                    <li class="collection-item">
                        <div>Province<a class="secondary-content">{{detail.province}}</a></div>
                    </li>
                    <li class="collection-item">
                        <div>Postal Code<a class="secondary-content">{{detail.postalCode}}</a></div>
                    </li>
                    <li class="collection-header">
                        <p> <strong>OTHER REQUIRED INFORMATION</strong> </p>
                    </li>
                    <li class="collection-item">
                        <div>Telephone Number<a class="secondary-content">{{detail.homeNumber}}</a></div>
                    </li>
                    <li class="collection-item">
                        <div>Cell Number<a class="secondary-content">{{detail.mobile}}</a></div>
                    </li>
                    <li class="collection-item">
                        <div>Date of Birth<a class="secondary-content">{{detail.dob.toDate().toDateString() | date:
                                'dd/MMM/yyyy'
                                }}</a></div>
                    </li>
                    <li class="collection-item">
                        <div>SIN<a class="secondary-content">{{detail.SIN}}</a></div>
                    </li>
                    <li class="collection-item">
                        <div>Student Number(s)<a class="secondary-content">{{detail.studentNo}}</a></div>
                    </li>
                    <li class="collection-item">
                        <div>Treaty Number<a class="secondary-content">{{detail.treatyNo}}</a></div>
                    </li>
                    <li class="collection-item">
                        <div>Email Address<a class="secondary-content">{{detail.email}}</a></div>
                    </li>
                    <li class="collection-item">
                        <div>Marital Status<a class="secondary-content">{{maritalStatusSelected}}</a></div>
                    </li>
                </ul>

                <ul class="collection with-header" *ngIf="detail.maritalStatus > 1">
                    <li class="collection-header center-align">
                        <h5>PART B: Spouse INFORMATION</h5>
                    </li>
                    <li class="collection-item">
                        <div>First Name<a class="secondary-content">{{detail.spouseFirstName}}</a></div>
                    </li>
                    <li class="collection-item">
                        <div>Last Name<a class="secondary-content">{{detail.spouseLastName}}</a></div>
                    </li>
                    <li class="collection-item">
                        <div>Middle Name<a class="secondary-content">{{detail.spouseMiddleName}}</a></div>
                    </li>
                    <li class="collection-item">
                        <div>Will your spouse be living with you during the time of your funding?<a
                                class="secondary-content">{{detail.spouseLive}}</a></div>
                    </li>
                    <li class="collection-item">
                        <div>Will your spouse be employed? funded student?<a
                                class="secondary-content">{{detail.spouseEmployed}}</a>
                        </div>
                    </li>
                </ul>

                <div class="row card-panel white" *ngIf="detail.maritalStatus > 0 && detail.dependents.length > 0">
                    <h5 class="row center-align">PART C: DEPENDENTS INFORMATION</h5>
                    <table class="hover striped responsive-table card-content">
                        <thead>
                            <tr>
                                <th>Children's Name</th>
                                <th>Age</th>
                                <th>Date of Birth (M/D/Y)</th>
                                <th>Grade in September </th>
                                <th>place they will attend school </th>
                                <th>Comments</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr *ngFor="let child of detail.dependents; let i=index" style="cursor: pointer;"
                                (click)="onShowDetails(i)">
                                <td>{{child.last_name}}, {{child.first_name}}</td>
                                <td>{{child.age}}</td>
                                <td>{{child.dob? child.dob.toDate().toDateString() : '' }}</td>
                                <td>{{child.grade}}</td>
                                <td>{{child.school}}</td>
                                <td>{{child.comment}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <ul class="collection with-header">
                    <li class="collection-header center-align">
                        <h5>PART D: ASSISTANCE REQUIRED</h5>
                    </li>
                    <li class="collection-item">
                        <div>Assistance Type<a class="secondary-content">{{detail.assistanceType}}</a></div>
                    </li>
                    <li class="collection-item">
                        <div>Institution Name<a class="secondary-content">{{detail.institution}}</a></div>
                    </li>
                    <li class="collection-item">
                        <div>Location<a class="secondary-content">{{detail.location}}</a></div>
                    </li>
                    <li class="collection-item">
                        <div>Program Name<a class="secondary-content">{{detail.program}}</a></div>
                    </li>
                    <li class="collection-item">
                        <div>Starts On<a class="secondary-content">{{detail.startDate?
                                detail.startDate.toDate().toDateString() :
                                ''}}</a></div>
                    </li>
                    <li class="collection-item">
                        <div>Ends On<a class="secondary-content">{{detail.endDate?
                                detail.endDate.toDate().toDateString() : ''}}</a>
                        </div>
                    </li>
                    <li class="collection-item">
                        <div>Program Length<a class="secondary-content">{{detail.length}} Years</a></div>
                    </li>
                    <li class="collection-item">
                        <div>Years Completed<a class="secondary-content">{{detail.yearsCompleted}}</a></div>
                    </li>
                    <li class="collection-header">
                        <p> <strong>Term(s)</strong> </p>
                    </li>
                    <li class="collection-item">
                        <div>Fall (Sept-Jan)
                            <a *ngIf="detail.terms.fall" class="secondary-content">
                                <i class="material-icons green-text">check_circle</i></a>
                            <a *ngIf="!detail.terms.fall" class="secondary-content">
                                <i class="material-icons red-text">cancel</i></a>
                        </div>
                    </li>
                    <li class="collection-item">
                        <div>Winter (Jan-Apr)
                            <a *ngIf="detail.terms.winter" class="secondary-content">
                                <i class="material-icons green-text">check_circle</i></a>
                            <a *ngIf="!detail.terms.winter" class="secondary-content">
                                <i class="material-icons red-text">cancel</i></a>
                        </div>
                    </li>
                    <li class="collection-item">
                        <div>Inter-Session (May-June)
                            <a *ngIf="detail.terms.inter_session" class="secondary-content">
                                <i class="material-icons green-text">check_circle</i></a>
                            <a *ngIf="!detail.terms.inter_session" class="secondary-content">
                                <i class="material-icons red-text">cancel</i></a>
                        </div>
                    </li>
                    <li class="collection-item">
                        <div>Summer-Session (July-Aug)
                            <a *ngIf="detail.terms.summer_session" class="secondary-content">
                                <i class="material-icons green-text">check_circle</i></a>
                            <a *ngIf="!detail.terms.summer_session" class="secondary-content">
                                <i class="material-icons red-text">cancel</i></a>
                        </div>
                    </li>
                </ul>

                <ul class="collection with-header">
                    <li class="collection-header center-align">
                        <h5>PART E: DECLARATION</h5>
                    </li>
                    <li class="collection-item">
                        <div>Applicant Name<a class="secondary-content">{{detail.applicantName}}</a></div>
                    </li>
                    <li class="collection-item">
                        <div>Applicant Signature <a class="secondary-content tooltipped blue-text" data-position="top"
                                data-tooltip="Click here to view Signature" (click)="showSignature()"
                                style="cursor: pointer;">View
                                Signature</a></div>
                    </li>
                    <li class="collection-item">
                        <div>Date<a class="secondary-content">{{detail.date? detail.date.toDate().toDateString():
                                ''}}</a>
                        </div>
                    </li>
                </ul>
                <ul class="collection with-header" *ngIf="detail.documents && detail.documents.length > 0">
                    <li class="collection-header center-align">
                        <h5>PART F: OTHER INFORMATIONS</h5>
                    </li>
                    <li class="collection-header">
                        <p> <strong>Uploaded Documents</strong> </p>
                    </li>
                    <li class="collection-item" *ngFor="let doc of detail.documents;let i=index;">
                        <div>{{doc.type}} - {{doc.name}}<a [href]="doc.link" target="_blank"
                                class="secondary-content tooltipped blue-text" data-position="bottom"
                                data-tooltip="Click here to view File" style="cursor: pointer;"><i
                                    class="material-icons">
                                    open_in_new
                                </i></a></div>
                    </li>
                </ul>

                <div class=" row col s12">
                    <button class="btn tooltipped col s12" data-position="bottom" data-tooltip="Open as a PDF"
                        type="button" style="margin-bottom: 25px;" (click)="openPdf()" style="margin:5px;"><i
                            class="material-icons">open_in_new</i>View as PDF</button>
                    <button class="btn blue tooltipped col s12" data-position="bottom"
                        data-tooltip="Download Application" type="button" style="margin-bottom: 25px;"
                        (click)="downloadPdf()" style="margin:5px;"><i class="material-icons blue">download</i>Download
                        Application</button>
                    <button class="btn green tooltipped col s12" data-position="bottom" data-tooltip="Print Application"
                        type="button" style="margin-bottom: 25px;" (click)="printPdf()" style="margin:5px;"> <i
                            class="material-icons">print</i>Print Application</button>

                    <button [ngClass]="'col s12 btn waves-effect ' + themeColor" (click)="backToList()" type="button"
                        style="margin-top: 25px;"><i class="material-icons left">arrow_back_ios</i> Back To Applications
                        Page</button>
                </div>
            </div>

            <div id="imageModal" class="modal" *ngIf="image && imageName">
                <div class="modal-content">
                    <div class="row col s12">
                        <h5>{{imageName}}</h5>
                        <img [src]="image" alt="Signature" class="col s12" />
                    </div>
                    <div class="row">
                        <div class="input-field col s12 center">
                            <button type="button" class='col s12 btn waves-effect red' (click)="closeModal()">
                                <i class="material-icons right">close</i> Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </main>

    </div>
</div>