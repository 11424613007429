<div class="container-pi">
    <div class="" *ngIf="!current_ap_id ">
        <h4 class="title-section1 title-member  icon-texts"><span class="material-symbols-outlined back-icon p-r-1"
                (click)=" backClicked()">
                arrow_circle_left
            </span>Add New Application </h4>
    </div>
    <main class="StickyContent white row">
        <div class="row col s12" *ngIf="!current_ap_id ">

            <div class="col s12 l10">

                <form (ngSubmit)="submitForm(addActionPlanForm.value)" [formGroup]="addActionPlanForm">
                    <div class="row card-panel">
                        <div class="input-field col s12 m6">
                            <div class="label-input">First Name</div>
                            <input id="firstname" type="text" formControlName="firstname"
                                style="text-transform: capitalize;" placeholder="First Name">
                        </div>

                        <div class="input-field col s12 m6">
                            <div class="label-input">Last Name</div>
                            <input id="lastname" type="text" formControlName="lastname"
                                style="text-transform: capitalize;" placeholder="Last Name">
                        </div>
                    </div>

                    <div class="row card-panel">
                        <div class="input-field col s6 ">
                            <div class="label-input">SIN <strong class="red-text">*</strong></div>
                            <input id="SIN" type="text" formControlName="SIN" class="validate"
                                [textMask]="{mask: SINMask, guide: true}" [readonly]="hasSIN" placeholder="SIN">
                            <div *ngIf="(SIN.dirty || SIN.touched) && SIN.invalid">
                                <small *ngIf="SIN.errors.minlength || SIN.errors.maxlength" class="red-text">The SIN
                                    must be 9 Digits.</small>
                            </div>
                        </div>

                        <div class="input-field class col s6">
                            <div class="label-input">Purpose</div>
                            <input formControlName="purpose" id="purpose" type="text" maxlength="1000"
                                autocomplete="off" require class="capitalize validate" placeholder="Purpose">
                        </div>

                        <div class="class col s12">
                            <div class="label-input">Funding Type <strong class="red-text">*</strong></div>
                            <div class="row">
                                <label class="col s12 m4">
                                    <input class="with-gap" formControlName="funding_type" name="funding_type"
                                        type="radio" value="EI" />
                                    <span>EI</span>
                                </label>
                                <label class="col s12 m4">
                                    <input class="with-gap" formControlName="funding_type" name="funding_type"
                                        type="radio" value="CRF" />
                                    <span>CRF</span>
                                </label>

                                <label class="col s12 m4">
                                    <input class="with-gap" formControlName="funding_type" name="funding_type"
                                        type="radio" value="FNJF" />
                                    <span>FNJF</span>
                                </label>

                                <label class="col s12 m4">
                                    <input class="with-gap" formControlName="funding_type" name="funding_type"
                                        type="radio" value="Non-Funded" />
                                    <span>Non-Funded</span>
                                </label>

                                <label class="col s12 m4">
                                    <input class="with-gap" formControlName="funding_type" name="funding_type"
                                        type="radio" value="Pending" />
                                    <span>Pending</span>
                                </label>
                            </div>
                        </div>

                        <div class="input-field class col s7 ">
                            <div class="label-input">Start Date<span class="red-text">*</span></div>
                            <input id="start_date" formControlName="start_date" type="text" class="input-box"
                                angular-mydatepicker name="mydate" (click)="dp1.toggleCalendar()"
                                [options]="myDpOptions" #dp1="angular-mydatepicker"
                                (dateChanged)="onStartDateChange($event)" placeholder="Start Date" />
                        </div>
                    </div>

                    <div class="section"></div>

                    <!-- <div class="row">
                        <button class='at-btn col s12 btn waves-effect success' style="margin-bottom: 15px;"
                            type="submit" [disabled]="!addActionPlanForm.valid">
                            <i class="material-icons right">add_circle</i> Save Application
                        </button>

                        <button type="button" [ngClass]="'col s12 btn waves-effect ' + themeColor "
                            (click)="backClicked()">
                            <i class="material-icons left">keyboard_arrow_left</i> Back To My Services
                        </button>
                    </div> -->

                    <div class="row">
                        <div class="col s6">
                            <button class="btn left waves-effect red" type="button" style="margin-bottom: 15px"
                                (click)="backClicked()"><i class="material-icons left">keyboard_arrow_left</i>Back To My
                                Services
                            </button>
                        </div>
                        <div class="col s6">
                            <button class=" btn right  green" type="submit" [disabled]="!addActionPlanForm.valid"
                                style="margin-bottom: 15px" title="Save">Save Application <i
                                    class="material-icons right">add_circle</i></button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <div class=" col s12 l11" *ngIf="current_ap_id">
            <div class="row card-panel">
                <div class="row center-align ">
                    <div class="col s12 l10">
                        <h5><strong><span class="red-text">Application</span></strong></h5>
                        <br>
                        <h6><strong>You have existing application in process and unable to create a New One now !
                            </strong></h6>
                    </div>
                </div>
            </div>
        </div>
        <div class=" col s12 l11" *ngIf="current_ap_id">
            <button type="button" [ngClass]="'col s12 btn waves-effect ' + themeColor " (click)="backClicked()">
                <i class="material-icons left">keyboard_arrow_left</i> Back
            </button>
        </div>
    </main>
</div>

<div id="QuestionModal" class="modal">
    <div class="modal-content">
        <h5><strong><span class="blue-text">Submit Funding Request</span></strong></h5>
        <br>
        <p>Are you ready to submit the funding application ?</p>
    </div>
    <div class="modal-footer">
        <button type="button" (click)="ReadytoSubmitYes()" class="btn waves-effect green white-text"
            style="margin-right: 15px;">
            <i class="material-icons right white-text">check</i> Yes
        </button>

        <button type="button" (click)="ReadytoSubmitNo()" class="btn waves-effect red white-text"
            style="margin-right: 15px;">
            <i class="material-icons right white-text">close</i> No
        </button>
    </div>
</div>