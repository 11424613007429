
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DocumentsService } from 'src/app/services/api/documents.service';
import { environment } from 'src/environments/environment';
import { IDModel } from 'src/app/models/isets/IDModel';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { toast } from 'materialize-css';

declare var $: any;
declare var navigator: any;
declare var M: any;

@Component({
  selector: 'app-upload-asets-document',
  templateUrl: './upload-asets-document.component.html',
  styleUrls: ['./upload-asets-document.component.css']
})

export class UploadAsetsDocumentComponent implements OnInit {

  @Input() clientID: any;
  @Input() isCordova: boolean;
  @Input() callerForm: string;
  @Output() documentSaved: EventEmitter<string> = new EventEmitter<string>();

  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  preview = '';
  fileName: string;
  uploadDocumentForm: UntypedFormGroup;
  documentTypes: any = ['Identification', 'Support request', 'Intervention', 'Other'];

  showDesciption = false;

  step1 = true;
  step2 = false;
  step3 = false;
  step4 = false;
  step5 = false;

  constructor(private fb: UntypedFormBuilder,
    private documentsService: DocumentsService,
    private location: Location) { }


  ngOnInit() {
    // have to add ReactiveFormsModule into share.modle.ts. 
    this.uploadDocumentForm = this.fb.group({
      documentType: ['', [Validators.required]],
      document: ['', [Validators.required]],
      description: [''],
      upload_doc: [''],
      send_msg: [''],
      message: ['']
    });

    if (this.callerForm === 'Support request') {
      this.uploadDocumentForm.patchValue({
        documentType: 'Support request',
        document: '',
        description: ''
      });
    }

    setTimeout(() => {
      $('select').formSelect();
    }, 25);
  }

  get documentType() { return this.uploadDocumentForm.get('documentType'); }
  get description() { return this.uploadDocumentForm.get('description'); }
  get document() { return this.uploadDocumentForm.get('document'); }
  get upload_doc() { return this.uploadDocumentForm.get('upload_doc'); }
  get send_msg() { return this.uploadDocumentForm.get('send_msg'); }
  get message() { return this.uploadDocumentForm.get('message'); }


  docTypeChange() {
    if (this.documentType.value === 'Other') {
      this.showDesciption = true;
    }

    if (this.documentType.value !== 'Other') {
      this.showDesciption = false;
      this.uploadDocumentForm.patchValue({
        description: '',
      });
    }

    if (this.step2) {
      if (this.uploadDocumentForm.get('upload_doc').value === '') {
        toast({ html: 'Please Answer the selection question first !', classes: 'red' });
      }
    }
  }

  submitForm(value: any) {
    if (!this.preview) {
      alert('No File Selected. \n Please select or scan a file from your device.');
      return;
    }
    if (this.preview === ' ') {
      return;
    }
    const model = new IDModel();
    model.clientID = this.clientID;

    if (value.documentType === 'Other') {
      model.documentName = 'Other / ' + value.description;
    } else {
      model.documentName = value.documentType;
    }
    if (value.documentType === 'Support request') {
      model.document_type = 1;
    } else if (value.documentType === 'Identification') {
      model.document_type = 2;
    } else if (value.documentType === 'Intervention') {
      model.document_type = 2; //3;
    } else {   //Other
      model.document_type = 2;
    }

    model.base64Document = this.preview.replace(/^data:image\/[a-z]+;base64,/, '');
    this.documentsService.postIsetsDocument(model).subscribe(x => {
      if (x.Message == 'Document Successfully submited') {
        this.documentSaved.emit('saved');

        this.uploadDocumentForm.patchValue({ documentType: '' });
        this.uploadDocumentForm.patchValue({ description: '' });
        this.uploadDocumentForm.patchValue({ upload_doc: '' });

        this.showDesciption = false;
        this.removeFile();

        this.step1 = false;
        this.step2 = true;

        setTimeout(() => {
          $('select').formSelect();
          M.updateTextFields();
        }, 25);
      } else if (x.Message == 'Unable To Save Document Please try Again!') {
        alert('Unable To Save Document Please try Again!');
        return;
      } else {
        alert('This is not image file,\n Please select a image file');
        return;
      }
    });
  }

  onUploadYes(evt) {
    this.step1 = true;
    this.step2 = false;
    setTimeout(() => {
      $('select').formSelect();
    }, 25)
  }

  onUploadNo(evt) {
    this.step1 = false;
    this.step2 = false;
    this.step4 = true;   //this.step3 = true; 
    setTimeout(() => {
      $('select').formSelect();
    }, 25)
  }

  onSendMessageYes(evt) {
    this.step3 = false;
    //send message to administrator
    this.step5 = true;
    setTimeout(() => {
      $('select').formSelect();
    }, 25)
  }

  onSendMessageNo(evt) {
    this.step3 = false;
    this.step4 = true;
    setTimeout(() => {
      $('select').formSelect();
    }, 25)
  }

  Send_message() {
    // this.emailService.sendEmailtoAdmin( this.client.givenname, this.client.lastname, this.client.email, this.uploadDocumentForm.value.message, '');
    // this.step5=false;
    // this.step4=true;
  }

  // Handle Web Upload
  handleFileInput(files: FileList) {
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.fileName = files[0].name;
      this.preview = reader.result as string;
    };
  }

  // Handle Mobile Camera/Library
  handleCameraInput() {
    navigator.camera.getPicture(onSuccess, onFail, {
      quality: 100,
      saveToPhotoAlbum: true,
      destinatinType: navigator.camera.DestinationType.DATA_URL
    });

    function onSuccess(imageData) {
      this.preview = 'data:image/jpeg;base64,' + imageData;
      this.fileName = 'Photo From Gallery';
    }

    function onFail(message) {
    }
  }

  capturePhoto() {
    navigator.camera.getPicture(this.onPhotoDataSuccess, this.onFail, {
      quality: 50,
      destinationType: navigator.camera.DestinationType.DATA_URL
    });
  }

  getPhotoFromLibrary() {
    navigator.camera.getPicture(this.onPhotoURISuccess, this.onFail, {
      quality: 50,
      destinationType: navigator.camera.DestinationType.DATA_URL,
      sourceType: navigator.camera.PictureSourceType.PHOTOLIBRARY,
    });
  }

  onPhotoURISuccess(imageURI) {
    this.preview = 'data:image/jpeg;base64,' + imageURI;
  }

  onPhotoDataSuccess(imageData) {
    this.preview = 'data:image/jpeg;base64,' + imageData;
  }

  onFail(message) {
  }

  removeFile() {
    this.fileName = null;
    this.preview = null;
    this.uploadDocumentForm.patchValue({
      documentType: 'Please Select Document Type',
      document: '',
      description: '',
    });
  }

  back1() {
    this.preview = ' ';
    this.location.back();
  }

  backClicked() {
    this.location.back();
  }

}