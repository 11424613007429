import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmployeeMainComponent } from './employee-main/employee-main.component';
import { EmployeeRoutingModule } from './employee-routing.module';
import { EmployeeMenuComponent } from './employee-menu/employee-menu.component';
import { InboxComponent } from './Messaging/inbox/inbox.component';
import { MyAccountComponent } from './my-account/my-account.component';
import { DepartmentInfoComponent } from './department/department-info/department-info.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
// import { TextMaskModule } from 'angular2-text-mask';
import { NewsListComponent } from './department/department-news/news-list/news-list.component';
import { AddNewsComponent } from './department/department-news/add-news/add-news.component';
import { EditNewsComponent } from './department/department-news/edit-news/edit-news.component';
import { SharedModule } from '../shared/shared.module';
import { provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
// import { SortablejsModule } from 'ngx-sortablejs';
import { EmployeeListComponent } from './department/department-employees/employee-list/employee-list.component';
import { InviteEmployeeComponent } from './department/department-employees/invite-employee/invite-employee.component';
import { DiscussionComponent } from './Messaging/discussion/discussion.component';
import { SubDepartmentComponent } from './department/sub-department/sub-department.component';
import { EmployeesRegistrationRequestComponent } from './employees-registration-request/employees-registration-request.component';

@NgModule({ declarations: [
        EmployeeMainComponent,
        EmployeeMenuComponent,
        InboxComponent,
        MyAccountComponent,
        DepartmentInfoComponent,
        NewsListComponent,
        AddNewsComponent,
        EditNewsComponent,
        EmployeeListComponent,
        InviteEmployeeComponent,
        DiscussionComponent,
        SubDepartmentComponent,
        EmployeesRegistrationRequestComponent,
    ], imports: [CommonModule,
        EmployeeRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        // TextMaskModule,
        SharedModule], providers: [provideHttpClient(withInterceptorsFromDi())] })

export class EmployeeModule { }
