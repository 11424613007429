<div class="body-bg">

  <div class="row p-b-7 p-t-2">

    <app-firebase-loading *ngIf="showLoader"></app-firebase-loading>

    <div class="p-b-2" *ngIf="sectionName">
      <div class="col s7 m10 l5 push-l1 push-s5 push-m1">
        <div class="header-title ">
          <h6>{{sectionName}}</h6>
        </div>
      </div>

      <div class="divider col l10 m8 s9 push-l1 push-m2 push-s2 grey lighten-3"></div>
    </div>


    <div class="container">
      <div *ngIf="model" class="col s12 m12 l12 p-t-3">
        <div *ngFor="let data of model">
          <app-news-feed *ngIf="data.visibility === 'Public'" [callerForm]="'News'" [newsModel]="data">
          </app-news-feed>
        </div>
      </div>
    </div>

    <div class="col s12 m10 l10 push-m1 push-l1 container1" *ngIf="!model && !showLoader">
      <div class="row  white">
        <strong>
          <span class="material-icons blue-text" style="display: inline-flex; vertical-align: top;">info</span>
          {{nationName}} Did Not Share Any News</strong>
      </div>
    </div>
  </div>
</div>