<div class="container-pi">
  <h4 class="title-section1 title-member  icon-texts"><span class="material-symbols-outlined back-icon p-r-1"
      (click)=" backClicked()">
      arrow_circle_left
    </span>Contact Us </h4>
  <!-- <h6 class="center p-m-2 b-m-2"><strong> Contact Us </strong></h6> -->
  <div class="row">
    <!-- <div class="col l1"></div> -->
    <div class="col s12 l12">
      <app-contact-editor [contactType]="'MemberMessage'"></app-contact-editor>
    </div>
    <div class="col l1"></div>
  </div>

  <div class="row">
    <div class="col l1"></div>
    <a [ngClass]="'col s12 l10 btn waves-effect ' + themeColor" (click)="backClicked()"><i
        class="material-icons left">arrow_back_ios_new</i> Back To Previous Page</a>
    <!-- <div class="col l1"></div> -->
  </div>
</div>