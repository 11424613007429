import { Component, OnInit } from '@angular/core';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { toast } from 'materialize-css';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-member-menu-settings',
  templateUrl: './member-menu-settings.component.html',
  styleUrls: ['./member-menu-settings.component.css']
})

export class MemberMenuSettingsComponent implements OnInit {

  defaultThemeColor = environment.appTheme.themeColor;
  themeColor: any;
  buttonType: any;
  sectionName: string;

  defaultMenus = [
    {
      name: 'MyAccount',
      displayName: 'My Account',
      icon: 'account_circle',
      isEnabled: true,
      index: 0,
      submenus: [
        {
          name: 'MyPersonalInformation',
          displayName: "My Personal Information",
          icon: 'account_box',
          isEnabled: true,
          index: 0,
        },
        {
          name: 'FamilyComposition',
          displayName: "Family Composition",
          icon: 'people',
          isEnabled: true,
          index: 1,
          underConstruction: false
        },
        {
          name: 'ISApplication',
          displayName: "IS Application",
          icon: 'import_contacts',
          isEnabled: true,
          index: 2,
        },
        {
          name: 'SKISApplication',
          displayName: "SK IS Application",
          icon: 'import_contacts',
          isEnabled: true,
          index: 3,
        },
        {
          name: 'MyCasePlan',
          displayName: 'My Case Plan',
          icon: 'add_business',
          isEnabled: true,
          index: 4,
        },
        {
          name: 'UploadDocuments',
          displayName: 'Upload Documents',
          icon: 'cloud_upload',
          isEnabled: true,
          index: 5,
        },
        {
          name: 'MySettings',
          displayName: 'My Settings',
          icon: 'settings',
          isEnabled: true,
          index: 6,
        },
        {
          name: 'MyAreasOfInterest',
          displayName: 'My Areas Of Interest',
          icon: 'share',
          isEnabled: true,
          index: 7,
        },
        {
          name: 'IASurvey',
          displayName: 'IA Survey',
          icon: 'list_alt',
          isEnabled: true,
          index: 8,
        }
      ]
    },

    {
      name: 'MyChat',
      displayName: 'My Chat / Circle',
      icon: 'mail',
      isEnabled: true,
      index: 1,
      submenus: []
    },

    {
      name: 'NewsAndEvents',
      displayName: 'News / Events',
      icon: 'notifications_none',
      isEnabled: true,
      index: 2,
      submenus: [
        {
          name: 'News',
          displayName: "News",
          icon: 'circle_notifications',
          isEnabled: true,
          index: 0,
        },
        {
          name: 'Events',
          displayName: "Events",
          icon: 'event_available',
          isEnabled: true,
          index: 1,
        },
      ]
    },

    {
      name: 'News',
      displayName: 'News',
      icon: 'notifications_none',
      isEnabled: true,
      index: 2,
      submenus: []
    },

    {
      name: 'Events',
      displayName: 'Events',
      icon: 'event_available',
      isEnabled: true,
      index: 3,
      submenus: []
    },

    {
      name: 'MyServices',
      displayName: 'My Services',
      icon: 'home_repair_service',
      isEnabled: true,
      index: 4,
      submenus: [
        {
          name: 'SocialDevelopment',
          displayName: 'Social Development',
          icon: 'assessment',
          isEnabled: true,
          index: 0,
          submenus: [
            {
              name: 'RequiredSignature',
              displayName: 'Required Signature',
              icon: 'pending_actions',
              isEnabled: true,
              index: 0,
            },
            {
              name: 'MyBenefits',
              displayName: 'My Benefits',
              icon: 'update',
              isEnabled: true,
              index: 1,
            },
            {
              name: 'RequestBenefits',
              displayName: 'Request Benefits',
              icon: 'add_circle',
              isEnabled: true,
              index: 2,
            },
            // {
            //   name: 'IncomeStatement',
            //   displayName: 'Submit My Income Statement',
            //   icon: 'monetization_on',
            //   isEnabled: true,
            //   index: 3,
            // },
            // {
            //   name: 'ActionPlan',
            //   displayName: 'My Action Plan',
            //   icon: 'supervised_user_circle',
            //   isEnabled: true,
            //   index: 4,
            // },
            // {
            //   name: 'PastBenefits',
            //   displayName: 'My Past Benefits',
            //   icon: 'history',
            //   isEnabled: true,
            //   index: 5,
            // },
          ]
        },
        {
          name: 'Housing',
          displayName: 'Housing',
          icon: 'assessment',
          isEnabled: true,
          index: 1,
        },
        {
          name: 'ISET',
          displayName: 'I.S.E.T',
          icon: 'assessment',
          isEnabled: true,
          index: 2,
          submenus: [
            {
              name: 'NewApplication',
              displayName: 'New Application',
              icon: 'account_box',
              isEnabled: true,
              index: 0,
            },
            {
              name: 'UpdateApplication',
              displayName: 'Update Application',
              icon: 'account_box',
              isEnabled: true,
              index: 1,
            },
            {
              name: 'Interventions',
              displayName: 'My Interventions',
              icon: 'update',
              isEnabled: true,
              index: 2,
            },
            {
              name: 'ServicePlan',
              displayName: 'Service Plan',
              icon: 'account_box',
              isEnabled: true,
              index: 3,
            },
            {
              name: 'UploadDocuments',
              displayName: 'Upload Documents',
              icon: 'pending_actions',
              isEnabled: true,
              index: 4,
            },
          ]
        },
        {
          name: 'PostSecondary',
          displayName: "Post Secondary Education",
          icon: 'assessment',
          isEnabled: true,
          index: 3,
          submenus: [
            {
              name: 'MyApplication',
              displayName: 'My Application',
              icon: 'pending_actions',
              isEnabled: true,
              index: 0,
            },
          ]
        },
      ]
    },

    {
      name: 'MyNation',
      displayName: 'My Nation',
      icon: 'dashboard',
      isEnabled: true,
      index: 5,
      submenus: [
        {
          name: 'Departments',
          displayName: 'Departments / Offices',
          icon: 'account_tree',
          isEnabled: true,
          index: 0,
        },
        {
          name: 'Governance',
          displayName: 'Governance',
          icon: 'gavel',
          isEnabled: true,
          index: 1,
        },
        {
          name: 'Boards',
          displayName: 'Boards',
          icon: 'groups',
          isEnabled: true,
          index: 3,
        },
        {
          name: 'NationContact',
          displayName: "Nation Contact",
          icon: 'contact_mail',
          isEnabled: true,
          index: 4,
        },
      ]
    },

    {
      name: 'PollsAndElection',
      displayName: 'Polls / Election',
      icon: 'poll',
      isEnabled: true,
      index: 6,
      submenus: [
        {
          name: 'Nomination',
          displayName: "Nomination",
          icon: 'people',
          isEnabled: true,
          index: 0,
        },
        {
          name: 'Election',
          displayName: "Election",
          icon: 'how_to_vote',
          isEnabled: true,
          index: 1,
        },
        {
          name: 'PollsAndSurveys',
          displayName: "Polls and Surveys",
          icon: 'poll',
          isEnabled: true,
          index: 2,
        },
        {
          name: 'MyCampaign',
          displayName: "My Campaign",
          icon: 'campaign',
          isEnabled: true,
          index: 3,
        },
        {
          name: 'MyNominations',
          displayName: "My Nominations",
          icon: 'campaign',
          isEnabled: true,
          index: 4,
        },
        {
          name: 'MyElections',
          displayName: "My Elections",
          icon: 'how_to_vote',
          isEnabled: true,
          index: 5,
        }
      ]
    },

    {
      name: 'JobRelated',
      displayName: 'Job Related',
      icon: 'work',
      isEnabled: true,
      index: 7,
      submenus: [
        {
          name: 'ResumeBuilder',
          displayName: 'Resume Builder',
          icon: 'folder_shared',
          isEnabled: true,
          index: 0,
          submenus: [
            {
              name: 'Objective',
              displayName: 'Objective',
              icon: 'home_work',
              isEnabled: true,
              index: 0,
            },
            {
              name: 'Highlights',
              displayName: 'Highlights',
              icon: 'person_add',
              isEnabled: true,
              index: 1,
            },
            {
              name: 'WorkExperienceAndTraining',
              displayName: 'Work Experience / Training',
              icon: 'apartment',
              isEnabled: true,
              index: 2,
            },
            {
              name: 'Skills',
              displayName: 'Skills',
              icon: 'home_work',
              isEnabled: true,
              index: 3,
            },
            {
              name: 'Education',
              displayName: 'Education',
              icon: 'import_contacts',
              isEnabled: true,
              index: 4,
            },
            {
              name: 'CoursesAndCertificates',
              displayName: 'Courses / Certificates',
              icon: 'note',
              isEnabled: true,
              index: 5,
            },
            {
              name: 'Activities',
              displayName: 'Activities',
              icon: 'home_work',
              isEnabled: true,
              index: 6,
            },
            {
              name: 'References',
              displayName: 'References',
              icon: 'group_add',
              isEnabled: true,
              index: 7,
            }
          ]
        },

        {
          name: 'JobBoard',
          displayName: "Job Board",
          icon: 'how_to_vote',
          isEnabled: true,
          index: 1,
        },
      ]
    },

    {
      name: 'MyHouse',
      displayName: "My House",
      icon: 'house',
      isEnabled: true,
      index: 8,
      submenus: []
    },

    {
      name: 'SocialDevelopment',
      displayName: 'Social Development',
      icon: 'assessment',
      isEnabled: true,
      index: 9,
      submenus: []
    },

    {
      name: 'Logout',
      displayName: 'Logout',
      icon: 'logout',
      isEnabled: true,
      index: 10,
      submenus: []
    },
  ];

  menus: any;
  isNewSetting = true;
  activeModalIndex: number = -1;
  submenus: any[];
  editName = false;

  constructor(private settingService: AppSettingsService,
    private router: Router,
    private dataService: DataService) {
    this.themeColor = this.settingService.getAppThemeColor() ? this.settingService.getAppThemeColor() : this.defaultThemeColor;
    this.buttonType = this.settingService.getButtonType() ? this.settingService.getButtonType() : 'rectangularButton';

    this.defaultMenus = this.dataService.getMemberMenus();
  }

  ngOnInit(): void {
    this.settingService.getMenuName('Settings').valueChanges().subscribe(menus => {
      if (menus && menus.length > 0) {
        this.submenus = menus[0].submenus;
        let menu = this.submenus.find(m => m.name == 'MemberMenu');
        if (menu) this.sectionName = menu.displayName;
      }
    });

    this.settingService.getMemberMenus().valueChanges().subscribe(menus => {
      if (menus && menus.length > 0) {
        this.menus = menus;
        this.isNewSetting = false;
      } else {
        this.menus = this.defaultMenus;
        this.isNewSetting = true;
      }

      this.menus = this.menus.sort((a, b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0));
      setTimeout(() => {
        $('.collapsible').collapsible();
        $('.modal').modal();
        $('.modal').modal('close');
      }, 25);
    });
  }

  editNameClicked() {
    this.editName = true;
  }

  saveName() {
    let index = this.submenus.findIndex(m => m.name == 'MemberMenu');
    this.submenus[index].displayName = this.sectionName;
    this.settingService.UpdateMenu('Settings', this.submenus).then((update) => {
      this.editName = false;
      toast({ html: `${update}`, classes: 'green' });
    }).catch((error) => {
      toast({ html: `${error}`, classes: 'red' });
    });
  }


  moveSubmenu(sectionIndex: number, submenu: any, position: number) {
    // Find the parent menu
    const parentMenu = this.menus[sectionIndex];
    const index = parentMenu.submenus.indexOf(submenu);
    const newIndex = index + position;
  
    // Ensure the new index is within bounds
    if (newIndex < 0 || newIndex >= parentMenu.submenus.length) return;
  
    // Reorder the submenus within the parent menu
    parentMenu.submenus.splice(index, 1);
    parentMenu.submenus.splice(newIndex, 0, submenu);
  
    // Update the indices within the parent menu
    parentMenu.submenus.forEach((sub, subIndex) => {
      sub.index = subIndex;
    });
  }
  

  openModal(index: number) {
    this.activeModalIndex = index;
    const modalElement = document.getElementById(`modal${index}`);
    if (modalElement) {
      const modalInstance = M.Modal.getInstance(modalElement);
      modalInstance.open();
    }
  }

  visibilityChanged(section: any) {
    section.isEnabled = !section.isEnabled;

  }

  moveSection(element, position: number) {
    var index = this.menus.indexOf(element);
    var newIndex = index + position;
    if (newIndex < 0 || newIndex == this.menus.length) return; //Already at the top or bottom.   
    this.menus.splice(index, 1);
    this.menus.splice(newIndex, 0, element);
    this.menus.forEach(element => {
      element.index = this.menus.indexOf(element);
    });
  };

  saveChanges() {
    let saved = false;
  
    if (this.menus && this.isNewSetting) {
      this.menus.forEach(m => {
        this.settingService.saveMemberMenu(m);
        saved = true;
      });
    }
  
    if (saved) {
      M.toast({ html: 'Menu Successfully Saved.', classes: 'green' });
    }
  
    if (this.menus && !this.isNewSetting) {
      let updated = false;
      this.menus.forEach(m => {
        this.settingService.updateMemberMenu(m);
        updated = true;
      });
      if (updated) {
        M.toast({ html: 'Menus Successfully Updated.', classes: 'green' });
      }
    }
  
    // Close the modal here
    this.closeModal();
  }
  
  closeModal() {
    $('.modal').modal('close');
  }


  

  backClicked() {
    this.router.navigate(['/admin/dashboard']);
  }

}