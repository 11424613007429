import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DepartmentModel } from 'src/app/models/DepartmentModel';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { DepartmentService } from 'src/app/services/firebase/department.service';
import { environment } from 'src/environments/environment';

declare var M: any;

@Component({
  selector: 'app-view-subdepartment-info',
  templateUrl: './view-subdepartment-info.component.html',
  styleUrls: ['./view-subdepartment-info.component.scss']
})
export class ViewSubdepartmentInfoComponent implements OnInit {
  model: DepartmentModel;
  subDepartmentId: any;  
  
  defaultThemeColor = environment.appTheme.themeColor;
  themeColor: any;
  buttonType: any;

  constructor(
    private router: Router,
    private departmentService: DepartmentService,
    private route: ActivatedRoute,
    private settingsService: AppSettingsService
  ) {
    this.themeColor = this.settingsService.getAppThemeColor() ? this.settingsService.getAppThemeColor() : this.defaultThemeColor;
    this.buttonType = this.settingsService.getButtonType() ? this.settingsService.getButtonType() : 'rectangularButton';
   

   }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.subDepartmentId = params.get('id');
    });

    if (this.subDepartmentId) {
      this.departmentService.getDepartmentByID(this.subDepartmentId).valueChanges().subscribe(x => {
        if (x) {
          this.model = x;
        }
      });
    }

  }

  backClicked() {
    this.router.navigate(['/manage-sub-department'], { state: { department_id:  this.model.parent_id} });
  }
}
