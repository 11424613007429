<div class="row center-align p-m-2">
  <h6> <strong> Scan/Upload Income Statement </strong></h6>
</div>

<div class="row col s12">
  <div class="col l1"></div>
  <div class="col s12 l10">

    <app-upload-document [applicantID]="applicantID" [callerForm]="callerName" (documentSaved)="parentEventHandlerFunction($event)"></app-upload-document>

  </div>
  <div class="col l1"></div>
</div>
