import { Component, OnInit } from '@angular/core';
import { AboutusService } from 'src/app/services/firebase/aboutus.service';
import { trigger, transition, useAnimation } from '@angular/animations';
import { bounceIn } from 'ng-animate';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { SafeUrl } from '@angular/platform-browser';
import { NationSettingsService } from 'src/app/services/api/nation-settings.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-about-us',
  templateUrl: './about-us.component.html',
  styleUrls: ['./about-us.component.css'],
  animations: [
    trigger('swing', [transition('* => *', useAnimation(bounceIn))])
  ],
})
export class AboutUsComponent implements OnInit {
  model: any[];
  nationOfUse = environment.firstNation.name;
  nationName = environment.firstNation.displayName;
  defaultThemeColor = environment.appTheme.themeColor;
  themeColor: any;
  iframehtml: any;
  pdfURL: SafeUrl;

  bodyBgColor: any;
  setting: any;
  defaultBgColor = '#ffffff';
  sortMode: any;


  constructor(private aboutUsService: AboutusService,
    private router: Router,
    private settingsService: AppSettingsService,
    public appSettingService: AppSettingsService,
    private nationSettingsService: NationSettingsService,
    private dataService: DataService) {
    this.themeColor = this.settingsService.getAppThemeColor() ? this.settingsService.getAppThemeColor() : this.defaultThemeColor;

    // =============================================   remove once added to app-menu =============

    this.dataService.getAppName().subscribe(appName => {
      if (appName) this.nationName = appName;
      else {
        this.nationSettingsService.getNationSettings().subscribe(sett => {
          if (sett) {
            let settingsModel = sett[0];
            if (settingsModel.band_name) this.nationName = settingsModel.band_name;
          }
        });
      }
    });

    document.addEventListener('DOMContentLoaded', function () {
      const longBodyContent = document.getElementById('longBodyContent') as HTMLDivElement;
      const lineHeight = parseInt(window.getComputedStyle(longBodyContent).getPropertyValue('line-height'), 10);
      const maxHeight = 3 * lineHeight; // Adjust the number of lines as needed

      while (longBodyContent.offsetHeight > maxHeight) {
        longBodyContent.textContent = truncateText(longBodyContent.textContent, maxHeight);
      }

      if (longBodyContent.scrollHeight > longBodyContent.offsetHeight) {
        longBodyContent.classList.add('ellipsis');
      }
    });

    function truncateText(text: string, maxHeight: number): string {
      const words = text.split(' ');
      while (words.length > 1 && words.join(' ').length > maxHeight) {
        words.pop();
      }
      return words.join(' ') + '...';
    }


  }

  ngOnInit() {


    this.aboutUsService.getSectionsList().valueChanges().subscribe(sec => {
      if (sec) {
        this.model = sec;
        

        this.model.sort((a, b) => (a.datePublished > b.datePublished ? -1 : 1));
        this.model.sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1));
       
      }

    });
  }


  viewAboutUsSectionData(event: Event, id?: any) {
    event.preventDefault();
    this.router.navigate(['/view-about-us-section'], { state: { about_us_section_id: id } });
  }


  sortSections() {
    switch (this.sortMode) {
      case 'newest':
        // Sort by datePublished from newest to oldest
        this.model.sort((a, b) => b.datePublished - a.datePublished);
        break;
      case 'oldest':
        // Sort by datePublished from oldest to newest
        this.model.sort((a, b) => a.datePublished - b.datePublished);
        break;
      case 'custom':
        // Sort by custom order (e.g., displayOrder)
        this.model.sort((a, b) => a.displayOrder - b.displayOrder);
        break;
      default:
        // Default sorting or throw an error
        break;
    }

  }



}
