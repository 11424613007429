import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { LRRCNSurveyModel } from 'src/app/models/LRRCNSurveyModel';
import { IncomeSupportModel } from '../../models/incomeSupportModel';

@Injectable({
  providedIn: 'root'
})
export class LRRCNSurveyService {

  constructor(private db: AngularFirestore) { }

  surveyListRef: AngularFirestoreCollection<any>;
  applicationRefs: AngularFirestoreDocument<any>;

  saveSurvey(model: LRRCNSurveyModel) {

    var newSurvey;

    let aboutLrrcn;
    let demographic;
    let language;
    let careGiving;
    let education;
    let license;
    let safteyTraining;
    let trainingAndEmployment;
    let jobSatisfaction;
    let barriersOfJob;
    let employmentHistory;
    let shiftWork;
    let addictionsSupport;
    let traditionalEmployment;

    aboutLrrcn = {
      knowAboutLrrcn: model.aboutLrrcn.knowAboutLrrcn,
      registered: model.aboutLrrcn.registered,
      knowHowToUse: model.aboutLrrcn.knowHowToUse,
    }

    demographic = {
      firstName: model.demographic.firstName,
      lastName: model.demographic.lastName,
      email: model.demographic.email,
      phone: model.demographic.phone,
      clientCategory: model.demographic.clientCategory,
      gender: model.demographic.gender,
      maritalStatus: model.demographic.maritalStatus,
      age: model.demographic.age,
      livingProvince: model.demographic.livingProvince,
      community: model.demographic.community,
      housing: model.demographic.housing,
    }

    careGiving = {
      availableCare: model.careGiving.availableCare,
      dayeCareAvailable: model.careGiving.dayeCareAvailable,
      neededCare: model.careGiving.neededCare,
      needsCare: model.careGiving.needsCare,
      numberOfDependants: model.careGiving.numberOfDependants,
      typeOfCareAvailable: model.careGiving.typeOfCareAvailable,
      careProvider: model.careGiving.careProvider,
    }
    
    education = {
      areaOfStudyToAchieve: model.education.areaOfStudyToAchieve,
      currentlyEnrolled: model.education.currentlyEnrolled,
      highestLevelOfEdu: model.education.highestLevelOfEdu,
      knowWhoToContactForUpgrade: model.education.knowWhoToContactForUpgrade,
      collegeApply: model.education.collegeApply,
      applyForFunding: model.education.applyForFunding,
      postSecondaryEdu: model.education.postSecondaryEdu,
      typeOfProgram: model.education.typeOfProgram,
      typeOfProgramToUpgrade: model.education.typeOfProgramToUpgrade,
      upgradeEdu: model.education.upgradeEdu,
      // whenToUpgradeEducation: model.education.whenToUpgradeEducation,
      whyNoUpgrade: model.education.whyNoUpgrade,
      levelOfEduToAchieve: model.education.levelOfEduToAchieve,
    }

    license = {
      beNotifiedForTraining: model.license.beNotifiedForTraining,
      howToBeNotified: model.license.howToBeNotified,
      typeOfLicense: model.license.typeOfLicense,
      typeOfLicenseToObtain: model.license.typeOfLicenseToObtain,
      barrierForLicense: model.license.barrierForLicense,
    }

    safteyTraining = {
    }

    trainingAndEmployment = {
      currentEmploymentComment: model.trainingAndEmployment.currentEmploymentComment,
      currentlyEmployed: model.trainingAndEmployment.currentlyEmployed,
      currentlySeekingEmployment: model.trainingAndEmployment.currentlySeekingEmployment,
      employmentStatus: model.trainingAndEmployment.employmentStatus,
      notifcationEmail: model.trainingAndEmployment.notifcationEmail,
      reasonForSeekingEmployment: model.trainingAndEmployment.reasonForSeekingEmployment,
      notificationPreference: model.trainingAndEmployment.notificationPreference,
      employmentGoals: model.trainingAndEmployment.employmentGoals,
      // secondEmploymentGoals: model.trainingAndEmployment.secondEmploymentGoals,
    }

    jobSatisfaction = {
      detailsForSatisfaction: model.jobSatisfaction.detailsForSatisfaction,
      satisfiedReason: model.jobSatisfaction.satisfiedReason,
      satisfiedWithEmployment: model.jobSatisfaction.satisfiedWithEmployment,
      factorsForSatisfaction: model.jobSatisfaction.factorsForSatisfaction,
    }

    barriersOfJob = {
      covidAffected: model.barriersOfJob.covidAffected,
      explanation: model.barriersOfJob.explanation,
      barriers: model.barriersOfJob.barriers,
      // secondBarriers: model.barriersOfJob.secondBarriers,
    }

    employmentHistory = {
      firstRecentJob: model.employmentHistory.firstRecentJob,
      secondRecentJob: model.employmentHistory.secondRecentJob,
      thirdRecentJob: model.employmentHistory.thirdRecentJob,
      noTrainingReason: model.employmentHistory.noTrainingReason,
      catagory: model.employmentHistory.catagory,
      previousExpirence: model.employmentHistory.previousExpirence,
      // secondCatagory: model.employmentHistory.secondCatagory,
    }

    shiftWork = {
      workAlone: model.shiftWork.workAlone,
      workAloneReason: model.shiftWork.workAloneReason,
      careerAssessment: model.shiftWork.careerAssessment,
      commuteForWork: model.shiftWork.commuteForWork,
      commuteLength: model.shiftWork.commuteLength,
      localOrInCommunityWorkshop: model.shiftWork.localOrInCommunityWorkshop,
      noReason: model.shiftWork.noReason,
      shiftWorkOutsideCommunity: model.shiftWork.shiftWorkOutsideCommunity,
      supportGroup: model.shiftWork.supportGroup,
      willingToRelocate: model.shiftWork.willingToRelocate,
      supportForRelocate: model.shiftWork.supportForRelocate,
      workshopsInCommunity: model.shiftWork.workshopsInCommunity,
      typesOfSupportGroup: model.shiftWork.typesOfSupportGroup,
    }

    addictionsSupport = {
      communicateOnBehalf: model.addictionsSupport.communicateOnBehalf,
      infoOnProgram: model.addictionsSupport.infoOnProgram,
      infoNNADAP: model.addictionsSupport.infoNNADAP,
    }

    traditionalEmployment = {
      additionalComments: model.traditionalEmployment.additionalComments,
      skills: model.traditionalEmployment.skills
    }

    newSurvey = {
      id: this.db.createId(),

      applicantID: model.applicantID,
      applicantDate: model.applicantDate,
      applicantSignature: model.applicantSignature,
      workerSignature: model.workerSignature,
      workerDate: model.workerDate,
      filledBy: model.filledBy,
      workerFirstName: model.workerFirstName || '',
      workerLastName: model.workerLastName || '',
      workerUid: model.workerUid,
      verified: model.verified,

      aboutLrrcn,
      demographic,
      languages: model.languages,
      careGiving,
      education,
      safteyTraining: model.safetyTraining,
      license,
      trainingAndEmployment,
      jobSatisfaction,
      barriersOfJob,
      employmentHistory,
      shiftWork,
      addictionsSupport,
      traditionalEmployment,
    }
    return this.db.collection('LRRCNSurvey').doc(newSurvey.id).set(newSurvey);
  }

  getLRRCNSurvey(id) {
    return this.db.collection('LRRCNSurvey', ref => ref.where('id', '==', id));
  }

  getLRRCNSurveyByApplicantId(applicantID) {
    return this.db.collection('LRRCNSurvey', ref => ref.where('applicantID', '==', applicantID));
  }

  getLRRCNSurveyByAdminId(workerID) {
    return this.db.collection('LRRCNSurvey', ref => ref.where('workerUid', '==', workerID));
  }

  getLRRCNSurveys() {
    this.surveyListRef = this.db.collection('LRRCNSurvey');
    return this.surveyListRef;
  }

  updateLRRCNSurvey(model: any) {
    return this.db.collection('LRRCNSurvey').doc(model.id).update(model);
  }

}