import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { SectionSettingService } from 'src/app/services/firebase/section-setting.service';
import { toast } from 'materialize-css';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';
import { DataService } from 'src/app/services/data.service';
import { Router } from '@angular/router';
import { MediaService } from 'src/app/services/api/media.service';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-add-section',
  templateUrl: './add-section.component.html',
  styleUrls: ['./add-section.component.css']
})

export class AddSectionComponent implements OnInit {

  themeColor: any;
  defaultThemeColor = environment.appTheme.themeColor;
  appName = environment.firstNation.displayName;
  sectionName: string;
  model: any;
  sectionForm: UntypedFormGroup;
  submenu = false;
  sections: any;
  searchQuery: string = "";
  filteredIcons: string[] = [];
  selectedIcon: string;

  allIcons: string[];
  allSelected = false;
  sectionId: any;
  fromWhere: any;

  cardView = true;
  logoUrl: any;
  bgImage = false;
  defaultBgColor = '#FFFFFF';
  defaultSectionColor = '#000000';

  openDeleteModal = false;
  modalData: any;

  constructor(private appSettingsService: AppSettingsService,
    private sectionService: SectionSettingService,
    private fb: UntypedFormBuilder,
    private location: Location,
    private router: Router,
    private dataService: DataService,
    private mediaService: MediaService) {
    this.themeColor = this.appSettingsService.getAppThemeColor() ? this.appSettingsService.getAppThemeColor() : this.defaultThemeColor;
    this.allIcons = this.dataService.getIcons();
    this.filteredIcons = this.allIcons;
  }

  ngOnInit(): void {
    this.appSettingsService.getAdminMenus().valueChanges().subscribe(menus => {
      if (menus && menus.length > 0) {
        this.sections = [];
        menus.forEach(m => {
          let menu: any = m;
          if (menu.name !== 'Logout' && menu.name !== 'Communication' && menu.name != 'PollsAndSurveys') {
            this.sections.push({
              name: menu.name,
              displayName: menu.displayName
            });
          }
        });
      }
    });

    this.appSettingsService.getMenuName('Settings').valueChanges().subscribe(menus => {
      if (menus && menus.length > 0) {
        let submenus = menus[0].submenus;
        let menu = submenus.filter(m => m.name == 'SectionSetting');
        if (menu && menu.length > 0) this.sectionName = menu[0].displayName;
      }
    });

    this.sectionForm = this.fb.group({
      name: ["CustomSection"],
      displayName: ['', [Validators.required]],
      sectionDescription: [''],
      icon: ['', [Validators.required]],
      options: this.fb.group({
        title: [false],
        description: [false],
        // link: [false],
        imageUpload: [false],
        videoUpload: [false],
        youtubeLink: [false],
        pdfUpload: [false],
      }, { validators: this.atLeastOneTrueValidator }),
      addToAdmin: ['', [Validators.required]],
      addToMenu: [false],
      addToHomePage: [false],
      bgColor: [this.defaultBgColor],
      displayNameColor: [this.defaultSectionColor],
      addToMember: [false]
    });

    const path = this.router.url.split('/');
    if (path[path.length - 1] === 'add-section') {
      this.fromWhere = 'add';
    } else if (path[path.length - 2] === 'edit-section') {
      this.fromWhere = 'edit';
      this.sectionId = path[path.length - 1];
      if (this.sectionId) {
        this.getSectionData();
      } else {
        this.router.navigate(['/admin/dashboard-sub-menu/settings/section-settings']);
      }
    }

    $(document).ready(function () {
      $(".modal").modal();
      M.updateTextFields();
    });
  }

  atLeastOneTrueValidator(formGroup: UntypedFormGroup): { [key: string]: boolean } | null {
    const controls = formGroup.controls;
    // Check if at least one control has a true value
    const isAtLeastOneTrue = Object.values(controls).some((control: AbstractControl) => control.value === true);
    return isAtLeastOneTrue ? null : { 'atLeastOneTrue': true };
  }

  get displayName() { return this.sectionForm.get('displayName'); }
  get sectionDescription() { return this.sectionForm.get('sectionDescription'); }
  get icon() { return this.sectionForm.get('icon'); }
  get addToAdmin() { return this.sectionForm.get('addToAdmin'); }
  get addToHome() { return this.sectionForm.get('addToHomePage'); }

  // ===================================== Icon =============================

  getSectionData() {
    this.sectionService.getSectionById(this.sectionId).valueChanges().subscribe(sec => {
      if (sec) {
        this.model = sec;

        this.sectionForm.setValue({
          name: this.model.name,
          displayName: this.model.displayName,
          sectionDescription: this.model.sectionDescription,
          icon: this.model.icon,
          options: {
            title: this.model.options.title,
            description: this.model.options.description,
            //link: this.model.options.link,
            imageUpload: this.model.options.imageUpload,
            videoUpload: this.model.options.videoUpload,
            youtubeLink: this.model.options.youtubeLink,
            pdfUpload: this.model.options.pdfUpload,
          },
          addToAdmin: this.model.addToAdmin,
          addToMenu: this.model.addToMenu,
          addToHomePage: this.model.addToHomePage,
          bgColor: this.model.bgColor ? this.model.bgColor : this.defaultBgColor,
          displayNameColor: this.model.displayNameColor ? this.model.displayNameColor : this.defaultSectionColor,
          addToMember: this.model.addToMember,
        });

        if (this.model.icon) this.selectedIcon = this.model.icon;
        if (this.model.logoUrl) {
          this.logoUrl = this.model.logoUrl;
          this.bgImageYes();
        }
        // if (this.model.addToHomePage) {
        //   this.sectionForm.addControl('bgColor', new FormControl(this.model.bgColor));
        //   this.sectionForm.addControl('displayNameColor', new FormControl(this.model.displayNameColor));
        // }
        if (this.model.addToAdmin) {
          if (this.model.addToAdmin !== 'dashboard') this.submenu = true;
        }

        const allValuesAreTrue = Object.values(this.model.options).every(value => value === true);
        if (allValuesAreTrue) this.allSelected = true;

        $(document).ready(function () {
          $(".modal").modal();
          M.updateTextFields();
        });
      }
    });

  }

  openIconPickerModal(): void {
    $(".modal").modal();
    $("#iconPickerModal").modal("open");
  }

  filterIcons(): void {
    this.filteredIcons = this.allIcons.filter((icon) =>
      icon.toLowerCase().includes(this.searchQuery.toLowerCase())
    );
  }

  selectIcon(newIcon: string): void {
    this.selectedIcon = newIcon;
  }

  saveSelectedIcon(selectedIcon: string): void {
    if (selectedIcon) {
      this.icon.setValue(selectedIcon);
    }
  }

  deleteSelectedIcon() {
    this.selectedIcon = null;
    this.icon.setValue('');
  }

  closeModal() {
    $(".modal").modal("close");
  }

  selectAll() {
    this.allSelected = !this.allSelected;
    if (this.allSelected) {
      this.sectionForm.get('options').setValue({
        title: true,
        description: true,
        // link: true,
        imageUpload: true,
        videoUpload: true,
        youtubeLink: true,
        pdfUpload: true,
      });
    } else {
      this.sectionForm.get('options').setValue({
        title: false,
        description: false,
        //link: false,
        imageUpload: false,
        videoUpload: false,
        youtubeLink: false,
        pdfUpload: false,
      });
    }
  }

  bgImageYes() {
    this.bgImage = true;
  }

  bgImageNo() {
    this.bgImage = false;
  }

  handlelogoInput(files: FileList) {
    if (files) {
      this.mediaService.UploadBinImage(files[0]).subscribe(upload => {
        if (upload) {
          this.logoUrl = upload.imgLink;
        }
      });
    }
  }

  deleteImage() {
    this.logoUrl = '';
  }

  addToDashboard() {
    this.addToAdmin.setValue('dashboard');
    this.submenu = false;
  }

  addAsSubmenu() {
    this.submenu = true;
  }

  saveSection(values) {
    let model: any = {
      name: ["CustomSection"],
      displayName: values.displayName,
      sectionDescription: values.sectionDescription,
      icon: values.icon,
      logoUrl: this.logoUrl ? this.logoUrl : null,
      options: {
        title: values.options.title,
        description: values.options.description,
        imageUpload: values.options.imageUpload,
        videoUpload: values.options.videoUpload,
        youtubeLink: values.options.youtubeLink,
        pdfUpload: values.options.pdfUpload,
      },
      addToMenu: values.addToMenu,
      addToHomePage: values.addToHomePage,
      addToAdmin: values.addToAdmin,
      addToMember: values.addToMember
    }
    if (model.addToHomePage) {
      model.bgColor = values.bgColor;
      model.displayNameColor = values.displayNameColor;
    }

    if (this.fromWhere == 'add') {
      this.sectionService.saveSection(model).then(() => {
        toast({ html: 'Section Successfully Saved!', classes: 'green' });
        this.backClicked();
      });
    } else {
      model.id = this.sectionId;
      this.sectionService.updateSection(model).then(() => {
        toast({ html: 'Section Successfully Updated!', classes: 'green' });
        this.backClicked();
      });
    }
  }

  onDelete() {
    this.modalData = {
      title: 'Delete Section',
      icon: 'warning',
      subTitle: 'Are you sure you want to delete this Section?',
      description: 'The section and its contents will be permanently deleted.',
      confirmation: {
        title: 'Are you really sure you want to delete this section?',
        text: 'Please, be absolutely certain before proceeding, as this action cannot be undone.',
        text2: 'Confirm your decision to proceed with the deletion.'
      }
    }
    this.openDeleteModal = true;
  }

  cancelHandler(valueEmitted) {
    if (valueEmitted) this.openDeleteModal = false;
    $('.modal').modal('close');
  }

  confirmHandler(valueEmitted) {
    if (valueEmitted) this.delete();
    this.openDeleteModal = false;
    $('.modal').modal('close');
  }

  delete() {
    if (this.sectionId) {
      this.sectionService.deleteSection(this.sectionId).then(() => {
        toast({ html: 'Section Successfully Deleted!', classes: 'green' });
        this.backClicked();
      });
    }
  }

  backClicked() {
    this.location.back();
  }

}