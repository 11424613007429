import { Component, OnInit } from '@angular/core';
import { UpdateProfileService } from 'src/app/services/firebase/update-profile.service';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { FamilyCompositionService } from 'src/app/services/api/family-composition.service';
import { MemberService } from 'src/app/services/api/member.service';
import { AddBandMemberModel } from 'src/app/models/AddMemberModel';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { ManageAdminService } from 'src/app/services/api/manage-admin.service';
import { toast } from 'materialize-css';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-members-profile-updates',
  templateUrl: './members-profile-updates.component.html',
  styleUrls: ['./members-profile-updates.component.css']
})
export class MembersProfileUpdatesComponent implements OnInit {

  public dateMask = [/[0-3]/, /[0-9]/, '/', /[0-1]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  public dateformat = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
  public isnMask = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  relationships: any = ["Spouse", "Son", "Daughter", "Grandson", "Granddaughter", "Niece", "Nephew", "Cousin", "Brother", "Sister", "Relative"];

  defaultThemeColor = environment.appTheme.themeColor;
  themeColor: any;
  buttonType: any;
  fontColor = environment.appTheme.adminFontColor;
  loading = false;
  updates: any[];
  profileUpdates: any[];
  famCompUpdates: any[];
  updateDetails: any[];
  showFCReq = false;

  member: any;
  searchMemberForm: UntypedFormGroup;
  noResult = false;
  selectedMember: any;
  relationship = ' ';
  tobeAdded: any = {};
  confirmed: any[] = [];
  searchResult: any[];
  familyMembers: any[];
  message = '';
  addMemberModel: AddBandMemberModel;
  currentUser: any;
  loginId: any;
  famMember: any;
  memberFound: any;
  foundMember = false;
  currentIndex: any;
  memberSelected: any;
  currentUpdate: any;
  showPersonalInfo = true;
  showFamilyComp = true;
  contact = false;
  contactDetail: any = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNo: '',
  }

  matches: any[];
  matchI = 0;
  step1 = true;
  step2 = false;

  constructor(private fb: UntypedFormBuilder,
    private updateProfileService: UpdateProfileService,
    private authService: AuthService,
    private manageAdminService: ManageAdminService,
    private location: Location,
    private familyCompositionService: FamilyCompositionService,
    private memberService: MemberService,
    private settingsService: AppSettingsService
  ) {
    this.themeColor = this.settingsService.getAppThemeColor() ? this.settingsService.getAppThemeColor() : this.defaultThemeColor;
    this.buttonType = this.settingsService.getButtonType() ? this.settingsService.getButtonType() : 'rectangularButton';
  }

  ngOnInit() {
    this.loading = true;
    this.currentUser = this.authService.getGlobalUser();
    if (this.currentUser && this.currentUser.id) {
      this.manageAdminService.getAdmin(this.currentUser.id).subscribe(user => {
        if (user) {
          this.loginId = user[0].loginID;
        }
      });
    }
    this.updateProfileService.getProfileUpdates().valueChanges().subscribe(x => {
      if (x) {
        this.updates = x;
        this.updates = this.updates.sort((d1, d2) => new Date(d2.date.toDate()).getTime() - new Date(d1.date.toDate()).getTime())
        this.profileUpdates = this.updates.filter(u => u.type == "Personal Info");
        this.famCompUpdates = this.updates.filter(u => u.type == "Family Composition");
      }
      this.loading = false;
    });

    this.searchMemberForm = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      dob: [''],
      isn: ['', [Validators.minLength(10), Validators.maxLength(10), Validators.pattern('^[0-9]*$')]],
      clientId: [''],
    });

    this.addMemberModel = new AddBandMemberModel();
    setTimeout(() => {
      $('.modal').modal();
      $('.tabs').tabs();
      $('.collapsible').collapsible();
    }, 25);
  }

  get isn() { return this.searchMemberForm.get('isn'); }

  formatDate(date: Date) {
    if (date) {
      var month = '' + (date.getMonth() + 1);
      var day = '' + date.getDate();
      var year = date.getFullYear();
      if (month.length < 2)
        month = '0' + month;
      if (day.length < 2)
        day = '0' + day;
      return [year, month, day].join('-');
    }
  }

  showDetails(index) {
    this.updateDetails = this.profileUpdates[index].changes;
    setTimeout(() => {
      $('.modal').modal();
      $('#personalInfoModel').modal('open');
    }, 25);
  }

  //*******************************  Family composition **********************************************************
  showFCDetails(index) {
    this.currentUpdate = index;
    this.memberService.getMember(this.famCompUpdates[index].applicantID).subscribe(x => {
      this.member = x[0];
      this.familyCompositionService.getFamilyMember(this.member.current_application).subscribe(data => {
        if (data) {
          this.familyMembers = data;
        }
      });
      setTimeout(() => {
        M.updateTextFields();
        $('.collapsible').collapsible();
      }, 25);
    });
    this.updateDetails = this.famCompUpdates[index].changes;
    this.showFCReq = true;
  }

  searchMember() {
    this.searchMemberForm.reset();
    this.searchResult = null;
    setTimeout(() => {
      M.updateTextFields();
      $('.modal').modal();
      $('#searchModal').modal('open');
      $('.tooltipped').tooltip();
    }, 25);
  }

  search(values) {
    this.memberService.findMemberByName(values).subscribe(resp => {
      if (resp && resp.length > 0) {
        this.searchResult = resp;
        this.noResult = false;
        if (resp.length == 0) this.noResult = true;
        setTimeout(() => {
          $('.tooltipped').tooltip();
        }, 25);
      } else {
        this.noResult = true;
      }
    });
  }

  selectMember(values) {
    this.memberService.getMember(values.applicantID).subscribe(x => {
      if (x && x.length > 0) {
        this.selectedMember = x[0];
      }
      this.relationship = null;
      setTimeout(() => {
        $('.modal').modal('close');
        $('.modal').modal();
        $('#relationModal').modal('open');
        M.updateTextFields();
        $('select').formSelect();
        $('.tooltipped').tooltip();
      }, 25);
    })
  }

  onRelationshipChange(value) {
    this.relationship = value;
  }

  addSelected(values) {
    var today: any = new Date();
    var age = Math.floor((today - new Date(values.DOB).getTime()) / 3.15576e+10);
    var ageType = "";
    if (age >= 0 && age <= 11) ageType = "0-11";
    if (age >= 12 && age <= 17) ageType = "12-17";
    if (age >= 18 && age <= 19) ageType = "18-19";
    if (age > 19) ageType = "Adult";
    const model = {
      applicantIdOfHead: this.member.applicantID,
      applicantIDOfChild: values.applicantID,
      firstName: values.GivenName,
      middleName: values.middle_name,
      lastName: values.LastName,
      ageType: ageType,
      isn: values.IndianStatusNo,
      relationship: this.relationship,
      familyCompId: this.member.current_family_comp,
    }

    let isMember = this.familyMembers.filter(x => x.applicantID == model.applicantIDOfChild);
    if (isMember && isMember.length > 0) {
      toast({ html: `${model.firstName} ${model.lastName} is already a member of this family Composition.`, classes: 'red' });
    }
    else {
      this.familyCompositionService.addFamilyMember(model).subscribe(x => {
        if (x) {
          toast({ html: 'Member Added to the family composition', classes: 'green' });
          this.confirmed.push(values);
        } else {
          toast({ html: 'Unable to Added to the family composition. Please Try Again.', classes: 'red' });
        }
      });
    }
    this.closeModal();
  }

  goBackToSearch() {
    setTimeout(() => {
      $('#searchModal').modal('open');
    }, 25);
  }

  selected(index) {
    this.currentIndex = index;
    this.tobeAdded = this.updateDetails[index];
    if (!this.tobeAdded.confirmed) {
      const model = {
        firstName: this.tobeAdded.firstName ? this.tobeAdded.firstName : '',
        lastName: this.tobeAdded.lastName ? this.tobeAdded.lastName : '',
        dob: this.tobeAdded.dob ? this.formatDate(this.tobeAdded.dob.toDate()) : '',
        isn: this.tobeAdded.isn ? this.tobeAdded.isn : '',
      }
      this.memberService.findMemberByName(model).subscribe(res => {
        if (res && res.length > 0) {
          this.matches = res;
          this.step1 = false;
          this.step2 = true;
        } else {
          this.matches = [];
          this.step1 = false;
          this.step2 = true;
        }
      });
    }
  }

  previousMatch() {
    this.matchI -= 1;
  }

  nextMatch() {
    this.matchI += 1;
  }

  setUser(id) {
    this.loading = true;
    this.memberService.getMember(id).subscribe(x => {

      if (x && x.length > 0) {
        this.memberFound = x[0];
        let isMember = this.familyMembers.filter(x => x.applicantID == id);
        if (isMember && isMember.length > 0) {
          this.message = `${this.memberFound.GivenName} ${this.memberFound.LastName} is already a member of this family Composition.`;
          $('.modal').modal();
          $('#messageModal').modal('open');
        } else if (this.memberFound.role == 'H of H') {
          this.message = `${this.memberFound.GivenName} ${this.memberFound.LastName} is currently listed as H 0f H on application ${this.memberFound.current_application}.`;
          $('.modal').modal();
          $('#messageModal').modal('open');
        }
        else if (this.memberFound.current_family_comp != this.member.current_family_comp) {
          this.message = `${this.memberFound.GivenName} ${this.memberFound.LastName} is currently listed under a different family composition.`;
          $('.modal').modal();
          $('#messageModal').modal('open');
        }
        else {
          this.confirmMembers(this.memberFound);
        }
      }
      this.loading = false;
    });
  }
  
  confirmMembers(values) {
    var today: any = new Date();
    if (this.tobeAdded.dob) {
      var age = Math.floor((today - this.tobeAdded.dob.toDate().getTime()) / 3.15576e+10);
      var ageType;
      if (age >= 0 && age <= 11) ageType = "0-11";
      if (age >= 12 && age <= 17) ageType = "12-17";
      if (age >= 18 && age <= 19) ageType = "18-19";
      if (age > 19) ageType = "Adult";
    } else {
      ageType = ''
    };
    const model = {
      applicantIdOfHead: this.member.applicantID,
      applicantIDOfChild: values.applicantID,
      firstName: values.GivenName,
      middleName: values.middle_name,
      lastName: values.LastName,
      ageType: ageType,
      isn: values.IndianStatusNo,
      relationship: this.tobeAdded.relationship,
      familyCompId: this.member.current_family_comp,
    }
    this.familyCompositionService.addFamilyMember(model).subscribe(x => {
      if (x) {
        toast({ html: 'Member Added to the family composition', classes: 'green' });
        this.confirmed.push(values);
        this.updateDetails[this.currentIndex].confirmed = true;
        const index = this.updateDetails.indexOf(this.tobeAdded);
        if (index != -1) {
          this.updateDetails.splice(index, 1);
        }
      } else {
        toast({ html: 'Unable to Added to the family composition. Please Try Again.', classes: 'red' });
      }
      this.closeModal();
      this.back();
    });
  }

  addMember() {
    this.addMemberModel.GivenName = this.tobeAdded.firstName ? this.tobeAdded.firstName : '';
    this.addMemberModel.LastName = this.tobeAdded.lastName ? this.tobeAdded.lastName : '';
    this.addMemberModel.middle_name = this.tobeAdded.middleName ? this.tobeAdded.middleName : '';
    this.addMemberModel.DOB = this.tobeAdded.dob ? this.tobeAdded.dob.toDate() : '';
    this.addMemberModel.IndianStatusNo = this.tobeAdded.isn ? this.tobeAdded.isn : '';
    this.addMemberModel.date_added = new Date();
    this.addMemberModel.Officer = this.loginId ? this.loginId : '';

    var values = {
      applicantID: 0,
      GivenName: this.addMemberModel.GivenName,
      LastName: this.addMemberModel.LastName,
      middle_name: this.addMemberModel.middle_name,
      IndianStatusNo: this.addMemberModel.IndianStatusNo,
      DOB: this.addMemberModel.DOB,
    }

    this.memberService.AddMember(this.addMemberModel).subscribe(res => {
      if (res) {
        values.applicantID = res;
        toast({ html: 'Member Added Successfully!', classes: 'green' });
        this.memberFound = values;
        this.closeModal();
        this.message = 'Member Added Successfully!';
        $('.modal').modal();
        $('#messageModal').modal('open');
      }
    });
  }

  declineUpdate() {
    this.updateDetails[this.currentIndex].confirmed = false;
    toast({ html: 'Changes Declined!', classes: 'red' });
    this.back();
  }

  cancel() {
    $('.modal').modal('close');
  }

  closeModal() {
    $('.modal').modal('close');
  }

  saveUpdate() {
    const update = this.famCompUpdates[this.currentUpdate];
    this.updateProfileService.saveFamilyCompUpdates(update.id, update.changes);
    toast({ html: 'Family composition Updated Successfully!', classes: 'green' });
    this.confirmed = [];
    this.backToUpdates();
  }

  deleteUpdate() {
    const update = this.famCompUpdates[this.currentUpdate];
    this.updateProfileService.deleteFamilyCompUpdates(update.id);
    toast({ html: 'Family composition Deleted!', classes: 'green' });
    this.backToUpdates();
  }

  contactClient() {
    if (this.member) {
      this.contactDetail.firstName = this.member.GivenName;
      this.contactDetail.lastName = this.member.LastName;
      this.contactDetail.email = this.member.Email;
      this.contactDetail.phoneNo = this.member.cell_phone_num;
      this.contact = true;
    }
  }

  contactHandler(valueEmitted) {
    if (valueEmitted.status == null) {
      return;
    }
    if (valueEmitted.status) {
      toast({ html: 'Message sent successfully!', classes: 'green' });
    } else {
      toast({ html: 'Unable to send Message!', classes: 'red' });
    }
    this.contact = false;
  }

  back() {
    this.step1 = true;
    this.step2 = false;
    setTimeout(() => {
      M.updateTextFields();
      $('.collapsible').collapsible();
    }, 25);
  }

  backClicked() {
    this.location.back();
  }

  backToUpdates() {
    this.showFCReq = false;
    setTimeout(() => {
      $('.modal').modal();
      $('.tabs').tabs();
    }, 25);
  }

}
