<div class="col s12">
    <div class="body-h">

        <div class="header-setting b-m-2">
            <div class="col l12 m12 s12">
                <h4 *ngIf="!editName">
                    <span *ngIf="sectionName">{{sectionName}}</span>
                    <span *ngIf="!sectionName">Applications</span>
                    <i class="material-icons edit-name " [ngStyle]="{'color': themeColor}" (click)="editNameClicked()">edit</i>
                </h4>
                <div class="input-field" *ngIf="editName">
                    <input type="text" [(ngModel)]="sectionName" class="left col s6 validate" autocomplete="off" />
                    <button type="button" class="btn ll-m-5" [ngStyle]="{'background-color': themeColor}" (click)="saveName()">Save</button>
                  </div>
                <p class="col s12 bold-100 sub-title-section3">This is the menu items that will be displayed when Admins and
                    employees log in.</p>
            </div>
        </div>

        <main class="StickyContent white ">

            <app-firebase-loading *ngIf="loading"></app-firebase-loading>

            <div class="row col s12" *ngIf="!applications && !loading">
                <div class="row center-align card white">
                    <i class="large material-icons red-text">info</i>
                    <h6 class="red-text"><strong>No Applications Found.</strong></h6>
                    <br />
                    <button class="waves-effect waves-light btn col s12 m8 offset-m2 green"
                        [routerLink]="['/admin/applications/create']" style="margin-bottom: 15px;"><i
                            class="material-icons left">dashboard_customize</i>Create Application
                    </button>
                </div>

                <div class="row">
                    <button class="col s12 btn wave-effect" (click)="backToPreviousePage()">
                        <i class="material-icons left">house</i>Back To Previous Page
                    </button>
                </div>
            </div>

            <div class="row col s12" *ngIf="applications">
                <div class="col s12 m6 l4" *ngFor="let app of applications;let i=index;">
                    <div class="card white hoverable sticky-action" style="height: 375px;">

                        <div class="card-content">
                            <div class="row center">
                                <img [src]="app.logo" class="activator" width="150px;" height="150">
                                <!-- <strong class="black-text card-title">{{app.name | uppercase}}</strong> -->
                            </div>
                            <span class="card-title activator grey-text text-darken-4"><strong
                                    class="black-text">{{app.name |
                                    uppercase}}</strong><i class="material-icons right">more_vert</i></span>
                        </div>

                        <div class="card-reveal">
                            <span class="card-title grey-text text-darken-4"><strong class="black-text">{{app.name |
                                    uppercase}}</strong><i class="material-icons right">close</i></span>
                            <p>{{app.description}}</p>
                        </div>

                        <div class="card-action">
                            <a class="col s12" [routerLink]="['/admin/applications/edit', app.name]"
                                style="cursor: pointer; margin-bottom: 15px;"><i class="material-icons right">
                                    edit
                                </i>Edit</a>

                            <a class="col s12 green-text" [routerLink]="['/admin/applications/preview', app.name]"
                                style="cursor: pointer; margin-bottom: 25px;"><i class="material-icons right">
                                    visibility
                                </i>Preview</a>
                        </div>
                    </div>
                </div>
            </div>

        </main>

    </div>
</div>