import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { GovernanceService } from 'src/app/services/firebase/governance.service';
import { environment } from 'src/environments/environment';

declare var $: any;
@Component({
  selector: 'app-governance',
  templateUrl: './governance.component.html',
  styleUrls: ['./governance.component.css']
})
export class GovernanceComponent implements OnInit {

  model: any;
  selectedOption: any;
  nation = environment.firstNation.displayName;
  themeColor = environment.appTheme.themeColor;
  defaultThemeColor = environment.appTheme.themeColor;
  setting: any;

  bodyBgColor: any;
  defaultBgColor = '#ffffff';

  constructor(private governanceService: GovernanceService,
    private router: Router,
    public appSettingService: AppSettingsService) { }

  ngOnInit() {
    if (localStorage.getItem('selectedOption')) {
      this.selectedOption = localStorage.getItem('selectedOption');
      this.governanceService.getGovernanceList().valueChanges().subscribe(gov => {
        if (gov.length > 0) {
          this.model = gov[0];
          if (this.selectedOption === 'Chief' && this.model.chiefs[0]) {
            this.router.navigate(['/governance-chief']);
          }
          else if (this.selectedOption === 'Councillors' && this.model.councillors[0]) {
            this.router.navigate(['/governance-detail', this.model.councillors[0].applicantID]);
          }
          setTimeout(() => {
            $('.collapsible').collapsible();
            $('.tooltipped').tooltip();
            $('.tabs').tabs();
          }, 50);
        }
      });
    }
  }

}
