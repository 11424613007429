<div class="row">
  <br />
  <h6 class=" center-align" *ngIf="entitlement[0]"> <strong> My Entitlement of {{ entitlement[0].Month }}/{{
      entitlement[0].Year }} </strong> </h6>

  <div class="section"></div>

  <div class="row col s12">
    <div class="col l1 "></div>
    <div class="col s12 m12 l10">
      <app-entitlement *ngIf="entitlement" [model]="entitlement"></app-entitlement>
    </div>
    <div class="col l1"></div>
  </div>
</div>

<div class="row col s12">
  <div class="col l1 "></div>
  <div class="col s12 m12 l10">
    <a [ngClass]="'col s12 btn waves-effect ' + themeColor" (click)="backClicked()"><i
        class="material-icons left">house</i> Back To Previous Page</a>
  </div>
  <div class="col l1"></div>
</div>