<app-firebase-loading *ngIf="loading"></app-firebase-loading>

<div id="emailModal" class="modal">
    <div class="modal-content">
        <h4>Send Email</h4>
        <form [formGroup]="emailForm" (ngSubmit)="Send(emailForm.value)">
            <div class="row">
                <div class="input-field col s12 l12">
                    <div class="chips chips-placeholder chips-initial chips-autocomplete" id="emails"></div>
                    <div *ngIf="invalidEmail">
                        <small class="red-text errMsg"> Invalid Email </small>
                    </div>
                </div>

                <div class="input-field col s12 l12">
                    <input id="subject" type="text" formControlName='subject' class="validate" autocomplete="off" placeholder="Mandatory"
                        style="text-transform: capitalize;" />
                    <label for="subject">Subject<span class="red-text">*</span></label>
                    <div *ngIf="(subject.dirty || subject.touched) && subject.invalid">
                        <small *ngIf="subject.errors.required" class="red-text errMsg"> The Subject is required.
                        </small>
                    </div>
                </div>

                <div class="input-field col s12 l12">
                    <textarea id="body" type="text" formControlName='body' autocomplete="off" placeholder="Optional"
                        style="text-transform: capitalize;" class="validate materialize-textarea" style="height: 100px;"
                        maxlength="500"> </textarea>
                    <label for="body">Body</label>
                </div>
            </div>

            <div class="row col s12" *ngIf="fileList?.length >0" >
                <h6><strong>Attached Files</strong></h6>
                <div class="row col s12 grey lighten-3" *ngFor="let file of fileList;let i=index" style="padding-top: 5px; padding-bottom: 5px; cursor: pointer;">
                    <div class="col s10">
                        <a style="font-size:medium;">
                            <i *ngIf="file.type == 'PDF'" class="material-icons red-text">
                                picture_as_pdf
                            </i>
                            <i *ngIf="file.type == 'Image'" class="material-icons red-text">
                                image
                            </i>
                            <strong style="margin-left: 15px;">
                                <a [href]="file.url" download target="_blank">{{file.name}}</a>
                              
                            </strong>

                        </a>
                    </div>
                    <div class="col s2 right-align">
                        <a (click)="removeFile(index)"><i class="material-icons red-text">
                                clear
                            </i></a>
                    </div>
                </div>
            </div>

            <div class="row">
                <div class="input-field col s12 center">
                    <button type="button" id="attachFile" class='col s12 btn waves-effect blue'
                        style="margin-bottom: 15px;">
                        <i class="material-icons right">attach_file</i> Attach Files
                    </button>
                    <input class="hide" type="file" name="files" id="files" multiple accept="image/*, application/pdf"
                        (change)="handleFileInput($event.target.files)">

                    <button type="submit" [disabled]="!emailForm.valid || emailList?.length==0 "
                        class='col s12 btn waves-effect green' style="margin-bottom: 15px;">
                        <i class="material-icons right">send</i> Send Email
                    </button>
                    <button type="button" class='col s12 btn waves-effect red' (click)="cancel()">
                        <i class="material-icons right">close</i> Cancel
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>