import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AboutusService } from 'src/app/services/firebase/aboutus.service';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { FireMembersService } from 'src/app/services/firebase/fire-members.service';
import { SessionsMonitorService } from 'src/app/services/firebase/sessions-monitor.service';
import { StaticContentService } from 'src/app/services/firebase/static-content.service';
import { toast } from 'materialize-css';
import { environment } from 'src/environments/environment';

declare var $: any;

@Component({
  selector: 'app-app-stats',
  templateUrl: './app-stats.component.html',
  styleUrls: ['./app-stats.component.css']
})

export class AppStatsComponent implements OnInit {

  themeColor: any;
  defaultThemeColor = environment.appTheme.themeColor;
  registredMembers: any[];
  staticContentList: any[];
  aboutUsList: any[];
  sessionsList: any [];
  groupedSessionsArray: any[];
  filtredSessionsArray: any[];
  sectionName: string;
  submenus: any[];
  editName = false;

  constructor(
    private fireMembersService: FireMembersService,
    private location: Location,
    private staticContent: StaticContentService,
    private aboutUsService: AboutusService,
    private sessionService: SessionsMonitorService,
    private router: Router,
    public appSettingsService: AppSettingsService) {
      this.themeColor = this.appSettingsService.getAppThemeColor() ? this.appSettingsService.getAppThemeColor() : this.defaultThemeColor;
     }

  ngOnInit() {
    this.appSettingsService.getMenuName('Stats').valueChanges().subscribe(menus => {
      if (menus && menus.length > 0) {
        this.submenus = menus[0].submenus;
        let menu = this.submenus.filter(m => m.name == 'AppStatistics');
        if (menu && menu.length > 0) this.sectionName = menu[0].displayName;
      }
    });

    this.fireMembersService.getMembersList().valueChanges().subscribe(m => {
      if (m) {
        if (m.length > 0) {
          this.registredMembers = m;
        }
      }
    });

    this.staticContent.getContentList().valueChanges().subscribe(m => {
      if (m) {
        if (m.length > 0) {
          this.staticContentList = m;
        }
      }
    });

    this.aboutUsService.getSectionsList().valueChanges().subscribe(m => {
      if (m) {
        if (m.length > 0) {
          this.aboutUsList = m;
        }
      }
    });

    this.sessionService.getAllUsersSessionsList().valueChanges().subscribe(s => {
      if (s) {
        if (s.length > 0 ) {
          this.sessionsList = s;
          this.filtredSessionsArray = this.sessionsList.filter(ss => ss.sessionStart.toDate() >= this.getLastWeek());
          this.filtredSessionsArray = this.filtredSessionsArray.sort(
            (a, b) => {
             return b.sessionStart.toDate() - a.sessionStart.toDate();
            }
          );
          const groups = this.filtredSessionsArray.reduce((groups, sessionList) => {
            const date = sessionList.sessionStart.toDate().toDateString();
            if (!groups[date]) {
              groups[date] = [];
            }
            groups[date].push(sessionList);
            return groups;
          }, {});

          this.groupedSessionsArray = Object.keys(groups).map((date) => {
            return {
              date,
              sessions: groups[date]
            };
          });
        }
      }
    });
  }

  editNameClicked() {
    this.editName = true;
  }

  saveName() {
    let index = this.submenus.findIndex(m => m.name == 'AppStatistics');
    this.submenus[index].displayName = this.sectionName;
    this.appSettingsService.UpdateMenu('Stats', this.submenus).then((update) => {
      this.editName = false;
      toast({ html: `${update}`, classes: 'green' });
    }).catch((error) => {
      toast({ html: `${error}`, classes: 'red' });
    });
  }

  
  getNumberOfRegistredMembers() {
    return this.registredMembers.filter(m => m.role === 'Member').length;
  }

  getNumberOfRegistredAdmins() {
    return this.registredMembers.filter(m => m.role === 'Admin').length;
  }

  getNumberOfRegistredEmployees() {
    return this.registredMembers.filter(m => m.role === 'Department-Employee').length;
  }

  getNumberOfNewsEvents() {
    return this.staticContentList.length;
  }

  getNumberOfPublicNewsEvents() {
    return this.staticContentList.filter(m => m.visibility === 'Public').length;
  }

  getNumberOfPrivateNewsEvents() {
    return this.staticContentList.filter(m => m.visibility === 'Private').length;
  }

  getNumberOfAboutUSSections() {
    return this.aboutUsList.length;
  }

  getNumberOfSessionsByDay(sessions: any[]) {
    return sessions.length;
  }

  getNumberOfComputerDevice() {
    return this.sessionsList.filter(s => s.device === 'Desktop').length;
  }

  getNumberOfMobileDevice() {
    return this.sessionsList.filter(s => s.device === 'Mobile').length;
  }

  getNumberOfIphone() {
    return this.sessionsList.filter(s => s.deviceType === 'iPhone').length;
  }

  getNumberOfAndroid() {
    return this.sessionsList.filter(s => s.deviceType === 'Android').length;
  }

  getLastWeek() {
    const today = new Date();
    const lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7);
    return lastWeek;
  }

  navigate(item) {
    if (item == 'AppStatistics') this.router.navigate(['admin/dashboard-sub-menu/stats/app-statistics']);
    else if (item == 'DepartmentStats') this.router.navigate(['admin/dashboard-sub-menu/stats/dep-statistics']);
    else if (item == 'Demographics') this.router.navigate(['admin/dashboard-sub-menu/stats/demographics']);
    else if (item == 'Charts' || 'Analytics' || 'Reports' ) {
      $('.modal').modal();
      $('#underConstructionModal').modal('open');
    }
  }

  backClicked(){
    this.location.back();
  }
}
