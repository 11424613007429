import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { toast } from 'materialize-css';
import { Children, Document, PSSSPApplicationModel } from 'src/app/models/PSSPApplicationModel';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { MemberService } from 'src/app/services/api/member.service';
import { FamilyCompositionService } from 'src/app/services/api/family-composition.service';
import { DatePipe } from '@angular/common';
import SignaturePad from 'signature_pad';
import { MediaService } from 'src/app/services/api/media.service';
import { PssspApplicationService } from 'src/app/services/firebase/psssp-application.service';
import { Location } from '@angular/common';
import { ImageModel } from 'src/app/models/ImageModel';

const pdfMake = require('pdfmake/build/pdfmake.js');
const pdfFonts = require('pdfmake-unicode/dist/pdfmake-unicode.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-psssp-application',
  templateUrl: './psssp-application.component.html',
  styleUrls: ['./psssp-application.component.css']
})

export class PSSSPApplicationComponent implements OnInit {

  nationofUse = environment.firstNation.name;
  nationName = environment.firstNation.displayName;
  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  provinceOfUse = environment.firstNation.province;

  provinces = [{ name: 'Alberta', code: 'AB' },
  { name: 'British Columbia', code: 'BC' },
  { name: 'Manitoba', code: 'MB' },
  { name: 'New Brunswick', code: 'NB' },
  { name: 'Newfoundland and Labrador', code: 'NL' },
  { name: 'Nova Scotia', code: 'NS' },
  { name: 'Ontario', code: 'ON' },
  { name: 'Prince Edward Island', code: 'PE' },
  { name: 'Quebec', code: 'QC' },
  { name: 'Saskatchewan', code: 'SK' }
  ];
  maritalStatus: any = [{ name: 'Single', value: '0' }, { name: 'Single-Parent', value: '1' }, { name: 'Married', value: '2' }, { name: "Common Law", value: '3' }];
  fileTypes: any = ['ID Documents', 'Transcripts/Marks', 'Letter of Acceptance', 'Registration of classes', 'Hospitalization Card', 'Treaty/Status card', 'Banking Information', 'Letter of Intent', 'Diploma', 'Certificate'];

  public postalCodeMask = [/[A-Za-z]/, /[0-9]/, /[A-Za-z]/, ' ', /[0-9]/, /[A-Za-z]/, /[0-9]/];
  public phoneNumberMask = ['(', /[0-9]/, /[0-9]/, /[0-9]/, ')',
    ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  public dateMask = [/[0-3]/, /[0-9]/, '/', /[0-1]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  public SINMask = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  public treatyNomask = [/[0-9]/, /[0-9]/, /[0-9]/];
  public dateformat = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
  public yearMask = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];

  loading = false;
  institutionsList: any[];
  programsList: any[];
  branches: any[] = [];
  currentUser: any;
  member: any;

  step = 0;
  spouse: any;
  familyMembers: any;
  studentInfoForm: UntypedFormGroup;
  spouseInfoForm: UntypedFormGroup;
  dependentsInfoForm: UntypedFormGroup;
  assistanceReqForm: UntypedFormGroup;
  declarationForm: UntypedFormGroup;
  otherInfoForm: UntypedFormGroup;

  addChildren = false;
  displayChildren = false;
  maritalStatusSelected;
  maritalStatusValue = '';
  signed = false;
  canvas: any;
  signaturePad: SignaturePad;
  showSignaturePad = false;
  showUpload = false;
  binDoc: File;
  fileName: string;
  fileType: string;
  filesUploaded = false;
  editMode = false;
  toEdit: number;
  model: PSSSPApplicationModel;
  childrenModel: Children;
  documentModel: Document;
  docsUploaded = false;
  dates = [];
  base64Image: any;
  otherInstitution = false;
  otherProgram = false;
  dobSet = false;
  dobSelected: any;
  childDobSet = false;
  childdobSelected: any;
  institutionId: any;

  constructor(private fb: UntypedFormBuilder,
    private location: Location,
    private authService: AuthService,
    private memberService: MemberService,
    private familyCompositionService: FamilyCompositionService,
    private mediaService: MediaService,
    private pssspService: PssspApplicationService) { }

  ngOnInit() {
    const province = this.provinces.filter(prov => prov.name == this.provinceOfUse);
    this.provinceOfUse = province[0].code;
    for (var i = 0; i < 5; i++) {
      this.dates.push({ selected: '', set: false })
    };
    this.pssspService.getInstitutions().valueChanges().subscribe(x => {
      if (x) {
        this.institutionsList = x.sort((a, b) => (a.name > b.name) ? 1 : ((b.name > a.name) ? -1 : 0));
      }
    });
    this.pssspService.getPrograms().valueChanges().subscribe(x => {
      if (x) {
        this.programsList = x.sort((a, b) => (a.program > b.program) ? 1 : ((b.program > a.program) ? -1 : 0));
      }
    });
    this.currentUser = this.authService.getGlobalUser();
    if (this.currentUser) {
      this.memberService.getMember(this.currentUser.applicantID).subscribe(x => {
        this.member = x[0];
        if (this.member.MaritalStatus) this.maritalStatusValue = this.maritalStatus[Number(this.member.MaritalStatus)].name;
      });
    }
    this.studentInfoForm = this.fb.group({
      first_name: ['', [Validators.required, Validators.minLength(1)]],
      middle_name: [''],
      last_name: ['', [Validators.required, Validators.minLength(1)]],
      street: [''],
      apt: [''],
      city: ['', [Validators.required]],
      province: [this.provinceOfUse, [Validators.required]],
      postal_code: ['', [Validators.required]],
      home_number: ['', []],
      mobile: ['', [Validators.required]],
      dob: ['', [this.isValidDate]],
      SIN: ['', [Validators.minLength(9), Validators.maxLength(9), Validators.pattern('^[0-9]*$')]],
      student_no: [''],
      treaty_no: [''],
      email: ['', [Validators.email]],
      marital_status: ['', [Validators.required]],
    });

    this.spouseInfoForm = this.fb.group({
      spouse_first_name: ['', [Validators.required, Validators.minLength(1)]],
      spouse_middle_name: [''],
      spouse_last_name: ['', [Validators.required, Validators.minLength(1)]],
      spouse_live: ['', [Validators.required]],
      spouse_employed: ['', [Validators.required]],
    });

    this.dependentsInfoForm = this.fb.group({
      add_children: ['', [Validators.required]],
      childrens: this.fb.array([]),
      child_first_name: ['', []],
      child_last_name: ['', []],
      child_dob: ['', []],
      child_age: [''],
      child_grade: [''],
      child_school: [''],
      comments: ['']
    });

    this.dependentsInfoForm.get('add_children').valueChanges.subscribe(value => {
      if (value == 'yes') {
        this.dependentsInfoForm.get('child_first_name').setValidators(Validators.required);
        this.dependentsInfoForm.get('child_last_name').setValidators(Validators.required);
        this.dependentsInfoForm.get('child_dob').setValidators([Validators.required, this.isValidDate]);
      } else {
        this.dependentsInfoForm.get('child_first_name').clearValidators();
        this.dependentsInfoForm.get('child_last_name').clearValidators();
        this.dependentsInfoForm.get('child_dob').clearValidators();
      }
      this.dependentsInfoForm.get('child_first_name').updateValueAndValidity();
      this.dependentsInfoForm.get('child_last_name').updateValueAndValidity();
      this.dependentsInfoForm.get('child_dob').updateValueAndValidity();
    });

    this.assistanceReqForm = this.fb.group({
      type: ['', [Validators.required]],
      institution: ['', [Validators.required]],
      otherInstitution: [''],
      location: [''],
      program: ['', [Validators.required]],
      otherProgram: [''],
      start_date: [''],
      end_date: [''],
      length: [''],
      years_completed: [''],
      terms: this.fb.group({
        fall: [false],
        winter: [false],
        inter_session: [false],
        summer_session: [false],
      }),
    });

    this.assistanceReqForm.get('institution').valueChanges.subscribe(value => {
      if (value == 'others') {
        this.assistanceReqForm.get("otherInstitution").setValidators([Validators.required]);
      } else {
        this.assistanceReqForm.get('otherInstitution').clearValidators();
      }
      this.assistanceReqForm.get('otherInstitution').updateValueAndValidity();
    });

    this.assistanceReqForm.get('program').valueChanges.subscribe(value => {
      if (value == 'others') {
        this.assistanceReqForm.get("otherProgram").setValidators([Validators.required]);
      } else {
        this.assistanceReqForm.get('otherProgram').clearValidators();
      }
      this.assistanceReqForm.get('otherProgram').updateValueAndValidity();
    });

    this.declarationForm = this.fb.group({
      name: ['', [Validators.required]],
      signature: ['', [Validators.required]],
      date: [''],
    });

    this.otherInfoForm = this.fb.group({
      upload_documents: ['', [Validators.required]],
      documents: this.fb.array([]),
      document: [''],
      document_type: [''],
      document_name: [''],
      document_link: ['']
    });

    this.otherInfoForm.get('upload_documents').valueChanges.subscribe(value => {
      if (value == 'yes') {
        this.document.setValidators([Validators.required]);
        this.document_type.setValidators([Validators.required]);
      } else {
        this.document.clearValidators();
        this.document_type.clearValidators();
      }
      this.document.updateValueAndValidity();
      this.document_type.updateValueAndValidity();
    });

    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);

    this.model = new PSSSPApplicationModel();
    this.childrenModel = new Children();
    this.documentModel = new Document();
  }

  //student
  get first_name() { return this.studentInfoForm.get('first_name'); }
  get last_name() { return this.studentInfoForm.get('last_name'); }
  get middle_name() { return this.studentInfoForm.get('middle_name'); }
  get street() { return this.studentInfoForm.get('applicant.province'); }
  get apt() { return this.studentInfoForm.get('applicant.address'); }
  get city() { return this.studentInfoForm.get('city'); }
  get province() { return this.studentInfoForm.get('province'); }
  get postal_code() { return this.studentInfoForm.get('postal_code'); }
  get home_number() { return this.studentInfoForm.get('home_number'); }
  get mobile() { return this.studentInfoForm.get('mobile'); }
  get dob() { return this.studentInfoForm.get('dob'); }
  get SIN() { return this.studentInfoForm.get('SIN'); }
  get student_no() { return this.studentInfoForm.get('student_no'); }
  get treaty_no() { return this.studentInfoForm.get('treaty_no'); }
  get email() { return this.studentInfoForm.get('email'); }
  get marital_status() { return this.studentInfoForm.get('applicant.marital_status'); }

  //spouse
  get spouse_first_name() { return this.spouseInfoForm.get('spouse_first_name'); }
  get spouse_last_name() { return this.spouseInfoForm.get('spouse_last_name'); }

  //dependent
  get childrens() { return this.dependentsInfoForm.get('childrens'); }
  get child_first_name() { return this.dependentsInfoForm.get('child_first_name'); }
  get child_last_name() { return this.dependentsInfoForm.get('child_last_name'); }
  get child_dob() { return this.dependentsInfoForm.get('child_dob'); }
  get child_age() { return this.dependentsInfoForm.get('child_age'); }
  get child_school() { return this.dependentsInfoForm.get('child_school'); }
  get child_grade() { return this.dependentsInfoForm.get('child_grade'); }
  get comments() { return this.dependentsInfoForm.get('comments'); }
  get childControls() { return this.dependentsInfoForm.get('childrens') as UntypedFormArray; }

  get start_date() { return this.assistanceReqForm.get('start_date'); }
  get end_date() { return this.assistanceReqForm.get('end_date'); }
  get program() { return this.assistanceReqForm.get('otherProgram'); }
  get institution() { return this.assistanceReqForm.get('otherInstitution'); }

  get signature() { return this.declarationForm.get('signature'); }
  get date() { return this.declarationForm.get('date'); }

  get documentsControls() { return this.otherInfoForm.get('documents') as UntypedFormArray; }
  get document() { return this.otherInfoForm.get('document'); }
  get document_type() { return this.otherInfoForm.get('document_type'); }
  get document_name() { return this.otherInfoForm.get('document_name'); }
  get document_link() { return this.otherInfoForm.get('document_link'); }


  setDate(control, index, e) {
    if (e.match(this.dateformat)) {
      this.dates[index].selected = e;
      const date = this.changeDates(e);
      const datePipe = new DatePipe('en-US');
      const formatedDate = datePipe.transform(date, 'fullDate');
      control.patchValue(formatedDate);
      this.dates[index].set = true;
    }
  }

  unSetDate(control, index) {
    control.patchValue(this.dates[index].selected);
    this.dates[index].set = false;
  }

  isValidDate(control: UntypedFormControl) {
    let date = control.value;
    const dateformat = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
    if (date) {
      if (!date.match(dateformat) && date.length > 10) {
        const datePipe = new DatePipe('en-US');
        date = datePipe.transform(date, 'dd/MM/yyyy');
      }
      if (date.match(dateformat)) {
        let dateArray = date.split('/');
        let year = dateArray[2];
        let month = dateArray[1] - 1;
        let day = dateArray[0];
        date = new Date(year, month, day);
        const today = new Date();
        if (date.valueOf() < today.valueOf()) {
          return null;
        } else {
          return { 'invalidDate': true }
        }
      }
    }
    return null;
  }

  changeDates(dateValue) {
    if (dateValue) {
      if (dateValue.match(this.dateformat)) {
        let dateArray = dateValue.split('/');
        let year = dateArray[2];
        let month = dateArray[1] - 1;
        let day = dateArray[0];
        const date = new Date(year, month, day);
        return date;
      } else {
        const date = new Date(dateValue);
        return date;
      }
    } else {
      return null
    }
  }

  onAddChildrenYes() {
    this.addChildren = true;
    this.child_first_name.reset();
    this.child_last_name.reset();
    this.child_dob.reset();
    this.child_age.reset();
    this.child_school.reset();
    this.child_grade.reset();
    setTimeout(() => {
      $('.collapsible').collapsible();
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }

  onAddChildrenNo() {
    this.addChildren = false;
    const children = this.childControls;
    while (children.length !== 0) {
      children.removeAt(0);
    }
    children.setValue([]);
    this.displayChildren = false;
  }

  newChild(values: any): UntypedFormGroup {
    return this.fb.group({
      first_name: [values.child_first_name],
      last_name: [values.child_last_name],
      dob: [values.child_dob],
      age: [values.child_age],
      grade: [values.child_grade],
      school: [values.child_school],
      comments: [values.comments],
    });
  }

  addChild(values: any) {
    const children = this.childControls;
    children.push(this.newChild(values));
    this.displayChildren = true;
    this.addChildren = false;
    setTimeout(() => {
      $('.collapsible').collapsible();
      $('select').formSelect();
    }, 25);
  }

  addAnotherChild() {
    this.addChildren = true;
    this.child_first_name.setValidators(Validators.required);
    this.child_last_name.setValidators(Validators.required);
    this.child_dob.setValidators(Validators.required);

    this.child_first_name.reset();
    this.child_last_name.reset();
    this.child_dob.reset();
    this.child_age.reset();
    this.child_school.reset();
    this.child_grade.reset();

    this.dates[1].selected = '';

    setTimeout(() => {
      $('collapsible').collapsible();
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }

  closeAddChild() {
    this.addChildren = false;
    this.child_first_name.clearValidators();
    this.child_last_name.clearValidators();
    this.child_dob.clearValidators();
    this.child_first_name.updateValueAndValidity();
    this.child_last_name.updateValueAndValidity();
    this.child_dob.updateValueAndValidity();
    if (this.childControls.length == 0) {
      this.dependentsInfoForm.patchValue({ 'add_children': 'no' });
    }
  }

  onDeleteChildern(index) {
    const children = this.childControls;
    children.removeAt(index);
    if (children.length == 0) {
      this.addChildren = false;
      this.displayChildren = false;
      this.dependentsInfoForm.patchValue({ 'add_children': 'no' });
    }
    toast({ html: 'Child deleted!', classes: 'red' });
  }

  // onEditChild(index) {
  //   this.toEdit = index;
  //   const child = this.childControls.value[index];
  //   this.child_first_name.patchValue(child.first_name);
  //   this.child_last_name.patchValue(child.last_name);
  //   this.child_dob.patchValue(child.dob);
  //   this.child_age.patchValue(child.age);
  //   this.child_school.patchValue(child.school);
  //   this.child_grade.patchValue(child.grade);
  //   this.comments.patchValue(child.comments);
  //   this.addChildren = true;
  //   this.editMode = true;
  //   setTimeout(() => {
  //     $('select').formSelect();
  //     $('.collapsible').collapsible();
  //     M.updateTextFields();
  //   }, 25);
  // }
  // updateChild(values) {
  // }

  onUplodYes() {
    this.showUpload = true;
    setTimeout(() => {
      $('select').formSelect();
    });
  }

  onUplodNo() {
    this.showUpload = false;
    this.documentsControls.setValue([]);
    //this.documentsControls.reset();
  }

  addAnotherFile() {
    this.showUpload = true;
    this.filesUploaded = false;
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }

  newDoc(values: any): UntypedFormGroup {
    return this.fb.group({
      docType: [values.docType],
      type: [values.type],
      name: [values.name],
      link: [values.link]
    });
  }

  docTypeChange(value) {
    this.document_type.patchValue(value);
  }

  async handleFileInput(files: FileList) {
    this.binDoc = undefined;
    if (files) {
      this.binDoc = files[0];
      this.base64Image = await this.imageToBase64(files[0]);
      this.document_name.patchValue(files[0].name);
    }
  }

  imageToBase64 = async (file: File) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      }
    });
  }

  uploadFile(values) {
    this.loading = true;
    if (this.binDoc) {
      if (this.validateInput(this.binDoc.name) == 'image') {
        const img = new ImageModel();
        img.imgBase64 = this.base64Image.replace(/^data:image\/[a-z]+;base64,/, '');
        this.mediaService.uploadImage(img).subscribe(upload => {
          if (upload) {
            if (upload.imgLink) {
              const value = {
                docType: 'image',
                name: values.document_name,
                type: values.document_type,
                link: upload.imgLink,
              }
              this.documentsControls.push(this.newDoc(value));
              this.filesUploaded = true;
            } else {
              toast({ html: 'Something Went Wrong While uploading document!Please Try again.', classes: 'red' });
            }
            this.loading = false;
            this.document_type.reset();
            this.document.reset();
          }
        });
      } else if (this.validateInput(this.binDoc.name) == 'pdf') {
        this.mediaService.uploadPDF(this.binDoc).subscribe(upload => {
          if (upload) {
            if (upload.imgLink) {
              const value = {
                docType: 'pdf',
                name: values.document_name,
                type: values.document_type,
                link: upload.imgLink,
              }
              this.documentsControls.push(this.newDoc(value));
              this.filesUploaded = true;
            } else {
              toast({ html: 'Something Went Wrong While uploading document!Please Try again.', classes: 'red' });
            }
            this.loading = false;
            this.document_type.reset();
            this.document.reset();
          }
        });
      }
    }
  }

  private validateInput(name: any) {
    const ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() === 'png') {
      return 'image';
    } else if (ext.toLowerCase() === 'jpg') {
      return 'image';
    } else if (ext.toLowerCase() === 'jpeg') {
      return 'image';
    }
    else if (ext.toLowerCase() === 'svg') {
      return 'image';
    }
    else if (ext.toLowerCase() === 'webp') {
      return 'image';
    } else if (ext.toLowerCase() === 'mp4' || ext.toLowerCase() === 'mkv') {
      return 'video';
    } else if (ext.toLowerCase() === 'pdf') {
      return 'pdf';
    } else {
      return false;
    }
  }

  removeFile(index) {
    const documents = this.documentsControls;
    documents.removeAt(index);
    if (documents.length == 0) {
      this.showUpload = false;
      this.otherInfoForm.patchValue({ 'upload_documents': 'no' });
    }
    toast({ html: 'File deleted!', classes: 'red' });

    documents.removeAt(index);
    if (documents.length == 0) {
      this.filesUploaded = false;
      //this.showUpload = false;
      //this.otherInfoForm.get('upload_documents').patchValue('no');
    }
    setTimeout(() => {
      $('select').formSelect();
      $('.collapsible').collapsible();
      $('.tooltipped').tooltip();
      M.updateTextFields();
    }, 25);
  }

  closeDocUpload() {
    //this.showUpload = false;
    this.filesUploaded = true;;
    this.document.reset();
    this.document_type.reset()
    if (this.documentsControls.length == 0) {
      this.showUpload = false;
      this.otherInfoForm.get('upload_documents').patchValue('no');
    }
    setTimeout(() => {
      $('select').formSelect();
      $('.collapsible').collapsible();
      $('.tooltipped').tooltip();
      M.updateTextFields();
    }, 25);
    // this.documentsControls.setValue([]);
  }

  uploadFiles() {
    this.model.documents = this.documentsControls.value;
    this.filesUploaded = true;
    this.docsUploaded = true;
  }

  onFullTime() { }
  onPartTime() { }

  submitStudentInfo(values) {
    if (this.member) {
      this.model.applicantID = this.member.applicantID;
    } else {
      this.model.applicantID = this.currentUser.applicantID;
    }
    this.model.firstName = values.first_name;
    this.model.lastName = values.last_name;
    this.model.middleName = values.middle_name;
    this.model.street = values.street;
    this.model.apt = values.apt;
    this.model.city = values.city;
    this.model.province = values.province;
    this.model.postalCode = values.postal_code;
    this.model.homeNumber = values.home_number;
    this.model.mobile = values.mobile;
    this.model.dob = this.changeDates(values.dob);
    this.model.SIN = values.SIN;
    this.model.studentNo = values.student_no;
    this.model.treatyNo = values.treaty_no;
    this.model.email = values.email;
    this.model.maritalStatus = values.marital_status;

    this.onNext();
  }

  submitSpouseInfo(values) {
    this.model.spouseFirstName = values.spouse_first_name;
    this.model.spouseLastName = values.spouse_last_name;
    this.model.spouseMiddleName = values.spouse_middle_name;
    this.model.spouseLive = values.spouse_live;
    this.model.spouseEmployed = values.spouse_employed;
    this.onNext();
  }

  submitDependentsInfo(values) {
    values.childrens.forEach(child => {
      if (child.dob) {
        child.dob = this.changeDates(child.dob);
      }
    });
    this.model.dependents = values.childrens;

    this.onNext();
  }

  submitAssistanceReq(values) {
    this.model.assistanceType = values.type;
    if (values.institution == 'others') {
      this.model.institution = values.otherInstitution;
    } else {
      this.model.institution = values.institution;
    }
    this.model.location = values.location;
    if (values.program == 'others') {
      this.model.program = values.otherProgram;
    } else {
      this.model.program = values.program;
    }
    this.model.startDate = this.changeDates(values.start_date);
    this.model.endDate = this.changeDates(values.end_date);
    this.model.length = values.length;
    this.model.yearsCompleted = values.years_completed;
    this.model.terms = {
      fall: values.terms.fall,
      winter: values.terms.winter,
      inter_session: values.terms.inter_session,
      summer_session: values.terms.summer_session
    };
    this.onNext();
  }

  submitDeclaration(values) {
    this.model.applicantName = values.name;
    this.model.signature = values.signature;
    this.model.date = this.changeDates(values.date);
    this.onNext();
  }

  submitOtherInfo(values) {
    this.model.documents = values.documents;
    this.onNext();
  }

  SubmitApplication() {
    this.loading = true;
    if (this.otherInstitution) {
      this.addInstitution(this.model.institution);
    }
    if (this.otherProgram) {
      this.addProgram(this.model.program);
    }
    this.pssspService.saveApplication(this.model).then(x => {
      toast({ html: 'Application Successfully Sent!', classes: 'green' });
      this.backClicked();
      this.loading = false;
    });
  }

  formattedDate(d) {
    let month = String(d.getMonth() + 1);
    let day = String(d.getDate());
    const year = String(d.getFullYear());

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return `${day}/${month}/${year}`;
  }

  onNext() {
    const datePipe = new DatePipe('en-US');
    if (!this.member && this.currentUser) {

      this.studentInfoForm.patchValue({ 'first_name': this.currentUser.GivenName });
      this.studentInfoForm.patchValue({ 'last_name': this.currentUser.LastName });
      this.studentInfoForm.patchValue({ 'middle_name': this.currentUser.middle_name });
      var array = this.currentUser.DOB.split("-");
      var date = `${array[2].substring(0, 2)}/${array[1]}/${array[0]}`;
      this.studentInfoForm.patchValue({ 'dob': date });
      this.studentInfoForm.patchValue({ 'email': this.currentUser.Email });
    }
    else if (this.step == 0 && this.member) {

      this.studentInfoForm.patchValue({ 'first_name': this.member.GivenName });
      this.studentInfoForm.patchValue({ 'last_name': this.member.LastName });
      this.studentInfoForm.patchValue({ 'middle_name': this.member.middle_name });
      var array = this.member.DOB.split("-");
      var date = `${array[2].substring(0, 2)}/${array[1]}/${array[0]}`;
      this.studentInfoForm.patchValue({ 'dob': date });
      this.studentInfoForm.patchValue({ 'city': this.member.city });
      this.studentInfoForm.patchValue({ 'province': this.member.province });
      this.studentInfoForm.patchValue({ 'mobile': this.member.cell_phone_num });
      this.studentInfoForm.patchValue({ 'home_number': this.member.Fax });
      this.studentInfoForm.patchValue({ 'postal_code': this.member.PostalCode });
      this.studentInfoForm.patchValue({ 'SIN': this.member.SIN });
      this.studentInfoForm.patchValue({ 'email': this.member.Email });
      this.studentInfoForm.patchValue({ 'treaty_no': this.member.TreatyNo });
      this.studentInfoForm.patchValue({ 'marital_status': this.member.MaritalStatus });
      this.maritalStatusSelected = this.member.MaritalStatus;
    }
    if (this.member && this.step == 2 && this.member.aidofspouse && this.member.aidofspouse > 0) {

      this.memberService.getMember(this.member.aidofspouse).subscribe(x => {
        this.spouse = x[0];
        this.spouseInfoForm.patchValue({ 'spouse_first_name': this.spouse.GivenName });
        this.studentInfoForm.patchValue({ 'spouse_last_name': this.spouse.LastName });
        this.studentInfoForm.patchValue({ 'spouse_middle_name': this.spouse.middle_name });
      });
    }
    if (this.member && this.step == 1 && this.member.current_family_com && this.member.current_family_comp > 0) {

      this.familyCompositionService.getFamilyMember(this.member.current_application).subscribe(data => {
        if (data) {
          this.familyMembers = data;

          this.familyMembers.forEach(m => {
            // if (m.isHead != 1) {
            var values = {
              child_first_name: m.GivenName,
              child_last_name: m.LastName,
              child_dob: m.DOB,
              child_age: '',
              child_grade: '',
              child_school: '',
              comments: ''
            }
            this.addChild(values);
            // }
          });
        }
      });
    }
    if (this.step == 4) {
      const name = `${this.model.firstName} ${this.model.lastName}`;
      this.declarationForm.patchValue({ 'name': name });
      this.declarationForm.patchValue({ 'date': this.formattedDate(new Date()) });
    }

    if (this.step == 1 && this.maritalStatusSelected == '0') this.step = 4;
    else if (this.step == 1 && this.maritalStatusSelected == '1') this.step = 3;
    else this.step += 1;
    setTimeout(() => {
      $('select').formSelect();
      $('.collapsible').collapsible();
      $('.tooltipped').tooltip();
      M.updateTextFields();
    }, 25);
    window.scrollTo(0, 0);
  }

  onBack() {
    if (this.step == 3 && this.maritalStatusSelected == "1") this.step = 1;
    else if (this.step == 4 && this.maritalStatusSelected == '0') this.step = 1;
    else this.step -= 1;
    setTimeout(() => {
      $('select').formSelect();
      $('.collapsible').collapsible();
      $('.tooltipped').tooltip();
      M.updateTextFields();
    }, 25);
    window.scrollTo(0, 0);
  }

  provinceChange(value) {
  }

  institutionSelected(value) {
    if (value == 'others') {
      this.otherInstitution = true;
    } else {
      this.otherInstitution = false;
      this.institutionsList.forEach(inst => {
        if (inst.name == value) {
          this.branches = inst.branches;
        }
      });
      if (this.branches.length == 0) {
        this.assistanceReqForm.get('location').reset();
      }
    }
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }

  programSelected(value) {
    if (value == 'others') {
      this.otherProgram = true;
    } else {
      this.otherProgram = false;
    }
  }

  addInstitution(value) {
    this.pssspService.addInstitution(value).then(x => {
      //console.log('Ininstitution added successfully');
    });
  }
  addProgram(value) {
    this.pssspService.addProgram(value).then(x => {
      //console.log('Program added successfully');
    });
  }

  maritalStatusChange(value) {
    this.maritalStatusSelected = value;
    this.maritalStatusValue = this.maritalStatus[Number(this.maritalStatusSelected)].name;
  }

  editSection(section) {
    this.step = section;
    setTimeout(() => {
      $('select').formSelect();
      $('.collapsible').collapsible();
      $('.tooltipped').tooltip();
      M.updateTextFields();
    }, 25);
    window.scrollTo(0, 0);
  }

  sign() {
    this.showSignaturePad = true;
    setTimeout(() => {
      this.canvas = document.querySelector('canvas');
      const parentWidth = $(this.canvas).parent().outerWidth();
      this.canvas.setAttribute('width', parentWidth);
      this.canvas.setAttribute('height', '175px');
      this.signaturePad = new SignaturePad(this.canvas, {
        backgroundColor: 'rgb(255,255,255)'
      });
    }, 50);
  }

  approveSignature() {
    if (this.signaturePad.isEmpty()) {
      toast({ html: 'Please Sign the Application!', classes: 'red' });
      return;
    } else {
      const dataUrl = this.signaturePad.toDataURL();

      this.signature.patchValue(dataUrl);
      this.signed = true;
      this.showSignaturePad = false;
      toast({ html: 'Application Signed', classes: 'green' });
      //this.onBack();
      setTimeout(() => {
        M.updateTextFields();
      }, 25);
    }
  }

  clearSignature() {
    this.signed = false;
    this.signaturePad.clear();
    this.signature.reset();
  }

  async getDocumentDefinition() {
    const datePipe = new DatePipe('en-US');
    return {
      info: {
        title: 'PETER BALLANTYNE CREE NATION POST-SECONDARY STUDENT SUPPORT PROGRAM APPLICATION FORM',
        subject: 'PSSSP',
        keywords: 'PSSSP Application',
      },
      content: [
        {
          columns: [
            [
              await this.getLogo(),
            ],
            [{
              text: 'PETER BALLANTYNE CREE NATION POST-SECONDARY STUDENT SUPPORT PROGRAM APPLICATION FORM',
              style: 'mainTitle',
            }]
          ]
        },
        {
          columns: [
            [{
              text: 'Student Information',
              style: 'title',
            },
            {
              text: 'First Name : ' + this.model.firstName
            },
            {
              text: 'Last Name : ' + this.model.lastName
            },
            {
              text: 'Middle Name : ' + this.model.middleName ? this.model.middleName : ''
            },
            {
              text: 'Mailing Information',
              style: 'subTitle',
            },
            {
              text: 'Street : ' + this.model.street
            },
            {
              text: 'Apartment : ' + this.model.apt
            },
            {
              text: 'City : ' + this.model.city
            },
            {
              text: 'Province : ' + this.model.province
            },
            {
              text: 'Postal Code : ' + this.model.postalCode
            },
            {
              text: 'Home Number : ' + this.model.homeNumber
            },
            {
              text: 'Mobile Number : ' + this.model.mobile
            },
            {
              text: 'Date of Birth : ' + this.model.dob
            },
            {
              text: 'SIN : ' + this.model.SIN
            },
            {
              text: 'Student Number : ' + this.model.studentNo
            },
            {
              text: 'Treaty Number : ' + this.model.treatyNo
            },
            {
              text: 'Email : ' + this.model.email
            },
            {
              text: 'Marital Status : ' + this.maritalStatusValue
            }],
            (this.maritalStatusSelected == '2' || this.maritalStatusSelected == '3') ?
              [{
                text: 'Spouse Information',
                style: 'title',
              },
              {
                text: this.model.spouseFirstName ? 'Spouse First Name : ' + this.model.spouseFirstName : ""
              },
              {
                text: this.model.spouseLastName ? 'Spouse Last Name : ' + this.model.spouseLastName : ""
              },
              {
                text: this.model.spouseMiddleName ? 'Spouse Middle Name : ' + this.model.spouseMiddleName : ''
              },
              {
                text: this.model.spouseLive ? 'Will your spouse be living with you during the time of your funding : ' + this.model.spouseLive : ""
              },
              {
                text: this.model.spouseEmployed ? 'Will your spouse be employed? funded student? :: ' + this.model.spouseEmployed : ""
              }] : ''
          ]
        },

        {
          columns: [
            this.model.dependents ?
              [
                {
                  text: 'Dependents',
                  style: 'title',
                },
                {
                  table: {
                    widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
                    body: [
                      [
                        {
                          text: 'First Name',
                          style: 'header',
                          bold: true,
                        },
                        {
                          text: 'Last Name',
                          style: 'header',
                          bold: true,
                        },
                        {
                          text: 'Date of Birth',
                          style: 'header',
                          bold: true,
                        },
                        {
                          text: 'Age',
                          style: 'header',
                          bold: true,
                        },
                        {
                          text: 'Grade',
                          style: 'header',
                          bold: true,
                        },
                        {
                          text: 'school',
                          style: 'header',
                          bold: true,
                        },
                        // {
                        //   text: 'comment',
                        //   style: 'header',
                        //   bold: true,
                        // },
                      ],
                      ...this.model.dependents.map(child => {
                        return [child.first_name, child.last_name, child.dob, child.age, child.grade, child.school];
                      })
                    ]
                  }
                }
              ] : ''
          ]
        },
        {
          text: 'Assistance Required',
          style: 'title',
        },
        {
          text: 'Assistance Type : ' + this.model.assistanceType
        },
        {
          text: 'Institution Name : ' + this.model.institution
        },
        {
          text: 'Location: ' + this.model.location
        },
        {
          text: 'Program : ' + this.model.program
        },
        {
          text: 'Starts On : ' + this.model.startDate ? this.model.startDate : ''
        },
        {
          text: 'Ends On: ' + this.model.endDate ? this.model.endDate : ''
        },
        {
          text: 'length : ' + this.model.length
        },
        {
          text: 'Years Completed: ' + this.model.yearsCompleted
        },
        {
          text: 'Terms',
        },
        {
          text: 'Fall : ' + this.model.terms.fall
        },
        {
          text: 'Winter : ' + this.model.terms.winter
        },
        {
          text: 'Inter-Session : ' + this.model.terms.inter_session
        },
        {
          text: 'Summe-Session : ' + this.model.terms.summer_session
        },
        {
          columns: [
            (this.model.documents && this.model.documents.length > 0) ?
              [
                {
                  text: 'Other Information',
                  style: 'title',
                },
                {
                  text: 'Document Uploaded',
                  style: 'subTitle',
                },
                {
                  table: {
                    widths: ['auto', 'auto', 'auto'],
                    body: [
                      [
                        {
                          text: 'Document',
                          style: 'header',
                          bold: true,
                        },
                        {
                          text: 'Document Description',
                          style: 'header',
                          bold: true,
                        },
                        {
                          text: 'Document Type',
                          style: 'header',
                          bold: true,
                        },
                      ],
                      ...this.model.documents.map(doc => {
                        return [{
                          text: doc.name,
                          link: doc.link,
                          style: 'links',
                        }, doc.type, doc.docType];
                      })
                    ]
                  }
                }
              ] : ''
          ]
        },
      ],
      styles: {
        mainTitle: {
          bold: true,
          fontSize: 20,
          alignment: 'left',
          margin: [0, 0, 0, 20]
        },
        title: {
          bold: true,
          fontSize: 15,
          alignment: 'center',
          margin: [10, 10, 10, 10]
        },
        subTitle: {
          bold: true,
          fontSize: 12,
          alignment: 'left',
          margin: [10, 10, 10, 10]
        },
        header: {
          fontSize: 12,
          bold: true,
          alignment: 'center',
        },
        links: {
          color: 'blue',
          decoration: 'underline',
          //italics: true,
          margin: [0, 5, 0, 5]
        }
      }
    };
  }

  async getLogo() {
    let imageLink = "assets/img/PSSSP-image.jpg";
    const image = await this.getBase64FromUrl(imageLink);
    if (image) {
      return {
        image: image,
        width: 100,
        alignment: 'left'
      }
    }
  }

  getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      }
    });
  }

  async openPdf() {
    const documentDefinition = await this.getDocumentDefinition();
    pdfMake.createPdf(documentDefinition).open();
  }

  async downloadPdf() {
    const documentDefinition = await this.getDocumentDefinition();
    pdfMake.createPdf(documentDefinition).download();
  }

  async printPdf() {
    const documentDefinition = await this.getDocumentDefinition();
    pdfMake.createPdf(documentDefinition).print();
  }

  backClicked() {
    this.location.back();
  }
}
