<div class="row ">
    <div class="col s12 m12 l11">
        <div class="row card-panel white darken-2">

            <div class="row" *ngIf="governances">
                <div class="card col l2 m3 s12" [ngStyle]="{'color': themeColor}" *ngFor="let governance of governances"
                    (click)="editGovernanceData($event, governance.id)">
                    <h4>{{ governance.name }}</h4>
                </div>
            </div>

            <div class="col s12 m12 l12">
                <form [formGroup]="addGovernanceForm" (ngSubmit)="PostGovernance(addGovernanceForm)">
                    <div class="row ">
                        <div class="row col l5 s12 m9">
                            <div class="form-group">
                                <div class="label-input">Governance Name</div>
                                <input type="text" class="form-control" class="validate" formControlName="name"
                                    placeholder="Governance Name">
                            </div>
                        </div>
                        <br />
                        <div class="row col l3 s12 m3">
                            <button class="add-button btn white center-align" [ngStyle]="{'color': themeColor}"
                                type="submit"><i class="material-icons-outlined bold-700">add</i> Add
                                Governance</button>
                        </div>
                    </div>
                </form>
            </div>
            
        </div>
    </div>
</div>