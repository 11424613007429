export class StaticContentModel {
  id = '';
  title = '';
  body = '';
  author = '';
  published = false;
  index = 0;
  unPublished = true;
  datePublished = new Date();
  imgLink = '';
  videoLink = '';
  type = '';
  visibility = '';
  comments: Comment[] = [];
  backgroundColor = '';
  likeCount: number = 0;
  allowComments: boolean = true;
  isLiked: boolean = false;
  likedUserName: string[] = [];
  nameLikedBy: string[];
  
}
export class Comment {
  id: string;
  userName: string = '';
  userId: string = '';
  comment: string = '';
  timeStamp = new Date();
  showReplies: boolean = false;
  newReplyText: string = '';
  replies: CommentReply[] = [];
  likesC: number = 0; 
  likedC: boolean = false;   
  likedByC: string[] = [];
}

export class CommentReply {
  id: string; 
  userName: string = '';
  userId: string = '';
  reply: string;
  timeStamp: Date = new Date();
  parentId: string | null;
  likesR: number = 0; 
  likedR: boolean = false;   
  likedByR: string[] = [];
}


export class Like {
  userName: string = '';
  userId: string = '';
  likeCount: number = 0;
}
