<div *ngIf="step1" class="p-m-2">
  <div *ngIf="request" class="row col s12" style="width: 100%;">

    <div class="row col s12" *ngIf="showDetails" style="max-width: fit-content;">

      <div class="col s12 card-panel" *ngIf="result">
        <div class="center-align">
          <p><strong>Request Status: </strong>
            <span *ngIf="!request.status" class="blue-text"><strong style="font-weight: bolder;">Pending</strong></span>
            <span *ngIf="request.status == 1" class="blue-text"><strong
                style="font-weight: bolder;">Pending</strong></span>

            <span *ngIf="request.status == 2" class="green-text"><strong
                style="font-weight: bolder;">Approved</strong></span>

            <span *ngIf="request.status == 3" class="red-text"><strong
                style="font-weight: bolder;">Declined</strong></span>
          </p>
          <p><strong>Request Date: </strong> <span *ngIf="!SQLDB">{{request?.requestDate.toDate() | date:
              'dd/MMM/yyyy'}}</span>
            <span *ngIf="SQLDB">{{request?.datetime | date: 'dd/MMM/yyyy'}}</span>
          </p>
          <h6 class="red-text"><strong style="font-weight: bolder;">{{result.length}} Possible Matches Found in the
              Database</strong></h6>
          <br />
        </div>
      </div>

      <div class="row col s12 card white">
        <div class="col s7 m8">
          <table>
            <thead>
              <tr>
                <th></th>
                <th>Applicant Data</th>
              </tr>
            </thead>
            <tr>
              <th>Last Name</th>
              <td>
                <span *ngIf="!SQLDB">{{request?.lastName}}</span>
                <span *ngIf="SQLDB">{{request?.Lname}}</span>
              </td>
            </tr>
            <tr>
              <th>Middle Name</th>
              <td>
                <span *ngIf="!SQLDB">{{request?.middleName}}</span>
                <span *ngIf="SQLDB">{{request?.Mname}}</span>
              </td>
            </tr>
            <tr>
              <th>First Name</th>
              <td>
                <span *ngIf="!SQLDB">{{request?.firstName}}</span>
                <span *ngIf="SQLDB">{{request?.Fname}}</span>
              </td>
            </tr>
            <tr>
              <th>Email</th>
              <td>
                <span>{{request?.email}}</span>
              </td>
            </tr>
            <tr>
              <th>Date Of Birth</th>
              <td>
                <span>{{request?.dob | date:'dd/MMM/yyyy'}}</span>
              </td>
            </tr>
            <tr>
              <th>Mobile</th>
              <td>
                <span>{{request?.mobile}}</span>
              </td>
            </tr>
            <tr>
              <th>ISN</th>
              <td>
                <span>{{request?.isn}}</span>
              </td>
            </tr>
            <tr>
              <th>Request Date</th>
              <td>
                <span *ngIf="!SQLDB">{{request?.requestDate.toDate() | date: 'dd/MMM/yyyy'}}</span>
                <span *ngIf="SQLDB">{{request?.datetime | date: 'dd/MMM/yyyy'}}</span>
              </td>
            </tr>
            <tr>
              <th>ID</th>
              <td *ngIf="ID_image">
                <a class="waves-light btn btn-small" (click)="viewImage()"><i
                    class="material-icons right">preview</i>View</a>
              </td>
              <td *ngIf="!ID_image">
                <a class="red-text"><small>No Id Provided</small></a>
              </td>
            </tr>
          </table>
        </div>

        <div class="col s5 m4" *ngIf="SQLDB && result">
          <div *ngIf="result && matches">
            <div>
              <table>
                <thead>
                  <tr>
                    <th class="center">
                      <span *ngIf="matchI < matches.length">Match {{matchI +1}}</span>
                      <span *ngIf="matches.length >0 && matchI == matches.length" class="red-text">No More
                        Matches</span>
                      <span *ngIf="matches.length==0" class="red-text">No Match Found</span>
                      <a style="cursor: pointer;" (click)="previousMatch()" *ngIf="matchI > 0"><i
                          class="material-icons left">
                          arrow_back_ios
                        </i></a>
                      <a style="cursor: pointer;" (click)="nextMatch()" *ngIf="matchI < result.length"><i
                          class="material-icons right">
                          arrow_forward_ios
                        </i></a>
                    </th>
                  </tr>
                </thead>
                <div *ngFor="let match of matches; let i = index">
                  <div *ngIf="matchI == i">
                    <tr>
                      <td>{{match.LastName}}</td>
                    </tr>
                    <tr>
                      <td>{{match.middle_name}}</td>
                    </tr>
                    <tr>
                      <td>{{match.GivenName}}</td>
                    </tr>
                    <tr>
                      <td>{{match.Email}}</td>
                    </tr>
                    <tr>
                      <td>{{match.DOB | date: 'dd/MMM/yyyy'}}</td>
                    </tr>
                    <tr>
                      <td *ngIf="match.Sex == 'F'">Female</td>
                      <td *ngIf="match.Sex == 'M'">Male</td>
                    </tr>
                    <tr>
                      <td>{{match.cell_phone_num }}</td>
                    </tr>
                    <tr>
                      <td>{{match.IndianStatusNo}}</td>
                    </tr>
                    <tr>
                      <td>{{match.MailAddress}}</td>
                    </tr>
                    <tr>
                      <td>{{match.city}}</td>
                    </tr>
                    <tr>
                      <td>{{match.province}}</td>
                    </tr>
                    <tr>
                      <td>{{match.PostalCode}}</td>
                    </tr>
                    <tr>
                      <td>
                        <a class="btn btn-small col s12 green center-align" (click)="SetUser(match.applicantID)"><i
                            class="material-icons right">thumb_up</i>Approve</a>
                      </td>
                    </tr>
                  </div>
                </div>

                <div *ngIf="(matches.length == 0) || matchI >= matches.length" class="center-align"
                  style="margin-top: 25px;height: inherit;">
                  <i class="material-icons center blue-text" style="font-size: 50px;">
                    info
                  </i>
                  <h5 *ngIf="matches.length == 0"><strong>No Match found</strong></h5>
                  <br />
                  <p>Would you like to add this member?</p>
                  <br />
                  <button class="col s12 btn waves-effect green" (click)="addMember()" style="margin-bottom: 25px;"><i
                      class="material-icons right">
                      person_add
                    </i>Add Member</button>
                </div>

              </table>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col s12">
      <div *ngIf="!step2">
        <button *ngIf="request.status<3" type="button" class='col s12 btn waves-effect red'
          style="margin-top: 10px;margin-bottom: 15px;" (click)="Decline()">
          <i class="material-icons right">thumb_down_alt</i> Decline Request
        </button>
      </div>

      <button class="col s12 btn waves-effect green" (click)="contactClient()" style="margin-bottom: 25px;"><i
          class="material-icons right">
          email
        </i>Contact Client</button>

      <button type="button" class='col s12 btn waves-effect red modal-trigger' data-target="confirmationModal"
        style="margin-top: 10px;margin-bottom: 15px;">
        <i class="material-icons right">delete</i> Delete Request
      </button>

      <a [ngClass]="'col s12 btn waves-effect ' + themeColor" (click)="backClicked()"><i
          class="material-icons left">arrow_back_ios</i> Back To Requests List</a>
    </div>
  </div>

  <div *ngIf="invited">
    <div class="row col s12" *ngIf="invitedMember">
      <div class="row">
        <h6 class="left"><strong> Manage Request </strong></h6>
        <p class="right green-text">
          <strong>Invited</strong>
        </p>
      </div>

      <div *ngIf="invitedMember" class="row collection">
        <a class="collection-item">
          <div>Last Name:<a class="secondary-content blue-text"><strong> {{invitedMember?.LastName}}</strong> </a></div>
        </a>
        <a class="collection-item">
          <div>Middle Name:<a class="secondary-content blue-text"><strong> {{invitedMember?.middle_name}}</strong> </a>
          </div>
        </a>
        <a class="collection-item">
          <div>First Name:<a class="secondary-content blue-text"><strong> {{invitedMember?.GivenName}}</strong> </a>
          </div>
        </a>
        <a class="collection-item">
          <div>Email:<a class="secondary-content blue-text"><strong> {{invitedMember?.Email}}</strong> </a></div>
        </a>
        <a class="collection-item">
          <div>Date Of Birth:<a class="secondary-content blue-text"><strong> {{invitedMember?.dob | date:
                'dd/MMM/yyyy'}}</strong> </a></div>
        </a>
        <a class="collection-item">
          <div>Gender:
            <a class="secondary-content blue-text" *ngIf="invitedMember?.Sex == 'M'"> <strong>Male</strong></a>
            <a class="secondary-content blue-text" *ngIf="invitedMember?.Sex == 'F'"> <strong>Female</strong></a>
          </div>
        </a>
        <a class="collection-item">
          <div>Mobile:<a class="secondary-content blue-text"><strong>{{invitedMember?.cell_phone_num}}</strong> </a>
          </div>
        </a>
        <a class="collection-item">
          <div>Telephone:<a class="secondary-content blue-text"><strong> {{invitedMember?.Telephone}}</strong> </a>
          </div>
        </a>
        <a class="collection-item">
          <div>ISN:<a class="secondary-content blue-text"><strong> {{invitedMember?.IndianStatusNo}}</strong> </a></div>
        </a>
        <a class="collection-item">
          <div>Address:<a class="secondary-content blue-text"><strong> {{invitedMember?.MailAddress}}</strong> </a>
          </div>
        </a>
        <a class="collection-item">
          <div>City:<a class="secondary-content blue-text"><strong> {{invitedMember?.city}}</strong> </a></div>
        </a>
        <a class="collection-item">
          <div>Province:<a class="secondary-content blue-text"><strong>{{invitedMember?.province}}</strong> </a></div>
        </a>
        <a class="collection-item">
          <div>Postal Code:<a class="secondary-content blue-text"><strong> {{invitedMember?.postalCode}}</strong> </a>
          </div>
        </a>
      </div>
    </div>
    <div class="row col s12">
      <button class="col s12 btn waves-effect blue" (click)="resendLink()" style="margin-bottom: 25px;"><i
          class="material-icons right">
          send
        </i>Resend Registration Link</button>

      <button class="col s12 btn waves-effect green" (click)="contactClient()" style="margin-bottom: 25px;"><i
          class="material-icons right">
          email
        </i>Contact Client</button>

      <a [ngClass]="'col s12 btn waves-effect ' + themeColor" (click)="backClicked()"><i
          class="material-icons left">arrow_back_ios</i> Back To Requests List</a>
    </div>
  </div>
</div>

<div class="col s12" *ngIf="step2">
  <div class="card">
    <div class="card-content">

      <div class="row center">
        <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
          <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
          <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
        </svg>
        <p class="green-text"> <strong>Registration Link Successfully Sent</strong></p>
      </div>
    </div>
  </div>
</div>

<div *ngIf="contact">
  <app-contact-members [message]=message [contactDetail]="contactDetail" (success)="contactHandler($event)">
  </app-contact-members>
</div>

<!-- Modal Structure -->
<div id="registrationModal" class="modal">
  <div class="modal-content">
    <h6><strong>Registration Questions</strong></h6>
    <div class="row">
      <br>
      <p>Please Select One Or More Question.</p>

      <div class="col s12 m6 l4">
        <label>
          <input type="checkbox" [checked]="verifySIN" (click)="sinChecked()" />
          <span>Ask For SIN Number.</span>
        </label>
      </div>
      <div class="col s12 m6 l4">
        <label>
          <input type="checkbox" [checked]="verifyISN" (click)="isnChecked()" />
          <span>Ask For ISN Number.</span>
        </label>
      </div>
      <div class="col s12 m6 l4">
        <label>
          <input type="checkbox" [checked]="secretQuestion" (click)="secretQuestionChecked()" />
          <span>Ask A Secret Question.</span>
        </label>
      </div>

      <div class="row input-field col s12" *ngIf="secretQuestion">
        <i class="material-icons prefix pink-text">help_outline</i>
        <input id="question" type="text" [(ngModel)]="question" autocomplete="off" />
        <label for="question">Secret Question :</label>
      </div>

      <div class="row input-field col s12" *ngIf="secretQuestion">
        <i class="material-icons prefix pink-text">help_outline</i>
        <input id="answer" type="text" [(ngModel)]="answer" autocomplete="off" />
        <label for="answer">Answer :</label>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <a class="modal-close btn btn-green" (click)="SendRegistrationLink()">Next</a>
    <a class="modal-close btn-flat">Cancel</a>
  </div>
</div>

<div id="invitationModal" class="modal">
  <div class="modal-content">
    <h5 class="center green-text">This Member has an Employee account on my nation!</h5>
    <br />
    <div class="row center-align">
      <p><strong>Send an Invitation to give a Member Access?</strong></p>
    </div>
    <div class="row">
      <div class="col s1"></div>
      <div class="col s10">
        <a (click)="invite()" class="col s12 waves-effect waves-blue blue center btn-large">Invite</a>
      </div>
      <div class="col s1"></div>
    </div>
  </div>

  <div class="modal-footer">
    <a (click)="closeModal()" class="modal-close waves-effect waves-blue btn-flat">Cancel</a>
  </div>
</div>

<div id="updateModal" class="modal" *ngIf="request && member">

  <form [formGroup]="updateForm" (ngSubmit)="updateProfile(updateForm.value)">
    <div class="modal-content">
      <h5><strong>Update User Data</strong></h5>
      <p> <i class="material-icons yellow-text">
          lightbulb
        </i>There are some data's which doesn't match with the data on our database!</p>
      <p><strong>Do you want to update the database ?</strong></p>
      <div class="row">
        <div class="col s12 m6">
          <label>
            <input type="radio" name="updateDB" (click)="updateDBYes()" />
            <span><strong>Yes</strong></span>
          </label>
        </div>

        <div class="col s12 m6">
          <label>
            <input type="radio" name="updateDB" (click)="updateDBNo()" />
            <span><strong>No</strong></span>
          </label>
        </div>
      </div>
      <br />

      <table *ngIf="updateDB">
        <thead>
          <tr>
            <th>Data</th>
            <th>
              <label>
                <input type="checkbox" (click)="selectRequestData()" [checked]="requestDataSelected" />
                <span>Request Data</span>
              </label>
            </th>
            <th>
              <label>
                <input type="checkbox" (click)="selectMemberData()" [checked]="memberDataSelected" />
                <span>Member Data</span>
              </label>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngIf="compare.firstName_df">
            <td>First Name <span class="red-text"></span></td>
            <td>
              <label>
                <input name="firstName" formControlName="firstName" [value]="request.Fname" type="radio" />
                <span>{{request.Fname}}</span>
              </label>
            </td>
            <td>
              <label>
                <input name="firstName" formControlName="firstName" [value]="member.GivenName" type="radio" />
                <span>{{member.GivenName}}</span>
              </label>
            </td>
          </tr>
          <tr *ngIf="compare.lastName_df">
            <td>Last Name <span class="red-text"></span></td>
            <td>
              <label>
                <input name="lastName" formControlName="lastName" [value]="request.Lname" type="radio" />
                <span>{{request.Lname}}</span>
              </label>
            </td>
            <td>
              <label>
                <input name="lastName" formControlName="lastName" [value]="member.LastName" type="radio" />
                <span>{{member.LastName}}</span>
              </label>
            </td>
          </tr>
          <tr *ngIf="compare.middleName_df">
            <td>Middle Name<span class="red-text"></span></td>
            <td>
              <label>
                <input name="middleName" formControlName="middleName" [value]="request.Mname" type="radio" />
                <span>{{request.Mname}}</span>
              </label>
            </td>
            <td>
              <label>
                <input name="middleName" formControlName="middleName" [value]="member.middle_name" type="radio" />
                <span>{{member.middle_name}}</span>
              </label>
            </td>
          </tr>
          <tr *ngIf="compare.dob_df">
            <td>Date of Birth<span class="red-text"></span></td>
            <td>
              <label>
                <input name="dob" type="radio" formControlName="dob" [value]="request.dob" />
                <span>{{request.dob}}</span>
              </label>
            </td>
            <td>
              <label>
                <input name="dob" formControlName="dob" [value]="member.DOB" type="radio" />
                <span>{{member.DOB}}</span>
              </label>
            </td>
          </tr>
          <tr *ngIf="compare.email_df">
            <td>Email<span class="red-text"></span></td>
            <td>
              <label>
                <input name="email" formControlName="email" [value]="request.email" type="radio" />
                <span>{{request.email}}</span>
              </label>
            </td>
            <td>
              <label>
                <input name="email" formControlName="email" [value]="member.Email" type="radio" />
                <span>{{member.Email}}</span>
              </label>
            </td>
          </tr>
          <tr *ngIf="compare.gender_df">
            <td>Gender/Sex<span class="red-text"></span></td>
            <td>
              <label>
                <input name="gender" type="radio" formControlName="gender" [value]="request.gender" />
                <span>{{request.gender.charAt(0)}}</span>
              </label>
            </td>
            <td>
              <label>
                <input name="gender" type="radio" formControlName="gender" [value]="member.Sex" />
                <span>{{member.Sex}}</span>
              </label>
            </td>
          </tr>
          <tr *ngIf="compare.isn_df">
            <td>Indian Status Number<span class="red-text"></span></td>
            <td>
              <label>
                <input name="isn" type="radio" formControlName="isn" [value]="request.isn" />
                <span>{{request.isn}}</span>
              </label>
            </td>
            <td>
              <label>
                <input name="isn" type="radio" formControlName="isn" [value]="member.IndianStatusNo" />
                <span>{{member.IndianStatusNo}}</span>
              </label>
            </td>
          </tr>
          <tr *ngIf="compare.sin_df">
            <td>SIN<span class="red-text"></span></td>
            <td>
              <label>
                <input name="sin" type="radio" formControlName="sin" [value]="request.sin" />
                <span>{{request.sin}}</span>
              </label>
            </td>
            <td>
              <label>
                <input name="sin" type="radio" formControlName="sin" [value]="member.SIN" />
                <span>{{member.SIN}}</span>
              </label>
            </td>
          </tr>
          <tr *ngIf="compare.healthCareNo_df">
            <td>Health Care Number<span class="red-text"></span></td>
            <td>
              <label>
                <input name="healthCareNo" type="radio" formControlName="healthCareNo" [value]="request.HealthCareNo" />
                <span>{{request.HealthCareNo}}</span>
              </label>
            </td>
            <td>
              <label>
                <input name="healthCareNo" type="radio" formControlName="healthCareNo" [value]="member.HealthCareNo" />
                <span>{{member.HealthCareNo}}</span>
              </label>
            </td>
          </tr>
          <tr *ngIf="compare.province_df">
            <td>Province<span class="red-text"></span></td>
            <td>
              <label>
                <input name="province" type="radio" formControlName="province" [value]="request.province" />
                <span>{{getProvinceName(request.province)}}</span>
              </label>
            </td>
            <td>
              <label>
                <input name="province" type="radio" formControlName="province" [value]="member.province" />
                <span>{{getProvinceName(member.province)}}</span>
              </label>
            </td>
          </tr>
          <tr *ngIf="compare.postalCode_df">
            <td>Postal Code<span class="red-text"></span></td>
            <td>
              <label>
                <input name="postalCode" type="radio" formControlName="postalCode" [value]="request.postalcode" />
                <span>{{request.postalcode}}</span>
              </label>
            </td>
            <td>
              <label>
                <input name="postalCode" type="radio" formControlName="postalCode" [value]="member.PostalCode" />
                <span>{{member.PostalCode}}</span>
              </label>
            </td>
          </tr>
          <tr *ngIf="compare.address_df">
            <td>Address<span class="red-text"></span></td>
            <td>
              <label>
                <input name="address" name="address" type="radio" formControlName="address" [value]="request.address" />
                <span>{{request.address}}</span>
              </label>
            </td>
            <td>
              <label>
                <input name="address" name="address" type="radio" formControlName="address"
                  [value]="member.MailAddress" />
                <span>{{member.MailAddress}}</span>
              </label>
            </td>
          </tr>
          <tr *ngIf="compare.city_df">
            <td>City<span class="red-text"></span></td>
            <td>
              <label>
                <input name="city" type="radio" formControlName="city" [value]="request.city" />
                <span>{{request.city}}</span>
              </label>
            </td>
            <td>
              <label>
                <input name="city" type="radio" formControlName="city" [value]="member.city" />
                <span>{{member.city}}</span>
              </label>
            </td>
          </tr>
          <tr *ngIf="compare.community_df">
            <td>Community<span class="red-text"></span></td>
            <td>
              <label>
                <input name="community" type="radio" formControlName="community" [value]="request.community" />
                <span>{{request.community}}</span>
              </label>
            </td>
            <td>
              <label>
                <input name="community" type="radio" formControlName="community" [value]="member.Community" />
                <span>{{member.Community}}</span>
              </label>
            </td>
          </tr>
          <tr *ngIf="compare.mobile_df">
            <td>Mobile Number<span class="red-text"></span></td>
            <td>
              <label>
                <input name="mobile" type="radio" formControlName="mobile" [value]="request.mobile" />
                <span>{{request.mobile}}</span>
              </label>
            </td>
            <td>
              <label>
                <input name="mobile" type="radio" formControlName="mobile" [value]="member.cell_phone_num" />
                <span>{{member.cell_phone_num}}</span>
              </label>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="modal-footer" style="margin-bottom: 15px;">
      <button *ngIf="updateDB" type="submit" class="modal-close btn-flat white-text green" style="margin: 10px;"
        [disabled]="!updateForm.valid"><i class="material-icons right">save</i>Update</button>

      <button *ngIf="!updateDB" [disabled]="updateDB == null" type="button" (click)="next()"
        class="modal-close btn-flat white-text green" style="margin: 10px;"><i
          class="material-icons right">skip_next</i>Next</button>

      <a class="modal-close waves-effect waves-red btn-flat" (click)="closeModal()" type="button"
        style="margin: 10px;"><i class="material-icons right">
          close </i>Cancel</a>
    </div>
  </form>
</div>

<div id="declinModal" class="modal">
  <div class="modal-content">
    <h4>Decline Request</h4>
    <p>Please Send a message to the client explaining why the Request has been declined.</p>
    <div class="input-field col s12">
      <textarea id="reason" [(ngModel)]="declineReason" class="materialize-textarea" rows="2"
        style="height: 75px;"></textarea>
      <label for="reason">Message</label>
    </div>
    <div class="row">
      <button class="col s12 btn waves-effect red" style="margin-bottom: 15px;" (click)="Decline()"
        [disabled]="declineReason == ''">
        <i class="material-icons right">thumb_down</i> Decline Request
      </button>

      <button class=" modal-close  col s12 btn-flat waves-effect blue-grey lighten-4" style="margin-bottom: 15px;">
        <i class="material-icons right">cancel</i> Close
      </button>
    </div>
  </div>
</div>

<div id="documentModal" class="modal">
  <div class="modal-content">
    <h6><strong>Documents Preview</strong></h6>
    <br />
    <div class="card center">
      <img class="responsive-img" *ngIf="ID_image" [src]="ID_image"  alt="Doc image"/>
    </div>
  </div>
  <div class="modal-footer">
    <a class="modal-close waves-effect waves-light btn red">Cancel</a>
  </div>
</div>

<div id="confirmationModal" class="modal">
  <div class="modal-content">
    <div>
      <h5><strong>Delete Account</strong></h5>
      <div class="valign-wrapper card-panel red lighten-5">
        <i class="material-icons left red-text" style="font-size: 40px;">
          info
        </i>
        <div>
          <h6>Are You sure you want to delete this request? </h6>
        </div>
      </div>
    </div>

    <h6 class="grey-text">User account</h6>
    <h6><strong>{{request?.email}}</strong></h6>
    <br />
  </div>
  <div class="modal-footer">
    <a class="modal-close waves-effect waves-light btn-flat"><strong>Cancel</strong></a>
    <a (click)="deleteRequest()" class="modal-close btn waves-effect waves-light red"><strong>Delete</strong></a>
  </div>
</div>