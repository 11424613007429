import { Component, OnInit } from '@angular/core';
import { PollsAndSurveyService } from 'src/app/services/firebase/polls-and-survey.service';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { toast } from 'materialize-css';

const pdfMake = require('pdfmake/build/pdfmake.js');
const pdfFonts = require('pdfmake-unicode/dist/pdfmake-unicode.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-survey-list',
  templateUrl: './survey-list.component.html',
  styleUrls: ['./survey-list.component.css']
})

export class SurveyListComponent implements OnInit {

  themeColor = environment.appTheme.adminThemeColor;
  defaultThemeColor = environment.appTheme.themeColor;
  buttonType: any;
  fontColor = environment.appTheme.fontColor;
  user: any;
  model: any[];
  showLoader = false;
  description ='';
  sectionName: string;
  editName = false;
  isSuperAdmin = false;
  permissions: any[];

  constructor(private surveyService: PollsAndSurveyService,
    private location: Location,
    private authService: AuthService,
    public appSettingsService: AppSettingsService
  ) {
    //this.user = this.authService.getGlobalUser();
    this.authService.getCurrentApplicantIDObservable().subscribe(
      user => {
        if (user) {
          this.user = user;
          if (user.isSuperAdmin) this.isSuperAdmin = true;
          if (user.permissions) this.permissions = user.permissions;
          else if (!this.isSuperAdmin) {
            $('.modal').modal();
            $('#permissionModal').modal('open');
          }
        }
      }
    );
    this.themeColor = this.appSettingsService.getAppThemeColor() ? this.appSettingsService.getAppThemeColor() : this.defaultThemeColor;
    this.buttonType = this.appSettingsService.getButtonType() ? this.appSettingsService.getButtonType() : 'rectangularButton';
  }

  ngOnInit() {
    this.showLoader = true;
    this.appSettingsService.getMenuName('PollsAndSurveys').valueChanges().subscribe(menus => {
      if (menus && menus.length > 0) {
        this.sectionName = menus[0].displayName;
      }
    });
    this.surveyService.getSurveyList().valueChanges().subscribe(srv => {
      if (srv.length > 0) {
        this.model = srv;
        this.model = this.model.sort(function (a, b) {
          return new Date(b.endDate).getTime() - new Date(a.endDate).getTime();
        });
      }
      this.showLoader = false;
    });

    this.appSettingsService.getMenuDescription('PollsAndSurveys').valueChanges().subscribe(menu => {
      if(menu) this.description = menu[0].description;
    })
  }

  editNameClicked() {
    this.editName = true;
  }

  saveName() {
    this.appSettingsService.UpdateMenu('PollsAndSurveys', this.sectionName).then((update) => {
      this.editName = false;
      toast({ html: `${update}`, classes: 'green' });
    }).catch((error) => {
      toast({ html: `${error}`, classes: 'red' });
    });
  }

  backClicked() {
    this.location.back();
  }

  public getInitials(name: any) {
    if (name) {
      name = name.replace(/<(?:.|\n)*?>/gm, '');
      return name.substring(0, 1);
    } else { return ''; }
  }

  async getDocumentDefinition(index) {
    return {
      info: {
        title: this.model[index].name
      },
      pageMargins: [60, 60, 60, 60],
      content: [
        {
          columns: [
            [{
              text: this.model[index].name,
              style: 'mainTitle'
            }],
            [
              {
                text: 'Start Date : ' + this.model[index].startDate,
              },
              {
                text: 'End Date : ' + this.model[index].endDate,
                color: 'red'
              }
            ]
          ],
        },
        this.getQuestions(index),
        await this.getCheckBox(),
        await this.getRadioButton(),
        this.getInput()
      ],
      styles: {
        mainTitle: {
          bold: true,
          fontSize: 16,
          alignment: 'left',
          margin: [0, 0, 0, 20]
        },
      }
    }
  }

  getQuestions(index) {
    let questionArray = [];
    this.model[index].questions.forEach(question => {
      let q = {
        text: question.questionNumber + '. ' + question.question
      }
      questionArray.push(q);
      let ans = this.getAnswers(question)
    });
    return questionArray;
  }

  getAnswers(question) {

  }

  async getCheckBox() {
    let img;
    let imageLink = "https://buildingtechsystems.com/wp-content/uploads/2021/06/unchecked-checkbox.png";
    const image = await this.getBase64FromUrl(imageLink);
    if (image) {
      img = {
        image: image,
        width: 20,
        alignment: 'left'
      }
    }
    return {
      columns: [
        img,
        {
          text: "  " + 'answer',
        }
      ]
    }
  }

  async getRadioButton() {
    let img;
    let imageLink = "https://icon-library.com/images/radio-button-icon/radio-button-icon-13.jpg";
    const image = await this.getBase64FromUrl(imageLink);
    if (image) {
      img = {
        image: image,
        width: 20,
        alignment: 'left'
      }
    }
    return {
      columns: [
        img,
        {
          text: "  " + 'answer',
        }
      ]
    }
  }

  getInput() {
    return {
      canvas: [
        {
          type: 'rect',
          x: 0,
          y: 0,
          w: 500,
          h: 60,
        }]
    }
  }

  async openPdf(index) {
    const documentDefinition = await this.getDocumentDefinition(index);
    pdfMake.createPdf(documentDefinition).open();
  }

  getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      }
    });
  }

}
