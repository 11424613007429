import { Component, OnInit } from '@angular/core';
import { Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { ActivityModel } from 'src/app/models/ActivityModel';
import { environment } from 'src/environments/environment';
import { ActivityService } from 'src/app/services/api/activity.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { toast } from 'materialize-css';
import { Location } from '@angular/common';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-add-activity',
  templateUrl: './add-activity.component.html',
  styleUrls: ['./add-activity.component.css']
})

export class AddActivityComponent implements OnInit {

  clientID = 0;
  addActivityForm: UntypedFormGroup;
  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  model: ActivityModel;

  constructor(private authService: AuthService,
    private fb: UntypedFormBuilder,
    private location: Location,
    private activityService: ActivityService) { }

  ngOnInit(): void {
    if (localStorage.getItem('applicantID')) {
      this.clientID = Number(localStorage.getItem('applicantID'));
    } else {
      this.clientID = this.authService.getCurrentApplicantID();
    }

    this.addActivityForm = this.fb.group({
      Activity: ['', [Validators.required]],
      description: ['', [Validators.required]],
    });

    this.model = new ActivityModel();
    this.model.client_id = this.clientID;
  }

  get Activity() { return this.addActivityForm.get('Activity'); }
  get description() { return this.addActivityForm.get('description'); }

  submitForm(value: any) {
    if (this.addActivityForm.valid) {
      this.model.Activity = value.Activity;
      this.model.ActivityDesc = value.description;
      this.activityService.saveActivity(this.model).subscribe(x => {
        if (x) {
          toast({ html: 'Activity Successfully Added!', classes: 'green' });
          this.location.back();
        }
      });
    }
  }

  backClicked() {
    this.location.back();
  }

}