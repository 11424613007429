
export class BandClientModel {

    givenname: string; 
    lastname: string;
    middle_initial: string;
    dob: Date;
    gender: number;
    address: string;
    city: string;
    province: number;
    postalcode: string;
    region_id: 0;
 
    SIN: string;
    Phone: string;
    email: string;
    band_name: string;
    band_id: string;
    cell_phone: string;
 
    same_address: boolean;
    mailing_address: string;
    mailing_city: string;
    mailing_province: string;
    mailing_postcode: string;
    messages: string;
    
    have_license: boolean;
    classno: string;
    license_expiry_date: Date;
    license_province: string;
    citizenship: string;
    language: string;
    status: number;
   
    signature: string;
    sign_date: Date;
 
 
    comments: string;
 
   }
  
   