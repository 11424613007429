import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
     name: 'NameStars'
})

export class MemberNameStarPipe implements PipeTransform {
   transform(value: string): any {
      let x = value.substring(0 , 1);
      for (let i = 1; i < value.length ; i++) { x = x + '*'; }
      return x;
   }
}
