import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { StatsService } from 'src/app/services/api/statsService';
import { ChartDataset, ChartOptions, ChartType } from 'chart.js';
// import { Color, Label } from 'ng2-charts';
import { SectionSettingService } from 'src/app/services/firebase/section-setting.service';
import { DatePipe } from '@angular/common';
import { DataService } from 'src/app/services/data.service';
import { RoutMetaService } from 'src/app/services/firebase/rout-meta.service';

declare var $: any;

@Component({
  selector: 'app-admin-home',
  templateUrl: './admin-home.component.html',
  styleUrls: ['./admin-home.component.css']
})

export class AdminHomeComponent implements OnInit {

  nationOfUse = environment.firstNation.name;
  // defaultThemeColor = environment.appTheme.themeColor;
  defaultThemeColor = '#000000';
  themeColor: any;
  buttonType: any;
  loading = false;
  user: any;
  userRole: any;
  defaultMenus: any[];
  adminMenus: any[];
  showDefaultMenu = true;
  permissions: any;
  isAdminMember = false
  isChiefOrCouncillor = false;
  isSuperAdmin = false;
  appMenu: any;
  public currentDate: string;
  metaTagSettings:any = null;
  //******************************************* Stat ********************* */
  birthsModel: any[];
  gendersModel: any[];
  public lineChartData: any;
  public lineChartLabels: any;
  public lineChartOptions: ChartOptions = {
    responsive: true,
    
    // legend: {
    //   position: 'bottom',
    // },

    // tooltips: {
    //   callbacks: {
    //     label: function (tooltipItem, data) {
    //       var label = ' Births +';

    //       if (label) {
    //         label += ' ';
    //       }
    //       const currentYear = +data.datasets[0].data[tooltipItem.index];
    //       const previousYear = +data.datasets[0].data[tooltipItem.index - 1];

    //       label += currentYear - previousYear;
    //       return label;
    //     }
    //   }
    // },
    plugins: {
      legend: {
        position: 'bottom',
      },
      tooltip: {
        callbacks: {
          label: (tooltipItem) => {
            const currentYear = +tooltipItem.raw;
            const previousYear = +this.lineChartData.datasets[0].data[tooltipItem.dataIndex - 1];
            return `Births + ${currentYear - previousYear}`;
          },
        },
      },
    },
    animation: {
      duration: 1000,
      easing: 'easeOutQuad',
    },
    elements: {
      line: {
        tension: 0.4,
      },
    },
  };

  // public lineChartColors: Color[] = [
  //   {
  //     // borderColor: "purple",
  //     backgroundColor: 'transparent',
  //     borderWidth: 2,
  //   },
  // ];
  public lineChartColors = [
    {
      backgroundColor: 'transparent',
      borderColor: '#42A5F5',
      borderWidth: 2,
    },
  ];

  public lineChartLegend = true;
  public lineChartType = 'line';
  public lineChartPlugins = [];

  // Pie Chart
  // public pieChartOptions: ChartOptions = {
  //   cutoutPercentage: 60,
  //   responsive: true,
  //   legend: {
  //     position: 'bottom',
  //   },
  // };
  public pieChartOptions: ChartOptions <'doughnut'> = {
    responsive: true,
    plugins: {
      legend: {
        position: 'bottom',
      },
    },
    cutout: '60%',
  };

  public pieChartLabels: any;
  public pieChartData: any[];
  // public pieChartType: ChartType = 'pie';
  public pieChartType: ChartType = 'doughnut';
  public pieChartLegend = true;
  public pieChartPlugins = [];
  public pieChartColors = [
    {
      backgroundColor: ['#cbe4ff', '#facedd'],
    },
  ];

  

  constructor(private authService: AuthService,
    private router: Router,
    private settingsService: AppSettingsService,
    private statsService: StatsService,
    private sectionService: SectionSettingService,
    private datePipe: DatePipe,
    private dataService: DataService,
    private routMeta: RoutMetaService,
  ) {
    this.themeColor = this.settingsService.getAppThemeColor() ? this.settingsService.getAppThemeColor() : this.defaultThemeColor;
    this.buttonType = this.settingsService.getButtonType() ? this.settingsService.getButtonType() : 'rectangularButton';

    this.authService.getCurrentApplicantIDObservable().subscribe(
      user => {
        if (user) {
          this.userRole = user.role;
          if (user.isSuperAdmin) this.isSuperAdmin = true;
          if (user.permissions) this.permissions = user.permissions;
          else if (!this.isSuperAdmin) {
            $('.modal').modal();
            $('#permissionModal').modal('open');
          }
        }
      }
    );
  }

  ngOnInit() {
    this.loading = true;
    this.authService.fireMember.subscribe(data => {
      if (data) {
        this.user = data;
      }
    });

    this.settingsService.getAdminMenus().valueChanges().subscribe(menus => {
      if (menus && menus.length > 0) {
        this.adminMenus = menus;
        this.adminMenus = this.adminMenus.sort((a, b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0));
        this.showDefaultMenu = false;
      } else {   
        this.defaultMenus = this.dataService.getAdminMenus();
        this.adminMenus = this.defaultMenus.sort((a, b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0));
        this.showDefaultMenu = true;
     }
      this.loading = false;
    });

    this.sectionService.getSections().valueChanges().subscribe(sec => {
      if (sec && sec.length > 0) {
        let sections = sec.filter(s => s.addToAdmin == 'dashboard');
        if (sections && sections.length > 0) {
          sections.forEach(sec => {
            if (!this.adminMenus) {
              this.adminMenus = [];
            }
            this.adminMenus.push(
              {
                name: sec.name,
                displayName: sec.displayName,
                isEnabled: true,
                icon: sec.icon,
                index: this.adminMenus.length,
                id: sec.id
              }
            )
          });
          this.adminMenus = this.adminMenus.sort((a, b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0));
        }
      }
    });

    this.statsService.GetDemographics().subscribe(data => {
      if (data) {
        if (data.births && data.births.length > 0) {
          this.birthsModel = data.births;
          // Linear Chart
          let chartData: ChartDataset[];
          // let years: Label[] = [];
          let years: string[] = [];
          let count: any[] = [];
          let total: any;
          total = this.birthsModel[0].initial;
          this.birthsModel.forEach(e => {
            years.push(e.yr);
            total += e.cnt;
            count.push(total);
          });
          // chartData = [
          //   { data: count, label: 'Births By Year' }
          // ];
          this.lineChartLabels = years;
          this.lineChartData = chartData;
          this.lineChartData = {
            labels: years,
            datasets: [
              { data: count, label: 'Births By Year' }
            ]
          };
        }

        if (data.genders && (data.genders[0].Male != 0 || data.genders[0].Female != 0)) {
          this.gendersModel = data.genders;
          // Pie Chart
          let gendersVal: number[] = [];
          // let lbl: Label[] = ['Male ' + ' ' + this.gendersModel[0].Male, 'Female ' + ' ' + this.gendersModel[0].Female]
          let lbl: string[] = ['Male ' + ' ' + this.gendersModel[0].Male, 'Female ' + ' ' + this.gendersModel[0].Female];
          gendersVal.push(this.gendersModel[0].Male);
          gendersVal.push(this.gendersModel[0].Female);
          this.pieChartLabels = lbl;
          this.pieChartData = [{ data: gendersVal }];
        }
      }
    });

    //******************************re check Here**************** */
    if (this.authService.globalUser) {
      if (this.authService.globalUser.isChief || this.authService.globalUser.isCouncillor) {
        this.isChiefOrCouncillor = true;
      }
      if (this.authService.globalUser.isMember) {
        this.isAdminMember = true;
      }
    }

    // =============== todays date 
    const today = new Date();
    this.currentDate = this.datePipe.transform(today, 'EEEE, dd MMMM yyyy');

    setTimeout(() => {
      $('.modal').modal();
    }, 25);
  }

  getMenuSetting(item) {
    if (this.adminMenus) {
      const menu = this.adminMenus.find(m => m.name == item);
      if (menu && menu.isEnabled) {
        return true;
      }
    }
    return false;
  }

  getPermission(menu) {
    if (menu == "Logout" || this.isSuperAdmin) return true;
    if (this.permissions && this.permissions.length > 0) {
      const p = this.permissions.find(p => p.menu == menu);
      if (p) return true;
    }
    return false;
  }

  getSubmenuPermission(menu) {
    if (this.permissions && this.permissions.length > 0) {
      const p = this.permissions.find(p => p.menu == menu);
      if (p) {
        return p.submenus;
      }
    }
    return false;
  }

  navigate(url: any, item) {
    if (item && !this.getPermission(item)) {
      $('.modal').modal();
      $('#permissionModal').modal('open');
      return;
    }
    if (url) {
      this.router.navigate([url]);
    } else { this.router.navigate(['/']); }
  }

  goToSetting() {
    this.router.navigate(['admin/dashboard-sub-menu/settings']);
  }

  getInitials(firstName: string, lastName: string): string {
    return `${firstName?.charAt(0)?.toUpperCase() || ''}${lastName?.charAt(0)?.toUpperCase() || ''}`;
  }

  itemClicked(item: any) {
    if (this.getPermission(item.name)) {
      const menusWithSubmenus = ['MyNation', 'Manage', 'Settings', 'Stats', 'Housing'];
      if (menusWithSubmenus.includes(item.name)) {
        localStorage.setItem('From', item.name);
        const sp = item.submenus;
        if (sp) localStorage.setItem('submenus', JSON.stringify(sp));
        let submenu_permission = this.getSubmenuPermission(item.name);
        if (submenu_permission) {
          localStorage.setItem('permissions', JSON.stringify(submenu_permission));
        }
        this.router.navigate(['admin/dashboard-sub-menu']);
      }
      else if (item.name === 'NewsAndEvents') {
        localStorage.setItem('From', 'NewsAndEvents');
        this.router.navigate(['admin/news-events']);
      }
      else if (item.name === 'PollsAndSurveys') {
        localStorage.setItem('From', 'PollsAndSurveys');
        this.router.navigate(['admin/survey-list']);
      }
      else if (item.name === 'Communication') {
        this.router.navigate(['admin/messages']);
      }
      else if (item.name == 'CustomSection') {
        localStorage.setItem('From', 'CustomSection');
        this.router.navigate(['admin/custom-section/', item.id]);
      }
    }
  }

  switchAccount() {
    this.router.navigate(['/member/member-main-home']);
  }

  signOut() {
    localStorage.clear();
    this.authService.signOut();
  }

  getMetaTagSettings() {
    this.settingsService.getMetadata().valueChanges().subscribe((metaTags: any) => {
      let lastIndex = metaTags?.length - 1;
      console.log("Meta Tags", metaTags)
      if (lastIndex || lastIndex === 0) {
        this.metaTagSettings = metaTags?.[lastIndex];
        // this.appSettingsForm.get('appTitle').setValue(this.metaTagSettings?.title)
        this.routMeta.updateTitle(metaTags[0].appTitle);
        // this.appSettingsForm.get('keywordSEO').setValue(this.metaTagSettings?.description);
        this.routMeta.updateDescription(metaTags[0].description);
        // this.appSettingsForm.get('Keywords').setValue(this.metaTagSettings?.KeyWords);
        this.routMeta.updateKeywords(metaTags[0].keyWords ? metaTags[0].keyWords : "");
  
      }
    });
  }

}

