<div class="body-h">
  <br>
  <div class="header-setting b-m-2">
    <div class="col s12 m9 l9">
      <h4 class="">Upload Your Supporting Documents </h4>
    </div>
    <div class="col s12 m2 l2">
      <button class=" btn margin-right waves-effect blue right" type="button" style="margin-bottom: 15px;"
        (click)="backClicked()"><i class="material-icons left">arrow_back</i>Back
      </button>
    </div>
    <div class="col s12 m11 l1"></div>
  </div>

  <main class="StickyContent white row">
    <div class="row">
      <div class="col s12 m1 l1"> </div>
      <div class="col s12 m10 l10">
        <app-upload-asets-document [clientID]="clientID" [callerForm]="callerName"
          (documentSaved)="parentEventHandlerFunction($event)"></app-upload-asets-document>
      </div>
      <div class="col s12 m1 l1"> </div>
    </div>
  </main>
</div>