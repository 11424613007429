<div class="row">

  <div class="col s12 ">
    <div class="body-h">

      <div class="header-setting b-m-2 ">
        <div class="col l12 m12 s12">
          <h4 *ngIf="!boardModel">Add Board</h4>
          <h4 *ngIf="boardModel">Update Board </h4>
          <select class="browser-default hide-on-large-only" (change)="navigate($event.target.value)">
            <option value="AboutUs">About Us</option>
            <option value="NationInfo">Nation Info</option>
            <option value="Departments">Department</option>
            <option value="Governance" disabled selected>Governance</option>
            <option value="AreaOfInterest">Area of Interest</option>
          </select>
        </div>
      </div>

      <main class="StickyContent white">
        <div class="row col s12">
          <div class="col s12 m12 l11">
            <form [formGroup]="addBoardForm" (ngSubmit)="addBoard(addBoardForm.value)">

              <div class="input-field col l10 s12 ">
                <strong class="label-input">Change Board Name Color : </strong> <input id="nameColor"
                  formControlName='nameColor' type="color">
              </div>

              <div class="input-field col l5 s12">
                <div class="label-input">Board Name</div>
                <input id="name" type="text" formControlName='name' required class="validate" autocomplete="off"
                  [ngStyle]="{'color': nameColor}" placeholder="Board Name" />
                <div *ngIf="(name.dirty || name.touched) && name.invalid">
                  <small *ngIf="name.errors.required" class="red-text"> The Board Name is required. </small>
                </div>
              </div>

              <div class="input-field col l5 s12">
                <div class="label-input">Phone</div>
                <!-- <input id="phone" formControlName='phone' type="text" [textMask]="{mask: phoneNumberMask}"
                  class="validate" placeholder="Phone" /> -->
                  <input id="phone" formControlName='phone' type="text" 
                  class="validate" placeholder="Phone" />
                <div *ngIf="(phone.dirty || phone.touched) && phone.invalid">
                  <small *ngIf="phone.errors.pattern" class="red-text"> Invalid Phone Number. </small>
                </div>
              </div>

              <div class="input-field col l5 s12">
                <div class="label-input">Email</div>
                <input id="email" formControlName='email' type="text" class="validate" placeholder="Email">
                <div *ngIf="(email.dirty || email.touched) && email.invalid">
                  <small *ngIf="email.errors.email" class="red-text"> Invalid email address. </small>
                </div>
              </div>

              <div class="input-field col l5 s12">
                <div class="label-input">Website</div>
                <input id="website" formControlName='website' type="text" class="validate" placeholder="Website">
                <div *ngIf="(website.dirty || website.touched) && website.invalid">
                  <small *ngIf="website.errors.pattern" class="red-text"> Invalid web address. </small>
                </div>
              </div>

              <div class="input-field col l10 s12">
                <div class="label-input">Mandate</div>
                <textarea id="mandate" formControlName='mandate' class="materialize-textarea"
                  placeholder="Mandate"></textarea>
              </div>

              <div class="row">
                <div class="row card-panel white" *ngIf="showLogoInput">
                  <div class="row col s12">
                    <p><strong>Please Select A Logo From Your Library </strong></p>
                    <div class="file-field input-field">
                      <div class="btn">
                        <span>Browse</span>
                        <input type="file" formControlName="logo" accept="image/*"
                          (change)="handleImageInput($event.target.files)" />
                      </div>
                      <div class="file-path-wrapper">
                        <input formControlName="logo" class="file-path" type="text"
                          placeholder="Select Logo From Device Library" />
                      </div>
                    </div>
                    <p class="red-text" *ngIf="imgErrorMsg">
                      <strong><small><i class="material-icons">error_outline</i> {{imgErrorMsg}}</small></strong>
                    </p>
                  </div>

                  <div class="row center" *ngIf="base64Image">
                    <div class="row col s12">
                      <img class="responsive-img" [src]="base64Image" height="40%" width="40%"  alt="Board image" />
                    </div>
                    <a class="btn-floating btn-small waves-effect waves-light red right" (click)="removeFile()"><i
                        class="material-icons">delete</i></a>
                  </div>
                </div>

                <div class="row card-panel white" *ngIf="showImageFromServer">
                  <div class="row col s12 " *ngIf="boardModel.logo">
                    <h6 class="label-input">Logo</h6>
                    <div class="row col s12">
                      <img class="responsive-img" [src]="boardModel.logo" height="40%" width="40%"  alt="View Board image" />
                    </div>
                    <a class="btn-floating btn-small waves-effect waves-light red right" (click)="removeSavedFile()"><i
                        class="material-icons">delete</i></a>
                  </div>
                </div>
              </div>

              <button type="submit" [disabled]="!addBoardForm.valid" class="col s12 btn waves-effect green"
                style="margin-bottom: 15px;">
                <i *ngIf="!boardModel" class="material-icons right">add_circle_outline</i>
                <i *ngIf="boardModel" class="material-icons right">update</i>
                <span *ngIf="!boardModel">Add Board</span>
                <span *ngIf="boardModel">Update Board</span>
              </button>

              <a [routerLink]="['/admin/dashboard-sub-menu/manage-board-members/',boardModel.id]" *ngIf="boardModel"
                type="button" class="col s12 btn waves-effect blue white-text" style="margin-bottom: 15px;">
                <i class="material-icons right">groups</i> Manage Board Members
              </a>

              <a [routerLink]="['/admin/dashboard-sub-menu/archived-board-members/',boardModel.id]" *ngIf="boardModel"
                type="button" class="col s12 btn waves-effect orange white-text" style="margin-bottom: 15px;">
                <i class="material-icons right">groups</i> Archived Board Members
              </a>

              <button *ngIf="boardModel" (click)="openDeleteModal()" type="button"
                class="col s12 btn waves-effect red white-text" style="margin-bottom: 15px;">
                <i class="material-icons right">remove</i> Delete Board
              </button>

              <a (click)="backClicked();" [ngClass]="'col s12 btn waves-effect ' + themeColor">
                <i class="material-icons left">arrow_back_ios</i>
                Back To Previous Page </a>
            </form>
          </div>
        </div>

        <div id="deleteBoardConfirmation" class="modal b-n-1">
          <div class="modal-content">
            <h5 class="col s11">Delete Board </h5>
            <i class="material-icons right modal-close col s1">close</i><br />

            <div class="valign-wrapper card-panel red lighten-5  row col s12">
              <i class="material-icons left red-text" style="font-size: 40px; font-weight: 100;">
                warning_amber
              </i>
              <div class="modal-delete">
                <h6>Delete Board</h6>
                <h6>Are you sure you want to Remove this Board ?</h6>
              </div>
            </div>
          </div>
          
          <div class="modal-footer">
            <button class="modal-close btn delete-button" (click)="deleteBoard();">DELETE</button>
          </div>
        </div>
      </main>
    </div>
  </div>
</div>