import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { map } from 'rxjs/internal/operators/map';
import { GroupConversationsModel } from 'src/app/models/Conversations.Model';
import { PrivateConversationsModel } from 'src/app/models/Conversations.Model';

@Injectable({
    providedIn: 'root'
})

export class ConversationsService {

    conversationListRef: AngularFirestoreCollection<any>;
    conversationRef: AngularFirestoreDocument<any>;

    constructor(private db: AngularFirestore) { }

    createPrivateConversation(model: PrivateConversationsModel) {
        const newContent = {
            id: this.db.createId(),
            dateCreated: model.dateCreated,
            user_1: model.user_1,
            user_2: model.user_2,
            lastMessage: model.lastMessage,
            // unreadCount: model.unreadCount
        }
        this.db.collection('Private-Chat').doc(newContent.id).set(newContent);
        return newContent;
    }

    createGroupConversation(model: GroupConversationsModel) {
        const newContent = {
            id: this.db.createId(),
            dateCreated: model.dateCreated,
            name: model.name,
            users: model.users,
            isPrivate: model.isPrivate,
            profilePicture: model.profilePicture,
            lastMessage: model.lastMessage,
            admin: model.admin,
            previousMembers: model.previousMembers
            // unreadCount: model.unreadCount
        }
        this.db.collection('Group-Chat').doc(newContent.id).set(newContent);
        return newContent;
    }

    getPrivateChatsByUserId1(id: string): any {
        this.conversationListRef = this.db.collection('Private-Chat', ref => ref.where('user_1.id', '==', id));
        return this.conversationListRef;
    }
    getPrivateChatsByUserId2(id: string): any {
        this.conversationListRef = this.db.collection('Private-Chat', ref => ref.where('user_2.id', '==', id));
        return this.conversationListRef;
    }
    getGroupChatsByUserId(id: string): any {
        this.conversationListRef = this.db.collection('Group-Chat', ref => ref.where('users', "array-contains", id));
        return this.conversationListRef;
    }
    getPublicGroups(): any {
        return this.db.collection('Group-Chat', ref => ref.where('isPrivate', "==", false));
    }
    updatePrivatelastMessage(id: any, messageObj: any) {
        return this.db.collection('Private-Chat').doc(id).update({ lastMessage: messageObj });
    }
    updateGrouplastMessage(id: any, messageObj: any) {
        return this.db.collection('Group-Chat').doc(id).update({ lastMessage: messageObj });
    }

    async addUnreadCount(conversationId: any, userId: any) {
        this.conversationRef = await this.db.doc('Private-Chat/' + conversationId);
        this.conversationRef.get()
            .pipe(map(doc => doc.data()))
            .subscribe(x => {
                if (x) {
                    if (x.user_1.id == userId) {
                        x.user_1.unreadCount += 1;
                        return this.db.collection('Private-Chat').doc(conversationId).update(x);

                    } else if (x.user_2.id == userId) {
                        x.user_2.unreadCount += 1;
                        return this.db.collection('Private-Chat').doc(conversationId).update(x);
                    }
                }
            });
    }

    async removeUnreadCount(conversationId: any, userId: any) {
        this.conversationRef = await this.db.doc('Private-Chat/' + conversationId);
        this.conversationRef.get()
            .pipe(map(doc => doc.data()))
            .subscribe(x => {
                if (x) {
                    if (x.user_1.id == userId) {
                        x.user_1.unreadCount = 0;
                        return this.db.collection('Private-Chat').doc(conversationId).update(x);

                    } else if (x.user_2.id == userId) {
                        x.user_2.unreadCount = 0;
                        return this.db.collection('Private-Chat').doc(conversationId).update(x);
                    }
                }
            });
    }

    // getTotalUnreadCountByUserId(id:any): any{
    //     let conversationListRef1 = this.db.collection('Private-Chat', ref => ref.where('user_1.id', '==', id));
    //     let conversationListRef2 = this.db.collection('Private-Chat', ref => ref.where('user_2.id', '==', id));
    //     let unreadCount = 0;
    //     conversationListRef1.get().toPromise()
    //     .then(function(querySnapshot) {
    //             querySnapshot.forEach(function(doc) {
    //                 let conversation = doc.data();
    //                 unreadCount += conversation.user_1.unreadCount;
    //             });            })
    //         .catch(function(error) {
    //             console.log("Error getting documents: ", error);
    //         });

    //         conversationListRef2.get().toPromise()
    //     .then(function(querySnapshot) {
    //             querySnapshot.forEach(function(doc) {
    //                 let conversation = doc.data();
    //                 unreadCount += conversation.user_2.unreadCount;
    //             });
    //             return unreadCount;
    //         })
    //         .catch(function(error) {
    //             console.log("Error getting documents: ", error);
    //         });
    //         // setTimeout(() => {
    //         //     return unreadCount;
    //         // }, 2000);
    // }

    updateGroupMembersList(id, users: any) {
        return this.db.collection('Group-Chat').doc(id).update({ users: users });
    }

    updateGroupInformation(model: GroupConversationsModel) {
        const newContent = {
            id: model.id,
            dateCreated: model.dateCreated,
            name: model.name,
            users: model.users,
            isPrivate: model.isPrivate,
            profilePicture: model.profilePicture,
            lastMessage: model.lastMessage,
            admin: model.admin,
            previousMembers: model.previousMembers

        }
        return this.db.collection('Group-Chat').doc(newContent.id).update(newContent);
    }

    deletePrivateChat(id: string) {
        return this.db.collection('private-Chat').doc(id).delete();
    }

    deleteGroupChat(id: string) {
        this.conversationRef = this.db.doc('Group-Chat/' + id);
        this.conversationRef.get()
            .pipe(map(doc => doc.data()))
            .subscribe(x => {
                if (x) {
                    this.db.collection('Archived-Chats').doc(x.id).set(x);
                    return this.db.collection('Group-Chat').doc(x.id).delete();
                }
            });
    }

}