<form [formGroup]="platform" class="flex items-center w-full gap-x-2">
  <p>
    <label>
      <input type="checkbox" formControlName="value" class="filled-in" [disabled]="platformHasError"
        [ngClass]="{ 'gray darken-2': platformHasError }" />

      <span class="black-text">
        {{ platform.controls["platform"].value | titlecase }}
        <small class="red-text no-margin no-padding" *ngIf="platformHasError && errorMessage !== ''"
          style="display: block;">
          {{ errorMessage }}
        </small>
      </span>
    </label>
  </p>
</form>