<div class="row">

    <!-- =========== Left Navigation ============= -->
    <div class="col l1" *ngIf="user">
        <app-side-menus [user]="user"></app-side-menus>
    </div>

    <!-- =========== Content============= -->
    <div class="col s12 m12 l8">
        <div class="hide-on-large-only drop-down valign-wrapper">
            <a class='col s12 m12 dropdown-trigger btn-flat hide-on-large-only ' data-target='menu-dropdown'>
                <i class="material-symbols-outlined center">{{selectedMenu.icon}}</i>{{selectedMenu.name}}<i
                    class="material-symbols-outlined col s2 right">expand_more</i></a>

            <ul id='menu-dropdown' class='dropdown-content col s12 m12 '>
                <ng-template [ngTemplateOutlet]="menus"></ng-template>
            </ul>
        </div>
        <router-outlet>
        </router-outlet>
    </div>

    <!-- =========== Right Navigation ============= -->
    <div class="col l3 s12 m4">
        <br />
        <ul class="right-nav hide-on-med-and-down">
            <h4 *ngIf="!editName" class="hide-on-med-and-down">
                <span *ngIf="sectionName">{{sectionName}}</span>
                <span *ngIf="!sectionName">News and Events</span>
                <i class="material-icons edit-name " [ngStyle]="{'color': themeColor}"
                    (click)="editNameClicked()">edit</i>
            </h4>
            <div class="input-field" *ngIf="editName">
                <input type="text" [(ngModel)]="sectionName" class="left col s6 validate" autocomplete="off" />
                <button type="button" class="btn ll-m-5" [ngStyle]="{'background-color': themeColor}"
                    (click)="saveName()">Save</button>
            </div>
            <div class="hide-on-med-and-down">
                <ul style="cursor: pointer;" class='col s12'>
                    <ng-template [ngTemplateOutlet]="menus"></ng-template>
                </ul>
            </div>

            <ng-template #menus class="sideNav">
                <ul class="collapsible">
                    <li (click)="select('Mange News', 'breaking_news_alt_1')"
                        [routerLink]="['/admin/news-events/home-page']">
                        <div class="collapsible-header"><i
                                class="material-symbols-outlined ">breaking_news_alt_1</i>News</div>
                        <div class="collapsible-body"></div>
                    </li>

                    <li (click)="select('Mange Events', 'event')" [routerLink]="['/admin/news-events/event-list']">
                        <div class="collapsible-header"><i class="  material-symbols-outlined ">event</i>Events
                        </div>
                        <div class="collapsible-body"></div>
                    </li>

                    <li (click)="select('Archived', 'move_to_inbox')"
                        [routerLink]="['/admin/news-events/archived-post']">
                        <div class="collapsible-header"><i class=" material-symbols-outlined ">move_to_inbox</i>Archived
                            News</div>
                        <div class="collapsible-body"></div>
                    </li>
                </ul>
            </ng-template>
        </ul>
    </div>

    <!-- ===================== Modal =============================== -->
    <div id="permissionModal" class="modal center b-n-1">
        <div class="modal-content">
            <i class="material-icons right modal-close">close</i><br />
            <h6 class="title-section2 bold-600 black-text">Unauthorized</h6>
            <p class="sub-title-section bold-100">Sorry, but you don't have permission to access this page.</p>
            <img src="https://img.icons8.com/dotty/344/id-not-verified.png" height="150px" />
            <p class="sub-title-section bold-100">Please Contanct your Administrator to gain access.</p>
        </div>
    </div>

</div>