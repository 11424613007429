import { Component, OnInit } from '@angular/core';
import SignaturePad from 'signature_pad';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { toast } from 'materialize-css';

import { MemberService } from 'src/app/services/api/member.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { PseApplicationService } from 'src/app/services/api/pse-application.service';
import { Location } from '@angular/common';
import { PseContractService } from 'src/app/services/api/pse-contract.service';
import { PseContractModel } from 'src/app/models/PseContractModel';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-pse-release-form',
  templateUrl: './pse-release-form.component.html',
  styleUrls: ['./pse-release-form.component.css']
})

export class PseReleaseFormComponent implements OnInit {

  nationofUse = environment.firstNation.name;
  nationName = environment.firstNation.displayName;
  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;

  public dateMask = [/[0-3]/, /[0-9]/, '/', /[0-1]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  public dateformat = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;

  step = 1;
  studentName = '';
  start_date = '';
  end_date = '';

  applicantID = 0;
  pse_app_id = 0;
  Institute = '';
  Program = '';

  //signature
  signed = false;
  canvas: any;
  signaturePad: SignaturePad;
  showSignaturePad = false;
  releaseForm: UntypedFormGroup;

  releaseFormModel: PseContractModel;

  myDpOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'dd/mm/yyyy',
    markCurrentDay: true,
  };

  constructor(private fb: UntypedFormBuilder,
    private location: Location,
    private authService: AuthService,
    private pseContractService: PseContractService) { }

  ngOnInit(): void {

    if (localStorage.getItem('applicantID')) {
      this.applicantID = Number(localStorage.getItem('applicantID'));
    } else {
      this.applicantID = this.authService.getCurrentApplicantID();
    }
    if (this.applicantID == 0) { this.authService.signOut(); }

    this.releaseForm = this.fb.group({
      //student_id: ['', [Validators.required]],
      student: ['', [Validators.required]],
      signature: ['', [Validators.required]],
      date: ['', [Validators.required]],
    });

    // this.memberService.getMember(this.applicantID).subscribe(x => {  
    //   if (x) {
    //       let member = x[0];
    //       this.studentName = member.GivenName + ' '+ member.LastName;
    //       this.releaseForm.patchValue({ 'student': this.studentName });
    //       if (member.current_PSE_app_id) this.pse_app_id = member.current_PSE_app_id;
    //       setTimeout(() => {
    //         $('select').formSelect();
    //         M.updateTextFields();
    //       }, 10);
    //       if (this.pse_app_id > 0 ){
    //         this.pseService.getEducationPlan(this.pse_app_id).subscribe(edu => {
    //           if (edu) {
    //             let eduPlanData = edu[0];
    //             if (eduPlanData.sponsor_from_date) {this.start_date = this.formatDates(eduPlanData.sponsor_from_date) };
    //             if (eduPlanData.sponsor_to_date) {this.end_date = this.formatDates(eduPlanData.sponsor_to_date) };
    //             if (eduPlanData.Institute) this.Institute = eduPlanData.Institute;
    //             if (eduPlanData.Program) this.Program = eduPlanData.Program;
    //           }
    //         });
    //       }
    //   }
    // });

    this.pseContractService.getClientInfo(this.applicantID).subscribe(x => {
      if (x) {
        let member = x[0];
        this.studentName = member.GivenName + ' ' + member.LastName;
        this.releaseForm.patchValue({ 'student': this.studentName });

        setTimeout(() => {
          $('select').formSelect();
          M.updateTextFields();
        }, 25);

        if (member.sponsor_from_date) { this.start_date = this.formatDates(member.sponsor_from_date) };
        if (member.sponsor_to_date) { this.end_date = this.formatDates(member.sponsor_to_date) };
        if (member.Institute) this.Institute = member.Institute;
        if (member.Program) this.Program = member.Program;
      };
    });

    this.releaseFormModel = new PseContractModel();

    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }

  formatDates(dateValue) {
    if (dateValue) {
      //   //1987-07-19T00:00:00
      let dateArray = dateValue.split('-');
      let year = dateArray[0];
      let month = dateArray[1];   //- 1;
      let day = dateArray[2].substring(0, 2);
      return day + '/' + month + '/' + year;
    } else {
      return '';
    }
  }

  //signature
  get signature() { return this.releaseForm.get('signature'); }
  get date() { return this.releaseForm.get('date'); }

  submitReleaseForm(values) {
    this.releaseFormModel.applicantID = this.applicantID;
    this.releaseFormModel.student = values.student;
    this.releaseFormModel.signature = this.signaturePad.toDataURL().replace('data:image/png;base64,', '');
    this.releaseFormModel.date = values.date.singleDate.jsDate.toDateString();
    this.releaseFormModel.app_id = this.pse_app_id;

    this.pseContractService.postReleaseForm(this.releaseFormModel).subscribe(x => {
      toast({ html: 'Release Form Successfully Signed!', classes: 'green' });
      this.backClicked();
    });
  }

  submitSignature(values: any) {
    if (!this.signaturePad || this.signaturePad.isEmpty()) {
      toast({ html: 'Please Sign the Release Form !', classes: 'red' });
      return;
    }
  }

  sign() {
    this.step = 2;
    this.showSignaturePad = true;
    setTimeout(() => {
      this.canvas = document.querySelector('canvas');
      const parentWidth = $(this.canvas).parent().outerWidth();
      this.canvas.setAttribute('width', parentWidth);
      this.canvas.setAttribute('height', '175px');
      this.signaturePad = new SignaturePad(this.canvas, {
        backgroundColor: 'rgb(255,255,255)'
      });
    }, 50);
  }

  approveSignature() {
    if (this.signaturePad.isEmpty()) {
      toast({ html: 'Please Sign the Release Form!', classes: 'red' });
      return;
    } else {
      const dataUrl = this.signaturePad.toDataURL();
      this.signature.patchValue(dataUrl);
      this.signed = true;
      this.showSignaturePad = false;
      toast({ html: 'Release Form Signed', classes: 'green' });

      this.step = 1;
      this.showSignaturePad = false;

      setTimeout(() => {
        $('select').formSelect();
        M.updateTextFields();
      }, 25);
    }
  }

  clearSignature() {
    this.signed = false;
    this.signaturePad.clear();
    this.signature.reset();
  }

  onBack() {
    this.step = 1;
    this.showSignaturePad = false;
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }

  backClicked() {
    this.location.back();
  }

  onDateChange(event: IMyDateModel): void {
    this.releaseForm.patchValue({ 'date': event.singleDate.formatted });
  }

}