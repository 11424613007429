import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { RequestToJoinModel } from 'src/app/models/RequestToJoin.Model';

@Injectable({
  providedIn: 'root'
})
export class RequesttojoinService {

  constructor(private db: AngularFirestore) { }

  requestToJoinListRef: AngularFirestoreCollection<any>;
  requestToJoinRef: AngularFirestoreDocument<any>;

  newRegistrationRequest(model: RequestToJoinModel) {

    const newRequest = {
      id : this.db.createId(),
      firstName : model.firstName,
      middleName : model.middleName,
      lastName : model.lastName,
      email : model.email,
      requestDate : new Date(),
      archived: false,
    };

    return this.db.collection('RequestToJoin').doc(newRequest.id).set(newRequest);
  }

  archiveRequest(model: RequestToJoinModel) {
    const newRequest = {
      id : model.id,
      firstName : model.firstName,
      middleName : model.middleName,
      lastName : model.lastName,
      email : model.email,
      requestDate : model.requestDate,
      archived: true,
    };
    return this.db.collection('RequestToJoin').doc(newRequest.id).update(newRequest);
  }

  getRegistrationRequestList(): AngularFirestoreCollection<RequestToJoinModel> {
    this.requestToJoinListRef = this.db.collection('RequestToJoin');
    return this.requestToJoinListRef;
  }

  getRegistrationRequestByID(id: string): AngularFirestoreDocument<RequestToJoinModel> {
    this.requestToJoinRef = this.db.doc('RequestToJoin/' + id);
    return this.requestToJoinRef;
  }

  saveFamilyMembers(model){
    model.id  = this.db.createId();
    return this.db.collection('Request-FamilyMembers').doc(model.id).set(model);
  }
}
