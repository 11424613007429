import { Component, OnInit, ViewChild } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { GovernanceService } from 'src/app/services/firebase/governance.service';
import { toast } from 'materialize-css';
import { MediaService } from 'src/app/services/api/media.service';
import { DepartmentService } from 'src/app/services/firebase/department.service';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { Location } from '@angular/common';
import { createMask } from '@ngneat/input-mask';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-manage-chief-account',
  templateUrl: './manage-chief-account.component.html',
  styleUrls: ['./manage-chief-account.component.css']
})

export class ManageChiefAccountComponent implements OnInit {
  @ViewChild(DataTableDirective, { static: true }) datatableElement: DataTableDirective;
  // dtOptions: DataTables.Settings = {};
  // dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  addChiefForm: UntypedFormGroup;
  postObj: any;
  today = new Date();
  appointmentDate: any;
  expiryDate: any;
  selectedFile: File = null;
  profilePic: any;
  governanceId: any;
  edit_governance: any;
  editing_chief: any;
  base64Image: any;
  mobilePattern = /^\s*([\(])[[0-9]{2}\d{1}[\)]\s*[\-]?[\.]?\s*\d{3}\s*[\-]?[\.]?\s*\d{4}$/;
  // public phoneNumberMask = ['(', /[0-9]/, /[0-9]/, /[0-9]/, ')', ' ', /[0-9]/, /[0-9]/, /[0-9]/,
  //  ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  phoneInputMask = createMask<string>({
    mask: '(999) 999-9999',
    guide: true,
    showMaskTyped: true,
  });

  public urlPattern = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
  departmentsList: any;
  departmentsOptions: any[];
  selectedDepartmentsList: any[];
  selectedMemberFirstName: '';
  selectedMemberLastName: '';
  myimgLink: any;
  binDoc: File;
  imgErrorMsg: any;
  // showImageFromServer = false;
  oldUrl: any;
  isMobile = false;
  user: any;
  applicantID: any;
  selectedChief: any;
  memberID: any;
  isChief = false;
  myDpOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'dd/mmm/yyyy',
    markCurrentDay: true,
  };

  constructor(
    private formBuilder: UntypedFormBuilder,
    private governanceService: GovernanceService,
    private departmentService: DepartmentService,
    private authService: AuthService,
    private location: Location
  ) {

    if (typeof window["cordova"] !== 'undefined') {
      this.isMobile = true;
    }
    this.addChiefForm = this.formBuilder.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      displayName: [''],
      chiefBio: [''],
      appointmentDate: [''],
      expiryDate: [''],
      email: ['', [Validators.email, Validators.required]],
      cell_phone: [''],
      office_phone: [''],
      chiefFacebookAcc: ['', [Validators.pattern(this.urlPattern)]],
      chiefInstagramAcc: ['', [Validators.pattern(this.urlPattern)]],
      chiefTwitterAcc: ['', [Validators.pattern(this.urlPattern)]],
      chiefLinkedinAcc: ['', [Validators.pattern(this.urlPattern)]],
      chiefYoutubeAcc: ['', [Validators.pattern(this.urlPattern)]],
      showEmail: [''],
      showMobile: [''],
      showFacebookAcc: [''],
      showTwitterAcc: [''],
      showInstagramAcc: [''],
      showLinkedinAcc: [''],
      showYoutubeAcc: [''],
      showOfficePhone: [''],
      allowContact: ['']
    });
    this.user = this.authService.getGlobalUser();
    if (this.user.isChief) {
      this.isChief = true;
    }
  }
  ngOnInit() {

    this.governanceService.getGovernanceList().valueChanges().subscribe(governance => {
      this.applicantID = this.user.applicantID;
      if (governance.length > 0) {
        this.edit_governance = governance[0];
        const found = this.edit_governance.chiefs[0];
        this.editing_chief = found;
        if (found) {
          this.memberID = this.edit_governance.chiefs[0].id;

          this.departmentService.getDepartmentList().valueChanges().subscribe(x => {
            if (x) {
              this.departmentsList = x;
              this.departmentsOptions = [];
              this.departmentsList.forEach(dep => {
                dep.isSelected = false;
                if (found.portfolio) {
                  found.portfolio.forEach(element => {
                    if (element.id === dep.id) {
                      dep.isSelected = true;
                    }
                  });
                }
                const option = { id: dep.id, name: dep.name, isSelected: dep.isSelected };
                this.departmentsOptions.push(option);
              });
            }
          });

          this.selectedMemberFirstName = found.firstName;
          this.selectedMemberLastName = found.lastName;
          this.addChiefForm = this.formBuilder.group({
            firstName: [{ value: found.firstName, disabled: true }, [Validators.required]],
            lastName: [{ value: found.lastName, disabled: true }, [Validators.required]],
            displayName: [found.displayName],
            chiefBio: [found.chiefBio],
            appointmentDate: [found.appointmentDate],
            expiryDate: [found.expiryDate],
            email: [found.email, [Validators.email, Validators.required]],
            cell_phone: [found.cell_phone],
            office_phone: [found.office_phone],
            chiefFacebookAcc: [found.chiefFacebookAcc, [Validators.pattern(this.urlPattern)]],
            chiefInstagramAcc: [found.chiefInstagramAcc, [Validators.pattern(this.urlPattern)]],
            chiefTwitterAcc: [found.chiefTwitterAcc, [Validators.pattern(this.urlPattern)]],
            chiefLinkedinAcc: [found.chiefLinkedinAcc, [Validators.pattern(this.urlPattern)]],
            chiefYoutubeAcc: [found.chiefYoutubeAcc, [Validators.pattern(this.urlPattern)]],
            showEmail: [found.showEmail],
            showMobile: [found.showMobile],
            showFacebookAcc: [found.showFacebookAcc],
            showTwitterAcc: [found.showTwitterAcc],
            showInstagramAcc: [found.showInstagramAcc],
            showLinkedinAcc: [found.showLinkedinAcc],
            showYoutubeAcc: [found.showYoutubeAcc],
            showOfficePhone: [found.showOfficePhone],
            allowContact: [found.allowContact]
          });

          if (found.profilePic) {
            this.myimgLink = found.profilePic;
            this.profilePic = found.profilePic;
          }
          this.appointmentDate = found.appointmentDate;
          this.expiryDate = found.expiryDate;
          const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

          setTimeout(() => {
            if (found.expiryDate) {
              const dateArray1 = found.expiryDate.split('/');
              const year1 = +dateArray1[2];
              const day1 = +dateArray1[0];

              const date1 = new Date(year1, months.indexOf(dateArray1[1]), day1);
              const model1: IMyDateModel = { isRange: false, singleDate: { jsDate: date1 }, dateRange: null };
              this.addChiefForm.patchValue({ expiryDate: model1 });
            }

            if (found.appointmentDate) {
              const dateArray = found.appointmentDate.split('/');
              const year = +dateArray[2];
              const day = +dateArray[0];
              const date2 = new Date(year, months.indexOf(dateArray[1]), day);
              const model2: IMyDateModel = { isRange: false, singleDate: { jsDate: date2 }, dateRange: null };
              this.addChiefForm.patchValue({ appointmentDate: model2 });
            }

            M.updateTextFields();
            M.textareaAutoResize($('#chiefBio'));
            $('.tooltipped').tooltip();
          }, 25);
        }
      }
    });
  }

  onAppointmentDateChange(event: IMyDateModel): void {
    if (this.addChiefForm) {
      this.addChiefForm.patchValue({ appointmentDate: event.singleDate.formatted });
      this.appointmentDate = event.singleDate.formatted;
    }
  }
  onExpiryDateChange(event: IMyDateModel): void {
    if (this.addChiefForm) {
      this.addChiefForm.patchValue({ expiryDate: event.singleDate.formatted });
      this.expiryDate = event.singleDate.formatted;
    }
  }

  get getFirstName() { return this.addChiefForm.get('firstName'); }
  get getLastName() { return this.addChiefForm.get('lastName'); }
  get getAppointmentDate() { return this.addChiefForm.get('appointmentDate'); }
  get getExpiryDate() { return this.addChiefForm.get('expiryDate'); }
  get getChiefBio() { return this.addChiefForm.get('chiefBio'); }
  get getEmail() { return this.addChiefForm.get('email'); }
  get getCell_phone() { return this.addChiefForm.get('cell_phone'); }
  get getOffice_phone() { return this.addChiefForm.get('office_phone'); }
  get getChiefFacebookAcc() { return this.addChiefForm.get('chiefFacebookAcc'); }
  get getChiefInstagramAcc() { return this.addChiefForm.get('chiefInstagramAcc'); }
  get getChiefTwitterAcc() { return this.addChiefForm.get('chiefTwitterAcc'); }
  get getChiefLinkedinAcc() { return this.addChiefForm.get('chiefLinkedinAcc'); }
  get getChiefYoutubeAcc() { return this.addChiefForm.get('chiefYoutubeAcc'); }

  addChiefData() {
    if (this.addChiefForm.valid) {
      const formData = this.addChiefForm.value;
      const portfolioItems = [];
      this.departmentsOptions.forEach(element => {
        if (element.isSelected) {
          portfolioItems.push({ id: element.id, name: element.name });
        }
      });
      const postObj: any = {
        id: this.memberID,
        firstName: this.selectedMemberFirstName,
        lastName: this.selectedMemberLastName,
        displayName: formData.displayName,
        appointmentDate: (this.appointmentDate) ? this.appointmentDate : '',
        expiryDate: (this.expiryDate) ? this.expiryDate : '',
        chiefBio: formData.chiefBio,
        chiefFacebookAcc: formData.chiefFacebookAcc,
        chiefInstagramAcc: formData.chiefInstagramAcc,
        chiefTwitterAcc: formData.chiefTwitterAcc,
        chiefLinkedinAcc: formData.chiefLinkedinAcc,
        chiefYoutubeAcc: formData.chiefYoutubeAcc,
        email: formData.email,
        cell_phone: formData.cell_phone,
        office_phone: formData.office_phone,
        archive: 0,
        portfolio: portfolioItems,
        showEmail: formData.showEmail,
        showMobile: formData.showMobile,
        showFacebookAcc: formData.showFacebookAcc,
        showTwitterAcc: formData.showTwitterAcc,
        showInstagramAcc: formData.showInstagramAcc,
        showLinkedinAcc: formData.showLinkedinAcc,
        showYoutubeAcc: formData.showYoutubeAcc,
        showOfficePhone: formData.showOfficePhone,
        allowContact: formData.allowContact
      };
      this.processForm(postObj);
    } else {
      (Object as any).values(this.addChiefForm.controls).forEach((control: any) => {
        control.markAsTouched();
      });
      return;
    }
  }

  processForm(postObj) {
    this.edit_governance.chiefs = [postObj];
    this.governanceService.UpdateGovernanceChief(this.edit_governance).then(x => {
      toast({ html: 'Chief Profile Successfully Updated!', classes: 'green' });
      return;
    });
  }

  backClicked() {
    this.location.back();
  }

}
