<div class="row">
  <div class="col s12 m12">
    <div class="card">
      <div class="card-content" *ngIf="electionDetail">
        <h5 *ngIf="electionDetail.name" class="center">  {{electionDetail.name}}</h5>
        <table class="striped">
          <thead>
            <th>
              <p *ngIf="voteCount > 1">You have <strong>{{voteCount}}</strong> votes</p>
              <p *ngIf="voteCount == 1">You have <strong>{{voteCount}}</strong> vote</p>
              <p  *ngIf="voteCount == 0">No one voted for you.</p>
            </th>
          </thead>
          <tbody *ngIf="memberWhoVoteForMe.length > 0">
            <tr  *ngFor="let member of memberWhoVoteForMe let i = index">
              <td>
                <strong>{{i+1}}. </strong> {{member.GivenName}} {{member.LastName}}
              </td>
            </tr>
          </tbody>
        </table>
        <div *ngIf="memberWhoVoteForMe.length < voteCount"> and other <strong>{{voteCount - memberWhoVoteForMe.length}}</strong>
          <span *ngIf="voteCount - memberWhoVoteForMe.length > 1"> members</span><span *ngIf="voteCount - memberWhoVoteForMe.length == 1"> member</span> voted for you.
        </div>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <button type="button" class="btn col s12 blue" (click)="backClicked()">
    <i class="material-icons left">arrow_back_ios</i> Back To Previous Page
  </button>
</div>
