import { Component, OnInit } from '@angular/core';
import { StaticContentService } from 'src/app/services/firebase/static-content.service';
import { ActivatedRoute} from '@angular/router';
import { Location} from '@angular/common';

@Component({
  selector: 'app-read-more',
  templateUrl: './read-more.component.html',
  styleUrls: ['./read-more.component.css']
})
export class ReadMoreComponent implements OnInit {

  id = '';
  myModel: any;
  newsList: any[];

  constructor(private route: ActivatedRoute, private staticContenet: StaticContentService,
              private location: Location) {}

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });

    this.staticContenet.getContentList().valueChanges().subscribe(datas => {
      if(datas) {
        this.newsList = datas;
        if (this.id) {
          const current = this.newsList.filter(data => data.id == this.id);
          if(current && current.length > 0) this.myModel = current[0];
           // this.staticContenet.getContentByID(this.id).valueChanges().subscribe(data => {
           //   if (data) {
           //     this.myModel = data;
           //   }
           // });
         }
      }
    });
  }

  backClicked() {
    this.location.back();
  }
}
