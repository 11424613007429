import { Component, Input, OnInit } from '@angular/core';
//import { EmbedVideoService } from 'ngx-embed-video';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';

declare var $: any;

@Component({
  selector: 'app-event-detail-view',
  templateUrl: './event-detail-view.component.html',
  styleUrls: ['./event-detail-view.component.css']
})

export class EventDetailViewComponent implements OnInit {

  @Input() model: any;
  defaultThemeColor = environment.appTheme.themeColor;
  themeColor: any;
  iframehtml: any
  buttonType: any;
  videoId: any;

  constructor(
    // private embedService: EmbedVideoService,
    public appSettingsService: AppSettingsService,
    private location: Location) {
    this.themeColor = this.appSettingsService.getAppThemeColor() ? this.appSettingsService.getAppThemeColor() : this.defaultThemeColor;
    this.buttonType = this.appSettingsService.getButtonType() ? this.appSettingsService.getButtonType() : 'rectangularButton';
  }

  ngOnInit() {
    if (this.model) {
      if (this.model.type === undefined) {
        this.model.type = 'Post';
      } else if (this.model.type === 'Video') {
        this.videoId = this.embedVideo(this.model.videoLink);
      }
      setTimeout(() => {
        $('.materialboxed').materialbox();
      }, 100);
    }
    this.model.body = this.model.body.replace(/<[^>]*>/g, '');
  }

  embedVideo(videoLink) {
    const videoIdMatch = videoLink.match(/(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([^&]+)|(?:https?:\/\/)?(?:www\.)?youtu\.be\/([^?]+)/);
    this.videoId = videoIdMatch ? (videoIdMatch[1] || videoIdMatch[2]) : '';
    return this.videoId;
  }

  backClicked() {
    this.location.back();
  }
}
