

export class LRRCNSurveyModel {

    applicantID:any
    applicantDate:any='' 
    applicantSignature:any =''

    workerSignature:any=''
    workerDate:any=''
    filledBy:any
    verified:boolean
    workerUid=''
    workerFirstName=''
    workerLastName=''
    
    // Demographic
    aboutLrrcn:AboutLrrcn

    // Demographic
    demographic:Demographic
    // Languages

    // languages:[Languages];
    languages:[]

    // careGiving
    careGiving:CareGiving
    // Education
    education:Education
    // License
    license:License

    // Safety Training
    safetyTraining:[]
    // safetyTraining:[SafetyTraining]

    // Training and Employment
    trainingAndEmployment:TrainingAndEmployment

    // Job/Life satisfaction
    jobSatisfaction:JobSatisfaction
   
    // Job Barriers
    barriersOfJob:BarriersOfJob;
   
    // Employment History
    employmentHistory:EmploymentHistory

    // Shift Work
    shiftWork:ShiftWork;
   
    // Addictions Support
    addictionsSupport:AddictionSuuport
    
    // Traditional Employment
    traditionalEmployment:TraditionalEmployment


}


export class AboutLrrcn{
    knowAboutLrrcn: '';
    registered: '';
    knowHowToUse: '';

}

export class Demographic{
    firstName: '';
    lastName: '';
    email: '';
    phone: '';
    clientCategory: '';
    maritalStatus: '';
    gender: boolean;
    age: '';
    livingProvince: '';
    community: '';
    housing: []; //
}
export class BarriersOfJob{
    barriers:[JobBArrier] //
    secondBarriers:[JobBArrier]
    explanation:'';
    covidAffected:''
}

export class EmploymentHistory{
    catagory:[EmploymentCatagory]
    firstRecentJob:Job
    secondRecentJob:Job
    thirdRecentJob:Job
    noTrainingReason:''
    previousExpirence:[WorkExpirience]
    secondCatagory:[EmploymentCatagory]
}


export class JobSatisfaction{
    detailsForSatisfaction:'';
    factorsForSatisfaction:[]
    satisfiedReason:'';
    satisfiedWithEmployment:'';
}
export class TrainingAndEmployment{
    currentEmploymentComment:'';
    currentlyEmployed:'';
    currentlySeekingEmployment:'';
    employmentGoals:[]; //
    employmentStatus:'';
    notifcationEmail:'';
    notificationPreference:[]; //
    reasonForSeekingEmployment:'';
    secondEmploymentGoals:'';
}
export class License{
    barrierForLicense:[]; //
    beNotifiedForTraining:'';
    howToBeNotified:'';
    typeOfLicense:'';
    typeOfLicenseToObtain:[] //
}

export class Education{
    areaOfStudyToAchieve:'';
    currentlyEnrolled:'';
    highestLevelOfEdu:'';
    knowWhoToContactForUpgrade:'';
    levelOfEduToAchieve:[]; //
    postSecondaryEdu:'';
    typeOfProgram:'';
    typeOfProgramToUpgrade:'';
    upgradeEdu:'';
    // whenToUpgradeEducation:'';
    whyNoUpgrade:''
    collegeApply:''
    applyForFunding:''
}

export class CareGiving{
    numberOfDependants:'';
    careProvider:[]; //
    dayeCareAvailable:''
    neededCare:'';
    needsCare:'';
    typeOfCareAvailable:'';
    availableCare:'';
}

export class ShiftWork{
    workAlone:'';
    workAloneReason:''
    careerAssessment:'';
    commuteForWork:'';
    commuteLength:{
        comments:''
        length:''
    }
    localOrInCommunityWorkshop:''
    noReason:''
    shiftWorkOutsideCommunity:''
    supportForRelocate:[RelocateSupport]
    supportGroup:''
    willingToRelocate:''
    workshopsInCommunity:[Workshop]
    typesOfSupportGroup:[SupportGroupType]
}

export class AddictionSuuport{
    communicateOnBehalf:{
        comments:'',
        value:''
    }
    infoNNADAP:[NNADAPInfo];
    infoOnProgram:''
}
export class TraditionalEmployment{
    additionalComments:''
    skills:[Skills]
}

export class Skills{
    skill:'';
    value:''
}
export class Languages{
    language:'';
    learnToSpeak:'';
    literacyLevel:'';
    speak:'';
    understand:'';
    write:'';
}

// 
export class SafetyTraining{
    training:'';
    value:'';
    // expiryDate:'';
    // wouldLikeTraining:'';
    // currentlyValid:'';
}

export class JobBArrier{
    barrier:'';
    value:''
}

export class EmploymentCatagory{
    history:'';
    value:''
}
export class RelocateSupport{
    support:'';
    value:''
}
export class WorkExpirience{
    expirience:'';
    value:''
}
export class Workshop{
    workshop:'';
    value:''
}
export class NNADAPInfo{
    info:'';
    value:''
}

export class SupportGroupType{
    group:'';
    value:''
}

export class Job{
    dateOfEmployment:''
    employer:''
    jobTitle:''
    mainDuties:''
    stillWOrkingThere:''
}



