<div class="container-pi  ">
  <!-- <div class="row center-align p-m-2">
    <h6> <strong> Scan/Upload Document </strong></h6>
  </div> -->
  <h4 class="title-section1 title-member icon-texts">
    <span class="material-symbols-outlined back-icon p-r-1" (click)=" backClicked()">
        arrow_circle_left
    </span>Scan/Upload Document</h4>

  <div class="row card-panel b-n-1">
    <!-- <div class="col l1"></div> -->
    <div class="col s12 l12">
      <app-upload-document [applicantID]="applicantID" [callerForm]="callerName" [isCordova]="isCordova"
        (documentSaved)="parentEventHandlerFunction($event)">
      </app-upload-document>
    </div>
    <!-- <div class="col l1"></div> -->
  </div>
</div>