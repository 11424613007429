import { Component, HostListener, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { RoutMetaService } from 'src/app/services/firebase/rout-meta.service';
import { SessionsMonitorService } from 'src/app/services/firebase/sessions-monitor.service';

@Component({
  selector: 'app-admin-main',
  templateUrl: './admin-main.component.html',
  styleUrls: ['./admin-main.component.css']
})

export class AdminMainComponent implements OnInit {
  scrWidth: number; 
  route: any;
  metaTagSettings: any;  

  constructor( private router: Router, private session : SessionsMonitorService ,  private settingService: AppSettingsService, private routMeta: RoutMetaService) { 
    router.events.subscribe((_: NavigationEnd) => this.route = _.url);
    this.scrWidth = window.innerWidth;
  }

  ngOnInit() {
    // this.session.startSession();
    this.getMetaTagSettings();

  }

  // @HostListener('window:beforeunload', [ '$event' ])
  // unloadHandler($event) {
    
  //   $event.preventDefault();
    
  //   // return this.session.endSession();
  //   return false;
  // }


  @HostListener('window:beforeunload', ['$event'])
  ngOnDestroy() {
    // this.session.endSession();
    return true;
  }

  // getMetaTagSettings() {
  //   this.settingService.getMetadata().valueChanges().subscribe((metaTags: any) => {
  //     let lastIndex = metaTags?.length - 1;
  //     console.log("Meta Tags", metaTags)
  //     if (lastIndex || lastIndex === 0) {
  //       this.metaTagSettings = metaTags?.[lastIndex];
  //       // this.appSettingsForm.get('appTitle').setValue(this.metaTagSettings?.title)
  //       this.routMeta.updateTitle(metaTags[0].appTitle);
  //       // this.appSettingsForm.get('keywordSEO').setValue(this.metaTagSettings?.description);
  //       this.routMeta.updateDescription(metaTags[0].description);
  //       // this.appSettingsForm.get('Keywords').setValue(this.metaTagSettings?.KeyWords);
  //       this.routMeta.updateKeywords(metaTags[0].keyWords ? metaTags[0].keyWords : "");
  
  //     }
  //   });
  // }

  getMetaTagSettings() {
    this.settingService.getMetadata().valueChanges().subscribe((metaTags: any) => {
      if (metaTags) {
        console.log("Meta Tags", metaTags);
        this.metaTagSettings = metaTags;
        this.routMeta.updateTitle(metaTags.appTitle);
        this.routMeta.updateDescription(metaTags.description);
        this.routMeta.updateKeywords(metaTags.keyWords ? metaTags.keyWords : "");
      }
    });
  }
  

}
