<div class="col s12">
  <div class="body-h">

    <div class="header-setting b-m-2">
      <div class=" col l12 m12 s12 ">
        <h4 *ngIf="!editName">
          <span *ngIf="sectionName">{{sectionName}}</span>
          <span *ngIf="!sectionName">App Statistic</span>
          <i class="material-icons edit-name" [ngStyle]="{'color': themeColor}" (click)="editNameClicked()">edit</i>
        </h4>
        <div class="input-field" *ngIf="editName">
          <input type="text" [(ngModel)]="sectionName" class="left col s6 validate" autocomplete="off" />
          <button type="button" class="btn ll-m-5" [ngStyle]="{'background-color': themeColor}"
            (click)="saveName()">Save</button>
        </div>
        <p class="col s12 bold-300 sub-title-section3">
          A collection and analysis of various metrics and data related to the usage, performance, and user interactions within an application. 
        </p>
      </div>
    </div>

    <main class="StickyContent">
      <div class="row card-panel  white col s12 m12 l5" *ngIf="registredMembers">
        <ul class="row collection">
          <li>
            <h6 class="center"> Registered Users </h6>
          </li>
          <li class="collection-item">
            <div>Members<a class="secondary-content">{{getNumberOfRegistredMembers()}}</a></div>
          </li>
          <li class="collection-item">
            <div>Administrators<a class="secondary-content">{{getNumberOfRegistredAdmins()}}</a></div>
          </li>
          <li class="collection-item">
            <div>Department Employees<a class="secondary-content">{{getNumberOfRegistredEmployees()}}</a></div>
          </li>
        </ul>
      </div>

      <div class="row card-panel white col s12 m12 l5" *ngIf="staticContentList">
        <ul class="row collection">
          <li>
            <h6 class="center"> Dynamic Content </h6>
          </li>
          <li class="collection-item">
            <div>News / Events <a class="secondary-content">{{getNumberOfNewsEvents()}}</a></div>
          </li>
          <li class="collection-item">
            <div><i class="material-icons green-text">public</i> Public <a
                class="secondary-content">{{getNumberOfPublicNewsEvents()}}</a></div>
          </li>
          <li class="collection-item">
            <div><i class="material-icons red-text">vpn_lock</i> Members Only<a
                class="secondary-content">{{getNumberOfPrivateNewsEvents()}}</a></div>
          </li>
          <li *ngIf="aboutUsList" class="collection-item">
            <div>About Us Sections<a class="secondary-content">{{getNumberOfAboutUSSections()}}</a></div>
          </li>
        </ul>
      </div>

      <div class="row card-panel white col s12 m12 l5" *ngIf="groupedSessionsArray">
        <ul class="row collection">
          <li>
            <h6 class="center"> Members Connected In The Last 7 Days </h6>
          </li>
          <li class="collection-item" *ngFor="let s of groupedSessionsArray">
            <div> {{s.date}} <a class="secondary-content">{{getNumberOfSessionsByDay(s.sessions)}}</a></div>
          </li>
        </ul>
      </div>

      <div class="row card-panel white col s12 m12 l5" *ngIf="sessionsList">
        <ul class="row collection">
          <li>
            <h6 class="center"> Members Devices </h6>
          </li>
          <li class="collection-item">
            <div>Desktop : <a class="secondary-content">{{getNumberOfComputerDevice()}}</a></div>
          </li>
          <li class="collection-item">
            <div>Mobile :<a class="secondary-content">{{getNumberOfMobileDevice()}}</a></div>
          </li>
          <li class="collection-item">
            <div><i class="material-icons grey-text">phone_iphone</i> IPhone <a
                class="secondary-content">{{getNumberOfIphone()}}</a></div>
          </li>
          <li class="collection-item">
            <div><i class="material-icons green-text">phone_android</i> Android<a
                class="secondary-content">{{getNumberOfAndroid()}}</a></div>
          </li>
        </ul>
      </div>
    </main>

    <div id="underConstructionModal" class="modal center b-n-1">
      <div class="modal-content">
        <i class="material-icons right modal-close">close</i><br />
        <h6 class="title-section2 bold-600 black-text">Under Construction</h6>
        <p class="sub-title-section bold-100">We’re working hard to make this feature available soon. </p>
        <img src="https://img.icons8.com/wired/344/tow-truck.png" height="150px" />
        <p class="sub-title-section bold-100">In the meantimee if you need some support? Contact us on info&#64;mynation.app
        </p>
        <p class="sub-title-section bold-100">Please Contanct your Administrator to gain access.</p>
      </div>
    </div>

  </div>
</div>