import { Injectable } from '@angular/core';
import { EmailModel } from 'src/app/models/emailModel';
import { environment } from 'src/environments/environment';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class FbadminService {

  constructor(private httpClient: HttpClient) { }



}
