import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "src/environments/environment";
import { ApiKeyManagerService } from "./apiKeyManager.Service";

@Injectable({
  providedIn: "root",
})
export class ChatGptService {
  constructor(
    private httpClient: HttpClient,
    private keyManager: ApiKeyManagerService
  ) {}

  url = environment.firstNation.goingMobileApi + "api/OpenAI/";

  // the response from api is 'an over-stringified string' so need to 'JSON.parse' response twice
  startChat(content: string): Observable<any> {
    let model = {
      content,
      model: "gpt-3.5-turbo",
    };
    const headers = new HttpHeaders({
      SOCIALAPIKEY: this.keyManager.getGoingMobileKey(),
      "Content-Type": "application/json; charset=utf-8",
    });
    return this.httpClient.post(
      this.url + "StartChat/",
      JSON.stringify(model),
      { headers }
    );
  }
}