<div class="row" *ngIf="eventSection">
    <div class="row p-t-3 p-b-2">
        <div class="col s12 m9 l5 push-l1 push-m1">
            <div class="header-title ">
                <h6 [ngStyle]="{'color': eventSection.displayNameColor}">{{eventSection.displayName}}</h6>
            </div>
        </div>
        <div class="col l6  hide-on-med-and-down" style="padding: 30px 0px;">
            <p class="seeAll-displayName teal-text right"><a [routerLink]="['/events-calendar']" (click)="navigate()"
                    [ngStyle]="{'color': eventSection.displayNameColor}">
                    <i>See All {{eventSection.displayName}} >>
                    </i></a>
            </p>
        </div>
        <div class="divider col l10 m10 s8 push-l1 push-m1 push-s2 grey lighten-3"></div>
    </div>

    <div class="row col l10 push-l1 m10 push-m1 s12 cont-ainer">
        <div class="col s12 m6 l4 xl4" *ngFor="let event of events">
            <a [routerLink]="['/events-detail', event.id]">
                <div class="example-2 card">

                    <!-- =============================== image card -->
                    <div class="wrapper" [ngStyle]="{'background-color': themeColor} " *ngIf="event.imgLink">
                        <img class="wrapper responsive-img " [src]="event.imgLink">

                        <div class="header">
                            <div class="date" [ngStyle]="{'background-color': themeColor} ">
                                <span class="day white-text">{{event.eventDate.split('/')[0]}}</span>
                                <span class="month white-text">{{event.eventDate.split('/')[1]}}</span>
                            </div>
                        </div>

                        <div class="data">
                            <div class="content">
                                <span class="author " *ngIf="event.startsAt"><i
                                        class="tiny material-symbols-outlined p-r-1">schedule</i>
                                    {{event.startsAt}} - {{event.endsAt}} </span>
                                <h1 class="title  short-body-snippet"><a href="#"><span
                                            [innerHTML]="event.title"></span></a></h1>
                                <p class="text"><span class="description-section bold-100 middle-body-snippet"
                                        [innerHTML]="event.body"></span></p>
                                <a [routerLink]="['/events-detail', event.id]" class="button left"
                                    [ngStyle]="{'color': themeColor} ">Read more</a>
                            </div>
                        </div>
                    </div>

                    <!-- =============================== no image card -->
                    <div class="wrapper" [ngStyle]="{'background-color': themeColor} " *ngIf="!event.imgLink">

                        <div class="header">
                            <div class="date" [ngStyle]="{'color': themeColor} ">
                                <span class="day">{{event.eventDate.split('/')[0]}}</span>
                                <span class="month">{{event.eventDate.split('/')[1]}}</span>
                            </div>
                        </div>

                        <div class="data1">
                            <div class="content">
                                <span class="author " *ngIf="event.startsAt"><i
                                        class="tiny material-symbols-outlined p-r-1">schedule</i>
                                    {{event.startsAt}} - {{event.endsAt}} </span>

                                <h1 class="title short-body-snippet"><a href="#"><span
                                            [innerHTML]="event.title"></span></a></h1>
                                <p class="text"><span class="description-section bold-100 middle-body-snippet"
                                        [innerHTML]="event.body"></span></p>
                                <a [routerLink]="['/events-detail', event.id]" class="button left"
                                    [ngStyle]="{'color': themeColor} ">Read
                                    more</a>
                            </div>
                        </div>
                    </div>

                </div>
            </a>
        </div>
    </div>
</div>