<div class="section"></div>

<div class="row col s12">
  <div class="col l1"></div>

  <div class="col s12 l10">
    <app-firebase-loading *ngIf="showLoading"></app-firebase-loading>

    <div class="row">
      <div class="col s6 ">
        <a (click)="backClicked()">
          <div class="card orange darken-4 hoverable " style="height: 95px;">
            <div class="card-content">
              <div class="row center">
                <i class="material-icons white-text">house</i><br />
                <strong class="white-text">Back</strong>
              </div>
            </div>
          </div>
        </a>
      </div>

      <div class="col s6  ">
        <a [routerLink]="['/member/add-department-news', departmentID]">
          <div class="card green darken-2 hoverable " style="height: 95px;">
            <div class="card-content">
              <div class="row center">
                <i class="material-icons white-text">add_circle</i><br />
                <strong class="white-text">Add News/Event</strong>
              </div>
            </div>
          </div>
        </a>
      </div>
    </div>

    <div class="row center-align" *ngIf="model">
      <h6 class="center"><strong>Drag The content to the position you want</strong></h6>
    </div>

    <div class="row card-panel white col s12 valign-wrapper" *ngIf="!model">
      <div class="section"></div>
      <h6><strong><i class="material-icons red-text" style="display: inline-flex;
          vertical-align: top;">info</i> You did not publish any department news.</strong></h6>
      <div class="section"></div>
    </div>

    <div class="row " *ngIf="model">
      <div [sortablejs]="model" [sortablejsOptions]="options">
        <div *ngFor="let content of model">
          <div class="row card-panel white">
            <div class="col s12">
              <div class="col s11">
                <a [routerLink]="['/member/edit-department-news', content.id]" class="truncate"
                  [innerHTML]="content.title"></a>
              </div>
              <div class="col s1">
                <i class="material-icons left-align blue-text">drag_indicator</i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col l1"></div>
</div>