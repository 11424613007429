import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { environment } from 'src/environments/environment';
import { toast } from 'materialize-css';
import { FireMembersService } from 'src/app/services/firebase/fire-members.service';
import { DepartmentService } from 'src/app/services/firebase/department.service';
import { Router } from '@angular/router';


declare var $: any;
declare var M: any;

@Component({
  selector: 'app-employee-menu',
  templateUrl: './employee-menu.component.html',
  styleUrls: ['./employee-menu.component.css']
})

export class EmployeeMenuComponent implements OnInit {

  loading = false;
  user: any;
  userId: any;
  obj: any;
  themeColor = environment.appTheme.themeColor;
  accountVerified: any = true;
  verificationForm: UntypedFormGroup;
  depName: any;

  constructor( private authService: AuthService,
    private depService: DepartmentService,
    private router: Router,
    private fb: UntypedFormBuilder, private memberService: FireMembersService) {
  }

  ngOnInit() {
    if (this.authService.getGlobalUser()) {
      this.user = this.authService.getGlobalUser();
      this.depService.getDepartmentByID(this.user.departmentId).valueChanges()
        .subscribe(d => {
          if (d) this.depName = d.name.replace(/<[^>]*>/g, '');
        })
    } else {
      const currentUser = this.authService.getCurrentUser();
      this.memberService.getMemberByID(currentUser.uid).valueChanges().subscribe(user => {
        if (user) {
          this.user = user;
          this.depService.getDepartmentByID(this.user.departmentId).valueChanges()
            .subscribe(d => {
              if (d) this.depName = d.name.replace(/<[^>]*>/g, '');
            })
        }
      })
    }

    this.verificationForm = this.fb.group({
      verificationCode: ['', [Validators.required]],
    });

    setTimeout(() => {
      $('.modal').modal();
    }, 25);
  }

  get verificationCode() { return this.verificationForm.get('verificationCode'); }

  verify(model: any) {
    if (model.verificationCode.replace(/\s/g, '') === this.authService.getGlobalUser().verificationPassword.replace(/\s/g, '')) {
      if (this.obj) {
        this.obj.accountVerified = true;
        this.memberService.updateAccount(this.obj);
        setTimeout(() => {
          toast({ html: 'Verification Success', classes: 'green' });
          this.accountVerified = true;
        }, 50);
      }
    } else {
      toast({ html: 'Invalid Verification Code', classes: 'red' });
    }
  }

  switchAccount() {
    this.router.navigate(['/member/home']);
  }

  signOut() {
    this.authService.signOut();
  }
}