import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { NominationDataModel } from 'src/app/models/NominationModel';

@Injectable({
  providedIn: 'root'
})

export class NominationDataService {

  constructor(private db: AngularFirestore) { }

  createNominationData(model: NominationDataModel){
      const newContent = {
        id: this.db.createId(),
        userId: model.userId,
        firstName: model.firstName,
        lastName: model.lastName,
        nominationId: model.nominationId,
        nominatedMemberId: model.nominatedMemberId,
        nominationStatus: model.nominationStatus
      }
      return this.db.collection('Nomination-Data').doc(newContent.id).set(newContent);
  }

  updateNominationStatus(nominationModelId: string, nominationStatus: string) {
    return this.db.collection('Nomination-Data').doc(nominationModelId).update({nominationStatus});
  }

  getNominationList() {
    return this.db.collection('Nomination-Data');
  }

  getNominationDataByNominationId(id: string) {
    return this.db.collection('Nomination-Data', ref => ref.where('nominationId', '==', id));
  }

  getMyNominationsById(id: string, userId: string) {
    return this.db.collection('Nomination-Data', ref => ref.where('nominationId', '==', id).where('userId', '==', userId));
  }

  getIfMemberNominated(nominationId: string, nominatedMemberId: string) {
    return this.db.collection('Nomination-Data', ref => ref.where('nominationId', '==', nominationId)
    .where('nominatedMemberId', '==', nominatedMemberId));
  }

  getNominationbyId(id: string) {
    return this.db.doc('Nomination/' + id);
  }

  getNominationsByNomineeId(id: string){
    return this.db.collection('Nomination-Data', ref => ref.where('nominatedMemberId', '==', id));
  }

  deleteNominationsById(id: string, userId: string) {
    return this.db.collection('Nomination-Data', ref => ref.where('nominationId', '==', id).where('userId', '==', userId)).doc().delete();
  }

  deleteNominationDataById(id: string) {
    return this.db.collection('Nomination-Data').doc(id).delete();
  }
  
}
