<div class="row back-col">
  <div class="p-t-5 p-b-5">
    <h6 class="header-bb bold-100 underlined" [ngStyle]="{ color: themeColor }">
      {{ nationName }}
    </h6>
  </div>
  <div class="row p-t-2 p-b-8">
    <div class="col s12 m10 l10 push-l1 push-m1" *ngIf="model">
      <a class="col s12 m12 l6 black-text" *ngFor="let data of model"
        [routerLink]="['/view-about-us-section', data.id]">
        <div class="">
          <app-firebase-loading *ngIf="!model"></app-firebase-loading>
          <div class="card b-n-2" [ngStyle]="{
              'background-color': data.backgroundColor,
              'border-color': themeColor
            }">
            <div *ngIf="data.imgLink">
              <img class="responsive-img hide-on-med-and-up" [src]="data.imgLink" alt="Aboutus image"/>
              <div class="col s12 l8 ">
                <!-- <h1 class="title-section3 bolder p-m-1" [innerHTML]="data.title"></h1> -->
                <span class="p-t-1" [innerHTML]="data.title"></span>
                <span class="middle-body hide-on-small-only" [innerHTML]="data.body" id="longBodyContent"></span>
              </div>

              <img class="materialboxed responsive-img col l4 hide-on-small-only" [src]="data.imgLink" />
            </div>

            <div *ngIf="!data.imgLink">
              <div class="col s12">
                <span class="p-t-1" [innerHTML]="data.title"></span>
                <span class="long-body" [innerHTML]="data.body"></span>
              </div>
            </div>
          </div>
        </div>
      </a>
    </div>
  </div>
</div>