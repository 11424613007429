
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TempHousingService } from 'src/app/services/firebase/temp-housing.service';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { HousingService } from 'src/app/services/api/housing.service';
const pdfMake = require('pdfmake/build/pdfmake.js');
const pdfFonts = require('pdfmake-unicode/dist/pdfmake-unicode.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;

declare var $: any;

@Component({
  selector: 'app-my-house',
  templateUrl: './my-house.component.html',
  styleUrls: ['./my-house.component.css']
})

export class MyHouseComponent implements OnInit {

  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  houseTypes = ['Bungalow', 'Townhouse', 'Condominium', 'Detached House', 'Semi-detached House'];
  // houseStyles = ['French-Style', 'Colonial-Style', 'Victorian-Style', 'Tudor-Style',
  // 'Craftsman House', 'Cottage-Style', 'Mediterranean-Style', 'Traditional Ranch', 'Other'];
  unitTypes = ['ft²', 'm²'];
  housingForm: UntypedFormGroup;
  houseInfoModel: any;
  isCMHC = false;
  isFinanced = false;

  showMap = false;
  zoom = 15;
  lat: any;
  lng: any;
  memberId: any;
  loading = false;
  ownerID = 9;
  houseId = '';
  house: any;
  occupants: any[];

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private authService: AuthService,
    private housingService: HousingService,
    private tempHousingService: TempHousingService, 
    private location: Location
  ) { }

  //private location: Location

  get nameOfTheOwner() { return this.housingForm.get('nameOfTheOwner'); }
  get houseNumber() { return this.housingForm.get('houseNumber'); }
  get houseType() { return this.housingForm.get('houseType'); }
  // get houseStyle() { return this.housingForm.get('houseStyle'); }
  get yearBuiltOn() { return this.housingForm.get('yearBuiltOn'); }
  get numberOfRooms() { return this.housingForm.get('numberOfRooms'); }
  // get size() { return this.housingForm.get('size'); }
  // get sizeUnit() { return this.housingForm.get('sizeUnit'); }
  // get value() { return this.housingForm.get('value'); }

  ngOnInit() {
    this.memberId = this.authService.getCurrentApplicantID();
    //this.memberId = 4370;
    if (this.memberId) {
      this.loading = true;
      this.housingService.getHouseByOwnerID(this.memberId).subscribe(x => {
        if (x && x.length > 0) {
          this.house = x[0];
          this.houseId = this.house.ID;
          if (this.house.ID) {
            this.housingService.getOccupants(this.house.ID).subscribe(x => {
              if (x && x.length > 0) this.occupants = x;
              else this.occupants = [];
              this.loading = false;

              setTimeout(() => {
                $('select').formSelect();
                $('.dropdown-trigger').dropdown();
                $('.modal').modal();
              }, 25);
            });
          } else {
            this.occupants = [];
          }
        }
        this.loading = false;
      });
    }

    this.housingForm = this.formBuilder.group({
      nameOfTheOwner: [''],
      houseNumber: ['', [Validators.required]],
      houseType: ['', [Validators.required]],
      // houseStyle: [''],
      yearBuiltOn: [''],
      numberOfRooms: [''],
      // size: [''],
      // sizeUnit: ['', [Validators.required]],
      // value: [''],
    });

    setTimeout(() => {
      $('select').formSelect();
    }, 25);
  }

  // geolocateMyHouse() {
  //   this.getCurrentLocation();
  //   this.showMap = true;
  // }

  //   getCurrentLocation() {
  //     if (navigator.geolocation) {
  //         navigator.geolocation.getCurrentPosition((position: Position) => {
  //             if (position) {
  //                 this.lat = position.coords.latitude;
  //                 this.lng = position.coords.longitude;
  //                // this.getAddress = (this.lat, this.lng)
  //                
  //                 // this.apiloader.load().then(() => {
  //                 //     let geocoder = new google.maps.Geocoder;
  //                 //     let latlng = {
  //                 //         lat: this.lat,
  //                 //         lng: this.lng
  //                 //     };
  //                 //     geocoder.geocode({
  //                 //         'location': latlng
  //                 //     }, function(results) {
  //                 //         if (results[0]) {
  //                 //             this.currentLocation = results[0].formatted_address;
  //                 //           
  //                 //         } else {
  //                 //             
  //                 //         }
  //                 //     });
  //                 // });
  //             }
  //         })
  //     }
  // }

  submitForm(value: any) {
  }

  myHouseClicked() {
    localStorage.setItem('From', 'MyHouse');
    this.router.navigate(['member/member-home-sub-Menu']);
  }

  getReport() {
    let report = {
      info: {
        title: 'My House REPORT',
        subject: 'My House Report',
        keywords: 'My House',
      },
      pageSize: 'LETTER',
      content: this.getContent(),
      styles: this.getStyles(),
    }

    //pdfMake.createPdf(report).download('My-House.pdf');
    pdfMake.createPdf(report).open();
  }

  getContent() {
    const property = this.house;
   
    let content = [];

    content.push(
      //header with title 
      {
        style: 'table',
        layout: 'noBorders',
        table: {
          widths: ['*', '*'],
          body: [
            [
              {
                columns: [
                  [
                    {
                      stack: [
                        {
                          text: 'MY NATION ',
                          style: 'mainTitle',
                          alignment: 'center',
                        },
                        {
                          text: 'Connecting Your Nation',
                          style: 'normalText',
                          alignment: 'center',
                        },
                        {
                          text: 'Sovereign Data For Sovereign Nations ',
                          fontSize: 8,
                          italics: true,
                          alignment: 'center',
                        },
                      ]
                    },
                  ],
                ]
              },
              {
                stack: [
                  {
                    text: "Department Of Housing",
                    style: 'title'
                  },
                  {
                    text: 'Houses',
                    style: 'title'
                  },
                  {},
                ]
              },
            ],
          ]
        }
      },

      //Proprty details
      {
        style: 'table',
        table: {
          widths: ['*', '*', '*'],
          body: [
            [
              {
                stack: [
                  {
                    text: 'Head of Household: ',
                    style: 'subTitle'
                  },
                  {
                    text: property.Name_of_Owner,
                    style: 'normalText'
                  },
                ],

              },
              {
                stack: [
                  {
                    text: 'Lived Since: ',
                    style: 'subTitle'
                  },
                  {
                    text: property.lived_since,
                    style: 'normalText'
                  },
                ],
              },
              {
                stack: [
                  {
                    text: 'Community: ',
                    style: 'subTitle'
                  },
                  {
                    text: property.community,
                    style: 'normalText'
                  },
                ],
              },
            ],
            [
              {
                stack: [
                  {
                    text: 'CMHC: ',
                    style: 'subTitle'
                  },
                  {
                    text: property.CMHC ? 'Yes' : "No",
                    style: 'normalText'
                  },
                ],
                colSpan: property.CMHC ? 1 : 2
              },
              property.CMHC ?
                {
                  stack: [
                    {
                      text: 'Rented: ',
                      style: 'subTitle'
                    },
                    {
                      text: property.rented ? 'Yes' : "No",
                      style: 'normalText'
                    },
                  ],
                  colSpan: property.rented ? 1 : 2
                } : {},
              {
                stack: [
                  {
                    text: 'Financed: ',
                    style: 'subTitle'
                  },
                  {
                    text: property.finianced ? 'Yes' : "No",
                    style: 'normalText'
                  },
                ],
              },
            ],
          ]
        }
      },

      property.desc_type == 'lotBlock' ?
        {
          style: 'table',
          table: {
            widths: ['*', '*'],
            body: [
              [
                {
                  stack: [
                    {
                      text: 'Block: ',
                      style: 'subTitle'
                    },
                    {
                      text: property.house_block,
                      style: 'normalText'
                    },
                  ]
                },
                {
                  stack: [
                    {
                      text: 'Lot: ',
                      style: 'subTitle'
                    },
                    {
                      text: property.house_lot,
                      style: 'normalText'
                    },
                  ],
                },
              ],
            ]
          }
        } : '',

      property.desc_type == 'landDesc' ?
        {
          style: 'table',
          table: {
            widths: ['*', '*', '*'],
            body: [

              [
                {
                  stack: [
                    {
                      text: 'QRT: ',
                      style: 'subTitle'
                    },
                    {
                      text: property.Quarter,
                      style: 'normalText'
                    },
                  ]
                },
                {
                  stack: [
                    {
                      text: 'Section: ',
                      style: 'subTitle'
                    },
                    {
                      text: property.section,
                      style: 'normalText'
                    },
                  ],
                  colSpan: 2
                },
                {}
              ],

              [
                {
                  stack: [
                    {
                      text: 'Township: ',
                      style: 'subTitle'
                    },
                    {
                      text: property.township,
                      style: 'normalText'
                    },
                  ]
                },
                {
                  stack: [
                    {
                      text: 'Range: ',
                      style: 'subTitle'
                    },
                    {
                      text: property.range,
                      style: 'normalText'
                    },
                  ],
                },
                {
                  stack: [
                    {
                      text: 'Meridian: ',
                      style: 'subTitle'
                    },
                    {
                      text: property.median,
                      style: 'normalText'
                    },
                  ],
                },
              ],
            ]
          }
        } : '',

      property.desc_type == 'otherLocation' ?
        {
          style: 'table',
          table: {
            widths: ['*', '*'],
            body: [

              [
                {
                  stack: [
                    {
                      text: 'Location Description: ',
                      style: 'subTitle'
                    },
                    {
                      text: property.Other_location,
                      style: 'normalText'
                    },
                  ],
                  colSpan: 3
                },
                {},
                {}
              ],
            ]
          }
        } : '',

      {
        style: 'table',
        table: {
          widths: ['*', '*', '*'],
          body: [
            [
              {
                stack: [
                  {
                    text: 'Mailing Address: ',
                    style: 'subTitle'
                  },
                  {
                    text: property.Address,
                    style: 'normalText'
                  },
                ],
              },
              {
                stack: [
                  {
                    text: 'Province: ',
                    style: 'subTitle'
                  },
                  {
                    text: property.Province,
                    style: 'normalText'
                  },
                ],
              },

              {
                stack: [
                  {
                    text: 'Postal Code: ',
                    style: 'subTitle'
                  },
                  {
                    text: property.PostalCode,
                    style: 'normalText'
                  },
                ],
              },
            ],
            [
              {
                stack: [
                  {
                    text: 'Built On: ',
                    style: 'subTitle'
                  },
                  {
                    text: property.Built_on,
                    style: 'normalText'
                  },
                ],
              },
              {
                stack: [
                  {
                    text: 'Age of House : ',
                    style: 'subTitle'
                  },
                  {
                    text: property.year,
                    style: 'normalText'
                  },
                ],
              },

              {
                stack: [
                  {
                    text: 'Style: ',
                    style: 'subTitle'
                  },
                  {
                    text: property.style,
                    style: 'normalText'
                  },
                ],
              },
            ],
            [
              {
                stack: [
                  {
                    text: 'No of Rooms: ',
                    style: 'subTitle'
                  },
                  {
                    text: property.rooms,
                    style: 'normalText'
                  },
                ],
              },
              {
                stack: [
                  {
                    text: 'Size Of House: ',
                    style: 'subTitle'
                  },
                  {
                    text: property.size ? property.size : '' + " sq ft",
                    style: 'normalText'
                  },
                ],
              },

              {
                stack: [
                  {
                    text: 'Size Of Lot: ',
                    style: 'subTitle'
                  },
                  {
                    text: property.SizeOfTheLot ? property.SizeOfTheLot : '' + " sq ft",
                    style: 'normalText'
                  },
                ],
              },
            ],
          ]
        }
      },

      //Occupants
      // property.occupants.length > 0 ?
      // {
      //   style: 'table',
      //   table: {
      //     widths: ['*', '*', '*', '*'],
      //     body: this.getOccupants(property.occupants)
      //   }
      // } : '',
    );
    return content;
  }

  // getOccupants(occupants: any[]) {
  //   let occupantsBody = [];
  //   occupantsBody.push([{text: "Occupants", colSpan: 4, style: 'title'},{},{},{}]);
  //   occupantsBody.push([
  //     {
  //       text: 'First Name',
  //       style: 'header',
  //       bold: true,
  //     },
  //     {
  //       text: 'Last Name',
  //       style: 'header',
  //       bold: true,
  //     },
  //     {
  //       text: 'Date of Birth',
  //       style: 'header',
  //       bold: true,
  //     },
  //     {
  //       text: 'Relationship',
  //       style: 'header',
  //       bold: true,
  //     },
  //   ]);
  //   occupants.forEach(o => {
  //     occupantsBody.push(
  //       [{ text: o.given_name ? o.given_name : '', style: 'normalText' },
  //         { text: o.last_name ? o.last_name : '', style: 'normalText' },
  //         { text: "Occupant DOB" },
  //         // { text: occupant.DOB ? occupant.DOB : '', style: 'normalText' }, 
  //         { text: o.Relationship ? o.Relationship : '', style: 'normalText' }]
  //     );
  //   });

  //  
  //   return occupantsBody;
  // };

  getStyles() {
    return {
      mainTitle: {
        bold: true,
        fontSize: 16,
        alignment: 'left',
        margin: [0, 0, 0, 20]
      },
      title: {
        bold: true,
        fontSize: 14,
        alignment: 'center',
        margin: [0, 10, 0, 10]
      },
      subTitle: {
        bold: true,
        fontSize: 12,
        alignment: 'left',
        margin: [0, 10, 0, 10]
      },
      normalText: {
        fontSize: 11,
      },
      header: {
        fontSize: 12,
        bold: true,
        alignment: 'center',
      },
      links: {
        color: 'blue',
        decoration: 'underline',
        //italics: true,
        margin: [0, 5, 0, 5]
      },
      table: {
        margin: [0, 5, 0, 5]
      },
    }
  }

  back() {
    this.location.back();
  }

}
