<div class="col s12 p-t-5" *ngIf="admin">
  <div class="body-h card-panel">

    <div class="header-setting">
      <div class="col l12 m12 s12">
        <h4 class="icon-text">Admin SignUp</h4>
      </div>
    </div>

    <main class="StickyContent">
      <div class="row col s12 m11 l11">

        <div class="row title">
          <h2 class="center bold-600 col s12 title-section2">
            Welcome,&nbsp;
            <span [ngClass]="fontColor">{{admin.FirstName}} </span>
          </h2>

          <h2 *ngIf="step1" class="center bold-100 col s12 m12 l12" style="font-family:'sans-serif'; font-size: 14px;"
            [ngStyle]="{'color': themeColor}">Please Enter Your Social Software Password To Verify Your Account.
          </h2>

          <h2 *ngIf="step2" class="center bold-100 col s12 m12 l12" style="font-family:'sans-serif'; font-size: 14px;"
            [ngStyle]="{'color': themeColor}">
            Create a strong password with a mix of letters, numbers and symbols</h2>
        </div>

        <!--Step 1 - Verfication Form-->
        <div *ngIf="step1">
          <form [formGroup]="verificationForm" class="row p-l-2">

            <div class="row" *ngIf="admin">
              <div class="input-field col s11">
                <p class="sub-title-section3 bold-500">Social Software Password<span class="red-text">
                    (*)</span></p>
                <input id="socialPassword" type="password" formControlName='socialPassword' required class="validate" />
                <div *ngIf="(socialPassword.socialPassword || socialPassword.touched) && socialPassword.invalid">
                  <small *ngIf="socialPassword.errors.required" class="red-text"> The Password Is Required. </small>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="input-field col s11 center">
                <button type="submit" [disabled]="!verificationForm.valid" class="col s12 btn"
                  [ngClass]="'btn ' + buttonType" [ngStyle]="{'background-color': themeColor}"
                  (click)="verification(verificationForm)">
                  <i class="material-icons left">lock</i> Verify
                </button>
              </div>
            </div>
          </form>
        </div>

        <!-- Step 2 - Registration -->
        <div *ngIf="step2" class="row p-l-2">

          <div class="row">
            <div class="input-field col s11 m10">
              <p class="label-input">Email<span class="red-text"> (*)</span></p>
              <input id="Email" type="text" [(ngModel)]="admin.Email_Address" disabled />
            </div>
          </div>

          <form [formGroup]="registrationForm" (ngSubmit)="register(registrationForm)">
            <div class="row">
              <div class="input-field col s11 m10">
                <input *ngIf="!showPassword" id="createPassword" type="password" formControlName='createPassword'
                  required class="validate" minlength="4" maxlength="25" />
                <input *ngIf="showPassword" id="createPassword" type="text" formControlName='createPassword' required
                  class="validate" minlength="4" maxlength="25" />
                <span *ngIf="!showPassword" (click)="toggleVisibility()" class="material-icons field-icon" style="margin-left: -10%;display:inline;
                  vertical-align: middle; font-size: 2.5vh; cursor: pointer;">
                  visibility_off
                </span>
                <span *ngIf="showPassword" (click)="toggleVisibility()" class="material-icons field-icon" style="margin-left: -10%;display:inline;
                  vertical-align: middle; font-size: 2.5vh; cursor: pointer;">
                  visibility
                </span>
                <div *ngIf="(createPassword.dirty || createPassword.touched) && createPassword.invalid">
                  <small *ngIf="createPassword.errors.required" class="red-text"> The Password Is Required.
                  </small>
                  <small *ngIf="createPassword.errors.minlength" class="red-text"> Password Length Must Be At Least 4
                    Characters . </small>
                  <small *ngIf="createPassword.errors.maxlength" class="red-text"> Password Must Not Exceed 25
                    Characters . </small>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="input-field col s11 m10">
                <input *ngIf="!showConfirmPassword" id="confirmPassword" type="password" required
                  formControlName='confirmPassword' class="validate">
                <input *ngIf="showConfirmPassword" id="confirmPassword" type="text" required
                  formControlName='confirmPassword' class="validate">
                <span *ngIf="!showConfirmPassword" (click)="toggleVisibility1()" class="material-icons field-icon"
                  style="margin-left: -10%;display:inline;
              vertical-align: middle; font-size: 2.5vh; cursor: pointer;">
                  visibility_off
                </span>
                <span *ngIf="showConfirmPassword" (click)="toggleVisibility1()" class="material-icons field-icon" style="margin-left: -10%;display:inline;
              vertical-align: middle; font-size: 2.5vh; cursor: pointer;">
                  visibility
                </span>
                <div *ngIf="(confirmPassword.dirty || confirmPassword.touched) && confirmPassword.invalid">
                  <small *ngIf="confirmPassword.errors.required" class="red-text">Confirm Password is required. </small>
                  <small *ngIf="confirmPassword.hasError('invalid')" class="red-text">Passwords Doesnt Match ! </small>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="input-field col s11 m10 center">
                <button type="submit" [disabled]="!registrationForm.valid" class="col s12 btn"
                  [ngClass]="'btn ' + buttonType" [ngStyle]="{'background-color': themeColor}">
                  <i class="material-icons left">how_to_reg</i> Create My Account
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </main>
  </div>
</div>