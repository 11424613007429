import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { toast } from 'materialize-css';
import { PseRegistrationService } from 'src/app/services/api/pse-registration.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { Location } from '@angular/common';
import { PseCoursesToRegisterModel } from 'src/app/models/PseCoursesToRegisterModel';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-pse-course-registration',
  templateUrl: './pse-course-registration.component.html',
  styleUrls: ['./pse-course-registration.component.css']
})
export class PseCourseRegistrationComponent implements OnInit {

  courseYears = [];   // [thisYear, nextYear ];
  courseTerms = ['FALL', 'WINTER', 'SPRING', 'SUMMER'];
  public dateMask = [/[0-3]/, /[0-9]/, '/', /[0-1]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  public dateformat = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;

  courseRegistrationForm: UntypedFormGroup;
  coursesModel: PseCoursesToRegisterModel[];

  addCourse = false;
  displayCourse = false;
  emptyCourse = false;
  coursesList: any[];

  selected_year = 0;
  selected_term = '';
  selected_course = '';

  results: any = [];
  applicantID = 0;
  default_school = '';
  application_id = 0;
  hasRegisteredCourses = false;
  RegisteredCourses: any = [];
  PSE_PWA_status = '';

  constructor(private fb: UntypedFormBuilder,
    private authService: AuthService,
    private pseRegistrationService: PseRegistrationService,
    private location: Location) { }

  ngOnInit(): void {
    this.selected_year = new Date().getFullYear();
    let current_month = new Date().getMonth();
    if (current_month === 12 || current_month === 1 || current_month === 2) {
      this.selected_term = 'WINTER';
    } else if (current_month === 3 || current_month === 4 || current_month === 5) {
      this.selected_term = 'SPRING';
    } else if (current_month === 6 || current_month === 7 || current_month === 8) {
      this.selected_term = 'SUMMER';
    } else if (current_month === 9 || current_month === 10 || current_month === 11) {
      this.selected_term = 'FALL';
    }

    if (localStorage.getItem('applicantID')) {
      this.applicantID = Number(localStorage.getItem('applicantID'));
    } else {
      this.applicantID = this.authService.getCurrentApplicantID();
    }

    this.courseYears.push(new Date().getFullYear());
    this.courseYears.push(new Date().getFullYear() + 1);

    this.courseRegistrationForm = this.fb.group({
      course: this.fb.group({
        add_course: ['', [Validators.required]],
        courses: this.fb.array([]),
        course_year: [''],
        course_term: [''],
        course_name: [''],
        institution: [''],       //this.default_school
        start_date: [''],
        end_date: ['']
      }),
    });

    this.courseRegistrationForm.get('course.add_course').valueChanges.subscribe(value => {
      if (value == 'yes') {
        this.courseRegistrationForm.get('course.course_name').setValidators([Validators.required, Validators.minLength(1)]);
        this.courseRegistrationForm.get('course.course_year').setValidators([Validators.minLength(4), Validators.maxLength(4), Validators.pattern('^[0-9]*$')]);
        this.courseRegistrationForm.get('course.course_term').setValidators(Validators.required);
        this.courseRegistrationForm.get('course.institution').setValidators(Validators.required);
        this.courseRegistrationForm.get('course.start_date').setValidators(Validators.required);
        this.courseRegistrationForm.get('course.end_date').setValidators(Validators.required);
      } else {
        this.courseRegistrationForm.get('course.course_name').clearValidators();
        this.courseRegistrationForm.get('course.course_year').clearValidators();
        this.courseRegistrationForm.get('course.course_term').clearValidators();
        this.courseRegistrationForm.get('course.institution').clearValidators();
        this.courseRegistrationForm.get('course.start_date').clearValidators();
        this.courseRegistrationForm.get('course.end_date').clearValidators();
      }
      this.courseRegistrationForm.get('course.course_name').updateValueAndValidity();
      this.courseRegistrationForm.get('course.course_year').updateValueAndValidity();
      this.courseRegistrationForm.get('course.course_term').updateValueAndValidity();
      this.courseRegistrationForm.get('course.institution').updateValueAndValidity();
      this.courseRegistrationForm.get('course.start_date').updateValueAndValidity();
      this.courseRegistrationForm.get('course.end_date').updateValueAndValidity();
    });

    this.coursesList = [];
    this.results = [];

    /*
    this.memberService.getPsePWAStatus(this.applicantID).subscribe(x => {
      if (x) {
        this.PSE_PWA_status = x;
      }
      if(this.PSE_PWA_status !='Authenticated') {
        toast({ html: 'You Are Not Student Now and Unable to Register Any Courses !', classes: 'red' });
        this.location.back();
      };
      */

    this.pseRegistrationService.getCurrentApplicationID(this.applicantID).subscribe(a => {
      if (a) { this.application_id = a; }
      if (this.application_id > 0) {   //Authenticated
        this.pseRegistrationService.getCurrentInstitution(this.applicantID).subscribe(b => {
          if (b) { this.default_school = b; }

          this.pseRegistrationService.getCourseList().subscribe(c => {
            if (c.length > 0) {
              this.results = c;
              const results1 = this.results.filter(b => b.course_year == this.selected_year);
              const results2 = results1.filter(a => a.term == this.selected_term);
              this.coursesList.push('');
              results2.forEach(a => { this.coursesList.push(a.course); });

              setTimeout(() => {
                $('.collapsible').collapsible();
                $('select').formSelect();
                M.updateTextFields();
              }, 25);

              this.RegisteredCourses = [];
              this.pseRegistrationService.getRegisteredCourses(this.application_id).subscribe(p => {
                if (p.length > 0) {

                  (<UntypedFormGroup>this.courseRegistrationForm.controls['course']).controls['add_course'].patchValue('yes');
                  p.forEach(clg => {
                    let startdate = "";
                    let enddate = "";
                    //if (clg.startdate) { startdate = this.formatDates(clg.startdate); }else{startdate = ""; }   
                    //if (clg.enddate) { enddate = this.formatDates(clg.enddate); }else{enddate = ""; }

                    if (clg.startdate) { startdate = this.changeDates(clg.startdate).toDateString(); } else { startdate = ""; }
                    if (clg.enddate) { enddate = this.changeDates(clg.enddate).toDateString(); } else { enddate = ""; }

                    let tmpFromgroup = this.fb.group({
                      student_aid: clg.student_aid,
                      course_id: clg.course_id,
                      course_year: clg.course_year,
                      course_term: clg.term,
                      course_name: clg.course,
                      institution: clg.course_type,
                      start_date: startdate,
                      end_date: enddate,
                      pse_application_id: clg.pse_application_id
                    });
                    this.courseControls.push(tmpFromgroup);

                    let aCourse = new PseCoursesToRegisterModel();
                    aCourse.student_aid = clg.student_aid;
                    aCourse.course_id = clg.course_id;
                    aCourse.course_year = clg.course_year;
                    aCourse.course_term = clg.term;
                    aCourse.course_name = clg.course;
                    aCourse.institution = clg.course_type;
                    //aCourse.start_date= startdate;  
                    // aCourse.end_date= enddate;   
                    aCourse.start_date = this.changeDates(clg.startdate).toDateString(),
                      aCourse.end_date = this.changeDates(clg.enddate).toDateString(),
                      aCourse.pse_application_id = clg.pse_application_id;
                    this.RegisteredCourses.push(aCourse);
                  });

                  this.emptyCourse = false;
                  this.displayCourse = true;
                  this.addCourse = false;
                  this.hasRegisteredCourses = true;

                  setTimeout(() => {
                    $('.collapsible').collapsible();
                    $('select').formSelect();
                    M.updateTextFields();
                  }, 25);
                } else {
                  (<UntypedFormGroup>this.courseRegistrationForm.controls['course']).controls['add_course'].patchValue('no');
                  this.onAddCourseNo();
                  this.hasRegisteredCourses = false;
                  this.RegisteredCourses = [];
                }
              });
            }
          });
        });
      } else {
        toast({ html: 'You have no application, unable to register any courses !', classes: 'red' });
        this.location.back();
      }
    });

    this.coursesModel = [];

    setTimeout(() => {
      $('.collapsible').collapsible();
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }

  get courses() { return this.courseRegistrationForm.get('course.courses'); }
  get course_year() { return this.courseRegistrationForm.get('course.course_year'); }
  get course_term() { return this.courseRegistrationForm.get('course.course_term'); }
  get course_name() { return this.courseRegistrationForm.get('course.course_name'); }
  get institution() { return this.courseRegistrationForm.get('course.institution'); }
  get start_date() { return this.courseRegistrationForm.get('course.start_date'); }
  get end_date() { return this.courseRegistrationForm.get('course.end_date'); }
  get courseControls() { return this.courseRegistrationForm.get('course.courses') as UntypedFormArray; }

  formatDates(dateValue) {
    if (dateValue) {
      let dateArray = dateValue.split('-');
      let year = dateArray[0];
      let month = dateArray[1];   //- 1;
      let day = dateArray[2].substring(0, 2);
      return day + '/' + month + '/' + year;
    } else {
      return '';
    }
  }

  onAddCourseYes() {
    this.addCourse = true;
    setTimeout(() => {
      $('.collapsible').collapsible();
      $('select').formSelect();
      M.updateTextFields();
    }, 25);

    this.emptyCourse = false;
    this.courseRegistrationForm.get('course.course_year').reset();
    this.courseRegistrationForm.get('course.course_term').reset();
    this.courseRegistrationForm.get('course.course_name').reset();
    this.courseRegistrationForm.get('course.institution').reset();
    this.courseRegistrationForm.get('course.start_date').reset();
    this.courseRegistrationForm.get('course.end_date').reset();
  }

  onAddCourseNo() {
    this.addCourse = false;
    const courses = this.courseControls;
    while (courses.length !== 0) {
      courses.removeAt(0);
    }
    this.emptyCourse = true;
    this.displayCourse = false;
    courses.setValue([]);
  }

  addMyCourse(values: any): void {
    const courses = this.courseControls;

    const tmp = values.course_name;
    if (!tmp || tmp == '') {
      toast({ html: 'Please Select a Course !', classes: 'red' });
    }

    //get course id
    const results1 = this.results.filter(b => b.course_year == this.selected_year);
    const results2 = results1.filter(a => a.term == this.selected_term);
    const results3 = results2.filter(a => a.course == this.selected_course);
    let cid = results3[0].course_id;

    //check duplicated course added 
    const courses1 = this.RegisteredCourses.filter(b => b.course_year == this.selected_year);
    const courses2 = courses1.filter(a => a.course_term == this.selected_term);
    const courses3 = courses2.filter(a => a.course_name == this.selected_course);
    if (courses3.length > 0) {
      toast({ html: '<p>The Course is Already in the List !</p> ', classes: 'red' });
      return;
    } else {

      let tmpFromgroup = this.fb.group({
        student_aid: this.applicantID,
        course_id: cid,
        course_year: [values.course_year],
        course_term: [values.course_term],
        course_name: this.selected_course.trim(),
        institution: [values.institution],
        start_date: this.changeDates(values.start_date).toDateString(),
        end_date: this.changeDates(values.end_date).toDateString(),
        pse_application_id: this.application_id
      });
      courses.push(tmpFromgroup);

      let aCourse = new PseCoursesToRegisterModel();
      aCourse.student_aid = this.applicantID;
      aCourse.course_id = cid;
      aCourse.course_year = values.course_year;
      aCourse.course_term = values.course_term;
      aCourse.course_name = this.selected_course.trim();
      aCourse.institution = values.institution;
      //aCourse.start_date= values.start_date;  
      //aCourse.end_date= values.end_date;
      aCourse.start_date = this.changeDates(values.start_date).toDateString(),
        aCourse.end_date = this.changeDates(values.end_date).toDateString(),
        aCourse.pse_application_id = this.application_id;
      this.RegisteredCourses.push(aCourse);
      this.hasRegisteredCourses = true;
      this.emptyCourse = false;
      this.displayCourse = true;
      this.addCourse = false;
      setTimeout(() => {
        $('.collapsible').collapsible();
        $('select').formSelect();
        M.updateTextFields();
      }, 25);
      toast({ html: 'Course Successfully Registered!', classes: 'green' });
    }

    // student_aid, course_id, course_type, startdate, enddate, completiondate, completed, score, passed, pse_application_id
    /*
    let courseModel = new PseFindCourseModel();
    courseModel.course = values.course_name.trim();
    courseModel.year = values.course_year;
    courseModel.term = values.course_term;
    
    this.pseRegistrationService.searchCourse(courseModel).subscribe(x => {
      if (x > 0) { 
          
          let cid = x;
          let tmpFromgroup=  this.fb.group({ 
            course_id: cid,
            course_year: [values.course_year],
            course_term: [values.course_term],
            course_name: [values.course_name.trim()],   
            institution: [values.institution],  
            start_date: [values.start_date], 
            end_date: [values.end_date], 
         });
         //console.log("course: ", tmpFromgroup)
         courses.push(tmpFromgroup);

       }else{
          toast({ html: '<p>No such course found on the system !</p> ', classes: 'red' }); 
          return;
       }
       this.emptyCourse = false;
       this.displayCourse = true;
       this.addCourse = false;
       setTimeout(() => {
         $('.collapsible').collapsible();
         $('select').formSelect();
       }, 25);
       toast({ html: 'Course Successfully Registered!', classes: 'green' });
    });
    */
  }

  addAnotherCourse() {
    this.selected_year = new Date().getFullYear();
    let current_month = new Date().getMonth();
    if (current_month === 12 || current_month === 1 || current_month === 2) {
      this.selected_term = 'WINTER';
    } else if (current_month === 3 || current_month === 4 || current_month === 5) {
      this.selected_term = 'SPRING';
    } else if (current_month === 6 || current_month === 7 || current_month === 8) {
      this.selected_term = 'SUMMER';
    } else if (current_month === 9 || current_month === 10 || current_month === 11) {
      this.selected_term = 'FALL';
    }
    this.addCourse = true;
   
    this.coursesList = [];
    const results1 = this.results.filter(b => b.course_year == this.selected_year);
    const results2 = results1.filter(a => a.term === this.selected_term);
    //this.coursesList = results2;

    this.coursesList.push('');
    results2.forEach(a => { this.coursesList.push(a.course); });

    this.selected_course = '';

    setTimeout(() => {
      $('.collapsible').collapsible();
      $('select').formSelect();
      M.updateTextFields();
    }, 25);

    //this.emptyCourse = false;
    this.courseRegistrationForm.get('course.course_year').reset();
    this.courseRegistrationForm.get('course.course_term').reset();
    this.courseRegistrationForm.get('course.course_name').reset();
    this.courseRegistrationForm.get('course.institution').reset();
    this.courseRegistrationForm.get('course.start_date').reset();
    this.courseRegistrationForm.get('course.end_date').reset();
  }

  onDeleteCourse(index) {

    this.RegisteredCourses = [];

    const courses = this.courseControls;
    courses.removeAt(index);
    if (courses.length == 0) {
      this.addCourse = false;
      this.displayCourse = false;
      this.emptyCourse = true;
      this.hasRegisteredCourses = false;
      this.courseRegistrationForm.patchValue({ 'course': { 'add_course': 'no' } });
    } else {
      for (let c of this.courseControls.controls) {
        let aCourse = new PseCoursesToRegisterModel();
        aCourse.student_aid = c.value.student_aid;
        aCourse.course_id = c.value.course_id;
        aCourse.course_year = c.value.course_year;
        aCourse.course_term = c.value.course_term;
        aCourse.course_name = c.value.course_name;
        aCourse.institution = c.value.institution;

        aCourse.start_date = c.value.start_date;
        aCourse.end_date = c.value.end_date;
        aCourse.pse_application_id = c.value.pse_application_id;
        this.RegisteredCourses.push(aCourse);
      }
    }
    toast({ html: 'Course deleted!', classes: 'red' });
  }

  closeAddCourse() {
    this.addCourse = false;

    this.courseRegistrationForm.get('course.course_year').clearValidators();
    this.courseRegistrationForm.get('course.course_term').clearValidators();
    this.courseRegistrationForm.get('course.course_name').clearValidators();
    this.courseRegistrationForm.get('course.institution').clearValidators();

    if (this.courseControls.length == 0) {
      this.courseRegistrationForm.patchValue({ 'course': { 'add_course': 'no' } });
    }
  }

  courseYearChange(value) {
    this.coursesList = [];
    this.selected_year = value;
    const results1 = this.results.filter(b => b.course_year == this.selected_year);
    const results2 = results1.filter(a => a.term == this.selected_term);
  
    this.coursesList.push('');
    results2.forEach(a => { this.coursesList.push(a.course); });
    this.selected_course = '';

    setTimeout(() => {
      $('.collapsible').collapsible();
      $('select').formSelect();
      M.updateTextFields();
    }, 10);
  }

  courseTermChange(value) {
    this.coursesList = [];
    this.selected_term = value;
    const results1 = this.results.filter(b => b.course_year == this.selected_year);
    const results2 = results1.filter(a => a.term == this.selected_term);
   
    this.coursesList.push('');
    results2.forEach(a => { this.coursesList.push(a.course); });

    this.selected_course = '';

    setTimeout(() => {
      $('.collapsible').collapsible();
      $('select').formSelect();
      M.updateTextFields();
    }, 10);
  }

  coursesChange(value) {
    //const results1 = this.results.filter(b => b.course_year == this.selected_year);
    // const results2 = results1.filter(a => a.term ==  this.selected_term);
    //const results3 = results2.filter(a => a.course ==  this.selected_course);
  }

  changeDates(dateValue) {
    if (dateValue) {
      if (dateValue.match(this.dateformat)) {
        let dateArray = dateValue.split('/');
        let year = dateArray[2];
        let month = dateArray[1] - 1;
        let day = dateArray[0];
        const date = new Date(year, month, day);
        return date;
      } else {
        const date = new Date(dateValue);
        return date;
      }
    } else {
      return null
    }
  }

  submitCoursesRegistration(values: any) {
    this.coursesModel = values.courses;
    this.pseRegistrationService.saveCoursesToRegister(this.coursesModel).subscribe(x => {
      if (x) {
        toast({ html: 'Courses Successfully Registered!', classes: 'green' });
        this.location.back();
      }
    });
  }

  onBack() {
    this.location.back();
  }

}