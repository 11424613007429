import { Component, OnInit, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';
import { UntypedFormGroup, UntypedFormBuilder, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { GovernanceService } from 'src/app/services/firebase/governance.service';
import { SearchMemberModel } from 'src/app/models/searchMemberModel';
import { MemberService } from 'src/app/services/api/member.service';
import { toast } from 'materialize-css';
import { DepartmentService } from 'src/app/services/firebase/department.service';
import { MediaService } from 'src/app/services/api/media.service';
import { CommunityService } from 'src/app/services/api/community.service';
import { FireMembersService } from 'src/app/services/firebase/fire-members.service';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import { environment } from 'src/environments/environment';
import { EmailService } from 'src/app/services/api/email.service';
import { EmailModel } from 'src/app/models/emailModel';
import { OtherRolesService } from 'src/app/services/firebase/other-roles.service';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import DataTables from 'datatables.net';
import { createMask } from '@ngneat/input-mask';

declare var $: any;
declare var M: any;
declare var navigator: any;

@Component({
  selector: 'app-add-councillor',
  templateUrl: './add-councillor.component.html',
  styleUrls: ['./add-councillor.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class AddCouncillorComponent implements OnInit, OnDestroy {

  defaultThemeColor = environment.appTheme.themeColor;
  themeColor: any;
  buttonType: any;
  councillorTitle: any;

  @ViewChild(DataTableDirective, { static: true }) datatableElement: DataTableDirective;
  // dtOptions: DataTables.Settings = {};
  dtTrigger: Subject<any> = new Subject();
  showSearchTable: Boolean = false;
  searchFormValid: Boolean = true;
  showCouncillorForm: Boolean = false;
  showSearch: Boolean = true;
  showAddButton: Boolean;
  showNextButton: Boolean = false;
  processing: Boolean = false;
  modalReference: any;
  employeename: any;
  toggleTab = false;
  addCouncillorForm: UntypedFormGroup;
  nationOfUse = environment.firstNation.name;
  searchMemberForm: UntypedFormGroup;
  memberModel: SearchMemberModel;
  postObj: any;
  employeesListData: any;
  loadingTxt = "";
  fromWhere: any;
  today = new Date();
  appointmentDate: any;
  expiryDate: any;
  councillorBio: any;
  selectedFile: File = null;
  profile_pic: any;
  employeeId: any;
  model: any;
  governance_id: any;
  edit_governance: any;
  selected_member: any;
  editing_councillor: any;
  base64Image: any;
  mobilePattern = /^\s*([\(])[[0-9]{2}\d{1}[\)]\s*[\-]?[\.]?\s*\d{3}\s*[\-]?[\.]?\s*\d{4}$/;
  //public phoneNumberMask = ['(', /[0-9]/, /[0-9]/, /[0-9]/, ')', ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  phoneInputMask = createMask<string>( {
    mask: '(999) 999-9999',
    guide: true,
    showMaskTyped: true,
  });

  public urlPattern = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
  departmentsList: any;
  departmentsOptions: any[];
  selectedDepartmentsList: any[];
  selectedMemberFirstName: '';
  selectedMemberLastName: '';
  selectedMemberEmail: '';
  myimgLink: any;
  binDoc: File;
  imgErrorMsg: any;
  showImageInput = true;
  oldUrl: any;
  communities: any[];

  councillorFacebookAcc: any;
  councillorInstagramAcc: any;
  councillorTwitterAcc: any;
  councillorLinkedinAcc: any;
  councillorYoutubeAcc: any;

  showMobileNo = 'Public';
  showOfficeNo = 'Public';
  showEmailAdr = 'Public';
  allowContact = 'Public';
  showFacebookAcc = 'Private';
  showTwitterAcc = 'Private';
  showInstagramAcc = 'Private';
  showLinkedinAcc = 'Private';
  showYoutubeAcc = 'Private';
  isMobile = false;
  isEdit = false;
  editEmployeeId: any;

  myDpOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'dd/mmm/yyyy',
    markCurrentDay: true,
  };

  deleteItem: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private memberService: MemberService,
    private governanceService: GovernanceService,
    private departmentService: DepartmentService,
    private mediaService: MediaService,
    private communityService: CommunityService,
    private fireMember: FireMembersService,
    private emailService: EmailService,
    private otherRolesService: OtherRolesService,
    private settingsService: AppSettingsService) {

    if (typeof window['cordova'] !== 'undefined') {
      this.isMobile = true;
    }

    this.themeColor = this.settingsService.getAppThemeColor() ? this.settingsService.getAppThemeColor() : this.defaultThemeColor;
    this.buttonType = this.settingsService.getButtonType() ? this.settingsService.getButtonType() : 'rectangularButton';

    this.councillorTitle = this.settingsService.getGovTitle().councillorTitle ? this.settingsService.getGovTitle().councillorTitle : 'Councillors';

    this.searchMemberForm = this.formBuilder.group({
      firstName: ['', [Validators.required, Validators.minLength(1)]],
      lastName: ['', [Validators.required, Validators.minLength(1)]]
    });
    this.createNewForm();
  }

  ngOnInit() {
    this.governance_id = window.history.state.governance_id;
    this.getGovernanceFormData(this.governance_id);

    let path = this.router.url.split('/');
    if (path[path.length - 1] === 'add-councillor') {
      this.isEdit = true;
      this.fromWhere = 'add';
      this.employeeId = window.history.state.employee_id;
      this.appointmentDate = null;
      this.expiryDate = null;
    } else if (path[path.length - 1] === 'edit-councillor') {
      this.fromWhere = 'edit';
      this.isEdit = false;
      this.employeeId = window.history.state.employee_id;
      if (this.employeeId) {
        this.getCouncillorData(this.employeeId, this.governance_id);

      } else {
        this.router.navigate(['/admin/dashboard-sub-menu/governance']);
      }
    } else {
      //this.router.navigate(['/admin/governance']);
    }
  }

  privateMobileClick() {
    this.showMobileNo = 'Private';
  }

  publicMobileClick() {
    this.showMobileNo = 'Public';
  }

  membersMobileClick() {
    this.showMobileNo = 'Members';
  }

  privateOfficeClick() {
    this.showOfficeNo = 'Private';
  }

  publicOfficeClick() {
    this.showOfficeNo = 'Public';
  }

  membersOfficeClick() {
    this.showOfficeNo = 'Members';
  }

  privateEmailClick() {
    this.showEmailAdr = 'Private';
  }

  publicEmailClick() {
    this.showEmailAdr = 'Public';
  }

  membersEmailClick() {
    this.showEmailAdr = 'Members';
  }

  privateContactClick() {
    this.allowContact = 'Private';
  }

  publicContactClick() {
    this.allowContact = 'Public';
  }

  membersContactClick() {
    this.allowContact = 'Members';
  }
  // facebook
  privateFacebookClick() {
    this.showFacebookAcc = 'Private';
  }

  publicFacebookClick() {
    this.showFacebookAcc = 'Public';
  }

  membersFacebookClick() {
    this.showFacebookAcc = 'Members';
  }
  // instagram
  privateInstagramClick() {
    this.showInstagramAcc = 'Private';
  }

  publicInstagramClick() {
    this.showInstagramAcc = 'Public';
  }

  membersInstagramClick() {
    this.showInstagramAcc = 'Members';
  }
  // twitter
  privateTwitterClick() {
    this.showTwitterAcc = 'Private';
  }

  publicTwitterClick() {
    this.showTwitterAcc = 'Public';
  }

  membersTwitterClick() {
    this.showTwitterAcc = 'Members';
  }
  // linkedin
  privateLinkedinClick() {
    this.showLinkedinAcc = 'Private';
  }

  publicLinkedinClick() {
    this.showLinkedinAcc = 'Public';
  }

  membersLinkedinClick() {
    this.showLinkedinAcc = 'Members';
  }
  // youtube
  privateYoutubeClick() {
    this.showYoutubeAcc = 'Private';
  }

  publicYoutubeClick() {
    this.showYoutubeAcc = 'Public';
  }

  membersYoutubeClick() {
    this.showYoutubeAcc = 'Members';
  }

  onAppointmentDateChange(event: IMyDateModel): void {
    if (this.addCouncillorForm) {
      this.addCouncillorForm.patchValue({ appointmentDate: event.singleDate.formatted });
      this.appointmentDate = event.singleDate.formatted;
    }
  }

  onExpiryDateChange(event: IMyDateModel): void {
    if (this.addCouncillorForm) {
      this.addCouncillorForm.patchValue({ expiryDate: event.singleDate.formatted });
      this.expiryDate = event.singleDate.formatted;
    }
  }

  /* On destroy event */
  ngOnDestroy(): void {
    // Do not forget to unsubscribe the event
    this.dtTrigger.unsubscribe();
  }

  //Cancel the search text
  onClearSearchEmployees() {
    this.showSearchTable = false;
    this.showNextButton = false;
    this.showAddButton = true;
    this.searchMemberForm.setValue({
      firstName: '',
      lastName: ''
    });
  }

  createNewForm() {
    if (this.nationOfUse === 'Batc' || this.nationOfUse === 'Pbcn') {
      this.communityService.getNationCommunities().subscribe(com => {
        if (com) {
          this.communities = com;
          this.addCouncillorForm = this.formBuilder.group({
            displayName: [],
            councillorBio: [''],
            appointmentDate: [''],
            expiryDate: [''],
            email: ['', [Validators.required, Validators.email]],
            cell_phone: ['', [Validators.pattern(this.mobilePattern)]],
            website: ['', [Validators.pattern(this.urlPattern)]],
            imgLink: [''],
            community: [''],
          });
          setTimeout(() => {
            $('select').formSelect();
          }, 50);
        }
      });
    } else {
      this.communityService.getCommunity().subscribe(com => {
        if (com) {
          this.communities = com;
          this.addCouncillorForm = this.formBuilder.group({
            displayName: [],
            councillorBio: [''],
            appointmentDate: [''],
            expiryDate: [''],
            email: ['', [Validators.required, Validators.email]],
            cell_phone: ['', [Validators.pattern(this.mobilePattern)]],
            website: ['', [Validators.pattern(this.urlPattern)]],
            imgLink: [''],
            community: [''],
          });
          setTimeout(() => {
            $('select').formSelect();
          }, 50);
        }
      });
    }
  }

  onSearchEmployees() {
    this.processing = false;
    if (this.searchMemberForm.value.firstName != '' || this.searchMemberForm.value.lastName != '') {
      this.memberModel = new SearchMemberModel();
      this.memberModel.firstName = this.searchMemberForm.value.firstName;
      this.memberModel.lastName = this.searchMemberForm.value.lastName;

      this.memberService.searchMember(this.memberModel).subscribe(x => {
        if (x) {
          this.employeesListData = this.filterMembers(x);
          this.showSearchTable = true;
          this.processing = true;
          // Calling the DT trigger to manually render the table
          this.dtTrigger.next(null);
        }
      });
      this.postObj = { ...this.searchMemberForm.value };
    }
  }

  filterMembers(searched_members) {
    var registred_members = [];
    if (typeof (this.edit_governance.chiefs) != "undefined" && this.edit_governance.chiefs.length > 0) {
      Object.keys(this.edit_governance.chiefs).map(x => {
        registred_members.push(this.edit_governance.chiefs[x].applicantID);
      });
    }
    if (typeof (this.edit_governance.councillors) != "undefined" && this.edit_governance.councillors.length > 0) {
      Object.keys(this.edit_governance.councillors).map(x => {
        registred_members.push(this.edit_governance.councillors[x].applicantID);
      });
    }
    var not_registered = searched_members.filter(function (item) { return !registred_members.includes(item.applicantID); });
    setTimeout(() => {
      $('.modal').modal();
    }, 50);
    return not_registered;
  }

  //Set form data @param employeeId
  get getFirstName() { return this.addCouncillorForm.get('firstName'); }
  get getLastName() { return this.addCouncillorForm.get('lastName'); }
  get getAppointmentDate() { return this.addCouncillorForm.get('appointmentDate'); }
  get getExpiryDate() { return this.addCouncillorForm.get('expiryDate'); }
  get getChiefBio() { return this.addCouncillorForm.get('chiefBio'); }
  get getEmail() { return this.addCouncillorForm.get('email'); }
  get getCell_phone() { return this.addCouncillorForm.get('cell_phone'); }
  get getcommunity() { return this.addCouncillorForm.get('community'); }
  get getOffice_phone() { return this.addCouncillorForm.get('office_phone'); }
  get getCouncillorFacebookAcc() { return this.addCouncillorForm.get('councillorFacebookAcc'); }
  get getCouncillorInstagramAcc() { return this.addCouncillorForm.get('councillorInstagramAcc'); }
  get getCouncillorTwitterAcc() { return this.addCouncillorForm.get('councillorTwitterAcc'); }
  get getCouncillorLinkedinAcc() { return this.addCouncillorForm.get('councillorLinkedinAcc'); }
  get getCouncillorYoutubeAcc() { return this.addCouncillorForm.get('councillorYoutubeAcc'); }
  get website() { return this.addCouncillorForm.get('website'); }


  setFormDetails(selected_member) {
    let found = this.employeesListData.filter(function (item) { return item.applicantID === selected_member.applicantID; });
    this.selected_member = selected_member;

    if (found !== undefined || found != null) {
      this.departmentService.getDepartmentList().valueChanges().subscribe(x => {
        if (x) {
          this.departmentsList = x;
          this.departmentsOptions = [];
          this.departmentsList.forEach(dep => {
            const option = { id: dep.id, name: dep.name, isSelected: false };
            this.departmentsOptions.push(option);
          });
        }
      });

      this.employeeId = this.selected_member.applicantID;
      this.selectedMemberFirstName = this.selected_member.GivenName;
      this.selectedMemberLastName = this.selected_member.LastName;
      this.selectedMemberEmail = this.selected_member.Email ? this.selected_member.Email : '';

      this.addCouncillorForm = this.formBuilder.group({
        firstName: [{ value: this.selectedMemberFirstName, disabled: true }, [Validators.required]],
        lastName: [{ value: this.selectedMemberLastName, disabled: true }, [Validators.required]],
        displayName: [this.selectedMemberFirstName + ' ' + this.selectedMemberLastName],
        chiefBio: [''],
        councillorFacebookAcc: ['', [Validators.pattern(this.urlPattern)]],
        councillorInstagramAcc: ['', [Validators.pattern(this.urlPattern)]],
        councillorTwitterAcc: ['', [Validators.pattern(this.urlPattern)]],
        councillorLinkedinAcc: ['', [Validators.pattern(this.urlPattern)]],
        councillorYoutubeAcc: ['', [Validators.pattern(this.urlPattern)]],
        appointmentDate: [''],
        expiryDate: [''],
        email: [this.selectedMemberEmail, [Validators.required, Validators.email]],
        cell_phone: [''],
        website: ['', [Validators.pattern(this.urlPattern)]],
        office_phone: [''],
        community: [''],
        imgLink: [''],
        showFacebookAcc: [''],
        showTwitterAcc: [''],
        showInstagramAcc: [''],
        showLinkedinAcc: [''],
        showYoutubeAcc: ['']
      });

      setTimeout(() => {
        $('.modal').modal();
        $('#detailModal').modal('open');
      }, 25);
    }
  }

  selectClick() {
    $('#detailModal').modal('close');
    this.showNextButton = true;
    this.showAddButton = false;
    this.showSetForm();

    setTimeout(() => {
      M.updateTextFields();
      $('select').formSelect();
    }, 50);
  }

  //Show set form when next is clicked
  showSetForm() {
    this.showCouncillorForm = true;
    this.showSearchTable = false;
    this.showNextButton = false;
    this.showSearch = false;
    this.showAddButton = false;
  }

  //Click on cancel button of Councillor form
  cancelCouncillorForm() {
    this.router.navigate(['/admin/dashboard-sub-menu/edit-governance'], { state: { governance_id: this.governance_id } });
  }

  //Add and update Councillor data @param fromWhere
  
  addCouncillorData() {
    if (this.addCouncillorForm.valid) {
      let formData = this.addCouncillorForm.value;
      let councilorData;
      // get user if exists
      this.fireMember.getMemberByAppliciantId(this.employeeId).valueChanges().subscribe(councillor => {
        if (councillor.length > 0) {
          councilorData = councillor[0];
          const model: any = {
            uid: councilorData.uid,
            isChief: false,
            isCouncillor: true
          };
          // update account
          this.fireMember.updateAccountRole(model);
          // then notify
          if (this.fromWhere === 'add') {
            const mail = new EmailModel();
            mail.emailList = [formData.email];
            mail.subject = `Added as a councilor`;
            mail.body = `Hello ${formData.displayName},<br/>`;
            mail.body = mail.body += `You have been elected to be a councillor by an admin so you can
            customize your profile by logging to your account.`;
            mail.body = mail.body += `<br/>Thank you.`;
            // this.emailService.sendEmail(mail).subscribe(() => {
            //    toast({ html: 'Email Successfully Sent.', classes: 'green' });
            // });
          }
        } else {
          if (this.fromWhere === 'add') {
            // new user so register
            const invitationDetailObj: any = {
              applicantID: this.employeeId,
              firstName: this.selectedMemberFirstName,
              lastName: this.selectedMemberLastName,
              displayName: formData['displayName'],
              email: formData['email'],
              isChief: false,
              isCouncillor: true,
              invitationDate: Date.now(),
            };
            this.otherRolesService.saveInvitation(invitationDetailObj);

            // then notify
            let registrationDomain = window.location.hostname;
            if (window.location.hostname === 'localhost') {
              registrationDomain = 'http://localhost:4200';
            } else {

              registrationDomain = `https://${registrationDomain}`;
            }
            registrationDomain = `${registrationDomain}/#/auth/register-other-roles/${this.employeeId}`;
            const email = {
              subject: 'Registration Email',
              body: `<p> Good Day <strong style="color: green;"> ` + formData['displayName'] + ` </strong></p>
            <p> Welcome to the "` + this.nationOfUse + `" mobile platform. </p>
        <p> This email is to confirm your registration as an official of our platform. </p>
        <p> Click the link below to complete your registration by creating your password and choosing your security settings.  </p>
       
        </p>

        <br/> <br/>
        <a href="` + registrationDomain + `" target="_blank"> Please Click Here To Complete Registration </a>
        <br/> <p>Thank You & Welcome to "` + this.nationOfUse + `"</p>" `,
              emailList: [],
            };
            email.emailList.push(formData['email']);
            this.emailService.sendEmail(email).subscribe(
              e => {
                if (e) {
                  toast({ html: 'Registration Email Successfully Sent', classes: 'green' });
                }
              }
            );
          }
        }
      });

      let portfolioItems = [];
      this.departmentsOptions.forEach(element => {
        if (element.isSelected) {
          portfolioItems.push({ id: element.id, name: element.name });
        }
      });

      let postObj: any = {
        applicantID: this.employeeId,
        firstName: this.selectedMemberFirstName,
        lastName: this.selectedMemberLastName,
        displayName: formData.displayName,
        appointmentDate: (this.appointmentDate) ? this.appointmentDate : '',
        expiryDate: (this.expiryDate) ? this.expiryDate : '',
        chiefBio: formData.chiefBio,
        councillorFacebookAcc: formData.councillorFacebookAcc,
        councillorInstagramAcc: formData.councillorInstagramAcc,
        councillorTwitterAcc: formData.councillorTwitterAcc,
        councillorLinkedinAcc: formData.councillorLinkedinAcc,
        councillorYoutubeAcc: formData.councillorYoutubeAcc,
        email: formData.email,
        cell_phone: formData.cell_phone,
        website: formData.website,
        office_phone: formData.office_phone,
        community: formData.community,
        archive: 0,
        portfolio: portfolioItems,
        profile_pic: this.profile_pic ? this.profile_pic: '',
        showEmail: this.showEmailAdr,
        showMobile: this.showMobileNo,
        showOfficePhone: this.showOfficeNo,
        allowContact: this.allowContact,
        showFacebookAcc: this.showFacebookAcc,
        showTwitterAcc: this.showTwitterAcc,
        showInstagramAcc: this.showInstagramAcc,
        showLinkedinAcc: this.showLinkedinAcc,
        showYoutubeAcc: this.showYoutubeAcc
      };

      if (this.binDoc && this.oldUrl) {
        this.mediaService.UpdateBinImage(this.binDoc, this.oldUrl).subscribe(
          upload => {
            if (upload) {
              postObj.profile_pic = upload.imgLink;
              this.processForm(postObj);
              return;
            }
          }
        );
      } else if (this.binDoc && !this.oldUrl) {
        this.mediaService.UploadBinImage(this.binDoc).subscribe(
          upload => {
            if (upload) {
              postObj.profile_pic = upload.imgLink;
              this.processForm(postObj);
              return;
            }
          }
        );
      } else if (!this.binDoc && this.oldUrl) {
        this.mediaService.DeleteBinImage(this.binDoc).subscribe(
          upload => {
            if (upload) {
              postObj.profile_pic = '';
              this.processForm(postObj);
              return;
            }
          });
      } else if (!this.binDoc && !this.oldUrl) {
        this.processForm(postObj);
        return;
      }

    } else {
      (Object as any).values(this.addCouncillorForm.controls).forEach((control: any) => {
        control.markAsTouched();
        // this.router.navigate(['/admin/edit-governance'], { state: { governance_id: this.governance_id } });
      });
      return;
    }
  }

  processForm(postObj) {
    let governance: any;

    governance = {
      id: this.governance_id,
      name: 'Default Governance',
      chiefs: this.edit_governance.chiefs,
      councillors: this.edit_governance.councillors
    };

    if (this.fromWhere === 'add') {
      var councillorArr = [];
      councillorArr = this.edit_governance.councillors;
      councillorArr.push(postObj);
      governance.councillors = councillorArr;
    }

    if (this.fromWhere === 'edit') {
      const obj = this.edit_governance.councillors.find(item => item.applicantID === this.employeeId);
      const index = this.edit_governance.councillors.indexOf(obj);

      var councillorArr = [];
      councillorArr = this.edit_governance.councillors;
      councillorArr[index] = postObj;
      governance.councillors = councillorArr;
    }

    this.governanceService.UpdateGovernanceCouncillor(governance).then(x => {
      if (this.fromWhere === 'add') {
        setTimeout(() => {
          this.fireMember.setMemberAsCouncillor(this.employeeId);
        }, 25);

        toast({ html: 'Councillor Successfully Added!', classes: 'green' });
        this.router.navigate(['/admin/dashboard-sub-menu/edit-governance'], { state: { governance_id: this.governance_id } });
      }

      if (this.fromWhere === 'edit') {
        toast({ html: 'Councillor Profile Successfully Updated!', classes: 'green' });
      }
    });
  }

  get imgLink() { return this.addCouncillorForm.get('imgLink'); }

  handleFileInput(files: FileList) {
    if (!this.validateImage(files[0].name)) {
      this.imgErrorMsg = 'Invalid File Type, Please Select an Image File';
      return;
    }

    this.binDoc = files[0];
    this.imgErrorMsg = undefined;

    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.base64Image = reader.result as string;
      this.profile_pic = reader.result;
    };
  }

  deletePhoto(){
    this.deleteItem = 'Photo';
    $('.modal').modal();
  }

  deleteCouncillor(){
    this.deleteItem = 'Councillor';
    $('.modal').modal();
  }

  removeSavedFile() {
    this.binDoc = undefined;
    this.base64Image = undefined;
    this.oldUrl = (this.myimgLink) ? this.myimgLink : undefined;
    this.myimgLink = '';
    this.profile_pic = undefined;
    this.showImageInput = true;
  }

  getCouncillorData(employee_applicantID, governance_id) {
    if (this.nationOfUse === 'Batc' || this.nationOfUse === 'Pbcn') {
      this.communityService.getNationCommunities().subscribe(com => {
        if (com) {
          this.communities = com;
          this.setRetrievedData(employee_applicantID, governance_id);
        }
      });
    }
    else {
      this.communityService.getCommunity().subscribe(com => {
        if (com) {
          this.communities = com;
          this.setRetrievedData(employee_applicantID, governance_id);
        }
      });
    }
  }

  setRetrievedData(employee_applicantID: any, governance_id: any) {
    this.governanceService.getGovernanceByID(governance_id).valueChanges().subscribe(x => {
      if (x) {
        this.edit_governance = x;
        let found = this.edit_governance.councillors.filter(function (item) { return item.applicantID === employee_applicantID; });

        found = found[0];
        this.editing_councillor = found
        if (found) {

          this.departmentService.getDepartmentList().valueChanges().subscribe(x => {
            if (x) {
              this.departmentsList = x;
              this.departmentsOptions = [];
              this.departmentsList.forEach(dep => {
                dep.isSelected = false;
                if (found.portfolio) {
                  found.portfolio.forEach(element => {
                    if (element.id === dep.id) {
                      dep.isSelected = true;
                    }
                  });
                }
                const option = { id: dep.id, name: dep.name, isSelected: dep.isSelected };
                this.departmentsOptions.push(option);
              });
            }
          });

          this.selectedMemberFirstName = found.firstName;
          this.selectedMemberLastName = found.lastName;
          this.addCouncillorForm = this.formBuilder.group({
            firstName: [{ value: found.firstName, disabled: true }, [Validators.required]],
            lastName: [{ value: found.lastName, disabled: true }, [Validators.required]],
            displayName: [found.displayName],
            chiefBio: [found.chiefBio],
            appointmentDate: [found.appointmentDate],
            expiryDate: [found.expiryDate],
            email: [found.email, [Validators.required, Validators.email]],
            cell_phone: [found.cell_phone, [Validators.pattern(this.mobilePattern)]],
            office_phone: [found.office_phone, [Validators.pattern(this.mobilePattern)]],
            community: [found.community],
            councillorFacebookAcc: [found.councillorFacebookAcc, [Validators.pattern(this.urlPattern)]],
            councillorInstagramAcc: [found.councillorInstagramAcc, [Validators.pattern(this.urlPattern)]],
            councillorTwitterAcc: [found.councillorTwitterAcc, [Validators.pattern(this.urlPattern)]],
            councillorLinkedinAcc: [found.councillorLinkedinAcc, [Validators.pattern(this.urlPattern)]],
            councillorYoutubeAcc: [found.councillorYoutubeAcc, [Validators.pattern(this.urlPattern)]],
            website: [found.website, [Validators.pattern(this.urlPattern)]]
          });

          this.showEmailAdr = found.showEmail;
          this.showMobileNo = found.showMobile;
          this.showOfficeNo = found.showOfficePhone;
          this.allowContact = found.allowContact;
          this.showFacebookAcc = found.showFacebookAcc || this.showFacebookAcc; // if not saved use default
          this.showTwitterAcc = found.showTwitterAcc || this.showTwitterAcc;
          this.showInstagramAcc = found.showInstagramAcc || this.showInstagramAcc;
          this.showLinkedinAcc = found.showLinkedinAcc || this.showLinkedinAcc;
          this.showYoutubeAcc = found.showYoutubeAcc || this.showYoutubeAcc;

          if (found.profile_pic) {
            this.myimgLink = found.profile_pic;
            this.profile_pic = found.profile_pic;
            this.showImageInput = false;
          }

          this.showSearch = false;
          this.showAddButton = false;
          this.showCouncillorForm = true;
          this.profile_pic = found.profile_pic;
          this.appointmentDate = found.appointmentDate;
          this.expiryDate = found.expiryDate;
          this.councillorBio = found.councillorBio;

          const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct',
            'Nov', 'Dec'];

          let dateArray1 = found.expiryDate.split('/');
          let year1 = +dateArray1[2];
          let day1 = +dateArray1[0];

          const appointmentDate = new Date(year1, months.indexOf(dateArray1[1]), day1);
          let model1: IMyDateModel = { isRange: false, singleDate: { jsDate: appointmentDate }, dateRange: null };
          this.addCouncillorForm.patchValue({ appointmentDate: model1 });
          if (!found.displayName) {
            this.addCouncillorForm.patchValue({ displayName: found.firstName + ' ' + found.lastName });
          }
          setTimeout(() => {
           
            let dateArray = found.appointmentDate.split('/');
            let year = +dateArray[2];
            let day = +dateArray[0];

            const expiryDate = new Date(year1, months.indexOf(dateArray1[1]), day1);
            let model1: IMyDateModel = { isRange: false, singleDate: { jsDate: expiryDate }, dateRange: null };
            this.addCouncillorForm.patchValue({ expiryDate: model1 });
            const appointmentDate = new Date(year, months.indexOf(dateArray[1]), day);
            let model: IMyDateModel = { isRange: false, singleDate: { jsDate: appointmentDate }, dateRange: null };
            this.addCouncillorForm.patchValue({ appointmentDate: model });

            M.updateTextFields();
            M.textareaAutoResize($('#chiefBio'));
            $('select').formSelect();
          }, 25);
        }
      }
    });
  }

  getGovernanceFormData(governance_id) {
    this.governanceService.getGovernanceByID(governance_id).valueChanges().subscribe(x => {
      if (x) {
        this.edit_governance = x;
      }
    });
  }

  archiveCouncillor() {
    let governance: any;

    governance = {
      id: this.governance_id,
      name: 'Default Governance',
      chiefs: this.edit_governance.chiefs,
      councillors: this.edit_governance.councillors
    };

    const index = this.edit_governance.councillors.indexOf(item => item.applicantID = this.employeeId)

    var councillorArr = [];
    councillorArr = this.edit_governance.councillors;
    councillorArr.splice(index, 1);
    governance.councillors = councillorArr;

    this.governanceService.UpdateGovernanceCouncillor(governance).then(x => {
      this.fireMember.removeMemberFromGovernance(this.employeeId);
      toast({ html: 'Councillor Successfully Deleted!', classes: 'red' });
      this.router.navigate(['/admin/edit-governance'], { state: { governance_id: this.governance_id } });
    });
  }

  private validateImage(name: any) {
    const ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() === 'png') {
      return true;
    } else if (ext.toLowerCase() === 'jpg') {
      return true;
    } else if (ext.toLowerCase() === 'jpeg') {
      return true;
    } else if (ext.toLowerCase() === 'svg') {
      return true;
    } else if (ext.toLowerCase() === 'webp') {
      return true;
    } else {
      return false;
    }
  }

  mobilePicture() {
    navigator.camera.getPicture(
      (imageData: any) => {
        if (imageData) {
          const imageName = 'name.png';
          const imageBlob = this.dataURItoBlob(imageData);
          const imageFile = new File([imageBlob], imageName, { type: 'image/jpeg' });
          this.binDoc = imageFile;
          this.base64Image = 'data:image/jpeg;base64,' + imageData;
          this.profile_pic = 'data:image/jpeg;base64,' + imageData;
          this.imgErrorMsg = undefined;
        }
      },
      this.onMobileCameraFail,
      {
        quality: 50,
        destinationType: navigator.camera.DestinationType.DATA_URL,
        encodingType: navigator.camera.EncodingType.JPEG,
        mediaType: navigator.camera.MediaType.PICTURE,
        sourceType: navigator.camera.PictureSourceType.CAMERA,
      });
  }

  libraryPicture() {
    navigator.camera.getPicture(
      (imageData: any) => {
        if (imageData) {
          const imageName = 'name.png';
          const imageBlob = this.dataURItoBlob(imageData);
          const imageFile = new File([imageBlob], imageName, { type: 'image/jpeg' });
          this.binDoc = imageFile;
          this.base64Image = 'data:image/jpeg;base64,' + imageData;
          this.profile_pic = 'data:image/jpeg;base64,' + imageData;
          this.imgErrorMsg = undefined;
        }
      },
      this.onMobileCameraFail,
      {
        quality: 50,
        destinationType: navigator.camera.DestinationType.DATA_URL,
        encodingType: navigator.camera.EncodingType.JPEG,
        mediaType: navigator.camera.MediaType.PICTURE,
        sourceType: navigator.camera.PictureSourceType.PHOTOLIBRARY,
      });
  }

  onMobileCameraFail(message) {
    alert(message);
  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/png' });
    return blob;
  }

  public getInitials(firstName: any, lastName: any) {
    if (firstName && lastName) {
      return firstName.substring(0, 1) + lastName.substring(0, 1);
    } else { return ''; }
  }

  navigate(item) {
    const routeMappings = {
      'AboutUs': 'admin/dashboard-sub-menu/about-us',
      'NationInfo': 'admin/dashboard-sub-menu/nation-settings',
      'Departments': 'admin/dashboard-sub-menu/department',
      'Governance': 'admin/dashboard-sub-menu/governance',
      'AreaOfInterest': 'admin/dashboard-sub-menu/areas-of-interest',
    };

    const route = routeMappings[item];
    if (route) {
      this.router.navigate([route]);
    }
  }
  
}
