import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})

export class ApiKeyManagerService {

  private demoKey = '1C3B0AEE-0C78-49FE-9304-DF14C0024B8F';
  private longlakeKey = '4F3942E0-53A1-4DB6-95C2-F5471CAE924E';
  private batcKey = '7E7EAFFC-E227-4F6B-A1BD-66B14D0F320A';
  private denethaKey = '9038C86E-7135-4588-A65E-89448B4BF13A';
  private litleRedRiverCreeNationKey = '59077A74-C2C3-4AF8-8BDA-D9C7E5E35A54';
  private piikaniKey = 'F1BCBF46-1225-4E49-BA69-A047C78B364A';
  private louisBull = '8B1667FD-64FB-44ED-85CB-83C0D119D9EF';
  private oteenow = 'A72FA379-C7DE-4A02-8E8E-AC01EDD838E3';
  private pbcn =  'EA9DD791-524B-4575-81C7-531B3C3462F0';
  private Ahtahkakoop = '331FE416-A7AC-4B95-9B0B-BC90405DEB2F';



  // ----- Firebase Cloud Messaging SERVERS KEYS --------
  // tslint:disable-next-line: max-line-length
  private demoFCMKey = 'key=AAAAMw6ln1s:APA91bGaXTTjPTHdJkxers7bDZYVamMqCMG0D7QQOM7S6NeEnCV-KAZ-n7i-fhXCzDvWdHHfJz-ZtjTUEqg4HpjqYXMItf2yGAMl5QvjS8f2m5NwFwHJaepWcV0uAsIuT-kSxlH2mjoM';
  private pbcnFCMKey = 'key=AAAA7PEhg_A:APA91bGIkYW_7fBshgam4RQJkYg-oZ8U8aQkdr1Z7b1PE_2IcCd0UvXMkzGKG5wZMIhCaHTCVQqdq-PYI6X-SAXOyievWpwxSQl04WbTA3YqOYO26qNCHnNkKNOz2lYAIarAYdaXOfI_';
  private lrrcnFCMKey = 'key=AAAAVgWQ3iw:APA91bE-jROFld65jfLXAETLtnkLeI6QAxe17u7_6WWC7H74z8b0W-y0eyQxuRv4-tE2kQIJA4DJWnkiCjU8hVMxbF1Cps0qO_EnJO6H8YEGzFvbxCqjRu64nmn3v7AfZ8URiO2Sp2pt';

  constructor() { }

  getKey(): string {
    if (environment.firstNation.name === 'Demo') { return this.demoKey; }
    if (environment.firstNation.name === 'LongLake') { return this.longlakeKey; }
    if (environment.firstNation.name === 'Batc') { return this.batcKey; }
    if (environment.firstNation.name === 'YourNation') { return this.demoKey; }
    if (environment.firstNation.name === 'Lrrcn') { return this.litleRedRiverCreeNationKey; }
    if (environment.firstNation.name === 'Piikani') { return this.piikaniKey; }
    if (environment.firstNation.name === 'LouisBull') { return this.louisBull; }
    if (environment.firstNation.name === 'Oteenow') { return this.oteenow; }
    if (environment.firstNation.name === 'Pbcn') { return this.pbcn; }
    if (environment.firstNation.name === 'Ahtahkakoop') { return this.Ahtahkakoop; }
  } 

  getFCMKey(): string {
    if (environment.firstNation.name === 'Demo') { return this.demoFCMKey; }
    if (environment.firstNation.name === 'Pbcn') { return this.pbcnFCMKey; }
    if (environment.firstNation.name === 'Lrrcn') { return this.lrrcnFCMKey; }
  }

  getGoingMobileKey(){
    return 'F1BCBF46-1225-4E49-BA69-A047C78B364A-9038C86E-7135-4588-A65E-89448B4BF13A-1C3B0AEE';
  }

}
