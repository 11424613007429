<div class="container">
<div class="col s12 ">
  <div class="body-h">
    <div class="header-setting">

      <div class="col l12 m12 s12">
        <h4 class="bold-500 icon-text title-nation"> <i class="material-symbols-outlined bold-500 p-r-1 "
            (click)="backClicked()"> arrow_back </i> Department Details</h4>
      </div>

      <div class="row col s12 l10 push-l1 p-t-2">
        <ul class="tabs">
          <li class="tab col s6" [routerLink]="['view-department-info', id]"><a class="active" href="#test1">
              <span *ngIf="nationOfUse === 'Batc'">Office Info</span>
              <span *ngIf="nationOfUse !== 'Batc'">Department Info</span>
            </a></li>
          <li class="tab col s6" [routerLink]="['contact-department-public', id]"><a href="#test2">Contact
              Department</a></li>
        </ul>
      </div>
    </div>

    <div class="row">
      <main class="StickyContent">
        <div class="">
          <router-outlet>
          </router-outlet>
        </div>
      </main>
    </div>
  </div>
</div>

<!-- <div class="row container" style="border:3px solid yellow ;">
  <div class="col s12 m4 l3">
    <ul class="tabs-vertical">
      <li class="tab" [routerLink]="['view-department-info', id]">
        <a class="active">
          <span *ngIf="nationOfUse === 'Batc'">Office Info</span>
          <span *ngIf="nationOfUse !== 'Batc'">Department Info</span>
        </a>
      </li>
      <li class="tab" [routerLink]="['contact-department-public', id]">
        <a>
          Contact Department
        </a>
      </li>
    </ul>
  </div>

  <div class="row col s12 m12 l9">
    <router-outlet></router-outlet>
  </div>
</div> -->

<!-- <div class="row container" style="border:3px solid yellow ;">
  <div class="col s12 m4 l3">
    <ul class="tabs-vertical">
      <li class="tab" [routerLink]="['view-department-info', id]">
        <a class="active">
          <span *ngIf="nationOfUse === 'Batc'">Office Info</span>
          <span *ngIf="nationOfUse !== 'Batc'">Department Info</span>
        </a>
      </li>
      <li class="tab" [routerLink]="['contact-department-public', id]">
        <a>
          Contact Department
        </a>
      </li>
    </ul>
  </div>

  <div class="row col s12 m12 l9">
    <router-outlet></router-outlet>
  </div>
</div> -->


<div class="row" *ngIf="false">
  <div class="col s12 m4 l3">
    <ul class="right-nav col s12 m4 l3 hide-on-med-and-down">

      <h4 class="hide-on-med-and-uphide-on-med-and-up">Department Sub Menu</h4>

      <div class="hide-on-med-and-down">
        <ul style="cursor: pointer;" class='col s12'>
          <ng-template [ngTemplateOutlet]="menus"></ng-template>
        </ul>
      </div>

      <ng-template #menus class="sideNav">
        <li class="active" [routerLink]="['view-department-info', id]" class="active"><a><i
              class="material-symbols-outlined black-text col s3 right-align"
              (click)="select('Office Info', 'grading')">grading</i>
            <div class="col s8 left-align">
              <strong *ngIf="nationOfUse === 'Batc'" class="black-text">Office Info</strong>
              <strong *ngIf="nationOfUse !== 'Batc'" class="black-text">Department Info</strong>
            </div>
          </a>
        </li>

        <li class="active" [routerLink]="['department-news', id]"><a><i
              class="material-symbols-outlined  black-text text-darken-2 col s3 right-align"
              (click)="select('Office News', 'campaign')">campaign</i>
            <div class="col s8 left-align">
              <strong *ngIf="nationOfUse === 'Batc'" class="black-text">Office News</strong>
              <strong *ngIf="nationOfUse !== 'Batc'" class="black-text">Department News</strong>
            </div>
          </a>
        </li>

        <!-- 
          [routerLink]="['./manage-sub-department']" 
        (click)="select('Sub Offices', 'account_tree')"
      -->
        <li class="active" [routerLink]="['manage-sub-department', id]"><a> <i
              class="material-symbols-outlined  black-text col s3 right-align"
              (click)="select('Sub Offices', 'account_tree')">account_tree</i>
            <div class="col s8 left-align">
              <strong *ngIf="nationOfUse === 'Batc'" class="black-text">Sub Offices</strong>
              <strong *ngIf="nationOfUse !== 'Batc'" class="black-text">Sub Departments</strong>
            </div>
          </a>
        </li>

        <li class="active" [routerLink]="['contact-department-public', id]"><a> <i
              class="material-symbols-outlined  black-text text-darken-2 col s3 right-align"
              (click)="select('Contact Department', 'email')">email</i>
            <div class="col s8 left-align">
              <strong *ngIf="nationOfUse === 'Batc'" class="black-text">Contact Department</strong>
              <strong *ngIf="nationOfUse !== 'Batc'" class="black-text">Contact Department List</strong>
            </div>
          </a>
        </li>

        <li class="active" (click)="backClicked()"><a> <i
              class="material-symbols-outlined black-text col s3 right-align">arrow_back_ios_new</i>
            <div class="col s8 left-align">
              <strong *ngIf="nationOfUse === 'Batc'" class="black-text">Back To Offices List</strong>
              <strong *ngIf="nationOfUse !== 'Batc'" class="black-text">Back To Department List</strong>
            </div>
          </a>
        </li>
      </ng-template>
    </ul>
  </div>

  <div class="row col s12 m12 l8">

    <div class="hide-on-large-only drop-down valign-wrapper">
      <a class='col s12 m12 dropdown-trigger btn-flat hide-on-large-only black-text' data-target='menu-dropdown-dep'>
        <i class="material-icons left">{{selectedMenu.icon}}</i>{{selectedMenu.name}}<i
          class="material-icons col s2 right">expand_more</i></a>
      <!-- Dropdown Structure -->
      <ul id='menu-dropdown-dep' class='dropdown-content col s12 m12 '>
        <ng-template [ngTemplateOutlet]="menus"></ng-template>
      </ul>
    </div>
    <br />
    <router-outlet>
    </router-outlet>
  </div>

</div>
</div>