export class AdminPosts{
    id: string;
    createdById: string;
    // audiencesId have a value of either "public" or Id: pointing to audiences document
    audienceId: string;
    audienceList: [string];
    message: string;
    mediaLink: string;
    timeStamp: Date;
    reactions: {
        likes: {
            count: number,
            userId: [],
            usernames: []
        }
    };
    comments: [];
    archived: boolean = false;

}

export class Comment{
    id: string;
    postId: string;
    userId: string;
    userName: string;
    comment: string;
    timestamp: Date;
    likes: {
        count: number,
        userId: [],
        usernames: []
    };
    replies:[];
}
