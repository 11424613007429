import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators, UntypedFormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { EmailModel } from 'src/app/models/emailModel';
import { SearchMembersModel } from 'src/app/models/searchMemberModel';
import { EmailService } from 'src/app/services/api/email.service';
import { ManageAdminService } from 'src/app/services/api/manage-admin.service';
import { MemberService } from 'src/app/services/api/member.service';
import { AdminInvitationService } from 'src/app/services/firebase/admin-invitation.service';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import { FireMembersService } from 'src/app/services/firebase/fire-members.service';
import { SMSService } from 'src/app/services/api/sms.service';
import { SMSModel } from 'src/app/models/SMSModel';
import { createMask } from '@ngneat/input-mask';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-invite-admin',
  templateUrl: './invite-admin.component.html',
  styleUrls: ['./invite-admin.component.css']
})

export class InviteAdminComponent implements OnInit {

 // public dateMask = [/[0-3]/, /[0-9]/, '/', /[0-1]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
 // public isnMask = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  //public SINMask = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
 // public postalCodeMask = [/[A-Za-z]/, /[0-9]/, /[A-Za-z]/, ' ', /[0-9]/, /[A-Za-z]/, /[0-9]/];
  // public phoneNumberMask = ['(', /[0-9]/, /[0-9]/, /[0-9]/, ')',
  //   ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  public healthCareNoMask = [/ \d+ /];
  provinces: any = ['Alberta', 'British Columbia', 'Manitoba', 'New Brunswick',
    'Newfoundland and Labrador', 'Nova Scotia', 'Ontario', 'Prince Edward Island', 'Quebec', 'Saskatchewan'];
  postalCodePattern = /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/;
  public dateformat = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;

  isnMask = createMask({
    mask: '9999999999',
    placeholderChar: '',
    maskChar: null,
  });

  SINMask = createMask({
    mask: '999 999 999',
    placeholderChar: '',
    maskChar: null,
  });

  dateInputMask = createMask<Date>({
    alias: 'datetime',
    guide: true,
    inputFormat: 'dd/mm/yyyy',
    parser: (value: string) => {
      const values = value.split('/');
      const year = +values[2];
      const month = +values[1] - 1;
      const date = +values[0];
      return new Date(year, month, date);
    },
  });

  phoneInputMask = createMask<string>( {
    mask: '(999) 999-9999',
    guide: true,
    showMaskTyped: true,
  });

  postalCodeMask = createMask<string>({
    mask: 'A9A 9A9' // Replace {1} with a space if desired
  });



  nationOfUse = environment.firstNation.name;
  defaultThemeColor = environment.appTheme.themeColor;
  url = environment.firstNation.website;
  themeColor: any;
  buttonType: any;
  loading = false;
  adminMenus: any[];

  p: number = 1;
  searchMember = true;
  newAdmin = false;
  showDetail = false;
  showUpdateForm = false;
  invitationSent = false;
  inviteAdmin = false;

  filterForm: UntypedFormGroup;
  updateForm: UntypedFormGroup;
  newAdminForm: UntypedFormGroup;

  adminList: any[];
  members: any[];
  member: any;
  results: any[];
  adminInfo: any;
  sentInvitation: any;

  viaEmail = false;
  viaText = false;
  secretQuestion = false;
  secretQuestion2 = false;
  question: any;
  answer: any;
  question2: any;
  answer2: any;
  permissions: any[];

  contactDetail: any = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNo: '',
  }
  contact = false;
  message: any = {
    title: '',
    subject: '',
    body: '',
    files: []
  }
  noPermision = false;
  noMethod = false;
  noVerification = false;
  checkPermission = [];
  menuChecked = new Set();
  allChecked = [];
  expand = true;
  invitationId: any;
  emailExists = false;

  constructor(private fb: UntypedFormBuilder,
    private settingsService: AppSettingsService,
    private fbMemberService: FireMembersService,
    private memberService: MemberService,
    private emailService: EmailService,
    private smsService: SMSService,
    private invitationService: AdminInvitationService,
    private adminService: ManageAdminService,
    private router: Router) {

    this.themeColor = this.settingsService.getAppThemeColor() ? this.settingsService.getAppThemeColor() : this.defaultThemeColor;
    this.buttonType = this.settingsService.getButtonType() ? this.settingsService.getButtonType() : 'rectangularButton';
    this.permissions = [];
  }

  ngOnInit(): void {
    this.filterForm = this.fb.group({
      firstName: [''],
      lastName: [''],
      ISN: ['', [Validators.minLength(10), Validators.maxLength(10), Validators.pattern('^[0-9]*$')]],
      clientId: ['']
    });

    this.settingsService.getAdminMenus().valueChanges().subscribe(adminMenus => {
      if (adminMenus && adminMenus.length > 0) {
        this.adminMenus = adminMenus;
        this.adminMenus = this.adminMenus.sort((a, b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0));
        this.adminMenus.forEach(menu => {
          if (menu.submenus && menu.submenus.length > 0) {
            menu.submenus = menu.submenus.sort((a, b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0));
          }
        });
      }

      setTimeout(() => {
        $('.collapsible').collapsible();
        $('.tooltipped').tooltip();
        $('.modal').modal();
      }, 25);
    });

    setTimeout(() => {
      $('.modal').modal();
      $('.collapsible').collapsible();
    });
  }

  getFormControl(name: string, formGroup: UntypedFormGroup) {
    return formGroup.get(name);
  }

  //*************************** Search from member Database ******************** */
  search(filters) {
    const model = new SearchMembersModel();
    if (filters.firstName && filters.firstName !== '') { model.firstName = filters.firstName; }
    if (filters.lastName && filters.lastName !== '') { model.lastName = filters.lastName; }
    if (filters.clientId && filters.clientId !== '') { model.clientId = filters.clientId; }
    if (filters.isn && filters.isn !== '') { model.isn = filters.isn; }
    filters.isMember = true;
    this.memberService.searchMember(model).subscribe(resp => {
      if (resp && resp.length > 0) {
        this.members = resp;
      } else {
        this.members = [];
      }
    });
  }

  clearFilters() {
    this.filterForm.reset();
    this.members = [];
  }

  showMemberDetail(member) {
    this.member = member;

    var dob;
    if (this.member.DOB) {
      var dobArray = this.member.DOB.split("-");
      dob = `${dobArray[2].substring(0, 2)}/${dobArray[1]}/${dobArray[0]}`;
    }

    this.updateForm = this.fb.group({
      firstName: [member.GivenName, [Validators.required]],
      lastName: [member.LastName, [Validators.required]],
      dob: [dob, [Validators.required, this.isValidDate]],
      gender: [member.Sex],
      mailAddress: [member.MailAddress],
      province: [member.province],
      postalCode: [member.PostalCode, [Validators.pattern(this.postalCodePattern)]],
      city: [member.city],
      isn: [member.IndianStatusNo, [Validators.minLength(10), Validators.maxLength(10), Validators.pattern('^[0-9]*$')]],
      sin: [member.SIN, [Validators.minLength(9), Validators.maxLength(9), Validators.pattern('^[0-9]*$')]],
      telephone: [member.telephone],
      cellPhoneNo: [member.cell_phone_num],
      Email: [member.Email, [Validators.email]],
      community: [],
    });

    setTimeout(() => {
      $('select').formSelect();
      $('.modal').modal();
      $('.collapsible').collapsible();
    });

    this.showDetail = true;
  }

  updateMemberDate(values) {
    this.member.GivenName = values.firstName;
    this.member.LastName = values.lastName;
    this.member.DOB = this.changeDates(values.dob) ?  this.changeDates(values.dob): '';
    this.member.Email = values.Email ? values.Email: '';
    this.member.cell_phone_num = values.cellPhoneNo ? values.cellPhoneNo: '';
    this.member.IndianStatusNo = values.isn ? values.isn : '';
    this.member.MailAddress = values.mailAddress ? values.mailAddress: '';

    this.member.PostalCode = values.postalCode ? values.postalCode: '';
    this.member.SIN = values.sin ? values.sin: '';
    this.member.Telephone = values.telephone ? values.telephone : '';
    this.member.city = values.city ?  values.city :'';
    this.member.province = values.province ? values.province: '';

    this.member.Sex = values.gender;
    this.member.middle_name = '';
    this.member.Community = 'Fox Lake';
    this.member.HealthCareNo = '';

    this.memberService.updateMemberData(this.member).subscribe(x => {
      if (x) {
        M.toast({ html: 'Client Data sucessfully updated!', classes: 'green' });
      }
    });
  }

  isValidDate(control: UntypedFormControl) {
    let date = control.value;
    const dateformat = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
    if (date) {
      if (!date.match(dateformat) && date.length > 10) {
        const datePipe = new DatePipe('en-US');
        date = datePipe.transform(date, 'dd/MM/yyyy');
      }
      if (date.match(dateformat)) {
        let dateArray = date.split('/');
        let year = dateArray[2];
        let month = dateArray[1] - 1;
        let day = dateArray[0];
        date = new Date(year, month, day);
        const today = new Date();
        if (date.valueOf() < today.valueOf()) {
          return null;
        } else {
          return { 'invalidDate': true }
        }
      }
    }
    return null;
  }

  changeDates(dateValue) {
    if (dateValue) {
      if (dateValue.match(this.dateformat)) {
        let dateArray = dateValue.split('/');
        let year = dateArray[2];
        let month = dateArray[1] - 1;
        let day = dateArray[0];
        const date = new Date(year, month, day);
        return date;
      } else {
        const date = new Date(dateValue);
        return date;
      }
    } else {
      return null
    }
  }

  get firstName() { return this.updateForm.get('firstName'); }
  get lastName() { return this.updateForm.get('lastName'); }
  get isn() { return this.updateForm.get('isn'); }
  get dob() { return this.updateForm.get('dob'); }
  get sin() { return this.updateForm.get('sin'); }
  get email() { return this.updateForm.get('Email'); }
  get postalCode() { return this.updateForm.get('postalCode'); }


  //*********************************Add a new Admin ******************** */
  onAddAdmin() {
    this.newAdmin = true;
    this.searchMember = false;
    this.newAdminForm = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      telephone: [''],
      mobile: ['']
    });
  }

  //********************************************** Common ********************************************* */
  invite(values?) {
    if (this.searchMember && this.member) {
      this.adminInfo = {
        applicantID: this.member.applicantID,
        firstName: this.member.GivenName,
        lastName: this.member.LastName,
        email: this.member.Email,
        mobile: this.member.cell_phone_num,
        newAdmin: false,
      }
    } else if (this.newAdmin && values) {
      this.adminInfo = {
        applicantID: null,
        firstName: values.firstName,
        lastName: values.lastName,
        email: values.email,
        mobile: values.mobile,
        newAdmin: true,
      }
    }

    this.emailExists = false;
    this.fbMemberService.getMemberByEmail(this.adminInfo.email).valueChanges().subscribe(account => {
      if (account && account.length > 0) this.emailExists = true;
      else {
        this.invitationService.getInvitationByEmail(this.adminInfo.email).valueChanges().subscribe(invitation => {
          if (invitation && invitation.length > 0) {
            this.invitationSent = true;
            this.sentInvitation = invitation[0];
          }
        });
      }
    });

    this.inviteAdmin = true;
    
    setTimeout(() => {
      $('select').formSelect();
      $('.modal').modal();
      $('.collapsible').collapsible();
    })
  }

  onContact() {
    this.contactDetail.firstName = this.adminInfo.firstName;
    this.contactDetail.lastName = this.adminInfo.lastName;
    this.contactDetail.email = this.adminInfo.email;
    this.contactDetail.phoneNo = this.adminInfo.mobile;
    this.contact = true;

    if (this.adminInfo.email) {
    } else {
      M.toast({ html: 'Email Not Provided', classes: 'red' });
    }
  }

  contactHandler(valueEmitted) {
    if (valueEmitted.status) {
      M.toast({ html: 'Email sent Successfully.', classes: 'green' });
      this.contact = false;
    }
  }

  sendViaEmailYes() {
    this.viaEmail = true;
  }

  sendViaEmailNo() {
    this.viaEmail = false;
  }

  sendViaTextYes() {
    this.viaText = true;
  }

  sendViaTextNo() {
    this.viaText = false;
  }

  toggleExpand() {
    this.expand = !this.expand;
  }

  //Grant permission
  selectAll(item) {
    if (this.allChecked.includes(item)) {
      this.removeMenuItem(item);
      let i = this.allChecked.indexOf(item);
      this.allChecked.splice(i, 1);
    } else {
      let menu = this.adminMenus.find(menu => menu.name == item);
      if (menu.submenus && menu.submenus.length > 0) {
        menu.submenus.forEach(submenu => {
          this.addSubmenuItem(item, submenu.name);
        });
      } else {
        this.addMenuItem(item);
      }
      this.allChecked.push(item);
    }
  }

  menuClicked(item, sub_item) {
    if (sub_item) {
      if (this.menuChecked.has(sub_item.name)) {
        this.removeSubmenuItem(item.name, sub_item.name);
      } else {
        this.addSubmenuItem(item.name, sub_item.name);
      }
    } else {
      if (this.menuChecked.has(item.name)) {
        this.removeMenuItem(item.name);
      } else {
        this.addMenuItem(item.name);
      }
    }
  }

  addMenuItem(item, sub_item?) {
    let submenu = sub_item ? [sub_item] : [];
    this.permissions.push({ menu: item, submenus: submenu });
    this.menuChecked.add(item);
    if (sub_item) this.menuChecked.add(sub_item);
  }

  removeMenuItem(item) {
    let menu = this.permissions.find(p => p.menu == item);
    if (menu.submenus && menu.submenus.length > 0) {
      menu.submenus.forEach(submenu => {
        this.menuChecked.delete(submenu);
      });
    }
    let index = this.permissions.indexOf(menu);
    this.permissions.splice(index, 1);
    this.menuChecked.delete(item);
  }

  addSubmenuItem(item, sub_item) {
    let menu = this.permissions.find(p => p.menu == item);
    if (menu) {
      menu.submenus.push(sub_item);
      this.menuChecked.add(sub_item);
    }
    else this.addMenuItem(item, sub_item);
  }

  removeSubmenuItem(item, sub_item) {
    let menu = this.permissions.find(p => p.menu == item);
    const index = menu.submenus.indexOf(sub_item);
    menu.submenus.splice(index, 1);
    this.menuChecked.delete(sub_item);

    if (menu.submenus.length == 0) {
      this.removeMenuItem(item);
    }
  }

  addQuestion() {
    if (this.secretQuestion) this.secretQuestion2 = true;
    else this.secretQuestion = true;
  }

  deleteQuestion(no) {
    if (no == 1) {
      if (this.secretQuestion2) {
        this.question = this.question2;
        this.answer = this.answer2;
        this.deleteQuestion(2);
      } else {
        this.question = '';
        this.answer = '';
        this.secretQuestion = false;
      }
    }
    else if (no == 2) {
      this.question2 = '';
      this.answer2 = '';
      this.secretQuestion2 = false;
    }
  }

  validateInvitation() {
    if (this.permissions.length == 0) this.noPermision = true;
    if (!this.viaText && !this.viaEmail) this.noMethod = true;
    if (!(this.secretQuestion || this.secretQuestion2)) this.noVerification = true;
    if (this.noPermision || this.noMethod || this.noVerification) $('#invitationModal').modal('open');
    else this.saveInvitation();
  }

  saveInvitation() {
    this.url = window.location.hostname !== 'localhost' ? this.url : 'http://localhost:4200/#';

    let secretQuestions = [];
    if (this.secretQuestion) {
      let item = {
        question: this.question,
        answer: this.answer
      }
      secretQuestions.push(item);
    }
    if (this.secretQuestion2) {
      let item = {
        question: this.question2,
        answer: this.answer2
      }
      secretQuestions.push(item);
    }

    let invitationModel: any = {
      applicantId: this.adminInfo.applicantID,
      firstName: this.adminInfo.firstName,
      lastName: this.adminInfo.lastName,
      email: this.adminInfo.email,
      mobile: this.adminInfo.mobile,
      newAdmin: this.adminInfo.newAdmin,
      invitedByEmail: this.viaEmail,
      invitedByText: this.viaText,
      invitationDate: new Date(),
      secretQuestion: secretQuestions.length > 0 ? true : false,
      questions: secretQuestions,
      permissions: this.permissions,
      status: 'Invited',
    }

    //Update Old Invitation
    if (this.invitationSent && this.sentInvitation) {
      this.invitationId = this.sentInvitation.id;
      invitationModel.id = this.invitationId;
      this.invitationService.updateInvitation(invitationModel).then(updated => {
        this.SendInvitationLink();
      });
    }

    //New Invitaion
    else {
      this.invitationService.newInvitation(invitationModel).then(saved => {
        this.invitationId = saved.id;
        this.invitationService.updateInvitationId(this.invitationId);
        this.SendInvitationLink();
      });
    }
  }

  SendInvitationLink() {
    let URL = this.url + '/auth/register-admin/' + this.invitationId;
    let email = new EmailModel();
    let msg = new SMSModel();
    if (this.viaEmail) {
      email.emailList = [];
      email.emailList.push(this.adminInfo.email);
      email.subject = 'Invitation to ' + this.nationOfUse;
      email.body = '<h5>Hello ' + this.adminInfo.firstName + ' ,</h5> \n admin invited you to create an account on My Nation Please follow the link below and don’t share this email with anyone. If you have any questions about this please email info@mynation.app or call Nordeen at 780 841 1880\n';
      email.body += ' <a href="' + URL + '" target="_blank" class="center"> ' + URL + '</a>';
    }

    if (this.viaText) {
      msg.phoneNumberList = [];
      msg.phoneNumberList.push(this.adminInfo.mobile);
      msg.body = 'Invitation to ' + this.nationOfUse + '.\n' + 'Hello ' + this.adminInfo.firstName + ', admin invited you to create an account on My Nation. Please follow the link below and don’t share this email with anyone. If you have any questions about this please email info@mynation.app or call Nordeen at 780 841 1880' + '\n' + URL;
    }

    if (this.viaEmail && this.viaText) {
      this.emailService.sendEmail(email).subscribe(emailSent => {
        this.smsService.sendSMS(msg).subscribe(smsSent => {
          M.toast({ html: 'Invitation Successfully Sent.', classes: 'green' });
          this.backToAdminList();
        });
      })
    } else if (this.viaEmail) {
      this.emailService.sendEmail(email).subscribe(x => {
        if (x && x.Status == 'OK') {
          M.toast({ html: 'Invitation Successfully Sent.', classes: 'green' });
          this.backToAdminList();
        } else {
          M.toast({ html: 'Failed to send Invitation Email. Please Try again.', classes: 'red' });
          this.backToAdminList();
        }
      });
    } else if (this.viaText) {
      this.smsService.sendSMS(msg).subscribe(smsSent => {
        M.toast({ html: 'Invitation Successfully Sent.', classes: 'green' });
        this.backToAdminList();
      });
    }
  }

  backToDetail() {
    this.inviteAdmin = false;

    $('select').formSelect();
    $(document).ready(function () {
      $('select').formSelect();
    });
  }

  back() {
    this.invitationSent = false;
    this.emailExists = false;
    if (this.newAdmin) {
      this.newAdmin = false;
      this.searchMember = true;
    } else {
      this.invitationSent = false;
      this.showDetail = false;
    }
  }

  backToAdminList() {
    this.router.navigate(['/admin/dashboard-sub-menu/admins-list'])
  }

}
