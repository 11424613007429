<div class="row p-t-5 ">
  <div class="card row" style="padding: 0vh 3vh;">
    <div class="col l3 s6 memb-img">
      <img *ngIf="model.profile_pic" [src]="model.profile_pic" alt="" class="center circle responsive-img"
        style=" border-radius: 50%;  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;">

      <img *ngIf="!model.profile_pic" class="responsive-img"
        style=" border-radius: 50%; box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;"
        src="../../../../assets/img/avatar.jpeg">
    </div>

    <div class="col l3 s6 memb-name">
      <h5 class=" title collection ">{{model.firstName}} {{model.lastName}}</h5>
      <h6 class="sub-title-section2 collection bold-600 ">{{councillorTitle}}</h6>
    </div>

    <div class="col l6 s12 coll-memb ">
      <ul class="collection ">
        <li class="collection-item" *ngIf="model.showEmail === privacy">
          <strong>
            <div class="detail-col"><span class="bold-text"> Email : </span><a class="secondary-content"
                [href]="'mailto:' + model.email">
                {{model.email}}
              </a></div>
          </strong>
        </li>
        <li class="collection-item" *ngIf="model.showOfficePhone === privacy">
          <strong>
            <div class="detail-col"><span class="bold-text">Office Phone : </span><a
                [href]="'tel:' + model.office_phone" class="secondary-content">
                {{model.office_phone}}
              </a></div>
          </strong>
        </li>
        <li class="collection-item" *ngIf="model.showMobile === privacy">
          <strong>
            <div class="detail-col"><span class="bold-text">Mobile Phone : </span><a [href]="'tel:' + model.cell_phone"
                class="secondary-content">
                {{model.cell_phone}}
              </a></div>
          </strong>
        </li>
        <li class="collection-item">
          <strong>
            <div class="detail-col"><span class="bold-text">Community : </span><a class="secondary-content black-text">
                {{model.community}}
              </a></div>
          </strong>
        </li>
      </ul>

      <div class="section center soc-med">
        <a href="{{model.councillorFacebookAcc}}" target="_blank" class="center col s2"
          *ngIf="(model.showFacebookAcc === 'Public') && model.councillorFacebookAcc"><span style="cursor: pointer;"> <i
              class="fa fa-facebook prefix blue-text" style=" font-size:larger;"></i></span> </a>
        <a href="{{model.councillorInstagramAcc}}" target="_blank" class="center col s2"
          *ngIf="(model.showInstagramAcc === 'Public' ) && model.councillorInstagramAcc"><span style="cursor: pointer;">
            <i class="fa fa-instagram prefix pink-text " style=" font-size:larger;"></i> </span></a>
        <a href="{{model.councillorTwitterAcc}}" target="_blank" class="center col s2"
          *ngIf="(model.showTwitterAcc === 'Public' ) && model.councillorTwitterAcc"><span style="cursor: pointer;"> <i
              class="black-text bold-800" style="font-size:larger;">X</i></span> </a>
        <a href="{{model.councillorLinkedinAcc}}" target="_blank" class="center col s2"
          *ngIf="(model.showLinkedinAcc === 'Public') && model.councillorLinkedinAcc"><span style="cursor: pointer;"> <i
              class="fa fa-linkedin prefix blue-text" style=" font-size:larger;"></i></span> </a>
        <a href="{{model.councillorYoutubeAcc}}" target="_blank" class="center col s2"
          *ngIf="(model.showYoutubeAcc === 'Public') && model.councillorYoutubeAcc"><span style="cursor: pointer;"> <i
              class="fa fa-youtube prefix red-text" style="font-size:larger;"></i></span> </a>
      </div>
    </div>
  </div>

  <div class="p-t-1 row bottom-card">
    <div class="collection col s12 l3  card">
      <ul class="row collection">
        <li class="collection-item">
          <strong>
            <div><span class="sub-title-section2 bold-700">Portfolio</span></div>
          </strong>
        </li>
        <ol>
          <li class="collection-item" *ngFor="let item2 of model.portfolio">
            <a class="secondary-content black-text " [innerHTML]="item2.name"></a>
          </li>
        </ol>
        <hr>
        <li class="collection-item">
          <strong>
            <div> <span class="sub-title-section2 bold-700">Website</span></div>
          </strong>
        </li>
        <li class="collection-item">
          <a class="blue-text" href="{{model.website}}" target="_blank">
            {{model.website}}
          </a>
        </li>
      </ul>
    </div>

    <div class="row col l8 s12 push-l1 card" *ngIf="model.portfolio && model.portfolio.length > 0">
      <h6 style="font-weight: bolder;  font-family: 'Roboto', sans-serif; margin-top: 5%; padding-left: 10px;">
        {{councillorTitle}} Bio
      </h6>
      <div class="row col s12 l12 bold-100 " *ngIf="model.chiefBio">
        <p>{{model.chiefBio}}</p>
      </div>
    </div>
  </div>
</div>