import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { DepartmentService } from 'src/app/services/firebase/department.service';
import { toast } from 'materialize-css';
import { ActivatedRoute } from '@angular/router';
import { MemberService } from 'src/app/services/api/member.service';

declare var M: any;

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.css']
})

export class MyAccountComponent implements OnInit {

  employeeID: any;
  routID: any;
  employeeForm: UntypedFormGroup;
  isManager = false;
  model: any;
  member: any;
  canManageAccount = false;
  canManageContact = false;
  accountOwner = true;
  profile=true;
  updateProfile=false;

  showMobileNo = 'Public';
  showOfficeNo = 'Public';
  showEmailAdr = 'Public';
  allowContact = 'Public';
  manageAccount = false;
  manageContact = false;
  manageDepartmentInfo = false;
  manageDepartmentNews = false;
  manageDepartmentEmployees = false;
  manageSubDepartments = false;
  imgLink = 'https://www.w3schools.com/howto/img_avatar.png';

  public phoneNumberMask = ['(', /[0-9]/, /[0-9]/, /[0-9]/, ')',
    ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];

  constructor(
    private authService: AuthService,
    private formBuilder: UntypedFormBuilder,
    private departmentService: DepartmentService,
    private route: ActivatedRoute,
    private memberService: MemberService
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.routID = params.get('id');
    });

    if (this.routID) {
      this.employeeID = this.routID;
      this.accountOwner = false;
    } else {
      this.employeeID = this.authService.getGlobalUser() ? this.authService.getGlobalUser().uid : this.authService.getCurrentUser().uid;
      this.departmentService.getDepartmentEmployeeByID(this.employeeID).valueChanges().subscribe(
        emp => {
          if (emp) {
            this.model = emp;
            this.member = emp;
            this.isManager = emp.isManager;
            this.canManageAccount = emp.manageAccount;
            this.canManageContact = emp.manageContact;
  
            // Emp Info
            this.employeeForm = this.formBuilder.group({
              jobTitle: [emp.jobTitle? emp.jobTitle: '', [Validators.required]],
              description: [emp.description? emp.description :'', [Validators.required]],
              workPhone: [emp.workPhone ? emp.description :'', [Validators.required]],
              mobilePhone: [emp.mobilePhone ?emp.mobilePhone:'', [Validators.required]],
            });
            // Contact
            this.showMobileNo = emp.showMobileNo;
            this.showOfficeNo = emp.showOfficeNo;
            this.showEmailAdr = emp.showEmailAdr;
            this.allowContact = emp.allowContact;
            // Permissions
            this.manageAccount = emp.manageAccount;
            this.manageContact = emp.manageContact;
            this.manageDepartmentInfo = emp.manageDepartmentInfo;
            this.manageDepartmentNews = emp.manageDepartmentNews;
            this.manageDepartmentEmployees = emp.manageDepartmentEmployees;
            this.manageSubDepartments = emp.manageSubDepartments;

            setTimeout(() => {
              M.updateTextFields();
              M.textareaAutoResize($('#description'));
            }, 25);

            this.memberService.getMember(this.model.applicantID).subscribe(x => {
              if(x) {
                this.member = x[0];
              }
            });
          }
        });
    }
  }

  public getInitials(firstName: any, lastName: any) {
    if (firstName && lastName) {
      return firstName.substring(0, 1) + lastName.substring(0, 1);
    } else { return ''; }
  }

  get jobTitle() { return this.employeeForm.get('jobTitle'); }
  get workPhone() { return this.employeeForm.get('workPhone'); }
  get mobilePhone() { return this.employeeForm.get('mobilePhone'); }
  get description() { return this.employeeForm.get('description'); }

  onUpdateProfile(){
    this.profile = false;
    this.updateProfile = true;
  }

  updateEmployee(value: any) {
    this.model.jobTitle = (this.model.manageAccount) ? value.jobTitle : this.model.jobTitle;
    this.model.workPhone = (this.model.manageAccount) ? value.workPhone : this.model.workPhone;
    this.model.mobilePhone = (this.model.manageAccount) ? value.mobilePhone : this.model.mobilePhone;
    this.model.description = (this.model.manageAccount) ? value.description : this.model.description;
    this.model.showMobileNo = this.showMobileNo;
    this.model.showOfficeNo = this.showOfficeNo;
    this.model.showEmailAdr = this.showEmailAdr;
    this.model.allowContact = this.allowContact;
    this.model.manageAccount = this.manageAccount;
    this.model.manageContact = this.manageContact;
    this.model.manageDepartmentInfo = this.manageDepartmentInfo;
    this.model.manageDepartmentNews = this.manageDepartmentNews;
    this.model.manageDepartmentEmployees = this.manageDepartmentEmployees;
    this.model.manageSubDepartments = this.manageSubDepartments;

    this.departmentService.updateEmployee(this.model).then(resp => {
      toast({ html: 'Account Successfully Updated', classes: 'green' });
    });
  }

  manageAccountClicked() {
    this.manageAccount = !this.manageAccount;
  }

  manageContactClicked() {
    this.manageContact = !this.manageContact;
  }

  manageDepartmentInfoClicked() {
    this.manageDepartmentInfo = !this.manageDepartmentInfo;
  }

  manageDepartmentNewsClicked() {
    this.manageDepartmentNews = !this.manageDepartmentNews;
  }

  manageDepartmentEmployeesClicked() {
    this.manageDepartmentEmployees = !this.manageDepartmentEmployees;
  }

  manageSubDepartmentsClicked() {
    this.manageSubDepartments = !this.manageSubDepartments;
  }

  privateMobileClick() {
    this.showMobileNo = 'Private';
  }

  publicMobileClick() {
    this.showMobileNo = 'Public';
  }

  membersMobileClick() {
    this.showMobileNo = 'Members';
  }

  privateOfficeClick() {
    this.showOfficeNo = 'Private';
  }

  publicOfficeClick() {
    this.showOfficeNo = 'Public';
  }

  membersOfficeClick() {
    this.showOfficeNo = 'Members';
  }

  privateEmailClick() {
    this.showEmailAdr = 'Private';
  }

  publicEmailClick() {
    this.showEmailAdr = 'Public';
  }

  membersEmailClick() {
    this.showEmailAdr = 'Members';
  }

  privateContactClick() {
    this.allowContact = 'Private';
  }

  publicContactClick() {
    this.allowContact = 'Public';
  }

  membersContactClick() {
    this.allowContact = 'Members';
  }

}