
export class IDModel {
    clientID: number; 
    documentName: string;
    base64Document = '';
    document_type = 0;  
  }
  
/* required by Craig
TO keep things consistent , field multi_docs_temp.document_type is current 1,2 or 3

1= Support request
2= ID document
3= Not used yet but will be a request for further documentation and also tie into scanner IE worker message client "Could you send me a copy of your certificate "

*/