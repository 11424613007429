<div *ngIf="model">

  <div class="row p-b-6">

    <!-- ******************** Slider ************************** -->
    <div class="row" *ngIf="sliderSection?.isEnabled && !onlyCards" [ngClass]="{'me': !preview}">
      <app-landing-page-slider *ngIf="preview" [fullscreenEnabled]="false"></app-landing-page-slider>
      <app-landing-page-slider *ngIf="!preview" [fullscreenEnabled]="true"></app-landing-page-slider>
    </div>

    <!-- <div
      [ngClass]="{'col l10 s12 m10 push-l1 push-m1': !onlyCards, 'container_opt': !onlyCards,  'container_preview': onlyCards}"> -->
    <div [ngClass]="{'container': !onlyCards, 'container_opt': !onlyCards,  'container_preview': onlyCards}">
      <div *ngFor="let section of model.sections">
        <div *ngIf="section.isEnabled">
          <div [ngSwitch]="section.name">

            <!-- ******************** Login and Register buttons ************************** -->
            <div *ngSwitchCase="'Login'">
              <!-- Card container with dynamic grid sizes -->
              <div class="card-padding" [ngClass]="['col', model.desktopGridSize, model.mobileGridSize]">
                <a class="card-option" [ngClass]="{
                   'rectangularCardButton': model.cardShape === 'Rectangle' && imageOption === 'no',
                   'roundCardButton': model.cardShape === 'Round',
                   'curvedCardButton': model.cardShape === 'Curved',
                   'hexaCardButton': model.cardShape === 'Hexagon',
                   'noCardButton': model.cardShape === 'None',
                   'no-fill-image': model.imageShape === 'yes'
                 }" (click)="login()" [ngStyle]="{
                   'background-color': section.bgColor,
                   'background-image': model.imageShape === 'yes' ? 'url(' + section.logoUrl + ')' : 'none',
                   'background-repeat': 'no-repeat', 
                   'background-size': model.imageCover === 'Contain' ? 'contain' : 'cover',
                   'position': model.imageShape === 'yes' ? 'relative' : 'static',
                   'background-position':model.imageShape=='yes' ? 'center center': 'auto'
                 }">

                  <!-- Overlay (displayed when imageShape is 'yes') -->
                  <div class="overlay" *ngIf="model.imageShape === 'yes' && model.cardShape !== 'None'"></div>
                  <div class="card-content">
                    <div class="vertical-center">
                      <!-- Display Name Above Image/Icon if 'Top' position is chosen -->
                      <ng-container *ngIf="model.titlePosition === 'Top'">
                        <!-- Displayed display name -->
                        <h3 [ngStyle]="{'color': section.displayNameColor}" [ngClass]="{
                          'small-t': model.cardSize === 'Small',
                          'medium-t': model.cardSize === 'Medium',
                          'large-t': model.cardSize === 'Large'
                        }">
                          {{ section.displayName }}
                        </h3>
                      </ng-container>
                      <!-- Icon or Image -->

                      <!-- Icon displayed when logoUrl is not available -->
                      <i *ngIf="!section.logoUrl" class="material-symbols-outlined"
                        [ngStyle]="{'border-color': section.displayNameColor, 'color': section.displayNameColor}"
                        [ngClass]="{
                        'rectangularCardIcon': model.cardIconShape === 'Rectangle',
                        'roundCardIcon': model.cardIconShape === 'Round',
                        'curvedCardIcon': model.cardIconShape === 'Curved',
                        'hexaCardIcon': model.cardIconShape === 'Hexagon',
                        'noCardIcon': model.cardIconShape === 'None'
                      }">
                        {{ section.icon }}
                      </i>

                      <!-- Image displayed when logoUrl is available and imageShape is 'no' -->
                      <img *ngIf="section.logoUrl && model.imageShape === 'no'" class="responsive-img center" alt="Slider image"
                        [src]="section.logoUrl" alt="rectangle image" [ngClass]="{
                        'rectangularCardIcon': model.cardIconShape === 'Rectangle',
                        'roundCardIcon': model.cardIconShape === 'Round',
                        'curvedCardIcon': model.cardIconShape === 'Curved',
                        'hexaCardIcon': model.cardIconShape === 'Hexagon',
                        'noCardIcon': model.cardIconShape === 'None'
                      }">

                      <!-- Display Name Below Image/Icon if 'Bottom' position is chosen -->
                      <ng-container *ngIf="model.titlePosition === 'Bottom'">
                        <!-- Displayed display name -->
                        <h3 [ngStyle]="{'color': section.displayNameColor}" [ngClass]="{
                      'small-t': model.cardSize === 'Small',
                      'medium-t': model.cardSize === 'Medium',
                      'large-t': model.cardSize === 'Large'}">
                          {{ section.displayName }}
                        </h3>
                      </ng-container>
                    </div>
                  </div>
                </a>
              </div>
            </div>

            <!-- ******************** ISET Registration  ************************** -->
            <div *ngSwitchCase="'ISETReg'">
              <div class=" card-padding" [ngClass]="['col', model.desktopGridSize , model.mobileGridSize ]">
                <a class="card-option" [ngClass]="{
                  'rectangularCardButton': model.cardShape === 'Rectangle' && imageOption === 'no',
                  'roundCardButton': model.cardShape === 'Round',
                  'curvedCardButton': model.cardShape === 'Curved',
                  'hexaCardButton': model.cardShape === 'Hexagon',
                  'noCardButton': model.cardShape === 'None',
                  'no-fill-image': model.imageShape === 'yes'
                }" (click)="ISETRegistration()" [ngStyle]="{
                  'background-color': section.bgColor,
                  'background-image': model.imageShape === 'yes' ? 'url(' + section.logoUrl + ')' : 'none',
                  'background-repeat': 'no-repeat', 
                  'background-size': model.imageCover === 'Contain' ? 'contain' : 'cover',
                  'position': model.imageShape === 'yes' ? 'relative' : 'static',
                  'background-position':model.imageShape=='yes' ? 'center center': 'auto'
                }">

                  <!-- Overlay (displayed when imageShape is 'yes') -->
                  <div class="overlay" *ngIf="model.imageShape === 'yes' && model.cardShape !== 'None'"></div>
                  <div class="card-content">
                    <div class="vertical-center">
                      <!-- Display Name Above Image/Icon if 'Top' position is chosen -->
                      <ng-container *ngIf="model.titlePosition === 'Top'">
                        <!-- Displayed display name -->
                        <h3 [ngStyle]="{'color': section.displayNameColor}" [ngClass]="{
                                    'small-t': model.cardSize === 'Small',
                                    'medium-t': model.cardSize === 'Medium',
                                    'large-t': model.cardSize === 'Large'
                                  }">
                          {{ section.displayName }}
                        </h3>
                      </ng-container>

                      <i *ngIf="!section.logoUrl" class="material-symbols-outlined"
                        [ngStyle]="{'border-color':section.displayNameColor , 'color':section.displayNameColor}"
                        [ngClass]="{
                          'rectangularCardIcon': model.cardIconShape === 'Rectangle',
                          'roundCardIcon': model.cardIconShape === 'Round',
                          'curvedCardIcon': model.cardIconShape === 'Curved',
                          'hexaCardIcon': model.cardIconShape === 'Hexagon',
                          'noCardIcon': model.cardIconShape === 'None'
                        }">
                        {{ section.icon }}</i>

                      <!-- Image displayed when logoUrl is available and imageShape is 'no' -->
                      <img *ngIf="section.logoUrl && model.imageShape === 'no'" class="responsive-img center" alt="ISET image"
                        [src]="section.logoUrl" alt="rectangle image" [ngClass]="{
                           'rectangularCardIcon': model.cardIconShape === 'Rectangle',
                           'roundCardIcon': model.cardIconShape === 'Round',
                           'curvedCardIcon': model.cardIconShape === 'Curved',
                           'hexaCardIcon': model.cardIconShape === 'Hexagon',
                           'noCardIcon': model.cardIconShape === 'None'
                         }">
                      <!-- Display Name Above Image/Icon if 'Top' position is chosen -->
                      <ng-container *ngIf="model.titlePosition === 'Bottom'">
                        <!-- Displayed display name -->
                        <h3 [ngStyle]="{'color': section.displayNameColor}" [ngClass]="{
              'small-t': model.cardSize === 'Small',
              'medium-t': model.cardSize === 'Medium',
              'large-t': model.cardSize === 'Large'
            }">
                          {{ section.displayName }}
                        </h3>
                      </ng-container>
                    </div>
                  </div>
                </a>
              </div>
            </div>

            <!-- ******************** News ************************** -->
            <div *ngSwitchCase="'News'">
              <!-- Card container with dynamic grid sizes -->
              <div class="card-padding" [ngClass]="['col', model.desktopGridSize, model.mobileGridSize]">
                <!-- Card button -->
                <a class="card-option" [ngClass]="{
                     'rectangularCardButton': model.cardShape === 'Rectangle' && imageOption === 'no',
                     'roundCardButton': model.cardShape === 'Round',
                     'curvedCardButton': model.cardShape === 'Curved',
                     'hexaCardButton': model.cardShape === 'Hexagon',
                     'noCardButton': model.cardShape === 'None',
                     'no-fill-image': model.imageShape === 'yes'
                   }" (click)="news()" [ngStyle]="{
                     'background-color': section.bgColor,
                     'background-image': model.imageShape === 'yes' ? 'url(' + section.logoUrl + ')' : 'none',
                     'background-size': model.imageCover === 'Contain' ? 'contain' : 'cover',
                     'background-repeat': 'no-repeat', 
                     'position': model.imageShape === 'yes' ? 'relative' : 'static',
                     'background-position':model.imageShape=='yes' ? 'center center': 'auto'
                   }">

                  <!-- Overlay (displayed when imageShape is 'yes') -->
                  <div class="overlay" *ngIf="model.imageShape === 'yes' && model.cardShape !== 'None'"></div>

                  <!-- Card content -->
                  <div class="card-content">
                    <div class="vertical-center">
                      <!-- Display Name Above Image/Icon if 'Top' position is chosen -->
                      <ng-container *ngIf="model.titlePosition === 'Top'">
                        <!-- Displayed display name -->
                        <h3 [ngStyle]="{'color': section.displayNameColor}" [ngClass]="{
            'small-t': model.cardSize === 'Small',
            'medium-t': model.cardSize === 'Medium',
            'large-t': model.cardSize === 'Large'
          }">
                          {{ section.displayName }}
                        </h3>
                      </ng-container>

                      <!-- Icon displayed when logoUrl is not available -->
                      <i *ngIf="!section.logoUrl" class="material-symbols-outlined"
                        [ngStyle]="{'border-color': section.displayNameColor, 'color': section.displayNameColor}"
                        [ngClass]="{
                           'rectangularCardIcon': model.cardIconShape === 'Rectangle',
                           'roundCardIcon': model.cardIconShape === 'Round',
                           'curvedCardIcon': model.cardIconShape === 'Curved',
                           'hexaCardIcon': model.cardIconShape === 'Hexagon',
                           'noCardIcon': model.cardIconShape === 'None'
                         }">
                        {{ section.icon }}
                      </i>

                      <!-- Image displayed when logoUrl is available and imageShape is 'no' -->
                      <img *ngIf="section.logoUrl && model.imageShape === 'no'" class="responsive-img center" alt="News image"
                        [src]="section.logoUrl" [ngClass]="{
                             'rectangularCardIcon': model.cardIconShape === 'Rectangle',
                             'roundCardIcon': model.cardIconShape === 'Round',
                             'curvedCardIcon': model.cardIconShape === 'Curved',
                             'hexaCardIcon': model.cardIconShape === 'Hexagon',
                             'noCardIcon': model.cardIconShape === 'None'
                           }">

                      <!-- Display Name Below Image/Icon if 'Bottom' position is chosen -->
                      <ng-container *ngIf="model.titlePosition === 'Bottom'">
                        <!-- Displayed display name -->
                        <h3 [ngStyle]="{'color': section.displayNameColor}" [ngClass]="{
                  'small-t': model.cardSize === 'Small',
                  'medium-t': model.cardSize === 'Medium',
                  'large-t': model.cardSize === 'Large'
                }">
                          {{ section.displayName }}
                        </h3>
                      </ng-container>
                    </div>
                  </div>
                </a>
              </div>
            </div>

            <!-- ******************** Events ************************** -->
            <div *ngSwitchCase="'Events'">
              <!-- Card container with dynamic grid sizes -->
              <div class="card-padding" [ngClass]="['col', model.desktopGridSize, model.mobileGridSize]">
                <!-- Card button -->
                <a class="card-option" [ngClass]="{
                     'rectangularCardButton': model.cardShape === 'Rectangle' && imageOption === 'no',
                     'roundCardButton': model.cardShape === 'Round',
                     'curvedCardButton': model.cardShape === 'Curved',
                     'hexaCardButton': model.cardShape === 'Hexagon',
                     'noCardButton': model.cardShape === 'None',
                     'no-fill-image': model.imageShape === 'yes'
                   }" (click)="events()" [ngStyle]="{
                     'background-color': section.bgColor,
                     'background-image': model.imageShape === 'yes' ? 'url(' + section.logoUrl + ')' : 'none',
                     'background-repeat': 'no-repeat', 
                     'background-size': model.imageCover === 'Contain' ? 'contain' : 'cover',
                     'position': model.imageShape === 'yes' ? 'relative' : 'static',
                     'background-position':model.imageShape=='yes' ? 'center center': 'auto'
                   }">

                  <!-- Overlay (displayed when imageShape is 'yes') -->
                  <div class="overlay" *ngIf="model.imageShape === 'yes' && model.cardShape !== 'None'"></div>

                  <!-- Card content -->
                  <div class="card-content">
                    <div class="vertical-center">

                      <!-- Display Name Above Image/Icon if 'Top' position is chosen -->
                      <ng-container *ngIf="model.titlePosition === 'Top'">
                        <!-- Displayed display name -->
                        <h3 [ngStyle]="{'color': section.displayNameColor}" [ngClass]="{
                                  'small-t': model.cardSize === 'Small',
                                  'medium-t': model.cardSize === 'Medium',
                                  'large-t': model.cardSize === 'Large'
                                }">
                          {{ section.displayName }}
                        </h3>
                      </ng-container>
                      <!-- Icon displayed when logoUrl is not available -->
                      <i *ngIf="!section.logoUrl" class="material-symbols-outlined"
                        [ngStyle]="{'border-color': section.displayNameColor, 'color': section.displayNameColor}"
                        [ngClass]="{
                           'rectangularCardIcon': model.cardIconShape === 'Rectangle',
                           'roundCardIcon': model.cardIconShape === 'Round',
                           'curvedCardIcon': model.cardIconShape === 'Curved',
                           'hexaCardIcon': model.cardIconShape === 'Hexagon',
                           'noCardIcon': model.cardIconShape === 'None'
                         }">
                        {{ section.icon }}
                      </i>

                      <!-- Image displayed when logoUrl is available and imageShape is 'no' -->
                      <img *ngIf="section.logoUrl && model.imageShape === 'no'" class="responsive-img center" alt="Events image"
                        [src]="section.logoUrl" [ngClass]="{
                             'rectangularCardIcon': model.cardIconShape === 'Rectangle',
                             'roundCardIcon': model.cardIconShape === 'Round',
                             'curvedCardIcon': model.cardIconShape === 'Curved',
                             'hexaCardIcon': model.cardIconShape === 'Hexagon',
                             'noCardIcon': model.cardIconShape === 'None'
                           }">

                      <!-- Display Name Above Image/Icon if 'Top' position is chosen -->
                      <ng-container *ngIf="model.titlePosition === 'Bottom'">
                        <!-- Displayed display name -->
                        <h3 [ngStyle]="{'color': section.displayNameColor}" [ngClass]="{
                                  'small-t': model.cardSize === 'Small',
                                  'medium-t': model.cardSize === 'Medium',
                                  'large-t': model.cardSize === 'Large'
                                }">
                          {{ section.displayName }}
                        </h3>
                      </ng-container>
                    </div>
                  </div>
                </a>
              </div>
            </div>

            <!-- ******************** Download App ************************** -->
            <div *ngSwitchCase="'AppStore'">
              <!-- Card container with dynamic grid sizes -->
              <div class="card-padding" [ngClass]="['col', model.desktopGridSize, model.mobileGridSize]">
                <!-- Card button -->
                <a class="modal-trigger card-option" [ngClass]="{
                      'rectangularCardButton': model.cardShape === 'Rectangle' && imageOption === 'no',
                      'roundCardButton': model.cardShape === 'Round',
                      'curvedCardButton': model.cardShape === 'Curved',
                      'hexaCardButton': model.cardShape === 'Hexagon',
                      'noCardButton': model.cardShape === 'None',
                      'no-fill-image': model.imageShape === 'yes'
                   }" href="#appstore" [ngStyle]="{
                      'background-color': section.bgColor,
                      'background-image': model.imageShape === 'yes' ? 'url(' + section.logoUrl + ')' : 'none',
                      'background-repeat': 'no-repeat', 
                      'background-size': model.imageCover === 'Contain' ? 'contain' : 'cover',
                      'position': model.imageShape === 'yes' ? 'relative' : 'static',
                      'background-position':model.imageShape=='yes' ? 'center center': 'auto'
                   }">

                  <!-- Overlay (displayed when imageShape is 'yes') -->
                  <div class="overlay" *ngIf="model.imageShape === 'yes' && model.cardShape !== 'None'"></div>

                  <!-- Card content -->
                  <div class="card-content">
                    <div class="vertical-center">

                      <!-- Display Name Above Image/Icon if 'Top' position is chosen -->
                      <ng-container *ngIf="model.titlePosition === 'Top'">
                        <!-- Displayed display name -->
                        <h3 [ngStyle]="{'color': section.displayNameColor}" [ngClass]="{
                                  'small-t': model.cardSize === 'Small',
                                  'medium-t': model.cardSize === 'Medium',
                                  'large-t': model.cardSize === 'Large'
                                }">
                          {{ section.displayName }}
                        </h3>
                      </ng-container>
                      <!-- Icon displayed when logoUrl is not available -->
                      <i *ngIf="!section.logoUrl" class="material-symbols-outlined" [ngStyle]="{
                            'border-color': section.displayNameColor,
                            'color': section.displayNameColor
                         }" [ngClass]="{
                            'rectangularCardIcon': model.cardIconShape === 'Rectangle',
                            'roundCardIcon': model.cardIconShape === 'Round',
                            'curvedCardIcon': model.cardIconShape === 'Curved',
                            'hexaCardIcon': model.cardIconShape === 'Hexagon',
                            'noCardIcon': model.cardIconShape === 'None'
                         }">
                        {{ section.icon }}
                      </i>

                      <!-- Image displayed when logoUrl is available and imageShape is 'no' -->
                      <img *ngIf="section.logoUrl && model.imageShape === 'no'" class="responsive-img center" alt="Download app image"
                        [src]="section.logoUrl" [ngClass]="{
                              'rectangularCardIcon': model.cardIconShape === 'Rectangle',
                              'roundCardIcon': model.cardIconShape === 'Round',
                              'curvedCardIcon': model.cardIconShape === 'Curved',
                              'hexaCardIcon': model.cardIconShape === 'Hexagon',
                              'noCardIcon': model.cardIconShape === 'None'
                           }">

                      <!-- Display Name Above Image/Icon if 'Top' position is chosen -->
                      <ng-container *ngIf="model.titlePosition === 'Bottom'">
                        <!-- Displayed display name -->
                        <h3 [ngStyle]="{'color': section.displayNameColor}" [ngClass]="{
                        'small-t': model.cardSize === 'Small',
                        'medium-t': model.cardSize === 'Medium',
                        'large-t': model.cardSize === 'Large'
                      }">
                          {{ section.displayName }}
                        </h3>
                      </ng-container>
                    </div>
                  </div>
                </a>
              </div>
            </div>

            <!-- ******************** Governance ************************** -->
            <div *ngSwitchCase="'Governance'">
              <!-- Card container with dynamic grid sizes -->
              <div class="card-padding" [ngClass]="['col', model.desktopGridSize, model.mobileGridSize]">
                <!-- Card button -->
                <a class="card-option" [ngClass]="{
                     'rectangularCardButton': model.cardShape === 'Rectangle' && imageOption === 'no',
                     'roundCardButton': model.cardShape === 'Round',
                     'curvedCardButton': model.cardShape === 'Curved',
                     'hexaCardButton': model.cardShape === 'Hexagon',
                     'noCardButton': model.cardShape === 'None',
                     'no-fill-image': model.imageShape === 'yes'
                   }" (click)="chief()" [ngStyle]="{
                     'background-color': section.bgColor,
                     'background-image': model.imageShape === 'yes' ? 'url(' + section.logoUrl + ')' : 'none',
                     'background-size': model.imageCover === 'Contain' ? 'contain' : 'cover',
                     'background-repeat': 'no-repeat', 
                     'position': model.imageShape === 'yes' ? 'relative' : 'static',
                     'background-position':model.imageShape=='yes' ? 'center center': 'auto'
                   }">

                  <!-- Overlay (displayed when imageShape is 'yes') -->
                  <div class="overlay" *ngIf="model.imageShape === 'yes' && model.cardShape !== 'None'"></div>

                  <!-- Card content -->
                  <div class="card-content">
                    <div class="vertical-center">
                      <!-- Display Name Above Image/Icon if 'Top' position is chosen -->
                      <ng-container *ngIf="model.titlePosition === 'Top'">
                        <!-- Displayed display name -->
                        <h3 [ngStyle]="{'color': section.displayNameColor}" [ngClass]="{
                                  'small-t': model.cardSize === 'Small',
                                  'medium-t': model.cardSize === 'Medium',
                                  'large-t': model.cardSize === 'Large'
                                }">
                          {{ section.displayName }}
                        </h3>
                      </ng-container>
                      <!-- Icon displayed when logoUrl is not available -->
                      <i *ngIf="!section.logoUrl" class="material-symbols-outlined"
                        [ngStyle]="{'border-color': section.displayNameColor, 'color': section.displayNameColor}"
                        [ngClass]="{
                           'rectangularCardIcon': model.cardIconShape === 'Rectangle',
                           'roundCardIcon': model.cardIconShape === 'Round',
                           'curvedCardIcon': model.cardIconShape === 'Curved',
                           'hexaCardIcon': model.cardIconShape === 'Hexagon',
                           'noCardIcon': model.cardIconShape === 'None'
                         }">
                        {{ section.icon }}
                      </i>

                      <!-- Image displayed when logoUrl is available and imageShape is 'no' -->
                      <img *ngIf="section.logoUrl && model.imageShape === 'no'" class="responsive-img center" alt="Governance image"
                        [src]="section.logoUrl" [ngClass]="{
                             'rectangularCardIcon': model.cardIconShape === 'Rectangle',
                             'roundCardIcon': model.cardIconShape === 'Round',
                             'curvedCardIcon': model.cardIconShape === 'Curved',
                             'hexaCardIcon': model.cardIconShape === 'Hexagon',
                             'noCardIcon': model.cardIconShape === 'None'
                           }">

                      <!-- Display Name Above Image/Icon if 'Top' position is chosen -->
                      <ng-container *ngIf="model.titlePosition === 'Bottom'">
                        <!-- Displayed display name -->
                        <h3 [ngStyle]="{'color': section.displayNameColor}" [ngClass]="{
                          'small-t': model.cardSize === 'Small',
                          'medium-t': model.cardSize === 'Medium',
                          'large-t': model.cardSize === 'Large'
                        }">
                          {{ section.displayName }}
                        </h3>
                      </ng-container>
                    </div>
                  </div>
                </a>
              </div>
            </div>

            <!-- ******************** DEPARTMENTS ************************** -->
            <div *ngSwitchCase="'Departments'">
              <!-- Card container with dynamic grid sizes -->
              <div class="card-padding" [ngClass]="['col', model.desktopGridSize, model.mobileGridSize]">
                <!-- Card button -->
                <a class="card-option" [ngClass]="{
                     'rectangularCardButton': model.cardShape === 'Rectangle' && imageOption === 'no',
                     'roundCardButton': model.cardShape === 'Round',
                     'curvedCardButton': model.cardShape === 'Curved',
                     'hexaCardButton': model.cardShape === 'Hexagon',
                     'noCardButton': model.cardShape === 'None',
                     'no-fill-image': model.imageShape === 'yes'
                   }" (click)="department()" [ngStyle]="{
                     'background-color': section.bgColor,
                     'background-image': model.imageShape === 'yes' ? 'url(' + section.logoUrl + ')' : 'none',
                     'background-size': model.imageCover === 'Contain' ? 'contain' : 'cover',
                     'background-repeat': 'no-repeat', 
                     'position': model.imageShape === 'yes' ? 'relative' : 'static',
                     'background-position':model.imageShape=='yes' ? 'center center': 'auto'
                   }">

                  <!-- Overlay (displayed when imageShape is 'yes') -->
                  <div class="overlay" *ngIf="model.imageShape === 'yes' && model.cardShape !== 'None'"></div>

                  <!-- Card content -->
                  <div class="card-content">
                    <div class="vertical-center">
                      <!-- Display Name Above Image/Icon if 'Top' position is chosen -->
                      <ng-container *ngIf="model.titlePosition === 'Top'">
                        <!-- Displayed display name -->
                        <h3 [ngStyle]="{'color': section.displayNameColor}" [ngClass]="{
                                  'small-t': model.cardSize === 'Small',
                                  'medium-t': model.cardSize === 'Medium',
                                  'large-t': model.cardSize === 'Large'
                                }">
                          {{ section.displayName }}
                        </h3>
                      </ng-container>
                      <!-- Icon displayed when logoUrl is not available -->
                      <i *ngIf="!section.logoUrl" class="material-symbols-outlined"
                        [ngStyle]="{'border-color': section.displayNameColor, 'color': section.displayNameColor}"
                        [ngClass]="{
                           'rectangularCardIcon': model.cardIconShape === 'Rectangle',
                           'roundCardIcon': model.cardIconShape === 'Round',
                           'curvedCardIcon': model.cardIconShape === 'Curved',
                           'hexaCardIcon': model.cardIconShape === 'Hexagon',
                           'noCardIcon': model.cardIconShape === 'None'
                         }">
                        {{ section.icon }}
                      </i>

                      <!-- Image displayed when logoUrl is available and imageShape is 'no' -->
                      <img *ngIf="section.logoUrl && model.imageShape === 'no'" class="responsive-img center" alt="department image"
                        [src]="section.logoUrl" [ngClass]="{
                             'rectangularCardIcon': model.cardIconShape === 'Rectangle',
                             'roundCardIcon': model.cardIconShape === 'Round',
                             'curvedCardIcon': model.cardIconShape === 'Curved',
                             'hexaCardIcon': model.cardIconShape === 'Hexagon',
                             'noCardIcon': model.cardIconShape === 'None'
                           }">

                      <!-- Display Name Above Image/Icon if 'Top' position is chosen -->
                      <ng-container *ngIf="model.titlePosition === 'Bottom'">
                        <!-- Displayed display name -->
                        <h3 [ngStyle]="{'color': section.displayNameColor}" [ngClass]="{
                                  'small-t': model.cardSize === 'Small',
                                  'medium-t': model.cardSize === 'Medium',
                                  'large-t': model.cardSize === 'Large'
                                }">
                          {{ section.displayName }}
                        </h3>
                      </ng-container>
                    </div>
                  </div>
                </a>
              </div>
            </div>

            <!-- ******************** Social Media ************************** -->
            <div *ngSwitchCase="'SocialMedia'">
              <!-- Card container with dynamic grid sizes -->
              <div class="card-padding" [ngClass]="['col', model.desktopGridSize, model.mobileGridSize]">
                <!-- Card button -->
                <a class="modal-trigger card-option" [ngClass]="{
                     'rectangularCardButton': model.cardShape === 'Rectangle' && imageOption === 'no',
                     'roundCardButton': model.cardShape === 'Round',
                     'curvedCardButton': model.cardShape === 'Curved',
                     'hexaCardButton': model.cardShape === 'Hexagon',
                     'noCardButton': model.cardShape === 'None',
                     'no-fill-image': model.imageShape === 'yes'
                   }" href="#social" [ngStyle]="{
                     'background-color': section.bgColor,
                     'background-image': model.imageShape === 'yes' ? 'url(' + section.logoUrl + ')' : 'none',
                     'background-size': model.imageCover === 'Contain' ? 'contain' : 'cover',
                     'background-repeat': 'no-repeat', 
                     'position': model.imageShape === 'yes' ? 'relative' : 'static',
                     'background-position':model.imageShape=='yes' ? 'center center': 'auto'
                   }">

                  <!-- Overlay (displayed when imageShape is 'yes') -->
                  <div class="overlay" *ngIf="model.imageShape === 'yes' && model.cardShape !== 'None'"></div>

                  <!-- Card content -->
                  <div class="card-content">
                    <div class="vertical-center">
                      <!-- Display Name Above Image/Icon if 'Top' position is chosen -->
                      <ng-container *ngIf="model.titlePosition === 'Top'">
                        <!-- Displayed display name -->
                        <h3 [ngStyle]="{'color': section.displayNameColor}" [ngClass]="{
                                  'small-t': model.cardSize === 'Small',
                                  'medium-t': model.cardSize === 'Medium',
                                  'large-t': model.cardSize === 'Large'
                                }">
                          {{ section.displayName }}
                        </h3>
                      </ng-container>
                      <!-- Icon displayed when logoUrl is not available -->
                      <i *ngIf="!section.logoUrl" class="material-symbols-outlined"
                        [ngStyle]="{'border-color': section.displayNameColor, 'color': section.displayNameColor}"
                        [ngClass]="{
                           'rectangularCardIcon': model.cardIconShape === 'Rectangle',
                           'roundCardIcon': model.cardIconShape === 'Round',
                           'curvedCardIcon': model.cardIconShape === 'Curved',
                           'hexaCardIcon': model.cardIconShape === 'Hexagon',
                           'noCardIcon': model.cardIconShape === 'None'
                         }">
                        {{ section.icon }}
                      </i>

                      <!-- Image displayed when logoUrl is available and imageShape is 'no' -->
                      <img *ngIf="section.logoUrl && model.imageShape === 'no'" class="responsive-img center" alt="socialmedia image"
                        [src]="section.logoUrl" [ngClass]="{
                             'rectangularCardIcon': model.cardIconShape === 'Rectangle',
                             'roundCardIcon': model.cardIconShape === 'Round',
                             'curvedCardIcon': model.cardIconShape === 'Curved',
                             'hexaCardIcon': model.cardIconShape === 'Hexagon',
                             'noCardIcon': model.cardIconShape === 'None'
                           }">

                      <!-- Display Name Above Image/Icon if 'Top' position is chosen -->
                      <ng-container *ngIf="model.titlePosition === 'Bottom'">
                        <!-- Displayed display name -->
                        <h3 [ngStyle]="{'color': section.displayNameColor}" [ngClass]="{
                                'small-t': model.cardSize === 'Small',
                                'medium-t': model.cardSize === 'Medium',
                                'large-t': model.cardSize === 'Large'
                              }">
                          {{ section.displayName }}
                        </h3>
                      </ng-container>
                    </div>
                  </div>
                </a>
              </div>
            </div>

            <!-- ******************** About Us ************************** -->
            <div *ngSwitchCase="'AboutUs'">
              <div class=" card-padding" [ngClass]="['col', model.desktopGridSize , model.mobileGridSize ]">

                <a class="card-option" [ngClass]="{
                    'rectangularCardButton': model.cardShape === 'Rectangle' && imageOption === 'no',
                    'roundCardButton': model.cardShape === 'Round',
                    'curvedCardButton': model.cardShape === 'Curved',
                    'hexaCardButton': model.cardShape === 'Hexagon',
                    'noCardButton': model.cardShape === 'None',
                    'no-fill-image': model.imageShape === 'yes'
                  }" (click)="aboutUs()" [ngStyle]="{'background-color': section.bgColor, 'background-image': model.imageShape === 'yes' ? 'url(' + section.logoUrl + ')' : 'none',
                  'background-size': model.imageCover === 'Contain' ? 'contain' : 'cover',
                  'background-repeat': 'no-repeat', 
                  'position': model.imageShape === 'yes' ? 'relative' : 'static',
                  'background-position':model.imageShape=='yes' ? 'center center': 'auto'
                }">

                  <!-- Overlay (displayed when imageShape is 'yes') -->
                  <div class="overlay" *ngIf="model.imageShape === 'yes' && model.cardShape !== 'None'"></div>

                  <div class="card-content">
                    <!-- Display Name Above Image/Icon if 'Top' position is chosen -->
                    <ng-container *ngIf="model.titlePosition === 'Top'">
                      <!-- Displayed display name -->
                      <h3 [ngStyle]="{'color': section.displayNameColor}" [ngClass]="{
                                  'small-t': model.cardSize === 'Small',
                                  'medium-t': model.cardSize === 'Medium',
                                  'large-t': model.cardSize === 'Large'
                                }">
                        {{ section.displayName }}
                      </h3>
                    </ng-container>
                    <div class="vertical-center">
                      <i *ngIf="!section.logoUrl" class="material-symbols-outlined"
                        [ngStyle]="{'border-color':section.displayNameColor , 'color':section.displayNameColor}"
                        [ngClass]="{'rectangularCardIcon': model.cardIconShape == 'Rectangle', 'roundCardIcon': model.cardIconShape == 'Round','curvedCardIcon': model.cardIconShape == 'Curved','hexaCardIcon': model.cardIconShape == 'Hexagon' , 'noCardIcon': model.cardIconShape == 'None' }">
                        {{ section.icon }}</i>

                      <!-- Image displayed when logoUrl is available and imageShape is 'no' -->
                      <img *ngIf="section.logoUrl && model.imageShape === 'no'" class="responsive-img center" alt="logoUrl"
                        [src]="section.logoUrl" [ngClass]="{
                          'rectangularCardIcon': model.cardIconShape === 'Rectangle',
                          'roundCardIcon': model.cardIconShape === 'Round',
                          'curvedCardIcon': model.cardIconShape === 'Curved',
                          'hexaCardIcon': model.cardIconShape === 'Hexagon',
                          'noCardIcon': model.cardIconShape === 'None'
                        }">

                      <!-- Display Name Above Image/Icon if 'Top' position is chosen -->
                      <ng-container *ngIf="model.titlePosition === 'Bottom'">
                        <!-- Displayed display name -->
                        <h3 [ngStyle]="{'color': section.displayNameColor}" [ngClass]="{
                                  'small-t': model.cardSize === 'Small',
                                  'medium-t': model.cardSize === 'Medium',
                                  'large-t': model.cardSize === 'Large'
                                }">
                          {{ section.displayName }}
                        </h3>
                      </ng-container>
                    </div>
                  </div>
                </a>
              </div>
            </div>

            <!-- ******************** Contact Us ************************** -->
            <div *ngSwitchCase="'ContactUs'">
              <div class=" card-padding" [ngClass]="['col', model.desktopGridSize , model.mobileGridSize ]">

                <a class="card-option" [ngClass]="{
                    'rectangularCardButton': model.cardShape === 'Rectangle' && imageOption === 'no',
                    'roundCardButton': model.cardShape === 'Round',
                    'curvedCardButton': model.cardShape === 'Curved',
                    'hexaCardButton': model.cardShape === 'Hexagon',
                    'noCardButton': model.cardShape === 'None',
                    'no-fill-image': model.imageShape === 'yes'
                  }" (click)="contactUs()" [ngStyle]="{'background-color': section.bgColor, 'background-image': model.imageShape === 'yes' ? 'url(' + section.logoUrl + ')' : 'none',
                  'background-size': model.imageShape === 'yes' ? 'cover' : 'auto',
                  'position': model.imageShape === 'yes' ? 'relative' : 'static',
                  'background-position':model.imageShape=='yes' ? 'center center': 'auto'}">

                  <!-- Overlay (displayed when imageShape is 'yes') -->
                  <div class="overlay" *ngIf="model.imageShape === 'yes'"></div>
                  <div class="card-content">
                    <!-- Display Name Above Image/Icon if 'Top' position is chosen -->
                    <ng-container *ngIf="model.titlePosition === 'Top'">
                      <!-- Displayed display name -->
                      <h3 [ngStyle]="{'color': section.displayNameColor}" [ngClass]="{
                                  'small-t': model.cardSize === 'Small',
                                  'medium-t': model.cardSize === 'Medium',
                                  'large-t': model.cardSize === 'Large'
                                }">
                        {{ section.displayName }}
                      </h3>
                    </ng-container>
                    <div class="vertical-center">
                      <i *ngIf="!section.logoUrl" class="material-symbols-outlined"
                        [ngStyle]="{'border-color':section.displayNameColor , 'color':section.displayNameColor}"
                        [ngClass]="{'rectangularCardIcon': model.cardIconShape == 'Rectangle', 'roundCardIcon': model.cardIconShape == 'Round','curvedCardIcon': model.cardIconShape == 'Curved','hexaCardIcon': model.cardIconShape == 'Hexagon' , 'noCardIcon': model.cardIconShape == 'None' }">
                        {{ section.icon }}</i>

                      <!-- Image displayed when logoUrl is available and imageShape is 'no' -->
                      <img *ngIf="section.logoUrl && model.imageShape === 'no'" class="responsive-img center" alt="contactus image" alt="logoUrl"
                        [src]="section.logoUrl" [ngClass]="{
                          'rectangularCardIcon': model.cardIconShape === 'Rectangle',
                          'roundCardIcon': model.cardIconShape === 'Round',
                          'curvedCardIcon': model.cardIconShape === 'Curved',
                          'hexaCardIcon': model.cardIconShape === 'Hexagon',
                          'noCardIcon': model.cardIconShape === 'None'
                        }">

                      <!-- Display Name Above Image/Icon if 'Top' position is chosen -->
                      <ng-container *ngIf="model.titlePosition === 'Bottom'">
                        <!-- Displayed display name -->
                        <h3 [ngStyle]="{'color': section.displayNameColor}" [ngClass]="{
                                  'small-t': model.cardSize === 'Small',
                                  'medium-t': model.cardSize === 'Medium',
                                  'large-t': model.cardSize === 'Large'
                                }">
                          {{ section.displayName }}
                        </h3>
                      </ng-container>
                    </div>
                  </div>
                </a>
              </div>
            </div>

            <!-- ******************** Demo Account ************************** -->
            <div *ngSwitchCase="'Demo'">
              <div class=" card-padding" [ngClass]="['col', model.desktopGridSize , model.mobileGridSize ]">

                <a class="card-option" [ngClass]="{
                    'rectangularCardButton': model.cardShape === 'Rectangle' && imageOption === 'no',
                    'roundCardButton': model.cardShape === 'Round',
                    'curvedCardButton': model.cardShape === 'Curved',
                    'hexaCardButton': model.cardShape === 'Hexagon',
                    'noCardButton': model.cardShape === 'None',
                    'no-fill-image': model.imageShape === 'yes'
                  }" (click)="demoAccount()" [ngStyle]="{'background-color': section.bgColor, 'background-image': model.imageShape === 'yes' ? 'url(' + section.logoUrl + ')' : 'none',
                  'background-size': model.imageShape === 'yes' ? 'cover' : 'auto',
                  'position': model.imageShape === 'yes' ? 'relative' : 'static',
                  'background-position':model.imageShape=='yes' ? 'center center': 'auto'
                }">

                  <!-- Overlay (displayed when imageShape is 'yes') -->
                  <div class="overlay" *ngIf="model.imageShape === 'yes'"></div>
                  <div class="card-content">
                    <!-- Display Name Above Image/Icon if 'Top' position is chosen -->
                    <ng-container *ngIf="model.titlePosition === 'Top'">
                      <!-- Displayed display name -->
                      <h3 [ngStyle]="{'color': section.displayNameColor}" [ngClass]="{
                                  'small-t': model.cardSize === 'Small',
                                  'medium-t': model.cardSize === 'Medium',
                                  'large-t': model.cardSize === 'Large'
                                }">
                        {{ section.displayName }}
                      </h3>
                    </ng-container>
                    <div class="vertical-center">
                      <i *ngIf="!section.logoUrl" class="material-symbols-outlined"
                        [ngStyle]="{'border-color':section.displayNameColor , 'color':section.displayNameColor}"
                        [ngClass]="{'rectangularCardIcon': model.cardIconShape == 'Rectangle', 'roundCardIcon': model.cardIconShape == 'Round','curvedCardIcon': model.cardIconShape == 'Curved','hexaCardIcon': model.cardIconShape == 'Hexagon' , 'noCardIcon': model.cardIconShape == 'None' }">
                        {{ section.icon }}</i>

                      <!-- Image displayed when logoUrl is available and imageShape is 'no' -->
                      <img *ngIf="section.logoUrl && model.imageShape === 'no'" class="responsive-img center" alt="demo account image" alt="logoUrl"
                        [src]="section.logoUrl" [ngClass]="{
                          'rectangularCardIcon': model.cardIconShape === 'Rectangle',
                          'roundCardIcon': model.cardIconShape === 'Round',
                          'curvedCardIcon': model.cardIconShape === 'Curved',
                          'hexaCardIcon': model.cardIconShape === 'Hexagon',
                          'noCardIcon': model.cardIconShape === 'None'
                        }">

                      <!-- Display Name Above Image/Icon if 'Top' position is chosen -->
                      <ng-container *ngIf="model.titlePosition === 'Bottom'">
                        <!-- Displayed display name -->
                        <h3 [ngStyle]="{'color': section.displayNameColor}" [ngClass]="{
                                  'small-t': model.cardSize === 'Small',
                                  'medium-t': model.cardSize === 'Medium',
                                  'large-t': model.cardSize === 'Large'
                                }">
                          {{ section.displayName }}
                        </h3>
                      </ng-container>
                    </div>
                  </div>
                </a>
              </div>
            </div>

          </div>
        </div>
      </div>

      <div *ngFor="let section of customeSection">

        <!-- Card container with dynamic grid sizes -->
        <div class="card-padding" [ngClass]="['col', model.desktopGridSize, model.mobileGridSize]">
          <!-- Card button -->
          <a class="card-option" [ngClass]="{
                 'rectangularCardButton': model.cardShape === 'Rectangle' && imageOption === 'no',
                 'roundCardButton': model.cardShape === 'Round',
                 'curvedCardButton': model.cardShape === 'Curved',
                 'hexaCardButton': model.cardShape === 'Hexagon',
                 'noCardButton': model.cardShape === 'None',
                 'no-fill-image': model.imageShape === 'yes'
               }" (click)="custome(section.id)" [ngStyle]="{
                 'background-color': section.bgColor,
                 'background-image': model.imageShape === 'yes' ? 'url(' + section.logoUrl + ')' : 'none',
                 'background-size': model.imageCover === 'Contain' ? 'contain' : 'cover',
                 'background-repeat': 'no-repeat', 
                 'position': model.imageShape === 'yes' ? 'relative' : 'static',
                 'background-position':model.imageShape=='yes' ? 'center center': 'auto'
               }">

            <!-- Overlay (displayed when imageShape is 'yes') -->
            <div class="overlay" *ngIf="model.imageShape === 'yes' && model.cardShape !== 'None'"></div>

            <!-- Card content -->
            <div class="card-content">
              <div class="vertical-center">
                <ng-container *ngIf="model.titlePosition === 'Top'">
                  <!-- Displayed display name -->
                  <h3 [ngStyle]="{'color': section.displayNameColor}" [ngClass]="{
        'small-t': model.cardSize === 'Small',
        'medium-t': model.cardSize === 'Medium',
        'large-t': model.cardSize === 'Large'
      }">
                    {{ section.displayName }}
                  </h3>
                </ng-container>

                <!-- Icon displayed when logoUrl is not available -->
                <i *ngIf="!section.logoUrl" class="material-symbols-outlined"
                  [ngStyle]="{'border-color': section.displayNameColor, 'color': section.displayNameColor}" [ngClass]="{
                       'rectangularCardIcon': model.cardIconShape === 'Rectangle',
                       'roundCardIcon': model.cardIconShape === 'Round',
                       'curvedCardIcon': model.cardIconShape === 'Curved',
                       'hexaCardIcon': model.cardIconShape === 'Hexagon',
                       'noCardIcon': model.cardIconShape === 'None'
                     }">
                  {{ section.icon }}
                </i>

                <!-- Image displayed when logoUrl is available and imageShape is 'no' -->
                <img *ngIf="section.logoUrl && model.imageShape === 'no'" class="responsive-img center" alt="custom-section image"
                  [src]="section.logoUrl" [ngClass]="{
                         'rectangularCardIcon': model.cardIconShape === 'Rectangle',
                         'roundCardIcon': model.cardIconShape === 'Round',
                         'curvedCardIcon': model.cardIconShape === 'Curved',
                         'hexaCardIcon': model.cardIconShape === 'Hexagon',
                         'noCardIcon': model.cardIconShape === 'None'
                       }">

                <!-- Display Name Below Image/Icon if 'Bottom' position is chosen -->
                <ng-container *ngIf="model.titlePosition === 'Bottom'">
                  <!-- Displayed display name -->
                  <h3 [ngStyle]="{'color': section.displayNameColor}" [ngClass]="{
              'small-t': model.cardSize === 'Small',
              'medium-t': model.cardSize === 'Medium',
              'large-t': model.cardSize === 'Large'
            }">
                    {{ section.displayName }}
                  </h3>
                </ng-container>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>


  </div>

  <!-- ******************** Footer ************************** -->

  <div class="row" *ngIf="footerSection?.isEnabled && !onlyCards">
    <app-landing-page-footer></app-landing-page-footer>
  </div>

  <!-- modal -->
  <!-- Social Media -->
  <div id="social" class="modal">
    <div class="modal-content">
      <app-social-media-links></app-social-media-links>
    </div>
  </div>

  <!-- Download from AppSore -->
  <div id="appstore" class="modal" *ngIf="appStoreSection && appStoreSection.isEnabled">
    <div class="modal-content">
      <!-- <app-app-store></app-app-store> -->

      <div class="col s12 l12 m12">
        <div class="container">
          <div class="row col s11 m12 l12 l6 center">
            <div class="header-title-mobileapp ">
              <h1 [ngStyle]="{'color': 'black'}"> {{appStoreSection.displayName}}</h1>
              <h6 class="description-appDownload" [innerHTML]="appStoreSection.description"></h6>
            </div>
          </div>

          <div class="col s8 m8 l8 push-l2 push-m2 push-s2 center">

            <!-- <div class="section"></div> -->

            <div class="row col s12 m6 l6 ">
              <a class="app-btn white-text flex vert  b-n-1 " [ngStyle]="{'background-color': themeColor} "
                [href]="appStoreSection.appleStoreUrl">
                <i class="fa fa-apple" style='font-size:44px; color: white;'></i>
                <p class="white-text" style="font-size: 10px; font-family: helvetica;">Available on the <br /> <span
                    class="white-text" style="font-size: 20px; font-family: helvetica;">App Store</span></p>
              </a>
            </div>

            <div class="row col s12 m6 l6">
              <a class="app-btn  white-text flex vert b-n-1 " [ngStyle]="{'background-color': themeColor} "
                [href]="appStoreSection.googlePlayUrl">
                <img src="https://img.icons8.com/ios-filled/40/ffffff/google-play.png" class="p-r-1" alt="Applestore image" alt="AppStore Url"/>
                <p class="white-text" style="font-size: 10px; font-family: helvetica;">Available on the <br /> <span
                    class="white-text" style="font-size: 20px; font-family: helvetica;">Play Store</span></p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>