import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { toast } from 'materialize-css';
import { EmailModel } from 'src/app/models/emailModel';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { RequesttojoinService } from 'src/app/services/firebase/requesttojoin.service';
import { RegistrationRequestService } from 'src/app/services/api/registration-request.service';
import { MemberService } from 'src/app/services/api/member.service';
import { FireMembersService } from 'src/app/services/firebase/fire-members.service';
import { AddBandMemberModel } from 'src/app/models/AddMemberModel';
import { DocumentsService } from 'src/app/services/api/documents.service';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-request-detail',
  templateUrl: './request-detail.component.html',
  styleUrls: ['./request-detail.component.css']
})

export class RequestDetailComponent implements OnInit {

  @Input() id: any;
  @Input() SQLDB: any;
  @Input() invited: any;
  @Output() back: EventEmitter<void> = new EventEmitter<void>();

  url = environment.firstNation.website;
  themeColor = environment.appTheme.adminThemeColor;
  public dateformat = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
  public phoneNumberMask = ['(', /[0-9]/, /[0-9]/, /[0-9]/, ')',
    ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  provinces = [{ name: 'Alberta', code: 'AB' },
  { name: 'British Columbia', code: 'BC' },
  { name: 'Manitoba', code: 'MB' },
  { name: 'New Brunswick', code: 'NB' },
  { name: 'Newfoundland and Labrador', code: 'NL' },
  { name: 'Nova Scotia', code: 'NS' },
  { name: 'Ontario', code: 'ON' },
  { name: 'Prince Edward Island', code: 'PE' },
  { name: 'Quebec', code: 'QC' },
  { name: 'Saskatchewan', code: 'SK' }
  ];
  loading = false;
  loggedUser: any;
  request: any;
  invitedMember: any;
  member: any;
  step1 = true;
  step2 = false;
  result: any[];
  applicantID: any;
  firebaseMember: any;
  verifySIN = false;
  verifyISN = false;
  secretQuestion = false;
  question = '';
  answer = '';
  observer: any;
  compare = {
    firstName_df: false,
    lastName_df: false,
    middleName_df: false,
    dob_df: false,
    isn_df: false,
    sin_df: false,
    email_df: false,
    gender_df: false,
    healthCareNo_df: false,
    province_df: false,
    postalCode_df: false,
    address_df: false,
    city_df: false,
    community_df: false,
    mobile_df: false,
  }
  updateForm: UntypedFormGroup;
  updateDB: boolean;
  requestDataSelected = false;
  memberDataSelected = false;
  match: any;
  emailSent = false;
  smsSent = false;
  declineReason = '';

  contact = false;
  contactDetail: any = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNo: '',
  }
  message: any = {
    title: '',
    subject: '',
    body: '',
    files: []
  }
  matches: any[] = [];
  showDetails = true;
  matchI = 0;
  fileStreamId: any;
  ID_image: any;
  sendEmail = false;
  
  constructor(private fb: UntypedFormBuilder,
    private authService: AuthService,
    private memberService: MemberService,
    private requesttojoinService: RequesttojoinService,
    private sqlRegistration: RegistrationRequestService,
    private fireMemberService: FireMembersService,
    private documentService: DocumentsService,) {
    this.loggedUser = this.authService.getGlobalUser();
  }

  ngOnInit() {
    this.loading = true;
    if (this.invited) {
      this.memberService.getMember(this.id).subscribe(x => {
        if (x && x.length > 0)
          this.invitedMember = x[0];
      });
    }
    else {
      if (this.id) {
        if (this.SQLDB) {
          const id = +this.id;
          this.sqlRegistration.getRegistrationRequestByID(id).subscribe(x => {
            this.request = x[0];
            
            const model = { firstName: this.request.Fname, lastName: this.request.Lname, dob: this.request.dob };
            this.memberService.findMemberByName(model).subscribe(res => {
              if (res && res.length > 0) {
                this.result = res;
                if (this.result.length > 0) {
                  this.result.forEach(r => {
                    this.memberService.getMember(r.applicantID).subscribe(m => {
                      if (m && m.length) {
                        this.matches.push(m[0]);
                      }
                    });
                  });
                }
                this.loading = false;
              } else {
                this.result = [];
                this.matches = [];
              }
            });
            if (this.request.fileStreamID) {
              this.documentService.getImage(this.request.fileStreamID).subscribe(data => {
                if (data) {
                  this.fileStreamId = data;
                  this.createImageFromBlob(this.fileStreamId);
                }
              });
            }
          });
        }

        if (!this.SQLDB) {
          this.requesttojoinService.getRegistrationRequestByID(this.id).valueChanges().subscribe(x => {
            this.request = x;
            const model = { firstName: this.request.firstName, lastName: this.request.lastName };
            this.memberService.findMemberByName(model).subscribe(res => {
              if (res) {
                this.result = res;
                if (this.result.length > 0) {
                  this.result.forEach(r => {
                    this.memberService.getMember(r.applicantID).subscribe(m => {
                      if (m && m.length) {
                        this.matches.push(m[0]);
                      }
                    });
                  });
                }
              }
            });
          });
        }
      }
    }

    setTimeout(() => {
      $('.modal').modal();
    }, 100);
  }

  createImageFromBlob(image: Blob) {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      this.ID_image = reader.result;
    }, false);
    if (image) {
      reader.readAsDataURL(image);
    }
  }

  viewImage() {
    $('.modal').modal();
    $('#documentModal').modal('open');
  }

  previousMatch() {
    this.matchI -= 1;
  }

  nextMatch() {
    this.matchI += 1;
  }

  getProvinceName(code) { return this.provinces.find(p => p.code === code).name; }

  changeDates(dateValue) {
    if (dateValue) {
      if (dateValue.match(this.dateformat)) {
        let dateArray = dateValue.split('/');
        let year = dateArray[2];
        let month = dateArray[1] - 1;
        let day = dateArray[0];
        const date = new Date(year, month, day);
        return date;
      } else {
        const date = new Date(dateValue);
        return date;
      }
    } else {
      return null
    }
  }

  SetUser(id: any) {
    this.loading = true;
    this.applicantID = id;
    this.memberService.getMember(this.applicantID).subscribe(x => {
      if (x && x.length > 0) {
        this.member = x[0];
        if (this.request && this.member) {
          const dataMatches = this.compareData();
          if (dataMatches) {
            this.updateForm = this.fb.group({});
            this.loading = false;
            this.next();
          }
          else {
            this.memberDataSelected = false;
            this.requestDataSelected = false;
            this.updateForm = this.fb.group({
              firstName: [null, this.compare.firstName_df ? [this.required] : []],
              lastName: [null, this.compare.lastName_df ? [this.required] : []],
              middleName: [null, this.compare.middleName_df ? [this.required] : []],
              dob: [null, this.compare.dob_df ? [this.required] : []],
              email: [null, this.compare.email_df ? [this.required] : []],
              gender: [null, this.compare.gender_df ? [this.required] : []],
              isn: [null, this.compare.isn_df ? [this.required] : []],
              sin: [null, this.compare.sin_df ? [this.required] : []],
              healthCareNo: [null, this.compare.healthCareNo_df ? [this.required] : []],
              province: [null, this.compare.province_df ? [this.required] : []],
              postalCode: [null, this.compare.postalCode_df ? [this.required] : []],
              address: [null, this.compare.address_df ? [this.required] : []],
              city: [null, this.compare.city_df ? [this.required] : []],
              community: [null, this.compare.community_df ? [this.required] : []],
              mobile: [null, this.compare.mobile_df ? [this.required] : []]
            });
            setTimeout(() => {
              $('.modal').modal();
              $('#updateModal').modal('open');
            }, 25);
            this.loading = false;
          }
        }
      }
    });
  }

  compareData() {
    if (this.request.Fname != '' && !this.isEqual(this.request.Fname, this.member.GivenName)) this.compare.firstName_df = true;
    if (this.request.Lname != '' && !this.isEqual(this.request.Lname, this.member.LastName)) this.compare.lastName_df = true;
    if (this.request.Mname != '' && !this.isEqual(this.request.Mname, this.member.middle_name)) this.compare.middleName_df = true;
    if (this.request.dob && this.request.dob != '' && !this.isEqual(this.request.dob, this.member.DOB)) this.compare.dob_df = true;
    if (this.request.isn != '' && !this.isEqual(this.request.isn, this.member.IndianStatusNo)) this.compare.isn_df = true;
    if (this.request.email != '' && !this.isEqual(this.request.email, this.member.Email)) this.compare.email_df = true;
    if (this.request.gender != '' && !this.isEqual(this.request.gender.charAt(0), this.member.Sex)) this.compare.gender_df = true;
    if (this.request.sin != '' && !this.isEqual(this.request.sin, this.member.SIN)) this.compare.sin_df = true;
    if (this.request.HealthCareNo != '' && !this.isEqual(this.request.HealthCareNo, this.member.HealthCareNo)) this.compare.healthCareNo_df = true;
    const province = this.provinces.filter(prov => prov.name == this.request.province);
    if (province && province.length > 0) this.request.province = province[0].code;
    if (this.request.province != '' && !this.isEqual(this.request.province, this.member.province)) this.compare.province_df = true;
    if (this.request.postalcode != '' && !this.isEqual(this.request.postalcode, this.member.PostalCode)) this.compare.postalCode_df = true;
    if (this.request.address != '' && !this.isEqual(this.request.address, this.member.MailAddress)) this.compare.address_df = true;
    if (this.request.city != '' && !this.isEqual(this.request.city, this.member.city)) this.compare.city_df = true;
    if (this.request.community != '' && !this.isEqual(this.request.community, this.member.Community)) this.compare.community_df = true;
    if (this.request.mobile != '' && !this.isEqual(this.request.mobile, this.member.cell_phone_num)) this.compare.mobile_df = true;

    const dataMatches = Object.values(this.compare).every(value => !value);
    return dataMatches;
  }

  isEqual(data1, data2) {
    if (data1 == data2) return true;
    return false;
  }

  required(control: UntypedFormControl) {
    let value = control.value;
    if (value == null || (typeof value == undefined)) {
      return { 'required': true }
    }
    return null;
  }

  updateDBYes() {
    this.updateDB = true;
    this.memberDataSelected = false;
    this.updateForm.reset();
  }

  updateDBNo() {
    this.updateDB = false;
    this.selectMemberData();
  }

  selectRequestData() {
    this.requestDataSelected = !this.requestDataSelected;
    if (this.requestDataSelected) {
      this.memberDataSelected = false;
      this.updateForm.setValue({
        firstName: this.request.Fname,
        lastName: this.request.Lname,
        middleName: this.request.Mname,
        dob: this.request.dob,
        email: this.request.email,
        gender: this.request.gender,
        isn: this.request.isn,
        sin: this.request.sin,
        healthCareNo: this.request.HealthCareNo,
        province: this.request.province,
        postalCode: this.request.postalcode,
        address: this.request.address,
        city: this.request.city,
        community: this.request.community,
        mobile: this.request.mobile
      });
    }
    else {
      this.updateForm.reset();
    }
  }

  selectMemberData() {
    this.memberDataSelected = !this.memberDataSelected;
    if (this.memberDataSelected) {
      this.requestDataSelected = false;
      this.updateForm.setValue({
        firstName: this.member.GivenName,
        lastName: this.member.LastName,
        middleName: this.member.middle_name,
        dob: this.member.DOB,
        email: this.member.Email,
        gender: this.member.Sex,
        isn: this.member.IndianStatusNo,
        sin: this.member.SIN,
        healthCareNo: this.member.HealthCareNo,
        province: this.member.province,
        postalCode: this.member.PostalCode,
        address: this.member.MailAddress,
        city: this.member.city,
        community: this.member.Community,
        mobile: this.member.cell_phone_num
      });
    } else {
      this.updateForm.reset();
    }
  }

  updateProfile(values) {
    const profileModel = {
      applicantID: this.applicantID,
      cell_phone_num: values.mobile ? values.mobile : '',
      HealthCareNo: values.healthCareNo ? values.healthCareNo : '',
      SIN: values.sin ? values.sin : '',
      Email: values.email,
      MailAddress: values.address ? values.address : '',
      city: values.city ? values.city : '',
      province: values.province ? values.province : '',
      PostalCode: values.postalCode ? values.postalCode : '',
      MaritalStatus: this.member.MaritalStatus ? this.member.MaritalStatus : '',
      HouseNumber: this.member.HouseNumber ? this.member.HouseNumber : '',
      Telephone: this.member.Telephone ? this.member.Telephone : '',
      Fax: this.member.Fax ? this.member.Fax : ''
    }
    this.memberService.updateMemberProfile(profileModel).subscribe(x => {
      if (x) {
        toast({ html: 'Member sucessfully updated!', classes: 'green' });
        $('#updateModal').modal('close');
        this.next();
      }
    });
  }

  addMember() {
    let model = new AddBandMemberModel();
    model.GivenName = this.request.Fname;
    model.LastName = this.request.Lname;
    model.middle_name = this.request.Mname;
    if (this.request.dob && (typeof this.request.dob) == 'string') {
      this.request.dob = this.changeDates(this.request.dob);
    }
    model.DOB = this.request.dob;
    if (this.request.gender == 'Female') this.request.gender = 'F';
    else if (this.request.gender == 'Male') this.request.gender = 'M';
    model.IndianStatusNo = this.request.isn;
    model.Email = this.request.email;
    model.cell_phone_num = this.request.mobile;
    
    model.date_added = new Date();

    this.memberService.AddMember(model).subscribe(resp => {
      if (resp) {
        toast({ html: 'Member Added successfully!', classes: 'green' });
        this.member = model;
        this.member.applicantID = resp;
        this.applicantID = resp;
        this.matches.push(this.member);
        this.updateForm = this.fb.group({});
        this.next();
      }
    });
  }

  next() {
    this.loading = true;
    this.observer = this.fireMemberService.getMemberByAppliciantId(this.applicantID).valueChanges().subscribe(x => {
      if (x && x.length > 0) {
        this.firebaseMember = x[0];
        if (this.firebaseMember.role == 'Member') {
          toast({ html: 'This user already has a Member Account.', classes: 'red' });
          this.observer.unsubscribe();
          this.loading = false;
          return;
        }
        if (this.firebaseMember.role == 'Employee') {
          $('.modal').modal({ dismissible: false });
          $('#invitationModal').modal('open');
          this.observer.unsubscribe();
          this.loading = false;
          return;
        }
      } else {
        $('.modal').modal({ dismissible: false });
        $('#registrationModal').modal('open');
        this.observer.unsubscribe();
        this.loading = false;
        return;
      }
    });
  }

  sinChecked() {
    this.verifySIN = !this.verifySIN;
  }

  isnChecked() {
    this.verifyISN = !this.verifyISN;
  }

  secretQuestionChecked() {
    this.secretQuestion = !this.secretQuestion;
    if (!this.secretQuestion) { this.question = ''; this.answer = ''; }
  }

  SendRegistrationLink() {
    this.url = window.location.hostname !== 'localhost' ? this.url : 'http://localhost:4200/#';
    const invitationModel = {
      ApplicantID: this.applicantID,
      verifySIN: this.verifySIN,
      verifyISN: this.verifyISN,
      secretQuestion: this.question,
      secretAnswer: this.answer,
      email: this.request.email,
    };

    this.loading = true;
    this.memberService.InviteMember(invitationModel).subscribe(res => {
      if (res) {
        $('.modal').modal('close');
        this.loading = false;
        this.contactDetail.email = this.request.email;
        this.contactDetail.phoneNo = this.request.mobile;
        this.message.subject = 'Registration Request Approved';
        this.message.body = 'Your Request to join the my nation app has been Approved.\n\nPlease click the following link to complete your registration.\nRegistration Link : ' + this.url + '/auth/register/' + this.applicantID;
        this.message.title = 'RegistrationLink';
        this.contact = true;
      }
    });
  }

  invite() {
    const mail = new EmailModel();
    mail.emailList = [this.request.email];
    mail.subject = 'Account Update';
    mail.body = '<h2>Hello, </h2> <p>Your account has been upgraded to a Member account. </p> <p> <strong>Please Use your Employee account credentials to log into the app. </strong> </p> </p> <br/> <p>Thank you for using Our App. </p>';
    this.firebaseMember.isMember = true;
    this.loading = true;
    this.fireMemberService.updateAccountRole(this.firebaseMember).then(x => {
      $('.modal').modal('close');
      this.loading = false;
      this.contactDetail.email = this.request.email;
      this.contactDetail.phoneNo = this.request.mobile;
      this.message.subject = 'Account Update ';
      this.message.body = 'Hello, Your account has been upgraded to a Member account.\nPlease Use your Employee account credentials to log into the app.';
      this.message.title = 'Invitation';
      this.contact = true;
    });
  }

  resendLink() {
    this.applicantID = this.invitedMember.applicantID;
    this.url = window.location.hostname !== 'localhost' ? this.url : 'http://localhost:4200/#';
    this.contactDetail.email = this.invitedMember.Email;
    this.contactDetail.phoneNo = this.invitedMember.cell_phone_num;
    this.message.subject = 'Registration Link';
    this.message.body = 'Your Request to join the my nation app has been Approved.\n\nPlease click the following link to complete your registration.\nRegistration Link : ' + this.url + '/auth/register/' + this.applicantID;
    this.message.title = 'Resend-RegistrationLink';
    this.contact = true;
  }

  contactClient() {
    if (this.request) {
      this.contactDetail.firstName = this.SQLDB ? this.request.Fname : this.request.firstName;
      this.contactDetail.lastName = this.SQLDB ? this.request.Lname : this.request.lastName;
      this.contactDetail.email = this.request.email;
      this.contactDetail.phoneNo = this.request.mobile;
    }
    if (this.invited) {
      this.contactDetail.firstName = this.invitedMember.GivenName;
      this.contactDetail.lastName = this.invitedMember.LastName;
      this.contactDetail.email = this.invitedMember.Email;
      this.contactDetail.phoneNo = this.invitedMember.cell_phone_num;
    }
    this.contact = true;
  }

  Decline() {
    this.contactDetail.email = this.request.email;
    this.contactDetail.phoneNo = this.request.mobile;
    this.message.subject = 'Registration Request Declined';
    this.message.body = 'Your Request to join the my nation app was Declined.\n';
    this.message.title = 'DeclineMsg';
    this.contact = true;
  }

  contactHandler(valueEmitted) {
    if (valueEmitted.status == null) {
      return;
    }
    if (valueEmitted.title == 'RegistrationLink') {
      if (valueEmitted.status) {
        const invitationModel = {
          ApplicantID: this.applicantID,
          verifySIN: this.verifySIN,
          verifyISN: this.verifyISN,
          secretQuestion: this.question,
          secretAnswer: this.answer,
          email: this.request.email,
        };
        this.loading = true;
        this.memberService.InviteMember(invitationModel).subscribe(res => {
          if (res) {
            this.updateReqStatus(2);
            this.step1 = false;
            this.step2 = true;
          }
        })
      } else {
        toast({ html: 'Unable to Send the Registration Link.Please Try again!', classes: 'red' });
      }
    }
    else if (valueEmitted.title == 'Resend-RegistrationLink') {
      if (valueEmitted.status) {
        this.step1 = false;
        this.step2 = true;
      }
    }
    else if (valueEmitted.title == 'Invitation') {
      if (valueEmitted.status) {
        this.updateReqStatus(2);
        toast({ html: 'Member Invitaion sent successfully!', classes: 'green' });
        this.backClicked();
      } else {
        toast({ html: 'Unable to send Invitation Link!', classes: 'red' });
        this.backClicked();
      }
    }
    else if (valueEmitted.title == 'DeclineMsg') {
      if (valueEmitted.status) {
        this.updateReqStatus(4);
        toast({ html: 'Message sent successfully!', classes: 'green' });
        this.backClicked();
      } else {
        toast({ html: 'Unable to send Message!', classes: 'red' });
        this.backClicked();
      }
    }
    else {
      if (valueEmitted.status) {
        toast({ html: 'Message sent successfully!', classes: 'green' });
        this.backClicked();
      } else {
        toast({ html: 'Unable to send Message to client!', classes: 'red' });
        this.backClicked();
      }
    }
    this.contact = false;
  }

  deleteRequest() {
    if (this.SQLDB) {
      this.sqlRegistration.deleteRequet(this.request.ID).subscribe(x => {
        if (x) toast({ html: 'The request has been deleted!', classes: 'red' });
        if(this.sendEmail){
          this.contactDetail.firstName = this.SQLDB? this.request.Fname : this.request.lastName;
          this.contactDetail.firstName = this.SQLDB? this.request.Lname : this.request.firstName;
          this.contactDetail.phoneNo = this.request.mobile;
          this.contactDetail.email = this.request.email;
          this.message.subject = 'Registration Request Deleted';
          this.contact = true;
        }
        this.backClicked();
      });
    }
  }

  Delete() {
    // this.sqlRegistration.deleteRequet(this.request.ID).subscribe(x => {
    // 
    //   if(x) toast({ html: 'The request deleted!', classes: 'red' });
    // });
    this.backClicked();
  }

  updateReqStatus(status) {
    const reqStatusModel = {
      ID: this.request.ID,
      status: status
    }
    this.sqlRegistration.updateRequestStatus(reqStatusModel).subscribe(x => {
      if (x) this.request.status = status
    });
  }

  onSendEmail() {
    this.sendEmail = !this.sendEmail;
  }

  closeModal() {
    $('.modal').modal('close');
  }

  backClicked() {
    this.back.emit();
  }

}
