// import { ValueTransformer } from '@angular/compiler/src/util';
import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { SCISApplicationModel } from 'src/app/models/SCISApplicationModel';

@Injectable({
  providedIn: 'root'
})
export class ScisApplicationService {

  constructor(private db: AngularFirestore) { }

  applicationListRef: AngularFirestoreCollection<any>;
  applicationRefs: AngularFirestoreDocument<any>;

  saveApplication(model: SCISApplicationModel) {

    // let newApp = {
    //   id: this.db.createId(),
    //   familyName: model.familyName,
    //   givenName: model.givenName,
    // }

    // if(model.sameMailingAddress){
    //   newApp['mailing_number'] = model.sameMailingAddress;
    // }

    var personalInfo;
    var firstNationInfo;
    var documents;
    var adoptionInfo;
    var familyInfo;
    var photoAndSignature;
    var declaration;
    var IRA;

    if (model.sameMailingAddress) {
      personalInfo = {
        familyName: model.familyName,
        givenName: model.givenName,
        birth_familyName: model.birth_familyName,
        aliasName: model.aliasName,
        gender: model.gender,
        dob: model.dob ? model.dob : '',
        number: model.number,
        street: model.street,
        apartment: model.apartment,
        POBox: model.POBox,
        city: model.city,
        province_state: model.province_state,
        //state: model.state,
        postalCode: model.postalCode,
        telephone: model.telephone,
        telephone2: model.telephone2,
        email: model.email,
        sameMailingAddress: model.sameMailingAddress,
      }
    } else {
      personalInfo = {
        familyName: model.familyName,
        givenName: model.givenName,
        birth_familyName: model.birth_familyName,
        aliasName: model.aliasName,
        gender: model.gender,
        dob: model.dob ? model.dob : '',
        number: model.number,
        street: model.street,
        apartment: model.apartment,
        POBox: model.POBox,
        city: model.city,
        province_state: model.province_state,
        //state: model.state,
        postalCode: model.postalCode,
        telephone: model.telephone,
        telephone2: model.telephone2,
        email: model.email,
        sameMailingAddress: model.sameMailingAddress,
        mailing_number: model.mailing_number,
        mailing_street: model.street,
        mailing_apartment: model.apartment,
        mailing_POBox: model.POBox,
        mailing_city: model.city,
        mailing_province_state: model.mailing_province_state,
        //mailing_state: model.state,
        mailing_postalCode: model.postalCode,
      }
    };

    firstNationInfo = {
      bandNumber: model.bandNumber,
      bandName: model.bandName,
    };

    documents = {
      proofOfBirthDocument: model.proofOfBirthDocument,
      nameLinkingDocumnet: model.nameLinkingDocumnet,
      supportingIdentityDocuments: model.supportingIdentityDocuments,
    };

    if (model.adopted) {
      adoptionInfo = {
        adopted: model.adopted,
        ISNEntitlement: model.ISNEntitlement,
        adoptiveMother: model.adoptiveMother,
        adoptiveFather: model.adoptiveFather,
        birthMother: model.birthMother,
        birthFather: model.birthFather,
      }
    } else {
      adoptionInfo = {
        adopted: model.adopted,
      }
    }

    familyInfo = {
      father: model.father,
      mother: model.mother,
      maternal_grandparents: model.maternal_grandparents,
      paternal_grandparents: model.paternal_grandparents,
      other_familyMembers: model.other_familyMembers
    }

    if (model.SCISRequested) {
      photoAndSignature = {
        SCISRequested: model.SCISRequested,
        photo: model.photo,
        photoBy: model.photoBy,
        photoAddress: model.photoAddress,
        photoDate: model.photoDate ? model.photoDate : '',
        signature: model.signature
      }
    } else {
      photoAndSignature = {
        SCISRequested: model.SCISRequested,
        signature: model.signature
      }
    }

    declaration = {
      applicantName: model.applicantName,
      signatureDate: model.signatureDate
    }

    if (model.IRA_assisted) {
      IRA = {
        IRA_assisted: model.IRA_assisted,
        IRA_name: model.IRA_name,
        bandNo_initiatorCode: model.bandNo_initiatorCode,
      }
    } else {
      IRA = {
        IRA_assisted: model.IRA_assisted
      }
    }

    var application = {
      id: this.db.createId(),
      PersonalInformation: personalInfo,
      FirstNationChoice: firstNationInfo,
      Documents: documents,
      AdoptionInformation: adoptionInfo,
      FamilyInformation: familyInfo,
      PhotoAndSignature: photoAndSignature,
      declaration: declaration,
      IRA: IRA
    }

    // if (model.sameMailingAddress) {
    //   application = {
    //     id: this.db.createId(),
    //     PersonalInformation:personalInfo,
    //     FirstNationChoice: firstNationInfo,
    //     Documents: documents,
    //     AdoptionInformation: adoptionInfo,
    //   }
    // } else {
    //   application = {
    //     id: this.db.createId(),
    //     familyName: model.familyName,
    //     givenName: model.givenName,
    //     birth_familyName: model.birth_familyName,
    //     aliasName: model.aliasName,
    //     gender: model.gender,
    //     number: model.number,
    //     street: model.street,
    //     apartment: model.apartment,
    //     POBox: model.POBox,
    //     city: model.city,
    //     province_state: model.province_state,
    //     //state: model.state,
    //     postalCode: model.postalCode,
    //     telephone: model.telephone,
    //     telephone2: model.telephone2,
    //     email: model.email,
    //     sameMailingAddress: model.sameMailingAddress,
    //     mailingAddress: {
    //       mailing_number: model.mailing_number,
    //       mailing_street: model.street,
    //       mailing_apartment: model.apartment,
    //       mailing_POBox: model.POBox,
    //       mailing_city: model.city,
    //       mailing_province_state: model.mailing_province_state,
    //       //mailing_state: model.state,
    //       mailing_postalCode: model.postalCode,
    //     },
    //     bandNumber: model.bandNumber,
    //     bandName: model.bandName,
    //     proofOfBirthDocument: model.proofOfBirthDocument,
    //     nameLinkingDocumnet: model.nameLinkingDocumnet,
    //     supportingIdentityDocuments: model.supportingIdentityDocuments
    //   }
    // }

    return this.db.collection('SCIS-Application').doc(application.id).set(application);
  }

  getApplications() {
    this.applicationListRef = this.db.collection('PSSSP-Application');
    return this.applicationListRef;
  }

}
