import { Component, OnInit } from '@angular/core';
import { NominationService } from 'src/app/services/firebase/nomination.service';
import { ElectionService } from 'src/app/services/firebase/election.service';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { AuthService } from 'src/app/services/firebase/auth.service';

declare var $: any;

@Component({
  selector: 'app-nomination-list',
  templateUrl: './nomination-list.component.html',
  styleUrls: ['./nomination-list.component.css']
})

export class NominationListComponent implements OnInit {

  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  model: any[]; dataModel: any[]; electionModel: any;
  showLoader = false;
  isUnderAge;

  constructor(
    private nominationService: NominationService,
    private electionService: ElectionService,
    private location: Location,
    private authService: AuthService) { }

  ngOnInit() {
    this.model = [];
    this.showLoader = true;
    this.nominationService.getNominationList().valueChanges().subscribe(data => {
      if (data.length > 0) {

        this.dataModel = data;
        this.dataModel.forEach(element => {
          const endDate = new Date(element.endDate);
          const today = new Date(new Date().toLocaleDateString());
          if (endDate >= today) {
            this.electionService.getElectionById(element.electionId).valueChanges().subscribe(data => {
              this.electionModel = data;
              let d = new Date();
              let curr_date = d.getDate();
              let curr_month = d.getMonth();
              let curr_year = d.getFullYear()
              // check if this user's age qualify for each nomination to nominate other
              if (new Date(this.authService.getGlobalUser().DOB) < new Date(curr_year - this.electionModel.voterMinAge, curr_month + 1, curr_date)) {
                this.isUnderAge = false;
                this.model.push(element);
              }
              if (this.model.length <= 0) {
                this.isUnderAge = true;
              }
            });
          }
        });
      }
      this.showLoader = false;
    });

    $(document).ready(function () {
      $('.tabs').tabs();
    });
  }

  backClicked() {
    this.location.back();
  }

}
