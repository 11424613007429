<div class="row ">
    <div class="col s12 m2 l1" *ngIf="user">
        <app-side-menus [user]="user"></app-side-menus>
    </div>

    <div class="col s12 m10 l2 xl2">
        <div class="submenu-sidenav hide-on-med-and-down">
            <ul id="slide-out" class="submenu-contents ">
               
                <div class="hide-on-med-and-down">
                    <ul style="cursor: pointer;">
                        <ng-template [ngTemplateOutlet]="menus"></ng-template>
                    </ul>
                </div>

                <ng-template #menus class="">
                    <li>
                        <ul class="collapsible">
                            <li [ngClass]="{'active': getPermission('RegisteredMembers')}" (click)="backToMenu()">
                                <div class="collapsible-header" (click)="getRegistredMembers()">
                                    <i class="material-symbols-outlined black-text">how_to_reg</i>
                                    <a class="black-text">Registered Members</a>
                                </div>
                                <div class="collapsible-body"></div>
                            </li>
                            <li (click)="backToMenu()">
                                <div class="collapsible-header" (click)="getRegistrationRequests()">
                                    <i class="material-symbols-outlined black-text">group_add</i>
                                    <a class="black-text">Registration Requests</a>
                                </div>
                                <div class="collapsible-body"
                                    [ngClass]="{'disabledDiv': !getPermission('RegistrationRequest')}">
                                    <ul>
                                        <li><a>
                                                <label>
                                                    <input type="radio" name="filter" [checked]='reqFilters.pending'
                                                        (click)="filterRequests('pending')" />

                                                    <i class="material-symbols-outlined left"
                                                        (click)="filterRequests('pending')">
                                                        pending_actions
                                                    </i> <a>New Applicant</a>
                                                </label>
                                            </a></li>
                                        <li><a>
                                                <label>
                                                    <input type="radio" name="filter" [checked]='reqFilters.invited'
                                                        (click)="filterRequests('invited')" />
                                                    <i class="material-symbols-outlined left"
                                                        (click)="filterRequests('invited')">
                                                        task_alt
                                                    </i> <a>Invited</a>
                                                </label>
                                            </a></li>
                                        <li><a>
                                                <label>
                                                    <input type="radio" name="filter" [checked]='reqFilters.declined'
                                                        (click)="filterRequests('declined')" />
                                                    <i class="material-symbols-outlined left"
                                                        (click)="filterRequests('declined')">
                                                        thumb_down_alt
                                                    </i> <a>Declined</a>
                                                </label>
                                            </a></li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="collapsible-header" (click)="getMemberVerification()"><i
                                        class="material-symbols-outlined black-text">verified</i>
                                    <a class="black-text">Member Verfication</a>
                                </div>
                            </li>
                            <li>
                                <div class="collapsible-header" (click)="getDisabledMembers()"><i
                                        class="material-symbols-outlined black-text">person_off</i>
                                    <a class="black-text">Disabled Members</a>
                                </div>
                            </li>
                            <li>
                                <div class="collapsible-header" (click)="getUpcomingBirthdays()"><i
                                        class="material-symbols-outlined black-text">cake</i>
                                    <a class="black-text">Birthdays</a>
                                </div>
                                <div class="collapsible-body" [ngClass]="{'disabledDiv': !getPermission('Birthdays')}">
                                    <ul>
                                        <li><a>
                                                <label>
                                                    <input name="birthday" type="radio"
                                                        [checked]='birthdayFilters.today'
                                                        (click)="filterBirthdays('today')" />
                                                    <span><i class="material-symbols-outlined left">
                                                            cake
                                                        </i>Today</span>
                                                </label>
                                            </a></li>
                                        <li><a>
                                                <label>
                                                    <input name="birthday" type="radio"
                                                        [checked]='birthdayFilters.thisWeek'
                                                        (click)="filterBirthdays('thisWeek')" />
                                                    <span><i class="material-symbols-outlined left">
                                                            cake
                                                        </i>This Week</span>
                                                </label>
                                            </a></li>
                                        <li><a>
                                                <label>
                                                    <input name="birthday" type="radio"
                                                        [checked]='birthdayFilters.thisMonth'
                                                        (click)="filterBirthdays('thisMonth')" />
                                                    <span><i class="material-symbols-outlined left">
                                                            cake
                                                        </i>This Month</span>
                                                </label>
                                            </a></li>
                                    </ul>
                                </div>
                            </li>
                            <li>
                                <div class="collapsible-header" (click)="searchMembers()"><i
                                        class="material-symbols-outlined black-text">search</i>
                                    <a *ngIf="nationOfUse !== 'Batc'" class="black-text">Search Members</a>
                                    <a *ngIf="nationOfUse === 'Batc'" class="black-text">Search Clients</a>
                                </div>
                            </li>
                            <li>
                                <div class="collapsible-header" (click)="addMembers()"><i
                                        class="material-symbols-outlined black-text">person_add</i>
                                    <a *ngIf="nationOfUse !== 'Batc'" class="black-text">Add Members</a>
                                    <a *ngIf="nationOfUse === 'Batc'" class="black-text">Add Clients</a>
                                </div>
                            </li>
                            <li>
                                <div class="collapsible-header" (click)="profileUpdates()"><i
                                        class="material-symbols-outlined black-text">edit_note</i>
                                    <a>Profile Updates</a>
                                </div>
                            </li>
                            <li>
                                <div class="collapsible-header" (click)="demographics()"><i
                                        class="material-symbols-outlined black-text">insert_chart</i>
                                    <strong>Demographics</strong>
                                </div>
                            </li>
                        </ul>
                    </li>
                </ng-template>
            </ul>
        </div>
    </div>

    <div class="col s12 m12 l9 xl9" style="position: relative;" *ngIf="component">

        <div class="hide-on-large-only drop-down valign-wrapper submenu-contents">
            <a class='col s12 m12 dropdown-trigger btn-flat hide-on-large-only black-text' data-target='menu-dropdown'>
                <i class="material-icons left">{{selectedMenu.icon}}</i>{{selectedMenu.name}}<i
                    class="material-icons col s2 right">expand_more</i></a>
            <!-- Dropdown Structure -->
            <ul id='menu-dropdown' class='dropdown-content col s12 m12 '>
                <ng-template [ngTemplateOutlet]="menus"></ng-template>
            </ul>
        </div>

        <div class="col s12" [ngSwitch]="component">
            <div *ngSwitchCase="'noPermission'">
                <div class="row">
                    <div class="col s12">
                        <div class="card center-align">
                            <div class="card-content">
                                <i class="material-symbols-outlined red-text" style="font-size: 100px;">
                                    no_accounts
                                </i>
                                <h3 style="font-size: 60px; font-weight: bolder;">403</h3>
                                <h5>Opps! Access Not Granted!</h5>
                                <br />
                                <h6>You don't have permission to access this page.</h6>
                                <h6>Please contact the administrator if you think this is Mistake.</h6>
                            </div>

                            <div class="card-action">
                                <a (click)="backClicked()" class="btn white-text" [ngClass]="'btn ' + buttonType"
                                    style="border-radius: 12px;">GO TO HOMEPAGE<i
                                        class="material-symbols-outlined left">
                                        home
                                    </i></a>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <div *ngSwitchCase="'requests'">
                <app-registration-request [filters]=reqFilters [menuClicked]=menuClicked></app-registration-request>
            </div>
            <div *ngSwitchCase="'members'">
                <app-registred-clients [filters]=membersFilter [menuClicked]=menuClicked></app-registred-clients>
            </div>
            <div *ngSwitchCase="'verification'">
                <app-member-verification></app-member-verification>
            </div>
            <div *ngSwitchCase="'disabled'">
                <app-disabled-members></app-disabled-members>
            </div>
            <div *ngSwitchCase="'birthdays'">
                <app-upcoming-birthdays [filters]=birthdayFilters></app-upcoming-birthdays>
            </div>
            <div *ngSwitchCase="'search'">
                <app-search-members></app-search-members>
            </div>
            <div *ngSwitchCase="'addMembers'">
                <app-add-members></app-add-members>
            </div>
            <div *ngSwitchCase="'profileUpdates'">
                <app-members-profile-updates></app-members-profile-updates>
            </div>
            <div *ngSwitchCase="'demographics'">
                <app-member-demographics></app-member-demographics>
            </div>

            <div *ngSwitchDefault>
                <div class="row">
                    <div class="col s12">
                        <div class="card center-align">
                            <div class="card-content">
                                <i class="material-symbols-outlined blue-text accent-1-text" style="font-size: 100px;">
                                    flutter_dash
                                </i>
                                <h1 style="font-size: 100px; font-weight: bolder;">404</h1>
                                <h4>Opps! Page Not Found</h4>
                                <br />
                                <h6>The page you are looking for might have been removed has its name changed or is
                                    temporarily unavailable.</h6>
                            </div>

                            <div class="card-action">
                                <a (click)="backClicked()" class="btn-large blue darken-2 white-text"
                                    style="border-radius: 12px;">GO TO HOMEPAGE<i
                                        class="material-symbols-outlined left">
                                        home
                                    </i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="section"></div>
    </div>
</div>