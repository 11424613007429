<div class="row center-align">
  <h5><strong>My Account</strong></h5>
</div>

<div class="row col s12" *ngIf="profile"> 
  <div class="col l1"></div>
  <div class="col s12 l10">

    <div *ngIf="member" class="row col s12 collection">
      <!-- <app-personal-info [model]="member" [dependant]="dep" (addressUpdated)="parentEventHandlerFunction($event)"></app-personal-info> -->
      <a class="collection-item">
        <div>Given Name:<a class="secondary-content blue-text"><strong>
              {{(member?.GivenName !== null) ? (member?.GivenName | NameStars) : ""}}</strong> </a></div>
      </a>
      <a class="collection-item">
        <div>Middle Name:<a class="secondary-content blue-text"><strong>
              {{(member?.middle_name !== null) ? (member?.middle_name | NameStars) : ""}}</strong> </a></div>
      </a>
      <a class="collection-item">
        <div>Last Name:<a class="secondary-content blue-text"><strong>{{(member?.LastName !== null) ? (member?.LastName | NameStars) : ""}}</strong></a>
        </div>
      </a>
      <a class="collection-item">
        <div>DOB:<a class="secondary-content black-text">{{ member?.DOB | date: 'dd/MMM/yyyy' }}</a></div>
      </a>
      <a class="collection-item">
        <div>Nation:<a class="secondary-content black-text">{{ member?.Band }}</a></div>
      </a>
      <a class="collection-item">
        <div>House Number:<a class="secondary-content black-text">{{ member?.HouseNumber }}</a></div>
      </a>
      <a class="collection-item">
        <div>Address:<a class="secondary-content black-text">{{ member?.MailAddress }}</a></div>
      </a>

      <a class="collection-item">
        <div>City:<a class="secondary-content black-text">{{ member?.city }}</a></div>
      </a>
      <a class="collection-item">
        <div>Province:<a class="secondary-content black-text">{{ member?.province }}</a></div>
      </a>

      <a class="collection-item">
        <div>Postal Code:<a class="secondary-content black-text">{{ member?.PostalCode }}</a></div>
      </a>
      <a class="collection-item">
        <div>Home Phone:<a class="secondary-content black-text">{{ member?.Telephone }} </a></div>
      </a>
      <a class="collection-item">
        <div>Mobile:<a class="secondary-content black-text">{{ member?.cell_phone_num }}</a></div>
      </a>
      <a class="collection-item">
        <div>Email:<a class="secondary-content black-text">{{ member?.Email }}</a></div>
      </a>
    </div>

    <div class="row">
      <button type="button" class="col s12 btn waves-effect green" (click)="onUpdateProfile()">Update Profile</button>
    </div>

  </div>
  <div class="col l1"></div>
</div>


<div class="row col s12" *ngIf="updateProfile">
  <div class="col l1 "></div>
  <div class="col s12 l10">

    <form [formGroup]="employeeForm" (ngSubmit)="updateEmployee(employeeForm.value)" *ngIf="model">

      <div class="row card-panel white">
        <h6 class="center" style="margin-bottom: 25px; margin-top: 15px;"><strong>General Information</strong></h6>

        <div class="input-field col s12">
          <i class="material-icons prefix teal-text">portrait</i>
          <input id="fnme" type="text" [value]="model.GivenName" disabled />
          <!-- <label>Given Name</label> -->
        </div>

        <div class="input-field col s12">
          <i class="material-icons prefix blue-text">portrait</i>
          <input id="lnme" type="text" [value]="model.LastName" disabled />
          <!-- <label>Last Name</label> -->
        </div>

        <div class="input-field col s12">
          <i class="material-icons prefix blue-text">email</i>
          <input id="email" type="text" [value]="model.Email" disabled />
          <!-- <label>Email</label> -->
        </div>

        <div class="input-field col s12 l12">
          <i class="material-icons prefix pink-text">work</i>
          <input id="jobTitle" type="text" formControlName='jobTitle' required class="validate" autocomplete="off" />
          <label for="jobTitle">Job Title</label>
          <div *ngIf="(jobTitle.dirty || jobTitle.touched) && jobTitle.invalid">
            <small *ngIf="jobTitle.errors.required" class="red-text"> The Job Title is required. </small>
          </div>
        </div>

        <div class="input-field col s12 l12">
          <i class="material-icons prefix teal-text">call</i>
          <!-- <input id="workPhone" type="text" formControlName='workPhone' [textMask]="{mask: phoneNumberMask}" required
            class="validate" autocomplete="off" /> -->
            <input id="workPhone" type="text" formControlName='workPhone'  required
            class="validate" autocomplete="off" />
          <label for="workPhone">Work Phone</label>
          <div *ngIf="(workPhone.dirty || workPhone.touched) && workPhone.invalid">
            <small *ngIf="workPhone.errors.required" class="red-text"> The Work Phone is required. </small>
          </div>
        </div>

        <div class="input-field col s12 l12">
          <i class="material-icons prefix purple-text text-darken-2">stay_current_portrait</i>
          <!-- <input id="mobilePhone" type="text" [textMask]="{mask: phoneNumberMask}" formControlName='mobilePhone'
            required class="validate" autocomplete="off" /> -->
            <input id="mobilePhone" type="text"  formControlName='mobilePhone'
            required class="validate" autocomplete="off" />
          <label for="mobilePhone">Mobile Phone</label>
          <div *ngIf="(mobilePhone.dirty || mobilePhone.touched) && mobilePhone.invalid">
            <small *ngIf="mobilePhone.errors.required" class="red-text"> The Mobile Phone is required. </small>
          </div>
        </div>

        <div class="input-field col s12 l12">
          <i class="material-icons prefix green-text">description</i>
          <textarea id="description" formControlName='description' required class=" validate materialize-textarea"
            autocomplete="off"></textarea>
          <label for="description">Responibilities</label>
          <div *ngIf="(description.dirty || description.touched) && description.invalid">
            <small *ngIf="description.errors.required" class="red-text"> The Job Responsibilities is required. </small>
          </div>
        </div>
      </div>

      <div class="row card-panel white" *ngIf="isManager || (canManageContact && accountOwner)">
        <h6 class="center" style="margin-bottom: 15px; margin-top: 15px;"><strong>Contact Settings</strong></h6>
        <div class="col s12">
          <p><strong>Show Mobile Phone</strong></p>
          <div class="col s12 m6 l4">
            <label>
              <input type="radio" name="group1" [checked]="showMobileNo === 'Public'" (click)="publicMobileClick()" />
              <span><strong>General Public</strong></span>
            </label>
          </div>
          <div class="col s12 m6 l4">
            <label>
              <input type="radio" name="group1" [checked]="showMobileNo === 'Members'" (click)="membersMobileClick()" />
              <span class="text"><strong>Members Only</strong></span>
            </label>
          </div>
          <div class="col s12 m6 l4">
            <label>
              <input type="radio" name="group1" [checked]="showMobileNo === 'Private'" (click)="privateMobileClick()" />
              <span class="red-text"><strong>Do Not Show </strong></span>
            </label>
          </div>
        </div>

        <div class="col s12">
          <p><strong>Show Office Phone</strong></p>
          <div class="col s12 m6 l4">
            <label>
              <input type="radio" value="Yes" name="group0" [checked]="showOfficeNo === 'Public'"
                (click)="publicOfficeClick()" />
              <span class="text"><strong>General Public</strong></span>
            </label>
          </div>
          <div class="col s12 m6 l4">
            <label>
              <input type="radio" value="No" name="group0" [checked]="showOfficeNo === 'Members'"
                (click)="membersOfficeClick()" />
              <span class="text"><strong>Members Only</strong></span>
            </label>
          </div>
          <div class="col s12 m6 l4">
            <label>
              <input type="radio" name="group0" [checked]="showOfficeNo === 'Private'" (click)="privateOfficeClick()" />
              <span class="red-text"><strong>Do Not Show</strong></span>
            </label>
          </div>
        </div>

        <div class="col s12">
          <p><strong>Show Email Address</strong></p>
          <div class="col s12 m6 l4">
            <label>
              <input type="radio" name="group2" [checked]="showEmailAdr === 'Public'" (click)="publicEmailClick()" />
              <span class="text"><strong>General Public</strong></span>
            </label>
          </div>
          <div class="col s12 m6 l4">
            <label>
              <input type="radio" name="group2" [checked]="showEmailAdr === 'Members'" (click)="membersEmailClick()" />
              <span class="blue-text"><strong>Members Only</strong></span>
            </label>
          </div>
          <div class="col s12 m6 l4">
            <label>
              <input type="radio" name="group2" [checked]="showEmailAdr === 'Private'" (click)="privateEmailClick()" />
              <span class="red-text"><strong>Do Not Show</strong></span>
            </label>
          </div>
        </div>

        <div class="col s12">
          <p><strong>Allow Contact Us Messages </strong></p>
          <div class="col s12 m6 l4">
            <label>
              <input type="radio" name="group3" [checked]="allowContact === 'Public'" (click)="publicContactClick()" />
              <span class="text"><strong>General Public</strong></span>
            </label>
          </div>
          <div class="col s12 m6 l4">
            <label>
              <input type="radio" name="group3" [checked]="allowContact === 'Members'"
                (click)="membersContactClick()" />
              <span class="text"><strong>Members Only</strong></span>
            </label>
          </div>
          <div class="col s12 m6 l4">
            <label>
              <input type="radio" name="group3" [checked]="allowContact === 'Private'"
                (click)="privateContactClick()" />
              <span class="red-text"><strong>Do Not Allow</strong></span>
            </label>
          </div>
        </div>
        <div class="row">
          <br />
        </div>
      </div>

      <div class="row card-panel white" *ngIf="isManager">
        <h6 class="center" style="margin-bottom: 15px; margin-top: 15px;"><strong>Permissions</strong></h6>
        <div class="col s12">

          <div class="row">
            <div class="col s12 l6">
              <label>
                <input type="checkbox" [checked]='manageAccount' (click)='manageAccountClicked()' />
                <span><strong>Update Account Information</strong></span>
              </label>
            </div>

            <div class="col s12 l6">
              <label>
                <input type="checkbox" [checked]='manageContact' (click)='manageContactClicked()' />
                <span><strong> Update Contact Settings</strong></span>
              </label>
            </div>

            <div class="col s12 l6">
              <label>
                <input type="checkbox" [checked]='manageDepartmentInfo' (click)='manageDepartmentInfoClicked()' />
                <span><strong> Update Department Information</strong></span>
              </label>
            </div>

            <div class="col s12 l6">
              <label>
                <input type="checkbox" [checked]='manageDepartmentNews' (click)='manageDepartmentNewsClicked()' />
                <span><strong>Manage Department News</strong></span>
              </label>
            </div>

            <div class="col s12 l6">
              <label>
                <input type="checkbox" [checked]='manageDepartmentEmployees'
                  (click)='manageDepartmentEmployeesClicked()' />
                <span><strong> Manage Department Employees</strong></span>
              </label>
            </div>

            <div class="col s12 l6">
              <label>
                <input type="checkbox" [checked]='manageSubDepartments' (click)='manageSubDepartmentsClicked()' />
                <span> <strong> Manage Sub Department</strong></span>
              </label>
            </div>
          </div>
        </div>
      </div>

      <button type="submit" *ngIf="isManager || (canManageContact && accountOwner)" [disabled]="!employeeForm.valid"
        class="btn col s12 green white-text">
        Save Changes <i class="material-icons white-text right">send</i>
      </button>
    </form>

  </div>
  <div class="col l1 "></div>
</div>