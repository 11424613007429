<div class="section"></div>

<div class="row">

  <div class="row col s12 p-b-5">

    <app-firebase-loading *ngIf="!model"></app-firebase-loading>

    <!-- <div class="col l2"></div> -->
    <div class="col s12 l12" *ngIf="model">
      <app-event-detail-view class="row col s12" [model]="model"></app-event-detail-view>

      <!-- <a class="col s10 push-s1 btn" [ngStyle]="{'background-color': themeColor}" (click)="back()"><i
        class="material-icons left">arrow_back_ios</i>
      Back To Previous Page </a>  -->

    </div>

  </div>
</div>