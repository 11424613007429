
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ApiKeyManagerService } from './apiKeyManager.Service';
import { PseCoursesToRegisterModel } from 'src/app/models/PseCoursesToRegisterModel';
import { PseCoursesModel } from 'src/app/models/PseCoursesModel';

@Injectable({
  providedIn: 'root'
})
export class PseRegistrationService {

    constructor(private httpClient: HttpClient, private keyManager: ApiKeyManagerService) { }

  
    getCourseList(): Observable<any[]> {
      const url = environment.firstNation.apiUrl + 'api/PseCourseRegistration/GetCoursesList/' ;
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
      return this.httpClient.get<any[]>( url, { headers });
    }

    
    getCurrentInstitution(id: number): Observable<any> {
      const url = environment.firstNation.apiUrl + 'api/PseCourseRegistration/GetCurrentInstitution/' + id ;
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
      return this.httpClient.get<any>( url, { headers });
    }

 
    getCurrentApplicationID(id: number): Observable<any> {
      const url = environment.firstNation.apiUrl + 'api/PseCourseRegistration/GetCurrentApplicationID/' + id ;
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
      return this.httpClient.get<any>( url, { headers });
    }

    getRegisteredCourses(id: number): Observable<any[]> {
      const url = environment.firstNation.apiUrl + 'api/PseCourseRegistration/GetRgisteredCourses/' + id ;
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
      return this.httpClient.get<any[]>( url, { headers });
    }
    

    
    saveCoursesToRegister(model: PseCoursesToRegisterModel[]): Observable<any> {
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
      return this.httpClient.post<any>(
        environment.firstNation.apiUrl + 'api/PseCourseRegistration/SaveCoursesToRegister', JSON.stringify(model), { headers });
    }



    //-================================================================================
    
    saveCourses(model: PseCoursesModel[]): Observable<any> {
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
      return this.httpClient.post<any>(
        environment.firstNation.apiUrl + 'api/PseCourseRegistration/SaveCourses', JSON.stringify(model), { headers });
    }
    

    getCourses(id: number): Observable<any[]> {
      const url = environment.firstNation.apiUrl + 'api/PseCourseRegistration/GetCourses/'  + id ;
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
      return this.httpClient.get<any[]>( url, { headers });
    }


}

