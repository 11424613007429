
export class IncomeSupportModel {
    firstName: '';
    lastName: '';
    isn: '';
    bandName: '';
    maritalStatus: '';
    hasSpouse: boolean;
    province: '';
    address: '';
    city: '';
    postalCode: '';
    mailingAddress: '';
    houseNo: '';
    homeNumber: '';
    mobile: '';
    healthCareNo: '';
    dob: Date;
    SIN: '';
    
    previousAddress: {
        address: '';
        province: '';
        city: '';
        postalCode: '';
        startDate: Date;
        endDate: Date;
    };
    recentEducation: {
        education: '';
        startDate: Date;
        endDate: Date;
    };
    employment: {
        presenltyEmployed: '',
        employerName: '';
        contactName: '';
        address: '';
        position: '';
        startDate: Date;
        endDate?: Date;
    }
   
    assets: [Asset];
    children: [Children];
    otherFamilyMembers: [OtherFamilyMembers];
    incomes: [Income];
    dateOfLastAssistance: Date;
    administrativeAuthority: '';
    amount: '';
    signature: '';
    date: Date;

    spouseFirstName: '';
    spouseLastName: '';
    spouseIsn: '';
    spouseBand: '';
    spouseProvince: '';
    spouseCity: '';
    spouseMobile: '';
    spouseEmail: '';
    spouseHealtCareNo: '';
    spouseDob: Date;
    spouseSIN: ''; 
    
    // spousePreviousAddress: {
    //     address: '';
    //     province: '';
    //     city: '';
    //     postalCode: '';
    //     startDate: Date;
    //     endDate: Date;
    // };
    spouseRecentEducation: {
        education: '';
        startDate: Date;
        endDate: Date;
    };
    spouseEmployment: {
        presenltyEmployed: '',
        employerName: '';
        contactName: '';
        address: '';
        position: '';
        startDate: Date;
        endDate?: Date;
    }
    // spousePreviousEmployment: {
    //     employerName: '';
    //     contactName: '';
    //     address: '';
    //     position: '';
    //     startDate: Date;
    //     endDate: Date;
    // };
    // spousePresentEmployment: {
    //     employerName: '';
    //     contactName: '';
    //     address: '';
    //     position: '';
    //     startDate: Date;
    //     endDate: Date;
    //     iscurrent: boolean;
    // };
    spouseIncomes: [Income];
    spouseSignature: '';
    spouseDate: Date;
    witnessSignature: '';
    witnessDate: Date;
    //applicationReceived: Date;
    //reviwedBy: '';
    //comments: '';
    messageToAdministrator: ';'
    documnet: '';
    pdfDoc: '';
}

export class Signatures {
    signature: string;
    date:  Date;
    spouseSignature: string;
    spouseDate: Date;
    witnessSignature: string;
    witnessDate: Date
}

export class Children {
    first_name: '';
    last_name: '';
    relation: '';
    health_care_no: '';
    dob: Date;
    ISN: '';
    band: '';
    school: '';
}

export class OtherFamilyMembers {
    first_name: '';
    last_name : '';
    relationship: "";
}

export class Asset {
    asset: '';
    amount:  number;
}

export class Income {
    income: "";
    amount: number;
    date_recevied: Date;
}
