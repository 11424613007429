<div class="section"></div>

<div class="row col s12">
  <div class="col l2 "></div>
  <div class="col s12 m12 l8">
    <ul class="collection" *ngIf="member">
      <li class="collection-item">
        <div>
          <strong>
            ID Type : {{ member.ID1_type }}
          </strong>
          <a [routerLink]="['/member/profile/documents-preview',1443]" class="secondary-content"><i
              class="material-icons">remove_red_eye</i> Front </a>
        </div>
      </li>

      <li class="collection-item">
        <div>
          <strong>
            Expire On : {{ member.ID1_expire | date }}
          </strong>
          <a *ngIf="member.ID1_back" [routerLink]="['/member/profile/documents-preview',member.ID1_back]"
            class="secondary-content"><i class="material-icons">remove_red_eye</i> Back </a>
          <a *ngIf="!member.ID1_back" disabled class="secondary-content grey-text"><i
              class="material-icons">remove_red_eye</i> Back </a>
        </div>
      </li>

      <li class="collection-item">
        <div>
          <strong> ID 2 Type : </strong>
          <strong *ngIf="member.ID2"> {{ member.ID2_type }} </strong>
          <strong *ngIf="!member.ID2"> </strong>
          <a *ngIf="member.ID2" [routerLink]="['/member/profile/documents-preview',member.ID2]"
            class="secondary-content tooltipped" data-position="bottom" data-tooltip="Preview Document"><i
              class="material-icons">remove_red_eye</i> Front </a>
          <a *ngIf="!member.ID2" disabled class="secondary-content grey-text"><i
              class="material-icons">remove_red_eye</i> Front </a>
        </div>
      </li>

      <li class="collection-item">
        <div>
          <strong>
            Expire On : {{ member.ID2_expire | date }}
          </strong>
          <a *ngIf="member.ID2_back" [routerLink]="['/member/profile/documents-preview',member.ID2_back]"
            class="secondary-content"><i class="material-icons">remove_red_eye</i> Back </a>
          <a *ngIf="!member.ID2_back" disabled class="secondary-content grey-text"><i
              class="material-icons">remove_red_eye</i> Back </a>
        </div>
      </li>
    </ul>
  </div>
  <div class="col l2"></div>
</div>

<div class="row col s12">
  <div class="col l2 "></div>
  <div class="col s12 m12 l8">
    <a class="btn-flat center blue col s12 white-text" (click)="backClicked()"><i
        class="material-icons left">keyboard_backspace</i> Back To My Profile</a>
  </div>
  <div class="col l2"></div>
</div>