<div class="row center-align">
  <br />
  <h6 class="red-text center"><strong> Please declare only the income received between the 16th of
      {{previousMonth}}/{{previousYear}} and
      15th of {{currentMonth}}/{{currentYear}} . </strong></h6>
</div>

<div class="row">
  <div class="col l1"></div>
  <div class="row col s12 l10" id="step1" *ngIf="step1">
    <form *ngIf="member" (ngSubmit)="SubmitIncomeForm(incomeDeclarationForm.value)" [formGroup]="incomeDeclarationForm">

      <div class="row card-panel col s12">
        <div class="row input-field col s12">
          <i class="material-icons prefix purple-text">timeline</i>
          <select formControlName="catagory">
            <option value="" disabled selected>Source of income</option>
            <option *ngFor="let t of Type" [value]="t">{{t}}</option>
          </select>
        </div>

        <div class="row input-field col s12">
          <i class="material-icons prefix green-text">monetization_on</i>
          <!-- <input formControlName="value" [textMask]="{mask: amountMask}" id="value" type="text" autocomplete="off"
            required class="validate"> -->
            <input formControlName="value"  id="value" type="text" autocomplete="off"
            required class="validate">
          <label for="value"> Value / Amount <strong class="red-text">*</strong> </label>
          <div *ngIf="(value.dirty || value.touched) && value.invalid">
            <small *ngIf="value.errors.required" class="red-text"> The income amount is required
            </small>
          </div>
        </div>

        <div class="row input-field col s12">
          <i class="material-icons prefix blue-text">account_circle</i>
          <select formControlName="member_name" required class="validate">
            <option value="" disabled selected>Who is the Earner ? </option>
            <option *ngFor="let m of membersName" [value]="m.id">{{m.name}} </option>
          </select>
        </div>

        <div class="row input-field col s12">
          <i class="material-icons prefix teal-text">notes</i>
          <textarea id="textarea1" formControlName="description" autocomplete="off" maxlength="250"
            class="materialize-textarea validate"></textarea>
          <label for="textarea1">Description</label>
        </div>
      </div>
      <div>

        <button type="submit" [disabled]="!incomeDeclarationForm.valid"
          class="btn waves-effect waves-light col s12 green" style="margin-top: 10px;">
          <i class="material-icons right">add_circle_outline</i> Add Income
        </button>
        <a [ngClass]="'btn waves-effect waves-light col s12 ' + themeColor " (click)="backClicked()"
          style="margin-top: 15px;">
          <i class="material-icons left">arrow_back_ios</i> Back To Previous Page
        </a>
      </div>
    </form>
  </div>

  <div class="row col s12 l10" id="step2" *ngIf="step2">
    <ul class="row collapsible">
      <li *ngFor="let d of incomeArray">
        <div class="collapsible-header" style="display: block">
          <i class="material-icons green-text">add</i>
          <strong class="black-text">{{d.catagory}}</strong>
          <span class="right green-text"><strong> {{d.value | currency}} </strong> </span>
        </div>
        <div class="collapsible-body white">
          <div class="row collection">
            <a class="collection-item">
              <div class="row"><strong>Owner:</strong><a class="secondary-content black-text"><strong>{{ d.member_name
                    }}</strong> </a></div>
            </a>
          </div>
        </div>
      </li>
      <li>
        <div class="collapsible-header" style="display: block">
          <i class="material-icons green-text">monetization_on</i>
          <strong class="black-text">Total Declared Income</strong>
          <span class="right green-text"><strong> {{total | currency}} </strong> </span>
        </div>
        <div class="collapsible-body">
        </div>
      </li>
    </ul>
    <div class="section"></div>

    <button class="row btn waves-effect waves-light col s12 blue " (click)="AddIncome()">
      <i class="material-icons right">add_circle_outline</i> Add An Other Income
    </button>

    <button class="row btn waves-effect waves-light col s12 green " (click)="legalPage()">
      <i class="material-icons right">cloud_upload</i> Submit My Income Statement
    </button>

    <button [ngClass]="'row btn waves-effect waves-light col s12 ' + themeColor " (click)="backClicked()">
      <i class="material-icons left">arrow_back_ios</i> Back To Previous Page
    </button>
  </div>

  <div class="row col s12 l10" id="step3" *ngIf="step3">

    <div class="row">
      <div class="col s12">
        <div class="card-panel">
          <div class="row">
            <div class="section"></div>
            <strong>
              <p> I hereby request continuance of the assistance granted under
                the {{province}} Works Act.
                I declare that, to the best of my
                knowledge and belief, I am eligible for such assistance.
              </p>
              <p>
                Reported above is all the income my dependents (my spouse and all
                dependents living with me) and I
                have received from all sources since my last report.
              </p>
              <p>
                the information above is true and complete and I understand
                this form is considered a legal statement
              </p>
            </strong>
            <br />
          </div>
        </div>
      </div>
    </div>

    <div class="section"></div>
    <button class="row btn waves-effect waves-light col s12 green" (click)="submitStatement()">
      <i class="material-icons right">send</i> Approve And Submit
    </button>

    <button [ngClass]="'row btn waves-effect waves-light col s12 ' + themeColor " (click)="backToIncome()">
      <i class="material-icons left">arrow_back_ios</i> Back To Previous Page.
    </button>
  </div>

  <div class="row col s12 l10" id="step4" *ngIf="step4">
    <div class="row">
      <div class="col s12">
        <div class="card-panel">
          <div class="row">
            <h6 class="center green-text"><strong>Income Declaration Successfully Submitted</strong></h6>
            <strong>
              <p> Thank You, your income declaration of {{currentMonth}} has been successfully submited to our officer
                for review.</p>
            </strong>
            <br />
          </div>
        </div>
      </div>
    </div>

    <div class="section"></div>
    <button [ngClass]="'row btn waves-effect waves-light col s12 ' + themeColor " (click)="backClicked()">
      <i class="material-icons left">arrow_back_ios</i> Back To Previous Page.
    </button>
  </div>
  <div class="col l1"></div>
</div>