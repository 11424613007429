import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
//import { EmbedVideoService } from 'ngx-embed-video';
import { FacebookService, InitParams, UIParams } from 'ngx-facebook';
import { ImageModel } from 'src/app/models/ImageModel';
import { CommunityService } from 'src/app/services/api/community.service';
import { EmailService } from 'src/app/services/api/email.service';
import { MediaService } from 'src/app/services/api/media.service';
import { MemberService } from 'src/app/services/api/member.service';
import { AboutusService } from 'src/app/services/firebase/aboutus.service';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { AreaOfInterestService } from 'src/app/services/firebase/area-of-interest.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { DepartmentNewsService } from 'src/app/services/firebase/department-news.service';
import { EventsService } from 'src/app/services/firebase/events.service';
import { FCMPushNotificationsService } from 'src/app/services/firebase/FCMPushNotifications.service';
import { FireMembersService } from 'src/app/services/firebase/fire-members.service';
import { StaticContentService } from 'src/app/services/firebase/static-content.service';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { toast } from 'materialize-css';
import { DataService } from 'src/app/services/data.service';

declare var $: any;
declare var M: any;
declare var navigator: any;

@Component({
  selector: 'app-content-editor-new',
  templateUrl: './content-editor-new.component.html',
  styleUrls: ['./content-editor-new.component.css']
})

export class ContentEditorNewComponent implements OnInit {

  defaultThemeColor = environment.appTheme.themeColor;
  adminThemeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  nationofUse = environment.firstNation.name;
  loading = false;
  themeColor: any;
  buttonType: any;

  @Input() model: any;
  @Input() contentType: string;
  @Input() actionType: string;

  @Output() contentSaved: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() contentUpdated: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() contentArchived: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() contentUnArchived: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() contentDeleted: EventEmitter<boolean> = new EventEmitter<boolean>();

  msgName = '';
  contentForm: UntypedFormGroup;
  hasMedia = false;
  hasNoMedia = false;
  addMedia: any;
  isUrl = false;
  isVideo = false;
  isUpload = false;
  isPhoto = false;
  isPdf = false;
  uploadedImage: any;
  videoUploaded = false;
  binDoc: File;
  base64Image: any;
  imgErrorMsg: any;
  pdfErrorMsg: any;

  isMobile = false;
  mobileBase64Image: any;
  isPrivate = false;
  isPublic = false;
  hasExpiryDate: any;
  hasNoExpiryDate: any;
  showDate = false;
  expirable: any;
  membersOnlyClicked = false;
  viewShareButton = false;
  duration = [
    { text: '1 Day', value: 1 },
    { text: '2 Days', value: 2 },
    { text: '3 Days', value: 3 },
    { text: '4 Days', value: 4 },
    { text: '5 Days', value: 5 },
    { text: '6 Days', value: 6 },
    { text: '1 Week', value: 7 },
  ];
  colorPicker: any[];
  selectedColor = 'black-text $';

  loggedUser: any;
  titleEditorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter Title here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'book-antiqua', name: 'Book Antiqua' },
      { class: 'bookman', name: 'Bookman' },
      { class: 'georgia', name: 'Georgia' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'hoefler-text', name: 'Hoefler Text' },
      { class: 'lucida-bright', name: 'Lucida Bright' },
      { class: 'courier', name: 'Courier' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' },
      { class: 'candara', name: 'Candara' }
    ],

    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['insertImage', 'subscript',
        'superscript', 'justifyLeft',
        'justifyCenter',
        'justifyRight',
        'justifyFull',
        'indent',
        'outdent',
        'insertUnorderedList',
        'insertOrderedList', 'link',
        'unlink', 'backgroundColor',
        'insertVideo',
        'insertHorizontalRule',]
    ]
  };
  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '125',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter Content here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'book-antiqua', name: 'Book Antiqua' },
      { class: 'bookman', name: 'Bookman' },
      { class: 'georgia', name: 'Georgia' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'hoefler-text', name: 'Hoefler Text' },
      { class: 'lucida-bright', name: 'Lucida Bright' },
      { class: 'courier', name: 'Courier' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' },
      { class: 'candara', name: 'Candara' }
    ],

    uploadWithCredentials: false,
    sanitize: false,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['insertImage', 'subscript', 'insertVideo',
        'superscript', 'backgroundColor']
    ]
  };
  notificationTopic = environment.firstNation.displayName + '_News_Events'

  showLogoInput = true;
  showImageFromServer = false;
  oldUrl: any;
  showFilters = false;
  genders: any = ['Male', 'Female'];
  nationCommunities: any;
  residency: any = ['On Reserve', 'Urban'];
  genderFilter: any;
  communityFilter: any;
  minAgeFilter: any;
  maxAgeFilter: any;
  residencyFilter: any;
  postLink: any;
  postEventLink: any;
  postDeptNewsLink: any;
  aboutUsLink: any;
  postLinkTwitter: any;
  postEventLinkTwitter: any;
  postDeptNewsLinkTwitter: any;
  aboutUsLinkTwitter: any;
  postLinkLinkedIn: any
  postLinkPintrest: any
  twitterURL = 'https://twitter.com/intent/tweet?url=';
  linkedinURL = 'https://www.linkedin.com/sharing/share-offsite/?url=';
  pinterestURL = 'http://pinterest.com/pin/create/button/?url=';
  public onReady(editor) {
    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement()
    );
  }

  sqlMembersCount = 0;
  firebaseMembersList: any;
  showGauge = false;
  public canvasWidth = 300
  public needleValue = 0
  public centralLabel = ''
  public name = 'Potential Reach'
  public bottomLabel = '0';
  public options = {
    hasNeedle: true,
    needleColor: 'gray',
    needleUpdateSpeed: 1000,
    arcColors: ['#FF0000', '#FFD700', '#32CD32'],
    arcDelimiters: [10, 50, 99],
    rangeLabel: ['0', '100'],
    needleStartValue: 50,
  }
  iframehtml: any;
  areasOfInterestsList: any[]
  videoId: any;

  constructor(
    private communityService: CommunityService,
    private fb: UntypedFormBuilder,
    public appSettingsService: AppSettingsService,
    private staticContentService: StaticContentService,
    private aboutUsService: AboutusService,
    private depNewsService: DepartmentNewsService,
    private mediaService: MediaService,
    private emailService: EmailService,
    private location: Location,
    private authService: AuthService,
    private eventService: EventsService,
    private pushNotificationsService: FCMPushNotificationsService,
    private fbService: FacebookService,
    private sqlMembersService: MemberService,
    private fbMembersService: FireMembersService,
    private router: Router,
    private interestService: AreaOfInterestService,
    // private embedService: EmbedVideoService,
    private dataService: DataService
  ) {
    this.themeColor = this.appSettingsService.getAppThemeColor() ? this.appSettingsService.getAppThemeColor() : this.defaultThemeColor;
    this.buttonType = this.appSettingsService.getButtonType() ? this.appSettingsService.getButtonType() : 'rectangularButton';

    this.colorPicker = this.dataService.getColors();
    this.loggedUser = this.authService.getGlobalUser();
    const initParams: InitParams = {
      appId: '361525988976235',
      xfbml: true,
      version: 'v2.8',
    };

    fbService.init(initParams);

    if (typeof window['cordova'] !== 'undefined') {
      this.isMobile = true;
    }
  }

  ngOnInit() {

    let hostname = environment.firstNation.website.split('/')[2]
    let host = this.isMobile ? hostname : window.location.hostname

    this.postLink = `https://${host}/#/read-more/${this.model.id}`;
    this.postDeptNewsLink = `https://${host}/#/view-department-news/${this.model.id}`;
    this.postEventLink = `https://${host}/#/events-detail/${this.model.id}`;
    this.aboutUsLink = `https://${host}#/view-about-us-section/${this.model.id}`;

    this.postLinkTwitter = `https://${host}/%23/read-more/${this.model.id}&text=Checkout the details of the news In The link below`;
    this.postDeptNewsLinkTwitter = `https://${host}/%23/view-department-news/${this.model.id}`;
    this.postEventLinkTwitter = `https://${host}/%23/events-detail/${this.model.id}`;
    this.aboutUsLinkTwitter = `https://${host}/%23/view-about-us-section/${this.model.id}`;

    this.postLinkLinkedIn = `https://${host}/%23/read-more/${this.model.id}`;

    // this.postLinkPintrest = `https://${window.location.hostname}/%23/read-more/${this.model.id}`;

    if (this.nationofUse === 'Batc' || this.nationofUse === 'Pbcn') {
      this.communityService.getNationCommunities().subscribe(
        com => {
          if (com.length > 0) {
            this.nationCommunities = com;
            setTimeout(() => {
              this.contentForm.patchValue({ communityFilter: this.model.communityFilter });
              $('select').formSelect();
            }, 25);
          }
        }
      );
    } else {
      this.communityService.getCommunity().subscribe(
        com => {
          if (com.length > 0) {
            this.nationCommunities = com;
            setTimeout(() => {
              this.contentForm.patchValue({ communityFilter: this.model.communityFilter });
              $('select').formSelect();
            }, 25);
          }
        }
      );
    }

    if (this.contentType === 'AboutUs') { this.msgName = 'About Us'; }
    if (this.contentType === 'News') {
      this.msgName = 'News';
      this.sqlMembersService.getMembersCount().subscribe(c => {
        if (c) {
          this.sqlMembersCount = Number(c.count);
        }
        this.fbMembersService.getMembersList().valueChanges().subscribe(m => {
          if (m) {
            this.firebaseMembersList = m;
          }
        });
        this.interestService.getAreasList().valueChanges().subscribe(a => {
          if (a) {
            if (a.length > 0) {
              this.areasOfInterestsList = a;
            }
          }
        });
      });
    }
    if (this.contentType === 'Event') { this.msgName = 'Event'; }
    if (this.contentType === 'DepNews') { this.msgName = 'Department News'; }

    if (this.contentType === 'Event') {
      this.contentForm = this.fb.group({
        genderFilter: [''],
        communityFilter: [''],
        residencyFilter: [''],
        minAgeFilter: [''],
        maxAgeFilter: [''],
        cardTitle: ['', [Validators.required]],
        eventDate: ['', [Validators.required]],
        eventPlace: ['', [Validators.required]],
        eventDuration: ['', [Validators.required]],
        content: [''],
        imgLink: [''],
        videoLink: [''],
        expiryDate: [''],
        backgroundColor: ['white']
      });
    } else {
      this.contentForm = this.fb.group({
        genderFilter: [''],
        communityFilter: [''],
        residencyFilter: [''],
        minAgeFilter: [''],
        maxAgeFilter: [''],
        cardTitle: ['', [Validators.required]],
        content: [''],
        imgLink: [''],
        videoLink: [''],
        expiryDate: [''],
        backgroundColor: ['white']
      });
    }
    // this.model.title = this.model.title.replace(/<[^>]*>/g, '');
    this.contentForm.patchValue({ content: this.model.body });
    this.contentForm.patchValue({ cardTitle: this.model.title });
    this.contentForm.patchValue({ backgroundColor: this.model.backgroundColor });
    this.contentForm.patchValue({ genderFilter: this.model.genderFilter });
    this.contentForm.patchValue({ residencyFilter: this.model.residencyFilter });
    this.contentForm.patchValue({ minAgeFilter: this.model.minAgeFilter });
    this.contentForm.patchValue({ maxAgeFilter: this.model.maxAgeFilter });
    if (this.model.eventDate) {
      this.contentForm.patchValue({ eventDate: this.model.eventDate });
      this.contentForm.patchValue({ eventPlace: this.model.eventPlace });
      this.contentForm.patchValue({ maxAgeFilter: this.model.maxAgeFilter });
      this.contentForm.patchValue({ eventDuration: this.model.eventDuration });
    }
    if (this.model.type === 'Link') {
      this.addMedia = true;
      this.hasMedia = true;
      this.hasNoMedia = false;
      this.isUrl = true;
      this.isVideo = false;
      this.isUpload = false;
      this.isPhoto = false;
      this.contentForm.patchValue({ videoLink: this.model.videoLink });
    } else if (this.model.type === 'Video') {
      this.addMedia = true;
      this.hasMedia = true;
      this.isVideo = true;
      this.contentForm.patchValue({ videoLink: this.model.videoLink });
      this.videoId = this.embedVideo(this.model.videoLink);
    } else if (this.model.type === 'Photo') {
      this.addMedia = true;
      this.hasMedia = true;
      this.isPhoto = true;
      this.uploadedImage = this.model.imgLink;

      if (this.model.imgLink) {
        //this.showLogoInput = false;
        this.showImageFromServer = true;
        this.oldUrl = this.model.imgLink;
      }
    } else if (this.model.type === 'uploadVideo') {
      this.addMedia = true;
      this.hasMedia = true;
      this.isUpload = true;
    } else if (this.model.type === 'PDF') {
      this.addMedia = true;
      this.hasMedia = true;
      this.isPdf = true;
      this.videoId = this.embedVideo(this.model.videoLink);
    } else {
      this.hasMedia = false;
      this.hasNoMedia = false;
      if (this.actionType == 'Edit') this.hasNoMedia = true;
    }
    if (this.model.visibility === 'Private') {
      this.isPrivate = true;
      this.isPublic = false;
      this.showFilters = true;
      setTimeout(() => {
        $('select').formSelect();
      }, 25);
    } else if (this.model.visibility === 'Public') {
      this.isPrivate = false;
      this.isPublic = true;
    }
    if (this.model.expiryDate && !this.model.eventDate) {
      this.hasExpiryDate = true;
      this.hasNoExpiryDate = false;
      this.expirable = true;
      const maxtDate = new Date();
      maxtDate.setFullYear(maxtDate.getFullYear() + 1);
      this.showDate = true;
      setTimeout(() => {
        $('#expiryDate').datepicker(
          {
            format: 'dd/mmm/yyyy',
            minDate: new Date(),
            maxDate: maxtDate,
            defaultDate: new Date(),
            yearRange: 3,
            onSelect: (date => {
              const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
              const selectedDate = new Date(date);
              const formattedDate = selectedDate.getDate() + '/' + months[selectedDate.getMonth()] + '/' + selectedDate.getFullYear();
              localStorage.setItem('expiryDate', formattedDate);
            })
          }
        );
        this.contentForm.patchValue({ expiryDate: this.model.expiryDate });
        this.contentForm.get('expiryDate').setValidators([Validators.required]);
        this.contentForm.updateValueAndValidity();
      }, 25);
    } else {
      this.showDate = false;
    }
    setTimeout(() => {
      if (this.contentType === 'Event') {
        const maxtDate = new Date();
        maxtDate.setFullYear(maxtDate.getFullYear() + 1);
        $('#eventDate').datepicker(
          {
            format: 'dd/mmm/yyyy',
            minDate: new Date(),
            maxDate: maxtDate,
            defaultDate: new Date(),
            yearRange: 2,
            onSelect: (date => {
              const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
              const selectedDate = new Date(date);
              const formattedDate = selectedDate.getDate() + '/' + months[selectedDate.getMonth()] + '/' + selectedDate.getFullYear();
              localStorage.setItem('eventDate', formattedDate);
            })
          }
        );
      }
      M.updateTextFields();
      $('.modal').modal();
      $('.dropdown-trigger').dropdown();
      $('select').formSelect();
    }, 25);
  }

  get contentImgLink() { return this.contentForm.get('imgLink') }

  ChangeColor(color: string) {
    this.selectedColor = color;
  }

  publicClicked() {
    this.membersOnlyClicked = false;
    this.model.visibility = 'Public';
    this.showFilters = false;

    this.needleValue = this.sqlMembersCount;
    this.bottomLabel = this.sqlMembersCount + ' Members'
    this.options.rangeLabel = ['0', this.sqlMembersCount.toString()]
    //this.showGauge = true;
  }

  genderChange(val: any) {
    this.genderFilter = val;
  }

  communityChange(val: any) {
    this.communityFilter = val;
  }

  residencyChange(val: any) {
    this.residencyFilter = val;
  }

  minAgeChange(val: any) {
    this.minAgeFilter = val;
  }

  maxAgeChange(val: any) {
    this.maxAgeFilter = val;
  }

  privateClicked() {
    this.membersOnlyClicked = true;
    this.model.visibility = 'Private';
    this.showFilters = true;
    const total = Math.round((this.firebaseMembersList.length / this.sqlMembersCount) * 100);
    this.needleValue = total;
    this.bottomLabel = this.firebaseMembersList.length + ' Members'
    this.options.rangeLabel = ['0', this.sqlMembersCount.toString()],
      // this.showGauge = true;
      setTimeout(() => {
        $('select').formSelect();
      }, 25);
  }

  yesClicked() {
    this.addMedia = true;
    setTimeout(() => {
      $('select').formSelect();
    }, 20);
  }

  noClicked() {
    this.addMedia = false;
    this.model.type = 'Post'  ///
    this.isUrl = false;
    this.isPhoto = false;
    this.isVideo = false;
    this.isUpload = false;
    this.isPdf = false;
  }

  typeChange(value: any) {
    this.model.type = value;
  }

  linkClick() {
    this.model.type = 'Link';
  }

  photoClick() {
    this.imgLink.patchValue('');
    this.model.type = 'Photo';
    this.binDoc = null;
    setTimeout(() => {
      M.updateTextFields();
    }, 20);
  }

  youTubeClick() {
    this.model.type = 'Video';
    this.binDoc = null;
    setTimeout(() => {
      M.updateTextFields();
    }, 20);
  }

  youtubeLink(event) {
    if (this.validateYouTubeUrl(event.target.value)) {
      this.videoId = this.embedVideo(event.target.value);
    } else {
      toast({ html: 'Invalud youtube link!', classes: 'red' });
    }
  }

  uploadClick() {
    this.imgLink.patchValue('');
    this.binDoc = null;
    this.model.type = 'uploadVideo';
  }

  uploadPdf() {
    this.imgLink.patchValue('');
    this.binDoc = null;
    this.model.type = 'PDF';
  }

  expiryDateYesClicked() {
    this.hasExpiryDate = true;
    this.hasNoExpiryDate = false;
    this.expirable = true;
    const maxtDate = new Date();
    maxtDate.setFullYear(maxtDate.getFullYear() + 1);
    this.showDate = true;
    setTimeout(() => {
      $('#expiryDate').datepicker(
        {
          format: 'dd/mmm/yyyy',
          minDate: new Date(),
          maxDate: maxtDate,
          defaultDate: new Date(),
          yearRange: 3,
          onSelect: (date => {
            const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
            const selectedDate = new Date(date);
            const formattedDate = selectedDate.getDate() + '/' + months[selectedDate.getMonth()] + '/' + selectedDate.getFullYear();
            localStorage.setItem('expiryDate', formattedDate);
          })
        }
      );
      this.contentForm.get('expiryDate').setValidators([Validators.required]);
      this.contentForm.updateValueAndValidity();
    }, 25);
  }

  dateChange() {
    if (this.contentForm) {
      this.contentForm.patchValue({ expiryDate: localStorage.getItem('expiryDate') });
    }
  }

  EventdateChange() {
    if (this.contentForm) {
      this.contentForm.patchValue({ eventDate: localStorage.getItem('eventDate') });
    }
  }

  expiryDateNoClicked() {
    this.hasExpiryDate = false;
    this.hasNoExpiryDate = true;
    this.expirable = false;
    this.showDate = false;
    localStorage.removeItem('expiryDate');
    this.contentForm.get('expiryDate').clearValidators();
    this.contentForm.updateValueAndValidity();
    this.contentForm.patchValue({ expiryDate: '' });
  }

  handleFileInput(files: FileList) {
    if (!this.validateImage(files[0].name)) {
      this.imgErrorMsg = 'Invalid File Type, Please Select an Image File';
      this.imgLink.patchValue('');
      return;
    }
    this.binDoc = files[0];
    this.pdfErrorMsg = undefined;
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.base64Image = reader.result as string;
      this.uploadedImage = this.base64Image;
    };
  }

  handleMobilePhotoInput() {
    navigator.camera.getPicture((image: any) => {
      const imageName = 'IMG.jpeg';
      const imageBlob = this.dataURItoBlob(image);
      const imageFile = new File([imageBlob], imageName, { type: 'image/jpeg' });
      this.binDoc = imageFile;
      this.base64Image = 'data:image/jpeg;base64,' + image;
      this.mobileBase64Image = image
    }, (error) => {
      alert(error);
    },
      {
        quality: 50,
        destinationType: navigator.camera.DestinationType.DATA_URL,
        encodingType: navigator.camera.EncodingType.JPEG,
        mediaType: navigator.camera.MediaType.PICTURE,
        sourceType: navigator.camera.PictureSourceType.PHOTOLIBRARY,
      }
    )
  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/png' });
    return blob;
  }

  handleMobileVideoInput() {
    navigator.camera.getPicture((video: any) => {
    }, (error) => {
      alert(error);
    },
      {
        quality: 50,
        destinationType: navigator.camera.DestinationType.DATA_URL,
        // encodingType: navigator.camera.EncodingType.JPEG,
        mediaType: navigator.camera.MediaType.VIDEO,
        sourceType: navigator.camera.PictureSourceType.PHOTOLIBRARY,
        allowEdit: true
      }
    )
  }

  handleVideoInput(files: FileList) {
    this.binDoc = files[0];
    if (files && files[0]) {
      var reader = new FileReader();
      reader.onload = function (e) {
        $('.uploadedVideo').attr('src', e.target.result);
      };
      reader.readAsDataURL(files[0]);
      this.videoUploaded = true;
    }
  }

  handlePDFInput(files: FileList) {
    if (!this.validatePDF(files[0].name)) {
      this.pdfErrorMsg = 'Invalid Document Type, Please Select a PDF File'
      this.imgLink.patchValue('');
      return;
    }
    this.pdfErrorMsg = undefined;
    this.binDoc = files[0];
  }

  viewPdf() {
  }

  get imgLink() { return this.contentForm.get('imgLink'); }
  get videoLink() { return this.contentForm.get('videoLink'); }
  get cardTitle() { return this.contentForm.get('cardTitle'); }
  get backgroundColor() { return this.contentForm.get('backgroundColor'); }
  get content() { return this.contentForm.get('content'); }
  get expiryDate() { return this.contentForm.get('expiryDate'); }
  get eventPlace() { return this.contentForm.get('eventPlace'); }
  get eventDate() { return this.contentForm.get('eventDate'); }
  get eventDuration() { return this.contentForm.get('eventDuration'); }

  PostContent(value: any) {
    $('.modal').modal('close');
    this.model.title = '<span class="' + this.selectedColor + '"><strong>'
      + this.cardTitle.value.replace(/\b(.)/g, (m => m.toUpperCase())) + '</strong></span>';
    this.model.body = this.content.value;
    this.model.videoLink = this.videoLink.value;
    this.model.backgroundColor = (this.backgroundColor.value) ? this.backgroundColor.value : '#FFFFFF';

    if (this.genderFilter) { this.model.genderFilter = this.genderFilter; }
    if (this.communityFilter) { this.model.communityFilter = this.communityFilter; }
    if (this.minAgeFilter) { this.model.minAgeFilter = this.minAgeFilter; }
    if (this.maxAgeFilter) { this.model.maxAgeFilter = this.maxAgeFilter; }
    if (this.residencyFilter) { this.model.residencyFilter = this.residencyFilter; }

    if (this.expirable) {
      this.model.expiryDate = localStorage.getItem('expiryDate');
      localStorage.removeItem('expiryDate');
    }
    this.model.author = this.authService.getGlobalUser().LastName + ' ' + this.authService.getGlobalUser().FirstName;

    if (this.model.type === 'Photo') {
      if (this.binDoc && this.oldUrl) {
        if (this.isMobile) {
          const img = new ImageModel();
          img.imgBase64 = this.mobileBase64Image;
          this.mediaService.uploadImage(img).subscribe(upload => {
            if (upload) {
              this.model.imgLink = upload.imgLink;
              if (this.actionType === 'Edit') {
                if (this.contentType === 'AboutUs') {
                  this.aboutUsService.UpdateAboutUsSection(this.model).then(resp => {
                    this.contentUpdated.emit(true);
                    this.backClicked();
                  });
                }
                if (this.contentType === 'DepNews') {
                  this.depNewsService.UpdateDepNews(this.model).then(resp => {
                    this.contentUpdated.emit(true);
                    this.backClicked();
                  });
                }
              }

              if (this.actionType === 'Add') {
                if (this.contentType === 'AboutUs') {
                  this.aboutUsService.newAboutUsSection(this.model).then(resp => {
                    this.contentSaved.emit(true);
                    this.backClicked();
                  });
                }

                if (this.contentType === 'DepNews') {
                  this.depNewsService.newDepNews(this.model).then(resp => {
                    this.contentSaved.emit(true);
                    this.backClicked();
                  });
                }
              }
            }
          });
        }
        else {
          this.mediaService.UpdateBinImage(this.binDoc, this.oldUrl).subscribe(
            upload => {
              if (upload) {
                this.model.imgLink = upload.imgLink;
                if (this.actionType === 'Edit') {
                  if (this.contentType === 'AboutUs') {
                    this.aboutUsService.UpdateAboutUsSection(this.model).then(resp => {
                      this.contentUpdated.emit(true);
                      this.backClicked();
                    });
                  }

                  if (this.contentType === 'DepNews') {
                    this.depNewsService.UpdateDepNews(this.model).then(resp => {
                      this.contentUpdated.emit(true);
                      this.backClicked();
                    });
                  }
                }

                if (this.actionType === 'Add') {
                  if (this.contentType === 'AboutUs') {
                    this.aboutUsService.newAboutUsSection(this.model).then(resp => {
                      this.contentSaved.emit(true);
                      this.backClicked();
                    });
                  }

                  if (this.contentType === 'DepNews') {
                    this.depNewsService.newDepNews(this.model).then(resp => {
                      this.contentSaved.emit(true);
                      this.backClicked();
                    });
                  }
                }
              }
            }
          );
        }
      } else if (this.binDoc && !this.oldUrl) {

        if (this.isMobile) {
          const img = new ImageModel();
          img.imgBase64 = this.mobileBase64Image;
          this.mediaService.uploadImage(img).subscribe(upload => {
            if (upload) {
              this.model.imgLink = upload.imgLink;

              if (this.actionType === 'Edit') {

                if (this.contentType === 'AboutUs') {
                  this.aboutUsService.UpdateAboutUsSection(this.model).then(resp => {
                    this.contentUpdated.emit(true);
                    this.backClicked();
                  });
                }

                if (this.contentType === 'DepNews') {
                  this.depNewsService.UpdateDepNews(this.model).then(resp => {
                    this.contentUpdated.emit(true);
                    this.backClicked();
                  });
                }
              }

              if (this.actionType === 'Add') {
                if (this.contentType === 'AboutUs') {
                  this.aboutUsService.newAboutUsSection(this.model).then(resp => {
                    this.contentSaved.emit(true);
                    this.backClicked();
                  });
                }

                if (this.contentType === 'DepNews') {
                  this.depNewsService.newDepNews(this.model).then(resp => {
                    this.contentSaved.emit(true);
                    this.backClicked();
                  });
                }
              }
              return;
            }
          })
        }
        else {
          this.mediaService.UploadBinImage(this.binDoc).subscribe(
            upload => {
              if (upload) {
                this.model.imgLink = upload.imgLink;

                if (this.actionType === 'Edit') {
                  if (this.contentType === 'AboutUs') {
                    this.aboutUsService.UpdateAboutUsSection(this.model).then(resp => {
                      this.contentUpdated.emit(true);
                      this.backClicked();
                    });
                  }

                  if (this.contentType === 'DepNews') {
                    this.depNewsService.UpdateDepNews(this.model).then(resp => {
                      this.contentUpdated.emit(true);
                      this.backClicked();
                    });
                  }
                }

                if (this.actionType === 'Add') {
                  if (this.contentType === 'AboutUs') {
                    this.aboutUsService.newAboutUsSection(this.model).then(resp => {
                      this.contentSaved.emit(true);
                      this.backClicked();
                    });
                  }

                  if (this.contentType === 'DepNews') {
                    this.depNewsService.newDepNews(this.model).then(resp => {
                      this.contentSaved.emit(true);
                      this.backClicked();
                    });
                  }
                }
                return;
              }
            }
          );
        }
      } else if (!this.binDoc && this.oldUrl) {

        if (this.actionType === 'Edit') {
          if (this.contentType === 'AboutUs') {
            this.aboutUsService.UpdateAboutUsSection(this.model).then(resp => {
              this.contentUpdated.emit(true);
            });
          }

          if (this.contentType === 'DepNews') {
            this.depNewsService.UpdateDepNews(this.model).then(resp => {
              this.contentUpdated.emit(true);
            });
          }
        }

        if (this.actionType === 'Add') {
          if (this.contentType === 'AboutUs') {
            this.aboutUsService.newAboutUsSection(this.model).then(resp => {
              this.contentSaved.emit(true);
            });
          }

          if (this.contentType === 'DepNews') {
            this.depNewsService.newDepNews(this.model).then(resp => {
              this.contentSaved.emit(true);
            });
          }
        }
      } else if (!this.binDoc && !this.oldUrl) {
        if (!this.model.imgLink) { this.model.imgLink = ''; }
        if (this.actionType === 'Edit') {
          if (this.contentType === 'AboutUs') {
            this.aboutUsService.UpdateAboutUsSection(this.model).then(resp => {
              this.contentUpdated.emit(true);
              this.backClicked();
            });
          }

          if (this.contentType === 'DepNews') {
            this.depNewsService.UpdateDepNews(this.model).then(resp => {
              this.contentUpdated.emit(true);
              this.backClicked();
            });
          }
        }

        if (this.actionType === 'Add') {
          if (this.contentType === 'AboutUs') {
            this.aboutUsService.newAboutUsSection(this.model).then(resp => {
              this.contentSaved.emit(true);
              this.backClicked();
            });
          }

          if (this.contentType === 'DepNews') {
            this.depNewsService.newDepNews(this.model).then(resp => {
              this.contentSaved.emit(true);
              this.backClicked();
            });
          }
        }
      }

    } else if (this.model.type === 'uploadVideo' && this.videoUploaded) {
      this.mediaService.uploadVideo(this.binDoc).subscribe(x => {
        if (x) {
          this.model.videoLink = x.imgLink;

          if (this.actionType === 'Edit') {
            if (this.contentType === 'AboutUs') {
              this.aboutUsService.UpdateAboutUsSection(this.model).then(resp => {
                this.contentUpdated.emit(true);
                this.backClicked();
              });
            }

            if (this.contentType === 'DepNews') {
              this.depNewsService.UpdateDepNews(this.model).then(resp => {
                this.contentUpdated.emit(true);
                this.backClicked();
              });
            }
          }

          if (this.actionType === 'Add') {
            if (this.contentType === 'AboutUs') {
              this.aboutUsService.newAboutUsSection(this.model).then(resp => {
                this.contentSaved.emit(true);
                this.backClicked();
              });
            }

            if (this.contentType === 'DepNews') {
              this.depNewsService.newDepNews(this.model).then(resp => {
                this.contentSaved.emit(true);
                this.backClicked();
              });
            }
          }
        }
      });
    } else if (this.model.type === 'PDF') {
      this.mediaService.uploadPDF(this.binDoc).subscribe(x => {
        if (x) {
          // videoLink is used to store the returned pdf link
          // x.imgLink == The Link Returned From The Api
          this.model.videoLink = x.imgLink;

          if (this.actionType === 'Edit') {
            if (this.contentType === 'AboutUs') {
              this.aboutUsService.UpdateAboutUsSection(this.model).then(resp => {
                this.contentUpdated.emit(true);
                this.backClicked();
              });
            }

            if (this.contentType === 'DepNews') {
              this.depNewsService.UpdateDepNews(this.model).then(resp => {
                this.contentUpdated.emit(true);
                this.backClicked();
              });
            }
          }

          if (this.actionType === 'Add') {
            if (this.contentType === 'AboutUs') {
              this.aboutUsService.newAboutUsSection(this.model).then(resp => {
                this.contentSaved.emit(true);
                this.backClicked();
              });
            }

            if (this.contentType === 'DepNews') {
              this.depNewsService.newDepNews(this.model).then(resp => {
                this.contentSaved.emit(true);
                this.backClicked();
              });
            }
          }
        }
      });
    } else {
      this.model.imgLink = '';
      if (this.actionType === 'Edit') {
        if (this.contentType === 'AboutUs') {
          this.aboutUsService.UpdateAboutUsSection(this.model).then(resp => {
            $('#shareModal').modal('open');
            this.contentUpdated.emit(true);
            this.backClicked();
          });
        }

        if (this.contentType === 'DepNews') {
          this.depNewsService.UpdateDepNews(this.model).then(resp => {
            if (this.model.visibility === 'Public') {
              $('#shareModal').modal('open');
            }
            this.contentUpdated.emit(true);
            this.backClicked();
          });
        }
      }

      if (this.actionType === 'Add') {
        if (this.contentType === 'AboutUs') {
          this.aboutUsService.newAboutUsSection(this.model).then(resp => {
            $('#shareModal').modal('open');
            this.contentSaved.emit(true);
            this.backClicked();
          });
        }

        if (this.contentType === 'DepNews') {
          this.depNewsService.newDepNews(this.model).then(resp => {
            if (this.model.visibility === 'Public') {
              $('#shareModal').modal('open');
            }
            this.contentSaved.emit(true);
            this.backClicked();
          });
        }
      }
    }
  }

  publishContent() {
    $('.modal').modal('close');
    this.model.published = true;
    this.model.unPublished = false;
    this.model.index = Math.floor(Math.random() * (10000 - 5000 + 1)) + 5000,

      $('.modal').modal('close');

    if (this.contentType === 'News') {
      this.staticContentService.UpdateContent(this.model).then(x => {
        this.contentUnArchived.emit(true);
      });
    }

    if (this.contentType === 'DepNews') {
      this.depNewsService.UpdateDepNews(this.model).then(resp => {
        this.contentUnArchived.emit(true);
      });
    }

    if (this.contentType === 'Event') {
      this.eventService.UpdateEvent(this.model).then(resp => {
        this.contentUnArchived.emit(true);
      });
    }
  }

  UnpublishContent() {
    this.model.published = false;
    this.model.unPublished = true;
    $('.modal').modal('close');

    if (this.contentType === 'News') {
      this.staticContentService.UpdateContent(this.model).then(x => {
        this.contentArchived.emit(true);
      });
    }

    if (this.contentType === 'DepNews') {
      this.depNewsService.UpdateDepNews(this.model).then(resp => {
        this.contentArchived.emit(true);
      });
    }
    if (this.contentType === 'Event') {
      this.eventService.UpdateEvent(this.model).then(resp => {
        this.contentUnArchived.emit(true);
      });
    }
  }

  DeleteContent() {
    $('.modal').modal('close');

    if (this.contentType === 'AboutUs') {
      this.aboutUsService.DeleteContent(this.model).then(x => {
        this.contentDeleted.emit(true);
        this.backClicked();
      });
    }

    if (this.contentType === 'DepNews') {
      this.depNewsService.DeleteDepNews(this.model).then(resp => {
        this.contentDeleted.emit(true);
        this.backClicked();
      });
    }
  }

  backClicked() {
    this.router.navigate(['/admin/dashboard']);
  }

  removeFile() {
    if (this.model.type == 'Photo') {
      this.uploadedImage = undefined;
      this.binDoc = undefined;
      this.base64Image = undefined;
      this.imgLink.patchValue('');
      this.model.imgLink = '';
    } else if (this.model.type == 'uploadVideo') {
      this.binDoc = undefined;
      this.model.videoLink = '';
      $('.uploadedVideo').attr('src', '');
      this.videoUploaded = false;
    } else if (this.model.type == 'Video') {
      this.iframehtml = undefined;
      this.videoId =  undefined;
      this.videoLink.patchValue('');
    }
  }

  embedVideo(videoLink) {
    const videoIdMatch = videoLink.match(/(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([^&]+)|(?:https?:\/\/)?(?:www\.)?youtu\.be\/([^?]+)/);
    this.videoId = videoIdMatch ? (videoIdMatch[1] || videoIdMatch[2]) : '';
    return this.videoId;
  }

  removeSavedFile() {
    this.binDoc = undefined;
    this.base64Image = undefined;
    this.oldUrl = this.model.imgLink;
    this.model.imgLink = '';
    this.imgLink.patchValue('');
    this.showImageFromServer = false;
    this.showLogoInput = true;
  }

  private validateImage(name: any) {
    const ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() === 'png') {
      return true;
    } else if (ext.toLowerCase() === 'jpg') {
      return true;
    } else if (ext.toLowerCase() === 'jpeg') {
      return true;
    }
    else if (ext.toLowerCase() === 'svg') {
      return true;
    }
    else if (ext.toLowerCase() === 'webp') {
      return true;
    } else {
      return false;
    }
  }

  private validateYouTubeUrl(url) {
    if (url) {
      var regExp = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
      if (url.match(regExp)) {
        return true;
      }
    }
    return false;
  }

  validatePDF(name: String) {
    var ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() == 'pdf') {
      return true;
    }
    else {
      return false;
    }
  }

  public postToFacebook() {
    const params: UIParams = {
      href: this.postLink,
      method: 'share',
    };

    this.fbService.ui(params).then((res) => {
    }).catch((err) => {
    });
  }

  public postEventToFacebook() {
    const params: UIParams = {
      href: this.postEventLink,
      method: 'share',
    };
    this.fbService.ui(params).then((res) => { }).catch((err) => { });
  }

  public postDepartmentNewsToFacebook() {
    const params: UIParams = {
      href: this.postDeptNewsLink,
      method: 'share',
    };
    this.fbService.ui(params).then((res) => { }).catch((err) => { });
  }

  public postAboutUsToFacebook() {
    const params: UIParams = {
      href: this.aboutUsLink,
      method: 'share',
    };
    this.fbService.ui(params).then((res) => { }).catch((err) => { });
  }

  public shareSocialMediaEdit() {
    $('#shareModal').modal('open');
  }
}
