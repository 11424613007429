import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';

declare var $: any;

@Component({
  selector: 'app-members',
  templateUrl: './members.component.html',
  styleUrls: ['./members.component.css']
})

export class MembersComponent implements OnInit {

  defaultThemeColor = environment.appTheme.themeColor;
  themeColor: any;
  buttonType: any;
  fontColor = environment.appTheme.adminFontColor;
  nationOfUse = environment.firstNation.name;
  currentUser: any;
  user: any;
  selectedMenu = {
    name: 'Registered Members',
    icon: 'how_to_reg',
  }
  component: string;
  reqFilters = {
    pending: true,
    invited: false,
    declined: false,
  }
  membersFilter = {
    members: false,
    admins: false,
    employers: false,
    employees: false
  }
  birthdayFilters = {
    today: false,
    thisWeek: false,
    thisMonth: false,
  }
  filterForm: UntypedFormGroup;
  permissions: any;
  isSuperAdmin = false;
  menuClicked = false;

  constructor(private location: Location, 
    private authService: AuthService,
    private settingsService: AppSettingsService,
    ) {
    this.themeColor = this.settingsService.getAppThemeColor() ? this.settingsService.getAppThemeColor() : this.defaultThemeColor;
      this.buttonType = this.settingsService.getButtonType() ? this.settingsService.getButtonType() : 'rectangularButton';
    this.currentUser = this.authService.getGlobalUser();
    if (this.currentUser) {
      if (this.currentUser.isSuperAdmin) this.isSuperAdmin = true;
    }
    this.authService.getCurrentApplicantIDObservable().subscribe(
      user => {
        if (user) {
          this.user = user;
          if (user.isSuperAdmin) this.isSuperAdmin = true;
          if (user.permissions) this.permissions = user.permissions;
          //this.newsPermission = this.permissions.filter(p =>)
          else if (!this.isSuperAdmin) {
            $('.modal').modal();
            $('#permissionModal').modal('open');
          }
        }
      }
    );
   
  }

  ngOnInit() {
    this.component = 'members'
  
    setTimeout(() => {
      $('.dropdown-trigger').dropdown({
        constrainWidth: false,
        isScrollable: true,
      });
      $('.sidenav').sidenav({ 'isFixed': true });
      $('.collapsible').collapsible();
    }, 25);
  }

  getPermission(menu) {
    if (this.isSuperAdmin) return true;
    if (this.permissions) {
      if (this.permissions.length > 0) {
        let p = this.permissions.includes(menu);
        if (p) return true;
      }
    }
    return true;
  }

  getRegistrationRequests() {
    let p = this.getPermission('RegistrationRequest');
    if (p) this.component = 'requests';
    else this.component = 'noPermission';
  }

  filterRequests(status) {
    for (const key in this.reqFilters) {
      this.reqFilters[key] = false;
    }
    this.reqFilters[status] = true;
    this.reqFilters = Object.assign({}, this.reqFilters);
  }

  getRegistredMembers() {
    let p = this.getPermission('RegisteredMembers');
    if (p) this.component = 'members';
    else this.component = 'noPermission';
  }

  getMemberVerification(){
    let p = this.getPermission('MemberVerfication');
    if (p) this.component = 'verification';
    else this.component = 'noPermission';
  }

  getDisabledMembers() {
    let p = this.getPermission('DisabledMembers');
    if (p) this.component = 'disabled';
    else this.component = 'noPermission';
  }

  getUpcomingBirthdays() {
    let p = this.getPermission('Birthdays');
    if (p) this.component = 'birthdays';
    else this.component = 'noPermission';
  }

  filterBirthdays(status) {
    Object.keys(this.birthdayFilters).forEach(v => this.birthdayFilters[v] = false);
    this.birthdayFilters[status] = !this.birthdayFilters[status];
    this.birthdayFilters = Object.assign({}, this.birthdayFilters);
  }

  searchMembers() {
    let p = this.getPermission('SearchMembers');
    if (p) this.component = 'search';
    else this.component = 'noPermission';
  }

  addMembers() {
    let p = this.getPermission('AddMembers');
    if (p) this.component = 'addMembers';
    else this.component = 'noPermission';
  }

  profileUpdates() {
    let p = this.getPermission('ProfileUpdates');
    if (p) this.component = 'profileUpdates';
    else this.component = 'noPermission';
  }

  demographics() {
    let p = this.getPermission('Demographics');
    if (p) this.component = 'demographics';
    else this.component = 'noPermission';
  }

  select(menu, icon) {
    this.selectedMenu.name = menu;
    this.selectedMenu.icon = icon;
  }

  backToMenu() {
    this.menuClicked = true;
  }

  backClicked() {
    this.location.back();
  }

}
