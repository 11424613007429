<div class="row dep-card" *ngIf="departmentSection">
    <div class="row p-t-3 p-b-2">
        <div class="col s12 m9 l5 push-l1 push-m1">
            <div class="header-title">
                <h6 [ngStyle]="{'color': departmentSection.displayNameColor}">{{departmentSection.displayName}}</h6>
            </div>
        </div>
        <div class="col l6 hide-on-small-only" style="padding: 30px 0px;">
            <p class="seeAll-displayName teal-text right"><a (click)="navigate()" [routerLink]="['/departments']"
                    [ngStyle]="{color: departmentSection.displayNameColor, text_decoration: 'underline'}">
                    <i>See All {{departmentSection.displayName}} >>
                    </i></a></p>
        </div>
        <div class="divider col l10 m10 s8 push-m1 push-s2 push-l1 grey lighten-3"></div>
    </div>
    <div class="row col l10 push-l1 m10 push-m1 s12 ">
        <div class="col s12 l3 m6" *ngFor="let dep of departments">

            <article class="card" *ngIf='dep.logo' [ngStyle]="{'border-color': themeColor} "
                [routerLink]="['/department-sub-menu', dep.id]">
                <div class="card-image">
                    <img *ngIf='dep.logo' class="responsive-img" [src]="dep.logo" />
                </div>
                <div class="card-cont">
                    <h3 [innerHTML]="dep.name" class="title-dep title-section3 bold-900 ">
                    </h3>
                    <p [innerHTML]="dep.mandate" class="middle-dep-body-snippet sub-title-section2 hide-on-small-only">
                    </p>
                </div>
            </article>

            <article class="card" *ngIf='!dep.logo' [ngStyle]="{'border-color': themeColor} "
                [routerLink]="['/department-sub-menu', dep.id]">
                <div class="card-cont">
                    <h3 [innerHTML]="dep.name" class="title-dep title-section3 bold-900 ">
                    </h3>
                    <p [innerHTML]="dep.mandate" class="long-dep-body-snippet sub-title-section2 "></p>
                </div>
            </article>
        </div>
    </div>



    <div class="row col s12 m12  hide-on-large-only  ">
        <p class="seeAll-displayName teal-text center "><a (click)="navigate()" [routerLink]="['/departments']"
                [ngStyle]="{color: departmentSection.displayNameColor, text_decoration: 'underline'}">
                See All {{departmentSection.displayName}} >> </a></p>
    </div>
</div>