import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppInfoModel } from 'src/app/models/AppInfoModel';
import { ActivityResultModel } from 'src/app/models/activityResultModel';
import { ParticipationModel } from 'src/app/models/ParticipationModel';
import { ApiKeyManagerService } from './apiKeyManager.Service';

@Injectable({
  providedIn: 'root'
})
export class ParticipationService {

  url = environment.firstNation.apiUrl + 'api/Participations/';

  constructor(private httpClient: HttpClient,  private keyManager: ApiKeyManagerService) { }

  getParticipationByID(id: number): Observable<ParticipationModel[]> {
    const headers = new HttpHeaders().set('SOCIALAPIKEY', this.keyManager.getKey());
    return this.httpClient.get<any[]>(this.url + 'GetParticipationByMember/' + id, { headers });
  }

  getActivityList(id: number): Observable<any[]> {
    const headers = new HttpHeaders().set('SOCIALAPIKEY', this.keyManager.getKey());
    return this.httpClient.get<any[]>(this.url + 'GetActivitiesByParticipationID/' + id, { headers });
  }

  getActivityDetail(id: number): Observable<any[]> {
    const headers = new HttpHeaders().set('SOCIALAPIKEY', this.keyManager.getKey());
    return this.httpClient.get<any[]>(this.url + 'GetDetailByID/' + id, { headers });
  }

  getActionList(id: number): Observable<any[]> {
    const headers = new HttpHeaders().set('SOCIALAPIKEY', this.keyManager.getKey());
    return this.httpClient.get<any[]>(this.url + 'GetActionsByParticipationID/' + id, { headers });
  }

  getActionDetail(id: number): Observable<any[]> {
    const headers = new HttpHeaders().set('SOCIALAPIKEY', this.keyManager.getKey());
    return this.httpClient.get<any[]>(this.url + 'GetDetailByID/' + id, { headers });
  }

  postActionResult(model: ActivityResultModel): any {
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
    return this.httpClient.post<ActivityResultModel>(this.url + 'PostActionResult/', JSON.stringify(model), { headers });
  }

  postActivityResult(model: ActivityResultModel): any {
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
    return this.httpClient.post<ActivityResultModel>(this.url + 'PostActivityResult/', JSON.stringify(model), { headers });
  }

  postReason(model: ActivityResultModel): any {
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
    return this.httpClient.post<ActivityResultModel>(this.url + 'PostReason/', JSON.stringify(model), { headers });
  }

}
