import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import { SearchMemberModel } from 'src/app/models/searchMemberModel';
import { MediaService } from 'src/app/services/api/media.service';
import { MemberService } from 'src/app/services/api/member.service';
import { GovernanceService } from 'src/app/services/firebase/governance.service';
import { toast } from 'materialize-css';
import { Location } from '@angular/common';
import { FireMembersService } from 'src/app/services/firebase/fire-members.service';
import { EmailService } from 'src/app/services/api/email.service';
import { EmailModel } from 'src/app/models/emailModel';
import { OtherRolesService } from 'src/app/services/firebase/other-roles.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { createMask } from '@ngneat/input-mask';

declare var M: any;

@Component({
  selector: 'app-manage-board-member-acc',
  templateUrl: './manage-board-member-acc.component.html',
  styleUrls: ['./manage-board-member-acc.component.css']
})
export class ManageBoardMemberAccComponent implements OnInit {

  id: any;
  boardModel: any;
  memberType: any;
  editMemberModel: any;
  searchForm: UntypedFormGroup;
  chiefForm: UntypedFormGroup;
  memberModel: SearchMemberModel;
  searchResult: any;
  p: number = 1;
  today = new Date();
  appointmentDate: any;
  expiryDate: any;
  myimgLink: any;
  showImageUpload = true;
  binDoc: File;
  oldUrl: any;
  base64Image: any
  profile_pic: any;
  // public phoneNumberMask = ['(', /[0-9]/, /[0-9]/, /[0-9]/, ')', ' ', /[0-9]/, /[0-9]/,
  //   /[0-9]/, ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  phoneInputMask = createMask<string>({
    mask: '(999) 999-9999',
    guide: true,
    showMaskTyped: true,
  });

  public urlPattern = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';

  showMobilePrivacy = false;
  showEmailPrivacy = false;
  showOfficePhonePrivacy = false;
  showFacebookPrivacy = false;
  showTwitterPrivacy = false;
  showInstagramPrivacy = false;
  showYoutubePrivacy = false;
  showLinkedInPrivacy = false;
  applicantID: any;
  user: any;

  myDpOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'dd/mmm/yyyy',
    markCurrentDay: true,
  };

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private memberService: MemberService,
    private route: ActivatedRoute,
    private mediaService: MediaService,
    private location: Location,
    private govService: GovernanceService,
    private fireMember: FireMembersService,
    private emailService: EmailService,
    private authService: AuthService,
    private otherRolesService: OtherRolesService) {

    this.chiefForm = this.formBuilder.group({
      firstName: [''],
      lastName: [''],
      displayName: [''],
      chiefBio: [''],
      appointmentDate: [''],
      expiryDate: [''],
      email: ['', [Validators.email, Validators.required]],
      cell_phone: [''],
      office_phone: [''],
      imgLink: [''],
      chiefFacebookAcc: ['', [Validators.pattern(this.urlPattern)]],
      chiefInstagramAcc: ['', [Validators.pattern(this.urlPattern)]],
      chiefTwitterAcc: ['', [Validators.pattern(this.urlPattern)]],
      chiefLinkedinAcc: ['', [Validators.pattern(this.urlPattern)]],
      chiefYoutubeAcc: ['', [Validators.pattern(this.urlPattern)]],
      showEmail: [''],
      showMobile: [''],
      showFacebookAcc: [''],
      showTwitterAcc: [''],
      showInstagramAcc: [''],
      showLinkedinAcc: [''],
      showYoutubeAcc: [''],
      showOfficePhone: [''],
      allowContact: [''],
    });

  }

  ngOnInit() {
    this.id = this.authService.getGlobalUser().applicantID;
    this.user = this.authService.getGlobalUser();
    this.govService.getBoardMemberByUserId(this.id).valueChanges().subscribe((boardMember: any) => {
      if (boardMember.length > 0) {
        const board = boardMember[0];
        this.govService.getBoardById(board.boardId).valueChanges().subscribe(b => {
          if (b) {
            this.boardModel = b;
            let result: any;
            if (board.memberType === 'Chair') {
              result = this.boardModel.boardChair.filter(f => Number(f.id) === Number(this.id));
            }
            if (board.memberType === 'Member') {
              result = this.boardModel.boardMembers.filter(f => Number(f.id) === Number(this.id));
            }
            if (result) {
              this.editMemberModel = result[0];
            }
            if (this.editMemberModel) {
              this.chiefForm = this.formBuilder.group({
                firstName: [{ value: this.editMemberModel.firstName, disabled: true }],
                lastName: [{ value: this.editMemberModel.lastName, disabled: true }],
                displayName: [this.editMemberModel.displayName],
                chiefBio: [this.editMemberModel.chiefBio],
                appointmentDate: [this.editMemberModel.appointmentDate],
                expiryDate: [this.editMemberModel.expiryDate],
                email: [this.editMemberModel.email, [Validators.email, Validators.required]],
                cell_phone: [this.editMemberModel.cell_phone],
                office_phone: [this.editMemberModel.office_phone],
                imgLink: [this.editMemberModel.imgLink],
                chiefFacebookAcc: [this.editMemberModel.chiefFacebookAcc, [Validators.pattern(this.urlPattern)]],
                chiefInstagramAcc: [this.editMemberModel.chiefInstagramAcc, [Validators.pattern(this.urlPattern)]],
                chiefTwitterAcc: [this.editMemberModel.chiefTwitterAcc, [Validators.pattern(this.urlPattern)]],
                chiefLinkedinAcc: [this.editMemberModel.chiefLinkedinAcc, [Validators.pattern(this.urlPattern)]],
                chiefYoutubeAcc: [this.editMemberModel.chiefYoutubeAcc, [Validators.pattern(this.urlPattern)]],
                showEmail: [this.editMemberModel.showEmail],
                showMobile: [this.editMemberModel.showMobile],
                showFacebookAcc: [this.editMemberModel.showFacebookAcc], // if not saved use default
                showTwitterAcc: [this.editMemberModel.showTwitterAcc],
                showInstagramAcc: [this.editMemberModel.showInstagramAcc],
                showLinkedinAcc: [this.editMemberModel.showLinkedinAcc],
                showYoutubeAcc: [this.editMemberModel.showYoutubeAcc],
                showOfficePhone: [this.editMemberModel.showOfficePhone],
                allowContact: [this.editMemberModel.allowContact],
              });

              if (this.editMemberModel.imgLink) {
                this.myimgLink = this.editMemberModel.imgLink;
                this.profile_pic = this.editMemberModel.imgLink;
                this.showImageUpload = false;
              }

              setTimeout(() => {
                M.updateTextFields();
              }, 25);
            }
          }
        });
      }
    });
  }

  showEmailPrivacyRB() {
    if (this.getEmail.value) {
      if (this.getEmail.value.length > 1 && this.getEmail.valid) {
        this.showEmailPrivacy = true;
      } else { this.showEmailPrivacy = false; }
    } else { this.showEmailPrivacy = false; }
  }

  showOfficeNoPrivacyRB() {
    if (this.getOffice_phone.value) {
      if (this.getOffice_phone.value.length > 1 && this.getOffice_phone.valid) {
        this.showOfficePhonePrivacy = true;
      } else { this.showOfficePhonePrivacy = false; }
    } else { this.showOfficePhonePrivacy = false; }
  }

  showMobileNoPrivacyRB() {
    if (this.getCell_phone.value) {
      if (this.getCell_phone.value.length > 1 && this.getCell_phone.valid) {
        this.showMobilePrivacy = true;
      } else { this.showMobilePrivacy = false; }
    } else { this.showMobilePrivacy = false; }
  }

  showFaceBookPrivacyRB() {
    if (this.getChiefFacebookAcc.value) {
      if (this.getChiefFacebookAcc.value.length > 1 && this.getChiefFacebookAcc.valid) {
        this.showFacebookPrivacy = true;
      } else { this.showFacebookPrivacy = false; }
    } else { this.showFacebookPrivacy = false; }
  }

  showTwitterPrivacyRB() {
    if (this.getChiefTwitterAcc.value) {
      if (this.getChiefTwitterAcc.value.length > 1 && this.getChiefTwitterAcc.valid) {
        this.showTwitterPrivacy = true;
      } else { this.showTwitterPrivacy = false; }
    } else { this.showTwitterPrivacy = false; }
  }

  showInstagramPrivacyRB() {
    if (this.getChiefInstagramAcc.value) {
      if (this.getChiefInstagramAcc.value.length > 1 && this.getChiefInstagramAcc.valid) {
        this.showInstagramPrivacy = true;
      } else { this.showInstagramPrivacy = false; }
    } else { this.showInstagramPrivacy = false; }
  }

  showYouTubePrivacyRB() {
    if (this.getChiefYoutubeAcc.value) {
      if (this.getChiefYoutubeAcc.value.length > 1 && this.getChiefYoutubeAcc.valid) {
        this.showYoutubePrivacy = true;
      } else { this.showYoutubePrivacy = false; }
    } else { this.showYoutubePrivacy = false; }
  }

  showLinkedInPrivacyRB() {
    if (this.getChiefLinkedinAcc.value) {
      if (this.getChiefLinkedinAcc.value.length > 1 && this.getChiefLinkedinAcc.valid) {
        this.showLinkedInPrivacy = true;
      } else { this.showLinkedInPrivacy = false; }
    } else { this.showLinkedInPrivacy = false; }
  }

  get getFirstName() { return this.chiefForm.get('firstName'); }
  get getLastName() { return this.chiefForm.get('lastName'); }
  get getAppointmentDate() { return this.chiefForm.get('appointmentDate'); }
  get getExpiryDate() { return this.chiefForm.get('expiryDate'); }
  get getChiefBio() { return this.chiefForm.get('chiefBio'); }
  get getEmail() { return this.chiefForm.get('email'); }
  get getCell_phone() { return this.chiefForm.get('cell_phone'); }
  get getOffice_phone() { return this.chiefForm.get('office_phone'); }
  get getChiefFacebookAcc() { return this.chiefForm.get('chiefFacebookAcc'); }
  get getChiefInstagramAcc() { return this.chiefForm.get('chiefInstagramAcc'); }
  get getChiefTwitterAcc() { return this.chiefForm.get('chiefTwitterAcc'); }
  get getChiefLinkedinAcc() { return this.chiefForm.get('chiefLinkedinAcc'); }
  get getChiefYoutubeAcc() { return this.chiefForm.get('chiefYoutubeAcc'); }
  get imgLink() { return this.chiefForm.get('imgLink'); }

  onAppointmentDateChange(event: IMyDateModel): void {
    if (this.chiefForm) {
      this.chiefForm.patchValue({ appointmentDate: event.singleDate.formatted });
      this.appointmentDate = event.singleDate.formatted;
    }
  }

  onExpiryDateChange(event: IMyDateModel): void {
    if (this.chiefForm) {
      this.chiefForm.patchValue({ expiryDate: event.singleDate.formatted });
      this.expiryDate = event.singleDate.formatted;
    }
  }

  saveData(model: any, imgUrl: any) {
    if (this.editMemberModel) {
      this.editMemberModel.displayName = model.displayName;
      this.editMemberModel.appointmentDate = (this.appointmentDate) ? this.appointmentDate : '';
      this.editMemberModel.expiryDate = (this.expiryDate) ? this.expiryDate : '';
      this.editMemberModel.chiefBio = model.chiefBio;
      this.editMemberModel.chiefFacebookAcc = model.chiefFacebookAcc;
      this.editMemberModel.chiefInstagramAcc = model.chiefInstagramAcc;
      this.editMemberModel.chiefTwitterAcc = model.chiefTwitterAcc;
      this.editMemberModel.chiefLinkedinAcc = model.chiefLinkedinAcc;
      this.editMemberModel.chiefYoutubeAcc = model.chiefYoutubeAcc;
      this.editMemberModel.email = model.email;
      this.editMemberModel.cell_phone = model.cell_phone;
      this.editMemberModel.office_phone = model.office_phone;
      this.editMemberModel.imgLink = (imgUrl) ? imgUrl : this.editMemberModel.imgLink;
      this.editMemberModel.showEmail = model.showEmail;
      this.editMemberModel.showMobile = model.showMobile;
      this.editMemberModel.showFacebookAcc = model.showFacebookAcc;
      this.editMemberModel.showTwitterAcc = model.showTwitterAcc;
      this.editMemberModel.showInstagramAcc = model.showInstagramAcc;
      this.editMemberModel.showLinkedinAcc = model.showLinkedinAcc;
      this.editMemberModel.showYoutubeAcc = model.showYoutubeAcc;
      this.editMemberModel.showOfficePhone = model.showOfficePhone;
      this.editMemberModel.allowContact = model.allowContact;

      if (this.memberType === 'Chair') {
        this.boardModel.boardChair = [];
        this.boardModel.boardChair.push(this.editMemberModel);
      }
      if (this.memberType === 'Member') {
        let updateItem = this.boardModel.boardMember.find(m => Number(m.id) === Number(this.editMemberModel.id));
        let index = this.boardModel.boardMember.indexOf(updateItem);
        this.boardModel.boardMember[index] = this.editMemberModel;
      }
    }

    this.govService.updateBoard(this.boardModel).then(up => {
      if (this.editMemberModel) {
        if (this.memberType === 'Chair') {
          toast({ html: 'Chair Of The Board Successfully Updated', classes: 'green' });
        }
        if (this.memberType === 'Member') {
          toast({ html: 'Board Member Successfully Updated', classes: 'green' });
        }
      }

      this.backClicked();
    });
  }

  processFormBeforeSaving(modelToProcess: any) {
    let imgUrl = '';
    if (this.binDoc && this.oldUrl) {
      this.mediaService.UpdateBinImage(this.binDoc, this.oldUrl).subscribe(
        upload => {
          if (upload) {
            imgUrl = upload.imgLink;
            this.saveData(modelToProcess, imgUrl);
            return;
          }
        }
      );
    } else if (this.binDoc && !this.oldUrl) {
      this.mediaService.UploadBinImage(this.binDoc).subscribe(
        upload => {
          if (upload) {
            imgUrl = upload.imgLink;
            this.saveData(modelToProcess, imgUrl);
            return;
          }
        }
      );
    } else if (!this.binDoc && this.oldUrl) {
      this.mediaService.DeleteBinImage(this.binDoc).subscribe(
        upload => {
          if (upload) {
            imgUrl = upload.imgLink;
            this.saveData(modelToProcess, imgUrl);
            return;
          }
        });
    } else if (!this.binDoc && !this.oldUrl) {
      this.saveData(modelToProcess, imgUrl);
      return;
    }
  }

  backClicked() {
    this.location.back();
  }

  handleFileInput(files: FileList) {
    if (!this.validateImage(files[0].name)) {
      alert('Invalid File Type, Please Select an Image File');
      return;
    }
    this.binDoc = files[0];
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.base64Image = reader.result as string;
      this.profile_pic = reader.result;
    };
  }

  removeSavedFile() {
    this.binDoc = undefined;
    this.base64Image = undefined;
    this.oldUrl = (this.myimgLink) ? this.myimgLink : undefined;
    this.myimgLink = '';
    this.profile_pic = undefined;
    this.showImageUpload = true;
  }

  private validateImage(name: any) {
    const ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() === 'png') {
      return true;
    } else if (ext.toLowerCase() === 'jpg') {
      return true;
    } else if (ext.toLowerCase() === 'jpeg') {
      return true;
    } else if (ext.toLowerCase() === 'svg') {
      return true;
    } else if (ext.toLowerCase() === 'webp') {
      return true;
    } else {
      return false;
    }
  }

}
