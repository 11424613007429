import { Component, OnInit } from "@angular/core";
import { AyrshareService } from "src/app/services/social-media/ayrshare.service";
import { PostModel } from "src/app/models/SocialMediaPostModel";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from "@angular/forms";
import { AuthService } from "src/app/services/firebase/auth.service";
import { Router } from "@angular/router";
import { ActivatedRoute } from "@angular/router";
import { Location } from "@angular/common";
import { ImageModel } from "src/app/models/ImageModel";
import { MediaService } from "src/app/services/api/media.service";
import { icons } from "src/app/models/SocialMediaIcons";
import { ValidationService } from "src/app/services/social-media/validation.service";
import { ProfieKeyService } from "src/app/services/social-media/profileKey.service";
import { HelperService } from "src/app/services/helper/helper";
import { AppSettingsService } from "src/app/services/firebase/app-settings.service";
import { toast } from "materialize-css";

declare var $: any;

@Component({
  selector: "app-social-media-create-post",
  templateUrl: "./create-post.component.html",
  styleUrls: ["./create-post.component.css"],
})
export class SocialMediaCreatePostComponent implements OnInit {
  defaultThemeColor = "#000000";
  themeColor: any;
  buttonType: any;

  availablePlatforms = [];
  profileKey;
  postForm: UntypedFormGroup;
  imgErrorMsg;
  previewModalOpen = false;

  // toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;
  socialMediaIcons = icons;
  isList;
  objectKeys = Object.keys;

  hasActiveAccounts = false;
  userProfile;
  loading = false;
  previewData;

  isMobile = false;

  cancelModalOpen = false;
  currentUser: any;
  notifSettings;

  uploadingMedia = false;
  videoUploading = false;
  imageUploading = false;
  sendingPost = false;

  constructor(
    private socialMediaService: AyrshareService,
    private fb: UntypedFormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private mediaService: MediaService,
    private validationService: ValidationService,
    private profieKeyService: ProfieKeyService,
    private settingsService: AppSettingsService) {
    this.themeColor = this.settingsService.getAppThemeColor() ? this.settingsService.getAppThemeColor() : this.defaultThemeColor;
    this.buttonType = this.settingsService.getButtonType() ? this.settingsService.getButtonType() : "rectangularButton";
  }

  ngOnInit() {
    this.openToast = false;
    this.profieKeyService.currentProfileKey.subscribe((p) => {
      this.profileKey = p;
    });

    this.getUserProfile();

    this.postForm = this.fb.group({
      post: ["", [Validators.required]],
      schedule: ["Immediate"],
      scheduleDate: [""],
      scheduleTime: [""],
      platforms: this.fb.array([], [Validators.required, this.validationService.notFalseValueValidator]),
      imageUrls: this.fb.array([]),
      videoUrls: this.fb.array(
        [],
        [this.validationService.imageMaxLengthValidator(1)]
      ),
    });

    if (this.profieKeyService.shouldShare) {
      this.patchPostForm();
    }
    console.log(this.platforms.value, this.hasActiveAccounts, "does actie accounr");

    this.imageUrls.valueChanges.subscribe((new_value) =>
      this.checkPlatformsValidity()
    );
    this.videoUrls.valueChanges.subscribe((new_value) =>
      this.checkPlatformsValidity()
    );

    setTimeout(() => {
      $(".modal").modal();
      $(".dropdown-trigger").dropdown();
    }, 25);
  }

  getUserProfile() {
    if (this.profileKey) {
      this.loading = true;
      this.socialMediaService
        .getUserByProfileKey(this.profileKey)
        .toPromise()
        .then((data) => {
          this.userProfile = data;
          this.hasActiveAccounts = true
            ? data["activeSocialAccounts"] &&
            data["activeSocialAccounts"].length > 0
            : false;
          this.loading = false;

          if (this.hasActiveAccounts) {
            this.availablePlatforms = data["activeSocialAccounts"];
            this.availablePlatforms.map((p) =>
              this.platforms.push(this.newPlatform(p))
            );
            console.log(this.platforms.value, "the platforms available");
          }
        })
        .catch((err) => {
          this.loading = false;
        });
    }
  }

  patchPostForm() {
    this.profieKeyService.currentShareData.subscribe((values) => {
      if (values) {
        this.post.patchValue(values["post"]);

        if (values["imageUrls"] && values["imageUrls"].length > 0) {
          values["imageUrls"].map((url) =>
            this.imageUrls.push(new UntypedFormControl(url))
          );
        }

        if (values["videoUrls"] && values["videoUrls"].length > 0) {
          values["videoUrls"].map((url) =>
            this.videoUrls.push(new UntypedFormControl(url))
          );
        }
      }
      this.profieKeyService.shouldShare = false;
    });
  }

  get scheduleDate() {
    return this.postForm.get("scheduleDate");
  }
  get post() {
    return this.postForm.get("post");
  }
  get scheduleTime() {
    return this.postForm.get("scheduleTime");
  }

  get platforms() {
    return <UntypedFormArray>this.postForm.get("platforms");
  }

  get imageUrls() {
    return <UntypedFormArray>this.postForm.get("imageUrls");
  }
  get videoUrls() {
    return <UntypedFormArray>this.postForm.get("videoUrls");
  }

  pickSchedule(event) {
    if (event.target.value == "Schedule") {
      this.postForm.controls["scheduleDate"].setValidators(Validators.required);
      this.postForm.controls["scheduleTime"].setValidators(Validators.required);
    } else {
      this.postForm.controls["scheduleDate"].patchValue("");
      this.postForm.controls["scheduleDate"].clearValidators();
      this.postForm.controls["scheduleTime"].patchValue("");
      this.postForm.controls["scheduleTime"].clearValidators();
    }
    this.postForm.controls["scheduleDate"].updateValueAndValidity();
    this.postForm.controls["scheduleTime"].updateValueAndValidity();
    this.postForm.updateValueAndValidity();
  }

  onPlatformError(pl) {
    let index = this.platforms.value.findIndex((p) => p.platform == pl);
    let platfrom = this.platforms.at(index).get("value");
    platfrom.patchValue(false);
    platfrom.updateValueAndValidity();
  }

  checkPlatformsValidity() {
    this.platforms.controls.forEach((platform) => {
      this.isValidPlatform(platform as UntypedFormGroup);
    });
  }

  isValidPlatform(platformGroup: UntypedFormGroup) {
    let videoRequiredPlatforms = ["tiktok", "youtube"];
    let imageRequiredPlatforms = ["pinterest"];
    let imageOrVideoRequiredPlatorms = ["instagram"];

    let noVideoPlatforms = ["pinterest"];
    let noImagePlatforms = ["youtube", "tiktok"];
    let noMediaPlatforms = ["fbg", "gmb", "reddit"];

    let platform = platformGroup.get("platform").value;

    let platformHasError = false;
    if (
      this.imageUrls.value.length == 0 &&
      imageRequiredPlatforms.includes(platform)
    ) {
      platformHasError = true;
    } else if (
      this.videoUrls.value.length == 0 &&
      videoRequiredPlatforms.includes(platform)
    ) {
      platformHasError = true;
    } else if (
      imageOrVideoRequiredPlatorms.includes(platform) &&
      this.videoUrls.value.length == 0 &&
      this.imageUrls.value.length == 0
    ) {
      platformHasError = true;
    } else if (
      noVideoPlatforms.includes(platform) &&
      this.videoUrls.value.length > 0
    ) {
      platformHasError = true;
    } else if (
      noImagePlatforms.includes(platform) &&
      this.imageUrls.value.length > 0
    ) {
      platformHasError = true;
    } else if (
      noMediaPlatforms.includes(platform) &&
      (this.imageUrls.value.length > 0 || this.videoUrls.value.length > 0)
    ) {
      platformHasError = true;
    } else {
      platformHasError = false;
    }

    if (platformHasError) {
      platformGroup.get("value").patchValue(false);
      platformGroup.get("value").updateValueAndValidity();
    } else {
    }
    platformGroup.get("value").updateValueAndValidity();
    platformGroup.updateValueAndValidity();
  }

  newPlatform(platform, value = false) {
    return this.fb.group({
      platform: [platform],
      value: [value],
    });
  }

  cancel() {
    this.router.navigate(["../dashboard"], {
      relativeTo: this.activatedRoute,
    });
  }

  handleMobilePhotoInput() {
    // navigator.camera.getPicture((image: any) => {
    //   const imageName = 'IMG.jpeg';
    //   const imageBlob = this.dataURItoBlob(image);
    //   const imageFile = new File([imageBlob], imageName, { type: 'image/jpeg' });
    //   const img = new ImageModel();
    //   let base64Image = image
    //   img.imgBase64 = base64Image.replace(/^data:image\/[a-z]+;base64,/, '')
    //   this.mediaService.uploadImage(img).subscribe(upload => {
    //     if (upload) {
    //       // this.addMediaUrl(upload.imgLink, 'image')
    //       this.imageUrls.push(new FormControl(upload.imgLink))
    //     }
    //   });
    //   // this.binDoc = imageFile;
    //   // this.base64Image = 'data:image/jpeg;base64,' + image;
    //   // this.mobileBase64Image = image;
    // }, (error) => {
    //   alert(error);
    // },
    // {
    //   quality: 50,
    //   destinationType: navigator.camera.DestinationType.DATA_URL,
    //   encodingType: navigator.camera.EncodingType.JPEG,
    //   mediaType: navigator.camera.MediaType.PICTURE,
    //   sourceType: navigator.camera.PictureSourceType.PHOTOLIBRARY,
    // }
    // );
  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: "image/png" });
    return blob;
  }

  handleFileInput(files: FileList) {
    if (!this.validateImage(files[0].name)) {
      this.imgErrorMsg = "Invalid File Type, Please Select an Image File";
      return;
    }

    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      const img = new ImageModel();
      let base64Image = reader.result as string;
      img.imgBase64 = base64Image.replace(/^data:image\/[a-z]+;base64,/, "");
      this.imageUploading = true;
      this.uploadingMedia = true;

      this.mediaService.uploadImage(img).subscribe((upload) => {
        this.imageUploading = false;
        this.uploadingMedia = false;
        if (upload) {
          // this.addMediaUrl(upload.imgLink, 'image')
          this.imageUrls.push(new UntypedFormControl(upload.imgLink));
        }
      });
    };
  }

  handleVideoInput(files: FileList) {
    let video = files[0];
    this.videoUploading = true;
    this.uploadingMedia = true;

    this.mediaService.uploadVideo(video).subscribe((x) => {
      this.videoUploading = false;
      this.uploadingMedia = false;
      if (x) {
        this.videoUrls.push(new UntypedFormControl(x.imgLink));
      }
    });
  }

  private validateImage(name: any) {
    const ext = name.substring(name.lastIndexOf(".") + 1);
    if (ext.toLowerCase() === "png") {
      return true;
    } else if (ext.toLowerCase() === "jpg") {
      return true;
    } else if (ext.toLowerCase() === "jpeg") {
      return true;
    } else if (ext.toLowerCase() === "svg") {
      return true;
    }
    else if (ext.toLowerCase() === "webp") {
      return true;
    } else {
      return false;
    }
  }

  removeImage(index) {
    this.imageUrls.removeAt(index);
  }

  removeVideo(index) {
    this.videoUrls.removeAt(index);
  }

  pickedPlatforms(platforms) {
    return platforms.reduce((res, p) => {
      if (p.value) res.push(p.platform);
      return res;
    }, []);
  }

  openPreviewModal() {
    this.previewModalOpen = true;
    let platform = this.pickedPlatforms(this.platforms.value)[0];
    this.previewPlatform(platform);
  }

  previewPlatform(p) {
    this.previewData = this.postForm.value;
    this.previewData["platform"] = p;
    this.previewData["user"] = this.userProfile.displayNames.filter(
      (u) => u.platform == p
    )[0];
  }

  addMediaUrls(model, values) {
    model.mediaUrls = values.imageUrls.concat(values.videoUrls);
    model.mediaUrls.length <= 0 ? delete model.mediaUrls : model.mediaUrls;
    delete values.imageUrls;
    delete values.videoUrls;
    return model;
  }

  addPlatformOptions(model, values) {
    if (values.platforms.includes("reddit")) {
      model["redditOptions"] = {
        title: values.post, // required
        subreddit: "test", // required (no "/r/" needed)
      };
    }

    if (values.platforms.includes("youtube")) {
      model["youTubeOptions"] = {
        title: values.post, // required
        youTubeVisibility: "public",
      };
    }

    if (values.platforms.includes("instagram") && values.imageUrls.length > 0) {
      model["instagramOptions"] = {
        autoResize: true,
      };
    }
    return model;
  }

  submitPost(values) {
    values.platforms = this.pickedPlatforms(values.platforms);

    let model = new PostModel();
    this.addPlatformOptions(model, values);

    if (values.schedule == "Schedule") {
      values.scheduleDate = new Date(
        values.scheduleDate + " " + values.scheduleTime
      ).toISOString();
    } else {
      delete values.scheduleDate;
    }

    this.addMediaUrls(model, values);
    Object.keys(values).forEach((key) => {
      model[key] = values[key];
    });

    model.profileKeys.push(this.profileKey);

    this.sendingPost = true;
    this.socialMediaService
      .sendPost(model)
      .toPromise()
      .then((message) => {
        this.sendingPost = false;
        //   this.sendPushNotificationToOwner(model.platforms)
        toast({
          html: "Posted Successfully",
          classes: "green",
        });
        setTimeout(() => {
          this.router.navigate(["../dashboard"], {
            relativeTo: this.activatedRoute,
          });
        }, 2000);
      })
      .catch((message) => {
        this.sendingPost = false;
        console.log(message);
        message["error"]["posts"].forEach((post) => {
          post.errors.forEach((error) => {
            toast({
              html: `${error.platform ? error.platform : ""} : ${[170, 260].includes(error.code)
                ? error.message.split(".")[0]
                : error.message
                }`,
              classes: "red",
            });
            console.log(`${error.platform} : ${error.message}`);
          });
        });
      });
  }
}
