import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiKeyManagerService } from './apiKeyManager.Service';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RegistrationRequestService {

  url = environment.firstNation.apiUrl + 'api/RegistrationRequest/';

  constructor(private httpClient: HttpClient,  private keyManager: ApiKeyManagerService) { }

  checkIFMemberExist(model: any): Observable<any> {
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
    return this.httpClient.post<any>(this.url + 'checkIfClientExist/', JSON.stringify(model), { headers });
  }

  saveRegistrationRequest(model: any): Observable<any> {
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
    return this.httpClient.post<any>(this.url + 'SaveRegistrationRequest/', JSON.stringify(model), { headers });
  }


  
  // saveISETRegistrationRequest(model: any): Observable<any> {
  //   const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
  //   return this.httpClient.post<any>(this.url + 'SaveISETRegistrationRequest/', JSON.stringify(model), { headers });
  // }




  getRegistrationRequests(): Observable<any[]> {
    const headers = new HttpHeaders().set('SOCIALAPIKEY', this.keyManager.getKey());
    return this.httpClient.get<any[]>(this.url + 'GetRegistrationRequest/', { headers });
    }


  // getISETRegistrationRequests(): Observable<any[]> {
  //   const headers = new HttpHeaders().set('SOCIALAPIKEY', this.keyManager.getKey());
  //   return this.httpClient.get<any[]>(this.url + 'GetISETRegistrationRequest/', { headers });
  // }



  getRegistrationRequestByID(id: number): Observable<any[]> {
    const headers = new HttpHeaders().set('SOCIALAPIKEY', this.keyManager.getKey());
    return this.httpClient.get<any[]>(this.url + 'GetRegistrationRequestByID/' + id, { headers });
  }

  getTreatyNumber(): Observable<any> {
    const headers = new HttpHeaders().set('SOCIALAPIKEY', this.keyManager.getKey());
    return this.httpClient.get<any>(this.url + 'GetNationTreatyNumber/', { headers });
  }

  updateRequestStatus(model: any): Observable<any> {
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
    return this.httpClient.post<any>(this.url + 'UpdateRequestStatus', JSON.stringify(model), { headers });
  }

  deleteRequet(id: number): Observable<any[]> {
    const headers = new HttpHeaders().set('SOCIALAPIKEY', this.keyManager.getKey());
    return this.httpClient.get<any[]>(this.url + 'DeleteRequest/' + id, { headers });
  }

}
