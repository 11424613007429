<div class="row">
  <div class="col m1 l2 xl3"></div>
  <div class="col s12 m10 l8 xl6">
    
    <div class="card">
      <div class="card-content">
        <span class="card-title center">
          <h5>Thank You for Registering</h5>
        </span>
        <div class="section"></div>
        <div class="row">
          <h6>A verification link was sent to your email account.</h6>
          <br />
          <p>
            Please click on the link that has been sent to you to verify your email and complete your registration.
          </p>
          <div class="section"></div>
          <h6 class="red-text">Didn't get the email?</h6>
          <p>
            Check your spam folder to make sure it didn't end up there or click on the resend link button.
          </p>
          <div class="section"></div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="input-field col s12 center">
        <button (click)="GoToLogin()" class='col s12 btn waves-effect success'>
          <i class="material-icons left">how_to_reg</i> Go To Login Page
        </button>
      </div>
    </div>

  </div>
  <div class="col m1 l2 xl3"></div>
</div>