<div class="row" *ngIf="socialMediaModel" >

    <div class="center ">

        <a [href]="socialMediaModel.twitter" *ngIf="socialMediaModel.twitter" target="_blank">
            <div class="iconwrap" style="background-color: #33CCFF; margin-left: 2%; ">
                <div class="hover"></div>
                <div class="icon">
                    <i class="fa fa-twitter"
                        style="color: white; font-size: 40px; margin-left: 12px; margin-top: 20px; z-index: -1; "></i>
                </div>
            </div>
        </a>
        <a [href]="socialMediaModel.facebook" *ngIf="socialMediaModel.facebook" target="_blank">
            <div class="iconwrap" style="background-color: #3b539b; margin-left: 2%; ">
                <div class="hover"></div>
                <div class="icon">
                    <i class="fa fa-facebook"
                        style="color: white; font-size: 40px; margin-left: 12px; margin-top:20px; z-index: -1; "></i>
                </div>
            </div>
        </a>
        <a [href]="socialMediaModel.youtube" *ngIf="socialMediaModel.youtube" target="_blank">
            <div class="iconwrap" style="background-color: red; margin-left: 2%;">
                <div class="hover"></div>
                <div class="icon">
                    <i class="fa fa-youtube-play"
                        style="color: white; font-size: 40px; margin-left: 12px; margin-top: 20px; z-index: -1; "></i>
                </div>
            </div>
        </a>
        <a [href]="socialMediaModel.instagram" *ngIf="socialMediaModel.instagram" target="_blank">
            <div class="iconwrap insta-color" style="margin-left: 2%; ">
                <div class="hover"></div>
                <div class="icon">
                    <i class="fa fa-instagram"
                        style="color: white; font-size: 40px; margin-left: 12px; margin-top: 20px; z-index: -1; "></i>
                </div>
            </div>
        </a>

    </div>
</div>