export class AssetsModel {
 
    applicantID: number;
    applicationNo: number;
    catagory: string;
    value: number;
    description: string;
    member_name: string;
    Fam_Mem_Type: string; 
    verified: boolean;
    type: string;

  }
