import { MediaService } from 'src/app/services/api/media.service';
import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { GovernanceService } from 'src/app/services/firebase/governance.service';
import { toast } from 'materialize-css';
import { environment } from 'src/environments/environment';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-add-board',
  templateUrl: './add-board.component.html',
  styleUrls: ['./add-board.component.css']
})
export class AddBoardComponent implements OnInit {

  id: any;
  boardModel: any;
  addBoardForm: UntypedFormGroup;
  public phoneNumberMask = ['(', /[0-9]/, /[0-9]/, /[0-9]/, ')', ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  mobilePattern = /^\s*([\(])[[0-9]{2}\d{1}[\)]\s*[\-]?[\.]?\s*\d{3}\s*[\-]?[\.]?\s*\d{4}$/;
  public urlPattern = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';
  themeColor = environment.appTheme.adminThemeColor;
  imgErrorMsg: any;
  base64Image: any;
  binDoc: File;
  showLogoInput = true;
  showImageFromServer = false;
  oldUrl: any;
  isAdd: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private location: Location,
    private router: Router,
    private route: ActivatedRoute,
    private govService: GovernanceService,
    private mediaService: MediaService) {

    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });
  }

  get name() { return this.addBoardForm.get('name'); }
  get nameColor() { return this.addBoardForm.get('nameColor'); }
  get mandate() { return this.addBoardForm.get('mandate'); }
  get phone() { return this.addBoardForm.get('phone'); }
  get email() { return this.addBoardForm.get('email'); }
  get website() { return this.addBoardForm.get('website'); }
  get logo() { return this.addBoardForm.get('logo'); }

  ngOnInit() {
    this.addBoardForm = this.formBuilder.group({
      name: ['', [Validators.required]],
      nameColor: ['#000000'],
      mandate: [''],
      phone: ['', [Validators.pattern(this.mobilePattern)]],
      email: ['', [Validators.email]],
      website: ['', [Validators.pattern(this.urlPattern)]],
      logo: ['']
    });


    if (this.id) {
      this.govService.getBoardById(this.id).valueChanges().subscribe(b => {
        if (b) {
          this.boardModel = b;
          this.addBoardForm.setValue({
            name: this.boardModel.name,
            nameColor: this.boardModel.nameColor,
            mandate: this.boardModel.mandate,
            phone: this.boardModel.phone,
            email: this.boardModel.email,
            website: this.boardModel.website ? this.boardModel.website : '',
            logo: ''
          });

          if (this.boardModel.logo) {
            this.showLogoInput = false;
            this.showImageFromServer = true;
            this.oldUrl = this.boardModel.logo;
          }
          setTimeout(() => {
            M.updateTextFields();
            M.textareaAutoResize($('#mandate'));
          }, 25);
        }
      });
    }
    $(document).ready(() => {
      $('.modal').modal();
    });

  }

  addBoard(model: any) {
    let board: any;
    if (!this.boardModel && !this.id) {
      this.isAdd = true;
      board = {
        id: '',
        name: model.name,
        nameColor: model.nameColor,
        mandate: model.mandate,
        phone: model.phone,
        email: model.email,
        website: model.website,
        logo: '',
        boardChair: [],
        boardMembers: []
      };
    }

    if (this.boardModel && this.id) {
      this.isAdd = false;
      this.boardModel.name = model.name;
      this.boardModel.nameColor = model.nameColor;
      this.boardModel.mandate = model.mandate;
      this.boardModel.phone = model.phone;
      this.boardModel.email = model.email;
      this.boardModel.website = model.website;
    }

    if (this.binDoc && this.oldUrl) {
      this.mediaService.UpdateBinImage(this.binDoc, this.oldUrl).subscribe(upload => {
        if (upload) {
          if (this.isAdd) {
            board.logo = upload.imgLink;
            this.govService.addBoard(board).then(x => {
              toast({ html: 'Board Successfully Saved', classes: 'green' });
              this.backClicked();
            });
          } else {
            this.boardModel.logo = upload.imgLink;
            this.govService.updateBoard(this.boardModel).then(x => {
              toast({ html: 'Board Successfully Updated', classes: 'green' });
              this.backClicked();
            });
          }
        }
      });
    } else if (this.binDoc && !this.oldUrl) {
      this.mediaService.UploadBinImage(this.binDoc).subscribe(upload => {
        if (upload) {
          if (this.isAdd) {
            board.logo = upload.imgLink;
            this.govService.addBoard(board).then(x => {
              toast({ html: 'Board Successfully Saved', classes: 'green' });
              this.backClicked();
            });
          } else {
            this.boardModel.logo = upload.imgLink;
            this.govService.updateBoard(this.boardModel).then(x => {
              toast({ html: 'Board Successfully Updated', classes: 'green' });
              this.backClicked();
            });
          }
        }
      });
    } else {
      if (this.isAdd) {
        this.govService.addBoard(board).then(x => {
          toast({ html: 'Board Successfully Saved', classes: 'green' });
          this.backClicked();
        });
      } else {
        this.govService.updateBoard(this.boardModel).then(x => {
          toast({ html: 'Board Successfully Updated', classes: 'green' });
          this.backClicked();
        });
      }
    }
  }

  deleteBoard() {
    $('#deleteBoardConfirmation').modal('close');
    this.govService.deleteBoard(this.boardModel.id).then(x => {
      toast({ html: 'Demo Request Successfully Deleted', classes: 'green' });
      this.backClicked();
    });
  }

  backClicked() {
    this.location.back();
  }

  openDeleteModal() {
    $('#deleteBoardConfirmation').modal('open');
  }

  handleImageInput(files: FileList) {
    if (!this.validateImage(files[0].name)) {
      this.imgErrorMsg = 'Invalid File Type, Please Select an Image File';
      this.logo.patchValue('');
      return;
    }
    this.binDoc = files[0];
    this.imgErrorMsg = undefined;
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.base64Image = reader.result as string;
    };
  }

  removeFile() {
    this.binDoc = undefined;
    this.base64Image = undefined;
    this.logo.patchValue('');
  }

  removeSavedFile() {
    this.binDoc = undefined;
    this.base64Image = undefined;
    this.oldUrl = this.boardModel.logo;
    this.boardModel.logo = '';
    this.logo.patchValue('');
    this.showImageFromServer = false;
    this.showLogoInput = true;
  }

  private validateImage(name: any) {
    const ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() === 'png') {
      return true;
    } else if (ext.toLowerCase() === 'jpg') {
      return true;
    } else if (ext.toLowerCase() === 'jpeg') {
      return true;
    }
    else if (ext.toLowerCase() === 'svg') {
      return true;
    }
    else if (ext.toLowerCase() === 'webp') {
      return true;
    } else {

      return false;
    }
  }

  navigate(item) {
    const routeMappings = {
      'AboutUs': 'admin/dashboard-sub-menu/about-us',
      'NationInfo': 'admin/dashboard-sub-menu/nation-settings',
      'Departments': 'admin/dashboard-sub-menu/department',
      'Governance': 'admin/dashboard-sub-menu/governance',
      'AreaOfInterest': 'admin/dashboard-sub-menu/areas-of-interest',
    };

    const route = routeMappings[item];
    if (route) {
      this.router.navigate([route]);
    }
  }

}
