import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { EntitlementModel } from 'src/app/models/entitlementModel';
import { EntitlementService } from 'src/app/services/api/entitlement.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { Location } from '@angular/common';
import { SignatureModel } from 'src/app/models/signatureModel';
import SignaturePad from 'signature_pad';
import { AppInfoService } from 'src/app/services/api/app-info.service';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-sign-multiple-bd',
  templateUrl: './sign-multiple-bd.component.html',
  styleUrls: ['./sign-multiple-bd.component.css']
})

export class SignMultipleBdComponent implements OnInit {

  entitlement: EntitlementModel[];
  compliance: any[];
  years: number[];
  data: any[];
  result: any = null;
  applicantID = 0;
  model: SignatureModel;
  canvas: any;
  signaturePad: SignaturePad;
  step0 = true;
  step1 = false;
  step2 = false;
  step3 = false;
  currentYear = new Date().getFullYear();
  currentMonth = new Date().getMonth();
  fiscalYear: any;
  isSpouse = false;
  showMessage = false;

  constructor(private authService: AuthService, private entitlementService: EntitlementService,
    private location: Location, private appInfoService: AppInfoService) { }

  ngOnInit() {
    if (this.authService.globalUser.applicantID) {
      this.applicantID = Number(this.authService.globalUser.applicantID);
    } else {
      this.applicantID = this.authService.getCurrentApplicantID();
    }
    this.appInfoService.getBDCompliance(this.applicantID).subscribe(comp => {
      if (comp) {
        if (comp.length > 0) {
          if (comp[0].SpouseAID) {
            this.isSpouse = ((comp[0].SpouseAID.toString() === this.applicantID.toString())
              ? true : false);
          }
        }
      }
    });

    this.entitlementService.getEntitlement(this.applicantID).subscribe(x => {
      this.entitlement = x;
      this.years = Array.from(new Set(this.entitlement.map(a => a.Year))).sort((a, b) => b - a);

      setTimeout(() => {
        $('select').formSelect();
      }, 25);
    });
  }

  newYearSelected(value: number, fiscalYear1: any, fiscalYear2: any) {
    this.data = [];
    this.fiscalYear = fiscalYear1 + ' - ' + fiscalYear2;

    for (const entry of this.entitlement) {
      if (!entry.date_signed_applicant) {

        if ((Number(entry.Year) === Number(value) && (
          entry.Month === 'April'
          || entry.Month === 'May'
          || entry.Month === 'June'
          || entry.Month === 'July'
          || entry.Month === 'August'
          || entry.Month === 'September'
          || entry.Month === 'October'
          || entry.Month === 'November'
          || entry.Month === 'December'
        )) || (Number(entry.Year) === Number(value + 1) && (
          entry.Month === 'January'
          || entry.Month === 'February'
          || entry.Month === 'March'
        ))) {
          this.data.push(entry);
        }

        if (Number(entry.Year) === Number(value + 1) && (
          entry.Month === 'January'
          || entry.Month === 'February'
          || entry.Month === 'March'
        )) {
          this.data.push(entry);
        }
      }
    }

    this.result = Array.from(new Set(this.data.map(a => a.id))).map(id => {
      const obj = {
        id,
        BDno: this.data.find(x => x.id === id).BDNo,
        Month: this.data.find(x => x.id === id).Month,
        Year: this.data.find(x => x.id === id).Year,
        // Core_essentials: this.data.find(x => x.id === id).Core_essentials,
        // Core_Shelter: this.data.find(x => x.id === id).Core_Shelter,
        // Special_needs: this.data.find(x => x.id === id).Special_needs,
        // COPH: this.data.find(x => x.id === id).COPH,
        // Amount: this.data.find(x => x.id === id).Amount,TotalAmount
        TotalAmount: this.data.find(x => x.id === id).TotalAmount,
        PayableTo: this.data.find(x => x.id === id).PayableTo,
        dateCreated: this.dateCreated(this.data.find(x => x.id === id).Month, this.data.find(x => x.id === id).Year),
        isChecked: false
      };
      setTimeout(() => {
        this.step0 = false;
        this.step1 = true;
        $('.collapsible').collapsible();
      }, 25);
      return obj;
    }).sort((a, b) => +new Date(b.dateCreated) - +new Date(a.dateCreated));

    if (this.result.length === 0) {
      this.showMessage = true;
      this.step0 = false;
      this.step1 = true;
    }
  }

  checkChange(value: any) {
    value.isChecked = !value.isChecked;
  }

  next() {
    const dblist: any[] = [];
    this.result.forEach(element => {
      if (element.isChecked) {
        dblist.push(element.BDno);
      }
    });
    if (dblist.length === 0) {
      M.toast({ html: 'Please Select A B&D From The List.', classes: 'red' });
    } else {
      this.model = new SignatureModel();
      this.model.bdList = dblist;

      this.step1 = false;
      this.step2 = true;

      setTimeout(() => {
        this.canvas = document.querySelector('canvas');
        const parentWidth = $(this.canvas).parent().outerWidth();
        this.canvas.setAttribute('width', parentWidth);
        this.canvas.setAttribute('height', '175px');
        this.signaturePad = new SignaturePad(this.canvas, {
          backgroundColor: 'rgb(255,255,255)'
        });
      }, 50);
    }
  }

  submit() {
    if (this.signaturePad.isEmpty()) {
      M.toast({ html: 'Please Signe The Entitlements!', classes: 'red' });
      return;
    }

    this.model.signature = this.signaturePad.toDataURL().replace('data:image/png;base64,', '');
    this.model.applicantName = this.authService.getGlobalUser().GivenName + ' ' + this.authService.getGlobalUser().LastName;

    if (this.isSpouse) {
      this.entitlementService.PostSpouseMultipleSignature(this.model).subscribe(x => {
        M.toast({ html: 'Entitlements Successfully Signed!', classes: 'green' });
        this.step2 = false;
        this.step3 = true;
        // this.emailService.sendBDApprovalNotification(this.entitlements[0].GivenName + ' ' + this.entitlements[0].LastName);
      });
    } else {

      this.entitlementService.PostMultipleSignature(this.model).subscribe(x => {
        M.toast({ html: 'Entitlements Successfully Signed!', classes: 'green' });
        this.step2 = false;
        this.step3 = true;
        // this.emailService.sendBDApprovalNotification(this.entitlements[0].GivenName + ' ' + this.entitlements[0].LastName);
      });
    }
  }

  clearSignature() {
    this.signaturePad.clear();
  }

  back0() {
    this.fiscalYear = undefined;
    this.step0 = true;
    this.step1 = false;
  }

  back() {
    this.step1 = true;
    this.step2 = false;
    setTimeout(() => {
      $('select').formSelect();
    }, 25);
  }

  dateCreated(month: string, year: number) {
    const months = ['January', 'February', 'March', 'April',
      , 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const monthIndex = months.indexOf(month);
    return new Date(year, monthIndex, 1);
  }

  backClicked() {
    this.location.back();
  }

}
