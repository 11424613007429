import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MemberModel } from '../../models/memberModel';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ApiKeyManagerService } from './apiKeyManager.Service';
import { SearchMemberModel } from 'src/app/models/searchMemberModel';
import { UpdateEmailModel } from 'src/app/models/UpdateEmailModel';
import { BandMemberModel } from 'src/app/models/BandMemberModel';
import { FindMemberModel } from 'src/app/models/FindMemberModel';
import { AddBandMemberModel, MemberMatchModel } from 'src/app/models/AddMemberModel';


@Injectable({
  providedIn: 'root'
})
export class MemberService {

  constructor(private httpClient: HttpClient, private keyManager: ApiKeyManagerService) { }

  getMember(id: number): Observable<any> {
    const url = environment.firstNation.apiUrl + 'api/members/Get/' + id;
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
    return this.httpClient.get<any>(url, { headers });
  }

  getMembers(): Observable<MemberModel[]> {
    const url = environment.firstNation.apiUrl + 'api/members/Get';
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
    return this.httpClient.get<any>(url, { headers });
  }

  getMembersCount(): Observable<any> {
    const url = environment.firstNation.apiUrl + 'api/members/GetMembersCount';
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
    return this.httpClient.get<any>(url, { headers });
  }

  getManageMembersList(): Observable<MemberModel[]> {
    const url = environment.firstNation.apiUrl + 'api/members/GetMembersList';
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
    return this.httpClient.get<any>(url, { headers });
  }

  searchMemberByName(model: SearchMemberModel): Observable<any> {
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
    return this.httpClient.post<any>(environment.firstNation.apiUrl + 'api/members/SearchMemberByName', JSON.stringify(model), { headers });
  }

  updateInvitationStatus(id: any): Observable<any> {
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
    const formData: FormData = new FormData();
    formData.append('applicantID', id);
    return this.httpClient.post<any>(environment.firstNation.apiUrl + 'api/members/UpdateInvitationStatus', formData, { headers });
  }



  findMemberByName(model: SearchMemberModel): Observable<any> {
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
    return this.httpClient.post<any>(environment.firstNation.apiUrl + 'api/members/findMemberByName', JSON.stringify(model), { headers });
  }



  findFamilyMemberByName(model: FindMemberModel): Observable<any> {
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
    return this.httpClient.post<any>(environment.firstNation.apiUrl + 'api/members/findMemberByName', JSON.stringify(model), { headers });
  }

  updateMemberEmail(model: UpdateEmailModel): Observable<any> {
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
    return this.httpClient.post<any>(environment.firstNation.apiUrl + 'api/members/UpdateEmail', JSON.stringify(model), { headers });
  }

  updateMemberAddress(model: any): Observable<any> {
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
    return this.httpClient.post<any>(environment.firstNation.apiUrl + 'api/members/UpdateMemberAddress',
     JSON.stringify(model), { headers });
  }

  InviteMember(invitationModel: any): Observable<any> {
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
    return this.httpClient.post<any>(
      environment.firstNation.apiUrl + 'api/members/InviteClient', JSON.stringify(invitationModel), { headers });
  }

  registerMember(id: number): Observable<any> {
    const url = environment.firstNation.apiUrl + 'api/members/RegisterClient/' + id;
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
    return this.httpClient.get<any>(url, { headers });
  }

  saveMemberIfNotExist(model: BandMemberModel): Observable<any> {
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
    return this.httpClient.post<any>(
      environment.firstNation.apiUrl + 'api/members/SaveMemberIfNotExist', JSON.stringify(model), { headers });
  }

  getUpcomingBirthDays(numberofDays: number): Observable<any> {
    const url = environment.firstNation.apiUrl + 'api/members/GetUpcomingBirthdays/' + numberofDays;
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
    return this.httpClient.get<any>(url, { headers });
  }

  getMembersTurning16(model: any): Observable<any> {
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
   
    return this.httpClient.post<any>
    (environment.firstNation.apiUrl + 'api/members/MembersTurning16/', JSON.stringify(model) , { headers });
  }

  getMembersTurning18(model: any): Observable<any> {
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
    return this.httpClient.post<any>
    (environment.firstNation.apiUrl + 'api/members/GetMembersTurning18/', JSON.stringify(model) , { headers });
  }

  getMembersTurning21(model: any): Observable<any> {
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
    return this.httpClient.post<any>
    (environment.firstNation.apiUrl + 'api/members/GetMembersTurning21/', JSON.stringify(model) , { headers });
  }

  getEmailList(): Observable<string[]> {
    const url = environment.firstNation.apiUrl + 'api/members/EmailList';
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
    return this.httpClient.get<any>(url, { headers });
  }

  SearchBandMembers(model: any): Observable<any> {
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
    return this.httpClient.post<any>
    (environment.firstNation.apiUrl + 'api/members/SearchBandMembers/', JSON.stringify(model) , { headers });
  }

  updateMemberProfile(model: any): Observable<any> {
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
    return this.httpClient.post<any>(environment.firstNation.apiUrl + 'api/members/UpdateMemberProfile', JSON.stringify(model), { headers });
  }

  updateMemberData(model: any): Observable<any> {
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
    return this.httpClient.post<any>(environment.firstNation.apiUrl + 'api/members/UpdateMemberData', JSON.stringify(model), { headers });
  }

  checkIfBDLast2Month(model: any): Observable<any> {
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
    return this.httpClient.post<any>(environment.firstNation.apiUrl + 'api/members/checkIfBDLast2Month/', JSON.stringify(model), { headers });
  }

  getChartData(treatyNo: string):Observable<any>{
    const model = {treatyNo: treatyNo};
    //const url = environment.firstNation.apiUrl + 'api/members/GetChartData';
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
    return this.httpClient.post<any>
    (environment.firstNation.apiUrl + 'api/members/GetChartData/', JSON.stringify(model) , { headers });
  }

  chekForPossibleMatch(model: any): Observable<any>{
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
    return this.httpClient.post<any>(
      environment.firstNation.apiUrl + 'api/members/chekForPossibleMatch', JSON.stringify(model), { headers });
  }

  AddMemberIfNotExist(model: AddBandMemberModel): Observable<any>{
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
    return this.httpClient.post<any>(
      environment.firstNation.apiUrl + 'api/members/AddMemberIfNotExist', JSON.stringify(model), { headers });
  }

  AddMember(model: AddBandMemberModel): Observable<any>{
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
    return this.httpClient.post<any>(
      environment.firstNation.apiUrl + 'api/members/AddMember', JSON.stringify(model), { headers });
  }

  updateCellPhone(mobile: any, id: any) {
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
    const formData: FormData = new FormData();
    formData.append('cell', mobile);
    formData.append('id', id);
    return this.httpClient.post<any>(environment.firstNation.apiUrl + 'api/members/UpdateCellPhone', formData, { headers });
  }

  

  // updatePseInvitationStatus(id: any): Observable<any> {
  //   const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
  //   const formData: FormData = new FormData();
  //   formData.append('applicantID', id);
  //   return this.httpClient.post<any>(environment.firstNation.apiUrl + 'api/members/UpdatePseInvitationStatus', formData, { headers });  
  // }


  
  // getPsePWAStatus(id: number): Observable<any> {
  //   const url = environment.firstNation.apiUrl + 'api/members/GetPsePWAStatus/' + id ;
  //   const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
  //   return this.httpClient.get<any>( url, { headers });
  // }

  async getSINAsync(id: string) {
    const url = environment.firstNation.apiUrl + 'api/members/GetSIN/' + id ;
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
    this.httpClient.get<any>(url, { headers }).subscribe(sin => {
      if(sin) {
        if (sin != 0){
          return false; 
        } else {return true;}
      } else {return true;}
    });
  }

  async getHealthCareNoAsync(id: string) {
    const url = environment.firstNation.apiUrl + 'api/members/GetHealthCareNo/' + id ;
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
    this.httpClient.get<any>(url, { headers }).subscribe(h => {
      if(h) {
        if (h != 0){
          return false; 
        } else {return true;}
      } else {return true;}
    });
  }


 async getISNAsync(id: string) {
    const url = environment.firstNation.apiUrl + 'api/members/GetISN/' + id ;
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
    this.httpClient.get<any>(url, { headers }).subscribe(isn => {
      if(isn) {
        if (isn != 0){
          return false; 
        } else {return true;}
      } else {return true;}
    });
  }

  getSIN(id: string): Observable<any> {
    const url = environment.firstNation.apiUrl + 'api/members/GetSIN/' + id ;
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
    return this.httpClient.get<any>(url, { headers });
  }


  getHealthCareNo(id: string): Observable<any> {
    const url = environment.firstNation.apiUrl + 'api/members/GetHealthCareNo/' + id ;
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
    return this.httpClient.get<any>(url, { headers });
  }


  getISN(id: string): Observable<any> {
    const url = environment.firstNation.apiUrl + 'api/members/GetISN/' + id ;
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
    return this.httpClient.get<any>(url, { headers });
  }

  getInvitedMembers(){
    const url = environment.firstNation.apiUrl + 'api/members/GetInvitedMembers';
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
    return this.httpClient.get<any>(url, { headers });
  }

  searchMember(model: any): Observable<any> {
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
    return this.httpClient.post<any>(
      environment.firstNation.apiUrl + 'api/members/SearchMember', JSON.stringify(model), { headers });
  }

  getBandMembersCount(){
    const url = environment.firstNation.apiUrl + 'api/members/GetMembersCount';
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
    return this.httpClient.get<any>(url, { headers });
  }

  getDemographicsData(){
    const url = environment.firstNation.apiUrl + 'api/members/GetMembersDemographics';
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
    return this.httpClient.get<any>(url, { headers });
  }

  filterMembers(model:any){
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
    return this.httpClient.post<any>(
      environment.firstNation.apiUrl + 'api/members/FilterMembers', JSON.stringify(model), { headers });
  }


  
  // updateIsetsInvitationStatus(id: any): Observable<any> {
  //   const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
  //   const formData: FormData = new FormData();
  //   formData.append('applicantID', id);
  //   return this.httpClient.post<any>(environment.firstNation.apiUrl + 'api/members/UpdateIsetsInvitationStatus', formData, { headers });
    
  // }

  getISETMemberID(ISETMemberId: any[]): Observable<any> {
    const url = environment.firstNation.apiUrl + 'api/members/GetAsetIdOnBandMembers/';
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
    return this.httpClient.post<any>(url, JSON.stringify(ISETMemberId), { headers });
  }


  saveAdmin(model: BandMemberModel): Observable<any> {
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
    return this.httpClient.post<any>(
      environment.firstNation.apiUrl + 'api/ManageAdmins/SaveAdmin', JSON.stringify(model), { headers });
  }
 
}
