import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class CustomApplicationService {

  constructor(private db: AngularFirestore) { }

  applicationListRef: AngularFirestoreCollection<any>;
  applicationRefs: AngularFirestoreDocument<any>;

  saveApplication(model: any) {
    const newApplication = {
      id: this.db.createId(),
      appName: model.appName,
      values: model.values,
    }
    return this.db.collection('Custom-Applications').doc(newApplication.id).set(newApplication);
  }


}
