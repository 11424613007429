<div class="body-h">
  <div class="header-setting b-m-2">
    <div class="col l9 m9 s12">
      <h4 class=""> My Certificate Attainment </h4>
      <p class="bold-100 sub-title-section3">It is a long established fact that a reader will be distracted by the
        readable content of a page when looking at its layout.</p>
    </div>
    <div class="col l3  m4 s12 p-m-2 ">
      <a [routerLink]="['/member/member-main-home/add-certificate']" class="add-button btn white center-align p-l-3 black-text"><i
          class="material-icons-outlined">add</i> Add Certificate </a>
    </div>
  </div>

  <main class="StickyContent white row">
    <div class="col s12 l11">
      <div class="row" *ngIf="hasRows">
        <div class="card-panel white center" *ngFor="let edu of model">
          <a [routerLink]="['/member/member-main-home/edit-certificate',edu.id]" *ngIf="!sqldb"><strong><i
                class="material-icons left black-text">import_contacts</i> <span class="center">{{ edu.course }}</span>
            </strong></a>
          <a [routerLink]="['/member/member-main-home/edit-certificate',edu.ID]" *ngIf="sqldb"><strong><i
                class="material-icons left black-text">import_contacts</i> <span class="center">{{ edu.course }}</span>
            </strong></a>
        </div>
      </div>

      <div class="card-panel orange darken-2" *ngIf="!hasRows">
        <h6 class="center white-text"><i class="material-icons white-text left">info</i>
          <strong> You don’t have any Certificates listed. <div class="section"></div> Please enter your courses and
            certificates so that potential employers will be able to you find you in our database and contact you (with
            permission) should your skills meet their requirements. </strong>
        </h6>
      </div>

      <div class="section"></div>
      <!-- <a [routerLink]="['/member/profile/add-certificate']" class='col s12 btn waves-effect green'
        style="margin-bottom: 15px;">
        <i class="material-icons right">add_circle</i> Add New Certificate
      </a> -->
      <button [ngClass]="'col s12 btn waves-effect ' + themeColor" (click)="backClicked()">
        <i class="material-icons left">house</i> Back To Home Page
      </button>
    </div>
  </main>
</div>