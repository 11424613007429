import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { PollsAndSurveyService } from 'src/app/services/firebase/polls-and-survey.service';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { PollsAndSurveyParticipationService } from 'src/app/services/firebase/polls-and-survey-participation.service';
// import { PollsAndSurveyParticipationService } from 'src/app/services/firebase/polls-and-survey-participation.service';

@Component({
  selector: 'app-survey-detail',
  templateUrl: './survey-detail.component.html',
  styleUrls: ['./survey-detail.component.css']
})

export class SurveyDetailComponent implements OnInit {

  id = '';
  clientID = '';
  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  model: any;
  surveyForm: UntypedFormGroup;
  answersArray: UntypedFormArray;
  valueEmittedFromChildComponent: any;
  step1 = true;
  step2 = false;
  partcipated = false;

  parentEventHandlerFunction(valueEmitted) {
    this.valueEmittedFromChildComponent = valueEmitted;
    if (this.valueEmittedFromChildComponent === 'saved') {
      this.step1 = false;
      this.step2 = true;
    }
  }

  constructor(private route: ActivatedRoute,
              private surveyService: PollsAndSurveyService,
              private participationService: PollsAndSurveyParticipationService,
              private fb: UntypedFormBuilder,
              private location: Location) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
      this.clientID = localStorage.getItem('applicantID');
    });
  
    this.participationService.getSurveyParticipationByClientAndSurveyId(this.id, this.clientID).valueChanges().subscribe(part => {
      if(part && part.length > 0){
        this.partcipated = true;
      }else{
        if (this.id) {
          this.surveyService.getSurveyByID(this.id).valueChanges().subscribe(srv => {
            if (srv) {
              this.model = srv;
            }
          });
        }
      }
    });  
  }

  backClicked() {
    this.location.back();
  }

}