import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MemberService } from 'src/app/services/api/member.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { LRRCNSurveyService } from 'src/app/services/firebase/lrrcn-survey.service';
import SignaturePad from 'signature_pad';
import { toast } from 'materialize-css';
import { Router } from '@angular/router';

declare var $: any;
declare var M: any;

@Component({
    selector: 'app-lrrcn-application-list',
    templateUrl: './lrrcn-application-list.component.html',
    styleUrls: ['./lrrcn-application-list.component.css']
})

export class LrrcnApplicationMemberListComponent implements OnInit {

    themeColor = environment.appTheme.adminThemeColor;
    loading = false;
    applications = []
    count: number;
    showToday
    detail: any;
    showDetails = false
    applicantID: any
    surveyId: any
    memberSignature = false
    applicantCanvas: any;
    applicantSignaturePad: SignaturePad;
    surveyToSign: any
    loggedUser: any

    constructor(
        private authService: AuthService,
        private location: Location,
        private lrrcnService: LRRCNSurveyService,
        private router : Router) {}

    ngOnInit() {
        this.loading = true;

        this.loggedUser = this.authService.getGlobalUser()
        if(this.loggedUser && this.loggedUser.applicantID){
            this.lrrcnService.getLRRCNSurveyByApplicantId(this.loggedUser.applicantID).valueChanges().subscribe(x => {
                if (x) {
                    // this.applications = x.sort((a,b) => (a.applicantDate > b.applicantDate) ? 1 : ((b.applicantDate > a.applicantDate) ? -1 : 0));
                    this.applications = x
                    this.count = this.applications.length;
                }
                setTimeout(() => {
                    $('.modal').modal();
                    M.updateTextFields();
                }, 25);
                this.loading = false
            })
        }else{
            this.loading = false;

        }
        // this.lrrcnService.getLRRCNSurveyByApplicantId(this.loggedUser.applicantID).valueChanges().subscribe(x => {
        //     if (x) {
        //         // this.applications = x.sort((a,b) => (a.applicantDate > b.applicantDate) ? 1 : ((b.applicantDate > a.applicantDate) ? -1 : 0));
        //         this.applications = x
        //         this.count = this.applications.length;
        //     }
    }

    backClicked() {
        this.location.back();
    }

    onShowDetails(index) {
        this.applicantID = this.applications[index].applicantID;
        this.surveyId = this.applications[index].id;
        this.showDetails = true
    }
    
    memberSign(index) {
        this.surveyToSign = this.applications[index]
        this.memberSignature = true;
        if (this.surveyToSign.applicantSignature === '') {
            setTimeout(() => {
                $('.modal').modal('open');
                this.applicantCanvas = document.querySelector('canvas');
                const parentWidth = $(this.applicantCanvas).parent().outerWidth();
                this.applicantCanvas.setAttribute('width', parentWidth - 50);
                this.applicantSignaturePad = new SignaturePad(this.applicantCanvas, {
                    backgroundColor: 'rgb(255,255,255)'
                });
            }, 50);
        } else {
            setTimeout(() => {
                $('.modal').modal('open');
            }, 50);
        }
    }

    clearSignatureOfApplicant() {
        this.applicantSignaturePad.clear();
    }

    signSurvey() {
        this.surveyToSign.applicantDate = new Date()
        if (this.surveyToSign.applicantSignature === '') {
            this.surveyToSign.applicantSignature = this.applicantSignaturePad.toDataURL()
        }

        $('.modal').modal('close');
        this.lrrcnService.updateLRRCNSurvey(this.surveyToSign).then(survey => {
            console.log(survey, 'updated')
            toast({ html: 'Survey Verified Survey!', classes: 'green' });
            
        }).catch(err => {
            console.log(err)
        })
    }

    canBeSigned(app) {
        if (!app.verified && app.filledBy === 'Admin' && app.applicantSignature === '') {
            return true
        }
        return false
    }

    cancel() {
        $('.modal').modal('close');
    }

    newSurvey(){
        this.router.navigate(['member/member-main-home/profile/lrrcn'])
    }

    emptySignPad() {
        if (!this.applicantSignaturePad || this.applicantSignaturePad.isEmpty()) {
            return true;
        }
        return false
    }

    backToList(valueEmitted) {
        if (valueEmitted == true) {
            this.applicantID = null
            this.surveyId = null
            this.showDetails = false
        }
    }
}
