import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { DatePipe } from '@angular/common';
import { CommunityService } from 'src/app/services/api/community.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { AddBandMemberModel } from 'src/app/models/AddMemberModel';
import { MemberService } from 'src/app/services/api/member.service';
import { toast } from 'materialize-css';
import { ManageAdminService } from 'src/app/services/api/manage-admin.service';
import { Location, PercentPipe } from '@angular/common';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { createMask } from '@ngneat/input-mask';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-add-members',
  templateUrl: './add-members.component.html',
  styleUrls: ['./add-members.component.css']
})

export class AddMembersComponent implements OnInit {

  nationofUse = environment.firstNation.name;
  provinceOfUse = environment.firstNation.province;
  nationCommunities: any[];
  defaultThemeColor = environment.appTheme.themeColor;
  themeColor: any;
  buttonType: any;

  genders: any = [{ gender: 'Male', value: 'M' }, { gender: 'Female', value: 'F' }];
  provinces: any = ['Alberta', 'British Columbia', 'Manitoba', 'New Brunswick',
    'Newfoundland and Labrador', 'Nova Scotia', 'Ontario', 'Prince Edward Island', 'Quebec', 'Saskatchewan'];
  
  // public postalCodeMask = [/[A-Za-z]/, /[0-9]/, /[A-Za-z]/, ' ', /[0-9]/, /[A-Za-z]/, /[0-9]/];
  // public phoneNumberMask = ['(', /[0-9]/, /[0-9]/, /[0-9]/, ')',
  //   ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  public dateMask = [/[0-3]/, /[0-9]/, '/', /[0-1]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  public dateformat = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
  // public isnMask = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  // public SINMask = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
 
  phoneInputMask = createMask<string>( {
    mask: '(999) 999-9999',
    guide: true,
    showMaskTyped: true,
  });

  postalCodeMask = createMask<string>({
    mask: 'A9A 9A9' // Replace {1} with a space if desired
  });

  isnMask = createMask({
    mask: '9999999999',
    placeholderChar: '',
    maskChar: null,
  });

  SINMask = createMask({
    mask: '999 999 999',
    placeholderChar: '',
    maskChar: null,
  });




  currentUser: any;
  dobSelected = '';
  dobSet = false;
  addMemberForm: UntypedFormGroup;
  model: AddBandMemberModel;
  loginId: any;
  hasExactMatch = false;
  exactMatch: any;
  matches: any[];
  showProfile = false;
  memberProfile: any;
  hasMatch = false;
  hasChanged = false;

  constructor(private fb: UntypedFormBuilder,
    private communityService: CommunityService,
    private authService: AuthService,
    private memberService: MemberService,
    private manageAdminService: ManageAdminService,
    private settingsService: AppSettingsService,
    private location: Location) { 
      this.themeColor = this.settingsService.getAppThemeColor() ? this.settingsService.getAppThemeColor() : this.defaultThemeColor;
      this.buttonType = this.settingsService.getButtonType() ? this.settingsService.getButtonType() : 'rectangularButton';
    }

  ngOnInit() {
    if (this.nationofUse === 'Batc' || this.nationofUse === 'Pbcn') {
      this.communityService.getNationCommunities().subscribe(
        com => {
          if (com.length > 0) {
            this.nationCommunities = com;
          }
        }
      );
    }

    this.currentUser = this.authService.getGlobalUser();
    if (this.currentUser && this.currentUser.id) {
      this.manageAdminService.getAdmin(this.currentUser.id).subscribe(user => {
        if (user) {
          this.loginId = user[0].loginID;
        }
      });
    }

    this.addMemberForm = this.fb.group({
      firstName: ['', [Validators.required]],
      middleName: [''],
      lastName: ['', [Validators.required]],
      dob: ['', [Validators.required, this.isValidDate]],
      gender: ['', [Validators.required]],
      isn: ['', [Validators.minLength(10), Validators.maxLength(10), Validators.pattern('^[0-9]*$')]],
      email: ['', [Validators.email]],
      telephone: [''],
      cellPhone: [''],
      healthCareNumber: [''],
      address: [''],
      city: [''],
      province: [this.provinceOfUse],
      postalCode: [''],
      sin: ['', [Validators.minLength(9), Validators.maxLength(9), Validators.pattern('^[0-9]*$')]],
      community: [''],
      admin: [''],
      fromApp: [],
    });

    this.onChanges();

    setTimeout(() => {
      $('select').formSelect();
      $('.modal').modal();
      M.updateTextFields();
    }, 25);
    this.model = new AddBandMemberModel();
  }

  get firstName() { return this.addMemberForm.get('firstName'); }
  get lastName() { return this.addMemberForm.get('lastName'); }
  get dob() { return this.addMemberForm.get('dob'); }
  get isn() { return this.addMemberForm.get('isn'); }
  get sin() { return this.addMemberForm.get('sin'); }
  get email() { return this.addMemberForm.get('email'); }
  get gender() { return this.addMemberForm.get('gender'); }

  onChanges(): void {
    this.firstName.valueChanges.subscribe(val => {
      this.hasChanged = true;
    });
    this.lastName.valueChanges.subscribe(val => {
      this.hasChanged = true;
    });
    this.isn.valueChanges.subscribe(val => {
      this.hasChanged = true;
    });
  }
  
  setDob(e) {
    if (e.match(this.dateformat)) {
      this.dobSelected = e;
      const date = this.changeDates(e);
      const datePipe = new DatePipe('en-US');
      const formatedDate = datePipe.transform(date, 'fullDate');
      this.dob.patchValue(formatedDate);
      this.dobSet = true;
    }
  }

  unsetDob() {
    this.dob.patchValue(this.dobSelected);
    this.dobSet = false;
  }

  isValidDate(control: UntypedFormControl) {
    let date = control.value;
    const dateformat = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
    if (date) {
      if (!date.match(dateformat) && date.length > 10) {
        const datePipe = new DatePipe('en-US');
        date = datePipe.transform(date, 'dd/MM/yyyy');
      }
      if (date.match(dateformat)) {
        let dateArray = date.split('/');
        let year = dateArray[2];
        let month = dateArray[1] - 1;
        let day = dateArray[0];
        date = new Date(year, month, day);
        const today = new Date();
        if (date.valueOf() < today.valueOf()) {
          return null;
        } else {
          return { 'invalidDate': true }
        }
      }
    }
    return null;
  }

  changeDates(dateValue) {
    if (dateValue) {
      if (dateValue.match(this.dateformat)) {
        let dateArray = dateValue.split('/');
        let year = dateArray[2];
        let month = dateArray[1] - 1;
        let day = dateArray[0];
        const date = new Date(year, month, day);
        return date;
      } else {
        const date = new Date(dateValue);
        return date;
      }
    } else {
      return null
    }
  }
  
  addMemberIfNotExist (memberData: any) {
    this.hasMatch = false;
    this.hasExactMatch = false;
    this.model.GivenName = memberData.firstName;
    this.model.middle_name = memberData.middleName;
    this.model.LastName = memberData.lastName;
    this.model.DOB = this.changeDates(memberData.dob);
    this.model.Sex = memberData.gender;
    this.model.IndianStatusNo = memberData.isn;
    this.model.Email = memberData.email;
    this.model.Telephone = memberData.telephone;
    this.model.cell_phone_num = memberData.cellPhone;
    this.model.HealthCareNo = memberData.healthCareNumber;
    this.model.MailAddress = memberData.address;
    this.model.city = memberData.city;
    this.model.province = memberData.province;
    this.model.PostalCode = memberData.postalCode;
    this.model.SIN = memberData.sin;
    this.model.Community = memberData.community;
    this.model.date_added = new Date();
    if (this.loginId) this.model.Officer = this.loginId;
  
    this.memberService.AddMemberIfNotExist(this.model).subscribe(resp => {
      if (resp) {
        if (typeof resp === 'number') {
          toast({ html: 'Member Successfully Added!', classes: 'green' });
          this.backClicked();
        } else {
          if (resp.length > 0) {
            this.hasMatch = true;
            if(this.model.IndianStatusNo && this.model.IndianStatusNo != ''){
              this.matches = resp.filter(member => member.IndianStatusNo == this.model.IndianStatusNo);
              if ( this.matches && this.matches.length > 0) {
                this.hasExactMatch = true;
                toast({ html: 'Member already Exists!', classes: 'red' });
                $('#similarMembersModal').modal('open');
                $('.collapsible').collapsible();
              }
              else {
                this.matches = resp;
                $('#similarMembersModal').modal('open');
                $('.collapsible').collapsible();
              }
            }
            else {
              this.matches = resp;
              $('#similarMembersModal').modal('open');
              $('.collapsible').collapsible();
            }
          }
        }
      }
    });
  }

  addMember(values) {
    this.memberService.AddMember(this.model).subscribe(resp => {
      if(resp)
      toast({ html: 'Member Added Successfully!', classes: 'green' });
      this.backClicked();
    });
  }
  
  backClicked() {
    this.location.back();
  }

  cancel() {
    $('.modal').modal('close');
    $('select').formSelect();
    M.updateTextFields();

  }
  showDetails(index) {
    this.cancel();
    this.showProfile = true;
    this.memberProfile = this.matches[index];
    this.memberProfile.Sex = this.memberProfile.Sex ? this.memberProfile.Sex == 'M' ? 'Male' : 'Female' : '';
  }

  hideProfile() {
    this.showProfile = false;
  }
}
