<div class="row">
  <div class="col s12 ">
    <div class="body-h">
      
      <div class="header-setting b-m-2 ">
        <div class="col l12 m12 s12" *ngIf="model">
          <h4 class="">Manage {{model.name}} Members</h4>
          <select class="browser-default hide-on-large-only" (change)="navigate($event.target.value)" >
            <option value="AboutUs">About Us</option>
            <option value="NationInfo">Nation Info</option>
            <option value="Departments">Department</option>
            <option value="Governance" disabled selected>Governance</option>
            <option value="AreaOfInterest">Area of Interest</option>
          </select>
        </div>
      </div>

      <main class="StickyContent white">
        <div class="row col s12" *ngIf="model">
          <div class="col s12 m12 l10">

            <div class="row card-panel " *ngIf="model.boardChair && model.boardChair.length > 0">
              <table class="striped centred">
                <thead>
                  <tr>
                    <th class="green-text"> {{model.name}} Chair </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td><span><strong>{{ model.boardChair[0].displayName }}</strong> </span></td>
                    <td><a (click)="selectChair(model.boardChair[0].id)" class="btn btn-small blue white-text right">
                        Select
                      </a></td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="row card-panel" *ngIf="model.boardChair && model.boardChair.length === 0">
              <div class="row center">
                <strong class="red-text">You Did Not Update The Chair Of The Board</strong>

              </div>
              <div class="row col s12">
                <button type="button" (click)="addChair();" class="col s12 btn green white-text"> Add Chair Of The
                  Board</button>
              </div>
            </div>

            <div class="row card-panel " *ngIf="model.boardMembers && model.boardMembers.length > 0">
              <table class="striped centred">
                <thead>
                  <tr>
                    <th class="green-text"> {{model.name}} Members </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let m of model.boardMembers">
                    <td><span><strong>{{ m.displayName}}</strong> </span></td>
                    <td><a class="btn btn-small blue white-text right" (click)="selectMember(m.id);"> Select </a></td>
                  </tr>
                </tbody>
              </table>
              <div class="row">
                <button type="button" (click)="addMember();" class="col s12 btn green white-text"> Add New Board
                  Member</button>
              </div>
            </div>

            <div class="row card-panel" *ngIf="model.boardMembers && model.boardMembers.length === 0">
              <div class="row center">
                <strong class="red-text">You Did Not Update The Board Members.</strong>
              </div>
              <div class="row col s12">
                <button type="button" (click)="addMember();" class="col s12 btn green white-text"> Add Board
                  Members</button>
              </div>
            </div>

            <div class="row">
              <div class="section"></div>
              <a (click)="backClicked();" [ngClass]="'col s12 btn waves-effect ' + themeColor">
                <i class="material-icons left">arrow_back_ios</i>
                Back To Previous Page </a>
            </div>

          </div>
        </div>
      </main>
    </div>
  </div>
</div>