import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DocumentsService } from 'src/app/services/api/documents.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { Location } from '@angular/common';
import { SupplementalSupportModel } from 'src/app/models/supplementalSupportModel';
import { toast } from 'materialize-css';

@Component({
  selector: 'app-upload-statement',
  templateUrl: './upload-statement.component.html',
  styleUrls: ['./upload-statement.component.css']
})

export class UploadStatementComponent implements OnInit {

  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  applicantID = 0;
  valueEmittedFromChildComponent: any;
  callerName = 'income_statement';
  isCordova = false;

  constructor(private location: Location, private authService: AuthService) {
    if (typeof window['cordova'] !== 'undefined') {
      this.isCordova = true;
    }
  }

  parentEventHandlerFunction(valueEmitted) {
    this.valueEmittedFromChildComponent = valueEmitted;
    if (this.valueEmittedFromChildComponent === 'saved') {
      toast({ html: 'Income Statement Successfully Submitted!', classes: 'green' });
      this.backClicked();
    }
  }

  ngOnInit() {
    if (localStorage.getItem('applicantID')) {
      this.applicantID = Number(localStorage.getItem('applicantID'));
    } else {
      this.applicantID = this.authService.getCurrentApplicantID();
    }
  }

  backClicked() {
    this.location.back();
  }
}