<div class="row center-align p-m-2">
    <h6> <strong> Add New Wall Post </strong></h6>
</div>

<div class="row col s12">
    <div class="col l1"></div>
    <div id="step0" class="col s12 l10">
        <form (ngSubmit)="submitForm(addNationWallPostForm.value)"  [formGroup]="addNationWallPostForm">
            <div class="row card-panel">
                <div class="input-field class col s12">
                    <i class="material-icons prefix blue-text">work</i>
                    <input formControlName="name" id="name" type="text" maxlength="75" autocomplete="off" required
                        class="validate">
                    <label for="name"> Name : <strong class="red-text">*</strong> </label>
                    <div *ngIf="(name.dirty || name.touched) && name.invalid">
                        <small *ngIf="name.errors.required" class="red-text">Name Is Required!
                        </small>
                    </div>
                </div>
            </div>
            <div class="section"></div>
            <div class="col s12">
                <button class='at-btn col s12 btn waves-effect success' style="margin-bottom: 15px;" type="submit" [disabled]="!addNationWallPostForm.valid">
                <i class="material-icons right">add_circle</i> Submit
                </button>
                <a [ngClass]="'col s12 btn waves-effect ' + themeColor " [routerLink]="['/member/nation-wall']">Back to Wall Post List</a>
            </div>
        </form>
    </div>
    <div class="col l1 "></div>
</div>