import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';
import { FbadminService } from 'src/app/services/api/FireBaseAdmin/fbadmin.service';
import { EmailModel } from 'src/app/models/emailModel';

@Component({
  selector: 'app-messenger',
  templateUrl: './messenger.component.html',
  styleUrls: ['./messenger.component.css']
})
export class MessengerComponent implements OnInit {

  constructor(private location: Location, private fbadminService: FbadminService) { }

  themeColor = environment.appTheme.adminThemeColor;
  fontColor = environment.appTheme.adminFontColor;
 
  ngOnInit() {
  }

  backClicked() {
    this.location.back();
  }

}
