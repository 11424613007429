<ul class="collection with-header" *ngIf="model">
    <li class="collection-header">
        <h5><strong>House Information</strong></h5>
    </li>
    <li *ngIf="fromAdmin" class="collection-item">
        <div>House ID<a class="secondary-content">{{model.ID}}</a></div>
    </li>
    <li *ngIf="fromAdmin" class="collection-item">
        <div>Owner ID<a class="secondary-content">{{model.aid_of_owner}}</a></div>
    </li>
    <li class="collection-item">
        <div>Head of Household<a class="secondary-content">{{model.GivenName}} {{model.LastName}}</a></div>
    </li>
    <li class="collection-item" *ngIf="model.desc_type == 'HN'">
        <div>House Number<a class="secondary-content">{{model.house_number}}</a></div>
    </li>
    <div *ngIf="model.desc_type == 'LBP'">
        <li class="collection-item">
            <div>Block<a class="secondary-content">{{model.house_block}}</a></div>
        </li>
        <li class="collection-item">
            <div>Lot<a class="secondary-content">{{model.house_lot}}</a></div>
        </li>
    </div>

    <div *ngIf="model.desc_type == 'LLD'">
        <li class="collection-item">
            <div>QRT<a class="secondary-content">{{model.Quarter}}</a></div>
        </li>
        <li class="collection-item">
            <div>Section<a class="secondary-content">{{model.section}}</a></div>
        </li>
        <li class="collection-item">
            <div>Township<a class="secondary-content">{{model.township}}</a></div>
        </li>
        <li class="collection-item">
            <div>Range<a class="secondary-content">{{model.range}}</a></div>
        </li>
        <li class="collection-item">
            <div>Meridian<a class="secondary-content">{{model.median}}</a></div>
        </li>
    </div>

    <li class="collection-item" *ngIf="model.desc_type == 'Other'">
        <div>Location Description<a class="secondary-content">{{model.Other_location}}</a></div>
    </li>
    <li class="collection-item">
        <div>CMHC
            <a *ngIf="model.CMHC" class="secondary-content"><i class="material-icons green-text">check_circle</i></a>
            <a *ngIf="!model.CMHC" class="secondary-content"><i class="material-icons red-text">cancel</i></a>
        </div>
    </li>
    <li *ngIf="fromAdmin" class="collection-item">
        <div>Financed
            <a *ngIf="model.finianced" class="secondary-content"><i
                    class="material-icons green-text">check_circle</i></a>
            <a *ngIf="!model.finianced" class="secondary-content"><i class="material-icons red-text">cancel</i></a>
        </div>
    </li>
    <li class="collection-item">
        <div>Community<a class="secondary-content">{{model.community}}</a></div>
    </li>
    <li class="collection-item">
        <div>Mailing Address<a class="secondary-content">{{model.MailAddress}}</a></div>
    </li>
    <li class="collection-item">
        <div>Province<a class="secondary-content">{{model.province}}</a></div>
    </li>
    <li class="collection-item">
        <div>Postal Code<a class="secondary-content">{{model.PostalCode}}</a></div>
    </li>
    <li class="collection-item">
        <div>Year <a class="secondary-content">{{model.year}}</a></div>
    </li>
    <li class="collection-item">
        <div>Age of House <a class="secondary-content">{{model.age}}</a></div>
    </li>
    <li class="collection-item">
        <div>Style<a class="secondary-content">{{model.style}}</a></div>
    </li>
    <li class="collection-item">
        <div>No of Rooms<a class="secondary-content">{{model.rooms}}</a></div>
    </li>
    <li class="collection-item">
        <div>Size Of House<a class="secondary-content">{{model.size}} sq ft</a></div>
    </li>
    <li class="collection-item" *ngIf="fromAdmin">
        <div>Cost of Building<a class="secondary-content">$ {{model.value}}</a></div>
    </li>
    <li class="collection-item">
        <div>Primary Heat<a class="secondary-content">{{model.Primary_heat}}</a></div>
    </li>
    <li class="collection-item">
        <div>Secondary Heat<a class="secondary-content">{{model.Secondary_heat}}</a></div>
    </li>
    <li class="collection-header">
        <h5 (click)="expandOccupants()"><strong>Occupants</strong><i class="material-icons right">
                expand_more
            </i></h5>
    </li>

    <div class="col s12" *ngIf="showOccupants">
        <div class="col s12" *ngIf="!occupants || occupants.length == 0">
            <li class="collection-item">
                <div>No Occupants Added
                </div>
            </li>
        </div>
    </div>

    <div class="col s12" *ngIf="showOccupants">
        <div class="col s12" *ngIf="occupants && occupants.length > 0">
            <h6 class="center-align">Total : {{occupants.length}}</h6>
            <table class="responsive-table striped centered">
                <thead>
                    <tr>
                        <th>Applicant ID</th>
                        <th>Given Name</th>
                        <th>Last Name</th>
                        <th>Classification</th>
                        <th>Mail Address</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let occ of occupants;let i=index">
                        <td>{{occ.applicantID}}</td>
                        <td>{{occ.GivenName}}</td>
                        <td>{{occ.LastName}}</td>
                        <td>{{occ.Classification}}</td>
                        <td>{{occ.MailAddress}}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</ul>