import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.css']
})

export class SideMenuComponent implements OnInit {

  nationOfUse = environment.firstNation.name;
  defaultThemeColor = '#000000';
  themeColor: any;
  buttonType: any;

  @Input() user: any;
  from: any;
  isSuperAdmin = false;
  adminMenus: any[];
  defaultMenus: any[];
  permissions: any[];
  menu: any;
  selectedMenu = {
    name: '',
    icon: '',
  }
  myNationMenu = false;
  manageMenu = false;
  newsAndEventsMenu = false;
  statisticsMenu = false;
  settingsMenu = false;
  pollsAndSurveyMenu = false;

  constructor(private location: Location,
    private router: Router,
    private settingsService: AppSettingsService,
    private dataService: DataService) {
    this.themeColor = this.settingsService.getAppThemeColor() ? this.settingsService.getAppThemeColor() : this.defaultThemeColor;
    this.buttonType = this.settingsService.getButtonType() ? this.settingsService.getButtonType() : 'rectangularButton';

    this.defaultMenus = this.dataService.getAdminMenus();
  }

  ngOnInit(): void {
    this.settingsService.getAdminMenus().valueChanges().subscribe(menus => {
      if (menus && menus.length > 0) {
        this.adminMenus = menus;
        this.adminMenus = this.adminMenus.sort((a, b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0));
      } else {
        this.adminMenus = this.defaultMenus.sort((a, b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0));
      }

      if (this.adminMenus) {
        if (localStorage.getItem('From')) {
          this.from = localStorage.getItem('From');
          if (this.from == 'MyNation') this.myNationMenu = true;
          if (this.from == 'Manage') this.manageMenu = true;
          if (this.from == 'Stats') this.statisticsMenu = true;

          let menu = this.adminMenus.filter(m => m.name == this.from);
          if (menu && menu.length > 0) {
            this.menu = menu[0];
            this.selectedMenu.name = this.menu.displayName;
            this.selectedMenu.icon = this.menu.icon;

            if (this.menu.submenus && this.menu.submenus.length < 0) this.menu.submenus = this.menu.submenus.sort((a, b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0));
          }
        }
      }
    });
  }

  getPermission(menu, submenu?) {
    if (this.isSuperAdmin) return true;
    if (this.permissions) {
      if (this.permissions.length > 0) {
        let p = this.permissions.find(p => p.menu == menu);
        if (p) {
          if (!submenu) return true;
          else if (p.submenus && p.submenus.length > 0) {
            let sp = p.submenus.includes(submenu);
            if (sp) return true;
          }
        }
      }
    }
    return false;
  }

  menuClicked(item: any) {
    this.selectedMenu.name = item.displayName;
    this.selectedMenu.icon = item.icon;
    if (this.getPermission(item.name)) {
      if (item.name === 'NewsAndEvents') this.router.navigate(['admin/news-events']);
      else if (item.name === 'Settings') this.router.navigate(['admin/settings']);
      else if (item.name === 'PollsAndSurveys') this.router.navigate(['admin/survey-list']);
      else if (item.name === 'Communication') this.router.navigate(['admin/messages']);
      else this.menu = item;
    }
  }

  backClicked() {
    this.location.back();
  }

}
