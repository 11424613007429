import { Component, OnInit } from '@angular/core';
import { HousingService } from 'src/app/services/firebase/housing.service';

const pdfMake = require('pdfmake/build/pdfmake.js');
const pdfFonts = require('pdfmake-unicode/dist/pdfmake-unicode.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;

declare var $: any;

@Component({
  selector: 'app-housing-application',
  templateUrl: './housing-application.component.html',
  styleUrls: ['./housing-application.component.css']
})
export class HousingApplicationComponent implements OnInit {

  loading = false;
  applications: any[];
  pending: any[] = [];
  approved: any[] = [];
  declined: any[] = [];
  completed: any[] = [];

  constructor(private housingService: HousingService) { }

  ngOnInit(): void {
    this.loading = true;
     this.housingService.getApplications().valueChanges().subscribe(apps => {
       if(apps && apps.length > 0){
         this.applications = apps;
       }
       this.loading = false;
       setTimeout(() => {
         $('.tabs').tabs();
         $('.modal').modal();
         $('.dropdown-trigger').dropdown();
       }, 25);
     })
  }

}
