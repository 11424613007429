import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { environment } from 'src/environments/environment';
import { LRRCNSurveyService } from 'src/app/services/firebase/lrrcn-survey.service';
import { MediaService } from 'src/app/services/api/media.service';
import { Attachment } from 'src/app/models/emailModel';

const pdfMake = require('pdfmake/build/pdfmake.js')
const pdfFonts = require('pdfmake-unicode/dist/pdfmake-unicode.js')
pdfMake.vfs = pdfFonts.pdfMake.vfs;

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-lrrcn-applications-detail',
  templateUrl: './lrrcn-survey-detail.component.html',
  styleUrls: ['./lrrcn-survey-detail.component.css']
})

export class LRRCNSurveyDetailComponent implements OnInit {

  themeColor = environment.appTheme.adminThemeColor;
  @Input() surveyId: any;
  @Output() onBackToList: EventEmitter<boolean> = new EventEmitter<boolean>();
  survey: any
  step = 0;
  genders = { "F": "Female", "M": "Male", "X": "Other" }
  workerImage
  workerImageName
  applicantImage
  applicantImageName
  fileName = 'IA Survey'
  nationOfUse = environment.firstNation.displayName;

  reportFile: File;
  sendEmail = false
  emailSent = false
  fileLink = '';
  emailList: any[] = [];
  fileList: any[] = [];

  constructor(
    private authService: AuthService,
    private lrrcnService: LRRCNSurveyService,
    private mediaService: MediaService) {
    // if (this.nationName === 'Demo') { this.isDemo = true; }
    // if (this.nationName === 'LongLake') { this.isLL = true; }
    // this.loggedUser = this.authService.getGlobalUser();
  }

  ngOnInit() {
    let user = this.authService.getGlobalUser()

    this.lrrcnService.getLRRCNSurvey(this.surveyId).valueChanges().subscribe(x => {
      if (x && x.length > 0) {
        this.survey = x[0]
        if (this.survey.applicantSignature != '') {
          this.applicantImage = this.survey.applicantSignature;
          this.applicantImageName = 'CLIENT SIGNATURE';
        }
        if (this.survey.workerSignature != '') {
          this.workerImage = this.survey.workerSignature;
          this.workerImageName = 'ADMIN SIGNATURE';
        }
      }
      setTimeout(() => {
        $('.modal').modal();
        $('.tooltipped').tooltip();
        $('.dropdown-trigger').dropdown();
        $('.collapsible').collapsible();
        $('.fixed-action-btn').floatingActionButton({
          direction: screen.width <= 600 ? 'bottom' : 'left',
          hoverEnabled: false,
        });
      }, 25);
      window.scrollTo(0, 0);
    });
  }

  nextSection() {
    this.step += 1;
    window.scrollTo(0, 0);
    setTimeout(() => {
      $('.dropdown-trigger').dropdown();
      $('.collapsible').collapsible();
      M.updateTextFields();
    }, 25);
  }

  previousSection() {
    // if (this.step ==15) this.HasApplicantSign=false
    this.step -= 1;
    window.scrollTo(0, 0);
    setTimeout(() => {
      $('.dropdown-trigger').dropdown();
      $('.collapsible').collapsible();
      M.updateTextFields();
    }, 25);
  }

  showApplicantSignature() {
    this.applicantImage = this.survey.applicantSignature;
    this.applicantImageName = 'SIGNATURE';
    setTimeout(() => {
      $('.modal').modal();
      $('#imageModal').modal('open');
    }, 25);
  }

  showWorkerSignature() {
    this.workerImage = this.survey.workerSignature;
    this.workerImageName = 'SIGNATURE';
    setTimeout(() => {
      $('.modal').modal();
      $('#imageModal').modal('open');
    }, 25);
  }

  backToList() {
    this.onBackToList.emit(true);
  }

  async getPdf() {
    let survey = {
      info: {
        title: 'IA SURVEY',
        subject: 'IA SURVEY',
        keywords: 'IA SURVEY',
      },
      pageSize: "LETTER",
      content: await this.getContent(),
      styles: this.getStyles()
    }
    return survey;
  }

  async getContent() {
    let content = []
    content.push(
      {
        style: 'table',
        layout: 'lightHorizontalLines',
        table: {

          headerRows: 1,
          widths: ['*', 'auto', 100, '*'],
          body: [

            [{
              text: `SECTION 1: ${this.nationOfUse.toLocaleUpperCase()} INFORMANTION`,
              style: 'title',
              colSpan: 4
            },
            {},
            {},
            {}
            ],
            [
              {
                text: `${this.nationOfUse} Information`,
                style: 'subTitle',
                colSpan: 4
              },
              {},
              {},
              {}
            ],

            [
              {
                stack: [
                  {
                    text: `Do you know about the ${this.nationOfUse} app?`,
                    style: 'fieldName'
                  },
                  {
                    text: this.survey.aboutLrrcn.knowAboutLrrcn,
                    style: 'fieldValue'
                  }

                ],
                colSpan: this.survey.aboutLrrcn.knowAboutLrrcn == 'Yes' ? 2 : 4,
              },
              {},
              (this.survey.aboutLrrcn.knowAboutLrrcn == 'Yes') ? {
                stack: [
                  {
                    text: 'Have you registered?',
                    style: 'fieldName'
                  },
                  {
                    text: this.survey.aboutLrrcn.registered,
                    style: 'fieldValue'
                  }

                ],

                colSpan: this.survey.aboutLrrcn.knowAboutLrrcn == 'No' ? 1 : 2,
              } : {
              },
              {}

            ],
            [
              {
                stack: [
                  {
                    text: 'Do you know know what you can use it for?',
                    style: 'fieldName'
                  },
                  {
                    text: this.survey.aboutLrrcn.knowHowToUse,
                    style: 'fieldValue'
                  }

                ],
                colSpan: 4
              },
              {}, {}, {}
            ]

            // (this.survey.demographic.housing.length > 0) ?
            //   [
            //     {
            //       stack: [
            //         {

            //         },
            //         {
            //           ul: [
            //             ...this.survey.demographic.housing.map(house => {
            //               return [
            //                 { text: house, style: 'fieldValue', },
            //               ];
            //             })
            //           ]
            //         }
            //       ],
            //       colSpan: 2,

            //     },



            //     {}, {}, {}

            //   ]

            //   : [
            //     {
            //       text: 'No Housing Information Provided',
            //       style: 'fieldValue',
            //       colSpan: 3
            //     },
            //     {}, {}, {}
            //   ],


          ],




        }
      },
      {
        style: 'table',
        layout: 'lightHorizontalLines',
        table: {

          headerRows: 1,
          widths: ['*', 'auto', 100, '*'],
          body: [

            [{
              text: 'SECTION 2: DEMOGRAPHICS',
              style: 'title',
              colSpan: 4

            },
            {},
            {},
            {}
            ],
            [
              {
                text: 'Client Information',
                style: 'subTitle',
                colSpan: 4
              },
              {},
              {},
              {}
            ],
            [
              {
                stack: [
                  {
                    text: 'Last Name',
                    style: 'fieldName'
                  },
                  {
                    text: this.survey.demographic.lastName,
                    style: 'fieldValue'
                  }

                ],
                colSpan: 2,
              },
              {},
              {
                stack: [
                  {
                    text: 'First Name',
                    style: 'fieldName'
                  },
                  {
                    text: this.survey.demographic.firstName,
                    style: 'fieldValue'
                  }
                ],
                colSpan: 2,

              }, {}
            ],
            [
              {
                stack: [
                  {
                    text: 'Email',
                    style: 'fieldName'
                  },
                  {
                    text: this.survey.demographic.email,
                    style: 'fieldValue'
                  }

                ],
                colSpan: 2,
              },
              {},
              {
                stack: [
                  {
                    text: 'Phone Number',
                    style: 'fieldName'
                  },
                  {
                    text: this.survey.demographic.phone,
                    style: 'fieldValue'
                  }
                ],
                colSpan: 2,

              },
              {}
            ],
            [
              {
                stack: [
                  {
                    text: 'Client Category',
                    style: 'fieldName'
                  },
                  {
                    text: this.survey.demographic.clientCategory,
                    style: 'fieldValue'
                  }

                ],
                colSpan: 2,
              },
              {},
              {
                stack: [
                  {
                    text: 'Gender',
                    style: 'fieldName'
                  },
                  {
                    text: this.survey.demographic.gender,
                    style: 'fieldValue'
                  }

                ],
                colSpan: 2,
              },
              {}
            ],
            [
              {
                stack: [
                  {
                    text: 'Maritail Status',
                    style: 'fieldName'
                  },
                  {
                    text: this.survey.demographic.maritalStatus,
                    style: 'fieldValue'
                  }

                ],
                colSpan: 2,
              },
              {},
              {
                stack: [
                  {
                    text: 'Age',
                    style: 'fieldName'
                  },
                  {
                    text: this.survey.demographic.age,
                    style: 'fieldValue'
                  }
                ],
                colSpan: 2,

              }, {}],


            [
              {
                text: 'Community',
                style: 'subTitle',
                colSpan: 4
              },
              {},
              {},
              {}
            ],
            [
              {
                stack: [
                  {
                    text: 'Living In',
                    style: 'fieldName'
                  },
                  {
                    text: this.survey.demographic.livingProvince,
                    style: 'fieldValue'
                  }

                ],
                colSpan: 2,
              },
              {},
              {
                stack: [
                  {
                    text: 'From Community',
                    style: 'fieldName'
                  },
                  {
                    text: this.survey.demographic.community,
                    style: 'fieldValue'
                  }
                ],
                colSpan: 2,

              }, {}],
            [
              {
                text: 'Housing',
                style: 'subTitle',
                colSpan: 4
              },
              {},
              {},
              {}
            ],
            (this.survey.demographic.housing.length > 0) ?
              [
                {
                  stack: [
                    {

                    },
                    {
                      ul: [
                        ...this.survey.demographic.housing.map(house => {
                          return [
                            { text: house, style: 'fieldValue', },
                          ];
                        })
                      ]
                    }
                  ],
                  colSpan: 2,

                },



                {}, {}, {}

              ]

              : [
                {
                  text: 'No Housing Information Provided',
                  style: 'fieldValue',
                  colSpan: 3
                },
                {}, {}, {}
              ],


          ],




        }
      },
      {
        style: 'table',
        layout: 'lightHorizontalLines',
        table: {
          widths: ['*', '*', '*', '*'],
          body: [
            [{
              text: 'SECTION 3: LANGUAGE',
              style: 'title',
              colSpan: 4

            },
            {},
            {},
            {}
            ],
            (this.survey.languages.length > 0) ?
              [{
                colSpan: 4,
                margin: [0, 10, 0, 10],
                widths: ['*', '*', '*', '*', '*', '*', '*'],
                table: {
                  body: [
                    [
                      {
                        text: 'Language',
                        style: 'fieldName',
                        bold: true,
                        border: [false, false, true, true]
                      },
                      {
                        text: 'Speak',
                        style: 'fieldName',
                        bold: true,
                        border: [false, false, true, true]
                      },
                      {
                        text: 'Write',
                        style: 'fieldName',
                        bold: true,
                        border: [false, false, true, true]
                      },
                      {
                        text: 'Understand',
                        style: 'fieldName',
                        bold: true,
                        border: [false, false, true, true]
                      },
                      {
                        text: 'Speaking Literacy Level',
                        style: 'fieldName',
                        bold: true,
                        border: [false, false, true, true]
                      },
                      {
                        text: 'Writing Literacy Level',
                        style: 'fieldName',
                        bold: true,
                        border: [false, false, true, true]
                      },
                      {
                        text: 'Learn How To Speak',
                        style: 'fieldName',
                        bold: true,
                        border: [false, false, true, true]
                      },
                    ],
                    ...this.survey.languages.map(lang => {
                      return [
                        { text: lang.language, style: 'fieldValue', border: [false, false, true, true] },
                        { text: lang.speak ? 'Yes' : 'No', style: 'fieldValue', border: [false, false, true, true] },
                        { text: lang.write ? 'Yes' : 'No', style: 'fieldValue', border: [false, false, true, true] },
                        { text: lang.understand ? 'Yes' : 'No', style: 'fieldValue', border: [false, false, true, true] },
                        { text: lang.speak ? lang.literacyLevel.speak : 'No', style: 'fieldValue', border: [false, false, true, true] },
                        { text: lang.write ? lang.literacyLevel.write : 'No', style: 'fieldValue', border: [false, false, true, true] },
                        { text: lang.learnToSpeak, style: 'fieldValue', border: [false, false, true, true] },

                      ];
                    })
                  ]
                }

              },
              {},
              {},
              {}
              ] : [
                {
                  text: 'No Language Information Provided',
                  style: 'fieldValue',
                  colSpan: 4
                },
                {}, {}, {}]
          ]
        }
      },
      {
        style: 'table',
        layout: 'lightHorizontalLines',
        table: {
          headerRows: 1,
          widths: ['*', 'auto', 100, '*'],
          body: [
            [{
              text: 'SECTION 4: CARE GIVING',
              style: 'title',
              colSpan: 4
            },
            {},
            {},
            {}
            ],
            [
              {
                stack: [
                  {
                    text: 'Number of dependent (persons relying on you for financial support)',
                    style: 'fieldName'
                  },
                  {
                    text: this.survey.careGiving.numberOfDependants,
                    style: 'fieldValue'
                  }

                ],
                colSpan: 4,
              },
              {},
              {}, {}
            ],
            [
              {
                text: 'Care Provider Information',
                style: 'subTitle',
                colSpan: 4
              },
              {},
              {},
              {}
            ],
            [
              {
                text: 'Provider of care for: ',
                style: 'fieldName',
                colSpan: 4
              },
              {},
              {},
              {}
            ],
            (this.survey.careGiving.careProvider.length > 0) ?
              [
                {
                  stack: [
                    {

                    },
                    {
                      ul: [
                        ...this.survey.careGiving.careProvider.map(care => {
                          return [
                            { text: care, style: 'fieldValue', },
                          ];
                        })
                      ]
                    }
                  ],
                  colSpan: 2,

                },



                {}, {}, {}

              ]

              : [
                {
                  text: 'No Care Provider Information Provided',
                  style: 'fieldValue',
                  colSpan: 3
                },
                {}, {}, {}
              ],
            [
              {
                text: 'Day Care Information',
                style: 'subTitle',
                colSpan: 4
              },
              {},
              {},
              {}
            ],
            [
              {
                stack: [
                  {
                    text: 'Daycare available in the community',
                    style: 'fieldName'
                  },
                  {
                    text: this.survey.careGiving.dayeCareAvailable,
                    style: 'fieldValue'
                  }

                ],
                colSpan: 1,
              },
              {},
              (this.survey.careGiving.dayeCareAvailable == 'No') ? {
                stack: [
                  {
                    text: 'Does the community need one?',
                    style: 'fieldName'
                  },
                  {
                    text: this.survey.careGiving.needsCare,
                    style: 'fieldValue'
                  }

                ],

                colSpan: this.survey.careGiving.needsCare == 'Yes' ? 1 : 2,
              } : {
                stack: [
                  {
                    text: 'Type of care available',
                    style: 'fieldName'
                  },
                  {
                    text: this.survey.careGiving.typeOfCareAvailable,
                    style: 'fieldValue'
                  }

                ],
                colSpan: 2,
              },
              (this.survey.careGiving.needsCare == 'Yes') ? {
                stack: [
                  {
                    text: 'Type of care needed',
                    style: 'fieldName'
                  },
                  {
                    text: this.survey.careGiving.neededCare,
                    style: 'fieldValue'
                  }

                ],
                colSpan: 1,
              } :
                {

                },

            ],

          ],

        }
      },
      {
        style: 'table',
        layout: 'lightHorizontalLines',
        table: {
          headerRows: 1,
          widths: ['*', 'auto', 100, '*'],
          body: [
            [{
              text: 'SECTION 5: EDUCATION',
              style: 'title',
              colSpan: 4
            },
            {},
            {},
            {}
            ],
            [
              {
                stack: [
                  {
                    text: 'Highest level of education you have completed and area of study',
                    style: 'fieldName'
                  },
                  {
                    text: this.survey.education.highestLevelOfEdu,
                    style: 'fieldValue'
                  }

                ],
                colSpan: 2,
              },
              {},
              {
                stack: [
                  {
                    text: 'Details of any post-secondary (area of study, what year you completed etc.)',
                    style: 'fieldName'
                  },
                  {
                    text: this.survey.education.postSecondaryEdu,
                    style: 'fieldValue'
                  }

                ],
                colSpan: 2,
              },
              {}
            ],
            [
              {
                text: "The educational level you would like to acheive",
                style: 'fieldName',
                colSpan: 4
              },
              {},
              {},
              {}
            ],
            (this.survey.education.levelOfEduToAchieve.length > 0) ?
              [
                {
                  stack: [
                    {

                    },
                    {
                      ul: [
                        ...this.survey.education.levelOfEduToAchieve.map(edu => {
                          return [
                            { text: edu, style: 'fieldValue', },
                          ];
                        })
                      ]
                    }
                  ],
                  colSpan: 2,
                },

                {}, {
                }, {}

              ]
              : [
                {
                  text: 'No Information on Educational Level Provided',
                  style: 'fieldValue',
                  colSpan: 3
                },
                {}, {}, {}
              ],

            [
              {
                stack: [
                  {
                    text: 'Area of study, trade or career you would like to Achieve',
                    style: 'fieldName'
                  },
                  {
                    text: this.survey.education.areaOfStudyToAchieve,
                    style: 'fieldValue'
                  }

                ],
                colSpan: 4,
              },
              {},
              {},
              {}
            ],
            [
              {
                text: 'Current Education',
                style: 'subTitle',
                colSpan: 4
              },
              {},
              {},
              {}
            ],
            [
              {
                stack: [
                  {
                    text: 'Are you currently enrolled in an education program?',
                    style: 'fieldName'
                  },
                  {
                    text: this.survey.education.currentlyEnrolled,
                    style: 'fieldValue'
                  }

                ],
                colSpan: 2,
              },
              {},
              (this.survey.education.currentlyEnrolled == 'No') ? {
                stack: [
                  {
                    text: 'Would you like to upgrade/further your education?',
                    style: 'fieldName'
                  },
                  {
                    text: this.survey.education.upgradeEdu,
                    style: 'fieldValue'
                  }

                ],

                colSpan: 2,
              } : {
                stack: [
                  {
                    text: 'Type of Program',
                    style: 'fieldName'
                  },
                  {
                    text: this.survey.education.typeOfProgram,
                    style: 'fieldValue'
                  }

                ],
                colSpan: 2,
              },
              {},
              // {}
            ],
            (this.survey.education.upgradeEdu == 'No') ? [
              {
                stack: [
                  {
                    text: 'Why don\'t you want to upgrade or further your education? ',
                    style: 'fieldName'
                  },
                  {
                    text: this.survey.education.whyNoUpgrade,
                    style: 'fieldValue'
                  }

                ],
                colSpan: 4,
              }, {}, {}, {}
            ] :
              [
                {
                  // stack: [
                  //   {
                  //     text: 'When would you like to do so?',
                  //     style: 'fieldName'
                  //   },
                  //   {
                  //     text: this.survey.education.whenToUpgradeEducation,
                  //     style: 'fieldValue'
                  //   }

                  // ],
                  // colSpan: 2,

                  stack: [
                    {
                      text: 'Type of program would you like to do',
                      style: 'fieldName'
                    },
                    {
                      text: this.survey.education.typeOfProgramToUpgrade,
                      style: 'fieldValue'
                    }

                  ],
                  colSpan: 4,
                }, {}, {}, {}
              ],


            // [
            //   (this.survey.education.upgradeEdu == 'No') ? {
            //     stack: [
            //       {
            //         text: 'Why don\'t you want to upgrade or further your education? ',
            //         style: 'fieldName'
            //       },
            //       {
            //         text: this.survey.education.whyNoUpgrade,
            //         style: 'fieldValue'
            //       }

            //     ],
            //     colSpan: 4,
            //   } : {},

            //   {},

            //   (this.survey.education.upgradeEdu == 'Yes') ? {
            //     stack: [
            //       {
            //         text: 'When would you like to do so?',
            //         style: 'fieldName'
            //       },
            //       {
            //         text: this.survey.education.whenToUpgradeEducation,
            //         style: 'fieldValue'
            //       }

            //     ],
            //     colSpan: 2,
            //   } : {},
            //   (this.survey.education.upgradeEdu == 'Yes') ? 
            //   {
            //     stack: [
            //       {
            //         text: 'Type of program would you like to do',
            //         style: 'fieldName'
            //       },
            //       {
            //         text: this.survey.education.typeOfProgramToUpgrade,
            //         style: 'fieldValue'
            //       }

            //     ],
            //     colSpan: 2,
            //   } : {},
            // ],
            [
              {
                stack: [
                  {
                    text: 'Do you know who to contact to obtain upgrading or furthering your education?',
                    style: 'fieldName'
                  },
                  {
                    text: this.survey.education.knowWhoToContactForUpgrade,
                    style: 'fieldValue'
                  }

                ],

                colSpan: 4,
              },
              {},
              {},
              {}
            ],

            [
              {
                stack: [
                  {
                    text: 'Do you know how to apply to a College/University?',
                    style: 'fieldName'
                  },
                  {
                    text: this.survey.education.collegeApply,
                    style: 'fieldValue'
                  }

                ],

                colSpan: 4,
              },
              {},
              {},
              {}
            ],

            [
              {
                stack: [
                  {
                    text: 'Do you know how to apply for funding?',
                    style: 'fieldName'
                  },
                  {
                    text: this.survey.education.applyForFunding,
                    style: 'fieldValue'
                  }

                ],

                colSpan: 4,
              },
              {},
              {},
              {}
            ],

          ],

        }
      },
      {
        style: 'table',
        layout: 'lightHorizontalLines',
        table: {
          headerRows: 1,
          widths: ['*', 'auto', 100, '*'],
          body: [
            [{
              text: 'SECTION 6:  CLASS OF OPERATORS LICENSE',
              style: 'title',
              colSpan: 4
            },
            {},
            {},
            {}
            ],
            [{
              text: 'License Infornation',
              style: 'subTitle',
              colSpan: 4
            },
            {},
            {},
            {}
            ],
            [
              {
                text: "What type(s) license(s) do you currently possess?",
                style: 'fieldName',
                colSpan: 4
              },
              {},
              {},
              {}
            ],
            (this.survey.license.typeOfLicense.length > 0) ?
              [
                {
                  stack: [
                    {

                    },
                    {
                      ul: [
                        ...this.survey.license.typeOfLicense.map(license => {
                          return [
                            { text: license, style: 'fieldValue', },
                          ];
                        })
                      ]
                    }
                  ],
                  colSpan: 2,
                },

                {}, {
                }, {}

              ]
              : [
                {
                  text: 'No Information on Current License Provided',
                  style: 'fieldValue',
                  colSpan: 3
                },
                {}, {}, {}
              ],
            [
              {
                text: "Barriers to obtaining a license",
                style: 'fieldName',
                colSpan: 4
              },
              {},
              {},
              {}
            ],
            (this.survey.license.barrierForLicense.length > 0) ?
              [
                {
                  stack: [
                    {

                    },
                    {
                      ul: [
                        ...this.survey.license.barrierForLicense.map(license => {
                          return [
                            { text: license, style: 'fieldValue', },
                          ];
                        })
                      ]
                    }
                  ],
                  colSpan: 2,
                },

                {}, {
                }, {}

              ]
              : [
                {
                  text: 'No Information Provided on Barriers for Obtaining a License',
                  style: 'fieldValue',
                  colSpan: 3
                },
                {}, {}, {}
              ],
            [
              {
                text: "What type(s) license(s) would do like to obtain?",
                style: 'fieldName',
                colSpan: 4
              },
              {},
              {},
              {}
            ],
            (this.survey.license.typeOfLicenseToObtain.length > 0) ?
              [
                {
                  stack: [
                    {

                    },
                    {
                      ul: [
                        ...this.survey.license.typeOfLicenseToObtain.map(license => {
                          return [
                            { text: license, style: 'fieldValue', },
                          ];
                        })
                      ]
                    }
                  ],
                  colSpan: 2,
                },

                {}, {
                }, {}

              ]
              : [
                {
                  text: 'No Information Provided on Barriers for Obtaining a License',
                  style: 'fieldValue',
                  colSpan: 3
                },
                {}, {}, {}
              ],
            [
              {
                stack: [
                  {
                    text: 'Would you like to be notified when training is offered?',
                    style: 'fieldName'
                  },
                  {
                    text: this.survey.license.beNotifiedForTraining,
                    style: 'fieldValue'
                  }

                ],

                colSpan: this.survey.license.beNotifiedForTraining === "Yes" ? 2 : 4,
              },
              {},
              (this.survey.license.beNotifiedForTraining === "Yes")
                ? {
                  stack: [
                    {
                      text: 'How would you like to be notified?',
                      style: 'fieldName'
                    },
                    {
                      text: this.survey.license.howToBeNotified,
                      style: 'fieldValue'
                    }

                  ],

                  colSpan: 2,
                }
                :
                {},
              {}
            ],


          ],

        }
      },
      {
        style: 'table',
        layout: 'lightHorizontalLines',
        table: {
          headerRows: 1,
          // widths: ['*', 'auto', 100, '*'],
          widths: ['*', '*', "*", "*"],
          body: [
            [{
              text: 'SECTION 7:  TRAINING - SAFTEY AND/OR TICKETS',
              style: 'title',
              colSpan: 4
            },
            {},
            {},
            {}
            ],
            (this.survey.safteyTraining.length > 0) ?
              [{
                colSpan: 4,
                margin: [0, 10, 0, 10],

                table: {
                  widths: ['*', '*'],
                  body: [
                    [
                      {
                        text: 'Training',
                        style: 'fieldName',
                        bold: true,
                        border: [true, true, true, true]
                      },
                      {
                        text: '',
                        style: 'fieldName',
                        bold: true,
                        border: [true, true, true, true]
                      },

                    ],
                    ...this.survey.safteyTraining.map(training => {
                      return [
                        { text: training.training, style: 'fieldName', border: [true, false, true, true] },
                        (training.dateValue.value == "Expired" && training.dateValue.date) ? { text: training.dateValue.value + ' on - ' + new Date(training.dateValue.date).toDateString(), style: 'fieldValue', border: [true, false, true, true] } :
                          { text: training.dateValue.value, style: 'fieldValue', border: [true, false, true, true] },



                      ];
                    })
                  ]
                }

              },
              {},
              {},
              {}
              ] : [
                {
                  text: 'No Training and Employemnet Information Provided',
                  style: 'fieldValue',
                  colSpan: 4
                },
                {},
                {},
                {}
              ]

          ],

        }
      },
      {
        style: 'table',
        layout: 'lightHorizontalLines',
        table: {
          // headerRows: 1,
          // widths: ['*', 'auto', 100, '*'],
          widths: ['*', '*', "*", "*"],
          body: [
            [{
              text: 'SECTION 8:  TRAINING & EMPLOYMENT OPPORTUNITIES / EMPLOYMENT & SKILLS',
              style: 'title',
              colSpan: 4
            },
            {},
            {},
            {}
            ],
            [
              {
                text: 'Notification',
                style: 'subTitle',
                colSpan: 4
              },
              {},
              {},
              {}
            ],
            [
              {
                text: "How would you prefer to be notified about training and/or employment opprotunities?",
                style: 'fieldName',
                colSpan: 4
              },
              {},
              {},
              {}
            ],
            (this.survey.trainingAndEmployment.notificationPreference.length > 0) ?
              [
                {
                  stack: [
                    {

                    },
                    {
                      ul: [
                        ...this.survey.trainingAndEmployment.notificationPreference.map(training => {
                          return [
                            { text: training, style: 'fieldValue', },
                          ];
                        })
                      ]
                    }
                  ],
                  colSpan: 2,
                },

                {}, {
                }, {}

              ]
              : [
                {
                  text: 'No Information on Information for training',
                  style: 'fieldValue',
                  colSpan: 4
                },
                {}, {}, {}
              ],
            [
              {
                stack: [
                  {
                    text: 'Email address to be part of a mailing list for education, training and employment opportunities',
                    style: 'fieldName'
                  },
                  {
                    text: this.survey.trainingAndEmployment.notifcationEmail,
                    style: 'fieldValue'
                  }

                ],
                colSpan: 4,
              },
              {},
              {}, {}
            ],
            [
              {
                text: 'Employment',
                style: 'subTitle',
                colSpan: 4
              },
              {},
              {},
              {}
            ],
            [
              {
                stack: [
                  {
                    text: 'Are you currently employed?',
                    style: 'fieldName'
                  },
                  {
                    text: this.survey.trainingAndEmployment.currentlyEmployed,
                    style: 'fieldValue'
                  }

                ],
                colSpan: 2,
              },
              {},
              {
                stack: [
                  {
                    text: 'Comments/Clarification',
                    style: 'fieldName'
                  },
                  {
                    text: this.survey.trainingAndEmployment.currentEmploymentComment,
                    style: 'fieldValue'
                  }
                ],
                colSpan: 2,

              }, {}
            ],
            (this.survey.trainingAndEmployment.currentlyEmployed === "Employed")
              ? [
                {
                  stack: [
                    {
                      text: 'Employment Status',
                      style: 'fieldName'
                    },
                    {
                      text: this.survey.trainingAndEmployment.employmentStatus,
                      style: 'fieldValue'
                    }

                  ],
                  colSpan: 4,
                },
                {},
                {}, {}
              ] : [
                {}, {}, {}, {}
              ],
            [
              {
                stack: [
                  {
                    text: 'Are you currently seeking employment?',
                    style: 'fieldName'
                  },
                  {
                    text: this.survey.trainingAndEmployment.currentlySeekingEmployment,
                    style: 'fieldValue'
                  }

                ],
                colSpan: 4,
              },
              {}, {}, {}
            ],
            [
              {
                stack: [
                  {
                    text: this.survey.trainingAndEmployment.currentlySeekingEmployment === 'Yes'
                      ? 'For how long?' : 'What support do you need for you to enter/reenter the workforce?',

                    style: 'fieldName'
                  },
                  {
                    text: this.survey.trainingAndEmployment.reasonForSeekingEmployment,
                    style: 'fieldValue'
                  }

                ],
                colSpan: 4,
              },
              {}, {}, {},
            ],
            [
              {
                text: 'Employment Goals',
                style: 'subTitle',
                colSpan: 4
              },
              {},
              {},
              {}
            ],
            [
              {
                text: "What best describes your current and desired employment goals?",
                style: 'fieldName',
                colSpan: 4
              },
              {},
              {},
              {}
            ],
            (this.survey.trainingAndEmployment.employmentGoals.length > 0) ?
              [
                {
                  colSpan: 4,
                  margin: [0, 10, 0, 10],
                  widths: ['*', '*'],
                  table: {
                    body: [
                      [
                        {
                          text: 'Goal',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, true, true]
                        },
                        {
                          text: 'value',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, true, true]
                        },

                      ],
                      ...this.survey.trainingAndEmployment.employmentGoals.map(employmentGoal => {
                        return [
                          { text: employmentGoal.goal, style: 'fieldName', border: [false, false, true, true] },
                          { text: employmentGoal.value, style: 'fieldValue', border: [false, false, true, true] },


                        ]
                      })
                      // ,
                      // ...this.survey.trainingAndEmployment.secondEmploymentGoals.map(employmentGoal => {
                      //   return [
                      //     { text: employmentGoal.goal, style: 'fieldName', border: [false, false, true, true] },
                      //     { text: employmentGoal.value, style: 'fieldValue', border: [false, false, true, true] },


                      //   ]
                      // })
                    ]
                  }

                },
                {},
                {},
                {}
              ] : [
                {
                  text: 'No Employments Goal Information Provided',
                  style: 'fieldValue',
                  colSpan: 4
                },
                {}, {}, {}]


          ],

        }
      },
      {
        style: 'table',
        layout: 'lightHorizontalLines',
        table: {
          headerRows: 1,
          // widths: ['*', 'auto', 100, '*'],
          widths: ['*', '*', "*", "*"],
          body: [
            [{
              text: 'SECTION 9: JOB /LIFE SATISFACTION',
              style: 'title',
              colSpan: 4
            },
            {},
            {},
            {}
            ],
            [
              {
                text: 'Job Satistfaction',
                style: 'subTitle',
                colSpan: 4
              },
              {},
              {},
              {}
            ],
            [
              {
                stack: [
                  {
                    text: 'Are any of these reasons for your unemployment?',
                    style: 'fieldName'
                  },
                  {
                    text: this.survey.jobSatisfaction.satisfiedWithEmployment,
                    style: 'fieldValue'
                  }

                ],
                colSpan: 4,
              },
              {},
              {}, {}
            ],
            [
              {
                stack: [
                  {
                    text: 'Why you are satisfied or not satisfied with your current job/situation',
                    style: 'fieldName'
                  },
                  {
                    text: this.survey.jobSatisfaction.detailsForSatisfaction,
                    style: 'fieldValue'
                  }

                ],
                colSpan: 4,
              },
              {},
              {}, {}
            ],
            (this.survey.jobSatisfaction.detailsForSatisfaction.length > 0) ?
              [
                {
                  stack: [
                    {
                      text: 'Factors impacting your satisfaction',
                      style: 'fieldName'
                    },
                    {
                      ul: [
                        ...this.survey.jobSatisfaction.factorsForSatisfaction.map(factor => {
                          return [
                            {
                              text: factor.detail != '' ? `${factor.factor} - ${factor.detail}` : factor.factor,
                              style: 'fieldValue',
                            },
                          ];
                        })
                      ]
                    }
                  ],
                  colSpan: 4,
                },

                {}, {
                }, {}

              ]
              : [
                {
                  text: 'No Information on Job Satisfaction factors',
                  style: 'fieldValue',
                  colSpan: 4
                },
                {}, {}, {}
              ],
            [
              {
                stack: [
                  {
                    text: 'Details on the factors impacting you job satisfaction',
                    style: 'fieldName'
                  },
                  {
                    text: this.survey.jobSatisfaction.detailsForSatisfaction,
                    style: 'fieldValue'
                  }

                ],
                colSpan: 4,
              },
              {},
              {}, {}
            ],




          ],

        }
      },
      {
        style: 'table',
        layout: 'lightHorizontalLines',
        table: {
          headerRows: 1,
          // widths: ['*', 'auto', 100, '*'],
          widths: ['*', '*', "*", "*"],
          body: [
            [{
              text: 'SECTION 10: BARRIERS TO GETTING OR KEEPING A JOB',
              style: 'title',
              colSpan: 4
            },
            {},
            {},
            {}
            ],
            [
              {
                text: 'Job Barriers',
                style: 'subTitle',
                colSpan: 4
              },
              {},
              {},
              {}
            ],
            [
              {
                stack: [
                  {
                    text: 'What do you believe is stopping you from getting or keeping a job?',
                    style: 'fieldName'
                  }
                ],
                colSpan: 4,
              },
              {},
              {}, {}
            ],
            (this.survey.barriersOfJob.barriers.length > 0) ?
              [
                {
                  colSpan: 4,
                  margin: [0, 10, 0, 10],
                  widths: ['*', '*'],
                  table: {
                    body: [
                      [
                        {
                          text: 'Barrier',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, true, true]
                        },
                        {
                          text: '',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, true, true]
                        },

                      ],
                      ...this.survey.barriersOfJob.barriers.map(barrier => {
                        return [
                          { text: barrier.barrier, style: 'fieldName', border: [false, false, true, true] },
                          { text: barrier.value, style: 'fieldValue', border: [false, false, true, true] },


                        ]
                      })
                      // ,
                      // ...this.survey.barriersOfJob.secondBarriers.map(barrier => {
                      //   return [
                      //     { text: barrier.barrier, style: 'fieldName', border: [false, false, true, true] },
                      //     { text: barrier.value, style: 'fieldValue', border: [false, false, true, true] },

                      //   ]
                      // })
                    ]
                  }

                },
                {},
                {},
                {}
              ] : [
                {
                  text: 'No Job Barrier Information Provided',
                  style: 'fieldValue',
                  colSpan: 4
                },
                {}, {}, {}],
            [
              {
                text: 'Employment affected by COVID-19',
                style: 'subTitle',
                colSpan: 4
              },
              {},
              {},
              {}
            ],
            [
              {
                stack: [
                  {
                    text: 'Has your employment been affected by COVID -19?',
                    style: 'fieldName'
                  },
                  {
                    text: this.survey.barriersOfJob.covidAffected,
                    style: 'fieldValue'
                  }

                ],
                colSpan: 2,
              },
              {},
              {
                stack: [
                  {
                    text: 'Explanation',
                    style: 'fieldName'
                  },
                  {
                    text: this.survey.barriersOfJob.explanation,
                    style: 'fieldValue'
                  }

                ],
                colSpan: 2,
              }, {}
            ],



          ],

        }
      },
      {
        style: 'table',
        layout: 'lightHorizontalLines',
        table: {
          // headerRows: 1,
          // widths: ['*', 'auto', 100, '*'],
          widths: ['*', '*', '*', '*'],
          body: [
            [{
              text: 'SECTION 11: EMPLOYMENT HISTORY',
              style: 'title',
              colSpan: 4
            },
            {},
            {},
            {}
            ],
            [
              {
                text: 'Employment History',
                style: 'subTitle',
                colSpan: 4
              },
              {},
              {},
              {}
            ],
            [
              {
                stack: [
                  {
                    text: 'What category best describes your employment history? How many years of experience?',
                    style: 'fieldName'
                  },
                ],
                colSpan: 4,
              },
              {},
              {}, {}
            ],
            (this.survey.employmentHistory.catagory.length > 0) ?
              [
                {
                  colSpan: 4,
                  margin: [0, 10, 0, 10],
                  widths: ['*', '*'],
                  table: {
                    body: [
                      [
                        {
                          text: 'Field',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, true, true]
                        },
                        {
                          text: '',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, true, true]
                        },

                      ],
                      ...this.survey.employmentHistory.catagory.map(catagory => {
                        return [
                          { text: catagory.history, style: 'fieldName', border: [false, false, true, true] },
                          { text: catagory.value, style: 'fieldValue', border: [false, false, true, true] },


                        ]
                      })
                      // ,
                      // ...this.survey.employmentHistory.secondCatagory.map(catagory => {
                      //   return [
                      //     { text: catagory.history, style: 'fieldName', border: [false, false, true, true] },
                      //     { text: catagory.value, style: 'fieldValue', border: [false, false, true, true] },

                      //   ]
                      // })
                    ]
                  }

                },
                {},
                {},
                {}
              ] : [
                {
                  text: 'No Employment Information Provided',
                  style: 'fieldValue',
                  colSpan: 4
                },
                {},
                {}, {}
              ],
            [
              {
                text: 'Previous Expirience',
                style: 'subTitle',
                colSpan: 4
              },
              {},
              {},
              {}
            ],
            [
              {
                stack: [
                  {
                    text: 'Do you have any previous experience?',
                    style: 'fieldName'
                  },
                ],
                colSpan: 4,
              },
              {},
              {}, {}
            ],
            (this.survey.employmentHistory.previousExpirence.length > 0) ?
              [
                {
                  colSpan: 4,
                  margin: [0, 10, 0, 10],
                  widths: ['*', '*'],
                  table: {
                    body: [
                      [
                        {
                          text: 'Expirience',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, true, true]
                        },
                        {
                          text: '',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, true, true]
                        },

                      ],
                      ...this.survey.employmentHistory.previousExpirence.map(expirience => {
                        return [
                          { text: expirience.expirience, style: 'fieldName', border: [false, false, true, true] },
                          { text: expirience.value, style: 'fieldValue', border: [false, false, true, true] },

                        ]
                      }),

                    ]
                  }

                },
                {},
                {},
                {}
              ] : [
                {
                  text: 'No Previous Expirience Information Provided',
                  style: 'fieldValue',
                  colSpan: 4
                },
                {},
                {}, {}
              ],
            [
              {
                stack: [
                  {
                    text: 'If you would not like training in these areas, please explain why not.',
                    style: 'fieldName'
                  },
                  {
                    text: this.survey.employmentHistory.noTrainingReason,
                    style: 'fieldValue'
                  }

                ],
                colSpan: 4,
              },
              {},
              {}, {}
            ],
            [
              {
                text: 'The next 3 section are about your 3 most recent jobs',
                style: 'subTitle',
                colSpan: 4
              },
              {},
              {},
              {}
            ],
            [
              {
                text: '1. First Recent Job',
                style: 'subTitle',
                colSpan: 4
              },
              {},
              {},
              {}
            ],
            (!this.survey.employmentHistory.firstRecentJob.noRecentJob) ?
              [
                {
                  stack: [
                    {
                      text: 'Employer',
                      style: 'fieldName'
                    },
                    {
                      text: this.survey.employmentHistory.firstRecentJob.employer,
                      style: 'fieldValue'
                    }

                  ],
                  colSpan: 1,
                },
                {
                  stack: [
                    {
                      text: 'Job Title',
                      style: 'fieldName'
                    },
                    {
                      text: this.survey.employmentHistory.firstRecentJob.jobTitle,
                      style: 'fieldValue'
                    }

                  ],
                  colSpan: 1,
                },
                {
                  stack: [
                    {
                      text: 'Main Duties',
                      style: 'fieldName'
                    },
                    {
                      text: this.survey.employmentHistory.firstRecentJob.mainDuties,
                      style: 'fieldValue'
                    }

                  ],
                  colSpan: 1,
                },
                {
                  stack: [
                    {
                      text: 'Approx. dates of employment',
                      style: 'fieldName'
                    },
                    {
                      text: this.survey.employmentHistory.firstRecentJob.startDateOfEmployment + ' - ' + this.survey.employmentHistory.firstRecentJob.endDateOfEmployment,
                      style: 'fieldValue'
                    }

                  ],
                  colSpan: 1,
                }

              ] : [
                {
                  text: 'No Information About Recent Job',
                  style: 'fieldValue',
                  colSpan: 4,
                }, {}, {}, {}
              ],
            (!this.survey.employmentHistory.firstRecentJob.noRecentJob) ?
              [
                {
                  stack: [
                    {
                      text: 'Still working there/reason you left',
                      style: 'fieldName'
                    },
                    {
                      text: this.survey.employmentHistory.firstRecentJob.stillWorkingThere,
                      style: 'fieldValue'
                    }

                  ],
                  colSpan: 4,
                },
                {}, {}, {}

              ] : [
                {}, {}, {}, {}
              ],
            [
              {
                text: '2. Second Recent Job',
                style: 'subTitle',
                colSpan: 4
              },
              {},
              {},
              {}
            ],
            (!this.survey.employmentHistory.secondRecentJob.noRecentJob) ?
              [
                {
                  stack: [
                    {
                      text: 'Employer',
                      style: 'fieldName'
                    },
                    {
                      text: this.survey.employmentHistory.secondRecentJob.employer,
                      style: 'fieldValue'
                    }

                  ],
                  colSpan: 1,
                },
                {
                  stack: [
                    {
                      text: 'Job Title',
                      style: 'fieldName'
                    },
                    {
                      text: this.survey.employmentHistory.secondRecentJob.jobTitle,
                      style: 'fieldValue'
                    }

                  ],
                  colSpan: 1,
                },
                {
                  stack: [
                    {
                      text: 'Main Duties',
                      style: 'fieldName'
                    },
                    {
                      text: this.survey.employmentHistory.secondRecentJob.mainDuties,
                      style: 'fieldValue'
                    }

                  ],
                  colSpan: 1,
                },
                {
                  stack: [
                    {
                      text: 'Approx. dates of employment',
                      style: 'fieldName'
                    },
                    {
                      text: this.survey.employmentHistory.secondRecentJob.startDateOfEmployment + ' - ' + this.survey.employmentHistory.secondRecentJob.endDateOfEmployment,
                      style: 'fieldValue'
                    }

                  ],
                  colSpan: 1,
                }

              ] : [
                {
                  text: 'No Information About Second Recent Job',
                  style: 'fieldValue',
                  colSpan: 4,
                }, {}, {}, {}
              ],
            (!this.survey.employmentHistory.secondRecentJob.noRecentJob) ?
              [
                {
                  stack: [
                    {
                      text: 'Still working there/reason you left',
                      style: 'fieldName'
                    },
                    {
                      text: this.survey.employmentHistory.secondRecentJob.stillWorkingThere,
                      style: 'fieldValue'
                    }

                  ],
                  colSpan: 4,
                },
                {}, {}, {}

              ] : [
                {}, {}, {}, {}
              ],
            [
              {
                text: '3. Third Recent Job',
                style: 'subTitle',
                colSpan: 4
              },
              {},
              {},
              {}
            ],
            (!this.survey.employmentHistory.thirdRecentJob.noRecentJob) ?
              [
                {
                  stack: [
                    {
                      text: 'Employer',
                      style: 'fieldName'
                    },
                    {
                      text: this.survey.employmentHistory.thirdRecentJob.employer,
                      style: 'fieldValue'
                    }

                  ],
                  colSpan: 1,
                },
                {
                  stack: [
                    {
                      text: 'Job Title',
                      style: 'fieldName'
                    },
                    {
                      text: this.survey.employmentHistory.thirdRecentJob.jobTitle,
                      style: 'fieldValue'
                    }

                  ],
                  colSpan: 1,
                },
                {
                  stack: [
                    {
                      text: 'Main Duties',
                      style: 'fieldName'
                    },
                    {
                      text: this.survey.employmentHistory.thirdRecentJob.mainDuties,
                      style: 'fieldValue'
                    }

                  ],
                  colSpan: 1,
                },
                {
                  stack: [
                    {
                      text: 'Approx. dates of employment',
                      style: 'fieldName'
                    },
                    {
                      text: this.survey.employmentHistory.thirdRecentJob.startDateOfEmployment + ' - ' + this.survey.employmentHistory.thirdRecentJob.endDateOfEmployment,
                      style: 'fieldValue'
                    }

                  ],
                  colSpan: 1,
                }

              ] : [
                {
                  text: 'No Information About Third Recent Job',
                  style: 'fieldValue',
                  colSpan: 4,
                }, {}, {}, {}
              ],
            (!this.survey.employmentHistory.thirdRecentJob.noRecentJob) ?
              [
                {
                  stack: [
                    {
                      text: 'Still working there/reason you left',
                      style: 'fieldName'
                    },
                    {
                      text: this.survey.employmentHistory.thirdRecentJob.stillWorkingThere,
                      style: 'fieldValue'
                    }

                  ],
                  colSpan: 4,
                },
                {}, {}, {}

              ] : [
                {}, {}, {}, {}
              ]



          ],

        }
      },
      {
        style: 'table',
        layout: 'lightHorizontalLines',
        table: {
          headerRows: 1,
          // widths: ['*', 'auto', 100, '*'],
          widths: ['*', '*'],
          body: [
            [{
              text: 'SECTION 12: EMPLOYMENT ON-RESERVE & OFF-RESERVE / SHIFT WORK',
              style: 'title',
              colSpan: 2
            },
            {},
            ],
            [
              {
                text: 'Shift Work',
                style: 'subTitle',
                colSpan: 2
              },
              {},

            ],
            [
              {
                stack: [
                  {
                    text: 'Do you feel more comfortable going out to work in groups then alone?',
                    style: 'fieldName'
                  },
                  {
                    text: this.survey.shiftWork.workAlone,
                    style: 'fieldValue'
                  }

                ],
                colSpan: this.survey.shiftWork.workAlone === 'Yes' ? 1 : 2,
              },

              (this.survey.shiftWork.workAlone === 'Yes') ? {
                stack: [
                  {
                    text: 'If so, why? ',
                    style: 'fieldName'
                  },
                  {
                    text: this.survey.shiftWork.workAloneReason,
                    style: 'fieldValue'
                  }
                ],
                colSpan: 1,

              } : {},
            ],

            [
              {
                stack: [
                  {
                    text: 'Are you willing to work shift work outside of the community?',
                    style: 'fieldName'
                  },
                  {
                    text: this.survey.shiftWork.shiftWorkOutsideCommunity,
                    style: 'fieldValue'
                  }

                ],
                colSpan: this.survey.shiftWork.shiftWorkOutsideCommunity === 'No' ? 1 : 2,
              },

              (this.survey.shiftWork.shiftWorkOutsideCommunity === 'No') ? {
                stack: [
                  {
                    text: 'Explanation',
                    style: 'fieldName'
                  },
                  {
                    text: this.survey.shiftWork.noReason,
                    style: 'fieldValue'
                  }
                ],
                colSpan: 1,

              } : {},
            ],
            [
              {
                stack: [
                  {
                    text: 'Are you willing to relocate outside of the community?',
                    style: 'fieldName'
                  },
                  {
                    text: this.survey.shiftWork.willingToRelocate,
                    style: 'fieldValue'
                  }

                ],
                colSpan: 2,
              },

              {


              }
            ],
            [
              {
                stack: [
                  {
                    text: 'Are you willing to commute for work?',
                    style: 'fieldName'
                  },
                  {
                    text: this.survey.shiftWork.commuteForWork,
                    style: 'fieldValue'
                  }
                ],
                colSpan: 1,
              },

              (this.survey.shiftWork.commuteForWork === "Yes") ?
                {
                  stack: [
                    {
                      text: 'How far would you commute?',
                      style: 'fieldName'
                    },
                    {
                      text: this.survey.shiftWork.commuteLength.length,
                      style: 'fieldValue'
                    },
                    {
                      text: 'Comments',
                      style: 'fieldName'
                    },
                    {
                      text: this.survey.shiftWork.commuteLength.comments,
                      style: 'fieldValue'
                    },

                  ],
                  colSpan: 1,

                } : {}
            ],
            // (this.survey.shiftWork.commuteForWork === "Yes") ?
            //   [
            //     {
            //       stack: [
            //         {
            //           text: 'How far would you commute?',
            //           style: 'fieldName'
            //         },
            //         {
            //           text: this.survey.shiftWork.commuteLength.length,
            //           style: 'fieldValue'
            //         }
            //       ],
            //       colSpan: 1,

            //     }
            //     , {
            //       stack: [
            //         {
            //           text: 'Comments',
            //           style: 'fieldName'
            //         },
            //         {
            //           text: this.survey.shiftWork.commuteLength.comments,
            //           style: 'fieldValue'
            //         }
            //       ],
            //       colSpan: 1,
            //     }
            //   ]
            //   : [
            //     {}, {}
            //   ],

            (this.survey.shiftWork.commuteForWork === "Yes" || this.survey.shiftWork.willingToRelocate === "Yes") ?
              [
                {
                  stack: [
                    {
                      text: 'What supports would you require to relocate?',
                      style: 'fieldName',

                    },
                    {
                      ul: [
                        ...this.survey.shiftWork.supportForRelocate.map(support => {
                          return [
                            { text: support.support, style: 'fieldValue', },
                          ];
                        })
                      ]
                    }
                  ],
                  colSpan: 2,
                },

                {},
              ]
              : [
                {}, {}
              ],
            [
              {
                text: 'Workshops',
                style: 'subTitle',
                colSpan: 2
              },
              {},

            ],
            [
              {
                text: 'What type of workshops would you like to have in place in the community?',
                style: 'fieldName',
                colSpan: 2
              },
              {}

            ],
            (this.survey.shiftWork.workshopsInCommunity.length > 0) ?
              [

                {
                  stack: [

                    {
                      ul: [
                        ...this.survey.shiftWork.workshopsInCommunity.map(workshop => {
                          return [
                            { text: workshop, style: 'fieldValue', },
                          ];
                        })
                      ]
                    }
                  ],
                  colSpan: 2,
                },
                {}


              ] : [{
                text: 'No Information Provided Workshops to have in place in the community',
                style: 'fieldValue',
                colSpan: 2
              }, {}],

            [
              {
                stack: [
                  {
                    text: 'Are you more comfortable with in-community workshop instructors or local community member workshops?',
                    style: 'fieldName'
                  },
                  {
                    text: this.survey.shiftWork.localOrInCommunityWorkshop,
                    style: 'fieldValue'
                  }

                ],
                colSpan: 2,
              },
              {}
            ],
            [
              {
                stack: [
                  {
                    text: ' Are you interested in creating support groups?',
                    style: 'fieldName'
                  },
                  {
                    text: this.survey.shiftWork.supportGroup,
                    style: 'fieldValue'
                  }

                ],
                colSpan: 1,
              },

              (this.survey.shiftWork.supportGroup === "Yes") ?
                {
                  stack: [
                    {
                      text: 'What types of support groups?',
                      style: 'fieldName'
                    },
                    {
                      stack: [

                        {
                          ul: [
                            ...this.survey.shiftWork.typesOfSupportGroup.map(support => {
                              return [
                                { text: support, style: 'fieldValue', },
                              ];
                            })
                          ]
                        },

                      ],
                    }
                  ],
                  colSpan: 1,

                } :
                {
                }
            ],
            [
              {
                stack: [
                  {
                    text: 'Would you be interested in a career assessment to help you figure out what careers you might be suited for?',
                    style: 'fieldName'
                  },
                  {
                    text: this.survey.shiftWork.careerAssessment,
                    style: 'fieldValue'
                  }

                ],
                colSpan: 2,
              },
              {}
            ],



          ],

        }
      },
      {
        style: 'table',
        layout: 'lightHorizontalLines',
        table: {
          headerRows: 1,
          // widths: ['*', 'auto', 100, '*'],
          widths: ['*', '*'],
          body: [
            [{
              text: 'SECTION 13: ADDICTION SUPPORT',
              style: 'title',
              colSpan: 2
            },
            {},
            ],

            [
              {
                stack: [
                  {
                    text: 'Would you like information about the NNADAP Program?',
                    style: 'fieldName'
                  },
                  {
                    text: this.survey.addictionsSupport.infoOnProgram,
                    style: 'fieldValue'
                  }

                ],
                colSpan: 1,
              },

              (this.survey.addictionsSupport.infoOnProgram === "Yes") ?
                {
                  stack: [
                    {
                      text: 'What types of support groups?',
                      style: 'fieldName'
                    },
                    {
                      stack: [

                        {
                          ul: [
                            ...this.survey.addictionsSupport.infoNNADAP.map(info => {
                              return [
                                { text: info, style: 'fieldValue', },
                              ];
                            })
                          ]
                        },

                      ],
                    }
                  ],
                  colSpan: 1,

                } :
                {
                }
            ],
            [
              {
                stack: [
                  {
                    text: ' Would you like us to communicate with NNADAP on your behalf?',
                    style: 'fieldName'
                  },
                  {
                    text: this.survey.addictionsSupport.communicateOnBehalf.value,
                    style: 'fieldValue'
                  }

                ],
                colSpan: 1,
              },
              {
                stack: [
                  {
                    text: 'Comments',
                    style: 'fieldName'
                  },
                  {
                    text: this.survey.addictionsSupport.communicateOnBehalf.comments,
                    style: 'fieldValue'
                  }

                ],
                colSpan: 1,
              }
            ],



          ],

        }
      },
      {
        style: 'table',
        layout: 'lightHorizontalLines',
        table: {
          headerRows: 1,
          // widths: ['*', 'auto', 100, '*'],
          widths: ['*', '*'],
          body: [
            [{
              text: 'SECTION 14: TRADITIONAL EMPLOYMENT',
              style: 'title',
              colSpan: 2
            },
            {},
            ],

            [
              {
                text: 'Way of Life and Hunting',
                style: 'subTitle',
                colSpan: 2
              },
              {},

            ],
            // [
            //   {
            //     stack: [
            //       {
            //         text: 'Do you have any previous experience?',
            //         style: 'fieldName'
            //       },
            //     ],
            //     colSpan: 2,
            //   },
            //   {},

            // ],
            (this.survey.traditionalEmployment.skills.length > 0) ?
              [
                {
                  colSpan: 2,
                  margin: [0, 10, 0, 10],
                  widths: ['*', '*'],
                  table: {
                    body: [
                      [
                        {
                          text: 'Skill',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, true, true]
                        },
                        {
                          text: '',
                          style: 'fieldName',
                          bold: true,
                          border: [false, false, true, true]
                        },

                      ],
                      ...this.survey.traditionalEmployment.skills.map(skill => {
                        return [
                          { text: skill.skill, style: 'fieldName', border: [false, false, true, true] },
                          { text: skill.value, style: 'fieldValue', border: [false, false, true, true] },

                        ]
                      }),

                    ]
                  }

                },
                {},

              ] : [
                {
                  text: 'No Skills Information Provided',
                  style: 'fieldValue',
                  colSpan: 2
                },
                {},

              ],
            [
              {
                stack: [
                  {
                    text: 'Additional comments',
                    style: 'fieldName'
                  },
                  {
                    text: this.survey.traditionalEmployment.additionalComments,
                    style: 'fieldValue'
                  },
                ],
                colSpan: 2,
              },
              {},

            ],



          ],

        }
      },
      {
        style: 'table',
        // layout: 'lightHorizontalLines',
        table: {
          headerRows: 1,

          widths: ['*', '*', '*'],
          body: [
            [{
              text: 'SECTION 15: DECLARATION AND SIGNATURE',
              style: 'title',
              colSpan: 3,
              border: [false, false, false, true]
            },
            {},
            {}
            ],
            [
              {
                text: [

                  { text: 'Client Signatute And Declaration ', style: 'subTitle' },

                ],
                colSpan: 3,
                border: [false, false, false, false],
                margin: [0, 5, 0, 5]
              },
              {}, {}
            ],
            [
              {
                text: [

                  { text: 'I ', style: 'fieldName' },
                  { text: this.survey.demographic.firstName + ' ' + this.survey.demographic.lastName, style: 'fieldValue' },
                  { text: ` give consent and authorization of ${this.nationOfUse}, Income Support staff conducting this ${this.survey.applicantDate ? this.survey.applicantDate.toDate().getFullYear() : this.survey.workerDate.toDate().getFullYear()} Income Support Survey with the information I  have provided. I am allowing ${this.nationOfUse} Income Support to use the data collected for the purpose of analyzing the First Nation’s training, education, and employment needs. `, style: 'fieldName' }
                ],
                colSpan: 3,
                border: [false, false, false, true],
                margin: [0, 0, 0, 5]
              },
              {}, {}
            ],
            [
              {
                text: 'Signature',
                style: 'subTitle',
              },
              (this.survey.applicantSignature !== '')
                ? {
                  image: this.survey.applicantSignature,
                  width: 100,
                  colSpan: 2
                } :
                {
                  text: 'Client Has Not Signed Yet',
                  style: 'fieldValue',
                  colSpan: 2
                },
              {}
            ],
            [
              {
                text: 'Date',
                style: 'fieldName',
              },

              (this.survey.applicantDate !== '')
                ? {
                  text: this.survey.applicantDate.toDate().toDateString(),
                  style: 'fieldValue',
                  colSpan: 2
                } : {
                  text: 'Client Has Not Signed Yet',
                  style: 'fieldValue',
                  colSpan: 2
                },
              {}
            ],
            [
              {
                text: [

                  { text: 'Admin Signatute', style: 'subTitle' },

                ],
                colSpan: 3,
                border: [false, false, false, false],
                margin: [0, 5, 0, 5]
              },
              {}, {}
            ],
            [
              {
                text: 'Name',
                style: 'subTitle',
              },

              {
                text: this.survey.workerFirstName + ' ' + this.survey.workerLastName,
                style: 'fieldValue',
                colSpan: 2
              },
              {}
            ],
            [
              {
                text: 'Signature',
                style: 'subTitle',
              },
              (this.survey.workerSignature !== '')
                ? {
                  image: this.survey.workerSignature,
                  width: 100,
                  colSpan: 2
                } :
                {
                  text: 'Admin Has Not Signed Yet',
                  style: 'fieldValue',
                  colSpan: 2
                },
              {}
            ],
            [
              {
                text: 'Date',
                style: 'fieldName',
              },

              (this.survey.workerDate !== '')
                ? {
                  text: this.survey.workerDate.toDate().toDateString(),
                  style: 'fieldValue',
                  colSpan: 2
                } : {
                  text: 'Admin Has Not Signed Yet',
                  style: 'fieldValue',
                  colSpan: 2
                },
              {}
            ],

          ],

        }
      },

    )


    return content
  }

  getStyles() {
    return {
      headerText: {
        fontSize: 8,
      },
      mainTitle: {
        bold: true,
        fontSize: 14,
        alignment: 'center',
      },
      paragraphText: {
        fontSize: 10,
        alignment: 'justify',
      },
      title: {
        bold: true,
        fontSize: 12,
        alignment: 'left',
        margin: [0, 10, 0, 10]
      },
      subTitle: {
        fontSize: 10,
        bold: true,
        alignment: 'left',
        margin: [0, 5, 0, 5]
      },
      fieldName: {
        fontSize: 10,
        alignment: 'left',
        //bold: true,
        margin: [0, 3, 0, 3]
      },
      fieldValue: {
        fontSize: 10,
        alignment: 'left',
        color: 'blue'
      },
      links: {
        fontSize: 10,
        color: 'blue',
        decoration: 'underline',
        //italics: true,
        //alignment: 'right',
      },
      warning: {
        fontSize: 12,
        color: 'red',
        alignment: 'center',
      },
      normalText: {
        fontSize: 10,
        alignment: 'justify',
      },

      header: {
        fontSize: 12,
        bold: true,
        alignment: 'left',
      },

      table: {
        margin: [10, 5, 0, 10]
      },
      fields: {
        color: 'blue',
        fontSize: 10,
      }
    }
  }

  async downloadSurvey() {
    const documentDefinition = await this.getPdf();
    pdfMake.createPdf(documentDefinition).download(this.fileName);
  }

  async openSurvey() {
    const documentDefinition = await this.getPdf();
    pdfMake.createPdf(documentDefinition).open();
  }

  async printSurvey() {
    const documentDefinition = await this.getPdf();
    pdfMake.createPdf(documentDefinition).print();
  }

  async sendSurvey() {
    this.fileList = [];

    const documentDefinition = await this.getPdf();
    const pdfDocGenerator = pdfMake.createPdf(documentDefinition);
    pdfDocGenerator.getBlob((blob) => {
      this.reportFile = new File([blob], this.fileName, {
        type: blob.type
      });
      
      this.mediaService.uploadPDF(this.reportFile).subscribe(upload => {
        if (upload) {
          if (upload.imgLink) this.fileLink = upload.imgLink
          console.log(upload, ' the upload')
          // this.fileLink = "https://api.mysabenefits.ca/Media/Demo/PDF/0de131ee-7909-44cc-bbbc-8e12fb611dd4-Houses.pdf";
          let report = new Attachment();
          report.url = this.fileLink;
          report.name = this.fileName;
          report.type = 'PDF';
          this.fileList.push(report);
          this.sendEmail = true;
        }
      });
    });

  }
  emailSentHandler(valueEmitted) {
    if (valueEmitted) this.emailSent = true;
    this.sendEmail = false;
  }
}