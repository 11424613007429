<div *ngIf="model" class="content-section">


    <div class="fixed-action-btn">
        <a class="btn-floating btn-large" (click)="toggleView()">
            <div class="toggle-button">
                <i *ngIf="isCardView" class="fa fa-th-list"></i>
                <i *ngIf="!isCardView" class="fa fa-th-large"></i>
                <span class="button-text">{{ isCardView ? 'List View' : 'Card View' }}</span>
            </div>
        </a>
    </div>



    <div *ngIf="isCardView">
        <app-card-view-landing-page></app-card-view-landing-page>
    </div>

    <div *ngIf="!isCardView">
        <div *ngFor="let section of model.sections">
            <div class="row" *ngIf="section.isEnabled" [ngStyle]="{'background-color': section.bgColor}">
                <div [ngSwitch]="section.name">

                    <!-- ******************** Slider ************************** -->
                    <div *ngSwitchCase="'Slider'" class="me">
                        <app-landing-page-slider [fullscreenEnabled]="true"></app-landing-page-slider>
                    </div>

                    <!-- ************** Login and Register buttons ***************** -->
                    <div *ngSwitchCase="'Login'">
                        <app-register-login-buttons></app-register-login-buttons>
                    </div>

                    <!-- ******************** About Us ************************** -->
                    <div *ngSwitchCase="'AboutUs'">
                        <app-about-us-section></app-about-us-section>
                    </div>

                    <!-- ******************** News ************************** -->
                    <div *ngSwitchCase="'News'">
                        <app-latest-news></app-latest-news>
                    </div>

                    <!-- ******************** Events ************************** -->
                    <div *ngSwitchCase="'Events'">
                        <app-upcoming-events></app-upcoming-events>
                    </div>

                    <!-- ******************** Download App ************************** -->
                    <div *ngSwitchCase="'AppStore'">
                        <app-app-store></app-app-store>
                    </div>

                    <!-- ******************** Governance ************************** -->
                    <div *ngSwitchCase="'Governance'">
                        <app-governance-list [from]="'landingPage'"></app-governance-list>
                    </div>

                    <!-- ******************** DEPARTMENTS ************************** -->
                    <div *ngSwitchCase="'Departments'">
                        <app-department-list></app-department-list>
                    </div>

                    <!-- ******************** Social Media ************************** -->
                    <!-- ngIf="hasSocial" -->
                    <div *ngSwitchCase="'SocialMedia'">
                        <app-social-media-links></app-social-media-links>
                    </div>
                    <!-- ******************** Footer ************************** -->
                    <!-- <div *ngSwitchCase="'Footer'">
                        <app-landing-page-footer></app-landing-page-footer>
                    </div> -->

                </div>
            </div>
        </div>

        <div *ngIf="customSections && customSections.length > 0">
            <div *ngFor="let sec of customSections">
                <div class="row" [ngStyle]="{'background-color': sec.bgColor}">
                    <app-section-list [id]="sec.id"></app-section-list>
                </div>
            </div>
        </div>
        <div class="row">
            <app-landing-page-footer></app-landing-page-footer>
        </div>
    </div>

</div>


<div *ngIf="!model && !loading" class="content-section container10 row " [ngStyle]="{'background-color': themeColor}">
    <div class="col s12 l5 content-right">
        <h4 class="header-1">NO DATA</h4>
        <h4 class="header-2">Landing Page</h4>
        <h6 class="header-3">
            Please be patient while the administrator adds / activates the landing page setting. <br>
            We apologize for the inconvenience,We expect it to be available shortly. <br>
            Thank you for your understanding and patience.</h6>
        <button class="btn btn-header" [routerLink]="['/auth/login']">Login </button>
    </div>
    <div class="col s12 l7">
        <img src="../../assets/img/aaa.png" class="responsive-img" height="700" width="700">
    </div>
</div>