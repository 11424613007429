import { Component, OnInit } from '@angular/core';
import { StatsService } from 'src/app/services/api/statsService';
import { ChartDataset, ChartOptions, ChartType, TooltipItem } from 'chart.js';
// import { Label, Color } from 'ng2-charts';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { toast } from 'materialize-css';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';

declare var $: any;

@Component({
  selector: 'app-demographics',
  templateUrl: './demographics.component.html',
  styleUrls: ['./demographics.component.css']
})

export class DemographicsComponent implements OnInit {

  themeColor: any;
  defaultThemeColor = environment.appTheme.themeColor;
  birthsModel: any[];
  gendersModel: any[];

  // Linear Chart
  public lineChartData: any;
  public lineChartLabels: any;
  public lineChartOptions: ChartOptions = {
    responsive: true,
    // tooltips: {
    //   callbacks: {
    //     label: function (tooltipItem, data) {
    //       var label = ' Births +';

    //       if (label) {
    //         label += ' ';
    //       }
    //       const currentYear = +data.datasets[0].data[tooltipItem.index];
    //       const previousYear = +data.datasets[0].data[tooltipItem.index - 1];

    //       label += currentYear - previousYear;
    //       return label;
    //     }
    //   }
    // }

    plugins: {
      tooltip: {
        callbacks: {
          label: function (tooltipItem: TooltipItem<'line'>) {
            const data = tooltipItem.dataset.data as number[];
            const currentYear = data[tooltipItem.dataIndex] as number;
            const previousYear = data[tooltipItem.dataIndex - 1] as number;
            return `Births + ${currentYear - previousYear}`;
          }
        }
      },
      legend: {
        position: 'top',
      }
    }
  };
  public lineChartColors = [
    {
      borderColor: 'darkgreen',
      backgroundColor: 'rgb(152,251,152)',
    },
  ];
  public lineChartLegend = true;
  public lineChartType = 'line';
  public lineChartPlugins = [];

  // Pie Chart
  public pieChartOptions: ChartOptions = {
    responsive: true,
    // legend: {
    //   position: 'top',
    // },
    plugins: {
      legend: {
        position: 'top',
      }
    }
  };
  public pieChartLabels: any;
  public pieChartData: any[];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartColors = [
    {
      backgroundColor: ['rgb(0,191,255)', 'rgb(255,105,180)'],
    },
  ];

  sectionName: string;
  submenus: any[];
  editName = false;

  constructor(private statsService: StatsService,
    private location: Location,
    private router: Router,
    public appSettingsService: AppSettingsService) { 
      this.themeColor = this.appSettingsService.getAppThemeColor() ? this.appSettingsService.getAppThemeColor() : this.defaultThemeColor;
    }

  ngOnInit() {
    this.appSettingsService.getMenuName('Stats').valueChanges().subscribe(menus => {
      if (menus && menus.length > 0) {
        this.submenus = menus[0].submenus;
        let menu = this.submenus.find(m => m.name == 'Demographics');
        if (menu) this.sectionName = menu.displayName;
      }
    });
    this.statsService.GetDemographics().subscribe(data => {
      if (data) {
        if (data.births && data.births.length > 0) {
          this.birthsModel = data.births;
          // Linear Chart
          let chartData: ChartDataset[];
          let years: string[] = [];
          let count: any[] = [];
          let total: any;
          total = this.birthsModel[0].initial;
          this.birthsModel.forEach(e => {
            years.push(e.yr);
            total += e.cnt;
            count.push(total);
          });
          // chartData = [
          //   { data: count, label: 'Births By Year' }
          // ];
          this.lineChartData = {
            labels: years,
            datasets: [
              { data: count, label: 'Births By Year' }
            ]
          };
          this.lineChartLabels = years;
          this.lineChartData = chartData;
        }

        if (data.genders && (data.genders[0].Male != 0 || data.genders[0].Female != 0)) {
          this.gendersModel = data.genders;
          // Pie Chart
          let gendersVal: number[] = [];
          // let lbl: Label[] = ['Male ' + ' ' + this.gendersModel[0].Male, 'Female ' + ' ' + this.gendersModel[0].Female]
          let lbl: string[] = ['Male ' + this.gendersModel[0].Male, 'Female ' + this.gendersModel[0].Female];
          gendersVal.push(this.gendersModel[0].Male);
          gendersVal.push(this.gendersModel[0].Female);
          this.pieChartLabels = lbl;
          this.pieChartData = [{ data: gendersVal }];
        }
      }
    });
  }

  editNameClicked() {
    this.editName = true;
  }

  saveName() {
    let index = this.submenus.findIndex(m => m.name == 'Demographics');
    this.submenus[index].displayName = this.sectionName;
    this.appSettingsService.UpdateMenu('Stats', this.submenus).then((update) => {
      this.editName = false;
      toast({ html: `${update}`, classes: 'green' });
    }).catch((error) => {
      toast({ html: `${error}`, classes: 'red' });
    });
  }

  navigate(item) {
    if (item == 'AppStatistics') this.router.navigate(['admin/dashboard-sub-menu/stats/app-statistics']);
    else if (item == 'DepartmentStats') this.router.navigate(['admin/dashboard-sub-menu/stats/dep-statistics']);
    else if (item == 'Demographics') this.router.navigate(['admin/dashboard-sub-menu/stats/demographics']);
    else if (item == 'Charts' || 'Analytics' || 'Reports') {
      $('.modal').modal();
      $('#underConstructionModal').modal('open');
    }
  }

  backClicked() {
    this.location.back();
  }

}
