<angular-editor *ngIf="!type" [formControl]="control" [config]="editorConfig" class="bg-white">
</angular-editor>

<angular-editor *ngIf="type" [placeholder]="'Sample Text or Use Artificial Assistant'" [(ngModel)]="model" (ngModelChange)="onChange($event)"
  [config]="editorConfig" class="bg-white">
</angular-editor>

<div class="white p-m-1 b-m-1 right" *ngIf="showChatGpt">
  <a (click)="openChatGptModal()" class="modal-close white-text btn" [ngStyle]="{ 'background-color': themeColor }">
    Use Aritificail Intelligence</a>
  <i class="material-icons right gray-text darken-3 tooltipped" data-position="bottom" style="padding-top: 0.7vh; cursor: pointer;">info</i>
</div>

<app-chat-gpt *ngIf="openChatGpt" [inputText]="inputText" [theInput]="selectedInput"
  (onMessageSelect)="updateFormValue($event)" (onCloseModal)="closeModalChatGptModal()">
</app-chat-gpt>