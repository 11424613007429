<div class="container-pi">
    <div class="">
        <h4 class="title-section1 title-member icon-texts"> <span class="material-symbols-outlined back-icon p-r-1"
            (click)=" backClicked()">
            arrow_circle_left
        </span>My Interventions </h4>
    </div>

    <main class="StickyContent white row">
        <div class="col s12 m10 l10">
            <app-firebase-loading *ngIf="!dtOptions"></app-firebase-loading>
            <div class="row card-panel white darken-2" *ngIf="dtOptions">
                <div style="overflow-x:auto;">
                    <table datatable [dtOptions]="dtOptions" class="center">
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Description</th>
                                <th>Payable</th>
                                <!-- <th>Cost</th>  -->
                                <th>Approved</th>
                                <!-- <th>Paid</th>  -->
                                <th>Outcome</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr *ngFor="let m of interventions">
                                <td>{{ m.ID }} </td>
                                <!-- <td> <a [routerLink]="['/member/isets/intervention-detail/',m.ID]">{{ m.Description}}
                                    </a></td>
                                <td> <a [routerLink]="['/member/isets/intervention-detail/',m.ID]">{{ m.payable_to}}
                                    </a></td> -->
                                <td> <a [routerLink]="['/member/member-main-home/intervention-detail/',m.ID]">{{
                                        m.Description}}
                                    </a></td>
                                <td> <a [routerLink]="['/member/member-main-home/intervention-detail/',m.ID]">{{
                                        m.payable_to}}
                                    </a></td>
                                <!--  <td>{{ m.Cost | currency}} </td>  -->
                                <td>
                                    <div *ngIf="m.approved"> <span class="green-text">
                                            <i class="material-icons">check_circle_outline</i> Yes </span> </div>
                                    <div *ngIf="!m.approved"> <span class="red-text text-darken-1">
                                            <i class="material-icons">highlight_off</i> No </span> </div>
                                </td>
                                <!--  <td>
                                <div *ngIf="m.paid"> <span class="green-text">
                                    <i class="material-icons">check_circle_outline</i> Yes </span> </div>
                                <div *ngIf="!m.paid"> <span class="red-text text-darken-1">
                                    <i class="material-icons">highlight_off</i>  No </span> </div>
                            </td> -->
                                <td>
                                    <div *ngIf="m.outcome === 'Complete'"> <span class="green-text"> {{ m.outcome}}
                                        </span> </div>
                                    <div *ngIf="m.outcome === 'Incomplete'"> <span class="blue-text"> {{ m.outcome}}
                                        </span> </div>
                                    <div *ngIf="m.outcome === 'In progress'"> <span class="red-text text-darken-1"> {{
                                            m.outcome}} </span> </div>
                                    <div *ngIf="m.outcome === 'Failed to report'"> <span
                                            class="black-text text-darken-1"> {{ m.outcome}} </span> </div>
                                    <div *ngIf="m.outcome === 'Cancelled'"> <span class="brown-text text-darken-1"> {{
                                            m.outcome}} </span> </div>
                                    <div *ngIf="m.outcome === 'Rescheduled'"> <span class="teal-text text-darken-1"> {{
                                            m.outcome}} </span> </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </main>

    <div class="row col s12">
        <div class="col s12 m10 l10">
            <a [ngClass]="'col s12 btn waves-effect ' + themeColor" (click)="backClicked()"><i
                    class="material-icons left">house</i> Back To My Services</a>
        </div>
    </div>
</div>