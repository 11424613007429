<div class="row center-align">
  <h5><strong>Department Employees</strong></h5>
</div>

<div class="row col s12">
  <div class="col l1 "></div>
  <div class="col s12 l10">

    <div class="row">
      <div class="col s6 m4 l3">
        <a [routerLink]="['/employee/home']">
          <div class="card orange darken-4 hoverable " style="height: 95px;">
            <div class="card-content">
              <div class="row center">
                <i class="material-icons white-text">house</i><br />
                <strong class="white-text">Back</strong>
              </div>
            </div>
          </div>
        </a>
      </div>

      <div class="col s6 m4 l3" *ngIf="isManager || canManageEmployees">
        <a [routerLink]="[]" (click)="addDepartmentMember($event);">
          <div class="card green hoverable " style="height: 95px;">
            <div class="card-content">
              <div class="row center">
                <i class="material-icons white-text">add_circle</i><br />
                <strong class="white-text">Invite Employee</strong>
              </div>
            </div>
          </div>
        </a>
      </div>

      <div class="col s6 m4 l3" *ngFor="let departmentMember of departmentMembers" [@swing]="swing">
        <a [routerLink]="[]" (click)="editDepartmentMember(departmentMember.uid, $event);">
          <div class="card-panel white darken-3 hoverable" style="height: 95px;">
            <div class="row center">
              <u> <strong class="blue-text text-darken-3" style="margin-top: 12px; font-size: 15px;"> {{
                  departmentMember.givenName }} {{ departmentMember.lastName }} </strong></u>
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
  
  <div class="col l1 "></div>
</div>