import { Component, OnInit, Input, Renderer2, ElementRef, HostListener, ChangeDetectorRef } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { Observable } from 'rxjs';
import { MemberModel } from 'src/app/models/memberModel';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { Router } from '@angular/router';
import { Location } from "@angular/common";
import { SocialMediaService } from 'src/app/services/firebase/social-media.service';
import { NationSettingsService } from 'src/app/services/api/nation-settings.service';
import { FbNationSettingsService } from 'src/app/services/firebase/fb-nation-settings.service';
import { UntypedFormGroup } from '@angular/forms';
import { DataService } from 'src/app/services/data.service';
import { SectionSettingService } from 'src/app/services/firebase/section-setting.service';

declare var $: any;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})

export class HeaderComponent implements OnInit {

  nationOfuse = environment.firstNation.name;
  nationName = environment.firstNation.displayName;
  defaultThemeColor = '#000000';
  setting: any;

  @Input() nationLogo = '';
  @Input() navbarOption;
  @Input() themeColor;
  @Input() mobileView: any;
  @Input() tabletView: any;
  @Input() desktopView: any;
  //to be deleted
  @Input() rightOrLeftSidebar = '';

  fireMember: Observable<MemberModel>;
  isLogged = false;
  role = '';
  defaultlogoType = 'logoOnly';

  publicMenus: any[];

  //before logged in
  menus: any[];
  favoriteMenu: any;
  showMenus = false;
  logoType: any;
  // appSettings: FormGroup;
  socialMedia = {
    facebook: '',
    twitter: '',
    instagram: '',
    youtube: '',
    tiktok:'',
    linkedin: ''
  };
  customCol: any;
  appSettings: UntypedFormGroup;
  //After logged in - Admin
  appStoreSection: any;
  isSuperAdmin = false;
  isChiefOrCoucillor = false;
  adminMenus: any;
  permissions: any;

  pageName: any;
  model: any;
  landingModel: any;
  submenu: any;
  showSubMenus = false;

  hasISET = environment.firstNation.ISET;
  hasSocial = environment.firstNation.SOCIAL;

  logo: any;
  logoOption: any;
  isLargeScreen: boolean;

  customeSection: any[];

  constructor(private authService: AuthService,
    private router: Router,
    private location: Location,
    private appSettingService: AppSettingsService,
    private settingService: AppSettingsService,
    private nationSettingsService: NationSettingsService,
    private nationSettingServicee: FbNationSettingsService,
    private socialService: SocialMediaService,
    private dataService: DataService,
    public appSettingsService: AppSettingsService,
    private sectionService: SectionSettingService,
    private cdr: ChangeDetectorRef) {

    this.publicMenus = this.dataService.getPublicMenus().sections;

    this.appSettingsService.getAppSettingsList().valueChanges().subscribe(
      appSettings => {
        if (appSettings) {
          if (appSettings.length > 0) {
            this.model = appSettings[0];
            this.logoType = this.appSettingsService.getLogoType() ? this.appSettingsService.getLogoType() : 'logoOnly';
          }
        }
      }
    );

    this.dataService.getAppName().subscribe(appName => {
      if (appName) this.nationName = appName;
      else {
        this.nationSettingsService.getNationSettings().subscribe(sett => {
          if (sett) {
            let settingsModel = sett[0];
            if (settingsModel.band_name) this.nationName = settingsModel.band_name;
          }
        });
      }
    });

    this.nationSettingServicee.getsettings().valueChanges().subscribe(setting => {
      if (setting && setting.length > 0) {
        if (setting[0].logo) this.logo = setting[0].logo;
      }
    });

    this.checkScreenSize();
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: Event): void {
    this.cdr.detectChanges();
  }

  ngOnInit() {
    this.appSettingService.getAppSetting().valueChanges().subscribe(
      appSettings => {
        if (appSettings && appSettings.length > 0) {
          this.setting = appSettings[0];
          this.logoOption = this.setting.logoOption ? this.setting.logoOption : this.defaultlogoType;
        } else {
          this.logoOption = this.defaultlogoType;
        }
      });

    this.authService.afAuth.authState.subscribe(auth => {
      if (auth) {
        this.fireMember = this.authService.getCurrentApplicantIDObservable();
        this.isLogged = true;
        this.fireMember.subscribe(data => {
          let userData: any = data;
          this.role = data.role;
          if (this.role == 'Member') if (userData.isChief || userData.isCoucillor) this.isChiefOrCoucillor = true;
          if (this.role == 'Admin') {
            if (userData.isSuperAdmin) this.isSuperAdmin = true;
            if (userData.permissions) this.permissions = userData.permissions;

            this.settingService.getAdminMenus().valueChanges().subscribe(adminMenus => {
              if (adminMenus && adminMenus.length > 0) {
                this.adminMenus = adminMenus;
              }
            })
          }
        });
      } else {
        this.role = '';
        this.isLogged = false;
      }
    });

    if (this.router.url === '') { }
    let mn: any;
    this.settingService.getHomePageSettings().valueChanges().subscribe(
      menus => {
        if (menus && menus.length > 0) {
          mn = menus[0];
          this.menus = mn.sections;
        } else {
          this.menus = this.publicMenus;
        }

        this.sectionService.getSections().valueChanges().subscribe(sec => {
          if (sec && sec.length > 0) {
            let sections = sec.filter(s => s.addToHomePage);
            if (sections && sections.length > 0) {
              this.customeSection = [];
              sections.forEach(sec => {
                this.customeSection.push(
                  {
                    id: sec.id,
                    addToHeader: sec.addToMenu,
                    displayName: sec.displayName,
                    bgColor: sec.bgColor,
                    displayNameColor: sec.displayNameColor,
                    icon: sec.icon,
                    isEnabled: true,
                    index: this.menus.length,
                    name: sec.name,
                    link: '/custome-section/' + sec.id,
                    logoUrl: sec.logoUrl
                  }
                )
              });

              this.customeSection = this.customeSection.filter(obj => obj.addToHeader);
              this.customeSection.forEach(sec => {
                this.menus.push(sec);
              })
              // this.model.sections = this.model.sections.sort((a, b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0));
            }
          }
        });


        this.menus = this.menus.filter(obj => obj.addToHeader);

        if (this.menus.find(m => m.name == 'AppStore')) {
          this.appStoreSection = this.menus.find(m => m.name == 'AppStore');
        }
        this.menus = this.menus.sort((a, b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0));
        let defaultFavorite = this.menus.find(m => m.name == 'Login');
        this.favoriteMenu = defaultFavorite;
        if (this.favoriteMenu) this.menus.splice(this.menus.indexOf(this.favoriteMenu), 1);

        if (!this.mobileView) {
          this.mobileView = {
            menuPosition: 'bottom',
            numberOfMenus: 5
          };
          this.customCol = 100 / (this.mobileView.numberOfMenus) + '%';
        } else {
          this.customCol = 100 / (this.mobileView.numberOfMenus) + '%';
        }
        if (!this.tabletView) {
          this.tabletView = { menuPosition: 'right' }
        }
        if (!this.desktopView) {
          this.desktopView = {
            menuPosition: 'right',
            numberOfMenus: 5
          }
          this.customCol = 100 / (this.desktopView.numberOfMenus + 2) + '%';
        } else {
          this.customCol = 100 / (this.desktopView.numberOfMenus + 2) + '%';
        }
        setTimeout(() => {
          $('.dropdown-trigger').dropdown({
            constrainWidth: false,
            isScrollable: true,
          });
          $('.tooltipped').tooltip();
        }, 25);
      }
    );

    this.socialService.getSocialMedia().valueChanges().subscribe(x => {
      if (x) {
        if (x.length > 0) {
          this.socialMedia = {
            facebook: x[0].facebook,
            twitter: x[0].twitter,
            instagram: x[0].instagram,
            youtube: x[0].youtube,
            linkedin: x[0].linkedin,
            tiktok: x[0].tiktok
          };
        }
      }
    });
  }

  checkScreenSize() {
    this.isLargeScreen = window.innerWidth >= 1024; // Adjust the threshold as needed
    window.addEventListener('resize', () => {
      this.isLargeScreen = window.innerWidth >= 1024; // Adjust the threshold as needed
    });
  }

  navigate(url: any, item?) {
    if (item) this.appSettingService.setCurrentMenu(item.displayName);
    if (this.role == "Admin" && item) {
      if (!this.getPermission(item)) {
        $('.modal').modal();
        $('#permissionModal').modal('open');
        return;
      }
    }

    if (item && item.name == "Demo") {
      this.memberAccountClick();
      return;
    }
    else if (item && item.name == "SocialMedia") {
      this.socialClicked();
      return
    }
    else if (item && item.name == "AppStore") {
      this.appStoreClicked();
      return
    }
    if (url) {
      this.router.navigate([url]);
    } else { this.router.navigate(['/']); }
  }

  navigateToHome() {
    this.router.navigate(['/']);
  }

  openSideNav() {
    $('.sidenav').sidenav();
    $('#public-mobile-navbar').sidenav('open');
  }

  showSubMenu(staticName) {
    this.submenu = staticName;
    this.showSubMenus = true;
  }

  showTooltip(item) {
    if (this.tabletView.menuPosition == 'right') {
      $('.tooltipped').tooltip({
        position: 'left',
        isHovered: true
      });
    } else {
      $('.tooltipped').tooltip({
        position: 'right',
        isHovered: true
      });
    }
  }

  signOut() {
    this.authService.signOut();
  }

  chiefClicked() {
    localStorage.setItem('selectedOption', 'Chief');
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['/governance']));
  }

  socialClicked() {
    $('.modal').modal();
    $('#socialModal').modal('open');
  }

  appStoreClicked() {
    $('.modal').modal();
    $('#appstoreModal').modal('open');
  }

  councillorsClicked() {
    localStorage.setItem('selectedOption', 'Councillors');
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['/governance']));
  }

  backClicked() {
    this.location.back();
  }

  backAdminClicked() {
    this.router.navigate(['/admin/dashboard']);
  }
  backPublicClicked() {
    this.router.navigate(['/home']);
  }

  forwardClicked() {
    this.location.forward();
  }

  incomingAppsClick() {
    localStorage.setItem('From', 'IncomingApplications');
    this.router.navigate(['admin/dashboard-sub-menu']);
  }

  createAccountClick() {
    $('#infoModal').modal('close');
    $('#DemoModal').modal('open');
  }

  memberAccountClick() {
    $('#DemoModal').modal('close');
    localStorage.setItem('AccountType', 'Member');
    this.router.navigate(['/auth/request-demo']);
  }

  adminAccountClick() {
    $('#DemoModal').modal('close');
    localStorage.setItem('AccountType', 'Admin');
    this.router.navigate(['/auth/request-demo']);
  }

  getMenuSetting(item) {
    if (this.adminMenus) {
      let menu = this.adminMenus.find(m => m.name == item);
      if (menu) {
        if (menu.isEnabled) return true;
      }
      return false;
    }
    return true;
  }

  getPermission(menu) {
    if (menu == "Logout") return true;
    if (this.isSuperAdmin) return true;
    if (this.permissions) {
      if (this.permissions.length > 0) {
        let p = this.permissions.find(p => p.menu == menu);
        if (p) return true;
      }
    }
    return false;
  }

}