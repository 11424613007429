import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { GovernanceService } from 'src/app/services/firebase/governance.service';

@Component({
  selector: 'app-boards-detail',
  templateUrl: './boards-detail.component.html',
  styleUrls: ['./boards-detail.component.css']
})
export class BoardsDetailComponent implements OnInit {

  id: any;
  model: any;
  constructor(private govservice: GovernanceService, private route: ActivatedRoute) { 
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });
  }

  ngOnInit() {
    if(this.id) {
      this.govservice.getBoardById(this.id).valueChanges().subscribe(b => {
        if(b) {
          this.model = b;
        }
      })
    }
  }

}
