import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { Observable } from 'rxjs';
import { MemberModel } from 'src/app/models/memberModel';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { Router } from '@angular/router';
import { Location } from "@angular/common";
import { SocialMediaService } from 'src/app/services/firebase/social-media.service';
import { FbNationSettingsService } from 'src/app/services/firebase/fb-nation-settings.service';
import { isThisSecond } from 'date-fns';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-more',
  templateUrl: './more.component.html',
  styleUrls: ['./more.component.css']
})

export class MoreComponent implements OnInit {

  defaultThemeColor = environment.appTheme.themeColor;
  nationOfuse = environment.firstNation.name;
  nationName = environment.firstNation.displayName;
  themeColor: any;
  isLogged = false;
  role = '';

  logo: any;

  fireMember: Observable<MemberModel>;
  pageName: any;
  model: any;
  landingModel: any;
  publicMenus: any[];
  menu: any[];
  menus: any[];
  
  login: any;
  follow: any;
  privacy: any;
  news: any;
  events: any;
  aboutUs: any;
  departments: any;
  governace: any;
  contact: any;
  demo: any;
  socialMedia = {
    facebook: '',
    twitter: '',
    instagram: '',
    youtube: ''
  };


  constructor(private authService: AuthService,
    private router: Router, private location: Location,
    private settingService: AppSettingsService,
    private appSettingService: AppSettingsService,
    private socialService: SocialMediaService,
    private nationSettingServicee: FbNationSettingsService,
    private dataService: DataService) {

    this.publicMenus = this.dataService.getPublicMenus().sections;
    this.appSettingService.getAppSetting().valueChanges().subscribe(
      appSettings => {
        if (appSettings) {
          if (appSettings.length > 0) {
            this.model = appSettings[0];
            this.themeColor = this.model.themeColor ? this.model.themeColor : this.defaultThemeColor;
          }
        }
      });
    this.router.events.subscribe(val => {
      if (location.path() === '/home') {
        this.pageName = environment.firstNation.displayName;
      }
      if (location.path() === '') {
        this.pageName = environment.firstNation.displayName;
      }
      if (location.path() === '/news') {
        this.pageName = environment.firstNation.displayName + ' News';
      }
      if (location.path() === '/events-calendar') {
        this.pageName = 'Events Calendar';
      }
      if (location.path() === '/auth/login') {
        this.pageName = 'Login';
      }
      if (location.path() === '/contact-nation') {
        this.pageName = 'Contact Us';
      }
      if (location.path() === '/more-sidenav') {
        this.pageName = 'More';
      }
      if (location.path() === '/home-sub-menu') {
        if (localStorage.getItem('FromPath') === 'SocialNetwork') { this.pageName = 'Social Network'; }
        if (localStorage.getItem('FromPath') === 'TermsAndPolicy') { this.pageName = 'Terms And Policy'; }
      }
      if (location.path() === '/about-us') {
        this.pageName = 'About Us';
      }
      if (location.path() === '/departments') {
        if (this.nationOfuse === 'Batc' || this.nationOfuse === 'Pbcn') {
          this.pageName = 'Offices';
        } else {
          this.pageName = 'Departments';
        }
      }
      if (location.path().includes('/view-about-us-section')) {
        this.pageName = 'About Us';
      }
      if (location.path().includes('/auth/register/')) {
        this.pageName = 'Members Sign Up';
      }
      if (location.path().includes('auth/admin-registration')) {
        this.pageName = 'Admin Sign Up';
      }
      if (location.path() === '/registration-request') {
        this.pageName = 'Register / Sign Up';
      }
      if (location.path() === '/governance') {
        this.pageName = 'Governance';
      }
    });
  }

  ngOnInit(): void {
    if (this.router.url === '') { }

    this.settingService.getHomePageSettings().valueChanges().subscribe(menus => {
      if (menus && menus.length > 0) {
        let mn: any = menus[0];
        this.menus = mn.sections;
        this.setMenus();
      } else {
        this.menus = this.publicMenus;
        this.setMenus();
      }
    });

    this.socialService.getSocialMedia().valueChanges().subscribe(x => {
      if (x) {
        if (x.length > 0) {
          this.socialMedia = {
            facebook: x[0].facebook,
            twitter: x[0].twitter,
            instagram: x[0].instagram,
            youtube: x[0].youtube
          };
        }
      }
    });

    this.authService.afAuth.authState.subscribe(auth => {
      if (auth) {
        this.fireMember = this.authService.getCurrentApplicantIDObservable();
        this.isLogged = true;
        this.fireMember.subscribe(data => {
          this.role = data.role;
        });
      } else {
        this.role = '';
        this.isLogged = false;
      }
    });

    this.nationSettingServicee.getsettings().valueChanges().subscribe(setting => {
      if (setting && setting.length > 0) {
        if (setting[0].logo) this.logo = setting[0].logo;
      }
    });
  }

  setMenus() {
    this.login = this.menus.find(menu => menu.name == "Login");

    this.governace = this.menus.find(menu => menu.name == "Governance");
  
    this.follow = this.menus.find(menu => menu.name == "SocialMedia");

    this.news = this.menus.find(menu => menu.name == "News");
    this.events = this.menus.find(menu => menu.name == "Events");
    this.aboutUs = this.menus.find(menu => menu.name == "AboutUs");
    this.departments = this.menus.find(menu => menu.name == "Departments");
    this.contact = this.menus.find(menu => menu.name == "ContactUs");
    this.demo = this.menus.find(menu => menu.name == "Demo");

    this.privacy = true;
  }

  navigate(url: any) {
    if (url) {
      this.router.navigate([url]);
    } else { this.router.navigate(['/']); }
  }

  signOut() {
    this.authService.signOut();
  }

  chiefClicked() {
    localStorage.setItem('selectedOption', 'Chief');
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['/governance']));
  }

  councillorsClicked() {
    localStorage.setItem('selectedOption', 'Councillors');
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() =>
      this.router.navigate(['/governance']));
  }

  memberAccountClick() {
    localStorage.setItem('AccountType', 'Member');
    this.router.navigate(['/auth/request-demo']);
  }

  adminAccountClick() {
    localStorage.setItem('AccountType', 'Admin');
    this.router.navigate(['/auth/request-demo']);
  }

  backClicked() {
    this.location.back();
  }

  forwardClicked() {
    this.location.forward();
  }

  incomingAppsClick() {
    localStorage.setItem('From', 'IncomingApplications');
    this.router.navigate(['admin/dashboard-sub-menu']);
  }

}
