import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-monthly-assistance-main',
  templateUrl: './monthly-assistance-main.component.html',
  styleUrls: ['./monthly-assistance-main.component.css']
})
export class MonthlyAssistanceMainComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
