<div class="row center-align p-m-2">
    <h6> <strong> {{ wall_post_name }} </strong></h6>
</div>

<div class="row col s12">
    <div class="col l1"></div>
    <div id="step0" class="col s12 l10">
        <form (ngSubmit)="submitForm(addWallPostCommentForm.value)"  [formGroup]="addWallPostCommentForm">
            <div class="row card-panel">
                <div class="chat-messages-parent">
                    <div class="chat-messages-child">
                        <div class="message" *ngFor="let wal_post_comment of wal_post_comments" [ngClass]="{'mine': wal_post_comment.member_id == logged_in_user_id}"><strong *ngIf="wal_post_comment.member_id != logged_in_user_id">{{ wal_post_comment.member_name}}: </strong> <span [innerHTML]="wal_post_comment.comment"></span></div>
                    </div>
                </div>
                <div class="input-field class col s12">
                    <i class="material-icons prefix blue-text">work</i>
                    <!-- <textarea [froalaEditor] formControlName="comment"></textarea> -->
                    <!-- <input formControlName="comment" id="comment" type="text" maxlength="75" autocomplete="off" required class="validate" [froalaEditor]> -->
                    <div *ngIf="(comment.dirty || comment.touched) && comment.invalid">
                        <small *ngIf="comment.errors.required" class="red-text">Comment Is Required!
                        </small>
                    </div>
                </div>
            </div>
            <div class="section"></div>
            <div class="col s12">
                <button class='at-btn col s12 btn waves-effect success' style="margin-bottom: 15px;" type="submit" [disabled]="!addWallPostCommentForm.valid">
                <i class="material-icons right">add_circle</i> Submit
                </button>
                <button type="button" *ngIf="able_to_delete_wall_post" (click)="DeleteWallPost()" class = "col s12 btn waves-effect red" style="margin-bottom: 15px;">Remove Wall Post</button>
                <a [ngClass]="'col s12 btn waves-effect ' + themeColor " [routerLink]="['/member/nation-wall']">Back to Wall Post List</a>
            </div>
        </form>
    </div>
    <div class="col l1 "></div>
</div>