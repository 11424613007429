import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { Location } from '@angular/common';
import { HighlightService } from 'src/app/services/api/highlight.service';
import { ObjectiveService } from 'src/app/services/api/objective.service';

@Component({
  selector: 'app-highlights',
  templateUrl: './highlights.component.html',
  styleUrls: ['./highlights.component.css']
})

export class HighlightsComponent implements OnInit {

  constructor(private location: Location, 
    private authService: AuthService, 
    private highlightService: HighlightService, 
    private objectiveService: ObjectiveService) {
    localStorage.setItem('from', 'myresume');
  }

  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  clientID = 0;
  model: any[];
  hasRows = false;
  resumemodel: any[];
  resume_id = '';

  ngOnInit() {
    if (localStorage.getItem('applicantID')) {
      this.clientID = Number(localStorage.getItem('applicantID'));
    } else {
      this.clientID = this.authService.getCurrentApplicantID();
    }

    this.objectiveService.getObjectiveByMemberId(this.clientID).subscribe(x => {
      if (x) {
        this.resumemodel = x;
        if (this.resumemodel.length > 0) { 
          this.resume_id = this.resumemodel[0].ID; 
          localStorage.setItem('resume_id', this.resume_id)
          this.highlightService.getHighlightByResumeId(Number(this.resume_id)).subscribe(x => {
            if (x) {
              this.model = x;
              if (this.model.length > 0) { this.hasRows = true; }
            }
          });
        }
      }
    });
  }

  backClicked() {
    this.location.back();
  }

}
