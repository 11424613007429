<app-firebase-loading *ngIf="loading"></app-firebase-loading>

<div class="row container-pi" *ngIf="!loading && !showDetails">
    <h4 class="title-section1 title-member">IA SURVEY APPLICATIONS</h4>
    
    <div class="row">
        <a (click)="newSurvey()"
            [ngClass]="'col s12 m5 offset-m2 l3 btn right waves-effect ' + themeColor" type="button"
            style="margin-bottom: 15px;"><i class="material-icons right">list_alt</i>Take A New Survey</a>
    </div>

    <div class="row col s12" *ngIf="applications ">
        <div class="col l1"></div>
        <div class="col s12 m12 l12">
            <div class="row card-panel white" *ngIf='applications && applications.length > 0'>
                <div class="row center-align">
                    <h5><strong> IA SURVEY Applications</strong></h5>
                    <p>Total: {{count}}</p>
                </div>
                <table class="hover striped responsive-table">
                    <thead>
                        <tr>
                            <th>Applicant ID</th>
                            <th>Given Name</th>
                            <th>Last Name</th>
                            <th>Application Submitted On</th>
                            <th>Approved By</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let app of applications; let i=index" style="cursor: pointer;">
                            <td class="blue-text" (click)="onShowDetails(i)">{{app.applicantID}}</td>
                            <td class="blue-text" (click)="onShowDetails(i)">{{app.demographic.firstName}}</td>
                            <td class="blue-text" (click)="onShowDetails(i)">{{app.demographic.lastName}}</td>
                            <td class="blue-text" (click)="onShowDetails(i)" *ngIf="app.applicantSignature !==''">{{
                                app.applicantDate.toDate().toDateString() | date: 'dd/MMM/yyyy'}}</td>
                            <td class="blue-text" (click)="memberSign(i)" *ngIf="app.applicantSignature ==''">
                                <a class="waves-effect waves-light btn">Sign Survey</a>
                            </td>
                            <td class="blue-text" (click)="onShowDetails(i)" *ngIf="app.verified">
                                {{app.workerFirstName}} {{app.workerLastName}}</td>
                            <td *ngIf="!app.verified">Not Approved yet</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="col l1"></div>

        <div id="modal1" class="modal">
            <div class="modal-content">
                <h5>Sign Application</h5>
                <div *ngIf="surveyToSign" class="col s12 row left-align card-content" style="padding-top: 3%;">
                    <div class="col s12 m6 l4">
                        <b class="black-text"> First Name: </b>
                        <strong> {{surveyToSign.demographic.firstName}} </strong>
                    </div>
                    <div class="col s12 m6 l4">
                        <b class="black-text"> Last Name: </b>
                        <strong> {{surveyToSign.demographic.lastName}} </strong>
                    </div>
                </div>

                <div *ngIf="surveyToSign && surveyToSign.applicantSignature === ''">
                    <div class="row">
                        <div class="section"></div>
                    </div>
                    <div *ngIf="memberSignature">
                        <div class="row">
                            <div [ngClass]="'card ' + themeColor">
                                <div class="row card-panel grey lighten-4 left-align">
                                    <div class="row">
                                        <h5 class=" blue-text text-darken-2">{{surveyToSign.demographic.firstName}}
                                            please sign here</h5>
                                        <div class="section"></div>
                                        <canvas></canvas>
                                    </div>
                                </div>
                            </div>
                            <a class="at-btn btn-flat center red lighten-1 col s12 white-text"
                                (click)="clearSignatureOfApplicant()"><i class="material-icons left">loop</i> Clear
                                Signature</a>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col s12 left-align">
                <button type="submit" class="waves-effect waves-light green darken-3 btn-small col s12"
                    style="margin-top: 15px; margin-bottom: 25px;" (click)="signSurvey()" [disabled]="emptySignPad()">
                    <i class="material-icons right">person_add</i><span>Sign Survey</span> </button>

                <a [ngClass]="'col s12 btn waves-effect ' + themeColor" (click)="cancel()"
                    style="margin-bottom: 25px;"><i class="material-icons left">arrow_back_ios</i> Back To Previous
                    Page</a>
            </div>
        </div>
    </div>

    <div *ngIf="!applications || applications.length === 0" class="row  card-panel red lighten-1">
        <h6 class="white-text"> <strong>
                No Survey Found In The DataBase.</strong>
        </h6>
    </div>
</div>

<div *ngIf="showDetails">
    <app-lrrcn-applications-detail (onBackToList)="backToList($event)" [surveyId]="surveyId">
    </app-lrrcn-applications-detail>
</div>