<app-firebase-loading *ngIf="loading"></app-firebase-loading>

<div class="row col s12" *ngIf="!showDetails" style="height: fit-content; overflow: hidden">
    <div class="row center-align">
        <h5><strong> INCOMING APPLICATIONS</strong></h5>
    </div>
    <div class="row col s12">
        <div class="col l1"></div>
        <div class="col s12 m12 l10">

            <div class="row card-panel white col s12" *ngIf="(!applications && !loading) || (applications && count == 0)">
                <p class="center"> <strong class="red-text"> <i class="material-icons">warning</i> 0 Income Assistance
                        Applications In the Database</strong> </p>
            </div>

            <div class="row card-panel white" *ngIf="applications && count > 0">
                <div class="row center-align">
                    <h5><strong>Income Assistance</strong></h5>
                    <p>Total: {{count}}</p>
                </div>
                <table class="hover striped responsive-table">
                    <thead>
                        <tr>
                            <th>Given Name</th>
                            <th>last Name</th>
                            <th>Application Submitted On</th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr *ngFor="let app of applications | paginate: { itemsPerPage: 20, currentPage: p }; let i=index"
                            style="cursor: pointer;" (click)="onShowDetails(i)">

                            <td class="blue-text">{{app.firstName}}</td>
                            <td class="blue-text">{{app.lastName}}</td>
                            <td class="blue-text">{{app.apply_date | date: 'dd/MMM/yyyy'}}</td>
                        </tr>
                    </tbody>
                </table>

                <div class="row center" *ngIf="count > 0">
                    <pagination-controls (pageChange)="p = $event"></pagination-controls>
                </div>

            </div>
        </div>
        <div class=" row col s12">

            <button [ngClass]="'col s12 btn waves-effect ' + themeColor" (click)="backClicked()" type="button"
                style="margin-top: 25px;"><i class="material-icons left">arrow_back_ios</i> Back To Applications
                Page</button>
        </div>
        <div class="col l1"></div>
    </div>
</div>

<div class="row col s12" *ngIf="showDetails">

    <ul class="collection with-header">
        <li class="collection-header center-align">
            <h5>Section 1: APPLICANT INFORMATION</h5>
        </li>
        <li class="collection-item">
            <div>First Name<a class="secondary-content">{{detail.firstName}}</a></div>
        </li>
        <li class="collection-item">
            <div>Last Name<a class="secondary-content">{{detail.lastName}}</a></div>
        </li>
        <li class="collection-item">
            <div>Date of Birth<a class="secondary-content">{{detail.dob? (detail.dob | date: 'dd/MMM/yyyy') : ""}}</a>
            </div>
        </li>
        <li class="collection-item">
            <div>Indian Status Number<a class="secondary-content">{{detail.isn}}</a></div>
        </li>
        <li class="collection-item">
            <div>Marital Status<a class="secondary-content">{{detail.maritalStatus}}</a></div>
        </li>
        <li class="collection-item">
            <div>Province of membership<a class="secondary-content">{{detail.province}}</a></div>
        </li>
        <li class="collection-item">
            <div>Band Name<a class="secondary-content">{{detail.bandName}}</a></div>
        </li>
        <li class="collection-item">
            <div>Address<a class="secondary-content">{{detail.address}}</a></div>
        </li>
        <li class="collection-item">
            <div>Postal Code<a class="secondary-content">{{detail.postalCode}}</a></div>
        </li>
        <li class="collection-item">
            <div>Health Care Number<a class="secondary-content">{{detail.healthCareNo}}</a></div>
        </li>
        <li class="collection-item">
            <div>SIN<a class="secondary-content">{{detail.SIN}}</a></div>
        </li>
    </ul>

    <ul class="collection with-header"
        *ngIf="detail.maritalStatus == 'Married' || detail.maritalStatus == 'Common Law'">
        <li class="collection-header center-align">
            <h5>Section 1: SPOUSE INFORMATION</h5>
        </li>
        <li class="collection-item">
            <div>First Name<a class="secondary-content">{{detail.spouseFirstName}}</a></div>
        </li>
        <li class="collection-item">
            <div>Last Name<a class="secondary-content">{{detail.spouseLastName}}</a></div>
        </li>
        <li class="collection-item">
            <div>Date of Birth<a class="secondary-content">{{detail.spouseDob? (detail.spouseDob | date) :
                    'dd/MMM/yyyy'}}</a></div>
        </li>
        <li class="collection-item">
            <div>Indian Status Number<a class="secondary-content">{{detail.spouseIsn}}</a></div>
        </li>
        <li class="collection-item">
            <div>Province of membership<a class="secondary-content">{{detail.spouseProvince}}</a></div>
        </li>
        <li class="collection-item">
            <div>Health Care Number<a class="secondary-content">{{detail.spouseHealthCareNo}}</a></div>
        </li>
        <li class="collection-item">
            <div>SIN<a class="secondary-content">{{detail.spouseSIN}}</a></div>
        </li>
    </ul>

    <div class="row card-panel white" *ngIf="family_members.length == 0">
        <h5 class="center-align">Section 2: FAMILY MEMBERS</h5>
        <h6> No Family Members Added.</h6>
    </div>

    <div class="row card-panel white" *ngIf="family_members && family_members.length > 0">
        <h5 class="center-align">Section 2: FAMILY MEMBERS</h5>
        <table class="hover striped responsive-table card-content">
            <thead>
                <tr>
                    <th>First Name</th>
                    <th>last Name</th>
                    <th>Date of Birth</th>
                    <th>Education Level</th>
                    <th>Relationship </th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let child of family_members; let i=index" style="cursor: pointer;">
                    <td>{{child.family_members}}</td>
                    <td>{{child.last_name}}</td>
                    <td>{{child.DOB ? (child.DOB | date: 'dd/MMM/yyyy') : ""}}</td>
                    <td>{{child.edu_level != -1 ?child.edu_level: ""}}</td>
                    <td>{{child.Relationship}}</td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="row card-panel white" *ngIf="detail.otherFamilyMembers && detail.otherFamilyMembers.length > 0">
        <h5 class="center-align">Section 2: OTHER FAMILY MEMBER INFORMATION</h5>
        <table class="hover striped responsive-table card-content">
            <thead>
                <tr>
                    <th>First Name</th>
                    <th>Last Name</th>
                    <th>Date of Birth</th>
                    <th>Relationship</th>
                    <th>Contribution</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let other of otherPersons; let i=index" style="cursor: pointer;">
                    <td>{{other.given_name}}</td>
                    <td>{{other.last_name}}</td>
                    <td>{{other.dob? (other.dob | date: 'dd/MMM/yyyy') : ""}}</td>
                    <td>{{other.Relationship }}</td>
                    <td>{{other.financial_contribution }}</td>
                </tr>
            </tbody>
        </table>
    </div>

    <ul class="collection with-header" *ngIf="detail.previous_address">
        <li class="collection-header center-align">
            <h5>Section 3: PREVIOUS ADDRESS</h5>
        </li>
        <li class="collection-item">
            <div>Address<a class="secondary-content">{{detail.previous_address}}</a></div>
        </li>
        <li class="collection-item">
            <div>From<a class="secondary-content">{{detail.previous_address_startDate?
                    detail.previous_address_startDate: ''}}</a></div>
        </li>
        <li class="collection-item">
            <div>To<a class="secondary-content">{{detail.previous_address_endDate? detail.previous_address_endDate:
                    ''}}</a></div>
        </li>
    </ul>

    <ul class="collection with-header">
        <li class="collection-header center-align">
            <h5>Section 3: PREVIOUS EMPLOYMENT</h5>
        </li>
        <li class="collection-item">
            <div>Employer Name<a class="secondary-content">{{detail.previous_employer}}</a></div>
        </li>
        <li class="collection-item">
            <div>Address<a class="secondary-content">{{detail.previous_employment_address}}</a></div>
        </li>
        <li class="collection-item">
            <div>Position<a class="secondary-content">{{detail.previous_employment_position}}</a></div>
        </li>
        <li class="collection-item">
            <div>From<a class="secondary-content">{{detail.previous_employment_startDate}}</a></div>
        </li>
        <li class="collection-item">
            <div>To<a class="secondary-content">{{detail.previous_employment_endDate}}</a></div>
        </li>
    </ul>
    <ul class="collection with-header">
        <li class="collection-header center-align">
            <h5>Section 3: PRESENT EMPLOYMENT</h5>
        </li>
        <li class="collection-item">
            <div>Employer Name<a class="secondary-content">{{detail.present_employer}}</a></div>
        </li>
        <li class="collection-item">
            <div>Address<a class="secondary-content">{{detail.present_employment_address}}</a></div>
        </li>
        <li class="collection-item">
            <div>Position<a class="secondary-content">{{detail.present_employment_position}}</a></div>
        </li>
        <li class="collection-item">
            <div>From<a class="secondary-content">{{detail.present_employment_startDate}}</a></div>
        </li>
        <li class="collection-item">
            <div>To<a class="secondary-content">{{detail.present_employment_endDate}}</a></div>
        </li>
    </ul>

    <ul class="collection with-header">
        <li class="collection-header center-align">
            <h5>Section 3: SPOUSE PREVIOUS EMPLOYMENT</h5>
        </li>
        <li class="collection-item">
            <div>Employer Name<a class="secondary-content">{{detail.previous_employer_spouse}}</a></div>
        </li>
        <li class="collection-item">
            <div>Address<a class="secondary-content">{{detail.previous_employment_address_spouse}}</a></div>
        </li>
        <li class="collection-item">
            <div>Position<a class="secondary-content">{{detail.previous_employment_position_spouse}}</a></div>
        </li>
        <li class="collection-item">
            <div>From<a class="secondary-content">{{detail.previous_employment_startDate_spouse}}</a></div>
        </li>
        <li class="collection-item">
            <div>To<a class="secondary-content">{{detail.previous_employment_endDate_spouse}}</a></div>
        </li>
    </ul>
    <ul class="collection with-header">
        <li class="collection-header center-align">
            <h5>Section 3: SPOUSE PRESENT EMPLOYMENT</h5>
        </li>
        <li class="collection-item">
            <div>Employer Name<a class="secondary-content">{{detail.present_employer_spouse}}</a></div>
        </li>
        <li class="collection-item">
            <div>Address<a class="secondary-content">{{detail.present_employment_address_spouse}}</a></div>
        </li>
        <li class="collection-item">
            <div>Position<a class="secondary-content">{{detail.present_employment_position_spouse}}</a></div>
        </li>
        <li class="collection-item">
            <div>From<a class="secondary-content">{{detail.present_employment_startDate_spouse}}</a></div>
        </li>
        <li class="collection-item">
            <div>To<a class="secondary-content">{{detail.present_employment_endDate_spouse}}</a></div>
        </li>
    </ul>

    <ul class="collection with-header">
        <li class="collection-header center-align">
            <h5>Section 4: VEHICLES</h5>
        </li>
        <div class="row card-panel white" *ngIf="!detail.car1_make && !detail.car2_make">
            <h6> No vehicles Registred.</h6>
        </div>
        <div *ngIf="detail.car1_make">
            <h6 class="center-align">Vechile 1</h6>
            <li class="collection-item">
                <div>Vehicle Model<a class="secondary-content">{{detail.car1_make}}</a></div>
            </li>
            <li class="collection-item">
                <div>Year Built<a class="secondary-content">{{detail.car1_year}}</a></div>
            </li>
            <li class="collection-item">
                <div>Vehicle Value $<a class="secondary-content">{{detail.car1_value}}</a></div>
            </li>
        </div>
        <div *ngIf="detail.car2_make">
            <h6 class="center-align">Vechile 2</h6>
            <li class="collection-item">
                <div>Vehicle Model<a class="secondary-content">{{detail.car2_make}}</a></div>
            </li>
            <li class="collection-item">
                <div>Year Built<a class="secondary-content">{{detail.car2_year}}</a></div>
            </li>
            <li class="collection-item">
                <div>Vehicle Value $<a class="secondary-content">{{detail.car2_value}}</a></div>
            </li>

        </div>
    </ul>

    <div class="row card-panel white" *ngIf="assets.length == 0">
        <h5 class="center-align">Section 4: ASSET</h5>
        <h6> No Asset Declared</h6>
    </div>
    <div class="row card-panel white" *ngIf="assets && assets.length > 0">
        <h5 class="center-align">Section 4: ASSET</h5>
        <table class="hover striped responsive-table card-content">
            <thead>
                <tr>
                    <th>Asset</th>
                    <th>Amount</th>
                    <th>Description</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let asset of assets; let i=index" style="cursor: pointer;">
                    <td>{{asset.catagory}}</td>
                    <td>{{asset.value}} $</td>
                    <td>{{asset.description}}</td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="row card-panel white" *ngIf="applicantIncomes.length == 0">
        <h5 class="center-align">Section 5: INCOME</h5>
        <h6> No Income Declared</h6>
    </div>
    <div class="row card-panel white" *ngIf="applicantIncomes && applicantIncomes.length > 0">
        <h5 class="center-align">Section 5: INCOME</h5>
        <table class="hover striped responsive-table card-content">
            <thead>
                <tr>
                    <th>Income</th>
                    <th>Amount</th>
                    <th>Date Recevied</th>
                    <th>Description</th>
                    <th>Type</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let income of applicantIncomes; let i=index" style="cursor: pointer;">
                    <td>{{income.catagory}}</td>
                    <td>{{income.value}} $</td>
                    <td>{{income.date_recieved}}</td>
                    <td>{{income.description}}</td>
                    <td>{{income.type}}</td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="row card-panel white" *ngIf="spouseIncomes.length == 0">
        <h5 class="center-align">Section 5: SPOUSE INCOME</h5>
        <h6> No Income Declared</h6>
    </div>
    <div class="row card-panel white" *ngIf="spouseIncomes && spouseIncomes.length > 0">
        <h5 class="center-align">Section 5: SPOUSE INCOME</h5>
        <table class="hover striped responsive-table card-content">
            <thead>
                <tr>
                    <th>Income</th>
                    <th>Amount</th>
                    <th>Date Recevied</th>
                    <th>Description</th>
                    <th>Type</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let income of spouseIncomes; let i=index" style="cursor: pointer;">
                    <td>{{income.catagory}}</td>
                    <td>{{income.value}} $</td>
                    <td>{{income.date_recieved}}</td>
                    <td>{{income.description}}</td>
                    <td>{{income.type}}</td>
                </tr>
            </tbody>
        </table>
    </div>

    <ul class="collection with-header" *ngIf="detail.previous_address">
        <li class="collection-header center-align">
            <h5>Section 7: HOUSING</h5>
        </li>
        <li class="collection-item">
            <div>Accomodation Shared:<a class="secondary-content">{{detail.isYesOfOthers}}</a></div>
        </li>
        <li class="collection-item">
            <div>Responsible for Shelter Costs: <a class="secondary-content">{{detail.WhoOfOthers}}</a></div>
        </li>
        <li class="collection-item">
            <div>Relationship<a class="secondary-content">{{detail.relationship_headofhouse}}</a></div>
        </li>
        <li class="collection-item">
            <div>CMHC assisted housing: <a class="secondary-content">{{detail.isYesOfCMHC}}</a></div>
        </li>
        <h6 class="center-align">Costs</h6>
        <li class="collection-item">
            <div>Utilities :<a class="secondary-content">{{detail.UtilitiesOfOthers}}</a></div>
        </li>
        <li class="collection-item">
            <div>Fuel :<a class="secondary-content">{{detail.FuelOfOthers}}</a></div>
        </li>
        <li class="collection-item">
            <div>Maintenance :<a class="secondary-content">{{detail.MaintenanceOfOthers}}</a></div>
        </li>
        <li class="collection-item">
            <div>Garbage, Water, Sewer: :<a class="secondary-content">{{detail.GarbageOfOthers}}</a></div>
        </li>

        <li class="collection-item">
            <div>Rent / Housing loan PMT ($): :<a class="secondary-content">{{detail.LoanPMTOfOthers}}</a></div>
        </li>
        <li class="collection-item">
            <div>Comments: :<a class="secondary-content">{{detail.comments}}</a></div>
        </li>
    </ul>
   
    <div class=" row col s12">
        <button class="btn tooltipped col s12" data-position="bottom" data-tooltip="Open as a PDF" type="button"
            style="margin-bottom: 25px;" (click)="openPdf()" style="margin:5px;"><i
                class="material-icons">open_in_new</i>View as PDF</button>
        <button class="btn blue tooltipped col s12" data-position="bottom" data-tooltip="Download Application"
            type="button" style="margin-bottom: 25px;" (click)="downloadPdf()" style="margin:5px;"><i
                class="material-icons blue">download</i>Download
            Application</button>
        <button class="btn green tooltipped col s12" data-position="bottom" data-tooltip="Print Application"
            type="button" style="margin-bottom: 25px;" (click)="printPdf()" style="margin:5px;"> <i
                class="material-icons">print</i>Print Application</button>

        <button [ngClass]="'col s12 btn waves-effect ' + themeColor" (click)="backToList()" type="button"
            style="margin-top: 25px;"><i class="material-icons left">arrow_back_ios</i> Back To Applications
            Page</button>
    </div>
</div>

<div id="imageModal" class="modal" *ngIf="image && imageName">
    <div class="modal-content">
        <div class="row col s12">
            <h5>{{imageName}}</h5>
            <img [src]="image" alt="Signature" class="col s12" />
        </div>
        <div class="row">
            <div class="input-field col s12 center">
                <button type="button" class='col s12 btn waves-effect red' (click)="closeModal()">
                    <i class="material-icons right">close</i> Cancel
                </button>
            </div>
        </div>
    </div>
</div>