
import { Injectable } from '@angular/core';
import { ApiKeyManagerService } from 'src/app/services/api/apiKeyManager.Service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EmailModel } from 'src/app/models/emailModel'; // capital case EmailModel
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { IsetsFireMembersService } from 'src/app/services/api/isets/Isets-fire-members.service'; //small case isets-fire-members.service
import { toast } from 'materialize-css';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { chdir } from 'process';
// import { stringify } from '@angular/compiler/src/util';

@Injectable({
  providedIn: 'root'
})
export class ISETSEmailService {

  constructor(private httpClient: HttpClient, private keyManager: ApiKeyManagerService,
              private fireMembersService: IsetsFireMembersService) { }

  url = environment.firstNation.apiUrl + 'api/Email/';     //'api/IsetsEmail/';
  website = environment.firstNation.website;
  

  sendEmail(model: EmailModel): Observable<any> {
    const headers = new HttpHeaders({SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8'});
    return this.httpClient.post<any>(this.url + 'sendEmail/', JSON.stringify(model), {headers});
  }


  sendContactAdminEmail(clientUID: string, email: EmailModel){
    this.fireMembersService.getClientsList().valueChanges().subscribe(x => {
        if (x) {
        let admins: any[];
        let member: any;
        admins  = x.filter(e => e.role === 'Admin');
        admins  = admins.filter(e => e.permissions.find(m => m.permission === 'ReceiveNotify').granted === true);
        
        member = x.filter(e => e.uid === clientUID);
        email.emailList = [];
        admins.forEach(o => {
          email.emailList.push(o.email);
        });
        const subject = email.subject;
        const body = email.body;
        email.subject = 'You have a new message from ' + member[0].GivenName + ' ' + member[0].LastName;
        email.body = 'From : ' + member[0].Email + ' <br/> Subject : <strong> ' + subject + ' </strong> <br/> <p>' + body + '</p>';
        const headers = new HttpHeaders({SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8'});
         this.httpClient.post<any>(this.url + 'sendEmail/', JSON.stringify(email), { headers });

      }
    });

  }

  sendNewsNotification() {
    let clients: any[];
    const mail = new EmailModel();
    const nation = environment.firstNation.displayName;
    
    this.fireMembersService.getClientsList().valueChanges().subscribe(x => {
        if (x) {
        clients  = x.filter(e => e.role === 'Member');
        mail.emailList = [];
        clients.forEach(o => {
          mail.emailList.push(o.email);
        });

        mail.subject = 'Nation News';
        mail.body = nation + ' added an update. If you want to stay updated, please follow the link : <a href="' + this.website + '" target="_blank"> ' + this.website + ' </a>';
       const headers = new HttpHeaders({SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8'});
        this.httpClient.post<any>(this.url + 'sendEmail/', JSON.stringify(mail), {headers}).subscribe(o => {
         ////// console.log(o);
        });
      }
    });
  }


  sendEventNotification(model: any) {
    model.title = model.title.replace(/<[^>]*>/g, '');
    model.body = model.body.replace(/<[^>]*>/g, '');
    let clients: any[];
    const mail = new EmailModel();
    this.fireMembersService.getClientsList().valueChanges().subscribe(x => {
        if (x) {
        clients  = x.filter(e => e.role === 'Member');
        mail.emailList = [];
        clients.forEach(o => {
          if (o.Email) {mail.emailList.push(o.Email); }
        });
        mail.subject = 'New Event On ' + model.eventDate;
        mail.body = '<span style="color:blue; margin-right: 15px;">Event Title : </span> ' + model.title + ' <br/> ';
        mail.body += '<span style="color:blue; margin-right: 15px;">Event Date : </span> ' + model.eventDate + ' <br/> ';
        mail.body += '<span style="color:blue; margin-right: 15px;">Event Place : </span> ' + model.eventPlace + ' <br/> ';
        mail.body += '<span style="color:blue; margin-right: 15px;">Description : </span> ' + model.body + ' <br/> ';

        //mail.body += '<br/><br/> For More Details Please Check The Event Calendar at : <a href="' + environment.firstNation.website + '/member/events-calendar" target="_blank">mynation.app</a>';
        mail.body += '<br/><br/> For More Details Please Check The Event Calendar at : <a href="' + environment.firstNation.website + '/member/events-calendar" target="_blank"> ' + this.website + ' </a>';
        mail.body += '<br/> Thank You';
        const headers = new HttpHeaders({SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8'});
        this.httpClient.post<any>(this.url + 'sendEmail/', JSON.stringify(mail), {headers}).subscribe(o => {
        });
      }
    });
  }




  sendEmailToApplicant(email: string) {

    const mail = new EmailModel();
    mail.emailList = [];
    mail.emailList.push(email);
    
    mail.subject = 'Application Received';
  
    mail.body = "Thank you for applying. We will be getting in touch with you shortly  <br/> <br/> Have a great day !";;
    const headers = new HttpHeaders({SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8'});
    this.httpClient.post<any>(this.url + 'sendEmail/', JSON.stringify(mail), {headers}).subscribe(o => {
     // console.log(o);
    });

  }



  sendEmailToApplicantNew(emailOfApplicant: string, firstnameOfApplicant: string, lastnameOfApplicant: string, message: string, phone: string) {

    const mail = new EmailModel();   //new IsetsEmailModel();
    mail.emailList = [];
    mail.emailList.push(emailOfApplicant);  
    mail.subject = 'Application Received';
    mail.body = "Thank you for applying. We will be getting in touch with you shortly  <br/> <br/> Have a great day !";
  
   // console.log("mail: ", mail);
    const headers = new HttpHeaders({SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8'});
    this.httpClient.post<any>(this.url + 'sendEmail/', JSON.stringify(mail), {headers}).subscribe(o => {
    console.log(o);
    // this.sendEmailtoAdmin(firstnameOfApplicant, lastnameOfApplicant, emailOfApplicant, message, phone);

    });

  }

  
  sendEmailtoAdmin(firstnameOfApplicant: string, lastnameOfApplicant: string, emailOfApplicant: string, message: string, phone: string){
    this.fireMembersService.getClientsList().valueChanges().subscribe(x => {
        if (x) {
        let admins: any[];
        admins  = x.filter(e => e.role === 'Admin');
        admins  = admins.filter(e => e.permissions.find(m => m.permission === 'ReceiveNotify').granted === true);
        const email = new EmailModel();    // new IsetsEmailModel();
        email.emailList = [];
        admins.forEach(o => {
          email.emailList.push(o.email);
        });

        email.subject = 'You have a new message from ' + firstnameOfApplicant + ' ' + lastnameOfApplicant;
       // email.body = 'From : ' + emailOfApplicant + ' <br/> Subject : <strong> ' + subject + ' </strong> <br/> <p>' + body + '</p>';

        var msg = "A New application is received. <br/>";
        msg += "Applicant Name: " + firstnameOfApplicant + ' ' + lastnameOfApplicant + '<br/>';
        msg += "E-mail:    " + emailOfApplicant + "<br/> ";
        msg += "Telephone: " + phone + "<br/>";
        msg += "Client Message: " + message + "<br/> ";
        msg += "Application Date: " + this.getFormattedDate(new Date()) + "<br/> ";
   
        email.body = msg;

        const headers = new HttpHeaders({SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8'});
        this.httpClient.post<any>(this.url + 'sendEmail/', JSON.stringify(email), {headers}).subscribe(o => {
         //console.log(o);
        });

      }
    });

  }


   getFormattedDate(date) {
    let year = date.getFullYear();
    let month = (1 + date.getMonth()).toString().padStart(2, '0');
    let day = date.getDate().toString().padStart(2, '0');
  
    return month + '/' + day + '/' + year;
}


  sendEmailToEmployer(email: string) {

    const webserver = environment.firstNation.website;

    const mail = new EmailModel();
    mail.emailList = [];
    mail.emailList.push(email);
    
    mail.subject = 'Registration Received';
  
    mail.body = "Thank you for registration. Please go to website " + webserver + "/#/auth/login to login, thank you ! <br/> <br/> Have a great day !";;
    const headers = new HttpHeaders({SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8'});
    this.httpClient.post<any>(this.url + 'sendEmail/', JSON.stringify(mail), {headers}).subscribe(o => {
     // console.log(o);
    });

  }





  sendRegistrationEmailtoAdmin(firstnameOfApplicant: string, lastnameOfApplicant: string){
    this.fireMembersService.getClientsList().valueChanges().subscribe(x => {
        if (x) {
        let admins: any[];
        admins  = x.filter(e => e.role === 'Admin');
        admins  = admins.filter(e => e.permissions.find(m => m.permission === 'ReceiveNotify').granted === true);
        const email = new EmailModel();
        email.emailList = [];
        admins.forEach(o => {
          email.emailList.push(o.email);
        });

        email.subject = 'Message from ' + firstnameOfApplicant + ' ' + lastnameOfApplicant; 
        var msg = "Client " + firstnameOfApplicant + ' ' + lastnameOfApplicant + " accepted the registration and now can access the Mobile app <br/>";
        email.body = msg;

        const headers = new HttpHeaders({SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8'});
        this.httpClient.post<any>(this.url + 'sendEmail/', JSON.stringify(email), {headers}).subscribe(o => {
         // console.log(o);
        });

      }
    });

  }




}



