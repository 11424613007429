import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { Options } from 'sortablejs';
import { DepartmentNewsService } from 'src/app/services/firebase/department-news.service';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/firebase/auth.service';

@Component({
  selector: 'app-news-list',
  templateUrl: './news-list.component.html',
  styleUrls: ['./news-list.component.css']
})

export class NewsListComponent implements OnInit {

  adminThemeColor = environment.appTheme.adminThemeColor;
  adminFontColor = environment.appTheme.adminFontColor;
  showLoading = false;
  privacy: any;
  model: any[];
  departmentId: any;
  isManager = false;
  canManageNews = false;
  options: Options = {
    animation: 150,
  };

  constructor(private authService: AuthService,
    private location: Location,
    private newsService: DepartmentNewsService) {

    this.options = {
      onUpdate: (event: any) => {
        this.model.forEach(element => {
          element.index = this.model.findIndex(x => x.id === element.id);
        });
        this.newsService.SortContentOnServer(this.model);
      }
    };
  }

  ngOnInit() {
    this.departmentId = this.authService.getGlobalUser().departmentId;
    this.isManager = this.authService.getGlobalUser().isManager;
    this.canManageNews = this.authService.getGlobalUser().manageDepartmentNews;

    this.showLoading = true;
    if (this.departmentId) {
      this.newsService.getDepNewsList(this.departmentId).valueChanges().subscribe(
        resp => {
          if (resp.length > 0) {
            this.model = resp;
            this.model.sort((a, b) => (a.index < b.index ? -1 : 1));
            this.showLoading = false;
          }
        },
        err => {
          this.showLoading = false;
        }
      );
    }
    this.showLoading = false;
  }

  backClicked() {
    this.location.back();
  }

}
