<div *ngIf="survey" class="row col s12">
    <p class="center-align">Step {{step}} of 15</p>

    <div class="row">
        <div class="row col s12">
            <a class='col s6 m3 center-align dropdown-trigger btn-flat red waves-effect waves-light white-text '
                id="report" data-target='dropdown1' style="font-weight: bold;"><i
                    class="material-icons right">picture_as_pdf</i>Get Report</a>

            <ul id='dropdown1' class='col dropdown-content'>
                <li><a (click)="downloadSurvey()" class="btn-flat center-align"><i class="material-icons right">
                            download
                        </i>Download</a></li>
                <li><a (click)="openSurvey()" class="btn-flat center-align"><i class="material-icons right">
                            open_in_new
                        </i>Open</a></li>
                <li><a (click)="printSurvey()" class="btn-flat center-align"><i class="material-icons right">
                            print
                        </i>Print</a></li>
            </ul>
        </div>
        <div class="row col s12">

            <button class="col s12 m5 offset-m2 l3 btn right  green" type="button" [disabled]="step >=14"
                (click)="nextSection()" style="margin-bottom: 15px;"><i class="material-icons right">skip_next</i>Next
                Section</button>

            <button class="col s12 m5 l3 btn left blue" type="button" [disabled]="0>=step" (click)="previousSection()"
                style="margin-bottom: 15px;"><i class="material-icons left">skip_previous</i>Previouse Section</button>
        </div>
    </div>

    <div class="row  white" *ngIf="step==0">
        <ul class="collection with-header">
            <li class="collection-header center-align">
                <h5>PART 1: {{nationOfUse | uppercase}} INFORMATION</h5>
            </li>
            <li class="collection-header">
                <p> <strong>Nation Information</strong> </p>
            </li>
            <li class="collection-item">
                <div>Do you know about the LRRCN app? <a
                        class="secondary-content">{{survey.aboutLrrcn.knowAboutLrrcn}}</a></div>
            </li>
            <li class="collection-item" *ngIf="survey.aboutLrrcn.knowAboutLrrcn ==='Yes'">
                <div> Have you registered?
                    <a *ngIf="survey.aboutLrrcn.registered !== ''" class="secondary-content">
                        {{survey.aboutLrrcn.registered}}
                    </a>
                    <a class="secondary-content" class="red-text secondary-content"
                        *ngIf="survey.aboutLrrcn.registered === ''">
                        Not Answered</a>
                </div>
            </li>
            <li class="collection-item">
                <div> Do you know know what you can use it for?<a
                        class="secondary-content">{{survey.aboutLrrcn.knowHowToUse}}</a></div>
            </li>
        </ul>
    </div>

    <div class="row  white" *ngIf="step==1">
        <ul class="collection with-header">
            <li class="collection-header center-align">
                <h5>PART 2: DEMOGRAPHICS</h5>
            </li>
            <li class="collection-header">
                <p> <strong>Client Information</strong> </p>
            </li>
            <li class="collection-item">
                <div>First Name<a class="secondary-content">{{survey.demographic.firstName}}</a></div>
            </li>
            <li class="collection-item">
                <div>Last Name<a class="secondary-content">{{survey.demographic.lastName}}</a></div>
            </li>
            <li class="collection-item">
                <div>Email<a class="secondary-content">{{survey.demographic.email}}</a></div>
            </li>
            <li class="collection-item">
                <div>Phone<a class="secondary-content">{{survey.demographic.phone}}</a></div>
            </li>
            <li class="collection-item">
                <div>Client Category<a class="secondary-content">{{survey.demographic.clientCategory}}</a></div>
            </li>

            <li class="collection-item">
                <div>Gender<a class="secondary-content">{{genders[survey.demographic.gender]}}</a></div>
            </li>
            <li class="collection-item">
                <div>Marital Status<a class="secondary-content">{{survey.demographic.maritalStatus}}</a></div>
            </li>
            <li class="collection-item">
                <div>Age<a class="secondary-content">{{survey.demographic.age}}</a></div>
            </li>
            <li class="collection-header">
                <p> <strong>Community</strong> </p>
            </li>
            <li class="collection-item">
                <div>From Community<a class="secondary-content">{{survey.demographic.community}}</a></div>
            </li>
            <li class="collection-item">
                <div>Living In<a class="secondary-content">{{survey.demographic.livingProvince}}</a></div>
            </li>
            <li class="collection-header">
                <p> <strong>Housing</strong> </p>
            </li>
            <ng-container *ngIf="survey.demographic.housing.length>0">
                <li class="collection-item" *ngFor="let housing of survey.demographic.housing">
                    <div class="teal-text">{{housing}}</div>
                </li>
            </ng-container>

            <li class="collection-item" *ngIf="survey.demographic.housing.length==0">
                <div class="red-text">Not Answered</div>
            </li>
        </ul>
    </div>

    <div class="row  white" *ngIf="step==2">
        <h5 class="row center-align">PART 3: LANGUAGE</h5>

        <table class="hover striped responsive-table card-content">
            <thead>
                <tr>
                    <th>Language</th>
                    <th>Speak</th>
                    <th>Write</th>
                    <th>Understand</th>
                    <th>Speaking Literacy Level </th>
                    <th>Writing Literacy Level </th>
                    <th>Learn How To Speak</th>
                </tr>
            </thead>

            <tbody>
                <tr *ngFor="let language of survey.languages; let i=index" style="cursor: pointer;">
                    <td>{{language.language}}</td>
                    <td class="teal-text">{{language.speak ? 'Yes' : "No"}}</td>
                    <td class="teal-text">{{language.write ? 'Yes' : "No" }}</td>
                    <td class="teal-text">{{language.understand ? 'Yes' : "No"}}</td>
                    <td class="teal-text">{{language.speak ? language.literacyLevel.speak : "No" }}</td>
                    <td class="teal-text">{{language.write ? language.literacyLevel.write : "No"}}</td>
                    <td *ngIf="language.learnToSpeak !== ''" class="teal-text">{{language.learnToSpeak}}</td>
                </tr>
            </tbody>
        </table>
    </div>

    <div class="row  white" *ngIf="step==3">
        <ul class="collection with-header">
            <li class="collection-header center-align">
                <h5 class="row center-align">PART 4: CARE GIVING</h5>
            </li>
            <li class="collection-header">
                <p> <strong>Dependants Information</strong> </p>
            </li>
            <li class="collection-item">
                <div>Number of dependent (persons relying on you for financial support) <a
                        *ngIf="survey.careGiving.numberOfDependants !== ''" class="secondary-content">
                        {{survey.careGiving.numberOfDependants}}</a>
                    <a class="secondary-content" class="red-text secondary-content"
                        *ngIf="survey.careGiving.numberOfDependants == ''">
                        Not Answered</a>
                </div>
            </li>

            <li class="collection-header">
                <p> <strong>Provider of care for: </strong> </p>
            </li>
            <ng-container *ngIf="survey.careGiving.careProvider.length>0">
                <li class="collection-item" *ngFor="let care of survey.careGiving.careProvider">
                    <div class="teal-text">{{care}}</div>
                </li>
            </ng-container>
            <li class="collection-item" *ngIf="survey.careGiving.careProvider.length==0">
                <div class="red-text">Not Answered</div>
            </li>
            <li class="collection-header">
                <p> <strong>Day Care Information</strong> </p>
            </li>
            <li class="collection-item">
                <div>Daycare available in the community<a *ngIf="survey.careGiving.dayeCareAvailable !== ''"
                        class="secondary-content">
                        {{survey.careGiving.dayeCareAvailable}}
                    </a>
                    <a class="secondary-content" class="red-text secondary-content"
                        *ngIf="survey.careGiving.dayeCareAvailable === ''">
                        Not Answered</a>
                </div>
            </li>
            <ng-container *ngIf="survey.careGiving.dayeCareAvailable !=='' ">
                <li class="collection-item" *ngIf="survey.careGiving.dayeCareAvailable ==='Yes' ">
                    <div>Type of care available

                        <a *ngIf="survey.careGiving.typeOfCareAvailable !== ''" class="secondary-content">
                            {{survey.careGiving.typeOfCareAvailable}}
                        </a>
                        <a class="secondary-content" class="red-text secondary-content"
                            *ngIf="survey.careGiving.typeOfCareAvailable === ''">
                            Not Answered</a>
                    </div>
                </li>
                <li class="collection-item" *ngIf="survey.careGiving.dayeCareAvailable ==='No' ">
                    <div>Does the community need one?
                        <a *ngIf="survey.careGiving.needsCare !== ''" class="secondary-content">
                            {{survey.careGiving.needsCare}}
                        </a>
                        <a class="secondary-content" class="red-text secondary-content"
                            *ngIf="survey.careGiving.needsCare === ''">
                            Not Answered</a>
                    </div>
                </li>

                <li class="collection-item" *ngIf="survey.careGiving.needsCare ==='Yes'">
                    <div>Type of care needed
                        <a *ngIf="survey.careGiving.neededCare !== ''" class="secondary-content">
                            {{survey.careGiving.neededCare}}
                        </a>
                        <a class="secondary-content" class="red-text secondary-content"
                            *ngIf="survey.careGiving.neededCare === ''">
                            Not Answered</a>
                    </div>
                </li>
            </ng-container>
        </ul>
    </div>
    <div class="row white" *ngIf="step==4">
        <ul class="collection with-header">
            <li class="collection-header center-align">
                <h5 class="row center-align">PART 5: EDUCATION</h5>
            </li>
            <li class="collection-header">
                <p> <strong>Highest Level Of Education</strong> </p>
            </li>
            <li class="collection-item">
                <div>Highest level of education you have completed and area of
                    study
                    <a *ngIf="survey.education.highestLevelOfEdu !== ''" class="secondary-content">
                        {{survey.education.highestLevelOfEdu}}
                    </a>
                    <a class="secondary-content" class="red-text secondary-content"
                        *ngIf="survey.education.highestLevelOfEdu === ''">
                        Not Answered</a>
                </div>
            </li>

            <li class="collection-item">
                <div>Details of any post-secondary (area of study, what year
                    you completed etc.)
                    <a *ngIf="survey.education.postSecondaryEdu !== ''" class="secondary-content">
                        {{survey.education.postSecondaryEdu}}
                    </a>
                    <a class="secondary-content" class="red-text secondary-content"
                        *ngIf="survey.education.postSecondaryEdu === ''">
                        Not Answered</a>
                </div>
            </li>

            <li class="collection-header">
                <p> <strong>The educational level you would like to acheive</strong> </p>
            </li>
            <ng-container *ngIf="survey.education.levelOfEduToAchieve.length>0">
                <li class="collection-item" *ngFor="let level of survey.education.levelOfEduToAchieve">
                    <div class="teal-text">{{level}}</div>
                </li>
            </ng-container>

            <li class="collection-item" *ngIf="survey.education.levelOfEduToAchieve==0">
                <div class="red-text">Not Answered</div>
            </li>
            <li class="collection-header">
                <p> <strong> Area of study, trade or career would you like to acheive</strong> </p>
            </li>
            <li class="collection-item">
                <div class="teal-text" *ngIf="survey.education.areaOfStudyToAchieve !== ''">
                    {{survey.education.areaOfStudyToAchieve}}</div>
                <div class="red-text" *ngIf="survey.education.areaOfStudyToAchieve === ''">
                    Not Answered</div>
            </li>
            <li class="collection-header">
                <p> <strong> Current Education</strong> </p>
            </li>

            <li class="collection-item">
                <div>Are you currently enrolled in an education program?
                    <a *ngIf="survey.education.currentlyEnrolled !== ''" class="secondary-content">
                        {{survey.education.currentlyEnrolled}}
                    </a>
                    <a class="secondary-content" class="red-text secondary-content"
                        *ngIf="survey.education.currentlyEnrolled === ''">
                        Not Answered</a>
                </div>
            </li>

            <ng-container *ngIf="survey.education.currentlyEnrolled !=='' ">
                <li class="collection-item" *ngIf="survey.education.currentlyEnrolled !=='No' ">
                    <div>Type Of Program
                        <a *ngIf="survey.education.typeOfProgram !== ''" class="secondary-content">
                            {{survey.education.typeOfProgram}}
                        </a>
                        <a class="secondary-content" class="red-text secondary-content"
                            *ngIf="survey.education.typeOfProgram === ''">
                            Not Answered</a>
                    </div>
                </li>

                <li class="collection-item" *ngIf="survey.education.currentlyEnrolled ==='No' ">
                    <div>Would you like to upgrade/further your education?
                        <a *ngIf="survey.education.upgradeEdu !== ''" class="secondary-content">
                            {{survey.education.upgradeEdu}}
                        </a>
                        <a class="secondary-content" class="red-text secondary-content"
                            *ngIf="survey.education.upgradeEdu === ''">
                            Not Answered</a>
                    </div>
                </li>
            </ng-container>

            <ng-container *ngIf="survey.education.upgradeEdu !=='' ">

                <li class="collection-item" *ngIf="survey.education.upgradeEdu ==='Yes' ">
                    <div>Type of program would you like to do

                        <a *ngIf="survey.education.typeOfProgramToUpgrade !== ''" class="secondary-content">
                            {{survey.education.typeOfProgramToUpgrade}}
                        </a>
                        <a class="secondary-content" class="red-text secondary-content"
                            *ngIf="survey.education.typeOfProgramToUpgrade === ''">
                            Not Answered</a>
                    </div>
                </li>

                <li class="collection-item" *ngIf="survey.education.upgradeEdu ==='No' ">
                    <div>Why don't you want to upgrade or further your education?
                        <a *ngIf="survey.education.whyNoUpgrade !== ''" class="secondary-content">
                            {{survey.education.whyNoUpgrade}}
                        </a>
                        <a class="secondary-content" class="red-text secondary-content"
                            *ngIf="survey.education.whyNoUpgrade === ''">
                            Not Answered</a>
                    </div>
                </li>
            </ng-container>

            <li class="collection-item">
                <div>Do you know who to contact to obtain upgrading or furthering your education?
                    <a *ngIf="survey.education.knowWhoToContactForUpgrade !== ''" class="secondary-content">
                        {{survey.education.knowWhoToContactForUpgrade}}
                    </a>
                    <a class="secondary-content" class="red-text secondary-content"
                        *ngIf="survey.education.knowWhoToContactForUpgrade === ''">
                        Not Answered</a>
                </div>
            </li>

            <li class="collection-item">
                <div>Do you know how to apply to a College/University?
                    <a *ngIf="survey.education.collegeApply !== ''" class="secondary-content">
                        {{survey.education.collegeApply}}
                    </a>
                    <a class="secondary-content" class="red-text secondary-content"
                        *ngIf="survey.education.collegeApply === ''">
                        Not Answered</a>
                </div>
            </li>

            <li class="collection-item">
                <div>Do you know how to apply for funding?
                    <a *ngIf="survey.education.applyForFunding !== ''" class="secondary-content">
                        {{survey.education.applyForFunding}}
                    </a>
                    <a class="secondary-content" class="red-text secondary-content"
                        *ngIf="survey.education.applyForFunding === ''">
                        Not Answered</a>
                </div>
            </li>
        </ul>
    </div>

    <div class="row  white" *ngIf="step==5">

        <ul class="collection with-header">
            <li class="collection-header center-align">
                <h5 class="row center-align">PART 6: CLASS OF OPERATORS LICENSE</h5>
            </li>
            <li class="collection-header">
                <p> <strong> What type(s) license(s) do you currently possess?</strong></p>
            </li>
            <ng-container *ngIf="survey.license.typeOfLicense.length>0">
                <li class="collection-item" *ngFor="let license of survey.license.typeOfLicense">
                    <div class="teal-text">{{license}}</div>
                </li>
            </ng-container>
            <li class="collection-item" *ngIf="survey.license.typeOfLicense.length==0">
                <div class="red-text">Not Answered</div>
            </li>
            <li class="collection-header">
                <p> <strong>Barriers to obtaining a license</strong> </p>
            </li>
            <ng-container *ngIf="survey.license.barrierForLicense.length>0">
                <li class="collection-item" *ngFor="let license of survey.license.barrierForLicense">
                    <div class="teal-text">{{license}}</div>
                </li>
            </ng-container>
            <li class="collection-item" *ngIf="survey.license.barrierForLicense.length==0">
                <div class="red-text">Not Answered</div>
            </li>
            <li class="collection-header">
                <p> <strong>Type(s) of license(s) desired to obtain</strong> </p>
            </li>
            <ng-container *ngIf="survey.license.typeOfLicenseToObtain.length>0">
                <li class="collection-item" *ngFor="let license of survey.license.typeOfLicenseToObtain">
                    <div class="teal-text">{{license}}</div>
                </li>
            </ng-container>
            <li class="collection-header">
                <p> <strong>Other License Information</strong> </p>
            </li>
            <li class="collection-item" *ngIf="survey.license.typeOfLicenseToObtain.length==0">
                <div class="red-text">Not Answered</div>
            </li>

            <li class="collection-item">
                <div>Would you like to be notified when training is offered?
                    <a *ngIf="survey.license.beNotifiedForTraining !== ''" class="secondary-content">
                        {{survey.license.beNotifiedForTraining}}
                    </a>
                    <a class="secondary-content" class="red-text secondary-content"
                        *ngIf="survey.license.beNotifiedForTraining === ''">
                        Not Answered</a>
                </div>
            </li>

            <li class="collection-item" *ngIf="survey.license.beNotifiedForTraining ==='Yes'">
                <div>
                    <a *ngIf="survey.license.howToBeNotified !== ''" class="secondary-content">
                        {{survey.license.howToBeNotified}}
                    </a>
                    <a class="secondary-content" class="red-text secondary-content"
                        *ngIf="survey.license.howToBeNotified === ''">
                        Not Answered</a>
                </div>
            </li>
        </ul>
    </div>

    <div class="row  white" *ngIf="step==6">
        <h5 class="row center-align">PART 7: TRAINING - SAFTEY AND/OR TICKETS</h5>
        <table class="hover striped responsive-table card-content">
            <thead>
                <tr>
                    <th>Training</th>
                    <th></th>
                    <th></th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let safteyTraining of survey.safteyTraining; let i=index" style="cursor: pointer;">
                    <td>{{safteyTraining.training}}</td>
                    <td class="teal-text">{{safteyTraining.dateValue.value}} {{safteyTraining.dateValue.value
                        ==='Expired' ? ' on - '+
                        safteyTraining.dateValue.date: ''}}
                    </td>
            </tbody>
            <span class="center-align" *ngIf="survey.safteyTraining.length == 0">
                <a class="red-text">No Saftey Training Added</a>
            </span>
        </table>
    </div>

    <div class="row  white" *ngIf="step==7">
        <ul class="collection with-header">
            <li class="collection-header center-align">
                <h5 class="row center-align">PART 8: TRAINING & EMPLOYMENT OPPORTUNITIES / EMPLOYMENT & SKILLS</h5>
            </li>

            <li class="collection-header">
                <p> <strong>Notification</strong> </p>
            </li>
            <li class="collection-item">
                <div>How would you prefer to be notified about training and/or employment opprotunities?</div>
            </li>
            <ng-container *ngIf="survey.trainingAndEmployment.notificationPreference.length>0">
                <li class="collection-item"
                    *ngFor="let notificationPreference of survey.trainingAndEmployment.notificationPreference">
                    <div class="teal-text">{{notificationPreference}}</div>
                </li>
            </ng-container>

            <li class="collection-item" *ngIf="survey.trainingAndEmployment.notificationPreference.length==0">
                <div class="red-text">Not Answered</div>
            </li>
            <li class="collection-item">
                <div>Email address o be part of a mailing list for education, training and employment opportunities
                    <a *ngIf="survey.trainingAndEmployment.notifcationEmail!== ''" class="secondary-content">
                        {{survey.trainingAndEmployment.notifcationEmail}}
                    </a>
                    <a class="secondary-content" class="red-text secondary-content"
                        *ngIf="survey.trainingAndEmployment.notifcationEmail === ''">
                        Not Answered</a>
                </div>
            </li>

            <li class="collection-header">
                <p> <strong>Employmnet</strong> </p>
            </li>
            <li class="collection-item">
                <div>Are you currently employed?
                    <a *ngIf="survey.trainingAndEmployment.currentlyEmployed!== ''" class="secondary-content">
                        {{survey.trainingAndEmployment.currentlyEmployed}}
                    </a>
                    <a class="secondary-content" class="red-text secondary-content"
                        *ngIf="survey.trainingAndEmployment.currentlyEmployed === ''">
                        Not Answered</a>
                </div>
            </li>
            <li class="collection-item">
                <div>Comments/Clarification
                    <a *ngIf="survey.trainingAndEmployment.currentEmploymentComment!== ''" class="secondary-content">
                        {{survey.trainingAndEmployment.currentEmploymentComment}}
                    </a>
                    <a class="secondary-content" class="red-text secondary-content"
                        *ngIf="survey.trainingAndEmployment.currentEmploymentComment === ''">
                        Not Answered</a>
                </div>
            </li>

            <ng-container *ngIf="survey.trainingAndEmployment.currentlyEmployed === 'Employed'">
                <li class="collection-item">
                    <div>Employment status
                        <a *ngIf="survey.trainingAndEmployment.employmentStatus!== ''" class="secondary-content">
                            {{survey.trainingAndEmployment.employmentStatus}}
                        </a>
                        <a class="secondary-content" class="red-text secondary-content"
                            *ngIf="survey.trainingAndEmployment.employmentStatus === ''">
                            Not Answered</a>
                    </div>
                </li>
            </ng-container>

            <li class="collection-item">
                <div>Are you currently seeking employment?
                    <a *ngIf="survey.trainingAndEmployment.currentlySeekingEmployment!== ''" class="secondary-content">
                        {{survey.trainingAndEmployment.currentlySeekingEmployment}}
                    </a>
                    <a class="secondary-content" class="red-text secondary-content"
                        *ngIf="survey.trainingAndEmployment.currentlySeekingEmployment === ''">
                        Not Answered</a>
                </div>
            </li>

            <li class="collection-item" *ngIf="survey.trainingAndEmployment.currentlySeekingEmployment !==''">
                <div>{{survey.trainingAndEmployment.currentlySeekingEmployment === 'No' ? 'What support do you need for
                    you to enter/reenter the workforce?'
                    : 'For how long'
                    }}

                    <a *ngIf="survey.trainingAndEmployment.reasonForSeekingEmployment!== ''" class="secondary-content">
                        {{survey.trainingAndEmployment.reasonForSeekingEmployment}}
                    </a>
                    <a class="secondary-content" class="red-text secondary-content"
                        *ngIf="survey.trainingAndEmployment.reasonForSeekingEmployment === ''">
                        Not Answered</a>
                </div>
            </li>

            <li class="collection-header">
                <p> <strong>Employment Goals</strong> </p>
            </li>

            <ng-container *ngIf="survey.trainingAndEmployment.employmentGoals.length>0">
                <li class="collection-item" *ngFor="let employmentGoal of survey.trainingAndEmployment.employmentGoals">
                    <div>{{employmentGoal.goal}}
                        <a class="secondary-content">{{employmentGoal.value}}
                        </a>
                    </div>
                </li>
            </ng-container>

            <li class="collection-item" *ngIf="survey.trainingAndEmployment.employmentGoals.length==0 ">
                <div class="red-text">Not Answered</div>
            </li>
        </ul>
    </div>

    <div class="row  white" *ngIf="step==8">
        <ul class="collection with-header">
            <li class="collection-header center-align">
                <h5 class="row center-align">PART 9: JOB /LIFE SATISFACTION</h5>
            </li>

            <li class="collection-header">
                <p> <strong>Job Satistfaction</strong> </p>
            </li>

            <li class="collection-item">
                <div>Are you satisfied with your current employment situation?
                    <a *ngIf="survey.jobSatisfaction.satisfiedWithEmployment!== ''" class="secondary-content">
                        {{survey.jobSatisfaction.satisfiedWithEmployment}}
                    </a>
                    <a class="secondary-content" class="red-text secondary-content"
                        *ngIf="survey.jobSatisfaction.satisfiedWithEmployment === ''">
                        Not Answered</a>
                </div>
            </li>

            <li class="collection-item">
                <div>Why you are satisfied or not satisfied with your current job/situation
                    <a *ngIf="survey.jobSatisfaction.detailsForSatisfaction!== ''" class="secondary-content">
                        {{survey.jobSatisfaction.detailsForSatisfaction}}
                    </a>
                    <a class="secondary-content" class="red-text secondary-content"
                        *ngIf="survey.jobSatisfaction.detailsForSatisfaction === ''">
                        Not Answered</a>
                </div>
            </li>

            <li class="collection-header">
                <p> <strong>Factors impacting your satisfaction</strong> </p>
            </li>

            <ng-container *ngIf="survey.jobSatisfaction.factorsForSatisfaction.length>0">
                <li class="collection-item" *ngFor="let factor of survey.jobSatisfaction.factorsForSatisfaction">
                    <div class="teal-text">{{factor.factor}}
                        <span *ngIf="survey.jobSatisfaction.factorsForSatisfaction.detail != '' "> - {{factor.detail}}
                        </span>
                    </div>
                </li>
            </ng-container>

            <li class="collection-item">
                <div>Details on the factors impacting you job satisfaction
                    <a *ngIf="survey.jobSatisfaction.detailsForSatisfaction!== ''" class="secondary-content">
                        {{survey.jobSatisfaction.detailsForSatisfaction}}
                    </a>
                    <a class="secondary-content" class="red-text secondary-content"
                        *ngIf="survey.jobSatisfaction.detailsForSatisfaction === ''">
                        Not Answered</a>
                </div>
            </li>
        </ul>
    </div>

    <div class="row white" *ngIf="step==9">
        <ul class="collection with-header">
            <li class="collection-header center-align">
                <h5 class="row center-align">PART 10: BARRIERS TO GETTING OR KEEPING A JOB</h5>
            </li>
            <li class="collection-header">
                <p> <strong>What do you believe is stopping you from getting or keeping a job</strong> </p>
            </li>
            <li class="collection-header">
                <p> <strong>Barrier</strong> </p>
            </li>
            <ng-container *ngIf="survey.barriersOfJob.barriers.length>0">
                <li class="collection-item" *ngFor="let barrier of survey.barriersOfJob.barriers">
                    <div>{{barrier.barrier}}
                        <a class="secondary-content">{{barrier.value}}
                        </a>
                    </div>
                </li>
            </ng-container>

            <li class="collection-item" *ngIf="survey.barriersOfJob.barriers.length==0 ">
                <div class="red-text">Not Answered</div>
            </li>

            <li class="collection-header">
                <p> <strong>Employment affected by COVID-19</strong> </p>
            </li>

            <li class="collection-item">
                <div>Has your employment been affected by COVID-19?
                    <a *ngIf="survey.barriersOfJob.covidAffected!== ''" class="secondary-content">
                        {{survey.barriersOfJob.covidAffected}}
                    </a>
                    <a class="secondary-content" class="red-text secondary-content"
                        *ngIf="survey.barriersOfJob.covidAffected === ''">
                        Not Answered</a>
                </div>
            </li>
            <li class="collection-item">
                <div>Explanation
                    <a *ngIf="survey.barriersOfJob.explanation!== ''" class="secondary-content">
                        {{survey.barriersOfJob.explanation}}
                    </a>
                    <a class="secondary-content" class="red-text secondary-content"
                        *ngIf="survey.barriersOfJob.explanation === ''">
                        Not Answered</a>
                </div>
            </li>
        </ul>
    </div>

    <div class="row  white" *ngIf="step==10">

        <ul class="collection with-header">
            <li class="collection-header center-align">
                <h5 class="row center-align">PART 11: EMPLOYMENT HISTORY</h5>
            </li>
            <li class="collection-header">
                <p> <strong>What category best describes your employment history? How many years of experience?</strong>
                </p>
            </li>
            <li class="collection-header">
                <p> <strong>Field</strong> </p>
            </li>
            <ng-container *ngIf="survey.employmentHistory.catagory.length>0">
                <li class="collection-item" *ngFor="let catagory of survey.employmentHistory.catagory">
                    <div>{{catagory.history}}
                        <a class="secondary-content">{{catagory.value}}
                        </a>
                    </div>
                </li>
            </ng-container>

            <li class="collection-item" *ngIf=" survey.employmentHistory.catagory.length==0 ">
                <div class="red-text">Not Answered</div>
            </li>

            <li class="collection-header">
                <p> <strong>Previous Experience</strong> </p>
            </li>
            <li class="collection-item">
                <div>Do you have any previous experience?
                </div>
            </li>
            <li class="collection-header">
                <p> <strong>Experience</strong> </p>
            </li>
            <ng-container *ngIf="survey.employmentHistory.previousExpirence.length>0">
                <li class="collection-item" *ngFor="let expirience of survey.employmentHistory.previousExpirence">
                    <div>{{expirience.expirience}}
                        <a class="secondary-content">{{expirience.value}}
                        </a>
                    </div>
                </li>
            </ng-container>

            <li class="collection-item"
                *ngIf="survey.employmentHistory.previousExpirence.length==0 && survey.employmentHistory.catagory.length==0 ">
                <div class="red-text">Not Answered</div>
            </li>

            <li class="collection-header">
                <p> <strong>If you would not like training in these areas, please explain why not.</strong>
                </p>
            </li>

            <li class="collection-item">
                <div class="teal-text" *ngIf="survey.employmentHistory.noTrainingReason !== ''">
                    {{survey.employmentHistory.noTrainingReason }}
                </div>
                <div class="red-text" *ngIf="survey.employmentHistory.noTrainingReason === ''">
                    Not Answered
                </div>
            </li>

            <li class="collection-header">
                <p> <strong> Job history of the previous job held (most recent):</strong>
                </p>
            </li>

            <ng-container *ngIf="!survey.employmentHistory.firstRecentJob.noRecentJob">
                <li class="collection-item">
                    <div>Employer
                        <a *ngIf="survey.employmentHistory.firstRecentJob.employer !== ''" class="secondary-content">
                            {{survey.employmentHistory.firstRecentJob.employer}}
                        </a>
                        <a class="secondary-content" class="red-text secondary-content"
                            *ngIf="survey.employmentHistory.firstRecentJob.employe === ''">
                            Not Answered</a>
                    </div>
                </li>
                <li class="collection-item">
                    <div>Job Title
                        <a *ngIf="survey.employmentHistory.firstRecentJob.jobTitle !== ''" class="secondary-content">
                            {{survey.employmentHistory.firstRecentJob.jobTitle}}
                        </a>
                        <a class="secondary-content" class="red-text secondary-content"
                            *ngIf="survey.employmentHistory.firstRecentJob.jobTitle === ''">
                            Not Answered</a>
                    </div>
                </li>
                <li class="collection-item">
                    <div>Main Duties
                        <a *ngIf="survey.employmentHistory.firstRecentJob.mainDuties !== ''" class="secondary-content">
                            {{survey.employmentHistory.firstRecentJob.mainDuties}}
                        </a>
                        <a class="secondary-content" class="red-text secondary-content"
                            *ngIf="survey.employmentHistory.firstRecentJob.mainDuties === ''">
                            Not Answered</a>
                    </div>
                </li>
                <li class="collection-item">
                    <div>Approx. dates of employment
                        <a *ngIf="survey.employmentHistory.firstRecentJob.startDateOfEmployment !== '' || survey.employmentHistory.firstRecentJob.endDateOfEmployment !== ''"
                            class="secondary-content">
                            {{survey.employmentHistory.firstRecentJob.startDateOfEmployment}} -
                            {{survey.employmentHistory.firstRecentJob.endDateOfEmployment}}
                        </a>
                        <a class="secondary-content" class="red-text secondary-content"
                            *ngIf="survey.employmentHistory.firstRecentJob.startDateOfEmployment === '' && survey.employmentHistory.firstRecentJob.endDateOfEmployment === ''">
                            Not Answered</a>
                    </div>
                </li>

                <li class="collection-item">
                    <div>Still working there/reason you left
                        <a *ngIf="survey.employmentHistory.firstRecentJob.stillWorkingThere !== ''"
                            class="secondary-content">
                            {{survey.employmentHistory.firstRecentJob.stillWorkingThere}}
                        </a>
                        <a class="secondary-content" class="red-text secondary-content"
                            *ngIf="survey.employmentHistory.firstRecentJob.stillWorkingThere === ''">
                            Not Answered</a>
                    </div>
                </li>
            </ng-container>

            <li class="collection-item" *ngIf="survey.employmentHistory.firstRecentJob.noRecentJob">
                <div class="red-text">No Information About Recent Job</div>
            </li>

            <li class="collection-header">
                <p> <strong> Job history of the previous job held (second most recent):</strong>
                </p>
            </li>
            <ng-container *ngIf="!survey.employmentHistory.secondRecentJob.noRecentJob">
                <li class="collection-item">
                    <div>Employer
                        <a *ngIf="survey.employmentHistory.secondRecentJob.employer !== ''" class="secondary-content">
                            {{survey.employmentHistory.secondRecentJob.employer}}
                        </a>
                        <a class="secondary-content" class="red-text secondary-content"
                            *ngIf="survey.employmentHistory.secondRecentJob.employer === ''">
                            Not Answered</a>
                    </div>
                </li>
                <li class="collection-item">
                    <div>Job Title
                        <a *ngIf="survey.employmentHistory.secondRecentJob.jobTitle !== ''" class="secondary-content">
                            {{survey.employmentHistory.secondRecentJob.jobTitle}}
                        </a>
                        <a class="secondary-content" class="red-text secondary-content"
                            *ngIf="survey.employmentHistory.secondRecentJob.jobTitle === ''">
                            Not Answered</a>
                    </div>
                </li>
                <li class="collection-item">
                    <div>Main Duties
                        <a *ngIf="survey.employmentHistory.secondRecentJob.mainDuties !== ''" class="secondary-content">
                            {{survey.employmentHistory.secondRecentJob.mainDuties}}
                        </a>
                        <a class="secondary-content" class="red-text secondary-content"
                            *ngIf="survey.employmentHistory.secondRecentJob.mainDuties === ''">
                            Not Answered</a>
                    </div>
                </li>
                <li class="collection-item">
                    <div>Approx. dates of employment
                        <a *ngIf="survey.employmentHistory.secondRecentJob.startDateOfEmployment !== '' || survey.employmentHistory.secondRecentJob.endDateOfEmployment !== ''"
                            class="secondary-content">
                            {{survey.employmentHistory.secondRecentJob.startDateOfEmployment}} -
                            {{survey.employmentHistory.secondRecentJob.endDateOfEmployment}}
                        </a>
                        <a class="secondary-content" class="red-text secondary-content"
                            *ngIf="survey.employmentHistory.secondRecentJob.startDateOfEmployment === '' && survey.employmentHistory.secondRecentJob.endDateOfEmployment === ''">
                            Not Answered</a>
                    </div>
                </li>

                <li class="collection-item">
                    <div>Still working there/reason you left
                        <a *ngIf="survey.employmentHistory.secondRecentJob.stillWorkingThere !== ''"
                            class="secondary-content">
                            {{survey.employmentHistory.secondRecentJob.stillWorkingThere}}
                        </a>
                        <a class="secondary-content" class="red-text secondary-content"
                            *ngIf="survey.employmentHistory.secondRecentJob.stillWorkingThere === ''">
                            Not Answered</a>
                    </div>
                </li>
            </ng-container>
            <li class="collection-item" *ngIf="survey.employmentHistory.secondRecentJob.noRecentJob">
                <div class="red-text">No Information About Second Recent Job</div>
            </li>

            <li class="collection-header">
                <p> <strong> Job history of the previous job held (third most recent):
                    </strong>
                </p>
            </li>
            <ng-container *ngIf="!survey.employmentHistory.thirdRecentJob.noRecentJob">
                <li class="collection-item">
                    <div>Employer
                        <a *ngIf="survey.employmentHistory.thirdRecentJob.employer !== ''" class="secondary-content">
                            {{survey.employmentHistory.thirdRecentJob.employer}}
                        </a>
                        <a class="secondary-content" class="red-text secondary-content"
                            *ngIf="survey.employmentHistory.thirdRecentJob.employer === ''">
                            Not Answered</a>
                    </div>
                </li>
                <li class="collection-item">
                    <div>Job Title
                        <a *ngIf="survey.employmentHistory.thirdRecentJob.jobTitle !== ''" class="secondary-content">
                            {{survey.employmentHistory.thirdRecentJob.jobTitle}}
                        </a>
                        <a class="secondary-content" class="red-text secondary-content"
                            *ngIf="survey.employmentHistory.thirdRecentJob.jobTitle === ''">
                            Not Answered</a>
                    </div>
                </li>
                <li class="collection-item">
                    <div>Main Duties
                        <a *ngIf="survey.employmentHistory.thirdRecentJob.mainDuties !== ''" class="secondary-content">
                            {{survey.employmentHistory.thirdRecentJob.mainDuties}}
                        </a>
                        <a class="secondary-content" class="red-text secondary-content"
                            *ngIf="survey.employmentHistory.thirdRecentJob.mainDuties === ''">
                            Not Answered</a>
                    </div>
                </li>
                <li class="collection-item">
                    <div>Approx. dates of employment
                        <a *ngIf="survey.employmentHistory.thirdRecentJob.startDateOfEmployment !== '' || survey.employmentHistory.thirdRecentJob.endDateOfEmployment !== ''"
                            class="secondary-content">
                            {{survey.employmentHistory.thirdRecentJob.startDateOfEmployment}} -
                            {{survey.employmentHistory.secondRecentJob.endDateOfEmployment}}
                        </a>
                        <a class="secondary-content" class="red-text secondary-content"
                            *ngIf="survey.employmentHistory.thirdRecentJob.startDateOfEmployment === '' && survey.employmentHistory.thirdRecentJob.endDateOfEmployment === ''">
                            Not Answered</a>
                    </div>
                </li>

                <li class="collection-item">
                    <div>Still working there/reason you left
                        <a *ngIf="survey.employmentHistory.thirdRecentJob.stillWorkingThere !== ''"
                            class="secondary-content">
                            {{survey.employmentHistory.thirdRecentJob.stillWorkingThere}}
                        </a>
                        <a class="secondary-content" class="red-text secondary-content"
                            *ngIf="survey.employmentHistory.thirdRecentJob.stillWorkingThere === ''">
                            Not Answered</a>
                    </div>
                </li>
            </ng-container>
            <li class="collection-item" *ngIf="survey.employmentHistory.thirdRecentJob.noRecentJob">
                <div class="red-text">No Information About Third Recent Job</div>
            </li>
        </ul>
    </div>

    <div class="row white" *ngIf="step==11">
        <ul class="collection with-header">
            <li class="collection-header center-align">
                <h5 class="row center-align">PART 12: EMPLOYMENT ON-RESERVE & OFF-RESERVE / SHIFT WORK</h5>
            </li>

            <li class="collection-header">
                <p> <strong>Shift work</strong> </p>
            </li>

            <li class="collection-item">
                <div> Do you feel more comfortable going out to work in groups then alone?
                    <a *ngIf="survey.shiftWork.workAlone !== ''" class="secondary-content">
                        {{survey.shiftWork.workAlone }}
                    </a>
                    <a class="secondary-content" class="red-text secondary-content"
                        *ngIf="survey.shiftWork.workAlone  === ''">
                        Not Answered</a>
                </div>
            </li>
            <li class="collection-item" *ngIf="survey.shiftWork.workAlone ==='Yes'">
                <div> why?
                    <a *ngIf="survey.shiftWork.workAloneReason !== ''" class="secondary-content">
                        {{survey.shiftWork.workAloneReason}}
                    </a>
                    <a class="secondary-content" class="red-text secondary-content"
                        *ngIf="survey.shiftWork.workAloneReason === ''">
                        Not Answered</a>
                </div>
            </li>

            <li class="collection-item">
                <div>Are you willing to work shift work outside of the
                    community?
                    <a *ngIf="survey.shiftWork.shiftWorkOutsideCommunity !== ''" class="secondary-content">
                        {{survey.shiftWork.shiftWorkOutsideCommunity }}
                    </a>
                    <a class="secondary-content" class="red-text secondary-content"
                        *ngIf="survey.shiftWork.shiftWorkOutsideCommunity  === ''">
                        Not Answered</a>
                </div>
            </li>

            <li class="collection-item" *ngIf="survey.shiftWork.shiftWorkOutsideCommunity ==='No'">
                <div>Explanation
                    <a *ngIf="survey.shiftWork.noReason !== ''" class="secondary-content">
                        {{survey.shiftWork.noReason}}
                    </a>
                    <a class="secondary-content" class="red-text secondary-content"
                        *ngIf="survey.shiftWork.noReason === ''">
                        Not Answered</a>
                </div>
            </li>

            <li class="collection-item">
                <div>Are you willing to relocate outside of the community?
                    <a *ngIf="survey.shiftWork.willingToRelocate !== ''" class="secondary-content">
                        {{survey.shiftWork.willingToRelocate}}
                    </a>
                    <a class="secondary-content" class="red-text secondary-content"
                        *ngIf="survey.shiftWork.willingToRelocate === ''">
                        Not Answered</a>
                </div>
            </li>

            <li class="collection-item">
                <div>Are you willing to commute for work?
                    <a *ngIf="survey.shiftWork.commuteForWork !== ''" class="secondary-content">
                        {{survey.shiftWork.commuteForWork}}
                    </a>
                    <a class="secondary-content" class="red-text secondary-content"
                        *ngIf="survey.shiftWork.commuteForWork === ''">
                        Not Answered</a>
                </div>
            </li>

            <li class="collection-item"
                *ngIf="survey.shiftWork.commuteForWork !=='' && survey.shiftWork.commuteForWork=='Yes' ">
                <div>How far would you commute?
                    <a *ngIf="survey.shiftWork.commuteLength.length !== ''" class="secondary-content">
                        {{survey.shiftWork.commuteLength.length}}
                    </a>
                    <a class="secondary-content" class="red-text secondary-content"
                        *ngIf="survey.shiftWork.commuteLength.length === ''">
                        Not Answered</a>
                </div>
            </li>
            <li class="collection-item"
                *ngIf="survey.shiftWork.commuteForWork !=='' && survey.shiftWork.commuteForWork=='Yes' ">
                <div>Comments
                    <a *ngIf="survey.shiftWork.commuteLength.comments !== ''" class="secondary-content">
                        {{survey.shiftWork.commuteLength.comments}}
                    </a>
                    <a class="secondary-content" class="red-text secondary-content"
                        *ngIf="survey.shiftWork.commuteLength.comments === ''">
                        Not Answered</a>
                </div>
            </li>

            <ng-container
                *ngIf="survey.shiftWork.commuteForWork ==='Yes' || survey.shiftWork.willingToRelocate ==='Yes'">

                <li class="collection-header">
                    <p> <strong>What supports would you require to relocate</strong> </p>
                </li>
                <ng-container *ngIf="survey.shiftWork.supportForRelocate.length >0">
                    <li class="collection-item" *ngFor="let support of survey.shiftWork.supportForRelocate">
                        <div class="teal-text">{{support.support}}

                        </div>
                    </li>
                </ng-container>
                <li class="collection-item" *ngIf="survey.shiftWork.supportForRelocate.length==0">
                    <div class="red-text">Not Answered</div>
                </li>
            </ng-container>

            <li class="collection-header">
                <p> <strong>What type of workshops would you like to have in place in the community?</strong> </p>
            </li>
            <ng-container *ngIf="survey.shiftWork.workshopsInCommunity.length >0">
                <li class="collection-item" *ngFor="let workshop of survey.shiftWork.workshopsInCommunity">
                    <div class="teal-text">{{workshop}}
                    </div>
                </li>
            </ng-container>

            <li class="collection-item" *ngIf="survey.shiftWork.workshopsInCommunity.length==0">
                <div class="red-text">Not Answered</div>
            </li>

            <li class="collection-item">
                <div>Are you more comfortable with in-community workshop instructors or local community member
                    workshops?
                    <a *ngIf="survey.shiftWork.localOrInCommunityWorkshop !== ''" class="secondary-content">
                        {{survey.shiftWork.localOrInCommunityWorkshop}}
                    </a>
                    <a class="secondary-content" class="red-text secondary-content"
                        *ngIf="survey.shiftWork.localOrInCommunityWorkshop === ''">
                        Not Answered</a>
                </div>
            </li>

            <li class="collection-item">
                <div>Are you interested in creating support groups?
                    <a *ngIf="survey.shiftWork.supportGroup !== ''" class="secondary-content">
                        {{survey.shiftWork.supportGroup}}
                    </a>
                    <a class="secondary-content" class="red-text secondary-content"
                        *ngIf="survey.shiftWork.supportGroup === ''">
                        Not Answered</a>
                </div>
            </li>

            <ng-container
                *ngIf="survey.shiftWork.supportGroup ==='Yes' && survey.shiftWork.typesOfSupportGroup.length >0">
                <li class="collection-item" *ngFor="let support of survey.shiftWork.typesOfSupportGroup">
                    <div class="teal-text">{{support}}
                    </div>
                </li>
            </ng-container>

            <li class="collection-item"
                *ngIf="survey.shiftWork.supportGroup ==='Yes' && survey.shiftWork.typesOfSupportGroup.length==0">
                <div class="red-text">Not Answered</div>
            </li>

            <li class="collection-item">
                <div>Interested in a career assessment to help you figure out what careers you might be suited
                    for?
                    <a *ngIf="survey.shiftWork.careerAssessment !== ''" class="secondary-content">
                        {{survey.shiftWork.careerAssessment}}
                    </a>
                    <a class="secondary-content" class="red-text secondary-content"
                        *ngIf="survey.shiftWork.careerAssessment === ''">
                        Not Answered</a>
                </div>
            </li>
        </ul>
    </div>

    <div class="row  white" *ngIf="step==12">

        <ul class="collection with-header">
            <li class="collection-header center-align">
                <h5 class="row center-align">PART 13: ADDICTION SUPPORT</h5>
            </li>

            <li class="collection-header">
                <p> <strong>Addictions Support</strong> </p>
            </li>

            <li class="collection-item">
                <div>Would you like information about the NNADAP Program?
                    <a *ngIf="survey.addictionsSupport.infoOnProgram !== ''" class="secondary-content">
                        {{survey.addictionsSupport.infoOnProgram}}
                    </a>
                    <a class="secondary-content" class="red-text secondary-content"
                        *ngIf="survey.addictionsSupport.infoOnProgram === ''">
                        Not Answered</a>
                </div>
            </li>

            <ng-container
                *ngIf="survey.addictionsSupport.infoOnProgram =='Yes' && survey.addictionsSupport.infoNNADAP.length>0">
                <li class="collection-item" *ngFor="let info of survey.addictionsSupport.infoNNADAP">
                    <div class="teal-text">{{info}}
                    </div>
                </li>
            </ng-container>

            <li class="collection-item"
                *ngIf="survey.addictionsSupport.infoOnProgram =='Yes' && survey.addictionsSupport.infoNNADAP.length==0">
                <div class="red-text">Not Answered</div>
            </li>

            <li class="collection-item">
                <div>Would you like us to communicate with NNADAP on your behalf
                    <a *ngIf="survey.addictionsSupport.communicateOnBehalf.value !== ''" class="secondary-content">
                        {{survey.addictionsSupport.communicateOnBehalf.value}}
                    </a>
                    <a class="secondary-content" class="red-text secondary-content"
                        *ngIf="survey.addictionsSupport.communicateOnBehalf.value === ''">
                        Not Answered</a>
                </div>
            </li>

            <li class="collection-item">
                <div>Comments
                    <a *ngIf="survey.addictionsSupport.communicateOnBehalf.comments !== ''" class="secondary-content">
                        {{survey.addictionsSupport.communicateOnBehalf.comments}}
                    </a>
                    <a class="secondary-content" class="red-text secondary-content"
                        *ngIf="survey.addictionsSupport.communicateOnBehalf.comments === ''">
                        Not Answered</a>
                </div>
            </li>
        </ul>
    </div>

    <div class="row  white" *ngIf="step==13">

        <ul class="collection with-header">
            <li class="collection-header center-align">
                <h5 class="row center-align">PART 14: TRADITIONAL EMPLOYMENT</h5>
            </li>

            <li class="collection-header">
                <p> <strong> Way of Life and Hunting
                        I have the skills for</strong>
                </p>
            </li>
            <ng-container *ngIf="survey.traditionalEmployment.skills.length>0">
                <li class="collection-item" *ngFor="let skill of survey.traditionalEmployment.skills">
                    <div>{{skill.skill}}
                        <a class="secondary-content">{{skill.value}}
                        </a>
                    </div>
                </li>
            </ng-container>
            <li class="collection-item" *ngIf="survey.traditionalEmployment.skills.length==0">
                <div class="red-text">Not Answered</div>
            </li>
            <li class="collection-header">
                <p> <strong> Additional Comments</strong>
                </p>
            </li>
            <li class="collection-item">
                <div>Comment
                    <a *ngIf="survey.traditionalEmployment.additionalComments !== ''" class="secondary-content">
                        {{survey.traditionalEmployment.additionalComments}}
                    </a>
                    <a class="secondary-content" class="red-text secondary-content"
                        *ngIf="survey.traditionalEmployment.additionalComments === ''">
                        Not Answered</a>
                </div>
            </li>
        </ul>
    </div>

    <div class="row  white" *ngIf="step==14">
        <ul class="collection with-header">
            <li class="collection-header center-align">
                <h5 class="row center-align">PART 15: CLIENT SIGNATURE AND DECLARATION</h5>
            </li>

            <li class="collection-header">
                <p> <strong> Client Signature</strong>
                </p>
            </li>
            
            <ng-container *ngIf="applicantImage && applicantImageName">
                <li class="collection-item">
                    <div>
                        I <span class="teal-text"><b>{{survey.demographic.firstName}}
                                {{survey.demographic.lastName}}</b></span> give consent and authorization of
                        {{nationOfUse}}, Income Support staff conducting
                        this 2022 Income {{survey.applicantDate ? survey.applicantDate.toDate().getFullYear() :
                        survey.workerDate.toDate().getFullYear()}} Support Survey with the information I have provided.
                        I am allowing myNation Income Support to use the data
                        collected for the purpose of analyzing the First Nation’s training, education, and employment
                        needs
                    </div>
                </li>

                <li class="collection-item">
                    <div>
                        Date
                        <a class="secondary-content">
                            {{survey.applicantDate.toDate().toDateString()}}
                        </a>
                    </div>
                </li>

                <li class="collection-item">
                    <div class="row col s12">
                        <img [src]="applicantImage" alt="Worker Signature" class="col s12" />
                    </div>
                </li>
            </ng-container>

            <ng-container *ngIf="survey.applicantSignature ===''">
                <li class="collection-item">
                    <div class="red-text">Client Has Not Signed Yet.
                    </div>
                </li>
            </ng-container>

            <li class="collection-header">
                <p> <strong> Admin Signature</strong>
                </p>
            </li>

            <ng-container *ngIf="workerImage && workerImageName">
                <li class="collection-item">
                    <div>
                        Date
                        <a class="secondary-content">
                            {{survey.workerDate.toDate().toDateString()}}
                        </a>
                    </div>
                </li>
                <li class="collection-item">
                    <div class="row col s12">
                        <img [src]="workerImage" alt="Worker Signature" class="col s12" />
                    </div>
                </li>
            </ng-container>

            <ng-container *ngIf="survey.workerSignature ===''">
                <li class="collection-item">
                    <div class="red-text">Admin Has Not Signed Yet.
                    </div>
                </li>
            </ng-container>
        </ul>
    </div>

    <div class="row">
        <button class="col s12 m5 offset-m2 l3 btn right  green" type="button" [disabled]="step >=14"
            (click)="nextSection()" style="margin-bottom: 15px;"><i class="material-icons right">skip_next</i>Next
            Section</button>

        <button class="col s12 m5 l3 btn left blue" type="button" [disabled]="0>=step" (click)="previousSection()"
            style="margin-bottom: 15px;"><i class="material-icons left">skip_previous</i>Previouse Section</button>

        <button [ngClass]="'col s12 btn waves-effect'" type="button" (click)="downloadSurvey()"
            style="margin-bottom: 15px;"><i class="material-icons right">picture_as_pdf</i>Download Report</button>
        <button [ngClass]="'col s12 btn waves-effect red'" type="button" (click)="openSurvey()"
            style="margin-bottom: 15px;"><i class="material-icons right">picture_as_pdf</i>Open report</button>

        <button [ngClass]="'col s12 btn waves-effect '+ themeColor" type="button" (click)="backToList()"
            style="margin-bottom: 15px;"><i class="material-icons left">skip_previous</i>Back To List</button>
    </div>
</div>

<div *ngIf="sendEmail" class="row col s12">
    <div class="col l1"></div>
    <div class="col s12 s10">
        <div>
            <app-send-email [emailList]="emailList" [fileList]="fileList" (emailSent)="emailSentHandler($event)">
            </app-send-email>
        </div>
    </div>
</div>