import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FireMembersService } from 'src/app/services/firebase/fire-members.service';
import { ElectionService } from 'src/app/services/firebase/election.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-candidate-page',
  templateUrl: './candidate-page.component.html',
  styleUrls: ['./candidate-page.component.css']
})

export class CandidatePageComponent implements OnInit {

  id = '';
  candidateDetail: any;
  electionsIRunFor: any;
  hasCandidateDetail = false;
  electionsDetail = [];
  isCandidate: any;
  constructor(private route: ActivatedRoute,
    private fireMembersService: FireMembersService,
    private electionService: ElectionService,
    private location: Location) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });

    this.fireMembersService.getMemberByID(this.id).valueChanges().subscribe(candidateData => {
      this.candidateDetail = candidateData;
    });

    this.electionService.getCandidateDetailById(this.id).valueChanges().subscribe(candidateData => {
      if (candidateData.length !== 0) {
        this.hasCandidateDetail = true;
        this.electionsIRunFor = candidateData[0];
        if (this.electionsIRunFor.candidateFor !== 0) {
          this.isCandidate = true;
          this.electionsIRunFor.candidateFor.forEach(electionID => {
            this.electionService.getElectionById(electionID).valueChanges().subscribe(election => {
              if (election) {
                this.electionsDetail.push(election);
              }
            });
          });
        } else {
          this.isCandidate = false;
        }
      } else {
        this.hasCandidateDetail = false;
      }
    });
  }

  backClicked() {
    this.location.back();
  }
}
