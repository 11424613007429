<div class="body-h">
    <div class="header-setting b-m-2">
        <h4 class="center-align"><span class="blue-text">Edit Action Plan</span></h4>
    </div>
    <main class="StickyContent white row">

        <div class="col s12 l11" *ngIf="step == 1">
            <ul class=" collection">
                <li class="section row collection-item">
                    <div class="row col s12">
                        <div class="section row col s12">
                            <h5 class="col s12"><strong><span class="red-text">Basic Information</span></strong> </h5>

                            <p class="col s12 m6"><strong>GivenName :</strong> <span
                                    class="blue-text">{{this.client_first_name}}</span></p>
                            <p class="col s12 m6"><strong>Last Name :</strong> <span
                                    class="blue-text">{{this.client_last_name}}</span></p>
                            <p class="col s12 m6"><strong>Date of Birth :</strong> <span
                                    class="blue-text">{{this.birthdate}}</span></p>
                            <p class="col s12 m6"><strong>SIN :</strong> <span
                                    class="blue-text">{{this.client_SIN}}</span></p>
                            <p class="col s12 m6"><strong>Funding Type :</strong> <span
                                    class="blue-text">{{this.funding_type}}</span></p>
                            <p class="col s12 m6"><strong>Budget Amount :</strong> <span
                                    class="blue-text">{{this.budget}}</span></p>
                            <!-- <p class="col s12 m6"><strong>Intervention: </strong> {{maritalStatusValue}}</p> -->
                            <p class="col s12 m6"> <strong>Start Date :</strong> <span
                                    class="blue-text">{{this.ap_start_date}}</span></p>
                            <p class="col s12 m6"> <strong>Status :</strong> <span
                                    class="blue-text">{{this.ap_status}}</span></p>
                        </div>
                    </div>
                </li>
            </ul>
        </div>

        <div class=" col s12 l11" *ngIf="step == 1">
            <div class="row blue lighten-4 black-text">
                <h5 class="center-align">Part A: <span class="red-text">Applicant Information</span></h5>
            </div>

            <form [formGroup]="clientForm" (ngSubmit)="submitClient(clientForm.value)">
                <div class="row card-panel">
                    <div class="col s12 m6">
                        <label for="gender">Gender<span class="red-text point">*</span></label>
                        <select formControlName="gender" class="validate">
                            <!-- <option disabled value="">Please Select</option>-->
                            <option *ngFor="let g of genders " [value]="g.code">{{g.name}}</option>
                        </select>
                    </div>

                    <div class="col s12 m6">
                        <label for="disablity">Disablity<span class="red-text point">*</span></label>
                        <select formControlName="disablity" class="validate ">
                            <option *ngFor="let d of disablitys" [value]="d.code">{{d.name}}</option>
                        </select>
                    </div>

                    <div class="col s12 m6">
                        <label for="indian_status">Indian Status<span class="red-text point">*</span></label>
                        <select formControlName="indian_status" class="validate ">
                            <option *ngFor="let i of aboriginal_status " [value]="i.code">{{i.name}}</option>
                        </select>
                    </div>

                    <div class="col s12 m6">
                        <label for="language">Language Preference<span class="red-text point">*</span></label>
                        <select formControlName="language" class="validate ">
                            <option *ngFor="let l of languages " [value]="l.code">{{l.name}}</option>
                        </select>
                    </div>

                    <div class="input-field col s12 m6">
                        <i class="material-icons prefix pink-text text-darken-3">phone</i>
                        <!-- <input id="telephone" [textMask]="{mask: phoneNumberMask, guide: true}" type="text"
                            formControlName="telephone" class="validate"> -->
                            <input id="telephone"  type="text"
                            formControlName="telephone" class="validate">
                        <label for="telephone">Telephone #</label>
                    </div>

                    <div class="input-field col s12 m6">
                        <i class="material-icons prefix yellow-text text-darken-3">phone</i>
                        <!-- <input id="fax" [textMask]="{mask: phoneNumberMask, guide: true}" type="text"
                            formControlName="fax" class="validate"> -->
                            <input id="fax" type="text"
                            formControlName="fax" class="validate">
                        <label for="fax">Fax #</label>
                    </div>


                    <div class="input-field col s12 m6">
                        <i class="material-icons prefix red-text">alternate_email</i>
                        <input id="email" type="text" formControlName="email" class="validate">
                        <label for="email">Email</label>
                        <div *ngIf="(email.dirty || email.touched) && email.invalid">
                            <small *ngIf="email.errors.email" class="red-text">Invalid Email Address</small>
                        </div>
                    </div>

                    <div class="input-field col s12 m6">
                        <i class="material-icons prefix orange-text">home</i>
                        <input id="address" type="text" formControlName="address" class="validate"
                            style="text-transform: capitalize;" style="text-transform: capitalize;" maxlength="80">
                        <label for="address">P.O.Box/Address</label>
                    </div>

                    <div class="input-field col s12 m6">
                        <i class="material-icons prefix purple-text">location_city</i>
                        <input id="city" type="text" formControlName="city" class="validate"
                            style="text-transform: capitalize;" style="text-transform: capitalize;" maxlength="50">
                        <label for="city">City<span class="red-text point">*</span></label>
                        <div *ngIf="(city.dirty || city.touched) && city.invalid">
                            <small *ngIf="city.errors.required" class="red-text">City is required</small>
                        </div>
                    </div>

                    <div class="input-field col s12 m6">
                        <i class="material-icons prefix teal-text">local_post_office</i>
                        <!-- <input id="postal_code" [textMask]="{mask: postalCodeMask, guide: true}" type="text"
                            formControlName="postal_code" class="validate" style='text-transform:uppercase'> -->
                            <input id="postal_code"  type="text"
                            formControlName="postal_code" class="validate" style='text-transform:uppercase'>
                        <label for="postal_code">Postal Code<span class="red-text point">*</span></label>
                        <div *ngIf="(postal_code.dirty || postal_code.touched) && postal_code.invalid">
                            <small *ngIf="postal_code.errors.required" class="red-text"> The Postal Code is required.
                            </small>
                        </div>
                    </div>

                    <div class="col s12 m6">
                        <label for="province">Province<span class="red-text point">*</span></label>
                        <select formControlName="province" class="validate ">
                            <option *ngFor="let p of provinces" [value]="p.code">{{p.name}}</option>
                        </select>
                    </div>

                    <div class="input-field col s12 m6">
                        <i class="material-icons prefix green-text">tag</i>
                        <!-- <input id="register_no" [textMask]="{mask: registerNumberMask, guide: true}" type="text"
                            formControlName="register_no" class="validate" style='text-transform:uppercase'> -->
                            <input id="register_no"  type="text"
                            formControlName="register_no" class="validate" style='text-transform:uppercase'>
                        <label for="register_no">INAC Registry #</label>
                    </div>

                    <div class="col s12 m12">
                        <label for="band">Band Name</label>
                        <select formControlName="band" class="validate">
                            <option disabled value="">Please Select</option>
                            <option *ngFor="let b of bands" [value]="b.band">{{b.band}}</option>
                        </select>
                    </div>
                </div>

                <div class="row">
                    <div class="col s6">
                        <button class="btn left waves-effect red" type="button" style="margin-bottom: 15px"
                            (click)="backToServices()"><i class="material-icons left">arrow_back</i>Back To My Services
                        </button>
                    </div>
                    <div class="col s6">
                        <button class=" btn right  green" type="submit" [disabled]="!clientForm.valid"
                            style="margin-bottom: 15px" title="Save">Next <i
                                class="material-icons right">arrow_forward</i></button>
                    </div>
                </div>
            </form>
        </div>

        <div class=" col s12 l11" *ngIf="step == 2">
            <br />
            <div class="row grey lighten-3 black-text">
                <h5 class="center-align">Part B: <span class="red-text">Education & Family Information</span></h5>
            </div>

            <form [formGroup]="educationForm" (ngSubmit)="submitEducation(educationForm.value)">
                <div class="row card-panel">
                    <div class="col s12 m6">
                        <label for="education">Education<span class="red-text point">*</span></label>
                        <select formControlName="education" class="validate ">
                            <option *ngFor="let e of educations" [value]="e.code">{{e.name}}</option>
                        </select>
                    </div>

                    <div class="col s12 m6">
                        <label for="edu_province">Edu. Province<span class="red-text point">*</span></label>
                        <select formControlName="edu_province" class="validate ">
                            <option *ngFor="let p of provinces" [value]="p.code">{{p.name}}</option>
                        </select>
                    </div>

                    <div class="col s12 m6">
                        <label for="sa_status">SA Client<span class="red-text point">*</span></label>
                        <select formControlName="sa_status" class="validate ">
                            <option *ngFor="let g of disablitys " [value]="g.code">{{g.name}}</option>
                        </select>
                    </div>

                    <div class="col s12 m6">
                        <label for="emp_barrier">Employment Barrier<span class="red-text point">*</span></label>
                        <select formControlName="emp_barrier" class="validate ">
                            <option *ngFor="let g of Barriers " [value]="g.code">{{g.name}}</option>
                        </select>
                    </div>

                    <div class="col s12 m6">
                        <label for="ei_status">EI Status<span class="red-text point">*</span></label>
                        <select formControlName="ei_status" class="validate ">
                            <option *ngFor="let g of EIStatus " [value]="g.code">{{g.name}}</option>
                        </select>
                    </div>

                    <div class="col s12 m4">
                        <label for="emp_status">Employment Status<span class="red-text point">*</span></label>
                        <select formControlName="emp_status" class="validate "
                            (change)="onEmploymentStatusChange($event.target.value)">
                            <option *ngFor="let g of EmpStatus " [value]="g.code">{{g.name}}</option>
                        </select>
                    </div>

                    <div class="col s12 m2">
                        <label for="emp_noc"> NOC </label>
                        <input formControlName="emp_noc" id="emp_noc" type="text" [(ngModel)]='selected_NOC'
                            autocomplete="off" require class="capitalize validate">
                    </div>

                    <div id="AddNOCModal" class="modal">
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header">
                                    <h5 class="modal-title center" id="myModalLabel"><strong>Please Select Employment
                                            NOC</strong></h5>
                                </div>

                                <div class="modal-body p-m-3">
                                    <div class="col s12 m12">
                                        <label for="skill_type">Skill Type<span class="red-text point">*</span></label>
                                        <select formControlName="skill_type" class="validate "
                                            (change)="onSkillTypesChange($event.target.value)">
                                            <option *ngFor="let s of skillTypes " [value]="s">{{s}}</option>
                                        </select>
                                    </div>

                                    <div class="col s12 m12">
                                        <label for="occupation">Occupation<span class="red-text point">*</span></label>
                                        <select formControlName="occupation" class="validate "
                                            (change)="onOccupationChange($event.target.value)">
                                            <option *ngFor="let n of occupations " [value]="n.noccode">{{n.nocgroup}}
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="modal-footer">
                            <a class="modal-close btn green " style="margin-right: 5px;">Close</a>
                        </div>
                    </div>

                    <!-- goals...... -->
                    <div class="col s12 m12">
                        <label for="goals"><span class="grey-text">
                                <h6>Goals:</h6>
                            </span></label>
                        <textarea id="goals" formControlName="goals" class="md-textarea form-control"
                            rows="10"></textarea>
                    </div>
                </div>

                <div class="row card-panel">
                    <div class="col s12 m12">
                        <div class="col s12 m6">
                            <label for="marital_status">Marital Status<span class="red-text point">*</span></label>
                            <select formControlName="marital_status" class="validate ">
                                <option *ngFor="let g of MaritalStatus " [value]="g.code">{{g.name}}</option>
                            </select>
                        </div>

                        <div class="input-field class col s12 m6">
                            <i class="material-icons prefix blue-text">tag</i>
                            <input formControlName="dependant_no" id="dependant_no" type="text" maxlength="2"
                                autocomplete="off" require class="capitalize validate">
                            <label for="dependant_no"> # of Dependnats </label>
                        </div>
                    </div>

                    <div class="col s12 m12">
                        <div class="col s12 m6">
                            <label for="childcare_required">Childcare Required ?<span
                                    class="red-text point">*</span></label>
                            <select formControlName="childcare_required" class="validate ">
                                <option *ngFor="let g of disablitys " [value]="g.code">{{g.name}}</option>
                            </select>
                        </div>

                        <div class="col s12 m6">
                            <label for="childcare_funding">Childcare Funding<span
                                    class="red-text point">*</span></label>
                            <select formControlName="childcare_funding" class="validate ">
                                <option *ngFor="let g of ChildCareFundings " [value]="g.code">{{g.name}}</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col s6">
                        <button class="btn left waves-effect red" type="button" style="margin-bottom: 15px"
                            (click)="onBack()"><i class="material-icons left">arrow_back</i>Back
                        </button>

                    </div>
                    <div class="col s6">
                        <button class=" btn right  green" type="submit" [disabled]="!educationForm.valid"
                            style="margin-bottom: 15px" title="Save">Submint Application <i
                                class="material-icons right">save</i></button>
                    </div>
                </div>
            </form>
        </div>
    </main>
</div>

<div id="messageModal" class="modal">
    <div class="modal-content">
        <h5><strong><span class="blue-text">Application</span></strong></h5>
        <br>
        <h6><strong> Thank you for sending your application {{client_first_name}}, Our business hours are Monday -
                Friday, 8:00 AM to 5:00 PM, we will notify when your application is processed </strong></h6>
    </div>

    <div class="modal-footer">
        <button class="blue btn white-text col s12" style="margin-bottom: 15px;" (click)="backClicked()">Close</button>
    </div>
</div>

<div id="QuestionModal" class="modal">
    <div class="modal-content">
        <h5><strong><span class="blue-text">Submit Funding Request</span></strong></h5>
        <br>
        <p>Are you ready to update the funding request ?</p>
    </div>
    <div class="modal-footer">
        <button type="button" (click)="ReadytoSubmitYes()" class="btn waves-effect green white-text"
            style="margin-right: 15px;">
            <i class="material-icons right white-text">check</i> Yes
        </button>

        <button type="button" (click)="ReadytoSubmitNo()" class="btn waves-effect red white-text"
            style="margin-right: 15px;">
            <i class="material-icons right white-text">close</i> No
        </button>
    </div>
</div>