<div class="row container-pi">
  <h4 class="title-section1 title-member icon-texts"> <span class="material-symbols-outlined back-icon p-r-1" (click)=" backClicked()">
    arrow_circle_left
</span>Governance</h4>

  <div class="col s12 m12 l12  card-panel b-n-1" *ngIf="model">
    <table class="centered highlight" [model]="model" [privacy]="'Members'">
      <thead>
        <tr>
          <th></th>
          <th>Email</th>
          <th>Full Name</th>
          <th>{{chiefTitle}} / {{councillorTitle}}</th>
          <th>Office Number </th>
          <th>Mobile Phone </th>
          <th>Detail</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="model.chiefs[0]" [routerLink]="['/member/member-main-home/governance-chief']">
          <td class="img-gov">
            <img *ngIf="model.chiefs[0].profile_pic" [src]="model.chiefs[0].profile_pic" alt=""
              class="circle responsive-img hide-on-small-only show-on-medium-and-up  "
              style=" width: 60px; height: 60px; border-radius: 50%; border-top: 2px solid; border-right: 2px solid; ">
            <img *ngIf="model.chiefs[0].profile_pic " [src]="model.chiefs[0].profile_pic" alt=""
              class="circle responsive-img hide-on-med-and-up "
              style=" width: 60px; height: 60px; border-radius: 50%; border-top: 2px solid; border-right: 2px solid; ">
            <div class="avatar-circle hide-on-med-and-up" *ngIf="!model.chiefs[0].profile_pic">
              <span class="initials">{{getInitials(model.chiefs[0].firstName,model.chiefs[0].lastName)}}</span>
            </div>
            <div class="avatar-circle2 hide-on-small-only show-on-medium-and-up" *ngIf="!model.chiefs[0].profile_pic">
              <span class="initials2">{{getInitials(model.chiefs[0].firstName,model.chiefs[0].lastName)}}</span>
            </div>
          </td>
          <td><span class="card-title sub-title-section2 bold-500">{{(model.chiefs[0].email)}}</span></td>
          <td>
            <h4 class="card-title sub-title-section2 bold-500">{{(model.chiefs[0].displayName) ?
              model.chiefs[0].displayName :
              model.chiefs[0].firstName + ' ' + model.chiefs[0].lastName}} </h4>
          </td>
          <td>
            <p class="bold-100">{{chiefTitle}}</p>
          </td>
          <td>{{(model.chiefs[0].office_phone)}}</td>
          <td>{{(model.chiefs[0].cell_phone)}}</td>
          <td>
            <span class="material-symbols-outlined detail-i" style="cursor: pointer;">
              arrow_forward
            </span>
          </td>
        </tr>

        <tr *ngFor="let item of model.councillors"
          [routerLink]="['/member/member-main-home/governance-detail',item.applicantID]">
          <td class="img-gov">
            <img *ngIf="item.profile_pic" [src]="item.profile_pic" alt=""
              class="circle responsive-img hide-on-small-only show-on-medium-and-up"
              style=" width: 60px; height: 60px; border-radius: 50%; border-top: 2px solid; border-right: 2px solid; ">

            <img *ngIf="item.profile_pic " [src]="item.profile_pic" alt=""
              class="circle responsive-img hide-on-med-and-up"
              style=" width: 60px; height: 60px; border-radius: 50%; border-top: 2px solid; border-right: 2px solid; ">
            <div class="avatar-circle hide-on-med-and-up" *ngIf="!item.profile_pic">
              <span class="initials">{{getInitials(item.firstName,item.lastName)}}</span>
            </div>
            <div class="avatar-circle2 hide-on-small-only show-on-medium-and-up" *ngIf="!item.profile_pic" [ngStyle]="{'background-color': themeColor}">
              <span class="initials2">{{getInitials(item.firstName,item.lastName)}}</span>
            </div>
          </td>
          <td><span class="card-title sub-title-section2 bold-500">{{(item.email)}}</span></td>
          <td>
            <h4 class="card-title sub-title-section2 bold-500">{{ (item.displayName) ? item.displayName : item.firstName
              + ' ' +
              item.lastName }} </h4>
          </td>
          <td>
            <p class=" bold-100">{{councillorTitle}}</p>
          </td>
          <td>{{(item.office_phone)}}</td>
          <td>{{(item.cell_phone)}}</td>
          <td>
            <span class="material-symbols-outlined detail-i" style="cursor: pointer;;">
              arrow_forward
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </div>