// import { ifStmt } from '@angular/compiler/src/output/output_ast';
import { Component, OnInit } from '@angular/core';
import { map } from 'rxjs/operators';
import { FileSharingServiceService } from 'src/app/services/firebase/file-sharing-service.service';
import { FireMembersService } from 'src/app/services/firebase/fire-members.service';
import { GovernanceService } from 'src/app/services/firebase/governance.service';

@Component({
  selector: 'app-share-new-file',
  templateUrl: './share-new-file.component.html',
  styleUrls: ['./share-new-file.component.css']
})

export class ShareNewFileComponent implements OnInit {

  visibility: 'Private';
  uploadProgerss: any;
  step1SelectVisibility = true;
  step2SelectGroup = false;
  step3SelectMembers = false;
  step4uploadStep = false;
  showProgress = false;
  file: File;
  selectedMembers: any[];
  model = {
    uid: '',
    ownerName: '',
    ownerID: '',
    fileName: '',
    fileType: '',
    fileSize: '',
    fileUrl: '',
    visibility: 'Public',
    sharedWith: [],
    dateShared: new Date(),
  }

  shareWithAdmins = false;
  adminList: any;
  governanceModel: any;
  shareWithCouncillors = false;
  councillorsList: any;
  shareWithChief = false;
  chiefModel: any;
  shareWithMembers = false;
  membersList: any;
  fnameFilter: any;
  lnameFilter: any;
  filtredList: any;
  step5Success = false;

  constructor(
    private fsService: FileSharingServiceService,
    private membersService: FireMembersService,
    private governanceService: GovernanceService) { }

  ngOnInit(): void {
    this.governanceService.getGovernanceList().valueChanges().subscribe(gov => {
      if (gov) {
        if (gov.length > 0) {
          this.governanceModel = gov[0];
          if (this.governanceModel.councillors) {
            if (this.governanceModel.councillors.lenght > 0) {
              this.governanceModel.councillors.forEach(element => {
                element = { ...element, isChecked: false }
              });
            }
          }
          if (this.governanceModel.chiefs) {
            if (this.governanceModel.chiefs.length > 0) {
              this.chiefModel = { ...this.governanceModel.chiefs[0], isChecked: true }
            }
          }
        }
      }
    });
  }

  shareWithPublicClick() {
    this.model.visibility = 'Public';
    this.step1SelectVisibility = false;
    this.step4uploadStep = true;
  }

  shareWithGroupClick() {
    this.model.visibility = 'Group';
    this.step1SelectVisibility = false;
    this.step2SelectGroup = true;
  }

  shareWithAdminsCheck() {
    this.shareWithAdmins = !this.shareWithAdmins;
    if (this.shareWithAdmins) {

      this.membersService.getAdminAccounts().valueChanges().pipe(
        map(adm => {
          return adm.map(user => ({
            return: { ...user, isChecked: false }
          }))
        })).subscribe(a => {
          if (a) {
            if (a.length > 0) {
              this.adminList = a;
            }
          }
        });
    }
    else {
      this.adminList = undefined;
    }
  }

  CheckedChange(model: any) {
    model.return.isChecked = !model.return.isChecked;
  }

  shareWithCouncilorsClick() {
    this.shareWithCouncillors = !this.shareWithCouncillors;
  }

  shareWithChiefClick() {
    this.shareWithChief = !this.shareWithChief;
  }

  shareWithSpecificMemberClick() {
    this.shareWithMembers = true;
    this.membersService.getMembersList().valueChanges().pipe(
      map(adm => {
        return adm.map(user => ({
          return: { ...user, isChecked: false }
        }))
      })).subscribe(m => {
        if (m) {
          if (m.length > 0) {
            this.membersList = m;
            this.filtredList = this.membersList.sort((a, b) => (a.LastName > b.LastName) ? 1 : ((b.LastName > a.LastName) ? -1 : 0));
          }
        }
      });
    this.model.visibility = "SpecificMembers";
    this.step1SelectVisibility = false;
    this.step3SelectMembers = true;
  }

  filterResult() {
    this.filtredList = this.membersList;
    if (this.fnameFilter) {
      this.filtredList = this.filtredList.filter(fn => fn.return.GivenName.toUpperCase().startsWith(this.fnameFilter.toUpperCase())).sort((a, b) => (a.return.GivenName > b.return.GivenName) ? 1 : ((b.return.GivenName > a.return.GivenName) ? -1 : 0));
    }

    if (this.lnameFilter) {
      this.filtredList = this.filtredList.filter(fn => fn.return.LastName.toUpperCase().startsWith(this.lnameFilter.toUpperCase())).sort((a, b) => (a.return.LastName > b.return.LastName) ? 1 : ((b.return.LastName > a.return.LastName) ? -1 : 0));
    }
  }

  GoToFileUpload() {
    this.selectedMembers = [];
    if (this.shareWithAdmins) {
      let selectedAdmins = this.adminList.filter(a => a.return.isChecked === true);
      if (selectedAdmins) {
        selectedAdmins.forEach(element => {
          const m = { uid: element.return.uid, name: element.return.LastName + ' ' + element.return.FirstName }
          this.selectedMembers.push(m);
        });
      }
    }

    if (this.shareWithMembers) {
      let members = this.filtredList.filter(a => a.return.isChecked === true)
      if (members) {
        members.forEach(element => {
          const m = { uid: element.return.uid, name: element.return.LastName + ' ' + element.return.GivenName }
          this.selectedMembers.push(m);
        });
      }
    }

    this.model.sharedWith = this.selectedMembers;
    this.step2SelectGroup = false;
    this.step3SelectMembers = false;
    this.step4uploadStep = true;
  }

  handleFileInput(files: FileList) {
    if (files) {
      this.file = files[0];
    }
  }

  shareFile() {
    if (this.file) {
      this.fsService.pushFileToStorage(this.model, this.file).subscribe(
        percentage => {
          this.showProgress = true;
          this.uploadProgerss = Math.round(percentage ? percentage : 0);
        },
        error => {
          console.log(error);
        },
        () => {
          this.showProgress = false;
          this.step4uploadStep = false;
          this.step5Success = true;
        }
      );
    }
  }

} 
