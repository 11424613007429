
export class IncomeSupportFamilyMemberModel {
  
    applicantIDOfHead: number;
    applicantIDOfChild: number;
    given_name: string;
    last_name: string; 
    dob: string;
    Relationship: string;
     edu_level: string; 
    application_no: number;
    family_comp_id: number;
    financial_contribution: number;

    care_required = '';
    child_care_level = -1;
    child_care_category = -1;
    apply_date = '';
    ChildHealthCareNo = '';
    ISNofChild = '';
    gender = '';
}
