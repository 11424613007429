<!--------------------------------------------------------- Introduction Page -------------------------------------------------------------->
<div class="row card white" *ngIf="step==0">

    <div class="center-align p-m-2">
        <h5><strong>
                APPLICATION FOR REGISTRATION ON THE INDIAN REGISTER AND
                FOR THE SECURE CERTIFICATE OF INDIAN STATUS (SCIS)
            </strong>
        </h5>
        <h6>(FOR ADULTS 16 YEARS OF AGE OR OLDER)</h6>
    </div>

    <div class="row card-content" style="text-align: justify;">
        <h5><strong>CHECKLIST</strong></h5>
        <ul>
            <li>
                <h6><strong>Application Form</strong></h6>
                <p><i class="tiny material-icons">fiber_manual_record</i>Section 7 of the Application must be signed and
                    dated.</p>
            </li>
            <hr />
            <li>
                <h6><strong>Birth Document</strong></h6>
                <p><i class="tiny material-icons">fiber_manual_record</i>Include an original birth certificate listing
                    the names of your parents. A photocopy is not acceptable.</p>
                <p><strong>Note: </strong>If you were adopted, you must provide an original birth certificate listing
                    the names of the your adoptive parent(s).</p>
            </li>
            <hr />
            <li>
                <h6><strong>Supporting Identity Documents</strong></h6>
                <p>Supporting identity documents must contain the following four (4) elements: full name, date of
                    birth, photo and signature.</p>
                <p><strong>Include with the application:</strong></p>
                <p><i class="tiny material-icons">fiber_manual_record</i>One (1) identity document that contains all the
                    elements listed above, OR</p>
                <p><i class="tiny material-icons">fiber_manual_record</i>More than one identity document that, combined
                    together, contain all the elements listed above, OR</p>
                <p><i class="tiny material-icons">fiber_manual_record</i>One (1) identity document that contains some
                    but not all the elements listed above and a Guarantor Declaration form.</p>
                <p><strong>You may include:</strong></p>
                <p><strong><i class="tiny material-icons">fiber_manual_record</i>Original</strong> identity documents
                    (recommended if submitting the application in person), OR
                </p>
                <p><strong><i class="tiny material-icons">fiber_manual_record</i>Photocopies</strong> of the supporting
                    identity documents and a <strong>Guarantor Declaration form </strong>The guarantor must sign and
                    date the photocopies of the front and back of the identity documents.
                </p>
            </li>
            <hr />
            <li>
                <h6><strong>Name Linking Documents(s)</strong></h6>
                <p>If you are applying under a name that is different than the name on your birth certificate, you must
                    provide a name-linking document, such
                    as a marriage certificate and a legal name change certificate.</p>
                <p><strong>If applicable, include with the application:</strong></p>
                <p><i class="tiny material-icons">fiber_manual_record</i>An <strong>Original</strong> name linking
                    document, OR</p>
                <p><strong><i class="tiny material-icons">fiber_manual_record</i>A Photocopy</strong> of the
                    name-linking document and a photocopy of a government-issued identity document that has your name as
                    it appears on the application form (for example, a driver's licence).
                </p>
            </li>
            <hr />
            <li>
                <h6><strong>Photos</strong></h6>
                <p><i class="tiny material-icons">fiber_manual_record</i>Include Canadian
                    passport-style photo. The name and address of the studio or person who took the photo,
                    and the date he photo was taken must be indicated. Photos are
                    required only if a Secure Certificate of Indian Status is requested.</p>
            </li>
            <hr />
            <li>
                <h6><strong>Adoption</strong></h6>
                <p><strong>If you were adopted as a child, include with the application: </strong></p>
                <p><i class="tiny material-icons">fiber_manual_record</i>A photocopy of the adoption order or photocopy
                    of the letter from the Social Services authorities confirming the details of the adoption: names of
                    the adoptive
                    parent(s), full name of adoptee as it appears on the adoption order, and date and place of adoption.
                </p>
                <p><i class="tiny material-icons">fiber_manual_record</i>A signed and dated consent form giving the
                    Indian Registrar permission to contact the Social Services
                    authorities for information on your birth ancestry. To obtain the consent form, call 1-800-567-9604.
                </p>
                <p><i class="tiny material-icons">fiber_manual_record</i>A photocopy of your pre-adoption birth
                    certificate (optional, if available)</p>
            </li>
        </ul>
    </div>

    <div class="row col s12">
        <button class="col s12 btn waves-effect green" type="button" style="margin-bottom: 15px" (click)="next()">Start
            Your Application
        </button>
    </div>
</div>

<!-------------------------------------------------------------------Applicaiton------------------------------------------->
<div class="row" *ngIf="step>0">
    <div *ngIf="bandList && currentUser">
        <div class="center-align">
            <h5><strong>SCIS APPLICATION</strong></h5>
            <p *ngIf="step <= 8"> Step {{step}} of 8</p>
        </div>
        <div class="row col s12">
            <div class="col l1"></div>

            <div class="col s12 l10">
                <!-------------------------------------Personal Information------------------------------------------->
                <form [formGroup]="personalInfoForm" (ngSubmit)="submitPersonalInfo(personalInfoForm.value)"
                    *ngIf="step == 1">
                    <div class="row col s12 teal lighten-2 white-text">
                        <h5 class="center-align">SECTION 1: Personal Information</h5>
                    </div>

                    <div class="row col s12 card white">

                        <div class="input-field col s12 m6">
                            <i class="material-icons prefix green-text">person</i>
                            <input id="familyName" type="text" formControlName='familyName' class="validate"
                                autocomplete="off" style="text-transform: uppercase;" />
                            <label for="familyName">Family Name<span class="red-text">*</span></label>
                            <div *ngIf="(familyName.dirty || familyName.touched) && familyName.invalid">
                                <small *ngIf="familyName.errors.required" class="red-text">Family Name is
                                    Required.</small>
                            </div>
                        </div>

                        <div class="input-field col s12 m6">
                            <i class="material-icons prefix green-text">person</i>
                            <input id="givenName" type="text" formControlName='givenName' class="validate"
                                autocomplete="off" style="text-transform: uppercase;" />
                            <label for="givenName">Given Name<span class="red-text">*</span></label>
                            <div *ngIf="(givenName.dirty || givenName.touched) && givenName.invalid">
                                <small *ngIf="givenName.errors.required" class="red-text">First Name is
                                    Required.</small>
                            </div>
                        </div>

                        <div class="input-field col s12 m6">
                            <i class="material-icons prefix green-text">person</i>
                            <input id="birth_familyName" type="text" formControlName='birth_familyName' class="validate"
                                autocomplete="off" style="text-transform: uppercase;" />
                            <label for="birth_familyName">Family Name at Birth(if different)</label>
                        </div>

                        <div class="input-field col s12 m6">
                            <i class="material-icons prefix green-text">person</i>
                            <input id="aliasName" type="text" formControlName='aliasName' class="validate"
                                autocomplete="off" style="text-transform: uppercase;" />
                            <label for="aliasName">Alias Name/Cultural Name(if applicable)</label>
                        </div>

                        <div class="input-field col s12 m6">
                            <i class="material-icons prefix green-text">event</i>
                            <!-- <input id="dob" type="text" formControlName='dob' class="validate"
                                [textMask]="{mask: dateMask, guide: true}" autocomplete="off"
                                style="text-transform: capitalize;" /> -->
                                <input id="dob" type="text" formControlName='dob' class="validate"
                                autocomplete="off"
                                style="text-transform: capitalize;" />
                            <label for="dob">Date of Birth (YYYY/MM/DD)<span class="red-text">*</span></label>
                            <div *ngIf="(dob.dirty || dob.touched) && dob.invalid">
                                <small *ngIf="dob.errors.required" class="red-text"> The Date Of Birth is required.
                                </small>
                                <small *ngIf="dob.errors.invalidDate" class="red-text"> The Date is invalid. </small>
                            </div>
                        </div>

                        <div class="input-field col s12 m6">
                            <div class="row col s12">
                                <i class="material-icons green-text left">wc</i><span>Gender<span
                                        class="red-text">*</span></span>
                            </div>
                            <div class="row col s12">
                                <label class="col s4">
                                    <input name="gender" type="radio" formControlName="gender" value="M" />
                                    <span>Male</span>
                                </label>
                                <label class="col s4">
                                    <input name="gender" type="radio" formControlName="gender" value="F" />
                                    <span>Female</span>
                                </label>
                                <label class="col s4">
                                    <input name="gender" type="radio" formControlName="gender" value="X" />
                                    <span>Another Gender</span>
                                </label>
                            </div>
                        </div>

                        <h6 class="row col s12"><strong>Permanent Address</strong></h6>

                        <div class="input-field col s12 m6">
                            <i class="material-icons prefix green-text">place</i>
                            <input id="number" type="number" formControlName='number' class="validate"
                                autocomplete="off" />
                            <label for="number">Number<span class="red-text">*</span></label>
                            <div *ngIf="(number.dirty || number.touched) && number.invalid">
                                <small *ngIf="number.errors.required" class="red-text">Number is Required.</small>
                            </div>
                        </div>

                        <div class="input-field col s12 m6">
                            <i class="material-icons prefix green-text">traffic</i>
                            <input id="street" type="text" formControlName='street' class="validate" autocomplete="off"
                                style="text-transform: uppercase;" />
                            <label for="street">Street<span class="red-text">*</span></label>
                            <div *ngIf="(street.dirty || street.touched) && street.invalid">
                                <small *ngIf="street.errors.required" class="red-text">Street Name is Required.</small>
                            </div>
                        </div>

                        <div class="input-field col s12 m6">
                            <i class="material-icons prefix green-text">apartment</i>
                            <input id="apartment" type="text" formControlName='apartment' class="validate"
                                autocomplete="off" style="text-transform: capitalize;" />
                            <label for="apartment">Apartment</label>
                        </div>

                        <div class="input-field col s12 m6">
                            <i class="material-icons prefix green-text">markunread_mailbox</i>
                            <input id="POBox" type="text" formControlName='POBox' class="validate" autocomplete="off"
                                style="text-transform: uppercase;" />
                            <label for="POBox">P.O.Box</label>
                        </div>

                        <div class="input-field col s12 m6">
                            <i class="material-icons prefix green-text">location_city</i>
                            <input id="city" type="text" formControlName='city' class="validate" autocomplete="off"
                                style="text-transform: uppercase;" />
                            <label for="city">City/Town<span class="red-text">*</span></label>
                            <div *ngIf="(city.dirty || city.touched) && city.invalid">
                                <small *ngIf="city.errors.required" class="red-text">City is Required.</small>
                            </div>
                        </div>

                        <div class="input-field col s12 m6" *ngIf="country == 'CA'">
                            <i class="material-icons prefix green-text">add_location</i>
                            <select formControlName="province_state" class="validate">
                                <option value="" disabled selected>Select</option>
                                <option *ngFor="let t of provinces" [value]="t.code">{{t.name}}</option>
                            </select>
                            <label for="province_state">Province/Territory(Canada)<span
                                    class="red-text">*</span></label>
                        </div>

                        <div class="input-field col s12 m6" *ngIf="country == 'USA'">
                            <i class="material-icons prefix green-text">add_location</i>
                            <select formControlName="province_state" class="validate">
                                <option value="" disabled selected>Select</option>
                                <option *ngFor="let t of state" [value]="t.code">{{t.name}}</option>
                            </select>
                            <label for="province_state">State(USA)<span class="red-text">*</span></label>
                        </div>

                        <div class="input-field col s12 m6">
                            <i class="material-icons prefix green-text">mail</i>
                            <!-- <input id="postalCode" [textMask]="{mask: postalCodeMask, guide: true}" type="text"
                                formControlName="postalCode" class="validate" style='text-transform:uppercase'> -->
                                <input id="postalCode"  type="text"
                                formControlName="postalCode" class="validate" style='text-transform:uppercase'>
                            <label for="postalCode">Postal/ZIP Code<span class="red-text">*</span></label>
                            <div *ngIf="(postalCode.dirty || postalCode.touched) && postalCode.invalid">
                                <small *ngIf="postalCode.errors.required" class="red-text"> The Postal Code is required.
                                </small>
                            </div>
                        </div>

                        <div class="input-field col s12 m6">
                            <i class="material-icons prefix green-text">mail</i>
                            <input id="email" type="text" formControlName="email" class="validate"
                                style='text-transform:uppercase'>
                            <label for="email">Email<span class="red-text">*</span></label>
                            <div *ngIf="(email.dirty || email.touched) && email.invalid">
                                <small *ngIf="email.errors.required" class="red-text">Email is Required.</small>
                                <small *ngIf="email.errors.email" class="red-text">Invalid Email Address</small>
                            </div>
                        </div>

                        <div class="input-field col s12 m6">
                            <i class="material-icons prefix green-text">phone</i>
                            <!-- <input id="telephone" [textMask]="{mask: phoneNumberMask, guide: true}" type="text"
                                formControlName="telephone" class="validate"> -->
                                <input id="telephone"  type="text"
                                formControlName="telephone" class="validate">
                            <label for="telephone">Telephone Number(Daytime)<span class="red-text">*</span></label>
                            <div *ngIf="(telephone.dirty || telephone.touched) && telephone.invalid">
                                <small *ngIf="telephone.errors.required" class="red-text">Telephone Number is
                                    Required.</small>
                            </div>
                        </div>

                        <div class="input-field col s12 m6">
                            <i class="material-icons prefix green-text">phone</i>
                            <input id="telephone2" [textMask]="{mask: phoneNumberMask, guide: true}" type="text"
                                formControlName="telephone2" class="validate">
                            <label for="telephone2">Telephone Number(Other)</label>
                        </div>

                        <div class="row input-field col s12">
                            <div class="row col s12">Mailing Address same as Permanent Address ? <span
                                    class="red-text">*</span>
                            </div>
                            <div class="row col s12">
                                <label class="col s6">
                                    <input class="with-gap" formControlName="sameMailingAddress"
                                        name="sameMailingAddress" type="radio" [value]="true"
                                        (change)="onSameMailingAddressYes($event)" />
                                    <span>Yes</span>
                                </label>
                                <label class="col s6">
                                    <input class="with-gap" formControlName="sameMailingAddress"
                                        name="sameMailingAddress" type="radio" [value]="false"
                                        (change)="onSameMailingAddressNo($event)" />
                                    <span>No</span>
                                </label>
                            </div>
                        </div>

                        <div *ngIf="showMailingAddress">
                            <h6 class="row col s12"><strong>Mailing Address</strong></h6>

                            <div class="input-field col s12 m6">
                                <i class="material-icons prefix green-text">place</i>
                                <input id="mailing_number" type="number" formControlName='mailing_number'
                                    class="validate" autocomplete="off" style="text-transform: capitalize;" />
                                <label for="mailing_number">Number<span class="red-text">*</span></label>
                                <div *ngIf="(mailing_number.dirty || mailing_number.touched) && mailing_number.invalid">
                                    <small *ngIf="mailing_number.errors.required" class="red-text">Number is
                                        Required.</small>
                                </div>
                            </div>

                            <div class="input-field col s12 m6">
                                <i class="material-icons prefix green-text">traffic</i>
                                <input id="mailing_street" type="text" formControlName='mailing_street' class="validate"
                                    autocomplete="off" style="text-transform: capitalize;" />
                                <label for="mailing_street">Street<span class="red-text">*</span></label>
                                <div *ngIf="(mailing_street.dirty || mailing_street.touched) && mailing_street.invalid">
                                    <small *ngIf="mailing_street.errors.required" class="red-text">Street is
                                        Required.</small>
                                </div>
                            </div>

                            <div class="input-field col s12 m6">
                                <i class="material-icons prefix green-text">apartment</i>
                                <input id="mailing_apartment" type="text" formControlName='mailing_apartment'
                                    class="validate" autocomplete="off" style="text-transform: capitalize;" />
                                <label for="mailing_apartment">Apartment</label>
                            </div>

                            <div class="input-field col s12 m6">
                                <i class="material-icons prefix green-text">markunread_mailbox</i>
                                <input id="mailing_POBox" type="text" formControlName='mailing_POBox' class="validate"
                                    autocomplete="off" style="text-transform: capitalize;" />
                                <label for="mailing_POBox">P.O.Box</label>
                            </div>

                            <div class="input-field col s12 m6">
                                <i class="material-icons prefix green-text">location_city</i>
                                <input id="mailing_city" type="text" formControlName='mailing_city' class="validate"
                                    autocomplete="off" style="text-transform: capitalize;" />
                                <label for="mailing_city">City/Town<span class="red-text">*</span></label>
                                <div *ngIf="(mailing_city.dirty || mailing_city.touched) && mailing_city.invalid">
                                    <small *ngIf="mailing_city.errors.required" class="red-text">City is
                                        Required.</small>
                                </div>
                            </div>

                            <div class="input-field col s12 m6" *ngIf="country == 'CA'">
                                <i class="material-icons prefix green-text">add_location</i>
                                <select formControlName="mailing_province_state" class="validate">
                                    <option value="" disabled selected>Select</option>
                                    <option *ngFor="let t of provinces" [value]="t.code">{{t.name}}</option>
                                </select>
                                <label for="mailing_province_state">Province/Territory(Canada)<span
                                        class="red-text">*</span></label>
                            </div>

                            <div class="input-field col s12 m6" *ngIf="country == 'USA'">
                                <i class="material-icons prefix green-text">add_location</i>
                                <select formControlName="mailing_province_state" class="validate">
                                    <option value="" disabled selected>Select</option>
                                    <option *ngFor="let t of state" [value]="t.code">{{t.name}}</option>
                                </select>
                                <label for="mailing_province_state">State(USA)<span class="red-text">*</span></label>
                            </div>

                            <div class="input-field col s12 m6">
                                <i class="material-icons prefix green-text">mail</i>
                                <!-- <input id="mailing_postalCode" [textMask]="{mask: postalCodeMask, guide: true}"
                                    type="text" formControlName="mailing_postalCode" class="validate"
                                    style='text-transform:uppercase'> -->
                                    <input id="mailing_postalCode" 
                                    type="text" formControlName="mailing_postalCode" class="validate"
                                    style='text-transform:uppercase'>
                                <label for="mailing_postalCode">Postal/ZIP Code<span class="red-text">*</span></label>
                                <div
                                    *ngIf="(mailing_postalCode.dirty || mailing_postalCode.touched) && mailing_postalCode.invalid">
                                    <small *ngIf="mailing_postalCode.errors.required" class="red-text"> The Postal Code
                                        is required.
                                    </small>
                                </div>
                            </div>

                        </div>
                    </div>

                    <button class="col s12 m5 l3 btn left waves-effect green" type="button" style="margin-bottom: 15px"
                        (click)="previous()"><i class="material-icons left">rule</i>Instructions
                    </button>

                    <!-- [disabled]="!personalInfoForm.valid"  -->
                    <button class="col s12 m5 offset-m2 l3 btn right waves-effect blue" type="submit"
                        [disabled]="!personalInfoForm.valid" style="margin-bottom: 15px">Next <i
                            class="material-icons right">arrow_forward</i></button>
                </form>

                <!-------------------------------------Band Choice Information------------------------------------------->
                <form [formGroup]="firstNationInfoForm" (ngSubmit)="submitFirstNationInfo(firstNationInfoForm.value)"
                    *ngIf="step == 2">
                    <div class="row col s12 teal lighten-2 white-text">
                        <h5 class="center-align">SECTION 2: First Nation/Band Choice</h5>
                    </div>

                    <div class="row col s12 card white">

                        <div class="input-field col s12">
                            <i class="material-icons prefix green-text">tag</i>
                            <!-- <input id="bandNumber" type="text" formControlName='bandNumber' class="validate"
                                autocomplete="off" style="text-transform: capitalize;"
                                [textMask]="{mask: treatyNoMask, guide: false}"
                                (keyup)="onBandNoChange($event.target.value,bandName)" /> -->
                                <input id="bandNumber" type="text" formControlName='bandNumber' class="validate"
                                autocomplete="off" style="text-transform: capitalize;"
                                (keyup)="onBandNoChange($event.target.value,bandName)" />
                            <label for="bandNumber">First Nation/Band Number(3 digit)<span
                                    class="red-text">*</span></label>
                            <div *ngIf="(bandNumber.dirty || bandNumber.touched) && bandNumber.invalid">
                                <small *ngIf="bandNumber.errors.required" class="red-text">First Nation/Band Number is
                                    Required.</small>
                            </div>
                        </div>

                        <div class="input-field col s12">
                            <i class="material-icons prefix green-text">label</i>
                            <input id="bandName" type="text" formControlName='bandName' class="validate"
                                autocomplete="off" style="text-transform: capitalize;" />
                            <label for="bandName">First Nation/Band Name<span class="red-text">*</span></label>
                            <div *ngIf="(bandName.dirty || bandName.touched) && bandName.invalid">
                                <small *ngIf="bandName.errors.required" class="red-text">First Nation/Band Name is
                                    Required.</small>
                            </div>
                        </div>
                    </div>

                    <button class="col s12 m5 l3 btn left waves-effect blue" type="button" style="margin-bottom: 15px"
                        (click)="previous()"><i class="material-icons left">arrow_back</i>Back
                    </button>

                    <!-- [disabled]="!firstNationInfoForm.valid" -->
                    <button class="col s12 m5 offset-m2 l3 btn right waves-effect blue" type="submit"
                        [disabled]="!firstNationInfoForm.valid" style="margin-bottom: 15px">Next <i
                            class="material-icons right">arrow_forward</i></button>
                </form>

                <!-------------------------------------Document Requirements------------------------------------------->
                <form [formGroup]="documentsForm" (ngSubmit)="submitdocuments(documentsForm.value)" *ngIf="step == 3">
                    <div class="row col s12 teal lighten-2 white-text">
                        <h5 class="center-align">SECTION 3: Document Requirements</h5>
                    </div>

                    <div class="row col s12 card white" formGroupName="proofOfBirth">

                        <h6 class="row col s12 m6 card-title"><strong>Proof of Birth Document</strong></h6>

                        <div class="input-field col s12">
                            <i class="material-icons prefix green-text">person</i>
                            <!-- <input id="registrationNo" type="text" formControlName='registrationNo' class="validate"
                                autocomplete="off" [textMask]="{mask: registrationNoMask, guide: true}"
                                (keyup)="treatNoChange($event.target.value, issuance_province_state)" /> -->
                                <input id="registrationNo" type="text" formControlName='registrationNo' class="validate"
                                autocomplete="off" 
                                (keyup)="treatNoChange($event.target.value, issuance_province_state)" />
                            <label for="registrationNo">Registration Number of Birth Document<span
                                    class="red-text">*</span></label>
                            <div *ngIf="(registrationNo.dirty || registrationNo.touched) && registrationNo.invalid">
                                <small *ngIf="registrationNo.errors.required" class="red-text">Birth Document
                                    Regisration Number is Required.</small>
                                <small *ngIf="registrationNo.errors.pattern" class="red-text">Invalid Registration
                                    Number</small>
                                <small *ngIf="registrationNo.errors.minlength || registrationNo.errors.maxlength"
                                    class="red-text">Registration Number must be 10 Digits.</small>
                            </div>
                        </div>

                        <div class="input-field col s12" *ngIf="country == 'CA'">
                            <i class="material-icons prefix green-text">add_location</i>
                            <select formControlName="province_state" class="validate">
                                <option value="" disabled selected>Select</option>
                                <option *ngFor="let t of provinces" [value]="t.code">{{t.name}}</option>
                            </select>
                            <label for="province_state">Province/Territory Of Issuance<span
                                    class="red-text">*</span></label>
                        </div>

                        <div class="row col s12" *ngIf="proofOfBirth">
                            <h6><strong>Attached File</strong></h6>

                            <div class="row col s12 grey lighten-3"
                                style="padding-top: 5px; padding-bottom: 5px; cursor: pointer;">
                                <div class="col s10">

                                    <div style="font-size:medium;">
                                        <i *ngIf="proofOfBirth.file_type == 'PDF'" class="material-icons red-text">
                                            picture_as_pdf
                                        </i>
                                        <i *ngIf="proofOfBirth.file_type == 'Image'" class="material-icons blue-text">
                                            image
                                        </i>
                                        <strong style="margin-left: 15px;"><a class="tooltipped" data-position="bottom"
                                                data-tooltip="View File" [href]="proofOfBirth.file_url"
                                                target="_blank">{{proofOfBirth.file_name}} </a></strong>
                                    </div>
                                </div>
                                <div class="col s2 right-align">
                                    <a (click)="removeFile('proofOfBirth',index)"><i class="material-icons red-text">
                                            delete
                                        </i></a>
                                </div>
                            </div>
                        </div>

                        <button type="button" id="attachFile" class="col s8 offset-s2 btn waves-effect green"
                            *ngIf="!proofOfBirth" style="margin-bottom: 15px"
                            [disabled]="!documentsForm.get('proofOfBirth').valid"
                            (click)="attachFiles('proofOfBirth')">Browse Documents<i
                                class="material-icons right">attach_file</i></button>
                        <input class="hide" type="file" name="proofOfBirth" id="proofOfBirth" accept="image/*"
                            (change)="uploadProofOfBirth($event.target.files, documentsForm.get('proofOfBirth').value)">
                    </div>

                    <div class="row col s12 card white" formGroupName="nameLinkingDoc">
                        <h6 class="row col s12 card-title left"><strong>Name Linking Document(s)</strong></h6>
                        <p><i class="material-icons amber-text small"> lightbulb </i><strong> &nbsp; Provide if the name
                                used on this application is different than the name listed on your proof of birth
                                document</strong></p>

                        <h6 class="row col s12">Do you wish to include Name linking Documnet(s)?</h6>
                        <div class="row col s12">
                            <label class="col s6">
                                <input class="with-gap" name="uploadDoc" formControlName="uploadDoc" type="radio"
                                    [value]="true" [checked]="uploadNameDoc" (change)="uploadNameDocYes($event)" />
                                <span>Yes</span>
                            </label>
                            <label class="col s6">
                                <input class="with-gap" name="uploadDoc" formControlName="uploadDoc" type="radio"
                                    [value]="false"
                                    [checked]="uploadNameDoc != undefined && !uploadNameDoc && nameLinkingDocs.length == 0"
                                    (change)="uploadNameDocNo($event)" />
                                <span>No</span>
                            </label>
                        </div>

                        <div class="row col s12" *ngIf="nameLinkingDocs?.length >0">
                            <h6><strong>Attached Files ({{nameLinkingDocs.length}})</strong></h6>

                            <div class="row col s12 grey lighten-3" *ngFor="let file of nameLinkingDocs;let i=index"
                                style="padding-top: 5px; padding-bottom: 5px; cursor: pointer;">
                                <div class="col s12">
                                    <p class="col s12 m6"><strong>Name: </strong>{{file.documentName}}</p>
                                    <p class="col s12 m6"><strong>Documnet Type: </strong>{{file.documentType}}</p>
                                </div>
                                <div class="col s10">
                                    <div class="col s12">
                                        <a style="font-size:medium;">
                                            <i *ngIf="file.file_type == 'PDF'" class="material-icons red-text">
                                                picture_as_pdf
                                            </i>
                                            <i *ngIf="file.file_type == 'Image'" class="material-icons red-text">
                                                image
                                            </i>
                                            <strong style="margin-left: 15px;">{{file.file_name}}</strong>
                                        </a>
                                    </div>
                                </div>
                                <div class="col s2 right-align">
                                    <a (click)="removeFile('nameLinkingDoc',i)"><i class="material-icons red-text">
                                            delete
                                        </i></a>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="uploadNameDoc">
                            <span class="material-icons right red-text" (click)="closeAddNameDoc()"
                                style="cursor: pointer;margin-right: 25px;">close</span>

                            <!-- <p>(Provide if the name used on this application is different than the name listed on your
                                proof of birth document)</p> -->

                            <div class="input-field col s12">
                                <i class="material-icons prefix green-text">label</i>
                                <input id="documentName" type="text" formControlName="documentName" class="validate"
                                    style="text-transform: uppercase;">
                                <label for="documentName">Name(exactly as it appears on the document)<span
                                        class="red-text">*</span></label>
                                <div
                                    *ngIf="(nameLinking_documentName.dirty || nameLinking_documentName.touched) && nameLinking_documentName.invalid">
                                    <small *ngIf="nameLinking_documentName.errors.required" class="red-text"> Name is
                                        Required.</small>
                                </div>
                            </div>

                            <div class="input-field col s12">
                                <i class="material-icons prefix green-text">title</i>
                                <input id="documentType" type="text" formControlName="documentType" class="validate"
                                    style="text-transform: uppercase;">
                                <label for="documentType">Document Type<span class="red-text">*</span></label>
                                <div
                                    *ngIf="(nameLinking_documentType.dirty || nameLinking_documentType.touched) && nameLinking_documentType.invalid">
                                    <small *ngIf="nameLinking_documentType.errors.required" class="red-text"> Document
                                        Type is Required.</small>
                                </div>
                            </div>

                            <button type="button" class="col s8 offset-s2 btn waves-effect green"
                                [disabled]="!documentsForm.get('nameLinkingDoc').valid" style="margin-bottom: 15px"
                                (click)="attachFiles('nameLinkingDoc')">Browse Documents<i
                                    class="material-icons right">attach_file</i></button>
                            <input class="hide" type="file" name="nameLinkingDoc" id="nameLinkingDoc" accept="image/*"
                                (change)="uploadNameLinkingDoc($event.target.files, documentsForm.get('nameLinkingDoc').value )">

                        </div>
                    </div>

                    <div class="row col s12 card white" formGroupName="supportingIdentityDocs">
                        <h6 class="row col s12 card-title"><strong>Supporting Identity Document(s)</strong></h6>

                        <h6 class="row col s12">Do you wish to include any other Supporting documents to this
                            application?
                        </h6>
                        <div class="row col s12">
                            <label class="col s6">
                                <input class="with-gap" name="uploadSupportingDocs"
                                    formControlName="uploadSupportingDocs" type="radio" [value]="true"
                                    [checked]="uploadSupportingDocs" (change)="uploadSupportingDocYes($event)" />
                                <span>Yes</span>
                            </label>
                            <label class="col s6">
                                <input class="with-gap" name="uploadSupportingDocs"
                                    formControlName="uploadSupportingDocs" type="radio" [value]="false"
                                    [checked]="uploadSupportingDocs != undefined  && !uploadSupportingDocs && supportingDocs.length == 0 "
                                    (change)="uploadSupportingDocNo($event)" />
                                <span>No</span>
                            </label>
                        </div>

                        <div class="row col s12" *ngIf="supportingDocs?.length >0">
                            <h6><strong>Attached Files ({{supportingDocs.length}})</strong></h6>

                            <div class="row col s12 grey lighten-3" *ngFor="let file of supportingDocs;let i=index"
                                style="padding-top: 5px; padding-bottom: 5px; cursor: pointer;">
                                <div class="col s12">

                                    <p class="col s12 m6"><strong>Documnet Type: </strong>{{file.documentType}}</p>
                                    <p class="col s12 m6"><strong>Document Name: </strong>{{file.documentName}}</p>
                                    <p class="col s12 m6"><strong>Expiry Date: </strong>{{file.expiryDate}}</p>
                                </div>
                                <div class="col s10">
                                    <div class="col s12">
                                        <a style="font-size:medium;">
                                            <i *ngIf="file.file_type == 'PDF'" class="material-icons red-text">
                                                picture_as_pdf
                                            </i>
                                            <i *ngIf="file.file_type == 'Image'" class="material-icons red-text">
                                                image
                                            </i>
                                            <strong style="margin-left: 15px;">{{file.file_name}}</strong>
                                        </a>
                                    </div>
                                </div>
                                <div class="col s2 right-align">
                                    <a (click)="removeFile('supportingDoc',i)"><i class="material-icons red-text">
                                            delete
                                        </i></a>
                                </div>
                            </div>
                        </div>

                        <div *ngIf="uploadSupportingDocs">
                            <span class="material-icons right red-text" (click)="closeAddSupportingDoc()"
                                style="cursor: pointer;margin-right: 25px;">close</span>


                            <h6 class="row col s12 card-title left"><strong>Supporting Identity Document(s)</strong>
                            </h6>

                            <div class="input-field col s12">
                                <i class="material-icons prefix green-text">label</i>
                                <input id="documentName" type="text" formControlName="documentName" class="validate"
                                    style="text-transform: uppercase;">
                                <label for="documentName">Name(exactly as it appears on the document)<span
                                        class="red-text">*</span></label>
                                <div
                                    *ngIf="(supporting_documentName.dirty || supporting_documentName.touched) && supporting_documentName.invalid">
                                    <small *ngIf="supporting_documentName.errors.required" class="red-text"> Name of
                                        is Required.</small>
                                </div>
                            </div>

                            <div class="input-field col s12">
                                <i class="material-icons prefix green-text">title</i>
                                <input id="documentType" type="text" formControlName="documentType" class="validate"
                                    style="text-transform: uppercase;">
                                <label for="documentType">Document Type<span class="red-text">*</span></label>
                                <div
                                    *ngIf="(supporting_documentType.dirty || supporting_documentType.touched) && supporting_documentType.invalid">
                                    <small *ngIf="supporting_documentType.errors.required" class="red-text"> Document
                                        Type is Required.</small>
                                </div>
                            </div>

                            <div class="input-field col s12">
                                <i class="material-icons prefix green-text">label</i>
                                <input id="documentNumber" type="number" formControlName="documentNumber"
                                    class="validate">
                                <label for="documentNumber">Document Number<span class="red-text">*</span></label>
                                <div
                                    *ngIf="(supporting_documentNumber.dirty || supporting_documentNumber.touched) && supporting_documentNumber.invalid">
                                    <small *ngIf="supporting_documentNumber.errors.required" class="red-text">Document
                                        Number is Required.</small>
                                </div>
                            </div>

                            <div class="input-field col s12">
                                <i class="material-icons prefix green-text">label</i>
                                <!-- <input id="expiryDate" type="text" formControlName="expiryDate" class="validate"
                                    [textMask]="{mask: dateMask, guide: true}" placeholder="YYYYMMDD"> -->
                                    <input id="expiryDate" type="text" formControlName="expiryDate" class="validate"
                                    placeholder="YYYYMMDD">
                                <label for="expiryDate">Expiry Date (YYYYMMDD) if applicable</label>
                                <div
                                    *ngIf="(supporting_documentExpiryDate.dirty || supporting_documentExpiryDate.touched) && supporting_documentExpiryDate.invalid">
                                    <small *ngIf="supporting_documentExpiryDate.errors.pattern" class="red-text">Invalid
                                        Date</small>
                                </div>
                            </div>

                            <button type="button" class="col s8 offset-s2 btn waves-effect green"
                                [disabled]="!documentsForm.get('supportingIdentityDocs').valid"
                                style="margin-bottom: 15px" (click)="attachFiles('supportingDoc')">Browse Documents<i
                                    class="material-icons right">attach_file</i></button>
                            <input class="hide" type="file" name="supportingDoc" id="supportingDoc" accept="image/*"
                                (change)="uploadSupportingDoc($event.target.files, documentsForm.get('supportingIdentityDocs').value )">

                        </div>
                    </div>

                    <button class="col s12 m5 l3 btn left waves-effect blue" type="button" style="margin-bottom: 15px"
                        (click)="previous()"><i class="material-icons left">arrow_back</i>Back
                    </button>

                    <!-- [disabled]="!documentsForm.valid" -->
                    <button class="col s12 m5 offset-m2 l3 btn right waves-effect blue" type="submit"
                        [disabled]="!documentsForm.valid" style="margin-bottom: 15px">Next <i
                            class="material-icons right">arrow_forward</i></button>

                    <!-- <p class="row col s12">Valid: {{documentsForm.valid && proofOfBirth}}</p> -->

                </form>

                <!-------------------------------------Adoption Information------------------------------------------->
                <form [formGroup]="adoptionInfoForm" (ngSubmit)="submitAdoptionInfo(adoptionInfoForm.value)"
                    *ngIf="step == 4">

                    <div class="row col s12 teal lighten-2 white-text">
                        <h5 class="center-align">SECTION 4: Adoption Information</h5>
                    </div>

                    <div class="row col s12 card white">
                        <div class="input-field col s12">
                            <h6><strong>Are You Adopted</strong><span class="red-text">*</span></h6>
                            <br />
                            <div class="row col s12">
                                <label class="col s6">
                                    <input class="with-gap" name="adopted" formControlName="adopted" type="radio"
                                        [value]="true" (change)="adoptedYes($event)" />
                                    <span>Yes</span>
                                </label>
                                <label class="col s6">
                                    <input class="with-gap" name="adopted" formControlName="adopted" type="radio"
                                        [value]="false" (change)="adoptedNo($event)" />
                                    <span>No</span>
                                </label>
                            </div>
                        </div>

                        <div *ngIf="isAdopted">
                            <p><i class="material-icons amber-text small">lightbulb </i><strong> &nbsp; Complete this
                                    section ONLY if you were adopted as a child.
                                    I was of birth document</strong></p>
                            <div formGroupName="ISNEntitlement">
                                <div class="input-field col s12">
                                    <p>I was adopted as a child, and I believe that I have entitlement to Indian status
                                        through (select all that apply)
                                        <span class="red-text">*</span>
                                    </p>
                                    <div class="row col s12">
                                        <label class="col m3 s6 ">
                                            <input name="adoptiveMother" type="checkbox"
                                                formControlName="adoptiveMother" [value]="true" />
                                            <span>Adoptive Mother</span>
                                        </label>
                                        <label class="col m3 s6">
                                            <input name="adoptiveFather" type="checkbox"
                                                formControlName="adoptiveFather" [value]="true" />
                                            <span>Adoptive Father</span>
                                        </label>
                                        <label class="col m3 s6">
                                            <input name="birthMother" type="checkbox" formControlName="birthMother"
                                                [value]="true" />
                                            <span>My Birth Mother</span>
                                        </label>
                                        <label class="col m3 s6">
                                            <input name="birthFather" type="checkbox" formControlName="birthFather"
                                                [value]="true" />
                                            <span>My Birth Mother</span>
                                        </label>

                                    </div>
                                </div>

                            </div>
                            <div formGroupName="adoptiveMother">
                                <h6 class="row col s12"><strong>Adoptive Mother</strong></h6>
                                <div class="input-field col s12 m6">
                                    <i class="material-icons prefix blue-text">person</i>
                                    <input id="familyName" type="text" formControlName='familyName' class="validate"
                                        autocomplete="off" style="text-transform: capitalize;" />
                                    <label for="familyName">Family Name</label>
                                </div>

                                <div class="input-field col s12 m6">
                                    <i class="material-icons prefix blue-text">person</i>
                                    <input id="givenName" type="text" formControlName='givenName' class="validate"
                                        autocomplete="off" style="text-transform: capitalize;" />
                                    <label for="givenName">Given Name</label>

                                </div>
                                <div class="input-field col s12">
                                    <i class="material-icons prefix green-text">event</i>
                                    <!-- <input id="dob" type="text" formControlName='dob' class="validate"
                                        [textMask]="{mask: dateMask, guide: true}" autocomplete="off"
                                        style="text-transform: capitalize;" /> -->
                                        <input id="dob" type="text" formControlName='dob' class="validate"
                                         autocomplete="off"
                                        style="text-transform: capitalize;" />
                                    <label for="dob">Date of Birth (YYYY/MM/DD)</label>
                                    <div
                                        *ngIf="(adoptiveMother.get('dob').dirty || adoptiveMother.get('dob').touched) && adoptiveMother.get('dob').invalid">
                                        <small *ngIf="adoptiveMother.get('dob').errors.invalidDate" class="red-text">The
                                            Date is invalid.</small>
                                    </div>
                                </div>
                            </div>

                            <div formGroupName="adoptiveFather">
                                <h6 class="row col s12"><strong>Adoptive Father</strong></h6>

                                <div class="input-field col s12 m6">
                                    <i class="material-icons prefix blue-text">person</i>
                                    <input id="familyName" type="text" formControlName='familyName' class="validate"
                                        autocomplete="off" style="text-transform: capitalize;" />
                                    <label for="familyName">Family Name</label>
                                </div>

                                <div class="input-field col s12 m6">
                                    <i class="material-icons prefix blue-text">person</i>
                                    <input id="givenName" type="text" formControlName='givenName' class="validate"
                                        autocomplete="off" style="text-transform: capitalize;" />
                                    <label for="givenName">Given Name</label>
                                </div>

                                <div class="input-field col s12">
                                    <i class="material-icons prefix green-text">event</i>
                                    <!-- <input id="dob" type="text" formControlName='dob' class="validate"
                                        [textMask]="{mask: dateMask, guide: true}" autocomplete="off"
                                        style="text-transform: capitalize;" /> -->
                                        <input id="dob" type="text" formControlName='dob' class="validate"
                                         autocomplete="off"
                                        style="text-transform: capitalize;" />
                                    <label for="dob">Date of Birth (YYYYMMDD)</label>
                                    <div
                                        *ngIf="(adoptiveFather.get('dob').dirty || adoptiveFather.get('dob').touched) && adoptiveFather.get('dob').invalid">
                                        <small *ngIf="adoptiveFather.get('dob').errors.invalidDate" class="red-text">The
                                            Date is invalid.</small>
                                    </div>
                                </div>
                            </div>

                            <div formGroupName="birthMother">
                                <h6 class="row col s12"><strong>Birth Mother(if Known)</strong></h6>

                                <div class="input-field col s12 m6">
                                    <i class="material-icons prefix green-text">person</i>
                                    <input id="familyName" type="text" formControlName='familyName' class="validate"
                                        autocomplete="off" style="text-transform: capitalize;" />
                                    <label for="familyName">Family Name</label>
                                </div>

                                <div class="input-field col s12 m6">
                                    <i class="material-icons prefix green-text">person</i>
                                    <input id="givenName" type="text" formControlName='givenName' class="validate"
                                        autocomplete="off" style="text-transform: capitalize;" />
                                    <label for="givenName">Given Name</label>
                                </div>
                            </div>

                            <div formGroupName="birthFather">
                                <h6 class="row col s12"><strong>Birth Father(if Known)</strong></h6>
                                <div class="input-field col s12 m6">
                                    <i class="material-icons prefix green-text">person</i>
                                    <input id="familyName" type="text" formControlName='familyName' class="validate"
                                        autocomplete="off" style="text-transform: capitalize;" />
                                    <label for="familyName">Family Name</label>
                                </div>

                                <div class="input-field col s12 m6">
                                    <i class="material-icons prefix green-text">person</i>
                                    <input id="givenName" type="text" formControlName='givenName' class="validate"
                                        autocomplete="off" style="text-transform: capitalize;" />
                                    <label for="givenName">Given Name</label>
                                </div>
                            </div>
                        </div>
                    </div>

                    <button class="col s12 m5 l3 btn left waves-effect blue" type="button" style="margin-bottom: 15px"
                        (click)="previous()"><i class="material-icons left">arrow_back</i>Back
                    </button>

                    <button class="col s12 m5 offset-m2 l3 btn right waves-effect blue" type="submit"
                        [disabled]="!adoptionInfoForm.valid" style="margin-bottom: 15px">Next <i
                            class="material-icons right">arrow_forward</i></button>
                </form>

                <!-------------------------------------Family Information------------------------------------------->
                <form [formGroup]="familyInfoForm" (ngSubmit)="submitFamilyInfo(familyInfoForm.value)"
                    *ngIf="step == 5">
                    <div class="row col s12 teal lighten-2 white-text">
                        <h5 class="center-align">SECTION 5: Family Information</h5>
                    </div>

                    <div class="row col s12 card white hoverable"
                        [ngClass]="{'invalidSection':!familyInfoForm.get('father').valid}">

                        <div class="row col s12" (click)="expand('A')">
                            <h6><strong>A. Father</strong></h6>
                        </div>

                        <div formGroupName="father" *ngIf="expandSection.A">
                            <div class="row col s12">
                                <p>If you believe that you are entitled to Indian status through your adoptive
                                    father, complete this section with your adoptive fathers’ information.</p>
                                <p>If you believe that you are entitled to Indian status through your birth father,
                                    complete
                                    this section with your
                                    birth fathers’ information, if available.</p>
                            </div>

                            <div class="input-field col s12 m6">
                                <i class="material-icons prefix green-text">person</i>
                                <input id="familyName" type="text" formControlName='familyName' class="validate"
                                    autocomplete="off" style="text-transform: uppercase;" />
                                <label for="familyName">Family Name<span class="red-text">*</span></label>
                            </div>

                            <div class="input-field col s12 m6">
                                <i class="material-icons prefix green-text">person</i>
                                <input id="birth_familyName" type="text" formControlName='birth_familyName'
                                    class="validate" autocomplete="off" style="text-transform: uppercase;" />
                                <label for="birth_familyName">Family Name at Birth(if different)</label>
                            </div>

                            <div class="input-field col s12 m6">
                                <i class="material-icons prefix green-text">person</i>
                                <input id="givenName" type="text" formControlName='givenName' class="validate"
                                    autocomplete="off" style="text-transform: uppercase;" />
                                <label for="givenName">Given Name<span class="red-text">*</span></label>
                            </div>

                            <div class="input-field col s12 m6">
                                <i class="material-icons prefix green-text">event</i>
                                <!-- <input id="dob" type="text" formControlName='dob' class="validate"
                                    [textMask]="{mask: dateMask, guide: true}" autocomplete="off"
                                    style="text-transform: capitalize;" /> -->
                                    <input id="dob" type="text" formControlName='dob' class="validate"
                                   autocomplete="off"
                                    style="text-transform: capitalize;" />
                                <label for="dob">Date of Birth (YYYYMMDD)</label>
                                <div
                                    *ngIf="(father.get('dob').dirty || father.get('dob').touched) && father.get('dob').invalid">
                                    <small *ngIf="father.get('dob').errors.invalidDate" class="red-text">The Date is
                                        invalid.</small>
                                </div>
                            </div>

                            <div class="input-field col s12">
                                <i class="material-icons prefix green-text">tag</i>
                                <!-- <input id="bandNumber" type="text" formControlName='bandNumber' class="validate"
                                    autocomplete="off" [textMask]="{mask: treatyNoMask, guide: false}"
                                    (keyup)="onBandNoChange($event.target.value, father.get('bandName'))" /> -->
                                    <input id="bandNumber" type="text" formControlName='bandNumber' class="validate"
                                    autocomplete="off" 
                                    (keyup)="onBandNoChange($event.target.value, father.get('bandName'))" />
                                <label for="bandNumber">First Nation/Band (3 digit) Number</label>
                            </div>

                            <div class="input-field col s12">
                                <i class="material-icons prefix green-text">label</i>
                                <input id="bandName" type="text" formControlName='bandName' class="validate"
                                    autocomplete="off" style="text-transform: uppercase;" />
                                <label for="bandName">First Nation/Band Name</label>
                            </div>

                            <div class="input-field col s4">
                                <p>
                                    <label>
                                        <input type="checkbox" formControlName="registred" [value]="true"
                                            (change)="registred('father')" />
                                        <span>Registred</span>
                                    </label>
                                </p>
                            </div>

                            <div class="input-field col s8" *ngIf="isRegistred.father">
                                <i class="material-icons prefix green-text">tag</i>
                                <!-- <input id="registrationNumber" type="text" formControlName='registrationNumber'
                                    class="validate" autocomplete="off"
                                    [textMask]="{mask: registrationNoMask, guide: true}" /> -->
                                    <input id="registrationNumber" type="text" formControlName='registrationNumber'
                                    class="validate" autocomplete="off"
                                   />
                                <label for="registrationNumber">Registration Number (10 digits)<span
                                        class="red-text">*</span></label>
                                <div
                                    *ngIf="(father.get('registrationNumber').dirty || father.get('registrationNumber').touched) && father.get('registrationNumber').invalid">
                                    <small *ngIf="father.get('registrationNumber').errors.required"
                                        class="red-text">Birth Document Regisration Number is Required.</small>
                                    <small *ngIf="father.get('registrationNumber').errors.pattern"
                                        class="red-text">Invalid Registration Number</small>
                                    <small
                                        *ngIf="father.get('registrationNumber').errors.minlength || father.get('registrationNumber').errors.maxlength"
                                        class="red-text">Registration Number must be 10 Digits.</small>
                                </div>
                            </div>

                            <div class="input-field col s12">
                                <p>Was the father adopted?</p>
                                <div class="row col s12">
                                    <label class="col s4">
                                        <input name="adopted" type="radio" formControlName="adopted" value="Yes" />
                                        <span>Yes</span>
                                    </label>
                                    <label class="col s4">
                                        <input name="adopted" type="radio" formControlName="adopted" value="No" />
                                        <span>No</span>
                                    </label>
                                    <label class="col s4">
                                        <input name="adopted" type="radio" formControlName="adopted" value="Unknown" />
                                        <span>Unknown</span>
                                    </label>
                                </div>
                            </div>

                            <div class="input-field col s12 ">
                                <p>
                                    <label>
                                        <input type="checkbox" formControlName="parentStated" [value]="true" />
                                        <span>Parent Not Stated on the Birth Document</span>
                                    </label>
                                </p>
                            </div>
                        </div>
                    </div>

                    <div class="row col s12 card white hoverable"
                        [ngClass]="{'invalidSection':!familyInfoForm.get('mother').valid}">
                        <div class="row col s12" (click)="expand('B')">
                            <h6><strong>B. Mother</strong></h6>
                        </div>

                        <div formGroupName="mother" *ngIf="expandSection.B">
                            <div class="row col s12">
                                <div class="row col s12">
                                    <p>If you believe that you are entitled to Indian status through your adoptive
                                        mother, complete this section with your adoptive mothers’ information.</p>
                                    <p>If you believe that you are entitled to Indian status through your birth mother,
                                        complete this section with your
                                        birth mothers’ information, if available.</p>
                                </div>
                            </div>

                            <div class="input-field col s12 m6">
                                <i class="material-icons prefix green-text">person</i>
                                <input id="familyName" type="text" formControlName='familyName' class="validate"
                                    autocomplete="off" style="text-transform: uppercase;" />
                                <label for="familyName">Family Name<span class="red-text">*</span></label>

                            </div>

                            <div class="input-field col s12 m6">
                                <i class="material-icons prefix green-text">person</i>
                                <input id="birth_familyName" type="text" formControlName='birth_familyName'
                                    class="validate" autocomplete="off" style="text-transform: uppercase;" />
                                <label for="birth_familyName">Family Name at Birth(if different)</label>
                            </div>

                            <div class="input-field col s12 m6">
                                <i class="material-icons prefix green-text">person</i>
                                <input id="givenName" type="text" formControlName='givenName' class="validate"
                                    autocomplete="off" style="text-transform: uppercase;" />
                                <label for="givenName">Given Name<span class="red-text">*</span></label>

                            </div>

                            <div class="input-field col s12 m6">
                                <i class="material-icons prefix green-text">event</i>
                                <!-- <input id="dob" type="text" formControlName='dob' class="validate"
                                    [textMask]="{mask: dateMask, guide: true}" autocomplete="off"
                                    style="text-transform: capitalize;" /> -->
                                    <input id="dob" type="text" formControlName='dob' class="validate"
                                     autocomplete="off"
                                    style="text-transform: capitalize;" />
                                <label for="dob">Date of Birth (YYYYMMDD)</label>
                                <div
                                    *ngIf="(mother.get('dob').dirty || mother.get('dob').touched) && mother.get('dob').invalid">
                                    <small *ngIf="mother.get('dob').errors.invalidDate" class="red-text">The Date is
                                        Invalid</small>
                                </div>
                            </div>

                            <div class="input-field col s12">
                                <i class="material-icons prefix green-text">tag</i>
                                <!-- <input id="bandNumber" type="text" formControlName='bandNumber' class="validate"
                                    autocomplete="off" style="text-transform: uppercase;"
                                    [textMask]="{mask: treatyNoMask, guide: false}"
                                    (keyup)="onBandNoChange($event.target.value, mother.get('bandName'))" /> -->
                                    <input id="bandNumber" type="text" formControlName='bandNumber' class="validate"
                                    autocomplete="off" style="text-transform: uppercase;"
                                    (keyup)="onBandNoChange($event.target.value, mother.get('bandName'))" />
                                <label for="bandNumber">First Nation/Band (3 digit) Number</label>
                            </div>

                            <div class="input-field col s12">
                                <i class="material-icons prefix green-text">label</i>
                                <input id="bandName" type="text" formControlName='bandName' class="validate"
                                    autocomplete="off" style="text-transform: uppercase;" />
                                <label for="bandName">First Nation/Band Name</label>
                            </div>

                            <div class="input-field col s4">
                                <p>
                                    <label>
                                        <input type="checkbox" formControlName="registred" [value]="true"
                                            (change)="registred('mother')" />
                                        <span>Registred</span>
                                    </label>
                                </p>
                            </div>

                            <div class="input-field col s8" *ngIf="isRegistred.mother">
                                <i class="material-icons prefix green-text">tag</i>
                                <!-- <input id="registrationNumber" type="text" formControlName='registrationNumber'
                                    class="validate" autocomplete="off" style="text-transform: capitalize;"
                                    [textMask]="{mask: registrationNoMask, guide: false}" /> -->
                                    <input id="registrationNumber" type="text" formControlName='registrationNumber'
                                    class="validate" autocomplete="off" style="text-transform: capitalize;"
                                   />
                                <label for="registrationNumber">Registration Number (10 digits)<span
                                        class="red-text">*</span></label>
                                <div
                                    *ngIf="(mother.get('registrationNumber').dirty || mother.get('registrationNumber').touched) && mother.get('registrationNumber').invalid">
                                    <small *ngIf="mother.get('registrationNumber').errors.required"
                                        class="red-text">Birth Document Regisration Number is Required.</small>
                                    <small *ngIf="mother.get('registrationNumber').errors.pattern"
                                        class="red-text">Invalid Registration Number</small>
                                    <small
                                        *ngIf="mother.get('registrationNumber').errors.minlength || mother.get('registrationNumber').errors.maxlength"
                                        class="red-text">Registration Number must be 10 Digits.</small>
                                </div>

                            </div>

                            <div class="input-field col s12">
                                <p>Was the Mother adopted?</p>
                                <div class="row col s12">
                                    <label class="col s4">
                                        <input name="adopted" type="radio" formControlName="adopted" value="Yes" />
                                        <span>Yes</span>
                                    </label>
                                    <label class="col s4">
                                        <input name="adopted" type="radio" formControlName="adopted" value="No" />
                                        <span>No</span>
                                    </label>
                                    <label class="col s4">
                                        <input name="adopted" type="radio" formControlName="adopted" value="Unknown" />
                                        <span>Unknown</span>
                                    </label>
                                </div>
                            </div>

                        </div>

                    </div>

                    <div class="row col s12 card white hoverable"
                        [ngClass]="{'invalidSection':!familyInfoForm.get('maternal_grandparents').valid}">

                        <div class="row col s12" (click)="expand('C')">
                            <h6><strong>C. Maternal Grandparents</strong></h6>
                        </div>

                        <div formGroupName="maternal_grandparents" *ngIf="expandSection.C">
                            <div class="row col s12">
                                <p>(Provide information up to the first person registered. For example, if your mother
                                    is registered, information on the maternal grandparents and great-grandparents is
                                    not required)</p>

                                <div class="row col s12" *ngIf="maternalGrandparents.controls.length > 0">
                                    <h5><strong>Added Persons</strong></h5>
                                    <ul class="collapsible">
                                        <li *ngFor="let person of maternalGrandparents.controls;let gi=index;">
                                            <div class="collapsible-header"><i
                                                    class="material-icons green-text">how_to_reg</i>
                                                <span class="col s10 left">
                                                    <strong> {{person.get('type').value}} : </strong>
                                                    {{person.get('givenName').value
                                                    | uppercase}} {{person.get('familyName').value | uppercase}}
                                                </span>
                                                <span class="cols s2 right">
                                                    <i class="material-icons red-text small col s2"
                                                        style="position: relative"
                                                        (click)="onDeleteGP('maternal', person.get('type').value, i)"
                                                        style="cursor: pointer;">delete</i>
                                                </span>

                                            </div>
                                            <div class="collapsible-body">
                                                <ul class="collection">
                                                    <li class="collection-item">
                                                        <div><strong>Birth Family Name: </strong><a
                                                                class="secondary-content">{{person.get('birth_familyName').value}}
                                                            </a></div>
                                                    </li>
                                                    <li class="collection-item">
                                                        <div><strong>Date of Birth: </strong><a
                                                                class="secondary-content">{{person.get('dob').value}}
                                                            </a></div>
                                                    </li>
                                                    <li class="collection-item">
                                                        <div><strong>Band Number: </strong><a
                                                                class="secondary-content">{{person.get('bandNumber').value}}
                                                            </a></div>
                                                    </li>
                                                    <li class="collection-item">
                                                        <div><strong>Band Name: </strong><a
                                                                class="secondary-content">{{person.get('bandName').value}}
                                                            </a></div>
                                                    </li>
                                                    <li class="collection-item">
                                                        <div><strong>Adopted: </strong><a
                                                                class="secondary-content">{{person.get('adopted').value}}
                                                            </a></div>
                                                    </li>
                                                    <li class="collection-item">
                                                        <div><strong>Registred: </strong><a
                                                                class="secondary-content">{{person.get('registred').value}}
                                                            </a></div>
                                                    </li>
                                                    <li class="collection-item" *ngIf="person.get('registred').value">
                                                        <div><strong>Registration Number: </strong><a
                                                                class="secondary-content">{{person.get('registrationNumber').value}}
                                                            </a></div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                    </ul>
                                </div>

                                <div class="input-field col s12">
                                    <h6><strong>Do you Wish to add a Maternal Grandparent?</strong></h6>
                                    <br />
                                    <div class="row col s12">
                                        <label class="col s6">
                                            <input class="with-gap" name="maternalGP" type="radio" [value]="true"
                                                (change)="addGrandParentYes('maternal')" [checked]="addMaternalGP" />
                                            <span>Yes</span>
                                        </label>
                                        <label class="col s6">
                                            <input class="with-gap" name="maternalGP" type="radio" [value]="false"
                                                (change)="addGrandParentNo('maternal')"
                                                [checked]="addMaternalGP != undefined && !addMaternalGP && maternalGrandparents.controls.length == 0" />
                                            <span>No</span>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="addMaternalGP">
                                <span class="material-icons right red-text" style="position: relative"
                                    (click)="closeAddGP('maternal')" style="cursor: pointer;">close</span>
                                <div class="input-field col s12">
                                    <i class="material-icons prefix purple-text">check_box</i>
                                    <select class="validate" formControlName='type'>
                                        <option value="" disabled selected>Choose your option</option>
                                        <option *ngFor="let value of maternalGPsList" [value]="value">{{value}}</option>
                                    </select>
                                    <label>Choose Which Grandparent you want to add<span
                                            class="red-text">*</span></label>
                                </div>

                                <div class="input-field col s12 m6">
                                    <i class="material-icons prefix green-text">person</i>
                                    <input id="familyName" type="text" formControlName='familyName' class="validate"
                                        autocomplete="off" style="text-transform: uppercase;" />
                                    <label for="familyName">Family Name<span class="red-text">*</span></label>
                                    <div
                                        *ngIf="(maternal_grandparents.get('familyName').dirty || maternal_grandparents.get('familyName').touched) && maternal_grandparents.get('familyName').invalid">
                                        <small *ngIf="maternal_grandparents.get('familyName').errors.required"
                                            class="red-text" style="position: absolute;">Family Name is
                                            Required.</small>
                                    </div>
                                </div>

                                <div class="input-field col s12 m6">
                                    <i class="material-icons prefix green-text">person</i>
                                    <input id="birth_familyName" type="text" formControlName='birth_familyName'
                                        class="validate" autocomplete="off" style="text-transform: uppercase;" />
                                    <label for="birth_familyName">Family Name at Birth(if different)</label>
                                </div>

                                <div class="input-field col s12 m6">
                                    <i class="material-icons prefix green-text">person</i>
                                    <input id="givenName" type="text" formControlName='givenName' class="validate"
                                        autocomplete="off" style="text-transform: capitalize;" />
                                    <label for="givenName">Given Name<span class="red-text">*</span></label>
                                    <div
                                        *ngIf="(maternal_grandparents.get('givenName').dirty || maternal_grandparents.get('givenName').touched) && maternal_grandparents.get('familyName').invalid">
                                        <small *ngIf="maternal_grandparents.get('givenName').errors.required"
                                            class="red-text" style="position: absolute;">Given Name is Required.</small>
                                    </div>
                                </div>

                                <div class="input-field col s12 m6">
                                    <i class="material-icons prefix green-text">event</i>
                                    <!-- <input id="dob" type="text" formControlName='dob' class="validate"
                                        [textMask]="{mask: dateMask, guide: true}" autocomplete="off"
                                        style="text-transform: capitalize;" /> -->
                                        <input id="dob" type="text" formControlName='dob' class="validate"
                                         autocomplete="off"
                                        style="text-transform: capitalize;" />
                                    <label for="dob">Date of Birth (YYYYMMDD)</label>
                                    <div
                                        *ngIf="(maternal_grandparents.get('dob').dirty || maternal_grandparents.get('dob').touched) && maternal_grandparents.get('dob').invalid">
                                        <small *ngIf="maternal_grandparents.get('dob').errors.invalidDate"
                                            class="red-text" style="position: absolute;">The Date is Invalid</small>
                                    </div>
                                </div>

                                <div class="input-field col s12 m6">
                                    <i class="material-icons prefix green-text">tag</i>
                                    <!-- <input id="bandNumber" type="text" formControlName='bandNumber' class="validate"
                                        autocomplete="off" style="text-transform: capitalize;"
                                        [textMask]="{mask: treatyNoMask, guide: false}"
                                        (keyup)="onBandNoChange($event.target.value, maternal_grandparents.get('bandName'))" /> -->
                                        <input id="bandNumber" type="text" formControlName='bandNumber' class="validate"
                                        autocomplete="off" style="text-transform: capitalize;"
                                        (keyup)="onBandNoChange($event.target.value, maternal_grandparents.get('bandName'))" />
                                    <label for="bandNumber">First Nation/Band (3 digit) Number</label>
                                </div>

                                <div class="input-field col s12 m6">
                                    <i class="material-icons prefix green-text">label</i>
                                    <input id="bandName" type="text" formControlName='bandName' class="validate"
                                        autocomplete="off" style="text-transform: uppercase;" />
                                    <label for="bandName">First Nation/Band Name</label>
                                </div>

                                <div class="input-field col s4">
                                    <p>
                                        <label>
                                            <input type="checkbox" formControlName="registred" [value]="true"
                                                (change)="registred('maternal_grandparents')" />
                                            <span>Registred</span>
                                        </label>
                                    </p>
                                </div>

                                <div class="input-field col s8" *ngIf="isRegistred.maternal_grandparents">
                                    <i class="material-icons prefix green-text">tag</i>
                                    <!-- <input id="registrationNumber" type="text" formControlName='registrationNumber'
                                        class="validate" autocomplete="off" style="text-transform: capitalize;"
                                        [textMask]="{mask: registrationNoMask, guide: false}" /> -->
                                        <input id="registrationNumber" type="text" formControlName='registrationNumber'
                                        class="validate" autocomplete="off" style="text-transform: capitalize;"
                                         />
                                    <label for="registrationNumber">Registration Number (10 digits)<span
                                            class="red-text">*</span></label>
                                    <div
                                        *ngIf="(maternal_grandparents.get('registrationNumber').dirty || maternal_grandparents.get('registrationNumber').touched) && maternal_grandparents.get('registrationNumber').invalid">
                                        <small *ngIf="maternal_grandparents.get('registrationNumber').errors.required"
                                            class="red-text" style="position: absolute;">Birth Document Regisration
                                            Number is Required.</small>
                                        <small *ngIf="maternal_grandparents.get('registrationNumber').errors.pattern"
                                            class="red-text" style="position: absolute;">Invalid Registration
                                            Number</small>
                                        <small
                                            *ngIf="maternal_grandparents.get('registrationNumber').errors.minlength || maternal_grandparents.get('registrationNumber').errors.maxlength"
                                            class="red-text" style="position: absolute;">Registration Number must be 10
                                            Digits.</small>
                                    </div>
                                </div>

                                <div class="input-field col s12">
                                    <p>Adopted?</p>
                                    <div class="row col s12">
                                        <label class="col s4">
                                            <input name="adopted" type="radio" formControlName="adopted" value="Yes" />
                                            <span>Yes</span>
                                        </label>
                                        <label class="col s4">
                                            <input name="adopted" type="radio" formControlName="adopted" value="No" />
                                            <span>No</span>
                                        </label>
                                        <label class="col s4">
                                            <input name="adopted" type="radio" formControlName="adopted"
                                                value="Unknown" />
                                            <span>Unknown</span>
                                        </label>
                                    </div>
                                </div>

                                <button type="button" class="col s10 offset-s1 btn waves-effect green"
                                    (click)="addGrandParent('maternal', familyInfoForm.get('maternal_grandparents').value)"
                                    [disabled]="!maternal_grandparents.valid" style="margin-bottom: 15px">Add<i
                                        class="material-icons right">add</i></button>

                                <button class="col s10 offset-s1 btn red" type="button" style="margin-bottom: 15px;"
                                    (click)="closeAddGP('maternal')">Close</button>

                            </div>

                        </div>
                    </div>

                    <div class="row col s12 card white hoverable"
                        [ngClass]="{'invalidSection':!familyInfoForm.get('paternal_grandparents').valid}">
                        <div class="row col s12" (click)="expand('D')">
                            <h6><strong>D. Paternal Grandparents</strong></h6>
                        </div>

                        <div formGroupName="paternal_grandparents" *ngIf="expandSection.D">
                            <div class="row col s12">
                                <p>(Provide information up to the first person registered. For example, if your father
                                    is registered, information on the paternal grandparents and great-grandparents is
                                    not required)</p>
                                <div class="row col s12" *ngIf="paternalGrandparents.controls.length > 0">
                                    <h5><strong>Added Persons</strong></h5>
                                    <ul class="collapsible">
                                        <li *ngFor="let person of paternalGrandparents.controls;let gi=index;">
                                            <div class="collapsible-header"><i
                                                    class="material-icons green-text">how_to_reg</i>
                                                <span class="col s10 left">
                                                    <strong> {{person.get('type').value}} : </strong>
                                                    {{person.get('givenName').value
                                                    | uppercase}} {{person.get('familyName').value | uppercase}}
                                                </span>
                                                <span class="cols s2 right">
                                                    <i class="material-icons red-text small col s2"
                                                        (click)="onDeleteGP('paternal', person.get('type').value, i)"
                                                        style="cursor: pointer;">delete</i>
                                                </span>
                                            </div>
                                            <div class="collapsible-body">
                                                <ul class="collection">
                                                    <li class="collection-item">
                                                        <div><strong>Birth Family Name: </strong><a
                                                                class="secondary-content">{{person.get('birth_familyName').value}}
                                                            </a></div>
                                                    </li>
                                                    <li class="collection-item">
                                                        <div><strong>Date of Birth: </strong><a
                                                                class="secondary-content">{{person.get('dob').value}}
                                                            </a></div>
                                                    </li>
                                                    <li class="collection-item">
                                                        <div><strong>Band Number: </strong><a
                                                                class="secondary-content">{{person.get('bandNumber').value}}
                                                            </a></div>
                                                    </li>
                                                    <li class="collection-item">
                                                        <div><strong>Band Name: </strong><a
                                                                class="secondary-content">{{person.get('bandName').value}}
                                                            </a></div>
                                                    </li>
                                                    <li class="collection-item">
                                                        <div><strong>Adopted: </strong><a
                                                                class="secondary-content">{{person.get('adopted').value}}
                                                            </a></div>
                                                    </li>
                                                    <li class="collection-item">
                                                        <div><strong>Registred: </strong><a
                                                                class="secondary-content">{{person.get('registred').value}}
                                                            </a></div>
                                                    </li>
                                                    <li class="collection-item" *ngIf="person.get('registred').value">
                                                        <div><strong>Registration Number: </strong><a
                                                                class="secondary-content">{{person.get('registrationNumber').value}}
                                                            </a></div>
                                                    </li>
                                                </ul>
                                            </div>
                                        </li>
                                    </ul>
                                </div>

                                <div class="input-field col s12">
                                    <h6><strong>Do you Wish to add a Paternal Grandparent?</strong></h6>
                                    <br />
                                    <div class="row col s12">
                                        <label class="col s6">
                                            <input class="with-gap" name="paternalGP" type="radio" [value]="true"
                                                (change)="addGrandParentYes('paternal')" [checked]="addPaternalGP" />
                                            <span>Yes</span>
                                        </label>
                                        <label class="col s6">
                                            <input class="with-gap" name="paternalGP" type="radio" [value]="false"
                                                (change)="addGrandParentNo('paternal')"
                                                [checked]="addPaternalGP != undefined && !addPaternalGP && paternalGrandparents.controls.length == 0" />
                                            <span>No</span>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div *ngIf="addPaternalGP">
                                <span class="material-icons right red-text" (click)="closeAddGP('paternal')"
                                    style="cursor: pointer;">close</span>
                                <div class="input-field col s12">
                                    <i class="material-icons prefix purple-text">check_box</i>
                                    <select class="validate" formControlName='type'>
                                        <option value="" disabled selected>Choose your option</option>
                                        <option *ngFor="let value of paternalGPsList" [value]="value">{{value}}</option>
                                    </select>
                                    <label>Choose Which Grandparent you want to add<span
                                            class="red-text">*</span></label>
                                </div>

                                <div class="input-field col s12 m6">
                                    <i class="material-icons prefix green-text">person</i>
                                    <input id="familyName" type="text" formControlName='familyName' class="validate"
                                        autocomplete="off" style="text-transform: uppercase;" />
                                    <label for="familyName">Family Name<span class="red-text">*</span></label>
                                    <div
                                        *ngIf="(paternal_grandparents.get('familyName').dirty || paternal_grandparents.get('familyName').touched) && paternal_grandparents.get('familyName').invalid">
                                        <small *ngIf="paternal_grandparents.get('familyName').errors.required"
                                            class="red-text" style="position: absolute;">Family Name is
                                            Required.</small>
                                    </div>
                                </div>

                                <div class="input-field col s12 m6">
                                    <i class="material-icons prefix green-text">person</i>
                                    <input id="birth_familyName" type="text" formControlName='birth_familyName'
                                        class="validate" autocomplete="off" style="text-transform: uppercase;" />
                                    <label for="birth_familyName">Family Name at Birth(if different)</label>
                                </div>

                                <div class="input-field col s12 m6">
                                    <i class="material-icons prefix green-text">person</i>
                                    <input id="givenName" type="text" formControlName='givenName' class="validate"
                                        autocomplete="off" style="text-transform: uppercase;" />
                                    <label for="givenName">Given Name<span class="red-text">*</span></label>
                                    <div
                                        *ngIf="(paternal_grandparents.get('givenName').dirty || paternal_grandparents.get('givenName').touched) && paternal_grandparents.get('familyName').invalid">
                                        <small *ngIf="paternal_grandparents.get('givenName').errors.required"
                                            class="red-text" style="position: absolute;">Given Name is Required.</small>
                                    </div>
                                </div>

                                <div class="input-field col s12 m6">
                                    <i class="material-icons prefix green-text">event</i>
                                    <!-- <input id="dob" type="text" formControlName='dob' class="validate"
                                        [textMask]="{mask: dateMask, guide: true}" autocomplete="off"
                                        style="text-transform: capitalize;" /> -->
                                        <input id="dob" type="text" formControlName='dob' class="validate"
                                        autocomplete="off"
                                        style="text-transform: capitalize;" />
                                    <label for="dob">Date of Birth (YYYYMMDD)</label>
                                    <div
                                        *ngIf="(paternal_grandparents.get('dob').dirty || paternal_grandparents.get('dob').touched) && paternal_grandparents.get('dob').invalid">
                                        <small *ngIf="paternal_grandparents.get('dob').errors.invalidDate"
                                            class="red-text" style="position: absolute;">The Date is Invalid</small>
                                    </div>
                                </div>

                                <div class="input-field col s12 m6">
                                    <i class="material-icons prefix green-text">tag</i>
                                    <!-- <input id="bandNumber" type="text" formControlName='bandNumber' class="validate"
                                        autocomplete="off" style="text-transform: capitalize;"
                                        [textMask]="{mask: treatyNoMask, guide: false}"
                                        (keyup)="onBandNoChange($event.target.value, paternal_grandparents.get('bandName'))" /> -->
                                        <input id="bandNumber" type="text" formControlName='bandNumber' class="validate"
                                        autocomplete="off" style="text-transform: capitalize;"
                                        (keyup)="onBandNoChange($event.target.value, paternal_grandparents.get('bandName'))" />
                                    <label for="bandNumber">First Nation/Band (3 digit) Number</label>
                                </div>

                                <div class="input-field col s12 m6">
                                    <i class="material-icons prefix green-text">label</i>
                                    <input id="bandName" type="text" formControlName='bandName' class="validate"
                                        autocomplete="off" style="text-transform: uppercase;" />
                                    <label for="bandName">First Nation/Band Name</label>
                                </div>

                                <div class="input-field col s4">
                                    <p>
                                        <label>
                                            <input type="checkbox" formControlName="registred" [value]="true"
                                                (change)="registred('paternal_grandparents')" />
                                            <span>Registred</span>
                                        </label>
                                    </p>
                                </div>

                                <div class="input-field col s8" *ngIf="isRegistred.paternal_grandparents">
                                    <i class="material-icons prefix green-text">tag</i>
                                    <!-- <input id="registrationNumber" type="text" formControlName='registrationNumber'
                                        class="validate" autocomplete="off" style="text-transform: capitalize;"
                                        [textMask]="{mask: registrationNoMask, guide: false}" /> -->
                                        <input id="registrationNumber" type="text" formControlName='registrationNumber'
                                        class="validate" autocomplete="off" style="text-transform: capitalize;"
                                        />
                                    <label for="registrationNumber">Registration Number (10 digits)<span
                                            class="red-text">*</span></label>
                                    <div
                                        *ngIf="(paternal_grandparents.get('registrationNumber').dirty || paternal_grandparents.get('registrationNumber').touched) && paternal_grandparents.get('registrationNumber').invalid">
                                        <small *ngIf="paternal_grandparents.get('registrationNumber').errors.required"
                                            class="red-text" style="position: absolute;">Birth Document Regisration
                                            Number is Required.</small>
                                        <small *ngIf="paternal_grandparents.get('registrationNumber').errors.pattern"
                                            class="red-text" style="position: absolute;">Invalid Registration
                                            Number</small>
                                        <small
                                            *ngIf="paternal_grandparents.get('registrationNumber').errors.minlength || paternal_grandparents.get('registrationNumber').errors.maxlength"
                                            class="red-text" style="position: absolute;">Registration Number must be 10
                                            Digits.</small>
                                    </div>
                                </div>


                                <div class="input-field col s12">
                                    <p>Adopted?</p>
                                    <div class="row col s12">
                                        <label class="col s4">
                                            <input name="adopted" type="radio" formControlName="adopted" value="Yes" />
                                            <span>Yes</span>
                                        </label>
                                        <label class="col s4">
                                            <input name="adopted" type="radio" formControlName="adopted" value="No" />
                                            <span>No</span>
                                        </label>
                                        <label class="col s4">
                                            <input name="adopted" type="radio" formControlName="adopted"
                                                value="Unknown" />
                                            <span>Unknown</span>
                                        </label>
                                    </div>
                                </div>

                                <button type="button" class="col s10 offset-s1 btn waves-effect green"
                                    (click)="addGrandParent('paternal', familyInfoForm.get('paternal_grandparents').value)"
                                    [disabled]="!paternal_grandparents.valid" style="margin-bottom: 15px">Add<i
                                        class="material-icons right">add</i></button>

                                <button class="col s10 offset-s1 btn red" type="button" style="margin-bottom: 15px;"
                                    (click)="closeAddGP('paternal')">Close</button>

                            </div>
                        </div>
                    </div>

                    <div class="row col s12 card white hoverable"
                        [ngClass]="{'invalidSection':!familyInfoForm.get('otherMembers').valid}">
                        <div class="row col s12" (click)="expand('E')">
                            <h6><strong>E. Additional Family Information</strong><span
                                    class="right blue-text">Optional</span></h6>
                        </div>

                        <div formGroupName="otherMembers" *ngIf="expandSection.E">
                            <div class="row col s12">
                                <h6>List the names of other registered relatives such as brothers, sisters, aunts,
                                    uncles, cousins.</h6>
                            </div>

                            <div class="row col s12" *ngIf="otherFamilyMembers.controls.length >0">
                                <h6><strong>Added Member</strong></h6>
                                <ul class="collapsible">
                                    <li *ngFor="let person of otherFamilyMembers.controls;let gi=index;">
                                        <div class="collapsible-header"><i
                                                class="material-icons green-text">how_to_reg</i>
                                            <span class="col s10 left">
                                                <strong> {{person.get('relationship').value}} : </strong>
                                                {{person.get('givenName').value
                                                | uppercase}} {{person.get('familyName').value | uppercase}}
                                            </span>
                                            <span class="cols s2 right">
                                                <i class="material-icons red-text small col s2"
                                                    (click)="onDeleteOthers(i)" style="cursor: pointer;">delete</i>
                                            </span>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                            <div class="input-field col s12">
                                <p><strong> Do you wish to add Other Family Members?</strong> </p>
                                <div class="row col s12">
                                    <label class="col s6">
                                        <input class="with-gap" name="addOthers" type="radio" [value]="true"
                                            (change)="addOtherMembersYes($event)" [checked]="addOtherMember" />
                                        <span>Yes</span>
                                    </label>
                                    <label class="col s6">
                                        <input class="with-gap" name="addOthers" type="radio" [value]="false"
                                            (change)="addOtherMembersNo($event)"
                                            [checked]="addOtherMember != undefined && !addOtherMember && otherFamilyMembers.controls.length == 0" />
                                        <span>No</span>
                                    </label>
                                </div>
                            </div>

                            <div *ngIf="addOtherMember">
                                <span class="material-icons right red-text" (click)="closeAddOthers()"
                                    style="cursor: pointer;">close</span>

                                <div class="col s12">
                                    <div class="input-field col s12 m6">
                                        <i class="material-icons prefix green-text">person</i>
                                        <input id="familyName" type="text" formControlName='familyName' class="validate"
                                            autocomplete="off" style="text-transform: uppercase;" />
                                        <label for="familyName">Family Name<span class="red-text">*</span></label>
                                    </div>

                                    <div class="input-field col s12 m6">
                                        <i class="material-icons prefix green-text">person</i>
                                        <input id="givenName" type="text" formControlName='givenName' class="validate"
                                            autocomplete="off" style="text-transform: uppercase;" />
                                        <label for="givenName">Given Name<span class="red-text">*</span></label>
                                    </div>

                                    <div class="input-field col s12">
                                        <i class="material-icons prefix green-text">label</i>
                                        <select formControlName="relationship" class="validate" id="relation">
                                            <option value="" disabled selected>Choose your option</option>
                                            <option *ngFor="let r of RelationshipList" [value]="r">{{r}}</option>
                                        </select>
                                        <label for="relationship">Relationship<span class="red-text">*</span></label>
                                    </div>

                                </div>


                                <button type="button" class="col s10 offset-s1 btn waves-effect green"
                                    (click)="addFamilyMember(familyInfoForm.get('otherMembers').value)"
                                    [disabled]="!otherMembers.valid" style="margin-bottom: 15px">Add<i
                                        class="material-icons right">add</i></button>

                                <button class="col s10 offset-s1 btn red" type="button" style="margin-bottom: 15px;"
                                    (click)="closeAddOthers()">Close</button>

                            </div>
                        </div>
                    </div>

                    <button class="col s12 m5 l3 btn left waves-effect blue" type="button" style="margin-bottom: 15px"
                        (click)="previous()"><i class="material-icons left">arrow_back</i>Back
                    </button>

                    <button class="col s12 m5 offset-m2 l3 btn right waves-effect blue" type="submit"
                        [disabled]="!familyInfoForm.valid" style="margin-bottom: 15px">Next <i
                            class="material-icons right">arrow_forward</i></button>
                </form>

                <!-------------------------------------Photo and Signature Information------------------------------------------->
                <form [formGroup]="photoSignatureForm" (ngSubmit)="submitPhotoSignature(photoSignatureForm.value)"
                    *ngIf="step == 6">
                    <div class="row col s12 teal lighten-2 white-text">
                        <h5 class="center-align">SECTION 6: Photo and Signature to Appear on the Secure Certificate of
                            Indian Status (SCIS)</h5>
                    </div>

                    <div class="row col s12 card white">
                        <div class="row input-field col s12">
                            <h6 class="row col s12"><strong>Are you requesting a SCIS ?</strong> <span
                                    class="red-text">*</span></h6>
                            <div class="row col s12">
                                <label class="col s6">
                                    <input class="with-gap" formControlName="SCIS_requested" name="SCIS_requested"
                                        type="radio" [value]="true" (change)="SCISRequestedYes($event)" />
                                    <span>Yes</span>
                                </label>
                                <label class="col s6">
                                    <input class="with-gap" formControlName="SCIS_requested" name="SCIS_requested"
                                        type="radio" [value]="false" (change)="SCISRequestedNo($event)" />
                                    <span>No</span>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="row col s12 card white" *ngIf="SCISRequested">
                        <h5 class="center-align"><strong>Photo</strong></h5>
                        <div class="row col s12 m6">
                            <div class="card">
                                <div class="card-image">
                                    <img *ngIf="photo" [src]="photo.file_url" (click)="showPhoto()">
                                    <img *ngIf="!photo" [src]="defaultPhoto" width="250" height="300">
                                </div>

                                <div class="card-content">
                                    <p *ngIf="!photo">Please upload Canadian Passport-style photograph</p>
                                    <p *ngIf="photo">
                                        <i class="material-icons left green-text">check_circle</i>Photo Uploaded
                                    </p>
                                </div>
                                <div class="card-action">
                                    <a *ngIf="!photo" style="cursor: pointer;" class="btn-flat waves-effect green-text"
                                        (click)="attachFiles('photo')">
                                        <i class="material-icons">add_photo_alternate</i> <strong>Upload
                                            Photo</strong></a>
                                    <input class="hide" type="file" name="photo" id="photo" accept="image/*"
                                        (change)="uploadPhoto($event.target.files)">

                                    <a *ngIf="photo" style="cursor: pointer;" class="btn-flat waves-effect red-text"
                                        (click)="removeFile('photo')">
                                        <i class="material-icons">delete_outline</i> <strong> Clear Photo</strong></a>
                                </div>
                            </div>
                        </div>

                        <div class="row col s12 m6">
                            <p> Please Provide the name and address of the studio or person who took the
                                photo, and the date the photo was taken on.</p>
                            <br />

                            <div class="input-field col s12">
                                <i class="material-icons prefix blue-text">person</i>
                                <input id="photoBy" type="text" formControlName='photoBy' class="validate"
                                    autocomplete="off" style="text-transform: uppercase;" />
                                <label for="photoBy">Name</label>
                            </div>

                            <div class="input-field col s12">
                                <i class="material-icons prefix blue-text">place</i>
                                <input id="photoAddress" type="text" formControlName='photoAddress' class="validate"
                                    autocomplete="off" style="text-transform: uppercase;" />
                                <label for="photoAddress">Address</label>
                            </div>

                            <div class="input-field col s12">
                                <i class="material-icons prefix blue-text">event</i>
                                <!-- <input id="photoDate" type="text" formControlName='photoDate' class="validate"
                                    autocomplete="off" [textMask]="{mask: dateMask, guide: true}" /> -->
                                    <input id="photoDate" type="text" formControlName='photoDate' class="validate"
                                    autocomplete="off"  />
                                <label for="photoDate">Date (YYYY/MM/DD)</label>
                                <div *ngIf="(photoDate.dirty || photoDate.touched) && photoDate.invalid">
                                    <small *ngIf="photoDate.errors. errors.invalidDate" class="red-text">Date is
                                        invalid</small>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row col s12">
                        <div class="row col s12 card">
                            <h6 class="blue-text text-darken-2 center"><strong>SIGNATURE</strong></h6>

                            <div class="row card-content" *ngIf="!signature">
                                <p>Sign your name inside the box without touching the borders. You may sign with an “X”,
                                    in syllabics or another language, as long as this is your usual legal signature.</p>
                                <div class="row s10 card-pane center" style="border: 2px solid grey;margin-top: 15px;">
                                    <div>
                                        <canvas></canvas>
                                    </div>
                                </div>
                            </div>

                            <div class="card-image" *ngIf="signature">
                                <img [src]="signature" alt="Signature" />
                            </div>

                            <div class="card-action" *ngIf="!signature">
                                <a style="cursor: pointer; margin-bottom: 15px;"
                                    class="btn-flat waves-effect green-text right" (click)="approveSignature()">
                                    <i class="material-icons right">border_color</i><strong>Approve
                                        Signature</strong></a>

                                <a style="cursor: pointer; margin-bottom: 15px;"
                                    class="btn-flat waves-effect red-text left" (click)="clearSignature()">
                                    <i class="material-icons">loop</i> <strong>Clear Signature</strong></a>
                            </div>

                            <div class="card-action" *ngIf="signature">
                                <!-- <a><i class="material-icons right green-text">check_circle</i>Signed</a> -->
                                <a style="cursor: pointer; margin-top: 15px; margin-bottom: 15px"
                                    class="btn-flat waves-effect red-text right" (click)="clearSignature()">
                                    <i class="material-icons right">delete_outline</i> <strong>Clear
                                        Signature</strong></a>

                                <a style=" margin-top: 15px; margin-bottom: 15px" class="green-text left">
                                    <i class="material-icons right">check_circle</i> <strong>Signed</strong></a>
                            </div>
                        </div>
                    </div>

                    <div id="photoModal" class="modal" *ngIf="photo">
                        <div class="modal-content">
                            <h5>Preview</h5>
                            <img [src]="photo.file_url">
                        </div>
                        <div class="modal-footer">
                            <a class="modal-close waves-effect waves-red btn-flat">Close</a>
                        </div>
                    </div>

                    <button class="col s12 m5 l3 btn left waves-effect blue" type="button" style="margin-bottom: 15px"
                        (click)="previous()"><i class="material-icons left">arrow_back</i>Back
                    </button>

                    <button class="col s12 m5 offset-m2 l3 btn right waves-effect blue" type="submit"
                        [disabled]="!photoSignatureForm.valid || !signature" style="margin-bottom: 15px">Next <i
                            class="material-icons right">arrow_forward</i></button>
                </form>
                <!-------------------------------------Declaration------------------------------------------->
                <form [formGroup]="declarationForm" (ngSubmit)="submitDeclaration(declarationForm.value)"
                    *ngIf="step == 7">
                    <div class="row col s12 teal lighten-2 white-text">
                        <h5 class="center-align">SECTION 7: Declaration and Signature</h5>
                    </div>

                    <div class="row col s12 card white">
                        <div class="card-content">
                            <h6><i class="material-icons amber-text">
                                    warning
                                </i><strong> &nbsp;Failing to sign and data the declaration will delay the processing of
                                    you
                                    application.</strong></h6>
                            <br />
                            <p>I solemnly declare that all statements made in this application are true, all documents
                                provided to support this application are unaltered,
                                and the enclosed photographs (if requesting a Secure Certificate of Indian Status) are a
                                true likeness of me. I have read and understand
                                the Notice to Applicants and the <em>Privacy Act Statement</em>.
                            </p>
                            <br />
                            <div class="row col s12">
                                <p class="col m2 s4">If eligible, I </p>
                                <div class="input-field col m10 s8">
                                    <input id="applicantName" type="text" formControlName="applicantName"
                                        class="validate" style="text-transform: uppercase;">
                                    <label for="applicantName">Applicant Name<span class="red-text">*</span></label>
                                    <div
                                        *ngIf="(applicantName.dirty || applicantName.touched) && applicantName.invalid">
                                        <small *ngIf="applicantName.errors.required" class="red-text">Name is
                                            Required.</small>
                                    </div>
                                </div>
                                <p> be registered in the Indian Register and, if applicable, to have
                                    my name entered on a First Nation/Band List, as provided for under the Indian Act. I
                                    further request to have a Secure Certificate of Indian
                                    Status issued to me (if applicable). </p>
                            </div>

                            <div class="row col s12 m6">
                                <p class="col s12">Signature<span class="red-text">*</span></p>
                                <a *ngIf="!signed && signature" [ngClass]="'btn col s8 waves-effect ' + themeColor"
                                    (click)="sign()" style="margin-top: 10px;">Sign</a>

                                <div class="card-image" *ngIf="signature && signed">
                                    <img [src]="signature" alt="Signature" />
                                </div>
                                <a *ngIf="signed" class="col s4" style="margin-top: 10px;"><i
                                        class="material-icons left green-text small">check_circle</i>Signed</a>
                                <a *ngIf="signed" class="col s4 pink-text" style="margin-top: 10px;cursor: pointer;"
                                    (click)="clearSig()"><i class="material-icons left pink-text">clear</i>Clear</a>
                            </div>

                            <div class="input-field col s12 m6">
                                <i class="material-icons prefix blue-text">calendar_today</i>
                                <!-- <input readonly id="signatureDate" type="text" formControlName="signatureDate"
                                    class="validate" [textMask]="{mask: dateMask, guide: true}"> -->
                                    <input readonly id="signatureDate" type="text" formControlName="signatureDate"
                                    class="validate" >
                                <label for="signatureDate">Date (YYYYMMDD)<span class="red-text">*</span></label>
                            </div>
                        </div>
                    </div>

                    <button class="col s12 m5 l3 btn left waves-effect blue" type="button" style="margin-bottom: 15px"
                        (click)="previous()"><i class="material-icons left">arrow_back</i>Back
                    </button>

                    <button class="col s12 m5 offset-m2 l3 btn right waves-effect blue" type="submit"
                        [disabled]="!declarationForm.valid || !signed" style="margin-bottom: 15px">Next <i
                            class="material-icons right">arrow_forward</i></button>
                </form>

                <!-------------------------------------IRA Information------------------------------------------->
                <form [formGroup]="IRAForm" (ngSubmit)="submitIRA(IRAForm.value)" *ngIf="step == 8">
                    <div class="row col s12 teal lighten-2 white-text">
                        <h5 class="center-align">SECTION 8: Indian Registration Administrator(IRA)</h5>
                    </div>

                    <div class="row col s12 card white">
                        <div class="row input-field col s12">
                            <h6 class="row col s12"><strong>Has an IRA assisted you in completing this form?</strong>
                                <span class="red-text">*</span>
                            </h6>
                            <div class="row col s12">
                                <label class="col s6">
                                    <input class="with-gap" formControlName="IRA_assisted" name="IRA_assisted"
                                        type="radio" [value]="true" (change)="IRA_assistedYes($event)" />
                                    <span>Yes</span>
                                </label>
                                <label class="col s6">
                                    <input class="with-gap" formControlName="IRA_assisted" name="IRA_assisted"
                                        type="radio" [value]="false" (change)="IRA_assistedNo($event)" />
                                    <span>No</span>
                                </label>
                            </div>
                        </div>
                    </div>

                    <div class="row col s12 card white" *ngIf="IRA_assisted">
                        <h6><i class="material-icons amber-text"> lightbulb </i><strong> &nbsp;If an IRA assisted in
                                completing this form, he or she must complete and
                                sign this section.</strong></h6>

                        <div class="input-field col s12">
                            <i class="material-icons prefix blue-text">person</i>
                            <input id="IRA_name" type="text" formControlName='IRA_name' class="validate"
                                autocomplete="off" style="text-transform: uppercase;" />
                            <label for="IRA_name">Name<span class="red-text">*</span></label>
                            <div *ngIf="(IRA_name.dirty || IRA_name.touched) && IRA_name.invalid">
                                <small *ngIf="IRA_name.errors.required" class="red-text">IRA Name is
                                    Required.</small>
                            </div>
                        </div>

                        <div class="input-field col s12">
                            <i class="material-icons prefix blue-text">person</i>
                            <input id="bandNo_initiatorCode" type="text" formControlName='bandNo_initiatorCode'
                                class="validate" autocomplete="off" style="text-transform: uppercase;" />
                            <label for="bandNo_initiatorCode">First Nation/Band Number or Initiator Code IRA</label>
                        </div>
                    </div>

                    <button class="col s12 m5 l3 btn left waves-effect blue" type="button" style="margin-bottom: 15px"
                        (click)="previous()"><i class="material-icons left">arrow_back</i>Back
                    </button>

                    <button class="col s12 m5 offset-m2 l3 btn right waves-effect green" type="submit"
                        [disabled]="!IRAForm.valid" style="margin-bottom: 15px" title="Save">Submit <i
                            class="material-icons right">send</i></button>
                </form>

                <div *ngIf="step == 9" class="summary">
                    <div class="row col s12 teal lighten-2 white-text">
                        <h5 class="left-align col s6">SUMMARY </h5>

                        <div class="row right" style="margin-top: 15px; margin-right: 10px;">
                            <a class='dropdown-trigger btn-flat waves-effect waves-light white-text ' id="report"
                                data-target='dropdown1' style="font-weight: bold; border: 2px solid white;"><i
                                    class="material-icons right">picture_as_pdf</i>Get Report</a>
                            <!-- Dropdown Structure -->
                            <ul id='dropdown1' class='col dropdown-content'>
                                <li><a (click)="downloadReport()" class="btn-flat center-align"><i
                                            class="material-icons right">
                                            download
                                        </i>Download</a></li>
                                <li><a (click)="openReport()" class="btn-flat center-align"><i
                                            class="material-icons right">
                                            open_in_new
                                        </i>Open</a></li>
                                <li><a (click)="printReport()" class="btn-flat center-align"><i
                                            class="material-icons right">
                                            print
                                        </i>Print</a></li>
                                <li><a (click)="sendReport()" class="btn-flat center-align"><i
                                            class="material-icons right">
                                            share
                                        </i>Send</a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="row" *ngIf="summary1">
                        <div class="center-align">
                            <p class="blue-text"><strong>Page 1</strong></p>
                        </div>

                        <ul class="collection with-header col s12">
                            <li class="collection-header">
                                <h5><strong>SECTION 1: Personal Information</strong></h5>
                            </li>
                            <li class="collection-item col m6 s12">
                                <div>Family Name<span class="secondary-content">{{model.familyName}}</span></div>
                            </li>
                            <li class="collection-item col m6 s12">
                                <div>Family Name<span class="secondary-content">{{model.givenName}}</span></div>
                            </li>
                            <li class="collection-item col m6 s12">
                                <div>Family Name at Birth<span
                                        class="secondary-content">{{model.birth_familyName}}</span></div>
                            </li>
                            <li class="collection-item col m6 s12">
                                <div>Alias/Cultural Name<span class="secondary-content">{{model.aliasName}}</span></div>
                            </li>
                            <li class="collection-item col m6 s12">
                                <div>Gender<span class="secondary-content">{{model.gender}}</span></div>
                            </li>
                            <li class="collection-item col m6 s12">
                                <div>Date of Birth<span class="secondary-content">{{model.dob? model.dob : ''}}</span>
                                </div>
                            </li>
                            <h6 class="col s12"><strong>Permanent Address</strong></h6>
                            <li class="collection-item col m6 s12">
                                <div>Number<span class="secondary-content">{{model.number}}</span></div>
                            </li>
                            <li class="collection-item col m6 s12">
                                <div>Street<span class="secondary-content">{{model.street}}</span></div>
                            </li>
                            <li class="collection-item col m6 s12">
                                <div>Apartment<span class="secondary-content">{{model.apartment}}</span></div>
                            </li>
                            <li class="collection-item col m6 s12">
                                <div>P.O.Box<span class="secondary-content">{{model.POBox}}</span></div>
                            </li>
                            <li class="collection-item col m6 s12">
                                <div>City/Town<span class="secondary-content">{{model.city}}</span></div>
                            </li>
                            <li class="collection-item col m6 s12" *ngIf="country =='CA'">
                                <div>Province/Territory<span class="secondary-content">{{model.province_state}}</span>
                                </div>
                            </li>
                            <li class="collection-item col m6 s12" *ngIf="country =='USA'">
                                <div>State<span class="secondary-content">{{model.province_state}}</span></div>
                            </li>
                            <li class="collection-item col m6 s12">
                                <div>Postal/ZIP Code<span class="secondary-content">{{model.postalCode}}</span></div>
                            </li>
                            <li class="collection-item col m6 s12">
                                <div>Email<span class="secondary-content">{{model.email}}</span></div>
                            </li>
                            <li class="collection-item col m6 s12">
                                <div>Telephone Number(Daytime)<span class="secondary-content">{{model.telephone}}</span>
                                </div>
                            </li>
                            <li class="collection-item col m6 s12">
                                <div>Telephone Number(Other)<span class="secondary-content">{{model.telephone2}}</span>
                                </div>
                            </li>
                            <div *ngIf="model.sameMailingAddress">
                                <h6 class="col s12"><strong>Mailing Address</strong></h6>
                                <li class="collection-item col m6 s12">
                                    <div>Number<span class="secondary-content">{{model.mailing_number}}</span></div>
                                </li>
                                <li class="collection-item col m6 s12">
                                    <div>Street<span class="secondary-content">{{model.mailing_street}}</span></div>
                                </li>
                                <li class="collection-item col m6 s12">
                                    <div>Apartment<span class="secondary-content">{{model.mailing_apartment}}</span>
                                    </div>
                                </li>
                                <li class="collection-item col m6 s12">
                                    <div>P.O.Box<span class="secondary-content">{{model.mailing_POBox}}</span></div>
                                </li>
                                <li class="collection-item col m6 s12">
                                    <div>City/Town<span class="secondary-content">{{model.mailing_city}}</span></div>
                                </li>
                                <li class="collection-item col m6 s12" *ngIf="country =='CA'">
                                    <div>Province/Territory<span
                                            class="secondary-content">{{model.mailing_province_state}}</span></div>
                                </li>
                                <li class="collection-item col m6 s12" *ngIf="country =='USA'">
                                    <div>State<span class="secondary-content">{{model.mailing_province_state}}</span>
                                    </div>
                                </li>
                                <li class="collection-item col m6 s12">
                                    <div>Postal/ZIP Code<span
                                            class="secondary-content">{{model.mailing_postalCode}}</span></div>
                                </li>
                            </div>
                        </ul>

                        <ul class="collection with-header col s12">
                            <li class="collection-header">
                                <h5><strong>SECTION 2: First Nation/Band Choice</strong></h5>
                            </li>
                            <li class="collection-item col s12">
                                <div>First Nation/Band Number<span class="secondary-content">{{model.bandNumber}}</span>
                                </div>
                            </li>
                            <li class="collection-item col s12">
                                <div>First Nation/Band Name<span class="secondary-content">{{model.bandName}}</span>
                                </div>
                            </li>
                        </ul>

                        <ul class="collection with-header col s12">
                            <li class="collection-header">
                                <h5><strong>SECTION 3: Document Requirements</strong></h5>
                            </li>
                            <h6 class="col s12"><strong>A. Proof of Birth Document</strong></h6>
                            <div *ngIf="model.proofOfBirthDocument">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Registration Number on Birth Document</th>
                                            <th *ngIf="country == 'CA'">Province/Territory</th>
                                            <th *ngIf="country == 'USA'">State</th>
                                            <th class="right">Document</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr>
                                            <td>{{model.proofOfBirthDocument.registrationNo}}</td>
                                            <td>{{model.proofOfBirthDocument.province_state}}</td>
                                            <td><a [href]="model.proofOfBirthDocument.file_url" target="_blank"
                                                    class="right">{{model.proofOfBirthDocument.file_name}}</a></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div *ngIf="!model.proofOfBirthDocument">
                                <li class="collection-header">
                                    <p>No Document Provided</p>
                                </li>
                            </div>

                            <h6 class="col s12"><strong>B. Name Linking Document(s)</strong></h6>
                            <div *ngIf="model.nameLinkingDocumnet?.length > 0">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Document Type</th>
                                            <th class="right">Document</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr *ngFor="let document of model.nameLinkingDocumnet">
                                            <td>{{document.documentName}}</td>
                                            <td>{{document.documentType}}</td>
                                            <td><a [href]="document.file_url" target="_blank"
                                                    class="right">{{document.file_name}}</a></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div *ngIf="model.nameLinkingDocumnet?.length == 0">
                                <li class="collection-header">
                                    <p>No Document Provided</p>
                                </li>
                            </div>

                            <h6 class="col s12"><strong>C. Supporting Identity Document(s)</strong></h6>
                            <div *ngIf="model.supportingIdentityDocuments?.length > 0">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Document Type</th>
                                            <th>Document Number</th>
                                            <th>Expiry Date</th>
                                            <th class="right">Document</th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        <tr *ngFor="let document of model.supportingIdentityDocuments">
                                            <td>{{document.documentName}}</td>
                                            <td>{{document.documentType}}</td>
                                            <td>{{document.documentNumber}}</td>
                                            <td>{{document.expiryDate}}</td>
                                            <td><a [href]="document.file_url" target="_blank"
                                                    class="right">{{document.file_name}}</a></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div *ngIf="model.supportingIdentityDocuments?.length == 0">
                                <li class="collection-header">
                                    <p>No Document Provided</p>
                                </li>
                            </div>
                        </ul>

                        <ul class="collection with-header col s12" *ngIf="model.adopted">
                            <li class="collection-header">
                                <h5><strong>SECTION 4: Adoption Information</strong></h5>
                            </li>

                            <h6 class="col s12">I was adopted as a child, and I believe that I have entitlement to
                                Indian status through</h6>

                            <table class="centered">
                                <thead>
                                    <tr>
                                        <th>My Adoptive Mother</th>
                                        <th>My Adoptive Father</th>
                                        <th>My Birth Mother</th>
                                        <th>My Birth Father</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>
                                            <a *ngIf="model.ISNEntitlement.adoptiveMother">
                                                <i class="material-icons green-text">check</i></a>
                                            <a *ngIf="!model.ISNEntitlement.adoptiveMother">
                                                <i class="material-icons red-text">close</i></a>
                                        </td>
                                        <td>
                                            <a *ngIf="model.ISNEntitlement.adoptiveFather">
                                                <i class="material-icons green-text">check</i></a>
                                            <a *ngIf="!model.ISNEntitlement.adoptiveFather">
                                                <i class="material-icons red-text">close</i></a>
                                        </td>
                                        <td>
                                            <a *ngIf="model.ISNEntitlement.birthMother">
                                                <i class="material-icons green-text">check</i></a>
                                            <a *ngIf="!model.ISNEntitlement.birthMother">
                                                <i class="material-icons red-text">close</i></a>
                                        </td>
                                        <td>
                                            <a *ngIf="model.ISNEntitlement.birthFather">
                                                <i class="material-icons green-text">check</i></a>
                                            <a *ngIf="!model.ISNEntitlement.birthFather">
                                                <i class="material-icons red-text">close</i></a>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <table>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Family Name</th>
                                        <th>Given Name</th>
                                        <th>Date of Birth</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngIf="model.adoptiveMother">
                                        <td><strong>Adoptive Mother</strong></td>
                                        <td>{{model.adoptiveMother.familyName}}</td>
                                        <td>{{model.adoptiveMother.givenName}}</td>
                                        <td>{{model.adoptiveMother.dob}}</td>
                                    </tr>
                                    <tr *ngIf="model.adoptiveFather">
                                        <td><strong>Adoptive Father</strong></td>
                                        <td>{{model.adoptiveFather.familyName}}</td>
                                        <td>{{model.adoptiveFather.givenName}}</td>
                                        <td>{{model.adoptiveFather.dob}}</td>
                                    </tr>
                                </tbody>
                            </table>

                            <table>
                                <thead>
                                    <tr>
                                        <th></th>
                                        <th>Family Name</th>
                                        <th>Given Name</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngIf="model.birthMother">
                                        <td><strong>Birth Mother</strong></td>
                                        <td>{{model.birthMother.familyName}}</td>
                                        <td>{{model.birthMother.givenName}}</td>
                                    </tr>
                                    <tr *ngIf="model.birthFather">
                                        <td><strong>Birth Father</strong></td>
                                        <td>{{model.birthFather.familyName}}</td>
                                        <td>{{model.birthFather.givenName}}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </ul>

                    </div>

                    <div class="row" *ngIf="summary2">
                        <div class="center-align">
                            <p class="blue-text"><strong>Page 2</strong></p>
                        </div>

                        <ul class="collection with-header col s12">
                            <li class="collection-header">
                                <h5><strong>SECTION 5: Family Information</strong></h5>
                            </li>

                            <div *ngIf="model.father">
                                <h6 class="col s12"><strong>A. Father</strong></h6>

                                <li class="collection-item col m6 s12">
                                    <div>Family Name<span class="secondary-content">{{model.father.familyName}}</span>
                                    </div>
                                </li>
                                <li class="collection-item col m6 s12">
                                    <div>Given Name<span class="secondary-content">{{model.father.givenName}}</span>
                                    </div>
                                </li>
                                <li class="collection-item col m6 s12">
                                    <div>Family Name at Birth<span
                                            class="secondary-content">{{model.father.birth_familyName}}</span></div>
                                </li>
                                <li class="collection-item col m6 s12">
                                    <div>Date of Birth<span class="secondary-content">{{model.father.dob}}</span></div>
                                </li>
                                <li class="collection-item col m6 s12">
                                    <div>First Nation/Band Name<span
                                            class="secondary-content">{{model.father.bandName}}</span></div>
                                </li>
                                <li class="collection-item col m6 s12">
                                    <div>First Nation/Band Number<span
                                            class="secondary-content">{{model.father.bandNumber}}</span></div>
                                </li>

                                <li class="collection-item col m6 s12">
                                    <div>Adopted<span class="secondary-content"> {{model.father.adopted}}
                                        </span></div>
                                </li>
                                <li class="collection-item col m6 s12">
                                    <div>Parent stated on the Birth Document<span class="secondary-content">
                                            <a *ngIf="model.father.parentStated">
                                                <i class="material-icons green-text">check</i></a>
                                            <a *ngIf="!model.father.adopted">
                                                <i class="material-icons red-text">close</i></a>
                                        </span></div>
                                </li>
                                <li class="collection-item col m6 s12">
                                    <div>Registred<span class="secondary-content">
                                            <a *ngIf="model.father.registred">
                                                <i class="material-icons green-text">check</i></a>
                                            <a *ngIf="!model.father.registred">
                                                <i class="material-icons red-text">close</i></a>
                                        </span></div>
                                </li>
                                <li class="collection-item col m6 s12" *ngIf="model.father.registred">
                                    <div>Registration Number<span
                                            class="secondary-content">{{model.father.registrationNumber}}</span></div>
                                </li>

                            </div>

                            <div *ngIf="model.mother">
                                <h6 class="col s12"><strong>B. Mother</strong></h6>

                                <li class="collection-item col m6 s12">
                                    <div>Family Name<span class="secondary-content">{{model.mother.familyName}}</span>
                                    </div>
                                </li>
                                <li class="collection-item col m6 s12">
                                    <div>Given Name<span class="secondary-content">{{model.mother.givenName}}</span>
                                    </div>
                                </li>
                                <li class="collection-item col m6 s12">
                                    <div>Family Name at Birth<span
                                            class="secondary-content">{{model.mother.birth_familyName}}</span></div>
                                </li>
                                <li class="collection-item col m6 s12">
                                    <div>Date of Birth<span class="secondary-content">{{model.mother.dob}}</span></div>
                                </li>
                                <li class="collection-item col m6 s12">
                                    <div>First Nation/Band Name<span
                                            class="secondary-content">{{model.mother.bandName}}</span></div>
                                </li>
                                <li class="collection-item col m6 s12">
                                    <div>First Nation/Band Number<span
                                            class="secondary-content">{{model.mother.bandNumber}}</span></div>
                                </li>
                                <li class="collection-item col m6 s12">
                                    <div>Registred<span class="secondary-content">
                                            <a *ngIf="model.mother.registred">
                                                <i class="material-icons green-text">check</i></a>
                                            <a *ngIf="!model.mother.registred">
                                                <i class="material-icons red-text">close</i></a>
                                        </span></div>
                                </li>
                                <li class="collection-item col m6 s12" *ngIf="model.mother.registred">
                                    <div>Registration Number<span
                                            class="secondary-content">{{model.mother.registrationNumber}}</span></div>
                                </li>
                            </div>

                            <h6 class="col s12"><strong>C. Maternal Grandparents</strong></h6>
                            <div *ngIf="model.maternal_grandparents?.length > 0">
                                <table class="striped highlight responsive-table">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Family Name</th>
                                            <th>Given Name(s)</th>
                                            <th>Family Name at Birth</th>
                                            <th>Date of Birth</th>
                                            <th>First Nation/Band Number</th>
                                            <th>First Nation/Band Name</th>
                                            <th>Registration Number</th>
                                            <th>Adopted</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let GP of model.maternal_grandparents">
                                            <td><strong>{{GP.type}}</strong></td>
                                            <td>{{GP.familyName}}</td>
                                            <td>{{GP.givenName}}</td>
                                            <td>{{GP.birth_familyName}}</td>
                                            <td>{{GP.dob}}</td>
                                            <td>{{GP.bandNumber}}</td>
                                            <td>{{GP.bandName}}</td>
                                            <td>{{GP.registred ? GP.registrationNumber : ''}}</td>
                                            <td>{{GP.adopted}}</td>
                                        </tr>
                                    </tbody>
                                </table>

                            </div>
                            <div *ngIf="model.maternal_grandparents?.length == 0">
                                <li class="collection-header">
                                    <p>No Maternal Grandparents Added.</p>
                                </li>
                            </div>

                            <h6 class="col s12"><strong>D. Paternal Grandparents</strong></h6>
                            <div *ngIf="model.paternal_grandparents?.length > 0">
                                <table class="striped highlight responsive-table">
                                    <thead>
                                        <tr>
                                            <th></th>
                                            <th>Family Name</th>
                                            <th>Given Name(s)</th>
                                            <th>Family Name at Birth</th>
                                            <th>Date of Birth</th>
                                            <th>First Nation/Band Number</th>
                                            <th>First Nation/Band Name</th>
                                            <th>Registration Number</th>
                                            <th>Adopted</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let GP of model.paternal_grandparents">
                                            <td><strong>{{GP.type}}</strong></td>
                                            <td>{{GP.familyName}}</td>
                                            <td>{{GP.givenName}}</td>
                                            <td>{{GP.birth_familyName}}</td>
                                            <td>{{GP.dob}}</td>
                                            <td>{{GP.bandNumber}}</td>
                                            <td>{{GP.bandName}}</td>
                                            <td>{{GP.registred ? GP.registrationNumber : ''}}</td>
                                            <td>{{GP.adopted}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div *ngIf="model.paternal_grandparents?.length == 0">
                                <li class="collection-header">
                                    <p>No Paternal Grandparents Added.</p>
                                </li>
                            </div>

                            <h6 class="col s12"><strong>Additional Family Information</strong></h6>
                            <div *ngIf="model.other_familyMembers?.length > 0">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Family Name</th>
                                            <th>Given Name(s)</th>
                                            <th>Relationship</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr *ngFor="let GP of model.other_familyMembers">
                                            <td>{{GP.familyName}}</td>
                                            <td>{{GP.givenName}}</td>
                                            <td>{{GP.relationship}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div *ngIf="model.other_familyMembers?.length == 0">
                                <li class="collection-header">
                                    <p>No Additional Family Infomation Added.</p>
                                </li>
                            </div>
                        </ul>

                        <ul class="collection with-header col s12">
                            <li class="collection-header">
                                <h5><strong>SECTION 6: Photo and Signature to Appear on the Secure Certificate of Indian
                                        Status (SCIS)</strong></h5>
                            </li>
                            <li class="collection-item col s12">
                                <div>SCIS Requested<span class="secondary-content">{{model.SCISRequested ? 'Yes' :
                                        'No'}}</span>
                                </div>
                            </li>
                            <h6 class="col s12"><strong>Photo To Appear on the Secure Certificate of Indian
                                    Status(SCIS)</strong></h6>
                            <div *ngIf="model.photo">
                                <table>
                                    <thead>
                                        <tr>
                                            <th>Name of the studio or Person Who took the photo</th>
                                            <th>Address of Studio or Person Who took the photo</th>
                                            <th>The Date the photo was taken</th>
                                            <th>Photo</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>{{model.photoBy}}</td>
                                            <td>{{model.photoAddress}}</td>
                                            <td>{{model.photoDate}}</td>
                                            <td *ngIf="model.photo"><a [href]="model.photo.file_url" target="_blank"
                                                    class="right">{{model.photo.file_name}}</a></td>
                                            <td *ngIf="!model.photo">No Photo was Uploaded</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <li class="collection-item col s12" *ngIf="!model.photo">
                                <div>No Photo was Uploaded</div>
                            </li>
                            <h6 class="col s12"><strong>Signature To Appear on the Secure Certificate of Indian
                                    Status(SCIS)</strong></h6>
                            <li class="collection-item col s12" *ngIf="model.signature">
                                <img [src]="signature" alt="Signature" />
                            </li>
                            <li class="collection-item col s12" *ngIf="!model.signature">
                                No Signature Provided.
                            </li>
                        </ul>

                        <ul class="collection with-header col s12">
                            <li class="collection-header">
                                <h5><strong>SECTION 7: Declaration and Signature</strong></h5>
                            </li>
                            <li class="collection-item col s12">
                                <p>
                                    I, <span class="blue-text">{{model.applicantName}}</span> requested to be registered
                                    in the Indian Register and, if applicable, to have
                                    my name entered on a First Nation/Band List, as provided for under the Indian Act. I
                                    further request to have a Secure Certificate of Indian
                                    Status issued to me (if applicable).
                                </p>
                            </li>
                            <li class="collection-item col s12">
                                <div>Signature<span class="secondary-content"> <img [src]="signature" width="100px"
                                            alt="Signature" /></span>
                                </div>
                            </li>
                            <li class="collection-item col s12">
                                <div>Date<span class="secondary-content">{{model.signatureDate}} </span>
                                </div>
                            </li>
                        </ul>

                        <ul class="collection with-header col s12">
                            <li class="collection-header">
                                <h5><strong>SECTION 8: Indian Registration Administrator(IRA)</strong></h5>
                            </li>
                            <li class="collection-item col s12">
                                <div>IRA Assisted in Completing this form
                                    <span class="secondary-content" *ngIf="model.IRA_assisted">Yes</span>
                                    <span class="secondary-content" *ngIf="!model.IRA_assisted">No</span>
                                </div>
                            </li>
                            <div *ngIf="model.IRA_assisted">
                                <li class="collection-item col s12">
                                    <div>IRA Name<span class="secondary-content">{{model.IRA_name}} </span>
                                    </div>
                                </li>
                                <li class="collection-item col s12">
                                    <div>First Nation/Band Number
                                        or Initiator Code<span class="secondary-content">{{model.bandNo_initiatorCode}}
                                        </span>
                                    </div>
                                </li>
                            </div>
                        </ul>
                    </div>

                    <div class="row col s12" *ngIf="summary1">
                        <button *ngIf="summary1" type="button" (click)="previous()"
                            class="col s12 m4 waves-effect blue btn" style="margin-bottom: 15px;">Back to
                            Application<i class="material-icons right">Back to The Application</i></button>
                        <button type="button" (click)="summaryNext()" style="margin-bottom: 15px;"
                            class="col s12 m4 offset-m4 waves-effect blue btn">Continue<i
                                class="material-icons right">last_page</i></button>
                    </div>

                    <div class="row col s12" *ngIf="summary2">
                        <button type="button" (click)="summaryBack()" class="col s12 m4 waves-effect blue btn"
                            style="margin-bottom: 15px;">Back<i class="material-icons left">first_page</i></button>
                        <button *ngIf="summary2" type="button" (click)="SubmitApplication()"
                            style="margin-bottom: 15px;" class="col s12 m4 offset-m4 waves-effect green btn">Submit<i
                                class="material-icons right">send</i></button>
                    </div>

                </div>
            </div>

            <div class="col l1"></div>
        </div>
    </div>
</div>

<div class="row" *ngIf="step == 10">
    <div class="col m1 l2"></div>
    <div class="col s12 m10 l8  ">
        <div class="card">
            <div class="card-content">

                <div class="row center">
                    <i style="font-size: 100px;" class="material-icons green-text">
                        check_circle
                    </i>

                    <p class="green-text"><strong>
                            Thank you <span style="text-transform: capitalize;">{{model.applicantName}} </span> for
                            taking
                            the time to fill the SCIS Application.
                        </strong></p>
                    <br />
                    <div>
                        <button type="button" (click)="downloadReport()"
                            class=" col s12 m4  waves-effect blue lighten-4 black-text btn"
                            style="margin-bottom: 15px;">Download
                            Application<i class="material-icons left">download</i></button>

                        <button type="button" (click)="sendReport()" style="margin-bottom: 15px;"
                            class=" col s12 m4 offset-m4 waves-effect green lighten-2 btn">Share Application<i
                                class="material-icons right">share</i></button>
                        <br />
                        <button type="button" (click)="back()" style="margin-bottom: 15px;"
                            class="btn-flat red-text">close<i class="material-icons right">close</i></button>
                    </div>

                    <p><strong>Our Business Hours Are : <br> Monday - Friday 9 AM - 5 PM</strong></p>
                    <br />
                    <p class="blue-text"> <strong>
                            Have a wonderful day. <span class="material-icons">
                                emoji_emotions
                            </span>
                        </strong></p>
                </div>
            </div>
        </div>
    </div>
    <div class="col m1 l2"></div>

</div>

<div *ngIf="sendEmail" class="row col s12">
    <div class="col l1"></div>
    <div class="col s12 s10">
        <div>
            <app-send-email [emailList]="emailList" [fileList]="fileList" (emailSent)="emailSentHandler($event)">
            </app-send-email>
        </div>
    </div>
</div>