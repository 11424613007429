<div class="row center-align p-m-2">
  <h6><strong> Session Details </strong></h6>
</div>

<div class="row col s12" *ngIf="selectedSession">
  <div class="col l1 "></div>
  
  <div class="row col s12 m12 l10">
    <div class="row">
      <div class="col s12 m6">
        <div class="col s4 teal-text">
          <strong>Login:</strong>
        </div>
        <div class="col s8" *ngIf="selectedSession.sessionStart">
          {{selectedSession.sessionStart.toDate() | date: 'MMM d, y, h:mm:ss a'}}
        </div>
      </div>

      <div class="col s12 m6">
        <div class="col s4 teal-text">
          <strong>Logout:</strong>
        </div>
        <div class="col s8" *ngIf="selectedSession.sessionEnd">
          {{selectedSession.sessionEnd.toDate() | date: 'MMM d, y, h:mm:ss a'}}
        </div>
      </div>

      <div class="col s12 m6">
        <div class="col s4 teal-text">
          <strong>Duration:</strong>
        </div>
        <div class="col s8">
          {{getDateDiff(selectedSession.sessionStart, selectedSession.sessionEnd)}}
        </div>
      </div>

      <div class="col s12 m6">
        <div class="col s4 teal-text">
          <strong>Platform</strong>
        </div>
        <div class="col s8">
          {{selectedSession.device}}
        </div>
      </div>

      <div class="col s12 m6">
        <div class="col s4 teal-text">
          <strong>Device</strong>
        </div>
        <div class="col s8">
          {{selectedSession.deviceType}}
        </div>
      </div>

      <div class="col s12 m6">
        <div class="col s4 teal-text">
          <strong>IP :</strong>
        </div>
        <div class="col s8">
          {{selectedSession.ipAddress}}
        </div>
      </div>

      <div class="col s12 m6">
        <div class="col s4 teal-text">
          <strong>Latitude:</strong>
        </div>
        <div class="col s8">
          {{selectedSession.latitude}}
        </div>
      </div>

      <div class="col s12 m6">
        <div class="col s4 teal-text">
          <strong>Longitude:</strong>
        </div>
        <div class="col s8">
          {{selectedSession.longitude}}
        </div>
      </div>
    </div>

    <div class="row card-panel white">
      <h6 class="center" style="margin-bottom: 25px; margin-top: 15px;"><strong>Location</strong></h6>
      <div class="row">
        <div class="row">
          <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom">
            <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
          </agm-map>
        </div>

        <div class="row">
          <ul class="collection with-header col s12">
            <li class="collection-header">
              <h6>Location</h6>
            </li>
            <li class="collection-item">
              <div>Latitude<a class="secondary-content">{{lat}} </a></div>
            </li>
            <li class="collection-item">
              <div>Longitude<a class="secondary-content">{{lng}} </a></div>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="row" *ngIf="selectedSession.activity && selectedSession.activity.length > 0">
      <hr />
      <table class="striped centered">
        <thead>
          <tr>
            <th>Page </th>
            <th>Action</th>
            <th>Date</th>
          </tr>
        </thead>

        <tbody>
          <tr *ngFor="let a of sselectedSessions.activity">
            <td>{{a.page}}</td>
            <td>{{a.action}}</td>
            <td>{{a.date.toDate() | date: 'h:mm:ss a'}}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="row col s12">
      <a [ngClass]="'col s12 btn waves-effect ' + themeColor" (click)="backClicked()"><i
          class="material-icons left">arrow_back_ios</i> Back To Previous Page</a>
    </div>
  </div>

  <div class="col l1 "></div>
</div>