import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { AuthService } from './auth.service';

declare var M: any;

@Injectable({
  providedIn: 'root'
})

export class FileSharingServiceService {

  sharedfilesCollectionRef: AngularFirestoreCollection<any>;
  sharedfileRef: AngularFirestoreDocument<any>; 

  userID: any;
  userName: any
  userBasePath: any;
  constructor(
    private authService: AuthService,
    private db: AngularFirestore, 
    private storage: AngularFireStorage
    ) { 
    this.userID = this.authService.getGlobalUser().uid;
    this.userName = this.authService.getGlobalUser().LastName + '-' + ((this.authService.getGlobalUser().GivenName) ? this.authService.getGlobalUser().GivenName : this.authService.getGlobalUser().FirstName);
    this.userBasePath = this.userName + '-' + this.userID + '/';
  }

  private saveFile(model: any) {    
    return this.db.collection('FileSharing').doc(model.uid).set(model);
  }

  pushFileToStorage(model: any, file: File): Observable<number> {
    const filePath = this.userBasePath + file.name;
    const storageRef = this.storage.ref(filePath);
    const uploadTask = this.storage.upload(filePath, file);  
    uploadTask.snapshotChanges().pipe(
      finalize(() => {
        storageRef.getDownloadURL().subscribe(downloadURL => {
          model.uid = this.db.createId();
          model.ownerName = this.userName;
          model.ownerID = this.userID;
          model.fileUrl = downloadURL;
          model.fileName = file.name;
          model.dateShared = new Date();
          model.fileType = file.type,
          model.fileSize = Math.round(file.size / 1024) + " KB",
          this.saveFile(model).then(x => {
            M.toast({ html: 'File Successfully Shared', classes: 'green' });
          });
        });
      })
    ).subscribe();  
    return uploadTask.percentageChanges();
  }

  getUserFiles() : AngularFirestoreCollection<any> {
    this.sharedfilesCollectionRef = this.db.collection('FileSharing', ref => ref.where('ownerID', '==', this.userID));
    return this.sharedfilesCollectionRef;
  }
}
