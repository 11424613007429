import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { FamilyCompositionModel } from 'src/app/models/FamilyCompositionModel';

@Injectable({
  providedIn: 'root'
})

export class FamilyCompositionService {
  
  constructor(private db: AngularFirestore) { }

  familyMember: AngularFirestoreDocument<any>;
  familyMembersList: AngularFirestoreCollection<any>;

  addFamilyMember(model: FamilyCompositionModel) {
    const newContent = {
      id: this.db.createId(),
      relativeId: model.memberId,
      firstName: model.firstName,
      lastName: model.lastName,
      DOB: model.DOB,
      SIN: model.SIN,
      ISN: model.ISN,
      relation: model.relation,
      email: model.email,
      mobile: model.mobile
    };
    return this.db.collection('FamilyComposition').doc(newContent.id).set(newContent);
  }

  getFamilyMembersList(memberId: any): AngularFirestoreCollection<any[]> {
    try {
      this.familyMembersList = this.db.collection('FamilyComposition', ref => ref.where('relativeId', '==', memberId));
      return this.familyMembersList;
    } catch (error) {
    }
  }

  getFamilyMember(id: string): AngularFirestoreDocument<any> {
    this.familyMember = this.db.doc('FamilyComposition/' + id);
    return this.familyMember;
  }

  updateFamilyMember(model: any, id: string) {
    const newContent = {
      id: id,
      relativeId: model.memberId,
      firstName: model.firstName,
      lastName: model.lastName,
      DOB: model.DOB,
      SIN: model.SIN,
      ISN: model.ISN,
      relation: model.relation,
      email: model.email,
      mobile: model.mobile
    };
    return this.db.collection('FamilyComposition').doc(id).update(newContent);
  }

  deleteFamilyMember(id: string) {
    return this.db.collection('FamilyComposition').doc(id).delete();
  }

}