<div class="col s12 ">
    <div class="body-h">
<!-- <div class="row center-align">
    <h5><strong> House Management </strong></h5>
</div> -->

<div class="header-setting b-m-2 ">
    <div class="col l12 m12 s12  p-b-2">
      <h4>House Management</h4>
    </div>
  </div>


<app-firebase-loading *ngIf="loading"></app-firebase-loading>

<main class="row  StickyContent" *ngIf="property && occupants">
    <div class="col s12 l12">
        <div>
            <app-property-details [model]="property" [occupants]="occupants" [from]="'A'">
            </app-property-details>
        </div>

        <div class="row col s12 card">

            <button class="btn col s12  waves-effect green" style="margin-bottom: 15px;"
                [routerLink]="['/admin/dashboard-sub-menu/update-property', propertyId]">
                <i class="material-icons right">edit</i> Update House
            </button>

            <a class='col s12 btn waves-effect blue dropdown-trigger' data-target='dropdown1'
                [ngClass]="{'disabled': !property || !occupants}" style="margin-bottom: 15px;"><i
                    class="material-icons right">picture_as_pdf</i>Get Report</a>

            <ul id='dropdown1' class='dropdown-content'>
                <li><a (click)="downloadReport()" class="btn-flat center-align"><i class="material-icons right">
                            download
                        </i>Download Report</a></li>
                <li><a (click)="openReport()" class="btn-flat center-align"><i class="material-icons right">
                            open_in_new
                        </i>Open Report</a></li>
                <li><a (click)="printReport()" class="btn-flat center-align"><i class="material-icons right">
                            print
                        </i>Print Report</a></li>
                <li><a (click)="sendReport()" class="btn-flat center-align"><i class="material-icons right">
                            share
                        </i>Send Report</a></li>
            </ul>

            <button type="button" style="margin-bottom: 15px;" data-target="clearHoH" *ngIf="ownerSelected"
                class="col s12 btn waves-effect purple modal-trigger">
                <i class="material-icons right">person_remove</i> Clear HoH
            </button>

            <button type="button" style="margin-bottom: 15px;" data-target="deleteConfirmation"
                class="col s12 btn waves-effect red modal-trigger">
                <i class="material-icons right">delete</i> Delete House
            </button>

            <button [ngClass]="'col s12 btn waves-effect ' + themeColor" (click)="back()">
                <i class="material-icons left">arrow_back_ios</i> Back To Previous Page
            </button>

        </div>
    </div>
</main>

<div id="deleteConfirmation" class="modal">
    <div class="modal-content">
        <h4>Delete House</h4>
        <h6>Are you sure you want to permantly delete this House? </h6>
        <p></p>
    </div>
    <div class="modal-footer">
        <a class="waves-effect red-text btn-flat" (click)="deleteHouse()"><i class="material-icons">
                delete
            </i>Delete</a>
        <a class="modal-close waves-effect waves-green btn-flat">Cancel</a>
    </div>
</div>

<div id="clearHoH" class="modal">
    <div class="modal-content">
        <h5>Remove Head Of House Hold</h5>
        <h6>Are you sure you want to Remove the Head of Household of this property?
        </h6>
        <p></p>
    </div>
    <div class="modal-footer">
        <a class="waves-effect red-text btn-flat" (click)="removeHoH()"><i class="material-icons">
            person_remove
            </i>Clear HoH</a>
        <a class="modal-close waves-effect waves-green btn-flat">Cancel</a>
    </div>
</div>

<div id="memberListModal" class="modal" *ngIf="members">
    <div class="modal-content">
        <h4>Members</h4>

        <form class="row" #filterForm="ngForm" style="padding: 10px;">

            <div class="input-field col s12 m6">
                <input name="firstName" type="text" [(ngModel)]="filters.firstName" autocomplete="off"
                    (input)="filterMembers()" style="text-transform: capitalize;" />
                <label for="firstName">First Name</label>
            </div>

            <div class="input-field col s12 m6">
                <input name="lastName" type="text" [(ngModel)]="filters.lastName" autocomplete="off"
                    (input)="filterMembers()" style="text-transform: capitalize;" />
                <label for="lastName">Last Name</label>
            </div>
        </form>

        <div class="row card white">
            <table class="highlight centered" *ngIf="members.length >0">
                <thead>
                    <tr>
                        <th>ID</th>
                        <th>First Name</th>
                        <th>Last Name</th>
                    </tr>
                </thead>

                <tbody>
                    <tr *ngFor="let m of filtredMembers? filtredMembers : members | paginate: { itemsPerPage: 20, currentPage: p }; let i=index;"
                        (click)="selectHoH(m)" style="cursor: pointer;">
                        <td>{{m.applicantID}}</td>
                        <td>{{m.GivenName}}</td>
                        <td>{{m.LastName}}</td>
                    </tr>
                </tbody>
            </table>
            <div class="row center">
                <pagination-controls (pageChange)="p = $event"></pagination-controls>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <a class="modal-close waves-effect waves-light btn-flat">Close</a>
    </div>
</div>

<div *ngIf="sendEmail" class="row col s12">
    <div class="col l1"></div>
    <div class="col s12 s10">
        <div>
            <app-send-email [emailList]="emailList" [fileList]="fileList" (emailSent)="emailSentHandler($event)">
            </app-send-email>
        </div>
    </div>
</div>
</div>
</div>