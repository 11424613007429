import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { GovernanceService } from 'src/app/services/firebase/governance.service';
import { environment } from 'src/environments/environment';
import { toast } from 'materialize-css';

const pdfMake = require('pdfmake/build/pdfmake.js');
const pdfFonts = require('pdfmake-unicode/dist/pdfmake-unicode.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;

@Component({
  selector: 'app-boards-list',
  templateUrl: './boards-list.component.html',
  styleUrls: ['./boards-list.component.css']
})

export class BoardsListComponent implements OnInit {

  themeColor = environment.appTheme.adminThemeColor;
  defaultThemeColor = environment.appTheme.themeColor;
  buttonType: any;
  sectionName: any;
  model: any[];

  submenus: any[];
  editName = false;
 
  constructor(private govService: GovernanceService, 
    private location: Location,
    public appSettingsService: AppSettingsService) { 
    this.themeColor = this.appSettingsService.getAppThemeColor() ? this.appSettingsService.getAppThemeColor(): this.defaultThemeColor;
    this.buttonType =  this.appSettingsService.getButtonType() ? this.appSettingsService.getButtonType(): 'rectangularButton';
  }

  ngOnInit() {
    this.appSettingsService.getMenuName('MyNation').valueChanges().subscribe(menus => {
      if (menus && menus.length > 0) {
        this.submenus = menus[0].submenus;
        let menu = this.submenus.find(m => m.name == 'Board');
        if (menu) this.sectionName = menu.displayName;
      }
    });
    this.govService.getBoardList().valueChanges().subscribe(bList => {
      if (bList) {
        this.model = bList;
      }
    });
  }

  editNameClicked() {
    this.editName = true;
  }

  saveName() {
    let index = this.submenus.findIndex(m => m.name == 'Board');
    this.submenus[index].displayName = this.sectionName;
    this.appSettingsService.UpdateMenu('MyNation', this.submenus).then((update) => {
      this.editName = false;
      toast({ html: `${update}`, classes: 'green' });
    }).catch((error) => {
      toast({ html: `${error}`, classes: 'red' });
    });
  }

  backClicked() {
    this.location.back();
  }

  async openPdf(index) {
    const documentDefinition = await this.getDocumentDefinition(index);
    pdfMake.createPdf(documentDefinition).open();
  }

  async downloadPdf(index) {
    const documentDefinition = await this.getDocumentDefinition(index);
    pdfMake.createPdf(documentDefinition).download();
  }

  async printPdf(index) {
    const documentDefinition = await this.getDocumentDefinition(index);
    pdfMake.createPdf(documentDefinition).print();
  }
  
  async getDocumentDefinition(index) {
    const board = this.model[index];
    let contentM = [];
    contentM = [
      {
        style: 'table',
        layout: 'noBorders',
        table: {
          widths: ['*', '*'],
          body: [
            [
              {
                columns: [

                  [
                    {
                      stack: [
                        {
                          text: 'MY NATION ',
                          style: 'mainTitle',
                          alignment: 'center',
                        },
                        {
                          text: 'Connecting Your Nation',
                          style: 'normalText',
                          alignment: 'center',
                        },
                        {
                          text: 'Sovereign Data For Sovereign Nations ',
                          fontSize: 8,
                          italics: true,
                          alignment: 'center',
                        },
                        // {
                        //   text: 'Customize the app to your Nation,Tribal Council or Political Organization unique requirements.',
                        //   fontSize: 8,
                        // },
                      ]
                    },
                  ],
                ]
              },
              {
                stack: [
                  {},
                  {
                    text: board.name.toUpperCase(),
                    style: 'boardName'
                  },
                  {},
                ]
              },
            ],
          ]
        }
      },
      {
        style: 'table',
        table: {
          widths: ['*', '*', '*'],
          body: [
            [
              {
                stack: [
                  {
                    text: 'NAME: ',
                    style: 'subTitle'
                  },
                  {
                    text: board.name.toUpperCase(),
                    style: 'boardName'
                  },
                ]
              },

              {
                stack: [
                  {
                    text: 'Mandate: ',
                    style: 'subTitle'
                  },
                  {
                    text: board.mandate,
                    style: 'normalText'
                  },
                  {
                    text: board.phone,
                    style: 'normalText'
                  }
                ],
                colSpan: 2
              },
              {},

            ],
          ]
        }
      },
      {
        text: 'Board Chair',
        style: 'subheader'
      },
      {
        table: {
          headerRows: 1,
          widths: [ '*', '*', '*', '*' ],

          body: [
            [ {text: 'First Name', bold: true},
              {text: 'Last Name', bold: true},
              {text: 'Elected on', bold: true},
              {text: 'Mandate ends on', bold: true}
           ]
          ]
        }
      },
    ];

    board.boardChair.forEach(boardChairO => {
      contentM.push(
      {
        table: {
          headerRows: 1,
          widths: [  '*', '*', '*', '*' ],

          body: [

            [
              boardChairO.firstName, boardChairO.lastName, boardChairO.appointmentDate, boardChairO.expiryDate]
          ]
        }
      });
    });
    contentM.push(
      {
      text: 'Board Member',
      style: 'subheader'
      },       {
        table: {
          // headers are automatically repeated if the table spans over multiple pages
          // you can declare how many rows should be treated as headers
          headerRows: 1,
          widths: [ '*', '*', '*', '*' ],

          body: [
            [ {text: 'First Name', bold: true},
              {text: 'Last Name', bold: true},
              {text: 'Elected on', bold: true},
              {text: 'Mandate ends on', bold: true}
           ]
          ]
        }
      },
)
    board.boardMembers.forEach(boardMember => {
      contentM.push(

        {
        table: {
          // headers are automatically repeated if the table spans over multiple pages
          // you can declare how many rows should be treated as headers
          headerRows: 1,
          widths: [  '*', '*', '*', '*' ],

          body: [

            [
              boardMember.firstName, boardMember.lastName, boardMember.appointmentDate, boardMember.expiryDate]
          ]
        }
      });
    });

    return {
      info: {
        title: board.name,
        subject: board.name,
        keywords: board.name,
      },
      pageSize: 'LETTER',
      content: contentM,
      styles: {
        mainTitle: {
          bold: true,
          fontSize: 16,
          alignment: 'left',
          margin: [0, 0, 0, 20]
        },
        title: {
          bold: true,
          fontSize: 14,
          alignment: 'center',
          margin: [0, 10, 0, 10]
        },
        subTitle: {
          bold: true,
          fontSize: 12,
          alignment: 'left',
          margin: [0, 10, 0, 10]
        },
        boardName: {
          fontSize: 11,
          color: board.nameColor
        },
        header: {
          fontSize: 12,
          bold: true,
          alignment: 'center',
        },
        subheader: {
          fontSize: 16,
          bold: true,
          margin: [0, 10, 0, 5]
        },
        links: {
          color: 'blue',
          decoration: 'underline',
          margin: [0, 5, 0, 5]
        },
        table: {
          margin: [0, 5, 0, 5]
        },
      }
    };
  }
  
  public getInitials(boardName: any) {
    if (boardName) {
     boardName = boardName.replace(/<(?:.|\n)*?>/gm, '');
     return boardName.substring(0, 1);
    } else {return ''; }
  }

}
