import { Component, Input, OnInit } from '@angular/core';
//import { EmbedVideoService } from 'ngx-embed-video';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { SectionSettingService } from 'src/app/services/firebase/section-setting.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-section-list',
  templateUrl: './section-list.component.html',
  styleUrls: ['./section-list.component.css']
})
export class SectionListComponent implements OnInit {

  defaultThemeColor = environment.appTheme.themeColor;
  themeColor: any;

  @Input() id: any
  section: any;
  model: any[];
  displayLimit = 2;
  iframehtml: any;
  videoId: any;

  constructor(private appSettingService: AppSettingsService,
    private sectionService: SectionSettingService,
    // private embedService: EmbedVideoService
  ) {
    this.themeColor = this.appSettingService.getAppThemeColor() ? this.appSettingService.getAppThemeColor() : this.defaultThemeColor;
  }

  ngOnInit(): void {
    if (this.id) {
      this.sectionService.getSectionById(this.id).valueChanges().subscribe(section => {
        if (section) this.section = section;
      });

      this.sectionService.getSectionContent(this.id).valueChanges().subscribe(contents => {
        if (contents && contents.length > 0) {
          this.model = contents;
          this.model = this.model.sort((a, b) => (a.datePublished > b.datePublished ? -1 : 1));
          this.model = this.model.splice(0, this.displayLimit);
        }
      });
    }
  }

  truncateHTML(text: string, limit: string): string {
    const charlimit = +limit;
    if (!text || text.length <= charlimit) {
      return text.replace(/<(?:.|\n)*?>/gm, '').replace(/&nbsp;/gm, ' ');
    }
    return text.replace(/<(?:.|\n)*?>/gm, '').replace(/&nbsp;/gm, ' ').substring(0, charlimit) + '...';
  }

  embedVideo(videoLink) {
    const videoIdMatch = videoLink.match(/(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([^&]+)|(?:https?:\/\/)?(?:www\.)?youtu\.be\/([^?]+)/);
    this.videoId = videoIdMatch ? (videoIdMatch[1] || videoIdMatch[2]) : '';
    return this.videoId;
  }

  navigate() {

  }

}
