import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { AdminPosts, Comment } from 'src/app/models/AdminPosts.Model';
import { AdminSavedAudiences } from 'src/app/models/AdminSavedAudiences.Model';



@Injectable({
  providedIn: 'root'
})
export class AdminPostsService {

  postsListRef: AngularFirestoreCollection<any>;
  postRef: AngularFirestoreDocument<any>;

  constructor(private db: AngularFirestore) { }

  createPost(model: AdminPosts){

    const newContent = {
      id: this.db.createId(),
      createdById: model.createdById,
      audienceId: model.audienceId,
      audienceList: model.audienceList,
      message: model.message,
      mediaLink: model.mediaLink,
      timeStamp: model.timeStamp,
      reactions: model.reactions,
      comments: model.comments,
      archived: false,
  }
    return this.db.collection('Admin-Posts').doc(newContent.id).set(newContent);
  }
  
  getPostsByAdminId(id, audienceId){
    if(audienceId == ''){
      this.postsListRef = this.db.collection('Admin-Posts', ref => ref.where('createdById', '==', id).where('archived', '==', false));
      return this.postsListRef;
    }else{
      this.postsListRef = this.db.collection('Admin-Posts', ref => ref.where('createdById', '==', id).where('audienceId', '==', audienceId));
      return this.postsListRef;
    }
    
  }

  getPostByMemberId(id){
    this.postsListRef = this.db.collection('Admin-Posts', ref => ref.where('audienceList', "array-contains", id).where('archived', '==', false));
    return this.postsListRef;
  }
  updateReactionOfApost(post){
    return this.db.collection('Admin-Posts').doc(post.id).update({reactions: post.reactions});


  }
  addCommentToPost(post, newCommentModel: Comment){
    const newContent = {
      id: this.db.createId(),
      postId: newCommentModel.postId,
      userId: newCommentModel.userId,
      userName: newCommentModel.userName,
      comment: newCommentModel.comment,
      timestamp: newCommentModel.timestamp,
      likes: newCommentModel.likes,
      replies: newCommentModel.replies
  }
    post.comments.push(newContent);
    return this.db.collection('Admin-Posts').doc(post.id).update({comments: post.comments});
  }

  updateComments(post){
    return this.db.collection('Admin-Posts').doc(post.id).update({comments: post.comments});

  }

  editPost(post){
    return this.db.collection('Admin-Posts').doc(post.id).update(post);

  }
  createAudience(model: AdminSavedAudiences){
    const newContent = {
      id: this.db.createId(),
      adminId: model.adminId,
      saved: model.saved,
      age:model.age,
      gender:model.gender,
      community:model.community,
      postIds: model.postIds,
      membersId: model.membersId,
      audienceTitle: model.audienceTitle
      
  }
     this.db.collection('Admin-Saved-Audiences').doc(newContent.id).set(newContent);
     return newContent;
  }

  getPostsByAudienceId(audienceId, adminId){
    this.postsListRef = this.db.collection('Admin-Posts', ref => ref.where('createdById', '==', adminId).where('audienceId', '==', audienceId));
    return this.postsListRef;

  }
  getSavedAudienceByAdminId(adminId){
    return this.db.collection('Admin-Saved-Audiences', ref => ref.where('adminId', "==", adminId).where('saved', '==', true));
  }

  archivePost(id){
    return this.db.collection('Admin-Posts').doc(id).update({archived: true});

  }
  
}
