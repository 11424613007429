import { Component, OnInit } from '@angular/core';
import { EntitlementService } from 'src/app/services/api/entitlement.service';
import { Location } from '@angular/common';
import { EntitlementModel } from 'src/app/models/entitlementModel';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { environment } from 'src/environments/environment';

declare var $: any;

@Component({
  selector: 'app-entitlement-detail',
  templateUrl: './entitlement-detail.component.html',
  styleUrls: ['./entitlement-detail.component.css']
})
export class EntitlementDetailComponent implements OnInit {

  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  id: string;
  applicantID = 0;
  entitlement: EntitlementModel[] = [];
  paidTotal = 0;
  // tslint:disable-next-line: max-line-length
  constructor(private entitlementService: EntitlementService, private location: Location, private route: ActivatedRoute, private authService: AuthService) {  localStorage.setItem('from', 'SocialAssistance');}

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });
    // if id is string then its a b&d number else its a monthly requirement id
    if (this.id) {
      this.entitlementService.getEntitlementByID(this.id).subscribe(x => {
        this.entitlement = x;
        this.paidTotal = this.entitlement.reduce((prev, cur) => {
          return prev + cur.Amount;
        }, 0);
        setTimeout(() => {
          $('.collapsible').collapsible();
        }, 25);
      });
    } else {

      if (localStorage.getItem('applicantID')) {
        this.applicantID = Number(localStorage.getItem('applicantID'));
        } else {
          this.applicantID = this.authService.getCurrentApplicantID();
        }

      this.entitlementService.getCurrentEntitlement(this.applicantID).subscribe(x => {
        this.entitlement = x;
        this.paidTotal = this.entitlement.reduce((prev, cur) => {
          return prev + cur.Amount;
        }, 0);
        setTimeout(() => {
          $('.collapsible').collapsible();
        }, 25);
      });
    }
  }

  backClicked() {
    this.location.back();
  }
}
