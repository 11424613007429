<div class="col s12">
    <div class="body-h">

        <div class="header-setting b-m-2">
            <h4 class="">Disabled Members </h4>
        </div>

        <main class="StickyContent white ">
            <div class="row col s12" *ngIf="!showDetail">
                <div class="row card-panel white" *ngIf="disabledMembers && disabledMembers.length >0">
                    <table class="row-border hover responsive-table">
                        <thead>
                            <tr>
                                <th></th>
                                <th>First Name</th>
                                <th>Last Name</th>
                                <th>Disabled Date</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody *ngFor="let m of disabledMembers; let i=index">
                            <tr>
                                <td><i *ngIf="!expand[i]" class="material-icons green-text text-lighten-2"
                                        (click)="expandDetails(i)" style="cursor: pointer;">
                                        add_box
                                    </i>
                                    <i *ngIf="expand[i]" class="material-icons red-text text-lighten-2"
                                        (click)="expandDetails(i)" style="cursor: pointer;">
                                        indeterminate_check_box
                                    </i>
                                </td>
                                <td><a (click)="showDetails(m.uid)" style="cursor: pointer;">{{m.GivenName}}</a></td>
                                <td><a (click)="showDetails(m.uid)" style="cursor: pointer;">{{m.LastName}} </a></td>
                                <td>{{m.statusDetails.updateDate.toDate() | date: 'dd/MMM/yyyy'}}</td>
                                <td><a class="btn green white-text tooltipped modal-trigger"
                                        data-target="confirmationModal" data-position="top" data-tooltip="Enable"
                                        (click)="selectMember(i)"><i class="material-icons">
                                            how_to_reg
                                        </i>&nbsp;Enable</a></td>
                            </tr>
                            <tr id="details" *ngIf="expand[i]">
                                <td colspan="4">
                                    <p class="col s12 m6"><strong>Disabled By: <br></strong>{{m.statusDetails.by}}</p>
                                    <p class="col s12 m6"><strong>Reason:<br> </strong>{{m.statusDetails.reason}}</p>
                                </td>
                                <td style="display: none;"></td>
                                <td style="display: none;"></td>
                                <td style="display: none;"></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div *ngIf="!disabledMembers || disabledMembers?.length === 0"
                    class="row card-panel red lighten-1 white-text">
                    <i class="material-icons left">
                        warning
                    </i>
                    <h6><strong>No disabled members found in the database.</strong></h6>
                </div>

                <div class="section"></div>

                <button class="col s12 white" style="border: 1px solid;"
                    [ngStyle]="{'border-color': themeColor, 'color': themeColor}" (click)="backClicked()"
                    [ngClass]="'btn-flat ' + buttonType"><i class="material-icons left">house</i>Back To Previous
                    Page</button>
            </div>
            <div class="col s12" *ngIf="showDetail">
                <app-client-detail [id]=memberId (back)="back()"></app-client-detail>
            </div>
            <div *ngIf="contact">
                <app-contact-members [message]=message [contactDetail]="contactDetail"
                    (success)="contactHandler($event)">
                </app-contact-members>
            </div>
        </main>
    </div>
</div>

<!-------modal----->
<div id="confirmationModal" class="modal modal_max">
    <div class="modal-content" *ngIf="selectedMember">
        <div>
            <h5><strong>Enable Account</strong></h5>
            <div class="valign-wrapper card-panel blue lighten-5">
                <i class="material-icons left blue-text" style="font-size: 40px;">
                    info
                </i>
                <div>
                    <h6>Are You sure you want to Enable this Account? </h6>
                    <h6><strong>Users with enabled accounts will be able to sign in again.</strong></h6>
                </div>
            </div>
        </div>

        <h6 class="grey-text">User account</h6>
        <h6><strong>{{selectedMember.Email}}</strong></h6>

        <br />
        <h6>Please Enter the Reason for <span *ngIf="isEnabled">Disabling</span> <span
                *ngIf="!isEnabled">Enabling</span>
            this account</h6>
        <div class="input-field col s12">
            <input placeholder="Reason" id="reason" type="text" [(ngModel)]="reason" class="validate">
        </div>
        <br />
        <p class="col s12" style="margin-bottom: 40px;">
            <strong>Do you wish to send a message to the client?</strong>
            <label class="right">
                <input type="checkbox" (click)="onSendEmail()" [checked]='sendEmail' />
                <span>Send Email</span>
            </label>
        </p>
    </div>
    <div class="modal-footer">
        <a class="modal-close waves-effect waves-light btn-flat"><strong>Cancel</strong></a>
        <a (click)="enableAccount()" class="modal-close btn waves-effect waves-light blue"
            [ngClass]="{'disabled': !reason}"><strong>Enable</strong></a>
    </div>
</div>

<div id="Details" class="modal">
    <div class="modal-content">
        <h4>Disabled Member</h4>
        <p>A bunch of text</p>
    </div>
    <div class="modal-footer">
        <a href="#!" class="modal-close waves-effect waves-green btn-flat">Agree</a>
    </div>
</div>