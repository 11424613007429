import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { SMSModel } from 'src/app/models/SMSModel';
import { environment } from 'src/environments/environment';
import { ApiKeyManagerService } from './apiKeyManager.Service';

@Injectable({
  providedIn: 'root'
})
export class SMSService {

  constructor(private httpClient: HttpClient, private keyManager: ApiKeyManagerService) { }
  url = environment.firstNation.apiUrl + 'api/SMS/';
  website = environment.firstNation.website;

  sendSMS(model: SMSModel):Observable<any>{
    const headers = new HttpHeaders({SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8'});
    return this.httpClient.post<any>(environment.firstNation.apiUrl + 'api/SMS/sendSMS/',JSON.stringify(model), {headers} );
  }
}
