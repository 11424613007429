import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { DocumentsService } from 'src/app/services/api/documents.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-document-detail',
  templateUrl: './document-detail.component.html',
  styleUrls: ['./document-detail.component.css']
})

export class DocumentDetailComponent implements OnInit {

  fileId: string;
  imageToShow: any;

  constructor(private route: ActivatedRoute, private documentsService: DocumentsService, private location: Location) { }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.fileId = params.get('id');
    });

    if (this.fileId !== null) {
      const id = +this.fileId;
      this.documentsService.getImage(id).subscribe(data => {
        if (data) {
          this.createImageFromBlob(data);
        }
      });
    }
  }

  createImageFromBlob(image: Blob) {
    const reader = new FileReader();
    reader.addEventListener('load', () => {
      this.imageToShow = reader.result;
    }, false);
    if (image) {
      reader.readAsDataURL(image);
    }
  }

  backClicked() {
    this.location.back();
  }

}
