import { Component, OnInit, Input } from '@angular/core';
import { StaticContentModel } from 'src/app/models/StaticContentModel';
import { environment } from 'src/environments/environment';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
//import { EmbedVideoService } from 'ngx-embed-video';

declare var $: any;

@Component({
  selector: 'app-news-feed',
  templateUrl: './news-feed.component.html',
  styleUrls: ['./news-feed.component.scss']
})

export class NewsFeedComponent implements OnInit {

  [x: string]: any;

  defaultThemeColor = environment.appTheme.themeColor;
  model: any;
  themeColor: any;
  iframehtml: any;
  videoId: any;

  @Input() callerForm: string;
  @Input() newsModel: StaticContentModel;
  @Input() hotNewsModel: StaticContentModel;

  constructor(private appSettingService: AppSettingsService,
    private location: Location,
    // private embedService: EmbedVideoService,
    private router: Router) {
    this.themeColor = this.appSettingService.getAppThemeColor() ? this.appSettingService.getAppThemeColor() : this.defaultThemeColor;
  }

  ngOnInit() {

    if (this.newsModel) {
      if (this.newsModel.type === undefined) {
        this.newsModel.type = 'Post';
      } else if (this.newsModel.type === 'Video') {
        this.videoId = this.embedVideo(this.newsModel.videoLink);
      }
    }

    if (this.hotNewsModel) {
      if (this.hotNewsModel.type === undefined) {
        this.hotNewsModel.type = 'Post';
      } else if (this.hotNewsModel.type === 'Video') {
        this.videoId = this.embedVideo(this.hotNewsModel.videoLink);
      }
    }
  }

  truncateHTML(text: string, limit: string): string {
    const charlimit = +limit;
    if (!text || text.length <= charlimit) {
      return text.replace(/<(?:.|\n)*?>/gm, '').replace(/&nbsp;/gm, ' ');
    }
    return text.replace(/<(?:.|\n)*?>/gm, '').replace(/&nbsp;/gm, ' ').substring(0, charlimit) + '...';
  }

  getHowLongAgo(postDate) {
    const datePipe = new DatePipe('en-US');
    let today = new Date();
    let difference = datePipe.transform(today.getTime() - postDate.getTime(), 'dd');
    return parseInt(difference);
  }

  navigateTo(id) {
    if (this.callerForm == 'News') {
      this.router.navigate(['/read-more', id]);
    } else if (this.callerForm == 'MemberNews') {
      this.router.navigate(['/member/news-detail', id]);
    } else if (this.callerForm == 'DepNews') {
      this.router.navigate(['/member/view-department-news', id]);
    } else if (this.callerForm == 'DepNews_Public') {
      this.router.navigate(['/view-department-news', id]);
    }
  }

  embedVideo(videoLink) {
    const videoIdMatch = videoLink.match(/(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([^&]+)|(?:https?:\/\/)?(?:www\.)?youtu\.be\/([^?]+)/);
    this.videoId = videoIdMatch ? (videoIdMatch[1] || videoIdMatch[2]) : '';
    return this.videoId;
  }

  backClicked() {
    this.location.back();
  }

}
