import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PollsAndSurveyParticipationService } from 'src/app/services/firebase/polls-and-survey-participation.service';
import { PollsAndSurveyService } from 'src/app/services/firebase/polls-and-survey.service';
import { ChartDataset, ChartOptions, ChartType } from 'chart.js';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-polls-result',
  templateUrl: './polls-result.component.html',
  styleUrls: ['./polls-result.component.css']
})

export class PollsResultComponent implements OnInit {

  pollId = '';
  pollModel: any;
  participationList: any;
  pollQuestion = [];
  numberOfAnswers = 0;
  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  loading = false;

  public barChartOptions: ChartOptions = {
    //scaleShowVerticalLines: false,
    responsive: true,
    // We use these empty structures as placeholders for dynamic theming.
    scales: {
      // yAxes: [
      //   {
      //     ticks: {
      //       stepSize: 1,
      //       beginAtZero: true,
      //     }
      //   }]
      y: {
        beginAtZero: true, 
        ticks: {
          stepSize: 1
        }
      }
    }
  };
  public barChartColors: Array<any> = [
    { // all colors in order
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(255, 159, 64, 0.2)',
        'rgba(255, 205, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(201, 203, 207, 0.2)'
      ],
      borderColor: [
        'rgb(255, 99, 132)',
        'rgb(255, 159, 64)',
        'rgb(255, 205, 86)',
        'rgb(75, 192, 192)',
        'rgb(54, 162, 235)',
        'rgb(153, 102, 255)',
        'rgb(201, 203, 207)'
      ],
      borderWidth: 1
    }
  ];
  public barChartType: ChartType = 'bar';
  public barChartLegend = false;
  public barChartPlugins = [];
  dynamicChartLabels = [];
  dynamicChartData = [];
  resultData = [];
  comments: any[];

  constructor(private route: ActivatedRoute, 
    private participationService: PollsAndSurveyParticipationService, 
    private pollService: PollsAndSurveyService, 
    private location: Location,) { }

  ngOnInit() {
    this.loading = true;
    this.route.paramMap.subscribe(params => {
      this.pollId = params.get('id');
    });

    if (this.pollId) {
      this.participationService.getPollParticipationByPollID(this.pollId).valueChanges().subscribe(part => {
        if (part) {
          this.participationList = part;
          if (this.participationList) {

            this.pollService.getPollByID(this.pollId).valueChanges().subscribe(poll => {
              if (poll) {
                this.pollModel = poll;

                this.pollModel.questions.forEach(question => {
                  if (question.answerType === 'Multiple Choice' || question.answerType === 'Scale') {
                    const questionChartLabels = [];
                    const totalCounts = [];
                    const questionChartData = [];

                    question.answers.forEach(answer => {
                      const ans = {
                        answer: answer.answer,
                        count: this.participationList.filter((el) => {
                          return el.answers.filter(function (item) {
                            return item.answer === answer.answer && item.questionNumber === question.questionNumber;
                          }).length;
                        }).length
                      }
                      totalCounts.push(ans.count);
                      questionChartLabels.push(ans.answer);
                    });

                    questionChartData.push({ data: totalCounts, label: 'Total' });
                    const result = {
                      questionNumber: question.questionNumber,
                      question: question.question,
                      answerType: question.answerType,
                      data: questionChartData,
                      labels: questionChartLabels,
                    }
                    this.resultData.push(result);
                  }
                  else {
                    var answers = [];
                    this.participationList.forEach(p => {
                      p.answers.forEach(item => {
                        if (item.questionNumber == question.questionNumber) {
                          answers.push(item.answer);
                        }
                      });
                    });

                    const result = {
                      questionNumber: question.questionNumber,
                      question: question.question,
                      answerType: question.answerType,
                      data: answers,
                      labels: "",
                    }
                    this.resultData.push(result);
                  }
                });
              }
            });
            this.comments = [];
            this.participationList.forEach(part => {
              if (part.comment && part.comment != '') {
                this.comments.push(part.comment);
              }
            });
          }
        }
      });
    }
    this.loading = false;
  }

  backClicked() {
    this.location.back();
  }
}
