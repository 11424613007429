import { Component, OnInit, OnDestroy, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { GovernanceService } from 'src/app/services/firebase/governance.service';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-manage-other-roles',
  templateUrl: './manage-other-roles.component.html',
  styleUrls: ['./manage-other-roles.component.css']
})
export class ManageOtherRolesComponent implements OnInit {
  user: any;
  isBoardMember = false;
  themeColor = environment.appTheme.adminThemeColor;

  constructor(private authService: AuthService,
    private router: Router,
    private location: Location,
    private governanceService: GovernanceService,) {
  }

  ngOnInit() {
    this.authService.fireMember.subscribe(data => {
      if (data) {
        this.user = data;
        this.governanceService.getBoardMemberByUserId(this.user.applicantID).valueChanges().subscribe((boardMember: any) => {
          if (boardMember.length > 0) {
            boardMember.forEach((bmDetail) => {
              if (!(bmDetail.status === 'Archived')) {
                this.isBoardMember = true;
              }
            });

          }
        });
      }
    });
  }

  boardMember() {
    this.router.navigate(['/admin/manage-board-member-acc']);
  }
  
  chief() {
    this.router.navigate(['/admin/manage-chief-account']);
  }

  councillor() {
    this.router.navigate(['/admin/manage-councillor-account']);
  }

  backClicked() {
    this.location.back();
  }

}

