import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { FireMembersService } from 'src/app/services/firebase/fire-members.service';
import { toast } from 'materialize-css';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';

declare var $: any;

@Component({
  selector: 'app-disabled-members',
  templateUrl: './disabled-members.component.html',
  styleUrls: ['./disabled-members.component.css']
})
export class DisabledMembersComponent implements OnInit {

  defaultThemeColor = environment.appTheme.themeColor;
  themeColor: any;
  buttonType: any;
  loading = false;
  loggedUser: any;
  disabledMembers: any[];
  dtOptions: any;
  selectedMember: any;
  reason = null;
  showDetail = false;
  memberId: any;
  expand: any[] = [];
  sendEmail = false;
  contactDetail: any = {
    firstName: '',
    lastName: '',
    email: '',
    phoneNo: '',
  }
  message: any = {
    title: '',
    subject: '',
    body: '',
    files: []
  }
  contact = false;

  constructor(private fireMembersService: FireMembersService,
    private authService: AuthService,
    private location: Location,
    private settingsService: AppSettingsService) {
    this.themeColor = this.settingsService.getAppThemeColor() ? this.settingsService.getAppThemeColor() : this.defaultThemeColor;
    this.buttonType = this.settingsService.getButtonType() ? this.settingsService.getButtonType() : 'rectangularButton';
    this.loggedUser = this.authService.getGlobalUser()
  }

  ngOnInit(): void {
    this.loading = true;
    this.fireMembersService.getMembersList().valueChanges().subscribe(x => {
      if (x) {
        this.disabledMembers = x.filter(e => e.status === 'Disabled');
        this.disabledMembers = this.disabledMembers.sort((a, b) => {
          return (b.statusDetails.updateDate.toDate() - a.statusDetails.updateDate.toDate());
        });
        this.expand = this.disabledMembers.map(m => false);
      }
      this.loading = false;
      setTimeout(() => {
        $('.tooltipped').tooltip();
      }, 25);
    });
  }

  expandDetails(index) {
    this.expand[index] = !this.expand[index];
  }

  showDetails(id) {
    this.memberId = id;
    this.showDetail = true;
  }

  selectMember(index) {
    this.selectedMember = this.disabledMembers[index];
    $('.modal').modal();
  }

  onSendEmail() {
    this.sendEmail = !this.sendEmail;
  }

  enableAccount() {
    let index = this.disabledMembers.indexOf(this.selectedMember);
    this.selectedMember.status = 'Enabled';
    this.selectedMember.statusDetails.reason = this.reason;
    this.selectedMember.statusDetails.by = this.loggedUser.FirstName + " " + this.loggedUser.LastName;
    this.selectedMember.statusDetails.updateDate = new Date();
    this.fireMembersService.updateAccount(this.selectedMember);
    this.disabledMembers.splice(index);
    this.reason = null;
    toast({ html: `${this.selectedMember.Email} Enabled`, classes: 'green rounded' });
    if (this.sendEmail) {
      this.contactDetail.firstName = this.selectedMember.GivenName;
      this.contactDetail.lastName = this.selectedMember.LastName;
      this.contactDetail.email = this.selectedMember.Email;
      this.contactDetail.phoneNo = this.selectedMember.cell_phone_num;
      this.message.subject = 'Account Enabled';
      this.message.title = 'AccountStatus';
      this.contact = true;
    }
  }

  contactHandler(valueEmitted) {
    if (valueEmitted.status) toast({ html: 'Message sent to client Successfully!', classes: 'green' });
    else toast({ html: 'Message Not sent to the client!', classes: 'red' });
  }

  back() {
    this.showDetail = false;
  }

  backClicked() {
    this.location.back();
  }
}
