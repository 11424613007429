import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
//import { EmbedVideoService } from 'ngx-embed-video';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { SectionSettingService } from 'src/app/services/firebase/section-setting.service';
import { environment } from 'src/environments/environment';

declare var $: any;

@Component({
  selector: 'app-custom-sections',
  templateUrl: './custom-sections.component.html',
  styleUrls: ['./custom-sections.component.css']
})
export class CustomSectionsComponent implements OnInit {

  themeColor: any;
  defaultThemeColor = environment.appTheme.themeColor;
  showLoading = false;
  sectionId: any;
  section: any;
  user: any;
  isSuperAdmin = false;
  permissions: any[];
  model: any[];
  iframehtml: any;
  videoId: any;

  constructor(private sectionService: SectionSettingService,
    public appSettingsService: AppSettingsService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private router: Router,
    //private embedService: EmbedVideoService,
    private fb: UntypedFormBuilder) {

    this.themeColor = this.appSettingsService.getAppThemeColor() ? this.appSettingsService.getAppThemeColor() : this.defaultThemeColor;

    this.authService.getCurrentApplicantIDObservable().subscribe(
      user => {
        if (user) {
          this.user = user;
          if (user.isSuperAdmin) this.isSuperAdmin = true;
          if (user.permissions) this.permissions = user.permissions;
          else if (!this.isSuperAdmin) {
            $('.modal').modal();
            $('#permissionModal').modal('open');
          }
        }
      }
    );
  }

  ngOnInit(): void {
    this. showLoading = true;
    this.route.paramMap.subscribe(params => {
      if (params.get('id')) {
        this.sectionId = params.get('id');
        this.sectionService.getSectionById(this.sectionId).valueChanges().subscribe(sec => {
          if (sec) {
            this.section = sec;
            this.sectionService.getSectionContent(this.sectionId).valueChanges().subscribe(content => {
              if(content && content.length > 0){
                this.model = content
                this.model.sort((a, b) => b.datePublished - a.datePublished);
              }
            })
          }
        });
      }
    });
    this.showLoading = false;
  }

  truncateHTML(text: string, limit: string): string {
    const charlimit = +limit;
    if (!text || text.length <= charlimit) {
      return text.replace(/<(?:.|\n)*?>/gm, '').replace(/&nbsp;/gm, ' ');
    }
    return text.replace(/<(?:.|\n)*?>/gm, '').replace(/&nbsp;/gm, ' ').substring(0, charlimit) + '...';
  }

  embedVideo(videoLink) {
    const videoIdMatch = videoLink.match(/(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([^&]+)|(?:https?:\/\/)?(?:www\.)?youtu\.be\/([^?]+)/);
    this.videoId = videoIdMatch ? (videoIdMatch[1] || videoIdMatch[2]) : '';
    return this.videoId;
  }

  addNew(){
    this.router.navigate(['admin/add-custom-section/', this.sectionId]);
  }
}
