<div *ngIf="model">
  <div class="row col l8 s12 m12 card white p-b-9">
    <div class="container6">
      <div class="col l12 s12 p-l-1">
        <h6 [innerHTML]="model.title"></h6>
      </div>

      <div class="p-l-1">
        <p class="date-content sub-title-section1 black-text bold-300">
          <span style="color: rgb(125, 125, 125)">Published by </span>
          {{ model.author }} |
          <span style="color: rgb(125, 125, 125)">Published date </span>
          {{
            model?.datePublished?.toDate()
              | date
                : "MMM dd,yyyy"
          }}
        </p>
      </div>

      <!-- comment and like  -->
      <div class="card-comments row p-l-1">
        <!-- Like Button -->

        <!-- Like Button -->
        <div class="col l3 s4" *ngIf="isLogged">
          <i
            class="fa"
            [class.fa-thumbs-up]="isLiked"
            [class.fa-thumbs-o-up]="!isLiked"
            aria-hidden="true"
            (click)="like(model.id)"
          ></i>
          <span>{{ likeCount }}</span>
        </div>

        <div
          class="col l3 s4 tooltipped"
          data-position="bottom"
          [attr.data-tooltip]="tooltipContent"
          *ngIf="!isLogged"
        >
          <i class="fa fa-thumbs-o-up"></i>
          <span>{{ likeCount }} likes</span>
        </div>

        <div
          class="col l3 s4"
          *ngIf="model.allowComments"
          style="cursor: pointer"
        >
          <i
            class="fa fa-comment-o"
            aria-hidden="true"
            (click)="scrollToComments()"
          ></i>
          <span> {{ model.comments.length }}</span>
        </div>

        <div class="col l8 s7"></div>
      </div>

      <div class="row">
        <div class="col l12 s12 about-img" *ngIf="model.type === 'Photo'">
          <img class="materialboxed responsive-img" [src]="model.imgLink" />
        </div>

        <div class="col l12 s12 about-vid" *ngIf="model.type === 'Video'">
          <div class="video-container">
            <youtube-player [videoId]="videoId"></youtube-player>
            <!-- <div [innerHTML]="iframehtml"></div> -->
          </div>
        </div>

        <div
          class="col l12 s12 about-iframe"
          *ngIf="model.type === 'PDF' && model.videoLink"
        >
          <iframe
            title="iframe-box2"
            [src]="model.videoLink | safeUrl"
          ></iframe>
        </div>

        <div
          class="col l12 s12 about-video"
          *ngIf="model.type === 'uploadVideo'"
        >
          <video class="responsive-video" controls>
            <source [src]="model.videoLink" type="video/mp4" />
          </video>
        </div>

        <!-- body-content -->
        <div class="col l12 s12 p-t-2">
          <span [innerHTML]="model.body | noSanitize"></span>
        </div>
      </div>

      <button
        class="btn-flat col l2 push-l3 comment-button hide-on-med-and-down white p-m-50"
        [ngStyle]="{ 'border-color': themeColor }"
        style="position: fixed"
      >
        {{ model.comments.length }} comments |
        {{ model?.datePublished?.toDate() | date : "MMM dd, yyyy" }}
      </button>

      <!-- =========  display comment =========== -->
      <div
        class="comments"
        #commentsSection
        id="commentsSection"
        *ngIf="model.allowComments"
      >
        <div class="fixed-title">
          <h4 class="title-section3 card bold-700">
            Comments <span class="bold-200">({{ model.comments.length }})</span>
          </h4>
        </div>

        <div *ngIf="comments.length > 0" class="scrollable-content">
          <div class="card row" *ngFor="let comment of comments">
            <div class="col s10 l10">
              <div class="content-column">
                <div class="user-info">
                  <span class="bold-600 sub-title-section2">{{
                    comment.userName
                  }}</span
                  ><br />
                  <span class="description-section2 bold-300">
                    <i>{{
                      comment.timeStamp.seconds * 1000 +
                        comment.timeStamp.nanoseconds / 1000000
                        | date
                          : "MMM dd, yyyy, h:mm a"
                    }}</i>
                  </span>
                </div>
                <p class="sub-title-section2 bold-400">{{ comment.comment }}</p>

                <div class="row reply">
                  <div class="col l5 s12">
                    <p class="col s4 l4">
                      {{ calculateTimeElapsed(comment.timeStamp) }}
                    </p>
                    <a class="col s4 l4">{{ comment.likesC }} like</a>
                    <a
                      class="col s4 l4 reply-link"
                      *ngIf="isLogged"
                      (click)="showReplyInputForComment(comment.id)"
                      >Reply</a
                    >
                  </div>

                  <div class="col l7 hide-on-small-only"></div>
                </div>

                <a
                  *ngIf="comment.replies && comment.replies.length > 0"
                  (click)="toggleReplies(comment)"
                  class="view-replies"
                >
                  --------
                  {{
                    comment.showReplies
                      ? "Hide Replies"
                      : "View Replies (" + comment.replies.length + ")"
                  }}
                </a>
              </div>
            </div>

            <div class="col s1 l1" *ngIf="isLogged">
              <span (click)="likeComment(comment)">
                <ng-container
                  *ngIf="
                    comment.likedByC.includes(authservice.globalUser?.uid);
                    else notLikedIcon
                  "
                >
                  <span class="material-icons red-text" style="font-size: 20px">
                    favorite</span
                  >
                </ng-container>
                <ng-template #notLikedIcon>
                  <span
                    class="material-symbols-outlined"
                    style="font-size: 20px"
                  >
                    favorite_border
                  </span>
                </ng-template>
              </span>
            </div>

            <div class="col s1 l1">
              <a
                *ngIf="comment.userId === authservice.globalUser?.uid"
                (click)="setCommentToDelete(comment)"
                data-target="deleteCommentModal"
                class="red-text icon-text modal-trigger"
                style="text-transform: capitalize"
              >
                <span
                  class="material-symbols-outlined bold-300 red-text"
                  style="font-size: 21px"
                >
                  delete
                </span>
              </a>
            </div>

            <div
              *ngIf="
                comment.showReplies &&
                comment.replies &&
                comment.replies.length > 0
              "
            >
              <div *ngFor="let reply of comment.replies" class="col s12 p-l-3">
                <div class="p-b-2 col s11">
                  <div class="user-info">
                    <span class="bold-600 sub-title-section3"
                      >{{ reply.userName }} -
                    </span>
                    <span class="description-section bold-300">{{
                      reply.reply
                    }}</span>
                  </div>
                  <div class="row reply-reply">
                    <div class="col l4 s12">
                      <p class="col s6 l4">
                        {{ calculateTimeElapsed(reply.timeStamp) }}
                      </p>
                      <a class="col s6 l4">{{ reply.likesR }} like</a>
                    </div>
                    <div class="col l8 hide-on-small-only"></div>
                  </div>
                </div>

                <div class="col s1 l1" *ngIf="isLogged">
                  <span (click)="likeReply(comment, reply)">
                    <ng-container
                      *ngIf="
                        reply.likedByR.includes(authservice.globalUser?.uid);
                        else likedRIcon
                      "
                    >
                      <span
                        class="material-icons red-text"
                        style="font-size: 16px"
                      >
                        favorite</span
                      >
                    </ng-container>
                    <ng-template #likedRIcon>
                      <span
                        class="material-symbols-outlined"
                        style="font-size: 16px"
                      >
                        favorite_border
                      </span>
                    </ng-template>
                  </span>
                </div>
              </div>
            </div>

            <div
              *ngIf="showReplyInput[comment.id]"
              class="reply-input input-with-button row col s12"
            >
              <input
                type="text"
                [(ngModel)]="newReplyText"
                placeholder="Add a reply..."
                class="validate"
              />
              <button class="btn b-n-1 blue-text" (click)="addReply(comment)">
                Reply
              </button>
            </div>
          </div>
        </div>

        <div *ngIf="comments.length === 0">
          <p class="p-l-1">No comments yet.</p>
        </div>

        <div class="row p-l-2 icon-text" *ngIf="isLogged">
          <div class="col s12 fixed-input-footer">
            <div class="input-with-button">
              <input
                type="text"
                [(ngModel)]="newCommentText"
                placeholder="Write your comment here"
                class="validate"
              />
              <button class="btn b-n-1 blue-text" (click)="addComment()">
                Add Comment
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- ========================= side recent post  ============================ -->
  <div
    class="row col l4 p-l-1 p-t-1 card-panel2 sticky hide-on-med-and-down card-yes1"
  >
    <div class="p-l-0 p-r-0 border-content">
      <h6 class="title-section4 bold-700 p-r-1">Recent Post</h6>
      <div class="card p-l-1 p-r-1" *ngFor="let news of otherNews">
        <div (click)="open(news.id)" class="card-cont-recent" style="cursor: pointer; text-transform: uppercase;  padding: 0;">
          <a>
            <h4 class="p-t-1" [innerHTML]="news.title"></h4>
          </a>
          <p>
            <span class="body-snippet">
              {{ truncateHTML(news.body, 100) }}</span
            >
          </p>

          <p class="p-b-1 recent-date">
            {{ news?.datePublished?.toDate() | date : "MMM dd, yyyy" }} |
            {{ news.comments?.length }} comments | By {{ news.author }}
          </p>
        </div>
      </div>
    </div>
  </div>

  <!-- Add this inside your components template -->
  <div id="deleteCommentModal" class="modal modal-fixed-footer b-n-1">
    <div class="modal-content row">
      <div class="col s11">
        <h4 class="sub-title-section bold-700">Confirm Deletion</h4>
      </div>
      <div
        class="col s1 modal-close center"
        style="border: 1px solid rgb(183, 183, 183)"
      >
        <span class="material-symbols-outlined"> close </span>
      </div>
      <p class="row col s12">Are you sure you want to delete this comment?</p>
    </div>
    <div class="modal-footer">
      <a
        (click)="deleteCommentFromModal()"
        class="waves-effect waves-light btn red"
        >OK</a
      >
    </div>
  </div>

  <div id="deleteReplyModal" class="modal modal-fixed-footer b-n-1">
    <div class="modal-content row">
      <div class="col s11">
        <h4 class="sub-title-section bold-700">Confirm Deletion</h4>
      </div>
      <div
        class="col s1 modal-close center"
        style="border: 1px solid rgb(183, 183, 183)"
      >
        <span class="material-symbols-outlined"> close </span>
      </div>
      <p class="row col s12">Are you sure you want to delete this comment?</p>
    </div>
    <div class="modal-footer">
      <a
        (click)="deleteReplyFromModal()"
        class="waves-effect waves-light btn red"
        >OK</a
      >
    </div>
  </div>
</div>
