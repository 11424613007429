import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-employee-main',
  templateUrl: './employee-main.component.html',
  styleUrls: ['./employee-main.component.css']
})

export class EmployeeMainComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
