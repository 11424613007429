import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { SectionSettingService } from 'src/app/services/firebase/section-setting.service';
import { toast } from 'materialize-css';
//import { EmbedVideoService } from 'ngx-embed-video';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { MediaService } from 'src/app/services/api/media.service';


declare var $: any;

@Component({
  selector: 'app-add-custom-section',
  templateUrl: './add-custom-section.component.html',
  styleUrls: ['./add-custom-section.component.css']
})

export class AddCustomSectionComponent implements OnInit {

  defaultThemeColor = environment.appTheme.themeColor;
  themeColor: any;
  contentForm: UntypedFormGroup;

  id: any;
  sectionId: any;
  section: any;

  mediaType: any;
  addMedia = false;
  hasMedia: boolean;
  hasNoMedia = false;
  singleMedia = false;

  //isUrl = false;
  isVideo = false;
  isUpload = false;
  isPhoto = false;
  isPdf = false;
  isYoutube = false;

  binDoc: File;
  //base64Image: any;
  imgErrorMsg: any;
  pdfErrorMsg: any;
  iframehtml: any

  allowComments = null;
  shareToPublic = null;
  comments: any[];

  editMode = false;
  model: any;

  fileUploaded = false;
  toBeUploaded: any;
  uploadedFile: any;
  fileUrl: any;
  newFile = false;

  openDeleteModal = false;
  modalData: any;
  videoId: any;
  
  constructor(private fb: UntypedFormBuilder,
    private appSettingsService: AppSettingsService,
    private sectionService: SectionSettingService,
    private location: Location,
    private route: ActivatedRoute,
    private mediaService: MediaService,
    //private embedService: EmbedVideoService,
    private router: Router) {
    this.themeColor = this.appSettingsService.getAppThemeColor() ? this.appSettingsService.getAppThemeColor() : this.defaultThemeColor;
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      if (params.get('secId')) {
        this.sectionId = params.get('secId');
        this.sectionService.getSectionById(this.sectionId).valueChanges().subscribe(sec => {
          if (sec) {
            this.section = sec;
            if ((this.section.options.imageUpload + this.section.options.videoUpload + this.section.options.pdfUpload + this.section.options.youtubeLink) === 1) this.singleMedia = true;
          }
        });
      }
    });

    this.contentForm = this.fb.group({
      title: ['', [Validators.required]],
      description: [''],
      addMedia: ['', [Validators.required]],
      mediaLink: [''],
      mediaType: [''],
      allowComment: ['', Validators.required],
      share: ['', [Validators.required]],
    });

    const path = this.router.url.split('/');
    if (path[path.length - 2] === 'add-content') {
      this.editMode = false;
    } else if (path[path.length - 3] === 'edit-content') {
      this.editMode = true;
      this.route.paramMap.subscribe(params => {
        if (params.get('id')) {
          this.id = params.get('id');
          this.getContentData();
        }
      });
    }
  }

  get title() { return this.contentForm.get('title'); }
  get description() { return this.contentForm.get('description'); }
  get mediaLink() { return this.contentForm.get('mediaLink'); }

  getContentData() {
    this.sectionService.getContenById(this.id).valueChanges().subscribe(content => {
      if (content) {
        this.model = content;
        this.contentForm.setValue({
          title: content.title,
          description: content.description,
          addMedia: content.mediaType ? true : false,
          allowComment: content.allowComment,
          share: content.share,
          mediaType: content.mediaType,
          mediaLink: '',
        });

        if (content.mediaType && content.mediaLink) {
          this.hasMedia = true;
          this.yesClicked();
          this.mediaType = content.mediaType;
          if (content.mediaType == 'Photo') this.isPhoto = true;
          if (content.mediaType == 'Video') this.isVideo = true;
          if (content.mediaType == 'PDF') this.isPdf = true;
          if (content.mediaType == 'Youtube') {
            this.isYoutube = true;
            this.embedVideo(content.mediaLink);
          }

          this.fileUploaded = true;
          this.fileUrl = content.mediaLink;
          //this.uploadedFile = content.mediaLink;
          this.fileUrl = content.mediaLink;
        } else {
          this.noClicked();
        }

        if (content.allowComment) {
          this.allowComments = true;
          this.comments = content.comments;
        }
        else this.allowComments = false;

        if (content.share == 'Public') this.shareToPublic = true;
        else this.shareToPublic = false;
      }

      $(document).ready(function () {
        $(".modal").modal();
      });
    });
  }

  yesCommentClicked() {
    this.allowComments = true;
    this.contentForm.get('allowComment').setValue(this.allowComments);
  }

  noCommentClicked() {
    this.allowComments = false;
    this.contentForm.get('allowComment').setValue(this.allowComments);
  }

  yesClicked() {
    this.addMedia = true;
    this.hasMedia = true;
    this.hasNoMedia = false;
    this.contentForm.get('addMedia').setValue(true);
    if (this.singleMedia) {
      if (this.section.options.imageUpload) this.mediaType = 'Photo';
      else if (this.section.options.videoUpload) this.mediaType = 'Video';
      else if (this.section.options.pdfUpload) this.mediaType = 'PDF';
      else if (this.section.options.youtubeLink) this.mediaType = 'Youtube';
    }

    setTimeout(() => {
      $('select').formSelect();
    }, 20);
  }

  noClicked() {
    this.addMedia = false;
    this.hasMedia = false;
    this.hasNoMedia = true;
    this.contentForm.get('addMedia').setValue(false);
    //this.isUrl = false;
    this.isPhoto = false;
    this.isVideo = false;
    this.isUpload = false;
    this.isPdf = false;
    this.mediaType = null;
    this.uploadedFile = null;
  }

  // linkClick() {
  //   this.mediaType = 'Link'
  // }

  photoClick() {
    this.mediaLink.patchValue('');
    this.binDoc = null;
    this.fileUploaded = false;
    if (this.editMode) this.uploadedFile = null;
    this.mediaType = 'Photo';
  }

  videoClick() {
    this.mediaLink.patchValue('');
    this.binDoc = null;
    this.fileUploaded = false;
    if (this.editMode) this.uploadedFile = null;
    this.mediaType = 'Video';
  }

  youtubeClick() {
    this.mediaLink.patchValue('');
    this.fileUploaded = false;
    if (this.editMode) this.uploadedFile = null;
    this.mediaType = 'Youtube';
  }

  uploadPdf() {
    this.mediaLink.patchValue('');
    this.binDoc = null;
    this.fileUploaded = false;
    if (this.editMode) this.uploadedFile = null;
    this.mediaType = 'PDF';
  }

  handlePhotoInput(files: FileList) {
    if (!this.validateImage(files[0].name)) {
      this.imgErrorMsg = 'Invalid File Type, Please Select an Image File';
      this.mediaLink.patchValue('');
      return;
    }
    // if (this.editMode) {
    //   //if (this.uploadedFile) this.oldUrl = this.uploadedFile;
    //   this.uploadedFile = null;
    //   this.newFile = true;
    // }
    this.binDoc = files[0];
    this.imgErrorMsg = undefined;
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      const base64Image = reader.result as string;;
      this.uploadedFile = base64Image;
      //this.base64Image = reader.result as string;
      // this.toBeUploaded = this.base64Image;
      this.fileUploaded = true;
      this.addMedia = false;
    };
  }

  handleVideoInput(files: FileList) {
    this.binDoc = files[0];
    this.uploadedFile = this.binDoc;
    if (files && files[0]) {
      var reader = new FileReader();
      reader.onload = function (e) {
        $('.uploadedVideo').attr('src', e.target.result);
      };
      reader.readAsDataURL(files[0]);
      this.fileUploaded = true;
      this.addMedia = false;
    }
  }

  handlePDFInput(files: FileList) {
    let pdfViewer = document.getElementById('pdfViewer');
    if (!this.validatePDF(files[0].name)) {
      this.pdfErrorMsg = 'Invalid Document Type, Please Select a PDF File'
      this.mediaLink.patchValue('');
      return;
    }

    this.binDoc = files[0];
    this.uploadedFile = this.binDoc;
    //let pdfFile = files[0];
    this.pdfErrorMsg = undefined;

    this.fileUploaded = true;
    this.addMedia = false;
    if (pdfViewer) {
      setTimeout(() => {
        let pdfUrl = URL.createObjectURL(this.binDoc);
        pdfViewer.setAttribute('src', pdfUrl);
      }, 25);
    }
  }

  youtubeLink(event) {
    if (this.validateYouTubeUrl(event.target.value)) {
      this.iframehtml = this.embedVideo(event.target.value);
      this.fileUploaded = true;
    } else {
      toast({ html: 'Invalud youtube link!', classes: 'red' });
    }
  }

  embedVideo(videoLink) {
    const videoIdMatch = videoLink.match(/(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([^&]+)|(?:https?:\/\/)?(?:www\.)?youtu\.be\/([^?]+)/);
    this.videoId = videoIdMatch ? (videoIdMatch[1] || videoIdMatch[2]) : '';
    return this.videoId;
  }

  removeFile() {
    if (this.mediaType == 'Photo') {
      this.binDoc = undefined;
      if (this.editMode) {
        this.deleteOldFile(this.fileUrl);
        this.fileUrl = null;
        this.fileUploaded = false;
      }
      // this.model.imgLink = '';
    }
    else if (this.mediaType == 'Video') {
      this.binDoc = undefined;
      $('.uploadedVideo').attr('src', '');
      if (this.editMode) {
        this.fileUrl = null;
        this.fileUploaded = false;
      }
    }
    else if (this.mediaType == 'Youtube') {
      this.iframehtml = undefined;
    }
    this.mediaLink.patchValue('');
    this.uploadedFile = undefined;
    this.fileUploaded = false;
    // this.addMedia = false;
    // this.hasMedia = false;
    this.noClicked();
  }

  private validateImage(name: any) {
    const ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() === 'png') {
      return true;
    } else if (ext.toLowerCase() === 'jpg') {
      return true;
    } else if (ext.toLowerCase() === 'jpeg') {
      return true;
    } 
    else if (ext.toLowerCase() === 'svg') {
      return true;
    }
    else if (ext.toLowerCase() === 'webp') {
      return true;
    }else {
      return false;
    }
  }

  private validatePDF(name: String) {
    var ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() == 'pdf') {
      return true;
    }
    else {
      return false;
    }
  }

  private validateYouTubeUrl(url) {
    if (url) {
      var regExp = /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
      if (url.match(regExp)) {
        return true;
      }
    }
    return false;
  }

  sharePublic() {
    this.shareToPublic = true;
    this.contentForm.get('share').setValue('Public');
  }

  shareMember() {
    this.shareToPublic = false;
    this.contentForm.get('share').setValue('Member');
  }

  saveForm(values) {
    let model = {
      sectionId: this.sectionId,
      title: values.title,
      description: values.description,
      allowComment: values.allowComment,
      share: values.share,
      mediaType: null,
      mediaLink: null,
      datePublished: new Date(),
      comments: [],
    }
    if (this.mediaType) {
      model.mediaType = this.mediaType;
      if (this.mediaType == 'Photo') {
        if (this.binDoc && this.uploadedFile) {
          this.mediaService.UploadBinImage(this.binDoc).subscribe(
            upload => {
              if (upload) {
                model.mediaLink = upload.imgLink;
                this.sectionService.saveContent(model).then(res => {
                  toast({ html: 'section successfully created!', classes: 'green' });
                  this.backClicked();
                });
              }
            });
        }
      } else if (this.mediaType == 'Video') {
        if (this.fileUploaded && this.binDoc) {
          this.mediaService.uploadVideo(this.binDoc).subscribe(upload => {
            if (upload) {
              model.mediaLink = upload.imgLink;
              this.sectionService.saveContent(model).then(res => {
                toast({ html: 'section successfully created!', classes: 'green' });
                this.backClicked();
              });
            }
          });
        }
      } else if (this.mediaType == 'PDF') {
        if (this.binDoc) {
          this.mediaService.uploadPDF(this.binDoc).subscribe(upload => {
            if (upload) {
              model.mediaLink = upload.imgLink;
              this.sectionService.saveContent(model).then(res => {
                toast({ html: 'section successfully created!', classes: 'green' });
                this.backClicked();
              });
            }
          })
        }
      } else if (this.mediaType == 'Youtube') {
        if (values.mediaLink) {
          model.mediaLink = values.mediaLink;
          this.sectionService.saveContent(model).then(res => {
            toast({ html: 'section successfully created!', classes: 'green' });
            this.backClicked();
          });
        }
      } else {
        toast({ html: 'Can not upload media. Please try again!', classes: 'red' });
      }
    } else {
      this.sectionService.saveContent(model).then(res => {
        toast({ html: 'section successfully created!', classes: 'green' });
        this.backClicked();
      });
    }
  }

  update(values) {
    let model: any = {
      id: this.id,
      sectionId: this.sectionId,
      title: values.title,
      description: values.description,
      allowComment: values.allowComment,
      share: values.share,
      mediaType: null,
      mediaLink: null,
      datePublished: new Date(),
      comments: this.comments
    }

    if (this.mediaType) {
      model.mediaType = this.mediaType;
      if (this.mediaType == 'Photo') {
        if (this.uploadedFile) {
          //this.deleteOldFile(this.oldUrl);
          this.mediaService.UploadBinImage(this.binDoc).subscribe(
            upload => {
              if (upload) {
                model.mediaLink = upload.imgLink;

                this.sectionService.updateContent(model).then(res => {
                  toast({ html: 'Section successfully updated!', classes: 'green' });
                  this.backClicked();
                });
              }
            });
        } else {
          this.sectionService.updateContent(model).then(res => {
            toast({ html: 'Section successfully updated!', classes: 'green' });
            this.backClicked();
          });
        }
      } else if (this.mediaType == 'Video') {
        if (this.fileUploaded && this.binDoc) {
          this.mediaService.uploadVideo(this.binDoc).subscribe(upload => {
            if (upload) {
              model.mediaLink = upload.imgLink;

              this.sectionService.updateContent(model).then(res => {
                toast({ html: 'Section successfully updated!', classes: 'green' });
                this.backClicked();
              });
            }
          });
        }
      } else if (this.mediaType == 'PDF') {
        if (this.binDoc) {
          this.mediaService.uploadPDF(this.binDoc).subscribe(upload => {
            if (upload) {
              model.mediaLink = upload.imgLink;
              this.sectionService.updateContent(model).then(res => {
                toast({ html: 'Section successfully updated!', classes: 'green' });
                this.backClicked();
              });
            }
          })
        }
      } else if (this.mediaType == 'Youtube') {
        if (values.mediaLink) {
          model.mediaLink = values.mediaLink;
          this.sectionService.updateContent(model).then(res => {
            toast({ html: 'Section successfully updated!', classes: 'green' });
            this.backClicked();
          });
        }
      } else {
        toast({ html: 'Can not upload media. Please try again!', classes: 'red' });
      }
    } else {
      this.sectionService.updateContent(model).then(res => {
        toast({ html: 'Section successfully updated!', classes: 'green' });
        this.backClicked();
      });
    }


    // else {
    //   this.sectionService.updateContent(model).then(res => {
    //     toast({ html: 'Section successfully updated!', classes: 'green' });
    //     this.backClicked();
    //   });
    // }
  }

  deleteOldFile(url) {
    this.mediaService.DeleteBinImage(url).subscribe(status => {
      if (status) return true;
      else return false;
    });
  }

  onDelete() {
    this.modalData = {
      title: 'Delete Post',
      icon: 'warning',
      subTitle: 'Are you sure you want to delete this post?',
      description: 'The post will be permanently deleted.',
      confirmation: {
        title: 'Are you really sure you want to delete this post?',
        text: 'Please, be absolutely certain before proceeding, as this action this action cannot be undone.',
        text2: 'Confirm your decision to proceed with the deletion.'
      }
    }
    this.openDeleteModal = true;
  }

  cancelHandler(valueEmitted) {
    if (valueEmitted) this.openDeleteModal = false;
    $('.modal').modal('close');
  }

  confirmHandler(valueEmitted) {
    if (valueEmitted) this.delete();
    this.openDeleteModal = false;
    $('.modal').modal('close');
  }


  delete() {
    this.sectionService.deleteContent(this.id).then(x => {
      toast({ html: 'Post Successfully deleted!', classes: 'green' });
      this.backClicked();
    });
  }

  backClicked() {
    this.location.back();
  }

}