import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GovernanceService } from 'src/app/services/firebase/governance.service';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';

@Component({
  selector: 'app-gov-member-detail',
  templateUrl: './gov-member-detail.component.html',
  styleUrls: ['./gov-member-detail.component.css']
})
export class GovMemberDetailComponent implements OnInit {
  
  defaultThemeColor = environment.appTheme.themeColor;
  themeColor: any;
  buttonType: any;
  model: any;
  selectedMember: any;
  id: any;

  constructor(private settingService: AppSettingsService, private governanceService: GovernanceService, private route: ActivatedRoute,  private location: Location, private router: Router) {
    this.themeColor = this.settingService.getAppThemeColor() ? this.settingService.getAppThemeColor(): this.defaultThemeColor;
    this.buttonType =  this.settingService.getButtonType() ? this.settingService.getButtonType(): 'rectangularButton';
   }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });

    if (this.id) {
      this.governanceService.getGovernanceList().valueChanges().subscribe(gov => {
        if (gov.length > 0) {
          this.model = gov[0];
          this.selectedMember = this.model.councillors.filter(c => c.applicantID === Number(this.id));
        }
      });
    }
  }

  backClicked() {
    this.location.back();
    // this.router.navigate(['/']);
  }
}
