import { Component, ElementRef, OnInit, Input } from '@angular/core';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { trigger, transition, useAnimation } from '@angular/animations';
import { bounceIn } from 'ng-animate';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { Location } from '@angular/common';
import { UnlinkAccountModel } from 'src/app/models/SocialMediaPostModel';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MediaService } from 'src/app/services/api/media.service';
import { AyshareUserService } from 'src/app/services/firebase/ayrshare-social-media.service';
import { HttpClient } from '@angular/common/http';
import { AyrshareService } from 'src/app/services/social-media/ayrshare.service';
import { FireMembersService } from 'src/app/services/firebase/fire-members.service';
import { analysisPlatforms } from 'src/app/models/SocialMediaPostModel';
import { ProfieKeyService } from 'src/app/services/social-media/profileKey.service';

@Component({
  selector: 'app-social-media-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css'], animations: [
    trigger('swing', [transition('* => *', useAnimation(bounceIn))])
  ],
})

export class SocialMediaSettingsComponent implements OnInit {

  constructor(private authService: AuthService,
    private router: Router,
    private socialMediaService: AyrshareService,
    private userProfileService: AyshareUserService,
    private profieKeyService: ProfieKeyService) { }

  availablePlatforms = []
  allAccountsPosts = []
  selectedAccountPosts = []
  selectedAccount

  profileKey

  hasActiveAccounts = false
  userProfile
  // socialMediaIcons=icons
  mediaAnalysisPlatforms = [...analysisPlatforms]
  // mediaAnalysisPlatforms=["facebook","instagram","twitter","youtube","pinterest"] //tiktok //linkedin -company
  loading = false
  deleteModalOpen = false
  user

  // toast
  toastMessage: any;
  toastClass: any;
  toastType: any;
  openToast = false;
  platformToRemove
  removePlatformModal = false

  isAdmin: any;
  perm: any;
  permission: any;

  permMember: any;
  permSocial: any;
  permPES: any;
  permSpecial: any;
  permAnalytics: any;

  ngOnInit() {
    this.user = this.authService.getGlobalUser()

    if (this.user.role == "Admin") {
      this.isAdmin = true
    }

    this.profieKeyService.currentProfileKey.subscribe(p => {
      this.profileKey = p
    });
  }

  getUserProfile() {
    if (this.profileKey) {
      this.loading = true;
      this.socialMediaService.getUserByProfileKey(this.profileKey).toPromise()
        .then(data => {
          this.userProfile = data
          this.hasActiveAccounts = true ? data['activeSocialAccounts'] && data['activeSocialAccounts'].length > 0 : false
          this.loading = false;

          console.log(this.userProfile, 'the user profule')
        })
        .catch(err => {
          console.log(err)
          this.loading = false;
        });
    }
  }

  unlinkSocialMedia() {
    this.removePlatformModal = false

    let model = new UnlinkAccountModel()
    model.profileKey = this.profileKey
    model.platform = this.platformToRemove

    this.socialMediaService.unlinkAccount(model).toPromise().then(message => {
      this.toast({ html: `${this.platformToRemove} account successsfully unlinked`, classes: 'green', type: 'success' });

      setTimeout(() => {
        // reload component
        // this.backToDashboard()
        this.ngOnInit();
      }, 2000);
    }).catch(err => {
      console.log(err)
    })
  }

  removeAccount(platform) {
    this.platformToRemove = platform
    this.removePlatformModal = true
    console.log(platform)
  }

  deleteProfile() {
    let model = {
      profileKey: this.profileKey
    }
   
    this.socialMediaService.deleteUserProfile(model).toPromise().then(message => {
      this.userProfileService.deleteProfile(this.profileKey)
      this.toast({ html: 'User profile successsfully deleted', classes: 'green', type: 'success' });
      setTimeout(() => {
        this.backToDashboard()
      }, 2000);
    }).catch(err => {
      console.log(err)
    })
  }

  backToDashboard() {
    this.router.navigate(['/admin/dashboard'])
  }

  toast(obj) {
    this.toastMessage = obj.html;
    this.toastClass = obj.classes ? obj.classes : 'green';
    this.toastType = obj.type ? obj.type : 'success';
    this.openToast = true;
    this.deleteModalOpen = false
    setTimeout(() => {
      this.openToast = false;
    }, 2000);
  }

}