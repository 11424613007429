<div class="row">
  <div class="col col s12">
    <form class="row" (ngSubmit)="submitForm(uploadDocumentForm.value)" [formGroup]="uploadDocumentForm">

      <div class="row card-panel white darken-2 " *ngIf="step1">
        <div class="row">
          <div class="row input-field col s12">
            <select formControlName="documentType" required class="validate browser-default" (change)="docTypeChange()">
              <option value="" disabled selected>Please Select Document Type</option>
              <option *ngFor="let doc of documentTypes" [value]="doc">{{doc}}</option>
            </select>
          </div>

          <div class="input-field col s12" *ngIf="showDesciption">
            <input id="description" type="text" formControlName='description' class="validate" autocomplete="off" />
            <label for="description">Description :</label>
          </div>

          <div class=" col s12 file-field input-field" *ngIf="!isCordova">
            <div class="btn">
              <span>Browse</span>
              <input type="file" formControlName="document" (change)="handleFileInput($event.target.files)" />
            </div>

            <div class="file-path-wrapper">
              <input formControlName="document" class="file-path validate" type="text"
                placeholder="Supporting Document" />
            </div>
          </div>

          <div class="row" *ngIf="isCordova ">
            <div class="col s6" style="display: inline;">
              <button class='at-btn col s12 btn waves-effect success' (click)="handleCameraInput()" type="button">
                Take Photo
              </button>
            </div>

            <div class="col s6" style="display: inline;">
              <button class='at-btn col s12 btn waves-effect success' (click)="getPhotoFromLibrary()" type="button">
                Browse Library
              </button>
            </div>
          </div>

          <div class="row col s12 input-field">
            <div class="col s12" *ngIf="preview">
              <img class="responsive-img" [src]="preview" alt="upload asset image">
              <a class="btn-floating btn-small waves-effect waves-light red right" (click)="removeFile()"><i
                  class="material-icons">delete</i></a>
            </div>
          </div>
        </div>
      </div>

      <div class="section"></div>

      <div class="col s12" *ngIf="step1">
        <button class='at-btn col s12 btn waves-effect success' style="margin-bottom: 15px;" type="submit"
          [disabled]="!uploadDocumentForm.valid">
          <i class="material-icons right">cloud_upload</i> Upload Document
        </button>
      </div>

      <div id="step2" class="row" *ngIf="step2">
        <div class="row">
          <div class="row col s12">
            <div class="row card-panel">

              <div class="row input-field col s12">
                <div class="row col s12"> <strong class="blue-text">Do you want to Submit Another Documents ? </strong>
                </div>

                <div class="row col s12">
                  <label class="col s4">
                    <input class="with-gap" formControlName="upload_doc" name="upload_doc" type="radio" value="yes"
                      (change)="onUploadYes($event)" />
                    <span class="blue-text"><strong>Yes</strong></span>
                  </label>
                  <label class="col s4">
                    <input class="with-gap" formControlName="upload_doc" name="upload_doc" type="radio" value="no"
                      (change)="onUploadNo($event)" />
                    <span class="blue-text"><strong>No</strong></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="step3" class="row" *ngIf="step3">
        <div class="row">
          <div class="row col s12">
            <div class="row card-panel">

              <div class="row input-field col s12">
                <div class="row col s12"> <strong class="blue-text">Would like to send a message to the administrator ?
                  </strong></div>

                <div class="row col s12">
                  <label class="col s4">
                    <input class="with-gap" formControlName="send_msg" name="send_msg" type="radio" value="yes"
                      (change)="onSendMessageYes($event)" />
                    <span class="blue-text"><strong>Yes</strong></span>
                  </label>

                  <label class="col s4">
                    <input class="with-gap" formControlName="send_msg" name="send_msg" type="radio" value="no"
                      (change)="onSendMessageNo($event)" />
                    <span class="blue-text"><strong>No</strong></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="step4" *ngIf="step4" class="row ">
        <div class="row">
          <div class="row col s12">
            <div class="row card-panel">
              <div class="row center">
                <h5 class="green-text">Thank You!</h5>
                <h6 class="green-text">Your documents have been succesfully submitted. Thank you for taking the time.
                  We will get back to you if we have any questions</h6>
                <br />
              </div>
            </div>
          </div>
        </div>

        <div class="section"></div>
        <button [ngClass]="'at-btn col s12 btn waves-effect ' + themeColor" (click)="backClicked()">
          <i class="material-icons left">keyboard_arrow_left</i> Back To Home Page
        </button>
      </div>

      <div id="step5" *ngIf="step5" class="row ">
        <div class="row">
          <div class="row col s12">
            <div class="row card-panel">

              <div class="md-form mb-4 pink-textarea active-pink-textarea">
                <label for="message"><span class="black-text"><strong>
                      <h6>Message to Administrator:</h6>
                    </strong></span></label>
                <textarea id="message" formControlName="message" class="md-textarea form-control" rows="10"></textarea>
              </div>

            </div>
          </div>
        </div>

        <div class="section"></div>
        <button class='at-btn col s12 btn waves-effect success' style="margin-bottom: 15px;" (click)="Send_message()">
          <i class="material-icons left">email</i> Send Message
        </button>
      </div>

    </form>
  </div>
</div>