

import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
//import { PsePaymentModel } from 'src/app/models/PsePayment.Model';
import { PseAppInfoModel } from 'src/app/models/PseAppInfo.Model';

import { ApiKeyManagerService } from './apiKeyManager.Service';


@Injectable({
    providedIn: 'root'
  })
  export class PseAppInfoService {
  
  url = environment.firstNation.apiUrl + 'api/PSEStudentHome/';
  

  constructor(private httpClient: HttpClient, private keyManager: ApiKeyManagerService) {}
  
  /*
  getPseAppInfo(id: number): Observable<PseAppInfoModel> {
    const headers = new HttpHeaders().set('SOCIALAPIKEY', this.keyManager.getKey());
    return this.httpClient.get<PsePaymentModel>(this.url + 'GetStudentAppInfo/' + id, {headers});
  }
  */
  
  getFundingBDCompliance(id: number): Observable<PseAppInfoModel[]> {
    const headers = new HttpHeaders().set('SOCIALAPIKEY', this.keyManager.getKey());
    return this.httpClient.get<PseAppInfoModel[]>(this.url + 'GetFundingComplianceList/' + id, {headers});
  }
  
  getIfClientHasCurrentBD(id: number): Observable<any> {
      const headers = new HttpHeaders().set('SOCIALAPIKEY', this.keyManager.getKey());
      return this.httpClient.get<any[]>(this.url + 'GetIfStudentHasCurrentFundingBD/' + id, {headers});
    }
  
  
  }
  