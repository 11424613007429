<div class="row container-pi">
  <!-- <app-firebase-loading *ngIf="loading"></app-firebase-loading> -->
  <h4 class="title-section1 title-member icon-texts">
    <span class="material-symbols-outlined back-icon p-r-1" (click)=" backClicked()">
      arrow_circle_left
  </span>Family Composition</h4>

  <div class="row card-panel b-n-1">

    <div class="row col s12" *ngIf="familyMembers && familyMembers.length > 0">
      <div class="col s12">
        <ul class="collection">
          <li *ngFor="let member of familyMembers" class="collection-item">
            <a *ngIf="isDemo" [routerLink]="['/member/profile/family-member-detail',member.applicantID]">
              {{(member?.GivenName !== null) ? (member?.GivenName | NameStars) : ""}} {{(member?.LastName !== null) ?
              (member?.LastName | NameStars) : ""}}
            </a>
            <a *ngIf="!isDemo" [routerLink]="['/member/profile/family-member-detail',member.applicantID]">
              {{member?.GivenName}} {{member?.LastName}}
            </a>
            <strong class="secondary-content">{{member.Classification}}</strong>
          </li>
        </ul>
      </div>
    </div>

    <div class="row black-text center p-t-10" *ngIf="(!familyMembers || familyMembers.length === 0) && !loading">
      <img src="../../assets/img/empty-folder.png" height="150" width="150">
      <h6 class="bold-200">Sorry , no data found !!</h6>
    </div>

    <div class="row col s12">

      <div *ngIf="displayAdded" class="row col s12" [formGroup]="addFamilyForm">
        <h6 class="center-align"><strong> Family Members to be Added</strong></h6>
        <div formArrayName="members" *ngFor="let m of memberControls.controls; let i = index;">
          <ul class="collapsible">
            <li class="collection-item">
              <div class="row collapsible-header"><i class="material-icons">person</i>
                <span class="col s12 m5"><strong>First Name: </strong>
                  {{memberControls.controls[i].controls.firstName.value}}</span>
                <span class="col s12 m5"><strong>Last Name: </strong>
                  {{memberControls.controls[i].controls.lastName.value}}</span>
                <span class="col s12 m2">
                  <i class="material-icons red-text small" (click)="onDelete(i)" style="cursor: pointer;">delete</i>
                </span>
              </div>
              <div class="row collapsible-body">
                <span class="col s12 m6"><strong>Middle Name: </strong>
                  {{memberControls.controls[i].controls.middleName.value}}</span>
                <span class="col s12 m6"> <strong>Birth Date: </strong>
                  {{memberControls.controls[i].controls.dob.value |date: 'dd/MMM/yyyy'}}</span>
                <span class="col s12 m6"> <strong>ISN: </strong>
                  {{memberControls.controls[i].controls.isn.value}}</span>
                <span class="col s12 m6"> <strong>Relationship: </strong>
                  {{memberControls.controls[i].controls.relationship.value}}</span>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <br>
      <div class="col s12 card white" *ngIf="showForm">
        <form [formGroup]="addFamilyForm" (ngSubmit)="addNewMember(addFamilyForm.value)"
          class="row col s12 card-content white">
          <div class="col s12">
            <span class="material-icons red-text right" (click)="closeForm()" style="cursor: pointer;">close</span>
            <h5 class="card-title">Add Family Member</h5>
            <div class="input-field col s12 ">
              <i class="material-icons prefix blue-text">person</i>
              <input type="text" formControlName='firstName' class="validate" autocomplete="off"
                style="text-transform: capitalize;" />
              <label for="firstName">First Name <span class="red-text">*</span></label>
              <div *ngIf="(firstName.dirty || firstName.touched) && firstName.invalid">
                <small *ngIf="firstName.errors.required" class="red-text"> First Name is required. </small>
              </div>
            </div>

            <div class="input-field col s12 ">
              <i class="material-icons prefix pink-text">person</i>
              <input type="text" formControlName='middleName' class="validate" autocomplete="off"
                style="text-transform: capitalize;" />
              <label for="middleName">Middle Name </label>
            </div>

            <div class="input-field col s12 ">
              <i class="material-icons prefix purple-text">person</i>
              <input id="lastName" type="text" formControlName='lastName' class="validate" autocomplete="off"
                style="text-transform: capitalize;" />
              <label for="lastName">Last Name <span class="red-text">*</span></label>
              <div *ngIf="(lastName.dirty || lastName.touched) && lastName.invalid">
                <small *ngIf="lastName.errors.required" class="red-text"> Last Name is required. </small>
              </div>
            </div>

            <div class="input-field col s12">
              <i class="material-icons prefix green-text">calendar_today</i>
              <input *ngIf="!dobSet" id="dob" type="text" formControlName='dob' autocomplete="off"
                [textMask]="{mask: dateMask, guide: true}" placeholder="dd/mm/yyyy"
                (blur)="setDob($event.target.value)" />
              <input *ngIf="dobSet" id="dob" type="text" formControlName='dob' autocomplete="off"
                placeholder="dd/mm/yyyy" (focus)="unsetDob()" />
              <label for="dob">Date of Birth (dd/mm/yyyy)<span class="red-text">*</span></label>
              <div *ngIf="(dob.dirty || dob.touched) && dob.invalid">
                <small *ngIf="dob.errors.required" class="red-text"> The Date Of Birth is required. </small>
                <small *ngIf="dob.errors.invalidDate" class="red-text"> The Date is invalid </small>
              </div>
            </div>

            <div class="input-field col s12 ">
              <i class="material-icons prefix blue-text">tag</i>
              <input id="isn" [textMask]="{mask: isnMask, guide: true}" type="text" formControlName="isn"
                class="validate">
              <label for="isn">ISN</label>
              <div *ngIf="(isn.dirty || isn.touched) && isn.invalid">
                <small *ngIf="isn.errors.minlength || isn.errors.maxlength || isn.errors.pattern" class="red-text"> The
                  ISN
                  must be 10
                  Digits.</small>
              </div>
            </div>

            <div class="row input-field col s12 ">
              <i class="material-icons prefix red-text">groups</i>
              <select formControlName="relationship" class="validate">
                <option value="" disabled selected>Choose your relationship</option>
                <option *ngFor="let relation of relationships" [value]="relation">{{relation}}</option>
              </select>
              <label for="relationship">Relationship <span class="red-text">*</span></label>
              <div *ngIf="(relationship.dirty || relationship.touched) && relationship.invalid">
                <small *ngIf="relationship.errors.required" class="red-text"> Relationship is required. </small>
              </div>
            </div>

            <br>
            <div class="col s12">
              <button type="submit" [disabled]="!addFamilyForm.valid" class="btn waves-effect blue col s12"
                style="margin-bottom: 15px;">
                <i class="material-icons right white-text">person_add</i> Add Member
              </button>
              <button type="button" class="btn waves-effect red col s12" (click)="closeForm()"
                style="margin-bottom: 5px;"><i class="material-icons right white-text">close</i>Cancel</button>
            </div>

          </div>

        </form>
      </div>

      <div class="row col s12" *ngIf="familyMembers">
        <button *ngIf="!hasBD && !showForm" type="button" class="col s12 waves-effect blue btn" (click)="openForm()"
          style="margin-bottom: 25px;">Add Family Member<i class="material-icons right">person_add</i></button>

        <button *ngIf="!hasBD && !showForm && displayAdded" type="button" class="col s12 waves-effect green btn"
          (click)="submit()" style="margin-bottom: 25px;">Submit<i class="material-icons right">send</i></button>

        <button *ngIf="hasBD" type="button" class="col s12 waves-effect blue btn modal-trigger" data-target="hasBDModal"
          style="margin-bottom: 25px;">Add Family Member<i class="material-icons right">person_add</i></button>

        <a [ngClass]="'col s12 btn waves-effect ' + themeColor" (click)="backClicked()"><i
            class="material-icons left">arrow_back_ios_new</i> Back To My Profile</a>
      </div>
    </div>
  </div>
</div>

<div class="container"></div>

<!-- Modal Structure -->
<div id="hasBDModal" class="modal">
  <div class="modal-content">
    <h6><i class="material-icons blue-text" style="display: inline-flex; vertical-align: top;">info</i> Your File is
      up
      to date</h6>
    <p><strong>If you need to make
        changes please contact your IA Administrator </strong> </p>
  </div>
  <div class="modal-footer">
    <a class="modal-close red btn-flat white-text">Close</a>
  </div>
</div>