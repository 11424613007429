import { Component, OnInit } from '@angular/core';
import { Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { HighlightModel } from 'src/app/models/HighlightModel';
import { HighlightService } from 'src/app/services/api/highlight.service';
import { toast } from 'materialize-css';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-edit-highlight',
  templateUrl: './edit-highlight.component.html',
  styleUrls: ['./edit-highlight.component.css']
})

export class EditHighlightComponent implements OnInit {

  editHighlightForm: UntypedFormGroup;
  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  model: HighlightModel;
  id = '';

  constructor(private route: ActivatedRoute, private fb: UntypedFormBuilder, private location: Location, private highlightService: HighlightService) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });

    if (this.id) {
      let newId = 0;
      newId = + this.id;
      this.editHighlightForm = this.fb.group({
        Highlight: ['', [Validators.required]],
      });

      this.highlightService.getHighlightById(newId).subscribe(x => {
        if (x) {
          this.model = x[0];
          this.editHighlightForm.patchValue({ Highlight: this.model.Highlight });  
       
          setTimeout(() => {
            $('.modal').modal();
            M.updateTextFields();
          }, 25);
        }
      });
    }
  }

  get Highlight() { return this.editHighlightForm.get('Highlight'); }
 
  submitForm(value: any) {
    if (this.editHighlightForm.valid){
      this.model.Highlight = value.Highlight;
      this.highlightService.updateHighlight(this.model).subscribe(x => {
        if (x) {
          toast({ html: 'Highlight Successfully Updated!', classes: 'green' });
          this.location.back();
        }
      });
    }
  }

  DeleteHighlight() {
    this.highlightService.deleteHighlight(this.model).subscribe(resp => {
      toast({ html: 'Highlight Removed Successfully!', classes: 'green' });
      this.backClicked();
    });
  }

  backClicked() {
    this.location.back();
  }
  
}
