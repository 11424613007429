<div class="body-h">

  <div class="header-setting b-m-2">
    <div class="col l12 m12 s12">
      <h4 class=""> Edit Certificate </h4>
    </div>
  </div>

  <main class="StickyContent white row">
    <div id="step0" class="col s12 l10">
      <form (ngSubmit)="submitForm(editCertificateForm.value)" [formGroup]="editCertificateForm">
        <div class="row card-panel">

          <div class="input-field class col s12 l6">
            <div class="label-input">Please Select a Course<strong class="red-text">*</strong></div>
            <select class="form-control" formControlName="course" id="course" autocomplete="off" require
              class="capitalize validate browser-default">
              <option *ngFor="let c of courses" [value]="c">{{c}}</option>
            </select>
          </div>

          <div class="input-field class col s12 l6">
            <div class="label-input">Institution Name <strong class="red-text">*</strong></div>
            <input formControlName="Institution" id="Institution" type="text" maxlength="48" autocomplete="off" require
              class="capitalize validate">
            <div *ngIf="(Institution.dirty || Institution.touched) && Institution.invalid">
              <small *ngIf="Institution.errors.required" class="red-text">The Institution Name Is Required!
              </small>
            </div>
          </div>

          <div class="input-field class col s12 l6">
            <div class="label-input"> Description</div>
            <input formControlName="description" id="description" type="text" maxlength="190" autocomplete="off"
              class="capitalize validate"> <!-- required class="capitalize validate" -->
          </div>

          <div class="input-field class col s12 l6">
            <div class="label-input"> Start Date <strong class="red-text">*</strong></div>
            <input id="start_date" formControlName="start_date" type="text" class="datepicker1"
              (change)="startDateChange()">
          </div>

          <div class="input-field class col s12 l6">
            <div class="label-input"> Date Completed</div>
            <input id="completion_date" formControlName="completion_date" type="text" class="datepicker2"
              (change)="completedDateChange()">
          </div>

          <div class="input-field class col s12 l6">
            <div class="label-input"> Expiry Date<strong class="red-text">*</strong></div>
            <input id="expiry_date" formControlName="expiry_date" type="text" class="datepicker3"
              (change)="expiryDateChange()">
          </div>
        </div>

        <div class="section"></div>

        <div class="col s12">
          <button class='at-btn col s12 btn waves-effect success' style="margin-bottom: 15px;" type="submit"
            [disabled]="!editCertificateForm.valid">
            <i class="material-icons right">add_circle</i> Update Certificate
          </button>

          <button data-target="modal1" class="col s12 btn modal-trigger red " style="margin-bottom: 15px;">Remove
            Certificate</button>

          <button type="button" [ngClass]="'col s12 btn waves-effect ' + themeColor " (click)="backClicked()">
            <i class="material-icons left">keyboard_arrow_left</i> Back To Certificate List
          </button>
        </div>
      </form>
    </div>
  </main>
</div>

<div id="modal1" class="modal">
  <div class="modal-content">
    <h5>Delete Certificate</h5>
    <strong> Do You Want to Remove This Certificate ? </strong>
  </div>
  <div class="modal-footer">
    <!--  <a class=" waves-effect waves-green btn-flat" (click)="DeleteCertificate()">Yes</a> -->
    <button type="button" (click)="DeleteCertificate()" class="btn waves-effect blue white-text"
      style="margin-right: 15px;"> Yes
    </button>
    <a class="modal-close amber darken-4 white-text btn-flat white-text">No</a>
  </div>
</div>