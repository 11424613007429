<div class="row center-align">
    <h6> <strong> Survey / Poll List</strong></h6>
</div>
<div class="row col s12">
    <div class="col m2"></div>
    <div class="col s12 m8">

        <app-firebase-loading *ngIf="loading"></app-firebase-loading>

        <div class="row card-panel white col s12" *ngIf="!model && !loading || (model && model.length == 0)">
            <p class="center"> <strong class="red-text"> <i class="material-icons">warning</i> 0 Surveys Found In the
                    Database</strong> </p>
        </div>

        <div *ngIf="model && model.length > 0">
            <div *ngFor="let item of model">
                <div class="row card-panel white">
                    <!-- <div class="row">
                        <h6 class="truncate"><strong>{{item.name}}</strong> </h6>
                    </div> -->
                    <ul class="row collection">
                        <li class="row collection-item">
                            <div class="col s4"><strong class="black-text">{{item.name}}</strong></div>
                            <div class="col s8 red-text"> <strong class="right"> End Date: {{item.endDate}} </strong>
                            </div>
                        </li>
                    </ul>
                    <div class="row">
                        <div class="col s12">
                            <a class="center col btn-flat s12 blue lighten-4"
                                [routerLink]="['/survey-result', item.id]">
                                <span style="cursor: pointer;"><i class="material-icons black-text"
                                        style="margin-right: 7px;">poll</i> <strong class="black-text">View
                                        Results</strong></span> </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="row col s12">
            <button [ngClass]="'col s12 btn waves-effect ' + themeColor" (click)="backClicked()">
                <i class="material-icons left">arrow_back_ios</i> Back To Previous Page
            </button>
        </div>
    </div>
    <div class="col m2"></div>
</div>
