import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EmployeeAuthGuard } from 'src/app/guards/employee-auth.guard';
import { EmployeeListComponent } from './department/department-employees/employee-list/employee-list.component';
import { InviteEmployeeComponent } from './department/department-employees/invite-employee/invite-employee.component';
import { DepartmentInfoComponent } from './department/department-info/department-info.component';
import { AddNewsComponent } from './department/department-news/add-news/add-news.component';
import { EditNewsComponent } from './department/department-news/edit-news/edit-news.component';
import { NewsListComponent } from './department/department-news/news-list/news-list.component';
import { SubDepartmentComponent } from './department/sub-department/sub-department.component';
import { EmployeeMainComponent } from './employee-main/employee-main.component';
import { EmployeeMenuComponent } from './employee-menu/employee-menu.component';
import { EmployeesRegistrationRequestComponent } from './employees-registration-request/employees-registration-request.component';
import { DiscussionComponent } from './Messaging/discussion/discussion.component';
import { InboxComponent } from './Messaging/inbox/inbox.component';
import { MyAccountComponent } from './my-account/my-account.component';

const routes: Routes = [
  {
    path: 'employee',
    component: EmployeeMainComponent,
    canActivate: [EmployeeAuthGuard],
    children: [
      {
        path: 'home',
        component: EmployeeMenuComponent,
        canActivate: [EmployeeAuthGuard],
      },
      {
        path: 'account',
        component: MyAccountComponent,
        canActivate: [EmployeeAuthGuard],
      },
      {
        path: 'account/:id',
        component: MyAccountComponent,
        canActivate: [EmployeeAuthGuard],
      },
      {
        path: 'inbox',
        component: InboxComponent,
        canActivate: [EmployeeAuthGuard],
      },
      {
        path: 'discussion/:id',
        component: DiscussionComponent,
        canActivate: [EmployeeAuthGuard],
      },
      {
        path: 'department-info',
        component: DepartmentInfoComponent,
        canActivate: [EmployeeAuthGuard],
      },
      {
        path: 'sub-department',
        component: SubDepartmentComponent,
        canActivate: [EmployeeAuthGuard],
      },
      {
        path: 'reg-request',
        component: EmployeesRegistrationRequestComponent,
        canActivate: [EmployeeAuthGuard],
      },
      {
        path: 'department-news-list',
        component: NewsListComponent,
        canActivate: [EmployeeAuthGuard],
      },
      {
        path: 'add-news',
        component: AddNewsComponent,
        canActivate: [EmployeeAuthGuard],
      },
      {
        path: 'edit-news/:id',
        component: EditNewsComponent,
        canActivate: [EmployeeAuthGuard],
      },
      {
        path: 'employee-list',
        component: EmployeeListComponent,
        canActivate: [EmployeeAuthGuard],
      },
      {
        path: 'invite-employee',
        component: InviteEmployeeComponent,
        canActivate: [EmployeeAuthGuard],
      },
    ],
  }];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})

export class EmployeeRoutingModule { }
