export class EmployabilityModel { 
    applicantID: number;
    applicationNo: number;

    IsNonDisabledEarners: string;
    IsDisabledEarners: string; 
    IsEmployable: string;
    IsPartiallyEmployable: string;
    IsUnemployable: string; 
    ReasonHealth: string; 
    ReasonSocial: string; 
    ReasonVF: string;
    IsSpouseNonDisabledEarners: string;
    IsSpouseDisabledEarners: string;
    IsSpouseEmployable: string;
    IsSpousePartiallyEmployable: string;
    IsSpouseUnemployable: string; 
    SpouseReasonHealth: string;
    SpouseReasonSocial: string;
    SpouseReasonVF: string;

  }