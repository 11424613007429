<div *ngIf="model" class="collection">
  <div class="col l6 m6 s12 coll-par">
    <a class="collection-item " *ngIf="isDemo">
      <div>Given Name:<a class="secondary-content blue-text"><strong>
            {{(model?.GivenName !== null) ? (model?.GivenName | NameStars) : ""}}</strong> </a></div>
    </a>
    <a class="collection-item" *ngIf="!isDemo">
      <div>Given Name:<a class="secondary-content blue-text"><strong> {{model?.GivenName}}</strong> </a></div>
    </a>
    <a class="collection-item" *ngIf="isDemo">
      <div>Middle Name:<a class="secondary-content blue-text"><strong>
            {{(model?.middle_name !== null) ? (model?.middle_name | NameStars) : ""}}</strong> </a></div>
    </a>
    <a class="collection-item" *ngIf="!isDemo">
      <div>Middle Name:<a class="secondary-content blue-text"><strong> {{model?.middle_name}}</strong> </a></div>
    </a>
    <a class="collection-item" *ngIf="isDemo">
      <div>Last Name:<a class="secondary-content blue-text"><strong>{{(model?.LastName !== null) ? (model?.LastName |
            NameStars) : ""}}</strong></a>
      </div>
    </a>
    <a class="collection-item" *ngIf="!isDemo">
      <div>Last Name:<a class="secondary-content blue-text"><strong>{{model?.LastName}}</strong></a></div>
    </a>
    <a class="collection-item">
      <div>DOB:<a class="secondary-content black-text">{{ model?.DOB | date: 'dd/MMM/yyyy' }}</a></div>
    </a>
    <a class="collection-item" *ngIf="!dependant && isDemo">
      <div>Treaty No:<a class="secondary-content black-text">{{(model?.IndianStatusNo !== null) ? (model?.IndianStatusNo
          | SinStars) : ""}}</a>
      </div>
    </a>
    <a class="collection-item" *ngIf="!dependant && !isDemo">
      <div>Treaty No:<a class="secondary-content black-text">{{model?.IndianStatusNo}}</a></div>
    </a>
    <a class="collection-item" *ngIf="!dependant">
      <div>Nation:<a class="secondary-content black-text">{{ model?.Band }}</a></div>
    </a>
    <a class="collection-item" *ngIf="!dependant">
      <div>House Number:<a class="secondary-content black-text">{{ model?.HouseNumber }}</a></div>
    </a>
    <a class="collection-item" *ngIf="!dependant">
      <div>Address:<a class="secondary-content black-text">{{ model?.MailAddress }}</a></div>
    </a>
  </div>

  <div class="col l6 m6 s12">
    <a class="collection-item" *ngIf="!dependant">
      <div>City:<a class="secondary-content black-text">{{ model?.city }}</a></div>
    </a>
    <a class="collection-item" *ngIf="!dependant">
      <div>Province:<a class="secondary-content black-text">{{ model?.province }}</a></div>
    </a>
    <a class="collection-item" *ngIf="!dependant">
      <div>Postal Code:<a class="secondary-content black-text">{{ model?.PostalCode }}</a></div>
    </a>
    <a class="collection-item" *ngIf="!dependant">
      <div>Home Phone:<a class="secondary-content black-text">{{ model?.Telephone }} </a></div>
    </a>
    <a class="collection-item" *ngIf="!dependant">
      <div>Fax:<a class="secondary-content black-text">{{ model?.Fax }}</a></div>
    </a>
    <a class="collection-item" *ngIf="!dependant">
      <div>Mobile:<a class="secondary-content black-text">{{ model?.cell_phone_num }}</a></div>
    </a>
    <a class="collection-item" *ngIf="isDemo">
      <div>Email:<a class="secondary-content black-text">{{(model?.Email !== null) ? (model?.Email | NameStars) :
          ""}}</a>
      </div>
    </a>
    <a class="collection-item" *ngIf="!isDemo">
      <div>Email:<a class="secondary-content black-text">{{ model?.Email }}</a></div>
    </a>
  </div>
</div>

<div class="section" *ngIf="!fromCandidates">
  <button *ngIf="hasBD && !dependant" type="button" [disabled]="loggedUser.isDemo"
    class="col s12 l3 btn waves-effect blue modal-trigger right" data-target="modal3">Update Profile</button>

  <button *ngIf="!hasBD && !dependant" type="button" [disabled]="loggedUser.isDemo" style="margin-bottom: 15px;"
    class="col s12 l3 btn waves-effect blue modal-trigger right" data-target="modal2">Update Profile <i
      class="material-icons right">location_on</i>
  </button>
</div>

<div id="modal2" class="modal modal-fixed-footer" *ngIf="!dependant">
  <form [formGroup]="updateProfileForm" (ngSubmit)="SubmitProfile(updateProfileForm.value)">
    <div class="modal-content">
      <h5 class="title-section4 bold-600">Update Personal Information</h5>
      <div class="row">

        <div class="input-field col s12 l4 ">
          <div class="label-input">House Number</div>
          <input type="text" formControlName='house_number' class="validate" autocomplete="off"
            placeholder="House Number" />
        </div>

        <div class="input-field col s12 l4">
          <div class="label-input">Address</div>
          <input type="text" formControlName='mailAddress' class="validate" autocomplete="off" placeholder="Address" />
        </div>

        <div class="input-field col s12 l4">
          <div class="label-input">City</div>
          <input id="city" type="text" formControlName='city' class="validate" autocomplete="off"
            style="text-transform: uppercase;" placeholder="City" />
        </div>

        <div class="input-field col s12 l4">
          <div class="label-input">Postal Code</div>
          <input id="postalCode" type="text" formControlName='postalCode' class="validate" autocomplete="off"
            style="text-transform: uppercase;" [textMask]="{mask: postalCodeMask}" placeholder="Postal Code" />
        </div>

        <div class="row input-field col s12 l4">
          <div class="label-input">Province</div>
          <select formControlName="province" required class="validate browser-default">
            <option value="" disabled selected>Choose your Province</option>
            <option *ngFor="let t of provinces" [value]="t">{{t}}</option>
          </select>
        </div>

        <div class="input-field col s12 l4">
          <div class="label-input">Home Phone</div>
          <input id="telephone" [textMask]="{mask: phoneNumberMask, guide: true}" type="text"
            formControlName="telephone" class="validate" placeholder="Home Phone">
        </div>

        <div class="input-field col s12 l4">
          <div class="label-input">Fax No</div>
          <input id="fax" [textMask]="{mask: phoneNumberMask, guide: true}" type="text" formControlName="fax"
            class="validate" placeholder="Fax No">
        </div>

        <div class="input-field col s12 l4">
          <div class="label-input">Mobile</div>
          <input id="cell" [textMask]="{mask: phoneNumberMask, guide: true}" type="text" formControlName="cell"
            class="validate" placeholder="Mobile">
        </div>

        <div class="input-field col s12 l4 ">
          <div class="label-input">Email <strong class="red-text">*</strong></div>
          <input id="email" type="email" formControlName='email' class="validate" autocomplete="off"
            placeholder="Email" />
          <div *ngIf="(email.dirty || email.touched) && email.invalid">
            <small *ngIf="email.errors.email" class="red-text"> wrong email format. </small>
          </div>
        </div>

        <div class="input-field col s12 l4">
          <div class="label-input">Health Care No</div>
          <input id="health_care_no" type="number" formControlName="health_care_no" class="validate"
            placeholder="Health Care No">
        </div>

        <div class="input-field col s12 l4">
          <div class="label-input">SIN</div>
          <input [readonly]="hasSIN" id="SIN" type="text" formControlName="SIN" class="validate"
            [textMask]="{mask: SINMask, guide: true}" placeholder="SIN">
        </div>

        <div class="input-field col s12 l4">
          <div class="label-input">Marital Status<span class="red-text">*</span></div>
          <select id="marital_status" formControlName="marital_status" required class="validate browser-default"
            (change)="maritalStatusChange($event.target.value)">
            <option value="" disabled selected>Choose your option</option>
            <option *ngFor="let status of maritalStatus" [value]="status">{{status}}</option>
          </select>
          <div>
            <small *ngIf="!maritalStatusSelected" class="red-text"> Marital Status is required.
            </small>
          </div>
        </div>
      </div>
    </div>

    <div class="modal-footer">
      <button type="submit" [disabled]="!updateProfileForm.valid" class="modal-close btn waves-effect green"
        style="margin-right: 15px;">
        <i class="material-icons right white-text">cloud_upload</i> Update
      </button>
      <a class="modal-close amber darken-4 white-text btn-flat white-text"> <i
          class="material-icons right">close</i>Cancel</a>
    </div>
  </form>
</div>

<!-- Modal Structure -->
<div id="modal3" class="modal">
  <div *ngIf="!model.fromAdmin" class="modal-content">
    <h6><i class="material-icons blue-text" style="display: inline-flex; vertical-align: top;">info</i> Your File is up
      to date</h6>
    <p><strong>If you need to make
        changes please contact your IA Administrator </strong> </p>
  </div>
  <div *ngIf="model.fromAdmin" class="modal-content">
    <h6><i class="material-icons blue-text" style="display: inline-flex; vertical-align: top;">info</i> Client has BD
    </h6>
    <p><strong>This Client Profile needs to be updated by their case manager.</strong> </p>
  </div>
  <div class="modal-footer">
    <a class="modal-close red btn-flat white-text">Close</a>
  </div>
</div>

<div id="MobileModal" class="modal">
  <form [formGroup]="contactForm" (ngSubmit)="updateNumber(contactForm.value)">
    <div class="modal-content">
      <h6><strong> Contact Info </strong></h6>
      <p>Please update your Mobile number in order to receive SMS alert and important updates from
        {{nationName}}
      </p>
      <div class="row">
        <div class="input-field col s12">
          <i class="material-icons prefix green-text text-darken-2">smartphone</i>
          <!-- <input id="cell1" type="text" formControlName='cell1' class="validate" autocomplete="off"
            [textMask]="{mask: phoneNumberMask}" /> -->
            <input id="cell1" type="text" formControlName='cell1' class="validate" autocomplete="off"/>
          <label for="cell1">Mobile Number </label>
        </div>
      </div>
    </div>
    <div class="modal-footer">
      <button type="submit" [disabled]="!contactForm.valid" class="btn-flat  green white-text">
        Update
      </button>
      <a class="modal-close waves-effect waves-green btn-flat">Maybe Later</a>
    </div>
  </form>
</div>