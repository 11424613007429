import { Component, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MemberModel } from 'src/app/models/memberModel';
import { MemberService } from 'src/app/services/api/member.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { EmployeeRegRequestService } from 'src/app/services/firebase/employee-reg-request.service';
import { environment } from 'src/environments/environment';
import { toast } from 'materialize-css';
import { FireMembersService } from 'src/app/services/firebase/fire-members.service';
import { Subscription } from 'rxjs';
import { EmailModel } from 'src/app/models/emailModel';
import { EmailService } from 'src/app/services/api/email.service';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-register-employee',
  templateUrl: './register-employee.component.html',
  styleUrls: ['./register-employee.component.css']
})
export class RegisterEmployeeComponent implements OnInit {

  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  loading = false;
  applicantID: string = null;
  member: MemberModel = null;
  registrationForm: UntypedFormGroup;
  request: any;
  verificationForm: UntypedFormGroup;
  hasMemberAccount = false;
  firebaseMember: any;
  step1 = true;
  step2 = false;
  accoutExists = false;
  accoutLinked = false;
  step3 = false;
  contactForm: UntypedFormGroup;
  contact = false;
  adminEmail = 'mihret@myNation.app';

  slicedIsnNumber: any;
  displayedISN: any;
  slicedSinNumber: any;
  displayedSIN: any;
  isMember = false;
  subscription: Subscription;
  observer: any;

  constructor(private fb: UntypedFormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private memberService: MemberService,
    private authService: AuthService,
    private employeeRegReqService: EmployeeRegRequestService,
    private fireMemberService: FireMembersService,
    private emailService: EmailService,) { }

  ngOnInit(): void {
    this.loading = true;

    this.route.paramMap.subscribe(params => {
      if (params.get('id')) {
        this.applicantID = params.get('id');
        
        //get request
        this.employeeRegReqService.getRequestByMemberId(this.applicantID).valueChanges().subscribe(req => {
          if (req) {
            this.request = req[0];
          
            //check if it is already registred.
            this.observer = this.fireMemberService.getMemberByAppliciantId(Number(this.applicantID)).valueChanges().subscribe(x => {
              if (x && x.length > 0) {
                this.firebaseMember = x[0];

                if (this.request.isMember) {
                  if (x[0].role == 'Member') {
                    this.hasMemberAccount = true;
                    $('.modal').modal({ dismissible: false });
                    $('#linkAccount').modal('open');
                    return;
                  } else if (x[0].role == 'Employee') {
                    this.step1 = false;
                    this.step2 = false;
                    this.accoutExists = true;
                    return;
                  }
                } else {
                  this.step1 = false;
                  this.step2 = false;
                  this.accoutExists = true;
                  return;
                }
              }
            });
            this.loading = false;
          }
        });

        this.memberService.getMember(Number(this.applicantID)).subscribe(data => {
          if (data) {
            this.member = data[0];

            if (!this.member.PWA_Verify_SIN && !this.member.PWA_Verify_ISN && !this.member.PWA_Secret_Question) {
              this.step1 = false;
              this.step2 = true;
            }
            this.verificationForm = this.fb.group({});

            if (this.member.PWA_Verify_SIN) {
              this.slicedSinNumber = this.member.SIN.slice(-3);
              this.displayedSIN = 'XXXXXX';
              this.verificationForm.addControl('sin', new UntypedFormControl(this.displayedSIN, [Validators.required, Validators.minLength(9)]));
            }
            if (this.member.PWA_Verify_ISN) {
              this.slicedIsnNumber = this.member.IndianStatusNo.slice(-3);
              this.displayedISN = 'XXXXX';
              this.verificationForm.addControl('isn', new UntypedFormControl(this.displayedISN, [Validators.required, Validators.minLength(10)]));
            }
            // if (!this.member.PWA_Secret_Question) {
            //   this.question.addValidators([Validators.required]);
            //   //this.verificationForm.addControl('question', new FormControl('', Validators.required));
            // }
            this.registrationForm = this.fb.group({
              email: [this.member.Email, [Validators.required]],
              createPassword: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(25),]],
              confirmPassword: ['', [Validators.required, this.passwordConfirming]],
            });

            if (this.member.Email) {
              //this.registrationForm.controls['email'].disable();
            }

            setTimeout(() => {
              M.updateTextFields();
            }, 25);
          }
          this.loading = false;
        });
      }
    });
  }

  get sin() { return this.verificationForm.get('sin'); }
  get isn() { return this.verificationForm.get('isn'); }
  get dob() { return this.verificationForm.get('dob'); }
  
  passwordConfirming(c: AbstractControl): any {
    if (!c.parent || !c) { return; }
    const pwd = c.parent.get('createPassword');
    const cpwd = c.parent.get('confirmPassword');

    if (!pwd || !cpwd) { return; }
    if (pwd.value !== cpwd.value) {
      return { invalid: true };
    }
  }

  get email() { return this.registrationForm.get('email'); }
  get createPassword() { return this.registrationForm.get('createPassword'); }
  get confirmPassword() { return this.registrationForm.get('confirmPassword'); }

  verification(data: any) {
    if (this.member.PWA_Verify_SIN) {
      if (Number(data.value.sin.slice(-3)) !== Number(this.member.SIN.slice(-3))) {
        // tslint:disable-next-line: max-line-length
        toast({ html: '<span> <i class="material-icons left">error_outline</i> Invalid Social Insurance Number ! </span>', classes: 'red' });
        return;
      }
    }

    if (this.member.PWA_Verify_ISN) {
      if (Number(data.value.isn.slice(-5)) !== Number(this.member.IndianStatusNo.slice(-5))) {
        toast({ html: '<span> <i class="material-icons left">error_outline</i> Invalid Indian Status Number ! </span>', classes: 'red' });
        return;
      }
    }

    if (this.member.PWA_Secret_Question) {

      const answer = this.member.PWA_Secret_Answer;
      const response = data.value.question;
      if (response.replace(/\s/g, '').toLowerCase() !== answer.replace(/\s/g, '').toLowerCase()) {
        toast({ html: '<span> <i class="material-icons left">error_outline</i> Invalid Answer ! </span>', classes: 'red' });
        return;
      }
    }
    toast({ html: '<span> <i class="material-icons left">check_circle_outline</i> Verification Success. </span> ', classes: 'green' });

    this.step1 = false;
    this.step2 = true;
  }

  register(values) {
    const model = {
      applicantID: this.member.applicantID,
      GivenName: this.member.GivenName,
      middle_name: (this.member.middle_name) ? this.member.middle_name : '',
      LastName: this.member.LastName,
      DOB: (this.member.DOB) ? this.member.DOB : '',
      Sex: (this.member.Sex) ? this.member.Sex : '',
      Band: (this.member.Band) ? this.member.Band : '',
      Email: this.request.email,
      DateAdded: new Date(),
      role: 'Employee',
      status: 'Enabled',
      lastLogin: new Date(),
      loginCount: 0,
      community: (this.member.Community) ? this.member.Community : '',
      residency: (this.member.Residency) ? this.member.Residency : '',
      isDemo: (this.member.role) ? true : false,
      departmentId: this.request ? this.request.department : '',
      //isMember: this.request.isMember
    }

    this.authService.employeeEmailSignUp(model, values.createPassword).then(() => {
      {
        if (this.request) this.employeeRegReqService.updateRequestStatus(this.request.id, 3);
        this.memberService.updateInvitationStatus(this.member.applicantID).subscribe(x => {
          if (x.Status == 'OK') {
            toast({ html: "Registration Successfull", classes: 'green' });
            this.router.navigate(['auth/login']);
          }
        });
      }
    }).catch(error => {
      toast({ html: error.message, classes: 'red' });
    });
  }

  linkAccount() {
    this.firebaseMember.departmentId = this.request.department;
    this.firebaseMember.role = 'Employee';
    this.firebaseMember.isMember = true;
    this.fireMemberService.updateAccountRole(this.firebaseMember).then(x => {
      this.observer.unsubscribe();
      this.step1 = false;
      this.step2 = false;
      this.accoutExists = false;
      this.accoutLinked = true;

      $('.modal').modal('close');
      toast({ html: "Your account has been linked. You can now access both accounts.", classes: 'green' });
    });
  }

  createEmployeeAccount() {
    this.email.patchValue(this.request.workEmail);
    this.email.disable();
    $('.modal').modal('close');
  }

  contactAdmin() {
    this.contactForm = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      subject: ['', [Validators.required]],
      body: ['', [Validators.required]],
    });
    this.contact = true;
    setTimeout(() => {
      $('.modal').modal();
      $('#emailModal').modal('open');
    }, 25);

  }

  get contactfirstName() { return this.contactForm.get('firstName'); }
  get contactlastName() { return this.contactForm.get('lastName'); }
  get contactemail() { return this.contactForm.get('email'); }
  get subject() { return this.contactForm.get('subject'); }
  get body() { return this.contactForm.get('body'); }

  sendEmail(model: any) {
    const mail = new EmailModel();
    mail.emailList = [this.adminEmail];
    mail.subject = 'You have a new message from ' + model.firstName + ' ' + model.lastName;
    mail.body = 'From : ' + model.email + ' <br/> Subject : <strong> ' + model.subject + ' </strong> <br/> <p>' + model.body + '</p>';
    this.emailService.sendEmail(mail).subscribe(x => {
      if (x.Status && x.Status == 'OK') {
        $('#emailModal').modal('close');
        this.accoutExists = false;
        this.step3 = true;
      }
    })
  }
}