<div class="row center-align">
    <h5><strong>Poll / Referendum Result </strong></h5>
</div>
<div class="row col s12">
    <div class="col l1"></div>
    <div class="col s12 l10">
        <div>
            <app-polls-result></app-polls-result>
        </div>
        <div class="row col s12">
           
            <button [ngClass]="'col s12 btn waves-effect ' + themeColor" (click)="backClicked()">
                <i class="material-icons left">arrow_back_ios</i> Back To Previous Page
            </button>
           
        </div>
    </div>
    <div class="col l1"></div>
</div>