import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { SupplementalSupportService } from 'src/app/services/api/SupplementalSupport.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { SupplementalSupportModel } from 'src/app/models/supplementalSupportModel';
import { environment } from 'src/environments/environment';

declare var $: any;

@Component({
  selector: 'app-supplemental-support',
  templateUrl: './supplemental-support.component.html',
  styleUrls: ['./supplemental-support.component.css']
})

export class SupplementalSupportComponent implements OnInit {

  themeColor = environment.appTheme.themeColor;
  declarationDate = new Date();
  months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  list: SupplementalSupportModel[];
  applicantID = 0;
  constructor(private location: Location, private supplementalSupportService: SupplementalSupportService,
    private authService: AuthService) { }

  ngOnInit() {
    if (localStorage.getItem('applicantID')) {
      this.applicantID = Number(localStorage.getItem('applicantID'));
    } else {
      this.applicantID = this.authService.getCurrentApplicantID();
    }
    this.supplementalSupportService.getSupplementalSupportByApplicantID(this.applicantID).subscribe(x => {
      this.list = x;
    });
  }

  backClicked() {
    this.location.back();
  }
}