
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-apply-dialog',
  templateUrl: './apply-dialog.component.html',
  styleUrls: ['./apply-dialog.component.css']
})
export class ApplyDialogComponent implements OnInit {

  dialogForm: UntypedFormGroup;
  themeColor = environment.appTheme.themeColor;

  constructor(private router: Router, private fb: UntypedFormBuilder, private location: Location) { }

  ngOnInit(): void {
   this.dialogForm = this.fb.group({
      //is_existing_client: [null, [Validators.required]] 
   });
  }

  submitForm(value: any) {
    //this.model.is_existing_client= (value.is_existing_client === 'yes') ? true : false;
  }

  backClicked() {
    this.location.back();
  }

  ApplyClicked() {
    this.router.navigate(['/member/isets/new-application']);
  }

  RegisterClicked() {
    this.router.navigate(['/member/isets/registration']);
  }
}