<div class="row  login-signup-button">
    <div *ngIf="setting" class="col l8 push-l2 s12">

        <div class="col s10 m8 push-s1 push-m2" *ngIf="!hasISET">
            <a [routerLink]="['/auth/login']"
                [ngStyle]="{'background-color': setting.button.bgColor, 'color': setting.button.titleColor, 'border-color': setting.button.borderColor}"
                class="btn-large col s12 m12 center-align center  waves-teal login-button"
                [ngClass]="'btn ' + buttonType">{{setting.displayName}}</a>
        </div>

        <div class="col s12 m6 l6" *ngIf="hasISET">
            <a [routerLink]="['/auth/login']"
                [ngStyle]="{'background-color': setting.button.bgColor, 'color': setting.button.titleColor, 'border-color': setting.button.borderColor}"
                class="btn-large col s12 m12 waves-effect waves-teal login-button"
                [ngClass]="'btn ' + buttonType">{{setting.displayName}}</a>
        </div>

         <!-- ISET Registration Form  -->
        <div class="col s12 m6 l6" *ngIf="hasISET && ISETRegSetting">
            <a [routerLink]="['/iset-registration-form']"
                [ngStyle]="{'background-color': ISETRegSetting.button.bgColor, 'color': ISETRegSetting.button.titleColor, 'border-color': ISETRegSetting.button.borderColor}"
                class="btn-large col s12 m12 request-button" [ngClass]="'btn ' + buttonType">
                {{ISETRegSetting.displayName}}
            </a>
        </div>
    </div>
</div>