import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { trigger, transition, useAnimation } from '@angular/animations';
import { bounceIn } from 'ng-animate';
import { SocialMediaService } from 'src/app/services/firebase/social-media.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { FireMembersService } from 'src/app/services/firebase/fire-members.service';
import { environment } from 'src/environments/environment';
import { ApplicationService } from 'src/app/services/firebase/application.service';
import { MemberService } from 'src/app/services/api/member.service';
import { PseApplicationService } from 'src/app/services/api/pse-application.service';
import { ActionPlanService } from 'src/app/services/api/isets/actionPlan.service';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { Router } from '@angular/router';
import { NationSettingsService } from 'src/app/services/api/nation-settings.service';
import { toast } from 'materialize-css';
import { DataService } from 'src/app/services/data.service';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-member-home-sub-menu',
  templateUrl: './member-home-sub-menu.component.html',
  styleUrls: ['./member-home-sub-menu.component.css'],
  animations: [
    trigger('swing', [transition('* => *', useAnimation(bounceIn))])
  ],
})

export class MemberHomeSubMenuComponent implements OnInit {

  nationName = environment.firstNation.displayName;
  defaultThemeColor = environment.appTheme.themeColor;
  themeColor: any;
  globalUser: any;

  myProfileMenu = false;
  socialDevelopmentMenu = false;
  socialNetworkMenu = false;
  pollsAndSurvey = false;
  contactUs = false;
  myHouse = false;
  myResume = false;
  NewsEventMenu = false;
  NewsMenu = false;
  EventMenu = false;
  myServicesMenu = false;
  myNationMenu = false;
  JobRelatedMenu = false;

  isCandidate = false;
  applicantID = 0;
  user: any;

  myServices = false;
  ISETSMenu = false;
  PSEMenu = false;

  socialMedia = {
    facebook: '',
    twitter: '',
    instagram: '',
    youtube: ''
  };
  // currentUserId = '';
  appMenus = [];

  isAppApproved = false;
  province = environment.firstNation.province;
  t_fundingBD = 0;

  //disable_ISET = (environment.firstNation.name === "Demo") ? true: false;
  //disable_ISET = (environment.firstNation.name === "Lrrcn") ? true : false;
  actionPlans: any[];
  hasAP = false;
  action_plan_id = 0;

  // SOIAL_OK = true;
  // PSE_OK = true;
  // ISETS_OK = true;

  SHOW_SOCIAL = environment.firstNation.SOCIAL;
  SHOW_ISETS = environment.firstNation.ISET;
  SHOW_PSE = environment.firstNation.PSE;

  menus: any;
  menu: any;
  defaultMenus: any;
  from: any;

  nationOfUse = environment.firstNation.name;
  message: any;

  back_flag = false;
  nationContactInfo: any;

  constructor(
    private location: Location,
    private router: Router,
    private service: SocialMediaService,
    private fireMembersService: FireMembersService,
    private authService: AuthService,
    private applicationService: ApplicationService,
    private memberService: MemberService,
    private pseService: PseApplicationService,
    private actionPlanService: ActionPlanService,
    private settingsService: AppSettingsService,
    private appSettingService: AppSettingsService,
    private nationSettingsService: NationSettingsService,
    private dataService: DataService
  ) {
    this.globalUser = this.authService.getGlobalUser();
    this.themeColor = this.settingsService.getAppThemeColor() ? this.settingsService.getAppThemeColor() : this.defaultThemeColor;

    this.defaultMenus = this.dataService.getMemberMenus();
    // this.defaultMenus = [
    //   {
    //     name: 'MyAccount',
    //     displayName: 'My Account',
    //     icon: 'account_circle',
    //     isEnabled: true,
    //     index: 0,
    //     submenus: [
    //       {
    //         name: 'MyPersonalInformation',
    //         displayName: "My Personal Information",
    //         icon: 'account_box',
    //         isEnabled: true,
    //         index: 0,
    //       },
    //       {
    //         name: 'FamilyComposition',
    //         displayName: "Family Composition",
    //         icon: 'people',
    //         isEnabled: true,
    //         index: 1,
    //         underConstruction: false
    //       },
    //       {
    //         name: 'ISApplication',
    //         displayName: "IS Application",
    //         icon: 'import_contacts',
    //         isEnabled: true,
    //         index: 2,
    //       },
    //       {
    //         name: 'SKISApplication',
    //         displayName: "SK IS Application",
    //         icon: 'import_contacts',
    //         isEnabled: true,
    //         index: 3,
    //       },
    //       {
    //         name: 'MyCasePlan',
    //         displayName: 'My Case Plan',
    //         icon: 'add_business',
    //         isEnabled: true,
    //         index: 4,
    //       },
    //       {
    //         name: 'UploadDocuments',
    //         displayName: 'Upload Documents',
    //         icon: 'cloud_upload',
    //         isEnabled: true,
    //         index: 5,
    //       },
    //       {
    //         name: 'MySettings',
    //         displayName: 'My Settings',
    //         icon: 'settings',
    //         isEnabled: true,
    //         index: 6,
    //       },
    //       {
    //         name: 'MyAreasOfInterest',
    //         displayName: 'My Areas Of Interest',
    //         icon: 'share',
    //         isEnabled: true,
    //         index: 7,
    //       },
    //       {
    //         name: 'IASurvey',
    //         displayName: 'IA Survey',
    //         icon: 'list_alt',
    //         isEnabled: true,
    //         index: 8,
    //       }
    //     ]
    //   },

    //   {
    //     name: 'MyChat',
    //     displayName: 'My Chat / Circle',
    //     icon: 'mail',
    //     isEnabled: true,
    //     index: 1,
    //     submenus: []
    //   },

    //   {
    //     name: 'NewsAndEvents',
    //     displayName: 'News / Events',
    //     icon: 'notifications_none',
    //     isEnabled: true,
    //     index: 2,
    //     submenus: [
    //       {
    //         name: 'News',
    //         displayName: "News",
    //         icon: 'circle_notifications',
    //         isEnabled: true,
    //         index: 0,
    //       },
    //       {
    //         name: 'Events',
    //         displayName: "Events",
    //         icon: 'event_available',
    //         isEnabled: true,
    //         index: 1,
    //       },
    //     ]
    //   },

    //   {
    //     name: 'News',
    //     displayName: 'News',
    //     icon: 'notifications_none',
    //     isEnabled: true,
    //     index: 2,
    //     submenus: []
    //   },

    //   {
    //     name: 'Events',
    //     displayName: 'Events',
    //     icon: 'event_available',
    //     isEnabled: true,
    //     index: 3,
    //     submenus: []
    //   },
    //   {
    //     name: 'MyServices',
    //     displayName: 'My Services',
    //     icon: 'home_repair_service',
    //     isEnabled: true,
    //     index: 4,
    //     submenus: [
    //       {
    //         name: 'SocialDevelopment',
    //         displayName: 'Social Development',
    //         icon: 'assessment',
    //         isEnabled: true,
    //         index: 0,
    //         submenus: [
    //           {
    //             name: 'RequiredSignature',
    //             displayName: 'Required Signature',
    //             icon: 'pending_actions',
    //             isEnabled: true,
    //             index: 0,
    //           },
    //           {
    //             name: 'MyBenefits',
    //             displayName: 'My Benefits',
    //             icon: 'update',
    //             isEnabled: true,
    //             index: 1,
    //           },
    //           {
    //             name: 'RequestBenefits',
    //             displayName: 'Request Benefits',
    //             icon: 'add_circle',
    //             isEnabled: true,
    //             index: 2,
    //           },
    //           // {
    //           //   name: 'IncomeStatement',
    //           //   displayName: 'Submit My Income Statement',
    //           //   icon: 'monetization_on',
    //           //   isEnabled: true,
    //           //   index: 3,
    //           // },
    //           // {
    //           //   name: 'ActionPlan',
    //           //   displayName: 'My Action Plan',
    //           //   icon: 'supervised_user_circle',
    //           //   isEnabled: true,
    //           //   index: 4,
    //           // },
    //           // {
    //           //   name: 'PastBenefits',
    //           //   displayName: 'My Past Benefits',
    //           //   icon: 'history',
    //           //   isEnabled: true,
    //           //   index: 5,
    //           // },
    //         ]
    //       },
    //       {
    //         name: 'Housing',
    //         displayName: 'Housing',
    //         icon: 'assessment',
    //         isEnabled: true,
    //         index: 1,
    //       },
    //       {
    //         name: 'ISET',
    //         displayName: 'I.S.E.T',
    //         icon: 'assessment',
    //         isEnabled: true,
    //         index: 2,
    //         submenus: [
    //           {
    //             name: 'NewApplication',
    //             displayName: 'New Application',
    //             icon: 'account_box',
    //             isEnabled: true,
    //             index: 0,
    //           },
    //           {
    //             name: 'UpdateApplication',
    //             displayName: 'Update Application',
    //             icon: 'account_box',
    //             isEnabled: true,
    //             index: 1,
    //           },
    //           {
    //             name: 'Interventions',
    //             displayName: 'My Interventions',
    //             icon: 'update',
    //             isEnabled: true,
    //             index: 2,
    //           },
    //           {
    //             name: 'ServicePlan',
    //             displayName: 'Service Plan',
    //             icon: 'account_box',
    //             isEnabled: true,
    //             index: 3,
    //           },
    //           {
    //             name: 'UploadISETDocuments',   //  name: 'UploadDocuments',
    //             displayName: 'Upload Documents',
    //             icon: 'pending_actions',
    //             isEnabled: true,
    //             index: 4,
    //           },
    //         ]
    //       },
    //       {
    //         name: 'PostSecondary',
    //         displayName: "Post Secondary Education",
    //         icon: 'assessment',
    //         isEnabled: true,
    //         index: 3,
    //         submenus: [
    //           {
    //             name: 'MyApplication',
    //             displayName: 'My Application',
    //             icon: 'pending_actions',
    //             isEnabled: true,
    //             index: 0,
    //           },
    //           {
    //             name: 'MyPSEBenefits',
    //             displayName: 'My PSE Benefits',
    //             icon: 'update',
    //             isEnabled: true,
    //             index: 1,
    //           },
    //           {
    //             name: 'ApplyforExtraFunding',
    //             displayName: 'Apply for Extra Funding',
    //             icon: 'account_box',
    //             isEnabled: true,
    //             index: 2,
    //           },
    //           {
    //             name: 'MyCourses',
    //             displayName: 'My Courses',
    //             icon: 'account_box',
    //             isEnabled: true,
    //             index: 3,
    //           },
    //           {
    //             name: 'StudentContract',
    //             displayName: 'Student Contract',
    //             icon: 'event_note',
    //             isEnabled: true,
    //             index: 4,
    //           },

    //           {
    //             name: 'ReleaseForm',
    //             displayName: 'Release Form',
    //             icon: 'fact_check',
    //             isEnabled: true,
    //             index: 5,
    //           },

    //         ]
    //       },
    //     ]
    //   },

    //   {
    //     name: 'MyNation',
    //     displayName: 'My Nation',
    //     icon: 'dashboard',
    //     isEnabled: true,
    //     index: 5,
    //     submenus: [
    //       {
    //         name: 'Departments',
    //         displayName: 'Departments / Offices',
    //         icon: 'account_tree',
    //         isEnabled: true,
    //         index: 0,
    //       },
    //       {
    //         name: 'Governance',
    //         displayName: 'Governance',
    //         icon: 'gavel',
    //         isEnabled: true,
    //         index: 1,
    //       },
    //       {
    //         name: 'Boards',
    //         displayName: 'Boards',
    //         icon: 'groups',
    //         isEnabled: true,
    //         index: 3,
    //       },
    //       {
    //         name: 'NationContact',
    //         displayName: "Nation Contact",
    //         icon: 'contact_mail',
    //         isEnabled: true,
    //         index: 4,
    //       },
    //     ]
    //   },

    //   {
    //     name: 'PollsAndElection',
    //     displayName: 'Polls / Election',
    //     icon: 'poll',
    //     isEnabled: true,
    //     index: 6,
    //     submenus: [
    //       {
    //         name: 'Nomination',
    //         displayName: "Nomination",
    //         icon: 'people',
    //         isEnabled: true,
    //         index: 0,
    //       },
    //       {
    //         name: 'Election',
    //         displayName: "Election",
    //         icon: 'how_to_vote',
    //         isEnabled: true,
    //         index: 1,
    //       },
    //       {
    //         name: 'PollsAndSurveys',
    //         displayName: "Polls and Surveys",
    //         icon: 'poll',
    //         isEnabled: true,
    //         index: 2,
    //       },
    //       {
    //         name: 'MyCampaign',
    //         displayName: "My Campaign",
    //         icon: 'campaign',
    //         isEnabled: true,
    //         index: 3,
    //       },
    //       {
    //         name: 'MyNominations',
    //         displayName: "My Nominations",
    //         icon: 'campaign',
    //         isEnabled: true,
    //         index: 4,
    //       },
    //       {
    //         name: 'MyElections',
    //         displayName: "My Elections",
    //         icon: 'how_to_vote',
    //         isEnabled: true,
    //         index: 5,
    //       }
    //     ]
    //   },

    //   {
    //     name: 'JobRelated',
    //     displayName: 'Job Related',
    //     icon: 'work',
    //     isEnabled: true,
    //     index: 7,
    //     submenus: [
    //       {
    //         name: 'ResumeBuilder',
    //         displayName: 'Resume Builder',
    //         icon: 'folder_shared',
    //         isEnabled: true,
    //         index: 0,
    //         submenus: [
    //           {
    //             name: 'Objective',
    //             displayName: 'Objective',
    //             icon: 'home_work',
    //             isEnabled: true,
    //             index: 0,
    //           },
    //           {
    //             name: 'Highlights',
    //             displayName: 'Highlights',
    //             icon: 'person_add',
    //             isEnabled: true,
    //             index: 1,
    //           },
    //           {
    //             name: 'WorkExperienceAndTraining',
    //             displayName: 'Work Experience / Training',
    //             icon: 'apartment',
    //             isEnabled: true,
    //             index: 2,
    //           },
    //           {
    //             name: 'Skills',
    //             displayName: 'Skills',
    //             icon: 'home_work',
    //             isEnabled: true,
    //             index: 3,
    //           },
    //           {
    //             name: 'Education',
    //             displayName: 'Education',
    //             icon: 'import_contacts',
    //             isEnabled: true,
    //             index: 4,
    //           },
    //           {
    //             name: 'CoursesAndCertificates',
    //             displayName: 'Courses / Certificates',
    //             icon: 'note',
    //             isEnabled: true,
    //             index: 5,
    //           },
    //           {
    //             name: 'Activities',
    //             displayName: 'Activities',
    //             icon: 'home_work',
    //             isEnabled: true,
    //             index: 6,
    //           },
    //           {
    //             name: 'References',
    //             displayName: 'References',
    //             icon: 'group_add',
    //             isEnabled: true,
    //             index: 7,
    //           }
    //         ]
    //       },

    //       {
    //         name: 'JobBoard',
    //         displayName: "Job Board",
    //         icon: 'how_to_vote',
    //         isEnabled: true,
    //         index: 1,
    //       },
    //     ]
    //   },

    //   {
    //     name: 'MyHouse',
    //     displayName: "My House",
    //     icon: 'house',
    //     isEnabled: true,
    //     index: 8,
    //     submenus: []
    //   },

    //   {
    //     name: 'SocialDevelopment',
    //     displayName: 'Social Development',
    //     icon: 'assessment',
    //     isEnabled: true,
    //     index: 9,
    //     submenus: []
    //   },

    //   {
    //     name: 'Logout',
    //     displayName: 'Logout',
    //     icon: 'logout',
    //     isEnabled: true,
    //     index: 10,
    //     submenus: []
    //   },
    // ];

    this.from = null;
  }

  ngOnInit() {
    if (localStorage.getItem('applicantID')) {
      this.applicantID = Number(localStorage.getItem('applicantID'));
    } else {
      this.applicantID = this.authService.getCurrentApplicantID();
    }

    this.fireMembersService.getMemberByAppliciantId(this.applicantID).valueChanges().subscribe(user => {
      if (user) {
        this.user = user[0];
        this.isCandidate = this.user.isCandidate ? this.user.isCandidate : false;
      }
    });

    // setTimeout(() => {
    //   $('select').formSelect();
    //   M.updateTextFields();
    // }, 25);

    this.settingsService.getMemberMenus().valueChanges().subscribe(menus => {
      if (menus && menus.length > 0) {
        this.menus = menus;
        this.menus = this.menus.sort((a, b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0));
      } else {
        this.menus = this.defaultMenus.sort((a, b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0));
      }

      this.appSettingService.getFromMenu().subscribe(from => {
        this.from = from;
        let menu = this.menus.filter(m => m.name == this.from);
        if (menu && menu.length > 0) {
          this.menu = menu[0];
          if (this.menu.submenus && this.menu.submenus.length > 0) {
            this.menu.submenus = this.menu.submenus.sort((a, b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0));
          }
        }

        if (this.from === 'MyServices') {
          let tmpmenu = this.menus.filter(m => m.name == 'MyServices');
          if (tmpmenu && tmpmenu.length > 0) {
            let servicesMenu = tmpmenu[0];
            let servicesSubmenu = servicesMenu.submenus;

            servicesSubmenu.forEach(m => {
              if (m.name == 'ISET') {
                if (this.SHOW_ISETS) { m.isEnabled = true; } else { m.isEnabled = false; }
              }

              if (m.name == 'PostSecondary') {
                if (this.SHOW_PSE) { m.isEnabled = true; } else { m.isEnabled = false; }
              }
            });
            this.menu.submenus = servicesSubmenu;
          }
        }
      });
    });

    if (localStorage.getItem('From')) {

      this.from = localStorage.getItem('From');
      if (localStorage.getItem('From') === 'MyAccount') { this.myProfileMenu = true; }
      if (localStorage.getItem('From') === 'NewsEvents') { this.NewsEventMenu = true; }
      if (localStorage.getItem('From') === 'News') { this.NewsMenu = true; }
      if (localStorage.getItem('From') === 'Events') { this.EventMenu = true; }
      //if (localStorage.getItem('From') === 'myServices') { this.myServicesMenu = true; }
      if (localStorage.getItem('From') === 'MyNation') { this.myNationMenu = true; }
      if (localStorage.getItem('From') === 'PollsAndElection') { this.pollsAndSurvey = true; }

      if (localStorage.getItem('From') === 'JobRelated') { this.JobRelatedMenu = true; }
      if (localStorage.getItem('From') === 'MyHouse') { this.myHouse = true; }
      if (localStorage.getItem('From') === 'SocialDevelopment') {
        this.socialDevelopmentMenu = true;
      }


      if (localStorage.getItem('From') === 'MyServices') {
        this.myServices = true;
        this.get_app_status();
      }//myService

      if (localStorage.getItem('From') === 'SocialNetwork') {
        this.socialNetworkMenu = true;
        this.service.getSocialMedia().valueChanges().subscribe(x => {
          if (x) {
            if (x.length > 0) {
              this.socialMedia = {
                facebook: x[0].facebook,
                twitter: x[0].twitter,
                instagram: x[0].instagram,
                youtube: x[0].youtube
              };
            }
          }
        });
      }
    }
    if (!this.globalUser?.verified) {
      this.nationSettingsService.getNationSettings().subscribe(sett => {
        if (sett) {
          this.nationContactInfo = sett[0];
        }
      });
    }
  }

  itemClicked(menu) {
    // if (!this.globalUser?.verified && (menu !== 'MyPersonalInformation' && menu !== 'News' && menu !== 'Events')) {
    //   if (this.nationContactInfo) {
    //     $('.modal').modal();
    //     $('#restrictedModal').modal('open');
    //   }
    // } else {
    if (menu == 'MyPersonalInformation') this.router.navigate(['member/member-main-home/profile/personal-information']);
    else if (menu == 'FamilyComposition') this.router.navigate(['member/member-main-home/profile/family-composition']);
    else if (menu == 'MySettings') this.router.navigate(['member/member-main-home/settings']);
    else if (menu == 'IASurvey') this.router.navigate(['member/member-main-home/profile/lrrcn-list']);
    else if (menu == 'ISApplication') this.router.navigate(['member/member-main-home/profile/is-application']);
    else if (menu == 'SKISApplication') this.router.navigate(['member/member-main-home/profile/sk-application']);
    else if (menu == 'MyCasePlan') this.router.navigate(['member/member-main-home/profile/case-plan']);
    // else if (menu == 'UploadDocuments') this.router.navigate(['member/member-main-home/upload-document']);
    else if (menu == 'UploadDocuments') this.router.navigate(['member/member-main-home/profile/upload-document']);
    else if (menu == 'MyAreasOfInterest') this.router.navigate(['member/member-main-home/profile/my-areas-of-interest']);

    else if (menu == 'News') this.router.navigate(['member/member-main-home/news']);
    else if (menu == 'Events') this.router.navigate(['member/member-main-home/events-calendar']);

    //My Services
    else if (menu == 'SocialDevelopment') this.socialDevelopmentClick();
    else if (menu == 'RequiredSignature') this.router.navigate(['member/member-main-home/monthly-assistance/multiple-bd']);
    else if (menu == 'MyBenefits') this.router.navigate(['member/member-main-home/monthly-assistance/my-entitlement']);
    else if (menu == 'RequestBenefits') this.router.navigate(['member/member-main-home/monthly-assistance/new-support-request']);

    else if (menu == 'ISET') this.isetsClick();
    else if (menu == 'NewApplication') this.router.navigate(['member/member-main-home/action-plan']);
    else if (menu == 'UpdateApplication') this.router.navigate(['member/member-main-home/edit-action-plan', this.action_plan_id]);
    else if (menu == 'Interventions') this.router.navigate(['member/member-main-home/intervention', this.applicantID]);
    else if (menu == 'ServicePlan') this.router.navigate(['member/member-main-home/service-plan']);
    else if (menu == 'UploadISETDocuments') this.router.navigate(['member/member-main-home/upload_documents']);

    else if (menu == 'PostSecondary') this.PSEClick();
    else if (menu == 'MyApplication') this.router.navigate(['member/member-main-home/pse-app']);
    else if (menu == 'MyPSEBenefits') this.router.navigate(['member/member-main-home/pse-entitlement']);
    else if (menu == 'ApplyforExtraFunding') this.router.navigate(['member/member-main-home/pse-fundings']);
    else if (menu == 'MyCourses') this.router.navigate(['member/member-main-home/pse-course']);
    else if (menu == 'StudentContract') this.router.navigate(['member/member-main-home/pse-contract']);
    else if (menu == 'ReleaseForm') this.router.navigate(['member/member-main-home/pse-release-form']);


    else if (menu == 'HouseApp') this.router.navigate(['member/member-main-home/house-app']);
    else if (menu == 'HouseDetail') this.router.navigate(['member/member-main-home/my-house']);
    else if (menu == 'Repairs') this.router.navigate(['member/member-main-home/house-repair']);
    else if (menu == 'Renovations') this.router.navigate(['member/member-main-home/house-renovation']);
    //My Nation
    else if (menu == 'Departments') this.router.navigate(['member/member-main-home/department']);
    else if (menu == 'Governance') this.router.navigate(['member/member-main-home/governance']);
    else if (menu == 'Boards') this.router.navigate(['member/member-main-home/boards-list']);
    else if (menu == 'NationContact') this.router.navigate(['member/member-main-home/member-home']);

    //Job related
    else if (menu == 'ResumeBuilder') this.myResumeClicked();
    else if (menu == 'JobBoard') this.router.navigate(['member/member-main-home/job-board']);
    else if (menu == 'Objective') this.router.navigate(['member/member-main-home/objective']);
    else if (menu == 'Highlights') this.router.navigate(['member/member-main-home/highlight']);
    else if (menu == 'WorkExperienceAndTraining') this.router.navigate(['member/member-main-home/employment']);
    else if (menu == 'Skills') this.router.navigate(['member/member-main-home/skill']);
    else if (menu == 'Education') this.router.navigate(['member/member-main-home/education']);
    else if (menu == 'CoursesAndCertificates') this.router.navigate(['member/member-main-home/certificate']);
    else if (menu == 'Activities') this.router.navigate(['member/member-main-home/activity']);

    else if (menu == 'References') this.router.navigate(['member/member-main-home/reference']);

    //polls and survey 
    else if (menu == 'Nomination') this.router.navigate(['member/member-main-home/my-nominations']);
    else if (menu == 'Election') this.router.navigate(['member/member-main-home/election-list']);
    else if (menu == 'PollsAndSurveys') this.router.navigate(['member/member-main-home/survey-list']);
    else if (menu == 'MyCampaign') this.router.navigate(['member/member-main-home/my-nominations']);
    else if (menu == 'MyNominations') this.router.navigate(['member/member-main-home/my-nominations']);
    else if (menu == 'MyElections') this.router.navigate(['member/member-main-home/survey-list']);
    //}
  }

  getVerified() {
    if (this.globalUser) {
      this.router.navigate(['/member/member-main-home/get-verified', this.globalUser?.uid]);
    } else {
      toast({ html: 'User ID can not be found.', classes: 'red' });
    }
    $('.modal').modal('close');
  }

  socialDevelopmentClick() {
    //this.myServices = false;
    this.socialDevelopmentMenu = true;
    this.appSettingService.updateFromMenu('');
    $(document).ready(function () {
      $('.tabs').tabs();
    });
  }

  isetsClick() {

    let menu = this.menus.filter(m => m.name == 'MyServices')[0];
    let menuSubmenu = menu.submenus;
    let submenu = menuSubmenu.filter(m => m.name == 'ISET');
    // if (submenu && submenu.length > 0) {
    //   this.menu = submenu[0];
    //   if (this.menu.submenus && this.menu.submenus.length > 0) {
    //     this.menu.submenus = this.menu.submenus.sort((a, b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0));     
    //   }

    if (submenu && submenu.length > 0) {

      this.menu = submenu[0];
      if (this.menu.submenus && this.menu.submenus.length > 0) {

        let tmpsubMenu = this.menu.submenus;
        if (this.hasAP) {
          tmpsubMenu[0].isEnabled = false;
          tmpsubMenu[1].isEnabled = true;
        } else {
          tmpsubMenu[0].isEnabled = true;
          tmpsubMenu[1].isEnabled = false;
        }
        this.menu.submenus = tmpsubMenu.sort((a, b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0));

      }

    }
    //  this.myServices = false;
    //  this.ISETSMenu = true;

    this.back_flag = true;

  }

  PSEClick() {
    // this.check_app_status();
    let menu = this.menus.filter(m => m.name == 'MyServices')[0];
    let menuSubmenu = menu.submenus;
    let submenu = menuSubmenu.filter(m => m.name == 'PostSecondary');
    if (submenu && submenu.length > 0) {
      this.menu = submenu[0];
      if (this.menu.submenus && this.menu.submenus.length > 0) {
        this.menu.submenus = this.menu.submenus.sort((a, b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0));
      }
    }
    // this.myServices = false;
    // this.PSEMenu = true;
    this.back_flag = true;

  }

  myResumeClicked() {
    let menu = this.menus.filter(m => m.name == 'JobRelated')[0];
    let menuSubmenu = menu.submenus;
    let submenu = menuSubmenu.filter(m => m.name == 'ResumeBuilder');
    if (submenu && submenu.length > 0) {
      this.menu = submenu[0];

      if (this.menu.submenus && this.menu.submenus.length > 0) {
        this.menu.submenus = this.menu.submenus.sort((a, b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0));
      }
    }
    // this.myResume = true;
  }

  housingClick() {

  }

  socialBackClicked() {
    this.myServices = true;
    this.socialDevelopmentMenu = false;
  }
  isetsBackClicked() {
    this.myServices = true;
    this.ISETSMenu = false;
  }
  pseBackClicked() {
    this.myServices = true;
    this.PSEMenu = false;
  }

  backToJobRelated() {
    this.myResume = false;
    this.JobRelatedMenu = true;
  }

  check_app_status() {

    // this.memberService.getMember(this.applicantID).subscribe(x => {   
    //   if (x[0].current_PSE_app_id) {
    //     this.hasApp = true;     
    //     let app_id = x[0].current_PSE_app_id;

    //     this.pseService.getPSEApplication(app_id).subscribe(y => {
    //           if (y) { 

    //               let myApp = y[0];
    //               this.isAppApproved = myApp.approved ? myApp.approved : false;

    //               this.pseService.getIfApplicationHasCurrentBD(app_id).subscribe(z => {
    //                   if (z) {         
    //                       this.t_fundingBD = z.count;  

    //                       if(this.hasApp===false) {
    //                         this.create_new_app = true; 
    //                       }else {
    //                         this.create_new_app = false;
    //                         if (this.isAppApproved===true) {
    //                           if (this.t_fundingBD > 0 ){  this.create_new_app = true;   }else{ this.disable_app = true;  }
    //                         }else{ this.update_app = true;   }
    //                       } 
    //                   }
    //             }); 

    //           }

    //     }); 

    //   }
    // }); 

    // setTimeout(() => {
    //   (<any>$('.modal')).modal();
    // }, 50);

  }

  getVisibility(item) {
    if (item == 'SocialDevelopment') {
      if (this.SHOW_SOCIAL) return true;
      else return false;
    } else if (item == 'Housing') {
      if (this.SHOW_SOCIAL) return true;
      else return false;
    } else if (item == 'ISET') {
      if (this.SHOW_ISETS) return true;
      else return false;
    } else if (item == 'PostSecondary') {
      if (this.SHOW_PSE) return true;
      else return false;
    } else {
      return true;
    }
  }

  signOut() {
    this.back_flag = false;
    this.authService.signOut();
    this.router.navigate(['/auth/login']);
  }

  backClicked() {
    this.location.back();
  }

  get_app_status() {

    // if (localStorage.getItem('SocialOK') === 'YES') { this.SOIAL_OK = true; } else { this.SOIAL_OK = false; }
    // if (localStorage.getItem('PSEOK') === 'YES') { this.PSE_OK = true; } else { this.PSE_OK = false; }
    // if (localStorage.getItem('ISETSOK') === 'YES') { this.ISETS_OK = true; } else { this.ISETS_OK = false; }

    // if (!this.SHOW_SOCIAL) {  this.SOIAL_OK = false; }
    // if (!this.SHOW_ISETS) {  this.ISETS_OK = false;  }
    // if (!this.SHOW_PSE) {  this.PSE_OK = false; }

    if (this.SHOW_SOCIAL) {
      this.applicationService.getApplications().valueChanges().subscribe(applications => {

        if (applications && applications.length > 0) {
          this.appMenus = applications.map(app => app.menu);
        }

        setTimeout(() => {
          $('select').formSelect();
          M.updateTextFields();
        }, 25);
      });
    }

    if (this.SHOW_ISETS) {
      this.actionPlanService.getIncomingActionPlans(this.applicantID).subscribe(aps => {
        if (aps) {
          this.actionPlans = aps;

          if (this.actionPlans.length != 0) {
            this.hasAP = true;
            this.action_plan_id = this.actionPlans[0].ID;
          } else {
            this.hasAP = false;
          }
        }
      });
    }
  }

  myservicesClicked() {

    this.back_flag = false;
    this.changeFrom('MyServices');
    this.router.navigate(['member/member-main-home/member-home-sub-Menu']);

  }

  changeFrom(from) {
    this.appSettingService.updateFromMenu(from);
  }
}