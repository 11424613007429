import { Component, Input, OnInit } from '@angular/core';
import { MemberService } from 'src/app/services/api/member.service';

@Component({
  selector: 'app-property-details',
  templateUrl: './property-details.component.html',
  styleUrls: ['./property-details.component.css']
})

export class PropertyDetailsComponent implements OnInit {

  @Input() model: any;
  @Input() HoH: any;
  @Input() occupants: any;
  @Input() from: any;
  fromAdmin = false;
  showOccupants = false;
  owner: any;
 
  constructor(private memberService: MemberService,) { }

  ngOnInit() {
    if (this.from) {
      if (this.from == 'A') this.fromAdmin = true;
      else this.fromAdmin = false;
    }
  }

  expandOccupants(){
    this.showOccupants = !this.showOccupants;
  }
}
