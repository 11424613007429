import { Component, OnInit } from '@angular/core';
import { ApplicationService } from 'src/app/services/firebase/application.service';
import { Router } from '@angular/router';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { toast } from 'materialize-css';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-application-list',
  templateUrl: './application-list.component.html',
  styleUrls: ['./application-list.component.css']
})
export class ApplicationListComponent implements OnInit {

  defaultThemeColor = environment.appTheme.themeColor;
  themeColor: any;
  loading = false;
  applications: any[];
  sectionName: string;
  submenus: any[];
  editName = false;

  constructor(private router: Router,
    private applicationService: ApplicationService,
    public appSettingsService: AppSettingsService) {
    this.themeColor = this.appSettingsService.getAppThemeColor() ? this.appSettingsService.getAppThemeColor() : this.defaultThemeColor;
  }

  ngOnInit(): void {
    this.loading = true;
    this.appSettingsService.getMenuName('Manage').valueChanges().subscribe(menus => {
      if (menus && menus.length > 0) {
        this.submenus = menus[0].submenus;
        let menu = this.submenus.find(m => m.name == 'Applications');
        if (menu) this.sectionName = menu.displayName;
      }
    });
    this.applicationService.getApplications().valueChanges().subscribe(applications => {
      if (applications && applications.length > 0) {
        this.applications = applications;
      }
      this.loading = false;
    });
  }

  editNameClicked() {
    this.editName = true;
  }

  saveName() {
    let index = this.submenus.findIndex(m => m.name == 'Applications');
    this.submenus[index].displayName = this.sectionName;
    this.appSettingsService.UpdateMenu('Manage', this.submenus).then((update) => {
      this.editName = false;
      toast({ html: `${update}`, classes: 'green' });
    }).catch((error) => {
      toast({ html: `${error}`, classes: 'red' });
    });
  }


  backToPreviousePage() {
    localStorage.setItem('From', 'Applications');
    this.router.navigate(['admin/dashboard-sub-menu']);
  }

}
