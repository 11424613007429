import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { environment } from 'src/environments/environment';

declare var $: any;

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.css']
})
export class EditorComponent implements OnInit {

  defaultThemeColor = environment.appTheme.themeColor;
  themeColor: any;
  buttonType: any;

  @Input() showChatGpt: boolean = true;
  @Input() control: UntypedFormControl = new UntypedFormControl();
  @Input() type;
  @Input() model;
  @Input() title;
  @Input() styler;
  @Input() editor;
  @Output() onModelChange = new EventEmitter<boolean>();

  openChatGpt = false
  selectedInput: UntypedFormControl;
  inputText;

  public Editor = ClassicEditor;

  aboutChatGpt = "Empower Your Creativity with Aritificail Intellignce Assist: Your Personal Content Assistant.";

  titleEditorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: false,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'book-antiqua', name: 'Book Antiqua' },
      { class: 'bookman', name: 'Bookman' },
      { class: 'georgia', name: 'Georgia' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'hoefler-text', name: 'Hoefler Text' },
      { class: 'lucida-bright', name: 'Lucida Bright' },
      { class: 'courier', name: 'Courier' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' },
      { class: 'candara', name: 'Candara' }
    ],

    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['insertImage', 'subscript',
        'superscript', 'justifyLeft',
        'justifyCenter',
        'justifyRight',
        'justifyFull',
        'indent',
        'outdent',
        'insertUnorderedList',
        'insertOrderedList', 'link',
        'unlink', 'backgroundColor',
        'insertVideo',
        'insertHorizontalRule',],
    ]
  };

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '200',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'book-antiqua', name: 'Book Antiqua' },
      { class: 'bookman', name: 'Bookman' },
      { class: 'georgia', name: 'Georgia' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'hoefler-text', name: 'Hoefler Text' },
      { class: 'lucida-bright', name: 'Lucida Bright' },
      { class: 'courier', name: 'Courier' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' },
      { class: 'candara', name: 'Candara' }
    ],
    uploadWithCredentials: false,
    sanitize: false,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['insertImage', 'subscript', 'insertVideo', 'backgroundColor', 'heading',]
    ]
  }

  stylerEditorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: false,
    showToolbar: true,
    placeholder: 'Enter text here...',
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    fonts: [
      { class: 'arial', name: 'Arial' },
      { class: 'book-antiqua', name: 'Book Antiqua' },
      { class: 'bookman', name: 'Bookman' },
      { class: 'georgia', name: 'Georgia' },
      { class: 'times-new-roman', name: 'Times New Roman' },
      { class: 'hoefler-text', name: 'Hoefler Text' },
      { class: 'lucida-bright', name: 'Lucida Bright' },
      { class: 'courier', name: 'Courier' },
      { class: 'calibri', name: 'Calibri' },
      { class: 'comic-sans-ms', name: 'Comic Sans MS' },
      { class: 'candara', name: 'Candara' }
    ],

    uploadWithCredentials: false,
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      ['insertImage', 'subscript',
        'superscript', 'justifyLeft',
        'justifyCenter',
        'justifyRight',
        'justifyFull',
        'indent',
        'outdent',
        'insertUnorderedList',
        'insertOrderedList', 'link',
        'unlink', 'backgroundColor',
        'insertVideo',
        'insertHorizontalRule'
      ],
      [
        'textColor',
      ]
    ],
  }

  constructor(
    private settingsService: AppSettingsService,
    private el: ElementRef
  ) {
    if (this.styler) {
      this.model = 'Sample Text';
    }
    this.themeColor = this.settingsService.getAppThemeColor() ? this.settingsService.getAppThemeColor() : this.defaultThemeColor;
    this.buttonType = this.settingsService.getButtonType() ? this.settingsService.getButtonType() : 'rectangularButton';
  }

  ngOnInit(): void {
    if (this.styler) {
      this.model = 'Sample Text';
      this.editorConfig = this.stylerEditorConfig;
    }
    else if (this.title) {
      this.editorConfig = this.titleEditorConfig;
    }
    $(document).ready(function () {
      $('.tooltipped').tooltip();
    });
    let self = this
    $(document).ready(function () {
      $('.tooltipped').tooltip({
        html: `<p style="color: white;margin: 0px;">${self.aboutChatGpt}</p>`
      });
    });
  }

  public onReady(editor) {
    editor.ui.getEditableElement().parentElement.insertBefore(
      editor.ui.view.toolbar.element,
      editor.ui.getEditableElement()
    );
  }

  isHovered = false;

  // Event handlers
  onMouseEnter() {
    this.isHovered = true;
  }

  onMouseLeave() {
    this.isHovered = false;
  }

  onChange() {
    this.onModelChange.emit(this.model);
  }

  updateFormValue(event) {
    if (this.type) {
      this.model = event.message;
    } else {
      this.control.patchValue(event.message);
    }
    this.closeModalChatGptModal();
  }

  openChatGptModal() {
    if (this.type) {
      this.selectedInput = this.model;
      this.inputText = this.getInnerText(this.model);
    } else {
      this.selectedInput = this.control;
      this.inputText = this.getInnerText(this.control.value);
    }
    this.openChatGpt = true
  }

  getInnerText(htmlString) {
    return new DOMParser().parseFromString(htmlString, 'text/html').body.innerText
  }

  closeModalChatGptModal() {
    this.openChatGpt = false
  }
}
