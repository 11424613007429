<div class="body-h">
  <div class="card-content col s12">
    <div class="col l12 header-setting">
      <h4 class="icon-text bold-700"> <i class="material-symbols-outlined  p-r-1" (click)="backClicked()"> arrow_back </i>Share News</h4>
    </div>

    <div class="row col s12 StickyContent">
      <div class="col l1"></div>
      <div class="col s12 m12 l10">
        <app-content-editor [contentType]="'News'" [model]="myModel" [actionType]="'Add'"
          (contentSaved)="parentEventHandlerFunction($event)"></app-content-editor>
      </div>
      <div class="col l1"></div>
    </div>
  </div>
</div>