<!-- List -->
<div class="flex justify-center h-screen" *ngIf="loading">
    <progress class="progress progress-customTeal w-full"></progress>
</div>
<div *ngIf="!loading">
    <ng-container *ngIf="userProfile">
        <div class="flex flex-col md:flex-row justify-end gap-2 gap-y-4 pb:10 md:pb-0 ">

            <div class="row justify-end gap-2 gap-y-4 pb:10 md:pb-0">
                <div *ngIf="hasActiveAccounts" class="justify-self-end col s6 offset-s6">
                    <button [routerLink]="['../create-post']" [relativeTo]="activatedRoute"
                        [ngStyle]="{ 'background-color': themeColor }" class="btn add-button right">
                        <i class="material-icons">post_add</i>
                        Create A Post
                    </button>
                </div>
            </div>

            <div *ngIf="!hasActiveAccounts && (isAdmin || (permSocial&& permSocial.linkAccount))"
                class=" justify-self-end">
                <app-link-account [new]="true" [link]="false"></app-link-account>
            </div>
        </div>

        <div *ngIf="selectedAccountPosts && selectedAccountPosts.length > 0 " class="pt-8 md:pt-0">
            <div class="flex pb-10 col-span-12 ">
                <!-- Select media -->
                <div class="space-y-4">
                    <p class="text-sm">Select Media</p>
                    <div *ngIf="availablePlatforms && availablePlatforms.length> 0"
                        class="flex flex-wrap gap-y-3 md:gap-y-6">
                        <!-- button -->
                        <div *ngFor="let v of availablePlatforms">
                            <button (click)="selectPlatform(v)"
                                class="rounded-full flex justify-center items-center mx-3 bg-white w-16 h-16 hover:border-4 hover:border-customTeal shadow-md">
                                <i *ngIf="v !== 'tiktok'"
                                    [ngClass]="'text-xl fa ' + socialMediaIcons[v].text + ' ' + socialMediaIcons[v].color"></i>
                                <svg class="p-0 justify-self-center" *ngIf="v == 'tiktok'"
                                    xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24" height="24"
                                    viewBox="0 0 50 50">
                                    <path
                                        d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z">
                                    </path>
                                </svg>
                            </button>
                        </div>

                        <button (click)="selectedAccountPosts=allAccountsPosts"
                            class="rounded-full flex justify-center items-center mx-3 bg-white w-16 h-16 hover:border-4 hover:border-customTeal shadow-md">
                            All Posts
                        </button>
                    </div>
                </div>
            </div>

            <div class="flex flex-wrap gap-4 w-full">
                <!-- List Item -->
                <div *ngFor="let post of selectedAccountPosts"
                    class="flex flex-col-reverse h-max md:flex-row  bg-white rounded-lg p-6 basis-full md:basis-5/12 md:h-52  md:gap-x-4">
                    <!-- image -->

                    <img *ngIf="post.mediaUrls && post.mediaUrls.length > 0  && imageMedia(post.mediaUrls[0])"
                        [src]="post.mediaUrls[0]"
                        class="object-cover w-4/12 h-24 mt-3 md:mt-0  md:h-40 rounded-md cover" alt="Image">
                    <video controls
                        *ngIf="post.mediaUrls && post.mediaUrls.length > 0 && !imageMedia(post.mediaUrls[0])"
                        [src]="post.mediaUrls[0]"
                        class="object-cover w-4/12 h-24 mt-3 md:mt-0  md:h-40 rounded-md cover" alt="Video"></video>

                    <!-- Detail -->
                    <div class="flex flex-col space-y-2 w-full"
                        [ngClass]="{'md:w-full': !post.mediaUrls || post.mediaUrls.length == 0, 'md:w-8/12': post.mediaUrls && post.mediaUrls.length > 0}">
                        <p class="text-sm w-full h-10  md:h-24 overflow-clip ">{{post.post}} </p>

                        <!-- Medias posted on -->
                        <div class="flex flex-wrap space-x-3 pt-3">
                            <ng-container *ngFor="let v of post.platforms">
                                <i *ngIf="v !== 'tiktok'"
                                    [ngClass]="'text-xl fa ' + socialMediaIcons[v].text + ' ' + socialMediaIcons[v].color"></i>
                                <svg *ngIf="v == 'tiktok'" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="24"
                                    height="24" viewBox="0 0 50 50">
                                    <path
                                        d="M41,4H9C6.243,4,4,6.243,4,9v32c0,2.757,2.243,5,5,5h32c2.757,0,5-2.243,5-5V9C46,6.243,43.757,4,41,4z M37.006,22.323 c-0.227,0.021-0.457,0.035-0.69,0.035c-2.623,0-4.928-1.349-6.269-3.388c0,5.349,0,11.435,0,11.537c0,4.709-3.818,8.527-8.527,8.527 s-8.527-3.818-8.527-8.527s3.818-8.527,8.527-8.527c0.178,0,0.352,0.016,0.527,0.027v4.202c-0.175-0.021-0.347-0.053-0.527-0.053 c-2.404,0-4.352,1.948-4.352,4.352s1.948,4.352,4.352,4.352s4.527-1.894,4.527-4.298c0-0.095,0.042-19.594,0.042-19.594h4.016 c0.378,3.591,3.277,6.425,6.901,6.685V22.323z">
                                    </path>
                                </svg>
                            </ng-container>
                        </div>

                        <!-- analytics and detail -->
                        <div class="flex justify-between pt-6">
                            <!-- view analytics -->
                            <div
                                *ngIf="post.status == 'success'&& hasActiveAccounts &&  getUserMediaPlatforms(post).length > 0">
                                <button class="flex space-x-2 items-center" [routerLink]="['../post-detail',post.id]"
                                    [relativeTo]="activatedRoute">
                                    <i class="fa fa-chart-line "></i>
                                    <span class="font-bold text-sm hover:text-customTeal hover:text-base">View
                                        Analytics</span>
                                </button>
                            </div>
                            <div>
                                <button class="flex space-x-2 items-center " [routerLink]="['../post-detail',post.id]"
                                    [relativeTo]="activatedRoute">
                                    <span
                                        class="font-bold text-sm text-customTeal hover:text-customTeal hover:text-base">Detail</span>
                                    <i class="fa fa-circle-arrow-right text-customTeal"></i>

                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>

        <div *ngIf="!selectedAccountPosts || selectedAccountPosts.length == 0"
            class="mt-5 px-3 py-6 md:px-12 md:py-8 w-full border-2 border-red bg-white rounded-lg">

            <div class="p-b-8">
                <div class="px-5 md:px-0 py-14 flex flex-col w-full jusify-center gap-y-3">
                    <div class="w-full py-4 text-center">
                        <span class="text-lg font-bold pb-3 block">Create Your first post today!</span>
                        Schedule a post for later or post on your social media accounts right
                        now and do it directly from the app!
                    </div>

                    <div class="flex justify-center">
                        <button *ngIf="hasActiveAccounts && isAdmin || (permSocial && permSocial.createSocialPost)"
                            [routerLink]="['../create-post']" [relativeTo]="activatedRoute"
                            class="py-2 px-6 gap-2 bg-customTeal rounded text-white w-50 md:w-60">
                            <i class="material-icons mr-3 float-left">post_add</i>
                            Create A Post
                        </button>

                        <app-link-account [link]="false" [new]="true"
                            *ngIf="!hasActiveAccounts && (isAdmin || (permSocial && permSocial.linkAccount))"></app-link-account>
                    </div>
                </div>
            </div>
        </div>
    </ng-container>
</div>