import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { ContactService } from 'src/app/services/firebase/contact.service';
import { DepartmentNewsService } from 'src/app/services/firebase/department-news.service';
import { DepartmentService } from 'src/app/services/firebase/department.service';
import { toast } from 'materialize-css';
import { environment } from 'src/environments/environment';

declare var $: any;

@Component({
  selector: 'app-departments-stats',
  templateUrl: './departments-stats.component.html',
  styleUrls: ['./departments-stats.component.css']
})

export class DepartmentsStatsComponent implements OnInit {

  themeColor: any;
  defaultThemeColor = environment.appTheme.themeColor;
  AlldepartmentList: any[];
  departmentList: any[];
  subDepartmentList: any[];
  employeesList: any[];
  AlldepartmentNewsList: any[];
  AllMsgList: any[];
  sectionName: string;
  submenus: any[];
  editName = false;

  constructor(
    private departmentService: DepartmentService,
    private location: Location,
    private departmentNewsService: DepartmentNewsService,
    private messagingService: ContactService,
    public appSettingsService: AppSettingsService,
    private router: Router) {
    this.themeColor = this.appSettingsService.getAppThemeColor() ? this.appSettingsService.getAppThemeColor() : this.defaultThemeColor;
  }

  ngOnInit() {
    this.appSettingsService.getMenuName('Stats').valueChanges().subscribe(menus => {
      if (menus && menus.length > 0) {
        this.submenus = menus[0].submenus;
        let menu = this.submenus.find(m => m.name == 'DepartmentStats');
        if (menu) this.sectionName = menu.displayName;
      }
    });

    this.departmentService.getDepartmentAndSubDepList().valueChanges().subscribe(d => {
      if (d) {
        if (d.length > 0) {
          this.AlldepartmentList = d;
          this.departmentList = this.AlldepartmentList.filter(dep => dep.parent_id === '0');
          this.subDepartmentList = this.AlldepartmentList.filter(dep => dep.parent_id !== '0');
        }
      }
    });

    this.departmentService.getAllDepartmentEmployeeList().valueChanges().subscribe(e => {
      if (e) {
        if (e.length > 0) {
          this.employeesList = e;
        }
      }
    });

    this.departmentNewsService.getAllDepNewsList().valueChanges().subscribe(n => {
      if (n) {
        if (n.length > 0) {
          this.AlldepartmentNewsList = n;
        }
      }
    });

    this.messagingService.getAllMessageList().valueChanges().subscribe(m => {
      if (m) {
        if (m.length > 0) {
          this.AllMsgList = m;
        }
      }
    });
  }

  editNameClicked() {
    this.editName = true;
  }

  saveName() {
    let index = this.submenus.findIndex(m => m.name == 'DepartmentStats');
    this.submenus[index].displayName = this.sectionName;
    this.appSettingsService.UpdateMenu('Stats', this.submenus).then((update) => {
      this.editName = false;
      toast({ html: `${update}`, classes: 'green' });
    }).catch((error) => {
      toast({ html: `${error}`, classes: 'red' });
    });
  }

  getNumberOfSubDepartment(depID: any) {
    if (this.employeesList) {
      return this.subDepartmentList.filter(sd => sd.parent_id === depID).length;
    } else { return 0; }
  }

  getNumberOfEmployees(depID: any) {
    if (this.employeesList) {
      return this.employeesList.filter(sd => sd.departmentId === depID).length;
    } else { return 0; }
  }

  getNumberOfManagers(depID: any) {
    if (this.employeesList) {
      return this.employeesList.filter(sd => sd.departmentId === depID && sd.isManager === true).length;
    } else { return 0; }
  }

  getNumberOfDepNews(depID: any) {
    if (this.AlldepartmentNewsList) {
      return this.AlldepartmentNewsList.filter(sd => sd.departmentId === depID).length;
    } else { return 0; }
  }

  getNumberOfMsgReceived(depID: any) {
    if (this.AllMsgList) {
      const departmentMsg = this.AllMsgList.filter(msg => msg.departmentID === depID);
      const departmentEmployee = this.employeesList.filter(e => e.departmentId === depID);
      let count = 0;
      if (departmentMsg.length > 0 && departmentEmployee.length > 0) {
        departmentMsg.forEach(element => {
          departmentEmployee.forEach(emp => {
            if (element.toID === emp.uid) {
              count += 1;
            }
          });
        });
      }
      return count;
    } else { return 0; }
  }

  getNumberOfMsgSent(depID: any) {
    if (this.AllMsgList) {
      const departmentMsg = this.AllMsgList.filter(msg => msg.departmentID === depID);
      const departmentEmployee = this.employeesList.filter(e => e.departmentId === depID);
      let count = 0;
      if (departmentMsg.length > 0 && departmentEmployee.length > 0) {
        departmentMsg.forEach(element => {
          departmentEmployee.forEach(emp => {
            if (element.fromID === emp.uid) {
              count += 1;
            }
          });
        });
      }
      return count;
    } else { return 0; }
  }

  navigate(item) {
    if (item == 'AppStatistics') this.router.navigate(['admin/dashboard-sub-menu/stats/app-statistics']);
    else if (item == 'DepartmentStats') this.router.navigate(['admin/dashboard-sub-menu/stats/dep-statistics']);
    else if (item == 'Demographics') this.router.navigate(['admin/dashboard-sub-menu/stats/demographics']);
    else if (item == 'Charts' || 'Analytics' || 'Reports') {
      $('.modal').modal();
      $('#underConstructionModal').modal('open');
    }
  }

  backClicked() {
    this.location.back();
  }

}
