import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { InterventionsService } from 'src/app/services/api/interventions.service';
import {Location} from '@angular/common';
import { environment } from 'src/environments/environment';
import { InterventionModel } from 'src/app/models/InterventionModel';

declare var $: any;

@Component({
  selector: 'app-intervention-detail',
  templateUrl: './intervention-detail.component.html',
  styleUrls: ['./intervention-detail.component.css']
})
export class InterventionDetailComponent implements OnInit {

  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  int_id: any;
  intervention: InterventionModel;   

  constructor(private interventionService: InterventionsService, 
    private location: Location, 
    private route: ActivatedRoute){
  }

  ngOnInit(): void {
    
    this.route.paramMap.subscribe(params => {
      this.int_id = params.get('id');
    });

    if (this.int_id ) {
      const id = + this.int_id ;
      this.interventionService.getInterventionByID(id).subscribe(x => {  
        this.intervention = x[0];
      });

      setTimeout(() => {
        $('.modal').modal();
      }, 25);
    }
  }

  backClicked() {
    this.location.back();
  }

}
