import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { CommunityService } from 'src/app/services/api/community.service';
import { MemberService } from 'src/app/services/api/member.service';
import { StatsService } from 'src/app/services/api/statsService';
import { ChartDataset, ChartOptions, ChartType } from 'chart.js';
// import { Label, Color } from 'ng2-charts';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-member-demographics',
  templateUrl: './member-demographics.component.html',
  styleUrls: ['./member-demographics.component.css']
})
export class MemberDemographicsComponent implements OnInit {

  public yearMask = [/[1-2]/, /[0-9]/, /[0-9]/, /[0-9]/];
  educationlevels = [
    { level: 'Preschool', value: 0 },
    { level: 'Up to Grade 7-8 (Sec. I-Grade 8)', value: 1 },
    { level: 'Grade 9-10 (Sec. II-III)', value: 2 },
    { level: 'Grade 11-12 (Sec. IV-V)', value: 3 },
    { level: 'Secondary School Diploma or GED', value: 4 },
    { level: 'Some post-secondary training', value: 5 },
    { level: 'Apprenticeship or trades certificate or diploma', value: 6 },
    { level: 'College', value: 7 },
    { level: 'CEGEP', value: 8 },
    { level: 'University certificate or diploma', value: 9 },
    { level: 'University - Bachelor Degree', value: 10 },
    { level: 'University – Master’s degree', value: 11 },
    { level: 'University – Doctorate', value: 12 },
    { level: 'Course or certificate', value: 13 },
    { level: 'Other', value: 14 }
  ];
  genders = ['Male', 'Female'];
  communities: any[];
  filterForm: UntypedFormGroup;
  nationalResidency = false;
  urbanResidency = false;
  showCommunity = false;
  member = false;
  notMember = false;
  isDeceased = false;
  isBirthed = false;

  userModel: any;
  birthsModel: any[];
  gendersModel: any[];

  // Linear Chart
  public lineChartData: any;
  public lineChartLabels: any;
  public lineChartOptions: ChartOptions = {
    responsive: true,
    // tooltips: {
    //   callbacks: {
    //     label: function (tooltipItem, data) {
    //       var label = ' Births +';

    //       if (label) {
    //         label += ' ';
    //       }
    //       const currentYear = +data.datasets[0].data[tooltipItem.index];
    //       const previousYear = +data.datasets[0].data[tooltipItem.index - 1];

    //       label += currentYear - previousYear;
    //       return label;
    //     }
    //   }
    // }
    plugins: {
      tooltip: {
        callbacks: {
          label: function (tooltipItem) {
            var label = ' Births +';
            if (label) {
              label += ' ';
            }
            const currentYear = +tooltipItem.raw;
            const previousYear = +tooltipItem.dataset.data[tooltipItem.dataIndex - 1] as number;
            label += currentYear - previousYear;
            return label;
          }
        }
      },
      legend: {
        position: 'top',
      }
    }
  };
  public lineChartColors = [
    {
      borderColor: 'darkgreen',
      backgroundColor: 'rgb(152,251,152)',
    },
  ];
  public lineChartLegend = true;
  public lineChartType = 'line';
  public lineChartPlugins = [];

  // Pie Chart
  public pieChartOptions: ChartOptions = {
    responsive: true,
    // legend: {
    //   position: 'top',
    // },
    plugins: {
      legend: {
        position: 'top',
      }
    }
  };
  public pieChartLabels: any;
  public pieChartData: any[];
  public pieChartType: ChartType = 'pie';
  public pieChartLegend = true;
  public pieChartColors = [
    {
      backgroundColor: ['rgb(0,191,255)', 'rgb(255,105,180)'],
    },
  ];

  constructor(private fb: UntypedFormBuilder,
    private communityService: CommunityService,
    private memberService: MemberService,
    private statsService: StatsService) { }

  ngOnInit(): void {
    this.statsService.GetDemographics().subscribe(data => {
      if (data) {
        this.birthsModel = data.births;
        this.gendersModel = data.genders;

        let chartData: ChartDataset[];
        // let years: Label[] = [];
        let years: string[] = [];
        let count: any[] = [];
        let total: any;
        total = this.birthsModel[0].initial;
        this.birthsModel.forEach(e => {
          years.push(e.yr);
          total += e.cnt;
          count.push(total);
        });
        // chartData = [{ data: count, label: 'Births By Year' }];
        this.lineChartData = {
          labels: years,
          datasets: [{ data: count, label: 'Births By Year' }]
        };
        this.lineChartLabels = years;
        this.lineChartData = chartData;

        // Pie Chart
        let gendersVal: number[] = [];
        // let lbl: Label[] = ['Male ' + ' ' + this.gendersModel[0].Male, 'Female ' + ' ' + this.gendersModel[0].Female]
        let lbl: string[] = ['Male ' + ' ' + this.gendersModel[0].Male, 'Female ' + ' ' + this.gendersModel[0].Female];
        gendersVal.push(this.gendersModel[0].Female);
        gendersVal.push(this.gendersModel[0].Male);
        this.pieChartLabels = lbl;
        this.pieChartData = gendersVal;

        setTimeout(() => {
          $('.collapsible').collapsible();
          $('.carousel').carousel();
        }, 25);
      }
    });

    this.memberService.getDemographicsData().subscribe(data => {
      if (data) {
        this.userModel = data.users[0];
      }
    });

    this.communityService.getCommunity().subscribe(com => {
      if (com.length > 0) {
        this.communities = com;
        setTimeout(() => {
          $('select').formSelect();
          
        }, 25);
      }
    });

    this.filterForm = this.fb.group({
      firstName: [''],
      lastName: [''],
      community: [],
      gender: ['Show All'],
      onReserve: [''],
      isMember: [null],
      eduLevel: [''],
      birthed_between: this.fb.group({
        from: [''],
        to: ['']
      }),
      deceased: [''],
      deceased_between: this.fb.group({
        from: [''],
        to: ['']
      }),
    });
  }

  get firstName() { return this.filterForm.get('firstName'); }
  get lastName() { return this.filterForm.get('lastName'); }
  get dob() { return this.filterForm.get('dob'); }
  get community() { return this.filterForm.get('community'); }
  get gender() { return this.filterForm.get('gender'); }
  get startAge() { return this.filterForm.get('ageRange.startAge'); }
  get endAge() { return this.filterForm.get('ageRange.endAge'); }
  get onReserve() { return this.filterForm.get('onReserve'); }
  get isMember() { return this.filterForm.get('isMember'); }
  get eduLevel() { return this.filterForm.get('eduLevel'); }

  onReserveYes() {
    this.nationalResidency = !this.nationalResidency;
    if (this.nationalResidency) this.showCommunity = true;
    else {
      this.showCommunity = false;
      this.onReserve.patchValue('');
      this.filterForm.get('community').patchValue([]);
    }
    setTimeout(() => {
      $('select').formSelect();
      $('.tooltipped').tooltip();
    }, 25);
  }

  onReserveNo() {
    this.urbanResidency = !this.urbanResidency;
    this.showCommunity = false;
    this.filterForm.get('community').patchValue([]);
    if (!this.urbanResidency) this.onReserve.patchValue('');
  }

  onMemberYes() {
    this.member = !this.member;
    if (!this.member) this.isMember.patchValue('');
  }

  onMemberNo() {
    this.notMember = !this.notMember;
    if (!this.notMember) this.isMember.patchValue('');
  }

  deceasedYes() {
    this.isDeceased = !this.isDeceased;
    setTimeout(() => {
      M.updateTextFields();
    }, 25);
  }

  birthedYes() {
    this.isBirthed = !this.isBirthed;
    setTimeout(() => {
      M.updateTextFields();
    }, 25);
  }

  getResult(values) {
    const model = {
      gender: values.gender ? values.gender : '',
      community: values.community ? values.community : '',
      onReserve: values.onReserve ? values.onReserve : '',
      isMember: values.isMember ? values.isMember : '',
      eduLevel: values.eduLevel ? values.eduLevel : '',
      birthStartDate: values.birthed_between.from,
      birthEndDate: values.birthed_between.to
    }
  
    // this.memberService.filterMembers(values).subscribe(result => {
    // });
  }

}
