<div class="row ">
    <div class="col l1" *ngIf="user">
        <app-side-menus [user]="user"></app-side-menus>
    </div>

    <div class="col l11 s12 white container-setting">
        <div class="row">
            <div class="col s12">
                <ul class="tabs">
                    <li class="tab col s4"><a class="active" href="#public">Public Section</a></li>
                    <li class="tab col s4"><a href="#admin">Admin Section</a></li>
                    <li class="tab col s4"><a href="#member">Member Section</a></li>
                </ul>
            </div>

            <!-- ==================== public Section =================  -->
            <table id="public" class="col s12">
                <tbody class="row card-section ">
                    <tr class="col l12 s12" *ngFor="let section of pageSections.sections">
                        <div class="" [ngStyle]="{'background-color': 'white'}"
                            [attr.href]="'#modal' + section.icon">
                            <td class="col s1">
                                <i class="material-symbols-outlined">{{section.icon}}</i>
                            </td>
                            <td class="col s3">
                                <h4 class="">{{section.displayName}}</h4>
                            </td>
                            <td class="col s8">
                                <input id="" type="text" autocomplete="off" class="validate"
                                    [(ngModel)]="section.displayName" />
                            </td>
                        </div>
                    </tr>
                </tbody>
            </table>

            <!-- ==================== admin Section =================  -->
            <table id="admin" class="col s12">
                <tbody class="row">
                    <tr class="col l12 s12" *ngFor="let item of adminMenus; let i=index">
                        <div class="  card-section " [ngStyle]="{'background-color': 'white'}"
                            [attr.href]="'#modal' + item.icon">
                            <td class="col s1">
                                <i class="material-symbols-outlined ">{{item.icon}}</i>
                            </td>
                            <td class="col s3">
                                <h4>{{item.displayName}}</h4>
                            </td>
                            <td class="col s8">
                                <input id="" type="text" autocomplete="off" class="validate"
                                    [(ngModel)]="item.displayName" />
                            </td>
                        </div>
                    </tr>
                </tbody>
            </table>

            <!-- ==================== member Section =================  -->
            <table id="member" class="col s12">
                <tbody class="row ">
                    <tr class="col l12 s12" *ngFor="let item of menus; let i=index">
                        <div class="   card-section" [ngStyle]="{'background-color': 'white'}"
                            [attr.href]="'#modal' + item.icon">
                            <td class="col s1">
                                <i class="material-symbols-outlined">{{item.icon}}</i>
                            </td>
                            <td class="col s3">
                                <h4>{{item.displayName}}</h4>
                            </td>
                            <td class="col s8">
                                <input id="" type="text" autocomplete="off" class="validate"
                                    [(ngModel)]="item.displayName" />
                            </td>
                        </div>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
</div>

<!-- ========== public ================ -->
<div *ngFor="let section of pageSections.sections">
    <div [id]="'modal' + section.index" class="modal bottom-sheet">
        <div class="modal-header">
            <a class="modal-close waves-effect waves-green btn right">Close</a>
        </div>
        <div class="modal-content">
            <h1>{{ section.displayName }}</h1>
            <div class="main-dash">
                <div class="row position-add-button">
                    <!-- Buttons for all sections -->
                    <div class="col s3">
                        <button class="btn add-button" data-show=".quickGuide">Quick Guide</button>
                    </div>
                    <div class="col s1">
                        <i class="material-symbols-outlined">arrow_forward_ios</i>
                    </div>
                    <div class="col s3">
                        <button class="btn add-button" data-show=".renameSec">Rename Section</button>
                    </div>
                    <div class="col s1">
                        <i class="material-symbols-outlined">arrow_forward_ios</i>
                    </div>
                    <div class="col s2">
                        <button class="btn add-button" data-show=".createNew">Create New</button>
                    </div>
                </div>

                <div class="col s12">
                    <div class="inner-basic division-details">
                        <!-- Content for all sections -->
                        <div class="quickGuide initialmsg">Quick Guide</div>
                        <div class="renameSec hide">Rename Section</div>
                        <div class="createNew hide">Create New</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- ============ admin =============== -->
<div *ngFor="let item of adminMenus">
    <div [id]="'modal' + item.icon" class="modal bottom-sheet">
        <div class="modal-header">
            <a class="modal-close waves-effect waves-green btn right">Close</a>
        </div>
        <div class="modal-content">
            <h1>{{ item.displayName }}</h1>
            <div class="main-dash">
                <div class="row position-add-button">
                    <!-- Buttons for all sections -->
                    <div class="col s3">
                        <button class="btn add-button" data-show=".quickGuide">Quick Guide</button>
                    </div>
                    <div class="col s1">
                        <i class="material-symbols-outlined">arrow_forward_ios</i>
                    </div>
                    <div class="col s3">
                        <button class="btn add-button" data-show=".renameSec">Rename Section</button>
                    </div>
                    <div class="col s1">
                        <i class="material-symbols-outlined">arrow_forward_ios</i>
                    </div>
                    <div class="col s2">
                        <button class="btn add-button" data-show=".createNew">Create New</button>
                    </div>
                </div>

                <div class="col s12">
                    <div class="inner-basic division-details">
                        <!-- Content for all sections -->
                        <div class="quickGuide initialmsg">Quick Guide</div>
                        <div class="renameSec hide">Rename Section</div>
                        <div class="createNew hide">Create New</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- ================ member ================ -->
<div *ngFor="let item of menus">
    <div [id]="'modal' + item.icon" class="modal bottom-sheet">
        <div class="modal-header">
            <a class="modal-close waves-effect waves-green btn right">Close</a>
        </div>
        <div class="modal-content">
            <h1>{{ item.displayName }}</h1>
            <div class="main-dash">
                <div class="row position-add-button">
                    <!-- Buttons for all sections -->
                    <div class="col s3">
                        <button class="btn add-button" data-show=".quickGuide">Quick Guide</button>
                    </div>
                    <div class="col s1">
                        <i class="material-symbols-outlined">arrow_forward_ios</i>
                    </div>
                    <div class="col s3">
                        <button class="btn add-button" data-show=".renameSec">Rename Section</button>
                    </div>
                    <div class="col s1">
                        <i class="material-symbols-outlined">arrow_forward_ios</i>
                    </div>
                    <div class="col s2">
                        <button class="btn add-button" data-show=".createNew">Create New</button>
                    </div>
                </div>

                <div class="col s12">
                    <div class="inner-basic division-details">
                        <!-- Content for all sections -->
                        <div class="quickGuide initialmsg">Quick Guide</div>
                        <div class="renameSec hide">Rename Section</div>
                        <div class="createNew hide">Create New</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>