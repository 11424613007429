<div class="col s12">
  <div class="body-h">
    <div class="header-setting b-m-2">
      <div class="col l9 m9 s12">

        <h4 *ngIf="!editName">
          <span *ngIf="sectionName">{{sectionName}}</span>
          <span *ngIf="!sectionName">About Us</span>
          <i class="material-icons edit-name" [ngStyle]="{'color': themeColor}" (click)="editNameClicked()">edit</i>
        </h4>

        <div class="input-field" *ngIf="editName">
          <input type="text" [(ngModel)]="sectionName" class="left col s6 validate" autocomplete="off" />
          <button type="button" class="btn ll-m-5" [ngStyle]="{'background-color': themeColor}"
            (click)="saveName()">Save</button>
        </div>

        <p class="col s12 bold-300 sub-title-section3">
          This is where you can make edits to the company's 'About Us' information that will be viewable by both the
          public and members exclusively.
        </p>
      </div>

      <div class="col l3 m4 s12 p-m-3">
        <a [routerLink]="['/admin/add-about-section']" class="add-button btn white center-align p-l-3"
          [ngStyle]="{'color': themeColor}"><i class="material-icons-outlined">add</i> Add&nbsp;
          <span *ngIf="sectionName">{{sectionName}}</span>
          <span *ngIf="!sectionName">About Us</span></a>
      </div>
    </div>

    <main class="StickyContent row" style="padding-top:0%;">
      <div class="col s12 l10" *ngIf="model" cdkDropList (cdkDropListDropped)="drop($event)">
        <div cdkDrag *ngFor="let sec of model | paginate: { itemsPerPage: 5, currentPage: p } ">
          <div class="row card news-card hoverable" [ngStyle]="{'background-color': sec.backgroundColor}"
            [routerLink]="['/admin/edit-about-section' , sec.id]" style="cursor: pointer;">

            <span class="material-symbols-outlined drag-handle right" cdkDragHandle
              style="color:gray;  font-size: 20px;">
              open_with
            </span>
            <div class="col s12 card-content">

              <div class="col s12"
                [ngClass]="{'col l9 m8 s12': (sec.type == 'PDF' || sec.type == 'Video' || sec.type == 'uploadVideo' || sec.type == 'Photo') && (sec.imgLink || sec.videoLink)}">
                <p class="date-content sub-title-section3 p-b-1">
                  {{sec.author}}
                  <span class="right" *ngIf="sec.visibility">
                    <p *ngIf="sec.visibility === 'Public'" class="green-text"><strong>Public</strong> </p>
                    <p *ngIf="sec.visibility === 'Private'" class="red-text"><strong>Members Only</strong> </p>
                  </span>
                </p>

                <p class="news-title">
                  <a [routerLink]="['/admin/edit-about-section' , sec.id]" class="" [innerHTML]="sec.title">
                  </a>
                </p>

                <p *ngIf="sec.imgLink" class="middle-body-snippet sub-title-section3 p-t-1 bold-100"
                  style="text-align: justify;">
                  {{truncateHTML(sec.body,300)}} </p>
                <p *ngIf="!sec.imgLink" class="long-body-snippet sub-title-section3 p-t-1 bold-100"
                  style="text-align: justify;">
                  {{truncateHTML(sec.body,300)}} </p>

                <p class="col l4 s12 date-content sub-title-section3 p-t-2">{{sec.datePublished.toDate() | date: 'MMM dd,yyyy'}}</p>

              </div>

              <div class="col l3 m4 hide-on-small-only"
                *ngIf="(sec.type == 'PDF' || sec.type == 'Video' || sec.type == 'uploadVideo' || sec.type == 'Photo') && (sec.imgLink || sec.videoLink)">

                <!-- <div class="card-image" *ngIf="newsModel.videoLink">
                  <img [src]="newsModel.videoLink" class="news-img">
                </div> -->

                <div class="card-image" *ngIf="sec.type === 'Photo' && sec.imgLink">
                  <img [src]="sec.imgLink" class="news-img">
                </div>

                <div class="col l12 s12" *ngIf="sec.type === 'PDF' && sec.videoLink">
                  <iframe title="iframe-box2" class="responsive-pdf" [src]="sec.videoLink | safeUrl"></iframe>
                </div>

                <div class="card-image" *ngIf="sec.type === 'Video' && sec.videoLink">
                  <div class="video-container ">
                    <youtube-player [videoId]="embedVideo(sec.videoLink)"></youtube-player>
                    <!-- <div [innerHTML]="embedVideo(sec.videoLink)"></div> -->
                  </div>
                </div>

                <div class="card-image" *ngIf="sec.type === 'uploadVideo' && sec.videoLink">
                  <video class="responsive-video " controls>
                    <source [src]="sec.videoLink" type="video/mp4">
                  </video>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row center">
          <pagination-controls (pageChange)="p = $event"></pagination-controls>
        </div>
      </div>
      <router-outlet></router-outlet>
    </main>
  </div>
</div>