

import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
     name: 'FrequencyName'
})
export class FrequencyNamePipe implements PipeTransform {

   transform(value: number): any {

        let frequency: string ='';
    
        switch (value) {
            case 1:
                frequency= "One Time";
                break;
            case 2:
                frequency="Daily";
                break;
            case 3:
                frequency="Mileage";
                break;
            case 4:
                frequency="Monthly";
                break;
            default:
                frequency="";
                break;
        }


      return frequency;


   }
}

