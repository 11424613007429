import { Component, OnInit } from '@angular/core';
import { Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { Location } from '@angular/common';
import { ObjectiveService } from 'src/app/services/api/objective.service';
import { ObjectiveModel } from 'src/app/models/ObjectiveModel';
import { toast } from 'materialize-css';

@Component({
  selector: 'app-add-objective',
  templateUrl: './add-objective.component.html',
  styleUrls: ['./add-objective.component.css']
})

export class AddObjectiveComponent implements OnInit {

  clientID = 0;
  addObjectiveForm: UntypedFormGroup;
  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  model: ObjectiveModel;

  constructor(private authService: AuthService, private fb: UntypedFormBuilder, private location: Location, private objectiveService: ObjectiveService) { }

  ngOnInit(): void {

    if (localStorage.getItem('applicantID')) {
      this.clientID = Number(localStorage.getItem('applicantID'));
    } else {
      this.clientID = this.authService.getCurrentApplicantID();
    }

    this.addObjectiveForm = this.fb.group({
      Objective: ['', [Validators.required]],
    });

    this.model = new ObjectiveModel();
    this.model.client_id = this.clientID;
  }

  get Objective() { return this.addObjectiveForm.get('Objective'); }


  submitForm(value: any) {
    if (this.addObjectiveForm.valid) {
      this.model.Objective = value.Objective;
      this.objectiveService.saveObjective(this.model).subscribe(x => {
        if (x) {
          toast({ html: 'Objective Successfully Added!', classes: 'green' });
          this.location.back();
        }
      });
    }
  }

  backClicked() {
    this.location.back();
  }
}