<div class="row center-align">
  <h5><strong> Department Information </strong></h5>
</div>

<div class="row col s12">
  <div class="col l1"></div>
  <div class="col s12 m12 l10">
    <form [formGroup]="departmentForm" (ngSubmit)="PostDepartment(departmentForm)">
      <div class="row card-panel white darken-2">
        <div class="row">
          <div class="input-field col s12">
            <i class="material-icons prefix green-text">text_rotation_none</i>
            <input id="name" type="text" formControlName='name' required [ngClass]="'validate ' + selectedColor"
              autocomplete="off" />
            <label for="name">Department Name</label>
            <div *ngIf="(name.dirty || name.touched) && name.invalid">
              <small *ngIf="name.errors.required" class="red-text"> The Department Name is required. </small>
            </div>
          </div>

          <div class="row col s12 " *ngIf="isManager || canUpdateDepInfo">
            <a href="" class="dropdown-trigger col s12 btn grey lighten-3 black-text" data-target='options1'
              style="margin-bottom: 15px;">
              <strong><i class="material-icons left pink-text">color_lens</i> Select Color</strong> </a>
            <ul id='options1' class='dropdown-content'>
              <li><a>Please Select a Color</a></li>
              <li *ngFor="let c of colorPicker" (click)="ChangeColor(c.value)"><a><i
                    class="material-icons {{c.value}}">fiber_manual_record</i>{{c.name}}</a></li>
            </ul>
          </div>

          <div class="input-field col s12">
            <i class="material-icons prefix green-text text-darken-2">text_rotation_none</i>
            <input id="shortName" type="text" formControlName='shortName' class="validate" autocomplete="off" />
            <label for="shortName">Short Name</label>
          </div>

          <div class="input-field col s12">
            <i class="material-icons prefix purple-text">phone</i>
            <!-- <input id="phone" type="text" formControlName='phone' class="validate" autocomplete="off"
              [textMask]="{mask: phoneNumberMask}" /> -->
              <input id="phone" type="text" formControlName='phone' class="validate" autocomplete="off"
               />
            <label for="phone">Phone Number</label>
          </div>

          <div class="input-field col s12">
            <i class="material-icons prefix orange-text">phone</i>
            <!-- <input id="fax" type="text" formControlName='fax' class="validate" autocomplete="off"
              [textMask]="{mask: phoneNumberMask}" /> -->
              <input id="fax" type="text" formControlName='fax' class="validate" autocomplete="off"
              />
            <label for="fax">Fax Number</label>
          </div>

          <div class="input-field col s12">
            <i class="material-icons prefix blue-text">place</i>
            <input id="address" type="text" formControlName='address' class="validate" autocomplete="off"
              maxlength="150" />
            <label for="address">Address</label>
          </div>

          <div class="input-field col s12">
            <i class="material-icons prefix teal-text">local_post_office</i>
            <!-- <input id="postal_code" type="text" formControlName='postal_code' class="validate postalcode"
              autocomplete="off" [textMask]="{mask: postalCodeMask}" /> -->
              <input id="postal_code" type="text" formControlName='postal_code' class="validate postalcode"
              autocomplete="off" />
            <label for="postal_code">Postal Code</label>
          </div>

          <div class="input-field col s12">
            <i class="material-icons prefix pink-text">subject</i>
            <textarea id="mandate" type="text" formControlName='mandate' class="materialize-textarea validate"
              autocomplete="off" maxlength="1000"></textarea>
            <label for="mandate">Mandate</label>
          </div>
        </div>
      </div>

      <div class="section"></div>
      <div *ngIf="isManager || canUpdateDepInfo">
        <button type="submit" [disabled]="!departmentForm.valid" class="col s12 btn waves-effect green"
          style="margin-bottom: 15px;">
          <i class="material-icons right">cloud_upload</i> Save Changes
        </button>
      </div>

      <a [ngClass]="'col s12 btn waves-effect ' + themeColor" (click)="backClicked();"><i
          class="material-icons left">house</i> Back To Department</a>
    </form>
  </div>
  <div class="col l1 "></div>
</div>