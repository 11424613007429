import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChange } from '@angular/core';
// import { EmbedVideoService } from 'ngx-embed-video';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { StaticContentService } from 'src/app/services/firebase/static-content.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-latest-news',
  templateUrl: './latest-news.component.html',
  styleUrls: ['./latest-news.component.css']
})
export class LatestNewsComponent implements OnInit {

  defaultThemeColor = environment.appTheme.themeColor;
  model: any;
  themeColor: any;
  newsSection: any;
  news: any[];

  bodyBgColor:any;

  @Input() cardView: any;
  videoId: any;

  constructor(
    private appSettingService: AppSettingsService,
    private newsService: StaticContentService,
    // private embedService: EmbedVideoService
  ) {
    this.themeColor = this.appSettingService.getAppThemeColor() ? this.appSettingService.getAppThemeColor() : this.defaultThemeColor;
    this.appSettingService.getAppSetting().valueChanges().subscribe(
      appSettings => {
        if (appSettings) {
          if (appSettings.length > 0) {
            this.model = appSettings[0];
            this.themeColor = this.model.themeColor ? this.model.themeColor : this.defaultThemeColor;
          }
        }
      });
  }

  ngOnInit(): void {
    this.appSettingService.getHomePageSettings().valueChanges().subscribe(hp => {
      if (hp) {
        if (hp.length > 0) {
          this.model = hp[0];
          const newsSection = this.model.sections.filter(s => s.name === 'News' && s.isEnabled);
          if (newsSection) {
            this.newsSection = newsSection[0];

            this.newsService.getSpecificContentList('Public', true).valueChanges().subscribe(news => {
              if (news && news.length > 0) {
                this.news = news;
                this.news.sort((a, b) => b.datePublished - a.datePublished);
                if (news && news.length >= this.newsSection.displayLimit) this.news = this.news.slice(0, this.newsSection.displayLimit);
                this.news.forEach(element => {
                  if (element.type === 'Video') {
                    element.iframe = this.embedVideo(element.videoLink);
                  }
                });
              }
            });
          }
        }
      }
    });
  }

  embedVideo(videoLink) {
    const videoIdMatch = videoLink.match(/(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([^&]+)|(?:https?:\/\/)?(?:www\.)?youtu\.be\/([^?]+)/);
    this.videoId = videoIdMatch ? (videoIdMatch[1] || videoIdMatch[2]) : '';
    return this.videoId;
  }

  getHowLongAgo(postDate) {
    const datePipe = new DatePipe('en-US');
    let today = new Date();
    let difference = datePipe.transform(today.getTime() - postDate.getTime(), 'dd');
    return parseInt(difference);
  }

  truncateHTML(text: string, limit: string): string {
    const charlimit = +limit;
    if (!text || text.length <= charlimit) {
      return text.replace(/<(?:.|\n)*?>/gm, '').replace(/&nbsp;/gm, ' ');
    }
    return text.replace(/<(?:.|\n)*?>/gm, '').replace(/&nbsp;/gm, ' ').substring(0, charlimit) + '...';
  }

  ngOnChanges(changes: SimpleChange) {
    let change: SimpleChange = changes['cardView'];
    if (this.newsSection) this.newsSection.cardView = change.currentValue;
  }

  navigate() {
    this.appSettingService.setCurrentMenu(this.newsSection.displayName);
  }
}