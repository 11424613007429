<!-- <div class="row col s12" *ngIf="false">
  
  <div class="col m1 l2"></div>
  <div class="col s12 m10 l8">


    <h5 style="font-weight: bolder; font-family: 'Anton', sans-serif;" class="hide-on-small-only">All Profile</h5>
    <h5 style="font-weight: bolder; font-family: 'Anton', sans-serif;" class="center hide-on-med-and-up">All Profile
    </h5>
    <hr />
  
    <div *ngIf="selectedOption === 'Chief' && model.chiefs[0]">
      <div class="row">
        <div class="col s12 m6 l6 xl4">
          <div class="card hoverable" style="border-radius: 0;">
            <br />
            <div class="card-image center">
              <img *ngIf="model.chiefs[0].profile_pic" class="responsive-img"
                style="width: 120px; height: 120px; border-radius: 50%; margin-left: auto; margin-right: auto; display: block; "
                [src]="model.chiefs[0].profile_pic">
              <img *ngIf="!model.chiefs[0].profile_pic" class="responsive-img"
                style="width: 120px; height: 120px; border-radius: 50%; margin-left: auto; margin-right: auto; display: block; "
                src="https://www.shareicon.net/data/132x132/2016/09/15/829452_user_512x512.png">

            </div>

            <div class="card-content center">
              <span class="card-title" style="font-weight: bolder; font-family: 'Anton', sans-serif;">
                <strong>{{(model.chiefs[0].displayName) ? model.chiefs[0].displayName : model.chiefs[0].firstName + '
                  ' + model.chiefs[0].lastName}}</strong>
              </span>

              <h6>{{model.chiefs[0].email}}</h6>

              <br />
              <a [routerLink]="['/governance-chief']" class="waves-effect btn-flat btn-viewprofile col s12">View
                Profile</a>
            </div>
            <div class="section"></div>
            <div class="card-action center-align">

              <a href="{{model.chiefs[0].chiefFacebookAcc}}" target="_blank"><span
                  *ngIf="(model.chiefs[0].showFacebookAcc === 'Public') && model.chiefs[0].chiefFacebookAcc"
                  style="cursor: pointer;"> <i class="fa fa-facebook prefix "
                    style="color: #3b5998; font-size:larger;"></i></span> </a>

              <a href="{{model.chiefs[0].chiefInstagramAcc}}" target="_blank"><span
                  *ngIf="(model.chiefs[0].showInstagramAcc === 'Public' ) && model.chiefs[0].chiefInstagramAcc"
                  style="cursor: pointer;"> <i class="fa fa-instagram prefix "
                    style="color: #8a3ab9; font-size:larger;"></i> </span></a>

              <a href="{{model.chiefs[0].chiefTwitterAcc}}" target="_blank"><span
                  *ngIf="(model.chiefs[0].showTwitterAcc === 'Public' ) && model.chiefs[0].chiefTwitterAcc"
                  style="cursor: pointer;"> <i class="fa fa-twitter prefix"
                    style="color: #1DA1F2; font-size:larger;"></i></span> </a>

              <a href="{{model.chiefs[0].chiefLinkedinAcc}}" target="_blank"><span
                  *ngIf="(model.chiefs[0].showLinkedinAcc === 'Public') && model.chiefs[0].chiefLinkedinAcc"
                  style="cursor: pointer;"> <i class="fa fa-linkedin prefix "
                    style="color: #0077b5; font-size:larger;"></i></span> </a>

              <a href="{{model.chiefs[0].chiefYoutubeAcc}}" target="_blank"><span
                  *ngIf="(model.chiefs[0].showYoutubeAcc === 'Public') && model.chiefs[0].chiefYoutubeAcc"
                  style="cursor: pointer;"> <i class="fa fa-youtube prefix red-text"
                    style="font-size:larger;"></i></span> </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>



  <div *ngIf="selectedOption === 'Councillors' && model.councillors">
    <div class="row">
      <div *ngFor="let item of model.councillors" class="col s12 m6 l6 xl4">
        <div class="card hoverable" style="border-radius: 0;">
          <br />
          <div class="row col s12 card-image center">
            <img *ngIf="item.profile_pic" class="responsive-img"
              style="width: 120px; height: 120px; border-radius: 50%; margin-left: auto; margin-right: auto; display: block; "
              [src]="item.profile_pic">

            <img *ngIf="!item.profile_pic" class="responsive-img"
              style="width: 120px; height: 120px; border-radius: 50%; margin-left: auto; margin-right: auto; display: block; "
              src="https://www.shareicon.net/data/132x132/2016/09/15/829452_user_512x512.png">


          </div>

          <div class="card-content center">
            <span class="card-title" style="font-weight: bolder; font-family: 'Anton', sans-serif;">
              <strong>{{(item.displayName) ? item.displayName : item.firstName + ' ' + item.lastName}}</strong>
            </span>

            <h6>{{item.email}}</h6>
            <br />
            <a [routerLink]="['/governance-detail',item.applicantID]"
              class="waves-effect waves-teal btn-flat btn-viewprofile col s12">View Profile</a>
          </div>
          <div class="section"></div>
          <div class="card-action center-align">
  
            <a href="{{item.councillorFacebookAcc}}" target="_blank"
              *ngIf="(item.showFacebookAcc === 'Public') && item.councillorFacebookAcc"><span style="cursor: pointer;">
                <i class="fa fa-facebook prefix " style="color: #3b5998; font-size:larger;"></i></span> </a>
            <a href="{{item.councillorInstagramAcc}}" target="_blank"
              *ngIf="(item.showInstagramAcc === 'Public' ) && item.councillorInstagramAcc"><span
                style="cursor: pointer;"> <i class="fa fa-instagram prefix "
                  style="color: #8a3ab9; font-size:larger;"></i> </span></a>
            <a href="{{item.councillorTwitterAcc}}" target="_blank"
              *ngIf="(item.showTwitterAcc === 'Public' ) && item.councillorTwitterAcc"><span style="cursor: pointer;">
                <i class="fa fa-twitter prefix" style="color: #1DA1F2; font-size:larger;"></i></span> </a>
            <a href="{{item.councillorLinkedinAcc}}" target="_blank"
              *ngIf="(item.showLinkedinAcc === 'Public') && item.councillorLinkedinAcc"><span style="cursor: pointer;">
                <i class="fa fa-linkedin prefix " style="color: #0077b5; font-size:larger;"></i></span> </a>
            <a href="{{item.councillorYoutubeAcc}}" target="_blank"
              *ngIf="(item.showYoutubeAcc === 'Public') && item.councillorYoutubeAcc"><span style="cursor: pointer;"> <i
                  class="fa fa-youtube prefix red-text" style="font-size:larger;"></i></span> </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  
</div> -->


<div class="row">
  <app-governance-list [from]="'governance'"></app-governance-list>
</div>



