<div class="row" *ngIf="member">
  <h5 class="center p-m-2 b-m-2">Welcome, <span [ngClass]="fontColor"> {{ member.GivenName }} </span></h5>
  <div class="col m1 l2 "></div>

  <div class="col s12 m10 l8 ">
    <div class="card">
      <div class="card-content">
        <span class="card-title center" *ngIf="step1">
          <h6 class="red-text"><strong> This is a secure site. Please help us verify your identity by answering the
              questions below. </strong></h6>
        </span>

        <div class="section"></div>
        <div *ngIf="step1">
          <form [formGroup]="verificationForm">

            <div class="row" *ngIf="member?.PWA_Verify_SIN">
              <div class="input-field col s12 l12">
                <i class="material-icons prefix">lock</i>
                <input id="sin" type="number" formControlName='sin' required class="validate"
                  onKeyPress="if(this.value.length > 9 || this.value > 100000000) return false;" />
                <label for="sin">Social Insurance Number <span class="red-text">(9 Digits)</span></label>
                <div *ngIf="(sin.dirty || sin.touched) && sin.invalid">
                  <small *ngIf="sin.errors.required" class="red-text"> The SIN Is Required. </small>
                  <small *ngIf="sin.errors.minlength || sin.errors.minlength" class="red-text"> SIN Must be 9 digits.
                  </small>
                </div>
              </div>
            </div>

            <div class="row" *ngIf="member?.PWA_Verify_ISN">
              <div class="input-field col s12 l12">
                <i class="material-icons prefix">lock</i>
                <input id="isn" type="number" formControlName='isn' required class="materialize-textarea validate"
                  onKeyPress="if(this.value.length > 10 || this.value > 1000000000) return false;" />
                <label for="isn">Indian Status Number <span class="red-text">(10 Digits)</span></label>
                <div *ngIf="(isn.dirty || isn.touched) && isn.invalid">
                  <small *ngIf="isn.errors.required" class="red-text"> The ISN Is Required. </small>
                  <small *ngIf="isn.errors.minlength || isn.errors.minlength" class="red-text"> SIN Must be 10 digits.
                  </small>
                </div>
              </div>
            </div>

            <div class="row" *ngIf="member?.PWA_Secret_Question">
              <div class="input-field col s12 l12">
                <i class="material-icons prefix">help_outline</i>
                <input id="question" type="text" formControlName='question' required
                  class="materialize-textarea validate" />
                <label for="question">{{member?.PWA_Secret_Question}} </label>
                <div *ngIf="(question.dirty || question.touched) && question.invalid">
                  <small *ngIf="question.errors.required" class="red-text"> Please answer the secret question! </small>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="input-field col s12 center">
                <button type="submit" [disabled]="!verificationForm.valid"
                  [ngClass]="'col s12 btn waves-effect ' + themeColor" (click)="verification(verificationForm)">
                  <i class="material-icons left">lock</i> Verify
                </button>
              </div>
            </div>
          </form>
        </div>

        <div *ngIf="step2">
          <div class="row">
            <div class="input-field col s12 l12">
              <i class="material-icons prefix">smartphone</i>
              <input id="phoneNumber" type="text" [(ngModel)]="member.cell_phone_num" disabled />
            </div>
          </div>

          <div class="row center">
            <div class="center" id="recaptcha-container"></div>
          </div>
          <div class="section"></div>

          <div class="row">
            <div class="input-field col s12 center">
              <button type="button" (click)="sendVerificationCode()"
                [ngClass]="'col s12 btn waves-effect ' + themeColor">
                <i class="material-icons left">sms</i> Request Verification Code
              </button>
            </div>
          </div>
        </div>

        <div *ngIf="step3">
          <form [formGroup]="registrationForm" (ngSubmit)="verifyLoginCode(registrationForm.value)">
            <div class="row col s12">
              <p><strong>Please Enter The Verification Code That We Have Sent To Your Mobile.</strong></p>
            </div>

            <div class="input-field col s12 l12">
              <i class="material-icons prefix">lock</i>
              <!-- <input id="verificationCode" type="text" [textMask]="{mask: verificationCodeMask}"
                formControlName='verificationCode' required class="validate" /> -->
                <input id="verificationCode" type="text" 
                formControlName='verificationCode' required class="validate" />
              <label for="verificationCode">Verification Code</label>
            </div>

            <div class="section"></div>
            <div class="row center">
              <p><strong>By Clicking <span class="green-text">Create My Account</span> I hereby :</strong></p>
              <p><strong>Agree and consent to the <a [routerLink]="['/eula']">User Agreement</a>, its policies and the
                  <a [routerLink]="['/privacy-policy']">Privacy Policy</a>.</strong> </p>
            </div>

            <div class="row">
              <div class="input-field col s12 center">
                <button type="submit" [disabled]="!registrationForm.valid"
                  [ngClass]="'col s12 btn waves-effect ' + themeColor">
                  <i class="material-icons left">how_to_reg</i> Verify And Create My Account
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  <div class="col m1 l2 "></div>
</div>