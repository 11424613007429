import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { GovernanceService } from 'src/app/services/firebase/governance.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-board-list',
  templateUrl: './board-list.component.html',
  styleUrls: ['./board-list.component.css']
})
export class BoardListComponent implements OnInit {

  themeColor = environment.appTheme.adminThemeColor;
  model: any[];
  
  constructor(private govService: GovernanceService, private location: Location) { }

  ngOnInit() {
    this.govService.getBoardList().valueChanges().subscribe(bList => {
      if (bList) {
        this.model = bList;
      }
    });
  }

  backClicked(){
    this.location.back();
  }
}
