<div class="row container-policy" style="font-family: 'sans-serif';">

  <div class="col s12 l12 m12">

    <div class="white">

      <div class="row">
        <h5 class="center blue-text text-darken-4" style="font-family: 'Anton'">Privacy Policy</h5>
        <br />

        <div class="divider"></div>
        <br />
        
        <div class="content">
          <p class="p-top">This Privacy Policy describes Our policies and procedures on the collection, use and
            disclosure of Your information when You use the Service and tells You about Your privacy rights and how
            the law protects You.
            We use Your Personal data to provide and improve the Service. By using the Service, You agree to the
            collection and use of information in accordance with this Privacy Policy.</p>


          <h5> Interpretation and Definitions </h5>
          <h5>Interpretation :</h5>
          <p>The words of which the initial letter is capitalized have meanings defined under the following
            conditions.</p>

          <p>The following definitions shall have the same meaning regardless of whether they appear in singular or in
            plural.</p>
          <div class="section"></div>
          <div class=" ">
            <h5>Definitions</h5>
            <p>For the purposes of this Privacy Policy:</p>

            <ul>
              <li style="list-style-type:square; margin-left: 20px;"> You means the individual
                accessing
                or using the Service, or the company, or other legal entity on behalf of which such individual is
                accessing or using the Service, as applicable.</li>
              <li style="list-style-type:square; margin-left: 20px;"> Company (referred to as either
                "the Company", "We", "Us" or "Our" in this Agreement) refers to 360 Nation Administration Solutions,
                Box
                1410 High Level Alberta T0H 1Z0.</li>
              <li style="list-style-type:square; margin-left: 20px;"> Application means the software
                program provided by the Company downloaded by You on any electronic device, named mynation.app</li>
              <li style="list-style-type:square; margin-left: 20px;"> Affiliate means an entity that
                controls, is controlled by or is under common control with a party, where "control" means ownership of
                50% or more of the shares, equity interest or other securities entitled to vote for election of
                directors or other managing authority.</li>
              <li style="list-style-type:square; margin-left: 20px;"> Account means a unique account
                created for You to access our Service or parts of our Service.</li>
              <li style="list-style-type:square; margin-left: 20px;"> Website refers to My Nation ,
                accessible from https://mynation.app</li>
              <li style="list-style-type:square; margin-left: 20px;"> Service refers to the
                Application
                or the Website or both.</li>
              <li style="list-style-type:square; margin-left: 20px;"> Country refers to: Alberta,
                Canada
              </li>
              <li style="list-style-type:square; margin-left: 20px;"> Service Provider means any
                natural
                or legal person who processes the data on behalf of the Company. It refers to third-party companies or
                individuals employed by the Company to facilitate the Service, to provide the Service on behalf of the
                Company, to perform services related to the Service or to assist the Company in analyzing how the
                Service is used.</li>
              <li style="list-style-type:square; margin-left: 20px;"> Third-party Social Media Service
                refers to any website or any social network website through which a User can log in or create
                an account to use the Service.</li>
              <li style="list-style-type:square; margin-left: 20px;"> Personal Data is any information
                that relates to an identified or identifiable individual.</li>
              <li style="list-style-type:square; margin-left: 20px;"> Cookies are small files that are
                placed on Your computer, mobile device or any other device by a website, containing the details of
                Your
                browsing history on that website among its many uses.</li>
              <li style="list-style-type:square; margin-left: 20px;"> Device means any device that can
                access the Service such as a computer, a cellphone or a digital tablet.</li>
              <li style="list-style-type:square; margin-left: 20px;"> Usage Data refers to data
                collected automatically, either generated by the use of the Service or from the Service infrastructure
                itself (for example, the duration of a page visit).</li>
            </ul>
          </div>

          <div class="section"></div>

          <h5> Collecting and Using Your Personal Data </h5>
          <h5>Types of Data Collected</h5>

          <h6>Personal Data</h6>
          <p>While using Our Service, We may ask You to provide Us with certain personally identifiable information
            that can be used to contact or identify You. Personally identifiable information may include, but is not
            limited to:</p>
          <ul>
            <li style="list-style-type:square; margin-left: 20px;">Email address</li>
            <li style="list-style-type:square; margin-left: 20px;">First name and last name.</li>
            <li style="list-style-type:square; margin-left: 20px;">Phone number</li>
            <li style="list-style-type:square; margin-left: 20px;">Address, State, Province, ZIP/Postal code, City
            </li>
            <li style="list-style-type:square; margin-left: 20px;">Usage Data</li>
          </ul>


          <h6>Usage Data</h6>
          <p>Usage Data is collected automatically when using the Service.</p>

          <p>Usage Data may include information such as Your Device's Internet Protocol address (e.g. IP address),
            browser type, browser version, the pages of our Service that You visit, the time and date of Your visit,
            the time spent on those pages, unique device identifiers and other diagnostic data.</p>

          <p>When You access the Service by or through a mobile device, We may collect certain information
            automatically, including, but not limited to, the type of mobile device You use, Your mobile device unique
            ID, the IP address of Your mobile device, Your mobile operating system, the type of mobile Internet
            browser You use, unique device identifiers and other diagnostic data.</p>

          <p>We may also collect information that Your browser sends whenever You visit our Service or when You access
            the Service by or through a mobile device.</p>


          <h6>Information from Third-Party Social Media Services</h6>

          <p>The Company allows You to create an account and log in to use the Service through the following
            Third-party Social Media Services:</p>

          <ul>
            <li style="list-style-type:square; margin-left: 20px;">Google</li>
            <li style="list-style-type:square; margin-left: 20px;">Facebook</li>
            <li style="list-style-type:square; margin-left: 20px;">Twiter</li>
          </ul>


          <p>If You decide to register through or otherwise grant us access to a Third-Party Social Media Service, We
            may collect Personal data that is already associated with Your Third-Party Social Media Service's account,
            such as Your name, Your email address, Your activities or Your contact list associated with that account.
          </p>

          <p>If You decide to register through or otherwise grant us access to a Third-Party Social Media Service, We
            may collect Personal data that is already associated with Your Third-Party Social Media Service's account,
            such as Your name, Your email address, Your activities or Your contact list associated with that account.
          </p>

          <p></p>

          <div class="section"></div>
          <div class=" ">
            <h5>Information Collected while Using the Application</h5>
            <p>While using Our Application, in order to provide features of Our Application, We may collect, with your
              prior permission:</p>
            <ul>
              <li style="list-style-type:square; margin-left: 20px;">Information regarding your location</li>
              <li style="list-style-type:square; margin-left: 20px;">Information from your Device's phone book
                (contacts
                list)</li>
              <li style="list-style-type:square; margin-left: 20px;">Pictures and other information from your Device's
                camera and photo library</li>
            </ul>


            <p>We use this information to provide features of Our Service, to improve and customize Our Service. The
              information may be uploaded to the Company's servers and/or a Service Provider's server or it be simply
              stored on Your device.</p>

            <p>You can enable or disable access to this information at any time, through Your Device settings.</p>
          </div>
          <div class="section"></div>
          <h5>Tracking Technologies and Cookies</h5>
          <p>We use Cookies and similar tracking technologies to track the activity on Our Service and store certain
            information. Tracking technologies used are beacons, tags, and scripts to collect and track information
            and to improve and analyze Our Service.</p>

          <p>You can instruct Your browser to refuse all Cookies or to indicate when a Cookie is being sent. However,
            if You do not accept Cookies, You may not be able to use some parts of our Service.</p>

          <p>Cookies can be "Persistent" or "Session" Cookies. Persistent Cookies remain on your personal computer or
            mobile device when You go offline, while Session Cookies are deleted as soon as You close your web
            browser. Learn more about cookies: All About Cookies.</p>
          <p>We use both session and persistent Cookies for the purposes set out below:</p>

          <h6> Necessary / Essential Cookies </h6>

          <p>Type: Session Cookies</p>

          <p>Administered by: Us</p>

          <p>Purpose: These Cookies are essential to provide You with services available through the Website and to
            enable You to use some of its features. They help to authenticate users and prevent fraudulent use of user
            accounts. Without these Cookies, the services that You have asked for cannot be provided, and We only use
            these Cookies to provide You with those services.</p>


          <h6> Tracking and Performance Cookies </h6>

          <p>Type: Persistent Cookies</p>

          <p>Administered by: Third-Parties</p>

          <p>Purpose: These Cookies are used to track information about traffic to the Website and how users use the
            Website. The information gathered via these Cookies may directly or indirectly identify you as an
            individual visitor. This is because the information collected is typically linked to a pseudonymous
            identifier associated with the device you use to access the Website. We may also use these Cookies to test
            new advertisements, pages, features or new functionality of the Website to see how our users react to
            them.</p>

          <p>For more information about the cookies we use and your choices regarding cookies, please visit our
            Cookies Policy.</p>

          <div class="section"></div>
          <div class=" ">
            <h5> Use of Your Personal Data </h5>
            <p>The Company may use Personal Data for the following purposes:</p>
            <ul>
              <li style="list-style-type:square; margin-left: 20px;"> To provide and maintain our Service,
                including to monitor the usage of our Service.</li>
              <li style="list-style-type:square; margin-left: 20px;"> To manage Your Account: to
                manage
                Your registration as a user of the Service. The Personal Data You provide can give You access to
                different functionalities of the Service that are available to You as a registered user.</li>
              <li style="list-style-type:square; margin-left: 20px;"> For the performance of a
                contract:
                the development, compliance and undertaking of the purchase contract for the products, items or
                services
                You have purchased or of any other contract with Us through the Service.</li>
              <li style="list-style-type:square; margin-left: 20px;"> To contact You: To contact You
                by
                email, telephone calls, SMS, or other equivalent forms of electronic communication, such as a mobile
                application's push notifications regarding updates or informative communications related to the
                functionalities, products or contracted services, including the security updates, when necessary or
                reasonable for their implementation.</li>
              <li style="list-style-type:square; margin-left: 20px;"> To provide You with news,
                special
                offers and general information about other goods, services and events which we offer that are similar
                to
                those that you have already purchased or enquired about unless You have opted not to receive such
                information.</li>
              <li style="list-style-type:square; margin-left: 20px;"> To manage Your requests: To
                attend
                and manage Your requests to Us.</li>
            </ul>

            <p>We may share your personal information in the following situations:</p>
            <ul>
              <li style="list-style-type:square; margin-left: 20px;"> With Service Providers: We may
                share Your personal information with Service Providers to monitor and analyze the use of our Service,
                to
                contact You.</li>
              <li style="list-style-type:square; margin-left: 20px;"> For Business transfers: We may
                share or transfer Your personal information in connection with, or during negotiations of, any merger,
                sale of Company assets, financing, or acquisition of all or a portion of our business to another
                company.</li>
              <li style="list-style-type:square; margin-left: 20px;"> With Affiliates: We may share
                Your
                information with Our affiliates, in which case we will require those affiliates to honor this Privacy
                Policy. Affiliates include Our parent company and any other subsidiaries, joint venture partners or
                other companies that We control or that are under common control with Us.</li>
              <li style="list-style-type:square; margin-left: 20px;"> With Business partners: We may
                share Your information with Our business partners to offer You certain products, services or
                promotions.
              </li>
              <li style="list-style-type:square; margin-left: 20px;"> With other users: when You share
                personal information or otherwise interact in the public areas with other users, such information may
                be
                viewed by all users and may be publicly distributed outside. If You interact with other users or
                register through a Third-Party Social Media Service, Your contacts on the Third-Party Social Media
                Service may see Your name, profile, pictures and description of Your activity. Similarly, other users
                will be able to view descriptions of Your activity, communicate with You and view Your profile.</li>
            </ul>
          </div>

          <div class="section"></div>
          <h5> Retention of Your Personal Data </h5>
          <p>The Company will retain Your Personal Data only for as long as is necessary for the purposes set out in
            this Privacy Policy. We will retain and use Your Personal Data to the extent necessary to comply with our
            legal obligations (for example, if we are required to retain your data to comply with applicable laws),
            resolve disputes, and enforce our legal agreements and policies.</p>

          <p>The Company will also retain Usage Data for internal analysis purposes. Usage Data is generally retained
            for a shorter period of time, except when this data is used to strengthen the security or to improve the
            functionality of Our Service, or We are legally obligated to retain this data for longer time periods.</p>

          <div class="section"></div>
          <h5> Transfer of Your Personal Data </h5>
          <p>Your information, including Personal Data, is processed at the Company's operating offices and in any
            other places where the parties involved in the processing are located. It means that this information may
            be transferred to — and maintained on — computers located outside of Your state, province, country or
            other governmental jurisdiction where the data protection laws may differ than those from Your
            jurisdiction.</p>

          <p>Your consent to this Privacy Policy followed by Your submission of such information represents Your
            agreement to that transfer.</p>

          <p>The Company will take all steps reasonably necessary to ensure that Your data is treated securely and in
            accordance with this Privacy Policy and no transfer of Your Personal Data will take place to an
            organization or a country unless there are adequate controls in place including the security of Your data
            and other personal information.</p>


          <div class="section"></div>
          <div class=" ">
            <h5> Disclosure of Your Personal Data </h5>
            <h6> Business Transactions </h6>
            <p>If the Company is involved in a merger, acquisition or asset sale, Your Personal Data may be
              transferred.
              We will provide notice before Your Personal Data is transferred and becomes subject to a different
              Privacy
              Policy.</p>

            <h6> Law enforcement </h6>
            <p>Under certain circumstances, the Company may be required to disclose Your Personal Data if required to
              do
              so by law or in response to valid requests by public authorities (e.g. a court or a government agency).
            </p>

            <h6> Other legal requirements </h6>
            <p>The Company may disclose Your Personal Data in the good faith belief that such action is necessary to:
            </p>

            <ul>
              <li style="list-style-type:square; margin-left: 20px;">Comply with a legal obligation</li>
              <li style="list-style-type:square; margin-left: 20px;">Protect and defend the rights or property of the
                Company</li>
              <li style="list-style-type:square; margin-left: 20px;">Prevent or investigate possible wrongdoing in
                connection with the Service</li>
              <li style="list-style-type:square; margin-left: 20px;">Protect the personal safety of Users of the
                Service
                or the public</li>
              <li style="list-style-type:square; margin-left: 20px;">Protect against legal liability</li>
            </ul>


            <h6> Security of Your Personal Data </h6>
            <p>The security of Your Personal Data is important to Us, but remember that no method of transmission over
              the Internet, or method of electronic storage is 100% secure. While We strive to use commercially
              acceptable means to protect Your Personal Data, We cannot guarantee its absolute security.</p>
          </div>
          <div class="section"></div>
          <h5> Detailed Information on the Processing of Your Personal Data </h5>
          <p>Service Providers have access to Your Personal Data only to perform their tasks on Our behalf and are
            obligated not to disclose or use it for any other purpose.</p>

          <h5>Analytics</h5>
          <p>We may use third-party Service providers to monitor and analyze the use of our Service.</p>

          <h6> Google Analytics </h6>
          <p>Google Analytics is a web analytics service offered by Google that tracks and reports website traffic.
            Google uses the data collected to track and monitor the use of our Service. This data is shared with other
            Google services. Google may use the collected data to contextualise and personalise the ads of its own
            advertising network.</p>

          <p>For more information on the privacy practices of Google, please visit the Google Privacy & Terms web
            page: https://policies.google.com/privacy?hl=en</p>

          <h6> Firebase </h6>
          <p>Firebase is an analytics service provided by Google Inc.</p>

          <p>You may opt-out of certain Firebase features through your mobile device settings, such as your device
            advertising settings or by following the instructions provided by Google in their Privacy Policy:
            https://policies.google.com/privacy?hl=en</p>

          <p>We also encourage you to review the Google's policy for safeguarding your data:
            https://support.google.com/analytics/answer/6004245.</p>

          <p>For more information on what type of information Firebase collects, please visit the Google Privacy &
            Terms web page: https://policies.google.com/privacy?hl=en</p>

          <div class="section"></div>
          <div class=" ">
            <h5> Email Marketing </h5>
            <p>We may use Your Personal Data to contact You with newsletters, marketing or promotional materials and
              other information that may be of interest to You. You may opt-out of receiving any, or all, of these
              communications from Us by following the unsubscribe link or instructions provided in any email We send
              or
              by contacting Us.</p>

            <p>We may use Email Marketing Service Providers to manage and send emails to You.</p>

            <h6> Mailchimp </h6>
            <p>Mailchimp is an email marketing sending service provided by The Rocket Science Group LLC.</p>

            <p>For more information on the privacy practices of Mailchimp, please visit their Privacy policy:
              https://mailchimp.com/legal/privacy/</p>

            <h6> Constant Contact </h6>
            <p>Constant Contact is an email marketing sending service provided by Constant Contact, Inc.</p>

            <p>For more information on the privacy practices of Constant Contact, please visit their Privacy policy:
              https://www.constantcontact.com/forward/privacy-center</p>

            <h6> AWeber </h6>
            <p>AWeber is an email marketing sending service provided by AWeber Communications.</p>

            <p>For more information on the privacy practices of AWeber, please visit their Privacy policy:
              https://www.aweber.com/privacy.htm</p>

            <h6> GetResponse </h6>
            <p>GetResponse is an email marketing sending service provided by GetResponse.</p>

            <p>For more information on the privacy practices of GetResponse, please visit their Privacy policy:
              https://www.getresponse.com/legal/privacy.html</p>

          </div>

          <div class="section"></div>
          <h5> Children's Privacy </h5>
          <p>Our Service does not address anyone under the age of 13. We do not knowingly collect personally
            identifiable information from anyone under the age of 13. If You are a parent or guardian and You are
            aware that Your child has provided Us with Personal Data, please contact Us. If We become aware that We
            have collected Personal Data from anyone under the age of 13 without verification of parental consent, We
            take steps to remove that information from Our servers.</p>

          <p>If We need to rely on consent as a legal basis for processing Your information and Your country requires
            consent from a parent, We may require Your parent's consent before We collect and use that information.
          </p>

          <div class="section"></div>
          <h5> Links to Other Websites </h5>
          <p>Our Service may contain links to other websites that are not operated by Us. If You click on a third
            party link, You will be directed to that third party's site. We strongly advise You to review the Privacy
            Policy of every site You visit.</p>

          <p>Our Service may contain links to other websites that are not operated by Us. If You click on a third
            party link, You will be directed to that third party's site. We strongly advise You to review the Privacy
            Policy of every site You visit.</p>

          <div class="section"></div>
          <div class=" ">
            <h5> Changes to this Privacy Policy </h5>
            <p>We may update our Privacy Policy from time to time. We will notify You of any changes by posting the
              new
              Privacy Policy on this page.</p>

            <p>We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming
              effective and update the "Last updated" date at the top of this Privacy Policy.</p>

            <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy
              Policy are effective when they are posted on this page.</p>

            <div class="section"></div>
            <h5> Contact Us </h5>
            <p>If you have any questions about this Privacy Policy, You can contact us:</p>

            <ul>
              <li style="list-style-type:square; margin-left: 20px;"> By email: info&#64;mynation.app </li>
              <li style="list-style-type:square; margin-left: 20px;"> By visiting this page on our website:
                https://mynation.app</li>
            </ul>
          </div>
        </div>
      </div>

      <!-- <div class="row">
    <button class="btn-flat center col l8 m8 s10 push-s1 push-m2 push-l2 back-button" (click)="backClicked()">
      <i class="material-icons left">arrow_back_ios</i> Back To Previous Page
    </button>
  </div> -->

    </div>

  </div>
</div>