import { Component, OnInit } from '@angular/core';
import { StaticContentService } from 'src/app/services/firebase/static-content.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';

declare var $: any;
@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.css']
})
export class NewsComponent implements OnInit {

  nationOfuse = environment.firstNation.name;
  nationName = environment.firstNation.displayName;
  defaultThemeColor = environment.appTheme.themeColor;
  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  model: any[];
  modelhotnews: any[];
  showLoader = false;

  // ========== remove once finished =========
  bodyBgColor: any;
  setting: any;
  sectionName: any;
  defaultBgColor = '#ffffff';

  constructor(
    private staticContentService: StaticContentService,
    private router: Router, public appSettingService: AppSettingsService) {

    // =============================================   remove once added to app-menu =============
  }

  ngOnInit() {
    this.sectionName = this.appSettingService.getCurrentMenu();

    this.showLoader = true;
    this.staticContentService.getContentList().valueChanges().subscribe(x => {
      if (x) {
        if (x.length > 0) {
          this.model = x;
          this.model = this.model.filter(data => data.published === true);
          // this.model.sort((a, b) => b.datePublished - a.datePublished);
          this.model.sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1));


          this.modelhotnews = x;
          this.modelhotnews = this.modelhotnews.filter(data => data.published === true);
          this.modelhotnews.sort((a, b) => b.datePublished - a.datePublished);
          this.modelhotnews = this.modelhotnews.slice(0, 3);


          this.showLoader = false;
        }
        this.showLoader = false;
      }
    });
    this.showLoader = false;
  }

  back() {
    localStorage.setItem('FromPath', 'NewsEvents');
    this.router.navigate(['/home-sub-menu']);
  }

}
