import { Component, OnInit } from '@angular/core';
import { ElectionService } from 'src/app/services/firebase/election.service';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';

@Component({
  selector: 'app-election-list',
  templateUrl: './election-list.component.html',
  styleUrls: ['./election-list.component.css']
})

export class ElectionListComponent implements OnInit {
  
  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  model: any[];
  showLoader = false;
  activeElection = [];
  closedElection = [];

  constructor(
    private electionService: ElectionService,
    private location: Location) { }

  ngOnInit() {
    this.showLoader = true;
    this.electionService.getElectionList().valueChanges().subscribe(data => {
      if (data.length > 0) {
        this.model = data;
        this.model.forEach(element => {
          const endDate = new Date(element.endDate);
          const today = new Date(new Date().toLocaleDateString());
          if (endDate > today ) {
            this.activeElection.push(element);
          } else {
            this.closedElection.push(element);
          }
        });
      }
      this.showLoader = false;
    });
  }

  backClicked() {
    this.location.back();
  }


}
