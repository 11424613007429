<!-- <app-header [themeColor]="themeColor" [navbarOption]="navbarOption" [nationLogo]="nationLogo" [rightOrLeftSidebar]="rightOrLeftSidebar"></app-header> -->
<div *ngIf="!isLoginPage && !isRegisterPage && !islandingEditor">
  <!-- <body class="bg-size" [ngStyle]="{'background-color':  bodyBgColor}"> -->
  <app-header [themeColor]="themeColor" [navbarOption]="navbarOption" [nationLogo]="nationLogo"
    [mobileView]="mobileView" [tabletView]="tabletView" [desktopView]="desktopView"></app-header>
</div>
<router-outlet class="mainBody">
  <app-loading *ngIf="!route.includes('message')"></app-loading>
</router-outlet>

<!-- <div class="row col s2 fixed-action-btn hide-on-med-and-up" style="margin-right: 10px;margin-bottom: 50px;">
<div class="row col s2 fixed-action-btn hide-on-med-and-up" style="margin-right: 10px;">
  <button (click)="back()" class="btn-floating btn-large red"><i class="material-icons">arrow_back</i>Back</button>
</div> -->

<!-- <a (click)="forward()" class="btn btn-large waves-effect waves-light right blue"><i class="material-icons">arrow_forward</i>forward</a> -->
<!-- <button (click)="back()" id="backBtn" class="btn-floating btn-large waves-effect waves-light blue">Back</button> -->

<!-- <app-footer></app-footer> -->