
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { ApiKeyManagerService } from 'src/app/services/api/apiKeyManager.Service';

//import { IsetsEmailModel } from 'src/app/models/isets/IsetsEmailModel';
import { EmailModel } from 'src/app/models/emailModel';

@Injectable({
  providedIn: 'root'
})


export class IsetsNationService {

    constructor(private httpClient: HttpClient, private keyManager: ApiKeyManagerService) { }
  
    getNationInfo(): Observable<EmailModel> {
      const url = environment.firstNation.apiUrl + 'api/nation/Get/';
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
      return this.httpClient.get<any>(url, { headers });
    }
  
    UpdateNationInfo(model: EmailModel): Observable<any> { 
      const url = environment.firstNation.apiUrl + 'api/nation/UpdateNation/';
      const headers = new HttpHeaders({SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8'});
      return this.httpClient.post<EmailModel>(url, JSON.stringify(model), {headers});
    }
   
    
  }
