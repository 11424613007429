import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CommentReply, StaticContentModel } from 'src/app/models/StaticContentModel';
import { Comment } from '../../models/StaticContentModel';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})

export class StaticContentService {
  baseUrl: any;


  constructor(private db: AngularFirestore, private authservice: AuthService) { }

  contentListRef: AngularFirestoreCollection<any>;
  contentRef: AngularFirestoreDocument<any>;
  private likeCountSubject: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  public likeCount$: Observable<number> = this.likeCountSubject.asObservable();
  private hasComment = true;
  private hasNoComment = false;
  comments: Comment[];
  likedUsers: any[] = [];

  // Update hasComment flag
  setHasComment(value: boolean) {
    this.hasComment = value;
  }

  // Update hasNoComment flag
  setHasNoComment(value: boolean) {
    this.hasNoComment = value;
  }

  // Get hasComment flag
  getHasComment(): boolean {
    return this.hasComment;
  }

  // Get hasNoComment flag
  getHasNoComment(): boolean {
    return this.hasNoComment;
  }

  // Method to update the likedBy array and like count for a content item
  updateLike(contentId: string, likedBy: string[], nameLikedBy: string[]): Promise<void> {
    const updateData = {
      likedBy,
      likeCount: likedBy.length,
      nameLikedBy
    };
    return this.contentListRef.doc(contentId).update(updateData);
  }

  getLikeCount(contentId: string): Promise<number> {
    console.log('Fetching like count for contentId:', contentId);
    return this.contentListRef.doc(contentId).get().toPromise()
      .then(snapshot => {
        const data = snapshot.data();
        if (data) {
          return data.likeCount || 0;
        }
        return 0;
      })
      .catch(error => {
        console.error('Error getting like count:', error);
        return 0;
      });
  }

  addCommentToContent(contentId: string, comment: Comment): Promise<void> {
    comment.id = this.generateUniqueId();
    return this.db
      .collection('StaticContent')
      .doc(contentId)
      .collection('comments')
      .doc(comment.id)
      .set(comment);
  }

  // Manage Posts ---------------------------------------------------------------------------------------
  addContent(model: any) {
    console.log('Data to be saved:', model);
    model.id = this.db.createId();
    model.likeCount = 0;
    model.likedBy = [];
    model.nameLikedBy = [];
    return this.db.collection('StaticContent').doc(model.id).set(model);
  }

  UpdateContent(model: any) {
    return this.db.collection('StaticContent').doc(model.id).update({
      ...model,
      likeCount: model.likeCount,
      likedBy: model.likedBy,
      nameLikedBy: model.nameLikedBy
    });
  }

  deleteComment(model: any, userId: string): Promise<void> {
    const commentIndex = model.comments.findIndex((comment: Comment) => comment.userId === userId);
    if (commentIndex !== -1) {
      model.comments.splice(commentIndex, 1);
      return this.db.collection('StaticContent').doc(model.id).update({
        comments: model.comments
      });
    } else {
      return Promise.reject('Comment not found.');
    }
  }

  deleteReply(comment: Comment, reply: CommentReply) {
    const replyIndex = comment.replies.findIndex(r => r.id === reply.id);
    if (replyIndex !== -1) {
      comment.replies.splice(replyIndex, 1);
      return this.db.collection('StaticContent').doc(comment.id).update({
        replies: comment.replies
      });
    } else {
      console.error('Reply not found.');
    }
  }

  getCommentsByContentId(contentId: string): Observable<Comment[]> {
    return this.contentListRef.doc(contentId).valueChanges().pipe(
      map((content: any) => content.comments || [])
    );
  }

  DeleteContent(model: any) {
    return this.db.collection('StaticContent').doc(model.id).delete();
  }

  getContentList(): AngularFirestoreCollection<any[]> {
    this.contentListRef = this.db.collection('StaticContent');
    return this.contentListRef;
  }

  getLimitedContentList(limit: number): AngularFirestoreCollection<any[]> {
    this.contentListRef = this.db.collection('StaticContent', ref => ref.orderBy('datePublished', 'desc').limit(limit));
    return this.contentListRef;
  }

  getSpecificContentList(visibility, published): AngularFirestoreCollection<any[]> {
    this.contentListRef = this.db.collection('StaticContent', ref => ref.where('visibility', '==', visibility).where('published', '==', published));
    return this.contentListRef;
    ;
  }

  SortContentOnServer(model: any[]) {
    model.forEach(element => {
      this.db.collection('StaticContent').doc(element.id).update(element);
    });
  }

  async updateContentComments(model: any, commentModel: Comment): Promise<void> {
    if (!Array.isArray(model.comments)) {
      model.comments = [];
    }

    const comment = {
      userName: commentModel.userName,
      userId: commentModel.userId,
      comment: commentModel.comment,
      timeStamp: new Date(),
      id: this.generateUniqueId(),
      replies: [],
      likesC: commentModel.likesC,
      likedByC: commentModel.likedByC,
      likedC: false,
    };

    if (!model.comments) {
      model.comments = [];
    }

    model.comments.push(comment);

    // Use await to ensure the comment array is updated before proceeding
    await this.db.collection('StaticContent').doc(model.id).update({
      comments: model.comments
    });
  }

  async updateComment(contentId: string, comment: Comment): Promise<void> {
    try {
      const contentRef = this.db.collection('StaticContent').doc(contentId);

      const snapshot = await contentRef.get().toPromise();
      const contentData = snapshot.data() as StaticContentModel;

      const commentIndex = contentData.comments.findIndex(c => c.id === comment.id);

      if (commentIndex !== -1) {
        contentData.comments[commentIndex] = comment;

        await contentRef.update({ comments: contentData.comments });
      } else {
        throw new Error('Comment not found.');
      }
    } catch (error) {
      console.error('Error updating comment:', error);
      throw error;
    }
  }

  // Find a comment by its ID
  findCommentById(contentId: string, commentId: string): Promise<Comment | undefined> {
    return this.db
      .collection('StaticContent')
      .doc(contentId)
      .collection('comments')
      .doc(commentId)
      .get()
      .toPromise()
      .then((doc) => {
        if (doc.exists) {
          return doc.data() as Comment;
        } else {
          return undefined;
        }
      })
      .catch((error) => {
        console.error('Error finding comment by ID:', error);
        throw error;
      });
  }

  getUserData(userId: string) {
    // Use Firebase Firestore to fetch user data
    this.db.collection('users').doc(userId).valueChanges().subscribe((userData: any) => {
      if (userData) {
        // Add the user data to the likedUsers array
        this.likedUsers.push(userData);
      }
    });
  }

  generateUniqueId(): string {
    return this.db.createId();
  }

  getContentByID(id: string): AngularFirestoreDocument<StaticContentModel> {
    this.contentRef = this.db.doc<StaticContentModel>('StaticContent/' + id);
    return this.contentRef;
  }

  getCommentsreply(): Comment[] {
    return this.comments;
  }

  // async updateCommentLikes(contentId: string, commentId: string, likesC: number, likedByC: string[]): Promise<void> {
  //   const contentRef = this.db.collection('StaticContent').doc(contentId);
  //   const snapshot = await contentRef.get().toPromise();
  //   if (snapshot.exists) {
  //     const contentData = snapshot.data() as StaticContentModel;
  //     const commentIndex = contentData.comments.findIndex(comment => comment.id === commentId);
  //     if (commentIndex !== -1) {
  //       contentData.comments[commentIndex].likesC = likesC;
  //       contentData.comments[commentIndex].likedByC = likedByC;
  //       await contentRef.update({
  //         comments: contentData.comments
  //       });
  //     }
  //   }

  // }

  async updateCommentLikes(contentId: string, commentId: string, likesC: number, likedByC: string[]): Promise<void> {
    const contentRef = this.db.collection('StaticContent').doc(contentId);
    const snapshot = await contentRef.get().toPromise();
    if (snapshot.exists) {
      const contentData = snapshot.data() as StaticContentModel;
      const commentIndex = contentData.comments.findIndex(comment => comment.id === commentId);
      if (commentIndex !== -1) {
        contentData.comments[commentIndex].likesC = likesC;
        contentData.comments[commentIndex].likedByC = likedByC;

        // Update the likedC property based on whether the current user has liked the comment
        const currentUserUid = this.authservice.globalUser.uid;
        contentData.comments[commentIndex].likedC = likedByC.includes(currentUserUid);

        await contentRef.update({
          comments: contentData.comments
        });
      }
    }
  }

  async updateCommentReply(contentId: string, commentId: string, replyId: string, reply: CommentReply, userId: string): Promise<void> {
    const contentRef = this.db.collection('StaticContent').doc(contentId);
    const snapshot = await contentRef.get().toPromise();
    if (snapshot.exists) {
      const contentData = snapshot.data() as { comments: Comment[] };
      const commentIndex = contentData.comments.findIndex(
        (comment) => comment.id === commentId
      );
      if (commentIndex !== -1) {
        const comment = contentData.comments[commentIndex];
        const replyIndex = comment.replies.findIndex(
          (r) => r.id === replyId
        );
        if (replyIndex !== -1) {
          const existingReply = comment.replies[replyIndex];
          existingReply.likesR = reply.likesR;
          existingReply.likedR = reply.likedR;
          existingReply.likedByR = reply.likedByR;
          contentData.comments[commentIndex] = comment;
          await contentRef.update(contentData);
        }
      }
    }
  }

}