import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DepartmentModel } from 'src/app/models/DepartmentModel';
import { DepartmentService } from 'src/app/services/firebase/department.service';
import { environment } from 'src/environments/environment';

declare var M: any;

@Component({
  selector: 'app-view-subdepartment-info',
  templateUrl: './view-subdepartment-info.component.html',
  styleUrls: ['./view-subdepartment-info.component.scss']
})

export class ViewSubdepartmentInfoComponent implements OnInit {

  model: DepartmentModel;
  subDepartmentId: any;
  themeColor = environment.appTheme.themeColor;
  nationOfUSe = environment.firstNation.name;
  constructor(
    private router: Router,
    private departmentService: DepartmentService,
    private route: ActivatedRoute) {}

   ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.subDepartmentId = params.get('id');
    });

    if (this.subDepartmentId) {
      this.departmentService.getDepartmentByID(this.subDepartmentId).valueChanges().subscribe(x => {
        if (x) {
          this.model = x;
        }
      });
    }
  }

  backClicked() {
    this.router.navigate(['/member/manage-sub-department', this.model.parent_id]);
    // this.router.navigate(['/member/manage-sub-department'], { state: { department_id:  this.model.parent_id} });
  }
}
