<div class="row col s12">
  <div class="col l1 "></div>
  <div class="col s12 m12 l10">
    
    <div *ngIf="partcipated && !model">
      <div class="row center-align">
        <h6> <strong> Poll / Survey </strong></h6>
      </div>
      <div class="card">
        <div class="card-content">
          <div class="row center">
            <i style="font-size: 100px;" class="material-icons red-text">
              info
            </i>
            <p class="red-text"> <strong>You Have already Participated In This Survey.</strong></p>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="model">
      <div class="row center-align">
        <h6 *ngIf="model.type === 'Poll'"> <strong> Poll </strong></h6>
        <h6 *ngIf="model.type === 'Survey'"> <strong> Survey </strong></h6>
      </div>
      <div *ngIf="step1">
        <app-polls-survey-view [model]="model" [clientID]="clientID" (participationSaved)="parentEventHandlerFunction($event)"></app-polls-survey-view>
      </div>

      <div *ngIf="step2">
        <div class="card">
          <div class="card-content">
            <div class="row center">
              <!-- <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
              </svg> -->
              <i style="font-size: 100px;" class="material-icons green-text">
                check_circle
              </i>
              <p class="green-text"> <strong>Thank You For Participating In This Survey</strong></p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section"></div>
      <button [ngClass]="'col s12 btn waves-effect ' + themeColor" (click)="backClicked()">
        <i class="material-icons left">arrow_back_ios</i> Back To Survey List
      </button>
  </div>
  <div class="col l1 "></div>
</div>