import { Component, OnInit } from '@angular/core';
import { MemberService } from 'src/app/services/api/member.service';
import { FamilyCompositionService } from 'src/app/services/api/family-composition.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { MemberModel } from 'src/app/models/memberModel';
import { DatePipe, Location } from '@angular/common';
import { environment } from 'src/environments/environment';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { toast } from 'materialize-css';
import { UpdateProfileService } from 'src/app/services/firebase/update-profile.service';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-family-composition',
  templateUrl: './family-composition.component.html',
  styleUrls: ['./family-composition.component.css']
})

export class FamilyCompositionComponent implements OnInit {

  public dateMask = [/[0-3]/, /[0-9]/, '/', /[0-1]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  public dateformat = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
  public isnMask = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  relationships: any = ["Spouse", "Son", "Daughter", "Grandson", "Granddaughter", "Niece", "Nephew", "Cousin", "Brother", "Sister", "Relative"];
  
  loading = false;
  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  nationOfuse = environment.firstNation.name;
  isDemo = false;
  member: MemberModel;
  familyMembers: MemberModel[];
  applicantID = 0;
  addFamilyForm: UntypedFormGroup;
  dobSelected = '';
  dobSet = false;
  hasBD = false;
  addedMembers: any[];
  showForm = false;
  displayAdded = false;
  
  // tslint:disable-next-line: max-line-length
  constructor(private authService: AuthService, 
    private memberService: MemberService, 
    private familyCompositionService: FamilyCompositionService, 
    private location: Location, private fb: UntypedFormBuilder, 
    private updateProfileService: UpdateProfileService) {
    localStorage.setItem('from', 'PersonalInfo');
  }

  ngOnInit() {
    this.loading=true;
    if (this.nationOfuse === 'Demo') { this.isDemo = true; }
    this.applicantID = this.authService.getCurrentApplicantID();
    this.memberService.getMember(this.applicantID).subscribe(x => {
      this.member = x[0];
      if(this.member.current_application){
        this.familyCompositionService.getFamilyMember(this.member.current_application).subscribe(data => {
          if (data) {
            this.familyMembers = data;
          }
          this.loading=false;
        });
      }
      this.loading = false;
    });

    const profile = {
      applicantID: this.applicantID,
      MaritalStatus: '',
      MailAddress: '',
      city: '',
      province: '',
      PostalCode: '',
      HouseNumber: '',
      Telephone: '',
      Fax: '',   // value.fax,
      cell_phone_num: '', //value.cell,
      HealthCareNo: '',
      SIN: '',
      Email: '',
      spouse_aid: 0,
    };

    this.memberService.checkIfBDLast2Month(profile).subscribe(cnt => {
      if (cnt > 0) {
        this.hasBD = true;
      }
    });

    this.addFamilyForm = this.fb.group({
      firstName: ['', [Validators.required, Validators.minLength(1)]],
      middleName: [''],
      lastName: ['', [Validators.required, Validators.minLength(1)]],
      dob: ['', [Validators.required, this.isValidDate]],
      isn: ['', [Validators.minLength(10), Validators.maxLength(10), Validators.pattern('^[0-9]*$')]],
      relationship: ['', [Validators.required]],
      members: this.fb.array([]),
    });

    setTimeout(() => {
      $('.collapsible').collapsible();
      $('select').formSelect();
      $('.modal').modal();
      M.updateTextFields();
    }, 25);
  }

  get firstName() { return this.addFamilyForm.get('firstName'); }
  get middleName() { return this.addFamilyForm.get('middleName'); }
  get lastName() { return this.addFamilyForm.get('lastName'); }
  get dob() { return this.addFamilyForm.get('dob'); }
  get isn() { return this.addFamilyForm.get('isn'); }
  get relationship() { return this.addFamilyForm.get('relationship'); }
  get memberControls() { return this.addFamilyForm.get('members') as UntypedFormArray; }

  setDob(e) {
    if (e.match(this.dateformat)) {
      this.dobSelected = e;
      const date = this.changeDates(e);
      const datePipe = new DatePipe('en-US');
      const formatedDate = datePipe.transform(date, 'fullDate');
      this.dob.patchValue(formatedDate);
      this.dobSet = true;
    }
  }

  unsetDob() {
    this.dob.patchValue(this.dobSelected);
    this.dobSet = false;
  }

  isValidDate(control: UntypedFormControl) {
    let date = control.value;
    const dateformat = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
    if (date) {
      if (!date.match(dateformat) && date.length > 10) {
        const datePipe = new DatePipe('en-US');
        date = datePipe.transform(date, 'dd/MM/yyyy');
      }
      if (date.match(dateformat)) {
        let dateArray = date.split('/');
        let day = dateArray[0];
        let month = dateArray[1] - 1;
        let year = dateArray[2];
        date = new Date(year, month, day);
        const today = new Date();
        if (Number(year) <= Number(today.getFullYear() - 100)) {
          return { 'invalidDate': true }
        } else if (date.valueOf() >= today.valueOf()) {
          return { 'invalidDate': true }
        } else {
          return null;
        }
      } else {
        return { 'invalidDate': true }
      }
    }
    return null;
  }

  changeDates(dateValue) {
    if (dateValue) {
      if (dateValue.match(this.dateformat)) {
        let dateArray = dateValue.split('/');
        let year = dateArray[2];
        let month = dateArray[1] - 1;
        let day = dateArray[0];
        const date = new Date(year, month, day);
        return date;
      } else {
        const date = new Date(dateValue);
        return date;
      }
    } else {
      return null
    }
  }

  backClicked() {
    this.location.back();
  }

  openForm() {
  this.showForm = true;
  this.dobSelected = '';
   this.firstName.reset();
   this.middleName.reset();
   this.lastName.reset();
   this.dob.reset();
   this.isn.reset()
   this.relationship.reset();
    setTimeout(() => {
      $('.collapsible').collapsible();
      $('select').formSelect();
      $('.modal').modal();
      M.updateTextFields();
    }, 25);
  }

  closeForm() {
    this.showForm = false;
  }

  newMember(values: any) {
    return this.fb.group({
      firstName: [values.firstName],
      middleName: [values.middleName],
      lastName: [values.lastName],
      dob: [values.dob],
      isn: [values.isn],
      relationship: [values.relationship]
    });
  }

  addNewMember(values: any) {
    const members = this.memberControls;
    values.dob = this.changeDates(values.dob);
    members.push(this.newMember(values));
    this.showForm = false;
    this.displayAdded = true;
    setTimeout(() => {
      $('.collapsible').collapsible();
    }, 25);
  }
  
  onDelete(index: any) { 
    const members = this.memberControls;
    members.removeAt(index);
    if(members.length == 0){
      this.displayAdded = false;
    }
  }

  submit(){
    const updates = {
      applicantID: this.applicantID,
      GivenName: this.member.GivenName,
      LastName: this.member.LastName,
      DOB: this.member.DOB,
      type: 'Family Composition',
      changes: this.addFamilyForm.get('members').value,
      date: new Date(),
    }
  
    this.updateProfileService.updateFamilyComp(updates).then(x => {
      toast({ html: 'Your request has been sent successfully!', classes: 'green' });
      this.backClicked();
    });
  }
}