import { Injectable } from '@angular/core';
import { AngularFirestoreDocument, AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class SocialMediaService {

  socialMediaListRef: AngularFirestoreCollection<any>;
  socialMediaRef: AngularFirestoreDocument<any>;

  constructor(private db: AngularFirestore) { }

  saveSocialMedia(model: any) {
    model.id = this.db.createId();
    return this.db.collection('SocialMedia').doc(model.id).set(model);
  }

  updateSocialMedia(model: any) {
    return this.db.collection('SocialMedia').doc(model.id).update(model);
  }

  getSocialMedia() {
    this.socialMediaListRef = this.db.collection('SocialMedia');
    return this.socialMediaListRef;
  }

}
