import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { AuthService } from 'src/app/services/firebase/auth.service';

@Component({
  selector: 'app-survey-results',
  templateUrl: './survey-results.component.html',
  styleUrls: ['./survey-results.component.css']
})

export class SurveyResultsComponent implements OnInit {

  defaultThemeColor = environment.appTheme.themeColor;
  themeColor: any;
  buttonType: any;
  user: any;
  permissions: any[];
  isSuperAdmin = false;
  
  constructor(
    private authService: AuthService,
    private location: Location,
    private settingsService: AppSettingsService) {
      this.authService.getCurrentApplicantIDObservable().subscribe(
        user => {
          if (user) {
            this.user = user;
            if (user.isSuperAdmin) this.isSuperAdmin = true;
            if (user.permissions) this.permissions = user.permissions;
            else if (!this.isSuperAdmin) {
              $('.modal').modal();
              $('#permissionModal').modal('open');
            }
          }
        }
      );
    this.themeColor = this.settingsService.getAppThemeColor() ? this.settingsService.getAppThemeColor() : this.defaultThemeColor;
    this.buttonType = this.settingsService.getButtonType() ? this.settingsService.getButtonType() : 'rectangularButton';
  }

  ngOnInit() {
  }

  backClicked() {
    this.location.back();
  }

}
