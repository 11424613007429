<div class="container">


  <br />
  <div class="row center-align">
    <h6><strong> Create An Account </strong></h6>
  </div>



  <div class="row col s12">
    <div class="col l1"></div>

    <div class="col s12 l10">
      <form [formGroup]="addEmployerForm" (ngSubmit)="addEmployer(addEmployerForm.value)">

        <div class="row card-panel white darken-2" *ngIf="step1">
          <div class="row">

            <div class="input-field col s12">
              <i class="material-icons green-text prefix">work</i>
              <input id="name" type="text" formControlName='name' required class="validate" autocomplete="off"
                style="text-transform: capitalize;" />
              <label for="name">Company Name</label>
              <div *ngIf="(name.dirty || name.touched) && name.invalid">
                <small *ngIf="name.errors.required" class="red-text"> The Company name is required. </small>
              </div>
            </div>


            <div class="input-field col s12">
              <i class="material-icons teal-text prefix">house</i>
              <input id="address" type="text" formControlName='address' required class="validate" autocomplete="off"
                style="text-transform: capitalize;" />
              <label for="address">Address</label>
              <div *ngIf="(address.dirty || address.touched) && address.invalid">
                <small *ngIf="address.errors.required" class="red-text"> The addrerss is required. </small>
              </div>
            </div>

            <div class="input-field col s12">
              <i class="material-icons blue-text prefix">place</i>
              <input id="city" type="text" formControlName='city' required class="validate" autocomplete="off"
                style="text-transform: capitalize;" />
              <label for="city">City</label>
              <div *ngIf="(city.dirty || city.touched) && city.invalid">
                <small *ngIf="city.errors.required" class="red-text"> The city is required. </small>
              </div>
            </div>

            <div class="row input-field col s12">
              <i class="material-icons purple-text prefix">place</i>
              <select formControlName="province" required class="validate">
                <option value="" disabled selected>Please Select a Province </option>
                <option *ngFor="let t of provinces" [value]="t">{{t}}</option>
              </select>
            </div>


            <div class="input-field col s12">
              <i class="material-icons pink-text prefix">phone</i>
              <!-- <input id="phone" type="text" formControlName='phone' required class="validate" autocomplete="off" [textMask]="{mask: phoneNumberMask}"/> -->
              <input id="phone" type="text" [inputMask]="phoneInputMask" formControlName='phone' required
                class="validate" autocomplete="off" />
              <label for="phone">Phone Number</label>
              <div *ngIf="(phone.dirty || phone.touched) && phone.invalid">
                <small *ngIf="phone.errors.required" class="red-text"> The phone number is required. </small>
              </div>
            </div>



          </div>

        </div>


        <div class="row card-panel white darken-2" *ngIf="step2">
          <div class="row">



            <div class="input-field col s12">
              <i class="material-icons blue-text prefix">email</i>
              <input id="email" type="email" formControlName='email' required class="validate" autocomplete="off" />
              <label for="email">Email</label>
              <div *ngIf="(email.dirty || email.touched) && email.invalid">
                <small *ngIf="email.errors.required" class="red-text"> The Email is required. </small>
                <small *ngIf="email.errors.email" class="red-text"> wrong email format. </small>
              </div>
            </div>

            <div class="input-field col s12">
              <i class="material-icons green-text prefix">lock</i>
              <input id="password" type="password" formControlName='password' required class="validate"
                autocomplete="off" />
              <label for="password">Password</label>
              <div *ngIf="(password.dirty || password.touched) && password.invalid">
                <small *ngIf="password.errors.required" class="red-text"> The password is required. </small>
              </div>
            </div>

            <div class="input-field col s12">
              <i class="material-icons teal-text prefix">lock</i>
              <input id="confirmPassword" type="password" formControlName='confirmPassword' required class="validate"
                autocomplete="off" />
              <label for="confirmPassword">Confirm Password</label>
              <div *ngIf="(confirmPassword.dirty || confirmPassword.touched) && confirmPassword.invalid">
                <small *ngIf="confirmPassword.errors.required" class="red-text"> The password confirmation is required.
                </small>
                <small *ngIf="confirmPassword.hasError('invalid')" class="red-text">Passwords Doesnt Match ! </small>
              </div>
            </div>

          </div>

        </div>

        <div class="section"></div>

        <a *ngIf="step1" class="col s12 btn waves-effect green" (click)="next()" style="margin-bottom: 15px;"><i
            class="material-icons right">arrow_right</i> Next</a>

        <button *ngIf="step2" type="submit" [disabled]="!addEmployerForm.valid" style="margin-bottom: 15px;"
          class="col s12 btn waves-effect green">
          <i class="material-icons right">cloud</i> Create Account
        </button>

        <a *ngIf="step2" class="col s12 btn waves-effect teal" (click)="back()" style="margin-bottom: 15px;"><i
            class="material-icons left">house</i> Back To Previous Page</a>

        <a class="col s12 btn waves-effect" [ngStyle]="{'background-color': themeColor}" (click)="backClicked()"><i
            class="material-icons left">house</i> Back To Home Page</a>

      </form>
    </div>

    <div class="col l1"></div>
  </div>
</div>