<div class="container body">

  <div class="row center-align">
    <h5><strong> Service Plan Detail </strong></h5>
  </div>

  <div *ngIf="sp_item" class="row col s12">
    <div class="col l1 "></div>
    <div class="col s12 l10">
      <div class="collection">

        <a class="collection-item">
          <div>Item ID:<a class="secondary-content blue-text">{{sp_item.item_id}}</a></div>
        </a>

        <a class="collection-item">
          <div *ngIf="sp_item.sub_goal === 1">Item:<a class="secondary-content blue-text">Non-Financial Support </a>
          </div>
          <div *ngIf="sp_item.sub_goal === 2">Item:<a class="secondary-content blue-text">Employment </a></div>
          <div *ngIf="sp_item.sub_goal === 3">Item:<a class="secondary-content blue-text">Career Planning </a></div>
          <div *ngIf="sp_item.sub_goal === 4">Item:<a class="secondary-content blue-text">Training</a></div>
          <div *ngIf="sp_item.sub_goal === 5">Item:<a class="secondary-content blue-text">Information </a></div>
          <div *ngIf="sp_item.sub_goal === 6">Item:<a class="secondary-content blue-text">Financial Independence </a>
          </div>
        </a>

        <a class="collection-item ">
          <!--  <div>Client Responsibility:<a class="secondary-content blue-text">{{sp_item.client_responsibility}}</a></div>   -->
          <div class="row">
            <div class="col m3, l3">Client Responsibility:</div>
            <div class="col m9, l9"> <a
                class="secondary-content blue-text margin-left:auto; margin-right:0;">{{sp_item.client_responsibility}}</a>
            </div>
          </div>
        </a>

        <a class="collection-item">
          <!--  <div>Counselor Responsibility:<a class="secondary-content blue-text">{{sp_item.cm_responsibility}}</a></div>  -->

          <div class="row">
            <div class="col m3, l3">Counselor Responsibility:</div>
            <div class="col m9, l9"> <a
                class="secondary-content blue-text margin-left:auto; margin-right:0;">{{sp_item.cm_responsibility}}</a>
            </div>
          </div>
        </a>

        <a class="collection-item">
          <div>Start Date:<a class="secondary-content blue-text">{{sp_item.start_date_planned | date:
              'dd/MMM/yyyy'}}</a></div>
        </a>

        <a class="collection-item">
          <div>Finish Date:<a class="secondary-content blue-text">{{(sp_item.finish_date_planned !== null) ?
              (sp_item.finish_date_planned | date: 'dd/MMM/yyyy') : ""}}</a></div>
        </a>

        <a class="collection-item">
          <div *ngIf="sp_item.outcome_obtained">Outcome Obtained: <a class="secondary-content blue-text">Yes</a></div>
          <div *ngIf="!sp_item.outcome_obtained">Outcome Obtained:<a class="secondary-content red-text">No</a></div>
        </a>

        <a class="collection-item">
          <div>Date Outcome Obtained:<a class="secondary-content blue-text">{{(sp_item.outcome_obtained_date !== null) ?
              (sp_item.outcome_obtained_date | date: 'dd/MMM/yyyy') : ""}}</a></div>
        </a>

        <a class="collection-item">
          <!--  <div>Comments:<a class="secondary-content blue-text">{{sp_item.comments}}</a></div>  -->

          <div class="row">
            <div class="col m3, l3">Comments:</div>
            <div class="col m9, l9"> <a
                class="secondary-content blue-text margin-left:auto; margin-right:0;">{{sp_item.comments}}</a></div>
          </div>

        </a>

        <a class="collection-item">
          <div>Case Worker:<a class="secondary-content blue-text">{{sp_item.caseworker}}</a></div>
        </a>

        <a class="collection-item">
          <div *ngIf="sp_item.Client_SIGOK=='True'">Client Signed: <a class="secondary-content blue-text">Yes</a></div>
          <div *ngIf="sp_item.Client_SIGOK=='False'">Client Signed:<a class="secondary-content red-text">No</a></div>
        </a>

        <a class="collection-item">
          <div>Client Signed Date:<a class="secondary-content blue-text">{{(sp_item.client_sig_date !== null) ?
              (sp_item.client_sig_date | date: 'dd/MMM/yyyy') : ""}}</a></div>
        </a>

        <a class="collection-item">
          <div *ngIf="sp_item.Authority_SIGOK=='True'">Authority Signed: <a class="secondary-content blue-text">Yes</a>
          </div>
          <div *ngIf="sp_item.Authority_SIGOK=='False'">Authority Signed:<a class="secondary-content red-text">No</a>
          </div>
        </a>

        <a class="collection-item">
          <div>Authority Signed Date:<a class="secondary-content blue-text">{{(sp_item.auth_sig_date !== null) ?
              (sp_item.auth_sig_date | date: 'dd/MMM/yyyy') : ""}}</a></div>
        </a>
      </div>
    </div>
    <div class="col l1 "></div>
  </div>

  <div class="row col s12">
    <div class="col l1 "></div>

    <div class="col s12 l10">

      <a [ngClass]="'col s12 btn waves-effect ' + themeColor" (click)="backClicked()"><i
          class="material-icons left">arrow_back_ios</i> Back To Service Plan List</a>
    </div>

    <div class="col l1"></div>
  </div>
</div>