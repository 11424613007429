<div *ngIf="currentUserPosts.length > 0" class="posts-display-container">

  <div class="card each-post" *ngFor="let post of currentUserPosts; index as i">
    <div class="card-content">
      <div class="row post-header">
        <div class="col s2 l1 center" >
          <img class="profile-img-small"
            [src]="currentNationLogo"
            height="40px">
        </div>
        <div class="col s8 l9">
          <div class="blue-color-post">{{currentNationName}}</div>
          <div>{{ post.timeStamp.toDate() | date:'MMM d, h:mm a' }} · </div>
        </div>
        <div *ngIf="isAdmin && post.createdById == id" class="col s2 btn-flat right dropdown-trigger" href='#' data-target='admin-post-options'
          (click)="selectPost(post)">
          <span class="material-icons">
            more_vert
          </span>
        </div>
        <ul id='admin-post-options' class='dropdown-content'>
          <li><a class="waves-effect btn-flat" (click)="editPostClicked()">Edit Post</a></li>
          <li><a class="waves-effect btn-flat modal-trigger" href="#deletePost">Delete Post</a></li>
        </ul>
      </div>

      <div class="row" *ngIf="replaceURLs(post.message); let postMessage">
        <p [innerHtml]="postMessage">
        </p>
        <div *ngIf="post.mediaLink != '' ">
          <img [src]="post.mediaLink" style="max-height: 100%; width: 100%;">
        </div>
      </div>

      <div class="divider"></div>
      <div class="row" style="padding: 8px 0; font-size: 10px !important;"
        *ngIf="post.reactions.likes.count > 0 || post.comments.length > 0">
        <div class="col s5 center">
          <div *ngIf="post.reactions.likes.count > 0">
            {{post.reactions.likes.count}} <span class="material-icons material-icon-margin-top-zero blue-color-post"
              style="font-size: 16px;">
              recommend
            </span>
          </div>
        </div>
        <div class="col s7 center padding-right-0">
          <div *ngIf="post.comments.length > 0">
            {{post.comments.length | shortNumber}} Comments
          </div>
        </div>
      </div>
      <div class="divider" *ngIf="post.reactions.likes.count > 0 || post.comments.length > 0"></div>

      <div class="row" style="font-size: 11px;">
        <button class="col s5 btn-flat likePost" type="button" (click)="reactToPost(post.id)">
          <div *ngIf="checkIfReactionGiven(post.id); then liked; else notLiked">
          </div>
          <ng-template #liked><span class="material-icons blue-color-post">
              thumb_up
            </span></ng-template>
          <ng-template #notLiked><span class="material-icons">
              thumb_up
            </span></ng-template>
          Like
        </button>

        <button class="col s7 btn-flat padding-0 center" type="button">
          <span class="material-icons">
            chat
          </span>Comment</button>
      </div>

      <!--  *ngIf="commentsOn" -->
      <div class="divider"></div><br>

      <div class="scrollMe" *ngIf="post.comments.length > 0">
        <div class="row comment-section">
          <div  class="row each-comment" *ngFor="let comment of post.comments" style="margin-bottom: 3%;">
            <div class="col l1 s2 center">
              <img class="profile-img-small" src="assets/img/profile.jpg" height="40px">
            </div>
            <div class="col s10" style="background: #fcfaf9; border-radius: 8px;">
              <div class="blue-color-post">{{comment.userName || 'user name'}}</div>
              <div>{{comment.comment}}</div>
              <div class="comment-actions" style="display: inline-flex;">
                <div class="btn-flat blue-color-post" (click)="likeComment(post.id, comment.id)">Like</div>
                <div class="btn-flat blue-color-post" *ngIf="comment.likes.count>=1" style="cursor: initial;"><span
                    class="material-icons" style="margin-top:0.9rem;font-size:12px;">
                    thumb_up
                  </span>{{comment.likes.count}}</div>
                <div class="btn-flat"> · {{ comment.timestamp.toDate() | date:'MMM d, h:mm a' }} ·</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="divider" *ngIf="post.comments.length > 0"></div>
      <div class="row comment-input-section">
        <br >
        <form [formGroup]="commentOnPost" (ngSubmit)="addCommentToPost(post, commentOnPost.value.comment)">
          <div class="col s2 l1 center">
            <img class="profile-img-small"
              src="https://api.mysabenefits.ca/Media/Demo/Images/84e668f5-53f0-4885-9edb-61fa9c333fcc-360Logo.png"
              height="30px">
          </div>
          <div class="col s8 l10" style="padding:0 4px ;">
            <textarea class="textarea materialize-textarea" formControlName="comment"
              placeholder="Write a comment..."></textarea>
          </div>
          <button class="btn-flat col s2 l1 center blue-color-post" type="submit"
            style="font-size: 12px; padding: 0; margin-top: -0.2rem;text-transform: capitalize;">
            <span class="material-icons">
              send
              </span>
          </button>
        </form>
      </div>
    </div>

    <!-- Delete Post Modal -->
    <div id="deletePost" class="modal" style="width: 50%;">
      <div class="modal-content center modal-header">
        <h4 class="center">Delete Post</h4>
        <p>Are You Sure You want to Permanently delete this post?</p>
      </div>
      <div class="modal-footer" style="height: max-content;">
        <div class="row" style="margin: 1rem 0;">
          <div class="col s5 m3 offset-m5">
            <button class="modal-close grey btn" type="button">
              Cancel</button>
          </div>
          <div class="col s6 m3">
            <a class="modal-close modal-header btn" (click)="archivePost()">Delete Post</a>
          </div>
        </div>
      </div>
    </div>
    <!-- End of Modal -->
  </div>
</div>

<!-- Edit Post Modal -->
<div id="editPost" class="modal" style="max-width: 45%;">
  <form [formGroup]="editPostForm" (ngSubmit)="editPost(editPostForm.value)">
    <div class="modal-content modal-header">
      <h6 class="center-align">Edit Post</h6>
    </div>
    <div class="modal-content">
      <div class="input-field class col s11" style="padding-right: 0; margin-top: 0;">
        <textarea class="materialize-textarea" formControlName="message" style="
            min-height: 6rem;" placeholder=""></textarea>
      </div>
    </div>
    <div class="modal-footer" style="height: max-content;">
      <div class="row" style="margin: 1rem 0;">
        <div class="col s5 m3 offset-m5">
          <button class="modal-close grey btn" type="button">
            Cancel</button>
        </div>
        <div class="col s6 m3">
          <button class="modal-close modal-header btn" type="submit">Edit Post</button>
        </div>
      </div>
    </div>
  </form>
</div>

<!-- End of Modal -->
<ng-template #replyToCommentSection>
  <div class="row comment-input-section">
    <form [formGroup]="replyToComment" (ngSubmit)="replyToComment(post.id, comment.id)">
      <div class="col s2 l1" style="text-align: center;">
        <img class="profile-img-small"
          src="https://api.mysabenefits.ca/Media/Demo/Images/84e668f5-53f0-4885-9edb-61fa9c333fcc-360Logo.png"
          height="30px">
      </div>
      <div class="col s8 l9" style="padding:0 4px ;">
        <textarea class="textarea materialize-textarea" formControlName="comment"
          placeholder="Write a reply..."></textarea>
      </div>
      <button class="btn-flat col s2" type="submit"
        style="font-size: 12px; padding: 0; color: #0D47A1; margin-top: -0.2rem;text-align: center;text-transform: capitalize;">
        Reply
      </button>
    </form>
  </div>
</ng-template>