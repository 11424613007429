import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { JobOfferService } from 'src/app/services/firebase/joboffer.service';

declare var $: any;

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  job_lits: any;
  constructor(private authService: AuthService, private jobOfferService: JobOfferService) { }

  ngOnInit() {
    $('.collapsible').collapsible();
    this.jobOfferService.getJobOffersList().valueChanges().subscribe(x => {
      if (x) this.job_lits = x;
    });
  }

  signOut() {
    this.authService.signOut();
  }
}