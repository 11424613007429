<div class="body-h">
  <div class="header-setting b-m-2">
    <h4 class=""> My Highlights</h4>
  </div>

  <main class="StickyContent white row">
    <div class="col s12 l11">
      <div class="row" *ngIf="hasRows">
        <div class="card-panel white center" *ngFor="let m of model">
          <a [routerLink]="['/member/profile/edit-highlight', m.ID]"><strong><i
                class="material-icons left black-text">import_contacts</i> <span class="center">{{ m.Highlight }}</span>
            </strong></a>
        </div>
      </div>

      <div class="card-panel orange darken-2" *ngIf="!hasRows">
        <h6 class="center white-text"><i class="material-icons white-text left">info</i>
          <div *ngIf="resume_id">
            <strong> You dont have any Highlights <div class="section"></div> Please enter your highlights so that
              potential employers will be able to find you in our database and contact you (with permission) should your
              skills meet their requirements. </strong>
          </div>
          <div *ngIf="!resume_id">
            <strong>You dont have any Objective, Please add Job Objective first before add any Highlights ! </strong>
          </div>
        </h6>
      </div>

      <div class="section"></div>
      <div *ngIf="resume_id">
        <a [routerLink]="['/member/profile/add-highlight']" class='col s12 btn waves-effect green'
          style="margin-bottom: 15px;">
          <i class="material-icons right">add_circle</i> Add New Highlight
        </a>
      </div>

      <button [ngClass]="'col s12 btn waves-effect ' + themeColor" (click)="backClicked()">
        <i class="material-icons left">house</i> Back To Home Page
      </button>
    </div>
  </main>
</div>