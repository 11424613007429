import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { PSSSPApplicationModel } from 'src/app/models/PSSPApplicationModel';

@Injectable({
  providedIn: 'root'
})

export class PssspApplicationService {

  constructor(private db: AngularFirestore) { }

  applicationListRef: AngularFirestoreCollection<any>;
  applicationRefs: AngularFirestoreDocument<any>;

  saveApplication(model: PSSSPApplicationModel) {
    var application = {
      id: this.db.createId(),
      applicantID: model.applicantID,
      firstName: model.firstName,
      lastName: model.lastName,
      middleName: model.middleName,
      street: model.street,
      apt: model.apt,
      city: model.city,
      province: model.province,
      postalCode: model.postalCode,
      homeNumber: model.homeNumber,
      mobile: model.mobile,
      dob: model.dob,
      SIN: model.SIN,
      studentNo: model.studentNo,
      treatyNo: model.treatyNo,
      email: model.email,
      maritalStatus: model.maritalStatus,

      spouseFirstName: model.spouseFirstName? model.spouseFirstName:'',
      spouseLastName: model.spouseLastName? model.spouseLastName: '' ,
      spouseMiddleName: model.spouseMiddleName ? model.spouseMiddleName: '',
      spouseLiveTogether: model.spouseLive? model.spouseLive : '',
      spouseEmployed: model.spouseEmployed ? model.spouseEmployed : '',

      dependents: model.dependents? model.dependents: [],
      assistanceType: model.assistanceType,
      institution: model.institution,
      location: model.location,
      program: model.program,
      startDate: model.startDate,
      endDate: model.endDate,
      length: model.length,
      yearsCompleted: model.yearsCompleted,
      terms: model.terms,

      applicantName: model.applicantName,
      signature: model.signature,
      date: model.date,

      documents: model.documents,
    }
    return this.db.collection('PSSSP-Application').doc(application.id).set(application);
  }

  getApplications(){
    this.applicationListRef = this.db.collection('PSSSP-Application');
    return this.applicationListRef;
  }

  getInstitutions(){
    this.applicationListRef = this.db.collection('Institution-Names');
    return this.applicationListRef;
  }

  getPrograms(){
    this.applicationListRef = this.db.collection('Programs');
    return this.applicationListRef;
  }

  saveInstitutions(){
    console.log("saving list of inistitutions");
    const data = {
      id: this.db.createId(),
      city: '',
      Province: '',
      institutions: [
        {
        name: "Bethany College",
        branches: ["Hepburn"]
      },
      {
        name: "Briercrest College and Seminary",
        branches: ["Caronport"]
      },
      {
        name: "Carlton Trail College",
        branches: ["Humboldt"]
      },
      {
        name: "Horizon College and Seminary",
        branches: ["Saskatoon"]
      },
      {
        name: "College Mathieu",
        branches: ["Gravelbourg"]
      },
      {
        name: "Cumberland College",
        branches: ["Nipawin", "Tisdale", "Melfort", "Hudson Bay" ]
      },

      {
        name: "Eston College",
        branches: [" Eston"]
      },
      {
        name: "Great Plains College",
        branches: []
      },
      {
        name: "Lakeland College",
        branches: ["Lloydminster"]
      },
      {
        name: "Nipawin Bible College",
        branches: ["Nipawin"]
      },

      {
        name: "North West College",
        branches: ["The Battlefords", "Meadow Lake"]
      },
      {
        name: "Northlands College",
        branches: ["La Ronge", "Buffalo Narrows", "Creighton" ]
      },
      {
        name: "Parkland College",
        branches: ["Melville"]
      },
      {
        name: "St Peter's College",
        branches: ["Muenster"]
      },
      {
        name: "Saskatchewan Indian Institute of Technologies",
        branches: []
      },
      {
        name: "Saskatchewan Polytechnic",
        branches: ["Moose Jaw", "Prince Albert","Regina", " Saskatoon"]
      },
      {
        name: "Southeast College",
        branches: ["Weyburn"]
      },
      {
        name: "Western Academy Broadcasting College",
        branches: ["Saskatoon"]
      },]}
    return this.db.collection('Institution-Names').doc(data.id).set(data);
  }

  addInstitution(name: string){
    const newInstitution = {
      id:this.db.createId(),
      province: '',
      city: '',
      name: name,
      branches: [],
    };
    return this.db.collection('Institution-Names').doc(newInstitution.id).set(newInstitution);
  }

  addProgram(name: string){
    const newProgram = {
      id: this.db.createId(),
      program: name
    };
    return this.db.collection('Programs').doc(newProgram.id).set(newProgram);
  }
}