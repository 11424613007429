<div *ngIf="member && member.Email">

  <div class="row">
    <div class="input-field col s12 l12">
      <i class="material-icons prefix">email</i>
      <input id="Email" type="text" [(ngModel)]="member.Email" disabled  />
    </div>
  </div>

  <form [formGroup]="registrationForm" (ngSubmit)="register(registrationForm)">
    <div class="row">
      <div class="input-field col s12 l12">
        <i class="material-icons prefix">lock</i>
        <input id="createPassword" type="password" formControlName='createPassword' required class="validate"
          minlength="4" maxlength="25" autocomplete="off"/>
        <label for="createPassword">Create Password</label>
        <div *ngIf="(createPassword.dirty || createPassword.touched) && createPassword.invalid">
          <small *ngIf="createPassword.errors.required" class="red-text"> The Password Is Required.
          </small>
          <small *ngIf="createPassword.errors.minlength" class="red-text"> Password Length Must Be At Least 4
            Characters . </small>
          <small *ngIf="createPassword.errors.maxlength" class="red-text"> Password Must Not Exceed 25
            Characters . </small>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="input-field col s12">
        <i class="material-icons prefix">lock</i>
        <input id="confirmPassword" type="password" required formControlName='confirmPassword' class="validate" autocomplete="off">
        <label for="confirmPassword">Confirm Password</label>
        <div *ngIf="(confirmPassword.dirty || confirmPassword.touched) && confirmPassword.invalid">
          <small *ngIf="confirmPassword.errors.required" class="red-text">Confirm Password is required. </small>
          <small *ngIf="confirmPassword.hasError('invalid')" class="red-text">Passwords Doesnt Match ! </small>
        </div>
      </div>
    </div>

    <div class="section"></div>
    <div class="row center" >
    <p><strong>By Clicking <span class="green-text">Create My Account</span> I hereby :</strong></p>
    <p><strong>Agree and consent to the <a [routerLink]="['/eula']">User Agreement</a>, its policies and the <a [routerLink]="['/privacy-policy']">Privacy Policy</a>.</strong>  </p>
    </div>

    <div class="row">
      <div class="input-field col s12 center">
        <button type="submit" [disabled]="!registrationForm.valid" [ngClass]="'col s12 btn waves-effect ' + themeColor">
          <i class="material-icons left">how_to_reg</i> Create My Account
        </button>
      </div>
    </div>

  </form>
</div>

<div *ngIf="!(member && member.Email)" class="col s12 m6">
  <div class="card">
    <div class="card-content black-text">
      <div class="center red lighten-4">
        <br/>
        <h6><strong>Invitation Not Found</strong></h6>
        <p>You have registered already or Not invited as a chief, councillor or a board member</p>
        <br/>
      </div>
      <br/>
      <div class="center ">
        <button class="btn light-blue darken-3" (click)="backClicked()">Okay</button>
      </div>
    </div>
  </div>
</div>
