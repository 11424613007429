<div class="row center-align">
  <h6> Documents Preview</h6>
</div>

<div class="row col s12">
  <div class="col l2 "></div>
  <div class="col s12 m12 l8">
    <div class="card">
      <img class="responsive-img" *ngIf="imageToShow" [src]="imageToShow"  alt="Document Image"/>
    </div>
  </div>
  <div class="col l2"></div>
</div>

<div class="row col s12">
  <div class="col l2 "></div>
  <div class="col s12 m12 l8">
    <a class="btn-flat center blue  col s12 white-text" (click)="backClicked()"><i
        class="material-icons left">keyboard_backspace</i> Back To My Documents</a>
  </div>
  <div class="col l2"></div>
</div>