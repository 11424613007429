<div class="row">
  <div class="col s12 ">
    <div class="body-h">

      <div class="header-setting b-m-2 ">
        <div class="col l12 m12 s12" *ngIf="model.length > 0">
          <h4 class="">Archived Members</h4>
          <select class="browser-default hide-on-large-only" (change)="navigate($event.target.value)" >
            <option value="AboutUs">About Us</option>
            <option value="NationInfo">Nation Info</option>
            <option value="Departments">Department</option>
            <option value="Governance" disabled selected>Governance</option>
            <option value="AreaOfInterest">Area of Interest</option>
          </select>
        </div>
      </div>

      <main class="StickyContent white">
        <div class="row col s12" *ngIf="model.length > 0 && board">
          <div class="col s12 m12 l10">
            <div class="row card-panel ">
              <table class="striped centred">
                <thead>
                  <tr>
                    <th class="green-text"> {{board.name}} Members </th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let m of model">
                    <td><span><strong>{{ m.memberData.GivenName }} {{m.memberData.LastName}}</strong> </span></td>
                    <td>{{m.boardMember.memberType}}</td>
                    <td><a class="btn btn-small green white-text right"
                        (click)="restoreMember(m.memberData.applicantID, m.boardMember);"> Restore Member </a></td>
                    <td><a class="btn btn-small orange white-text right"
                        (click)="deleteMember(m.memberData.applicantID)">Delete</a></td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="row">
              <div class="section"></div>
              <a (click)="backClicked();" [ngClass]="'col s12 btn waves-effect ' + themeColor">
                <i class="material-icons left">arrow_back_ios</i>
                Back To Previous Page </a>
            </div>
          </div>
        </div>

        <div *ngIf="model.length == 0" style="min-height: 50px;">
          <div class="row">
            <div class="col s12 m6">
              <div class="card red lighten-4">
                <div class="card-content black-text center">
                  <p>No Archived Members</p>
                  <br />
                  <div class="center">
                    <button class="waves-effect waves-light btn" (click)="backClicked()">Okay</button>
                  </div>
                  <br />
                </div>
              </div>
            </div>
          </div>
          <a (click)="backClicked();" style="width: 100%;" [ngClass]="'col s12 btn waves-effect ' + themeColor">
            <i class="material-icons left">arrow_back_ios</i>
            Back To Previous Page </a>
        </div>

        <div id="chairExistsModal" class="modal">
          <div class="modal-content">
            <h4>Chair Already exists</h4>
          </div>
          <div class="modal-footer">
            <button class="modal-close waves-effect waves-green btn orange">Cancel</button>
          </div>
        </div>
      </main>

    </div>
  </div>
</div>