import { Component, OnInit } from '@angular/core';
import {Location} from '@angular/common';
import { ServicePlanService } from 'src/app/services/api/isets/servicePlan.service';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';

declare var $: any;

@Component({
  selector: 'app-service-plan-detail',
  templateUrl: './service-plan-detail.component.html',
  styleUrls: ['./service-plan-detail.component.css']
})

export class ServicePlanDetailComponent implements OnInit {

  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  item_id: any;
  sp_item: any; 
  
  constructor( private location: Location, 
    private servicePlanService: ServicePlanService, 
    private route: ActivatedRoute ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.item_id = params.get('id');
    });

    if (this.item_id ) {
      const id = + this.item_id;
      this.servicePlanService.getServicePlanByID(id).subscribe(x => { 
        this.sp_item = x[0];
      });
  
      setTimeout(() => {
        $('.modal').modal();
      }, 25);
    }
  }

  backClicked() {
    this.location.back();
  }
}
