<div class="row col col s12">
  <form (ngSubmit)="submitForm(uploadDocumentForm.value)" [formGroup]="uploadDocumentForm">

    <div class="row">
      <div class="row input-field col s12 l6">
        <div class="label-input">Document Type</div>
        <select formControlName="documentType" required class="validate browser-default" (change)="docTypeChange()">
          <option *ngIf="callerForm !== 'income_statement'" value="" disabled selected>Please Select Document Type
          </option>
          <option *ngIf="callerForm === 'income_statement'" value="Income Statement" disabled selected>Income Statement
          </option>
          <option *ngFor="let doc of documentTypes" [value]="doc">{{doc}}</option>
        </select>
      </div>

      <div class="input-field col s12 l6" *ngIf="showDesciption">
        <div class="label-input">Description</div>
        <input id="description" type="text" formControlName='description' class="validate" autocomplete="off" placeholder="Description" />
        <div *ngIf="(description.dirty || description.touched) && description.invalid">
          <small *ngIf="description.errors.required" class="red-text"> The description is required. </small>
        </div>
      </div>

      <div class=" col s12 l6  file-field input-field" *ngIf="!isCordova">
        <div class="label-input">Upload File</div>
        <div class="btn">
          <span>Browse File</span>
          <input type="file" (change)="handleFileInput($event.target.files)" />
        </div>

        <!-- <div class="file-path-wrapper">
          <input formControlName="document" class="file-path validate" type="text" placeholder="Supporting Document" />
        </div> -->
      </div>

      <div class="row" *ngIf="isCordova">
        <button type="button" class="btn-small  green  col s6" (click)="mobilePicture();"><i
            class="material-icons left">camera_alt </i> Camera</button>
        <button type="button" class="btn-small  blue col s6" (click)="libraryPicture();">
          <i class="material-icons left">photo_library</i> Library</button>
      </div>

      <div class="row col s12 input-field">
        <div class="col s12" *ngIf="preview">
          <img class="responsive-img" width="400px" [src]="preview" alt="cordova image">
          <a class="btn-floating btn-small waves-effect waves-light red right" (click)="removeFile()"><i
              class="material-icons">delete</i></a>
        </div>
      </div>
    </div>

    <div class="section"></div>

    <div class="col s12">
      <button *ngIf="!isCordova" type="button" [disabled]="!uploadDocumentForm.valid"
        class="at-btn col s12 offset-m2 btn right waves-effect success  modal-trigger" data-target="modal1"
        style="margin-bottom: 15px;"><i class="material-icons right">cloud_upload</i> Upload Document</button>

      <button *ngIf="isCordova" type="button"
        class="at-btn col s12 offset-m2 btn right waves-effect success  modal-trigger" data-target="modal1"
        style="margin-bottom: 15px;"><i class="material-icons right">cloud_upload</i> Upload Document</button>

      <!-- <button type="button" [ngClass]="'at-btn col s12 btn waves-effect ' + themeColor " (click)="backClicked()">
        <i class="material-icons left">keyboard_arrow_left</i> Back To Previous Page
      </button> -->
    </div>

    <div id="modal1" class="modal">
      <div class="modal-content">
        <h5>Upload Documents</h5>
        <p>Are you sure you want to upload this documents ?</p>
      </div>
      <div class="modal-footer">
        <button type="submit" class="btn waves-effect green" style="margin-right: 15px;">
          <i class=" material-icons right white-text">cloud_upload</i> Upload
        </button>
        <a class="modal-close amber darken-4 white-text btn-flat white-text"> <i
            class="material-icons right">close</i>Cancel</a>
      </div>
    </div>

  </form>
</div>