import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})

export class PollsAndSurveyService {

  surveyListRef: AngularFirestoreCollection<any>;
  surveyRef: AngularFirestoreDocument<any>;

  pollsListRef: AngularFirestoreCollection<any>;
  pollsRef: AngularFirestoreDocument<any>;

  constructor(private db: AngularFirestore) { }

  newSurvey(arg: any)  {
    arg.id  = this.db.createId();
    return this.db.collection('Surveys').doc(arg.id).set(arg);
  }

  getSurveyByID(id: string): AngularFirestoreDocument<any> {
    this.surveyRef = this.db.doc('Surveys/' + id);
    return this.surveyRef;
  }

  getSurveyList(): AngularFirestoreCollection<any[]> {
    this.surveyListRef = this.db.collection('Surveys');
    return this.surveyListRef;
  }
  
  updateSurvey(model: any){
    return this.db.collection('Surveys').doc(model.id).update(model);
  }

  newPoll(model: any)  {
    model.id  = this.db.createId();
    return this.db.collection('Polls').doc(model.id).set(model);
  }

  updatePoll(model: any)  {
    return this.db.collection('Polls').doc(model.id).update(model);
  }

  getPollByID(id: string): AngularFirestoreDocument<any> {
    this.pollsRef = this.db.doc('Polls/' + id);
    return this.pollsRef;
  }

  getPollList(): AngularFirestoreCollection<any[]> {
    this.pollsListRef = this.db.collection('Polls');
    return this.pollsListRef;
  } 
}