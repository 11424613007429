
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiKeyManagerService } from './apiKeyManager.Service';
import { HighlightModel } from 'src/app/models/HighlightModel';



@Injectable({
    providedIn: 'root'
  })
  export class HighlightService {
  
    constructor(private httpClient: HttpClient, private keyManager: ApiKeyManagerService) { }
  
    url = environment.firstNation.apiUrl + 'api/Highlight/';
  
    saveHighlight(model: HighlightModel): Observable<any> {
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
      return this.httpClient.post<HighlightModel>(this.url + 'SaveHighlight/', JSON.stringify(model), { headers });
    }
  
    updateHighlight(model: HighlightModel): Observable<any> {
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
      return this.httpClient.post<HighlightModel>(this.url + 'EditHighlight/', JSON.stringify(model), { headers });
    }
  
  
    deleteHighlight(model: HighlightModel): Observable<any> {
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
      return this.httpClient.post<HighlightModel>(this.url + 'DeleteHighlight/', JSON.stringify(model), { headers });
    }
  
  
    getHighlightById(id: number): Observable<HighlightModel[]> {
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
      return this.httpClient.get<HighlightModel[]>(this.url + 'GetHighlightByID/' + id, { headers });
    }
  

    getHighlightByResumeId(id: number): Observable<HighlightModel[]> {
      const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
      return this.httpClient.get<HighlightModel[]>(this.url + 'GetHighlightByResumeId/' + id, { headers });
    }
  
 
  }
