<div class="row center-align p-m-2">
  <h6><strong> Family Member</strong></h6>
</div>

<div class="row col s12" *ngIf="member">
  <div class="col l2 "></div>
  <div class="col s12 m12 l8">
    <app-personal-info [model]="member" [dependant]="dep"></app-personal-info>
  </div>
  <div class="col l2"></div>
</div>

<div class="row col s12">
  <div class="col l2 "></div>
  <div class="col s12 m12 l8">
    <a [ngClass]="'col s12 btn waves-effect ' + themeColor" (click)="backClicked()"><i
        class="material-icons left">keyboard_backspace</i> Back To Family Composition</a>
  </div>
  <div class="col l2"></div>
</div>