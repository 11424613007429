import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})

export class EmployeeRegRequestService {

  constructor(private db: AngularFirestore) { }

  requestListRef: AngularFirestoreCollection<any>;
  requestRefs: AngularFirestoreDocument<any>;

  saveRequest(model) {
    model.id = this.db.createId();
    return this.db.collection('Employee-RequestToJoin').doc(model.id).set(model);
  }

  getRequests() {
    this.requestListRef = this.db.collection('Employee-RequestToJoin');
    return this.requestListRef;
  }

  getRequestByMemberId(memberId) {
    this.requestListRef = this.db.collection('Employee-RequestToJoin', ref => ref.where('memberId', '==', Number(memberId)));
    return this.requestListRef;
  }

  getRequestByEmail(email) {
    this.requestListRef = this.db.collection('Employee-RequestToJoin', ref => ref.where('email', '==', email));
    return this.requestListRef;
  }

  getDepartmentRequests(departmentId) {
    this.requestListRef = this.db.collection('Employee-RequestToJoin', ref => ref.where('department', '==', departmentId));
    return this.requestListRef;
  }

  deleteRequest(id) {
    return this.db.collection('Employee-RequestToJoin').doc(id).delete();
  }

  // declineRequest(id){
  //   return this.db.collection('Employee-RequestToJoin').doc(id).update({})
  // }

  updateRequestStatus(id, status: number, reason?) {
    if (reason) {
      return this.db.collection('Employee-RequestToJoin').doc(id).update({ status: status, reject_reason: reason });
    } else {
      return this.db.collection('Employee-RequestToJoin').doc(id).update({ status: status });
    }
  }

  linkRequestToMember(id, memberId) {
    return this.db.collection('Employee-RequestToJoin').doc(id).update({ memberId: memberId });
  }

}
