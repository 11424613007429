import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { SocialMediaService } from 'src/app/services/firebase/social-media.service';
import { environment } from 'src/environments/environment';

declare var $: any;

@Component({
  selector: 'app-landing-page-slider',
  templateUrl: './landing-page-slider.component.html',
  styleUrls: ['./landing-page-slider.component.css']
})
export class LandingPageSliderComponent implements OnInit {

  @Input() edit: boolean = false;
  @Output() slideRef: EventEmitter<number> = new EventEmitter<number>();
  model: any;
  sliderSection: any;
  defaultThemeColor = environment.appTheme.themeColor;
  themeColor: any;
  //new 
  modelSoc: any;
  socialMediaSection: any;
  socialMediaModel: any[];
  interval: number;
  @Input() fullscreenEnabled: boolean = true;
  sliderIntervalId: any;

  constructor(private appSettingService: AppSettingsService, 
    private socialService: SocialMediaService) {
    this.themeColor = this.appSettingService.getAppThemeColor() ? this.appSettingService.getAppThemeColor() : this.defaultThemeColor;
  }
  slides = [{}];

  ngOnInit(): void {

    this.appSettingService.getHomePageSettings().valueChanges().subscribe(hp => {
      if (hp) {
        if (hp.length > 0) {
          this.model = hp[0];
          const slider = this.model.sections.filter(s => s.name === 'Slider' && s.isEnabled);

          if (slider) {
            this.sliderSection = slider[0];
            setTimeout(() => {
              $('.parallax').parallax();
              $('.tooltipped').tooltip();
            }, 25);

            setTimeout(() => {
              this.interval = this.sliderSection.interval ? this.sliderSection.interval: 3000 ;
              $('.slider').slider({
                indicators: true,
                interval: this.interval,
              });
            }, this.interval);
          }
        }
      }

      this.appSettingService.getHomePageSettings().valueChanges().subscribe(hp => {
        if (hp) {
          if (hp.length > 0) {
            this.modelSoc = hp[0];
            const socialSection = this.modelSoc.sections.filter(s => s.name === 'SocialMedia' && s.isEnabled);
            if (socialSection) {
              this.socialMediaSection = socialSection;
              this.socialService.getSocialMedia().valueChanges().subscribe(n => {
                if (n) {
                  if (n.length > 0) this.socialMediaModel = n[0];
                }
              });
            }
          }
        }
      });
    });
  }

  sliderClicked(index) {
    if (this.edit) {
      this.slideRef.emit(index);
    }
  }

  navigate(url: any) {
    if (!url) return;
    window.open(url, '_blank');
  }

}
