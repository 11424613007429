

export class ReferenceModel {
    ID = 0;
    RefFirst = '';
    RefLast = '';
    RefOrg = '';
    RefRelationship = '';
    RefTel= '';
    resume_id = 0;

  }
