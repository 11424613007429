
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ApiKeyManagerService } from './apiKeyManager.Service';


@Injectable({
    providedIn: 'root'
  })
  export class DatabaseConfigService {
  
    constructor(private httpClient: HttpClient, private keyManager: ApiKeyManagerService) { }
  
    url = environment.firstNation.apiUrl + 'api/DatabaseConfig/';
  

     updateDatabaseTables(id: any):Observable<any>{
        const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
        const formData: FormData = new FormData();
        formData.append('applicantID', id);
        return this.httpClient.post<any>(this.url  + 'UpdateDatabaseTables/' , formData, { headers });
      }
  
     // saveActivity(model: ActivityModel): Observable<any> {
      //  const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
      //  return this.httpClient.post<ActivityModel>(this.url + 'SaveActivity/', JSON.stringify(model), { headers });
     // }


     
  
}