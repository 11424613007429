<div class="row center-align p-m-2 b-m-2">
  <h6> <strong> Poll/Referendum </strong></h6>
</div>

<div class="row col s12">
  <div class="col l1 "></div>
  <div class="col s12 m12 l10">
    <div *ngIf="model">

      <div *ngIf="step1">
        <app-polls-view [model]="model" [clientID]="clientID" (participationSaved)="parentEventHandlerFunction($event)"></app-polls-view>
      </div>

      <div *ngIf="step2">
        <div class="card">
          <div class="card-content">
            <div class="row center">
              <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
              </svg>
              <p class="green-text"> <strong>Thank You For Participating In This Poll/Referendum</strong></p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="section"></div>
      <button [ngClass]="'col s12 btn waves-effect ' + themeColor" (click)="backClicked()">
        <i class="material-icons left">arrow_back_ios</i> Back To Polls List
      </button>
  </div>
  <div class="col l1 "></div>
</div>