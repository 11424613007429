<div class="col s12">
  <div class="body-h">
    <div class="header-setting b-m-2">
      <div class="col l9 m9 s12">
        <h4 *ngIf="!editName">
          <span *ngIf="sectionName">{{sectionName}}</span>
          <span *ngIf="!sectionName">Manage Board</span>
          <i class="material-icons edit-name " [ngStyle]="{'color': themeColor}" (click)="editNameClicked()">edit</i>
        </h4>

        <div class="input-field" *ngIf="editName">
          <input type="text" [(ngModel)]="sectionName" class="left col s6 validate" autocomplete="off" />
          <button type="button" class="btn ll-m-5" [ngStyle]="{'background-color': themeColor}"
            (click)="saveName()">Save</button>
        </div>

        <p class="col s12 bold-300 sub-title-section3">This is where you can manage boards and board members.</p>
      </div>
      <div class="col l3  m4 s12 p-m-2 ">
        <a [routerLink]="['/admin/dashboard-sub-menu/add-board/']" class="add-button btn white center-align "
          [ngStyle]="{'color': themeColor}"><i class="material-icons-outlined">add</i> Add New&nbsp;
          <span *ngIf="sectionName">{{sectionName}}</span>
          <span *ngIf="!sectionName">Board</span>
        </a>
      </div>
    </div>

    <main class="StickyContent row " style="padding-top:0%;">
      <div class="col s12 m12 l11 p-m-3">
        
          <div class="card col l12 s12 m12 " *ngFor="let board of model; let i = index">
            <div class="col s2" *ngIf="board.logo">
              <div class="card-option">
                <img [src]="board.logo" class="responsive-img circle">
              </div>
            </div>
            <div class="col s2" *ngIf="!board.logo">
              <div class="avatar-circle hide-on-med-and-up" [ngStyle]="{'background-color': themeColor}">
                <span class="initials">{{getInitials(board.name)}}</span>
              </div>
              <div class="avatar-circle2 hide-on-small-only show-on-medium-and-up"
                [ngStyle]="{'background-color': themeColor}">
                <span class="initials2">{{getInitials(board.name)}}</span>
              </div>
            </div>
            <div class="col s4">
              <a>
                <span [ngStyle]="{'color': board.nameColor}" class="name-governance"><strong>{{board.name}}</strong>
                </span>
              </a>
            </div>
            <div class="col s2">
              <a class="btn white right tooltipped" data-position="bottom" data-tooltip="Open as a PDF" type="button"
                style="margin-bottom: 25px;" (click)="openPdf(i)" style="margin:5px;"><i
                  class="material-icons-outlined black-text">open_in_new</i></a>
            </div>
            <div class="col s2">
              <a class="btn white right tooltipped" data-position="bottom" data-tooltip="Download as PDF" type="button"
                (click)="downloadPdf(i)" style="margin:5px;"><i
                  class="material-icons-outlined black-text">download</i></a>
            </div>
            <div class="col s2">
              <a class="btn white right tooltipped" data-position="bottom" data-tooltip="Edit" type="button"
                (click)="downloadPdf(i)" style="margin:5px;"><i class="material-icons-outlined  black-text"
                  [routerLink]="['/admin/dashboard-sub-menu/edit-board/',board.id]">
                  edit
                </i></a>
            </div>
          </div>
      </div>
    </main>
  </div>
</div>