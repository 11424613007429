import { Component, OnInit } from '@angular/core';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-register-login-buttons',
  templateUrl: './register-login-buttons.component.html',
  styleUrls: ['./register-login-buttons.component.css']
})

export class RegisterLoginButtonsComponent implements OnInit {

  defaultThemeColor = environment.appTheme.themeColor;
  themeColor: any;
  buttonType: any;
  model: any;
  setting: any;
  ISETRegSetting: any;
  hasISET=  environment.firstNation.ISET;
  hasSocial=  environment.firstNation.SOCIAL;
  
  constructor(private appSettingService: AppSettingsService) {
    this.themeColor = this.appSettingService.getAppThemeColor() ? this.appSettingService.getAppThemeColor() : this.defaultThemeColor;
    this.buttonType = this.appSettingService.getButtonType() ? this.appSettingService.getButtonType() : 'rectangularButton';
  }

  ngOnInit(): void {
    this.appSettingService.getHomePageSettings().valueChanges().subscribe(hp => {
      if (hp) {
        if (hp.length > 0) {
          this.model = hp[0];

          const buttons = this.model.sections.filter(s => s.name === 'Login' && s.isEnabled);
          if(buttons){
           this.setting = buttons[0];
          }
          if(this.hasISET){
            const ISETReg = this.model.sections.filter(s => s.name === 'ISETReg' && s.isEnabled);
            if(ISETReg) this.ISETRegSetting = ISETReg[0];
          }
        }
      }
    });
  }
}