import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { ApiKeyManagerService } from './apiKeyManager.Service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CommunityService {

  url = environment.firstNation.apiUrl + 'api/Community/';

  constructor(private httpClient: HttpClient, private keyManager: ApiKeyManagerService) { }

  
  getCommunity(): Observable<any[]> {
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
    return this.httpClient.get<any[]>( this.url + 'GetCommunities', { headers });
  }
  

  getBands(): Observable<any[]> {
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
    return this.httpClient.get<any[]>(
      this.url + 'GetBands', { headers });
  }

  getNationCommunities(): Observable<any[]> {
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
    return this.httpClient.get<any[]>(
      this.url + 'GetNationCommunities', { headers });
  }

  //
  
  getCommunityList(): Observable<any[]> {
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
    return this.httpClient.get<any[]>( this.url + 'GetCommunityList', { headers });
  }

}
