<div class="col s12">
  <div class="body-h">

    <div class="header-setting b-m-2">
      <div class="col l10 m10 s12">
        <h4 class="{{ sectionName ? '' : 'app-menu-title' }}">{{ sectionName || 'App Menu' }}</h4>
        <p class="bold-300 sub-title-section3">This is the menu that the general public will have
          access to on your mobile app</p>
      </div>
      <div class="col s12 l2 m2 p-m-3">
        <button class="col s12  btn save-button"
            [ngClass]="'btn ' + buttonType" (click)="saveMenu();">Save</button>
      </div>
    </div>

    <main class="StickyContent white">
      <div class="row col l11 m11 s12" *ngIf="appMenu">
        <table class="menu-items">
          <thead>
            <tr class="center">
              <th></th>
              <th></th>
              <th>Visibility</th>
              <th>Move</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of appMenu.MenuItems" class="card">
              <td class="favorite" [class.disabled]="!item.isEnabled">
                <i *ngIf="!item.favorite" class="material-symbols-outlined visible" (click)="favoriteSelected(item)"
                  style="cursor: pointer;">star</i>
                <i *ngIf="item.favorite" class="material-icons visible" (click)="favoriteSelected(item)"
                  style="cursor: pointer;">star</i>
              </td>
              <td>
                <div class="input-field">
                  <i class="material-symbols-outlined prefix" [ngStyle]="{'color': themeColor}">{{item.icon}}</i>
                  <input id="" type="text" autocomplete="off" [(ngModel)]="item.item" class="validate" />
                </div>
              </td>
              <td class="p-r-2">
                <div class="switch color">
                  <label>
                    <span class="hide-on-small-only bold-700 black-text">HIDE</span>
                    <input type="checkbox" [checked]="item.isEnabled" (change)="visibilityChanged(item)">
                    <span class="lever"></span>
                    <span class="hide-on-small-only bold-700 black-text">SHOW</span>
                  </label>
                </div>
              </td>
              <td class="move-btn">
                <button class="btn white" [disabled]="!item.isEnabled || item.index >= appMenu.MenuItems.length - 1"
                  (click)="moveSection(item, 1);"><i class="material-icons-outlined black-text">expand_more</i></button>
                <button class="btn white" (click)="moveSection(item, -1);"
                  [disabled]="!item.isEnabled || item.index === 0"><i
                    class="material-icons-outlined black-text">expand_less</i></button>
              </td>
            </tr>
          </tbody>
        </table>
        <br />
        <div class="row">
          <button class="col s12 l3 m3 offset-l9 offset-m9 btn save-button" style="margin-bottom: 15px;"
            [ngClass]="'btn ' + buttonType" (click)="saveMenu();">Save Changes</button>
        </div>
      </div>
    </main>

    <div id="confirmationModal" class="modal">
      <div class="modal-content">
        <h5><strong>Save Changes</strong></h5>
        <p>Admin Menu Setting</p>
        <hr />
        <div class="row center">
          <i class="material-icons red-text col s12 m6 l4" style="font-size: 60px;">help</i>
          <div class="row col s12 m6 l8 grey lighten-3">
            <p>Are you sure you want to make these changes?</p>
            <div class="col s12">
              <button type="button" style="margin-bottom: 15px;"
                class="btn center col l8 m10 s12 push-m1 push-l2 save-button"
                [ngClass]="'btn ' + themeColor">Continue</button>
            </div>
            <div class="col s12">
              <button type="button" style="margin-bottom: 15px;" [ngClass]="'btn ' + buttonType"
                class="btn center col l8 m10 s12 push-m1 push-l2 save-button red">Cancel</button>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
