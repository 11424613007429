import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class DepartmentNewsService {

  depNewsSectionListRef: AngularFirestoreCollection<any>;
  depNewsSectionRef: AngularFirestoreDocument<any>;

  constructor(private db: AngularFirestore) { }

  newDepNews(model: any)  {
    model.id = this.db.createId();
    return this.db.collection('DepartmentNews').doc(model.id).set(model);
  }

  UpdateDepNews(model: any)  {
    return this.db.collection('DepartmentNews').doc(model.id).update(model);
  }

  getDepNewsByID(id: string): AngularFirestoreDocument<any> {
    this.depNewsSectionRef = this.db.doc('DepartmentNews/' + id);
    return this.depNewsSectionRef;
  }

  getDepNewsList(id: any): AngularFirestoreCollection<any[]> {
    this.depNewsSectionListRef = this.db.collection('DepartmentNews', ref => ref.where('departmentID', '==', id));
    return this.depNewsSectionListRef;
  }

  getAllDepNewsList(): AngularFirestoreCollection<any[]> {
    this.depNewsSectionListRef = this.db.collection('DepartmentNews');
    return this.depNewsSectionListRef;
  }

  DeleteDepNews(model: any) {
    return this.db.collection('DepartmentNews').doc(model.id).delete();
  }

  SortContentOnServer(model: any[]) {
    model.forEach(element => {
      this.db.collection('DepartmentNews').doc(element.id).update(element);
    });
  }
}
