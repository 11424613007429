import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators, AbstractControl, UntypedFormControl } from '@angular/forms';
import { MemberModel } from '../../../models/memberModel';
import { MemberService } from 'src/app/services/api/member.service';
import { toast } from 'materialize-css';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { environment } from 'src/environments/environment';
import { OtherRolesService } from 'src/app/services/firebase/other-roles.service';

function passwordConfirming(c: AbstractControl): any {
  if (!c.parent || !c) { return; }
  const pwd = c.parent.get('createPassword');
  const cpwd = c.parent.get('confirmPassword');

  if (!pwd || !cpwd) { return; }
  if (pwd.value !== cpwd.value) {
    return { invalid: true };
  }
}

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-register-other-roles',
  templateUrl: './register-other-roles.component.html',
  styleUrls: ['./register-other-roles.component.css']
})

export class RegisterOtherRolesComponent implements OnInit {

  // tslint:disable-next-line: max-line-length
  constructor(private fb: UntypedFormBuilder,
              private otherRolesService: OtherRolesService,
              private route: ActivatedRoute,
              private memberService: MemberService,
              private authService: AuthService,
              private router: Router) { }

  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  applicantID: string = null;
  member: MemberModel = null;
  applicant: any;
  additionalRoles: any = {};

  step1 = true;
  step2 = false;
  minDate = new Date(new Date().getFullYear() - 100, 0, 1);
  maxDate = new Date(new Date().getFullYear() - 18, 0, 1);
  verificationForm: UntypedFormGroup;
  registrationForm: UntypedFormGroup;

  get sin() { return this.verificationForm.get('sin'); }
  get isn() { return this.verificationForm.get('isn'); }
  get dob() { return this.verificationForm.get('dob'); }
  get question() { return this.verificationForm.get('question'); }
  get createPassword() { return this.registrationForm.get('createPassword'); }
  get confirmPassword() { return this.registrationForm.get('confirmPassword'); }

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      if (params.get('id')) {
        const id = + params.get('id');
        this.memberService.getMember(id).subscribe(data => {
          if (data) {
            this.member = data[0];
                // get invitation data
            this.otherRolesService.getInvitationByApplicantId(id).valueChanges().subscribe(applicant => {
              if (applicant.length > 0) {
                this.applicant = applicant[0];
                this.member.Email = this.applicant.email;
                this.additionalRoles.isChief = this.applicant.isChief ? this.applicant.isChief : false;
                this.additionalRoles.isCouncillor = this.applicant.isCouncillor ? this.applicant.isCouncillor : false;
                this.additionalRoles.isBoardMember = this.applicant.isBoardMember ? this.applicant.isBoardMember : false;
              }
            });

            if (!this.member.PWA_Verify_SIN && !this.member.PWA_Verify_ISN && !this.member.PWA_Secret_Question) {
              this.step1 = false;
              this.step2 = true;
            }

            this.verificationForm = this.fb.group({
            });

            if (this.member.PWA_Verify_SIN) {
              this.verificationForm.addControl('sin', new UntypedFormControl('', [Validators.required, Validators.minLength(9)]  ));
            }
            if (this.member.PWA_Verify_ISN) {
              this.verificationForm.addControl('isn', new UntypedFormControl('', [Validators.required, Validators.minLength(10)]  ));
            }
            if (this.member.PWA_Secret_Question) {
              this.verificationForm.addControl('question', new UntypedFormControl('', Validators.required));
            }
          }
        });
      }
    });

    this.registrationForm = this.fb.group({
      createPassword: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required, passwordConfirming]],
    });

    setTimeout(() => {
      $('.modal').modal();
    }, 25);
  }

  verification(data: any) {
    if (this.member.PWA_Verify_SIN) {
      if (Number(data.value.sin) !== Number(this.member.SIN)) {
        // tslint:disable-next-line: max-line-length
        toast({ html: '<span> <i class="material-icons left">error_outline</i> Invalid Social Insurance Number ! </span>', classes: 'red' });
        return;
      }
    }

    if (this.member.PWA_Verify_ISN) {
      if (Number(data.value.isn) !== Number(this.member.IndianStatusNo)) {
        toast({ html: '<span> <i class="material-icons left">error_outline</i> Invalid Indian Status Number ! </span>', classes: 'red' });
        return;
      }
    }

    if (this.member.PWA_Secret_Question) {
      const answer = this.member.PWA_Secret_Answer;
      const response = data.value.question;
      if (response.replace(/\s/g, '').toLowerCase() !== answer.replace(/\s/g, '').toLowerCase()) {
        toast({ html: '<span> <i class="material-icons left">error_outline</i> Invalid Answer ! </span>', classes: 'red' });
        return;
      }
    }

    toast({ html: '<span> <i class="material-icons left">check_circle_outline</i> Verification Success. </span> ', classes: 'green' });

    this.step1 = false;
    this.step2 = true;
  }

  register(data: any) {
    this.authService.emailSignUpWithOtherRoles(this.member, data.value.createPassword, this.additionalRoles).then(() => {
      {
        this.otherRolesService.deleteInvitation(this.applicant.id);
      }
    }).catch(error => {
      toast({ html: error.message, classes: 'red' });
    });
  }

  dateToShortFormat(value: Date) {
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const day = value.getDate();
    const monthIndex = value.getMonth();
    const year = value.getFullYear();
    return day + '/' + monthNames[monthIndex] + '/' + year;
  }

  backClicked() {
    this.router.navigate(['/']);
  }
}