import { Component, OnInit } from '@angular/core';
import { ActivatedRoute ,Router} from '@angular/router';
//import { EmbedVideoService } from 'ngx-embed-video';
import { DepartmentNewsService } from 'src/app/services/firebase/department-news.service';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { DepartmentService } from 'src/app/services/firebase/department.service';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';

@Component({
  selector: 'app-dep-news-list',
  templateUrl: './dep-news-list.component.html',
  styleUrls: ['./dep-news-list.component.css']
})
export class DepNewsListComponent implements OnInit {
  nationOfuse = environment.firstNation.name;
  defaultThemeColor = environment.appTheme.themeColor;
  themeColor: any;
  buttonType: any;
  fontColor = environment.appTheme.fontColor;
  model: any[];
  id: any;
  depname: any;
  showMsg = false;
  nationOfUse =  environment.firstNation.name;
  videoId: any;


  constructor(private settingsService: AppSettingsService, private departmentNewsService: DepartmentNewsService, private location: Location,private depservice: DepartmentService,private router: Router,
              // private embedService: EmbedVideoService, 
              private route: ActivatedRoute) { }

  ngOnInit() {
    this.themeColor = this.settingsService.getAppThemeColor() ? this.settingsService.getAppThemeColor() : this.defaultThemeColor;
    this.buttonType = this.settingsService.getButtonType() ? this.settingsService.getButtonType() : 'rectangularButton';

    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });

    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });
    if (this.id) {
      this.depservice.getDepartmentByID(this.id).valueChanges().subscribe(dep => {
        if (dep) {
          this.depname = dep.name;
        }
      });
    }

    if (this.id) {

    this.departmentNewsService.getDepNewsList(this.id).valueChanges().subscribe(x => {
      if (x) {
        this.model = x;
        this.model = this.model.filter(data => data.published === true);
        this.model.sort((a, b) => (a.index < b.index ? -1 : 1));
        this.model.forEach(el => {
          if (el.type === 'Video') {
            const link = el.videoLink;
            el.videoLink = this.embedVideo(link);
          }
          if (el.visibility === undefined) {
            el.visibility = 'Public';
          }
        });
      }
    });
  }
  }

  embedVideo(videoLink) {
    const videoIdMatch = videoLink.match(/(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([^&]+)|(?:https?:\/\/)?(?:www\.)?youtu\.be\/([^?]+)/);
    this.videoId = videoIdMatch ? (videoIdMatch[1] || videoIdMatch[2]) : '';
    return this.videoId;
  }

  backClicked() {
    this.router.navigate(['/departments']);
  }

}
