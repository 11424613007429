import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { EventsService } from 'src/app/services/firebase/events.service';
import { environment } from 'src/environments/environment';
import { finished } from 'stream';



@Component({
  selector: 'app-event-calendar',
  templateUrl: './event-calendar.component.html',
  styleUrls: ['./event-calendar.component.css'],

})
export class EventCalendarComponent implements OnInit {

  model: any[];
  themeColor = environment.appTheme.themeColor;
  nationName = environment.firstNation.displayName;
  isMember = false;
  showLoader = false;

// ========== remove once finished =========
  bodyBgColor: any;
  setting: any;
  defaultBgColor = '#ffffff';
  defaultThemeColor = environment.appTheme.themeColor;


  constructor(private eventService: EventsService, private router: Router ,public appSettingService: AppSettingsService) {
   
        // =============================================   remove once added to app-menu =============
    
   }

  ngOnInit() {
    this.showLoader = true;
    this.eventService.getPublicEventsList().valueChanges().subscribe(
      evnt => {
        if (evnt) {
          if (evnt.length > 0 ) {
            this.model = evnt;
            this.model.sort((a, b) => (a.displayOrder > b.displayOrder ? 1 : -1));
            this.showLoader = false;
          }
          this.showLoader = false;
        }
      }
    );
  }


  back() {
    localStorage.setItem('FromPath', 'NewsEvents');
    this.router.navigate(['/home-sub-menu']);
  }
}
