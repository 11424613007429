<app-firebase-loading *ngIf="loading"></app-firebase-loading>

<div class="row col s12" *ngIf="surveyModel">
  <div class="row card-panel white col s12" *ngIf="!participationList || participationList.length == 0">
    <p class="center"> <strong class="red-text"> <i class="material-icons">info</i> No Result Found.</strong> </p>
  </div>

  <div *ngIf="participationList.length > 0">
    <div class="row" *ngFor="let q of resultData; let i=index">
      <div class="card-panel white col s12">
        <h5><strong><span class="green-text">{{q.questionNumber}} : </span> {{q.question}} ? </strong></h5>
        <br />
  
        <div *ngIf="q.answerType === 'Multiple Choice' || q.answerType === 'Scale' || q.answerType === 'Dropdown' || q.answerType === 'Checkboxes' ">
          <div style="display: block">
            <canvas baseChart [datasets]="q.data" [labels]="q.labels" [options]="barChartOptions"
              [plugins]="barChartPlugins" [legend]="barChartLegend" [colors]="barChartColors" [chartType]="barChartType">
            </canvas>
          </div>
        </div>
  
        <div *ngIf="q.answerType === 'Open Ended Answer'">
          <ul class="collection" *ngFor="let ans of q.data">
            <li class="collection-item">{{ans}}</li>
          </ul>
        </div>
      </div>
    </div>
  
    <div class="col s12 card white" *ngIf="comments">
      <div>
        <h5><strong>Comments</strong></h5>
      </div>
      <br>
      <div class="row col s12" *ngIf="comments.length == 0" >
          <p class="center"> <strong class="red-text"> <i class="material-icons" >info</i> No Comments Found.</strong> </p>
      </div>
      <div *ngIf="comments.length > 0" class="row col s12">
        <ul class="collection" *ngFor="let c of comments">
          <li class="collection-item">{{c}}</li>
        </ul>
      </div>
    </div>
  </div>
</div>