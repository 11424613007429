import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, FormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { PollsAndSurveyModel, SurveyQuestion, Question } from 'src/app/models/PollsAndSurveyModel';
import { PollsAndSurveyParticipationService } from 'src/app/services/firebase/polls-and-survey-participation.service';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { Options } from '@angular-slider/ngx-slider';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-polls-survey-view',
  templateUrl: './polls-survey-view.component.html',
  styleUrls: ['./polls-survey-view.component.css']
})

export class PollsSurveyViewComponent implements OnInit {

  @Input() clientID: any;
  @Input() model: PollsAndSurveyModel;
  @Output() participationSaved: EventEmitter<string> = new EventEmitter<string>();

  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  surveyForm: UntypedFormGroup;
  answersArray: UntypedFormArray;
  visibleStepIndex = 0;
  totalQuestions = 0;
  submitDisabled = false;
  showComment = false;
  leaveComment = false;

  options: Options = {
    floor: 0,
    ceil: 10,
    showTicks: true,
    step: 1,
  }

  constructor(private fb: UntypedFormBuilder, 
    private participationService: PollsAndSurveyParticipationService, 
    private location: Location) { }

  ngOnInit() {
    if (this.model) {
      this.surveyForm = this.fb.group({
        answerItems: this.fb.array([]),
        leaveComment: ['', [Validators.required]],
        comment: [''],
      });
      this.surveyForm.get('leaveComment').valueChanges.subscribe(x => {
        if (x == 'yes') {
          this.surveyForm.get('comment').setValidators([Validators.required]);
        } else {
          this.surveyForm.get('comment').clearValidators();
        }
        this.surveyForm.get('comment').updateValueAndValidity();
      });
      this.totalQuestions = this.model.questions.length;
      this.model.questions.forEach(question => {
        if (question.answerType === 'Open Ended Answer') {
          this.addAnswer(question);
        } else {
          this.addAnswer2(question);
        }
        setTimeout(() => {
          $('select').formSelect();
        }, 25);
      });
    }
  }

  addAnswer(questionModel: SurveyQuestion) {
    this.answersArray = this.surveyForm.get('answerItems') as UntypedFormArray;
    this.answersArray.push(this.createAnswer(questionModel));
  }

  createAnswer(questionModel: SurveyQuestion) {
    return this.fb.group({
      questionNumber: [questionModel.questionNumber],
      question: [questionModel.question],
      questionType: [questionModel.answerType],
      allowMultipleAnswers: [questionModel.allowMultipleAnswers],
      answer: ['', [Validators.required]],
    });
  }

  addAnswer2(questionModel: Question) {
    this.answersArray = this.surveyForm.get('answerItems') as UntypedFormArray;
    this.answersArray.push(this.createAnswer2(questionModel));
  }

  createAnswer2(questionModel: Question) {
    let answers = this.fb.array([]);
    questionModel.answers.forEach(x => {
      answers.push(this.addAnswer3(x.answer));
    });
    return this.fb.group({
      questionNumber: [questionModel.questionNumber],
      question: [questionModel.question],
      questionType: [questionModel.answerType],
      allowMultipleAnswers: [questionModel.allowMultipleAnswers],
      answers: answers,
      answer: ['', [Validators.required]],
      // scales: this.fb.group({
      //   start: [questionModel.scales.start]
      //   startLabel:
      //   end:
      //   endLabel:
      // })
    });
  }

  addAnswer3(value) {
    return this.fb.group({
      answer: [value, [Validators.required]]
    });
  }

  answerSelected(question_index, value) {
    const answerArray = this.surveyForm.get('answerItems') as UntypedFormArray;
    if (answerArray.controls[question_index].get('questionType').value === 'Multiple Choice' && !answerArray.controls[question_index].get('allowMultipleAnswers').value) {
      answerArray.controls[question_index].get('answer').patchValue(value);
    }
    if (answerArray.controls[question_index].get('questionType').value === 'Multiple Choice' && answerArray.controls[question_index].get('allowMultipleAnswers').value) {
      var answer = answerArray.controls[question_index].get('answer').value;
      if (answer == "") answerArray.controls[question_index].get('answer').patchValue(value);
      else {
        const answers: string[] = answer.split(',');
        if (answers.includes(value)) {
          const index = answers.indexOf(value);
          answers.splice(index, 1);
          value = answers.join(',');
          //value = [answer].join(',');
        } else {
          value = [answer, value].join(',');
        }
      }
      answerArray.controls[question_index].get('answer').patchValue(value);
    }
  }

  continue(index: any) {
    this.visibleStepIndex = index + 1;
    setTimeout(() => {
      $('select').formSelect();

    }, 25);
  }

  previous(index: any) {
    if (index > 0) { this.visibleStepIndex = index - 1; }
    const answerArray = this.surveyForm.get('answerItems') as UntypedFormArray;
    if (answerArray.controls[index-1].get('questionType').value === 'Multiple Choice') {
      answerArray.controls[index-1].get('answer').patchValue(''); 
    }
      setTimeout(() => {
        $('select').formSelect();
      }, 25);
  }

  prev() {
    this.visibleStepIndex = this.visibleStepIndex - 1;
    setTimeout(() => {
      $('select').formSelect();
    }, 25);
  }

  backToList() {
    this.location.back();
  }

  onCommentYes() {
    this.showComment = true;
    setTimeout(() => {
      M.textareaAutoResize($('#comment'));
    }, 25);

  }
  onCommentNo() {
    this.showComment = false;
    this.surveyForm.get('comment').reset();
  }

  submitSurvey(value: any) {
    this.submitDisabled = true;
    const participation = {
      memberID: this.clientID,
      surveyId: this.model.id,
      //memberName: '',
      comment: value.comment,
      date: new Date(),
      answers: []
    };

    value.answerItems.forEach(element => {
      const answer = {
        questionNumber: element.questionNumber,
        question: element.question,
        questionType: element.questionType,
        allowMultipleAnswers: element.allowMultipleAnswers,
        answers: element.answers ? element.answers : [],
        answer: element.answer,
      };
      if (answer.questionType == 'Scale') answer.answer = answer.answer.toString();
      participation.answers.push(answer);
    });

    this.participationService.newSurveyParticipation(participation).then(x => {
      this.participationSaved.emit('saved');
      this.submitDisabled = false;
    });
  }

  isMultipleQuestionsAllowed(index) {
    return this.model.questions[index].allowMultipleAnswers;
  }

  getOptions(index) {
    return this.model.questions[index].answers;
  }

  getScaleOption(index) {

    const scales = this.model.questions[index].scales;
    const start = Number(scales.start);
    const end = Number(scales.end);
    const startLabel = scales.startLabel;
    const endLabel = scales.endLabel;
    let options: Options = {
      floor: start,
      ceil: end,
      showTicks: true,
      showTicksValues: true,
      step: 1,
      getLegend: (value: number): string => {
        if (value == start) return startLabel;
        else if (value == end) return endLabel
        // else return value.toString();
      },
    }
    return options;
  }

  getData(index) {
    const scales = this.model.questions[index].scales;
    const startLabel = scales.startLabel;
    const endLabel = scales.endLabel;
    const start = Number(scales.start);
    const end = Number(scales.end);
    var items: string[] = [];
    for (var i = start; i <= end; i++) {
      //answers.push(this.fb.group({value: i}));
      items.push(i.toString());
    }
    const data = {
      range: items,
      label: { start: startLabel, end: endLabel }
    }
    return data;
  }

  createRange(index) {
    const scales = this.model.questions[index].scales;
    const start = Number(scales.start);
    const end = Number(scales.end);
    var items: number[] = [];
    for (var i = start; i <= end; i++) {
      //answers.push(this.fb.group({value: i}));
      items.push(i);
    }
    return items;
  }

  getLables(index) {
    const scales = this.model.questions[index].scales;
    const startLabel = scales.startLabel;
    const endLabel = scales.endLabel;
    return { start: startLabel, end: endLabel };
  }

  getColSize(index) {
    const scales = this.model.questions[index].scales;
    var start = Number(scales.start);
    var end = Number(scales.end);
    var items = end - start;
    items = items + 3;
    // if(items>5){
    //   return `col m${Math.floor(10/items/2)} s${Math.floor(10/items/2)}`;
    // }else{
    //   return `col m${Math.floor(12/items)} s${Math.floor(12/items/2)}`;
    // }
    if (items > 7) {
      return `col s${12 / (items) * 2}`;
    } else {
      return `col s${12 / items}`;
    }
    //  return `col s${12/items}`;
  }

  setScaleValue(index, value) {
    const answerItems = this.surveyForm.get('answerItems') as UntypedFormArray;
    const answer = answerItems.controls[index].get('answer');
    answer.patchValue(value.toString());
  }

  get answers() {
    return (this.surveyForm.get('answerItems') as UntypedFormArray).controls;
  }
}
