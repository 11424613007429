import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApiKeyManagerService } from '../api/apiKeyManager.Service';
import { FCMTokenService } from './FCMToken.service';
import * as firebase from 'firebase/app';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class FCMPushNotificationsService {

  fcmServer = 'https://fcm.googleapis.com/fcm/send';
  fcmTokensList: any;
  membersDeviceTokens: any[];

  // private messaging = firebase.messaging();

  constructor(
    private fcmTokens: FCMTokenService,
    private httpClient: HttpClient,
    private keyManager: ApiKeyManagerService) {
    // this.fcmTokens.getTokenList().valueChanges().subscribe(t => {
    //   if (t) {
    //     if (t.length > 0) {
    //       this.fcmTokensList = t;
    //       this.membersDeviceTokens = [];
    //       this.fcmTokensList.forEach(tkn => {
    //         this.membersDeviceTokens.push(tkn.token);
    //       });
    //     }
    //   }
    // });
  }

  notificationPayload = {
    // registration_ids: undefined,
    to: undefined,
    // to: '/topics/News_Events',
    name: 'notification',
    notification: {
      body: '',
      title: ''
    },
    android: {
      collapse_key: 'a',
      priority: 'high',
      ttl: '3600s',
      notification: {
        channel_id: 'MyNation_default_channel'
      }
    },
    apns: {
      headers: {
        'apns-priority': '10',
      },
      payload: {
        aps: {
          badge: 1,
          sound: 'default'
        }
      }
    },
    webpush:{
      fcm_options: {
        link: '/news'
      }
    },
    data: {
      notification_foreground: 'true',
      notification_body : '',
      notification_title: '',
    }
  };

  sendNotificationToAllTheMembers(title: any, body: any,topic:any) {
    // if (this.membersDeviceTokens) {
    //   this.notificationPayload.registration_ids = this.membersDeviceTokens;
    //   this.notificationPayload.notification.title = title;
    //   this.notificationPayload.notification.body = body;

    //   const headers = new HttpHeaders({
    //     Authorization: this.keyManager.getFCMKey(),
    //     'Content-Type': 'application/json;'
    //   });

    //   this.httpClient.post<any>(this.fcmServer, JSON.stringify(this.notificationPayload), { headers }).subscribe(o => {
    //     // console.log(o);
    //   });
    // }
      let notificationTopic = '/topics/'+topic
      this.notificationPayload.notification.title = title;
      this.notificationPayload.notification.body = body;
      this.notificationPayload.to=notificationTopic
      this.notificationPayload.data.notification_title = title;
      this.notificationPayload.data.notification_body = body;

      const headers = new HttpHeaders({
        Authorization: this.keyManager.getFCMKey(),
        'Content-Type': 'application/json;'
      });

      this.httpClient.post<any>(this.fcmServer, JSON.stringify(this.notificationPayload), { headers }).subscribe(o => {
       
      });

  }
}
