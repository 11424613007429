import { Component, OnInit } from '@angular/core';
import { FormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { CommunityService } from 'src/app/services/api/community.service';
import { MemberService } from 'src/app/services/api/member.service';
import { HouseModel, HousingModel } from 'src/app/models/HousingModel';
import { TempHousingService } from 'src/app/services/firebase/temp-housing.service';
import { toast } from 'materialize-css';
import { BandMemberFilterModel } from 'src/app/models/BandMemberFilterModel';
import { environment } from 'src/environments/environment';
import { ISApplicationService } from 'src/app/services/api/ISApplication.service';
import { HousingService } from 'src/app/services/api/housing.service';
import { DatePipe } from '@angular/common';
import { createMask } from '@ngneat/input-mask';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-new-property',
  templateUrl: './new-property.component.html',
  styleUrls: ['./new-property.component.css']
})
export class NewPropertyComponent implements OnInit {

  provinceOfUse = environment.firstNation.province;
  houseTypes = ['Bungalow', 'Townhouse', 'Condominium', 'Detached House', 'Semi-detached House', 'Mobile Home'];
  relationships = ["Grandson", "Granddaughter", "Niece", "Nephew", "Cousin", "Brother", "Sister", "Relative", 'Other'];
  fuelType = ['Natural Gas', 'Propane', 'Oil', 'Wood', 'Electric'];

  public postalCodeMask = [/[A-Za-z]/, /[0-9]/, /[A-Za-z]/, ' ', /[0-9]/, /[A-Za-z]/, /[0-9]/];
  public dateMask = [/[0-3]/, /[0-9]/, '/', /[0-1]/, /[0-9]/, '/', /[1-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  public dateformat = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
 // public yearMask = [/[1-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  public ageMask = [/[1-9]/, /[0-9]/];

  yearMask = createMask<string>({
    mask: '9999',
    placeholder: 'YYYY',
    definitions: {
      '9': {
        validator: '[0-9]',
        cardinality: 1,
      },
    },
  });

  loading = false;
  p: number = 1;
  propertyForm: UntypedFormGroup;
  familyForm: UntypedFormGroup;
  communities: any[];
  CMHC = false;
  members: any[];
  member: any;
  model: HouseModel;
  filters = {
    firstName: '',
    lastName: ''
  }
  filtredMembers: any[];
  familyMembers: any[] = [];
  houseNo = false;
  lotBlock = false;
  landDesc = false;
  other = false;
  ownerSelected = false;
  rented = false;
  financed = false;
  step1 = true;
  step2 = false;
  addFamily = false;
  yearSelected = false;
  ageOfHouse: number;

  constructor(private fb: UntypedFormBuilder,
    private communityService: CommunityService,
    private memberService: MemberService,
    private location: Location,
    private ISAppService: ISApplicationService,
    private housingService: HousingService) { }

  ngOnInit() {
    this.loading = true;
    if (JSON.parse(localStorage.getItem("membersList"))) {
      this.members = JSON.parse(localStorage.getItem("membersList"));
      this.loading = false;
      setTimeout(() => {
        $('.modal').modal();
        M.updateTextFields();
      }, 25);
    } else {
      this.memberService.getManageMembersList().subscribe(x => {
        if (x) {
          this.members = x;
          localStorage.setItem("membersList", JSON.stringify(this.members));
        }
        this.loading = false;
        setTimeout(() => {
          $('select').formSelect();
          $('.modal').modal();
          M.updateTextFields();
        }, 25);
      });
    }
    this.communityService.getCommunity().subscribe(com => {
      if (com.length > 0) {
        this.communities = com;
        setTimeout(() => {
          $('select').formSelect();
          M.updateTextFields();
        }, 25);
      }
    });

    this.propertyForm = this.fb.group({
      HoH_id: [null],
      HoH: [''],
      house_number: [''],
      block: [''],
      lot: [''],
      QRT: [''],
      section: [''],
      township: [''],
      range: [''],
      meridian: [''],
      other_location: [''],
      desc_type: ['', [Validators.required]],
      CMHC: ['', [Validators.required]],
      financed: [''],
      rooms: [''],
      community: ['', [Validators.required]],
      style: [''],
      year: [''],
      value: [''],
      size: [''],
      fuel_type: [''],
      fuel_type2: [''],
    });

    this.propertyForm.get('desc_type').valueChanges.subscribe(x => {
      if (x == 'HN') this.propertyForm.get('house_number').setValidators(Validators.required);
      else this.propertyForm.get('house_number').clearValidators();

      if (x == 'LBP') {
        this.propertyForm.get('block').setValidators(Validators.required);
        this.propertyForm.get('lot').setValidators(Validators.required);
      }
      else {
        this.propertyForm.get('block').clearValidators();
        this.propertyForm.get('lot').clearValidators();
      }
      if (x == 'LLD') {
        this.propertyForm.get('QRT').setValidators(Validators.required);
        this.propertyForm.get('section').setValidators(Validators.required);
        this.propertyForm.get('township').setValidators(Validators.required);
        this.propertyForm.get('range').setValidators(Validators.required);
        this.propertyForm.get('meridian').setValidators(Validators.required);
      }
      else {
        this.propertyForm.get('QRT').clearValidators();
        this.propertyForm.get('section').clearValidators();
        this.propertyForm.get('township').clearValidators();
        this.propertyForm.get('range').clearValidators();
        this.propertyForm.get('meridian').clearValidators();
      }
      if (x === 'Other') this.propertyForm.get('other_location').setValidators(Validators.required);
      else this.propertyForm.get('other_location').clearValidators();

      this.propertyForm.get('house_number').updateValueAndValidity();
      this.propertyForm.get('block').updateValueAndValidity();
      this.propertyForm.get('lot').updateValueAndValidity();
      this.propertyForm.get('QRT').updateValueAndValidity();
      this.propertyForm.get('section').updateValueAndValidity();
      this.propertyForm.get('township').updateValueAndValidity();
      this.propertyForm.get('range').updateValueAndValidity();
      this.propertyForm.get('meridian').updateValueAndValidity();
      this.propertyForm.get('other_location').updateValueAndValidity();
    });

    this.model = new HouseModel();

    setTimeout(() => {
      $('select').formSelect();
      $('.modal').modal();
      M.updateTextFields();
    }, 25);
  }

  get house_number() { return this.propertyForm.get('house_number'); }
  get block() { return this.propertyForm.get('block'); }
  get lot() { return this.propertyForm.get('lot'); }
  get QRT() { return this.propertyForm.get('QRT'); }
  get section() { return this.propertyForm.get('section'); }
  get township() { return this.propertyForm.get('township'); }
  get range() { return this.propertyForm.get('range'); }
  get meridian() { return this.propertyForm.get('meridian'); }
  get other_location() { return this.propertyForm.get('other_location'); }
  get building_cost() { return this.propertyForm.get('value'); }
  get ageOfProperty() { return this.propertyForm.get('year'); }

  //select Description Type
  onHouseNo() {
    this.houseNo = true;
    this.lotBlock = false;
    this.block.reset();
    this.lot.reset();
    this.landDesc = false;
    this.QRT.reset();
    this.section.reset();
    this.township.reset();
    this.range.reset();
    this.meridian.reset();
    this.other = false;
    this.other_location.reset();
    setTimeout(() => {
      M.updateTextFields();
    }, 25);
  }

  onLotBlock() {
    this.lotBlock = true;
    this.houseNo = false;
    this.house_number.reset();
    this.landDesc = false;
    this.QRT.reset();
    this.section.reset();
    this.township.reset();
    this.range.reset();
    this.meridian.reset();
    this.other = false;
    this.other_location.reset();
    setTimeout(() => {
      M.updateTextFields();
    }, 25);
  }

  onLandDesc() {
    this.landDesc = true;
    this.lotBlock = false;
    this.block.reset();
    this.lot.reset();
    this.houseNo = false;
    this.house_number.reset();
    this.other = false;
    this.other_location.reset();
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }

  onOther() {
    this.other = true;
    this.landDesc = false;
    this.QRT.reset();
    this.section.reset();
    this.township.reset();
    this.range.reset();
    this.meridian.reset();
    this.lotBlock = false;
    this.block.reset();
    this.lot.reset();
    this.houseNo = false;
    this.house_number.reset();
    setTimeout(() => {
      M.updateTextFields();
    }, 25);
  }

  setTwoNumberDecimal(value: any, control) {
    let amount = parseFloat(value).toFixed(2);
    if (control === 'costOfBuilding') {
      this.building_cost.patchValue(amount);
      this.building_cost.updateValueAndValidity();
    }
  }

  isValidDate(control: UntypedFormControl) {
    let date = control.value;
    const dateformat = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
    if (date) {
      if (!date.match(dateformat) && date.length > 10) {
        const datePipe = new DatePipe('en-US');
        date = datePipe.transform(date, 'dd/MM/yyyy');
      }
      if (date.match(dateformat)) {
        let dateArray = date.split('/');
        let year = dateArray[2];
        let month = dateArray[1] - 1;
        let day = dateArray[0];
        date = new Date(year, month, day);
        const today = new Date();
        if (date.valueOf() < today.valueOf()) {
          return null;
        } else {
          return { 'invalidDate': true }
        }
      }
    }
    return null;
  }

  checkHouseNo(value) {
    let observer = this.housingService.houseNoExists(value).subscribe(x => {
      if (x) {
        toast({ html: 'House Number already exists!', classes: 'red' });
        this.house_number.setErrors({ 'inUse': true });
      } else {
        observer.unsubscribe();
      }
    });
  }

  yearChanged(value) {
    this.yearSelected = true;
    const currentYear = new Date().getFullYear();
    const age = currentYear - Number(value);
    this.ageOfHouse = age;
  }

  changeDates(dateValue) {
    if (dateValue) {
      if (dateValue.match(this.dateformat)) {
        let dateArray = dateValue.split('/');
        let year = dateArray[2];
        let month = dateArray[1] - 1;
        let day = dateArray[0];
        const date = new Date(year, month, day);
        return date;
      } else {
        const date = new Date(dateValue);
        return date;
      }
    } else {
      return null
    }
  }

  //Select HoH for a house
  showMembers() {
    if (!this.members) {
      this.loading = true;
      this.memberService.getMembers().subscribe(x => {
        if (x) {
          this.members = x;
        }
        this.loading = true;
      });
    }
    setTimeout(() => {
      $('.modal').modal();
      $('#memberListModal').modal('open');
    }, 25);

  }

  filterMembers() {
    const model = new BandMemberFilterModel();
    if (this.filters.firstName && this.filters.firstName !== '') { model.firstName = this.filters.firstName; }
    if (this.filters.lastName && this.filters.lastName !== '') { model.lastName = this.filters.lastName; }
    if (this.filters.firstName === '' && this.filters.lastName === '') {
      this.resetfilters();
    } else {
      this.memberService.SearchBandMembers(model).subscribe(x => {
        if (x) {
          this.filtredMembers = x;
        }
      });
    }
  }

  resetfilters() {
    this.filtredMembers = undefined;
    this.filters.firstName = '';
    this.filters.lastName = '';
  }

  selectHoH(member: any) {
    this.member = member;
    this.housingService.hasHouse(member.applicantID).subscribe(x => {
      if (this.member) {
        if (x) {
          $('.modal').modal();
          $('#messageModel').modal('open');
        } else {
          const name = this.member.GivenName + ' ' + this.member.LastName;
          this.propertyForm.get('HoH').patchValue(name);
          this.propertyForm.get('HoH_id').patchValue(this.member.applicantID);
          $('#memberListModal').modal('close');
          this.ownerSelected = true;
          setTimeout(() => {
            M.updateTextFields();
          }, 25);
        }
      }
    });
    $('.modal').modal('close');
    this.resetfilters();
  }

  clearHoH() {
    this.propertyForm.get('HoH').reset();
    this.propertyForm.get('HoH_id').reset()
    this.ownerSelected = false;
  }

  getOccupants() {
    if (this.member) {
      this.memberService.getMember(this.member.applicantID).subscribe(m => {
        if (m) {
          this.member = m[0];
          if (this.member.current_family_comp) {
            this.ISAppService.getFamilyMembers(this.member.current_family_comp).subscribe(family => {
              if (family && family.length > 0) {
                this.familyMembers = family;
              } else {
                this.familyMembers = [];
              }
            });
          }
        }
      });
    }
    this.step1 = false;
    this.step2 = true;
    this.resetfilters();
  }

  submitForm(values) {
    this.model.aid_of_owner = values.HoH_id;
    this.model.house_number = values.house_number ? values.house_number.toString() : '';
    this.model.house_block = values.block ? values.block.toString() : '';
    this.model.house_lot = values.lot ? values.lot.toString() : '';
    this.model.Quarter = values.QRT ? values.QRT : '';
    this.model.section = values.section ? values.section : '';
    this.model.township = values.township ? values.township : '';
    this.model.range = values.range ? values.range : '';
    this.model.median = values.meridian ? values.meridian : '';
    this.model.Other_location = values.other_location ? values.other_location : '';
    this.model.desc_type = values.desc_type;
    this.model.CMHC = values.CMHC;
    this.model.finianced = values.financed;
    this.model.community = values.community;
    this.model.rooms = values.rooms;
    this.model.style = values.style;
    this.model.year = values.year;
    this.model.value = values.value ? values.value.toString() : '';
    this.model.size = values.size ? values.size.toString() : '';
    this.model.Fuel_Type = values.fuel_type;
    this.model.Fuel_Type2 = values.fuel_type2;

    this.member = null;

    this.housingService.saveProprty(this.model).subscribe(res => {
      if (res) {
        toast({ html: 'Property Successfully Saved!', classes: 'green' });
        this.back();
      }
    });
  }

  cancel() {
    $('.modal').modal('close');
  }

  backToPreviousPage() {
    this.step2 = false;
    this.step1 = true;
    this.resetfilters();
    setTimeout(() => {
      $('select').formSelect();
      $('.modal').modal();
      M.updateTextFields();
    }, 25);
  }

  back() {
    this.location.back();
  }

}
