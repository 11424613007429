<div class="row container-pi">
  <h4 class="title-section1 title-member icon-texts">
    <span class="material-symbols-outlined back-icon p-r-1" (click)=" backClicked()">
        arrow_circle_left
    </span>Governance</h4>
  <div class="col s12 m12 l12">
    <div class="" *ngIf="selectedMember">
      <app-gov-chief-detail-shared [model]="selectedMember" [privacy]="'Public'"></app-gov-chief-detail-shared>
    </div>

  </div>