<div class="section p-m-2"></div>
<div class="row container">
    <form class="row col s12" [formGroup]="courseRegistrationForm"
        (ngSubmit)="submitCoursesRegistration(courseRegistrationForm.controls.course.value)">
        <div class="row col s12 teal lighten-2 white-text">
            <h5 class="center-align">Courses Registration</h5>
        </div>
        <br>

        <section></section>
        <div formGroupName="course">
            <div class="col s12 card white">
                <br>
                <div class="row col s12">Do you want to Register Course ?</div>
                <div class="row input-field col s12">
                    <div class="row col s12">
                        <label class="col s6">
                            <input type="radio" value="yes" formControlName="add_course" (change)="onAddCourseYes()" />
                            <span>Yes</span>
                        </label>
                        <label class="col s6">
                            <input type="radio" value="no" formControlName="add_course" (change)="onAddCourseNo()" />
                            <span>No</span>
                        </label>
                    </div>
                </div>
            </div>
            <br>
            <div *ngIf="emptyCourse" class="center-align">
                <span>No Course Registered.</span>
                <i class="col s12 material-icons medium">inventory</i>
            </div>

            <div *ngIf="displayCourse" class="row col s12 card white">
                <h6 class="card-title">Courses Registered</h6>

                <div formArrayName="courses" *ngFor="let c of courseControls.controls; let i = index;">
                    <ul class="collapsible">
                        <li>
                            <div class="row collapsible-header"><i class="material-icons">class</i>
                                <span class="col s4"><strong>Course: </strong>
                                    {{courseControls.controls[i].controls.course_name.value}}</span>
                                <span class="col s3"><strong>Year: </strong>
                                    {{courseControls.controls[i].controls.course_year.value}}</span>
                                <span class="col s3"><strong>Term: </strong>
                                    {{courseControls.controls[i].controls.course_term.value}}</span>
                                <span class="col s2">
                                    <i class="material-icons red-text small" (click)="onDeleteCourse(i)"
                                        style="cursor: pointer;">delete</i>
                                </span>
                            </div>

                            <div class="row collapsible-body">
                                <span class="col s6"> <strong>Course ID : </strong>
                                    {{courseControls.controls[i].controls.course_id.value}}</span>
                                <span class="col s6"> <strong>Institution : </strong>
                                    {{courseControls.controls[i].controls.institution.value}}</span>
                                <span class="col s6"> <strong>Start Date : </strong>
                                    {{courseControls.controls[i].controls.start_date.value}}</span>
                                <span class="col s6"> <strong>End Date : </strong>
                                    {{courseControls.controls[i].controls.end_date.value}}</span>
                            </div>
                        </li>
                    </ul>
                </div>

                <div class="row col s12" style="margin-bottom: 25px; margin-top: 25px;" *ngIf="!addCourse">
                    <p class="center-align">Do You wish to Add Another Course ?</p>
                    <button class="col s12 btn green" type="button" (click)="addAnotherCourse()"><i
                            class="material-icons">add</i>Add Another Course to Register</button>
                </div>
            </div>

            <div class="section"></div>

            <div *ngIf="addCourse" class="row col s12 card white">

                <div class="col s12 card-content">
                    <span class="material-icons right" (click)="closeAddCourse()" style="cursor: pointer;">close</span>
                    <span class="card-title center-align col s12">Add Course to Register</span>

                    <div class="input-field col s12 ">
                        <i class="material-icons prefix pink-text">pin</i> 
                        <select formControlName="course_year" [(ngModel)]='selected_year' class="validate"
                            (change)="courseYearChange($event.target.value)">
                            <option *ngFor="let y of courseYears" [value]="y">{{y}}</option>
                        </select>
                        <label for="course_year">Year<span class="red-text">*</span></label>
                    </div>

                    <div class="input-field col s12 ">
                        <i class="material-icons prefix blue-text">tab</i> 
                        <select formControlName="course_term" [(ngModel)]='selected_term' class="validate"
                            (change)="courseTermChange($event.target.value)">
                            <option *ngFor="let t of courseTerms" [value]="t">{{t}}</option>
                        </select>
                        <label for="course_term">Term<span class="red-text">*</span></label>
                    </div>

                    <div class="input-field col s12 ">
                        <i class="material-icons prefix red-text">class</i>
                        <select formControlName="course_name" [(ngModel)]='selected_course' class="validate"
                            (change)="coursesChange($event.target.value)">
                            <option *ngFor="let c of coursesList" [value]="c">{{c}}</option>
                        </select>
                        <label for="course_name">Course<span class="red-text">*</span></label>
                    </div>

                    <div class="input-field col s12 ">
                        <i class="material-icons prefix purple-text">school</i>
                        <input id="institution" type="text" formControlName="institution" [(ngModel)]='default_school'
                            class="validate">
                        <label for="institution">Institution<span class="red-text">*</span></label>
                    </div>

                    <div class="input-field col s12">
                        <i class="material-icons prefix blue-text">today</i>
                        <!-- <input id="start_date" type="text" formControlName="start_date" class="validate"
                            [textMask]="{mask: dateMask, guide: true}" placeholder="dd/mm/yyyy"> -->
                            <input id="start_date" type="text" formControlName="start_date" class="validate"
                           placeholder="dd/mm/yyyy">
                        <label for="start_date">Start Date <span class="red-text">*</span></label>
                    </div>

                    <div class="input-field col s12">
                        <i class="material-icons prefix yellow-text">today</i>
                        <!-- <input id="end_date" type="text" formControlName="end_date" class="validate"
                            [textMask]="{mask: dateMask, guide: true}" placeholder="dd/mm/yyyy"> -->
                            <input id="end_date" type="text" formControlName="end_date" class="validate"
                           placeholder="dd/mm/yyyy">
                        <label for="end_date">End Date <span class="red-text">*</span></label>
                    </div>
                </div>

                <button class="col s12 btn green" type="button" style="margin-bottom: 15px;"
                    (click)="addMyCourse(courseRegistrationForm.controls.course.value)"
                    [disabled]="!courseRegistrationForm.controls.course.valid">Add Course to Register</button>
            </div>
        </div>

        <!--  *ngIf="!addCourse"-->
        <button *ngIf="hasRegisteredCourses" class="col s12 m5 l4 btn left waves-effect blue" type="button"
            style="margin-bottom: 15px" (click)="onBack()"><i class="material-icons left">arrow_back</i>Back to Home
            Page
        </button>

        <button *ngIf="hasRegisteredCourses" class="col s12 m5 offset-m2 l4 btn right waves-effect blue" type="submit"
            style="margin-bottom: 15px" title="Save">Save Registered Courses <i
                class="material-icons right">send</i></button>
    </form>
</div>
