import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import { faUserLock } from '@fortawesome/free-solid-svg-icons';
import { debounce, elementAt } from 'rxjs/operators';
import { CommunityService } from 'src/app/services/api/community.service';
import { RegistrationRequestService } from 'src/app/services/api/registration-request.service';

import { IncomeSupportFamilyMemberModel } from 'src/app/models/IncomeSupportFamilyMemberModel';
import { IncomeSupportAppModel } from 'src/app/models/IncomeSupportAppModel';
import { FindMemberModel } from 'src/app/models/FindMemberModel';
import { ISApplicationService } from 'src/app/services/api/ISApplication.service';
import { AssetsModel } from 'src/app/models/AssetsModel';
import { IncomeModel } from 'src/app/models/IncomeModel';
import { toast } from 'materialize-css';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { MemberService } from 'src/app/services/api/member.service';
import { Location } from '@angular/common';
import SignaturePad from 'signature_pad';
import { Router } from '@angular/router';
import { EmployabilityModel } from 'src/app/models/EmployabilityModel';
import { EmploymentModel } from 'src/app/models/employmentModel';
import { EmploymentService } from 'src/app/services/api/employment.service';

declare var $: any;
declare var M: any;
declare var navigator: any;

@Component({
  selector: 'app-sk-income-support-app',
  templateUrl: './sk-income-support-app.component.html',
  styleUrls: ['./sk-income-support-app.component.css']
})

export class SKIncomeSupportAppComponent implements OnInit {

  nationofUse = environment.firstNation.name;
  nationName = environment.firstNation.displayName;
  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  provinceOfUse = environment.firstNation.province;
  genders: any = ['Male', 'Female', 'Other'];
  maritalStatus: any = ['Single', 'Married', 'Separated ', 'Widowed', "Common Law"];
  provinces: any = ['Alberta', 'British Columbia', 'Manitoba', 'New Brunswick', 'Newfoundland and Labrador', 'Nova Scotia', 'Ontario', 'Prince Edward Island', 'Quebec', 'Saskatchewan'];
  provincesCode = ['AB', 'BC', "MB", 'NB', "NL", 'NS', 'ON', 'PE', 'QC', 'SK'];

  public postalCodeMask = [/[A-Za-z]/, /[0-9]/, /[A-Za-z]/, ' ', /[0-9]/, /[A-Za-z]/, /[0-9]/];
  public phoneNumberMask = ['(', /[0-9]/, /[0-9]/, /[0-9]/, ')', ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  public dateMask = [/[0-3]/, /[0-9]/, '/', /[0-1]/, /[0-9]/, '/', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  public isnMask = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  public SINMask = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  public healthCareNoMask = [/ \d+ /];

  treatyNo = '';
  bands: any[];
  bandUse: any[];
  bandName = '';
  provinceCode: any;
  bandList: any[];

  AssetsOfAlberta = ["Owing from other persons", "In trust with other persons", "Life Insurance", "Savings", "Trapping Equipment", "Farm equipment", "Other"];
  incomes = ["Wages - including Serverance And Holiday Pay", "Pension (State Pay)", "Workers Compensation", "Unemployment Insurance", "Post Secondary Education Support", "Other Educational Training Allowance", "Fur & Fish Sales, Farming or Small Business", "Band Distribution", "Rental or Land Lease", "Family Support Payments", "Other Income", "Lump Sum or Settlement Within The Past Year"];

  section2Form: UntypedFormGroup;
  section3Form: UntypedFormGroup;
  section31Form: UntypedFormGroup;
  section4Form: UntypedFormGroup;
  section5Form: UntypedFormGroup;
  section6Form: UntypedFormGroup;
  section8Form: UntypedFormGroup;
  section41Form: UntypedFormGroup;  //employability
  section42Form: UntypedFormGroup;  //employment

  selectedValue = '';
  showSpouseInfo = false;
  applicantShowEndDate = false;
  spouseShowEndDate = false;
  maritalStatusSelected = false;
  assetSelected = false;
  incomeSelected = false;
  spouseIncomeSelected = false;

  step = 1;
  step2 = 1;

  addAssets = false;
  displayAssets = false;
  emptyAssets = false;

  addVehicle = false;
  displayVehicle = false;
  emptyVehicle = false;
  HasTwoVehicle = false;   /// at most add two vehicle

  addChildren = false;
  displayChildren = false;
  emptyChildren = false;

  addOtherMemebers = false;
  displayOthers = false;
  emptyOthers = false;

  addIncomes = false;
  displayIncomes = false;
  emptyIncomes = false;

  addSpouseIncomes = false;
  displaySpouseIncomes = false;
  emptySpouseIncomes = false;

  myDpOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'dd/mmm/yyyy',
    markCurrentDay: true,
    closeSelectorOnDateSelect: false,
  };

  applicantID = 0;
  RelationshipList = ["Spouse", "Son", "Daughter", "Grandson", "Granddaughter", "Niece", "Nephew", "Cousin", "Brother", "Sister", "Relative"];
  EduLevelList = ["Preschool", "Up to Grade 7-8 (Sec. I = Grade 8)", "Grade 9-10 (Sec. II-III)", "Grade 11-12 (Sec. IV-V)",
    "Secondary School Diploma or GED", "Some post-secondary training", "Apprenticeship or trades certificate or diploma",
    "College", "CEGEP", "University certificate or diploma", "University - Bachelor Degree", "University – Master’s degree", "University – Doctorate", "Course or certificate", "Other"];

  ApplicantEduLevelList = ["Preschool", "Up to Grade 7-8 (Sec. I = Grade 8)", "Grade 9-10 (Sec. II-III)", "Grade 11-12 (Sec. IV-V)",
    "Secondary School Diploma or GED", "Some post-secondary training", "Apprenticeship or trades certificate or diploma",
    "College", "CEGEP", "University certificate or diploma", "University - Bachelor Degree", "University – Master’s degree", "University – Doctorate", "Course or certificate", "Other"];

  ApplicationModel: IncomeSupportAppModel;
  FamilyMemberModel: IncomeSupportFamilyMemberModel[];
  otherPersonModel: IncomeSupportFamilyMemberModel[];
  tmpMemberModel: any[];
  tmpOtherMemberModel: any[];
  tmpAssetsModel: any[];
  tmpVehicleModel: any[];
  AssetsModel: AssetsModel[];
  oneAssets: AssetsModel;
  NewRequestOfFamilyMemberModel: IncomeSupportFamilyMemberModel[];
  applicantIncomeModel: any[];
  spouseIncomeModel: any[];
  IncomesModel: IncomeModel[];
  aIncome: IncomeModel;

  aMember: IncomeSupportFamilyMemberModel;
  findMemberModel: FindMemberModel;
  memberID = 0;
  spouse_aid = 0;

  applicantCanvas: any;
  spouseCanvas: any;
  witnessCanvas: any;
  applicantSignaturePad: SignaturePad;
  HasApplicantSign = false;

  HasSpouse = false;
  SpouseAdded = false;
  current_application = 0;
  current_family_comp_id = 0;

  profile_updated = false;
  applicant_name = '';
  spouse_name = '';

  //employability
  EmployabilityModel: EmployabilityModel;
  applicant_unemployable = true;
  spouse_unemployable = true;

  //employment
  province = environment.firstNation.province;
  addApplicantExperience = false;
  displayApplicantExperience = false;
  emptyApplicantExperience = false;
  applicant_present_job = false;
  applicantEmploymentModel: any[];

  EmploymentModel: EmploymentModel[];
  aEmployment: EmploymentModel;

  addSpouseExperience = false;
  displaySpouseExperience = false;
  emptySpouseExperience = false;
  spouse_present_job = false;
  spouseEmploymentModel: any[];
  showSpouseHistory = false;
  spouse_visible = false;

  constructor(private formBuilder: UntypedFormBuilder,
    private router: Router,
    private communityService: CommunityService,
    private employmentService: EmploymentService,
    private sqlRegistrationRequest: RegistrationRequestService,
    private authService: AuthService,
    private memberService: MemberService,
    private IncomeSupportApplicationService: ISApplicationService,
    private location: Location) { }

  ngOnInit() {
    if (localStorage.getItem('applicantID')) {
      this.applicantID = Number(localStorage.getItem('applicantID'));
    } else {
      this.applicantID = this.authService.getCurrentApplicantID();
    }

    if (this.applicantID == 0) { this.authService.signOut(); }

    if (localStorage.getItem('Profile_updated') == 'Yes') {
      this.profile_updated = true;
    } else { this.profile_updated = false; }
    localStorage.removeItem('Profile_updated');

    this.section41Form = this.formBuilder.group({
      applicant_earner: ['', [Validators.required]],
      applicant_employability: ['', [Validators.required]],
      applicant_reason: [''],
      applicant_VF: [false],
      spouse_earner: [''],
      spouse_employability: [''],
      spouse_reason: [''],
      spouse_VF: [false],
    });

    this.section42Form = this.formBuilder.group({
      applicant: this.formBuilder.group({
        add_applicant_experience: ['', [Validators.required]],
        applicantHistory: this.formBuilder.array([]),
        applicant_position: [''],
        applicant_employer: [''],
        applicant_city: [''],
        applicant_province: ['Alberta'],
        applicant_description: [''],
        applicant_start_date: [null],
        applicant_present: [''],
        applicant_end_date: [null],
        applicant_leaving_reason: [''],
      }),

      spouse: this.formBuilder.group({
        add_spouse_experience: ['', [Validators.required]],
        spouseHistory: this.formBuilder.array([]),
        spouse_position: [''],
        spouse_employer: [''],
        spouse_city: [''],
        spouse_province: [''],
        spouse_description: [''],
        spouse_start_date: [null],
        spouse_present: [''],
        spouse_end_date: [null],
        spouse_leaving_reason: [''],
      }),
    }),

      this.section42Form.get('applicant.add_applicant_experience').valueChanges.subscribe(value => {
        if (value == 'yes') {
          this.section42Form.get('applicant.applicant_position').setValidators(Validators.required);
          this.section42Form.get('applicant.applicant_employer').setValidators(Validators.required);
          this.section42Form.get('applicant.applicant_city').setValidators(Validators.required);
          this.section42Form.get('applicant.applicant_province').setValidators(Validators.required);
          this.section42Form.get('applicant.applicant_description').setValidators(Validators.required);
          this.section42Form.get('applicant.applicant_start_date').setValidators(Validators.required);
          this.section42Form.get('applicant.applicant_present').setValidators(Validators.required);
        } else {
          this.section42Form.get('applicant.applicant_position').clearValidators();
          this.section42Form.get('applicant.applicant_employer').clearValidators();
          this.section42Form.get('applicant.applicant_city').clearValidators();
          this.section42Form.get('applicant.applicant_province').clearValidators();
          this.section42Form.get('applicant.applicant_description').clearValidators();
          this.section42Form.get('applicant.applicant_start_date').clearValidators();
          this.section42Form.get('applicant.applicant_present').clearValidators();
        }
        this.section42Form.get('applicant.applicant_position').updateValueAndValidity();
        this.section42Form.get('applicant.applicant_employer').updateValueAndValidity();
        this.section42Form.get('applicant.applicant_city').updateValueAndValidity();
        this.section42Form.get('applicant.applicant_province').updateValueAndValidity();
        this.section42Form.get('applicant.applicant_description').updateValueAndValidity();
        this.section42Form.get('applicant.applicant_start_date').updateValueAndValidity();
        this.section42Form.get('applicant.applicant_present').updateValueAndValidity();
      });

    this.section42Form.get('spouse.add_spouse_experience').valueChanges.subscribe(value => {
      if (value == 'yes') {
        this.section42Form.get('spouse.spouse_position').setValidators(Validators.required);
        this.section42Form.get('spouse.spouse_employer').setValidators(Validators.required);
        this.section42Form.get('spouse.spouse_city').setValidators(Validators.required);
        this.section42Form.get('spouse.spouse_province').setValidators(Validators.required);
        this.section42Form.get('spouse.spouse_description').setValidators(Validators.required);
        this.section42Form.get('spouse.spouse_start_date').setValidators(Validators.required);
        this.section42Form.get('spouse.spouse_present').setValidators(Validators.required);
      } else {
        this.section42Form.get('spouse.spouse_position').clearValidators();
        this.section42Form.get('spouse.spouse_employer').clearValidators();
        this.section42Form.get('spouse.spouse_city').clearValidators();
        this.section42Form.get('spouse.spouse_province').clearValidators();
        this.section42Form.get('spouse.spouse_description').clearValidators();
        this.section42Form.get('spouse.spouse_start_date').clearValidators();
        this.section42Form.get('spouse.spouse_present').clearValidators();
      }
      this.section42Form.get('spouse.spouse_position').updateValueAndValidity();
      this.section42Form.get('spouse.spouse_employer').updateValueAndValidity();
      this.section42Form.get('spouse.spouse_city').updateValueAndValidity();
      this.section42Form.get('spouse.spouse_province').updateValueAndValidity();
      this.section42Form.get('spouse.spouse_description').updateValueAndValidity();
      this.section42Form.get('spouse.spouse_start_date').updateValueAndValidity();
      this.section42Form.get('spouse.spouse_present').updateValueAndValidity();
    });

    this.EmployabilityModel = new EmployabilityModel();
    this.ApplicationModel = new IncomeSupportAppModel();
    this.memberService.getMember(this.applicantID).subscribe(x => {
      if (x) {
        this.ApplicationModel.applicantID = x[0].applicantID;
        this.ApplicationModel.firstName = x[0].GivenName;
        this.ApplicationModel.lastName = x[0].LastName;
        this.applicant_name = x[0].GivenName + ' ' + x[0].LastName;
        //this.ApplicationModel.dob =x[0].DOB;
        if (x[0].DOB) { this.ApplicationModel.dob = x[0].DOB; } else { this.ApplicationModel.dob = ""; }

        if (x[0].Band) { this.ApplicationModel.bandName = x[0].Band; } else { this.ApplicationModel.bandName = ""; }
        if (x[0].IndianStatusNo) { this.ApplicationModel.isn = x[0].IndianStatusNo; } else { this.ApplicationModel.isn = ""; }
        //if (x[0].MaritalStatus) {this.ApplicationModel.maritalStatus =x[0].MaritalStatus; }else{  this.ApplicationModel.maritalStatus = ""; }

        if (x[0].MailAddress) { this.ApplicationModel.address = x[0].MailAddress; } else { this.ApplicationModel.address = ""; }
        if (x[0].province) { this.ApplicationModel.province = x[0].province; } else { this.ApplicationModel.province = ""; }
        if (x[0].PostalCode) { this.ApplicationModel.postalCode = x[0].PostalCode; } else { this.ApplicationModel.postalCode = ""; }
        if (x[0].HealthCareNo) { this.ApplicationModel.healthCareNo = x[0].HealthCareNo; } else { this.ApplicationModel.healthCareNo = ""; }

        if (x[0].Telephone) {
          let phone = "" + x[0].Telephone + "";
          //phone = phone.toString();
          phone = phone.replace("(", "")
          phone = phone.replace(")", "")
          phone = phone.replace("-", "")
          phone = phone.replace(" ", "")
          this.ApplicationModel.telephone = phone;
        } else { this.ApplicationModel.telephone = ""; }

        if (x[0].SIN) { this.ApplicationModel.SIN = x[0].SIN; } else { this.ApplicationModel.SIN = ""; }

        if (x[0].current_application) { this.current_application = x[0].current_application; }
        if (x[0].current_family_comp) { this.current_family_comp_id = x[0].current_family_comp; }

        this.ApplicationModel.previous_employer = '';
        this.ApplicationModel.previous_employment_address = '';
        this.ApplicationModel.previous_employment_position = '';
        this.ApplicationModel.previous_employment_startDate = null;
        this.ApplicationModel.previous_employment_endDate = null;

        this.ApplicationModel.present_employer = '';
        this.ApplicationModel.present_employment_address = '';
        this.ApplicationModel.present_employment_position = '';
        this.ApplicationModel.present_employment_startDate = null;
        this.ApplicationModel.present_employment_endDate = null;
        this.ApplicationModel.present_employment_iscurrent = true;

        this.ApplicationModel.previous_employer_spouse = '';
        this.ApplicationModel.previous_employment_address_spouse = '';
        this.ApplicationModel.previous_employment_position_spouse = '';
        this.ApplicationModel.previous_employment_startDate_spouse = null;
        this.ApplicationModel.previous_employment_endDate_spouse = null;

        this.ApplicationModel.present_employer_spouse = '';
        this.ApplicationModel.present_employment_address_spouse = '';
        this.ApplicationModel.present_employment_position_spouse = '';
        this.ApplicationModel.present_employment_startDate_spouse = null;
        this.ApplicationModel.present_employment_endDate_spouse = null;
        this.ApplicationModel.present_employment_iscurrent_spouse = true;

        this.ApplicationModel.education = '';
        this.ApplicationModel.education_startDate = null;
        this.ApplicationModel.education_endDate = null;

        this.ApplicationModel.education_spouse = '';
        this.ApplicationModel.education_startDate_spouse = null;
        this.ApplicationModel.education_endDate_spouse = null;

        this.sqlRegistrationRequest.getTreatyNumber().subscribe(t => {
          if (t) {
            this.treatyNo = t;
            this.communityService.getBands().subscribe(band => {
              if (band) {
                this.bands = band;

                this.getBandListFromProvinceCode(this.provinceOfUse);

                // this.bandUse = this.bands.filter(b => b.TreatyNo == this.treatyNo);
                this.bandUse = this.bands.filter(b => b.treatyno == this.treatyNo);
                if (this.bandUse && this.bandUse.length > 0) {
                  this.bandName = this.bandUse[0].Band;
                }

                const defDate = new Date();
                defDate.setFullYear(defDate.getFullYear() - 30);
                const defaultDate = `${defDate.getDate()}/${defDate.getMonth() + 1}/${defDate.getFullYear()}`;

                this.section2Form = this.formBuilder.group({
                  previous_address: this.formBuilder.group({
                    applicant: this.formBuilder.group({
                      address: [''],
                      address_from: [null],
                      address_to: [null],
                    }),
                    spouse: this.formBuilder.group({
                      spouse_address: [''],
                      spouse_address_from: [null],
                      spouse_address_to: [null],
                    }),
                  }),
                });

                this.section31Form = this.formBuilder.group({
                  add_vehicle: ['', [Validators.required]],
                  vehicle: this.formBuilder.array([]),
                  model: [''],
                  year_to_make: [''],
                  vehicle_value: ['0.00']    //vehicle_value: [null]
                });

                this.section31Form.get('add_vehicle').valueChanges.subscribe(value => {
                  if (value == 'yes') {
                    this.section31Form.get('model').setValidators(Validators.required);
                    this.section31Form.get('year_to_make').setValidators(Validators.required);
                    this.section31Form.get('vehicle_value').setValidators(Validators.required);
                  } else {
                    this.section31Form.get('model').clearValidators();
                    this.section31Form.get('year_to_make').clearValidators();
                    this.section31Form.get('vehicle_value').clearValidators();
                  }
                  this.section31Form.get('model').updateValueAndValidity();
                  this.section31Form.get('year_to_make').updateValueAndValidity();
                  this.section31Form.get('vehicle_value').updateValueAndValidity();
                });

                this.section3Form = this.formBuilder.group({
                  add_assets: ['', [Validators.required]],
                  assets: this.formBuilder.array([]),
                  asset_type: [''],
                  asset_amount: ['0.00']    //asset_amount: [null]
                });

                this.section3Form.get('add_assets').valueChanges.subscribe(value => {
                  if (value == 'yes') {
                    this.section3Form.get('asset_type').setValidators(Validators.required);
                    this.section3Form.get('asset_amount').setValidators(Validators.required);
                  } else {
                    this.section3Form.get('asset_type').clearValidators();
                    this.section3Form.get('asset_amount').clearValidators();
                  }
                  this.section3Form.get('asset_type').updateValueAndValidity();
                  this.section3Form.get('asset_amount').updateValueAndValidity();
                });

                this.section4Form = this.formBuilder.group({
                  children: this.formBuilder.group({
                    marital_status: ['', [Validators.required]],
                    applicant_edu_level: ['', [Validators.required]],
                    add_children: ['', [Validators.required]],
                    childrens: this.formBuilder.array([]),
                    child_first_name: [''],
                    child_last_name: [''],
                    child_dob: [''],
                    child_relationship: [''],
                    child_edu_level: [''],
                  }),

                  other: this.formBuilder.group({
                    add_others: ['', [Validators.required]],
                    others: this.formBuilder.array([]),
                    other_first_name: [''],
                    other_last_name: [''],
                    other_dob: [''],
                    other_relation: [''],
                    contribution: ['0.00'],
                  })
                });

                this.section4Form.get('children.add_children').valueChanges.subscribe(value => {
                  if (value == 'yes') {
                    this.section4Form.get('children.child_first_name').setValidators(Validators.required);
                    this.section4Form.get('children.child_last_name').setValidators(Validators.required);
                    this.section4Form.get('children.child_dob').setValidators(Validators.required);
                    this.section4Form.get('children.child_relationship').setValidators(Validators.required);
                  } else {
                    this.section4Form.get('children.child_first_name').clearValidators();
                    this.section4Form.get('children.child_last_name').clearValidators();
                    this.section4Form.get('children.child_dob').clearValidators();
                    this.section4Form.get('children.child_relationship').clearValidators();
                  }
                  this.section4Form.get('children.child_first_name').updateValueAndValidity();
                  this.section4Form.get('children.child_last_name').updateValueAndValidity();
                  this.section4Form.get('children.child_dob').updateValueAndValidity();
                  this.section4Form.get('children.child_relationship').updateValueAndValidity();
                });

                this.section4Form.get('other.add_others').valueChanges.subscribe(value => {
                  if (value == 'yes') {
                    this.section4Form.get('other.other_first_name').setValidators(Validators.required);
                    this.section4Form.get('other.other_last_name').setValidators(Validators.required);
                    this.section4Form.get('other.other_dob').setValidators(Validators.required);
                  } else {
                    this.section4Form.get('other.other_first_name').clearValidators();
                    this.section4Form.get('other.other_last_name').clearValidators();
                    this.section4Form.get('other.other_dob').clearValidators();
                  }
                  this.section4Form.get('other.other_first_name').updateValueAndValidity();
                  this.section4Form.get('other.other_last_name').updateValueAndValidity();
                  this.section4Form.get('other.other_dob').updateValueAndValidity();
                });

                this.section5Form = this.formBuilder.group({
                  applicant: this.formBuilder.group({
                    add_income: ['', [Validators.required]],
                    incomes: this.formBuilder.array([]),
                    income_type: [''],
                    income_amount: [],
                    income_date: [null],
                  }),

                  spouse: this.formBuilder.group({
                    add_spouse_income: ['', [Validators.required]],
                    spouse_incomes: this.formBuilder.array([]),
                    spouse_income_type: [''],
                    spouse_income_amount: [],
                    spouse_income_date: [null],
                  }),
                }),

                  this.section5Form.get('applicant.add_income').valueChanges.subscribe(value => {
                    if (value == 'yes') {
                      this.section5Form.get('applicant.income_type').setValidators(Validators.required);
                      this.section5Form.get('applicant.income_amount').setValidators(Validators.required);
                    } else {
                      this.section5Form.get('applicant.income_type').clearValidators();
                      this.section5Form.get('applicant.income_amount').clearValidators();
                    }
                    this.section5Form.get('applicant.income_type').updateValueAndValidity();
                    this.section5Form.get('applicant.income_amount').updateValueAndValidity();
                  });

                this.section5Form.get('spouse.add_spouse_income').valueChanges.subscribe(value => {
                  if (value == 'yes') {
                    this.section5Form.get('spouse.spouse_income_type').setValidators(Validators.required);
                    this.section5Form.get('spouse.spouse_income_amount').setValidators(Validators.required);
                  } else {
                    this.section5Form.get('spouse.spouse_income_type').clearValidators();
                    this.section5Form.get('spouse.spouse_income_amount').clearValidators();
                  }
                  this.section5Form.get('spouse.spouse_income_type').updateValueAndValidity();
                  this.section5Form.get('spouse.spouse_income_amount').updateValueAndValidity();
                });

                this.section6Form = this.formBuilder.group({
                  applicant_signature: [''],
                });

                this.section8Form = this.formBuilder.group({
                  house_shared: ['', [Validators.required]],
                  landlord: ['', [Validators.required]],
                  relation: [''],
                  ownership: ['', [Validators.required]],
                  cmhc_assisted: [[Validators.required]],
                  utilities: ['0.00'],
                  fuel: ['0.00'],
                  maintenance: ['0.00'],
                  garbage: ['0.00'],
                  rent: ['0.00'],
                  comments: ['']
                });

                if (this.current_application > 0) {
                  const id = this.current_application;
                  this.IncomeSupportApplicationService.getApplication(id).subscribe(app => {
                    if (app) {
                      let applicant_education = '';
                      applicant_education = this.memberEducatonLevel(app[0].EDU_LEVEL);

                      const marital_status = app[0].maritalStatus;
                      (<UntypedFormGroup>this.section4Form.controls['children']).controls['marital_status'].patchValue(app[0].maritalStatus);
                      (<UntypedFormGroup>this.section4Form.controls['children']).controls['applicant_edu_level'].patchValue(applicant_education);

                      var tmp;
                      let pre_address_startdate = "";
                      tmp = app[0].previous_address_startDate;
                      if (tmp) { pre_address_startdate = this.formatDates(app[0].previous_address_startDate); }
                      let pre_address_enddate = "";
                      tmp = app[0].previous_address_endDate;
                      if (tmp) { pre_address_enddate = this.formatDates(app[0].previous_address_endDate); }

                      (<UntypedFormGroup>(<UntypedFormGroup>this.section2Form.controls['previous_address']).controls['applicant']).controls['address'].patchValue(app[0].previous_address);
                      (<UntypedFormGroup>(<UntypedFormGroup>this.section2Form.controls['previous_address']).controls['applicant']).controls['address_from'].patchValue(pre_address_startdate);
                      (<UntypedFormGroup>(<UntypedFormGroup>this.section2Form.controls['previous_address']).controls['applicant']).controls['address_to'].patchValue(pre_address_enddate);

                      if (marital_status == 'Married' || marital_status == "Common Law") {
                        this.HasSpouse = true;
                        let pre_address_startdate_spouse = "";
                        tmp = app[0].previous_address_startDate;
                        if (tmp) { pre_address_startdate_spouse = this.formatDates(app[0].previous_address_startDate_spouse); }
                        let pre_address_enddate_spouse = "";
                        tmp = app[0].previous_address_endDate;
                        if (tmp) { pre_address_enddate_spouse = this.formatDates(app[0].previous_address_endDate_spouse); }

                        (<UntypedFormGroup>(<UntypedFormGroup>this.section2Form.controls['previous_address']).controls['spouse']).controls['spouse_address'].patchValue(app[0].previous_address_spouse);
                        (<UntypedFormGroup>(<UntypedFormGroup>this.section2Form.controls['previous_address']).controls['spouse']).controls['spouse_address_from'].patchValue(pre_address_startdate_spouse);
                        (<UntypedFormGroup>(<UntypedFormGroup>this.section2Form.controls['previous_address']).controls['spouse']).controls['spouse_address_to'].patchValue(pre_address_enddate_spouse);
                      }

                      if (app[0].car1_value > 0 || app[0].car2_value > 0) {
                        this.section31Form.controls['add_vehicle'].patchValue('yes');
                        if (app[0].car1_value > 0) {
                          let tmpFromgroup = this.formBuilder.group({
                            model: app[0].car1_make,
                            year_to_make: app[0].car1_year,
                            vehicle_value: app[0].car1_value,
                          });
                          this.vehicleControls.push(tmpFromgroup);
                        }

                        if (app[0].car2_value > 0) {
                          let tmpFromgroup = this.formBuilder.group({
                            model: app[0].car2_make,
                            year_to_make: app[0].car2_year,
                            vehicle_value: app[0].car2_value,
                          });
                          this.vehicleControls.push(tmpFromgroup);
                        }

                        this.addVehicle = false;
                        this.emptyVehicle = false;
                        this.displayVehicle = true;
                        setTimeout(() => {
                          $('.collapsible').collapsible();
                          $('select').formSelect();
                          M.updateTextFields();
                        }, 25);
                      } else {
                        this.section31Form.controls['add_vehicle'].patchValue('no');
                        this.onAddVehicleNo();
                      }

                      if (app[0].isYesOfOthers == true) { this.section8Form.controls['house_shared'].patchValue('yes'); }
                      if (app[0].isYesOfOthers == false) { this.section8Form.controls['house_shared'].patchValue('no'); }
                      if (app[0].WhoOfOthers) { this.section8Form.controls['landlord'].patchValue(app[0].WhoOfOthers); }
                      if (app[0].relationship_headofhouse) { this.section8Form.controls['relation'].patchValue(app[0].relationship_headofhouse); }

                      if (app[0].isRentedOfOthers) { this.section8Form.controls['ownership'].patchValue(app[0].isRentedOfOthers); }

                      if (app[0].isYesOfCMHC == true) { this.section8Form.controls['cmhc_assisted'].patchValue('yes'); }
                      if (app[0].isYesOfCMHC == false) { this.section8Form.controls['cmhc_assisted'].patchValue('no'); }

                      if (app[0].UtilitiesOfOthers) { this.section8Form.controls['utilities'].patchValue(app[0].UtilitiesOfOthers); }
                      if (app[0].FuelOfOthers) { this.section8Form.controls['fuel'].patchValue(app[0].FuelOfOthers); }
                      if (app[0].MaintenanceOfOthers) { this.section8Form.controls['maintenance'].patchValue(app[0].MaintenanceOfOthers); }
                      if (app[0].GarbageOfOthers) { this.section8Form.controls['garbage'].patchValue(app[0].GarbageOfOthers); }
                      if (app[0].LoanPMTOfOthers) { this.section8Form.controls['rent'].patchValue(app[0].LoanPMTOfOthers); }
                      if (app[0].comments) { this.section8Form.controls['comments'].patchValue(app[0].comments); }
                    }

                    setTimeout(() => {
                      $('select').formSelect();
                      M.updateTextFields();
                    }, 25);

                    this.IncomeSupportApplicationService.getFamilyMembers(this.current_family_comp_id).subscribe(fm => {
                      if (fm.length > 0) {
                        (<UntypedFormGroup>this.section4Form.controls['children']).controls['add_children'].patchValue('yes');

                        fm.forEach(member => {
                          let tmpFromgroup = this.formBuilder.group({
                            given_name: member.given_name,
                            last_name: member.last_name,
                            dob: this.formatDates(member.DOB),
                            Relationship: member.Relationship,
                            edu_level: this.memberEducatonLevel(member.edu_level),
                            applicantIDOfHead: member.applicantIDOfHead,
                            applicantIDOfChild: member.applicantIDOfChild,
                          });
                          this.childControls.push(tmpFromgroup);
                          if (member.Relationship == 'Spouse') {
                            this.SpouseAdded = true;
                            this.spouse_aid = member.applicantIDOfChild;
                          }
                        });

                        this.emptyChildren = false;
                        this.displayChildren = true;
                        this.addChildren = false;
                        setTimeout(() => {
                          $('.collapsible').collapsible();
                          $('select').formSelect();
                          M.updateTextFields();
                        }, 25);
                      } else {
                        (<UntypedFormGroup>this.section4Form.controls['children']).controls['add_children'].patchValue('no');
                        this.onAddChildrenNo();
                      }

                      this.IncomeSupportApplicationService.getOtherPersons(this.current_application).subscribe(persons => {
                        if (persons.length > 0) {
                          (<UntypedFormGroup>this.section4Form.controls['other']).controls['add_others'].patchValue('yes');

                          persons.forEach(person => {
                            let tmpFromgroup = this.formBuilder.group({
                              given_name: person.given_name,
                              last_name: person.last_name,
                              dob: this.formatDates(person.dob),
                              Relationship: person.Relationship,
                              applicantIDOfHead: person.applicantIDOfHead,
                              applicantIDOfPerson: person.applicantIDOfChild,
                              contribution: person.financial_contribution,
                            });
                            this.othersControls.push(tmpFromgroup);
                            this.addOtherMemebers = false;
                            this.emptyOthers = false;
                            this.displayOthers = true;
                            setTimeout(() => {
                              $('.collapsible').collapsible();
                              $('select').formSelect();
                              M.updateTextFields();
                            }, 25);
                          });
                        } else {
                          (<UntypedFormGroup>this.section4Form.controls['other']).controls['add_others'].patchValue('no');
                          this.onAddOthersNo();
                        }

                        this.IncomeSupportApplicationService.getEmployability(id).subscribe(e => {
                          if (e) {
                            if (e[0].IsNonDisabledEarners) { this.section41Form.controls['applicant_earner'].patchValue('yes'); }
                            if (e[0].IsDisabledEarners) { this.section41Form.controls['applicant_earner'].patchValue('yes'); }

                            if (e[0].IsEmployable) {
                              this.section41Form.controls['applicant_employability'].patchValue('employable');
                              this.applicant_unemployable = false;
                            }
                            if (e[0].IsPartiallyEmployable) {
                              this.section41Form.controls['applicant_employability'].patchValue('partial_employable');
                              this.applicant_unemployable = true;
                            }
                            if (e[0].IsUnemployable) {
                              this.section41Form.controls['applicant_employability'].patchValue('umemployable');
                              this.applicant_unemployable = true;
                            }
                            if (e[0].ReasonHealth) { this.section41Form.controls['applicant_reason'].patchValue('health'); }
                            if (e[0].ReasonSocial) { this.section41Form.controls['applicant_reason'].patchValue('social'); }
                            if (e[0].ReasonVF) { this.section41Form.controls['applicant_VF'].patchValue(true); }

                            if (e[0].IsSpouseNonDisabledEarners) { this.section41Form.controls['spouse_earner'].patchValue('yes'); }
                            if (e[0].IsSpouseDisabledEarners) { this.section41Form.controls['spouse_earner'].patchValue('yes'); }
                            if (e[0].IsSpouseEmployable) {
                              this.section41Form.controls['spouse_employability'].patchValue('employable');
                              this.spouse_unemployable = false;
                            }
                            if (e[0].IsSpousePartiallyEmployable) {
                              this.section41Form.controls['spouse_employability'].patchValue('partial_employable');
                              this.spouse_unemployable = true;
                            }
                            if (e[0].IsSpouseUnemployable) {
                              this.section41Form.controls['spouse_employability'].patchValue('umemployable');
                              this.spouse_unemployable = true;
                            }
                            if (e[0].SpouseReasonHealth) { this.section41Form.controls['spouse_reason'].patchValue('health'); }
                            if (e[0].SpouseReasonSocial) { this.section41Form.controls['spouse_reason'].patchValue('social'); }
                            if (e[0].SpouseReasonVF) { this.section41Form.controls['spouse_VF'].patchValue(true); }
                          }
                          setTimeout(() => {
                            $('select').formSelect();
                            M.updateTextFields();
                          }, 25);

                          this.employmentService.getEmploymentListByMemberID(this.applicantID).subscribe(empList => {
                            if (empList.length > 0) {
                              let applicantHasHistory = false;
                              empList.forEach(a => {
                                applicantHasHistory = true;
                                let current_job = 'no';
                                let end_date = '';
                                let reason = '';
                                if (a.iscurrent) {
                                  current_job = 'yes';
                                  end_date = '';
                                  reason = '';
                                } else {
                                  current_job = 'no';
                                  end_date = this.formatDates(a.end_date);
                                  reason = a.reason;
                                }
                                let tmpFromgroup = this.formBuilder.group({
                                  applicant_position: a.Name,
                                  applicant_employer: a.employer,
                                  applicant_city: a.City,
                                  applicant_province: a.Prov,
                                  applicant_description: a.description,
                                  applicant_start_date: this.formatDates(a.start_date),
                                  applicant_present: current_job,
                                  applicant_end_date: end_date,
                                  applicant_leaving_reason: reason
                                });
                                this.applicantHistoryControls.push(tmpFromgroup);
                              });

                              if (applicantHasHistory) {
                                (<UntypedFormGroup>this.section42Form.controls['applicant']).controls['add_applicant_experience'].patchValue('yes');
                                this.addApplicantExperience = false;
                                this.emptyApplicantExperience = false;
                                this.displayApplicantExperience = true;
                              } else {
                                (<UntypedFormGroup>this.section42Form.controls['applicant']).controls['add_applicant_experience'].patchValue('no');
                                this.onAddApplicantExperienceNo();
                              }
                            }
                            setTimeout(() => {
                              $('select').formSelect();
                              M.updateTextFields();
                            }, 25);

                            this.IncomeSupportApplicationService.getAssets(this.current_application).subscribe(assets => {
                              if (assets.length > 0) {
                                this.section3Form.controls['add_assets'].patchValue('yes');
                                assets.forEach(a => {
                                  let tmpFromgroup = this.formBuilder.group({
                                    asset: a.catagory,
                                    amount: a.value,
                                  });
                                  this.assetControls.push(tmpFromgroup);

                                  this.addAssets = false;
                                  this.emptyAssets = false;
                                  this.displayAssets = true;
                                  setTimeout(() => {
                                    $('.collapsible').collapsible();
                                    $('select').formSelect();
                                    M.updateTextFields();
                                  }, 25);
                                });
                              } else {
                                this.section3Form.controls['add_assets'].patchValue('no');
                                this.onAddAssetsNo();
                              }
                              this.IncomeSupportApplicationService.getIncomes(this.current_application).subscribe(incomes => {
                                if (incomes.length > 0) {
                                  let applicantHasIncome = false;
                                  let spouseHasIncome = false;
                                  incomes.forEach(a => {
                                    if (a.Fam_Mem_Type == 'A') {
                                      applicantHasIncome = true;

                                      let tmpFromgroup = this.formBuilder.group({
                                        income: a.catagory,
                                        amount: a.value,
                                        date_received: this.formatDates(a.date_recieved),
                                      });
                                      this.incomeControls.push(tmpFromgroup);
                                    }

                                    if (a.Fam_Mem_Type == 'S') {
                                      spouseHasIncome = true;
                                      let tmpFromgroup = this.formBuilder.group({
                                        income: a.catagory,
                                        amount: a.value,
                                        date_received: this.formatDates(a.date_recieved),
                                      });
                                      this.spouseIncomeControls.push(tmpFromgroup);
                                    }
                                  });

                                  if (applicantHasIncome) {
                                    (<UntypedFormGroup>this.section5Form.controls['applicant']).controls['add_income'].patchValue('yes');
                                    this.addIncomes = false;
                                    this.emptyIncomes = false;
                                    this.displayIncomes = true;
                                  } else {
                                    (<UntypedFormGroup>this.section5Form.controls['applicant']).controls['add_income'].patchValue('no');
                                    this.onAddIncomesNo();
                                  }

                                  if (spouseHasIncome) {
                                    (<UntypedFormGroup>this.section5Form.controls['spouse']).controls['add_spouse_income'].patchValue('yes');
                                    this.addSpouseIncomes = false;
                                    this.emptySpouseIncomes = false;
                                    this.displaySpouseIncomes = true;
                                  } else {
                                    (<UntypedFormGroup>this.section5Form.controls['spouse']).controls['add_spouse_income'].patchValue('no');
                                    this.onAddSpouseIncomeNo();
                                  }

                                  setTimeout(() => {
                                    $('.collapsible').collapsible();
                                    $('select').formSelect();
                                    M.updateTextFields();
                                  }, 25);
                                }

                                if (this.spouse_aid > 0) {
                                  this.employmentService.getEmploymentListByMemberID(this.spouse_aid).subscribe(empList => {
                                    if (empList.length > 0) {
                                      let spouseHasHistory = false;
                                      empList.forEach(a => {
                                        spouseHasHistory = true;
                                        let current_job = 'no';
                                        let end_date = '';
                                        let reason = '';
                                        if (a.iscurrent) {
                                          current_job = 'yes';
                                          end_date = '';
                                          reason = '';
                                        } else {
                                          current_job = 'no';
                                          end_date = this.formatDates(a.end_date);
                                          reason = a.reason;
                                        }

                                        let tmpFromgroup = this.formBuilder.group({
                                          spouse_position: a.Name,
                                          spouse_employer: a.employer,
                                          spouse_city: a.City,
                                          spouse_province: a.Prov,
                                          spouse_description: a.description,
                                          spouse_start_date: this.formatDates(a.start_date),
                                          spouse_present: current_job,
                                          spouse_end_date: end_date,
                                          spouse_leaving_reason: reason
                                        });
                                        this.spouseHistoryControls.push(tmpFromgroup);
                                      });

                                      if (spouseHasHistory) {
                                        (<UntypedFormGroup>this.section42Form.controls['spouse']).controls['add_spouse_experience'].patchValue('yes');
                                        this.addSpouseExperience = false;
                                        this.emptySpouseExperience = false;
                                        this.displaySpouseExperience = true;
                                      } else {
                                        (<UntypedFormGroup>this.section42Form.controls['spouse']).controls['add_spouse_experience'].patchValue('no');
                                        this.onAddSpouseExperienceNo();
                                      }
                                    }
                                  }); 
                                }
                              }); 
                            });  // end of assets
                          }); // end of employment
                        }); // end of employability
                      });  // end of other persons
                    });   // end of family members             
                    setTimeout(() => {
                      $('select').formSelect();
                      M.updateTextFields();
                    }, 25);
                  });  // end of getApplicaiton
                }
                setTimeout(() => {
                  $('select').formSelect();
                  $('.modal').modal();
                  $('.tabs').tabs();
                  $('.collapsible').collapsible();
                  M.updateTextFields();
                }, 25);
              }
            });
          }
        });
      }
    });
  }

  update_profile() {
    this.router.navigate(['member/member-main-home/profile/personal-information']);
  }

  onUpdateProfileYes(e) {
    this.router.navigate(['member/member-main-home/profile/personal-information']);
  }

  onUpdateProfileNo(e) {
    this.profile_updated = true;

    setTimeout(() => {
      $('select').formSelect();
      $('.modal').modal();
      $('.tabs').tabs();
      $('.collapsible').collapsible();
      M.updateTextFields();
    }, 25);
  }

  memberEducatonLevel(level) {
    let applicant_education = '';
    switch (level) {
      case 0: {
        applicant_education = "Preschool";
        break;
      }
      case 1: {
        applicant_education = "Up to Grade 7-8 (Sec. I = Grade 8)";
        break;
      }
      case 2: {
        applicant_education = "Grade 9-10 (Sec. II-III)";
        break;
      }
      case 3: {
        applicant_education = "Grade 11-12 (Sec. IV-V)";
        break;
      }
      case 4: {
        applicant_education = "Secondary School Diploma or GED";
        break;
      }
      case 5: {
        applicant_education = "Some post-secondary training";
        break;
      }
      case 6: {
        applicant_education = "Apprenticeship or trades certificate or diploma";
        break;
      }
      case 7: {
        applicant_education = "College";
        break;
      }
      case 8: {
        applicant_education = "CEGEP";
        break;
      }
      case 9: {
        applicant_education = "University certificate or diploma";
        break;
      }
      case 10: {
        applicant_education = "University - Bachelor Degree";
        break;
      }
      case 11: {
        applicant_education = "University – Master’s degree";
        break;
      }
      case 12: {
        applicant_education = "University – Doctorate";
        break;
      }
      case 13: {
        applicant_education = "Course or certificate";
        break;
      }
      case 14: {
        applicant_education = "Other";
        break;
      }
      default: {
        applicant_education = "";
        break;
      }
    }
    return applicant_education;
  }

  get asset() { return this.section3Form.get('assets'); }
  get asset_type() { return this.section3Form.get('asset_type'); }
  get asset_amount() { return this.section3Form.get('asset_amount'); }
  get assetControls() { return this.section3Form.get('assets') as UntypedFormArray; }

  get vehicle() { return this.section31Form.get('vehicle'); }
  get model() { return this.section31Form.get('model'); }
  get year_to_make() { return this.section31Form.get('year_to_make'); }
  get vehicle_value() { return this.section31Form.get('vehicle_value'); }
  get vehicleControls() { return this.section31Form.get('vehicle') as UntypedFormArray; }


  get childrens() { return this.section4Form.get('children.childrens'); }
  get child_first_name() { return this.section4Form.get('children.child_first_name'); }
  get child_last_name() { return this.section4Form.get('children.child_last_name'); }
  get child_dob() { return this.section4Form.get('children.child_dob'); }
  get child_relationship() { return this.section4Form.get('children.child_relationship'); }
  get marital_status() { return this.section4Form.get('children.marital_status'); }
  get applicant_edu_level() { return this.section4Form.get('children.applicant_edu_level'); }
  get childControls() { return this.section4Form.get('children.childrens') as UntypedFormArray; }

  get other_first_name() { return this.section4Form.get('other.other_first_name'); }
  get other_last_name() { return this.section4Form.get('other.other_last_name'); }
  get other_dob() { return this.section4Form.get('other.other_dob'); }
  get other_relation() { return this.section4Form.get('other.other_relation'); }
  get contribution() { return this.section4Form.get('other.contribution'); }
  get othersControls() { return this.section4Form.get('other.others') as UntypedFormArray; }

  get income_type() { return this.section5Form.get('applicant.income_type'); }
  get income_amount() { return this.section5Form.get('applicant.income_amount'); }
  get income_date() { return this.section5Form.get('applicant.income_date'); }
  get incomeControls() { return this.section5Form.get('applicant.incomes') as UntypedFormArray; }

  get spouse_income_type() { return this.section5Form.get('spouse.spouse_income_type'); }
  get spouse_income_amount() { return this.section5Form.get('spouse.spouse_income_amount'); }
  get spouse_income_date() { return this.section5Form.get('spouse.spouse_income_date'); }
  get spouseIncomeControls() { return this.section5Form.get('spouse.spouse_incomes') as UntypedFormArray; }

  get house_shared() { return this.section8Form.get('house_shared'); }
  get landlord() { return this.section8Form.get('landlord'); }
  get relation() { return this.section8Form.get('relation'); }
  get ownership() { return this.section8Form.get('ownership'); }
  get cmhc_assisted() { return this.section8Form.get('cmhc_assisted'); }
  get utilities() { return this.section8Form.get('utilities'); }
  get fuel() { return this.section8Form.get('fuel'); }
  get maintenance() { return this.section8Form.get('maintenance'); }
  get garbage() { return this.section8Form.get('garbage'); }
  get rent() { return this.section8Form.get('rent'); }
  get comments() { return this.section8Form.get('comments'); }

  get applicant_signature() { return this.section6Form.get('applicant_signature'); }
  //get applicant_date() { return this.section6Form.get('applicant_date'); }

  // Employability
  get applicant_earner() { return this.section41Form.get('applicant.applicant_earner'); }
  get applicant_employability() { return this.section41Form.get('applicant.applicant_employability'); }
  get applicant_reason() { return this.section41Form.get('applicant.applicant_reason'); }
  get applicant_VF() { return this.section41Form.get('applicant.applicant_VF'); }
  get spouse_earner() { return this.section41Form.get('spouse.spouse_earner'); }
  get spouse_employability() { return this.section41Form.get('spouse.spouse_employability'); }
  get spouse_reason() { return this.section41Form.get('spouse.spouse_reason'); }
  get spouse_VF() { return this.section41Form.get('spouse.spouse_VF'); }

  //Employment
  get applicant_position() { return this.section42Form.get('applicant.applicant_position'); }
  get applicant_employer() { return this.section42Form.get('applicant.applicant_employer'); }
  get applicant_city() { return this.section42Form.get('applicant.applicant_city'); }
  get applicant_province() { return this.section42Form.get('applicant.applicant_province'); }
  get applicant_description() { return this.section42Form.get('applicant.applicant_description'); }
  get applicant_start_date() { return this.section42Form.get('applicant.applicant_start_date'); }
  get applicant_present() { return this.section42Form.get('applicant.applicant_present'); }
  get applicant_end_date() { return this.section42Form.get('applicant.applicant_end_date'); }
  get applicant_leaving_reason() { return this.section42Form.get('applicant.applicant_leaving_reason'); }
  get applicantHistoryControls() { return this.section42Form.get('applicant.applicantHistory') as UntypedFormArray; }

  get spouse_position() { return this.section42Form.get('spouse.spouse_position'); }
  get spouse_employer() { return this.section42Form.get('spouse.spouse_employer'); }
  get spouse_city() { return this.section42Form.get('spouse.spouse_city'); }
  get spouse_province() { return this.section42Form.get('spouse.spouse_province'); }
  get spouse_description() { return this.section42Form.get('spouse.spouse_description'); }
  get spouse_start_date() { return this.section42Form.get('spouse.spouse_start_date'); }
  get spouse_present() { return this.section42Form.get('spouse.spouse_present'); }
  get spouse_end_date() { return this.section42Form.get('spouse.spouse_end_date'); }
  get spouse_leaving_reason() { return this.section42Form.get('spouse.spouse_leaving_reason'); }
  get spouseHistoryControls() { return this.section42Form.get('spouse.spouseHistory') as UntypedFormArray; }

  getBandListFromProvinceCode(province) {
    const index = this.provinces.indexOf(province);
    const provinceCode = this.provincesCode[index];
    if (this.bands) {
      const bandInProvince = this.bands.filter(band => provinceCode === this.getProvinceCodeFromAddress(band.address));
      const bandList = [];
      bandInProvince.forEach(b => {
        bandList.push(b.Band);
      });
      this.bandList = bandList;
    }
  }

  getProvinceCodeFromAddress(address: any) {
    if (address) {
      let addr = address.substring(address.length - 11);
      addr = addr.substring(0, 2);
      return addr;
    } else {
      return "";
    }
  }

  onChildIsnChange(e) {
    const isn = e.toString().slice(0, 3)
    const isnArray = isn.split('');
    if (isnArray[0] != '_' && isnArray[1] != '_' && isnArray[2] != '_') {
      const bandUse = this.bands.filter(b => b.TreatyNo == isn);
      if (bandUse && bandUse.length > 0) {
        const bandName = bandUse[0].Band;
        this.section4Form.patchValue({ children: { 'child_band': bandName } });
      } else {
        this.section4Form.patchValue({ children: { 'child_band': '' } });
      }
      setTimeout(() => {
        $('select').formSelect();
        M.updateTextFields();
      }, 25);
    }
  }

  //radio button value changes
  onSpouseCurrentEmploymentYes() {
    this.spouseShowEndDate = false;
  }
  onSpouseCurrentEmploymentNo() {
    this.spouseShowEndDate = true;
    setTimeout(() => {
      M.updateTextFields();
    }, 25);
  }
  onApplicantCurrentEmploymentYes() {
    this.applicantShowEndDate = false;
  }
  onApplicantCurrentEmploymentNo() {
    this.applicantShowEndDate = true;
    setTimeout(() => {
      M.updateTextFields();
    }, 25);
  }

  getApplicantAddress(value: any) {
    (<UntypedFormGroup>(<UntypedFormGroup>this.section2Form.controls['previous_address']).controls['spouse']).controls['spouse_address'].patchValue(value);
    setTimeout(() => {
      M.updateTextFields();
    }, 25);
  }

  getApplicantStartDate(value: any) {
    (<UntypedFormGroup>(<UntypedFormGroup>this.section2Form.controls['previous_address']).controls['spouse']).controls['spouse_address_from'].patchValue(value);
    setTimeout(() => {
      M.updateTextFields();
    }, 25);
  }

  getApplicantEndDate(value: any) {
    (<UntypedFormGroup>(<UntypedFormGroup>this.section2Form.controls['previous_address']).controls['spouse']).controls['spouse_address_to'].patchValue(value);
    setTimeout(() => {
      M.updateTextFields();
    }, 25);
  }

  //Add Children
  onAddChildrenYes() {
    this.addChildren = true;
    setTimeout(() => {
      $('.collapsible').collapsible();
      $('select').formSelect();
      M.updateTextFields();
    }, 25);

    this.emptyChildren = false;
    this.section4Form.get('children.child_first_name').reset();
    this.section4Form.get('children.child_last_name').reset();
    this.section4Form.get('children.child_dob').reset();
    this.section4Form.get('children.child_relationship').reset();
    this.section4Form.get('children.child_edu_level').reset();
  }

  onAddChildrenNo() {
    this.addChildren = false;
    const children = this.childControls;
    while (children.length !== 0) {
      children.removeAt(0);
    }
    this.emptyChildren = true;
    this.displayChildren = false;
    children.setValue([]);
  }

  newChild(values: any): UntypedFormGroup {
    let tmpFromgroup = null;
    this.findMemberModel = new FindMemberModel();
    this.findMemberModel.firstName = values.child_first_name.trim();
    this.findMemberModel.lastName = values.child_last_name.trim();
    this.findMemberModel.dob = this.formatDate2(values.child_dob);    //this.changeDates(values.child_dob);  

    this.memberID = 0;
    this.memberService.findFamilyMemberByName(this.findMemberModel).subscribe(x => {
      if (x) {
        let oneChild = x[0];
        this.memberID = oneChild.applicantID;
        tmpFromgroup = this.formBuilder.group({
          given_name: [values.child_first_name],
          last_name: [values.child_last_name],
          dob: [values.child_dob],
          Relationship: [values.child_relationship],
          edu_level: [values.child_edu_level],
          applicantIDOfHead: this.applicantID,
          applicantIDOfChild: this.memberID,
        });
      } else {
        tmpFromgroup = this.formBuilder.group({
          given_name: '',
          last_name: '',
          dob: null,
          Relationship: '',
          edu_level: '',
          applicantIDOfHead: this.applicantID,
          applicantIDOfChild: 0,
        });
      }
    });

    return tmpFromgroup;
  }

  addChild(values: any): void {
    if (this.SpouseAdded) {
      if (values.child_relationship == 'Spouse') {
        toast({ html: '<p>Warning: Duplicated Spouse !</p> ', classes: 'red' });
        return;
      }
    }

    const children = this.childControls;
    this.findMemberModel = new FindMemberModel();
    this.findMemberModel.firstName = values.child_first_name.trim();
    this.findMemberModel.lastName = values.child_last_name.trim();
    this.findMemberModel.dob = this.formatDate2(values.child_dob);   //this.changeDates(values.child_dob);  

    this.memberID = 0;
    this.memberService.findFamilyMemberByName(this.findMemberModel).subscribe(x => {
      if (x) {
        let oneChild = x[0];
        this.memberID = oneChild.applicantID;

        let tmpFromgroup = this.formBuilder.group({
          given_name: [values.child_first_name.trim()],
          last_name: [values.child_last_name.trim()],
          dob: [values.child_dob],
          Relationship: [values.child_relationship],
          edu_level: [values.child_edu_level],
          applicantIDOfHead: this.applicantID,
          applicantIDOfChild: this.memberID,
        });

        children.push(tmpFromgroup);
      } else {
        toast({ html: '<p>This member does not exist on the system !</p> ', classes: 'red' });
        return;
      }

      if (values.child_relationship == 'Spouse') {
        this.SpouseAdded = true;
        this.spouse_aid = this.memberID;
      }

      this.emptyChildren = false;
      this.displayChildren = true;
      this.addChildren = false;
      setTimeout(() => {
        $('.collapsible').collapsible();
        $('select').formSelect();
      }, 25);
      toast({ html: 'Member Successfully Added!', classes: 'green' });
    });
  }

  addAnotherChild() {
    this.addChildren = true;
    this.section4Form.get('children.child_first_name').reset();
    this.section4Form.get('children.child_last_name').reset();
    this.section4Form.get('children.child_dob').reset();
    this.section4Form.get('children.child_relationship').reset();
    this.section4Form.get('children.child_edu_level').reset();

    setTimeout(() => {
      $('.collapsible').collapsible();
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }

  onDeleteChildern(index) {
    const child = this.childControls;
    const deleted_child = child.controls[index].value.Relationship;
    if (deleted_child == 'Spouse') {
      this.SpouseAdded = false;
      this.spouse_aid = 0;
    }

    child.removeAt(index);
    if (child.length == 0) {
      this.addChildren = false;
      this.displayChildren = false;
      this.emptyChildren = true;
      this.section4Form.patchValue({ 'children': { 'add_children': 'no' } });
    }
    toast({ html: 'Child deleted!', classes: 'red' });
  }

  closeAddChild() {
    this.addChildren = false;
    this.section4Form.get('children.child_first_name').clearValidators();
    this.section4Form.get('children.child_last_name').clearValidators();
    this.section4Form.get('children.child_dob').clearValidators();
    this.section4Form.get('children.child_first_name').updateValueAndValidity();
    this.section4Form.get('children.child_last_name').updateValueAndValidity();
    this.section4Form.get('children.child_dob').updateValueAndValidity();
    if (this.childControls.length == 0) {
      this.section4Form.patchValue({ 'children': { 'add_children': 'no' } });
    }
  }

  //==Add Others========================================
  onAddOthersYes() {
    this.addOtherMemebers = true;
    this.emptyOthers = false;
    this.section4Form.get('other.other_first_name').reset();
    this.section4Form.get('other.other_last_name').reset();
    this.section4Form.get('other.other_relation').reset();
    this.section4Form.get('other.other_dob').reset();
    /// this.section4Form.get('other.contribution').reset();
    setTimeout(() => {
      $('.collapsible').collapsible();/////
      $('select').formSelect();  /////
      M.updateTextFields();
    }, 25);
  }

  onAddOthersNo() {
    this.addOtherMemebers = false;
    const others = this.othersControls;
    while (others.length !== 0) {
      others.removeAt(0);
    }
    this.emptyOthers = true;
    this.displayOthers = false;
    others.setValue([]);
  }

  addOthers(values: any): void {
    const others = this.othersControls;
    this.findMemberModel = new FindMemberModel();
    this.findMemberModel.firstName = values.other_first_name.trim();
    this.findMemberModel.lastName = values.other_last_name.trim();
    this.findMemberModel.dob = this.formatDate2(values.other_dob);
    this.memberID = 0;
    this.memberService.findFamilyMemberByName(this.findMemberModel).subscribe(x => {
      if (x) {
        let oneOther = x[0];
        this.memberID = oneOther.applicantID;

        let tmpFromgroup = this.formBuilder.group({
          given_name: values.other_first_name.trim(),
          last_name: values.other_last_name.trim(),
          dob: [values.other_dob],
          Relationship: [values.other_relation],
          applicantIDOfHead: this.applicantID,
          applicantIDOfPerson: this.memberID,
          contribution: values.contribution,
        });

        others.push(tmpFromgroup);

        this.emptyOthers = false;
        this.displayOthers = true;
        this.addOtherMemebers = false;
        setTimeout(() => {
          $('.collapsible').collapsible();
          $('select').formSelect();
        }, 25);
        toast({ html: 'Other Members Successfully Added!', classes: 'green' });
      } else {
        toast({ html: '<p>This person does not exist on the system !</p> ', classes: 'red' });
        return;
      }
    });
  }

  addAnotherOther() {
    this.addOtherMemebers = true;
    this.section4Form.get('other.other_first_name').reset();
    this.section4Form.get('other.other_last_name').reset();
    this.section4Form.get('other.other_relation').reset();
    this.section4Form.get('other.other_dob').reset();
    this.section4Form.get('other.contribution').reset();
    setTimeout(() => {
      M.updateTextFields();
    }, 25);
  }

  onDeleteOthers(index) {
    const others = this.othersControls;
    others.removeAt(index);
    if (others.length == 0) {
      this.addOtherMemebers = false;
      this.displayOthers = false;
      this.emptyOthers = true;
      this.section4Form.patchValue({ 'other': { 'add_others': 'no' } });
    }
    toast({ html: 'Other Member deleted!', classes: 'red' });
  }

  closeAddOther() {
    this.addOtherMemebers = false;
    this.section4Form.get('other.other_first_name').clearValidators();
    this.section4Form.get('other.other_last_name').clearValidators();
    this.section4Form.get('other.other_dob').clearValidators();
    this.section4Form.get('other.other_first_name').updateValueAndValidity();
    this.section4Form.get('other.other_last_name').updateValueAndValidity();
    this.section4Form.get('other.other_dob').updateValueAndValidity();
    if (this.othersControls.length == 0) {
      this.section4Form.patchValue({ 'other': { 'add_others': 'no' } });
    }
  }

  setTwoNumberDecimal(value: any) {
    let amount = parseFloat(value).toFixed(2);
    (<UntypedFormGroup>this.section4Form.controls['other']).controls['contribution'].patchValue(amount);
  }

  //======================================================================================================
  onApplicantEmployableChanged() {
    this.section41Form.get('applicant_reason').reset('');
    this.section41Form.get('applicant_VF').reset(false);
    this.applicant_unemployable = false;

    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }

  onApplicantPartialEmployableChanged() {
    this.applicant_unemployable = true;
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }

  onApplicantUnemployableChanged() {
    this.applicant_unemployable = true;
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }

  onSpouseEmployableChanged() {
    this.section41Form.get('spouse_reason').reset('');
    this.section41Form.get('spouse_VF').reset(false);
    this.spouse_unemployable = false;

    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }

  onSpousePartialEmployableChanged() {
    this.spouse_unemployable = true;
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }

  onSpouseUnemployableChanged() {
    this.spouse_unemployable = true;
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }

  //Add Vehicle =========================================
  onAddVehicleYes() {
    this.addVehicle = true;
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
    this.emptyVehicle = false;
    this.section31Form.get('model').reset();
    this.section31Form.get('year_to_make').reset();
    this.section31Form.get('vehicle_value').reset();
  }

  onAddVehicleNo() {
    this.addVehicle = false;
    const cars = this.vehicleControls;
    while (cars.length !== 0) {
      cars.removeAt(0);
    }
    this.emptyVehicle = true;
    this.displayVehicle = false;
    cars.setValue([]);
  }

  newVehicle(values: any) {
    return this.formBuilder.group({
      model: [values.model],
      year_to_make: [values.year_to_make],
      vehicle_value: [values.vehicle_value],
    })
  }

  addNewVehicle(values: any) {
    const vehicle = this.vehicleControls;
    vehicle.push(this.newVehicle(values));
    this.emptyVehicle = false;
    this.addVehicle = false;
    this.displayVehicle = true;

    if (vehicle.length == 2) { this.HasTwoVehicle = true; }

    toast({ html: 'Vehicle Successfully Added!', classes: 'green' });
  }

  addAnotherVehicle() {
    this.addVehicle = true;
    this.section31Form.get('model').reset();
    this.section31Form.get('year_to_make').reset();
    this.section31Form.get('vehicle_value').reset();
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }

  onDeleteVehicle(index) {
    const vehicle = this.vehicleControls;
    vehicle.removeAt(index);
    if (vehicle.length == 0) {
      this.addVehicle = false;
      this.displayVehicle = false;
      this.emptyVehicle = true;
      this.section31Form.patchValue({ 'add_vehicle': 'no' });
    }
    this.HasTwoVehicle = false;
    toast({ html: 'Vehicle deleted!', classes: 'red' });
  }

  closeAddVehicle() {
    this.addVehicle = false;
    this.section31Form.get('model').clearValidators();
    this.section31Form.get('year_to_make').clearValidators();
    this.section31Form.get('vehicle_value').clearValidators();
    this.section31Form.get('model').updateValueAndValidity();
    this.section31Form.get('year_to_make').updateValueAndValidity();
    this.section31Form.get('vehicle_value').updateValueAndValidity();
    if (this.vehicleControls.length == 0) {
      this.section31Form.patchValue({ 'add_vehicle': 'no' });
    }
  }

  setCarTwoNumberDecimal(value: any) {
    let amount = parseFloat(value).toFixed(2);
    this.section31Form.controls['vehicle_value'].patchValue(amount);
  }

  //===Add Assets ========================================
  onAddAssetsYes() {
    this.addAssets = true;
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
    this.emptyAssets = false;
    this.section3Form.get('asset_type').reset();
    this.section3Form.get('asset_amount').reset();
  }
  onAddAssetsNo() {
    this.addAssets = false;
    const assets = this.assetControls;
    while (assets.length !== 0) {
      assets.removeAt(0);
    }
    this.emptyAssets = true;
    this.displayAssets = false;
    assets.setValue([]);
  }
  newAsset(values: any) {
    return this.formBuilder.group({
      asset: [values.asset_type],
      amount: [values.asset_amount],
    })
  }
  addNewAssets(values: any) {
    const assets = this.assetControls;
    assets.push(this.newAsset(values));
    this.emptyAssets = false;
    this.addAssets = false;
    this.displayAssets = true;
    toast({ html: 'Asset Successfully Added!', classes: 'green' });
  }
  addAnotherAsset() {
    this.assetSelected = false;
    this.addAssets = true;
    this.section3Form.get('asset_type').reset();
    this.section3Form.get('asset_amount').reset();
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }
  onDeleteAssets(index) {
    const asset = this.assetControls;
    asset.removeAt(index);
    if (asset.length == 0) {
      this.addAssets = false;
      this.displayAssets = false;
      this.emptyAssets = true;
      this.section3Form.patchValue({ 'add_assets': 'no' });
    }
    toast({ html: 'Asset deleted!', classes: 'red' });
  }
  closeAddAsset() {
    this.addAssets = false;
    this.section3Form.get('asset_type').clearValidators();
    this.section3Form.get('asset_amount').clearValidators();
    this.section3Form.get('asset_type').updateValueAndValidity();
    this.section3Form.get('asset_amount').updateValueAndValidity();
    if (this.assetControls.length == 0) {
      this.section3Form.patchValue({ 'add_assets': 'no' });
    }
  }

  setAssetsTwoNumberDecimal(value: any) {
    let amount = parseFloat(value).toFixed(2);
    this.section3Form.controls['asset_amount'].patchValue(amount);
  }

  //Add Incomes
  onAddIncomesYes() {
    this.addIncomes = true;
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
    this.emptyIncomes = false;
    this.section5Form.get('applicant.income_type').reset();
    this.section5Form.get('applicant.income_amount').reset();
    this.section5Form.get('applicant.income_date').reset();
  }
  onAddIncomesNo() {
    this.addIncomes = false;
    const incomes = this.incomeControls;
    while (incomes.length !== 0) {
      incomes.removeAt(0);
    }
    this.emptyIncomes = true;
    this.displayIncomes = false;
    incomes.setValue([]);
  }
  newIncome(values: any) {
    return this.formBuilder.group({
      income: [values.income_type],
      amount: [values.income_amount],
      date_received: [values.income_date]
    });
  }
  addNewIncome(Values: any) {
    const income = this.incomeControls;
    income.push(this.newIncome(Values));
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
    this.emptyIncomes = false;
    this.addIncomes = false;
    this.displayIncomes = true;
    toast({ html: 'Income Successfully Added!', classes: 'green' });
  }
  addAnotherIncome() {
    this.incomeSelected = false;
    this.addIncomes = true;
    this.section5Form.get('applicant.income_type').reset();
    this.section5Form.get('applicant.income_amount').reset();
    this.section5Form.get('applicant.income_date').reset();
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }

  OnDeleteIncome(index) {
    const income = this.incomeControls;
    income.removeAt(index);
    if (income.length == 0) {
      this.addIncomes = false;
      this.displayIncomes = false;
      this.emptyIncomes = true;
      this.section5Form.patchValue({ 'applicant': { 'add_income': 'no' } });
      //this.addIncomes = false;
    }
    toast({ html: 'Income deleted!', classes: 'red' });
  }

  closeAddIncome() {
    this.addIncomes = false;
    this.section5Form.get('applicant.income_type').clearValidators();
    this.section5Form.get('applicant.income_amount').clearValidators();
    this.section5Form.get('applicant.income_type').updateValueAndValidity();
    this.section5Form.get('applicant.income_amount').updateValueAndValidity();
    if (this.incomeControls.length == 0) {
      this.section5Form.patchValue({ 'applicant': { 'add_income': 'no' } });
    }
  }

  setIncomesTwoNumberDecimal(value: any) {
    let amount = parseFloat(value).toFixed(2);
    (<UntypedFormGroup>this.section5Form.controls['applicant']).controls['income_amount'].patchValue(amount);
  }

  setSpouseIncomesTwoNumberDecimal(value: any) {
    let amount = parseFloat(value).toFixed(2);
    (<UntypedFormGroup>this.section5Form.controls['spouse']).controls['spouse_income_amount'].patchValue(amount);
  }

  //Add Spouse Income
  onAddSpouseIncomeYes() {
    this.addSpouseIncomes = true;
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
    this.emptySpouseIncomes = false;
    this.section5Form.get('spouse.spouse_income_type').reset();
    this.section5Form.get('spouse.spouse_income_amount').reset();
    this.section5Form.get('spouse.spouse_income_date').reset();
  }

  onAddSpouseIncomeNo() {
    this.addSpouseIncomes = false;
    const incomes = this.spouseIncomeControls;
    while (incomes.length !== 0) {
      incomes.removeAt(0);
    }
    this.emptySpouseIncomes = true;
    this.displaySpouseIncomes = false;
    incomes.setValue([]);

  }
  newSpouseIncome(values: any) {
    return this.formBuilder.group({
      income: [values.spouse_income_type],
      amount: [values.spouse_income_amount],
      date_received: [values.spouse_income_date]
    })
  }
  addNewSpouseIncome(values: any) {
    this.emptySpouseIncomes = false;
    const income = this.spouseIncomeControls;
    income.push(this.newSpouseIncome(values));
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
    this.addSpouseIncomes = false;
    this.displaySpouseIncomes = true;
    toast({ html: 'Income Successfully Added!', classes: 'green' });
  }
  addAnotherSpouseIncome() {
    this.spouseIncomeSelected = false;
    this.addSpouseIncomes = true;
    this.section5Form.get('spouse.spouse_income_type').reset();
    this.section5Form.get('spouse.spouse_income_amount').reset();
    this.section5Form.get('spouse.spouse_income_date').reset();
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }
  OnDeleteSpouseIncome(index) {
    const income = this.spouseIncomeControls;
    income.removeAt(index);
    if (income.length == 0) {
      this.addSpouseIncomes = false;
      this.displaySpouseIncomes = false;
      this.emptySpouseIncomes = true;
      this.section5Form.patchValue({ 'spouse': { 'add_spouse_income': 'no' } });
    }
    toast({ html: 'Income deleted!', classes: 'red' });
  }
  closeAddSpouseIncome() {
    this.addSpouseIncomes = false;
    this.section5Form.get('spouse.spouse_income_type').clearValidators();
    this.section5Form.get('spouse.spouse_income_amount').clearValidators();
    this.section5Form.get('spouse.spouse_income_type').updateValueAndValidity();
    this.section5Form.get('spouse.spouse_income_amount').updateValueAndValidity();
    if (this.spouseIncomeControls.length == 0) {
      this.section5Form.patchValue({ 'spouse': { 'add_spouse_income': 'no' } });
    }
  }

  //Add Applicant Employment
  onAddApplicantExperienceYes() {
    this.addApplicantExperience = true;
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
    this.emptyApplicantExperience = false;
    this.section42Form.get('applicant.applicant_position').reset();
    this.section42Form.get('applicant.applicant_employer').reset();
    this.section42Form.get('applicant.applicant_city').reset();

    this.section42Form.get('applicant.applicant_province').reset('Alberta');
    this.section42Form.get('applicant.applicant_description').reset();
    this.section42Form.get('applicant.applicant_start_date').reset();
    this.section42Form.get('applicant.applicant_present').reset();
    this.section42Form.get('applicant.applicant_end_date').reset();
    this.section42Form.get('applicant.applicant_leaving_reason').reset();
  }

  onAddApplicantExperienceNo() {
    this.addApplicantExperience = false;
    const historys = this.applicantHistoryControls;
    while (historys.length !== 0) {
      historys.removeAt(0);
    }
    this.emptyApplicantExperience = true;
    this.displayApplicantExperience = false;
    historys.setValue([]);
  }

  newApplicantExperience(values: any) {
    return this.formBuilder.group({
      applicant_position: [values.applicant_position],
      applicant_employer: [values.applicant_employer],
      applicant_city: [values.applicant_city],

      applicant_province: [values.applicant_province],
      applicant_description: [values.applicant_description],
      applicant_start_date: [values.applicant_start_date],
      applicant_present: [values.applicant_present],
      applicant_end_date: [values.applicant_end_date],
      applicant_leaving_reason: [values.applicant_leaving_reason]
    });
  }

  addNewApplicantExperience(Values: any) {
    const historys = this.applicantHistoryControls;
    historys.push(this.newApplicantExperience(Values));
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
    this.emptyApplicantExperience = false;
    this.addApplicantExperience = false;
    this.displayApplicantExperience = true;
    toast({ html: 'Working Experience Successfully Added!', classes: 'green' });
  }

  addApplicantAnotherWorkExperience() {
    //this.incomeSelected = false;
    this.addApplicantExperience = true;

    this.section42Form.get('applicant.applicant_position').reset();
    this.section42Form.get('applicant.applicant_employer').reset();
    this.section42Form.get('applicant.applicant_city').reset();

    this.section42Form.get('applicant.applicant_province').reset('Alberta');
    this.section42Form.get('applicant.applicant_description').reset();
    this.section42Form.get('applicant.applicant_start_date').reset();
    this.section42Form.get('applicant.applicant_present').reset();
    this.section42Form.get('applicant.applicant_end_date').reset();
    this.section42Form.get('applicant.applicant_leaving_reason').reset();

    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }

  OnDeleteApplicantExperience(index) {
    const historys = this.applicantHistoryControls;
    historys.removeAt(index);
    if (historys.length == 0) {
      this.addApplicantExperience = false;
      this.displayApplicantExperience = false;
      this.emptyApplicantExperience = true;
      this.section42Form.patchValue({ 'applicant': { 'add_applicant_experience': 'no' } });
      //this.addIncomes = false;
    }
    toast({ html: 'Working Experience deleted!', classes: 'red' });
  }

  closeApplicantExperience() {
    this.addApplicantExperience = false;

    this.section42Form.get('applicant.applicant_position').clearValidators();
    this.section42Form.get('applicant.applicant_employer').clearValidators();
    this.section42Form.get('applicant.applicant_position').updateValueAndValidity();
    this.section42Form.get('applicant.applicant_employer').updateValueAndValidity();

    if (this.applicantHistoryControls.length == 0) {
      this.section42Form.patchValue({ 'applicant': { 'add_applicant_experience': 'no' } });
    }
  }

  onApplicantPresentYes() {
    this.applicant_present_job = true;
    this.section42Form.get('applicant.applicant_end_date').clearValidators();
    this.section42Form.get('applicant.applicant_end_date').updateValueAndValidity();
    this.section42Form.get('applicant.applicant_end_date').reset();
    this.section42Form.get('applicant.applicant_leaving_reason').reset();
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }

  onApplicantPresentNo() {
    this.section42Form.get('applicant.applicant_end_date').setValidators(Validators.required);
    this.section42Form.get('applicant.applicant_end_date').updateValueAndValidity();
    this.applicant_present_job = false;
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }

  //Add Spouse Employment
  onAddSpouseExperienceYes() {
    this.addSpouseExperience = true;
    setTimeout(() => {
      $('select').formSelect();
      $('.modal').modal();
      $('.tabs').tabs();
      $('.collapsible').collapsible();
      M.updateTextFields();
    }, 25);
    this.emptySpouseExperience = false;
    this.section42Form.get('spouse.spouse_position').reset();
    this.section42Form.get('spouse.spouse_employer').reset();
    this.section42Form.get('spouse.spouse_city').reset();

    this.section42Form.get('spouse.spouse_province').reset('Alberta');
    this.section42Form.get('spouse.spouse_description').reset();
    this.section42Form.get('spouse.spouse_start_date').reset();
    this.section42Form.get('spouse.spouse_present').reset();
    this.section42Form.get('spouse.spouse_end_date').reset();
    this.section42Form.get('spouse.spouse_leaving_reason').reset();
  }

  onAddSpouseExperienceNo() {
    this.addSpouseExperience = false;
    const historys = this.spouseHistoryControls;
    while (historys.length !== 0) {
      historys.removeAt(0);
    }
    this.emptySpouseExperience = true;
    this.displaySpouseExperience = false;
    historys.setValue([]);
  }


  newSpouseExperience(values: any) {
    return this.formBuilder.group({
      spouse_position: [values.spouse_position],
      spouse_employer: [values.spouse_employer],
      spouse_city: [values.spouse_city],

      spouse_province: [values.spouse_province],
      spouse_description: [values.spouse_description],
      spouse_start_date: [values.spouse_start_date],
      spouse_present: [values.spouse_present],
      spouse_end_date: [values.spouse_end_date],
      spouse_leaving_reason: [values.spouse_leaving_reason]
    });
  }

  addNewSpouseExperience(Values: any) {
    const historys = this.spouseHistoryControls;
    historys.push(this.newSpouseExperience(Values));
    setTimeout(() => {
      $('select').formSelect();
      $('.modal').modal();
      $('.tabs').tabs();
      $('.collapsible').collapsible();
      M.updateTextFields();
    }, 25);
    this.emptySpouseExperience = false;
    this.addSpouseExperience = false;
    this.displaySpouseExperience = true;
    toast({ html: 'Working Experience Successfully Added!', classes: 'green' });
  }

  addSpouseAnotherWorkExperience() {
    this.addSpouseExperience = true;

    this.section42Form.get('spouse.spouse_position').reset();
    this.section42Form.get('spouse.spouse_employer').reset();
    this.section42Form.get('spouse.spouse_city').reset();
    this.section42Form.get('spouse.spouse_province').reset('Alberta');
    this.section42Form.get('spouse.spouse_description').reset();
    this.section42Form.get('spouse.spouse_start_date').reset();
    this.section42Form.get('spouse.spouse_present').reset();
    this.section42Form.get('spouse.spouse_end_date').reset();
    this.section42Form.get('spouse.spouse_leaving_reason').reset();

    setTimeout(() => {
      $('select').formSelect();
      $('.modal').modal();
      $('.tabs').tabs();
      $('.collapsible').collapsible();
      M.updateTextFields();
    }, 25);
  }

  OnDeleteSpouseExperience(index) {
    const historys = this.spouseHistoryControls;
    historys.removeAt(index);
    if (historys.length == 0) {
      this.addSpouseExperience = false;
      this.displaySpouseExperience = false;
      this.emptySpouseExperience = true;
      this.section42Form.patchValue({ 'spouse': { 'add_spouse_experience': 'no' } });
    }
    toast({ html: 'Working Experience deleted!', classes: 'red' });
  }

  closeSpouseExperience() {
    this.addSpouseExperience = false;

    this.section42Form.get('spouse.spouse_position').clearValidators();
    this.section42Form.get('spouse.spouse_employer').clearValidators();
    this.section42Form.get('spouse.spouse_position').updateValueAndValidity();
    this.section42Form.get('spouse.spouse_employer').updateValueAndValidity();

    if (this.spouseHistoryControls.length == 0) {
      this.section42Form.patchValue({ 'spouse': { 'add_spouse_experience': 'no' } });
    }
  }


  onSpousePresentYes() {
    this.spouse_present_job = true;

    this.section42Form.get('spouse.spouse_end_date').clearValidators();
    this.section42Form.get('spouse.spouse_end_date').updateValueAndValidity();
    this.section42Form.get('spouse.spouse_end_date').reset();
    this.section42Form.get('spouse.spouse_leaving_reason').reset();

    setTimeout(() => {
      $('select').formSelect();
      $('.modal').modal();
      $('.tabs').tabs();
      $('.collapsible').collapsible();
      M.updateTextFields();
    }, 25);
  }

  onSpousePresentNo() {

    this.section42Form.get('spouse.spouse_end_date').setValidators(Validators.required);
    this.section42Form.get('spouse.spouse_end_date').updateValueAndValidity();
    this.spouse_present_job = false;

    setTimeout(() => {
      $('select').formSelect();
      $('.modal').modal();
      $('.tabs').tabs();
      $('.collapsible').collapsible();
      M.updateTextFields();
    }, 25);
  }

  //on Selects changed
  maritalStatusChange(value: any) {

    if (value == 'Married' || value == "Common Law") {
      this.HasSpouse = true;
    } else {
      this.HasSpouse = false;
    }

    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }

  bandChange(value: any) {
  }

  childRelationshipChange(value: any) {
  }

  childEduLevelChange(value: any) {
    /*
   setTimeout(() => {
     $('select').formSelect();
     M.updateTextFields();
   }, 25);
   */
  }

  onAssetSelected(value: any) {
    this.assetSelected = true;
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }

  onIncomeSelected(value: any) {
    this.incomeSelected = true;
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }

  onSpouseIncomeSelected(value: any) {
    this.spouseIncomeSelected = true;
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }

  setUtilitiesTwoNumberDecimal(value: any) {
    let amount = parseFloat(value).toFixed(2);
    this.section8Form.controls['utilities'].patchValue(amount);
  }

  setFuelTwoNumberDecimal(value: any) {
    let amount = parseFloat(value).toFixed(2);
    this.section8Form.controls['fuel'].patchValue(amount);
  }

  setMaintenanceTwoNumberDecimal(value: any) {
    let amount = parseFloat(value).toFixed(2);
    this.section8Form.controls['maintenance'].patchValue(amount);
  }

  setGarbageTwoNumberDecimal(value: any) {
    let amount = parseFloat(value).toFixed(2);
    this.section8Form.controls['garbage'].patchValue(amount);
  }

  setRentTwoNumberDecimal(value: any) {
    let amount = parseFloat(value).toFixed(2);
    this.section8Form.controls['rent'].patchValue(amount);
  }

  tabSelected(tab) {
    this.step2 = tab;
  }

  onNext2() {
    if (this.step2 == 1) {
      $('.tabs').tabs('select', 'previous_employer');
      this.step2 = 2;
    } else if (this.step2 == 2) {
      $('.tabs').tabs('select', 'present_employer');
      this.step2 = 3;
    }
  }

  onBack2() {
    if (this.step2 == 2) {
      $('.tabs').tabs('select', 'previous_address');
      this.step2 = 1;
    } if (this.step2 == 3) {
      $('.tabs').tabs('select', 'previous_employer');
      this.step2 = 2;
    }
  }

  //navigations
  onNext() {
    if (this.step == 2) { this.step2 = 1; }
    if (this.step == 1) { this.step2 = 3; }
    this.step += 1;

    if (this.step == 6 && !this.HasSpouse) {
      this.onAddSpouseExperienceNo();
      this.section42Form.patchValue({ 'spouse': { 'add_spouse_experience': 'no' } });
    }

    if (this.step == 10 && !this.HasSpouse) {
      this.onAddSpouseIncomeNo();
      this.section5Form.patchValue({ 'spouse': { 'add_spouse_income': 'no' } });
    }

    setTimeout(() => {
      $('select').formSelect();
      $('.modal').modal();
      $('.tabs').tabs();
      $('.collapsible').collapsible();
      M.updateTextFields();
    }, 25);
  }

  onBack() {
    if (this.step > 1) { this.step -= 1; }
    if (this.step == 6 && !this.HasSpouse) {
      this.onAddSpouseExperienceNo();
      this.section42Form.patchValue({ 'spouse': { 'add_spouse_experience': 'no' } });
    }

    if (this.step == 10 && !this.HasSpouse) {
      this.onAddSpouseIncomeNo();
      this.section5Form.patchValue({ 'spouse': { 'add_spouse_income': 'no' } });
    }

    setTimeout(() => {
      $('select').formSelect();
      $('.modal').modal();
      $('.tabs').tabs();
      $('.collapsible').collapsible();
      M.updateTextFields();
    }, 25);
  }

  changeDates(dateValue) {
    if (dateValue) {
      let dateArray = dateValue.split('/');
      let year = dateArray[2];
      let month = dateArray[1] - 1;
      let day = dateArray[0];
      const date = new Date(year, month, day);
      if (date instanceof Date) {
        return date;
      }
    }
    return null;
  }

  formatDates(dateValue) {
    if (dateValue) {
      //   //1987-07-19T00:00:00
      let dateArray = dateValue.split('-');
      let year = dateArray[0];
      let month = dateArray[1];   //- 1;
      let day = dateArray[2].substring(0, 2);
      return day + '/' + month + '/' + year;
    } else {
      return '';
    }
  }

  formatDate2(dateValue) {
    if (dateValue) {
      //   //1987-07-19T00:00:00
      let dateArray = dateValue.split('/');
      let day = dateArray[0];
      let month = dateArray[1];   //- 1;
      let year = dateArray[2].substring(0, 4);
      return year + '-' + month + '-' + day;
    } else {
      return '';
    }
  }

  submitSection2(values: any) {
    this.ApplicationModel.previous_address = values.previous_address.applicant.address;
    if (values.previous_address.applicant.address_from) {
      this.ApplicationModel.previous_address_startDate = this.changeDates(values.previous_address.applicant.address_from).toDateString();
    } else {
      this.ApplicationModel.previous_address_startDate = "";
    }
    if (values.previous_address.applicant.address_to) {
      this.ApplicationModel.previous_address_endDate = this.changeDates(values.previous_address.applicant.address_to).toDateString();
    } else {
      this.ApplicationModel.previous_address_endDate = "";
    }
    /*
    this.ApplicationModel.previous_employer = values.previous_employer.applicant.previous_employer;
    this.ApplicationModel.previous_employment_address = values.previous_employer.applicant.previous_address;
    this.ApplicationModel.previous_employment_position = values.previous_employer.applicant.previous_position;
    this.ApplicationModel.previous_employment_startDate = this.changeDates(values.previous_employer.applicant.previous_from);
    this.ApplicationModel.previous_employment_endDate = this.changeDates(values.previous_employer.applicant.previous_to);

    this.ApplicationModel.present_employer = values.present_employer.applicant.employer;
    this.ApplicationModel.present_employment_address = values.present_employer.applicant.present_address;
    this.ApplicationModel.present_employment_position = values.present_employer.applicant.present_position;
    this.ApplicationModel.present_employment_startDate = this.changeDates(values.present_employer.applicant.present_from);
    this.ApplicationModel.present_employment_endDate= this.changeDates(values.present_employer.applicant.present_to);
    this.ApplicationModel.present_employment_iscurrent = values.present_employer.applicant.iscurrent;  /////////
    */

    this.ApplicationModel.previous_address_spouse = values.previous_address.spouse.spouse_address;
    if (values.previous_address.spouse.spouse_address_from) {
      this.ApplicationModel.previous_address_startDate_spouse = this.changeDates(values.previous_address.spouse.spouse_address_from).toDateString();
    } else {
      this.ApplicationModel.previous_address_startDate_spouse = "";
    }

    if (values.previous_address.spouse.spouse_address_to) {
      this.ApplicationModel.previous_address_endDate_spouse = this.changeDates(values.previous_address.spouse.spouse_address_to).toDateString();
    } else {
      this.ApplicationModel.previous_address_endDate_spouse = "";
    }
    /*
    this.ApplicationModel.previous_employer_spouse = values.previous_employer.spouse.spouse_previous.employer;
    this.ApplicationModel.previous_employment_address_spouse = values.previous_employer.spouse.spouse_previous_address;
    this.ApplicationModel.previous_employment_position_spouse = values.previous_employer.spouse.spouse_previous_position;
    this.ApplicationModel.previous_employment_startDate_spouse = this.changeDates(values.previous_employer.spouse.spouse_previous_from);
    this.ApplicationModel.previous_employment_endDate_spouse = this.changeDates(values.previous_employer.spouse.spouse_previous_to);
    
    this.ApplicationModel.present_employer_spouse = values.present_employer.spouse.spouse_present_employer;
    this.ApplicationModel.present_employment_address_spouse = values.present_employer.spouse.spouse_present_address;
    this.ApplicationModel.present_employment_position_spouse = values.present_employer.spouse.spouse_present_position;
    this.ApplicationModel.present_employment_startDate_spouse = this.changeDates(values.present_employer.spouse.spouse_present_from);
    this.ApplicationModel.present_employment_endDate_spouse= this.changeDates(values.present_employer.spouse.spouse_present_to);
    this.ApplicationModel.present_employment_iscurrent_spouse = values.present_employer.spouse.spouse_iscurrent;  /////////
    */
    this.onNext();
  }

  submitSection31(values: any) {
    //this.tmpVehicleModel = values.vehicle;

    this.ApplicationModel.car1_make = '';
    this.ApplicationModel.car2_make = '';
    this.ApplicationModel.car1_year = '';
    this.ApplicationModel.car2_year = '';
    this.ApplicationModel.car1_value = 0;
    this.ApplicationModel.car2_value = 0;

    const vehicleList = values.vehicle;
    if (vehicleList.length > 0) {
      this.ApplicationModel.car1_make = vehicleList[0].model;
      this.ApplicationModel.car1_year = vehicleList[0].year_to_make;
      this.ApplicationModel.car1_value = vehicleList[0].vehicle_value;
    }

    if (vehicleList.length > 1) {
      this.ApplicationModel.car2_make = vehicleList[1].model;
      this.ApplicationModel.car2_year = vehicleList[1].year_to_make;
      this.ApplicationModel.car2_value = vehicleList[1].vehicle_value;
    }
    this.onNext();
  }

  submitSection3(values: any) {
    //this.model.assets = values.assets;
    this.tmpAssetsModel = values.assets;
    this.onNext();
  }

  submitSection4(values: any, content: string) {
    if (content == "children") {
      if (values.marital_status == 'Married' || values.marital_status == "Common Law") {
        this.HasSpouse = true;
      } else {
        this.HasSpouse = false;
      }
      if (this.HasSpouse && !this.SpouseAdded) {
        toast({ html: 'Please Add Spouse !', classes: 'red' });
        return;
      }

      this.tmpMemberModel = values.childrens;

      this.ApplicationModel.maritalStatus = values.marital_status;
      this.ApplicationModel.EDU_LEVEL = values.applicant_edu_level;

      this.ApplicationModel.spouse_aid = this.spouse_aid;
      this.ApplicationModel.spouseFirstName = '';
      this.ApplicationModel.spouseLastName = '';
      this.ApplicationModel.spouseDob = '';
      this.ApplicationModel.spouseISN = '';
      this.ApplicationModel.spouseHealtCareNo = '';
      this.ApplicationModel.spouseSIN = '';
      this.ApplicationModel.spouseProvince = '';

      if (this.spouse_aid) {
        this.memberService.getMember(this.spouse_aid).subscribe(x => {
          // console.log("spouse: ", x[0]);  
          if (x[0].GivenName) { this.ApplicationModel.spouseFirstName = x[0].GivenName; }
          if (x[0].LastName) { this.ApplicationModel.spouseLastName = x[0].LastName; }
          if (x[0].DOB) { this.ApplicationModel.spouseDob = x[0].DOB; }
          if (x[0].IndianStatusNo) { this.ApplicationModel.spouseISN = x[0].IndianStatusNo; }
          if (x[0].HealthCareNo) { this.ApplicationModel.spouseHealtCareNo = x[0].HealthCareNo; }
          if (x[0].SIN) { this.ApplicationModel.spouseSIN = x[0].SIN; }
          if (x[0].province) { this.ApplicationModel.spouseProvince = x[0].province; }
          this.spouse_name = x[0].GivenName + ' ' + x[0].LastName;
        });
      }
    } else if (content == 'others') {
      // this.model.otherFamilyMembers = values.others;
      this.tmpOtherMemberModel = values.others;
    }
    this.onNext();
  }

  submitSection5(values: any, content: string) {
    if (content == 'applicant') {
      this.applicantIncomeModel = values.incomes;
    }
    else if (content == 'spouse') {
      this.spouseIncomeModel = values.spouse_incomes;
    }
    this.onNext();
  }

  submitSection6(values: any) {
    if (!this.applicantSignaturePad || this.applicantSignaturePad.isEmpty()) {
      toast({ html: 'Applicant Need to Sign The Application !', classes: 'red' });
      return;
    }

    /*
    if (!this.spouseSignaturePad || this.spouseSignaturePad.isEmpty()) {
      toast({ html: 'Spouse Need to Sign The Application !', classes: 'red' });
      return;
    }
  */

    this.ApplicationModel.applicant_signature = this.applicantSignaturePad.toDataURL().replace('data:image/png;base64,', '');
    this.ApplicationModel.applicant_date = new Date();
    /*
    this.ApplicationModel.spouse_signature = this.spouseSignaturePad.toDataURL().replace('data:image/png;base64,', '');
    this.ApplicationModel.spouse_date  = new Date(); 

    if (this.spouseSignaturePad.isEmpty()) {
      this.ApplicationModel.witness_signature = '';
    }else{
      this.ApplicationModel.witness_signature = this.witnessSignaturePad.toDataURL().replace('data:image/png;base64,', '');
    }
    this.ApplicationModel.witness_date  = new Date(); 
    */

    //this.ApplicationModel.comments =""; // values.comments;   
    //console.log("ApplicationModel: ", this.ApplicationModel);

    this.step = 0;
  }

  submitSection8(values: any) {
    this.ApplicationModel.isYesOfOthers = values.house_shared;
    this.ApplicationModel.WhoOfOthers = values.landlord;
    this.ApplicationModel.relationship_headofhouse = values.relation;
    this.ApplicationModel.isRentedOfOthers = values.ownership;
    this.ApplicationModel.isYesOfCMHC = values.cmhc_assisted;
    this.ApplicationModel.LoanPMTOfOthers = Number(values.rent);
    this.ApplicationModel.MaintenanceOfOthers = Number(values.maintenance);
    this.ApplicationModel.GarbageOfOthers = Number(values.garbage);
    this.ApplicationModel.FuelOfOthers = Number(values.fuel);
    this.ApplicationModel.UtilitiesOfOthers = Number(values.utilities);
    this.ApplicationModel.comments = values.comments;

    this.onNext();
  }

  submitSection41(values: any) {
    this.EmployabilityModel.applicantID = this.applicantID;
    this.EmployabilityModel.applicationNo = 0;

    if (values.applicant_earner == 'yes') {
      this.EmployabilityModel.IsNonDisabledEarners = '1';
      this.EmployabilityModel.IsDisabledEarners = '0';
    } else {
      this.EmployabilityModel.IsNonDisabledEarners = '0';
      this.EmployabilityModel.IsDisabledEarners = '1';
    }

    if (values.applicant_employability == 'employable') {
      this.EmployabilityModel.IsEmployable = '1';
      this.EmployabilityModel.IsPartiallyEmployable = '0';
      this.EmployabilityModel.IsUnemployable = '0';
    } else if (values.applicant_employability == 'partial_employable') {
      this.EmployabilityModel.IsEmployable = '0';
      this.EmployabilityModel.IsPartiallyEmployable = '1';
      this.EmployabilityModel.IsUnemployable = '0';
    } else if (values.applicant_employability == 'umemployable') {
      this.EmployabilityModel.IsEmployable = '0';
      this.EmployabilityModel.IsPartiallyEmployable = '0';
      this.EmployabilityModel.IsUnemployable = '1';
    }

    if (values.applicant_reason == 'health') {
      this.EmployabilityModel.ReasonHealth = '1';
      this.EmployabilityModel.ReasonSocial = '0';
    } else if (values.applicant_reason == 'social') {
      this.EmployabilityModel.ReasonHealth = '0';
      this.EmployabilityModel.ReasonSocial = '1';
    } else {
      this.EmployabilityModel.ReasonHealth = '0';
      this.EmployabilityModel.ReasonSocial = '0';
    }

    if (values.applicant_VF) {
      this.EmployabilityModel.ReasonVF = '1';
    } else {
      this.EmployabilityModel.ReasonVF = '0';
    }

    if (values.spouse_earner == 'yes') {
      this.EmployabilityModel.IsSpouseNonDisabledEarners = '1';
      this.EmployabilityModel.IsSpouseDisabledEarners = '0';
    } else {
      this.EmployabilityModel.IsSpouseNonDisabledEarners = '0';
      this.EmployabilityModel.IsSpouseDisabledEarners = '1';
    }

    if (values.spouse_employability == 'employable') {
      this.EmployabilityModel.IsSpouseEmployable = '1';
      this.EmployabilityModel.IsSpousePartiallyEmployable = '0';
      this.EmployabilityModel.IsSpouseUnemployable = '0';
    } else if (values.spouse_employability == 'partial_employable') {
      this.EmployabilityModel.IsSpouseEmployable = '0';
      this.EmployabilityModel.IsSpousePartiallyEmployable = '1';
      this.EmployabilityModel.IsSpouseUnemployable = '0';
    } else if (values.spouse_employability == 'umemployable') {
      this.EmployabilityModel.IsSpouseEmployable = '0';
      this.EmployabilityModel.IsSpousePartiallyEmployable = '0';
      this.EmployabilityModel.IsSpouseUnemployable = '1';
    }

    if (values.spouse_reason == 'health') {
      this.EmployabilityModel.SpouseReasonHealth = '1';
      this.EmployabilityModel.SpouseReasonSocial = '0';
    } else if (values.spouse_reason == 'social') {
      this.EmployabilityModel.SpouseReasonHealth = '0';
      this.EmployabilityModel.SpouseReasonSocial = '1';
    } else {
      this.EmployabilityModel.SpouseReasonHealth = '0';
      this.EmployabilityModel.SpouseReasonSocial = '0';
    }

    if (values.spouse_VF) {
      this.EmployabilityModel.SpouseReasonVF = '1';
    } else {
      this.EmployabilityModel.SpouseReasonVF = '0';
    }

    this.onNext();
  }

  submitSection42(values: any, content: string) {
    if (content == 'applicant') {
      this.applicantEmploymentModel = values.applicantHistory;
    } else if (content == 'spouse') {
      this.spouseEmploymentModel = values.spouseHistory;
    }
    this.onNext();
  }

  onCMHCAssistedYes() {
  }

  onCMHCAssistedNo() {
  }

  BackToApp() {
    this.step = 9;
  }

  SubmitApplication() {
    this.IncomeSupportApplicationService.saveApplicatioin(this.ApplicationModel).subscribe(data => {
      if (data) {
        let searchResult: any[];
        searchResult = data;
        const family_com_id = searchResult[0].family_comp_id;
        const app_id = searchResult[0].application_number;

        this.EmployabilityModel.applicationNo = app_id;
        this.IncomeSupportApplicationService.saveEmployability(this.EmployabilityModel).subscribe(e => {
          if (e) { }
          //applicant employment
          this.EmploymentModel = [];
          if (this.applicantEmploymentModel) {
            this.applicantEmploymentModel.forEach(i => {
              this.aEmployment = new EmploymentModel();
              this.aEmployment.client_id = this.applicantID;

              this.aEmployment.Name = i.applicant_position;
              this.aEmployment.description = i.applicant_description;
              this.aEmployment.employer = i.applicant_employer;
              this.aEmployment.City = i.applicant_city;
              this.aEmployment.Prov = i.applicant_province;

              this.aEmployment.start_date = this.changeDates(i.applicant_start_date);
              this.aEmployment.iscurrent = (i.applicant_present === 'yes') ? true : false;

              if (!this.aEmployment.iscurrent) {
                this.aEmployment.end_date = this.changeDates(i.applicant_end_date);
                this.aEmployment.reason = i.applicant_leaving_reason;
              } else {
                this.aEmployment.end_date = null;   //new Date();
                this.aEmployment.reason = "";
              }
              this.EmploymentModel.push(this.aEmployment);
            });
          } else {
            this.aEmployment = new EmploymentModel();
            this.aEmployment.client_id = this.applicantID;
            this.EmploymentModel.push(this.aEmployment);
          }

          //spouse employment
          if (this.spouseEmploymentModel) {
            this.spouseEmploymentModel.forEach(i => {
              this.aEmployment = new EmploymentModel();
              this.aEmployment.client_id = this.spouse_aid;

              this.aEmployment.Name = i.spouse_position;
              this.aEmployment.description = i.spouse_description;
              this.aEmployment.employer = i.spouse_employer;
              this.aEmployment.City = i.spouse_city;
              this.aEmployment.Prov = i.spouse_province;

              this.aEmployment.start_date = this.changeDates(i.spouse_start_date);
              this.aEmployment.iscurrent = (i.spouse_present === 'yes') ? true : false;

              if (!this.aEmployment.iscurrent) {
                this.aEmployment.end_date = this.changeDates(i.spouse_end_date);
                this.aEmployment.reason = i.spouse_leaving_reason;
              } else {
                this.aEmployment.end_date = null;   //new Date();
                this.aEmployment.reason = "";
              }
              this.EmploymentModel.push(this.aEmployment);
            });
          } else {
            this.aEmployment = new EmploymentModel();
            this.aEmployment.client_id = this.spouse_aid;
            this.EmploymentModel.push(this.aEmployment);
          }
          this.employmentService.saveWorkExperiences(this.EmploymentModel).subscribe(x => {
            if (x) { }

            this.FamilyMemberModel = [];
            this.aMember = new IncomeSupportFamilyMemberModel();
            this.aMember.applicantIDOfHead = this.applicantID;
            this.aMember.applicantIDOfChild = this.applicantID;
            this.aMember.given_name = this.ApplicationModel.firstName;
            this.aMember.last_name = this.ApplicationModel.lastName;
            this.aMember.dob = this.ApplicationModel.dob;
            this.aMember.Relationship = 'Applicant';
            this.aMember.edu_level = this.ApplicationModel.EDU_LEVEL;
            this.aMember.family_comp_id = family_com_id;
            this.aMember.application_no = app_id;  //0;
            this.aMember.financial_contribution = 0;
            this.FamilyMemberModel.push(this.aMember);

            this.NewRequestOfFamilyMemberModel = [];
            this.tmpMemberModel.forEach(child => {
              if (child.applicantIDOfHead != child.applicantIDOfChild) {
                this.aMember = new IncomeSupportFamilyMemberModel();
                this.aMember.applicantIDOfHead = child.applicantIDOfHead;
                this.aMember.applicantIDOfChild = child.applicantIDOfChild;
                this.aMember.given_name = child.given_name;
                this.aMember.last_name = child.last_name;

                this.aMember.Relationship = child.Relationship;
                this.aMember.edu_level = child.edu_level;
                this.aMember.family_comp_id = family_com_id;
                this.aMember.application_no = app_id;   //0;
                this.aMember.financial_contribution = 0;
                if (child.applicantIDOfChild > 0) {
                  this.aMember.dob = this.changeDates(child.dob).toDateString();
                  this.FamilyMemberModel.push(this.aMember);
                } else {
                  this.aMember.dob = this.formatDate2(child.dob);
                  this.NewRequestOfFamilyMemberModel.push(this.aMember);
                }
              }
            });

            this.IncomeSupportApplicationService.saveFamilyMembers(this.FamilyMemberModel).subscribe(x => {
              if (x) {

                this.otherPersonModel = [];
                this.tmpOtherMemberModel.forEach(member => {
                  this.aMember = new IncomeSupportFamilyMemberModel();
                  this.aMember.applicantIDOfHead = this.applicantID; //member.applicantIDOfHead;
                  this.aMember.applicantIDOfChild = member.applicantIDOfPerson;
                  this.aMember.given_name = member.given_name;
                  this.aMember.last_name = member.last_name;
                  this.aMember.dob = this.changeDates(member.dob).toDateString();
                  this.aMember.Relationship = member.Relationship;
                  this.aMember.edu_level = '';
                  this.aMember.family_comp_id = family_com_id;
                  this.aMember.application_no = app_id;  // 0;   
                  this.aMember.financial_contribution = Number(member.contribution);
                  this.otherPersonModel.push(this.aMember);
                });

                this.IncomeSupportApplicationService.saveOtherPersons(this.otherPersonModel).subscribe(x => {
                  if (x) { }
                  this.AssetsModel = [];
                  this.tmpAssetsModel.forEach(asset => {
                    this.oneAssets = new AssetsModel();
                    this.oneAssets.applicantID = this.applicantID;
                    this.oneAssets.applicationNo = app_id;   //0;   
                    this.oneAssets.catagory = asset.asset;
                    this.oneAssets.value = asset.amount;
                    this.oneAssets.description = '';
                    this.oneAssets.member_name = '';
                    this.oneAssets.Fam_Mem_Type = 'A';
                    this.oneAssets.verified = false;
                    this.oneAssets.type = 'R';
                    this.AssetsModel.push(this.oneAssets);
                  });

                  this.IncomeSupportApplicationService.saveAssets(this.AssetsModel).subscribe(y => {
                    if (y) { }

                    this.IncomesModel = [];
                    this.applicantIncomeModel.forEach(i => {
                      this.aIncome = new IncomeModel();
                      this.aIncome.applicantID = this.applicantID;
                      this.aIncome.applicationNo = app_id;   //0;   
                      this.aIncome.catagory = i.income;
                      this.aIncome.value = i.amount;
                      this.aIncome.description = '';
                      this.aIncome.member_name = this.applicant_name;
                      this.aIncome.Fam_Mem_Type = 'A';
                      this.aIncome.verified = false;
                      this.aIncome.type = 'R';
                      this.aIncome.date_recieved = this.formatDate2(i.date_received);
                      this.IncomesModel.push(this.aIncome);
                    });

                    this.spouseIncomeModel.forEach(i => {
                      this.aIncome = new IncomeModel();
                      this.aIncome.applicantID = this.spouse_aid;
                      this.aIncome.applicationNo = app_id;   //0;   
                      this.aIncome.catagory = i.income;
                      this.aIncome.value = i.amount;
                      this.aIncome.description = '';
                      this.aIncome.member_name = this.spouse_name;
                      this.aIncome.Fam_Mem_Type = 'S';
                      this.aIncome.verified = false;
                      this.aIncome.type = 'R';
                      this.aIncome.date_recieved = this.formatDate2(i.date_received);
                      this.IncomesModel.push(this.aIncome);
                    });

                    this.IncomeSupportApplicationService.saveIncomes(this.IncomesModel).subscribe(y => {
                      if (y) {
                        toast({ html: 'Application Successfully Sent!', classes: 'green' });
                        this.backClicked();
                        //this.loading = false;
                      }
                    });
                  });
                });
              }
            });
          });
        });
      } else {
        toast({ html: 'Failed to Save Application!', classes: 'red' });
      }
    });
  }

  backClicked() {
    this.location.back();
  }

  editSection(section) {
    this.step = section;
    $(document).ready(function () {
      $('select').formSelect();
      $('.modal').modal();
      $('.tabs').tabs();
      $('.collapsible').collapsible();
      M.updateTextFields();
    });
  }

  ApplicantSign() {
    this.HasApplicantSign = true;
    setTimeout(() => {
      this.applicantCanvas = document.querySelector('canvas');
      const parentWidth = $(this.applicantCanvas).parent().outerWidth();
      this.applicantCanvas.setAttribute('width', parentWidth - 50);
      this.applicantSignaturePad = new SignaturePad(this.applicantCanvas, {
        backgroundColor: 'rgb(255,255,255)'
      });
    }, 50);

  }

  clearSignatureOfApplicant() {
    this.applicantSignaturePad.clear();
  }
}