import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment.prod';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SupplementalSupportModel } from 'src/app/models/supplementalSupportModel';
import { ApiKeyManagerService } from './apiKeyManager.Service';

@Injectable({
  providedIn: 'root'
})
export class SupplementalSupportService {

  url = environment.firstNation.apiUrl + 'api/supplementalsupport/';

  constructor(private httpClient: HttpClient, private keyManager: ApiKeyManagerService) { }

  getSupplementalSupportByApplicantID(id: number): Observable<SupplementalSupportModel[]> {
    const headers = new HttpHeaders().set('SOCIALAPIKEY', this.keyManager.getKey());
    return this.httpClient.get<SupplementalSupportModel[]>(this.url + 'GetSupplementalSupportByApplicantID/' + id, {headers});
  }

  getSupplementalSupportByID(id: number): Observable<SupplementalSupportModel[]> {
    const headers = new HttpHeaders().set('SOCIALAPIKEY', this.keyManager.getKey());
    return this.httpClient.get<SupplementalSupportModel[]>(this.url + 'GetSupplementalSupportByID/' + id, {headers});
  }

}
