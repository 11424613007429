<div class="row center-align p-m-2">
  <h6><strong> Manage Client </strong></h6>
</div>

<app-firebase-loading *ngIf="loading"></app-firebase-loading>

<div *ngIf="member">
  <app-personal-info [model]="member" [dependant]="dep" [fromCandidates]="fromCandidates"></app-personal-info>

  <div *ngIf="fromCandidates == true; else clientsBlock">
    <a [ngClass]="'col s12 btn waves-effect ' + themeColor" (click)="backClicked()"><i
        class="material-icons left">arrow_back_ios</i> Back To Previous Page</a>
  </div>
  
  <ng-template #clientsBlock>

    <a class="col s12 btn waves-effect blue" (click)="clientActivity();" style="margin-bottom: 15px;"><i
        class="material-icons right">phonelink</i> Member Activity</a>

    <button class="col s12 btn waves-effect teal" [disabled]="loggedUser.isDemo" (click)="contactClient()"
      style="margin-bottom: 15px;"><i class="material-icons right">mail</i> Contact
      Client</button>

    <button *ngIf="!isEnabled" class="col s12 btn waves-effect green modal-trigger" data-target="confirmationModal"
      [disabled]="loggedUser.isDemo" style="margin-bottom: 15px;"><i class="material-icons right">mobile_friendly</i>
      Enable Client Account</button>

    <button *ngIf="isEnabled" class="col s12 btn waves-effect red modal-trigger" data-target="confirmationModal"
      [disabled]="loggedUser.isDemo" style="margin-bottom: 15px;"><i class="material-icons right">mobile_off</i>
      Disable
      Client Account</button>

    <button [disabled]="!hasEmail" style="margin-bottom: 15px;" data-target="resetPasswordModal"
      class="col s12 btn waves-effect blue modal-trigger"> <i class="material-icons right">mail</i> Send Link to Reset
      Password
    </button>

    <a [ngClass]="'col s12 btn waves-effect ' + themeColor" (click)="backClicked()"><i
        class="material-icons left">arrow_back_ios</i> Back To List</a>
  </ng-template>
</div>

<div *ngIf="contact">
  <app-contact-members [message]=message [contactDetail]="contactDetail" (success)="contactHandler($event)">
  </app-contact-members>
</div>

<!-- Modal Structure -->
<div id="confirmationModal" class="modal modal_max">
  <div class="modal-content" *ngIf="member">

    <div *ngIf="isEnabled">
      <h5><strong>Disable Account</strong></h5>
      <div class="valign-wrapper card-panel red lighten-5">
        <i class="material-icons left red-text" style="font-size: 40px;">
          warning_amber
        </i>
        <div>
          <h6>Are You sure you want to Disable this Account? </h6>
          <h6 class="red-text"><strong>Users with a disabled accounts aren't able to sign in.</strong></h6>
        </div>
      </div>
    </div>

    <div *ngIf="!isEnabled">
      <h5><strong>Enable Account</strong></h5>
      <div class="valign-wrapper card-panel blue lighten-5">
        <i class="material-icons left blue-text" style="font-size: 40px;">
          info
        </i>
        <div>
          <h6>Are You sure you want to Enable this Account? </h6>
          <h6><strong>Users with enabled accounts will be able to sign in again.</strong></h6>
        </div>
      </div>
    </div>

    <h6 class="grey-text">User account</h6>
    <h6><strong>{{member.Email}}</strong></h6>
    <br />
    <h6>Please Enter the Reason for <span *ngIf="isEnabled">Disabling</span> <span *ngIf="!isEnabled">Enabling</span>
      this account</h6>
    <div class="input-field col s12">
      <input placeholder="Reason" id="reason" type="text" [(ngModel)]="reason" class="validate">
    </div>
    <br />
    <p class="col s12" style="margin-bottom: 40px;">
      <strong>Do you wish to send a message to the client?</strong>
      <label class="right">
        <input type="checkbox" (click)="onSendEmail()" [checked]='sendEmail' />
        <span>Send Email</span>
      </label>
    </p>
  </div>
  <div class="section"></div>
  <div class="modal-footer">
    <a class="modal-close waves-effect waves-light btn-flat"><strong>Cancel</strong></a>
    <button *ngIf="!isEnabled" (click)="enableAccount()" class="modal-close btn waves-effect waves-light blue"
      [disabled]="!reason"><strong>Enable</strong></button>
    <button *ngIf="isEnabled" (click)="disableAccount()" class="modal-close btn waves-effect waves-light red"
      [disabled]="!reason"><strong>Disable</strong></button>
  </div>
</div>

<div id="resetPasswordModal" class="modal">
  <div class="modal-content">
    <h4>Reset Member Password</h4>
    <form class="container" (ngSubmit)="resetPassword()" [formGroup]="resetPasswordForm">
      <div class="row">

        <div class="row">
          <div class="section"></div>
          <p>
            Please enter the member email and Follow the link we will send to the member to reset account password
          </p>
          <div class="section"></div>
          <div class="input-field col s12">
            <div> <label for="email">Email</label></div>
            <div>
              <input id="email" type="email" [(ngModel)]='client_email' required formControlName='email'
                class="validate">
              <div *ngIf="(email.dirty || email.touched) && email.invalid">
                <small *ngIf="email.errors.required" class="red-text"> Email is required. </small>
                <small *ngIf="email.errors.email" class="red-text"> wrong email format. </small>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="input-field col s12 center">
            <button type='submit' [disabled]="!resetPasswordForm.valid" name='btn_login'
              class='col s12 btn btn-large waves-effect indigo'> <i class="material-icons left">compare_arrows</i> Send
              Link to Reset Password</button>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
