<div class="container body">

    <div class="row center-align">
        <h6 *ngIf="entitlements"><strong> Payment of {{ entitlements[0]?.month }}/{{ entitlements[0]?.year }} </strong>
        </h6>
        <h6 *ngIf="!entitlements"><strong> Payments Received </strong></h6>
        <p *ngIf="userID" class="red-text"><strong>My ID : {{userID}}</strong> </p>
    </div>

    <div class="row col s12">
        <div class="col l1"></div>
        <div class="col s12 m12 l10">
            <div *ngIf="hasEntitlement">
                <div *ngIf="result.length > 0">
                    <h6 class="blue-text"> <strong>Please Click One Funding to Apply for Extra Funding: </strong>
                    </h6>
                </div>

                <div class="row">
                    <ul *ngIf="result" class="row collection">
                        <div class="section"></div>
                        <li *ngFor="let e of result" class="collection-item" (click)="selectBD_new(e.funding_id)">
                            <div>
                                <span class="blue-text"><u>{{e.funding_id}}</u></span>
                                <span
                                    class="secondary-content black-text">{{e.month}}/{{e.year}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{e.school_period}}
                                </span>
                            </div>
                        </li>
                    </ul>

                    <div class="section"></div>
                    <div class="row">
                        <a [ngClass]="'at-btn col s12 btn waves-effect ' + themeColor" (click)="backClicked()"><i
                                class="material-icons left ">house</i> Back To Home Page</a>
                    </div>
                </div>
            </div>

            <div *ngIf="!hasEntitlement" class="card-panel blue darken-2 center">
                <h6 class="white-text"> <strong>
                        You Don't Have Any Entitlement In The Database.</strong>
                </h6>
            </div>

            <div *ngIf="!hasEntitlement">
                <a [ngClass]="'at-btn col s12 btn waves-effect blue'" (click)="backClicked()"><i
                        class="material-icons left ">house</i> Back To Home Page</a>
            </div>
        </div>

        <div class="col l1"></div>
    </div>
</div>