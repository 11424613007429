<div class="row">
    <div class="card-panel row">
        <p><span class="blue-text"> Board Name : </span> {{model.name}} </p>
        <p><span class="blue-text"> mandate :</span> {{model.mandate}} </p>
        <p><span class="blue-text"> Phone :</span> {{model.phone}} </p>
        <p><span class="blue-text"> Email :</span> {{model.email}} </p>
    </div>

    <div class="row">
        <div *ngIf="model.boardChair && model.boardChair.length > 0">
            <div class="col s12 m6">
                <div class="card medium">
                    <div class="card-image">
                        <img *ngIf="model.boardChair[0].imgLink" class="responsive-img"
                            style="object-fit: contain; width: 100%; height:230px;" [src]="model.boardChair[0].imgLink">
                        <img *ngIf="!model.boardChair[0].imgLink" class="responsive-img"
                            style="object-fit: contain; width: 100%; height:230px;"
                            src="../../../../assets/img/emptyProfilePic.png">
                    </div>
                    <div class="card-content center">

                        <span class="card-title">
                            <strong>{{model.boardChair[0].lastName}} {{model.boardChair[0].firstName}}</strong>
                        </span>
                        <h6 class="red-text"><strong>Chair Of The {{model.name}}</strong></h6>
                    </div>

                    <div class="card-action">

                        <a href="{{model.boardChair[0].chiefFacebookAcc}}" target="_blank" class="center"
                            *ngIf="(model.boardChair[0].showFacebookAcc === 'Public') && model.boardChair[0].chiefFacebookAcc"><span
                                style="cursor: pointer;"> <i class="fa fa-facebook prefix "
                                    style="color: #3b5998; font-size:larger;"></i></span> </a>
                        <a href="{{model.boardChair[0].chiefInstagramAcc}}" target="_blank" class="center"
                            *ngIf="(model.boardChair[0].showInstagramAcc === 'Public' ) && model.boardChair[0].chiefInstagramAcc"><span
                                style="cursor: pointer;"> <i class="fa fa-instagram prefix "
                                    style="color: #8a3ab9; font-size:larger;"></i> </span></a>
                        <a href="{{model.boardChair[0].chiefTwitterAcc}}" target="_blank" class="center"
                            *ngIf="(model.boardChair[0].showTwitterAcc === 'Public' ) && model.boardChair[0].chiefTwitterAcc"><span
                                style="cursor: pointer;"> <i class="fa fa-twitter prefix"
                                    style="color: #1DA1F2; font-size:larger;"></i></span> </a>
                        <a href="{{model.boardChair[0].chiefLinkedinAcc}}" target="_blank" class="center"
                            *ngIf="(model.boardChair[0].showLinkedinAcc === 'Public') && model.boardChair[0].chiefLinkedinAcc"><span
                                style="cursor: pointer;"> <i class="fa fa-linkedin prefix "
                                    style="color: #0077b5; font-size:larger;"></i></span> </a>
                        <a href="{{model.boardChair[0].chiefYoutubeAcc}}" target="_blank" class="center"
                            *ngIf="(model.boardChair[0].showYoutubeAcc === 'Public') && model.boardChair[0].chiefYoutubeAcc"><span
                                style="cursor: pointer;"> <i class="fa fa-youtube prefix red-text"
                                    style="font-size:larger;"></i></span> </a>
                        <a [routerLink]="['/governance-chief']" class="right blue-text">View Profile</a>
                    </div>
                </div>
            </div>
        </div>

        <div *ngIf="model.boardMembers && model.boardMembers.length > 0">
            <div class="col s12 m6" *ngFor="let m of model.boardMembers">
                <div class="card medium">
                    <div class="card-image">
                        <img *ngIf="m.imgLink" class="responsive-img"
                            style="object-fit: contain; width: 100%; height:230px;" [src]="m.imgLink">
                        <img *ngIf="!m.imgLink" class="responsive-img"
                            style="object-fit: contain; width: 100%; height:230px;"
                            src="../../../../assets/img/emptyProfilePic.png">
                    </div>
                    <div class="card-content center">

                        <span class="card-title">
                            <strong>{{m.lastName}} {{m.firstName}}</strong>
                        </span>
                        <h6 class="green-text"><strong> {{model.name}} Member</strong></h6>
                    </div>

                    <div class="card-action">
                        <a href="{{m.chiefFacebookAcc}}" target="_blank" class="center"
                            *ngIf="(m.showFacebookAcc === 'Public') && m.chiefFacebookAcc"><span
                                style="cursor: pointer;"> <i class="fa fa-facebook prefix "
                                    style="color: #3b5998; font-size:larger;"></i></span> </a>
                        <a href="{{m.chiefInstagramAcc}}" target="_blank" class="center"
                            *ngIf="(m.showInstagramAcc === 'Public' ) && m.chiefInstagramAcc"><span
                                style="cursor: pointer;"> <i class="fa fa-instagram prefix "
                                    style="color: #8a3ab9; font-size:larger;"></i> </span></a>
                        <a href="{{m.chiefTwitterAcc}}" target="_blank" class="center"
                            *ngIf="(m.showTwitterAcc === 'Public' ) && m.chiefTwitterAcc"><span
                                style="cursor: pointer;"> <i class="fa fa-twitter prefix"
                                    style="color: #1DA1F2; font-size:larger;"></i></span> </a>
                        <a href="{{m.chiefLinkedinAcc}}" target="_blank" class="center"
                            *ngIf="(m.showLinkedinAcc === 'Public') && m.chiefLinkedinAcc"><span
                                style="cursor: pointer;"> <i class="fa fa-linkedin prefix "
                                    style="color: #0077b5; font-size:larger;"></i></span> </a>
                        <a href="{{m.chiefYoutubeAcc}}" target="_blank" class="center"
                            *ngIf="(m.showYoutubeAcc === 'Public') && m.chiefYoutubeAcc"><span style="cursor: pointer;">
                                <i class="fa fa-youtube prefix red-text" style="font-size:larger;"></i></span> </a>
                        <a [routerLink]="['/governance-chief']" class="right blue-text">View Profile</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>