import { Component, OnInit } from '@angular/core';

import { InterventionsService } from 'src/app/services/api/interventions.service';
import { ActivatedRoute } from '@angular/router';
import {Location} from '@angular/common';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-intervention',
  templateUrl: './intervention.component.html',
  styleUrls: ['./intervention.component.css']
})
export class InterventionComponent implements OnInit {

  constructor( private intventionsService: InterventionsService,  
    private location: Location, 
    private route: ActivatedRoute) {
  }

  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  interventions: any[];     
  dtOptions: any = {};
  client_ID: any; 

  ngOnInit(): void {
    this.dtOptions = {};
    this.dtOptions = {
      pagingType: 'simple_numbers',
      pageLength: 25,
      dom: 'Bfrtip',
      responsive: true,
      buttons: [
       'copy',
       'print',
       'csv',
       'excel'
      ]
    };

    this.route.paramMap.subscribe(params => {
     this.client_ID = params.get('id');
    });

   
    if (this.client_ID) {
      const id = + this.client_ID;
     
      this.intventionsService.getInterventions(id).subscribe(data => {
        if (data) {
          this.interventions = [];   
          this.interventions = data;
        }
      }); 
    }
  }

  backClicked() {
    this.location.back();
  }

}
