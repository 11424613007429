<div class="row">

  <!-- =========== Left Navigation ============= -->
  <div class="col s12 m2 l1" *ngIf="user">
    <app-side-menus [user]="user" (onMenuClicked)="onMenuClicked($event)"></app-side-menus>
  </div>

  <!-- =========== Content============= -->

  <div class="col s12 m10 l2">

    <div class="submenu-sidenav hide-on-med-and-down" *ngIf="submenus">
      <div *ngFor="let item of submenus; let i=index">
        <!-- <div class="col s6 m4 l12" *ngIf="item.isEnabled" (click)="itemClicked(item)"
          [ngClass]="{'tooltipped': item.underConstruction, 'disabledDiv modal-trigger': !getPermission(menu.name, item.name)}"
          data-position="top" data-tooltip="Under Construction" data-target="permissionModal" style="cursor: pointer;">
          <div class="card-panel hoverable b-n-1" [ngStyle]="{'height': '16vh'}">
            <div class="row ">
              <div class="icon-wrapper"> <i class="material-icons custom-icon white-text"
                  [ngStyle]="{'background-color' : themeColor}">{{item.icon}}</i></div>
              <strong class="truncate black-text">{{item.displayName}}</strong>
            </div>
          </div>
        </div> -->
        <ul *ngIf="item.isEnabled" (click)="itemClicked(item.name, item.id)" class="submenu-contents"
          [ngClass]="{'disabledDiv modal-trigger': !getPermission(menu.name, item.name)}" data-target="permissionModal"
          style="cursor: pointer;">
          <li>
            <i class="material-icons-outlined black-text">{{item.icon}}</i>
            <a class="black-text">{{item.displayName}}</a>
          </li>
        </ul>
      </div>
    </div>
  </div>

  <div class="col s12 m12 l9">
    <select class="browser-default hide-on-large-only" *ngIf="submenus" (change)="itemClicked($event.target.value)">
      <option value="" disabled selected>Choose your option
      </option>
      <option *ngFor="let item of submenus; let i=index" [value]="item.name">
        {{item.displayName}}
      </option>
    </select>

    <router-outlet></router-outlet>

  </div>


  <!-- =========== nnnn ============= -->
  <ng-template #menus *ngIf="adminMenus">
    <div *ngFor="let item of adminMenus;let i=index">
      <a *ngIf="item.isEnabled && !(item.name == 'Communication' || item.name == 'Logout')" (click)="menuClicked(item)"
        [ngClass]="{'disabledDiv modal-trigger': !getPermission(item.name), 'active': item.name == from}"
        data-target="permissionModal"> <i class="material-symbols-outlined">{{item.icon}}</i>
        <p>{{item.displayName}}</p>
      </a>
    </div>

  </ng-template>

  <!-- =========== Modals ============= -->
  <div id="permissionModal" class="modal center b-n-1">
    <div class="modal-content">
      <i class="material-icons right modal-close">close</i><br />
      <h6 class="title-section2 bold-600 black-text">Unauthorized</h6>
      <p class="sub-title-section bold-100">Sorry, but you don't have permission to access this page.</p>
      <img src="https://img.icons8.com/dotty/344/id-not-verified.png" height="150px" />
      <p class="sub-title-section bold-100">Please Contanct your Administrator to gain access.</p>
    </div>
  </div>

  <div id="underConstructionModal" class="modal center b-n-1">
    <div class="modal-content">
      <i class="material-icons right modal-close">close</i><br />
      <h6 class="title-section2 bold-600 black-text">Under Construction</h6>
      <p class="sub-title-section bold-100">We’re working hard to make this feature available soon. </p>
      <img src="https://img.icons8.com/wired/344/tow-truck.png" height="150px" />
      <p class="sub-title-section bold-100">In the meantimee if you need some support? Contact us on info&#64;mynation.app
      </p>
      <p class="sub-title-section bold-100">Please Contanct your Administrator to gain access.</p>
    </div>
  </div>

  <div id="disabledSubMenus" class="modal center b-n-1">
    <div class="modal-content">
      <i class="material-icons right modal-close">close</i><br />
      <h6 class="title-section2 bold-600 black-text">Disabled</h6>
      <p class="sub-title-section bold-100">Sorry, but all section in the section have been disabled.</p>
      <img src="https://img.icons8.com/dotty/344/id-not-verified.png" height="150px" />
      <p class="sub-title-section bold-100">Please Contanct your Administrator to gain access.</p>
    </div>
  </div>

</div>











<!----------------------------------------------------------------------------------------------------------------------------------------------------->

<div *ngIf="false">
  <!-------------------------------- Notifications ---------------------------------------->
  <div class="row" *ngIf="notificationMenu">

    <div class="col s6 m4 l3" [@swing]="swing">
      <div class="card blue hoverable" (click)="backClicked()" style="height: 95px;">
        <div class="card-content">
          <div class="row center">
            <i class="material-icons white-text">house</i>
            <br />
            <strong class="white-text">Back</strong>
          </div>
        </div>
      </div>
    </div>

    <div class="col s6 m4 l3" [@swing]="swing" [ngClass]="{'disabledDiv': !getPermission('ManageNews')}">
      <a (click)="publicClicked()">
        <div class="card teal lighten-1 hoverable" style="height: 95px;">
          <div class="card-content">
            <div class="row center">
              <i class="material-icons white-text ">public</i><br />
              <strong class="white-text">Manage News</strong>
            </div>
          </div>
        </div>
      </a>
    </div>

    <div class="col s6 m4 l3" [@swing]="swing" [ngClass]="{'disabledDiv': !getPermission('ManageEvents')}">
      <a [routerLink]="['/admin/static-content/event-list']">
        <div class="card blue darken-1 hoverable" style="height: 95px;">
          <div class="card-content">
            <div class="row center">
              <i class="material-icons white-text ">public</i><br />
              <strong class="white-text">Manage Events</strong>
            </div>
          </div>
        </div>
      </a>
    </div>

    <div class="col s6 m4 l3" [@swing]="swing" [ngClass]="{'disabledDiv': !getPermission('Archives')}">
      <a (click)="ArchiveClicked()">
        <div class="card orange darken-2 hoverable" style="height: 95px;">
          <div class="card-content">
            <div class="row center">
              <i class="material-icons white-text ">archive</i><br />
              <strong class="white-text">Archives</strong>
            </div>
          </div>
        </div>
      </a>
    </div>

    <div class="col s6 m4 l3" *ngIf="archive" [ngClass]="{'disabledDiv': !getPermission('ArchivedNews')}">
      <a [routerLink]="['/admin/static-content/archived-post']" (click)="ArchiveClicked()">
        <div class="card pink darken-1 hoverable" style="height: 95px;">
          <div class="card-content">
            <div class="row center">
              <i class="material-icons white-text ">archive</i><br />
              <strong class="white-text">Archived News</strong>
            </div>
          </div>
        </div>
      </a>
    </div>

    <div class="col s6 m4 l3" *ngIf="archive" [ngClass]="{'disabledDiv': !getPermission('ArchivedEvents')}">
      <a [routerLink]="[]">
        <div class="card green darken-1 hoverable" style="height: 95px;">
          <div class="card-content">
            <div class="row center">
              <i class="material-icons white-text ">archive</i><br />
              <strong class="white-text">Archived Events</strong>
            </div>
          </div>
        </div>
      </a>
    </div>

    <!-- <div class="col s6 m4 l3" [@swing]="swing">
    <a (click)="privateClicked()">
      <div class="card pink lighten-1 hoverable " style="height: 95px;">
        <div class="card-content">
          <div class="row center">
            <i class="material-icons white-text">lock</i><br />
            <strong class="white-text">Members Only</strong>
          </div>
        </div>
      </div>
    </a>
  </div> -->

  </div>

  <!-------------------------------- Membership ---------------------------------------->
  <div class="row" *ngIf="membershipMenu">

    <div class="col s6 m4 l3" [@swing]="swing">
      <div class="card blue hoverable" (click)="backClicked()" style="height: 95px;">
        <div class="card-content">
          <div class="row center">
            <i class="material-icons white-text">house</i>
            <br />
            <strong class="white-text">Back</strong>
          </div>
        </div>
      </div>
    </div>

    <div class="col s6 m4 l3" [@swing]="swing">
      <a [routerLink]="['/admin/members/membership']">
        <div class="card green darken-4  hoverable " style="height: 95px;">
          <div class="card-content">
            <div class="row center">
              <i class="material-icons white-text">account_circle</i><br />
              <strong class="white-text">Membership</strong>
            </div>
          </div>
        </div>
      </a>
    </div>

    <!-- [ngClass]="{'disabledDiv': !registrationRequest}" -->
    <div class="col s6 m4 l3" [@swing]="swing" [ngClass]="{'disabledDiv': !getPermission('RegistrationRequest')}">
      <a [routerLink]="['/admin/members/registration-request']">
        <div class="card cyan darken-1 hoverable " style="height: 95px;">
          <div class="card-content">
            <div class="row center">
              <i class="material-icons white-text">person_add</i><br />
              <strong class="white-text">Registration Request</strong>
            </div>
          </div>
        </div>
      </a>
    </div>

    <!-- [ngClass]="{'disabledDiv': !registeredClients}" -->
    <div class="col s6 m4 l3" [@swing]="swing" [ngClass]="{'disabledDiv': !getPermission('RegisteredMembers')}">
      <a [routerLink]="['/admin/members/registred-clients']">
        <div class="card green darken-2 hoverable " style="height: 95px;">
          <div class="card-content">
            <div class="row center">
              <i class="material-icons white-text">group_add</i><br />
              <strong class="white-text">Registered Members</strong>
            </div>
          </div>
        </div>
      </a>
    </div>

    <!-- [ngClass]="{'disabledDiv': !birthdays}" -->
    <div class="col s6 m4 l3" [@swing]="swing" [ngClass]="{'disabledDiv': !getPermission('Birthdays')}">
      <a [routerLink]="['/admin/members/upcoming-birthdays']">
        <div class="card purple lighten-1 hoverable " style="height: 95px;">
          <div class="card-content">
            <div class="row center">
              <i class="material-icons white-text">cake</i><br />
              <strong class="white-text">Birthdays</strong>
            </div>
          </div>
        </div>
      </a>
    </div>

    <!-- [ngClass]="{'disabledDiv': !addMembers}" -->
    <div class="col s6 m4 l3" [@swing]="swing" [ngClass]="{'disabledDiv': !getPermission('AddMembers')}">
      <a [routerLink]="['/admin/members/add-members']">
        <div class="card amber darken-3  hoverable " style="height: 95px;">
          <div class="card-content">
            <div class="row center">
              <i class="material-icons white-text">person_add</i><br />
              <strong *ngIf="nationOfUse !== 'Batc'" class="white-text">Add Members</strong>
              <strong *ngIf="nationOfUse === 'Batc'" class="white-text">Add Clients</strong>
            </div>
          </div>
        </div>
      </a>
    </div>

    <!-- [ngClass]="{'disabledDiv': !search}" -->
    <div class="col s6 m4 l3" [@swing]="swing" [ngClass]="{'disabledDiv': !getPermission('SearchMembers')}">
      <a [routerLink]="['/admin/members/search-members']">
        <div class="card blue darken-3 hoverable " style="height: 95px;">
          <div class="card-content">
            <div class="row center">
              <i class="material-icons white-text">search</i><br />
              <strong *ngIf="nationOfUse !== 'Batc'" class="white-text">Search Members</strong>
              <strong *ngIf="nationOfUse === 'Batc'" class="white-text">Search Clients</strong>
            </div>
          </div>
        </div>
      </a>
    </div>

    <!-- [ngClass]="{'disabledDiv': !profileUpdates}" -->
    <div class="col s6 m4 l3" [@swing]="swing" [ngClass]="{'disabledDiv': !getPermission('ProfileUpdates')}">
      <a [routerLink]="['/admin/members/profile-updates']">
        <div class="card pink darken-3 hoverable " style="height: 95px;">
          <div class="card-content">
            <div class="row center">
              <i class="material-icons white-text">edit_note</i>
              <app-new-notification-badge
                [content]="{component: 'profile-updates', color: 'blue', textColor: 'white' }">
              </app-new-notification-badge><br />
              <strong class="white-text">Profile Updates</strong>
            </div>
          </div>
        </div>
      </a>
    </div>

    <!-- <div class="col s6 m4 l3" [@swing]="swing" [ngClass]="{'disabledDiv': !congratulate}">
    <a [routerLink]="['/admin/members/congratulate-members']">
      <div class="card teal darken-2 hoverable " style="height: 95px;">
        <div class="card-content">
          <div class="row center">
            <i class="material-icons white-text">grade</i><br />
            <strong *ngIf="nationOfUse !== 'Batc'" class="white-text">Congratulate Members</strong>
            <strong *ngIf="nationOfUse === 'Batc'" class="white-text">Congratulate Clients</strong>
          </div>
        </div>
      </div>
    </a>
  </div> -->

    <!-- <div class="col s6 m4 l3" [@swing]="swing" [ngClass]="{'disabledDiv': !becomingSenior}">
    <a [routerLink]="['/admin/members/members-turning21']">
      <div class="card blue darken-3 hoverable " style="height: 95px;">
        <div class="card-content">
          <div class="row center">
            <i class="material-icons white-text">cake</i><br />
            <strong class="white-text">Becoming Senior</strong>
          </div>
        </div>
      </div>
    </a>
  </div> -->

    <!-- <div class="col s6 m4 l3" [@swing]="swing" [ngClass]="{'disabledDiv': !turning16}">
    <a [routerLink]="['/admin/members/members-turning16']">
      <div class="card purple darken-3 hoverable " style="height: 95px;">
        <div class="card-content">
          <div class="row center">
            <i class="material-icons white-text">cake</i><br />
            <strong class="white-text">Turning 16</strong>
          </div>
        </div>
      </div>
    </a>
  </div> -->

    <!-- <div class="col s6 m4 l3" [@swing]="swing" [ngClass]="{'disabledDiv': !turning18}">
    <a [routerLink]="['/admin/members/members-turning18']">
      <div class="card pink darken-3 hoverable " style="height: 95px;">
        <div class="card-content">
          <div class="row center">
            <i class="material-icons white-text">cake</i><br />
            <strong class="white-text">Turning 18</strong>
          </div>
        </div>
      </div>
    </a>
  </div> -->

    <!-- <div class="col s6 m4 l3" [@swing]="swing">
    <a [routerLink]="['/admin/members/manage-members']">
      <div class="card blue darken-4 hoverable " style="height: 95px;">
        <div class="card-content">
          <div class="row center">
            <i class="material-icons white-text">people</i><br />
            <strong class="white-text">Members</strong>
          </div>
        </div>
      </div>
    </a>
  </div> -->

  </div>

  <!-------------------------------- Employment ---------------------------------------->
  <div class="row" *ngIf="employmentMenu">

    <div class="col s6 m4 l3" *ngIf="!isJobClicked && !isTrainingClicled">
      <div class="card blue hoverable" (click)="backClicked()" style="height: 95px;">
        <div class="card-content">
          <div class="row center">
            <i class="material-icons white-text">house</i>
            <br />
            <strong class="white-text">Back</strong>
          </div>
        </div>
      </div>
    </div>

    <!-- [ngClass]="{'disabledDiv': !registredEmployers}" -->
    <div class="col s6 m4 l3" *ngIf="!isJobClicked && !isTrainingClicled"
      [ngClass]="{'disabledDiv': !getPermission('RegisteredEmployers')}">
      <a [routerLink]="['/admin/employers/employers-list']">
        <!--</a><a [routerLink]="['/admin/employers-list']">  -->
        <div class="card pink darken-1 hoverable " style="height: 95px;">
          <div class="card-content">
            <div class="row center">
              <i class="material-icons white-text">business_center</i><br />
              <strong class="white-text">Registered Employers</strong>
            </div>
          </div>
        </div>
      </a>
    </div>

    <div class="col s6 m4 l3" *ngIf="!isTrainingClicled">

      <div class="card purple lighten-1 hoverable" (click)="JobPostingClicked()" style="height: 95px;">
        <div class="card-content">
          <div class="row center">
            <i class="material-icons white-text" *ngIf="!isJobClicked">work</i>
            <i class="material-icons white-text" *ngIf="isJobClicked">house</i>
            <br />
            <strong *ngIf="!isJobClicked" class="white-text">Job Posting</strong>
            <strong *ngIf="isJobClicked" class="white-text">Back</strong>
          </div>
        </div>
      </div>
    </div>

    <!-- [ngClass]="{'disabledDiv': !jobPositings}" -->
    <div class="col s6 m4 l3" *ngIf="isJobClicked" [ngClass]="{'disabledDiv': !getPermission('JobPosting')}">
      <a [routerLink]="['/admin/employers/job-list']">
        <div class="card green darken-2 hoverable " style="height: 95px;">
          <div class="card-content">
            <div class="row center">
              <i class="material-icons white-text">next_week</i><br />
              <strong class="white-text">Job Postings </strong>
            </div>
          </div>
        </div>
      </a>
    </div>

    <!-- [ngClass]="{'disabledDiv': !postJob}" -->
    <div class="col s6 m4 l3" *ngIf="isJobClicked" [ngClass]="{'disabledDiv': !getPermission('PostJob')}">
      <a [routerLink]="['/admin/employers/post-job']">
        <div class="card blue darken-4 hoverable" style="height: 95px;">
          <div class="card-content">
            <div class="row center">
              <i class="material-icons white-text">home_work</i><br />
              <strong class="white-text">Post Job</strong>
            </div>
          </div>
        </div>
      </a>
    </div>

    <!-- [ngClass]="{'disabledDiv': !jobArchived}" -->
    <div class="col s6 m4 l3" *ngIf="isJobClicked" [ngClass]="{'disabledDiv': !getPermission('ArchivedJobs')}">
      <a [routerLink]="['/admin/employers/archive-job']">
        <div class="card cyan darken-3 hoverable " style="height: 95px;">
          <div class="card-content">
            <div class="row center">
              <i class="material-icons white-text">home_work</i><br />
              <strong class="white-text">Archived Jobs</strong>
            </div>
          </div>
        </div>
      </a>
    </div>

    <!-- [ngClass]="{'disabledDiv': !training}" -->
    <div class="col s6 m4 l3" *ngIf="!isJobClicked" [ngClass]="{'disabledDiv': !getPermission('Training')}">

      <div class="card blue hoverable" (click)="trainingClicked()" style="height: 95px;">
        <div class="card-content">
          <div class="row center">
            <i class="material-icons white-text" *ngIf="!isTrainingClicled">work</i>
            <i class="material-icons white-text" *ngIf="isTrainingClicled">house</i>
            <br />
            <strong *ngIf="!isTrainingClicled" class="white-text">Training</strong>
            <strong *ngIf="isTrainingClicled" class="white-text">Back</strong>
          </div>
        </div>
      </div>
    </div>

    <!-- [ngClass]="{'disabledDiv': !trainings}" -->
    <div class="col s6 m4 l3" *ngIf="isTrainingClicled" [ngClass]="{'disabledDiv': !getPermission('TrainingList')}">
      <a [routerLink]="['/admin/employers/trainings']">
        <div class="card green darken-2 hoverable" style="height: 95px;">
          <div class="card-content">
            <div class="row center">
              <i class="material-icons white-text">home_work</i><br />
              <strong class="white-text">Training List</strong>
            </div>
          </div>
        </div>
      </a>
    </div>

    <!-- [ngClass]="{'disabledDiv': !addTraining}" -->
    <div class="col s6 m4 l3" *ngIf="isTrainingClicled" [ngClass]="{'disabledDiv': !getPermission('AddTraining')}">
      <a [routerLink]="['/admin/employers/add-training']">
        <div class="card blue darken-4 hoverable" style="height: 95px;">
          <div class="card-content">
            <div class="row center">
              <i class="material-icons white-text">home_work</i><br />
              <strong class="white-text">Add Training</strong>
            </div>
          </div>
        </div>
      </a>
    </div>

    <!-- [ngClass]="{'disabledDiv': !archiveTraining}" -->
    <div class="col s6 m4 l3" *ngIf="isTrainingClicled" [ngClass]="{'disabledDiv': !getPermission('ArchiveTraining')}">
      <a [routerLink]="['/admin/employers/archive-training']">
        <div class="card cyan darken-2 hoverable " style="height: 95px;">
          <div class="card-content">
            <div class="row center">
              <i class="material-icons white-text">home_work</i><br />
              <strong class="white-text">Archive Training</strong>
            </div>
          </div>
        </div>
      </a>
    </div>

    <!-- [ngClass]="{'disabledDiv': !skills}" -->
    <div class="col s6 m4 l3" *ngIf="!isJobClicked && !isTrainingClicled"
      [ngClass]="{'disabledDiv': !getPermission('SkillsDatabank')}">
      <a [routerLink]="['/admin/skills-databank']">
        <div class="card cyan darken-3  hoverable " style="height: 95px;">
          <div class="card-content">
            <div class="row center">
              <i class="material-icons white-text">storage</i><br />
              <strong class="white-text">Skills Databank</strong>
            </div>
          </div>
        </div>
      </a>
    </div>

  </div>

  <!------------------------------- Housing ----------------------------------------------->
  <div class="row" *ngIf="housingMenu">
    <div class="col s6 m4 l3" [@swing]="swing">
      <div class="card blue hoverable" (click)="backClicked()" style="height: 95px;">
        <div class="card-content">
          <div class="row center">
            <i class="material-icons white-text">house</i>
            <br />
            <strong class="white-text">Back</strong>
          </div>
        </div>
      </div>
    </div>

    <!-- [ngClass]="{'disabledDiv': !houses}" -->
    <div class="col s6 m4 l3" [@swing]="swing" [ngClass]="{'disabledDiv': !getPermission('Houses')}">
      <a [routerLink]="['/admin/housing']">
        <div class="card cyan darken-1 hoverable " style="height: 95px;">
          <div class="card-content">
            <div class="row center">
              <i class="material-icons white-text">list</i><br />
              <strong class="white-text">Houses</strong>
            </div>
          </div>
        </div>
      </a>
    </div>

    <!-- [ngClass]="{'disabledDiv': !newHouses}" -->
    <div class="col s6 m4 l3" [@swing]="swing" [ngClass]="{'disabledDiv': !getPermission('NewHouse')}">
      <a [routerLink]="['/admin/new-property']">
        <div class="card green darken-2  hoverable " style="height: 95px;">
          <div class="card-content">
            <div class="row center">
              <i class="material-icons white-text">house</i><br />
              <strong class="white-text">New House</strong>
            </div>
          </div>
        </div>
      </a>
    </div>

    <!-- [ngClass]="{'disabledDiv': !repairRequests}" -->
    <div class="col s6 m4 l3" [@swing]="swing" [ngClass]="{'disabledDiv': !getPermission('RepairRequests')}">
      <a [routerLink]="['/admin/repair-request']">
        <div class="card purple lighten-1 hoverable " style="height: 95px;">
          <div class="card-content">
            <div class="row center">
              <i class="material-icons white-text">home_repair_service</i><br />
              <strong class="white-text">Repair Requests</strong>
            </div>
          </div>
        </div>
      </a>
    </div>

    <!-- [ngClass]="{'disabledDiv': !renovationRequest}" -->
    <div class="col s6 m4 l3" [@swing]="swing" [ngClass]="{'disabledDiv': !getPermission('RenovationRequest')}">
      <a [routerLink]="['/admin/renovation-request']">
        <div class="card pink darken-2 hoverable " style="height: 95px;">
          <div class="card-content">
            <div class="row center">
              <i class="material-icons white-text">construction</i><br />
              <strong class="white-text">Renovation Request </strong>
            </div>
          </div>
        </div>
      </a>
    </div>

  </div>

  <!-------------------------------- Statistics ---------------------------------------->

  <!-- <div class="row" *ngIf="statisticsMenu">
    <div class="col s6 m4 l3" [@swing]="swing">
      <div class="card blue hoverable" (click)="backClicked()" style="height: 95px;">
        <div class="card-content">
          <div class="row center">
            <i class="material-icons white-text">house</i>
            <br />
            <strong class="white-text">Back</strong>
          </div>
        </div>
      </div>
    </div>

    <div class="col s6 m4 l3" [@swing]="swing" [ngClass]="{'disabledDiv': !getPermission('AppStatistics')}">
      <a [routerLink]="['/admin/stats/app-statistics']">
        <div class="card purple darken-3 hoverable " style="height: 95px;">
          <div class="card-content">
            <div class="row center">
              <i class="material-icons white-text">bar_chart</i><br />
              <strong class="white-text">App Statistics</strong>
            </div>
          </div>
        </div>
      </a>
    </div>

    <div class="col s6 m4 l3" [@swing]="swing" [ngClass]="{'disabledDiv': !getPermission('DepartmentStats')}">
      <a [routerLink]="['/admin/stats/dep-statistics']">
        <div class="card blue darken-3 hoverable " style="height: 95px;">
          <div class="card-content">
            <div class="row center">
              <i class="material-icons white-text">bar_chart</i><br />
              <strong class="white-text">Department Stats</strong>
            </div>
          </div>
        </div>
      </a>
    </div>

    <div class="col s6 m4 l3" [@swing]="swing" [ngClass]="{'disabledDiv': !getPermission('Charts')}">
      <a [routerLink]="[]" class="tooltipped" data-position="bottom" data-tooltip="Under Construction">
        <div class="card teal darken-3 hoverable " style="height: 95px;">
          <div class="card-content">
            <div class="row center">
              <i class="material-icons white-text">bar_chart</i><br />
              <strong class="white-text">Charts </strong>
            </div>
          </div>
        </div>
      </a>
    </div>

    <div class="col s6 m4 l3" [@swing]="swing" [ngClass]="{'disabledDiv': !getPermission('Analytics')}">
      <a [routerLink]="[]" class="tooltipped" data-position="bottom" data-tooltip="Under Construction">
        <div class="card pink lighten-1 hoverable " style="height: 95px;">
          <div class="card-content">
            <div class="row center">
              <i class="material-icons white-text">bubble_chart</i><br />
              <strong class="white-text">Analytics</strong>
            </div>
          </div>
        </div>
      </a>
    </div>

    <div class="col s6 m4 l3" [@swing]="swing" [ngClass]="{'disabledDiv': !getPermission('Demographics')}">
      <a [routerLink]="['/admin/stats/demographics']">
        <div class="card amber darken-3 hoverable " style="height: 95px;">
          <div class="card-content">
            <div class="row center">
              <i class="material-icons white-text">show_chart</i><br />
              <strong class="white-text">Demographics</strong>
            </div>
          </div>
        </div>
      </a>
    </div>

    <div class="col s6 m4 l3" [@swing]="swing" [ngClass]="{'disabledDiv': !getPermission('Reports')}">
      <a [routerLink]="[]" class="tooltipped" data-position="bottom" data-tooltip="Under Construction">
        <div class="card cyan darken-2 hoverable " style="height: 95px;">
          <div class="card-content">
            <div class="row center">
              <i class="material-icons white-text">description</i><br />
              <strong class="white-text">Reports </strong>
            </div>
          </div>
        </div>
      </a>
    </div>
  </div>
 -->


  <!-- <div class="row" *ngIf="pollsAndSurveyMenu">

  <div class="col s6 m4 l3" [@swing]="swing">
    <div class="card blue hoverable" (click)="backClicked()" style="height: 95px;">
      <div class="card-content">
        <div class="row center">
          <i class="material-icons white-text">house</i>
          <br />
          <strong class="white-text">Back</strong>
        </div>
      </div>
    </div>
  </div>

  <div class="col s6 m4 l3" [@swing]="swing" [ngClass]="{'disabledDiv': !polls}">
    <a [routerLink]="['/admin/polls-list']">
      <div class="card pink darken-3 hoverable " style="height: 95px;">
        <div class="card-content">
          <div class="row center">
            <i class="material-icons white-text">poll</i><br />
            <strong class="white-text">Polls/Referendum</strong>
          </div>
        </div>
      </div>
    </a>
  </div>

  <div class="col s6 m4 l3" [@swing]="swing" [ngClass]="{'disabledDiv': !surveys}">
    <a [routerLink]="['/admin/survey-list']">
      <div class="card cyan darken-2 hoverable " style="height: 95px;">
        <div class="card-content">
          <div class="row center">
            <i class="material-icons white-text">description</i><br />
            <strong class="white-text">Surveys </strong>
          </div>
        </div>
      </div>
    </a>
  </div>

</div> -->

  <!-------------------------------- Settings ---------------------------------------->
  <div class="row" *ngIf="settingsMenu">

    <div class="col s6 m4 l3" [@swing]="swing">
      <div class="card blue hoverable" (click)="backClicked()" style="height: 95px;">
        <div class="card-content">
          <div class="row center">
            <i class="material-icons white-text">house</i>
            <br />
            <strong class="white-text">Back</strong>
          </div>
        </div>
      </div>
    </div>

    <!-- [ngClass]="{'disabledDiv': !appSetting}" -->
    <div class="col s6 m4 l3" [@swing]="swing" [ngClass]="{'disabledDiv': !getPermission('MenuSettings')}">
      <a [routerLink]="['/admin/app-settings']">
        <div class="card  purple darken-2 hoverable" style="height: 95px;">
          <div class="card-content">
            <div class="row center">
              <i class="material-icons white-text">phonelink_setup</i><br />
              <strong class="white-text">Menu Settings</strong>
            </div>
          </div>
        </div>
      </a>
    </div>

    <div class="col s6 m4 l3" [@swing]="swing" [ngClass]="{'disabledDiv': !getPermission('NationSettings')}">
      <a [routerLink]="['/admin/nation-settings']">
        <div class="card  pink darken-3 hoverable" style="height: 95px;">
          <div class="card-content">
            <div class="row center">
              <i class="material-icons white-text">phonelink_setup</i><br />
              <strong class="white-text">Nation Settings</strong>
            </div>
          </div>
        </div>
      </a>
    </div>

    <!-- [ngClass]="{'disabledDiv': !landing}" -->
    <div class="col s6 m4 l3" [@swing]="swing" [ngClass]="{'disabledDiv': !getPermission('LandingPage')}">
      <a [routerLink]="['/admin/landing-page-settings']">
        <div class="card  yellow darken-4 hoverable" style="height: 95px;">
          <div class="card-content">
            <div class="row center">
              <i class="material-icons white-text">web</i><br />
              <strong class="white-text">Landing Page</strong>
            </div>
          </div>
        </div>
      </a>
    </div>

    <!-- [ngClass]="{'disabledDiv': !appMenu}" -->
    <div class="col s6 m4 l3" [@swing]="swing" [ngClass]="{'disabledDiv': !getPermission('AppMenu')}">
      <a [routerLink]="['/admin/app-menu-settings']">
        <div class="card  cyan darken-1 hoverable" style="height: 95px;">
          <div class="card-content">
            <div class="row center">
              <i class="material-icons white-text">list</i><br />
              <strong class="white-text">App Menu</strong>
            </div>
          </div>
        </div>
      </a>
    </div>

    <!-- <div class="col s6 m4 l3" [@swing]="swing" *ngIf="isDemo" [ngClass]="{'disabledDiv': !contactSetting}">
    <a [routerLink]="['/admin/contact-settings']">
    <div class="card teal darken-3 hoverable" style="height: 95px;" >
      <div class="card-content">
      <div class="row center">
        <i class="material-icons white-text">contact_mail</i><br/>
        <strong class="white-text">Contact Settings</strong>
      </div>
      </div>
    </div>
  </a>
  </div>  -->

    <!-- [ngClass]="{'disabledDiv': !manageAdmin}" -->
    <div class="col s6 m4 l3" [@swing]="swing" [ngClass]="{'disabledDiv': !getPermission('AdminMenu')}">
      <a [routerLink]="['/admin/admin-menu-settings']">
        <div class="card red lighten-1 hoverable" style="height: 95px;">
          <div class="card-content">
            <div class="row center">
              <i class="material-icons white-text">list</i><br />
              <strong class="white-text">Admin Menu</strong>
            </div>
          </div>
        </div>
      </a>
    </div>

    <!-- [ngClass]="{'disabledDiv': !manageAdmin}" -->
    <div class="col s6 m4 l3" [@swing]="swing" [ngClass]="{'disabledDiv': !getPermission('ManageAdmins')}">
      <a [routerLink]="['/admin/admins-list']">
        <div class="card  green hoverable" style="height: 95px;">
          <div class="card-content">
            <div class="row center">
              <i class="material-icons white-text">supervised_user_circle</i><br />
              <strong class="white-text">Manage Admins</strong>
            </div>
          </div>
        </div>
      </a>
    </div>


    <!-- [ngClass]="{'disabledDiv': !demoReq}" -->
    <div class="col s6 m4 l3" [@swing]="swing" *ngIf="isDemo"
      [ngClass]="{'disabledDiv': !getPermission('DemoRequest')}">
      <a [routerLink]="['/admin/demo-request-list']">
        <div class="card  teal darken-3 hoverable" style="height: 95px;">
          <div class="card-content">
            <div class="row center">
              <i class="material-icons white-text">preview
              </i><br />
              <strong class="white-text">Demo Request</strong>
            </div>
          </div>
        </div>
      </a>
    </div>


    <!-- [ngClass]="{'disabledDiv': !social}" -->
    <div class="col s6 m4 l3" [@swing]="swing" [ngClass]="{'disabledDiv': !getPermission('SocialMedia')}">
      <a [routerLink]="['/admin/social-media']">
        <div class="card pink hoverable" style="height: 95px;">
          <div class="card-content">
            <div class="row center">
              <i class="material-icons white-text">share</i><br />
              <strong class="white-text">Social Media</strong>
            </div>
          </div>
        </div>
      </a>
    </div>


    <div class="col s6 m4 l3" [@swing]="swing" [ngClass]="{'disabledDiv': !getPermission('AreaOfInterests')}">
      <a [routerLink]="['/admin/areas-of-interest']">
        <div class="card cyan text-darken-2 hoverable" style="height: 95px;">
          <div class="card-content">
            <div class="row center">
              <i class="material-icons white-text">view_comfy</i><br />
              <strong class="white-text">Areas Of Interests</strong>
            </div>
          </div>
        </div>
      </a>
    </div>


    <div class="col s6 m4 l3" [@swing]="swing" [ngClass]="{'disabledDiv': !getPermission('UpdateDatabase')}">
      <div class="card orange darken-5 hoverable" (click)="updateDatabaseClick()" style="height: 95px;">
        <div class="card-content">
          <div class="row center">
            <i class="material-icons white-text">cloud_upload</i><br />
            <strong class="white-text">Update Database</strong>
          </div>
        </div>
      </div>
    </div>

    <!-- 
  <div class="col s6 m4 l3" [@swing]="swing">
    <a [routerLink]="['/admin/courses-list']">
    <div class="card orange darken-5 hoverable"  style="height: 95px;" >
      <div class="card-content">
      <div class="row center">
        <i class="material-icons white-text">class</i><br/>
        <strong class="white-text">Course Admin</strong>
      </div>
      </div>
    </div>
    </a>
  </div>
-->

  </div>

  <!-------------------------------- Applications ---------------------------------------->
  <div class="row" *ngIf="applicationsMenu">

    <div class="col s6 m4 l3" [@swing]="swing">
      <div class="card blue hoverable" (click)="backClicked()" style="height: 95px;">
        <div class="card-content">
          <div class="row center">
            <i class="material-icons white-text">house</i>
            <br />
            <strong class="white-text">Back</strong>
          </div>
        </div>
      </div>
    </div>

    <!-- [ngClass]="{'disabledDiv': !applicationList}" -->
    <div class="col s6 m4 l3" [@swing]="swing" *ngIf="isDemo"
      [ngClass]="{'disabledDiv': !getPermission('Applications')}">
      <a [routerLink]="['/admin/applications/list']">
        <div class="card  purple darken-2 hoverable" style="height: 95px;">
          <div class="card-content">
            <div class="row center">
              <i class="material-icons white-text">apps</i>
              <br />
              <strong class="white-text">Applications</strong>
            </div>
          </div>
        </div>
      </a>
    </div>

    <!-- [ngClass]="{'disabledDiv': !createApplication}" -->
    <div class="col s6 m4 l3" [@swing]="swing" *ngIf="isDemo"
      [ngClass]="{'disabledDiv': !getPermission('NewApplication')}">
      <a [routerLink]="['/admin/applications/create']">
        <div class="card yellow darken-4 hoverable" style="height: 95px;">
          <div class="card-content">
            <div class="row center">
              <i class="material-icons white-text">dashboard_customize</i>
              <br />
              <strong class="white-text">Create New Application</strong>
            </div>
          </div>
        </div>
      </a>
    </div>


  </div>

  <!-------------------------------- Incoming Applications ---------------------------------------->
  <div class="row" *ngIf="incomingAppsMenu">

    <div class="col s6 m4 l3" [@swing]="swing">
      <div class="card blue hoverable" (click)="backClicked()" style="height: 95px;">
        <div class="card-content">
          <div class="row center">
            <i class="material-icons white-text">house</i>
            <br />
            <strong class="white-text">Back</strong>
          </div>
        </div>
      </div>
    </div>

    <!-- [ngClass]="{'disabledDiv': !postSecondary}" -->
    <div class="col s6 m4 l3" [@swing]="swing" *ngIf="isDemo"
      [ngClass]="{'disabledDiv': !getPermission('PostSecondary')}">
      <a [routerLink]="['/admin/incoming-apps/post-secondary']">
        <div class="card  purple darken-2 hoverable" style="height: 95px;">
          <div class="card-content">
            <div class="row center">
              <i class="material-icons white-text">school</i>
              <app-new-notification-badge [content]="{component: 'post-secondary', color: 'blue', textColor: 'white' }">
              </app-new-notification-badge>
              <br />
              <strong class="white-text">Post Secondary</strong>
            </div>
          </div>
        </div>
      </a>
    </div>

    <!-- [ngClass]="{'disabledDiv': !incomeAssistance}" -->
    <div class="col s6 m4 l3" [@swing]="swing" *ngIf="isDemo"
      [ngClass]="{'disabledDiv': !getPermission('IncomeAssistance')}">
      <a [routerLink]="['/admin/incoming-apps/income-assistance']">
        <div class="card yellow darken-4 hoverable" style="height: 95px;">
          <div class="card-content">
            <div class="row center">
              <i class="material-icons white-text">request_quote</i>
              <app-new-notification-badge [content]="{component: 'income-assistance'}"></app-new-notification-badge>
              <br />
              <strong class="white-text">Income Assistance</strong>
            </div>
          </div>
        </div>
      </a>
    </div>

    <!-- [ngClass]="{'disabledDiv': !housing}" -->
    <div class="col s6 m4 l3" [@swing]="swing" [ngClass]="{'disabledDiv': !getPermission('Housing')}">
      <a class="tooltipped" data-position="bottom" data-tooltip="Under Construction">
        <!-- [routerLink]="['/admin/incoming-apps/housing']" -->
        <div class="card  cyan darken-1 hoverable" style="height: 95px;">
          <div class="card-content">
            <div class="row center">
              <i class="material-icons white-text">cottage</i><br />
              <strong class="white-text">Housing</strong>
            </div>
          </div>
        </div>
      </a>
    </div>

    <!-- [ngClass]="{'disabledDiv': !ISETS}" -->
    <div class="col s6 m4 l3" [@swing]="swing" *ngIf="isDemo" [ngClass]="{'disabledDiv': !getPermission('ISETS')}">
      <a class="tooltipped" data-position="bottom" data-tooltip="Under Construction">
        <div class="card teal darken-3 hoverable" style="height: 95px;">
          <div class="card-content">
            <div class="row center">
              <i class="material-icons white-text">miscellaneous_services</i><br />
              <strong class="white-text">ISETS</strong>
            </div>
          </div>
        </div>
      </a>
    </div>

    <div class="col s6 m4 l3" [@swing]="swing" [ngClass]="{'disabledDiv': !lands}"
      [ngClass]="{'disabledDiv': !getPermission('Lands')}">
      <a class="tooltipped" data-position="bottom" data-tooltip="Under Construction">
        <div class="card  green hoverable" style="height: 95px;">
          <div class="card-content">
            <div class="row center">
              <i class="material-icons white-text">crop_landscape</i><br />
              <strong class="white-text">Lands</strong>
            </div>
          </div>
        </div>
      </a>
    </div>

    <!-- [ngClass]="{'disabledDiv': !CFS}" -->
    <div class="col s6 m4 l3" [@swing]="swing" [ngClass]="{'disabledDiv': !getPermission('CFS')}">
      <a class="tooltipped" data-position="bottom" data-tooltip="Under Construction">
        <div class="card  teal darken-3 hoverable" style="height: 95px;">
          <div class="card-content">
            <div class="row center">
              <i class="material-icons white-text">family_restroom
              </i><br />
              <strong class="white-text">CFS</strong>
            </div>
          </div>
        </div>
      </a>
    </div>

    <div class="col s6 m4 l3" [@swing]="swing" [ngClass]="{'disabledDiv': !getPermission('IASurvey')}">
      <a [routerLink]="['/admin/applications/lrrcn-list']">
        <div class="card pink darken-4 hoverable " style="height: 95px;">
          <div class="card-content">
            <div class="row center">
              <i class="material-icons white-text">list_alt</i>
              <br />
              <strong class="white-text">IA Survey</strong>
            </div>
          </div>
        </div>
      </a>
    </div>


    <!-- <div class="col s6 m4 l3" [@swing]="swing">
    <a [routerLink]="['/admin/applications/lrrcn-list']">
      <div class="card pink  hoverable " style="height: 95px;">
        <div class="card-content">
          <div class="row center">
            <i class="material-icons white-text">list_alt</i>
            <br />
            <strong class="white-text">IA Survey Results</strong>
          </div>
        </div>
      </div>
    </a>
  </div> -->
  </div>

</div>