import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { AboutusService } from 'src/app/services/firebase/aboutus.service';
import { toast } from 'materialize-css';
import { SessionsMonitorService } from 'src/app/services/firebase/sessions-monitor.service';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';

@Component({
  selector: 'app-update-about-section',
  templateUrl: './update-about-section.component.html',
  styleUrls: ['./update-about-section.component.css']
})
export class UpdateAboutSectionComponent implements OnInit {

  adminThemeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  myModel: any;
  id: any;
  valueEmittedFromChildComponent: any;
  sectionName: string;

  constructor(
    private aboutUsService: AboutusService,
    private location: Location,
    private route: ActivatedRoute,
    private sessionMonitor: SessionsMonitorService,
    private settingsService: AppSettingsService
  ) { }

  ngOnInit() {
    this.settingsService.getMenuName('MyNation').valueChanges().subscribe(menus => {
      if (menus && menus.length > 0) {
        let submenus = menus[0].submenus;
        let menu = submenus.filter(m => m.name == 'AboutUs');
        if (menu && menu.length > 0) this.sectionName = menu[0].displayName;
      }
    });

    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });

    if (this.id) {
      this.aboutUsService.getSectionByID(this.id).valueChanges().subscribe(obj => {
        if (obj) {
          this.myModel = obj;
        }
      });
    }
  }

  updateEventHandler(valueEmitted) {
    this.valueEmittedFromChildComponent = valueEmitted;
    if (this.valueEmittedFromChildComponent === true) {
      this.sessionMonitor.newActivity('About Us', 'Section Updated');
      toast({ html: 'About Us Section Successfully Updated!', classes: 'green' });
    }
  }

  deleteEventHandler(valueEmitted) {
    this.valueEmittedFromChildComponent = valueEmitted;
    if (this.valueEmittedFromChildComponent === true) {
      this.sessionMonitor.newActivity('About Us', 'Section Deleted');
      toast({ html: 'About Us Section Successfully Deleted!', classes: 'green' });
      this.backClicked();
    }
  }

  backClicked() {
    this.location.back();
  }
}
