import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Location } from '@angular/common';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { EntitlementService } from 'src/app/services/api/entitlement.service';
import { EntitlementModel } from 'src/app/models/entitlementModel';
import { environment } from 'src/environments/environment';

declare var $: any;

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.css']
})

export class HistoryComponent implements OnInit {

  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  entitlement: EntitlementModel[];
  years: number[];
  data: EntitlementModel[];
  result: any = null;
  applicantID = 0;
  fiscalYear: any;

  constructor(private authService: AuthService, private entitlementService: EntitlementService, private location: Location) { localStorage.setItem('from', 'SocialAssistance'); }

  ngOnInit() {
    if (this.authService.globalUser.applicantID) {
      this.applicantID = Number(this.authService.globalUser.applicantID);
    } else {
      this.applicantID = this.authService.getCurrentApplicantID();
    }

    this.entitlementService.getEntitlement(this.applicantID).subscribe(x => {
      this.entitlement = x;
      this.years = Array.from(new Set(this.entitlement.map(a => a.Year))).sort((a, b) => b - a);

      setTimeout(() => {
        $('select').formSelect();
      }, 25);
    });
  }

  newYearSelected(value: number, fiscalYear1: any, fiscalYear2: any) {

    this.data = [];
    this.fiscalYear = fiscalYear1 + ' - ' + fiscalYear2;

    for (const entry of this.entitlement) {
      if (!entry.date_signed_applicant) {

        if ((Number(entry.Year) === Number(value) && (
          entry.Month === 'April'
          || entry.Month === 'May'
          || entry.Month === 'June'
          || entry.Month === 'July'
          || entry.Month === 'August'
          || entry.Month === 'September'
          || entry.Month === 'October'
          || entry.Month === 'November'
          || entry.Month === 'December'
        )) || (Number(entry.Year) === Number(value + 1) && (
          entry.Month === 'January'
          || entry.Month === 'February'
          || entry.Month === 'March'
        ))) {
          this.data.push(entry);
        }

        if (Number(entry.Year) === Number(value + 1) && (
          entry.Month === 'January'
          || entry.Month === 'February'
          || entry.Month === 'March'
        )) {
          this.data.push(entry);
        }
      }
    }

    this.result = Array.from(new Set(this.data.map(a => a.id))).map(id => {
      return {
        id,
        BDno: this.data.find(x => x.id === id).BDNo,
        Month: this.data.find(x => x.id === id).Month,
        Year: this.data.find(x => x.id === id).Year,
        Core_essentials: this.data.find(x => x.id === id).Core_essentials,
        Core_Shelter: this.data.find(x => x.id === id).Core_Shelter,
        Special_needs: this.data.find(x => x.id === id).Special_needs,
        COPH: this.data.find(x => x.id === id).COPH,
        Amount: this.data.find(x => x.id === id).Amount,
        PayableTo: this.data.find(x => x.id === id).PayableTo,
        dateCreated: this.dateCreated(this.data.find(x => x.id === id).Month, this.data.find(x => x.id === id).Year),

      };
    }).sort((a, b) => +new Date(b.dateCreated) - +new Date(a.dateCreated));
  }

  backClicked() {
    this.location.back();
  }

  dateCreated(month: string, year: number) {
    const months = ['January', 'February', 'March', 'April',
      , 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const monthIndex = months.indexOf(month);
    return new Date(year, monthIndex, 1);
  }
}