import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
     name: 'SinStars'
})
export class SinStarsPipe implements PipeTransform {
   transform(value: string): any {
      const x = value.substring(0 , 2) + '*****' + value.substring(7 , 9);
      return x;
   }
}
