<div>
  <div *ngIf="loading"></div>

  <div class="row" *ngIf="!loading">
    <!-- =========== Left Navigation ============= -->
    <div class="col s12 m2 l1">
      <div class="small-side-nav center" [ngStyle]="{ 'background-color': themeColor }">
        <div class="">
          <a [routerLink]="['/admin/dashboard']"><i class="material-symbols-outlined">home</i>
            <p class="hide-on-med-and-down">Home</p>
          </a>
          <ng-container *ngIf="!showProfiles">
            <div *ngFor="let item of menuItems; let i = index">
              <a (click)="navigate(item.url)">
                <i class="material-symbols-outlined">{{ item.icon }}</i>
                <p class="hide-on-med-and-down">{{ item.name }}</p>
              </a>
            </div>
          </ng-container>
        </div>
      </div>
    </div>

    <div class="col s12 m10 l10 right-side">
      <div class="no-profile" *ngIf="!hasProfile">
        <div>
          <div class="user" *ngIf="user" class="bold-800 text-xl">
            Welcome
            <span [ngStyle]="{ color: themeColor }">{{ user.FirstName }}</span>
            <span class="text-base font-normal"> we're glad your here</span>
          </div>

          <div>
            <p>
              Seems like you don't have any profiles. Create your profile to
              start posting to your social accounts!
            </p>
            <a class="modal-trigger white-text btn tooltipped" [ngStyle]="{ 'background-color': themeColor }"
              data-target="createProfileModal">
              <i class="material-icons right">post_add</i>Create Profile</a>
          </div>
        </div>
      </div>

      <div *ngIf="hasProfile">
        <div class="mb-2 md:mb-0">
          <h6 class="black-text account-text">
            Hello. You're on the account for
            <span class="block text-customTeal bold-800 text-lg" [ngStyle]="{ color: themeColor }">{{
              selectedProfile.title }}</span>
          </h6>
          <div class="flex flex-col md:flex-row gap-x-6 gap-y-3"></div>
        </div>
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>

<div id="createProfileModal" class="modal">
  <div class="modal-content p-b-0">
    <h6 class="bold-800 black-text">Create Profile</h6>
    <small class="grey-text lighten-2">Create your profile and start managing your social medias.
    </small>
    <div>
      <form [formGroup]="profileForm" (ngSubmit)="createUserProfile(profileForm.value)">
        <div class="grid grid-cols-1 gap-y-5">
          <div class="form-control w-full max-w-x">
            <label class="label">
              <span class="heading-title">Title</span>
            </label>
            <input type="text" formControlName="title" placeholder="Type here"
              class="input input-bordered input-info w-full" />
          </div>

          <p>
            <label>
              <input type="checkbox" checked="checked" class="filled-in" formControlName="terms" />
              <span>By clicking Register, I agree to
                <a href="https://www.ayrshare.com/terms/" target="__blank"
                  class="underline-offset-2 text-customTeal">Terms of Service
                </a></span>
            </label>
          </p>

          <div *ngIf="(terms.dirty || terms.touched) && terms.invalid">
            <small *ngIf="terms.errors.required" class="text-error">This section is required you need to agree to the
              terms and
              conditions and privacy policy to proceed..
            </small>
          </div>

          <div class="modal-footer">
            <a class="modal-close waves-effect waves-green btn-flat bold-700">Cancel</a>
            <button type="submit" [disabled]="!profileForm.valid" [ngStyle]="{ 'background-color': themeColor }"
              [ngClass]="{
                'gray lighten-1 black-text': !profileForm.valid
              }" class="waves-effect waves-green btn-flat font-bold white-text">
              Create
            </button>
          </div>

          <div class="flex flex-col space-y-4 pt-4"></div>
        </div>
      </form>
    </div>
  </div>
</div>

<div id="profileCreatedModal" class="modal">
  <div class="modal-content">
    <div class="profile-created-container">
      <p class="bold-800 black-text pt-6 profile-created-title">
        You’ve created a profile
      </p>

      <div class="[#636363]-text profile-created-text">
        Ayrshare is our partner that helps us make your social media management
        easy and convinent. Please Link accounts you’d like to manage.
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <app-link-account [link]="false" [new]="true" class="modal-close"></app-link-account>
    <a [ngStyle]="{ 'background-color': themeColor }"
      class="modal-close waves-effect waves-green btn-flat white-text bold-700">Got it</a>
  </div>
</div>

<div id="underConstructionModal" class="modal center b-n-1">
  <div class="modal-content">
    <i class="material-icons right modal-close">close</i><br />
    <h6 class="title-section2 bold-600 black-text">Under Construction</h6>
    <p class="sub-title-section bold-100">
      We’re working hard to make this feature available soon.
    </p>
    <img src="https://img.icons8.com/wired/344/tow-truck.png" height="150px" />
    <p class="sub-title-section bold-100">
      In the meantimee if you need some support? Contact us on info&#64;mynation.app
    </p>
    <p class="sub-title-section bold-100">
      Please Contanct your Administrator to gain access.
    </p>
  </div>
</div>