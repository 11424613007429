import { Component, OnInit } from '@angular/core';
import { Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { NationWallService } from 'src/app/services/firebase/nation-wall.service';
import { WallPostModel } from 'src/app/models/WallPostModel';
import { toast } from 'materialize-css';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';

@Component({
	selector: 'app-add-nation-wall-post',
	templateUrl: './add-nation-wall-post.component.html',
	styleUrls: ['./add-nation-wall-post.component.css']
})

export class AddNationWallPostComponent implements OnInit {
	addNationWallPostForm: UntypedFormGroup;
	model: WallPostModel;
	themeColor = environment.appTheme.themeColor
	constructor(private fb: UntypedFormBuilder,
		private nationWallService: NationWallService,
		private location: Location) { }

	ngOnInit() {
		this.addNationWallPostForm = this.fb.group({
			name: ["", [Validators.required]]
		});
	}

	get name() { return this.addNationWallPostForm.get("name"); }

	submitForm(value: any) {
		this.model = new WallPostModel();
		this.model.name = value.name;
		console.log("this.model: ", this.model)

		this.nationWallService.addWallPost(this.model).then(x => {
			toast({ html: '<p> Wall Post Posted Successfully!!</p>', classes: 'green' });
			this.backClicked();
		});
	}

	backClicked() {
		this.location.back();
	}
}