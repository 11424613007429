import { Component, OnInit } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-request-registration',
  templateUrl: './request-registration.component.html',
  styleUrls: ['./request-registration.component.css']
})

export class RequestRegistrationComponent implements OnInit {

  constructor(){};
  ngOnInit() {}

  /*
  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  requestTojoinForm: FormGroup;
  step1 = true;
  step2 = false;
  step3 = false;
  step4 = false;
  step5 = false;
  clientModel: FindClientModel;
  client: any;  //ClientDataModel;   ///
  step6 = false;
  step7 = false;

  constructor(private fb: FormBuilder,
              private requesttojoinService:  IsetsRequesttojoinService,
              private location: Location,
              private sqlRegistrationRequest: ISetsRegistrationRequestService,
              private router: Router, private clientService: ISETSClientService) { }

  ngOnInit() {

        this.requestTojoinForm = this.fb.group({
          firstName: ['', [Validators.required]],
          lastName: ['', [Validators.required]],
          middle_initial: [''],
          //dob: [''],
          dob: ['', [Validators.required]],
          email: ['', [Validators.required, Validators.email]],
        // officer: ['', [Validators.required]],
        });

        setTimeout(() => {
          $('.datepicker').datepicker({
            format: 'dd/mmm/yyyy',
            defaultDate: new Date(1990, 0, 1),
            yearRange: 40,
            minDate: new Date(1940, 0, 1),
            maxDate: new Date(new Date().getFullYear(), 12, 31),
            onSelect(e) {
              localStorage.setItem('dob', e);
            },
          });

          $('select').formSelect();
        }, 25);
  }

  SubmitRequest(model: ISETSRequestToJoinModel) {

    if (environment.firstNation.SQLDB === true) {

      this.clientModel = new FindClientModel();
      this.clientModel.firstName = this.requestTojoinForm.value.firstName.trim();
      this.clientModel.lastName = this.requestTojoinForm.value.lastName.trim();
      this.clientModel.dob = this.requestTojoinForm.value.dob;

      this.clientService.findClientByName(this.clientModel).subscribe(x => {
        if (x) {
          this.client = x[0];
         if (this.client.invite_status ==='Pending' ) {
          this.step1 = false;
          this.step2 = false;
          this.step6 = true;

          } else if (this.client.invite_status ==='Accepted') {
            this.step1 = false;
            this.step2 = false;
            this.step7 = true;

          }else { 

              this.clientService.findRegistrationByName(this.clientModel).subscribe(y => { 
                if (y) {
                  this.step1 = false;
                  this.step2 = false;
                  this.step4 = true;
                 
                }else{

                  this.clientService.findNewApplicationByName(this.clientModel).subscribe(z => { 
                    if (z) {
                      this.step1 = false;
                      this.step2 = false;
                      this.step5 = true;
                    
                    }else{

                      const obj = {
                        Fname: model.firstName.trim(),
                        Lname: model.lastName.trim(),
                        Mname: model.middle_initial,
                        email: model.email,
                        //dob: (localStorage.getItem('dob') ? new Date(localStorage.getItem('dob')): null ),
                        dob: model.dob,
            
                        datetime: new Date(),
                        processed: false,
                        // officer: model.officer,
                      };
            
                      this.sqlRegistrationRequest.saveRegistrationRequest(obj).subscribe(x => {
                   
                        const emailModel = new UpdateEmailModel();
                        emailModel.clientID = this.client.ID;
                        emailModel.email = this.requestTojoinForm.value.email;
                       
                        this.clientService.updateClientEmail(emailModel).subscribe( x => {
                          if (x) {
                          //  toast({html: 'Email Successfully Updated.', classes: 'green'});
                            $('.modal').modal('close');

                            this.step1 = false;
                            this.step2 = true;
                          }
                        });
                      });
                    }
                  });
                }
              });       
          }
        }else{
          this.step1 = false;
          this.step2 = false;
          this.step3 = true; 
        }
      });
    }

    if (environment.firstNation.SQLDB === false) {
      // model.dob = (localStorage.getItem('dob') ? localStorage.getItem('dob') : '' ),
        model.dob = (localStorage.getItem('dob') ? new Date(localStorage.getItem('dob')) : null ),

        this.requesttojoinService.newRegistrationRequest(model).then(x => {
          this.step1 = false;
          this.step2 = true;
        }).catch(error => toast({ html: error.message, classes: 'red' }));
    }
  }

  get firstName() { return this.requestTojoinForm.get('firstName'); }
  get middleName() { return this.requestTojoinForm.get('middleName'); }
  get lastName() { return this.requestTojoinForm.get('lastName'); }
  get email() { return this.requestTojoinForm.get('email'); }
  get dob() { return this.requestTojoinForm.get('dob'); }


  backClicked() {
    this.location.back();
  }

  back() {
    this.router.navigate(['']);
  }

  BirthDateChange(evt) {
    if (this.requestTojoinForm ) {
      this.requestTojoinForm.patchValue({dob : $('#dob').val()});
     }

     setTimeout(() => {
      $('.datepicker').datepicker({
        format: 'dd/mmm/yyyy',
        defaultDate: new Date(1990, 0, 1),
        yearRange: 40,
        minDate: new Date(1940, 0, 1),
        maxDate: new Date(new Date().getFullYear(), 12, 31),
        onSelect(e) {
          localStorage.setItem('dob', e);
        },
      });

        $('select').formSelect();
      }, 25);
   }
*/
}