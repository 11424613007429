import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/firebase/auth.service';
import { map, take, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})

export class MemberAuthGuard  {

  constructor(private auth: AuthService, private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    if (this.auth.isLogged && this.auth.role !== '') {
      if (this.auth.role === 'Member' || (this.auth.role == 'Admin' && this.auth.isMember) || (this.auth.role == 'Employee' && this.auth.isMember)) {
        return true;
      } else { 
        this.router.navigate(['/auth/login'])
        return false; }
    }

    return this.auth.afAuth.authState
      .pipe(
        take(1),
        map(user => !!user),
        tap(
          loggedIn => {
            if (!loggedIn) {
              this.router.navigate(['/auth/login']);
            }
          }
        )
      );
  }

}
