<div class="col s12">
    <div class="body-h">
        <div class="header-setting b-m-2">
            <h4>Demographics</h4>
        </div>
      
        <main class="StickyContent white ">
            <ul class="collapsible" *ngIf="false">
                <li>
                    <div class="collapsible-header"><i class="material-icons">unfold_more</i>Show Filters</div>
                    <div class="row collapsible-body">

                        <form [formGroup]="filterForm" (ngSubmit)="getResult(filterForm.value)">

                            <div class="input-field col s12 m6">
                                <i class="material-icons prefix pink-text">account_circle</i>
                                <input id="firstName" type="text" formControlName='firstName' autocomplete="off"
                                    style="text-transform: capitalize;" />
                                <label for="firstName">First Name</label>
                            </div>

                            <div class="input-field col s12 m6">
                                <i class="material-icons prefix purple-text">account_circle</i>
                                <input id="lastName" type="text" formControlName='lastName' autocomplete="off"
                                    style="text-transform: capitalize;" />
                                <label for="lastName">Last Name</label>
                            </div>

                            <div class="input-field col s12 m6">
                                <i class="material-icons prefix blue-text">wc</i>
                                <select formControlName="gender">
                                    <option [value]="" selected>Show All</option>
                                    <option *ngFor="let g of genders" [value]="g">{{g}}</option>
                                </select>
                                <label>Gender </label>
                            </div>

                            <div class="input-field col s12 m6">
                                <i class="material-icons prefix orange-text">location_on</i>
                                <select multiple formControlName="eduLevel">
                                    <option [value]="" disabled selected>Show All</option>
                                    <option *ngFor="let edu of educationlevels" [value]="edu.value">{{edu.level}}
                                    </option>
                                </select>
                                <label>Education Level</label>
                            </div>

                            <div class="input-field">
                                <div class="row col s12">
                                    <p>Residency</p>
                                    <label class="col s6">
                                        <input name="onReserve" type="radio" formControlName="onReserve"
                                            [checked]="nationalResidency" [value]="true" (click)="onReserveYes()" />
                                        <span>Nation Residents</span>
                                    </label>
                                    <label class="col s6">
                                        <input name="onReserve" type="radio" formControlName="onReserve"
                                            [checked]="urbanResidency" [value]="false" (click)="onReserveNo()" />
                                        <span>Urban Residents</span>
                                    </label>
                                </div>
                            </div>

                            <div class="input-field col s12" *ngIf="showCommunity">
                                <i class="material-icons prefix orange-text">location_on</i>
                                <select multiple formControlName="community">
                                    <option [value]="" disabled selected>Show All</option>
                                    <option *ngFor="let c of communities" [value]="c.community">{{c.community}}</option>
                                </select>
                                <label>Community</label>
                            </div>

                            <div class="input-field">
                                <div class="row col s12">
                                    <p>Membership</p>
                                    <label class="col s6">
                                        <input name="isMember" type="radio" formControlName="isMember"
                                            [checked]="member" [value]="true" (click)="onMemberYes()" />
                                        <span>Members</span>
                                    </label>
                                    <label class="col s6">
                                        <input name="isMember" type="radio" formControlName="isMember"
                                            [checked]="notMember" [value]="false" (click)="onMemberNo()" />
                                        <span>Non Members</span>
                                    </label>
                                </div>
                            </div>

                            <div formGroupName="birthed_between">
                                <div>Birthed</div>
                                <div class="input-field col s12 m6">
                                    <i class="material-icons prefix green-text">calendar_today</i>
                                    <input id="from" type="number" formControlName='from' autocomplete="off" />
                                    <label for="from">From</label>
                                </div>

                                <div class="input-field col s12 m6">
                                    <i class="material-icons prefix green-text">calendar_today</i>
                                    <input id="to" type="number" formControlName='to' autocomplete="off" />
                                    <label for="to">To</label>
                                </div>
                            </div>

                            <div class="input-field col s12 right-align">
                                <span>
                                    <label>
                                        <input name="deceased" type="checkbox" formControlName="deceased"
                                            [checked]="isDeceased" (click)="deceasedYes()" />
                                        <span>Deceased</span>
                                    </label>
                                </span>
                            </div>

                            <div formGroupName="deceased_between" *ngIf="isDeceased">
                                <div>Deceased</div>
                                <div class="input-field col s12 m6">
                                    <i class="material-icons prefix green-text">calendar_today</i>
                                    <input id="from" type="number" formControlName='from' autocomplete="off" />
                                    <label for="from">From</label>
                                </div>

                                <div class="input-field col s12 m6">
                                    <i class="material-icons prefix green-text">calendar_today</i>
                                    <input id="to" type="number" formControlName='to' autocomplete="off" />
                                    <label for="tp">To</label>
                                </div>
                            </div>

                            <div class="col s12">
                                <button type="submit" class="waves-effect waves-light  blue darken-3 btn-small col s12"
                                    style="margin-bottom: 5px;">
                                    <i class="material-icons right">search</i>Get Data</button>
                            </div>
                        </form>
                    </div>
                </li>
            </ul>

            <div class="row col s12">
                <div class="row" *ngIf="userModel">
                    <div class="col s4 m4 card white center-align">
                        <i class="material-icons green-text hide-on-small-only" style="font-size: 40px;">
                            person
                        </i>
                        <P><span style="font-size: 24px; font-weight: bolder;"
                                class="green-text">{{userModel.TotalUsers}}</span> Users</P>
                    </div>
                    <div class="col s4 m4 card white center-align">
                        <i class="material-icons blue-text hide-on-small-only" style="font-size: 40px;">
                            person_outline
                        </i>
                        <P> <span style="font-size: 24px; font-weight: bolder;"
                                class="blue-text">{{userModel.Employee}}</span>
                            Employees</P>
                    </div>
                    <div class="col s4 m4 card white center-align">
                        <i class="material-icons purple-text hide-on-small-only" style="font-size: 40px;">
                            manage_accounts
                        </i>
                        <P> <span style="font-size: 24px; font-weight: bolder;"
                                class="purple-text">{{userModel.Admins}}</span>
                            Admins</P>
                    </div>
                </div>

                <ul class="collapsible" *ngIf="birthsModel">
                    <li class="active">
                        <div class="collapsible-header"><i class="material-icons">auto_graph</i><strong>Population
                                Growth (Last
                                10 years)</strong></div>
                        <div class="row collapsible-body">
                            <div class="col s12 hide-on-small-only">
                                <div class="chip grey lighten-3" *ngFor="let i of birthsModel">
                                    {{i.yr}} -
                                    <span class="red-text"><strong>{{i.cnt}}</strong></span>
                                </div>
                            </div>
                            <div class="col s12">
                                <div style="display: block;">
                                    <canvas baseChart width="300" height="300" [datasets]="lineChartData"
                                        [labels]="lineChartLabels" [options]="lineChartOptions"
                                        [colors]="lineChartColors" [legend]="lineChartLegend"
                                        [chartType]="lineChartType" [plugins]="lineChartPlugins">
                                    </canvas>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>

                <ul class="collapsible" *ngIf="gendersModel">
                    <li class="active">
                        <div class="collapsible-header"><i class="material-icons">pie_chart</i><strong>Population By
                                Gender</strong></div>
                        <div class="row collapsible-body">

                            <div class="col s12 hide-on-small-only">
                                <div class="col s6 center-align">
                                    <i class="material-icons pink-text" style="font-size: 60px;">
                                        woman
                                    </i>
                                    <h4><strong>{{gendersModel[0].Female}}</strong></h4>
                                </div>
                                <div class="col s6 center-align">
                                    <i class="material-icons blue-text" style="font-size: 60px;">
                                        man
                                    </i>
                                    <h4><strong>{{gendersModel[0].Male}}</strong></h4>
                                </div>

                            </div>
                            <div class="col s12" *ngIf="pieChartData">
                                <div class="chart">
                                    <canvas baseChart width="200" height="150" [data]="pieChartData"
                                        [labels]="pieChartLabels" [chartType]="pieChartType" [options]="pieChartOptions"
                                        [colors]="pieChartColors" [legend]="pieChartLegend">
                                    </canvas>
                                </div>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </main>
    </div>
</div>