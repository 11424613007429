import { Component, OnInit } from '@angular/core';
import { Options } from 'sortablejs';
import { DataService } from 'src/app/services/data.service';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { environment } from 'src/environments/environment';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-section-list',
  templateUrl: './section-list.component.html',
  styleUrls: ['./section-list.component.css']
})

export class SectionListComponent implements OnInit {

  themeColor: any;
  defaultThemeColor = environment.appTheme.themeColor;
  pageSections: any;
  isNewSetting = true;
  isSuperAdmin = false;
  permissions: any[];
  user: any;
  options: Options = {
    animation: 150,
  };
  defaultPublicMenus: any;
  adminMenus: any[];
  showDefaultMenu = true;
  loading = false;
  defaultAdminMenus: any[];
  menus: any;
  defaultMemberMenus: any[];

  constructor(
    private settingsService: AppSettingsService,
    private authService: AuthService,
    public appSettingsService: AppSettingsService,
    public dataService: DataService) {
    this.themeColor = this.settingsService.getAppThemeColor() ? this.settingsService.getAppThemeColor() : this.defaultThemeColor;
    this.defaultAdminMenus = this.dataService.getAdminMenus();
    this.defaultPublicMenus = this.dataService.getPublicMenus();

    // ================= page Sections ======================
    this.options = {
      animation: 150,
      filter: '.disabled',
      onMove: event => {
        return !event.related.classList.contains('disabled');
      },
      onUpdate: (event: any) => {
        this.pageSections.sections.forEach(element => {
          element.index = this.pageSections.sections.findIndex(x => x.name === element.name);
        });
        if (this.isNewSetting) {
          this.settingsService.saveHomePageSettings(this.pageSections);
        } else {
          this.settingsService.updateHomePageSettings(this.pageSections);
        }
      }
    };

    // ================= users ======================
    this.authService.getCurrentApplicantIDObservable().subscribe(
      user => {
        if (user) {
          this.user = user;
          if (user.isSuperAdmin) this.isSuperAdmin = true;
          if (user.permissions) this.permissions = user.permissions;
          else if (!this.isSuperAdmin) {
            $('.modal').modal();
            $('#permissionModal').modal('open');
          }
        }
      }
    );

    // ===================== admin menu ================
    this.settingsService.getAdminMenus().valueChanges().subscribe(menus => {
      if (menus && menus.length > 0) {
        this.adminMenus = menus;
        this.adminMenus = this.adminMenus.sort((a, b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0));
        this.showDefaultMenu = false;
      } else {
        this.adminMenus = this.defaultAdminMenus.sort((a, b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0));
        this.showDefaultMenu = true;
      }
      this.loading = false;
    });
  }

  ngOnInit(): void {
    // ============= public page ===========
    this.settingsService.getHomePageSettings().valueChanges().subscribe(hp => {
      if (hp && hp.length > 0) {
        this.pageSections = hp[0];
        this.isNewSetting = false;
        this.pageSections.sections = this.pageSections.sections.sort((a, b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0));
      } else {
        this.pageSections = this.defaultPublicMenus;
        this.pageSections.sections = this.pageSections.sections.sort((a, b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0));
        this.isNewSetting = true;
      }

      // ============= member page ===========
      this.appSettingsService.getMemberMenus().valueChanges().subscribe(menus => {
        if (menus && menus.length > 0) {
          this.menus = menus;
          this.menus = this.menus.sort((a, b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0));
          this.showDefaultMenu = false;
        } else {
          this.menus = this.defaultMemberMenus.sort((a, b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0));
          this.showDefaultMenu = true;
        }
        this.loading = false;
      });

      $(document).ready(function () {
        $('.collapsible').collapsible();
        $('.modal').modal();
      });
      $(document).ready(function () {
        $('.tabs').tabs();
      });
      setTimeout(() => {
        M.updateTextFields();
      }, 25);
    });
  }

}