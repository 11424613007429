import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class EventsService {

  constructor(private db: AngularFirestore) { }

  eventListRef: AngularFirestoreCollection<any>;
  eventRef: AngularFirestoreDocument<any>;

// Manage Posts ---------------------------------------------------------------------------------------
  addEvent(model: any) {

    model.id = this.db.createId();

    return this.db.collection('Events').doc(model.id).set(model);
  }

  getEventByID(id: string): AngularFirestoreDocument<any> {
    this.eventRef = this.db.doc('Events/' + id);
    return this.eventRef;
  }

  UpdateEvent(model: any) {
    return this.db.collection('Events').doc(model.id).update(model);
  }

  DeleteEvent(model: any) {
    return this.db.collection('Events').doc(model.id).delete();
  }

getEventsList(): AngularFirestoreCollection<any[]> {
  this.eventListRef = this.db.collection('Events');
  return this.eventListRef;
}

getPublicEventsList(): AngularFirestoreCollection<any[]> {
  this.eventListRef = this.db.collection('Events', ref => ref.where('visibility', '==', 'Public'));
  return this.eventListRef;
}

getPrivateEventsList(): AngularFirestoreCollection<any[]> {
  this.eventListRef = this.db.collection('Events', ref => ref.where('visibility', '==', 'Private'));
  return this.eventListRef;
}

SortEventsOnServer(model: any[]) {
  model.forEach(element => {
    this.db.collection('Events').doc(element.id).update(element);
  });
}

getLimitedContentList(limit: number): AngularFirestoreCollection<any[]> {
  this.eventListRef = this.db.collection('Events', ref => ref.orderBy('datePublished', 'desc').limit(limit));
  return this.eventListRef;
}

}
