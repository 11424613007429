
<p><strong>Under Construction</strong></p>

<!-- 
<div class="container">

    <div class="section"></div>
    <div class="row">
        <div class="col m1 l2 "></div>

        <div class="col s12 m10 l8 " *ngIf="step1">
    
          <div class="row card">
            <div class="card-content">
              <div class="row center">
                <strong class="red-text">Note: </strong>
                <strong> Please enter your data accurately. We will match your provided data to our database before confirming your registration.
                    </strong> </div>
              <div>
                <form [formGroup]="requestTojoinForm" (ngSubmit)="SubmitRequest(requestTojoinForm.value)">
    
                  <div class="row" >   
    
                    <div class="input-field col s12">
                      <i class="material-icons prefix purple-text">account_circle</i>
                      <input id="firstName" type="text" formControlName='firstName' class="validate" autocomplete="off" style="text-transform: capitalize;" />
                      <label for="firstName">First Name <strong class="red-text">*</strong></label>
                      <div *ngIf="(firstName.dirty || firstName.touched) && firstName.invalid">
                        <small *ngIf="firstName.errors.required" class="red-text"> The First Name is required. </small>
                      </div>
                    </div>
    
                    <div class="input-field col s12">
                      <i class="material-icons prefix pink-text">account_circle</i>
                      <input id="middle_initial" type="text" formControlName='middle_initial'  autocomplete="off" style="text-transform: capitalize;"/>
                      <label for="middle_initial">Middle Name</label>
                    </div>
    
                    <div class="input-field col s12">
                      <i class="material-icons prefix green-text">account_circle</i>
                      <input id="lastName" type="text" formControlName='lastName' required class="validate" autocomplete="off" style="text-transform: capitalize;"/>
                      <label for="lastName">Last Name <strong class="red-text">*</strong></label>
                      <div *ngIf="(lastName.dirty || lastName.touched) && lastName.invalid">
                        <small *ngIf="lastName.errors.required" class="red-text"> The Last Name is required. </small>
                      </div>
                    </div>
    

                    <div class="row">
                      <p class="red-text center"><strong>Please Select Month then Year Then Day And Click Ok</strong></p>
                      <div class="input-field col s12">
                        <i class="material-icons prefix pink-text">calendar_today</i>
                        <input id="dob" type="text" class="datepicker" (change)="BirthDateChange($event)" maxlength="49" formControlName='dob' autocomplete="off" />
                        <label for="dob">Date Of Birth  <span class="red-text">*</span></label>
                        <div *ngIf="(dob.dirty || dob.touched) && dob.invalid">
                          <small *ngIf="dob.errors.required" class="red-text"> The Date Of Birth is required. </small>
                        </div>
                      </div>
                    </div>

                  
                    <div class="input-field col s12">
                      <i class="material-icons prefix blue-text">email</i>
                      <input id="email" type="email" formControlName='email' required class="validate" autocomplete="off" />
                      <label for="email">Email <strong class="red-text">*</strong></label>
                      <div *ngIf="(email.dirty || email.touched) && email.invalid">
                        <small *ngIf="email.errors.required" class="red-text"> The Email is required. </small>
                        <small *ngIf="email.errors.email" class="red-text"> wrong email format. </small>
                      </div>
                    </div>

                  </div>
    
    
                
                    <div class="row">
                    
                    <div class="section"></div>
                    <div class="row center" >
                    <p><strong>By Clicking <span class="green-text">Request To Join</span> I hereby :</strong></p>
                    <p><strong>Agree and consent to the <a [routerLink]="['/eula']">User Agreement</a>, its policies and the <a [routerLink]="['/privacy-policy']">Privacy Policy</a>.</strong>  </p>
                    </div>
                    
                    
                    <button type="submit" [disabled]="!requestTojoinForm.valid" style="margin-bottom: 15px;" class="col s12 btn waves-effect green">
                                    <i class="material-icons right">cloud</i> Submit Your Request
                    </button>
                    
                    <a [ngClass]="'col s12 btn waves-effect ' + themeColor" (click)="back()"><i class="material-icons left">house</i> Back </a>
                    </div>
                
    
                </form>

              </div>
    
            </div>
    
          </div>
        </div>
    
        <div class="col s12 m10 l8 " *ngIf="step2">
          <div class="card">
            <div class="card-content">
    
              <div class="row center">
    
                <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                  <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/>
                  <path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
                </svg>
                <p class="green-text"> <strong>Your Request To Join Was Successfully Sent</strong></p>
    
              </div>
    
            </div>
          </div>

          <div class="section"></div>
          <div class="col s12">  
            <button type="button" [ngClass]="'col s12 btn waves-effect ' + themeColor " (click)="back()">
              <i class="material-icons left">keyboard_arrow_left</i> Back
            </button>
          </div>

        </div>
    

        <div class="col s12 m10 l8 " *ngIf="step3">
          <div class="card">
            <div class="card-content">
    
              <div class="row center">
             
                <p class="red-text"> <strong>The Data you entered did not match our records. Please call your Account Manager at (825) 993-0846 
                  or email at info@oteenow.com to make sure your name and birth date correct. </strong></p>
                  <div class="section"></div>
                <p class="red-text"><strong>Thank you and have a wonderful day</strong></p>
              </div>
            </div>
          </div>

          <div class="section"></div>
          <div class="col s12">  
            <button type="button" [ngClass]="'col s12 btn waves-effect ' + themeColor " (click)="back()">
              <i class="material-icons left">keyboard_arrow_left</i> Back
            </button>
          </div>

        </div>
        <div class="col s12 m10 l8 " *ngIf="step4">
          <div class="card">
            <div class="card-content">
    
              <div class="row center">
             
                <p class="red-text"> <strong>You have registered as an existing client, No need to register again. Please call your Account Manager at (825) 993-0846 
                  or email at info@oteenow.com  </strong></p>
                  <div class="section"></div>
                <p class="red-text"><strong>Thank you and have a wonderful day</strong></p>
              </div>
    
            </div>
          </div>

          <div class="section"></div>
          <div class="col s12">  
            <button type="button" [ngClass]="'col s12 btn waves-effect ' + themeColor " (click)="back()">
              <i class="material-icons left">keyboard_arrow_left</i> Back
            </button>
          </div>
        </div>
        <div class="col s12 m10 l8 " *ngIf="step5">
          <div class="card">
            <div class="card-content">
    
              <div class="row center">
             
                <p class="red-text"> <strong>You have applied as a new client, No need to apply again. Please call your Account Manager at (825) 993-0846 
                  or email at info@oteenow.com  </strong></p>
                  <div class="section"></div>
                <p class="red-text"><strong>Thank you and have a wonderful day</strong></p>
              </div>
    
            </div>
          </div>

          <div class="section"></div>
          <div class="col s12">  
            <button type="button" [ngClass]="'col s12 btn waves-effect ' + themeColor " (click)="back()">
              <i class="material-icons left">keyboard_arrow_left</i> Back
            </button>
          </div>
        </div>
        <div class="col s12 m10 l8 " *ngIf="step6">
          <div class="card">
            <div class="card-content">
    
              <div class="row center">
              
                <p class="red-text"> <strong>You are aready sent an registration invitation and please follow the process to create your account. If you have any question, Please call your Account Manager at (825) 993-0846 
                  or email at info@oteenow.com  </strong></p>
                  <div class="section"></div>
                <p class="red-text"><strong>Thank you and have a wonderful day</strong></p>
              </div>
    
            </div>
          </div>

          <div class="section"></div>
          <div class="col s12">  
            <button type="button" [ngClass]="'col s12 btn waves-effect ' + themeColor " (click)="back()">
              <i class="material-icons left">keyboard_arrow_left</i> Back
            </button>
          </div>
        </div>
        <div class="col s12 m10 l8 " *ngIf="step7">
          <div class="card">
            <div class="card-content">
    
              <div class="row center">
                <p class="red-text"> <strong>You are aready registered and have your mobile account and no need to register again. If you have any question, Please call your Account Manager at (825) 993-0846 
                  or email at info@oteenow.com  </strong></p>
                  <div class="section"></div>
                <p class="red-text"><strong>Thank you and have a wonderful day</strong></p>
              </div>
    
            </div>
          </div>

          <div class="section"></div>
          <div class="col s12">  
            <button type="button" [ngClass]="'col s12 btn waves-effect ' + themeColor " (click)="back()">
              <i class="material-icons left">keyboard_arrow_left</i> Back
            </button>
          </div>

        </div>
        <div class="col m1 l2 "></div>
      </div>
</div> -->