<div class="row container-pi1">
  <h4 class="title-section1  title-member icon-texts"> <span class="material-symbols-outlined back-icon p-r-1" (click)=" backClicked()">
    arrow_circle_left
</span>Election List</h4>

  <div class="col s12">
    <div class="row  ">
      <app-firebase-loading *ngIf="showLoader"></app-firebase-loading>

      <div class="row card white col s12" *ngIf="!model && !showLoader">
        <p class="center"> <strong class="red-text"> <i class="material-icons">warning</i> No Election Found In the
            Database</strong> </p>
      </div>

      <div *ngIf="activeElection">
        <div class="row col l5 s12 card white" *ngFor="let item of activeElection" style="margin: 9px;">
          <div class=" p-t-2 ">
            <div class="p-b-2">
              <div class="col s6">
                <a [routerLink]="['/member/member-main-home/vote', item.id]"
                  class="truncate bold-700 title-section3">{{item.name}}</a>
              </div>
              <div class="col s6">
                <span class="green-text col s12 right">Open</span>
              </div>
            </div>
            <ul class="col s12 collection">
              <li class="row collection-item">
                <div class="col s4"> <strong class="black-text">End Date</strong></div>
                <div class="col s8 red-text"> <strong class="right"> {{item.endDate}} </strong></div>
              </li>
            </ul>
            <div class="col s12">
              <a class="center col btn-flat s12 blue lighten-4"
                [routerLink]="['/member/member-main-home/vote', item.id]">
                <span style="cursor: pointer;"><strong class="black-text"><i class="material-icons"
                      style="margin-right: 7px;">how_to_vote</i>Vote</strong></span> </a>
            </div>
          </div>
        </div>
      </div>

      <div *ngIf="closedElection">
        <div class="row col l5 s12 card white" *ngFor="let item of closedElection" style="margin: 9px;">
          <div class="p-t-2">
            <div class="p-b-2">
              <div class="col s6">
                <a [routerLink]="['/member/member-main-home/election-detail', item.id]"
                  class="truncate bold-700 title-section3">{{item.name}}</a>
              </div>
              <div class="col s6">
                <span class="red-text right">Closed</span><br />
              </div>
            </div>
            <ul class="col s12 collection">
              <li class="row collection-item">
                <div class="col s4"> <strong class="black-text">End Date</strong></div>
                <div class="col s8 red-text"> <strong class="right"> {{item.endDate}} </strong></div>
              </li>
            </ul>

            <div class="col s12">
              <a *ngIf="item.showVotingResult" class="center col btn-flat s12 blue lighten-4"
                [routerLink]="['/member/member-main-home/election-result', item.id]"> <span
                  style="cursor: pointer;"><strong class="black-text"><i class="material-icons"
                      style="margin-right: 7px;">poll</i>Result</strong></span> </a>
              <a *ngIf="!item.showVotingResult" class="center col btn-flat s12 green lighten-4"
                [routerLink]="['/member/member-main-home/election-detail', item.id]"> <span
                  style="cursor: pointer;"><strong class="black-text"><i class="material-icons"
                      style="margin-right: 7px;">read_more</i>Detail</strong></span> </a>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>