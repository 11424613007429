import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ServicePlanModel } from 'src/app/models/isets/ServicePlanModel';
import { ApiKeyManagerService } from './../apiKeyManager.Service';


@Injectable({
    providedIn: 'root'
  })
  export class ServicePlanService {
  
  url = environment.firstNation.apiUrl + 'api/ServicePlan/';
  
  constructor(private httpClient: HttpClient, private keyManager: ApiKeyManagerService) { }
  
  
  getServicePlanItems(client_id: number): Observable<ServicePlanModel[]> {
    
    const url = this.url + 'GetServicePlanItems/' + client_id;
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
    return this.httpClient.get<any>(url, { headers });
  
  }
  
  
  getServicePlanByID(spid: number): Observable<ServicePlanModel> {
    
    const url = this.url + 'GetServicePlanByID/' + spid;
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
    return this.httpClient.get<any>(url, { headers });
  
  
  }
  
  
  }
