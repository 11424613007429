<div class="row container-pi1">
  <h4 class="title-section1 title-member icon-texts"> <span class="material-symbols-outlined back-icon p-r-1" (click)=" backClicked()">
    arrow_circle_left
</span>My Nomination</h4>

  <div *ngIf="showNominations" class="card">
    <!-- will show nominaitons here -->
    <div class="row">
      <div class="col s12 m12"> 
        <div class="card-panel" *ngFor="let nominationDetail of nominationDetails">
          <div class="card-content black-text">
            <span class="card-title">{{nominationDetail.name}}</span>
            <p>Start Date: {{nominationDetail.startDate}}</p>
            <p>End Date: {{nominationDetail.endDate}}</p> 
            <p>Number of Nominees: {{nominationDetail.numberOfNominees}}</p>
            <div *ngIf="nominationDetail.nominationStatus==='Pending'">
              <a class="waves-effect waves-light btn green" (click)="AcceptNomination(nominationDetail.id)"><i class="material-icons left">check</i>Accept nomination</a>
              <a class="waves-effect waves-light btn red" (click)="RejectNomination(nominationDetail.id)"><i class="material-icons left">close</i>Reject nomination</a>
            </div>
            <div *ngIf="nominationDetail.nominationStatus==='Expired'">
              <p class="waves-effect waves-light  red-text">Nomination Expired</p>
            </div>
            <div *ngIf="nominationDetail.nominationStatus ==='Accepted'">
              <a class="waves-effect waves-light btn green"><i class="material-icons left">check</i>Nomination Accepted</a>
            </div>
            <div *ngIf="nominationDetail.nominationStatus ==='Rejected'">
              <a class="waves-effect waves-light btn red"><i class="material-icons left">close</i>Nomination Rejected</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="!showNominations">
    <div class="row" style="margin-top: 5%;">
      <div class="col s12 m12">
        <div class="center">
          <img src="../../assets/img/empty-folder.png" height="150" width="150">
          <!-- <h6 class="bold-200">Sorry , no data found !!</h6> -->
          <h6 class="bold-200">{{message}} !!</h6>
        </div>
      </div>
    </div>
  </div>

  <div class="row">
    <a [ngClass]="'col s12 btn waves-effect ' + themeColor" (click)="backClicked()"><i
      class="material-icons left">arrow_back_ios_new</i> Back To Previous Page</a>
  </div>

</div>