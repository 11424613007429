import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { toast } from 'materialize-css';
import { SKCasePlanModel } from 'src/app/models/SKCasePlanModel';
import { CasePlanService } from 'src/app/services/api/case-plan.service';
import { Location } from '@angular/common';
import { EmploymentService } from 'src/app/services/api/employment.service';
import { EmploymentService as EmploymentFirebaseService } from 'src/app/services/firebase/employment.service';
import { EducationService } from 'src/app/services/api/education.service';
import { EducationService as EducationFirebaseService } from 'src/app/services/firebase/education.service';
import { CertificateService } from 'src/app/services/api/certificate.service';
//import { FireCertificateService } from 'src/app/services/firebase/fire_certificate.service';
import { MemberService } from 'src/app/services/api/member.service';
import { SKExperienceModel } from 'src/app/models/SKExperienceModel';
import { SKBarrierModel } from 'src/app/models/SKBarrierModel';
import { SKCareerInterestModel } from 'src/app/models/SKCareerInterestModel';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-sk-case-plan-frm',
  templateUrl: './sk-case-plan-frm.component.html',
  styleUrls: ['./sk-case-plan-frm.component.css']
})

export class SKCasePlanFRMComponent implements OnInit {

  nationofUse = environment.firstNation.name;
  nationName = environment.firstNation.displayName;
  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  provinceOfUse = environment.firstNation.province;

  EduLevelList = ["Preschool", "Up to Grade 7-8 (Sec. I = Grade 8)", "Grade 9-10 (Sec. II-III)", "Grade 11-12 (Sec. IV-V)",
    "Secondary School Diploma or GED", "Some post-secondary training", "Apprenticeship or trades certificate or diploma",
    "College", "CEGEP", "University certificate or diploma", "University - Bachelor Degree", "University – Master’s degree", "University – Doctorate", "Course or certificate", "Other"];

  /* EduLevelList = ["Up to Grade 7-8 (Sec. I = Grade 8)", "Grade 9-10 (Sec. II-III)", "Grade 11-12 (Sec. IV-V)",
 "Secondary School Diploma or GED",  "Some post-secondary training", "Apprenticeship or trades certificate or diploma", 
 "College, CEGEP,Other", "University certificate or diploma", "University - Bachelor Degree", "University – Master’s degree", "University – Doctorate", "Course or certificate", "Other"];
*/

  ExperienceList = ["Accountant", "Accounting Clerk", "Autobody", "Baker", "Bookkeeping", "Butcher", "Carpentry", "Cashier", "Childcare", "Cleaner",
    "Clerical", "Computer Operator", "Computer Programmer", "Cooking", "Customer Service", "Delivery/Courier", "Dental Assistant", "Electrical",
    "Electronics", "Farming", "Fishing", "Food/Beverage Server", "Food Counter Attendant", "Forestry/Logging", "General Laborer", "Graphic Arts",
    "Hairdresser", "Heavy Equipment Operator", "Home Support Worker", "Janitorial", "Kitchen help", "Machine Operator", "Machinist", "Masonry",
    "Metal Forming", "Metal/Woodwork", "Motor Vehicle Mechanic", "Nursing", "Painter", "Paralegal", "Plumbing/Gas/Pipefitting", "Sales Representative",
    "Security Guard", "Sewing", "Sheet Metal Worker", "Shipper/Receiver/Warehouse", "Teacher / Teachers’ Aide", "Teller", "Trades Helper", "Trapping",
    "Upholstery", "Welder", "Other"];

  BarriersList = ["Age", "Childcare", "Criminal Record", "Drug and/or alcohol abuse", "Has child with special needs",
    "Housing Issues / homelessness", "Illiteracy", "Lack of Education", "Lack of Life skills", "Learning disabilities",
    "Legal problems", "Long-term Income Assistance Recipient", "No transportation and/or vehicle", "Physical disabilities",
    "Suspended Driver's License / No Driver's License", "Other"];

  sectionForm: UntypedFormGroup;
  section1Form: UntypedFormGroup;
  HasEIContribute = false;
  applicantID = 0;
  caseplan_id = 0;
  model: SKCasePlanModel;
  experienceModel: SKExperienceModel[];
  oneExperience: SKExperienceModel;
  tmpExperienceModel: any[];
  OtherExperienceSelected = false;
  t_other_experience = 0;

  EmpModel: any[];
  modelOfCertificate: any[];
  modelOfEducation: any[];
  hasRowsOfEmp = false;
  hasRowsOfCertificate = false;
  hasRowsOfEducation = false;

  sqldb = environment.firstNation.SQLDB;

  addItem = true;  // false;
  displayItem = false;
  emptyItem = false;
  hasExperience = false;
  step = 1;

  //Barrier to Employment
  section2Form: UntypedFormGroup;
  addBarrier = true;
  displayBarrier = false;
  emptyBarrier = true;
  OtherSelected = false;
  barrierModel: SKBarrierModel[];
  oneBarrier: SKBarrierModel;
  t_other_barrier = 0;
  //tmpBarrierModel: any;

  // Career Interests
  BusinessSelected = false;
  ComputersSelected = false;
  ConstructionSelected = false;
  EducationSelected = false;
  EngineeringSelected = false;
  HealthSelected = false;
  HospitalitySelected = false;
  LawSelected = false;
  TransportationSelected = false;
  OtherCareerSelected = false;

  businessJobList = ["Account Executive", "Accounting and Auditing", "Advertising and Marketing", "Budget Analyst", "Claims Adjusters",
    "Financial Managers", "Financial Service Sales", "Forensic Accountants", "Human Resource Managers", "Insurance Agent",
    "Investment Banker", "Loan Officer", "Meeting and Convention Planners", "Personal Financial Advisor", "Purchasing Agents",
    "Real Estate Agent", "Real Estate Appraiser", "Real Estate Broker"];
  computerJobList = ["Computer and Information Systems Manager", "Computer Hardware Engineer", "Computer Network Architect", "Computer Programmer",
    "Computer Repair", "Computer Research Scientist", "Computer Science Teacher"];
  constructionJobList = ["Architect", "Building Inspector", "Carpenter", "Cement Mason", "Commercial Industrial Designer", "Construction Heavy Equipment Operator",
    "Construction Manager", "Construction Worker", "Drafter", "Drywaller", "Electrician", "Flooring Installer", "Landscape Architects",
    "Landscaper", "Plumber", "Roofer", "Stonemason", "Surveyor"];
  educationJobList = ["Education Administrator", "Teacher – Adult literacy", "Teacher – Postsecondary", "Teacher – Preschool, Elementary, Middle and Secondary",
    "Teacher – Special education", "Teacher Assistant"];
  engineeringJobList = ["Biomedical Engineers", "Civil Engineer", "Computer Hardware Engineer", "Computer Software Engineer", "Electrical Engineer", "Electronics Engineer",
    "Engineer (General)", "Engineering Technician", "Environmental Engineer", "Farming & Fishing", "Health and Safety Engineer",
    "Industrial Engineer", "Marine Engineer", "Mechanical Engineer", "Mining Geological Engineer", "Petroleum Engineer"];
  healthJobList = ["Forensic Science", "Gerontology", "Health Administration", "Informatics", "Medicine", "Mental Health", "Nursing", "Nutrition/Dietetics",
    "Occupational Therapy", "Optometry", "Pharmacy", "Physical Therapy", "Podiatric Medicine", "Public Health", "Speech/Language/Hearing", "Veterinary Medicine"];
  hospitalityJobList = ["Chef", "Food Preparation Worker", "Food Server", "Hotel Manager", "Travel Agent"];
  lawJobList = ["Conservation Officer", "Corrections Officer", "Court Clerk", "Criminalist", "Customs Agent", "Forensic Pathologist", "Lawyer", "Paralegal",
    "Police Officer", "Probation Officer", "Security Guard"];
  transportationJobList = ["Bus Driver", "Delivery Driver", "Fleet Coordinator", "Flight Attendant", "Flight Engineer", "Fork Lift Operator", "Pilot",
    "Railroad Transportation Worker", "Taxi Driver", "Train Operator", "Truck Driver", "Warehouse Operations"];

  CareerList = ["Business & Finance", "Computers", "Construction", "Education", "Engineering", "Health", "Hospitality", "Law", "Transportation", "Other"];

  section3Form: UntypedFormGroup;
  addInterest = true;
  displayInterest = false;
  emptyInterest = true;
  interestModel: SKCareerInterestModel[];
  oneInterest: SKCareerInterestModel;
  t_other_interest = 0;
  tmpInterestModel: any;

  constructor(private formBuilder: UntypedFormBuilder, private authService: AuthService, private router: Router,
    private casePlanService: CasePlanService, private location: Location,
    private employmentService: EmploymentService, private employmentFirebaseService: EmploymentFirebaseService,
    private educationService: EducationService, private educationFirebaseService: EducationFirebaseService,
    private certificateService: CertificateService, private memberService: MemberService) { }    //private certificateFirebaseService: FireCertificateService

  ngOnInit() {
    if (localStorage.getItem('applicantID')) {
      this.applicantID = Number(localStorage.getItem('applicantID'));
    } else {
      this.applicantID = this.authService.getCurrentApplicantID();
    }

    if (this.applicantID == 0) { this.authService.signOut(); }

    localStorage.setItem('employment_call_from', 'case_plan');
    localStorage.setItem('education_call_from', 'case_plan');
    localStorage.setItem('certificate_call_from', 'case_plan');

    this.sectionForm = this.formBuilder.group({
      edu_level: ['', [Validators.required]],
      EI_Contribute: ['', [Validators.required]],
      EI_Claim: ['', [Validators.required]],
      Comment1: ['', [Validators.required]],
      Comment2: ['', [Validators.required]],
      Comment3: ['', [Validators.required]],
    });

    this.section1Form = this.formBuilder.group({
      ExperienceFB: this.formBuilder.group({
        experience: ['', [Validators.required]],
        other_dec: [''],      //other_dec:['', [Validators.required]], 
        experienceItems: this.formBuilder.array([]),
      }),
    });
    this.section1Form.get('ExperienceFB.experience').valueChanges.subscribe(value => {
      if (value == 'Other') {
        this.section1Form.get('ExperienceFB.other_dec').setValidators(Validators.required);
      } else {
        this.section1Form.get('ExperienceFB.other_dec').clearValidators();
      }
      this.section1Form.get('ExperienceFB.other_dec').updateValueAndValidity();
    });

    this.section2Form = this.formBuilder.group({
      BarrierFB: this.formBuilder.group({
        barrier: ['', [Validators.required]],
        other: [''],    //['', [Validators.required]], 
        address_barrier: ['no', [Validators.required]],
        barrierItems: this.formBuilder.array([]),
      }),
    });
    this.section2Form.get('BarrierFB.barrier').valueChanges.subscribe(value => {
      if (value == 'Other') {
        this.section2Form.get('BarrierFB.other').setValidators(Validators.required);
      } else {
        this.section2Form.get('BarrierFB.other').clearValidators();
      }
      this.section2Form.get('BarrierFB.other').updateValueAndValidity();
    });

    this.section3Form = this.formBuilder.group({
      InterestFB: this.formBuilder.group({
        job_field: ['', [Validators.required]],
        job: ['', [Validators.required]],
        interestItems: this.formBuilder.array([]),
      }),
    });

    this.model = new SKCasePlanModel();
    this.model.applicantID = this.applicantID;

    this.memberService.getMember(this.applicantID).subscribe(x => {
      if (x) {
        let edu_level = "";
        if (x[0].edu_level) { edu_level = x[0].edu_level; }
        this.sectionForm.patchValue({ 'edu_level': this.memberEducatonLevel(edu_level) });

        if (this.sqldb) {
          this.employmentService.getEmploymentListByMemberID(this.applicantID).subscribe(x => {
            if (x) {
              this.EmpModel = x;
              if (this.EmpModel.length > 0) { this.hasRowsOfEmp = true; }

              this.educationService.getEducationByMemberId(this.applicantID).subscribe(y => {
                if (y) {
                  this.modelOfEducation = y;
                  if (this.modelOfEducation.length > 0) { this.hasRowsOfEducation = true; }

                  this.certificateService.getCertificateByMemberId(this.applicantID).subscribe(z => {
                    if (z) {
                      this.modelOfCertificate = z;
                      if (this.modelOfCertificate.length > 0) { this.hasRowsOfCertificate = true; }
                    }

                    setTimeout(() => {
                      $('select').formSelect();
                      M.updateTextFields();
                    }, 25);
                  });
                }
              });
            }
            setTimeout(() => {
              $('select').formSelect();
              M.updateTextFields();
            }, 50);

            this.casePlanService.getSKCasePlan(this.applicantID).subscribe(cp => {
              if (cp.length > 0) {
                let plan = cp[0];
                this.caseplan_id = plan.caseplan_id;

                if (plan.EI_Contribute) { this.HasEIContribute = true; this.sectionForm.patchValue({ 'EI_Contribute': 'yes' }); }
                if (!plan.EI_Contribute) { this.sectionForm.patchValue({ 'EI_Contribute': 'no' }); }

                if (plan.EI_Claim) { this.sectionForm.patchValue({ 'EI_Claim': 'yes' }); }
                if (!plan.EI_Claim) { this.sectionForm.patchValue({ 'EI_Claim': 'no' }); }

                this.sectionForm.patchValue({ 'Comment1': plan.Comment1 });
                this.sectionForm.patchValue({ 'Comment2': plan.Comment2 });
                this.sectionForm.patchValue({ 'Comment3': plan.Comment3 });

                if (this.caseplan_id > 0) {
                  this.casePlanService.getSKExperience(this.caseplan_id).subscribe(ex => {
                    if (ex) {

                      ex.forEach(e => {
                        if (e.experience == 'Other') { this.t_other_experience += 1; }
                        let tmpFromgroup = this.formBuilder.group({
                          experience: e.experience,
                          other_dec: e.other_dec,
                        });
                        this.experienceControls.push(tmpFromgroup);
                      });

                      this.emptyItem = false;
                      this.displayItem = true;
                      // this.addItem = false;       
                      this.hasExperience = true;

                      setTimeout(() => {
                        $('.collapsible').collapsible();
                        $('select').formSelect();
                        M.updateTextFields();
                      }, 25);
                    } else {
                      // this.addItem = false;  
                      this.emptyItem = true;
                      this.displayItem = false;
                      this.hasExperience = false;
                    }

                    this.casePlanService.getSKBarriers(this.caseplan_id).subscribe(b => {
                      if (b) {
                        let myaddress = "";
                        b.forEach(e => {
                          if (e.barrier == 'Other') { this.t_other_barrier += 1; }
                          myaddress = e.address_barrier;

                          let tmpFromgroup = this.formBuilder.group({
                            barrier: e.barrier,
                            other: e.other,
                            address_barrier: e.address_barrier,
                          });
                          this.barrierControls.push(tmpFromgroup);
                        });

                        if (myaddress == "True") {
                          (<UntypedFormGroup>this.section2Form.controls['BarrierFB']).controls['address_barrier'].patchValue("yes");
                        } else {
                          (<UntypedFormGroup>this.section2Form.controls['BarrierFB']).controls['address_barrier'].patchValue("no");
                        }
                        this.emptyBarrier = false;
                        this.displayBarrier = true;
                        // this.addBarrier = false;       

                        setTimeout(() => {
                          $('.collapsible').collapsible();
                          $('select').formSelect();
                          M.updateTextFields();
                        }, 25);

                      } else {
                        // this.addBarrier = false;  
                        this.emptyBarrier = true;
                        this.displayBarrier = false;
                      }

                      this.casePlanService.getSKCareerInterest(this.caseplan_id).subscribe(c => {
                        if (c) {
                          c.forEach(e => {
                            if (e.job_field == '') { this.t_other_interest += 1; }
                            let tmpFromgroup = this.formBuilder.group({
                              caseplan_id: e.caseplan_id,
                              job_field: e.job_field,
                              job: e.job,
                            });
                            this.interestControls.push(tmpFromgroup);
                          });

                          this.emptyInterest = false;
                          this.displayInterest = true;
                          // this.addInterest = false;       

                          setTimeout(() => {
                            $('.collapsible').collapsible();
                            $('select').formSelect();
                            M.updateTextFields();
                          }, 25);

                        } else {
                          // this.addInterest = false;  
                          this.emptyInterest = true;
                          this.displayInterest = false;
                        }
                      });
                    });
                  });
                }
              }
            });
          });
        } else {
          this.employmentFirebaseService.getEmploymentByMemberId(this.applicantID).valueChanges().subscribe(x => {
            if (x) {
              this.EmpModel = x;
              if (this.EmpModel.length > 0) { this.hasRowsOfEmp = true; }
            }

            this.educationFirebaseService.getEducationByMemberId(this.applicantID).valueChanges().subscribe(x => {
              if (x) {
                this.modelOfEducation = x;
                if (this.modelOfEducation.length > 0) { this.hasRowsOfEducation = true; }
              }

              // this.certificateFirebaseService.getCertificateByMemberId(this.applicantID).valueChanges().subscribe(x => {
              //   if (x) {
              //     this.modelOfCertificate = x;
              //     if (this.modelOfCertificate.length > 0) { this.hasRowsOfCertificate = true; }
              //   }
              // });

              setTimeout(() => {
                //$('.collapsible').collapsible();
                $('select').formSelect();
                M.updateTextFields();
              }, 25);
            });
          });
        }
        /*
        this.casePlanService.getSKCasePlan(this.applicantID).subscribe(cp => {
          if (cp.length > 0 ) {            
              let plan = cp[0];
              this.caseplan_id = plan.caseplan_id; 

              if(plan.EI_Contribute){   this.HasEIContribute = true;  this.sectionForm.patchValue({ 'EI_Contribute': 'yes' } );}
              if(!plan.EI_Contribute){  this.sectionForm.patchValue({ 'EI_Contribute': 'no' } );}

              if(plan.EI_Claim){  this.sectionForm.patchValue({ 'EI_Claim': 'yes' } );}
              if(!plan.EI_Claim){  this.sectionForm.patchValue({ 'EI_Claim': 'no' } );}

              this.sectionForm.patchValue({ 'Comment1': plan.Comment1 } );
              this.sectionForm.patchValue({ 'Comment2': plan.Comment2 } );
              this.sectionForm.patchValue({ 'Comment3': plan.Comment3 } );

              if (this.caseplan_id > 0) {
                  this.casePlanService.getSKExperience(this.caseplan_id).subscribe(ex => {
                    if (ex) {  

                      ex.forEach(e => {       
                        if (e.experience=='Other')  {this.t_other_experience += 1; }

                        let tmpFromgroup =  this.formBuilder.group({ 
                            experience: e.experience,
                            other_dec: e.other_dec,
                        });   
                        this.experienceControls.push(tmpFromgroup);        
                      });

                      this.emptyItem = false;
                      this.displayItem = true;
                      // this.addItem = false;       
                      this.hasExperience = true;

                      setTimeout(() => {
                        $('.collapsible').collapsible();
                        $('select').formSelect();
                        M.updateTextFields();
                      }, 25);
                    }else{
                      // this.addItem = false;  
                        this.emptyItem = true;
                        this.displayItem = false;
                        this.hasExperience = false;
                    }
                      this.casePlanService.getSKBarriers(this.caseplan_id).subscribe(b => {
                        if (b ) {  
                          let myaddress = "";
                          b.forEach(e => {      
                            if (e.barrier=='Other')  {this.t_other_barrier += 1; }
                           myaddress =  e.address_barrier;

                            let tmpFromgroup =  this.formBuilder.group({ 
                                barrier: e.barrier,
                                other: e.other,
                                address_barrier: e.address_barrier,
                            });   
                            this.barrierControls.push(tmpFromgroup);        
                          });
                          
                          if (myaddress == "True") {
                            (< FormGroup > this.section2Form.controls['BarrierFB']).controls['address_barrier'].patchValue("yes");
                          }else{
                            (< FormGroup > this.section2Form.controls['BarrierFB']).controls['address_barrier'].patchValue("no");
                          }
                            
                          this.emptyBarrier = false;
                          this.displayBarrier = true;
                        // this.addBarrier = false;       
                      
                          setTimeout(() => {
                            $('.collapsible').collapsible();
                            $('select').formSelect();
                            M.updateTextFields();
                          }, 25);
                          
                        }else{
                        // this.addBarrier = false;  
                          this.emptyBarrier = true;
                          this.displayBarrier = false;         
                        }

                        this.casePlanService.getSKCareerInterest(this.caseplan_id).subscribe(c => {
                          if (c) {                  
                            c.forEach(e => {       
                              if (e.job_field=='')  {this.t_other_interest += 1; }          
                              let tmpFromgroup =  this.formBuilder.group({ 
                                  caseplan_id: e.caseplan_id,
                                  job_field: e.job_field,
                                  job: e.job,
                              });   
                              this.interestControls.push(tmpFromgroup);        
                            });
      
                            this.emptyInterest = false;
                            this.displayInterest = true;
                          // this.addInterest = false;       
                          
                            setTimeout(() => {
                              $('.collapsible').collapsible();
                              $('select').formSelect();
                              M.updateTextFields();
                            }, 25);
                            
                          }else{
                          // this.addInterest = false;  
                            this.emptyInterest = true;
                            this.displayInterest = false; 
                          }
                        });
                      });
                  });
              }
          }
        });*/
      }
    });

    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }

  get edu_level() { return this.sectionForm.get('edu_level'); }
  get EI_Contribute() { return this.sectionForm.get('EI_Contribute'); }
  get EI_Claim() { return this.sectionForm.get('EI_Claim'); }
  get Comment1() { return this.sectionForm.get('Comment1'); }
  get Comment2() { return this.sectionForm.get('Comment2'); }
  get Comment3() { return this.sectionForm.get('Comment3'); }

  get experience() { return this.section1Form.get('ExperienceFB.experience'); }
  get other_dec() { return this.section1Form.get('ExperienceFB.other_dec'); }
  get experienceItems() { return this.section1Form.get('ExperienceFB.experienceItems'); }
  get experienceControls() { return this.section1Form.get('ExperienceFB.experienceItems') as UntypedFormArray; }

  get barrier() { return this.section2Form.get('BarrierFB.barrier'); }
  get other() { return this.section2Form.get('BarrierFB.other'); }
  get address_barrier() { return this.section2Form.get('BarrierFB.address_barrier'); }
  get barrierItems() { return this.section2Form.get('BarrierFB.barrierItems'); }
  get barrierControls() { return this.section2Form.get('BarrierFB.barrierItems') as UntypedFormArray; }

  get job_field() { return this.section3Form.get('InterestFB.job_field'); }
  get job() { return this.section3Form.get('InterestFB.job'); }
  get interestItems() { return this.section3Form.get('InterestFB.interestItems'); }
  get interestControls() { return this.section3Form.get('InterestFB.interestItems') as UntypedFormArray; }

  //Experience / Skills=========================================
  addFBGItem(values: any): void {
    const myexperience = values.experience;
    if (myexperience == 'Other') {
      this.t_other_experience += 1;
    }

    if (this.t_other_experience > 3) {
      this.t_other_experience = 3;
      toast({ html: 'At most three other Experience !', classes: 'red' });
      return;
    }

    const items = this.experienceControls;
    let tmpFromgroup = this.formBuilder.group({
      experience: [values.experience.trim()],
      other_dec: [values.other_dec],
    });
    items.push(tmpFromgroup);

    this.emptyItem = false;
    this.displayItem = true;
    //this.addItem = false;
    this.hasExperience = true;

    setTimeout(() => {
      $('.collapsible').collapsible();
      $('select').formSelect();
    }, 25);
    toast({ html: 'Experience Successfully Added!', classes: 'green' });
    this.section1Form.get('ExperienceFB.experience').reset();
    this.section1Form.get('ExperienceFB.other_dec').reset();
  }

  addAnotherItem() {
    //this.addItem = true;
    this.section1Form.get('ExperienceFB.experience').reset();
    this.section1Form.get('ExperienceFB.other_dec').reset();
    setTimeout(() => {
      $('.collapsible').collapsible();
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }

  onDeleteItem(index) {
    const items = this.experienceControls;
    const experience = this.experienceControls.controls[index].value.experience;
    if (experience == 'Other') {
      this.t_other_experience -= 1;
      if (this.t_other_experience < 0) { this.t_other_experience = 0; }
    }

    items.removeAt(index);
    if (items.length == 0) {
      // this.addItem = false;
      this.displayItem = false;
      this.emptyItem = true;
      this.hasExperience = false;
    }
    toast({ html: 'Experience Deleted!', classes: 'red' });
  }

  closeAddItem() {
    // this.addItem = false;  
  }

  ExperienceChange(e) {
    const experience = e.toString();
    this.OtherExperienceSelected = false;
    if (experience == 'Other') {
      this.OtherExperienceSelected = true;
    }
    this.section1Form.get('ExperienceFB.other_dec').reset();
    this.section1Form.get('ExperienceFB.experience').valueChanges.subscribe(value => {
      if (value == 'Other') {
        this.section1Form.get('ExperienceFB.other_dec').setValidators(Validators.required);
      } else {
        this.section1Form.get('ExperienceFB.other_dec').clearValidators();
      }
      this.section1Form.get('ExperienceFB.other_dec').updateValueAndValidity();
    });

    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }

  //Barrier to Employment=========================================
  addBarrierItem(values: any): void {
    const barrier = values.barrier;
    if (barrier == 'Other') {
      this.t_other_barrier += 1;
    }

    if (this.t_other_barrier > 3) {
      this.t_other_barrier = 3;
      toast({ html: 'At most three other Barriers !', classes: 'red' });
      return;
    }

    const items = this.barrierControls;
    let tmpFromgroup = this.formBuilder.group({
      barrier: [values.barrier],
      other: [values.other],
      address_barrier: [values.address_barrier],
    });
    items.push(tmpFromgroup);

    this.emptyBarrier = false;
    this.displayBarrier = true;
    //this.addBarrier = false;
    this.OtherSelected = false;

    setTimeout(() => {
      $('.collapsible').collapsible();
      $('select').formSelect();
    }, 25);
    toast({ html: 'Barrier Successfully Added!', classes: 'green' });
    this.section2Form.get('BarrierFB.barrier').reset();
    this.section2Form.get('BarrierFB.other').reset();
  }

  onDeleteBarrier(index) {
    const items = this.barrierControls;
    const barrier = this.barrierControls.controls[index].value.barrier;
    if (barrier == 'Other') {
      this.t_other_barrier -= 1;
      if (this.t_other_barrier < 0) { this.t_other_barrier = 0; }
    }

    items.removeAt(index);
    if (items.length == 0) {
      // this.addBarrier = false;
      this.displayBarrier = false;
      this.emptyBarrier = true;
    }
    toast({ html: 'Barrier Deleted!', classes: 'red' });
  }

  BarrierChange(e) {
    const barrier = e.toString();
    this.OtherSelected = false;
    if (barrier == 'Other') {
      this.OtherSelected = true;
    }

    this.section2Form.get('BarrierFB.other').reset();
    this.section2Form.get('BarrierFB.barrier').valueChanges.subscribe(value => {
      if (value == 'Other') {
        this.section2Form.get('BarrierFB.other').setValidators(Validators.required);
      } else {
        this.section2Form.get('BarrierFB.other').clearValidators();
      }
      this.section2Form.get('BarrierFB.other').updateValueAndValidity();
    });

    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }

  //Career Interest=========================================
  addCareerInterestItem(values: any): void {
    const job_type = values.job_field;
    if (job_type == 'Other') {
      this.t_other_interest += 1;
    }

    if (this.t_other_interest > 2) {
      this.t_other_interest = 2;
      toast({ html: 'At most two other career interests !', classes: 'red' });
      return;
    }

    const items = this.interestControls;
    let tmpFromgroup = this.formBuilder.group({
      job_field: [values.job_field],
      job: [values.job],
    });
    items.push(tmpFromgroup);

    this.emptyInterest = false;
    this.displayInterest = true;
    //this.addInterest = false;
    //this.OtherCareerSelected = false;

    setTimeout(() => {
      $('.collapsible').collapsible();
      $('select').formSelect();
    }, 25);
    toast({ html: 'Career Interest Successfully Added!', classes: 'green' });
    this.section3Form.get('InterestFB.job_field').reset();
    this.section3Form.get('InterestFB.job').reset();
  }

  onDeleteInterest(index) {
    const items = this.interestControls;
    const job_type = this.interestControls.controls[index].value.job_field;
    if (job_type == 'Other') {
      this.t_other_interest -= 1;
      if (this.t_other_interest < 0) { this.t_other_interest = 0; }
    }
    items.removeAt(index);
    if (items.length == 0) {
      // this.addInterest = false;
      this.displayInterest = false;
      this.emptyInterest = true;
    }
    toast({ html: 'Career Interest Deleted!', classes: 'red' });
  }


  CareerInterestChange(e) {
    const job = e.toString();

    this.BusinessSelected = false;
    this.ComputersSelected = false;
    this.ConstructionSelected = false;
    this.EducationSelected = false;
    this.EngineeringSelected = false;
    this.HealthSelected = false;
    this.HospitalitySelected = false;
    this.LawSelected = false;
    this.TransportationSelected = false;
    this.OtherCareerSelected = false;

    // CareerList = [, , , , , , , , , "Other"];

    switch (job) {
      case "Business & Finance": {
        this.BusinessSelected = true;
        break;
      }
      case "Computers": {
        this.ComputersSelected = true;
        break;
      }
      case "Construction": {
        this.ConstructionSelected = true;
        break;
      }

      case "Education": {
        this.EducationSelected = true;
        break;
      }

      case "Engineering": {
        this.EngineeringSelected = true;
        break;
      }

      case "Health": {
        this.HealthSelected = true;
        break;
      }

      case "Hospitality": {
        this.HospitalitySelected = true;
        break;
      }
      case "Law": {
        this.LawSelected = true;
        break;
      }
      case "Transportation": {
        this.TransportationSelected = true;
        break;
      }
      case "Other": {
        this.OtherCareerSelected = true;
        break;
      }
    }

    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }

  submitSection1(values: any) {
    this.tmpExperienceModel = values.experienceItems;
    this.onNext()
  }

  submitSection2(values: any) {
    let countofOther = 0;
    const tmpModel = values.barrierItems;
    this.barrierModel = [];
    tmpModel.forEach(item => {
      this.oneBarrier = new SKBarrierModel();
      //this.oneBarrier.caseplan_id = this.caseplan_id;
      this.oneBarrier.barrier = item.barrier;

      this.oneBarrier.other = item.other;
      if (item.barrier == 'Other') {
        countofOther += 1;
        if (this.t_other_barrier == 1) {
          this.oneBarrier.other = "Other1:" + item.other;
        } else if (this.t_other_barrier == 2) {
          if (countofOther == 1) {
            this.oneBarrier.other = "Other1:" + item.other;
          } else {
            this.oneBarrier.other = "Other2:" + item.other;
          }
        } else {
          if (countofOther == 1) {
            this.oneBarrier.other = "Other1:" + item.other;
          } else if (countofOther == 2) {
            this.oneBarrier.other = "Other2:" + item.other;
          }
          else {
            this.oneBarrier.other = "Other3:" + item.other;
          }
        }
      }

      if (values.address_barrier == 'yes') {
        this.oneBarrier.address_barrier = 1;
      } else {
        this.oneBarrier.address_barrier = 0;
      }
      this.barrierModel.push(this.oneBarrier);
    });
    this.onNext()
  }

  submitSection3(values: any) {
    this.tmpInterestModel = values.interestItems;

    /*
     const tmpModel =  values.interestItems; 
     this.interestModel = [];
     tmpModel.forEach(item => {   
       this.oneInterest = new SKCareerInterestModel();
       this.oneInterest.caseplan_id = this.caseplan_id;
       this.oneInterest.job_field = item.job_field;
 
       this.oneInterest.job = item.job;
       if (item.job_field == 'Other') {
         if (this.t_other_interest == 1) {
           this.oneInterest.job = "Other1:" + item.job;
         }else{
           this.oneInterest.job = "Other2:" + item.job;
         }  
       }
       this.interestModel.push(this.oneInterest); 
     });
     */
    this.onNext()
  }

  memberEducatonLevel(level) {

    let applicant_education = '';
    switch (level) {
      case 0: {
        applicant_education = "Preschool";
        break;
      }
      case 1: {
        applicant_education = "Up to Grade 7-8 (Sec. I = Grade 8)";
        break;
      }
      case 2: {
        applicant_education = "Grade 9-10 (Sec. II-III)";
        break;
      }
      case 3: {
        applicant_education = "Grade 11-12 (Sec. IV-V)";
        break;
      }
      case 4: {
        applicant_education = "Secondary School Diploma or GED";
        break;
      }
      case 5: {
        applicant_education = "Some post-secondary training";
        break;
      }
      case 6: {
        applicant_education = "Apprenticeship or trades certificate or diploma";
        break;
      }

      case 7: {
        applicant_education = "College";
        break;
      }
      case 8: {
        applicant_education = "CEGEP";
        break;
      }

      case 9: {
        applicant_education = "University certificate or diploma";
        break;
      }
      case 10: {
        applicant_education = "University - Bachelor Degree";
        break;
      }
      case 11: {
        applicant_education = "University – Master’s degree";
        break;
      }
      case 12: {
        applicant_education = "University – Doctorate";
        break;
      }
      case 13: {
        applicant_education = "Course or certificate";
        break;
      }
      case 14: {
        applicant_education = "Other";
        break;
      }
      default: {
        applicant_education = "";
        break;
      }
    }
    return applicant_education;
  }

  onEIContributeYes() {
    this.HasEIContribute = true;
  }

  onEIContributeNo() {
    this.HasEIContribute = false;
  }

  onNext() {
    this.step += 1;
    setTimeout(() => {
      $('select').formSelect();
      $('.modal').modal();
      $('.collapsible').collapsible();
      M.updateTextFields();
    }, 25);
  }

  onBack() {
    if (this.step > 1) { this.step -= 1; }
    setTimeout(() => {
      $('select').formSelect();
      $('.modal').modal();
      $('.collapsible').collapsible();
      M.updateTextFields();
    }, 25);
  }

  submitCasePlan(values: any) {
    this.model.EI_Claim = values.EI_Claim;
    this.model.Comment1 = values.Comment1;
    this.model.Comment2 = values.Comment2;
    this.model.Comment3 = values.Comment3;
    this.model.edu_level = values.edu_level;
    this.model.caseplan_id = this.caseplan_id;

    $('.modal').modal('close');

    if (this.sectionForm.valid) {
      this.casePlanService.saveSKCasePlan(this.model).subscribe(x => {
        if (x) {
          this.caseplan_id = x;

          let countofotherexperience = 0;
          this.experienceModel = [];
          this.tmpExperienceModel.forEach(item => {
            this.oneExperience = new SKExperienceModel();
            this.oneExperience.caseplan_id = this.caseplan_id;
            this.oneExperience.experience = item.experience;

            this.oneExperience.other_dec = item.other_dec;
            if (item.experience == 'Other') {
              countofotherexperience += 1;
              if (this.t_other_experience == 1) {
                this.oneExperience.other_dec = "Other1:" + item.other_dec;
              } else if (this.t_other_experience == 2) {
                if (countofotherexperience == 1) {
                  this.oneExperience.other_dec = "Other1:" + item.other_dec;
                } else {
                  this.oneExperience.other_dec = "Other2:" + item.other_dec;
                }
              } else {
                if (countofotherexperience == 1) {
                  this.oneExperience.other_dec = "Other1:" + item.other_dec;
                } else if (countofotherexperience == 2) {
                  this.oneExperience.other_dec = "Other2:" + item.other_dec;
                }
                else {
                  this.oneExperience.other_dec = "Other3:" + item.other_dec;
                }
              }
            }
            this.experienceModel.push(this.oneExperience);
          });

          this.casePlanService.saveSKExperience(this.experienceModel).subscribe(y => {
            if (y) { }

            this.barrierModel.forEach(item => {
              item.caseplan_id = this.caseplan_id;
            });

            this.casePlanService.saveSKBarriers(this.barrierModel).subscribe(z => {
              if (z) { }

              let count = 0;
              this.interestModel = [];
              this.tmpInterestModel.forEach(item => {
                this.oneInterest = new SKCareerInterestModel();
                this.oneInterest.caseplan_id = this.caseplan_id;
                this.oneInterest.job_field = item.job_field;

                this.oneInterest.job = item.job;
                if (item.job_field == 'Other') {
                  count += 1;
                  if (this.t_other_interest == 1) {
                    this.oneInterest.job = "Other1:" + item.job;
                  } else {
                    if (count == 1) {
                      this.oneInterest.job = "Other1:" + item.job;
                    } else {
                      this.oneInterest.job = "Other2:" + item.job;
                    }
                  }
                }
                this.interestModel.push(this.oneInterest);
              });

              this.casePlanService.saveSKCareerInterest(this.interestModel).subscribe(k => {
                if (k) {
                  toast({ html: 'Case Plan Successfully Added!', classes: 'green' });
                  this.router.navigate(['/member/member-home-sub-Menu']);
                }
              });
            });
          });
        }
      });
    }
  }

  backClicked() {
    this.location.back();
  }
}