<div class="row">

    <div class="col l1" *ngIf="user">
        <app-side-menus [user]="user"></app-side-menus>
    </div>

    <div class="col s12 m12 l10 xl8" *ngIf="section">
        <div class="body-h">
            <div class="header-setting b-m-2">
                <div class="col l8 m8 s12">
                    <h4 class="">
                        <span>{{section.displayName}}</span>
                    </h4>
                    <p class="bold-100 sub-title-section3" [innerHTML]="section.sectionDescription"></p>
                </div>
                <div class="col l4 m4 s12 p-m-2 ">
                    <a [routerLink]="['/admin/add-content/', sectionId]" class="add-button btn white center-align p-l-3"
                        [ngStyle]="{'color': themeColor}"><i
                            class="material-icons-outlined">add</i>Add&nbsp;{{section.displayName}}
                    </a>
                </div>
            </div>

            <main class="StickyContent white row">
                <div class="row black-text center" *ngIf="(!model || model?.length == 0) && !showLoading">
                    <img src="../../assets/img/empty-folder.png" height="100" width="80">
                    <h6 class="bold-700">Sorry , {{ nationOfUse }} did not post any <span
                            *ngIf="section">{{section.displayName}}!</span></h6>
                </div>

                <div class="row  col s12 l10" *ngIf="model">
                    <div *ngFor="let content of model | paginate: { itemsPerPage: 5, currentPage: p }">
                        <div class="row card news-card hoverable" style="cursor: pointer;"
                            [routerLink]="['/admin/edit-content/', content.sectionId, content.id]">
                            <div class="row col s12 card-content">

                                <div class="col s12"
                                    [ngClass]="{'col l9 m8 s12': content.mediaType == 'Photo' || content.mediaType == 'Video' || content.mediaType == 'Youtube' || content.mediaType == 'PDF' }">

                                    <p class="news-title">
                                        <a [routerLink]="['/admin/edit-content/', content.sectionId, content.id]"
                                            class="" [innerHTML]="content.title">
                                        </a>
                                    </p>

                                    <p *ngIf="content.mediaLink"
                                        class="middle-body-snippet sub-title-section3 p-t-1 bold-100"
                                        style="text-align: justify;">
                                        {{truncateHTML(content.description,300)}} </p>
                                    <p *ngIf="!content.mediaLink"
                                        class="long-body-snippet sub-title-section3 p-t-1 bold-100"
                                        style="text-align: justify;">
                                        {{truncateHTML(content.description,300)}} </p>

                                    <p class="col l4 m5 s12 date-content sub-title-section3 p-t-2">
                                        {{content.datePublished.toDate() |
                                        date: 'MMM
                                        dd, yyyy'}}
                                    </p>

                                    <p class="col l4 m5 push-m1 push-l2 s12 p-t-2" *ngIf="content.share">
                                        <span *ngIf="content.share === 'Public'" class="green-text">
                                            <strong>Public</strong>
                                        </span>
                                        <span *ngIf="content.share === 'Member'" class="red-text"><strong>Members
                                                Only</strong>
                                        </span>
                                    </p>

                                </div>

                                <div class="col l3 m4 hide-on-small-only" *ngIf="content.mediaType">

                                    <div class="card-image" *ngIf="content.mediaType === 'Photo' && content.mediaLink">
                                        <img [src]="content.mediaLink" class="news-img">
                                    </div>

                                    <div class="card-image" *ngIf="content.mediaType === 'Video' && content.mediaLink">
                                        <video class="responsive-video" controls>
                                            <source [src]="content.mediaLink" type="video/mp4">
                                        </video>
                                    </div>

                                    <div class="card-image"
                                        *ngIf="content.mediaType === 'Youtube' && content.mediaLink">
                                        <div class="video-container ">
                                            <youtube-player [videoId]="embedVideo(content.mediaLink)"></youtube-player>
                                        </div>
                                    </div>

                                    <div class="col l12 s12" *ngIf="content.mediaType === 'PDF' && content.mediaLink">
                                        <iframe title="iframe-box2" class="responsive-pdf"
                                            [src]="content.mediaLink | safeUrl"></iframe>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="row center">
                        <pagination-controls (pageChange)="p = $event"> </pagination-controls>
                    </div>
                </div>
            </main>
        </div>
    </div>

    <div id="permissionModal" class="modal center b-n-1">
        <div class="modal-content">
            <i class="material-icons right modal-close">close</i><br />
            <h6 class="title-section2 bold-600 black-text">Unauthorized</h6>
            <p class="sub-title-section bold-100">Sorry, but you don't have permission to access this page.</p>
            <img src="https://img.icons8.com/dotty/344/id-not-verified.png" height="150px" />
            <p class="sub-title-section bold-100">Please Contanct your Administrator to gain access.</p>
        </div>
    </div>
</div>