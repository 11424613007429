<!-- <div class="row col s12 p-t-2 p-b-5">

  <div class="row col s9 m10 l5 push-l1 push-s2 push-m1">
    <div class="header-title">
      <h6>Contact Us</h6>
    </div>
  </div>

  <div class="divider row col l10 m8 s8 push-m2 push-s2 push-l1 grey lighten-3"></div>

  <div class="row col l10 m10 push-m1 s12">
    <div class="col s10 m5 l4 push-l1 push-s1 push-m1" *ngFor="let dep of departments">
      <div class="card activator white" [ngStyle]="{'border-color': themeColor} " >

        <div class="card-image activator waves-effect waves-block waves-light col s6">
          <div class="section"></div>
          <img *ngIf="dep.logo" class="activator responsive-img center circle" [src]="dep.logo"
            style="width: 100px; height: 100px; border-radius: 50%; margin-left: auto; margin-right: auto; display: block; ">

          <img *ngIf="!dep.logo" class="activator responsive-img center circle"
            src="https://esrimalaysia.com.my/sites/esrimalaysia.com.my/files/2019-07/Organisation-placeholder-tile.jpg"
            style="width: 100px; height: 100px; border-radius: 50%; margin-left: auto; margin-right: auto; display: block; ">
        </div>

        <div class="card-content col s6">
          <h6 style="font-family: 'Anton', sans-serif; font-size: medium; " class="activator card-title "
            [ngStyle]="{'color': themeColor}" [innerHTML]="dep.name" [routerLink]="['/department-sub-menu', dep.id]"></h6>
          <p>{{dep.email}}</p>
          <div class="section"></div>
        </div>


        <div class="tu-tu">
          <div class="row col s12  center ">

            <div class="col s6 cont-email"> <a [href]="'mailto:' + dep.email"><i class="fa fa-envelope-o"></i>
                <p>{{dep.email}}</p>
              </a>
            </div>


            <div class="col s6 cont-call" [ngStyle]="{'color': themeColor}"> <a
                href="tel:{{formatPhone(dep.phone)}}"> <i class="fa fa-phone"></i>
                <p> {{dep.phone}}</p>
              </a>
            </div>

          </div>
        </div>


      </div>
    </div>
  </div>


</div> -->


<div>
  <div class="row col s12 p-t-2 p-b-5">
    <!-- <div class="btn backed-button white black-text left hide-on-med-and-up" (click)="backClicked()">
    <i class="material-symbols-outlined left" style="margin-right: 5px;">arrow_back_ios</i>
  </div> -->

    <div class="row col s9 m10 l5 push-l1 push-s2 push-m1">
      <div class="header-title">
        <h6 *ngIf="!sectionName">Contact Us</h6>
        <h6 *ngIf="sectionName">{{sectionName}}</h6>
      </div>
    </div>

    <div class="divider row col l10 m8 s8 push-m2 push-s2 push-l1 grey lighten-3"></div>

    <div class="row col l10 m10 push-m1 s12" *ngIf="false">
      <div class="col s10 m5 l4 push-l1 push-s1 push-m1" *ngFor="let dep of departments">

        <div class="card activator white" [ngStyle]="{'border-color': themeColor} ">

          <div class="card-image activator waves-effect waves-block waves-light col s6">
            <div class="section"></div>
            <img *ngIf="dep.logo" class="activator responsive-img center circle" [src]="dep.logo"
              style="width: 100px; height: 100px; border-radius: 50%; margin-left: auto; margin-right: auto; display: block; ">

            <img *ngIf="!dep.logo" class="activator responsive-img center circle"
              src="https://esrimalaysia.com.my/sites/esrimalaysia.com.my/files/2019-07/Organisation-placeholder-tile.jpg"
              style="width: 100px; height: 100px; border-radius: 50%; margin-left: auto; margin-right: auto; display: block; ">
          </div>

          <div class="card-content col s6">
            <h6 style="font-family: 'Anton', sans-serif; font-size: medium; " class="activator card-title "
              [ngStyle]="{'color': themeColor}" [innerHTML]="dep.name" [routerLink]="['/department-sub-menu', dep.id]">
            </h6>
            <p>{{dep.email}}</p>
            <div class="section"></div>
          </div>

          <div class="tu-tu">
            <div class="row col s12  center ">

              <div class="col s6 cont-email"> <a [href]="'mailto:' + dep.email"><i class="fa fa-envelope-o"></i>
                  <p>{{dep.email}}</p>
                </a>
              </div>

              <div class="col s6 cont-call" [ngStyle]="{'color': themeColor}"> <a href="tel:{{formatPhone(dep.phone)}}">
                  <i class="fa fa-phone"></i>
                  <p> {{dep.phone}}</p>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row col s12 m10 l10 push-m1 push-l1">
      <ul class="tabs">
        <li class="tab col s4"><a href="#Chief">{{chiefTitle}}</a></li>
        <li class="tab col s4"><a href="#Councillors">{{councillorTitle}}</a></li>
        <li class="tab col s4"><a href="#department">Departments</a></li>
      </ul>
    </div>

    <div class="row col l10 m12  s12">

      <div id="Chief" class="col s12 m5 l4 push-l1 push-m1">

        <div *ngIf="chief">
          <div class="card activator white" [ngStyle]="{'border-color': themeColor}">

            <ul class="row col s2 list-social">
              <li class="col s12" *ngIf="(chief.showFacebookAcc === 'Public') && chief.chiefFacebookAcc">
                <a href="{{chief.chiefFacebookAcc}}">
                  <i class="fa fa-facebook-f"></i>
                </a>
              </li>
              <li class="col s12" *ngIf="(chief.showTwitterAcc === 'Public' ) && chief.chiefTwitterAcc">
                <a href="{{chief.chiefTwitterAcc}}">
                  <i class="fa fa-twitter"></i>
                </a>
              </li>
              <li class="col s12" *ngIf="(chief.showLinkedinAcc === 'Public') && chief.chiefLinkedinAcc">
                <a href="{{chief.chiefLinkedinAcc}}">
                  <i class="fa fa-linkedin"></i>
                </a>
              </li>
              <li class="col s12" *ngIf="(chief.showInstagramAcc === 'Public' ) && chief.chiefInstagramAcc">
                <a href="{{chief.chiefInstagramAcc}}">
                  <i class="fa fa-instagram"></i>
                </a>
              </li>
              <li class="col s12" *ngIf="(chief.showYoutubeAcc === 'Public') && chief.chiefYoutubeAcc">
                <a href="{{chief.chiefYoutubeAcc}}">
                  <i class="fa fa-youtube"></i>
                </a>
              </li>
            </ul>
            <div class="row col s10">
              <div class="card-image activator waves-effect waves-block waves-light col s5">
                <div class="section"></div>

                <img *ngIf="chief.profile_pic" class="activator responsive-img center circle" [src]="chief.profile_pic"
                  style="width: 90px; height: 90px; border-radius: 50%; margin-left: auto; margin-right: auto; display: block; ">

                <img *ngIf="!chief.profile_pic" class="activator responsive-img center circle"
                  src="../../../../assets/img/avatar.jpeg"
                  style="width: 90px; height: 90px; border-radius: 50%; margin-left: auto; margin-right: auto; display: block; ">
              </div>


              <div class="card-content col s7">
                <h6 style="font-family: 'Anton', sans-serif; font-size: 1em; padding: 0; "
                  [routerLink]="['/governance']" class="activator card-title " [ngStyle]="{'color': themeColor}">
                  {{chief.firstName +' ' +
                  chief.lastName}}
                </h6>
                <p>{{chief.email}}</p>

                <div class="section"></div>
              </div>

              <div class="tu-tu">
                <div class="row col s12 l12 m12 center ">

                  <div class="col s5 cont-email"  *ngIf="chief.showEmail"> <a [href]="'mailto:' + chief.email"><i
                        class="fa fa-envelope-o"></i>
                      <p class="label-hidden">{{chief.email}}</p>
                    </a>
                  </div>


                  <div class="col  s5 cont-phone" [ngStyle]="{'color': themeColor}" *ngIf="chief.showOfficePhone"> <a
                      href="tel:{{formatPhone(chief.office_phone)}}">
                      <i class="fa fa-phone"></i>
                      <p class="text label-hidden"> {{chief.office_phone}}</p>
                    </a>
                  </div>

                  <!-- <div class="col s4 cont-call" [ngStyle]="{'color': themeColor}" *ngIf="chief.showMobile"> <a
                  href="tel:{{formatPhone(chief.cell_phone)}}">
                  <i class="fa fa-mobile"></i>
                  <p> {{chief.cell_phone}}</p>
                </a>
              </div> -->
                </div>
                <!-- social-icon center -->

                <!-- <ul class="col s10 push-s1">
                <li class="col s2">
                  <a href="{{chief.chiefFacebookAcc}}"
                    *ngIf="(chief.showFacebookAcc === 'Public') && chief.chiefFacebookAcc">
                    <i class="fa fa-facebook-f"></i>
                  </a>
                </li>
                <li class="col s2">
                  <a href="{{chief.chiefTwitterAcc}}"
                    *ngIf="(chief.showTwitterAcc === 'Public' ) && chief.chiefTwitterAcc">
                    <i class="fa fa-twitter"></i>
                  </a>
                </li>
                <li class="col s2">
                  <a href="{{chief.chiefLinkedinAcc}}"
                    *ngIf="(chief.showLinkedinAcc === 'Public') && chief.chiefLinkedinAcc">
                    <i class="fa fa-linkedin"></i>
                  </a>
                </li>
                <li class="col s2">
                  <a href="{{chief.chiefInstagramAcc}}"
                    *ngIf="(chief.showInstagramAcc === 'Public' ) && chief.chiefInstagramAcc">
                    <i class="fa fa-instagram"></i>
                  </a>
                </li>
                <li class="col s2">
                  <a href="{{chief.chiefYoutubeAcc}}"
                    *ngIf="(chief.showYoutubeAcc === 'Public') && chief.chiefYoutubeAcc">
                    <i class="fa fa-youtube"></i>
                  </a>
                </li>
              </ul> -->

              </div>
            </div>
            <!-- <div class="tu-tu center">
            <div class="col s12" style="padding: 1vh 0vh; border-top: 1px solid red;">
              <div class="col s2">
                <a href="{{chief.chiefFacebookAcc}}"
                  *ngIf="(chief.showFacebookAcc === 'Public') && chief.chiefFacebookAcc">
                  <i class="fa fa-facebook-f"></i>
                </a>
              </div>
              <div class="col s2">
                <a href="{{chief.chiefTwitterAcc}}"
                  *ngIf="(chief.showTwitterAcc === 'Public' ) && chief.chiefTwitterAcc">
                  <i class="fa fa-twitter"></i>
                </a>
              </div>
              <div class="col s2">
                <a href="{{chief.chiefLinkedinAcc}}"
                  *ngIf="(chief.showLinkedinAcc === 'Public') && chief.chiefLinkedinAcc">
                  <i class="fa fa-linkedin"></i>
                </a>
              </div>
              <div class="col s2">
                <a href="{{chief.chiefInstagramAcc}}"
                  *ngIf="(chief.showInstagramAcc === 'Public' ) && chief.chiefInstagramAcc">
                  <i class="fa fa-instagram"></i>
                </a>
              </div>
              <div class="col s2">
                <a href="{{chief.chiefYoutubeAcc}}"
                  *ngIf="(chief.showYoutubeAcc === 'Public') && chief.chiefYoutubeAcc">
                  <i class="fa fa-youtube"></i>
                </a>
              </div>
              <div class="col s2">
                <a href="{{chief.chiefYoutubeAcc}}"
                  *ngIf="(chief.showYoutubeAcc === 'Public') && chief.chiefYoutubeAcc">
                  <i class="fa fa-youtube"></i>
                </a>
              </div>


            </div>
          </div> -->


          </div>
        </div>
      </div>

      <div id="Councillors" class="col s12">

        <div *ngIf="councillors">
          <div class="col s12 m5 l4 push-l1 push-m1" *ngFor="let councillor of councillors">
            <div class="card activator white" [ngStyle]="{'border-color': themeColor} ">
              <ul class="row col s2 list-social">

                <li class="col s12"
                  *ngIf="(councillor.showFacebookAcc === 'Public') && councillor.councillorFacebookAcc">
                  <a href="{{councillor.chiefFacebookAcc}}">
                    <i class="fa fa-facebook-f"></i>
                  </a>
                </li>
                <li class="col s12"
                  *ngIf="(councillor.showTwitterAcc === 'Public' ) && councillor.councillorTwitterAcc">
                  <a href="{{councillor.chiefTwitterAcc}}">
                    <i class="fa fa-twitter"></i>
                  </a>
                </li>
                <li class="col s12"
                  *ngIf="(councillor.showLinkedinAcc === 'Public') && councillor.councillorLinkedinAcc">
                  <a href="{{councillor.chiefLinkedinAcc}}">
                    <i class="fa fa-linkedin"></i>
                  </a>
                </li>
                <li class="col s12"
                  *ngIf="(councillor.showInstagramAcc === 'Public' ) && councillor.councillorInstagramAcc">
                  <a href="{{councillor.chiefInstagramAcc}}">
                    <i class="fa fa-instagram"></i>
                  </a>
                </li>
                <li class="col s12" *ngIf="(councillor.showYoutubeAcc === 'Public') && councillor.councillorYoutubeAcc">
                  <a href="{{councillor.chiefYoutubeAcc}}">
                    <i class="fa fa-youtube"></i>
                  </a>
                </li>
              </ul>
              <div class="row col s10">
                <div class="card-image activator waves-effect waves-block waves-light col s5">
                  <div class="section"></div>

                  <img *ngIf="councillor.profile_pic" class="activator responsive-img center circle"
                    [src]="councillor.profile_pic"
                    style="width: 90px; height: 90px; border-radius: 50%; margin-left: auto; margin-right: auto; display: block; ">

                  <img *ngIf="!councillor.profile_pic" class="activator responsive-img center circle"
                    src="../../../../assets/img/avatar.jpeg"
                    style="width: 90px; height: 90px; border-radius: 50%; margin-left: auto; margin-right: auto; display: block; ">
                </div>

                <div class="card-content col s7">
                  <h6 style="font-family: 'Anton', sans-serif; font-size: 1em; padding: 0;"
                    [routerLink]="['/governance-detail', councillor.applicantID ]" class="activator card-title "
                    [ngStyle]="{'color': themeColor}">{{councillor.firstName +' ' +
                    councillor.lastName}}
                  </h6>
                  <p>{{councillor.email}}</p>

                  <div class="section"></div>
                </div>

                <div class="tu-tu">
                  <div class="row col s12 center ">

                    <div class="col s5 cont-email"> <a [href]="'mailto:' + chief.email"><i class="fa fa-envelope-o"></i>
                        <p class="label-hidden">{{councillor.email}}</p>
                      </a>
                    </div>

                    <div class="col s5 cont-phone" [ngStyle]="{'color': themeColor}"> <a
                        href="tel:{{formatPhone(chief.office_phone)}}">
                        <i class="fa fa-phone"></i>
                        <p class="label-hidden"> {{councillor.office_phone}}</p>
                      </a>
                    </div>

                    <!-- <div class="col s6 cont-call" [ngStyle]="{'color': themeColor}"> <a
                  href="tel:{{formatPhone(chief.cell_phone)}}">
                  <i class="fa fa-mobile"></i>
                  <p> {{councillor.cell_phone}}</p>
                </a>
              </div> -->



                  </div>



                </div>
              </div>
            </div>

            <!-- <div class="profile-card1" [routerLink]="['/governance-detail',councillor.applicantID]" *ngIf="false">
  
            <div class="profile-body">
              <div class="author-img">
                <img *ngIf="councillor.profile_pic" [src]="councillor.profile_pic" alt="">
                <img *ngIf="!councillor.profile_pic" src="../../../../assets/img/avatar.jpeg" alt="">
              </div>
              <div class="name" [ngStyle]="{'color': governanceSection.councillorColor}">
                {{(councillor.displayName) ?
                councillor.displayName :
                councillor.firstName +' ' +
                councillor.lastName}}</div>
  
              <div class="intro">
                <p class="center bold-100" [ngStyle]="{'color': governanceSection.councillorColor}">
                  {{councillor.email}}</p>
                <p class="center bold-100" [ngStyle]="{'color': governanceSection.councillorColor}">
                  {{nation}} Councillor</p>
              </div>
              <div class="social-icon">
                <ul>
                  <li>
                    <a href="{{councillor.chiefFacebookAcc}}"
                      *ngIf="(councillor.showFacebookAcc === 'Public') && councillor.councillorFacebookAcc">
                      <i class="fa fa-facebook-f"></i>
                    </a>
                  </li>
                  <li>
                    <a href="{{councillor.chiefTwitterAcc}}"
                      *ngIf="(councillor.showTwitterAcc === 'Public' ) && councillor.councillorTwitterAcc">
                      <i class="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="{{councillor.chiefLinkedinAcc}}"
                      *ngIf="(councillor.showLinkedinAcc === 'Public') && councillor.councillorLinkedinAcc">
                      <i class="fa fa-linkedin"></i>
                    </a>
                  </li>
                  <li>
                    <a href="{{councillor.chiefInstagramAcc}}"
                      *ngIf="(councillor.showInstagramAcc === 'Public' ) && councillor.councillorInstagramAcc">
                      <i class="fa fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="{{councillor.chiefYoutubeAcc}}"
                      *ngIf="(councillor.showYoutubeAcc === 'Public') && councillor.councillorYoutubeAcc">
                      <i class="fa fa-youtube"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div> -->
          </div>
        </div>
      </div>

      <div id="department" class="col s12">
        <div *ngIf="departments">
          <div class="col s12 m5 l4 push-l1  push-m1" *ngFor="let dep of departments">

            <div class="card activator white" [ngStyle]="{'border-color': themeColor} ">

              <div class="card-image activator waves-effect waves-block waves-light col s5">
                <div class="section"></div>
                <img *ngIf="dep.logo" class="activator responsive-img center circle" [src]="dep.logo"
                  style="width: 90px; height: 90px; border-radius: 50%; margin-left: auto; margin-right: auto; display: block; ">

                <img *ngIf="!dep.logo" class="activator responsive-img center circle"
                  src="https://esrimalaysia.com.my/sites/esrimalaysia.com.my/files/2019-07/Organisation-placeholder-tile.jpg"
                  style="width: 90px; height: 90px; border-radius: 50%; margin-left: auto; margin-right: auto; display: block; ">
              </div>

              <div class="card-content col s7">
                <h6 style="font-family: 'Anton', sans-serif; font-size: 1em; padding: 0; " class="activator card-title "
                  [ngStyle]="{'color': themeColor}" [innerHTML]="dep.name"
                  [routerLink]="['/department-sub-menu', dep.id]">
                </h6>
                <p>{{dep.email}}</p>
                <div class="section"></div>
              </div>

              <div class="tu-tu">
                <div class="row col s12 center ">

                  <div class="col s6 cont-email"> <a [href]="'mailto:' + dep.email"><i class="fa fa-envelope-o"></i>
                      <p class="label-hidden">{{dep.email}}</p>
                    </a>
                  </div>

                  <div class="col s6 cont-phone" [ngStyle]="{'color': themeColor}"> <a
                      href="tel:{{formatPhone(dep.phone)}}">
                      <i class="fa fa-phone"></i>
                      <p class="label-hidden"> {{dep.phone}}</p>
                    </a>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

      </div>

    </div>


  </div>

</div>



















<!-- <div class="row col s12 l10">
    <div class="col s10 m5 l3 push-l1 push-s1 push-m1" *ngFor="let dep of departments">
      <div class="card activator white b-n-1" [ngStyle]="{'border-color': themeColor} ">
        <div class="card-image activator waves-effect waves-block waves-light">
          <div class="section"></div>
          <img *ngIf="dep.logo" class="activator responsive-img center circle" [src]="dep.logo"
            style="width: 100px; height: 100px; border-radius: 50%; margin-left: auto; margin-right: auto; display: block; ">

          <img *ngIf="!dep.logo" class="activator responsive-img center circle"
            src="https://esrimalaysia.com.my/sites/esrimalaysia.com.my/files/2019-07/Organisation-placeholder-tile.jpg"
            style="width: 100px; height: 100px; border-radius: 50%; margin-left: auto; margin-right: auto; display: block; ">
        </div>

        <div class="card-content center">
          <h6 style="font-family: 'Anton', sans-serif; font-size: medium; " class="activator card-title "
            [ngStyle]="{'color': themeColor}" [innerHTML]="dep.name"></h6>
          <p>{{dep.email}}</p>
          <div class="section"></div>
        </div>

        <div class="card-reveal">
          <span class="card-title">
            <h6 style="font-weight: bolder; font-family: 'Anton', sans-serif;" class="center card-title activator"
              [innerHTML]="dep.name"></h6><i class="material-icons right ">close</i>
          </span>

          <div class="row">
            <ul class="collection">
              <li class="collection-item">
                <div>
                  <span> <strong class="bold-text small"> Contact Name : </strong></span>
                  <span class="right black-text small"> <strong> {{dep.contactName}} </strong></span>
                </div>
              </li>
              <li class="collection-item">
                <div>
                  <span> <strong class="bold-text small"> Title : </strong></span>
                  <span class="right black-text small"> <strong> {{dep.contactTitle}} </strong></span>
                </div>
              </li>
              <li class="collection-item">
                <div>
                  <span> <strong class="bold-text small"> Email : </strong></span>
                  <span class="right blue-text small" style="text-decoration: underline;"> <strong><a
                        href="mailto:{{dep.contactEmail}}"> {{dep.contactEmail}} </a>
                    </strong></span>
                </div>
              </li>
              <li class="collection-item">
                <div>
                  <span> <strong class="bold-text small"> Office Phone : </strong></span>
                  <span class="right blue-text small" style="text-decoration: underline;"> <strong><a
                        href="tel:{{formatPhone(dep.contactOfficePhone)}}">
                        {{dep.contactOfficePhone}} </a></strong></span>
                </div>
              </li>
              <li class="collection-item">
                <div>
                  <span> <strong class="bold-text small"> Mobile : </strong></span>
                  <span class="right blue-text small" style="text-decoration: underline;"> <strong><a
                        href="tel:{{formatPhone(dep.contactMobile)}}">{{dep.contactMobile}}</a>
                    </strong></span>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div> -->




<!-- <div class="card-reveal">
          <span class="card-title">
            <h6 style="font-weight: bolder; font-family: 'Anton', sans-serif;" class="center card-title activator"
              [innerHTML]="dep.name"></h6><i class="material-icons right ">close</i>
          </span>
          

          <div class="row">
            <ul class="collection">
              <li class="collection-item">
                <div>
                  <span> <strong class="bold-text small"> Contact Name : </strong></span>
                  <span class="right black-text small"> <strong> {{dep.contactName}} </strong></span>
                </div>
              </li>
              <li class="collection-item">
                <div>
                  <span> <strong class="bold-text small"> Title : </strong></span>
                  <span class="right black-text small"> <strong> {{dep.contactTitle}} </strong></span>
                </div>
              </li>
              <li class="collection-item">
                <div>
                  <span> <strong class="bold-text small"> Email : </strong></span>
                  <span class="right blue-text small" style="text-decoration: underline;"> <strong><a
                        href="mailto:{{dep.contactEmail}}"> {{dep.contactEmail}} </a>
                    </strong></span>
                </div>
              </li>
              <li class="collection-item">
                <div>
                  <span> <strong class="bold-text small"> Office Phone : </strong></span>
                  <span class="right blue-text small" style="text-decoration: underline;"> <strong><a
                        href="tel:{{formatPhone(dep.contactOfficePhone)}}">
                        {{dep.contactOfficePhone}} </a></strong></span>
                </div>
              </li>
              <li class="collection-item">
                <div>
                  <span> <strong class="bold-text small"> Mobile : </strong></span>
                  <span class="right blue-text small" style="text-decoration: underline;"> <strong><a
                        href="tel:{{formatPhone(dep.contactMobile)}}">{{dep.contactMobile}}</a>
                    </strong></span>
                </div>
              </li>
            </ul>
          </div>
        </div> -->