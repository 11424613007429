import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { EducationModel } from 'src/app/models/educationModel';

@Injectable({
  providedIn: 'root'
})
export class EducationService {
  model_parent_id:any;
  constructor(private db: AngularFirestore) { }

  contentListRef: AngularFirestoreCollection<any>;
  contentRef: AngularFirestoreDocument<any>;

// Manage Posts ---------------------------------------------------------------------------------------
  saveEducation(model: EducationModel) {
    const newContent = {
      id: this.db.createId(),
      client_id: model.client_id,
      Institution: model.Institution,
      details: model.details,
      description: model.description,
      completion_date: model.completion_date,
    };

    return this.db.collection('Education').doc(newContent.id).set(newContent);
  }

  updateEducation(model: any) {
    const newContent = {
      id: model.id,
      client_id: model.client_id,
      Institution: model.Institution,
      details: model.details,
      description: model.description,
      completion_date: model.completion_date,
    };
    return this.db.collection('Education').doc(model.id).update(newContent);
  }

  getEducationByMemberId(client_id){
    return this.db.collection('Education', ref => ref.where('client_id', '==', client_id));
  }

  DeleteEducation(model_id: any) {
    return this.db.collection('Education').doc(model_id).delete();
  }

  getEducationById(id: string): AngularFirestoreDocument<EducationModel> {
    return this.db.doc('Education/' + id);
  }
}
