<div class="row p-m-2">

  <a [routerLink]="['/member/home']">
    <div class="col s6 m4 l3">
      <div class="card blue hoverable" style="height: 105px;">
        <div class="card-content">
          <div class="row center">
            <i class="material-icons white-text">house</i>
            <br />
            <strong class="white-text">Back</strong>
          </div>
        </div>
      </div>
    </div>
  </a>

  <!-------------------------------------------- -->
  <div class="col s6 m4 l3">
    <a [routerLink]="['/member/monthly-assistance/new-income-statement']">
      <div class="card pink darken-4 hoverable " style="height: 105px;">
        <div class="card-content">
          <div class="row center">
            <i class="material-icons white-text">account_circle</i>
            <br />
            <strong class="white-text">Create Income Statement</strong>
          </div>
        </div>
      </div>
    </a>
  </div>

  <!--------------------------  ------------------ -->
  <div class="col s6 m4 l3">
    <a [routerLink]="['/member/monthly-assistance/upload-statement']">
      <div class="card teal darken-2 hoverable " style="height: 105px;">
        <div class="card-content">
          <div class="row center">
            <i class="material-icons white-text">smartphone</i>
            <br />
            <strong class="white-text">Scan/Upload From Device</strong>
          </div>
        </div>
      </div>
    </a>
  </div>

  <div class="col s6 m4 l3">
    <a [routerLink]="['/member/contact-us']">
      <div class="card purple darken-2 hoverable " style="height: 105px;">
        <div class="card-content">
          <div class="row center">
            <i class="material-icons white-text">email</i>
            <br />
            <strong class="white-text" style="margin-bottom: 15px;">Declare No Income</strong>
          </div>
        </div>
      </div>
    </a>
  </div>
</div>