

export class PSSSPApplicationModel {
    applicantID: any;
    firstName: '';
    lastName: '';
    middleName: '';
    street: '';
    apt: '';
    city: '';
    province: '';
    postalCode: '';
    homeNumber: '';
    mobile: '';
    dob: Date;
    SIN: '';
    studentNo: '';
    treatyNo: '';
    email: '';
    maritalStatus: '';

    spouseFirstName: '';
    spouseLastName: '';
    spouseMiddleName: '';
    spouseLive: '';
    spouseEmployed: '';

    dependents: [Children];

    assistanceType: '';
    institution: '';
    location: '';
    program: '';
    startDate: Date;;
    endDate: Date;
    length: '';
    yearsCompleted: '';
    terms: {
        fall: '';
        winter: '';
        inter_session: '';
        summer_session: '';
    }

    applicantName: '';
    signature: '';
    date: Date;;
    documents: [Document];
}

export class Children {
    first_name: '';
    last_name: '';
    dob: Date;
    age: '';
    grade: '';
    school: '';
    comment: '';
}
export class Document {
    docType: '';
    name: '';
    type: '';
    link: '';
}
