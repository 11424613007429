import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class TribalCouncilService {

  fnListRef: AngularFirestoreCollection<any>;
  fnDocRef: AngularFirestoreDocument<any>;

  constructor(private db: AngularFirestore) { }
  

     saveTribalCouncil(tCList: any[]) {
    // let count = 0;
    // tCList.forEach(element => {
    //   const fnModel = {
    //     id : this.db.createId(),
    //     CouncilName: element.CouncilName,
    //     Website: element.Website,
    //     Province: environment.firstNation.province
    //   };
    //   this.db.collection('TribalCouncil').doc(fnModel.id).set(fnModel);
    //   count += 1;
    // });
    // console.log(count + " Records saved successfully ");

  }

  getTribalCouncilList(): AngularFirestoreCollection<any> {
    this.fnListRef = this.db.collection('TribalCouncil');
    return this.fnListRef;
  }

  getTribalCouncilByProvince(province: any): AngularFirestoreCollection<any> {
    this.fnListRef = this.db.collection('TribalCouncil', ref => ref.where('Province', '==', province));
    return this.fnListRef;
  }

}
