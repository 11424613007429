<div class="row container-pi">
  <div class="row card-panel b-n-1">
    <h4 class="title-section2 title-member p-l-1">Poll / Survey List</h4>
    <div class="col s12 p-b-5">
      <ul class="tabs">
        <li class="tab col s2"><a class="active" href="#test1">Nomination</a></li>
        <li class="tab col s2"><a href="#test2">Election</a></li>
        <li class="tab col s2"><a href="#test3">Polls and Surveys</a></li>
      </ul>
    </div>
    <div id="test1" class="col s12">
      <div class="row col s12">
        <app-firebase-loading *ngIf="showLoader"></app-firebase-loading>

        <div class="col s12 l12">

          <div class="row card-panel white col s12" *ngIf="!model && !showLoader">
            <p class="center"> <strong class="red-text"> <i class="material-icons">warning</i> No Nomination Found In
                the Database</strong> </p>
          </div>
          <div *ngIf="isUnderAge">
            <div class="row card-panel white col s12">
              <p class="center"> <strong class="orange-text"> <i class="material-icons">warning</i> Current user can
                  not
                  give nomination.</strong> </p>
            </div>
          </div>

          <div *ngIf="model && !isUnderAge">
            <div class="row card-panel white" *ngFor="let item of model">
              <a class="col s12" [routerLink]="['/member/nomination-detail', item.id]">

                <div class="col s4">
                  <a [routerLink]="['/member/nomination-detail', item.id]" class="truncate">{{item.name}}</a>
                </div>
                <div class="col s4">
                  <span>Starts On: {{item.startDate}}</span>
                </div>
                <div class="col s4">
                  <span>Ends On: {{item.endDate}}</span>
                </div>
              </a>
            </div>
          </div>
          <!-- <button [ngClass]="'col s12 btn waves-effect ' + themeColor" (click)="backClicked()">
            <i class="material-icons left">arrow_back_ios</i> Back To Previous Page
          </button> -->
        </div>
      </div>
    </div>

    <div id="test2" class="col s12">
      <app-election-list></app-election-list>
    </div>
    
    <div id="test3" class="col s12">
      <app-survey-list></app-survey-list>
    </div>
  </div>
</div>
