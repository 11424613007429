export class SCISApplicationModel{
    familyName: 'Tamene'
    givenName: 'Mihret';
    birth_familyName: '';
    aliasName: '';
    gender: '';
    dob: Date;
    number: '';
    street: '';
    apartment: '';
    POBox: '';
    city: '';
    province_state: '';
    postalCode: '';
    telephone: '';
    telephone2: '';
    email: '';
    sameMailingAddress: boolean;
    mailing_number: '';
    mailing_street: '';
    mailing_apartment: '';
    mailing_POBox: '';
    mailing_city: '';
    mailing_province_state: '';
    mailing_postalCode: '';

    bandNumber: '';
    bandName: '';

    proofOfBirthDocument: any;
    nameLinkingDocumnet: any[];
    supportingIdentityDocuments: any[];

    adopted: boolean;
    ISNEntitlement: any;
    adoptiveMother: any;
    adoptiveFather: any;
    birthMother: any;
    birthFather: any;

    father: any;
    mother: any;
    maternal_grandparents: any[];
    paternal_grandparents: any[];
    other_familyMembers: any[];

    SCISRequested: boolean;
    photo: any;
    photoBy: '';
    photoAddress: '';
    photoDate: '';
    signature: '';
    applicantName: '';
    signatureDate: '';
    IRA_assisted: boolean;
    IRA_name: '';
    bandNo_initiatorCode: '';

}