import { Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { environment } from 'src/environments/environment';
declare var $: any;
@Component({
  selector: 'app-board-list-shared',
  templateUrl: './board-list-shared.component.html',
  styleUrls: ['./board-list-shared.component.css']
})

export class BoardListSharedComponent implements OnInit {

  @Input() viewModel: any;
  showMore = [];

  defaultThemeColor = environment.appTheme.themeColor;
  themeColor: any;
  buttonType: any;

  constructor(private settingService: AppSettingsService, 
    private location: Location) { 
    this.themeColor = this.settingService.getAppThemeColor() ? this.settingService.getAppThemeColor(): this.defaultThemeColor;
    this.buttonType =  this.settingService.getButtonType() ? this.settingService.getButtonType(): 'rectangularButton';
  }

  ngOnInit() {
    if (this.viewModel) {
      const len = this.viewModel.length;
      this.showMore = new Array(len).fill(false);
    }
    $(document).ready(function(){
      $('.tabs').tabs();
    });
  }

  backClicked() {
    this.location.back();
  }

  readMore(index){
    this.showMore[index] = !this.showMore[index];
  }

  truncateHTML(text: string, limit: string): string {
    const charlimit = +limit;
    if (!text || text.length <= charlimit) {
      return text.replace(/<(?:.|\n)*?>/gm, '').replace(/&nbsp;/gm, ' ');
    }
    return text.replace(/<(?:.|\n)*?>/gm, '').replace(/&nbsp;/gm, ' ').substring(0, charlimit) + '...';
  }
}
