import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Options } from 'sortablejs';
import { Router } from '@angular/router';
import { EventsService } from 'src/app/services/firebase/events.service';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { DataService } from 'src/app/services/data.service';
import { toast } from 'materialize-css';

declare var $: any;

@Component({
  selector: 'app-news-events',
  templateUrl: './news-events.component.html',
  styleUrls: ['./news-events.component.css']
})

export class NewsEventsComponent implements OnInit {

  nationOfUse = environment.firstNation.name;
  defaultThemeColor = environment.appTheme.themeColor;
  themeColor: any;
  buttonType: any;
  user: any;

  news: any[];
  events: any[];
  permissions: any[];
  newsPermission: any;
  defaultMenus: any[];
  model: any[];
  isSuperAdmin = false;
  adminMenus: any[];
  showLoading = false;
  options: Options = {
    animation: 150,
  };

  //Menus
  myNationMenu = false;
  manageMenu = false;
  newsAndEventsMenu = false;
  statisticsMenu = false;
  settingsMenu = false;
  pollsAndSurveyMenu = false;
  selectedMenu = {
    name: 'Create',
    icon: 'add_circle',
  }
  sectionName: string;
  editName = false;

  constructor(private authService: AuthService,
    public appSettingsService: AppSettingsService,
    private router: Router,
    private settingsService: AppSettingsService,
    private eventService: EventsService,
    private dataService: DataService
  ) {
    this.themeColor = this.appSettingsService.getAppThemeColor() ? this.appSettingsService.getAppThemeColor() : this.defaultThemeColor;
    this.buttonType = this.appSettingsService.getButtonType() ? this.appSettingsService.getButtonType() : 'rectangularButton';
    this.options = {
      onUpdate: (event: any) => {
        this.model.forEach(element => {
          element.index = this.model.findIndex(x => x.id === element.id);
        });
        this.eventService.SortEventsOnServer(this.model);
      }
    };

    this.defaultMenus = this.dataService.getAdminMenus();
   
    this.authService.getCurrentApplicantIDObservable().subscribe(
      user => {
        if (user) {
          this.user = user;
          if (user.isSuperAdmin) this.isSuperAdmin = true;
          if (user.permissions) this.permissions = user.permissions;
          else if (!this.isSuperAdmin) {
            $('.modal').modal();
            $('#permissionModal').modal('open');
          }
        }
      }
    );
  }

  ngOnInit(): void {
    this.appSettingsService.getMenuName('NewsAndEvents').valueChanges().subscribe(menus => {
      if (menus && menus.length > 0) {
        this.sectionName = menus[0].displayName;
      }
    });
    this.settingsService.getAdminMenus().valueChanges().subscribe(menus => {
      if (menus && menus.length > 0) {
        this.adminMenus = menus;
        this.adminMenus = this.adminMenus.sort((a, b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0));
      } else {
        this.adminMenus = this.defaultMenus.sort((a, b) => (a.index > b.index) ? 1 : ((b.index > a.index) ? -1 : 0));
      }
    });

    this.router.navigate(['admin/news-events/home-page']);

    setTimeout(() => {
      $('.modal').modal();
      $('.dropdown-trigger').dropdown({
        constrainWidth: false,
        isScrollable: true,
        hover: true
      });
      $('.tooltipped').tooltip();
      $('.collapsible').collapsible();
    }, 25);
  }

  editNameClicked() {
    this.editName = true;
  }

  saveName() {
    this.appSettingsService.UpdateMenu('NewsAndEvents', this.sectionName).then((update) => {
      this.editName = false;
      toast({ html: `${update}`, classes: 'green' });
    }).catch((error) => {
      toast({ html: `${error}`, classes: 'red' });
    });
  }

  select(menu, icon) {
    this.selectedMenu.name = menu;
    this.selectedMenu.icon = icon;
  }

  menuClicked(item: any) {
    if (item.name === 'NewsAndEvents') return;
    if (this.getPermission(item.name)) {
      if (item.name === 'MyNation') {
        localStorage.setItem('From', 'MyNation');
        const sp = item.submenus;
        if (sp) {
          localStorage.setItem('submenus', JSON.stringify(sp));
          let submenu_permission = this.getSubmenuPermission(item.name);
          if (submenu_permission) {
            localStorage.setItem('permissions', JSON.stringify(submenu_permission));
          }
        }
        this.router.navigate(['admin/dashboard-sub-menu']);
      }
      else if (item.name === 'Manage') {
        localStorage.setItem('From', 'Manage');
        const sp = item.submenus;
        if (sp) {
          localStorage.setItem('submenus', JSON.stringify(sp));
          let submenu_permission = this.getSubmenuPermission(item.name);
          if (submenu_permission) {
            localStorage.setItem('permissions', JSON.stringify(submenu_permission));
          }
        }
        this.router.navigate(['admin/dashboard-sub-menu']);
      }
      else if (item.name === 'Settings') {
        localStorage.removeItem('From');
        this.router.navigate(['admin/settings']);
      }
      else if (item.name === 'Stats') {
        localStorage.setItem('From', 'Stats');
        const sp = item.submenus;
        if (sp) localStorage.setItem('submenus', JSON.stringify(sp));
        let submenu_permission = this.getSubmenuPermission(item.name);
        if (submenu_permission) {
          localStorage.setItem('permissions', JSON.stringify(submenu_permission));
        }
        this.router.navigate(['admin/dashboard-sub-menu']);
      }

      else if (item.name === 'PollsAndSurveys') {
        this.router.navigate(['admin/survey-list']);
      }
      else if (item.name === 'Communication') {
        this.router.navigate(['admin/messages']);
      }
    }
  }

  getSubMenu(item) {
    let menu: any = this.adminMenus.filter(m => m.name == item);
    if (menu && menu.length > 0) {
      if (menu[0].submenus && menu[0].submenus.length > 0) {
        return menu[0].submenus
      }
    }
  }

  getPermission(menu) {
    if (menu == "Logout") return true;
    if (this.isSuperAdmin) return true;
    if (this.permissions) {
      if (this.permissions.length > 0) {
        let p = this.permissions.find(p => p.menu == menu);
        if (p) return true;
      }
    }
    return false;
  }

  getSubmenuPermission(menu) {
    if (this.permissions && this.permissions.length > 0) {
      let p = this.permissions.find(p => p.menu == menu);
      if (p) {
        return p.submenus;
      }
    }
    return false;
  }

  backClicked() {
    this.router.navigate(['/admin/dashboard']);
  }

}
