import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { PseApplicationService } from 'src/app/services/api/pse-application.service';
import { environment } from 'src/environments/environment';
import { toast } from 'materialize-css';
import { Location } from '@angular/common';
import { ImageModel } from 'src/app/models/ImageModel';
import { MediaService } from 'src/app/services/api/media.service';
import { EmailService } from 'src/app/services/api/email.service';
import { FundingDocumentModel, PseFundingModel } from 'src/app/models/PseFunding.Model';
import { FireMembersService } from 'src/app/services/firebase/fire-members.service';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-pse-supplemental-funding',
  templateUrl: './pse-supplemental-funding.component.html',
  styleUrls: ['./pse-supplemental-funding.component.css']
})

export class PseSupplementalFundingComponent implements OnInit {

  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  nationName = environment.firstNation.displayName;

  FundingForm: UntypedFormGroup;
  applicantID = 0;
  pse_app_id = 0;
  member: any;
  current_family_comp_id = 0;
  app_info: any;
  rent_required: false;
  deposit_required: false;
  t_funding_amount = 0;
  rates: any;

  fundingModel: PseFundingModel;
  step = 1;

  //travel rates
  mileage_rate = 0;
  accommdation_rate = 0;
  breakfast_rate = 0;
  lunch_rate = 0;
  dinner_rate = 0;

  balance_amount = 0;
  selected_BD_Year = 0;
  selected_BD_Month = '';
  selected_School_Period = '';

  //load documents
  showUpload = false;
  binDoc: File;
  fileType: string;
  filesUploaded = false;
  showUpload2 = false;
  base64Image: any;
  otherInfoForm: UntypedFormGroup;
  docsUploaded = false;
  tmpDocumentModel: any[];
  documentsModel: FundingDocumentModel[];
  aDoc: FundingDocumentModel;

  showDocumentsType = false; ///

  loading = false;

  fileTypes: any = ['Damage Deposit', 'Tuition', 'Books', 'Rent', 'Utilities', 'Living Allowance', 'Child Care', 'Travel', 'Other'];

  student_email = '';
  Student_name = '';
  request_details = '';

  constructor(private fb: UntypedFormBuilder,
    private authService: AuthService,
    private pseService: PseApplicationService,
    private location: Location,
    private mediaService: MediaService,
    private emailService: EmailService,
    private fireMembersService: FireMembersService) { }

  ngOnInit(): void {

    if (localStorage.getItem('applicantID')) {
      this.applicantID = Number(localStorage.getItem('applicantID'));
    } else {
      this.applicantID = this.authService.getCurrentApplicantID();
    }
    if (this.applicantID == 0) { this.authService.signOut(); }

    if (localStorage.getItem('selected_BD_Year')) {
      this.selected_BD_Year = Number(localStorage.getItem('selected_BD_Year'));
      localStorage.setItem('selected_BD_Year', '');
    }
    if (localStorage.getItem('selected_BD_Month')) {
      this.selected_BD_Month = localStorage.getItem('selected_BD_Month');
      localStorage.setItem('selected_BD_Month', '');
    }
    if (localStorage.getItem('selected_School_Period')) {
      this.selected_School_Period = localStorage.getItem('selected_School_Period');
      localStorage.setItem('selected_School_Period', '');
    }

    this.FundingForm = this.fb.group({
      //deposit_ytd: [''],
      //deposit_budget: [''],
      deposit_amount: [''],
      //tuition_ytd: [''],
      //tuition_budget: [''],
      tuition_amount: [''],

      //books_ytd: [''],
      //books_budget: [''],
      books_amount: [''],

      // rent_ytd: [''],
      //rent_budget: [''],
      rent_amount: [''],

      //utilities_ytd: [''],
      //utilities_budget: [''],
      utilities_amount: [''],

      //living_ytd: [''],
      //living_budget: [''],
      living_amount: [''],

      //childcare_ytd: [''],
      //childcare_budget: [''],
      childcare_amount: [''],

      //other_ytd: [''],
      //other_budget: [''],
      other_amount: [''],

      mileage: [''],
      breakfast: [''],
      lunch: [''],
      accommodation: [''],
      dinner: [''],
      funding_amt: ['']
    });

    this.otherInfoForm = this.fb.group({
      upload_documents: ['', [Validators.required]],
      documents: this.fb.array([]),
      document: [''],
      document_type: [''],
      document_name: [''],
      document_link: ['']
    });

    this.otherInfoForm.get('upload_documents').valueChanges.subscribe(value => {
      if (value == 'yes') {
        this.document.setValidators([Validators.required]);
        this.document_type.setValidators([Validators.required]);
      } else {
        this.document.clearValidators();
        this.document_type.clearValidators();
      }
      this.document.updateValueAndValidity();
      this.document_type.updateValueAndValidity();
    });

    this.pseService.getStudent(this.applicantID).subscribe(x => {
      if (x) {
        this.member = x.find(cmp => cmp.school_period === this.selected_School_Period);
        if (this.member) {
          if (this.member.current_PSE_app_id) this.pse_app_id = this.member.current_PSE_app_id;
          if (this.member.current_family_comp) { this.current_family_comp_id = this.member.current_family_comp; }
          if (this.member.Email) { this.student_email = this.member.Email; }
          this.Student_name = this.member.GivenName + ' ' + this.member.LastName;
        }

        //this.member = x[0];
        // if (this.member.current_PSE_app_id) this.pse_app_id = this.member.current_PSE_app_id;
        // if (this.member.current_family_comp) { this.current_family_comp_id = this.member.current_family_comp ; }
        // if ( this.member.Email) { this.student_email= this.member.Email ; }
        // this.Student_name = this.member.GivenName + ' ' + this.member.LastName;
      }

      setTimeout(() => {
        $('select').formSelect();
        M.updateTextFields();
      }, 25);

      this.pseService.getFundingRate().subscribe(r => {
        if (r) {
          this.rates = r[0];
          if (this.rates.mileage) this.mileage_rate = this.rates.mileage;
          if (this.rates.accommodation) this.accommdation_rate = this.rates.accommodation;
          if (this.rates.breakfast) this.breakfast_rate = this.rates.breakfast;
          if (this.rates.lunch) this.lunch_rate = this.rates.lunch;
          if (this.rates.dinner) this.dinner_rate = this.rates.dinner;
        }

        setTimeout(() => {
          $('select').formSelect();
          M.updateTextFields();
        }, 25);

        if (this.pse_app_id > 0) {
          this.pseService.getPSEApp(this.pse_app_id).subscribe(d => {   //this.pseService.getPSEApp(this.applicantID).subscribe(d => {
            if (d) {
              this.app_info = d[0];
              if (this.app_info.rent_required !== null) { this.rent_required = this.app_info.rent_required; } else { this.rent_required = false; }
              if (this.app_info.deposit_required !== null) { this.deposit_required = this.app_info.deposit_required; } else { this.deposit_required = false; }
            }

            setTimeout(() => {
              $('select').formSelect();
              M.updateTextFields();
            }, 25);
          });
        }
      });
    });

    this.fundingModel = new PseFundingModel();
    this.fundingModel.year = this.selected_BD_Year;
    this.fundingModel.month = this.selected_BD_Month;
    var serial_date: Date = new Date(this.selected_BD_Year, this.month_to_int(this.selected_BD_Month) - 1, 1);
    this.fundingModel.date_serial = serial_date;   //.toDateString();
  }

  month_to_int(month: string) {
    if (month.toLowerCase() === 'january') {
      return 1;
    } else if (month.toLowerCase() === 'february') {
      return 2;
    } else if (month.toLowerCase() === 'march') {
      return 3;
    } else if (month.toLowerCase() === 'april') {
      return 4;
    } else if (month.toLowerCase() === 'may') {
      return 5;
    } else if (month.toLowerCase() === 'june') {
      return 6;
    } else if (month.toLowerCase() === 'july') {
      return 7;
    } else if (month.toLowerCase() === 'august') {
      return 8;
    } else if (month.toLowerCase() === 'september') {
      return 9;
    } else if (month.toLowerCase() === 'october') {
      return 10;
    } else if (month.toLowerCase() === 'november') {
      return 11;
    } else if (month.toLowerCase() === 'december') {
      return 12;
    } else {
      return -1;
    }
  }

  //get deposit_ytd() { return this.FundingForm.get('deposit_ytd'); }
  //get deposit_budget() { return this.FundingForm.get('deposit_budget'); }
  get deposit_amount() { return this.FundingForm.get('deposit_amount'); }
  //get tuition_ytd() { return this.FundingForm.get('tuition_ytd'); }
  //get tuition_budget() { return this.FundingForm.get('tuition_budget'); }
  get tuition_amount() { return this.FundingForm.get('tuition_amount'); }

  //get books_ytd() { return this.FundingForm.get('books_ytd'); }
  // get books_budget() { return this.FundingForm.get('books_budget'); }
  get books_amount() { return this.FundingForm.get('books_amount'); }

  //get rent_ytd() { return this.FundingForm.get('rent_ytd'); }
  //get rent_budget() { return this.FundingForm.get('rent_budget'); }
  get rent_amount() { return this.FundingForm.get('rent_amount'); }

  //get utilities_ytd() { return this.FundingForm.get('utilities_ytd'); }
  //get utilities_budget() { return this.FundingForm.get('utilities_budget'); }
  get utilities_amount() { return this.FundingForm.get('utilities_amount'); }

  // get living_ytd() { return this.FundingForm.get('living_ytd'); }
  //get living_budget() { return this.FundingForm.get('living_budget'); }
  get living_amount() { return this.FundingForm.get('living_amount'); }

  //get childcare_ytd() { return this.FundingForm.get('childcare_ytd'); }
  //get childcare_budget() { return this.FundingForm.get('childcare_budget'); }
  get childcare_amount() { return this.FundingForm.get('childcare_amount'); }

  //get other_ytd() { return this.FundingForm.get('other_ytd'); }
  //get other_budget() { return this.FundingForm.get('other_budget'); }
  get other_amount() { return this.FundingForm.get('other_amount'); }

  get mileage() { return this.FundingForm.get('mileage'); }
  get breakfast() { return this.FundingForm.get('breakfast'); }
  get lunch() { return this.FundingForm.get('lunch'); }
  get accommodation() { return this.FundingForm.get('accommodation'); }
  get dinner() { return this.FundingForm.get('dinner'); }
  get funding_amt() { return this.FundingForm.get('funding_amt'); }

  get documentsControls() { return this.otherInfoForm.get('documents') as UntypedFormArray; }
  get document() { return this.otherInfoForm.get('document'); }
  get document_type() { return this.otherInfoForm.get('document_type'); }
  get document_name() { return this.otherInfoForm.get('document_name'); }
  get document_link() { return this.otherInfoForm.get('document_link'); }

  /*
    checkCheque(radio){
      var element = <HTMLInputElement> document.getElementById("Cheque");
      var isChecked = element.checked;
      if (isChecked){ 
       // this.disbursmentForm.get('disbursment.Cheque').reset();
        this.disbursmentForm.get('disbursment.PO').reset();
        this.disbursmentForm.get('disbursment.EFT').reset();
        this.disbursmentForm.get('disbursment.Other').reset();
      }else{  
       // this.fall_term_checked=false;
      }
      setTimeout(() => {
        $('select').formSelect();
        M.updateTextFields();
      }, 25);
    }
  */

  submitFunding(values) {

    if (values.deposit_amount) { this.fundingModel.deposit_amt = values.deposit_amount; this.request_details += 'Deposit: $' + values.deposit_amount + ' <br/> '; } else { this.fundingModel.deposit_amt = 0; }
    if (values.tuition_amount) { this.fundingModel.tuition_amt = values.tuition_amount; this.request_details += 'Tuition: $' + values.tuition_amount + ' <br/> '; } else { this.fundingModel.tuition_amt = 0; }
    if (values.books_amount) { this.fundingModel.books_amt = values.books_amount; this.request_details += 'Books: $' + values.books_amount + ' <br/> '; } else { this.fundingModel.books_amt = 0; }
    if (values.rent_amount) { this.fundingModel.rent_amt = values.rent_amount; this.request_details += 'Rent: $' + values.rent_amount + ' <br/> '; } else { this.fundingModel.rent_amt = 0; }
    if (values.utilities_amount) { this.fundingModel.utilities_amt = values.utilities_amount; this.request_details += 'Utilities: $' + values.utilities_amount + ' <br/> '; } else { this.fundingModel.utilities_amt = 0; }
    if (values.living_amount) { this.fundingModel.living_allowance_amt = values.living_amount; this.request_details += 'Living Allowance: $' + values.living_amount + ' <br/> '; } else { this.fundingModel.living_allowance_amt = 0; }
    if (values.childcare_amount) { this.fundingModel.childcare_amt = values.childcare_amount; this.request_details += 'Child Care: $' + values.childcare_amount + ' <br/> '; } else { this.fundingModel.childcare_amt = 0; }
    if (values.other_amount) { this.fundingModel.other_amt = values.other_amount; this.request_details += 'Other AMT: $' + values.other_amount + ' <br/> '; } else { this.fundingModel.other_amt = 0; }

    if (values.mileage) { this.fundingModel.mileage = values.mileage; } else { this.fundingModel.mileage = 0; }
    if (values.breakfast) { this.fundingModel.breakfast = values.breakfast; } else { this.fundingModel.breakfast = 0; }
    if (values.lunch) { this.fundingModel.lunch = values.lunch; } else { this.fundingModel.lunch = 0; }
    if (values.accommodation) { this.fundingModel.accommodation = values.accommodation; } else { this.fundingModel.accommodation = 0; }
    if (values.dinner) { this.fundingModel.dinner = values.dinner; } else { this.fundingModel.dinner = 0; }
    this.fundingModel.applicantID = this.applicantID;
    this.fundingModel.funding_app_id = this.pse_app_id;

    var travel_funding = 0;
    travel_funding = this.fundingModel.mileage * this.mileage_rate + this.fundingModel.accommodation * this.accommdation_rate + this.fundingModel.breakfast * this.breakfast_rate + this.fundingModel.lunch * this.lunch_rate + this.fundingModel.dinner * this.dinner_rate;
    this.fundingModel.travel_amt = travel_funding;
    if (travel_funding > 0) { this.request_details += 'Travel: $' + travel_funding + ' <br/> '; }

    // var t_funding = 0;
    this.t_funding_amount = Number(travel_funding) + Number(this.fundingModel.deposit_amt) + Number(this.fundingModel.tuition_amt) + Number(this.fundingModel.books_amt) + Number(this.fundingModel.rent_amt) + Number(this.fundingModel.utilities_amt) + Number(this.fundingModel.living_allowance_amt) + Number(this.fundingModel.childcare_amt) + Number(this.fundingModel.other_amt);

    this.balance_amount = this.t_funding_amount;
    this.step += 1;
  }

  submitOtherInfo(values) {

    this.tmpDocumentModel = values.documents;

    //loading documents
    this.fundingModel.documents = [];
    this.tmpDocumentModel.forEach(cl => {
      this.aDoc = new FundingDocumentModel();
      this.aDoc.applicantID = this.applicantID;
      this.aDoc.docType = cl.docType;
      this.aDoc.name = cl.name;
      this.aDoc.type = cl.type;
      this.aDoc.link = cl.link;
      if (cl.photo) { this.aDoc.photo = cl.photo; } else { this.aDoc.photo = ""; }
      this.aDoc.funding_app_id = this.pse_app_id;
      this.fundingModel.documents.push(this.aDoc);
    });

    this.pseService.saveFundingRequest(this.fundingModel).subscribe(data => {
      if (data) {
        if (this.student_email) {
          this.emailService.sendSubmitFundingRequestNotification(this.member.Email, this.member.GivenName).subscribe(e => {
            if (e) {
              this.student_email = this.student_email.toLowerCase();

              if (this.student_email.endsWith('nationadmin.ca') || this.student_email.endsWith('hl360.ca') || this.student_email.endsWith('taxexempt.ca')) {
                this.showMessage();
                this.loading = false;
              } else {
                let emails: string[];
                this.fireMembersService.getAdminAccounts().valueChanges().subscribe(x => {
                  if (x) {
                    emails = [];
                    x.forEach(o => {
                      if (o.Email == 'mirandad@lrrcn.ab.ca' || o.Email == 'marylou.grande@lrrcn.ab.ca' || o.Email == 'daled@lrrcn.ab.ca' || o.Email == 'colindas@lrrcn.ab.ca' || o.Email == 'ivyl@lrrcn.ab.ca') {
                        emails.push(o.Email);
                      }
                      //if (o.Email === 'michael@hl360.ca'){  emails.push(o.Email);  }             
                    });

                    this.emailService.sendFundingRequestNotificationToAdmin(this.Student_name, emails, this.request_details).subscribe(m => {
                      if (m) {
                        this.showMessage();
                        this.loading = false;
                      }
                    });
                  }
                });
              }
            }
          });
        }
        toast({ html: 'Thank you for using ' + this.nationName + ' mobile App, The administrator will review your reqeust and get back to you shortly !', classes: 'green' });
        this.backClicked();
      }
    });
  }

  //upload documents
  onUplodYes() {
    this.showUpload = true;
    this.showUpload2 = false;
    setTimeout(() => {
      $('.modal').modal();
      $('#FileUploadModal').modal('open');
      $('select').formSelect();
    });
  }

  onUplodNo() {
    this.showUpload = false;
    this.documentsControls.setValue([]);
  }

  addAnotherFile() {
    this.showUpload = true;
    this.filesUploaded = false;
    this.showDocumentsType = false;
    this.fileType = '';

    setTimeout(() => {
      $('#FileUploadModal').modal('open');
      $('select').formSelect();
      $('#DocumentsLoadModal').modal('close');
      M.updateTextFields();
    }, 25);
  }

  newDoc(values: any): UntypedFormGroup {
    return this.fb.group({
      docType: [values.docType],
      type: [values.type],
      name: [values.name],
      link: [values.link],
      photo: [values.photo]
    });
  }

  docTypeChange(value) {
    //  this.document_type.patchValue(value);
  }

  async handleFileInput(files: FileList) {
    this.binDoc = undefined;
    if (files) {
      this.binDoc = files[0];

      this.base64Image = await this.imageToBase64(files[0]);
      this.document_name.patchValue(files[0].name);
      this.fileType = this.validateInput(this.binDoc.name);

      this.showDocumentsType = true;

      setTimeout(() => {
        $('select').formSelect();
        $('.collapsible').collapsible();
        $('.tooltipped').tooltip();
        M.updateTextFields();
      }, 25);
    }
  }

  imageToBase64 = async (file: File) => {
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      }
    });
  }

  uploadFile(values) {
    this.loading = true;

    if (this.fileType == '') {
      toast({ html: 'Please Select The Document Type.', classes: 'red' });
      return;
    }

    if (this.binDoc) {
      if (this.validateInput(this.binDoc.name) == 'image') {
        const img = new ImageModel();
        img.imgBase64 = this.base64Image.replace(/^data:image\/[a-z]+;base64,/, '');

        this.mediaService.uploadImage(img).subscribe(upload => {
          if (upload) {
            if (upload.imgLink) {
              const value = {
                docType: 'JPG', //'image',
                name: values.document_name,
                type: values.document_type,
                link: upload.imgLink,
                photo: this.base64Image.replace(/^data:image\/[a-z]+;base64,/, '')
              }
              this.documentsControls.push(this.newDoc(value));
              this.filesUploaded = true;
              this.showUpload2 = false;
              this.document_type.reset();
              this.document.reset();
              this.document_type.setValue('');
              $('#document').val('');
            } else {
              toast({ html: 'Something Went Wrong While uploading image document! Please Try again.', classes: 'red' });

            }
            this.loading = false;
          }
        });
      } else if (this.validateInput(this.binDoc.name) == 'pdf') {
        this.mediaService.uploadPDF(this.binDoc).subscribe(upload => {
          if (upload) {
            if (upload.imgLink) {
              const value = {
                docType: 'PDF',     //'pdf',
                name: values.document_name,
                type: values.document_type,
                link: upload.imgLink,
                photo: this.base64Image.replace(/^data:application\/[a-z]+;base64,/, '')
              }
              this.documentsControls.push(this.newDoc(value));
              this.filesUploaded = true;
              this.loading = false;
              this.showUpload2 = false;
              this.document_type.reset();
              this.document.reset();
              this.document_type.setValue('');
              $('#document').val('');
            } else {
              toast({ html: 'Something Went Wrong While uploading PDF document! Please Try again.', classes: 'red' });
            }
            this.loading = false;
          }
        });
      }
      this.showDocumentsType = false;
    }

    setTimeout(() => {
      $('#FileUploadModal').modal('close');
      $('select').formSelect();
      $('#DocumentsLoadModal').modal('open');
      M.updateTextFields();
    }, 25);
  }

  showUploadInput() {
    this.showUpload2 = true;
  }

  private validateInput(name: any) {
    const ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() === 'png') {
      return 'image';
    } else if (ext.toLowerCase() === 'jpg') {
      return 'image';
    } else if (ext.toLowerCase() === 'jpeg') {
      return 'image';
      //} else if (ext.toLowerCase() === 'mp4' || ext.toLowerCase() === 'mkv') {
      // return 'video';
    }
    else if (ext.toLowerCase() === 'svg') {
      return 'image';
    }
    else if (ext.toLowerCase() === 'webp') {
      return 'image';
    } else if (ext.toLowerCase() === 'pdf') {
      return 'pdf';
    } else {
      return '';
    }
  }

  removeFile(index) {
    const documents = this.documentsControls;
    documents.removeAt(index);
    if (documents.length == 0) {
      this.showUpload = false;
      //this.otherInfoForm.patchValue({ 'upload_documents': 'no' });
      this.otherInfoForm.patchValue({ 'upload_documents': '' });
    }
    toast({ html: 'File deleted!', classes: 'red' });

    this.fileType = '';
    this.base64Image = null;

    setTimeout(() => {
      $('select').formSelect();
      $('.collapsible').collapsible();
      $('.tooltipped').tooltip();
      M.updateTextFields();
    }, 25);
  }

  viewFile(index) {
    const documents = this.documentsControls;
    var doc_link = documents.value[index].link;
    if (doc_link) { window.open(doc_link); }
    else { toast({ html: 'Unable to open the document !', classes: 'red' }); }
  }

  cancelUploadFile() {
    const documents = this.documentsControls;
    if (documents.length == 0) {
      this.showUpload = false;
      this.otherInfoForm.patchValue({ 'upload_documents': 'no' });
    }

    this.fileType = '';
    this.base64Image = null;

    setTimeout(() => {
      $('select').formSelect();
      $('.collapsible').collapsible();
      $('.tooltipped').tooltip();
      M.updateTextFields();
    }, 25);
  }

  closeDocUpload() {
    this.filesUploaded = true;
    this.document.reset();
    this.document_type.reset()

    if (this.documentsControls.length == 0) {
      this.showUpload = false;
      this.otherInfoForm.get('upload_documents').patchValue('no');
    }
    setTimeout(() => {
      $('select').formSelect();
      $('#DocumentsLoadModal').modal('close');
      M.updateTextFields();
    }, 25);
    // this.documentsControls.setValue([]);
  }

  onBack() {
    if (this.step == 1) {
      this.loading = false;
    }
    if (this.step > 1) { this.step -= 1; }

    setTimeout(() => {
      $('select').formSelect();
      $('.collapsible').collapsible();
      $('.tooltipped').tooltip();
      M.updateTextFields();
    }, 25);
    window.scrollTo(0, 0);
  }

  backClicked() {
    this.location.back();
  }

  backClicked2() {
    $('#messageModal').modal('close');
    this.location.back();
  }

  showMessage() {
    setTimeout(() => {
      M.updateTextFields();
      $('.modal').modal();
      $('#messageModal').modal('open');
      $('.tooltipped').tooltip();
    }, 25);
  }
}