import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EmploymentModel } from 'src/app/models/employmentModel';
import { ApiKeyManagerService } from './apiKeyManager.Service';

@Injectable({
  providedIn: 'root'
})
export class EmploymentService {

  constructor(private httpClient: HttpClient, private keyManager: ApiKeyManagerService) { }

  url = environment.firstNation.apiUrl + 'api/Employment/';

  saveEmployemnt(model: EmploymentModel): Observable<any> {
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
    return this.httpClient.post<EmploymentModel>(this.url + 'SaveEmployment/', JSON.stringify(model), { headers });
  }

  editEmployemnt(model: EmploymentModel): Observable<any> {
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
    return this.httpClient.post<EmploymentModel>(this.url + 'EditEmployment/', JSON.stringify(model), { headers });
  }

  deleteEmployemnt(id: number): Observable<any> {
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
    return this.httpClient.get<any>(
      this.url + 'DeleteEmployment/' + id, { headers });
  }

  getEmploymentByID(id: number): Observable<EmploymentModel[]> {
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
    return this.httpClient.get<EmploymentModel[]>( this.url + 'GetEmploymentByID/' + id, { headers });
  }

  getEmploymentListByMemberID(id: number): Observable<EmploymentModel[]> {
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey() });
    return this.httpClient.get<EmploymentModel[]>( this.url + 'GetEmploymentByMemberId/' + id, { headers });
  }

  searchByKeyword(keywords: string[]): Observable<any> {
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
    return this.httpClient.post<any>(this.url + 'searchbykeyword', JSON.stringify(keywords), { headers });
  }



  
  saveWorkExperiences(model: EmploymentModel[]): Observable<any> {
    const headers = new HttpHeaders({ SOCIALAPIKEY: this.keyManager.getKey(), 'Content-Type': 'application/json; charset=utf-8' });
    return this.httpClient.post<EmploymentModel>(this.url + 'SaveWorkHistory/', JSON.stringify(model), { headers });
  }

  //getWorkExperiences


}
