import { Component, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { TempHousingService } from 'src/app/services/firebase/temp-housing.service';
import { environment } from 'src/environments/environment';
import { Location , DatePipe } from '@angular/common';
import { toast } from 'materialize-css';
import { LabelType, Options } from '@angular-slider/ngx-slider';

const pdfMake = require('pdfmake/build/pdfmake.js');
const pdfFonts = require('pdfmake-unicode/dist/pdfmake-unicode.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;
import { DepartmentService } from 'src/app/services/firebase/department.service';
import { FireMembersService } from 'src/app/services/firebase/fire-members.service';
import { EmailService } from 'src/app/services/api/email.service';
import { HousingService } from 'src/app/services/api/housing.service';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-house-renovation',
  templateUrl: './house-renovation.component.html',
  styleUrls: ['./house-renovation.component.css']
})
export class HouseRenovationComponent implements OnInit {

  public dateMask = [/[0-3]/, /[0-9]/, '/', /[0-1]/, /[0-9]/, '/', /[1-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  public dateformat = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;

  status = ['Pending', 'Approved', 'Declined', 'Completed'];
  themeColor = environment.appTheme.themeColor;
  loading = false;

  step1 = true;
  step2 = false;
  renovationForm: UntypedFormGroup;
  itemForm: UntypedFormGroup;
  employeeForm: UntypedFormGroup;
  appealForm: UntypedFormGroup;
  feedbackForm: UntypedFormGroup;
  memberId: any;
  property: any;
  propertyId: any;
  requests: any[];
  addItem = false;
  addEmployee = false;
  showMore: any[] = [];
  index: number;

  scaleOption: Options = {
    floor: 0,
    ceil: 10,
    showTicks: true,
    step: 1,
  }

  constructor(private location: Location,
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private tempHousingService: TempHousingService,
    private departmentService: DepartmentService,
    private fireMembersService: FireMembersService,
    private emailService: EmailService, 
    private housingService: HousingService) { }
    
  ngOnInit() {
    this.loading = true;
    this.memberId = this.authService.getCurrentApplicantID();
    if (this.memberId) {
      this.tempHousingService.getRenovationRequestByMemberID(this.memberId).valueChanges().subscribe(requests => {
        if (requests && requests.length > 0) {
          this.requests = requests;
          for (var i = 0; i < this.requests.length; i++) {
            this.showMore.push(false);
          };
        }

        this.housingService.getPropertyByOwnerID(this.memberId).subscribe(property => {
          if (property && property.length > 0) {
            this.property = property[0];
            this.propertyId = this.property.id;
          }
          this.loading = false;
        });
      });
    }

    this.renovationForm = this.fb.group({
      title: ['', [Validators.required]],
      description: ['', [Validators.required]],
      purpose: [''],
      requested_by: [''],
      approved_by: [''],
      authorized_by: [''],
      renovation_date: ['', [Validators.required, this.isValidDate]],
      renovation_cost: [[Validators.required]],

      renovation_items: this.fb.group({
        add_item: ['', [Validators.required]],
        brand: [''],
        model: [''],
        serial_no: [''],
        quantity: [''],
        items: this.fb.array([]),
      }),

      renovation_employees: this.fb.group({
        add_employee: ['', [Validators.required]],
        employee_name: [''],
        employee_id: [''],
        employees: this.fb.array([]),
      })
    });

    this.feedbackForm = this.fb.group({
      rating: [1, [Validators.required]],
      review: [''],
    });

    this.appealForm = this.fb.group({
      statement: ['', [Validators.required]]
    });

    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
      $('.collapsible').collapsible();
      $('.modal').modal();
      $('.tooltipped').tooltip();
    }, 25);
  }

  get title() { return this.renovationForm.get('title'); }
  get description() { return this.renovationForm.get('description'); }
  get renovation_date() { return this.renovationForm.get('renovation_date'); }
  get renovation_cost() { return this.renovationForm.get('renovation_cost'); }
  get add_item() { return this.renovationForm.get('renovation_items.add_item'); }
  get brand() { return this.renovationForm.get('renovation_items.brand'); }
  get model() { return this.renovationForm.get('renovation_items.model'); }
  get serial_no() { return this.renovationForm.get('renovation_items.serial_no'); }
  get quantity() { return this.renovationForm.get('renovation_items.quantity'); }
  get renovation_items() { return this.renovationForm.get('renovation_items.items') as UntypedFormArray; }
  get add_employee() { return this.renovationForm.get('renovation_employees.add_employee'); }
  get employee_name() { return this.renovationForm.get('renovation_employees.employee_name'); }
  get employee_id() { return this.renovationForm.get('renovation_employees.employee_id'); }

  get renovation_employees() { return this.renovationForm.get('renovation_employees.employees') as UntypedFormArray; }

  onShowMore(index) {
    this.showMore[index] = !this.showMore[index];
  }

  appealDecision(index) {
    this.index = index;
    $('.modal').modal();
    $('#appealModal').modal('open');
  }

  appeal(values) {
    const request = this.requests[this.index];
    const appeal = {
      statement: values.statement,
      date: new Date()
    }
    request.appeal = appeal;

    this.tempHousingService.updateRenovationRequest(request).then(x => {
      toast({ html: 'Appeal Submitted!', classes: 'green' });
      this.closeModal();
    });
    this.closeModal();
  }

  requestRenovation() {
    this.renovationForm.reset();
    this.step1 = false;
    this.step2 = true;
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
      M.textareaAutoResize($('#description'));
    }, 25);
  }

  setTwoNumberDecimal(value: any, control) {
    let amount = parseFloat(value).toFixed(2);
    if (control === 'renovation_cost') {
      this.renovation_cost.patchValue(amount);
      this.renovation_cost.updateValueAndValidity();
    }
  }

  isValidDate(control: UntypedFormControl) {
    let date = control.value;
    const dateformat = /^(0?[1-9]|[12][0-9]|3[01])[\/\-](0?[1-9]|1[012])[\/\-]\d{4}$/;
    if (date) {
      if (!date.match(dateformat) && date.length > 10) {
        const datePipe = new DatePipe('en-US');
        date = datePipe.transform(date, 'dd/MM/yyyy');
      }
      if (date.match(dateformat)) {
        let dateArray = date.split('/');
        let year = dateArray[2];
        let month = dateArray[1] - 1;
        let day = dateArray[0];
        date = new Date(year, month, day);
        const today = new Date();
        if (date.valueOf() < today.valueOf()) {
          return { 'invalidDate': true }
        } else {
          return null;
        }
      }
    }
    return null;
  }

  addItemsYes() {
    this.addItem = true;
    this.brand.reset();
    this.model.reset();
    this.serial_no.reset();
    this.quantity.reset();
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }

  addItemsNo() {
    this.addItem = false;
    const items = this.renovation_items;
    while (items.length !== 0) {
      items.removeAt(0);
    }
  }

  newItem(values) {
    return this.fb.group({
      brand: [values.brand],
      model: [values.model],
      serial_no: [values.serial_no],
      quantity: [values.quantity],
    });
  }

  addNewItem(values) {
    const items = this.renovation_items;
    items.push(this.newItem(values));
    this.addItem = false;
  }

  removeItem(index) {
    const items = this.renovation_items;
    items.removeAt(index);
    if (items.length == 0) {
      this.addItem = false;
      this.add_item.patchValue(false);
    }
  }

  closeAddItem() {
    this.addItem = false;
    if (this.renovation_items.length == 0) {
      this.add_item.patchValue(false);
    }
  }

  addEmployeeYes() {
    this.addEmployee = true;
    this.employee_name.reset();
    this.employee_id.reset();
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
    }, 25);
  }

  addEmployeeNo() {
    this.addEmployee = false;
    const employees = this.renovation_employees;
    while (employees.length !== 0) {
      employees.removeAt(0);
    }
  }

  newEmployee(values) {
    return this.fb.group({
      employee_name: [values.employee_name, [Validators.required]],
      employee_id: [values.employee_id, [Validators.required]],
    });
  }

  addNewEmployee(values) {
    const employees = this.renovation_employees;
    employees.push(this.newEmployee(values));
    this.addEmployee = false;
  }

  removeEmployee(index) {
    const employees = this.renovation_employees;
    employees.removeAt(index);
    if (employees.length == 0) {
      this.addEmployee = false;
      this.add_employee.patchValue(false);
    }
  }

  closeAddEmployee() {
    this.addEmployee = false;
    if (this.renovation_employees.length == 0) {
      this.add_employee.patchValue(false);
    }
  }

  changeDates(dateValue) {
    if (dateValue) {
      if (dateValue.match(this.dateformat)) {
        let dateArray = dateValue.split('/');
        let year = dateArray[2];
        let month = dateArray[1] - 1;
        let day = dateArray[0];
        const date = new Date(year, month, day);
        return date;
      } else {
        const date = new Date(dateValue);
        return date;
      }
    } else {
      return null
    }
  }

  submitRequest(values) {
    let model = {
      memberID: this.memberId,
      houseID: this.propertyId ? this.propertyId : 0,
      houseNumber: this.property ? this.property.house_number : 0,
      title: values.title,
      description: values.description,
      purpose: values.purpose,
      // requestedBy: values.requested_by,
      // approvedBy: values.approved_by,
      // authorizedBy: values.authorized_by,
      renovationDate: this.changeDates(values.renovation_date),
      // renovationCost: values.renovation_cost,
      renovationItems: values.renovation_items.items,
      renovationEmployees: values.renovation_employees.employees,
      status: this.status[0],
      requestDate: new Date(),
    }

    this.tempHousingService.saveRenovationRequest(model).then(x => {
      this.departmentService.getEmployeeInDepartmentWithApp('House Renovation').valueChanges().subscribe((usersIndept: any) => {
        if (usersIndept) {
          const emailOfemployeesToBeNotified = [];
          if (usersIndept.length > 0 ) {
            // get the user
            usersIndept.forEach((app: any) => {
              this.fireMembersService.getMemberByID(app.employeeId).valueChanges().subscribe((user: any) => {
                if (user) {
                  emailOfemployeesToBeNotified.push(user.Email);
                }
              });

            });
            // then notify
            this.emailService.sendFilledApplicationNotification('House Renovation', emailOfemployeesToBeNotified);
          }
        }});

      toast({ html: 'Request has been Sent!', classes: 'green' });
      this.backToPreviousPage();
    });
    this.feedbackForm.reset();
    this.closeModal();
  }

  giveFeedback(index) {
    this.index = index;
  }

  isDate(date) {
    return date instanceof Date
  }

  createRange() {
    var items: number[] = [];
    for (var i = 1; i <= 5; i++) {
      items.push(i);
    }
    return items;
  }

  getScaleOption() {
    let options: Options = {
      floor: 1,
      ceil: 6,
      showTicks: true,
      showTicksValues: true,
      step: 1,
      getTickColor: (value: number): string => {
        if (value < 2) {
          return 'red';
        }
        if (value < 4) {
          return 'yellow';
        }
        return 'green';
      },
      stepsArray: [
        { value: 1, legend: 'Very poor' },
        { value: 2, legend: 'Fair' },
        { value: 3, legend: 'Average' },
        { value: 4, legend: 'Good' },
        { value: 5, legend: 'Excellent' }
      ]
    }
    return options;
  }

  submitReview(values) {
    const request = this.requests[this.index];
    const feedback = {
      rating: values.rating,
      review: values.review
    }
    request.feedback = feedback;

    this.tempHousingService.updateRenovationRequest(request).then(x => {
      toast({ html: 'Review Sucessfully Sent!', classes: 'green' });

      //this.backToPreviousPage();
    });
    this.feedbackForm.reset();
    this.closeModal();
  }

  async getDocumentDefinition(index) {
    const request = this.requests[index];

    return {
      info: {
        title: 'Renovation REQUEST',
        subject: 'Renovation Request',
        keywords: 'Renovation Request',
      },
      pageSize: 'LETTER',
      content: [
        {
          style: 'table',
          layout: 'noBorders', // optional
          table: {
            widths: ['*', '*'],
            body: [
              [
                {
                  columns: [
                    [
                      await this.getLogo(),
                    ],
                    [
                      {
                        stack: [
                          {
                            text: 'MY NATION ',
                            style: 'mainTitle',
                            alignment: 'center',
                          },
                          {
                            text: 'Connecting Your Nation',
                            style: 'normalText',
                            alignment: 'center',
                          },
                          {
                            text: 'Sovereign Data For Sovereign Nations ',
                            fontSize: 8,
                            italics: true,
                            alignment: 'center',
                          },
                          // {
                          //   text: 'Customize the app to your Nation,Tribal Council or Political Organization unique requirements.',
                          //   fontSize: 8,
                          // },
                        ]
                      },
                    ],
                  ]
                },
                {
                  stack: [
                    {},
                    {
                      text: 'RENOVATION REQUESTS',
                      style: 'title'
                    },
                    {},
                  ]
                },
              ],
            ]
          }
        },
        {
          style: 'table',
          table: {
            widths: ['*', '*', '*'],
            body: [
              // ['Column 1', 'Column 2', 'Column 3'],
              [
                {
                  stack: [
                    {
                      text: 'Title: ',
                      style: 'subTitle'
                    },
                    {
                      text: request.title.toUpperCase(),
                      style: 'normalText'
                    },
                  ]
                },

                {
                  stack: [
                    {
                      text: 'Description: ',
                      style: 'subTitle'
                    },
                    {
                      text: request.description,
                      style: 'normalText'
                    },
                  ],
                  colSpan: 2
                },
                {},

                // {
                //   text: [
                //     { text: 'Status\n', italics: true },
                //     { text:  request.status.toUpperCase()}]
                // }
              ],
              [
                {
                  stack: [
                    {
                      text: 'House Number: ',
                      style: 'subTitle'
                    },
                    {
                      text: request.houseNumber,
                      style: 'normalText'
                    },
                  ]
                },

                {
                  stack: [
                    {
                      text: 'Requested On: ',
                      style: 'subTitle'
                    },
                    {
                      text: request.requestDate.toDate().toDateString(),
                      style: 'normalText'
                    },
                  ],
                },
                {
                  stack: [
                    {
                      text: 'Status: ',
                      style: 'subTitle'
                    },
                    {
                      text: request.status.toUpperCase(),
                      style: 'normalText'
                    },
                  ],
                },
              ],
            ]
          }
        },

        //Approved Requests
        request.decision && request.status === this.status[1] ?
          {
            style: 'table',
            table: {
              widths: ['*', '*', '*'],
              body: [
                [
                  {
                    text: 'Request Details: ',
                    style: 'subTitle',
                    colSpan: 3
                  },
                  {},
                  {}
                ],
                [
                  {
                    stack: [
                      {
                        text: 'Case Officer: ',
                        style: 'subTitle'
                      },
                      {
                        text: request.decision.employee,
                        style: 'normalText'
                      },
                    ]
                  },
                  {
                    stack: [
                      {
                        text: 'Start Date: ',
                        style: 'subTitle'
                      },
                      {
                        text: request.decision.startDate.toDate().toDateString(),
                        style: 'normalText'
                      },
                    ],
                  },
                  {
                    stack: [
                      {
                        text: 'End Date: ',
                        style: 'subTitle'
                      },
                      {
                        text: request.decision.endDate.toDate().toDateString(),
                        style: 'normalText'
                      },
                    ],
                  },
                ],
                [
                  {
                    stack: [
                      {
                        text: 'Message: ',
                        style: 'subTitle'
                      },
                      {
                        text: request.decision.message,
                        style: 'normalText'
                      },
                    ],
                    colSpan: 2
                  },
                  {},
                  {
                    stack: [
                      {
                        text: 'Decision Made On: ',
                        style: 'subTitle'
                      },
                      {
                        text: request.decision.date.toDate().toDateString(),
                        style: 'normalText'
                      },
                    ],
                  },
                ],
              ]
            }
          } : '',

        //Declined Requests
        request.decision && request.status === this.status[2] ?
          {
            style: 'table',
            table: {
              widths: ['*', '*', '*'],
              body: [
                [
                  {
                    text: 'Request Details: ',
                    style: 'subTitle',
                    colSpan: 3
                  },
                  {},
                  {}
                ],
                [
                  {
                    stack: [
                      {
                        text: 'Reason: ',
                        style: 'subTitle'
                      },
                      {
                        text: request.decision.reason,
                        style: 'normalText'
                      },
                    ],
                    colSpan: 2
                  },
                  {},
                  {
                    stack: [
                      {
                        text: 'Decision Made On: ',
                        style: 'subTitle'
                      },
                      {
                        text: request.decision.date.toDate().toDateString(),
                        style: 'normalText'
                      },
                    ],
                  },
                ],
              ]
            }
          } : '',

        //Completed Requests
        request.status === this.status[3] && request.decision ?
          {
            style: 'table',
            table: {
              widths: ['*', '*', '*'],
              body: [
                [
                  {
                    text: 'Request Details ',
                    style: 'subTitle',
                    colSpan: 3
                  },
                  {},
                  {}
                ],
                [
                  {
                    stack: [
                      {
                        text: 'Case Officer: ',
                        style: 'subTitle'
                      },
                      {
                        text: request.decision.employee,
                        style: 'normalText'
                      },
                    ]
                  },
                  {
                    stack: [
                      {
                        text: 'Start Date: ',
                        style: 'subTitle'
                      },
                      {
                        text: request.decision.startDate.toDate().toDateString(),
                        style: 'normalText'
                      },
                    ],
                  },
                  {
                    stack: [
                      {
                        text: 'End Date: ',
                        style: 'subTitle'
                      },
                      {
                        text: request.decision.endDate.toDate().toDateString(),
                        style: 'normalText'
                      },
                    ],
                  },
                ],
                [
                  {
                    stack: [
                      {
                        text: 'Message: ',
                        style: 'subTitle'
                      },
                      {
                        text: request.decision.message,
                        style: 'normalText'
                      },
                    ],
                    colSpan: 2
                  },
                  {},
                  {
                    stack: [
                      {
                        text: 'Completed On: ',
                        style: 'subTitle'
                      },
                      {
                        text: request.completedOn.toDate().toDateString(),
                        style: 'normalText'
                      },
                    ],
                  },
                ],
              ]
            }
          } : '',
        request.status === this.status[3] && request.feedback ?
          {
            style: 'table',
            table: {
              widths: ['*', '*', '*'],
              body: [
                [
                  {
                    text: 'Feedback ',
                    style: 'subTitle',
                    colSpan: 3
                  },
                  {},
                  {}
                ],
                [
                  {
                    stack: [
                      {
                        text: 'Rating: ',
                        style: 'subTitle'
                      },
                      {
                        text: request.feedback.rating + " " + "/ 5",
                        style: 'normalText'
                      },
                    ],

                  },
                  {
                    stack: [
                      {
                        text: 'Review: ',
                        style: 'subTitle'
                      },
                      {
                        text: request.feedback.review,
                        style: 'normalText'
                      },
                    ],
                    colSpan: 2
                  },
                  {},
                ],
              ]
            }
          } : '',
      ],
      styles: {
        mainTitle: {
          bold: true,
          fontSize: 16,
          alignment: 'left',
          margin: [0, 0, 0, 20]
        },
        title: {
          bold: true,
          fontSize: 14,
          alignment: 'center',
          margin: [0, 10, 0, 10]
        },
        subTitle: {
          bold: true,
          fontSize: 12,
          alignment: 'left',
          margin: [0, 10, 0, 10]
        },
        normalText: {
          fontSize: 11,
        },
        header: {
          fontSize: 12,
          bold: true,
          alignment: 'center',
        },
        links: {
          color: 'blue',
          decoration: 'underline',
          //italics: true,
          margin: [0, 5, 0, 5]
        },
        table: {
          margin: [0, 5, 0, 5]
        },
      }
    };
  }

  async getLogo() {
    let imageLink = "assets/img/Nation-logo.png";
    const image = await this.getBase64FromUrl(imageLink);
    if (image) {
      return {
        image: image,
        width: 100,
        alignment: 'left'
      }
    }
  }

  getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      }
    });
  }

  async openPdf(index) {
    const documentDefinition = await this.getDocumentDefinition(index);
    pdfMake.createPdf(documentDefinition).open();
  }

  async downloadPdf(index) {
    const documentDefinition = await this.getDocumentDefinition(index);
    pdfMake.createPdf(documentDefinition).download();
  }

  async printPdf(index) {
    const documentDefinition = await this.getDocumentDefinition(index);
    pdfMake.createPdf(documentDefinition).print();
  }

  closeModal() {
    $('.modal').modal('close');
  }

  backToPreviousPage() {
    this.step1 = true;
    this.step2 = false;
  }

  back() {
    this.location.back();
  }
}
