import { Component, OnInit } from '@angular/core';
import { IncomeDeclarationModel } from 'src/app/models/incomeDeclarationModel';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { MemberModel } from 'src/app/models/memberModel';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { IncomeDeclarationService } from 'src/app/services/api/income-declaration.service';
import { MemberService } from 'src/app/services/api/member.service';
import { FamilyCompositionService } from 'src/app/services/api/family-composition.service';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';
import { toast } from 'materialize-css';
import { EmailService } from 'src/app/services/api/email.service';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-new-income-statement',
  templateUrl: './new-income-statement.component.html',
  styleUrls: ['./new-income-statement.component.css']
})
export class NewIncomeStatementComponent implements OnInit {

  province = environment.firstNation.province;
  themeColor = environment.appTheme.themeColor;
  step1 = true;
  step2 = false;
  step3 = false;
  step4 = false;
  applicantID = 0;
  removeVisible = false;
  total = 0;
  incomeDeclarationForm: UntypedFormGroup;
  incomeArray: IncomeDeclarationModel[];
  incomeModel: IncomeDeclarationModel;
  member: MemberModel;
  familyMembers: MemberModel[];
  membersName: any[];
  current_DOI: any;
  months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  previousMonth = '';
  currentMonth = '';

  public amountMask = createNumberMask({
    prefix: '$ ',
    allowDecimal: true,
    integerLimit: 4,
  });

  previousYear = 0;
  currentYear = 0;

  Type: any = ['OAS/GIS/ETC', 'Aunities', 'Sponsership', 'Earned Interest', 'CPP/QPP', 'EI',
    'Pension Income', 'WSIB', 'Victim of Crime', 'Room/Board Income', 'Trusts', 'Mortgage Recivable',
    'Rental Income', 'Child Support', 'Spouse Support', 'Settlement Payments', 'Loans', 'OSAP',
    'Federal Benefits', 'Provincial Benefits', 'Child Care Subsidy', 'Other'];

  constructor(private fb: UntypedFormBuilder, private location: Location,
              private authService: AuthService,
              private incomeDeclarationService: IncomeDeclarationService,
              private memberService: MemberService,
              private familyCompositionService: FamilyCompositionService,
              private emailService: EmailService) { }

  ngOnInit() {
    if (localStorage.getItem('applicantID')) {
      this.applicantID = Number(localStorage.getItem('applicantID'));
    } else {
      this.applicantID = this.authService.getCurrentApplicantID();
    }
    this.setDates();
    this.memberService.getMember(this.applicantID).subscribe(x => {
      if (x) {
        this.member = x[0];
        this.familyCompositionService.getFamilyMember(this.member.current_application).subscribe(data => {
          if (data) {
            this.familyMembers = data;
            this.membersName = [];
            for (const m of data) {
              if (m.DOB) {
                if (this.getAge(m.DOB) >= 18) {
                  const str = m.GivenName + ' ' + m.LastName;
                  this.membersName.push({ id: m.applicantID, name: str });
                }
              }
            }
            setTimeout(() => {
              $('select').formSelect();
            }, 25);
          }
        });
        this.incomeArray = [];
        this.incomeDeclarationForm = this.fb.group({
          // incoms: this.fb.array([this.createItem()])
          catagory: ['', [Validators.required]],
          description: [''],
          value: ['', [Validators.required]],
          member_name: ['', [Validators.required]],
        });
        setTimeout(() => {
          M.updateTextFields();
          M.textareaAutoResize($('#textarea1'));
        }, 25);
      }
    });
  }


  SubmitIncomeForm(model: any) {
    this.incomeModel = new IncomeDeclarationModel();
    this.incomeModel.mainApplicantID = this.member.applicantID;
    this.incomeModel.applicantID = model.member_name;
    this.incomeModel.applicationNo = this.member.current_application;
    this.incomeModel.catagory = model.catagory;
    this.incomeModel.description = model.description;
    this.incomeModel.value = model.value;
    this.incomeModel.DOI_ID = this.current_DOI;
    const id = +model.member_name;
    this.incomeModel.member_name = this.familyMembers.find(x => x.applicantID === id).GivenName + ' '
      + this.familyMembers.find(x => x.applicantID === id).LastName;
    this.incomeArray.push(this.incomeModel);
    this.calculateTotal();
    this.step1 = false;
    this.step2 = true;
    setTimeout(() => {
      $('.collapsible').collapsible();
    }, 25);
  }

  AddIncome() {
    this.incomeDeclarationForm = this.fb.group({
      // incoms: this.fb.array([this.createItem()])
      catagory: ['', [Validators.required]],
      description: [''],
      value: ['', [Validators.required, Validators.pattern('^\-?[0-9]+(?:\.[0-9]{1,2})?$')]],
      member_name: ['', [Validators.required]],
    });

    setTimeout(() => {

      $('select').formSelect();
      M.updateTextFields();
    }, 25);

    this.step1 = true;
    this.step2 = false;
  }

  legalPage() {
    this.step2 = false;
    this.step3 = true;
  }
  backToIncome() {
    this.step2 = true;
    this.step3 = false;
  }

  submitStatement() {
    this.incomeDeclarationService.saveIncomDeclaration(this.incomeArray).subscribe(x => {
      // tslint:disable-next-line: max-line-length
      this.emailService.sendIncomeDeclarationNotification(this.member.GivenName + ' ' + this.member.LastName, this.previousMonth + '-' + this.currentMonth);
      toast({ html: 'Income Statement Successfully Submited!', classes: 'green' });
      this.step3 = false;
      this.step4 = true;
    });
  }

  calculateTotal() {
      this.total = 0;
      this.incomeArray.forEach(x => {
        this.total += Number(x.value);
      });
  }

  backClicked() {
    this.location.back();
  }


 getAge(birthDateString: Date) {
  const today = new Date();
  const birthDate = new Date(birthDateString);
  let age = today.getFullYear() - birthDate.getFullYear();
  const m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age --;
  }
  return age;
}

  setDates() {
    this.currentYear = new Date().getFullYear();
    this.currentMonth = this.months[new Date().getMonth()];
    if (this.currentMonth === 'January') {
      this.previousMonth = this.months[11];
      this.previousYear = new Date().getFullYear() - 1;
    } else {
      this.previousMonth = this.months[new Date().getMonth() - 1];
      this.previousYear = new Date().getFullYear();
    }
  }

  get catagory() { return this.incomeDeclarationForm.get('catagory'); }
  get value() { return this.incomeDeclarationForm.get('value'); }
  get type() { return this.incomeDeclarationForm.get('type'); }
  get member_name() { return this.incomeDeclarationForm.get('member_name'); }
  get dateReceived() { return this.incomeDeclarationForm.get('dateReceived'); }
}