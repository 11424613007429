import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
// import { EmbedVideoService } from 'ngx-embed-video';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { SectionSettingService } from 'src/app/services/firebase/section-setting.service';
import { environment } from 'src/environments/environment';
import { toast } from 'materialize-css';

declare var $: any;

@Component({
  selector: 'app-section-detail',
  templateUrl: './section-detail.component.html',
  styleUrls: ['./section-detail.component.css']
})
export class SectionDetailComponent implements OnInit {

  defaultThemeColor = environment.appTheme.themeColor;
  themeColor: any;
  iframehtml: any;
  id: any;
  model: any;
  comments: any[];
  newCommentText: any;
  videoId: any;

  constructor(private route: ActivatedRoute,
    public appSettingsService: AppSettingsService,
    private sectionSetting: SectionSettingService,
    // private embedService: EmbedVideoService
  ) {
    this.themeColor = this.appSettingsService.getAppThemeColor() ? this.appSettingsService.getAppThemeColor() : this.defaultThemeColor;
  }

  ngOnInit(): void {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });

    if (this.id) {
      this.sectionSetting.getContenById(this.id).valueChanges().subscribe(con => {
        if (con) {
          this.model = con;
          if (this.model.allowComment && this.model.comments?.length > 0) {
            this.comments = this.model.comments;
          } else {
            this.comments = [];
          }

          if (this.model.mediaType && this.model.mediaType == 'Youtube') {
            this.videoId = this.embedVideo(this.model.mediaLink);
          }
        }
      });
    }
  }

  embedVideo(videoLink) {
    const videoIdMatch = videoLink.match(/(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([^&]+)|(?:https?:\/\/)?(?:www\.)?youtu\.be\/([^?]+)/);
    this.videoId = videoIdMatch ? (videoIdMatch[1] || videoIdMatch[2]) : '';
    return this.videoId;
  }

  addCommentClicked() {
    this.newCommentText = '';
    $('.modal').modal();
    $('#commentModal').modal('open');
  }

  addComment() {
    if (this.newCommentText.trim() !== '') {
      const newComment: any = {
        comment: this.newCommentText,
        timeStamp: new Date(),
      };

      this.comments.push(newComment);

      this.newCommentText = '';
      this.sectionSetting.updateComments(this.id, this.comments).then(res => {
        toast({ html: 'Comment Added!', classes: 'green' });
        $('#commentModal').modal('close');
      });
    }
  }
}
