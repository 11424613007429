import { AuthService } from './../../../services/firebase/auth.service';
import { ElectionService } from './../../../services/firebase/election.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { VotingService } from 'src/app/services/firebase/voting.service';
import { FireMembersService } from 'src/app/services/firebase/fire-members.service';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';

declare var $: any;

@Component({
  selector: 'app-elections-result',
  templateUrl: './elections-result.component.html',
  styleUrls: ['./elections-result.component.css']
})

export class ElectionsResultComponent implements OnInit {

  electionId = '';
  electionDetail: any;
  resultMessage = '';
  votes = [];
  candidateData = [];
  winnersObject = [];
  winningVote = 1;
  showAllVote: any;
  role: any;
  showMyVotes: any;
  myVotesObj = [];
  userId: any;
  totalVote = 0;
  showWinner = false;
  themeColor = environment.appTheme.themeColor;

  constructor(private route: ActivatedRoute,
    private votingService: VotingService,
    private electionService: ElectionService,
    private fireMembersService: FireMembersService,
    private authService: AuthService,
    private location: Location) { }
  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.electionId = params.get('id');
    });

    this.role = this.authService.getGlobalUser().role; // get logged in user id
    this.userId = this.authService.getGlobalUser().uid;

    if (this.electionId) {
      this.electionService.getElectionById(this.electionId).valueChanges().subscribe(res => {
        if (res) {
          if (res.length !== 0 && ((res.showVotingResult || this.role.toLowerCase() === 'admin')
            || (res.candidates.includes(this.userId) && res.showAllCandidatesVotes))
          ) {

            this.showMyVotes = false;
            this.electionDetail = res;
            if (this.electionDetail.candidates.length !== 0) {
              this.electionDetail.candidates.forEach((candidate) => {
                this.fireMembersService.getMemberByID(candidate).valueChanges().subscribe(candidateData => {
                  // get vote by election id and candidate id
                  if (candidateData) {
                    this.votingService.getVotes(this.electionId, candidate).valueChanges().subscribe(vote => {
                      // compare vote
                      this.totalVote += vote.length;
                      if (vote.length > this.winningVote) {
                        this.winnersObject = [{ candidateData, vote }];
                        this.winningVote = vote.length;
                      } else if (vote.length === this.winningVote) {
                        this.winnersObject.push({ candidateData, vote });
                      } else {
                        // pass
                      }
                      this.candidateData.push({ candidateData, vote });
                    });
                  }
                });
              });
            } else {
              this.resultMessage = 'No candidates were chosen';
            }
          } else if (res.length !== 0 && res.showAllCandidatesVotes) {
          } else if (res.length !== 0 && !res.showVotingResult) {
            this.showMyVotes = true;
            this.votingService.getVotes(this.electionId, this.userId).valueChanges().subscribe(vote => {
              if (vote) {
                this.myVotesObj.push(vote);
              }
            });
          }
        }
      });
    }
  }

  displayWinner() {
    this.showWinner = true;
  }
  backClicked() {
    this.location.back();
  }
}
