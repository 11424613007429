<app-firebase-loading *ngIf="loading"></app-firebase-loading>

<div id="smsModal" class="modal b-n-1">
    <div class="modal-content" *ngIf="msgSignature">
        <div class="row col s12">
            <h4 class="col s11">Send Text Message</h4>
            <i class="material-icons right modal-close col s1 red-text">close</i>
        </div>

        <form [formGroup]="SMSForm" (ngSubmit)="send(SMSForm.value)">
            <div class="row">
                <p *ngIf="!adminInfo" class="col s12 red-text">The Admin Contact Information is not available and will
                    not be included in the message.
                </p>

                <p class="col s12 bold-700">To: <span *ngFor="let no of phoneNoList">{{no}} </span></p>
                <p class="col s12">{{msgSignature.greeting}} <span *ngIf=user.firstName>{{user.firstName}}</span></p>
                <!-- <div class="input-field col s12 l12">
                    <div class="label-input">Subject</div>
                    <input id="subject" type="text" formControlName='subject' class="validate" autocomplete="off"
                        placeholder="subject" />
                    <div *ngIf="(subject.dirty || subject.touched) && subject.invalid">
                        <small *ngIf="subject.errors.required" class="red-text"> The Subject is required. </small>
                    </div>
                </div> -->

                <div class="input-field col s12 l12">
                    <div class="label-input">Message</div>
                    <textarea id="body" type="text" formControlName='body' required autocomplete="off"
                        class="validate materialize-textarea" style="height: 120px;" maxlength="500"
                        placeholder="Message here ...."> </textarea>
                    <div *ngIf="(body.dirty || body.touched) && body.invalid">
                        <small *ngIf="body.errors.required" class="red-text"> The Message is required.
                        </small>
                    </div>
                </div>

                <p>{{msgSignature.closing}}</p>
                <p *ngIf="!adminInfo && msgSignature.signName">{{fbAdmin.FirstName}} {{fbAdmin.LastName}} </p>
                <div *ngIf="adminInfo">
                    <p *ngIf="msgSignature.signName">{{adminInfo.FirstName}} {{adminInfo.LastName}} </p>
                    <p *ngIf="adminInfo.show_title && adminInfo.Title">{{adminInfo.Title}}</p>
                    <p class="bold-400">
                        <span *ngIf="adminInfo.show_email && adminInfo.Email_Address"><span class="bold-600">Email:
                            </span>{{adminInfo.Email_Address}} </span>
                        <span *ngIf="adminInfo.show_office && adminInfo.phone"><span class="bold-600">Office:
                            </span>{{adminInfo.phone}} </span>
                        <span *ngIf="adminInfo.show_cell && adminInfo.Cell"><span class="bold-600">Cell:
                            </span>{{adminInfo.Cell}} </span>
                    </p>
                    <div class="bold-400" *ngIf="socialMedias">
                        <span *ngIf="msgSignature.facebook && socialMedias.facebook"><a
                                [href]="socialMedias.facebook">Facebook</a></span>
                        <span *ngIf="msgSignature.x && socialMedias.x"><a [href]="socialMedias.x">X</a></span>
                        <span *ngIf="msgSignature.instagram && socialMedias.instagram"><a
                                [href]="socialMedias.instagram"> | Instagram</a></span>
                        <span *ngIf="msgSignature.tiktok && socialMedias.tiktok"><a [href]="socialMedias.tiktok"> |
                                Tiktok</a></span>
                        <span *ngIf="msgSignature.youtube && socialMedias.youtube"><a [href]="socialMedias.youtube"> |
                                Youtube</a></span>
                        <span *ngIf="msgSignature.linkedin && socialMedias.linkedin"><a [href]="socialMedias.linkedin">
                                | Linkedin</a></span>
                    </div>
                </div>
            </div>

            <div class="row">

                <div class="input-field col s12">
                    <a class='left blue-text modal-close' style="cursor: pointer;"
                        [routerLink]="['/admin/dashboard-sub-menu/settings/app-settings/']">
                        Customize SMS
                    </a>
                    <button type="submit" [disabled]="!SMSForm.valid" class='btn right waves-effect green'>
                        Send SMS
                    </button>
                </div>
            </div>
        </form>
    </div>
</div>