<div class="row container-pi">
  <div *ngIf="step1">
    <div class="card-panel white col s12 b-n-1">
      <h6 class="bold-400" style="margin-bottom: 15px; margin-top: 15px;"><strong>Contact Options</strong></h6>
      <div class="row ">
        <div class="input-field col s12">
          <!-- <i class="material-icons prefix purple-text">contact_mail</i> -->
          <select (change)="contactOptionChange($event.target.value)" class="browser-default">
            <option value="" disabled selected>Please Select</option>
            <option value="Department">Departments</option>
            <option value="Councillor">Council</option>
            <option value="Chief">Chief</option>
          </select>
        </div>
      </div>

      <div class="row" *ngIf="departmentsList && showDepList">
        <div class="input-field col s12">
          <i class="material-icons prefix pink-text">account_tree</i>
          <select (change)="departmentOptionChange($event.target.value)">
            <option value="" disabled selected>Please Select A Department</option>
            <option *ngFor="let item of departmentsList" [value]="item.id"><span [innerHTML]="item.name"></span>
            </option>
          </select>
        </div>
      </div>
    </div>

    <div class="row col s12 card-panel blue darken-2" *ngIf="showMsg">
      <p class="white-text">
        <strong>
          {{selectedDepName}} did not update their contact list please try
          again later </strong>
      </p>
    </div>

    <div *ngIf="employeeList && showDepList" class="row">
      <div class="col s12 m6 " *ngFor="let emp of employeeList">
        <div class="row card-panel white col s12">
          <div class="row col s12">
            <div class="section"></div>
            <ul class="collection">
              <li class="collection-item">
                <div>
                  <span> <strong class="teal-text"> First Name : </strong></span>
                  <span class="right black-text"> <strong> {{emp.givenName}} </strong></span>
                </div>
              </li>
              <li class="collection-item">
                <div>
                  <span> <strong class="teal-text"> Last Name : </strong></span>
                  <span class="right black-text"> <strong> {{emp.lastName}} </strong></span>
                </div>
              </li>
              <li class="collection-item">
                <div>
                  <span> <strong class="teal-text"> Title : </strong></span>
                  <span class="right blue-text"> <strong> {{emp.jobTitle}} </strong></span>
                </div>
              </li>
            </ul>
          </div>
          <div class="row" *ngIf="contactType === 'MemberMessage'">
            <a (click)="showEmailModal(emp)" class="center col s4" *ngIf="emp.showEmailAdr !== 'Private'"> <span
                style="cursor: pointer;"><i class="material-icons blue-text">mail</i> <strong
                  class="black-text">Email</strong></span> </a>

            <a (click)="showWorkPhone(emp)" class="center col s4" *ngIf="emp.showOfficeNo !== 'Private'"><span
                style="cursor: pointer;"><i class="center material-icons teal-text">call</i><strong
                  class="center black-text">Work</strong> </span></a>

            <a (click)="showMobile(emp)" class="center col s4" *ngIf="emp.showMobileNo !== 'Private'"><span
                style="cursor: pointer;"> <i class="material-icons orange-text">stay_current_portrait</i> <strong
                  class="black-text">Mobile</strong></span> </a>
          </div>

          <div class="row" *ngIf="contactType === 'PublicMessage'">
            <a (click)="showEmailModal(emp)" class="center col s4" *ngIf="emp.showEmailAdr === 'Public'"> <span
                style="cursor: pointer;"><i class="material-icons blue-text">mail</i> <strong
                  class="black-text">Email</strong></span> </a>

            <a (click)="showWorkPhone(emp)" class="center col s4" *ngIf="emp.showOfficeNo === 'Public'"><span
                style="cursor: pointer;"><i class="center material-icons teal-text">call</i><strong
                  class="center black-text">Work</strong> </span></a>

            <a (click)="showMobile(emp)" class="center col s4" *ngIf="emp.showMobileNo === 'Public'"><span
                style="cursor: pointer;"> <i class="material-icons orange-text">stay_current_portrait</i> <strong
                  class="black-text">Mobile</strong></span> </a>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="contactType === 'PublicMessage' && selectedDepartment">
      <ul class="row col s12 collection">
        <li class="collection-item">
          <div>Phone <a href="tel:{{formatPhoneNumber(selectedDepartment[0].phone)}}"
              class="secondary-content"><u>{{selectedDepartment[0].phone}}</u> </a></div>
        </li>
        <li class="collection-item">
          <div>Fax <a class="secondary-content">{{selectedDepartment[0].fax}}</a></div>
        </li>
        <li class="collection-item">
          <div>Email <a class="secondary-content">{{selectedDepartment[0].email}}</a></div>
        </li>
      </ul>

      <ul class="row col s12 collection">
        <li class="collection-item">
          <div>Address <a class="secondary-content">{{selectedDepartment[0].address}}</a></div>
        </li>
        <li class="collection-item">
          <div>Postal Code <a class="secondary-content">{{selectedDepartment[0].postal_code}}</a></div>
        </li>
      </ul>
    </div>

    <div class="card-panel white col s12 b-n-1" *ngIf="selectableCouncillorsList && showCouncillorsList">
      <h6 class="bold-400" style="margin-bottom: 15px; margin-top: 15px;"><strong>Contact List</strong></h6>
      <div class="row">
        <div *ngFor="let c of selectableCouncillorsList">
          <div class="white col s12 l6 card-panel-left ">
            <div class="row col s12 ">
              <div class="section"></div>
              <ul class="collection">
                <li class="collection-item">
                  <div>
                    <span> <strong class="teal-text"> First Name : </strong></span>
                    <span class="right black-text"> <strong> {{c.firstName}} </strong></span>
                  </div>
                </li>
                <li class="collection-item">
                  <div>
                    <span> <strong class="teal-text"> Last Name : </strong></span>
                    <span class="right black-text"> <strong> {{c.lastName}} </strong></span>
                  </div>
                </li>
                <li class="collection-item">
                  <div>
                    <span> <strong class="teal-text"> Title : </strong></span>
                    <span class="right blue-text"> <strong> Councillor </strong></span>
                  </div>
                </li>
              </ul>
            </div>

            <div *ngIf="contactType === 'MemberMessage'">
              <div class="row calls-media">
                <div class="col s1"></div>
                <a (click)="showEmailModal(c)" class="center col s3" *ngIf="c.showEmail !== 'Private'"> <span
                    style="cursor: pointer;"><i class="material-icons blue-text">mail</i> <strong
                      class="">Email</strong></span> </a>

                <a (click)="showWorkPhone(c)" class="center col s3" *ngIf="c.showOfficePhone !== 'Private'"><span
                    style="cursor: pointer;"><i class="center material-icons teal-text">call</i><strong
                      class="center ">Work</strong> </span></a>

                <a (click)="showMobile(c)" class="center col s3" *ngIf="c.showMobile !== 'Private'"><span
                    style="cursor: pointer;"> <i class="material-icons orange-text">stay_current_portrait</i> <strong
                      class="">Mobile</strong></span> </a>
              </div>

              <div class="row social-media">
                <div class="col s1"></div>
                <a href="{{c.councillorFacebookAcc}}" target="_blank" class="center col s2"
                  *ngIf="(c.showFacebookAcc !== 'Private') && c.councillorFacebookAcc"><span style="cursor: pointer;">
                    <i class="fa fa-facebook prefix circle" style="color: #3b5998; font-size:25px;"></i></span> </a>
                <a href="{{c.councillorInstagramAcc}}" target="_blank" class="center col s2"
                  *ngIf="(c.showInstagramAcc !== 'Private' ) && c.councillorInstagramAcc"><span
                    style="cursor: pointer;"> <i class="fa fa-instagram prefix circle"
                      style="color: #8a3ab9; font-size:25px;"></i> </span></a>
                <a href="{{c.councillorTwitterAcc}}" target="_blank" class="center col s2"
                  *ngIf="(c.showTwitterAcc !== 'Private' ) && c.councillorTwitterAcc"><span style="cursor: pointer;"> <i
                      class="fa fa-twitter prefix circle" style="color: #1DA1F2; font-size:25px;"></i></span> </a>
                <a href="{{c.councillorLinkedinAcc}}" target="_blank" class="center col s2"
                  *ngIf="(c.showLinkedinAcc !== 'Private') && c.councillorLinkedinAcc"><span style="cursor: pointer;">
                    <i class="fa fa-linkedin prefix circle" style="color: #0077b5; font-size:25px;"></i></span> </a>
                <a href="{{c.councillorYoutubeAcc}}" target="_blank" class="center col s2"
                  *ngIf="(c.showYoutubeAcc !== 'Private') && c.councillorYoutubeAcc"><span style="cursor: pointer;"> <i
                      class="fa fa-youtube prefix red-text circle " style="font-size:25px;"></i></span> </a>
                <div class="col s1"></div>
              </div>

              <div *ngIf="contactType === 'PublicMessage'">
                <div class="row">
                  <a (click)="showEmailModal(c)" class="center col s4" *ngIf="c.showEmail === 'Public'"> <span
                      style="cursor: pointer;"><i class="material-icons blue-text">mail</i> <strong
                        class="black-text">Email</strong></span> </a>

                  <a (click)="showWorkPhone(c)" class="center col s4" *ngIf="c.showOfficePhone === 'Public'"><span
                      style="cursor: pointer;"><i class="center material-icons teal-text">call</i><strong
                        class="center black-text">Work</strong> </span></a>

                  <a (click)="showMobile(c)" class="center col s4" *ngIf="c.showMobile === 'Public'"><span
                      style="cursor: pointer;"> <i class="material-icons orange-text">stay_current_portrait</i> <strong
                        class="black-text">Mobile</strong></span> </a>
                </div>
                <div class="row card-panel">
                  <div class="col s1"></div>
                  <a href="{{c.councillorFacebookAcc}}" target="_blank" class="center col s2"
                    *ngIf="(c.showFacebookAcc === 'Public') && c.councillorFacebookAcc"><span style="cursor: pointer;">
                      <i class="fa fa-facebook prefix circle" style="color: #3b5998; font-size:25px;"></i></span> </a>
                  <a href="{{c.councillorInstagramAcc}}" target="_blank" class="center col s2"
                    *ngIf="(c.showInstagramAcc === 'Public' ) && c.councillorInstagramAcc"><span
                      style="cursor: pointer;">
                      <i class="fa fa-instagram prefix circle" style="color: #8a3ab9; font-size:25px;"></i> </span></a>
                  <a href="{{c.councillorTwitterAcc}}" target="_blank" class="center col s2"
                    *ngIf="(c.showTwitterAcc === 'Public' ) && c.councillorTwitterAcc"><span style="cursor: pointer;">
                      <i class="fa fa-twitter prefix circle" style="color: #1DA1F2; font-size:25px;"></i></span> </a>
                  <a href="{{c.councillorLinkedinAcc}}" target="_blank" class="center col s2"
                    *ngIf="(c.showLinkedinAcc === 'Public') && c.councillorLinkedinAcc"><span style="cursor: pointer;">
                      <i class="fa fa-linkedin prefix circle" style="color: #0077b5; font-size:25px;"></i></span> </a>
                  <a href="{{c.councillorYoutubeAcc}}" target="_blank" class="center col s2"
                    *ngIf="(c.showYoutubeAcc === 'Public') && c.councillorYoutubeAcc"><span style="cursor: pointer;"> <i
                        class="fa fa-youtube prefix red-text circle" style="font-size:25px;"></i></span> </a>
                  <div class="col s1"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card-panel white col s12 b-n-1" *ngIf="chiefsList && showChiefsList">
        <div class="row ">
          <div *ngFor="let chief of chiefsList">
            <div class="row white col s12 l6 card-panel-left">
              <div class="row col s12">
                <div class="section"></div>
                <ul class="collection">
                  <li class="collection-item">
                    <div>
                      <span> <strong class="teal-text"> First Name : </strong></span>
                      <span class="right black-text"> <strong> {{chief.firstName}} </strong></span>
                    </div>
                  </li>
                  <li class="collection-item">
                    <div>
                      <span> <strong class="teal-text"> Last Name : </strong></span>
                      <span class="right black-text"> <strong> {{chief.lastName}} </strong></span>
                    </div>
                  </li>
                  <li class="collection-item">
                    <div>
                      <span> <strong class="teal-text"> Title : </strong></span>
                      <span class="right blue-text"> <strong> Chief </strong></span>
                    </div>
                  </li>
                </ul>
              </div>
              <div *ngIf="contactType === 'MemberMessage'">
                <div class="row">
                  <a (click)="showEmailModal(chief)" class="center col s4" *ngIf="chief.showEmail !== 'Private'"> <span
                      style="cursor: pointer;"><i class="material-icons blue-text">mail</i>
                      <strong class="black-text">Email</strong>
                    </span> </a>

                  <a (click)="showWorkPhone(chief)" class="center col s4"
                    *ngIf="chief.showOfficePhone !== 'Private'"><span style="cursor: pointer;"><i
                        class="center material-icons teal-text">call</i>
                      <strong class="center black-text">Work</strong>
                    </span></a>

                  <a (click)="showMobile(chief)" class="center col s4" *ngIf="chief.showMobile !== 'Private'"><span
                      style="cursor: pointer;"> <i class="material-icons orange-text">stay_current_portrait</i>
                      <strong class="black-text">Mobile</strong>
                    </span> </a>
                </div>
                <div class="row social-media">
                  <a href="{{chief.chiefFacebookAcc}}" target="_blank" class="center col s2"
                    *ngIf="(chief.showFacebookAcc !== 'Private') && chief.chiefFacebookAcc"><span
                      style="cursor: pointer;"> <i class="fa fa-facebook prefix circle "
                        style="color: #3b5998; font-size:25px;"></i></span> </a>
                  <a href="{{chief.chiefInstagramAcc}}" target="_blank" class="center col s2"
                    *ngIf="(chief.showInstagramAcc !== 'Private') && chief.chiefInstagramAcc"><span
                      style="cursor: pointer;"> <i class="fa fa-instagram prefix circle"
                        style="color: #8a3ab9; font-size:25px;"></i> </span></a>
                  <a href="{{chief.chiefTwitterAcc}}" target="_blank" class="center col s2"
                    *ngIf="(chief.showTwitterAcc !== 'Private') && chief.chiefTwitterAcc"><span
                      style="cursor: pointer;">
                      <i class="fa fa-twitter prefix circle" style="color: #1DA1F2; font-size:25px;"></i></span> </a>
                  <a href="{{chief.chiefLinkedinAcc}}" target="_blank" class="center col s2"
                    *ngIf="(chief.showLinkedinAcc !== 'Private') && chief.chiefLinkedinAcc"><span
                      style="cursor: pointer;"> <i class="fa fa-linkedin prefix circle"
                        style="color: #0077b5; font-size:25px;"></i></span> </a>
                  <a href="{{chief.chiefYoutubeAcc}}" target="_blank" class="center col s2"
                    *ngIf="(chief.showYoutubeAcc !== 'Private') && chief.chiefYoutubeAcc"><span
                      style="cursor: pointer;">
                      <i class="fa fa-youtube prefix red-text circle" style="font-size:25px;"></i></span> </a>
                </div>

                <div *ngIf="contactType === 'PublicMessage'">
                  <div class="row">
                    <a (click)="showEmailModal(chief)" class="center col s4" *ngIf="chief.showEmail === 'Public'"> <span
                        style="cursor: pointer;"><i class="material-icons blue-text">mail</i> <strong
                          class="black-text">Email</strong></span> </a>

                    <a (click)="showWorkPhone(chief)" class="center col s4"
                      *ngIf="chief.showOfficePhone === 'Public'"><span style="cursor: pointer;"><i
                          class="center material-icons teal-text">call</i><strong
                          class="center black-text">Work</strong> </span></a>

                    <a (click)="showMobile(chief)" class="center col s4" *ngIf="chief.showMobile === 'Public'"><span
                        style="cursor: pointer;"> <i class="material-icons orange-text">stay_current_portrait</i>
                        <strong class="black-text">Mobile</strong></span> </a>
                  </div>

                  <div class="row social-media">
                    <a href="{{chief.chiefFacebookAcc}}" target="_blank" class="center col s4"
                      *ngIf="(chief.showFacebookAcc === 'Public') && chief.chiefFacebookAcc"><span
                        style="cursor: pointer;">
                        <i class="fa fa-facebook prefix " style="color: #3b5998;"></i></span> </a>
                    <a href="{{chief.chiefInstagramAcc}}" target="_blank" class="center col s4"
                      *ngIf="(chief.showInstagramAcc === 'Public') && chief.chiefInstagramAcc"><span
                        style="cursor: pointer;"> <i class="fa fa-instagram prefix " style="color: #8a3ab9;"></i></span>
                    </a>
                    <a href="{{chief.chiefTwitterAcc}}" target="_blank" class="center col s4"
                      *ngIf="(chief.showTwitterAcc === 'Public')  && chief.chiefTwitterAcc"><span
                        style="cursor: pointer;">
                        <i class="fa fa-twitter prefix" style="color: #1DA1F2;"></i></span> </a>
                    <a href="{{chief.chiefLinkedinAcc}}" target="_blank" class="center col s4"
                      *ngIf="(chief.showLinkedinAcc === 'Public') && chief.chiefLinkedinAcc"><span
                        style="cursor: pointer;">
                        <i class="fa fa-linkedin prefix " style="color: #0077b5;"></i></span> </a>
                    <a href="{{chief.chiefYoutubeAcc}}" target="_blank" class="center col s4"
                      *ngIf="(chief.showYoutubeAcc === 'Public') && chief.chiefYoutubeAcc"><span
                        style="cursor: pointer;">
                        <i class="fa fa-youtube prefix red-text"></i></span> </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div id="workPhoneModal" *ngIf="selectedEmployee" class=" modal valign-wrapper">
      <div class="modal-content">
        <div class="row col s12 center">
          <h5><strong> Work Phone </strong></h5>
          <a href="tel:{{formatedWorkPhone}}">
            <h5><strong>{{selectedEmployee.workPhone}}</strong> </h5>
          </a>
        </div>
      </div>
      <div class="modal-footer">
        <a class="modal-close  btn red">Close</a>
      </div>
    </div>

    <div id="mobilePhoneModal" *ngIf="selectedEmployee" class=" modal valign-wrapper">
      <div class="modal-content">
        <div class="row col s12 center">
          <h5><strong> Mobile Phone </strong></h5>
          <a href="tel:{{formatedMobilePhone}}"></a>
          <h5><strong>{{selectedEmployee.mobilePhone}}</strong> </h5>
        </div>
      </div>
      <div class="modal-footer">
        <a class="modal-close btn red">Close</a>
      </div>
    </div>

    <div id="emailModal" *ngIf="selectedEmployee" class=" modal ">
      <form *ngIf="showContactForm" [formGroup]="contactForm" (ngSubmit)="Send(contactForm.value)">
        <div class="modal-content">
          <div class="row col s12 center">
            <h6><strong> {{modalHeader}} </strong></h6>

            <div class="row">
              <div class="input-field col s12 l12">
                <i class="material-icons prefix pink-text">mail</i>
                <input id="subject" type="text" formControlName='subject' required class="validate"
                  autocomplete="off" />
                <label for="subject">Subject</label>
                <div *ngIf="(subject.dirty || subject.touched) && subject.invalid">
                  <small *ngIf="subject.errors.required" class="red-text"> The Subject is required. </small>
                </div>
              </div>

              <div class="input-field col s12 l12">
                <i class="material-icons prefix purple-text">subject</i>
                <textarea id="body" type="text" formControlName='body' required autocomplete="off"
                  class="validate materialize-textarea" style="height: 200px;" maxlength="1000"> </textarea>

                <label for="body">Body</label>
                <div *ngIf="(body.dirty || body.touched) && body.invalid">
                  <small *ngIf="body.errors.required" class="red-text"> The Body Is Required.
                  </small>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <a class="modal-close waves-effect waves-green btn red">
            <i class="material-icons right">delete</i>
            Close</a>
          <button type="submit" [disabled]="!contactForm.valid" style="margin-left: 5px;" class="btn green">
            <i class="material-icons right">send</i>
            Send</button>
        </div>
      </form>
    </div>