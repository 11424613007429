import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
// import { EmbedVideoService } from 'ngx-embed-video';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { SectionSettingService } from 'src/app/services/firebase/section-setting.service';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';

@Component({
  selector: 'app-custom-sections',
  templateUrl: './custom-sections.component.html',
  styleUrls: ['./custom-sections.component.css']
})
export class CustomSectionsComponent implements OnInit {

  nationOfuse = environment.firstNation.name;
  nationName = environment.firstNation.displayName;
  defaultThemeColor = environment.appTheme.themeColor;
  themeColor = environment.appTheme.themeColor;
  showLoader = false;
  sectionId: any;
  section: any;
  model: any[];
  iframehtml: any;
  videoId: any;
  sectionName: any;

  constructor(private appSettingService: AppSettingsService,
    private sectionService: SectionSettingService,
    private route: ActivatedRoute,
    private location: Location,
    // private embedService: EmbedVideoService
  ) { }

  ngOnInit(): void {
    this.showLoader = true;
    this.route.paramMap.subscribe(params => {
      if (params.get('id')) {
        this.sectionId = params.get('id');
        this.sectionService.getSectionById(this.sectionId).valueChanges().subscribe(sec => {
          if (sec) {
            this.section = sec;
            this.sectionService.getSectionContent(this.sectionId).valueChanges().subscribe(content => {
              if (content && content.length > 0) {
                this.model = content;

                //this.model = this.model.filter(c =>  c.share == 'Public');
                this.model.sort((a, b) => b.datePublished - a.datePublished);
              }
            });
          }
        });
      }
    });

    this.showLoader = false;
  }

  
  truncateHTML(text: string, limit: string): string {
    const charlimit = +limit;
    if (!text || text.length <= charlimit) {
      return text.replace(/<(?:.|\n)*?>/gm, '').replace(/&nbsp;/gm, ' ');
    }
    return text.replace(/<(?:.|\n)*?>/gm, '').replace(/&nbsp;/gm, ' ').substring(0, charlimit) + '...';
  }

  navigateTo(id) {
  }

  embedVideo(videoLink) {
    const videoIdMatch = videoLink.match(/(?:https?:\/\/)?(?:www\.)?youtube\.com\/watch\?v=([^&]+)|(?:https?:\/\/)?(?:www\.)?youtu\.be\/([^?]+)/);
    this.videoId = videoIdMatch ? (videoIdMatch[1] || videoIdMatch[2]) : '';
    return this.videoId;
  }

  backClicked() {
    this.location.back();
  }

}
