import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TempHousingService } from 'src/app/services/firebase/temp-housing.service';
import { DepartmentService } from 'src/app/services/firebase/department.service';
import { FireMembersService } from 'src/app/services/firebase/fire-members.service';
import { EmailService } from 'src/app/services/api/email.service';
import { toast } from 'materialize-css';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { LabelType, Options } from '@angular-slider/ngx-slider';
import { HousingService } from 'src/app/services/api/housing.service';

const pdfMake = require('pdfmake/build/pdfmake.js');
const pdfFonts = require('pdfmake-unicode/dist/pdfmake-unicode.js');
pdfMake.vfs = pdfFonts.pdfMake.vfs;

import { EmailModel } from 'src/app/models/emailModel';
declare var $: any;
declare var M: any;

@Component({
  selector: 'app-house-repair',
  templateUrl: './house-repair.component.html',
  styleUrls: ['./house-repair.component.css']
})
export class HouseRepairComponent implements OnInit {

  themeColor = environment.appTheme.themeColor;
  loading = false;
  status = ['Pending', 'Approved', 'Declined', 'Completed'];

  step1 = true;
  step2 = false;
  repairForm: UntypedFormGroup;
  appealForm: UntypedFormGroup;
  feedbackForm: UntypedFormGroup;
  memberId: any = 1246;
  property: any;
  propertyId: any;
  requests: any[];
  showMore: any[] = [];
  index: number;
  completedRequests: any[];
  requestSent = false;
  model: any;

  scaleOption: Options = {
    floor: 0,
    ceil: 10,
    showTicks: true,
    //showTicksValues: true,
    step: 1,
    // getSelectionBarColor: (value: number): string => {
    //   if (value <= 2) {
    //     return 'red';
    //   }
    //   if (value <= 4) {
    //     return 'yellow';
    //   }
    //   if (value == 5) {
    //     return 'green';
    //   }
    //   return 'green';

    // },
    // getTickColor: (value: number): string => {
    //   if (value <= 2) {
    //     return 'red';
    //   }
    //   if (value <= 4) {
    //     return 'yellow';
    //   }
    //   if (value == 5) {
    //     return 'green';
    //   }
    //   return 'green';
    // },
    // getTickColor: (value: number): string => {
    //   if (value < 3) {
    //     return 'red';
    //   }
    //   if (value < 6) {
    //     return 'orange';
    //   }
    //   if (value < 9) {
    //     return 'yellow';
    //   }
    //   return '#2AE02A';
    // }
    // stepsArray: [
    //   { value: 1, legend: 'Very poor' },
    //   { value: 2, legend: 'Fair' },
    //   { value: 3, legend: 'Average' },
    //   { value: 4, legend: 'Good' },
    //   { value: 5, legend: 'Excellent' }
    // ]
  }
  value: number = 1;
  emailList = [];

  constructor(private location: Location,
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private tempHousingService: TempHousingService,
    private departmentService: DepartmentService,
    private fireMembersService: FireMembersService,
    private emailService: EmailService,
    private housingService: HousingService
  ) { }

  ngOnInit() {
    this.loading = true;
    this.memberId = this.authService.getCurrentApplicantID();
    
    if (this.memberId) {

      this.tempHousingService.getRepairRequestByMemberID(this.memberId).valueChanges().subscribe(requests => {
        if (requests && requests.length > 0) {
          this.requests = requests;

          this.requests = this.requests.sort(function (a, b) {
            return new Date(b.requestDate).getTime() - new Date(a.requestDate).getTime();
          });

          for (var i = 0; i < this.requests.length; i++) {
            this.showMore.push(false);
          };
          this.housingService.getPropertyByOwnerID(this.memberId).subscribe(property => {
            if (property && property.length > 0) {
              this.property = property[0];
              this.propertyId = this.property.ID;

            }
            this.loading = false;
            setTimeout(() => {
              $('select').formSelect();
              M.updateTextFields();
              $('.collapsible').collapsible();
            }, 25);
          });
        }
        this.loading = false;
      });
    }
    this.feedbackForm = this.fb.group({
      rating: [1, [Validators.required]],
      review: [''],
    });

    this.repairForm = this.fb.group({
      title: ['', [Validators.required]],
      description: ['', [Validators.required]]
    });

    this.appealForm = this.fb.group({
      statement: ['', [Validators.required]]
    });

    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
      $('.collapsible').collapsible();
      $('.modal').modal();
      $('.tooltipped').tooltip();
    }, 25);

  }

  get title() { return this.repairForm.get('title'); }
  get description() { return this.repairForm.get('description'); }

  onShowMore(index) {
    this.showMore[index] = !this.showMore[index];
  }

  appealDecision(index) {
    this.index = index;
    $('.modal').modal();
    $('#appealModal').modal('open');
  }

  appeal(values) {
    const request = this.requests[this.index];
    const appeal = {
      statement: values.statement,
      date: new Date()
    }
    request.appeal = appeal;

    this.tempHousingService.updateRepairRequest(request).then(x => {
      toast({ html: 'Appeal Submitted!', classes: 'green' });
      this.closeModal();
    });
    this.closeModal();
  }

  requestRepair() {
    this.repairForm.reset();
    this.step1 = false;
    this.step2 = true;
    setTimeout(() => {
      $('select').formSelect();
      M.updateTextFields();
      M.textareaAutoResize($('#description'));
    }, 25);
  }

  giveFeedback(index) {
    this.index = index;
  }

  submitRequest(values) {
    this.model = {
      memberID: this.memberId,
      houseID: this.propertyId ? this.propertyId : 0,
      houseNumber: this.property ? this.property.house_number : 0,
      title: values.title,
      description: values.description,
      status: this.status[0],
      requestDate: new Date()
    }

    this.tempHousingService.saveRepairRequest(this.model).then(x => {
      this.departmentService.getEmployeeInDepartmentWithApp('House Repair').valueChanges().subscribe((usersIndept: any) => {
        if (usersIndept) {
          if (usersIndept.length > 0) {
            // get the user
            usersIndept.forEach((app: any) => {
              this.fireMembersService.getMemberByID(app.employeeId).valueChanges().subscribe((user: any) => {
                if (user) {
                  this.emailList.push(user.Email);
                }
              });
            });

            // then notify
            const mail = new EmailModel();
            mail.emailList = this.emailList;
            mail.subject = `House Repair Application filled`;
            mail.body = `Hello,<br/>`;
            mail.body = mail.body += `The House Repair application has been filled please review.`;
            mail.body = mail.body += `<br/>Thank you.`;
            if (mail.emailList.length > 0) {
              this.emailService.sendEmail(mail).subscribe(() => {
                toast({ html: 'Email Successfully Sent.', classes: 'green' });
              });
            }
          }
        }
      });

      toast({ html: 'Request has been Sent!', classes: 'green' });
      this.backToPreviousPage();
    });
  }

  submitReview(values) {
    const request = this.requests[this.index];
    const feedback = {
      rating: values.rating,
      review: values.review
    }
    request.feedback = feedback;

    this.tempHousingService.updateRepairRequest(request).then(x => {
      toast({ html: 'Review Sucessfully Sent!', classes: 'green' });
    });
    this.feedbackForm.reset();
    this.closeModal();
  }

  isDate(date) {
    return date instanceof Date
  }

  createRange() {
    var items: number[] = [];
    for (var i = 1; i <= 5; i++) {
      items.push(i);
    }
    return items;
  }

  getScaleOption() {
    let options: Options = {
      floor: 1,
      ceil: 6,
      showTicks: true,
      showTicksValues: true,
      step: 1,
      getTickColor: (value: number): string => {
        if (value < 2) {
          return 'red';
        }
        if (value < 4) {
          return 'yellow';
        }
        return 'green';
      },
      stepsArray: [
        { value: 1, legend: 'Very poor' },
        { value: 2, legend: 'Fair' },
        { value: 3, legend: 'Average' },
        { value: 4, legend: 'Good' },
        { value: 5, legend: 'Excellent' }
      ]
    }
    return options;
  }

  async getDocumentDefinition(index) {
    const request = this.requests[index];

    return {
      info: {
        title: 'REPAIR REQUEST',
        subject: 'Repair Request',
        keywords: 'Repair Request',
      },
      pageSize: 'LETTER',
      content: [
        {
          style: 'table',
          layout: 'noBorders', // optional
          table: {
            widths: ['*', '*'],
            body: [
              [
                {
                  columns: [
                    [
                      await this.getLogo(),
                    ],
                    [
                      {
                        stack: [
                          {
                            text: 'MY NATION ',
                            style: 'mainTitle',
                            alignment: 'center',
                          },
                          {
                            text: 'Connecting Your Nation',
                            style: 'normalText',
                            alignment: 'center',
                          },
                          {
                            text: 'Sovereign Data For Sovereign Nations ',
                            fontSize: 8,
                            italics: true,
                            alignment: 'center',
                          },
                        ]
                      },
                    ],
                  ]
                },
                {
                  stack: [
                    {},
                    {
                      text: 'REPAIR REQUESTS',
                      style: 'title'
                    },
                    {},
                  ]
                },
              ],
            ]
          }
        },
        {
          style: 'table',
          table: {
            widths: ['*', '*', '*'],
            body: [
              // ['Column 1', 'Column 2', 'Column 3'],
              [
                {
                  stack: [
                    {
                      text: 'Title: ',
                      style: 'subTitle'
                    },
                    {
                      text: request.title.toUpperCase(),
                      style: 'normalText'
                    },
                  ]
                },

                {
                  stack: [
                    {
                      text: 'Description: ',
                      style: 'subTitle'
                    },
                    {
                      text: request.description,
                      style: 'normalText'
                    },
                  ],
                  colSpan: 2
                },
                {},
              ],
              [
                {
                  stack: [
                    {
                      text: 'House Number: ',
                      style: 'subTitle'
                    },
                    {
                      text: request.houseNumber,
                      style: 'normalText'
                    },
                  ]
                },

                {
                  stack: [
                    {
                      text: 'Requested On: ',
                      style: 'subTitle'
                    },
                    {
                      text: request.requestDate.toDate().toDateString(),
                      style: 'normalText'
                    },
                  ],
                },
                {
                  stack: [
                    {
                      text: 'Status: ',
                      style: 'subTitle'
                    },
                    {
                      text: request.status.toUpperCase(),
                      style: 'normalText'
                    },
                  ],
                },
              ],
            ]
          }
        },

        //Approved Requests
        request.decision && request.status === this.status[1] ?
          {
            style: 'table',
            table: {
              widths: ['*', '*', '*'],
              body: [
                [
                  {
                    text: 'Request Details: ',
                    style: 'subTitle',
                    colSpan: 3
                  },
                  {},
                  {}
                ],
                [
                  {
                    stack: [
                      {
                        text: 'Case Officer: ',
                        style: 'subTitle'
                      },
                      {
                        text: request.decision.employee,
                        style: 'normalText'
                      },
                    ]
                  },
                  {
                    stack: [
                      {
                        text: 'Start Date: ',
                        style: 'subTitle'
                      },
                      {
                        text: request.decision.startDate.toDate().toDateString(),
                        style: 'normalText'
                      },
                    ],
                  },
                  {
                    stack: [
                      {
                        text: 'End Date: ',
                        style: 'subTitle'
                      },
                      {
                        text: request.decision.endDate.toDate().toDateString(),
                        style: 'normalText'
                      },
                    ],
                  },
                ],
                [
                  {
                    stack: [
                      {
                        text: 'Message: ',
                        style: 'subTitle'
                      },
                      {
                        text: request.decision.message,
                        style: 'normalText'
                      },
                    ],
                    colSpan: 2
                  },
                  {},
                  {
                    stack: [
                      {
                        text: 'Decision Made On: ',
                        style: 'subTitle'
                      },
                      {
                        text: request.decision.date.toDate().toDateString(),
                        style: 'normalText'
                      },
                    ],
                  },
                ],
              ]
            }
          } : '',

        //Declined Requests
        request.decision && request.status === this.status[2] ?
          {
            style: 'table',
            table: {
              widths: ['*', '*', '*'],
              body: [
                [
                  {
                    text: 'Request Details: ',
                    style: 'subTitle',
                    colSpan: 3
                  },
                  {},
                  {}
                ],
                [
                  {
                    stack: [
                      {
                        text: 'Reason: ',
                        style: 'subTitle'
                      },
                      {
                        text: request.decision.reason,
                        style: 'normalText'
                      },
                    ],
                    colSpan: 2
                  },
                  {},
                  {
                    stack: [
                      {
                        text: 'Decision Made On: ',
                        style: 'subTitle'
                      },
                      {
                        text: request.decision.date.toDate().toDateString(),
                        style: 'normalText'
                      },
                    ],
                  },
                ],
              ]
            }
          } : '',

        //Completed Requests
        request.status === this.status[3] && request.decision ?
          {
            style: 'table',
            table: {
              widths: ['*', '*', '*'],
              body: [
                [
                  {
                    text: 'Request Details ',
                    style: 'subTitle',
                    colSpan: 3
                  },
                  {},
                  {}
                ],
                [
                  {
                    stack: [
                      {
                        text: 'Case Officer: ',
                        style: 'subTitle'
                      },
                      {
                        text: request.decision.employee,
                        style: 'normalText'
                      },
                    ]
                  },
                  {
                    stack: [
                      {
                        text: 'Start Date: ',
                        style: 'subTitle'
                      },
                      {
                        text: request.decision.startDate.toDate().toDateString(),
                        style: 'normalText'
                      },
                    ],
                  },
                  {
                    stack: [
                      {
                        text: 'End Date: ',
                        style: 'subTitle'
                      },
                      {
                        text: request.decision.endDate.toDate().toDateString(),
                        style: 'normalText'
                      },
                    ],
                  },
                ],
                [
                  {
                    stack: [
                      {
                        text: 'Message: ',
                        style: 'subTitle'
                      },
                      {
                        text: request.decision.message,
                        style: 'normalText'
                      },
                    ],
                    colSpan: 2
                  },
                  {},
                  {
                    stack: [
                      {
                        text: 'Completed On: ',
                        style: 'subTitle'
                      },
                      {
                        text: request.completedOn.toDate().toDateString(),
                        style: 'normalText'
                      },
                    ],
                  },
                ],
              ]
            }
          } : '',
        request.status === this.status[3] && request.feedback ?
          {
            style: 'table',
            table: {
              widths: ['*', '*', '*'],
              body: [
                [
                  {
                    text: 'Feedback ',
                    style: 'subTitle',
                    colSpan: 3
                  },
                  {},
                  {}
                ],
                [
                  {
                    stack: [
                      {
                        text: 'Rating: ',
                        style: 'subTitle'
                      },
                      {
                        text: request.feedback.rating + " " + "/ 5",
                        style: 'normalText'
                      },
                    ],

                  },
                  {
                    stack: [
                      {
                        text: 'Review: ',
                        style: 'subTitle'
                      },
                      {
                        text: request.feedback.review,
                        style: 'normalText'
                      },
                    ],
                    colSpan: 2
                  },
                  {},
                ],
              ]
            }
          } : '',
      ],
      styles: {
        mainTitle: {
          bold: true,
          fontSize: 16,
          alignment: 'left',
          margin: [0, 0, 0, 20]
        },
        title: {
          bold: true,
          fontSize: 14,
          alignment: 'center',
          margin: [0, 10, 0, 10]
        },
        subTitle: {
          bold: true,
          fontSize: 12,
          alignment: 'left',
          margin: [0, 10, 0, 10]
        },
        normalText: {
          fontSize: 11,
        },
        header: {
          fontSize: 12,
          bold: true,
          alignment: 'center',
        },
        links: {
          color: 'blue',
          decoration: 'underline',
          //italics: true,
          margin: [0, 5, 0, 5]
        },
        table: {
          margin: [0, 5, 0, 5]
        },
      }
    };
  }

  async getLogo() {
    let imageLink = "assets/img/Nation-logo.png";
    const image = await this.getBase64FromUrl(imageLink);
    if (image) {
      return {
        image: image,
        width: 100,
        alignment: 'left'
      }
    }
  }

  getBase64FromUrl = async (url) => {
    const data = await fetch(url);
    const blob = await data.blob();
    return new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = () => {
        const base64data = reader.result;
        resolve(base64data);
      }
    });
  }

  async openPdf(index) {
    const documentDefinition = await this.getDocumentDefinition(index);
    pdfMake.createPdf(documentDefinition).open();
  }

  async downloadPdf(index) {
    const documentDefinition = await this.getDocumentDefinition(index);
    pdfMake.createPdf(documentDefinition).download();
  }

  async printPdf(index) {
    const documentDefinition = await this.getDocumentDefinition(index);
    pdfMake.createPdf(documentDefinition).print();
  }

  closeModal() {
    $('.modal').modal('close');
  }

  backToPreviousPage() {
    this.requestSent = false;
    this.step1 = true;
    this.step2 = false;
  }

  back() {
    this.location.back();
  }

}
