import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges} from "@angular/core";
import { icons } from "src/app/models/SocialMediaIcons";
import { UntypedFormGroup, FormGroupDirective } from "@angular/forms";

@Component({
  selector: "app-social-media-platform",
  templateUrl: "./platform.component.html",
  styleUrls: ["./platform.component.css"],
})

export class SocialMediaPlatformComponent implements OnInit {
  platform: UntypedFormGroup;
  @Input() formGroupName: string;

  // @Output() platformPick = new EventEmitter();
  @Output() platformError = new EventEmitter();

  errorMessage = "";
  platformHasError = false;
  socialMediaIcons = icons;

  @Input() videoUrls;
  @Input() imageUrls;
  @Input() imgUrls;

  constructor(private rootFormGroup: FormGroupDirective) {}

  ngOnInit() {
    this.platform = this.rootFormGroup.control;
    console.log(this.platform, "the platform to update");
    this.isValidPlatform();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.platform) {
      this.isValidPlatform();
    }
  }

  isValidPlatform() {
    let videoRequiredPlatforms = ["tiktok", "youtube"];
    let imageRequiredPlatforms = ["pinterest"];
    let imageOrVideoRequiredPlatorms = ["instagram"];

    let noVideoPlatforms = ["pinterest"];
    let noImagePlatforms = ["youtube", "tiktok"];
    let noMediaPlatforms = ["fbg", "gmb", "reddit"];

    let platform = this.platform.get("platform").value;
    if (
      this.imageUrls.length == 0 &&
      imageRequiredPlatforms.includes(platform)
    ) {
      this.errorMessage = "Image is required";
      this.platformHasError = true;
    } else if (
      this.videoUrls.length == 0 &&
      videoRequiredPlatforms.includes(platform)
    ) {
      this.errorMessage = "Video is required";
      this.platformHasError = true;
    } else if (
      imageOrVideoRequiredPlatorms.includes(platform) &&
      this.videoUrls.length == 0 &&
      this.imageUrls.length == 0
    ) {
      this.errorMessage = "Image or Video is required";
      this.platformHasError = true;
    } else if (
      noVideoPlatforms.includes(platform) &&
      this.videoUrls.length > 0
    ) {
      this.errorMessage = "Video is not allowed";
      this.platformHasError = true;
    } else if (
      noImagePlatforms.includes(platform) &&
      this.imageUrls.length > 0
    ) {
      this.errorMessage = "Image is not allowed";
      this.platformHasError = true;
    } else if (
      noMediaPlatforms.includes(platform) &&
      (this.imageUrls.length > 0 || this.videoUrls.length > 0)
    ) {
      this.errorMessage = "Image or Video is not allowed";
      this.platformHasError = true;
    } else {
      this.errorMessage = "";
      this.platformHasError = false;
    }
    if (this.platformHasError) {
      this.platform.get("value").patchValue(false);
      this.platform.get("value").disable();
      this.platform.get("value").updateValueAndValidity();
    } else {
      this.platform.get("value").enable();
      this.platform.get("value").updateValueAndValidity();
    }
    this.platform.get("value").updateValueAndValidity();
    this.platform.updateValueAndValidity();
  }
  
}
