
import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { environment } from 'src/environments/environment';
import { FireMembersService } from 'src/app/services/firebase/fire-members.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { CommunityService } from 'src/app/services/api/community.service';
import { Router } from '@angular/router';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { AdminInvitationService } from 'src/app/services/firebase/admin-invitation.service';
import { toast } from "materialize-css";

declare var $: any;

@Component({
  selector: 'app-admins-list',
  templateUrl: './admins-list.component.html',
  styleUrls: ['./admins-list.component.css']
})

export class AdminsListComponent implements OnInit {

  loggedUser: any;
  fontColor = environment.appTheme.adminFontColor;
  defaultThemeColor = environment.appTheme.themeColor;
  themeColor: any;
  buttonType: any;
  loading = false;

  constructor(
    private location: Location,
    private fireMembersService: FireMembersService,
    private authService: AuthService,
    private communityService: CommunityService,
    private router: Router,
    public appSettingsService: AppSettingsService,
    public adminInvitationService: AdminInvitationService,
  ) {
    localStorage.setItem('from', 'Settings');
    this.loggedUser = this.authService.getGlobalUser();
    this.themeColor = this.appSettingsService.getAppThemeColor() ? this.appSettingsService.getAppThemeColor() : this.defaultThemeColor;
    this.buttonType = this.appSettingsService.getButtonType() ? this.appSettingsService.getButtonType() : 'rectangularButton';
  }

  admins: any[];
  disabledAdmins: any[];
  dtOptions: any;
  nationCommunities: any[];
  selectedCommunity: any;
  nationofUse = environment.firstNation.name;
  filtredResult: any[];
  nationAdmins: any[];
  include = false;
  sectionName: string;
  adminInvitations: any[];
  invitationId: any;
  submenus: any[];
  editName = false;

  ngOnInit() {
    this.appSettingsService.getMenuName('Manage').valueChanges().subscribe(menus => {
      if (menus && menus.length > 0) {
        this.submenus = menus[0].submenus;
        let menu = this.submenus.find(m => m.name == 'Admins');
        if (menu) this.sectionName = menu.displayName;
      }
    });

    this.dtOptions = {};
    if (this.nationofUse === 'Batc' || this.nationofUse === 'Pbcn') {
      this.communityService.getNationCommunities().subscribe(
        com => {
          if (com.length > 0) {
            this.nationCommunities = com;
          }
        }
      );
    }

    this.loading = true;
    this.fireMembersService.getAdminAccounts().valueChanges().subscribe(x => {
      if (x) {
        this.admins = x;
        this.disabledAdmins = this.admins.filter(admin => admin.status === 'Disabled');
        this.admins = this.admins.filter(admin => admin.status === 'Enabled');

        setTimeout(() => {
          $('select').formSelect();
          $('.modal').modal();
          $('.tabs').tabs();
          $('.tooltipped').tooltip();
        }, 25);
      }
      this.loading = false;
    });

    this.loading = false;
    this.adminInvitationService.getAllInvitation().valueChanges().subscribe(invitations => {
      if (invitations && invitations.length > 0) {
        this.adminInvitations = invitations;
      }
      setTimeout(() => {
        $('.collapsible').collapsible();
        $('.tabs').tabs();
      }, 50);
 
      this.loading = false;
    });
  }

  editNameClicked() {
    this.editName = true;
  }

  saveName() {
    let index = this.submenus.findIndex(m => m.name == 'Admins');
    this.submenus[index].displayName = this.sectionName;
    this.appSettingsService.UpdateMenu('Manage', this.submenus).then((update) => {
      this.editName = false;
      toast({ html: `${update}`, classes: 'green' });
    }).catch((error) => {
      toast({ html: `${error}`, classes: 'red' });
    });
  }


  includeDisabled() {
    this.include = !this.include;
    if (this.include) {
      this.disabledAdmins.forEach(admin => {
        this.admins.push(admin);
      });
    } else {
      this.disabledAdmins = this.admins.filter(admin => admin.status === 'Disabled');
      this.admins = this.admins.filter(admin => admin.status === 'Enabled');
    }
    setTimeout(() => {
      $('.tooltipped').tooltip();
    }, 25);
  }

  isRegistred(uid: any) {
    const adm = this.admins.find(m => m.id === uid);
    if (adm) {
      return true;
    } else {
      return false;
    }
  }

  communityChange(val: any) {
    if (val > 0) {
      this.filtredResult = this.nationAdmins.filter(adm => adm.default_band === Number(val));
    } else {
      this.filtredResult = this.nationAdmins;
    }
  }

  deleteInviation(id) {
    this.invitationId = id;
    $('.modal').modal();
  }

  delete() {
    this.adminInvitationService.deleteInvitation(this.invitationId).then(x => {
      toast({ html: 'Invitation successfully deleted', classes: 'red' });
    });
  }

  navigate(item) {
    const routeMappings = {
      'Admins': 'admin/dashboard-sub-menu/admins-list',
      'Membership': 'admin/members',
      'Applications': 'admin/dashboard-sub-menu/applications',
      'DemoRequest': 'admin/dashboard-sub-menu/demo-request',
    };

    const route = routeMappings[item];
    if (route) {
      this.router.navigate([route]);
    }
  }

  backClicked() {
    this.location.back();
  }

}
