<div class="container-pi">
    
    <h4 class="title-section1 title-member icon-texts"><span class="material-symbols-outlined back-icon p-r-1" (click)=" backClicked()">
        arrow_circle_left
    </span>Jobbb Offers List </h4>
<main class="StickyContent white row">
    <div class="col s12 l11">

        <div *ngIf="hasRows">
            <ul class="row collapsible">
                <li *ngFor="let j of joblist">
                    <div class="collapsible-header">
                        <i class="material-icons left blue-text">work</i>
                        <strong style="text-decoration: underline; text-transform: capitalize;"
                            class="gray-text text darken-2">{{ j.title}} at {{j.employer}} </strong>
                    </div>
                    <div class="collapsible-body">
                        <div class="row">
                            <span class="col s12 m6"> <strong class="blue-text">Description: </strong>
                                {{j.description}}</span>
                            <span class="col  s12 m6"> <strong class="blue-text">Skills: </strong>
                                {{j.skills}}</span>
                            <span class="col  s12 m6"> <strong class="blue-text">Job Type: </strong>
                                {{j.duration}}</span>
                            <span class="col  s12 m6"> <strong class="blue-text">Start Date: </strong>
                                {{j.start_date}}</span>
                            <span class="col  s12 m6"> <strong class="blue-text">Email: </strong> {{j.email}}</span>
                            <span class="col  s12 m6"> <strong class="blue-text">Telephone: </strong>
                                {{j.phone}}</span>
                        </div>
                    </div>
                </li>
            </ul>
        </div>

        <div class="card-panel orange darken-2" *ngIf="!hasRows">
            <h6 class="center white-text"><i class="material-icons white-text left">info</i>
                <strong> No new jobs have been posted. Please check again soon.</strong>
            </h6>
        </div>
        <a class="col s12 l12 btn waves-effect" title="Add Wall Post" (click)="backClicked()"
            style="margin-bottom: 15px;">
            <i class="material-icons left">arrow_back_ios</i>Back to Menu
        </a>
    </div>
</main>
</div>