import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { GovernanceService } from 'src/app/services/firebase/governance.service';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';

@Component({
  selector: 'app-gov-chief-detail',
  templateUrl: './gov-chief-detail.component.html',
  styleUrls: ['./gov-chief-detail.component.css']
})
export class GovChiefDetailComponent implements OnInit {
  
  defaultThemeColor = environment.appTheme.themeColor;
  themeColor: any;
  buttonType: any;
  model: any;
  selectedMember: any;
  

  constructor(private settingService: AppSettingsService, private governanceService: GovernanceService, private location: Location,private router: Router ) {
    this.themeColor = this.settingService.getAppThemeColor() ? this.settingService.getAppThemeColor(): this.defaultThemeColor;
    this.buttonType =  this.settingService.getButtonType() ? this.settingService.getButtonType(): 'rectangularButton';
   }

  ngOnInit() {
      this.governanceService.getGovernanceList().valueChanges().subscribe(gov => {
        if (gov.length > 0) {
          this.model = gov[0];
          if (this.model.chiefs.length > 0) {
            this.selectedMember = this.model.chiefs[0];
          }
        }
      });
  }

  backClicked() {
    this.location.back();
    // this.router.navigate(['/'])
  }
}
