import { Component, OnInit } from '@angular/core';
import { set } from 'date-fns';
import { NationSettingsService } from 'src/app/services/api/nation-settings.service';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { SocialMediaService } from 'src/app/services/firebase/social-media.service';

@Component({
  selector: 'app-landing-page-footer',
  templateUrl: './landing-page-footer.component.html',
  styleUrls: ['./landing-page-footer.component.css']
})
export class LandingPageFooterComponent implements OnInit {

  model: any;
  footerSection: any;
  socialMediaSection: any;
  socialMediaModel: any[];
  sqlNationSetting: any;
  appStoreSection:any;
  nationSetting: any = {
    address: '',
    Postal: '',
    Prov: '',
    Town: '',
    email: '',
    fax: '',
    tollFree: '',
  };

  constructor(private appSettingService: AppSettingsService,
    private nationSettingsService: NationSettingsService,
    private socialService: SocialMediaService) {}

  ngOnInit(): void {
    this.appSettingService.getHomePageSettings().valueChanges().subscribe(hp => {
      if (hp) {
        if (hp.length > 0) {
          this.model = hp[0];
          const footer = this.model.sections.filter(s => s.name === 'Footer' && s.isEnabled);
          if (footer && footer.length > 0) this.footerSection = footer[0];

          this.nationSettingsService.getNationSettings().subscribe(sett => {
            if (sett && sett.length > 0) {
              this.sqlNationSetting = sett[0];
              // this.nationSetting = sett[0];
              if (this.footerSection.style) {
                this.nationSetting.address = this.footerSection.style.replace("Sample Text", this.sqlNationSetting.address);
                this.nationSetting.Postal = this.footerSection.style.replace("Sample Text", this.sqlNationSetting.Postal);
                this.nationSetting.Prov = this.footerSection.style.replace("Sample Text", this.sqlNationSetting.Prov);
                this.nationSetting.Town = this.footerSection.style.replace("Sample Text", this.sqlNationSetting.Town);
                this.nationSetting.email = this.footerSection.style.replace("Sample Text", this.sqlNationSetting.email);
                this.nationSetting.phone = this.footerSection.style.replace("Sample Text", this.sqlNationSetting.telephone);
                this.nationSetting.fax = this.footerSection.style.replace("Sample Text", this.sqlNationSetting.fax);
                this.footerSection.tollFree = this.footerSection.style.replace("Sample Text", this.footerSection.tollFree);
              } else {
                this.nationSetting.address = this.sqlNationSetting.address;
                this.nationSetting.Postal = this.sqlNationSetting.Postal;
                this.nationSetting.Prov = this.sqlNationSetting.Prov;
                this.nationSetting.Town = this.sqlNationSetting.Town;
                this.nationSetting.email = this.sqlNationSetting.email;
                this.nationSetting.phone = this.sqlNationSetting.telephone;
                this.nationSetting.fax = this.sqlNationSetting.fax;
                this.footerSection.tollFree = this.footerSection.tollFree;
              }
            } else {
              if (this.footerSection.style) {
                this.nationSetting.address = this.footerSection.style.replace("Sample Text", this.footerSection.address);
                this.nationSetting.postalCode = this.footerSection.style.replace("Sample Text", this.footerSection.postalCode);
                this.nationSetting.province = this.footerSection.style.replace("Sample Text", this.footerSection.province);
                this.nationSetting.city = this.footerSection.style.replace("Sample Text", this.footerSection.city);
                this.nationSetting.contactEmail = this.footerSection.style.replace("Sample Text", this.footerSection.contactEmail);
                this.nationSetting.phone = this.footerSection.style.replace("Sample Text", this.footerSection.phone);
                this.nationSetting.fax = this.footerSection.style.replace("Sample Text", this.footerSection.fax);
                this.nationSetting.tollFree = this.footerSection.style.replace("Sample Text", this.footerSection.tollFree);
              } else {
                this.nationSetting.address = this.footerSection.address;
                this.nationSetting.postalCode = this.footerSection.postalCode;
                this.nationSetting.province = this.footerSection.province;
                this.nationSetting.city = this.footerSection.city;
                this.nationSetting.contactEmail = this.footerSection.contactEmail;
                this.nationSetting.phone = this.footerSection.phone;
                this.nationSetting.fax = this.footerSection.fax;
                this.nationSetting.tollFree = this.footerSection.tollFree;
              }
            }
          });
        }
        const socialSection = this.model.sections.filter(s => s.name === 'SocialMedia' && s.isEnabled);
        if (socialSection) {
          this.socialMediaSection = socialSection;
          this.socialService.getSocialMedia().valueChanges().subscribe(n => {
            if (n) {
              if (n.length > 0) this.socialMediaModel = n[0];
            }
          });
        }

        const appStoreSection = this.model.sections.filter(s => s.name === 'AppStore' && s.isEnabled);
        if(appStoreSection){
          this.appStoreSection = appStoreSection[0];
        }
      }
    });
  }
}
