import { Injectable } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';

declare const Pusher: any;
@Injectable({
  providedIn: 'root'
})
export class HelperService {
  getImageType(name: any) {
    throw new Error('Method not implemented.');
  }


  constructor(
    private clipboard: Clipboard,
  ) {};




  backButton(){
    window.history.back()
  }

  truncateHTML(text: string, limit: string): string{
    const charlimit = +limit;
    if (!text || text.length <= charlimit) {
      return text.replace(/<(?:.|\n)*?>/gm, '').replace(/&nbsp;/gm, ' ');
    }
    return text.replace(/<(?:.|\n)*?>/gm, '').replace(/&nbsp;/gm, ' ').substring(0, charlimit) + '...';

  }
  getInnerText(htmlString){
    return new DOMParser().parseFromString(htmlString, 'text/html').body.innerText
  }

  replaceUrl(message){
    if(!message) return;
 
    var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;
    return message.replace(urlRegex, function (url) {
      var hyperlink = url;
      if (!hyperlink.match('^https?:\/\/')) {
        hyperlink = 'http://' + hyperlink;
      }
      return '<a href="' + hyperlink + '" target="_blank" rel="noopener noreferrer">' + url + '</a>'
    });
  }

 
  isValidURL(str) {
    var regex = /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
    if(!regex .test(str)) {
      return false;
    } else {
      return true;
    }
  }

  calculateWordCount(text) {
    const wpm = 225; // average speed per minutes
    const wordsArr = text.trim().split(' ');
    const wordsCount = wordsArr.filter(word => word !== '').length;
    return Math.ceil(wordsCount / wpm);
  }

  dataURItoBlob(dataURI) {
    const byteString = window.atob(dataURI);
    const arrayBuffer = new ArrayBuffer(byteString.length);
    const int8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < byteString.length; i++) {
      int8Array[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([int8Array], { type: 'image/png' });
    return blob;
  }


  validatePDF(name: any) {
    const ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() === 'pdf') {
      return true;
    }
    else {
      return false;
    }
  }

  validateImage(name: any) {
    const ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() === 'png') {
      return true;
    } else if (ext.toLowerCase() === 'jpg') {
      return true;
    } else if (ext.toLowerCase() === 'jpeg') {
      return true;
    } else if (ext.toLowerCase() === "svg") {
      return true;
    }
    else if (ext.toLowerCase() === "webp") {
      return true;
    } else {
      return false;
    }
  }
  
  getUrl(url) {
    const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|\&v=)([^#\&\?]*).*/;
    const match = url.match(regExp);

    if (match && match[2].length === 11) {
      const youtubeUrl = 'https://www.youtube.com/embed/' + match[2];
      return youtubeUrl;
    } else {
        return url;
    }
  }


  copy(text){
    this.clipboard.copy(text)
  }
}
