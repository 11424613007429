<div class="row">
    <!-- Information Page -->
    <div class="row card white" *ngIf="step == 0">
        <div class="row col l4 hide-on-med-and-down">
            <img src="assets/img/PSSSP-image.jpg" width="300px" height="300px" />
        </div>

        <div class="row col s12 l8 right">
            <h5 class="cart-title center-align"><strong>BALLANTYNE CREE NATION
                    POST-SECONDARY STUDENT SUPPORT PROGRAM
                    APPLICATION FORM – Continuing Student</strong>
            </h5>
            <ul class="center-align">
                <li>Chief Joseph Custer Reserve #201 </li>
                <li>P.O Box 97</li>
                <li>Prince Albert, SK S6V-5R4</li>
                <li><strong>Phone: </strong> (306) 953-4409</li>
                <li><strong>Fax: </strong> (306) 953-4405</li>
                <li><strong>Toll Free: </strong> 1-877-953-4409</li>
                <li><strong>Email:</strong> pssspreception&#64;pbcnea.ca</li>
            </ul>
        </div>

        <div class="col s12" style="text-align: justify;">
            <p><strong>Fully completed and signed original</strong> application forms and related documentation must be
                received at the Post-Secondary Office by the following deadline dates: </p>
            <p class="center-align"><strong>May 31st for Fall Semester (September) commencement</strong></p>
            <p class="center-align"><strong>March 31st for Intersession and Summer school commencement</strong></p>
            <p class="center-align">**All continuing students must apply for Fall/Winter and Spring, if needed.</p>
            <p><strong>PLEASE NOTE: </strong>Faxed applications will not be accepted for final approval (must be
                followed by original application). Scanned and emailed applications will be accepted.
                Late applications will not be considered for funding.
                Incomplete applications will not be accepted
            </p>

            <ul class="collection with-header">
                <li class="collection-header">
                    <h6><strong>APPLICATIONS WILL NOT BE CONSIDERED WITHOUT THE FOLLOWING DOCUMENTS:</strong></h6>
                </li>
                <li class="collection-header">
                    <p><strong>PLEASE CHECK OFF DOCUMENTATION ATTACHED:</strong></p>
                </li>
                <li class="collection-item">Transcript/Marks (Post-Secondary)</li>
                <li class="collection-item">Letter of Acceptance (If new program)</li>
                <li class="collection-item">Registration of classes</li>
                <li class="collection-item"> Copy of Hospitalization Card ( for new Dependents)</li>
                <li class="collection-item">Copy of Treaty/Status card</li>
                <li class="collection-item">Banking information for direct deposit</li>
                <li class="collection-item">Letter of Intent (Future Goals and Academic plans)</li>
            </ul>
        </div>
        <div class="row col s12">
            <button class="col s12 btn waves-effect green" type="button" style="margin-top: 15px; margin-bottom: 15px"
                (click)="onNext()">Start Your
                Application
            </button>
        </div>
    </div>

    <!-- Applicantion Pages -->
    <div class="row" *ngIf="step > 0">
        <p class="center-align"> Step {{step}} of 6</p>

        <form *ngIf="step == 1" class="row col s12 card white" [formGroup]="studentInfoForm"
            (ngSubmit)="submitStudentInfo(studentInfoForm.value)">

            <div class="row col s12 teal lighten-2 white-text">
                <h5 class="center-align">PART A: STUDENT INFORMATION</h5>
            </div>

            <div class="row card-content">
                <div class="input-field col s12 m6">
                    <i class="material-icons prefix red-text">person</i>
                    <input id="last_name" type="text" class="validate" formControlName="last_name" autocomplete="off"
                        style="text-transform: capitalize;">
                    <label for="last_name">Last Name<span class="red-text">*</span></label>
                    <div *ngIf="(last_name.dirty || last_name.touched) && last_name.invalid">
                        <small *ngIf="last_name.errors.required" class="red-text"> The Last Name is required. </small>
                    </div>
                </div>

                <div class="input-field col s12 m6">
                    <i class="material-icons prefix red-text">person</i>
                    <input id="first_name" type="text" class="validate" formControlName="first_name" autocomplete="off"
                        style="text-transform: capitalize;">
                    <label for="first_name">First Name<span class="red-text">*</span></label>
                    <div *ngIf="(first_name.dirty || first_name.touched) && first_name.invalid">
                        <small *ngIf="first_name.errors.required" class="red-text"> The First Name is required. </small>
                    </div>
                </div>

                <div class="input-field col s12 m6">
                    <i class="material-icons prefix red-text">person</i>
                    <input id="middle_name" type="text" class="validate" formControlName="middle_name"
                        autocomplete="off" style="text-transform: capitalize;">
                    <label for="middle_name">Middle Name</label>
                </div>

                <h6 class="row col s12"><strong>MAILING ADDRESS: </strong>This address will be where you want your
                    letter to be mailed. </h6>
                <div class="input-field col s12 m6">
                    <i class="material-icons prefix green-text">location_on</i>
                    <input id="street" type="text" formControlName="street" class="validate"
                        style="text-transform: capitalize;">
                    <label for="street">P.O.Box/Street</label>
                </div>

                <div class="input-field col s12 m6">
                    <i class="material-icons prefix green-text">apartment</i>
                    <input id="apt" type="text" formControlName="apt" class="validate"
                        style="text-transform: capitalize;">
                    <label for="apt">Apartment</label>
                </div>

                <div class="input-field col s12 m6">
                    <i class="material-icons prefix pink-text">add_location</i>
                    <select formControlName="province" class="validate" (change)="provinceChange($event.target.value)">
                        <option *ngFor="let t of provinces" [value]="t.code">{{t.name}}</option>
                    </select>
                    <label for="province">Province<span class="red-text">*</span></label>
                </div>

                <div class="input-field col s12 m6">
                    <i class="material-icons prefix purple-text">location_city</i>
                    <input id="city" type="text" formControlName="city" class="validate"
                        style="text-transform: capitalize;">
                    <label for="city">City<span class="red-text">*</span></label>
                    <div *ngIf="(city.dirty || city.touched) && city.invalid">
                        <small *ngIf="city.errors.required" class="red-text">City is required</small>
                    </div>
                </div>

                <div class="input-field col s12 m6">
                    <i class="material-icons prefix purple-text">mail</i>
                    <!-- <input id="postal_code" [textMask]="{mask: postalCodeMask, guide: true}" type="text"
                        formControlName="postal_code" class="validate" style='text-transform:uppercase'> -->
                        <input id="postal_code"  type="text"
                        formControlName="postal_code" class="validate" style='text-transform:uppercase'>
                    <label for="postal_code">Postal Code<span class="red-text">*</span></label>
                    <div *ngIf="(postal_code.dirty || postal_code.touched) && postal_code.invalid">
                        <small *ngIf="postal_code.errors.required" class="red-text"> The Postal Code is required.
                        </small>
                    </div>
                </div>

                <h6 class="row col s12"><strong>OTHER REQUIRED INFORMATION: </strong></h6>
                <div class="input-field col s12 m6">
                    <i class="material-icons prefix blue-text">phone</i>
                    <!-- <input id="home_number" [textMask]="{mask: phoneNumberMask, guide: true}" type="text"
                        formControlName="home_number" class="validate"> -->
                        <input id="home_number"  type="text"
                        formControlName="home_number" class="validate">
                    <label for="home_number">Home Number</label>
                </div>

                <div class="input-field col s12 m6">
                    <i class="material-icons prefix blue-text">phone_iphone</i>
                    <!-- <input id="mobile" [textMask]="{mask: phoneNumberMask, guide: true}" type="text"
                        formControlName="mobile" class="validate"> -->
                        <input id="mobile" type="text"
                        formControlName="mobile" class="validate">
                    <label for="mobile">Mobile Number<span class="red-text">*</span></label>
                    <div *ngIf="(mobile.dirty || mobile.touched) && mobile.invalid">
                        <small *ngIf="mobile.errors.required" class="red-text">Mobile Number is Required.</small>
                    </div>
                </div>

                <div class="input-field col s12 m6">
                    <i class="material-icons prefix blue-text">today</i>
                    <!-- <input *ngIf="!dates[0].set" id="dob" type="text" formControlName="dob" class="validate"
                        [textMask]="{mask: dateMask, guide: true}" placeholder="dd/mm/yyyy"
                        (blur)="setDate(dob,0, $event.target.value)"> -->
                        <input *ngIf="!dates[0].set" id="dob" type="text" formControlName="dob" class="validate"
                         placeholder="dd/mm/yyyy"
                        (blur)="setDate(dob,0, $event.target.value)">
                    <input *ngIf="dates[0].set" id="dob" type="text" formControlName="dob" class="validate"
                        placeholder="dd/mm/yyyy" (focus)="unSetDate(dob, 0)">
                    <label for="dob">Date of Birth (dd/mm/yyyy)<span class="red-text">*</span></label>
                    <div *ngIf="(dob.dirty || dob.touched) && dob.invalid">
                        <small *ngIf="dob.errors.required" class="red-text"> The Date Of Birth is required. </small>
                        <small *ngIf="dob.errors.invalidDate" class="red-text"> The Date invalid </small>
                    </div>
                </div>

                <div class="input-field col s12 m6">
                    <i class="material-icons prefix pink-text">apps</i>
                    <!-- <input id="SIN" type="text" formControlName="SIN" class="validate"
                        [textMask]="{mask: SINMask, guide: true}"> -->
                        <input id="SIN" type="text" formControlName="SIN" class="validate"
                      >
                    <label for="SIN">SIN</label>
                    <div *ngIf="(SIN.dirty || SIN.touched) && SIN.invalid">
                        <small *ngIf="SIN.errors.minlength || SIN.errors.maxlength" class="red-text">The SIN must be 9
                            Digits.</small>
                    </div>
                </div>

                <div class="input-field col s12 m6">
                    <i class="material-icons prefix blue-text">tag</i>
                    <input id="student_no" type="text" formControlName="student_no" class="validate">
                    <label for="student_no">Student Number</label>
                </div>

                <div class="input-field col s12 m6">
                    <i class="material-icons prefix blue-text">pin</i>
                    <!-- <input id="treaty_no" type="text" formControlName="treaty_no" class="validate"
                        [textMask]="{mask: treatyNomask, guide: true}"> -->
                        <input id="treaty_no" type="text" formControlName="treaty_no" class="validate"
                       >
                    <label for="treaty_no">Treaty Number</label>
                </div>

                <div class="input-field col s12 m6">
                    <i class="material-icons prefix purple-text">mail</i>
                    <input id="email" type="text" formControlName="email" class="validate">
                    <label for="email">Email</label>
                    <div *ngIf="(email.dirty || email.touched) && email.invalid">
                        <small *ngIf="email.errors.email" class="red-text">Invalid Email Address</small>
                    </div>
                </div>

                <div class="input-field col s12 m6">
                    <i class="material-icons prefix blue-text">people</i>
                    <select id="marital_status" formControlName="marital_status" class="validate"
                        (change)="maritalStatusChange($event.target.value)">
                        <option value="" disabled selected>Choose your option</option>
                        <option *ngFor="let status of maritalStatus" [value]="status.value">{{status.name}}</option>
                    </select>
                    <label for="marital_status">Marital Status<span class="red-text">*</span></label>
                </div>
            </div>

            <button class="col s12 m5 l3 btn left waves-effect blue" type="button" style="margin-bottom: 15px"
                (click)="onBack()"><i class="material-icons left">arrow_back</i>Back
            </button>

            <button class="col s12 m5 offset-m2 l3 btn right waves-effect blue" type="submit"
                [disabled]="!studentInfoForm.valid" style="margin-bottom: 15px" title="Save">Next <i
                    class="material-icons right">arrow_forward</i></button>
        </form>

        <form *ngIf="step == 2" class="row col s12 card white" [formGroup]="spouseInfoForm"
            (ngSubmit)="submitSpouseInfo(spouseInfoForm.value)">
            <div class="row col s12 teal lighten-2 white-text">
                <h5 class="center-align">PART B: SPOUSE INFORMATION</h5>
            </div>
            <h6><strong>Note: </strong>Include Common-Law Information.</h6>

            <div class="row card-content">
                <div class="input-field col s12 m4">
                    <i class="material-icons prefix blue-text">person</i>
                    <input id="spouse_last_name" type="text" class="validate" formControlName="spouse_last_name"
                        autocomplete="off" style="text-transform: capitalize;">
                    <label for="spouse_last_name">Last Name<span class="red-text">*</span></label>
                    <div *ngIf="(spouse_last_name.dirty || spouse_last_name.touched) && spouse_last_name.invalid">
                        <small *ngIf="spouse_last_name.errors.required" class="red-text"> The Spouse Last Name is
                            required. </small>
                    </div>
                </div>
                <div class="input-field col s12 m4">
                    <i class="material-icons prefix blue-text">person</i>
                    <input id="spouse_first_name" type="text" class="validate" formControlName="spouse_first_name"
                        autocomplete="off" style="text-transform: capitalize;">
                    <label for="spouse_first_name">First Name<span class="red-text">*</span></label>
                    <div *ngIf="(spouse_first_name.dirty || spouse_first_name.touched) && spouse_first_name.invalid">
                        <small *ngIf="spouse_first_name.errors.required" class="red-text"> The Spouse First Name is
                            required. </small>
                    </div>
                </div>

                <div class="input-field col s12 m4">
                    <i class="material-icons prefix blue-text">person</i>
                    <input id="spouse_middle_name" type="text" class="validate" formControlName="spouse_middle_name"
                        autocomplete="off" style="text-transform: capitalize;">
                    <label for="spouse_middle_name">Middle Name</label>
                </div>

                <div class="row input-field col s12">
                    <div class="row col s12">Will your spouse be living with you during the time of your funding? <span
                            class="red-text">*</span>
                    </div>
                    <div class="row col s12">
                        <label class="col s6">
                            <input class="with-gap" formControlName="spouse_live" name="spouse_live" type="radio"
                                value="yes" />
                            <span>Yes</span>
                        </label>
                        <label class="col s6">
                            <input class="with-gap" formControlName="spouse_live" name="spouse_live" type="radio"
                                value="no" />
                            <span>No</span>
                        </label>
                    </div>
                </div>

                <div class="row input-field col s12">
                    <div class="row col s12">Will your spouse be employed? funded Student? <span
                            class="red-text">*</span></div>
                    <div class="row col s12">
                        <label class="col s6">
                            <input class="with-gap" formControlName="spouse_employed" name="spouse_employed"
                                type="radio" value="yes" />
                            <span>Yes</span>
                        </label>
                        <label class="col s6">
                            <input class="with-gap" formControlName="spouse_employed" name="spouse_employed"
                                type="radio" value="no" />
                            <span>No</span>
                        </label>
                    </div>
                </div>
            </div>

            <button class="col s12 m5 l3 btn left waves-effect blue" type="button" style="margin-bottom: 15px"
                (click)="onBack()"><i class="material-icons left">arrow_back</i>Back
            </button>

            <button class="col s12 m5 offset-m2 l3 btn right waves-effect blue" type="submit"
                [disabled]="!spouseInfoForm.valid" style="margin-bottom: 15px" title="Save">Next <i
                    class="material-icons right">arrow_forward</i></button>
        </form>

        <form *ngIf="step == 3" class="row col s12" [formGroup]="dependentsInfoForm"
            (ngSubmit)="submitDependentsInfo(dependentsInfoForm.value)">
            <div class="row col s12 teal lighten-2 white-text">
                <h5 class="center-align">PART C: DEPENDENTS INFORMATION</h5>
            </div>
            <h6><strong>Note: </strong> Please Provide a CCTB form for all dependents in your care.</h6>

            <div class="col s12 card white">
                <div class="row col s12 card-content">
                    <div class="row col s12"><strong>Do you want to Add children?</strong><span
                            class="red-text">*</span></div>
                    <div class="row input-field col s12">
                        <div class="row col s12">
                            <label class="col s6">
                                <input type="radio" value="yes" formControlName="add_children"
                                    (change)="onAddChildrenYes()" />
                                <span>Yes</span>
                            </label>
                            <label class="col s6">
                                <input type="radio" value="no" formControlName="add_children"
                                    (change)="onAddChildrenNo()" />
                                <span>No</span>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <br>
            <div *ngIf="displayChildren" class="row col s12 card white">
                <h6 class="card-title"> Childrens Added</h6>
                <div formArrayName="childrens" *ngFor="let child of childControls.controls; let i = index;">
                    <ul class="collapsible">
                        <li>
                            <div class="row collapsible-header"><i class="material-icons">person</i>
                                <span class="col s4"><strong>First Name: </strong>
                                    {{childControls.controls[i].controls.first_name.value}}</span>
                                <span class="col s4"><strong>last Name: </strong>
                                    {{childControls.controls[i].controls.last_name.value}}</span>
                                <div class="col s4">
                                    <span>
                                        <i class="material-icons red-text small col s2" (click)="onDeleteChildern(i)"
                                            style="cursor: pointer;">delete</i>
                                    </span>
                                    <span>
                                        <i class="material-icons blue-text small col s2" (click)="onEditChild(i)"
                                            style="cursor: pointer;">edit</i>
                                    </span>
                                </div>
                            </div>
                            <div class="row collapsible-body">
                                <span class="col s6"> <strong>Birth Date: </strong>
                                    {{childControls.controls[i].controls.dob.value}}</span>
                                <span class="col s6"> <strong>Age: </strong>
                                    {{childControls.controls[i].controls.age.value}}</span>
                                <span class="col s6"> <strong>Grade: </strong>
                                    {{childControls.controls[i].controls.grade.value}}</span>
                                <span class="col s6"> <strong>School: </strong>
                                    {{childControls.controls[i].controls.school.value}}</span>
                                <span class="col s6"> <strong>Comments: </strong>
                                    {{childControls.controls[i].controls.comments.value}}</span>
                            </div>
                        </li>
                    </ul>
                </div>

                <div class="row col s12" style="margin-bottom: 25px; margin-top: 25px;" *ngIf="!addChildren">
                    <p class="center-align"><strong>Do you wish to Add Another Child?</strong></p>
                    <button class="col s12 btn green" type="button" (click)="addAnotherChild()"><i
                            class="material-icons">add</i>Add Another Child</button>
                </div>
            </div>

            <div class="section"></div>

            <div *ngIf="addChildren" class="row col s12 card white">
                <br>
                <div class="col s12 card-content">
                    <span class="card-title center-align col s10">Add dependent</span>
                    <span class="material-icons right red-text col s2" (click)="closeAddChild()"
                        style="cursor: pointer;">close</span>

                    <div class="input-field col s12 m6">
                        <i class="material-icons prefix blue-text">face</i>
                        <input id="child_first_name" type="text" formControlName="child_first_name" class="validate"
                            style="text-transform: capitalize;">
                        <label for="child_first_name">Child First Name<span class="red-text">*</span></label>
                        <div *ngIf="(child_first_name.dirty || child_first_name.touched) && child_first_name.invalid">
                            <small *ngIf="child_first_name.errors.required" class="red-text"> The First Name is
                                required. </small>
                        </div>
                    </div>
                    <div class="input-field col s12 m6">
                        <i class="material-icons prefix pink-text">face</i>
                        <input id="child_last_name" type="text" formControlName="child_last_name" class="validate"
                            style="text-transform: capitalize;">
                        <label for="child_last_name">Child Last Name<span class="red-text">*</span></label>
                        <div *ngIf="(child_last_name.dirty || child_last_name.touched) && child_last_name.invalid">
                            <small *ngIf="child_last_name.errors.required" class="red-text"> The Last Name is
                                required. </small>
                        </div>
                    </div>

                    <div class="input-field col s12 m6">
                        <i class="material-icons prefix blue-text">today</i>
                        <!-- <input *ngIf="!dates[1].set" id="child_dob" type="text" formControlName="child_dob"
                            class="validate" [textMask]="{mask: dateMask, guide: true}" placeholder="dd/mm/yyyy"
                            (blur)="setDate(child_dob,1,$event.target.value)"> -->
                            <input *ngIf="!dates[1].set" id="child_dob" type="text" formControlName="child_dob"
                            class="validate" placeholder="dd/mm/yyyy"
                            (blur)="setDate(child_dob,1,$event.target.value)">
                        <input *ngIf="dates[1].set" id="child_dob" type="text" formControlName="child_dob"
                            class="validate" placeholder="dd/mm/yyyy" (focus)="unSetDate(child_dob, 1)">
                        <label for="child_dob">Date of Birth (dd/mm/yyyy)<span class="red-text">*</span></label>
                        <div *ngIf="(child_dob.dirty || child_dob.touched) && child_dob.invalid">
                            <small *ngIf="child_dob.errors.required" class="red-text"> The Date of Birth is
                                required.</small>
                            <small *ngIf="child_dob.errors.invalidDate" class="red-text"> The Date is
                                Invalid</small>
                        </div>
                    </div>

                    <div class="input-field col s12 m6">
                        <i class="material-icons prefix green-text">apps</i>
                        <input id="child_age" type="number" formControlName="child_age" class="validate">
                        <label for="child_age">Age</label>
                    </div>

                    <div class="input-field col s12 m6">
                        <i class="material-icons prefix green-text">grade</i>
                        <input id="child_grade" type="number" formControlName="child_grade" class="validate">
                        <label for="child_grade">Grade in September</label>
                    </div>

                    <div class="input-field col s12 m6">
                        <i class="material-icons prefix green-text">school</i>
                        <select formControlName="child_school" class="validate" id="child_school">
                            <option value="" disabled selected>Choose your option</option>
                            <option value="PA">P.A</option>
                            <option value="onReserve">On Reserve</option>
                        </select>
                        <label for="child_school">Place they will attend school(P.A or on reserve)</label>
                    </div>

                    <div class="input-field col s12">
                        <i class="material-icons prefix green-text">comment</i>
                        <textarea id="comments" class="materialize-textarea"></textarea>
                        <label for="comments">Comments</label>
                    </div>
                </div>

                <button *ngIf="!editMode" class="col s12 btn green" type="button" style="margin-bottom: 15px;"
                    (click)="addChild(dependentsInfoForm.value)" [disabled]="!dependentsInfoForm.valid">Add
                    Child</button>

                <button *ngIf="editMode" class="col s12 btn green" type="button" style="margin-bottom: 15px;"
                    [disabled]="!dependentsInfoForm.valid">Update Child
                    Child</button>
                <!-- (click)="updateChild(dependentsInfoForm.value)" -->

                <button class="col s12 btn red" type="button" style="margin-bottom: 15px;"
                    (click)="closeAddChild()">Close</button>

            </div>

            <button *ngIf="!addChildren" class="col s12 m5 l3 btn left waves-effect blue" type="button"
                style="margin-bottom: 15px" (click)="onBack()"><i class="material-icons left">arrow_back</i>Back
            </button>

            <button *ngIf="!addChildren" class="col s12 m5 offset-m2 l3 btn right waves-effect blue" type="submit"
                [disabled]="!dependentsInfoForm.valid" style="margin-bottom: 15px" title="Save">Next <i
                    class="material-icons right">arrow_forward</i></button>
        </form>

        <form *ngIf="step == 4" class="row col s12" [formGroup]="assistanceReqForm"
            (ngSubmit)="submitAssistanceReq(assistanceReqForm.value)">
            <div class="row col s12 teal lighten-2 white-text">
                <h5 class="center-align">PART D: ASSISTANCE REQUIRED</h5>
            </div>

            <div class="col s12 card white">
                <div class="row col s12 card-content">
                    <div class="row col s12"><strong> Type of Assistance</strong><span class="red-text">*</span></div>
                    <div class="row input-field col s12">
                        <div class="row col s12">
                            <label class="col s6">
                                <input type="radio" value="Full Time" formControlName="type" (change)="onFullTime()" />
                                <span>Full Time</span>
                            </label>
                            <label class="col s6">
                                <input type="radio" value="Part Time" formControlName="type" (change)="onPartTime()" />
                                <span>Part Time (Tuition & Books Only)</span>
                            </label>
                        </div>
                    </div>

                    <div class="input-field col s12">
                        <i class="material-icons prefix blue-text">business</i>
                        <select formControlName="institution" class="validate"
                            (change)="institutionSelected($event.target.value)">
                            <option value="" disabled selected>Choose your Institution</option>
                            <option *ngFor="let t of institutionsList" [value]="t.name">{{t.name}}</option>
                            <option value="others">Others</option>
                        </select>
                        <label for="institution">Institution<span class="red-text">*</span></label>
                    </div>
                    <div *ngIf="otherInstitution" class="input-field col s12">
                        <i class="material-icons prefix blue-text">label</i>
                        <input id="otherInstitution" type="text" formControlName="otherInstitution" class="validate"
                            style="text-transform: capitalize;">
                        <label for="otherInstitution">Name of Institution (Please Type the offical name of the
                            Institution)<span class="red-text">*</span></label>
                        <div *ngIf="(institution.dirty || institution.touched) && institution.invalid">
                            <small *ngIf="institution.errors.required" class="red-text"> Name of Institution is
                                required. </small>
                        </div>
                    </div>

                    <div class="input-field col s12" *ngIf="branches.length > 0">
                        <i class="material-icons prefix blue-text">location_on</i>
                        <select formControlName="location" class="validate">
                            <option value="" disabled selected>Choose Location</option>
                            <option *ngFor="let t of branches" [value]="t">{{t}}</option>
                        </select>
                        <label for="location">Location</label>
                    </div>

                    <div class="input-field col s12" *ngIf="branches.length == 0">
                        <i class="material-icons prefix blue-text">location_on</i>
                        <input id="location" type="text" formControlName="location" class="validate"
                            style="text-transform: capitalize;">
                        <label for="location">Location</label>
                    </div>

                    <div class="input-field col s12">
                        <i class="material-icons prefix blue-text">book</i>
                        <select formControlName="program" class="validate"
                            (change)="programSelected($event.target.value)">
                            <option value="" disabled selected>Choose your Program</option>
                            <option *ngFor="let p of programsList" [value]="p.program">{{p.program}}</option>
                            <option value="others">Others</option>
                        </select>
                        <!-- <input id="program" type="text" formControlName="program" class="validate"
                            style="text-transform: capitalize;"> -->
                        <label for="program">Program <span class="red-text">*</span></label>
                    </div>

                    <div *ngIf="otherProgram" class="input-field col s12">
                        <i class="material-icons prefix blue-text">label</i>
                        <input id="otherProgram" type="text" formControlName="otherProgram" class="validate"
                            style="text-transform: capitalize;">
                        <label for="otherProgram">Program (Please Type the offical name of the Program)<span
                                class="red-text">*</span></label>
                        <div *ngIf="(program.dirty || program.touched) && program.invalid">
                            <small *ngIf="program.errors.required" class="red-text"> Name of Program is required.
                            </small>
                        </div>
                    </div>

                    <div class="input-field col s12 m6">
                        <i class="material-icons prefix blue-text">date_range</i>
                        <!-- <input *ngIf="!dates[2].set" id="start_date" type="text" formControlName="start_date"
                            class="validate" [textMask]="{mask: dateMask, guide: true}" placeholder="dd/mm/yyyy"
                            (blur)="setDate(start_date,2, $event.target.value)"> -->
                            <input *ngIf="!dates[2].set" id="start_date" type="text" formControlName="start_date"
                            class="validate"  placeholder="dd/mm/yyyy"
                            (blur)="setDate(start_date,2, $event.target.value)">
                        <input *ngIf="dates[2].set" id="start_date" type="text" formControlName="start_date"
                            class="validate" placeholder="dd/mm/yyyy" (focus)="unSetDate(start_date, 2)">
                        <label for="start_date">Starts On</label>
                    </div>

                    <div class="input-field col s12 m6">
                        <i class="material-icons prefix blue-text">date_range</i>
                        <!-- <input *ngIf="!dates[3].set" id="end_date" type="text" formControlName="end_date"
                            class="validate" [textMask]="{mask: dateMask, guide: true}" placeholder="dd/mm/yyyy"
                            (blur)="setDate(end_date,3, $event.target.value)"> -->
                            <input *ngIf="!dates[3].set" id="end_date" type="text" formControlName="end_date"
                            class="validate"  placeholder="dd/mm/yyyy"
                            (blur)="setDate(end_date,3, $event.target.value)">
                        <input *ngIf="dates[3].set" id="end_date" type="text" formControlName="end_date"
                            class="validate" placeholder="dd/mm/yyyy" (focus)="unSetDate(end_date, 3)">
                        <label for="end_date">Ends On</label>
                    </div>

                    <div class="input-field col s12 m6">
                        <i class="material-icons prefix blue-text">schedule</i>
                        <input id="length" type="number" formControlName="length" class="validate">
                        <label for="length">Program Length in Years</label>
                    </div>

                    <div class="input-field col s12 m6">
                        <i class="material-icons prefix blue-text">timelapse</i>
                        <!-- <input id="years_completed" type="text" formControlName="years_completed" class="validate"
                            [textMask]="{mask: yearMask, guide: true}"> -->
                            <input id="years_completed" type="text" formControlName="years_completed" class="validate"
                            >
                        <label for="years_completed">Year completed</label>
                    </div>

                    <div class="row col s12"><strong>Terms </strong>(**Mark only the semesters your application will
                        cover. Each student is required to reapply on an annual basis and for Inter-summer Session).
                    </div>
                    <div class="row input-field col s12" formGroupName="terms">
                        <div class="row col s12">

                            <label class="col s12 m6 l3">
                                <input type="checkbox" formControlName="fall" />
                                <span>Fall (Sept-Jan)</span>
                            </label>
                            <label class="col s12 m6 l3">
                                <input type="checkbox" formControlName="winter" />
                                <span>Winter (Jan-Apr)</span>
                            </label>
                            <label class="col s12 m6 l3">
                                <input type="checkbox" formControlName="inter_session" />
                                <span>Inter-Session (May-June)</span>
                            </label>
                            <label class="col s12 m6 l3">
                                <input type="checkbox" formControlName="summer_session" />
                                <span>Summer-Session (July-Aug)</span>
                            </label>
                        </div>
                    </div>
                    <div class="row col s12">
                    </div>
                </div>
            </div>

            <button class="col s12 m5 l3 btn left waves-effect blue" type="button" style="margin-bottom: 15px"
                (click)="onBack()"><i class="material-icons left">arrow_back</i>Back
            </button>

            <button class="col s12 m5 offset-m2 l3 btn right waves-effect blue" type="submit"
                [disabled]="!assistanceReqForm.valid" style="margin-bottom: 15px" title="Save">Next <i
                    class="material-icons right">arrow_forward</i></button>
        </form>

        <form *ngIf="step == 5 && !showSignaturePad" class="row col s12 card white" [formGroup]="declarationForm"
            (ngSubmit)="submitDeclaration(declarationForm.value)">
            <div class="row col s12 teal lighten-2 white-text">
                <h5 class="center-align">PART E: DECLARATION</h5>
            </div>
            <div class="row card-content">
                <p>I hereby undertake the following as conditions for sponsorship by the Post-Secondary Student Support
                    Program (PSSSP) of the Peter Ballantyne Cree Nation for the duration of my program of studies:</p>
                <ol>
                    <li>To attend classes on a regular basis.</li>
                    <li>To consult with my counselor if any problems arise academically, emotionally, physically or
                        financially.</li>
                    <li>To adhere to sponsorship regulations and meet the academic requirements for continuation for my
                        program of studies. </li>
                    <li>To provide my transcript of marks and report to PSSSP upon completion of each academic year.
                    </li>
                    <li>To adhere to sponsorship rules and regulations stated in the Post-Secondary Student Support
                        Handbook. </li>
                    <li>To consult with counselor of any changes of residence, dependents, etc. </li>
                    <li>I declare that the information provided by me on the application form is complete, correct and
                        is given in order to substantiate my entitlement for sponsorship. </li>
                    <li>
                        <p>I, </p>
                        <div class="input-field col s12">
                            <input id="name" type="text" formControlName="name" class="validate"
                                style="text-transform: capitalize;">
                            <label for="name">Applicant Name<span class="red-text">*</span></label>
                        </div>
                        <p>authorize the PSSSP to obtain any information required to determine my and/or
                            dependent(s) eligibility for sponsorship.</p>
                    </li>
                    <li>I hereby authorize the release of my mid-term/final marks, progress reports, and attendance
                        records.</li>
                    <li>I hereby give permission to the Peter Ballantyne Cree Nation Post-Secondary Support Program to
                        verify or confirm with any source the correctness and accuracy of the information contained in
                        this application. </li>
                </ol>

                <div class="row col s12 m6">
                    <p class="col s12">Signature<span class="red-text">*</span></p>
                    <a *ngIf="!signed" [ngClass]="'btn col s8 waves-effect ' + themeColor" (click)="sign()"
                        style="margin-top: 10px;">Sign</a>

                    <a *ngIf="signed" class="col s4" style="margin-top: 10px;"><i
                            class="material-icons left green-text small">check_circle</i>Signed</a>
                    <a *ngIf="signed" class="col s4 pink-text" style="margin-top: 10px;" (click)="clearSignature()"><i
                            class="material-icons left pink-text">clear</i>Clear</a>
                </div>

                <div class="input-field col s12 m6">
                    <i class="material-icons prefix blue-text">calendar_today</i>
                    <!-- <input *ngIf="!dates[4].set" id="date" type="text" formControlName="date" class="validate"
                        [textMask]="{mask: dateMask, guide: true}" (blur)="setDate(date,4, $event.target.value)"> -->
                        <input *ngIf="!dates[4].set" id="date" type="text" formControlName="date" class="validate"
                        (blur)="setDate(date,4, $event.target.value)">
                    <input *ngIf="dates[4].set" id="date" type="text" formControlName="date" class="validate"
                        (focus)="unSetDate(date, 4)">
                    <label for="date">Date (dd/mm/yyyy)<span class="red-text">*</span></label>
                </div>
            </div>

            <button class="col s12 m5 l3 btn left waves-effect blue" type="button" style="margin-bottom: 15px"
                (click)="onBack()"><i class="material-icons left">arrow_back</i>Back
            </button>

            <button class="col s12 m5 offset-m2 l3 btn right waves-effect blue" type="submit"
                [disabled]="!declarationForm.valid" style="margin-bottom: 15px" title="Save">Next <i
                    class="material-icons right">arrow_forward</i></button>
        </form>

        <div *ngIf="step == 5 && showSignaturePad" class="row card white" [formGroup]="declarationForm"
            (ngSubmit)="submitSection6(declarationForm.value)">

            <div class="row s12 card-panel grey lighten-4 center">
                <div class="row">
                    <h6 class="blue-text text-darken-2 center"><strong>Applicant'S SIGNATURE</strong></h6>
                    <canvas></canvas>
                </div>
            </div>

            <div class="section"></div>

            <a class="at-btn btn-flat center green lighten-1 col s12 white-text" (click)="approveSignature()"
                style="margin-bottom: 15px;"><i class="material-icons left">check</i> Sign</a>
            <br>
            <a class="at-btn btn-flat center red lighten-1 col s12 white-text" (click)="clearSignature()"
                style="margin-bottom: 15px;"><i class="material-icons left">loop</i> Clear Signature</a>
            <br>
            <a [ngClass]="'at-btn col s12 btn waves-effect ' + themeColor" (click)="onBack()"><i
                    class="material-icons left">arrow_back_ios</i> Back To My Application </a>
        </div>

        <form *ngIf="step == 6" class="row col s12" [formGroup]="otherInfoForm"
            (ngSubmit)="submitOtherInfo(otherInfoForm.value)">
            <div class="row col s12 teal lighten-2 white-text">
                <h5 class="center-align">PART F: OTHER INFORMATION</h5>
            </div>
            <br>
            <div class="row col s12">
                <div class="row col s12 card white">
                    <p>Please provide any other information that might assist Post-Secondary selection committee in
                        making
                        the application decision (Attach letter)</p>
                    <br>
                    <div class="row input-field col s12">
                        <div class="row col s12">
                            <p><strong>Would you like to upload Documents with your Application ?</strong></p>
                        </div>
                        <div class="row col s12">
                            <label class="col s6">
                                <input class="with-gap" name="upload_documents" formControlName="upload_documents"
                                    type="radio" value="yes" (click)="onUplodYes($event)" />
                                <span class="text"><strong>Yes</strong></span>
                            </label>
                            <label class="col s6">
                                <input class="with-gap" name="upload_documents" formControlName="upload_documents"
                                    type="radio" value="no" (click)="onUplodNo($event)" />
                                <span class="text"><strong>No</strong></span>
                            </label>
                        </div>
                    </div>
                </div>

                <div *ngIf="showUpload" class="row col s12 card white">

                    <div class="row col s12 card-content" *ngIf="documentsControls.length > 0">
                        <!-- <h6 *ngIf="!docsUploaded" class="card-title">Files ready to upload</h6> -->
                        <h6 class="card-title">Files Uploaded</h6>
                        <div style="min-height: 100px;;">
                            <ul class="collection">
                                <li class="row collection-item"
                                    *ngFor="let file of documentsControls.value; let i = index;">
                                    <div class="row col s11">
                                        <div class="col s12"><strong>
                                            </strong>{{file.type}} - {{file.name}}</div>
                                    </div>
                                    <div class="secondary-content col s1">
                                        <i class="material-icons red-text small" (click)="removeFile(i)"
                                            data-tooltip="I am a tooltip" style="cursor: pointer;">delete</i>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div *ngIf="!filesUploaded" class="row col s12 card-content">
                        <div class="input-field col s12">
                            <i class="material-icons prefix purple-text">description</i>
                            <select id="fileType" formControlName="document_type" class="validate"
                                (change)="docTypeChange($event.target.value)">
                                <option value="" disabled selected>Select a catagory</option>
                                <option *ngFor="let type of fileTypes" [value]="type">{{type}}</option>
                            </select>
                            <label for="document_type">Type of Document<span class="red-text">*</span></label>
                        </div>

                        <div class="col s12 file-field input-field">
                            <div class="btn">
                                <span>Choose File <i class="material-icons">file_upload
                                    </i></span>
                                <input type="file" formControlName="document" accept="image/*, application/pdf"
                                    (change)="handleFileInput($event.target.files)" />
                            </div>
                            <div class="file-path-wrapper">
                                <input class="file-path validate" type="text" placeholder="No File Choosen" />
                            </div>
                        </div>
                        <button [disabled]="!otherInfoForm.valid" class="col s12 btn green" style="margin-bottom: 15px;"
                            type="button" (click)="uploadFile(otherInfoForm.value)"><i
                                class="material-icons left">upload_file</i>Upload File</button>
                        <!-- [disabled]="!otherInfoForm.valid" -->

                        <button class="col s12 btn red" style="margin-bottom: 15px;" type="button"
                            (click)="closeDocUpload()"><i class="material-icons left">close</i>Close</button>
                    </div>

                    <div class="col s12" *ngIf="filesUploaded">
                        <button class="col s12 btn green" style="margin-bottom: 15px;" type="button"
                            (click)="addAnotherFile()"><i class="material-icons left">upload_file</i>Add another
                            file</button>
                    </div>
                </div>
            </div>

            <button class="col s12 m5 l3 btn left waves-effect blue" type="button" style="margin-bottom: 15px"
                (click)="onBack()"><i class="material-icons left">arrow_back</i>Back
            </button>

            <button class="col s12 m5 offset-m2 l3 btn right waves-effect blue" type="submit"
                style="margin-bottom: 15px" title="Save">Submit <i class="material-icons right">save</i></button>
        </form>

        <div *ngIf="step == 7" class="row col s12">
            <div class="teal lighten-2 white-text" style="margin-top: 25px; padding-top: 5px; padding-bottom: 5px;">
                <h5 class="center-align">Preview Application</h5>
            </div>

            <div class="row card white">
                <div class=" row  col s12 right">

                    <button class="btn tooltipped" data-position="bottom" data-tooltip="Open as a PDF" type="button"
                        (click)="openPdf()" style="margin:5px;"><i class="material-icons">open_in_new</i></button>
                    <button class="btn blue tooltipped" data-position="bottom" data-tooltip="Download PDF" type="button"
                        (click)="downloadPdf()" style="margin:5px;"><i class="material-icons blue">download</i></button>
                    <button class="btn green tooltipped" data-position="bottom" data-tooltip="Print PDF" type="button"
                        (click)="printPdf()" style="margin:5px;"> <i class="material-icons">print</i></button>
                </div>
                <div class="section"></div>
                <ul class="row col s12 collection">
                    <li class="section row collection-item">
                        <div class="col s12">
                            <h5 class="col s8"><strong>STUDENT INFORMATION </strong></h5>
                            <a class="col s4 blue-text right waves-effect waves-light btn-flat" (click)="editSection(1)"
                                style="cursor: pointer;"><i class="material-icons left">edit</i>Edit</a>
                        </div>
                        <div class="col s12">
                            <p class="col s12 m6"> <strong>First Name : </strong> {{model.firstName}}</p>
                            <p class="col s12 m6"> <strong>Last Name : </strong>{{model.lastName}} </p>
                            <p class="col s12 m6"> <strong>Middle Name : </strong> {{model.middleName}}</p>
                            <h6 class="col s12"><strong>MAILING ADDRESS</strong> </h6>
                            <p class="col s12 m6"> <strong>Street : </strong> {{model.street}}</p>
                            <p class="col s12 m6"> <strong>Apartment : </strong> {{model.apt}}</p>
                            <p class="col s12 m6"> <strong>City : </strong> {{model.city}}</p>
                            <p class="col s12 m6"> <strong>Province : </strong>{{model.province}} </p>
                            <p class="col s12 m6"> <strong>Postal Code : </strong>{{model.postalCode}} </p>
                            <h6 class="col s12"><strong>REQUIRED INFORMATION</strong></h6>
                            <p class="col s12 m6"> <strong>Home Number :</strong>{{model.homeNumber}} </p>
                            <p class="col s12 m6"> <strong>Mobile Number : </strong> {{model.mobile}}</p>
                            <p class="col s12 m6"> <strong>Date of Birth : </strong> {{model.dob | date: 'full'}}</p>
                            <p class="col s12 m6"> <strong>SIN : </strong> {{model.SIN}}</p>
                            <p class="col s12 m6"> <strong>Student Number : </strong>{{model.studentNo}} </p>
                            <p class="col s12 m6"> <strong>Treaty Number : </strong> {{model.treatyNo}}</p>
                            <p class="col s12 m6"> <strong>Email : </strong>{{model.email}} </p>
                            <p class="col s12 m6"> <strong>Marital Status : </strong>{{maritalStatusValue}} </p>
                        </div>
                    </li>
                    <li class="section row collection-item"
                        *ngIf="maritalStatusSelected == '2' || maritalStatusSelected == '3'">
                        <div class="col s12">
                            <h5 class="col s8"><strong>SPOUSE INFORMATION</strong></h5>
                            <a class="col s4 blue-text right waves-effect waves-light btn-flat" (click)="editSection(2)"
                                style="cursor: pointer;"><i class="material-icons left">edit</i>Edit</a>
                        </div>
                        <div class="col s12">
                            <p class="col s12 m6"> <strong>First Name : </strong> {{model.spouseFirstName}}</p>
                            <p class="col s12 m6"> <strong>Last Name : </strong>{{model.spouseLastName}} </p>
                            <p class="col s12 m6"> <strong>Middle Name : </strong>{{model.spouseMiddleName}}</p>
                            <p class="col s12"> <strong>Will your spouse be living with you during the time of your
                                    funding : </strong>{{model.spouseLive}} </p>
                            <p class="col s12"> <strong>Will your spouse be employed? funded student? :
                                </strong>{{model.spouseEmployed}} </p>
                        </div>
                    </li>
                    <li class="section row collection-item" *ngIf="maritalStatusSelected != '0'">
                        <div class="col s12">
                            <h5 class="col s8"><strong>DEPENDENTS INFORMATION

                                </strong></h5>
                            <a class="col s4 blue-text right waves-effect waves-light btn-flat" (click)="editSection(3)"
                                style="cursor: pointer;"><i class="material-icons left">edit</i>Edit</a>
                        </div>

                        <p class="col s12" *ngIf="model.dependents?.length==0"> No Children Added</p>

                        <div class="row col s12" *ngIf="model.dependents?.length > 0"></div>
                        <div class="section row col s12" *ngFor="let child of model.dependents; let i=index;">
                            <div class="divider"></div>
                            <p class="col s12 m6"><strong>First Name : </strong>{{child.first_name}}</p>
                            <p class="col s12 m6"><strong>Last Name : </strong>{{child.last_name}} </p>
                            <p class="col s12 m6"> <strong>Date of Birth :</strong> {{child.dob}}</p>
                            <p class="col s12 m6"><strong>Age : </strong>{{child.age}} </p>
                            <p class="col s12 m6"> <strong>Grade :</strong> {{child.grade}}</p>
                            <p class="col s12 m6"> <strong>School :</strong> {{child.school}}</p>
                            <p class="col s12 m6"> <strong>Comment :</strong> {{child.comment}}</p>
                        </div>
                    </li>
                    <li class="section row collection-item">
                        <div class="col s12">
                            <h5 class="col s8"><strong>ASSISTANCE REQUIRED</strong></h5>
                            <a class="col s4 blue-text right waves-effect waves-light btn-flat" (click)="editSection(4)"
                                style="cursor: pointer;"><i class="material-icons left">edit</i>Edit</a>
                        </div>
                        <div class="col s12">
                            <p class="col s12 m6"> <strong>Assistance Type : </strong> {{model.assistanceType}}</p>
                            <p class="col s12 m6"> <strong>Institution Name : </strong>{{model.institution}} </p>
                            <p class="col s12 m6"> <strong>Location : </strong>{{model.location}}</p>
                            <p class="col s12 m6"> <strong>Program : </strong>{{model.program}} </p>
                            <p class="col s12 m6"> <strong>Starts On : </strong>{{model.startDate}} </p>
                            <p class="col s12 m6"> <strong>End On : </strong>{{model.endDate}} </p>
                            <p class="col s12 m6"> <strong>Start Date : </strong>{{model.startDate}} </p>
                            <p class="col s12 m6"> <strong>Program Length in Years : </strong>{{model.length}} </p>
                            <p class="col s12 m6"> <strong>Years Completed : </strong>{{model.yearsCompleted}} </p>
                            <h6 class="col s12"><strong>Terms</strong> </h6>
                            <p class="col s12">
                                <strong>Fall : </strong>
                                <a *ngIf="model.terms.fall">
                                    <i class="material-icons green-text">check</i></a>
                                <a *ngIf="!model.terms.fall">
                                    <i class="material-icons red-text">cancel</i></a>
                            </p>
                            <p class="col s12"> <strong>Winter : </strong>
                                <a *ngIf="model.terms.winter">
                                    <i class="material-icons green-text">check</i></a>
                                <a *ngIf="!model.terms.winter">
                                    <i class="material-icons red-text">cancel</i></a>
                            </p>
                            <p class="col s12"> <strong>Inter-Session : </strong>
                                <a *ngIf="model.terms.inter_session">
                                    <i class="material-icons green-text">check</i></a>
                                <a *ngIf="!model.terms.inter_session">
                                    <i class="material-icons red-text">cancel</i></a>
                            </p>
                            <p class="col s12"> <strong>Summer-Session : </strong>
                                <a *ngIf="model.terms.summer_session">
                                    <i class="material-icons green-text">check</i></a>
                                <a *ngIf="!model.terms.summer_session">
                                    <i class="material-icons red-text">cancel</i></a>
                            </p>
                        </div>
                    </li>
                    <li class="section row collection-item" *ngIf="documentsControls.length > 0">
                        <div class="col s12">
                            <h5 class="col s8"><strong>OTHER INFORMATION</strong></h5>
                            <a class="col s4 blue-text right waves-effect waves-light btn-flat" (click)="editSection(6)"
                                style="cursor: pointer;"><i class="material-icons left">edit</i>Edit</a>
                        </div>
                        <div class="col s12" *ngFor="let doc of model.documents; let i=index;">
                            <p class="col s12 m6"><strong>{{doc.name}} - </strong>{{doc.type}}</p>
                            <a [href]="doc.link" target="_blank"><i class="material-icons">
                                    open_in_new
                                </i></a>
                        </div>
                    </li>
                </ul>
                <div class="row col s12">
                    <button type="button" (click)="onBack()" class="col s12 m4  waves-effect blue btn">Back to
                        Application<i class="material-icons right">Back to The Application</i></button>
                    <button type="button" (click)="SubmitApplication()"
                        class="col s12 m4 offset-m4 waves-effect green btn">Submit<i
                            class="material-icons right">send</i></button>
                </div>
            </div>
        </div>
    </div>
</div>

<app-firebase-loading *ngIf="loading"></app-firebase-loading>