import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { EmailService } from 'src/app/services/api/email.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { ContactService } from 'src/app/services/firebase/contact.service';
import { DepartmentService } from 'src/app/services/firebase/department.service';
import { GovernanceService } from 'src/app/services/firebase/governance.service';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';
import { toast } from 'materialize-css';
import { EmailModel } from 'src/app/models/emailModel';

declare var $: any;

@Component({
  selector: 'app-contact-editor',
  templateUrl: './contact-Editor.component.html',
  styleUrls: ['./contact-Editor.component.css']
})

export class ContactEditorComponent implements OnInit {

  @Input() contactType: string;

  governanceModel: any;
  showDepList = false;
  departmentsList: any[];
  showEmpList = false;
  employeeList: any[];
  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  contactForm: UntypedFormGroup;
  step1 = true;
  step2 = false;
  selectedCount = 0;
  showContactForm = false;
  showCouncillorsList = false;
  showChiefsList = false;
  selectableCouncillorsList: any[];
  chiefsList: any[];
  contactOption = '';
  modalHeader = '';
  selectedEmployee: any;
  formatedWorkPhone: any;
  formatedMobilePhone: any;
  showMsg = false;
  selectedDepName = '';
  selectedDepartment: any;

  constructor(
    private location: Location,
    private contactService: ContactService,
    private emailService: EmailService,
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private depService: DepartmentService,
    private govService: GovernanceService) { }

  ngOnInit() {

    this.contactForm = this.fb.group({
      subject: ['', [Validators.required]],
      body: ['', [Validators.required]],
    });

    this.depService.getDepartmentList().valueChanges().subscribe(x => {
      if (x) {
        this.departmentsList = x;
      }
    });

    this.govService.getGovernanceList().valueChanges().subscribe(
      gov => {
        if (gov.length > 0) {
          this.governanceModel = gov[0];
          this.selectableCouncillorsList = [];
          this.chiefsList = [];
          this.governanceModel.councillors
            .forEach(e => {
              const option = {
                id: e.id,
                firstName: e.firstName,
                lastName: e.lastName,
                email: e.email,
                allowContact: e.allowContact,
                isSelected: false,
                showMobile: e.showMobile,
                showEmail: e.showEmail,
                showOfficePhone: e.showOfficePhone,
                councillorFacebookAcc: e.councillorFacebookAcc,
                councillorInstagramAcc: e.councillorInstagramAcc,
                councillorTwitterAcc: e.councillorTwitterAcc,
                councillorLinkedinAcc: e.councillorLinkedinAcc,
                councillorYoutubeAcc: e.councillorYoutubeAcc,
                showFacebookAcc: e.showFacebookAcc,
                showTwitterAcc: e.showTwitterAcc,
                showInstagramAcc: e.showInstagramAcc,
                showLinkedinAcc: e.showLinkedinAcc,
                showYoutubeAcc: e.showYoutubeAcc,
              };
              this.selectableCouncillorsList.push(option);
            });
          this.governanceModel.chiefs
            .forEach(c => {
              const chiefOption = {
                id: c.id,
                firstName: c.firstName,
                lastName: c.lastName,
                email: c.email,
                allowContact: c.allowContact,
                isSelected: false,
                showMobile: c.showMobile,
                showEmail: c.showEmail,
                showOfficePhone: c.showOfficePhone,
                chiefFacebookAcc: c.chiefFacebookAcc,
                chiefInstagramAcc: c.chiefInstagramAcc,
                chiefTwitterAcc: c.chiefTwitterAcc,
                chiefLinkedinAcc: c.chiefLinkedinAcc,
                chiefYoutubeAcc: c.chiefYoutubeAcc,
                showFacebookAcc: c.showFacebookAcc,
                showTwitterAcc: c.showTwitterAcc,
                showInstagramAcc: c.showInstagramAcc,
                showLinkedinAcc: c.showLinkedinAcc,
                showYoutubeAcc: c.showYoutubeAcc,
              };
              this.chiefsList.push(chiefOption);
            });
        }
      }
    );

    setTimeout(() => {
      $('select').formSelect();
      $('.modal').modal();
    }, 25);
  }

  contactOptionChange(selectedValue: any) {
    this.employeeList = []; // to clear the ui when option changes
    if (selectedValue === 'Department') {
      this.contactOption = selectedValue;
      this.showDepList = true;
      this.showContactForm = false;
      this.showCouncillorsList = false;
      this.showChiefsList = false;
      this.selectedDepartment = undefined;
      setTimeout(() => {
        $('select').formSelect();
      }, 25);
    }
    if (selectedValue === 'Councillor') {
      this.contactOption = selectedValue;
      this.showDepList = false;
      this.showContactForm = false;
      this.showCouncillorsList = true;
      this.showChiefsList = false;
      this.selectedDepartment = undefined;
    }
    if (selectedValue === 'Chief') {
      this.contactOption = selectedValue;
      this.contactForm = this.fb.group({
        subject: ['', [Validators.required]],
        body: ['', [Validators.required]],
      });
      this.showDepList = false;
      this.showContactForm = false;
      this.showCouncillorsList = false;
      this.showContactForm = true;
      this.showChiefsList = true;
      this.selectedDepartment = undefined;
    }
  }

  councillorChecked() {
    this.selectedCount = 0;
    this.selectableCouncillorsList.forEach(element => {
      if (element.isSelected) {
        this.selectedCount += 1;
      }
    });
    if (this.selectedCount > 0) {
      this.contactForm = this.fb.group({
        subject: ['', [Validators.required]],
        body: ['', [Validators.required]],
      });
      this.showContactForm = true;
    } else {
      this.showContactForm = false;
    }
  }

  get subject() { return this.contactForm.get('subject'); }
  get body() { return this.contactForm.get('body'); }

  departmentOptionChange(selectedValue: any) {
    if (selectedValue) {
      if (this.contactType !== 'PublicMessage') {
        this.depService.getDepartmentEmployeeList(selectedValue).valueChanges().subscribe(
          emp => {
            if (emp) {
              if (emp.length > 0) {
                this.employeeList = emp;
                this.showMsg = false;
              } else {
                let dep: any;
                this.departmentsList.forEach(element => {
                  if (element.id === selectedValue) {
                    dep = element;
                    this.selectedDepName = dep.name.replace(/<[^>]*>/g, '');
                  }
                });
                this.employeeList = [];
                this.showMsg = true;
              }
            } else { this.showMsg = true; }
          }
        );
      } else (this.contactType === 'PublicMessage')
      {
        this.selectedDepartment = this.departmentsList.filter(d => d.id === selectedValue);
      }
    }
  }

  showMobile(emp: any) {
    this.modalHeader = 'Mobile Number';
    this.selectedEmployee = emp;
    this.formatedMobilePhone = emp.mobilePhone.replace('(', '');
    this.formatedMobilePhone = this.formatedMobilePhone.replace(')', '');
    this.formatedMobilePhone = this.formatedMobilePhone.replace('-', '');
    this.formatedMobilePhone = this.formatedMobilePhone.replace(/\s/g, '');
    setTimeout(() => {
      $('.modal').modal();
      $('#mobilePhoneModal').modal('open');
    }, 25);
  }

  formatPhoneNumber(phone: any) {
    let formatedNumber = phone;
    formatedNumber = formatedNumber.replace('(', '');
    formatedNumber = formatedNumber.replace(')', '');
    formatedNumber = formatedNumber.replace('-', '');
    formatedNumber = formatedNumber.replace(/\s/g, '');
    return formatedNumber;
  }

  showWorkPhone(emp: any) {
    this.modalHeader = 'Work Phone';
    this.selectedEmployee = emp;
    this.formatedWorkPhone = emp.workPhone.replace('(', '');
    this.formatedWorkPhone = this.formatedWorkPhone.replace(')', '');
    this.formatedWorkPhone = this.formatedWorkPhone.replace('-', '');
    this.formatedWorkPhone = this.formatedWorkPhone.replace(/\s/g, '');
    setTimeout(() => {
      $('.modal').modal();
      $('#workPhoneModal').modal('open');
    }, 25);
  }

  showEmailModal(emp: any) {
    this.modalHeader = 'Send Email';
    this.selectedEmployee = emp;
    this.contactForm = this.fb.group({
      subject: ['', [Validators.required]],
      body: ['', [Validators.required]],
    });
    this.showContactForm = true;
    setTimeout(() => {
      $('.modal').modal();
      $('#emailModal').modal('open');
    }, 25);
  }

  Send(msg: any) {
    const toList = [];
    const mail = new EmailModel();
    mail.emailList = toList;
    mail.subject = 'New Contact Message';
    mail.body = '<strong> You Received A New Email Please Login To Your Account </strong>';

    if (this.contactOption === 'Department') {
      const outboxMsg = {
        id: '',
        discussionID: '',
        departmentID: this.selectedEmployee.departmentId,
        toID: this.selectedEmployee.uid,
        toEmail: this.selectedEmployee.email,
        toName: this.selectedEmployee.givenName + ' ' + this.selectedEmployee.lastName,
        fromID: this.authService.getCurrentUser().uid,
        fromEmail: this.authService.getCurrentUser().email,
        fromName: localStorage.getItem('userFirstName') + ' ' + localStorage.getItem('userLastName'),
        subject: msg.subject,
        body: msg.body,
        dateSent: new Date(),
        isRead: false,
        responses: []
      };
      toList.push(this.selectedEmployee.email);
      $('#emailModal').modal('close');
      this.contactService.sendMessage(outboxMsg).then(
        () => {
          this.emailService.sendEmail(mail).subscribe(e => {
            if (e) {
              // this.step1 = false;
              toast({ html: '<i class="material-icons">lock</i> Email Successfully Sent.', classes: 'green' });
            }
          });
        });
    }
  }

  backClicked() {
    this.location.back();
  }

}
