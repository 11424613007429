<div class="row" *ngIf="viewModel">
  <div *ngFor="let board of viewModel; let i=index" class="row col s12 l4">
    <div class="card">
      <div class="card-title">{{board.name}}</div>
      <div *ngIf="board.logo" class="col s4 m4 l4 card-image left">
        <!-- If there is a logo, set the image size to l4 -->
        <img [src]="board.logo" class="card-img center circle responsive-img" alt=""
          style="width: 100px; height: 100px; border-radius: 0%; margin-top: 25%; margin-left: auto; margin-right: auto; display: block; ">
      </div>
      <div [ngClass]="{'col s8 m8 l8': board.logo, 'col s12 l12': !board.logo}" class="card-content">
        <!-- If there is no logo, set the card-content size to l12 -->
        <div *ngIf="!showMore[i] && board.mandate.length > 100">
          <p class="text-content body-snippet">{{truncateHTML(board.mandate, 100)}}</p>
          <p class="read-more1">
            <a (click)="readMore(i)">Read More >></a>
          </p>
        </div>
        <div *ngIf="showMore[i] || board.mandate.length <= 100">
          <p class="text-content">{{board.mandate}}</p>
          <p class="read-more1" *ngIf="showMore[i] && board.mandate.length > 100">
            <a (click)="readMore(i)">Read Less >></a>
          </p>
        </div>
      </div>
      <div class="card-tabs">
        <ul class="tabs tabs-fixed-width">
          <li class="tab" *ngIf="board.email"><a href="#email{{i}}">Email</a></li>
          <li class="tab" *ngIf="board.phone"><a href="#phone{{i}}">Phone</a></li>
          <li class="tab" *ngIf="board.website"><a href="#website{{i}}">Website</a></li>
        </ul>
      </div>

      <div class="card-content grey lighten-4">
        <div id="email{{i}}">
          <a [href]="'mailto:' + board.email">
            {{board.email}}
          </a>
        </div>
        <div id="phone{{i}}">
          <a [href]="'tel:' + board.phone">
            {{board.phone}}
          </a>
        </div>
        <div id="website{{i}}">
          <a [href]="board.website" target="_blank" class="blue-text" style="text-transform: none;">
            {{board.website}}
          </a>
        </div>
      </div>
    </div>
  </div>
</div>