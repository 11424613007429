import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/compat/firestore';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminDemoRequestService {

  adminListRef: AngularFirestoreCollection<any>;
  adminRef: AngularFirestoreDocument<any>;

  constructor(private db: AngularFirestore) { }

  newAdminRequest(model: any)  {
    model.id = this.db.createId();
    return this.db.collection('Demo-Account-Request').doc(model.id).set(model);
  }

  getAdminRequestByID(id: string): AngularFirestoreDocument<any> {
    this.adminRef = this.db.doc('Demo-Account-Request/' + id);
    return this.adminRef;
  }

  getAdminRequestList(): AngularFirestoreCollection<any[]> {
    this.adminListRef = this.db.collection('Demo-Account-Request');
    return this.adminListRef;
  }

  ApproveRequest(model: any): any {
    return this.db.collection('Demo-Account-Request').doc(model.id).update(model);
  }

  DeclineRequest(model: any) {
    return this.db.collection('Demo-Account-Request').doc(model.id).delete();
  }

}
