<div class="row center-align" *ngIf="model">
    <br />
    <h6><strong> {{model.name}} </strong></h6>
</div>

<div class="row col s12">
    <div class="col l1 "></div>
    <div class="col s12 m12 l10" *ngIf="model">
        <app-board-detail-shared [model]="model"></app-board-detail-shared>
    </div>
    <div class="col l1 "></div>
</div>