import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AreaOfInterestService } from 'src/app/services/firebase/area-of-interest.service';
import { environment } from 'src/environments/environment';
import { toast } from 'materialize-css';
import { Location } from '@angular/common';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { Router } from '@angular/router';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-area-of-interest-admin',
  templateUrl: './area-of-interest-admin.component.html',
  styleUrls: ['./area-of-interest-admin.component.css']
})
export class AreaOfInterestAdminComponent implements OnInit {
  
  defaultThemeColor = environment.appTheme.themeColor;
  themeColor: any;
  buttonType: any;
  areasListModel: any[];
  selectedModel: any;
  areaOfInterestForm: UntypedFormGroup;
  showMsg = false;
  sectionName: string;

  constructor(
    private areaService: AreaOfInterestService,
    private formBuilder: UntypedFormBuilder,
    private location: Location,
    private appSettingsService: AppSettingsService,
    private router: Router) {
    this.themeColor = this.appSettingsService.getAppThemeColor() ? this.appSettingsService.getAppThemeColor() : this.defaultThemeColor;
    this.buttonType = this.appSettingsService.getButtonType() ? this.appSettingsService.getButtonType() : 'rectangularButton';
  }

  ngOnInit() {
    this.appSettingsService.getMenuName('MyNation').valueChanges().subscribe(menus => {
      if (menus && menus.length > 0) {
        let submenus = menus[0].submenus;
        let menu = submenus.filter(m => m.name == 'AreaOfInterest');
        if (menu && menu.length > 0) this.sectionName = menu[0].displayName;
      }
    });
    this.areaOfInterestForm = this.formBuilder.group({
      name: ['', [Validators.required]],
    });
    this.areaService.getAreasList().valueChanges().subscribe(a => {
      if (a) {
        this.areasListModel = a;
        this.showMsg = true;
      }
    });
  }

  get name() { return this.areaOfInterestForm.get('name'); }

  addArea() {
    this.selectedModel = undefined;
    this.areaOfInterestForm.patchValue({ 'name': '' });
    setTimeout(() => {
      $('.modal').modal();
      $('#editModal').modal('open');
    }, 25);
  }

  selectArea(area: any) {
    if (area) {
      this.selectedModel = area;
      this.areaOfInterestForm.patchValue({ 'name': area.name });
      setTimeout(() => {
        $('.modal').modal();
        M.updateTextFields();
        $('#editModal').modal('open');
      }, 25);
    }
  }

  manageAreasOfInterest(model: any) {
    // if ! then add
    if (!this.selectedModel) {
      const newArea = {
        id: '',
        name: model.name,
        membersCount: 0
      };

      this.areaService.saveArea(newArea).then(saved => {
        toast({ html: 'Area Of Interest Successfully Saved', classes: 'green' });
        $('#editModal').modal('close');
      });

    } else {
      // if === then update
      const newArea = {
        id: this.selectedModel.id,
        name: model.name,
        membersCount: this.selectedModel.membersCount
      };

      this.areaService.updateArea(newArea).then(saved => {
        toast({ html: 'Area Of Interest Successfully Saved', classes: 'green' });
        $('#editModal').modal('close');
      });
    }
  }

  navigate(item) {
    const routeMappings = {
      'AboutUs': 'admin/dashboard-sub-menu/about-us',
      'NationInfo': 'admin/dashboard-sub-menu/nation-settings',
      'Departments': 'admin/dashboard-sub-menu/department',
      'Governance': 'admin/dashboard-sub-menu/governance',
      'AreaOfInterest': 'admin/dashboard-sub-menu/areas-of-interest',
    };
    const route = routeMappings[item];
    if (route) {
      this.router.navigate([route]);
    }
  }

  backClicked() {
    this.location.back();
  }

}
