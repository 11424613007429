<div class="row col s12 p-m-2">
  <div class="col l1 "></div>
  <div class="row" *ngIf="nominationObject && alreadyNominated">
    <div class="col s12 m6">
      <div class="card">
        <div class="card-content black-text">
          <div class="center red lighten-4">
            <br />
            <h6>You have already nominated for a <strong>{{nominationObject.name}}!</strong></h6>
            <br />
          </div>
          <br />
          <div class="center ">
            <button class="btn light-blue darken-3" (click)="backClicked()">Okay</button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col s12 l10" *ngIf="nominationObject && !alreadyNominated">
    <div class="row center-align">
      <h6><strong> Select A Nominee </strong></h6>
    </div>

    <form (ngSubmit)="submitForm(nominateForm.value)" [formGroup]="nominateForm">
      <div class="row card-panel white">
        <h6 class="center" style="margin-bottom: 25px; margin-top: 15px;"><strong>Nomination Name:
            {{nominationObject.name}}</strong></h6>
        <div class="row  col s12">

          <div class="row  card-panel">
            <div class="row">
              <p class="center orange-text text-darken-2"><i class="material-icons ">info</i> Important
              </p>
              <p class="center"> <strong>You can only nominate once, Make sure you are choosing the
                  correct person.</strong> </p>
            </div>
            <div class="col s7">
              <span><strong>Nomination start date: </strong> {{nominationObject.startDate}}</span>
            </div>
            <div class="col s5">
              <span><strong>Nomination End date: </strong> {{nominationObject.endDate}}</span>
            </div>
          </div>
          <br />
          <div class="row">
            <div class="input-field col s12">
              <select formControlName="selectedNominee" id="selectedNominee">
                <option value="" disabled selected>Choose your option</option>

              </select>
              <label for="selectedNominee">Chooose Nominee: </label>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <button class='at-btn col s12 btn waves-effect success' type="submit" [disabled]="!nominateForm.valid">
          <i class="material-icons left">how_to_reg</i> Save Nomination
        </button>
      </div>
    </form>
  </div>
  <div class="row">
    <button type="button" class="btn col s12 blue" (click)="backClicked()"> <i
        class="material-icons left">arrow_back_ios</i> Back To Previous Page </button>
  </div>

</div>