import { Component, OnInit, Input } from '@angular/core';
import { InterventionModel } from 'src/app/models/InterventionModel';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-isets-intervention',
  templateUrl: './isets-intervention.component.html',
  styleUrls: ['./isets-intervention.component.css']
})
export class IsetsInterventionComponent implements OnInit {

  @Input() model: InterventionModel;  
  nation = environment.firstNation.name;
  //isDemo = false;
  //isLL = false;
  constructor() {
   // if(this.nation === 'Demo'){this.isDemo = true;}
   // if(this.nation === 'LongLake'){this.isLL = true;}
   }

  ngOnInit(): void {
  }

}
