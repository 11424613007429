import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { DepartmentService } from 'src/app/services/firebase/department.service';
import { FireMembersService } from 'src/app/services/firebase/fire-members.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-sub-department',
  templateUrl: './sub-department.component.html',
  styleUrls: ['./sub-department.component.css']
})

export class SubDepartmentComponent implements OnInit {

  employeeID: any;
  departmentId: any;
  subDepartments: any[];
  departmentDetail: any;
  depList = true;
  depDetail = false;

  constructor(private authService: AuthService,
    private memberService: FireMembersService,
    private depService: DepartmentService,
    private location: Location,) { }

  ngOnInit(): void {
    if (this.authService.getGlobalUser()) {
      this.employeeID = this.authService.getGlobalUser().uid;
      this.departmentId = this.authService.getGlobalUser().departmentId;
      if (this.departmentId) {
        this.depService.getDepartmentByID(this.departmentId).valueChanges().subscribe(dep => {
          if (dep) {
          }
        })
      }
    } else {
      this.employeeID = this.authService.getCurrentUser().uid;
      this.memberService.getMemberByID(this.employeeID).valueChanges().subscribe(member => {
        if (member) {
          this.departmentId = member.departmentId;
          if (this.departmentId) {
            this.depService.getSubDepartmentList(this.departmentId).valueChanges().subscribe(dep => {
              if (dep && dep.length > 0) {
                this.subDepartments = dep;
                this.subDepartments.sort((a, b) => (a.index < b.index ? -1 : 1));
              }
            });
          }
        }
      });
    }
  }

  showDepDetail(index) {
    this.departmentDetail = this.subDepartments[index];
    this.depList = false;
    this.depDetail = true;
  }

  backToList() {
    this.depList = true;
    this.depDetail = false;
  }

  backClicked() {
    this.location.back();
  }

}