<div *ngIf="step1 || step2" class="row center-align p-m-2">
  <h6><strong class="red-text"> Welcome to Oteenow Employment & Training Society</strong></h6>
</div>

<div class="row col s12">
  <div class="col l2"></div>
  <div class="col s12 l8">
    <form [formGroup]="requestTojoinForm" (ngSubmit)="SubmitRequest(requestTojoinForm.value)">
      <div class="row card-panel white darken-2" *ngIf="step1">

        <div class="row">
          <div class="input-field col s12">
            <input id="lastName" type="text" formControlName='lastName' required class="capitalize validate"
              autocomplete="off" />
            <label for="lastName">Last Name<strong class="red-text">*</strong></label>
            <div *ngIf="(lastName.dirty || lastName.touched) && lastName.invalid">
              <small *ngIf="lastName.errors.required" class="red-text"> Last name is required. </small>
            </div>
          </div>

          <div class="input-field col s12">
            <input id="firstName" type="text" formControlName='firstName' required class="capitalize validate"
              autocomplete="off" />
            <label for="firstName">First Name<strong class="red-text">*</strong></label>
            <div *ngIf="(firstName.dirty || firstName.touched) && firstName.invalid">
              <small *ngIf="firstName.errors.required" class="red-text"> First name is required. </small>
            </div>
          </div>

          <div class="input-field col s12">
            <input id="middle_initial" type="text" formControlName='middle_initial' class="capitalize"
              autocomplete="off" onKeyPress="if(this.value.length > 1 ) return false;" />
            <label for="middle_initial">Middle Initial</label>
          </div>

          <div class="row">
            <p class="red-text center"><strong>Please Select Month then Year Then Day And Click Ok</strong></p>
            <div class="input-field col s12">
              <input id="dob" type="text" class="datepicker1" (change)="BirthDateChange($event)" formControlName='dob'
                autocomplete="off" />
              <label for="dob">Date Of Birth <span class="red-text">*</span></label>
              <div *ngIf="(dob.dirty || dob.touched) && dob.invalid">
                <small *ngIf="dob.errors.required" class="red-text"> The Date Of Birth is required. </small>
              </div>
            </div>
          </div>

          <div class="row input-field col s12">
            <div class="row col s12"> Please Select a Gender <strong class="red-text">*</strong></div>
            <div class="row col s12">
              <label class="col s4">
                <input class="with-gap" formControlName="gender" name="gender" type="radio" checked="checked"
                  value="Male" />
                <span>Male</span>
              </label>
              <label class="col s4">
                <input class="with-gap" formControlName="gender" name="gender" type="radio" value="Female" />
                <span>Female</span>
              </label>
              <label class="col s4">
                <input class="with-gap" formControlName="gender" name="gender" type="radio" value="Other" />
                <span>Other</span>
              </label>
            </div>
          </div>

          <div class="input-field col s12">
            <!-- <input id="sin" type="text" formControlName='sin' [textMask]="{mask: sinMask}"
              onkeyup="var start = this.selectionStart; var end = this.selectionEnd;  this.setSelectionRange(start, end);"
              autocomplete="off" /> -->
              <input id="sin" type="text" formControlName='sin' 
              onkeyup="var start = this.selectionStart; var end = this.selectionEnd;  this.setSelectionRange(start, end);"
              autocomplete="off" />
            <label for="sin">Social Insurance Number (Optional)</label>
          </div>

          <div>
            <div class="input-field col s12">
              <label for="address">Home Address: Street <strong class="red-text">*</strong></label>
            </div>
            <div class="input-field col s12">

              <input id="address" type="text" formControlName='address' required class="capitalize validate"
                autocomplete="off" />
              <div *ngIf="(address.dirty || address.touched) && address.invalid">
                <small *ngIf="address.errors.required" class="red-text"> The addrerss is required. </small>
              </div>
            </div>
          </div>

          <div>
            <div class="input-field col s12">
              <label for="city">City <strong class="red-text">*</strong></label>
            </div>
            <div class="input-field col s12">
              <input id="city" type="text" formControlName='city' required class="capitalize validate"
                [(ngModel)]='default_city' autocomplete="off" />
              <div *ngIf="(city.dirty || city.touched) && city.invalid">
                <small *ngIf="city.errors.required" class="red-text"> The city is required. </small>
              </div>
            </div>
          </div>

          <div>
            <div class="input-field col s12">
              <label for="province">Please Select a Province <strong class="red-text">*</strong></label>
            </div>
            <div class="input-field col s12">
              <select formControlName="province" [(ngModel)]='default_province' required class="capitalize validate">
                <option *ngFor="let t of provinces" [value]="t">{{t}}</option>
              </select>
            </div>
          </div>

          <div class="input-field col s12">
            <!-- <input id="postcode" type="text" formControlName='postcode' required class="validate"
              [textMask]="{mask: postalCodeMask}"
              onkeyup="var start = this.selectionStart; var end = this.selectionEnd; this.value = this.value.toUpperCase(); this.setSelectionRange(start, end); "
              autocomplete="off" /> -->
              <input id="postcode" type="text" formControlName='postcode' required class="validate"
             
              onkeyup="var start = this.selectionStart; var end = this.selectionEnd; this.value = this.value.toUpperCase(); this.setSelectionRange(start, end); "
              autocomplete="off" />

            <label for="postcode">Postal Code <strong class="red-text">*</strong></label>
            <div *ngIf="(postcode.dirty || postcode.touched) && postcode.invalid">
              <small *ngIf="postcode.errors.required" class="red-text"> The postal code is required. </small>
            </div>
          </div>

          <div class="row input-field col s12">
            <div class="row col s12"> Mailing Address Same AS Home Address ? <strong class="red-text">*</strong></div>

            <div class="row col s12">
              <label class="col s4">
                <input class="with-gap" formControlName="same_address" name="same_address" type="radio"
                  checked="checked" value="yes" (change)="onMailingAddressYes($event)" />
                <span>Yes</span>
              </label>
              <label class="col s4">
                <input class="with-gap" formControlName="same_address" name="same_address" type="radio" value="no"
                  (change)="onMailingAddressNo($event)" />
                <span>No</span>
              </label>
            </div>
          </div>

          <div class="input-field col s12" *ngIf="showMailingAddress">
            <input id="mailing_address" type="text" formControlName='mailing_address' class="capitalize"
              [(ngModel)]='default_mailing_address' autocomplete="off" />
            <label for="mailing_address">Mailing Address: Street</label>
          </div>

          <div class="input-field col s12" *ngIf="showMailingAddress">
            <input id="mailing_city" type="text" formControlName='mailing_city' class="capitalize"
              [(ngModel)]='default_mailing_city' autocomplete="off" />
            <label for="mailing_city">City</label>
          </div>

          <div class="input-field col s12" *ngIf="showMailingAddress">
            <select class="form-control" formControlName="mailing_province" id="mailing_province"
              [(ngModel)]='default_mailing_province' autocomplete="off">
              <option *ngFor="let t of provinces2" [value]="t">{{t}}</option>
            </select>
            <label> Please Select a Province </label>
          </div>

          <div class="input-field col s12" *ngIf="showMailingAddress">
            <div> <label for="mailing_postcode">Postal Code </label> </div>
            <div>
              <!-- <input id="mailing_postcode" type="text" formControlName='mailing_postcode'
                [textMask]="{mask: postalCodeMask}"
                onkeyup="var start = this.selectionStart; var end = this.selectionEnd; this.value = this.value.toUpperCase(); this.setSelectionRange(start, end); "
                [(ngModel)]='default_mailing_postcode' autocomplete="off" /> -->
                <input id="mailing_postcode" type="text" formControlName='mailing_postcode'
                onkeyup="var start = this.selectionStart; var end = this.selectionEnd; this.value = this.value.toUpperCase(); this.setSelectionRange(start, end); "
                [(ngModel)]='default_mailing_postcode' autocomplete="off" />
            </div>
          </div>
        </div>
      </div>

      <div class="row card-panel white darken-2" *ngIf="step2">
        <div class="row">
          <div class="input-field col s12">
            <!-- <input id="homephone" type="tel" formControlName='homephone' required class="validate"
              [textMask]="{mask: phoneNumberMask}" autocomplete="off" /> -->
              <input id="homephone" type="tel" formControlName='homephone' required class="validate"
              autocomplete="off" />
            <label for="homephone">Home Phone <strong class="red-text">*</strong></label>
            <div *ngIf="(homephone.dirty || homephone.touched) && homephone.invalid">
              <small *ngIf="homephone.errors.required" class="red-text"> The home phone number is required. </small>
            </div>
          </div>

          <div class="input-field col s12">
            <!-- <input id="cellphone" type="tel" formControlName='cellphone' [textMask]="{mask: phoneNumberMask}"
              autocomplete="off" /> -->
              <input id="cellphone" type="tel" formControlName='cellphone' 
              autocomplete="off" />
            <label for="cellphone">Cell Phone</label>
          </div>

          <div class="input-field col s12">
            <!-- <input id="messages" type="text" formControlName='messages' [textMask]="{mask: phoneNumberMask}"
              autocomplete="off" /> -->
              <input id="messages" type="text" formControlName='messages' 
              autocomplete="off" />
            <label for="messages">Messages</label>
          </div>

          <div class="input-field col s12">
            <input id="email" type="email" formControlName='email' required class="validate" autocomplete="off" />
            <label for="email">Email<strong class="red-text">*</strong></label>
            <div *ngIf="(email.dirty || email.touched) && email.invalid">
              <small *ngIf="email.errors.required" class="red-text"> The Email is required. </small>
              <small *ngIf="email.errors.email" class="red-text"> wrong email format. </small>
            </div>
          </div>

          <div class="row input-field col s12">
            <div class="row col s12"> Do You Have Driver's License ? <strong class="red-text">*</strong></div>
            <div class="row col s12">
              <label class="col s4">
                <input class="with-gap" formControlName="have_license" name="have_license" type="radio"
                  checked="checked" value="yes" (change)="onDriverLicenseYes($event)" />
                <span>Yes</span>
              </label>
              <label class="col s4">
                <input class="with-gap" formControlName="have_license" name="have_license" type="radio" value="no"
                  (change)="onDriverLicenseNo($event)" />
                <span>No</span>
              </label>
            </div>
          </div>

          <div class="input-field col s12" *ngIf="showLicenseInfo">
            <input id="classno" type="text" formControlName='classno' class="capitalize" [(ngModel)]='default_classno'
              autocomplete="off" />
            <label for="classno">Driver's License Class Number</label>
          </div>

          <div class="input-field col s12" *ngIf="showLicenseInfo">
            <input id="license_expiry_date" formControlName="license_expiry_date" type="text"
              [(ngModel)]='default_expiry_date' class="datepicker2" (change)="LicenseDateChange($event)">
            <label for="license_expiry_date"> Driver's License Expiry Date: </label>
          </div>

          <div class="input-field col s12" *ngIf="showLicenseInfo">
            <select class="form-control" formControlName="license_province" [(ngModel)]='default_license_province'>
              <!--  <option value="" disabled selected>{{(nation?.Prov !== null) ? nation?.Prov : ""}} </option>  -->
              <option *ngFor="let t of provinces3" [value]="t">{{t}}</option>
            </select>
            <label> Please Select a Province </label>
          </div>

          <div class="input-field col s12">
            <input id="citizenship" type="text" formControlName='citizenship' class="capitalize" autocomplete="off" />
            <label for="citizenship">Citizenship</label>
          </div>

          <div class="input-field col s12">
            <input id="language" type="text" formControlName='language' class="capitalize" autocomplete="off" />
            <label for="language">Language</label>
          </div>

          <div class="row input-field col s12">
            <div class="row col s12"> Which refers to you ? <strong class="red-text">*</strong></div>
            <div class="row col s12">
              <label class="col s4">
                <input class="with-gap" formControlName="status" name="status" type="radio" checked="checked"
                  value="Treaty Status" />
                <span>Treaty Status</span>
              </label>

              <label class="col s4">
                <input class="with-gap" formControlName="status" name="status" type="radio" value="Non-Status" />
                <span>Non-Status</span>
              </label>

              <label class="col s4">
                <input class="with-gap" formControlName="status" name="status" type="radio" value="Inuit" />
                <span>Inuit</span>
              </label>
            </div>
          </div>
          <div>
            <div class="input-field col s8 l8">
              <!--  <input id="treatyno" type="number" formControlName='treatyno'   onKeyPress="if(this.value.length > 10 || this.value > 1000000000) return false;" />  -->

              <!-- <input id="treatyno" type="text" formControlName='treatyno' [textMask]="{mask: treatyNoMask}"
                onkeyup="var start = this.selectionStart; var end = this.selectionEnd;  this.setSelectionRange(start, end); "
                autocomplete="off" /> -->

                <input id="treatyno" type="text" formControlName='treatyno' 
                onkeyup="var start = this.selectionStart; var end = this.selectionEnd;  this.setSelectionRange(start, end); "
                autocomplete="off" />

              <label for="treatyno">Treaty Number: <span class="red-text">(10 Digits)</span></label>
            </div>

            <div class="row col s4 l4">
              <a [ngClass]="'col s12 btn waves-effect ' + themeColor" (click)="SearchForBand()"><small>Find
                  Band</small></a>
            </div>
          </div>
          <div>
            <div class="input-field col s12">
              <label for="messages">Band Name:</label>
            </div>

            <div class="input-field col s12">
              <input id="bandname" formControlName="bandname" type="text" [(ngModel)]='band_name' autocomplete="off">
            </div>
          </div>
        </div>
      </div>

      <div class="row card-panel white darken-2" *ngIf="step2">
        <br />
        <h6 class="center-align"><strong>CLIENT CONFIDENTIALITY CONSENT AND DISCLOSURE</strong></h6>

        <span *ngIf="step4" class=" blue-text">
          <strong class="activator" (click)="showMore()"><small>Read More ...</small></strong>
        </span>

        <span *ngIf="step5">
          <p><strong>This information</strong> is collected under the authority of the Indigenous Skills & Employment
            Training (ISET) signed
            between Human Resources and Skills Development Canada and Oteenow Employment and Training Society (OETS).
          </p>

          <p><strong>This information</strong> will be used to gather and store client information to determine
            entitlement to programs, services
            and/or funding that may be available through OETS and its partners. Therefore, this information may also be
            shared with
            partners and program delivery and/or funding including other ISETS holders. ISET sub-agreement holders,
            Alberta Human Resources,
            and Services Canada </p>

          <p><strong>The Privacy Act of Canada</strong> restricts any sharing of personal data unless written permission
            is first obtained from the
            client. Therefore, by signing this client confidentiality consent and disclosure form, you will be
            authorizing OETS to share only
            information that is required by partners in the program and services delivery, other ISETS holders and
            Alberta Human Services.</p>

          <p><strong>As a client</strong> you have access to information (under the Access to information Act) that OETS
            maintains and the Client
            may request to see it, upon one day's written notice of such a request. Be reminded that IETS is merely a
            custodian of the information
            gathered on Clients and that all information is the sole property of the Human Resources and Skills
            Development Canada. the Client is
            not entitled to take possession of their file but may request to see, add or change the information conained
            therein.</p>

          <p><strong>I the undersigned,</strong> have read, acknowledge and understand that by signing the client
            confidentiality consent and disclosure
            form that I hereby permit OETS to share my resume, name, contact information, employment and educational
            history with its partners
            or potential employers to promote myself for potential employment opportunities.</p>

          <p><strong>I further understand</strong> the intent of OETS is to Profile the client with partners and
            potential employers for the specific
            purpose of obtaining employment and that such authorization shall only be valid for the fiscal year during
            which it was signed and dated.</p>

          <p><strong>I will not</strong> disclose personal information regarding my file and or funding without the
            permission of OETS.</p>

          <p><strong>I, the undersigned</strong> have read and understand the OETS Client Registration Form, to the best
            of my knowledge. The information
            I have provided is correct and complete.</p>

          <p><strong>I will advise</strong> OETS of any changes within 72 hours, Should I decide to apply for funding
            from OETS. I understand that this
            information shall be considered as part of any application for funding within the OETS Student Policy.</p>

          <p><strong>I have also read, acknowledge and understand the Client Eligibility Criteria for requesting
              Training Supports</strong></p>

          <p class="center-align">CONFIDENTIALITY NOTICE</p>
          <small>
            <P>IMPORTANT: THIS FACSIMILE/EMAIL TRANSMISSION CONTAINS PRIVATE AND CONFIDENTIAL INFORMAITON. THIS
              TRANSMISSION FOR THE EXCLUSIVE USE OF THE
              INDIVIDUAL OR ENTITY HOME IT IS ADDRESSED, AND MAY CONTAIN INFORMATION THAT IS PROPRIETARY, PRIVILEGED,
              CONFIDENTIAL AND/OR EXEMPT FROM
              DISCLOSURE UNDER LAW. IF YOU ARE NOT THE INTENDED RECIPIENT, AN EMPLOYEE OR AGENT RESPONSIBLE FOR
              DELIVERING THIS FACSIMILE/EMAIL TRANSMISSION
              TO THE INTENDED RECIPIENT YOU ARE HEREBY NOTIFIED THAT ANY DISCLOSURE, DISSEMINATION, DISTRIBUTION OR
              COPYING OF THIS INFORMAITON STRICTLY
              PROHIBITED. PLEASE NOTIFY THE SENDER BY TELEPHONE 780-444-0911 TO ARRANGE THE RETURN OR DESTRUCTION OF THE
              INFORMATION AND ALL COPIES.</P>
          </small>

          <span class=" blue-text">
            <strong class="activator" (click)="HideContent()"><small>Hide Consent & Disclosure</small></strong>
          </span>
        </span>
      </div>

      <div class="row card-panel white darken-2" *ngIf="step2">
        <div class="row">
          <div class="row col s12"> <strong>Do You Agree ?</strong> </div>
          <div class="row col s12">
            <label class="col s4">
              <input class="with-gap" formControlName="agree" name="agree" type="radio" value="Yes"
                (change)="onAgreeYes($event)" />
              <span class="black-text"><strong>Yes</strong></span>
            </label>
            <label class="col s4">
              <input class="with-gap" formControlName="agree" name="agree" type="radio" value="No"
                (change)="onAgreeNo($event)" />
              <span class="black-text"><strong>No</strong></span>
            </label>
          </div>
        </div>

        <div class="md-form mb-4 pink-textarea active-pink-textarea">
          <label for="message"><span class="black-text"><strong>
                <h6>Message to Administrator:</h6>
              </strong></span></label>
          <textarea id="message" formControlName="message" class="md-textarea form-control" rows="10"></textarea>
        </div>
      </div>

      <div class="section"></div>

      <a *ngIf="step1" class="col s12 btn waves-effect green" (click)="next(); gotoTop()"
        style="margin-bottom: 15px;"><i class="material-icons right">arrow_right</i> Next</a>

      <div class="row center" *ngIf="step2">
        <p><strong>By Clicking <span class="green-text">Request To Join</span> I hereby :</strong></p>
        <p><strong>Agree and consent to the <a [routerLink]="['/eula']">User Agreement</a>, its policies and the <a
              [routerLink]="['/privacy-policy']">Privacy Policy</a>.</strong> </p>
      </div>

      <button *ngIf="step2" [disabled]="!requestTojoinForm.valid || !isAgreed" style="margin-bottom: 15px;"
        (click)="approveAndSign()" class="col s12 btn waves-effect green"> <i class="material-icons right">cloud</i>
        Approve And Sign
      </button>

      <a *ngIf="step2" class="col s12 btn waves-effect teal" (click)="back(); gotoTop()" style="margin-bottom: 15px;"><i
          class="material-icons left">house</i> Back To Previous Page</a>

      <a *ngIf="step2" [ngClass]="'col s12 btn waves-effect ' + themeColor" (click)="backClicked()"><i
          class="material-icons left">house</i> Back To Home Page</a>

      <div *ngIf="step3">
        <div class="row">
          <div [ngClass]="'card ' + themeColor">
            <div class="row card-panel grey lighten-4 center">
              <div class="row">
                <h5 class="center blue-text text-darken-2">Please Sign Here</h5>
                <div class="section"></div>
                <canvas></canvas>
              </div>
            </div>
          </div>

          <div class="section"></div>

          <button type="submit" style="margin-bottom: 15px;" class="col s12 btn waves-effect green">
            <i class="material-icons right">cloud</i> Submit Application</button>

          <a class="at-btn btn-flat center red lighten-1 col s12 white-text" (click)="clearSignature()"><i
              class="material-icons left">loop</i> Clear Signature</a>

          <a [ngClass]="'at-btn col s12 btn waves-effect ' + themeColor" (click)="back1()"><i
              class="material-icons left">keyboard_backspace</i> Back To Application </a>
        </div>
      </div>


      <div id="step6" *ngIf="step6" class="row ">

        <div class="row">
          <div class="row col s12">
            <div class="row card-panel">

              <div class="row input-field col s12">
                <div class="row col s12"> <strong class="blue-text">Do You want to Submit Supporting Documents ?
                  </strong></div>

                <div class="row col s12">
                  <label class="col s4">
                    <input class="with-gap" formControlName="id_photo" name="id_photo" type="radio" value="yes"
                      (change)="onUploadPhotoYes($event)" />
                    <span class="blue-text"><strong>Yes</strong></span>
                  </label>

                  <label class="col s4">
                    <input class="with-gap" formControlName="id_photo" name="id_photo" type="radio" value="no"
                      (change)="onUploadPhotoNo($event)" />
                    <span class="blue-text"><strong>No</strong></span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div id="step7" *ngIf="step7" class="row ">
        <div class="row">
          <div class="row col s12">
            <div class="row card-panel">

              <div class="row center">
                <h5 class="green-text">Thank You!</h5>
                <h6 class="green-text">Thank you for applying to Oteenow Employment Services. We will contact you as
                  soon as
                  we process your application.</h6>
                <br />
              </div>
            </div>
          </div>
        </div>

        <div class="section"></div>
        <button [ngClass]="'at-btn col s12 btn waves-effect ' + themeColor" (click)="backClicked()">
          <i class="material-icons left">keyboard_arrow_left</i> Back To Home Page
        </button>
      </div>
    </form>
  </div>
  <div class="col l2"></div>
</div>