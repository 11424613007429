<div class="row footer-pub" *ngIf="footerSection && nationSetting">
    <footer class="page-footer"
        [ngStyle]="{'background-color': footerSection.bgColor, 'color' : footerSection.textColor }">

        <div class="row col l12">
            <div class="col l3 s12 m6 center">
                <h6 class="title-section3 bold-700" [ngStyle]="{'color' : footerSection.textColor }">ADDRESS</h6>
                <p class="title-section3 bold-100" [ngStyle]="{'color' : footerSection.textColor }"
                    [innerHTML]="nationName">
                </p>
                <p class="title-section3 bold-100" [ngStyle]="{'color' : footerSection.textColor }">
                    <span [innerHTML]="nationSetting.address"></span>,
                    <span [innerHTML]="nationSetting.Town"></span>
                </p>
                <p class="title-section3 bold-100" [ngStyle]="{'color' : footerSection.textColor }"
                    [innerHTML]="nationSetting.Prov">
                </p>
                <p class="title-section3 bold-100" [ngStyle]="{'color' : footerSection.textColor }"
                    [innerHTML]="nationSetting.Postal">
                </p>
            </div>
            <div class="col l3 s12 m6 center">
                <h6 class="bold-700 title-section3" [ngStyle]="{'color' : footerSection.textColor }">CONTACT</h6>
                <ul>
                    <li><a [ngStyle]="{'color' : footerSection.textColor }" [href]="'tel:' + nationSetting.telephone"
                            class="sub-title-section bold-100"><i class="material-icons title-section2 p-r-1">phone</i>
                            <span [innerHTML]="nationSetting.telephone"></span></a>
                    </li>
                    <li><a [ngStyle]="{'color' : footerSection.textColor }" [href]="'tel:' + nationSetting.fax"
                            class="sub-title-section bold-100"> <i
                                class="material-icons title-section2 p-r-1">fax</i><span
                                [innerHTML]="nationSetting.fax"></span></a></li>
                    <li><a [ngStyle]="{'color' : footerSection.textColor }" [href]="'mailto:' + nationSetting.email"
                            class="sub-title-section bold-100"> <i
                                class="material-icons title-section2 p-r-1">mail</i><span
                                [innerHTML]="nationSetting.email"></span></a></li>
                </ul>
            </div>

            <div class="col l3 s12 m6" *ngIf="socialMediaModel">
                <h6 class="title-section3 bold-700 center" [ngStyle]="{'color' : footerSection.textColor }">FOLLOW
                </h6>
                <div class="hero">
                    <div class="social-links">
                        <a [ngStyle]="{'color' : footerSection.textColor }" [href]="socialMediaModel.facebook"
                            *ngIf="socialMediaModel.facebook" target="_blank">
                            <i class="fa fa-facebook"></i>
                        </a>
                        <a [ngStyle]="{'color' : footerSection.textColor }" [href]="socialMediaModel.instagram"
                            *ngIf="socialMediaModel.instagram" target="_blank">
                            <i class="fa fa-instagram"></i>
                        </a>
                        <a [ngStyle]="{'color' : footerSection.textColor }" [href]="socialMediaModel.twitter"
                            *ngIf="socialMediaModel.twitter" target="_blank">
                            <!-- <i class="fa fa-twitter"></i> -->
                            <svg _ngcontent-yei-c148="" width="25" height="25" xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512">
                                <path _ngcontent-yei-c148="" fill="currentcolor"
                                    d="M389.2 48h70.6L305.6 224.2 487 464H345L233.7 318.6 106.5 464H35.8L200.7 275.5 26.8 48H172.4L272.9 180.9 389.2 48zM364.4 421.8h39.1L151.1 88h-42L364.4 421.8z">
                                </path>
                            </svg>
                        </a>
                        <a [ngStyle]="{'color' : footerSection.textColor }" [href]="socialMediaModel.youtube"
                            *ngIf="socialMediaModel.youtube" target="_blank">
                            <i class="fa fa-youtube-play"></i>
                        </a>
                        <a [ngStyle]="{'color' : footerSection.textColor }" [href]="socialMediaModel.linkedin"
                            *ngIf="socialMediaModel.linkedin" target="_blank">
                            <i class="fa fa-linkedin"></i>
                        </a>
                        <a [ngStyle]="{'color' : footerSection.textColor }" [href]="socialMediaModel.tiktok"
                            *ngIf="socialMediaModel.tiktok" target="_blank">
                            <svg _ngcontent-yei-c68="" width="25" height="25" xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512">
                                <path _ngcontent-yei-c68="" fill="currentcolor"
                                    d="M448,209.91a210.06,210.06,0,0,1-122.77-39.25V349.38A162.55,162.55,0,1,1,185,188.31V278.2a74.62,74.62,0,1,0,52.23,71.18V0l88,0a121.18,121.18,0,0,0,1.86,22.17h0A122.18,122.18,0,0,0,381,102.39a121.43,121.43,0,0,0,67,20.14Z">
                                </path>
                            </svg>
                        </a>
                    </div>
                </div>
            </div>

            <div class="col l3 s12 m6" *ngIf="appStoreSection">
                <h6 class="title-section3 bold-700 center p-b-1" style="text-transform: uppercase;"
                    [ngStyle]="{'color' : appStoreSection.displayNameColor }"> {{appStoreSection.displayName}}
                </h6>
                <div class="row col s12 m10 l10 push-l1 push-m1 ">
                    <a class="app-btn blu white-text flex vert  b-n-1 " [ngStyle]="{'background-color': themeColor} "
                        [href]="appStoreSection.appleStoreUrl">
                        <i class="fa fa-apple" style='font-size:44px'></i>
                        <p style="font-size: 10px; font-family: helvetica;">Available on the <br /> <span
                                style="font-size: 20px; font-family: helvetica;">App Store</span></p>
                    </a>
                </div>
                <div class="row col s12 m10 l10 push-l1 push-m1 ">
                    <a class="app-btn blu  white-text flex vert b-n-1 " [ngStyle]="{'background-color': themeColor} "
                        [href]="appStoreSection.googlePlayUrl">
                        <img src="https://img.icons8.com/ios-filled/40/ffffff/google-play.png" class="p-r-1" alt="AppStore Image"/>
                        <p style="font-size: 10px; font-family: helvetica;">Available on the <br /> <span
                                style="font-size: 20px; font-family: helvetica;">Play Store</span></p>
                    </a>
                </div>
            </div>
        </div>

        <div class="footer-menu center col l12 s12">
            <ul>
                <li><a [routerLink]="['/terms-conditions']" [ngStyle]="{'color' : footerSection.linkColor }">Terms &
                        Conditions</a></li>
                <li><a [routerLink]="['/privacy-policy']" [ngStyle]="{'color' : footerSection.linkColor }">Privacy
                        Policy</a></li>
                <li><a [routerLink]="['/eula']" [ngStyle]="{'color' : footerSection.linkColor }">End-User Licence
                        Agreement</a></li>
                <li><a [routerLink]="['/governance-chief']" [ngStyle]="{'color' : footerSection.linkColor }">Chief</a>
                </li>
                <!-- <li><a (click)="councillorsClicked();">Councillors</a></li> -->
                <li><a [routerLink]="['/boards-list']" [ngStyle]="{'color' : footerSection.linkColor }">Boards</a></li>
            </ul>
        </div>

        <hr class="col l12" />

        <p class="center-align col l12 hide-on-med-and-down" [ngStyle]="{'color' : footerSection.linkColor}"> ©
            Copyright 2021
            {{footerSection.nationName}} App All
            Rights Reserved</p>
        <p class="center-align col l12 p-b-5 hide-on-med-and-up" [ngStyle]="{'color' : footerSection.linkColor}"> ©
            Copyright 2021
            {{footerSection.nationName}} App All
            Rights Reserved</p>

        <div class="row center-align">

            <p class="col s12 m12 l12" [ngStyle]="{'color' : footerSection.linkColor }"><strong>Powered by: </strong><a
                    [ngStyle]="{'color' : footerSection.linkColor }" style="cursor:pointer"
                    href="https://www.mynation.app/" target="_blank">mynation.app</a>
            </p>
        </div>
    </footer>
</div>