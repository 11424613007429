import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { LoadingService } from 'src/app/services/loader/loading.service';
import { LoadingState } from 'src/app/models/loadingState';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { environment } from 'src/environments/environment';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.css']
})

export class LoadingComponent implements OnInit {

  show = false;
  private subscription: Subscription;
  private user: any;
  isLogged = false;
  themeColor: any;
  defaultThemeColor = environment.appTheme.themeColor;
  userRole: any;

  isAdminMember = false
  isChiefOrCouncillor = false;
  isSuperAdmin = false;
  permissions: any;

  constructor(private loadingService: LoadingService,
    private authService: AuthService,
    public appSettingsService: AppSettingsService) {
    this.user = authService.getGlobalUser();
    this.themeColor = this.appSettingsService.getAppThemeColor() ? this.appSettingsService.getAppThemeColor() : this.defaultThemeColor;
  }

  ngOnInit() {
    if (this.user) { this.isLogged = true; }

    this.subscription = this.loadingService.loaderState
      .subscribe((state: LoadingState) => {
        this.show = state.show;
      });
  }
}
