<div class="row center-align p-m-2">
    <h6><strong>My Areas Of Interest</strong></h6>
</div>

<div class="row col s12">
    <div class="col l2 "></div>
    <div class="col s12 m12 l8">
        <div *ngIf="checkBoxList">
            <table>
                <thead>
                    <th class="blue-text center">Please Select Your Areas Of Interest</th>
                </thead>
                <tbody>
                    <tr *ngFor="let area of checkBoxList">
                        <td>
                            <label>
                                <input type="checkbox" class="filled-in" [(ngModel)]="area.isSelected"
                                    (change)="itemCheckedChange(area.id, area.isSelected)" />
                                <span><strong>{{area.name}}</strong></span>
                            </label>
                        </td>
                    </tr>
                </tbody>
            </table>
            <div class="row">
                <div class="section"></div>
                <button type="button" *ngIf="!myInterestModel" (click)="saveMyInterests()"
                    class="btn btn-green col s12"> Save My Areas Of Interest <i
                        class="material-icons right">cloud_upload</i></button>
            </div>
        </div>
    </div>
    <div class="col l2 "></div>
</div>