export class FamilyCompositionModel {
    memberId: number;
    firstName: string;
    lastName: string;
    DOB: Date;
    SIN: string;
    ISN: string;
    relation: string;
    email: string;
    mobile: number;
  }
  