import { Component, OnInit } from '@angular/core';
import { Validators, UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { HighlightModel } from 'src/app/models/HighlightModel';
import { environment } from 'src/environments/environment';
import { HighlightService } from 'src/app/services/api/highlight.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { toast } from 'materialize-css';
import { Location } from '@angular/common';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-add-highlight',
  templateUrl: './add-highlight.component.html',
  styleUrls: ['./add-highlight.component.css']
})
export class AddHighlightComponent implements OnInit {

  clientID = 0;
  addHighlightForm: UntypedFormGroup;
  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  model: HighlightModel;
  resume_id = 0;
  
  constructor(private authService: AuthService, private fb: UntypedFormBuilder, private location: Location, private highlightService: HighlightService) { }

  ngOnInit(): void {
    if (localStorage.getItem('applicantID')) {
      this.clientID = Number(localStorage.getItem('applicantID'));
    } else {
      this.clientID = this.authService.getCurrentApplicantID();
    }
    if (localStorage.getItem('resume_id')) {
      this.resume_id = Number(localStorage.getItem('resume_id'));
    }
    this.addHighlightForm = this.fb.group({
      Highlight: ['', [Validators.required]],
    });

    this.model = new HighlightModel();
    this.model.resume_id = this.resume_id;
  }

  get Highlight() { return this.addHighlightForm.get('Highlight'); }

  submitForm(value: any) {
    if (this.addHighlightForm.valid){
      this.model.Highlight = value.Highlight;
      this.highlightService.saveHighlight(this.model).subscribe(x => {
        if (x) {
          toast({ html: 'Highlight Successfully Added!', classes: 'green' });
          this.location.back();
        }
      });
    }
  }

  backClicked() {
  this.location.back();
  }

}
