import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { AppSettingsService } from 'src/app/services/firebase/app-settings.service';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { SocialMediaService } from 'src/app/services/firebase/social-media.service';
import { WindowService } from 'src/app/services/window-service/window.service';
import { toast } from 'materialize-css';
import { FbNationSettingsService } from 'src/app/services/firebase/fb-nation-settings.service';

declare var $: any;

@Component({
  selector: 'app-login-preview',
  templateUrl: './login-preview.component.html',
  styleUrls: ['./login-preview.component.css']
})
export class LoginPreviewComponent implements OnInit {

  mobilePattern = /^\s*([\(])[[0-9]{2}\d{1}[\)]\s*[\-]?[\.]?\s*\d{3}\s*[\-]?[\.]?\s*\d{4}$/;
  // tslint:disable-next-line: max-line-length
  public phoneNumberMask = ['(', /[0-9]/, /[0-9]/, /[0-9]/, ')', ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  public verificationCodeMask = [/[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];

  windowRef: any;
  loginForm: UntypedFormGroup;
  phoneLoginForm: UntypedFormGroup;
  nationOfUse = environment.firstNation.name;
  step1 = true;
  step2 = false;
  recaptchaVerified = false;
  model: any;
  defaultThemeColor = environment.appTheme.themeColor;
  themeColor: any;
  socialMediaModel: any;
  showPassword: boolean = false;
  loginSetting: any;
  defaultSetting = {
    name: 'Login',
    displayName: 'Login Page',
    icon: '',
    title: 'Welcome Back',
    description: 'Our suite of mobile applications have been designed with First Nations in mind to assist First Nations governments, organizations and advocacy groups with digital information management, online communications and community development.',
    image: 'https://api.mysabenefits.ca/Media/Demo/Images/6da6cf35-4671-4b9a-be99-edba6e21f7f4-thumbnail_IMG_0372.jpg',
  };

  setting: any;
  logo: any;
  defaultauthBgColor = '#000';
  authformBgColor: any;
  authformSColor: any;

  constructor(private fb: UntypedFormBuilder,
    private socialService: SocialMediaService,
    private authService: AuthService,
    private winService: WindowService,
    private settingsService: AppSettingsService,
    public appSettingService: AppSettingsService,
    private nationSettingServicee: FbNationSettingsService,) {
    this.settingsService.getAppSettingsList().valueChanges().subscribe(
      appSettings => {
        if (appSettings) {
          if (appSettings.length > 0) {
            this.model = appSettings[0];
            this.themeColor = this.settingsService.getAppThemeColor() ? this.settingsService.getAppThemeColor() : this.defaultThemeColor;
          }
        }
      });
  }

  ngOnInit(): void {
    this.appSettingService.getAppSetting().valueChanges().subscribe(
      appSettings => {
        if (appSettings && appSettings.length > 0) {
          this.setting = appSettings[0];
          this.authformBgColor = this.setting.authformBgColor ? this.setting.authformBgColor : this.defaultauthBgColor;
          this.authformSColor = this.setting.authformSColor ? this.setting.authformSColor : this.defaultauthBgColor;
        } else {
          this.authformBgColor = this.defaultauthBgColor;
          this.authformSColor = this.defaultauthBgColor;
        }
      });

    this.nationSettingServicee.getsettings().valueChanges().subscribe(setting => {
      if (setting && setting.length > 0) {
        if (setting[0].logo) this.logo = setting[0].logo;
      }
    });

    this.settingsService.getLoginPageSettings().valueChanges().subscribe(setting => {
      if (setting && setting.length > 0) {
        this.loginSetting = setting[0];
      } else {
        this.loginSetting = this.defaultSetting;
      }
    });

    this.loginForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });

    if (this.model) {
      const socialSection = this.model.sections.filter(s => s.name === 'SocialMedia' && s.isEnabled);
      if (socialSection) {
        if (socialSection.length > 0) {
          this.socialService.getSocialMedia().valueChanges().subscribe(n => {
            if (n) {
              if (n.length > 0) {
                this.socialMediaModel = n[0];
              }
            }
          });
        }
      }
    }
    setTimeout(() => {
      $('.tabs').tabs();
    }, 25);
  }

  get email() { return this.loginForm.get('email'); }
  get password() { return this.loginForm.get('password'); }
  get phoneNumber() { return this.phoneLoginForm.get('phoneNumber'); }
  get verificationCode() { return this.phoneLoginForm.get('verificationCode'); }

  toggleVisibility() {
    this.showPassword = !this.showPassword;
  }

  login(data: any) {
    this.authService.emailLogin(data.value.email, data.value.password).then(x => {
    }).catch(error => toast({ html: error.message, classes: 'red' }));
  }
}