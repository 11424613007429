export class AddBandMemberModel {
    GivenName  = '';
    middle_name = '';
    LastName  = '';
    DOB: Date = null;
    Sex = '';
    IndianStatusNo = '';
    Email = '';
    Telephone = '';
    cell_phone_num = '';
    HealthCareNo = '';
    MailAddress = '';
    city = '';
    province = '';
    PostalCode = '';
    SIN = '';
    Community = '';
    date_added: Date;
    Officer= '';
    //fromApp:boolean;
    is_employee?: boolean = false;
  }

  export class MemberMatchModel {
    GivenName  = '';
    LastName  = '';
    IndianStatusNo = '';
  }