import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ChartOptions, ChartType } from 'chart.js';
import { ActivatedRoute } from '@angular/router';
import { PollsAndSurveyService } from 'src/app/services/firebase/polls-and-survey.service';
import { PollsAndSurveyParticipationService } from 'src/app/services/firebase/polls-and-survey-participation.service';

@Component({
  selector: 'app-survey-result',
  templateUrl: './survey-result.component.html',
  styleUrls: ['./survey-result.component.css']
})

export class SurveyResultComponent implements OnInit {

  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  loading = false;

  surveyId = '';
  surveyModel: any;
  participationList: any;
  surveyQuestion = [];

  // public barChartOptions: ChartOptions = {
  //   //scaleShowVerticalLines: false,
  //   responsive: true,
  //   // We use these empty structures as placeholders for dynamic theming.
  //   scales: {
  //     yAxes: [
  //       {
  //         ticks: {
  //           stepSize: 1,
  //           beginAtZero: true,
  //         }
  //       }]
  //   }
  // };
  public barChartOptions: ChartOptions = {
    responsive: true,
    scales: {
      x: {},
      y: {
        beginAtZero: true,  // This should be outside ticks
        ticks: {
          stepSize: 1
        }
      }
    }
  };
  public barChartColors: Array<any> = [
    { // all colors in order
      backgroundColor: [
        'rgba(255, 99, 132, 0.2)',
        'rgba(255, 159, 64, 0.2)',
        'rgba(255, 205, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(201, 203, 207, 0.2)'
      ],
      borderColor: [
        'rgb(255, 99, 132)',
        'rgb(255, 159, 64)',
        'rgb(255, 205, 86)',
        'rgb(75, 192, 192)',
        'rgb(54, 162, 235)',
        'rgb(153, 102, 255)',
        'rgb(201, 203, 207)'
      ],
      borderWidth: 1
    }
  ];
  public barChartType: ChartType = 'bar';
  public barChartLegend = false;
  public barChartPlugins = [];
  dynamicChartLabels = [];
  dynamicChartData = [];
  resultData = [];
  comments: any[];

  constructor(private route: ActivatedRoute, 
    private participationService: PollsAndSurveyParticipationService, 
    private surveyService: PollsAndSurveyService) { }

  ngOnInit() {
    this.loading = true;
    this.route.paramMap.subscribe(params => {
      this.surveyId = params.get('id');
    });

    if (this.surveyId) {
      this.participationService.getSurveyParticipationBySurveyID(this.surveyId).valueChanges().subscribe(part => {
        if (part) {
          this.participationList = part;
          if (this.participationList) {
            this.surveyService.getSurveyByID(this.surveyId).valueChanges().subscribe(survey => {
              if (survey) {
                this.surveyModel = survey;
                this.surveyModel.questions.forEach(question => {
                  if (question.answerType === 'Multiple Choice' || question.answerType === 'Scale') {
                    const questionChartLabels = [];
                    const totalCounts = [];
                    const questionChartData = [];
                    question.answers.forEach(answer => {
                      const ans = {
                        answer: answer.answer,
                        count: this.participationList.filter((el) => {
                          return el.answers.filter(function (item) {
                            return item.answer === answer.answer && item.questionNumber === question.questionNumber;
                          }).length;
                        }).length
                      }
                      if(question.allowMultipleAnswers){
                        ans.count = this.participationList.filter((el) => {
                          return el.answers.filter(function (item) {
                            return item.answer.split(',').includes(answer.answer) && item.questionNumber === question.questionNumber;
                          }).length;
                        }).length
                      }
                      totalCounts.push(ans.count);
                      questionChartLabels.push(ans.answer);
                    });
                    questionChartData.push({ data: totalCounts, label: 'Total' });
                    const result = {
                      questionNumber: question.questionNumber,
                      question: question.question,
                      answerType: question.answerType,
                      data: questionChartData,
                      labels: questionChartLabels,
                    }
                    this.resultData.push(result);
                  }
                  else {
                    var answers = [];
                    this.participationList.forEach(p => {
                      p.answers.forEach(item => {
                        if (item.questionNumber == question.questionNumber) {
                          answers.push(item.answer);
                        }
                      });
                    });
                    const result = {
                      questionNumber: question.questionNumber,
                      question: question.question,
                      answerType: question.answerType,
                      data: answers,
                      labels: "",
                    }
                    this.resultData.push(result);
                  }
                });
              }
            });
            this.comments = [];
            this.participationList.forEach(part => {
              if(part.comment && part.comment != ''){
                this.comments.push(part.comment);
              }  
            });
          }
        }
      });
    }
    this.loading = false;
  }
}