import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IAngularMyDpOptions, IMyDateModel } from 'angular-mydatepicker';
import { SearchMemberModel } from 'src/app/models/searchMemberModel';
import { MediaService } from 'src/app/services/api/media.service';
import { MemberService } from 'src/app/services/api/member.service';
import { GovernanceService } from 'src/app/services/firebase/governance.service';
import { toast } from 'materialize-css';
import { Location } from '@angular/common';
import { FireMembersService } from 'src/app/services/firebase/fire-members.service';
import { EmailService } from 'src/app/services/api/email.service';
import { EmailModel } from 'src/app/models/emailModel';
import { OtherRolesService } from 'src/app/services/firebase/other-roles.service';
import { createMask } from '@ngneat/input-mask';

declare var M: any;
declare var $: any;

@Component({
  selector: 'app-add-board-chair',
  templateUrl: './add-board-chair.component.html',
  styleUrls: ['./add-board-chair.component.css']
})
export class AddBoardChairComponent implements OnInit {

  id: any;
  boardId: any;
  boardModel: any;
  memberType: any;
  editMemberModel: any;
  searchForm: UntypedFormGroup;
  chiefForm: UntypedFormGroup;
  step1 = false;
  step2 = false;
  memberModel: SearchMemberModel;
  selectedMemberModel: any;
  searchResult: any;
  p: number = 1;
  today = new Date();
  appointmentDate: any;
  expiryDate: any;
  myimgLink: any;
  showImageUpload = true;
  binDoc: File;
  oldUrl: any;
  base64Image: any;
  profile_pic: any;
  //public phoneNumberMask = ['(', /[0-9]/, /[0-9]/, /[0-9]/, ')', ' ', /[0-9]/, /[0-9]/, /[0-9]/, ' ', '-', ' ', /[0-9]/, /[0-9]/, /[0-9]/, /[0-9]/];
  phoneInputMask = createMask<string>( {
    mask: '(999) 999-9999',
    guide: true,
    showMaskTyped: true,
  });
  public urlPattern = '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?';

  showMobilePrivacy = false;
  showEmailPrivacy = false;
  showOfficePhonePrivacy = false;
  showFacebookPrivacy = false;
  showTwitterPrivacy = false;
  showInstagramPrivacy = false;
  showYoutubePrivacy = false;
  showLinkedInPrivacy = false;
  applicantID: any;
  isArchived = false;
  myDpOptions: IAngularMyDpOptions = {
    dateRange: false,
    dateFormat: 'dd/mmm/yyyy',
    markCurrentDay: true,
  };

  constructor(
    private formBuilder: UntypedFormBuilder,
    private router: Router,
    private memberService: MemberService,
    private route: ActivatedRoute,
    private mediaService: MediaService,
    private location: Location,
    private govService: GovernanceService,
    private fireMember: FireMembersService,
    private emailService: EmailService,
    private otherRolesService: OtherRolesService) {

    if (localStorage.getItem('boardID') && localStorage.getItem('boardMember')) {
      this.boardId = localStorage.getItem('boardID');
      this.memberType = localStorage.getItem('boardMember');
    }

    this.route.paramMap.subscribe(params => {
      this.id = params.get('id');
    });
  }

  get fName() { return this.searchForm.get('fName'); }
  get lName() { return this.searchForm.get('lName'); }

  ngOnInit() {
    this.govService.getBoardById(this.boardId).valueChanges().subscribe(b => {
      if (b) {
        this.boardModel = b;

        if (!this.id) {
          this.searchForm = this.formBuilder.group({
            fName: [''],
            lName: [''],
          });
          this.step1 = true;
        }

        if (this.id) {
          this.applicantID = parseInt(this.id, 10);
          var result: any;
          if (this.memberType === 'Chair') {
            result = this.boardModel.boardChair.filter(f => Number(f.id) === Number(this.id))
          }
          if (this.memberType === 'Member') {
            result = this.boardModel.boardMembers.filter(f => Number(f.id) === Number(this.id))
          }
          if (result.length > 0) {
            this.editMemberModel = result[0];
          } else {
            // if restoring archived member
            this.fireMember.getMemberByAppliciantId(this.applicantID).valueChanges().subscribe(memberDetail => {
              if (memberDetail.length > 0) {
                this.step1 = false;
                this.step2 = true;
                this.memberSelected(memberDetail[0]);
                setTimeout(() => {
                  M.updateTextFields();
                }, 25);
              }
            });
          }

          if (this.editMemberModel) {
            this.chiefForm = this.formBuilder.group({
              firstName: [{ value: this.editMemberModel.firstName, disabled: true }],
              lastName: [{ value: this.editMemberModel.lastName, disabled: true }],
              displayName: [this.editMemberModel.displayName],
              chiefBio: [this.editMemberModel.chiefBio],
              appointmentDate: [this.editMemberModel.appointmentDate],
              expiryDate: [this.editMemberModel.expiryDate],
              email: [this.editMemberModel.email, [Validators.email, Validators.required]],
              cell_phone: [this.editMemberModel.cell_phone],
              website: [this.editMemberModel.website, [Validators.pattern(this.urlPattern)]],
              office_phone: [this.editMemberModel.office_phone],
              imgLink: [this.editMemberModel.imgLink],
              chiefFacebookAcc: [this.editMemberModel.chiefFacebookAcc, [Validators.pattern(this.urlPattern)]],
              chiefInstagramAcc: [this.editMemberModel.chiefInstagramAcc, [Validators.pattern(this.urlPattern)]],
              chiefTwitterAcc: [this.editMemberModel.chiefTwitterAcc, [Validators.pattern(this.urlPattern)]],
              chiefLinkedinAcc: [this.editMemberModel.chiefLinkedinAcc, [Validators.pattern(this.urlPattern)]],
              chiefYoutubeAcc: [this.editMemberModel.chiefYoutubeAcc, [Validators.pattern(this.urlPattern)]],
              showEmail: [this.editMemberModel.showEmail],
              showMobile: [this.editMemberModel.showMobile],
              showFacebookAcc: [this.editMemberModel.showFacebookAcc],
              showTwitterAcc: [this.editMemberModel.showTwitterAcc],
              showInstagramAcc: [this.editMemberModel.showInstagramAcc],
              showLinkedinAcc: [this.editMemberModel.showLinkedinAcc],
              showYoutubeAcc: [this.editMemberModel.showYoutubeAcc],
              showOfficePhone: [this.editMemberModel.showOfficePhone],
              allowContact: [this.editMemberModel.allowContact],
            });

            if (this.editMemberModel.imgLink) {
              this.myimgLink = this.editMemberModel.imgLink;
              this.profile_pic = this.editMemberModel.imgLink;
              this.showImageUpload = false;
            }
            this.step1 = false;
            this.step2 = true;
            setTimeout(() => {
              M.updateTextFields();
            }, 25);
          }
        }
      }
    });
    $(document).ready(() => {
      $('.modal').modal();
    });
  }

  searchMember(value: any) {
    this.memberModel = new SearchMemberModel();
    this.memberModel.firstName = (value.fName) ? value.fName : '';
    this.memberModel.lastName = (value.lName) ? value.lName : '';
    this.memberService.searchMemberByName(this.memberModel).subscribe(m => {
      if (m) {
        this.searchResult = m;
        this.p = 1;
      }
    });
  }

  memberSelected(model: any) {
    this.selectedMemberModel = model;
    this.applicantID = this.selectedMemberModel.applicantID;
    this.chiefForm = this.formBuilder.group({
      firstName: [{ value: this.selectedMemberModel.GivenName, disabled: true }],
      lastName: [{ value: this.selectedMemberModel.LastName, disabled: true }],
      displayName: [this.selectedMemberModel.GivenName + ' ' + this.selectedMemberModel.LastName],
      chiefBio: [''],
      appointmentDate: [''],
      expiryDate: [''],
      email: ['', [Validators.email, Validators.required]],
      cell_phone: [''],
      website: ['', [Validators.pattern(this.urlPattern)]],
      office_phone: [''],
      imgLink: [''],
      chiefFacebookAcc: ['', [Validators.pattern(this.urlPattern)]],
      chiefInstagramAcc: ['', [Validators.pattern(this.urlPattern)]],
      chiefTwitterAcc: ['', [Validators.pattern(this.urlPattern)]],
      chiefLinkedinAcc: ['', [Validators.pattern(this.urlPattern)]],
      chiefYoutubeAcc: ['', [Validators.pattern(this.urlPattern)]],
      showEmail: ['Private'],
      showMobile: ['Private'],
      showFacebookAcc: ['Private'],
      showTwitterAcc: ['Private'],
      showInstagramAcc: ['Private'],
      showLinkedinAcc: ['Private'],
      showYoutubeAcc: ['Private'],
      showOfficePhone: ['Private'],
      allowContact: ['Private'],
    });
    this.step1 = false;
    this.step2 = true;
    setTimeout(() => {
      M.updateTextFields();
    }, 25);
  }

  showEmailPrivacyRB() {
    if (this.getEmail.value) {
      if (this.getEmail.value.length > 1 && this.getEmail.valid) {
        this.showEmailPrivacy = true;
      } else { this.showEmailPrivacy = false; }
    } else { this.showEmailPrivacy = false; }
  }

  showOfficeNoPrivacyRB() {
    if (this.getOffice_phone.value) {
      if (this.getOffice_phone.value.length > 1 && this.getOffice_phone.valid) {
        this.showOfficePhonePrivacy = true;
      } else { this.showOfficePhonePrivacy = false; }
    } else { this.showOfficePhonePrivacy = false; }
  }

  showMobileNoPrivacyRB() {
    if (this.getCell_phone.value) {
      if (this.getCell_phone.value.length > 1 && this.getCell_phone.valid) {
        this.showMobilePrivacy = true;
      } else { this.showMobilePrivacy = false; }
    } else { this.showMobilePrivacy = false; }
  }

  showFaceBookPrivacyRB() {
    if (this.getChiefFacebookAcc.value) {
      if (this.getChiefFacebookAcc.value.length > 1 && this.getChiefFacebookAcc.valid) {
        this.showFacebookPrivacy = true;
      } else { this.showFacebookPrivacy = false; }
    } else { this.showFacebookPrivacy = false; }
  }

  showTwitterPrivacyRB() {
    if (this.getChiefTwitterAcc.value) {
      if (this.getChiefTwitterAcc.value.length > 1 && this.getChiefTwitterAcc.valid) {
        this.showTwitterPrivacy = true;
      } else { this.showTwitterPrivacy = false; }
    } else { this.showTwitterPrivacy = false; }
  }

  showInstagramPrivacyRB() {
    if (this.getChiefInstagramAcc.value) {
      if (this.getChiefInstagramAcc.value.length > 1 && this.getChiefInstagramAcc.valid) {
        this.showInstagramPrivacy = true;
      } else { this.showInstagramPrivacy = false; }
    } else { this.showInstagramPrivacy = false; }
  }

  showYouTubePrivacyRB() {
    if (this.getChiefYoutubeAcc.value) {
      if (this.getChiefYoutubeAcc.value.length > 1 && this.getChiefYoutubeAcc.valid) {
        this.showYoutubePrivacy = true;
      } else { this.showYoutubePrivacy = false; }
    } else { this.showYoutubePrivacy = false; }
  }

  showLinkedInPrivacyRB() {
    if (this.getChiefLinkedinAcc.value) {
      if (this.getChiefLinkedinAcc.value.length > 1 && this.getChiefLinkedinAcc.valid) {
        this.showLinkedInPrivacy = true;
      } else { this.showLinkedInPrivacy = false; }
    } else { this.showLinkedInPrivacy = false; }
  }

  get getFirstName() { return this.chiefForm.get('firstName'); }
  get getLastName() { return this.chiefForm.get('lastName'); }
  get getAppointmentDate() { return this.chiefForm.get('appointmentDate'); }
  get getExpiryDate() { return this.chiefForm.get('expiryDate'); }
  get getChiefBio() { return this.chiefForm.get('chiefBio'); }
  get getEmail() { return this.chiefForm.get('email'); }
  get getCell_phone() { return this.chiefForm.get('cell_phone'); }
  get getOffice_phone() { return this.chiefForm.get('office_phone'); }
  get getChiefFacebookAcc() { return this.chiefForm.get('chiefFacebookAcc'); }
  get getChiefInstagramAcc() { return this.chiefForm.get('chiefInstagramAcc'); }
  get getChiefTwitterAcc() { return this.chiefForm.get('chiefTwitterAcc'); }
  get getChiefLinkedinAcc() { return this.chiefForm.get('chiefLinkedinAcc'); }
  get getChiefYoutubeAcc() { return this.chiefForm.get('chiefYoutubeAcc'); }
  get imgLink() { return this.chiefForm.get('imgLink'); }
  get website() { return this.chiefForm.get('website'); }

  onAppointmentDateChange(event: IMyDateModel): void {
    if (this.chiefForm) {
      this.chiefForm.patchValue({ appointmentDate: event.singleDate.formatted });
      this.appointmentDate = event.singleDate.formatted;
    }
  }

  onExpiryDateChange(event: IMyDateModel): void {
    if (this.chiefForm) {
      this.chiefForm.patchValue({ expiryDate: event.singleDate.formatted });
      this.expiryDate = event.singleDate.formatted;
    }
  }

  saveData(model: any, imgUrl: any) {
    if (!this.editMemberModel) {
      // get user if exists
      this.fireMember.getMemberByAppliciantId(this.applicantID).valueChanges().subscribe(boardMembers => {
        if (boardMembers.length > 0) {
          const boardMemberModel = {
            id: '',
            memberId: this.applicantID,
            memberType: this.memberType,
            boardId: this.boardId
          };
          const mail = new EmailModel();
          mail.emailList = [model.email];
          mail.subject = `Added as a Board Member`;
          mail.body = `Hello ${this.selectedMemberModel.GivenName},<br/>`;
          mail.body = mail.body += `You have been added as a board member for ${this.boardModel.name} by an admin so you can
          customize your profile by logging to your account.`;
          mail.body = mail.body += `<br/>Thank you.`;

          // this.emailService.sendEmail(mail).subscribe(() => {
          //    toast({ html: 'Email Successfully Sent.', classes: 'green' });
          // });

        } else {
          // new user so register
          const invitationDetailObj: any = {
            applicantID: this.applicantID,
            firstName: this.selectedMemberModel.GivenName,
            lastName: this.selectedMemberModel.LastName,
            displayName: model['displayName'],
            email: model['email'],
            isBoardMember: true,
            invitationDate: Date.now(),
          };
          this.otherRolesService.saveInvitation(invitationDetailObj);

          // then notify
          let registrationDomain = window.location.hostname;
          if (window.location.hostname === 'localhost') {
            registrationDomain = 'http://localhost:4200';
          } else {

            registrationDomain = `https://${registrationDomain}`;
          }
          registrationDomain = `${registrationDomain}/#/auth/register-other-roles/${this.applicantID}`;
          const email = {
            subject: 'Registration Email',
            body: `<p> Good Day <strong style="color: green;"> ` + model['displayName'] + ` </strong></p>
            <p> Welcome to the myNation mobile platform. </p>
        <p> This email is to confirm your registration as an official member of our demo platform since
      you have been invited to be a board member for <strong>${this.boardModel.name}</strong> . </p>
        <p> Click the link below to complete your registration by creating your password and choosing your security settings.  </p>
        <p>  As a member of myNation you will be able to customize your own profile, chat with other members and stay up to date
        with news, events and contest updates.
        <br/> And also as a board member of ${this.boardModel.name} you can customize your board member profile.
        You will also notice additional options such as contacting departments, signing and submitting documents, building your
         resume, searching job boards and more.
        While these features are not yet available for demo accounts, we encourage you to take a look around and get an idea of
         how this platform will work for your Nation.
        </p>

        <br/> <br/>
        <a href="` + registrationDomain + `" target="_blank"> Please Click Here To Complete Registration </a>
        <br/> <p>Thank You & Welcome to myNation</p>
        `,
            emailList: [],
          };
          // email.emailList.push(model['email']);
          // this.emailService.sendEmail(email).subscribe(
          //   e => {
          //     if (e) {
          //       toast({ html: 'Registration Email Successfully Sent', classes: 'green' });
          //     }
          //   }
          // );
        }
      });

      const postObj: any = {
        id: this.selectedMemberModel.applicantID,
        firstName: this.selectedMemberModel.GivenName,
        lastName: this.selectedMemberModel.LastName,
        displayName: model.displayName,
        appointmentDate: (this.appointmentDate) ? this.appointmentDate : '',
        expiryDate: (this.expiryDate) ? this.expiryDate : '',
        chiefBio: model.chiefBio,
        chiefFacebookAcc: model.chiefFacebookAcc,
        chiefInstagramAcc: model.chiefInstagramAcc,
        chiefTwitterAcc: model.chiefTwitterAcc,
        chiefLinkedinAcc: model.chiefLinkedinAcc,
        chiefYoutubeAcc: model.chiefYoutubeAcc,
        email: model.email,
        cell_phone: model.cell_phone,
        website: model.website,
        office_phone: model.office_phone,
        imgLink: imgUrl,
        showEmail: model.showEmail,
        showMobile: model.showMobile,
        showFacebookAcc: model.showFacebookAcc,
        showTwitterAcc: model.showTwitterAcc,
        showInstagramAcc: model.showInstagramAcc,
        showLinkedinAcc: model.showLinkedinAcc,
        showYoutubeAcc: model.showYoutubeAcc,
        showOfficePhone: model.showOfficePhone,
        allowContact: model.allowContact
      };

      if (this.memberType === 'Chair') {
        if (this.boardModel.boardChair) {
          this.boardModel.boardChair.push(postObj);
        } else {
          this.boardModel.boardChair = [];
          this.boardModel.boardChair.push(postObj);
        }
      }

      if (this.memberType === 'Member') {
        if (this.boardModel.boardMembers) {
          this.boardModel.boardMembers.push(postObj);
        } else {
          this.boardModel.boardMembers = [];
          this.boardModel.boardMembers.push(postObj);
        }
      }
    }

    if (this.editMemberModel) {
      this.editMemberModel.displayName = model.displayName;
      this.editMemberModel.appointmentDate = (this.appointmentDate) ? this.appointmentDate : '';
      this.editMemberModel.expiryDate = (this.expiryDate) ? this.expiryDate : '';
      this.editMemberModel.chiefBio = model.chiefBio;
      this.editMemberModel.chiefFacebookAcc = model.chiefFacebookAcc;
      this.editMemberModel.chiefInstagramAcc = model.chiefInstagramAcc;
      this.editMemberModel.chiefTwitterAcc = model.chiefTwitterAcc;
      this.editMemberModel.chiefLinkedinAcc = model.chiefLinkedinAcc;
      this.editMemberModel.chiefYoutubeAcc = model.chiefYoutubeAcc;
      this.editMemberModel.email = model.email;
      this.editMemberModel.cell_phone = model.cell_phone;
      this.editMemberModel.website = model.website;
      this.editMemberModel.office_phone = model.office_phone;
      this.editMemberModel.imgLink = (imgUrl) ? imgUrl : this.editMemberModel.imgLink;
      this.editMemberModel.showEmail = model.showEmail;
      this.editMemberModel.showMobile = model.showMobile;
      this.editMemberModel.showFacebookAcc = model.showFacebookAcc;
      this.editMemberModel.showTwitterAcc = model.showTwitterAcc;
      this.editMemberModel.showInstagramAcc = model.showInstagramAcc;
      this.editMemberModel.showLinkedinAcc = model.showLinkedinAcc;
      this.editMemberModel.showYoutubeAcc = model.showYoutubeAcc;
      this.editMemberModel.showOfficePhone = model.showOfficePhone;
      this.editMemberModel.allowContact = model.allowContact;
      this.editMemberModel.status = 'Active';

      if (this.memberType === 'Chair') {
        this.boardModel.boardChair = [];
        this.boardModel.boardChair.push(this.editMemberModel);
      }
      if (this.memberType === 'Member') {
        let updateItem = this.boardModel.boardMembers.find(m => Number(m.id) === Number(this.editMemberModel.id));
        let index = this.boardModel.boardMembers.indexOf(updateItem);
        this.boardModel.boardMembers[index] = this.editMemberModel;
      }
    }

    this.govService.updateBoard(this.boardModel).then(up => {
      if (this.editMemberModel) {
        if (this.memberType === 'Chair') {
          toast({ html: 'Chair Of The Board Successfully Updated', classes: 'green' });
        }
        if (this.memberType === 'Member') {
          toast({ html: 'Board Member Successfully Updated', classes: 'green' });
        }
      }

      if (!this.editMemberModel) {
        if (this.memberType === 'Chair') {
          toast({ html: 'Chair Of The Board Successfully Added', classes: 'green' });
        }
        if (this.memberType === 'Member') {
          toast({ html: 'Board Member Successfully Added', classes: 'green' });
        }
      }

      this.router.navigate(['/admin/manage-board-members/', this.boardId])
    });

  }


  processFormBeforeSaving(modelToProcess: any) {
    var imgUrl = '';
    if (this.binDoc && this.oldUrl) {
      this.mediaService.UpdateBinImage(this.binDoc, this.oldUrl).subscribe(
        upload => {
          if (upload) {
            imgUrl = upload.imgLink;
            this.saveData(modelToProcess, imgUrl)
            return;
          }
        }
      );
    } else if (this.binDoc && !this.oldUrl) {
      this.mediaService.UploadBinImage(this.binDoc).subscribe(
        upload => {
          if (upload) {
            imgUrl = upload.imgLink;
            this.saveData(modelToProcess, imgUrl)
            return;
          }
        }
      );
    } else if (!this.binDoc && this.oldUrl) {
      this.mediaService.DeleteBinImage(this.binDoc).subscribe(
        upload => {
          if (upload) {
            imgUrl = upload.imgLink;
            this.saveData(modelToProcess, imgUrl)
            return;
          }
        });
    } else if (!this.binDoc && !this.oldUrl) {
      this.saveData(modelToProcess, imgUrl)
      return;
    }
  }

  openDeleteModal() {
    $('#deleteBoardMemberConfirmation').modal('open');
  }
  deleteBoardMember() {
    $('#deleteBoardMemberConfirmation').modal('close');
    if (this.memberType === 'Chair') {
      this.boardModel.boardChair = [];
      this.govService.updateBoard(this.boardModel);
    }
    if (this.memberType === 'Member') {
      const deleteItem = this.boardModel.boardMembers.find(m => Number(m.id) === Number(this.editMemberModel.id));
      const index = this.boardModel.boardMembers.indexOf(deleteItem);
      this.boardModel.boardMembers.splice(index, 1);
      this.govService.updateBoard(this.boardModel).then(x => {
        toast({ html: 'Board Member Successfully Deleted', classes: 'green' });
        this.backClicked();
      });
    }
  }

  archiveBoardMember() {
    const memberToBeArchived = {
      memberId: this.editMemberModel.id,
      boardId: this.boardModel.id,
      memberType: '',
    };
    // get board member
    this.govService.getBoardMemberByUserIdAndBoardId(this.editMemberModel.id, this.boardModel.id)
      .valueChanges().subscribe(boardMemberDetail => {
        if (boardMemberDetail.length > 0) {
          this.isArchived = true;
        }
      });
    if (this.memberType === 'Chair') {
      memberToBeArchived.memberType = 'Chair';
      this.boardModel.boardChair = [];
      this.govService.updateBoard(this.boardModel).then(x => {
        if (!this.isArchived) {
          this.govService.archiveBoardMember(memberToBeArchived);
        }
        toast({ html: 'Chair Of The Board Successfully Deleted', classes: 'green' });
        this.backClicked();
      });
    }
    if (this.memberType === 'Member') {
      memberToBeArchived.memberType = 'Member';
      let deleteItem = this.boardModel.boardMembers.find(m => Number(m.id) === Number(this.editMemberModel.id));
      let index = this.boardModel.boardMembers.indexOf(deleteItem);
      this.boardModel.boardMembers.splice(index, 1);
      this.govService.updateBoard(this.boardModel).then(x => {
        if (!this.isArchived) {
          this.govService.archiveBoardMember(memberToBeArchived);
        }
        toast({ html: 'Board Member Successfully Deleted', classes: 'green' });
        this.backClicked();
      });
    }
  }

  backClicked() {
    this.location.back();
  }

  handleFileInput(files: FileList) {
    if (!this.validateImage(files[0].name)) {
      alert('Invalid File Type, Please Select an Image File');
      return;
    }
    this.binDoc = files[0];
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => {
      this.base64Image = reader.result as string;
      this.profile_pic = reader.result;
    };
  }

  removeSavedFile() {
    this.binDoc = undefined;
    this.base64Image = undefined;
    this.oldUrl = (this.myimgLink) ? this.myimgLink : undefined;
    this.myimgLink = '';
    this.profile_pic = undefined;
    this.showImageUpload = true;
  }

  private validateImage(name: any) {
    const ext = name.substring(name.lastIndexOf('.') + 1);
    if (ext.toLowerCase() === 'png') {
      return true;
    } else if (ext.toLowerCase() === 'jpg') {
      return true;
    } else if (ext.toLowerCase() === 'jpeg') {
      return true;
    } 
    else if (ext.toLowerCase() === 'svg') {
      return true;
    } 
    else if (ext.toLowerCase() === 'webp') {
      return true;
    } 
    else {
      return false;
    }
  }

  navigate(item) {
    const routeMappings = {
      'AboutUs': 'admin/dashboard-sub-menu/about-us',
      'NationInfo': 'admin/dashboard-sub-menu/nation-settings',
      'Departments': 'admin/dashboard-sub-menu/department',
      'Governance': 'admin/dashboard-sub-menu/governance',
      'AreaOfInterest': 'admin/dashboard-sub-menu/areas-of-interest',
    };

    const route = routeMappings[item];
    if (route) {
      this.router.navigate([route]);
    }
  }

}
