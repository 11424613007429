import { Component, OnInit } from '@angular/core';
import { EntitlementModel } from 'src/app/models/entitlementModel';
import { AuthService } from 'src/app/services/firebase/auth.service';
import { EntitlementService } from 'src/app/services/api/entitlement.service';
import { Location } from '@angular/common';
import SignaturePad from 'signature_pad';
import { SignatureModel } from 'src/app/models/signatureModel';
import { toast } from 'materialize-css';
import { environment } from 'src/environments/environment';
import { AppInfoService } from 'src/app/services/api/app-info.service';

declare var $: any;

@Component({
  selector: 'app-my-entitlement',
  templateUrl: './my-entitlement.component.html',
  styleUrls: ['./my-entitlement.component.css']
})
export class MyEntitlementComponent implements OnInit {

  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  step_0 = true;
  step0 = false;
  step1 = false;
  step2 = false;
  step3 = false;
  step4 = false;
  userID: any;
  isSpouse = false;
  name = '';

  complianceList: any[];
  years: number[];
  data: any[];
  result: any = [];

  hasSignedEntitlement = false;
  hasEntitlement = false;
  entitlements: EntitlementModel[];

  hasSpouse = false;
  spouseSignatureOK = false;

  model: SignatureModel;
  canvas: any;
  signaturePad: SignaturePad;

  applicantID = 0;

  currentYear = new Date().getFullYear();
  currentMonth = new Date().getMonth();
  fiscalYear: any;

  constructor(private authService: AuthService, private entitlementService: EntitlementService,
    private location: Location,
    private appInfoService: AppInfoService) {
    this.userID = authService.getGlobalUser().applicantID;
    localStorage.setItem('from', 'SocialAssistance');
  }

  ngOnInit() {
    if (localStorage.getItem('applicantID')) {
      this.applicantID = Number(localStorage.getItem('applicantID'));
    } else {
      this.applicantID = this.authService.getCurrentApplicantID();
    }

    this.appInfoService.getBDCompliance(this.applicantID).subscribe(comp => {
      if (comp) {
        if (comp.length > 0) {
          if (comp[0].SpouseAID) { this.isSpouse = ((comp[0].SpouseAID.toString() === this.applicantID.toString()) ? true : false); }

          this.hasEntitlement = true;
          this.complianceList = comp;

          // const result = Array.from(new Set(this.complianceList.map(a => a.Year))).sort((a, b) => b - a);

          this.years = Array.from(new Set(this.complianceList.map(a => a.Year))).sort((a, b) => b - a);
        } else { this.hasEntitlement = false; }
      } else { this.hasEntitlement = false; }
    });
  }

  selectBD(BDNo: string) {

    this.entitlementService.getEntitlementByBDNo(BDNo).subscribe(ent => {
      if (ent) {
        this.entitlements = ent;
        const compliance = this.complianceList.find(cmp => cmp.BDNo === BDNo);

        if (compliance) {
          if (!this.isSpouse) {
            this.name = compliance.LastName + ' ' + compliance.GivenName;
            if (compliance.BD_Client_SIGOK === 'True') { this.hasSignedEntitlement = true; }
            if (compliance.BD_Client_SIGOK === 'false') { this.hasSignedEntitlement = false; }
            this.step0 = false;
            this.step1 = true;
          }

          if (this.isSpouse) {
            this.name = compliance.spouse_name;
            if (compliance.BD_Spouse_SIGOK === 'True') { this.hasSignedEntitlement = true; }
            if (compliance.BD_Spouse_SIGOK === 'false') { this.hasSignedEntitlement = false; }
            this.step0 = false;
            this.step1 = true;
          }
        }
      }
    });
  }

  approveAndSigne() {
    this.step1 = false;
    this.step2 = true;

    setTimeout(() => {
      this.canvas = document.querySelector('canvas');
      const parentWidth = $(this.canvas).parent().outerWidth();
      this.canvas.setAttribute('width', parentWidth);
      this.canvas.setAttribute('height', '175px');
      this.signaturePad = new SignaturePad(this.canvas, {
        backgroundColor: 'rgb(255,255,255)'
      });
    }, 50);
  }

  submit() {

    if (this.signaturePad.isEmpty()) {
      toast({ html: 'Please Signe The Entitlement!', classes: 'red' });
      return;
    }

    if (!this.isSpouse) {
      this.model = new SignatureModel();
      this.model.bdNo = this.entitlements[0].BDNo;
      this.model.signature = this.signaturePad.toDataURL().replace('data:image/png;base64,', '');
      this.model.applicantName = this.authService.getGlobalUser().GivenName + ' ' + this.authService.getGlobalUser().LastName;
      this.entitlementService.postSignature(this.model).subscribe(x => {
        toast({ html: 'Entitlement Successfully Signed!', classes: 'green' });
        this.step2 = false;
        this.step3 = true;
        // this.emailService.sendBDApprovalNotification(this.entitlements[0].GivenName + ' ' + this.entitlements[0].LastName);
      });
    }

    if (this.isSpouse) {
      const model = new SignatureModel();
      model.bdNo = this.entitlements[0].BDNo;
      model.spouseSignature = this.signaturePad.toDataURL().replace('data:image/png;base64,', '');
      this.entitlementService.postSpouseSignature(model).subscribe(x => {
        toast({ html: 'Entitlement Successfully Signed!', classes: 'green' });
        this.step2 = false;
        this.step3 = true;
        // this.emailService.sendBDApprovalNotification(this.entitlements[0].GivenName + ' ' + this.entitlements[0].LastName);
      });
    }
  }

  clearSignature() {
    this.signaturePad.clear();
  }

  back_0() {
    this.step_0 = true;
    this.step0 = false;
  }

  back0() {
    this.entitlements = undefined;
    this.step0 = true;
    this.step1 = false;
  }

  back1() {
    this.step1 = true;
    this.step2 = false;
  }

  newYearSelected(value: number, fiscalYear1: any, fiscalYear2: any) {
    this.data = [];
    this.fiscalYear = fiscalYear1 + ' - ' + fiscalYear2;

    for (const entry of this.complianceList) {
      if (!entry.date_signed_applicant) {

        if ((Number(entry.Year) === Number(value) && (
          entry.Month === 'April'
          || entry.Month === 'May'
          || entry.Month === 'June'
          || entry.Month === 'July'
          || entry.Month === 'August'
          || entry.Month === 'September'
          || entry.Month === 'October'
          || entry.Month === 'November'
          || entry.Month === 'December'
        )) || (Number(entry.Year) === Number(value + 1) && (
          entry.Month === 'January'
          || entry.Month === 'February'
          || entry.Month === 'March'
        ))) {
          this.data.push(entry);
        }

        if (Number(entry.Year) === Number(value + 1) && (
          entry.Month === 'January'
          || entry.Month === 'February'
          || entry.Month === 'March'
        )) {

          this.data.push(entry);
        }
      }
    }

    this.result = Array.from(new Set(this.data.map(a => a.BDNo))).map(BDNo => {
      const obj = {
        AID: this.data.find(x => x.BDNo === BDNo).AID,
        BDNo: this.data.find(x => x.BDNo === BDNo).BDNo,
        Month: this.data.find(x => x.BDNo === BDNo).Month,
        Year: this.data.find(x => x.BDNo === BDNo).Year,
        BD_Client_SIGOK: this.data.find(x => x.BDNo === BDNo).BD_Client_SIGOK,
        spouse_name: this.data.find(x => x.BDNo === BDNo).spouse_name,
        SpouseAID: this.data.find(x => x.BDNo === BDNo).SpouseAID,
        BD_Spouse_SIGOK: this.data.find(x => x.BDNo === BDNo).BD_Spouse_SIGOK,
        dateCreated: this.dateCreated(this.data.find(x => x.BDNo === BDNo).Month, this.data.find(x => x.BDNo === BDNo).Year),
        GivenName: this.data.find(x => x.BDNo === BDNo).GivenName,
        LastName: this.data.find(x => x.BDNo === BDNo).LastName,

      };
      setTimeout(() => {
        this.step_0 = false;
        this.step0 = true;
      }, 25);

      return obj;
    }).sort((a, b) => +new Date(b.dateCreated) - +new Date(a.dateCreated));
  }

  backClicked() {
    this.location.back();
  }

  dateCreated(month: string, year: number) {
    const months = ['January', 'February', 'March', 'April',
      , 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const monthIndex = months.indexOf(month);
    return new Date(year, monthIndex, 1);
  }
}
