<div class="container-pi">
  <div class="">
    <h4 class="title-section1 title-member  icon-texts"><span class="material-symbols-outlined back-icon p-r-1"
      (click)=" back()">
      arrow_circle_left
  </span> My Housee </h4>
  </div>
  
  <app-firebase-loading *ngIf="loading"></app-firebase-loading>
  
  <div class="StickyContent white row">
    <div class="col s12 l12">
  
      <div class="row center card white" *ngIf="!house">
        <i style="font-size: 100px;" class="material-icons blue-text">
          info
        </i>
        <p class="blue-text"> <strong>No House Registred by Your Name.</strong></p>
      </div>
  
      <div *ngIf="house && occupants">
        <app-property-details [model]="house" [occupants]="occupants" [from]="'M'"></app-property-details>
      </div>
      <div class="row col s12">
  
        <!-- [routerLink]="['/member/update-property', propertyId]" -->
        <!-- <button class="btn col s12  waves-effect green" style="margin-bottom: 15px;" [routerLink]="['/member/update-house', houseId]">
                <i class="material-icons left">edit</i> Update Property
            </button> -->
  
        <!-- <button type="button" class='col s12 btn waves-effect green' style="margin-bottom: 15px;" (click)="getReport()">
          <i class="material-icons right">download</i> Get Report
        </button> -->
  
        <button [ngClass]="'col s12 btn waves-effect ' + themeColor" (click)="back()">
          <i class="material-icons left">arrow_back_ios</i> Back To Previous Page
        </button>
  
      </div>
    </div>
    
  </div>
  </div>
  
  <!-- <div class="row col s12">
    <div class="col l1 "></div>
    <div class="col s12 l10">
      <form (ngSubmit)="submitForm(housingForm.value)" [formGroup]="housingForm">
  
        <div class="row card-panel">
          <div class="row">
  
            <h6 class="center" style="margin-bottom: 25px; margin-top: 15px;"><strong>House Information</strong></h6>
  
          <div class="input-field class col s12">
            <i class="material-icons prefix green-text">person_pin</i>
            <input formControlName="nameOfTheOwner" id="nameOfTheOwner" type="text" maxlength="100" autocomplete="off" required
            class="validate">
            <label for="nameOfTheOwner"> Head Of Household: </label>
          </div>
  
          <div class="input-field class col s12">
            <i class="material-icons prefix orange-text">add_location</i>
            <input formControlName="houseNumber" id="houseNumber" type="text" maxlength="5" autocomplete="off" required
            class="validate">
            <label for="houseNumber"> House Number: <strong class="red-text">*</strong> </label>
            <div *ngIf="(houseNumber.dirty || houseNumber.touched) && houseNumber.invalid">
              <small *ngIf="houseNumber.errors.required" class="red-text"> Please enter the house number.
              </small>
            </div>
          </div>
  
          <div class="input-field class col s12">
            <i class="material-icons prefix blue-text">house</i>
            <select formControlName="houseType" required class="validate">
              <option value="" disabled selected> Select house Type </option>
              <option *ngFor="let type of houseTypes" [value]="type">{{type}}</option>
            </select>
            <div *ngIf="(houseType.dirty || houseType.touched) && houseType.invalid">
              <small *ngIf="houseType.errors.required" class="red-text"> Please select the house type.
              </small>
            </div>
          </div> -->
  
  <!-- <div class="input-field class col s12">
            <i class="material-icons prefix teal-text">house</i>
            <select formControlName="houseStyle" required class="validate">
              <option value="" disabled selected> Select House Style </option>
              <option *ngFor="let type of houseStyles" [value]="type">{{type}}</option>
            </select>
            <div *ngIf="(houseStyle.dirty || houseStyle.touched) && houseStyle.invalid">
              <small *ngIf="houseStyle.errors.required" class="red-text"> Please select the house Style.
              </small>
            </div>
          </div> -->
  
  <!-- <div class="input-field class col s12">
            <i class="material-icons prefix pink-text">today</i>
            <input formControlName="yearBuiltOn" id="yearBuiltOn" type="text" maxlength="4" autocomplete="off" required
            class="validate">
            <label for="yearBuiltOn"> Year Built : </label>
          </div>
  
          <div class="input-field class col s12">
            <i class="material-icons prefix purple-text">meeting_room</i>
            <input formControlName="numberOfRooms" id="numberOfRooms" type="text" maxlength="2" autocomplete="off" required
            class="validate">
            <label for="numberOfRooms"> Number Of Rooms: </label>
          </div> -->
  
  <!-- <div class="input-field class col s8">
            <i class="material-icons prefix blue-text text-darken-2">fullscreen</i>
            <input formControlName="size" id="size" type="text" maxlength="10" autocomplete="off" required
            class="validate">
            <label for="size"> size: </label>
          </div>
  
          <div class="input-field class col s4">
            <select formControlName="houseType" required class="validate">
              <option *ngFor="let type of unitTypes" [value]="type">{{type}}</option>
            </select>
          </div>
  
          <div class="input-field class col s12">
            <i class="material-icons prefix orange-text text-darken-4">monetization_on</i>
            <input formControlName="value" id="value" type="text" maxlength="10" autocomplete="off" required
            class="validate">
            <label for="value"> Value: </label>
          </div> -->
  
  <!-- </div>
      </div>
  
      <div class="row card-panel white">
        <h6 class="center" style="margin-bottom: 25px; margin-top: 15px;"><strong>House Location</strong></h6>
        <div class="row">
  
  
          <div class="row" *ngIf="showMap">
            <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom">
              <agm-marker [latitude]="lat" [longitude]="lng"></agm-marker>
            </agm-map>
          </div>
  
          <div class="row" *ngIf="showMap">
            <ul class="collection with-header col s12">
              <li class="collection-header"><h6>My House Position</h6></li>
              <li class="collection-item"><div>Latitude<a   class="secondary-content">{{lat}} </a></div></li>
              <li class="collection-item"><div>Longitude<a   class="secondary-content">{{lng}}  </a></div></li>
            </ul>
          </div> -->
  
  <!-- <div class="row card-panel col s12" *ngIf="!showMap">
            <div class="row">
              <p class="center red-text text-darken-2"><i class="material-icons ">info</i> Important </p>
              <p class="center"> <strong>To Get Accurate Position, Please Make Sure You Are Inside of Your House. </strong> </p>
            </div>
          </div> -->
  
  <!-- <button *ngIf="!showMap" type="button" class="btn col s12 green" (click)="geolocateMyHouse();"> <i class="material-icons right">add_location</i> Geolocate My House </button>
        </div>
      </div>
  
      <div class="row">
        <button type="button" class="btn col s12 blue" (click)="myHouseClicked();"> <i class="material-icons left">arrow_back_ios</i> Back To My House </button>
      </div>
  
      </form>
    </div>
    <div class="col l1 "></div>
  </div> -->