<h6 class="center p-m-2 b-m-2"><strong>My Action Detail </strong> </h6>

<div class="row col s12">
    <div class="col l1"></div>

    <div id="step1">
        <div class="col s12 m12 l10">
            <div class="row card-panel">
                <ul class="row collapsible">
                    <li>
                        <div class="collapsible-header">
                            <div class="collection-item">
                                <i class="material-icons green-text">check_circle_outline</i> <strong> Objective
                                </strong>
                            </div>
                        </div>
                        <div class="collapsible-body"><span><strong>{{ actObj }}</strong></span></div>
                    </li>
                    <li>
                        <div class="collapsible-header"><i
                                class="material-icons blue-text">timeline</i><strong>Employment Measures</strong> </div>
                        <div class="collapsible-body"><span><strong> {{ empObj }} </strong></span></div>
                    </li>
                    <li>
                        <div class="collapsible-header"><i class="material-icons orange-text">group_work</i><strong>
                                Action Detail </strong></div>
                        <div class="collapsible-body">
                            <div class="row collection">
                                <a class="collection-item">
                                    <div>
                                        <p class="black-text"><strong>{{ activity?.item}}</strong></p>
                                    </div>
                                </a>
                                <a class="collection-item">
                                    <div><strong> <span class="black-text">Start Date:</span> </strong> <a
                                            class="secondary-content blue-text"><strong>{{ activity?.start_date | date:
                                                'dd/MMM/yyyy'}}</strong>
                                        </a></div>
                                </a>
                                <a class="collection-item">
                                    <div><strong> <span class="black-text">End Date:</span></strong> <a
                                            class="secondary-content blue-text"><strong>{{ activity?.end_date | date:
                                                'dd/MMM/yyyy' }}</strong>
                                        </a></div>
                                </a>
                                <a class="collection-item">
                                    <div><strong> <span class="black-text">Frequency:</span></strong> <a
                                            class="secondary-content blue-text"><strong>{{ activity?.frequency
                                                }}</strong>
                                        </a></div>
                                </a>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="collapsible-header"><i
                                class="material-icons purple-text">schedule</i><strong>Outcome Obtained</strong></div>
                        <div class="collapsible-body">
                            <div class="row">
                                <div class="row input-field">
                                    <div class="col s3">
                                        <label>
                                            <input name="group1" value="Yes" type="radio" (click)="YesClicked()" />
                                            <span>Yes</span>
                                        </label>
                                    </div>
                                    <div class="col s3">
                                        <label>
                                            <input name="group1" value="No" type="radio" (click)="NoClicked()" />
                                            <span>No</span>
                                        </label>
                                    </div>
                                    <div class="col s6">
                                        <label>
                                            <input id="preogress" name="group1" value="In Progress" checked type="radio"
                                                (click)="InProgressClicked()" />
                                            <span>In Progress</span>
                                        </label>
                                    </div>
                                </div>
                            </div>

                            <div class="row" *ngIf="outcomeYes">
                                <form [formGroup]="resultForm" (ngSubmit)="submitResult(resultForm)">
                                    <div class="row">
                                        <div class="row">
                                            <div class="input-field col s12 l12">
                                                <input id="date" type="text" formControlName='date' required
                                                    class="datepicker validate active a" />

                                                <label for="date">Date Obtained </label>
                                                <div *ngIf="(date.dirty || date.touched) && date.invalid">
                                                    <small *ngIf="date.errors.required" class="red-text"> The Date of
                                                        Birth Is Required. </small>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="input-field col s12 l12">
                                            <textarea id="textarea1" class="materialize-textarea validate"
                                                formControlName='result' required></textarea>
                                            <label for="textarea1">Please Enter The Action Result :</label>
                                            <div *ngIf="(result.dirty || result.touched) && result.invalid">
                                                <small *ngIf="result.errors.required" class="red-text"> Action Result Is
                                                    Required.
                                                </small>
                                            </div>
                                        </div>

                                        <button class="right btn waves-effect waves-light col s6" type="submit"
                                            [disabled]="!resultForm.valid">Submit
                                            <i class="material-icons right">send</i>
                                        </button>
                                    </div>
                                </form>
                            </div>

                            <div class="row" *ngIf="outcomeNo">
                                <form [formGroup]="reasonForm" (ngSubmit)="submitReason(reasonForm)">
                                    <div class="row">
                                        <div class="input-field col s12 l12">
                                            <textarea id="textarea1" class="materialize-textarea validate"
                                                formControlName='reason' required></textarea>
                                            <label for="textarea1">Please Enter The Reason :</label>

                                            <div *ngIf="(reason.dirty || reason.touched) && reason.invalid">
                                                <small *ngIf="reason.errors.required" class="red-text"> The Reason Is
                                                    Required.
                                                </small>
                                            </div>
                                        </div>
                                        <button class="right btn waves-effect waves-light col s6" type="submit"
                                            [disabled]="!reasonForm.valid">Submit
                                            <i class="material-icons right">send</i>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>

            <div class="row">
                <div class="section"></div>
                <button [ngClass]="'row btn waves-effect waves-light col s12 ' + themeColor " (click)="backClicked()">
                    <i class="material-icons left">keyboard_backspace</i> Back To My Participation
                </button>
            </div>
        </div>
    </div>

    <div class="col l1 "></div>
</div>