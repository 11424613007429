import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ParticipationService } from 'src/app/services/api/participation.service';
import { Location } from '@angular/common';
import { AuthService } from 'src/app/services/firebase/auth.service';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-participation-detail',
  templateUrl: './participation-detail.component.html',
  styleUrls: ['./participation-detail.component.css']
})

export class ParticipationDetailComponent implements OnInit {
  applicantID = 0;
  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  model: any;
  activityList: any[];
  actionsList: any[];
  countActivity = 0;
  countAction = 0;

  constructor(private location: Location,
    private participationService: ParticipationService,
    private authService: AuthService) { localStorage.setItem('from', 'SocialAssistance'); }

  ngOnInit() {

    if (localStorage.getItem('applicantID')) {
      this.applicantID = Number(localStorage.getItem('applicantID'));
    } else {
      this.applicantID = this.authService.getCurrentApplicantID();
    }

    this.participationService.getParticipationByID(this.applicantID).subscribe(x => {
      if (x) {
        this.model = x[0];
        this.participationService.getActivityList(x[0].ID).subscribe(data => {
          if (data) {
            this.activityList = data;
            this.countActivity = data.length;
          }
        });

        this.participationService.getActionList(x[0].ID).subscribe(data2 => {
          if (data2) {
            this.actionsList = data2;
            this.countAction = data2.length;
          }
        });

        setTimeout(() => {
          $('.collapsible').collapsible();
        }, 50);
      }
    });
  }

  backClicked() {
    this.location.back();
  }

}
