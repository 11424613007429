<div class="row" *ngIf="governanceSection">
    <div class="row p-t-3 p-b-2">
        <div class="col s12 m9 l7 push-l1  push-m1">
            <div class="header-title">
                <h6>{{governanceSection.displayName}}</h6>
            </div>
        </div>

        <div class="col l4 m4 hide-on-small-only" style="padding: 30px 0px;">
            <ul class="tabs">
                <li class="tab col s4" *ngIf="governanceSection.showChief"><a (click)="selectOption('chief')" href="#Chief">{{chiefTitle}}</a></li>
                <li class="tab" [ngClass]="governanceSection.showChief ? 'col s4' : 'col s12'"><a (click)="selectOption('councillors')"
                        href="#Councillors">{{councillorTitle}}</a></li>
                <li class="tab col s4"><a href="#Boards" (click)="selectOption('boards')">Boards</a></li>
            </ul>
        </div>
        <div class="divider col l10 m8 s8 push-l1 push-m2 push-s2 grey lighten-3"></div>
    </div>

    <div class="row" *ngIf="governance">
        <div class="col l10 m10 s12  push-l1 push-m1">
            <div class="row">
                <div class="col l5 right s12 p-t-3 p-b-3 hide-on-med-and-up">
                    <ul class="tabs">
                        <li class="tab col s4" *ngIf="governanceSection.showChief"><a href="#Chief">{{chiefTitle}}</a></li>
                        <li class="tab col s4"><a href="#Councillors">{{councillorTitle}}</a></li>
                        <li class="tab col s4"><a href="#Boards">Boards</a></li>
                    </ul>
                </div>

                <!--------------------------- Chief -------------------------------------------->
                <div id="Chief" class="col s12">
                    <div *ngIf="governanceSection.showChief && chief">
                        <div class="col s12 m12 l10 push-l1 chief">
                            <!-- -===================== card 1 -->
                            <div class="profile-card1 row" [routerLink]="['/governance-chief']">
                                <div class="profile-body col l5 s12">
                                    <div class="author-img">
                                        <img *ngIf="chief.profile_pic" [src]="chief.profile_pic" alt="">
                                        <img *ngIf="!chief.profile_pic" src="../../../../assets/img/avatar.jpeg" alt="">
                                    </div>
                                    <div class="name" [ngStyle]="{'color': governanceSection.chiefColor}">
                                        {{(chief.displayName) ?
                                        chief.displayName :
                                        chief.firstName +' ' +
                                        chief.lastName}}</div>
                                    <div class="intro">
                                        <p class="center bold-100">
                                            <a [ngStyle]="{'color': governanceSection.chiefColor}" [href]="'mailto:' + chief.email">
                                                {{chief.email}}
                                            </a> </p>
                                        <p class="center bold-100" [ngStyle]="{'color': governanceSection.chiefColor}">
                                            {{nation}} {{chiefTitle}}</p>
                                    </div>
                                    <div class="social-icon">
                                        <ul>
                                            <li>
                                                <a href="{{chief.chiefFacebookAcc}}"
                                                    *ngIf="(chief.showFacebookAcc === 'Public') && chief.chiefFacebookAcc"
                                                    target="_blank" >
                                                    <i class="fa fa-facebook-f" [ngStyle]="{'color': '#23a6fb'}"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="{{chief.chiefTwitterAcc}}"
                                                    *ngIf="(chief.showTwitterAcc === 'Public' ) && chief.chiefTwitterAcc"
                                                    target="_blank">
                                                    <i class="bold-700" [ngStyle]="{'color': '#000'}">X</i>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="{{chief.chiefLinkedinAcc}}"
                                                    *ngIf="(chief.showLinkedinAcc === 'Public') && chief.chiefLinkedinAcc"
                                                    target="_blank">
                                                    <i class="fa fa-linkedin" [ngStyle]="{'color': '#1968c7'}"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="{{chief.chiefInstagramAcc}}"
                                                    *ngIf="(chief.showInstagramAcc === 'Public' ) && chief.chiefInstagramAcc"
                                                    target="_blank">
                                                    <i class="fa fa-instagram" [ngStyle]="{'color': '#ff15a7'}"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="{{chief.chiefYoutubeAcc}}"
                                                    *ngIf="(chief.showYoutubeAcc === 'Public') && chief.chiefYoutubeAcc"
                                                    target="_blank">
                                                    <i class="fa fa-youtube" [ngStyle]="{'color': 'red'}"></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>

                                <div class=" col s12 l7 right p-t-2 chief-det">
                                    <p class=" bold-100 ">
                                        <span>First Name :</span> {{chief.firstName}}
                                    </p>
                                    <p class=" bold-100">
                                        <span>Last Name :</span> {{chief.lastName}}
                                    </p>
                                    <p class=" bold-100">
                                        <span>Name :</span> {{chief.displayName}}
                                    </p>

                                    <p class=" bold-100">
                                        <span>Email :</span><a [href]="'mailto:' + chief.email">{{chief.email}}</a>
                                    </p>
                                    <p class=" bold-100">
                                        <span>Office Phone : </span> <a [href]="'tel:' + chief.office_phone">{{chief.office_phone}}</a>
                                    </p>
                                    <p class=" bold-100">
                                        <span>Mobile Phone : </span> <a [href]="'tel:' + chief.cell_phone">{{chief.cell_phone}}</a>
                                    </p>
                                    <p class=" bold-100 middle-body-snippet">
                                        <span>Chief Bio: </span> {{chief.chiefBio}}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!--------------------------- Councillor -------------------------------------------->
                <div id="Councillors" class="col s12">
                    <div *ngIf="councillors">
                        <div class="col s12 m6 l3" *ngFor="let councillor of councillors">
                            <div class="profile-card1" [routerLink]="['/governance-detail',councillor.applicantID]">
                                <div class="profile-body">
                                    <div class="author-img">
                                        <img *ngIf="councillor.profile_pic" [src]="councillor.profile_pic" alt="">
                                        <img *ngIf="!councillor.profile_pic" src="../../../../assets/img/avatar.jpeg"
                                            alt="">
                                    </div>
                                    <div class="name" [ngStyle]="{'color': governanceSection.councillorColor}">
                                        {{(councillor.displayName) ?
                                        councillor.displayName :
                                        councillor.firstName +' ' +
                                        councillor.lastName}}</div>

                                    <div class="intro">
                                        <p class="center bold-100"
                                            [ngStyle]="{'color': governanceSection.councillorColor}">
                                            <a [href]="'mailto:' + councillor.email">{{councillor.email}}</a></p>
                                        <p class="center bold-100"
                                            [ngStyle]="{'color': governanceSection.councillorColor}">
                                            {{nation}} {{councillorTitle}}</p>
                                    </div>
                                    <div class="social-icon">
                                        <ul>
                                            <li>
                                                <a href="{{councillor.chiefFacebookAcc}}"
                                                    *ngIf="(councillor.showFacebookAcc === 'Public') && councillor.councillorFacebookAcc"
                                                    target="_blank">
                                                    <i class="fa fa-facebook-f" [ngStyle]="{'color': '#23a6fb'}"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="{{councillor.chiefTwitterAcc}}"
                                                    *ngIf="(councillor.showTwitterAcc === 'Public' ) && councillor.councillorTwitterAcc"
                                                    target="_blank">
                                                    <i class="bold-700" [ngStyle]="{'color': 'black'}">X</i>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="{{councillor.chiefLinkedinAcc}}"
                                                    *ngIf="(councillor.showLinkedinAcc === 'Public') && councillor.councillorLinkedinAcc"
                                                    target="_blank">
                                                    <i class="fa fa-linkedin" [ngStyle]="{'color': '#1968c7'}"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="{{councillor.chiefInstagramAcc}}"
                                                    *ngIf="(councillor.showInstagramAcc === 'Public' ) && councillor.councillorInstagramAcc"
                                                    target="_blank">
                                                    <i class="fa fa-instagram" [ngStyle]="{'color': '#ff15a7'}"></i>
                                                </a>
                                            </li>
                                            <li>
                                                <a href="{{councillor.chiefYoutubeAcc}}"
                                                    *ngIf="(councillor.showYoutubeAcc === 'Public') && councillor.councillorYoutubeAcc"
                                                    target="_blank">
                                                    <i class="fa fa-youtube" [ngStyle]="{'color': 'red'}"></i>
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!--------------------------- Board -------------------------------------------->
                <div id="Boards" class="col s12">
                    <app-board-list-shared [viewModel]="modell"></app-board-list-shared>
                </div>
            </div>
        </div>
    </div>
</div>