import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PollsAndSurveyModel, Question, SurveyQuestion } from 'src/app/models/PollsAndSurveyModel';
import { PollsAndSurveyParticipationService } from 'src/app/services/firebase/polls-and-survey-participation.service';
import { environment } from 'src/environments/environment';
import { Location } from '@angular/common';

declare var $: any;
declare var M: any;

@Component({
  selector: 'app-polls-view',
  templateUrl: './polls-view.component.html',
  styleUrls: ['./polls-view.component.css']
})

export class PollsViewComponent implements OnInit {

  @Input() clientID: any;
  @Input() model: PollsAndSurveyModel;
  @Output() participationSaved: EventEmitter<string> = new EventEmitter<string>();

  themeColor = environment.appTheme.themeColor;
  fontColor = environment.appTheme.fontColor;
  pollForm: UntypedFormGroup;
  answersArray: UntypedFormArray;
  visibleStepIndex = 0;
  totalQuestions = 0;
  submitDisabled = false;
  showComment = false;
  leaveComment = false;

  constructor(private fb: UntypedFormBuilder, 
    private participationService: PollsAndSurveyParticipationService, 
    private location: Location) { }

  ngOnInit() {
    if (this.model) {
      this.pollForm = this.fb.group({
        answerItems: this.fb.array([]),
        leaveComment: ['', [Validators.required]],
        comment: [''],
      });

      this.pollForm.get('leaveComment').valueChanges.subscribe(x => {
        if (x == 'yes') {
          this.pollForm.get('comment').setValidators([Validators.required]);
        } else {
          this.pollForm.get('comment').clearValidators();
        }
        this.pollForm.get('comment').updateValueAndValidity();
      })

      this.totalQuestions = this.model.questions.length;

      this.model.questions.forEach(question => {
        if (question.answerType === 'Multiple Choice') {
          if (question.answers) this.addAnswer2(question);
        } else if (question.answerType === 'Scale') {
          this.addAnswer2(question);
        } else {
          this.addAnswer(question);
        }
      });
    }
  }

  addAnswer(questionModel: SurveyQuestion) {
    this.answersArray = this.pollForm.get('answerItems') as UntypedFormArray;
    this.answersArray.push(this.createAnswer(questionModel));
  }

  addAnswer2(questionModel: Question) {
    this.answersArray = this.pollForm.get('answerItems') as UntypedFormArray;
    this.answersArray.push(this.createAnswer2(questionModel));
  }

  createAnswer(questionModel: SurveyQuestion) {
    return this.fb.group({
      questionNumber: [questionModel.questionNumber],
      question: [questionModel.question],
      questionType: [questionModel.answerType],
      answer: ['', [Validators.required]],
    });
  }

  createAnswer2(questionModel: Question) {
    let answers = this.fb.array([]);
    questionModel.answers.forEach(x => {
      answers.push(this.addAnswer3(x.answer));
    });

    return this.fb.group({
      questionNumber: [questionModel.questionNumber],
      question: [questionModel.question],
      questionType: [questionModel.answerType],
      answers: answers,
      answer: ['', [Validators.required]],
    });
  }

  addAnswer3(value) {
    return this.fb.group({
      answer: [value, [Validators.required]]
    });
  }

  continue(index: any) {
    this.visibleStepIndex = index + 1;
  }

  previous(index: any) {
    if (index > 0) { this.visibleStepIndex = index - 1; }
  }

  prev() {
    this.visibleStepIndex = this.visibleStepIndex - 1;
  }

  submitPoll(value: any) {
    this.submitDisabled = true;
    const participation = {
      memberID: this.clientID,
      pollID: this.model.id,
      memberName: '',
      comment: value.comment,
      date: new Date(),
      answers: []
    };

    value.answerItems.forEach(element => {
      const answ = {
        questionNumber: element.questionNumber,
        question: element.question,
        questionType: element.questionType,
        answers: element.answers ? element.answers : [],
        answer: element.answer,
      };
      participation.answers.push(answ);
    });

  this.participationService.newPollParticipation(participation).then(x => {
      this.participationSaved.emit('saved');
      this.submitDisabled = false;
    });
  }

  answerSelected(question_index, value) {
    const answerArray = this.pollForm.get('answerItems') as UntypedFormArray;
    answerArray.controls[question_index].get('answer').patchValue(value);
  }

  onCommentYes() {
    this.showComment = true;
    setTimeout(() => {
      M.textareaAutoResize($('#comment'));
    }, 25);
  }

  onCommentNo() {
    this.showComment = false;
    this.pollForm.get('comment').reset();
  }

  backToList(){
    this.location.back();
  }

  getData(index) {
    const scales = this.model.questions[index].scales;
    const startLabel = scales.startLabel;
    const endLabel = scales.endLabel;

    const start = Number(scales.start);
    const end = Number(scales.end);
    var items: string[] = [];
    for (var i = start; i <= end; i++) {
      items.push(i.toString());
    }
    const data = {
      range: items,
      label: { start: startLabel, end: endLabel }
    }
    return data;
  }

  createRange(index) {
    const scales = this.model.questions[index].scales;
    const start = Number(scales.start);
    const end = Number(scales.end);
    var items: number[] = [];
    for (var i = start; i <= end; i++) {
      items.push(i);
    }
    return items;
  }

  getLables(index) {
    const scales = this.model.questions[index].scales;
    const startLabel = scales.startLabel;
    const endLabel = scales.endLabel;
    return { start: startLabel, end: endLabel };
  }

  getColSize(index) {
    const scales = this.model.questions[index].scales;
    var start = Number(scales.start);
    var end = Number(scales.end);
    var items = end - start;
    if (items > 7) {
      return `col s${12/(items)*2}`;
    } else {
      return `col s${12/items}`;
    }
  }

  setValue(index, value) {
    const answerItems = this.pollForm.get('answerItems') as UntypedFormArray;
    const answer = answerItems.controls[index].get('answer');
    answer.patchValue(value.toString());
  }

  get answers() {
    return (this.pollForm.get('answerItems') as UntypedFormArray).controls;
  }
}
